import React from 'react';
import PropTypes from 'prop-types';

import SVGIcon from '@palette/components/utils/Icons/SVGIcon/SVGIcon';

import LogoPaletteSvg from './svgs/logo_palette.svg';
import LogoPaletteWhiteSvg from './svgs/logo_palette_white.svg';
import LogoSvg from './svgs/logo.svg';
import LogoWhiteSvg from './svgs/logo_white.svg';

const PaletteLogo = ({
  className,
  logoOnly,
  white,
  width,
  height,
}) => {
  if (white) {
    if (logoOnly) {
      return (
        <SVGIcon className={className} preFillInWhite={false} Component={LogoWhiteSvg} width={width || 45} height={height || 45} />
      );
    }

    return (
      <SVGIcon className={className} preFillInWhite={false} Component={LogoPaletteWhiteSvg} width={width || 106} height={height || 36} />
    );
  }

  if (logoOnly) {
    return (
      <SVGIcon className={className} preFillInWhite={false} Component={LogoSvg} width={width || 45} height={height || 45} />
    );
  }

  return (
    <SVGIcon className={className} preFillInWhite={false} Component={LogoPaletteSvg} width={width || 106} height={height || 36} />
  );
};

PaletteLogo.propTypes = {
  className: PropTypes.string,
  logoOnly: PropTypes.bool,
  white: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

PaletteLogo.defaultProps = {
  className: '',
  logoOnly: false,
  white: false,
  width: null,
  height: null,
};

export default PaletteLogo;
