import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Input from '@palette/components/designSystem/Input/Input';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';

const MasterPlanSettingsGeneralNameFormItem = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      name="name"
      label={t('masterPlanSettingsGeneral.form.name.label')}
      required
      rules={[
        { required: true, message: t('masterPlanSettingsGeneral.form.name.rules.required') },
      ]}
    >
      <Input size="big" placeholder={t('masterPlanSettingsGeneral.form.name.placeholder')} disabled={disabled} />
    </FormItem>
  );
};

MasterPlanSettingsGeneralNameFormItem.propTypes = {
  disabled: PropTypes.bool,
};

MasterPlanSettingsGeneralNameFormItem.defaultProps = {
  disabled: false,
};

export default MasterPlanSettingsGeneralNameFormItem;
