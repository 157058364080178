import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { formatToISOStringWithFractionalSeconds } from '@palette/helpers/MomentHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* getVariables() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.usersVariables.getVariables, {}, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* createVariable(payload) {
  const {
    name,
    type,
    defaultValue,
  } = payload;

  const data = {
    name,
    type,
    defaultValue,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.usersVariables.createVariable, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* editVariable(payload) {
  const {
    usersIds,
    userVariableId,
    value,
    date,
  } = payload;

  const data = {
    userIds: usersIds,
    userVariableId,
    value,
    date: formatToISOStringWithFractionalSeconds(date),
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.usersVariables.editVariable, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* deleteVariableValueOverride(payload) {
  const {
    overrideId,
  } = payload;

  const data = {
    overrideId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.usersVariables.deleteVariableValueOverride, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}
