import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MasterPlanPeriodSelector from '@palette/components/masterPlanPeriod/MasterPlanPeriodSelector/MasterPlanPeriodSelector';
import MasterPlanPeriodTabs from '@palette/components/masterPlanPeriod/MasterPlanPeriodTabs/MasterPlanPeriodTabs';
import Loader from '@palette/components/utils/Loader/Loader';
import MasterPlanPeriodHighlightZone from '@palette/components/masterPlanPeriod/MasterPlanPeriodHighlightZone/MasterPlanPeriodHighlightZone';

import { useMasterPlanPeriodInParams } from '@palette/hooks/MasterPlanHooks';

import { getPeriodElapsedTimePercentage } from '@palette/helpers/MasterPlanHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanPeriodDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodDetailsPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { masterPlanId, year, periodId, plan, period } = useMasterPlanPeriodInParams();

  const getPeriodByIsPending = useSelector(MasterPlansSelectors.getPeriodByIsPending);

  useEffect(() => {
    if (masterPlanId) {
      dispatch(MasterPlansActions.getById({ planId: masterPlanId }));
    }
  }, [masterPlanId]);

  useEffect(() => {
    if (plan !== null && year && periodId) {
      dispatch(MasterPlansActions.getPeriodBy({ planId: plan.id, year, period: periodId }));
    }
  }, [plan, year, periodId]);

  const tabsNodes = useMemo(() => {
    if (plan === null || period === null || getPeriodByIsPending) {
      return (
        <Loader />
      );
    }

    return (
      <MasterPlanPeriodTabs className={styles.tabs} plan={plan} period={period} />
    );
  }, [plan, period, getPeriodByIsPending]);

  if (plan === null) {
    return (
      <Loader />
    );
  }

  const periodElapsedTimePercentage = getPeriodElapsedTimePercentage(plan, { year, period: periodId });

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.selectorActionsWrapper}>
        <div className={styles.selectorWrapper}>
          {
            plan.periodType !== PERIOD_TYPES.CUSTOM && (
              <MasterPlanPeriodSelector
                className={styles.periodSelectorWrapper}
                plan={plan}
                period={{ year, period: periodId }}
              />
            )
          }
          <div className={styles.timeElapsedWrapper}>
            <div className={styles.timeElapsedLabel}>
              {`${t('masterPlanPeriodDetailsPageContent.timeElapsed')} · `}
            </div>
            <div className={styles.timeElapsedPercentage}>
              {`${periodElapsedTimePercentage}%`}
            </div>
          </div>
        </div>
      </div>
      <MasterPlanPeriodHighlightZone className={styles.highlightZone} />
      {tabsNodes}
    </div>
  );
};

MasterPlanPeriodDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

MasterPlanPeriodDetailsPageContent.defaultProps = {
  className: '',
};

export default MasterPlanPeriodDetailsPageContent;
