/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';

import { original } from 'immer';

import {
  getLocalStorageItem,
  setLocalStorageItem,
  getMenuIsCollapsed,
  setMenuIsCollapsed,
} from '@palette/helpers/LocalStorageHelper';
import { posthogClient } from '@palette/helpers/PosthogHelper';

import { POSTHOG_INITIATED } from '@palette/constants/localStorage';

/*
 * Initial State
 */
const initialState = {
  getProfileIsPending: false,
  updateProfileIsPending: false,
  updateCompanyIsPending: false,
  updateStatementStrategyIsPending: false,
  toggleStatementValidationsIsPending: false,
  toggleStatementValidationsNotificationsIsPending: false,
  toggleEmailPreferencesIsPending: false,
  toggleDigestNotificationsIsPending: false,
  getSeatsCountIsPending: false,
  loginIsPending: false,
  resetPasswordIsPending: false,
  forgotPasswordIsPending: false,
  acknowledgeTermsAndConditionsIsPending: false,
  getAggregatedFxRatesIsPending: false,
  createOrUpdateFxRateIsPending: false,
  getListFxRatesIsPending: false,
  getSubscriptionIsPending: false,
  updateSubscriptionSeatsIsPending: false,
  sendFeedbackIsPending: false,
  updateStatementFxRateModeIsPending: false,

  profile: null,
  menuIsCollapsed: getMenuIsCollapsed() === 'true',
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Collapse Menu */
    collapseMenu: (state, { payload }) => {
      setMenuIsCollapsed(payload);
      state.menuIsCollapsed = payload;
    },
    /* Get Profile */
    getProfile: (state) => {
      state.getProfileIsPending = true;
    },
    setProfile: (state, { payload }) => {
      const { profile = null } = payload;

      const seatsCount = state.profile?.userData.company.seatsCount;
      const aggregatedFxRates = state.profile?.userData.company.aggregatedFxRates;
      const subscription = state.profile?.userData.company.subscription;

      const posthogInitiatedLocalStorage = getLocalStorageItem(POSTHOG_INITIATED);

      if (posthogInitiatedLocalStorage !== undefined && posthogInitiatedLocalStorage === 'false') {
        const {
          id,
          email,
          company,
          currency,
          account,
          displayName,
          firstName,
          lastName,
        } = profile.userData;

        posthogClient.identify(id, {
          email,
          name: displayName,
          firstName,
          lastName,
          currency,
          role: account.role.name,
          rights: Object.keys(account.role.rights),
          companyName: company.name,
          companyId: company.id,
        });
        posthogClient.group('company', company.id);

        setLocalStorageItem(POSTHOG_INITIATED, 'true');
      }

      state.profile = profile;

      if (profile === null) return;

      state.profile.userData.company.seatsCount = seatsCount;
      state.profile.userData.company.aggregatedFxRates = aggregatedFxRates;
      state.profile.userData.company.subscription = subscription;
    },
    getProfileCompleted: (state) => {
      state.getProfileIsPending = false;
    },
    /* Update Company */
    updateCompany: (state) => {
      state.updateCompanyIsPending = true;
    },
    updateCompanyCompleted: (state) => {
      state.updateCompanyIsPending = false;
    },
    /* Update Profile */
    updateProfile: (state) => {
      state.updateProfileIsPending = true;
    },
    updateProfileCompleted: (state) => {
      state.updateProfileIsPending = false;
    },
    /* Update Statement Strategy */
    updateStatementStrategy: (state) => {
      state.updateStatementStrategyIsPending = true;
    },
    updateStatementStrategyCompleted: (state) => {
      state.updateStatementStrategyIsPending = false;
    },
    /* Toggle Statement Validations */
    toggleStatementValidations: (state) => {
      state.toggleStatementValidationsIsPending = true;
    },
    toggleStatementValidationsCompleted: (state) => {
      state.toggleStatementValidationsIsPending = false;
    },
    /* Toggle Statement Validations Notifications */
    toggleStatementValidationsNotifications: (state) => {
      state.toggleStatementValidationsNotificationsIsPending = true;
    },
    toggleStatementValidationsNotificationsCompleted: (state) => {
      state.toggleStatementValidationsNotificationsIsPending = false;
    },
    /* Toggle Email Preferences */
    toggleEmailPreferences: (state) => {
      state.toggleEmailPreferencesIsPending = true;
    },
    toggleEmailPreferencesCompleted: (state) => {
      state.toggleEmailPreferencesIsPending = false;
    },
    /* Toggle Digest Notifications */
    toggleDigestNotifications: (state) => {
      state.toggleDigestNotificationsIsPending = true;
    },
    toggleDigestNotificationsCompleted: (state) => {
      state.toggleDigestNotificationsIsPending = false;
    },
    /* Get Seats Count */
    getSeatsCount: (state) => {
      state.getSeatsCountIsPending = true;
    },
    setCompanySeatsCount: (state, { payload }) => {
      const { companySeatsCount } = payload;
      state.profile.userData.company.seatsCount = companySeatsCount;
    },
    getSeatsCountCompleted: (state) => {
      state.getSeatsCountIsPending = false;
    },
    /* Set Should Display NPS */
    setShouldDisplayNPS: (state, { payload }) => {
      const { shouldDisplayNPS = false } = payload;
      const profileInState = state.profile !== null ? _cloneDeep(original(state.profile)) : {};
      profileInState.shouldDisplayNPS = shouldDisplayNPS;

      state.profile = profileInState;
    },
    /* Login */
    login: (state) => {
      state.loginIsPending = true;
    },
    loginCompleted: (state) => {
      state.loginIsPending = false;
    },
    /* Reset Password */
    resetPassword: (state) => {
      state.resetPasswordIsPending = true;
    },
    resetPasswordCompleted: (state) => {
      state.resetPasswordIsPending = false;
    },
    /* Forgot Password */
    forgotPassword: (state) => {
      state.forgotPasswordIsPending = true;
    },
    forgotPasswordCompleted: (state) => {
      state.forgotPasswordIsPending = false;
    },
    /* Connect As */
    connectAs: () => {},
    /* Acknowledge Terms And Conditions */
    acknowledgeTermsAndConditions: (state) => {
      state.acknowledgeTermsAndConditionsIsPending = true;
    },
    acknowledgeTermsAndConditionsCompleted: (state) => {
      state.acknowledgeTermsAndConditionsIsPending = false;
    },
    /* Get Aggregated FX Rates */
    getAggregatedFxRates: (state) => {
      state.getAggregatedFxRatesIsPending = true;
    },
    setAggregatedFxRates: (state, { payload }) => {
      const { companyAggregatedFxRates } = payload;
      state.profile.userData.company.aggregatedFxRates = companyAggregatedFxRates;
    },
    getAggregatedFxRatesCompleted: (state) => {
      state.getAggregatedFxRatesIsPending = false;
    },
    /* Create FX Rate */
    createFxRate: (state) => {
      state.createOrUpdateFxRateIsPending = true;
    },
    createFxRateCompleted: (state) => {
      state.createOrUpdateFxRateIsPending = false;
    },
    /* Get List FX Rates */
    getListFxRates: (state) => {
      state.getListFxRatesIsPending = true;
    },
    setListFxRates: (state, { payload }) => {
      const { listFxRates } = payload;
      state.profile.userData.company.listFxRates = listFxRates;
    },
    getListFxRatesCompleted: (state) => {
      state.getListFxRatesIsPending = false;
    },
    /* Delete FX Rate */
    deleteFxRate: () => {
      // Nothing to do here
    },
    deleteFxRateCompleted: () => {
      // Nothing to do here
    },
    /* Logout */
    logout: () => {
      // Nothing to do here
    },
    logoutSucceeded: () => {
      posthogClient.reset();
    },
    /* Get Billing and Subscription */
    getSubscription: (state) => {
      state.getSubscriptionIsPending = true;
    },
    setSubscription: (state, { payload }) => {
      const { subscription } = payload;
      state.profile.userData.company.subscription = subscription;
    },
    getSubscriptionCompleted: (state) => {
      state.getSubscriptionIsPending = false;
    },
    /* Update Subscription Seats */
    updateSubscriptionSeats: (state) => {
      state.updateSubscriptionSeatsIsPending = true;
    },
    updateSubscriptionSeatsCompleted: (state) => {
      state.updateSubscriptionSeatsIsPending = false;
    },
    /* Send Feedback */
    sendFeedback: (state) => {
      state.sendFeedbackIsPending = true;
    },
    sendFeedbackCompleted: (state) => {
      state.sendFeedbackIsPending = false;
    },
    /* Update Statement FX Rate Mode */
    updateStatementFxRateMode: (state) => {
      state.updateStatementFxRateModeIsPending = true;
    },
    updateStatementFxRateModeCompleted: (state) => {
      state.updateStatementFxRateModeIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const profile = (state) => root(state).profile;

const getProfileIsPending = (state) => root(state).getProfileIsPending;
const menuIsCollapsed = (state) => root(state).menuIsCollapsed;
const updateProfileIsPending = (state) => root(state).updateProfileIsPending;
const updateCompanyIsPending = (state) => root(state).updateCompanyIsPending;
const updateStatementStrategyIsPending = (state) => root(state).updateStatementStrategyIsPending;
const toggleStatementValidationsIsPending = (state) => root(state).toggleStatementValidationsIsPending;
const toggleStatementValidationsNotificationsIsPending = (state) => root(state).toggleStatementValidationsNotificationsIsPending;
const toggleEmailPreferencesIsPending = (state) => root(state).toggleEmailPreferencesIsPending;
const toggleDigestNotificationsIsPending = (state) => root(state).toggleDigestNotificationsIsPending;
const getSeatsCountIsPending = (state) => root(state).getSeatsCountIsPending;
const loginIsPending = (state) => root(state).loginIsPending;
const resetPasswordIsPending = (state) => root(state).resetPasswordIsPending;
const forgotPasswordIsPending = (state) => root(state).forgotPasswordIsPending;
const acknowledgeTermsAndConditionsIsPending = (state) => root(state).acknowledgeTermsAndConditionsIsPending;
const getAggregatedFxRatesIsPending = (state) => root(state).getAggregatedFxRatesIsPending;
const createOrUpdateFxRateIsPending = (state) => root(state).createOrUpdateFxRateIsPending;
const getListFxRatesIsPending = (state) => root(state).getListFxRatesIsPending;
const getSubscriptionIsPending = (state) => root(state).getSubscriptionIsPending;
const updateSubscriptionSeatsIsPending = (state) => root(state).updateSubscriptionSeatsIsPending;
const sendFeedbackIsPending = (state) => root(state).sendFeedbackIsPending;
const updateStatementFxRateModeIsPending = (state) => root(state).updateStatementFxRateModeIsPending;

export const selectors = {
  getProfileIsPending,
  updateProfileIsPending,
  updateCompanyIsPending,
  updateStatementStrategyIsPending,
  toggleStatementValidationsIsPending,
  toggleStatementValidationsNotificationsIsPending,
  toggleEmailPreferencesIsPending,
  toggleDigestNotificationsIsPending,
  getSeatsCountIsPending,
  loginIsPending,
  resetPasswordIsPending,
  forgotPasswordIsPending,
  acknowledgeTermsAndConditionsIsPending,
  getAggregatedFxRatesIsPending,
  createOrUpdateFxRateIsPending,
  getListFxRatesIsPending,
  getSubscriptionIsPending,
  updateSubscriptionSeatsIsPending,
  sendFeedbackIsPending,
  updateStatementFxRateModeIsPending,

  profile,
  menuIsCollapsed,
};
