import PropTypes from 'prop-types';

import { manageNumberAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'ProfileAccountInvalidCredentialsStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  remaining: PropTypes.number,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'ProfileAccountInvalidCredentialsContentStrategy',
      remaining: manageNumberAttribute(strategy, 'remaining'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
