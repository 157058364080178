import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* auditTrailsListByFilters(payload) {
  const {
    typeFilter = undefined,
    sinceFilter = undefined,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
  } = payload;

  const data = {
    page,
    limit,
  };

  if (typeFilter !== null) {
    data.entity = typeFilter;
  }

  if (sinceFilter !== null) {
    data.since = sinceFilter;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.auditTrail.list, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}
