import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import AmountDescriptionWidgetContent from '@palette/components/widgetContents/AmountDescriptionWidgetContent/AmountDescriptionWidgetContent';

import * as CommissionsTotalDataModel from '@palette/models/widgets/highlight/CommissionsTotalData';

import styles from './CommissionsTotalWidgetContent.less';

const classNames = bindClassNames.bind(styles);

const CommissionsTotalWidgetContent = ({ className, data }) => {
  const { t } = useTranslation();

  const dealsType = data.dealsCount > 1 ? pluralize(data.dealsType) : pluralize.singular(data.dealsType);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <AmountDescriptionWidgetContent
        amount={data.totalCommissionAmount}
        currency={data.currency}
        description={t('commissionsTotalWidgetContent.totalDeals', { total: data.dealsCount, dealsType })}
      />
    </div>
  );
};

CommissionsTotalWidgetContent.propTypes = {
  className: PropTypes.string,
  data: CommissionsTotalDataModel.propTypes.isRequired,
};

CommissionsTotalWidgetContent.defaultProps = {
  className: '',
};

export default CommissionsTotalWidgetContent;
