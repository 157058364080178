import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Avatar from '@palette/components/user/Avatar/Avatar';
import CommentMessageTypeFilled from '@palette/components/utils/Icons/CommentMessageTypeFilled';
import CommentEventTypeFilled from '@palette/components/utils/Icons/CommentEventTypeFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import InfoCircleLine from '@palette/components/utils/Icons/InfoCircleLine';

import { getMoment, noYearShortFormat } from '@palette/helpers/MomentHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';

import * as ThreadModel from '@palette/models/Thread';
import * as CommentModel from '@palette/models/Comment';

import { EVENT_CONTENT_ACTIONS, TYPES } from '@palette/constants/comments';

import styles from './ThreadComment.less';

const classNames = bindClassNames.bind(styles);

const ThreadComment = ({ className, thread, comment }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  let actionNode = null;
  if (comment.type === TYPES.MESSAGE) {
    actionNode = (
      <span className={styles.action}>
        {t('thread.action.commented')}
      </span>
    );
  } else {
    let actionContentNode = null;

    switch (comment.content.action) {
      case EVENT_CONTENT_ACTIONS.COMMISSION_CHANGED:
      case EVENT_CONTENT_ACTIONS.COMMISSION_UPDATED: {
        if (comment.content.amount !== null) {
          actionContentNode = (
            <>
              {t('threadComment.commission.changed')}
              &nbsp;
              <span className={styles.amount}>
                {formatPrice(comment.content.amount, thread.commission?.currency)}
              </span>
            </>
          );
        } else {
          actionContentNode = t('threadComment.commission.reset');
        }
        break;
      }
      case EVENT_CONTENT_ACTIONS.COMMISSION_VALUE_UPDATED: {
        const trackingObjectValueDefinition = getPlanValueDefinition(thread.commission?.scopeParams?.plan);
        if (comment.content.value !== null) {
          actionContentNode = (
            <>
              {t('threadComment.commission.dealValue.changed', { dealType: trackingObjectValueDefinition })}
              &nbsp;
              <span className={styles.amount}>
                {comaSeparatorFormatter(comment.content.value)}
              </span>
            </>
          );
        } else {
          actionContentNode = t('threadComment.commission.dealValue.reset', { dealType: trackingObjectValueDefinition });
        }
        break;
      }
      case EVENT_CONTENT_ACTIONS.PAYMENTS_UPDATED: {
        if (comment.content.payments) {
          const paymentsLabel = t('paymentsWithCount', { count: comment.content.payments.length });
          const contentNode = (
            <>
              {
                comment.content.payments.map((payment) => (
                  <div key={payment.date}>
                    {t('threadComment.payments.paymentOn', { percentage: parseInt(payment.percentage * 100, 10), date: moment.utc(payment.date).format('MM/YYYY') })}
                  </div>
                ))
              }
            </>
          );
          actionContentNode = (
            <>
              {t('threadComment.payments.changed')}
              &nbsp;
              <span className={styles.payments}>
                {paymentsLabel}
                <Tooltip
                  title={paymentsLabel}
                  content={contentNode}
                >
                  <InfoCircleLine className={styles.paymentsInfoIcon} />
                </Tooltip>
              </span>
            </>
          );
        } else {
          actionContentNode = t('threadComment.payments.reset');
        }
        break;
      }
      case EVENT_CONTENT_ACTIONS.STATUS_CHANGED:
      case EVENT_CONTENT_ACTIONS.STATUS_UPDATED:
      default: {
        actionContentNode = (
          <>
            {t('threadComment.status.changed')}
            &nbsp;
            <span
              className={classNames({
                status: true,
                [comment.content.status.toLowerCase()]: true,
              })}
            >
              {t(`thread.statuses.${comment.content.status.toLowerCase()}`)}
            </span>
          </>
        );
        break;
      }
    }

    actionNode = (
      <span className={styles.action}>
        {actionContentNode}
      </span>
    );
  }

  let contentNode = null;
  if (comment.type === TYPES.MESSAGE) {
    contentNode = (
      <div className={styles.content}>
        {comment.content}
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <div className={styles.avatarWrapper}>
          <Avatar size={30} user={comment.user} />
          <div className={styles.commentTypeIconWrapper}>
            {comment.type === TYPES.MESSAGE ? (<CommentMessageTypeFilled />) : (<CommentEventTypeFilled />)}
          </div>
        </div>
        <div className={styles.title}>
          <span className={styles.name}>
            {`${comment.user.firstName} ${comment.user.lastName.length > 0 ? `${comment.user.lastName.substring(0, 1)}.` : ''}`}
          </span>
          &nbsp;
          {actionNode}
        </div>
      </div>
      {contentNode}
      <div className={styles.footer}>
        {moment(comment.createdAt).format(`${noYearShortFormat()} · YYYY · HH:mm`)}
      </div>
    </div>
  );
};

ThreadComment.propTypes = {
  className: PropTypes.string,
  thread: ThreadModel.propTypes.isRequired,
  comment: CommentModel.propTypes.isRequired,
};

ThreadComment.defaultProps = {
  className: '',
};

export default ThreadComment;
