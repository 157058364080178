import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import UserVariableValueInput from '@palette/components/usersVariables/UserVariableValueInput/UserVariableValueInput';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { actions as UsersVariablesActions, selectors as UsersVariablesSelectors } from '@palette/state/UsersVariables';

import { getMoment, shortFormatWithShortYearAndTime } from '@palette/helpers/MomentHelper';

import { USER_VARIABLE_TYPES } from '@palette/constants/user';

import * as UserVariableModel from '@palette/models/UserVariable';
import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './EditVariableValueModal.less';

const EditVariableValueModal = ({
  visible,
  onClose,
  users,
  userVariable,
  currentValue,
  onEditSuccess,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();

  const editVariableIsPending = useSelector(UsersVariablesSelectors.editVariableIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    value: currentValue,
    date: moment(),
  }), []);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose, onEditSuccess]);

  const handleEditSuccess = useCallback(() => {
    cleanAndClose();
    if (onEditSuccess !== null) onEditSuccess();
  }, [cleanAndClose, onEditSuccess]);

  const handleFinish = useCallback(({ value, date }) => {
    dispatch(UsersVariablesActions.editVariable({ usersIds: users.map((user) => (user.id)), userVariableId: userVariable.id, value, date: moment.utc(date).format(), onSuccessCB: handleEditSuccess }));
  }, [users, userVariable, handleEditSuccess]);

  const handleEditVariable = useCallback(() => form.submit(), [form]);

  const subtitleNode = useMemo(() => {
    if (users.length > 1) {
      return t('editVariableValueModal.editVariableModalSubTitle.multiUsers', { count: users.length });
    }

    return t('editVariableValueModal.editVariableModalSubTitle.singleUser', { userDisplayName: users[0].displayName });
  }, [users]);

  return (
    <Modal
      className={styles.modal}
      blockHeight
      title={(
        <div className={styles.header}>
          <div className={styles.title}>
            {t('editVariableValueModal.editVariableModalTitle', { variableName: userVariable.name })}
          </div>
          <div className={styles.subTitle}>
            {subtitleNode}
          </div>
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleEditVariable}
      okText={t('editVariableValueModal.editVariable')}
      loading={editVariableIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="value"
          label={userVariable.type === USER_VARIABLE_TYPES.DATE ? t('editVariableValueModal.form.dateValue.label') : t('editVariableValueModal.form.value.label')}
          required
          rules={[
            { required: userVariable.type !== USER_VARIABLE_TYPES.ARRAY, message: t('editVariableValueModal.form.value.rules.required') },
          ]}
        >
          <UserVariableValueInput variableType={userVariable.type} size="big" disabled={editVariableIsPending} />
        </FormItem>
        <FormItem
          name="date"
          label={t('editVariableValueModal.form.date.label')}
          required
          rules={[
            { required: true, message: t('editVariableValueModal.form.date.rules.required') },
          ]}
        >
          <DatePicker
            className={styles.datePicker}
            picker="date"
            allowClear={false}
            size="big"
            disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
            showTime
            format={shortFormatWithShortYearAndTime}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

EditVariableValueModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  users: PropTypes.arrayOf(MetaUserModel.propTypes).isRequired,
  userVariable: UserVariableModel.propTypes.isRequired,
  currentValue: UserVariableModel.propTypesShape.defaultValue.isRequired,
  onEditSuccess: PropTypes.func,
};

EditVariableValueModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onEditSuccess: null,
};

export default EditVariableValueModal;
