/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';

import _map from 'lodash/map';
import _merge from 'lodash/merge';
import _keyBy from 'lodash/keyBy';
import _assign from 'lodash/assign';
import _cloneDeep from 'lodash/cloneDeep';
import _mergeWith from 'lodash/mergeWith';
import { original } from 'immer';

import { PLANS_PAGE_CONTENT_TABS_IDS, USER_PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import * as MasterPlanModel from '@palette/models/MasterPlan';

/*
 * Initial State
 */
const initialState = {
  getListIsPending: false,
  getListLastPeriodsIsPending: false,
  getByIdIsPending: false,
  getPlanPeriodsIsPending: false,
  getPlanDashboardIsPending: false,
  archiveByIdIsPending: false,
  deleteByIdIsPending: false,
  getPeriodByIsPending: false,
  getPeriodUsersIsPending: false,
  getPeriodDealsIsPending: false,
  createPlanIsPending: false,
  updatePlanIsPending: false,
  getPlanUsersAndManagersIsPending: false,
  updatePlanUserDatesIsPending: false,
  addUsersToPlanIsPending: false,
  removeUsersFromPlanIsPending: false,
  updatePlanUsersDefinitionIsPending: false,
  updatePlanTrackingObjectDefinitionIsPending: false,
  updatePlanRulesIsPending: false,
  updatePayoutRulesIsPending: false,
  getPlanDealObjectSampleIsPending: false,
  getPlanUserObjectSampleIsPending: false,
  duplicatePlanIsPending: false,
  computeAllIsPending: false,
  computePlanIsPending: false,
  getPlanListForUserIsPending: false,
  getPlanListLastPeriodsForUserIsPending: false,
  getICPeriodEstimationIsPending: false,
  getPeriodEstimationForUserIsPending: false,
  getPayoutScheduleIsPending: false,
  getPeriodDashboardIsPending: false,
  shouldRequestLastPeriods: false,

  listsByType: Object.values(PLANS_PAGE_CONTENT_TABS_IDS)
    .reduce((res, type) => ({
      ...res,
      [type]: [],
    }), {}),
  listStats: {},
  byId: {},
  planPeriods: {},
  planUsersAndManagers: {},
  planDashboard: {},
  periodsBy: {},
  periodUsers: {},
  periodManagers: {},
  periodDeals: {},
  periodDealsPagination: {},
  periodDashboard: {},
  planDealObjectSamples: {},
  planUserObjectSamples: {},
  planListForUser: {
    [USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE]: [],
    [USER_PLANS_PAGE_CONTENT_TABS_IDS.PAST]: [],
    [USER_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED]: [],
    stats: {},
  },
  icPeriodEstimations: {},
  periodEstimationsForUser: {},
  payoutSchedule: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'masterPlans',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* list */
    getList: (state) => {
      state.getListIsPending = true;
    },
    getICList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const {
        activePlans = [],
        pastPlans = [],
        draftPlans = [],
        archivedPlans = [],
        stats,
      } = payload;

      state.listsByType[PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE] = _map(activePlans, 'id');
      state.listsByType[PLANS_PAGE_CONTENT_TABS_IDS.PAST] = _map(pastPlans, 'id');
      state.listsByType[PLANS_PAGE_CONTENT_TABS_IDS.DRAFT] = _map(draftPlans, 'id');
      state.listsByType[PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED] = _map(archivedPlans, 'id');

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(activePlans.concat(pastPlans, draftPlans, archivedPlans), 'id'),
        MasterPlanModel.merge,
      );

      state.listStats = stats;
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Get list last periods */
    getListLastPeriods: (state) => {
      state.getListLastPeriodsIsPending = true;
    },
    getListICLastPeriods: (state) => {
      state.getListLastPeriodsIsPending = true;
    },
    setListLastPeriods: (state, { payload }) => {
      const { lastPeriods } = payload;

      lastPeriods.forEach((lastPeriod) => {
        state.byId[lastPeriod.planId].lastPeriod = lastPeriod;
      });
    },
    getListLastPeriodsCompleted: (state) => {
      state.getListLastPeriodsIsPending = false;
    },
    /* Get By Id */
    getById: (state) => {
      state.getByIdIsPending = true;
    },
    getICById: (state) => {
      state.getByIdIsPending = true;
    },
    setById: (state, { payload }) => {
      const { plan, updateLastPeriod = true } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      const inStatePlan = clonedById[plan.id] || null;

      clonedById[plan.id] = MasterPlanModel.merge(inStatePlan, plan);

      if (!updateLastPeriod) {
        clonedById[plan.id].lastPeriod = inStatePlan.lastPeriod;
      }

      state.byId = clonedById;
    },
    getByIdCompleted: (state) => {
      state.getByIdIsPending = false;
    },
    /* Get Plan Periods */
    getPlanPeriods: (state) => {
      state.getPlanPeriodsIsPending = true;
    },
    getICPlanPeriods: (state) => {
      state.getPlanPeriodsIsPending = true;
    },
    setPlanPeriods: (state, { payload }) => {
      const { planId, periods, callData } = payload;
      state.planPeriods = _assign(state.planPeriods, { [planId]: { periods, callData } });
    },
    cleanPlanPeriods: (state, { payload }) => {
      const { planId } = payload;
      const clonedPlanPeriods = _cloneDeep(original(state.planPeriods));
      delete clonedPlanPeriods[planId];
      state.planPeriods = clonedPlanPeriods;
    },
    getPlanPeriodsCompleted: (state) => {
      state.getPlanPeriodsIsPending = false;
    },
    /* Get Plan's Dashboard */
    getPlanDashboard: (state) => {
      state.getPlanDashboardIsPending = true;
    },
    getICPlanDashboard: (state) => {
      state.getPlanDashboardIsPending = true;
    },
    setPlanDashboard: (state, { payload }) => {
      const { planId, dashboard, callData } = payload;

      const updatedPlanDashboard = {
        [planId]: {
          dashboard,
          callData,
        },
      };
      state.planDashboard = _assign(_cloneDeep(original(state.planDashboard)), updatedPlanDashboard);
    },
    getPlanDashboardCompleted: (state) => {
      state.getPlanDashboardIsPending = false;
    },
    /* Archive Plan */
    archiveById: (state) => {
      state.archiveByIdIsPending = true;
    },
    archiveByIdCompleted: (state) => {
      state.archiveByIdIsPending = false;
      state.shouldRequestLastPeriods = true;
    },
    /* Delete Plan */
    deleteById: (state) => {
      state.deleteByIdIsPending = true;
    },
    deleteByIdCompleted: (state) => {
      state.deleteByIdIsPending = false;
      state.shouldRequestLastPeriods = true;
    },
    /* Reset shouldRequestLastPeriods */
    resetShouldRequestLastPeriods: (state) => {
      state.shouldRequestLastPeriods = false;
    },
    /* Get Period By */
    getPeriodBy: (state) => {
      state.getPeriodByIsPending = true;
    },
    getICPeriodBy: (state) => {
      state.getPeriodByIsPending = true;
    },
    setPeriodBy: (state, { payload }) => {
      const { planId, period } = payload;

      const updatedPeriodBy = {
        [planId]: {
          [period.year]: {
            [period.period]: period,
          },
        },
      };

      state.periodsBy = _merge(_cloneDeep(original(state.periodsBy)), updatedPeriodBy);
    },
    cleanPeriodByForPlan: (state, { payload }) => {
      const { planId } = payload;
      const clonedPeriodsBy = _cloneDeep(original(state.periodsBy));
      delete clonedPeriodsBy[planId];

      state.periodsBy = clonedPeriodsBy;
    },
    getPeriodByCompleted: (state) => {
      state.getPeriodByIsPending = false;
    },
    /* Get Period's Users */
    getPeriodUsers: (state) => {
      state.getPeriodUsersIsPending = true;
    },
    setPeriodUsers: (state, { payload }) => {
      const { planId, year, period, users } = payload;

      const updatedPeriodUsers = {
        [planId]: {
          [year]: {
            [period]: users,
          },
        },
      };

      const newPeriodUsers = _cloneDeep(original(state.periodUsers));
      if (newPeriodUsers[planId]?.[year]?.[period] !== undefined) {
        newPeriodUsers[planId][year][period] = [];
      }

      state.periodUsers = _merge(newPeriodUsers, updatedPeriodUsers);
    },
    cleanPeriodUsers: (state, { payload }) => {
      const { planId } = payload;
      const clonedPeriodUsers = _cloneDeep(original(state.periodUsers));
      delete clonedPeriodUsers[planId];

      state.periodUsers = clonedPeriodUsers;
    },
    setPeriodManagers: (state, { payload }) => {
      const { planId, year, period, managers } = payload;

      const updatedPeriodManagers = {
        [planId]: {
          [year]: {
            [period]: managers,
          },
        },
      };

      const newPeriodManagers = _cloneDeep(original(state.periodManagers));
      if (newPeriodManagers[planId]?.[year]?.[period] !== undefined) {
        newPeriodManagers[planId][year][period] = [];
      }

      state.periodManagers = _merge(newPeriodManagers, updatedPeriodManagers);
    },
    cleanPeriodManagers: (state, { payload }) => {
      const { planId } = payload;
      const clonedPeriodManagers = _cloneDeep(original(state.periodManagers));
      delete clonedPeriodManagers[planId];

      state.periodManagers = clonedPeriodManagers;
    },
    getPeriodUsersCompleted: (state) => {
      state.getPeriodUsersIsPending = false;
    },
    /* Get Period's Deals */
    getPeriodDeals: (state) => {
      state.getPeriodDealsIsPending = true;
    },
    getICPeriodDeals: (state) => {
      state.getPeriodDealsIsPending = true;
    },
    setPeriodDeals: (state, { payload }) => {
      const {
        planId,
        year,
        period,
        deals,
        pagination,
        callData,
      } = payload;

      const updatedPeriodDeals = {
        [planId]: {
          [year]: {
            [period]: {
              deals,
              callData,
            },
          },
        },
      };

      const updatedPeriodDealsPagination = {
        [planId]: {
          [year]: {
            [period]: pagination,
          },
        },
      };

      state.periodDeals = _assign(_cloneDeep(original(state.periodDeals)), updatedPeriodDeals);
      state.periodDealsPagination = _assign(_cloneDeep(original(state.periodDealsPagination)), updatedPeriodDealsPagination);
    },
    cleanPeriodDeals: (state, { payload }) => {
      const { planId } = payload;
      const clonedPeriodDeals = _cloneDeep(original(state.periodDeals));
      const clonedPeriodDealsPagination = _cloneDeep(original(state.periodDealsPagination));
      delete clonedPeriodDeals[planId];
      delete clonedPeriodDealsPagination[planId];

      state.periodDeals = clonedPeriodDeals;
      state.periodDealsPagination = clonedPeriodDealsPagination;
    },
    getPeriodDealsCompleted: (state) => {
      state.getPeriodDealsIsPending = false;
    },
    /* Download Period's Deals */
    downloadPeriodDeals: () => {
      // Nothing to do here
    },
    downloadPeriodDealsCompleted: () => {
      // Nothing to do here
    },
    /* Get Period's Dashboard */
    getPeriodDashboard: (state) => {
      state.getPeriodDashboardIsPending = true;
    },
    getICPeriodDashboard: (state) => {
      state.getPeriodDashboardIsPending = true;
    },
    setPeriodDashboard: (state, { payload }) => {
      const { planId, dashboard, callData } = payload;

      const updatedPeriodDashboard = {
        [planId]: {
          dashboard,
          callData,
        },
      };
      state.periodDashboard = _assign(_cloneDeep(original(state.periodDashboard)), updatedPeriodDashboard);
    },
    getPeriodDashboardCompleted: (state) => {
      state.getPeriodDashboardIsPending = false;
    },
    /* Create Plan */
    createPlan: (state) => {
      state.createPlanIsPending = true;
    },
    createPlanCompleted: (state) => {
      state.createPlanIsPending = false;
    },
    /* Update Plan */
    updatePlan: (state) => {
      state.updatePlanIsPending = true;
    },
    updatePlanCompleted: (state) => {
      state.updatePlanIsPending = false;
    },
    /* Get Plan's Users and Managers */
    getPlanUsersAndManagers: (state) => {
      state.getPlanUsersAndManagersIsPending = true;
    },
    setPlanUsersAndManagers: (state, { payload }) => {
      const { planId, users, managers } = payload;

      const updatedPlanUsersAndManagers = {
        [planId]: {
          users,
          managers,
        },
      };

      state.planUsersAndManagers = _assign(_cloneDeep(original(state.planUsersAndManagers)), updatedPlanUsersAndManagers);
    },
    getPlanUsersAndManagersCompleted: (state) => {
      state.getPlanUsersAndManagersIsPending = false;
    },
    /* Update Plan User's dates */
    updatePlanUserDates: (state) => {
      state.updatePlanUserDatesIsPending = true;
    },
    updatePlanUserDatesCompleted: (state) => {
      state.updatePlanUserDatesIsPending = false;
    },
    /* Add Users to Plan */
    addUsersToPlan: (state) => {
      state.addUsersToPlanIsPending = true;
    },
    addUsersToPlanCompleted: (state) => {
      state.addUsersToPlanIsPending = false;
    },
    /* Remove Users from Plan */
    removeUsersFromPlan: (state) => {
      state.removeUsersFromPlanIsPending = true;
    },
    removeUsersFromPlanCompleted: (state) => {
      state.removeUsersFromPlanIsPending = false;
    },
    /* Update Plan's Users Definition */
    updatePlanUsersDefinition: (state) => {
      state.updatePlanUsersDefinitionIsPending = true;
    },
    updatePlanUsersDefinitionCompleted: (state) => {
      state.updatePlanUsersDefinitionIsPending = false;
    },
    /* Update Plan's Tracking Object Definition */
    updatePlanTrackingObjectDefinition: (state) => {
      state.updatePlanTrackingObjectDefinitionIsPending = true;
    },
    updatePlanTrackingObjectDefinitionCompleted: (state) => {
      state.updatePlanTrackingObjectDefinitionIsPending = false;
    },
    /* Get Plan's Deal Object Sample */
    getPlanDealObjectSample: (state) => {
      state.getPlanDealObjectSampleIsPending = true;
    },
    setPlanDealObjectSample: (state, { payload }) => {
      const { planId, type, dealObjectSample } = payload;

      const updatedDealObjectSample = {
        [planId]: {
          [type]: dealObjectSample,
        },
      };

      state.planDealObjectSamples = _merge(_cloneDeep(original(state.planDealObjectSamples)), updatedDealObjectSample);
    },
    getPlanDealObjectSampleCompleted: (state) => {
      state.getPlanDealObjectSampleIsPending = false;
    },
    /* Get Plan's User Object Sample */
    getPlanUserObjectSample: (state) => {
      state.getPlanUserObjectSampleIsPending = true;
    },
    setPlanUserObjectSample: (state, { payload }) => {
      const { planId, type, userObjectSample } = payload;

      const updatedUserObjectSample = {
        [planId]: {
          [type]: userObjectSample,
        },
      };

      state.planUserObjectSamples = _merge(_cloneDeep(original(state.planUserObjectSamples)), updatedUserObjectSample);
    },
    getPlanUserObjectSampleCompleted: (state) => {
      state.getPlanUserObjectSampleIsPending = false;
    },
    /* Duplicate Plan */
    duplicatePlan: (state) => {
      state.duplicatePlanIsPending = true;
    },
    duplicatePlanCompleted: (state) => {
      state.duplicatePlanIsPending = false;
    },
    /* Update Plan Rules */
    updatePlanRules: (state) => {
      state.updatePlanRulesIsPending = true;
    },
    updatePlanRulesCompleted: (state) => {
      state.updatePlanRulesIsPending = false;
    },
    /* Update Payout Rules */
    updatePayoutRules: (state) => {
      state.updatePayoutRulesIsPending = true;
    },
    updatePayoutRulesCompleted: (state) => {
      state.updatePayoutRulesIsPending = false;
    },
    /* Compute all plans */
    computeAll: (state) => {
      state.computeAllIsPending = true;
    },
    computeAllCompleted: (state) => {
      state.computeAllIsPending = false;
    },
    /* Compute a plan */
    computePlan: (state) => {
      state.computePlanIsPending = true;
    },
    computeICPlan: (state) => {
      state.computePlanIsPending = true;
    },
    computePlanCompleted: (state) => {
      state.computePlanIsPending = false;
    },
    /* Get Plan List For Generic User */
    getPlanListForUser: (state) => {
      state.getPlanListForUserIsPending = true;
    },
    setPlanListForUser: (state, { payload }) => {
      const { activePlans = [], pastPlans = [], archivedPlans = [], stats } = payload;

      state.planListForUser[USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE] = _map(activePlans, 'id');
      state.planListForUser[USER_PLANS_PAGE_CONTENT_TABS_IDS.PAST] = _map(pastPlans, 'id');
      state.planListForUser[USER_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED] = _map(archivedPlans, 'id');

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(activePlans.concat(pastPlans, archivedPlans), 'id'),
        MasterPlanModel.merge,
      );

      state.planListForUser.stats = stats;
    },
    getPlanListForUserCompleted: (state) => {
      state.getPlanListForUserIsPending = false;
    },
    /* Get Plan list last periods For Generic User */
    getPlanListLastPeriodsForUser: (state) => {
      state.getPlanListLastPeriodsForUserIsPending = true;
    },
    setPlanListLastPeriodsForUser: (state, { payload }) => {
      const { lastPeriods } = payload;

      lastPeriods.forEach((lastPeriod) => {
        if (state.byId[lastPeriod.planId] != null) {
          state.byId[lastPeriod.planId].lastPeriod = lastPeriod;
        }
      });
    },
    getPlanListLastPeriodsForUserCompleted: (state) => {
      state.getPlanListLastPeriodsForUserIsPending = false;
    },
    /* Get IC Plan Period's Estimation */
    getICPeriodEstimation: (state) => {
      state.getICPeriodEstimationIsPending = true;
    },
    setICPeriodEstimation: (state, { payload }) => {
      const {
        planId,
        year,
        period,
        estimation,
      } = payload;

      const updatedPeriodEstimation = {
        [planId]: {
          [year]: {
            [period]: estimation,
          },
        },
      };

      state.icPeriodEstimations = _assign(_cloneDeep(original(state.icPeriodEstimations)), updatedPeriodEstimation);
    },
    getICPeriodEstimationCompleted: (state) => {
      state.getICPeriodEstimationIsPending = false;
    },
    /* Get Plan Period's Estimation For User */
    getPeriodEstimationForUser: (state) => {
      state.getPeriodEstimationForUserIsPending = true;
    },
    setPeriodEstimationForUser: (state, { payload }) => {
      const {
        planId,
        userId,
        year,
        period,
        estimation,
      } = payload;

      const updatedPeriodEstimation = {
        [planId]: {
          [userId]: {
            [year]: {
              [period]: estimation,
            },
          },
        },
      };

      state.periodEstimationsForUser = _merge(_cloneDeep(original(state.periodEstimationsForUser)), updatedPeriodEstimation);
    },
    getPeriodEstimationForUserCompleted: (state) => {
      state.getPeriodEstimationForUserIsPending = false;
    },
    /* Get Plan's Payout Schedule */
    getPayoutSchedule: (state) => {
      state.getPayoutScheduleIsPending = true;
    },
    setPayoutSchedule: (state, { payload }) => {
      const { planId, amountPerPeriod } = payload;

      const updatedPayoutSchedule = {
        [planId]: amountPerPeriod,
      };

      state.payoutSchedule = _assign(_cloneDeep(original(state.payoutSchedule)), updatedPayoutSchedule);
    },
    getPayoutScheduleCompleted: (state) => {
      state.getPayoutScheduleIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getListsByType = (state) => root(state).listsByType;
const getListStats = (state) => root(state).listStats;
const getById = (state) => root(state).byId;
const getICById = (state) => root(state).byId;
const getPlanPeriods = (state) => root(state).planPeriods;
const getICPlanPeriods = (state) => root(state).planPeriods;
const getPlanUsersAndManagers = (state) => root(state).planUsersAndManagers;
const getPlanDashboard = (state) => root(state).planDashboard;
const getPeriodsBy = (state) => root(state).periodsBy;
const getICPeriodsBy = (state) => root(state).periodsBy;
const getPeriodUsers = (state) => root(state).periodUsers;
const getPeriodManagers = (state) => root(state).periodManagers;
const getPeriodDeals = (state) => root(state).periodDeals;
const getICPeriodDeals = (state) => root(state).periodDeals;
const getPeriodDealsPagination = (state) => root(state).periodDealsPagination;
const getPeriodDashboard = (state) => root(state).periodDashboard;
const getPlanDealObjectSamples = (state) => root(state).planDealObjectSamples;
const getPlanUserObjectSamples = (state) => root(state).planUserObjectSamples;
const getPlanListForUser = (state) => root(state).planListForUser;
const getICPeriodEstimations = (state) => root(state).icPeriodEstimations;
const getPeriodEstimationsForUser = (state) => root(state).periodEstimationsForUser;
const getPayoutSchedule = (state) => root(state).payoutSchedule;

const getListIsPending = (state) => root(state).getListIsPending;
const getListLastPeriodsIsPending = (state) => root(state).getListLastPeriodsIsPending;
const getByIdIsPending = (state) => root(state).getByIdIsPending;
const getPlanPeriodsIsPending = (state) => root(state).getPlanPeriodsIsPending;
const getPlanDashboardIsPending = (state) => root(state).getPlanDashboardIsPending;
const archiveByIdIsPending = (state) => root(state).archiveByIdIsPending;
const deleteByIdIsPending = (state) => root(state).deleteByIdIsPending;
const getPeriodByIsPending = (state) => root(state).getPeriodByIsPending;
const getPeriodUsersIsPending = (state) => root(state).getPeriodUsersIsPending;
const getPeriodDealsIsPending = (state) => root(state).getPeriodDealsIsPending;
const createPlanIsPending = (state) => root(state).createPlanIsPending;
const updatePlanIsPending = (state) => root(state).updatePlanIsPending;
const getPlanUsersAndManagersIsPending = (state) => root(state).getPlanUsersAndManagersIsPending;
const updatePlanUserDatesIsPending = (state) => root(state).updatePlanUserDatesIsPending;
const addUsersToPlanIsPending = (state) => root(state).addUsersToPlanIsPending;
const removeUsersFromPlanIsPending = (state) => root(state).removeUsersFromPlanIsPending;
const updatePlanUsersDefinitionIsPending = (state) => root(state).updatePlanUsersDefinitionIsPending;
const updatePlanTrackingObjectDefinitionIsPending = (state) => root(state).updatePlanTrackingObjectDefinitionIsPending;
const getPlanDealObjectSampleIsPending = (state) => root(state).getPlanDealObjectSampleIsPending;
const getPlanUserObjectSampleIsPending = (state) => root(state).getPlanUserObjectSampleIsPending;
const duplicatePlanIsPending = (state) => root(state).duplicatePlanIsPending;
const updatePlanRulesIsPending = (state) => root(state).updatePlanRulesIsPending;
const updatePayoutRulesIsPending = (state) => root(state).updatePayoutRulesIsPending;
const computeAllIsPending = (state) => root(state).computeAllIsPending;
const computePlanIsPending = (state) => root(state).computePlanIsPending;
const getPlanListForUserIsPending = (state) => root(state).getPlanListForUserIsPending;
const getPlanListLastPeriodsForUserIsPending = (state) => root(state).getPlanListLastPeriodsForUserIsPending;
const getICPeriodEstimationIsPending = (state) => root(state).getICPeriodEstimationIsPending;
const getPeriodEstimationForUserIsPending = (state) => root(state).getPeriodEstimationForUserIsPending;
const payoutScheduleIsPending = (state) => root(state).getPayoutScheduleIsPending;
const getPeriodDashboardIsPending = (state) => root(state).getPeriodDashboardIsPending;
const shouldRequestLastPeriods = (state) => root(state).shouldRequestLastPeriods;

const getPlansList = createSelector(
  [getListsByType, getById],
  (listsByType, byId) => {
    const allPlansIds = Object.keys(listsByType).reduce((allIds, listsByTypeKey) => (allIds.concat(listsByType[listsByTypeKey])), []);
    return allPlansIds.map((planId) => byId[planId] || null).filter(Boolean);
  },
);

const getPlansListByType = createSelector(
  [getListsByType, getById, getProps],
  (listsByType, byId, { listType = PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE }) => listsByType[listType].map((planId) => byId[planId] || null).filter(Boolean),
);

const getMasterPlanById = createSelector(
  [getById, getProps],
  (byId, { masterPlanId }) => byId[masterPlanId] || null,
);

const getICMasterPlanById = createSelector(
  [getICById, getProps],
  (byId, { masterPlanId }) => byId[masterPlanId] || null,
);

const getMasterPlanPeriods = createSelector(
  [getPlanPeriods, getProps],
  (planPeriods, { masterPlanId }) => planPeriods[masterPlanId]?.periods || [],
);

const getICMasterPlanPeriods = createSelector(
  [getICPlanPeriods, getProps],
  (planPeriods, { masterPlanId }) => planPeriods[masterPlanId]?.periods || [],
);

const getMasterPlanDashboard = createSelector(
  [getPlanDashboard, getProps],
  (planDashboard, { masterPlanId }) => planDashboard[masterPlanId]?.dashboard || [],
);

const getMasterPlanPeriodBy = createSelector(
  [getPeriodsBy, getProps],
  (periodsBy, { masterPlanId, year, periodId }) => periodsBy[masterPlanId]?.[year]?.[periodId] || null,
);

const getICMasterPlanPeriodBy = createSelector(
  [getICPeriodsBy, getProps],
  (periodsBy, { masterPlanId, year, periodId }) => periodsBy[masterPlanId]?.[year]?.[periodId] || null,
);

const getMasterPlanPeriodUsers = createSelector(
  [getPeriodUsers, getProps],
  (periodUsers, { masterPlanId, year, periodId }) => periodUsers[masterPlanId]?.[year]?.[periodId] || [],
);

const getMasterPlanPeriodManagers = createSelector(
  [getPeriodManagers, getProps],
  (periodManagers, { masterPlanId, year, periodId }) => periodManagers[masterPlanId]?.[year]?.[periodId] || [],
);

const getMasterPlanPeriodDeals = createSelector(
  [getPeriodDeals, getProps],
  (periodDeals, { masterPlanId, year, periodId }) => periodDeals[masterPlanId]?.[year]?.[periodId]?.deals || [],
);

const getICMasterPlanPeriodDeals = createSelector(
  [getICPeriodDeals, getProps],
  (periodDeals, { masterPlanId, year, periodId }) => periodDeals[masterPlanId]?.[year]?.[periodId]?.deals || [],
);

const getMasterPlanPeriodDealsPagination = createSelector(
  [getPeriodDealsPagination, getProps],
  (periodDealsPagination, { masterPlanId, year, periodId }) => periodDealsPagination[masterPlanId]?.[year]?.[periodId] || null,
);

const getMasterPlanPeriodDashboard = createSelector(
  [getPeriodDashboard, getProps],
  (periodDashboard, { masterPlanId }) => periodDashboard[masterPlanId]?.dashboard || [],
);

const getMasterPlanUsersAndManagers = createSelector(
  [getPlanUsersAndManagers, getProps],
  (planUsersAndManagers, { masterPlanId }) => planUsersAndManagers[masterPlanId] || { users: [], managers: [] },
);

const getMasterPlanDealObjectSample = createSelector(
  [getPlanDealObjectSamples, getProps],
  (planDealObjectSamples, { masterPlanId, type }) => planDealObjectSamples[masterPlanId]?.[type] || null,
);

const getMasterPlanUserObjectSample = createSelector(
  [getPlanUserObjectSamples, getProps],
  (planUserObjectSamples, { masterPlanId, type }) => planUserObjectSamples[masterPlanId]?.[type] || null,
);

const getMasterPlanListForUser = createSelector(
  [getPlanListForUser, getById],
  (planListForUser, byId) => ({
    ...planListForUser,
    [USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE]: planListForUser[USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE].map((planId) => byId[planId] || null).filter(Boolean),
    [USER_PLANS_PAGE_CONTENT_TABS_IDS.PAST]: planListForUser[USER_PLANS_PAGE_CONTENT_TABS_IDS.PAST].map((planId) => byId[planId] || null).filter(Boolean),
    [USER_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED]: planListForUser[USER_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED].map((planId) => byId[planId] || null).filter(Boolean),
  }),
);

const getICPlanPeriodEstimation = createSelector(
  [getICPeriodEstimations, getProps],
  (icPeriodEstimations, { masterPlanId, year, periodId }) => icPeriodEstimations[masterPlanId]?.[year]?.[periodId] || null,
);

const getPlanPeriodEstimationForUser = createSelector(
  [getPeriodEstimationsForUser, getProps],
  (periodEstimationsForUser, { masterPlanId, year, periodId, userId }) => periodEstimationsForUser[masterPlanId]?.[userId || masterPlanId]?.[year]?.[periodId] || null,
);

const getMasterPlanPayoutSchedule = createSelector(
  [getPayoutSchedule, getProps],
  (payoutSchedule, { masterPlanId }) => payoutSchedule[masterPlanId] || null,
);

export const selectors = {
  getListIsPending,
  getListLastPeriodsIsPending,
  getByIdIsPending,
  getPlanPeriodsIsPending,
  getPlanDashboardIsPending,
  archiveByIdIsPending,
  deleteByIdIsPending,
  getPeriodByIsPending,
  getPeriodUsersIsPending,
  getPeriodDealsIsPending,
  createPlanIsPending,
  updatePlanIsPending,
  getPlanUsersAndManagersIsPending,
  updatePlanUserDatesIsPending,
  addUsersToPlanIsPending,
  removeUsersFromPlanIsPending,
  updatePlanUsersDefinitionIsPending,
  updatePlanTrackingObjectDefinitionIsPending,
  getPlanDealObjectSampleIsPending,
  getPlanUserObjectSampleIsPending,
  duplicatePlanIsPending,
  updatePlanRulesIsPending,
  updatePayoutRulesIsPending,
  computeAllIsPending,
  computePlanIsPending,
  getPlanListForUserIsPending,
  getPlanListLastPeriodsForUserIsPending,
  getICPeriodEstimationIsPending,
  getPeriodEstimationForUserIsPending,
  payoutScheduleIsPending,
  getPeriodDashboardIsPending,
  shouldRequestLastPeriods,

  getListStats,
  getPlansList,
  getPlansListByType,
  getMasterPlanById,
  getICMasterPlanById,
  getMasterPlanPeriods,
  getICMasterPlanPeriods,
  getMasterPlanDashboard,
  getMasterPlanPeriodBy,
  getICMasterPlanPeriodBy,
  getMasterPlanPeriodUsers,
  getMasterPlanPeriodManagers,
  getMasterPlanPeriodDeals,
  getICMasterPlanPeriodDeals,
  getMasterPlanPeriodDealsPagination,
  getMasterPlanPeriodDashboard,
  getMasterPlanUsersAndManagers,
  getMasterPlanDealObjectSample,
  getMasterPlanUserObjectSample,
  getMasterPlanListForUser,
  getICPlanPeriodEstimation,
  getPlanPeriodEstimationForUser,
  getMasterPlanPayoutSchedule,

  // Export following selectors only for saga "updatePlanDataInState" and saga "computeICPlanSuccess"
  getPlanPeriods,
  getPlanDashboard,
  getPlanUsersAndManagers,
  getPeriodsBy,
  getPeriodUsers,
  getPeriodManagers,
  getPeriodDeals,
  getPeriodDashboard,
};
