import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Switch from '@palette/components/designSystem/Switch/Switch';
import Button from '@palette/components/designSystem/Button/Button';
import Select from '@palette/components/designSystem/Select/Select';

import { useCompanyRights } from '@palette/hooks/CompanyHooks';

import {
  RIGHTS,
  ROLE_MANAGEMENT_ADMIN_SECTIONS,
  ROLE_MANAGEMENT_IC_SECTIONS,
  ROLE_SCOPE_RIGHTS_SELECT_OPTIONS,
} from '@palette/constants/profile';

import * as RoleModel from '@palette/models/Role';

import styles from './RoleRights.less';

const classNames = bindClassNames.bind(styles);

const RoleRights = ({ className, role, onUpdate }) => {
  const { t } = useTranslation();
  const companyRights = useCompanyRights();

  const [rights, setRights] = useState(role.rights);

  useEffect(() => {
    setRights(role.rights);
  }, [role.rights]);

  const disableEdition = useMemo(() => (
    role.isProtected
  ), [role]);

  const hasAdminWildcardRight = useMemo(() => (
    rights[RIGHTS.ADMIN.WILDCARD] === true
  ), [rights]);

  const displayRight = useCallback((right) => (
    companyRights.includes(right)
  ), [companyRights]);

  const filterSection = useCallback((section) => {
    const sectionRights = section.rights.filter((right) => displayRight(right));

    if (sectionRights.length === 0) return null;

    return {
      ...section,
      rights: sectionRights,
    };
  }, [displayRight]);

  const rightIsSelected = useCallback((right) => (
    rights[right] === true
  ), [rights]);

  const handleAdminWildcardRightCheckChange = useCallback((checked) => {
    if (checked) {
      setRights({ [RIGHTS.ADMIN.WILDCARD]: true });
    } else if (role.rights[RIGHTS.ADMIN.WILDCARD] === true) {
      setRights([]);
    } else {
      setRights(role.rights);
    }
  }, [role]);

  const handleAdminScopeRightChange = useCallback((newValue) => {
    const newRights = { ...rights };

    newRights[RIGHTS.ADMIN.TEAMS.VIEW_SCOPED] = RIGHTS.ADMIN.TEAMS.VIEW_SCOPED === newValue;
    newRights[RIGHTS.ADMIN.TEAMS.VIEW_ALL] = RIGHTS.ADMIN.TEAMS.VIEW_ALL === newValue;

    setRights(newRights);
  }, [rights]);

  const handleRightCheckChange = useCallback((right, checked) => {
    const newRights = { ...rights };
    newRights[right] = checked;
    setRights(newRights);
  }, [rights]);

  const buildSections = useCallback((sections) => (
    sections.map((section) => (
      <div className={styles.section} key={section.i18nTitle}>
        <div className={styles.sectionTitle}>
          {t(section.i18nTitle)}
        </div>
        <div className={styles.rights}>
          {section.rights.map((right) => (
            <div key={right} className={styles.right}>
              <Switch
                checked={rightIsSelected(right)}
                onChange={(checked) => handleRightCheckChange(right, checked)}
                disabled={disableEdition}
              />
              {/* eslint-disable-next-line react/no-danger */}
              <div className={styles.rightDescription} dangerouslySetInnerHTML={{ __html: t(`roleManagement.rights.descriptions.${right}`) }} />
            </div>
          ))}
        </div>
      </div>
    ))
  ), [rightIsSelected, handleRightCheckChange, disableEdition]);

  const hasFullAccessNode = useMemo(() => (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        {t('roleManagement.sections.admin.wildcard')}
      </div>
      <div className={styles.rights}>
        <div className={styles.right}>
          <Switch
            checked={hasAdminWildcardRight}
            onChange={handleAdminWildcardRightCheckChange}
            disabled={disableEdition}
          />
          <div className={styles.rightDescription}>
            {t('roleManagement.rights.descriptions.admin.wildcard')}
          </div>
        </div>
      </div>
    </div>
  ), [hasAdminWildcardRight, handleAdminWildcardRightCheckChange, disableEdition]);

  const scopeRightValue = useMemo(() => {
    if (rights[RIGHTS.ADMIN.TEAMS.VIEW_SCOPED] === true) return RIGHTS.ADMIN.TEAMS.VIEW_SCOPED;

    if (rights[RIGHTS.ADMIN.TEAMS.VIEW_ALL] === true) return RIGHTS.ADMIN.TEAMS.VIEW_ALL;

    return null;
  }, [rights]);

  const scopeSelectNode = useMemo(() => {
    const scopeOptions = ROLE_SCOPE_RIGHTS_SELECT_OPTIONS.map((option) => ({
      label: t(option.i18nLabel),
      value: option.value,
    }));

    return (
      <Select
        className={styles.scopeSelect}
        options={scopeOptions}
        value={scopeRightValue}
        onChange={handleAdminScopeRightChange}
        disabled={disableEdition}
      />
    );
  }, [scopeRightValue, handleAdminScopeRightChange, disableEdition]);

  const adminSectionsNode = useMemo(() => {
    if (hasAdminWildcardRight) return null;

    const adminSections = ROLE_MANAGEMENT_ADMIN_SECTIONS.map((section) => filterSection(section)).filter(Boolean);

    if (adminSections.length === 0) return null;

    const sectionsNodes = buildSections(adminSections);

    return (
      <div className={styles.adminRights}>
        <div className={styles.adminRightsLabel}>
          {t('roleManagement.adminRightsLabel')}
        </div>
        <div className={styles.adminRightsSections}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              {t('roleManagement.sections.admin.scope')}
            </div>
            <div className={styles.rights}>
              <div className={styles.right}>
                {scopeSelectNode}
              </div>
            </div>
          </div>
          {sectionsNodes}
        </div>
      </div>
    );
  }, [hasAdminWildcardRight, filterSection, buildSections, scopeSelectNode]);

  const icSectionsNode = useMemo(() => {
    if (hasAdminWildcardRight) return null;

    const icSections = ROLE_MANAGEMENT_IC_SECTIONS.map((section) => filterSection(section)).filter(Boolean);

    if (icSections.length === 0) return null;

    const sectionsNodes = buildSections(icSections);

    return (
      <div className={styles.icRights}>
        <div className={styles.icRightsLabel}>
          {t('roleManagement.icRightsLabel')}
        </div>
        <div className={styles.icRightsSections}>
          {sectionsNodes}
        </div>
      </div>
    );
  }, [hasAdminWildcardRight, filterSection, buildSections]);

  const isPristine = useMemo(() => {
    const rightsKeys = Object.keys(rights);
    const roleRightsKeys = Object.keys(role.rights);

    return (
      rightsKeys.length === roleRightsKeys.length
      && rightsKeys.every((rightKey) => (rights[rightKey] === role.rights[rightKey]))
    );
  }, [role, rights]);

  const handleUpdateRights = useCallback(() => {
    onUpdate(rights);
  }, [rights, onUpdate]);

  const saveButtonNode = useMemo(() => {
    if (disableEdition) return null;

    return (
      <div className={styles.saveButtonWrapper}>
        <Button className={styles.saveButton} onClick={handleUpdateRights} disabled={isPristine}>
          {t('roleManagement.saveButtonLabel')}
        </Button>
      </div>
    );
  }, [disableEdition, handleUpdateRights, isPristine]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {hasFullAccessNode}
      {adminSectionsNode}
      {icSectionsNode}
      {saveButtonNode}
    </div>
  );
};

RoleRights.propTypes = {
  className: PropTypes.string,
  role: RoleModel.propTypes.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

RoleRights.defaultProps = {
  className: '',
};

export default RoleRights;
