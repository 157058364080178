import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementV3ApprovalsList from '@palette/components/statementV3/StatementV3ApprovalsList/StatementV3ApprovalsList';
import StatementV3ApproveAction from '@palette/components/statementV3/StatementV3ApproveAction/StatementV3ApproveAction';
import StatementV3MarkAsPaidAction from '@palette/components/statementV3/StatementV3MarkAsPaidAction/StatementV3MarkAsPaidAction';

import { getPeriodName } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';

import styles from './StatementV3UserStatementInfosAndActions.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementInfosAndActions = ({ className, statement, currency }) => {
  const periodName = getPeriodName({ type: statement.periodicity }, statement.period, statement.year);
  const statementAmount = formatPrice(statement.amount, statement.currency);
  const approvals = statement.actions.filter((action) => action.type === STATEMENT_ACTION_TYPES.APPROVAL);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infos}>
        <div className={styles.periodName}>{periodName}</div>
        <div className={styles.statementAmount}>{statementAmount}</div>
      </div>
      <div className={styles.actions}>
        <StatementV3ApprovalsList
          className={styles.approvalsListWrapper}
          approvals={approvals}
          statementAmount={statement.amount}
          statementCurrency={statement.currency}
          limitShown={6}
        />
        <div className={styles.buttonsWrapper}>
          <StatementV3ApproveAction
            className={styles.actionButton}
            userStatement={statement}
            currency={currency}
            userId={statement.userId}
          />
          <StatementV3MarkAsPaidAction
            className={styles.actionButton}
            userStatement={statement}
            currency={currency}
            userId={statement.userId}
          />
        </div>
      </div>
    </div>
  );
};

StatementV3UserStatementInfosAndActions.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementV3UserStatementInfosAndActions.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3UserStatementInfosAndActions;
