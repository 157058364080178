import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import styles from './CommissionV3DealValue.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3DealValue = ({ className, commission }) => {
  const { t } = useTranslation();

  const dealValue = Math.round(commission.dealAmount * 100) / 100;

  const splittedFormattedValue = comaSeparatorFormatter(dealValue).split('.');
  const valuesNodes = [];

  valuesNodes.push((
    <div key="integerPart" className={styles.integerPart}>
      {splittedFormattedValue[0]}
    </div>
  ));

  if (splittedFormattedValue.length > 1) {
    valuesNodes.push((
      <div key="decimalPart" className={styles.decimalPart}>
        {`.${splittedFormattedValue[1]}`}
      </div>
    ));
  }

  const valueNode = (
    <div className={styles.value}>
      {valuesNodes}
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.labelWrapper}>
        {t('commissionDetails.deal')}
      </div>
      <div className={styles.valueWrapper}>
        {valueNode}
      </div>
    </div>
  );
};

CommissionV3DealValue.propTypes = {
  className: PropTypes.string,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
};

CommissionV3DealValue.defaultProps = {
  className: '',
};

export default CommissionV3DealValue;
