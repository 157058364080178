import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import pluralize from 'pluralize';
import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Button from '@palette/components/designSystem/Button/Button';
import FormulaHelper from '@palette/components/formula/FormulaHelper/FormulaHelper';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import UsersDefinitionConnectorsSelect from '@palette/components/masterPlanSettings/UsersDefinitionConnectorsSelect/UsersDefinitionConnectorsSelect';
import Input from '@palette/components/designSystem/Input/Input';

import { usePlanUserObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { FORMULA_INPUT_TYPES, SUGGESTIONS_TYPES } from '@palette/constants/formula';
import { MATCHING_FIELDS } from '@palette/constants/connector';
import { EMPTY_DEFAULT_VALUE } from '@palette/constants/usersDefinition';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './MasterPlanUsersDefinitionForm.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanUsersDefinitionForm = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updatePlanUsersDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanUsersDefinitionIsPending);

  const initialConnectorIdType = useMemo(() => {
    if (plan.usersDefinition?.originalType === EMPTY_DEFAULT_VALUE) return null;

    return {
      connectorId: plan.usersDefinition?.connectorId,
      type: plan.usersDefinition?.originalType,
    };
  }, [plan]);
  const initialUiType = useMemo(() => (plan.usersDefinition?.uiType), [plan]);
  const initialMatchingField = useMemo(() => (plan.usersDefinition?.matchingField), [plan]);

  const [connectorIdTypeValue, setConnectorIdTypeValue] = useState(initialConnectorIdType);
  const [uiTypeValue, setUiTypeValue] = useState(initialUiType);

  const [displayRenameTypeFormItem, showRenameTypeFormItem] = useState(initialUiType !== null);

  const userObjectSample = usePlanUserObjectSample(plan, connectorIdTypeValue);
  const formulaHelperData = useAdditionalProperties(uiTypeValue || connectorIdTypeValue?.type, userObjectSample);

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: connectorIdTypeValue?.connectorId || plan.usersDefinition?.connectorId || null }));

  const [form] = AntDForm.useForm();
  const initialValues = useMemo(() => ({
    connectorIdType: initialConnectorIdType,
    uiType: initialUiType,
    matchingField: initialMatchingField,
  }), [
    initialConnectorIdType,
    initialUiType,
    initialMatchingField,
  ]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setConnectorIdTypeValue(initialValues.connectorIdType);
    setUiTypeValue(initialValues.uiType);
    showRenameTypeFormItem(initialValues.uiType !== null);
    setFormValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const getFormUpdatedData = useCallback((values) => {
    if (values.connectorIdType === null || initialValues.connectorIdType === null) return [{}, {}, {}];

    const { connectorId, type } = values.connectorIdType;
    const finalValues = { ...values, connectorId, type, uiType: uiTypeValue };
    delete finalValues.connectorIdType;

    const { connectorId: initialConnectorId, type: initialType } = initialValues.connectorIdType;
    const finalInitialValues = { ...initialValues, connectorId: initialConnectorId, type: initialType };
    delete finalInitialValues.connectorIdType;

    const updatedData = Object.keys(finalValues).reduce((data, valKey) => {
      if (!_isEqual(finalInitialValues[valKey], finalValues[valKey])) {
        return {
          ...data,
          [valKey]: finalValues[valKey],
        };
      }

      return data;
    }, {});

    return [updatedData, finalValues, finalInitialValues];
  }, [uiTypeValue, initialValues]);

  const formIsPristine = useMemo(() => {
    const [updatedData] = getFormUpdatedData(formValues);

    return Object.keys(updatedData).length === 0;
  }, [formValues, getFormUpdatedData]);

  const handleConnectorIdTypeValueChange = (newConnectorIdType) => {
    setConnectorIdTypeValue(newConnectorIdType);

    if (initialConnectorIdType === null) {
      dispatch(MasterPlansActions.updatePlanUsersDefinition({
        planId: plan.id,
        connectorId: newConnectorIdType.connectorId,
        type: newConnectorIdType.type,
        disableSuccessNotification: true,
      }));
    }
  };

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    if (changedValues.connectorIdType !== undefined) {
      handleConnectorIdTypeValueChange(changedValues.connectorIdType);
    }

    if (changedValues.uiType !== undefined) {
      setUiTypeValue(changedValues.uiType);
    }

    setFormValues(allValues);
  }, []);

  const handleFinish = useCallback((values) => {
    const [updatedData] = getFormUpdatedData(values);

    if (Object.keys(updatedData).length > 0) {
      dispatch(MasterPlansActions.updatePlanUsersDefinition({ planId: plan.id, ...updatedData }));
    }
  }, [plan, getFormUpdatedData]);

  const handleUpdatePlanUsersDefinition = () => form.submit();

  const matchingFieldTopFields = useMemo(() => {
    if (!connector || !connectorIdTypeValue) return [];

    return MATCHING_FIELDS[`${connector.type}_${connectorIdTypeValue.type}`] || [];
  }, [connector, connectorIdTypeValue]);

  const resetUIType = useCallback(() => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      uiType: null,
    });

    setUiTypeValue(null);
    showRenameTypeFormItem(false);
  }, [
    form,
    setUiTypeValue,
    showRenameTypeFormItem,
  ]);

  const connectorIdTypeRenameNode = useMemo(() => {
    if (!connector || !connectorIdTypeValue) return null;

    if (displayRenameTypeFormItem) {
      return (
        <div className={styles.connectorIdTypeWrapper}>
          <FormItem
            className={styles.connectorIdTypeInput}
            name="uiType"
            label={t('masterPlanUsersDefinitionForm.form.uiType.label', { userType: pluralize.singular(connectorIdTypeValue.type || '') })}
          >
            <Input size="big" placeholder={t('masterPlanUsersDefinitionForm.form.uiType.placeholder')} disabled={updatePlanUsersDefinitionIsPending} />
          </FormItem>
          <Button className={styles.renameTypeBtn} type="link" onClick={resetUIType} disabled={updatePlanUsersDefinitionIsPending}>
            {t('masterPlanUsersDefinitionForm.form.uiType.resetName')}
          </Button>
        </div>
      );
    }

    return (
      <Button className={styles.renameTypeBtn} type="link" onClick={() => showRenameTypeFormItem(true)} disabled={updatePlanUsersDefinitionIsPending}>
        {t('masterPlanUsersDefinitionForm.form.connectorIdType.rename')}
      </Button>
    );
  }, [
    connector,
    connectorIdTypeValue,
    updatePlanUsersDefinitionIsPending,
    displayRenameTypeFormItem,
    resetUIType,
  ]);

  const remainingFormNode = useMemo(() => {
    if (connectorIdTypeValue === null) return null;

    return (
      <>
        <FormulaHelper
          className={styles.formulaHelper}
          helperData={formulaHelperData}
        />
        <FormItem
          name="matchingField"
          label={t('masterPlanUsersDefinitionForm.form.matchingField.label')}
          extra={`ex: ${uiTypeValue || connectorIdTypeValue.type || '[Resource]'}.id`}
          required
          rules={[
            { required: true, message: t('masterPlanUsersDefinitionForm.form.matchingField.rules.required') },
          ]}
        >
          <FormulaInput
            type={FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION}
            size="big"
            helperData={formulaHelperData}
            topFields={matchingFieldTopFields}
            disabled={updatePlanUsersDefinitionIsPending}
            suggestionsType={SUGGESTIONS_TYPES.USER}
          />
        </FormItem>
      </>
    );
  }, [
    connectorIdTypeValue,
    uiTypeValue,
    formulaHelperData,
    matchingFieldTopFields,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>
        {t('masterPlanUsersDefinitionForm.title')}
      </div>
      <div className={styles.description}>
        {t('masterPlanUsersDefinitionForm.description', { dealType: pluralize.singular(plan.trackingObject?.type || '') })}
      </div>
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <div
          className={classNames({
            connectorIdTypeWrapper: true,
            withRenameInput: displayRenameTypeFormItem,
          })}
        >
          <FormItem
            className={styles.connectorIdTypeInput}
            name="connectorIdType"
            label={t('masterPlanUsersDefinitionForm.form.connectorIdType.label')}
            required
            rules={[
              { required: true, message: t('masterPlanUsersDefinitionForm.form.connectorIdType.rules.required') },
            ]}
          >
            <UsersDefinitionConnectorsSelect size="big" disabled={updatePlanUsersDefinitionIsPending} initialValue={initialConnectorIdType} />
          </FormItem>
          {connectorIdTypeRenameNode}
        </div>
        {remainingFormNode}
        <div className={styles.actions}>
          <Button className={styles.submitBtn} onClick={handleUpdatePlanUsersDefinition} disabled={formIsPristine || updatePlanUsersDefinitionIsPending}>
            {t('masterPlanUsersDefinitionForm.form.submitBtnLabel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

MasterPlanUsersDefinitionForm.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanUsersDefinitionForm.defaultProps = {
  className: '',
};

export default MasterPlanUsersDefinitionForm;
