import PropTypes from 'prop-types';

import {
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { ACTION_TYPES } from '@palette/constants/statementsHistory';

import * as StatementHistoryUserModel from '@palette/models/StatementHistoryUser';

import { entities } from '@palette/models/statementHistoryStrategies/entities';

export const modelName = 'StatementHistory';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  author: StatementHistoryUserModel.propTypes,
  date: PropTypes.string,
  action: PropTypes.oneOf(Object.keys(ACTION_TYPES)),
  content: PropTypes.oneOfType(Object.values(entities).map((entity) => entity.propTypes)),
});

export const manageActionType = (eventAction) => Object.keys(ACTION_TYPES).find((action) => ACTION_TYPES[action].action === eventAction);

export const transform = (statementHistoryChange) => {
  if (!statementHistoryChange) {
    return null;
  }

  const eventAction = manageStringAttribute(statementHistoryChange, 'action');

  return (
    {
      id: manageStringAttribute(statementHistoryChange, 'id'),
      author: StatementHistoryUserModel.transform(manageFreeShapeObjectAttribute(statementHistoryChange, 'author')),
      date: manageDateAttribute(statementHistoryChange, 'creationDate'),
      action: manageActionType(eventAction),
      content: entities[eventAction]?.transform(manageFreeShapeObjectAttribute(statementHistoryChange, 'details')),
    }
  );
};

export const transformList = (statementHistoryChanges) => statementHistoryChanges.map((statementHistoryChange) => transform(statementHistoryChange));
