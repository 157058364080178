import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CardItem from '@palette/components/settings/CardItem/CardItem';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import Tag from '@palette/components/designSystem/Tag/Tag';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import AddExchangeRateButton from '@palette/components/company/AddExchangeRateButton/AddExchangeRateButton';
import EditExchangeRateButton from '@palette/components/company/EditExchangeRateButton/EditExchangeRateButton';
import HistoryExchangeRateButton from '@palette/components/company/HistoryExchangeRateButton/HistoryExchangeRateButton';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './CompanyExchangeRates.less';

const classNames = bindClassNames.bind(styles);

const CompanyExchangeRates = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company } = profile.userData;

  const getAggregatedFxRatesIsPending = useSelector(ProfileSelectors.getAggregatedFxRatesIsPending);

  useEffect(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return;

    dispatch(ProfileActions.getAggregatedFxRates());
  }, []);

  const ratesNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

    let renderNode;

    if ((getAggregatedFxRatesIsPending || !company.aggregatedFxRates) && !company.aggregatedFxRates?.length >= 1) {
      renderNode = <LoadingLine className={styles.icon} width={20} height={20} spin />;
    } else {
      renderNode = company.aggregatedFxRates.map((fxRate, index) => (
        <div className={styles.rateLine} key={`${fxRate.from}-${fxRate.to}-${index}`}>
          <div className={styles.details}>
            <Tag
              className={styles.tag}
              theme={TAG_THEMES.INFO}
              label={fxRate.from}
            />
            <ChevronRightLine className={styles.chevronRight} />
            <Tag
              className={styles.tag}
              theme={TAG_THEMES.INFO}
              label={fxRate.to}
            />
            <span className={styles.dots}>:</span>
            <Tag label={floatToFixedNumber(fxRate.currentRate).toString()} />
          </div>
          <div className={styles.actions}>
            <EditExchangeRateButton
              from={fxRate.from}
              to={fxRate.to}
              rate={fxRate.currentRate}
            />
            <HistoryExchangeRateButton
              from={fxRate.from}
              to={fxRate.to}
              rate={fxRate.currentRate}
              totalRates={fxRate.count}
            />
          </div>
        </div>
      ));
    }

    return (
      <div className={styles.ratesWrapper}>{renderNode}</div>
    );
  }, [profile, company, getAggregatedFxRatesIsPending]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyExchangeRates.block.title')}
      icon={<RefreshLine width={20} height={20} />}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.textContent}>{t('companyExchangeRates.content.text')}</div>
        {ratesNode}
        <AddExchangeRateButton />
      </div>
    </CardItem>
  );
};

CompanyExchangeRates.propTypes = {
  className: PropTypes.string,
};

CompanyExchangeRates.defaultProps = {
  className: '',
};

export default CompanyExchangeRates;
