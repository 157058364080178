import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Alert from '@palette/components/designSystem/Alert/Alert';
import UsersSelectionPanel from '@palette/components/user/UsersSelectionPanel/UsersSelectionPanel';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { ALERT_TYPES } from '@palette/constants/alert';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

import styles from './ApplyDashboardPresetModal.less';

const ApplyDashboardPresetModal = ({ visible, onClose, dashboardPreset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedUsers, setSelectedUsers] = useState([]);

  const applyDashboardPresetToUsersIsPending = useSelector(DashboardPresetsSelectors.applyDashboardPresetToUsersIsPending);

  const handleSelectedUsersChange = (users) => {
    setSelectedUsers(users);
  };

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
    redirectTo({ path: routePaths.users, qsObject: { tab: 'presets' } });
  };

  const handleApplyDashboardPreset = useCallback(() => {
    dispatch(DashboardPresetsActions.applyDashboardPresetToUsers({ presetId: dashboardPreset.id, selectedUsers, onSuccessCB }));
  }, [selectedUsers]);

  return (
    <Modal
      className={styles.modal}
      width="60%"
      title={t('applyDashboardPresetModal.title', { presetName: dashboardPreset.name })}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleApplyDashboardPreset}
      okText={t('applyDashboardPresetModal.apply', { count: selectedUsers.length })}
      disableOk={selectedUsers.length === 0}
      loading={applyDashboardPresetToUsersIsPending}
      stretchHeight
    >
      <div className={styles.content}>
        <Alert
          type={ALERT_TYPES.INFO}
          message={t('applyDashboardPresetModal.alert.message')}
          description={t('applyDashboardPresetModal.alert.description')}
        />
        <div className={styles.selectionPanelWrapper}>
          <UsersSelectionPanel onChange={handleSelectedUsersChange} restrictToUsersWithAccount />
        </div>
      </div>
    </Modal>
  );
};

ApplyDashboardPresetModal.propTypes = {
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

ApplyDashboardPresetModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default ApplyDashboardPresetModal;
