import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SendFilled from '@palette/components/utils/Icons/SendFilled';
import CardItem from '@palette/components/settings/CardItem/CardItem';
import Switch from '@palette/components/designSystem/Switch/Switch';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { EMAIL_PREFERENCES, FEATURES, RIGHTS } from '@palette/constants/profile';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './EmailNotifications.less';

const classNames = bindClassNames.bind(styles);

const EmailNotifications = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company, account, id } = profile.userData;
  const { emailPreferences } = account;

  const [weeklyDigest, setWeeklyDigest] = useState(emailPreferences[EMAIL_PREFERENCES.WEEKLY_DIGEST]);
  const [monthlyDigest, setMonthlyDigest] = useState(emailPreferences[EMAIL_PREFERENCES.MONTHLY_DIGEST]);
  const [commentThreads, setCommentThreads] = useState(emailPreferences[EMAIL_PREFERENCES.THREAD_NOTIFICATIONS]);
  const [statementValidated, setStatementValidated] = useState(emailPreferences[EMAIL_PREFERENCES.STATEMENT_VALIDATION_NOTIFICATIONS]);
  const [digestNotifications, setDigestNotifications] = useState(company.features[FEATURES.DIGEST_NOTIFICATIONS]);

  const toggleEmailPreferencesIsPending = useSelector(ProfileSelectors.toggleEmailPreferencesIsPending);
  const toggleDigestNotificationsIsPending = useSelector(ProfileSelectors.toggleDigestNotificationsIsPending);

  const toggleWeeklyDigest = useCallback((value) => {
    if (!toggleEmailPreferencesIsPending) {
      setWeeklyDigest(value);
      dispatch(ProfileActions.toggleEmailPreferences({ value, preference: EMAIL_PREFERENCES.WEEKLY_DIGEST, userId: id }));
    }
  }, [toggleEmailPreferencesIsPending, id]);

  const toggleMonthlyDigest = useCallback((value) => {
    if (!toggleEmailPreferencesIsPending) {
      setMonthlyDigest(value);
      dispatch(ProfileActions.toggleEmailPreferences({ value, preference: EMAIL_PREFERENCES.MONTHLY_DIGEST, userId: id }));
    }
  }, [toggleEmailPreferencesIsPending, id]);

  const toggleCommentThreads = useCallback((value) => {
    if (!toggleEmailPreferencesIsPending) {
      setCommentThreads(value);
      dispatch(ProfileActions.toggleEmailPreferences({ value, preference: EMAIL_PREFERENCES.THREAD_NOTIFICATIONS, userId: id }));
    }
  }, [toggleEmailPreferencesIsPending, id]);

  const toggleStatementValidated = useCallback((value) => {
    if (!toggleEmailPreferencesIsPending) {
      setStatementValidated(value);
      dispatch(ProfileActions.toggleEmailPreferences({ value, preference: EMAIL_PREFERENCES.STATEMENT_VALIDATION_NOTIFICATIONS, userId: id }));
    }
  }, [toggleEmailPreferencesIsPending, id]);

  const toggleDigestNotifications = useCallback((value) => {
    if (!toggleDigestNotificationsIsPending) {
      setDigestNotifications(value);
      dispatch(ProfileActions.toggleDigestNotifications({ value }));
    }
  }, [toggleDigestNotificationsIsPending]);

  const weeklyDigestNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]) || !company.features[FEATURES.DIGEST_NOTIFICATIONS]) return null;

    return (
      <div className={styles.switchWrapper}>
        <Switch
          value={weeklyDigest}
          onChange={toggleWeeklyDigest}
          disabled={toggleEmailPreferencesIsPending}
        />
        <div className={styles.switchLabel}>{t('statementValidations.content.switch.weekly')}</div>
      </div>
    );
  }, [
    profile,
    company,
    weeklyDigest,
    toggleWeeklyDigest,
    toggleEmailPreferencesIsPending,
  ]);

  const monthlyDigestNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE]) && !company.features[FEATURES.DIGEST_NOTIFICATIONS]) return null;

    return (
      <div className={styles.switchWrapper}>
        <Switch
          value={monthlyDigest}
          onChange={toggleMonthlyDigest}
          disabled={toggleEmailPreferencesIsPending}
        />
        <div className={styles.switchLabel}>{t('statementValidations.content.switch.monthly')}</div>
      </div>
    );
  }, [
    profile,
    company,
    monthlyDigest,
    toggleMonthlyDigest,
    toggleEmailPreferencesIsPending,
  ]);

  const statementValidatedNode = useMemo(() => {
    if (
      !hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])
      || !company.features[FEATURES.STATEMENT_VALIDATION]
      || !company.features[FEATURES.STATEMENT_VALIDATION_NOTIFICATIONS]
    ) return null;

    return (
      <div className={styles.switchWrapper}>
        <Switch
          value={statementValidated}
          onChange={toggleStatementValidated}
          disabled={toggleEmailPreferencesIsPending}
        />
        <div className={styles.switchLabel}>{t('statementValidations.content.switch.newStatement')}</div>
      </div>
    );
  }, [
    profile,
    company,
    statementValidated,
    toggleStatementValidated,
    toggleEmailPreferencesIsPending,
  ]);

  const authorizeEmailNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

    return (
      <div className={styles.authorizationWrapper}>
        <div className={styles.textContentBold}>{t('statementValidations.content.title.authorization')}</div>
        <div className={styles.textContent}>{t('statementValidations.content.text.authorization')}</div>
        <div className={styles.switchWrapper}>
          <Switch
            value={digestNotifications}
            onChange={toggleDigestNotifications}
            disabled={toggleDigestNotificationsIsPending}
          />
          <div className={styles.switchLabel}>{t('statementValidations.content.switch.authorization')}</div>
        </div>
      </div>
    );
  }, [
    profile,
    digestNotifications,
    toggleDigestNotifications,
    toggleDigestNotificationsIsPending,
  ]);

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('emailNotifications.block.title')}
      icon={<SendFilled width={20} height={20} />}
    >
      <div className={styles.textContent}>{t('emailNotifications.content.text.intro')}</div>
      {weeklyDigestNode}
      {monthlyDigestNode}
      <div className={styles.switchWrapper}>
        <Switch
          value={commentThreads}
          onChange={toggleCommentThreads}
          disabled={toggleEmailPreferencesIsPending}
        />
        <div className={styles.switchLabel}>{t('statementValidations.content.switch.threads')}</div>
      </div>
      {statementValidatedNode}
      {authorizeEmailNode}
    </CardItem>
  );
};

EmailNotifications.propTypes = {
  className: PropTypes.string,
};

EmailNotifications.defaultProps = {
  className: '',
};

export default EmailNotifications;
