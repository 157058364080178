import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextArea from '@palette/components/designSystem/TextArea/TextArea';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';

import styles from './DescriptionFormItem.less';

const DescriptionFormItem = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      className={styles.wrapper}
      name="description"
      label={t('createPlanPage.form.description.label')}
    >
      <TextArea placeholder={t('createPlanPage.form.description.placeholder')} className={styles.textarea} disabled={disabled} />
    </FormItem>
  );
};

DescriptionFormItem.propTypes = {
  disabled: PropTypes.bool,
};

DescriptionFormItem.defaultProps = {
  disabled: false,
};

export default DescriptionFormItem;
