import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _size from 'lodash/size';
import _toPath from 'lodash/toPath';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import RawDataEntry from '@palette/components/resources/RawDataEntry/RawDataEntry';
import RawDataEntryDetails from '@palette/components/resources/RawDataEntryDetails/RawDataEntryDetails';
import RawDataAddButton from '@palette/components/resources/RawDataAddButton/RawDataAddButton';
import RawDataPanelTitleNode from '@palette/components/resources/RawDataPanelTitleNode/RawDataPanelTitleNode';
import AlertCircleLine from '@palette/components/utils/Icons/AlertCircleLine';

import {
  ADD_CTA_PADDING_LEFT,
  CHILDRENS_PADDING_LEFT,
  COLLAPSE_ICON_CONFIG,
  RAW_DATA_SEARCH_RESULTS_PATH_SEPARATOR as SEARCH_PATH_SEPARATOR,
} from '@palette/constants/resources';
import {
  STATEMENT_DIFF_TYPES,
  STATEMENT_DIFF_TYPE_FIELD,
  STATEMENT_DIFF_OLD_FIELD,
  STATEMENT_DIFF_NEW_FIELD,
} from '@palette/constants/statements';

import styles from './RawDataTreeRecursive.less';

const RawDataTreeRecursive = ({
  resourceId,
  items,
  overwrites,
  type,
  indentPaddingLeft,
  path,
  showActionButtons,
  hasSearchValue,
  highlightType,
  manageDiff,
  expandAllByDefault,
  recursivityDepth,
  maxNbOfAutoExpandedArrayItems,
}) => {
  const { t } = useTranslation();

  if (!_size(items) > 0) return null;

  if (recursivityDepth !== null && recursivityDepth === 0) {
    return (
      <div className={styles.recursivityDepthLimitReachedWrapper}>
        <AlertCircleLine className={styles.recursivityDepthLimitReachedIcon} />
        <div className={styles.recursivityDepthLimitReachedLabel}>
          {t('rawDataTreeRecursive.recursivityDepthLimitReached')}
        </div>
      </div>
    );
  }

  let entries = items;

  if (type === 'object') {
    entries = Object.keys(items).sort();
  }

  return entries.map((entry, i) => {
    let entryType = 'default';
    let data;
    let hasHighlightType = highlightType;

    if (type === 'array') {
      data = entries[i];
    } else if (type === 'object') {
      data = items[entry];
    }

    if (data) {
      if (Array.isArray(data)) {
        entryType = 'array';
      } else if (typeof data === 'object') {
        entryType = 'object';
      }
    }

    const indentLeft = indentPaddingLeft + CHILDRENS_PADDING_LEFT;
    const paddingLeft = `${indentLeft / 10}rem`;
    const addCtaIndentLeft = indentLeft + ADD_CTA_PADDING_LEFT;
    const addCtaPaddingLeft = `${addCtaIndentLeft / 10}rem`;

    let label = entries[i]?.toString();
    let labelDisplayed = label;
    let value = items[entry];

    if (type === 'array') {
      label = i.toString();
      labelDisplayed = (i + 1).toString();
      value = entries[i];
    }

    let pathEntry = _toPath(`${path}.${label}`).slice(1);

    if (hasSearchValue) {
      if (value && typeof value === 'string' && value.includes(SEARCH_PATH_SEPARATOR)) {
        [value, pathEntry] = value.split(SEARCH_PATH_SEPARATOR);
        if (value === 'true' || value === 'false') {
          value = !!(value);
        }
      }
      if (type !== 'array' && label && label.includes(SEARCH_PATH_SEPARATOR)) {
        [label, pathEntry] = label.split(SEARCH_PATH_SEPARATOR);
        labelDisplayed = label;
      }
      pathEntry = _toPath(pathEntry);
    }

    if (entryType === 'default') {
      return (
        <RawDataEntry
          resourceId={resourceId}
          property={labelDisplayed}
          paddingLeft={paddingLeft}
          key={`raw-data-entry-${labelDisplayed}-${i}`}
          showActionButtons={showActionButtons}
          path={pathEntry}
          overwrites={overwrites}
          hasDiffHighlight={highlightType !== null}
          {...(highlightType === null || highlightType === 'new' ? { value } : { oldValue: value })}
        />
      );
    }

    if (manageDiff && entryType === 'object' && STATEMENT_DIFF_TYPE_FIELD in data) {
      if (
        data[STATEMENT_DIFF_TYPE_FIELD] === STATEMENT_DIFF_TYPES.ADDED
        || data[STATEMENT_DIFF_TYPE_FIELD] === STATEMENT_DIFF_TYPES.DELETED
      ) {
        const diffType = data[STATEMENT_DIFF_TYPE_FIELD];

        data = data.value;
        hasHighlightType = 'new';

        if (diffType === STATEMENT_DIFF_TYPES.DELETED) {
          hasHighlightType = 'old';
        }
      } else {
        return (
          <RawDataEntry
            resourceId={resourceId}
            property={labelDisplayed}
            oldValue={data[STATEMENT_DIFF_OLD_FIELD]}
            value={data[STATEMENT_DIFF_NEW_FIELD]}
            paddingLeft={paddingLeft}
            key={`raw-data-entry-${labelDisplayed}-${i}`}
            showActionButtons={false}
            path={pathEntry}
            overwrites={overwrites}
            hasDiffHighlight
          />
        );
      }
    }

    let arraySearchPathEntry;

    if (hasSearchValue && type === 'array' && _size(data) > 0 && path !== 'root') {
      let firstEl;

      if (Array.isArray(data)) {
        [firstEl] = data;
      } else if (typeof data === 'object') {
        [firstEl] = Object.keys(data);
      }
      arraySearchPathEntry = firstEl?.toString().split(SEARCH_PATH_SEPARATOR)[1]?.split('.').slice(0, -1);
    }

    const keyValue = `raw-data-${labelDisplayed}-${i}`;

    let isExpanded = hasSearchValue || expandAllByDefault;
    if (isExpanded && type === 'array') {
      isExpanded = i < maxNbOfAutoExpandedArrayItems;
    }

    return (
      <Collapse
        panels={[
          {
            title: (
              <RawDataPanelTitleNode
                label={labelDisplayed}
                count={_size(data)}
                paddingLeft={paddingLeft}
                property={entryType || type}
                badge={hasHighlightType}
              />
            ),
            content: (
              <>
                {showActionButtons && (
                  <RawDataAddButton
                    paddingLeft={addCtaPaddingLeft}
                    property={entryType || type}
                    resourceId={resourceId}
                    path={arraySearchPathEntry || pathEntry}
                  />
                )}
                <RawDataTreeRecursive
                  resourceId={resourceId}
                  items={data}
                  overwrites={overwrites}
                  type={entryType}
                  indentPaddingLeft={indentLeft}
                  path={`${path}.${label}`}
                  showActionButtons={showActionButtons}
                  hasSearchValue={hasSearchValue}
                  highlightType={hasHighlightType}
                  manageDiff={manageDiff}
                  expandAllByDefault={expandAllByDefault}
                  recursivityDepth={recursivityDepth !== null ? (recursivityDepth - 1) : null}
                  maxNbOfAutoExpandedArrayItems={maxNbOfAutoExpandedArrayItems}
                />
              </>
            ),
            extra: (
              <RawDataEntryDetails
                resourceId={resourceId}
                showActionButtons={showActionButtons}
                path={arraySearchPathEntry || pathEntry}
                id={arraySearchPathEntry || pathEntry}
                overwrites={overwrites}
              />
            ),
          },
        ]}
        className={styles.collapse}
        expandIconPosition="end"
        bordered={false}
        ghost
        key={keyValue}
        keyValue={keyValue}
        defaultActiveKey={isExpanded ? keyValue : undefined}
        {...COLLAPSE_ICON_CONFIG}
      />
    );
  });
};

RawDataTreeRecursive.propTypes = {
  resourceId: PropTypes.string,
  items: PropTypes.any,
  overwrites: PropTypes.array,
  type: PropTypes.oneOf([
    'object',
    'array',
    'default',
  ]),
  indentPaddingLeft: PropTypes.number,
  path: PropTypes.any,
  showActionButtons: PropTypes.bool,
  hasSearchValue: PropTypes.bool,
  highlightType: PropTypes.oneOf([null, 'old', 'new']),
  manageDiff: PropTypes.bool,
  expandAllByDefault: PropTypes.bool,
  recursivityDepth: PropTypes.number,
  maxNbOfAutoExpandedArrayItems: PropTypes.number,
};

RawDataTreeRecursive.defaultProps = {
  resourceId: '',
  items: {},
  overwrites: [],
  type: 'default',
  indentPaddingLeft: 0,
  path: 'root',
  showActionButtons: true,
  hasSearchValue: false,
  highlightType: null,
  manageDiff: false,
  expandAllByDefault: false,
  recursivityDepth: null,
  maxNbOfAutoExpandedArrayItems: 10,
};

export default RawDataTreeRecursive;
