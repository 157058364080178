import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Link from '@palette/components/designSystem/Link/Link';
import DollarFilled from '@palette/components/utils/Icons/DollarFilled';
import Calendar2Filled from '@palette/components/utils/Icons/Calendar2Filled';
import ProfileFilled from '@palette/components/utils/Icons/ProfileFilled';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { getPeriodDates, getPeriodName } from '@palette/helpers/StatementHelper';

import routePaths from '@palette/config/routePaths';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './StatementsBreadcrumb.less';

const classNames = bindClassNames.bind(styles);

const StatementsBreadcrumb = ({ className, statementPeriod, userName }) => {
  const { t } = useTranslation();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const periodLabel = useMemo(() => {
    if (!statementPeriod) return null;

    const periodName = getPeriodName(statementPeriod.statementStrategy, statementPeriod.period);
    const periodDates = getPeriodDates(statementPeriod.beginDate, statementPeriod.endDate);

    return (
      <>
        <span className={styles.periodName}>{periodName}</span>
        {periodDates}
      </>
    );
  }, [statementPeriod]);

  if (!statementPeriod) return null;

  let currentLocationNode = (
    <div className={styles.currentLocationWrapper}>
      <Calendar2Filled className={styles.currentLocationIcon} width={16} height={16} />
      <div className={styles.currentLocationLabel}>
        {periodLabel}
      </div>
    </div>
  );

  let periodLinkNode = null;

  if (userName) {
    periodLinkNode = (
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.statementsPeriod}
          params={{ statementPeriodId: statementPeriod.id }}
          qsObject={periodsDatesFilterQSObject}
        >
          <Calendar2Filled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {periodLabel}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
    );

    currentLocationNode = (
      <div className={styles.currentLocationWrapper}>
        <ProfileFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {userName}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.statements}
          qsObject={periodsDatesFilterQSObject}
        >
          <DollarFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {t('statementsBreadcrumb.statements')}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
      {periodLinkNode}
      {currentLocationNode}
    </div>
  );
};

StatementsBreadcrumb.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes,
  userName: PropTypes.string,
};

StatementsBreadcrumb.defaultProps = {
  className: '',
  statementPeriod: null,
  userName: null,
};

export default StatementsBreadcrumb;
