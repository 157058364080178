import PropTypes from 'prop-types';

import { manageFreeShapeObjectAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import * as StatementHistoryUserModel from '@palette/models/StatementHistoryUser';

export const modelName = 'UserAddInPlanStrategy';

export const propTypes = PropTypes.exact({
  planId: PropTypes.string,
  planName: PropTypes.string,
  payee: StatementHistoryUserModel.propTypes,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      planId: manageStringAttribute(strategy, 'planId'),
      planName: manageStringAttribute(strategy, 'planName'),
      payee: StatementHistoryUserModel.transform(manageFreeShapeObjectAttribute(strategy, 'payee')),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
