export const FORMULA_INPUT_TYPES = {
  ONE_FIELD_SELECTION: 'ONE_FIELD_SELECTION',
  MULTIPLE_FIELDS_SELECTION: 'MULTIPLE_FIELDS_SELECTION',
  FORMULA: 'FORMULA',
};

export const SUGGESTIONS_TYPES = {
  USER: 'USER',
  DEAL: 'DEAL',
  DATE: 'DATE',
};

export const SUGGESTIONS_TYPES_TESTS = {
  USER: [
    (value) => (value.indexOf('id') !== -1 ? 1 : 0),
    (value) => { // check if value is equals to 'id'
      if (value === 'id') return 1;

      const idIndex = value.indexOf('.id');
      if (idIndex !== -1) {
        const withoutPrefix = value.substring(idIndex, value.length);

        if (withoutPrefix === 'id') return 1;

        return 0;
      }

      return 0;
    },
    (value) => (value.indexOf('ownerid') !== -1 ? 1 : 0),
  ],
  DEAL: [
    (value) => (value.indexOf('owner') !== -1 ? 1 : 0),
    (value) => (value.indexOf('user') !== -1 ? 1 : 0),
    (value) => (value.indexOf('id') !== -1 ? 1 : 0),
    (value) => (value.indexOf('ownerid') !== -1 ? 1 : 0),
  ],
  DATE: [
    (value) => (value.indexOf('close') !== -1 ? 1 : 0),
    (value) => (value.indexOf('date') !== -1 ? 1 : 0),
    // We don't want the classic endsWith
    // because we don't want words ending with at like "eat" but only createdAt or created_at
    (value) => (
      (
        value.substring(value.length - 2) === 'At'
        || value.substring(value.length - 3) === '_at'
      )
        ? 1
        : 0
    ),
  ],
};

export const DEFAULT_VALUE_DEFINITION_VALUE = 100;

export const DEFAULT_TARGET_VALUE = 20000;

export const DEFAULT_PERIOD_DEFINITION = {
  beginAt: '2021-10-01T00:00:00.000Z',
  endAt: '2021-12-31T00:00:00.000Z',
  target: DEFAULT_TARGET_VALUE,
  periodType: 'Q4',
  periodYear: 2021,
};

export const DEFAULT_USERS_DEFINITION = {
  _id: '646b3d5497521806ac31cc70',
  progress: 0.1,
  totalProgress: 0.2,
  target: DEFAULT_TARGET_VALUE,
  startDate: '2021-11-15T00:00:00.000Z',
  endDate: '2021-12-10T00:00:00.000Z',
  currency: 'EUR',
};

export const DEFAULT_SCOPE_TEAM = {
  progress: 0.1,
  totalProgress: 0.2,
  target: DEFAULT_TARGET_VALUE,
  size: 12,
};

export const DEFAULT_SCOPE_MANAGER = {
  size: 12,
  managers: 1,
};

export const DEFAULT_INJECTION = {
  progress: 0.87,
  value: 1000,
  commissions: 800,
};

export const DEFAULT_INJECTION_DATA_POINTS = [
  {
    date: '2021-11-30T00:00:00.000Z',
    progress: 0.45,
  },
  {
    date: '2021-11-30T00:00:00.000Z',
    progress: 0.65,
  },
];
