import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _cloneDeep from 'lodash/cloneDeep';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Table from '@palette/components/designSystem/Table/Table';
import UserVariableValueCellContent from '@palette/components/usersVariables/UserVariableValueCellContent/UserVariableValueCellContent';
import DeleteVariableValueHistoryItem from '@palette/components/usersVariables/DeleteVariableValueHistoryItem/DeleteVariableValueHistoryItem';

import { shortFormatWithTime } from '@palette/helpers/MomentHelper';

import { USER_VARIABLE_TYPES } from '@palette/constants/user';

import * as UserVariableModel from '@palette/models/UserVariable';
import * as MetaUserModel from '@palette/models/MetaUser';
import * as UserVariableOverrideModel from '@palette/models/UserVariableOverride';

import styles from './ShowVariableValueHistoryModal.less';

const ShowVariableValueHistoryModal = ({ visible, onClose, user, userVariable, overrides }) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const finalData = _cloneDeep(overrides);

    finalData.unshift({
      date: null,
      value: userVariable.defaultValue,
    });

    return finalData;
  }, [overrides, userVariable]);

  const columns = useMemo(() => {
    const cols = [];

    const fromColumn = {
      id: 'from',
      Header: t('showVariableValueHistoryModal.columns.from.header'),
      accessor: 'date',
      minWidth: 300,
      Cell: ({ value }) => (value !== null ? shortFormatWithTime(value, true) : t('showVariableValueHistoryModal.columns.from.defaultValue')),
    };
    cols.push(fromColumn);

    const valueColumn = {
      id: 'value',
      Header: userVariable.type === USER_VARIABLE_TYPES.DATE ? t('showVariableValueHistoryModal.columns.dateValue.header') : t('showVariableValueHistoryModal.columns.value.header'),
      accessor: 'value',
      minWidth: 200,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<UserVariableValueCellContent userVariable={userVariable} value={value} />),
    };
    cols.push(valueColumn);

    if (data.length > 1) {
      const actionsColumn = {
        id: 'actions',
        accessor: (override) => (override),
        minWidth: 50,
        width: 50,
        maxWidth: 50,
        /* eslint-disable react/prop-types */
        Cell: ({ value: override }) => {
          if (override.date === null) return null;

          return (
            <DeleteVariableValueHistoryItem user={user} userVariable={userVariable} override={override} />
          );
        },
        /* eslint-enable react/prop-types */
      };
      cols.push(actionsColumn);
    }

    return cols;
  }, [data, user, userVariable]);

  return (
    <Modal
      className={styles.modal}
      blockHeight
      title={(
        <div className={styles.header}>
          <div className={styles.title}>
            {t('showVariableValueHistoryModal.showHistoryModalTitle', { variableName: userVariable.name })}
          </div>
          <div className={styles.subTitle}>
            {t('showVariableValueHistoryModal.showHistoryModalSubTitle', { userDisplayName: user.displayName })}
          </div>
        </div>
      )}
      visible={visible}
      onCancel={onClose}
      displayFooter={false}
    >
      <Table columns={columns} data={data} stickyHeader fitInContainer />
    </Modal>
  );
};

ShowVariableValueHistoryModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  user: MetaUserModel.propTypes.isRequired,
  userVariable: UserVariableModel.propTypes.isRequired,
  overrides: PropTypes.arrayOf(UserVariableOverrideModel.propTypes).isRequired,
};

ShowVariableValueHistoryModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default ShowVariableValueHistoryModal;
