import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

import { RIGHTS } from '@palette/constants/profile';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* getById(payload) {
  const { connectorId, objectId, type } = payload;

  const data = { connectorId, objectId, type };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.resources.getById, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.VIEW],
  );
}

export function* deleteProperty(payload) {
  const { connectorId, objectId, type, fragments, value } = payload;

  const data = { connectorId, objectId, type, fragments, value };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.resources.overwrite, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.MANAGE],
  );
}

export function* addEditProperty(payload) {
  const { connectorId, objectId, type, fragments, value } = payload;

  const data = { connectorId, objectId, type, fragments, value };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.resources.overwrite, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.MANAGE],
  );
}

export function* deleteOverwrite(payload) {
  const { connectorId, objectId, type, overwriteIds } = payload;

  const data = { connectorId, objectId, type, overwriteIds };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.resources.deleteOverwrite, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.MANAGE],
  );
}

export function* cloneResource(payload) {
  const { connectorId, objectId, originalType } = payload;

  const data = { connectorId, objectId, type: originalType };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.resources.clone, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.MANAGE],
  );
}

export function* deleteResource(payload) {
  const { connectorId, objectId, originalType } = payload;

  const data = { connectorId, objectId, type: originalType };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.resources.delete, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.MANAGE],
  );
}
