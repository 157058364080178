import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import HighlightZone from '@palette/components/highlight/HighlightZone/HighlightZone';
import MasterPlanHighlightZoneFilters from '@palette/components/masterPlan/MasterPlanHighlightZoneFilters/MasterPlanHighlightZoneFilters';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useMasterPlanPeriodsFilters } from '@palette/hooks/MasterPlanHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanHighlightZone.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanHighlightZone = ({ className, plan }) => {
  const dispatch = useDispatch();
  const profile = useProfile();

  const [periodsFilterType, customFilterPeriods] = useMasterPlanPeriodsFilters(plan, false);

  const planDashboardIsPending = useSelector(MasterPlansSelectors.getPlanDashboardIsPending);
  const masterPlanDashboard = useSelector((state) => MasterPlansSelectors.getMasterPlanDashboard(state, { masterPlanId: plan.id }));

  const getMasterPlanWidgetsData = useCallback(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) return;

    dispatch(MasterPlansActions.getPlanDashboard({ planId: plan.id, periodsFilterType, customPeriods: customFilterPeriods }));
  }, [plan, periodsFilterType, customFilterPeriods]);

  useEffect(() => {
    if (plan === null) return;

    getMasterPlanWidgetsData();
  }, [plan, periodsFilterType, customFilterPeriods]);

  const planDashboard = useMemo(() => masterPlanDashboard.filter((widget) => plan.planComponents.includes(widget.type)),
    [plan, masterPlanDashboard],
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <HighlightZone
        className={styles.highlightzone}
        loading={planDashboardIsPending}
        data={planDashboard}
        actionsNode={<MasterPlanHighlightZoneFilters plan={plan} />}
      />
    </div>
  );
};

MasterPlanHighlightZone.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanHighlightZone.defaultProps = {
  className: '',
};

export default MasterPlanHighlightZone;
