import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import SVGIcon from '@palette/components/utils/Icons/SVGIcon/SVGIcon';

import NotificationActiveFilledSvg from '../svgs/NotificationActiveFilled.svg';

import styles from './NotificationActiveFilled.less';

const classNames = bindClassNames.bind(styles);

const NotificationActiveFilled = ({ className, ...props }) => (
  <SVGIcon
    className={classNames({
      [className]: className !== '',
      wrapper: true,
    })}
    Component={NotificationActiveFilledSvg}
    {...props}
  />
);

NotificationActiveFilled.propTypes = {
  className: PropTypes.string,
};

NotificationActiveFilled.defaultProps = {
  className: '',
};

export default NotificationActiveFilled;
