import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MasterPlanSettingsQuotaSetup from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaSetup/MasterPlanSettingsQuotaSetup';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';
import Button from '@palette/components/designSystem/Button/Button';

import { getCreatePlanFlowStepIndex } from '@palette/helpers/MasterPlanHelper';

import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './CreatePlanFlowQuotaSetupStep.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowQuotaSetupStep = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId: plan.quotaId }));
  const quotaGetByIdIsPending = useSelector(QuotasSelectors.getByIdIsPending);

  const [editionMode, setEditionMode] = useState(plan.quotaId !== null);

  const initialDisplayActions = useMemo(() => {
    const planStep = plan.creationFlowStep;
    if (planStep !== null) {
      const planStepIndex = getCreatePlanFlowStepIndex(planStep);
      const nextStepIndex = getCreatePlanFlowStepIndex(CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value);

      return (planStepIndex > nextStepIndex) || (plan.quotaId !== null);
    }

    return plan.quotaId !== null;
  }, [plan]);

  const [displayActions, setDisplayActions] = useState(initialDisplayActions);

  const [creationIsDiscard, setCreationIsDiscard] = useState(initialDisplayActions);

  useEffect(() => {
    setDisplayActions(initialDisplayActions);
    if (!initialDisplayActions) {
      setCreationIsDiscard(false);
    }
  }, [initialDisplayActions, plan.quotaId]);

  useEffect(() => {
    if (plan.quotaId !== null) {
      dispatch(QuotasActions.getById({ quotaId: plan.quotaId }));
      setEditionMode(false);
    }
  }, [plan]);

  const handleDiscardCreation = useCallback(() => {
    setDisplayActions(true);
    setCreationIsDiscard(true);
    setEditionMode(false);
  }, []);

  const switchToEditionMode = useCallback(() => {
    setDisplayActions(false);
    setCreationIsDiscard(false);
    setEditionMode(true);
  }, []);

  const quotaSetupNode = useMemo(() => {
    if (quota === null && !quotaGetByIdIsPending && !editionMode) {
      return (
        <div className={styles.disclaimerDescriptionWrapper}>
          {t('createPlanFlowQuotaSetupStep.disclaimer.noQuota.description')}
          <div className={styles.disclaimerDescriptionCTAsWrapper}>
            <Button
              className={styles.disclaimerDescriptionCTA}
              type="primary"
              onClick={switchToEditionMode}
            >
              {t('createPlanFlowQuotaSetupStep.disclaimer.noQuota.defineQuotaCTA')}
            </Button>
            {
              !creationIsDiscard && (
                <Button
                  className={styles.disclaimerDescriptionCTA}
                  type="link"
                  onClick={handleDiscardCreation}
                >
                  {t('createPlanFlowQuotaSetupStep.disclaimer.noQuota.discard')}
                </Button>
              )
            }
          </div>
        </div>
      );
    }

    const quotaSettingsNode = (
      <MasterPlanSettingsQuotaSetup plan={plan} quota={quota} inPlanCreationFlow />
    );

    if (quota === null) {
      return (
        <>
          <div className={styles.cancelQuotaSetupWrapper}>
            <Button
              type="link"
              onClick={handleDiscardCreation}
            >
              {t('createPlanFlowQuotaSetupStep.quotaSetup.cancelSetup')}
            </Button>
          </div>
          {quotaSettingsNode}
        </>
      );
    }

    return quotaSettingsNode;
  }, [
    plan,
    quota,
    quotaGetByIdIsPending,
    editionMode,
    handleDiscardCreation,
    creationIsDiscard,
    switchToEditionMode,
  ]);

  const actionsNode = useMemo(() => {
    if (!displayActions) return null;

    return (
      <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value} />
    );
  }, [plan, displayActions]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {quotaSetupNode}
      {actionsNode}
    </div>
  );
};

CreatePlanFlowQuotaSetupStep.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowQuotaSetupStep.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowQuotaSetupStep;
