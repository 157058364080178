import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import UserStatementInvestigateListItem from '@palette/components/statement/userStatementInvestigate/UserStatementInvestigateListItem/UserStatementInvestigateListItem';

import * as UserStatementCorrectionSummaryModel from '@palette/models/UserStatementCorrectionSummary';

import styles from './UserStatementInvestigateList.less';

const classNames = bindClassNames.bind(styles);

const UserStatementInvestigateList = ({ className, summaries, isPending, summarySelected, onClickSummary }) => {
  const { t } = useTranslation();

  const listContentNode = useMemo(() => {
    let contentNode = (
      <DefaultEmptyState />
    );

    if (isPending && !summaries.length) {
      contentNode = (
        <Loader />
      );
    } else if (summaries.length > 0) {
      contentNode = (
        <div className={styles.summariesList}>
          <div className={styles.columns}>
            <span className={styles.column}>{t('userStatementInvestigateList.column.name')}</span>
            <span className={styles.column}>{t('userStatementInvestigateList.column.plan')}</span>
            <span className={styles.column}>{t('userStatementInvestigateList.column.period')}</span>
            <span className={styles.column}>{t('userStatementInvestigateList.column.was')}</span>
            <span className={styles.column}>{t('userStatementInvestigateList.column.is')}</span>
          </div>
          {summaries.map((summary) => (
            <UserStatementInvestigateListItem
              key={`summary-${summary.id}`}
              className={styles.item}
              summary={summary}
              onClick={onClickSummary}
              isSelected={summarySelected === summary.id}
            />
          ))}
        </div>
      );
    }

    return contentNode;
  }, [isPending, summaries, summarySelected]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>
        {t('userStatementInvestigateList.title', { count: summaries.length })}
      </div>
      {listContentNode}
    </div>
  );
};

UserStatementInvestigateList.propTypes = {
  className: PropTypes.string,
  summaries: PropTypes.arrayOf(UserStatementCorrectionSummaryModel.propTypes),
  isPending: PropTypes.bool,
  onClickSummary: PropTypes.func.isRequired,
  summarySelected: PropTypes.string,
};

UserStatementInvestigateList.defaultProps = {
  className: '',
  summaries: [],
  isPending: false,
  summarySelected: null,
};

export default UserStatementInvestigateList;
