import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions as AuditTrailActions } from '@palette/state/AuditTrail/slice';
import { manageError as manageAuditTrailError } from '@palette/state/AuditTrail/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';

import * as AuditTrailService from '@palette/services/AuditTrailService';

import * as AuditTrailModel from '@palette/models/AuditTrail';
import * as PaginationModel from '@palette/models/Pagination';

export function* listByFilters({ payload = {} }) {
  const {
    typeFilter = undefined,
    sinceFilter = undefined,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
  } = payload;

  const callData = {
    typeFilter,
    sinceFilter,
    page,
    limit,
  };

  const callResult = yield call(AuditTrailService.auditTrailsListByFilters, callData);

  if (callResult.ok) {
    const auditTrails = AuditTrailModel.transformList(callResult.data.data);
    const pagination = PaginationModel.transform(callResult.data.pagination);

    yield put(AuditTrailActions.setListByFilters({ auditTrails, pagination, callData }));
  } else {
    const error = manageAuditTrailError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(AuditTrailActions.listByFiltersCompleted());
}

export function* loop() {
  yield all([
    takeLatest(AuditTrailActions.listByFilters.type, listByFilters),
  ]);
}

export function* clean() {
  yield put(AuditTrailActions.resetToInitialState());
}
