import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import MyStatementsPeriodsNavigation from '@palette/components/ic/myStatement/MyStatementsPeriodsNavigation/MyStatementsPeriodsNavigation';
import MyUserStatementContent from '@palette/components/ic/myStatement/MyUserStatementContent/MyUserStatementContent';
import ExternalLink from '@palette/components/designSystem/ExternalLink/ExternalLink';
import Alert from '@palette/components/designSystem/Alert/Alert';

import {
  useMyStatementPeriodIdInParams,
  useMyStatementPeriods,
  usePeriodsDatesFilter,
  usePeriodsDatesFilterAndCurrencyQSObject,
} from '@palette/hooks/StatementHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { STATEMENT_PERIODS_DATES_TYPES } from '@palette/constants/statements';

import routePaths from '@palette/config/routePaths';

import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements';

import styles from './MyStatementsPageContent.less';

const classNames = bindClassNames.bind(styles);

const MyStatementsPageContent = ({ className, sessionId, inSalesforce, showViewMoreButtonLink, isUnauthorized }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const { statementPeriodId, statementPeriod } = useMyStatementPeriodIdInParams();
  const { statementPeriods } = useMyStatementPeriods(inSalesforce);

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject(inSalesforce);
  const [,,, changePeriodsDatesFilter] = usePeriodsDatesFilter(inSalesforce);

  useEffect(() => {
    if (statementPeriodId === null) {
      const nowMoment = moment.utc();

      const candidatePeriods = statementPeriods.filter((stmtPeriod) => {
        const beginMomentDate = moment.utc(stmtPeriod.beginDate);
        const endMomentDate = moment.utc(stmtPeriod.endDate);

        return nowMoment.isBetween(beginMomentDate, endMomentDate, 'day', '[]');
      });

      if (candidatePeriods.length > 0) {
        const newStatementPeriodId = candidatePeriods[0].id;

        redirectTo({
          path: inSalesforce ? routePaths.v2.sfStatementDetails : routePaths.v2.myStatementDetails,
          params: {
            statementPeriodId: newStatementPeriodId,
            ...(inSalesforce ? { sessionId } : undefined),
          },
          qsObject: periodsDatesFilterQSObject,
        });
      }
    }
  }, [statementPeriods, sessionId, inSalesforce]);

  useEffect(() => {
    if (statementPeriodId !== null) {
      dispatch(StatementsActions.getICUserStatement({ statementPeriodId, inSalesforce }));
    }
  }, [statementPeriodId, inSalesforce]);

  const userStatement = useSelector((state) => StatementsSelectors.getICUserStatement(state, { statementPeriodId }));
  const getICUserStatementIsPending = useSelector((state) => StatementsSelectors.getICUserStatementIsPending(state));

  useEffect(() => {
    if (statementPeriod === null && userStatement !== null && userStatement.beginAt !== null && userStatement.endAt !== null) {
      changePeriodsDatesFilter(STATEMENT_PERIODS_DATES_TYPES.CUSTOM, moment.utc(userStatement.beginAt), moment.utc(userStatement.endAt));
    }
  }, [userStatement]);

  const contentNode = useMemo(() => {
    if (statementPeriod === null) return null;

    if (getICUserStatementIsPending) {
      return (
        <Loader />
      );
    }

    return (
      <MyUserStatementContent statementPeriod={statementPeriod} userStatement={userStatement} inSalesforce={inSalesforce} />
    );
  }, [statementPeriod, getICUserStatementIsPending, userStatement, inSalesforce]);

  const unauthorizedNode = useMemo(() => {
    if (!inSalesforce || !isUnauthorized) return null;

    /* eslint-disable react/no-danger */
    return (
      <div className={styles.topContentsWrapper}>
        <Alert
          className={styles.alert}
          type={ALERT_TYPES.WARNING}
          message={t('myStatementsPageContent.unauthorized.title')}
          description={<span dangerouslySetInnerHTML={{ __html: t('myStatementsPageContent.unauthorized.description') }} />}
        />
      </div>
    );
    /* eslint-enable react/no-danger */
  }, [inSalesforce, isUnauthorized]);

  const viewMoreButtonNode = useMemo(() => {
    if (!inSalesforce || !showViewMoreButtonLink) return null;

    return (
      <ExternalLink
        className={styles.topContentsWrapper}
        href="https://app.palettehq.com/?ref=sf"
        displayIcon
      >
        {t('myStatementsPageContent.viewMoreInPalette')}
      </ExternalLink>
    );
  }, [inSalesforce, showViewMoreButtonLink]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.topContentsWrapper}>
        <MyStatementsPeriodsNavigation sessionId={sessionId} inSalesforce={inSalesforce} />
      </div>
      {unauthorizedNode}
      {viewMoreButtonNode}
      {contentNode}
    </div>
  );
};

MyStatementsPageContent.propTypes = {
  className: PropTypes.string,
  inSalesforce: PropTypes.bool,
  sessionId: PropTypes.string,
  showViewMoreButtonLink: PropTypes.bool,
  isUnauthorized: PropTypes.bool,
};

MyStatementsPageContent.defaultProps = {
  className: '',
  inSalesforce: false,
  sessionId: '',
  showViewMoreButtonLink: false,
  isUnauthorized: false,
};

export default MyStatementsPageContent;
