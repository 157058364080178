import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import EditDefaultValuesPanel from '@palette/components/quota/EditDefaultValuesPanel/EditDefaultValuesPanel';

import { QUOTA_ACTIONS } from '@palette/constants/quotas';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

const QuotaUserDefaultValuesPanel = ({ quotaId, quotaUserId }) => {
  const dispatch = useDispatch();

  const quotaUser = useSelector((state) => QuotasSelectors.getQuotaUserByIdAndQuotaId(state, { quotaId, quotaUserId }));

  const updateUserFromQuotaIsPending = useSelector(QuotasSelectors.updateUserFromQuotaIsPending);

  const handleSaveNewDefaultValues = (newDefaultValues) => {
    dispatch(QuotasActions.updateUserFromQuota({ quotaId, quotaUser, defaultValues: newDefaultValues, action: QUOTA_ACTIONS.UPDATE_USER_DEFAULT_VALUES }));
  };

  return (
    <EditDefaultValuesPanel
      displayQuotaName
      allowDeleteAll
      quotaId={quotaId}
      initialDefaultValues={quotaUser.defaultValues}
      updateIsPending={updateUserFromQuotaIsPending}
      saveNewDefaultValues={handleSaveNewDefaultValues}
    />
  );
};

QuotaUserDefaultValuesPanel.propTypes = {
  quotaId: PropTypes.string.isRequired,
  quotaUserId: PropTypes.string.isRequired,
};

export default QuotaUserDefaultValuesPanel;
