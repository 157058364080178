import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import AlertOctagon from '@palette/components/utils/Icons/AlertOctagon';
import ResourceConfigurationModal from '@palette/components/connector/ResourceConfigurationModal/ResourceConfigurationModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './ResourceConfiguration.less';

const classNames = bindClassNames.bind(styles);

const ResourceConfiguration = ({
  className,
  connectorId,
  resourceType,
  requiredFields,
  isSynchronizing,
  enabled,
  onRefreshList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const [resourceConfigurationModalIsVisible, setResourceConfigurationModalIsVisible] = useState(false);

  const getConfigurationsFieldsIsPending = useSelector(ConnectorsSelectors.getConfigurationsFieldsIsPending);

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId }));
  const getConfigurationsFieldsByIdAndType = useSelector((state) => ConnectorsSelectors.getConfigurationsFieldsByIdAndType(state, { connectorId, resourceType }));

  useEffect(() => {
    if (enabled) {
      dispatch(ConnectorsActions.getResourceConfigurationsFields({ connectorId }));
    }
  }, []);

  const handleConfigureClick = (event) => {
    event.stopPropagation();
    setResourceConfigurationModalIsVisible(true);
  };

  const handleCloseModal = (event) => {
    event.stopPropagation();
    setResourceConfigurationModalIsVisible(false);
  };

  const configureButtonNode = useMemo(() => {
    const isDisabled = !connector.resources.find((resource) => resource.type === resourceType);
    const hasConfigYet = !!getConfigurationsFieldsByIdAndType?.isConfigExists;

    const contentNode = (
      <Button
        className={classNames({
          configureButton: true,
          disabled: isDisabled,
        })}
        type="link"
        icon={(
          isSynchronizing && !isDisabled
            ? <LoadingLine width={16} height={16} spin />
            : <PenFilled className={styles.iconEdit} width={16} height={16} />
        )}
        onClick={handleConfigureClick}
        disabled={isDisabled || getConfigurationsFieldsIsPending || isSynchronizing}
      >
        {t('resourceConfiguration.configure.resource')}
        {!hasConfigYet && !isDisabled && !getConfigurationsFieldsIsPending && (
          <Tooltip title={t('resourceConfiguration.configure.tooltip.noconfig')}>
            <div className={styles.alertButtonContainer}>
              <AlertOctagon className={styles.iconAlert} />
            </div>
          </Tooltip>
        )}
      </Button>
    );

    if (isDisabled) {
      return (
        <Tooltip title={t('resourceConfiguration.configure.tooltip.disabled')}>
          <div className={styles.buttonContainer}>
            {contentNode}
          </div>
        </Tooltip>
      );
    }

    return contentNode;
  }, [
    connector,
    resourceType,
    getConfigurationsFieldsIsPending,
    isSynchronizing,
    getConfigurationsFieldsByIdAndType,
  ]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.CONNECTORS.VIEW]) || !enabled) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {configureButtonNode}
      {resourceConfigurationModalIsVisible && (
        <div onClick={(event) => event.stopPropagation()}>
          <ResourceConfigurationModal
            visible
            onClose={handleCloseModal}
            connector={connector}
            resourceType={resourceType}
            requiredFields={requiredFields}
            onRefreshList={onRefreshList}
          />
        </div>
      )}
    </div>
  );
};

ResourceConfiguration.propTypes = {
  className: PropTypes.string,
  connectorId: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  requiredFields: PropTypes.array,
  isSynchronizing: PropTypes.bool,
  enabled: PropTypes.bool,
  onRefreshList: PropTypes.func,
};

ResourceConfiguration.defaultProps = {
  className: '',
  requiredFields: [],
  isSynchronizing: false,
  enabled: false,
  onRefreshList: () => {},
};

export default ResourceConfiguration;
