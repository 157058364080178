/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';

import _keyBy from 'lodash/keyBy';
import _assign from 'lodash/assign';
import _merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';

import { deduplicateFilterFn } from '@palette/helpers/CommonHelper';

import { TYPES } from '@palette/constants/quotas';

const getQuotaUserId = (quotaUser) => quotaUser.user.id;

/*
 * Initial State
 */
const initialState = {
  getListIsPending: false,
  getByIdIsPending: false,
  createQuotaIsPending: false,
  addUsersToQuotaIsPending: false,
  updateQuotaIsPending: false,
  updateUserFromQuotaIsPending: false,
  listQuotasByUserIsPending: false,
  deleteByIdIsPending: false,
  removeUserFromQuotaIsPending: false,
  list: [],
  byId: {},
  byUserId: {},
  beingEditedPlanId: null,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'quotas',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get List And Current */
    getListAndCurrent: (state) => {
      state.getListIsPending = true;
      state.getByIdIsPending = true;
    },
    getListAndCurrentCompleted: (state) => {
      state.getListIsPending = false;
      state.getByIdIsPending = false;
    },
    /* Get List */
    getList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { quotas = [] } = payload;
      state.list = _map(quotas, 'id');

      state.byId = _merge(state.byId, _keyBy(quotas, 'id'));
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Get By Id */
    getById: (state) => {
      state.getByIdIsPending = true;
    },
    setById: (state, { payload }) => {
      const { quota, merge = false } = payload;
      if (merge) {
        state.byId = _merge(state.byId, { [quota.id]: quota });
      } else {
        state.byId = _assign(state.byId, { [quota.id]: quota });
      }
    },
    getByIdCompleted: (state) => {
      state.getByIdIsPending = false;
    },
    /* Create Quota */
    createQuota: (state) => {
      state.createQuotaIsPending = true;
    },
    createQuotaCompleted: (state) => {
      state.createQuotaIsPending = false;
    },
    /* Add Users to Quota */
    addUsersToQuota: (state) => {
      state.addUsersToQuotaIsPending = true;
    },
    addUsersToQuotaCompleted: (state) => {
      state.addUsersToQuotaIsPending = false;
    },
    /* Update Quota */
    updateQuota: (state) => {
      state.updateQuotaIsPending = true;
    },
    updateQuotaCompleted: (state) => {
      state.updateQuotaIsPending = false;
    },
    /* Update User from Quota */
    updateUserFromQuota: (state) => {
      state.updateUserFromQuotaIsPending = true;
    },
    updateUserFromQuotaCompleted: (state) => {
      state.updateUserFromQuotaIsPending = false;
    },
    /* List Quotas By User */
    listQuotasByUser: (state) => {
      state.listQuotasByUserIsPending = true;
    },
    setListByUser: (state, { payload }) => {
      const { quotas, userId } = payload;
      state.byUserId[userId] = _map(quotas, 'id');

      // Merge partial quotas into state:
      // Keeping existing quota data if possible and overriding defaultValues and users
      const originalById = original(state.byId);
      const newById = _mergeWith(
        _cloneDeep(originalById),
        _keyBy(quotas, 'id'),
        (existingQuota, receivedQuota) => {
          if (!existingQuota) return receivedQuota;

          // Merge users keeping existing user data if possible and overriding defaultValues and overwrites properties
          const users = _mergeWith(
            _keyBy(existingQuota.users, getQuotaUserId),
            _keyBy(receivedQuota.users, getQuotaUserId),
            (existingUser, receivedUser) => {
              if (!existingUser) return receivedUser;

              return ({
                ...existingUser,
                user: {
                  ...existingUser.user,
                  [existingUser.user.type]: _mergeWith(
                    existingUser.user[existingUser.user.type],
                    receivedUser.user[receivedUser.user.type],
                    (existingUserType, receivedUserType) => {
                      if (!existingUserType) return receivedUserType;

                      return existingUserType;
                    },
                  ),
                },
                defaultValues: receivedUser.defaultValues,
                overwrites: receivedUser.overwrites,
              });
            },
          );

          return {
            ...existingQuota,
            defaultValues: receivedQuota.defaultValues,
            users: Object.values(users),
          };
        },
      );

      state.byId = newById;
    },
    listQuotasByUserCompleted: (state) => {
      state.listQuotasByUserIsPending = false;
    },
    /* Delete Quota */
    deleteById: (state) => {
      state.deleteByIdIsPending = true;
    },
    deleteByIdCompleted: (state) => {
      state.deleteByIdIsPending = false;
    },
    /* Remove User From Quota */
    removeUserFromQuota: (state) => {
      state.removeUserFromQuotaIsPending = true;
    },
    removeUserFromQuotaCompleted: (state) => {
      state.removeUserFromQuotaIsPending = false;
    },
    /* Set plan being edited */
    setBeingEditedPlan: (state, { payload }) => {
      const { planId } = payload;
      state.beingEditedPlanId = planId;
    },
    /* Download Quota */
    downloadQuota: () => {
      // Nothing to do here
    },
    downloadQuotaCompleted: () => {
      // Nothing to do here
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getList = (state) => root(state).list;
const getById = (state) => root(state).byId;
const getByUserId = (state) => root(state).byUserId;
const beingEditedPlanId = (state) => root(state).beingEditedPlanId;

const getListIsPending = (state) => root(state).getListIsPending;
const getByIdIsPending = (state) => root(state).getByIdIsPending;
const createQuotaIsPending = (state) => root(state).createQuotaIsPending;
const addUsersToQuotaIsPending = (state) => root(state).addUsersToQuotaIsPending;
const updateQuotaIsPending = (state) => root(state).updateQuotaIsPending;
const updateUserFromQuotaIsPending = (state) => root(state).updateUserFromQuotaIsPending;
const listQuotasByUserIsPending = (state) => root(state).listQuotasByUserIsPending;
const deleteByIdIsPending = (state) => root(state).deleteByIdIsPending;
const removeUserFromQuotaIsPending = (state) => root(state).removeUserFromQuotaIsPending;

const getQuotasList = createSelector(
  [getList, getById],
  (listIds, byId) => listIds.map((quotaId) => byId[quotaId] || null),
);

const getQuotaById = createSelector(
  [getById, getProps],
  (byId, { quotaId }) => byId[quotaId] || null,
);

const getQuotaTypes = createSelector(
  [getById],
  (byId) => Object.values(byId).map((quota) => quota.type).concat(Object.values(TYPES)).filter(deduplicateFilterFn),
);

const getQuotaUserByIdAndQuotaId = createSelector(
  [getQuotaById, getProps],
  (quota, { quotaUserId }) => {
    if (quota == null) return null;

    const quotaUsers = quota.users.filter((quotaUser) => quotaUser.user.id === quotaUserId);
    if (quotaUsers.length > 0) return quotaUsers[0];

    return null;
  },
);

const getQuotaUsersByIdsAndQuotaId = createSelector(
  [getQuotaById, getProps],
  (quota, { quotaUserIds }) => {
    if (quota == null) return null;
    if (quotaUserIds == null) return null;

    return quota.users.filter((quotaUser) => quotaUserIds.includes(quotaUser.user.id));
  },
);

const listQuotasByUser = createSelector(
  [getByUserId, getById, getProps],
  (byUserId, byId, { userId }) => (byUserId[userId] || []).map((quotaId) => byId[quotaId] || null),
);

const getQuotasListForPeriodType = createSelector(
  [getQuotasList, getProps],
  (quotasList, { periodType }) => quotasList.filter((quota) => (quota?.periodType === periodType)),
);

export const selectors = {
  beingEditedPlanId,

  getListIsPending,
  getByIdIsPending,
  addUsersToQuotaIsPending,
  updateQuotaIsPending,
  updateUserFromQuotaIsPending,
  listQuotasByUserIsPending,
  createQuotaIsPending,
  deleteByIdIsPending,
  removeUserFromQuotaIsPending,

  getList,
  getQuotasList,
  getQuotaById,
  getQuotaTypes,
  getQuotaUserByIdAndQuotaId,
  getQuotaUsersByIdsAndQuotaId,
  listQuotasByUser,
  getQuotasListForPeriodType,
};
