import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import { message as AntDMessage } from 'antd';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';

import { ONGOING_BULK_MARK_AS_PAID_STATUS_MESSAGE_KEY } from '@palette/constants/statements';

import { selectors as StatementsSelectors } from '@palette/state/Statements';

import styles from './OngoingBulkMarkAsPaidStatus.less';

const classNames = bindClassNames.bind(styles);

const OngoingBulkMarkAsPaidStatus = ({ className }) => {
  const { t } = useTranslation();

  const ongoingBulkMarkAsPaidStatus = useSelector(StatementsSelectors.getOngoingBulkMarkAsPaidStatus);

  useEffect(() => {
    if (ongoingBulkMarkAsPaidStatus === null) return;

    const currentIndex = ongoingBulkMarkAsPaidStatus.index + 1;
    const nbToProcess = ongoingBulkMarkAsPaidStatus.statementIds.length;

    if (currentIndex > nbToProcess) {
      AntDMessage.destroy(ONGOING_BULK_MARK_AS_PAID_STATUS_MESSAGE_KEY);
      return;
    }

    const messageContentNode = (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <div className={styles.status}>
          {t('ongoingBulkMarkAsPaidStatus.status', { currentIndex, nbToProcess })}
        </div>
        <div className={styles.description}>
          {t('ongoingBulkMarkAsPaidStatus.description')}
        </div>
      </div>
    );

    AntDMessage.info(
      {
        key: ONGOING_BULK_MARK_AS_PAID_STATUS_MESSAGE_KEY,
        icon: (<LoadingLine className={styles.loadingIcon} spin />),
        content: messageContentNode,
        className: 'paletteNotification',
        duration: 0,
      },
      0,
    );
  }, [ongoingBulkMarkAsPaidStatus]);

  return null;
};

OngoingBulkMarkAsPaidStatus.propTypes = {
  className: PropTypes.string,
};

OngoingBulkMarkAsPaidStatus.defaultProps = {
  className: '',
};

export default OngoingBulkMarkAsPaidStatus;
