import PropTypes from 'prop-types';
import _invert from 'lodash/invert';
import _cloneDeep from 'lodash/cloneDeep';

import {
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { NOTEBOOK_CELL_TYPES_IDS, NOTEBOOK_CODE_CELL_EXECUTION_STATUS } from '@palette/constants/notebooks';

import { entities } from '@palette/models/globalNotifStrategies/entities';
import { manageReasonKey } from '@palette/models/GlobalNotif';

import * as NotebookDataframeModel from '@palette/models/NotebookDataframe';

export const modelName = 'NotebookCell';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  typeKey: PropTypes.string,
  type: PropTypes.oneOf(Object.values(NOTEBOOK_CELL_TYPES_IDS)),
  content: PropTypes.string,
  name: PropTypes.string,
  outputName: PropTypes.string,
  outputUniqueIdentifier: PropTypes.string,
  status: PropTypes.string,
  position: PropTypes.number,
  previewDataframe: NotebookDataframeModel.propTypes,
  lastExecutionStatus: PropTypes.oneOf(Object.values(NOTEBOOK_CODE_CELL_EXECUTION_STATUS)),
  executionsCount: PropTypes.number,
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.oneOfType([
      ...Object.values(entities).map((entity) => entity.propTypes),
      PropTypes.string,
    ]),
  }),
});

export const updateCellName = (cell, newName) => {
  const updatedCell = _cloneDeep(cell);
  updatedCell.name = newName;

  return updatedCell;
};

export const updateCellOutputName = (cell, newOutputName) => {
  const updatedCell = _cloneDeep(cell);
  updatedCell.outputName = newOutputName;

  return updatedCell;
};

export const updateCellUniqueIdentifier = (cell, uniqueIdentifier) => {
  const updatedCell = _cloneDeep(cell);
  updatedCell.outputUniqueIdentifier = uniqueIdentifier;

  return updatedCell;
};

export const updateCellContent = (cell, newContent) => {
  const updatedCell = _cloneDeep(cell);
  updatedCell.content = newContent;

  return updatedCell;
};

export const updateCellStatus = (cell, newStatus) => {
  const updatedCell = _cloneDeep(cell);
  updatedCell.status = newStatus;

  return updatedCell;
};

export const updateCellInformations = (cell, informations) => {
  const updatedCell = _cloneDeep(cell);
  return Object.keys(informations).reduce((result, key) => ({
    ...result,
    ...(key in updatedCell && { [key]: informations[key] }),
  }), updatedCell);
};

export const transform = (notebookCell) => {
  if (!notebookCell) {
    return null;
  }

  const id = manageStringAttribute(notebookCell, '_id', null) || manageStringAttribute(notebookCell, 'id', null);

  let type = NOTEBOOK_CELL_TYPES_IDS.TEXT;
  const typeKey = _invert(NOTEBOOK_CELL_TYPES_IDS)[manageStringAttribute(notebookCell, 'language')];
  if (typeKey) {
    type = NOTEBOOK_CELL_TYPES_IDS[typeKey];
  }

  const manageError = (nbCell) => {
    if (!nbCell.error) return null;

    const { code, message } = manageFreeShapeObjectAttribute(nbCell, 'error');
    const codeKey = `notebookCell.errors.type.${code}`;

    let finalMessage = message;
    const reasonKeyExists = manageReasonKey(codeKey);

    if (reasonKeyExists && typeof message === 'object') {
      finalMessage = entities[codeKey]?.transform(message);
    }

    return ({
      code: codeKey,
      message: finalMessage,
    });
  };

  return (
    {
      id,
      typeKey,
      type,
      content: manageStringAttribute(notebookCell, 'code'),
      executionsCount: manageNumberAttribute(notebookCell, 'executionsCount'),
      lastExecutionStatus: manageStringAttribute(notebookCell, 'lastExecutionStatus'),
      name: manageStringAttribute(notebookCell, 'name'),
      outputName: manageStringAttribute(notebookCell, 'outputName'),
      outputUniqueIdentifier: manageStringAttribute(notebookCell, 'outputUniqueIdentifier'),
      status: manageStringAttribute(notebookCell, 'status'),
      position: manageNumberAttribute(notebookCell, 'position'),
      previewDataframe: NotebookDataframeModel.transform(notebookCell.preview),
      error: manageError(notebookCell),
    }
  );
};

export const transformList = (notebookCells) => notebookCells.map(transform);

export const transformForService = (notebookCell) => ({
  _id: notebookCell.id ?? undefined,
  language: notebookCell.type,
  code: notebookCell.content,
  name: notebookCell.name,
  outputName: notebookCell.outputName !== '' ? notebookCell.outputName : undefined,
  outputUniqueIdentifier: notebookCell.outputUniqueIdentifier ?? 'id',
  status: notebookCell.status,
  position: notebookCell.position,
  error: notebookCell.error,
});

export const transformListForService = (notebookCells) => notebookCells.map(transformForService);
