import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actions as CompanyActions, selectors as CompanySelectors } from '@palette/state/Company';

export const useRoleInParams = (fetchRole = false) => {
  const { roleId } = useParams();
  const dispatch = useDispatch();

  const role = useSelector((state) => CompanySelectors.getRoleById(state, { roleId }));
  const isPending = useSelector((state) => CompanySelectors.getRoleByIdIsPending(state));

  useEffect(() => {
    if (fetchRole) {
      dispatch(CompanyActions.getRoleById({ roleId }));
    }
  }, [roleId]);

  return { roleId, role, isPending };
};
