import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '@palette/components/designSystem/Select/Select';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useDashboardPresetUserInSearch } from '@palette/hooks/DashboardPresetHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { IC_PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';
import { ALERT_TYPES } from '@palette/constants/alert';
import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { RIGHTS } from '@palette/constants/profile';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

import styles from './MasterPlansListForCandidateUserSelect.less';

const classNames = bindClassNames.bind(styles);

const MasterPlansListForCandidateUserSelect = ({
  className,
  value,
  onChange,
  disabled,
  filterOnPeriodType,
  filterFunc,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const { presetUserId, presetUser } = useDashboardPresetUserInSearch();

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => setInternalValue(value), [value]);

  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    setInternalValue(newValue);

    if (onChange !== null) {
      onChange(newValue);
    }
  }, [onChange]);

  const getListIsPending = useSelector(DashboardPresetsSelectors.getPlanListForCandidateUserIsPending);
  const activePlansList = useSelector((state) => DashboardPresetsSelectors.getPlansListForCandidateByType(state, { listType: IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE }));
  const pastPlansList = useSelector((state) => DashboardPresetsSelectors.getPlansListForCandidateByType(state, { listType: IC_PLANS_PAGE_CONTENT_TABS_IDS.PAST }));

  useEffect(() => {
    if (presetUser !== null && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) {
      dispatch(DashboardPresetsActions.getPlanListForCandidateUser({ candidateUserId: presetUserId }));
    }
  }, [presetUser]);

  const filteredPastPlansList = useMemo(() => {
    if (filterOnPeriodType === null && filterFunc === null) return pastPlansList;

    let finalList = pastPlansList;

    if (filterOnPeriodType !== null) {
      finalList = finalList.filter((planItem) => planItem.periodType === filterOnPeriodType);
    }

    if (filterFunc !== null) {
      finalList = finalList.filter((planItem) => filterFunc(planItem));
    }

    return finalList;
  }, [filterOnPeriodType, filterFunc, pastPlansList]);

  const filteredActivePlansList = useMemo(() => {
    if (filterOnPeriodType === null && filterFunc === null) return activePlansList;

    let finalList = activePlansList;

    if (filterOnPeriodType !== null) {
      finalList = finalList.filter((planItem) => planItem.periodType === filterOnPeriodType);
    }

    if (filterFunc !== null) {
      finalList = finalList.filter((planItem) => filterFunc(planItem));
    }

    return finalList;
  }, [filterOnPeriodType, filterFunc, activePlansList]);

  const plansListOptions = useMemo(() => {
    if (filteredActivePlansList.length === 0) {
      return filteredPastPlansList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      }));
    }

    if (filteredPastPlansList.length === 0) {
      return filteredActivePlansList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      }));
    }

    return {
      [t('masterPlansListSelect.active')]: filteredActivePlansList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      })),
      [t('masterPlansListSelect.past')]: filteredPastPlansList.map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
      })),
    };
  }, [filterOnPeriodType, filteredActivePlansList, filteredPastPlansList]);

  if (!getListIsPending && filteredActivePlansList.length === 0 && filteredPastPlansList.length === 0) {
    return (
      <Alert
        className={classNames({
          noPlanAlert: true,
          [className]: className !== '',
        })}
        type={ALERT_TYPES.WARNING}
        message={t('masterPlanList.empty.description')}
      />
    );
  }

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={plansListOptions}
      value={internalValue}
      onSelect={handleChange}
      disabled={disabled}
      placeholder={t('masterPlansListSelect.placeholder')}
      pendingOptions={plansListOptions.length === 0 && getListIsPending}
      {...otherProps}
    />
  );
};

MasterPlansListForCandidateUserSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  filterOnPeriodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  filterFunc: PropTypes.func,
};

MasterPlansListForCandidateUserSelect.defaultProps = {
  className: '',
  value: null,
  onChange: null,
  disabled: false,
  filterOnPeriodType: null,
  filterFunc: null,
};

export default MasterPlansListForCandidateUserSelect;
