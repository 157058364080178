import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import PlanV3AdvancedOptionsInputTableList from '@palette/components/planV3/PlanV3AdvancedOptionsInputTableList/PlanV3AdvancedOptionsInputTableList';

import styles from './PlanV3AdvancedOptions.less';

const classNames = bindClassNames.bind(styles);

const PlanV3AdvancedOptions = ({ className, planId }) => {
  const { t } = useTranslation();

  const titleNode = useMemo(() => (
    <div className={styles.header}>
      <div className={styles.title}>{t('editPlanPage.results.advancedOptions.title')}</div>
      <div className={styles.subtitle}>{t('editPlanPage.results.advancedOptions.subtitle')}</div>
    </div>
  ), []);

  const tableNode = useMemo(() => (
    <PlanV3AdvancedOptionsInputTableList
      planId={planId}
    />
  ), [
    planId,
  ]);

  const contentNode = useMemo(() => (
    <div className={styles.content}>
      {tableNode}
    </div>
  ), [tableNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapse}
        panels={[{
          title: titleNode,
          content: contentNode,
        }]}
        bordered={false}
        ghost
      />
    </div>
  );
};

PlanV3AdvancedOptions.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

PlanV3AdvancedOptions.defaultProps = {
  className: '',
};

export default PlanV3AdvancedOptions;
