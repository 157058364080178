import PropTypes from 'prop-types';

import { manageAmountAttribute, manageNumberAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'CommissionsTotalData';

export const propTypes = PropTypes.shape({
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  dealsCount: PropTypes.number,
  dealsType: PropTypes.string,
});

export const transform = (commissionsTotalData) => {
  if (!commissionsTotalData) {
    return null;
  }

  return (
    {
      totalCommissionAmount: manageAmountAttribute(commissionsTotalData, 'totalCommissions'),
      currency: manageStringAttribute(commissionsTotalData, 'currency'),
      dealsCount: manageNumberAttribute(commissionsTotalData, 'dealsCount'),
      dealsType: manageStringAttribute(commissionsTotalData, 'dealsType'),
    }
  );
};
