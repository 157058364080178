import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import DashboardPresetsListItem from '@palette/components/dashboard/DashboardPresetsListItem/DashboardPresetsListItem';
import Loader from '@palette/components/utils/Loader/Loader';

import { useDashboardPresetsList } from '@palette/hooks/DashboardPresetHooks';

import { selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

import styles from './DashboardPresetsPageContent.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetsPageContent = ({ className }) => {
  const getDashboardPresetsListIsPending = useSelector(DashboardPresetsSelectors.getDashboardPresetsListIsPending);
  const dashboardPresetsList = useDashboardPresetsList();

  const dashboardPresetsListItemsNodes = useMemo(() => {
    if (dashboardPresetsList.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      dashboardPresetsList.map((dashboardPreset) => (
        <DashboardPresetsListItem
          key={dashboardPreset.id}
          className={styles.listItem}
          dashboardPreset={dashboardPreset}
        />
      ))
    );
  }, [dashboardPresetsList]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Loader spinning={getDashboardPresetsListIsPending}>
        <div className={styles.list}>
          {dashboardPresetsListItemsNodes}
        </div>
      </Loader>
    </div>
  );
};

DashboardPresetsPageContent.propTypes = {
  className: PropTypes.string,
};

DashboardPresetsPageContent.defaultProps = {
  className: '',
};

export default DashboardPresetsPageContent;
