import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import UserStatementHistoryListItem from '@palette/components/statement/userStatementHistory/UserStatementHistoryListItem/UserStatementHistoryListItem';

import { useStatementUserHistoryInParams } from '@palette/hooks/StatementHistoryHooks';

import styles from './UserStatementHistoryList.less';

const classNames = bindClassNames.bind(styles);

const UserStatementHistoryList = ({ className, onPageChange, onLimitChange }) => {
  const { t } = useTranslation();

  const { historyChangesList, listByFiltersPagination } = useStatementUserHistoryInParams();

  const handlePageChange = useCallback((newPage) => {
    onPageChange(newPage);
  });

  const handleLimitChange = useCallback((newLimit) => {
    onLimitChange(newLimit);
  });

  const contentNode = useMemo(() => {
    if (historyChangesList.length === 0) {
      return (
        <DefaultEmptyState
          className={styles.emptyStateContainer}
          description={t('statement.history.empty.description')}
          size="compact"
        />
      );
    }

    return (
      <>
        {historyChangesList.map((historyChange) => (
          <UserStatementHistoryListItem
            key={`history_event_${historyChange.id}`}
            historyChange={historyChange}
          />
        ))}
        <Pagination
          className={styles.pagination}
          pagination={listByFiltersPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </>
    );
  }, [
    historyChangesList,
    listByFiltersPagination,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UserStatementHistoryList.propTypes = {
  className: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

UserStatementHistoryList.defaultProps = {
  className: '',
};

export default UserStatementHistoryList;
