export const AND_OPERATOR = '$and';

export const OR_OPERATOR = '$or';

export const REGEX_OPERATOR = '$regex';

export const OPERATORS_OPTIONS = [
  {
    label: 'Equals',
    value: '$eq',
  },
  {
    label: 'Not Equals',
    value: '$ne',
  },
  {
    label: 'Contains',
    value: '$regex',
  },
  {
    label: 'In',
    value: '$in',
  },
  {
    label: 'Not In',
    value: '$nin',
  },
  {
    label: 'Greater Than',
    value: '$gt',
  },
  {
    label: 'Greater Than Equals',
    value: '$gte',
  },
  {
    label: 'Lower Than',
    value: '$lt',
  },
  {
    label: 'Lower Than Equals',
    value: '$lte',
  },
];

export const OPERATOR_TO_STRING = {
  $eq: '=',
  $ne: 'NOT =',
  $regex: 'CONTAINS',
  $in: 'IN',
  $nin: 'NOT IN',
  $gt: '>',
  $gte: '>=',
  $lt: '<',
  $lte: '<=',
  $and: 'AND',
  $or: 'OR',
};

export const DEFAULT_CONDITION_VALUE_KEY = '__default__';
export const DEFAULT_CONDITION_VALUE_OPERATOR = OPERATORS_OPTIONS[0].value;
export const DEFAULT_CONDITION_VALUE = { [DEFAULT_CONDITION_VALUE_KEY]: { [DEFAULT_CONDITION_VALUE_OPERATOR]: '' } };

export const DEFAULT_CONDITION_DISPLAYED_FIELD = '<empty>';
export const DEFAULT_CONDITION_DISPLAYED_CODE = '<empty>';

export const INITIAL_QUERY_BUILDER_VALUE = { [AND_OPERATOR]: [] };
export const INITIAL_STRINGIFIED_QUERY_BUILDER_VALUE = JSON.stringify(INITIAL_QUERY_BUILDER_VALUE);
export const ALTERNATIVE_INITIAL_QUERY_BUILDER_VALUE = { [OR_OPERATOR]: [] };

export const DEFAULT_AND_OR_GROUP_VALUE = { [AND_OPERATOR]: [DEFAULT_CONDITION_VALUE] };
