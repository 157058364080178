import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

// import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage400 = (problem) => {
  const { detail = undefined } = problem.additionalData;
  const context = detail;

  const managedErrors = {
    // TODO
  };

  let error = null;

  if (managedErrors[problem.errorId]) {
    error = {
      code: managedErrors[problem.errorId],
      context,
    };
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    400: manage400,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code, context } = error;

    return {
      ...problem,
      message: { code, context },
    };
  }

  return manageGlobalError({ status, problem });
};
