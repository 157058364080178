import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import EditablePeriodYear from '@palette/components/frequencies/EditablePeriodYear/EditablePeriodYear';
import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';

import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as YearPeriodModel from '@palette/models/YearPeriod';

import styles from './MasterPlanPeriodInput.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodInput = ({
  className,
  periodType,
  frequency,
  fiscalYearShift,
  value,
  onChange,
  disabled,
  beginDate,
  disableBeforePeriod,
  disableAfterPeriod,
  ...otherProps
}) => {
  const moment = getMoment();

  const handleChange = useCallback((newValue) => {
    if (onChange !== null) {
      onChange(newValue);
    }
  }, [onChange]);

  const period = useMemo(() => {
    if (value === null) {
      return {
        year: moment().year(),
        period: null,
      };
    }

    return value;
  }, [value]);

  if (periodType === PERIOD_TYPES.CUSTOM) return null;

  const getPeriodContentDisplay = (periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay = null, fiscalYearShiftToDisplay = 0) => {
    let frequencyPeriodNameNode = getFrequencyPeriodName(periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay, false, fiscalYearShiftToDisplay);
    if (periodToDisplay === null) {
      frequencyPeriodNameNode = null;
    }

    return (
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {frequencyPeriodNameNode}
        </div>
        <ChevronDownLine className={styles.contentIcon} width={24} height={24} />
      </div>
    );
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      <EditablePeriodYear
        popoverVisibleClassName={styles.popoverVisible}
        periodType={periodType}
        frequency={frequency}
        fiscalYearShift={fiscalYearShift}
        initialPeriod={period.period}
        initialYear={period.year}
        onUpdate={handleChange}
        getContentDisplay={getPeriodContentDisplay}
        disabled={disabled}
        beginDate={beginDate}
        disableBeforePeriod={disableBeforePeriod}
        disableAfterPeriod={disableAfterPeriod}
      />
    </div>
  );
};

MasterPlanPeriodInput.propTypes = {
  className: PropTypes.string,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)).isRequired,
  frequency: PropTypes.number.isRequired,
  fiscalYearShift: PropTypes.number,
  value: YearPeriodModel.propTypes,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  beginDate: PropTypes.string,
  disableBeforePeriod: YearPeriodModel.propTypes,
  disableAfterPeriod: YearPeriodModel.propTypes,
};

MasterPlanPeriodInput.defaultProps = {
  className: '',
  fiscalYearShift: 0,
  value: null,
  onChange: null,
  disabled: false,
  beginDate: null,
  disableBeforePeriod: null,
  disableAfterPeriod: null,
};

export default MasterPlanPeriodInput;
