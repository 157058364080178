import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { getTypeDisplayed } from '@palette/helpers/CommonHelper';

import { EMPTY_DEFAULT_VALUE } from '@palette/constants/usersDefinition';

import * as ConnectorModel from '@palette/models/Connector';
import * as MetaUserModel from '@palette/models/MetaUser';

export const modelName = 'PlanUsersDefinition';

export const propTypes = PropTypes.shape({
  connectorId: PropTypes.string,
  connector: ConnectorModel.propTypes,
  matchingField: PropTypes.string,
  type: PropTypes.string,
  originalType: PropTypes.string,
  uiType: PropTypes.string,
  users: PropTypes.arrayOf(MetaUserModel.propTypes),
  managers: PropTypes.arrayOf(MetaUserModel.propTypes),
});

const manageUsers = (CObjectConnector, CObjectType, users = []) => {
  const enhancedUsers = users.map((user) => ({
    ...user,
    CObjectConnector,
    CObjectType,
    mongoCollection: 'CObject',
  }));
  return MetaUserModel.transformList(enhancedUsers);
};

export const transform = (planUsersDefinition) => {
  if (!planUsersDefinition) {
    return null;
  }

  let connector = null;
  let connectorId = manageStringAttribute(planUsersDefinition, 'connector', null, true);
  if (connectorId === null) {
    connector = ConnectorModel.transform(planUsersDefinition.connector);
    connectorId = connector?.id;
  }

  const originalType = manageStringAttribute(planUsersDefinition, 'type');
  let type = getTypeDisplayed(originalType);
  const uiType = manageStringAttribute(planUsersDefinition, 'uiType', null);
  if (uiType !== null && uiType.length > 0) {
    type = uiType;
  }

  let matchingField = manageStringAttribute(planUsersDefinition, 'matchingField');
  if (matchingField === EMPTY_DEFAULT_VALUE) {
    matchingField = '';
  }

  return ({
    connectorId,
    connector,
    matchingField,
    type,
    originalType,
    uiType,
    users: manageUsers(connectorId, type, manageArrayAttribute(planUsersDefinition, 'values')),
    managers: MetaUserModel.transformList(manageArrayAttribute(planUsersDefinition, 'managers')),
  });
};

export const transformList = (planUsersDefinitions) => planUsersDefinitions.map((planUsersDefinition) => transform(planUsersDefinition));

export const merge = (planUsersDefinition1, planUsersDefinition2) => {
  if (!planUsersDefinition2) return planUsersDefinition1;
  if (!planUsersDefinition1) return planUsersDefinition2;

  return planUsersDefinition2;
};
