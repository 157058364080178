import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { TAG_THEMES } from '@palette/constants/tag';

import styles from './Tag.less';

const classNames = bindClassNames.bind(styles);

const Tag = ({ className, theme, label, icon }) => {
  const iconNode = useMemo(() => {
    if (!icon) return null;

    const TagIcon = icon;

    return <TagIcon className={styles.icon} />;
  }, [icon]);

  const contentNode = useMemo(() => {
    const classNameProperty = classNames({
      wrapper: true,
      [theme]: theme !== TAG_THEMES.DEFAULT,
      [className]: className !== '',
    });

    const content = (
      <div className={styles.content}>
        {iconNode}
        {label && (
          <div
            className={classNames({
              label: true,
              'no-margin': !iconNode,
            })}
          >
            {label}
          </div>
        )}
      </div>
    );

    return (
      <div className={classNameProperty}>
        {content}
      </div>
    );
  }, [theme, className, iconNode, label]);

  return contentNode;
};

Tag.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(TAG_THEMES)),
  label: PropTypes.string,
  icon: PropTypes.func,
};

Tag.defaultProps = {
  className: '',
  theme: TAG_THEMES.DEFAULT,
  label: '',
  icon: null,
};

export default Tag;
