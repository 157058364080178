/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
 * Initial State
 */
const initialState = {
  addWidgetIsPending: false,
  updateWidgetIsPending: false,
  getWidgetsDataIsPending: false,
  moveWidgetIsPending: false,
  removeWidgetIsPending: false,

  widgetsData: [],
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Add Widget */
    addWidget: (state) => {
      state.addWidgetIsPending = true;
    },
    addWidgetCompleted: (state) => {
      state.addWidgetIsPending = false;
    },
    /* Update Widget */
    updateWidget: (state) => {
      state.updateWidgetIsPending = true;
    },
    updateWidgetCompleted: (state) => {
      state.updateWidgetIsPending = false;
    },
    /* Get Widgets Data */
    getWidgetsData: (state) => {
      state.getWidgetsDataIsPending = true;
    },
    setWidgetsData: (state, { payload }) => {
      const { widgetsData } = payload;
      state.widgetsData = widgetsData;
    },
    getWidgetsDataCompleted: (state) => {
      state.getWidgetsDataIsPending = false;
    },
    /* Move Widget */
    moveWidget: (state) => {
      state.moveWidgetIsPending = true;
    },
    moveWidgetCompleted: (state) => {
      state.moveWidgetIsPending = false;
    },
    /* Remove Widget */
    removeWidget: (state) => {
      state.removeWidgetIsPending = true;
    },
    removeWidgetCompleted: (state) => {
      state.removeWidgetIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
// const getProps = (_, props) => props;

const addWidgetIsPending = (state) => root(state).addWidgetIsPending;
const updateWidgetIsPending = (state) => root(state).updateWidgetIsPending;
const getWidgetsDataIsPending = (state) => root(state).getWidgetsDataIsPending;
const moveWidgetIsPending = (state) => root(state).moveWidgetIsPending;
const removeWidgetIsPending = (state) => root(state).removeWidgetIsPending;

const widgetsData = (state) => root(state).widgetsData;

export const selectors = {
  addWidgetIsPending,
  updateWidgetIsPending,
  getWidgetsDataIsPending,
  moveWidgetIsPending,
  removeWidgetIsPending,

  widgetsData,
};
