import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import '../../../styles/admin/sourceVignette.less';

const SourceVignette = ({
  name, type, icon, disabled, onClick,
}) => (
  <div
    onClick={disabled ? null : onClick}
    className={`Source_vignette_wrapper ${disabled ? 'Source_vignette_wrapper_disabled' : ''}`}
  >
    <div className="Source_vignette_icon">{icon}</div>
    <div className="Source_vignette_content_wrapper">
      <div className="Source_vignette_name title bold flex">
        <div style={{ flexGrow: '1', whiteSpace: 'nowrap' }}>{name}</div>
        {disabled && <Tag color="grey" style={{ marginRight: 0, background: '#c5c5c7' }}>Linked</Tag>}

      </div>
      <div className="Source_vignette_type">{type}</div>
    </div>
  </div>
);

SourceVignette.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

SourceVignette.defaultProps = {
  onClick: () => {},
  disabled: false,
};
export default SourceVignette;
