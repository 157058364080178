import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Table from '@palette/components/designSystem/Table/Table';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Tag from '@palette/components/designSystem/Tag/Tag';
import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './HistoryExchangeRateModal.less';

const classNames = bindClassNames.bind(styles);

const HistoryExchangeRateModal = ({ visible, from, to, onAddNewRateClick, onClose }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const moment = getMoment();
  const dispatch = useDispatch();

  const { company } = profile.userData;

  const getListFxRatesIsPending = useSelector(ProfileSelectors.getListFxRatesIsPending);

  useEffect(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return;

    if (from && to) {
      dispatch(ProfileActions.getListFxRates({ from, to }));
    }
  }, [from, to]);

  const deleteFxRate = (event, fxRateId) => {
    event.stopPropagation();
    dispatch(ProfileActions.deleteFxRate({ fxRateId, from, to }));
  };

  const columns = useMemo(() => ([
    {
      Header: t('companyExchangeRates.history.modal.table.date'),
      accessor: ({ startDate, isCurrent }) => ({ startDate, isCurrent }),
      width: '60%',
      minWidth: '60%',
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div className={classNames('cell', 'dateCell')}>
          <span>{moment(value.startDate).format('lll')}</span>
          {value.isCurrent && (
            <Tag
              className={styles.tagCurrent}
              theme={TAG_THEMES.INFO}
              label={t('common.global.current')}
            />
          )}
        </div>
      ),
      /* eslint-enable react/prop-types */
    },
    {
      Header: t('companyExchangeRates.history.modal.table.rate'),
      accessor: ({ historyRate }) => ({ historyRate }),
      width: '30%',
      minWidth: '30%',
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div className={classNames('cell', 'rateCell')}>
          {value.historyRate}
        </div>
      ),
      /* eslint-enable react/prop-types */
    },
    {
      Header: ' ',
      accessor: ({ id }) => ({ id }),
      width: '10%',
      minWidth: '10%',
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div className={classNames('cell', 'trashCell')}>
          <Popconfirm
            title={t('companyExchangeRates.history.modal.delete.popconfirm')}
            onConfirm={(event) => deleteFxRate(event, value.id)}
            okText={t('common.global.yes')}
            cancelText={t('common.global.no')}
            size="small"
          >
            <Button
              className={styles.icon}
              type="linkDestroy"
              icon={<TrashFilled width={16} height={16} />}
            />
          </Popconfirm>
        </div>
      ),
      /* eslint-enable react/prop-types */
    },
  ]), []);

  const contentNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

    if ((getListFxRatesIsPending || !company.listFxRates) && !company.listFxRates?.length >= 1) {
      return <LoadingLine className={styles.laoderIcon} width={20} height={20} spin />;
    }

    if (!company.listFxRates.length) return null;

    return (
      <div className={styles.table}>
        <Table data={company.listFxRates} columns={columns} stickyHeader stretch />
      </div>
    );
  }, [profile, company, getListFxRatesIsPending, columns]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('companyExchangeRates.history.modal.title')}
        </div>
      )}
      visible={visible}
      onCancel={onClose}
      displayFooter={false}
    >
      <div className={styles.details}>
        <Tag
          theme={TAG_THEMES.INFO}
          label={from}
        />
        <ChevronRightLine className={styles.chevronRight} />
        <Tag
          theme={TAG_THEMES.INFO}
          label={to}
        />
      </div>
      <div className={styles.listWrapper}>
        {contentNode}
      </div>
      <Button
        className={styles.newRateButton}
        type="link"
        icon={<AddFilled width={20} height={20} />}
        onClick={onAddNewRateClick}
      >
        {t('companyExchangeRates.button.add.label')}
      </Button>
    </Modal>
  );
};

HistoryExchangeRateModal.propTypes = {
  visible: PropTypes.bool,
  from: PropTypes.string,
  to: PropTypes.string,
  onAddNewRateClick: PropTypes.func,
  onClose: PropTypes.func,
};

HistoryExchangeRateModal.defaultProps = {
  visible: false,
  from: '',
  to: '',
  onAddNewRateClick: () => {},
  onClose: () => {},
};

export default HistoryExchangeRateModal;
