import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Tooltip as AntDTooltip } from 'antd';

import styles from './Tooltip.less';

const classNames = bindClassNames.bind(styles);

const Tooltip = ({
  className,
  overlayClassName,
  children,
  title,
  content,
  ...otherProps
}) => {
  let titleNode = (
    <div className={styles.title}>
      {title}
    </div>
  );
  if (content !== null) {
    titleNode = (
      <div className={styles.titleWithContent}>
        {titleNode}
        <div className={styles.content}>
          {content}
        </div>
      </div>
    );
  }

  return (
    <AntDTooltip
      className={classNames({
        [className]: className !== '',
      })}
      overlayClassName={classNames({
        overlay: true,
        [overlayClassName]: overlayClassName !== '',
      })}
      title={titleNode}
      {...otherProps}
    >
      {children}
    </AntDTooltip>
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
};

Tooltip.defaultProps = {
  className: '',
  overlayClassName: '',
  children: null,
  title: null,
  content: null,
};

export default Tooltip;
