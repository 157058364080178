import PropTypes from 'prop-types';

export const modelName = 'WidgetDescription';

export const propTypes = PropTypes.shape({
  type: PropTypes.string,
  nameI18NId: PropTypes.string,
  descriptionI18NId: PropTypes.string,
  previewPictureSrc: PropTypes.string,
});
