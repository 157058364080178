import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';

import {
  manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PlanV3InputTable';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  planId: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  variableName: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    position: PropTypes.number,
  })),
  rows: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.number,
    values: PropTypes.arrayOf(PropTypes.shape({
      columnId: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })),
  })),
});

export const transform = (inputTable) => {
  if (!inputTable) {
    return null;
  }

  const columnsRaw = manageArrayAttribute(inputTable, 'columns');
  const columns = _orderBy(columnsRaw, ['position'], ['asc']);

  const rowsRaw = manageArrayAttribute(inputTable, 'rows');
  const rows = _orderBy(rowsRaw, ['position'], ['asc']);

  return (
    {
      id: manageStringAttribute(inputTable, 'id'),
      planId: manageStringAttribute(inputTable, 'planId'),
      type: manageStringAttribute(inputTable, 'type'),
      name: manageStringAttribute(inputTable, 'name'),
      variableName: manageStringAttribute(inputTable, 'variableName'),
      columns,
      rows,
    }
  );
};

export const transformList = (inputTables) => inputTables.map((inputTable) => transform(inputTable));
