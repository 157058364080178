import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { ContainerOutlined as AntDContainerOutlined } from '@ant-design/icons';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import CopyNotebookModal from '@palette/components/notebooks/CopyNotebookModal/CopyNotebookModal';
import ArchiveNotebookModal from '@palette/components/notebooks/ArchiveNotebookModal/ArchiveNotebookModal';
import UnarchiveNotebookModal from '@palette/components/notebooks/UnarchiveNotebookModal/UnarchiveNotebookModal';
import DeleteNotebookModal from '@palette/components/notebooks/DeleteNotebookModal/DeleteNotebookModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { NOTEBOOK_STATUSES, NOTEBOOK_TYPES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './NotebooksProjectsListItemMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const NotebooksProjectsListItemMoreOptionsMenu = ({ className, notebook }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [copyNotebookModalIsVisible, showCopyNotebookModal] = useState(false);
  const [archiveNotebookModalIsVisible, showArchiveNotebookModal] = useState(false);
  const [unarchiveNotebookModalIsVisible, showUnarchiveNotebookModal] = useState(false);
  const [deleteNotebookModalIsVisible, showDeleteNotebookModal] = useState(false);

  const moreMenuItems = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])) return [];

    const menuItems = [];

    if (notebook.status !== NOTEBOOK_STATUSES.ARCHIVED) {
      const copyNotebookMenuItem = {
        key: 'copyNotebook',
        title: t('notebooksProjectsListItemMoreOptionsMenu.copyNotebook'),
        icon: (
          <DuplicateFilled width={16} height={16} />
        ),
        onClick: () => {
          showCopyNotebookModal(true);
        },
      };

      menuItems.push(copyNotebookMenuItem);
    }

    if (notebook.status !== NOTEBOOK_STATUSES.ARCHIVED && notebook.type !== NOTEBOOK_TYPES.TEMPLATE) {
      const archiveNotebookMenuItem = {
        key: 'archiveNotebook',
        title: t('notebooksProjectsListItemMoreOptionsMenu.archiveNotebook'),
        icon: (
          <AntDContainerOutlined width={16} height={16} />
        ),
        onClick: () => {
          showArchiveNotebookModal(true);
        },
      };

      menuItems.push(archiveNotebookMenuItem);
    }

    if (notebook.status === NOTEBOOK_STATUSES.ARCHIVED && notebook.type !== NOTEBOOK_TYPES.TEMPLATE) {
      const unarchiveNotebookMenuItem = {
        key: 'unarchiveNotebook',
        title: t('notebooksProjectsListItemMoreOptionsMenu.unarchiveNotebook'),
        icon: (
          <AntDContainerOutlined width={16} height={16} />
        ),
        onClick: () => {
          showUnarchiveNotebookModal(true);
        },
      };

      menuItems.push(unarchiveNotebookMenuItem);
    }

    if (notebook.type !== NOTEBOOK_TYPES.TEMPLATE) {
      const deleteNotebookMenuItem = {
        key: 'deleteNotebook',
        title: t('notebooksProjectsListItemMoreOptionsMenu.deleteNotebook'),
        icon: (
          <TrashFilled width={16} height={16} />
        ),
        onClick: () => {
          showDeleteNotebookModal(true);
        },
      };

      menuItems.push(deleteNotebookMenuItem);
    }

    return menuItems;
  }, [
    profile,
    notebook,
    showCopyNotebookModal,
    showArchiveNotebookModal,
    showUnarchiveNotebookModal,
    showDeleteNotebookModal,
  ]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={moreMenuItems}
      />
      {
        copyNotebookModalIsVisible && (
          <CopyNotebookModal visible onClose={() => showCopyNotebookModal(false)} notebook={notebook} />
        )
      }
      {
        archiveNotebookModalIsVisible && (
          <ArchiveNotebookModal visible onClose={() => showArchiveNotebookModal(false)} notebook={notebook} />
        )
      }
      {
        unarchiveNotebookModalIsVisible && (
          <UnarchiveNotebookModal visible onClose={() => showUnarchiveNotebookModal(false)} notebook={notebook} />
        )
      }
      {
        deleteNotebookModalIsVisible && (
          <DeleteNotebookModal visible onClose={() => showDeleteNotebookModal(false)} notebook={notebook} />
        )
      }
    </div>
  );
};

NotebooksProjectsListItemMoreOptionsMenu.propTypes = {
  notebook: NotebookModel.propTypes.isRequired,
  className: PropTypes.string,
};

NotebooksProjectsListItemMoreOptionsMenu.defaultProps = {
  className: '',
};

export default NotebooksProjectsListItemMoreOptionsMenu;
