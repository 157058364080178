import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import * as WidgetDescriptionModel from '@palette/models/WidgetDescription';

import styles from './WidgetCard.less';

const classNames = bindClassNames.bind(styles);

const WidgetCard = ({ className, widgetDescription, selected, onClick }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (onClick !== null) {
      onClick(widgetDescription.type);
    }
  }, [onClick]);

  return (
    <div
      className={classNames({
        wrapper: true,
        selected,
        [className]: className !== '',
      })}
      onClick={handleClick}
    >
      <div className={styles.name}>
        {t(widgetDescription.nameI18NId)}
      </div>
      <div className={styles.description}>
        {t(widgetDescription.descriptionI18NId)}
      </div>
      <div className={styles.pictureWrapper}>
        <img
          className={styles.picture}
          src={widgetDescription.previewPictureSrc}
          alt=""
        />
      </div>
    </div>
  );
};

WidgetCard.propTypes = {
  className: PropTypes.string,
  widgetDescription: WidgetDescriptionModel.propTypes.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

WidgetCard.defaultProps = {
  className: '',
  selected: false,
  onClick: null,
};

export default WidgetCard;
