import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import OnboardUsersFlowPageStepsList from '@palette/components/userOnboarding/OnboardUsersFlowPageStepsList/OnboardUsersFlowPageStepsList';
import OnboardUsersFlowPageStepsManagement from '@palette/components/userOnboarding/OnboardUsersFlowPageStepsManagement/OnboardUsersFlowPageStepsManagement';

import styles from './OnboardUsersFlowPageGeneralContent.less';

const classNames = bindClassNames.bind(styles);

const OnboardUsersFlowPageGeneralContent = ({ className }) => {
  const { t } = useTranslation();

  const stepsListNode = useMemo(() => (
    <OnboardUsersFlowPageStepsList />
  ), []);

  const stepsManagementNode = useMemo(() => (
    <OnboardUsersFlowPageStepsManagement />
  ), []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.leftSide}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {t('onboardUsersFlowSteps.title')}
          </h2>
        </div>
        <div className={styles.steps}>
          {stepsListNode}
        </div>
      </div>
      <div className={styles.main}>
        {stepsManagementNode}
      </div>
    </div>
  );
};

OnboardUsersFlowPageGeneralContent.propTypes = {
  className: PropTypes.string,
};

OnboardUsersFlowPageGeneralContent.defaultProps = {
  className: '',
};

export default OnboardUsersFlowPageGeneralContent;
