import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';

import * as QuotaModel from '@palette/models/Quota';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { QUOTA_ACTIONS } from '@palette/constants/quotas';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './EditableQuotaName.less';

const classNames = bindClassNames.bind(styles);

const EditableQuotaName = ({ className, quota }) => {
  const profile = useProfile();

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [quotaName, setQuotaName] = useState(quota.name);

  useEffect(() => {
    setQuotaName(quota.name);
  }, [quota.name]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const updateQuotaIsPending = useSelector(QuotasSelectors.updateQuotaIsPending);

  useEffect(() => {
    if (!updateQuotaIsPending) {
      setEditing(false);
    }
  }, [updateQuotaIsPending]);

  const saveUpdate = () => {
    if (quota.name !== quotaName) {
      dispatch(QuotasActions.updateQuota({ quotaId: quota.id, name: quotaName, type: quota.type, defaultValues: quota.defaultValues, action: QUOTA_ACTIONS.UPDATE_QUOTA_NAME }));
    } else {
      setEditing(false);
    }
  };

  const handleInputChange = (newValue) => {
    setQuotaName(newValue);
  };

  const switchToEditMode = () => {
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE]) && !quota.archived) {
      setEditing(true);
    }
  };

  if (editing) {
    return (
      <Input
        ref={inputRef}
        className={styles.input}
        type="text"
        value={quotaName}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={saveUpdate}
      />
    );
  }

  return (
    <h1
      className={classNames({
        wrapper: true,
        disabled: quota.archived,
        [className]: className !== '',
      })}
      onClick={switchToEditMode}
    >
      {quota.name}
    </h1>
  );
};

EditableQuotaName.propTypes = {
  className: PropTypes.string,
  quota: QuotaModel.propTypes.isRequired,
};

EditableQuotaName.defaultProps = {
  className: '',
};

export default EditableQuotaName;
