import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as ProfileAccountInvalidCredentialsStrategyModel from '@palette/models/globalNotifStrategies/profile/ProfileAccountInvalidCredentialsStrategy';

const classNames = bindClassNames.bind();

const ProfileAccountInvalidCredentialsContentStrategy = ({ className, content }) => {
  const { remaining } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="profile.errors.remaining.attempts"
        values={{ count: remaining }}
      />
    </span>
  );
};

ProfileAccountInvalidCredentialsContentStrategy.propTypes = {
  className: PropTypes.string,
  content: ProfileAccountInvalidCredentialsStrategyModel.propTypes.isRequired,
};

ProfileAccountInvalidCredentialsContentStrategy.defaultProps = {
  className: '',
};

export default ProfileAccountInvalidCredentialsContentStrategy;
