import { getMoment } from '@palette/helpers/MomentHelper';

export const manageBooleanAttribute = (model, attrName, defaultValue = false) => (
  model[attrName] != null ? model[attrName] : defaultValue
);

export const manageDateAttribute = (model, attrName, defaultValue = null, inUTC = true) => {
  const moment = getMoment();
  if (model[attrName] != null) {
    return inUTC ? moment.utc(model[attrName]).format() : moment(model[attrName]).format();
  }

  return defaultValue;
};

export const manageNumberAttribute = (model, attrName, defaultValue = 0, strict = false) => {
  if (model[attrName] == null) return defaultValue;
  if (strict && Number.isNaN(model[attrName])) return defaultValue;

  return parseInt(model[attrName], 10);
};

export const manageFloatAttribute = (model, attrName, defaultValue = 0.00) => (
  model[attrName] != null ? parseFloat(model[attrName]).toFixed(2) : defaultValue
);

export const managePureFloatAttribute = (model, attrName, defaultValue = 0.00) => (
  model[attrName] != null ? parseFloat(model[attrName]) : defaultValue
);

export const manageStringAttribute = (model, attrName, defaultValue = '', strict = false) => {
  if (model[attrName] == null) return defaultValue;
  if (strict && typeof (model[attrName]) !== 'string') return defaultValue;

  return model[attrName].toString();
};

export const manageArrayAttribute = (model, attrName, defaultValue = []) => (
  model[attrName] != null && Array.isArray(model[attrName]) ? model[attrName] : defaultValue
);

export const manageFreeShapeObjectAttribute = (model, attrName, defaultValue = {}) => (
  model[attrName] != null && typeof (model[attrName]) === 'object' ? model[attrName] : defaultValue
);

export const manageAmountAttribute = (model, attrName, defaultValue = 0.00) => {
  let amount = manageStringAttribute(model, attrName, null, true);
  const amountToParse = { amount: amount || model[attrName] };
  amount = managePureFloatAttribute(amountToParse, 'amount', defaultValue);
  if (amount && amount % 1 === 0) {
    amount = parseInt(amount, 10);
  }

  return amount;
};
