import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import CommissionPerTargetRuleDetails from '@palette/components/commission/CommissionPerTargetRuleDetails/CommissionPerTargetRuleDetails';
import CommissionCustomCodeRuleDetails from '@palette/components/commission/CommissionCustomCodeRuleDetails/CommissionCustomCodeRuleDetails';
import CommissionPerObjectRuleDetails from '@palette/components/commission/CommissionPerObjectRuleDetails/CommissionPerObjectRuleDetails';

import { RULE_TYPES } from '@palette/constants/masterPlans';

import * as CommissionModel from '@palette/models/Commission';

const CommissionRuleDetails = ({ className, commission }) => {
  const rule = useMemo(() => commission.scopeParams?.rule, [commission]);

  const contentNode = useMemo(() => {
    if (!rule) return null;

    if (rule.type === RULE_TYPES.PER_TARGET) {
      return (
        <CommissionPerTargetRuleDetails className={className} commission={commission} />
      );
    }

    if (rule.type === RULE_TYPES.CODE_ONLY) {
      return (
        <CommissionCustomCodeRuleDetails className={className} commission={commission} />
      );
    }

    return (
      <CommissionPerObjectRuleDetails className={className} commission={commission} />
    );
  }, [rule]);

  return contentNode;
};

CommissionRuleDetails.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionRuleDetails.defaultProps = {
  className: '',
};

export default CommissionRuleDetails;
