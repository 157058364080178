import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import {
  COMMISSION_DRAWER_COMMISION_ID_QS_KEY,
  SEARCHED_FROM_QS_KEY,
  SEARCHED_TO_QS_KEY,
} from '@palette/constants/commissions';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import routePaths from '@palette/config/routePaths';

import * as CommissionDealValueOverwriteStrategyModel from '@palette/models/statementHistoryStrategies/CommissionDealValueOverwriteStrategy';

const classNames = bindClassNames.bind();

const CommissionDealValueOverwriteContentStrategy = ({ className, details, i18nAction }) => {
  const {
    commissionId,
    amount,
    currency,
    currentValue,
    previousValue,
    dealName,
    planId,
    planName,
  } = details;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey={`statement.history.list.content.${i18nAction}`}
        values={{
          name: dealName,
          amount: formatPrice(amount, currency),
          planName,
          previousValue,
          currentValue,
        }}
        components={[
          <Link
            key={`${i18nAction}-commission-link`}
            path={routePaths.v2.commissionList}
            qsObject={{
              [COMMISSION_DRAWER_COMMISION_ID_QS_KEY]: commissionId,
              [SEARCHED_FROM_QS_KEY]: '',
              [SEARCHED_TO_QS_KEY]: '',
            }}
          />,
          <Link
            key={`${i18nAction}-plan-link`}
            path={routePaths.v2.planDetails}
            params={{ masterPlanId: planId }}
          />,
        ]}
      />
    </span>
  );
};

CommissionDealValueOverwriteContentStrategy.propTypes = {
  className: PropTypes.string,
  details: CommissionDealValueOverwriteStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

CommissionDealValueOverwriteContentStrategy.defaultProps = {
  className: '',
};

export default CommissionDealValueOverwriteContentStrategy;
