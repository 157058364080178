import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import MasterPlanSettingsTabs from '@palette/components/masterPlanSettings/MasterPlanSettingsTabs/MasterPlanSettingsTabs';

import { useMasterPlanInParams } from '@palette/hooks/MasterPlanHooks';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsPageContent.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsPageContent = ({ className }) => {
  const dispatch = useDispatch();

  const { masterPlanId, plan } = useMasterPlanInParams();

  useEffect(() => {
    if (masterPlanId) {
      dispatch(MasterPlansActions.getById({ planId: masterPlanId }));
    }
  }, [masterPlanId]);

  if (plan === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MasterPlanSettingsTabs plan={plan} />
    </div>
  );
};

MasterPlanSettingsPageContent.propTypes = {
  className: PropTypes.string,
};

MasterPlanSettingsPageContent.defaultProps = {
  className: '',
};

export default MasterPlanSettingsPageContent;
