import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import AllCurrenciesSelect from '@palette/components/utils/AllCurrenciesSelect/AllCurrenciesSelect';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './ExchangeRateModal.less';

const ExchangeRateModal = ({
  visible,
  type,
  from,
  to,
  rate,
  onClose,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const moment = getMoment();
  const dispatch = useDispatch();

  const createOrUpdateFxRateIsPending = useSelector(ProfileSelectors.createOrUpdateFxRateIsPending);

  const [alertMsg, setAlertMsg] = useState(null);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    from: type === 'edit' ? from || null : null,
    to: type === 'edit' ? to || null : null,
    rate: type === 'edit' ? rate || 1 : 1,
    startDate: moment(),
  }), []);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const cleanAndClose = () => {
    onClose();
  };

  const handleFinish = useCallback((values) => {
    dispatch(ProfileActions.createFxRate({
      ...values,
      onSuccessCB: cleanAndClose,
    }));
  }, []);

  const handleSubmit = () => form.submit();

  const computeFxRateSampleMsg = useCallback((changedValues, allValues) => {
    let values;
    let renderMsg;

    if (!changedValues && !allValues) {
      values = initialValues;
    } else {
      values = {
        ...allValues,
        ...changedValues,
      };
    }

    if (!values.from || !values.to) {
      renderMsg = null;
    } else if (values.rate !== null) {
      renderMsg = t('companyExchangeRates.modal.alert.label', {
        from: values.from,
        rate: values.rate,
        to: values.to,
      });
    } else {
      renderMsg = t('companyExchangeRates.modal.alert.error.label');
    }

    setAlertMsg(renderMsg);
  }, [initialValues]);

  useEffect(() => {
    computeFxRateSampleMsg();
  }, []);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t(`companyExchangeRates.modal.title.${type}`)}
        </div>
      )}
      visible
      onCancel={cleanAndClose}
      onOk={handleSubmit}
      okText={t(`companyExchangeRates.modal.save.label.${type}`)}
      okIcon={<CheckFilled />}
      loading={createOrUpdateFxRateIsPending}
    >
      <div className={styles.content}>
        <Form
          onFinish={handleFinish}
          initialValues={initialValues}
          form={form}
          onValuesChange={computeFxRateSampleMsg}
        >
          <FormItem
            name="from"
            label={t('companyExchangeRates.modal.select.label.from')}
            required
            rules={[
              { required: true, message: t('companyExchangeRates.modal.select.error.message') },
            ]}
          >
            <AllCurrenciesSelect
              disabled={createOrUpdateFxRateIsPending || type === 'edit'}
              placeholder={t('companyExchangeRates.modal.select.placeholder')}
            />
          </FormItem>
          <FormItem
            name="to"
            label={t('companyExchangeRates.modal.select.label.to')}
            required
            rules={[
              { required: true, message: t('companyExchangeRates.modal.select.error.message') },
            ]}
          >
            <AllCurrenciesSelect
              disabled={createOrUpdateFxRateIsPending || type === 'edit'}
              placeholder={t('companyExchangeRates.modal.select.placeholder')}
            />
          </FormItem>
          <FormItem
            name="rate"
            label={t('companyExchangeRates.modal.input.label.rate')}
            required
            rules={[
              { required: true, message: t('companyExchangeRates.modal.rate.error.message'), type: 'number' },
            ]}
          >
            <Input placeholder={t('companyExchangeRates.modal.input.placeholder.rate')} type="number" />
          </FormItem>
          {alertMsg !== null && (
            <Alert
              className={styles.alert}
              type={ALERT_TYPES.INFO}
              message={alertMsg}
            />
          )}
          <FormItem
            name="startDate"
            label={t('companyExchangeRates.modal.input.label.startDate')}
            required
            rules={[
              { required: true, message: t('companyExchangeRates.modal.startDate.error.message'), type: 'date' },
            ]}
          >
            <DatePicker
              className={styles.dateField}
              picker="date"
              allowClear={false}
              disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
              showTime
              showNow={false}
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
};

ExchangeRateModal.propTypes = {
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['add', 'edit']),
  from: PropTypes.string,
  to: PropTypes.string,
  rate: PropTypes.number,
  onClose: PropTypes.func,
};

ExchangeRateModal.defaultProps = {
  visible: false,
  type: 'add',
  from: '',
  to: '',
  rate: 1,
  onClose: () => {},
};

export default ExchangeRateModal;
