import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { capitalize, isFakedNumber } from '@palette/helpers/CommonHelper';
import { myEval } from '@palette/helpers/EvalHelper';
import { getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';
import { getPlanLastPeriod } from '@palette/helpers/MasterPlanHelper';

import * as PlanModel from '@palette/models/MasterPlan';
import * as PayoutRuleModel from '@palette/models/PayoutRule';
import * as YearPeriodModel from '@palette/models/YearPeriod';

import styles from './MasterPlanSettingsPayoutRulesStepItem.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsPayoutRulesStepItem = ({ className, plan, period, rule, isLast }) => {
  const { t } = useTranslation();

  const periodEndMomentDate = useMemo(() => {
    if (period !== null) {
      return getMomentDateFromPeriod(plan.periodType, plan.frequency, period, plan.customBeginAt, true, plan.fiscalYearShift);
    }

    const planLastPeriod = getPlanLastPeriod(plan);

    return getMomentDateFromPeriod(plan.periodType, plan.frequency, planLastPeriod, plan.customBeginAt, true, plan.fiscalYearShift);
  }, [plan, period]);

  const ruleNode = useMemo(() => {
    if (rule.code) {
      if (rule.conditionFormula !== 'true') {
        return (
          <div className={styles.rule}>
            <span className={styles.title}>{t('masterPlanSettingsPayoutRules.ruleStep.conditionMet')}</span>
            <span className={styles.highlightedText}>{`If (${rule.conditionFormula})`}</span>
            <span className={styles.text}>{t('masterPlanSettingsPayoutRules.ruleStep.definedByCustom')}</span>
          </div>
        );
      }
      if (rule.conditionFormula === 'true' && plan.payoutRules.some((r) => r.conditionFormula !== 'true') && isLast) {
        return (
          <div className={styles.rule}>
            <span className={styles.title}>{t('masterPlanSettingsPayoutRules.ruleStep.noOtheronditionMet')}</span>
            <span className={styles.text}>{t('masterPlanSettingsPayoutRules.ruleStep.definedByCustom')}</span>
          </div>
        );
      }
      return (
        <div className={styles.rule}>
          <span className={styles.text}>{t('masterPlanSettingsPayoutRules.ruleStep.definedByCustom')}</span>
        </div>
      );
    }

    return rule.payments.map((payment, index) => {
      let frequency = '';
      let statement = '';
      let startDate = '';
      let lastWord = '';
      let lastWord2 = '';

      if (payment.repeatFormula !== '1') {
        frequency = t('masterPlanSettingsPayoutRulesStepInPaymentWithCount', { count: parseInt(payment.repeatFormula, 10) });

        if (payment.intervalCount > 0) {
          const type = t(`masterPlanSettingsPayoutRulesStepIntervalType${capitalize(payment.intervalType)}`, { count: payment.intervalCount });

          frequency += t('masterPlanSettingsPayoutRules.ruleStep.intervalType.every', {
            count: payment.intervalCount,
            type,
          });
        }

        frequency += '.';
      }

      statement = t('masterPlanSettingsPayoutRules.ruleStep.statement');

      if (payment.repeatFormula !== '1') {
        statement = t('masterPlanSettingsPayoutRules.ruleStep.starting');
      }

      startDate = myEval(`moment(${payment.startingAtFormula}).format("MMMM YYYY")`, {
        fieldsValues: {
          Deal: {
            dateValue: periodEndMomentDate.subtract('15', 'days'),
          },
          Plan: {
            endAt: periodEndMomentDate,
          },
          Period: {
            endAt: periodEndMomentDate,
          },
        },
      }, payment.startingAtFormula);

      if (rule.conditionFormula !== 'true') {
        lastWord = t('masterPlanSettingsPayoutRules.ruleStep.conditionMet');
        lastWord2 = `If (${rule.conditionFormula})`;
      }

      if (rule.conditionFormula === 'true' && plan.payoutRules.some((r) => r.conditionFormula !== 'true') && isLast) {
        lastWord = t('masterPlanSettingsPayoutRules.ruleStep.noOtheronditionMet');
      }

      return (
        <div className={styles.rule} key={index}>
          <span className={styles.title}>
            {t('masterPlanSettingsPayoutRules.ruleStep.payXOfCommission', {
              commission: isFakedNumber(payment.percentageFormula) ? `${(100 * payment.percentageFormula).toFixed(0)}%` : payment.percentageFormula,
            })}
          </span>
          <span className={styles.text}>{frequency}</span>
          <span className={styles.highlightedText}>{statement}</span>
          <span className={styles.text}>{startDate}</span>
          {lastWord && <span className={styles.highlightedText}>{lastWord}</span>}
          {lastWord2 && <span className={styles.text}>{lastWord2}</span>}
        </div>
      );
    });
  }, [plan, periodEndMomentDate, rule, isLast]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {ruleNode}
    </div>
  );
};

MasterPlanSettingsPayoutRulesStepItem.propTypes = {
  className: PropTypes.string,
  plan: PlanModel.propTypes.isRequired,
  period: YearPeriodModel.propTypes,
  rule: PayoutRuleModel.propTypes.isRequired,
  isLast: PropTypes.bool,
};

MasterPlanSettingsPayoutRulesStepItem.defaultProps = {
  className: '',
  period: null,
  isLast: false,
};

export default MasterPlanSettingsPayoutRulesStepItem;
