import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _flatMap from 'lodash/flatMap';

import { FileOutlined, MenuOutlined } from '@ant-design/icons';

import HierarchyLine from '@palette/components/utils/Icons/HierarchyLine';
import ArrowRightSquareLine from '@palette/components/utils/Icons/ArrowRightSquareLine';

import styles from './AlgoliaSearchResultLvl0.less';

const classNames = bindClassNames.bind(styles);

const AlgoliaSearchResultLvl0 = ({
  className,
  title,
  items,
  getItemProps,
  getListProps,
  ...otherProps
}) => {
  /* eslint-disable no-underscore-dangle, react/no-danger */
  const itemsNodes = useMemo(() => {
    const groupedItems = items.reduce((groups, item) => {
      if (item.hierarchy[item.type] && item.type === 'lvl1') {
        const groupKey = item.hierarchy[item.type];
        const groupDescription = {
          parent: item,
          itemList: [item, ...(groups[groupKey]?.itemList || [])],
        };

        return {
          ...groups,
          [groupKey]: groupDescription,
        };
      }

      const groupKey = item.hierarchy.lvl1;
      const groupDescription = {
        parent: groupKey,
        itemList: [...(groups[groupKey]?.itemList || []), { ...item, hasParent: true }],
      };

      return {
        ...groups,
        [groupKey]: groupDescription,
      };
    }, {});

    return _flatMap(groupedItems, (group) => (
      group.itemList.map((item) => {
        let contentNode = null;
        if (item.hierarchy[item.type] && item.type === 'lvl1') {
          contentNode = (
            <>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: (
                    item._snippetResult?.hierarchy[item.type]?.value
                    || item?.hierarchy ? item?.hierarchy[item.type] : ''
                  ),
                }}
              />
              {
                item.content && (
                  <div
                    className={styles.details}
                    dangerouslySetInnerHTML={{
                      __html: (
                        item._snippetResult?.content?.value
                        || item?.content
                      ),
                    }}
                  />
                )
              }
            </>
          );
        }

        if (item.hierarchy[item.type] && item.type !== 'lvl1') {
          contentNode = (
            <>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: (
                    item._snippetResult.hierarchy[item.type]?.value
                    || item?.hierarchy ? item.hierarchy[item.type] : ''
                  ),
                }}
              />
              <div
                className={styles.details}
                dangerouslySetInnerHTML={{
                  __html: (
                    item._snippetResult?.hierarchy?.lvl1?.value
                    || item?.hierarchy?.lvl1
                  ),
                }}
              />
            </>
          );
        }

        if (item.type === 'content') {
          contentNode = (
            <>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: (
                    item._snippetResult.content?.value
                    || item?.content
                  ),
                }}
              />
              <div
                className={styles.details}
                dangerouslySetInnerHTML={{
                  __html: (
                    item._snippetResult?.hierarchy?.lvl1?.value
                    || item?.hierarchy?.lvl1
                  ),
                }}
              />
            </>
          );
        }

        const { onClick, ...itemProps } = getItemProps(item);

        const handleItemClick = (event) => {
          onClick(event);
          window.open(item.url);
        };

        return (
          <div key={item.objectID} className={styles.item} onClick={handleItemClick} {...itemProps} role="option" aria-selected="false" tabIndex="0">
            <div className={styles.icons}>
              {
                item.hasParent === true && item.type !== 'content' && (
                  <div className={styles.parentIconWrapper}>
                    <HierarchyLine className={styles.parentIcon} width={20} height={20} />
                  </div>
                )
              }
              {
                item.type === 'lvl1' && (
                  <FileOutlined style={{ fontSize: 28, color: '#A6B6C9' }} />
                )
              }
              {
                item.type === 'content' && (
                  <MenuOutlined style={{ fontSize: 28, color: '#A6B6C9' }} />
                )
              }
              {
                item.type !== 'lvl1' && item.type !== 'content' && (
                  <ArrowRightSquareLine width={28} height={28} />
                )
              }
            </div>
            <div className={styles.content}>
              {contentNode}
            </div>
          </div>
        );
      })
    ));
  }, [items]);
  /* eslint-enable no-underscore-dangle, react/no-danger */

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.results} {...getListProps()}>
        {itemsNodes}
      </div>
    </div>
  );
};

AlgoliaSearchResultLvl0.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  getItemProps: PropTypes.func.isRequired,
  getListProps: PropTypes.func.isRequired,
};

AlgoliaSearchResultLvl0.defaultProps = {
  className: '',
};

export default AlgoliaSearchResultLvl0;
