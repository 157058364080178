import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import OnboardUserForm from '@palette/components/userOnboarding/OnboardUserForm/OnboardUserForm';
import OnboardUsersFlowStepActions from '@palette/components/userOnboarding/OnboardUsersFlowStepActions/OnboardUsersFlowStepActions';

import { actions as UsersActions } from '@palette/state/Users';

import styles from './OnboardUsersManualInputFlowStep.less';

const classNames = bindClassNames.bind(styles);

const OnboardUsersManualInputFlowStep = ({ className }) => {
  const dispatch = useDispatch();

  const [newOnboardUser, setNewOnboardUser] = useState(null);

  const [submitFormFunc, setSubmitFormFunc] = useState(null);
  const [nextStepCBFunc, setNextStepCBFunc] = useState(null);

  const handleNextStepClick = useCallback((nextStepCB) => {
    setNextStepCBFunc(() => nextStepCB);
    if (submitFormFunc !== null) submitFormFunc();
  }, [submitFormFunc]);

  const handleFinish = useCallback((updatedOnboardUser) => {
    dispatch(UsersActions.setOnboardCurrentUsers({ users: [updatedOnboardUser] }));
    if (nextStepCBFunc !== null) nextStepCBFunc();
  }, [nextStepCBFunc]);

  const handleValuesChange = useCallback((updatedOnboardUser) => {
    setNewOnboardUser(updatedOnboardUser);
  }, []);

  const setSubmitFunc = useCallback((submitFunc) => {
    setSubmitFormFunc(() => submitFunc);
  }, []);

  const actionsNode = useMemo(() => (
    <OnboardUsersFlowStepActions
      onNextStep={handleNextStepClick}
      disabledNext={
        newOnboardUser === null
        || newOnboardUser.firstName === ''
        || newOnboardUser.lastName === ''
        || newOnboardUser.email === ''
      }
    />
  ), [
    handleNextStepClick,
    newOnboardUser,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <OnboardUserForm setSubmitFunc={setSubmitFunc} onUpdateFinish={handleFinish} onValuesChange={handleValuesChange} />
      {actionsNode}
    </div>
  );
};

OnboardUsersManualInputFlowStep.propTypes = {
  className: PropTypes.string,
};

OnboardUsersManualInputFlowStep.defaultProps = {
  className: '',
};

export default OnboardUsersManualInputFlowStep;
