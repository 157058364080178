import AlertOctagon from '@palette/components/utils/Icons/AlertOctagon';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import CloseSimpleLine from '@palette/components/utils/Icons/CloseSimpleLine';
import HourglassFilled from '@palette/components/utils/Icons/HourglassFilled';
import LockFilled from '@palette/components/utils/Icons/LockFilled';
import SpeedometerFilled from '@palette/components/utils/Icons/SpeedometerFilled';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';

import { TAG_THEMES } from '@palette/constants/tag';

export const CURRENCY_QS_KEY = 'currency';
export const PERIODS_DATES_TYPE_QS_KEY = 'type';
export const PERIODS_DATES_FROM_QS_KEY = 'from';
export const PERIODS_DATES_TO_QS_KEY = 'to';

export const STATEMENT_STRATEGY_TYPES = {
  MONTH: 'MONTH',
  HALF_MONTH: 'HALF_MONTH',
  BI_WEEK: 'BI_WEEK',
  WEEK: 'WEEK',
};

export const WEEK_ROLL_TYPES = {
  US: 'US',
  EU: 'EU',
};

export const HALF_MONTH_PIVOTS = {
  FIFTEENTH: 'FIFTEENTH',
  SIXTEENTH: 'SIXTEENTH',
};

export const STATEMENT_STRATEGY_TYPES_I18N = {
  WEEK_LONG: 'statementStrategyTypes.long.week',
  WEEK_SHORT: 'statementStrategyTypes.short.week',
  BI_WEEK_LONG: 'statementStrategyTypes.long.biWeek',
  BI_WEEK_SHORT: 'statementStrategyTypes.short.biWeek',
  HALF_MONTH_LONG: 'statementStrategyTypes.long.halfMonth',
  HALF_MONTH_SHORT: 'statementStrategyTypes.short.halfMonth',
};

export const STATEMENT_STRATEGY_MONTH_I18N = {
  M1: 'frequency.monthly.periods.0',
  M2: 'frequency.monthly.periods.1',
  M3: 'frequency.monthly.periods.2',
  M4: 'frequency.monthly.periods.3',
  M5: 'frequency.monthly.periods.4',
  M6: 'frequency.monthly.periods.5',
  M7: 'frequency.monthly.periods.6',
  M8: 'frequency.monthly.periods.7',
  M9: 'frequency.monthly.periods.8',
  M10: 'frequency.monthly.periods.9',
  M11: 'frequency.monthly.periods.10',
  M12: 'frequency.monthly.periods.11',
};

export const STATEMENT_STRATEGY_MONTH_SHORT_I18N = {
  M1: 'frequency.monthly.short.periods.0',
  M2: 'frequency.monthly.short.periods.1',
  M3: 'frequency.monthly.short.periods.2',
  M4: 'frequency.monthly.short.periods.3',
  M5: 'frequency.monthly.short.periods.4',
  M6: 'frequency.monthly.short.periods.5',
  M7: 'frequency.monthly.short.periods.6',
  M8: 'frequency.monthly.short.periods.7',
  M9: 'frequency.monthly.short.periods.8',
  M10: 'frequency.monthly.short.periods.9',
  M11: 'frequency.monthly.short.periods.10',
  M12: 'frequency.monthly.short.periods.11',
};

export const STATEMENT_PERIOD_STATUSES = {
  PENDING_ACTIONS: {
    id: 'PENDING_ACTIONS',
    theme: TAG_THEMES.WARNING,
    i18n: 'statement.statuses.pendingActions',
    icon: AlertOctagon,
  },
  APPROVAL_REQUIRED: {
    id: 'APPROVAL_REQUIRED',
    theme: TAG_THEMES.INFO,
    i18n: 'statement.statuses.approvalRequired',
    icon: HourglassFilled,
  },
  PAYMENT_REQUIRED: {
    id: 'PAYMENT_REQUIRED',
    theme: TAG_THEMES.SUCCESS,
    i18n: 'statement.statuses.paymentRequired',
    icon: StatementsFilled,
  },
  PAID: {
    id: 'PAID',
    theme: TAG_THEMES.SUCCESS,
    i18n: 'statement.statuses.paid',
    icon: CheckFilled,
  },
  IN_FUTURE: {
    id: 'IN_FUTURE',
    theme: TAG_THEMES.LAVENDER,
    i18n: 'statement.statuses.inFuture',
    icon: SpeedometerFilled,
  },
  EMPTY_STATEMENTS: {
    id: 'EMPTY_STATEMENTS',
    theme: TAG_THEMES.LAVENDER,
    i18n: 'statement.statuses.emptyStatements',
    icon: CloseSimpleLine,
  },
};

export const STATEMENT_PERIOD_USER_STATUSES = {
  PAID: {
    id: 'PAID',
    theme: TAG_THEMES.SUCCESS,
    i18n: 'statement.statuses.periodUser.paid',
    icon: LockFilled,
  },
  PAID_AMOUNT: {
    id: 'PAID_AMOUNT',
    theme: TAG_THEMES.DEFAULT,
    i18n: '',
    icon: StatementsFilled,
  },
  APPROVED: {
    id: 'APPROVED',
    theme: TAG_THEMES.INFO,
    i18n: 'statement.statuses.periodUser.approved',
    icon: CheckFilled,
  },
};

export const STATEMENT_APPROVAL_STATUSES = {
  APPROVED: 'APPROVED',
  TO_APPROVE: 'TO_APPROVE',
  IN_CONFLICT: 'IN_CONFLICT',
};

export const STATEMENT_PERIODS_DATES_TYPES = {
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  HALF_YEAR: 'HALF_YEAR',
  YEAR: 'YEAR',
  CUSTOM: 'CUSTOM',
};

export const STATEMENT_SNAPSHOT_ORIGINS = {
  APPROVED: 'APPROVED',
  PAID: 'PAID',
};

export const STATEMENT_DIFF_TYPES = {
  ADDED: 'ADDED',
  UPDATED: 'UPDATED',
  DELETED: 'DELETED',
};

export const STATEMENT_DIFF_TYPE_FIELD = 'statementChangeType';
export const STATEMENT_DIFF_OLD_FIELD = 'oldValue';
export const STATEMENT_DIFF_NEW_FIELD = 'newValue';

export const ONGOING_BULK_APPROVE_STATUS_MESSAGE_KEY = 'ongoingBulkApproveStatus';

export const ONGOING_BULK_MARK_AS_PAID_STATUS_MESSAGE_KEY = 'ongoingBulkMarkAsPaidStatus';
