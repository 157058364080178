import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import Modal from '@palette/components/designSystem/Modal/Modal';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import Link from '@palette/components/designSystem/Link/Link';

import { useMasterPlanColumns } from '@palette/hooks/MasterPlanHooks';

import { getPlanFirstPeriod } from '@palette/helpers/MasterPlanHelper';
import { getColumnValue } from '@palette/helpers/ConnectorHelper';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowDealsResultsModal.less';

const CreatePlanFlowDealsResultsModal = ({ visible, onClose, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planFirstPeriod = useMemo(() => (
    getPlanFirstPeriod(plan)
  ), [plan]);

  const planColumns = useMasterPlanColumns(plan);

  const getPeriodDealsIsPending = useSelector(MasterPlansSelectors.getPeriodDealsIsPending);
  const periodDeals = useSelector((state) => (MasterPlansSelectors.getMasterPlanPeriodDeals(state, { masterPlanId: plan.id, year: planFirstPeriod.year, periodId: planFirstPeriod.period })));
  const periodDealsPagination = useSelector((state) => (
    MasterPlansSelectors.getMasterPlanPeriodDealsPagination(state, { masterPlanId: plan.id, year: planFirstPeriod.year, periodId: planFirstPeriod.period })),
  );

  const [currentPage, setCurrentPage] = useState(periodDealsPagination?.page || 0);
  const [currentLimit, setCurrentLimit] = useState(periodDealsPagination?.limit || 20);

  useEffect(() => {
    setCurrentPage(periodDealsPagination?.page || 0);
    setCurrentLimit(periodDealsPagination?.limit || 20);
  }, [periodDealsPagination]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  });

  const handleLimitChange = useCallback((newLimit) => {
    setCurrentLimit(newLimit);
  });

  useEffect(() => {
    dispatch(MasterPlansActions.getPeriodDeals({
      planId: plan.id,
      year: planFirstPeriod.year,
      period: planFirstPeriod.period,
      page: currentPage,
      limit: currentLimit,
    }));
  }, [currentPage, currentLimit]);

  const cleanAndClose = () => {
    onClose();
  };

  const tableColumns = useMemo(() => {
    const planTableColumns = planColumns.map((column, index) => {
      const planColumnId = `planColumn_${index}_${column.name}`;

      return {
        id: planColumnId,
        Header: column.name,
        accessor: (periodDeal) => getColumnValue(periodDeal.resourceObject?.data || {}, column),
        minWidth: 150,
        maxWidth: 250,
        width: 'auto',
      };
    });

    const indexColumn = {
      id: 'indexColumn',
      Header: '#',
      accessor: (periodDeal) => (periodDeal),
      minWidth: 130,
      maxWidth: 150,
      width: '15%',
      /* eslint-disable react/prop-types */
      Cell: ({ value, row }) => {
        const dealPath = routePaths.v2.resourceDetails;
        const dealPathParams = {
          connectorId: plan.trackingObject?.connectorId,
          type: plan.trackingObject?.type,
          resourceId: value.resourceObjectId,
        };
        const rowIndex = currentPage * currentLimit + row.index + 1;
        return (
          <Link path={dealPath} params={dealPathParams} displayIcon>
            {`${plan.trackingObject?.type} #${rowIndex}`}
          </Link>
        );
      },
      /* eslint-enable react/prop-types */
    };

    return [indexColumn].concat(planTableColumns);
  }, [
    plan,
    planColumns,
    currentPage,
    currentLimit,
  ]);

  const contentNode = useMemo(() => {
    if (periodDeals.length === 0) {
      return (
        <DefaultEmptyState description={t('masterPlanPeriodDeals.empty.description', { dealType: pluralize.singular(plan.trackingObject?.type || '') })} />
      );
    }

    return (
      <>
        <Table
          type="borderless"
          columns={tableColumns}
          data={periodDeals}
        />
        <Pagination
          className={styles.pagination}
          pagination={periodDealsPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </>
    );
  }, [
    plan,
    periodDeals,
    periodDealsPagination,
    tableColumns,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <Modal
      className={styles.modal}
      title={t('createPlanFlowDealsResultsModal.title', { dealType: pluralize(plan.trackingObject?.type), total: periodDealsPagination?.total || 0 })}
      visible={visible}
      onCancel={cleanAndClose}
      okText={null}
      cancelText={t('createPlanFlowDealsResultsModal.close')}
      loading={getPeriodDealsIsPending}
    >
      {contentNode}
    </Modal>
  );
};

CreatePlanFlowDealsResultsModal.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

CreatePlanFlowDealsResultsModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CreatePlanFlowDealsResultsModal;
