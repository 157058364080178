import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Badge } from 'antd';

import MenuNotificationsPopoverContent from '@palette/components/navigation/MenuNotificationsPopoverContent/MenuNotificationsPopoverContent';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import Popover from '@palette/components/designSystem/Popover/Popover';

import { actions as ThreadsActions, selectors as ThreadsSelectors } from '@palette/state/Threads';

import { STATUSES } from '@palette/constants/threads';

import styles from './MenuNotifications.less';

const MenuNotifications = ({ name }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const listIsPending = useSelector(ThreadsSelectors.getListIsPending);
  const nbOfPendingThreads = useSelector((state) => ThreadsSelectors.getNbOfThreadsByStatus(state, { threadsStatus: STATUSES.PENDING }));

  useEffect(() => {
    dispatch(ThreadsActions.getList());
  }, []);

  return (
    <Popover
      content={(<MenuNotificationsPopoverContent />)}
    >
      <div className={styles.wrapper}>
        <div>
          {t(name)}
        </div>
        {listIsPending ? (<RefreshLine width={16} height={16} spin />) : (<Badge className={styles.badge} count={nbOfPendingThreads} />) }
      </div>
    </Popover>
  );
};

MenuNotifications.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MenuNotifications;
