/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Alert, Button, Result, message, notification,
} from 'antd';
import {
  FileSearchOutlined,
  FireOutlined, LoadingOutlined, LockOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import routePaths from '@palette/config/routePaths';
import {
  createConnector,
  listConnectors, connectCrelate,
  connectClose, connectChargebee, connectPipedrive, connectStripe, createConnectorGsheet,
  connectMSSQL, connectMySQL, connectSaasOptics, connectSapling, connectNetsuite, connectPennylane,
  connectSnowflake, connectRedshift,
} from '../../../services/connector';
import FullScreenLoader from '../../../components/loader';
import { getProfile, logout } from '../../../services/user';
import { parseSearch, getSessionId } from '../../../services/utils';

import '../../../styles/admin/addConnector.less';
import ConnectCRMWithApiKeyModal
  from '../../../components/admin/ConnectCRM/connectCRMWithApiKeyModal';
import { ANALYTICS_DOC_SOURCES, HUBSPOT_FORM_URL } from '../../../services/constants';
import { sendClickOnDocLink } from '../../../services/analytics';
import { availableConnectorsList } from '../connectorsHelper';

class ConnectCRM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      connectors: null,
      profile: null,
    };
  }

  componentDidMount() {
    const {
      fromHubspot,
    } = parseSearch(this.props.location.search);
    const comingFromHubspot = fromHubspot === 'true';
    if (!getSessionId()) {
      if (comingFromHubspot) {
        window.location.href = HUBSPOT_FORM_URL;
      } else {
        this.props.history.push(routePaths.v2.login);
      }
    }
    this.fetch();
    const { error_description: e } = parseSearch(this.props.location.search);
    if (e) message.error(e);
  }

  fetch = () => {
    Promise.all([
      listConnectors(),
      getProfile(),
    ]).then(([{ data: connectors }, { data: profile }]) => {
      this.setState({ connectors, profile });
    });
  };

  closeConnectModal = () => this.props.history.push('/add-connector');

  addConnectorSuccess = (id, name) => {
    getProfile().then(() => {
      this.props.history.push(`/connectors/${id}?success=true`);
      notification.success({
        message: 'Data source connected',
        description: `${name} connected successfully!`,
      });
    });
  };

  createConnectorCSV = () => {
    this.setState({ loading: true }, () => {
      createConnector().then(({ data: connector }) => {
        getProfile().then(() => {
          this.props.history.push(`/connectors/${connector._id}?success=true`);
          notification.success({
            message: 'Data source connected',
            description: 'CSV connected successfully!',
          });
        });
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        this.setState({ loading: false });
      });
    });
  };

  createConnectorGSheet = () => {
    this.setState({ loading: true }, () => {
      createConnectorGsheet().then(({ data: connector }) => {
        getProfile().then(() => {
          this.props.history.push(`/connectors/${connector._id}?success=true`);
          notification.success({
            message: 'Data source connected',
            description: 'Google Sheets connected successfully!',
          });
        });
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        this.setState({ loading: false });
      });
    });
  };

  render() {
    const {
      loading,
      connectors,
      profile,
    } = this.state;
    const {
      firstvisit,
      connectclose,
      connectchargebee,
      connectpipedrive,
      connectstripe,
      connectmssql,
      connectmysql,
      connectsaasoptics,
      connectsapling,
      connectnetsuite,
      connectpennylane,
      connectcrelate,
      connectsnowflake,
      connectredshift,
    } = parseSearch(this.props.location.search);
    const firstVisit = firstvisit === 'true';
    const isConnectClose = connectclose === 'true';
    const isConnectChargebee = connectchargebee === 'true';
    const isConnectSaasoptics = connectsaasoptics === 'true';
    const isConnectSapling = connectsapling === 'true';
    const isConnectPipedrive = connectpipedrive === 'true';
    const isConnectStripe = connectstripe === 'true';
    const isConnectMicrosoftSQL = connectmssql === 'true';
    const isConnectMySQL = connectmysql === 'true';
    const isConnectNetsuite = connectnetsuite === 'true';
    const isConnectPennylane = connectpennylane === 'true';
    const isConnectCrelate = connectcrelate === 'true';
    const isConnectSnowflake = connectsnowflake === 'true';
    const isConnectRedshift = connectredshift === 'true';

    const handleConnectorClick = (connector) => {
      if (connector === 'gsheet') {
        this.createConnectorGSheet();
      } else if (connector === 'csv') {
        this.createConnectorCSV();
      } else {
        this.props.history.push(`/add-connector?${connector}=true`);
      }
    };

    const availableConnectors = availableConnectorsList(loading, profile, handleConnectorClick).filter(({ hide }) => !hide);
    const availableConnectorsConnected = availableConnectors
      .map((a) => ({
        ...a,
        alreadyConnected: !!(connectors || []).find((con) => con.type === a.name),
      }));

    const grouped = _.groupBy(availableConnectorsConnected, 'type');

    const hasConnector = (connectors || []).length > 0;

    const isLive = profile && profile.userData.company && profile.userData.company.live;
    return (
      <div className="div-wrapper">
        <div className="container">
          <div className="container-inner">
            <div className="ConnectCRM_wrapper">
              <div className="ConnectCRM_inner">
                {firstVisit ? (
                  <Result
                    icon={(
                      <img
                        src="/img/welcome.png"
                        alt="welcome"
                        style={{ width: '340px', maxWidth: '100%' }}
                      />
                    )}
                    // status="success"
                    title="Account created"
                    subTitle={(
                      <>
                        <div>We're happy to see you on board!</div>
                        <div>Let's get started by setting up the link to your CRM.</div>
                      </>
                    )}
                    extra={[
                      <a href={`/#${routePaths.addConnector}`} key="startnow">
                        <Button type="primary" size="large" icon={<FireOutlined />}>
                          Start now
                        </Button>
                      </a>,

                    ]}
                  />
                ) : (
                  connectors ? (
                    <>
                      <div className="flex">
                        <div style={{ flexGrow: '1' }}>
                          <h1 className="bold" style={{ marginBottom: '8px' }}>Connect your data source</h1>

                        </div>
                        {!hasConnector && isLive && (
                          <div
                            className="flex"
                            style={{ alignItems: 'center' }}
                          >
                            <a href="/">
                              <Button type="primary" ghost>Access Palette now</Button>
                            </a>
                          </div>
                        )}

                      </div>
                      <div className="subtitle grey">
                        Please link your CRM or other data provider to continue.
                      </div>
                      <br />
                      <div className="flex">
                        <Alert
                          className="Blue_alert Security_alert"
                          message="Secured connexion, encrypted information."
                          description={(
                            <div className="flex">
                              <div style={{ flexGrow: '1' }}>
                                We take security very seriously.
                                The information you're about to communicate is
                                instantly encrypted and stored in a secured database.
                                {' '}
                                <a
                                  className="bold"
                                  style={{ color: '#1e7abf' }}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  href="https://palettehq.com/en/security"
                                >
                                  For more information, please visit our security policies.
                                </a>
                              </div>
                              <div className="flex">
                                <div
                                  className="Soc2_panel"
                                >
                                  <div>
                                    <img
                                      style={{ width: '60px', marginRight: '12px' }}
                                      src="/img/security/soc2-palette-120.png"
                                      alt="SOC-2 logo Palette"
                                      className="width-100"
                                    />

                                  </div>
                                  <div style={{ width: '200px', maxWidth: '100%', color: '#206db6' }}>Palette is SOC-2 Type II compliant (related document is available upon request).</div>
                                </div>
                              </div>

                            </div>
                          )}
                          type="info"
                          showIcon
                          icon={<LockOutlined />}
                        />
                      </div>

                      <div className="ConnectCRM_button_wrapper">
                        {Object.keys(grouped).map((type) => (
                          <div key={type} className="Source_type_wrapper">
                            <h3 className="bold">{type}</h3>
                            <div className="ConnectCRM_inner_list">
                              {grouped[type].map((co) => (
                                <co.template
                                  key={co.name}
                                  disabled={co.alreadyConnected}
                                />
                              ))}
                            </div>

                          </div>
                        ))}
                      </div>
                      <div className="center">
                        <br />
                        <div className="CRM_not_listed">
                          <b>If your data source is not listed here,</b>
                          &nbsp;
                          please contact us at hello@palettehq.com
                        </div>
                        {isLive && (
                          <div
                            style={{
                              marginTop: '12px',
                            }}
                          >
                            <a href="/">Go to Dashboard</a>
                          </div>
                        )}
                        <br />
                        <div
                          style={{
                            marginTop: '12px',
                            fontSize: '14px',
                          }}
                        >
                          <a onClick={() => logout(() => this.props.history.push(routePaths.v2.login))}>
                            Logout
                          </a>
                        </div>
                      </div>
                    </>
                  ) : <LoadingOutlined />
                )}
              </div>
            </div>
          </div>
        </div>
        {isConnectPipedrive && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectPipedrive}
            crmName="Pipedrive"
            crmCall={connectPipedrive}
            description={(
              <div>
                Please provide your Pipedrive Api Key.
                {' '}
                <br />
                You can find this key in:
                {' '}
                <b>{'Personal preferences > API'}</b>
              </div>
            )}
          />
        )}
        {isConnectMicrosoftSQL && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectMicrosoftSQL}
            crmName="Microsoft SQL"
            crmCall={connectMSSQL}
            placeholder="Microsoft SQL URI"
            paramName="uri"
            description={(
              <div>
                Please provide a Microsoft SQL URI.
                <br />
                It should include the host, port, database, username and password.
                <br />
                Example:&nbsp;
                <b>Server=HOST,PORT;Database=XXX;User Id=USERNAME;Password=PASSWORD;Encrypt=true</b>
              </div>
            )}
          />
        )}
        {isConnectMySQL && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectMySQL}
            crmName="MySQL"
            paramName="uri"
            crmCall={connectMySQL}
            placeholder="MySQL URI"
            description={(
              <div>
                Please provide a MySQL URI.
                <br />
                It should include the username, password, host and port.
                <br />
                Example:&nbsp;
                <b>mysql://user:password@host:3306/database_name</b>
              </div>
            )}
          />
        )}
        {isConnectStripe && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectStripe}
            crmName="Stripe"
            crmCall={connectStripe}
            description={(
              <>
                <div>
                  Please provide a Stripe restricted key.
                  {' '}
                  <br />
                  You can find or create one in:
                  {' '}
                  <b>{'Developers > API keys'}</b>
                </div>
                <div style={{ marginTop: 8 }}>
                  <b>Requirements</b>
                  {' - Your API key needs to have at least 2 permissions:'}
                  <ul style={{ paddingLeft: '1.5rem' }}>
                    <li>
                      {'\u2022 '}
                      <u>Customers</u>
                      {' - Read permission'}
                    </li>
                    <li>
                      {'\u2022 '}
                      <u>Webhook Endpoints</u>
                      {' - Write permission'}
                    </li>
                  </ul>
                </div>
              </>
            )}
          />
        )}
        {isConnectClose && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectClose}
            crmName="Close CRM"
            crmCall={connectClose}
            description={(
              <div>
                Please provide your Close Api Key.
                {' '}
                <br />
                You can find this key in:
                {' '}
                <b>{'Settings > Integrations > API Keys'}</b>
              </div>
            )}
          />
        )}
        {isConnectChargebee && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectChargebee}
            crmName="Chargebee"
            crmCall={connectChargebee}
            description={(
              <div>
                Please provide your Chargebee Api Key.
                <br />
                Here is the process to create one:
                <br />
                {'1) Go to '}
                <b>{'Settings > Configure Chargebee > API Keys'}</b>
                .
                <br />
                {'2) Click on '}
                <b>+ Add API Key</b>
                {' and select Read-Only Key.'}
                <br />
                {'3) Select '}
                <b>All</b>
                <br />
                {'4) Name your api key '}
                <b>PaletteHQ.com</b>
                <br />
                {'5) Finally, click on '}
                <b>Create Key</b>
                {' and copy it in the input below!'}
              </div>
            )}
          />
        )}
        {isConnectSaasoptics && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectSaasoptics}
            crmName="SaaSOptics"
            crmCall={connectSaasOptics}
            description={(
              <div>

                Please provide your SaaSOptics Api Key, Subdomain and Account Name.
              </div>
            )}
          />
        )}
        {isConnectSapling && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectSapling}
            crmName="Sapling"
            crmCall={connectSapling}
            description={(
              <div>

                Please provide your SaaSOptics Api Key, Subdomain and Account Name.
              </div>
            )}
          />
        )}
        {isConnectNetsuite && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectNetsuite}
            crmName="NetSuite"
            crmCall={connectNetsuite}
            paramName="accountId"
            placeholder="NetSuite Account ID"
            paramIcon={null}
            description={(
              <div>
                {'Please provide your NetSuite Account ID, Consumer Key, Consumer Secret, Token Id and Token Secret. '}
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://doc.palettehq.com/netsuite-connector"
                  onClick={() => { sendClickOnDocLink(ANALYTICS_DOC_SOURCES.NETSUITE_CONNECTOR_MODAL, profile); }}
                >
                  <FileSearchOutlined />
                  {' '}
                  Documentation for NetSuite Connector
                </a>
              </div>
            )}
          />
        )}
        {isConnectPennylane && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectPennylane}
            crmName="Pennylane"
            crmCall={connectPennylane}
            description={(
              <div>
                Please provide a Pennylane Secret key.
              </div>
            )}
          />
        )}
        {isConnectCrelate && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectCrelate}
            crmName="Crelate"
            crmCall={connectCrelate}
            description={(
              <div>
                Please provide a Crelate Secret key.
              </div>
            )}
          />
        )}
        {isConnectSnowflake && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectSnowflake}
            crmName="Snowflake"
            crmCall={connectSnowflake}
            description={(
              <div>
                Please provide at least your Snowflake Access URL, Account Name, User Name and Password.
              </div>
            )}
          />
        )}
        {isConnectRedshift && (
          <ConnectCRMWithApiKeyModal
            closeModal={this.closeConnectModal}
            onSuccess={this.addConnectorSuccess}
            visible={isConnectRedshift}
            crmName="Redshift"
            crmCall={connectRedshift}
            description={(
              <div>
                Please provide your Redshift Secret Access Key, Access Key ID, Secret ARN, Cluster Identifier, Region and Database Name.
              </div>
            )}
          />
        )}
        {loading && <FullScreenLoader />}
      </div>
    );
  }
}

ConnectCRM.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(ConnectCRM);
