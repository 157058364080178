import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Tag from '@palette/components/designSystem/Tag/Tag';

import { SCOPES } from '@palette/constants/masterPlans';
import { TAG_THEMES } from '@palette/constants/tag';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './MasterPlanScopeTags.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanScopeTags = ({ className, plan, hiddenScopeExcluded }) => {
  const { t } = useTranslation();

  const scopeNode = [];

  if (plan.scope === SCOPES.TEAM) {
    scopeNode.push((
      <Tag
        className={styles.scopeTag}
        theme={TAG_THEMES.SUCCESS}
        key="scope-team"
        label={t('masterPlan.scope.team')}
      />
    ));
  }

  if (plan.scope === SCOPES.MANAGER) {
    scopeNode.push((
      <Tag
        className={styles.scopeTag}
        theme={TAG_THEMES.INFO}
        key="scope-manager"
        label={t('masterPlan.scope.manager')}
      />
    ));
  }

  if (!plan.live && !hiddenScopeExcluded) {
    scopeNode.push((
      <Tag
        className={styles.scopeTag}
        theme={TAG_THEMES.LAVENDER}
        key="scope-hidden"
        label={t('masterPlan.scope.hidden')}
      />
    ));
  }

  if (!scopeNode.length >= 1) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {scopeNode}
    </div>
  );
};

MasterPlanScopeTags.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  hiddenScopeExcluded: PropTypes.bool,
};

MasterPlanScopeTags.defaultProps = {
  className: '',
  hiddenScopeExcluded: false,
};

export default MasterPlanScopeTags;
