import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import QueryBuilderDisplayedAndOrCondition from '@palette/components/designSystem/QueryBuilderDisplayedAndOrCondition/QueryBuilderDisplayedAndOrCondition';

import { isAndOrBlock } from '@palette/helpers/QueryBuilderHelper';

import {
  OPERATOR_TO_STRING,
} from '@palette/constants/queryBuilder';

import styles from './QueryBuilderDisplayedAndOrBlock.less';

const classNames = bindClassNames.bind(styles);

const QueryBuilderDisplayedAndOrBlock = ({
  className,
  value,
  prefix,
}) => {
  const andOrOperator = Object.keys(value)[0];
  const andOrOperatorString = OPERATOR_TO_STRING[andOrOperator];

  const conditions = value[andOrOperator];

  const conditionsNodes = useMemo(() => (
    conditions.map((condition, index) => {
      let conditionNode = null;

      if (isAndOrBlock(condition)) {
        conditionNode = (
          <QueryBuilderDisplayedAndOrBlock
            key={index}
            className={styles.condition}
            value={condition}
            prefix={prefix}
          />
        );
      } else {
        conditionNode = (
          <QueryBuilderDisplayedAndOrCondition
            key={index}
            className={styles.condition}
            value={condition}
            prefix={prefix}
          />
        );
      }

      if (index !== conditions.length - 1) {
        return (
          <div key={index} className={styles.conditionWithOperator}>
            {conditionNode}
            <div className={styles.operator}>
              {andOrOperatorString}
            </div>
          </div>
        );
      }

      return conditionNode;
    })
  ), [conditions, prefix]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div>(</div>
      {conditionsNodes}
      <div>)</div>
    </div>
  );
};

QueryBuilderDisplayedAndOrBlock.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

QueryBuilderDisplayedAndOrBlock.defaultProps = {
  className: '',
  prefix: '',
};

export default QueryBuilderDisplayedAndOrBlock;
