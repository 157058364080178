import { STATEMENT_APPROVAL_STATUSES, STATEMENT_PERIOD_STATUSES } from '@palette/constants/statements';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import { statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';

export const isApprovalInConflict = (statementAmount, statementCurrency, approvalAmount, approvalCurrency) => !!((statementAmount !== approvalAmount) || (statementCurrency !== approvalCurrency));

export const getStatementUserApprovalStatus = (userStatement, profileId) => {
  const { amount, currency, actions } = userStatement;

  const approvals = actions.filter((action) => action.type === STATEMENT_ACTION_TYPES.APPROVAL);

  const actionApprovedByMe = approvals.find((approval) => approval.userId === profileId);

  if (actionApprovedByMe) {
    const isInConflict = isApprovalInConflict(amount, currency, actionApprovedByMe.amount, actionApprovedByMe.currency);

    if (isInConflict) {
      return STATEMENT_APPROVAL_STATUSES.IN_CONFLICT;
    }
  } else {
    return STATEMENT_APPROVAL_STATUSES.TO_APPROVE;
  }

  return STATEMENT_APPROVAL_STATUSES.APPROVED;
};

export const isUserStatementPaid = ({ actions }) => actions.some((action) => action.type === STATEMENT_ACTION_TYPES.PAYMENT);

export const useStatementPeriodStatus = (periodStatement) => {
  if (statementPeriodIsInFuture(periodStatement, false, 'fromDate')) return STATEMENT_PERIOD_STATUSES.IN_FUTURE;

  const { actions, correctionsCount } = periodStatement;

  const paidCount = actions.filter((action) => action.type === STATEMENT_ACTION_TYPES.PAYMENT).length;
  const approvedCount = actions.filter((action) => action.type === STATEMENT_ACTION_TYPES.APPROVAL).length;

  if (paidCount > 0) return STATEMENT_PERIOD_STATUSES.PAID;

  if (correctionsCount > 0) return STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS;

  if (approvedCount > 0) return STATEMENT_PERIOD_STATUSES.PAYMENT_REQUIRED;

  return STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED;
};

export const useStatementsPeriodStatus = ({ statements }) => {
  const statuses = [];

  statements.forEach((periodStatement) => {
    statuses.push(useStatementPeriodStatus(periodStatement));
  });

  if (statuses.includes(STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED)) return STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED;
  if (statuses.includes(STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS)) return STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS;
  if (statuses.includes(STATEMENT_PERIOD_STATUSES.PAYMENT_REQUIRED)) return STATEMENT_PERIOD_STATUSES.PAYMENT_REQUIRED;
  if (statuses.includes(STATEMENT_PERIOD_STATUSES.IN_FUTURE)) return STATEMENT_PERIOD_STATUSES.IN_FUTURE;

  return STATEMENT_PERIOD_STATUSES.PAID;
};
