import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  TEAM_DELETION_SUCCESS: {
    code: 'notifications.teams.success.delete',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  TEAM_CREATION_SUCCESS: {
    code: 'notifications.teams.success.create',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  TEAM_UPDATE_NAME_SUCCESS: {
    code: 'notifications.teams.success.updateName',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  TEAM_MOVE_SUCCESS: {
    code: 'notifications.teams.success.moveTeam',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
