import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PlansUsingQuotaModal from '@palette/components/quota/PlansUsingQuotaModal/PlansUsingQuotaModal';

import * as QuotaModel from '@palette/models/Quota';

import styles from './PlansUsingQuota.less';

const classNames = bindClassNames.bind(styles);

const PlansUsingQuota = ({ quota, className }) => {
  const { t } = useTranslation();

  const [plansUsingQuotaModalIsVisible, showPlansUsingQuotaModal] = useState(false);

  if (quota.plans.length === 0) {
    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <span className={styles.notUsed}>
          {t('plansUsingQuota.notUsed')}
        </span>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.buttonContainer}
        type="link"
        onClick={() => showPlansUsingQuotaModal(true)}
      >
        {t('plansUsingQuota.link', { count: quota.plans.length })}
      </Button>
      {
        plansUsingQuotaModalIsVisible && (
          <PlansUsingQuotaModal quota={quota} visible onClose={() => showPlansUsingQuotaModal(false)} />
        )
      }
    </div>
  );
};

PlansUsingQuota.propTypes = {
  quota: QuotaModel.propTypes.isRequired,
  className: PropTypes.string,
};

PlansUsingQuota.defaultProps = {
  className: '',
};

export default PlansUsingQuota;
