import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import UsersSelectionPanel from '@palette/components/user/UsersSelectionPanel/UsersSelectionPanel';
import UsersSelectedPanel from '@palette/components/user/UsersSelectedPanel/UsersSelectedPanel';
import FastOnboardModalContent from '@palette/components/user/FastOnboardModalContent/FastOnboardModalContent';

import { useFastOnboarding } from '@palette/hooks/UserHooks';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions } from '@palette/state/Users';

import styles from './AddUsersWithFastOnboardingModal.less';

const AddUsersWithFastOnboardingModal = ({
  visible,
  onClose,
  onOk,
  usersSelectionTabTitle,
  usersSelectedTabTitle,
  preSelectedUsers,
  restrictToConnectorIdAndType,
  disableUserConnectorsSelection,
  loading,
  title,
  okText,
  cancelText,
  onlyActiveUsers,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [usersToAdd, setUsersToAdd] = useState([]);
  const [isFastOnboardingOnGoing, setIsFastOnboardingOnGoing] = useState(false);

  useEffect(() => {
    if (visible) {
      dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: null }));
      setUsersToAdd([]);
    }
  }, [visible]);

  const cleanAndClose = useCallback(() => {
    dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: null }));
    onClose();
  }, [onClose]);

  const handleSelectedUsersChange = useCallback((selectedUsers) => {
    setUsersToAdd(selectedUsers);
  }, []);

  const handleAddUsers = useCallback(() => {
    onOk(usersToAdd, cleanAndClose);
  }, [usersToAdd, cleanAndClose]);

  const handleFastOnboardLinkClick = useCallback(() => {
    setIsFastOnboardingOnGoing(true);
  }, []);

  const tabs = useMemo(() => ([
    {
      title: usersSelectionTabTitle || t('addUsersModal.usersSelection'),
      content: (
        <UsersSelectionPanel
          preSelectedUsers={preSelectedUsers}
          onChange={handleSelectedUsersChange}
          restrictToConnectorIdAndType={restrictToConnectorIdAndType}
          disableUserConnectorsSelection={disableUserConnectorsSelection}
          onFastOnboardLinkClick={handleFastOnboardLinkClick}
          onlyActiveUsers={onlyActiveUsers}
        />
      ),
    },
    {
      title: `${usersSelectedTabTitle || t('addUsersModal.usersSelected')} (${usersToAdd.length})`,
      content: (
        <UsersSelectedPanel selectedUsers={usersToAdd} />
      ),
    },
  ]), [
    usersSelectionTabTitle,
    preSelectedUsers,
    handleSelectedUsersChange,
    restrictToConnectorIdAndType,
    disableUserConnectorsSelection,
    handleFastOnboardLinkClick,
    usersSelectedTabTitle,
    usersToAdd,
    onlyActiveUsers,
  ]);

  const onOnboardingSuccessCB = useCallback(() => {
    dispatch(UsersActions.getList());
    dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: null }));
    setIsFastOnboardingOnGoing(false);
  }, []);

  const {
    selectedResources,
    setSelectedResources,
    isMissingRequiredFields,
    setIsMissingRequiredFields,
    onboardUsersIsPending,
    onboardCurrentUsers,
    handleContinueFastOnboard,
    registerNextStepHandler,
  } = useFastOnboarding(onOnboardingSuccessCB);

  const contentNode = useMemo(() => {
    if (isFastOnboardingOnGoing) {
      return (
        <FastOnboardModalContent
          restrictToConnectorIdAndType={restrictToConnectorIdAndType}
          setIsMissingRequiredFields={setIsMissingRequiredFields}
          onResourcesSelected={setSelectedResources}
          registerNextStepHandler={registerNextStepHandler}
        />
      );
    }

    return (
      <Tabs tabs={tabs} />
    );
  }, [
    isFastOnboardingOnGoing,
    tabs,
    restrictToConnectorIdAndType,
    setSelectedResources,
    setIsMissingRequiredFields,
    registerNextStepHandler,
  ]);

  const handleCancelFastOnboard = useCallback(() => {
    dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: null }));
    setIsFastOnboardingOnGoing(false);
  }, []);

  const finalModalTitle = useMemo(() => {
    if (isFastOnboardingOnGoing) {
      return t('fastOnboardModal.title');
    }

    return title !== null ? title : t('addUsersModal.title');
  }, [title, isFastOnboardingOnGoing]);

  const finalOkText = useMemo(() => {
    if (isFastOnboardingOnGoing) {
      return onboardCurrentUsers.length === 0 ? t('fastOnboardModal.continue') : t('fastOnboardModal.onboardUsers');
    }

    return okText !== null ? okText : t('addUsersModal.okText');
  }, [okText, isFastOnboardingOnGoing, onboardCurrentUsers]);

  const finalCancelText = useMemo(() => {
    if (isFastOnboardingOnGoing) {
      return t('addUsersWithFastOnboardingModal.cancelFastOnboarding');
    }

    return cancelText;
  }, [cancelText, isFastOnboardingOnGoing, onboardCurrentUsers]);

  const disableOk = useMemo(() => {
    if (isFastOnboardingOnGoing) {
      return selectedResources.length === 0 || (onboardCurrentUsers.length > 0 && isMissingRequiredFields);
    }

    return usersToAdd.length === 0;
  }, [
    isFastOnboardingOnGoing,
    selectedResources,
    onboardCurrentUsers,
    isMissingRequiredFields,
    usersToAdd,
  ]);

  return (
    <Modal
      className={styles.modal}
      title={finalModalTitle}
      visible={visible}
      onCancel={isFastOnboardingOnGoing ? handleCancelFastOnboard : cleanAndClose}
      onOk={isFastOnboardingOnGoing ? handleContinueFastOnboard : handleAddUsers}
      okText={finalOkText}
      cancelText={finalCancelText}
      disableOk={disableOk}
      width="80%"
      stretchHeight
      loading={loading || onboardUsersIsPending}
      {...otherProps}
    >
      {contentNode}
    </Modal>
  );
};

AddUsersWithFastOnboardingModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  usersSelectionTabTitle: PropTypes.string,
  usersSelectedTabTitle: PropTypes.string,
  preSelectedUsers: PropTypes.arrayOf(MetaUserModel.propTypes),
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  disableUserConnectorsSelection: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onlyActiveUsers: PropTypes.bool,
};

AddUsersWithFastOnboardingModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onOk: () => {},
  usersSelectionTabTitle: null,
  usersSelectedTabTitle: null,
  preSelectedUsers: [],
  restrictToConnectorIdAndType: null,
  disableUserConnectorsSelection: false,
  loading: false,
  title: null,
  okText: null,
  cancelText: '',
  onlyActiveUsers: false,
};

export default AddUsersWithFastOnboardingModal;
