import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS, STATEMENT_FX_RATES_MODE } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

export const getProfile = () => (
  ApiService.post(apiConfig.ENDPOINTS.profile.getProfile, undefined, getConfig())
);

export function* updateCompany(payload) {
  const {
    timezone = undefined,
    features = undefined,
    logo = undefined,
    currencies = undefined,
  } = payload;

  const data = {};

  if (timezone !== undefined) data.timezone = timezone;

  if (features !== undefined) data.features = features;

  if (logo !== undefined) data.logo = logo;

  if (currencies !== undefined) data.currencies = currencies;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.updateCompany, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* updateProfile(payload) {
  const {
    emailPreferences = undefined,
    profilePicUrl = undefined,
  } = payload;

  const data = {};

  if (emailPreferences !== undefined) data.emailPreferences = emailPreferences;

  if (profilePicUrl !== undefined) data.profilePicUrl = profilePicUrl;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.profile.updateProfile, data, getConfig()),
    [],
  );
}

export function* toggleStatementValidations() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.toggleStatementValidations, undefined, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* updateStatementStrategy(payload) {
  const {
    type,
    weekRollType = undefined,
    halfMonthPivot = undefined,
    dateStrategy,
  } = payload;

  const data = {
    type,
    weekLocale: weekRollType,
    halfMonthPivot,
    date: dateStrategy,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.updateStatementStrategy, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* getSeatsCount() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.getSeatsCount, undefined, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export const login = (payload) => {
  const { email, password } = payload;

  const data = {
    email,
    password,
  };

  return ApiService.post(apiConfig.ENDPOINTS.profile.login, data);
};

export const forgotPassword = (payload) => {
  const { email } = payload;

  const data = {
    email,
  };

  return ApiService.post(apiConfig.ENDPOINTS.profile.forgotPassword, data);
};

export const resetPassword = (payload) => {
  const { resetPasswordToken, password } = payload;

  const data = {
    resetPasswordToken,
    password,
  };

  return ApiService.post(apiConfig.ENDPOINTS.profile.resetPassword, data);
};

export function* acknowledgeTermsAndConditions() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.profile.acknowledgeTermsAndConditions, undefined, getConfig()),
    [],
  );
}

export function* getAggregatedFxRates() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.getAggregatedFxRates, undefined, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* createFxRate(payload) {
  const { from, to, rate, startDate } = payload;

  const data = { from, to, rate, startDate };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.createFxRate, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* getListFxRates(payload) {
  const { from, to } = payload;

  const data = { from, to };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.listFxRates, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* deleteFxRate(payload) {
  const { fxRateId } = payload;

  const data = { fxRateId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.deleteFxRate, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* logout() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.profile.logout, undefined, getConfig()),
    [],
  );
}

export function* getSubscription() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.getSubscription, undefined, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* getCustomerPortalUrl() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.getCustomerPortalUrl, {}, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* updateSeats(payload) {
  const { quantity } = payload;

  const data = { quantity };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.updateSeats, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* sendFeedback(payload) {
  const { content, attachments } = payload;

  let text = content;
  if (attachments.length > 0) {
    const attachmentsUrls = attachments.join(', ');

    text = `${text}\n\nAttachments:\n${attachmentsUrls}`;
  }

  const data = {
    text,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.profile.sendFeedback, data, getConfig()),
    [],
  );
}

export function* updateStatementFxRateMode(payload) {
  const { value = STATEMENT_FX_RATES_MODE.FROM_PAYMENT_DATE } = payload;

  const data = { statementFxRateMode: value };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.updateStatementFxRateMode, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}
