import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import { useCompanyRoles } from '@palette/hooks/CompanyHooks';

import routePaths from '@palette/config/routePaths';

import { actions as CompanyActions } from '@palette/state/Company';

const CreateRole = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyRoles = useCompanyRoles();

  const initializeRoleCreation = useCallback(() => {
    const onRoleCreationSuccess = (roleId) => {
      redirectTo({ path: routePaths.v2.roleDetails, params: { roleId } });
    };

    dispatch(CompanyActions.createRole({
      name: `${t('role.createNewRole.initialName')} ${companyRoles.length + 1}`,
      description: '',
      onSuccessCB: onRoleCreationSuccess,
    }));
  }, [companyRoles]);

  return (
    <div className={className}>
      <Button
        type="primaryBlue"
        icon={<AddFilled />}
        onClick={initializeRoleCreation}
      >
        {t('role.createNewRole')}
      </Button>
    </div>
  );
};

CreateRole.propTypes = {
  className: PropTypes.string,
};

CreateRole.defaultProps = {
  className: '',
};

export default CreateRole;
