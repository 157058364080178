import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MasterPlanSettingsPayoutRulesSteps from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRulesSteps/MasterPlanSettingsPayoutRulesSteps';
import MasterPlanSettingsPayoutRulesForm from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRulesForm/MasterPlanSettingsPayoutRulesForm';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsPayoutRules.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsPayoutRules = ({ className, planId }) => {
  const { t } = useTranslation();

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));

  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample, { plan });

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {plan.payoutRulesDescription && (
        <>
          <div className={styles.title}>{t('masterPlanSettingsPayoutRules.descriptionTitle')}</div>
          <div className={styles.section}>
            <div className={styles.text}>{plan.payoutRulesDescription}</div>
          </div>
        </>
      )}

      <div className={styles.title}>{t('masterPlanSettingsPayoutRules.detailsTitle')}</div>

      <div className={styles.section}>
        <MasterPlanSettingsPayoutRulesSteps plan={plan} />
      </div>

      <div className={styles.title}>{t('masterPlanSettingsPayoutRules.configurationTitle')}</div>

      <div className={styles.section}>
        <MasterPlanSettingsPayoutRulesForm
          plan={plan}
          helperData={formulaHelperData}
        />
      </div>
    </div>
  );
};

MasterPlanSettingsPayoutRules.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

MasterPlanSettingsPayoutRules.defaultProps = {
  className: '',
};

export default MasterPlanSettingsPayoutRules;
