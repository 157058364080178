import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@palette/components/designSystem/Link/Link';
import Tag from '@palette/components/designSystem/Tag/Tag';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import Button from '@palette/components/designSystem/Button/Button';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { getColumnValue, getResourceColumns } from '@palette/helpers/ConnectorHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import routePaths from '@palette/config/routePaths';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as ResourceObjectModel from '@palette/models/ResourceObject';

import { actions as UsersActions } from '@palette/state/Users';
import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';

import styles from './UserLinkedResourceItem.less';

const classNames = bindClassNames.bind(styles);

const UserLinkedResourceItem = ({ className, user, resourceObject }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const connectorsList = useConnectorsList();

  const dispatch = useDispatch();

  const resourceObjectConnector = useMemo(() => (
    connectorsList.find((connector) => (connector.id === resourceObject.connectorId))
  ), [connectorsList, resourceObject]);

  const resourceDetailedObject = useSelector((state) => ResourcesSelectors.getResourceById(state, { resourceId: resourceObject.id }));

  useEffect(() => {
    dispatch(ResourcesActions.getById({ connectorId: resourceObject.connectorId, objectId: resourceObject.id, type: resourceObject.originalType }));
  }, [resourceObject]);

  const linkNode = useMemo(() => (
    <Link
      key={resourceObject.id}
      className={styles.link}
      path={routePaths.v2.resourceDetails}
      params={{
        resourceId: resourceObject.id,
        connectorId: resourceObject.connectorId,
        type: resourceObject.originalType,
      }}
      displayIcon
    >
      {t('userLinkedResourceItem.viewLinkedResource')}
    </Link>
  ), [resourceObject]);

  const handleUnlinkResource = useCallback(() => {
    dispatch(UsersActions.unlinkResourceFromUser({ userId: user.id, resourceObjectId: resourceObject.id }));
  }, [user, resourceObject]);

  const detailsContentNode = useMemo(() => {
    if (resourceDetailedObject === null) return null;

    const resourceColumns = getResourceColumns(
      resourceObjectConnector,
      resourceDetailedObject.originalType,
      (column) => column.displayInResources,
    );

    const detailsNodes = resourceColumns.map((resourceColumn) => (
      <div key={resourceColumn.name} className={styles.details}>
        <div className={styles.detailsLabel}>
          {resourceColumn.name}
        </div>
        <div className={styles.detailsValue}>
          {getColumnValue(resourceDetailedObject.data ?? {}, resourceColumn)}
        </div>
      </div>
    ));

    return (
      <div className={styles.detailsList}>
        {detailsNodes}
      </div>
    );
  }, [resourceDetailedObject, resourceObjectConnector]);

  const unlinkResourceBtnNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.UPDATE])) return null;

    return (
      <Popconfirm
        title={t('userLinkedResourceItem.unlinkResource.popconfirm.title')}
        onConfirm={handleUnlinkResource}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Button
          className={styles.unlinkResourceBtn}
          icon={(<ClosePopupFilled className={styles.unlinkResourceBtnIcon} width={16} height={16} />)}
          disabled={false}
        />
      </Popconfirm>
    );
  }, [handleUnlinkResource]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkActionsWrapper}>
        <div className={styles.linkWrapper}>
          <Tag
            theme={TAG_THEMES.LAVENDER}
            label={resourceObjectConnector?.name}
          />
          <Tag
            className={styles.type}
            theme={TAG_THEMES.LAVENDER}
            label={resourceObject.type}
          />
          {linkNode}
        </div>
        {unlinkResourceBtnNode}
      </div>
      <Collapse
        className={styles.detailsCollapse}
        panels={[
          {
            title: t('userLinkedResourceItem.viewDetails'),
            content: detailsContentNode,
          },
        ]}
        ghost
      />
    </div>
  );
};

UserLinkedResourceItem.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
  resourceObject: ResourceObjectModel.propTypes.isRequired,
};

UserLinkedResourceItem.defaultProps = {
  className: '',
};

export default UserLinkedResourceItem;
