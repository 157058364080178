import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as StatementHistoryUserModel from '@palette/models/StatementHistoryUser';

export const modelName = 'StatementCorrectionAppliedDeleteStrategy';

export const propTypes = PropTypes.exact({
  approver: StatementHistoryUserModel.propTypes,
  amount: PropTypes.number,
  currency: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      approver: StatementHistoryUserModel.transform(manageFreeShapeObjectAttribute(strategy, 'doneBy')),
      amount: manageAmountAttribute(strategy, 'amount'),
      currency: manageStringAttribute(strategy, 'currency'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
