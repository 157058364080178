import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* commissionV3getCommissionDetails(payload) {
  const { commissionId } = payload;

  const endpoint = apiConfig.ENDPOINTS.commissionV3.detailsCommission.replace('{commissionId}', commissionId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* commissionV3overwriteAmount(payload) {
  const { commissionId, amount } = payload;

  const params = { amount };

  const endpoint = apiConfig.ENDPOINTS.commissionV3.overwriteCommission.replace('{commissionId}', commissionId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.COMMISSIONS.MANAGE],
  );
}

export function* commissionV3overwritePayments(payload) {
  const { commissionId, payments } = payload;

  const params = { payments };

  const endpoint = apiConfig.ENDPOINTS.commissionV3.overwritePayments.replace('{commissionId}', commissionId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.COMMISSIONS.MANAGE],
  );
}

export function* commissionV3getICCommissionDetails(payload) {
  const { commissionId } = payload;

  const endpoint = apiConfig.ENDPOINTS.ic.commissionV3.detailsCommission.replace('{commissionId}', commissionId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}
