import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PlanWidgetBase from '@palette/components/widgets/dashboard/PlanWidgetBase/PlanWidgetBase';
import PlanProgressGaugeChart from '@palette/components/charts/PlanProgressGaugeChart/PlanProgressGaugeChart';
import ClappingHandsLottieAnimation from '@palette/components/utils/ClappingHandsLottieAnimation/ClappingHandsLottieAnimation';

import { CLICK_ON_EASTER_EGG_OVERPERFORMING } from '@palette/constants/analytics';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';
import * as PlanProgressGaugeDataModel from '@palette/models/widgets/dashboard/PlanProgressGaugeData';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './PlanProgressGaugeWidget.less';

const classNames = bindClassNames.bind(styles);

const PlanProgressGaugeWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planProgressGaugeData = PlanProgressGaugeDataModel.transform(widgetData.data);

  const handleClappingHandsLottieAnimationClick = useCallback((animationIsPlaying) => {
    if (!readOnly && animationIsPlaying) {
      dispatch(AnalyticsActions.sendEvent({ event: CLICK_ON_EASTER_EGG_OVERPERFORMING }));
    }
  }, [readOnly]);

  const clappingHandsLottieAnimation = useMemo(() => {
    if (planProgressGaugeData.progress < 1) return null;

    return (
      <ClappingHandsLottieAnimation
        className={styles.clappingHandsAnimation}
        onClick={handleClappingHandsLottieAnimationClick}
      />
    );
  }, [planProgressGaugeData, handleClappingHandsLottieAnimationClick]);

  return (
    <PlanWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      useMostRecent={widgetData.dashboardComponent.params.useMostRecent}
      plan={planPeriodData.plan}
      period={planPeriodData.period}
      title={t('planProgressGaugeWidget.title')}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      {clappingHandsLottieAnimation}
      <PlanProgressGaugeChart
        plan={planPeriodData.plan}
        data={planProgressGaugeData}
      />
    </PlanWidgetBase>
  );
};

PlanProgressGaugeWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanProgressGaugeWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlanProgressGaugeWidget;
