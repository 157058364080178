import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Tag from '@palette/components/designSystem/Tag/Tag';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';
import { statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';
import { getStatementUserApprovalStatus, useStatementPeriodStatus } from '@palette/helpers/StatementV3Helper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_PERIOD_STATUSES, STATEMENT_PERIOD_USER_STATUSES, STATEMENT_APPROVAL_STATUSES } from '@palette/constants/statements';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import { usePeriodsDatesFilter } from '@palette/hooks/StatementHooks';

import * as StatementV3PeriodModel from '@palette/models/StatementV3Period';
import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import styles from './StatementV3ApproveAction.less';

const classNames = bindClassNames.bind(styles);

const StatementV3ApproveAction = ({
  className,
  userStatement,
  currency,
  isForIC,
  isSecondaryType,
  userId,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const addActionIsPending = useSelector(StatementV3Selectors.addActionIsPending);
  const deleteActionIsPending = useSelector(StatementV3Selectors.deleteActionIsPending);

  const approvalIsPending = useSelector((state) => StatementV3Selectors.getApprovalIsPending(state, { statementPeriodId: userStatement.id }));
  const approvalIsWaiting = useSelector((state) => StatementV3Selectors.getApprovalIsWaiting(state, { statementPeriodId: userStatement.id }));

  const statementPeriodStatus = useStatementPeriodStatus(userStatement);
  const statementUserApprovalStatus = getStatementUserApprovalStatus(userStatement, profile.userData.id);

  const [, from, to] = usePeriodsDatesFilter();

  const handleApprove = useCallback(() => {
    dispatch(StatementV3Actions.addAction({
      statementPeriodId: userStatement.id,
      type: STATEMENT_ACTION_TYPES.APPROVAL,
      currency,
      statementCurrency: userStatement.currency,
      isForIC,
      from,
      to,
      userId,
    }));
  }, [userStatement, currency, isForIC, from, to, userId]);

  const handleCancelApproval = useCallback(() => {
    dispatch(StatementV3Actions.deleteAction({
      statementPeriodId: userStatement.id,
      type: STATEMENT_ACTION_TYPES.APPROVAL,
      currency,
      statementCurrency: userStatement.currency,
      isForIC,
      from,
      to,
      userId,
    }));
  }, [userStatement, currency, isForIC, from, to, userId]);

  const contentNode = useMemo(() => {
    const isFarInFuture = statementPeriodIsInFuture(userStatement, true, 'fromDate');

    if (
      !statementPeriodStatus
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAID
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.EMPTY_STATEMENTS
      || ( // far in the future
        statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE
        && isFarInFuture
      )
      || userStatement.isPaid
    ) return null;

    if (statementUserApprovalStatus === STATEMENT_APPROVAL_STATUSES.APPROVED) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_USER_STATUSES.APPROVED;

      return (
        <div className={styles.approvedWrapper}>
          <Tag
            theme={theme}
            label={t(i18n)}
            icon={icon}
          />
          <Popconfirm
            title={t('statementPeriodUsers.cancelApproval.button.popconfirm')}
            onConfirm={handleCancelApproval}
            okText={t('common.global.yes')}
            cancelText={t('common.global.no')}
            size="small"
            disabled={deleteActionIsPending || userStatement.isDirty}
          >
            <Button
              className={styles.cancelApprovalBtn}
              icon={(<ClosePopupFilled className={styles.cancelApprovalBtnIcon} width={16} height={16} />)}
              disabled={deleteActionIsPending || userStatement.isDirty}
            />
          </Popconfirm>
        </div>
      );
    }

    const needReapproval = statementUserApprovalStatus === STATEMENT_APPROVAL_STATUSES.IN_CONFLICT;
    const typeBtnLabel = needReapproval ? 'reapprove' : 'approve';

    let btnLabel = t(`statementPeriodUsers.${typeBtnLabel}.button.label`);
    if (approvalIsWaiting) {
      btnLabel = t('statementPeriodUsers.approve.button.waitingLabel');
    }
    if (approvalIsPending) {
      btnLabel = t('statementPeriodUsers.approve.button.processingLabel');
    }

    let buttonType = isSecondaryType ? 'infoSecondary' : 'primaryBlue';
    if (needReapproval) {
      buttonType = isSecondaryType ? 'warningSecondary' : 'warning';
    }

    return (
      <Popconfirm
        title={t(`statementPeriodUsers.${typeBtnLabel}.button.popconfirm`)}
        onConfirm={handleApprove}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
        disabled={addActionIsPending}
      >
        <Button
          className={classNames({ smallButton: isSecondaryType })}
          type={buttonType}
          icon={(approvalIsPending || approvalIsWaiting) ? <LoadingLine spin /> : <CheckFilled />}
          disabled={addActionIsPending}
        >
          {btnLabel}
        </Button>
      </Popconfirm>
    );
  }, [
    userStatement,
    statementPeriodStatus,
    addActionIsPending,
    deleteActionIsPending,
    approvalIsPending,
    approvalIsWaiting,
    handleCancelApproval,
    handleApprove,
    isSecondaryType,
    statementUserApprovalStatus,
  ]);

  const contentContainer = useMemo(() => {
    if (!contentNode) return null;

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
    );
  }, [contentNode]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return contentContainer;
};

StatementV3ApproveAction.propTypes = {
  className: PropTypes.string,
  userStatement: PropTypes.oneOfType([StatementV3PeriodModel.propTypes, StatementV3OneStatementModel.propTypes]),
  currency: PropTypes.string,
  isForIC: PropTypes.bool,
  isSecondaryType: PropTypes.bool,
  userId: PropTypes.string,
};

StatementV3ApproveAction.defaultProps = {
  className: '',
  userStatement: null,
  currency: null,
  isForIC: false,
  isSecondaryType: false,
  userId: null,
};

export default StatementV3ApproveAction;
