/* eslint-disable prefer-promise-reject-errors, react/no-unescaped-entities, react/no-unused-prop-types, no-unused-vars */
import React, { Component } from 'react';
import {
  DoubleLeftOutlined, DoubleRightOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PaletteLogo from '@palette/components/utils/PaletteLogo/PaletteLogo';
import routePaths from '@palette/config/routePaths';

import { isConnected } from '../services/user';
import Loader from '../components/loader';
import '../styles/login.less';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  componentDidMount() {
    if (isConnected()) {
      this.props.history.push('/');
    }
  }

  render() {
    const { loading } = this.state;

    return (
      <div className="Login_global_global">
        <MetaTag title="Sign up" />
        <div className="Login_global_wrapper">
          <div className="Register_form_double">
            <div className="Register_form_one">
              <div className="Register_quote_wrapper">
                <div style={{ position: 'relative' }}>
                  <div className="Register_quote_icon Register_quote_icon_top">
                    <DoubleLeftOutlined />
                  </div>
                  <div className="Register_quote">
                    Helping people get the full benefit from of
                    their sales compensation plan is our mission.
                  </div>
                  <div style={{ textAlign: 'right' }} className="Register_quote_icon Register_quote_icon_bottom">
                    <DoubleRightOutlined />
                  </div>
                </div>

                <div className="Register_quote_credits_company">CEO @Palette</div>
              </div>
            </div>
            <div className="Register_form_one">
              <div className="Login_form_logo_wrapper">
                <PaletteLogo width={272} height={90} />
              </div>
              <div className="center">
                Contact us at
                {' '}
                <br />
                <b>hello@palettehq.com</b>
                <br />
                {' '}
                to open an account.
              </div>
              <br />
              <div>
                <a className="Login_register_link" href={`/#${routePaths.login}`}>
                  Login
                </a>
              </div>
              {loading && <Loader />}
            </div>
          </div>

        </div>
      </div>
    );
  }
}

Register.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

Register.defaultProps = {
  history: {},
  location: {},
};

export default (withRouter(Register));
