import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import EditableRoleName from '@palette/components/role/EditableRoleName/EditableRoleName';
import EditableRoleDescription from '@palette/components/role/EditableRoleDescription/EditableRoleDescription';
import RoleRights from '@palette/components/role/RoleRights/RoleRights';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { useRoleInParams } from '@palette/hooks/RoleHooks';

import { ALERT_TYPES } from '@palette/constants/alert';

import { actions as CompanyActions } from '@palette/state/Company';

import styles from './RoleDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const RoleDetailsPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { role } = useRoleInParams(true);

  const roleNameNode = useMemo(() => {
    if (role === null) return null;

    if (role.isProtected) {
      return (
        <h1>
          {role.name}
        </h1>
      );
    }

    return (
      <EditableRoleName role={role} />
    );
  }, [role]);

  const roleDescriptionNode = useMemo(() => {
    if (role === null) return null;

    if (role.isProtected) {
      return (
        <div
          className={classNames({
            descriptionWrapper: true,
            description: true,
          })}
        >
          {role.description}
        </div>
      );
    }

    return (
      <EditableRoleDescription className={styles.descriptionWrapper} role={role} />
    );
  }, [role]);

  const isProtectedAlertNode = useMemo(() => {
    if (role === null || !role.isProtected) return null;

    return (
      <Alert
        className={styles.isProtectedAlert}
        type={ALERT_TYPES.INFO}
        message={t('roleDetailsPageContent.isProtectedAlert')}
      />
    );
  }, [role]);

  const handleRightsUpdate = useCallback((newRights) => {
    if (role !== null) {
      dispatch(CompanyActions.updateRole({ roleId: role.id, rights: newRights }));
    }
  }, [role]);

  if (role === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        {roleNameNode}
        {roleDescriptionNode}
      </div>
      <div className={styles.content}>
        {isProtectedAlertNode}
        <RoleRights role={role} onUpdate={handleRightsUpdate} />
      </div>
    </div>
  );
};

RoleDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

RoleDetailsPageContent.defaultProps = {
  className: '',
};

export default RoleDetailsPageContent;
