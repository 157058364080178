import PropTypes from 'prop-types';

import { manageFreeShapeObjectAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import { entities } from '@palette/models/globalNotifStrategies/entities';

export const modelName = 'GlobalNotif';

export const propTypes = PropTypes.shape({
  reasonKey: PropTypes.oneOf(Object.keys(GLOBAL_NOTIF_REASONS)),
  theme: PropTypes.oneOf(Object.values(GLOBAL_NOTIF_THEMES)),
  type: PropTypes.oneOf(Object.values(GLOBAL_NOTIF_TYPES)),
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.oneOfType(Object.values(entities).map((entity) => entity.propTypes)),
  fadeOut: PropTypes.bool,
  hidden: PropTypes.bool,
});

export const manageReasonKey = (reasonCode) => Object.keys(GLOBAL_NOTIF_REASONS).find((reason) => GLOBAL_NOTIF_REASONS[reason].code === reasonCode);

export const transform = (globalNotif) => {
  if (!globalNotif) {
    return null;
  }

  const notif = globalNotif;

  if (!notif.code && !notif.context && notif.message) {
    const { code, context } = manageFreeShapeObjectAttribute(notif, 'message');

    notif.code = code ?? GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_UNKNOWN.code;
    notif.context = context;
  }

  const reasonCode = manageStringAttribute(notif, 'code');
  const reasonKey = manageReasonKey(reasonCode);
  const { type } = GLOBAL_NOTIF_REASONS[reasonKey];

  const manageId = (notification) => {
    let id = manageStringAttribute(notification, 'id', null);

    if (!id && type === GLOBAL_NOTIF_TYPES.PERMANENT) {
      id = `${reasonCode}-${Date.now()}`;
    }

    return id;
  };

  return (
    {
      reasonKey,
      theme: GLOBAL_NOTIF_REASONS[reasonKey].theme,
      type,
      id: manageId(notif),
      title: reasonCode,
      content: entities[reasonCode]?.transform(manageFreeShapeObjectAttribute(notif, 'context')),
      fadeOut: false,
      hidden: false,
    }
  );
};

export const transformList = (globalNotifs) => globalNotifs.map((globalNotif) => transform(globalNotif));
