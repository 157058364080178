import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import PlanV3RequirementsPageContent from '@palette/components/planV3/PlanV3RequirementsPageContent/PlanV3RequirementsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './PlanV3RequirementsPage.less';

const PlanV3RequirementsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { planId } = useParams();

  if (
    !planId
    || !hasAllRights(profile, [
      RIGHTS.ADMIN.PLANS.VIEW,
      RIGHTS.ADMIN.PLANS.CREATE,
      RIGHTS.ADMIN.PLANS.UPDATE,
      RIGHTS.ADMIN.PLANS.GBO.MANAGE,
      RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
      RIGHTS.ADMIN.QUOTAS.VIEW,
      RIGHTS.ADMIN.QUOTAS.MANAGE,
      RIGHTS.ADMIN.QUOTAS.USERS.MANAGE,
    ])
  ) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.requirementsPlan')} />
      <PageTitle
        className={styles.titleContainer}
        title={t('app.metas.requirementsPlan')}
        subtitle={t('createPlanPage.subtitle.top')}
        positionSubtitle="top"
      />
      <PlanV3RequirementsPageContent planId={planId} />
    </div>
  );
};

export default PlanV3RequirementsPage;
