import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Form as AntDForm } from 'antd';

import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Form from '@palette/components/designSystem/Form/Form';
import TextArea from '@palette/components/designSystem/TextArea/TextArea';
import Button from '@palette/components/designSystem/Button/Button';
import Avatar from '@palette/components/user/Avatar/Avatar';

import { useProfile } from '@palette/hooks/ProfileHooks';

import * as CommissionModel from '@palette/models/Commission';

import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';

import styles from './CommissionCommentForm.less';

const classNames = bindClassNames.bind(styles);

const CommissionCommentForm = ({ className, commission, onChangeOpenChange }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const initialValues = {
    comment: '',
  };

  const [open, setOpen] = useState(false);
  const [allValues, setAllValues] = useState(initialValues);

  const addCommentIsPending = useSelector(CommissionsSelectors.addCommentIsPending);

  const [form] = AntDForm.useForm();

  const resetForm = () => {
    form.resetFields();
    setAllValues(initialValues);
  };

  const handleFinish = ({ comment }) => {
    dispatch(CommissionsActions.addComment({ commissionId: commission.id, comment, onSuccessCB: resetForm }));
  };

  const handlePublishComment = (event) => {
    event.stopPropagation();
    form.submit();
  };

  const changeOpen = useCallback((newOpen) => {
    setOpen(newOpen);
    if (onChangeOpenChange !== null) onChangeOpenChange(newOpen);
  });

  const handleTextAreaFocus = () => {
    changeOpen(true);
  };

  const handleTextAreaBlur = () => {
    setTimeout(() => {
      changeOpen(false);
    }, 200);
  };

  const labelNode = (
    <div className={styles.labelWrapper}>
      <Avatar user={profile.userData} />
      <div className={styles.label}>
        {t('commissionCommentForm.form.comment.label')}
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        open,
        [className]: className !== '',
      })}
    >
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
        onValuesChange={(changedValues, allFormValues) => { setAllValues(allFormValues); }}
      >
        <FormItem
          className={styles.commentFormItem}
          name="comment"
          label={labelNode}
        >
          <TextArea
            className={styles.textArea}
            rows={4}
            placeholder={t('commissionCommentForm.form.comment.placeholder')}
            disableResize
            disabled={addCommentIsPending}
            onFocus={handleTextAreaFocus}
            onBlur={handleTextAreaBlur}
          />
        </FormItem>
        <Button
          className={styles.submitBtn}
          disabled={allValues.comment === '' || addCommentIsPending}
          loading={addCommentIsPending}
          onClick={handlePublishComment}
        >
          {t('commissionCommentForm.form.submit.label')}
        </Button>
      </Form>
    </div>
  );
};

CommissionCommentForm.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  onChangeOpenChange: PropTypes.func,
};

CommissionCommentForm.defaultProps = {
  className: '',
  onChangeOpenChange: null,
};

export default CommissionCommentForm;
