/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import * as uuid from 'uuid';
import { v4 as uuidv4 } from 'uuid';
import {
  Button, Checkbox, Divider, Form, Input, Modal, Select, Table, Tag, Tooltip,
} from 'antd';
import { DatabaseOutlined, FileTextOutlined, FunctionOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CloseSimpleLine from '@palette/components/utils/Icons/CloseSimpleLine';
import ReactDragListView from 'react-drag-listview';

import '../../styles/admin/columnEditor.less';

class ColumnEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.props.initialValue.map((v) => ({
        ...v,
        uuid: uuid.v4(),
      })),
      showEvalFnModal: null,
    };
  }

  getColumns = () => this.state.columns.map(({
    uuid: x,
    ...column
  }) => column);

  onChange = () => this.props.onChange(this.getColumns());

  onColumnChange = (value, index, field) => {
    this.setState(({
      columns,
      ...prevState
    }) => {
      // eslint-disable-next-line no-param-reassign
      columns[index][field] = value;
      return {
        ...prevState,
        columns: [...columns],
      };
    }, this.onChange);
  };

  onEvalFnUpdate = ({ evalFn }) => {
    const { showEvalFnModal: { index } } = this.state;
    this.onColumnChange(evalFn, index, 'evalFn');
    this.setState({ showEvalFnModal: null });
  };

  addColumn = () => this.setState((prevState) => ({
    ...prevState,
    columns: [
      ...prevState.columns,
      {
        formatter: 'String',
        name: '',
        fields: [],
        uuid: uuidv4(),
        resources: true,
      },
    ],
  }), this.onChange);

  deleteColumn = (index) => {
    const { columns } = this.state;
    columns.splice(index, 1);
    this.setState({ columns: [...columns] }, this.onChange);
  };

  tagNode = (props) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        className="ColumnEditor_tag"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        closeIcon={<CloseSimpleLine width={12} height={12} />}
      >
        {label}
      </Tag>
    );
  };

  render() {
    const {
      columns,
      showEvalFnModal,
    } = this.state;
    const {
      elementType,
      autoCompleteFields,
      customizedFields,
      displayCheckboxes,
      disabled,
    } = this.props;
    return (
      <div className="ColumnEditor_wrapper">
        <ReactDragListView
          lineClassName="Drag_line"
          onDragEnd={(fromIndex, toIndex) => {
            const { columns: c } = this.state;
            // eslint-disable-next-line max-len
            const [elem] = c.splice(fromIndex, 1);
            c.splice(toIndex, 0, elem);
            this.setState({ columns: [...c] }, this.onChange);
          }}
          handleSelector="a"
        >
          <Table
            size="small"
            pagination={false}
            dataSource={columns}
            rowKey="uuid"
          >
            {!disabled && (
              <Table.Column
                key="uuid"
                align="center"
                render={() => (
                  <div>
                    <a
                      style={{ color: '#979797' }}
                      className="drag-handle"
                    >
                      <div className="No_select">
                        <img
                          draggable="false"
                          src="/img/drag_icon.svg"
                          alt="drag"
                          className="No_select"
                          style={{
                            width: '20px',
                            height: '20px',
                            outline: 'none',
                            opacity: '0.3',
                          }}
                        />
                      </div>
                    </a>
                  </div>
                )}
              />
            )}
            <Table.Column
              title="Column name"
              key="uuid"
              render={(id, { name }, index) => (
                <div>
                  <Input
                    style={{ width: '160px' }}
                    key={id}
                    name={id}
                    value={name}
                    placeholder="Enter a column name"
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.onColumnChange(e.target.value, index, 'name');
                    }}
                    disabled={disabled}
                  />
                </div>
              )}
            />
            <Table.Column
              title={(
                <span style={{ textTransform: 'capitalize' }}>
                  {`${elementType ? `${elementType.toLowerCase()} ` : ''}Fields `}
                  <Tooltip
                    title="If you add multiple fields, they'll be concatenated with a space in between."
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              )}
              key="uuid"
              render={(id, { fields }, index) => (
                <Select
                  key={id}
                  mode="multiple"
                  tagRender={this.tagNode}
                  style={{ width: '260px' }}
                  placeholder="Enter a field"
                  value={fields}
                  dropdownMatchSelectWidth={false}
                  onChange={(e) => this.onColumnChange(e, index, 'fields')}
                  disabled={disabled}
                >
                  {autoCompleteFields.map((field) => {
                    const isCustomizedField = customizedFields.includes(field.split('.')[0]);

                    return (
                      <Select.Option value={field} key={field}>
                        <span style={{ textTransform: 'none' }}>
                          {field}
                          {isCustomizedField && ' (custom field)'}
                        </span>
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            />
            <Table.Column
              title="Display"
              key="uuid"
              render={(e, column, index) => (
                <div style={{ display: 'flex' }}>
                  <Select
                    value={column.formatter}
                    style={{ width: 110 }}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => {
                      this.onColumnChange(value, index, 'formatter');
                      if (value === 'Custom') {
                        this.setState({
                          showEvalFnModal: {
                            column,
                            index,
                          },
                        });
                      }
                    }}
                    disabled={disabled}
                  >
                    <Select.Option value="String">Default</Select.Option>
                    <Select.Option value="Date"><span style={{ textTransform: 'none' }}>Date (YYYY-MM-DD)</span></Select.Option>
                    <Select.Option value="DateTime"><span style={{ textTransform: 'none' }}>Date & Time (YYYY-MM-DD HH:mm)</span></Select.Option>
                    <Select.Option value="Custom">Custom</Select.Option>
                  </Select>
                  {column.formatter === 'Custom' && (
                    <Button
                      type="link"
                      icon={<FunctionOutlined />}
                      onClick={() => this.setState({
                        showEvalFnModal: {
                          column,
                          index,
                        },
                      })}
                    />
                  )}
                </div>
              )}
            />
            {displayCheckboxes && (
              <>
                <Table.Column
                  align="center"
                  title={(
                    <span>
                      <DatabaseOutlined />
                      {' Resources'}
                    </span>
                  )}
                  key="uuid"
                  render={(id, { resources }, index) => (
                    <Checkbox
                      style={{ width: '85px' }}
                      onChange={(e) => this.onColumnChange(e.target.checked, index, 'resources')}
                      checked={resources}
                      disabled={disabled}
                    />
                  )}
                />
                <Table.Column
                  align="center"
                  title={(
                    <span>
                      <FileTextOutlined />
                      {' Plans'}
                    </span>
                  )}
                  key="uuid"
                  render={(id, { plans }, index) => (
                    <Checkbox
                      style={{ width: '60px' }}
                      onChange={(e) => this.onColumnChange(e.target.checked, index, 'plans')}
                      checked={plans}
                      disabled={disabled}
                    />
                  )}
                />
              </>
            )}
            {!disabled && (
              <Table.Column
                key="uuid"
                align="right"
                render={(id, val, index) => (
                  <div className="ColumnEditor_delete_wrapper">
                    <Divider
                      type="vertical"
                      style={{
                        height: '18px',
                        marginTop: '8px',
                        marginLeft: '0',
                      }}
                    />
                    <Button
                      ghost
                      className="grey"
                      style={{
                        border: 'none',
                        boxShadow: 'none',
                        padding: '4px 8px',
                      }}
                      onClick={() => this.deleteColumn(index)}
                    >
                      <CloseSimpleLine width={18} height={18} />
                    </Button>
                  </div>
                )}
              />
            )}
          </Table>
        </ReactDragListView>
        {!disabled && (
          <Button
            icon={<PlusOutlined />}
            type="primary"
            ghost
            size="small"
            onClick={() => this.addColumn()}
            style={{ marginTop: '10px' }}
          >
            Add Column
          </Button>
        )}
        {showEvalFnModal && (
          <Modal
            title={`${showEvalFnModal.column.name} custom function`}
            onCancel={() => this.setState({ showEvalFnModal: null })}
            visible
            footer={null}
          >
            <Form
              onFinish={this.onEvalFnUpdate}
              initialValues={{ evalFn: showEvalFnModal.column.evalFn }}
            >
              <Form.Item name="evalFn">
                <Input placeholder="Type code here" />
              </Form.Item>
              <Form.Item
                style={{
                  textAlign: 'right',
                  marginBottom: 0,
                }}
              >
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={disabled}
                >
                  Validate
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

ColumnEditor.propTypes = {
  initialValue: PropTypes.array,
  autoCompleteFields: PropTypes.array,
  customizedFields: PropTypes.array,
  elementType: PropTypes.string,
  displayCheckboxes: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

ColumnEditor.defaultProps = {
  initialValue: [],
  autoCompleteFields: [],
  customizedFields: [],
  elementType: '',
  displayCheckboxes: false,
  disabled: false,
  onChange: () => null,
};

export default ColumnEditor;
