import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _sortBy from 'lodash/sortBy';

import * as FolderWithResourcesModel from '@palette/models/FolderWithResources';

import { folderColorPropTypes, folderColorDefaultProps } from '@palette/components/folders/FoldersRecursive/FolderTitle/FolderTitle';

import styles from './FolderSubFolders.less';

// eslint-disable-next-line import/no-cycle
const Folder = React.lazy(() => import('@palette/components/folders/FoldersRecursive/Folder/Folder'));

const classNames = bindClassNames.bind(styles);

const FolderSubFolders = ({
  className,
  subFolderClassName,
  folder,
  ItemComponent,
  fromRoot,
  onMoveFolder,
  onMoveEntity,
  folderColor,
}) => {
  if (folder.children.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        _sortBy(folder.children, [(c) => c.name.toUpperCase()]).map((subFolder) => (
          <React.Suspense key={subFolder.id} fallback={null}>
            <Folder
              className={classNames({
                [subFolderClassName]: subFolderClassName !== '',
              })}
              folder={subFolder}
              ItemComponent={ItemComponent}
              fromRoot={fromRoot}
              onMoveFolder={onMoveFolder}
              onMoveEntity={onMoveEntity}
              folderColor={folderColor}
            />
          </React.Suspense>
        ))
      }
    </div>
  );
};

FolderSubFolders.propTypes = {
  className: PropTypes.string,
  subFolderClassName: PropTypes.string,
  folder: FolderWithResourcesModel.propTypes.isRequired,
  ItemComponent: PropTypes.func.isRequired,
  fromRoot: PropTypes.bool,
  onMoveFolder: PropTypes.func,
  onMoveEntity: PropTypes.func,
  folderColor: folderColorPropTypes,
};

FolderSubFolders.defaultProps = {
  className: '',
  subFolderClassName: '',
  fromRoot: false,
  onMoveFolder: null,
  onMoveEntity: null,
  folderColor: folderColorDefaultProps,
};

export default FolderSubFolders;
