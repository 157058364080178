import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Disclaimer from '@palette/components/designSystem/Disclaimer/Disclaimer';
import Button from '@palette/components/designSystem/Button/Button';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanRecomputeWarning.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanRecomputeWarning = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const moment = getMoment();

  const computePlanIsPending = useSelector(MasterPlansSelectors.computePlanIsPending);

  if (
    plan !== null
    && plan.lastComputedAt !== null
    && plan.lastBreakingChangeUpdate !== null
    && moment(plan.lastComputedAt).utc().isBefore(moment(plan.lastBreakingChangeUpdate).utc())
    && !plan.allPeriodsFrozen
  ) {
    const handleComputePlan = () => {
      dispatch(MasterPlansActions.computePlan({ planId: plan.id }));
    };

    const recomputeButton = (
      <Button
        icon={(<RefreshLine width={18} height={18} spin={computePlanIsPending} />)}
        onClick={handleComputePlan}
        disabled={computePlanIsPending}
      >
        {t('masterPlanRecomputeWarning.recompute')}
      </Button>
    );

    return (
      <Disclaimer
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
        type="warning"
        title={t('masterPlanRecomputeWarning.title')}
        description={t('masterPlanRecomputeWarning.description')}
        extra={recomputeButton}
      />
    );
  }

  return null;
};

MasterPlanRecomputeWarning.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

MasterPlanRecomputeWarning.defaultProps = {
  className: '',
  plan: null,
};

export default MasterPlanRecomputeWarning;
