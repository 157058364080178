import React from 'react';
import {
  message, Result, Card, Button,
} from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import smartlookClient from 'smartlook-client';
import ReactGA from 'react-ga';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';

import LoggedUserApp from '@palette/components/navigation/LoggedUserApp/LoggedUserApp';
import MaintenancePageContent from '@palette/components/maintenance/MaintenancePageContent/MaintenancePageContent';
import routePaths from '@palette/config/routePaths';

import { listConnectors } from '../../services/connector';
import { getProfile, profile$, shouldDisplayNPS } from '../../services/user';
import {
  isSuperAdmin, isDemoAccount, onRouteChanged, deleteCookie,
  showTrial, hasRight,
} from '../../services/utils';
import FullScreenLoader from '../../components/loader';
import { availableConnectorsList } from './connectorsHelper';
import '../../styles/index.less';
import 'animate.css';
import { MAINTENANCE_MODE } from '../../services/constants';
import { sendCompanyGroup, sendIdentify, sendPageVisit } from '../../services/analytics';

class AppWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      loading: true,
      loadingConnectorsList: false,
      connectors: null,
      unlisten: () => {},
    };
  }

  componentDidMount() {
    if (MAINTENANCE_MODE || window.location.href.indexOf(routePaths.v2.maintenance) !== -1) {
      this.setState({ loading: false });
      return;
    }
    this.getTheProfile();
    this.subscription = profile$.subscribe((profile) => {
      if (profile) {
        this.getTheConnectorsList(profile);
        this.setState({ profile });
        if (window
          && window.location
          && window.location.href.indexOf('localhost') === -1
          && window.location.host !== 'salesforce.palettehq.com'
          && !isSuperAdmin()
          && !isDemoAccount(profile)
        ) {
          try {
            if (!smartlookClient.initialized()) {
              smartlookClient.init('fcd117bbb9d493606d1c447cb9ea7e58028004f0');
              setTimeout(() => {
                smartlookClient.identify(profile.userData.id, {
                  name: `${profile.userData.firstName} ${profile.userData.lastName}`,
                  email: profile.userData.email,
                  company: profile.userData.company.name,
                  role: profile?.userData.account?.role?.name,
                });
              }, 1000);
            }
            if (profile.userData.account.intercomIdentifier !== null) {
              window.Intercom('boot', {
                api_base: 'https://api-iam.intercom.io',
                app_id: 'wnpwllra',
                user_id: profile.userData.id,
                user_hash: profile.userData.account.intercomIdentifier,
              });
              window.Intercom('update', {
                email: profile.userData.email,
                name: `${profile.userData.firstName} ${profile.userData.lastName}`,
                user_id: profile.userData.id,
                companies: [{ name: profile.userData.company.name, company_id: profile.userData.company.id }],
                role: profile?.userData.account?.role?.name,
              });
            }
            sendIdentify();
            sendPageVisit(this.props.location.pathname, {
              path: `/${this.props.location.pathname}`,
              search: this.props.location.search,
              userId: profile.userData.id,
            });
            sendCompanyGroup();
          } catch (r) { console.error(r); }
        }
        ReactGA.set({ userId: profile.userData.id, email: profile.userData.email });
      }
    });
    ReactGA.initialize('UA-187845755-1');
    ReactGA.pageview(`/${this.props.location.pathname}${this.props.location.search}`);
    // Listen history for GoogleAnalytics purpose
    const unlisten = this.props.history.listen((location) => {
      const { profile } = this.state;
      ReactGA.pageview(`${location.pathname}${location.search}`);
      if (window
        && window.location
        && window.location.href.indexOf('localhost') === -1
        && !isSuperAdmin()) {
        window.Intercom('update');
        if (profile) {
          sendIdentify();
        }
        sendPageVisit(this.props.location.pathname);
      }
    });

    this.setState({
      unlisten,
    });
  }

  componentDidUpdate(prevProps) {
    const current = `${this.props.location.pathname}${this.props.location.search}`;
    const prev = `${prevProps.location.pathname}${prevProps.location.search}`;
    if (current !== prev) {
      onRouteChanged(current);
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    this.state.unlisten();
  }

  getTheConnectorsList = (profile) => {
    if (profile && hasRight(profile, 'admin.connectors.view')) {
      this.setState({ loadingConnectorsList: true }, () => {
        listConnectors()
          .then(({ data: connectors }) => {
            this.setState({ connectors, loadingConnectorsList: false });
          });
      });
    }
  };

  getTheProfile = () => {
    this.setState({ loading: true }, () => {
      getProfile()
        .then((profile) => {
          ReactGA.set({ userId: profile.userData.id, email: profile.userData.email });
          this.setState({ loading: false, profile });
          shouldDisplayNPS();
        })
        .catch((err) => {
          console.error('getProfile', err);
          if (err && err.response && err.response.status === 401) {
            localStorage.clear();
            deleteCookie('sessionId');
            const redir = (window.location.hash && window.location.hash.length > 0)
              ? window.location.hash.substring(1, window.location.hash.length) : null;
            // eslint-disable-next-line react/prop-types
            this.props.history.push(`${routePaths.v2.login}?next=${encodeURIComponent(redir)}`);
          } else {
            message.error('Error while fetching user data.');
          }
        });
    });
  };

  render() {
    const { loading, profile, loadingConnectorsList, connectors } = this.state;

    const availableConnectors = availableConnectorsList(loadingConnectorsList, profile);
    const availableConnectorsConnected = availableConnectors
      .filter((a) => !!(connectors || []).find((con) => con.type === a.name));

    if (MAINTENANCE_MODE || window.location.href.indexOf(routePaths.v2.maintenance) !== -1) {
      return <MaintenancePageContent />;
    }

    return (
      <div id="App">
        {(profile && profile.userData.company && !profile.userData.company.live && !isSuperAdmin())
          ? (
            <div className="Admin_pending_state">
              <Card>
                <Result
                  icon={(
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      <img
                        src="/img/setup.png"
                        alt="work in progress"
                        style={{
                          width: '300px',
                          maxWidth: '100%',
                        }}
                      />
                    </div>
                  )}
                  status="success"
                  title={(
                    <div>
                      <div>
                        Thank you!
                      </div>
                      <div>Your account is being setup by our team</div>
                    </div>
                  )}
                  subTitle="We will contact you once it's ready to use. Thank you for your trust."
                />
                {!loadingConnectorsList && availableConnectorsConnected.length >= 1 && (
                  <div className="Connectors_container">
                    <div className="Connectors_wrapper">
                      <h3 className="bold">{`Data source connected (${connectors.length})`}</h3>
                      <div className="Connectors_list">
                        {availableConnectorsConnected.map((co) => (
                          <co.template key={co.name} disabled />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <div className="center">
                  <a href={`/#${routePaths.addConnector}`} className="center">
                    <Button
                      icon={<PlusOutlined />}
                      type="primary"
                    >
                      Connect a data source
                    </Button>
                  </a>
                </div>
              </Card>

            </div>
          )
          : ((profile
            && profile.userData.company
            && showTrial(profile))
          && moment(profile.userData.company.trialEndDate).isBefore(moment(new Date()))
          && !isSuperAdmin()
            ? (
              <div className="Admin_pending_state">
                <Card>
                  <Result
                    status="warning"
                    title={(
                      <div>
                        <div>Your trial is over </div>
                      </div>
                    )}
                    subTitle={(
                      <div>
                        {profile.userData.company.trialPaymentUrl
                          ? (
                            <div>
                              You can start your subscription by clicking the button below.
                            </div>
                          )
                          : <div>Please contact us to define next steps.</div>}
                        {profile.userData.company.trialPaymentUrl ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={profile.userData.company.trialPaymentUrl}
                          >
                            <Button
                              style={{ marginTop: '12px' }}
                              type="primary"
                            >
                              Start my subscription now

                            </Button>
                          </a>
                        ) : null}
                      </div>
                    )}
                  />
                </Card>

              </div>
            )
            : (
              <LoggedUserApp appWrapperState={this.state} />
            ))}
        {loading && <FullScreenLoader />}
      </div>
    );
  }
}

AppWrapper.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(AppWrapper);
