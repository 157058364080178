import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { getDefaultPeriodValue } from '@palette/helpers/MasterPlanHelper';

import {
  CREATE_PLAN_FLOW_STEPS,
  FREEZE_TYPES,
  PAYOUT_CONDITION_STRATEGIES,
  QUOTA_TARGET_STRATEGIES,
  SCOPES,
} from '@palette/constants/masterPlans';
import { DEFAULT_FOLDER_ID } from '@palette/constants/folders';
import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { TIMEZONES_AND_TIME_OFFSETS_LIST } from '@palette/constants/timezone';
import { HIGHLIGHT_WIDGETS } from '@palette/constants/highlight';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as TrackingObjectDefinitionModel from '@palette/models/TrackingObjectDefinition';
import * as ResourceColumnModel from '@palette/models/ResourceColumn';
import * as PlanRuleModel from '@palette/models/PlanRule';
import * as YearPeriodModel from '@palette/models/YearPeriod';
import * as MasterPlanInjectionModel from '@palette/models/MasterPlanInjection';
import * as PlanUsersDefinitionModel from '@palette/models/PlanUsersDefinition';
import * as QuotaModel from '@palette/models/Quota';
import * as PayoutRuleModel from '@palette/models/PayoutRule';
import * as PlanEstimationDefinitionModel from '@palette/models/PlanEstimationDefinition';
import * as AutoFreeze from '@palette/models/AutoFreeze';

export const modelName = 'MasterPlan';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  scope: PropTypes.oneOf(Object.values(SCOPES)),
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  frequency: PropTypes.number,
  fiscalYearShift: PropTypes.number,
  live: PropTypes.bool,
  currency: PropTypes.string,
  beginPeriod: YearPeriodModel.propTypes,
  endPeriod: YearPeriodModel.propTypes,
  customBeginAt: PropTypes.string,
  lastComputedAt: PropTypes.string,
  lastBreakingChangeUpdate: PropTypes.string,
  lastPeriod: MasterPlanPeriodModel.propTypes,
  columns: PropTypes.arrayOf(ResourceColumnModel.propTypes),
  variables: PropTypes.arrayOf(ResourceColumnModel.propTypes),
  creationFlowStep: PropTypes.oneOf([null, ...Object.keys(CREATE_PLAN_FLOW_STEPS)]),
  freezeType: PropTypes.oneOf(Object.values(FREEZE_TYPES)),
  lastFrozenPeriod: YearPeriodModel.propTypes,
  allPeriodsFrozen: PropTypes.bool,
  autofreeze: AutoFreeze.propTypes,
  allowWrongPayoutsPercentageValue: PropTypes.bool,
  usersDefinition: PlanUsersDefinitionModel.propTypes,
  trackingObject: TrackingObjectDefinitionModel.propTypes,
  rules: PropTypes.arrayOf(PlanRuleModel.propTypes),
  rulesInRaw: PropTypes.bool,
  payoutRules: PropTypes.arrayOf(PayoutRuleModel.propTypes),
  payoutRulesInRaw: PropTypes.bool,
  payoutRulesDescription: PropTypes.string,
  payoutRulesConditionStrategy: PropTypes.oneOf(['', ...Object.values(PAYOUT_CONDITION_STRATEGIES)]),
  payoutRulesTemplate: PropTypes.string,
  quotaId: PropTypes.string,
  quota: QuotaModel.propTypes,
  quotaTargetStrategy: PropTypes.oneOf(Object.values(QUOTA_TARGET_STRATEGIES)),
  injections: PropTypes.arrayOf(MasterPlanInjectionModel.propTypes),
  injectionsInRaw: PropTypes.bool,
  folderId: PropTypes.string,
  parentPlan: PropTypes.string,
  parentName: PropTypes.string,
  timezone: PropTypes.oneOf(TIMEZONES_AND_TIME_OFFSETS_LIST),
  estimationDefinition: PlanEstimationDefinitionModel.propTypes,
  planComponents: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(HIGHLIGHT_WIDGETS))),
  periodComponents: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(HIGHLIGHT_WIDGETS))),
  archived: PropTypes.bool,
});

export const manageMasterPlanScope = (masterPlan) => (SCOPES[manageStringAttribute(masterPlan, 'scope')] || SCOPES.INDIVIDUAL);

export const manageMasterPlanPeriodType = (masterPlan) => (PERIOD_TYPES[manageStringAttribute(masterPlan, 'periodType')] || PERIOD_TYPES.MONTH);

export const manageMasterPlanFreezeType = (masterPlan) => (FREEZE_TYPES[manageStringAttribute(masterPlan, 'freezeType')] || FREEZE_TYPES.ALL);

export const manageMasterPlanQuotaTargetStrategy = (masterPlan) => (QUOTA_TARGET_STRATEGIES[manageStringAttribute(masterPlan, 'quotaTargetStrategy')] || QUOTA_TARGET_STRATEGIES.SUM);

export const transform = (masterPlan) => {
  if (!masterPlan) {
    return null;
  }

  const periodType = manageMasterPlanPeriodType(masterPlan);

  const frequency = manageNumberAttribute(masterPlan, 'frequency', 1);

  const fiscalYearShift = manageNumberAttribute(masterPlan, 'fiscalYearShift', 0);

  const beginPeriodRaw = manageFreeShapeObjectAttribute(masterPlan, 'beginPeriod', null) || getDefaultPeriodValue(PERIOD_TYPES.MONTH, 1);
  const beginPeriod = YearPeriodModel.transform(beginPeriodRaw);

  const endPeriodRaw = manageFreeShapeObjectAttribute(masterPlan, 'endPeriod', null);
  const endPeriod = YearPeriodModel.transform(endPeriodRaw);

  let quota = null;
  let quotaId = manageStringAttribute(masterPlan, 'quota', null);
  if (quotaId === null) {
    quota = QuotaModel.transform(manageFreeShapeObjectAttribute(masterPlan, 'quota', null));
    quotaId = quota?.id || null;
  }

  const injectionsArray = manageArrayAttribute(masterPlan, 'injections', null);
  const injectionsInRaw = injectionsArray !== null;

  const rulesArray = manageArrayAttribute(masterPlan, 'rules', null);
  const rulesInRaw = rulesArray !== null;

  const payoutRulesArray = manageArrayAttribute(masterPlan, 'payoutRules', null);
  const payoutRulesInRaw = payoutRulesArray !== null;

  return (
    {
      id: manageStringAttribute(masterPlan, '_id'),
      name: manageStringAttribute(masterPlan, 'name'),
      scope: manageMasterPlanScope(masterPlan),
      periodType,
      frequency,
      fiscalYearShift,
      live: manageBooleanAttribute(masterPlan, 'live'),
      currency: manageStringAttribute(masterPlan, 'currency', null),
      beginPeriod,
      endPeriod,
      customBeginAt: manageDateAttribute(masterPlan, 'customBeginAt'),
      lastComputedAt: manageDateAttribute(masterPlan, 'lastComputedAt'),
      lastBreakingChangeUpdate: manageDateAttribute(masterPlan, 'lastBreakingChangeUpdate'),
      lastPeriod: MasterPlanPeriodModel.transform(masterPlan.lastPeriod),
      columns: ResourceColumnModel.transformList(manageArrayAttribute(masterPlan, 'columns')),
      variables: ResourceColumnModel.transformList(manageArrayAttribute(masterPlan, 'variables')),
      creationFlowStep: manageStringAttribute(masterPlan, 'flowStr', null),
      freezeType: manageMasterPlanFreezeType(masterPlan),
      lastFrozenPeriod: YearPeriodModel.transform(manageFreeShapeObjectAttribute(masterPlan, 'lastFrozenPeriod', null)),
      allPeriodsFrozen: manageBooleanAttribute(masterPlan, 'allPeriodsFrozen'),
      autofreeze: AutoFreeze.transform(manageFreeShapeObjectAttribute(masterPlan, 'autofreeze', null)),
      allowWrongPayoutsPercentageValue: manageBooleanAttribute(masterPlan, 'allowWrongPayoutsPercentageValue'),
      usersDefinition: PlanUsersDefinitionModel.transform(masterPlan.groupByObject),
      trackingObject: TrackingObjectDefinitionModel.transform(masterPlan.trackingObject),
      rules: PlanRuleModel.transformList(manageArrayAttribute(masterPlan, 'rules')),
      rulesInRaw,
      payoutRules: PayoutRuleModel.transformList(manageArrayAttribute(masterPlan, 'payoutRules')),
      payoutRulesInRaw,
      payoutRulesDescription: manageStringAttribute(masterPlan, 'payoutRulesDescription'),
      payoutRulesConditionStrategy: manageStringAttribute(masterPlan, 'payoutRulesConditionStrategy'),
      payoutRulesTemplate: manageStringAttribute(masterPlan, 'payoutRulesTemplate'),
      quotaId,
      quota,
      quotaTargetStrategy: manageMasterPlanQuotaTargetStrategy(masterPlan),
      injections: MasterPlanInjectionModel.transformList(manageArrayAttribute(masterPlan, 'injections')),
      injectionsInRaw,
      folderId: manageStringAttribute(masterPlan, 'folder', DEFAULT_FOLDER_ID),
      parentPlan: manageStringAttribute(masterPlan, 'parentPlan', null),
      parentName: manageStringAttribute(masterPlan, 'parentName', null),
      timezone: manageStringAttribute(masterPlan, 'timezone', null),
      estimationDefinition: PlanEstimationDefinitionModel.transform(manageFreeShapeObjectAttribute(masterPlan, 'estimation', null)),
      planComponents: manageArrayAttribute(masterPlan, 'planComponents'),
      periodComponents: manageArrayAttribute(masterPlan, 'periodComponents'),
      archived: manageBooleanAttribute(masterPlan, 'archived'),
    }
  );
};

export const transformList = (masterPlans) => masterPlans.map((masterPlan) => transform(masterPlan));

export const merge = (masterPlan1, masterPlan2) => {
  if (!masterPlan2) return masterPlan1;
  if (!masterPlan1) return masterPlan2;

  const mergedLastPeriod = MasterPlanPeriodModel.merge(masterPlan1.lastPeriod, masterPlan2.lastPeriod);
  const mergedUsersDefinition = PlanUsersDefinitionModel.merge(masterPlan1.usersDefinition, masterPlan2.usersDefinition);
  const mergedTrackingObject = TrackingObjectDefinitionModel.merge(masterPlan1.trackingObject, masterPlan2.trackingObject);

  let mergedRules = masterPlan1.rules;
  if (masterPlan2.rulesInRaw) {
    mergedRules = masterPlan2.rules;
  }

  let mergedPayoutRules = masterPlan1.payoutRules;
  if (masterPlan2.payoutRulesInRaw) {
    mergedPayoutRules = masterPlan2.payoutRules;
  }

  let mergedPayoutRulesDescription = masterPlan1.payoutRulesDescription;
  if (masterPlan2.payoutRulesDescription) {
    mergedPayoutRulesDescription = masterPlan2.payoutRulesDescription;
  }

  let mergedInjections = masterPlan1.injections;
  if (masterPlan2.injectionsInRaw) {
    mergedInjections = masterPlan2.injections;
  }

  return {
    ...masterPlan2,
    lastPeriod: mergedLastPeriod,
    usersDefinition: mergedUsersDefinition,
    trackingObject: mergedTrackingObject,
    rules: mergedRules,
    payoutRules: mergedPayoutRules,
    payoutRulesDescription: mergedPayoutRulesDescription,
    injections: mergedInjections,
  };
};
