import { getColumnValue, getResourceColumns } from '@palette/helpers/ConnectorHelper';
import { yearPeriodToString } from '@palette/helpers/MasterPlanHelper';
import { applyGenericTimezone } from '@palette/helpers/MomentHelper';

export const getNameColumn = (resourceColumns) => {
  const nameColumns = resourceColumns.filter((column) => column.name.toLowerCase() === 'name');
  return nameColumns.length > 0 ? nameColumns[0] : null;
};

export const getDealName = (commission, connector) => {
  const resourceColumns = getResourceColumns(
    connector,
    commission.scopeParams?.plan?.trackingObject?.originalType,
    (column) => column.displayInPlans,
  );
  let nameColumn = getNameColumn(resourceColumns);
  if (nameColumn === null) {
    // if a "name" column is not found, we take the first one
    nameColumn = commission.scopeParams?.plan?.columns.length > 0
      ? commission.scopeParams?.plan?.columns[0]
      : resourceColumns[0];
  }

  return getColumnValue(commission.scopeParams?.resourceObject?.data || {}, nameColumn);
};

export const getCommissionAmount = (commission) => (commission.overwrittenAmount !== null ? commission.overwrittenAmount : commission.amount);

export const getCommissionValue = (commission) => (commission.overwrittenValue !== null ? commission.overwrittenValue : commission.value);

export const getScheduleFromPercentage = (commission) => {
  const commissionAmount = getCommissionAmount(commission);
  const payments = commission.payments || [];
  return payments.map(({ date, percentage }) => ({
    date,
    percentage,
    amount: commissionAmount * percentage,
  }));
};

export const getCommissionsByPeriodsByPlans = (commissions) => {
  if (!commissions) return null;

  const plans = {};

  Object.values(commissions).forEach((commission) => {
    const { amount, overwrittenAmount, commentsCount, currency, scopeParams } = commission;
    const { period, plan } = scopeParams;

    const periodString = yearPeriodToString(period);
    const finalAmount = getCommissionAmount({ amount, overwrittenAmount });

    plans[plan.id] = plans[plan.id] || {
      plan,
      periods: {},
      totalAmount: 0,
      totalComments: 0,
      currency: '',
    };

    plans[plan.id].periods[periodString] = (plans[plan.id].periods[periodString] || []).concat(commission);
    plans[plan.id].totalAmount += finalAmount;
    plans[plan.id].totalComments += commentsCount;
    plans[plan.id].currency = currency;
  });

  return plans;
};

export const getTotalPayments = (schedule) => {
  let scheduleAmount = 0;
  let schedulePercentage = 0;

  schedule.forEach((payment) => {
    scheduleAmount += payment.amount;
    schedulePercentage += payment.percentage;
  });

  schedulePercentage = parseInt(schedulePercentage * 100, 10);

  return [scheduleAmount, schedulePercentage];
};

export const buildCommissionListByFiltersPayload = ({
  searchedFrom,
  searchedTo,
  searchedResourceFromSearch,
  searchedStatus,
  searchedUser,
  searchedTeam,
  searchedResourceType,
  searchedPlan,
  sortBy,
  currency,
}) => ({
  from: searchedFrom,
  to: searchedTo,
  dealFilter: searchedResourceFromSearch,
  statusFilter: searchedStatus,
  searchedUser,
  searchedTeam,
  searchedResourceType,
  searchedPlan,
  sortBy,
  currency,
});

export const applyPlanTimezoneToCommissionDate = (commission) => (
  applyGenericTimezone(commission.date, commission.scopeParams?.plan?.timezone || 'Z')
);

export const computePaymentValueToDisplay = (payment, cumulativeRoundedValues, commissionAmount, shouldCheckDiffWithCommissionAmount) => {
  const paymentValue = payment.percentage * commissionAmount;
  if (!shouldCheckDiffWithCommissionAmount) return paymentValue;

  const diffWithCommissionAmount = cumulativeRoundedValues - commissionAmount;
  if (diffWithCommissionAmount !== 0) {
    return paymentValue - diffWithCommissionAmount;
  }

  return paymentValue;
};
