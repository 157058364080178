import _pick from 'lodash/pick';
import _invert from 'lodash/invert';

import { MONTH_FREQUENCIES } from '@palette/constants/frequencies';

import { PROGRESS_TYPES } from '@palette/components/designSystem/Progress/Progress';

export const SCOPES = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
  MANAGER: 'MANAGER',
};

export const DUPLICATE_TYPES = {
  DUPLICATE: 'DUPLICATE',
  LINK: 'LINK',
};

export const FREEZE_TYPES = {
  ALL: 'ALL',
  RULES_ONLY: 'RULES_ONLY',
};

export const QUOTA_TARGET_STRATEGIES = {
  SUM: 'SUM',
  MATCH: 'MATCH',
};

export const PLAN_INJECTION_TYPES = {
  MATCH_USER: 'MATCH_USER',
  ALL_USERS: 'ALL_USERS',
};

export const PLAN_INJECTION_PERIOD_MATCH_STRATEGIES = {
  AUTO: 'AUTO',
  RELATIVE: 'RELATIVE',
  ABSOLUTE: 'ABSOLUTE',
  FORMULA: 'FORMULA',
};

export const AVAILABLE_PLAN_INJECTION_PERIOD_MATCH_STRATEGIES = _pick(PLAN_INJECTION_PERIOD_MATCH_STRATEGIES, ['AUTO', 'RELATIVE', 'ABSOLUTE']);

export const DEFAULT_PERIOD_VALUE = { year: (new Date()).getFullYear(), period: 1 };

export const PERIODS_FILTER_TYPE = {
  YEAR_TO_DATE: 'YEAR_TO_DATE',
  PAST_6_MONTHS: 'PAST_6_MONTHS',
  QUARTER_TO_DATE: 'QUARTER_TO_DATE',
  ALL_TIMES: 'ALL_TIMES',
  CUSTOM: 'CUSTOM',
};

export const PERIODS_FILTER_TYPE_QS_KEY = 'viewBy';
export const PERIODS_FILTER_CUSTOM_TYPE_PERIODS_QS_KEY = 'periods';
export const PERIODS_FILTER_TYPE_IDS = {
  YEAR_TO_DATE: 'year-to-date',
  PAST_6_MONTHS: 'past-6-months',
  QUARTER_TO_DATE: 'quarter-to-date',
  ALL_TIMES: 'all-times',
  CUSTOM: 'custom',
};
export const PERIODS_FILTER_TYPE_IDS_INVERTED = _invert(PERIODS_FILTER_TYPE_IDS);

export const PERIOD_PROGRESSBAR_TYPES = {
  SUCCESS: PROGRESS_TYPES.SUCCESS,
  WARNING: PROGRESS_TYPES.WARNING,
  DANGER: PROGRESS_TYPES.DANGER,
};

export const CREATE_PLAN_FLOW_STEPS = {
  STEP_GENERAL: {
    label: 'createPlanFlowSteps.step_general.label',
    subTitle: 'createPlanFlowSteps.step_general.subTitle',
    isDiscard: () => false,
    value: 'STEP_GENERAL',
  },
  SELECT_USERS: {
    label: 'createPlanFlowSteps.select_users.label',
    subTitle: 'createPlanFlowSteps.select_users.subTitle',
    isDiscard: () => false,
    value: 'SELECT_USERS',
  },
  DATA_SOURCE: {
    label: 'createPlanFlowSteps.data_source.label',
    subTitle: 'createPlanFlowSteps.data_source.subTitle',
    isDiscard: () => false,
    value: 'DATA_SOURCE',
  },
  DATA_FILTERING: {
    label: 'createPlanFlowSteps.data_filtering.label',
    subTitle: 'createPlanFlowSteps.data_filtering.subTitle',
    isDiscard: () => false,
    value: 'DATA_FILTERING',
  },
  QUOTA_SETUP: {
    label: 'createPlanFlowSteps.quota_setup.label',
    subTitle: 'createPlanFlowSteps.quota_setup.subTitle',
    isDiscard: () => false,
    value: 'QUOTA_SETUP',
  },
  QUOTA_ATTAINMENT: {
    label: 'createPlanFlowSteps.quota_attainment.label',
    subTitle: 'createPlanFlowSteps.quota_attainment.subTitle',
    isDiscard: () => false,
    value: 'QUOTA_ATTAINMENT',
  },
  COMMISSION_RULES: {
    label: 'createPlanFlowSteps.commission_rules.label',
    subTitle: 'createPlanFlowSteps.commission_rules.subTitle',
    isDiscard: () => false,
    value: 'COMMISSION_RULES',
  },
  PAYOUT_SCHEDULE: {
    label: 'createPlanFlowSteps.payout_schedule.label',
    subTitle: 'createPlanFlowSteps.payout_schedule.subTitle',
    isDiscard: (plan) => plan?.rules?.length === 0,
    value: 'PAYOUT_SCHEDULE',
  },
  SUMMARY: {
    label: 'createPlanFlowSteps.summary.label',
    subTitle: 'createPlanFlowSteps.summary.subTitle',
    isDiscard: () => false,
    value: 'SUMMARY',
  },
};

export const CREATE_PLAN_FLOW_FIRST_STEP = CREATE_PLAN_FLOW_STEPS.STEP_GENERAL;

export const CREATE_PLAN_FLOW_ORDERED_STEPS = [
  CREATE_PLAN_FLOW_STEPS.STEP_GENERAL,
  CREATE_PLAN_FLOW_STEPS.SELECT_USERS,
  CREATE_PLAN_FLOW_STEPS.DATA_SOURCE,
  CREATE_PLAN_FLOW_STEPS.DATA_FILTERING,
  CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP,
  CREATE_PLAN_FLOW_STEPS.QUOTA_ATTAINMENT,
  CREATE_PLAN_FLOW_STEPS.COMMISSION_RULES,
  CREATE_PLAN_FLOW_STEPS.PAYOUT_SCHEDULE,
  CREATE_PLAN_FLOW_STEPS.SUMMARY,
];

export const DRAFT_PLAN_ID_QS_KEY = 'draftPlanId';

export const DRAFT_PLAN_STEP_QS_KEY = 'creationPlanStep';

export const SEARCHED_DEAL_QS_KEY = 'searchedDeal';

export const SEARCHED_USER_QS_KEY = 'searchedUser';

export const SEARCHED_PAYEE_QS_KEY = 'searchedPayee';

export const COMPARE_PLAN_FIRST_QS_KEY = 'firstPlanId';
export const COMPARE_PLAN_PERIOD_FIRST_QS_KEY = 'firstPlanPeriod';
export const COMPARE_PLAN_SECOND_QS_KEY = 'secondPlanId';
export const COMPARE_PLAN_PERIOD_SECOND_QS_KEY = 'secondPlanPeriod';

export const FORMATTERS_VALUES = {
  String: 'String',
  Date: 'Date',
  DateTime: 'DateTime',
  Custom: 'Custom',
};

export const COLUMN_FORMATTERS = {
  DEFAULT: {
    label: 'plan.columnFormatter.default',
    value: FORMATTERS_VALUES.String,
  },
  DATE: {
    label: 'plan.columnFormatter.date',
    value: FORMATTERS_VALUES.Date,
  },
  DATETIME: {
    label: 'plan.columnFormatter.datetime',
    value: FORMATTERS_VALUES.DateTime,
  },
  CUSTOM: {
    label: 'plan.columnFormatter.custom',
    value: FORMATTERS_VALUES.Custom,
  },
};

export const AVAILABLE_MONTH_FREQUENCIES_OPTIONS = _pick(MONTH_FREQUENCIES, [1, 3, 6, 12]);

export const SETTINGS_USERS_FILTER = {
  ACTIVE: 'active',
  ALL: 'all',
};

export const RULE_TYPES = {
  PER_OBJECT: 'PER_OBJECT',
  PER_TARGET: 'PER_TARGET',
  CODE_ONLY: 'CODE_ONLY',
};

export const VARIABLE_TYPES = {
  SIMPLE: 'SIMPLE',
  CONDITIONAL: 'CONDITIONAL',
};

export const PAYOUT_CONDITION_STRATEGIES = {
  FIRST_MATCH: 'FIRST_MATCH',
  ALL_MATCHES: 'ALL_MATCHES',
};

export const PAYOUT_RULES_TEMPLATE = {
  payMonthAfterEndOfPlan_001: 'payMonthAfterEndOfPlan_001',
  payEndOfPlan_001: 'payEndOfPlan_001',
  payClosedDate_001: 'payClosedDate_001',
  custom: 'custom',
};

export const PAYOUT_INTERVAL_TYPES = {
  day: 'day',
  month: 'month',
  year: 'year',
};

export const USERS_TAB_SORT_OPTIONS = {
  ALPHABETICAL: 'ALPHABETICAL',
  PROGRESS: 'PROGRESS',
  COMMISSION: 'COMMISSION',
  TARGET: 'TARGET',
  VALUE: 'VALUE',
};
