import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Tag from '@palette/components/designSystem/Tag/Tag';
import DollarFilled from '@palette/components/utils/Icons/DollarFilled';

import { COMMISSION_DRAWER_COMMISION_ID_QS_KEY } from '@palette/constants/commissions';
import { COMMISSION_DRAWER_QS_KEY, COMMISSION_DRAWER_TABS_IDS } from '@palette/constants/tabs';
import { TAG_THEMES } from '@palette/constants/tag';

import * as PaymentModel from '@palette/models/Payment';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './PeriodDealCommissionInfoPayments.less';

const classNames = bindClassNames.bind(styles);

const PeriodDealCommissionInfoPayments = ({ className, mode, onClick, payments, commissionId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openCommissionDrawer = useCallback((tabKey) => {
    if (onClick) onClick();
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [COMMISSION_DRAWER_COMMISION_ID_QS_KEY]: commissionId, [COMMISSION_DRAWER_QS_KEY]: tabKey } }));
  }, []);

  const tagNode = useMemo(() => (
    <Tag
      theme={TAG_THEMES.LAVENDER}
      label={payments.length > 0 ? payments.length.toString() : ''}
      icon={DollarFilled}
    />
  ), [payments]);

  const linkNode = useMemo(() => (
    <div className={styles.link}>
      {t('periodDealCommissionInfoPayments.payments', { count: payments.length })}
    </div>
  ), [payments]);

  const contentNode = useMemo(() => {
    if (mode === 'link') return linkNode;

    return tagNode;
  }, [mode, tagNode, linkNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      onClick={() => openCommissionDrawer(COMMISSION_DRAWER_TABS_IDS.PAYMENTS)}
    >
      {contentNode}
    </div>
  );
};

PeriodDealCommissionInfoPayments.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(['tag', 'link']),
  payments: PropTypes.arrayOf(PaymentModel.propTypes).isRequired,
  commissionId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

PeriodDealCommissionInfoPayments.defaultProps = {
  className: '',
  mode: 'tag',
  onClick: undefined,
};

export default PeriodDealCommissionInfoPayments;
