import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import Select from '@palette/components/designSystem/Select/Select';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import TeamSelectorMultiple from '@palette/components/team/TeamSelectorMultiple/TeamSelectorMultiple';

import { useCompanyRoles } from '@palette/hooks/CompanyHooks';
import { useDashboardPresetsList } from '@palette/hooks/DashboardPresetHooks';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './InviteUserModal.less';

const InviteUserModal = ({ visible, onClose, user }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();

  const inviteUserIsPending = useSelector(UsersSelectors.inviteUserIsPending);

  const [form] = AntDForm.useForm();

  const companyRoles = useCompanyRoles();
  const dashboardPresetsList = useDashboardPresetsList();

  const rolesOptions = useMemo(() => (
    companyRoles.map((role) => ({
      label: role.name,
      value: role.id,
    }))
  ), [companyRoles]);

  const dashboardPresetsOptions = useMemo(() => (
    dashboardPresetsList.map((dashboardPreset) => ({
      label: dashboardPreset.name,
      value: dashboardPreset.id,
    }))
  ), [dashboardPresetsList]);

  const initialValues = useMemo(() => {
    const defaultRole = companyRoles.find((role) => (role.isDefault));
    const defaultRoleOption = rolesOptions.find((roleOption) => (roleOption.value === defaultRole.id));
    const defaultDashboardPresets = dashboardPresetsList.filter((dashboardPreset) => (dashboardPreset.isDefault));
    const defaultDashboardPreset = defaultDashboardPresets.length > 0 ? defaultDashboardPresets[0] : undefined;

    return {
      email: user.email,
      role: defaultRoleOption?.value,
      hireDate: null,
      teamIds: [],
      dashboardPreset: defaultDashboardPreset?.id,
    };
  }, [user, companyRoles, rolesOptions, dashboardPresetsList]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const onSuccessCB = useCallback(() => {
    cleanAndClose();
  }, [cleanAndClose]);

  const handleTeamsChange = (teamIds) => {
    form.setFieldsValue({ teamIds });
  };

  const handleFinish = useCallback((values) => {
    const { email, role, hireDate, teamIds, dashboardPreset } = values;
    const hireDateValue = hireDate !== null ? moment(hireDate).utc().startOf('day').format() : null;

    dispatch(UsersActions.inviteUser({
      userId: user.id,
      email,
      roleId: role,
      hireDate: hireDateValue,
      teamIds,
      dashboardPresetId: dashboardPreset,
      onSuccessCB,
    }));
  }, [user, onSuccessCB]);

  const handleInviteUser = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('inviteUserModal.title', { userDisplayName: user.displayName })}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleInviteUser}
      okText={t('inviteUserModal.confirm')}
      loading={inviteUserIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="email"
          label={t('inviteUserModal.form.email.label')}
          required
          rules={[
            { required: true, message: t('inviteUserModal.form.email.rules.required') },
            { type: 'email', warningOnly: false, message: t('inviteUserModal.form.email.rules.email') },
          ]}
        >
          <Input size="big" disabled={inviteUserIsPending} />
        </FormItem>
        <FormItem
          name="role"
          label={t('inviteUserModal.form.role.label')}
          required
        >
          <Select size="big" options={rolesOptions} disabled={inviteUserIsPending} />
        </FormItem>
        <FormItem
          name="hireDate"
          label={t('inviteUserModal.form.hireDate.label')}
        >
          <DatePicker
            className={styles.hireDatePicker}
            size="big"
            picker="date"
            allowClear
            disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
            disabled={inviteUserIsPending}
          />
        </FormItem>
        <FormItem
          name="teamIds"
          label={t('inviteUserModal.form.teams.label')}
        >
          <TeamSelectorMultiple
            className={styles.teamSelector}
            onChange={handleTeamsChange}
            size="big"
          />
        </FormItem>
        <FormItem
          name="dashboardPreset"
          label={t('inviteUserModal.form.dashboardDesign.label')}
          required
        >
          <Select size="big" options={dashboardPresetsOptions} disabled={inviteUserIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

InviteUserModal.propTypes = {
  user: MetaUserModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

InviteUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default InviteUserModal;
