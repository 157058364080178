import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import EditVariableValueModal from '@palette/components/usersVariables/EditVariableValueModal/EditVariableValueModal';

import * as UserVariableModel from '@palette/models/UserVariable';
import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './EditVariableValue.less';

const classNames = bindClassNames.bind(styles);

const EditVariableValue = ({ className, user, userVariable, currentValue }) => {
  const [editVariableValueModalIsVisible, showEditVariableValueModal] = useState(false);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.editValueBtn}
        icon={(<PenFilled className={styles.editValueBtnIcon} width={16} height={16} />)}
        onClick={() => showEditVariableValueModal(true)}
      />
      {
        editVariableValueModalIsVisible && (
          <EditVariableValueModal
            visible
            onClose={() => showEditVariableValueModal(false)}
            users={[user]}
            userVariable={userVariable}
            currentValue={currentValue}
          />
        )
      }
    </div>
  );
};

EditVariableValue.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
  userVariable: UserVariableModel.propTypes.isRequired,
  currentValue: UserVariableModel.propTypesShape.defaultValue.isRequired,
};

EditVariableValue.defaultProps = {
  className: '',
};

export default EditVariableValue;
