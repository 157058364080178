import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import ArrowRightCircle from '@palette/components/utils/Icons/ArrowRightCircle';
import Tag from '@palette/components/designSystem/Tag/Tag';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import PlanV3EditAchievements from '@palette/components/planV3/PlanV3EditAchievements/PlanV3EditAchievements';
import PlanV3EditCommissions from '@palette/components/planV3/PlanV3EditCommissions/PlanV3EditCommissions';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import { PLAN_STATUS_TYPE, EDIT_PAGE_TYPE, CELL_TYPE } from '@palette/constants/planV3';
import { TAG_THEMES } from '@palette/constants/tag';

import routePaths from '@palette/config/routePaths';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import { actions as PlanV3Actions } from '@palette/state/PlanV3';

import styles from './PlanV3EditPageContentMain.less';

const classNames = bindClassNames.bind(styles);

const PlanV3EditPageContentMain = ({ className, details, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLastStep = useMemo(() => type === EDIT_PAGE_TYPE.COMMISSION, [type]);

  const { name, description, planId, status, cells } = details;

  const cell = useMemo(() => {
    const codeCellType = isLastStep ? CELL_TYPE.COMMISSIONS : CELL_TYPE.ACHIEVEMENTS;
    return cells.find((planCell) => planCell.type === codeCellType) ?? null;
  }, [isLastStep, cells]);

  const [codeCellContent, setCodeCellContent] = useState(cell?.code ?? '');

  useEffect(() => {
    setCodeCellContent(cell?.code ?? '');
  }, [cell]);

  const handleBackPreviousStep = useCallback(() => {
    let path = routePaths.v2.planV3RequirementsById;

    if (isLastStep) {
      path = routePaths.v2.planV3EditAchievementById;
    }

    redirectTo({ path, params: { planId } });
  }, [planId, isLastStep]);

  const savePlanCodeCells = useCallback(() => {
    if (codeCellContent === cell?.code && !isLastStep) {
      return;
    }

    const updatedCodeCells = cells.map((planCodeCell) => {
      if (planCodeCell.id === cell?.id) {
        return {
          id: planCodeCell.id,
          code: codeCellContent,
        };
      }

      return {
        id: planCodeCell.id,
        code: planCodeCell.code,
      };
    });

    dispatch(PlanV3Actions.savePlan({ planId, codeCells: updatedCodeCells, refreshPlanDetails: isLastStep }));
  }, [codeCellContent, cell, cells, planId, isLastStep]);

  const handleNextStepClick = useCallback(() => {
    savePlanCodeCells();

    if (isLastStep) return;

    redirectTo({ path: routePaths.v2.planV3EditCommissionById, params: { planId } });
  }, [savePlanCodeCells, planId, isLastStep]);

  const renderHeaderTitleNode = useMemo(() => (
    <div className={styles.subtitle}>
      {isLastStep ? t('editPlanPage.subtitle.top.step3') : t('editPlanPage.subtitle.top.step2')}
    </div>
  ), [isLastStep]);

  const renderContentNode = useMemo(() => {
    if (isLastStep) return <PlanV3EditCommissions details={details} onCodeCellChange={setCodeCellContent} />;
    return <PlanV3EditAchievements details={details} onCodeCellChange={setCodeCellContent} />;
  }, [isLastStep, details, setCodeCellContent]);

  const renderFooterActionsNode = useMemo(() => (
    <div className={styles.footer}>
      <Button type="secondary" onClick={handleBackPreviousStep}>
        {t('editPlanPage.footer.back.previous.step')}
      </Button>
      <Button
        type={isLastStep ? 'primary' : 'primaryBlue'}
        icon={isLastStep ? <CheckFilled height={16} width={16} /> : <ArrowRightCircle height={16} width={16} />}
        onClick={handleNextStepClick}
      >
        {isLastStep ? t('editPlanPage.footer.save.step') : t('editPlanPage.footer.next.step')}
      </Button>
    </div>
  ), [isLastStep, handleBackPreviousStep, handleNextStepClick]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        {renderHeaderTitleNode}
        <div className={styles.row}>
          <div className={styles.nameInfos}>
            {name && <div className={styles.title}>{name}</div>}
            {description && <div className={styles.description}>{description}</div>}
          </div>
          <div className={styles.saveInfos}>
            {status === PLAN_STATUS_TYPE.DRAFT && (
              <Tag className={styles.tag} theme={TAG_THEMES.WARNING} label={t(`plansV3.status.${status}`)} />
            )}
          </div>
        </div>
      </div>
      {renderContentNode}
      {renderFooterActionsNode}
    </div>
  );
};

PlanV3EditPageContentMain.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
  type: PropTypes.oneOf(Object.keys(EDIT_PAGE_TYPE)),
};

PlanV3EditPageContentMain.defaultProps = {
  className: '',
  type: EDIT_PAGE_TYPE.ACHIEVEMENT,
};

export default PlanV3EditPageContentMain;
