import bindClassNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AddNotebookCell from '@palette/components/notebooks/AddNotebookCell/AddNotebookCell';
import EditableNotebookDescription from '@palette/components/notebooks/EditableNotebookDescription/EditableNotebookDescription';
import EditableNotebookName from '@palette/components/notebooks/EditableNotebookName/EditableNotebookName';
import NotebookCell from '@palette/components/notebooks/NotebookCell/NotebookCell';
import Button from '@palette/components/designSystem/Button/Button';
import BookFilled from '@palette/components/utils/Icons/BookFilled';

import { NOTEBOOK_DOCUMENTATION_URL, NOTEBOOK_STATUSES } from '@palette/constants/notebooks';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './NotebookDetails.less';

const classNames = bindClassNames.bind(styles);

const NotebookDetails = ({ className, notebook }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  const goToDocumentation = useCallback(() => {
    window.open(NOTEBOOK_DOCUMENTATION_URL, '_blank');
  }, []);

  const handleUpdateNotebookName = useCallback((newNotebookName) => {
    dispatch(NotebooksActions.editNotebook({ notebookId: notebook.id, name: newNotebookName, description: notebook.description }));
  }, [notebook]);

  const handleUpdateNotebookDescription = useCallback((newNotebookDescription) => {
    dispatch(NotebooksActions.editNotebook({ notebookId: notebook.id, name: notebook.name, description: newNotebookDescription }));
  }, [notebook]);

  const handleAddCell = useCallback((cellTypeId, index) => {
    dispatch(NotebooksActions.addCell({ notebook, cellTypeId, position: index }));
  }, [notebook]);

  const headerNode = useMemo(() => (
    <div className={styles.header}>
      <div className={styles.titleActionsWrapper}>
        <EditableNotebookName notebook={notebook} updateName={handleUpdateNotebookName} />
        <Button className={styles.button} type="link" icon={<BookFilled />} onClick={goToDocumentation}>
          {t('notebookDetails.documentation')}
        </Button>
      </div>
      <EditableNotebookDescription
        className={styles.descriptionWrapper}
        notebook={notebook}
        updateDescription={handleUpdateNotebookDescription}
      />
    </div>
  ), [
    notebook,
    handleUpdateNotebookName,
    handleUpdateNotebookDescription,
  ]);

  const cellsNode = useMemo(() => {
    const cellsItemsNodes = notebook.cells.map((cell) => (
      <React.Fragment key={`cellWrapper_${cell.id}`}>
        <NotebookCell
          key={`cell_${cell.id}`}
          className={styles.cell}
          notebook={notebook}
          cell={cell}
          disabled={editNotebookIsPending}
        />
      </React.Fragment>
    ));

    const lastPosition = notebook.cells.length === 0 ? -1 : Math.max(
      ...notebook.cells.map((cell) => cell.position),
    );

    return (
      <div className={styles.cellsWrapper}>
        {cellsItemsNodes}
        <AddNotebookCell
          key="addCell_0"
          className={styles.addCell}
          onAddCell={(cellTypeId) => handleAddCell(cellTypeId, lastPosition + 1)}
          disabled={notebook.status === NOTEBOOK_STATUSES.ARCHIVED}
        />
      </div>
    );
  }, [
    handleAddCell,
    notebook,
    editNotebookIsPending,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {headerNode}
      {cellsNode}
    </div>
  );
};

NotebookDetails.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
};

NotebookDetails.defaultProps = {
  className: '',
};

export default NotebookDetails;
