import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './MyStatementMarkAsPaidLabel.less';

const classNames = bindClassNames.bind(styles);

const MyStatementMarkAsPaidLabel = ({ className, userStatement, currency }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const { isPaid, paidBy } = userStatement;

  if (!isPaid || !paidBy) return null;

  const paidByDate = moment(paidBy.date).format('M/DD/YYYY');
  const paidByAmount = formatPrice(paidBy.amount, userStatement.currency || currency);
  const paidByUserName = paidBy.user.displayName;

  const { icon, i18n, theme } = STATEMENT_PERIOD_USER_STATUSES.PAID;

  return (
    <Tooltip
      title={paidByDate}
      content={(
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('statement.paidBy.tooltip.label', {
              userName: paidByUserName,
              amount: paidByAmount,
            }),
          }}
        />
      )}
    >
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <Tag
          theme={theme}
          label={t(i18n)}
          icon={icon}
        />
      </div>
    </Tooltip>
  );
};

MyStatementMarkAsPaidLabel.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
  currency: PropTypes.string,
};

MyStatementMarkAsPaidLabel.defaultProps = {
  className: '',
  userStatement: null,
  currency: null,
};

export default MyStatementMarkAsPaidLabel;
