import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageArrayAttribute,
} from '@palette/helpers/ModelHelper';

import * as TeamUserModel from '@palette/models/TeamUser';

export const modelName = 'Team';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  members: PropTypes.arrayOf(TeamUserModel.propTypes),
  parentId: PropTypes.string,
});

export const withSubTeamsPropTypes = PropTypes.shape({
  team: propTypes,
  subTeams: PropTypes.arrayOf(propTypes),
});

export const transform = (team) => {
  if (!team) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(team, '_id'),
      name: manageStringAttribute(team, 'name'),
      members: TeamUserModel.transformList(manageArrayAttribute(team, 'members')),
      parentId: manageStringAttribute(team, 'parentTeam', null),
    }
  );
};

export const transformList = (teams) => teams.map((team) => transform(team));
