import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';

import styles from './WeCanHelp.less';

const classNames = bindClassNames.bind(styles);

const WeCanHelp = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <p className={styles.text}>
        {t('masterPlanSettingsWeCanHelp.configurationText1')}
        <Button
          className={styles.buttonLink}
          type="linkSecondary"
          onClick={() => {
            window.Intercom('show');
          }}
        >
          {t('masterPlanSettingsWeCanHelp.configurationText2')}
        </Button>
        {t('masterPlanSettingsWeCanHelp.configurationText3')}
      </p>
    </div>
  );
};

WeCanHelp.propTypes = {
  className: PropTypes.string,
};

WeCanHelp.defaultProps = {
  className: '',
};

export default WeCanHelp;
