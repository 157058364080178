import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import Alert from '@palette/components/designSystem/Alert/Alert';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import Select from '@palette/components/designSystem/Select/Select';

import { getMoment } from '@palette/helpers/MomentHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { HALF_MONTH_PIVOTS, STATEMENT_STRATEGY_TYPES, WEEK_ROLL_TYPES } from '@palette/constants/statements';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import * as StatementStrategyModel from '@palette/models/StatementStrategy';
import * as StatementStrategyHistoryModel from '@palette/models/StatementStrategyHistory';

import styles from './EditStatementStrategyModal.less';

const EditStatementStrategyModal = ({ visible, onClose, statementStrategy, statementStrategies }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();

  const typePropertyTypes = Object.keys(STATEMENT_STRATEGY_TYPES).map((type) => ({
    label: t(`statementStrategy.propertyTypes.${type}`),
    value: type,
  }));

  const rollPropertyTypes = Object.keys(WEEK_ROLL_TYPES).map((type) => ({
    label: t(`statementStrategy.weekBegins.${type}`),
    value: type,
  }));

  const breakPropertyTypes = Object.keys(HALF_MONTH_PIVOTS).map((type) => ({
    label: t('statementStrategy.monthBreaks.types', {
      break: t(`statementStrategy.monthBreaks.${type}`),
    }),
    value: type,
  }));

  const updateStatementStrategyIsPending = useSelector(ProfileSelectors.updateStatementStrategyIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    strategyType: statementStrategy.type,
    rollType: statementStrategy.weekRollType,
    breakType: statementStrategy.halfMonthPivot,
  }), []);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const handleClose = () => onClose();

  const handleFinish = (values) => {
    const { strategyType, rollType, breakType, startStrategyAt } = values;

    dispatch(ProfileActions.updateStatementStrategy({
      type: strategyType,
      weekRollType: rollType,
      halfMonthPivot: breakType,
      dateStrategy: startStrategyAt,
      onSuccessCB: handleClose,
    }));
  };

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setFormValues({
      ...formValues,
      ...allValues,
    });
  }, [formValues]);

  const formIsPristine = useMemo(() => {
    if (_isEqual(initialValues, formValues)) return true;

    if (initialValues.strategyType === formValues.strategyType) {
      if (formValues.strategyType === STATEMENT_STRATEGY_TYPES.MONTH) return true;

      if (
        formValues.strategyType === STATEMENT_STRATEGY_TYPES.HALF_MONTH
        && initialValues.breakType === formValues.breakType
      ) return true;

      if (
        (
          formValues.strategyType === STATEMENT_STRATEGY_TYPES.WEEK
          || formValues.strategyType === STATEMENT_STRATEGY_TYPES.BI_WEEK
        )
        && initialValues.rollType === formValues.rollType
      ) return true;
    }

    return false;
  }, [initialValues, formValues]);

  const periodNode = useMemo(() => {
    if (formValues.strategyType === STATEMENT_STRATEGY_TYPES.MONTH) return null;

    if (formValues.strategyType === STATEMENT_STRATEGY_TYPES.HALF_MONTH) {
      return (
        <FormItem
          name="breakType"
          label={t('statementStrategy.modal.selectBreak')}
          required
          rules={[
            { required: true, message: t('statementStrategy.modal.selectBreak.error.message') },
          ]}
        >
          <Select
            placeholder={t('statementStrategy.modal.selectBreak.placeholder')}
            options={breakPropertyTypes}
          />
        </FormItem>
      );
    }

    return (
      <FormItem
        name="rollType"
        label={t('statementStrategy.modal.selectRoll')}
        required
        rules={[
          { required: true, message: t('statementStrategy.modal.strategyRole.error.message') },
        ]}
      >
        <Select
          placeholder={t('statementStrategy.modal.selectRoll.placeholder')}
          options={rollPropertyTypes}
        />
      </FormItem>
    );
  }, [formValues]);

  const datePickerNode = useMemo(() => {
    if (formIsPristine) return null;

    const now = moment().format();
    const todayExists = statementStrategies.find((strategy) => strategy.date.substring(0, 10) === now.substring(0, 10));

    const disabledDates = (date) => {
      if (todayExists) {
        return !date || date.isSameOrBefore(moment(), 'day');
      }

      return !date || date.isBefore(moment(), 'day');
    };

    return (
      <FormItem
        name="startStrategyAt"
        label={t('statementStrategy.modal.startStrategyAt')}
        required
        rules={[
          { required: true, message: t('statementStrategy.modal.startStrategyAt.error.message'), type: 'date' },
        ]}
      >
        <DatePicker
          className={styles.dateField}
          picker="date"
          allowClear={false}
          disabledDate={(d) => disabledDates(d)}
          showNow={false}
        />
      </FormItem>
    );
  }, [formIsPristine, statementStrategies]);

  const handleUpdateStrategy = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('statementStrategy.modal.title')}
        </div>
      )}
      visible={visible}
      onCancel={handleClose}
      onOk={handleUpdateStrategy}
      okText={t('statementStrategy.modal.save.label')}
      okIcon={<CheckFilled />}
      loading={updateStatementStrategyIsPending}
      disableOk={formIsPristine}
    >
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.ERROR}
        message={t('statementStrategy.modal.disclaimer.content')}
      />
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
        onValuesChange={handleFormValuesChange}
      >
        <FormItem
          name="strategyType"
          label={t('statementStrategy.modal.selectStrategy')}
          required
          rules={[
            { required: true, message: t('statementStrategy.modal.strategyType.error.message') },
          ]}
        >
          <Select
            placeholder={t('statementStrategy.modal.selectStrategy.placeholder')}
            options={typePropertyTypes}
          />
        </FormItem>
        {periodNode}
        {datePickerNode}
      </Form>
    </Modal>
  );
};

EditStatementStrategyModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  statementStrategy: StatementStrategyModel.propTypes.isRequired,
  statementStrategies: PropTypes.arrayOf(StatementStrategyHistoryModel.propTypes),
};

EditStatementStrategyModal.defaultProps = {
  visible: false,
  onClose: () => {},
  statementStrategies: [],
};

export default EditStatementStrategyModal;
