import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import TextArea from '@palette/components/designSystem/TextArea/TextArea';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './NewNotebookModal.less';

const NewNotebookModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createNotebookIsPending = useSelector(NotebooksSelectors.createNotebookIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    name: undefined,
    description: undefined,
  }), []);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const onSuccessCB = useCallback((newNotebookId) => {
    cleanAndClose();
    redirectTo({ path: routePaths.v2.notebookDetails, params: { notebookId: newNotebookId } });
  }, [cleanAndClose]);

  const handleFinish = useCallback(({ name, description }) => {
    dispatch(NotebooksActions.createNotebook({ name, description, onSuccessCB }));
  }, [onSuccessCB]);

  const handleNewNotebook = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('newNotebookModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleNewNotebook}
      okText={t('newNotebookModal.create')}
      loading={createNotebookIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="name"
          label={t('newNotebookModal.form.name.label')}
          required
          rules={[
            { required: true, message: t('newNotebookModal.form.name.rules.required') },
          ]}
        >
          <Input size="big" disabled={createNotebookIsPending} />
        </FormItem>
        <FormItem
          name="description"
          label={t('newNotebookModal.form.description.label')}
        >
          <TextArea autoSize={{ minRows: 2 }} disabled={createNotebookIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

NewNotebookModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

NewNotebookModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default NewNotebookModal;
