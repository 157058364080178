import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage400 = (problem) => {
  const { detail = undefined } = problem.additionalData;
  const context = detail;

  const managedErrors = {
    'notebook.required.information': GLOBAL_NOTIF_REASONS.NOTEBOOK_REQUIRED_INFORMATION_ERROR.code,
    'notebook.codeCell.required.information': GLOBAL_NOTIF_REASONS.NOTEBOOK_CELL_REQUIRED_INFORMATION_ERROR.code,
    'notebook.conflicting.outputName': GLOBAL_NOTIF_REASONS.NOTEBOOK_CELL_OUTPUT_NAME_CONFLICT.code,
    'notebook.already.existing.name': GLOBAL_NOTIF_REASONS.NOTEBOOK_ALREADY_EXISTING_NAME.code,
    'notebook.forbidden.codeCell.export': GLOBAL_NOTIF_REASONS.NOTEBOOK_FORBIDDEN_CELL_EXPORT.code,
    'notebook.invalid.codeCell.execution.status': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_CELL_EXEC_STATUS.code,
    'notebook.invalid.column.type': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_COLUMN_TYPE.code,
    'notebook.invalid.language': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_LANGUAGE.code,
    'notebook.invalid.output.language': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_OUTPUT_LANGUAGE.code,
    'notebook.invalid.group': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_GROUP.code,
    'notebook.invalid.status': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_STATUS.code,
    'notebook.invalid.type': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_TYPE.code,
    'notebook.invalid.output.unique.identifier.error': GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_OUPUT_UID_ERROR.code,
    'notebook.status.language': GLOBAL_NOTIF_REASONS.NOTEBOOK_STATUS_LANGUAGE_ERROR.code,
    'notebook.codeCell.outputName.locked': GLOBAL_NOTIF_REASONS.NOTEBOOK_OUPUT_NAME_LOCKED.code,
    'notebook.empty.output.error': GLOBAL_NOTIF_REASONS.NOTEBOOK_EMPTY_OUPUT_ERROR.code,
    'notebook.codeCell.not.exists': GLOBAL_NOTIF_REASONS.NOTEBOOK_CELL_NOT_EXISTS.code,
    'notebook.connector.not.found': GLOBAL_NOTIF_REASONS.NOTEBOOK_CONNECTOR_NOT_FOUND.code,
    'notebook.not.existing': GLOBAL_NOTIF_REASONS.NOTEBOOK_NOT_EXISTING_ERROR.code,
    'notebook.forbidden.update': GLOBAL_NOTIF_REASONS.NOTEBOOK_FORBIDDEN_UPDATE.code,
    'notebook.run.error': GLOBAL_NOTIF_REASONS.NOTEBOOK_RUN_ERROR.code,
    'notebook.output.unique.identifier.locked': GLOBAL_NOTIF_REASONS.NOTEBOOK_OUTPUT_UID_LOCKED.code,
    'notebook.python.error': GLOBAL_NOTIF_REASONS.NOTEBOOK_PYTHON_ERROR.code,
    'notebook.unexportable.language': GLOBAL_NOTIF_REASONS.NOTEBOOK_UNEXPORTABLE_LANGUAGE.code,
  };

  let error = null;

  if (managedErrors[problem.errorId]) {
    error = {
      code: managedErrors[problem.errorId],
      context,
    };
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    400: manage400,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code, context } = error;

    return {
      ...error,
      message: { code, context },
    };
  }

  return manageGlobalError({ status, problem });
};
