import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _difference from 'lodash/difference';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES_ASSOCIATED_RIGHTS, ROLE_MANAGEMENT_EXCLUDED_RIGHTS } from '@palette/constants/profile';

import { actions as CompanyActions, selectors as CompanySelectors } from '@palette/state/Company';

export const useCompanyRoles = (withUsersCount = false) => {
  const dispatch = useDispatch();

  const rolesList = useSelector(CompanySelectors.getRoles);

  useEffect(() => {
    dispatch(CompanyActions.getRoles({ withUsersCount }));
  }, []);

  return rolesList;
};

export const useAllRights = () => {
  const dispatch = useDispatch();

  const rightsList = useSelector(CompanySelectors.getRights);

  useEffect(() => {
    dispatch(CompanyActions.getRights());
  }, []);

  return rightsList;
};

export const useCompanyRights = () => {
  const profile = useProfile();
  const rightsList = useAllRights();

  return useMemo(() => {
    let toExclude = [...ROLE_MANAGEMENT_EXCLUDED_RIGHTS];

    Object.keys(FEATURES_ASSOCIATED_RIGHTS).forEach((featureKey) => {
      if (!hasFeature(profile, featureKey)) {
        toExclude = toExclude.concat(FEATURES_ASSOCIATED_RIGHTS[featureKey]);
      }
    });

    return _difference(rightsList, toExclude);
  }, [profile, rightsList]);
};

export const useCompanyDefaultCurrency = () => {
  const profile = useProfile();

  return useMemo(() => (
    profile.userData.company.currency
  ), [profile]);
};
