import PropTypes from 'prop-types';

import { manageAmountAttribute, manageArrayAttribute, manageDateAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';
import { getPeriodConstantsFromPeriodId } from '@palette/helpers/StatementHelper';

import { STATEMENT_PERIODICITY_TYPES } from '@palette/constants/StatementV3';

import * as StatementV3ActionModel from '@palette/models/StatementV3Action';

export const modelName = 'StatementV3OneStatement';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  userId: PropTypes.string,
  periodId: PropTypes.string,
  periodicity: PropTypes.oneOf(Object.values(STATEMENT_PERIODICITY_TYPES)),
  period: PropTypes.number,
  year: PropTypes.number,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  actions: PropTypes.arrayOf(StatementV3ActionModel.propTypes),
});

export const transform = (statement) => {
  if (!statement) {
    return null;
  }

  const key = manageStringAttribute(statement, 'key');
  const [, periodId] = key.split('-');
  const [periodicity, period, year] = getPeriodConstantsFromPeriodId(periodId);

  return (
    {
      id: manageStringAttribute(statement, 'key'),
      amount: manageAmountAttribute(statement, 'amount'),
      currency: manageStringAttribute(statement, 'currency'),
      userId: manageStringAttribute(statement, 'payee'),
      periodId,
      periodicity,
      period,
      year,
      fromDate: manageDateAttribute(statement, 'start'),
      toDate: manageDateAttribute(statement, 'end'),
      actions: StatementV3ActionModel.transformList(manageArrayAttribute(statement, 'actions')),
    }
  );
};

export const transformList = (statements) => statements.map((statement) => transform(statement));
