import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import * as NotebookModel from '@palette/models/Notebook';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './UnarchiveNotebookModal.less';

const UnarchiveNotebookModal = ({ visible, onClose, notebook }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const unarchiveNotebookIsPending = useSelector(NotebooksSelectors.unarchiveNotebookIsPending);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const handleUnarchiveNotebook = useCallback(() => {
    dispatch(NotebooksActions.unarchiveNotebook({ notebookId: notebook.id, onSuccessCB: cleanAndClose }));
  }, [cleanAndClose]);

  return (
    <Modal
      className={styles.modal}
      title={t('unarchiveNotebookModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleUnarchiveNotebook}
      okText={t('unarchiveNotebookModal.confirm')}
      loading={unarchiveNotebookIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('unarchiveNotebookModal.content', { notebookName: notebook.name }) }} />
    </Modal>
  );
};

UnarchiveNotebookModal.propTypes = {
  notebook: NotebookModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

UnarchiveNotebookModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default UnarchiveNotebookModal;
