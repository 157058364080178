import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import DragLine from '@palette/components/utils/Icons/DragLine';
import DrawerVariableEdition from '@palette/components/commission/DrawerVariableEdition/DrawerVariableEdition';
import Button from '@palette/components/designSystem/Button/Button';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import * as ResourceColumnModel from '@palette/models/ResourceColumn';

import styles from './DraggableDrawerVariableEdition.less';

const classNames = bindClassNames.bind(styles);

const DraggableDrawerVariableEdition = ({
  className,
  variable,
  helperData,
  onNameChange,
  onFieldsChange,
  onFormatterChange,
  onEvalFnChange,
  onRemoveVariable,
  disabled,
}) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <DragLine className={styles.dragIcon} width={24} height={24} />
    <DrawerVariableEdition
      variable={variable}
      helperData={helperData}
      onNameChange={onNameChange}
      onFieldsChange={onFieldsChange}
      onFormatterChange={onFormatterChange}
      onEvalFnChange={onEvalFnChange}
    />
    <Button
      className={styles.removeButton}
      type="primary"
      shape="circle"
      onClick={onRemoveVariable}
      icon={<ClosePopupFilled width={24} height={24} />}
      disabled={disabled}
    />
  </div>
);

DraggableDrawerVariableEdition.propTypes = {
  className: PropTypes.string,
  variable: ResourceColumnModel.propTypes.isRequired,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  onNameChange: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  onFormatterChange: PropTypes.func.isRequired,
  onEvalFnChange: PropTypes.func.isRequired,
  onRemoveVariable: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

DraggableDrawerVariableEdition.defaultProps = {
  className: '',
};

export default DraggableDrawerVariableEdition;
