import moment from 'moment';
import MomentTimezone from 'moment-timezone';
import 'moment/locale/fr';

import { LETTER_NUMBER_REGEX } from '@palette/constants/global';

import { isFakedNumber } from '@palette/helpers/CommonHelper';
import { TIME_OFFSETS } from '@palette/constants/timezone';

const momentInstance = moment;

momentInstance.suppressDeprecationWarnings = true;
momentInstance.locale('en');

export const getMoment = () => (momentInstance);

export const setMomentLocale = (locale) => {
  momentInstance.locale(locale);
};

export const noYearShortFormat = () => momentInstance
  .localeData()
  .longDateFormat('ll')
  .replace(/[,-.]*\s*Y+\s*/, '');

export const shortFormat = (date, alwaysDisplayYear = false, inUTC = false) => {
  let momentDate = momentInstance(date);
  let now = momentInstance();
  if (inUTC) {
    momentDate = momentInstance.utc(date);
    now = momentInstance.utc();
  }
  const format = (momentDate.year() === now.year() && !alwaysDisplayYear) ? noYearShortFormat() : 'll';

  return momentDate.format(format);
};

export const shortFormatWithTime = (date, alwaysDisplayYear = false, inUTC = false) => {
  let momentDate = momentInstance(date);
  if (inUTC) {
    momentDate = momentInstance.utc(date);
  }

  if (momentDate.hours() === 0 && momentDate.minutes() === 0 && momentDate.seconds() === 0) return shortFormat(date, alwaysDisplayYear, inUTC);

  const formattedTime = momentDate.format('LTS');

  return `${shortFormat(date, alwaysDisplayYear, inUTC)} ${formattedTime}`;
};

export const shortFormatWithShortYear = (date, inUTC = false) => {
  let momentDate = momentInstance(date);
  if (inUTC) {
    momentDate = momentInstance.utc(date);
  }

  const formattedDate = momentDate.format('ll');

  // Eg: "Nov 23, 22"
  return formattedDate.slice(0, formattedDate.length - 4) + formattedDate.slice(-2);
};

export const shortFormatWithShortYearAndTime = (date, inUTC = false) => {
  let momentDate = momentInstance(date);
  if (inUTC) {
    momentDate = momentInstance.utc(date);
  }

  if (momentDate.hours() === 0 && momentDate.minutes() === 0 && momentDate.seconds() === 0) return shortFormatWithShortYear(date, inUTC);

  const formattedTime = momentDate.format('LTS');

  return `${shortFormatWithShortYear(date, inUTC)} ${formattedTime}`;
};

export const fromNowOrShort = (date) => {
  const momentDate = momentInstance(date);
  const now = momentInstance();

  if (now.diff(momentDate, 'days') > 2) {
    return shortFormat(momentDate);
  }

  return momentDate.fromNow();
};

export const formatBeginEndDate = (beginDate, endDate, hideYearIfSame = false, inUTC = false) => {
  let momentBeginDate = momentInstance(beginDate);
  let momentEndDate = momentInstance(endDate);
  if (inUTC) {
    momentBeginDate = momentInstance.utc(beginDate);
    momentEndDate = momentInstance.utc(endDate);
  }

  if (momentBeginDate.isSame(momentEndDate, 'day')) return momentBeginDate.format(noYearShortFormat());

  const hasSameYear = momentBeginDate.year() === momentEndDate.year();

  const format = hasSameYear ? noYearShortFormat() : 'll';

  const formattedBeginDate = momentBeginDate.format(format);
  const formattedEndDate = momentEndDate.format(format);
  const formattedYear = !hideYearIfSame && hasSameYear ? ` ${momentBeginDate.year()}` : '';

  return `${formattedBeginDate} - ${formattedEndDate}${formattedYear}`;
};

export const isValueLengthMatches = (value, length, isNumber) => {
  let val = value.toString();
  if (isNumber) {
    val = value.toFixed();
  }
  return val.length === length;
};

export const convertNumberToMoment = (numberToConvert, useUTC0 = false) => {
  if (isValueLengthMatches(numberToConvert, 10, true)) {
    return momentInstance.unix(numberToConvert);
  }
  return useUTC0 ? momentInstance(numberToConvert).utc() : momentInstance(numberToConvert);
};

export const convertAnyValueToMoment = (toConvert, useUTC0 = false) => {
  if (typeof toConvert === 'number' && !Number.isNaN(toConvert)) {
    return convertNumberToMoment(toConvert, useUTC0);
  }

  let useUnix = false;
  let isTimestamp = false;

  if (isFakedNumber(toConvert)) {
    isTimestamp = isValueLengthMatches(toConvert, 10) || isValueLengthMatches(toConvert, 13);
    useUnix = isValueLengthMatches(toConvert, 10);
  }

  const finalValue = isTimestamp ? Number(toConvert) : toConvert;

  if (useUnix && momentInstance.unix(toConvert).isValid()) {
    return momentInstance.unix(finalValue);
  }

  if (momentInstance(finalValue).isValid()) {
    return useUTC0 ? momentInstance(finalValue).utc() : momentInstance(finalValue);
  }

  return useUTC0 ? momentInstance().utc() : momentInstance();
};

export const isValueValidMomentableDate = (value) => {
  let tempReturn = false;

  if (isFakedNumber(value)) {
    tempReturn = isValueLengthMatches(value, 10) || isValueLengthMatches(value, 13);
  } else if (typeof (value) === 'string') {
    tempReturn = !LETTER_NUMBER_REGEX.test(value) && momentInstance(value).isValid();
  }

  if (tempReturn) {
    /**
     * Check if value (supposed to be a date) is a coherent date, which means that
     * the value should be between 50 years before and 50 years after the date now.
     * If not, we considere the value is not a date (maybe an ID, something like that).
     */
    const valueDate = convertAnyValueToMoment(value);
    const now = momentInstance();
    const startDate = momentInstance(now).subtract(50, 'years');
    const endDate = momentInstance(now).add(50, 'years');

    return valueDate.isBetween(startDate, endDate);
  }

  return false;
};

export const isDate12AM = (value, useUTC0 = false) => convertAnyValueToMoment(value, useUTC0).format('hh:mm:ss a') === '12:00:00 am';

export const getLocalTimezone = () => MomentTimezone.tz.guess(true);

export const utcToLocalTimezoneToUtc = (date) => (
  MomentTimezone.utc(date).tz(getLocalTimezone(), true).utc()
);

export const formatToISOStringWithFractionalSeconds = (date, endOfSecond = false) => {
  if (endOfSecond) {
    return MomentTimezone.utc(date).endOf('second').toISOString();
  }

  return MomentTimezone.utc(date).toISOString();
};

export const isTimeOffset = (genericTimezone) => Object.keys(TIME_OFFSETS).includes(genericTimezone);

export const applyGenericTimezone = (date, genericTimezone = 'Z') => {
  if (isTimeOffset(genericTimezone)) {
    return momentInstance.utc(date).utcOffset(genericTimezone);
  }

  return MomentTimezone.utc(date).tz(genericTimezone);
};

export const getBeginOfHalfYear = (date) => {
  const dateMoment = momentInstance.utc(date).date(15);
  const month = dateMoment.month();
  return month < 6 ? dateMoment.startOf('year') : dateMoment.month(6).startOf('month');
};

export const getEndOfHalfYear = (date) => {
  const dateMoment = momentInstance.utc(date).date(15);
  const month = dateMoment.month();
  return month < 6 ? dateMoment.month(5).endOf('month') : dateMoment.endOf('year');
};
