import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as ResourceObjectModel from '@palette/models/ResourceObject';
import * as CommissionModel from '@palette/models/Commission';

export const modelName = 'MasterPlanPeriodDeal';

export const propTypes = PropTypes.shape({
  date: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  progress: PropTypes.number,
  users: PropTypes.arrayOf(MetaUserModel.propTypes),
  resourceObjectId: PropTypes.string,
  resourceObject: ResourceObjectModel.propTypes,
  payees: PropTypes.arrayOf(ResourceObjectModel.propTypes),
  commissions: PropTypes.arrayOf(CommissionModel.propTypes),
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  hasOnlyTargetRule: PropTypes.bool,
});

export const transform = (masterPlanPeriodDeal) => {
  if (!masterPlanPeriodDeal) {
    return null;
  }

  let resourceObject = null;
  let resourceObjectId = manageStringAttribute(masterPlanPeriodDeal, 'object', null, true);
  if (resourceObjectId === null) {
    resourceObject = ResourceObjectModel.transform(masterPlanPeriodDeal.object);
    resourceObjectId = resourceObject?.id || '';
  }

  const groupByObject = manageFreeShapeObjectAttribute(masterPlanPeriodDeal, 'groupByObject', null);
  const users = groupByObject !== null ? [MetaUserModel.transform(groupByObject)] : MetaUserModel.transformList(manageArrayAttribute(masterPlanPeriodDeal, 'groupByObjects'));

  return ({
    date: manageDateAttribute(masterPlanPeriodDeal, 'date'),
    value: manageAmountAttribute(masterPlanPeriodDeal, 'value'),
    progress: managePureFloatAttribute(masterPlanPeriodDeal, 'progress'),
    users,
    resourceObjectId,
    resourceObject,
    payees: MetaUserModel.transformList(manageArrayAttribute(masterPlanPeriodDeal, 'payees')),
    commissions: CommissionModel.transformList(manageArrayAttribute(masterPlanPeriodDeal, 'commissions')),
    totalCommissionAmount: manageAmountAttribute(masterPlanPeriodDeal, 'totalCommissions'),
    currency: manageStringAttribute(masterPlanPeriodDeal, 'currency'),
    hasOnlyTargetRule: manageBooleanAttribute(masterPlanPeriodDeal, 'hasOnlyTargetRule'),
  });
};

export const transformList = (masterPlanPeriodDeals) => masterPlanPeriodDeals.map((masterPlanPeriodDeal) => transform(masterPlanPeriodDeal));
