import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import DuplicateMasterPlanTypeSelector from '@palette/components/masterPlan/DuplicateMasterPlanTypeSelector/DuplicateMasterPlanTypeSelector';

const DuplicateMasterPlanTypeFormItem = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      name="duplicateType"
      label={t('duplicateMasterPlanModal.goalLabel')}
      required
      rules={[
        { required: true },
      ]}
    >
      <DuplicateMasterPlanTypeSelector disabled={disabled} />
    </FormItem>
  );
};

DuplicateMasterPlanTypeFormItem.propTypes = {
  disabled: PropTypes.bool,
};

DuplicateMasterPlanTypeFormItem.defaultProps = {
  disabled: false,
};

export default DuplicateMasterPlanTypeFormItem;
