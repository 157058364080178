import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* computeAll() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.asyncJobs.compute, {}, getConfig()),
    [],
  );
}

export function* computeOne(payload) {
  const {
    planId,
    force = true,
  } = payload;

  const data = {
    entityId: planId,
    force,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.asyncJobs.compute, data, getConfig()),
    [],
  );
}

export function* getById({ jobId }) {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.asyncJobs.getById, { jobId }, getConfig()),
    [],
  );
}

export function* getICPeriodEstimation(payload) {
  const {
    planId,
    year,
    period,
  } = payload;

  const data = {
    planId,
    period: {
      year,
      period,
    },
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.asyncJobs.getICPeriodEstimation, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* getPeriodEstimationForUser(payload) {
  const {
    planId,
    year,
    period,
    user = null,
  } = payload;

  const data = {
    planId,
    period: {
      year,
      period,
    },
  };
  if (user !== null) {
    data.userId = user.id;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.asyncJobs.getPeriodEstimationForUser, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}
