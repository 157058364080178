import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import ArrowLeftSquareLine from '@palette/components/utils/Icons/ArrowLeftSquareLine';
import ArrowRightSquareLine from '@palette/components/utils/Icons/ArrowRightSquareLine';

import styles from './SidebarCollapser.less';

const classNames = bindClassNames.bind(styles);

const SidebarCollapser = ({ className, onToggle, collapsed }) => {
  const { t } = useTranslation();

  let contentNode = null;
  if (!collapsed) {
    contentNode = t('sidebarCollapser.hide');
  }

  return (
    <Button
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      type="primary"
      shape="round"
      icon={collapsed ? <ArrowRightSquareLine /> : <ArrowLeftSquareLine />}
      onClick={onToggle}
    >
      {contentNode}
    </Button>
  );
};

SidebarCollapser.propTypes = {
  className: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

SidebarCollapser.defaultProps = {
  className: '',
};

export default SidebarCollapser;
