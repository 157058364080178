import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import TeamsBreadcrumb from '@palette/components/team/TeamsBreadcrumb/TeamsBreadcrumb';
import TeamDetailsPageContent from '@palette/components/team/TeamDetailsPageContent/TeamDetailsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useTeamIdInParams } from '@palette/hooks/TeamHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './TeamDetailsPage.less';

const TeamDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { team } = useTeamIdInParams();

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.VIEW_ALL, RIGHTS.ADMIN.TEAMS.VIEW_SCOPED])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.teamDetailsTitle', { teamName: team?.name })} />
      <TeamsBreadcrumb className={styles.breadcrumb} team={team} />
      <PageTitle className={styles.title}>
        <h2>{team?.name}</h2>
      </PageTitle>
      <TeamDetailsPageContent />
    </div>
  );
};

export default TeamDetailsPage;
