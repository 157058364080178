import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { isSuperAdmin } from '@palette/helpers/ProfileHelper';

import { WHITELISTED_COMPANIES } from '@palette/constants/profile';

import styles from './SuperAdminBanner.less';

const SuperAdminBanner = () => {
  const profile = useProfile();
  const { t } = useTranslation();

  if (!isSuperAdmin() || Object.values(WHITELISTED_COMPANIES).includes(profile.userData.companyId)) return null;

  return (
    <div className={styles.wrapper}>
      {t('superAdminBanner.content')}
    </div>
  );
};

export default SuperAdminBanner;
