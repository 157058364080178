/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import * as UserVariablesListModel from '@palette/models/UserVariablesList';

/*
 * Initial State
 */
const initialState = {
  getUsersVariablesIsPending: false,
  createVariableIsPending: false,
  editVariableIsPending: false,
  deleteVariableValueOverrideIsPending: false,

  usersVariables: UserVariablesListModel.transform({}),
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'usersVariables',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Users Variables */
    getUsersVariables: (state) => {
      state.getUsersVariablesIsPending = true;
    },
    setUsersVariables: (state, { payload }) => {
      const { usersVariables } = payload;
      state.usersVariables = usersVariables;
    },
    getUsersVariablesCompleted: (state) => {
      state.getUsersVariablesIsPending = false;
    },
    /* Create Variable */
    createVariable: (state) => {
      state.createVariableIsPending = true;
    },
    createVariableCompleted: (state) => {
      state.createVariableIsPending = false;
    },
    /* Edit Variable */
    editVariable: (state) => {
      state.editVariableIsPending = true;
    },
    editVariableCompleted: (state) => {
      state.editVariableIsPending = false;
    },
    /* Edit Variable */
    deleteVariableValueOverride: (state) => {
      state.deleteVariableValueOverrideIsPending = true;
    },
    deleteVariableValueOverrideCompleted: (state) => {
      state.deleteVariableValueOverrideIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];

const getUsersVariablesIsPending = (state) => root(state).getUsersVariablesIsPending;
const createVariableIsPending = (state) => root(state).createVariableIsPending;
const editVariableIsPending = (state) => root(state).editVariableIsPending;
const deleteVariableValueOverrideIsPending = (state) => root(state).deleteVariableValueOverrideIsPending;

const getUsersVariables = (state) => root(state).usersVariables;

export const selectors = {
  getUsersVariablesIsPending,
  createVariableIsPending,
  editVariableIsPending,
  deleteVariableValueOverrideIsPending,

  getUsersVariables,
};
