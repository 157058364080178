import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import UserSelector from '@palette/components/user/UserSelector/UserSelector';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './MergeUserSelector.less';

const classNames = bindClassNames.bind(styles);

const MergeUserSelector = ({
  className,
  title,
  userQSKey,
  user,
}) => {
  const dispatch = useDispatch();

  const handleUserSelected = useCallback((newUserId) => {
    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: { [userQSKey]: newUserId } }));
  }, [userQSKey]);

  const userProfileNode = useMemo(() => {
    if (user === null) return null;

    return (
      <UserProfile
        className={styles.profile}
        user={user}
        vertical
        clickable
      />
    );
  }, [user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <h2>
        {title}
      </h2>
      <UserSelector
        className={styles.select}
        allowClear={false}
        searchedUser={user?.id || null}
        onUserSelected={handleUserSelected}
      />
      {userProfileNode}
    </div>
  );
};

MergeUserSelector.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  userQSKey: PropTypes.string.isRequired,
  user: MetaUserModel.propTypes,
};

MergeUserSelector.defaultProps = {
  className: '',
  user: null,
};

export default MergeUserSelector;
