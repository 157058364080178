import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AvatarGroup from '@palette/components/user/AvatarGroup/AvatarGroup';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { getFrequencyName } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';

import * as QuotaPlanModel from '@palette/models/QuotaPlan';

import styles from './PlanUsingQuotaItem.less';

const classNames = bindClassNames.bind(styles);

const PlanUsingQuotaItem = ({ className, plan }) => {
  const { t } = useTranslation();

  const handleViewBtnClick = useCallback(() => {
    redirectTo({ path: routePaths.v2.planDetails, params: { masterPlanId: plan.id } });
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.planInfosWrapper}>
        <div className={styles.planName}>
          {plan.name}
        </div>
        <div className={styles.planInfos}>
          <div>
            {`${getFrequencyName(plan)} · ${t('usersWithCount', { count: plan.usersCount })}`}
          </div>
          <AvatarGroup className={styles.avatars} users={plan.users} nbToDisplay={5} totalUsers={plan.usersCount} />
        </div>
      </div>
      <Button onClick={handleViewBtnClick}>
        {t('planUsingQuotaItem.view')}
      </Button>
    </div>
  );
};

PlanUsingQuotaItem.propTypes = {
  className: PropTypes.string,
  plan: QuotaPlanModel.propTypes.isRequired,
};

PlanUsingQuotaItem.defaultProps = {
  className: '',
};

export default PlanUsingQuotaItem;
