import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { actions as DashboardActions, selectors as DashboardSelectors } from '@palette/state/Dashboard';

import styles from './WidgetBase.less';

const classNames = bindClassNames.bind(styles);

const WidgetBase = ({ className, widgetIndex, addedActions, children, readOnly }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const widgetsData = useSelector(DashboardSelectors.widgetsData);

  const handleRemove = useCallback(() => {
    dispatch(DashboardActions.removeWidget({ widgetIndex }));
  }, [widgetIndex]);

  const handleMove = useCallback((nbOfPositions) => {
    dispatch(DashboardActions.moveWidget({ widgetIndex, nbOfPositions }));
  }, [widgetIndex]);

  const handleMoveLeft = useCallback(() => {
    handleMove(-1);
  }, [handleMove]);

  const handleMoveRight = useCallback(() => {
    handleMove(1);
  }, [handleMove]);

  const actionsNode = useMemo(() => {
    if (readOnly) return null;

    let moveLeftNode = (
      <Tooltip title={t('widgetBase.tooltips.moveLeft')}>
        <Button
          className={styles.actionButton}
          type="linkSecondaryBlack"
          icon={<ChevronLeftLine width={20} height={20} />}
          onClick={handleMoveLeft}
        />
      </Tooltip>
    );
    if (widgetIndex === 0) {
      moveLeftNode = null;
    }

    let moveRightNode = (
      <Tooltip title={t('widgetBase.tooltips.moveRight')}>
        <Button
          className={styles.actionButton}
          type="linkSecondaryBlack"
          icon={<ChevronRightLine width={20} height={20} />}
          onClick={handleMoveRight}
        />
      </Tooltip>
    );
    if (widgetIndex === widgetsData.length - 1) {
      moveRightNode = null;
    }

    return (
      <div className={styles.actionsWrapper}>
        {moveLeftNode}
        {moveRightNode}
        {addedActions}
        <Tooltip title={t('widgetBase.tooltips.remove')}>
          <Button
            className={styles.actionButton}
            type="linkDestroy"
            icon={<TrashFilled width={16} height={16} />}
            onClick={handleRemove}
          />
        </Tooltip>
      </div>
    );
  }, [widgetIndex, widgetsData, addedActions, readOnly]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {actionsNode}
      {children}
    </div>
  );
};

WidgetBase.propTypes = {
  className: PropTypes.string,
  widgetIndex: PropTypes.number.isRequired,
  addedActions: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.any,
  readOnly: PropTypes.bool,
};

WidgetBase.defaultProps = {
  className: '',
  addedActions: [],
  children: null,
  readOnly: false,
};

export default WidgetBase;
