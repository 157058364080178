import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'AggregatedFxRate';

export const propTypes = PropTypes.shape({
  from: PropTypes.string,
  to: PropTypes.string,
  count: PropTypes.number,
  currentRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const transform = (aggregatedFxRate) => {
  if (!aggregatedFxRate) {
    return null;
  }

  return (
    {
      from: manageStringAttribute(aggregatedFxRate, 'from'),
      to: manageStringAttribute(aggregatedFxRate, 'to'),
      count: manageNumberAttribute(aggregatedFxRate, 'count'),
      currentRate: manageAmountAttribute(aggregatedFxRate, 'currentRate'),
    }
  );
};

export const transformList = (aggregatedFxRates) => aggregatedFxRates.map((aggregatedFxRate) => transform(aggregatedFxRate));
