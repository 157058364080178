import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import Button from '@palette/components/designSystem/Button/Button';
import EyesLine from '@palette/components/utils/Icons/EyesLine';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { COMMISSION_V3_DRAWER_QS_KEY, COMMISSION_V3_DRAWER_TABS_IDS } from '@palette/constants/tabs';
import { COMMISSION_DRAWER_COMMISSION_ID_QS_KEY } from '@palette/constants/commissions';

import routePaths from '@palette/config/routePaths';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';
import * as StatementV3ListCommissionModel from '@palette/models/StatementV3ListCommission';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './StatementV3UserStatementCommissionsItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementCommissionsItem = ({ className, statement, commission }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();
  const profile = useProfile();

  const openCommissionDrawer = useCallback((tabKey) => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [COMMISSION_DRAWER_COMMISSION_ID_QS_KEY]: commission.id, [COMMISSION_V3_DRAWER_QS_KEY]: tabKey } }));
  }, [commission]);

  const actionsNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return null;

    return (
      <div className={styles.actions}>
        <Button
          className={styles.viewButton}
          type="link"
          icon={<EyesLine width={18} height={18} />}
          onClick={() => openCommissionDrawer(COMMISSION_V3_DRAWER_TABS_IDS.PAYMENTS)}
        >
          {t('commissionList.actions.button.view_details')}
        </Button>
      </div>
    );
  }, [profile, statement]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.colRow}>
        <span className={styles.details}>
          <Link
            path={routePaths.v2.myPlansV3RequirementsById}
            params={{ planId: commission.planId }}
            displayIcon
          >
            {commission.planName !== '' ? commission.planName : '-'}
          </Link>
        </span>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {commission.dealId !== '' ? commission.dealId : '-'}
        </span>
      </div>
      <div className={styles.colRow}>
        <strong className={styles.details}>
          {formatPrice(commission.amount, commission.currency)}
        </strong>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {commission.date && (
            moment.utc(commission.date).format('LL')
          )}
          {!commission.date && '-'}
        </span>
        {actionsNode}
      </div>
    </div>
  );
};

StatementV3UserStatementCommissionsItem.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  commission: StatementV3ListCommissionModel.propTypes.isRequired,
};

StatementV3UserStatementCommissionsItem.defaultProps = {
  className: '',
};

export default StatementV3UserStatementCommissionsItem;
