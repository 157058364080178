import React, { useState } from 'react';

import Button from '@palette/components/designSystem/Button/Button';
import PlusLine from '@palette/components/utils/Icons/PlusLine';
import AddUsersInQuotaModal from '@palette/components/quota/AddUsersInQuotaModal/AddUsersInQuotaModal';

import styles from './AddUsersInQuota.less';

const AddUsersInQuota = () => {
  const [addUsersInQuotaModalIsVisible, showAddUsersInQuotaModal] = useState(false);

  return (
    <>
      <Button
        className={styles.button}
        type="primary"
        shape="circle"
        onClick={() => showAddUsersInQuotaModal(true)}
        icon={<PlusLine width={14} height={14} />}
      />
      {
        addUsersInQuotaModalIsVisible && (
          <AddUsersInQuotaModal visible onClose={() => showAddUsersInQuotaModal(false)} />
        )
      }
    </>
  );
};

export default AddUsersInQuota;
