import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as ResourceDeletionStrategyModel from '@palette/models/globalNotifStrategies/resource/ResourceDeletionStrategy';

const classNames = bindClassNames.bind();

const ResourceDeletionContentStrategy = ({ className, content }) => {
  const { type } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notifications.resources.resource_deleted.success.content"
        values={{ type }}
      />
    </span>
  );
};

ResourceDeletionContentStrategy.propTypes = {
  className: PropTypes.string,
  content: ResourceDeletionStrategyModel.propTypes.isRequired,
};

ResourceDeletionContentStrategy.defaultProps = {
  className: '',
};

export default ResourceDeletionContentStrategy;
