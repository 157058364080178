import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import { CONNECTOR_DETAILS, CONNECTOR_TYPES } from '@palette/constants/connector';
import { DRAFT_DATA_SOURCE_TABS_IDS } from '@palette/constants/tabs';
import { EMPTY_DEFAULT_VALUE as TRACKING_OBJECTS_EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';
import { EMPTY_DEFAULT_VALUE as USERS_DEFINITION_EMPTY_DEFAULT_VALUE } from '@palette/constants/usersDefinition';

import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './CreatePlanFlowConnectorSelector.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowConnectorSelector = ({
  className,
  plan,
  value,
  currentTab,
  onChange,
  disabled,
  ...otherProps
}) => {
  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    if (onChange !== null) {
      onChange(newValue);
    }
  });

  const connectorsList = useConnectorsList();

  const filteredConnectorsList = useMemo(() => (
    connectorsList
      .filter((connector) => (connector.resources.length > 0))
      .filter((connector) => {
        if (currentTab === DRAFT_DATA_SOURCE_TABS_IDS.CONNECTORS) {
          return connector.type !== CONNECTOR_TYPES.NOTEBOOK;
        }
        return connector.type === CONNECTOR_TYPES.NOTEBOOK;
      })
  ), [connectorsList, currentTab]);

  const usersDefinitionConnectorIdType = useMemo(() => {
    if (plan.usersDefinition?.originalType === USERS_DEFINITION_EMPTY_DEFAULT_VALUE) return null;

    return {
      connectorId: plan.usersDefinition?.connectorId,
      type: plan.usersDefinition?.originalType,
    };
  }, [plan]);

  const trackingObjectConnectorIdType = useMemo(() => {
    if (plan.trackingObject?.originalType === TRACKING_OBJECTS_EMPTY_DEFAULT_VALUE) return usersDefinitionConnectorIdType;

    return {
      connectorId: plan.trackingObject?.connectorId,
      type: plan.trackingObject?.originalType,
    };
  }, [plan, usersDefinitionConnectorIdType]);

  const connectorsOptions = useMemo(() => (
    filteredConnectorsList.reduce((options, connector) => {
      const connectorOption = {
        label: connector.name,
        value: connector.id,
        ...(CONNECTOR_DETAILS[connector.type] || CONNECTOR_DETAILS.UNKNOWN),
      };

      if (trackingObjectConnectorIdType?.connectorId === connector.id) {
        options.unshift(connectorOption);
      } else {
        options.push(connectorOption);
      }

      return options;
    }, [])
  ), [filteredConnectorsList, trackingObjectConnectorIdType]);

  const connectorsNodes = useMemo(() => (
    connectorsOptions.map((connectorsOption) => {
      const selected = value === connectorsOption.value;

      return (
        <div
          key={connectorsOption.value}
          className={classNames({
            connectorCardWrapper: true,
            disabled,
            selected,
          })}
          onClick={() => handleChange(connectorsOption.value)}
        >
          <div className={styles.iconWrapper}>
            {connectorsOption.iconComponent({ className: styles.iconWrapperIcon })}
          </div>
          <div className={styles.label}>
            {connectorsOption.label}
          </div>
          {selected && (
            <CheckFilled
              className={styles.checkedIcon}
              width={20}
              height={20}
            />
          )}
        </div>
      );
    })
  ), [connectorsOptions, value, handleChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      {connectorsNodes}
    </div>
  );
};

CreatePlanFlowConnectorSelector.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  value: PropTypes.string,
  currentTab: PropTypes.oneOf(Object.values(DRAFT_DATA_SOURCE_TABS_IDS)),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CreatePlanFlowConnectorSelector.defaultProps = {
  className: '',
  value: null,
  currentTab: DRAFT_DATA_SOURCE_TABS_IDS.CONNECTORS,
  onChange: null,
  disabled: false,
};

export default CreatePlanFlowConnectorSelector;
