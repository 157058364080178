import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import UserResourcesSelectionPanel from '@palette/components/resources/UserResourcesSelectionPanel/UserResourcesSelectionPanel';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './SelectUserResourcesModal.less';

const SelectUserResourcesModal = ({
  visible,
  onClose,
  onOk,
  preSelectedResources,
  restrictToConnectorIdAndType,
  disableReloadUsers,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [selectedResources, setSelectedResources] = useState([]);

  useEffect(() => {
    if (visible) setSelectedResources([]);
  }, [visible]);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSelectedResourcesChange = (selectedUserResources) => {
    setSelectedResources(selectedUserResources);
  };

  const handleSelectResources = useCallback(() => {
    onOk(selectedResources, cleanAndClose);
  }, [selectedResources, cleanAndClose]);

  return (
    <Modal
      className={styles.modal}
      title={t('selectUserResourcesModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleSelectResources}
      okText={t('selectUserResourcesModal.okText')}
      disableOk={selectedResources.length === 0}
      width="60%"
      stretchHeight
      {...otherProps}
    >
      <UserResourcesSelectionPanel
        preSelectedResources={preSelectedResources}
        onChange={handleSelectedResourcesChange}
        restrictToConnectorIdAndType={restrictToConnectorIdAndType}
        disableReloadUsers={disableReloadUsers}
      />
    </Modal>
  );
};

SelectUserResourcesModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  preSelectedResources: PropTypes.arrayOf(ResourceObjectModel.propTypes),
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  disableReloadUsers: PropTypes.bool,
};

SelectUserResourcesModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onOk: () => {},
  preSelectedResources: [],
  restrictToConnectorIdAndType: null,
  disableReloadUsers: false,
};

export default SelectUserResourcesModal;
