import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@palette/components/designSystem/Link/Link';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import LoadingOutlined from '@palette/components/utils/Icons/LoadingOutlined';
import MasterPlanListItemMoreOptionsMenu from '@palette/components/masterPlan/MasterPlanListItemMoreOptionsMenu/MasterPlanListItemMoreOptionsMenu';
import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';
import MasterPlanPeriodProgressDetails from '@palette/components/masterPlan/MasterPlanPeriodProgressDetails/MasterPlanPeriodProgressDetails';
import MasterPlanListItemFreezeButton from '@palette/components/masterPlan/MasterPlanListItemFreezeButton/MasterPlanListItemFreezeButton';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import {
  getFrequencyName,
  getPlanPeriodName,
  getPlanUsersCount,
  isArchived,
  isUserGroupByUserPlan,
} from '@palette/helpers/MasterPlanHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { RIGHTS } from '@palette/constants/profile';
import { PERIOD_TABS_IDS, PERIOD_TABS_QS_KEY } from '@palette/constants/tabs';
import { SEARCHED_USER_QS_KEY } from '@palette/constants/masterPlans';

import { useProfile } from '@palette/hooks/ProfileHooks';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MetaUserModel from '@palette/models/MetaUser';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanListItem.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanListItem = ({ className, plan, disableActions, displayTotalCommission, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const getListLastPeriodsIsPending = useSelector(MasterPlansSelectors.getListLastPeriodsIsPending);
  const getPlanListLastPeriodsForUserIsPending = useSelector(MasterPlansSelectors.getPlanListLastPeriodsForUserIsPending);

  const isLastPeriodStatsPending = getListLastPeriodsIsPending || getPlanListLastPeriodsForUserIsPending;

  const handlePlanPeriodClick = () => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.CLICK_PERIOD }));
  };

  const nameNode = useMemo(() => {
    const handlePlanNameClick = () => {
      dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.CLICK_PLAN_NAME }));
    };

    let linkNode = (
      <Tooltip title={t('masterPlan.seeLastPeriods')}>
        <Link
          path={routePaths.v2.planDetails}
          params={{ masterPlanId: plan.id }}
          onClick={handlePlanNameClick}
          displayIcon
        >
          {plan.name}
        </Link>
      </Tooltip>
    );

    if (plan.periodType === PERIOD_TYPES.CUSTOM) {
      linkNode = (
        <div className={styles.nameLabel}>
          {plan.name}
        </div>
      );
    }

    return (
      <div className={styles.nameWrapper}>
        {linkNode}
      </div>
    );
  }, [plan]);

  const usersCountNode = (
    <>
      <div className={styles.usersCount}>{getPlanUsersCount(plan)}</div>
      <TeamFilled className={styles.usersCountIcon} />
    </>
  );

  const lastPeriodLinkNode = useMemo(() => {
    let lastPeriodLink = null;

    if (plan.lastPeriod !== null) {
      const lastPeriodName = getPlanPeriodName(plan, plan.lastPeriod);

      let qsObject = {};
      if (user !== null && !isUserGroupByUserPlan(plan)) {
        qsObject = {
          [PERIOD_TABS_QS_KEY]: PERIOD_TABS_IDS.DEALS,
          [SEARCHED_USER_QS_KEY]: user.id,
        };
      }

      lastPeriodLink = (
        <Link
          path={routePaths.v2.planPeriodDetails}
          params={{ masterPlanId: plan.id, year: plan.lastPeriod.year, periodId: plan.lastPeriod.period }}
          qsObject={qsObject}
          onClick={handlePlanPeriodClick}
          displayIcon
        >
          {t('masterPlan.lastPeriod.viewPeriod', { periodName: lastPeriodName })}
        </Link>
      );
    }

    return lastPeriodLink;
  }, [plan, isLastPeriodStatsPending, user]);

  const lastPeriodTotalCommissionNode = useMemo(() => {
    let lastPeriodTotalCommission = null;

    if (isLastPeriodStatsPending) {
      lastPeriodTotalCommission = <LoadingOutlined spin />;
    } else if (plan.lastPeriod !== null) {
      lastPeriodTotalCommission = (
        <>
          <div className={styles.lastPeriodCommission}>
            {t('masterPlan.lastPeriod.totalCommission')}
          </div>
          <div className={styles.lastPeriodCommissionValue}>
            {formatPrice(plan.lastPeriod.totalCommissionAmount, plan.lastPeriod.currency)}
          </div>
        </>
      );
    }

    return lastPeriodTotalCommission;
  }, [plan, isLastPeriodStatsPending]);

  const totalCommissionValueNode = useMemo(() => {
    if (!displayTotalCommission) return null;

    return (
      <div className={styles.totalCommissionValue}>{lastPeriodTotalCommissionNode}</div>
    );
  }, [displayTotalCommission, lastPeriodTotalCommissionNode]);

  const freezeButtonNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE]) || disableActions) return null;

    const handleConfirmFreezeAction = (action) => {
      dispatch(AnalyticsActions.sendEvent({
        event: MASTER_PLANS_EVENTS.PLAN_LIST.CLICK_FREEZE,
        params: { action },
      }));
    };

    return <MasterPlanListItemFreezeButton plan={plan} lastPeriod={plan.lastPeriod} onConfirm={handleConfirmFreezeAction} />;
  }, [profile, plan, disableActions]);

  const lastPeriodDetailsNode = useMemo(() => {
    let lastPeriodDetails = null;

    if (isLastPeriodStatsPending) {
      lastPeriodDetails = <LoadingOutlined spin />;
    } else if (plan.lastPeriod !== null && plan.quotaId !== null) {
      lastPeriodDetails = (
        <MasterPlanPeriodProgressDetails plan={plan} period={plan.lastPeriod} />
      );
    }

    return lastPeriodDetails;
  }, [plan, isLastPeriodStatsPending]);

  const actionsNode = useMemo(() => {
    if (disableActions) return null;

    return (
      <div>
        <MasterPlanListItemMoreOptionsMenu plan={plan} />
      </div>
    );
  }, [disableActions, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.namePeriodWrapper}>
          <div className={styles.nameScopeWrapper}>{nameNode}</div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsWrapper}>
            {freezeButtonNode}
            <div className={styles.usersCountWrapper}>{usersCountNode}</div>
            <div className={styles.frequency}>{getFrequencyName(plan)}</div>
            <MasterPlanScopeTags plan={plan} className={styles.scope} />
          </div>
          {totalCommissionValueNode}
        </div>
      </div>
      <div className={styles.progressActionsWrapper}>
        <div
          className={classNames({
            lastPeriodLink: true,
            archived: isArchived(plan),
            alignRight: disableActions && isArchived(plan),
          })}
        >
          {lastPeriodLinkNode}
        </div>
        {!isArchived(plan) && (
          <div
            className={classNames({
              lastPeriodDetails: true,
              alignRight: disableActions,
            })}
          >
            {lastPeriodDetailsNode}
          </div>
        )}
        {actionsNode}
      </div>
    </div>
  );
};

MasterPlanListItem.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  disableActions: PropTypes.bool,
  displayTotalCommission: PropTypes.bool,
  user: MetaUserModel.propTypes,
};

MasterPlanListItem.defaultProps = {
  className: '',
  disableActions: false,
  displayTotalCommission: false,
  user: null,
};

export default MasterPlanListItem;
