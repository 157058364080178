import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import PlanV3FormRequirements from '@palette/components/ic/planV3/PlanV3FormRequirements/PlanV3FormRequirements';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3RequirementsPageContent.less';

const classNames = bindClassNames.bind(styles);

const PlanV3RequirementsPageContent = ({ className, planId }) => {
  const dispatch = useDispatch();

  const configuration = useSelector((state) => PlanV3Selectors.getConfiguration(state));
  const isPending = useSelector((state) => PlanV3Selectors.getConfigurationIsPending(state));

  useEffect(() => {
    if (planId && !isPending) {
      dispatch(PlanV3Actions.getICConfiguration({ planId }));
    }
  }, [planId]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {isPending && <Loader />}
      {!isPending && <PlanV3FormRequirements planId={planId} configuration={configuration} />}
    </div>
  );
};

PlanV3RequirementsPageContent.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

PlanV3RequirementsPageContent.defaultProps = {
  className: '',
};

export default PlanV3RequirementsPageContent;
