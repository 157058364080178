import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import Button from '@palette/components/designSystem/Button/Button';
import QuotaInfos from '@palette/components/quota/QuotaInfos/QuotaInfos';
import Table from '@palette/components/designSystem/Table/Table';
import DefaultValueStartingDateCell from '@palette/components/quota/DefaultValueStartingDateCell/DefaultValueStartingDateCell';
import DefaultValueValueCell from '@palette/components/quota/DefaultValueValueCell/DefaultValueValueCell';
import AddLine from '@palette/components/utils/Icons/AddLine';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import * as YearPeriodValueModel from '@palette/models/YearPeriodValue';

import { selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './EditDefaultValuesPanel.less';

const EditDefaultValuesPanel = ({
  quotaId,
  initialDefaultValues,
  saveNewDefaultValues,
  updateIsPending,
  displayQuotaName,
  displayQuotaInfos,
  allowDeleteAll,
}) => {
  const profile = useProfile();
  const moment = getMoment();
  const { t } = useTranslation();

  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));

  const locked = useMemo(() => (
    !hasAllRights(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE, RIGHTS.ADMIN.QUOTAS.USERS.MANAGE])
  ), [profile]);

  const [scrollToBottom, setScrollToBottom] = useState(!locked);
  const [toDeletes, setToDeletes] = useState({});
  const [quotaDefaultValues, setQuotaDefaultValues] = useState(_cloneDeep(initialDefaultValues));

  useEffect(() => {
    setToDeletes({});
    setQuotaDefaultValues(_cloneDeep(initialDefaultValues));
    setScrollToBottom(false);
  }, [quotaId]);

  useEffect(() => {
    setToDeletes({});
    setQuotaDefaultValues(_cloneDeep(initialDefaultValues));
    setScrollToBottom(!locked);
  }, [initialDefaultValues]);

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        setScrollToBottom(false);
      }, 200);
    }
  }, [scrollToBottom]);

  const isPristine = useMemo(() => (
    Object.keys(toDeletes).length === 0 && _isEqual(initialDefaultValues, quotaDefaultValues)
  ), [toDeletes, initialDefaultValues, quotaDefaultValues]);

  const handleSaveButton = () => {
    const newDefaultValues = quotaDefaultValues.reduce((res, defaultValue, index) => {
      if (!toDeletes[index]) {
        res.push(defaultValue);
      }

      return res;
    }, []);
    saveNewDefaultValues(newDefaultValues);
  };

  const handleDefaultValueUpdate = (updatedDefaultValue, index, toDelete = false) => {
    const defaultValues = _cloneDeep(quotaDefaultValues);
    defaultValues[index] = updatedDefaultValue;

    setQuotaDefaultValues(defaultValues);
    setToDeletes({ ...toDeletes, [index]: toDelete });
  };

  const handleAddNewButton = () => {
    setScrollToBottom(true);

    const defaultValues = _cloneDeep(quotaDefaultValues);
    defaultValues.push({
      year: moment().year(),
      period: 1,
      value: 0,
    });

    setQuotaDefaultValues(defaultValues);
  };

  const columns = useMemo(() => ([
    {
      Header: t('editDefaultValuesPanel.startingDate'),
      accessor: ({ defaultValue, toDelete }) => ({ defaultValue, toDelete }),
      width: '40%',
      minWidth: '40%',
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value, row, ...otherCellprops }) => (
        <DefaultValueStartingDateCell
          quotaPeriodType={quota.periodType}
          quotaFrequency={quota.frequency}
          quotaBeginAt={quota.beginAt}
          defaultValue={value.defaultValue}
          locked={locked}
          index={row.index}
          toDelete={value.toDelete}
          onUpdate={handleDefaultValueUpdate}
          allowDeleteAll={allowDeleteAll}
          {...otherCellprops}
        />
      ),
      /* eslint-enable react/prop-types */
    },
    {
      Header: t('editDefaultValuesPanel.defaultValue'),
      accessor: ({ defaultValue, toDelete }) => ({ defaultValue, toDelete }),
      width: '58%',
      minWidth: '58%',
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value, row, ...otherCellprops }) => (
        <DefaultValueValueCell
          defaultValue={value.defaultValue}
          locked={locked}
          index={row.index}
          toDelete={value.toDelete}
          onUpdate={handleDefaultValueUpdate}
          allowDeleteAll={allowDeleteAll}
          {...otherCellprops}
        />
      ),
      /* eslint-enable react/prop-types */
    },
  ]), [locked, quota.frequency, quotaDefaultValues]);

  const data = useMemo(() => quotaDefaultValues.map((defaultValue, index) => ({ defaultValue, toDelete: (toDeletes[index] || false) })), [quotaDefaultValues, toDeletes]);

  const saveButtonNode = (
    <Button
      onClick={handleSaveButton}
      loading={updateIsPending}
      disabled={locked || isPristine}
    >
      {t('editDefaultValuesPanel.saveDefaultValue')}
    </Button>
  );

  let tableNode = (
    <div className={styles.table}>
      <Table columns={columns} data={data} stickyHeader scrollToBottom={scrollToBottom} stretch />
    </div>
  );
  if (data.length === 0) {
    tableNode = (
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.INFO}
        message={t('editDefaultValuesPanel.alert.noDefaultValues.message')}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.quotaInfosWrapper}>
          {
            displayQuotaName && (
              <div className={styles.quotaName}>{quota.name}</div>
            )
          }
          {
            displayQuotaInfos && (
              <QuotaInfos className={styles.infos} quota={quota} displayUsersCount={!displayQuotaName} />
            )
          }
        </div>
        {saveButtonNode}
      </div>
      { tableNode }
      <div className={styles.addNewDefaultValueWrapper}>
        <Button
          type="secondary"
          icon={(<AddLine />)}
          onClick={handleAddNewButton}
        >
          {t('editDefaultValuesPanel.addNewDefaultValue')}
        </Button>
      </div>
    </div>
  );
};

EditDefaultValuesPanel.propTypes = {
  quotaId: PropTypes.string.isRequired,
  initialDefaultValues: PropTypes.arrayOf(YearPeriodValueModel.propTypes).isRequired,
  updateIsPending: PropTypes.bool.isRequired,
  saveNewDefaultValues: PropTypes.func.isRequired,
  displayQuotaName: PropTypes.bool,
  displayQuotaInfos: PropTypes.bool,
  allowDeleteAll: PropTypes.bool,
};

EditDefaultValuesPanel.defaultProps = {
  displayQuotaName: false,
  displayQuotaInfos: true,
  allowDeleteAll: false,
};

export default EditDefaultValuesPanel;
