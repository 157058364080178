import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import LeftMainContentLayout from '@palette/components/designSystem/LeftMainContentLayout/LeftMainContentLayout';
import AddDataConnectionStepsList from '@palette/components/planV3/DataSources/AddDataConnectionStepsList/AddDataConnectionStepsList';
import AddDataConnectionFlowPageStepsManagement from '@palette/components/planV3/DataSources/AddDataConnectionFlowPageStepsManagement/AddDataConnectionFlowPageStepsManagement';

import { useNewDataConnectionInfo } from '@palette/hooks/PlanV3Hooks';

import { ADD_DATA_CONNECTION_FLOW_FIRST_STEP } from '@palette/constants/notebooks';

import { actions as PlanV3Actions } from '@palette/state/PlanV3';

import styles from './AddDataConnectionModal.less';

const AddDataConnectionModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { disableAddEditDataConnectionAction, addEditDataConnectionAction } = useNewDataConnectionInfo();

  useEffect(() => {
    dispatch(PlanV3Actions.setAddDataConnectionCurrentFlowStep({ addDataConnectionCurrentFlowStep: ADD_DATA_CONNECTION_FLOW_FIRST_STEP }));
  }, []);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const handleAddDataConnection = useCallback(() => {
    if (addEditDataConnectionAction !== null) {
      addEditDataConnectionAction();
      cleanAndClose();
    }
  }, [addEditDataConnectionAction, cleanAndClose]);

  const leftSideContentNode = useMemo(() => (
    <AddDataConnectionStepsList />
  ), []);

  const mainContentNode = useMemo(() => (
    <AddDataConnectionFlowPageStepsManagement />
  ), []);

  return (
    <Modal
      className={styles.modal}
      title={t('addDataConnectionModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleAddDataConnection}
      okText={t('addDataConnectionModal.import')}
      disableOk={disableAddEditDataConnectionAction}
      blockHeight
    >
      <LeftMainContentLayout
        leftSideContent={leftSideContentNode}
        leftSideContentClassName={styles.leftSideContentWrapper}
        mainClassName={styles.mainWrapper}
        mainContent={mainContentNode}
        mainContentClassName={styles.mainContentWrapper}
        leftSideWidth={21.2}
        autoWidth={false}
        borderless
      />
    </Modal>
  );
};

AddDataConnectionModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

AddDataConnectionModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddDataConnectionModal;
