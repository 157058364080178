import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import RulePayoutForm from '@palette/components/rule/RulePayoutForm/RulePayoutForm';
import FunctionLine from '@palette/components/utils/Icons/FunctionLine';
import ResetLine from '@palette/components/utils/Icons/ResetLine';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';

import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';

import * as PayoutRuleModel from '@palette/models/PayoutRule';

import styles from './MasterPlanSettingsPayoutRulesFormRule.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsPayoutRulesFormRule = ({
  className,
  ruleNumberPosition,
  helperData,
  rule,
  onRemove,
  onChange,
  onCollapseChange,
  openedCollapses,
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => ({
    ...rule,
    conditionFormula: rule.conditionFormula || 'true',
    code: rule.code || null,
    payments: rule.payments || [],
  }), [rule]);

  const [ruleConditionFormula, setRuleConditionFormula] = useState(initialValues.conditionFormula);
  const [ruleCode, setRuleCode] = useState(initialValues.code);
  const [rulePayments, setRulePayments] = useState(initialValues.payments);
  const [showCustomCodeSection, setShowCustomCodeSection] = useState(initialValues.code !== '' && initialValues.code !== null);

  const handleChange = useCallback(() => {
    const updatedRule = {
      ...rule,
      ...initialValues,
      conditionFormula: ruleConditionFormula,
      code: ruleCode,
      payments: rulePayments,
    };

    if (!_isEqual(initialValues, updatedRule) || ruleCode === null) {
      onChange(updatedRule);
    }
  }, [
    rule,
    initialValues,
    ruleConditionFormula,
    ruleCode,
    rulePayments,
  ]);

  const handleShowCustomCodeSelection = useCallback((toShow) => {
    if (!toShow) {
      setRuleCode(null);
    } else {
      setRuleCode('');
    }

    setShowCustomCodeSection(toShow);
  }, []);

  const handleChangeRulePayments = useCallback((payments) => {
    setRulePayments(payments);
  }, []);

  useEffect(() => {
    handleChange();
  }, [
    ruleConditionFormula,
    ruleCode,
    rulePayments,
  ]);

  const handleRemove = useCallback((event) => {
    event.stopPropagation();
    onRemove();
  }, [onRemove]);

  const ruleTitle = useMemo(() => (
    <span className={styles.ruleTitle}>{`${t('masterPlanSettingsPayoutRules.form.ruleTitle')} ${ruleNumberPosition}`}</span>
  ), [ruleNumberPosition]);

  const ruleContent = useMemo(() => (
    <>
      <div className={styles.sectionItem}>
        <FormItem
          className={styles.formItem}
          label={t('masterPlanSettingsPayoutRules.form.conditionTitle')}
        >
          <FormulaInput
            className={styles.inputField}
            value={ruleConditionFormula}
            placeholder={t('masterPlanSettingsPayoutRules.form.conditionPlaceholder')}
            type={FORMULA_INPUT_TYPES.FORMULA}
            helperData={helperData}
            onChange={(formula) => setRuleConditionFormula(formula)}
          />
        </FormItem>
        <div className={styles.fieldDisclaimer}>{t('masterPlanSettingsPayoutRules.form.conditionSubtitle')}</div>
        {
          ruleConditionFormula === '' && (
            <div className={styles.fieldError}>{t('masterPlanSettingsPayoutRules.form.conditionSubtitle.error.required')}</div>
          )
        }
      </div>
      {!showCustomCodeSection && (
        <>
          <div className={classNames({ sectionItem: true, centered: true })}>
            <Button
              className={styles.actionButton}
              type="link"
              icon={(<FunctionLine />)}
              onClick={() => handleShowCustomCodeSelection(true)}
            >
              {t('masterPlanSettingsPayoutRulesForm.form.switchToCode')}
            </Button>
          </div>
          <div className={styles.sectionItem}>
            <RulePayoutForm
              payments={rulePayments}
              helperData={helperData}
              onChange={handleChangeRulePayments}
              ruleId={rule.id}
            />
          </div>
        </>
      )}
      {showCustomCodeSection && (
        <div className={styles.sectionItem}>
          <div className={classNames({ fieldTitle: true, withExtra: true })}>
            {t('masterPlanSettingsPayoutRulesForm.form.ruleCode.title')}
            <Button
              className={classNames({ actionButton: true })}
              type="link"
              icon={<ResetLine className={styles.actionIcon} />}
              onClick={() => handleShowCustomCodeSelection(false)}
            >
              {t('masterPlanSettingsPayoutRulesForm.form.backToNormal')}
            </Button>
          </div>
          <FormulaInput
            className={styles.inputField}
            value={ruleCode}
            placeholder={t('masterPlanSettingsPayoutRulesForm.form.codePlaceholder')}
            type={FORMULA_INPUT_TYPES.FORMULA}
            componentType="textarea"
            helperData={helperData}
            onChange={(code) => setRuleCode(code)}
          />
          <div className={styles.fieldDisclaimer}>{t('masterPlanSettingsPayoutRules.form.ruleCode.disclaimer')}</div>
          {
            ruleCode === '' && (
              <div className={styles.fieldError}>{t('masterPlanSettingsPayoutRules.form.ruleCode.error.required')}</div>
            )
          }
        </div>
      )}
    </>
  ), [
    ruleConditionFormula,
    rulePayments,
    helperData,
    showCustomCodeSection,
    ruleCode,
    handleShowCustomCodeSelection,
  ]);

  const ruleExtra = useMemo(() => (
    <Tooltip title={t('masterPlanSettingsPayoutRules.form.ruleRemoveTooltip')}>
      <Button
        className={styles.actionButton}
        type="link"
        icon={<TrashFilled className={styles.actionTrashIcon} />}
        onClick={(event) => handleRemove(event)}
      />
    </Tooltip>
  ), [handleRemove]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      id={`rule-payout-offset-${ruleNumberPosition - 1}`}
    >
      <Collapse
        className={styles.collapse}
        panels={[
          {
            title: ruleTitle,
            content: ruleContent,
            extra: ruleExtra,
          },
        ]}
        keyValue={`collapse-payout-rule-${ruleNumberPosition}`}
        defaultActiveKey={openedCollapses[ruleNumberPosition - 1] && `collapse-payout-rule-${ruleNumberPosition}`}
        onChange={onCollapseChange}
      />
    </div>
  );
};

MasterPlanSettingsPayoutRulesFormRule.propTypes = {
  className: PropTypes.string,
  rule: PayoutRuleModel.propTypes,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  ruleNumberPosition: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onCollapseChange: PropTypes.func,
  openedCollapses: PropTypes.array,
};

MasterPlanSettingsPayoutRulesFormRule.defaultProps = {
  className: '',
  rule: {},
  onChange: () => {},
  onRemove: () => {},
  onCollapseChange: () => {},
  openedCollapses: [],
};

export default MasterPlanSettingsPayoutRulesFormRule;
