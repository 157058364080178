import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import QuotaOverwriteIndicator from '@palette/components/quota/QuotaOverwriteIndicator/QuotaOverwriteIndicator';
import QuotaLockedIndicator from '@palette/components/quota/QuotaLockedIndicator/QuotaLockedIndicator';
import { InputInCellRefForwarded as InputInCell } from '@palette/components/designSystem/InputInCell/InputInCell';
import RefreshLight from '@palette/components/utils/Icons/RefreshLight';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { useCorrespondingPlanUser } from '@palette/hooks/QuotaHooks';

import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import {
  getUserTarget,
  quotaUserOutOfYearPeriod,
} from '@palette/helpers/QuotaHelper';

import { QUOTA_ACTIONS, QUOTA_TARGET_ORIGIN } from '@palette/constants/quotas';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './QuotaTableBodyCell.less';

const classNames = bindClassNames.bind(styles);

const QuotaTableBodyCell = ({
  className,
  quotaUserId,
  period,
  quotaId,
  year,
  locked,
}) => {
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const dispatch = useDispatch();

  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));
  const quotaUser = useSelector((state) => QuotasSelectors.getQuotaUserByIdAndQuotaId(state, { quotaId, quotaUserId }));

  const correspondingPlanUser = useCorrespondingPlanUser(quotaUserId);

  const userTarget = useMemo(() => getUserTarget(quota, quotaUser, year, period), [quota, quotaUser, year, period]);

  const [target, setTarget] = useState(userTarget.target);

  useEffect(() => {
    setTarget(userTarget.target);
  }, [userTarget]);

  const hasOverwrite = useMemo(() => (userTarget.origin === QUOTA_TARGET_ORIGIN.USER_OVERWRITE), [userTarget.origin]);

  const overwriteIndicator = useMemo(() => {
    if (hasOverwrite) {
      return (<QuotaOverwriteIndicator />);
    }
    return null;
  }, [hasOverwrite]);

  const lockedIndicator = useMemo(() => {
    if (locked) {
      return (<QuotaLockedIndicator className={styles.lockedIndicator} />);
    }

    return null;
  }, [locked]);

  const handleInputFocus = () => {
    inputRef?.current?.select();
  };

  const handleInputChange = (newValue) => {
    setTarget(newValue);
  };

  const saveUpdate = useCallback(() => {
    if (userTarget.target !== target) {
      dispatch(QuotasActions.updateUserFromQuota({ quotaId, quotaUser, overwrite: { year, period, target }, action: QUOTA_ACTIONS.UPDATE_USER_OVERWRITE }));
    }
  }, [userTarget, target, quotaId, quotaUser, year, period]);

  const handleInputPressEnter = () => {
    inputRef?.current?.blur();
  };

  const bodyNode = useMemo(() => {
    let notInQuotaLabel = null;
    const userJoinDate = quotaUser.user.joinDate;
    const userLeaveDate = quotaUser.user.leaveDate;

    if (quotaUserOutOfYearPeriod(userJoinDate, quota, year, period)) {
      notInQuotaLabel = t('quotaTableBodyCell.notOnboardedYet.tooltip');
    }

    if (notInQuotaLabel === null && quotaUserOutOfYearPeriod(userLeaveDate, quota, year, period, true)) {
      notInQuotaLabel = t('quotaTableBodyCell.offboarded.tooltip');
    }

    if (notInQuotaLabel === null && correspondingPlanUser !== null) {
      if (quotaUserOutOfYearPeriod(correspondingPlanUser.startDate, quota, year, period)) {
        notInQuotaLabel = t('quotaTableBodyCell.notInPlanYet.tooltip');
      }

      if (notInQuotaLabel === null && quotaUserOutOfYearPeriod(correspondingPlanUser.endDate, quota, year, period, true)) {
        notInQuotaLabel = t('quotaTableBodyCell.leftThePlan.tooltip');
      }
    }

    if (notInQuotaLabel !== null) {
      return (
        <Tooltip title={notInQuotaLabel}>
          <div
            className={classNames({
              cellContent: !locked,
            })}
          >
            -
          </div>
        </Tooltip>
      );
    }

    if (locked) {
      return comaSeparatorFormatter(floatToFixedNumber(target));
    }

    return (
      <InputInCell
        ref={inputRef}
        className={styles.input}
        type="comaSeparatorFormatted"
        controls={false}
        value={target}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={handleInputPressEnter}
      />
    );
  }, [userTarget, target, quotaId, quota, quotaUser, year, period, locked, correspondingPlanUser]);

  const handleResetOverwrite = () => {
    dispatch(QuotasActions.updateUserFromQuota({ quotaId, quotaUser, overwrite: { year, period, target: null }, action: QUOTA_ACTIONS.RESET_USER_OVERWRITE }));
  };

  let resetOverwriteBtnNode = null;
  if (!locked && hasOverwrite) {
    resetOverwriteBtnNode = (
      <Tooltip title={t('quotaTableBodyCell.resetOverwriteBtn.tooltip')}>
        <Button
          className={styles.resetOverwriteBtn}
          icon={(<RefreshLight className={styles.resetOverwriteBtnIcon} width={16} height={16} />)}
          onClick={handleResetOverwrite}
        />
      </Tooltip>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        locked,
        [className]: className !== '',
      })}
    >
      {lockedIndicator}
      {overwriteIndicator}
      {resetOverwriteBtnNode}
      {bodyNode}
    </div>
  );
};

QuotaTableBodyCell.propTypes = {
  className: PropTypes.string,
  quotaUserId: PropTypes.string.isRequired,
  period: PropTypes.number.isRequired,
  quotaId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  locked: PropTypes.bool.isRequired,
};

QuotaTableBodyCell.defaultProps = {
  className: '',
};

export default QuotaTableBodyCell;
