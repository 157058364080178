import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import StatementV3PeriodStatus from '@palette/components/statementV3/StatementV3PeriodStatus/StatementV3PeriodStatus';
import OngoingBulkMarkAsPaidStatus from '@palette/components/statement/statementPeriodUsers/OngoingBulkMarkAsPaidStatus/OngoingBulkMarkAsPaidStatus';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { usePeriodsDatesFilter } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';
import { useStatementsPeriodStatus } from '@palette/helpers/StatementV3Helper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_PERIOD_STATUSES } from '@palette/constants/statements';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import * as StatementV3PeriodModel from '@palette/models/StatementV3Period';
import * as StatementV3ActionModel from '@palette/models/StatementV3Action';

import { selectors as StatementV3Selectors, actions as StatementV3Actions } from '@palette/state/StatementV3';

import styles from './StatementV3MarkAllAsPaidAction.less';

const classNames = bindClassNames.bind(styles);

const StatementV3MarkAllAsPaidAction = ({ className, statementPeriod, statements, currency, disabled }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const addMultipleActionsIsPending = useSelector(StatementV3Selectors.addMultipleActionsIsPending);
  const ongoingBulkMarkAsPaidStatus = useSelector(StatementV3Selectors.getOngoingBulkMarkAsPaidStatus);

  const statementPeriodStatus = useStatementsPeriodStatus(statementPeriod);

  const [, from, to] = usePeriodsDatesFilter();

  const markableUserStatements = useMemo(() => (
    statements.filter((statement) => !statement.actions.some((action) => action.type === STATEMENT_ACTION_TYPES.PAYMENT))
  ), [statements]);

  const handleMarkAllAsPaid = useCallback(() => {
    const statementIds = markableUserStatements.map((statement) => statement.id);

    dispatch(StatementV3Actions.addMultipleActions({
      statementPeriodIds: statementIds,
      type: STATEMENT_ACTION_TYPES.PAYMENT,
      currency,
      from,
      to,
    }));
  }, [markableUserStatements, currency, from, to]);

  const contentNode = useMemo(() => {
    if (
      statementPeriodStatus === STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAYMENT_REQUIRED
    ) {
      let btnLabel = t('statementPeriodUsers.markAllAsPaid.button.label');
      if (addMultipleActionsIsPending && ongoingBulkMarkAsPaidStatus !== null) {
        btnLabel = t('statementPeriodUsers.markAllAsPaid.button.processingLabel', { currentIndex: ongoingBulkMarkAsPaidStatus.index + 1, nbToProcess: ongoingBulkMarkAsPaidStatus.statementPeriodIds.length });
      }

      return (
        <Popconfirm
          title={t('statementPeriodUsers.markAllAsPaid.button.popconfirm')}
          onConfirm={handleMarkAllAsPaid}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
        >
          <Button
            type="primary"
            icon={addMultipleActionsIsPending ? <LoadingLine spin /> : <StatementsFilled />}
            disabled={
              addMultipleActionsIsPending
              || markableUserStatements.length === 0
              || disabled
            }
          >
            {btnLabel}
          </Button>
        </Popconfirm>
      );
    }

    return (
      <StatementV3PeriodStatus statementV3PeriodStatus={statementPeriodStatus} />
    );
  }, [
    statementPeriod,
    statementPeriodStatus,
    addMultipleActionsIsPending,
    disabled,
    handleMarkAllAsPaid,
    markableUserStatements,
    ongoingBulkMarkAsPaidStatus,
  ]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      <OngoingBulkMarkAsPaidStatus />
    </div>
  );
};

StatementV3MarkAllAsPaidAction.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3PeriodModel.propTypes.isRequired,
  statements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    fromDate: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    userId: PropTypes.string,
    correctionsCount: PropTypes.number,
    actions: PropTypes.arrayOf(StatementV3ActionModel.propTypes),
  })),
  currency: PropTypes.string,
  disabled: PropTypes.bool,
};

StatementV3MarkAllAsPaidAction.defaultProps = {
  className: '',
  statements: [],
  currency: null,
  disabled: false,
};

export default StatementV3MarkAllAsPaidAction;
