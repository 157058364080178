import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getBackRoute, getBackRouteName, popLatestBackRoute } from '../services/utils';

class BackLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeUrl: null,
      routeName: null,
    };
  }

  componentDidMount() {
    const { defaultUrl } = this.props;
    this.setState({
      routeUrl: getBackRoute(this.props.location.pathname, defaultUrl),
      routeName: getBackRouteName(this.props.location.pathname, defaultUrl),
    });
  }

  render() {
    const { routeUrl, routeName } = this.state;
    const { style } = this.props;
    return (
      <a href={routeUrl} onClick={popLatestBackRoute} style={style}>
        <ArrowLeftOutlined />
        {routeName ? ' Back to ' : ' Back'}
        <span className="capialize">
          {routeName}
        </span>
      </a>
    );
  }
}

BackLink.propTypes = {
  location: PropTypes.object.isRequired,
  defaultUrl: PropTypes.string,
  style: PropTypes.object,
};

BackLink.defaultProps = {
  defaultUrl: '/',
  style: {},
};

export default withRouter(BackLink);
