import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Popover from '@palette/components/designSystem/Popover/Popover';
import UsersStatementsApprovalsListItem from '@palette/components/statement/statementPeriodUsers/UsersStatementsApprovalsListItem/UsersStatementsApprovalsListItem';

import * as UserStatementApprovalModel from '@palette/models/UserStatementApproval';

import styles from './UsersStatementsApprovalsListMoreAvatar.less';

const classNames = bindClassNames.bind(styles);

const UsersStatementsApprovalsListMoreAvatar = ({ className, approvals }) => {
  const listNode = useMemo(() => approvals.map((approval, index) => (
    <UsersStatementsApprovalsListItem
      key={`approval-${index}`}
      approval={approval}
      type="inline"
    />
  )), [approvals]);

  const contentNode = useMemo(() => (
    <Popover
      trigger="hover"
      placement="right"
      content={listNode}
    >
      <div className={styles.counter}>
        {`+${approvals.length}`}
      </div>
    </Popover>
  ), [approvals, listNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UsersStatementsApprovalsListMoreAvatar.propTypes = {
  className: PropTypes.string,
  approvals: PropTypes.arrayOf(UserStatementApprovalModel.propTypes).isRequired,
};

UsersStatementsApprovalsListMoreAvatar.defaultProps = {
  className: '',
};

export default UsersStatementsApprovalsListMoreAvatar;
