import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DragLine from '@palette/components/utils/Icons/DragLine';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import AddDashboardWidgetDrawer from '@palette/components/ic/myDashboard/AddDashboardWidgetDrawer/AddDashboardWidgetDrawer';

import { DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY, DASHBOARD_WIDGETS } from '@palette/constants/dashboard';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import { actions as DashboardPresetsActions } from '@palette/state/DashboardPresets';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './DashboardPresetWidgets.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetWidgets = ({ className, dashboardPreset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ## Drag & Drop management ## */
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const widgetIndex = result.source.index;
    const toPositionIndex = result.destination.index;

    dispatch(DashboardPresetsActions.moveWidget({ presetId: dashboardPreset.id, widgetIndex, toPositionIndex }));
  };

  const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  /* ## Actions definition ## */
  const handleRemoveWidget = useCallback((widgetIndex) => {
    dispatch(DashboardPresetsActions.removeWidget({ presetId: dashboardPreset.id, widgetIndex }));
  }, [dashboardPreset]);

  const widgetsNodes = useMemo(() => (
    dashboardPreset.dashboardComponents.map((dashboardComponent, index) => (
      <Draggable key={index} draggableId={`widget_${index}`} index={index}>
        {(providedDraggable) => (
          <div
            className={styles.widgetsListItem}
            ref={providedDraggable.innerRef}
            {...providedDraggable.draggableProps}
            {...providedDraggable.dragHandleProps}
            style={getItemStyle(
              providedDraggable.draggableProps.style,
            )}
          >
            <div className={styles.widgetWrapper}>
              <DragLine className={styles.dragIcon} width={24} height={24} />
              <div className={styles.widgetNameDescriptionWrapper}>
                <div className={styles.widgetName}>
                  {t(DASHBOARD_WIDGETS[dashboardComponent.type].nameI18NId)}
                </div>
                <div className={styles.widgetDescription}>
                  {t(DASHBOARD_WIDGETS[dashboardComponent.type].descriptionI18NId)}
                </div>
              </div>
              <Button
                className={styles.removeButton}
                type="primary"
                shape="circle"
                onClick={() => handleRemoveWidget(index)}
                icon={<ClosePopupFilled width={18} height={18} />}
              />
            </div>
          </div>
        )}
      </Draggable>
    ))
  ), [dashboardPreset]);

  const handleOpenAddWidgetDrawer = useCallback(() => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY]: 'true' } }));
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.widgetsTitle}>
        {t('dashboardPresetDetailsPageContent.widgets.title', { count: dashboardPreset.dashboardComponents.length })}
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="widgetsDroppable">
          {
            (providedDroppable) => (
              <div
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                <div className={styles.widgetsList}>
                  {widgetsNodes}
                  {providedDroppable.placeholder}
                </div>
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
      <Button
        className={styles.addWidgetButton}
        type="primary"
        icon={<AddFilled />}
        onClick={handleOpenAddWidgetDrawer}
      >
        {t('dashboardPresetDetailsPageContent.widgets.addWidget')}
      </Button>
      <AddDashboardWidgetDrawer forDashboardPreset />
    </div>
  );
};

DashboardPresetWidgets.propTypes = {
  className: PropTypes.string,
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
};

DashboardPresetWidgets.defaultProps = {
  className: '',
};

export default DashboardPresetWidgets;
