import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './DeleteMasterPlanUserModal.less';

const DeleteMasterPlanUserModal = ({ visible, onClose, user, planId, forManagers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const onSuccessCB = useCallback(() => {
    cleanAndClose();
  }, [cleanAndClose]);

  const handleConfirmation = useCallback(() => {
    dispatch(MasterPlansActions.removeUsersFromPlan({
      usersToRemove: [user],
      planId,
      forManagers,
      onSuccessCB,
    }));
  }, [
    user,
    planId,
    forManagers,
    onSuccessCB,
  ]);

  const userName = user.displayName;
  return (
    <Modal
      className={styles.modal}
      title={t('masterPlanSettings.Users.deleteMasterPlanUserModal.title', { userName })}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleConfirmation}
      okText={t('masterPlanSettings.Users.deleteMasterPlanUserModal.confirm')}
      okTheme="danger"
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('masterPlanSettings.Users.deleteMasterPlanUserModal.content') }} />
    </Modal>
  );
};

DeleteMasterPlanUserModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  user: MetaUserModel.propTypes.isRequired,
  planId: PropTypes.string.isRequired,
  forManagers: PropTypes.bool.isRequired,
};

DeleteMasterPlanUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default DeleteMasterPlanUserModal;
