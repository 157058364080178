import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import CommissionV3DrawerPaymentsTab from '@palette/components/commissionV3/CommissionV3DrawerPaymentsTab/CommissionV3DrawerPaymentsTab';

import { COMMISSION_V3_DRAWER_QS_KEY, COMMISSION_V3_DRAWER_TABS_IDS } from '@palette/constants/tabs';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import styles from './CommissionV3DrawerTabs.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3DrawerTabs = ({ className, commission }) => {
  const { t } = useTranslation();

  const tabs = useMemo(() => ([
    {
      tabKey: COMMISSION_V3_DRAWER_TABS_IDS.PAYMENTS,
      title: t('commissionDrawerTabs.payments'),
      titleCount: commission.payments.length || 0,
      content: <CommissionV3DrawerPaymentsTab commission={commission} />,
    },
  ]), [commission]);

  return (
    <Tabs
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      stretched
      tabs={tabs}
      qsTabKey={COMMISSION_V3_DRAWER_QS_KEY}
    />
  );
};

CommissionV3DrawerTabs.propTypes = {
  className: PropTypes.string,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
};

CommissionV3DrawerTabs.defaultProps = {
  className: '',
};

export default CommissionV3DrawerTabs;
