import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageDateAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

export const modelName = 'UserStatementCorrectionSummary';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  plan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
    frequency: PropTypes.number,
    customBeginAt: PropTypes.string,
    fiscalYearShift: PropTypes.number,
  }),
  deal: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  periodId: PropTypes.string,
  period: PropTypes.number,
  year: PropTypes.number,
  oldTotal: PropTypes.number,
  newTotal: PropTypes.number,
  currency: PropTypes.string,
});

export const managePlanAttribute = ({ plan }) => ({
  id: manageStringAttribute(plan, '_id'),
  name: manageStringAttribute(plan, 'name'),
  periodType: (PERIOD_TYPES[manageStringAttribute(plan, 'periodType')] || PERIOD_TYPES.MONTH),
  frequency: manageNumberAttribute(plan, 'frequency', 1),
  customBeginAt: manageDateAttribute(plan, 'beginAt'),
  fiscalYearShift: manageNumberAttribute(plan, 'fiscalYearShift', 0),
});

export const manageDealAttribute = ({ deal }) => ({
  id: manageStringAttribute(deal, '_id'),
  name: manageStringAttribute(deal, 'name'),
});

export const manageId = (userStatementCorrectionSummary) => (
  `${manageDealAttribute(userStatementCorrectionSummary).id}:${userStatementCorrectionSummary.periodId}`
);

export const transform = (userStatementCorrectionSummary) => {
  if (!userStatementCorrectionSummary) {
    return null;
  }

  return ({
    id: manageId(userStatementCorrectionSummary),
    plan: managePlanAttribute(userStatementCorrectionSummary),
    deal: manageDealAttribute(userStatementCorrectionSummary),
    periodId: manageStringAttribute(userStatementCorrectionSummary, 'periodId'),
    period: manageNumberAttribute(userStatementCorrectionSummary, 'period'),
    year: manageNumberAttribute(userStatementCorrectionSummary, 'year'),
    oldTotal: manageAmountAttribute(userStatementCorrectionSummary, 'oldTotal'),
    newTotal: manageAmountAttribute(userStatementCorrectionSummary, 'newTotal'),
    currency: manageStringAttribute(userStatementCorrectionSummary, 'currency'),
  });
};

export const transformList = (userStatementCorrectionSummaries) => userStatementCorrectionSummaries.map((userStatementCorrectionSummary) => transform(userStatementCorrectionSummary));
