/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import _cloneDeep from 'lodash/cloneDeep';
import { original } from 'immer';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';

import { IC_PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';
import * as MasterPlanModel from '@palette/models/MasterPlan';

/*
 * Initial State
 */
const initialState = {
  getDashboardPresetsListIsPending: false,
  getByIdIsPending: false,
  updateDashboardPresetIsPending: false,
  getPresetCandidateUsersIsPending: false,
  getPresetDataForCandidateUserIsPending: false,
  moveWidgetIsPending: false,
  removeWidgetIsPending: false,
  addWidgetIsPending: false,
  getPlanListForCandidateUserIsPending: false,
  deleteByIdIsPending: false,
  applyDashboardPresetToUsersIsPending: false,
  createDashboardPresetIsPending: false,

  list: [],
  byId: {},
  presetCandidateUsers: null,
  presetDataForCandidateUser: null,
  planListForCandidateByType: Object.values(IC_PLANS_PAGE_CONTENT_TABS_IDS)
    .reduce((res, type) => ({
      ...res,
      [type]: [],
    }), {}),
  planForCandidateById: {},
  planListForCandidateStats: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'dashboardPresets',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get List */
    getDashboardPresetsList: (state) => {
      state.getDashboardPresetsListIsPending = true;
    },
    setDashboardPresetsList: (state, { payload }) => {
      const { dashboardPresets = [] } = payload;
      state.list = _map(dashboardPresets, 'id');

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(dashboardPresets, 'id'),
        DashboardPresetModel.merge,
      );
    },
    getDashboardPresetsListCompleted: (state) => {
      state.getDashboardPresetsListIsPending = false;
    },
    /* Get By Id */
    getById: (state) => {
      state.getByIdIsPending = true;
    },
    setById: (state, { payload }) => {
      const { dashboardPreset } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      const inStateDashboardPreset = clonedById[dashboardPreset.id] || null;

      clonedById[dashboardPreset.id] = DashboardPresetModel.merge(inStateDashboardPreset, dashboardPreset);

      state.byId = clonedById;
    },
    getByIdCompleted: (state) => {
      state.getByIdIsPending = false;
    },
    /* Update dashboard preset before update call's result */
    preUpdateName: (state, { payload }) => {
      const { presetId, name } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      clonedById[presetId].name = name;

      state.byId = clonedById;
    },
    preUpdateDashboardComponents: (state, { payload }) => {
      const { presetId, dashboardComponents } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      clonedById[presetId].dashboardComponents = dashboardComponents;

      state.byId = clonedById;
    },
    /* Update dashboard preset */
    updateDashboardPreset: (state) => {
      state.updateDashboardPresetIsPending = true;
    },
    updateDashboardPresetCompleted: (state) => {
      state.updateDashboardPresetIsPending = false;
    },
    /* Create dashboard preset */
    createDashboardPreset: (state) => {
      state.createDashboardPresetIsPending = true;
    },
    createDashboardPresetCompleted: (state) => {
      state.createDashboardPresetIsPending = false;
    },
    /* Get preset candidate users */
    getPresetCandidateUsers: (state) => {
      state.getPresetCandidateUsersIsPending = true;
    },
    setPresetCandidateUsers: (state, { payload }) => {
      const { candidateUsers } = payload;

      state.presetCandidateUsers = _keyBy(candidateUsers, (user) => user.id);
    },
    getPresetCandidateUsersCompleted: (state) => {
      state.getPresetCandidateUsersIsPending = false;
    },
    /* Get dashboard preset data for candidate user */
    getPresetDataForCandidateUser: (state) => {
      state.getPresetDataForCandidateUserIsPending = true;
    },
    setPresetData: (state, { payload }) => {
      const { presetData } = payload;

      state.presetDataForCandidateUser = presetData;
    },
    getPresetDataForCandidateUserCompleted: (state) => {
      state.getPresetDataForCandidateUserIsPending = false;
    },
    /* Move Widget */
    moveWidget: (state) => {
      state.moveWidgetIsPending = true;
    },
    moveWidgetCompleted: (state) => {
      state.moveWidgetIsPending = false;
    },
    /* Remove Widget */
    removeWidget: (state) => {
      state.removeWidgetIsPending = true;
    },
    removeWidgetCompleted: (state) => {
      state.removeWidgetIsPending = false;
    },
    /* Add Widget */
    addWidget: (state) => {
      state.addWidgetIsPending = true;
    },
    addWidgetCompleted: (state) => {
      state.addWidgetIsPending = false;
    },
    /* Get plan list for candidate user */
    getPlanListForCandidateUser: (state) => {
      state.getPlanListForCandidateUserIsPending = true;
    },
    setPlanListForCandidateUser: (state, { payload }) => {
      const { activePlans = [], pastPlans = [], stats } = payload;

      state.planListForCandidateByType[IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE] = _map(activePlans, 'id');
      state.planListForCandidateByType[IC_PLANS_PAGE_CONTENT_TABS_IDS.PAST] = _map(pastPlans, 'id');

      state.planForCandidateById = _mergeWith(
        _cloneDeep(original(state.planForCandidateById)),
        _keyBy(activePlans.concat(pastPlans), 'id'),
        MasterPlanModel.merge,
      );

      state.planListForCandidateStats = stats;
    },
    getPlanListForCandidateUserCompleted: (state) => {
      state.getPlanListForCandidateUserIsPending = false;
    },
    /* Delete Dashboard Preset */
    deleteById: (state) => {
      state.deleteByIdIsPending = true;
    },
    deleteByIdCompleted: (state) => {
      state.deleteByIdIsPending = false;
    },
    /* Apply Dashboard Preset to Users */
    applyDashboardPresetToUsers: (state) => {
      state.applyDashboardPresetToUsersIsPending = true;
    },
    applyDashboardPresetToUsersCompleted: (state) => {
      state.applyDashboardPresetToUsersIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;

const getList = (state) => root(state).list;
const getById = (state) => root(state).byId;
const getPresetCandidateUsers = (state) => root(state).presetCandidateUsers;
const getPresetDataForCandidateUser = (state) => root(state).presetDataForCandidateUser;
const getMasterPlanListForCandidateByType = (state) => root(state).planListForCandidateByType;
const getPlanListForCandidateStats = (state) => root(state).planListForCandidateStats;
const getPlanForCandidateById = (state) => root(state).planForCandidateById;

const getByIdIsPending = (state) => root(state).getByIdIsPending;
const getDashboardPresetsListIsPending = (state) => root(state).getDashboardPresetsListIsPending;
const updateDashboardPresetIsPending = (state) => root(state).updateDashboardPresetIsPending;
const getPresetCandidateUsersIsPending = (state) => root(state).getPresetCandidateUsersIsPending;
const getPresetDataForCandidateUserIsPending = (state) => root(state).getPresetDataForCandidateUserIsPending;
const moveWidgetIsPending = (state) => root(state).moveWidgetIsPending;
const removeWidgetIsPending = (state) => root(state).removeWidgetIsPending;
const addWidgetIsPending = (state) => root(state).addWidgetIsPending;
const getPlanListForCandidateUserIsPending = (state) => root(state).getPlanListForCandidateUserIsPending;
const deleteByIdIsPending = (state) => root(state).deleteByIdIsPending;
const applyDashboardPresetToUsersIsPending = (state) => root(state).applyDashboardPresetToUsersIsPending;
const createDashboardPresetIsPending = (state) => root(state).createDashboardPresetIsPending;

const getDashboardPresetsList = createSelector(
  [getList, getById],
  (listIds, byId) => listIds.map((dashboardPresetId) => (byId[dashboardPresetId] || null)).filter(Boolean),
);

const getDashboardPresetById = createSelector(
  [getById, getProps],
  (byId, { presetId }) => byId[presetId] || null,
);

const getPresetCandidateUsersList = createSelector(
  [getPresetCandidateUsers],
  (presetCandidateUsers) => (presetCandidateUsers !== null ? Object.values(presetCandidateUsers) : null),
);

const getPresetCandidateUser = createSelector(
  [getPresetCandidateUsers, getProps],
  (presetCandidateUsers, { presetUserId }) => (presetCandidateUsers !== null ? presetCandidateUsers[presetUserId] || null : null),
);

const getPlansListForCandidateByType = createSelector(
  [getMasterPlanListForCandidateByType, getPlanForCandidateById, getProps],
  (listsByType, byId, { listType = IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE }) => listsByType[listType].map((planId) => byId[planId] || null).filter(Boolean),
);

const getMasterPlanForCandidateById = createSelector(
  [getPlanForCandidateById, getProps],
  (byId, { masterPlanId }) => byId[masterPlanId] || null,
);

export const selectors = {
  getPresetDataForCandidateUser,
  getPlanListForCandidateStats,

  getDashboardPresetsListIsPending,
  getByIdIsPending,
  updateDashboardPresetIsPending,
  getPresetCandidateUsersIsPending,
  getPresetDataForCandidateUserIsPending,
  moveWidgetIsPending,
  removeWidgetIsPending,
  addWidgetIsPending,
  getPlanListForCandidateUserIsPending,
  deleteByIdIsPending,
  applyDashboardPresetToUsersIsPending,
  createDashboardPresetIsPending,

  getDashboardPresetsList,
  getDashboardPresetById,
  getPresetCandidateUsersList,
  getPresetCandidateUser,
  getPlansListForCandidateByType,
  getMasterPlanForCandidateById,
};
