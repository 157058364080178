import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import MasterPlanSettingsCommissionRulesForm from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesForm/MasterPlanSettingsCommissionRulesForm';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';
import ResourceObjectSample from '@palette/components/resources/ResourceObjectSample/ResourceObjectSample';
import CreatePlanFlowPreviewCommissions from '@palette/components/masterPlanCreation/CreatePlanFlowPreviewCommissions/CreatePlanFlowPreviewCommissions';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './CreatePlanFlowCommissionRulesStep.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowCommissionRulesStep = ({ className, plan }) => {
  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample);

  const [rules, setRules] = useState([]);
  const [saveCommissionRulesCB, setSaveCommissionRulesCB] = useState(null);

  const handleNextStep = useCallback((goToNextStep) => {
    saveCommissionRulesCB(goToNextStep);
  }, [saveCommissionRulesCB]);

  const handleSetSaveCommissionRulesCB = useCallback((saveRulesCB) => {
    setSaveCommissionRulesCB(() => saveRulesCB);
  }, []);

  const previewCommissionsNode = useMemo(() => {
    if (rules.length === 0) return null;

    return (
      <CreatePlanFlowPreviewCommissions plan={plan} onPreview={handleNextStep} />
    );
  }, [
    rules,
    plan,
    handleNextStep,
  ]);

  const actionsNode = useMemo(() => (
    <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.COMMISSION_RULES.value} onNextStep={handleNextStep} />
  ), [plan, handleNextStep]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.formWrapper}>
        <MasterPlanSettingsCommissionRulesForm
          plan={plan}
          helperData={formulaHelperData}
          inPlanCreationFlow
          inPlanCreationFlowSetSaveCB={handleSetSaveCommissionRulesCB}
          inPlanCreationFlowOnRulesUpdate={setRules}
        />
        {previewCommissionsNode}
        {actionsNode}
      </div>
      <ResourceObjectSample className={styles.resourceObjectSample} helperData={formulaHelperData} />
    </div>
  );
};

CreatePlanFlowCommissionRulesStep.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowCommissionRulesStep.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowCommissionRulesStep;
