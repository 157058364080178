import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import StatementV3EditUserStatementAdjustmentButton from '@palette/components/statementV3/StatementV3EditUserStatementAdjustmentButton/StatementV3EditUserStatementAdjustmentButton';

import { useStatementUserIdInParams } from '@palette/hooks/StatementHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { isUserStatementPaid } from '@palette/helpers/StatementV3Helper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';
import * as StatementV3ListCorrectionModel from '@palette/models/StatementV3ListCorrection';

import styles from './StatementV3UserStatementAdjustmentsItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementAdjustmentsItem = ({ className, statement, correction, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();
  const profile = useProfile();

  const { userId } = useStatementUserIdInParams();

  const [deleteIsPending, setDeleteIsPending] = useState(false);

  const deleteCorrectionsIsPending = useSelector(StatementV3Selectors.deleteCorrectionsIsPending);

  const isStatementPaid = useMemo(() => isUserStatementPaid(statement), [statement]);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const deleteCorrection = useCallback((event) => {
    stopPropagation(event);
    setDeleteIsPending(true);

    dispatch(StatementV3Actions.correctionDelete({
      statementId: statement.id,
      correctionId: correction.id,
      currency,
      statementCurrency: statement.currency,
    }));
  }, [
    statement,
    correction,
    userId,
    currency,
  ]);

  useEffect(() => {
    if (!deleteCorrectionsIsPending && deleteIsPending) {
      setDeleteIsPending(false);
    }
  }, [deleteCorrectionsIsPending, deleteIsPending]);

  const actionsNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.ADJUSTMENTS.MANAGE]) || isStatementPaid) return null;

    return (
      <div className={styles.actions}>
        <StatementV3EditUserStatementAdjustmentButton
          currency={currency}
          statement={statement}
          correction={correction}
          disabled={deleteCorrectionsIsPending}
        />
        <Popconfirm
          title={t('userStatementAdjustments.actions.delete.popconfirm')}
          onConfirm={(event) => deleteCorrection(event)}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
        >
          <Button
            className={styles.deleteButton}
            type="linkDestroy"
            icon={deleteCorrectionsIsPending && deleteIsPending ? <LoadingLine spin /> : <TrashFilled width={18} height={18} />}
            disabled={deleteCorrectionsIsPending}
          >
            {t('actions.remove')}
          </Button>
        </Popconfirm>
      </div>
    );
  }, [
    isStatementPaid,
    profile,
    statement,
    currency,
    correction,
    deleteCorrection,
    deleteCorrectionsIsPending,
    deleteIsPending,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.colRow}>
        <span className={styles.details}>
          {correction.reason !== '' ? correction.reason : '-'}
        </span>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {formatPrice(correction.amount, correction.currency)}
        </span>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {correction.date && (
            moment.utc(correction.date).format('LL')
          )}
          {!correction.date && '-'}
        </span>
        {actionsNode}
      </div>
    </div>
  );
};

StatementV3UserStatementAdjustmentsItem.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  correction: StatementV3ListCorrectionModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementV3UserStatementAdjustmentsItem.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3UserStatementAdjustmentsItem;
