import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import HighlightZone from '@palette/components/highlight/HighlightZone/HighlightZone';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useMasterPlanPeriodsFilters } from '@palette/hooks/MasterPlanHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MyMasterPlanHighlightZone.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanHighlightZone = ({ className, plan }) => {
  const dispatch = useDispatch();
  const profile = useProfile();

  const [periodsFilterType, customFilterPeriods] = useMasterPlanPeriodsFilters(plan, false);

  const planDashboardIsPending = useSelector(MasterPlansSelectors.getPlanDashboardIsPending);
  const masterPlanDashboard = useSelector((state) => MasterPlansSelectors.getMasterPlanDashboard(state, { masterPlanId: plan.id }));

  const getMasterPlanWidgetsData = useCallback(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return;

    dispatch(MasterPlansActions.getICPlanDashboard({ planId: plan.id, periodsFilterType, customPeriods: customFilterPeriods }));
  }, [plan, periodsFilterType, customFilterPeriods]);

  useEffect(() => {
    if (plan === null) return;

    getMasterPlanWidgetsData();
  }, [plan, periodsFilterType, customFilterPeriods]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <HighlightZone loading={planDashboardIsPending} data={masterPlanDashboard} forIC />
    </div>
  );
};

MyMasterPlanHighlightZone.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MyMasterPlanHighlightZone.defaultProps = {
  className: '',
};

export default MyMasterPlanHighlightZone;
