import { linearGradientDef } from '@nivo/core';

export const CHART_GRADIENT_BLUE = {
  id: 'GRADIENT_BLUE',
  colors: [
    { offset: 0, color: '#88C7F8' },
    { offset: 100, color: '#5BAAE8' },
  ],
};

export const CHART_GRADIENT_GREEN = {
  id: 'GRADIENT_GREEN',
  colors: [
    { offset: 0, color: '#AAF2AD' },
    { offset: 100, color: '#88D98C' },
  ],
};

export const CHART_GRADIENT_ORANGE = {
  id: 'GRADIENT_ORANGE',
  colors: [
    { offset: 0, color: '#FFC761' },
    { offset: 100, color: '#FFAD6B' },
  ],
};

export const CHART_GRADIENT_RED = {
  id: 'GRADIENT_RED',
  colors: [
    { offset: 0, color: '#FF8E75' },
    { offset: 100, color: '#FC6C71' },
  ],
};

export const CHART_GRADIENT_TRANSPARENT = {
  id: 'GRADIENT_TRANSPARENT',
  colors: [
    { offset: 0, color: 'transparent' },
    { offset: 100, color: 'transparent' },
  ],
};

export const gradientsDefs = [
  linearGradientDef(CHART_GRADIENT_BLUE.id, CHART_GRADIENT_BLUE.colors),
  linearGradientDef(CHART_GRADIENT_GREEN.id, CHART_GRADIENT_GREEN.colors),
  linearGradientDef(CHART_GRADIENT_ORANGE.id, CHART_GRADIENT_ORANGE.colors),
  linearGradientDef(CHART_GRADIENT_RED.id, CHART_GRADIENT_RED.colors),
  linearGradientDef(CHART_GRADIENT_TRANSPARENT.id, CHART_GRADIENT_TRANSPARENT.colors),
];

export const TRACKS_COLOR = '#F6F8FA';

export const MARKER_COLOR = 'rgba(166,182,201,0.4)';

export const TEXT_STYLE_CAPTION_1 = {
  fontFamily: 'Satoshi',
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: '1.2rem',
};

export const COLOR_NEUTRALS_GREY_2 = '#A6B6C9';
export const COLOR_PRIMARY_BLUE = '#128BEB';

export const LABEL_TEXT_COLOR_WHITE = '#FFFFFF';

export const BAR_LABEL_THEME = {
  labels: {
    text: {
      fontWeight: 'bold',
      fontSize: '14',
    },
  },
};
