import React, { Component } from 'react';
import pluralize from 'pluralize';
import Papa from 'papaparse';
import {
  Button, Divider, Input, message, Modal, Select,
  Card,
} from 'antd';
import {
  PlusOutlined, UploadOutlined, FileTextOutlined, DeleteOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import '../../styles/admin/inviteTeam.less';
import { updateConnector, uploadObjects } from '../../services/connector';
import { slugify } from '../../services/utils';
import { getProfile } from '../../services/user';
import { sendClickOnDocLink } from '../../services/analytics';
import { ANALYTICS_DOC_SOURCES } from '../../services/constants';

const formatField = (field) => slugify(field.toLowerCase()).trim().replace(/-/g, '_').replace(/[^a-z0-9_]/g, '');

class UploadCSVModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      objects: null,
      fields: null,
      types: props.connector.objects.map((o) => o.type),
      type: '',
      name: '',
    };
    this.InputFileRef = React.createRef();
  }

  addItem = () => {
    const { types, name } = this.state;
    if (!name) return;
    this.setState({
      types: [...types, name],
      name: '',
    });
  };

  onFileChange = ({ currentTarget: { files: [file] } }) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (e) => {
        const str = e.target.result;
        const result = Papa.parse(str, {
          skipEmptyLines: true,
          transform: (val) => {
            if (val.toLowerCase() === 'id') return 'id';

            return val;
          },
        });
        result.meta.fields = result.data.shift();
        result.meta.formatedFields = result.meta.fields.map(formatField);
        const duplicateField = result.meta.fields
          .find((field, index, array) => array.indexOf(field) !== index);
        if (duplicateField) {
          message.error(`Duplicate column ${duplicateField}`);
          this.InputFileRef.current.value = '';
          return;
        }
        if (result.errors.length > 0) {
          message.error(`${result.errors[0].message} - Row #${result.errors[0].row}`);
          this.InputFileRef.current.value = '';
          return;
        }
        if (!result.meta.fields.includes('id')) {
          message.error('Your CSV file must have an "id" column');
          this.InputFileRef.current.value = '';
          return;
        }
        if (this.state.type === 'User') {
          if (!result.meta.fields.includes('first_name')) {
            message.error('Users must have an "first_name" column');
            this.InputFileRef.current.value = '';
            return;
          }
          if (!result.meta.fields.includes('last_name')) {
            message.error('Users must have an "last_name" column');
            this.InputFileRef.current.value = '';
            return;
          }
        }
        const objects = result.data.map((values) => (
          result.meta.formatedFields.reduce((obj, field, index) => ({
            ...obj,
            [field]: values[index],
          }), {})
        )).filter((o) => !!o.id);
        const hasDuplicateId = Object.values(objects.reduce((obj, { id }) => ({
          ...obj,
          [id]: (obj[id] || 0) + 1,
        }), {}))
          .filter((x) => x > 1).length > 0;
        if (hasDuplicateId) {
          message.error('"id" column contains duplicate ids');
          this.InputFileRef.current.value = '';
          return;
        }
        this.setState({
          objects,
          fields: result.meta.fields,
        });
      };
      reader.onerror = () => {
        message.error('Error reading file');
      };
    }
  };

  upload = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, async () => {
      try {
        const hasObject = this.props.connector.objects
          .find((o) => o.type === this.state.type);
        if (!hasObject) {
          await updateConnector({
            connectorId: this.props.connector._id,
            objects: this.props.connector.objects.concat([{
              type: this.state.type,
              columns: this.state.fields.slice(0, 7).map((field, index) => ({
                name: field,
                fields: [formatField(field)],
                formatter: 'String',
                resources: true,
                plans: index < 3,
              })),
            }]),
          });
        }
        await uploadObjects(
          this.props.connector._id,
          this.state.type,
          this.state.objects,
        );
        if (!hasObject) await getProfile();
        this.setState({ loading: false });
        this.props.ok();
      } catch (error) {
        message.error('Error while uploading data.');
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const {
      objects,
      fields,
      types,
      type,
      name,
      loading,
    } = this.state;
    return (
      <Modal
        title="Upload CSV Data"
        visible={this.props.visible}
        onCancel={this.props.close}
        className="center"
        footer={(
          <div className="flex" style={{ alignItems: 'center' }}>
            <div style={{ flexGrow: '1', justifyContent: 'flex-start', textAlign: 'left' }}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://doc.palettehq.com/upload_csv"
                style={{ width: '100%' }}
                onClick={() => { sendClickOnDocLink(ANALYTICS_DOC_SOURCES.UPLOAD_CSV_MODAL, this.props.profile); }}
              >
                <FileSearchOutlined />
                {' '}
                How to upload a CSV
              </a>
            </div>

            <Button key="back" onClick={this.props.close}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              disabled={!objects || !type}
              onClick={this.upload}
              icon={<UploadOutlined />}
              loading={loading}
            >
              {`Upload${objects ? ` ${pluralize('object', objects.length, true)}` : ''}`}
            </Button>
          </div>
        )}
      >
        <div style={{ marginBottom: '12px' }}>
          Select the type of resource you're about to upload:
        </div>
        <Select
          style={{ width: 240 }}
          placeholder="Select Type"
          value={type}
          onChange={(v) => this.setState({ type: v })}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  padding: 8,
                }}
              >
                <Input
                  style={{ flex: 'auto' }}
                  value={name}
                  placeholder="ex: Deal, Activity, ..."
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                  onClick={this.addItem}
                >
                  <PlusOutlined />
                  {' Add Type'}
                </a>
              </div>
            </div>
          )}
        >
          {types.map((t) => (
            <Select.Option key={t}>
              {t}
            </Select.Option>
          ))}
        </Select>
        <br />
        <br />
        {type && (
          objects ? (
            <Card>
              <div>
                {`Found ${objects.length} rows with fields ${fields.map((f) => `"${f}"`)
                  .join(', ')} to upload.`}
              </div>
              <br />
              <Button
                type="danger"
                onClick={() => this.setState({ objects: null })}
                icon={<DeleteOutlined />}
                ghost
              >
                delete
              </Button>
            </Card>
          ) : (
            <>
              <Button
                type="link"
                icon={<FileTextOutlined />}
                onClick={() => this.InputFileRef.current.click()}
              >
                Click to upload your .csv file
              </Button>
              <input
                ref={this.InputFileRef}
                style={{ display: 'none' }}
                accept=".csv"
                type="file"
                onChange={this.onFileChange}
              />
            </>
          )
        )}
      </Modal>
    );
  }
}

UploadCSVModal.propTypes = {
  connector: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  ok: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(UploadCSVModal);
