import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Select from '@palette/components/designSystem/Select/Select';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';

import { QUOTA_TARGET_STRATEGIES, SCOPES } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './QuotaTargetStrategySelect.less';

const classNames = bindClassNames.bind(styles);

const QuotaTargetStrategySelect = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planQuotaTargetStrategyKey = useMemo(() => (
    Object.keys(QUOTA_TARGET_STRATEGIES).find((strategyKey) => QUOTA_TARGET_STRATEGIES[strategyKey] === plan.quotaTargetStrategy)
  ), [plan]);

  const strategiesOptions = useMemo(() => (
    Object.keys(QUOTA_TARGET_STRATEGIES).map((strategyKey) => (
      {
        label: t(`quotaTargetStrategy.${strategyKey.toLowerCase()}`),
        value: QUOTA_TARGET_STRATEGIES[strategyKey],
      }
    ))
  ), [planQuotaTargetStrategyKey]);

  const handleSelectStrategy = useCallback((quotaTargetStrategy) => {
    if (plan.quotaTargetStrategy !== quotaTargetStrategy) {
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, quotaTargetStrategy }));
    }
  }, [plan]);

  if (plan.scope !== SCOPES.MANAGER) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <InfoCircleFilled className={styles.icon} width={16} height={16} />
      <Select
        className={styles.select}
        bordered={false}
        options={strategiesOptions}
        value={plan.quotaTargetStrategy}
        onSelect={handleSelectStrategy}
      />
    </div>
  );
};

QuotaTargetStrategySelect.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

QuotaTargetStrategySelect.defaultProps = {
  className: '',
};

export default QuotaTargetStrategySelect;
