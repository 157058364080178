import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import EditableDashboardPresetName from '@palette/components/dashboard/EditableDashboardPresetName/EditableDashboardPresetName';
import Alert from '@palette/components/designSystem/Alert/Alert';
import DashboardPresetUserSelect from '@palette/components/dashboard/DashboardPresetUserSelect/DashboardPresetUserSelect';
import DashboardPresetWidgets from '@palette/components/dashboard/DashboardPresetWidgets/DashboardPresetWidgets';
import DashboardPresetPreview from '@palette/components/dashboard/DashboardPresetPreview/DashboardPresetPreview';
import DashboardPresetMoreOptionsMenu from '@palette/components/dashboard/DashboardPresetMoreOptionsMenu/DashboardPresetMoreOptionsMenu';
import ApplyDashboardPresetButton from '@palette/components/dashboard/ApplyDashboardPresetButton/ApplyDashboardPresetButton';

import { useDashboardPresetInParams, useDashboardPresetUserInSearch } from '@palette/hooks/DashboardPresetHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

import styles from './DashboardPresetDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetDetailsPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const { presetId, dashboardPreset, isPending } = useDashboardPresetInParams();
  const { presetUserId, presetUser } = useDashboardPresetUserInSearch();

  useEffect(() => {
    if (presetId) {
      dispatch(DashboardPresetsActions.getById({ presetId }));
    }
  }, [presetId]);

  const getPresetDataForCandidateUserIsPending = useSelector((state) => DashboardPresetsSelectors.getPresetDataForCandidateUserIsPending(state));
  const presetData = useSelector(DashboardPresetsSelectors.getPresetDataForCandidateUser);

  useEffect(() => {
    if (presetId && presetUser !== null) {
      dispatch(DashboardPresetsActions.getPresetDataForCandidateUser({
        presetId,
        candidateUserId: presetUserId,
      }));
    }
  }, [presetId, presetUser, presetUserId, dashboardPreset]);

  const widgetsNode = useMemo(() => {
    if (dashboardPreset === null && isPending) {
      return (
        <div className={styles.widgets}>
          <Loader />
        </div>
      );
    }

    if (dashboardPreset === null) return null;

    return (
      <DashboardPresetWidgets className={styles.widgets} dashboardPreset={dashboardPreset} />
    );
  }, [isPending, dashboardPreset]);

  const previewContentNode = useMemo(() => {
    if (presetUser === null || getPresetDataForCandidateUserIsPending) {
      return (
        <div className={styles.previewContent}>
          <Loader />
        </div>
      );
    }

    return (
      <div className={styles.previewContent}>
        <DashboardPresetPreview user={presetUser} data={presetData} />
      </div>
    );
  }, [getPresetDataForCandidateUserIsPending, presetUser, presetData]);

  const previewNode = useMemo(() => {
    if (dashboardPreset === null && isPending) {
      return (
        <div className={styles.preview}>
          <Loader />
        </div>
      );
    }

    if (dashboardPreset === null) return null;

    return (
      <div className={styles.preview}>
        <div className={styles.previewHeader}>
          <div className={styles.previewHeaderTitle}>
            {t('dashboardPresetDetailsPageContent.preview.title')}
          </div>
          <DashboardPresetUserSelect className={styles.presetUserSelect} dashboardPreset={dashboardPreset} />
        </div>
        <Alert
          className={styles.previewAlert}
          type={ALERT_TYPES.INFO}
          message={t('dashboardPresetDetailsPageContent.preview.alert.message')}
          description={t('dashboardPresetDetailsPageContent.preview.alert.description')}
        />
        {previewContentNode}
      </div>
    );
  }, [isPending, dashboardPreset, previewContentNode]);

  if (dashboardPreset === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <EditableDashboardPresetName dashboardPreset={dashboardPreset} />
        <div className={styles.actions}>
          {
            hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.UPDATE]) && (
              <ApplyDashboardPresetButton className={styles.applyPresetBtn} dashboardPreset={dashboardPreset} />
            )
          }
          <DashboardPresetMoreOptionsMenu className={styles.moreMenu} dashboardPreset={dashboardPreset} />
        </div>
      </div>
      <div className={styles.content}>
        {widgetsNode}
        {previewNode}
      </div>
    </div>
  );
};

DashboardPresetDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

DashboardPresetDetailsPageContent.defaultProps = {
  className: '',
};

export default DashboardPresetDetailsPageContent;
