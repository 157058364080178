import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodsDatesSelector from '@palette/components/statement/statementPeriod/StatementPeriodsDatesSelector/StatementPeriodsDatesSelector';
import StatementPanelPeriodsCardsList from '@palette/components/statement/statementPeriod/StatementPanelPeriodsCardsList/StatementPanelPeriodsCardsList';

import {
  useCurrentStrategyDefaultDatesType,
  useUserStatementsPanelContext,
} from '@palette/hooks/StatementHooks';

import { getMoment } from '@palette/helpers/MomentHelper';

import styles from './StatementPanelPeriodsNavigation.less';

const classNames = bindClassNames.bind(styles);

const StatementPanelPeriodsNavigation = ({ className }) => {
  const moment = getMoment();

  const { defaultDatesType } = useCurrentStrategyDefaultDatesType();

  const {
    setDatesType,
    setDatesFrom,
    setDatesTo,
    typeToUse,
    fromToUse,
    toToUse,
  } = useUserStatementsPanelContext();

  const handleFilterChange = useCallback((selectedDatesType, selectedDatesFrom = null, selectedDatesTo = null) => {
    let newDatesType = defaultDatesType;
    let newDatesFrom = null;
    let newDatesTo = null;

    if (selectedDatesType !== null) {
      newDatesType = selectedDatesType;

      if (selectedDatesFrom !== null) {
        newDatesFrom = moment.utc(selectedDatesFrom).format('YYYY-MM-DD');

        if (selectedDatesTo !== null) {
          newDatesTo = moment.utc(selectedDatesTo).format('YYYY-MM-DD');
        }
      }
    }

    setDatesType(newDatesType);
    setDatesFrom(newDatesFrom);
    setDatesTo(newDatesTo);
  }, [defaultDatesType, setDatesType, setDatesFrom, setDatesTo]);

  const forcedDatesFilter = useMemo(() => ({
    type: typeToUse,
    from: fromToUse,
    to: toToUse,
    handleFilterChange,
  }), [typeToUse, fromToUse, toToUse, handleFilterChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <StatementPeriodsDatesSelector className={styles.datesSelector} forcedDatesFilter={forcedDatesFilter} />
      <StatementPanelPeriodsCardsList />
    </div>
  );
};

StatementPanelPeriodsNavigation.propTypes = {
  className: PropTypes.string,
};

StatementPanelPeriodsNavigation.defaultProps = {
  className: '',
};

export default StatementPanelPeriodsNavigation;
