import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddUsersInQuota from '@palette/components/quota/AddUsersInQuota/AddUsersInQuota';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './QuotaUserHeader.less';

const QuotaUserHeader = ({ disableAddBtn }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const addUsersRight = hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {t('users_other')}
      </div>
      {
        !disableAddBtn && addUsersRight && (
          <AddUsersInQuota />
        )
      }
    </div>
  );
};

QuotaUserHeader.propTypes = {
  disableAddBtn: PropTypes.bool,
};

QuotaUserHeader.defaultProps = {
  disableAddBtn: false,
};

export default QuotaUserHeader;
