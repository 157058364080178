import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import StatementPeriodStatus from '@palette/components/statement/statementPeriod/StatementPeriodStatus/StatementPeriodStatus';
import OngoingBulkMarkAsPaidStatus from '@palette/components/statement/statementPeriodUsers/OngoingBulkMarkAsPaidStatus/OngoingBulkMarkAsPaidStatus';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useStatementPeriodStatus } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_PERIOD_STATUSES } from '@palette/constants/statements';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import { selectors as StatementsSelectors, actions as StatementsActions } from '@palette/state/Statements';

import styles from './UsersStatementsMarkAllAsPaidAction.less';

const classNames = bindClassNames.bind(styles);

const UsersStatementsMarkAllAsPaidAction = ({ className, userStatements, statementPeriod, currency, disabled }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const markStatementsAsPaidIsPending = useSelector(StatementsSelectors.markStatementsAsPaidIsPending);
  const ongoingBulkMarkAsPaidStatus = useSelector(StatementsSelectors.getOngoingBulkMarkAsPaidStatus);

  const approveStatementsIsPending = useSelector(StatementsSelectors.approveStatementsIsPending);

  const statementPeriodStatus = useStatementPeriodStatus(statementPeriod);

  const markableUserStatements = useMemo(() => (
    userStatements.filter((userStatement) => (
      !userStatement.isPaid && !userStatement.isDirty
    ))
  ), [userStatements]);

  const handleMarkAllAsPaid = useCallback(() => {
    const statementIds = markableUserStatements.map((statement) => statement.id);

    dispatch(StatementsActions.markAsPaidStatements({
      statementIds,
      statementPeriodId: statementPeriod.id,
      currency,
    }));
  }, [markableUserStatements]);

  const contentNode = useMemo(() => {
    if (
      statementPeriodStatus === STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAYMENT_REQUIRED
    ) {
      let btnLabel = t('statementPeriodUsers.markAllAsPaid.button.label');
      if (markStatementsAsPaidIsPending && ongoingBulkMarkAsPaidStatus !== null) {
        btnLabel = t('statementPeriodUsers.markAllAsPaid.button.processingLabel', { currentIndex: ongoingBulkMarkAsPaidStatus.index + 1, nbToProcess: ongoingBulkMarkAsPaidStatus.statementIds.length });
      }

      return (
        <Popconfirm
          title={t('statementPeriodUsers.markAllAsPaid.button.popconfirm')}
          onConfirm={handleMarkAllAsPaid}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
        >
          <Button
            type="primary"
            icon={markStatementsAsPaidIsPending ? <LoadingLine spin /> : <StatementsFilled />}
            disabled={
              markStatementsAsPaidIsPending
              || markableUserStatements.length === 0
              || disabled
              || approveStatementsIsPending
            }
          >
            {btnLabel}
          </Button>
        </Popconfirm>
      );
    }

    return (
      <StatementPeriodStatus statementPeriod={statementPeriod} />
    );
  }, [
    statementPeriod,
    statementPeriodStatus,
    markStatementsAsPaidIsPending,
    disabled,
    handleMarkAllAsPaid,
    markableUserStatements,
    ongoingBulkMarkAsPaidStatus,
    approveStatementsIsPending,
  ]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      <OngoingBulkMarkAsPaidStatus />
    </div>
  );
};

UsersStatementsMarkAllAsPaidAction.propTypes = {
  className: PropTypes.string,
  userStatements: PropTypes.arrayOf(UserStatementModel.propTypes),
  statementPeriod: StatementPeriodModel.propTypes,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
};

UsersStatementsMarkAllAsPaidAction.defaultProps = {
  className: '',
  userStatements: [],
  statementPeriod: null,
  currency: null,
  disabled: false,
};

export default UsersStatementsMarkAllAsPaidAction;
