import PropTypes from 'prop-types';

import { manageArrayAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import { FOLDER_TYPES } from '@palette/constants/folders';

export const modelName = 'Folder';

const propTypesShape = {
  id: PropTypes.string,
  company: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(FOLDER_TYPES)),
  parent: PropTypes.string,
};

propTypesShape.children = PropTypes.arrayOf(PropTypes.shape(propTypesShape));

export const propTypesWithChildrenShape = propTypesShape;

export const propTypes = PropTypes.shape(propTypesShape);

export const manageType = (folder) => (FOLDER_TYPES[manageStringAttribute(folder, 'type')] || FOLDER_TYPES.PLAN);

const manageChildren = (type, children) => {
  const updatedChildren = children.map((child) => ({
    ...child,
    type,
  }));
  // eslint-disable-next-line no-use-before-define
  return transformList(updatedChildren);
};

export const transform = (folder) => {
  if (!folder) {
    return null;
  }

  const type = manageType(folder);
  const children = manageArrayAttribute(folder, 'children', []);

  return (
    {
      id: manageStringAttribute(folder, '_id', null),
      company: manageStringAttribute(folder, 'company', null),
      name: manageStringAttribute(folder, 'name', null),
      type,
      children: manageChildren(type, children),
      parent: manageStringAttribute(folder, 'parent', null),
    }
  );
};

export const transformList = (folders) => folders.map((folder) => transform(folder));
