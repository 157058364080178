import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import UserStatementHistoryList from '@palette/components/statement/userStatementHistory/UserStatementHistoryList/UserStatementHistoryList';

import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';

import { scrollToPosition } from '@palette/helpers/NavigationHelper';

import {
  DEFAULT_LIMIT_QS_VALUE,
  DEFAULT_PAGE_QS_VALUE,
  LIMIT_QS_KEY,
  PAGE_QS_KEY,
  STATEMENT_HISTORY_ID,
} from '@palette/constants/navigation';

import { useStatementUserHistoryInParams } from '@palette/hooks/StatementHistoryHooks';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './UserStatementHistory.less';

const classNames = bindClassNames.bind(styles);

const UserStatementHistory = ({ className }) => {
  const dispatch = useDispatch();

  const { listHistoryIsPending } = useStatementUserHistoryInParams();

  const [getPaginationLimit, paginationLimitInSearch] = useLimitInSearch();
  const [getPaginationPage, paginationPageInSearch] = usePageInSearch();

  useEffect(() => {
    const keysToDelete = [];

    if (getPaginationPage === DEFAULT_PAGE_QS_VALUE && paginationPageInSearch) {
      keysToDelete.push(PAGE_QS_KEY);
    }

    if (getPaginationLimit === DEFAULT_LIMIT_QS_VALUE && paginationLimitInSearch) {
      keysToDelete.push(LIMIT_QS_KEY);
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    getPaginationPage,
    paginationPageInSearch,
    getPaginationLimit,
    paginationLimitInSearch,
  ]);

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  useEffect(() => {
    if (!listHistoryIsPending) {
      scrollToPosition(STATEMENT_HISTORY_ID);
    }
  }, [listHistoryIsPending]);

  const resultsNode = useMemo(() => (
    <Loader spinning={listHistoryIsPending}>
      <UserStatementHistoryList onPageChange={handlePageChange} onLimitChange={handleLimitChange} />
    </Loader>
  ), [listHistoryIsPending, handlePageChange, handleLimitChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {resultsNode}
    </div>
  );
};

UserStatementHistory.propTypes = {
  className: PropTypes.string,
};

UserStatementHistory.defaultProps = {
  className: '',
};

export default UserStatementHistory;
