/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import CommissionDrawer from '@palette/components/commission/CommissionDrawer/CommissionDrawer';
import MyStatementsPageContent from '@palette/components/ic/myStatement/MyStatementsPageContent/MyStatementsPageContent';

import {
  sfAuth,
  getProfile,
} from '../services/sf';
import FullScreenLoader from '../components/loader';
import '../styles/ic/my-statement.less';

class SalesforceDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      profile: null,
      unauthorized: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.auth();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.sessionId !== prevProps.match.params.sessionId) {
      this.auth();
    }
  }

  auth = () => {
    sfAuth({ userId: atob(this.props.match.params.sessionId) }).then(({ data: session }) => {
      getProfile(session.sessionId).then(({ data: profile }) => {
        this.setState({
          loading: false,
          profile,
          unauthorized: false,
        });
      });
    }).catch((e) => {
      if (e && e.response && e.response.data.status === 401) {
        this.setState({ unauthorized: true });
      }
    });
  };

  render() {
    const {
      loading,
      profile,
      unauthorized,
    } = this.state;

    if (!profile) return null;

    return (
      <div>
        <div className="div-wrapper MyStatement">
          <MyStatementsPageContent
            inSalesforce
            sessionId={this.props.match.params.sessionId}
            showViewMoreButtonLink={profile.account}
            isUnauthorized={unauthorized}
          />
          <CommissionDrawer inSalesforce />
        </div>

        <div className="Palette_credits">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://palettehq.com/en?ref=salesforce"
          >
            <img src="/favicon.png" alt="palette logo" />
            {' '}
            Powered by Palette
          </a>
        </div>

        {loading && <FullScreenLoader />}
      </div>
    );
  }
}

SalesforceDashboard.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(SalesforceDashboard);
