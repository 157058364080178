import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import TeamMembersListItem from '@palette/components/team/TeamMembersListItem/TeamMembersListItem';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import * as TeamUserModel from '@palette/models/TeamUser';

import styles from './TeamMembersList.less';

const classNames = bindClassNames.bind(styles);

const TeamMembersList = ({ className, members }) => {
  const teamsItemsNodes = useMemo(() => {
    if (members.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      members.map((teamMember) => (
        <TeamMembersListItem
          key={teamMember.user.id}
          className={styles.teamMember}
          member={teamMember}
        />
      ))
    );
  }, [members]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {teamsItemsNodes}
    </div>
  );
};

TeamMembersList.propTypes = {
  className: PropTypes.string,
  members: PropTypes.arrayOf(TeamUserModel.propTypes).isRequired,
};

TeamMembersList.defaultProps = {
  className: '',
};

export default TeamMembersList;
