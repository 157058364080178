import PropTypes from 'prop-types';

import {
  manageArrayAttribute, manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'NotebookDataframe';

export const propTypes = PropTypes.shape({
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    maxLength: PropTypes.number,
  })),
  data: PropTypes.arrayOf(PropTypes.object),
});

export const transform = (notebookDataframe) => {
  if (!notebookDataframe) {
    return null;
  }

  const rawData = manageArrayAttribute({ notebookDataframe }, 'notebookDataframe');

  const headersByLabel = {};
  const data = [];
  rawData.forEach((dataItem) => {
    const checkedDataItem = {};

    Object.entries(dataItem).forEach(([label, value]) => {
      if (!headersByLabel[label]) {
        headersByLabel[label] = {
          label,
          maxLength: label.length,
        };
      }

      const checkedValue = manageStringAttribute({ value }, 'value');

      headersByLabel[label].maxLength = Math.max(headersByLabel[label].maxLength, checkedValue.length);
      checkedDataItem[label] = checkedValue;
    });

    data.push(checkedDataItem);
  });
  const headers = Object.values(headersByLabel);

  return (
    {
      headers,
      data,
    }
  );
};

export const transformList = (notebookDataframes) => notebookDataframes.map((notebookDataframe) => transform(notebookDataframe));
