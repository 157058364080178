import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

import Loader from '@palette/components/utils/Loader/Loader';
import InlineThread from '@palette/components/thread/InlineThread/InlineThread';

import { selectors as ThreadsSelectors } from '@palette/state/Threads';

import { STATUSES } from '@palette/constants/threads';

import styles from './MenuNotificationsPopoverContent.less';

const MenuNotificationsPopoverContent = () => {
  const { t } = useTranslation();
  const [threadsStatus, setThreadsStatus] = useState(STATUSES.PENDING);

  const listIsPending = useSelector(ThreadsSelectors.getListIsPending);
  const nbOfPendingThreads = useSelector((state) => ThreadsSelectors.getNbOfThreadsByStatus(state, { threadsStatus: STATUSES.PENDING }));
  const nbOfResolvedThreads = useSelector((state) => ThreadsSelectors.getNbOfThreadsByStatus(state, { threadsStatus: STATUSES.RESOLVED }));

  const threads = useSelector((state) => ThreadsSelectors.getThreadsListByStatus(state, { threadsStatus }));

  if (listIsPending) {
    return (
      <Loader />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('menuNotificationsPopoverContent.title')}
        </div>
        <div className={styles.statusSelector}>
          <Button
            type={threadsStatus === STATUSES.PENDING ? 'primary' : 'link'}
            onClick={() => setThreadsStatus(STATUSES.PENDING)}
            {...(threadsStatus === STATUSES.PENDING ? { shape: 'round' } : {})}
          >
            {t('menuNotificationsPopoverContent.pending', { count: nbOfPendingThreads })}
          </Button>
          <Button
            type={threadsStatus === STATUSES.RESOLVED ? 'primary' : 'link'}
            onClick={() => setThreadsStatus(STATUSES.RESOLVED)}
            {...(threadsStatus === STATUSES.RESOLVED ? { shape: 'round' } : {})}
          >
            {t('menuNotificationsPopoverContent.resolved', { count: nbOfResolvedThreads })}
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        {threads.map((thread) => (<InlineThread className={styles.thread} key={thread.id} thread={thread} />))}
      </div>
    </div>
  );
};

export default MenuNotificationsPopoverContent;
