import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import Link from '@palette/components/designSystem/Link/Link';
import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import RawData from '@palette/components/resources/RawData/RawData';
import ListDealCommissions from '@palette/components/commission/ListDealCommissions/ListDealCommissions';
import ResourceDetailsSummary from '@palette/components/resources/ResourceDetailsSummary/ResourceDetailsSummary';

import { CONNECTOR_TYPES } from '@palette/constants/connector';
import { RIGHTS } from '@palette/constants/profile';
import {
  RESOURCES_PAGE_CONTENT_TABS_IDS,
  RESOURCES_PAGE_CONTENT_QS_KEY,
  RESOURCE_DETAILS_PAGE_CONTENT_QS_KEY,
  RESOURCE_DETAILS_PAGE_CONTENT_TABS_IDS,
} from '@palette/constants/tabs';

import { getColumnValue, getResourceColumns } from '@palette/helpers/ConnectorHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { useProfile } from '@palette/hooks/ProfileHooks';

import routePaths from '@palette/config/routePaths';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';

import styles from './ResourceDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const ResourceDetailsPageContent = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();
  const { connectorId, type, resourceId } = useParams();

  const byIdIsPending = useSelector(ResourcesSelectors.getByIdIsPending);
  const resource = useSelector((state) => ResourcesSelectors.getResourceById(state, { resourceId }));
  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById((state), { connectorId }));

  useEffect(() => {
    if (!connector) {
      redirectTo({ path: routePaths.v2.resources });
    } else if (connectorId && type && resourceId) {
      dispatch(ResourcesActions.getById({ connectorId, type, objectId: resourceId }));
    }
  }, [connector, connectorId, type, resourceId]);

  const isNotebookType = useMemo(() => connector.type === CONNECTOR_TYPES.NOTEBOOK, [connector]);

  const canManage = useMemo(() => hasAtLeastOneRight(profile, [RIGHTS.ADMIN.OBJECTS.MANAGE]) && !isNotebookType, [profile, isNotebookType]);

  const isUser = useMemo(() => {
    if (resource === null) return false;
    const userConnectorObject = resource.connector.resources.filter((o) => o.isUser).map((o) => o.originalType);
    return userConnectorObject && userConnectorObject.includes(resource.originalType);
  }, [resource]);

  const getTabPropsByType = useCallback((tabType) => ({
    title: t(`resources.details.tabs.${tabType}`),
    content: tabType === RESOURCE_DETAILS_PAGE_CONTENT_TABS_IDS.COMMISSIONS && !isUser ? (
      <ListDealCommissions
        connectorId={connectorId}
        objectId={resourceId}
        type={type}
      />
    ) : (
      <RawData
        connectorId={connectorId}
        objectId={resourceId}
        type={type}
        onlyReadable={!canManage}
        hasSearch
        hasHistoryCTA={!isNotebookType}
        hasDocumentationLink={!isNotebookType}
      />
    ),
    defaultActive: tabType === RESOURCE_DETAILS_PAGE_CONTENT_TABS_IDS.RAWDATA,
    tabKey: tabType,
  }), [connectorId, type, resourceId, canManage, isUser, isNotebookType]);

  const tabs = useMemo(() => {
    const finalTabsIds = [RESOURCE_DETAILS_PAGE_CONTENT_TABS_IDS.RAWDATA];

    /**
     * Do not display Commissions tab if isUser type
     */
    if (!isUser) {
      finalTabsIds.push(RESOURCE_DETAILS_PAGE_CONTENT_TABS_IDS.COMMISSIONS);
    }

    return finalTabsIds.map((tabType) => getTabPropsByType(tabType));
  }, [getTabPropsByType, isUser]);

  const tabsNode = useMemo(() => (
    <Tabs
      className={styles.tabs}
      tabs={tabs}
      qsTabKey={RESOURCE_DETAILS_PAGE_CONTENT_QS_KEY}
    />
  ), [tabs]);

  const summaryNode = useMemo(() => {
    if (resource === null) return null;

    return (
      <ResourceDetailsSummary
        className={styles.summaryContainer}
        resourceObject={resource}
        isUser={isUser}
        canManage={canManage}
      />
    );
  }, [resource, isUser, canManage]);

  const contentNode = useMemo(() => {
    if (resource === null) {
      if (byIdIsPending) {
        return (
          <Loader className={styles.loaderContainer} />
        );
      }

      return null;
    }

    const columns = getResourceColumns(
      connector,
      resource.originalType,
      (column) => column.displayInResources,
    );

    return (
      <>
        <MetaTag title={getColumnValue(resource.data, columns[0])} />
        {summaryNode}
        {tabsNode}
      </>
    );
  }, [
    connector,
    resource,
    byIdIsPending,
    connectorId,
    type,
    summaryNode,
    tabsNode,
  ]);

  const backLinkNode = useMemo(() => {
    const qsObject = {};

    if (isNotebookType) {
      qsObject[RESOURCES_PAGE_CONTENT_QS_KEY] = RESOURCES_PAGE_CONTENT_TABS_IDS.NOTEBOOKS;
    }

    return (
      <Link
        className={styles.backLink}
        path={routePaths.v2.resourcesByConnectorId}
        params={{ connectorId, type }}
        qsObject={qsObject}
        displayIcon
        iconIsAfter={false}
        iconNode={<ChevronLeftLine className={styles.icon} width={18} height={18} />}
      >
        {t('resources.details.backLink.label')}
      </Link>
    );
  }, [connectorId, type, isNotebookType]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {backLinkNode}
      {contentNode}
    </div>
  );
};

ResourceDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

ResourceDetailsPageContent.defaultProps = {
  className: '',
};

export default ResourceDetailsPageContent;
