import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import MasterPlanPeriodProgress from '@palette/components/masterPlan/MasterPlanPeriodProgress/MasterPlanPeriodProgress';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import {
  getPlanValueDefinition,
  planHasNoQuotaTarget,
} from '@palette/helpers/MasterPlanHelper';
import { comaSeparatorFormatter, floatToFixedNumber, formatNumberShortDisplay } from '@palette/helpers/CommonHelper';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './MasterPlanPeriodProgressDetails.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodProgressDetails = ({ className, period, plan }) => {
  const targetNode = useMemo(() => {
    const planValueDefinition = getPlanValueDefinition(plan);

    const hasNoQuotaTarget = planHasNoQuotaTarget(plan, period);

    let targetProgressValue = `${period.dealsCount > 0 ? formatNumberShortDisplay(period.target * period.progress) : '-'} / ${formatNumberShortDisplay(period.target)}`;
    let targetProgressValueDetailed = `${period.dealsCount > 0 ? comaSeparatorFormatter(floatToFixedNumber(period.target * period.progress)) : '-'} / ${formatNumberShortDisplay(period.target)}`;
    if (hasNoQuotaTarget) {
      targetProgressValue = period.dealsCount > 0 ? formatNumberShortDisplay(period.totalDealValue) : '-';
      targetProgressValueDetailed = period.dealsCount > 0 ? comaSeparatorFormatter(floatToFixedNumber(period.totalDealValue)) : '-';
    }

    return (
      <div className={styles.targetWrapper}>
        <div className={styles.targetType}>
          {`${planValueDefinition} · `}
        </div>
        <Tooltip title={targetProgressValueDetailed}>
          <div className={styles.targetProgressValue}>
            {targetProgressValue}
          </div>
        </Tooltip>
      </div>
    );
  }, [plan, period]);

  const progressNode = useMemo(() => {
    const hasNoQuotaTarget = planHasNoQuotaTarget(plan, period);

    if (hasNoQuotaTarget) return null;

    return (
      <MasterPlanPeriodProgress period={period} plan={plan} />
    );
  }, [plan, period]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {targetNode}
      {progressNode}
    </div>
  );
};

MasterPlanPeriodProgressDetails.propTypes = {
  className: PropTypes.string,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanPeriodProgressDetails.defaultProps = {
  className: '',
};

export default MasterPlanPeriodProgressDetails;
