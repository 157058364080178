import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute, manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { FEATURES, STATEMENT_FX_RATES_MODE } from '@palette/constants/profile';

import * as CurrencyModel from '@palette/models/Currency';
import * as StatementStrategyModel from '@palette/models/StatementStrategy';
import * as StatementStrategyHistoryModel from '@palette/models/StatementStrategyHistory';
import * as PayrollProviderModel from '@palette/models/PayrollProvider';
import * as AggregatedFxRateModel from '@palette/models/AggregatedFxRate';
import * as HistoryFxRateModel from '@palette/models/HistoryFxRate';
import * as BillingSubscriptionModel from '@palette/models/BillingSubscription';

export const modelName = 'Company';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  live: PropTypes.bool,
  logo: PropTypes.string,
  currency: PropTypes.string,
  currencies: PropTypes.arrayOf(CurrencyModel.propTypes),
  trialEndDate: PropTypes.string,
  trialPaymentUrl: PropTypes.string,
  features: PropTypes.shape(Object.values(FEATURES).reduce((res, feature) => ({ ...res, [feature]: PropTypes.bool }), {})),
  timezone: PropTypes.string,
  statementStrategy: StatementStrategyModel.propTypes,
  statementStrategies: PropTypes.arrayOf(StatementStrategyHistoryModel.propTypes),
  seatsCount: PropTypes.number,
  minSeats: PropTypes.number,
  payrollProviders: PropTypes.array,
  stripeSubscriptionId: PropTypes.string,
  slack: PropTypes.string,
  aggregatedFxRates: PropTypes.arrayOf(AggregatedFxRateModel.propTypes),
  listFxRates: PropTypes.arrayOf(HistoryFxRateModel.propTypes),
  subscription: BillingSubscriptionModel.propTypes,
  statementFxRateMode: PropTypes.oneOf(Object.values(STATEMENT_FX_RATES_MODE)),
});

export const manageCompanyFeatures = (companyFeatures = {}) => Object.values(FEATURES).reduce((res, feature) => ({ ...res, [feature]: companyFeatures[feature] || false }), {});

export const manageStatementStrategy = (company) => (
  StatementStrategyModel.transform(manageFreeShapeObjectAttribute(company, 'activeStatementStrategy'))
);

export const manageStatementStrategies = (company) => StatementStrategyHistoryModel.transformList(manageArrayAttribute(company, 'statementPeriodStrategies'));

export const transformSeatsCount = (company) => manageNumberAttribute(company, 'seatsCount');

export const transformAggregatedFxRates = (company) => AggregatedFxRateModel.transformList(manageArrayAttribute(company, 'aggregatedFxRates'));

export const transformListFxRates = (company) => HistoryFxRateModel.transformList(manageArrayAttribute(company, 'listFxRates'));

export const transformSubscription = (company) => BillingSubscriptionModel.transform(manageFreeShapeObjectAttribute(company, 'subscription'));

export const transform = (company) => {
  if (!company) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(company, '_id'),
      name: manageStringAttribute(company, 'name'),
      live: manageBooleanAttribute(company, 'live'),
      logo: manageStringAttribute(company, 'logo'),
      currency: manageStringAttribute(company, 'currency', null),
      currencies: CurrencyModel.transformList(manageArrayAttribute(company, 'currencies')),
      trialEndDate: manageDateAttribute(company, 'trialEndDate'),
      trialPaymentUrl: manageStringAttribute(company, 'trialPaymentUrl', null),
      features: manageCompanyFeatures(company.features),
      timezone: manageStringAttribute(company, 'timezone'),
      statementStrategy: manageStatementStrategy(company),
      statementStrategies: manageStatementStrategies(company),
      seatsCount: transformSeatsCount(company),
      minSeats: manageNumberAttribute(company, 'minSeats'),
      payrollProviders: PayrollProviderModel.transformList(manageArrayAttribute(company, 'payrollProviders')),
      stripeSubscriptionId: manageStringAttribute(company, 'stripeSubscriptionId', null),
      slack: manageStringAttribute(company, 'slack', null),
      aggregatedFxRates: transformAggregatedFxRates(company),
      listFxRates: transformListFxRates(company),
      subscription: transformSubscription(company),
      statementFxRateMode: manageStringAttribute(company, 'statementFxRateMode', STATEMENT_FX_RATES_MODE.FROM_PAYMENT_DATE),
    }
  );
};

export const transformList = (companies) => companies.map((company) => transform(company));
