import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { InputInCellRefForwarded as InputInCell } from '@palette/components/designSystem/InputInCell/InputInCell';

import { INPUT_TABLE_CELL_TYPES } from '@palette/constants/global';
import Switch from '@palette/components/designSystem/Switch/Switch';

import styles from './InputTableBodyCell.less';

const classNames = bindClassNames.bind(styles);

const InputTableBodyCell = ({
  className,
  value,
  cellIndex,
  cellType,
  onChange,
  isAddNewRow,
}) => {
  const inputRef = useRef(null);

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleInputFocus = () => {
    inputRef?.current?.select();
  };

  const handleInputChange = (newValue) => {
    setCurrentValue(newValue);
  };

  const saveUpdate = useCallback(() => {
    if (!isAddNewRow && value !== currentValue) {
      onChange(cellIndex, currentValue);
    }
  }, [isAddNewRow, value, currentValue, cellIndex, onChange]);

  const handleInputPressEnter = () => {
    inputRef?.current?.blur();
  };

  const handleSwitchChange = useCallback((newValue) => {
    if (!isAddNewRow && value !== newValue) {
      onChange(cellIndex, newValue);
    }
  }, [isAddNewRow, value, onChange, cellIndex]);

  const inCellNode = useMemo(() => {
    if (isAddNewRow) return null;

    if (cellType === INPUT_TABLE_CELL_TYPES.BOOLEAN) {
      return (
        <div className={styles.switchWrapper}>
          <Switch
            checked={Boolean(currentValue)}
            onChange={handleSwitchChange}
          />
        </div>
      );
    }

    return (
      <InputInCell
        ref={inputRef}
        className={styles.input}
        type={cellType === INPUT_TABLE_CELL_TYPES.STRING ? 'text' : 'comaSeparatorFormatted'}
        value={currentValue}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={handleInputPressEnter}
      />
    );
  }, [
    cellType,
    currentValue,
    saveUpdate,
    isAddNewRow,
    inputRef,
    handleInputFocus,
    handleInputChange,
    handleInputPressEnter,
    handleSwitchChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        isAddNewRow,
        [className]: className !== '',
      })}
    >
      {inCellNode}
    </div>
  );
};

InputTableBodyCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  cellIndex: PropTypes.string.isRequired,
  cellType: PropTypes.oneOf(Object.values(INPUT_TABLE_CELL_TYPES)).isRequired,
  onChange: PropTypes.func.isRequired,
  isAddNewRow: PropTypes.bool,
};

InputTableBodyCell.defaultProps = {
  className: '',
  value: '',
  isAddNewRow: false,
};

export default InputTableBodyCell;
