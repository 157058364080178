import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import UnlinkQuotaFromMasterPlanModal from '@palette/components/masterPlanSettings/UnlinkQuotaFromMasterPlanModal/UnlinkQuotaFromMasterPlanModal';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import LinkFilled from '@palette/components/utils/Icons/LinkFilled';

const UnlinkQuotaFromMasterPlan = ({ plan }) => {
  const { t } = useTranslation();

  const [unlinkQuotaFromMasterPlanModalIsVisible, showUnlinkQuotaFromMasterPlanModal] = useState(false);

  return (
    <>
      <Button
        type="linkDestroy"
        icon={<LinkFilled />}
        onClick={() => showUnlinkQuotaFromMasterPlanModal(true)}
      >
        {t('unlinkQuotaFromMasterPlan.unlinkQuotaLabel')}
      </Button>
      {
        unlinkQuotaFromMasterPlanModalIsVisible && (
          <UnlinkQuotaFromMasterPlanModal visible onClose={() => showUnlinkQuotaFromMasterPlanModal(false)} plan={plan} />
        )
      }
    </>
  );
};

UnlinkQuotaFromMasterPlan.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
};

export default UnlinkQuotaFromMasterPlan;
