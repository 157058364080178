import React from 'react';

import MaintenancePageContent from '@palette/components/maintenance/MaintenancePageContent/MaintenancePageContent';
import GlobalNotif from '@palette/components/globalNotif/GlobalNotif/GlobalNotif';

const MaintenancePage = () => (
  <>
    <GlobalNotif />
    <MaintenancePageContent />
  </>
);

export default MaintenancePage;
