import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PlusLine from '@palette/components/utils/Icons/PlusLine';
import CreateDashboardModal from '@palette/components/quotaPerformance/CreateDashboardModal/CreateDashboardModal';

const CreateDashboard = ({ className }) => {
  const { t } = useTranslation();

  const [createDashboardModalIsVisible, showCreateDashboardModal] = useState(false);

  return (
    <div className={className}>
      <Button
        type="secondary"
        icon={<PlusLine width={12} height={12} />}
        onClick={() => showCreateDashboardModal(true)}
      >
        {t('quotasPerformance.createDashboard.label')}
      </Button>
      {
        createDashboardModalIsVisible && (
          <CreateDashboardModal
            visible
            onClose={() => showCreateDashboardModal(false)}
          />
        )
      }
    </div>
  );
};

CreateDashboard.propTypes = {
  className: PropTypes.string,
};

CreateDashboard.defaultProps = {
  className: '',
};

export default CreateDashboard;
