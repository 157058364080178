import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import MasterPlansListSelect from '@palette/components/masterPlan/MasterPlansListSelect/MasterPlansListSelect';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import styles from './AddPlanSelectors.less';

const classNames = bindClassNames.bind(styles);

const AddPlanSelectors = ({ className, onPlanIdsChange, disabled, disablePlanIds }) => {
  const { t } = useTranslation();

  const [counterFields, setCounterFields] = useState(1);
  const [counterChanges, setCounterChanges] = useState(0);
  const [planIds, setPlanIds] = useState([]);

  useEffect(() => {
    if (onPlanIdsChange) {
      onPlanIdsChange(planIds);
    }
  }, [counterFields]);

  const handlePlanIdChange = useCallback((position, planId) => {
    const finalIds = planIds;

    finalIds[position] = planId;

    setPlanIds(finalIds);
    setCounterChanges(counterChanges + 1);

    if (onPlanIdsChange) {
      onPlanIdsChange(finalIds);
    }
  }, [planIds, setPlanIds, setCounterChanges, counterChanges, onPlanIdsChange]);

  const contentNode = useMemo(() => (
    <>
      <div className={styles.label}>
        {t('quotasPerformance.addPlanSelectors.field.label')}
      </div>
      {[...Array(counterFields)].map((input, i) => (
        <MasterPlansListSelect
          key={`selector-${i}`}
          className={styles.selectField}
          alertClassName={styles.selectPlanAlertWrapper}
          placeholder={t('quotasPerformance.addPlanSelectors.select.placeholder')}
          onChange={(planId) => handlePlanIdChange(i, planId)}
          allowClear
          size="big"
          disablePlanIds={[...disablePlanIds, ...planIds]}
          disabled={disabled}
        />
      ))}
      <Button
        className={styles.addButton}
        type="link"
        icon={<AddFilled />}
        onClick={() => setCounterFields(counterFields + 1)}
        disabled={counterFields !== planIds.filter((planId) => planId).length}
      >
        {t('quotasPerformance.addPlanSelectors.addButton.label')}
      </Button>
    </>
  ), [counterFields, handlePlanIdChange, planIds, disablePlanIds, disabled, counterChanges]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

AddPlanSelectors.propTypes = {
  className: PropTypes.string,
  onPlanIdsChange: PropTypes.func,
  disabled: PropTypes.bool,
  disablePlanIds: PropTypes.array,
};

AddPlanSelectors.defaultProps = {
  className: '',
  onPlanIdsChange: undefined,
  disabled: false,
  disablePlanIds: [],
};

export default AddPlanSelectors;
