import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import { PLAN_SETTINGS_TABS_QS_KEY, PLAN_SETTINGS_TABS_IDS } from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import * as PlanUserTrackUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanUserTrackUpdateStrategy';

const classNames = bindClassNames.bind();

const PlanUserTrackUpdateContentStrategy = ({ className, details, i18nAction }) => {
  const { planId, planName } = details;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey={`statement.history.list.content.${i18nAction}`}
        values={{ name: planName }}
        components={[
          <Link
            key={i18nAction}
            path={routePaths.v2.planSettings}
            params={{ masterPlanId: planId }}
            qsObject={{
              [PLAN_SETTINGS_TABS_QS_KEY]: PLAN_SETTINGS_TABS_IDS.DATA_SOURCE,
            }}
          />,
        ]}
      />
    </span>
  );
};

PlanUserTrackUpdateContentStrategy.propTypes = {
  className: PropTypes.string,
  details: PlanUserTrackUpdateStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

PlanUserTrackUpdateContentStrategy.defaultProps = {
  className: '',
};

export default PlanUserTrackUpdateContentStrategy;
