import { useMemo } from 'react';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { getCommissionAmount } from '@palette/helpers/CommissionHelper';

import {
  SEARCHED_RESOURCE_QS_KEY,
  SEARCHED_STATUS_QS_KEY,
  SEARCHED_FROM_QS_KEY,
  SEARCHED_TO_QS_KEY,
  SEARCHED_USER_QS_KEY,
  STATUSES_OPTIONS,
  SEARCHED_TEAM_QS_KEY,
  SEARCHED_RESOURCE_TYPE_QS_KEY,
  SEARCHED_PLAN_QS_KEY,
  SORT_BY_QS_KEY,
  SORTBY_OPTIONS,
  SORTBY_OPTIONS_KEYS,
  CURRENCY_QS_KEY,
  COMMISSION_PAYMENT_PERIOD_QS_KEY,
  COMMISSION_PAYMENT_YEAR_QS_KEY,
} from '@palette/constants/commissions';

import { useProfile } from '@palette/hooks/ProfileHooks';

export const usePayments = (commission) => {
  const moment = getMoment();

  return useMemo(() => {
    const commissionAmount = getCommissionAmount(commission);

    const done = {
      payments: [],
      percentage: 0,
      value: 0,
    };
    const toBeDone = {
      payments: [],
      percentage: 0,
      value: 0,
    };
    const remaining = {
      percentage: 0,
      value: 0,
    };
    let cumulativeRoundedValues = 0;

    if (commission.payments !== null) {
      commission.payments.forEach((payment) => {
        const paymentValue = payment.percentage * commissionAmount;
        cumulativeRoundedValues += parseFloat(paymentValue.toFixed(2));
        if (moment.utc(payment.date).isBefore(moment.utc(), 'month')) {
          done.payments.push(payment);
          done.percentage += payment.percentage;
          done.value += paymentValue;
        } else {
          toBeDone.payments.push(payment);
          toBeDone.percentage += payment.percentage;
          toBeDone.value += paymentValue;
        }
      });
    }

    remaining.percentage = 1 - done.percentage - toBeDone.percentage;
    remaining.value = commissionAmount - done.value - toBeDone.value;

    return [done, toBeDone, remaining, cumulativeRoundedValues];
  }, [commission]);
};

export const useSearchedResourceInSearch = () => useKeyInSearch(SEARCHED_RESOURCE_QS_KEY, '');

export const useSearchedStatusInSearch = () => {
  const [searchedStatus, searchedStatusInSearch] = useKeyInSearch(SEARCHED_STATUS_QS_KEY);

  let status = searchedStatus;

  if (status) {
    status = STATUSES_OPTIONS[status.toUpperCase()];
  }

  return [status || undefined, searchedStatus, searchedStatusInSearch];
};

export const useSearchedDatesInSearch = () => {
  const moment = getMoment();

  const [searchedFrom, searchedFromInSearch] = useKeyInSearch(SEARCHED_FROM_QS_KEY);
  const [searchedTo, searchedToInSearch] = useKeyInSearch(SEARCHED_TO_QS_KEY);

  return useMemo(() => {
    let dateFrom = searchedFrom;
    let dateTo = searchedTo;

    if (dateFrom) {
      dateFrom = moment.utc(dateFrom).startOf('day');
    } else if (searchedFromInSearch) {
      dateFrom = null;
    } else {
      dateFrom = moment().utc().startOf('month');
    }

    if (dateTo) {
      dateTo = moment.utc(dateTo).endOf('day');
    } else if (searchedToInSearch) {
      dateTo = null;
    } else {
      dateTo = moment().utc().endOf('month');
    }

    return [dateFrom, searchedFromInSearch, dateTo, searchedToInSearch];
  }, [searchedFrom, searchedTo, searchedFromInSearch, searchedToInSearch]);
};

export const useSearchedUserInSearch = () => useKeyInSearch(SEARCHED_USER_QS_KEY);

export const useSearchedTeamInSearch = () => {
  const [searchedTeam, searchedTeamInSearch] = useKeyInSearch(SEARCHED_TEAM_QS_KEY);

  let team = searchedTeam;

  if (!team || team === '-1') {
    team = undefined;
  }

  return [team, searchedTeamInSearch];
};

export const useSearchedResourceTypeInSearch = () => useKeyInSearch(SEARCHED_RESOURCE_TYPE_QS_KEY);

export const useSearchedPlanInSearch = () => useKeyInSearch(SEARCHED_PLAN_QS_KEY);

export const useSortByInSearch = () => {
  const [sortByUrl, sortByInSearch] = useKeyInSearch(SORT_BY_QS_KEY, SORTBY_OPTIONS_KEYS.DATE_DESC.toLowerCase());

  let sortBy = sortByUrl;

  if (sortBy) {
    sortBy = SORTBY_OPTIONS[sortBy.toUpperCase()];
  }

  return [sortBy || undefined, sortByUrl, sortByInSearch];
};

export const useCurrencyInSearch = () => {
  const profile = useProfile();

  return useKeyInSearch(CURRENCY_QS_KEY, profile.userData.currency);
};

export const useCommissionListPageFilters = () => {
  const [searchedResourceFromSearch] = useSearchedResourceInSearch();
  const [searchedStatus, searchStatusUrl, searchedStatusInSearch] = useSearchedStatusInSearch();
  const [searchedFrom, searchedFromInSearch, searchedTo, searchedToInSearch] = useSearchedDatesInSearch();
  const [searchedUser, searchedUserInSearch] = useSearchedUserInSearch();
  const [searchedTeam, searchedTeamInSearch] = useSearchedTeamInSearch();
  const [searchedResourceType, searchedResourceTypeInSearch] = useSearchedResourceTypeInSearch();
  const [searchedPlan, searchedPlanInSearch] = useSearchedPlanInSearch();
  const [sortBy, sortByUrl, sortByInSearch] = useSortByInSearch();
  const [currency, currencyInSearch] = useCurrencyInSearch();

  return {
    searchedResourceFromSearch,
    searchedStatus,
    searchStatusUrl,
    searchedStatusInSearch,
    searchedFrom,
    searchedFromInSearch,
    searchedTo,
    searchedToInSearch,
    searchedUser,
    searchedUserInSearch,
    searchedTeam,
    searchedTeamInSearch,
    searchedResourceType,
    searchedResourceTypeInSearch,
    searchedPlan,
    searchedPlanInSearch,
    sortBy,
    sortByUrl,
    sortByInSearch,
    currency,
    currencyInSearch,
  };
};

export const useCommissionPaymentPeriodInSearch = () => useKeyInSearch(COMMISSION_PAYMENT_PERIOD_QS_KEY);

export const useCommissionPaymentYearInSearch = () => useKeyInSearch(COMMISSION_PAYMENT_YEAR_QS_KEY);

export const useCommissionPaymentsPageFilters = () => {
  const [commissionPaymentPeriod, commissionPaymentPeriodInSearch] = useCommissionPaymentPeriodInSearch();
  const [commissionPaymentYear, commissionPaymentYearInSearch] = useCommissionPaymentYearInSearch();

  return {
    commissionPaymentPeriod,
    commissionPaymentPeriodInSearch,
    commissionPaymentYear,
    commissionPaymentYearInSearch,
  };
};
