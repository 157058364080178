import PropTypes from 'prop-types';

import {
  manageArrayAttribute, manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PlanV3CellPreview';

export const propTypes = PropTypes.shape({
  headers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    maxLength: PropTypes.number,
  })),
  data: PropTypes.arrayOf(PropTypes.object),
});

export const transform = (planV3CellPreview) => {
  if (!planV3CellPreview) {
    return null;
  }

  const rawData = manageArrayAttribute({ planV3CellPreview }, 'planV3CellPreview');

  const headersByLabel = {};
  const data = [];
  rawData.forEach((dataItem) => {
    const checkedDataItem = {};

    Object.entries(dataItem).forEach(([label, value]) => {
      if (!headersByLabel[label]) {
        headersByLabel[label] = {
          label,
          maxLength: label.length,
        };
      }

      let checkedValue = manageStringAttribute({ value }, 'value', null, true);
      if (checkedValue === null) {
        checkedValue = JSON.stringify(value);
      }

      headersByLabel[label].maxLength = Math.max(headersByLabel[label].maxLength, checkedValue.length);
      checkedDataItem[label] = checkedValue;
    });

    data.push(checkedDataItem);
  });
  const headers = Object.values(headersByLabel);

  return (
    {
      headers,
      data,
    }
  );
};

export const transformList = (planV3CellPreviews) => planV3CellPreviews.map((planV3CellPreview) => transform(planV3CellPreview));
