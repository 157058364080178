import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { DEFAULT_PAGE_QS_VALUE, DEFAULT_LIMIT_QS_VALUE } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* listResourceObjects(payload = {}) {
  const {
    connectorId,
    type,
    filter,
    quickSearch,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
  } = payload;

  const data = {
    connectorId,
    type,
    filter,
    quickSearch,
    page,
    limit,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.connectors.listResourceObjects, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.VIEW],
  );
}

export function* resetLastSyncDates(payload = {}) {
  const {
    connectorId,
    resourceTypes,
    lastSyncDate,
  } = payload;

  const data = {
    connectorId,
    resourceTypes,
    lastSyncDate,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.connectors.resetLastSyncDates, data, getConfig()),
    [RIGHTS.ADMIN.OBJECTS.VIEW],
  );
}

export function* getList() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.connectors.getList, undefined, getConfig()),
    [RIGHTS.ADMIN.CONNECTORS.VIEW],
  );
}

export function* getListForIC() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.connectors.list, undefined, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* getResourceFields(payload = {}) {
  const {
    connectorId,
    resourceType,
  } = payload;

  const data = {
    connectorId,
    resourceType,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.connectors.getResourceFields, data, getConfig()),
    [],
  );
}

export function* getResourceConfigurationsFields(payload = {}) {
  const { connectorId } = payload;

  const data = { connectorId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.connectors.getResourceConfigurationsByConnectorId, data, getConfig()),
    [RIGHTS.ADMIN.CONNECTORS.VIEW],
  );
}

export function* getAvailableConfigurationsFields(payload = {}) {
  const { connectorId, resourceType } = payload;

  const data = { connectorId, resourceType };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.connectors.getAvailabeConfigurationsFields, data, getConfig()),
    [RIGHTS.ADMIN.CONNECTORS.VIEW],
  );
}

export function* upsertResourceConfigurations(payload = {}) {
  const { connectorId, resourceType, fields } = payload;

  const data = { connectorId, resourceType, fields };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.connectors.upsertResourceConfigurations, data, getConfig()),
    [RIGHTS.ADMIN.CONNECTORS.MANAGE],
  );
}
