import _flattenDeep from 'lodash/flattenDeep';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { getQSStringified } from '@palette/helpers/MenuHelper';

import { useIsCollapsed } from '@palette/hooks/NavigationHooks';

export const useSelectedMenuKeys = (menus, profile) => {
  const [menuIsCollapsed] = useIsCollapsed();

  const menuItems = _flattenDeep(
    menus
      .filter((menu) => menu.show(profile))
      .map((menu) => (
        menu.items
          .filter((item) => item.show(profile))
          .map((item) => (
            item.submenuItems
              ? item.submenuItems.filter((submenuItem) => submenuItem.show(profile)).map((submenuItem) => ({ ...submenuItem, parentMenuKey: item.key }))
              : item
          ))
      )),
  ).filter((item) => !!item.path);

  const location = useLocation();

  const openSubMenuKeys = [];

  const selectedMenuItemKeys = menuItems.map((item) => {
    if (useRouteMatch(item.path) !== null) {
      let matched = item.key;

      if (item.pathQS && location.search !== getQSStringified(item.pathQS)) {
        matched = false;
      }

      if (matched !== false && item.parentMenuKey && !menuIsCollapsed) {
        openSubMenuKeys.push(item.parentMenuKey);
      }

      return matched;
    }

    return false;
  }).filter(Boolean);

  return [openSubMenuKeys, selectedMenuItemKeys];
};
