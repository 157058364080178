import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import MasterPlanSettingsCommissionRulesSteps from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesSteps/MasterPlanSettingsCommissionRulesSteps';
import MasterPlanSettingsPayoutRulesSteps from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRulesSteps/MasterPlanSettingsPayoutRulesSteps';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './MyMasterPlanCalculationModal.less';

const MyMasterPlanCalculationModal = ({ visible, onClose, plan, period }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.modal}
      title={t('myMasterPlanCalculationModal.title')}
      visible={visible}
      onCancel={onClose}
      displayFooter={false}
      width="50%"
    >
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.title}>{t('myMasterPlanCalculationModal.commissions.title')}</div>
          <MasterPlanSettingsCommissionRulesSteps plan={plan} period={period} forIC />
        </div>
        <div className={styles.section}>
          <div className={styles.title}>{t('myMasterPlanCalculationModal.payout.title')}</div>
          <MasterPlanSettingsPayoutRulesSteps plan={plan} period={{ year: period.year, period: period.period }} forIC />
        </div>
      </div>
    </Modal>
  );
};

MyMasterPlanCalculationModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes,
};

MyMasterPlanCalculationModal.defaultProps = {
  visible: false,
  onClose: () => {},
  period: null,
};

export default MyMasterPlanCalculationModal;
