import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as StatementHistoryUserModel from '@palette/models/StatementHistoryUser';
import * as StatementStrategyModel from '@palette/models/StatementStrategy';

export const modelName = 'StatementCorrectionRejectStrategy';

export const propTypes = PropTypes.exact({
  amount: PropTypes.number,
  currency: PropTypes.string,
  date: PropTypes.string,
  period: PropTypes.number,
  year: PropTypes.number,
  payee: StatementHistoryUserModel.propTypes,
  strategy: StatementStrategyModel.propTypes,
  periodId: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      amount: manageAmountAttribute(strategy, 'amount'),
      currency: manageStringAttribute(strategy, 'currency'),
      date: manageDateAttribute(strategy, 'date'),
      period: manageNumberAttribute(strategy, 'period'),
      year: manageNumberAttribute(strategy, 'year'),
      payee: StatementHistoryUserModel.transform(manageFreeShapeObjectAttribute(strategy, 'payee')),
      strategy: StatementStrategyModel.transform(strategy, 'strategy'),
      periodId: manageStringAttribute(strategy, 'periodId'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
