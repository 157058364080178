import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import OffboardUserModal from '@palette/components/userDetails/OffboardUserModal/OffboardUserModal';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './OffboardUserButton.less';

const classNames = bindClassNames.bind(styles);

const OffboardUserButton = ({ className, user }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [offboardUserModalIsVisible, showOffboardUserModal] = useState(false);

  const offboardUserButtonNode = useMemo(() => (
    <Button
      className={styles.offboardBtn}
      type="danger"
      icon={<ClosePopupFilled />}
      onClick={() => showOffboardUserModal(true)}
    >
      {t('offboardUserButton.label', { userDisplayName: user.displayName })}
    </Button>
  ), [showOffboardUserModal, user]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.UPDATE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {offboardUserButtonNode}
      {offboardUserModalIsVisible && (
        <OffboardUserModal
          visible
          onClose={() => showOffboardUserModal(false)}
          user={user}
        />
      )}
    </div>
  );
};

OffboardUserButton.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

OffboardUserButton.defaultProps = {
  className: '',
};

export default OffboardUserButton;
