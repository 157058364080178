import React from 'react';
import PropTypes from 'prop-types';
import '../styles/pageTopTitle.less';

const PageTopTitle = ({ title, actions }) => (
  <div>
    <div className="Page_top_title_wrapper">
      <div className="Page_top_title">
        <h1 className="no-margin">
          {title}
        </h1>
      </div>
      <div>
        {actions}
      </div>

    </div>
  </div>
);

PageTopTitle.propTypes = {
  title: PropTypes.object.isRequired,
  actions: PropTypes.object,
};
PageTopTitle.defaultProps = {
  actions: null,
};

export default PageTopTitle;
