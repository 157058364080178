import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import FastOnboardModal from '@palette/components/user/FastOnboardModal/FastOnboardModal';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './FastOnboardLink.less';

const classNames = bindClassNames.bind(styles);

const FastOnboardLink = ({ className, restrictToConnectorIdAndType, onFastOnboardLinkClick }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [fastOnboardModalIsVisible, showFastOnboardModal] = useState(false);

  const handleShowFastOnboardModal = useCallback(() => {
    if (onFastOnboardLinkClick !== null) {
      onFastOnboardLinkClick();
    } else {
      showFastOnboardModal(true);
    }
  }, [onFastOnboardLinkClick]);

  const fastOnboardLinkNode = useMemo(() => (
    <Button
      className={styles.link}
      type="link"
      icon={<AddFilled />}
      onClick={handleShowFastOnboardModal}
    >
      {t('fastOnboardLink.label')}
    </Button>
  ), [showFastOnboardModal, handleShowFastOnboardModal]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.UPDATE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {fastOnboardLinkNode}
      {fastOnboardModalIsVisible && (
        <FastOnboardModal
          visible
          onClose={() => showFastOnboardModal(false)}
          restrictToConnectorIdAndType={restrictToConnectorIdAndType}
        />
      )}
    </div>
  );
};

FastOnboardLink.propTypes = {
  className: PropTypes.string,
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  onFastOnboardLinkClick: PropTypes.func,
};

FastOnboardLink.defaultProps = {
  className: '',
  restrictToConnectorIdAndType: null,
  onFastOnboardLinkClick: null,
};

export default FastOnboardLink;
