import PropTypes from 'prop-types';

import { manageNumberAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'NotebookCodeCellRequiredInformationStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  position: PropTypes.number,
  field: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'NotebookCodeCellRequiredInformationContentStrategy',
      position: manageNumberAttribute(strategy, 'position'),
      field: manageStringAttribute(strategy, 'information'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
