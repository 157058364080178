import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Select from '@palette/components/designSystem/Select/Select';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { useProfile } from '@palette/hooks/ProfileHooks';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import { actions as StatementsActions } from '@palette/state/Statements';
import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './UsersStatementsExportToPayrollAction.less';

const classNames = bindClassNames.bind(styles);

const UsersStatementsExportToPayrollAction = ({ className, userStatements, statementPeriod, selectedTeamId, currency }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const handleExportToPayroll = useCallback((payrollProvider) => {
    let runExport = true;
    if (payrollProvider.teamId !== null) {
      runExport = payrollProvider.teamId === selectedTeamId;
    }

    if (runExport) {
      const statementIds = userStatements.map((statement) => statement.id);

      dispatch(StatementsActions.exportToPayroll({
        payrollProviderId: payrollProvider.id,
        statementPeriodId: statementPeriod.id,
        statementIds,
        currency,
      }));
    }
  }, [userStatements, selectedTeamId, currency]);

  const [selectedPayrollProvider, setSelectedPayrollProvider] = useState(profile.userData.company.payrollProviders.length > 0 ? profile.userData.company.payrollProviders[0] : null);

  const selectedPayrollProviderTeam = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId: selectedPayrollProvider !== null ? selectedPayrollProvider.teamId : null }));

  const handlePayrollProviderChange = useCallback((newSelectedPayrollProviderId) => {
    const newSelectedPayrollProvider = profile.userData.company.payrollProviders.find((payrollProvider) => (payrollProvider.id === newSelectedPayrollProviderId));
    setSelectedPayrollProvider(newSelectedPayrollProvider ?? null);
  }, [profile, setSelectedPayrollProvider]);

  const payrollProvidersOptions = useMemo(() => {
    if (profile.userData.company.payrollProviders.length < 2) return [];

    return profile.userData.company.payrollProviders.map((payrollProvider) => ({
      label: payrollProvider.name,
      value: payrollProvider.id,
    }));
  }, [profile]);

  const payrollProvidersListNode = useMemo(() => {
    if (profile.userData.company.payrollProviders.length < 2) return null;

    const disabled = selectedPayrollProvider !== null && selectedPayrollProvider.teamId !== null && selectedPayrollProvider.teamId !== selectedTeamId;

    const buttonNode = (
      <Button
        className={classNames({
          button: true,
          disabled,
        })}
        type="link"
        icon={<DownloadFilled width={20} height={20} />}
        onClick={() => handleExportToPayroll(selectedPayrollProvider)}
        disabled={disabled}
      >
        {t('userStatements.exportToPayroll', { payrollProviderName: selectedPayrollProvider !== null ? selectedPayrollProvider.name : '' })}
      </Button>
    );

    let finalButtonNode = buttonNode;

    if (selectedPayrollProviderTeam !== null) {
      finalButtonNode = (
        <Tooltip title={t('userStatements.payrollProviderRestrictedTo', { team: selectedPayrollProviderTeam.name })}>
          <div>
            {buttonNode}
          </div>
        </Tooltip>
      );
    }

    return (
      <div
        className={classNames({
          listWrapper: true,
          [className]: className !== '',
        })}
      >
        <Select
          dropdownMatchSelectWidth={false}
          options={payrollProvidersOptions}
          value={selectedPayrollProvider !== null ? selectedPayrollProvider.id : null}
          onChange={handlePayrollProviderChange}
        />
        {finalButtonNode}
      </div>
    );
  }, [
    profile,
    handlePayrollProviderChange,
    selectedPayrollProvider,
    payrollProvidersOptions,
    selectedTeamId,
    handleExportToPayroll,
    selectedPayrollProviderTeam,
  ]);

  const contentNode = useMemo(() => {
    if (profile.userData.company.payrollProviders.length === 0) return null;

    if (profile.userData.company.payrollProviders.length === 1) {
      const payrollProvider = profile.userData.company.payrollProviders[0];

      const disabled = payrollProvider.teamId !== null && payrollProvider.teamId !== selectedTeamId;

      const buttonNode = (
        <Button
          className={classNames({
            buttonWrapper: true,
            disabled,
            [className]: className !== '',
          })}
          type="link"
          icon={<DownloadFilled width={20} height={20} />}
          onClick={() => handleExportToPayroll(payrollProvider)}
          disabled={disabled}
        >
          {t('userStatements.exportToPayroll', { payrollProviderName: payrollProvider.name })}
        </Button>
      );

      if (selectedPayrollProviderTeam !== null) {
        return (
          <Tooltip title={t('userStatements.payrollProviderRestrictedTo', { team: selectedPayrollProviderTeam.name })}>
            <div>
              {buttonNode}
            </div>
          </Tooltip>
        );
      }

      return buttonNode;
    }

    return payrollProvidersListNode;
  }, [
    profile,
    statementPeriod,
    handleExportToPayroll,
    selectedTeamId,
    payrollProvidersListNode,
    selectedPayrollProviderTeam,
  ]);

  return contentNode;
};

UsersStatementsExportToPayrollAction.propTypes = {
  className: PropTypes.string,
  userStatements: PropTypes.arrayOf(UserStatementModel.propTypes).isRequired,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  selectedTeamId: PropTypes.string,
  currency: PropTypes.string,
};

UsersStatementsExportToPayrollAction.defaultProps = {
  className: '',
  selectedTeamId: null,
  currency: null,
};

export default UsersStatementsExportToPayrollAction;
