import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import EditUserStatementAdjustmentButton from '@palette/components/statement/userStatement/EditUserStatementAdjustmentButton/EditUserStatementAdjustmentButton';

import { useStatementUserIdInParams } from '@palette/hooks/StatementHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as UserStatementAdjustmentModel from '@palette/models/UserStatementAdjustment';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './UserStatementAdjustmentsItem.less';

const classNames = bindClassNames.bind(styles);

const UserStatementAdjustmentsItem = ({ className, userStatement, statementPeriod, adjustment, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();
  const profile = useProfile();

  const { userId } = useStatementUserIdInParams();

  const [deleteIsPending, setDeleteIsPending] = useState(false);

  const deleteAdjustmentIsPending = useSelector(StatementsSelectors.deleteAdjustmentIsPending);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const deleteAdjustment = useCallback((event) => {
    stopPropagation(event);

    setDeleteIsPending(true);

    dispatch(StatementsActions.deleteAdjustment({
      statementId: userStatement.id,
      adjustmentId: adjustment.id,
      statementPeriodId: statementPeriod.id,
      userId,
      currency,
    }));
  }, [
    userStatement,
    adjustment,
    statementPeriod,
    userId,
    currency,
  ]);

  useEffect(() => {
    if (!deleteAdjustmentIsPending && deleteIsPending) {
      setDeleteIsPending(false);
    }
  }, [deleteAdjustmentIsPending, deleteIsPending]);

  const actionsNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.ADJUSTMENTS.MANAGE]) || userStatement.isPaid) return null;

    return (
      <div className={styles.actions}>
        <EditUserStatementAdjustmentButton
          currency={currency}
          userStatement={userStatement}
          statementPeriod={statementPeriod}
          adjustment={adjustment}
          disabled={deleteAdjustmentIsPending}
        />
        <Popconfirm
          title={t('userStatementAdjustments.actions.delete.popconfirm')}
          onConfirm={(event) => deleteAdjustment(event)}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
        >
          <Button
            className={styles.deleteButton}
            type="linkDestroy"
            icon={deleteAdjustmentIsPending && deleteIsPending ? <LoadingLine spin /> : <TrashFilled width={18} height={18} />}
            disabled={deleteAdjustmentIsPending}
          >
            {t('actions.remove')}
          </Button>
        </Popconfirm>
      </div>
    );
  }, [
    profile,
    userStatement,
    currency,
    statementPeriod,
    adjustment,
    deleteAdjustment,
    deleteAdjustmentIsPending,
    deleteIsPending,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.colRow}>
        <span className={styles.details}>
          {adjustment.reason}
        </span>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {formatPrice(adjustment.amount, adjustment.currency)}
        </span>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {moment.utc(adjustment.when).format('LL')}
        </span>
        {actionsNode}
      </div>
    </div>
  );
};

UserStatementAdjustmentsItem.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes.isRequired,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  adjustment: UserStatementAdjustmentModel.propTypes.isRequired,
  currency: PropTypes.string,
};

UserStatementAdjustmentsItem.defaultProps = {
  className: '',
  currency: null,
};

export default UserStatementAdjustmentsItem;
