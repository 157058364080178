import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

export function* sendEvent(event, params = {}) {
  const { hash, host, href } = window.location;
  const data = {
    event, type: 'TRACK', params: { ...params, hash, host, href },
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.analytics.logEvent, data, getConfig()),
    [],
  );
}

export function* sendCompanyGroup(params = {}) {
  const data = {
    type: 'GROUP', params,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.analytics.logEvent, data, getConfig()),
    [],
  );
}

export function* sendIdentify(params = {}) {
  const data = {
    type: 'IDENTIFY', params,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.analytics.logEvent, data, getConfig()),
    [],
  );
}

export function* sendPageVisit(pageName, params = {}) {
  const data = {
    type: 'PAGE', page: pageName, params,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.analytics.logEvent, data, getConfig()),
    [],
  );
}
