import React, { Component } from 'react';
import { Tooltip, Modal } from 'antd';
import PropTypes from 'prop-types';
import { CONNECTOR_TYPES } from '../../../../services/constants';
import SourceVignette from '../sourceVignette';
import '../../../../styles/connectCRMButton.less';
import QuickBooksOriginalButton from '../../../QuickBooksOriginalButton';

class ConnectQuickBooksButtonOauth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      connectModale: false,
    };
  }

  openConnectModale = () => this.setState({ connectModale: true });

  closeConnectModale = () => this.setState({ connectModale: false });

  render() {
    const { loading, connectModale } = this.state;
    const { disabled, sandbox } = this.props;
    return (
      <>
        <Tooltip
          placement="right"
          title={disabled ? 'Connector already linked' : null}
        >
          <SourceVignette
            loading={loading}
            icon={(
              <img
                style={{ width: '72px' }}
                alt="quickbooks"
                src="/img/quickbooks_icon.svg"
              />
            )}
            type={CONNECTOR_TYPES.BILLING}
            name={`QuickBooks${sandbox ? ' (sandbox)' : ''}`}
            id={`QUICKBOOKS${sandbox ? '_SANDBOX' : ''}`}
            disabled={disabled}
            onClick={disabled ? null : this.openConnectModale}
          />
        </Tooltip>
        <Modal
          title="Connect your QuickBooks account"
          onCancel={this.closeConnectModale}
          footer={null}
          visible={connectModale}
        >
          <div>
            <div className="grey">In order to connect your QuickBooks account, please click on "Connect to QuickBooks" button.</div>
            <div className="flex" style={{ justifyContent: 'center', marginTop: '14px' }}>
              {
              // 274*48
              }
              <QuickBooksOriginalButton sandbox={sandbox} />
            </div>
          </div>
        </Modal>

      </>
    );
  }
}

ConnectQuickBooksButtonOauth.propTypes = {
  disabled: PropTypes.bool,
  sandbox: PropTypes.bool,
};

ConnectQuickBooksButtonOauth.defaultProps = {
  disabled: false,
  sandbox: false,
};
export default ConnectQuickBooksButtonOauth;
