import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* statementsHistoryListByFilters(payload) {
  const {
    statementId,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
  } = payload;

  const data = {
    statementId,
    page,
    limit,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.listHistoryChangesById, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}
