/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { API_URL } from './constants';
import { refreshProfile } from './user';
import { setSessionId } from './utils';

export const sfAuth = (params) => Axios.post(`${API_URL}/sf/auth`, params).then((data) => {
  setSessionId(data.data.sessionId);
  return data;
});

export const getProfile = (session) => Axios.post(`${API_URL}/sf/get-profile`, null, {
  headers: {
    sessionId: session,
  },
}).then((getProfileData) => {
  refreshProfile(getProfileData.data);
  return getProfileData;
});
