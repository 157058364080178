import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import InputTableNewColumnModal from '@palette/components/designSystem/InputTableNewColumnModal/InputTableNewColumnModal';

import styles from './InputTableAddNewColumnHeaderCell.less';

const classNames = bindClassNames.bind(styles);

const InputTableAddNewColumnHeaderCell = ({ className, onAddNewColumn, columns }) => {
  const [newColumnModalIsVisible, showNewColumnModal] = useState(false);

  const handleAddNewColumn = useCallback((newColumnName = null) => {
    if (newColumnName !== null) {
      onAddNewColumn(newColumnName);
    }

    showNewColumnModal(false);
  }, [onAddNewColumn]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      onClick={() => showNewColumnModal(true)}
    >
      <div className={styles.label}>
        +
      </div>
      {newColumnModalIsVisible && (
        <InputTableNewColumnModal
          visible
          columns={columns}
          onClose={handleAddNewColumn}
        />
      )}
    </div>
  );
};

InputTableAddNewColumnHeaderCell.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAddNewColumn: PropTypes.func.isRequired,
};

InputTableAddNewColumnHeaderCell.defaultProps = {
  className: '',
};

export default InputTableAddNewColumnHeaderCell;
