import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PlusLine from '@palette/components/utils/Icons/PlusLine';
import CreateQuotaModal from '@palette/components/quota/CreateQuotaModal/CreateQuotaModal';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

const CreateQuota = ({ className, periodType, frequency, afterCreate }) => {
  const { t } = useTranslation();
  const [createQuotaModalIsVisible, showCreateQuotaModal] = useState(false);

  return (
    <div className={className}>
      <Button
        type="secondary"
        icon={<PlusLine width={12} height={12} />}
        onClick={() => showCreateQuotaModal(true)}
      >
        {t('quota.createNewONe')}
      </Button>
      {
        createQuotaModalIsVisible && (
          <CreateQuotaModal visible onClose={() => showCreateQuotaModal(false)} periodType={periodType} frequency={frequency} afterCreate={afterCreate} />
        )
      }
    </div>
  );
};

CreateQuota.propTypes = {
  className: PropTypes.string,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  frequency: PropTypes.number,
  afterCreate: PropTypes.func,
};

CreateQuota.defaultProps = {
  className: '',
  periodType: null,
  frequency: null,
  afterCreate: null,
};

export default CreateQuota;
