import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CompareFilled from '@palette/components/utils/Icons/CompareFilled';
import MergeUserSelector from '@palette/components/user/MergeUserSelector/MergeUserSelector';
import MergeUserResults from '@palette/components/user/MergeUserResults/MergeUserResults';

import { useMergeUserInSearch } from '@palette/hooks/UserHooks';

import {
  MERGE_USER_FIRST_QS_KEY,
  MERGE_USER_SECOND_QS_KEY,
} from '@palette/constants/user';

import styles from './UsersMergePageContent.less';

const classNames = bindClassNames.bind(styles);

const UsersMergePageContent = ({ className }) => {
  const firstUser = useMergeUserInSearch(MERGE_USER_FIRST_QS_KEY);
  const secondUser = useMergeUserInSearch(MERGE_USER_SECOND_QS_KEY);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.usersSelectorWrapper}>
        <MergeUserSelector
          title="A"
          className={styles.mergeUserSelector}
          userQSKey={MERGE_USER_FIRST_QS_KEY}
          user={firstUser}
        />
        <div className={styles.divider}>
          <CompareFilled className={styles.dividerIcon} width={80} height={80} />
        </div>
        <MergeUserSelector
          title="B"
          className={styles.mergeUserSelector}
          userQSKey={MERGE_USER_SECOND_QS_KEY}
          user={secondUser}
        />
      </div>
      <MergeUserResults
        className={styles.results}
        firstUser={firstUser}
        secondUser={secondUser}
      />
    </div>
  );
};

UsersMergePageContent.propTypes = {
  className: PropTypes.string,
};

UsersMergePageContent.defaultProps = {
  className: '',
};

export default UsersMergePageContent;
