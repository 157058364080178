import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageBooleanAttribute,
  manageArrayAttribute,
} from '@palette/helpers/ModelHelper';
import { getTypeDisplayed } from '@palette/helpers/CommonHelper';

import * as ResourceColumnModel from '@palette/models/ResourceColumn';

export const modelName = 'Resource';

export const propTypes = PropTypes.shape({
  type: PropTypes.string,
  originalType: PropTypes.string,
  isUser: PropTypes.bool,
  columns: PropTypes.arrayOf(ResourceColumnModel.propTypes),
  name: PropTypes.string,
});

export const transform = (resource) => {
  if (!resource) {
    return null;
  }

  const originalType = manageStringAttribute(resource, 'type');

  return (
    {
      type: getTypeDisplayed(originalType),
      originalType,
      isUser: manageBooleanAttribute(resource, 'isUser'),
      columns: ResourceColumnModel.transformList(manageArrayAttribute(resource, 'columns')),
      name: manageStringAttribute(resource, 'name'),
    }
  );
};

export const transformList = (resources) => resources.map((resource) => transform(resource));
