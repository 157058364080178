import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _groupBy from 'lodash/groupBy';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import NotebookDataConnectionConnectorsList from '@palette/components/notebooks/NotebookDataConnectionConnectorsList/NotebookDataConnectionConnectorsList';

import { NOTEBOOK_DATA_CONNECTION_TYPES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './NotebookDataConnectionList.less';

const classNames = bindClassNames.bind(styles);

const NotebookDataConnectionList = ({ className, notebook }) => {
  const dataConnectionConnectorsListNode = useMemo(() => {
    const dataConnectionConnectors = notebook.dataConnections
      .filter((dataConnection) => (dataConnection.type === NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR))
      .map((dataConnection) => (dataConnection[dataConnection.type]));

    if (dataConnectionConnectors.length === 0) return null;

    const groupByConnectorName = _groupBy(dataConnectionConnectors, (dataConnectionConnector) => (dataConnectionConnector.connectorName));
    return Object.entries(groupByConnectorName).map(([connectorName, dataConnectionConnectorsList], index) => (
      <NotebookDataConnectionConnectorsList
        key={connectorName}
        notebook={notebook}
        connectorName={connectorName}
        dataConnectionConnectorsList={dataConnectionConnectorsList}
        defaultOpen={index === 0}
      />
    ));
  }, [notebook]);

  const dataConnectionsListNode = useMemo(() => {
    if (notebook.dataConnections.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <div className={styles.dataConnectionsList}>
        {dataConnectionConnectorsListNode}
      </div>
    );
  }, [notebook, dataConnectionConnectorsListNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {dataConnectionsListNode}
    </div>
  );
};

NotebookDataConnectionList.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
};

NotebookDataConnectionList.defaultProps = {
  className: '',
};

export default NotebookDataConnectionList;
