import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* list() {
  const data = {
    includeData: true,
    sort: { lastCommentAt: -1 },
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.threads.list, data, getConfig()),
    [],
  );
}

export function* getOne(payload) {
  const { mongoCollection, mongoId } = payload;

  const data = { mongoCollection, mongoId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.threads.getOne, data, getConfig()),
    [],
  );
}

export function* create(payload) {
  const { mongoCollection, mongoId } = payload;

  const data = { mongoCollection, mongoId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.threads.create, data, getConfig()),
    [],
  );
}

export function* addComment(payload) {
  const { threadId, comment } = payload;

  const data = { threadId, content: comment };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.threads.addComment, data, getConfig()),
    [],
  );
}

export function* setStatus(payload) {
  const { threadId, status } = payload;

  const data = { threadId, status };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.threads.setStatus, data, getConfig()),
    [],
  );
}
