import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Avatar as AntDAvatar } from 'antd';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import AvatarUploaderModal from '@palette/components/settings/AvatarUploaderModal/AvatarUploaderModal';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './AvatarUploader.less';

const classNames = bindClassNames.bind(styles);

const DEFAULT_SIZE = 44;

const AvatarUploader = ({
  className,
  logoSrc,
  defaultContent,
  size,
  editable,
  modelType,
  onUpdated,
  user,
}) => {
  const { t } = useTranslation();

  const [editAvatarIsVisible, showEditAvatarModal] = useState(false);
  const [editTooltipVisible, setEditTooltipVisible] = useState(false);

  const handleEditClick = () => {
    setEditTooltipVisible(false);
    showEditAvatarModal(true);
  };

  const avatarNode = useMemo(() => (
    <AntDAvatar
      draggable={false}
      shape="circle"
      size={size}
      src={logoSrc}
    >
      {!logoSrc && defaultContent}
    </AntDAvatar>
  ), [size, logoSrc, defaultContent]);

  const editButtonNode = useMemo(() => {
    if (!editable) return null;

    return (
      <Tooltip
        title={t('common.global.edit')}
        visible={editTooltipVisible}
        onVisibleChange={(visible) => setEditTooltipVisible(visible)}
      >
        <Button
          className={classNames({
            editWrapper: true,
            editWrapperBig: size > DEFAULT_SIZE,
          })}
          type="link"
          icon={<PenFilled width={14} height={14} />}
          onClick={handleEditClick}
        />
      </Tooltip>
    );
  }, [editable, editTooltipVisible, setEditTooltipVisible, size, handleEditClick]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {avatarNode}
      {editButtonNode}
      {editable && editAvatarIsVisible && (
        <AvatarUploaderModal
          visible
          onClose={() => showEditAvatarModal(false)}
          onUpdated={onUpdated}
          modelType={modelType}
          logoSrc={logoSrc}
          user={user}
        />
      )}
    </div>
  );
};

AvatarUploader.propTypes = {
  className: PropTypes.string,
  logoSrc: PropTypes.string,
  size: PropTypes.number,
  editable: PropTypes.bool,
  defaultContent: PropTypes.node,
  modelType: PropTypes.oneOf(['profile', 'company', 'user']),
  onUpdated: PropTypes.func,
  user: MetaUserModel.propTypes,
};

AvatarUploader.defaultProps = {
  className: '',
  logoSrc: '',
  size: DEFAULT_SIZE,
  editable: false,
  defaultContent: null,
  modelType: 'profile',
  onUpdated: () => {},
  user: null,
};

export default AvatarUploader;
