import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageNumberAttribute,
  manageArrayAttribute,
} from '@palette/helpers/ModelHelper';

import * as QuotaPerformancePlanModel from '@palette/models/QuotaPerformancePlan';

export const modelName = 'QuotaPerformance';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  totalPlans: PropTypes.number,
  plans: PropTypes.arrayOf(QuotaPerformancePlanModel.propTypes),
});

export const transform = (quotaPerformance) => {
  if (!quotaPerformance) {
    return null;
  }

  const plansArray = manageArrayAttribute(quotaPerformance, 'achievements', null);
  const plansInRaw = plansArray !== null;

  return (
    {
      id: manageStringAttribute(quotaPerformance, '_id'),
      name: manageStringAttribute(quotaPerformance, 'name'),
      description: manageStringAttribute(quotaPerformance, 'description'),
      totalPlans: manageNumberAttribute(quotaPerformance, 'plansTotal'),
      plans: QuotaPerformancePlanModel.transformList(manageArrayAttribute(quotaPerformance, 'achievements')),
      plansInRaw,
    }
  );
};

export const transformList = (quotasPerformance) => quotasPerformance.map((quotaPerformance) => transform(quotaPerformance));

export const merge = (quotaPerformance1, quotaPerformance2) => {
  if (!quotaPerformance2) return quotaPerformance1;
  if (!quotaPerformance1) return quotaPerformance2;

  let mergedTotalPlans = quotaPerformance1.totalPlans;
  if (quotaPerformance2.totalPlans > 0) {
    mergedTotalPlans = quotaPerformance2.totalPlans;
  }

  let mergedPlans = quotaPerformance1.plans;
  if (quotaPerformance2.plansInRaw) {
    mergedPlans = quotaPerformance2.plans;
  }

  return {
    ...quotaPerformance2,
    totalPlans: mergedTotalPlans,
    plans: mergedPlans,
  };
};
