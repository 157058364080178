import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PayrollProvider';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  teamId: PropTypes.string,
});

export const transform = (payrollProvider) => {
  if (!payrollProvider) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(payrollProvider, '_id'),
      name: manageStringAttribute(payrollProvider, 'name'),
      teamId: manageStringAttribute(payrollProvider, 'team', null),
    }
  );
};

export const transformList = (payrollProviders) => payrollProviders.map((payrollProvider) => transform(payrollProvider));
