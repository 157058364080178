import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import LeftMainContentLayout from '@palette/components/designSystem/LeftMainContentLayout/LeftMainContentLayout';
import UserStatementInvestigateHeader from '@palette/components/statement/userStatementInvestigate/UserStatementInvestigateHeader/UserStatementInvestigateHeader';
import UserStatementInvestigateList from '@palette/components/statement/userStatementInvestigate/UserStatementInvestigateList/UserStatementInvestigateList';
import UserStatementInvestigateMainDrawer from '@palette/components/statement/userStatementInvestigate/UserStatementInvestigateMainDrawer/UserStatementInvestigateMainDrawer';

import { usePeriodsDatesFilterAndCurrencyQSObject, useStatementUserCorrectionStatementIdInParams } from '@palette/hooks/StatementHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementModel from '@palette/models/UserStatement';

import { actions as StatementsActions } from '@palette/state/Statements';

import styles from './UserStatementInvestigateContent.less';

const classNames = bindClassNames.bind(styles);

const UserStatementInvestigateContent = ({ className, statementPeriod, userStatement, currency }) => {
  const dispatch = useDispatch();
  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const { correctionToInvestigate, userStatementCorrectionSummaries, userStatementCorrectionSummariesIsPending } = useStatementUserCorrectionStatementIdInParams();

  const [summarySelected, setSummarySelected] = useState(null);

  useEffect(() => {
    if (!correctionToInvestigate) {
      redirectTo({
        path: routePaths.v2.statementsUserStatement,
        params: { statementPeriodId: statementPeriod.id, userId: userStatement.user.id },
        qsObject: periodsDatesFilterQSObject,
      });
    } else if (summarySelected) {
      const { plan, deal, periodId } = userStatementCorrectionSummaries.filter((summary) => summary.id === summarySelected)[0];

      dispatch(StatementsActions.getUserStatementCorrectionDetails({
        correctionStatementId: correctionToInvestigate.from.statementId,
        correctionSummaryId: summarySelected,
        planId: plan.id,
        dealId: deal.id,
        periodId,
      }));
    }
  }, [correctionToInvestigate, summarySelected, userStatementCorrectionSummaries]);

  const handleClickSummary = (summaryId) => {
    setSummarySelected(summaryId);
  };

  const dealName = useCallback(() => {
    if (!summarySelected) return null;

    return userStatementCorrectionSummaries.filter((summary) => summary.id === summarySelected)[0].deal.name;
  }, [userStatementCorrectionSummaries, summarySelected]);

  if (!correctionToInvestigate) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <LeftMainContentLayout
        leftSideHeader={(
          <UserStatementInvestigateHeader
            className={styles.header}
            userStatement={userStatement}
            statementPeriod={statementPeriod}
            correction={correctionToInvestigate}
            currency={currency}
          />
        )}
        mainContent={(
          <UserStatementInvestigateList
            className={styles.main}
            summaries={userStatementCorrectionSummaries}
            isPending={userStatementCorrectionSummariesIsPending}
            onClickSummary={handleClickSummary}
            summarySelected={summarySelected}
          />
        )}
      />
      <UserStatementInvestigateMainDrawer
        statementId={correctionToInvestigate.from.statementId}
        summaryId={summarySelected}
        dealName={dealName()}
        handleClose={() => setSummarySelected(null)}
        visible={summarySelected !== null}
      />
    </div>
  );
};

UserStatementInvestigateContent.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  userStatement: UserStatementModel.propTypes,
  currency: PropTypes.string,
};

UserStatementInvestigateContent.defaultProps = {
  className: '',
  userStatement: null,
  currency: null,
};

export default UserStatementInvestigateContent;
