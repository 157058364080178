import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Button from '@palette/components/designSystem/Button/Button';
import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';

import * as MetaUserModel from '@palette/models/MetaUser';

import { ALERT_TYPES } from '@palette/constants/alert';

import styles from './UsersSelectedPanel.less';

const classNames = bindClassNames.bind(styles);

const UsersSelectedPanel = ({ className, selectedUsers, noSelectedUsersWarningMessage, onRemove }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        selectedUsers.length === 0 && (
          <Alert
            className={styles.warning}
            type={ALERT_TYPES.WARNING}
            message={noSelectedUsersWarningMessage || t('usersSelectedPanel.warning.noNewSelectedUsers')}
          />
        )
      }
      {
        selectedUsers.map((user) => (
          <div
            key={user.id}
            className={styles.profileWrapper}
          >
            <UserProfile
              className={styles.profile}
              user={user}
              vertical
            />
            {
              onRemove !== null && (
                <div className={styles.onRemoveBtnWrapper}>
                  <Tooltip title={t('usersSelectedPanel.removeUser')}>
                    <Button
                      className={styles.onRemoveBtn}
                      type="linkSecondaryBlack"
                      icon={(<ClosePopupLine width={24} height={24} />)}
                      onClick={() => onRemove(user)}
                    />
                  </Tooltip>
                </div>
              )
            }
          </div>
        ))
      }
    </div>
  );
};

UsersSelectedPanel.propTypes = {
  className: PropTypes.string,
  selectedUsers: PropTypes.arrayOf(MetaUserModel.propTypes),
  noSelectedUsersWarningMessage: PropTypes.string,
  onRemove: PropTypes.func,
};

UsersSelectedPanel.defaultProps = {
  className: '',
  selectedUsers: [],
  noSelectedUsersWarningMessage: null,
  onRemove: null,
};

export default UsersSelectedPanel;
