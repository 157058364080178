import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import WidgetBase from '@palette/components/widgets/dashboard/WidgetBase/WidgetBase';
import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';
import WidgetPeriodYearSelector from '@palette/components/widgets/dashboard/WidgetPeriodYearSelector/WidgetPeriodYearSelector';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Button from '@palette/components/designSystem/Button/Button';
import MyMasterPlansListSelect from '@palette/components/ic/myMasterPlan/MyMasterPlansListSelect/MyMasterPlansListSelect';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getFrequencyName } from '@palette/helpers/MasterPlanHelper';
import { buildWidgetParams } from '@palette/helpers/DashboardHelper';

import { DASHBOARD_WIDGETS } from '@palette/constants/dashboard';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import { actions as DashboardActions } from '@palette/state/Dashboard';

import styles from './PlanWidgetBase.less';

const classNames = bindClassNames.bind(styles);

const PlanWidgetBase = ({
  className,
  widgetIndex,
  useMostRecent,
  plan,
  period,
  title,
  children,
  readOnly,
  enableLinks,
}) => {
  const profile = useProfile();
  const dispatch = useDispatch();

  const [planEditionMode, enablePlanEditionMode] = useState(false);

  const onUpdatePlanIdSuccess = useCallback(() => {
    enablePlanEditionMode(false);
  }, []);

  const handleChangePlanId = useCallback((newPlanId) => {
    const widgetDesc = profile.dashboardComponents[widgetIndex];
    const allParams = {
      ...widgetDesc.params,
      planId: newPlanId,
      period: null,
      useMostRecent: true,
    };

    dispatch(DashboardActions.updateWidget({
      widgetIndex,
      widgetType: widgetDesc.type,
      params: buildWidgetParams(widgetDesc.type, allParams),
      onSuccessCB: onUpdatePlanIdSuccess,
    }));
  }, [profile, widgetIndex, onUpdatePlanIdSuccess]);

  const handlePeriodYearUpdate = useCallback((newPeriod) => {
    const widgetDesc = profile.dashboardComponents[widgetIndex];
    const allParams = {
      ...widgetDesc.params,
      period: newPeriod,
    };

    dispatch(DashboardActions.updateWidget({ widgetIndex, widgetType: widgetDesc.type, params: buildWidgetParams(widgetDesc.type, allParams) }));
  }, [profile, widgetIndex]);

  const handleUseMostRecentUpdate = useCallback((newUseMostRecent) => {
    const widgetDesc = profile.dashboardComponents[widgetIndex];
    let newPeriod = {};
    if (newUseMostRecent) {
      newPeriod = {
        period: null,
      };
    }

    const allParams = {
      ...widgetDesc.params,
      useMostRecent: newUseMostRecent,
      ...newPeriod,
    };
    dispatch(DashboardActions.updateWidget({ widgetIndex, widgetType: widgetDesc.type, params: buildWidgetParams(widgetDesc.type, allParams) }));
  }, [profile, widgetIndex]);

  const isAvailableForWidget = useCallback((planCandidate) => {
    const widgetDesc = profile.dashboardComponents[widgetIndex];
    return DASHBOARD_WIDGETS[widgetDesc.type].isAvailableForProfileAndPlan(profile, planCandidate);
  }, [profile]);

  const planNameNode = useMemo(() => {
    if (!readOnly && planEditionMode) {
      return (
        <div className={styles.planSelectWrapper}>
          <MyMasterPlansListSelect
            value={plan.id}
            onChange={handleChangePlanId}
            bordered={false}
            filterFunc={isAvailableForWidget}
            dropdownMatchSelectWidth={false}
          />
          <Button
            className={styles.nameEditCancelButton}
            type="linkDestroy"
            icon={<ClosePopupFilled width={14} height={14} />}
            onClick={() => enablePlanEditionMode(false)}
          />
        </div>
      );
    }

    return (
      <div className={styles.nameWrapper}>
        <Link
          className={styles.nameLink}
          path={routePaths.v2.myPlanDetails}
          params={{ masterPlanId: plan.id }}
          disabled={readOnly && !enableLinks}
        >
          {plan.name}
        </Link>
        {
          !readOnly && (
            <Button
              className={styles.nameEditButton}
              type="link"
              icon={<PenFilled width={14} height={14} />}
              onClick={() => enablePlanEditionMode(true)}
            />
          )
        }
      </div>
    );
  }, [plan, planEditionMode, handleChangePlanId, isAvailableForWidget, readOnly, enableLinks]);

  const periodSelectorNode = useMemo(() => {
    if (period === null) return null;

    return (
      <WidgetPeriodYearSelector
        className={styles.periodSelector}
        plan={plan}
        period={period}
        useMostRecent={useMostRecent}
        onPeriodUpdate={handlePeriodYearUpdate}
        onUseMostRecentUpdate={handleUseMostRecentUpdate}
        disabled={readOnly}
      />
    );
  }, [plan, period, useMostRecent, readOnly]);

  if (!readOnly && !plan.live) {
    return (
      <WidgetBase
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
        widgetIndex={widgetIndex}
        readOnly={readOnly}
      >
        <DefaultEmptyState className={styles.emptyState} />
      </WidgetBase>
    );
  }

  return (
    <WidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      readOnly={readOnly}
    >
      <div className={styles.header}>
        {planNameNode}
        <div className={styles.detailsWrapper}>
          <div className={styles.frequency}>
            {getFrequencyName(plan)}
          </div>
          <MasterPlanScopeTags plan={plan} className={styles.scope} />
        </div>
        {periodSelectorNode}
      </div>
      {
        title !== null && (
          <div className={styles.title}>
            {title}
          </div>
        )
      }
      <div className={styles.content}>
        {children}
      </div>
    </WidgetBase>
  );
};

PlanWidgetBase.propTypes = {
  className: PropTypes.string,
  widgetIndex: PropTypes.number.isRequired,
  useMostRecent: PropTypes.bool.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes,
  children: PropTypes.any,
  title: PropTypes.string,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanWidgetBase.defaultProps = {
  className: '',
  period: null,
  children: null,
  title: null,
  readOnly: false,
  enableLinks: true,
};

export default PlanWidgetBase;
