import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { formatToISOStringWithFractionalSeconds } from '@palette/helpers/MomentHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* statementsValidationsList(payload) {
  const {
    userId = null,
    periodType = null,
    periodYear = null,
  } = payload;

  const data = {
    periodType,
    periodYear,
  };

  if (userId !== null) {
    data.userId = userId;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.listStatementsValidations, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* listStatementsSnapshots(payload) {
  const {
    userId = null,
    from = null,
    to = null,
  } = payload;

  const data = {
    beginDate: formatToISOStringWithFractionalSeconds(from),
    endDate: formatToISOStringWithFractionalSeconds(to, true),
  };

  if (userId !== null) {
    data.userId = userId;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.listStatementsSnapshots, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* downloadStatementValidation(payload) {
  const { validationId } = payload;

  const data = {
    validationId,
    download: true,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.statementValidationDownload, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* downloadSnapshot(payload) {
  const { snapshotId } = payload;

  const data = {
    snapshotId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.downloadSnapshot, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* listStatementPeriods(payload) {
  const { currency, from, to, userId = null } = payload;

  const data = {
    currency: currency ?? undefined,
    beginDate: formatToISOStringWithFractionalSeconds(from),
    endDate: formatToISOStringWithFractionalSeconds(to, true),
    payeeId: userId ?? undefined,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.listStatementPeriods, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getStatementPeriodById(payload) {
  const { statementPeriodId, currency } = payload;

  const data = {
    periodId: statementPeriodId,
    currency: currency ?? undefined,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.getStatementPeriodById, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getUserStatementById(payload) {
  const { statementPeriodId, userId, currency } = payload;

  const data = {
    periodId: statementPeriodId,
    payeeId: userId,
    currency: currency ?? undefined,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.getUserStatementById, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* deleteAdjustment(payload) {
  const {
    statementId,
    adjustmentId,
  } = payload;

  const data = {
    statementId,
    adjustmentId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.deleteAdjustment, data, getConfig()),
    [RIGHTS.ADMIN.ADJUSTMENTS.MANAGE],
  );
}

export function* addAdjustment(payload) {
  const {
    statementId,
    amount,
    currency,
    reason,
  } = payload;

  const data = {
    statementId,
    amount,
    currency,
    reason,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.addAdjustment, data, getConfig()),
    [RIGHTS.ADMIN.ADJUSTMENTS.MANAGE],
  );
}

export function* updateAdjustment(payload) {
  const {
    statementId,
    adjustmentId,
    amount,
    currency,
    reason,
  } = payload;

  const data = {
    statementId,
    adjustmentId,
    amount,
    currency,
    reason,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.updateAdjustment, data, getConfig()),
    [RIGHTS.ADMIN.ADJUSTMENTS.MANAGE],
  );
}

export function* listMyStatementPeriods(payload) {
  const { from, to, inSalesforce } = payload;

  const data = {
    beginDate: formatToISOStringWithFractionalSeconds(from),
    endDate: formatToISOStringWithFractionalSeconds(to, true),
  };

  return yield checkEndpointRights(
    () => ApiService.post(
      inSalesforce
        ? apiConfig.ENDPOINTS.sf.statements.listSFStatementPeriods
        : apiConfig.ENDPOINTS.ic.statements.listMyStatementPeriods,
      data,
      getConfig(),
    ),
    inSalesforce ? [] : [RIGHTS.IC.COMPENSATION],
  );
}

export function* getICUserStatement(payload) {
  const { statementPeriodId, inSalesforce } = payload;

  const data = {
    periodId: statementPeriodId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(
      inSalesforce
        ? apiConfig.ENDPOINTS.sf.statements.getSFUserStatement
        : apiConfig.ENDPOINTS.ic.statements.getICUserStatement,
      data,
      getConfig(),
    ),
    inSalesforce ? [] : [RIGHTS.IC.COMPENSATION],
  );
}

export function* approveStatements(payload) {
  const { statementPeriodId, statementIds } = payload;

  const data = {
    periodId: statementPeriodId,
    statementIds,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.approveStatements, data, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* cancelStatementApproval(payload) {
  const { statementId } = payload;

  const data = {
    statementId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.cancelStatementApproval, data, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* markAsPaidStatements(payload) {
  const { statementPeriodId, statementIds } = payload;

  const data = {
    periodId: statementPeriodId,
    statementIds,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.markAsPaidStatements, data, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* ignoreCorrection(payload) {
  const {
    statementId,
    correctionStatementId,
  } = payload;

  const data = {
    statementNeedingCorrection: correctionStatementId,
    statementIgnoringCorrection: statementId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.ignoreCorrection, data, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* investigateCorrectionSummaries(payload) {
  const {
    correctionStatementId,
  } = payload;

  const data = {
    statementId: correctionStatementId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.investigateCorrectionSummaries, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* investigateCorrectionDetails(payload) {
  const {
    correctionStatementId,
    planId,
    dealId,
    periodId,
  } = payload;

  const data = {
    statementId: correctionStatementId,
    planId,
    dealId,
    periodId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.investigateCorrectionDetails, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* createCorrection(payload) {
  const {
    statementId,
    correctionStatementId,
    amount,
    currency,
    reason,
  } = payload;

  const data = {
    statementNeedingCorrection: correctionStatementId,
    statementToCorrect: statementId,
    amount,
    currency,
    reason,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.applyCorrection, data, getConfig()),
    [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE],
  );
}

export function* downloadPeriodUsersStatements(payload) {
  const {
    statementIds,
    statementPeriodId,
    currency,
  } = payload;

  const data = {
    periodId: statementPeriodId,
    currency: currency ?? undefined,
    statementIds: statementIds ?? undefined,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.downloadPeriodUsersStatements, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* downloadUserStatement(payload) {
  const {
    user,
    statementPeriodId,
    currency,
  } = payload;

  const data = {
    payeeId: user.id,
    periodId: statementPeriodId,
    currency: currency ?? undefined,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.downloadUserStatements, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* downloadMyUserStatements(payload) {
  const {
    statementPeriodId,
  } = payload;

  const data = {
    periodId: statementPeriodId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.statements.downloadMyUserStatements, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* exportToPayroll(payload) {
  const {
    payrollProviderId,
    statementPeriodId,
    statementIds,
    currency,
  } = payload;

  const data = {
    payrollProviderId,
    periodId: statementPeriodId,
    statementIds: statementIds ?? undefined,
    currency: currency ?? undefined,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.exportToPayroll, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* markAsDirty(payload) {
  const {
    statementId,
  } = payload;

  const data = {
    statementId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.statements.markAsDirty, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}
