import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './CompanyBillingForm.less';

const classNames = bindClassNames.bind(styles);

const CompanyBillingForm = ({ className, seats, minSeats }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const updateSubscriptionSeatsIsPending = useSelector(ProfileSelectors.updateSubscriptionSeatsIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    quantity: seats,
  }), []);

  const handleFinish = useCallback((values) => {
    dispatch(ProfileActions.updateSubscriptionSeats({ ...values }));
  }, []);

  const handleSubmit = () => form.submit();

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form
        className={styles.form}
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <FormItem
          name="quantity"
          required
          rules={[
            { required: true, message: t('companyBilling.block.form.error.required.message') },
            { type: 'number', min: minSeats, message: t('companyBilling.block.form.error.min.message', { count: minSeats }) },
          ]}
        >
          <Input
            className={styles.input}
            type="number"
            min={minSeats}
            disabled={updateSubscriptionSeatsIsPending}
            addonBefore={t('companyBilling.block.form.addon.label')}
          />
        </FormItem>
        <Button
          onClick={handleSubmit}
          disabled={updateSubscriptionSeatsIsPending}
        >
          {t('companyBilling.block.form.submit.label')}
        </Button>
      </Form>
    </div>
  );
};

CompanyBillingForm.propTypes = {
  className: PropTypes.string,
  seats: PropTypes.number.isRequired,
  minSeats: PropTypes.number,
};

CompanyBillingForm.defaultProps = {
  className: '',
  minSeats: 0,
};

export default CompanyBillingForm;
