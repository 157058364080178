import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { getLocalTimezone } from '@palette/helpers/MomentHelper';

import apiConfig from '@palette/config/api';

import { RIGHTS } from '@palette/constants/profile';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* updateDashboardComponents(payload) {
  const { components } = payload;

  const data = components;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboard.updateComponents, data, getConfig()),
    [RIGHTS.IC.DASHBOARD.UPDATE],
  );
}

export function* listDashboardComponents() {
  const timezone = getLocalTimezone();
  const data = {
    timezone,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboard.listComponents, data, getConfig()),
    [],
  );
}
