export const TYPES = {
  EVENT: 'EVENT',
  MESSAGE: 'MESSAGE',
};

export const EVENT_CONTENT_STATUSES = {
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
};

export const EVENT_CONTENT_ACTIONS = {
  COMMISSION_CHANGED: 'COMMISSION_CHANGED',
  COMMISSION_UPDATED: 'COMMISSION_UPDATED',
  COMMISSION_VALUE_UPDATED: 'COMMISSION_VALUE_UPDATED',
  PAYMENTS_UPDATED: 'PAYMENTS_UPDATED',
  STATUS_CHANGED: 'STATUS_CHANGED',
  STATUS_UPDATED: 'STATUS_UPDATED',
};
