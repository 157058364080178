import PropTypes from 'prop-types';

import { manageAmountAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'CommissionsDueEstimateData';

export const propTypes = PropTypes.shape({
  commissionsDueEstimateAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
});

export const transform = (commissionsDueEstimateData) => {
  if (!commissionsDueEstimateData) {
    return null;
  }

  return (
    {
      commissionsDueEstimateAmount: manageAmountAttribute(commissionsDueEstimateData, 'futurePayout'),
      currency: manageStringAttribute(commissionsDueEstimateData, 'currency'),
    }
  );
};
