import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES } from '@palette/constants/notebooks';

export const modelName = 'NotebookDataSelectorBlockListItem';

export const propTypes = PropTypes.shape({
  key: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(Object.values(NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES)),
});

export const transform = (notebookDataSelectorBlockListItem) => {
  if (!notebookDataSelectorBlockListItem) {
    return null;
  }

  const rawType = manageStringAttribute(notebookDataSelectorBlockListItem, 'type');
  const type = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES[rawType] ?? NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.SELECTABLE;

  return (
    {
      key: manageStringAttribute(notebookDataSelectorBlockListItem, 'key'),
      label: manageStringAttribute(notebookDataSelectorBlockListItem, 'label'),
      type,
    }
  );
};

export const transformList = (currencies) => currencies.map((notebookDataSelectorBlockListItem) => transform(notebookDataSelectorBlockListItem));
