import {
  INPUT_TABLE_CELL_INDEX_SEPARATOR,
  INPUT_TABLE_CELL_TYPES,
  LONG_CONTENT_LENGTH_COLUMN_MULTIPLICATOR,
  MEDIUM_CONTENT_LENGTH,
  MEDIUM_CONTENT_LENGTH_COLUMN_MULTIPLICATOR,
  SHORT_CONTENT_LENGTH,
  SHORT_CONTENT_LENGTH_COLUMN_MULTIPLICATOR,
} from '@palette/constants/global';

export const getColumnWidthAsFiniteNumber = (column) => {
  if (Number.isFinite(column.totalWidth)) return column.totalWidth;

  if (Number.isFinite(column.width)) return column.width;

  return column.totalMinWidth;
};

export const getColumnWidth = (column) => {
  if (!Number.isNaN(column.totalWidth)) return column.totalWidth;

  if (!Number.isNaN(column.width)) return column.width;

  return column.totalMinWidth;
};

export const computeColumnWidthFromContentLength = (contentLength) => {
  if (contentLength < SHORT_CONTENT_LENGTH) {
    return contentLength * SHORT_CONTENT_LENGTH_COLUMN_MULTIPLICATOR;
  }

  if (contentLength < MEDIUM_CONTENT_LENGTH) {
    return contentLength * MEDIUM_CONTENT_LENGTH_COLUMN_MULTIPLICATOR;
  }

  return contentLength * LONG_CONTENT_LENGTH_COLUMN_MULTIPLICATOR;
};

export const buildCellIndex = ({ column, row }) => (`${column.id}${INPUT_TABLE_CELL_INDEX_SEPARATOR}${row.index}`);

export const extractCellColumnAndRowIndex = (cellIndex) => cellIndex.split(INPUT_TABLE_CELL_INDEX_SEPARATOR);

export const convertCellValue = (cellValue, formerCellType, newCellType) => {
  if (formerCellType === INPUT_TABLE_CELL_TYPES.BOOLEAN) return '';

  if (newCellType === INPUT_TABLE_CELL_TYPES.BOOLEAN) {
    return cellValue.toLowerCase() === 'false' ? false : Boolean(cellValue);
  }

  if (newCellType === INPUT_TABLE_CELL_TYPES.NUMBER) {
    const parsedValue = parseFloat(cellValue);
    return Number.isNaN(parsedValue) ? '' : parsedValue;
  }

  return cellValue.toString();
};
