import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import QuotaLockedIndicator from '@palette/components/quota/QuotaLockedIndicator/QuotaLockedIndicator';
import EditablePeriodYear from '@palette/components/frequencies/EditablePeriodYear/EditablePeriodYear';

import { isInitialDefaultValues } from '@palette/helpers/QuotaHelper';
import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as YearPeriodValueModel from '@palette/models/YearPeriodValue';

import styles from './DefaultValueStartingDateCell.less';

const classNames = bindClassNames.bind(styles);

const DefaultValueStartingDateCell = ({
  className,
  quotaPeriodType,
  quotaFrequency,
  defaultValue,
  locked,
  onUpdate,
  index,
  toDelete,
  allowDeleteAll,
  quotaBeginAt,
}) => {
  const { t } = useTranslation();

  const lockedIndicator = useMemo(() => {
    if (locked) {
      return (<QuotaLockedIndicator className={styles.lockedIndicator} />);
    }

    return null;
  }, [locked]);

  const handlePeriodYearUpdate = ({ period, year }) => {
    onUpdate({ ...defaultValue, period, year }, index);
  };

  const getEditablePeriodYearContentDisplay = (periodType, frequency, year, period, beginAt) => {
    const periodName = getFrequencyPeriodName(periodType, frequency, year, period, beginAt);

    return t('periodYear.from', { periodName });
  };

  const bodyNode = useMemo(() => {
    const periodName = getFrequencyPeriodName(quotaPeriodType, quotaFrequency, defaultValue.year, defaultValue.period, quotaBeginAt);

    const startingDateDisplay = !allowDeleteAll && isInitialDefaultValues(defaultValue)
      ? t('quota.defaultValue.initial')
      : t('periodYear.from', { periodName });

    if (locked || (!allowDeleteAll && isInitialDefaultValues(defaultValue))) {
      return startingDateDisplay;
    }

    return (
      <EditablePeriodYear
        className={classNames({
          editablePeriodYear: true,
          toDelete,
        })}
        contentClassName={styles.editablePeriodYearContent}
        popoverVisibleClassName={styles.popoverVisible}
        periodType={quotaPeriodType}
        frequency={quotaFrequency}
        initialPeriod={defaultValue.period}
        initialYear={defaultValue.year}
        onUpdate={handlePeriodYearUpdate}
        getContentDisplay={getEditablePeriodYearContentDisplay}
        beginDate={quotaBeginAt}
      />
    );
  }, [locked, defaultValue]);

  return (
    <div
      className={classNames({
        wrapper: true,
        editable: !locked && (allowDeleteAll || !isInitialDefaultValues(defaultValue)),
        toDelete,
        [className]: className !== '',
      })}
    >
      {lockedIndicator}
      {bodyNode}
    </div>
  );
};

DefaultValueStartingDateCell.propTypes = {
  className: PropTypes.string,
  quotaPeriodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)).isRequired,
  quotaFrequency: PropTypes.number.isRequired,
  defaultValue: YearPeriodValueModel.propTypes.isRequired,
  locked: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  toDelete: PropTypes.bool.isRequired,
  allowDeleteAll: PropTypes.bool,
  quotaBeginAt: PropTypes.string,
};

DefaultValueStartingDateCell.defaultProps = {
  className: '',
  allowDeleteAll: false,
  quotaBeginAt: null,
};

export default DefaultValueStartingDateCell;
