import React from 'react';
import _invert from 'lodash/invert';

import NotebookTextCellContent from '@palette/components/notebooks/NotebookTextCellContent/NotebookTextCellContent';
import NotebookCodeEditorCellContent from '@palette/components/notebooks/NotebookCodeEditorCellContent/NotebookCodeEditorCellContent';
import NotebookRunnableCellPreview from '@palette/components/notebooks/NotebookRunnableCellPreview/NotebookRunnableCellPreview';
import NotebookMarkdownCellPreview from '@palette/components/notebooks/NotebookMarkdownCellPreview/NotebookMarkdownCellPreview';

import {
  NOTEBOOK_CELL_TYPES, NOTEBOOK_CELL_TYPES_IDS,
} from '@palette/constants/notebooks';

export const getCellContentComponentFromType = (cellTypeId) => {
  switch (cellTypeId) {
    case NOTEBOOK_CELL_TYPES.SQL.id:
    case NOTEBOOK_CELL_TYPES.MARKDOWN.id:
    case NOTEBOOK_CELL_TYPES.PYTHON.id:
    case NOTEBOOK_CELL_TYPES.JS.id: {
      return NotebookCodeEditorCellContent;
    }
    case NOTEBOOK_CELL_TYPES.TEXT.id: {
      return NotebookTextCellContent;
    }
    default: {
      return null;
    }
  }
};

export const getCellContentNodeFromType = (
  cellTypeId,
  currentContent,
  handleContentChange,
  handleCellContentFocus,
  handleCellContentBlur,
  disabled,
) => {
  const CellContentComponent = getCellContentComponentFromType(cellTypeId);
  const cellTypeKey = _invert(NOTEBOOK_CELL_TYPES_IDS)[cellTypeId];
  const cellType = NOTEBOOK_CELL_TYPES[cellTypeKey];

  let codeEditorCellContentProps = {};
  if (cellType.codeEditorLanguage) {
    codeEditorCellContentProps = {
      codeEditorLanguage: cellType.codeEditorLanguage,
    };
  }

  return (
    <CellContentComponent
      content={currentContent}
      onUpdate={handleContentChange}
      onFocus={handleCellContentFocus}
      onBlur={handleCellContentBlur}
      disabled={disabled}
      {...codeEditorCellContentProps}
    />
  );
};

export const getCellPreviewComponentFromType = (cellType) => {
  switch (cellType) {
    case NOTEBOOK_CELL_TYPES.SQL.id:
    case NOTEBOOK_CELL_TYPES.PYTHON.id:
    case NOTEBOOK_CELL_TYPES.JS.id:
    {
      return NotebookRunnableCellPreview;
    }
    case NOTEBOOK_CELL_TYPES.MARKDOWN.id: {
      return NotebookMarkdownCellPreview;
    }
    default: {
      return null;
    }
  }
};
