import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { getCommissionValue, getDealName } from '@palette/helpers/CommissionHelper';
import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';

import * as CommissionModel from '@palette/models/Commission';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';

import styles from './EditCommissionValueModal.less';

const EditCommissionValueModal = ({ visible, onClose, commission, labelDefinition }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: commission.scopeParams?.plan?.trackingObject?.connectorId || '' }));

  const overwriteValueIsPending = useSelector(CommissionsSelectors.overwriteValueIsPending);

  const dealName = useMemo(() => {
    if (connector !== null) {
      return getDealName(commission, connector);
    }

    return '';
  }, [commission, connector]);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    value: floatToFixedNumber(getCommissionValue(commission)),
  }), [commission]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const cleanAndClose = () => {
    onClose();
  };

  const handleFinish = (values) => {
    let newValue = parseFloat(values.value);

    if (newValue === commission.value) {
      newValue = undefined;
    }

    if (
      (!newValue && newValue !== 0 && !commission.overwrittenValue)
      || (newValue === parseFloat(initialValues.value))
    ) {
      cleanAndClose();
      return;
    }

    dispatch(CommissionsActions.overwriteValue({ commissionId: commission.id, value: newValue, onSuccessCB: cleanAndClose }));
  };

  const handleOverwriteValue = () => form.submit();

  const handleResetToOriginalValue = () => {
    dispatch(CommissionsActions.overwriteValue({ commissionId: commission.id, value: undefined, onSuccessCB: cleanAndClose }));
  };

  let resetValueNode = null;

  if (commission.overwrittenValue !== null) {
    const initialValue = comaSeparatorFormatter(floatToFixedNumber(commission.value));

    resetValueNode = (
      <Popconfirm
        title={t('editCommissionValueModal.resetToOriginalAmount.popconfirm.title')}
        onConfirm={handleResetToOriginalValue}
        okText={t('editCommissionValueModal.resetToOriginalAmount.popconfirm.confirm')}
        cancelText={t('editCommissionValueModal.resetToOriginalAmount.popconfirm.cancel')}
        size="small"
        disabled={overwriteValueIsPending}
      >
        <Button
          className={styles.resetToOriginalAmountButton}
          type="link"
          disabled={overwriteValueIsPending}
        >
          {t('editCommissionValueModal.resetToOriginalAmount.popconfirm.cta', { value: initialValue })}
        </Button>
      </Popconfirm>
    );
  }

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {dealName !== '' && t('editCommissionValueModal.labelDefinitionFor', { labelDefinition, dealName })}
          {dealName === '' && labelDefinition}
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleOverwriteValue}
      okText={t('editCommissionValueModal.overwriteValue')}
      okIcon={<CheckFilled />}
      loading={overwriteValueIsPending}
      extraFooterNode={resetValueNode}
    >
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <FormItem
          name="value"
          label={t('editCommissionValueModal.form.amount.label')}
        >
          <Input size="big" type="comaSeparatorFormatted" disabled={overwriteValueIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

EditCommissionValueModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  commission: CommissionModel.propTypes.isRequired,
  labelDefinition: PropTypes.string.isRequired,
};

EditCommissionValueModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default EditCommissionValueModal;
