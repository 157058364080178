import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';
import Popover from '@palette/components/designSystem/Popover/Popover';
import Tag from '@palette/components/designSystem/Tag/Tag';

import { useMasterPlanColumns } from '@palette/hooks/MasterPlanHooks';

import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';
import { getColumnValue } from '@palette/helpers/ConnectorHelper';

import { SEARCHED_DEAL_QS_KEY } from '@palette/constants/masterPlans';
import { TAG_THEMES } from '@palette/constants/tag';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';
import * as LatestObjectsDataModel from '@palette/models/widgets/dashboard/LatestObjectsData';

import styles from './PlanWithPeriods.less';

const classNames = bindClassNames.bind(styles);

const PlanWithPeriods = ({
  className,
  deal,
  plan,
  periods,
  readOnly,
  enableLinks,
}) => {
  const { t } = useTranslation();

  const planColumns = useMasterPlanColumns(plan);

  const planNameNode = useMemo(() => (
    <div className={styles.nameWrapper}>
      <div className={styles.name}>
        {plan.name}
      </div>
      <MasterPlanScopeTags className={styles.scope} plan={plan} />
    </div>
  ), [plan, readOnly, enableLinks]);

  const periodsNodes = useMemo(() => (
    periods.map((period) => {
      const linkLabel = getPlanPeriodName(plan, period);

      const dealName = planColumns.length > 0 ? getColumnValue(deal.resourceObject?.data || {}, planColumns[0]) : '';

      return (
        <Link
          key={linkLabel}
          className={styles.periodNameLink}
          displayIcon
          path={routePaths.v2.myPlanPeriodDetails}
          params={{ masterPlanId: plan.id, year: period.year, periodId: period.period }}
          qsObject={{ [SEARCHED_DEAL_QS_KEY]: dealName }}
          disabled={readOnly && !enableLinks}
        >
          {linkLabel}
        </Link>
      );
    })
  ), [deal, planColumns, plan, periods, readOnly, enableLinks]);

  const popoverNode = useMemo(() => {
    const periodsWrapperNode = (
      <div className={styles.periodsWrapper}>
        {periodsNodes}
      </div>
    );

    if (periods.length === 1) {
      return (
        <Tag
          className={styles.periodsCountTag}
          theme={TAG_THEMES.INFO}
          label={periodsWrapperNode}
        />
      );
    }

    return (
      <Popover
        trigger="hover"
        content={periodsWrapperNode}
      >
        <div>
          <Tag
            className={styles.periodsCountTag}
            theme={TAG_THEMES.INFO}
            label={t('planWithPeriods.periods', { count: periods.length })}
          />
        </div>
      </Popover>
    );
  }, [periods]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {planNameNode}
      {popoverNode}
    </div>
  );
};

PlanWithPeriods.propTypes = {
  className: PropTypes.string,
  deal: LatestObjectsDataModel.dealPropTypes.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
  periods: PropTypes.arrayOf(YearPeriodModel.propTypes).isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanWithPeriods.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlanWithPeriods;
