import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import NameFormItem from '@palette/components/planV3/planV3FormItems/NameFormItem/NameFormItem';
import DescriptionFormItem from '@palette/components/planV3/planV3FormItems/DescriptionFormItem/DescriptionFormItem';
import FrequencyFormItem from '@palette/components/planV3/planV3FormItems/FrequencyFormItem/FrequencyFormItem';
import StartPeriodFormItem from '@palette/components/planV3/planV3FormItems/StartPeriodFormItem/StartPeriodFormItem';
import EndPeriodFormItem from '@palette/components/planV3/planV3FormItems/EndPeriodFormItem/EndPeriodFormItem';
import DayIntervalFormItem from '@palette/components/planV3/planV3FormItems/DayIntervalFormItem/DayIntervalFormItem';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

import styles from './PlanV3FormRequirementsGeneral.less';

const classNames = bindClassNames.bind(styles);

const PlanV3FormRequirementsGeneral = ({ className, isPending, frequencyType }) => {
  const { t } = useTranslation();

  const [showEndPeriodField, setShowEndPeriodField] = useState(frequencyType === PLAN_PERIODICITY_TYPE.CUSTOM);

  useEffect(() => {
    if (frequencyType === PLAN_PERIODICITY_TYPE.CUSTOM) {
      setShowEndPeriodField(true);
    }
  }, [frequencyType]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <NameFormItem disabled={isPending} />
          <FrequencyFormItem disabled={isPending} />
          {frequencyType === PLAN_PERIODICITY_TYPE.DAY && <DayIntervalFormItem disabled={isPending} />}
        </div>
        <div className={classNames(styles.row, styles.orderFirst)}>
          <DescriptionFormItem disabled={isPending} />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.row}>
          <StartPeriodFormItem disabled={isPending} />
          <div className={styles.buttonSuppContainer}>
            {!showEndPeriodField && (
              <Button
                className={styles.suppButton}
                type="link"
                icon={<AddFilled width={18} height={18} />}
                onClick={() => setShowEndPeriodField(true)}
              >
                {t('masterPlanSettingsGeneral.form.setEndPeriod')}
              </Button>
            )}
          </div>
        </div>
        <div className={styles.row}>
          {showEndPeriodField && (
            <>
              <EndPeriodFormItem disabled={isPending} isRequired={frequencyType === PLAN_PERIODICITY_TYPE.CUSTOM} />
              <div className={styles.buttonSuppContainer}>
                {frequencyType !== PLAN_PERIODICITY_TYPE.CUSTOM && (
                  <Button
                    className={styles.suppButton}
                    type="linkDestroy"
                    icon={<TrashFilled width={18} height={18} />}
                    onClick={() => setShowEndPeriodField(false)}
                  >
                    {t('createPlanPage.form.remove.endPeriod.label')}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

PlanV3FormRequirementsGeneral.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  frequencyType: PropTypes.oneOf(Object.values(PLAN_PERIODICITY_TYPE)),
};

PlanV3FormRequirementsGeneral.defaultProps = {
  className: '',
  isPending: false,
  frequencyType: PLAN_PERIODICITY_TYPE.MONTH,
};

export default PlanV3FormRequirementsGeneral;
