import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Link from '@palette/components/designSystem/Link/Link';
import NotebooksProjectsListItemMoreOptionsMenu from '@palette/components/notebooks/NotebooksProjectsListItemMoreOptionsMenu/NotebooksProjectsListItemMoreOptionsMenu';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';
import { NOTEBOOK_TYPES } from '@palette/constants/notebooks';
import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import { actions as NavigationActions } from '@palette/state/Navigation';

import * as NotebookModel from '@palette/models/Notebook';
import * as PaginationModel from '@palette/models/Pagination';

import styles from './NotebooksListTable.less';

const classNames = bindClassNames.bind(styles);

const NotebooksListTable = ({ className, notebooksList, notebooksListPagination }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const profile = useProfile();
  const dispatch = useDispatch();

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const columns = useMemo(() => {
    const cols = [];

    const nameColumn = {
      id: 'notebookName',
      Header: t('notebooksListTable.header.name'),
      accessor: (notebook) => notebook,
      minWidth: 300,
      width: '30%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <Link
          displayIcon
          path={routePaths.v2.notebookDetails}
          params={{ notebookId: value.id }}
          maxWidth="26"
        >
          {value.name}
        </Link>
      ),
    };

    cols.push(nameColumn);

    const ownerColumn = {
      id: 'notebookOwner',
      Header: t('notebooksListTable.header.owner'),
      accessor: (notebook) => notebook,
      minWidth: 200,
      width: '20%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<UserProfile user={value.owner} clickable isPaletteUser={value.type === NOTEBOOK_TYPES.TEMPLATE} />),
    };

    cols.push(ownerColumn);

    const creationDateColumn = {
      id: 'notebookCreationDate',
      Header: t('notebooksListTable.header.created'),
      accessor: (notebook) => moment.utc(notebook.creationDate).format('L'),
      minWidth: 100,
      width: '10%',
    };

    cols.push(creationDateColumn);

    const lastModificationDateColumn = {
      id: 'notebookLastModificationDate',
      Header: t('notebooksListTable.header.edited'),
      accessor: (notebook) => moment.utc(notebook.lastModificationDate).format('L'),
      minWidth: 100,
      width: '10%',
    };

    cols.push(lastModificationDateColumn);

    const usedInPlansColumn = {
      id: 'notebookUsedInPlans',
      Header: t('notebooksListTable.header.usedInPlans'),
      accessor: () => '-',
      minWidth: 250,
      width: '25%',
    };

    cols.push(usedInPlansColumn);

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])) {
      const actionsColumn = {
        id: 'notebookActions',
        Header: t('notebooksListTable.header.actions'),
        accessor: (notebook) => notebook,
        minWidth: 50,
        width: '5%',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<NotebooksProjectsListItemMoreOptionsMenu notebook={value} />),
      };

      cols.push(actionsColumn);
    }

    return cols;
  }, [profile]);

  const contentNode = useMemo(() => {
    if (notebooksList.length === 0) {
      return (
        <div className={styles.emptyStateWrapper}>
          <DefaultEmptyState description={t('notebooksListTable.emptyDescription')} />
        </div>
      );
    }

    return (
      <>
        <Table
          type="borderless"
          stretch
          columns={columns}
          data={notebooksList}
        />
        <Pagination
          className={styles.pagination}
          pagination={notebooksListPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </>
    );
  }, [
    notebooksList,
    columns,
    notebooksListPagination,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

NotebooksListTable.propTypes = {
  className: PropTypes.string,
  notebooksList: PropTypes.arrayOf(NotebookModel.propTypes).isRequired,
  notebooksListPagination: PaginationModel.propTypes.isRequired,
};

NotebooksListTable.defaultProps = {
  className: '',
};

export default NotebooksListTable;
