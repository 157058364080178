import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Progress, { PROGRESS_TYPES } from '@palette/components/designSystem/Progress/Progress';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES } from '@palette/constants/profile';

import styles from './StatementPeriodPaidProgress.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodPaidProgress = ({ className, classNameProgressBar, paidCount, usersCount }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const totalNode = useMemo(() => {
    const calculResult = [];

    if (paidCount > 0) {
      calculResult.push(<span key="green" className={styles.totalGreen}>{paidCount}</span>);
    } else {
      calculResult.push(<span key="grey" className={styles.totalGrey}>0</span>);
    }

    calculResult.push(` / ${usersCount}`);

    return <div className={styles.total}>{calculResult}</div>;
  }, [paidCount, usersCount]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION)) return null;

  const getTotalPercent = (100 / usersCount) * paidCount;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.label}>
        {t('statementPeriodApprovalProgress.paid')}
      </div>
      <Progress
        className={classNames({
          progressBar: true,
          [classNameProgressBar]: classNameProgressBar !== '',
        })}
        type={PROGRESS_TYPES.SUCCESS}
        percent={getTotalPercent}
      />
      {totalNode}
    </div>
  );
};

StatementPeriodPaidProgress.propTypes = {
  className: PropTypes.string,
  classNameProgressBar: PropTypes.string,
  paidCount: PropTypes.number.isRequired,
  usersCount: PropTypes.number.isRequired,
};

StatementPeriodPaidProgress.defaultProps = {
  className: '',
  classNameProgressBar: '',
};

export default StatementPeriodPaidProgress;
