import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';

import * as RoleModel from '@palette/models/Role';

import { actions as CompanyActions, selectors as CompanySelectors } from '@palette/state/Company';

import styles from './EditableRoleName.less';

const classNames = bindClassNames.bind(styles);

const EditableRoleName = ({ className, role }) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [roleName, setRoleName] = useState(role.name);

  useEffect(() => {
    setRoleName(role.name);
  }, [role.name]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const updateRoleIsPending = useSelector(CompanySelectors.updateRoleIsPending);

  useEffect(() => {
    if (!updateRoleIsPending) {
      setEditing(false);
    }
  }, [updateRoleIsPending]);

  const saveUpdate = () => {
    if (role.name !== roleName && roleName !== '') {
      dispatch(CompanyActions.updateRole({ roleId: role.id, name: roleName }));
    } else {
      setEditing(false);
    }
  };

  const handleInputChange = (newValue) => {
    setRoleName(newValue);
  };

  const switchToEditMode = () => {
    setEditing(true);
  };

  if (editing) {
    return (
      <Input
        ref={inputRef}
        className={styles.input}
        type="text"
        value={roleName}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={saveUpdate}
      />
    );
  }

  return (
    <h1
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      onClick={switchToEditMode}
    >
      {role.name}
    </h1>
  );
};

EditableRoleName.propTypes = {
  className: PropTypes.string,
  role: RoleModel.propTypes.isRequired,
};

EditableRoleName.defaultProps = {
  className: '',
};

export default EditableRoleName;
