import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { getTypeDisplayed } from '@palette/helpers/CommonHelper';

import { EMPTY_DEFAULT_VALUE, FILTER_OBJECT_EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';

import * as ConnectorModel from '@palette/models/Connector';
import * as MasterPlanVariableModel from '@palette/models/MasterPlanVariable';

export const modelName = 'TrackingObjectDefinition';

export const propTypes = PropTypes.shape({
  connectorId: PropTypes.string,
  connector: ConnectorModel.propTypes,
  type: PropTypes.string,
  originalType: PropTypes.string,
  uiType: PropTypes.string,
  dateField: PropTypes.string,
  filterFormula: PropTypes.string,
  filterObject: PropTypes.string,
  matchingField: PropTypes.string,
  valueFormula: PropTypes.string,
  valueVariables: PropTypes.arrayOf(MasterPlanVariableModel.propTypes),
  valueDefinition: PropTypes.string,
});

export const transform = (trackingObject) => {
  if (!trackingObject) {
    return null;
  }

  let connectorId = manageStringAttribute(trackingObject, 'connector', null, true);
  let connector = null;
  if (connectorId === null) {
    connector = ConnectorModel.transform(trackingObject.connector);
    connectorId = connector?.id || '';
  }

  const originalType = manageStringAttribute(trackingObject, 'type');
  let type = getTypeDisplayed(originalType);
  const uiType = manageStringAttribute(trackingObject, 'uiType', null);
  if (uiType !== null && uiType.length > 0) {
    type = uiType;
  }

  let dateField = manageStringAttribute(trackingObject, 'dateField');
  if (dateField === EMPTY_DEFAULT_VALUE) {
    dateField = '';
  }

  let matchingField = manageStringAttribute(trackingObject, 'matchingField');
  if (matchingField === EMPTY_DEFAULT_VALUE) {
    matchingField = '';
  }

  let filterObject = manageStringAttribute(trackingObject, 'filterObject');
  if (filterObject === FILTER_OBJECT_EMPTY_DEFAULT_VALUE) {
    filterObject = '';
  }

  return (
    {
      connectorId,
      connector,
      type,
      originalType,
      uiType,
      dateField,
      filterFormula: manageStringAttribute(trackingObject, 'filterFormula'),
      filterObject,
      matchingField,
      valueFormula: manageStringAttribute(trackingObject, 'valueFormula'),
      valueVariables: MasterPlanVariableModel.transformList(manageArrayAttribute(trackingObject, 'valueVariables')),
      valueDefinition: manageStringAttribute(trackingObject, 'valueDefinition'),
    }
  );
};

export const transformList = (trackingObjects) => trackingObjects.map((trackingObject) => transform(trackingObject));

export const merge = (trackingObject1, trackingObject2) => {
  if (!trackingObject2) return trackingObject1;
  if (!trackingObject1) return trackingObject2;

  return trackingObject2;
};
