import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { getMoment } from '@palette/helpers/MomentHelper';

import {
  COMMISSION_PAYMENT_PERIOD_QS_KEY,
  COMMISSION_PAYMENT_YEAR_QS_KEY,
} from '@palette/constants/commissions';
import { YEARLY_MONTHS } from '@palette/constants/frequencies';

import { useCommissionPaymentsPageFilters } from '@palette/hooks/CommissionHooks';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CommissionPaymentsPeriodYearSelector.less';

const classNames = bindClassNames.bind(styles);

const CommissionPaymentsPeriodYearSelector = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const moment = getMoment();

  const commissionPaymentsPageFilters = useCommissionPaymentsPageFilters();

  const periodsOptions = Object.keys(YEARLY_MONTHS).map((period, index) => ({
    label: t(YEARLY_MONTHS[period]),
    value: index.toString(),
  }));

  const {
    commissionPaymentPeriod,
    commissionPaymentPeriodInSearch,
    commissionPaymentYear,
    commissionPaymentYearInSearch,
  } = useMemo(() => commissionPaymentsPageFilters, [commissionPaymentsPageFilters]);

  useEffect(() => {
    const keysToDelete = [];

    if (commissionPaymentPeriod === null && commissionPaymentPeriodInSearch) {
      keysToDelete.push(COMMISSION_PAYMENT_PERIOD_QS_KEY);
    }

    if (commissionPaymentYear === null && commissionPaymentYearInSearch) {
      keysToDelete.push(COMMISSION_PAYMENT_YEAR_QS_KEY);
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    commissionPaymentPeriod,
    commissionPaymentPeriodInSearch,
    commissionPaymentYear,
    commissionPaymentYearInSearch,
  ]);

  const handlePeriodSelect = useCallback((period) => {
    const QSToAdd = {
      [COMMISSION_PAYMENT_PERIOD_QS_KEY]: period,
    };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleYearSelect = useCallback((year) => {
    const QSToAdd = {
      [COMMISSION_PAYMENT_YEAR_QS_KEY]: moment(year).year(),
    };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const periodSelectorNode = useMemo(() => (
    <Select
      className={classNames({
        field: true,
        periodField: true,
      })}
      value={(commissionPaymentPeriod || moment().month()).toString()}
      onChange={handlePeriodSelect}
      options={periodsOptions}
    />
  ), [
    periodsOptions,
    commissionPaymentPeriod,
    handlePeriodSelect,
  ]);

  const yearSelectorNode = useMemo(() => (
    <DatePicker
      className={classNames({
        field: true,
        yearField: true,
      })}
      picker="year"
      value={moment().year(commissionPaymentYear || moment().year())}
      allowClear={false}
      disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
      onChange={handleYearSelect}
    />
  ), [
    commissionPaymentYear,
    handleYearSelect,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {periodSelectorNode}
      {yearSelectorNode}
    </div>
  );
};

CommissionPaymentsPeriodYearSelector.propTypes = {
  className: PropTypes.string,
};

CommissionPaymentsPeriodYearSelector.defaultProps = {
  className: '',
};

export default CommissionPaymentsPeriodYearSelector;
