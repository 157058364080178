import { DEFAULT_NB_DISPLAYED_PAGES } from '@palette/constants/pagination';

export const getDisplayedPages = (pagination) => {
  if (pagination === null) return [];

  const { limit, page, total } = pagination;

  let nbDisplayedPages = DEFAULT_NB_DISPLAYED_PAGES;
  if (nbDisplayedPages < 3) {
    nbDisplayedPages = 3;
  }

  const lastPage = Math.ceil(total / limit);

  const toDisplay = [];

  if (lastPage <= nbDisplayedPages) {
    for (let i = 1; i <= lastPage; i += 1) {
      toDisplay.push(i);
    }
  } else if (page < nbDisplayedPages) {
    for (let i = 1; i < nbDisplayedPages; i += 1) {
      toDisplay.push(i);
    }
    toDisplay.push(null);
    toDisplay.push(lastPage);
  } else if (page > (lastPage - (nbDisplayedPages - 1))) {
    toDisplay.push(1);
    toDisplay.push(null);
    for (let i = (lastPage - (nbDisplayedPages - 2)); i <= lastPage; i += 1) {
      toDisplay.push(i);
    }
  } else {
    const middleNb = nbDisplayedPages - 3;
    const middleBeforeNb = Math.round(middleNb / 2);

    toDisplay.push(1);
    toDisplay.push(null);

    for (let i = 0; i < middleBeforeNb; i += 1) {
      toDisplay.push(page - middleBeforeNb + i);
    }

    toDisplay.push(page);

    for (let j = 0; j < (middleNb - middleBeforeNb); j += 1) {
      toDisplay.push(page + j + 1);
    }

    toDisplay.push(null);
    toDisplay.push(lastPage);
  }

  return toDisplay;
};
