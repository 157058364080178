import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import AuditTrailPageContent from '@palette/components/auditTrail/AuditTrailPageContent/AuditTrailPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './AuditTrailPage.less';

const AuditTrailPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.auditTrailTitle')} />
      <PageTitle
        title={t('auditTrail.page.title')}
        subtitle={t('auditTrail.page.subtitle')}
      />
      <AuditTrailPageContent />
    </div>
  );
};

export default AuditTrailPage;
