import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Avatar from '@palette/components/user/Avatar/Avatar';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import StatementApproversStatusIcon from '@palette/components/statement/statementCommon/StatementApproversStatusIcon/StatementApproversStatusIcon';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import * as UserStatementApprovalModel from '@palette/models/UserStatementApproval';

import styles from './UsersStatementsApprovalsListItem.less';

const classNames = bindClassNames.bind(styles);

const UsersStatementsApprovalsListItem = ({ className, approval, type }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const avatarNode = useMemo(() => {
    if (type === 'inline') {
      return (
        <UserProfile
          className={styles.avatar}
          user={approval.user}
          avatarSize={24}
          avatarStyle={{
            fontSize: '1.2rem',
            width: '2.4rem',
            height: '2.4rem',
          }}
        />
      );
    }

    return (
      <Avatar
        className={styles.avatar}
        user={approval.user}
        size={24}
        style={{
          fontSize: '1.2rem',
          width: '2.4rem',
          height: '2.4rem',
        }}
      />
    );
  }, [approval, type]);

  const statusNode = useMemo(() => (
    <StatementApproversStatusIcon
      className={styles.status}
      isInConflict={approval.isInConflict}
    />
  ), [approval]);

  const getDate = useMemo(() => moment(approval.date).format('M/DD/YYYY'), [approval]);

  const getAmount = useMemo(() => formatPrice(approval.amount, approval.currency), [approval]);

  const getUserName = useMemo(() => approval.user.displayName, [approval]);

  const contentNode = useMemo(() => {
    if (type === 'inline') {
      return (
        <div className={styles.inline}>
          <span className={styles.date}>{getDate}</span>
          {avatarNode}
          <span className={styles.amount}>{getAmount}</span>
          {statusNode}
        </div>
      );
    }

    return (
      <Tooltip
        title={getDate}
        content={(
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('statement.approvals.tooltip.label', {
                userName: getUserName,
                amount: getAmount,
              }),
            }}
          />
        )}
      >
        {avatarNode}
        {statusNode}
      </Tooltip>
    );
  }, [type, avatarNode, statusNode, getDate, getAmount, getUserName]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UsersStatementsApprovalsListItem.propTypes = {
  className: PropTypes.string,
  approval: UserStatementApprovalModel.propTypes.isRequired,
  type: PropTypes.oneOf(['withTooltip', 'inline']),
};

UsersStatementsApprovalsListItem.defaultProps = {
  className: '',
  type: 'withTooltip',
};

export default UsersStatementsApprovalsListItem;
