import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _unionBy from 'lodash/unionBy';
import _differenceBy from 'lodash/differenceBy';
import _intersection from 'lodash/intersection';

import Input from '@palette/components/designSystem/Input/Input';
import Checkbox from '@palette/components/designSystem/Checkbox/Checkbox';
import Table from '@palette/components/designSystem/Table/Table';
import Avatar from '@palette/components/user/Avatar/Avatar';
import Tag from '@palette/components/designSystem/Tag/Tag';
import PlanV3FormRequirementsUsersAdvancedTables from '@palette/components/planV3/planV3FormItems/PlanV3FormRequirementsUsersAdvancedTables/PlanV3FormRequirementsUsersAdvancedTables';

import { TAG_THEMES } from '@palette/constants/tag';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3FormRequirementsUsers.less';

const classNames = bindClassNames.bind(styles);

const PlanV3FormRequirementsUsers = ({
  className,
  planId,
  isPending,
  onChange,
  usersIds,
  setAfterPlanCreationCB,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listUsersIsPending = useSelector(PlanV3Selectors.getListUsersIsPending);
  const usersList = useSelector((state) => PlanV3Selectors.getListUsers(state));

  const [searchedUser, setSearchedUser] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (!listUsersIsPending && !isPending) {
      dispatch(PlanV3Actions.getListUsers());
    }
  }, []);

  const filteredUsersList = useMemo(() => {
    if (usersList.length === 0 || searchedUser === '') return usersList;

    return usersList.filter((user) => (
      user.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
      || user.email.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
    ));
  }, [usersList, searchedUser]);

  const changeSelectedUsers = (newSelectedUsers) => {
    setSelectedUsers(newSelectedUsers);
    onChange(newSelectedUsers);
  };

  useEffect(() => {
    if (usersIds.length && usersList.length) {
      changeSelectedUsers(usersList.filter((user) => usersIds.includes(user.id)));
    }
  }, [usersIds, usersList]);

  const handleAllUsersCheckboxChange = useCallback((e) => {
    let newSelectedUsers;

    if (e.target.checked) {
      newSelectedUsers = _unionBy(selectedUsers, filteredUsersList, (user) => user.id);
    } else {
      newSelectedUsers = _differenceBy(selectedUsers, filteredUsersList, (user) => user.id);
    }

    changeSelectedUsers(newSelectedUsers);
  }, [filteredUsersList]);

  const handleUserCheckboxChange = (e, user) => {
    let newSelectedUsers = [...selectedUsers];

    if (e.target.checked) {
      newSelectedUsers.push(user);
    } else {
      newSelectedUsers = newSelectedUsers.filter((selectedUser) => selectedUser.id !== user.id);
    }

    changeSelectedUsers(newSelectedUsers);
  };

  const columns = useMemo(() => {
    const dataUsersIds = filteredUsersList.map((user) => user.id);
    const selectedUsersIds = selectedUsers.map((user) => user.id);
    const intersection = _intersection(dataUsersIds, selectedUsersIds);

    let headerCheckboxProps = { checked: false, indeterminate: false };
    if (dataUsersIds.length > 0 && intersection.length > 0) {
      if (intersection.length === dataUsersIds.length) {
        headerCheckboxProps = { checked: true, indeterminate: false };
      } else {
        headerCheckboxProps = { checked: false, indeterminate: true };
      }
    }

    return ([
      {
        id: 'selector',
        Header: (<Checkbox onChange={handleAllUsersCheckboxChange} {...headerCheckboxProps} />),
        accessor: (user) => user,
        minWidth: 48,
        width: 48,
        /* eslint-disable react/prop-types */
        Cell: ({ value: user }) => (
          <Checkbox
            checked={selectedUsersIds.includes(user.id)}
            onChange={(e) => handleUserCheckboxChange(e, user)}
          />
        ),
        /* eslint-enable react/prop-types */
      },
      {
        id: 'users',
        Header: t('common.global.name'),
        accessor: (user) => user,
        minWidth: 320,
        /* eslint-disable react/prop-types */
        Cell: ({ value: user }) => (
          <div className={styles.nameContainer}>
            <Avatar user={user ?? {}} />
            <div className={styles.nameDetails}>
              <span className={styles.name}>{user.displayName}</span>
              {user.email}
            </div>
          </div>
        ),
        /* eslint-enable react/prop-types */
      },
      {
        id: 'email',
        Header: t('common.global.teams'),
        accessor: (user) => user,
        minWidth: 580,
        /* eslint-disable react/prop-types */
        Cell: ({ value: user }) => (
          <div className={styles.teamsContainer}>
            {user.teams.map((team, index) => <Tag key={`user-${user.id}-team-${index}`} theme={TAG_THEMES.INFO} label={team} />)}
          </div>
        ),
        /* eslint-enable react/prop-types */
      },
    ]);
  }, [filteredUsersList, selectedUsers]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.filters}>
        <Input
          className={styles.search}
          type="search"
          placeholder={t('usersSelectionPanel.userSearch.placeholder')}
          onChange={setSearchedUser}
          value={searchedUser}
          disabled={listUsersIsPending || isPending}
        />
      </div>
      <span className={styles.selectedCount}>
        {t('createPlanPage.form.listUsers.countSelected', { usersCount: selectedUsers.length })}
      </span>
      <div className={styles.table}>
        <Table columns={columns} data={filteredUsersList} stickyHeader fitInContainer />
      </div>
      <PlanV3FormRequirementsUsersAdvancedTables className={styles.advancedTables} planId={planId} selectedUsers={selectedUsers} setAfterPlanCreationCB={setAfterPlanCreationCB} />
    </div>
  );
};

PlanV3FormRequirementsUsers.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onChange: PropTypes.func,
  usersIds: PropTypes.arrayOf(PropTypes.string),
  planId: PropTypes.string,
  setAfterPlanCreationCB: PropTypes.func,
};

PlanV3FormRequirementsUsers.defaultProps = {
  className: '',
  isPending: false,
  onChange: () => {},
  usersIds: [],
  planId: null,
  setAfterPlanCreationCB: null,
};

export default PlanV3FormRequirementsUsers;
