import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import { Upload as AntDUpload } from 'antd';

import PlusLine from '@palette/components/utils/Icons/PlusLine';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { ALERT_TYPES } from '@palette/constants/alert';

import appConfig from '@palette/config/app';

import styles from './CloudinaryUploader.less';

const classNames = bindClassNames.bind(styles);

const CloudinaryUploader = ({ className, fileUrl, onFileUrlChange, uploadedFileAddon, ...otherProps }) => {
  const { t } = useTranslation();

  const [imageUrl, setImageUrl] = useState(fileUrl);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setImageUrl(fileUrl);
    setUploading(false);
    setErrors([]);
  }, [fileUrl]);

  const handleImageUrlChange = useCallback((newImageUrl) => {
    setImageUrl(newImageUrl);
    onFileUrlChange(newImageUrl);
  }, [onFileUrlChange]);

  const handleBeforeUpload = useCallback((file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const hasMaxHalfMegaSize = file.size / 1024 / 1024 < 0.5;
    const finalErrors = [];

    if (!isJpgOrPng) finalErrors.push(t('cloudinaryUploader.modal.error.format'));
    if (!hasMaxHalfMegaSize) finalErrors.push(t('cloudinaryUploader.modal.error.size'));

    const fileHasNoErrors = !finalErrors.length;

    if (fileHasNoErrors) setErrors([]);
    else setErrors(finalErrors);

    return fileHasNoErrors;
  }, []);

  const handleChange = useCallback((info) => {
    const { file } = info;

    switch (file.status) {
      case 'uploading':
        setErrors([]);
        setUploading(true);
        handleImageUrlChange(null);
        break;
      case 'done':
        setErrors([]);
        setUploading(false);
        handleImageUrlChange(file.response.secure_url);
        break;
      case 'error':
        setErrors([t('cloudinaryUploader.modal.error.uploading')]);
        setUploading(false);
        handleImageUrlChange(null);
        break;
      default:
    }
  }, [handleImageUrlChange]);

  const alertNode = useMemo(() => {
    if (!errors.length) return null;

    return (
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.ERROR}
        message={errors.map((error, index) => (<span key={index}>{error}</span>))}
      />
    );
  }, [errors]);

  const currentImageNode = useMemo(() => {
    if (imageUrl) {
      return (
        <div className={styles.uploadedPictureImgWrapper}>
          <img src={imageUrl} alt="avatar" className={styles.uploadedPictureImg} />
          {uploadedFileAddon}
        </div>
      );
    }

    return (
      <div className={styles.uploadWrapper}>
        {uploading && (
          <LoadingLine
            className={styles.iconUpload}
            width={18}
            height={18}
            spin
          />
        )}
        {!uploading && (
          <PlusLine
            className={styles.iconUpload}
            width={14}
            height={14}
          />
        )}
        <div className={styles.labelUpload}>{t('common.global.upload')}</div>
      </div>
    );
  }, [uploadedFileAddon, imageUrl, uploading]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <AntDUpload
        name="file"
        listType="picture-card"
        showUploadList={false}
        action={`${appConfig.CLOUDINARY_API}/${appConfig.CLOUDINARY_NAME}/upload`}
        beforeUpload={handleBeforeUpload}
        onChange={handleChange}
        data={{ upload_preset: appConfig.CLOUDINARY_PRESET }}
        {...otherProps}
      >
        {currentImageNode}
      </AntDUpload>
      {alertNode}
    </div>
  );
};

CloudinaryUploader.propTypes = {
  className: PropTypes.string,
  onFileUrlChange: PropTypes.func.isRequired,
  fileUrl: PropTypes.string,
  uploadedFileAddon: PropTypes.node,
};

CloudinaryUploader.defaultProps = {
  className: '',
  fileUrl: null,
  uploadedFileAddon: null,
};

export default CloudinaryUploader;
