import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageDateAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { STATUSES } from '@palette/constants/commissions';

import * as ConnectorModel from '@palette/models/Connector';
import * as MetaUserModel from '@palette/models/MetaUser';
import * as PaymentModel from '@palette/models/Payment';
import * as YearPeriodModel from '@palette/models/YearPeriod';
import * as MongoDetailsModel from '@palette/models/MongoDetails';

export const modelName = 'Statement';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  companyId: PropTypes.string,
  payee: MetaUserModel.propTypes,
  approver: MetaUserModel.propTypes,
  period: YearPeriodModel.propTypes,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  date: PropTypes.string,
  mongoDetails: MongoDetailsModel.propTypes,
});

export const managePayments = (payments) => {
  if (payments === null) return null;
  return PaymentModel.transformList(payments);
};

export const manageStatus = (commission) => (STATUSES[manageStringAttribute(commission, 'paymentsStatus')] || STATUSES.ON_HOLD);

export const transform = (statement) => {
  if (!statement) {
    return null;
  }

  const id = manageStringAttribute(statement, '_id', null)
    || manageStringAttribute(statement, 'objectId', null)
    || manageStringAttribute(statement, 'mongoId');

  const originalType = manageStringAttribute(statement, 'type', null) || manageStringAttribute(statement, 'CObjectType');

  let connector = null;
  let connectorId = manageStringAttribute(statement, 'connector', null, true);

  if (connectorId === null) {
    if (statement.connector == null) {
      connectorId = manageStringAttribute(statement, 'CObjectConnector', null, true);
      if (connectorId === null) {
        connector = ConnectorModel.transform(statement.CObjectConnector);
        connectorId = connector?.id;
      }
    } else {
      connector = ConnectorModel.transform(statement.connector);
      connectorId = connector?.id;
    }
  }

  const payeeInfos = {
    mongoCollection: statement.mongoCollection,
    id: statement.id,
    firstName: statement.firstName,
    lastName: statement.lastName,
    email: statement.email,
    mongoId: statement.mongoId,
    CObjectConnector: statement.CObjectConnector,
    CObjectType: statement.CObjectType,
  };

  const period = {
    year: statement.periodYear,
    period: parseInt(statement.periodType.substring(1), 10),
  };

  return (
    {
      id: manageStringAttribute(statement, '_id'),
      companyId: manageStringAttribute(statement, 'company'),
      payee: MetaUserModel.transform(payeeInfos),
      approver: MetaUserModel.transform(statement.user),
      period: YearPeriodModel.transform(period),
      amount: manageAmountAttribute(statement, 'amount'),
      date: manageDateAttribute(statement, 'date'),
      currency: manageStringAttribute(statement, 'currency', null),
      mongoDetails: MongoDetailsModel.transform({
        ...statement,
        CObjectType: originalType,
        CObjectConnector: connectorId,
        mongoId: id,
        mongoCollection: 'CObject',
      }),
    }
  );
};

export const transformList = (statements) => statements.map((statement) => transform(statement));
