import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import MyUserStatementAdjustmentsItem from '@palette/components/ic/myStatement/MyUserStatementAdjustmentsItem/MyUserStatementAdjustmentsItem';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './MyUserStatementAdjustments.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementAdjustments = ({ className, userStatement }) => {
  const { t } = useTranslation();

  const titleNode = useMemo(() => (
    <div className={styles.title}>
      {t('userStatementAdjustments.collapse.title', { count: userStatement.adjustments.length })}
    </div>
  ), [userStatement]);

  const extraNode = useMemo(() => (
    <div className={styles.extraDetails}>
      <div className={styles.totalLabel}>
        {t('userStatementAdjustments.collapse.total')}
      </div>
      <div className={styles.totalAmount}>
        {formatPrice(userStatement.adjustmentsTotalAmount, userStatement.currency)}
      </div>
    </div>
  ), [userStatement]);

  const contentNode = useMemo(() => {
    const adjustmentItemsNodes = userStatement.adjustments.map((adjustment) => (
      <MyUserStatementAdjustmentsItem
        key={adjustment.id}
        className={styles.adjustmentItem}
        adjustment={adjustment}
      />
    ));

    return (
      <div>
        <div className={styles.adjustmentsListHeader}>
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.reasonLabel')}</span>
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.amountLabel')}</span>
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.whenLabel')}</span>
        </div>
        {adjustmentItemsNodes}
      </div>
    );
  }, [userStatement]);

  if (userStatement.adjustments.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title: titleNode,
          extra: extraNode,
          content: contentNode,
        }]}
        keyValue={userStatement.id}
        defaultActiveKey={userStatement.id}
      />
    </div>
  );
};

MyUserStatementAdjustments.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes.isRequired,
};

MyUserStatementAdjustments.defaultProps = {
  className: '',
};

export default MyUserStatementAdjustments;
