import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Button from '@palette/components/designSystem/Button/Button';
import VariableForm from '@palette/components/variable/VariableForm/VariableForm';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import { enhanceFormulaHelperDataWithVariables } from '@palette/helpers/FormulaHelper';

import * as MasterPlanVariableModel from '@palette/models/MasterPlanVariable';

import styles from './VariablesForm.less';

const classNames = bindClassNames.bind(styles);

const VariablesForm = ({
  className,
  variables,
  helperData,
  onChange,
  prefixId,
  prefixType,
  disabled,
  verticalMode,
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => ({
    variables: variables || [],
  }), [variables]);

  const [stateVariables, setStateVariables] = useState(initialValues.variables);

  useEffect(() => {
    setStateVariables(initialValues.variables);
  }, [initialValues]);

  const checkAllVariablesAreOk = useCallback(() => (
    !stateVariables.some((variable) => (variable.name === '' || variable.formula === ''))
  ), [stateVariables]);

  const handleChange = useCallback(() => {
    // If name or formula is empty for a variable, then do not do the update (to avoid error in api call response)
    // Part of "form" validation
    if (checkAllVariablesAreOk() && !_isEqual(initialValues.variables, stateVariables)) {
      onChange(stateVariables);
    }
  }, [initialValues, stateVariables]);

  useEffect(() => {
    handleChange();
  }, [stateVariables]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const allVariables = _cloneDeep(stateVariables);
    const [removed] = allVariables.splice(result.source.index, 1);

    allVariables.splice(result.destination.index, 0, removed);

    setStateVariables(allVariables);
  };

  const handleAddingAVariable = useCallback(() => {
    setStateVariables([...stateVariables, MasterPlanVariableModel.transform({})]);
  }, [stateVariables]);

  const handleRemoveVariable = useCallback((index) => {
    const newVariables = [...stateVariables];

    newVariables.splice(index, 1);

    setStateVariables(newVariables);
  }, [stateVariables]);

  const handleVariableChange = useCallback((updatedVariable, index) => {
    const newVariables = [...stateVariables];

    newVariables.splice(index, 1, updatedVariable);

    setStateVariables(newVariables);
  }, [stateVariables]);

  const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle,
  });

  const stateVariablesNode = useMemo(() => {
    const variableNames = [];

    return stateVariables.map((variable, index) => {
      const enhancedHelperData = enhanceFormulaHelperDataWithVariables(helperData, variableNames, prefixType);
      const variableFormNode = (
        <Draggable key={index} draggableId={`${prefixId}-variable-${index}`} index={index}>
          {(providedDraggable) => (
            <div
              className={styles.container}
              ref={providedDraggable.innerRef}
              {...providedDraggable.draggableProps}
              {...providedDraggable.dragHandleProps}
              style={getItemStyle(
                providedDraggable.draggableProps.style,
              )}
            >
              <VariableForm
                variable={variable}
                helperData={enhancedHelperData}
                onChange={(updatedVariable) => handleVariableChange(updatedVariable, index)}
                onRemove={() => handleRemoveVariable(index)}
                prefixType={prefixType}
                disabled={disabled}
                hasDragLineIcon={stateVariables.length > 1}
                verticalMode={verticalMode}
              />
            </div>
          )}
        </Draggable>
      );

      if (variable.name !== '') {
        variableNames.push(variable.name);
      }

      return variableFormNode;
    });
  }, [
    stateVariables,
    prefixId,
    helperData,
    prefixType,
    handleVariableChange,
    handleRemoveVariable,
    disabled,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="variablesDroppable">
          {
            (providedDroppable) => (
              <div
                className={styles.variablesDroppable}
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {stateVariablesNode}
                {providedDroppable.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
      <Button icon={(<AddFilled />)} onClick={handleAddingAVariable} disabled={disabled}>
        {t('variablesForm.form.addAVariable')}
      </Button>
    </div>
  );
};

VariablesForm.propTypes = {
  className: PropTypes.string,
  variables: PropTypes.arrayOf(MasterPlanVariableModel.propTypes),
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  onChange: PropTypes.func,
  prefixId: PropTypes.string,
  prefixType: PropTypes.string,
  disabled: PropTypes.bool,
  verticalMode: PropTypes.bool,
};

VariablesForm.defaultProps = {
  className: '',
  variables: [],
  helperData: {},
  onChange: () => {},
  prefixId: 'variables-list',
  prefixType: null,
  disabled: false,
  verticalMode: false,
};

export default VariablesForm;
