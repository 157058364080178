import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import StatementV3UserStatementCorrectionsItem from '@palette/components/statementV3/StatementV3UserStatementCorrectionsItem/StatementV3UserStatementCorrectionsItem';
import AlertOctagon from '@palette/components/utils/Icons/AlertOctagon';

import { STATEMENT_CORRECTION_TYPES } from '@palette/constants/StatementV3';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';

import styles from './StatementV3UserStatementCorrectionsSuggested.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementCorrectionsSuggested = ({ className, statement, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listCorrections = useSelector(StatementV3Selectors.getListCorrections);
  const listCorrectionsIsPending = useSelector(StatementV3Selectors.getListCorrectionsIsPending);

  useEffect(() => {
    dispatch(StatementV3Actions.getListCorrections({ statementId: statement.id, currency }));
  }, [currency, statement]);

  const contentNode = useMemo(() => {
    if (!listCorrections && !listCorrectionsIsPending) return null;

    if (listCorrectionsIsPending) return <Loader />;

    if (listCorrections.length === 0) return null;

    const pendingCorrections = listCorrections.filter((correction) => correction.status === STATEMENT_CORRECTION_TYPES.PENDING);

    if (pendingCorrections.length === 0) return null;

    const title = (
      <div className={styles.title}>
        {t('userStatementCorrections.collapse.title', { count: pendingCorrections.length })}
        <AlertOctagon className={styles.icon} />
      </div>
    );

    const content = pendingCorrections.map((correction) => (
      <StatementV3UserStatementCorrectionsItem
        key={correction.id}
        statement={statement}
        correction={correction}
        currency={currency}
      />
    ));

    return (
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title,
          content,
          extra: '',
        }]}
        keyValue={statement.id}
        defaultActiveKey={statement.id}
      />
    );
  }, [statement, listCorrections, listCorrectionsIsPending, currency]);

  if (!statement) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementV3UserStatementCorrectionsSuggested.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementV3UserStatementCorrectionsSuggested.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3UserStatementCorrectionsSuggested;
