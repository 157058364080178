import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _keyBy from 'lodash/keyBy';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Select from '@palette/components/designSystem/Select/Select';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import { selectors as UsersSelectors } from '@palette/state/Users';

import { useCompanyMetaUsers } from '@palette/hooks/UserHooks';

import styles from './UserSelector.less';

const classNames = bindClassNames.bind(styles);

const UserSelector = ({ className, onUserSelected, disabled, searchedUser, allowClear }) => {
  const { t } = useTranslation();

  const usersListIsPending = useSelector(UsersSelectors.getListIsPending);
  const companyMetaUsers = useCompanyMetaUsers();

  const usersOptions = useMemo(() => {
    const usersById = _keyBy(companyMetaUsers, (user) => user.id);

    return Object.values(usersById).map((user) => ({
      label: (
        <UserProfile
          className={styles.userOption}
          user={user}
          avatarSize={18}
          avatarStyle={{
            fontSize: '1rem',
            width: '1.8rem',
            minWidth: '1.8rem',
            height: '1.8rem',
          }}
        />
      ),
      value: user.id,
      rawlabel: user.displayName,
    }));
  }, [companyMetaUsers]);

  const usersSelectNode = useMemo(() => {
    if (usersOptions.length === 0) return null;

    return (
      <Select
        className={styles.select}
        dropdownClassName={styles.filterByUserSelectorDropdown}
        options={usersOptions}
        showSearch
        enableFilterOptions
        filterOptionProp="rawlabel"
        allowClear={allowClear}
        placeholder={t('userSelector.select.placeholder')}
        value={searchedUser}
        onChange={onUserSelected}
        disabled={disabled}
      />
    );
  }, [usersOptions, searchedUser, onUserSelected, allowClear]);

  const contentNode = useMemo(() => {
    if (usersListIsPending) {
      return (
        <LoadingLine className={styles.icon} width={24} height={24} spin />
      );
    }

    return usersSelectNode;
  }, [usersListIsPending, usersSelectNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UserSelector.propTypes = {
  className: PropTypes.string,
  onUserSelected: PropTypes.func,
  disabled: PropTypes.bool,
  searchedUser: PropTypes.string,
  allowClear: PropTypes.bool,
};

UserSelector.defaultProps = {
  className: '',
  onUserSelected: null,
  disabled: false,
  searchedUser: '',
  allowClear: true,
};

export default UserSelector;
