import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Modal from '@palette/components/designSystem/Modal/Modal';
import EditDefaultValuesPanel from '@palette/components/quota/EditDefaultValuesPanel/EditDefaultValuesPanel';

import { QUOTA_ACTIONS } from '@palette/constants/quotas';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './EditQuotaDefaultValuesModal.less';

const EditQuotaDefaultValuesModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const { quotaId } = useParams();
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));
  const updateQuotaIsPending = useSelector(QuotasSelectors.updateQuotaIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const handleSaveNewDefaultValues = (newDefaultValues) => {
    dispatch(QuotasActions.updateQuota({ quotaId, name: quota.name, type: quota.type, defaultValues: newDefaultValues, action: QUOTA_ACTIONS.UPDATE_QUOTA_DEFAULT_VALUES }));
  };

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.quotaName}>
          {quota.name}
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      displayFooter={false}
      width="55%"
    >
      <EditDefaultValuesPanel
        quotaId={quotaId}
        initialDefaultValues={quota.defaultValues}
        updateIsPending={updateQuotaIsPending}
        saveNewDefaultValues={handleSaveNewDefaultValues}
      />
    </Modal>
  );
};

EditQuotaDefaultValuesModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

EditQuotaDefaultValuesModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default EditQuotaDefaultValuesModal;
