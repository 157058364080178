import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MetaUserModel from '@palette/models/MetaUser';

export const modelName = 'UserStatementApproval';

export const propTypes = PropTypes.shape({
  user: MetaUserModel.propTypes,
  date: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  isApprovedByMe: PropTypes.bool,
  isInConflict: PropTypes.bool,
});

export const transform = (userStatementApproval) => {
  if (!userStatementApproval) {
    return null;
  }

  return ({
    user: MetaUserModel.transform(manageFreeShapeObjectAttribute(userStatementApproval, 'who')),
    date: manageDateAttribute(userStatementApproval, 'when'),
    amount: manageAmountAttribute(userStatementApproval, 'amount'),
    currency: manageStringAttribute(userStatementApproval, 'currency'),
    isApprovedByMe: manageBooleanAttribute(userStatementApproval, 'isApprovedByMe'),
    isInConflict: manageBooleanAttribute(userStatementApproval, 'isInConflict'),
  });
};

export const transformList = (userStatementApprovals) => userStatementApprovals.map((userStatementApproval) => transform(userStatementApproval));
