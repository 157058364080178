import React, { Component } from 'react';
import {
  message, Tooltip,
} from 'antd';
import PropTypes from 'prop-types';

import {
  getSalesforceConnectURL,
} from '../../../../services/connector';
import { CONNECTOR_TYPES } from '../../../../services/constants';
import SourceVignette from '../sourceVignette';
import '../../../../styles/connectCRMButton.less';

class ConnectSalesforceButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salesforceLoading: false,
    };
  }

  connectSalesforce = () => {
    this.setState({ salesforceLoading: true });
    getSalesforceConnectURL()
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((e) => {
        message.error('Error while contacting Salesforce.');
        console.error(e);
      }).finally(() => {
        this.setState({ salesforceLoading: false });
      });
  };

  render() {
    const { salesforceLoading } = this.state;
    const { disabled } = this.props;
    return (
      <Tooltip
        placement="right"
        title={disabled ? 'Connector already linked' : null}
      >
        <SourceVignette
          loading={salesforceLoading}
          icon={<img src="/img/salesforce.png" alt="salesforce" style={{ width: '44px' }} />}
          type={CONNECTOR_TYPES.CRM}
          name="Salesforce"
          id="SALESFORCE"
          disabled={disabled}
          onClick={disabled ? null : this.connectSalesforce}
        />
      </Tooltip>

    );
  }
}
ConnectSalesforceButton.propTypes = {
  disabled: PropTypes.bool,
};

ConnectSalesforceButton.defaultProps = {
  disabled: false,
};
export default ConnectSalesforceButton;
