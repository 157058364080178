import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import PlanV3CreatePageContent from '@palette/components/planV3/PlanV3CreatePageContent/PlanV3CreatePageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './PlanV3CreatePage.less';

const PlanV3CreatePage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (
    !hasAllRights(profile, [
      RIGHTS.ADMIN.PLANS.VIEW,
      RIGHTS.ADMIN.PLANS.CREATE,
      RIGHTS.ADMIN.PLANS.UPDATE,
      RIGHTS.ADMIN.PLANS.GBO.MANAGE,
      RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
      RIGHTS.ADMIN.QUOTAS.VIEW,
      RIGHTS.ADMIN.QUOTAS.MANAGE,
      RIGHTS.ADMIN.QUOTAS.USERS.MANAGE,
    ])
  ) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.createPlanFlowTitle')} />
      <PageTitle
        className={styles.titleContainer}
        title={t('createPlanPage.title')}
        subtitle={t('createPlanPage.subtitle.top')}
        positionSubtitle="top"
      />
      <PlanV3CreatePageContent />
    </div>
  );
};

export default PlanV3CreatePage;
