import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import styles from './CommissionClearFiltersModal.less';

const CommissionClearFiltersModal = ({ onClose, onClear, visible }) => {
  const { t } = useTranslation();

  const handleCancel = () => onClose();

  const handleCloseAndClear = () => {
    handleCancel();
    onClear();
  };

  return (
    <Modal
      className={styles.modal}
      title={t('commissionList.clearFilters.modal.title')}
      onOk={handleCloseAndClear}
      onCancel={handleCancel}
      okText={t('commissionList.clearFilters.modal.btn.confirm')}
      visible={visible}
    />
  );
};

CommissionClearFiltersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

CommissionClearFiltersModal.defaultProps = {
  visible: false,
};

export default CommissionClearFiltersModal;
