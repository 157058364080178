import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import _size from 'lodash/size';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import ListDealCommissionCollapse from '@palette/components/commission/ListDealCommissionCollapse/ListDealCommissionCollapse';

import { getCommissionsByPeriodsByPlans } from '@palette/helpers/CommissionHelper';

import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';

import styles from './ListDealCommissions.less';

const classNames = bindClassNames.bind(styles);

const ListDealCommissions = ({ className, connectorId, objectId, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const composeId = `${connectorId}_${type}_${objectId}`;
  const listDealCommissionsIsPending = useSelector(CommissionsSelectors.listDealCommissionsIsPending);
  const commissionsByObjectComposeId = useSelector((state) => CommissionsSelectors.getCommissionsByObjectComposeId(state, { composeId }));

  useEffect(() => {
    if (connectorId && objectId && type) {
      dispatch(CommissionsActions.listDealCommissions({ connectorId, objectId, type }));
    }
  }, [connectorId, objectId, type]);

  const commissionsByPeriodsByPlans = getCommissionsByPeriodsByPlans(commissionsByObjectComposeId) || [];

  const commissionsByPeriodsByPlansNode = useMemo(() => (
    Object.values(commissionsByPeriodsByPlans).map((commission, index) => {
      const { plan, periods, totalAmount, totalComments, currency } = commission;

      return (
        <ListDealCommissionCollapse
          key={index}
          plan={plan}
          periods={periods}
          totalAmount={totalAmount}
          totalComments={totalComments}
          currency={currency}
          isOpened={index === 0}
        />
      );
    })
  ), [commissionsByPeriodsByPlans]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {listDealCommissionsIsPending && <Loader />}
      {!listDealCommissionsIsPending && !_size(commissionsByPeriodsByPlans) > 0 && (
        <DefaultEmptyState description={t('commissions.empty.description')} />
      )}
      {!listDealCommissionsIsPending && _size(commissionsByPeriodsByPlans) > 0 && (
        commissionsByPeriodsByPlansNode
      )}
    </div>
  );
};

ListDealCommissions.propTypes = {
  className: PropTypes.string,
  connectorId: PropTypes.string,
  objectId: PropTypes.string,
  type: PropTypes.string,
};

ListDealCommissions.defaultProps = {
  className: '',
  connectorId: '',
  objectId: '',
  type: '',
};

export default ListDealCommissions;
