import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _differenceBy from 'lodash/differenceBy';

import Alert from '@palette/components/designSystem/Alert/Alert';
import Select from '@palette/components/designSystem/Select/Select';
import CreateQuota from '@palette/components/quota/CreateQuota/CreateQuota';
import QuotaInfos from '@palette/components/quota/QuotaInfos/QuotaInfos';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Button from '@palette/components/designSystem/Button/Button';
import Link from '@palette/components/designSystem/Link/Link';
import UnlinkQuotaFromMasterPlan from '@palette/components/masterPlanSettings/UnlinkQuotaFromMasterPlan/UnlinkQuotaFromMasterPlan';
import MasterPlanSettingsQuotaTable from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaTable/MasterPlanSettingsQuotaTable';
import QuotaTargetStrategySelect from '@palette/components/masterPlanSettings/QuotaTargetStrategySelect/QuotaTargetStrategySelect';

import routePaths from '@palette/config/routePaths';

import { CREATE_PLAN_FLOW_STEPS, SCOPES } from '@palette/constants/masterPlans';
import { ALERT_TYPES } from '@palette/constants/alert';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as QuotaModel from '@palette/models/Quota';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';
import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsQuotaSetupPanel.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsQuotaSetupPanel = ({ className, plan, quota, inPlanCreationFlow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(QuotasActions.getList());
    dispatch(MasterPlansActions.getPlanUsersAndManagers({ planId: plan.id }));
  }, []);

  useEffect(() => {
    dispatch(MasterPlansActions.getPlanUsersAndManagers({ planId: plan.id }));
  }, [plan.id]);

  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: plan.id }));
  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);

  const listIsPending = useSelector(QuotasSelectors.getListIsPending);
  const quotasList = useSelector((state) => QuotasSelectors.getQuotasListForPeriodType(state, { periodType: plan.periodType }));

  const [selectedQuotaId, setSelectedQuotaId] = useState(null);
  const selectedQuota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId: selectedQuotaId }));
  const getByIdIsPending = useSelector(QuotasSelectors.getByIdIsPending);

  useEffect(() => {
    if (selectedQuotaId !== null) {
      dispatch(QuotasActions.getById({ quotaId: selectedQuotaId }));
    }
  }, [selectedQuotaId]);

  const afterQuotaAssigned = () => {
    setSelectedQuotaId(null);
  };

  const assignQuotaToPlan = (quotaId) => {
    let otherUpdatedData = {};
    if (inPlanCreationFlow) {
      otherUpdatedData = {
        creationFlowStep: CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value,
        disableSuccessNotification: true,
      };
    }
    dispatch(MasterPlansActions.updatePlan({ planId: plan.id, quotaId, onSuccessCB: afterQuotaAssigned, ...otherUpdatedData }));
  };

  const afterCreated = (createdQuotaId) => {
    assignQuotaToPlan(createdQuotaId);
  };

  const handleQuotaSelected = (quotaId) => {
    setSelectedQuotaId(quotaId);
  };

  const createQuotaNode = useMemo(() => (
    <CreateQuota className={styles.createQuotaBtn} periodType={plan.periodType} frequency={plan.frequency} afterCreate={afterCreated} />
  ), [plan]);

  const quotasOptions = useMemo(() => {
    if (quotasList.length === 0) return [];

    return quotasList.map((quotaItem) => ({
      label: (
        <div key={quotaItem.id}>
          <div>
            {quotaItem.name}
          </div>
          <QuotaInfos quota={quotaItem} />
        </div>
      ),
      value: quotaItem.id,
      rawlabel: quotaItem.name,
    }));
  }, [quotasList]);

  const missingUsers = useMemo(() => {
    if (getByIdIsPending && (selectedQuota === null || selectedQuota?.users.length === 0)) return null;

    if (selectedQuota === null) return [];

    const planUsers = [].concat(planUsersAndManagers.users, planUsersAndManagers.managers).map((planUserOrManager) => planUserOrManager.user);

    return _differenceBy(
      planUsers,
      selectedQuota.users.map((quotaUser) => quotaUser.user),
      (user) => user.id,
    );
  }, [selectedQuota, planUsersAndManagers, getByIdIsPending]);

  const missingUsersNode = useMemo(() => {
    if (missingUsers === null || missingUsers.length === 0) return null;

    const alertDescriptionNode = (
      <div>
        <div className={styles.profilesWrapper}>
          {
            missingUsers.map((user) => (
              <UserProfile
                className={styles.profile}
                key={user.id}
                user={user}
                vertical
              />
            ))
          }
        </div>
        <div className={styles.confirmQuestion}>
          {t('masterPlanSettingsQuotaSetupPanel.doYouConfirmQuestion')}
        </div>
        <Button className={styles.confirmBtn} onClick={() => assignQuotaToPlan(selectedQuotaId)}>
          {t('masterPlanSettingsQuotaSetupPanel.confirmBtnLabel')}
        </Button>
      </div>
    );

    return (
      <div className={styles.missingUsersAlertWrapper}>
        <Alert
          type={ALERT_TYPES.WARNING}
          message={t('masterPlanSettingsQuotaSetupPanel.missingUsersInQuota.alert.message', { count: missingUsers.length })}
          description={alertDescriptionNode}
        />
      </div>
    );
  }, [missingUsers, selectedQuotaId]);

  const contentNode = useMemo(() => {
    if (quotasList.length === 0) {
      return (
        <div className={styles.noQuotasAlertWrapper}>
          <Alert
            type={ALERT_TYPES.INFO}
            message={t('masterPlanSettingsQuotaSetupPanel.alert.noQuotas.message')}
            description={t('masterPlanSettingsQuotaSetupPanel.alert.noQuotas.description')}
          />
          <div className={styles.noQuotasAlertActions}>
            {createQuotaNode}
          </div>
        </div>
      );
    }

    if (quota === null) {
      return (
        <div className={styles.quotaSetupWrapper}>
          <div className={styles.quotaSelectorWrapper}>
            <Select
              className={styles.quotaSelector}
              placeholder={t('masterPlanSettingsQuotaSetupPanel.quotaSelector.placeholder')}
              options={quotasOptions}
              showSearch
              enableFilterOptions
              filterOptionProp="rawlabel"
              disabled={getByIdIsPending}
              value={selectedQuotaId || quota?.id || null}
              onChange={handleQuotaSelected}
              dropdownMatchSelectWidth={false}
            />
            <div className={styles.quotaSelectorOr}>
              {t('common.global.or')}
            </div>
            {createQuotaNode}
          </div>
          {missingUsersNode}
          {
            selectedQuotaId !== null && (missingUsers === null || missingUsers.length === 0) && (
              <Button className={styles.linkQuotaBtn} onClick={() => assignQuotaToPlan(selectedQuotaId)}>
                {t('masterPlanSettingsQuotaSetupPanel.linkBtnLabel')}
              </Button>
            )
          }
        </div>
      );
    }

    return (
      <div className={styles.quotaTableWrapper}>
        <div className={styles.quotaNameWrapper}>
          <h3>{quota.name}</h3>
          <Link
            className={styles.quotaLink}
            path={routePaths.v2.quotaDetails}
            params={{ quotaId: quota.id }}
            displayIcon
          >
            {t('masterPlanSettingsQuotaSetupPanel.viewQuotaLinkLabel')}
          </Link>
        </div>
        <div className={styles.quotaInfosWithActions}>
          <QuotaInfos quota={quota} displayUsersCount={false} />
          <div className={styles.actions}>
            <QuotaTargetStrategySelect className={styles.action} plan={plan} />
            <UnlinkQuotaFromMasterPlan className={styles.action} plan={plan} />
          </div>
        </div>
        <div className={styles.quotaTable}>
          <MasterPlanSettingsQuotaTable plan={plan} quota={quota} />
        </div>
      </div>
    );
  }, [
    quotasList,
    createQuotaNode,
    listIsPending,
    quotasOptions,
    quota,
    selectedQuotaId,
    selectedQuota,
    getByIdIsPending,
    missingUsersNode,
    updatePlanIsPending,
  ]);

  if (plan.scope === SCOPES.TEAM) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlanSettingsQuotaSetupPanel.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  quota: QuotaModel.propTypes,
  inPlanCreationFlow: PropTypes.bool,
};

MasterPlanSettingsQuotaSetupPanel.defaultProps = {
  className: '',
  quota: null,
  inPlanCreationFlow: false,
};

export default MasterPlanSettingsQuotaSetupPanel;
