import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import CreateVariableModal from '@palette/components/usersVariables/CreateVariableModal/CreateVariableModal';

const CreateVariable = ({ className }) => {
  const { t } = useTranslation();
  const [createVariableModalIsVisible, showCreateVariableModal] = useState(false);

  return (
    <div className={className}>
      <Button
        type="primaryBlue"
        icon={<AddFilled />}
        onClick={() => showCreateVariableModal(true)}
      >
        {t('usersVariables.createNewVariable')}
      </Button>
      {
        createVariableModalIsVisible && (
          <CreateVariableModal visible onClose={() => showCreateVariableModal(false)} />
        )
      }
    </div>
  );
};

CreateVariable.propTypes = {
  className: PropTypes.string,
};

CreateVariable.defaultProps = {
  className: '',
};

export default CreateVariable;
