import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* getRoles(payload = {}) {
  const { withUsersCount = false } = payload;

  const data = {};
  if (withUsersCount) {
    data.includeUsage = true;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.list, data, getConfig()),
    [RIGHTS.ADMIN.ROLES.VIEW],
  );
}

export function* getRights() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.listRights, {}, getConfig()),
    [],
  );
}

export function* deleteRoleById(payload = {}) {
  const { roleId } = payload;

  const data = {
    roleId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.delete, data, getConfig()),
    [RIGHTS.ADMIN.ROLES.MANAGE],
  );
}

export function* duplicateRoleById(payload = {}) {
  const { roleId } = payload;

  const data = {
    roleId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.duplicate, data, getConfig()),
    [RIGHTS.ADMIN.ROLES.MANAGE],
  );
}

export function* makeDefaultRoleById(payload = {}) {
  const { roleId } = payload;

  const data = {
    roleId,
    default: true,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.update, data, getConfig()),
    [RIGHTS.ADMIN.ROLES.MANAGE],
  );
}

export function* getRoleById(payload = {}) {
  const { roleId } = payload;

  const data = {
    roleId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.getRoleById, data, getConfig()),
    [RIGHTS.ADMIN.ROLES.VIEW],
  );
}

export function* updateRole(payload = {}) {
  const {
    roleId,
    name = null,
    description = null,
    rights = null,
  } = payload;

  const data = {
    roleId,
  };

  if (name !== null) {
    data.name = name;
  }

  if (description !== null) {
    data.description = description;
  }

  if (rights !== null) {
    const newRights = Object.keys(rights).reduce((res, right) => {
      if (rights[right] === true) {
        res.push(right);
      }

      return res;
    }, []);

    data.rights = newRights;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.update, data, getConfig()),
    [RIGHTS.ADMIN.ROLES.MANAGE],
  );
}

export function* createRole(payload = {}) {
  const {
    roleId,
    name,
    description = '',
    rights = [],
  } = payload;

  const data = {
    roleId,
    name,
    description,
    rights,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.roles.create, data, getConfig()),
    [RIGHTS.ADMIN.ROLES.MANAGE],
  );
}
