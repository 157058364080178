import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { InputInCellRefForwarded as InputInCell } from '@palette/components/designSystem/InputInCell/InputInCell';
import QuotaLockedIndicator from '@palette/components/quota/QuotaLockedIndicator/QuotaLockedIndicator';

import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { isInitialDefaultValues } from '@palette/helpers/QuotaHelper';

import * as YearPeriodValueModel from '@palette/models/YearPeriodValue';

import styles from './DefaultValueValueCell.less';

const classNames = bindClassNames.bind(styles);

const DefaultValueValueCell = ({
  className,
  defaultValue,
  locked,
  onUpdate,
  index,
  toDelete,
  allowDeleteAll,
}) => {
  const inputRef = useRef(null);

  const [target, setTarget] = useState(defaultValue.value);

  const lockedIndicator = useMemo(() => {
    if (locked) {
      return (<QuotaLockedIndicator className={styles.lockedIndicator} />);
    }

    return null;
  }, [locked]);

  const handleInputFocus = () => {
    inputRef?.current?.select();
  };

  const handleInputChange = (newValue) => {
    setTarget(newValue);
  };

  const saveUpdate = () => {
    if (defaultValue.value !== target) {
      onUpdate({ ...defaultValue, value: target }, index);
    }
  };

  const handleInputPressEnter = () => {
    saveUpdate();
    inputRef?.current?.blur();
  };

  const handleInputClear = () => {
    onUpdate({ ...defaultValue }, index, true);
  };

  const bodyNode = useMemo(() => {
    if (locked) {
      return comaSeparatorFormatter(defaultValue.value);
    }

    return (
      <InputInCell
        ref={inputRef}
        type="comaSeparatorFormatted"
        controls={false}
        value={target}
        onFocus={handleInputFocus}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={handleInputPressEnter}
        allowClear={allowDeleteAll || !isInitialDefaultValues(defaultValue)}
        onClear={handleInputClear}
      />
    );
  }, [locked, target]);

  return (
    <div
      className={classNames({
        wrapper: true,
        locked,
        toDelete,
        [className]: className !== '',
      })}
    >
      {lockedIndicator}
      {bodyNode}
    </div>
  );
};

DefaultValueValueCell.propTypes = {
  className: PropTypes.string,
  defaultValue: YearPeriodValueModel.propTypes.isRequired,
  locked: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  toDelete: PropTypes.bool.isRequired,
  allowDeleteAll: PropTypes.bool,
};

DefaultValueValueCell.defaultProps = {
  className: '',
  allowDeleteAll: false,
};

export default DefaultValueValueCell;
