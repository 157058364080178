import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import PeriodDealCommissionInfoPayments from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfoPayments/PeriodDealCommissionInfoPayments';
import CommissionPayout from '@palette/components/commissionList/CommissionPayout/CommissionPayout';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';

import * as CommissionModel from '@palette/models/Commission';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './PeriodDealPayoutInfos.less';

const classNames = bindClassNames.bind(styles);

const PeriodDealPayoutInfos = ({ className, commission }) => {
  const dispatch = useDispatch();

  const handlePaymentsClick = () => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_PAYMENT_TAG }));
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <CommissionPayout commission={commission} />
      <PeriodDealCommissionInfoPayments
        mode="link"
        payments={commission.payments}
        commissionId={commission.id}
        onClick={handlePaymentsClick}
      />
    </div>
  );
};

PeriodDealPayoutInfos.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

PeriodDealPayoutInfos.defaultProps = {
  className: '',
};

export default PeriodDealPayoutInfos;
