import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  PLAN_ARCHIVE_SUCCESS: {
    code: 'notifications.masterPlans.success.archive',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_COMPUTE_DONE_SUCCESS: {
    code: 'plans.success.computePlan.done',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_402_SEATS_EXHAUSTED: {
    code: 'masterPlans.errors.seats.exhausted',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_404_PLAN_NOT_FOUND: {
    code: 'masterPlans.errors.plan.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_404_CONNECTOR_NOT_FOUND: {
    code: 'masterPlans.errors.connector.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_404_PARENT_PLAN_NOT_FOUND: {
    code: 'masterPlans.errors.parentPlan.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_409_COMPUTE_PENDING: {
    code: 'masterPlans.errors.computation.running',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_409_PLAN_IS_INJECTED: {
    code: 'masterPlans.errors.plan.is.injected',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_409_INCOMPATIBLE_PLAN_SCOPE: {
    code: 'masterPlans.errors.incompatible.plan.scope',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_DELETION_SUCCESS: {
    code: 'notifications.masterPlans.success.delete',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_USER_DATES_SUCCESS: {
    code: 'notifications.masterPlans.success.userDates',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_UPDATE_SUCCESS: {
    code: 'notifications.masterPlans.success.update',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_COMPUTE_ALL_SUCCESS: {
    code: 'plans.success.computeAll.done',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_INVALID_COMMISSION_AMOUNT_ERROR: {
    code: 'plan.invalid.commission.amount.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_CODE_RULE_EVALUATION_ERROR: {
    code: 'plan.code.rule.evaluation.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_INJECTION_ERROR: {
    code: 'plan.injection.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_PERCENTAGE_RULE_TARGET_ERROR: {
    code: 'percentage.rule.target.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_INVALID_CODE_FORMULA_ERROR: {
    code: 'plan.invalid.codeOnly.formula.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_PAYOUT_EVALUATION_ERROR: {
    code: 'plan.payout.rule.evaluation.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_TRACKED_RESOURCE_EVALUATION_ERROR: {
    code: 'plan.tracked.resource.value.evaluation.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_INVALID_PAYMENT_PERCENTAGE_ERROR: {
    code: 'plan.invalid.paymentPercentage.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
};
