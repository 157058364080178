import PropTypes from 'prop-types';

import { manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'PlanSourceTrackUpdateStrategy';

export const propTypes = PropTypes.exact({
  planId: PropTypes.string,
  planName: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      planId: manageStringAttribute(strategy, 'planId'),
      planName: manageStringAttribute(strategy, 'planName'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
