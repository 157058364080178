import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import MasterPlanSettingsQuotaSetupPanel from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaSetupPanel/MasterPlanSettingsQuotaSetupPanel';
import MasterPlanSettingsCustomQuotaSetupPanel from '@palette/components/masterPlanSettings/MasterPlanSettingsCustomQuotaSetupPanel/MasterPlanSettingsCustomQuotaSetupPanel';
import MasterPlanSettingsTeamQuotaSetupPanel from '@palette/components/masterPlanSettings/MasterPlanSettingsTeamQuotaSetupPanel/MasterPlanSettingsTeamQuotaSetupPanel';

import { SCOPES } from '@palette/constants/masterPlans';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as QuotaModel from '@palette/models/Quota';
import { actions as QuotasActions } from '@palette/state/Quotas';

const MasterPlanSettingsQuotaSetup = ({ className, plan, quota, inPlanCreationFlow }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(QuotasActions.setBeingEditedPlan({ planId: plan.id }));

    return () => {
      dispatch(QuotasActions.setBeingEditedPlan({ planId: null }));
    };
  }, [plan]);

  if (plan.scope === SCOPES.TEAM) {
    return (
      <MasterPlanSettingsTeamQuotaSetupPanel className={className} plan={plan} quota={quota} inPlanCreationFlow={inPlanCreationFlow} />
    );
  }

  if (plan.periodType === PERIOD_TYPES.CUSTOM) {
    return (
      <MasterPlanSettingsCustomQuotaSetupPanel className={className} plan={plan} quota={quota} inPlanCreationFlow={inPlanCreationFlow} />
    );
  }

  return (
    <MasterPlanSettingsQuotaSetupPanel className={className} plan={plan} quota={quota} inPlanCreationFlow={inPlanCreationFlow} />
  );
};

MasterPlanSettingsQuotaSetup.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  quota: QuotaModel.propTypes,
  inPlanCreationFlow: PropTypes.bool,
};

MasterPlanSettingsQuotaSetup.defaultProps = {
  className: '',
  quota: null,
  inPlanCreationFlow: false,
};

export default MasterPlanSettingsQuotaSetup;
