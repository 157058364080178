import PropTypes from 'prop-types';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as ResourceObjectModel from '@palette/models/ResourceObject';
import * as PlanRuleModel from '@palette/models/PlanRule';
import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'CommissionScopeParams';

export const propTypes = PropTypes.shape({
  plan: MasterPlanModel.propTypes,
  resourceObject: ResourceObjectModel.propTypes,
  rule: PlanRuleModel.propTypes,
  period: YearPeriodModel.propTypes,
});

export const transform = (commissionScopeParams) => {
  if (!commissionScopeParams) {
    return null;
  }

  return (
    {
      plan: MasterPlanModel.transform(commissionScopeParams.plan),
      resourceObject: ResourceObjectModel.transform(commissionScopeParams.object),
      rule: PlanRuleModel.transform(commissionScopeParams.rule),
      period: YearPeriodModel.transform(commissionScopeParams.period),
    }
  );
};

export const transformList = (commissionScopeParamsList) => commissionScopeParamsList.map((commissionScopeParams) => transform(commissionScopeParams));
