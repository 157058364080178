export const buildTeamsTree = (teams, rootTeamId = null) => {
  const teamsById = teams.reduce((res, team) => {
    res[team.id] = {
      team,
      subTeams: [],
    };

    return res;
  }, {});

  const rootTeams = [];

  teams.forEach((team) => {
    const teamWithSubTeams = teamsById[team.id];

    if (team.parentId !== null && teamsById[team.parentId] !== undefined) {
      teamsById[team.parentId].subTeams.push(teamWithSubTeams);
    } else {
      rootTeams.push(teamWithSubTeams);
    }
  });

  if (rootTeamId !== null && teamsById[rootTeamId] !== undefined) {
    return teamsById[rootTeamId].subTeams;
  }

  return rootTeams;
};

export const buildTeamsTreeOptions = (teamsTree, disableTeamFamily = null, forceDisable = false) => teamsTree.map((teamsTreeItem) => {
  let disableOption = forceDisable || teamsTreeItem.team.id === disableTeamFamily;
  let options = [];
  if (teamsTreeItem.subTeams.length > 0) {
    options = buildTeamsTreeOptions(teamsTreeItem.subTeams, disableTeamFamily, disableOption);

    disableOption = disableOption || teamsTreeItem.subTeams.some((subTeam) => (subTeam.team.id === disableTeamFamily));
  }

  return {
    label: teamsTreeItem.team.name,
    value: teamsTreeItem.team.id,
    options,
    disabled: disableOption,
  };
});

export const getTeamMembersMetaUsers = (team) => (
  team.members.map((member) => (member.user))
);

export const getParents = (team, teamsListById) => {
  if (team.parentId === null) return [];

  const parentTeam = teamsListById[team.parentId] || null;

  if (parentTeam === null) return [];

  const parents = getParents(parentTeam, teamsListById);

  parents.push(parentTeam);

  return parents;
};
