import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Drawer as AntDDrawer } from 'antd';

import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';
import CommissionV3Details from '@palette/components/commissionV3/CommissionV3Details/CommissionV3Details';
import CommissionV3Value from '@palette/components/commissionV3/CommissionV3Value/CommissionV3Value';
import CommissionV3DealValue from '@palette/components/commissionV3/CommissionV3DealValue/CommissionV3DealValue';
import CommissionV3DrawerTabs from '@palette/components/commissionV3/CommissionV3DrawerTabs/CommissionV3DrawerTabs';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { COMMISSION_DRAWER_COMMISSION_ID_QS_KEY } from '@palette/constants/commissions';
import { COMMISSION_V3_DRAWER_QS_KEY } from '@palette/constants/tabs';
import { RIGHTS } from '@palette/constants/profile';

import { actions as CommissionV3Actions, selectors as CommissionV3Selectors } from '@palette/state/CommissionV3';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CommissionV3Drawer.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3Drawer = ({ className, ...otherProps }) => {
  const { t } = useTranslation();
  const reactLocation = useLocation();
  const reactHistory = useHistory();
  const dispatch = useDispatch();
  const profile = useProfile();

  const isIC = useMemo(() => !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW]) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]), [profile]);

  const [commissionId] = useKeyInSearch(COMMISSION_DRAWER_COMMISSION_ID_QS_KEY, null);

  const commission = useSelector(CommissionV3Selectors.getDetailsCommission);

  useEffect(() => {
    if (commissionId) {
      if (isIC) dispatch(CommissionV3Actions.getICDetailsCommission({ commissionId }));
      else dispatch(CommissionV3Actions.getDetailsCommission({ commissionId }));
    }
  }, [commissionId, isIC]);

  const handleOnClose = useCallback(() => {
    dispatch(NavigationActions.cleanLocationSearch({ keysToDelete: [COMMISSION_DRAWER_COMMISSION_ID_QS_KEY, COMMISSION_V3_DRAWER_QS_KEY] }));
  }, [reactLocation, reactHistory]);

  let drawerContentNode = null;
  if (commission !== null) {
    drawerContentNode = (
      <div className={styles.content}>
        <CommissionV3Details className={styles.commissionDetails} commission={commission} isIC={isIC} />
        <div className={styles.commissionValueAndDealValue}>
          <CommissionV3Value className={styles.commissionValue} commission={commission} />
          <CommissionV3DealValue className={styles.commissionDealValue} commission={commission} />
        </div>
        <CommissionV3DrawerTabs className={styles.commissionDrawerTabs} commission={commission} />
      </div>
    );
  }

  return (
    <AntDDrawer
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('commissionDrawer.title')}
      placement="right"
      width={600}
      onClose={handleOnClose}
      visible={commissionId !== null}
      closeIcon={<ClosePopupLine width={28} height={28} />}
      {...otherProps}
    >
      {drawerContentNode}
    </AntDDrawer>
  );
};

CommissionV3Drawer.propTypes = {
  className: PropTypes.string,
};

CommissionV3Drawer.defaultProps = {
  className: '',
};

export default CommissionV3Drawer;
