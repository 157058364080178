import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { getDealName } from '@palette/helpers/CommissionHelper';

import * as CommissionModel from '@palette/models/Commission';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './CommissionName.less';

const classNames = bindClassNames.bind(styles);

const CommissionName = ({ className, commission }) => {
  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: commission.scopeParams?.plan?.trackingObject?.connectorId || '' }));

  const dealName = useMemo(() => {
    if (commission !== null && connector !== null) {
      return getDealName(commission, connector);
    }

    return '';
  }, [commission, connector]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {dealName}
    </div>
  );
};

CommissionName.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionName.defaultProps = {
  className: '',
};

export default CommissionName;
