import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import Link from '@palette/components/designSystem/Link/Link';
import StatementPeriodApprovalProgress from '@palette/components/statement/statementPeriod/StatementPeriodApprovalProgress/StatementPeriodApprovalProgress';
import StatementPeriodPaidProgress from '@palette/components/statement/statementPeriod/StatementPeriodPaidProgress/StatementPeriodPaidProgress';
import StatementCounterCorrections from '@palette/components/statement/statementCommon/StatementCounterCorrections/StatementCounterCorrections';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { FEATURES } from '@palette/constants/profile';

import { formatPeriodNameDates, getPeriodName, statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { hasFeature } from '@palette/helpers/ProfileHelper';

import { useProfile } from '@palette/hooks/ProfileHooks';

import routePaths from '@palette/config/routePaths';

import * as StatementV3ListPeriodModel from '@palette/models/StatementV3ListPeriod';

import styles from './StatementV3PeriodsListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PeriodsListItem = ({ className, statementPeriod }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const headerNode = useMemo(() => (
    <div className={styles.spacedBlocks}>
      <div className={styles.nameDatesWrapper}>
        <Link
          className={styles.name}
          displayIcon
          path={routePaths.v2.statementV3Period}
          params={{ statementPeriodId: statementPeriod.periodId }}
          qsObject={periodsDatesFilterQSObject}
        >
          {getPeriodName({ type: statementPeriod.periodicity }, statementPeriod.period)}
        </Link>
        <div className={styles.dates}>
          {formatPeriodNameDates(statementPeriod.fromDate, statementPeriod.toDate)}
        </div>
      </div>
      <div className={styles.statusesWrapper}>
        <div className={styles.totalWrapper}>
          <div className={styles.totalLabel}>
            {t('statementPeriodsListItem.total')}
          </div>
          <div className={styles.totalAmount}>
            {formatPrice(statementPeriod.amount, statementPeriod.currency)}
          </div>
        </div>
        <div className={styles.usersWrapper}>
          <div className={styles.usersCount}>
            {statementPeriod.usersCount}
          </div>
          <TeamFilled className={styles.usersCountIcon} />
        </div>
      </div>
    </div>
  ), [statementPeriod, periodsDatesFilterQSObject]);

  const footerNode = useMemo(() => {
    if (statementPeriodIsInFuture(statementPeriod, true, statementPeriod.fromDate)) return null;

    return (
      <>
        <div className={styles.spacedBlocks}>
          <StatementPeriodApprovalProgress
            className={styles.progressBarWrapper}
            classNameProgressBar={styles.progressBarProgress}
            approvedCount={statementPeriod.approvedCount}
            inConflictCount={statementPeriod.inConflictCount}
            usersCount={statementPeriod.usersCount}
          />
          {!statementPeriodIsInFuture(statementPeriod, false) && (
            <StatementPeriodPaidProgress
              className={styles.progressBarWrapper}
              classNameProgressBar={styles.progressBarProgress}
              paidCount={statementPeriod.paidCount}
              usersCount={statementPeriod.usersCount}
            />
          )}
        </div>
        <div
          className={classNames({
            spacedBlocks: true,
            spacedBlocksInFooter: true,
            spacedBlocksInversed: statementPeriod.usersCount > 0,
          })}
        >
          {hasFeature(profile, FEATURES.STATEMENT_VALIDATION) && (
            <StatementCounterCorrections
              className={styles.correctionsWrapper}
              counter={statementPeriod.correctionsCount}
            />
          )}
        </div>
      </>
    );
  }, [statementPeriod, profile]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {headerNode}
      {footerNode}
    </div>
  );
};

StatementV3PeriodsListItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3ListPeriodModel.propTypes.isRequired,
};

StatementV3PeriodsListItem.defaultProps = {
  className: '',
};

export default StatementV3PeriodsListItem;
