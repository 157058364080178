import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as NotebookCodeInvalidOutputUIDStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookCodeInvalidOutputUIDStrategy';

const classNames = bindClassNames.bind();

const NotebookCodeInvalidOutputUIDContentStrategy = ({ className, content }) => {
  const { cellName } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notebookCell.errors.type.notebook.invalid.output.unique.identifier.error.content"
        values={{ cellName }}
      />
    </span>
  );
};

NotebookCodeInvalidOutputUIDContentStrategy.propTypes = {
  className: PropTypes.string,
  content: NotebookCodeInvalidOutputUIDStrategyModel.propTypes.isRequired,
};

NotebookCodeInvalidOutputUIDContentStrategy.defaultProps = {
  className: '',
};

export default NotebookCodeInvalidOutputUIDContentStrategy;
