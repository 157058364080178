import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import { Form as AntDForm } from 'antd';

import Button from '@palette/components/designSystem/Button/Button';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Modal from '@palette/components/designSystem/Modal/Modal';
import Select from '@palette/components/designSystem/Select/Select';

import AddFilled from '@palette/components/utils/Icons/AddFilled';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import { PROPERTY_TYPES, VARIABLE_NAME_REGEX } from '@palette/constants/resources';
import { convertAnyValueToMoment, getMoment, isDate12AM } from '@palette/helpers/MomentHelper';
import { getRawValueType } from '@palette/helpers/ResourceHelper';

import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';

import styles from './RawDataAddEditModal.less';

const classNames = bindClassNames.bind(styles);

const defaultValues = {
  name: undefined,
  type: PROPERTY_TYPES.STRING,
  value: undefined,
  date: undefined,
  boolean: undefined,
  number: undefined,
  '[list,0]': undefined,
  '[nested,0,key]': undefined,
  '[nested,0,value]': undefined,
};

const RawDataAddEditModal = ({ visible, onClose, resourceId, path, action }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();
  const [currentType, setCurrentType] = useState(PROPERTY_TYPES.STRING);
  const [currentEditPropertyType, setCurrentEditPropertyType] = useState(PROPERTY_TYPES.STRING);
  const [listCounter, setListCounter] = useState(1);
  const [nestedCounter, setNestedCounter] = useState(1);
  const [initialValues, setInitialValues] = useState(defaultValues);

  const resource = useSelector((state) => ResourcesSelectors.getResourceById(state, { resourceId }));

  const addEditPropertyIsPending = useSelector(ResourcesSelectors.addEditPropertyIsPending);

  const [form] = AntDForm.useForm();

  const propertyTypes = Object.keys(PROPERTY_TYPES).map((type) => ({
    label: t(`resources.propertyTypes.${type}`),
    value: type,
  }));

  const initialVal = useMemo(() => _get(resource.data, path), [resource, path]);

  const isPathAnArrayOfItems = useMemo(() => Array.isArray(initialVal), [initialVal]);

  const checkInitialValues = () => {
    let name;

    if (action === 'add') {
      if (isPathAnArrayOfItems) {
        name = initialVal.length + 1;
      }

      return {
        ...initialValues,
        name,
      };
    }

    // Get property value
    const getPathValue = initialVal;
    let getPathValueType = getRawValueType(getPathValue)[2];

    // Get property name
    name = path.slice(-1).join();
    const pathWithoutLastItem = path.slice(0, path.length - 1);
    const parentData = _get(resource.data, pathWithoutLastItem);
    const hasParentAndParentIsArray = Array.isArray(parentData);

    if (hasParentAndParentIsArray) {
      name = (Number(path.slice(-1).join()) + 1).toString();
    }

    // Get property type
    if (getPathValueType === PROPERTY_TYPES.OBJECT && Array.isArray(getPathValue)) {
      getPathValueType = PROPERTY_TYPES.ARRAY;
    }

    let usefullValues = {};

    if (getPathValueType === PROPERTY_TYPES.STRING) {
      usefullValues = {
        value: getPathValue,
      };
    } else if (getPathValueType === PROPERTY_TYPES.DATE) {
      usefullValues = {
        date: convertAnyValueToMoment(getPathValue, true),
      };
    } else if (getPathValueType === PROPERTY_TYPES.BOOLEAN) {
      usefullValues = {
        boolean: getPathValue,
      };
    } else if (getPathValueType === PROPERTY_TYPES.NUMBER) {
      usefullValues = {
        number: getPathValue,
      };
    } else if (getPathValueType === PROPERTY_TYPES.ARRAY) {
      for (let i = 0; i < getPathValue.length; i += 1) {
        const isEditable = getRawValueType(getPathValue[i])[2] !== PROPERTY_TYPES.OBJECT;
        const key = `[list,${i}]`;
        const keyNotEditable = `[list,${i},undefined]`;
        usefullValues = {
          ...usefullValues,
          [key]: getPathValue[i],
          ...(!isEditable && { [keyNotEditable]: undefined }),
        };
      }
      setListCounter(getPathValue.length);
    } else if (getPathValueType === PROPERTY_TYPES.OBJECT) {
      Object.keys(getPathValue).sort().map((item, i) => {
        const isEditable = getRawValueType(getPathValue[item])[2] !== PROPERTY_TYPES.OBJECT;
        const key = `[nested,${i},key]`;
        const keyNotEditable = `[nested,${i},key,undefined]`;
        const value = `[nested,${i},value]`;
        const valueNotEditable = `[nested,${i},value,undefined]`;
        usefullValues = {
          ...usefullValues,
          [key]: item,
          [value]: getPathValue[item],
          ...(!isEditable && { [keyNotEditable]: undefined }),
          ...(!isEditable && { [valueNotEditable]: undefined }),
        };
        return usefullValues;
      });
      setNestedCounter(Object.keys(getPathValue).length);
    }

    return {
      name,
      type: getPathValueType,
      ...usefullValues,
    };
  };

  useEffect(() => {
    if (visible) {
      setInitialValues(checkInitialValues());
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setCurrentType(PROPERTY_TYPES[initialValues.type]);
      setCurrentEditPropertyType(PROPERTY_TYPES[initialValues.type]);
      form.resetFields();
    }
  }, [initialValues]);

  const cleanAndClose = () => {
    setCurrentType(PROPERTY_TYPES.STRING);
    setCurrentEditPropertyType(PROPERTY_TYPES.STRING);
    setListCounter(1);
    setNestedCounter(1);
    form.resetFields();
    onClose();
  };

  const handleFinish = (values) => {
    let finalValueName = values.name;
    let finalValue = values.value;

    if (isPathAnArrayOfItems) {
      finalValueName = initialVal.length.toString();
    }

    const fragments = _isEmpty(path)
      ? [finalValueName]
      : (
        action === 'edit'
          ? path
          : path.concat([finalValueName])
      );

    if (values.type === PROPERTY_TYPES.DATE) {
      finalValue = values.date;
      if (isDate12AM(values.date)) {
        finalValue = moment(values.date).format('YYYY-MM-DD');
      }
    } else if (values.type === PROPERTY_TYPES.BOOLEAN) {
      finalValue = values.boolean;
    } else if (values.type === PROPERTY_TYPES.NUMBER) {
      finalValue = values.number;
    } else if (values.type === PROPERTY_TYPES.ARRAY) {
      finalValue = [];
      for (let i = 0; i < listCounter; i += 1) {
        finalValue.push(values[`[list,${i}]`]);
      }
    } else if (values.type === PROPERTY_TYPES.OBJECT) {
      finalValue = {};
      for (let i = 0; i < nestedCounter; i += 1) {
        finalValue = {
          ...finalValue,
          [values[`[nested,${i},key]`]]: values[`[nested,${i},value]`],
        };
      }
    }

    dispatch(ResourcesActions.addEditProperty({
      connectorId: resource.connectorId,
      objectId: resource.id,
      type: resource.originalType,
      fragments,
      value: finalValue,
    }));

    // Scroll to the new field added.
    setTimeout(() => {
      const newField = document.getElementById(`root-${fragments.join()}`);
      newField?.scrollIntoView({ behavior: 'smooth' });
    }, 200);

    cleanAndClose();
  };

  const handleAddEdit = (event) => {
    event.stopPropagation();
    form.submit();
  };

  const handleNameValueChange = useCallback((e, nameField) => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      [nameField]: e.target.value.replace(/\s/g, '_'),
    });

    form.validateFields([nameField]);
  });

  const handleFormValuesChange = useCallback((changedValues) => {
    const fieldsValues = form.getFieldsValue(true);
    let valueToUpdate = {};

    if ('type' in changedValues && changedValues.type !== PROPERTY_TYPES.DATE && changedValues.type !== PROPERTY_TYPES.BOOLEAN) {
      let value;

      if (changedValues.type === PROPERTY_TYPES.NUMBER) {
        value = Number(fieldsValues.value || fieldsValues['[list,0]'] || fieldsValues['[nested,0,value]'])
        || (fieldsValues.value || fieldsValues['[list,0]'] || fieldsValues['[nested,0,value]'])?.toString();
      } else if (changedValues.type === PROPERTY_TYPES.STRING) {
        value = (fieldsValues.number || fieldsValues['[list,0]'] || fieldsValues['[nested,0,value]'])?.toString();
      } else if (changedValues.type === PROPERTY_TYPES.ARRAY) {
        value = (fieldsValues.value || fieldsValues.number || fieldsValues['[nested,0,value]'])?.toString();
      } else if (changedValues.type === PROPERTY_TYPES.OBJECT) {
        value = (fieldsValues.value || fieldsValues.number || fieldsValues['[list,0]'])?.toString();
      }

      valueToUpdate = {
        value,
        number: value,
        '[list,0]': value,
        '[nested,0,value]': value,
      };
    } else if ('value' in changedValues) {
      valueToUpdate = {
        number: undefined,
        '[list,0]': undefined,
        '[nested,0,value]': undefined,
      };
    } else if ('number' in changedValues) {
      valueToUpdate = {
        value: undefined,
        '[list,0]': undefined,
        '[nested,0,value]': undefined,
      };
    } else if ('[list,0]' in changedValues) {
      valueToUpdate = {
        value: undefined,
        number: undefined,
        '[nested,0,value]': undefined,
      };
    } else if ('[nested,0,value]' in changedValues) {
      valueToUpdate = {
        value: undefined,
        number: undefined,
        '[list,0]': undefined,
      };
    }

    form.setFieldsValue({
      ...fieldsValues,
      ...valueToUpdate,
    });
  });

  const handleTypeChange = useCallback((newType) => setCurrentType(newType));

  const handleEditOriginalFormatDate = useCallback(() => {
    const fieldsValues = form.getFieldsValue(true);

    handleTypeChange(PROPERTY_TYPES.STRING);

    form.setFieldsValue({
      ...fieldsValues,
      type: PROPERTY_TYPES.STRING,
      value: initialVal,
    });
  }, [initialVal]);

  const isTypeSelectDisabled = action === 'edit' && (currentEditPropertyType === PROPERTY_TYPES.ARRAY || currentEditPropertyType === PROPERTY_TYPES.OBJECT);

  const inputStringNode = useMemo(() => (
    <FormItem
      className={styles.rowField}
      name="value"
      label={t('rawData.modals.edit.propertyValue')}
      required
      rules={[
        { required: true, message: t('rawData.modals.edit.rules.propertyValue'), type: 'string' },
      ]}
    >
      <Input placeholder={t('rawData.modals.edit.propertyValuePlaceholder')} />
    </FormItem>
  ), []);

  const inputNumberNode = useMemo(() => (
    <FormItem
      className={styles.rowField}
      name="number"
      label={t('rawData.modals.edit.propertyValue')}
      required
      rules={[
        { required: true, message: t('rawData.modals.edit.rules.propertyNumber'), type: 'number' },
      ]}
    >
      <Input placeholder={t('rawData.modals.edit.propertyValuePlaceholder')} type="number" />
    </FormItem>
  ), []);

  const inputDateNode = useMemo(() => (
    <div className={styles.colFields}>
      <FormItem
        className={classNames({
          rowField: true,
          rowFieldDate: true,
        })}
        name="date"
        label={t('rawData.modals.edit.propertyValue')}
        required
        rules={[
          { required: true, message: t('rawData.modals.edit.rules.propertyDate'), type: 'date' },
        ]}
      >
        <DatePicker
          className={styles.rowField}
          picker="date"
          allowClear={false}
          disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
          showTime
          showNow={false}
        />
      </FormItem>
      {action === 'edit' && (
        <div className={styles.disclaimerDate}>
          {`${t('rawData.modals.storedAs', { value: initialVal })} `}
          <Button
            className={styles.editOriginalFormatLink}
            type="link"
            onClick={handleEditOriginalFormatDate}
          >
            {t('rawData.modals.editOriginalFormatLabel')}
          </Button>
        </div>
      )}
    </div>
  ), [action, initialVal]);

  const inputBooleanNode = useMemo(() => (
    <FormItem
      className={styles.rowField}
      name="boolean"
      label={t('rawData.modals.edit.propertyValue')}
      required
      rules={[
        { required: true, message: t('rawData.modals.edit.rules.propertyBoolean'), type: 'boolean' },
      ]}
    >
      <Input
        type="radio"
        radioValues={[
          {
            label: t('common.global.true').toLowerCase(),
            value: true,
          },
          {
            label: t('common.global.false').toLowerCase(),
            value: false,
          },
        ]}
        radioAlign="col"
      />
    </FormItem>
  ), []);

  const inputArrayNode = useMemo(() => (
    <div className={styles.colFields}>
      <FormItem
        className={classNames({
          rowField: true,
          labelOnly: true,
        })}
        label={t('rawData.modals.edit.propertyValue')}
        required
      />
      {[...Array(listCounter)].map((input, i) => (
        <div key={i}>
          {Object.keys(initialValues).find((key) => key === `[list,${i},undefined]`) && (
            <FormItem className={styles.rowField}>
              <Input placeholder={t('rawData.modals.edit.notEditable')} addonBefore={`${i + 1} - `} disabled />
            </FormItem>
          )}
          <FormItem
            className={classNames({
              rowField: true,
              hidden: Object.keys(initialValues).find((key) => key === `[list,${i},undefined]`),
            })}
            name={`[list,${i}]`}
            required
            rules={[
              {
                required: !Object.keys(initialValues).find((key) => key === `[list,${i},undefined]`),
                message: t('rawData.modals.edit.rules.propertyValue'),
              },
            ]}
          >
            <Input placeholder={t('rawData.modals.edit.propertyValuePlaceholder')} addonBefore={`${i + 1} - `} />
          </FormItem>
        </div>
      ))}
      <Button
        className={styles.addButton}
        type="link"
        icon={<AddFilled />}
        onClick={() => setListCounter(listCounter + 1)}
      >
        {t('rawData.button.addItem')}
      </Button>
      <span className={styles.disclaimer}>{t('rawData.modals.edit.disclaimer')}</span>
    </div>
  ), [listCounter]);

  const inputObjectNode = useMemo(() => (
    <div className={styles.colFields}>
      <FormItem
        className={classNames({
          rowField: true,
          labelOnly: true,
        })}
        label={t('rawData.modals.edit.propertyValue')}
        required
      />
      {[...Array(nestedCounter)].map((input, i) => (
        <div key={i}>
          <div className={styles.rowFields}>
            {Object.keys(initialValues).find((key) => key === `[nested,${i},key,undefined]`) && (
              <FormItem className={styles.rowField}>
                <Input placeholder={t('rawData.modals.edit.notEditable')} addonBefore={`${i + 1} - `} disabled />
              </FormItem>
            )}
            {Object.keys(initialValues).find((key) => key === `[nested,${i},value,undefined]`) && (
              <FormItem className={styles.rowField}>
                <Input placeholder={t('rawData.modals.edit.notEditable')} disabled />
              </FormItem>
            )}
          </div>
          <div className={styles.rowFields}>
            <FormItem
              className={classNames({
                rowField: true,
                hidden: Object.keys(initialValues).find((key) => key === `[nested,${i},key,undefined]`),
              })}
              name={`[nested,${i},key]`}
              required
              rules={[
                {
                  required: !Object.keys(initialValues).find((key) => key === `[nested,${i},key,undefined]`),
                  message: t('rawData.modals.edit.rules.propertyName'),
                  ...(!Object.keys(initialValues).find((key) => key === `[nested,${i},key,undefined]`) && {
                    pattern: VARIABLE_NAME_REGEX,
                  }),
                },
              ]}
              onChange={(event) => handleNameValueChange(event, `[nested,${i},key]`)}
            >
              <Input placeholder={t('common.global.name')} addonBefore={`${i + 1} - `} />
            </FormItem>
            <FormItem
              className={classNames({
                rowField: true,
                hidden: Object.keys(initialValues).find((key) => key === `[nested,${i},value,undefined]`),
              })}
              name={`[nested,${i},value]`}
              required
              rules={[
                {
                  required: !Object.keys(initialValues).find((key) => key === `[nested,${i},value,undefined]`),
                  message: t('rawData.modals.edit.rules.propertyValue'),
                },
              ]}
            >
              <Input placeholder={t('common.global.value')} />
            </FormItem>
          </div>
        </div>
      ))}
      <Button
        className={styles.addButton}
        type="link"
        icon={<AddFilled />}
        onClick={() => setNestedCounter(nestedCounter + 1)}
      >
        {t('rawData.button.addProperty')}
      </Button>
      <span className={styles.disclaimer}>{t('rawData.modals.edit.disclaimer')}</span>
    </div>
  ), [nestedCounter]);

  const titleNode = useMemo(() => {
    let subtitle;

    if (action === 'add' && _size(path) >= 1) {
      let name = path.slice(-1).join();
      name = Number(name) + 1 || name;

      if (isPathAnArrayOfItems) {
        subtitle = t('rawData.modals.edit.subtitle.item', {
          position: initialVal.length + 1,
          parent: name,
        });
      } else {
        subtitle = t('rawData.modals.edit.subtitle.property', {
          parent: name,
        });
      }
    }

    return (
      <div className={styles.title}>
        {t(`rawData.modals.${action}.title`, { type: resource.type })}
        {subtitle && <span>{subtitle}</span>}
      </div>
    );
  }, [path]);

  return (
    <Modal
      className={styles.modal}
      title={titleNode}
      visible={visible}
      onCancel={() => cleanAndClose()}
      onOk={(event) => handleAddEdit(event)}
      okIcon={<CheckFilled />}
      okText={t(`rawData.modals.${action}.confirm`)}
      loading={addEditPropertyIsPending}
      width="50%"
    >
      <Form className={styles.content} onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <div className={styles.rowFields}>
          <FormItem
            className={styles.rowField}
            name="name"
            label={t('rawData.modals.edit.propertyName')}
            required={!(isPathAnArrayOfItems || action === 'edit')}
            rules={[
              {
                required: !(isPathAnArrayOfItems || action === 'edit'),
                message: t('rawData.modals.edit.rules.propertyName'),
                ...(!(isPathAnArrayOfItems || action === 'edit') && { pattern: VARIABLE_NAME_REGEX }),
              },
            ]}
            onChange={(event) => handleNameValueChange(event, 'name')}
          >
            <Input
              placeholder={t('rawData.modals.edit.propertyName')}
              disabled={isPathAnArrayOfItems || action === 'edit'}
              autoFocus={action === 'add'}
            />
          </FormItem>
          <FormItem
            className={styles.rowField}
            name="type"
            label={t('rawData.modals.edit.propertyType')}
          >
            <Select
              placeholder={t('rawData.modals.edit.propertyType')}
              options={propertyTypes}
              disabled={isTypeSelectDisabled}
              onChange={handleTypeChange}
            />
          </FormItem>
        </div>
        <div className={styles.rowFields}>
          {currentType === PROPERTY_TYPES.STRING && inputStringNode}
          {currentType === PROPERTY_TYPES.NUMBER && inputNumberNode}
          {currentType === PROPERTY_TYPES.DATE && inputDateNode}
          {currentType === PROPERTY_TYPES.BOOLEAN && inputBooleanNode}
          {currentType === PROPERTY_TYPES.ARRAY && inputArrayNode}
          {currentType === PROPERTY_TYPES.OBJECT && inputObjectNode}
        </div>
      </Form>
    </Modal>
  );
};

RawDataAddEditModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  resourceId: PropTypes.string,
  path: PropTypes.array,
  action: PropTypes.oneOf(['add', 'edit']),
};

RawDataAddEditModal.defaultProps = {
  visible: false,
  onClose: () => {},
  resourceId: '',
  path: [],
  action: 'add',
};

export default RawDataAddEditModal;
