import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';

import { getDisplayedPages } from '@palette/helpers/PaginationHelper';

import { AVAILABLE_LIMITS } from '@palette/constants/pagination';

import * as PaginationModel from '@palette/models/Pagination';

import styles from './Pagination.less';

const classNames = bindClassNames.bind(styles);

const Pagination = ({ className, pagination, onPageChange, onLimitChange }) => {
  const { t } = useTranslation();

  const [customPage, setCustomPage] = useState('');
  useEffect(() => {
    setCustomPage('');
  }, [pagination]);

  const lastPage = useMemo(() => {
    if (pagination === null) return 1;

    return Math.floor(pagination.total / pagination.limit);
  }, [pagination]);

  const handleLimitChange = useCallback((newLimit) => {
    onLimitChange(newLimit);
  }, []);

  const handlePageNumberChange = useCallback((newPageNumber) => {
    onPageChange(newPageNumber - 1);
    setCustomPage('');
  }, []);

  const handlePageSelectorInputChange = useCallback(() => {
    if (!customPage || customPage === '') return;

    const newCustomPageNumber = parseInt(customPage, 10);

    if (Number.isNaN(newCustomPageNumber) || newCustomPageNumber < 1) {
      handlePageNumberChange(1);
      return;
    }

    if (newCustomPageNumber > lastPage) {
      handlePageNumberChange(lastPage);
      return;
    }

    handlePageNumberChange(newCustomPageNumber);
  }, [customPage, handlePageNumberChange, lastPage]);

  const limitOptions = AVAILABLE_LIMITS.map((limit) => ({
    label: t('pagination.perPage', { limit }),
    value: limit,
  }));

  const displayedPages = getDisplayedPages(pagination);
  const pagesNodes = useMemo(() => (
    displayedPages.map((pageNumber, pageNumberIndex) => {
      if (pageNumber === (pagination.page + 1)) {
        return (
          <div
            key={pageNumberIndex}
            className={classNames({
              page: true,
              currentPage: true,
            })}
          >
            {pageNumber}
          </div>
        );
      }

      if (pageNumber === null) {
        return (
          <div
            key={pageNumberIndex}
            className={classNames({
              page: true,
              otherPages: true,
            })}
          >
            ...
          </div>
        );
      }

      return (
        <Button
          key={pageNumberIndex}
          className={classNames({
            page: true,
            pageBtn: true,
          })}
          type="link"
          onClick={() => handlePageNumberChange(pageNumber)}
        >
          {pageNumber}
        </Button>
      );
    })
  ), [displayedPages, handlePageNumberChange]);

  const previousPage = useMemo(() => {
    if (pagination === null) return 0;

    return pagination.page - 1;
  }, [pagination]);
  const nextPage = useMemo(() => {
    if (pagination === null) return 0;

    return pagination.page + 1;
  }, [pagination]);

  const pagesControlsNode = useMemo(() => (
    <div className={styles.pagesControls}>
      <Button
        className={styles.prevNextBtn}
        type="linkSecondaryBlack"
        icon={(<ChevronLeftLine width={24} height={24} />)}
        disabled={previousPage < 0}
        onClick={() => handlePageNumberChange(previousPage + 1)}
      />
      <div className={styles.pages}>
        {pagesNodes}
      </div>
      <Button
        className={styles.prevNextBtn}
        type="linkSecondaryBlack"
        icon={(<ChevronRightLine width={24} height={24} />)}
        disabled={nextPage > lastPage}
        onClick={() => handlePageNumberChange(nextPage + 1)}
      />
    </div>
  ), [
    previousPage,
    handlePageNumberChange,
    pagesNodes,
    nextPage,
    lastPage,
  ]);

  if (pagination === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.results}>
        {t('pagination.results', { count: pagination.total })}
      </div>
      {pagesControlsNode}
      <Select
        className={styles.limitSelector}
        options={limitOptions}
        value={pagination.limit}
        onChange={handleLimitChange}
      />
      <div className={styles.goToPageContainer}>
        <div className={styles.directPageSelectorLabel}>
          {t('pagination.goToPage')}
        </div>
        <Input
          className={styles.directPageSelectorInput}
          type="number"
          controls={false}
          min={1}
          value={customPage}
          onChange={setCustomPage}
          onBlur={handlePageSelectorInputChange}
          onPressEnter={handlePageSelectorInputChange}
        />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  pagination: PaginationModel.propTypes,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  className: '',
  pagination: null,
};

export default Pagination;
