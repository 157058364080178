import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CompanyFilled from '@palette/components/utils/Icons/CompanyFilled';
import CardItem from '@palette/components/settings/CardItem/CardItem';
import Tag from '@palette/components/designSystem/Tag/Tag';
import CompanyTimezone from '@palette/components/company/CompanyTimezone/CompanyTimezone';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import InfoCircleLine from '@palette/components/utils/Icons/InfoCircleLine';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import AvatarUploader from '@palette/components/settings/AvatarUploader/AvatarUploader';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './CompanySettings.less';

const classNames = bindClassNames.bind(styles);

const CompanySettings = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company } = profile.userData;

  const getSeatsCountIsPending = useSelector(ProfileSelectors.getSeatsCountIsPending);

  useEffect(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return;

    dispatch(ProfileActions.getSeatsCount());
  }, []);

  const seatsNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

    let renderNode;

    if (getSeatsCountIsPending) {
      renderNode = <LoadingLine className={styles.icon} width={20} height={20} spin />;
    } else {
      renderNode = (
        <Tooltip title={t('companySettings.seats.tooltip')}>
          <div>
            <Tag
              className={styles.seatsTagWrapper}
              theme={TAG_THEMES.INFO}
              label={t('companySettings.seats.count', { count: company.seatsCount })}
              icon={InfoCircleLine}
            />
          </div>
        </Tooltip>
      );
    }

    return (
      <div className={styles.seatsWrapper}>{renderNode}</div>
    );
  }, [profile, company, getSeatsCountIsPending]);

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companySettings.block.title')}
      icon={<CompanyFilled width={20} height={20} />}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.avatarWrapper}>
          <AvatarUploader
            logoSrc={company.logo}
            defaultContent={<CompanyFilled width={20} height={20} />}
            editable={hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])}
            modelType="company"
          />
        </div>
        <div className={styles.detailsWrapper}>
          <div className={styles.companyName}>{company.name}</div>
          {company.currency && (
            <Tag
              className={styles.currencyTag}
              label={company.currency}
            />
          )}
          <CompanyTimezone />
          {seatsNode}
        </div>
      </div>
    </CardItem>
  );
};

CompanySettings.propTypes = {
  className: PropTypes.string,
};

CompanySettings.defaultProps = {
  className: '',
};

export default CompanySettings;
