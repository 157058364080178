export const ANALYTICS_DOC_SOURCES = {
  ADD_PLAN_PAGE: {
    id: 'ADD_PLAN_PAGE',
    label: 'Documentation link in Add Plan page',
  },
  ADD_USERS_MODAL: {
    id: 'ADD_USERS_MODAL',
    label: 'Documentation link in "Add Users" modal',
  },
  CONNECTOR_DETAILS_PAGE: {
    id: 'CONNECTOR_DETAILS_PAGE',
    label: 'Documentation link in Connector Details page',
  },
  CONNECTORS_PAGE: {
    id: 'CONNECTORS_PAGE',
    label: 'Documentation link in Connectors page',
  },
  NETSUITE_CONNECTOR_MODAL: {
    id: 'NETSUITE_CONNECTOR_MODAL',
    label: 'Documentation link in "Connect NetSuite" modal',
  },
  PLAN_DETAILS_PAGE_SETTINGS: {
    id: 'PLAN_DETAILS_PAGE_SETTINGS',
    label: 'Documentation link in Plan Details page, in Settings tab',
  },
  ROLES_PAGE: {
    id: 'ROLES_PAGE',
    label: 'Documentation link in Roles page',
  },
  SIDEBAR: {
    id: 'SIDEBAR',
    label: 'Documentation link in sidebar',
  },
  TEAM_DETAILS_PAGE: {
    id: 'TEAM_DETAILS_PAGE',
    label: 'Documentation link in Team Details page',
  },
  TEAMS: {
    id: 'TEAMS',
    label: 'Documentation link in Teams page',
  },
  UPLOAD_CSV_MODAL: {
    id: 'UPLOAD_CSV_MODAL',
    label: 'Documentation link in "Upload CSV Data" modal',
  },
};

export const CLICK_ON_DOC_LINK_EVENT = 'click on Documentation link';

export const QUOTA_EVENTS = {
  CREATED: 'Quota Created',
  DELETED: 'Quota Deleted',
  EDIT_DEFAULT_VALUES: 'Quota Edit Default Values',
  EDIT_NAME: 'Quota Edit Name',
  ADD_USERS: 'Quota Add Users',
  REMOVE_USER: 'Quota Remove User',
  EDIT_USER_DEFAULT_VALUES: 'Quota Edit User\'s Default Values',
  EDIT_OVERWRITE_USER_TARGET: 'Quota Overwrite a user\'s target',
  EDIT_RESET_USER_TARGET: 'Quota Reset a user\'s target',
  EDIT_USER_LAST_PERIOD: 'Quota Edit User\'s last period',
};

export const QUOTA_PERFORMANCE_EVENTS = {
  CREATED: 'Quota Performance Dashboard Created',
  DELETED: 'Quota Performance Dashboard Deleted',
  EDIT_NAME: 'Quota Performance Dashboard Edit Name',
  EDIT_DESCRIPTION: 'Quota Performance Dashboard Edit Description',
  ADD_PLANS: 'Quota Performance Dashboard Add Plans',
  REMOVE_PLAN: 'Quota Performance Dashboard Remove Plan',
};

export const RAW_DATA_EVENTS = {
  CREATE_OR_EDIT: 'RawData create or edit',
  DELETE_OVERWRITE: 'RawData delete overwrite',
  REMOVE_PROPERTY: 'RawData remove property',
};

export const COMMISSION_EVENTS = {
  UPDATE_AMOUNT: 'Update commission amount',
  UPDATE_VALUE: 'Update commission value',
  LOADING_DETAILS: 'Loading Commission details',
  UPDATE_PAYOUT: 'Update commission payout',
};

export const THREAD_EVENTS = {
  NEW_COMMENT: 'New Comment',
  SET_STATUS: 'Thread status set to {{STATUS}}',
};

export const CLICK_ON_EASTER_EGG_OVERPERFORMING = 'Click Easter Egg Over-performing';

export const DASHBOARD_PRESET_EVENTS = {
  UPDATE_DASHBOARD_PRESET: 'Update Dashboard preset',
};

export const MASTER_PLANS_EVENTS = {
  ARCHIVED: 'Plan archived',
  PLAN_LIST: {
    CLICK_PLAN_NAME: 'Plan list click plan name',
    CLICK_PERIOD: 'Plan list click period',
    CLICK_CREATE_PLAN: 'Plan list click create plan',
    CLICK_FREEZE: 'Plan list click freeze',
    CREATE_FOLDER: 'Plan list create folder',
    CLICK_COMPARE_PLAN: 'Plan list click compare plans',
    OPTIONS_CLICK_SETTING: 'Plan list option click settings',
    OPTIONS_CLICK_DUPLICATE: 'Plan list option click duplicate',
    OPTIONS_CLICK_COMPARE: 'Plan list option click compare',
    OPTIONS_CLICK_ARCHIVE: 'Plan list option click archive',
    OPTIONS_CLICK_DELETE: 'Plan list option click delete',
  },
  PERIOD_LIST: {
    CLICK_VIEW_BY: 'Period list click view by',
    CLICK_SETTINGS: 'Period list click settings',
    CLICK_RECOMPUTE: 'Period list click recompute',
    CLICK_FREEZE: 'Period list click freeze',
  },
  PERIOD_DETAILS: {
    CLICK_SETTINGS: 'Period details click settings',
    CLICK_RECOMPUTE: 'Period details click recompute',
    CLICK_FREEZE: 'Period details click freeze',
    CLICK_PREVIOUS_PERIOD: 'Period details click previous period',
    CLICK_NEXT_PERIOD: 'Period details click next period',
    SELECT_PERIOD: 'Period details select other period',
    CLICK_VIEW_DEALS: 'Period details view deals',
    CLICK_VIEW_DETAILS: 'Period details view details',
    CLICK_PAYMENT_TAG: 'Period details payment tag',
    CLICK_ACTIVITY_TAG: 'Period details activity tag',
    CLICK_EDIT_COLUMNS: 'Period details edit columns',
    CLICK_COMMISSIONS_WITHOUT_PAYMENT_LINK: 'Period details click view commissions on-hold',
  },
  CREATE_PLAN_FLOW: {
    CREATE_NEW_PLAN: 'Create a new plan',
    CREATE_NEW_PLAN_FINALIZED: 'Create a new plan finalized',
    CREATE_PLAN_FLOW_ADD_DEPENDENCIES: 'Create plan flow click link to add dependencies',
  },
  HIGHLIGHT_ZONE: {
    CLICK_COMMISSIONS_UNRELEASED_WIDGET: 'Period list click view commissions on-hold in widget',
  },
};

export const PLANS_V3_EVENTS = {
  PLAN_LIST: {
    CLICK_CREATE_PLAN: 'Plan (v3) click create plan on listing page',
    CLICK_PLAN_NAME: 'Plan (v3) click plan name on listing page',
    CLICK_DELETE: 'Plan (v3) click delete on listing page',
  },
  CREATE_NEW_PLAN: 'New Plan (v3) created',
  UPDATE_PLAN_REQUIREMENTS: 'Plan (v3) requirements updated',
  SAVE_PLAN: 'Plan (v3) saved',
};

export const DASHBOARD_EVENTS = {
  ADD_WIDGET_DRAWER: 'Add widget to dashboard IC',
  OPEN_WIDGET_DRAWER: 'Opening widget drawer',
};

export const COMMISSION_LIST_EVENTS = {
  FILTER_SEARCH: 'Commission list filter search',
  FILTER_USER: 'Commission list filter user',
  FILTER_TEAM: 'Commission list filter team',
  FILTER_RESOURCE: 'Commission list filter resource',
  FILTER_PLAN: 'Commission list filter plan',
  FILTER_DATE: 'Commission list filter date',
  FILTER_STATUS: 'Commission list filter status',
  SORT: 'Commission list sort',
  EXPORT: 'Commission list export',
};

export const STATEMENT_EVENTS = {
  VALIDATION_EMAIL_ACTIVATED: 'Statement Validation Email Activated (admin)',
  VALIDATION_EMAIL_DEACTIVATED: 'Statement Validation Email Deactivated (admin)',
  CLICK_INVESTIGATE_LINK: 'Statement investigate click link',
  CLICK_BACK_TO_STATEMENT_LINK: 'Statement investigate click back to statement link',
  CLICK_FROM_WHICH_STATEMENT_LINK: 'Statement investigate click from which statement link',
};

export const EMAIL_NOTIFICATIONS_EVENTS = {
  SUBSCRIBED_weeklyDigest: 'Subscribed weekly email',
  UNSUBSCRIBED_weeklyDigest: 'Unsubscribed weekly email',
  SUBSCRIBED_monthlyDigest: 'Subscribed monthly email',
  UNSUBSCRIBED_monthlyDigest: 'Unsubscribed monthly email',
  SUBSCRIBED_statementValidationNotifications: 'Subscribed statement validation email',
  UNSUBSCRIBED_statementValidationNotifications: 'Unubscribed statement validation email',
};

export const AUDIT_TRAIL_EVENTS = {
  FILTER_TYPE: 'Audit trail filter type',
  FILTER_TIME: 'Audit trail filter time',
  CLICK_LINK: 'Audit trail click link',
};

export const USER_EVENTS = {
  INVITE_USER: 'invite user',
  OFFBOARDING_APPROVED: 'Offboarding approved',
  ONBOARD_USERS_COMPLETED: 'Onboard Users Completed',
  DEACTIVATE_USER: 'Deactivate user',
  UNLINK_RESOURCE_FROM_USER: 'Unlink resource from user',
  LINK_RESOURCES_TO_USER: 'Link resources to user',
  FIRST_LOGIN: 'First login',
};

export const USERS_VARIABLES_EVENTS = {
  VARIABLE_CREATED: 'User Variable Created',
  VARIABLE_EDITED: 'User Variable Edited',
  VARIABLE_OVERRIDE_DELETED: 'User Variable Override Deleted',
};
