import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PaletteLogo from '@palette/components/utils/PaletteLogo/PaletteLogo';
import GoogleAuthButton from '@palette/components/login/GoogleAuthButton/GoogleAuthButton';
import EmailPasswordForm from '@palette/components/login/EmailPasswordForm/EmailPasswordForm';
import Link from '@palette/components/designSystem/Link/Link';

import { useRegisterRefInSearch } from '@palette/hooks/AuthenticationHooks';

import { PALETTE_SALESFORCE_URL } from '@palette/constants/global';

import appConfig from '@palette/config/app';
import routePaths from '@palette/config/routePaths';

import styles from './LoginPageContent.less';

const classNames = bindClassNames.bind(styles);

const LoginPageContent = ({ className }) => {
  const { t } = useTranslation();

  const [refValue] = useRegisterRefInSearch();

  const inSalesforceApp = useMemo(() => (
    window.location.host === PALETTE_SALESFORCE_URL
  ), []);

  const titleNode = useMemo(() => {
    if (inSalesforceApp) return null;

    return (
      <h1>
        {t('loginPageContent.title')}
      </h1>
    );
  }, [inSalesforceApp]);

  const googleAuthButtonNode = useMemo(() => {
    if (inSalesforceApp || appConfig.GOOGLE_CLIENT_ID === null) return null;

    return (
      <GoogleAuthButton className={styles.googleAuthButton} />
    );
  }, [inSalesforceApp, appConfig.GOOGLE_CLIENT_ID]);

  const passwordAuthNode = useMemo(() => {
    if (inSalesforceApp) return null;

    return (
      <>
        <EmailPasswordForm className={styles.passwordAuthWrapper} />
        <Link path={routePaths.v2.forgotPassword}>
          {t('loginPageContent.forgotPassword')}
        </Link>
      </>
    );
  }, [inSalesforceApp]);

  const intermediaryTitleNode = useMemo(() => {
    if (inSalesforceApp || googleAuthButtonNode === null) return null;

    return (
      <div className={styles.intermediaryTitle}>
        <div className={styles.dots} />
        <div className={styles.title}>
          {t('loginPageContent.intermediaryTitle')}
        </div>
        <div className={styles.dots} />
      </div>
    );
  }, [inSalesforceApp, googleAuthButtonNode, passwordAuthNode]);

  const noAccountYetNode = useMemo(() => {
    if (inSalesforceApp) return null;

    let qsObject = {};
    if (refValue !== null) {
      qsObject = {
        ref: refValue,
      };
    }

    return (
      <>
        <div className={styles.intermediaryTitle}>
          <div className={styles.dots} />
        </div>
        <Link className={styles.registerLink} path={routePaths.register} qsObject={qsObject}>
          {t('loginPageContent.noAccountYet')}
        </Link>
      </>
    );
  }, [inSalesforceApp, refValue]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <PaletteLogo width={177} height={60} />
      {titleNode}
      {googleAuthButtonNode}
      {intermediaryTitleNode}
      {passwordAuthNode}
      {noAccountYetNode}
    </div>
  );
};

LoginPageContent.propTypes = {
  className: PropTypes.string,
};

LoginPageContent.defaultProps = {
  className: '',
};

export default LoginPageContent;
