import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Select from '@palette/components/designSystem/Select/Select';
import Calendar2Filled from '@palette/components/utils/Icons/Calendar2Filled';
import Button from '@palette/components/designSystem/Button/Button';
import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { usePeriodsDatesFilter, usePeriodsDatesTypesOptions } from '@palette/hooks/StatementHooks';

import { getBeginOfHalfYear, getEndOfHalfYear, getMoment } from '@palette/helpers/MomentHelper';

import { STATEMENT_PERIODS_DATES_TYPES } from '@palette/constants/statements';

import styles from './StatementPeriodsDatesSelector.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodsDatesSelector = ({ className, forcedDatesFilter, inSalesforce }) => {
  const moment = getMoment();

  const periodsDatesFilter = usePeriodsDatesFilter(inSalesforce);

  const [type, from, to, handleFilterChange] = useMemo(() => {
    if (forcedDatesFilter !== null) {
      return [forcedDatesFilter.type, forcedDatesFilter.from, forcedDatesFilter.to, forcedDatesFilter.handleFilterChange];
    }

    return periodsDatesFilter;
  }, [periodsDatesFilter, forcedDatesFilter]);

  const typesOptions = usePeriodsDatesTypesOptions(from);

  const handleTypeChange = useCallback((newType) => {
    if (newType === STATEMENT_PERIODS_DATES_TYPES.CUSTOM) {
      const fromDateMoment = moment.utc(from);

      const newFrom = moment.utc(fromDateMoment).startOf('month');

      let newTo = moment.utc(newFrom).endOf('month');

      if (type === STATEMENT_PERIODS_DATES_TYPES.QUARTER) {
        newTo = moment.utc(newFrom).endOf('quarter');
      }

      if (type === STATEMENT_PERIODS_DATES_TYPES.HALF_YEAR) {
        newTo = getEndOfHalfYear(newFrom);
      }

      if (type === STATEMENT_PERIODS_DATES_TYPES.YEAR) {
        newTo = moment.utc(newFrom).endOf('year');
      }

      handleFilterChange(newType, newFrom, newTo);
      return;
    }

    handleFilterChange(newType, from);
  }, [type, from, handleFilterChange]);

  const handlePrevPeriodClick = useCallback(() => {
    if (type === STATEMENT_PERIODS_DATES_TYPES.CUSTOM) return;

    const fromDateMoment = moment.utc(from);

    let newFrom = moment.utc(fromDateMoment).subtract(1, 'month');

    if (type === STATEMENT_PERIODS_DATES_TYPES.QUARTER) {
      newFrom = moment.utc(fromDateMoment).subtract(1, 'quarter');
    }

    if (type === STATEMENT_PERIODS_DATES_TYPES.HALF_YEAR) {
      newFrom = getBeginOfHalfYear(moment.utc(fromDateMoment).subtract(6, 'month'));
    }

    if (type === STATEMENT_PERIODS_DATES_TYPES.YEAR) {
      newFrom = moment.utc(fromDateMoment).subtract(1, 'year');
    }

    handleFilterChange(type, newFrom);
  }, [type, from, handleFilterChange]);

  const handleNextPeriodClick = useCallback(() => {
    if (type === STATEMENT_PERIODS_DATES_TYPES.CUSTOM) return;

    const fromDateMoment = moment.utc(from);

    let newFrom = moment.utc(fromDateMoment).add(1, 'month');

    if (type === STATEMENT_PERIODS_DATES_TYPES.QUARTER) {
      newFrom = moment.utc(fromDateMoment).add(1, 'quarter');
    }

    if (type === STATEMENT_PERIODS_DATES_TYPES.HALF_YEAR) {
      newFrom = getBeginOfHalfYear(moment.utc(fromDateMoment).add(6, 'month'));
    }

    if (type === STATEMENT_PERIODS_DATES_TYPES.YEAR) {
      newFrom = moment.utc(fromDateMoment).add(1, 'year');
    }

    handleFilterChange(type, newFrom);
  }, [type, from, handleFilterChange]);

  const handleCustomDatesChange = useCallback((newDates) => {
    handleFilterChange(type, newDates[0], newDates[1]);
  }, [type, handleFilterChange]);

  const navigationNode = useMemo(() => {
    const selectNode = (
      <div className={styles.typeSelectWrapper}>
        <Calendar2Filled className={styles.typeSelectIcon} width={16} height={16} />
        <Select
          className={styles.typeSelect}
          dropdownClassName={styles.typeSelectDropdown}
          showArrow={false}
          options={typesOptions}
          value={type}
          onSelect={handleTypeChange}
        />
      </div>
    );

    const disablePrevNext = type === STATEMENT_PERIODS_DATES_TYPES.CUSTOM;

    return (
      <div className={styles.navigationWrapper}>
        <Button
          className={classNames({
            prevNextBtn: true,
            disabled: disablePrevNext,
          })}
          type="link"
          icon={<ChevronLeftLine width={24} height={24} />}
          onClick={handlePrevPeriodClick}
          disabled={disablePrevNext}
        />
        {selectNode}
        <Button
          className={classNames({
            prevNextBtn: true,
            disabled: disablePrevNext,
          })}
          type="link"
          icon={<ChevronRightLine width={24} height={24} />}
          onClick={handleNextPeriodClick}
          disabled={disablePrevNext}
        />
      </div>
    );
  }, [
    type,
    typesOptions,
    handleTypeChange,
    handlePrevPeriodClick,
    handleNextPeriodClick,
  ]);

  const datesNode = useMemo(() => {
    if (type !== STATEMENT_PERIODS_DATES_TYPES.CUSTOM) return null;

    return (
      <DatePicker
        className={styles.datesRangePicker}
        allowClear={false}
        disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
        showTime={false}
        showNow={false}
        isRange
        value={[from, to]}
        onChange={handleCustomDatesChange}
      />
    );
  }, [type, from, to, handleCustomDatesChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {navigationNode}
      {datesNode}
    </div>
  );
};

StatementPeriodsDatesSelector.propTypes = {
  className: PropTypes.string,
  forcedDatesFilter: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(STATEMENT_PERIODS_DATES_TYPES)),
    from: PropTypes.any,
    to: PropTypes.any,
    handleFilterChange: PropTypes.func,
  }),
  inSalesforce: PropTypes.bool,
};

StatementPeriodsDatesSelector.defaultProps = {
  className: '',
  forcedDatesFilter: null,
  inSalesforce: false,
};

export default StatementPeriodsDatesSelector;
