import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodsNavigation from '@palette/components/statement/statementPeriod/StatementPeriodsNavigation/StatementPeriodsNavigation';
import UsersStatements from '@palette/components/statement/statementPeriodUsers/UsersStatements/UsersStatements';

import { useAndFetchStatementPeriodInParams } from '@palette/hooks/StatementHooks';

import styles from './StatementsPeriodPageContent.less';

const classNames = bindClassNames.bind(styles);

const StatementsPeriodPageContent = ({ className, currency }) => {
  const { statementPeriod } = useAndFetchStatementPeriodInParams(currency);

  if (!statementPeriod) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <StatementPeriodsNavigation className={styles.navigation} currency={currency} />
      {/**
       * TODO: widgets coming in WP6.
       */}
      <UsersStatements statementPeriod={statementPeriod} currency={currency} />
    </div>
  );
};

StatementsPeriodPageContent.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
};

StatementsPeriodPageContent.defaultProps = {
  className: '',
  currency: null,
};

export default StatementsPeriodPageContent;
