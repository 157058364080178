import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import algoliasearch from 'algoliasearch/lite';
import { createAutocomplete } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import AlgoliaSearchResultLvl0 from '@palette/components/vendors/Algolia/AlgoliaSearchResultLvl0/AlgoliaSearchResultLvl0';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useKeyPress } from '@palette/hooks/CommonHooks';

import { ANALYTICS_DOC_SOURCES } from '@palette/constants/analytics';
import { PALETTE_DOCUMENTATION_URL } from '@palette/constants/global';

import appConfig from '@palette/config/app';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './AlgoliaAutocomplete.less';

const classNames = bindClassNames.bind(styles);

const AlgoliaAutocomplete = ({ className }) => {
  const dispatch = useDispatch();

  const inputRef = useRef();
  const listRef = useRef();

  const { t } = useTranslation();

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');

  const searchClient = useMemo(() => algoliasearch(appConfig.ALGOLIA_APP_ID, appConfig.ALGOLIA_API_KEY), []);
  const [autocompleteState, setAutocompleteState] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [focusedElementIndex, setFocusedElementIndex] = useState(-1);

  useEffect(() => {
    inputRef.current?.focus({
      cursor: 'start',
    });
  }, []);

  const selectableElements = useMemo(() => (listRef.current?.querySelectorAll('*[tabindex="0"]')), [inputValue, autocompleteState]);

  useEffect(() => {
    setFocusedElementIndex(-1);
  }, [inputValue]);

  useEffect(() => {
    if (selectableElements?.length > 0) {
      let newIndex = focusedElementIndex;
      if (upPress) {
        newIndex = focusedElementIndex - 1;
        if (!selectableElements[newIndex]) {
          newIndex = selectableElements.length - 1;
        }
      }

      if (downPress) {
        newIndex = focusedElementIndex + 1;
        if (!selectableElements[newIndex]) {
          newIndex = 0;
        }
      }

      selectableElements[newIndex].focus();
      setFocusedElementIndex(newIndex);
    }
  }, [downPress, upPress]);

  useEffect(() => {
    if (selectableElements?.length > 0 && selectableElements?.[focusedElementIndex] && enterPress) {
      selectableElements[focusedElementIndex].focus();
      selectableElements[focusedElementIndex].click();
    }
  }, [focusedElementIndex, enterPress]);

  const autocomplete = React.useMemo(
    () => createAutocomplete({
      onStateChange({ state }) {
        setAutocompleteState(state);
      },
      getSources() {
        return [
          {
            sourceId: 'SearchInDoc',
            getItemInputValue({ item }) {
              return item.query;
            },
            getItems({ query, setContext }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: appConfig.ALGOLIA_DOC_INDEX_NAME,
                    query,
                    params: {
                      hitsPerPage: 20,
                      highlightPreTag: '<mark>',
                      highlightPostTag: '</mark>',
                      snippetEllipsisText: '…',
                      attributesToRetrieve: ['hierarchy.lvl0', 'hierarchy.lvl1', 'hierarchy.lvl2', 'hierarchy.lvl3', 'hierarchy.lvl4', 'hierarchy.lvl5', 'hierarchy.lvl6', 'content', 'type', 'url'],
                      attributesToSnippet: ['hierarchy.lvl1:10', 'hierarchy.lvl2:10', 'hierarchy.lvl3:10', 'hierarchy.lvl4:10', 'hierarchy.lvl5:10', 'hierarchy.lvl6:10', 'content:10'],
                    },
                  },
                ],
                transformResponse({ results, hits }) {
                  setContext({
                    nbResults: results[0].nbHits,
                  });

                  return hits;
                },
              });
            },
            getItemUrl({ item }) {
              return item.url;
            },
          },
        ];
      },
    }), []);

  const { onChange, onClick, onBlur, onFocus, ...inputProps } = autocomplete.getInputProps({});

  const onInputChange = (newValue) => {
    onChange({ currentTarget: { value: newValue } });
    setInputValue(newValue);
  };

  const handleSeeFullDoc = () => {
    dispatch(AnalyticsActions.sendClickOnDocLink({ analyticsDocSource: ANALYTICS_DOC_SOURCES.SIDEBAR }));
    window.open(PALETTE_DOCUMENTATION_URL);
  };

  const emptyStateNode = useMemo(() => {
    if (inputValue === '') {
      return (
        <div className={styles.empty}>
          {t('algoliaAutocomplete.typeSearch')}
        </div>
      );
    }

    return (
      <DefaultEmptyState description={t('algoliaAutocomplete.noResultsFor', { search: inputValue })} />
    );
  }, [inputValue]);

  const resultsNodes = useMemo(() => {
    if (!autocompleteState.collections || autocompleteState.collections.length === 0) return emptyStateNode;

    const collection = autocompleteState.collections[0];
    const { source, items } = collection;

    if (items.length === 0) return emptyStateNode;

    const itemsByLvl0 = items.reduce((res, item) => {
      const key = item.hierarchy.lvl0;
      const keyItems = res[key] || [];
      keyItems.push(item);
      return {
        ...res,
        [key]: keyItems,
      };
    }, {});

    const handleGetItemProps = (item) => autocomplete.getItemProps({ item, source });

    const handleSeeMoreClick = () => {
      window.open(`${PALETTE_DOCUMENTATION_URL}/search?q=${inputValue}`);
    };

    return (
      <>
        <div>
          {
            Object.keys(itemsByLvl0).map((lvl0) => (
              <AlgoliaSearchResultLvl0
                className={styles.result}
                key={lvl0}
                title={lvl0}
                items={itemsByLvl0[lvl0]}
                getItemProps={handleGetItemProps}
                getListProps={autocomplete.getListProps}
                {...autocomplete.getPanelProps({})}
              />
            ))
          }
        </div>
        {
          autocompleteState.context.nbResults > 20 && (
            // eslint-disable-next-line jsx-a11y/tabindex-no-positive
            <Button className={styles.seeAllbtn} type="link" onClick={handleSeeMoreClick} tabIndex="1">
              {t('algoliaAutocomplete.seeAllResults', { nbResults: autocompleteState.context.nbResults })}
            </Button>
          )
        }
      </>
    );
  }, [autocomplete, autocompleteState, inputValue]);

  if (
    appConfig.ALGOLIA_APP_ID === null
    || appConfig.ALGOLIA_API_KEY === null
    || appConfig.ALGOLIA_DOC_INDEX_NAME === null
  ) {
    return null;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...autocomplete.getRootProps({})}
    >
      <div className={styles.header} onSubmit={() => false}>
        <Input
          ref={inputRef}
          className={styles.input}
          {...inputProps}
          type="search"
          onChange={onInputChange}
        />
        {/* eslint-disable-next-line jsx-a11y/tabindex-no-positive */}
        <Button type="link" onClick={handleSeeFullDoc} tabIndex="1">
          {t('algoliaAutocomplete.seeFullDoc')}
        </Button>
      </div>
      <div className={styles.results} ref={listRef}>
        {resultsNodes}
      </div>
    </div>
  );
};

AlgoliaAutocomplete.propTypes = {
  className: PropTypes.string,
};

AlgoliaAutocomplete.defaultProps = {
  className: '',
};

export default AlgoliaAutocomplete;
