import React from 'react';

import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';
import SpeedometerFilled from '@palette/components/utils/Icons/SpeedometerFilled';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import SearchLine from '@palette/components/utils/Icons/SearchLine';
import FlagFilled from '@palette/components/utils/Icons/FlagFilled';
import MenuNotificationsIcon from '@palette/components/navigation/MenuNotificationsIcon/MenuNotificationsIcon';
import MenuNotifications from '@palette/components/navigation/MenuNotifications/MenuNotifications';
import MenuWithComputeButton from '@palette/components/navigation/MenuWithComputeButton/MenuWithComputeButton';

import routePaths from '@palette/config/routePaths';

import { hasAtLeastOneAdminRight, hasFeature, hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { MENU_TAGS } from '@palette/constants/menu';
import { FEATURES, RIGHTS } from '@palette/constants/profile';

const icMenu = {
  key: 'IcMenu',
  title: 'icMenu.title',
  show: (profile) => hasAtLeastOneRight(profile, [
    RIGHTS.IC.COMPENSATION,
  ]) || !hasAtLeastOneAdminRight(profile),
  items: [
    {
      key: 'IcMenuMyDashboard',
      path: routePaths.v2.myDashboard,
      icon: (<SpeedometerFilled />),
      name: 'icMenu.myDashboard',
      show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])),
    },
    {
      key: 'IcMenuMyCommissions',
      path: routePaths.v2.myStatements,
      icon: (<StatementsFilled />),
      name: 'icMenu.myCommissions',
      show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasFeature(profile, FEATURES.PLANV2) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]),
      submenuItems: [
        {
          key: 'IcMenuMyStatementsV2',
          path: routePaths.v2.myStatements,
          icon: (<StatementsFilled />),
          name: 'icMenu.myStatements',
          show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]),
        },
        {
          key: 'IcMenuMyCommissionList',
          path: routePaths.v2.myCommissionList,
          icon: (<SearchLine />),
          name: 'icMenu.myCommissionList',
          show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]),
        },
      ],
    },
    {
      key: 'IcMenuMyPlans',
      path: routePaths.v2.myPlans,
      icon: (<MedicalChartFilled />),
      name: 'icMenu.myPlans',
      show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION) && hasFeature(profile, FEATURES.PLANV2) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])),
      disableOnClick: true,
      contentComponent: ({ name, path, pathQS }) => (<MenuWithComputeButton name={name} path={path} pathQS={pathQS} />),
    },
    {
      key: 'IcMenuMyPlansV3Only',
      path: routePaths.v2.myPlansV3,
      icon: (<MedicalChartFilled />),
      name: 'icMenu.myPlans',
      show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION)
        && hasFeature(profile, FEATURES.PLANV3)
        && !hasFeature(profile, FEATURES.PLANV2)
        && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])),
      hasTag: MENU_TAGS.BETA,
    },
    {
      key: 'IcMenuMyStatementsV3Only',
      path: routePaths.v2.myStatementsV3,
      icon: (<StatementsFilled />),
      name: 'icMenu.myStatements',
      show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION)
        && hasFeature(profile, FEATURES.PLANV3)
        && !hasFeature(profile, FEATURES.PLANV2)
        && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])),
      hasTag: MENU_TAGS.BETA,
    },
    {
      key: 'IcMenuMyPlansV3',
      path: routePaths.v2.myPlansV3,
      icon: (<FlagFilled />),
      name: 'adminMenu.plansV3.configuration',
      show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION)
        && hasFeature(profile, FEATURES.PLANV3)
        && hasFeature(profile, FEATURES.PLANV2)
        && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])),
      submenuItems: [
        {
          key: 'IcMenuMyPlansV3Plans',
          path: routePaths.v2.myPlansV3,
          icon: (<MedicalChartFilled />),
          name: 'adminMenu.plansV3.plans',
          hasTag: MENU_TAGS.BETA,
          show: (profile) => (hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])),
        },
        {
          key: 'IcMenuMyStatementsV3',
          path: routePaths.v2.myStatementsV3,
          icon: (<StatementsFilled />),
          name: 'adminMenu.plansV3.statements',
          hasTag: MENU_TAGS.BETA,
          show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]),
        },
      ],
    },
    {
      key: 'IcMenuNotifications',
      icon: (<MenuNotificationsIcon />),
      name: 'icMenu.notifications',
      show: (profile) => hasFeature(profile, FEATURES.COMPENSATION) && !hasAtLeastOneAdminRight(profile),
      disableOnClick: true,
      contentComponent: ({ name }) => (<MenuNotifications name={name} />),
    },
  ],
};

export default icMenu;
