import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  USER_INVITATION_SUCCESS: {
    code: 'notifications.users.success.invite',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_ONBOARDING_SUCCESS: {
    code: 'notifications.users.success.onboardUsers',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_UPDATE_SUCCESS: {
    code: 'notifications.users.success.updateUser',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_DEACTIVATE_SUCCESS: {
    code: 'notifications.users.success.deactivateUser',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_UNLINK_RESOURCE_SUCCESS: {
    code: 'notifications.users.success.unlinkResourceFromUser',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_LINK_RESOURCE_SUCCESS: {
    code: 'notifications.users.success.linkResourcesToUser',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_OFFBOARDING_SUCCESS: {
    code: 'notifications.users.success.offboardUser',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
