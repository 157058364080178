import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import FreezeFilled from '@palette/components/utils/Icons/FreezeFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { actions as PlanV3Actions } from '@palette/state/PlanV3';

import styles from './PlanV3PeriodFreezeButton.less';

const classNames = bindClassNames.bind(styles);

const PlanV3PeriodFreezeButton = ({ className, planId, periodId, isFrozen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleFreeze = () => {
    dispatch(PlanV3Actions.freezePeriod({ planId, periodId }));
  };

  const handleUnfreeze = () => {
    dispatch(PlanV3Actions.unfreezePeriod({ planId, periodId }));
  };

  const handleClickButton = () => {
    setTooltipVisible(false);
  };

  const freezeBtnNode = useMemo(() => {
    if (isFrozen) {
      return (
        <Popconfirm
          title={t('masterPlanPeriodFreezeButton.unfreezeFromPeriod.popconfirm')}
          onConfirm={handleUnfreeze}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
        >
          <Tooltip
            title={t('masterPlanPeriodFreezeButton.unfreezeFromPeriod.tooltip')}
            visible={tooltipVisible}
            onVisibleChange={(visible) => setTooltipVisible(visible)}
          >
            <Button
              className={styles.freezeBtn}
              type="linkSecondary"
              icon={<FreezeFilled />}
              onClick={handleClickButton}
            >
              {t('masterPlanPeriodFreezeButton.unfreezeFromPeriod')}
            </Button>
          </Tooltip>
        </Popconfirm>
      );
    }

    return (
      <Popconfirm
        title={t('masterPlanPeriodFreezeButton.freezeUntilPeriod.popconfirm')}
        onConfirm={handleFreeze}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Tooltip
          title={t('masterPlanPeriodFreezeButton.freezeUntilPeriod.tooltip')}
          visible={tooltipVisible}
          onVisibleChange={(visible) => setTooltipVisible(visible)}
        >
          <Button
            className={styles.freezeBtn}
            type="linkSecondary"
            icon={<FreezeFilled />}
            onClick={handleClickButton}
          >
            {t('masterPlanPeriodFreezeButton.freezeUntilPeriod')}
          </Button>
        </Tooltip>
      </Popconfirm>
    );
  }, [isFrozen, tooltipVisible]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {freezeBtnNode}
    </div>
  );
};

PlanV3PeriodFreezeButton.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
  periodId: PropTypes.string.isRequired,
  isFrozen: PropTypes.bool.isRequired,
};

PlanV3PeriodFreezeButton.defaultProps = {
  className: '',
};

export default PlanV3PeriodFreezeButton;
