import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions as ThreadsActions } from '@palette/state/Threads/slice';
import { manageError as manageThreadsError } from '@palette/state/Threads/errors';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { THREAD_EVENTS } from '@palette/constants/analytics';

import * as ThreadsService from '@palette/services/ThreadsService';
import * as ThreadModel from '@palette/models/Thread';

export function* getList() {
  const callResult = yield call(ThreadsService.list);

  if (callResult.ok) {
    const threads = ThreadModel.transformList(callResult.data);
    yield put(ThreadsActions.setList({ threads }));
  }

  yield put(ThreadsActions.getListCompleted());
}

export function* getOne({ payload = {} }) {
  const { mongoCollection, mongoId } = payload;

  const callResult = yield call(ThreadsService.getOne, { mongoCollection, mongoId });

  if (callResult.ok) {
    const thread = ThreadModel.transform(callResult.data);
    yield put(ThreadsActions.setOne({ mongoCollection, mongoId, thread, merge: true }));
  } else if (callResult.status === 404 && callResult.data?.type === 'palette:error:thread.not.found') {
    yield put(ThreadsActions.setOne({ mongoCollection, mongoId, thread: null }));
  } else {
    const error = manageThreadsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ThreadsActions.getOneCompleted());
}

export function* create({ payload = {} }) {
  const { mongoCollection, mongoId } = payload;

  const callResult = yield call(ThreadsService.create, { mongoCollection, mongoId });

  if (callResult.ok) {
    const thread = ThreadModel.transform(callResult.data);
    yield put(ThreadsActions.setOne({ mongoCollection, mongoId, thread }));
  } else {
    const error = manageThreadsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ThreadsActions.createCompleted());
}

export function* addComment({ payload = {} }) {
  const { threadId, comment, onSuccessCB } = payload;

  const callResult = yield call(ThreadsService.addComment, { threadId, comment });

  if (callResult.ok) {
    onSuccessCB();

    yield call(analyticsSendEvent, { payload: { event: THREAD_EVENTS.NEW_COMMENT, params: { threadId } } });
  } else {
    const error = manageThreadsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ThreadsActions.addCommentCompleted());
}

export function* setStatus({ payload = {} }) {
  const { threadId, status } = payload;

  const callResult = yield call(ThreadsService.setStatus, { threadId, status });

  if (callResult.ok) {
    yield call(analyticsSendEvent, { payload: { event: THREAD_EVENTS.SET_STATUS.replace('{{STATUS}}', status), params: { threadId } } });
  } else {
    const error = manageThreadsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ThreadsActions.setStatusCompleted());
}

export function* loop() {
  yield all([
    takeLatest(ThreadsActions.getList.type, getList),
    takeLatest(ThreadsActions.getOne.type, getOne),
    takeLatest(ThreadsActions.create.type, create),
    takeLatest(ThreadsActions.addComment.type, addComment),
    takeLatest(ThreadsActions.setStatus.type, setStatus),
  ]);
}

export function* clean() {
  yield put(ThreadsActions.resetToInitialState());
}
