import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'StatementPerMonthData';

export const propTypes = PropTypes.shape({
  currency: PropTypes.string,
  statementPerPeriod: PropTypes.arrayOf(PropTypes.shape({
    year: PropTypes.number,
    period: PropTypes.number,
    totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
    statementPeriodId: PropTypes.string,
  })),
});

const transformAmountPerPeriod = (amountPerPeriod) => {
  if (!amountPerPeriod) {
    return null;
  }

  return (
    {
      year: manageNumberAttribute(amountPerPeriod, 'year'),
      period: manageNumberAttribute(amountPerPeriod, 'period'),
      totalCommissionAmount: manageAmountAttribute(amountPerPeriod, 'totalCommissionAmount'),
      currency: manageStringAttribute(amountPerPeriod, 'currency'),
      statementPeriodId: manageStringAttribute(amountPerPeriod, 'statementPeriodId'),
    }
  );
};

export const transformList = (statementPerPeriods) => statementPerPeriods.map((statementPerPeriod) => transformAmountPerPeriod(statementPerPeriod));

export const transform = (statementPerPeriod) => {
  if (!statementPerPeriod) {
    return null;
  }

  return (
    {
      currency: manageStringAttribute(statementPerPeriod, 'currency', null),
      statementPerPeriod: transformList(manageArrayAttribute(statementPerPeriod, 'periods')),
    }
  );
};
