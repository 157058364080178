import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Table from '@palette/components/designSystem/Table/Table';
import QuotaTableBodyCell from '@palette/components/quota/QuotaTableBodyCell/QuotaTableBodyCell';
import QuotaTableUserTotalCell from '@palette/components/quota/QuotaTableUserTotalCell/QuotaTableUserTotalCell';
import QuotaTableTotalTitleCell from '@palette/components/quota/QuotaTableTotalTitleCell/QuotaTableTotalTitleCell';
import QuotaInfos from '@palette/components/quota/QuotaInfos/QuotaInfos';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Button from '@palette/components/designSystem/Button/Button';
import LockFilled from '@palette/components/utils/Icons/LockFilled';
import UnlockFilled from '@palette/components/utils/Icons/UnlockFilled';

import * as QuotaModel from '@palette/models/Quota';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getQuotaTableColumns, getQuotaTableUserRow } from '@palette/helpers/QuotaHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './UserQuotaEdition.less';

const classNames = bindClassNames.bind(styles);

const UserQuotaEdition = ({
  className,
  quota,
  quotaUserId,
  changeDefaultValues,
  year,
}) => {
  const profile = useProfile();
  const { t } = useTranslation();
  const [locked, setLocked] = useState(true);

  const columns = useMemo(
    () => ([
      ...getQuotaTableColumns(quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell),
    ]),
    [quota.frequency, quota.id, year, locked],
  );

  const data = useMemo(
    () => ([
      {
        quotaUserId,
        ...getQuotaTableUserRow(quota, quotaUserId, year),
      },
    ]),
    [quotaUserId, quota, year],
  );

  const handleLockButton = () => {
    const newLocked = !locked;
    setLocked(newLocked);
  };

  let lockButtonNode = null;
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE])) {
    lockButtonNode = (
      <Button
        className={styles.actionBtn}
        type={locked ? 'primaryBlue' : 'primary'}
        icon={locked ? (<LockFilled />) : (<UnlockFilled />)}
        onClick={handleLockButton}
      >
        {locked ? t('quota.editQuota') : t('quota.lockQuota')}
      </Button>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <div className={styles.quotaInfosWrapper}>
          <div className={styles.quotaName}>{quota.name}</div>
          <QuotaInfos className={styles.infos} quota={quota} displayUsersCount={false} />
        </div>
        <div className={styles.actions}>
          <Button
            className={styles.actionBtn}
            type="link"
            icon={<PenFilled />}
            onClick={() => changeDefaultValues(quota.id)}
          >
            {hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE]) ? t('quota.changeDefaultValues') : t('quota.showDefaultValues')}
          </Button>
          {lockButtonNode}
        </div>
      </div>
      <Table className={styles.table} columns={columns} data={data} />
    </div>
  );
};

UserQuotaEdition.propTypes = {
  quota: QuotaModel.propTypes.isRequired,
  quotaUserId: PropTypes.string.isRequired,
  changeDefaultValues: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  className: PropTypes.string,
};

UserQuotaEdition.defaultProps = {
  className: '',
};

export default UserQuotaEdition;
