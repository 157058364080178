import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { QUOTA_PERFORMANCE_METRIC_TYPES, QUOTA_PERFORMANCE_ROWS_PARAMS } from '@palette/constants/quotasPerformance';

import * as MetaUserModel from '@palette/models/MetaUser';

export const modelName = 'QuotaPerformancePlan';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  frequency: PropTypes.number,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  customBeginAt: PropTypes.string,
  quotaTrackingType: PropTypes.string,
  content: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array,
  }),
  summaries: PropTypes.shape({
    columns: PropTypes.array,
    rows: PropTypes.array,
  }),
});

export const manageColumnsRows = (quotaPerformancePlan, attrName) => {
  const result = {};
  const data = quotaPerformancePlan[attrName];

  Object.keys(data).forEach((entity) => {
    if (entity === QUOTA_PERFORMANCE_ROWS_PARAMS.ROWS) {
      result[entity] = data[entity].map((row) => {
        const finalRow = {};

        Object.keys(row).forEach((dataKey) => {
          switch (dataKey) {
            case QUOTA_PERFORMANCE_ROWS_PARAMS.USER:
              finalRow.user = MetaUserModel.transform(manageFreeShapeObjectAttribute(row, dataKey));
              break;
            case QUOTA_PERFORMANCE_ROWS_PARAMS.OPERATOR:
              finalRow.operator = manageStringAttribute(row, dataKey);
              break;
            default: {
              const metricType = manageStringAttribute(row[dataKey], 'metricType');
              let value = manageAmountAttribute(row[dataKey], 'value');

              if (metricType === QUOTA_PERFORMANCE_METRIC_TYPES.PERCENTAGE) {
                value = managePureFloatAttribute(row[dataKey], 'value');
              }

              finalRow[dataKey] = {
                metricType,
                value,
              };
            }
          }
        });

        return ({
          ...row,
          ...finalRow,
        });
      });
    } else {
      result[entity] = manageArrayAttribute(data, entity);
    }
  });

  return result;
};

export const managePeriodType = (quotaPerformancePlan) => (PERIOD_TYPES[manageStringAttribute(quotaPerformancePlan, 'planPeriodType')] || PERIOD_TYPES.MONTH);

export const transform = (quotaPerformancePlan) => {
  if (!quotaPerformancePlan) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(quotaPerformancePlan, 'planId'),
      name: manageStringAttribute(quotaPerformancePlan, 'planName'),
      frequency: manageNumberAttribute(quotaPerformancePlan, 'planFrequency', 1),
      periodType: managePeriodType(quotaPerformancePlan),
      customBeginAt: manageDateAttribute(quotaPerformancePlan, 'planCustomBeginAt'),
      quotaTrackingType: manageStringAttribute(quotaPerformancePlan, 'quotaTrackingType'),
      content: manageColumnsRows(quotaPerformancePlan, 'content'),
      summaries: manageColumnsRows(quotaPerformancePlan, 'summaries'),
    }
  );
};

export const transformList = (quotaPerformancePlans) => quotaPerformancePlans.map((quotaPerformancePlan) => transform(quotaPerformancePlan));
