import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';

import {
  getCreatePlanFlowStepIndex,
  getNextCreatePlanFlowStep,
  getPreviousCreatePlanFlowStep,
} from '@palette/helpers/MasterPlanHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { DRAFT_PLAN_ID_QS_KEY, DRAFT_PLAN_STEP_QS_KEY } from '@palette/constants/masterPlans';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CreatePlanFlowStepActions.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowStepActions = ({
  className,
  plan,
  currentStepValue,
  disabled,
  disabledBack,
  disabledNext,
  onNextStep,
  enableSkipButton,
  onSkipStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createPlanIsPending = useSelector(MasterPlansSelectors.createPlanIsPending);
  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);
  const addUsersToPlanIsPending = useSelector(MasterPlansSelectors.addUsersToPlanIsPending);
  const computePlanIsPending = useSelector(MasterPlansSelectors.computePlanIsPending);
  const removeUsersFromPlanIsPending = useSelector(MasterPlansSelectors.removeUsersFromPlanIsPending);
  const updatePlanUsersDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanUsersDefinitionIsPending);
  const updatePlanTrackingObjectDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanTrackingObjectDefinitionIsPending);
  const updatePlanRulesIsPending = useSelector(MasterPlansSelectors.updatePlanRulesIsPending);
  const updatePayoutRulesIsPending = useSelector(MasterPlansSelectors.updatePayoutRulesIsPending);

  const handleBackToPreviousStep = useCallback((previousCreatePlanFlowStep) => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [DRAFT_PLAN_STEP_QS_KEY]: previousCreatePlanFlowStep.value } }));
  }, [currentStepValue]);

  const backBtnNode = useMemo(() => {
    let previousCreatePlanFlowStep = getPreviousCreatePlanFlowStep(currentStepValue);
    while (
      previousCreatePlanFlowStep !== null
      && previousCreatePlanFlowStep.isDiscard(plan)
    ) {
      previousCreatePlanFlowStep = getPreviousCreatePlanFlowStep(previousCreatePlanFlowStep.value);
    }

    if (previousCreatePlanFlowStep === null) return null;

    return (
      <Button
        type="secondary"
        onClick={() => handleBackToPreviousStep(previousCreatePlanFlowStep)}
        disabled={disabled || disabledBack}
      >
        {t('createPlanFlowStep.back', { previousStep: t(previousCreatePlanFlowStep.label) })}
      </Button>
    );
  }, [
    currentStepValue,
    plan,
    handleBackToPreviousStep,
    disabled,
    disabledBack,
  ]);

  const handleNextStep = useCallback(() => {
    const nextCreatePlanFlowStep = getNextCreatePlanFlowStep(currentStepValue);

    const onSuccessCB = (newPlanId = null) => {
      const qsObject = { [DRAFT_PLAN_STEP_QS_KEY]: nextCreatePlanFlowStep.value };
      if (plan === null) {
        qsObject[DRAFT_PLAN_ID_QS_KEY] = newPlanId;
      }

      dispatch(NavigationActions.addQSToLocation({ qsObject }));
    };

    if (plan === null) {
      const goToNextStep = (newPlanId) => {
        if (newPlanId !== null) {
          dispatch(MasterPlansActions.updatePlan({
            planId: newPlanId,
            creationFlowStep: nextCreatePlanFlowStep.value,
            disableSuccessNotification: true,
            onSuccessAfterUpdatesCB: () => onSuccessCB(newPlanId),
          }));
        }
      };

      if (onNextStep !== null) {
        onNextStep((newPlanId = null) => goToNextStep(newPlanId));
      }

      return;
    }

    const nextStepIsDiscard = nextCreatePlanFlowStep.isDiscard(plan);

    const goToNextStep = (nextStepWasDiscard) => {
      let updateCreationFlowStep = true;
      const planStep = plan.creationFlowStep;
      if (planStep !== null) {
        const planStepIndex = getCreatePlanFlowStepIndex(planStep);
        const nextStepIndex = getCreatePlanFlowStepIndex(nextCreatePlanFlowStep.value);

        updateCreationFlowStep = planStepIndex < nextStepIndex;
      }
      // Force the update if nextStep was discard before plan updates
      updateCreationFlowStep = updateCreationFlowStep || nextStepWasDiscard;

      if (updateCreationFlowStep) {
        dispatch(MasterPlansActions.updatePlan({
          planId: plan.id,
          creationFlowStep: nextCreatePlanFlowStep.value,
          disableSuccessNotification: true,
          onSuccessAfterUpdatesCB: onSuccessCB,
        }));
      } else {
        onSuccessCB();
      }
    };

    if (onNextStep !== null) {
      onNextStep(() => goToNextStep(nextStepIsDiscard));
    } else {
      goToNextStep(nextStepIsDiscard);
    }
  }, [plan, currentStepValue, onNextStep]);

  const handleSkipStep = useCallback(() => {
    if (onSkipStep !== null) {
      onSkipStep(() => handleNextStep());
    }
  }, [onSkipStep, handleNextStep]);

  const handleFinish = useCallback(() => {
    const onUpdateSuccessCB = () => {
      redirectTo({ path: routePaths.v2.planDetails, params: { masterPlanId: plan.id } });
    };

    const onComputeSuccessCB = () => {
      dispatch(MasterPlansActions.updatePlan({
        planId: plan.id,
        creationFlowStep: null,
        onSuccessAfterUpdatesCB: onUpdateSuccessCB,
      }));
    };

    dispatch(MasterPlansActions.computePlan({ planId: plan.id, onSuccessCB: onComputeSuccessCB }));
  }, [plan]);

  const skipButtonNode = useMemo(() => {
    if (!enableSkipButton) return null;

    return (
      <Button
        type="secondary"
        onClick={handleSkipStep}
        disabled={disabled}
      >
        {t('createPlanFlowStep.skip')}
      </Button>
    );
  }, [enableSkipButton, disabled, handleSkipStep]);

  const nextBtnNode = useMemo(() => {
    const nextCreatePlanFlowStep = getNextCreatePlanFlowStep(currentStepValue);

    const loadingNextBtn = createPlanIsPending
      || updatePlanIsPending
      || addUsersToPlanIsPending
      || computePlanIsPending
      || removeUsersFromPlanIsPending
      || updatePlanUsersDefinitionIsPending
      || updatePlanTrackingObjectDefinitionIsPending
      || updatePlanRulesIsPending
      || updatePayoutRulesIsPending;
    const disabledNextBtn = disabled || disabledNext || loadingNextBtn;

    if (nextCreatePlanFlowStep === null) {
      return (
        <Button
          className={styles.nextBtn}
          onClick={handleFinish}
          disabled={disabledNextBtn}
          loading={loadingNextBtn}
        >
          {t('createPlanFlowStep.finish')}
        </Button>
      );
    }

    return (
      <Button
        className={styles.nextBtn}
        onClick={handleNextStep}
        disabled={disabledNextBtn}
        loading={loadingNextBtn}
      >
        {t('createPlanFlowStep.next')}
      </Button>
    );
  }, [
    currentStepValue,
    handleNextStep,
    disabled,
    disabledNext,
    createPlanIsPending,
    updatePlanIsPending,
    addUsersToPlanIsPending,
    computePlanIsPending,
    removeUsersFromPlanIsPending,
    updatePlanUsersDefinitionIsPending,
    updatePlanTrackingObjectDefinitionIsPending,
    updatePlanRulesIsPending,
    updatePayoutRulesIsPending,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.row}>
        {backBtnNode}
      </div>
      <div className={styles.row}>
        {skipButtonNode}
        {nextBtnNode}
      </div>
    </div>
  );
};

CreatePlanFlowStepActions.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
  currentStepValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  disabledBack: PropTypes.bool,
  disabledNext: PropTypes.bool,
  onNextStep: PropTypes.func,
  enableSkipButton: PropTypes.bool,
  onSkipStep: PropTypes.func,
};

CreatePlanFlowStepActions.defaultProps = {
  className: '',
  plan: null,
  disabled: false,
  disabledBack: false,
  disabledNext: false,
  onNextStep: null,
  enableSkipButton: false,
  onSkipStep: null,
};

export default CreatePlanFlowStepActions;
