import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import CommissionV3EditAmountModal from '@palette/components/commissionV3/CommissionV3EditAmountModal/CommissionV3EditAmountModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { addCurrencyToNode, addCurrency } from '@palette/helpers/CurrencyHelper';
import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getCommissionV3Amount } from '@palette/helpers/CommissionV3Helper';

import { RIGHTS } from '@palette/constants/profile';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import styles from './CommissionV3Value.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3Value = ({ className, commission }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [editCommissionAmountModalIsVisible, showEditCommissionAmountModal] = useState(false);

  const commissionAmount = getCommissionV3Amount(commission);

  const splittedFormattedValue = comaSeparatorFormatter(commissionAmount).split('.');
  const valuesNodes = [];

  valuesNodes.push((
    <div key="integerPart" className={styles.integerPart}>
      {splittedFormattedValue[0]}
    </div>
  ));

  if (splittedFormattedValue.length > 1) {
    valuesNodes.push((
      <div key="decimalPart" className={styles.decimalPart}>
        {`.${splittedFormattedValue[1]}`}
      </div>
    ));
  }

  const valueNode = (
    <div className={styles.value}>
      {addCurrencyToNode(valuesNodes, commission.currency)}
    </div>
  );

  let initialValueNode = null;

  if (commission.amountOverwritten !== null && (commission.amountOverwritten !== commission.amount)) {
    const initialAmount = comaSeparatorFormatter(commission.amount);

    initialValueNode = (
      <div className={styles.initialValue}>
        {addCurrency(initialAmount, commission.currency)}
      </div>
    );
  }

  let editButtonNode = (
    <Button
      className={styles.button}
      type="link"
      icon={<PenFilled width={16} height={16} />}
      onClick={() => showEditCommissionAmountModal(true)}
    />
  );

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMMISSIONS.MANAGE])) {
    editButtonNode = null;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.labelWrapper}>
        {t('commissionValue.commission')}
      </div>
      <div className={styles.valueWrapper}>
        {valueNode}
        {initialValueNode}
        {editButtonNode}
      </div>
      {
        editCommissionAmountModalIsVisible && (
          <CommissionV3EditAmountModal
            visible
            onClose={() => showEditCommissionAmountModal(false)}
            commission={commission}
          />
        )
      }
    </div>
  );
};

CommissionV3Value.propTypes = {
  className: PropTypes.string,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
};

CommissionV3Value.defaultProps = {
  className: '',
};

export default CommissionV3Value;
