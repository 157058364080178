import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import * as PlanRuleBracketModel from '@palette/models/PlanRuleBracket';

import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';
import { RULE_TYPES } from '@palette/constants/masterPlans';

import styles from './RuleBracketForm.less';

const classNames = bindClassNames.bind(styles);

const RuleBracketForm = ({
  className,
  ruleBracket,
  percentage,
  helperData,
  onRemove,
  onChange,
  position,
  isFirst,
  isLast,
  ruleType,
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => ({
    ...ruleBracket,
    id: ruleBracket.id || '',
    formula: ruleBracket.formula || '',
    from: ruleBracket.from,
    to: ruleBracket.to,
  }), [ruleBracket]);

  const [bracketValue, setBracketValue] = useState(initialValues);

  useEffect(() => {
    setBracketValue(initialValues);
  }, [initialValues]);

  const handleChange = useCallback(() => {
    if (!_isEqual(initialValues, bracketValue)) {
      onChange(bracketValue);
    }
  }, [initialValues, bracketValue]);

  useEffect(() => {
    handleChange();
  }, [bracketValue]);

  const handleBracketChange = useCallback((field, value) => {
    setBracketValue({
      ...bracketValue,
      [field]: value,
    });
  }, [bracketValue]);

  const deleteNode = useMemo(() => {
    const isDisabled = position === 1;

    return (
      <Tooltip title={!isDisabled ? t(`rulesCalculationForm.delete.${ruleType}`) : ''}>
        <Button
          className={classNames({
            actionDeleteLink: true,
            disabled: isDisabled,
          })}
          type="link"
          icon={<TrashFilled className={styles.actionIcon} />}
          onClick={!isDisabled ? onRemove : undefined}
          disabled={isDisabled}
        />
      </Tooltip>
    );
  }, [position, ruleType, onRemove]);

  const fromValue = useMemo(() => {
    if (isFirst && (ruleType === RULE_TYPES.PER_OBJECT || ruleType === RULE_TYPES.PER_TARGET)) return 0;

    return bracketValue.from;
  }, [isFirst, ruleType, bracketValue]);

  const toValue = useMemo(() => {
    let lastValue = isLast ? '' : bracketValue.to;

    if (lastValue === Infinity) {
      lastValue = 100;
    }

    return lastValue;
  }, [isLast, bracketValue]);

  const fromInputIsDisabled = useMemo(() => (
    ruleType === RULE_TYPES.PER_OBJECT
    || (isFirst && ruleType === RULE_TYPES.PER_TARGET)
  ), [ruleType, isFirst]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div
        className={classNames({
          inputArea: true,
          isLastArea: isLast,
        })}
      >
        <FormItem
          className={styles.formItem}
          label={t('rulesCalculationForm.from')}
        >
          <>
            <Input
              className={classNames({
                inputField: true,
                disabled: fromInputIsDisabled,
              })}
              addonAfter={percentage ? '%' : null}
              type="number"
              min={0}
              disabled={fromInputIsDisabled}
              value={fromValue}
              onChange={(value) => handleBracketChange('from', value)}
            />
            {
              (fromValue === '' || fromValue === null) && ruleType === RULE_TYPES.PER_TARGET && (
                <div className={styles.inputFieldError}>{t('rulesCalculationForm.fromTo.error.required')}</div>
              )
            }
          </>
        </FormItem>
        {ruleType === RULE_TYPES.PER_OBJECT && (
          <FormItem
            className={styles.formItem}
            label={t('rulesCalculationForm.to')}
          >
            <>
              <Input
                className={classNames({
                  inputField: true,
                  disabled: isLast,
                })}
                addonAfter={percentage ? '%' : null}
                type="number"
                min={0}
                disabled={isLast}
                value={toValue}
                onChange={(value) => handleBracketChange('to', value)}
              />
              {
                (toValue === '' || toValue === null) && !isLast && (
                  <div className={styles.inputFieldError}>{t('rulesCalculationForm.fromTo.error.required')}</div>
                )
              }
            </>
          </FormItem>
        )}
        <FormItem
          className={styles.formItem}
          label={t('rulesCalculationForm.formula')}
        >
          <>
            <FormulaInput
              className={styles.inputField}
              value={bracketValue.formula || ''}
              placeholder={t('rulesCalculationForm.value.placeholder')}
              type={FORMULA_INPUT_TYPES.FORMULA}
              helperData={helperData}
              onChange={(value) => handleBracketChange('formula', value)}
            />
            {
              bracketValue.formula === '' && (
                <div className={styles.inputFieldError}>{t('rulesCalculationForm.value.error.required')}</div>
              )
            }
          </>
        </FormItem>
        {deleteNode}
      </div>
    </div>
  );
};

RuleBracketForm.propTypes = {
  className: PropTypes.string,
  ruleBracket: PlanRuleBracketModel.propTypes,
  percentage: PropTypes.bool,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  position: PropTypes.number.isRequired,
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  ruleType: PropTypes.oneOf(Object.values(RULE_TYPES)),
};

RuleBracketForm.defaultProps = {
  className: '',
  ruleBracket: {},
  percentage: true,
  helperData: {},
  onRemove: () => {},
  onChange: () => {},
  ruleType: RULE_TYPES.PER_OBJECT,
};

export default RuleBracketForm;
