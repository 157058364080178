import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import QuotaListItem from '@palette/components/quota/QuotaListItem/QuotaListItem';
import Tabs from '@palette/components/designSystem/Tabs/Tabs';

import { QUOTA_LIST_TABS_IDS, QUOTA_LIST_TABS_QS_KEY } from '@palette/constants/tabs';

import { usePrevious } from '@palette/hooks/CommonHooks';
import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './QuotaList.less';

const QuotaList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { quotaId } = useParams();
  const [locationTab] = useKeyInSearch(QUOTA_LIST_TABS_QS_KEY);
  const previousLocationTab = usePrevious(locationTab);

  const listIsPending = useSelector(QuotasSelectors.getListIsPending);
  const quotasList = useSelector(QuotasSelectors.getQuotasList);

  useEffect(() => {
    dispatch(QuotasActions.getListAndCurrent({ quotaId }));
  }, []);

  useEffect(() => {
    if (!previousLocationTab && quotaId && locationTab === QUOTA_LIST_TABS_IDS.ARCHIVED) {
      dispatch(QuotasActions.getListAndCurrent({ quotaId, archived: true }));
    }
  }, [locationTab, quotaId]);

  const handleFilterChange = useCallback((key) => {
    dispatch(QuotasActions.getListAndCurrent({ quotaId: null, archived: Object.values(QUOTA_LIST_TABS_IDS)[key] === QUOTA_LIST_TABS_IDS.ARCHIVED }));
  });

  const listContentNode = useMemo(() => {
    let content = <DefaultEmptyState description={t('quotaList.empty.description')} />;

    if (listIsPending) {
      content = <Loader />;
    } else if (quotasList.length > 0) {
      content = quotasList.map((quota) => (
        <QuotaListItem key={quota.id} className={styles.item} quota={quota} />
      ));
    }

    return content;
  }, [listIsPending]);

  const getTabPropsByType = (tabType) => ({
    title: t(`quotaList.filters.${tabType}`),
    content: null,
    defaultActive: tabType === QUOTA_LIST_TABS_IDS.ACTIVE,
    tabKey: tabType,
  });

  const tabs = useMemo(() => Object.values(QUOTA_LIST_TABS_IDS).map((tabType) => getTabPropsByType(tabType)), [getTabPropsByType]);

  const tabsNode = useMemo(() => (
    <Tabs
      className={styles.tabs}
      tabs={tabs}
      qsTabKey={QUOTA_LIST_TABS_QS_KEY}
      onChange={handleFilterChange}
    />
  ), [tabs]);

  return (
    <div className={styles.wrapper}>
      {tabsNode}
      <div className={styles.list}>
        {listContentNode}
      </div>
    </div>
  );
};

export default QuotaList;
