import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import RoleBreadcrumb from '@palette/components/role/RoleBreadcrumb/RoleBreadcrumb';
import RoleDetailsPageContent from '@palette/components/role/RoleDetailsPageContent/RoleDetailsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useRoleInParams } from '@palette/hooks/RoleHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './RoleDetailsPage.less';

const RoleDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { role } = useRoleInParams();

  if (!hasAllRights(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.ROLES.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.rolesTitle')} />
      <RoleBreadcrumb className={styles.breadcrumb} role={role} />
      <RoleDetailsPageContent />
    </div>
  );
};

export default RoleDetailsPage;
