import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Alert as AntDAlert } from 'antd';

import CloseSimpleLine from '@palette/components/utils/Icons/CloseSimpleLine';

import { ALERT_ICONS, ALERT_TYPES } from '@palette/constants/alert';

import styles from './Alert.less';

const classNames = bindClassNames.bind(styles);

const Alert = ({
  className,
  type,
  description,
  message,
  isClosable,
  onClose,
  ...otherProps
}) => {
  const AlertIcon = ALERT_ICONS[type];

  const handleClose = useCallback(() => {
    if (isClosable && onClose) onClose();
  }, [isClosable, onClose]);

  const messageNode = useMemo(() => (
    <>
      {isClosable && (
        <CloseSimpleLine
          className={styles.crossIcon}
          width={12}
          height={12}
          onClick={handleClose}
        />
      )}
      {message}
    </>
  ), [message, isClosable]);

  return (
    <AntDAlert
      className={classNames({
        wrapper: true,
        closable: isClosable,
        [className]: className !== '',
      })}
      type={type}
      message={messageNode}
      description={description}
      showIcon
      icon={<AlertIcon className={styles.icon} />}
      {...otherProps}
    />
  );
};

Alert.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ALERT_TYPES)),
  message: PropTypes.any.isRequired,
  description: PropTypes.node,
  isClosable: PropTypes.bool,
  onClose: PropTypes.func,
};

Alert.defaultProps = {
  className: '',
  type: 'info',
  description: null,
  isClosable: false,
  onClose: undefined,
};

export default Alert;
