import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import Table from '@palette/components/designSystem/Table/Table';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Link from '@palette/components/designSystem/Link/Link';

import { MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS_I18N } from '@palette/constants/user';

import routePaths from '@palette/config/routePaths';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './MergeUserResults.less';

const classNames = bindClassNames.bind(styles);

const MergeUserResults = ({
  className,
  firstUser,
  secondUser,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getMergeConflictsIsPending = useSelector(UsersSelectors.getMergeConflictsIsPending);
  const mergeUserConflits = useSelector(UsersSelectors.getMergeUserConflits);

  useEffect(() => {
    if (firstUser === null || secondUser === null) return;

    dispatch(UsersActions.getMergeConflicts({ firstUserId: firstUser.id, secondUserId: secondUser.id }));
  }, [firstUser, secondUser]);

  const userDetailsDiffColumns = useMemo(() => ([
    {
      id: 'field',
      accessor: ({ field }) => t(MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS_I18N[field]),
      minWidth: 200,
      width: '20%',
    },
    {
      id: 'source',
      Header: t('mergeUserResults.userDetailsDiff.table.headers.user', { userName: 'A' }),
      accessor: 'source',
      minWidth: 300,
      width: '30%',
    },
    {
      id: 'destination',
      Header: t('mergeUserResults.userDetailsDiff.table.headers.user', { userName: 'B' }),
      accessor: 'destination',
      minWidth: 300,
      width: '30%',
    },
  ]), []);

  const userDetailsDiffNode = useMemo(() => {
    if (mergeUserConflits === null) return null;

    return (
      <div className={styles.userDetailsDiffTable}>
        <Table columns={userDetailsDiffColumns} data={mergeUserConflits.userDetailsDiff} stickyHeader />
        {
          mergeUserConflits.userDetailsDiff.length === 0 && (
            <DefaultEmptyState className={styles.emptyState} />
          )
        }
      </div>
    );
  }, [userDetailsDiffColumns, mergeUserConflits]);

  const plansInCommonNode = useMemo(() => {
    if (mergeUserConflits === null) return null;

    if (mergeUserConflits.plansInCommon.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return mergeUserConflits.plansInCommon.map((planInCommon) => (
      <Link className={styles.link} key={planInCommon.id} path={routePaths.v2.planDetails} params={{ masterPlanId: planInCommon.id }} displayIcon>
        {planInCommon.name}
      </Link>
    ));
  }, [mergeUserConflits]);

  const quotasInCommonNode = useMemo(() => {
    if (mergeUserConflits === null) return null;

    if (mergeUserConflits.quotasInCommon.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return mergeUserConflits.quotasInCommon.map((quotaInCommon) => (
      <Link className={styles.link} key={quotaInCommon.id} path={routePaths.v2.quotaDetails} params={{ quotaId: quotaInCommon.id }} displayIcon>
        {quotaInCommon.name}
      </Link>
    ));
  }, [mergeUserConflits]);

  const contentNode = useMemo(() => {
    if (getMergeConflictsIsPending) {
      return (
        <Loader />
      );
    }

    if (mergeUserConflits === null) return null;

    return (
      <>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {t('mergeUserResults.sections.userDetailsDiff')}
          </div>
          <div className={styles.sectionContent}>
            {userDetailsDiffNode}
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {t('mergeUserResults.sections.plansInCommon')}
          </div>
          <div className={styles.sectionContent}>
            {plansInCommonNode}
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {t('mergeUserResults.sections.quotasInCommon')}
          </div>
          <div className={styles.sectionContent}>
            {quotasInCommonNode}
          </div>
        </div>
      </>
    );
  }, [
    getMergeConflictsIsPending,
    mergeUserConflits,
    userDetailsDiffNode,
    plansInCommonNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MergeUserResults.propTypes = {
  className: PropTypes.string,
  firstUser: MetaUserModel.propTypes,
  secondUser: MetaUserModel.propTypes,
};

MergeUserResults.defaultProps = {
  className: '',
  firstUser: null,
  secondUser: null,
};

export default MergeUserResults;
