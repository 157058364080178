import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _unionBy from 'lodash/unionBy';
import _differenceBy from 'lodash/differenceBy';
import _intersection from 'lodash/intersection';
import _find from 'lodash/find';

import Loader from '@palette/components/utils/Loader/Loader';
import Table from '@palette/components/designSystem/Table/Table';
import Input from '@palette/components/designSystem/Input/Input';
import Checkbox from '@palette/components/designSystem/Checkbox/Checkbox';
import NoUserTypeConnectorsAlert from '@palette/components/connector/NoUserTypeConnectorsAlert/NoUserTypeConnectorsAlert';
import UserConnectorsSelect from '@palette/components/user/UserConnectorsSelect/UserConnectorsSelect';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useCompanyMetaUsers } from '@palette/hooks/UserHooks';

import { excludeMetaUsersResourceObjects } from '@palette/helpers/UserHelper';
import { getColumnValue, getResourceColumns } from '@palette/helpers/ConnectorHelper';

import { selectors as UsersSelectors } from '@palette/state/Users';
import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './UserResourcesSelectionPanel.less';

const classNames = bindClassNames.bind(styles);

const UserResourcesSelectionPanel = ({
  className,
  onChange,
  restrictToConnectorIdAndType,
  disableReloadUsers,
  preSelectedResources,
  lockPreselectedResources,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedConnectorId, setSelectedConnectorId] = useState(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.connectorId : '');
  const [selectedConnectorType, setSelectedConnectorType] = useState(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.type : '');
  const [searchedUser, setSearchedUser] = useState('');
  const [selectedResources, setSelectedResources] = useState([]);

  useEffect(() => {
    setSelectedConnectorId(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.connectorId : '');
    setSelectedConnectorType(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.type : '');
  }, [restrictToConnectorIdAndType]);

  useEffect(() => {
    setSelectedResources([...preSelectedResources]);
  }, [preSelectedResources.length]);

  const usersListIsPending = useSelector(UsersSelectors.getListIsPending);
  const companyMetaUsersList = useCompanyMetaUsers(disableReloadUsers);

  const getAllUsersResourcesObjectsIsPending = useSelector(ConnectorsSelectors.getAllUsersResourcesObjectsIsPending);

  const connectorsList = useSelector(ConnectorsSelectors.getConnectorsWithUsersResources);
  const getSelectedConnectorResourceObjectsIsPending = useSelector(ConnectorsSelectors.getResourceObjectsIsPending);

  const selectedConnector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: selectedConnectorId }));
  const selectedConnectorResourceObjects = useSelector((state) => ConnectorsSelectors.getResourceObjectsByConnectorAndType(state, { connectorId: selectedConnectorId, type: selectedConnectorType }));

  useEffect(() => {
    dispatch(ConnectorsActions.getAllUsersResourcesObjects());
  }, []);

  const selectedConnectorResourceColumns = useMemo(() => {
    if (selectedConnector === null) return [];

    return getResourceColumns(
      selectedConnector,
      selectedConnectorType,
      (column) => column.displayInResources,
    );
  }, [selectedConnector]);

  const data = useMemo(() => {
    let listedUsers = excludeMetaUsersResourceObjects(selectedConnectorResourceObjects, companyMetaUsersList);

    if (searchedUser !== '') {
      const searchedUserLowerCase = searchedUser.toLowerCase();
      listedUsers = listedUsers.filter((resourceObject) => (
        Object.values(resourceObject.data)
          .some(
            (resourceObjectDataValue) => (resourceObjectDataValue?.toString().toLowerCase().indexOf(searchedUserLowerCase) > -1),
          )
      ));
    }

    return listedUsers;
  },
  [
    companyMetaUsersList,
    selectedConnectorResourceObjects,
    searchedUser,
  ]);

  const changeSelectedResources = useCallback((newSelectedResources) => {
    setSelectedResources(newSelectedResources);
    if (onChange !== null) {
      let changedSelectedResources = newSelectedResources;
      if (lockPreselectedResources) {
        changedSelectedResources = _differenceBy(newSelectedResources, preSelectedResources, (resourceObject) => resourceObject.id);
      }
      onChange(changedSelectedResources);
    }
  }, [onChange, preSelectedResources, lockPreselectedResources]);

  const handleAllResourcesCheckboxChange = useCallback((e) => {
    let newSelectedResources;

    if (e.target.checked) {
      newSelectedResources = _unionBy(selectedResources, data, (resourceObject) => resourceObject.id);
    } else {
      newSelectedResources = _differenceBy(selectedResources, data, (resourceObject) => resourceObject.id);
      if (lockPreselectedResources) {
        newSelectedResources = _unionBy(newSelectedResources, preSelectedResources, (resourceObject) => resourceObject.id);
      }
    }

    changeSelectedResources(newSelectedResources);
  }, [selectedResources, data, changeSelectedResources, preSelectedResources, lockPreselectedResources]);

  const handleUserCheckboxChange = useCallback((e, resourceObject) => {
    let newSelectedResources = [...selectedResources];

    if (e.target.checked) {
      newSelectedResources.push(resourceObject);
    } else {
      newSelectedResources = newSelectedResources.filter((selectedResource) => selectedResource.id !== resourceObject.id);
    }

    changeSelectedResources(newSelectedResources);
  }, [selectedResources, changeSelectedResources]);

  const columns = useMemo(
    () => {
      const dataResourceObjectsIds = data.map((resourceObject) => resourceObject.id);
      const selectedResourcesIds = selectedResources.map((resourceObject) => resourceObject.id);
      const preSelectedResourcesIds = preSelectedResources.map((resourceObject) => resourceObject.id);
      const intersection = _intersection(dataResourceObjectsIds, selectedResourcesIds);
      let headerCheckboxProps = { checked: false, indeterminate: false };
      if (dataResourceObjectsIds.length > 0 && intersection.length > 0) {
        if (intersection.length === dataResourceObjectsIds.length) {
          headerCheckboxProps = { checked: true, indeterminate: false };
        } else {
          headerCheckboxProps = { checked: false, indeterminate: true };
        }
      }

      const cols = [
        {
          id: 'selector',
          Header: (<Checkbox onChange={handleAllResourcesCheckboxChange} {...headerCheckboxProps} />),
          accessor: (resourceObject) => resourceObject,
          minWidth: 48,
          width: 48,
          /* eslint-disable react/prop-types */
          Cell: ({ value: resourceObject }) => (
            <Checkbox
              checked={selectedResourcesIds.includes(resourceObject.id)}
              onChange={(e) => handleUserCheckboxChange(e, resourceObject)}
              disabled={lockPreselectedResources ? preSelectedResourcesIds.includes(resourceObject.id) : false}
            />
          ),
          /* eslint-enable react/prop-types */
        },
      ];

      selectedConnectorResourceColumns.forEach((resourceColumn) => {
        cols.push({
          id: resourceColumn.name,
          Header: resourceColumn.name,
          accessor: (resourceObject) => getColumnValue(resourceObject.data, resourceColumn),
          minWidth: 150,
        });
      });

      return cols;
    },
    [
      data,
      selectedResources,
      handleAllResourcesCheckboxChange,
      handleUserCheckboxChange,
      selectedConnectorResourceColumns,
      preSelectedResources,
      lockPreselectedResources,
    ],
  );

  if (getAllUsersResourcesObjectsIsPending || usersListIsPending) {
    return (
      <Loader />
    );
  }

  if (connectorsList.length === 0) {
    return (
      <div className={className}>
        <NoUserTypeConnectorsAlert className={styles.warning} />
      </div>
    );
  }

  const resetFilters = () => {
    setSearchedUser('');
  };

  const handleSelectConnector = (value, connectorId, type) => {
    resetFilters();
    setSelectedConnectorId(connectorId);
    setSelectedConnectorType(type);
  };

  const handleSearchEnterPressed = () => {
    if (searchedUser !== '' && data.length === 1) {
      const resourceObject = data[0];

      const preSelectedResourceFound = _find(preSelectedResources, (preSelectedResource) => preSelectedResource.id === resourceObject.id);
      if (preSelectedResourceFound) return;

      const alreadySelected = _find(selectedResources, (selectedResource) => selectedResource.id === resourceObject.id);

      let newSelectedResources = [...selectedResources];
      if (!alreadySelected) {
        newSelectedResources.push(resourceObject);
      } else {
        newSelectedResources = newSelectedResources.filter((selectedResource) => selectedResource.id === resourceObject.id);
      }

      changeSelectedResources(newSelectedResources);
    }
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        restrictToConnectorIdAndType === null && (
          <UserConnectorsSelect
            className={styles.connectorsSelect}
            selectFirstOptionByDefault
            hideIfSingleChoice
            onSelect={handleSelectConnector}
          />
        )
      }
      <div className={styles.filters}>
        <Input
          type="search"
          placeholder={t('userResourcesSelectionPanel.userSearch.placeholder')}
          disabled={getSelectedConnectorResourceObjectsIsPending}
          onChange={setSearchedUser}
          value={searchedUser}
          onPressEnter={handleSearchEnterPressed}
        />
      </div>
      <div className={styles.table}>
        <Table columns={columns} data={data} stickyHeader fitInContainer centerInContainer nbOfFixedColumns={1} />
        {
          data.length === 0 && (
            <DefaultEmptyState className={styles.emptyState} />
          )
        }
      </div>
    </div>
  );
};

UserResourcesSelectionPanel.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  disableReloadUsers: PropTypes.bool,
  preSelectedResources: PropTypes.arrayOf(ResourceObjectModel.propTypes),
  lockPreselectedResources: PropTypes.bool,
};

UserResourcesSelectionPanel.defaultProps = {
  className: '',
  onChange: null,
  restrictToConnectorIdAndType: null,
  disableReloadUsers: false,
  preSelectedResources: [],
  lockPreselectedResources: true,
};

export default UserResourcesSelectionPanel;
