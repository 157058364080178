import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import MasterPlanPeriodsFilter from '@palette/components/masterPlan/MasterPlanPeriodsFilter/MasterPlanPeriodsFilter';
import MasterPlanPeriodsList from '@palette/components/masterPlan/MasterPlanPeriodsList/MasterPlanPeriodsList';
import Loader from '@palette/components/utils/Loader/Loader';
import MasterPlanHighlightZone from '@palette/components/masterPlan/MasterPlanHighlightZone/MasterPlanHighlightZone';

import { useMasterPlanInParams, useMasterPlanPeriodsFilters } from '@palette/hooks/MasterPlanHooks';

import { PERIODS_FILTER_TYPE } from '@palette/constants/masterPlans';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanDetailsPageContent = ({ className }) => {
  const dispatch = useDispatch();

  const { masterPlanId, plan, isPending } = useMasterPlanInParams();

  const [periodsFilterType, customFilterPeriods] = useMasterPlanPeriodsFilters(plan, isPending);

  const planPeriodsIsPending = useSelector((state) => MasterPlansSelectors.getPlanPeriodsIsPending(state));

  const periods = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriods(state, { masterPlanId }));

  useEffect(() => {
    if (masterPlanId) {
      dispatch(MasterPlansActions.getById({ planId: masterPlanId }));
    }
  }, [masterPlanId]);

  useEffect(() => {
    if (
      plan !== null
      && !isPending
      && periodsFilterType !== null
      && (
        periodsFilterType !== PERIODS_FILTER_TYPE.CUSTOM || customFilterPeriods !== null
      )
    ) {
      dispatch(MasterPlansActions.getPlanPeriods({
        planId: plan.id,
        periodsFilterType,
        customPeriods: customFilterPeriods,
      }));
    }
  }, [plan, isPending, periodsFilterType, customFilterPeriods]);

  const filtersNode = useMemo(() => {
    if (plan === null) return null;

    return (
      <MasterPlanPeriodsFilter
        plan={plan}
        disabled={planPeriodsIsPending}
      />
    );
  }, [plan, planPeriodsIsPending]);

  const highlightZoneNode = useMemo(() => {
    if (plan === null) return null;

    return (
      <MasterPlanHighlightZone className={styles.highlightZone} plan={plan} />
    );
  }, [plan]);

  const listNode = useMemo(() => {
    if (plan === null || planPeriodsIsPending) return null;

    return (
      <MasterPlanPeriodsList className={styles.periodsList} plan={plan} periods={periods} />
    );
  }, [plan, planPeriodsIsPending, periods]);

  const contentNode = useMemo(() => {
    if (isPending) {
      return (
        <Loader />
      );
    }

    if (plan === null) return null;

    return (
      <>
        {filtersNode}
        {highlightZoneNode}
        {listNode}
      </>
    );
  }, [
    isPending,
    plan,
    filtersNode,
    highlightZoneNode,
    listNode,
  ]);

  if (plan === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlanDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

MasterPlanDetailsPageContent.defaultProps = {
  className: '',
};

export default MasterPlanDetailsPageContent;
