import React, { useMemo } from 'react';

import TermsAndConditionsModal from '@palette/components/termsAndConditions/TermsAndConditionsModal/TermsAndConditionsModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { PALETTE_SALESFORCE_URL } from '@palette/constants/global';

const TermsAndConditions = () => {
  const profile = useProfile();

  const shouldDisplayModal = useMemo(() => (
    window.location.host !== PALETTE_SALESFORCE_URL && !profile.userData.account.hasAcknowledgeLastTerms
  ), [profile]);

  if (!shouldDisplayModal) return null;

  return (
    <TermsAndConditionsModal visible />
  );
};

export default TermsAndConditions;
