import _keyBy from 'lodash/keyBy';

import { floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { convertAnyValueToMoment, getMoment } from '@palette/helpers/MomentHelper';

import { USER_VARIABLE_TYPES } from '@palette/constants/user';

const convertStringValue = (value, toType) => {
  if (value == null || typeof (value) !== 'string') return null;
  switch (toType) {
    case USER_VARIABLE_TYPES.STRING: {
      return value;
    }
    case USER_VARIABLE_TYPES.NUMBER: {
      return !Number.isNaN(Number(value)) ? floatToFixedNumber(value) : null;
    }
    case USER_VARIABLE_TYPES.DATE: {
      return convertAnyValueToMoment(value, true);
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return !!value;
    }
    case USER_VARIABLE_TYPES.ARRAY: {
      return [value];
    }
    default: {
      return null;
    }
  }
};

const convertNumberValue = (value, toType) => {
  if (value == null || Number.isNaN(value)) return null;
  switch (toType) {
    case USER_VARIABLE_TYPES.STRING: {
      return Number(value).toString();
    }
    case USER_VARIABLE_TYPES.NUMBER: {
      return value;
    }
    case USER_VARIABLE_TYPES.DATE: {
      return convertAnyValueToMoment(value, true);
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return !!value;
    }
    case USER_VARIABLE_TYPES.ARRAY: {
      return [Number(value).toString()];
    }
    default: {
      return null;
    }
  }
};

const convertDateValue = (value, toType) => {
  const moment = getMoment();
  if (value == null || !moment.utc(value).isValid()) return null;
  switch (toType) {
    case USER_VARIABLE_TYPES.STRING: {
      return moment.utc(value).format();
    }
    case USER_VARIABLE_TYPES.NUMBER: {
      return moment.utc(value).valueOf();
    }
    case USER_VARIABLE_TYPES.DATE: {
      return value;
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return !!value;
    }
    case USER_VARIABLE_TYPES.ARRAY: {
      return [moment.utc(value).format()];
    }
    default: {
      return null;
    }
  }
};

const convertBooleanValue = (value, toType) => {
  if (value == null || typeof (value) !== 'boolean') return null;
  switch (toType) {
    case USER_VARIABLE_TYPES.STRING: {
      return Boolean(value).toString();
    }
    case USER_VARIABLE_TYPES.NUMBER: {
      return Number(value);
    }
    case USER_VARIABLE_TYPES.DATE: {
      const moment = getMoment();
      return value ? moment.utc() : null;
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return value;
    }
    case USER_VARIABLE_TYPES.ARRAY: {
      return [Boolean(value).toString()];
    }
    default: {
      return null;
    }
  }
};

const convertArrayValue = (value, toType) => {
  if (value == null || !Array.isArray(value) || value.length === 0) return null;
  const firstItem = value[0];

  return convertStringValue(firstItem, toType);
};

export const convertVariableValue = (value, fromType, toType) => {
  switch (fromType) {
    case USER_VARIABLE_TYPES.STRING: {
      return convertStringValue(value, toType);
    }
    case USER_VARIABLE_TYPES.NUMBER: {
      return convertNumberValue(value, toType);
    }
    case USER_VARIABLE_TYPES.DATE: {
      return convertDateValue(value, toType);
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return convertBooleanValue(value, toType);
    }
    case USER_VARIABLE_TYPES.ARRAY: {
      return convertArrayValue(value, toType);
    }
    default: {
      return undefined;
    }
  }
};

export const computeUserVariableValue = (variable, overridesForVariable, computeForDate) => {
  const moment = getMoment();

  const computeForDateMoment = moment.utc(computeForDate);

  let finalValue = variable.defaultValue;

  if (overridesForVariable.length > 0) {
    overridesForVariable.forEach(({ date, value }) => {
      const dateMoment = moment.utc(date);
      if (computeForDateMoment.isSameOrAfter(dateMoment)) {
        finalValue = value;
      }
    });
  }

  return finalValue;
};

export const combineUsersAndUsersVariables = (companyMetaUsers, usersVariables, computeForDate) => {
  const overridesByUserId = _keyBy(usersVariables.overrides, 'userId');

  return companyMetaUsers.map((metaUser) => {
    const userWithVariables = {
      user: metaUser,
    };

    const overridesForUser = overridesByUserId[metaUser.id]?.overrides ?? {};

    usersVariables.variables.forEach((variable) => {
      const overridesForVariable = overridesForUser[variable.id] ?? [];

      userWithVariables[variable.id] = {
        value: computeUserVariableValue(variable, overridesForVariable, computeForDate),
        overrides: overridesForVariable,
      };
    });

    return userWithVariables;
  });
};

export const getColumnWidthFromType = (variableType) => {
  switch (variableType) {
    case USER_VARIABLE_TYPES.DATE: {
      return 250;
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return 100;
    }
    default: {
      return 200;
    }
  }
};
