import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import _intersectionBy from 'lodash/intersectionBy';

import QuotaUserHeader from '@palette/components/quota/QuotaUserHeader/QuotaUserHeader';
import QuotaUserCell from '@palette/components/quota/QuotaUserCell/QuotaUserCell';
import QuotaTableBodyCell from '@palette/components/quota/QuotaTableBodyCell/QuotaTableBodyCell';
import QuotaTableUserTotalCell from '@palette/components/quota/QuotaTableUserTotalCell/QuotaTableUserTotalCell';
import QuotaTableTotalTitleCell from '@palette/components/quota/QuotaTableTotalTitleCell/QuotaTableTotalTitleCell';
import Table from '@palette/components/designSystem/Table/Table';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { getQuotaData, getQuotaTableColumns } from '@palette/helpers/QuotaHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { QUOTA_TARGET_STRATEGIES, SCOPES } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as QuotaModel from '@palette/models/Quota';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsQuotaTable.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsQuotaTable = ({ className, plan, quota }) => {
  const dispatch = useDispatch();
  const moment = getMoment();

  const [year, setYear] = useState(moment().year());

  useEffect(() => {
    dispatch(MasterPlansActions.getPlanUsersAndManagers({ planId: plan.id }));
  }, []);

  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: plan.id }));

  const columns = useMemo(
    () => {
      if (quota === null) return [];

      return ([
        {
          Header: (<QuotaUserHeader disableAddBtn />),
          accessor: 'quotaUserId',
          minWidth: 200,
          // eslint-disable-next-line react/prop-types
          Cell: ({ value, ...otherCellProps }) => (<QuotaUserCell quotaUserId={value} quotaId={quota.id} year={year} {...otherCellProps} />),
          disableBodyCellComponent: true,
          disableHeaderCellComponent: true,
        },
        ...getQuotaTableColumns(
          quota,
          QuotaTableBodyCell,
          year,
          false,
          QuotaTableUserTotalCell,
          QuotaTableTotalTitleCell,
        ),
      ]);
    },
    [quota, plan, year],
  );

  const data = useMemo(() => {
    if (quota === null) return [];

    let planUsers = planUsersAndManagers.users.map((planUserOrManager) => planUserOrManager.user);

    if (plan.scope === SCOPES.MANAGER && plan.quotaTargetStrategy === QUOTA_TARGET_STRATEGIES.MATCH) {
      planUsers = planUsersAndManagers.managers.map((planUserOrManager) => planUserOrManager.user);
    }

    let displayedUsers = _intersectionBy(
      quota.users.map((quotaUser) => quotaUser.user),
      planUsers,
      (user) => user.id,
    );

    displayedUsers = displayedUsers.filter((user) => {
      const userLeaveDate = user.leaveDate;

      if (userLeaveDate !== null && moment.utc(userLeaveDate).year() < year) {
        return false;
      }

      const userJoinDate = user.joinDate;

      if (userJoinDate !== null && moment.utc(userJoinDate).year() > year) {
        return false;
      }

      return true;
    });

    return getQuotaData(quota, displayedUsers, year);
  }, [quota, plan, planUsersAndManagers, year]);

  const handleYearChange = (date, dateString) => {
    setYear(parseInt(dateString, 10));
  };

  const handleDisabledDate = useCallback((d) => (
    !d
    || d.isSameOrBefore('1970-01-01')
    || (
      quota !== null
      && quota.periodType === PERIOD_TYPES.DAY
      && d.isSameOrBefore(moment(quota.beginAt))
    )
  ), [quota]);

  const yearPickerNode = useMemo(() => {
    if (quota.periodType === PERIOD_TYPES.CUSTOM) return null;

    return (
      <DatePicker
        className={styles.yearPicker}
        picker="year"
        value={moment().year(year)}
        allowClear={false}
        disabledDate={handleDisabledDate}
        onChange={handleYearChange}
      />
    );
  }, [quota, handleDisabledDate, handleYearChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {yearPickerNode}
      <Table
        className={styles.table}
        columns={columns}
        data={data}
        nbOfFixedColumns={1}
        stickyHeader
        highlightRowOnHover
      />
    </div>
  );
};

MasterPlanSettingsQuotaTable.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  quota: QuotaModel.propTypes.isRequired,
};

MasterPlanSettingsQuotaTable.defaultProps = {
  className: '',
};

export default MasterPlanSettingsQuotaTable;
