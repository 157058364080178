import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { PLAN_PERIODICITY_TYPE, PLAN_STATUS_TYPE, PLAN_VARIABLE_SOURCE_TYPE } from '@palette/constants/planV3';

import { managePeriodicityType, manageStatusType } from '@palette/models/PlanV3List';
import * as PlanV3CellPreviewModel from '@palette/models/PlanV3CellPreview';

export const modelName = 'PlanV3Detail';

export const propTypes = PropTypes.shape({
  planId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(PLAN_STATUS_TYPE)),
  frequency: PropTypes.oneOf(Object.keys(PLAN_PERIODICITY_TYPE)),
  startPeriod: PropTypes.string,
  endPeriod: PropTypes.string,
  variables: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    source: PropTypes.oneOf(Object.keys(PLAN_VARIABLE_SOURCE_TYPE)),
  })),
  cells: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    preview: PlanV3CellPreviewModel.propTypes, // TODO: check details
    // TODO: check error details
    error: PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.any,
    }),
    language: PropTypes.string,
  })),
  periods: PropTypes.arrayOf(PropTypes.shape({
    periodId: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    isFrozen: PropTypes.bool,
  })),
});

export const transform = (details) => {
  if (!details) {
    return null;
  }

  const { status } = details;
  const { periodicity, start, end } = manageFreeShapeObjectAttribute(details, 'requirements');
  const { variables } = manageFreeShapeObjectAttribute(details, 'context');

  const rawCells = manageArrayAttribute(details, 'codeCells');
  const cells = rawCells.map(({ preview, ...cell }) => ({
    ...cell,
    preview: PlanV3CellPreviewModel.transform(preview),
  }));

  return (
    {
      planId: manageStringAttribute(details, 'id'),
      name: manageStringAttribute(details, 'name'),
      description: manageStringAttribute(details, 'description'),
      status: manageStatusType({ status }),
      frequency: managePeriodicityType({ periodicity }),
      startPeriod: manageDateAttribute({ start }, 'start'),
      endPeriod: manageDateAttribute({ end }, 'end'),
      variables: manageArrayAttribute({ variables }, 'variables'),
      cells,
      periods: manageArrayAttribute(details, 'periods'),
    }
  );
};
