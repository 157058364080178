import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './QuotaOverwriteIndicator.less';

const classNames = bindClassNames.bind(styles);

const QuotaOverwriteIndicator = ({ className }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  />
);

QuotaOverwriteIndicator.propTypes = {
  className: PropTypes.string,
};

QuotaOverwriteIndicator.defaultProps = {
  className: '',
};

export default QuotaOverwriteIndicator;
