import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import LeftMainContentLayout from '@palette/components/designSystem/LeftMainContentLayout/LeftMainContentLayout';
import NotebookDetailsLeftSideContent from '@palette/components/notebooks/NotebookDetailsLeftSideContent/NotebookDetailsLeftSideContent';
import NotebookDetails from '@palette/components/notebooks/NotebookDetails/NotebookDetails';

import { useNotebookIdInParams } from '@palette/hooks/NotebookHooks';

import styles from './NotebookDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const NotebookDetailsPageContent = ({ className }) => {
  const { notebook } = useNotebookIdInParams();

  const leftSideContentNode = useMemo(() => {
    if (notebook === null) return null;

    return (
      <NotebookDetailsLeftSideContent notebook={notebook} />
    );
  }, [notebook]);

  const mainContentNode = useMemo(() => {
    if (notebook === null) return null;

    return (
      <NotebookDetails notebook={notebook} />
    );
  }, [notebook]);

  return (
    <LeftMainContentLayout
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      leftSideContentClassName={styles.leftSideContentWrapper}
      leftSideContent={leftSideContentNode}
      mainContentClassName={styles.mainContentWrapper}
      mainContent={mainContentNode}
      leftSideWidth={34.3}
    />
  );
};

NotebookDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

NotebookDetailsPageContent.defaultProps = {
  className: '',
};

export default NotebookDetailsPageContent;
