import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import MyStatementPeriodsCardsListItem from '@palette/components/ic/myStatement/MyStatementPeriodsCardsListItem/MyStatementPeriodsCardsListItem';

import {
  useMyStatementPeriodIdInParams,
  useMyStatementPeriods,
} from '@palette/hooks/StatementHooks';

import styles from './MyStatementPeriodsCardsList.less';

const classNames = bindClassNames.bind(styles);

const MAX_ITEMS_BY_ROW = 6;

const MyStatementPeriodsCardsList = ({ className, sessionId, inSalesforce }) => {
  const { t } = useTranslation();

  const { statementPeriodId } = useMyStatementPeriodIdInParams();
  const { listMyStatementPeriodsIsPending, statementPeriods } = useMyStatementPeriods(inSalesforce);

  const contentNode = useMemo(() => {
    if (listMyStatementPeriodsIsPending) return <Loader className={styles.loaderWrapper} />;

    if (statementPeriods.length === 0) {
      return (
        <DefaultEmptyState description={t('statementPeriodsList.noPeriods')} />
      );
    }

    return (
      <div
        className={classNames({
          container: true,
          hasMultipleLines: statementPeriods.length > MAX_ITEMS_BY_ROW,
        })}
      >
        {statementPeriods.map((statementPeriod) => (
          <MyStatementPeriodsCardsListItem
            key={statementPeriod.id}
            className={styles.period}
            statementPeriod={statementPeriod}
            isSelected={statementPeriod.id === statementPeriodId}
            inSalesforce={inSalesforce}
            sessionId={sessionId}
          />
        ))}
      </div>
    );
  }, [
    listMyStatementPeriodsIsPending,
    statementPeriods,
    statementPeriodId,
    inSalesforce,
    sessionId,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MyStatementPeriodsCardsList.propTypes = {
  className: PropTypes.string,
  inSalesforce: PropTypes.bool,
  sessionId: PropTypes.string,
};

MyStatementPeriodsCardsList.defaultProps = {
  className: '',
  inSalesforce: false,
  sessionId: '',
};

export default MyStatementPeriodsCardsList;
