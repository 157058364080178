import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Tag from '@palette/components/designSystem/Tag/Tag';
import Popover from '@palette/components/designSystem/Popover/Popover';

import { TAG_THEMES } from '@palette/constants/tag';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './PeriodDealOwners.less';

const classNames = bindClassNames.bind(styles);

const PeriodDealOwners = ({ className, owners }) => {
  const { t } = useTranslation();

  const popoverContentNode = useMemo(() => {
    if (owners.length < 2) return null;

    const ownersNodes = owners.map((owner) => (
      <UserProfile
        key={owner.id}
        className={styles.owner}
        user={owner}
        clickable
      />
    ));

    return (
      <div className={styles.popoverContentWrapper}>
        {ownersNodes}
      </div>
    );
  }, [owners]);

  if (owners.length === 0) return null;

  if (owners.length === 1) {
    return (
      <UserProfile user={owners[0]} clickable />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Popover
        trigger="hover"
        content={popoverContentNode}
      >
        <div>
          <Tag
            className={styles.ownersCountTag}
            theme={TAG_THEMES.LAVENDER}
            label={t('periodDealOwners.users', { count: owners.length })}
          />
        </div>
      </Popover>
    </div>
  );
};

PeriodDealOwners.propTypes = {
  className: PropTypes.string,
  owners: PropTypes.arrayOf(MetaUserModel.propTypes).isRequired,
};

PeriodDealOwners.defaultProps = {
  className: '',
};

export default PeriodDealOwners;
