import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { usePayments } from '@palette/hooks/CommissionHooks';

import { getCommissionAmount, getDealName } from '@palette/helpers/CommissionHelper';
import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { addCurrency, formatPrice, getCurrencySymbol } from '@palette/helpers/CurrencyHelper';

import { ALERT_TYPES } from '@palette/constants/alert';

import * as CommissionModel from '@palette/models/Commission';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';

import styles from './EditCommissionAmountModal.less';

const EditCommissionAmountModal = ({ visible, onClose, commission }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: commission.scopeParams?.plan?.trackingObject?.connectorId || '' }));

  const overwriteAmountIsPending = useSelector(CommissionsSelectors.overwriteAmountIsPending);

  const dealName = useMemo(() => {
    if (connector !== null) {
      return getDealName(commission, connector);
    }

    return '';
  }, [commission, connector]);

  const [paymentsDone] = usePayments(commission);
  const commissionAmount = getCommissionAmount(commission);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    amount: getCommissionAmount(commission),
  }), [commission]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const cleanAndClose = () => {
    onClose();
  };

  const handleFinish = (values) => {
    let newAmount = parseFloat(values.amount);

    if (newAmount === commission.amount) {
      newAmount = undefined;
    }

    if (
      (!newAmount && newAmount !== 0 && !commission.overwrittenAmount)
      || (newAmount === parseFloat(initialValues.amount))
    ) {
      cleanAndClose();
      return;
    }

    dispatch(CommissionsActions.overwriteAmount({ commissionId: commission.id, amount: newAmount, onSuccessCB: cleanAndClose }));
  };

  const handleOverwriteValue = () => form.submit();

  const handleResetToOriginalAmount = () => {
    dispatch(CommissionsActions.overwriteAmount({ commissionId: commission.id, amount: undefined, onSuccessCB: cleanAndClose }));
  };

  let resetAmountNode = null;

  if (commission.overwrittenAmount !== null) {
    const initialAmount = comaSeparatorFormatter(commission.amount);

    resetAmountNode = (
      <Popconfirm
        title={t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.title')}
        onConfirm={handleResetToOriginalAmount}
        okText={t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.confirm')}
        cancelText={t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.cancel')}
        size="small"
        disabled={overwriteAmountIsPending}
      >
        <Button
          className={styles.resetToOriginalAmountButton}
          type="link"
          disabled={overwriteAmountIsPending}
        >
          {t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.cta', { amount: addCurrency(initialAmount, commission.currency) })}
        </Button>
      </Popconfirm>
    );
  }

  let paymenstAlreadyDoneAlertNode = null;

  if (paymentsDone.payments.length > 0) {
    const percentage = parseFloat(paymentsDone.percentage * 100).toFixed(2);
    const amount = formatPrice(paymentsDone.percentage * commissionAmount, commission.currency);
    paymenstAlreadyDoneAlertNode = (
      <Alert
        className={styles.paymentsDoneAlert}
        type={ALERT_TYPES.WARNING}
        message={t('editCommissionAmountModal.paymentsDone.alert.message', { percentage, amount })}
      />
    );
  }

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {dealName !== '' && t('editCommissionAmountModal.commissionFor', { dealName })}
          {dealName === '' && t('editCommissionAmountModal.commission')}
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleOverwriteValue}
      okText={t('editCommissionAmountModal.overwriteAmount')}
      okIcon={<CheckFilled />}
      loading={overwriteAmountIsPending}
      extraFooterNode={resetAmountNode}
    >
      {paymenstAlreadyDoneAlertNode}
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <FormItem
          name="amount"
          label={t('editCommissionAmountModal.form.amount.label')}
        >
          <Input size="big" type="comaSeparatorFormatted" addonBefore={getCurrencySymbol(commission.currency)} disabled={overwriteAmountIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

EditCommissionAmountModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  commission: CommissionModel.propTypes.isRequired,
};

EditCommissionAmountModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default EditCommissionAmountModal;
