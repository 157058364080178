import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import { getQSStringified } from '@palette/helpers/MenuHelper';

import styles from './MenuWithComputeButton.less';

const MenuWithComputeButton = ({ name, path, pathQS }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const computeAllIsPending = useSelector(MasterPlansSelectors.computeAllIsPending);
  const nbOfConnectors = useSelector(ConnectorsSelectors.getNbOfConnectors);

  const handleClick = () => dispatch(MasterPlansActions.computeAll());

  const nameNode = useMemo(() => {
    if (path === '') {
      return (
        <div>
          {t(name)}
        </div>
      );
    }

    return (
      <Link
        to={{
          pathname: path,
          search: getQSStringified(pathQS),
        }}
      >
        {t(name)}
      </Link>
    );
  }, [path, name, pathQS]);

  return (
    <div className={styles.wrapper}>
      {nameNode}
      {nbOfConnectors > 0 && (
        <Button
          className={styles.button}
          type="primary"
          shape="circle"
          icon={<RefreshLine className={styles.icon} width={16} height={16} spin={computeAllIsPending} />}
          size="small"
          onClick={handleClick}
        />
      )}
    </div>
  );
};

MenuWithComputeButton.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  pathQS: PropTypes.object,
};

MenuWithComputeButton.defaultProps = {
  path: '',
  pathQS: null,
};

export default MenuWithComputeButton;
