import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MyUserStatementPlanCollapse from '@palette/components/ic/myStatement/MyUserStatementPlanCollapse/MyUserStatementPlanCollapse';
import Input from '@palette/components/designSystem/Input/Input';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './MyUserStatementPlans.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementPlans = ({ className, userStatement, inSalesforce }) => {
  const { t } = useTranslation();

  const [searchedDeal, setSearchedDeal] = useState('');

  const searchDealInputNode = useMemo(() => (
    <Input
      className={styles.dealSearch}
      type="search"
      placeholder={t('userStatementPlans.dealSearch.placeholder')}
      onChange={setSearchedDeal}
      value={searchedDeal}
    />
  ), [searchedDeal, setSearchedDeal]);

  const contentNode = useMemo(() => {
    const plansCollapseNodes = userStatement.plans.map((userStatementPlan) => (
      <MyUserStatementPlanCollapse
        key={userStatementPlan.plan.id}
        className={styles.planCollapse}
        userStatementPlan={userStatementPlan}
        searchedDeal={searchedDeal}
        inSalesforce={inSalesforce}
      />
    ));

    return (
      <div className={styles.plans}>
        {plansCollapseNodes}
      </div>
    );
  }, [userStatement, searchedDeal, inSalesforce]);

  if (userStatement.plans.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {searchDealInputNode}
      {contentNode}
    </div>
  );
};

MyUserStatementPlans.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes.isRequired,
  inSalesforce: PropTypes.bool,
};

MyUserStatementPlans.defaultProps = {
  className: '',
  inSalesforce: false,
};

export default MyUserStatementPlans;
