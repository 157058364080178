import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import CallMeHandLottieAnimation from '@palette/components/utils/CallMeHandLottieAnimation/CallMeHandLottieAnimation';

import { DASHBOARD_WIDGETS_COMPONENTS } from '@palette/constants/widgets';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';

import styles from './DashboardPresetPreview.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetPreview = ({ className, user, data }) => {
  const { t } = useTranslation();

  const widgetsNode = useMemo(() => {
    if (data === null || data.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    const widgetsNodes = data.map((widgetData, index) => {
      const WidgetComponent = DASHBOARD_WIDGETS_COMPONENTS[widgetData.dashboardComponent.type] || null;

      if (WidgetComponent === null) return null;

      return (
        <WidgetComponent
          key={`widget_${index}`}
          widgetData={widgetData}
          widgetIndex={index}
          readOnly
          enableLinks={false}
          user={user}
        />
      );
    });

    return (
      <div className={styles.widgets}>
        {widgetsNodes}
      </div>
    );
  }, [data]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          {t('myDashboardPage.title', { firstName: user.firstName })}
        </div>
        <CallMeHandLottieAnimation className={styles.lottieAnimation} />
      </div>
      {widgetsNode}
    </div>
  );
};

DashboardPresetPreview.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
  data: PropTypes.arrayOf(DashboardComponentDataModel.propTypes),
};

DashboardPresetPreview.defaultProps = {
  className: '',
  data: null,
};

export default DashboardPresetPreview;
