import React from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import ResetLine from '@palette/components/utils/Icons/ResetLine';

import styles from './RawDataHistoryDrawerList.less';

const RawDataHistoryDrawerList = ({ resource, canManage, handleRollback }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const overwritesCount = resource.overwrites?.length || 0;

  if (!overwritesCount > 0) {
    return null;
  }

  const overwrites = _orderBy(resource.overwrites, ['created'], 'desc');

  return (
    <div className={styles.wrapper}>
      {overwrites.map((overwrite, index) => (
        <div className={styles.overwriteEntry} key={overwrite.id}>
          <div className={styles.content}>
            <p>
              <strong>
                {overwrite.path.join('.')}
                &nbsp;
              </strong>
              {overwrite.value === undefined
                ? t('rawData.historyDrawer.deletedTo')
                : t('rawData.historyDrawer.setTo', { value: JSON.stringify(overwrite.value) })}
            </p>
            <span>
              {moment(overwrite.created).format('YYYY-MM-DD @ HH:mm:ss')}
            </span>
          </div>
          {canManage && index > 0 && (
            <div className={styles.action}>
              <Tooltip title={t('rawData.historyDrawer.rollbackTooltip')} placement="left">
                <Button
                  className={styles.rollbackButton}
                  type="link"
                  onClick={() => handleRollback(overwrite.id)}
                  icon={<ResetLine />}
                >
                  {t('rawData.historyDrawer.rollback')}
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

RawDataHistoryDrawerList.propTypes = {
  resource: ResourceObjectModel.propTypes.isRequired,
  canManage: PropTypes.bool,
  handleRollback: PropTypes.func,
};

RawDataHistoryDrawerList.defaultProps = {
  canManage: false,
  handleRollback: () => {},
};

export default RawDataHistoryDrawerList;
