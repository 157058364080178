import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import NewNotebookButton from '@palette/components/notebooks/NewNotebookButton/NewNotebookButton';
import NotebooksPageContent from '@palette/components/notebooks/NotebooksPageContent/NotebooksPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import styles from './NotebooksPage.less';

const NotebooksPage = () => {
  const profile = useProfile();
  const { t } = useTranslation();

  if (!hasFeature(profile, FEATURES.NOTEBOOKS) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.notebooksTitle')} />
      <PageTitle className={styles.titleWrapper}>
        <div className={styles.titlesContainer}>
          <h1>{t('notebooksPage.title')}</h1>
          <h2>{t('notebooksPage.subtitle')}</h2>
        </div>
        <NewNotebookButton className={styles.newNotebookBtn} />
      </PageTitle>
      <NotebooksPageContent />
    </div>
  );
};

export default NotebooksPage;
