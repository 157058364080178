import React from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash/size';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import RawDataAddButton from '@palette/components/resources/RawDataAddButton/RawDataAddButton';
import RawDataPanelTitleNode from '@palette/components/resources/RawDataPanelTitleNode/RawDataPanelTitleNode';
import RawDataTreeRecursive from '@palette/components/resources/RawDataTreeRecursive/RawDataTreeRecursive';

import { ADD_CTA_PADDING_LEFT, COLLAPSE_ICON_CONFIG } from '@palette/constants/resources';

import styles from './RawDataTree.less';

const RawDataTree = ({
  data,
  resourceId,
  expandRootByDefault,
  expandAllByDefault,
  showActionButtons,
  hasSearchValue,
  manageDiff,
  recursivityDepth,
  maxNbOfAutoExpandedArrayItems,
}) => {
  const addCtaPaddingLeft = `${ADD_CTA_PADDING_LEFT / 10}rem`;

  return (
    <div className={styles.wrapper}>
      <Collapse
        panels={[
          {
            title: (
              <RawDataPanelTitleNode
                label={data.type}
                count={_size(data.data)}
                type="object"
                isRoot
              />
            ),
            content: (
              <>
                {showActionButtons && (
                  <RawDataAddButton
                    property="object"
                    resourceId={resourceId}
                    paddingLeft={addCtaPaddingLeft}
                  />
                )}
                <RawDataTreeRecursive
                  resourceId={resourceId}
                  items={data.data}
                  overwrites={data.overwrites}
                  type="object"
                  showActionButtons={showActionButtons}
                  hasSearchValue={hasSearchValue}
                  manageDiff={manageDiff}
                  expandAllByDefault={expandAllByDefault}
                  recursivityDepth={recursivityDepth}
                  maxNbOfAutoExpandedArrayItems={maxNbOfAutoExpandedArrayItems}
                />
              </>
            ),
          },
        ]}
        expandIconPosition="end"
        bordered={false}
        ghost
        prefixKey="raw-data"
        defaultActiveKey={expandRootByDefault || expandAllByDefault || hasSearchValue ? 'raw-data0' : undefined}
        {...COLLAPSE_ICON_CONFIG}
      />
    </div>
  );
};

RawDataTree.propTypes = {
  data: PropTypes.object,
  resourceId: PropTypes.string,
  expandRootByDefault: PropTypes.bool,
  expandAllByDefault: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  hasSearchValue: PropTypes.bool,
  manageDiff: PropTypes.bool,
  recursivityDepth: PropTypes.number,
  maxNbOfAutoExpandedArrayItems: PropTypes.number,
};

RawDataTree.defaultProps = {
  data: {},
  resourceId: '',
  expandRootByDefault: false,
  expandAllByDefault: false,
  showActionButtons: true,
  hasSearchValue: false,
  manageDiff: false,
  recursivityDepth: null,
  maxNbOfAutoExpandedArrayItems: 10,
};

export default RawDataTree;
