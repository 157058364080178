import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import ExchangeRateModal from '@palette/components/company/ExchangeRateModal/ExchangeRateModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './AddExchangeRateButton.less';

const classNames = bindClassNames.bind(styles);

const AddExchangeRateButton = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [exchangeRateModalIsVisible, showExchangeRateModal] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.addButton}
        type="link"
        icon={<AddFilled width={20} height={20} />}
        onClick={() => showExchangeRateModal(true)}
      >
        {t('companyExchangeRates.button.add.label.new')}
      </Button>
      {exchangeRateModalIsVisible && (
        <ExchangeRateModal
          visible
          type="add"
          onClose={() => showExchangeRateModal(false)}
        />
      )}
    </div>
  );
};

AddExchangeRateButton.propTypes = {
  className: PropTypes.string,
};

AddExchangeRateButton.defaultProps = {
  className: '',
};

export default AddExchangeRateButton;
