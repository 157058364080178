import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as PlanV3MissingInformationErrorStrategyModel from '@palette/models/globalNotifStrategies/planV3/PlanV3MissingInformationErrorStrategy';

const classNames = bindClassNames.bind();

const PlanV3MissingInformationErrorContentStrategy = ({ className, content }) => {
  const { fieldPath } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notifications.plan.V3.error.missing.information.content"
        values={{
          fieldPath: fieldPath.split('.').join(' > '),
        }}
      />
    </span>
  );
};

PlanV3MissingInformationErrorContentStrategy.propTypes = {
  className: PropTypes.string,
  content: PlanV3MissingInformationErrorStrategyModel.propTypes.isRequired,
};

PlanV3MissingInformationErrorContentStrategy.defaultProps = {
  className: '',
};

export default PlanV3MissingInformationErrorContentStrategy;
