import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import EditableNotebookCellName from '@palette/components/notebooks/EditableNotebookCellName/EditableNotebookCellName';
import NotebookCellMoreOptionsMenu from '@palette/components/notebooks/NotebookCellMoreOptionsMenu/NotebookCellMoreOptionsMenu';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import Button from '@palette/components/designSystem/Button/Button';
import ExportFilled from '@palette/components/utils/Icons/ExportFilled';
import PlayFilled from '@palette/components/utils/Icons/PlayFilled';
import NotebookCellContentWrapper from '@palette/components/notebooks/NotebookCellContentWrapper/NotebookCellContentWrapper';
import CurvedLine from '@palette/components/utils/Icons/CurvedLine';
import EditableNotebookOutputName from '@palette/components/notebooks/EditableNotebookOutputName/EditableNotebookOutputName';
import EditableNotebookCellUniqueIdentifier from '@palette/components/notebooks/EditableNotebookCellUniqueIdentifier/EditableNotebookCellUniqueIdentifier';
import NotebookCellPreview from '@palette/components/notebooks/NotebookCellPreview/NotebookCellPreview';
import NotebookCellError from '@palette/components/notebooks/NotebookCellError/NotebookCellError';
import Tag from '@palette/components/designSystem/Tag/Tag';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useCellEdition } from '@palette/hooks/NotebookHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import {
  NOTEBOOK_CELL_EXPORTABLE_STATUSES,
  NOTEBOOK_CELL_TYPES,
  NOTEBOOK_CODE_CELL_EXECUTION_STATUS,
  NOTEBOOK_STATUSES,
} from '@palette/constants/notebooks';
import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookCellModel from '@palette/models/NotebookCell';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './NotebookCell.less';

const classNames = bindClassNames.bind(styles);

const NotebookCell = ({
  className,
  notebook,
  cell,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const {
    editableCell,
    errors,
    someRequiredFieldIsMissing,
    handleCellContentUpdate,
    handleMakeExportable,
    handleUpdateCellName,
    handleUpdateCellOutputName,
    handleUpdateCellOutputUniqueIdentifier,
  } = useCellEdition(notebook?.id, cell);

  const [exportedOverlayIsVisible, setExportedOverlayIsVisible] = useState(true);

  useEffect(() => {
    setExportedOverlayIsVisible(true);
  }, [cell]);

  const disabledRunCell = useSelector((state) => NotebooksSelectors.getDisabledRunCell(state, { notebookId: notebook.id }));
  const runCellIsPending = useSelector((state) => NotebooksSelectors.getRunCellIsPending(state, {
    notebookId: notebook.id,
    cellId: cell.id,
  }));

  const handleRunCell = useCallback(() => {
    const updatedCell = NotebookCellModel.updateCellInformations(cell, editableCell);
    dispatch(NotebooksActions.updateAndRunCell({ notebookId: notebook.id, updatedCell }));
  }, [notebook, cell, editableCell]);

  const moreOptionsMenuNode = useMemo(() => {
    if (
      !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])
      || notebook.status === NOTEBOOK_STATUSES.ARCHIVED
    ) return null;

    return (
      <NotebookCellMoreOptionsMenu
        key="moreOptionsMenu"
        className={styles.action}
        notebook={notebook}
        cell={cell}
      />
    );
  }, [
    profile,
    notebook,
    cell,
    editableCell,
    disabled,
  ]);

  const makeExportableActionNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])) return null;

    if (NOTEBOOK_CELL_TYPES[cell.typeKey].exportableStatus === NOTEBOOK_CELL_EXPORTABLE_STATUSES.UNEXPORTABLE) return null;

    const cellCantBeExported = cell.executionsCount === 0 || cell.lastExecutionStatus === NOTEBOOK_CODE_CELL_EXECUTION_STATUS.ERROR;
    const cellIsExported = editableCell.status === NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTED;

    if (cellIsExported) {
      return (
        <Tag
          className={styles.tag}
          key="isExportedCell"
          theme={TAG_THEMES.SUCCESS}
          label={t('notebookCell.isExported')}
          icon={CheckFilled}
        />
      );
    }

    if (notebook.status === NOTEBOOK_STATUSES.ARCHIVED && !cellIsExported) {
      return (
        <Tag
          key="isNotExportedCell"
          theme={TAG_THEMES.INFO}
          label={t('notebookCell.isNotExported')}
        />
      );
    }

    return (
      <Popconfirm
        key="makeExportableAction"
        title={t('notebookCell.actions.makeExportable.popconfirm')}
        onConfirm={handleMakeExportable}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Button
          className={styles.action}
          type="primaryOutlined"
          icon={(<ExportFilled width={10} height={10} />)}
          disabled={disabled || cellCantBeExported}
        >
          {t('notebookCell.actions.makeExportable.label')}
        </Button>
      </Popconfirm>
    );
  }, [
    profile,
    notebook,
    cell,
    editableCell,
    disabled,
    handleMakeExportable,
  ]);

  const runActionNode = useMemo(() => {
    if (
      !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])
      || !NOTEBOOK_CELL_TYPES[cell.typeKey].isRunnable
      || notebook.status === NOTEBOOK_STATUSES.ARCHIVED
    ) return null;

    return (
      <Button
        key="runAction"
        className={styles.action}
        type="primaryOutlined"
        icon={(<PlayFilled width={10} height={10} />)}
        disabled={(runCellIsPending === true) || disabledRunCell}
        onClick={handleRunCell}
        loading={runCellIsPending === true}
      >
        {t('notebookCell.actions.run')}
      </Button>
    );
  }, [
    profile,
    cell,
    runCellIsPending,
    disabledRunCell,
    handleRunCell,
  ]);

  const actionsNode = useMemo(() => {
    let actions = [];

    if (runActionNode !== null) {
      actions.push(runActionNode);
    }

    if (makeExportableActionNode !== null) {
      actions.push(makeExportableActionNode);
    }

    if (moreOptionsMenuNode !== null) {
      actions.push(moreOptionsMenuNode);
    }

    if (actions.length === 0) {
      actions = null;
    }

    return (
      <div className={styles.cellActions}>
        {actions}
      </div>
    );
  }, [
    moreOptionsMenuNode,
    makeExportableActionNode,
    runActionNode,
  ]);

  const exportedOverlayNode = useMemo(() => {
    if (
      notebook.status === NOTEBOOK_STATUSES.ARCHIVED
      || editableCell.status !== NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTED
      || !exportedOverlayIsVisible
    ) return null;

    return (
      <div className={styles.exportedOverlay}>
        <div className={styles.exportedOverlayDescription}>
          {t('notebookCell.exportedOverlay.description')}
        </div>
        <Button
          className={styles.exportedOverlayBtn}
          type="primaryOutlined"
          onClick={() => setExportedOverlayIsVisible(false)}
        >
          {t('notebookCell.exportedOverlay.edit.data')}
        </Button>
      </div>
    );
  }, [
    notebook,
    editableCell,
    exportedOverlayIsVisible,
  ]);

  const outputNameNode = useMemo(() => {
    if (
      !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])
      || !NOTEBOOK_CELL_TYPES[cell.typeKey].isRunnable
    ) return null;

    const disabledEdition = disabled
      || notebook.status === NOTEBOOK_STATUSES.ARCHIVED
      || cell.status === NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTED;

    return (
      <div className={styles.outputNameWrapper}>
        <CurvedLine className={styles.outputNameIcon} />
        <EditableNotebookOutputName
          className={styles.outputName}
          cell={editableCell}
          disabled={disabledEdition}
          updateOutputName={handleUpdateCellOutputName}
        />
      </div>
    );
  }, [
    profile,
    notebook,
    editableCell,
    disabled,
    handleUpdateCellOutputName,
  ]);

  const previewNode = useMemo(() => {
    if (
      !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])
      || notebook.status === NOTEBOOK_STATUSES.ARCHIVED
    ) return null;

    return (
      <NotebookCellPreview
        className={styles.preview}
        notebook={notebook}
        cell={cell}
      />
    );
  }, [
    profile,
    notebook,
    cell,
  ]);

  const errorNode = useMemo(() => {
    if (!cell?.error || runCellIsPending) return null;
    return <NotebookCellError className={styles.error} cellId={cell.id} error={cell.error} />;
  }, [cell, runCellIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      id={cell.id}
    >
      <div className={styles.detailsActionsWrapper}>
        <div className={classNames({
          actionsWrapper: true,
          requiredFieldMissing: someRequiredFieldIsMissing,
        })}
        >
          <EditableNotebookCellName
            className={styles.cellName}
            cell={editableCell}
            disabled={disabled || notebook.status === NOTEBOOK_STATUSES.ARCHIVED}
            updateName={handleUpdateCellName}
            hasError={errors.name}
          />
          <EditableNotebookCellUniqueIdentifier
            className={styles.cellOutputUniqueIdentifier}
            cell={editableCell}
            disabled={disabled || notebook.status === NOTEBOOK_STATUSES.ARCHIVED}
            updateUniqueIdentifier={handleUpdateCellOutputUniqueIdentifier}
            hasError={errors.outputUniqueIdentifier}
          />
          {actionsNode}
        </div>
        <div className={styles.cellContentWrapper}>
          <NotebookCellContentWrapper
            cell={cell}
            onCellContentUpdate={handleCellContentUpdate}
            onCellContentSave={handleCellContentUpdate}
            disabled={disabled || notebook.status === NOTEBOOK_STATUSES.ARCHIVED || disabledRunCell}
          />
          {exportedOverlayNode}
        </div>
        {outputNameNode}
      </div>
      {previewNode}
      {errorNode}
    </div>
  );
};

NotebookCell.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  cell: NotebookCellModel.propTypes.isRequired,
  disabled: PropTypes.bool,
};

NotebookCell.defaultProps = {
  className: '',
  disabled: false,
};

export default NotebookCell;
