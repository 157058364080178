import PropTypes from 'prop-types';

import { manageNumberAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import { PLAN_PERIODICITY_TYPE, PLAN_STATUS_TYPE } from '@palette/constants/planV3';

export const modelName = 'PlanV3List';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  usersCount: PropTypes.number,
  folderId: PropTypes.string,
  periodicity: PropTypes.oneOf(Object.keys(PLAN_PERIODICITY_TYPE)),
  status: PropTypes.oneOf(Object.keys(PLAN_STATUS_TYPE)),
});

export const managePeriodicityType = (plan) => Object.keys(PLAN_PERIODICITY_TYPE).find((p) => PLAN_PERIODICITY_TYPE[p] === manageStringAttribute(plan, 'periodicity'));

export const manageStatusType = (plan) => Object.keys(PLAN_STATUS_TYPE).find((s) => PLAN_STATUS_TYPE[s] === manageStringAttribute(plan, 'status'));

export const transform = (plan) => {
  if (!plan) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(plan, 'id'),
      name: manageStringAttribute(plan, 'name'),
      usersCount: manageNumberAttribute(plan, 'usersCount'),
      folderId: manageStringAttribute(plan, 'folder'),
      periodicity: managePeriodicityType(plan),
      status: manageStatusType(plan),
    }
  );
};

export const transformList = (plans) => plans.map((plan) => transform(plan));
