import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Image as AntImage } from 'antd';

import styles from './Image.less';

const classNames = bindClassNames.bind(styles);

const Image = ({ className, ...otherProps }) => (
  <AntImage
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
    preview={false}
    {...otherProps}
  />
);

Image.propTypes = {
  className: PropTypes.string,
};

Image.defaultProps = {
  className: '',
};

export default Image;
