import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import MasterPlanTrackingObjectDefinitionForm from '@palette/components/masterPlanSettings/MasterPlanTrackingObjectDefinitionForm/MasterPlanTrackingObjectDefinitionForm';
import MasterPlanUsersDefinitionForm from '@palette/components/masterPlanSettings/MasterPlanUsersDefinitionForm/MasterPlanUsersDefinitionForm';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsDataSource.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsDataSource = ({ className, planId }) => {
  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MasterPlanTrackingObjectDefinitionForm plan={plan} />
      <MasterPlanUsersDefinitionForm className={styles.usersDefinitionForm} plan={plan} />
    </div>
  );
};

MasterPlanSettingsDataSource.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

MasterPlanSettingsDataSource.defaultProps = {
  className: '',
};

export default MasterPlanSettingsDataSource;
