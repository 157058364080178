import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';

import styles from './RemoveDashboard.less';

const classNames = bindClassNames.bind(styles);

const RemoveDashboard = ({ className, dashboardId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deleteTooltipVisible, setDeleteTooltipVisible] = useState(false);
  const [deleteIsPending, setDeleteIsPending] = useState(false);

  const deleteDashboardIsPending = useSelector(QuotasPerformanceSelectors.deleteDashboardIsPending);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleDelete = (event) => {
    stopPropagation(event);
    setDeleteTooltipVisible(false);
  };

  const deleteDashboard = useCallback((event) => {
    stopPropagation(event);
    setDeleteIsPending(true);

    dispatch(QuotasPerformanceActions.deleteDashboard({
      quotaPerformanceId: dashboardId,
      onSuccessCB: () => redirectTo({ path: routePaths.v2.quotasPerformance }),
    }));
  }, [dashboardId]);

  useEffect(() => {
    if (!deleteDashboardIsPending && deleteIsPending) {
      setDeleteIsPending(false);
    }
  }, [deleteDashboardIsPending, deleteIsPending]);

  const contentNode = useMemo(() => (
    <Popconfirm
      title={t('quotasPerformance.removeDashboard.popconfirm.label')}
      onConfirm={(event) => deleteDashboard(event)}
      okText={t('common.global.yes')}
      cancelText={t('common.global.no')}
      size="small"
    >
      <Tooltip
        title={t('quotasPerformance.removeDashboard.tooltip.label')}
        visible={deleteTooltipVisible}
        onVisibleChange={(visible) => setDeleteTooltipVisible(visible)}
      >
        <Button
          className={styles.deleteButton}
          type="link"
          icon={deleteDashboardIsPending && deleteIsPending ? <LoadingLine spin /> : <TrashFilled className={styles.deleteIcon} />}
          onClick={handleDelete}
          disabled={deleteDashboardIsPending}
        />
      </Tooltip>
    </Popconfirm>
  ), [deleteTooltipVisible, deleteDashboard, deleteDashboardIsPending, deleteIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

RemoveDashboard.propTypes = {
  className: PropTypes.string,
  dashboardId: PropTypes.string.isRequired,
};

RemoveDashboard.defaultProps = {
  className: '',
};

export default RemoveDashboard;
