import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntDForm } from 'antd';
import _isEqual from 'lodash/isEqual';
import pluralize from 'pluralize';
import _keyBy from 'lodash/keyBy';

import Button from '@palette/components/designSystem/Button/Button';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Form from '@palette/components/designSystem/Form/Form';
import Input from '@palette/components/designSystem/Input/Input';
import Select from '@palette/components/designSystem/Select/Select';
import Switch from '@palette/components/designSystem/Switch/Switch';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import PlanWhatIfProjectionChart from '@palette/components/charts/PlanWhatIfProjectionChart/PlanWhatIfProjectionChart';

import { useMasterPlanColumns } from '@palette/hooks/MasterPlanHooks';

import { getPlanLastPeriod } from '@palette/helpers/MasterPlanHelper';
import { getColumnValue } from '@palette/helpers/ConnectorHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanProjectionDefinitionForm.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanProjectionDefinitionForm = ({
  className,
  plan,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planColumns = useMasterPlanColumns(plan);

  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);

  const [form] = AntDForm.useForm();

  const initialExplanation = useMemo(() => (plan.estimationDefinition?.explanation || ''), [plan]);
  const initialReferenceDealId = useMemo(() => (plan.estimationDefinition?.referenceDealId || null), [plan]);
  const initialDisplay = useMemo(() => (plan.estimationDefinition?.display || false), [plan]);

  const initialValues = useMemo(() => ({
    explanation: initialExplanation,
    referenceDealId: initialReferenceDealId,
    display: initialDisplay,
  }), [initialExplanation, initialReferenceDealId, initialDisplay]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      ...initialValues,
    });
  }, [initialValues]);

  const planLastPeriod = useMemo(() => getPlanLastPeriod(plan), [plan]);

  const getPeriodDealsIsPending = useSelector(MasterPlansSelectors.getPeriodDealsIsPending);
  const planLastPeriodDeals = useSelector((state) => (MasterPlansSelectors.getMasterPlanPeriodDeals(state, { masterPlanId: plan.id, year: planLastPeriod.year, periodId: planLastPeriod.period })));

  useEffect(() => {
    if (planLastPeriod) {
      dispatch(MasterPlansActions.getPeriodDeals({
        planId: plan.id,
        year: planLastPeriod.year,
        period: planLastPeriod.period,
      }));
    }
  }, [plan, planLastPeriod]);

  const dealsOptions = useMemo(() => (
    planLastPeriodDeals.map((periodDeal) => {
      const commissionsTotal = formatPrice(periodDeal.totalCommissionAmount, periodDeal.currency);
      const columnsValues = planColumns.map((planColumn) => (getColumnValue(periodDeal.resourceObject?.data || {}, planColumn)));
      const dealDefinition = `${commissionsTotal} - ${columnsValues.join(' ')}`;

      return {
        label: dealDefinition,
        value: periodDeal.resourceObjectId,
      };
    })
  ), [planLastPeriodDeals, planColumns, plan]);

  const getFormUpdatedData = useCallback((values) => {
    const finalValues = values;

    const finalInitialValues = initialValues;

    const updatedData = Object.keys(finalValues).reduce((data, valKey) => {
      if (!_isEqual(finalInitialValues[valKey], finalValues[valKey])) {
        return {
          ...data,
          [valKey]: finalValues[valKey],
        };
      }

      return data;
    }, {});

    return [updatedData, finalValues, finalInitialValues];
  }, [initialValues]);

  const formIsPristine = useMemo(() => {
    const [updatedData] = getFormUpdatedData(formValues);

    return Object.keys(updatedData).length === 0;
  }, [formValues, getFormUpdatedData]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setFormValues(allValues);
  }, []);

  const handleFinish = useCallback((values) => {
    const [updatedData, finalValues] = getFormUpdatedData(values);

    if (Object.keys(updatedData).length > 0) {
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, estimationDefinition: finalValues }));
    }
  }, [plan, getFormUpdatedData]);

  const handleDeleteProjectionDefinition = useCallback(() => {
    dispatch(MasterPlansActions.updatePlan({ planId: plan.id, estimationDefinition: null }));
  }, [plan]);

  const handleSaveProjectionDefinition = useCallback(() => {
    form.submit();
  }, [form]);

  const actionsNode = useMemo(() => {
    let deleteActionNode = null;
    if (initialReferenceDealId !== null) {
      deleteActionNode = (
        <div className={styles.deleteAction}>
          {t('masterPlanProjectionDefinitionForm.deleteAction.description')}
          <Button
            className={styles.deleteActionBtn}
            type="linkDestroy"
            icon={(<TrashFilled width={16} height={16} />)}
            onClick={handleDeleteProjectionDefinition}
            disabled={updatePlanIsPending}
          >
            {t('masterPlanProjectionDefinitionForm.deleteAction.btnLabel')}
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.actions}>
        <Button className={styles.submitBtn} onClick={handleSaveProjectionDefinition} disabled={formIsPristine || updatePlanIsPending}>
          {initialReferenceDealId !== null ? t('masterPlanProjectionDefinitionForm.form.updateSubmitBtnLabel') : t('masterPlanProjectionDefinitionForm.form.defineSubmitBtnLabel')}
        </Button>
        {deleteActionNode}
      </div>
    );
  }, [
    handleSaveProjectionDefinition,
    formIsPristine,
    updatePlanIsPending,
    handleDeleteProjectionDefinition,
    initialReferenceDealId,
  ]);

  const getPlanUsersAndManagersIsPending = useSelector(MasterPlansSelectors.getPlanUsersAndManagersIsPending);
  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: plan.id }));
  const [selectedUserIdForPreview, setSelectedUserIdForPreview] = useState(null);

  useEffect(() => {
    if (plan !== null) {
      dispatch(MasterPlansActions.getPlanUsersAndManagers({ planId: plan.id }));
    }
  }, [plan]);

  const usersById = useMemo(() => {
    const planUsers = planUsersAndManagers.users.map((userData) => (userData.user));
    const planManagers = planUsersAndManagers.managers.map((userData) => (userData.user));

    return _keyBy(planUsers.concat(planManagers), (planUser) => planUser.id);
  }, [planUsersAndManagers]);

  useEffect(() => {
    const userIdsList = Object.keys(usersById);
    if (selectedUserIdForPreview === null && userIdsList.length > 0) {
      setSelectedUserIdForPreview(userIdsList[0]);
    }
  }, [usersById]);

  const usersOptions = useMemo(() => (
    Object.values(usersById).map((planUser) => ({
      label: (
        <UserProfile
          className={styles.userOption}
          user={planUser}
          avatarSize={18}
          avatarStyle={{
            fontSize: '1rem',
            width: '1.8rem',
            minWidth: '1.8rem',
            height: '1.8rem',
          }}
        />
      ),
      value: planUser.id,
      rawlabel: planUser.displayName,
    }))
  ), [usersById]);

  const handleUserForPreviewChange = useCallback((userId) => {
    setSelectedUserIdForPreview(userId);
  }, []);

  const projectionPreviewNode = useMemo(() => {
    if (plan.estimationDefinition === null || plan.estimationDefinition.referenceDealId === null) return null;

    if (usersOptions.length === 0) return null;

    let projectionChartNode = null;
    if (selectedUserIdForPreview !== null) {
      projectionChartNode = (
        <PlanWhatIfProjectionChart
          plan={plan}
          period={planLastPeriod}
          forAdmin
          user={usersById[selectedUserIdForPreview]}
        />
      );
    }

    return (
      <div className={styles.previewProjectionWrapper}>
        <h2>
          {t('masterPlanProjectionDefinitionForm.previewProjection.title')}
        </h2>
        <div className={styles.userForPreviewSelectorWrapper}>
          <div className={styles.userForPreviewSelectorLabel}>
            {t('masterPlanProjectionDefinitionForm.previewProjection.selectorLabel')}
          </div>
          <Select
            className={styles.userForPreviewSelector}
            dropdownClassName={styles.userForPreviewSelectorDropdown}
            options={usersOptions}
            showSearch
            enableFilterOptions
            filterOptionProp="rawlabel"
            value={selectedUserIdForPreview}
            onChange={handleUserForPreviewChange}
            pendingOptions={usersOptions.length === 0 && getPlanUsersAndManagersIsPending}
          />
        </div>
        <div className={styles.projectionChartWrapper}>
          {projectionChartNode}
        </div>
      </div>
    );
  }, [
    plan,
    planLastPeriod,
    usersOptions,
    getPlanUsersAndManagersIsPending,
    selectedUserIdForPreview,
    handleUserForPreviewChange,
    usersById,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <FormItem
          name="explanation"
          label={t('masterPlanProjectionDefinitionForm.form.explanation.label')}
        >
          <Input size="big" placeholder={t('masterPlanProjectionDefinitionForm.form.explanation.placeholder')} disabled={updatePlanIsPending} />
        </FormItem>
        <FormItem
          name="referenceDealId"
          label={t('masterPlanProjectionDefinitionForm.form.referenceDealId.label', { dealType: pluralize.singular(plan.trackingObject?.type || '') })}
          required
        >
          <Select
            size="big"
            options={dealsOptions}
            disabled={updatePlanIsPending}
            pendingOptions={dealsOptions.length === 0 && getPeriodDealsIsPending}
          />
        </FormItem>
        <div className={styles.displaySwitchWrapper}>
          <FormItem
            className={styles.displaySwitch}
            name="display"
            required
          >
            <Switch disabled={updatePlanIsPending} />
          </FormItem>
          <div className={styles.displayLabel}>
            {t('masterPlanProjectionDefinitionForm.form.display.label')}
          </div>
        </div>
        {actionsNode}
      </Form>
      {projectionPreviewNode}
    </div>
  );
};

MasterPlanProjectionDefinitionForm.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanProjectionDefinitionForm.defaultProps = {
  className: '',
};

export default MasterPlanProjectionDefinitionForm;
