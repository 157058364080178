import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tag from '@palette/components/designSystem/Tag/Tag';
import Link from '@palette/components/designSystem/Link/Link';
import WidgetsFilled from '@palette/components/utils/Icons/WidgetsFilled';
import DashboardPresetsListItemMoreOptionsMenu from '@palette/components/dashboard/DashboardPresetsListItemMoreOptionsMenu/DashboardPresetsListItemMoreOptionsMenu';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import routePaths from '@palette/config/routePaths';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import styles from './DashboardPresetsListItem.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetsListItem = ({ className, dashboardPreset }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const nameNode = useMemo(() => (
    <Link
      className={styles.name}
      maxWidth="20"
      displayIcon
      path={routePaths.v2.dashboardPresetDetails}
      params={{ presetId: dashboardPreset.id }}
    >
      {dashboardPreset.name}
    </Link>
  ), [dashboardPreset]);

  const componentsNode = useMemo(() => (
    <Tag
      className={styles.components}
      theme={TAG_THEMES.LAVENDER}
      key="scope-hidden"
      label={t('dashboardPresetsListItem.components', { count: dashboardPreset.dashboardComponents.length })}
    />
  ), [dashboardPreset]);

  const [moreMenuIsOpen, setMoreMenuIsOpen] = useState(false);

  const handleMoreMenuOpenChange = useCallback((isOpen) => {
    setMoreMenuIsOpen(isOpen);
  }, [setMoreMenuIsOpen]);

  const moreOptionsMenuNode = useMemo(() => {
    if (
      !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])
      || dashboardPreset.isDefault
    ) return null;

    return (
      <div
        className={classNames({
          moreMenuWrapper: true,
          moreMenuIsOpen,
        })}
      >
        <DashboardPresetsListItemMoreOptionsMenu
          className={styles.moreMenu}
          dashboardPreset={dashboardPreset}
          onOpenChange={handleMoreMenuOpenChange}
        />
      </div>
    );
  }, [dashboardPreset, moreMenuIsOpen, handleMoreMenuOpenChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <WidgetsFilled className={styles.icon} width={24} height={24} />
      {nameNode}
      {componentsNode}
      {moreOptionsMenuNode}
    </div>
  );
};

DashboardPresetsListItem.propTypes = {
  className: PropTypes.string,
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
};

DashboardPresetsListItem.defaultProps = {
  className: '',
};

export default DashboardPresetsListItem;
