import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import FolderOpenFilled from '@palette/components/utils/Icons/FolderOpenFilled';
import FolderCloseFilled from '@palette/components/utils/Icons/FolderCloseFilled';

import styles from './FolderIcon.less';

const classNames = bindClassNames.bind(styles);

const FolderIcon = ({ className, isOpened, ...otherProps }) => {
  if (isOpened) {
    return (
      <FolderOpenFilled
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
        {...otherProps}
      />
    );
  }

  return (
    <FolderCloseFilled
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    />
  );
};

FolderIcon.propTypes = {
  className: PropTypes.string,
  isOpened: PropTypes.bool,
};

FolderIcon.defaultProps = {
  className: '',
  isOpened: false,
};

export default FolderIcon;
