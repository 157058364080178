import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import HistoryFilled from '@palette/components/utils/Icons/HistoryFilled';
import ShowVariableValueHistoryModal from '@palette/components/usersVariables/ShowVariableValueHistoryModal/ShowVariableValueHistoryModal';

import * as UserVariableModel from '@palette/models/UserVariable';
import * as MetaUserModel from '@palette/models/MetaUser';
import * as UserVariableOverrideModel from '@palette/models/UserVariableOverride';

import styles from './ShowVariableValueHistory.less';

const classNames = bindClassNames.bind(styles);

const ShowVariableValueHistory = ({ className, user, userVariable, overrides }) => {
  const [showVariableValueHistoryModalIsVisible, showShowVariableValueHistoryModal] = useState(false);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.showHistoryBtn}
        icon={(<HistoryFilled className={styles.showHistoryBtnIcon} width={16} height={16} />)}
        onClick={() => showShowVariableValueHistoryModal(true)}
      />
      {
        showVariableValueHistoryModalIsVisible && (
          <ShowVariableValueHistoryModal
            visible
            onClose={() => showShowVariableValueHistoryModal(false)}
            user={user}
            userVariable={userVariable}
            overrides={overrides}
          />
        )
      }
    </div>
  );
};

ShowVariableValueHistory.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
  userVariable: UserVariableModel.propTypes.isRequired,
  overrides: PropTypes.arrayOf(UserVariableOverrideModel.propTypes).isRequired,
};

ShowVariableValueHistory.defaultProps = {
  className: '',
};

export default ShowVariableValueHistory;
