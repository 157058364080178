import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import CommissionDrawerVariablesTab from '@palette/components/commission/CommissionDrawerVariablesTab/CommissionDrawerVariablesTab';
import CommissionDrawerPaymentsTab from '@palette/components/commission/CommissionDrawerPaymentsTab/CommissionDrawerPaymentsTab';
import CommissionDrawerCalculationsTab from '@palette/components/commission/CommissionDrawerCalculationsTab/CommissionDrawerCalculationsTab';
import CommissionDrawerActivitiesTab from '@palette/components/commission/CommissionDrawerActivitiesTab/CommissionDrawerActivitiesTab';

import { COMMISSION_DRAWER_QS_KEY, COMMISSION_DRAWER_TABS_IDS } from '@palette/constants/tabs';

import * as CommissionModel from '@palette/models/Commission';

import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';

import styles from './CommissionDrawerTabs.less';

const classNames = bindClassNames.bind(styles);

const CommissionDrawerTabs = ({ className, commission, viewOnly, inSalesforce }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!viewOnly) {
      dispatch(CommissionsActions.getActivities({ commissionId: commission.id }));
    }
  }, [commission, viewOnly]);

  const activities = useSelector((state) => CommissionsSelectors.getCommissionActivitiesById(state, { commissionId: commission.id }));

  const tabs = useMemo(() => {
    const drawerTabs = [
      {
        tabKey: COMMISSION_DRAWER_TABS_IDS.VARIABLES,
        title: t('commissionDrawerTabs.variables'),
        content: (
          <CommissionDrawerVariablesTab commission={commission} viewOnly={viewOnly} />
        ),
      },
      {
        tabKey: COMMISSION_DRAWER_TABS_IDS.PAYMENTS,
        title: t('commissionDrawerTabs.payments'),
        titleCount: commission.payments?.length || 0,
        content: (
          <CommissionDrawerPaymentsTab commission={commission} viewOnly={viewOnly} />
        ),
      },
      {
        tabKey: COMMISSION_DRAWER_TABS_IDS.CALCULATIONS,
        title: t('commissionDrawerTabs.calculations'),
        content: (
          <CommissionDrawerCalculationsTab commission={commission} />
        ),
      },
    ];

    if (!viewOnly) {
      drawerTabs.push({
        tabKey: COMMISSION_DRAWER_TABS_IDS.ACTIVITIES,
        title: t('commissionDrawerTabs.activities'),
        titleCount: activities ? activities.comments?.length : 0,
        content: (
          <CommissionDrawerActivitiesTab commission={commission} />
        ),
      });
    }

    return drawerTabs;
  }, [
    commission,
    viewOnly,
    activities,
  ]);

  return (
    <Tabs
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      stretched
      tabs={tabs}
      qsTabKey={COMMISSION_DRAWER_QS_KEY}
      inSalesforce={inSalesforce}
    />
  );
};

CommissionDrawerTabs.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  viewOnly: PropTypes.bool,
  inSalesforce: PropTypes.bool,
};

CommissionDrawerTabs.defaultProps = {
  className: '',
  viewOnly: false,
  inSalesforce: false,
};

export default CommissionDrawerTabs;
