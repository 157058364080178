import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import MyUserStatementAdjustments from '@palette/components/ic/myStatement/MyUserStatementAdjustments/MyUserStatementAdjustments';
import MyUserStatementInfosAndActions from '@palette/components/ic/myStatement/MyUserStatementInfosAndActions/MyUserStatementInfosAndActions';
import MyUserStatementPlans from '@palette/components/ic/myStatement/MyUserStatementPlans/MyUserStatementPlans';
import StatementRecomputeWarning from '@palette/components/statement/statementCommon/StatementRecomputeWarning/StatementRecomputeWarning';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './MyUserStatementContent.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementContent = ({ className, statementPeriod, userStatement, inSalesforce }) => {
  const recomputeWarningNode = useMemo(() => {
    if (!userStatement?.isDirty) return null;
    return <StatementRecomputeWarning />;
  }, [userStatement]);

  const infosAndActionsNode = useMemo(() => {
    if (userStatement === null) return null;

    return (
      <MyUserStatementInfosAndActions
        statementPeriod={statementPeriod}
        userStatement={userStatement}
        inSalesforce={inSalesforce}
      />
    );
  }, [statementPeriod, userStatement, inSalesforce]);

  const contentNode = useMemo(() => {
    if (userStatement === null) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <div className={styles.content}>
        <MyUserStatementAdjustments className={styles.collapseBlock} userStatement={userStatement} />
        <MyUserStatementPlans userStatement={userStatement} inSalesforce={inSalesforce} />
      </div>
    );
  }, [userStatement, inSalesforce]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {recomputeWarningNode}
      {infosAndActionsNode}
      {contentNode}
    </div>
  );
};

MyUserStatementContent.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  userStatement: UserStatementModel.propTypes,
  inSalesforce: PropTypes.bool,
};

MyUserStatementContent.defaultProps = {
  className: '',
  userStatement: null,
  inSalesforce: false,
};

export default MyUserStatementContent;
