import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanPeriodProgressDetails from '@palette/components/masterPlan/MasterPlanPeriodProgressDetails/MasterPlanPeriodProgressDetails';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as MasterPlanModel from '@palette/models/MasterPlan';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';

import styles from './MyMasterPlanPeriodsListItem.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanPeriodsListItem = ({ className, period, plan }) => {
  const { t } = useTranslation();

  const nameNode = useMemo(() => (
    <Link
      className={styles.nameLink}
      path={routePaths.v2.myPlanPeriodDetails}
      params={{ masterPlanId: plan.id, year: period.year, periodId: period.period }}
      displayIcon
    >
      {getPlanPeriodName(plan, period)}
    </Link>
  ), [plan, period]);

  const progressNode = useMemo(() => (
    <MasterPlanPeriodProgressDetails plan={plan} period={period} />
  ), [plan, period]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.nameWrapper}>{nameNode}</div>
        <div className={styles.peopleAndCommissions}>
          <div className={styles.commissionWrapper}>
            <div className={styles.commissionLabel}>
              {`${t('masterPlanPeriodsListItem.commissions')} · `}
            </div>
            <div className={styles.commissionValue}>
              {formatPrice(period.totalCommissionAmount, period.currency)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.progressActionsWrapper}>
        {progressNode}
      </div>
    </div>
  );
};

MyMasterPlanPeriodsListItem.propTypes = {
  className: PropTypes.string,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
};

MyMasterPlanPeriodsListItem.defaultProps = {
  className: '',
};

export default MyMasterPlanPeriodsListItem;
