import DollarFilled from '@palette/components/utils/Icons/DollarFilled';
import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';
import ProfileFilled from '@palette/components/utils/Icons/ProfileFilled';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';

import CommissionValueOverwriteContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/CommissionValueOverwriteContentStrategy';
import PlanGeneralSettingsUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/PlanGeneralSettingsUpdateContentStrategy';
import PlanCommissionRulesUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/PlanCommissionRulesUpdateContentStrategy';
import PlanPayoutRulesUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/PlanPayoutRulesUpdateContentStrategy';
import PlanDeleteContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/PlanDeleteContentStrategy';
import PlanDependenciesUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/PlanDependenciesUpdateContentStrategy';
import PlanSourceTrackUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/PlanSourceTrackUpdateContentStrategy';
import PlanUserTrackUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/PlanUserTrackUpdateContentStrategy';
import CommissionDealValueOverwriteContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/CommissionDealValueOverwriteContentStrategy';
import CommissionPayoutOverwriteContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/CommissionPayoutOverwriteContentStrategy';
import ResourceUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/ResourceUpdateContentStrategy';
import UserAddInPlanContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/UserAddInPlanContentStrategy';
import UserDeleteFromPlanContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/UserDeleteFromPlanContentStrategy';
import StatementCorrectionApplyContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/StatementCorrectionApplyContentStrategy';
import StatementCorrectionRejectContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/StatementCorrectionRejectContentStrategy';
import StatementCorrectionAppliedUpdateContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/StatementCorrectionAppliedUpdateContentStrategy';
import StatementCorrectionAppliedDeleteContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/StatementCorrectionAppliedDeleteContentStrategy';
import StatementCorrectionApplyManuallyContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/StatementCorrectionApplyManuallyContentStrategy';
import StatementCorrectedContentStrategy from '@palette/components/statement/userStatementHistory/UserStatementHistoryContentStrategies/StatementCorrectedContentStrategy';

export const ACTION_ENTITIES = {
  PLAN: {
    i18nEntity: 'plan',
    icon: MedicalChartFilled,
  },
  COMMISSION: {
    i18nEntity: 'commission',
    icon: StatementsFilled,
  },
  DEAL: {
    i18nEntity: 'deal',
    icon: StatementsFilled,
  },
  USER: {
    i18nEntity: 'user',
    icon: ProfileFilled,
  },
  STATEMENT: {
    i18nEntity: 'statement',
    icon: DollarFilled,
  },
};

export const CORRECTION_STATUS = {
  APPROVED: 'APPROVED',
  REFUSED: 'REFUSED',
};

export const ACTION_TYPES = {
  PLAN_GENERAL_SETTINGS_UPDATE: {
    entity: ACTION_ENTITIES.PLAN,
    action: 'PlanGeneralSettingsUpdate',
    i18nAction: 'plan_general_settings_update',
    contentStrategyComp: PlanGeneralSettingsUpdateContentStrategy,
  },
  PLAN_COMMISSION_RULES_UPDATE: {
    entity: ACTION_ENTITIES.PLAN,
    action: 'PlanCommissionRulesUpdate',
    i18nAction: 'plan_commission_rules_update',
    contentStrategyComp: PlanCommissionRulesUpdateContentStrategy,
  },
  PLAN_PAYOUT_RULES_UPDATE: {
    entity: ACTION_ENTITIES.PLAN,
    action: 'PlanPayoutsUpdate',
    i18nAction: 'plan_payout_rules_update',
    contentStrategyComp: PlanPayoutRulesUpdateContentStrategy,
  },
  PLAN_DELETE: {
    entity: ACTION_ENTITIES.PLAN,
    action: 'PlanDeletion',
    i18nAction: 'plan_delete',
    contentStrategyComp: PlanDeleteContentStrategy,
  },
  PLAN_DEPENDENCIES_UPDATE: {
    entity: ACTION_ENTITIES.PLAN,
    action: 'PlanInjectionsUpdate',
    i18nAction: 'plan_dependencies_update',
    contentStrategyComp: PlanDependenciesUpdateContentStrategy,
  },
  PLAN_SOURCE_TRACK_UPDATE: {
    entity: ACTION_ENTITIES.PLAN,
    action: 'PlanCommissionableResourceUpdate',
    i18nAction: 'plan_source_track_update',
    contentStrategyComp: PlanSourceTrackUpdateContentStrategy,
  },
  PLAN_USER_TRACK_UPDATE: {
    entity: ACTION_ENTITIES.PLAN,
    action: 'PlanUserResourceUpdate',
    i18nAction: 'plan_user_track_update',
    contentStrategyComp: PlanUserTrackUpdateContentStrategy,
  },
  COMMISSION_VALUE_OVERWRITE: {
    entity: ACTION_ENTITIES.COMMISSION,
    action: 'CommissionAmountOverwrite',
    i18nAction: 'commission_value_overwrite',
    contentStrategyComp: CommissionValueOverwriteContentStrategy,
  },
  COMMISSION_DEAL_VALUE_OVERWRITE: {
    entity: ACTION_ENTITIES.COMMISSION,
    action: 'CommissionDealValueOverwrite',
    i18nAction: 'commission_deal_value_overwrite',
    contentStrategyComp: CommissionDealValueOverwriteContentStrategy,
  },
  COMMISSION_PAYOUT_OVERWRITE: {
    entity: ACTION_ENTITIES.COMMISSION,
    action: 'CommissionPaymentsOverwrite',
    i18nAction: 'commission_payout_overwrite',
    contentStrategyComp: CommissionPayoutOverwriteContentStrategy,
  },
  RESOURCE_UPDATE: {
    entity: ACTION_ENTITIES.DEAL,
    action: 'ResourceOverwrite',
    i18nAction: 'resource_update',
    contentStrategyComp: ResourceUpdateContentStrategy,
  },
  RESOURCE_UPDATE_SYNC: {
    entity: ACTION_ENTITIES.DEAL,
    action: 'ResourceUpdateDuringSync',
    i18nAction: '', // TODO: wip from api.
    contentStrategyComp: null, // TODO: wip from api.
  },
  USER_ADD_IN_PLAN: {
    entity: ACTION_ENTITIES.USER,
    action: 'UserInsertionInPlan',
    i18nAction: 'user_add_in_plan',
    contentStrategyComp: UserAddInPlanContentStrategy,
  },
  USER_DELETE_FROM_PLAN: {
    entity: ACTION_ENTITIES.USER,
    action: 'UserDeletionFromPlan',
    i18nAction: 'user_delete_from_plan',
    contentStrategyComp: UserDeleteFromPlanContentStrategy,
  },
  STATEMENT_CORRECTION_APPLY: {
    entity: ACTION_ENTITIES.STATEMENT,
    action: 'StatementCorrectionApplied',
    i18nAction: 'statement_correction_apply',
    contentStrategyComp: StatementCorrectionApplyContentStrategy,
  },
  STATEMENT_CORRECTION_REJECT: {
    entity: ACTION_ENTITIES.STATEMENT,
    action: 'StatementCorrectionIgnored',
    i18nAction: 'statement_correction_reject',
    contentStrategyComp: StatementCorrectionRejectContentStrategy,
  },
  STATEMENT_CORRECTION_APPLIED_UPDATE: {
    entity: ACTION_ENTITIES.STATEMENT,
    action: 'StatementAdjustmentEdited',
    i18nAction: 'statement_correction_applied_update',
    contentStrategyComp: StatementCorrectionAppliedUpdateContentStrategy,
  },
  STATEMENT_CORRECTION_APPLIED_DELETE: {
    entity: ACTION_ENTITIES.STATEMENT,
    action: 'StatementAdjustmentDeleted',
    i18nAction: 'statement_correction_applied_delete',
    contentStrategyComp: StatementCorrectionAppliedDeleteContentStrategy,
  },
  STATEMENT_CORRECTION_APPLY_MANUALLY: {
    entity: ACTION_ENTITIES.STATEMENT,
    action: 'StatementAdjustmentCreated',
    i18nAction: 'statement_correction_apply_manually',
    contentStrategyComp: StatementCorrectionApplyManuallyContentStrategy,
  },
  STATEMENT_CORRECTED: {
    entity: ACTION_ENTITIES.STATEMENT,
    action: 'StatementCorrected',
    i18nAction: 'statement_corrected',
    contentStrategyComp: StatementCorrectedContentStrategy,
  },
};
