import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanPeriodFreezeButton from '@palette/components/masterPlanPeriod/MasterPlanPeriodFreezeButton/MasterPlanPeriodFreezeButton';
import MasterPlanPeriodProgressDetails from '@palette/components/masterPlan/MasterPlanPeriodProgressDetails/MasterPlanPeriodProgressDetails';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getPlanPeriodName, getPeriodUsersCount } from '@palette/helpers/MasterPlanHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';

import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanPeriodsListItem.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodsListItem = ({ className, period, plan, isCurrent }) => {
  const dispatch = useDispatch();
  const profile = useProfile();
  const { t } = useTranslation();

  const nameNode = useMemo(() => (
    <Link
      path={routePaths.v2.planPeriodDetails}
      params={{ masterPlanId: plan.id, year: period.year, periodId: period.period }}
      displayIcon
    >
      {getPlanPeriodName(plan, period)}
    </Link>
  ), [plan, period]);

  const usersCountNode = useMemo(() => (
    <>
      <div className={styles.usersCount}>{getPeriodUsersCount(plan, period)}</div>
      <TeamFilled className={styles.usersCountIcon} />
    </>
  ), [plan, period]);

  const progressNode = useMemo(() => (
    <MasterPlanPeriodProgressDetails plan={plan} period={period} />
  ), [plan, period]);

  const freezeBtnNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE]) || isCurrent) return null;

    const handleConfirmFreezeAction = (action) => {
      dispatch(AnalyticsActions.sendEvent({
        event: MASTER_PLANS_EVENTS.PERIOD_LIST.CLICK_FREEZE,
        params: { action },
      }));
    };

    return (
      <MasterPlanPeriodFreezeButton
        plan={plan}
        period={period}
        onConfirm={handleConfirmFreezeAction}
      />
    );
  }, [profile, plan, period, isCurrent]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.nameWrapper}>{nameNode}</div>
        <div className={styles.peopleAndCommissions}>
          <div className={styles.usersCountWrapper}>{usersCountNode}</div>
          <div className={styles.commissionWrapper}>
            <div className={styles.commissionLabel}>
              {`${t('masterPlanPeriodsListItem.commissions')} · `}
            </div>
            <div className={styles.commissionValue}>
              {period.dealsCount > 0 ? formatPrice(period.totalCommissionAmount, period.currency) : '-'}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.progressActionsWrapper}>
        {progressNode}
        <div className={styles.actionsWrapper}>{freezeBtnNode}</div>
      </div>
    </div>
  );
};

MasterPlanPeriodsListItem.propTypes = {
  className: PropTypes.string,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
  isCurrent: PropTypes.bool,
};

MasterPlanPeriodsListItem.defaultProps = {
  className: '',
  isCurrent: false,
};

export default MasterPlanPeriodsListItem;
