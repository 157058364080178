import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DeleteDashboardPresetModal from '@palette/components/dashboard/DeleteDashboardPresetModal/DeleteDashboardPresetModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import styles from './DashboardPresetMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetMoreOptionsMenu = ({ className, dashboardPreset }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [deleteDashboardPresetModalIsVisible, showDeleteDashboardPresetModal] = useState(false);

  const moreMenuitems = [];

  const deleteMenuItem = {
    key: 'deleteDashboardPreset',
    title: t('dashboardPreset.deleteDashboardPreset'),
    icon: (
      <TrashFilled />
    ),
    onClick: () => showDeleteDashboardPresetModal(true),
  };

  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) {
    moreMenuitems.push(deleteMenuItem);
  }

  if (moreMenuitems.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={[
          deleteMenuItem,
        ]}
      />
      {
        deleteDashboardPresetModalIsVisible && (
          <DeleteDashboardPresetModal visible onClose={() => showDeleteDashboardPresetModal(false)} dashboardPreset={dashboardPreset} />
        )
      }
    </div>
  );
};

DashboardPresetMoreOptionsMenu.propTypes = {
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
  className: PropTypes.string,
};

DashboardPresetMoreOptionsMenu.defaultProps = {
  className: '',
};

export default DashboardPresetMoreOptionsMenu;
