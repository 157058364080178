import React from 'react';

import { TableOutlined } from '@ant-design/icons';

import Image from '@palette/components/designSystem/Image/Image';
import BoxFilled from '@palette/components/utils/Icons/BoxFilled';

export const ALL_USERS_CONNECTOR_VALUE = 'all_users';

// TODO: Manage i18n locale for this format
export const COLUMN_DATE_FORMAT = {
  Date: 'YYYY-MM-DD',
  DateTime: 'YYYY-MM-DD HH:mm',
};

export const CONNECTOR_TYPES = {
  HUBSPOT: 'HUBSPOT',
  QUICKBOOKS: 'QUICKBOOKS',
  QUICKBOOKS_SANDBOX: 'QUICKBOOKS_SANDBOX',
  NOTEBOOK: 'NOTEBOOK',
};

export const MATCHING_FIELDS = {
  HUBSPOT_Deal: ['hubspot_owner_id'],
  HUBSPOT_User: ['ownerId'],
  HUBSPOT_Engagement: ['ownerId'],
  HUBSPOT_Contact: ['hubspot_owner_id'],
  HUBSPOT_Company: ['hubspot_owner_id'],
  SALESFORCE_User: ['Id'],
  SALESFORCE_Opportunity: ['OwnerId'],
  SALESFORCE_Task: ['OwnerId', 'CreatedById'],
  SELLSY_User: ['linkedid'],
  SELLSY_Opportunity: [],
  SELLSY_Invoice: ['docspeakerStaffId'],
  SELLSY_CreditNote: ['docspeakerStaffId'],
  SELLSY_Estimate: ['docspeakerStaffId'],
  PIPEDRIVE_User: ['id'],
  PIPEDRIVE_Deal: ['user_id', 'creator_user_id'],
};

export const DATE_FIELDS = {
  HUBSPOT_Deal: ['closedate', 'createdate'],
  HUBSPOT_User: [],
  HUBSPOT_Engagement: ['createdAt'],
  HUBSPOT_Contact: ['createdate'],
  HUBSPOT_Company: ['createdate', 'closedate', 'recent_deal_close_date'],
  SALESFORCE_User: [],
  SALESFORCE_Opportunity: ['CloseDate', 'CreatedDate'],
  SALESFORCE_Task: ['CreatedDate'],
  SELLSY_User: [],
  SELLSY_Opportunity: ['dueDate', 'date', 'created'],
  SELLSY_Invoice: ['created'],
  SELLSY_CreditNote: ['created'],
  SELLSY_Estimate: ['created'],
  PIPEDRIVE_User: [],
  PIPEDRIVE_Deal: ['close_time', 'won_time'],
};

export const CONNECTOR_CATEGORIES = {
  UNKNOWN: 'UNKNOWN',
  RAW_DATA: 'RAW_DATA',
  CRM: 'CRM',
  BILLING: 'BILLING',
  HR: 'HR',
};

export const CONNECTOR_DETAILS = {
  UNKNOWN: {
    category: CONNECTOR_CATEGORIES.UNKNOWN,
    iconComponent: (otherProps) => <BoxFilled {...otherProps} />,
  },
  HUBSPOT: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/hubspot_icon.png" {...otherProps} />),
  },
  PIPEDRIVE: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/pipedrive_icon.png" {...otherProps} />),
  },
  SALESFORCE: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/salesforce.png" {...otherProps} />),
  },
  SELLSY: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/sellsy_icon.png" {...otherProps} />),
  },
  QUICKBOOKS: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/quickbooks_icon.png" {...otherProps} />),
  },
  QUICKBOOKS_SANDBOX: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/quickbooks_icon.png" {...otherProps} />),
  },
  OUTREACH: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/outreach-icon.png" {...otherProps} />),
  },
  CLOSE: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/close-logo.png" {...otherProps} />),
  },
  STRIPE: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/stripe-logo.png" {...otherProps} />),
  },
  SAASOPTICS: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/saasoptics_logo.png" {...otherProps} />),
  },
  SAPLING: {
    category: CONNECTOR_CATEGORIES.HR,
    iconComponent: (otherProps) => (<Image src="/img/saplinghr.png" {...otherProps} />),
  },
  CHARGEBEE: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/chargebee-icon.png" {...otherProps} />),
  },
  XERO: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/xero-logo.png" {...otherProps} />),
  },
  GSHEET: {
    category: CONNECTOR_CATEGORIES.RAW_DATA,
    iconComponent: (otherProps) => (<Image src="/img/google-sheets-logo.png" {...otherProps} />),
  },
  CSV: {
    category: CONNECTOR_CATEGORIES.RAW_DATA,
    iconComponent: (otherProps) => (<TableOutlined {...otherProps} />),
  },
  MSSQL: {
    category: CONNECTOR_CATEGORIES.RAW_DATA,
    iconComponent: (otherProps) => (<Image src="/img/ms_sql.png" {...otherProps} />),
  },
  MYSQL: {
    category: CONNECTOR_CATEGORIES.RAW_DATA,
    iconComponent: (otherProps) => (<Image src="/img/mysql_logo.png" {...otherProps} />),
  },
  NETSUITE: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/netsuite.png" {...otherProps} />),
  },
  PENNYLANE: {
    category: CONNECTOR_CATEGORIES.BILLING,
    iconComponent: (otherProps) => (<Image src="/img/pennylane-logo.png" {...otherProps} />),
  },
  JOBADDER: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/jobadder-logo.png" {...otherProps} />),
  },
  CRELATE: {
    category: CONNECTOR_CATEGORIES.CRM,
    iconComponent: (otherProps) => (<Image src="/img/crelate-logo.png" {...otherProps} />),
  },
  SNOWFLAKE: {
    category: CONNECTOR_CATEGORIES.RAW_DATA,
    iconComponent: (otherProps) => (<Image src="/img/snowflake-logo.png" {...otherProps} />),
  },
  REDSHIFT: {
    category: CONNECTOR_CATEGORIES.RAW_DATA,
    iconComponent: (otherProps) => (<Image src="/img/redshift-logo.png" {...otherProps} />),
  },
};

export const CONNECTOR_RESOURCE_SEPARATOR = '||';
