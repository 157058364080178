export const STATUSES = {
  OFFBOARDED: 'OFFBOARDED',
  NOT_INVITED: 'NOT_INVITED',
  PENDING: 'PENDING',
  CONNECTED: 'CONNECTED',
};

export const ONBOARDING_USERS_FLOWS = {
  MANUAL_INPUT: 'MANUAL_INPUT',
  FROM_RESOURCES: 'FROM_RESOURCES',
};

export const ONBOARDING_USERS_FLOW_STEPS = {
  MANUAL_INPUT: {
    label: 'onboardUsersFlowSteps.manual_input.label',
    subTitle: 'onboardUsersFlowSteps.manual_input.subTitle',
    isDiscard: () => false,
    value: 'MANUAL_INPUT',
  },
  SELECT_RESOURCES: {
    label: 'onboardUsersFlowSteps.select_resources.label',
    subTitle: 'onboardUsersFlowSteps.select_resources.subTitle',
    isDiscard: () => false,
    value: 'SELECT_RESOURCES',
  },
  ADD_TO_TEAMS: {
    label: 'onboardUsersFlowSteps.add_to_teams.label',
    subTitle: 'onboardUsersFlowSteps.add_to_teams.subTitle',
    isDiscard: () => true,
    availableSoon: true,
    value: 'ADD_TO_TEAMS',
  },
  ADD_TO_PLANS: {
    label: 'onboardUsersFlowSteps.add_to_plans.label',
    subTitle: 'onboardUsersFlowSteps.add_to_plans.subTitle',
    isDiscard: () => true,
    availableSoon: true,
    value: 'ADD_TO_PLANS',
  },
  DEFINE_QUOTAS: {
    label: 'onboardUsersFlowSteps.define_quotas.label',
    subTitle: 'onboardUsersFlowSteps.define_quotas.subTitle',
    isDiscard: () => true,
    availableSoon: true,
    value: 'DEFINE_QUOTAS',
  },
  DEFINE_VARIABLES: {
    label: 'onboardUsersFlowSteps.define_variables.label',
    subTitle: 'onboardUsersFlowSteps.define_variables.subTitle',
    isDiscard: () => true,
    availableSoon: true,
    value: 'DEFINE_VARIABLES',
  },
  CHECK_DATA: {
    label: 'onboardUsersFlowSteps.check_data.label',
    subTitle: 'onboardUsersFlowSteps.check_data.subTitle',
    isDiscard: () => false,
    value: 'CHECK_DATA',
  },
};

export const ONBOARDING_USERS_MANUAL_INPUT_FLOW_FIRST_STEP = ONBOARDING_USERS_FLOW_STEPS.MANUAL_INPUT;
export const ONBOARDING_USERS_FROM_RESOURCES_FLOW_FIRST_STEP = ONBOARDING_USERS_FLOW_STEPS.SELECT_RESOURCES;

export const ONBOARDING_USERS_MANUAL_INPUT_FLOW_ORDERED_STEPS = [
  ONBOARDING_USERS_FLOW_STEPS.MANUAL_INPUT,
  ONBOARDING_USERS_FLOW_STEPS.CHECK_DATA,
];

export const ONBOARDING_USERS_FROM_RESOURCES_FLOW_ORDERED_STEPS = [
  ONBOARDING_USERS_FLOW_STEPS.SELECT_RESOURCES,
  ONBOARDING_USERS_FLOW_STEPS.ADD_TO_TEAMS,
  ONBOARDING_USERS_FLOW_STEPS.ADD_TO_PLANS,
  ONBOARDING_USERS_FLOW_STEPS.DEFINE_QUOTAS,
  ONBOARDING_USERS_FLOW_STEPS.DEFINE_VARIABLES,
  ONBOARDING_USERS_FLOW_STEPS.CHECK_DATA,
];

export const MERGE_USER_FIRST_QS_KEY = 'firstUserId';

export const MERGE_USER_SECOND_QS_KEY = 'secondUserId';

export const MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS = {
  EMAIL: 'email',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  CURRENCY: 'currency',
  JOIN_DATE: 'joinDate',
  LEAVE_DATE: 'leaveDate',
  PAYROLL_ID: 'payrollId',
  ACCOUNT: 'account',
};

export const MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS_I18N = {
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.EMAIL]: 'userDataSettings.form.email.label',
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.FIRST_NAME]: 'userDataSettings.form.firstName.label',
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.LAST_NAME]: 'userDataSettings.form.lastName.label',
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.CURRENCY]: 'userDataSettings.form.currency.label',
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.JOIN_DATE]: 'userDataSettings.form.hireDate.label',
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.LEAVE_DATE]: 'offboardUserModal.sections.leaveDate',
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.PAYROLL_ID]: 'userDataSettings.form.payrollId.label',
  [MERGE_USER_CONFLICT_DETAILS_DIFF_FIELDS.ACCOUNT]: 'userAccountSettings.title',
};

export const USER_VARIABLE_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  ARRAY: 'ARRAY',
};

export const USER_VARIABLE_DATE_FILTER_QS_KEY = 'date';
