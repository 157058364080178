import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import Checkbox from '@palette/components/designSystem/Checkbox/Checkbox';
import Select from '@palette/components/designSystem/Select/Select';
import MasterPlansListSelect from '@palette/components/masterPlan/MasterPlansListSelect/MasterPlansListSelect';
import Alert from '@palette/components/designSystem/Alert/Alert';

import {
  getAllPeriodsOptions,
  getDependencyRelativePeriodsOptions,
  getPlanLastPeriod,
  getPlanPeriodName,
  getRelativePeriod,
  isInFuture,
  isInPast,
  yearPeriodToString,
  yearPeriodStringToJSON, cleanInjectionFromPeriodMatchType,
} from '@palette/helpers/MasterPlanHelper';

import {
  AVAILABLE_PLAN_INJECTION_PERIOD_MATCH_STRATEGIES,
  PLAN_INJECTION_PERIOD_MATCH_STRATEGIES,
  PLAN_INJECTION_TYPES,
} from '@palette/constants/masterPlans';
import { VARIABLE_NAME_REGEX } from '@palette/constants/resources';
import { ALERT_TYPES } from '@palette/constants/alert';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanDependencyForm.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanDependencyForm = ({ className, plan, dependencyIndex, onSave }) => {
  const { t } = useTranslation();

  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);

  const injectionTypesOptions = Object.keys(PLAN_INJECTION_TYPES).map((injectionTypeKey) => ({
    label: t(`masterPlanDependencyForm.injectionTypes.${injectionTypeKey.toLowerCase()}`),
    value: PLAN_INJECTION_TYPES[injectionTypeKey],
  }));

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => {
    let dependency = {};
    if (dependencyIndex !== null) {
      dependency = plan.injections[dependencyIndex];
    }

    return ({
      name: dependency?.name ?? '',
      withDataPoints: dependency?.withDataPoints ?? false,
      withPayments: dependency?.withPayments ?? false,
      type: dependency?.type ?? PLAN_INJECTION_TYPES.MATCH_USER,
      planId: dependency?.planId ?? null,
      periodMatchType: dependency?.periodMatchType ?? PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.AUTO,
      relativePeriod: dependency?.relativePeriod ?? 0,
      absolutePeriod: dependency?.absolutePeriod ? yearPeriodToString(dependency.absolutePeriod) : null,
      periodFormula: dependency?.periodFormula ?? '',
    });
  }, [plan, dependencyIndex]);

  const [planIdValue, setPlanIdValue] = useState(initialValues.planId);
  const [periodMatchTypeValue, setPeriodMatchTypeValue] = useState(initialValues.periodMatchType);
  const [relativePeriodValue, setRelativePeriodValue] = useState(initialValues.relativePeriod);
  const [absolutePeriodValue, setAbsolutePeriodValue] = useState(initialValues.absolutePeriod);
  useEffect(() => {
    setPeriodMatchTypeValue(initialValues.periodMatchType);
    setPlanIdValue(initialValues.planId);
    setRelativePeriodValue(initialValues.relativePeriod);
    setAbsolutePeriodValue(initialValues.absolutePeriod);
  }, [initialValues]);

  const dependencyPlan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planIdValue }));

  const injectionPeriodMatchStrategiesOptions = useMemo(() => {
    let injectionPeriodMatchStrategies = AVAILABLE_PLAN_INJECTION_PERIOD_MATCH_STRATEGIES;

    if (dependencyPlan !== null && plan.id === dependencyPlan.id) {
      injectionPeriodMatchStrategies = _omit(AVAILABLE_PLAN_INJECTION_PERIOD_MATCH_STRATEGIES, ['AUTO']);
    }

    return Object.keys(injectionPeriodMatchStrategies).map((injectionPeriodMatchStrategyKey) => ({
      label: t(`masterPlanDependencyForm.injectionPeriodMatchStrategies.${injectionPeriodMatchStrategyKey.toLowerCase()}`),
      value: injectionPeriodMatchStrategies[injectionPeriodMatchStrategyKey],
    }));
  }, [plan, dependencyPlan]);

  const handleCheckboxChange = useCallback((event) => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      [event.target.id]: event.target.checked,
    });
  }, [form]);

  const getFormUpdatedData = useCallback((values) => {
    let finalValues = {
      ...initialValues,
      ...values,
      planId: planIdValue,
      periodMatchType: periodMatchTypeValue,
      relativePeriod: relativePeriodValue,
      absolutePeriod: absolutePeriodValue,
    };
    finalValues = cleanInjectionFromPeriodMatchType(finalValues);
    if (finalValues.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.ABSOLUTE) {
      finalValues.absolutePeriod = yearPeriodStringToJSON(finalValues.absolutePeriod);
    }

    let finalInitialValues = {
      ...initialValues,
    };
    finalInitialValues = cleanInjectionFromPeriodMatchType(finalInitialValues);
    if (finalInitialValues.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.ABSOLUTE) {
      finalInitialValues.absolutePeriod = yearPeriodStringToJSON(finalInitialValues.absolutePeriod);
    }

    const updatedData = Object.keys(finalValues).reduce((data, valKey) => {
      if (!_isEqual(finalInitialValues[valKey], finalValues[valKey])) {
        return {
          ...data,
          [valKey]: finalValues[valKey],
        };
      }

      return data;
    }, {});

    return [updatedData, finalValues, finalInitialValues];
  }, [
    initialValues,
    planIdValue,
    periodMatchTypeValue,
    relativePeriodValue,
    absolutePeriodValue,
  ]);

  const handleFinish = useCallback((values) => {
    const [, finalValues] = getFormUpdatedData(values);
    onSave(finalValues, dependencyIndex);
  }, [getFormUpdatedData, dependencyIndex, onSave]);

  const handleSaveDependency = () => form.submit();

  const withDataPointsOptionNode = useMemo(() => (
    <div className={styles.dependencyOption}>
      <div className={styles.dependencyOptionInfos}>
        <div className={styles.dependencyOptionTitle}>
          {t('masterPlanDependencyForm.form.withDataPoints.label')}
        </div>
        <div className={styles.dependencyOptionDescription}>
          {t('masterPlanDependencyForm.form.withDataPoints.description')}
        </div>
      </div>
      <div className={styles.dependencyOptionForm}>
        <FormItem
          className={styles.dependencyOptionFormItem}
          name="withDataPoints"
        >
          <Checkbox disabled={updatePlanIsPending} onChange={handleCheckboxChange} />
        </FormItem>
      </div>
    </div>
  ), [updatePlanIsPending, handleCheckboxChange]);

  const withPaymentsOptionNode = useMemo(() => (
    <div className={styles.dependencyOption}>
      <div className={styles.dependencyOptionInfos}>
        <div className={styles.dependencyOptionTitle}>
          {t('masterPlanDependencyForm.form.withPayments.label')}
        </div>
        <div className={styles.dependencyOptionDescription}>
          {t('masterPlanDependencyForm.form.withPayments.description')}
        </div>
      </div>
      <div className={styles.dependencyOptionForm}>
        <FormItem
          className={styles.dependencyOptionFormItem}
          name="withPayments"
        >
          <Checkbox disabled={updatePlanIsPending} onChange={handleCheckboxChange} />
        </FormItem>
      </div>
    </div>
  ), [updatePlanIsPending, handleCheckboxChange]);

  const typeOptionNode = useMemo(() => (
    <div className={styles.dependencyOption}>
      <div
        className={classNames({
          dependencyOptionInfos: true,
          typeOptionInfos: true,
        })}
      >
        <div className={styles.dependencyOptionTitle}>
          {t('masterPlanDependencyForm.form.type.label')}
        </div>
        <div className={styles.dependencyOptionDescription}>
          {t('masterPlanDependencyForm.form.type.description')}
        </div>
      </div>
      <div className={styles.dependencyOptionForm}>
        <FormItem
          className={styles.dependencyOptionFormItem}
          name="type"
        >
          <Select className={styles.typeOptionSelect} options={injectionTypesOptions} disabled={updatePlanIsPending} />
        </FormItem>
      </div>
    </div>
  ), [injectionTypesOptions, updatePlanIsPending]);

  const dependencyOptionsNode = useMemo(() => (
    <div className={styles.dependencyOptions}>
      {withDataPointsOptionNode}
      {withPaymentsOptionNode}
      {typeOptionNode}
    </div>
  ), [withDataPointsOptionNode, withPaymentsOptionNode, typeOptionNode]);

  const deleteActionNode = useMemo(() => {
    if (dependencyIndex !== null) {
      return (
        <div className={styles.deleteAction}>
          {t('masterPlanDependencyForm.deleteAction.description')}
          <Button
            className={styles.deleteActionBtn}
            type="linkDestroy"
            icon={(<TrashFilled width={16} height={16} />)}
            onClick={() => onSave(null, dependencyIndex)}
            disabled={updatePlanIsPending}
          >
            {t('masterPlanDependencyForm.deleteAction.btnLabel')}
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.deleteAction}>
        <Button
          className={styles.deleteActionBtn}
          type="linkDestroy"
          icon={(<TrashFilled width={16} height={16} />)}
          onClick={() => onSave(null, dependencyIndex)}
          disabled={updatePlanIsPending}
        >
          {t('masterPlanDependencyForm.cancelAction.btnLabel')}
        </Button>
      </div>
    );
  }, [dependencyIndex, onSave, updatePlanIsPending]);

  const handlePlanChange = useCallback((newPlanId) => {
    let newPeriodMatchType = PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.AUTO;
    let newRelativePeriod = 0;
    const newAbsolutePeriod = null;

    if (plan.id === newPlanId) {
      newPeriodMatchType = PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.RELATIVE;
      newRelativePeriod = -1;
    }

    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      periodMatchType: newPeriodMatchType,
      relativePeriod: newRelativePeriod,
      absolutePeriod: newAbsolutePeriod,
    });

    setPlanIdValue(newPlanId);
    setPeriodMatchTypeValue(newPeriodMatchType);
    setRelativePeriodValue(newRelativePeriod);
    setAbsolutePeriodValue(newAbsolutePeriod);
  }, [form, plan]);

  const handlePeriodMatchTypeChange = useCallback((newPeriodMatchType) => {
    let newRelativePeriod = 0;
    const newAbsolutePeriod = null;

    if (newPeriodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.RELATIVE) {
      newRelativePeriod = -1;
    }

    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      relativePeriod: newRelativePeriod,
      absolutePeriod: newAbsolutePeriod,
    });

    setPeriodMatchTypeValue(newPeriodMatchType);
    setRelativePeriodValue(newRelativePeriod);
    setAbsolutePeriodValue(newAbsolutePeriod);
  }, [form, plan]);

  const handleFormValuesChange = useCallback((changedValues) => {
    if (changedValues.planId !== undefined) {
      handlePlanChange(changedValues.planId);
    }

    if (changedValues.periodMatchType !== undefined) {
      handlePeriodMatchTypeChange(changedValues.periodMatchType);
    }

    if (changedValues.relativePeriod !== undefined) {
      setRelativePeriodValue(changedValues.relativePeriod);
    }

    if (changedValues.absolutePeriod !== undefined) {
      setAbsolutePeriodValue(changedValues.absolutePeriod);
    }
  }, [handlePlanChange, handlePeriodMatchTypeChange]);

  const strategyValueNode = useMemo(() => {
    if (
      dependencyPlan === null
      || periodMatchTypeValue === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.AUTO
      || periodMatchTypeValue === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.FORMULA // FORMULA strategy is not managed for now in the app
    ) return null;

    const isAnAutoInjection = plan.id === dependencyPlan.id;

    if (periodMatchTypeValue === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.ABSOLUTE) {
      const allPeriodsOptions = getAllPeriodsOptions(dependencyPlan, isAnAutoInjection);

      return (
        <FormItem
          className={styles.strategyFormItem}
          name="absolutePeriod"
          label={t('masterPlanDependencyForm.form.absolutePeriod.label')}
          required
          rules={[
            { required: true, message: t('masterPlanDependencyForm.form.absolutePeriod.rules.required') },
          ]}
        >
          <Select
            size="big"
            options={allPeriodsOptions}
            disabled={updatePlanIsPending}
          />
        </FormItem>
      );
    }

    const planLastPeriod = getPlanLastPeriod(plan);
    const lastPeriodName = getPlanPeriodName(plan, planLastPeriod);

    if (plan.periodType === PERIOD_TYPES.DAY) {
      const relativeSelectedPeriod = getRelativePeriod(plan, dependencyPlan, relativePeriodValue);
      const relativePeriodName = getPlanPeriodName(dependencyPlan, relativeSelectedPeriod);

      return (
        <div
          className={classNames({
            strategyFormItem: true,
            relativePeriodWithExample: true,
          })}
        >
          <div className={styles.formItemAndExample}>
            <FormItem
              className={styles.strategyFormItem}
              name="relativePeriod"
              label={t('masterPlanDependencyForm.form.relativePeriod.label')}
              required
              rules={[
                { required: true, message: t('masterPlanDependencyForm.form.relativePeriod.rules.required') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || (getFieldValue('relativePeriod') < 0)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('masterPlanDependencyForm.form.relativePeriod.rules.isNegative')));
                  },
                }),
              ]}
            >
              <Input
                size="big"
                type="number"
                max={-1}
                controls={false}
                disabled={updatePlanIsPending}
              />
            </FormItem>
            <div className={styles.example}>
              {`P${relativePeriodValue} (ex: ${relativePeriodName})`}
            </div>
          </div>
          <div className={styles.description}>
            {t('masterPlanDependencyForm.form.relativePeriod.description', { lastPeriodName })}
          </div>
        </div>
      );
    }

    const relativePeriodsOptions = getDependencyRelativePeriodsOptions(plan, dependencyPlan);
    return (
      <FormItem
        className={styles.strategyFormItem}
        name="relativePeriod"
        label={t('masterPlanDependencyForm.form.relativePeriod.label')}
        required
        rules={[
          { required: true, message: t('masterPlanDependencyForm.form.relativePeriod.rules.required') },
        ]}
        extra={t('masterPlanDependencyForm.form.relativePeriod.description', { lastPeriodName })}
      >
        <Select
          size="big"
          options={relativePeriodsOptions}
          disabled={updatePlanIsPending}
          showSearch
        />
      </FormItem>
    );
  }, [
    periodMatchTypeValue,
    plan,
    dependencyPlan,
    updatePlanIsPending,
    relativePeriodValue,
  ]);

  const warningNode = useMemo(() => {
    if (dependencyPlan === null || plan.id === dependencyPlan.id) return null;

    if (isInFuture(dependencyPlan)) {
      return (
        <Alert
          className={styles.futurePastAlert}
          type={ALERT_TYPES.INFO}
          message={t('masterPlanDependencyForm.alerts.planIsInFuture')}
        />
      );
    }

    if (isInPast(dependencyPlan)) {
      return (
        <Alert
          className={styles.futurePastAlert}
          type={ALERT_TYPES.INFO}
          message={t('masterPlanDependencyForm.alerts.planIsInPast')}
        />
      );
    }

    return null;
  }, [plan, dependencyPlan]);

  const strategyNode = useMemo(() => {
    if (plan.periodType === PERIOD_TYPES.CUSTOM || dependencyPlan === null) return null;

    return (
      <div className={styles.strategyWrapper}>
        <FormItem
          className={styles.strategyFormItem}
          name="periodMatchType"
          label={t('masterPlanDependencyForm.form.periodMatchType.label')}
          required
        >
          <Select
            size="big"
            options={injectionPeriodMatchStrategiesOptions}
            disabled={updatePlanIsPending}
          />
        </FormItem>
        {strategyValueNode}
      </div>
    );
  }, [
    plan,
    dependencyPlan,
    injectionPeriodMatchStrategiesOptions,
    updatePlanIsPending,
    strategyValueNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>
        {t('masterPlanDependencyForm.title')}
      </div>
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <div className={styles.nameAndPlan}>
          <FormItem
            className={styles.nameFormItem}
            name="name"
            label={t('masterPlanDependencyForm.form.name.label')}
            required
            rules={[
              { required: true, message: t('masterPlanDependencyForm.form.name.rules.required') },
              { pattern: VARIABLE_NAME_REGEX, message: t('masterPlanDependencyForm.form.name.rules.pattern') },
            ]}
          >
            <Input size="big" placeholder={t('masterPlanDependencyForm.form.name.placeholder')} disabled={updatePlanIsPending} />
          </FormItem>
          <FormItem
            className={styles.planFormItem}
            name="planId"
            label={t('masterPlanDependencyForm.form.plan.label')}
            required
            rules={[
              { required: true, message: t('masterPlanDependencyForm.form.plan.rules.required') },
            ]}
          >
            <MasterPlansListSelect
              size="big"
              disabled={updatePlanIsPending}
              filterOnPeriodType={plan.periodType}
            />
          </FormItem>
        </div>
        {warningNode}
        {strategyNode}
        <Collapse
          panels={[
            {
              title: (
                <div className={styles.dependencyOptionsCollapseTitle}>
                  <AddFilled className={styles.dependencyOptionsCollapseTitleIcon} width={20} height={20} />
                  {t('masterPlanDependencyForm.addDependencyOptions')}
                </div>
              ),
              content: dependencyOptionsNode,
            },
          ]}
        />
        <div className={styles.actions}>
          <Button className={styles.submitBtn} onClick={handleSaveDependency} disabled={updatePlanIsPending}>
            {t('masterPlanDependencyForm.form.submitBtnLabel')}
          </Button>
          {deleteActionNode}
        </div>
      </Form>
    </div>
  );
};

MasterPlanDependencyForm.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  dependencyIndex: PropTypes.number,
  onSave: PropTypes.func.isRequired,
};

MasterPlanDependencyForm.defaultProps = {
  className: '',
  dependencyIndex: null,
};

export default MasterPlanDependencyForm;
