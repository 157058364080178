import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Avatar as AntDAvatar } from 'antd';

import stringToColor from 'string-to-color';

import PaletteLogo from '@palette/components/utils/PaletteLogo/PaletteLogo';

import { PALETTE_USER_AVATAR_SIZE } from '@palette/constants/global';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './Avatar.less';

const classNames = bindClassNames.bind(styles);

const Avatar = ({ className, size, user, isPaletteUser, style }) => {
  const finalSize = useMemo(() => {
    if (size !== 'small') return size;

    if (isPaletteUser) return PALETTE_USER_AVATAR_SIZE;

    return size;
  }, [size, isPaletteUser]);

  const contentNode = useMemo(() => {
    if (isPaletteUser) {
      return (
        <PaletteLogo
          className={styles.avatar}
          logoOnly
          width={finalSize}
          height={finalSize}
        />
      );
    }

    const userProfilePictureUrl = user.profilePictureUrl;

    let avatarContentNode = null;

    if (userProfilePictureUrl === null) {
      avatarContentNode = user.displayName.substring(0, 1).toUpperCase();
    }

    return (
      <AntDAvatar
        className={styles.avatar}
        draggable={false}
        shape="circle"
        size={finalSize}
        src={userProfilePictureUrl}
        style={{
          backgroundColor: userProfilePictureUrl ? 'transparent' : stringToColor(user.id),
          lineHeight: '2.2rem',
          ...style,
        }}
      >
        {avatarContentNode}
      </AntDAvatar>
    );
  }, [user, finalSize, style, isPaletteUser]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      style={{ width: finalSize, height: finalSize }}
    >
      {contentNode}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user: MetaUserModel.propTypes.isRequired,
  isPaletteUser: PropTypes.bool,
};

Avatar.defaultProps = {
  className: '',
  style: {},
  size: 'small',
  isPaletteUser: false,
};

export default Avatar;
