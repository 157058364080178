import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import Button from '@palette/components/designSystem/Button/Button';
import QueryBuilderAndOrBlock from '@palette/components/designSystem/QueryBuilderAndOrBlock/QueryBuilderAndOrBlock';
import QueryBuilderDisplayedAndOrBlock from '@palette/components/designSystem/QueryBuilderDisplayedAndOrBlock.js/QueryBuilderDisplayedAndOrBlock';

import { getQueryBuilderObjectValue } from '@palette/helpers/QueryBuilderHelper';

import {
  ALTERNATIVE_INITIAL_QUERY_BUILDER_VALUE,
  DEFAULT_CONDITION_VALUE_KEY,
  INITIAL_QUERY_BUILDER_VALUE,
} from '@palette/constants/queryBuilder';

import styles from './QueryBuilder.less';

const classNames = bindClassNames.bind(styles);

const QueryBuilder = ({
  className,
  helperData,
  emptyLabel,
  value,
  onChange,
  prefix,
  disabled,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState(getQueryBuilderObjectValue(value));
  useEffect(() => setInternalValue(getQueryBuilderObjectValue(value)), [value]);

  const [displayEditor, showEditor] = useState(false);

  const handleChange = (newValue) => {
    setInternalValue(newValue);

    if (onChange !== null) {
      const stringifiedNewValue = JSON.stringify(newValue).replace(DEFAULT_CONDITION_VALUE_KEY, prefix);
      onChange(stringifiedNewValue);
    }
  };

  const displayedQueryNode = useMemo(() => {
    const finalEmptyLabel = emptyLabel || t('queryBuilder.empty');

    if (
      internalValue === ''
      || _isEqual(internalValue, INITIAL_QUERY_BUILDER_VALUE)
      || _isEqual(internalValue, ALTERNATIVE_INITIAL_QUERY_BUILDER_VALUE)
    ) {
      return finalEmptyLabel;
    }

    return (
      <QueryBuilderDisplayedAndOrBlock
        value={internalValue}
        prefix={prefix}
      />
    );
  }, [internalValue, prefix]);

  const displayedQueryBtnNode = useMemo(() => {
    if (displayEditor) {
      return (
        <Button
          type="primaryBlue"
          onClick={() => showEditor(false)}
          disabled={disabled}
        >
          {t('queryBuilder.hideEditor')}
        </Button>
      );
    }

    return (
      <Button
        onClick={() => showEditor(true)}
        disabled={disabled}
      >
        {t('queryBuilder.showEditor')}
      </Button>
    );
  }, [displayEditor, disabled]);

  const editorNode = useMemo(() => {
    if (!displayEditor) return null;

    return (
      <QueryBuilderAndOrBlock
        className={styles.editor}
        value={internalValue}
        onChange={handleChange}
        helperData={helperData}
        prefix={prefix}
        disabled={disabled}
      />
    );
  }, [displayEditor, internalValue, helperData, prefix, disabled]);

  return (
    <div
      className={classNames({
        wrapper: true,
        isOpen: displayEditor,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      <div className={styles.displayedQueryWrapper}>
        <div className={styles.displayedQuery}>
          {displayedQueryNode}
        </div>
        {displayedQueryBtnNode}
      </div>
      {editorNode}
    </div>
  );
};

QueryBuilder.propTypes = {
  className: PropTypes.string,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  emptyLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
};

QueryBuilder.defaultProps = {
  className: '',
  emptyLabel: null,
  value: '',
  onChange: null,
  prefix: '',
  disabled: false,
};

export default QueryBuilder;
