import { call, delay } from 'redux-saga/effects';

import * as AsyncJobsService from '@palette/services/AsyncJobsService';

import { manageError as manageAsyncJobsError } from '@palette/state/AsyncJobs/errors';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as AsyncJobModel from '@palette/models/AsyncJob';

import appConfig from '@palette/config/app';

import { STATUSES } from '@palette/constants/asyncJobs';

export const jobInProgress = (job) => {
  const moment = getMoment();
  return (
    job.status !== STATUSES.DONE
    && job.status !== STATUSES.ERRORED
    && (
      moment().diff(moment(job.lastActivityAt), 'seconds') < appConfig.ASYNC_JOB_PROGRESS_DELAY
    )
  );
};

export function* waitJob({ job, successSaga, errorSaga, completedSaga }) {
  let currentJob = job;

  while (jobInProgress(currentJob)) {
    const callResult = yield call(AsyncJobsService.getById, { jobId: currentJob.id });

    if (callResult.ok) {
      currentJob = AsyncJobModel.transform(callResult.data);
    } else {
      const { message } = manageAsyncJobsError(callResult);

      yield call(errorSaga, {
        job: currentJob,
        error: {
          id: currentJob.id,
          message,
        },
      });

      return;
    }

    yield delay(appConfig.ASYNC_JOB_POLLING_SLEEP_DURATION);
  }

  if (currentJob.status === STATUSES.ERRORED) {
    yield call(errorSaga, {
      job: currentJob,
      error: {
        id: currentJob.id,
        message: JSON.parse(currentJob.error),
      },
    });
  }

  if (currentJob.status === STATUSES.DONE) {
    yield call(successSaga, {
      job: currentJob,
      enableSuccessNotification: currentJob.enableSuccessNotification,
      enableReload: currentJob.enableReload,
    });
  }

  yield call(completedSaga);
}
