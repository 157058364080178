import _orderBy from 'lodash/orderBy';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';

import getI18n from '@palette/i18n';

import { scrollToPosition } from '@palette/helpers/NavigationHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import {
  buildFrequencyOptionValue,
  getCustomFrequencyPeriodName,
  getCustomPeriodTypeFrequencyName,
  getFrequencyPeriodName,
  getMomentDateFromPeriod,
  getNextPeriod,
  getPeriodForMomentDate,
  getPeriodTypeFrequencyName,
  getPreviousPeriod,
} from '@palette/helpers/FrequencyHelper';

import {
  AVAILABLE_MONTH_FREQUENCIES_OPTIONS,
  FORMATTERS_VALUES,
  PERIODS_FILTER_TYPE,
  PERIOD_PROGRESSBAR_TYPES,
  CREATE_PLAN_FLOW_ORDERED_STEPS,
  CREATE_PLAN_FLOW_FIRST_STEP,
  PLAN_INJECTION_PERIOD_MATCH_STRATEGIES,
  SCOPES,
  DEFAULT_PERIOD_VALUE,
  RULE_TYPES,
} from '@palette/constants/masterPlans';
import { DAY_FREQUENCIES, OTHER_FREQUENCIES, PERIOD_TYPES } from '@palette/constants/frequencies';
import { FOLDER_TYPES, DEFAULT_FOLDER_ID } from '@palette/constants/folders';
import { CHART_GRADIENT_GREEN, CHART_GRADIENT_ORANGE, CHART_GRADIENT_RED } from '@palette/constants/charts';
import { APP_BOTTOM_ID } from '@palette/constants/navigation';
import { PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import * as FolderModel from '@palette/models/Folder';

export const getFrequencyName = (plan) => {
  if (plan.periodType === PERIOD_TYPES.CUSTOM) return getCustomPeriodTypeFrequencyName(plan.customBeginAt, plan.frequency);

  return getPeriodTypeFrequencyName(plan.periodType, plan.frequency);
};

export const getPlanPeriodName = (plan = null, period = null, useShortMonth = false, showYear = true) => {
  if (plan === null) return '';

  if (plan.periodType === PERIOD_TYPES.CUSTOM) {
    return getCustomFrequencyPeriodName(plan.customBeginAt, plan.frequency);
  }

  if (period === null) {
    return '';
  }

  return getFrequencyPeriodName(plan.periodType, plan.frequency, period.year, period.period, plan.customBeginAt, useShortMonth, plan.fiscalYearShift, showYear);
};

export const getPlanValueDefinition = (plan) => {
  const i18n = getI18n();
  return (plan?.trackingObject?.valueDefinition || i18n.t('common.global.value'));
};

export const getDefaultPeriodValue = (periodType, frequency) => {
  if (periodType === PERIOD_TYPES.MONTH) {
    const moment = getMoment();

    const startOfMonthPeriod = moment().startOf('month').format('M');

    const defaultPeriodValue = {
      year: moment().year(),
      period: Number(startOfMonthPeriod),
    };

    // Quarter frequency
    if (frequency === 3) {
      const startOfQuarterPeriod = moment().startOf('quarter').format('M');

      if (startOfQuarterPeriod >= 10) {
        defaultPeriodValue.period = 4;
      } else if (startOfQuarterPeriod >= 7) {
        defaultPeriodValue.period = 3;
      } else if (startOfQuarterPeriod >= 4) {
        defaultPeriodValue.period = 2;
      } else {
        defaultPeriodValue.period = 1;
      }
    }

    // Semester frequency
    if (frequency === 6) {
      const currentMonth = moment().format('M');

      if (currentMonth >= 7) {
        defaultPeriodValue.period = 2;
      } else {
        defaultPeriodValue.period = 1;
      }
    }

    if (frequency === 12) {
      defaultPeriodValue.period = 1;
    }

    return defaultPeriodValue;
  }

  return DEFAULT_PERIOD_VALUE;
};

export const getPeriodElapsedTimePercentage = (plan, beginPeriod, endPeriod = null) => {
  const moment = getMoment();

  const beginOfPeriod = getMomentDateFromPeriod(plan.periodType, plan.frequency, beginPeriod, plan.customBeginAt, false, plan.fiscalYearShift);
  const endOfPeriod = getMomentDateFromPeriod(plan.periodType, plan.frequency, endPeriod || beginPeriod, plan.customBeginAt, true, plan.fiscalYearShift);

  const now = moment();

  if (beginOfPeriod.isAfter(now)) return 0;

  const elapsedPeriodTime = now.diff(beginOfPeriod);
  const totalPeriodTime = endOfPeriod.diff(beginOfPeriod);

  const p = Math.round((elapsedPeriodTime / totalPeriodTime) * 100);
  return Math.min(p, 100);
};

export const getPeriodProgressBarType = (elapsedTimePercentage, progressPercentage) => {
  if (progressPercentage > elapsedTimePercentage) {
    return PERIOD_PROGRESSBAR_TYPES.SUCCESS;
  }

  if (progressPercentage < (elapsedTimePercentage - 20)) {
    return PERIOD_PROGRESSBAR_TYPES.DANGER;
  }

  return PERIOD_PROGRESSBAR_TYPES.WARNING;
};

export const getPeriodProgressChartGradient = (elapsedTimePercentage, progressPercentage) => {
  const barType = getPeriodProgressBarType(elapsedTimePercentage, progressPercentage);

  switch (barType) {
    case PERIOD_PROGRESSBAR_TYPES.SUCCESS:
      return CHART_GRADIENT_GREEN;
    case PERIOD_PROGRESSBAR_TYPES.DANGER:
      return CHART_GRADIENT_RED;
    case PERIOD_PROGRESSBAR_TYPES.WARNING:
    default:
      return CHART_GRADIENT_ORANGE;
  }
};

export const getPlanFirstPeriod = (masterPlan) => {
  const moment = getMoment();

  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM || masterPlan.periodType === PERIOD_TYPES.DAY) {
    return {
      period: 1,
      year: moment(masterPlan.customBeginAt).utc().year(),
    };
  }

  return masterPlan.beginPeriod;
};

export const getPlanLastPeriod = (masterPlan) => {
  const moment = getMoment();

  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM) {
    return {
      period: 1,
      year: moment(masterPlan.customBeginAt).utc().year(),
    };
  }

  let currentPeriod = null;

  if (masterPlan.periodType === PERIOD_TYPES.DAY) {
    const now = moment().utc();
    const lastDay = moment(now).endOf('day');

    let beginPeriodMomentDate = moment(masterPlan.customBeginAt).utc().startOf('day').add(12, 'hours');
    let endPeriodMomentDate = moment(beginPeriodMomentDate).add(masterPlan.frequency - 1, 'days');
    let periodNb = 1;

    while (endPeriodMomentDate.isBefore(lastDay)) {
      beginPeriodMomentDate = moment(beginPeriodMomentDate).add(masterPlan.frequency, 'days');
      endPeriodMomentDate = moment(beginPeriodMomentDate).add(masterPlan.frequency - 1, 'days');
      periodNb += 1;
    }

    currentPeriod = {
      period: periodNb,
      year: beginPeriodMomentDate.utc().year(),
    };
  } else {
    const now = moment().utc();
    const currentMonthWithShift = moment(now).utc().subtract(masterPlan.fiscalYearShift, 'month').month();

    const lastPeriodYear = moment(now).utc().year();
    const lastPeriodPeriod = Math.ceil((currentMonthWithShift + 1) / masterPlan.frequency);

    currentPeriod = {
      period: lastPeriodPeriod,
      year: lastPeriodYear,
    };
  }

  if (masterPlan.endPeriod !== null) {
    const currentPeriodMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, currentPeriod, masterPlan.customBeginAt, false, masterPlan.fiscalYearShift);
    const endPeriodMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, masterPlan.endPeriod, masterPlan.customBeginAt, false, masterPlan.fiscalYearShift);

    if (currentPeriodMomentDate.isSameOrBefore(endPeriodMomentDate)) {
      return currentPeriod;
    }

    return masterPlan.endPeriod;
  }

  return currentPeriod;
};

export const isInPast = (masterPlan) => {
  const moment = getMoment();
  const now = moment().utc();

  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM) {
    const periodEndMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, null, masterPlan.customBeginAt, true, masterPlan.fiscalYearShift);

    return periodEndMomentDate.utc().isBefore(now);
  }

  if (masterPlan.endPeriod !== null) {
    const periodEndMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, masterPlan.endPeriod, masterPlan.customBeginAt, true, masterPlan.fiscalYearShift);

    return periodEndMomentDate.utc().isBefore(now);
  }

  return false;
};

export const isInFuture = (masterPlan) => {
  const moment = getMoment();
  const now = moment().utc();

  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM || masterPlan.periodType === PERIOD_TYPES.DAY) {
    const beginDate = moment(masterPlan.customBeginAt).utc();

    return beginDate.isAfter(now);
  }

  const periodBeginMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, masterPlan.beginPeriod, masterPlan.customBeginAt, false, masterPlan.fiscalYearShift);

  return periodBeginMomentDate.utc().isAfter(now);
};

export const isDraft = (masterPlan) => masterPlan?.creationFlowStep !== null;

export const isArchived = (masterPlan) => masterPlan?.archived ?? false;

export const getAvailablePlanFrequenciesOptions = () => {
  const i18n = getI18n();

  const monthOptions = Object.keys(AVAILABLE_MONTH_FREQUENCIES_OPTIONS).map((monthFreqKey) => ({
    label: i18n.t(AVAILABLE_MONTH_FREQUENCIES_OPTIONS[monthFreqKey]),
    value: buildFrequencyOptionValue(PERIOD_TYPES.MONTH, monthFreqKey),
  }));

  const dayOptions = [
    {
      label: i18n.t(DAY_FREQUENCIES.DAY),
      value: PERIOD_TYPES.DAY,
    },
  ];

  const customOptions = [
    {
      label: i18n.t(OTHER_FREQUENCIES.CUSTOM),
      value: PERIOD_TYPES.CUSTOM,
    },
  ];

  return monthOptions.concat(dayOptions, customOptions);
};

export const periodIsFrozen = (plan, period) => {
  if (plan.lastFrozenPeriod === null) return false;

  if (plan.periodType === PERIOD_TYPES.CUSTOM) {
    const moment = getMoment();
    const beginDate = moment(plan.customBeginAt).utc();
    return plan.lastFrozenPeriod.year === beginDate.year() && plan.lastFrozenPeriod.period === 1;
  }

  if (period === null) return false;

  if (plan.periodType === PERIOD_TYPES.DAY) {
    return period.period <= plan.lastFrozenPeriod.period;
  }

  return (
    period.year < plan.lastFrozenPeriod.year
    || (
      period.year === plan.lastFrozenPeriod.year
      && period.period <= plan.lastFrozenPeriod.period
    )
  );
};

export const isPlanAutoFreezeEnabled = (plan) => plan.autofreeze?.enabled || false;

export const getNbOfPeriodFromGivenPeriod = (masterPlan, fromPeriod, isFirstPeriod = false) => {
  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM) {
    return 1;
  }

  const planLastPeriod = getPlanLastPeriod(masterPlan);

  if (masterPlan.periodType === PERIOD_TYPES.DAY) {
    const moment = getMoment();

    const fromMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, fromPeriod, masterPlan.customBeginAt, !isFirstPeriod, masterPlan.fiscalYearShift);
    const toMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, planLastPeriod, masterPlan.customBeginAt, true, masterPlan.fiscalYearShift);

    const diffNbDays = moment(toMomentDate).diff(moment(fromMomentDate), 'days');

    return Math.ceil(diffNbDays / masterPlan.frequency);
  }

  const nbPeriods = 12 / masterPlan.frequency;

  const diffNbYears = planLastPeriod.year - fromPeriod.year;
  let nbPeriodsDoneInFromPeriod = fromPeriod.period;
  if (isFirstPeriod) {
    nbPeriodsDoneInFromPeriod -= 1;
  }
  let diffNbPeriods = diffNbYears * nbPeriods - nbPeriodsDoneInFromPeriod;
  const nbPeriodsDoneInLastPeriod = planLastPeriod.period;
  diffNbPeriods += nbPeriodsDoneInLastPeriod;

  return diffNbPeriods;
};

export const getNbOfPeriodsSinceLastFrozenPeriod = (masterPlan) => {
  const planFirstPeriod = getPlanFirstPeriod(masterPlan);

  if (masterPlan.lastFrozenPeriod === null) {
    return getNbOfPeriodFromGivenPeriod(masterPlan, planFirstPeriod, true);
  }

  const lastFrozenPeriodMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, masterPlan.lastFrozenPeriod, masterPlan.customBeginAt, false, masterPlan.fiscalYearShift);
  const planFirstPeriodMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, planFirstPeriod, masterPlan.customBeginAt, false, masterPlan.fiscalYearShift);

  let fromPeriod = masterPlan.lastFrozenPeriod;
  let isFirstPeriod = false;
  if (lastFrozenPeriodMomentDate.isBefore(planFirstPeriodMomentDate)) {
    fromPeriod = planFirstPeriod;
    isFirstPeriod = true;
  }

  return getNbOfPeriodFromGivenPeriod(masterPlan, fromPeriod, isFirstPeriod);
};

export const yearPeriodToString = (yearPeriod) => JSON.stringify({ year: yearPeriod.year, period: yearPeriod.period });
export const yearPeriodStringToJSON = (yearPeriodString) => JSON.parse(yearPeriodString);

export const getAllPeriodsOptions = (masterPlan, isAnAutoInjection = false) => {
  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM) {
    const customPeriod = getPlanFirstPeriod(masterPlan);

    return [{
      label: getPlanPeriodName(masterPlan, customPeriod),
      value: yearPeriodToString(customPeriod),
    }];
  }

  if (masterPlan.periodType === PERIOD_TYPES.DAY) {
    const firstPeriod = getPlanFirstPeriod(masterPlan);
    const lastPeriod = getPlanLastPeriod(masterPlan);

    let loopStart = lastPeriod.period;
    if (isAnAutoInjection) {
      loopStart = lastPeriod.period - 1;
    }

    const options = [];

    for (let i = loopStart; i > (firstPeriod.period - 1); i -= 1) {
      const optionPeriod = {
        year: firstPeriod.year,
        period: i,
      };

      options.push({
        label: getPlanPeriodName(masterPlan, optionPeriod),
        value: yearPeriodToString(optionPeriod),
      });
    }

    return options;
  }

  const firstPeriod = getPlanFirstPeriod(masterPlan);
  const lastPeriod = getPlanLastPeriod(masterPlan);

  const options = [];

  let optionPeriod = lastPeriod;
  if (isAnAutoInjection) {
    optionPeriod = getPreviousPeriod(masterPlan.periodType, masterPlan.frequency, lastPeriod.year, lastPeriod.period);
  }

  while (
    optionPeriod.year > firstPeriod.year
    || (
      optionPeriod.year === firstPeriod.year
      && optionPeriod.period >= firstPeriod.period
    )
  ) {
    options.push({
      label: getPlanPeriodName(masterPlan, optionPeriod),
      value: yearPeriodToString(optionPeriod),
    });

    optionPeriod = getPreviousPeriod(masterPlan.periodType, masterPlan.frequency, optionPeriod.year, optionPeriod.period);
  }

  return options;
};

export const getDependencyRelativePeriodsOptions = (masterPlan, dependencyMasterPlan) => {
  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM || masterPlan.periodType === PERIOD_TYPES.DAY) {
    return []; // should not happen
  }

  const lastPeriod = getPlanLastPeriod(masterPlan);
  const lastPeriodMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, lastPeriod, masterPlan.customBeginAt, false, masterPlan.fiscalYearShift);

  const dependencyPlanPeriod = getPeriodForMomentDate(
    lastPeriodMomentDate,
    dependencyMasterPlan.periodType,
    dependencyMasterPlan.frequency,
    dependencyMasterPlan.customBeginAt,
    dependencyMasterPlan.fiscalYearShift,
  );

  if (dependencyPlanPeriod !== null) {
    const i18n = getI18n();

    let nbOptionsToDisplay = 12 / dependencyMasterPlan.frequency;
    if (dependencyMasterPlan.frequency > 2) {
      nbOptionsToDisplay *= 2;
    }

    const inThePastOptions = [];

    let optionPeriod = dependencyPlanPeriod;

    for (let i = 0; i < nbOptionsToDisplay; i += 1) {
      const value = (i + 1) * (-1);

      optionPeriod = getPreviousPeriod(dependencyMasterPlan.periodType, dependencyMasterPlan.frequency, optionPeriod.year, optionPeriod.period);

      inThePastOptions.push({
        label: `P${value} (ex: ${getPlanPeriodName(dependencyMasterPlan, optionPeriod)})`,
        value,
      });
    }

    const inTheFutureOptions = [];

    const isSamePlan = masterPlan.id === dependencyMasterPlan.id;
    if (!isSamePlan) {
      inTheFutureOptions.push({
        label: `P+0 (ex: ${getPlanPeriodName(dependencyMasterPlan, dependencyPlanPeriod)})`,
        value: 0,
      });

      optionPeriod = dependencyPlanPeriod;

      for (let i = 0; i < nbOptionsToDisplay; i += 1) {
        const value = i + 1;

        optionPeriod = getNextPeriod(dependencyMasterPlan.periodType, dependencyMasterPlan.frequency, optionPeriod.year, optionPeriod.period);

        inTheFutureOptions.push({
          label: `P+${value} (ex: ${getPlanPeriodName(dependencyMasterPlan, optionPeriod)})`,
          value,
        });
      }
    }

    if (inTheFutureOptions.length > 0) {
      return {
        [i18n.t('masterPlanDependencyForm.form.relativePeriod.inThePast')]: inThePastOptions,
        [i18n.t('masterPlanDependencyForm.form.relativePeriod.inTheFuture')]: inTheFutureOptions,
      };
    }

    return inThePastOptions;
  }

  return [];
};

export const getRelativePeriod = (masterPlan, dependencyMasterPlan, relativePeriodValue) => {
  if (masterPlan.periodType === PERIOD_TYPES.CUSTOM) {
    return {
      year: 1970,
      period: 1,
    }; // should not happen
  }

  const lastPeriod = getPlanLastPeriod(masterPlan);
  const lastPeriodMomentDate = getMomentDateFromPeriod(masterPlan.periodType, masterPlan.frequency, lastPeriod, masterPlan.customBeginAt, false, masterPlan.fiscalYearShift);

  const dependencyPlanPeriod = getPeriodForMomentDate(
    lastPeriodMomentDate,
    dependencyMasterPlan.periodType,
    dependencyMasterPlan.frequency,
    dependencyMasterPlan.customBeginAt,
    dependencyMasterPlan.fiscalYearShift,
  );

  if (masterPlan.periodType === PERIOD_TYPES.DAY) {
    return {
      year: dependencyPlanPeriod.year,
      period: dependencyPlanPeriod.period + relativePeriodValue,
    };
  }

  if (relativePeriodValue === 0) {
    return dependencyPlanPeriod;
  }

  if (relativePeriodValue < 0) {
    const nbPeriods = 12 / dependencyMasterPlan.frequency;

    let newYear = dependencyPlanPeriod.year;
    let newPeriod = dependencyPlanPeriod.period;

    const nbYearToRemove = Math.floor(Math.abs(relativePeriodValue) / nbPeriods);

    newYear -= nbYearToRemove;

    const remainingToRemove = Math.abs(relativePeriodValue) - (nbPeriods * nbYearToRemove);
    newPeriod -= remainingToRemove;

    if (newPeriod <= 0) {
      newYear -= 1;
      newPeriod = nbPeriods + newPeriod;
    }

    return {
      year: newYear,
      period: newPeriod,
    };
  }

  const nbPeriods = 12 / dependencyMasterPlan.frequency;

  let newYear = dependencyPlanPeriod.year;
  let newPeriod = dependencyPlanPeriod.period;

  const nbYearToAdd = Math.floor(relativePeriodValue / nbPeriods);

  newYear += nbYearToAdd;

  const remainingToAdd = relativePeriodValue - (nbPeriods * nbYearToAdd);
  newPeriod += remainingToAdd;

  if (newPeriod > nbPeriods) {
    newYear += 1;
    newPeriod -= nbPeriods;
  }

  return {
    year: newYear,
    period: newPeriod,
  };
};

export const isActiveUserFilter = (planUser) => {
  const moment = getMoment();
  const now = moment().utc();

  if (planUser.endDate !== null) {
    return (
      moment(planUser.endDate).isAfter(now)
      && (
        planUser.startDate === null
        || moment(planUser.startDate).isBefore(now)
      )
    );
  }

  if (planUser.startDate !== null) return moment(planUser.startDate).isBefore(now);

  return true;
};

export const payoutRulesTemplates = (trackingObjectType) => {
  const i18n = getI18n();

  return {
    payEndOfPlan_001: {
      payoutRulesDescription: i18n.t('masterPlanSettingsPayoutRules.template1.desc'),
      payoutRules: [{
        conditionFormula: 'true',
        payments: [
          {
            intervalCount: 1,
            intervalType: 'month',
            percentageFormula: '1',
            repeatFormula: '1',
            startingAtFormula: "moment(Period.endAt).subtract(3, 'days').toISOString()",
          },
        ],
      }],
    },
    payMonthAfterEndOfPlan_001: {
      payoutRulesDescription: i18n.t('masterPlanSettingsPayoutRules.template2.desc'),
      payoutRules: [{
        conditionFormula: 'true',
        payments: [
          {
            intervalCount: 1,
            intervalType: 'month',
            percentageFormula: '1',
            repeatFormula: '1',
            startingAtFormula: 'moment(Period.endAt).add(25, "days").toISOString()',
          },
        ],
      }],
    },
    payClosedDate_001: {
      payoutRulesDescription: i18n.t('masterPlanSettingsPayoutRules.template3.desc', { trackingObjectType }),
      payoutRules: [
        {
          conditionFormula: 'true',
          payments: [
            {
              intervalCount: 1,
              intervalType: 'month',
              percentageFormula: '1',
              repeatFormula: '1',
              startingAtFormula: `moment(${trackingObjectType}.dateValue).add(1, "month").toISOString()`,
            },
          ],
        },
      ],
    },
    custom: {},
  };
};

export const getPeriodsFilters = (plan) => {
  if (plan.periodType === PERIOD_TYPES.CUSTOM) { // should not happen
    return _pick(PERIODS_FILTER_TYPE, ['ALL_TIMES']);
  }

  if (plan.periodType === PERIOD_TYPES.DAY) {
    const averageNumberOfDaysPerMonth = 30;
    const toKeep = ['ALL_TIMES', 'CUSTOM'];

    if (plan.frequency < 12 * averageNumberOfDaysPerMonth) {
      toKeep.unshift('YEAR_TO_DATE');
    }

    if (plan.frequency < 3 * averageNumberOfDaysPerMonth) {
      toKeep.unshift('QUARTER_TO_DATE');
    }

    if (plan.frequency < 6 * averageNumberOfDaysPerMonth) {
      toKeep.unshift('PAST_6_MONTHS');
    }

    return _pick(PERIODS_FILTER_TYPE, toKeep);
  }

  // In case of periodType of plan is MONTH
  const toKeep = ['ALL_TIMES', 'CUSTOM'];

  if (plan.frequency < 12) {
    toKeep.unshift('YEAR_TO_DATE');
  }

  if (plan.frequency < 3) {
    toKeep.unshift('QUARTER_TO_DATE');
  }

  if (plan.frequency < 6) {
    toKeep.unshift('PAST_6_MONTHS');
  }

  return _pick(PERIODS_FILTER_TYPE, toKeep);
};

const hasPlanInTree = (plans, folder) => (
  plans.find((p) => p.folderId === folder.id)
  || (
    folder.children && folder.children.length > 0
      ? (folder.children.find((f) => hasPlanInTree(plans, f)))
      : false
  )
);

const filterEmptyFolders = (plans, folders) => folders.reduce((acc, element) => [
  ...acc,
  ...(hasPlanInTree(plans, element)
    ? [{
      ...element,
      children: (!element.children || element.children.length === 0)
        ? []
        : filterEmptyFolders(plans, element.children),
    }]
    : []),
], []);

const assignPlansToFolder = (folder, plansByFolders, listType) => {
  const folderWithPlans = {
    ...folder,
    resources: [],
  };

  if (plansByFolders[folder.id] !== undefined) {
    folderWithPlans.resources = plansByFolders[folder.id];

    if (listType === PLANS_PAGE_CONTENT_TABS_IDS.PAST) {
      folderWithPlans.resources = _orderBy(
        folderWithPlans.resources,
        [
          (plan) => getMomentDateFromPeriod(plan.periodType, plan.frequency, getPlanLastPeriod(plan), plan.customBeginAt, true, plan.fiscalYearShift),
          'name',
        ],
        ['desc', 'asc'],
      );
    }
  }

  folderWithPlans.children = folderWithPlans.children.map((subFolder) => assignPlansToFolder(subFolder, plansByFolders, listType));

  return folderWithPlans;
};

const isFolderIdExistsInFoldersTree = (folderId, folders) => {
  if (folders.length === 0) return false;

  return folders.some((folder) => folder.id === folderId || isFolderIdExistsInFoldersTree(folderId, folder.children));
};

export const buildPlansInFolders = (plans, folders, hasRight, listType, search = '') => {
  const plansByFolders = {};

  plans.forEach((plan) => {
    let { folderId } = plan;

    if (folderId !== DEFAULT_FOLDER_ID && !isFolderIdExistsInFoldersTree(folderId, folders)) {
      folderId = DEFAULT_FOLDER_ID;
    }

    if (plansByFolders[folderId] === undefined) {
      plansByFolders[folderId] = [];
    }

    plansByFolders[folderId].push(plan);
  });

  let rootFolder = FolderModel.transform({});

  rootFolder.id = DEFAULT_FOLDER_ID;
  rootFolder.type = FOLDER_TYPES.PLAN;

  rootFolder = assignPlansToFolder(rootFolder, plansByFolders, listType);
  rootFolder.children = folders.map((folder) => assignPlansToFolder(folder, plansByFolders, listType));

  if (search !== '' || !hasRight) {
    return {
      ...rootFolder,
      children: filterEmptyFolders(plans, rootFolder.children),
    };
  }

  return rootFolder;
};

export const isUserGroupByUserPlan = (masterPlan) => (
  masterPlan.trackingObject?.originalType === masterPlan.usersDefinition?.originalType
);

export const getNextCreatePlanFlowStep = (currentStep) => {
  const stepsByValueWithNext = {};
  CREATE_PLAN_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByValueWithNext[step.value] = {
      step,
      next: CREATE_PLAN_FLOW_ORDERED_STEPS[index + 1] || null,
    };
  });

  return stepsByValueWithNext[currentStep].next || null;
};

export const getPreviousCreatePlanFlowStep = (currentStep) => {
  const stepsByValueWithPrevious = {};
  CREATE_PLAN_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByValueWithPrevious[step.value] = {
      step,
      previous: CREATE_PLAN_FLOW_ORDERED_STEPS[index - 1] || null,
    };
  });

  return stepsByValueWithPrevious[currentStep].previous || null;
};

export const getCreatePlanFlowStepIndex = (currentStep) => {
  const stepsByValueWithIndex = {};
  CREATE_PLAN_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByValueWithIndex[step.value] = {
      step,
      index,
    };
  });

  return stepsByValueWithIndex[currentStep].index || 0;
};

export const getCreatePlanFlowStepFromStepIndex = (stepIndex) => {
  const stepsByIndex = {};
  CREATE_PLAN_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByIndex[index] = step;
  });

  return stepsByIndex[stepIndex] || CREATE_PLAN_FLOW_FIRST_STEP;
};

export const getPlanUsersCount = (plan) => (plan.scope === SCOPES.MANAGER ? plan.lastPeriod.managersCount : plan.lastPeriod.usersCount) || 0;

export const getPeriodUsersCount = (plan, period) => (plan.scope === SCOPES.MANAGER ? period.managersCount : period.usersCount) || 0;

export const getDependencyStrategyDescription = (dependency, dependencyPlan, plan) => {
  if (dependencyPlan === null) return null;

  const i18n = getI18n();

  const strategyDescriptionChunks = [
    i18n.t(`masterPlanDependencyItem.injectionPeriodMatchStrategies.${dependency.periodMatchType.toLowerCase()}`),
  ];

  if (dependency.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.RELATIVE) {
    const planLastPeriod = getPlanLastPeriod(plan);
    const lastPeriodName = getPlanPeriodName(plan, planLastPeriod);
    const relativeSelectedPeriod = getRelativePeriod(plan, dependencyPlan, dependency.relativePeriod);
    const relativePeriodName = getPlanPeriodName(dependencyPlan, relativeSelectedPeriod);

    strategyDescriptionChunks.push(
      i18n.t('masterPlanDependencyItem.relativePeriod', { relativePeriod: (dependency.relativePeriod > 0 ? `+${dependency.relativePeriod}` : dependency.relativePeriod), lastPeriodName, relativePeriodName }),
    );
  }

  if (dependency.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.ABSOLUTE && dependency.absolutePeriod !== null) {
    strategyDescriptionChunks.push(getPlanPeriodName(dependencyPlan, dependency.absolutePeriod));
  }

  return strategyDescriptionChunks.join(' -- ');
};

export const getBeginAndEndDatesByFilterType = (periodsFilterType, customPeriods, plan) => {
  const moment = getMoment();

  let beginDate;
  let endDate;

  switch (periodsFilterType) {
    case PERIODS_FILTER_TYPE.CUSTOM: {
      if (customPeriods !== null) {
        beginDate = getMomentDateFromPeriod(plan.periodType, plan.frequency, customPeriods.fromPeriod, plan.customBeginAt, false, plan.fiscalYearShift).format();
        endDate = getMomentDateFromPeriod(plan.periodType, plan.frequency, customPeriods.toPeriod, plan.customBeginAt, true, plan.fiscalYearShift).format();
      }
      break;
    }
    case PERIODS_FILTER_TYPE.QUARTER_TO_DATE: {
      beginDate = moment().utc().startOf('quarter').format();
      break;
    }
    case PERIODS_FILTER_TYPE.YEAR_TO_DATE: {
      beginDate = moment().utc()
        .startOf('year')
        .add(plan.fiscalYearShift, 'month')
        .startOf('month')
        .format();
      break;
    }
    case PERIODS_FILTER_TYPE.PAST_6_MONTHS: {
      beginDate = moment().utc()
        .subtract(5, 'months')
        .startOf('month')
        .format();
      break;
    }
    default: {
      beginDate = undefined;
      endDate = undefined;
    }
  }

  return { beginDate, endDate };
};

export const getLastPeriodAndPastPeriods = (periods) => {
  let last = null;
  let past = [];

  periods.forEach((period) => {
    if (last === null) {
      last = period;
    } else if (
      last.year < period.year
      || (last.year === period.year && last.period < period.period)
    ) {
      past.push(last);
      last = period;
    } else {
      past.push(period);
    }
  });

  past = _orderBy(past, ['year', 'period'], ['desc', 'desc']);

  return [last, past];
};

export const commissionRulesValidationForm = (value) => (
  !value
  || (
    value.type !== RULE_TYPES.CODE_ONLY
    && value.brackets.every((bracket) => (
      bracket.formula !== ''
      && (bracket.from !== '' && bracket.from !== null)
      && (
        (bracket.to !== '' && bracket.to !== null && value.type === RULE_TYPES.PER_OBJECT)
        || ((bracket.to !== '' || bracket.to !== null) && value.type === RULE_TYPES.PER_TARGET)
      )
    ))
  )
  || (
    value.type === RULE_TYPES.CODE_ONLY
    && value.explanation !== ''
    && value.initFormula !== ''
  )
);

export const payoutScheduleValidationForm = (value) => {
  let hasError = false;

  if (!value
    || (
      value.code === null
      && value.conditionFormula !== ''
      && value.payments.every((payment) => (
        payment.percentageFormula !== ''
        && payment.repeatFormula !== ''
        && payment.startingAtFormula !== ''
      ))
    )
    || (value.code !== '' && value.code !== null)
  ) {
    if (value?.code === null) {
      value?.payments.map((payment) => {
        if (hasError === false && payment.repeatFormula !== '1' && (payment.intervalCount < 1 || payment.intervalFormula === '')) {
          hasError = true;
        }

        return hasError;
      });
    }

    if (!hasError) {
      return true;
    }
  }

  return false;
};

export const scrollToNextStep = (position = APP_BOTTOM_ID) => {
  setTimeout(() => {
    scrollToPosition(position);
  }, 300);
};

export const payoutRulesConditionToHideRuleStep = (rule) => rule.payments.length === 0 && !rule.code;

export const planHasNoQuotaTarget = (plan, period) => plan?.quotaId === null || period?.target === -1;

export const cleanInjectionFromPeriodMatchType = (injection) => {
  const cleanedInjection = _cloneDeep(injection);

  if (cleanedInjection.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.AUTO) {
    delete cleanedInjection.relativePeriod;
    delete cleanedInjection.absolutePeriod;
    delete cleanedInjection.periodFormula;
  }

  if (cleanedInjection.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.RELATIVE) {
    delete cleanedInjection.absolutePeriod;
    delete cleanedInjection.periodFormula;
  }

  if (cleanedInjection.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.ABSOLUTE) {
    delete cleanedInjection.relativePeriod;
    delete cleanedInjection.periodFormula;
  }

  if (cleanedInjection.periodMatchType === PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.FORMULA) {
    delete cleanedInjection.relativePeriod;
    delete cleanedInjection.absolutePeriod;
  }

  return cleanedInjection;
};

export const computeUserJoinLeaveDate = ({ date, manageEndDate }) => {
  if (!date) return date;

  const moment = getMoment();
  const momentDate = moment(date).utc();

  if (manageEndDate) return momentDate.endOf('day').format();

  return momentDate.startOf('day').format();
};

export const isPeriodCurrentOne = (plan, period) => {
  if (plan.periodType === PERIOD_TYPES.CUSTOM) return !isInPast(plan);

  const moment = getMoment();

  const periodBeginMomentDate = getMomentDateFromPeriod(plan.periodType, plan.frequency, period, plan.customBeginAt, false, plan.fiscalYearShift);
  const periodEndMomentDate = getMomentDateFromPeriod(plan.periodType, plan.frequency, period, plan.customBeginAt, true, plan.fiscalYearShift);
  const now = moment().utc();

  return now.isBetween(periodBeginMomentDate, periodEndMomentDate, undefined, '[]');
};

export const checkColumnsVariablesFields = (allFields) => {
  let checkErrors = false;

  if (allFields.length === 0) return !checkErrors;

  allFields.forEach((field) => {
    if (field.name === '' || (field.formatter === FORMATTERS_VALUES.Custom && field.evalFn === '')) {
      checkErrors = true;
    }
  });

  return !checkErrors;
};
