import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import StatementCounterCorrections from '@palette/components/statement/statementCommon/StatementCounterCorrections/StatementCounterCorrections';
import StatementV3ApprovalsList from '@palette/components/statementV3/StatementV3ApprovalsList/StatementV3ApprovalsList';
import StatementV3ApproveAction from '@palette/components/statementV3/StatementV3ApproveAction/StatementV3ApproveAction';
import StatementV3MarkAsPaidAction from '@palette/components/statementV3/StatementV3MarkAsPaidAction/StatementV3MarkAsPaidAction';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { selectors as UsersSelectors } from '@palette/state/Users';

import routePaths from '@palette/config/routePaths';

import styles from './StatementV3UsersStatementsList.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UsersStatementsList = ({ className, userStatements, currency }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const statementValidationEnabledOnProfile = hasFeature(profile, FEATURES.STATEMENT_VALIDATION);
  const statementValidationCanManageOnProfile = !!statementValidationEnabledOnProfile && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE]);

  const columns = useMemo(() => {
    const cols = [];

    const userColumn = {
      id: 'statementUser',
      Header: t('userStatement.table.header.name'),
      accessor: (statement) => statement,
      minWidth: 240,
      width: '24%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => {
        const user = useSelector((state) => UsersSelectors.getUserById(state, { userId: value.userId }));

        return (
          <UserProfile
            className={styles.userProfile}
            user={user}
            clickable
            linkProps={{
              path: routePaths.v2.statementV3UserStatement,
              params: { statementPeriodId: value.id },
              qsObject: periodsDatesFilterQSObject,
              displayIcon: true,
              contentClassName: styles.userProfileLink,
            }}
          />
        );
      },
      /* eslint-enable react/prop-types */
      sortType: (a, b) => {
        const aName = a.values.statementUser.displayName;
        const bName = b.values.statementUser.displayName;
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      },
    };

    cols.push(userColumn);

    const commissionsColumn = {
      id: 'statementCommissions',
      Header: t('userStatement.table.header.commissions'),
      accessor: (statement) => statement,
      minWidth: 410,
      width: '41%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => {
        const statementAmount = formatPrice(value.amount, value.currency);

        return (
          <div className={styles.commissions}>
            <div className={styles.amounts}>
              <div className={styles.statementAmount}>
                {statementAmount}
              </div>
            </div>
            {statementValidationEnabledOnProfile && (
              <StatementCounterCorrections
                className={styles.correctionsWrapper}
                counter={value.correctionsCount}
              />
            )}
          </div>
        );
      },
      /* eslint-enable react/prop-types */
      sortType: (a, b) => {
        const aAmount = a.values.statementCommissions.statementAmount;
        const bAmount = b.values.statementCommissions.statementAmount;
        if (aAmount < bAmount) return -1;
        if (aAmount > bAmount) return 1;
        return 0;
      },
    };

    cols.push(commissionsColumn);

    const approvalsColumn = {
      id: 'statementApprovals',
      accessor: (statement) => statement,
      minWidth: statementValidationCanManageOnProfile ? 380 : 670,
      width: statementValidationCanManageOnProfile ? '38%' : '67%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => {
        const approvals = value.actions.filter((action) => action.type === STATEMENT_ACTION_TYPES.APPROVAL);
        return (
          <StatementV3ApprovalsList
            className={styles.approvalsWrapper}
            approvals={approvals}
            statementAmount={value.amount}
            statementCurrency={value.currency}
          />
        );
      },
      /* eslint-enable react/prop-types */
      disableSortBy: true,
    };

    cols.push(approvalsColumn);

    if (statementValidationCanManageOnProfile) {
      const actionsColumn = {
        id: 'statementActions',
        Header: (
          <div className={styles.headerRight}>
            {t('userStatement.table.header.actions')}
          </div>
        ),
        accessor: (statement) => statement,
        minWidth: 290,
        width: '29%',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <div className={styles.actionsWrapper}>
            <StatementV3ApproveAction
              className={styles.actionButton}
              userStatement={value}
              currency={currency}
              isSecondaryType
            />
            <StatementV3MarkAsPaidAction
              className={styles.actionButton}
              userStatement={value}
              currency={currency}
              isSecondaryType
            />
          </div>
        ),
        disableSortBy: true,
      };

      cols.push(actionsColumn);
    }

    return cols;
  }, [
    currency,
    statementValidationEnabledOnProfile,
    statementValidationCanManageOnProfile,
  ]);

  const contentNode = useMemo(() => {
    if (userStatements.length === 0) return <DefaultEmptyState description={t('usersStatementsList.noUserStatement')} />;

    return (
      <Table
        type="borderless"
        columns={columns}
        data={userStatements}
        nbOfFixedColumns={statementValidationCanManageOnProfile ? 1 : 0}
        fixedColumnsPosition="fromRight"
        enableSortableColumns
      />
    );
  }, [
    userStatements,
    columns,
    statementValidationCanManageOnProfile,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementV3UsersStatementsList.propTypes = {
  className: PropTypes.string,
  userStatements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    userId: PropTypes.string,
    correctionsCount: PropTypes.number,
  })),
  currency: PropTypes.string,
};

StatementV3UsersStatementsList.defaultProps = {
  className: '',
  userStatements: [],
  currency: null,
};

export default StatementV3UsersStatementsList;
