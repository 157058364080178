import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import StatementPanelPeriodsCardsListItem from '@palette/components/statement/statementPeriod/StatementPanelPeriodsCardsListItem/StatementPanelPeriodsCardsListItem';

import { useUserStatementsPanelContext } from '@palette/hooks/StatementHooks';

import styles from './StatementPanelPeriodsCardsList.less';

const classNames = bindClassNames.bind(styles);

const MAX_ITEMS_BY_ROW = 6;

const StatementPanelPeriodsCardsList = ({ className }) => {
  const { t } = useTranslation();

  const { statementPeriodId, listStatementPeriodsIsPending, statementPeriods } = useUserStatementsPanelContext();

  const contentNode = useMemo(() => {
    if (listStatementPeriodsIsPending) return <Loader className={styles.loaderWrapper} />;

    if (statementPeriods.length === 0) {
      return (
        <DefaultEmptyState description={t('statementPeriodsList.noPeriods')} />
      );
    }

    return (
      <div
        className={classNames({
          container: true,
          hasMultipleLines: statementPeriods.length > MAX_ITEMS_BY_ROW,
        })}
      >
        {statementPeriods.map((statementPeriod) => (
          <StatementPanelPeriodsCardsListItem
            key={statementPeriod.id}
            className={styles.period}
            statementPeriod={statementPeriod}
            isSelected={statementPeriod.id === statementPeriodId}
          />
        ))}
      </div>
    );
  }, [
    listStatementPeriodsIsPending,
    statementPeriods,
    statementPeriodId,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementPanelPeriodsCardsList.propTypes = {
  className: PropTypes.string,
};

StatementPanelPeriodsCardsList.defaultProps = {
  className: '',
};

export default StatementPanelPeriodsCardsList;
