import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as QuotaModel from '@palette/models/Quota';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './DeleteQuotaModal.less';

const DeleteQuotaModal = ({ visible, onClose, quota, title, content }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteQuotaIsPending = useSelector(QuotasSelectors.deleteByIdIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
    redirectTo({ path: routePaths.v2.quotas });
  };

  const handleDeleteQuota = () => {
    dispatch(QuotasActions.deleteById({ quotaId: quota.id, onSuccessCB }));
  };

  return (
    <Modal
      className={styles.modal}
      title={title}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDeleteQuota}
      okText={t('quota.deleteQuota.confirm')}
      loading={deleteQuotaIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>
  );
};

DeleteQuotaModal.propTypes = {
  quota: QuotaModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

DeleteQuotaModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default DeleteQuotaModal;
