import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlgoliaSearchModal from '@palette/components/vendors/Algolia/AlgoliaSearchModal/AlgoliaSearchModal';

import { MENU_MODALS } from '@palette/constants/menu';

import { actions as NavigationActions, selectors as NavigationSelectors } from '@palette/state/Navigation';

const ActionsMenuDocumentationListener = () => {
  const dispatch = useDispatch();

  const getModalVisible = useSelector(NavigationSelectors.getModalVisible);

  const isAlgoliaSearchModalVisible = getModalVisible === MENU_MODALS.ALGOLIA_SEARCH_MODAL;

  if (!isAlgoliaSearchModalVisible) return null;

  return <AlgoliaSearchModal visible onClose={() => dispatch(NavigationActions.setModalVisible(null))} />;
};

export default ActionsMenuDocumentationListener;
