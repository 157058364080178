import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import AllCurrenciesSelect from '@palette/components/utils/AllCurrenciesSelect/AllCurrenciesSelect';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './AddCompanyCurrencyModal.less';

const AddCompanyCurrencyModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company } = profile.userData;

  const updateCompanyIsPending = useSelector(ProfileSelectors.updateCompanyIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    code: null,
  }), []);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const cleanAndClose = () => {
    onClose();
  };

  const handleFinish = useCallback((values) => {
    const newCurrencyCode = values.code;

    if (!newCurrencyCode) {
      cleanAndClose();
      return;
    }

    const newCurrencies = [
      ...company.currencies.map((currency) => ({
        code: currency.code,
        default: currency.isDefault,
      })),
      {
        code: newCurrencyCode,
        default: false,
      },
    ];

    dispatch(ProfileActions.updateCompany({ currencies: newCurrencies, onSuccessCB: cleanAndClose }));
  }, [company]);

  const handleAddCurrency = () => form.submit();

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('companyCurrencies.addCompanyCurrency.modal.title')}
        </div>
      )}
      visible
      onCancel={cleanAndClose}
      onOk={handleAddCurrency}
      okText={t('companyCurrencies.addCompanyCurrency.modal.save.label')}
      okIcon={<CheckFilled />}
      loading={updateCompanyIsPending}
    >
      <div className={styles.content}>
        <Form
          onFinish={handleFinish}
          initialValues={initialValues}
          form={form}
        >
          <FormItem
            name="code"
            label={t('companyCurrencies.addCompanyCurrency.modal.select.label')}
            required
            rules={[
              { required: true, message: t('companyCurrencies.addCompanyCurrency.modal.select.error.message') },
            ]}
          >
            <AllCurrenciesSelect
              size="big"
              disabled={updateCompanyIsPending}
              placeholder={t('companyCurrencies.addCompanyCurrency.modal.select.placeholder')}
              disableCompanyCurrencies
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
};

AddCompanyCurrencyModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

AddCompanyCurrencyModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddCompanyCurrencyModal;
