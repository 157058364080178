import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import EditCommissionValueModal from '@palette/components/commission/EditCommissionValueModal/EditCommissionValueModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getCommissionValue } from '@palette/helpers/CommissionHelper';

import { RIGHTS } from '@palette/constants/profile';
import { RULE_TYPES } from '@palette/constants/masterPlans';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionDealValue.less';

const classNames = bindClassNames.bind(styles);

const CommissionDealValue = ({ className, commission, viewOnly }) => {
  const profile = useProfile();

  const [editDealValueModalIsVisible, showEditDealValueModal] = useState(false);

  const commissionValue = getCommissionValue(commission);

  const dealValue = Math.round(commissionValue * 100) / 100;

  const splittedFormattedValue = comaSeparatorFormatter(dealValue).split('.');
  const valuesNodes = [];

  valuesNodes.push((
    <div key="integerPart" className={styles.integerPart}>
      {splittedFormattedValue[0]}
    </div>
  ));

  if (splittedFormattedValue.length > 1) {
    valuesNodes.push((
      <div key="decimalPart" className={styles.decimalPart}>
        {`.${splittedFormattedValue[1]}`}
      </div>
    ));
  }

  const valueNode = (
    <div className={styles.value}>
      {valuesNodes}
    </div>
  );

  let initialValueNode = null;

  if (commission.overwrittenValue !== null) {
    initialValueNode = (
      <div className={styles.initialValue}>
        {comaSeparatorFormatter(floatToFixedNumber(commission.value))}
      </div>
    );
  }

  let editButtonNode = (
    <Button
      className={styles.button}
      type="link"
      icon={<PenFilled width={16} height={16} />}
      onClick={() => showEditDealValueModal(true)}
    />
  );

  if (
    viewOnly
    || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMMISSIONS.MANAGE])
    || !commission.scopeParams?.rule
    || commission.scopeParams?.rule.type === RULE_TYPES.CODE_ONLY
    || commission.scopeParams?.rule.type === RULE_TYPES.PER_TARGET
    || commission.cantUpdateMine
  ) {
    editButtonNode = null;
  }

  const trackingObjectValueDefinition = getPlanValueDefinition(commission.scopeParams?.plan);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.labelWrapper}>
        {trackingObjectValueDefinition}
      </div>
      <div className={styles.valueWrapper}>
        {valueNode}
        {initialValueNode}
        {editButtonNode}
      </div>
      {
        editDealValueModalIsVisible && (
          <EditCommissionValueModal
            visible
            onClose={() => showEditDealValueModal(false)}
            commission={commission}
            labelDefinition={trackingObjectValueDefinition}
          />
        )
      }
    </div>
  );
};

CommissionDealValue.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  viewOnly: PropTypes.bool,
};

CommissionDealValue.defaultProps = {
  className: '',
  viewOnly: false,
};

export default CommissionDealValue;
