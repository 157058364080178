import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';

import Button from '@palette/components/designSystem/Button/Button';
import Input from '@palette/components/designSystem/Input/Input';
import Modal from '@palette/components/designSystem/Modal/Modal';
import Switch from '@palette/components/designSystem/Switch/Switch';
import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import BarsFilled from '@palette/components/utils/Icons/BarsFilled';

import { NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS } from '@palette/constants/notebooks';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ConnectorModel from '@palette/models/Connector';

import styles from './ResourceConfigurationModal.less';

const ResourceConfigurationModal = ({
  visible,
  onClose,
  connector,
  resourceType,
  requiredFields,
  onRefreshList,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [configurationFields, setConfigurationFields] = useState(requiredFields);
  const [searchedValue, setSearchedValue] = useState(null);
  const [resetBtnDisabled, setResetBtnDisabled] = useState(false);

  const upsertResourceConfigurationsIsPending = useSelector(ConnectorsSelectors.upsertResourceConfigurationsIsPending);
  const getAvailableConfigurationsFieldsIsPending = useSelector(ConnectorsSelectors.getAvailableConfigurationsFieldsIsPending);

  const getColumnsFieldsUsedByConnectorAndType = useSelector((state) => ConnectorsSelectors.getColumnsFieldsUsedByConnectorAndType(state, { connectorId: connector.id, resourceType }));
  const getConfigurationsFieldsByIdAndType = useSelector((state) => ConnectorsSelectors.getConfigurationsFieldsByIdAndType(state, { connectorId: connector.id, resourceType }));

  useEffect(() => {
    dispatch(ConnectorsActions.getAvailableConfigurationsFields({ connectorId: connector.id, resourceType }));
  }, []);

  useEffect(() => {
    const syncedFields = getConfigurationsFieldsByIdAndType?.syncedFields || [];

    if (syncedFields.length) {
      setConfigurationFields(syncedFields.map((field) => field.name));
    }
  }, [getConfigurationsFieldsByIdAndType]);

  useEffect(() => {
    setResetBtnDisabled(configurationFields.length === 0);
  }, [configurationFields]);

  const handleClose = (e) => onClose(e);

  const refreshAndClose = (e) => {
    onRefreshList();
    handleClose(e);
  };

  const handleSearchFilterChange = useCallback((newSearchedValue) => {
    setSearchedValue(newSearchedValue.length > 0 ? newSearchedValue : null);
  }, []);

  const handleUpdateConfigurations = useCallback((e) => {
    const allFields = getConfigurationsFieldsByIdAndType?.allFields || [];
    const finalFields = allFields
      .filter((field) => configurationFields.includes(field.name))
      .map(({ dataSelectorBlockListItemType, ...field }) => ({ ...field }));

    dispatch(ConnectorsActions.upsertResourceConfigurations({
      connectorId: connector.id,
      resourceType,
      fields: finalFields,
      onSuccessCB: () => refreshAndClose(e),
    }));
  }, [
    connector,
    resourceType,
    configurationFields,
    getConfigurationsFieldsByIdAndType,
  ]);

  const handleResetFields = useCallback(() => {
    const allFields = getConfigurationsFieldsByIdAndType?.allFields || [];
    const allFieldsNames = allFields.map((field) => field.name);
    const unselectableFields = getColumnsFieldsUsedByConnectorAndType
      .filter((field) => configurationFields.includes(field))
      .concat(requiredFields);

    setConfigurationFields(allFieldsNames.filter((field) => unselectableFields.includes(field)));
    setResetBtnDisabled(true);
  }, [
    getConfigurationsFieldsByIdAndType,
    getColumnsFieldsUsedByConnectorAndType,
    configurationFields,
    requiredFields,
  ]);

  const handleUpdateFieldItem = (fieldName, value) => {
    if (!value) {
      setConfigurationFields((fields) => fields.filter((field) => field !== fieldName));
    } else {
      setConfigurationFields((fields) => [...fields, fieldName]);
    }
  };

  const searchNode = useMemo(() => (
    <div className={styles.searchFilter}>
      <Input
        className={styles.searchFilterInput}
        type="search"
        placeholder={t('resourceConfiguration.modal.search.placeholder')}
        onChange={handleSearchFilterChange}
        value={searchedValue}
        disabled={getAvailableConfigurationsFieldsIsPending}
      />
    </div>
  ), [
    handleSearchFilterChange,
    searchedValue,
    getAvailableConfigurationsFieldsIsPending,
  ]);

  const resetBtnNode = useMemo(() => (
    <Popconfirm
      title={t('resourceConfiguration.modal.reset.title')}
      onConfirm={handleResetFields}
      okText={t('common.global.yes')}
      cancelText={t('common.global.no')}
      size="small"
    >
      <Button
        className={styles.resetBtn}
        type="link"
        icon={(<BarsFilled width={24} height={24} />)}
        disabled={getAvailableConfigurationsFieldsIsPending || resetBtnDisabled}
      >
        {t('resourceConfiguration.modal.reset.label')}
      </Button>
    </Popconfirm>
  ), [
    handleResetFields,
    getAvailableConfigurationsFieldsIsPending,
    resetBtnDisabled,
  ]);

  const switchNode = useCallback((fieldName) => {
    const isFieldUsedInSomeColumns = getColumnsFieldsUsedByConnectorAndType.includes(fieldName);
    const isSynced = configurationFields.includes(fieldName);
    const isFieldRequired = isSynced && requiredFields.includes(fieldName);
    const isDisabled = (isFieldUsedInSomeColumns || isFieldRequired) && isSynced;

    const contentNode = (
      <Switch
        type="primaryTeal"
        disabled={upsertResourceConfigurationsIsPending || isDisabled}
        value={isSynced}
        onChange={(value) => handleUpdateFieldItem(fieldName, value)}
      />
    );

    if (isDisabled) {
      const tooltilLabel = isFieldRequired
        ? t('resourceConfiguration.modal.field.required')
        : t('resourceConfiguration.modal.field.used');

      return (
        <Tooltip title={tooltilLabel}>
          <div>
            {contentNode}
          </div>
        </Tooltip>
      );
    }

    return contentNode;
  }, [
    getColumnsFieldsUsedByConnectorAndType,
    upsertResourceConfigurationsIsPending,
    configurationFields,
    requiredFields,
  ]);

  const filteredFields = useMemo(() => {
    if (!getConfigurationsFieldsByIdAndType?.allFields) return [];

    const { allFields } = getConfigurationsFieldsByIdAndType;

    if (searchedValue === null || searchedValue === '') return allFields;

    return allFields.filter((field) => new RegExp(searchedValue, 'im').test(field.name));
  }, [
    getConfigurationsFieldsByIdAndType,
    searchedValue,
  ]);

  const fieldsListNodes = useMemo(() => {
    if (getAvailableConfigurationsFieldsIsPending) {
      return (
        <Loader spinning />
      );
    }

    if (filteredFields.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return _orderBy(filteredFields, ['name']).map((field) => {
      const TypeIcon = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS[field.dataSelectorBlockListItemType];

      return (
        <div className={styles.switchWrapper} key={field.name}>
          <div className={styles.labelContainer}>
            <TypeIcon className={styles.icon} />
            <div className={styles.label}>
              {field.name}
            </div>
          </div>
          <div className={styles.switch}>
            {switchNode(field.name)}
          </div>
        </div>
      );
    });
  }, [
    getAvailableConfigurationsFieldsIsPending,
    switchNode,
    filteredFields,
  ]);

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('resourceConfiguration.modal.title', { resourceType })}
        </div>
      )}
      visible={visible}
      onCancel={handleClose}
      onOk={handleUpdateConfigurations}
      okText={t('resourceConfiguration.modal.save.label')}
      loading={upsertResourceConfigurationsIsPending}
      disableOk={(
        getAvailableConfigurationsFieldsIsPending
        || !filteredFields.length
        || !configurationFields.length
      )}
    >
      <div className={styles.introduction}>
        {t('resourceConfiguration.modal.introduction')}
      </div>
      {searchNode}
      {resetBtnNode}
      <div className={styles.switchContainer}>
        {fieldsListNodes}
      </div>
    </Modal>
  );
};

ResourceConfigurationModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  connector: ConnectorModel.propTypes.isRequired,
  resourceType: PropTypes.string.isRequired,
  requiredFields: PropTypes.array,
  onRefreshList: PropTypes.func,
};

ResourceConfigurationModal.defaultProps = {
  visible: false,
  requiredFields: [],
  onClose: () => {},
  onRefreshList: () => {},
};

export default ResourceConfigurationModal;
