import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import StatementV3UserStatementCorrectionsItemActions from '@palette/components/statementV3/StatementV3UserStatementCorrectionsItemActions/StatementV3UserStatementCorrectionsItemActions';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getPeriodName } from '@palette/helpers/StatementHelper';

import routePaths from '@palette/config/routePaths';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';
import * as StatementV3ListCorrectionModel from '@palette/models/StatementV3ListCorrection';

import styles from './StatementV3UserStatementCorrectionsItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementCorrectionsItem = ({ className, statement, correction, currency }) => {
  const { t } = useTranslation();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const { fromStatement, amount } = correction;

  const periodShortName = getPeriodName({ type: fromStatement.periodicity }, fromStatement.period, fromStatement.year);

  const reason = t('userStatementCorrections.content.default.reason', { dates: periodShortName });

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.content}>
        <Link
          className={styles.link}
          path={routePaths.v2.statementV3UserStatement}
          params={{ statementPeriodId: fromStatement.id }}
          qsObject={periodsDatesFilterQSObject}
        >
          {reason}
        </Link>
        <div className={styles.amount}>
          {formatPrice(amount, correction.currency)}
        </div>
      </div>
      <div className={styles.actions}>
        <StatementV3UserStatementCorrectionsItemActions
          statement={statement}
          correction={correction}
          currency={currency}
        />
      </div>
    </div>
  );
};

StatementV3UserStatementCorrectionsItem.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  correction: StatementV3ListCorrectionModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementV3UserStatementCorrectionsItem.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3UserStatementCorrectionsItem;
