import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import Select from '@palette/components/designSystem/Select/Select';
import Input from '@palette/components/designSystem/Input/Input';
import TextArea from '@palette/components/designSystem/TextArea/TextArea';
import VariablesForm from '@palette/components/variable/VariablesForm/VariablesForm';
import RuleCalculationForm from '@palette/components/rule/RuleCalculationForm/RuleCalculationForm';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import { enhanceFormulaHelperDataWithVariables } from '@palette/helpers/FormulaHelper';

import { RULE_TYPES } from '@palette/constants/masterPlans';

import * as PlanRuleModel from '@palette/models/PlanRule';

import styles from './MasterPlanSettingsCommissionRulesFormRule.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCommissionRulesFormRule = ({
  className,
  ruleNumberPosition,
  helperData,
  rule,
  onRemove,
  onChange,
  onCollapseChange,
  openedCollapses,
  onSubmitEditionName,
  trackingObjectType,
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => ({
    ...rule,
    name: rule.name || '',
    type: rule.type || RULE_TYPES.PER_OBJECT,
    description: rule.description || '',
    variables: rule.variables || [],
    brackets: rule.brackets || [],
    percentage: rule.percentage || false,
    initFormula: rule.initFormula || '',
    explanation: rule.explanation || '',
  }), [rule]);

  const [ruleName, setRuleName] = useState(initialValues.name);
  const [ruleType, setRuleType] = useState(initialValues.type);
  const [ruleDescription, setRuleDescription] = useState(initialValues.description);
  const [ruleVariables, setRuleVariables] = useState(initialValues.variables);
  const [ruleBrackets, setRuleBrackets] = useState(initialValues.brackets);
  const [rulePercentage, setRulePercentage] = useState(initialValues.percentage);
  const [ruleInitFormula, setRuleInitFormula] = useState(initialValues.initFormula);
  const [ruleExplanation, setRuleExplanation] = useState(initialValues.explanation);
  const [showCustomCodeSection] = useState(initialValues.initFormula !== '' || false);
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    setRuleName(initialValues.name);
    setRuleType(initialValues.type);
    setRuleDescription(initialValues.description);
    setRuleVariables(initialValues.variables);
    setRuleBrackets(initialValues.brackets);
    setRulePercentage(initialValues.percentage);
    setRuleInitFormula(initialValues.initFormula);
    setRuleExplanation(initialValues.explanation);
    setIsEditingName(false);
  }, [initialValues]);

  const ruleTypes = Object.keys(RULE_TYPES).map((type) => ({
    label: t(`masterPlanSettingsCommissionRulesForm.form.ruleType.${type}`, { type: trackingObjectType }),
    value: type,
  }));

  const handleChange = useCallback(() => {
    const updatedRule = {
      ...rule,
      ...initialValues,
      name: ruleName,
      description: ruleDescription,
      type: ruleType,
      variables: ruleVariables,
      percentage: rulePercentage,
      brackets: ruleBrackets,
      initFormula: ruleInitFormula,
      explanation: ruleExplanation,
    };

    if (!showCustomCodeSection && ruleType !== RULE_TYPES.CODE_ONLY && ruleInitFormula !== '') {
      updatedRule.initFormula = '';
    }

    if (!_isEqual(initialValues, updatedRule)) {
      if (updatedRule._id === null) {
        delete updatedRule._id;
      }
      onChange(updatedRule);
    }
  }, [
    initialValues,
    rule,
    ruleName,
    ruleType,
    ruleDescription,
    ruleVariables,
    ruleBrackets,
    rulePercentage,
    ruleInitFormula,
    ruleExplanation,
  ]);

  const handleChangeRuleName = useCallback((name) => {
    setRuleName(name);
  }, []);

  const handleChangeRuleType = useCallback((type) => {
    setRuleType(type);
  }, []);

  const handleChangeRuleDescription = useCallback((description) => {
    setRuleDescription(description);
  }, []);

  const handleChangeRuleVariables = useCallback((variables) => {
    setRuleVariables(variables);
  }, []);

  const handleChangeRuleBrackets = useCallback((brackets) => {
    setRuleBrackets(brackets);
  }, []);

  const handleChangeRulePercentage = useCallback((percentage) => {
    setRulePercentage(percentage);
  }, []);

  const handleChangeRuleInitFormula = useCallback((initFormula) => {
    setRuleInitFormula(initFormula);
  }, []);

  const handleChangeRuleExplanation = useCallback((explanation) => {
    setRuleExplanation(explanation);
  }, []);

  useEffect(() => {
    handleChange();
  }, [
    ruleType,
    ruleDescription,
    ruleVariables,
    ruleBrackets,
    rulePercentage,
    ruleInitFormula,
    ruleExplanation,
  ]);

  const handleRemove = useCallback((event) => {
    event.stopPropagation();
    onRemove();
  }, [onRemove]);

  const handleEditName = useCallback((event) => {
    event.stopPropagation();
    setIsEditingName(true);
  }, []);

  const handleCloseEditionName = useCallback((event) => {
    event.stopPropagation();
    handleChangeRuleName(initialValues.name);
    setIsEditingName(false);
  }, [initialValues.name]);

  const handleSubmitEditionName = useCallback((event) => {
    event.stopPropagation();
    onSubmitEditionName();
    handleChange();
  }, [onSubmitEditionName, handleChange]);

  const ruleTitle = useMemo(() => (
    <span className={styles.ruleTitle}>
      {!isEditingName && (
        <>
          {ruleName || `${t('masterPlanSettingsCommissionRules.ruleTitle')} ${ruleNumberPosition}`}
          <Button
            className={styles.actionButton}
            type="link"
            icon={(<PenFilled />)}
            onClick={(event) => handleEditName(event)}
          />
        </>
      )}
      {isEditingName && (
        <>
          <Input
            placeholder={t('masterPlanSettingsCommissionRulesForm.form.ruleName.placeholder')}
            value={ruleName}
            onChange={handleChangeRuleName}
            onClick={(event) => event.stopPropagation()}
          />
          <Button
            className={styles.actionButton}
            type="link"
            icon={(<ClosePopupFilled className={styles.actionTrashIcon} />)}
            onClick={(event) => handleCloseEditionName(event)}
          />
          <Button
            className={styles.actionButton}
            type="link"
            icon={(<CheckFilled width={20} height={20} />)}
            onClick={(event) => handleSubmitEditionName(event)}
          />
        </>
      )}
    </span>
  ), [
    ruleName,
    isEditingName,
    handleEditName,
    handleChangeRuleName,
    handleCloseEditionName,
    handleSubmitEditionName,
  ]);

  const ruleContent = useMemo(() => {
    const ruleVariablesNames = [];
    ruleVariables.forEach((ruleVariable) => {
      if (ruleVariable.name !== '') {
        ruleVariablesNames.push(ruleVariable.name);
      }
    });

    return (
      <>
        <div className={styles.sectionItem}>
          <div className={styles.fieldTitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleType.title')}</div>
          <div className={styles.fieldSubtitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleType.subtitle')}</div>
          <Select
            options={ruleTypes}
            onChange={handleChangeRuleType}
            value={ruleType}
          />
          <div
            className={styles.fieldDisclaimer}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t(`masterPlanSettingsCommissionRulesForm.form.ruleType.disclaimer.${ruleType}`, { type: trackingObjectType }) }}
          />
        </div>
        <div className={styles.sectionItem}>
          <div className={styles.fieldTitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleDesc.title')}</div>
          <div className={styles.fieldSubtitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleDesc.subtitle')}</div>
          <TextArea
            placeholder={t('masterPlanSettingsCommissionRulesForm.form.ruleDesc.placeholder')}
            value={ruleDescription}
            onChange={handleChangeRuleDescription}
          />
        </div>
        {ruleType !== RULE_TYPES.CODE_ONLY && (
          <div className={styles.sectionItem}>
            <div className={styles.fieldTitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleVariables.title')}</div>
            <div className={styles.fieldSubtitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleVariables.subtitle')}</div>
            <VariablesForm
              variables={ruleVariables}
              helperData={helperData}
              onChange={handleChangeRuleVariables}
              prefixId={`rule-${rule.id}`}
            />
          </div>
        )}
        {(ruleType === RULE_TYPES.CODE_ONLY || showCustomCodeSection) && (
          <div className={styles.sectionItem}>
            <div className={classNames({ fieldTitle: true, withExtra: true })}>
              {t('masterPlanSettingsCommissionRulesForm.form.ruleCode.title')}
            </div>
            <TextArea
              placeholder={t('masterPlanSettingsCommissionRulesForm.form.ruleCode.placeholder')}
              value={ruleInitFormula}
              onChange={handleChangeRuleInitFormula}
            />
            {
              ruleInitFormula === '' && (
                <div className={styles.inputFieldError}>{t('masterPlanSettingsCommissionRulesForm.form.ruleCode.error.required')}</div>
              )
            }
          </div>
        )}
        {(ruleType === RULE_TYPES.CODE_ONLY) && (
          <div className={styles.sectionItem}>
            <div className={styles.fieldTitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleExplanation.title')}</div>
            <TextArea
              placeholder={t('masterPlanSettingsCommissionRulesForm.form.ruleExplanation.placeholder')}
              value={ruleExplanation}
              onChange={handleChangeRuleExplanation}
            />
            {
              ruleExplanation === '' && (
                <div className={styles.inputFieldError}>{t('masterPlanSettingsCommissionRulesForm.form.ruleExplanation.error.required')}</div>
              )
            }
          </div>
        )}
        {ruleType !== RULE_TYPES.CODE_ONLY && (
          <div className={styles.sectionItem}>
            <div className={styles.fieldTitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleCalculation.title')}</div>
            <div className={styles.fieldSubtitle}>{t('masterPlanSettingsCommissionRulesForm.form.ruleCalculation.subtitle')}</div>
            <RuleCalculationForm
              brackets={ruleBrackets}
              percentage={rulePercentage}
              helperData={enhanceFormulaHelperDataWithVariables(helperData, ruleVariablesNames)}
              onChangeBrackets={handleChangeRuleBrackets}
              onChangePercentage={handleChangeRulePercentage}
              ruleType={ruleType}
              ruleId={rule.id}
            />
          </div>
        )}
      </>
    );
  }, [
    rule,
    ruleType,
    handleChangeRuleType,
    ruleDescription,
    handleChangeRuleDescription,
    ruleVariables,
    handleChangeRuleVariables,
    ruleBrackets,
    handleChangeRuleBrackets,
    rulePercentage,
    handleChangeRulePercentage,
    ruleInitFormula,
    handleChangeRuleInitFormula,
    ruleExplanation,
    handleChangeRuleExplanation,
    helperData,
    showCustomCodeSection,
  ]);

  const ruleExtra = useMemo(() => (
    <Tooltip title={t('masterPlanSettingsCommissionRulesForm.form.ruleRemoveTooltip')}>
      <Button
        className={styles.actionButton}
        type="link"
        icon={<TrashFilled className={styles.actionTrashIcon} />}
        onClick={(event) => handleRemove(event)}
      />
    </Tooltip>
  ), [handleRemove]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      id={`rule-offset-${ruleNumberPosition - 1}`}
    >
      <Collapse
        className={styles.collapse}
        panels={[
          {
            title: ruleTitle,
            content: ruleContent,
            extra: ruleExtra,
          },
        ]}
        keyValue={`collapse-rule-${ruleNumberPosition}`}
        defaultActiveKey={openedCollapses[ruleNumberPosition - 1] && `collapse-rule-${ruleNumberPosition}`}
        onChange={onCollapseChange}
      />
    </div>
  );
};

MasterPlanSettingsCommissionRulesFormRule.propTypes = {
  className: PropTypes.string,
  rule: PlanRuleModel.propTypes,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  ruleNumberPosition: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onCollapseChange: PropTypes.func,
  openedCollapses: PropTypes.array,
  onSubmitEditionName: PropTypes.func,
  trackingObjectType: PropTypes.string,
};

MasterPlanSettingsCommissionRulesFormRule.defaultProps = {
  className: '',
  rule: {},
  onChange: () => {},
  onRemove: () => {},
  onCollapseChange: () => {},
  openedCollapses: [],
  onSubmitEditionName: () => {},
  trackingObjectType: '',
};

export default MasterPlanSettingsCommissionRulesFormRule;
