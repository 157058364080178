import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import HighlightWidgetBase from '@palette/components/widgets/highlight/HighlightWidgetBase/HighlightWidgetBase';
import PlanProgressGaugeChart from '@palette/components/charts/PlanProgressGaugeChart/PlanProgressGaugeChart';

import * as HighlightComponentDataModel from '@palette/models/HighlightComponentData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';
import * as PlanProgressGaugeDataModel from '@palette/models/widgets/dashboard/PlanProgressGaugeData';

import styles from './HighlightProgressGaugeWidget.less';

const classNames = bindClassNames.bind(styles);

const HighlightProgressGaugeWidget = ({ className, widgetData }) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planProgressGaugeData = PlanProgressGaugeDataModel.transform(widgetData.data);

  return (
    <HighlightWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('planProgressGaugeWidget.title')}
    >
      <PlanProgressGaugeChart
        plan={planPeriodData.plan}
        data={planProgressGaugeData}
      />
    </HighlightWidgetBase>
  );
};

HighlightProgressGaugeWidget.propTypes = {
  className: PropTypes.string,
  widgetData: HighlightComponentDataModel.propTypes.isRequired,
};

HighlightProgressGaugeWidget.defaultProps = {
  className: '',
};

export default HighlightProgressGaugeWidget;
