import { PALETTE_DOCUMENTATION_URL } from '@palette/constants/global';

export const PLAN_PERIODICITY_TYPE = {
  MONTH: 'MONTH',
  QUARTER: 'QUARTER',
  SEMESTER: 'SEMESTER',
  YEAR: 'YEAR',
  DAY: 'DAY',
  CUSTOM: 'CUSTOM',
};

export const PLAN_STATUS_TYPE = {
  DRAFT: 'DRAFT',
  FINISHED: 'FINISHED',
};

export const PLAN_VARIABLE_SOURCE_TYPE = {
  PALETTE: 'PALETTE',
  CRM: 'CRM',
  MODELS: 'MODELS',
};

export const EDIT_PAGE_TYPE = {
  ACHIEVEMENT: 'ACHIEVEMENT',
  COMMISSION: 'COMMISSION',
};

export const ACHIEVEMENT_DOCUMENTATION_URL = `${PALETTE_DOCUMENTATION_URL}planV3/achievements`;

export const COMMISSION_DOCUMENTATION_URL = `${PALETTE_DOCUMENTATION_URL}planV3/commissions-statements`;

export const CODE_CELL_PARAMS = {
  codeEditorLanguage: 'python',
};

export const CELL_TYPE = {
  ACHIEVEMENTS: 'ACHIEVEMENTS',
  COMMISSIONS: 'COMMISSIONS',
};

export const INPUT_TABLE_TYPES = {
  USER: 'USER',
  PERIOD: 'PERIOD',
  CUSTOM: 'CUSTOM',
};
