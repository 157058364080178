import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import UserDetailsStatementsTab from '@palette/components/userDetails/UserDetailsStatementsTab/UserDetailsStatementsTab';
import UserDetailsPlansTab from '@palette/components/userDetails/UserDetailsPlansTab/UserDetailsPlansTab';
import UserDetailsTeamsTab from '@palette/components/userDetails/UserDetailsTeamsTab/UserDetailsTeamsTab';
import UserDetailsQuotasTab from '@palette/components/userDetails/UserDetailsQuotasTab/UserDetailsQuotasTab';
import UserDetailsDashboardPreviewTab from '@palette/components/userDetails/UserDetailsDashboardPreviewTab/UserDetailsDashboardPreviewTab';
import UserDetailsSettingsTab from '@palette/components/userDetails/UserDetailsSettingsTab/UserDetailsSettingsTab';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { USER_DETAILS_TABS_QS_KEY, USER_DETAILS_TABS_IDS } from '@palette/constants/tabs';
import { FEATURES, RIGHTS } from '@palette/constants/profile';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserDetailsTabs.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsTabs = ({ className, user }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const finalTabs = [];

    if (hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) {
      finalTabs.push({
        tabKey: USER_DETAILS_TABS_IDS.STATEMENTS,
        title: t('userDetailsTabs.tabs.statements'),
        content: (<UserDetailsStatementsTab user={user} />),
        defaultActive: true,
      });
    }

    if (hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) {
      finalTabs.push({
        tabKey: USER_DETAILS_TABS_IDS.PLANS,
        title: t('userDetailsTabs.tabs.plans'),
        content: (<UserDetailsPlansTab user={user} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.VIEW])) {
      finalTabs.push({
        tabKey: USER_DETAILS_TABS_IDS.TEAMS,
        title: t('userDetailsTabs.tabs.teams'),
        content: (<UserDetailsTeamsTab user={user} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.VIEW])) {
      finalTabs.push({
        tabKey: USER_DETAILS_TABS_IDS.QUOTAS,
        title: t('userDetailsTabs.tabs.quotas'),
        content: (<UserDetailsQuotasTab user={user} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.VIEW])) {
      finalTabs.push({
        tabKey: USER_DETAILS_TABS_IDS.DASHBOARD_PREVIEW,
        title: t('userDetailsTabs.tabs.dashboardPreview'),
        content: (<UserDetailsDashboardPreviewTab user={user} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.UPDATE])) {
      finalTabs.push({
        tabKey: USER_DETAILS_TABS_IDS.SETTINGS,
        title: t('userDetailsTabs.tabs.settings'),
        content: (<UserDetailsSettingsTab user={user} />),
      });
    }

    return finalTabs;
  }, [profile, user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        tabs={tabs}
        qsTabKey={USER_DETAILS_TABS_QS_KEY}
      />
    </div>
  );
};

UserDetailsTabs.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsTabs.defaultProps = {
  className: '',
};

export default UserDetailsTabs;
