import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntDForm } from 'antd';

import Select from '@palette/components/designSystem/Select/Select';
import Button from '@palette/components/designSystem/Button/Button';
import UnlinkQuotaFromMasterPlan from '@palette/components/masterPlanSettings/UnlinkQuotaFromMasterPlan/UnlinkQuotaFromMasterPlan';
import QuotaTargetStrategySelect from '@palette/components/masterPlanSettings/QuotaTargetStrategySelect/QuotaTargetStrategySelect';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Form from '@palette/components/designSystem/Form/Form';
import EditDefaultValuesPanel from '@palette/components/quota/EditDefaultValuesPanel/EditDefaultValuesPanel';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import { QUOTA_ACTIONS } from '@palette/constants/quotas';
import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as QuotaModel from '@palette/models/Quota';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';
import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsTeamQuotaSetupPanel.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsTeamQuotaSetupPanel = ({ className, plan, quota, inPlanCreationFlow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quotasList = useSelector(QuotasSelectors.getQuotasList);
  useEffect(() => {
    if (quotasList.length === 0) {
      dispatch(QuotasActions.getList());
    }
  }, [quotasList]);

  const createQuotaIsPending = useSelector(QuotasSelectors.createQuotaIsPending);
  const quotaTypes = useSelector(QuotasSelectors.getQuotaTypes);

  const [form] = AntDForm.useForm();

  const quotaTypesOptions = quotaTypes.map((type) => ({
    label: type,
    value: type,
  }));

  const initialValues = {
    type: undefined,
    defaultValue: 0,
  };

  const handleFinish = (values) => {
    const onSuccessCB = (quotaId) => {
      let otherUpdatedData = {};
      if (inPlanCreationFlow) {
        otherUpdatedData = {
          creationFlowStep: CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value,
          disableSuccessNotification: true,
        };
      }
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, quotaId, ...otherUpdatedData }));
    };

    const finalValues = {
      ...values,
      name: `${plan.name} Quota`,
      periodType: plan.periodType,
      frequency: plan.frequency,
      beginAt: plan.customBeginAt ? plan.customBeginAt : undefined,
      global: true,
    };

    dispatch(QuotasActions.createQuota({ ...finalValues, onSuccessCB }));
  };

  const handleCreateAndLinkQuota = () => form.submit();

  const updateQuotaIsPending = useSelector(QuotasSelectors.updateQuotaIsPending);

  const handleSaveNewDefaultValues = (newDefaultValues, onSuccessCB = null) => {
    dispatch(QuotasActions.updateQuota({
      quotaId: quota.id,
      name: quota.name,
      type: quota.type,
      defaultValues: newDefaultValues,
      action: QUOTA_ACTIONS.UPDATE_QUOTA_DEFAULT_VALUES,
      onSuccessCB,
    }));
  };

  const [customTeamTargetEdition, setCustomTeamTargetEdition] = useState(false);
  const [newCustomTeamTarget, setNewCustomTeamTarget] = useState(null);

  const handleCancelCustomTeamTargetEdition = useCallback(() => {
    setNewCustomTeamTarget(null);
    setCustomTeamTargetEdition(false);
  }, [setNewCustomTeamTarget, setCustomTeamTargetEdition]);

  const handleSubmitCustomTeamTargetEdition = useCallback(() => {
    const newDefaultValues = [];
    newDefaultValues.push({
      ...quota.defaultValues[0],
      value: newCustomTeamTarget,
    });
    handleSaveNewDefaultValues(newDefaultValues, handleCancelCustomTeamTargetEdition);
  }, [quota, handleSaveNewDefaultValues, newCustomTeamTarget, handleCancelCustomTeamTargetEdition]);

  const customTeamQuotaNode = useMemo(() => {
    if (quota === null || quota.periodType !== PERIOD_TYPES.CUSTOM) return null;

    if (customTeamTargetEdition) {
      return (
        <div className={styles.customTeamQuotaWrapper}>
          <div className={styles.customQuotaValue}>
            {quota.type}
          </div>
          <Input
            className={styles.customTeamQuotaInput}
            size="big"
            type="comaSeparatorFormatted"
            value={newCustomTeamTarget || quota.defaultValues[0].value}
            onChange={(newValue) => setNewCustomTeamTarget(newValue)}
          />
          <Button
            className={styles.actionButton}
            type="link"
            icon={(<ClosePopupFilled className={styles.actionTrashIcon} />)}
            onClick={handleCancelCustomTeamTargetEdition}
          />
          <Button
            className={styles.actionButton}
            type="link"
            icon={(<CheckFilled width={20} height={20} />)}
            onClick={handleSubmitCustomTeamTargetEdition}
          />
        </div>
      );
    }

    return (
      <div className={styles.customTeamQuotaWrapper}>
        <div className={styles.customQuotaValueWrapper}>
          <div className={styles.customQuotaValueType}>
            {quota.defaultValues[0].value}
          </div>
          <div className={styles.customQuotaValue}>
            {quota.type}
          </div>
        </div>
        <Button
          className={styles.actionButton}
          type="link"
          icon={(<PenFilled />)}
          onClick={() => setCustomTeamTargetEdition(true)}
        />
      </div>
    );
  }, [
    quota,
    customTeamTargetEdition,
    setCustomTeamTargetEdition,
    newCustomTeamTarget,
    setNewCustomTeamTarget,
    handleCancelCustomTeamTargetEdition,
    handleSubmitCustomTeamTargetEdition,
  ]);

  const editTeamQuotaPanelNode = useMemo(() => {
    if (quota === null) return null;

    if (quota.periodType === PERIOD_TYPES.CUSTOM) {
      return (
        <div className={styles.customQuotaWrapper}>
          <div className={styles.customQuotaTargetLabel}>
            {t('masterPlanSettingsQuotaSetupPanel.customTeamQuota.target.label')}
          </div>
          {customTeamQuotaNode}
        </div>
      );
    }

    return (
      <EditDefaultValuesPanel
        quotaId={quota.id}
        initialDefaultValues={quota.defaultValues}
        updateIsPending={updateQuotaIsPending}
        saveNewDefaultValues={handleSaveNewDefaultValues}
        displayQuotaInfos={false}
      />
    );
  }, [quota, updateQuotaIsPending, customTeamQuotaNode]);

  const contentNode = useMemo(() => {
    if (quota === null) {
      return (
        <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
          <FormItem
            name="type"
            label={t('quota.form.tracked.label')}
            required
            rules={[
              { required: true, message: t('quota.form.tracked.rules.required') },
            ]}
          >
            <Select
              size="big"
              showSearch
              addSearchToOptions
              addSearchToOptionsLabel={t('quota.form.tracked.addTypeToOptions')}
              searchNotFoundLabel={t('quota.form.tracked.searchNotFound')}
              placeholder={t('quota.form.tracked.placeholder')}
              options={quotaTypesOptions}
              disabled={createQuotaIsPending}
            />
          </FormItem>
          <FormItem
            name="defaultValue"
            label={plan.periodType === PERIOD_TYPES.CUSTOM ? t('quota.form.defaultValueForCustomAndTeam.label') : t('quota.form.defaultValueForTeam.label')}
            required
          >
            <Input size="big" type="comaSeparatorFormatted" disabled={createQuotaIsPending} />
          </FormItem>
          <Button onClick={handleCreateAndLinkQuota}>
            {t('masterPlanSettingsQuotaSetupPanel.createForTeamBtnLabel')}
          </Button>
        </Form>
      );
    }

    return (
      <div className={styles.quotaTableWrapper}>
        <div className={styles.quotaInfosWithActions}>
          <h3>{quota.name}</h3>
          <div className={styles.actions}>
            <QuotaTargetStrategySelect className={styles.action} plan={plan} />
            <UnlinkQuotaFromMasterPlan className={styles.action} plan={plan} />
          </div>
        </div>
        <div className={styles.quotaTable}>
          {editTeamQuotaPanelNode}
        </div>
      </div>
    );
  }, [
    quota,
    plan,
    form,
    createQuotaIsPending,
    quotaTypesOptions,
    initialValues,
    handleFinish,
    editTeamQuotaPanelNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlanSettingsTeamQuotaSetupPanel.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  quota: QuotaModel.propTypes,
  inPlanCreationFlow: PropTypes.bool,
};

MasterPlanSettingsTeamQuotaSetupPanel.defaultProps = {
  className: '',
  quota: null,
  inPlanCreationFlow: false,
};

export default MasterPlanSettingsTeamQuotaSetupPanel;
