import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _sortBy from 'lodash/sortBy';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import FolderIcon from '@palette/components/designSystem/FolderIcon/FolderIcon';
import EditDataConnectionConnectorButton from '@palette/components/planV3/DataSourcesList/EditDataConnectionConnectorButton/EditDataConnectionConnectorButton';

import { NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS } from '@palette/constants/notebooks';

import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './DataConnectionConnectorsListItem.less';

const classNames = bindClassNames.bind(styles);

const DataConnectionConnectorsListItem = ({ className, dataConnectionConnector, isPending }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapseChange = useCallback((openPanels) => {
    setIsOpen(openPanels.length > 0);
  }, []);

  const titleNode = useMemo(() => {
    const { type } = dataConnectionConnector;

    return (
      <div className={styles.titleWrapper}>
        <FolderIcon className={styles.titleIcon} isOpened={isOpen} />
        <div className={styles.title}>
          {type}
        </div>
      </div>
    );
  }, [
    dataConnectionConnector,
    isOpen,
  ]);

  const extraNode = useMemo(() => (
    <EditDataConnectionConnectorButton dataConnectionConnector={dataConnectionConnector} isPending={isPending} />
  ), [dataConnectionConnector, isPending]);

  const columnsNodes = useMemo(() => {
    const sortedDataConnectionConnector = _sortBy(dataConnectionConnector.columns, ['name']);

    return sortedDataConnectionConnector.map((column) => {
      const TypeIcon = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS[column.dataSelectorBlockListItemType];

      return (
        <div key={column.name} className={styles.columnWrapper}>
          <TypeIcon className={styles.icon} />
          <div className={styles.column}>
            {column.name}
          </div>
        </div>
      );
    });
  }, [dataConnectionConnector]);

  return (
    <Collapse
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      panels={[
        {
          title: titleNode,
          extra: extraNode,
          content: (
            <div className={styles.columns}>
              {columnsNodes}
            </div>
          ),
        },
      ]}
      ghost
      onChange={handleCollapseChange}
    />
  );
};

DataConnectionConnectorsListItem.propTypes = {
  className: PropTypes.string,
  dataConnectionConnector: NotebookDataConnectionConnectorModel.propTypes.isRequired,
  isPending: PropTypes.bool,
};

DataConnectionConnectorsListItem.defaultProps = {
  className: '',
  isPending: false,
};

export default DataConnectionConnectorsListItem;
