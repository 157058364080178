import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import FreezeFilled from '@palette/components/utils/Icons/FreezeFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { periodIsFrozen, isPlanAutoFreezeEnabled } from '@palette/helpers/MasterPlanHelper';
import { getPreviousPeriod } from '@palette/helpers/FrequencyHelper';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './MasterPlanPeriodFreezeButton.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodFreezeButton = ({ className, onConfirm, plan, period }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const updateLastFrozenPeriod = (lastFrozenPeriod) => {
    dispatch(MasterPlansActions.updatePlan({ planId: plan.id, lastFrozenPeriod }));
  };

  const handleUnfreeze = () => {
    if (onConfirm) {
      onConfirm('unfreeze');
    }
    const previousPeriod = getPreviousPeriod(plan.periodType, plan.frequency, period.year, period.period);
    updateLastFrozenPeriod(previousPeriod);
  };

  const handleFreeze = () => {
    if (onConfirm) {
      onConfirm('freeze');
    }
    updateLastFrozenPeriod({ year: period.year, period: period.period });
  };

  const handleClickButton = () => {
    setTooltipVisible(false);
  };

  const freezeBtnNode = useMemo(() => {
    if (periodIsFrozen(plan, period)) {
      const planAutoFreezeEnabled = isPlanAutoFreezeEnabled(plan);

      return (
        <Popconfirm
          title={t('masterPlanPeriodFreezeButton.unfreezeFromPeriod.popconfirm')}
          onConfirm={handleUnfreeze}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
        >
          <Tooltip
            title={t('masterPlanPeriodFreezeButton.unfreezeFromPeriod.tooltip')}
            visible={tooltipVisible}
            onVisibleChange={(visible) => setTooltipVisible(visible)}
          >
            <Button
              className={styles.freezeBtn}
              type="linkSecondary"
              icon={<FreezeFilled />}
              onClick={handleClickButton}
              disabled={planAutoFreezeEnabled}
            >
              {planAutoFreezeEnabled && t('masterPlanPeriodFreezeButton.autoFreezeEnabled')}
              {!planAutoFreezeEnabled && t('masterPlanPeriodFreezeButton.unfreezeFromPeriod')}
            </Button>
          </Tooltip>
        </Popconfirm>
      );
    }

    return (
      <Popconfirm
        title={t('masterPlanPeriodFreezeButton.freezeUntilPeriod.popconfirm')}
        onConfirm={handleFreeze}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Tooltip
          title={t('masterPlanPeriodFreezeButton.freezeUntilPeriod.tooltip')}
          visible={tooltipVisible}
          onVisibleChange={(visible) => setTooltipVisible(visible)}
        >
          <Button
            className={styles.freezeBtn}
            type="linkSecondary"
            icon={<FreezeFilled />}
            onClick={handleClickButton}
          >
            {t('masterPlanPeriodFreezeButton.freezeUntilPeriod')}
          </Button>
        </Tooltip>
      </Popconfirm>
    );
  }, [plan, period, tooltipVisible]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {freezeBtnNode}
    </div>
  );
};

MasterPlanPeriodFreezeButton.propTypes = {
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
};

MasterPlanPeriodFreezeButton.defaultProps = {
  className: '',
  onConfirm: undefined,
};

export default MasterPlanPeriodFreezeButton;
