import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import MyMasterPlanList from '@palette/components/ic/myMasterPlan/MyMasterPlanList/MyMasterPlanList';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { IC_PLANS_PAGE_CONTENT_TABS_IDS, IC_PLANS_PAGE_CONTENT_QS_KEY } from '@palette/constants/tabs';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MyMasterPlansPageContent.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlansPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationTab] = useKeyInSearch(IC_PLANS_PAGE_CONTENT_QS_KEY);

  const [currentTab, setCurrentTab] = useState('');
  const [listLoaded, setListLoaded] = useState(false);
  const [lastPeriodsLoaded, setLastPeriodsLoaded] = useState({});

  const stats = useSelector(MasterPlansSelectors.getListStats);
  const getListIsPending = useSelector(MasterPlansSelectors.getListIsPending);

  const handleGetLastPeriodsSuccessCB = useCallback(() => {
    if (currentTab) {
      setLastPeriodsLoaded({
        ...lastPeriodsLoaded,
        [currentTab]: true,
      });
    }
  }, [currentTab, lastPeriodsLoaded]);

  const getListStatsByTabType = useCallback((type) => {
    if (
      (type === IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE || type === IC_PLANS_PAGE_CONTENT_TABS_IDS.PAST)
      && !lastPeriodsLoaded[type]
    ) {
      dispatch(MasterPlansActions.getListICLastPeriods({ type, onSuccessCB: handleGetLastPeriodsSuccessCB }));
    }
  }, [lastPeriodsLoaded, handleGetLastPeriodsSuccessCB]);

  const handleGetListSuccessCB = useCallback(() => {
    setListLoaded(true);
    getListStatsByTabType(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!currentTab && !locationTab) {
      setCurrentTab(IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE);
    } else if (locationTab && (locationTab !== currentTab)) {
      setCurrentTab(locationTab);
    }

    if ((currentTab === locationTab) || (!locationTab && currentTab === IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE)) {
      if (listLoaded) {
        getListStatsByTabType(currentTab);
      } else {
        dispatch(MasterPlansActions.getICList({ onSuccessCB: handleGetListSuccessCB }));
      }
    }
  }, [currentTab, locationTab]);

  const getTabPropsByType = useCallback((type) => ({
    title: t(`masterPlansPageContent.tabs.${type}`),
    titleCount: stats[type] || 0,
    content: (
      <MyMasterPlanList listType={type} />
    ),
    defaultActive: type === IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE,
    tabKey: type,
  }), [stats]);

  const tabs = useMemo(() => {
    const finalTabsIds = [IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE, IC_PLANS_PAGE_CONTENT_TABS_IDS.PAST];

    /**
     * Do not display Archived tab if no plan inside
     */
    if (!getListIsPending && stats[IC_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED] !== 0) {
      finalTabsIds.push(IC_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED);
    }

    return finalTabsIds.map((type) => getTabPropsByType(type));
  }, [getListIsPending, stats, getTabPropsByType]);

  const handleTabChange = (key) => setCurrentTab(Object.values(IC_PLANS_PAGE_CONTENT_TABS_IDS)[key]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        className={styles.tabs}
        tabs={tabs}
        onChange={handleTabChange}
        qsTabKey={IC_PLANS_PAGE_CONTENT_QS_KEY}
      />
    </div>
  );
};

MyMasterPlansPageContent.propTypes = {
  className: PropTypes.string,
};

MyMasterPlansPageContent.defaultProps = {
  className: '',
};

export default MyMasterPlansPageContent;
