import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import GitPullRequest from '@palette/components/utils/Icons/GitPullRequest';

import * as MasterPlanInjectionModel from '@palette/models/MasterPlanInjection';

import routePaths from '@palette/config/routePaths';

import styles from './MasterPlanDependencies.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanDependencies = ({ className, injections }) => {
  const { t } = useTranslation();

  if (!injections.length > 0) return null;

  const dependenciesNode = injections.map((injection, i) => (
    <div className={styles.injectionItem} key={`dependency-${i}`}>
      <Link
        className={styles.injectionLink}
        path={routePaths.v2.planDetails}
        params={{ masterPlanId: injection.planId }}
      >
        {injection.name}
      </Link>
    </div>
  ));

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.titleContainer}>
        <GitPullRequest className={styles.icon} width={16} height={16} />
        <div className={styles.title}>{t('masterPlanDependencies.title')}</div>
      </div>
      <div className={styles.content}>
        {dependenciesNode}
      </div>
    </div>
  );
};

MasterPlanDependencies.propTypes = {
  className: PropTypes.string,
  injections: PropTypes.arrayOf(MasterPlanInjectionModel.propTypes),
};

MasterPlanDependencies.defaultProps = {
  className: '',
  injections: [],
};

export default MasterPlanDependencies;
