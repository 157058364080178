import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './QuotaTableTotalTitleCell.less';

const QuotaTableTotalTitleCell = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {t('quota.total')}
    </div>
  );
};

export default QuotaTableTotalTitleCell;
