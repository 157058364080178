import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import styles from './StatementApproversStatusIcon.less';

const classNames = bindClassNames.bind(styles);

const StatementApproversStatusIcon = ({ className, isInConflict }) => (
  <div
    className={classNames({
      wrapper: true,
      warning: isInConflict,
      [className]: className !== '',
    })}
  >
    <CheckFilled className={styles.icon} width={12} height={12} />
  </div>
);

StatementApproversStatusIcon.propTypes = {
  className: PropTypes.string,
  isInConflict: PropTypes.bool,
};

StatementApproversStatusIcon.defaultProps = {
  className: '',
  isInConflict: false,
};

export default StatementApproversStatusIcon;
