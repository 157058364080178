/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import { original } from 'immer';

/*
 * Initial State
 */
const initialState = {
  getListIsPending: false,
  getListInPlansIsPending: false,
  getCurrenciesListIsPending: false,
  inviteUserIsPending: false,
  resendUserInvitationIsPending: false,
  getUserByIdIsPending: false,
  getUserResourcesCommonDataIsPending: false,
  onboardUsersIsPending: false,
  updateUserIsPending: false,
  updateUserAccountIsPending: false,
  deactivateUserIsPending: false,
  unlinkResourceFromUserIsPending: false,
  linkResourcesToUserIsPending: false,
  offboardUserIsPending: false,
  resetLeaveDateIsPending: false,
  updateUserProfilePictureIsPending: false,
  getMergeConflictsIsPending: false,

  list: [],
  listInPlans: [],
  byId: {},
  currenciesList: [],
  onboardUsersFlow: null,
  onboardUsersCurrentFlowStep: null,
  onboardCurrentUsers: [],
  mergeUserConflits: null,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get List */
    getList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { users = [] } = payload;
      state.list = _map(users, 'id');
      state.byId = _merge(state.byId, _keyBy(users, 'id'));
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Get User By Id */
    getUserById: (state) => {
      state.getUserByIdIsPending = true;
    },
    setUser: (state, { payload }) => {
      const { user = null } = payload;

      if (user !== null) {
        const clonedById = _cloneDeep(original(state.byId));
        clonedById[user.id] = user;
        state.byId = clonedById;
      }
    },
    getUserByIdCompleted: (state) => {
      state.getUserByIdIsPending = false;
    },
    /* Invite user */
    inviteUser: (state) => {
      state.inviteUserIsPending = true;
    },
    inviteUserCompleted: (state) => {
      state.inviteUserIsPending = false;
    },
    /* Resend User Invitation */
    resendUserInvitation: (state) => {
      state.resendUserInvitationIsPending = true;
    },
    resendUserInvitationCompleted: (state) => {
      state.resendUserInvitationIsPending = false;
    },
    /* Get List In Plans */
    getListInPlans: (state) => {
      state.getListInPlansIsPending = true;
    },
    setListInPlans: (state, { payload }) => {
      const { usersInPlans } = payload;
      state.listInPlans = usersInPlans;
    },
    getListInPlansCompleted: (state) => {
      state.getListInPlansIsPending = false;
    },
    /* Get Currenclies List */
    getCurrenciesList: (state) => {
      state.getCurrenciesListIsPending = true;
    },
    setCurrenciesList: (state, { payload }) => {
      const { currencies } = payload;
      state.currenciesList = currencies;
    },
    getCurrenciesListCompleted: (state) => {
      state.getCurrenciesListIsPending = false;
    },
    /* Onboard users */
    setOnboardUsersFlow: (state, { payload }) => {
      const { onboardUsersFlow, onboardUsersCurrentFlowStep = null } = payload;

      state.onboardUsersFlow = onboardUsersFlow;
      state.onboardUsersCurrentFlowStep = onboardUsersCurrentFlowStep;
      state.onboardCurrentUsers = [];
    },
    setOnboardUsersCurrentFlowStep: (state, { payload }) => {
      const { onboardUsersCurrentFlowStep } = payload;

      state.onboardUsersCurrentFlowStep = onboardUsersCurrentFlowStep;
    },
    getUserResourcesCommonData: (state) => {
      state.getUserResourcesCommonDataIsPending = true;
    },
    getUserResourcesCommonDataCompleted: (state) => {
      state.getUserResourcesCommonDataIsPending = false;
    },
    setOnboardCurrentUsers: (state, { payload }) => {
      const { users } = payload;

      state.onboardCurrentUsers = users;
    },
    onboardUsers: (state) => {
      state.onboardUsersIsPending = true;
    },
    onboardUsersCompleted: (state) => {
      state.onboardUsersIsPending = false;
    },
    /* Update user */
    updateUser: (state) => {
      state.updateUserIsPending = true;
    },
    updateUserCompleted: (state) => {
      state.updateUserIsPending = false;
    },
    /* Update user account */
    updateUserAccount: (state) => {
      state.updateUserAccountIsPending = true;
    },
    updateUserAccountCompleted: (state) => {
      state.updateUserAccountIsPending = false;
    },
    /* Deactivate user */
    deactivateUser: (state) => {
      state.deactivateUserIsPending = true;
    },
    deactivateUserCompleted: (state) => {
      state.deactivateUserIsPending = false;
    },
    /* Unlink Resource From user */
    unlinkResourceFromUser: (state) => {
      state.unlinkResourceFromUserIsPending = true;
    },
    unlinkResourceFromUserCompleted: (state) => {
      state.unlinkResourceFromUserIsPending = false;
    },
    /* Link Resources To user */
    linkResourcesToUser: (state) => {
      state.linkResourcesToUserIsPending = true;
    },
    linkResourcesToUserCompleted: (state) => {
      state.linkResourcesToUserIsPending = false;
    },
    /* Offboard user */
    offboardUser: (state) => {
      state.offboardUserIsPending = true;
    },
    offboardUserCompleted: (state) => {
      state.offboardUserIsPending = false;
    },
    /* Reset user leave date */
    resetLeaveDate: (state) => {
      state.resetLeaveDateIsPending = true;
    },
    resetLeaveDateCompleted: (state) => {
      state.resetLeaveDateIsPending = false;
    },
    /* Update User's Profile Picture */
    updateUserProfilePicture: (state) => {
      state.updateUserProfilePictureIsPending = true;
    },
    updateUserProfilePictureCompleted: (state) => {
      state.updateUserProfilePictureIsPending = false;
    },
    /* Get User Merge Conflicts */
    getMergeConflicts: (state) => {
      state.getMergeConflictsIsPending = true;
    },
    setMergeUserConflits: (state, { payload }) => {
      const { mergeUserConflits } = payload;

      state.mergeUserConflits = mergeUserConflits;
    },
    getMergeConflictsCompleted: (state) => {
      state.getMergeConflictsIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getList = (state) => root(state).list;
const getListInPlans = (state) => root(state).listInPlans;
const getById = (state) => root(state).byId;
const getCurrenciesList = (state) => root(state).currenciesList;
const getOnboardUsersFlow = (state) => root(state).onboardUsersFlow;
const getOnboardUsersCurrentFlowStep = (state) => root(state).onboardUsersCurrentFlowStep;
const getOnboardCurrentUsers = (state) => root(state).onboardCurrentUsers;
const getMergeUserConflits = (state) => root(state).mergeUserConflits;

const getListIsPending = (state) => root(state).getListIsPending;
const getListInPlansIsPending = (state) => root(state).getListInPlansIsPending;
const getCurrenciesListIsPending = (state) => root(state).getCurrenciesListIsPending;
const inviteUserIsPending = (state) => root(state).inviteUserIsPending;
const resendUserInvitationIsPending = (state) => root(state).resendUserInvitationIsPending;
const getUserByIdIsPending = (state) => root(state).getUserByIdIsPending;
const getUserResourcesCommonDataIsPending = (state) => root(state).getUserResourcesCommonDataIsPending;
const onboardUsersIsPending = (state) => root(state).onboardUsersIsPending;
const updateUserIsPending = (state) => root(state).updateUserIsPending;
const updateUserAccountIsPending = (state) => root(state).updateUserAccountIsPending;
const deactivateUserIsPending = (state) => root(state).deactivateUserIsPending;
const unlinkResourceFromUserIsPending = (state) => root(state).unlinkResourceFromUserIsPending;
const linkResourcesToUserIsPending = (state) => root(state).linkResourcesToUserIsPending;
const offboardUserIsPending = (state) => root(state).offboardUserIsPending;
const resetLeaveDateIsPending = (state) => root(state).resetLeaveDateIsPending;
const updateUserProfilePictureIsPending = (state) => root(state).updateUserProfilePictureIsPending;
const getMergeConflictsIsPending = (state) => root(state).getMergeConflictsIsPending;

const getUsersList = createSelector(
  [getList, getById],
  (listIds, byId) => listIds.map((userId) => byId[userId] || null),
);

const getUserById = createSelector(
  [getById, getProps],
  (byId, { userId }) => byId[userId] || null,
);

export const selectors = {
  getListIsPending,
  getListInPlansIsPending,
  getCurrenciesListIsPending,
  inviteUserIsPending,
  resendUserInvitationIsPending,
  getUserByIdIsPending,
  getUserResourcesCommonDataIsPending,
  onboardUsersIsPending,
  updateUserIsPending,
  updateUserAccountIsPending,
  deactivateUserIsPending,
  unlinkResourceFromUserIsPending,
  linkResourcesToUserIsPending,
  offboardUserIsPending,
  resetLeaveDateIsPending,
  updateUserProfilePictureIsPending,
  getMergeConflictsIsPending,

  getUsersList,
  getListInPlans,
  getUserById,
  getCurrenciesList,
  getOnboardUsersFlow,
  getOnboardUsersCurrentFlowStep,
  getOnboardCurrentUsers,
  getMergeUserConflits,
};
