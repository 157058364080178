import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _sortBy from 'lodash/sortBy';

import Modal from '@palette/components/designSystem/Modal/Modal';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import Alert from '@palette/components/designSystem/Alert/Alert';
import BlingBlingIllustration from '@palette/components/utils/Icons/BlingBlingIllustration';
import Button from '@palette/components/designSystem/Button/Button';
import CommissionV3EditPayoutPaymentRow from '@palette/components/commissionV3/CommissionV3EditPayoutPaymentRow/CommissionV3EditPayoutPaymentRow';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { getTotalPayments } from '@palette/helpers/CommissionHelper';
import { getCommissionV3Amount, getScheduleFromPercentage } from '@palette/helpers/CommissionV3Helper';
import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { addCurrencyToNode, formatPrice, getCurrencySymbol } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { ALERT_TYPES } from '@palette/constants/alert';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import { actions as CommissionV3Actions, selectors as CommissionV3Selectors } from '@palette/state/CommissionV3';

import styles from './CommissionV3PaymentsEditPayoutScheduleModal.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3PaymentsEditPayoutScheduleModal = ({ visible, onClose, commission }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const moment = getMoment();

  const overwritePaymentsIsPending = useSelector(CommissionV3Selectors.overwritePaymentsIsPending);

  const [usePercentage, setUsePercentage] = useState(true);

  const commissionAmount = useMemo(() => getCommissionV3Amount(commission), [commission, visible]);
  const initialSchedule = useMemo(() => getScheduleFromPercentage(commission), [commission, visible]);

  const [schedule, setSchedule] = useState(initialSchedule);

  const sortAndSetSchedule = (newSchedule) => {
    setSchedule(_sortBy(newSchedule, ['date']));
  };

  const [totalAmount, totalPercentage] = useMemo(() => getTotalPayments(schedule), [schedule]);

  useEffect(() => {
    setUsePercentage(true);
    sortAndSetSchedule(getScheduleFromPercentage(commission));
  }, [commission, visible]);

  const cleanAndClose = () => {
    setUsePercentage(true);
    sortAndSetSchedule(getScheduleFromPercentage(commission));
    onClose();
  };

  const handleSavePayoutSchedule = () => {
    dispatch(CommissionV3Actions.overwritePayments({ commissionId: commission.id, payments: schedule, onSuccessCB: cleanAndClose }));
  };

  const handleResetToOriginalSchedule = () => {
    dispatch(CommissionV3Actions.overwritePayments({ commissionId: commission.id, payments: commission.originalPayments, onSuccessCB: cleanAndClose }));
  };

  const handleDeletePayment = useCallback((index) => {
    const newSchedule = _cloneDeep(schedule);
    newSchedule.splice(index, 1);
    setSchedule(newSchedule);
  }, [schedule]);

  const handleSetDate = useCallback((index, newDate) => {
    const newSchedule = _cloneDeep(schedule);
    newSchedule[index].date = newDate;
    sortAndSetSchedule(newSchedule);
  }, [schedule, sortAndSetSchedule]);

  const handleSetPercentage = useCallback((index, newPercentage) => {
    const newSchedule = _cloneDeep(schedule);
    newSchedule[index].percentage = newPercentage;
    newSchedule[index].amount = commissionAmount * newPercentage;
    setSchedule(newSchedule);
  }, [schedule, commissionAmount]);

  const handleSetAmount = useCallback((index, newAmount) => {
    const newSchedule = _cloneDeep(schedule);
    newSchedule[index].percentage = newAmount / commissionAmount;
    newSchedule[index].amount = newAmount;
    setSchedule(newSchedule);
  }, [schedule, commissionAmount]);

  const handleAddPayment = useCallback(() => {
    const newSchedule = _cloneDeep(schedule);

    const lastSceduledDate = schedule.length > 0 ? moment.utc(schedule[schedule.length - 1].date) : moment.utc();

    const newPercentage = Math.max(100 - totalPercentage, 0) / 100;
    const newAmount = floatToFixedNumber(commissionAmount * newPercentage);

    newSchedule.push({
      date: moment.utc(lastSceduledDate).add(1, 'day').format(),
      percentage: newPercentage,
      amount: newAmount,
    });
    setSchedule(newSchedule);
  }, [schedule, totalAmount, totalPercentage, commissionAmount]);

  const splittedFormattedValue = comaSeparatorFormatter(commissionAmount).split('.');
  const valuesNodes = [];
  valuesNodes.push((
    <div key="integerPart" className={styles.integerPart}>
      {splittedFormattedValue[0]}
    </div>
  ));
  if (splittedFormattedValue.length > 1) {
    valuesNodes.push((
      <div key="decimalPart" className={styles.decimalPart}>
        {`.${splittedFormattedValue[1]}`}
      </div>
    ));
  }

  const resetNode = (
    <Popconfirm
      title={t('paymentsEditPayoutScheduleModal.resetPayout.popconfirm.title')}
      onConfirm={handleResetToOriginalSchedule}
      okText={t('paymentsEditPayoutScheduleModal.resetPayout.popconfirm.confirm')}
      cancelText={t('paymentsEditPayoutScheduleModal.resetPayout.popconfirm.cancel')}
      size="small"
    >
      <Button
        className={styles.resetToOriginalButton}
        type="link"
      >
        {t('paymentsEditPayoutScheduleModal.resetPayout.popconfirm.cta')}
      </Button>
    </Popconfirm>
  );

  return (
    <Modal
      className={styles.modal}
      title={t('paymentsEditPayoutScheduleModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleSavePayoutSchedule}
      okText={t('paymentsEditPayoutScheduleModal.save')}
      okIcon={<CheckFilled />}
      loading={overwritePaymentsIsPending}
      extraFooterNode={resetNode}
    >
      <div
        className={classNames({
          contentWrapper: true,
        })}
      >
        <Alert
          type={ALERT_TYPES.WARNING}
          message={t('paymentsEditPayoutScheduleModal.warning')}
        />
        <div className={styles.commissionAmountWrapper}>
          <BlingBlingIllustration className={styles.illustration} />
          <div className={styles.amount}>
            {addCurrencyToNode(valuesNodes, commission.currency)}
          </div>
          <div className={styles.label}>
            {t('paymentsEditPayoutScheduleModal.commission')}
          </div>
        </div>
        <div className={styles.scheduleWrapper}>
          <div className={styles.header}>
            <div className={styles.label}>
              {t('paymentsEditPayoutScheduleModal.payments')}
            </div>
            <div className={styles.modeSelectWrapper}>
              <div className={styles.modeSelectLabel}>
                {t('paymentsEditPayoutScheduleModal.use')}
              </div>
              <Button
                className={styles.modeSelectBtn}
                type="flat"
                pressed={!usePercentage}
                onClick={() => setUsePercentage(false)}
              >
                {getCurrencySymbol(commission.currency)}
              </Button>
              <Button
                className={styles.modeSelectBtn}
                type="flat"
                pressed={usePercentage}
                onClick={() => setUsePercentage(true)}
              >
                %
              </Button>
            </div>
          </div>
          <div className={styles.content}>
            {
              schedule.map((payment, index) => (
                <CommissionV3EditPayoutPaymentRow
                  key={index}
                  className={styles.payment}
                  payment={payment}
                  commissionAmount={commissionAmount}
                  index={index}
                  usePercentage={usePercentage}
                  currency={commission.currency}
                  onDelete={handleDeletePayment}
                  setDate={handleSetDate}
                  setPercentage={handleSetPercentage}
                  setAmount={handleSetAmount}
                  disabled={overwritePaymentsIsPending}
                />
              ))
            }
            <Button
              className={styles.addButton}
              type="link"
              icon={<AddFilled />}
              onClick={handleAddPayment}
              disabled={overwritePaymentsIsPending}
            >
              {t('paymentsEditPayoutScheduleModal.addPayment')}
            </Button>
          </div>
          <div className={styles.footer}>
            <div className={styles.totalLabel}>
              {t('paymentsEditPayoutScheduleModal.total')}
            </div>
            <div className={styles.totalAmount}>
              {formatPrice(totalAmount, commission.currency)}
            </div>
            <div className={styles.totalPercentage}>
              {`(${totalPercentage}%)`}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CommissionV3PaymentsEditPayoutScheduleModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
};

CommissionV3PaymentsEditPayoutScheduleModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CommissionV3PaymentsEditPayoutScheduleModal;
