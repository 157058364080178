import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { TreeSelect as AntDTreeSelect } from 'antd';

import ArrowRightSquareLine from '@palette/components/utils/Icons/ArrowRightSquareLine';
import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';
import SearchLine from '@palette/components/utils/Icons/SearchLine';

import styles from './TreeSelect.less';

const classNames = bindClassNames.bind(styles);

export const getOptionsNodes = (options) => options.map(({ label, value, options: subOptions = [], ...otherOptionProps }) => {
  let subOptionsNodes = null;
  if (subOptions.length > 0) {
    subOptionsNodes = getOptionsNodes(subOptions);
  }
  return (
    <AntDTreeSelect.TreeNode key={value} value={value} title={label} {...otherOptionProps}>
      {subOptionsNodes}
    </AntDTreeSelect.TreeNode>
  );
});

const TreeSelect = ({
  className,
  options,
  defaultSelectedValue,
  placeholder,
  disabled,
  showSearch,
  searchNotFoundLabel,
  defaultOpen,
  onSelect,
  onChange,
  onSearch,
  onDropdownVisibleChange,
  open,
  size,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue || placeholder !== '' ? '-1' : '');
  const [dropdownOpen, setDropdownOpen] = useState(defaultOpen);

  const optionsNodes = getOptionsNodes(options);
  if (placeholder !== '') {
    optionsNodes.unshift((<AntDTreeSelect.TreeNode key="-1" value="-1" title={placeholder} />));
  }

  const notFoundContentNode = (
    <div className={styles.not_found_content_description}>
      {searchNotFoundLabel || t('treeSelect.searchNotFound')}
    </div>
  );

  const handleDropdownVisibleChange = (value) => {
    setDropdownOpen(value);
    onDropdownVisibleChange(value);
  };

  const dropdownIcon = (<ChevronDownLine width={24} height={24} onClick={() => setDropdownOpen(!dropdownOpen)} />);

  let managedProps = {
    disabled,
    onSearch,
    onSelect,
    onChange,
    suffixIcon: dropdownIcon,
    treeNodeFilterProp: 'title',
    notFoundContent: notFoundContentNode,
    switcherIcon: (<ArrowRightSquareLine />),
    treeLine: { showLeafIcon: false },
    treeDefaultExpandAll: true,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    open: open !== null ? open : dropdownOpen,
  };

  if (showSearch) {
    const handleOnSelect = (value, opt) => {
      setSelectedValue(value);
      onSelect(value, opt);
      onChange(value, opt);
    };

    managedProps = {
      ...managedProps,
      suffixIcon: dropdownOpen ? (<SearchLine width={18} height={18} />) : dropdownIcon,
      showSearch,
      onSelect: handleOnSelect,
      value: selectedValue,
      open: dropdownOpen,
    };
  }

  return (
    <AntDTreeSelect
      className={classNames({
        wrapper: true,
        big: size === 'big',
        [className]: className !== '',
      })}
      dropdownClassName={classNames({
        dropdown: true,
        firstIsPlaceholder: placeholder !== '',
        onlyOneChoice: optionsNodes.length === 1,
      })}
      {...managedProps}
      {...otherProps}
    >
      {optionsNodes}
    </AntDTreeSelect>
  );
};

const treeSelectOptionShape = {
  label: PropTypes.any,
  value: PropTypes.any,
};
treeSelectOptionShape.options = PropTypes.arrayOf(PropTypes.shape(treeSelectOptionShape));

TreeSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(treeSelectOptionShape)).isRequired,
  defaultSelectedValue: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showSearch: PropTypes.bool,
  searchNotFoundLabel: PropTypes.string,
  defaultOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  open: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'big']),
};

TreeSelect.defaultProps = {
  className: '',
  defaultSelectedValue: null,
  placeholder: '',
  disabled: false,
  showSearch: false,
  searchNotFoundLabel: null,
  defaultOpen: false,
  onSelect: () => {},
  onChange: () => {},
  onSearch: undefined,
  onDropdownVisibleChange: () => {},
  open: null,
  size: 'default',
};

export default TreeSelect;
