import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES } from '@palette/constants/notebooks';

export const modelName = 'FieldDefinition';

export const propTypes = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  dataSelectorBlockListItemType: PropTypes.oneOf(Object.values(NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES)),
});

export const transform = (fieldDefinition) => {
  if (!fieldDefinition) {
    return null;
  }

  const type = manageStringAttribute(fieldDefinition, 'type');

  let dataSelectorBlockListItemType = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.SELECTABLE;

  if (type === 'object') {
    dataSelectorBlockListItemType = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.OBJECT;
  }

  if (type === 'array' || type === 'list') {
    dataSelectorBlockListItemType = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.ARRAY;
  }

  return (
    {
      name: manageStringAttribute(fieldDefinition, 'name'),
      type,
      dataSelectorBlockListItemType,
    }
  );
};

export const transformList = (fieldDefinitions) => fieldDefinitions.map((fieldDefinition) => transform(fieldDefinition));
