import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CommissionDrawerEditVariablesList from '@palette/components/commission/CommissionDrawerEditVariablesList/CommissionDrawerEditVariablesList';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getColumnValue } from '@palette/helpers/ConnectorHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionDrawerVariablesTab.less';

const classNames = bindClassNames.bind(styles);

const CommissionDrawerVariablesTab = ({ className, commission, viewOnly }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const variables = commission.scopeParams?.plan.variables;

  const variablesNodes = useMemo(() => {
    if (!variables.length) {
      return (
        <DefaultEmptyState
          description={viewOnly ? t('commissionDrawerVariablesTab.emptyState2') : t('commissionDrawerVariablesTab.emptyState1')}
        />
      );
    }

    return variables.map((variable, index) => (
      <div className={styles.variable} key={`planVariable_${index}_${variable.name}`}>
        <div className={styles.name}>
          {variable.name}
        </div>
        <div className={styles.value}>
          {getColumnValue(commission.scopeParams?.resourceObject?.data || {}, variable)}
        </div>
      </div>
    ));
  }, [variables, viewOnly]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {!viewOnly && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE]) && (
        <CommissionDrawerEditVariablesList commission={commission} />
      )}
      <div className={styles.variables}>
        {variablesNodes}
      </div>
    </div>
  );
};

CommissionDrawerVariablesTab.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  viewOnly: PropTypes.bool,
};

CommissionDrawerVariablesTab.defaultProps = {
  className: '',
  viewOnly: false,
};

export default CommissionDrawerVariablesTab;
