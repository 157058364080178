import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import PlanV3ListItem from '@palette/components/ic/planV3/PlanV3ListItem/PlanV3ListItem';

import { selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3List.less';

const classNames = bindClassNames.bind(styles);

const PlanV3List = ({ className, searchedPlan }) => {
  const { t } = useTranslation();

  const listIsPending = useSelector(PlanV3Selectors.getListIsPending);

  const plansList = useSelector((state) => PlanV3Selectors.getList(state));

  const filteredPlansList = useMemo(() => {
    if (plansList.length === 0 || searchedPlan === '') return plansList;

    return plansList.filter((plan) => plan.name.toLowerCase().indexOf(searchedPlan.toLowerCase()) > -1);
  }, [plansList, searchedPlan]);

  let listContentNode = (
    <DefaultEmptyState description={t('masterPlanList.empty.description')} />
  );

  if (listIsPending) {
    listContentNode = (
      <Loader />
    );
  } else if (filteredPlansList.length > 0) {
    listContentNode = filteredPlansList.map((plan) => (
      <PlanV3ListItem key={plan.id} plan={plan} />
    ));
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {listContentNode}
    </div>
  );
};

PlanV3List.propTypes = {
  className: PropTypes.string,
  searchedPlan: PropTypes.string,
};

PlanV3List.defaultProps = {
  className: '',
  searchedPlan: '',
};

export default PlanV3List;
