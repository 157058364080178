import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  RESOURCE_404_OBJECT_NOT_FOUND: {
    code: 'resources.errors.object.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  RESOURCE_DELETION_SUCCESS: {
    code: 'notifications.resources.resource_deleted.success.message',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  RESOURCE_DATA_COPY_TO_CLIPBOARD_SUCCESS: {
    code: 'notifications.resources.resource_data_copied.success.message',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  RESOURCE_DATA_COPY_TO_CLIPBOARD_ERROR: {
    code: 'notifications.resources.resource_data_copied.error.message',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
