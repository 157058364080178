import PropTypes from 'prop-types';

import {
  manageDateAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'HighlightComponentParams';

export const propTypes = PropTypes.shape({
  planId: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
});

export const transform = (highlightComponentParams) => {
  if (!highlightComponentParams) {
    return null;
  }

  return (
    {
      planId: manageStringAttribute(highlightComponentParams, 'planFilter', null),
      from: manageDateAttribute(highlightComponentParams, 'from', null),
      to: manageDateAttribute(highlightComponentParams, 'to', null),
    }
  );
};

export const transformList = (highlightComponentParamsList) => highlightComponentParamsList.map((highlightComponentParams) => transform(highlightComponentParams));
