import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import StatementPanelPeriodsNavigation from '@palette/components/statement/statementPeriod/StatementPanelPeriodsNavigation/StatementPanelPeriodsNavigation';
import Loader from '@palette/components/utils/Loader/Loader';
import UserStatementContent from '@palette/components/statement/userStatement/UserStatementContent/UserStatementContent';
import StatementRecomputeWarning from '@palette/components/statement/statementCommon/StatementRecomputeWarning/StatementRecomputeWarning';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useComputedPeriodsDatesFilter, useCurrentStrategyDefaultDatesType } from '@palette/hooks/StatementHooks';

import { LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';
import { USER_STATEMENT_CONTENT_TABS_QS_KEY } from '@palette/constants/tabs';

import { getUserStatementsPanelContext } from '@palette/helpers/StatementHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as NavigationActions } from '@palette/state/Navigation';
import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements';

import styles from './UserDetailsStatementsTab.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsStatementsTab = ({ className, user }) => {
  const dispatch = useDispatch();
  const moment = getMoment();

  const UserStatementsPanelContext = getUserStatementsPanelContext();

  const profile = useProfile();
  const [currency, handleCurrencyChange] = useState(null);

  const { defaultDatesType } = useCurrentStrategyDefaultDatesType();

  const [datesType, setDatesType] = useState(defaultDatesType);
  const [datesFrom, setDatesFrom] = useState(null);
  const [datesTo, setDatesTo] = useState(null);

  const resetPeriodsDatesFilter = useCallback(() => {
    setDatesType(defaultDatesType);
    setDatesFrom(null);
    setDatesTo(null);
  }, [defaultDatesType, setDatesType, setDatesFrom, setDatesTo]);

  const [typeToUse, fromToUse, toToUse] = useComputedPeriodsDatesFilter(datesType, datesFrom, datesTo, resetPeriodsDatesFilter);

  const listStatementPeriodsIsPending = useSelector(StatementsSelectors.listStatementPeriodsIsPending);
  const statementPeriods = useSelector(StatementsSelectors.getStatementPeriods);

  const userId = useMemo(() => user.id, [user]);

  useEffect(() => {
    dispatch(StatementsActions.listStatementPeriods({ currency, from: fromToUse, to: toToUse, userId }));
  }, [currency, fromToUse, toToUse]);

  const [statementPeriodId, setStatementPeriodId] = useState(null);

  useEffect(() => {
    if (statementPeriodId === null) {
      const nowMoment = moment.utc();

      const candidatePeriods = statementPeriods.filter((statementPeriod) => {
        const beginMomentDate = moment.utc(statementPeriod.beginDate);
        const endMomentDate = moment.utc(statementPeriod.endDate);

        return nowMoment.isBetween(beginMomentDate, endMomentDate, 'day', '[]');
      });

      if (candidatePeriods.length > 0) {
        const newStatementPeriodId = candidatePeriods[0].id;
        setStatementPeriodId(newStatementPeriodId);
      }
    }
  }, [statementPeriods]);

  const statementPeriod = useSelector((state) => StatementsSelectors.getStatementPeriodById(state, { statementPeriodId }));

  useEffect(() => {
    if (statementPeriodId !== null && userId !== null) {
      dispatch(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));
    }
  }, [statementPeriodId, currency]);

  const resetHistoryParams = () => dispatch(NavigationActions.updateAndCleanQSInLocation({
    keysToDelete: [
      PAGE_QS_KEY,
      LIMIT_QS_KEY,
      USER_STATEMENT_CONTENT_TABS_QS_KEY,
    ],
  }));

  const userStatement = useSelector((state) => StatementsSelectors.getUserStatement(state, { statementPeriodId, userId }));
  const getUserStatementByIdIsPending = useSelector((state) => StatementsSelectors.getUserStatementByIdIsPending(state));

  const recomputeWarningNode = useMemo(() => {
    if (!userStatement?.isDirty) return null;
    return <StatementRecomputeWarning />;
  }, [userStatement]);

  const contentNode = useMemo(() => {
    if (statementPeriod === null) return null;

    if (getUserStatementByIdIsPending) return <Loader />;

    return (
      <UserStatementContent
        statementPeriod={statementPeriod}
        userStatement={userStatement}
        currency={currency}
      />
    );
  }, [statementPeriod, userStatement, getUserStatementByIdIsPending, currency]);

  const userStatementsPanelContextValue = useMemo(() => ({
    user,
    currency,
    statementPeriodId,
    statementPeriod,
    setStatementPeriodId,
    datesType,
    setDatesType,
    datesFrom,
    setDatesFrom,
    datesTo,
    setDatesTo,
    listStatementPeriodsIsPending,
    statementPeriods,
    typeToUse,
    fromToUse,
    toToUse,
    resetHistoryParams,
  }), [
    user,
    currency,
    statementPeriodId,
    statementPeriod,
    setStatementPeriodId,
    datesType,
    setDatesType,
    datesFrom,
    setDatesFrom,
    datesTo,
    setDatesTo,
    listStatementPeriodsIsPending,
    statementPeriods,
    typeToUse,
    fromToUse,
    toToUse,
    resetHistoryParams,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <UserStatementsPanelContext.Provider value={userStatementsPanelContextValue}>
        <div className={styles.currencySelectWrapper}>
          <CompanyCurrencySelect
            enableMixedCurrencyOption
            defaultMixedCurrencyOptionValue={null}
            value={currency}
            onChange={handleCurrencyChange}
            disabled={profile.userData.company.currencies.length < 2}
          />
        </div>
        <StatementPanelPeriodsNavigation className={styles.periodsNavigationWrapper} />
        {recomputeWarningNode}
        {contentNode}
      </UserStatementsPanelContext.Provider>
    </div>
  );
};

UserDetailsStatementsTab.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsStatementsTab.defaultProps = {
  className: '',
};

export default UserDetailsStatementsTab;
