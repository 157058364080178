import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _flatMap from 'lodash/flatMap';
import { useDispatch, useSelector } from 'react-redux';

import { Drawer as AntDDrawer } from 'antd';

import RawDataHistoryDrawerHeader from '@palette/components/resources/RawDataHistoryDrawerHeader/RawDataHistoryDrawerHeader';
import RawDataHistoryDrawerList from '@palette/components/resources/RawDataHistoryDrawerList/RawDataHistoryDrawerList';
import RawDataHistoryRollbackModal from '@palette/components/resources/RawDataHistoryRollbackModal/RawDataHistoryRollbackModal';

import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';

import { getSearch } from '@palette/helpers/NavigationHelper';

import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';
import { actions as NavigationActions, selectors as NavigationSelectors } from '@palette/state/Navigation';

import styles from './RawDataHistoryDrawer.less';

const classNames = bindClassNames.bind(styles);

const RawDataHistoryDrawer = ({ className, showActionButtons, ...otherProps }) => {
  const dispatch = useDispatch();

  const location = useSelector(NavigationSelectors.getCurrentLocation);

  const [rollbackModalIsVisible, showRollbackModal] = useState(false);
  const [currentIdToRollback, setCurrentIdToRollback] = useState('');

  const historyLocation = useMemo(() => {
    const qsObj = getSearch(location);

    return qsObj.history || null;
  }, [location]);

  useEffect(() => {
    if (historyLocation?.connectorId && historyLocation?.objectId && historyLocation?.type) {
      const { connectorId, objectId, type } = historyLocation;

      dispatch(ResourcesActions.getById({ connectorId, objectId, type }));
    }
  }, [historyLocation]);

  const resource = useSelector((state) => ResourcesSelectors.getResourceById(state, { resourceId: historyLocation?.objectId }));

  const handleOnClose = useCallback(() => {
    dispatch(NavigationActions.cleanLocationSearch({ keysToDelete: ['history'] }));
  }, []);

  const handleCloseModal = () => {
    setCurrentIdToRollback('');
    showRollbackModal(false);
  };

  const handleRollback = (id) => {
    setCurrentIdToRollback(id);
    showRollbackModal(true);
  };

  const handleConfirmRollback = () => {
    dispatch(ResourcesActions.deleteOverwrite({
      connectorId: resource.connectorId,
      objectId: resource.id,
      type: resource.originalType,
      rollbackId: currentIdToRollback, // id (string) OR 0 (reset to original value)
      overwritesAllIds: _flatMap(resource.overwrites, (o) => o.id),
    }));

    showRollbackModal(false);
    handleOnClose();
  };

  let drawerContentNode = null;

  if (resource !== null) {
    drawerContentNode = (
      <div className={styles.content}>
        <RawDataHistoryDrawerList
          resource={resource}
          canManage={showActionButtons}
          handleRollback={handleRollback}
        />
        {
          rollbackModalIsVisible && (
            <RawDataHistoryRollbackModal
              visible
              onClose={handleCloseModal}
              onConfirm={handleConfirmRollback}
            />
          )
        }
      </div>
    );
  }

  return (
    <AntDDrawer
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={(
        <RawDataHistoryDrawerHeader
          resource={resource}
          canManage={showActionButtons}
          handleRollback={handleRollback}
        />
      )}
      placement="right"
      width={500}
      onClose={handleOnClose}
      visible={historyLocation !== null}
      closeIcon={<ClosePopupLine width={28} height={28} />}
      {...otherProps}
    >
      {drawerContentNode}
    </AntDDrawer>
  );
};

RawDataHistoryDrawer.propTypes = {
  className: PropTypes.string,
  showActionButtons: PropTypes.bool,
};

RawDataHistoryDrawer.defaultProps = {
  className: '',
  showActionButtons: true,
};

export default RawDataHistoryDrawer;
