import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import FolderIcon from '@palette/components/designSystem/FolderIcon/FolderIcon';
import EditDataConnectionConnectorButton from '@palette/components/notebooks/EditDataConnectionConnectorButton/EditDataConnectionConnectorButton';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';

import { NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import { actions as NotebooksActions } from '@palette/state/Notebooks';

import styles from './NotebookDataConnectionConnectorsListItem.less';

const classNames = bindClassNames.bind(styles);

const NotebookDataConnectionConnectorsListItem = ({ className, notebook, dataConnectionConnector }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const handleCollapseChange = useCallback((openPanels) => {
    setIsOpen(openPanels.length > 0);
  }, []);

  const handleCopyConnectionConnectorDataframeNameToClipboard = useCallback((event) => {
    event.stopPropagation();
    dispatch(NotebooksActions.copyConnectionConnectorDataframeNameToClipboard({ dataConnectionConnector }));
  }, [dataConnectionConnector]);

  const titleNode = useMemo(() => {
    const { dataframeName, type } = dataConnectionConnector;

    return (
      <div className={styles.titleWrapper}>
        <FolderIcon className={styles.titleIcon} isOpened={isOpen} />
        <div className={styles.title}>
          {type}
        </div>
        <Tooltip title={t('notebookDataConnectionConnectorsListItem.copy.dataframe', { dataframeName })}>
          <Button
            className={styles.dataframeNameButton}
            type="link"
            icon={<DuplicateFilled width={16} height={16} />}
            onClick={handleCopyConnectionConnectorDataframeNameToClipboard}
          />
        </Tooltip>
      </div>
    );
  }, [
    dataConnectionConnector,
    isOpen,
    handleCopyConnectionConnectorDataframeNameToClipboard,
  ]);

  const extraNode = useMemo(() => (
    <EditDataConnectionConnectorButton
      notebook={notebook}
      dataConnectionConnector={dataConnectionConnector}
    />
  ), [notebook, dataConnectionConnector]);

  const columnsNodes = useMemo(() => {
    const sortedDataConnectionConnector = _sortBy(dataConnectionConnector.columns, ['name']);

    return sortedDataConnectionConnector.map((column) => {
      const TypeIcon = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS[column.dataSelectorBlockListItemType];

      return (
        <div key={column.name} className={styles.columnWrapper}>
          <TypeIcon className={styles.icon} />
          <div className={styles.column}>
            {column.name}
          </div>
        </div>
      );
    });
  }, [dataConnectionConnector]);

  return (
    <Collapse
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      panels={[
        {
          title: titleNode,
          extra: extraNode,
          content: (
            <div className={styles.columns}>
              {columnsNodes}
            </div>
          ),
        },
      ]}
      ghost
      onChange={handleCollapseChange}
    />
  );
};

NotebookDataConnectionConnectorsListItem.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  dataConnectionConnector: NotebookDataConnectionConnectorModel.propTypes.isRequired,
};

NotebookDataConnectionConnectorsListItem.defaultProps = {
  className: '',
};

export default NotebookDataConnectionConnectorsListItem;
