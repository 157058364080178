import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import RawData from '@palette/components/resources/RawData/RawData';

import styles from './FormulaHelper.less';

const classNames = bindClassNames.bind(styles);

const FormulaHelper = ({ className, helperData, borderless }) => {
  const { t } = useTranslation();

  let rawData = {
    type: helperData.type,
    data: helperData.data[helperData.type],
  };
  if (Object.keys(helperData.data).length > 1) {
    rawData = {
      type: t('formulaHelper.formulaHelperTitle'),
      data: helperData.data,
    };
  }

  return (
    <RawData
      className={classNames({
        wrapper: true,
        bordered: !borderless,
        [className]: className !== '',
      })}
      data={rawData}
      onlyReadable
      isExpandedByDefault={false}
    />
  );
};

FormulaHelper.propTypes = {
  className: PropTypes.string,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  borderless: PropTypes.bool,
};

FormulaHelper.defaultProps = {
  className: '',
  borderless: false,
};

export default FormulaHelper;
