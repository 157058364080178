import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Avatar from '@palette/components/user/Avatar/Avatar';
import Link from '@palette/components/designSystem/Link/Link';

import * as MetaUserModel from '@palette/models/MetaUser';

import routePaths from '@palette/config/routePaths';

import { PALETTE_USER_AVATAR_SIZE, VERTICAL_AVATAR_SIZE } from '@palette/constants/global';

import styles from './UserProfile.less';

const classNames = bindClassNames.bind(styles);

const UserProfile = ({
  className,
  nameContainerClassName,
  nameClassName,
  user,
  isPaletteUser,
  vertical,
  onClick,
  clickable,
  linkProps,
  avatarSize,
  avatarStyle,
  additionalNode,
}) => {
  const { t } = useTranslation();

  const onClickProp = useMemo(() => {
    if (onClick === null) return {};

    return {
      onClick,
    };
  }, [onClick]);

  const nameNode = useMemo(() => {
    if (clickable && !isPaletteUser) {
      return (
        <Link
          className={classNames({
            name: true,
            [nameClassName]: nameClassName !== '',
            link: true,
            icon: linkProps.displayIcon,
          })}
          path={routePaths.v2.userDetails}
          params={{ userId: user?.id }}
          {...linkProps}
        >
          {user?.displayName}
        </Link>
      );
    }

    return (
      <span
        className={classNames({
          name: true,
          [nameClassName]: nameClassName !== '',
        })}
      >
        {isPaletteUser ? t('common.palette.user') : user?.displayName}
      </span>
    );
  }, [
    user,
    isPaletteUser,
    nameClassName,
    clickable,
    linkProps,
  ]);

  const avatarFinalSize = useMemo(() => {
    if (avatarSize !== 'small') return avatarSize;

    if (vertical) return VERTICAL_AVATAR_SIZE;

    if (isPaletteUser) return PALETTE_USER_AVATAR_SIZE;

    return avatarSize;
  }, [vertical, avatarSize, isPaletteUser]);

  return (
    <div
      className={classNames({
        wrapper: true,
        vertical,
        clickable: onClick !== null,
        [className]: className !== '',
      })}
      {...onClickProp}
    >
      <Avatar
        user={user ?? {}}
        isPaletteUser={isPaletteUser}
        size={avatarFinalSize}
        style={avatarStyle}
      />
      <div
        className={classNames({
          nameContainer: true,
          [nameContainerClassName]: nameContainerClassName !== '',
        })}
      >
        {nameNode}
        {additionalNode}
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  className: PropTypes.string,
  nameContainerClassName: PropTypes.string,
  nameClassName: PropTypes.string,
  user: MetaUserModel.propTypes,
  isPaletteUser: PropTypes.bool,
  vertical: PropTypes.bool,
  onClick: PropTypes.func,
  clickable: PropTypes.bool,
  linkProps: PropTypes.object,
  avatarSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  avatarStyle: PropTypes.object,
  additionalNode: PropTypes.node,
};

UserProfile.defaultProps = {
  className: '',
  nameContainerClassName: '',
  nameClassName: '',
  user: null,
  isPaletteUser: false,
  vertical: false,
  onClick: null,
  clickable: false,
  linkProps: {},
  avatarSize: 'small',
  avatarStyle: {},
  additionalNode: null,
};

export default UserProfile;
