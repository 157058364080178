import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import Loader from '@palette/components/utils/Loader/Loader';
import UserStatementInvestigateContent from '@palette/components/statement/userStatementInvestigate/UserStatementInvestigateContent/UserStatementInvestigateContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import {
  useAndFetchStatementPeriodInParams,
  useCurrencyInSearch,
  useStatementUserIdInParams,
} from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import { actions as StatementsActions } from '@palette/state/Statements';

import styles from './StatementsInvestigateCorrectionPage.less';

const StatementsInvestigateCorrectionPage = () => {
  const profile = useProfile();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currency] = useCurrencyInSearch(profile.userData.company.currencies.length < 2 ? profile.userData.company.currency : null);

  const { statementPeriodId, statementPeriod } = useAndFetchStatementPeriodInParams(currency);
  const { userId, userStatement, isPending } = useStatementUserIdInParams();

  useEffect(() => {
    if (statementPeriodId !== null && userId !== null) {
      dispatch(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));
    }
  }, [statementPeriodId, userId, currency]);

  const contentNode = useMemo(() => {
    if (statementPeriod === null) return null;

    if (isPending && !userStatement) {
      return (
        <Loader />
      );
    }

    return (
      <UserStatementInvestigateContent
        statementPeriod={statementPeriod}
        userStatement={userStatement}
        currency={currency}
      />
    );
  }, [isPending, statementPeriod, userStatement, currency]);

  if (!(statementPeriod && hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]))) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.statementsInvestigateTitle')} />
      {contentNode}
    </div>
  );
};

export default StatementsInvestigateCorrectionPage;
