import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TeamPlanIcon from '@palette/components/utils/Icons/TeamPlanIcon';
import InvidualPlanIcon from '@palette/components/utils/Icons/InvidualPlanIcon';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import styles from './OnboardingUsersButton.less';

const classNames = bindClassNames.bind(styles);

const OnboardingUsersButton = ({ className }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const handleChoose = useCallback((chosenFlowPath) => {
    redirectTo({ path: chosenFlowPath });
  }, []);

  const moreMenuItems = useMemo(() => {
    const menuItems = [];

    const fromResourcesMenuItem = {
      key: 'fromResources',
      title: t('onboardingUsersButton.moreMenu.fromResources'),
      icon: (
        <TeamPlanIcon width={16} height={16} />
      ),
      onClick: () => handleChoose(routePaths.v2.onboardUsersFromResourcesFlow),
    };

    menuItems.push(fromResourcesMenuItem);

    const manualInputMenuItem = {
      key: 'manualInput',
      title: t('onboardingUsersButton.moreMenu.manualInput'),
      icon: (
        <InvidualPlanIcon width={16} height={16} />
      ),
      onClick: () => handleChoose(routePaths.v2.onboardUsersManualInputFlow),
    };

    menuItems.push(manualInputMenuItem);

    return menuItems;
  }, [handleChoose]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.UPDATE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu items={moreMenuItems}>
        <Button
          type="primaryBlue"
          icon={<AddFilled />}
        >
          {t('onboardingUsersButton.label')}
        </Button>
      </MoreMenu>
    </div>
  );
};

OnboardingUsersButton.propTypes = {
  className: PropTypes.string,
};

OnboardingUsersButton.defaultProps = {
  className: '',
};

export default OnboardingUsersButton;
