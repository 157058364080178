import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CommonWidgetBase from '@palette/components/widgets/dashboard/CommonWidgetBase/CommonWidgetBase';
import CommissionsUnreleasedWidgetDashboardContent from '@palette/components/widgetContents/CommissionsUnreleasedWidgetDashboardContent/CommissionsUnreleasedWidgetDashboardContent';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as CommissionsUnreleasedDataModel from '@palette/models/widgets/common/CommissionsUnreleasedData';

import styles from './CommissionsUnreleasedWidget.less';

const classNames = bindClassNames.bind(styles);

const CommissionsUnreleasedWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const commissionsUnreleasedListData = CommissionsUnreleasedDataModel.transform(widgetData.data);

  return (
    <CommonWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      title={t('common.commissionsUnreleasedWidget.title')}
      readOnly={readOnly}
    >
      <CommissionsUnreleasedWidgetDashboardContent data={commissionsUnreleasedListData} disableLink={readOnly || !enableLinks} />
    </CommonWidgetBase>
  );
};

CommissionsUnreleasedWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

CommissionsUnreleasedWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default CommissionsUnreleasedWidget;
