import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import UserStatementCorrectionsItem from '@palette/components/statement/userStatement/UserStatementCorrectionsItem/UserStatementCorrectionsItem';
import AlertOctagon from '@palette/components/utils/Icons/AlertOctagon';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './UserStatementCorrections.less';

const classNames = bindClassNames.bind(styles);

const UserStatementCorrections = ({ className, statementPeriod, userStatement, currency }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => {
    if (!userStatement || !userStatement.corrections.length) return null;

    const title = (
      <div className={styles.title}>
        {t('userStatementCorrections.collapse.title', { count: userStatement.corrections.length })}
        <AlertOctagon className={styles.icon} />
      </div>
    );

    const content = userStatement.corrections.map((correction) => (
      <UserStatementCorrectionsItem
        key={correction.from.statementId}
        userStatement={userStatement}
        statementPeriod={statementPeriod}
        correction={correction}
        currency={currency}
      />
    ));

    return (
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title,
          content,
          extra: '',
        }]}
        keyValue={userStatement.id}
        defaultActiveKey={userStatement.id}
      />
    );
  }, [userStatement, statementPeriod, currency]);

  if (!userStatement) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UserStatementCorrections.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
  statementPeriod: StatementPeriodModel.propTypes,
  currency: PropTypes.string,
};

UserStatementCorrections.defaultProps = {
  className: '',
  userStatement: null,
  statementPeriod: null,
  currency: null,
};

export default UserStatementCorrections;
