import React, { Component } from 'react';
import {
  Modal, Input, Button, message, Alert, Form,
} from 'antd';
import PropTypes from 'prop-types';
import {
  LockOutlined, CloudOutlined, LinkOutlined, IdcardOutlined, UserOutlined,
} from '@ant-design/icons';

import CloseSimpleLine from '@palette/components/utils/Icons/CloseSimpleLine';

class ConnectCRMWithApiKeyModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  handleOk = (values) => {
    this.setState({ loading: true });
    const { crmCall, crmName } = this.props;
    crmCall(values).then((res) => {
      message.success(`${crmName} connected successfully!`);
      this.setState({ loading: false }, () => {
        this.props.onSuccess(res.data._id, crmName);
      });
    }).catch((err) => {
      console.error(`Connect ${crmName} error`, err);
      message.error(`Error while connecting your ${crmName} account. Please verify the input and try again.`);
      this.setState({ loading: false });
    });
  };

  render() {
    const { loading } = this.state;
    const {
      crmName, description, placeholder, paramName, paramIcon,
    } = this.props;
    const place = placeholder || `${crmName} Api Key`;
    return (
      <Modal
        title={`Connect ${crmName}`}
        visible={this.props.visible}
        onCancel={this.props.closeModal}
        footer={null}
        closeIcon={<CloseSimpleLine width={22} height={22} className="Connector_modal_close_icon" />}
      >
        <Alert
          message="Secured connexion, encrypted information."
          description={(
            <div>
              We take security very seriously.
              The information you're about to communicate is
              instantly encrypted and stored in a secured database.
              {' '}
              <a
                className="Green_link bold"
                target="_blank"
                rel="noreferrer noopener"
                href="https://palettehq.com/en/security"
              >
                For more information, please visit our security policies.
              </a>
            </div>
          )}
          type="success"
          showIcon
          icon={<LockOutlined />}
        />
        <br />
        <div>
          {description || `Please provide your ${crmName} Api Key.` }
        </div>
        <br />
        <Form onFinish={this.handleOk} layout="vertical">
          {crmName !== 'Snowflake' && crmName !== 'Redshift' && (
            <Form.Item
              label={place}
              name={paramName}
              rules={[{
                required: true,
                message: `Please input ${place}`,
              }]}
            >
              <Input
                prefix={paramIcon}
                placeholder={place}
                size="large"
              />
            </Form.Item>
          )}
          {crmName === 'Chargebee' && (
            <Form.Item
              name="site"
              extra={(
                <>
                  If your Chargebee dashboard is at https://
                  <b>xyz</b>
                  {'.chargebee.com enter '}
                  <b>xyz</b>
                  {' here.'}
                </>
              )}
              rules={[{
                required: true,
                message: 'Please input site',
              }]}
            >
              <Input
                prefix={<CloudOutlined />}
                placeholder={`${crmName} Site`}
                size="large"
              />
            </Form.Item>
          )}
          {(crmName === 'SaaSOptics' || crmName === 'Sapling') && (
            <Form.Item
              name="subdomain"
              rules={[{
                required: true,
                message: 'Please input Subdomain',
              }]}
            >
              <Input
                prefix={<LinkOutlined />}
                placeholder={`${crmName} Subdomain`}
                size="large"
              />
            </Form.Item>
          )}
          {(crmName === 'SaaSOptics') && (
            <Form.Item
              name="accountName"
              rules={[{
                required: true,
                message: 'Please input Account Name',
              }]}
            >
              <Input
                prefix={<IdcardOutlined />}
                placeholder={`${crmName} Account Name`}
                size="large"
              />
            </Form.Item>
          )}
          {crmName === 'NetSuite' && (
            <>
              <Form.Item
                label="Netsuite Name (can be the company name)"
                name="name"
                rules={[{
                  required: true,
                  message: 'Please input a name for this account',
                }]}
              >
                <Input
                  placeholder="Give this connector a name"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Consumer Key"
                name="consumerKey"
                rules={[{
                  required: true,
                  message: 'Please input Consumer Key',
                }]}
              >
                <Input
                  placeholder={`${crmName} Consumer Key`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Consumer Secret"
                name="consumerSecret"
                rules={[{
                  required: true,
                  message: 'Please input Consumer Secret',
                }]}
              >
                <Input
                  placeholder={`${crmName} Consumer Secret`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Token ID"
                name="tokenId"
                rules={[{
                  required: true,
                  message: 'Please input Token ID',
                }]}
              >
                <Input
                  placeholder={`${crmName} Token ID`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Token Secret"
                name="tokenSecret"
                rules={[{
                  required: true,
                  message: 'Please input Token Secret',
                }]}
              >
                <Input
                  placeholder={`${crmName} Token Secret`}
                  size="large"
                />
              </Form.Item>
            </>
          )}
          {crmName === 'Snowflake' && (
            <>
              <Form.Item
                name="accessUrl"
                label={`${crmName} Access URL`}
                rules={[{
                  required: true,
                  message: 'Please input Access URL',
                }]}
              >
                <Input
                  prefix={<LinkOutlined />}
                  placeholder={`${crmName} Access URL`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="account"
                label={`${crmName} Account Name`}
                rules={[{
                  required: true,
                  message: 'Please input Account Name',
                }]}
              >
                <Input
                  prefix={<IdcardOutlined />}
                  placeholder={`${crmName} Account Name`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="username"
                label={`${crmName} User Name`}
                rules={[{
                  required: true,
                  message: 'Please input User Name',
                }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder={`${crmName} User Name`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={`${crmName} Password`}
                rules={[{
                  required: true,
                  message: 'Please input Password',
                }]}
              >
                <Input
                  prefix={<LockOutlined />}
                  placeholder={`${crmName} Password`}
                  size="large"
                  type="password"
                />
              </Form.Item>
              <Form.Item name="role">
                <Input placeholder={`${crmName} Role`} size="large" />
              </Form.Item>
              <Form.Item name="database">
                <Input placeholder={`${crmName} Database`} size="large" />
              </Form.Item>
              <Form.Item name="schema">
                <Input placeholder={`${crmName} Schema`} size="large" />
              </Form.Item>
              <Form.Item name="warehouse">
                <Input placeholder={`${crmName} Warehouse`} size="large" />
              </Form.Item>
            </>
          )}
          {crmName === 'Redshift' && (
            <>
              <Form.Item
                name="secretAccessKey"
                label={`${crmName} Secret Access Key`}
                rules={[{
                  required: true,
                  message: 'Please input Secret Access Key',
                }]}
              >
                <Input
                  prefix={<LockOutlined />}
                  placeholder={`${crmName} Secret Access Key`}
                  size="large"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                name="accessKeyId"
                label={`${crmName} Access Key ID`}
                rules={[{
                  required: true,
                  message: 'Please input Access Key ID',
                }]}
              >
                <Input
                  prefix={<IdcardOutlined />}
                  placeholder={`${crmName} Access Key ID`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="secretArn"
                label={`${crmName} Secret ARN`}
                rules={[{
                  required: true,
                  message: 'Please input Secret ARN',
                }]}
              >
                <Input
                  prefix={<LockOutlined />}
                  placeholder={`${crmName} Secret ARN`}
                  size="large"
                  type="password"
                />
              </Form.Item>
              <Form.Item
                name="clusterIdentifier"
                label={`${crmName} Cluster Identifier`}
                rules={[{
                  required: true,
                  message: 'Please input Cluster Identifier',
                }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder={`${crmName} Cluster Identifier`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="region"
                label={`${crmName} Region`}
                rules={[{
                  required: true,
                  message: 'Please input Region',
                }]}
              >
                <Input
                  placeholder={`${crmName} Region`}
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="database"
                label={`${crmName} Database Name`}
                rules={[{
                  required: true,
                  message: 'Please input Database Name',
                }]}
              >
                <Input
                  placeholder={`${crmName} Database Name`}
                  size="large"
                />
              </Form.Item>
            </>
          )}
          <div className="flex" style={{ justifyContent: 'flex-end' }}>
            <Button key="back" onClick={this.props.closeModal}>
              Cancel
            </Button>
            &nbsp;
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
            >
              Connect
              {' '}
              {crmName}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

ConnectCRMWithApiKeyModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  crmName: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  crmCall: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  paramName: PropTypes.string,
  paramIcon: PropTypes.element,
};
ConnectCRMWithApiKeyModal.defaultProps = {
  placeholder: null,
  paramName: 'apiKey',
  paramIcon: <LockOutlined />,
};
export default ConnectCRMWithApiKeyModal;
