import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { ResponsiveBar } from '@nivo/bar';

import ChartTooltip from '@palette/components/designSystem/ChartTooltip/ChartTooltip';

import { floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { addCurrencyToNode, formatPrice } from '@palette/helpers/CurrencyHelper';
import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';

import { BAR_LABEL_THEME, CHART_GRADIENT_BLUE, gradientsDefs, LABEL_TEXT_COLOR_WHITE } from '@palette/constants/charts';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as PlanPayoutScheduleDataModel from '@palette/models/widgets/dashboard/PlanPayoutScheduleData';

import styles from './PlanPayoutScheduleChart.less';

const classNames = bindClassNames.bind(styles);

const PlanPayoutScheduleChart = ({ className, data, currency }) => {
  if (data.amountPerPeriod.length === 0) return null;

  const chartData = data.amountPerPeriod.map((periodAmount) => ({
    shortPeriod: getFrequencyPeriodName(PERIOD_TYPES.MONTH, 1, periodAmount.year, periodAmount.period, null, true),
    period: getFrequencyPeriodName(PERIOD_TYPES.MONTH, 1, periodAmount.year, periodAmount.period, null),
    shortAmount: floatToFixedNumber(periodAmount.totalPaymentAmount, 0),
    amount: floatToFixedNumber(periodAmount.totalPaymentAmount),
    currency: currency || periodAmount.currency,
  }));

  const getTooltip = (dataDesc) => (
    <ChartTooltip
      title={dataDesc.data.period}
      content={formatPrice(dataDesc.data.amount, dataDesc.data.currency)}
    />
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.chartWrapper}>
        <div className={styles.chart}>
          <ResponsiveBar
            data={chartData}
            keys={['shortAmount']}
            indexBy="shortPeriod"
            margin={{ top: 0, right: 0, bottom: 55, left: 5 }}
            label={(d) => addCurrencyToNode(d.value, d.data.currency)}
            padding={0.2}
            valueScale={{ type: 'linear' }}
            borderRadius={5}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 8,
              tickRotation: -65,
            }}
            axisLeft={null}
            labelSkipWidth={40}
            labelSkipHeight={20}
            enableGridY={false}
            defs={gradientsDefs}
            fill={[
              { match: '*', id: CHART_GRADIENT_BLUE.id },
            ]}
            labelTextColor={LABEL_TEXT_COLOR_WHITE}
            tooltip={getTooltip}
            theme={BAR_LABEL_THEME}
          />
        </div>
      </div>
    </div>
  );
};

PlanPayoutScheduleChart.propTypes = {
  className: PropTypes.string,
  data: PlanPayoutScheduleDataModel.propTypes.isRequired,
  currency: PropTypes.string,
};

PlanPayoutScheduleChart.defaultProps = {
  className: '',
  currency: null,
};

export default PlanPayoutScheduleChart;
