export const getCommissionV3Amount = (commission) => (commission.amountOverwritten !== null ? commission.amountOverwritten : commission.amount);

export const getScheduleFromPercentage = (commission) => {
  const commissionAmount = getCommissionV3Amount(commission);
  const { payments } = commission;

  return payments.map(({ date, percentage }) => ({
    date,
    percentage,
    amount: commissionAmount * percentage,
  }));
};
