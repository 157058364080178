import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import TableBodyCell from '@palette/components/designSystem/TableBodyCell/TableBodyCell';

import { getColumnWidth, getColumnWidthAsFiniteNumber } from '@palette/helpers/components/TableHelper';

import styles from './TableBodyRow.less';

const classNames = bindClassNames.bind(styles);

const TableBodyRow = ({
  className,
  type,
  row,
  prepareRow,
  BodyCellComponent,
  nbOfFixedColumns,
  fixedColumnsPosition,
  stretch,
  highlightOnHover,
  hasFooters,
  enableExpandRow,
  renderExpandedRow,
  horizontalScrollingOnLeft,
  horizontalScrollingOnRight,
}) => {
  prepareRow(row);

  let expandedRowNode = null;
  if (enableExpandRow && row.isExpanded === true && renderExpandedRow !== null) {
    expandedRowNode = (
      <div className={styles.expandWrapper}>
        {renderExpandedRow(row)}
      </div>
    );
  }

  const { cells } = row;
  if (nbOfFixedColumns > 0) {
    let totalRight = 0;
    for (let i = cells.length - 1; i >= 0; i -= 1) {
      cells[i].column.totalRight = totalRight;
      totalRight += getColumnWidthAsFiniteNumber(cells[i].column);
    }
  }
  let cellsNodes = cells.map((cell, cellIndex) => {
    let cellWidth = getColumnWidthAsFiniteNumber(cell.column);
    if (stretch) {
      cellWidth = getColumnWidth(cell.column);
    }

    let cellContentNode = (
      <BodyCellComponent type={type}>
        {
          cell.render('Cell')
        }
      </BodyCellComponent>
    );
    if (cell.column.disableBodyCellComponent === true) {
      cellContentNode = cell.render('Cell');
    }

    const cellStyleLeft = !Number.isFinite(cell.column.totalLeft) ? 0 : cell.column.totalLeft;
    const cellStyle = {
      width: cellWidth,
      minWidth: cell.column.totalMinWidth,
      maxWidth: cell.column.totalMaxWidth,
      left: cellStyleLeft,
    };
    if (
      nbOfFixedColumns > 0
      && (
        (fixedColumnsPosition === 'fromRight' && cellIndex !== 0)
        || (fixedColumnsPosition === 'fromLeft' && cellIndex === cells.length - 1)
      )
    ) {
      cellStyle.left = 'auto';
      cellStyle.right = cell.column.totalRight;
    }

    return (
      <div
        className={classNames({
          cell: true,
          stickyCell: (
            (fixedColumnsPosition === 'fromLeft' && cellIndex < nbOfFixedColumns)
            || (fixedColumnsPosition === 'fromRight' && cellIndex > (cells.length - 1 - nbOfFixedColumns))
            || (
              nbOfFixedColumns > 0
              && (
                cellIndex === 0
                || cellIndex === cells.length - 1
              )
            )
          ),
          highlight: !!cell.column.highlight,
          withShadow: (
            nbOfFixedColumns > 0
            && (
              (fixedColumnsPosition === 'fromLeft' && !horizontalScrollingOnLeft && cellIndex === nbOfFixedColumns - 1)
              || (fixedColumnsPosition === 'fromRight' && !horizontalScrollingOnRight && cellIndex === cells.length - nbOfFixedColumns)
            )
          ),
          withOppositeShadow: (
            nbOfFixedColumns > 0
            && (
              (fixedColumnsPosition === 'fromLeft' && !horizontalScrollingOnRight && cellIndex === cells.length - 1)
              || (fixedColumnsPosition === 'fromRight' && !horizontalScrollingOnLeft && cellIndex === 0)
            )
          ),
          fixedColumnsFromRight: nbOfFixedColumns > 0 && fixedColumnsPosition === 'fromRight',
          hidden: nbOfFixedColumns > 0 && (cellIndex === 0 || cellIndex === cells.length - 1),
        })}
        {...cell.getCellProps()}
        style={cellStyle}
      >
        {cellContentNode}
      </div>
    );
  });

  if (expandedRowNode !== null) {
    cellsNodes = (
      <div className={styles.cellsWrapper}>
        {cellsNodes}
      </div>
    );
  }

  return (
    <div
      className={classNames({
        row: true,
        highlightOnHover,
        hasFooters,
        borderless: type === 'borderless',
        withExpand: expandedRowNode !== null,
        [className]: className !== '',
      })}
      {...row.getRowProps()}
    >
      {cellsNodes}
      {expandedRowNode}
    </div>
  );
};

TableBodyRow.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  row: PropTypes.object.isRequired,
  prepareRow: PropTypes.func.isRequired,
  BodyCellComponent: PropTypes.func,
  nbOfFixedColumns: PropTypes.number,
  fixedColumnsPosition: PropTypes.oneOf(['fromLeft', 'fromRight']),
  stretch: PropTypes.bool,
  highlightOnHover: PropTypes.bool,
  hasFooters: PropTypes.bool,
  enableExpandRow: PropTypes.bool,
  renderExpandedRow: PropTypes.func,
  horizontalScrollingOnLeft: PropTypes.bool,
  horizontalScrollingOnRight: PropTypes.bool,
};

TableBodyRow.defaultProps = {
  className: '',
  type: 'default',
  BodyCellComponent: TableBodyCell,
  nbOfFixedColumns: 0,
  fixedColumnsPosition: 'fromLeft',
  stretch: false,
  highlightOnHover: false,
  hasFooters: false,
  enableExpandRow: false,
  renderExpandedRow: null,
  horizontalScrollingOnLeft: false,
  horizontalScrollingOnRight: false,
};

export default TableBodyRow;
