export const STATEMENT_PERIODICITY_TYPES = {
  M: 'MONTH',
  HF: 'HALF_MONTH',
  BW: 'BI_WEEK',
  W: 'WEEK',
};

export const STATEMENT_ACTION_TYPES = {
  PAYMENT: 'PAYMENT',
  APPROVAL: 'APPROVAL',
};

export const STATEMENT_CORRECTION_TYPES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REFUSED: 'REFUSED',
};
