import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import MasterPlanSettingsPayoutRulesForm from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRulesForm/MasterPlanSettingsPayoutRulesForm';
import ResourceObjectSample from '@palette/components/resources/ResourceObjectSample/ResourceObjectSample';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';
import CreatePlanFlowPreviewPayoutSchedule from '@palette/components/masterPlanCreation/CreatePlanFlowPreviewPayoutSchedule/CreatePlanFlowPreviewPayoutSchedule';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './CreatePlanFlowPayoutScheduleStep.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowPayoutScheduleStep = ({ className, plan }) => {
  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample);

  const [rules, setRules] = useState([]);
  const [template, setTemplate] = useState();
  const [savePayoutRulesCB, setSavePayoutRulesCB] = useState(null);

  const handleNextStep = useCallback((goToNextStep) => {
    savePayoutRulesCB(goToNextStep);
  }, [savePayoutRulesCB]);

  const handleSetSavePayoutRulesCB = useCallback((saveRulesCB) => {
    setSavePayoutRulesCB(() => saveRulesCB);
  }, []);

  const previewPayoutScheduleNode = useMemo(() => {
    if (rules.length === 0) return null;

    return (
      <CreatePlanFlowPreviewPayoutSchedule plan={plan} template={template} onPreview={handleNextStep} />
    );
  }, [rules, plan, template, handleNextStep]);

  const actionsNode = useMemo(() => (
    <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.PAYOUT_SCHEDULE.value} onNextStep={handleNextStep} />
  ), [plan, handleNextStep]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.formWrapper}>
        <MasterPlanSettingsPayoutRulesForm
          plan={plan}
          helperData={formulaHelperData}
          inPlanCreationFlow
          inPlanCreationFlowSetSaveCB={handleSetSavePayoutRulesCB}
          inPlanCreationFlowOnRulesUpdate={setRules}
          inPlanCreationFlowOnTemplateUpdate={setTemplate}
        />
        {previewPayoutScheduleNode}
        {actionsNode}
      </div>
      <ResourceObjectSample className={styles.resourceObjectSample} helperData={formulaHelperData} />
    </div>
  );
};

CreatePlanFlowPayoutScheduleStep.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowPayoutScheduleStep.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowPayoutScheduleStep;
