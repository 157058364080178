import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import StatementV3PeriodsNavigation from '@palette/components/ic/statementV3/StatementV3PeriodsNavigation/StatementV3PeriodsNavigation';
import StatementV3UserStatementInfosAndActions from '@palette/components/ic/statementV3/StatementV3UserStatementInfosAndActions/StatementV3UserStatementInfosAndActions';
import StatementV3UserStatementCorrectionsApplied from '@palette/components/ic/statementV3/StatementV3UserStatementCorrectionsApplied/StatementV3UserStatementCorrectionsApplied';
import StatementV3UserStatementComissions from '@palette/components/ic/statementV3/StatementV3UserStatementComissions/StatementV3UserStatementComissions';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import styles from './MyStatementsV3UserStatementPage.less';

const MyStatementsV3UserStatementPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();
  const { statementPeriodId } = useParams();

  const oneStatement = useSelector((state) => StatementV3Selectors.getOneStatement(state));

  useEffect(() => {
    dispatch(StatementV3Actions.getOneStatement({ statementPeriodId, isIC: true }));
  }, [statementPeriodId]);

  const infosAndActionsNode = useMemo(() => {
    if (oneStatement === null) return null;

    return (
      <StatementV3UserStatementInfosAndActions statement={oneStatement} />
    );
  }, [oneStatement]);

  const contentNode = useMemo(() => {
    if (oneStatement === null) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <>
        <StatementV3UserStatementCorrectionsApplied statement={oneStatement} />
        <StatementV3UserStatementComissions statement={oneStatement} />
      </>
    );
  }, [oneStatement]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.statementsUserStatementTitle')} />
      <PageTitle title={t('myStatementsPage.title')} />
      <div className={styles.container}>
        <StatementV3PeriodsNavigation className={styles.navigation} />
        {infosAndActionsNode}
        {contentNode}
      </div>
    </div>
  );
};

export default MyStatementsV3UserStatementPage;
