import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDragLayer } from 'react-dnd';

import FolderPreview from '@palette/components/folders/FoldersRecursive/FolderPreview/FolderPreview';
import ResourcePreview from '@palette/components/folders/FoldersRecursive/ResourcePreview/ResourcePreview';

import { folderColorPropTypes, folderColorDefaultProps } from '@palette/components/folders/FoldersRecursive/FolderTitle/FolderTitle';

import { FOLDER_ITEM_TYPES } from '@palette/constants/folders';

import styles from './FolderResourcePreview.less';

const classNames = bindClassNames.bind(styles);

const FolderResourcePreview = ({ className, folderColor, isPlanV3 }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialClientOffset(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const itemPreviewNode = useMemo(() => {
    if (itemType === FOLDER_ITEM_TYPES.FOLDER) {
      return (
        <FolderPreview folderId={item.folderId} folderColor={folderColor} />
      );
    }

    if (itemType === FOLDER_ITEM_TYPES.RESOURCE) {
      return (
        <ResourcePreview resourceId={item.resourceId} resourceType={item.resourceType} isPlanV3={isPlanV3} />
      );
    }

    return null;
  }, [itemType, item, folderColor, isPlanV3]);

  const itemStyles = useMemo(() => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return { transform };
  }, [initialOffset, currentOffset]);

  if (!isDragging) {
    return null;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div style={itemStyles}>
        {itemPreviewNode}
      </div>
    </div>
  );
};

FolderResourcePreview.propTypes = {
  className: PropTypes.string,
  folderColor: folderColorPropTypes,
  isPlanV3: PropTypes.bool,
};

FolderResourcePreview.defaultProps = {
  className: '',
  folderColor: folderColorDefaultProps,
  isPlanV3: false,
};

export default FolderResourcePreview;
