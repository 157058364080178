import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import { useAddDataConnectionStepValue } from '@palette/hooks/NotebookHooks';

import { getAddDataConnectionFlowStepIndex } from '@palette/helpers/NotebookHelper';

import { ADD_DATA_CONNECTION_FLOW_ORDERED_STEPS } from '@palette/constants/notebooks';

import { actions as NotebooksActions } from '@palette/state/Notebooks';

import styles from './AddDataConnectionStepsList.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionStepsList = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addDataConnectionStepValue = useAddDataConnectionStepValue();

  const addDataConnectionStepIndex = useMemo(() => (
    getAddDataConnectionFlowStepIndex(addDataConnectionStepValue)
  ), [addDataConnectionStepValue]);

  const stepsNodes = useMemo(() => (
    ADD_DATA_CONNECTION_FLOW_ORDERED_STEPS.map((step, index) => {
      const isDisabled = index > addDataConnectionStepIndex;
      const isDiscard = step.isDiscard();

      const handleStepClicked = () => {
        if (!isDisabled && !isDiscard) {
          dispatch(NotebooksActions.setAddDataConnectionCurrentFlowStep({ addDataConnectionCurrentFlowStep: step }));
        }
      };

      let stepIconNode = null;

      if (!isDisabled && !isDiscard) {
        if (index === addDataConnectionStepIndex) {
          stepIconNode = (
            <PenFilled className={styles.stepIcon} width={16} height={16} />
          );
        } else {
          stepIconNode = (
            <CheckFilled className={styles.stepIcon} width={16} height={16} />
          );
        }
      }

      return (
        <div
          key={step.value}
          className={classNames({
            step: true,
            selected: addDataConnectionStepValue === step.value,
            disabled: isDisabled,
            discard: isDiscard && !isDisabled,
          })}
          onClick={handleStepClicked}
        >
          <div className={styles.stepIconWrapper}>
            {stepIconNode}
          </div>
          <div className={styles.stepName}>
            {t(step.label)}
          </div>
        </div>
      );
    })
  ), [addDataConnectionStepValue, addDataConnectionStepIndex]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {stepsNodes}
    </div>
  );
};

AddDataConnectionStepsList.propTypes = {
  className: PropTypes.string,
};

AddDataConnectionStepsList.defaultProps = {
  className: '',
};

export default AddDataConnectionStepsList;
