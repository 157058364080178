import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Loader from '@palette/components/utils/Loader/Loader';
import TeamsWithSubTeamsList from '@palette/components/team/TeamsWithSubTeamsList/TeamsWithSubTeamsList';

import { useTeamsTree } from '@palette/hooks/TeamHooks';

import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './TeamsPageContent.less';

const classNames = bindClassNames.bind(styles);

const TeamsPageContent = ({ className }) => {
  const teamsListIsPending = useSelector(TeamsSelectors.getListIsPending);
  const teamsTree = useTeamsTree();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Loader spinning={teamsListIsPending}>
        <TeamsWithSubTeamsList teams={teamsTree} />
      </Loader>
    </div>
  );
};

TeamsPageContent.propTypes = {
  className: PropTypes.string,
};

TeamsPageContent.defaultProps = {
  className: '',
};

export default TeamsPageContent;
