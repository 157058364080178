import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Table from '@palette/components/designSystem/Table/Table';
import PeriodDealCommissionInfos from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfos/PeriodDealCommissionInfos';
import PeriodDealCommissionValue from '@palette/components/masterPlanPeriod/PeriodDealCommissionValue/PeriodDealCommissionValue';
import PeriodDealPayoutInfos from '@palette/components/masterPlanPeriod/PeriodDealPayoutInfos/PeriodDealPayoutInfos';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getCommissionAmount, getCommissionValue } from '@palette/helpers/CommissionHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';
import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as CommissionModel from '@palette/models/Commission';

import styles from './MyMasterPlanPeriodDealCommissionsTable.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanPeriodDealCommissionsTable = ({ className, periodDealOrUserWithCommissions, plan }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => ([
    {
      id: 'rule',
      Header: t('masterPlanPeriodDealCommissionsTable.table.headers.rule'),
      accessor: (commission) => {
        if (commission.ruleId === null) return '-';

        const ruleIndex = plan.rules.findIndex((rule) => rule.id === commission.ruleId);
        if (ruleIndex === -1) return '-';

        return plan.rules[ruleIndex].name || t('masterPlanPeriodDealCommissionsTable.ruleNumber', { index: ruleIndex + 1 });
      },
      minWidth: 100,
    },
    {
      id: 'revenue',
      Header: getPlanValueDefinition(plan),
      accessor: (commission) => comaSeparatorFormatter(floatToFixedNumber(getCommissionValue(commission))),
      minWidth: 100,
      maxWidth: 150,
      width: '15%',
    },
    {
      id: 'commission',
      Header: (
        <div className={styles.headerCommission}>
          {t('masterPlanPeriodDealCommissionsTable.table.headers.commission')}
        </div>
      ),
      accessor: (commission) => commission,
      minWidth: 160,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => {
        const amount = formatPrice(getCommissionAmount(value), value.currency);
        const hasDot = value.overwrittenAmount !== null;

        return (
          <PeriodDealCommissionValue
            value={getCommissionAmount(value)}
            formattedValue={amount}
            hasDot={hasDot}
            hasOnlyTargetRule={periodDealOrUserWithCommissions.hasOnlyTargetRule || false}
          />
        );
      },
      /* eslint-enable react/prop-types */
    },
    {
      id: 'payoutInfos',
      Header: (
        <div className={styles.headerCentered}>
          {t('masterPlanPeriodDeals.table.headers.payout')}
        </div>
      ),
      accessor: (commission) => commission,
      minWidth: 180,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<PeriodDealPayoutInfos commission={value} />),
    },
    {
      id: 'commissionInfos',
      accessor: (commission) => commission,
      minWidth: 170,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<PeriodDealCommissionInfos commission={value} displayPayments={false} />),
    },
  ]), [periodDealOrUserWithCommissions, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Table columns={columns} data={periodDealOrUserWithCommissions.commissions} />
    </div>
  );
};

MyMasterPlanPeriodDealCommissionsTable.propTypes = {
  className: PropTypes.string,
  periodDealOrUserWithCommissions: PropTypes.shape({
    hasOnlyTargetRule: PropTypes.bool,
    commissions: PropTypes.arrayOf(CommissionModel.propTypes),
  }).isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
};

MyMasterPlanPeriodDealCommissionsTable.defaultProps = {
  className: '',
};

export default MyMasterPlanPeriodDealCommissionsTable;
