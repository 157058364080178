import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import MasterPlanListItem from '@palette/components/masterPlan/MasterPlanListItem/MasterPlanListItem';

import { PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import { getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';
import { getPlanLastPeriod, isArchived } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './PlanListForUser.less';

const classNames = bindClassNames.bind(styles);

const PlanListForUser = ({ className, planList, listType, user }) => {
  const { t } = useTranslation();

  const listContentNode = useMemo(() => {
    if (planList.length === 0) {
      return (
        <DefaultEmptyState description={t('masterPlanList.empty.description')} />
      );
    }

    let orderedList = planList;

    if (listType === PLANS_PAGE_CONTENT_TABS_IDS.PAST) {
      orderedList = _orderBy(
        planList,
        [
          (plan) => getMomentDateFromPeriod(plan.periodType, plan.frequency, getPlanLastPeriod(plan), plan.customBeginAt, true, plan.fiscalYearShift),
          'name',
        ],
        ['desc', 'asc'],
      );
    }

    return orderedList.map((plan) => (
      <MasterPlanListItem
        className={styles.planItem}
        key={plan.id}
        plan={plan}
        user={user}
        disableActions
        displayTotalCommission={!isArchived(plan)}
      />
    ));
  }, [planList, listType, user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {listContentNode}
    </div>
  );
};

PlanListForUser.propTypes = {
  className: PropTypes.string,
  planList: PropTypes.arrayOf(MasterPlanModel.propTypes).isRequired,
  user: MetaUserModel.propTypes.isRequired,
  listType: PropTypes.string,
};

PlanListForUser.defaultProps = {
  className: '',
  listType: PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE,
};

export default PlanListForUser;
