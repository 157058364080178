import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import InvidualPlanIcon from '@palette/components/utils/Icons/InvidualPlanIcon';
import ManagerPlanIcon from '@palette/components/utils/Icons/ManagerPlanIcon';
import TeamPlanIcon from '@palette/components/utils/Icons/TeamPlanIcon';
import Input from '@palette/components/designSystem/Input/Input';

import { SCOPES } from '@palette/constants/masterPlans';

import styles from './MasterPlanScopeSelector.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanScopeSelector = ({ className, value, onChange, disabled, ...otherProps }) => {
  const { t } = useTranslation();

  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    if (onChange !== null) {
      onChange(newValue);
    }
  });

  const individualNode = (
    <div
      className={classNames({
        scopeCardWrapper: true,
        disabled,
        selected: value === SCOPES.INDIVIDUAL,
      })}
      onClick={() => handleChange(SCOPES.INDIVIDUAL)}
    >
      <InvidualPlanIcon className={styles.icon} />
      <div className={styles.label}>
        {t('planScope.individual.label')}
      </div>
      <div className={styles.description}>
        {t('planScope.individual.description')}
      </div>
      <Input className={styles.radio} type="radio" checked={value === SCOPES.INDIVIDUAL} />
    </div>
  );

  const teamNode = (
    <div
      className={classNames({
        scopeCardWrapper: true,
        disabled,
        selected: value === SCOPES.TEAM,
      })}
      onClick={() => handleChange(SCOPES.TEAM)}
    >
      <TeamPlanIcon className={styles.icon} />
      <div className={styles.label}>
        {t('planScope.team.label')}
      </div>
      <div className={styles.description}>
        {t('planScope.team.description')}
      </div>
      <Input className={styles.radio} type="radio" checked={value === SCOPES.TEAM} />
    </div>
  );

  const managerNode = (
    <div
      className={classNames({
        scopeCardWrapper: true,
        disabled,
        selected: value === SCOPES.MANAGER,
      })}
      onClick={() => handleChange(SCOPES.MANAGER)}
    >
      <ManagerPlanIcon className={styles.icon} />
      <div className={styles.label}>
        {t('planScope.manager.label')}
      </div>
      <div className={styles.description}>
        {t('planScope.manager.description')}
      </div>
      <Input className={styles.radio} type="radio" checked={value === SCOPES.MANAGER} />
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      {individualNode}
      {teamNode}
      {managerNode}
    </div>
  );
};

MasterPlanScopeSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

MasterPlanScopeSelector.defaultProps = {
  className: '',
  value: SCOPES.INDIVIDUAL,
  onChange: null,
  disabled: false,
};

export default MasterPlanScopeSelector;
