import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Avatar from '@palette/components/user/Avatar/Avatar';
import AvatarUploader from '@palette/components/settings/AvatarUploader/AvatarUploader';
import UserDetailsTabs from '@palette/components/user/UserDetailsTabs/UserDetailsTabs';

import { useUserIdInParams } from '@palette/hooks/UserHooks';

import styles from './UserDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsPageContent = ({ className }) => {
  const { user } = useUserIdInParams();

  if (user === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.userProfile}>
        <AvatarUploader
          className={styles.avatarUploader}
          modelType="user"
          user={user}
          logoSrc={user.profilePictureUrl}
          defaultContent={(
            <Avatar
              user={user}
              size={90}
              style={{ fontSize: '4rem' }}
            />
          )}
          size={90}
          editable
        />
        <div className={styles.userProfileName}>
          {user.displayName}
        </div>
      </div>
      <UserDetailsTabs className={styles.userDetailsTabs} user={user} />
    </div>
  );
};

UserDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

UserDetailsPageContent.defaultProps = {
  className: '',
};

export default UserDetailsPageContent;
