import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import BookFilled from '@palette/components/utils/Icons/BookFilled';
import Alert from '@palette/components/designSystem/Alert/Alert';
import PlayFilled from '@palette/components/utils/Icons/PlayFilled';
import CodeEditor from '@palette/components/designSystem/CodeEditor/CodeEditor';
import PlanV3CodeCellError from '@palette/components/planV3/PlanV3CodeCellError/PlanV3CodeCellError';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import GenerateWithAIButton from '@palette/components/planV3/GenerateWithAIButton/GenerateWithAIButton';

import { computeColumnWidthFromContentLength } from '@palette/helpers/components/TableHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { CODE_CELL_PARAMS } from '@palette/constants/planV3';

import * as PlanV3CellPreviewModel from '@palette/models/PlanV3CellPreview';

import styles from './PlanV3EditBlockCell.less';

const classNames = bindClassNames.bind(styles);

const PlanV3EditBlockCell = ({
  className,
  title,
  docLabel,
  description,
  alertTitle,
  alertContent,
  submitLabel,
  handleDocClick,
  cellCode,
  handleCellCodeChange,
  handleRunCodeCell,
  codeCellIsRunning,
  codeCellError,
  preview,
  planId,
  codeCellId,
}) => {
  const errorNode = useMemo(() => {
    if (!codeCellError || codeCellIsRunning) return null;

    return <PlanV3CodeCellError className={styles.spaceTop} error={codeCellError} />;
  }, [codeCellError, codeCellIsRunning]);

  const resultsTableColumns = useMemo(() => (
    (preview?.headers ?? []).map((header, index) => ({
      id: `column_${index}_${header.label}`,
      Header: header.label,
      accessor: header.label,
      minWidth: computeColumnWidthFromContentLength(header.maxLength),
      maxWidth: computeColumnWidthFromContentLength(header.maxLength),
      width: 'auto',
    }))
  ), [preview]);

  const resultsTableNode = useMemo(() => {
    if (!preview || codeCellError !== null || codeCellIsRunning) return null;

    if (preview.data.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <Table
        className={styles.table}
        columns={resultsTableColumns}
        data={preview.data ?? []}
        stickyHeader
        fitInContainer
      />
    );
  }, [
    preview,
    resultsTableColumns,
    codeCellError,
    codeCellIsRunning,
  ]);

  const generateWithAIButtonNode = useMemo(() => {
    if (cellCode !== '' || codeCellId === null) return null;

    return (
      <GenerateWithAIButton className={styles.spaceTop} disabled={codeCellIsRunning} planId={planId} codeCellId={codeCellId} />
    );
  }, [cellCode, planId, codeCellId]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {title}
        </div>
        <Button
          className={styles.docBtn}
          type="link"
          icon={<BookFilled />}
          onClick={handleDocClick}
        >
          {docLabel}
        </Button>
      </div>
      <div className={styles.description}>
        {description}
      </div>
      <Alert
        className={styles.spaceTop}
        type={ALERT_TYPES.INFO}
        message={alertTitle}
        description={alertContent}
      />
      <div className={styles.spaceTop}>
        <CodeEditor
          className={styles.codeCell}
          defaultLanguage={CODE_CELL_PARAMS.codeEditorLanguage}
          value={cellCode}
          onChange={handleCellCodeChange}
          disabled={codeCellIsRunning}
        />
      </div>
      {errorNode}
      <div className={styles.footer}>
        {generateWithAIButtonNode}
        <Button
          className={styles.spaceTop}
          type="successSecondary"
          icon={<PlayFilled />}
          onClick={handleRunCodeCell}
          loading={codeCellIsRunning}
          disabled={codeCellIsRunning}
        >
          {submitLabel}
        </Button>
      </div>
      {resultsTableNode}
    </div>
  );
};

PlanV3EditBlockCell.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  docLabel: PropTypes.string,
  description: PropTypes.string,
  alertTitle: PropTypes.string,
  alertContent: PropTypes.string,
  submitLabel: PropTypes.string,
  handleDocClick: PropTypes.func,
  cellCode: PropTypes.string,
  handleCellCodeChange: PropTypes.func,
  handleRunCodeCell: PropTypes.func,
  codeCellIsRunning: PropTypes.bool,
  codeCellError: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.any,
  }),
  preview: PlanV3CellPreviewModel.propTypes,
  planId: PropTypes.string.isRequired,
  codeCellId: PropTypes.string,
};

PlanV3EditBlockCell.defaultProps = {
  className: '',
  title: '',
  docLabel: '',
  description: '',
  alertTitle: '',
  alertContent: '',
  submitLabel: '',
  handleDocClick: () => {},
  cellCode: '',
  handleCellCodeChange: () => {},
  handleRunCodeCell: () => {},
  codeCellIsRunning: false,
  codeCellError: null,
  preview: null,
  codeCellId: null,
};

export default PlanV3EditBlockCell;
