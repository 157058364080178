import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  FOLDER_400_CANNOT_BE_SELF_PARENT: {
    code: 'folder.errors.cannot.be.self.parent',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  FOLDER_404_FOLDER_NOT_FOUND: {
    code: 'folder.errors.folder.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  FOLDER_404_PARENT_NOT_FOUND: {
    code: 'folder.errors.parent.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  FOLDER_409_DELETION_NOT_EMPTY: {
    code: 'folder.errors.delete.not.empty',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
