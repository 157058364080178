import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import TreeSelect from '@palette/components/designSystem/TreeSelect/TreeSelect';

import { useTeamsTree } from '@palette/hooks/TeamHooks';

import { buildTeamsTreeOptions } from '@palette/helpers/TeamHelper';

import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './TeamSelector.less';

const classNames = bindClassNames.bind(styles);

const TeamSelector = ({
  className,
  selectedTeam,
  onTeamSelected,
  disabled,
  placeholder,
  disableTeamFamily,
  size,
}) => {
  const { t } = useTranslation();

  const [teamId, setTeamId] = useState(placeholder !== '' ? '-1' : '');

  const teamsListIsPending = useSelector(TeamsSelectors.getListIsPending);
  const teamsTree = useTeamsTree();

  useEffect(() => {
    if (selectedTeam === null) setTeamId(placeholder !== '' ? '-1' : '');
  }, [selectedTeam]);

  const teamsOptions = useMemo(() => buildTeamsTreeOptions(teamsTree, disableTeamFamily), [teamsTree, disableTeamFamily]);

  const handleTeamSelect = useCallback((team) => {
    if (onTeamSelected !== null) onTeamSelected(team);
    setTeamId(team);
  }, [onTeamSelected]);

  const contentNode = useMemo(() => {
    if (teamsListIsPending) {
      return (
        <LoadingLine className={styles.icon} width={24} height={24} spin />
      );
    }

    return (
      <TreeSelect
        className={styles.treeSelect}
        showSearch
        searchNotFoundLabel={t('teamSelector.searchNotFound')}
        placeholder={placeholder ?? t('teamSelector.placeholder')}
        options={teamsOptions}
        onSelect={handleTeamSelect}
        value={selectedTeam || teamId}
        disabled={disabled}
        size={size}
      />
    );
  }, [
    teamsListIsPending,
    teamsOptions,
    handleTeamSelect,
    selectedTeam,
    teamId,
    disabled,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

TeamSelector.propTypes = {
  className: PropTypes.string,
  selectedTeam: PropTypes.string,
  onTeamSelected: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  disableTeamFamily: PropTypes.string,
  size: PropTypes.oneOf(['default', 'big']),
};

TeamSelector.defaultProps = {
  className: '',
  selectedTeam: null,
  onTeamSelected: null,
  disabled: false,
  placeholder: null,
  disableTeamFamily: null,
  size: 'default',
};

export default TeamSelector;
