import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { FOLDER_ITEM_TYPES, FOLDER_TYPES } from '@palette/constants/folders';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { getUseDrag } from '@palette/hooks/FolderHooks';

import styles from './FolderResource.less';

const classNames = bindClassNames.bind(styles);

const FolderResource = ({
  className,
  resourceId,
  resourceParentId,
  resourceType,
  resourceProp,
  ItemComponent,
}) => {
  const profile = useProfile();

  const item = useMemo(() => ({
    type: FOLDER_ITEM_TYPES.RESOURCE,
    resourceId,
    resourceParentId,
    resourceType,
  }), [resourceId, resourceParentId, resourceType]);

  /* DnD management */
  const [, drag, dragPreview] = getUseDrag(profile, item);

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div
        ref={drag}
      >
        <ItemComponent {...resourceProp} />
      </div>
    </div>
  );
};

FolderResource.propTypes = {
  className: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  resourceParentId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf(Object.values(FOLDER_TYPES)).isRequired,
  resourceProp: PropTypes.object.isRequired,
  ItemComponent: PropTypes.func.isRequired,
};

FolderResource.defaultProps = {
  className: '',
};

export default FolderResource;
