export const PERIOD_TYPES = {
  MONTH: 'MONTH',
  DAY: 'DAY',
  CUSTOM: 'CUSTOM',
};

// "MONTH-based" frequencies (Monthly, Quarterly, Semesterly, ...)
export const MONTH_FREQUENCIES = {
  1: 'frequency.monthly',
  2: 'frequency.bimonthly',
  3: 'frequency.quarterly',
  4: 'frequency.fourMonthsPeriod',
  6: 'frequency.halfYearly',
  12: 'frequency.yearly',
};

// "MONTH-based" frequencies (Month, Quarter, Semester, ...)
export const MONTH_FREQUENCIES_STR = {
  1: 'frequency.month',
  2: 'frequency.bimonth',
  3: 'frequency.quarter',
  4: 'frequency.fourMonths',
  6: 'frequency.halfYear',
  12: 'frequency.year',
};

// "Day-based" frequencies
export const DAY_FREQUENCIES = {
  DAY: 'frequency.day',
  EVERY_X_DAYS: 'frequency.day.every',
  WEEKLY: 'frequency.day.weekly',
};

// "Custom-based" frequencies
export const OTHER_FREQUENCIES = {
  CUSTOM: 'frequency.custom',
};

// "MONTH-based" frequency periods
export const PERIODS = {
  '1-0': 'frequency.monthly.periods.0',
  '1-1': 'frequency.monthly.periods.1',
  '1-2': 'frequency.monthly.periods.2',
  '1-3': 'frequency.monthly.periods.3',
  '1-4': 'frequency.monthly.periods.4',
  '1-5': 'frequency.monthly.periods.5',
  '1-6': 'frequency.monthly.periods.6',
  '1-7': 'frequency.monthly.periods.7',
  '1-8': 'frequency.monthly.periods.8',
  '1-9': 'frequency.monthly.periods.9',
  '1-10': 'frequency.monthly.periods.10',
  '1-11': 'frequency.monthly.periods.11',
  '2-0': 'frequency.bimonthly.periods.0',
  '2-1': 'frequency.bimonthly.periods.1',
  '2-2': 'frequency.bimonthly.periods.2',
  '2-3': 'frequency.bimonthly.periods.3',
  '2-4': 'frequency.bimonthly.periods.4',
  '2-5': 'frequency.bimonthly.periods.5',
  '3-0': 'frequency.quarterly.periods.0',
  '3-1': 'frequency.quarterly.periods.1',
  '3-2': 'frequency.quarterly.periods.2',
  '3-3': 'frequency.quarterly.periods.3',
  '4-0': 'frequency.fourMonthsPeriod.periods.0',
  '4-1': 'frequency.fourMonthsPeriod.periods.1',
  '4-2': 'frequency.fourMonthsPeriod.periods.2',
  '6-0': 'frequency.halfYearly.periods.0',
  '6-1': 'frequency.halfYearly.periods.1',
  '12-0': 'frequency.yearly.periods.0',
};

// "MONTH-shortname-based" frequency periods
export const PERIODS_SHORTNAME = {
  '1-0': 'frequency.monthly.short.periods.0',
  '1-1': 'frequency.monthly.short.periods.1',
  '1-2': 'frequency.monthly.short.periods.2',
  '1-3': 'frequency.monthly.short.periods.3',
  '1-4': 'frequency.monthly.short.periods.4',
  '1-5': 'frequency.monthly.short.periods.5',
  '1-6': 'frequency.monthly.short.periods.6',
  '1-7': 'frequency.monthly.short.periods.7',
  '1-8': 'frequency.monthly.short.periods.8',
  '1-9': 'frequency.monthly.short.periods.9',
  '1-10': 'frequency.monthly.short.periods.10',
  '1-11': 'frequency.monthly.short.periods.11',
  '2-0': 'frequency.bimonthly.periods.0',
  '2-1': 'frequency.bimonthly.periods.1',
  '2-2': 'frequency.bimonthly.periods.2',
  '2-3': 'frequency.bimonthly.periods.3',
  '2-4': 'frequency.bimonthly.periods.4',
  '2-5': 'frequency.bimonthly.periods.5',
  '3-0': 'frequency.quarterly.periods.0',
  '3-1': 'frequency.quarterly.periods.1',
  '3-2': 'frequency.quarterly.periods.2',
  '3-3': 'frequency.quarterly.periods.3',
  '4-0': 'frequency.fourMonthsPeriod.periods.0',
  '4-1': 'frequency.fourMonthsPeriod.periods.1',
  '4-2': 'frequency.fourMonthsPeriod.periods.2',
  '6-0': 'frequency.halfYearly.periods.0',
  '6-1': 'frequency.halfYearly.periods.1',
  '12-0': 'frequency.yearly.periods.0',
};

export const YEARLY_MONTHS = {
  M1: 'frequency.monthly.periods.0',
  M2: 'frequency.monthly.periods.1',
  M3: 'frequency.monthly.periods.2',
  M4: 'frequency.monthly.periods.3',
  M5: 'frequency.monthly.periods.4',
  M6: 'frequency.monthly.periods.5',
  M7: 'frequency.monthly.periods.6',
  M8: 'frequency.monthly.periods.7',
  M9: 'frequency.monthly.periods.8',
  M10: 'frequency.monthly.periods.9',
  M11: 'frequency.monthly.periods.10',
  M12: 'frequency.monthly.periods.11',
};

export const FREQUENCY_OPTION_VALUE_SEPARATOR = '___';
