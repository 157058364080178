import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import QuotaUserModal from '@palette/components/quota/QuotaUserModal/QuotaUserModal';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import CalendarFilled from '@palette/components/utils/Icons/CalendarFilled';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import UserQuotaEdition from '@palette/components/userDetails/UserQuotaEdition/UserQuotaEdition';

import { getMoment } from '@palette/helpers/MomentHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserDetailsQuotasTab.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsQuotasTab = ({ className, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const [year, setYear] = useState(moment().year());
  const [quotaId, setQuotaId] = useState(null);

  const listQuotasByUserIsPending = useSelector(QuotasSelectors.listQuotasByUserIsPending);
  const userQuotas = useSelector((state) => QuotasSelectors.listQuotasByUser(state, { userId: user.id }));

  useEffect(() => {
    if (!listQuotasByUserIsPending) {
      dispatch(QuotasActions.listQuotasByUser({ user }));
    }
  }, [user]);

  const handleYearChange = (date) => {
    setYear(moment(date).year());
  };

  const changeDefaultValuesForQuota = useCallback((chosenQuotaId) => {
    dispatch(QuotasActions.getById({ quotaId: chosenQuotaId }));
    setQuotaId(chosenQuotaId);
  }, [setQuotaId]);

  const quotasNodes = useMemo(() => {
    if (userQuotas.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return userQuotas.map((quota) => (
      <UserQuotaEdition
        className={classNames({
          quotaEdition: true,
          dayQuotaEdition: quota.periodType === PERIOD_TYPES.DAY,
        })}
        key={quota.id}
        quota={quota}
        quotaUserId={user.id}
        changeDefaultValues={() => changeDefaultValuesForQuota(quota.id)}
        year={year}
      />
    ));
  }, [user, userQuotas, year, changeDefaultValuesForQuota]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.yearSelectorWrapper}>
        <CalendarFilled width={22} height={22} />
        <div className={styles.yearLabel}>
          {t('quotaUserModal.quotasFor')}
        </div>
        <DatePicker
          className={styles.yearPicker}
          picker="year"
          value={moment().year(year)}
          allowClear={false}
          disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
          onChange={handleYearChange}
        />
      </div>
      <div className={styles.quotas}>
        {quotasNodes}
      </div>
      {
        quotaId !== null && (
          <QuotaUserModal visible onClose={() => setQuotaId(null)} quotaUserId={user.id} quotaId={quotaId} />
        )
      }
    </div>
  );
};

UserDetailsQuotasTab.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsQuotasTab.defaultProps = {
  className: '',
};

export default UserDetailsQuotasTab;
