import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';

import styles from './EndPeriodFormItem.less';

const EndPeriodFormItem = ({ disabled, isRequired }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      className={styles.wrapper}
      name="endPeriod"
      label={t('createPlanPage.form.endPeriod.label')}
      required={isRequired}
      rules={[
        { required: isRequired, message: t('createPlanPage.form.endPeriod.rules.required'), type: 'date' },
      ]}
    >
      <DatePicker
        className={styles.dateField}
        picker="date"
        allowClear
        disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
        disabled={disabled}
        size="big"
      />
    </FormItem>
  );
};

EndPeriodFormItem.propTypes = {
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

EndPeriodFormItem.defaultProps = {
  disabled: false,
  isRequired: false,
};

export default EndPeriodFormItem;
