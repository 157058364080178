import React, { useEffect, useRef } from 'react';

import TrialBanner from '@palette/components/banners/TrialBanner/TrialBanner';
import MarketingBanner from '@palette/components/banners/MarketingBanner/MarketingBanner';
import SuperAdminBanner from '@palette/components/banners/SuperAdminBanner/SuperAdminBanner';

import { setBannersHeight } from '@palette/helpers/LocalStorageHelper';

import styles from './BannerWrappers.less';

const BannerWrappers = () => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current) {
      const wrapperHeight = wrapperRef.current.offsetHeight;
      setBannersHeight(wrapperHeight);
    }
  }, [wrapperRef]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <TrialBanner />
      <MarketingBanner />
      <SuperAdminBanner />
    </div>
  );
};

export default BannerWrappers;
