import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import StatementsBreadcrumb from '@palette/components/statement/statementPagesContents/StatementsBreadcrumb/StatementsBreadcrumb';
import StatementsUserStatementPageContent from '@palette/components/statement/statementPagesContents/StatementsUserStatementPageContent/StatementsUserStatementPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import {
  useCurrencyInSearch,
  useStatementPeriodIdInParams,
  useStatementUserIdInParams,
} from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import styles from './StatementsUserStatementPage.less';

const StatementsUserStatementPage = () => {
  const profile = useProfile();
  const { t } = useTranslation();

  const { statementPeriod } = useStatementPeriodIdInParams();
  const { userStatement } = useStatementUserIdInParams();

  const [currency, handleCurrencyChange] = useCurrencyInSearch(profile.userData.company.currencies.length < 2 ? profile.userData.company.currency : null);

  const userDisplayName = useMemo(() => {
    if (userStatement === null) return '-';

    return userStatement.user.displayName;
  }, [userStatement]);

  if (!(hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]))) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.statementsUserStatementTitle')} />
      <StatementsBreadcrumb className={styles.breadcrumb} statementPeriod={statementPeriod} userName={userDisplayName} />
      <div className={styles.titleWithCurrency}>
        <PageTitle className={styles.title}>
          <h2>{t('statementsUserStatement.page.title')}</h2>
        </PageTitle>
        <CompanyCurrencySelect
          enableMixedCurrencyOption
          defaultMixedCurrencyOptionValue={null}
          value={currency}
          onChange={handleCurrencyChange}
          disabled={profile.userData.company.currencies.length < 2}
        />
      </div>
      <StatementsUserStatementPageContent currency={currency} />
    </div>
  );
};

export default StatementsUserStatementPage;
