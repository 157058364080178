import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _omitBy from 'lodash/omitBy';
import _overEvery from 'lodash/overEvery';
import _reject from 'lodash/reject';
import _size from 'lodash/size';

import { getI18n } from '@palette/i18n';

import {
  PROPERTY_TYPES,
  RAW_DATA_SEARCH_RESULTS_PATH_SEPARATOR as SEARCH_PATH_SEPARATOR,
} from '@palette/constants/resources';

import { convertAnyValueToMoment, isDate12AM, isValueValidMomentableDate } from '@palette/helpers/MomentHelper';

/**
 * Returns an array with:
 * [0] => value to display
 * [1] => type to display
 * [2] => type linked to PROPERTY_TYPES in constants/resources
 */
export const getRawValueType = (value) => {
  const valueType = typeof value;
  const i18n = getI18n();

  if (valueType === 'string') {
    if (isValueValidMomentableDate(value)) {
      let date;
      const finalValue = convertAnyValueToMoment(value, true);
      if (isDate12AM(value)) {
        date = finalValue.format('ll');
      } else {
        date = finalValue.format('lll');
      }
      return [date, 'date', PROPERTY_TYPES.DATE];
    }
    if (!value) {
      return ['-', i18n.t('common.global.text').toLowerCase(), PROPERTY_TYPES.STRING];
    }
    return [value, undefined, PROPERTY_TYPES.STRING];
  }

  if (valueType === 'boolean') {
    return [i18n.t(`common.global.${value.toString()}`).toLowerCase(), valueType, PROPERTY_TYPES.BOOLEAN];
  }

  if (value === null) {
    return ['-', 'null', PROPERTY_TYPES.STRING];
  }

  return [value, valueType, PROPERTY_TYPES[valueType.toUpperCase()]];
};

export const searchTermInString = (searchTerm, str) => {
  if (!str) return false;

  const finalSearchTerm = searchTerm.toString().toLowerCase();
  const finalStr = str.toString().toLowerCase();

  return finalStr.indexOf(finalSearchTerm) !== -1;
};

export const removeEmptyObjectsAndArrays = (obj) => {
  const isEmptyObject = _overEvery(_isObject, _isEmpty);

  if (_isArray(obj)) {
    return _reject(_map(obj, removeEmptyObjectsAndArrays), isEmptyObject);
  }

  if (_isObject(obj)) {
    return _omitBy(_mapValues(obj, removeEmptyObjectsAndArrays), isEmptyObject);
  }

  return obj;
};

export const pathHelper = (path) => (path !== '' ? `${path}.` : '');

export const filterDataBySearch = (searchTerm, items, path = '', returningMatchesOnly = true) => {
  let finalData = {};

  if (!_size(items) > 0) return finalData;

  if (Array.isArray(items)) {
    const finalResultArr = [];

    items.forEach((item, index) => {
      if (Array.isArray(item) || typeof item === 'object') {
        finalResultArr.push(
          filterDataBySearch(searchTerm, item, `${pathHelper(path)}${index}`, returningMatchesOnly),
        );
      } else if ((returningMatchesOnly && searchTermInString(searchTerm, item)) || !returningMatchesOnly) {
        finalResultArr.push(
          `${item}${SEARCH_PATH_SEPARATOR}${pathHelper(path)}${index}`,
        );
      }
    });

    return finalResultArr;
  }

  if (typeof items === 'object') {
    let finalResult = {};

    Object.keys(items).map((item) => {
      if (items[item] && (Array.isArray(items[item]) || typeof items[item] === 'object')) {
        finalResult = {
          ...finalResult,
          [`${item}${SEARCH_PATH_SEPARATOR}${pathHelper(path)}${item}`]: filterDataBySearch(
            searchTerm,
            items[item],
            `${pathHelper(path)}${typeof items[item] === 'object' ? item : ''}`,
            returningMatchesOnly ? !searchTermInString(searchTerm, item) : returningMatchesOnly,
          ),
        };
      } else if (
        (returningMatchesOnly && (
          searchTermInString(searchTerm, item) || searchTermInString(searchTerm, items[item])
        )) || !returningMatchesOnly
      ) {
        finalResult = {
          ...finalResult,
          [`${item}${SEARCH_PATH_SEPARATOR}${pathHelper(path)}${item}`]: items[item],
        };
      }

      return finalResult;
    });

    finalData = {
      ...finalData,
      ...finalResult,
    };
  }

  return finalData;
};
