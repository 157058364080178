import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './ChartTooltip.less';

const classNames = bindClassNames.bind(styles);

const ChartTooltip = ({
  className,
  children,
  title,
  content,
}) => {
  const titleNode = (
    <div className={styles.title}>
      {title}
    </div>
  );

  let contentNode = null;
  if (content !== null) {
    contentNode = (
      <div className={styles.content}>
        {content}
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.contentWrapper}>
        {titleNode}
        {contentNode}
        {children}
      </div>
    </div>
  );
};

ChartTooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  content: PropTypes.any,
  children: PropTypes.any,
};

ChartTooltip.defaultProps = {
  className: '',
  title: null,
  content: null,
  children: null,
};

export default ChartTooltip;
