import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { NOTEBOOK_DATA_CONNECTION_TYPES } from '@palette/constants/notebooks';

import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

export const modelName = 'NotebookDataConnection';

export const propTypes = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(NOTEBOOK_DATA_CONNECTION_TYPES)),
  [NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR]: NotebookDataConnectionConnectorModel.propTypes,
});
export const transform = (notebookDataConnection) => {
  if (!notebookDataConnection) {
    return null;
  }

  const type = (NOTEBOOK_DATA_CONNECTION_TYPES[manageStringAttribute(notebookDataConnection, 'dataConnectionType')] || NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR);

  let connection = null;
  if (type === NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR) {
    connection = NotebookDataConnectionConnectorModel.transform(notebookDataConnection);
  }

  return (
    {
      type,
      [type]: connection,
    }
  );
};

export const transformList = (notebookDataConnections) => notebookDataConnections.map((notebookDataConnection) => transform(notebookDataConnection));
