import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GlobalNotifItem from '@palette/components/globalNotif/GlobalNotifItem/GlobalNotifItem';

import { actions as GlobalNotifActions, selectors as GlobalNotifSelectors } from '@palette/state/GlobalNotif';

import styles from './GlobalNotif.less';

const GlobalNotif = () => {
  const dispatch = useDispatch();

  const getTemporaryNotifs = useSelector(GlobalNotifSelectors.getTemporary);
  const getPermanentNotifs = useSelector(GlobalNotifSelectors.getPermanent);

  const handleClosePermanentNotif = (id) => {
    dispatch(GlobalNotifActions.fadeOutPermanentGlobalNotifFromUI({ id }));
  };

  const permanentNotifsNode = useMemo(() => Object.keys(getPermanentNotifs).map((notif) => (
    <GlobalNotifItem
      key={`${notif.type}-${getPermanentNotifs[notif].id}`}
      notif={getPermanentNotifs[notif]}
      onClose={handleClosePermanentNotif}
    />
  )), [getPermanentNotifs]);

  const temporaryNotifsNode = useMemo(() => getTemporaryNotifs.map((notif, index) => (
    <GlobalNotifItem
      key={`${notif.type}-${index}`}
      notif={notif}
    />
  )), [getTemporaryNotifs]);

  return (
    <div className={styles.wrapper}>
      {permanentNotifsNode}
      {temporaryNotifsNode}
    </div>
  );
};

export default GlobalNotif;
