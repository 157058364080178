import {
  AND_OPERATOR,
  OR_OPERATOR,
  INITIAL_QUERY_BUILDER_VALUE,
  REGEX_OPERATOR,
} from '@palette/constants/queryBuilder';

export const getQueryBuilderObjectValue = (value) => {
  let jsonValue = INITIAL_QUERY_BUILDER_VALUE;
  if (value !== '') {
    jsonValue = JSON.parse(value);
  }

  return jsonValue;
};

export const buildNewValue = (useAndOp, newConditions) => ({
  [useAndOp ? AND_OPERATOR : OR_OPERATOR]: newConditions,
});

export const isAndOrBlock = (conditionObject) => {
  const firstKey = Object.keys(conditionObject)[0];

  return firstKey === AND_OPERATOR || firstKey === OR_OPERATOR;
};

export const buildSearchFilter = (text = '', columns, basePath = 'data.', $options) => {
  const allFields = [...new Set(columns.map(({ fields }) => fields).flat())];

  if (allFields.length === 0) return {};

  return {
    [AND_OPERATOR]: text.trim().split(' ').map((fragment) => ({
      [OR_OPERATOR]: allFields.map((field) => ({
        [`${basePath}${field}`]: {
          [REGEX_OPERATOR]: fragment.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'),
          $options,
        },
      })),
    })),
  };
};
