import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import RuleVariable from '@palette/components/rule/RuleVariable/RuleVariable';

import * as MasterPlanVariableModel from '@palette/models/MasterPlanVariable';

import styles from './RuleVariables.less';

const classNames = bindClassNames.bind(styles);

const RuleVariables = ({ className, scope, title, variables }) => {
  if (!variables || variables.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {title && (
        <div className={styles.title}>
          {title}
        </div>
      )}
      <div className={styles.list}>
        {
          variables.map((variable) => (
            <RuleVariable
              key={variable.name}
              className={styles.variable}
              scope={scope}
              ruleVariable={variable}
            />
          ))
        }
      </div>
    </div>
  );
};

RuleVariables.propTypes = {
  className: PropTypes.string,
  variables: PropTypes.arrayOf(MasterPlanVariableModel.propTypes).isRequired,
  scope: PropTypes.object,
  title: PropTypes.string,
};

RuleVariables.defaultProps = {
  className: '',
  scope: {},
  title: '',
};

export default RuleVariables;
