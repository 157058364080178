import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { USER_VARIABLE_TYPES } from '@palette/constants/user';

export const modelName = 'UserVariable';

export const propTypesShape = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(USER_VARIABLE_TYPES)),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export const propTypes = PropTypes.shape(propTypesShape);

export const manageUserVariableType = (userVariable) => (USER_VARIABLE_TYPES[manageStringAttribute(userVariable, 'type')] || USER_VARIABLE_TYPES.STRING);

export const manageUserVariableValue = (userVariableValue, type) => {
  switch (type) {
    case USER_VARIABLE_TYPES.STRING: {
      return manageStringAttribute({ userVariableValue }, 'userVariableValue');
    }
    case USER_VARIABLE_TYPES.NUMBER: {
      return manageAmountAttribute({ userVariableValue }, 'userVariableValue');
    }
    case USER_VARIABLE_TYPES.DATE: {
      return manageDateAttribute({ userVariableValue }, 'userVariableValue');
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return manageBooleanAttribute({ userVariableValue }, 'userVariableValue');
    }
    case USER_VARIABLE_TYPES.ARRAY: {
      return manageArrayAttribute({ userVariableValue }, 'userVariableValue');
    }
    default: {
      return userVariableValue;
    }
  }
};

export const transform = (userVariable) => {
  if (!userVariable) {
    return null;
  }

  const type = manageUserVariableType(userVariable);

  return (
    {
      id: manageStringAttribute(userVariable, '_id'),
      name: manageStringAttribute(userVariable, 'name'),
      type,
      defaultValue: manageUserVariableValue(userVariable.defaultValue ?? '', type),
    }
  );
};

export const transformList = (userVariables) => userVariables.map((userVariable) => transform(userVariable));
