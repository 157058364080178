import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import PlanV3RequirementsPageContent from '@palette/components/ic/planV3/PlanV3RequirementsPageContent/PlanV3RequirementsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './MyPlanV3RequirementsPage.less';

const MyPlanV3RequirementsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { planId } = useParams();

  if (!planId || !hasAllRights(profile, [RIGHTS.IC.COMPENSATION])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.requirementsPlan')} />
      <PageTitle
        className={styles.titleContainer}
        title={t('app.metas.requirementsPlan')}
      />
      <PlanV3RequirementsPageContent planId={planId} />
    </div>
  );
};

export default MyPlanV3RequirementsPage;
