import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import StatementV3PeriodStatus from '@palette/components/statementV3/StatementV3PeriodStatus/StatementV3PeriodStatus';
import StatementV3OngoingBulkApproveStatus from '@palette/components/statementV3/StatementV3OngoingBulkApproveStatus/StatementV3OngoingBulkApproveStatus';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { usePeriodsDatesFilter } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';
import { statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';
import { getStatementUserApprovalStatus, useStatementsPeriodStatus } from '@palette/helpers/StatementV3Helper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_APPROVAL_STATUSES, STATEMENT_PERIOD_STATUSES } from '@palette/constants/statements';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import { selectors as StatementV3Selectors, actions as StatementV3Actions } from '@palette/state/StatementV3';

import * as StatementV3PeriodModel from '@palette/models/StatementV3Period';
import * as StatementV3ActionModel from '@palette/models/StatementV3Action';

import styles from './StatementV3ApproveAllAction.less';

const classNames = bindClassNames.bind(styles);

const StatementV3ApproveAllAction = ({
  className,
  statementPeriod,
  statements,
  currency,
  disabled,
  areAllStatementsPaid,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const addMultipleActionsIsPending = useSelector(StatementV3Selectors.addMultipleActionsIsPending);
  const ongoingBulkApproveStatus = useSelector(StatementV3Selectors.getOngoingBulkApproveStatus);

  const statementPeriodStatus = useStatementsPeriodStatus(statementPeriod);

  const [, from, to] = usePeriodsDatesFilter();

  const approvableUserStatements = useMemo(() => (
    statements.filter((statement) => {
      if (statement.actions.some((action) => action.type === STATEMENT_ACTION_TYPES.PAYMENT)) return false;

      const statementUserApprovalStatus = getStatementUserApprovalStatus(statement, profile.userData.id);

      return statementUserApprovalStatus !== STATEMENT_APPROVAL_STATUSES.APPROVED;
    })
  ), [statements, profile]);

  const handleApproveAll = useCallback(() => {
    const statementIds = approvableUserStatements.map((statement) => statement.id);

    dispatch(StatementV3Actions.addMultipleActions({
      statementPeriodIds: statementIds,
      type: STATEMENT_ACTION_TYPES.APPROVAL,
      currency,
      from,
      to,
    }));
  }, [approvableUserStatements, currency, from, to]);

  const contentNode = useMemo(() => {
    const isFarInFuture = statementPeriodIsInFuture(statementPeriod, true, 'fromDate');

    if (areAllStatementsPaid || isFarInFuture) return null;

    if (
      statementPeriodStatus === STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS
      || ( // not so far in future
        statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE
        && !isFarInFuture
      )
    ) {
      let btnLabel = t('statementPeriodUsers.approveAll.button.label');
      if (addMultipleActionsIsPending && ongoingBulkApproveStatus !== null) {
        btnLabel = t('statementPeriodUsers.approveAll.button.processingLabel', { currentIndex: ongoingBulkApproveStatus.index + 1, nbToProcess: ongoingBulkApproveStatus.statementPeriodIds.length });
      }
      return (
        <Popconfirm
          title={t('statementPeriodUsers.approveAll.button.popconfirm')}
          onConfirm={handleApproveAll}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
          disabled={addMultipleActionsIsPending || approvableUserStatements.length === 0 || disabled}
        >
          <Button
            type="primaryBlue"
            icon={addMultipleActionsIsPending ? <LoadingLine spin /> : <CheckFilled />}
            disabled={addMultipleActionsIsPending || approvableUserStatements.length === 0 || disabled}
          >
            {btnLabel}
          </Button>
        </Popconfirm>
      );
    }

    return (
      <StatementV3PeriodStatus statementV3PeriodStatus={statementPeriodStatus} />
    );
  }, [
    statementPeriod,
    statementPeriodStatus,
    addMultipleActionsIsPending,
    disabled,
    handleApproveAll,
    approvableUserStatements,
    ongoingBulkApproveStatus,
    areAllStatementsPaid,
  ]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      <StatementV3OngoingBulkApproveStatus />
    </div>
  );
};

StatementV3ApproveAllAction.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3PeriodModel.propTypes.isRequired,
  statements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    fromDate: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    userId: PropTypes.string,
    correctionsCount: PropTypes.number,
    actions: PropTypes.arrayOf(StatementV3ActionModel.propTypes),
  })),
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  areAllStatementsPaid: PropTypes.bool,
};

StatementV3ApproveAllAction.defaultProps = {
  className: '',
  statements: [],
  currency: null,
  disabled: false,
  areAllStatementsPaid: false,
};

export default StatementV3ApproveAllAction;
