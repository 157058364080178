import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import StatementUsersNavigation from '@palette/components/statement/userStatement/StatementUsersNavigation/StatementUsersNavigation';
import StatementPeriodsNavigation from '@palette/components/statement/statementPeriod/StatementPeriodsNavigation/StatementPeriodsNavigation';
import Loader from '@palette/components/utils/Loader/Loader';
import UserStatementContent from '@palette/components/statement/userStatement/UserStatementContent/UserStatementContent';
import StatementRecomputeWarning from '@palette/components/statement/statementCommon/StatementRecomputeWarning/StatementRecomputeWarning';

import { useAndFetchStatementPeriodInParams, useStatementUserIdInParams } from '@palette/hooks/StatementHooks';

import { actions as StatementsActions } from '@palette/state/Statements';

import styles from './StatementsUserStatementPageContent.less';

const classNames = bindClassNames.bind(styles);

const StatementsUserStatementPageContent = ({ className, currency }) => {
  const dispatch = useDispatch();

  const { statementPeriodId, statementPeriod } = useAndFetchStatementPeriodInParams(currency, true);
  const { userId, userStatement, isPending } = useStatementUserIdInParams();

  useEffect(() => {
    if (statementPeriodId !== null && userId !== null) {
      dispatch(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));
    }
  }, [statementPeriodId, userId, currency]);

  const recomputeWarningNode = useMemo(() => {
    if (!userStatement?.isDirty) return null;
    return <StatementRecomputeWarning />;
  }, [userStatement]);

  const selectUserNode = useMemo(() => {
    if (statementPeriod === null) return null;
    return <StatementUsersNavigation />;
  }, [statementPeriod]);

  const contentNode = useMemo(() => {
    if (statementPeriod === null) return null;

    if (isPending) return <Loader />;

    return (
      <UserStatementContent
        statementPeriod={statementPeriod}
        userStatement={userStatement}
        currency={currency}
      />
    );
  }, [statementPeriod, userStatement, isPending, currency]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <StatementPeriodsNavigation className={styles.navigation} currency={currency} inUserStatement />
      {recomputeWarningNode}
      {selectUserNode}
      {contentNode}
    </div>
  );
};

StatementsUserStatementPageContent.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
};

StatementsUserStatementPageContent.defaultProps = {
  className: '',
  currency: null,
};

export default StatementsUserStatementPageContent;
