import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';

import {
  manageArrayAttribute, manageBooleanAttribute,
} from '@palette/helpers/ModelHelper';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as YearPeriodValueModel from '@palette/models/YearPeriodValue';
import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'QuotaUser';

export const propTypes = PropTypes.shape({
  user: MetaUserModel.propTypes,
  defaultValues: PropTypes.arrayOf(YearPeriodValueModel.propTypes),
  overwrites: PropTypes.arrayOf(YearPeriodValueModel.propTypes),
  deletable: PropTypes.bool,
  lastPeriod: YearPeriodModel.propTypes,
});

export const manageDefaultValues = (quotaUser) => {
  const defaultValues = manageArrayAttribute(quotaUser, 'defaultValues').map((defaultValue) => YearPeriodValueModel.transform(defaultValue));
  return _sortBy(defaultValues, ['year', 'period']);
};
export const manageOverwrites = (quotaUser) => {
  const overwrites = manageArrayAttribute(quotaUser, 'overwrites').map((overwrite) => YearPeriodValueModel.transform(overwrite));
  return _sortBy(overwrites, ['year', 'period']);
};

export const transform = (quotaUser) => {
  if (!quotaUser) {
    return null;
  }

  return ({
    user: MetaUserModel.transform(quotaUser),
    defaultValues: manageDefaultValues(quotaUser),
    overwrites: manageOverwrites(quotaUser),
    deletable: manageBooleanAttribute(quotaUser, 'deletable'),
    lastPeriod: YearPeriodModel.transform(quotaUser.lastPeriod),
  });
};

export const transformList = (quotaUsers) => quotaUsers.map((quotaUser) => transform(quotaUser));
