import { manageGlobalError } from '@palette/helpers/ErrorHelper';

export const manageError = ({ status, data }) => {
  const managedErrors = {};

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](data);
  }

  if (error !== null) {
    const { code } = error;

    return {
      ...error,
      message: { code },
    };
  }

  return manageGlobalError({ status });
};
