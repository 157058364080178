import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Select from '@palette/components/designSystem/Select/Select';
import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import MasterPlanPeriodInput from '@palette/components/masterPlanSettings/MasterPlanPeriodInput/MasterPlanPeriodInput';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import Input from '@palette/components/designSystem/Input/Input';

import { getAvailablePlanFrequenciesOptions } from '@palette/helpers/MasterPlanHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { getFrequencyPeriodName, getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as YearPeriodModel from '@palette/models/YearPeriod';

import styles from './MasterPlanSettingsGeneralFrequencyBeginEndFormItems.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsGeneralFrequencyBeginEndFormItems = ({
  className,
  periodTypeValue,
  frequencyValue,
  beginPeriodValue,
  displayEndPeriod,
  endPeriodValue,
  fiscalYearShiftValue,
  customBeginAtValue,
  handleSetEndPeriod,
  handleRemoveEndPeriod,
  disabled,
  disableEndPeriod,
  disableFrequency,
  disableBeforePeriod,
  disableBeforeDate,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const frequencyTypesOptions = getAvailablePlanFrequenciesOptions();

  const fiscalYearShiftNode = useMemo(() => {
    if (periodTypeValue !== PERIOD_TYPES.MONTH || beginPeriodValue === null) return null;

    const selectedFrequency = frequencyValue || 1;

    if (selectedFrequency === 1) return null;

    const beginPeriodName = getFrequencyPeriodName(periodTypeValue, frequencyValue, beginPeriodValue.year, beginPeriodValue.period, customBeginAtValue, false, fiscalYearShiftValue);
    const beginPeriodDate = getMomentDateFromPeriod(periodTypeValue, frequencyValue, beginPeriodValue, customBeginAtValue, false, fiscalYearShiftValue);

    return (
      <div className={styles.fiscalYearShiftWrapper}>
        <div className={styles.fiscalYearShiftLabel}>
          {t('masterPlanSettingsGeneral.form.fiscalYearShift.label')}
        </div>
        <div className={styles.fiscalYearShiftInputWrapper}>
          {t('masterPlanSettingsGeneral.form.fiscalYearShift.description1')}
          <FormItem name="fiscalYearShift" className={styles.fiscalYearShiftFormItem}>
            <Input
              className={styles.fiscalYearShiftFormInput}
              type="number"
              placeholder="0"
              min={0}
              max={selectedFrequency - 1}
              disabled={disabled}
            />
          </FormItem>
          {t('masterPlanSettingsGeneral.form.fiscalYearShift.description2')}
        </div>
        <div className={styles.fiscalYearShiftHint}>
          {t('masterPlanSettingsGeneral.form.fiscalYearShift.hint', { beginPeriodName, beginPeriodMonth: beginPeriodDate.format('MMMM YYYY') })}
        </div>
      </div>
    );
  }, [
    periodTypeValue,
    frequencyValue,
    disabled,
    beginPeriodValue,
    customBeginAtValue,
    fiscalYearShiftValue,
  ]);

  const beginEndForMonthTypeNode = useMemo(() => {
    let endPeriodFormItemNode = (
      <Button
        type="link"
        icon={<AddFilled width={18} height={18} />}
        onClick={handleSetEndPeriod}
        disabled={beginPeriodValue === null || disabled}
      >
        {t('masterPlanSettingsGeneral.form.setEndPeriod')}
      </Button>
    );

    if (displayEndPeriod) {
      endPeriodFormItemNode = (
        <div className={styles.endPeriodWrapper}>
          <FormItem
            className={styles.endPeriodInput}
            name="endPeriod"
            label={t('masterPlanSettingsGeneral.form.endPeriod.label')}
            required
            dependencies={['beginPeriod']}
            rules={[
              { required: true, message: t('masterPlanSettingsGeneral.form.endPeriod.rules.required') },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || (getFieldValue('beginPeriod').year < value.year) || (getFieldValue('beginPeriod').year === value.year && getFieldValue('beginPeriod').period <= value.period)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('masterPlanSettingsGeneral.form.endPeriod.rules.isAfter')));
                },
              }),
            ]}
          >
            <MasterPlanPeriodInput
              periodType={periodTypeValue}
              frequency={frequencyValue || 1}
              fiscalYearShift={fiscalYearShiftValue}
              disabled={disabled}
              disableBeforePeriod={beginPeriodValue}
            />
          </FormItem>
          <Button
            className={styles.removeEndPeriodBtn}
            type="linkDestroy"
            icon={(<TrashFilled width={18} height={18} />)}
            disabled={disabled}
            onClick={handleRemoveEndPeriod}
          />
        </div>
      );
    }

    if (disableEndPeriod) {
      endPeriodFormItemNode = null;
    }

    return (
      <>
        <div
          className={classNames({
            beginEndDatesWrapper: true,
            beginPeriodOnly: !displayEndPeriod || disableEndPeriod,
          })}
        >
          <FormItem
            className={styles.beginPeriodInput}
            name="beginPeriod"
            label={t('masterPlanSettingsGeneral.form.beginPeriod.label')}
            required
            rules={[
              { required: true },
            ]}
          >
            <MasterPlanPeriodInput
              periodType={periodTypeValue}
              frequency={frequencyValue || 1}
              fiscalYearShift={fiscalYearShiftValue}
              disabled={disabled}
              disableBeforePeriod={disableBeforePeriod}
            />
          </FormItem>
          {endPeriodFormItemNode}
        </div>
        {fiscalYearShiftNode}
      </>
    );
  }, [
    periodTypeValue,
    frequencyValue,
    fiscalYearShiftValue,
    beginPeriodValue,
    displayEndPeriod,
    endPeriodValue,
    disabled,
    disableEndPeriod,
    handleSetEndPeriod,
    disableBeforePeriod,
    fiscalYearShiftNode,
  ]);

  const beginEndForDayTypeNode = useMemo(() => {
    let endPeriodFormItemNode;
    let endPeriodSetButtonNode = (
      <Button
        type="link"
        icon={<AddFilled width={18} height={18} />}
        onClick={handleSetEndPeriod}
        disabled={customBeginAtValue === null || disabled}
      >
        {t('masterPlanSettingsGeneral.form.setEndPeriod')}
      </Button>
    );

    if (displayEndPeriod) {
      endPeriodSetButtonNode = null;
      endPeriodFormItemNode = (
        <div className={styles.endPeriodWrapper}>
          <FormItem
            className={styles.endPeriodInput}
            name="endPeriod"
            label={t('masterPlanSettingsGeneral.form.endPeriod.label')}
            required
            rules={[
              { required: true, message: t('masterPlanSettingsGeneral.form.endPeriod.rules.required') },
            ]}
          >
            <MasterPlanPeriodInput
              periodType={periodTypeValue}
              frequency={frequencyValue || 7}
              fiscalYearShift={fiscalYearShiftValue}
              disabled={disabled}
              beginDate={customBeginAtValue}
            />
          </FormItem>
          <Button
            className={styles.removeEndPeriodBtn}
            type="linkDestroy"
            icon={(<TrashFilled width={18} height={18} />)}
            disabled={disabled}
            onClick={handleRemoveEndPeriod}
          />
        </div>
      );
    }

    if (disableEndPeriod) {
      endPeriodFormItemNode = null;
      endPeriodSetButtonNode = null;
    }

    const handleDisabledDate = (d) => {
      const isDisable = !d || d.isSameOrBefore('1970-01-01');

      if (disableBeforeDate !== null) {
        return isDisable || d.isBefore(disableBeforeDate);
      }

      return isDisable;
    };

    return (
      <div
        className={classNames({
          beginEndDatesForDayTypeWrapper: true,
          beginPeriodOnly: !displayEndPeriod || disableEndPeriod,
        })}
      >
        <div className={styles.beginDateFrequencyWrapper}>
          <div
            className={classNames({
              customBeginAtWrapper: true,
              onlyInput: !endPeriodSetButtonNode,
            })}
          >
            <FormItem
              className={styles.customBeginAtInput}
              name="customBeginAt"
              label={t('masterPlanSettingsGeneral.form.customBeginAt.label')}
              required
              rules={[
                { required: true },
              ]}
            >
              <DatePicker
                className={styles.datePicker}
                size="big"
                picker="date"
                allowClear={false}
                disabledDate={handleDisabledDate}
                disabled={disabled}
              />
            </FormItem>
            {endPeriodSetButtonNode}
          </div>
          <FormItem
            className={styles.frequencyInput}
            name="frequency"
            label={t('masterPlanSettingsGeneral.form.frequencyForDay.label')}
            extra={t('masterPlanSettingsGeneral.form.frequencyForDay.description')}
            required
            rules={[
              { required: true, message: t('masterPlanSettingsGeneral.form.frequencyForDay.rules.required') },
            ]}
          >
            <Input size="big" type="number" min={1} max={365} disabled={disabled || disableFrequency} />
          </FormItem>
        </div>
        {endPeriodFormItemNode}
      </div>
    );
  }, [
    periodTypeValue,
    frequencyValue,
    fiscalYearShiftValue,
    customBeginAtValue,
    displayEndPeriod,
    endPeriodValue,
    disabled,
    disableEndPeriod,
    handleSetEndPeriod,
    disableFrequency,
    disableBeforeDate,
  ]);

  const beginEndForCustomTypeNode = useMemo(() => (
    <div className={styles.beginEndDatesWrapper}>
      <FormItem
        className={styles.customBeginAtInput}
        name="customBeginAt"
        label={t('masterPlanSettingsGeneral.form.customBeginAt.label')}
        required
        dependencies={['customEndAt']}
        rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || !getFieldValue('customEndAt') || moment(getFieldValue('customEndAt')).isAfter(moment(value))) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('masterPlanSettingsGeneral.form.customBeginAt.rules.isBefore')));
            },
          }),
        ]}
      >
        <DatePicker
          className={styles.datePicker}
          size="big"
          picker="date"
          allowClear={false}
          disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
          disabled={disabled}
        />
      </FormItem>
      <FormItem
        name="customEndAt"
        label={t('masterPlanSettingsGeneral.form.customEndAt.label')}
        required
        dependencies={['customBeginAt']}
        rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || !getFieldValue('customBeginAt') || moment(getFieldValue('customBeginAt')).isBefore(moment(value))) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('masterPlanSettingsGeneral.form.customEndAt.rules.isAfter')));
            },
          }),
        ]}
      >
        <DatePicker
          className={styles.datePicker}
          size="big"
          picker="date"
          allowClear={false}
          disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01') || (customBeginAtValue !== null && d.isSameOrBefore(customBeginAtValue))}
          disabled={disabled}
        />
      </FormItem>
    </div>
  ), [
    disabled,
    customBeginAtValue,
  ]);

  const beginEndPlanNode = useMemo(() => {
    if (periodTypeValue === PERIOD_TYPES.CUSTOM) return beginEndForCustomTypeNode;
    if (periodTypeValue === PERIOD_TYPES.DAY) return beginEndForDayTypeNode;

    return beginEndForMonthTypeNode;
  }, [
    periodTypeValue,
    beginEndForMonthTypeNode,
    beginEndForDayTypeNode,
    beginEndForCustomTypeNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <FormItem
        name="frequencyType"
        label={t('masterPlanSettingsGeneral.form.frequency.label')}
        required
        rules={[
          { required: true },
        ]}
      >
        <Select size="big" options={frequencyTypesOptions} disabled={disabled || disableFrequency} />
      </FormItem>
      {beginEndPlanNode}
    </div>
  );
};

MasterPlanSettingsGeneralFrequencyBeginEndFormItems.propTypes = {
  className: PropTypes.string,
  periodTypeValue: PropTypes.oneOf(Object.values(PERIOD_TYPES)).isRequired,
  frequencyValue: PropTypes.number,
  beginPeriodValue: YearPeriodModel.propTypes,
  displayEndPeriod: PropTypes.bool,
  endPeriodValue: YearPeriodModel.propTypes,
  fiscalYearShiftValue: PropTypes.number,
  customBeginAtValue: PropTypes.string,
  handleSetEndPeriod: PropTypes.func.isRequired,
  handleRemoveEndPeriod: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disableEndPeriod: PropTypes.bool,
  disableFrequency: PropTypes.bool,
  disableBeforePeriod: YearPeriodModel.propTypes,
  disableBeforeDate: PropTypes.string,
};

MasterPlanSettingsGeneralFrequencyBeginEndFormItems.defaultProps = {
  className: '',
  disabled: false,
  frequencyValue: null,
  beginPeriodValue: null,
  fiscalYearShiftValue: 0,
  displayEndPeriod: false,
  endPeriodValue: null,
  customBeginAtValue: null,
  disableEndPeriod: false,
  disableFrequency: false,
  disableBeforePeriod: null,
  disableBeforeDate: null,
};

export default MasterPlanSettingsGeneralFrequencyBeginEndFormItems;
