import Axios from 'axios';
import { API_URL } from './constants';

export const connectClose = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-close`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectChargebee = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-chargebee`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectPipedrive = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-pipedrive`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectSaasOptics = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-saas-optics`, { ...params, apiToken: params.apiKey }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectSapling = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-sapling`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectNetsuite = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-netsuite`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const connectStripe = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-stripe`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectMSSQL = (params) => Axios.post(`${API_URL}/users/admin/connectors/create-mssql`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectMySQL = (params) => Axios.post(`${API_URL}/users/admin/connectors/create-mysql`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectPennylane = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-pennylane`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectCrelate = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-crelate`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectSnowflake = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-snowflake`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const connectRedshift = (params) => Axios.post(`${API_URL}/users/admin/connectors/add-redshift`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getSalesforceConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-salesforce-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getJobadderConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-jobadder-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getSellsyConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-sellsy-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getHubspotConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-hubspot-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getXeroConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-xero-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getPipedriveConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-pipedrive-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getQuickBooksConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-quickbooks-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getQuickBooksSandboxConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-quickbooks-sandbox-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});
export const getOutreachConnectURL = () => Axios.post(`${API_URL}/users/admin/connectors/get-outreach-oauth-url`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const listConnectors = () => Axios.post(`${API_URL}/users/admin/connectors/list`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const getConnectorById = (connectorId) => Axios.post(`${API_URL}/users/admin/connectors/get-by-id`, { connectorId }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const getObjectsAvailableForConnector = () => Axios.post(`${API_URL}/users/admin/connectors/get-available-object-types`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const updateConnector = (data) => Axios.post(`${API_URL}/users/admin/connectors/update`, data, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const syncAll = () => Axios.post(`${API_URL}/users/sync-connectors`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const createConnector = () => Axios.post(`${API_URL}/users/admin/connectors/create`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const createConnectorGsheet = () => Axios.post(`${API_URL}/users/admin/connectors/create-gsheet`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const syncConnector = (connectorId) => Axios.post(`${API_URL}/users/admin/connectors/sync`, { connectorId }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const compute = (entityId, force = false) => Axios.post(`${API_URL}/users/compute`, { entityId, force }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const uploadObjects = (connectorId, type, objects) => Axios.post(`${API_URL}/users/admin/connectors/upload-objects`, {
  connectorId, objects, type,
}, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const retrieveSFReportByName = (connectorId, reportName) => Axios.post(`${API_URL}/users/admin/connectors/retrieve-sf-report-by-name`, { connectorId, reportName }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const checkSFObject = (connectorId, name) => Axios.post(`${API_URL}/users/admin/connectors/check-sf-object`, { connectorId, name }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const retrieveSheet = (spreadsheetId, range) => Axios.post(`${API_URL}/users/admin/connectors/check-gsheet`, { spreadsheetId, range }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const revoke = (connectorId) => Axios.post(`${API_URL}/users/admin/connectors/revoke`, { connectorId }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const deleteResource = ({ connectorId, type }) => Axios.post(`${API_URL}/users/admin/connectors/delete-resource`, { connectorId, type }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export default {};
