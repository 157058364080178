import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CalendarOutlined as AntDCalendarOutlined } from '@ant-design/icons/lib/icons';

import Button from '@palette/components/designSystem/Button/Button';
import AddQuotaUserLeaveDateModal from '@palette/components/quota/AddQuotaUserLeaveDateModal/AddQuotaUserLeaveDateModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './AddQuotaUserLeaveDate.less';

const AddQuotaUserLeaveDate = ({ className, quotaUserId, quotaId }) => {
  const profile = useProfile();

  const [addQuotaUserLeaveDateIsVisible, showAddQuotaUserLeaveDate] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE])) return null;

  return (
    <div className={className}>
      <Button
        className={styles.button}
        type="primary"
        shape="circle"
        onClick={() => showAddQuotaUserLeaveDate(true)}
        icon={<AntDCalendarOutlined width={14} height={14} />}
      />
      {
        addQuotaUserLeaveDateIsVisible && (
          <AddQuotaUserLeaveDateModal visible onClose={() => showAddQuotaUserLeaveDate(false)} quotaUserId={quotaUserId} quotaId={quotaId} />
        )
      }
    </div>
  );
};

AddQuotaUserLeaveDate.propTypes = {
  className: PropTypes.string,
  quotaUserId: PropTypes.string.isRequired,
  quotaId: PropTypes.string.isRequired,
};

AddQuotaUserLeaveDate.defaultProps = {
  className: '',
};

export default AddQuotaUserLeaveDate;
