/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';

import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _mergeWith from 'lodash/mergeWith';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';

import { NOTEBOOKS_TABS_IDS } from '@palette/constants/tabs';

/*
 * Initial State
 */
const initialState = {
  listNotebooksIsPending: false,
  archiveNotebookIsPending: false,
  unarchiveNotebookIsPending: false,
  deleteNotebookIsPending: false,
  createNotebookIsPending: false,
  getNotebookByIdIsPending: false,
  editNotebookIsPending: false,
  previewDataConnectionConnectorResultsIsPending: false,
  runCellIsPending: {},
  errorsByCellId: {},
  byId: {},
  listByFilter: {
    [NOTEBOOKS_TABS_IDS.ALL]: [],
    [NOTEBOOKS_TABS_IDS.YOURS]: [],
    [NOTEBOOKS_TABS_IDS.TEMPLATES]: [],
    [NOTEBOOKS_TABS_IDS.ARCHIVED]: [],
  },
  listByFilterPagination: {},
  listStats: {},
  lastListCallData: null,
  addDataConnectionCurrentFlowStep: null,
  newDataConnectionSource: null,
  newDataConnectionData: null,
  previewDataConnectionConnectorResults: null,
  disabledRunCell: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'notebooks',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* List Notebooks */
    listNotebooks: (state) => {
      state.listNotebooksIsPending = true;
    },
    setNotebooksList: (state, { payload }) => {
      const {
        callData,
        notebooksList,
        notebooksListPagination,
        notebooksListStats,
      } = payload;

      state.listByFilter[callData.filter] = _map(notebooksList, 'id');

      state.listByFilterPagination[callData.filter] = notebooksListPagination;

      state.listStats = notebooksListStats;

      state.lastListCallData = callData;

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(notebooksList, 'id'),
      );
    },
    listNotebooksCompleted: (state) => {
      state.listNotebooksIsPending = false;
    },
    /* Copy Notebook */
    copyNotebook: (state) => {
      state.copyNotebookIsPending = true;
    },
    copyNotebookCompleted: (state) => {
      state.copyNotebookIsPending = false;
    },
    /* Archive Notebook */
    archiveNotebook: (state) => {
      state.archiveNotebookIsPending = true;
    },
    archiveNotebookCompleted: (state) => {
      state.archiveNotebookIsPending = false;
    },
    /* Unarchive Notebook */
    unarchiveNotebook: (state) => {
      state.unarchiveNotebookIsPending = true;
    },
    unarchiveNotebookCompleted: (state) => {
      state.unarchiveNotebookIsPending = false;
    },
    /* Delete Notebook */
    deleteNotebook: (state) => {
      state.deleteNotebookIsPending = true;
    },
    deleteNotebookCompleted: (state) => {
      state.deleteNotebookIsPending = false;
    },
    /* Create Notebook */
    createNotebook: (state) => {
      state.createNotebookIsPending = true;
    },
    createNotebookCompleted: (state) => {
      state.createNotebookIsPending = false;
    },
    /* Get Notebook By Id */
    getNotebookById: (state) => {
      state.getNotebookByIdIsPending = true;
    },
    setNotebook: (state, { payload }) => {
      const { notebook } = payload;

      const updatedById = _cloneDeep(original(state.byId));
      updatedById[notebook.id] = notebook;

      state.byId = updatedById;
    },
    getNotebookByIdCompleted: (state) => {
      state.getNotebookByIdIsPending = false;
    },
    /* Edit Notebook */
    editNotebook: (state) => {
      state.editNotebookIsPending = true;
    },
    editNotebookCompleted: (state) => {
      state.editNotebookIsPending = false;
    },
    /* Add new Notebook's cell */
    addCell: (state) => {
      state.editNotebookIsPending = true;
    },
    addCellCompleted: (state) => {
      state.editNotebookIsPending = false;
    },
    /* Update Notebook's cell */
    updateCell: (state) => {
      state.editNotebookIsPending = true;
    },
    updateCellCompleted: (state, { payload }) => {
      state.editNotebookIsPending = false;

      const { notebookId, cellId } = payload;

      const updatedRunCellIsPending = {
        [notebookId]: {
          [cellId]: null,
        },
      };

      state.runCellIsPending = _merge(
        _cloneDeep(original(state.runCellIsPending)),
        updatedRunCellIsPending,
      );
    },
    /* Delete Notebook's cell */
    deleteNotebookCell: (state) => {
      state.editNotebookIsPending = true;
    },
    deleteNotebookCellCompleted: (state) => {
      state.editNotebookIsPending = false;
    },
    /* Add Data Connection Flow Management */
    setAddDataConnectionCurrentFlowStep: (state, { payload }) => {
      const { addDataConnectionCurrentFlowStep } = payload;

      state.addDataConnectionCurrentFlowStep = addDataConnectionCurrentFlowStep;
    },
    /* Data Connections management */
    setNewDataConnectionSource: (state, { payload }) => {
      const { type, params = {} } = payload;

      state.newDataConnectionSource = {
        type,
        params,
      };
    },
    setNewDataConnectionData: (state, { payload }) => {
      state.newDataConnectionData = payload;
    },
    resetNewDataConnectionInfo: (state) => {
      state.newDataConnectionSource = null;
      state.newDataConnectionData = null;
      state.previewDataConnectionConnectorResults = null;
    },
    /* Add data connection connector to Notebook */
    addDataConnectionConnector: (state) => {
      state.editNotebookIsPending = true;
    },
    addDataConnectionConnectorCompleted: (state) => {
      state.editNotebookIsPending = false;
    },
    /* Edit data connection connector to Notebook */
    editDataConnectionConnector: (state) => {
      state.editNotebookIsPending = true;
    },
    editDataConnectionConnectorCompleted: (state) => {
      state.editNotebookIsPending = false;
    },
    /* Preview data connection connector results */
    previewDataConnectionConnectorResults: (state) => {
      state.previewDataConnectionConnectorResultsIsPending = true;
    },
    setPreviewDataConnectionConnectorResults: (state, { payload }) => {
      const { dataframe } = payload;

      state.previewDataConnectionConnectorResults = dataframe;
    },
    previewDataConnectionConnectorResultsCompleted: (state) => {
      state.previewDataConnectionConnectorResultsIsPending = false;
    },
    /* Run a cell */
    runCell: (state, { payload }) => {
      const { notebookId, cell } = payload;

      // Update notebook cell pending state
      const updatedRunCellIsPending = {
        [notebookId]: {
          [cell.id]: true,
        },
      };

      state.runCellIsPending = _merge(
        _cloneDeep(original(state.runCellIsPending)),
        updatedRunCellIsPending,
      );

      // Update notebook disabled state
      const updatedDisabledRunCell = {
        [notebookId]: true,
      };

      state.disabledRunCell = _merge(
        _cloneDeep(original(state.disabledRunCell)),
        updatedDisabledRunCell,
      );

      // Reset notebook cell error
      const updatedRunCellError = {
        [notebookId]: {
          [cell.id]: null,
        },
      };

      state.errorsByCellId = _merge(
        _cloneDeep(original(state.errorsByCellId)),
        updatedRunCellError,
      );
    },
    runCellCompleted: (state, { payload }) => {
      const { notebookId, cellId } = payload;

      const updatedRunCellIsPending = {
        [notebookId]: {
          [cellId]: false,
        },
      };

      state.runCellIsPending = _merge(
        _cloneDeep(original(state.runCellIsPending)),
        updatedRunCellIsPending,
      );

      const updatedDisabledRunCell = {
        [notebookId]: false,
      };

      state.disabledRunCell = _merge(
        _cloneDeep(original(state.disabledRunCell)),
        updatedDisabledRunCell,
      );
    },
    /* Copy Connection Connector Dataframe Name to Clipboard */
    copyConnectionConnectorDataframeNameToClipboard: () => {
      // Nothing to do here
    },
    /* Update Notebook's cell error */
    updateCellError: (state, { payload }) => {
      const { notebookId, cellId, error } = payload;

      const updatedRunCellError = {
        [notebookId]: {
          [cellId]: error,
        },
      };

      state.errorsByCellId = _merge(
        _cloneDeep(original(state.errorsByCellId)),
        updatedRunCellError,
      );
    },
    /* Update and Run Notebook's Cell */
    updateAndRunCell: () => {
      // Nothing to do here
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const rawById = (state) => root(state).byId;
const rawListByFilter = (state) => root(state).listByFilter;
const rawListByFilterPagination = (state) => root(state).listByFilterPagination;
const rawListStats = (state) => root(state).listStats;
const rawLastListCallData = (state) => root(state).lastListCallData;
const rawAddDataConnectionCurrentFlowStep = (state) => root(state).addDataConnectionCurrentFlowStep;
const rawNewDataConnectionSource = (state) => root(state).newDataConnectionSource;
const rawNewDataConnectionData = (state) => root(state).newDataConnectionData;
const rawPreviewDataConnectionConnectorResults = (state) => root(state).previewDataConnectionConnectorResults;
const rawDisabledRunCell = (state) => root(state).disabledRunCell;

const listNotebooksIsPending = (state) => root(state).listNotebooksIsPending;
const copyNotebookIsPending = (state) => root(state).copyNotebookIsPending;
const archiveNotebookIsPending = (state) => root(state).archiveNotebookIsPending;
const unarchiveNotebookIsPending = (state) => root(state).unarchiveNotebookIsPending;
const deleteNotebookIsPending = (state) => root(state).deleteNotebookIsPending;
const createNotebookIsPending = (state) => root(state).createNotebookIsPending;
const getNotebookByIdIsPending = (state) => root(state).getNotebookByIdIsPending;
const editNotebookIsPending = (state) => root(state).editNotebookIsPending;
const previewDataConnectionConnectorResultsIsPending = (state) => root(state).previewDataConnectionConnectorResultsIsPending;
const rawRunCellIsPending = (state) => root(state).runCellIsPending;
const rawErrorsByCellId = (state) => root(state).errorsByCellId;

const getRunCellIsPending = createSelector(
  [rawRunCellIsPending, getProps],
  (runCellIsPending, { notebookId, cellId }) => (runCellIsPending[notebookId]?.[cellId] ?? null),
);

const getNotebooksList = createSelector(
  [rawListByFilter, rawById, getProps],
  (listByFilter, byId, { filter }) => (
    (listByFilter[filter] ?? []).map((notebookId) => byId[notebookId] || null).filter(Boolean)
  ),
);

const getNotebooksListPagination = createSelector(
  [rawListByFilterPagination, getProps],
  (listByFilterPagination, { filter }) => (
    listByFilterPagination[filter] ?? {}
  ),
);

const getNotebooksListStats = rawListStats;

const getNotebooksLastListCallData = rawLastListCallData;

const getNotebookById = createSelector(
  [rawById, getProps],
  (byId, { notebookId }) => (byId[notebookId] || null),
);

const getAddDataConnectionCurrentFlowStep = rawAddDataConnectionCurrentFlowStep;

const getNewDataConnectionSource = rawNewDataConnectionSource;

const getNewDataConnectionData = rawNewDataConnectionData;

const getPreviewDataConnectionConnectorResults = rawPreviewDataConnectionConnectorResults;

const getDisabledRunCell = createSelector(
  [rawDisabledRunCell, getProps],
  (disabledRunCell, { notebookId }) => (disabledRunCell[notebookId] ?? false),
);

const getErrorsByCellId = createSelector(
  [rawErrorsByCellId, getProps],
  (errorsByCellId, { notebookId, cellId }) => (errorsByCellId[notebookId]?.[cellId] ?? null),
);

export const selectors = {
  listNotebooksIsPending,
  copyNotebookIsPending,
  archiveNotebookIsPending,
  unarchiveNotebookIsPending,
  deleteNotebookIsPending,
  createNotebookIsPending,
  getNotebookByIdIsPending,
  editNotebookIsPending,
  previewDataConnectionConnectorResultsIsPending,
  getRunCellIsPending,
  getErrorsByCellId,

  getNotebooksList,
  getNotebooksListPagination,
  getNotebooksListStats,
  getNotebooksLastListCallData,
  getNotebookById,
  getAddDataConnectionCurrentFlowStep,
  getNewDataConnectionSource,
  getNewDataConnectionData,
  getPreviewDataConnectionConnectorResults,
  getDisabledRunCell,
};
