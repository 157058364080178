import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Button as AntDButton } from 'antd';

import styles from './Button.less';

const classNames = bindClassNames.bind(styles);

const Button = ({
  className,
  type,
  pressed,
  disabled,
  children,
  ...otherProps
}) => (
  <AntDButton
    className={classNames({
      wrapper: true,
      disabled,
      primaryOutlined: type === 'primaryOutlined',
      primaryBlue: type === 'primaryBlue',
      secondary: type === 'secondary',
      warning: type === 'warning',
      link: type === 'link',
      linkSecondary: type === 'linkSecondary',
      linkSecondaryBlack: type === 'linkSecondaryBlack',
      linkDestroy: type === 'linkDestroy',
      flat: type === 'flat',
      flatFill: type === 'flatFill',
      flatFillBlue: type === 'flatFillBlue',
      flatFillBlueOutlined: type === 'flatFillBlueOutlined',
      danger: type === 'danger',
      dangerSecondary: type === 'dangerSecondary',
      infoSecondary: type === 'infoSecondary',
      successSecondary: type === 'successSecondary',
      warningSecondary: type === 'warningSecondary',
      pressed,
      [className]: className !== '',
    })}
    type={type.startsWith('link') ? 'link' : 'default'}
    disabled={disabled}
    {...otherProps}
  >
    {children}
  </AntDButton>
);

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'primaryOutlined',
    'primaryBlue',
    'secondary',
    'warning',
    'link',
    'linkSecondary',
    'linkSecondaryBlack',
    'linkDestroy',
    'flat',
    'flatFill',
    'flatFillBlue',
    'flatFillBlueOutlined',
    'danger',
    'dangerSecondary',
    'infoSecondary',
    'successSecondary',
    'warningSecondary',
  ]),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  pressed: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  type: 'primary',
  children: null,
  disabled: false,
  pressed: false,
};

export default Button;
