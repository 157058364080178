import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import Link from '@palette/components/designSystem/Link/Link';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Tag from '@palette/components/designSystem/Tag/Tag';
import DeletePlanModal from '@palette/components/commonPlan/DeletePlanModal/DeletePlanModal';
import SettingsFilled from '@palette/components/utils/Icons/SettingsFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { PLANS_V3_EVENTS } from '@palette/constants/analytics';
import { RIGHTS } from '@palette/constants/profile';
import { PLAN_PERIODICITY_TYPE, PLAN_STATUS_TYPE } from '@palette/constants/planV3';
import { TAG_THEMES } from '@palette/constants/tag';

import { useProfile } from '@palette/hooks/ProfileHooks';

import * as PlanV3ListModel from '@palette/models/PlanV3List';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './PlanV3ListItem.less';

const classNames = bindClassNames.bind(styles);

const PlanV3ListItem = ({ className, plan, disableActions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const [deletePlanModalIsVisible, showDeletePlanModal] = useState(false);

  const nameNode = useMemo(() => {
    const handlePlanNameClick = () => {
      dispatch(AnalyticsActions.sendEvent({ event: PLANS_V3_EVENTS.PLAN_LIST.CLICK_PLAN_NAME }));
    };

    return (
      <div className={styles.nameWrapper}>
        <Link
          path={routePaths.v2.planV3EditAchievementById}
          params={{ planId: plan.id }}
          onClick={handlePlanNameClick}
        >
          {plan.name}
        </Link>
        {plan.status === PLAN_STATUS_TYPE.DRAFT && (
          <Tag className={styles.tag} theme={TAG_THEMES.WARNING} label={t(`plansV3.status.${plan.status}`)} />
        )}
      </div>
    );
  }, [plan]);

  const usersCountNode = (
    <>
      <div className={styles.usersCount}>{plan.usersCount}</div>
      <TeamFilled className={styles.usersCountIcon} />
    </>
  );

  const deleteButtonNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DELETE]) || disableActions) return null;

    const handleConfirmDeleteAction = () => {
      dispatch(AnalyticsActions.sendEvent({ event: PLANS_V3_EVENTS.PLAN_LIST.CLICK_DELETE }));
      showDeletePlanModal(true);
    };

    return (
      <Button
        className={styles.actionButton}
        type="linkSecondary"
        icon={<TrashFilled className={styles.deletable} />}
        onClick={handleConfirmDeleteAction}
      />
    );
  }, [profile, disableActions]);

  const actionsNode = useMemo(() => {
    if (disableActions) return null;

    return (
      <>
        <Tooltip title={t('plansV3.linkTo.requirements.tooltip')}>
          <Link
            className={styles.actionButton}
            path={routePaths.v2.planV3RequirementsById}
            params={{ planId: plan.id }}
          >
            <SettingsFilled className={styles.settings} />
          </Link>
        </Tooltip>
        {deleteButtonNode}
      </>
    );
  }, [disableActions, deleteButtonNode, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.namePeriodWrapper}>
          <div className={styles.nameScopeWrapper}>
            {nameNode}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsWrapper}>
            <div className={styles.usersCountWrapper}>
              {usersCountNode}
            </div>
            <div className={styles.frequency}>
              {t(`plansV3.periodicity.${PLAN_PERIODICITY_TYPE[plan.periodicity]}`)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        {actionsNode}
      </div>
      {deletePlanModalIsVisible && (
        <DeletePlanModal
          visible={deletePlanModalIsVisible}
          onClose={() => showDeletePlanModal(false)}
          planId={plan.id}
          planName={plan.name}
          isPlanV3
        />
      )}
    </div>
  );
};

PlanV3ListItem.propTypes = {
  className: PropTypes.string,
  plan: PlanV3ListModel.propTypes.isRequired,
  disableActions: PropTypes.bool,
};

PlanV3ListItem.defaultProps = {
  className: '',
  disableActions: false,
};

export default PlanV3ListItem;
