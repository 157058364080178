import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Drawer as AntDDrawer } from 'antd';

import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';
import CommissionDetails from '@palette/components/commission/CommissionDetails/CommissionDetails';
import CommissionValue from '@palette/components/commission/CommissionValue/CommissionValue';
import CommissionDealValue from '@palette/components/commission/CommissionDealValue/CommissionDealValue';
import CommissionDrawerTabs from '@palette/components/commission/CommissionDrawerTabs/CommissionDrawerTabs';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { isDraft } from '@palette/helpers/MasterPlanHelper';
import { getSearch, stringifyQS } from '@palette/helpers/NavigationHelper';

import { COMMISSION_DRAWER_COMMISION_ID_QS_KEY } from '@palette/constants/commissions';
import { COMMISSION_DRAWER_QS_KEY } from '@palette/constants/tabs';

import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CommissionDrawer.less';

const classNames = bindClassNames.bind(styles);

const CommissionDrawer = ({ className, inSalesforce, ...otherProps }) => {
  const { t } = useTranslation();
  const reactLocation = useLocation();
  const reactHistory = useHistory();
  const dispatch = useDispatch();

  const [commissionId] = useKeyInSearch(COMMISSION_DRAWER_COMMISION_ID_QS_KEY, null, inSalesforce);

  const commission = useSelector((state) => CommissionsSelectors.getCommissionById(state, { commissionId }));

  const viewOnly = useMemo(() => (
    isDraft(commission?.scopeParams?.plan) || inSalesforce
  ), [commission, inSalesforce]);

  useEffect(() => {
    if (commissionId) dispatch(CommissionsActions.getById({ commissionId, firstFetch: true, inSalesforce }));
  }, [commissionId]);

  const handleOnClose = useCallback(() => {
    if (inSalesforce) {
      const qsObj = getSearch(reactLocation);
      delete qsObj[COMMISSION_DRAWER_COMMISION_ID_QS_KEY];
      delete qsObj[COMMISSION_DRAWER_QS_KEY];

      reactHistory.push({
        pathname: reactLocation.pathname,
        search: stringifyQS(qsObj),
      });
    } else {
      dispatch(NavigationActions.cleanLocationSearch({ keysToDelete: [COMMISSION_DRAWER_COMMISION_ID_QS_KEY, COMMISSION_DRAWER_QS_KEY] }));
    }
  }, [inSalesforce, reactLocation, reactHistory]);

  let drawerContentNode = null;
  if (commission !== null) {
    drawerContentNode = (
      <div className={styles.content}>
        <CommissionDetails className={styles.commissionDetails} commission={commission} viewOnly={viewOnly} />
        <div className={styles.commissionValueAndDealValue}>
          <CommissionValue className={styles.commissionValue} commission={commission} viewOnly={viewOnly} />
          <CommissionDealValue className={styles.commissionDealValue} commission={commission} viewOnly={viewOnly} />
        </div>
        <CommissionDrawerTabs className={styles.commissionDrawerTabs} commission={commission} viewOnly={viewOnly} inSalesforce={inSalesforce} />
      </div>
    );
  }

  return (
    <AntDDrawer
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('commissionDrawer.title')}
      placement="right"
      width={600}
      onClose={handleOnClose}
      visible={commissionId !== null}
      closeIcon={<ClosePopupLine width={28} height={28} />}
      {...otherProps}
    >
      {drawerContentNode}
    </AntDDrawer>
  );
};

CommissionDrawer.propTypes = {
  className: PropTypes.string,
  inSalesforce: PropTypes.bool,
};

CommissionDrawer.defaultProps = {
  className: '',
  inSalesforce: false,
};

export default CommissionDrawer;
