import React from 'react';
import { useSelector } from 'react-redux';

import NotificationActiveFilled from '@palette/components/utils/Icons/NotificationActiveFilled/NotificationActiveFilled';
import NotificationInactiveFilled from '@palette/components/utils/Icons/NotificationInactiveFilled';

import { selectors as ThreadsSelectors } from '@palette/state/Threads';

import { STATUSES } from '@palette/constants/threads';

const MenuNotificationsIcon = (props) => {
  const nbOfPendingThreads = useSelector((state) => ThreadsSelectors.getNbOfThreadsByStatus(state, { threadsStatus: STATUSES.PENDING }));

  return nbOfPendingThreads > 0 ? (<NotificationActiveFilled {...props} />) : (<NotificationInactiveFilled {...props} />);
};

export default MenuNotificationsIcon;
