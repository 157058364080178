import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import EditablePeriodYear from '@palette/components/frequencies/EditablePeriodYear/EditablePeriodYear';
import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';

import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as YearPeriodModel from '@palette/models/YearPeriod';

import styles from './PeriodsPicker.less';

const classNames = bindClassNames.bind(styles);

const PeriodsPicker = ({
  className,
  periodType,
  frequency,
  fiscalYearShift,
  beginDate,
  fromPeriod,
  toPeriod,
  onFromPeriodUpdate,
  onToPeriodUpdate,
  disabled,
  defaultInitialPeriod,
  disableBeforePeriod,
  disableAfterPeriod,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const now = moment();

  const [currentFromPeriod, setCurrentFromPeriod] = useState(fromPeriod);
  const [currentToPeriod, setCurrentToPeriod] = useState(toPeriod);

  if (periodType === PERIOD_TYPES.CUSTOM) return null;

  const handleFromPeriodYearUpdate = (newFromPeriod) => {
    setCurrentFromPeriod(newFromPeriod);
    onFromPeriodUpdate(newFromPeriod);
  };

  const handleToPeriodYearUpdate = (newToPeriod) => {
    setCurrentToPeriod(newToPeriod);
    onToPeriodUpdate(newToPeriod);
  };

  const getContentDisplayNode = (contentDisplay) => (
    <div className={styles.contentWrapper}>
      <div className={styles.content}>
        {contentDisplay}
      </div>
      <ChevronDownLine className={styles.contentIcon} width={24} height={24} />
    </div>
  );

  const getFromPeriodContentDisplay = (periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay = null, fiscalYearShiftToDisplay = 0) => {
    const contentDisplay = currentFromPeriod !== null ? getFrequencyPeriodName(periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay, false, fiscalYearShiftToDisplay) : t('periodsPicker.from');
    return getContentDisplayNode(contentDisplay);
  };

  const getToPeriodContentDisplay = (periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay = null, fiscalYearShiftToDisplay = 0) => {
    const contentDisplay = currentToPeriod !== null ? getFrequencyPeriodName(periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay, false, fiscalYearShiftToDisplay) : t('periodsPicker.to');
    return getContentDisplayNode(contentDisplay);
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <EditablePeriodYear
        className={styles.periodYearWrapper}
        contentClassName={styles.editablePeriodYearContent}
        popoverVisibleClassName={styles.popoverVisible}
        periodType={periodType}
        frequency={frequency}
        fiscalYearShift={fiscalYearShift}
        beginDate={beginDate}
        initialPeriod={currentFromPeriod?.period || defaultInitialPeriod}
        initialYear={currentFromPeriod?.year || now.year()}
        onUpdate={handleFromPeriodYearUpdate}
        getContentDisplay={getFromPeriodContentDisplay}
        disabled={disabled}
        disableBeforePeriod={disableBeforePeriod}
        disableAfterPeriod={currentToPeriod || disableAfterPeriod}
      />
      <EditablePeriodYear
        className={styles.periodYearWrapper}
        contentClassName={styles.editablePeriodYearContent}
        popoverVisibleClassName={styles.popoverVisible}
        periodType={periodType}
        frequency={frequency}
        fiscalYearShift={fiscalYearShift}
        beginDate={beginDate}
        initialPeriod={currentToPeriod?.period || defaultInitialPeriod}
        initialYear={currentToPeriod?.year || now.year()}
        onUpdate={handleToPeriodYearUpdate}
        getContentDisplay={getToPeriodContentDisplay}
        disabled={disabled}
        disableBeforePeriod={currentFromPeriod || disableBeforePeriod}
        disableAfterPeriod={disableAfterPeriod}
      />
    </div>
  );
};

PeriodsPicker.propTypes = {
  className: PropTypes.string,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)).isRequired,
  frequency: PropTypes.number.isRequired,
  fiscalYearShift: PropTypes.number,
  beginDate: PropTypes.string,
  fromPeriod: YearPeriodModel.propTypes,
  toPeriod: YearPeriodModel.propTypes,
  onFromPeriodUpdate: PropTypes.func.isRequired,
  onToPeriodUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultInitialPeriod: PropTypes.number,
  disableBeforePeriod: YearPeriodModel.propTypes,
  disableAfterPeriod: YearPeriodModel.propTypes,
};

PeriodsPicker.defaultProps = {
  className: '',
  fiscalYearShift: 0,
  beginDate: null,
  fromPeriod: null,
  toPeriod: null,
  disabled: false,
  defaultInitialPeriod: 1,
  disableBeforePeriod: null,
  disableAfterPeriod: null,
};

export default PeriodsPicker;
