import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import QueryBuilder from '@palette/components/designSystem/QueryBuilder/QueryBuilder';
import FilterFilled from '@palette/components/utils/Icons/FilterFilled';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './ResourcesQueryFilterModal.less';

const ResourcesQueryFilterModal = ({ visible, value, helperData, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const [queryFilter, setQueryFilter] = useState(value);

  const cleanAndClose = (event) => {
    onClose(event);
  };

  const handleQueryBuilder = useCallback((event) => {
    onSubmit(queryFilter);
    cleanAndClose(event);
  }, [queryFilter]);

  const handleChange = (newFilter) => {
    setQueryFilter(newFilter);
  };

  return (
    <Modal
      className={styles.modal}
      title={t('resources.filter.modal.title')}
      visible={visible}
      onCancel={(event) => cleanAndClose(event)}
      onOk={(event) => handleQueryBuilder(event)}
      okText={t('resources.filter.modal.ok.label')}
      okIcon={<FilterFilled />}
      width="100%"
    >
      <QueryBuilder
        helperData={helperData}
        emptyLabel={t('resources.filter.modal.queryBuilder.empty.label')}
        prefix="data."
        value={value}
        onChange={handleChange}
      />
    </Modal>
  );
};

ResourcesQueryFilterModal.propTypes = {
  visible: PropTypes.bool,
  value: PropTypes.string,
  helperData: ResourceObjectModel.propTypes,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

ResourcesQueryFilterModal.defaultProps = {
  visible: false,
  value: '',
  helperData: {},
  onSubmit: () => {},
  onClose: () => {},
};

export default ResourcesQueryFilterModal;
