/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

import _assign from 'lodash/assign';
import _merge from 'lodash/merge';

/*
 * Initial State
 */
const initialState = {
  getByIdIsPending: false,
  deleteIsPending: false,
  cloneIsPending: false,
  byId: {},
  removePropertyIsPending: false,
  addEditPropertyIsPending: false,
  rollbackIsPending: false,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    getById: (state) => {
      state.getByIdIsPending = true;
    },
    setById: (state, { payload }) => {
      const { resource, merge = false } = payload;
      if (merge) {
        state.byId = _merge(state.byId, { [resource.id]: resource });
      } else {
        state.byId = _assign(state.byId, { [resource.id]: resource });
      }
    },
    getByIdCompleted: (state) => {
      state.getByIdIsPending = false;
    },
    removeProperty: (state) => {
      state.removePropertyIsPending = true;
    },
    removePropertyCompleted: (state) => {
      state.removePropertyIsPending = false;
    },
    addEditProperty: (state) => {
      state.addEditPropertyIsPending = true;
    },
    addEditPropertyCompleted: (state) => {
      state.addEditPropertyIsPending = false;
    },
    deleteOverwrite: (state) => {
      state.rollbackIsPending = true;
    },
    deleteOverwriteCompleted: (state) => {
      state.rollbackIsPending = false;
    },
    deleteResource: (state) => {
      state.deleteIsPending = true;
    },
    deleteResourceCompleted: (state) => {
      state.deleteIsPending = false;
    },
    cloneResource: (state) => {
      state.cloneIsPending = true;
    },
    cloneResourceCompleted: (state) => {
      state.cloneIsPending = false;
    },
    copyResourceDataAsJSON: () => {
      // Nothing to do here
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getById = (state) => root(state).byId;

const getByIdIsPending = (state) => root(state).getByIdIsPending;
const removePropertyIsPending = (state) => root(state).removePropertyIsPending;
const addEditPropertyIsPending = (state) => root(state).addEditPropertyIsPending;
const rollbackIsPending = (state) => root(state).rollbackIsPending;
const deleteIsPending = (state) => root(state).deleteIsPending;
const cloneIsPending = (state) => root(state).cloneIsPending;

const getResourceById = createSelector(
  [getById, getProps],
  (byId, { resourceId }) => byId[resourceId] || null,
);

export const selectors = {
  getByIdIsPending,
  getResourceById,
  removePropertyIsPending,
  addEditPropertyIsPending,
  rollbackIsPending,
  deleteIsPending,
  cloneIsPending,
};
