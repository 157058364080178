/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
 * Initial State
 */
const initialState = {
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Send analytics event */
    sendEvent: () => {
      /* Nothing to do */
    },
    /* Send analytics click event for documentation link */
    sendClickOnDocLink: () => {
      /* Nothing to do */
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
// const root = (state) => state[slice.name];

export const selectors = {
};
