import PropTypes from 'prop-types';

import { manageArrayAttribute, manageDateAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'ResourceObjectOverwrite';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  path: PropTypes.array,
  value: PropTypes.any,
  originalValue: PropTypes.any,
  created: PropTypes.string,
});

export const transform = (ResourceObjectOverwrite) => {
  if (!ResourceObjectOverwrite) {
    return null;
  }

  return ({
    id: manageStringAttribute(ResourceObjectOverwrite, '_id'),
    path: manageArrayAttribute(ResourceObjectOverwrite, 'fragments'),
    value: ResourceObjectOverwrite.value,
    originalValue: ResourceObjectOverwrite.originalValue,
    created: manageDateAttribute(ResourceObjectOverwrite, 'created'),
  });
};

export const transformList = (ResourceObjectOverwrites) => ResourceObjectOverwrites.map((ResourceObjectOverwrite) => transform(ResourceObjectOverwrite));
