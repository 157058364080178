import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  PLAN_V3_CREATED_SUCCESS: {
    code: 'notifications.plan.V3.success.create',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_V3_REQUIREMENTS_UPDATED_SUCCESS: {
    code: 'notifications.plan.V3.success.requirements.update',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_V3_NO_PLAN_USER_ERROR: {
    code: 'notifications.plan.V3.error.no.plan.user',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_V3_NO_PLAN_DATA_SOURCE_ERROR: {
    code: 'notifications.plan.V3.error.no.datasource',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_V3_INVALID_END_DATE_ERROR: {
    code: 'notifications.plan.V3.error.invalid.endDate.message',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_V3_MISSING_INFORMATION_ERROR: {
    code: 'notifications.plan.V3.error.missing.information.message',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  PLAN_V3_COPY_TO_CLIPBOARD_ERROR: {
    code: 'notifications.planV3.error.copyName',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_V3_COPY_TO_CLIPBOARD_SUCCESS: {
    code: 'notifications.planV3.success.copyName',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_V3_PLAN_SAVED_SUCCESS: {
    code: 'notifications.plan.V3.success.plan.saved',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PLAN_V3_RUN_CODE_CELL_SUCCESS: {
    code: 'notifications.plan.V3.success.run.code.cell',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
