import {
  put,
  all,
  call,
  delay,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { actions as ProfileActions } from '@palette/state/Profile/slice';
import { actions as ConnectorsActions } from '@palette/state/Connectors/slice';
import { manageError as manageProfileError } from '@palette/state/Profile/errors';

import { setLocalStorageItem } from '@palette/helpers/LocalStorageHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { setSessionId } from '@palette/helpers/SessionHelper';

import {
  EMAIL_NOTIFICATIONS_EVENTS,
  STATEMENT_EVENTS,
  USER_EVENTS,
} from '@palette/constants/analytics';
import { POSTHOG_INITIATED } from '@palette/constants/localStorage';
import { EMAIL_PREFERENCES, FEATURES, STATEMENT_FX_RATES_MODE } from '@palette/constants/profile';
import { STATEMENT_STRATEGY_TYPES } from '@palette/constants/statements';
import { LOGIN_NEXT_DOCUMENTATION_URI } from '@palette/constants/authentication';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import routePaths from '@palette/config/routePaths';

import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as ExceptionsActions } from '@palette/state/Exceptions/slice';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import * as ProfileModel from '@palette/models/Profile';
import * as CompanyModel from '@palette/models/Company';

import * as ProfileService from '@palette/services/ProfileService';

export function* refreshProfile({ profile = null }) {
  yield put(ProfileActions.setProfile({ profile }));

  // Refresh connectors list each time profile is refresh
  // TODO: Need to move this call at the app boostrap when full app is in V2
  yield put(ConnectorsActions.refreshList());
}

export function* refreshProfileShouldDisplayNPS({ shouldDisplayNPS }) {
  yield put(ProfileActions.setShouldDisplayNPS({ shouldDisplayNPS }));
}

export function* getProfile({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(ProfileService.getProfile);

  if (callResult.ok) {
    const profile = ProfileModel.transform(callResult.data);
    yield call(refreshProfile, { profile });

    if (onSuccessCB !== null) onSuccessCB(profile);
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.getProfileCompleted());
}

export function* updateCompany({ payload = {} }) {
  const {
    timezone = undefined,
    logo = undefined,
    currencies = undefined,
    enableSuccessNotification = false,
    onSuccessCB = null,
  } = payload;

  const data = {};

  if (timezone !== undefined) data.timezone = timezone;

  if (logo !== undefined) data.logo = logo;

  if (currencies !== undefined) data.currencies = currencies;

  const callResult = yield call(ProfileService.updateCompany, data);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    if (enableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PROFILE_COMPANY_UPDATE_SUCCESS));
    }

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.updateCompanyCompleted());
}

export function* updateProfile({ payload = {} }) {
  const {
    logo = undefined,
    enableSuccessNotification = false,
    onSuccessCB = null,
  } = payload;

  const data = {};

  if (logo !== undefined) data.profilePicUrl = logo;

  const callResult = yield call(ProfileService.updateProfile, data);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    if (enableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PROFILE_UPDATE_SUCCESS));
    }

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.updateProfileCompleted());
}

export function* updateStatementStrategy({ payload = {} }) {
  const {
    type,
    weekRollType,
    halfMonthPivot,
    dateStrategy,
    onSuccessCB = null,
  } = payload;

  const data = {
    type,
    dateStrategy,
  };

  if (type === STATEMENT_STRATEGY_TYPES.WEEK || type === STATEMENT_STRATEGY_TYPES.BI_WEEK) {
    data.weekRollType = weekRollType;
  } else if (type === STATEMENT_STRATEGY_TYPES.HALF_MONTH) {
    data.halfMonthPivot = halfMonthPivot;
  }

  const callResult = yield call(ProfileService.updateStatementStrategy, data);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    if (onSuccessCB !== null) onSuccessCB();

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PROFILE_UPDATE_STATEMENT_STRATEGY_SUCCESS));
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.updateStatementStrategyCompleted());
}

export function* toggleStatementValidations({ payload = {} }) {
  const { value } = payload;

  const callResult = yield call(ProfileService.toggleStatementValidations);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    if (value) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.SETTINGS_STATEMENT_TOGGLE_VALIDATIONS_ACTIVATED_SUCCESS));
    } else {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.SETTINGS_STATEMENT_TOGGLE_VALIDATIONS_DEACTIVATED_SUCCESS));
    }
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.toggleStatementValidationsCompleted());
}

export function* toggleStatementValidationsNotifications({ payload = {} }) {
  const { value, userId } = payload;

  const data = {
    features: {
      [FEATURES.STATEMENT_VALIDATION_NOTIFICATIONS]: value,
    },
  };

  const callResult = yield call(ProfileService.updateCompany, data);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    yield call(analyticsSendEvent, {
      payload: {
        event: value ? STATEMENT_EVENTS.VALIDATION_EMAIL_ACTIVATED : STATEMENT_EVENTS.VALIDATION_EMAIL_DEACTIVATED,
        params: { userId },
      },
    });
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.toggleStatementValidationsNotificationsCompleted());
}

export function* toggleEmailPreferences({ payload = {} }) {
  const { value, preference, userId } = payload;

  const data = {
    emailPreferences: {
      [preference]: value,
    },
  };

  const callResult = yield call(ProfileService.updateProfile, data);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    if (preference !== EMAIL_PREFERENCES.THREAD_NOTIFICATIONS) {
      yield call(analyticsSendEvent, {
        payload: {
          event: value
            ? EMAIL_NOTIFICATIONS_EVENTS[`SUBSCRIBED_${preference}`]
            : EMAIL_NOTIFICATIONS_EVENTS[`UNSUBSCRIBED_${preference}`],
          params: { userId },
        },
      });
    }
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.toggleEmailPreferencesCompleted());
}

export function* toggleDigestNotifications({ payload = {} }) {
  const { value } = payload;

  const data = {
    features: {
      [FEATURES.DIGEST_NOTIFICATIONS]: value,
    },
  };

  const callResult = yield call(ProfileService.updateCompany, data);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.toggleDigestNotificationsCompleted());
}

export function* getSeatsCount() {
  const callResult = yield call(ProfileService.getSeatsCount);

  if (callResult.ok) {
    const companySeatsCount = CompanyModel.transformSeatsCount({ seatsCount: callResult.data });
    yield put(ProfileActions.setCompanySeatsCount({ companySeatsCount }));
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.getSeatsCountCompleted());
}

export function* login({ payload = {} }) {
  const { email, password, next = null } = payload;

  const callData = { email, password };

  const callResult = yield call(ProfileService.login, callData);

  if (callResult.ok) {
    const { sessionId } = callResult.data;

    setSessionId(sessionId);
    setLocalStorageItem(POSTHOG_INITIATED, 'false');

    if (next !== null) {
      if (next.includes(LOGIN_NEXT_DOCUMENTATION_URI)) {
        window.location = next;
        return;
      }

      redirectTo({ path: `/${decodeURIComponent(next)}` });
    } else {
      redirectTo({ path: '/' });
    }
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.loginCompleted());
}

export function* forgotPassword({ payload = {} }) {
  const { email } = payload;

  const callData = { email };

  const callResult = yield call(ProfileService.forgotPassword, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PROFILE_FORGOT_PASSWORD_SUCCESS));
    yield delay(2000);
    redirectTo({ path: routePaths.v2.login });
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.forgotPasswordCompleted());
}

export function* resetPassword({ payload = {} }) {
  const { resetPasswordToken, password, isNewUser = false } = payload;

  const callData = { resetPasswordToken, password };

  const callResult = yield call(ProfileService.resetPassword, callData);

  if (callResult.ok) {
    const { sessionId } = callResult.data;
    setSessionId(sessionId);

    if (isNewUser) {
      yield call(analyticsSendEvent, { payload: { event: USER_EVENTS.FIRST_LOGIN } });
    }

    redirectTo({ path: '/' });
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.resetPasswordCompleted());
}

export function connectAs({ payload = {} }) {
  const { sessionId } = payload;

  setSessionId(sessionId);
  setLocalStorageItem(POSTHOG_INITIATED, 'false');

  redirectTo({ path: '/' });
}

export function* acknowledgeTermsAndConditions() {
  const callResult = yield call(ProfileService.acknowledgeTermsAndConditions);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.acknowledgeTermsAndConditionsCompleted());
}

export function* getAggregatedFxRates() {
  const callResult = yield call(ProfileService.getAggregatedFxRates);

  if (callResult.ok) {
    const companyAggregatedFxRates = CompanyModel.transformAggregatedFxRates({ aggregatedFxRates: callResult.data });
    yield put(ProfileActions.setAggregatedFxRates({ companyAggregatedFxRates }));
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.getAggregatedFxRatesCompleted());
}

export function* createFxRate({ payload = {} }) {
  const { from, to, rate, startDate, onSuccessCB = null } = payload;

  const callData = { from, to, rate, startDate };

  const callResult = yield call(ProfileService.createFxRate, callData);

  if (callResult.ok) {
    yield put(ProfileActions.getAggregatedFxRates());
    yield take(ProfileActions.getAggregatedFxRatesCompleted.type);
    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.createFxRateCompleted());
}

export function* getListFxRates({ payload = {} }) {
  const { from, to } = payload;

  const callData = { from, to };

  const callResult = yield call(ProfileService.getListFxRates, callData);

  if (callResult.ok) {
    const listFxRates = CompanyModel.transformListFxRates({ listFxRates: callResult.data });
    yield put(ProfileActions.setListFxRates({ listFxRates }));
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.getListFxRatesCompleted());
}

export function* deleteFxRate({ payload = {} }) {
  const { fxRateId, from, to } = payload;

  const callData = { fxRateId };

  const callResult = yield call(ProfileService.deleteFxRate, callData);

  if (callResult.ok) {
    yield put(ProfileActions.getListFxRates({ from, to }));
    yield put(ProfileActions.getAggregatedFxRates());
    yield take(ProfileActions.getListFxRatesCompleted.type);
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.deleteFxRateCompleted());
}

export function* logout() {
  const callResult = yield call(ProfileService.logout);

  if (callResult.ok) {
    yield put(ProfileActions.logoutSucceeded());

    yield call(refreshProfile, { profile: null });

    yield put(ExceptionsActions.forceLogout());
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }
}

export function* getSubscription() {
  const [subscriptionResults, portalUrlResults] = yield all([
    call(ProfileService.getSubscription),
    call(ProfileService.getCustomerPortalUrl),
  ]);

  let error;
  let customerSubscription = {};

  if (subscriptionResults.ok) {
    customerSubscription = {
      ...subscriptionResults.data,
    };
  } else {
    error = manageProfileError(subscriptionResults);
  }

  if (portalUrlResults.ok) {
    customerSubscription = {
      ...customerSubscription,
      portalUrl: portalUrlResults.data,
    };
  } else {
    error = manageProfileError(portalUrlResults);
  }

  if (!error) {
    const subscriptionTransformed = CompanyModel.transformSubscription({ subscription: customerSubscription });
    yield put(ProfileActions.setSubscription({ subscription: subscriptionTransformed }));
  } else {
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.getSubscriptionCompleted());
}

export function* updateSubscriptionSeats({ payload = {} }) {
  const { quantity } = payload;

  const callData = { quantity };

  const callResult = yield call(ProfileService.updateSeats, callData);

  if (callResult.ok) {
    yield put(ProfileActions.getSubscription());
    yield take(ProfileActions.getSubscriptionCompleted.type);
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.updateSubscriptionSeatsCompleted());
}

export function* sendFeedback({ payload = {} }) {
  const {
    content,
    attachments = [],
    onSuccessCB = null,
  } = payload;

  const callData = {
    content,
    attachments,
  };

  const callResult = yield call(ProfileService.sendFeedback, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PROFILE_SEND_FEEDBACK_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.sendFeedbackCompleted());
}

export function* updateStatementFxRateMode({ payload = {} }) {
  const { value = STATEMENT_FX_RATES_MODE.FROM_PAYMENT_DATE } = payload;

  const callData = { value };

  const callResult = yield call(ProfileService.updateStatementFxRateMode, callData);

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());
    yield take(ProfileActions.getProfileCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.SETTINGS_STATEMENT_FX_RATE_MODE_SUCCESS));
  } else {
    const error = manageProfileError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ProfileActions.updateStatementFxRateModeCompleted());
}

export function* loop() {
  yield all([
    takeLatest(ProfileActions.getProfile.type, getProfile),
    takeLatest(ProfileActions.updateCompany.type, updateCompany),
    takeLatest(ProfileActions.updateProfile.type, updateProfile),
    takeLatest(ProfileActions.updateStatementStrategy.type, updateStatementStrategy),
    takeLatest(ProfileActions.toggleStatementValidations.type, toggleStatementValidations),
    takeLatest(ProfileActions.toggleStatementValidationsNotifications.type, toggleStatementValidationsNotifications),
    takeLatest(ProfileActions.toggleEmailPreferences.type, toggleEmailPreferences),
    takeLatest(ProfileActions.toggleDigestNotifications.type, toggleDigestNotifications),
    takeLatest(ProfileActions.getSeatsCount.type, getSeatsCount),
    takeLatest(ProfileActions.login.type, login),
    takeLatest(ProfileActions.forgotPassword.type, forgotPassword),
    takeLatest(ProfileActions.resetPassword.type, resetPassword),
    takeLatest(ProfileActions.connectAs.type, connectAs),
    takeLatest(ProfileActions.acknowledgeTermsAndConditions.type, acknowledgeTermsAndConditions),
    takeLatest(ProfileActions.getAggregatedFxRates.type, getAggregatedFxRates),
    takeLatest(ProfileActions.createFxRate.type, createFxRate),
    takeLatest(ProfileActions.getListFxRates.type, getListFxRates),
    takeLatest(ProfileActions.deleteFxRate.type, deleteFxRate),
    takeLatest(ProfileActions.logout.type, logout),
    takeLatest(ProfileActions.getSubscription.type, getSubscription),
    takeLatest(ProfileActions.updateSubscriptionSeats.type, updateSubscriptionSeats),
    takeLatest(ProfileActions.sendFeedback.type, sendFeedback),
    takeLatest(ProfileActions.updateStatementFxRateMode.type, updateStatementFxRateMode),
  ]);
}

export function* clean() {
  yield put(ProfileActions.resetToInitialState());
}
