/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _mergeWith from 'lodash/mergeWith';
import _cloneDeep from 'lodash/cloneDeep';
import { original } from 'immer';

import * as RoleModel from '@palette/models/Role';

/*
 * Initial State
 */
const initialState = {
  getRolesIsPending: false,
  deleteRoleByIdIsPending: false,
  duplicateRoleByIdIsPending: false,
  makeDefaultRoleByIdIsPending: false,
  getRoleByIdIsPending: false,
  updateRoleIsPending: false,
  getRightsIsPending: false,
  createRoleIsPending: false,

  roles: [],
  byId: {},
  rights: [],
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Roles List */
    getRoles: (state) => {
      state.getRolesIsPending = true;
    },
    setRoles: (state, { payload }) => {
      const { roles = [] } = payload;
      state.roles = _map(roles, 'id');

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(roles, 'id'),
        RoleModel.merge,
      );
    },
    getRolesCompleted: (state) => {
      state.getRolesIsPending = false;
    },
    /* Delete Role by Id */
    deleteRoleById: (state) => {
      state.deleteRoleByIdIsPending = true;
    },
    deleteRoleByIdCompleted: (state) => {
      state.deleteRoleByIdIsPending = false;
    },
    /* Duplicate Role by Id */
    duplicateRoleById: (state) => {
      state.duplicateRoleByIdIsPending = true;
    },
    duplicateRoleByIdCompleted: (state) => {
      state.duplicateRoleByIdIsPending = false;
    },
    /* Make Default Role by Id */
    makeDefaultRoleById: (state) => {
      state.makeDefaultRoleByIdIsPending = true;
    },
    makeDefaultRoleByIdCompleted: (state) => {
      state.makeDefaultRoleByIdIsPending = false;
    },
    /* Get Role by Id */
    getRoleById: (state) => {
      state.getRoleByIdIsPending = true;
    },
    setRole: (state, { payload }) => {
      const { role } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      const inStateRole = clonedById[role.id] || null;

      clonedById[role.id] = RoleModel.merge(inStateRole, role);

      state.byId = clonedById;
    },
    getRoleByIdCompleted: (state) => {
      state.getRoleByIdIsPending = false;
    },
    /* Update Role */
    updateRole: (state) => {
      state.updateRoleIsPending = true;
    },
    updateRoleCompleted: (state) => {
      state.updateRoleIsPending = false;
    },
    /* Create Role */
    createRole: (state) => {
      state.createRoleIsPending = true;
    },
    createRoleCompleted: (state) => {
      state.createRoleIsPending = false;
    },
    /* Get Rights List */
    getRights: (state) => {
      state.getRightsIsPending = true;
    },
    setRights: (state, { payload }) => {
      const { rights = [] } = payload;
      state.rights = rights;
    },
    getRightsCompleted: (state) => {
      state.getRightsIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;

const rawRoles = (state) => root(state).roles;
const rawById = (state) => root(state).byId;
const rawRights = (state) => root(state).rights;

const rawGetRolesIsPending = (state) => root(state).getRolesIsPending;
const rawDeleteRoleByIdIsPending = (state) => root(state).deleteRoleByIdIsPending;
const rawDuplicateRoleByIdIsPending = (state) => root(state).duplicateRoleByIdIsPending;
const rawMakeDefaultRoleByIdIsPending = (state) => root(state).makeDefaultRoleByIdIsPending;
const rawGetRoleByIdIsPending = (state) => root(state).getRoleByIdIsPending;
const rawUpdateRoleIsPending = (state) => root(state).updateRoleIsPending;
const rawGetRightsIsPending = (state) => root(state).getRightsIsPending;
const rawCreateRoleIsPending = (state) => root(state).createRoleIsPending;

const getRoles = createSelector(
  [rawRoles, rawById],
  (rolesIds, byId) => rolesIds.map((roleId) => byId[roleId] || null).filter(Boolean),
);

const getRoleById = createSelector(
  [rawById, getProps],
  (byId, { roleId }) => byId[roleId] || null,
);

const getRights = rawRights;

const getRolesIsPending = rawGetRolesIsPending;
const deleteRoleByIdIsPending = rawDeleteRoleByIdIsPending;
const duplicateRoleByIdIsPending = rawDuplicateRoleByIdIsPending;
const makeDefaultRoleByIdIsPending = rawMakeDefaultRoleByIdIsPending;
const getRoleByIdIsPending = rawGetRoleByIdIsPending;
const updateRoleIsPending = rawUpdateRoleIsPending;
const getRightsIsPending = rawGetRightsIsPending;
const createRoleIsPending = rawCreateRoleIsPending;

export const selectors = {
  getRolesIsPending,
  deleteRoleByIdIsPending,
  duplicateRoleByIdIsPending,
  makeDefaultRoleByIdIsPending,
  getRoleByIdIsPending,
  updateRoleIsPending,
  getRightsIsPending,
  createRoleIsPending,

  getRoles,
  getRoleById,
  getRights,
};
