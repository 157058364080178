import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PlanWidgetBase from '@palette/components/widgets/dashboard/PlanWidgetBase/PlanWidgetBase';
import PlanProgressBarChart from '@palette/components/charts/PlanProgressBarChart/PlanProgressBarChart';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as PlanProgressBarChartModel from '@palette/models/widgets/dashboard/PlanProgressBarChartData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';

import styles from './PlanProgressBarChartWidget.less';

const classNames = bindClassNames.bind(styles);

const PlanProgressBarChartWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planProgressBarChartData = PlanProgressBarChartModel.transform(widgetData.data);

  const contentNode = useMemo(() => {
    if (planProgressBarChartData.progressPerPeriod.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <PlanProgressBarChart
        plan={planPeriodData.plan}
        period={planPeriodData.period}
        data={planProgressBarChartData}
      />
    );
  }, [planProgressBarChartData]);

  return (
    <PlanWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      useMostRecent={widgetData.dashboardComponent.params.useMostRecent}
      plan={planPeriodData.plan}
      period={planPeriodData.period}
      title={t('planProgressBarChartWidget.title')}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      {contentNode}
    </PlanWidgetBase>
  );
};

PlanProgressBarChartWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanProgressBarChartWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlanProgressBarChartWidget;
