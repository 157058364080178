import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  PROFILE_ACCOUNT_LOCKED: {
    code: 'profile.errors.account.locked',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PROFILE_ACCOUNT_INVALID_CREDENTIALS: {
    code: 'profile.errors.invalid.credentials',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PROFILE_404_USER_NOT_FOUND: {
    code: 'profile.errors.reset.password.link.expired',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PROFILE_COMPANY_UPDATE_SUCCESS: {
    code: 'notifications.profile.success.company.update',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PROFILE_UPDATE_SUCCESS: {
    code: 'notifications.profile.success.profile.update',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PROFILE_UPDATE_STATEMENT_STRATEGY_SUCCESS: {
    code: 'notifications.profile.success.updateStatementStrategy',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PROFILE_FORGOT_PASSWORD_SUCCESS: {
    code: 'notifications.success.forgotPassword',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PROFILE_SEND_FEEDBACK_SUCCESS: {
    code: 'notifications.profile.success.sendFeedback',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  SETTINGS_STATEMENT_FX_RATE_MODE_SUCCESS: {
    code: 'notifications.statementFxRateMode.success',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  SETTINGS_STATEMENT_TOGGLE_VALIDATIONS_ACTIVATED_SUCCESS: {
    code: 'notifications.statementValidations.success.activated',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  SETTINGS_STATEMENT_TOGGLE_VALIDATIONS_DEACTIVATED_SUCCESS: {
    code: 'notifications.statementValidations.success.deactivated',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
