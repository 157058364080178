import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import CardItem from '@palette/components/settings/CardItem/CardItem';
import Switch from '@palette/components/designSystem/Switch/Switch';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './StatementValidations.less';

const classNames = bindClassNames.bind(styles);

const StatementValidations = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company, id } = profile.userData;

  const [statementValidations, setStatementValidations] = useState(company.features[FEATURES.STATEMENT_VALIDATION]);
  const [statementValidationsNotifications, setStatementValidationsNotifications] = useState(company.features[FEATURES.STATEMENT_VALIDATION_NOTIFICATIONS]);

  const toggleStatementValidationsIsPending = useSelector(ProfileSelectors.toggleStatementValidationsIsPending);
  const toggleStatementValidationsNotificationsIsPending = useSelector(ProfileSelectors.toggleStatementValidationsNotificationsIsPending);

  const toggleStatementValidations = useCallback((value) => {
    if (!toggleStatementValidationsIsPending) {
      setStatementValidations(value);
      dispatch(ProfileActions.toggleStatementValidations({ value }));
    }
  }, [toggleStatementValidationsIsPending]);

  const toggleStatementValidationsNotifications = useCallback((value) => {
    if (!toggleStatementValidationsNotificationsIsPending) {
      setStatementValidationsNotifications(value);
      dispatch(ProfileActions.toggleStatementValidationsNotifications({ value, userId: id }));
    }
  }, [toggleStatementValidationsNotificationsIsPending, id]);

  const notificationsNode = useMemo(() => {
    if (!statementValidations) return null;

    return (
      <div className={styles.notificationsWrapper}>
        <div className={styles.textContentBold}>{t('statementValidations.content.title.notifications')}</div>
        <div className={styles.textContent}>{t('statementValidations.content.text.notifications')}</div>
        <div className={styles.switchWrapper}>
          <Switch
            value={statementValidationsNotifications}
            onChange={toggleStatementValidationsNotifications}
            disabled={toggleStatementValidationsNotificationsIsPending}
          />
          <div className={styles.switchLabel}>{t('statementValidations.content.switch.toggleNotifications')}</div>
        </div>
      </div>
    );
  }, [
    statementValidations,
    statementValidationsNotifications,
    toggleStatementValidationsNotifications,
    toggleStatementValidationsNotificationsIsPending,
  ]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('statementValidations.block.title')}
      icon={<CheckFilled width={20} height={20} />}
    >
      <div className={styles.textContent}>{t('statementValidations.content.text.approvals')}</div>
      <div className={styles.switchWrapper}>
        <Switch
          value={statementValidations}
          onChange={toggleStatementValidations}
          disabled={toggleStatementValidationsIsPending}
        />
        <div className={styles.switchLabel}>{t('statementValidations.content.switch.toggleValidations')}</div>
      </div>
      {notificationsNode}
    </CardItem>
  );
};

StatementValidations.propTypes = {
  className: PropTypes.string,
};

StatementValidations.defaultProps = {
  className: '',
};

export default StatementValidations;
