import PropTypes from 'prop-types';

import { manageAmountAttribute, manageDateAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';
import { getPeriodConstantsFromPeriodId } from '@palette/helpers/StatementHelper';

import { STATEMENT_PERIODICITY_TYPES, STATEMENT_CORRECTION_TYPES } from '@palette/constants/StatementV3';

export const modelName = 'StatementV3ListCorrection';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  author: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  date: PropTypes.string,
  reason: PropTypes.string,
  status: PropTypes.oneOf(Object.values(STATEMENT_CORRECTION_TYPES)),
  fromStatement: PropTypes.shape({
    id: PropTypes.string,
    periodId: PropTypes.string,
    periodicity: PropTypes.oneOf(Object.values(STATEMENT_PERIODICITY_TYPES)),
    period: PropTypes.number,
    year: PropTypes.number,
  }),
});

export const transform = (correction) => {
  if (!correction) {
    return null;
  }

  const manageFromStatement = (sourceStatementId) => {
    if (!sourceStatementId) return undefined;

    const id = sourceStatementId;
    const [, periodId] = sourceStatementId.split('-');
    const [periodicity, period, year] = getPeriodConstantsFromPeriodId(periodId);

    return { id, periodId, periodicity, period, year };
  };

  return (
    {
      id: manageStringAttribute(correction, 'id'),
      author: manageStringAttribute(correction, 'authorId'),
      amount: manageAmountAttribute(correction, 'amount'),
      currency: manageStringAttribute(correction, 'currency'),
      date: manageDateAttribute(correction, 'date'),
      reason: manageStringAttribute(correction, 'reason'),
      status: STATEMENT_CORRECTION_TYPES[manageStringAttribute(correction, 'status')],
      fromStatement: manageFromStatement(manageStringAttribute(correction, 'sourceStatementId')),
    }
  );
};

export const transformList = (corrections) => corrections.map((correction) => transform(correction));
