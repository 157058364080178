import { useMemo } from 'react';

import { getMoment } from '@palette/helpers/MomentHelper';

import { getCommissionV3Amount } from '@palette/helpers/CommissionV3Helper';

export const usePayments = (commission) => {
  const moment = getMoment();

  return useMemo(() => {
    const commissionAmount = getCommissionV3Amount(commission);

    const done = {
      payments: [],
      percentage: 0,
      value: 0,
    };
    const toBeDone = {
      payments: [],
      percentage: 0,
      value: 0,
    };
    const remaining = {
      percentage: 0,
      value: 0,
    };
    let cumulativeRoundedValues = 0;

    if (commission.payments.length) {
      commission.payments.forEach((payment) => {
        const paymentValue = payment.percentage * commissionAmount;
        cumulativeRoundedValues += parseFloat(paymentValue.toFixed(2));
        if (moment.utc(payment.date).isBefore(moment.utc(), 'month')) {
          done.payments.push(payment);
          done.percentage += payment.percentage;
          done.value += paymentValue;
        } else {
          toBeDone.payments.push(payment);
          toBeDone.percentage += payment.percentage;
          toBeDone.value += paymentValue;
        }
      });
    }

    remaining.percentage = 1 - done.percentage - toBeDone.percentage;
    remaining.value = commissionAmount - done.value - toBeDone.value;

    return [done, toBeDone, remaining, cumulativeRoundedValues];
  }, [commission]);
};
