/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';

import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _mergeWith from 'lodash/mergeWith';
import _keyBy from 'lodash/keyBy';

/*
 * Initial State
 */
const initialState = {
  byId: {},
  listByFilters: {},
  listByFiltersPagination: {},

  listByFiltersIsPending: false,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'auditTrail',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* List By Filters */
    listByFilters: (state) => {
      state.listByFiltersIsPending = true;
    },
    setListByFilters: (state, { payload }) => {
      const { auditTrails, pagination, callData } = payload;

      state.listByFilters = {
        list: _map(auditTrails, 'id'),
        callData,
      };
      state.listByFiltersPagination = pagination;

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(auditTrails, 'id'),
      );
    },
    listByFiltersCompleted: (state) => {
      state.listByFiltersIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getById = (state) => root(state).byId;
const getListByFilters = (state) => root(state).listByFilters;
const listByFiltersPagination = (state) => root(state).listByFiltersPagination;

const listByFiltersIsPending = (state) => root(state).listByFiltersIsPending;

const getAuditTrailsListByFilters = createSelector(
  [getListByFilters, getById],
  (byFilters, byId) => (byFilters.list || []).map((auditTrailId) => byId[auditTrailId] || null).filter(Boolean),
);

export const selectors = {
  getAuditTrailsListByFilters,
  listByFiltersPagination,

  listByFiltersIsPending,
};
