import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Avatar as AntDAvatar } from 'antd';

import Avatar from '@palette/components/user/Avatar/Avatar';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './AvatarGroup.less';

const classNames = bindClassNames.bind(styles);

const AvatarGroup = ({ className, size, users, nbToDisplay, totalUsers }) => {
  const diffNbUsers = totalUsers - nbToDisplay;

  let diffNbUsersNode = null;
  if (diffNbUsers > 0) {
    const diffNbUsersLabel = `+${diffNbUsers}`;
    diffNbUsersNode = (
      <AntDAvatar
        className={classNames({
          diffNbUsers: true,
          avatar: true,
        })}
        draggable={false}
        shape="circle"
        size={size}
        style={{
          left: `${nbToDisplay * 12 + 6}px`,
          zIndex: 0,
        }}
      >
        {diffNbUsersLabel}
      </AntDAvatar>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      style={{ width: `${(nbToDisplay + 1) * 12}px` }}
    >
      {
        users.slice(0, nbToDisplay).map((user, index) => (
          <Avatar key={user.id} className={styles.avatar} style={{ left: `${index * 12}px`, zIndex: (nbToDisplay + 1 - index) }} user={user} size={size} />
        ))
      }
      {diffNbUsersNode}
    </div>
  );
};

AvatarGroup.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  users: PropTypes.arrayOf(MetaUserModel.propTypes).isRequired,
  nbToDisplay: PropTypes.number.isRequired,
  totalUsers: PropTypes.number.isRequired,
};

AvatarGroup.defaultProps = {
  className: '',
  size: 'small',
};

export default AvatarGroup;
