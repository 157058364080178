import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import StatementsSnapshotsExportListItem from '@palette/components/statement/statementValidation/StatementsSnapshotsExportListItem/StatementsSnapshotsExportListItem';

import { selectors as StatementsSelectors } from '@palette/state/Statements';

import styles from './StatementsSnapshotsExportList.less';

const classNames = bindClassNames.bind(styles);

const StatementsSnapshotsExportList = ({ className }) => {
  const { t } = useTranslation();

  const statementsSnapshotsList = useSelector(StatementsSelectors.getListStatementsSnapshots);

  const resultsListNode = useMemo(() => {
    if (statementsSnapshotsList.length === 0) {
      return (
        <DefaultEmptyState
          className={styles.emptyContainer}
          size="compact"
          description={t('statementsSnapshotsExport.empty.description')}
        />
      );
    }

    return statementsSnapshotsList.map((snapshot) => (
      <StatementsSnapshotsExportListItem key={snapshot.id} snapshot={snapshot} />
    ));
  }, [
    statementsSnapshotsList,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {resultsListNode}
    </div>
  );
};

StatementsSnapshotsExportList.propTypes = {
  className: PropTypes.string,
};

StatementsSnapshotsExportList.defaultProps = {
  className: '',
};

export default StatementsSnapshotsExportList;
