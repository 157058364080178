import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import PlanV3ListItem from '@palette/components/planV3/PlanV3ListItem/PlanV3ListItem';
import RootFolder from '@palette/components/folders/FoldersRecursive/RootFolder/RootFolder';

import { buildPlansInFolders } from '@palette/helpers/MasterPlanHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { PLANS_V3_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';
import { FOLDER_TYPES, DEFAULT_FOLDER_ID } from '@palette/constants/folders';
import { RIGHTS } from '@palette/constants/profile';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { selectors as PlanV3Selectors } from '@palette/state/PlanV3';
import { actions as FoldersActions, selectors as FoldersSelectors } from '@palette/state/Folders';

import styles from './PlanV3List.less';

const classNames = bindClassNames.bind(styles);

const PlanV3List = ({ className, listType, searchedPlan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const listIsPending = useSelector(PlanV3Selectors.getListIsPending);
  const foldersListIsPending = useSelector(FoldersSelectors.getListIsPending);

  const plansList = useSelector((state) => PlanV3Selectors.getList(state));
  const foldersList = useSelector(FoldersSelectors.getFoldersList);

  const filteredPlansList = useMemo(() => {
    if (plansList.length === 0 || searchedPlan === '') return plansList;

    return plansList.filter((plan) => plan.name.toLowerCase().indexOf(searchedPlan.toLowerCase()) > -1);
  }, [plansList, searchedPlan]);

  const hasRightToCreatePlan = hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE]);

  const rootFolder = useMemo(() => (
    buildPlansInFolders(filteredPlansList, foldersList, hasRightToCreatePlan, listType, searchedPlan)
  ), [filteredPlansList, foldersList, hasRightToCreatePlan, searchedPlan]);

  const handleMoveFolder = (folderId, parentId) => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])) return;

    let finalParentId = parentId;
    let type;

    if (parentId === DEFAULT_FOLDER_ID) {
      finalParentId = undefined;
      type = FOLDER_TYPES.PLAN;
    }

    dispatch(FoldersActions.moveFolder({
      folderId,
      parentId: finalParentId,
      type,
    }));
  };

  const handleMoveEntity = (resourceId, parentId) => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])) return;

    let finalParentId = parentId;

    if (parentId === DEFAULT_FOLDER_ID) {
      finalParentId = '';
    }

    dispatch(FoldersActions.updatePlanFolder({ planId: resourceId, folderId: finalParentId }));
  };

  let listContentNode = (
    <DefaultEmptyState description={t('masterPlanList.empty.description')} />
  );

  if (listIsPending || foldersListIsPending) {
    listContentNode = (
      <Loader />
    );
  } else if (rootFolder.children.length > 0 || rootFolder.resources.length > 0) {
    listContentNode = (
      <RootFolder
        folder={rootFolder}
        ItemComponent={PlanV3ListItem}
        onMoveFolder={handleMoveFolder}
        onMoveEntity={handleMoveEntity}
        folderColor="yellow"
        isPlanV3
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {listContentNode}
    </div>
  );
};

PlanV3List.propTypes = {
  className: PropTypes.string,
  listType: PropTypes.string,
  searchedPlan: PropTypes.string,
};

PlanV3List.defaultProps = {
  className: '',
  listType: PLANS_V3_PAGE_CONTENT_TABS_IDS.ACTIVE,
  searchedPlan: '',
};

export default PlanV3List;
