import PropTypes from 'prop-types';

import { manageAmountAttribute, manageDateAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

export const modelName = 'StatementV3Action';

export const propTypes = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(STATEMENT_ACTION_TYPES)),
  userId: PropTypes.string,
  date: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
});

export const transform = (action) => {
  if (!action) {
    return null;
  }

  return (
    {
      type: STATEMENT_ACTION_TYPES[manageStringAttribute(action, 'type')],
      userId: manageStringAttribute(action, 'authorId'),
      date: manageDateAttribute(action, 'date'),
      amount: manageAmountAttribute(action, 'amount'),
      currency: manageStringAttribute(action, 'currency'),
    }
  );
};

export const transformList = (actions) => actions.map((action) => transform(action));
