import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import AddEditAdjustmentModal from '@palette/components/statement/userStatement/AddEditAdjustmentModal/AddEditAdjustmentModal';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementAdjustmentModel from '@palette/models/UserStatementAdjustment';

import styles from './EditUserStatementAdjustmentButton.less';

const classNames = bindClassNames.bind(styles);

const EditUserStatementAdjustmentButton = ({
  className,
  userStatement,
  statementPeriod,
  currency,
  adjustment,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation();

  const [editAdjustmentModalIsVisible, showEditAdjustmentModal] = useState(false);

  const handleEdit = useCallback((event) => {
    if (onClick !== null) {
      onClick(event);
    }
    showEditAdjustmentModal(true);
  }, [onClick]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.editButton}
        type="link"
        icon={<PenFilled width={18} height={18} />}
        onClick={handleEdit}
        disabled={disabled}
      >
        {t('actions.edit')}
      </Button>
      {editAdjustmentModalIsVisible && (
        <AddEditAdjustmentModal
          visible
          onClose={() => showEditAdjustmentModal(false)}
          statementId={userStatement.id}
          statementAmount={userStatement.statementAmount}
          statementCurrency={userStatement.currency}
          statementPeriod={statementPeriod}
          adjustment={adjustment}
          currency={currency}
        />
      )}
    </div>
  );
};

EditUserStatementAdjustmentButton.propTypes = {
  className: PropTypes.string,
  adjustment: UserStatementAdjustmentModel.propTypes.isRequired,
  userStatement: UserStatementModel.propTypes.isRequired,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  currency: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

EditUserStatementAdjustmentButton.defaultProps = {
  className: '',
  onClick: null,
  currency: null,
  disabled: false,
};

export default EditUserStatementAdjustmentButton;
