import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  STATEMENT_ADJUSTMENT_DELETION_SUCCESS: {
    code: 'notifications.statements.success.deleteAdjustment',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  STATEMENT_ADJUSTMENT_UPDATE_SUCCESS: {
    code: 'notifications.statements.success.updateAdjustment',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  STATEMENT_ADJUSTMENT_ADD_SUCCESS: {
    code: 'notifications.statements.success.addAdjustment',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  STATEMENT_APPLY_CORRECTION_SUCCESS: {
    code: 'notifications.statements.success.applyCorrection',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  STATEMENT_CANCEL_APPROVAL_SUCCESS: {
    code: 'notifications.statements.success.cancelStatementApproval',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  STATEMENT_IGNORE_CORRECTION_SUCCESS: {
    code: 'notifications.statements.success.ignoreCorrection',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  STATEMENT_APPROVE_STATEMENTS_SUCCESS: {
    code: 'notifications.statements.success.approve.statements.title',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  STATEMENT_MARK_AS_PAID_STATEMENTS_SUCCESS: {
    code: 'notifications.statements.success.markAsPaid.statements.title',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
