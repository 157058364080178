import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Tag from '@palette/components/designSystem/Tag/Tag';
import Link from '@palette/components/designSystem/Link/Link';

import { useMasterPlanColumns } from '@palette/hooks/MasterPlanHooks';

import { getColumnValue } from '@palette/helpers/ConnectorHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { SEARCHED_FROM_QS_KEY, SEARCHED_RESOURCE_QS_KEY, SEARCHED_TO_QS_KEY } from '@palette/constants/commissions';
import { TAG_THEMES } from '@palette/constants/tag';

import routePaths from '@palette/config/routePaths';

import * as LatestObjectsDataModel from '@palette/models/widgets/dashboard/LatestObjectsData';

import styles from './DealDescription.less';

const classNames = bindClassNames.bind(styles);

const DealDescription = ({ className, deal, displayType, readOnly, enableLinks }) => {
  const moment = getMoment();

  const planColumns = useMasterPlanColumns(deal.plansWithPeriods[0].plan);

  const description = useMemo(() => {
    const dealName = planColumns.length > 0 ? getColumnValue(deal.resourceObject?.data || {}, planColumns[0]) : '';

    const qsObject = {
      [SEARCHED_RESOURCE_QS_KEY]: dealName,
      [SEARCHED_FROM_QS_KEY]: moment(deal.date).format('YYYY-MM-DD'),
      [SEARCHED_TO_QS_KEY]: moment(deal.date).format('YYYY-MM-DD'),
    };

    return (
      <Link
        className={styles.nameLink}
        path={routePaths.v2.myCommissionList}
        qsObject={qsObject}
        disabled={readOnly && !enableLinks}
      >
        {dealName}
      </Link>
    );
  }, [deal, readOnly, enableLinks, planColumns]);

  const typeNode = useMemo(() => {
    if (!displayType) return null;

    const type = deal.plansWithPeriods?.[0]?.plan.trackingObject?.type || null;

    if (type === null) return null;

    return (
      <Tag
        className={styles.type}
        theme={TAG_THEMES.INFO}
        label={type}
      />
    );
  }, [deal, displayType]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {description}
      {typeNode}
    </div>
  );
};

DealDescription.propTypes = {
  className: PropTypes.string,
  deal: LatestObjectsDataModel.dealPropTypes.isRequired,
  displayType: PropTypes.bool,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

DealDescription.defaultProps = {
  className: '',
  displayType: false,
  readOnly: false,
  enableLinks: true,
};

export default DealDescription;
