import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import CreatePlanFlowCommissionsResults from '@palette/components/masterPlanCreation/CreatePlanFlowCommissionsResults/CreatePlanFlowCommissionsResults';

import { getPlanFirstPeriod, getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowPreviewCommissions.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowPreviewCommissions = ({ className, plan, onPreview }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planFirstPeriod = useMemo(() => getPlanFirstPeriod(plan), [plan]);

  const [runAtLeastOnce, setRunAtLeastOnce] = useState(false);

  const computePlanIsPending = useSelector(MasterPlansSelectors.computePlanIsPending);

  const performCompute = useCallback(() => {
    setRunAtLeastOnce(true);
    dispatch(MasterPlansActions.computePlan({ planId: plan.id, disableSuccessNotification: true }));
  }, [plan]);

  const handlePreview = useCallback(() => {
    if (onPreview !== null) {
      onPreview(performCompute);
    } else {
      performCompute();
    }
  }, [performCompute, onPreview]);

  const resultsNode = useMemo(() => {
    if (computePlanIsPending) {
      return (
        <div className={styles.loadingWrapper}>
          <RefreshLine className={styles.loadingIcon} width={18} height={18} spin />
          <div className={styles.loadingLabel}>
            {t('loading')}
          </div>
        </div>
      );
    }

    if (runAtLeastOnce) {
      return (
        <CreatePlanFlowCommissionsResults plan={plan} />
      );
    }

    return null;
  }, [plan, runAtLeastOnce, computePlanIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="primaryBlue"
        onClick={handlePreview}
        disabled={computePlanIsPending}
      >
        {t('createPlanFlowPreviewCommissions.testBtn.label', { period: getPlanPeriodName(plan, planFirstPeriod) })}
      </Button>
      {resultsNode}
    </div>
  );
};

CreatePlanFlowPreviewCommissions.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  onPreview: PropTypes.func,
};

CreatePlanFlowPreviewCommissions.defaultProps = {
  className: '',
  onPreview: null,
};

export default CreatePlanFlowPreviewCommissions;
