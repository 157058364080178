import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import ExternalLink from '@palette/components/designSystem/ExternalLink/ExternalLink';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { applyPlanTimezoneToCommissionDate, getDealName } from '@palette/helpers/CommissionHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';
import { RIGHTS } from '@palette/constants/profile';

import * as CommissionModel from '@palette/models/Commission';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './CommissionDetails.less';

const classNames = bindClassNames.bind(styles);

const CommissionDetails = ({ className, commission, viewOnly }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: commission.scopeParams?.plan?.trackingObject?.connectorId || '' }));

  const dealName = useMemo(() => {
    if (commission !== null && connector !== null) {
      return getDealName(commission, connector);
    }

    return '';
  }, [commission, connector]);

  const planPeriodPath = hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]) ? routePaths.v2.planPeriodDetails : routePaths.v2.myPlanPeriodDetails;
  const planPeriodParams = {
    masterPlanId: commission.scopeParams?.plan?.id,
    year: commission.scopeParams?.period?.year,
    periodId: commission.scopeParams?.period?.period,
  };

  let planNameNode = (
    <Link path={planPeriodPath} params={planPeriodParams} displayIcon>
      {commission.scopeParams?.plan?.name}
    </Link>
  );

  if (viewOnly) {
    planNameNode = (
      <span>
        {commission.scopeParams?.plan?.name}
      </span>
    );
  }

  const nameNode = (
    <div className={styles.detail}>
      <div className={styles.label}>
        {t('commissionDetails.name')}
      </div>
      <div className={styles.value}>
        <span>
          {dealName}
        </span>
        <div className={styles.actions}>
          {
            hasAtLeastOneRight(profile, [RIGHTS.ADMIN.OBJECTS.DISPLAY]) && !viewOnly && (
              <Link
                path={routePaths.v2.resourceDetails}
                params={{
                  connectorId: commission.scopeParams?.plan?.trackingObject?.connectorId,
                  type: commission.scopeParams?.plan?.trackingObject?.originalType,
                  resourceId: commission.scopeParams?.resourceObject?.id,
                }}
                displayIcon
              >
                {t('commissionDetails.viewRawObject')}
              </Link>
            )
          }
          {commission.scopeParams?.resourceObject?.crmLink
             && (
               <ExternalLink
                 href={commission.scopeParams?.resourceObject?.crmLink}
                 displayIcon
               >
                 {t('resource.openIn', {
                   connectorName: commission.scopeParams?.plan?.trackingObject?.connector?.name,
                 })}
               </ExternalLink>
             )}
        </div>
      </div>
    </div>
  );

  const dateNode = (
    <div className={styles.detail}>
      <div className={styles.label}>
        {t('commissionDetails.date')}
      </div>
      <div className={styles.value}>
        {applyPlanTimezoneToCommissionDate(commission).format('LL')}
      </div>
    </div>
  );

  const planNode = (
    <div className={styles.detail}>
      <div className={styles.label}>
        {t('commissionDetails.plan')}
      </div>
      <div className={styles.value}>
        {planNameNode}
        <div className={styles.complement}>
          {getPlanPeriodName(commission.scopeParams?.plan, commission.scopeParams?.period)}
        </div>
      </div>
    </div>
  );

  const payeeNode = (
    <div className={styles.detail}>
      <div className={styles.label}>
        {t('commissionDetails.payee')}
      </div>
      <div className={styles.value}>
        <UserProfile user={commission.payee} clickable={!viewOnly} />
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {nameNode}
      {dateNode}
      {planNode}
      {payeeNode}
    </div>
  );
};

CommissionDetails.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  viewOnly: PropTypes.bool,
};

CommissionDetails.defaultProps = {
  className: '',
  viewOnly: false,
};

export default CommissionDetails;
