import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Tag from '@palette/components/designSystem/Tag/Tag';
import TeamMembersListItemMoreOptionsMenu from '@palette/components/team/TeamMembersListItemMoreOptionsMenu/TeamMembersListItemMoreOptionsMenu';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TEAM_USER_TYPES } from '@palette/constants/team';
import { TAG_THEMES } from '@palette/constants/tag';

import * as TeamUserModel from '@palette/models/TeamUser';

import styles from './TeamMembersListItem.less';

const classNames = bindClassNames.bind(styles);

const TeamMembersListItem = ({ className, member }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const profileNode = useMemo(() => (
    <UserProfile
      clickable
      user={member.user}
    />
  ), [member]);

  const teamRoleNode = useMemo(() => {
    if (member.type === TEAM_USER_TYPES.CONTRIBUTOR) return null;

    return (
      <Tag
        className={styles.teamLeadTag}
        theme={TAG_THEMES.INFO}
        label={t('teamMembersListItem.teamLead')}
      />
    );
  }, [member]);

  const [moreMenuIsOpen, setMoreMenuIsOpen] = useState(false);

  const handleMoreMenuOpenChange = useCallback((isOpen) => {
    setMoreMenuIsOpen(isOpen);
  }, [setMoreMenuIsOpen]);

  const moreOptionsMenuNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.MEMBERS.MANAGE])) return null;

    return (
      <div
        className={classNames({
          moreMenuWrapper: true,
          moreMenuIsOpen,
        })}
      >
        <TeamMembersListItemMoreOptionsMenu
          className={styles.moreMenu}
          member={member}
          onOpenChange={handleMoreMenuOpenChange}
        />
      </div>
    );
  }, [profile, moreMenuIsOpen, member, handleMoreMenuOpenChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {profileNode}
      <div className={styles.roleAndMoreMenu}>
        {teamRoleNode}
        {moreOptionsMenuNode}
      </div>
    </div>
  );
};

TeamMembersListItem.propTypes = {
  className: PropTypes.string,
  member: TeamUserModel.propTypes.isRequired,
};

TeamMembersListItem.defaultProps = {
  className: '',
};

export default TeamMembersListItem;
