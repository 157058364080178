import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { getUseDrop } from '@palette/hooks/FolderHooks';

import styles from './FolderContent.less';

const classNames = bindClassNames.bind(styles);

const FolderContent = ({
  className,
  children,
  fromRoot,
  onMoveFolder,
  onMoveEntity,
}) => {
  const isEmpty = children[0]?.props.folder.children.length === 0
               && children[0]?.props.folder.resources.length === 0;

  if (isEmpty) return null;

  /* DnD management */
  const [dropCollected, drop] = getUseDrop(onMoveFolder, onMoveEntity);

  /* Component management */
  if (fromRoot) {
    return (
      <div
        ref={drop}
        className={classNames({
          wrapper: true,
          contentFromRoot: fromRoot,
          draggableIsOver: dropCollected.isOverCurrent,
          [className]: className !== '',
        })}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {children}
    </div>
  );
};

FolderContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  fromRoot: PropTypes.bool,
  onMoveFolder: PropTypes.func,
  onMoveEntity: PropTypes.func,
};

FolderContent.defaultProps = {
  className: '',
  fromRoot: false,
  onMoveFolder: null,
  onMoveEntity: null,
};

export default FolderContent;
