import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import HighlightWidgetBase from '@palette/components/widgets/highlight/HighlightWidgetBase/HighlightWidgetBase';
import PlanTotalValueWidgetContent from '@palette/components/widgetContents/PlanTotalValueWidgetContent/PlanTotalValueWidgetContent';

import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';

import * as HighlightComponentDataModel from '@palette/models/HighlightComponentData';
import * as PlanTotalValueDataModel from '@palette/models/widgets/dashboard/PlanTotalValueData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';

import styles from './HighlightTotalValueWidget.less';

const classNames = bindClassNames.bind(styles);

const HighlightTotalValueWidget = ({ className, widgetData }) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planTotalValueData = PlanTotalValueDataModel.transform(widgetData.data);
  const valueDefinition = getPlanValueDefinition(planPeriodData.plan);

  let title = t('planTotalValueWidget.title.empty');

  if (valueDefinition !== '') {
    title = t('planTotalValueWidget.title', { valueDefinition });
  }

  return (
    <HighlightWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={title}
    >
      <PlanTotalValueWidgetContent
        className={styles.content}
        planTotalValueData={planTotalValueData}
      />
    </HighlightWidgetBase>
  );
};

HighlightTotalValueWidget.propTypes = {
  className: PropTypes.string,
  widgetData: HighlightComponentDataModel.propTypes.isRequired,
};

HighlightTotalValueWidget.defaultProps = {
  className: '',
};

export default HighlightTotalValueWidget;
