import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PaletteLogo from '@palette/components/utils/PaletteLogo/PaletteLogo';
import ForgotPasswordForm from '@palette/components/forgotPassword/ForgotPasswordForm/ForgotPasswordForm';
import Link from '@palette/components/designSystem/Link/Link';

import routePaths from '@palette/config/routePaths';

import styles from './ForgotPasswordPageContent.less';

const classNames = bindClassNames.bind(styles);

const ForgotPasswordPageContent = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <PaletteLogo width={177} height={60} />
      <h1>
        {t('forgotPasswordPageContent.title')}
      </h1>
      <ForgotPasswordForm className={styles.forgotPasswordForm} />
      <Link path={routePaths.v2.login}>
        {t('forgotPasswordPageContent.login')}
      </Link>
    </div>
  );
};

ForgotPasswordPageContent.propTypes = {
  className: PropTypes.string,
};

ForgotPasswordPageContent.defaultProps = {
  className: '',
};

export default ForgotPasswordPageContent;
