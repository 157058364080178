import PropTypes from 'prop-types';

import {
  manageAmountAttribute, manageBooleanAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PlanEstimationDataPoint';

export const propTypes = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  progress: PropTypes.number,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFixed: PropTypes.bool,
  isBracket: PropTypes.bool,
  currency: PropTypes.string,
});

export const transform = (planEstimationDataPoint) => {
  if (!planEstimationDataPoint) {
    return null;
  }

  return ({
    value: manageAmountAttribute(planEstimationDataPoint, 'value'),
    progress: managePureFloatAttribute(planEstimationDataPoint, 'progress'),
    amount: manageAmountAttribute(planEstimationDataPoint, 'amount'),
    isFixed: manageBooleanAttribute(planEstimationDataPoint, 'isFixed'),
    isBracket: manageBooleanAttribute(planEstimationDataPoint, 'isBracket'),
    currency: manageStringAttribute(planEstimationDataPoint, 'currency'),
  });
};

export const transformList = (planEstimationDataPoints) => planEstimationDataPoints.map((planEstimationDataPoint) => transform(planEstimationDataPoint));

export const merge = (planEstimationDataPoint1, planEstimationDataPoint2) => {
  if (!planEstimationDataPoint2) return planEstimationDataPoint1;
  if (!planEstimationDataPoint1) return planEstimationDataPoint2;

  return planEstimationDataPoint2;
};
