import PropTypes from 'prop-types';

import { manageAmountAttribute, manageDateAttribute, manageNumberAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';
import { getPeriodConstantsFromPeriodId } from '@palette/helpers/StatementHelper';

import { STATEMENT_PERIODICITY_TYPES } from '@palette/constants/StatementV3';

export const modelName = 'StatementV3ListPeriod';

export const propTypes = PropTypes.shape({
  periodId: PropTypes.string,
  periodicity: PropTypes.oneOf(Object.values(STATEMENT_PERIODICITY_TYPES)),
  period: PropTypes.number,
  year: PropTypes.number,
  amount: PropTypes.number,
  currency: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  usersCount: PropTypes.number,
  paidCount: PropTypes.number,
  pendingApprovalsCount: PropTypes.number,
  approvedCount: PropTypes.number,
  inConflictCount: PropTypes.number,
  correctionsCount: PropTypes.number,
});

export const transform = (listPeriod) => {
  if (!listPeriod) {
    return null;
  }

  const periodId = manageStringAttribute(listPeriod, 'periodId');
  const [periodicity, period, year] = getPeriodConstantsFromPeriodId(periodId);

  return (
    {
      periodId,
      periodicity,
      period,
      year,
      amount: manageAmountAttribute(listPeriod, 'amount'),
      currency: manageStringAttribute(listPeriod, 'currency'),
      fromDate: manageDateAttribute(listPeriod, 'start'),
      toDate: manageDateAttribute(listPeriod, 'end'),
      usersCount: manageNumberAttribute(listPeriod, 'usersCount'),
      paidCount: manageNumberAttribute(listPeriod, 'paidStatementsCount'),
      pendingApprovalsCount: manageNumberAttribute(listPeriod, 'pendingApprovalsCount'),
      approvedCount: manageNumberAttribute(listPeriod, 'approvedStatementsCount'),
      inConflictCount: manageNumberAttribute(listPeriod, 'conflictingStatementsCount'),
      correctionsCount: manageNumberAttribute(listPeriod, 'correctionsCount'),
    }
  );
};

export const transformList = (listPeriods) => listPeriods.map((listPeriod) => transform(listPeriod));
