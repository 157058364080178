import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';

import { Form as AntDForm } from 'antd';

import { ContainerOutlined } from '@ant-design/icons';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';
import CreatePlanFlowConnectorSelector from '@palette/components/masterPlanCreation/CreatePlanFlowConnectorSelector/CreatePlanFlowConnectorSelector';
import CreatePlanFlowResourceSelector from '@palette/components/masterPlanCreation/CreatePlanFlowResourceSelector/CreatePlanFlowResourceSelector';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import InvidualPlanIcon from '@palette/components/utils/Icons/InvidualPlanIcon';
import ForwardLine from '@palette/components/utils/Icons/ForwardLine';
import Tabs from '@palette/components/designSystem/Tabs/Tabs';

import { scrollToNextStep } from '@palette/helpers/MasterPlanHelper';

import { usePlanDealObjectSample, usePlanUserObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';
import { EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';
import { CONNECTOR_TYPES, MATCHING_FIELDS } from '@palette/constants/connector';
import { FORMULA_INPUT_TYPES, SUGGESTIONS_TYPES } from '@palette/constants/formula';
import { DRAFT_DATA_SOURCE_QS_KEY, DRAFT_DATA_SOURCE_TABS_IDS } from '@palette/constants/tabs';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';
import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowDataSourceStep.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowDataSourceStep = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [locationTab] = useKeyInSearch(DRAFT_DATA_SOURCE_QS_KEY);

  const updatePlanTrackingObjectDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanTrackingObjectDefinitionIsPending);
  const updatePlanUsersDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanUsersDefinitionIsPending);

  const disableFields = useMemo(() => (
    updatePlanTrackingObjectDefinitionIsPending || updatePlanUsersDefinitionIsPending
  ), [updatePlanTrackingObjectDefinitionIsPending, updatePlanUsersDefinitionIsPending]);

  const [currentTab, setCurrentTab] = useState(locationTab);

  const connectorsList = useConnectorsList();

  const [form] = AntDForm.useForm();

  const [goToNextStepCB, setGoToNextStepCB] = useState(null);

  const initialConnectorId = useMemo(() => {
    if (
      plan.trackingObject?.originalType === EMPTY_DEFAULT_VALUE
      || plan.trackingObject?.connectorId === ''
      || plan.trackingObject?.originalType === ''
    ) return null;

    return plan.trackingObject?.connectorId;
  }, [plan]);

  const initialConnectorResourceType = useMemo(() => {
    if (
      plan.trackingObject?.originalType === EMPTY_DEFAULT_VALUE
      || plan.trackingObject?.connectorId === ''
      || plan.trackingObject?.originalType === ''
    ) return null;

    return plan.trackingObject?.originalType;
  }, [plan]);

  const initialMatchingField = useMemo(() => (plan.trackingObject?.matchingField), [plan]);
  const initialUsersDefinitionMatchingField = useMemo(() => (plan.usersDefinition?.matchingField), [plan]);

  const [connectorIdValue, setConnectorIdValue] = useState(initialConnectorId);
  const [connectorResourceTypeValue, setConnectorResourceTypeValue] = useState(initialConnectorResourceType);

  const initialValues = useMemo(() => ({
    connectorId: initialConnectorId,
    type: initialConnectorResourceType,
    matchingField: initialMatchingField,
    usersDefinitionMatchingField: initialUsersDefinitionMatchingField,
  }), [
    initialConnectorId,
    initialConnectorResourceType,
    initialMatchingField,
    initialUsersDefinitionMatchingField,
  ]);

  useEffect(() => {
    setConnectorIdValue(initialValues.connectorId);
    setConnectorResourceTypeValue(initialValues.type);
  }, [initialValues]);

  const connectorIdTypeValue = useMemo(() => {
    if (!connectorIdValue || !connectorResourceTypeValue) return null;

    return {
      connectorId: connectorIdValue,
      type: connectorResourceTypeValue,
    };
  }, [connectorIdValue, connectorResourceTypeValue]);

  const dealObjectSample = usePlanDealObjectSample(plan, connectorIdTypeValue);
  const formulaHelperData = useAdditionalProperties(connectorIdTypeValue?.type, dealObjectSample);

  const connector = useMemo(() => (
    connectorsList.find((connectorItem) => (connectorItem.id === connectorIdValue))
  ), [connectorsList, connectorIdValue]);

  useEffect(() => {
    if (connector?.type === CONNECTOR_TYPES.NOTEBOOK) {
      setCurrentTab(DRAFT_DATA_SOURCE_TABS_IDS.NOTEBOOKS);
    } else if (!currentTab && !locationTab) {
      setCurrentTab(DRAFT_DATA_SOURCE_TABS_IDS.CONNECTORS);
    } else if (locationTab && (locationTab !== currentTab)) {
      setCurrentTab(locationTab);
    }
  }, [currentTab, locationTab, connector]);

  const usersDefinitionConnectorIdTypeValue = useMemo(() => ({
    connectorId: plan.usersDefinition?.connectorId,
    type: plan.usersDefinition?.originalType,
  }), [plan]);

  const usersDefinitionObjectSample = usePlanUserObjectSample(plan, usersDefinitionConnectorIdTypeValue);
  const usersDefinitionFormulaHelperData = useAdditionalProperties(usersDefinitionConnectorIdTypeValue.type, usersDefinitionObjectSample);

  const usersDefinitionConnector = useMemo(() => (
    connectorsList.find((connectorItem) => (connectorItem.id === plan.usersDefinition?.connectorId))
  ), [connectorsList, plan]);

  const matchingFieldTopFields = useMemo(() => {
    if (!connector || !connectorResourceTypeValue) return [];

    return MATCHING_FIELDS[`${connector.type}_${connectorResourceTypeValue}`] || [];
  }, [connector, connectorResourceTypeValue]);

  const usersDefinitionMatchingFieldTopFields = useMemo(() => {
    if (!usersDefinitionConnector) return [];

    return MATCHING_FIELDS[`${usersDefinitionConnector.type}_${plan.usersDefinition?.originalType}`] || [];
  }, [usersDefinitionConnector, plan]);

  const handleFormValuesChange = useCallback((changedValues) => {
    if (changedValues.connectorId !== undefined && connectorIdValue !== changedValues.connectorId) {
      setConnectorIdValue(changedValues.connectorId);
      setConnectorResourceTypeValue(null);
      form.setFieldsValue({ type: null });
    }

    if (changedValues.type !== undefined) {
      setConnectorResourceTypeValue(changedValues.type);
    }
  }, [form, connectorIdValue]);

  const handleTabChange = (key) => setCurrentTab(Object.values(DRAFT_DATA_SOURCE_TABS_IDS)[key]);

  const handleFinish = useCallback((values) => {
    const updateUsersDefinitionMatchingField = () => {
      dispatch(MasterPlansActions.updatePlanUsersDefinition({
        planId: plan.id,
        matchingField: values.usersDefinitionMatchingField || values.matchingField,
        disableSuccessNotification: true,
        onSuccessCB: goToNextStepCB,
      }));
    };

    dispatch(MasterPlansActions.updatePlanTrackingObjectDefinition({
      planId: plan.id,
      connectorId: values.connectorId,
      type: values.type,
      matchingField: values.matchingField,
      disableSuccessNotification: true,
      disableRefreshPlan: true,
      onSuccessCB: updateUsersDefinitionMatchingField,
    }));
  }, [plan, goToNextStepCB]);

  const resourceFormItemNode = useMemo(() => {
    if (connectorIdValue === null || !connector) return null;

    const { name, type } = connector;

    if (type === CONNECTOR_TYPES.NOTEBOOK && currentTab !== DRAFT_DATA_SOURCE_TABS_IDS.NOTEBOOKS) return null;
    if (type !== CONNECTOR_TYPES.NOTEBOOK && currentTab !== DRAFT_DATA_SOURCE_TABS_IDS.CONNECTORS) return null;

    return (
      <FormItem
        name="type"
        label={(
          // eslint-disable-next-line react/no-danger
          <div className={styles.typeFormItemLabel} dangerouslySetInnerHTML={{ __html: t('createPlanFlowDataSourceStep.form.type.label', { connectorName: name }) }} />
        )}
        rules={[
          { required: true },
        ]}
      >
        <CreatePlanFlowResourceSelector connector={connector} disabled={disableFields} onChange={() => scrollToNextStep()} />
      </FormItem>
    );
  }, [connectorIdValue, connector, disableFields, currentTab]);

  const matchingNode = useMemo(() => {
    if (connectorIdValue === null || !connector || connectorResourceTypeValue === null) return null;

    let trackingObjectMatchingFieldFormItemIconNode = (
      <ContainerOutlined className={styles.matchingFieldFormItemIcon} />
    );
    if (plan.usersDefinition?.originalType === connectorResourceTypeValue) { // is a "User Group By User" plan
      trackingObjectMatchingFieldFormItemIconNode = (
        <InvidualPlanIcon className={styles.matchingFieldFormItemIcon} width={18} height={18} />
      );
    }

    const trackingObjectMatchingFieldFormItemNode = (
      <div className={styles.matchingFieldFormItemWrapper}>
        <div className={styles.matchingFieldFormItemLabelWrapper}>
          <div className={styles.matchingFieldFormItemLabel}>
            {trackingObjectMatchingFieldFormItemIconNode}
            <div className={styles.matchingFieldFormItemLabelContent}>
              {connectorResourceTypeValue}
            </div>
          </div>
          <div className={styles.matchingFieldFormItemConnector}>
            {connector.type}
          </div>
        </div>
        <FormItem
          className={styles.matchingFieldFormItem}
          name="matchingField"
          rules={[
            { required: true, message: t('masterPlanTrackingObjectDefinitionForm.form.matchingField.rules.required') },
          ]}
        >
          <FormulaInput
            type={FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION}
            size="big"
            helperData={formulaHelperData}
            topFields={matchingFieldTopFields}
            disabled={disableFields}
            suggestionsType={SUGGESTIONS_TYPES.DEAL}
            placeholder={`ex: ${connectorResourceTypeValue || '[Resource]'}.ownerId`}
          />
        </FormItem>
      </div>
    );

    const usersDefinitionMatchingFieldFormItemNode = (
      <div className={styles.matchingFieldFormItemWrapper}>
        <div className={styles.matchingFieldFormItemLabelWrapper}>
          <div className={styles.matchingFieldFormItemLabel}>
            <InvidualPlanIcon className={styles.matchingFieldFormItemIcon} width={18} height={18} />
            <div className={styles.matchingFieldFormItemLabelContent}>
              {plan.usersDefinition?.type}
            </div>
          </div>
          <div className={styles.matchingFieldFormItemConnector}>
            {usersDefinitionConnector.type}
          </div>
        </div>
        <FormItem
          className={styles.matchingFieldFormItem}
          name="usersDefinitionMatchingField"
          rules={[
            { required: true, message: t('masterPlanUsersDefinitionForm.form.matchingField.rules.required') },
          ]}
        >
          <FormulaInput
            type={FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION}
            size="big"
            helperData={usersDefinitionFormulaHelperData}
            topFields={usersDefinitionMatchingFieldTopFields}
            disabled={disableFields}
            suggestionsType={SUGGESTIONS_TYPES.USER}
            placeholder={`ex: ${plan.usersDefinition?.type || '[Resource]'}.id`}
          />
        </FormItem>
      </div>
    );

    /* eslint-disable react/no-danger */
    if (plan.usersDefinition?.originalType === connectorResourceTypeValue) { // is a "User Group By User" plan
      return (
        <div className={styles.matchingWrapper}>
          <div className={styles.matchingLabel} dangerouslySetInnerHTML={{ __html: t('createPlanFlowDataSourceStep.form.matchingUGByU.label', { dealType: pluralize.singular(connectorResourceTypeValue) }) }} />
          {trackingObjectMatchingFieldFormItemNode}
        </div>
      );
    }

    return (
      <div className={styles.matchingWrapper}>
        <div className={styles.matchingLabel} dangerouslySetInnerHTML={{ __html: t('createPlanFlowDataSourceStep.form.matching.label', { dealType: pluralize.singular(connectorResourceTypeValue) }) }} />
        <div className={styles.matchingArea}>
          {trackingObjectMatchingFieldFormItemNode}
          <div className={styles.matchingAreaShouldMatchWrapper}>
            <ForwardLine className={styles.matchingAreaIcon} width={20} height={20} />
            <div className={styles.matchingAreaShouldMatchLabel}>
              {t('createPlanFlowDataSourceStep.form.shouldMatch')}
            </div>
            <ForwardLine className={styles.matchingAreaIcon} width={20} height={20} />
          </div>
          {usersDefinitionMatchingFieldFormItemNode}
        </div>
      </div>
    );
    /* eslint-enable react/no-danger */
  }, [
    plan,
    connectorIdValue,
    connector,
    connectorResourceTypeValue,
    formulaHelperData,
    matchingFieldTopFields,
    usersDefinitionConnector,
    usersDefinitionFormulaHelperData,
    usersDefinitionMatchingFieldTopFields,
    disableFields,
  ]);

  const handleNextStep = useCallback((goToNextStep) => {
    setGoToNextStepCB(() => goToNextStep);

    form.submit();
  }, [form]);

  const actionsNode = useMemo(() => {
    if (connectorIdValue === null || !connector || connectorResourceTypeValue === null) return null;

    return (
      <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.DATA_SOURCE.value} onNextStep={handleNextStep} />
    );
  }, [
    plan,
    connectorIdValue,
    connector,
    connectorResourceTypeValue,
    handleNextStep,
  ]);

  const getTabPropsByType = useCallback((tabType) => ({
    title: t(`createPlanFlowDataSourceStep.form.tabs.${tabType}`),
    content: (
      <div className={styles.tabsConnectorsContainer}>
        <div className={styles.tabItemConnectors}>
          <FormItem
            className={styles.formItemWrapper}
            name="connectorId"
            label={(
              // eslint-disable-next-line react/no-danger
              <div className={styles.typeFormItemLabel} dangerouslySetInnerHTML={{ __html: t('createPlanFlowDataSourceStep.form.connectorId.label') }} />
            )}
            rules={[
              { required: true },
            ]}
          >
            <CreatePlanFlowConnectorSelector plan={plan} currentTab={tabType} disabled={disableFields} onChange={() => scrollToNextStep()} />
          </FormItem>
        </div>
        <div className={styles.tabItemResources}>
          {resourceFormItemNode}
        </div>
      </div>
    ),
    defaultActive: tabType === currentTab,
    tabKey: tabType,
  }), [plan, disableFields, resourceFormItemNode, currentTab]);

  const tabs = useMemo(() => [
    DRAFT_DATA_SOURCE_TABS_IDS.CONNECTORS,
    DRAFT_DATA_SOURCE_TABS_IDS.NOTEBOOKS,
  ].map((tabType) => getTabPropsByType(tabType)), [
    getTabPropsByType,
  ]);

  const tabsNode = useMemo(() => currentTab && (
    <Tabs
      className={styles.tabs}
      tabs={tabs}
      onChange={handleTabChange}
      qsTabKey={DRAFT_DATA_SOURCE_QS_KEY}
    />
  ), [tabs, handleTabChange, currentTab]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        {tabsNode}
        {matchingNode}
      </Form>
      {actionsNode}
    </div>
  );
};

CreatePlanFlowDataSourceStep.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowDataSourceStep.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowDataSourceStep;
