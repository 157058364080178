import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import DataConnectionConnectorsListItem from '@palette/components/planV3/DataSourcesList/DataConnectionConnectorsListItem/DataConnectionConnectorsListItem';

import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './DataConnectionConnectorsList.less';

const classNames = bindClassNames.bind(styles);

const DataConnectionConnectorsList = ({ className, connectorName, dataConnectionConnectorsList, defaultOpen, isPending }) => {
  const listNode = useMemo(() => (
    dataConnectionConnectorsList.map((dataConnectionConnector, index) => (
      <DataConnectionConnectorsListItem
        key={`item-${dataConnectionConnector.originalType}-${index}`}
        className={styles.listItem}
        dataConnectionConnector={dataConnectionConnector}
        isPending={isPending}
      />
    ))
  ), [dataConnectionConnectorsList, isPending]);

  const collapseExtraProps = useMemo(() => {
    if (!defaultOpen) return {};

    return {
      keyValue: connectorName,
      defaultActiveKey: connectorName,
    };
  }, [connectorName, defaultOpen]);

  return (
    <Collapse
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      panels={[
        {
          title: (
            <div className={styles.connectorNameWrapper}>
              {connectorName}
            </div>
          ),
          content: (
            <div className={styles.list}>
              {listNode}
            </div>
          ),
        },
      ]}
      ghost
      {...collapseExtraProps}
    />
  );
};

DataConnectionConnectorsList.propTypes = {
  className: PropTypes.string,
  connectorName: PropTypes.string.isRequired,
  dataConnectionConnectorsList: PropTypes.arrayOf(NotebookDataConnectionConnectorModel.propTypes).isRequired,
  defaultOpen: PropTypes.bool,
  isPending: PropTypes.bool,
};

DataConnectionConnectorsList.defaultProps = {
  className: '',
  defaultOpen: false,
  isPending: false,
};

export default DataConnectionConnectorsList;
