import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PlanWidgetBase from '@palette/components/widgets/dashboard/PlanWidgetBase/PlanWidgetBase';
import PlanTotalValueWidgetContent from '@palette/components/widgetContents/PlanTotalValueWidgetContent/PlanTotalValueWidgetContent';

import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as PlanTotalValueDataModel from '@palette/models/widgets/dashboard/PlanTotalValueData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';

import styles from './PlanTotalValueWidget.less';

const classNames = bindClassNames.bind(styles);

const PlanTotalValueWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planTotalValueData = PlanTotalValueDataModel.transform(widgetData.data);
  const valueDefinition = getPlanValueDefinition(planPeriodData.plan);

  let title = t('planTotalValueWidget.title.empty');

  if (valueDefinition !== '') {
    title = t('planTotalValueWidget.title', { valueDefinition });
  }

  return (
    <PlanWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      useMostRecent={widgetData.dashboardComponent.params.useMostRecent}
      plan={planPeriodData.plan}
      period={planPeriodData.period}
      title={title}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      <PlanTotalValueWidgetContent planTotalValueData={planTotalValueData} />
    </PlanWidgetBase>
  );
};

PlanTotalValueWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanTotalValueWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlanTotalValueWidget;
