import PropTypes from 'prop-types';

import { manageDateAttribute, manageFreeShapeObjectAttribute } from '@palette/helpers/ModelHelper';

import * as StatementStrategyModel from '@palette/models/StatementStrategy';

export const modelName = 'StatementStrategyHistory';

export const propTypes = PropTypes.shape({
  date: PropTypes.string,
  strategy: StatementStrategyModel.propTypes,
});

export const transform = (strategyHistory) => {
  if (!strategyHistory) {
    return null;
  }

  return (
    {
      date: manageDateAttribute(strategyHistory, 'date'),
      strategy: StatementStrategyModel.transform(manageFreeShapeObjectAttribute(strategyHistory, 'strategy')),
    }
  );
};

export const transformList = (strategiesHistory) => strategiesHistory.map((strategyHistory) => transform(strategyHistory));
