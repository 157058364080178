import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageArrayAttribute,
  manageDateAttribute,
} from '@palette/helpers/ModelHelper';

import { manageConnectorName } from '@palette/helpers/ConnectorHelper';

import * as ResourceModel from '@palette/models/Resource';
import * as CompanyModel from '@palette/models/Company';

export const modelName = 'Connector';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  resources: PropTypes.arrayOf(ResourceModel.propTypes),
  companyId: PropTypes.string,
  company: CompanyModel.propTypes,
  name: PropTypes.string,
  lastSync: PropTypes.string,
});

export const manageCompany = (connector) => CompanyModel.transform(connector.company);

export const transform = (connector) => {
  if (!connector) {
    return null;
  }

  let company = null;
  let companyId = manageStringAttribute(connector, 'company', null, true);
  if (companyId === null) {
    company = manageCompany(connector);
    companyId = company?.id || '';
  }

  const type = manageStringAttribute(connector, 'type');
  const name = manageStringAttribute(connector, 'name');

  return (
    {
      id: manageStringAttribute(connector, '_id'),
      type,
      resources: ResourceModel.transformList(manageArrayAttribute(connector, 'objects')),
      companyId,
      company,
      name: manageConnectorName({ type, name }),
      lastSync: manageDateAttribute(connector, 'lastSync'),
    }
  );
};

export const transformList = (connectors) => connectors.map((connector) => transform(connector));
