import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Alert from '@palette/components/designSystem/Alert/Alert';
import Button from '@palette/components/designSystem/Button/Button';
import MasterPlanProjectionDefinitionForm from '@palette/components/masterPlanSettings/MasterPlanProjectionDefinitionForm/MasterPlanProjectionDefinitionForm';

import { ALERT_TYPES } from '@palette/constants/alert';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsProjection.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsProjection = ({ className, planId }) => {
  const { t } = useTranslation();

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));

  const [editionMode, setEditionMode] = useState(plan?.estimationDefinition !== null && plan?.estimationDefinition?.referenceDealId !== null);
  useEffect(() => {
    setEditionMode(plan.estimationDefinition !== null && plan.estimationDefinition.referenceDealId !== null);
  }, [plan]);

  const contentNode = useMemo(() => {
    if (!editionMode) {
      return (
        <Alert
          className={styles.alert}
          type={ALERT_TYPES.INFO}
          message={t('masterPlanSettingsProjection.alert.message')}
          description={(
            <div className={styles.alertDescriptionWrapper}>
              {t('masterPlanSettingsProjection.alert.description')}
              <Button
                className={styles.alertDescriptionCTA}
                type="secondary"
                onClick={() => setEditionMode(true)}
              >
                {t('masterPlanSettingsProjection.alert.defineProjectionCTA')}
              </Button>
            </div>
          )}
        />
      );
    }

    return (
      <MasterPlanProjectionDefinitionForm plan={plan} />
    );
  }, [editionMode, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlanSettingsProjection.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

MasterPlanSettingsProjection.defaultProps = {
  className: '',
};

export default MasterPlanSettingsProjection;
