import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import NotebooksListTable from '@palette/components/notebooks/NotebooksListTable/NotebooksListTable';

import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';

import { NOTEBOOKS_TABS_IDS } from '@palette/constants/tabs';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './NotebooksTab.less';

const classNames = bindClassNames.bind(styles);

const NotebooksTab = ({ className, filter }) => {
  const dispatch = useDispatch();

  const [paginationLimit] = useLimitInSearch();
  const [paginationPage] = usePageInSearch();

  const listNotebooksIsPending = useSelector(NotebooksSelectors.listNotebooksIsPending);

  const notebooksList = useSelector((state) => NotebooksSelectors.getNotebooksList(state, { filter }));
  const notebooksListPagination = useSelector((state) => NotebooksSelectors.getNotebooksListPagination(state, { filter }));

  useEffect(() => {
    dispatch(NotebooksActions.listNotebooks({ filter, page: paginationPage, limit: paginationLimit }));
  }, [
    filter,
    paginationLimit,
    paginationPage,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Loader spinning={listNotebooksIsPending}>
        <NotebooksListTable
          notebooksList={notebooksList}
          notebooksListPagination={notebooksListPagination}
        />
      </Loader>
    </div>
  );
};

NotebooksTab.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf(Object.values(NOTEBOOKS_TABS_IDS)).isRequired,
};

NotebooksTab.defaultProps = {
  className: '',
};

export default NotebooksTab;
