export const APP_BOTTOM_ID = 'AppBottom';
export const APP_TOP_ID = 'AppTop';
export const MAIN_CONTENT_ID = 'MainContent';
export const STATEMENT_HISTORY_ID = 'StatementHistory';

export const PAGE_QS_KEY = 'page';
export const LIMIT_QS_KEY = 'limit';

export const DEFAULT_PAGE_QS_VALUE = 0;
export const DEFAULT_LIMIT_QS_VALUE = 20;

export const SIDEBAR_COLLAPSED_WIDTH = '9.8rem';
export const SIDEBAR_NOT_COLLAPSED_WIDTH = '24.8rem';

export const COMMISSIONS_UPDATE_PATH_ENTITIES = {
  COMMISSION_LIST: 'commission-list',
  PLANS: 'plans',
  STATEMENTS: 'statements',
};
