import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { NOTEBOOK_DATA_CONNECTION_TYPES } from '@palette/constants/notebooks';
import { useNextAddDataConnectionStep } from '@palette/hooks/PlanV3Hooks';
import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { getMoment } from '@palette/helpers/MomentHelper';

import { CONNECTOR_DETAILS, CONNECTOR_TYPES } from '@palette/constants/connector';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './AddDataConnectionDataSourceConnectors.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionDataSourceConnectors = ({ className, isModelsFor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const connectorsList = useConnectorsList();

  const dataConnections = useSelector(PlanV3Selectors.getDataConnections);

  const filteredConnectorsList = useMemo(() => {
    const filter = (type) => {
      if (isModelsFor) {
        return type === CONNECTOR_TYPES.NOTEBOOK;
      }
      return type !== CONNECTOR_TYPES.NOTEBOOK;
    };

    return connectorsList.filter((connector) => (filter(connector.type) && connector.resources.length > 0));
  }, [connectorsList, isModelsFor]);

  const nextAddDataConnectionStep = useNextAddDataConnectionStep();

  const handleConnectorClicked = useCallback((connectorId) => {
    dispatch(PlanV3Actions.setNewDataConnectionSource({ type: NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR, params: { connectorId } }));
    dispatch(PlanV3Actions.setAddDataConnectionCurrentFlowStep({ addDataConnectionCurrentFlowStep: nextAddDataConnectionStep }));
  }, [nextAddDataConnectionStep]);

  const connectorItemsNode = useMemo(() => (
    filteredConnectorsList.map((connector) => {
      const connectorDetails = CONNECTOR_DETAILS[connector.type] ?? CONNECTOR_DETAILS.UNKNOWN;

      let lastSyncNode = null;

      if (connector.lastSync !== null) {
        lastSyncNode = (
          <div className={styles.connectorLastSync}>
            {t('addDataConnectionDataSourceConnectors.connectorLastSync', { date: moment(connector.lastSync).format('ll - HH:mm') })}
          </div>
        );
      }

      const currentConnectorDataConnectionsResourceTypes = dataConnections
        .filter((dataConnectionConnector) => (
          dataConnectionConnector.connectorId === connector.id
        )).map((dataConnectionConnector) => (
          dataConnectionConnector.originalType
        ));

      const disabledConnector = currentConnectorDataConnectionsResourceTypes.length === connector.resources.length;

      let handleConnectorClick;

      if (!disabledConnector) {
        handleConnectorClick = () => handleConnectorClicked(connector.id);
      }

      const usedResourcesNode = (
        <div className={styles.usedResources}>
          {t('addDataConnectionDataSourceConnectors.usedResourcesNode', { total: connector.resources.length, used: currentConnectorDataConnectionsResourceTypes.length })}
        </div>
      );

      const contentNode = (
        <div
          key={connector.id}
          className={classNames({
            connectorItem: true,
            disabledConnector,
          })}
          onClick={handleConnectorClick}
        >
          <div className={styles.iconConnector}>
            {connectorDetails.iconComponent({ width: '40px', height: '40px', className: styles.iconConnectorIcon })}
          </div>
          <div className={styles.connectorDetails}>
            <div className={styles.connectorName}>
              {connector.name}
            </div>
            {lastSyncNode}
            {usedResourcesNode}
          </div>
        </div>
      );

      if (disabledConnector) {
        return (
          <Tooltip title={t('notebooks.addDataConnectionConnector.disabled')} key={`${connector.id}_tooltip`}>
            {contentNode}
          </Tooltip>
        );
      }

      return contentNode;
    })
  ), [
    filteredConnectorsList,
    handleConnectorClicked,
    dataConnections,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {connectorItemsNode}
    </div>
  );
};

AddDataConnectionDataSourceConnectors.propTypes = {
  className: PropTypes.string,
  isModelsFor: PropTypes.bool,
};

AddDataConnectionDataSourceConnectors.defaultProps = {
  className: '',
  isModelsFor: false,
};

export default AddDataConnectionDataSourceConnectors;
