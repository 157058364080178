import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { ALERT_TYPES } from '@palette/constants/alert';

import styles from './MasterPlanSettingsQuotaAttainmentNeedDataModal.less';

const MasterPlanSettingsQuotaAttainmentNeedDataModal = ({ visible, onClose }) => {
  const { t } = useTranslation();

  const cleanAndClose = () => {
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('masterPlanSettingsQuotaAttainment.needData.modal.title')}
        </div>
      )}
      okText={null}
      visible={visible}
      onCancel={cleanAndClose}
      cancelText={t('common.global.close')}
    >
      <Alert
        type={ALERT_TYPES.INFO}
        message={t('masterPlanSettingsQuotaAttainment.needData.modal.content')}
      />
    </Modal>
  );
};

MasterPlanSettingsQuotaAttainmentNeedDataModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

MasterPlanSettingsQuotaAttainmentNeedDataModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default MasterPlanSettingsQuotaAttainmentNeedDataModal;
