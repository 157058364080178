import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import RolesFilled from '@palette/components/utils/Icons/RolesFilled';
import DeleteRoleModal from '@palette/components/role/DeleteRoleModal/DeleteRoleModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as RoleModel from '@palette/models/Role';

import { actions as CompanyActions } from '@palette/state/Company';

import styles from './RolesListItemMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const RolesListItemMoreOptionsMenu = ({ className, onOpenChange, role }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const [deleteRoleModalIsVisible, showDeleteRoleModal] = useState(false);

  const closeMoreMenu = useCallback(() => {
    if (onOpenChange !== null) {
      onOpenChange(false);
    }
  }, [onOpenChange]);

  const onSuccessCB = useCallback(() => {
    closeMoreMenu();
    dispatch(CompanyActions.getRoles({ withUsersCount: true }));
  }, [closeMoreMenu]);

  const moreMenuItems = useMemo(() => {
    const menuItems = [];

    const duplicateRoleMenuItem = {
      key: 'duplicateRole',
      title: t('rolesListItemMoreOptionsMenu.duplicateRole'),
      icon: (
        <DuplicateFilled width={16} height={16} />
      ),
      onClick: () => {
        dispatch(CompanyActions.duplicateRoleById({ roleId: role.id, onSuccessCB }));
      },
    };

    menuItems.push(duplicateRoleMenuItem);

    if (!role.isDefault) {
      const makeDefaultRoleMenuItem = {
        key: 'makeDefaultRole',
        title: t('rolesListItemMoreOptionsMenu.makeDefaultRole'),
        icon: (
          <RolesFilled width={16} height={16} />
        ),
        onClick: () => {
          dispatch(CompanyActions.makeDefaultRoleById({ roleId: role.id, onSuccessCB }));
        },
      };

      menuItems.push(makeDefaultRoleMenuItem);
    }

    if (!role.isProtected && !role.isDefault) {
      const deleteRoleMenuItem = {
        key: 'deleteRole',
        title: t('rolesListItemMoreOptionsMenu.deleteRole'),
        icon: (
          <TrashFilled width={16} height={16} />
        ),
        onClick: () => {
          showDeleteRoleModal(true);
          closeMoreMenu();
        },
      };

      menuItems.push(deleteRoleMenuItem);
    }

    return menuItems;
  }, [
    role,
    showDeleteRoleModal,
    closeMoreMenu,
    onSuccessCB,
  ]);

  const moreMenuProps = useMemo(() => {
    if (onOpenChange === null) return {};

    return {
      onVisibleChange: onOpenChange,
    };
  }, [onOpenChange]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.ROLES.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={moreMenuItems}
        {...moreMenuProps}
      />
      {
        deleteRoleModalIsVisible && (
          <DeleteRoleModal visible onClose={() => showDeleteRoleModal(false)} role={role} />
        )
      }
    </div>
  );
};

RolesListItemMoreOptionsMenu.propTypes = {
  className: PropTypes.string,
  role: RoleModel.propTypes.isRequired,
  onOpenChange: PropTypes.func,
};

RolesListItemMoreOptionsMenu.defaultProps = {
  className: '',
  onOpenChange: null,
};

export default RolesListItemMoreOptionsMenu;
