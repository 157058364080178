import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CreatePlanFlowPageStepsList from '@palette/components/masterPlanCreation/CreatePlanFlowPageStepsList/CreatePlanFlowPageStepsList';
import CreatePlanFlowPageStepsManagement from '@palette/components/masterPlanCreation/CreatePlanFlowPageStepsManagement/CreatePlanFlowPageStepsManagement';
import Loader from '@palette/components/utils/Loader/Loader';

import { useDraftMasterPlan, useDraftMasterPlanStepInSearch } from '@palette/hooks/MasterPlanHooks';

import {
  getCreatePlanFlowStepFromStepIndex,
  getCreatePlanFlowStepIndex,
  getNextCreatePlanFlowStep,
} from '@palette/helpers/MasterPlanHelper';

import { CREATE_PLAN_FLOW_STEPS, DRAFT_PLAN_STEP_QS_KEY } from '@palette/constants/masterPlans';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CreatePlanFlowPageContent.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { draftPlan, draftPlanId } = useDraftMasterPlan();
  const [creationPlanStep] = useDraftMasterPlanStepInSearch();

  const getByIdIsPending = useSelector(MasterPlansSelectors.getByIdIsPending);

  const redirectToStep = (planFlowStep) => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [DRAFT_PLAN_STEP_QS_KEY]: planFlowStep.value } }));
  };

  useEffect(() => {
    if (draftPlan !== null) {
      const creationPlanStepIndex = getCreatePlanFlowStepIndex(creationPlanStep);

      // If creationPlanStep is above the plan creationFlowStep
      const planStep = draftPlan.creationFlowStep;
      let planStepIndex = 0;
      if (planStep !== null) {
        planStepIndex = getCreatePlanFlowStepIndex(planStep);
      }
      if (creationPlanStepIndex > planStepIndex) {
        redirectToStep(getCreatePlanFlowStepFromStepIndex(planStepIndex));

        return;
      }

      // If creationPlanStep is discard for the draft plan
      const stepIsDiscard = CREATE_PLAN_FLOW_STEPS[creationPlanStep].isDiscard(draftPlan);

      if (stepIsDiscard) {
        const nextCreatePlanFlowStep = getNextCreatePlanFlowStep(creationPlanStep);

        const nextStepIndex = getCreatePlanFlowStepIndex(nextCreatePlanFlowStep.value);

        const updateCreationFlowStep = planStepIndex < nextStepIndex;

        const onSuccessCB = () => {
          redirectToStep(nextCreatePlanFlowStep);
        };

        if (updateCreationFlowStep) {
          dispatch(MasterPlansActions.updatePlan({
            planId: draftPlan.id,
            creationFlowStep: nextCreatePlanFlowStep.value,
            disableSuccessNotification: true,
            onSuccessAfterUpdatesCB: onSuccessCB,
          }));
        } else {
          onSuccessCB();
        }
      }
    }
  }, [draftPlan, creationPlanStep]);

  const stepsListNode = useMemo(() => {
    if (draftPlanId !== null && draftPlan === null && getByIdIsPending) {
      return (
        <Loader />
      );
    }

    return (
      <CreatePlanFlowPageStepsList plan={draftPlan} />
    );
  }, [draftPlan, draftPlanId, getByIdIsPending]);

  const stepsManagementNode = useMemo(() => {
    if (draftPlanId !== null && draftPlan === null && getByIdIsPending) {
      return (
        <Loader />
      );
    }

    return (
      <CreatePlanFlowPageStepsManagement plan={draftPlan} />
    );
  }, [draftPlan, draftPlanId, getByIdIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.leftSide}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {t('createPlanFlowSteps.title')}
          </h2>
        </div>
        <div className={styles.steps}>
          {stepsListNode}
        </div>
      </div>
      <div className={styles.main}>
        {stepsManagementNode}
      </div>
    </div>
  );
};

CreatePlanFlowPageContent.propTypes = {
  className: PropTypes.string,
};

CreatePlanFlowPageContent.defaultProps = {
  className: '',
};

export default CreatePlanFlowPageContent;
