import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import TeamsWithSubTeamsListItem from '@palette/components/team/TeamsWithSubTeamsListItem/TeamsWithSubTeamsListItem';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import * as TeamModel from '@palette/models/Team';

import styles from './TeamsWithSubTeamsList.less';

const classNames = bindClassNames.bind(styles);

const TeamsWithSubTeamsList = ({ className, teams }) => {
  const teamsItemsNodes = useMemo(() => {
    if (teams.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      teams.map((teamWithSubTeams) => (
        <TeamsWithSubTeamsListItem
          key={teamWithSubTeams.team.id}
          className={styles.teamCard}
          team={teamWithSubTeams}
        />
      ))
    );
  }, [teams]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {teamsItemsNodes}
    </div>
  );
};

TeamsWithSubTeamsList.propTypes = {
  className: PropTypes.string,
  teams: PropTypes.arrayOf(TeamModel.withSubTeamsPropTypes).isRequired,
};

TeamsWithSubTeamsList.defaultProps = {
  className: '',
};

export default TeamsWithSubTeamsList;
