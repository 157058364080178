import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';
import PeriodDealCommissionInfoComments from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfoComments/PeriodDealCommissionInfoComments';
import PeriodDealCommissionInfoPayments from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfoPayments/PeriodDealCommissionInfoPayments';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import CommissionValue from '@palette/components/commission/CommissionValue/CommissionValue';
import FunctionLine from '@palette/components/utils/Icons/FunctionLine';

import { getMoment } from '@palette/helpers/MomentHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getPlanPeriodName, yearPeriodStringToJSON } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';

import { COMMISSION_DRAWER_COMMISION_ID_QS_KEY } from '@palette/constants/commissions';
import { COMMISSION_DRAWER_QS_KEY, COMMISSION_DRAWER_TABS_IDS } from '@palette/constants/tabs';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './ListDealCommissionCollapse.less';

const classNames = bindClassNames.bind(styles);

const ListDealCommissionCollapse = ({
  className,
  plan,
  periods,
  totalAmount,
  totalComments,
  currency,
  isOpened,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();

  const openCommissionDrawer = (tabKey, commissionId) => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [COMMISSION_DRAWER_COMMISION_ID_QS_KEY]: commissionId, [COMMISSION_DRAWER_QS_KEY]: tabKey } }));
  };

  const titleNode = useMemo(() => (
    <div className={styles.title}>
      {plan.name}
    </div>
  ), [plan]);

  const planLink = useMemo(() => {
    let linkNode = (
      <Link
        path={routePaths.v2.planDetails}
        params={{ masterPlanId: plan.id }}
        displayIcon
        onClick={(event) => event.stopPropagation()}
      >
        {t('commissions.seePlanDetails')}
      </Link>
    );

    if (plan.periodType === PERIOD_TYPES.CUSTOM) {
      linkNode = (
        <Link
          path={routePaths.v2.planPeriodDetails}
          params={{ masterPlanId: plan.id, year: moment(plan.customBeginAt).utc().year(), periodId: 1 }}
          displayIcon
        >
          {t('commissions.seePlanDetails')}
        </Link>
      );
    }

    return linkNode;
  }, [plan]);

  const extraNode = useMemo(() => (
    <div className={styles.extraDetails}>
      <div className={styles.colRow}>
        {planLink}
        <MasterPlanScopeTags plan={plan} className={styles.tags} />
      </div>
      <div className={styles.colRow}>
        <div className={styles.comments}>
          {t('commissionsCommentsWithCount', { count: totalComments })}
        </div>
        <div className={styles.amount}>
          {formatPrice(totalAmount, currency)}
        </div>
      </div>
    </div>
  ), [plan, totalComments, totalAmount, currency]);

  const contentNode = useMemo(() => Object.keys(periods).map((period) => {
    const periodJSON = yearPeriodStringToJSON(period);
    const periodName = getPlanPeriodName(plan, periodJSON);
    const commissions = periods[period];

    const valueNode = [];

    commissions.map((commission) => valueNode.push((
      <div className={styles.commission} key={commission.id}>
        <div className={styles.amoutValues}>
          <CommissionValue
            className={styles.commissionValue}
            commission={commission}
          />
        </div>
        <div className={styles.links}>
          <UserProfile
            className={styles.user}
            user={commission.payee}
            clickable
          />
          <PeriodDealCommissionInfoPayments
            className={styles.detailItem}
            payments={commission.payments}
            commissionId={commission.id}
          />
          <div
            className={classNames({
              rules: true,
              detailItem: true,
            })}
            onClick={() => openCommissionDrawer(COMMISSION_DRAWER_TABS_IDS.CALCULATIONS, commission.id)}
          >
            <FunctionLine className={styles.icon} />
            {t('commissions.rules')}
          </div>
          <PeriodDealCommissionInfoComments
            className={styles.detailItem}
            commentsCount={commission.commentsCount}
            commissionId={commission.id}
          />
        </div>
      </div>
    )));

    return (
      <div className={styles.period} key={period}>
        <div className={styles.periodNameContainer}>
          <div className={styles.periodName}>{periodName}</div>
          <Link
            path={routePaths.v2.planPeriodDetails}
            params={{ masterPlanId: plan.id, year: periodJSON.year, periodId: periodJSON.period }}
            displayIcon
          >
            {t('commissions.seePeriodDetails')}
          </Link>
        </div>
        <div className={styles.commissions}>{valueNode}</div>
      </div>
    );
  }), [plan, periods]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapseContainer}
        panels={[
          {
            title: titleNode,
            content: contentNode,
            extra: extraNode,
          },
        ]}
        keyValue={plan.id}
        defaultActiveKey={isOpened ? plan.id : undefined}
      />
    </div>
  );
};

ListDealCommissionCollapse.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  periods: PropTypes.object.isRequired,
  totalAmount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  isOpened: PropTypes.bool,
};

ListDealCommissionCollapse.defaultProps = {
  className: '',
  isOpened: false,
};

export default ListDealCommissionCollapse;
