import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';

import { HIGHLIGHT_WIDGETS_COMPONENTS } from '@palette/constants/widgets';

import * as HighlightComponentDataModel from '@palette/models/HighlightComponentData';

import styles from './HighlightZone.less';

const classNames = bindClassNames.bind(styles);

const HighlightZone = ({ className, loading, data, forIC, actionsNode }) => {
  const loadingNode = useMemo(() => {
    if (!loading) return null;

    return (
      <LoadingLine className={styles.loadingIcon} width={20} height={20} spin />
    );
  }, [loading]);

  const widgetsNode = useMemo(() => {
    if (data === null || data.length === 0) {
      return (
        <DefaultEmptyState className={styles.emptyState} />
      );
    }

    const widgetsNodes = data.map((widgetData, index) => {
      const WidgetComponent = HIGHLIGHT_WIDGETS_COMPONENTS[widgetData.type] || null;

      if (WidgetComponent === null) return null;

      return (
        <WidgetComponent
          key={`widget_${index}`}
          className={styles.widget}
          widgetData={widgetData}
          forIC={forIC}
        />
      );
    });

    return (
      <div className={styles.widgets}>
        {widgetsNodes}
      </div>
    );
  }, [data, forIC]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {actionsNode}
      {loadingNode}
      {widgetsNode}
    </div>
  );
};

HighlightZone.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(HighlightComponentDataModel.propTypes),
  forIC: PropTypes.bool,
  actionsNode: PropTypes.node,
};

HighlightZone.defaultProps = {
  className: '',
  loading: false,
  data: null,
  forIC: false,
  actionsNode: null,
};

export default HighlightZone;
