import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import AddDataConnectionDataSourceConnectors from '@palette/components/notebooks/AddDataConnectionDataSourceConnectors/AddDataConnectionDataSourceConnectors';

import {
  ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_QS_KEY,
  ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_IDS,
} from '@palette/constants/tabs';

import { actions as NotebooksActions } from '@palette/state/Notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './AddDataConnectionSelectDataSourceStep.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionSelectDataSourceStep = ({ className, notebook }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(NotebooksActions.resetNewDataConnectionInfo());
  }, []);

  const tabs = useMemo(() => {
    const finalTabs = [];

    finalTabs.push({
      tabKey: ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_IDS.CONNECTORS,
      title: t('addDataConnectionSelectDataSourceStep.tabs.connectors'),
      content: (<AddDataConnectionDataSourceConnectors notebook={notebook} />),
      defaultActive: true,
    });

    return finalTabs;
  }, [notebook]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        tabs={tabs}
        qsTabKey={ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_QS_KEY}
      />
    </div>
  );
};

AddDataConnectionSelectDataSourceStep.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
};

AddDataConnectionSelectDataSourceStep.defaultProps = {
  className: '',
};

export default AddDataConnectionSelectDataSourceStep;
