import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import PlanV3List from '@palette/components/planV3/PlanV3List/PlanV3List';
import Input from '@palette/components/designSystem/Input/Input';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import Button from '@palette/components/designSystem/Button/Button';
import CreateEditFolderModal from '@palette/components/folders/CreateEditFolderModal/CreateEditFolderModal';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { PLANS_V3_EVENTS } from '@palette/constants/analytics';
import { FOLDER_TYPES } from '@palette/constants/folders';
import { RIGHTS } from '@palette/constants/profile';
import { PLANS_V3_PAGE_CONTENT_TABS_IDS, PLANS_V3_PAGE_CONTENT_QS_KEY } from '@palette/constants/tabs';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';
import { actions as FoldersActions } from '@palette/state/Folders';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './PlanV3PageContent.less';

const classNames = bindClassNames.bind(styles);

const PlanV3PageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();
  const [locationTab] = useKeyInSearch(PLANS_V3_PAGE_CONTENT_QS_KEY);

  const [searchedPlan, setSearchedPlan] = useState('');
  const [createEditFolderModalIsVisible, showCreateEditFolderModal] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [listLoaded, setListLoaded] = useState(false);

  const plansList = useSelector((state) => PlanV3Selectors.getList(state));

  const handleGetListSuccessCB = useCallback(() => {
    setListLoaded(true);
  }, [currentTab]);

  useEffect(() => {
    if (!currentTab && !locationTab) {
      setCurrentTab(PLANS_V3_PAGE_CONTENT_TABS_IDS.ACTIVE);
    } else if (locationTab && (locationTab !== currentTab)) {
      setCurrentTab(locationTab);
    }

    if ((currentTab === locationTab) || (!locationTab && currentTab === PLANS_V3_PAGE_CONTENT_TABS_IDS.ACTIVE)) {
      if (!listLoaded) {
        dispatch(PlanV3Actions.getList({ onSuccessCB: handleGetListSuccessCB }));

        if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.VIEW_ALL, RIGHTS.ADMIN.TEAMS.VIEW_SCOPED])) {
          dispatch(FoldersActions.getList({ type: FOLDER_TYPES.PLAN }));
        }
      }
    }
  }, [currentTab, locationTab]);

  const getTabPropsByType = useCallback((type) => ({
    title: t(`masterPlansPageContent.tabs.${type}`),
    titleCount: plansList.length || 0,
    content: (
      <PlanV3List listType={type} searchedPlan={searchedPlan} />
    ),
    defaultActive: type === PLANS_V3_PAGE_CONTENT_TABS_IDS.ACTIVE,
    tabKey: type,
  }), [plansList, searchedPlan]);

  const tabs = useMemo(() => [PLANS_V3_PAGE_CONTENT_TABS_IDS.ACTIVE].map((type) => getTabPropsByType(type)), [getTabPropsByType]);

  const handleTabChange = (key) => setCurrentTab(Object.values(PLANS_V3_PAGE_CONTENT_TABS_IDS)[key]);

  const handleCreatePlanClick = () => {
    dispatch(AnalyticsActions.sendEvent({ event: PLANS_V3_EVENTS.PLAN_LIST.CLICK_CREATE_PLAN }));
    redirectTo({ path: routePaths.v2.planV3Create });
  };

  const actionsNodes = [];

  const toolboxNode = useMemo(() => (
    <div
      className={classNames({
        toolbox: true,
      })}
    >
      <Input
        className={styles.search}
        type="search"
        placeholder={t('masterPlansPageContent.planSearch.placeholder')}
        onChange={setSearchedPlan}
        value={searchedPlan}
      />
      <div className={styles.actionsWrapper}>
        {actionsNodes}
      </div>
    </div>
  ), [setSearchedPlan, searchedPlan, actionsNodes]);

  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE])) {
    actionsNodes.unshift((
      <Button
        className={styles.actionBtn}
        key="createPlanFlow"
        type="primaryBlue"
        icon={<AddFilled />}
        onClick={handleCreatePlanClick}
      >
        {t('plansV3.createNewPlan.btnLabel')}
      </Button>
    ));

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])) {
      actionsNodes.push((
        <Button
          className={styles.actionBtn}
          key="createNewFolder"
          type="secondary"
          icon={<AddFilled />}
          onClick={() => showCreateEditFolderModal(true)}
        >
          {t('plansV3.createNewFolder.btnLabel')}
        </Button>
      ));
    }
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <DndProvider backend={HTML5Backend}>
        <Tabs
          className={styles.tabs}
          tabs={tabs}
          onChange={handleTabChange}
          qsTabKey={PLANS_V3_PAGE_CONTENT_QS_KEY}
          tabBarExtraContent={toolboxNode}
        />
      </DndProvider>
      {
        createEditFolderModalIsVisible
        && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])
        && (
          <CreateEditFolderModal
            visible
            onClose={() => showCreateEditFolderModal(false)}
          />
        )
      }
    </div>
  );
};

PlanV3PageContent.propTypes = {
  className: PropTypes.string,
};

PlanV3PageContent.defaultProps = {
  className: '',
};

export default PlanV3PageContent;
