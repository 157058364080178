import React, { useMemo } from 'react';

import AddDataConnectionSelectDataSourceStep from '@palette/components/notebooks/AddDataConnectionSelectDataSourceStep/AddDataConnectionSelectDataSourceStep';
import AddDataConnectionSelectDataStepManagement from '@palette/components/notebooks/AddDataConnectionSelectDataStepManagement/AddDataConnectionSelectDataStepManagement';

import { useAddDataConnectionStepValue } from '@palette/hooks/NotebookHooks';

import { ADD_DATA_CONNECTION_FLOW_STEPS } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';

const AddDataConnectionFlowPageStepsManagement = ({ notebook }) => {
  const addDataConnectionFlowStepsNodes = useMemo(() => ({
    [ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA_SOURCE.value]: () => (
      <AddDataConnectionSelectDataSourceStep notebook={notebook} />
    ),
    [ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA.value]: () => (
      <AddDataConnectionSelectDataStepManagement />
    ),
  }), []);

  const addDataConnectionStepValue = useAddDataConnectionStepValue();

  const stepNode = useMemo(() => {
    switch (addDataConnectionStepValue) {
      case ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA_SOURCE.value: {
        return addDataConnectionFlowStepsNodes[ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA_SOURCE.value]();
      }
      case ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA.value: {
        return addDataConnectionFlowStepsNodes[ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA.value]();
      }
      default:
        return null;
    }
  }, [addDataConnectionStepValue, addDataConnectionFlowStepsNodes]);

  return stepNode;
};

AddDataConnectionFlowPageStepsManagement.propTypes = {
  notebook: NotebookModel.propTypes.isRequired,
};

export default AddDataConnectionFlowPageStepsManagement;
