import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import StatementV3Breadcrumb from '@palette/components/statementV3/StatementV3Breadcrumb/StatementV3Breadcrumb';
import StatementV3UsersStatements from '@palette/components/statementV3/StatementV3UsersStatements/StatementV3UsersStatements';
import StatementV3PeriodsNavigation from '@palette/components/statementV3/StatementV3PeriodsNavigation/StatementV3PeriodsNavigation';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useCurrencyInSearch } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import styles from './StatementV3PeriodPage.less';

const StatementV3PeriodPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();
  const { statementPeriodId } = useParams();

  const periodDetails = useSelector((state) => StatementV3Selectors.getPeriodDetails(state));

  const [currency, handleCurrencyChange] = useCurrencyInSearch(profile.userData.company.currencies.length < 2 ? profile.userData.company.currency : null);

  useEffect(() => {
    dispatch(StatementV3Actions.getPeriodDetails({ periodId: statementPeriodId, currency }));
  }, [currency, statementPeriodId]);

  if (!periodDetails || !(hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]))) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.statementsPeriodTitle')} />
      <StatementV3Breadcrumb className={styles.breadcrumb} period={periodDetails} />
      <div className={styles.titleWithCurrency}>
        <PageTitle className={styles.title}>
          <h2>{t('statementsPeriod.page.title')}</h2>
        </PageTitle>
        <CompanyCurrencySelect
          enableMixedCurrencyOption
          defaultMixedCurrencyOptionValue={null}
          value={currency}
          onChange={handleCurrencyChange}
          disabled={profile.userData.company.currencies.length < 2}
        />
      </div>
      <div className={styles.container}>
        <StatementV3PeriodsNavigation className={styles.navigation} currency={profile.userData.company.currency} />
        <StatementV3UsersStatements statementPeriod={periodDetails} currency={currency} />
      </div>
    </div>
  );
};

export default StatementV3PeriodPage;
