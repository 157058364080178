import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import AvatarGroup from '@palette/components/user/AvatarGroup/AvatarGroup';
import Link from '@palette/components/designSystem/Link/Link';

import { getTeamMembersMetaUsers } from '@palette/helpers/TeamHelper';

import routePaths from '@palette/config/routePaths';

import * as TeamModel from '@palette/models/Team';

import styles from './TeamsListItemForUser.less';

const classNames = bindClassNames.bind(styles);

const TeamsListItemForUser = ({ className, team }) => {
  const nameNode = useMemo(() => (
    <Link
      className={styles.name}
      maxWidth="20"
      displayIcon
      path={routePaths.v2.teamDetails}
      params={{ teamId: team.id }}
    >
      {team.name}
    </Link>
  ), [team]);

  const membersNode = useMemo(() => {
    const membersMetaUsers = getTeamMembersMetaUsers(team);

    return (
      <div className={styles.members}>
        <AvatarGroup
          users={membersMetaUsers}
          nbToDisplay={5}
          totalUsers={team.members.length}
        />
      </div>
    );
  }, [team]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {nameNode}
      {membersNode}
    </div>
  );
};

TeamsListItemForUser.propTypes = {
  className: PropTypes.string,
  team: TeamModel.propTypes.isRequired,
};

TeamsListItemForUser.defaultProps = {
  className: '',
};

export default TeamsListItemForUser;
