import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { AutoComplete as AntDAutoComplete } from 'antd';

import styles from './AutoComplete.less';

const classNames = bindClassNames.bind(styles);

const AutoComplete = ({
  className,
  dropdownClassName,
  options,
  placeholder,
  disabled,
  size,
  ...otherProps
}) => (
  <AntDAutoComplete
    className={classNames({
      wrapper: true,
      big: size === 'big',
      [className]: className !== '',
    })}
    dropdownClassName={classNames({
      dropdown: true,
      [dropdownClassName]: dropdownClassName !== '',
    })}
    dropdownMatchSelectWidth={false}
    options={options}
    placeholder={placeholder}
    disabled={disabled}
    {...otherProps}
  />
);

AutoComplete.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.any,
  })).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'big']),
};

AutoComplete.defaultProps = {
  className: '',
  dropdownClassName: '',
  placeholder: '',
  disabled: false,
  size: 'default',
};

export default AutoComplete;
