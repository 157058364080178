import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Disclaimer from '@palette/components/designSystem/Disclaimer/Disclaimer';
import Button from '@palette/components/designSystem/Button/Button';
import MasterPlanSettingsQuotaSetup from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaSetup/MasterPlanSettingsQuotaSetup';
import MasterPlanSettingsQuotaAttainment from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaAttainment/MasterPlanSettingsQuotaAttainment';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './MasterPlanSettingsQuota.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsQuota = ({ className, planId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId: plan.quotaId }));
  const quotaGetByIdIsPending = useSelector(QuotasSelectors.getByIdIsPending);

  const [editionMode, setEditionMode] = useState(plan.quotaId !== null);

  useEffect(() => {
    if (plan.quotaId !== null) {
      dispatch(QuotasActions.getById({ quotaId: plan.quotaId }));
      setEditionMode(false);
    }
  }, [plan]);

  const quotaSetupNode = useMemo(() => {
    if (quota === null && !quotaGetByIdIsPending && !editionMode) {
      const disclaimerDescriptionNode = (
        <Button
          type="secondary"
          onClick={() => setEditionMode(true)}
        >
          {t('masterPlanSettingsQuota.disclaimer.noQuota.defineQuotaCTA')}
        </Button>
      );

      return (
        <Disclaimer
          title={t('masterPlanSettingsQuota.disclaimer.noQuota.title')}
          description={t('masterPlanSettingsQuota.disclaimer.noQuota.description')}
          extra={disclaimerDescriptionNode}
        />
      );
    }

    return (
      <MasterPlanSettingsQuotaSetup plan={plan} quota={quota} />
    );
  }, [plan, quota, quotaGetByIdIsPending, editionMode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {quotaSetupNode}
      <MasterPlanSettingsQuotaAttainment className={styles.quotaAttainment} plan={plan} />
    </div>
  );
};

MasterPlanSettingsQuota.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

MasterPlanSettingsQuota.defaultProps = {
  className: '',
};

export default MasterPlanSettingsQuota;
