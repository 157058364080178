import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PaymentsSummary from '@palette/components/commission/PaymentsSummary/PaymentsSummary';
import PaymentsList from '@palette/components/commission/PaymentsList/PaymentsList';
import Alert from '@palette/components/designSystem/Alert/Alert';
import PaymentsEditPayoutSchedule from '@palette/components/commission/PaymentsEditPayoutSchedule/PaymentsEditPayoutSchedule';

import { getCommissionAmount } from '@palette/helpers/CommissionHelper';

import { ALERT_TYPES } from '@palette/constants/alert';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionDrawerPaymentsTab.less';

const classNames = bindClassNames.bind(styles);

const CommissionDrawerPaymentsTab = ({ className, commission, viewOnly }) => {
  const { t } = useTranslation();

  const commissionAmount = getCommissionAmount(commission);

  let contentNode = (
    <Alert
      type={ALERT_TYPES.WARNING}
      message={t('commissionDrawerPaymentsTab.noScheduledPayments')}
    />
  );

  if (commissionAmount === 0) {
    contentNode = (
      <Alert
        type={ALERT_TYPES.INFO}
        message={t('commissionDrawerPaymentsTab.noCommissionToPay')}
      />
    );
  }

  if (commission.payments !== null && commission.payments.length > 0) {
    contentNode = (
      <>
        <PaymentsList commission={commission} />
        <PaymentsSummary className={styles.summary} commission={commission} />
      </>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      {
        !viewOnly && (
          <PaymentsEditPayoutSchedule className={styles.editPayoutSchedule} commission={commission} />
        )
      }
    </div>
  );
};

CommissionDrawerPaymentsTab.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  viewOnly: PropTypes.bool,
};

CommissionDrawerPaymentsTab.defaultProps = {
  className: '',
  viewOnly: false,
};

export default CommissionDrawerPaymentsTab;
