import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { NOTEBOOK_CELL_TYPES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookCellModel from '@palette/models/NotebookCell';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './UnMakeExportableCellModal.less';

const UnMakeExportableCellModal = ({ visible, onClose, notebook, cell }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const handleUnMakeExportableCell = useCallback(() => {
    const updatedCell = NotebookCellModel.updateCellStatus(cell, NOTEBOOK_CELL_TYPES[cell.typeKey].exportableStatus);
    dispatch(NotebooksActions.updateCell({ notebookId: notebook.id, updatedCell, onSuccessCB: cleanAndClose }));
  }, [notebook, cell, cleanAndClose]);

  return (
    <Modal
      className={styles.modal}
      title={t('unMakeExportableCellModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleUnMakeExportableCell}
      okText={t('unMakeExportableCellModal.confirm')}
      loading={editNotebookIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('unMakeExportableCellModal.content', { notebookName: notebook.name, cellName: cell.name }) }} />
    </Modal>
  );
};

UnMakeExportableCellModal.propTypes = {
  notebook: NotebookModel.propTypes.isRequired,
  cell: NotebookCellModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

UnMakeExportableCellModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default UnMakeExportableCellModal;
