import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';
import Link from '@palette/components/designSystem/Link/Link';
import Table from '@palette/components/designSystem/Table/Table';
import QuotaPerformanceTableCell from '@palette/components/quotaPerformance/QuotaPerformanceTableCell/QuotaPerformanceTableCell';
import RemoveDashboardPlan from '@palette/components/quotaPerformance/RemoveDashboardPlan/RemoveDashboardPlan';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getQuotaPerformancePeriodName, getQuotaPerformanceValuesByMetric } from '@palette/helpers/QuotaPerformanceHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import * as QuotaPerformancePlanModel from '@palette/models/QuotaPerformancePlan';

import styles from './QuotaPerformanceDashboardPlan.less';

const classNames = bindClassNames.bind(styles);

const QuotaPerformanceDashboardPlan = ({ className, dashboardId, plan }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const tableColumns = useMemo(() => {
    const contentColumns = [...plan.content.columns];
    const entityType = contentColumns.shift();
    const entityLabel = t(`quotasPerformance.dashboardPlan.entity.label.${entityType}`);

    const summariesColumns = [...plan.summaries.columns];
    const summariesType = summariesColumns.shift();
    const summariesRows = plan.summaries.rows;
    const summariesRowsCount = summariesRows.length;

    const { periodType, frequency, customBeginAt } = plan;

    const finalArray = [
      {
        id: entityType,
        Header: (
          <QuotaPerformanceTableCell isDark>
            {entityLabel}
          </QuotaPerformanceTableCell>
        ),
        Footer: (
          <div className={styles.footerCell}>
            {summariesRows.map((row, i) => (
              <QuotaPerformanceTableCell key={i} isDark isBold isHighlight hasBorderBottom={i < summariesRowsCount - 1}>
                {t(`quotasPerformance.dashboardPlan.summary.label.${row[summariesType]}`)}
              </QuotaPerformanceTableCell>
            ))}
          </div>
        ),
        accessor: 'user',
        minWidth: 200,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <QuotaPerformanceTableCell>
            <UserProfile user={value} clickable />
          </QuotaPerformanceTableCell>
        ),
        disableHeaderCellComponent: true,
        disableBodyCellComponent: true,
        disableFooterCellComponent: true,
      },
      ...contentColumns.map((column) => ({
        id: column,
        Header: () => {
          const period = column.split(':');

          return (
            <QuotaPerformanceTableCell isDark>
              {getQuotaPerformancePeriodName(periodType, frequency, period[1], period[0], customBeginAt)}
            </QuotaPerformanceTableCell>
          );
        },
        Footer: (
          <div className={styles.footerCell}>
            {summariesRows.map((row, i) => row[column] && (
              <QuotaPerformanceTableCell
                key={i}
                isBold
                isHighlight
                hasBorderBottom={i < summariesRowsCount - 1}
                theme={getQuotaPerformanceValuesByMetric(row[column]).theme}
              >
                {getQuotaPerformanceValuesByMetric(row[column]).value}
              </QuotaPerformanceTableCell>
            ))}
          </div>
        ),
        accessor: column,
        width: 120,
        minWidth: 120,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => value && (
          <QuotaPerformanceTableCell theme={getQuotaPerformanceValuesByMetric(value).theme}>
            {getQuotaPerformanceValuesByMetric(value).value}
          </QuotaPerformanceTableCell>
        ),
        disableHeaderCellComponent: true,
        disableBodyCellComponent: true,
        disableFooterCellComponent: true,
      })),
    ];

    return finalArray;
  }, [plan]);

  const removeDashboardPlanNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) return null;

    return (
      <RemoveDashboardPlan dashboardId={dashboardId} planId={plan.id} />
    );
  }, [profile, dashboardId, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <div className={styles.infos}>
          <div className={styles.title}>
            <h2 className={styles.planName}>{plan.name}</h2>
            <Link
              path={routePaths.v2.planDetails}
              params={{ masterPlanId: plan.id }}
              displayIcon
            >
              {t('quotasPerformance.dashboardPlan.planDetails')}
            </Link>
          </div>
          <div className={styles.metric}>
            {t('quotasPerformance.dashboardPlan.metricTracked')}
            <span>{plan.quotaTrackingType || t('quotasPerformance.dashboardPlan.noMetricTracked')}</span>
          </div>
        </div>
        <div className={styles.actions}>
          {removeDashboardPlanNode}
        </div>
      </div>
      {!plan.quotaTrackingType && (
        <Alert
          className={styles.alertContainer}
          type={ALERT_TYPES.WARNING}
          message={t('quotasPerformance.dashboardPlan.noMetricTracked.alert.message')}
        />
      )}
      <Table
        className={styles.table}
        columns={tableColumns}
        data={plan.content.rows}
        nbOfFixedColumns={1}
        stickyHeader
        displayFooters={plan.content.rows.length > 0}
      />
    </div>
  );
};

QuotaPerformanceDashboardPlan.propTypes = {
  className: PropTypes.string,
  dashboardId: PropTypes.string.isRequired,
  plan: QuotaPerformancePlanModel.propTypes.isRequired,
};

QuotaPerformanceDashboardPlan.defaultProps = {
  className: '',
};

export default QuotaPerformanceDashboardPlan;
