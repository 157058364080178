import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Alert from '@palette/components/designSystem/Alert/Alert';
import OffboardUserButton from '@palette/components/userDetails/OffboardUserButton/OffboardUserButton';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import ResetLine from '@palette/components/utils/Icons/ResetLine';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions } from '@palette/state/Users';

import styles from './UserOffboardingSettings.less';

const classNames = bindClassNames.bind(styles);

const UserOffboardingSettings = ({ className, user }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const moment = getMoment();
  const dispatch = useDispatch();

  const handleResetLeaveDate = useCallback(() => {
    dispatch(UsersActions.resetLeaveDate({ userId: user.id }));
  }, [user]);

  const contentNode = useMemo(() => {
    if (user.leaveDate !== null) {
      return (
        <>
          <Alert
            className={styles.description}
            type={ALERT_TYPES.ERROR}
            message={t('userOffboardingSettings.userHasLeaveDate', { userDisplayName: user.displayName, leaveDate: moment.utc(user.leaveDate).format('LL') })}
            description={t('userOffboardingSettings.resetLeaveDateDescription')}
          />
          <Popconfirm
            title={t('userOffboardingSettings.resetLeaveDate.popconfirm.title')}
            onConfirm={handleResetLeaveDate}
            okText={t('common.global.yes')}
            cancelText={t('common.global.no')}
            size="small"
          >
            <Button
              className={styles.resetLeaveDateBtn}
              type="secondary"
              icon={<ResetLine />}
            >
              {t('userOffboardingSettings.resetLeaveDate.label', { userDisplayName: user.displayName })}
            </Button>
          </Popconfirm>
        </>
      );
    }

    return (
      <>
        <Alert
          className={styles.description}
          type={ALERT_TYPES.WARNING}
          message={t('userOffboardingSettings.description')}
        />
        <OffboardUserButton user={user} />
      </>
    );
  }, [user, handleResetLeaveDate]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.UPDATE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <h2>{t('userOffboardingSettings.title')}</h2>
      {contentNode}
    </div>
  );
};

UserOffboardingSettings.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserOffboardingSettings.defaultProps = {
  className: '',
};

export default UserOffboardingSettings;
