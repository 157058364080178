import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import Markdown from 'react-markdown';

import * as NotebookCellModel from '@palette/models/NotebookCell';

import styles from './NotebookMarkdownCellPreview.less';

const classNames = bindClassNames.bind(styles);

const NotebookMarkdownCellPreview = ({
  className,
  cell,
}) => (
  <Markdown
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    {cell.content}
  </Markdown>
);

NotebookMarkdownCellPreview.propTypes = {
  className: PropTypes.string,
  cell: NotebookCellModel.propTypes.isRequired,
};

NotebookMarkdownCellPreview.defaultProps = {
  className: '',
};

export default NotebookMarkdownCellPreview;
