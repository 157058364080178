import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import HighlightZone from '@palette/components/highlight/HighlightZone/HighlightZone';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useCommissionListPageFilters } from '@palette/hooks/CommissionHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { buildCommissionListByFiltersPayload } from '@palette/helpers/CommissionHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';

import styles from './MyCommissionListHighlightZone.less';

const classNames = bindClassNames.bind(styles);

const MyCommissionListHighlightZone = ({ className }) => {
  const dispatch = useDispatch();
  const profile = useProfile();

  const commissionListPageFilters = useCommissionListPageFilters();

  const listByFiltersDashboardIsPending = useSelector(CommissionsSelectors.listByFiltersDashboardIsPending);
  const listByFiltersDashboard = useSelector(CommissionsSelectors.getCommissionListByFiltersDashboard);

  const getCommissionListWidgetsData = () => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return;

    const listByFiltersPayload = buildCommissionListByFiltersPayload(commissionListPageFilters);

    dispatch(CommissionsActions.listICByFiltersDashboard(listByFiltersPayload));
  };

  const {
    searchedResourceFromSearch,
    searchedStatus,
    searchedFrom,
    searchedTo,
    searchedResourceType,
    searchedPlan,
  } = useMemo(() => commissionListPageFilters, [commissionListPageFilters]);

  useEffect(() => {
    getCommissionListWidgetsData();
  }, [
    searchedFrom,
    searchedTo,
    searchedResourceFromSearch,
    searchedStatus,
    searchedResourceType,
    searchedPlan,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <HighlightZone loading={listByFiltersDashboardIsPending} data={listByFiltersDashboard} forIC />
    </div>
  );
};

MyCommissionListHighlightZone.propTypes = {
  className: PropTypes.string,
};

MyCommissionListHighlightZone.defaultProps = {
  className: '',
};

export default MyCommissionListHighlightZone;
