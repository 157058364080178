import adminMenu from '@palette/config/menus/adminMenu';
import icMenu from '@palette/config/menus/icMenu';
import actionsMenu from '@palette/config/menus/actionsMenu';

const menus = [
  adminMenu,
  icMenu,
  actionsMenu,
];

export default menus;
