import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'HistoryFxRate';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  historyRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startDate: PropTypes.string,
  isCurrent: PropTypes.bool,
});

export const transform = (historyFxRate) => {
  if (!historyFxRate) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(historyFxRate, '_id'),
      from: manageStringAttribute(historyFxRate, 'from'),
      to: manageStringAttribute(historyFxRate, 'to'),
      historyRate: manageAmountAttribute(historyFxRate, 'rate'),
      startDate: manageDateAttribute(historyFxRate, 'startDate'),
      isCurrent: manageBooleanAttribute(historyFxRate, 'isCurrent'),
    }
  );
};

export const transformList = (historyFxRates) => historyFxRates.map((historyFxRate) => transform(historyFxRate));
