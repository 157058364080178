import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import AddEditAdjustmentModal from '@palette/components/statement/userStatement/AddEditAdjustmentModal/AddEditAdjustmentModal';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useStatementUserIdInParams, usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { floatToFixedNumber } from '@palette/helpers/CommonHelper';

import routePaths from '@palette/config/routePaths';

import { actions as StatementsActions } from '@palette/state/Statements';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as UserStatementCorrectionModel from '@palette/models/UserStatementCorrection';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './UserStatementCorrectionsItemActions.less';

const classNames = bindClassNames.bind(styles);

const UserStatementCorrectionsItemActions = ({
  className,
  userStatement,
  statementPeriod,
  correction,
  reason,
  currency,
  needRedirectOnCB,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const [createAdjustmentModalIsVisible, showCreateAdjustmentModal] = useState(false);

  const { userId } = useStatementUserIdInParams();

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleCreateAdjustmentClick = (event) => {
    stopPropagation(event);
    showCreateAdjustmentModal(true);
  };

  const handleSuccessCB = useCallback(() => {
    if (!needRedirectOnCB) return;

    redirectTo({
      path: routePaths.v2.statementsUserStatement,
      params: { statementPeriodId: statementPeriod.id, userId },
      qsObject: periodsDatesFilterQSObject,
    });
  }, [
    needRedirectOnCB,
    statementPeriod,
    userId,
    periodsDatesFilterQSObject,
  ]);

  const ignoreCorrection = useCallback((event) => {
    stopPropagation(event);

    dispatch(StatementsActions.ignoreCorrection({
      statementId: userStatement.id,
      correctionStatementId: correction.from.statementId,
      statementPeriodId: statementPeriod.id,
      userId,
      currency,
      onSuccessCB: handleSuccessCB,
    }));
  }, [
    userStatement,
    correction,
    statementPeriod,
    userId,
    currency,
    handleSuccessCB,
  ]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.actionButton}
        type="link"
        icon={<PenFilled width={18} height={18} />}
        onClick={handleCreateAdjustmentClick}
      >
        {t('userStatementCorrections.actions.createAdjustment')}
      </Button>
      <Popconfirm
        title={t('userStatementCorrections.actions.ignore.confirm')}
        onConfirm={ignoreCorrection}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Button
          className={styles.actionButton}
          type="linkDestroy"
          icon={<TrashFilled width={18} height={18} />}
        >
          {t('userStatementCorrections.actions.ignore')}
        </Button>
      </Popconfirm>
      {createAdjustmentModalIsVisible && (
        <AddEditAdjustmentModal
          visible
          onClose={() => showCreateAdjustmentModal(false)}
          statementId={userStatement.id}
          statementAmount={userStatement.statementAmount}
          statementCurrency={userStatement.currency}
          adjustment={{
            reason,
            amount: floatToFixedNumber(correction.newAmount - correction.correctedAmount),
            currency: correction.currency,
          }}
          correctionStatementId={correction.from.statementId}
          statementPeriod={statementPeriod}
          currency={currency}
          needRedirectOnCB={needRedirectOnCB}
          isASuggestedCorrection
        />
      )}
    </div>
  );
};

UserStatementCorrectionsItemActions.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes.isRequired,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  correction: UserStatementCorrectionModel.propTypes.isRequired,
  reason: PropTypes.string.isRequired,
  currency: PropTypes.string,
  needRedirectOnCB: PropTypes.bool,
};

UserStatementCorrectionsItemActions.defaultProps = {
  className: '',
  currency: null,
  needRedirectOnCB: false,
};

export default UserStatementCorrectionsItemActions;
