import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  COMMISSION_404_NOT_FOUND: {
    code: 'commissions.errors.commission.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  COMMISSION_PER_TARGET: {
    code: 'commissions.errors.per.target',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
