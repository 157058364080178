import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as NotebookDataframeNameCopiedStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookDataframeNameCopiedStrategy';

const classNames = bindClassNames.bind();

const NotebookDataframeNameCopiedContentStrategy = ({ className, content }) => {
  const { name } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notifications.notebooks.success.copyConnectionConnectorDataframeNameToClipboard.content"
        values={{ name }}
      />
    </span>
  );
};

NotebookDataframeNameCopiedContentStrategy.propTypes = {
  className: PropTypes.string,
  content: NotebookDataframeNameCopiedStrategyModel.propTypes.isRequired,
};

NotebookDataframeNameCopiedContentStrategy.defaultProps = {
  className: '',
};

export default NotebookDataframeNameCopiedContentStrategy;
