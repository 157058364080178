import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PlanWithPeriods from '@palette/components/masterPlan/PlanWithPeriods/PlanWithPeriods';
import Tag from '@palette/components/designSystem/Tag/Tag';
import Popover from '@palette/components/designSystem/Popover/Popover';

import { TAG_THEMES } from '@palette/constants/tag';

import * as LatestObjectsDataModel from '@palette/models/widgets/dashboard/LatestObjectsData';

import styles from './PlansWithPeriods.less';

const classNames = bindClassNames.bind(styles);

const PlansWithPeriods = ({ className, deal, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const plansNodes = useMemo(() => (
    deal.plansWithPeriods.map(({ plan, periods }) => (
      <PlanWithPeriods
        key={plan.id}
        className={styles.planWithPeriods}
        deal={deal}
        plan={plan}
        periods={periods}
        readOnly={readOnly}
        enableLinks={enableLinks}
      />
    ))
  ), [deal, readOnly, enableLinks]);

  const finalNode = useMemo(() => {
    if (plansNodes.length > 1) {
      const popoverContentNode = (
        <div className={styles.popoverContentWrapper}>
          {plansNodes}
        </div>
      );

      return (
        <Popover
          trigger="hover"
          content={popoverContentNode}
        >
          <div>
            <Tag
              className={styles.plansCountTag}
              theme={TAG_THEMES.LAVENDER}
              label={t('plansWithPeriods.plans', { count: plansNodes.length })}
            />
          </div>
        </Popover>
      );
    }

    return plansNodes;
  }, [plansNodes]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {finalNode}
    </div>
  );
};

PlansWithPeriods.propTypes = {
  className: PropTypes.string,
  deal: LatestObjectsDataModel.dealPropTypes.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlansWithPeriods.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlansWithPeriods;
