import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Button from '@palette/components/designSystem/Button/Button';
import PaymentsEditPayoutScheduleModal from '@palette/components/commission/PaymentsEditPayoutScheduleModal/PaymentsEditPayoutScheduleModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getCommissionAmount } from '@palette/helpers/CommissionHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as CommissionModel from '@palette/models/Commission';

import styles from './PaymentsEditPayoutSchedule.less';

const classNames = bindClassNames.bind(styles);

const PaymentsEditPayoutSchedule = ({ className, commission }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [editPayoutScheduleModalIsVisible, showEditPayoutScheduleModal] = useState(false);

  const commissionAmount = getCommissionAmount(commission);

  if (commissionAmount === 0) return null;

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMMISSIONS.MANAGE]) || commission.cantUpdateMine) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        icon={<PenFilled />}
        onClick={() => showEditPayoutScheduleModal(true)}
      >
        {t('paymentsEditPayoutSchedule.editPayoutSchedule')}
      </Button>
      {
        editPayoutScheduleModalIsVisible && (
          <PaymentsEditPayoutScheduleModal visible onClose={() => showEditPayoutScheduleModal(false)} commission={commission} />
        )
      }
    </div>
  );
};

PaymentsEditPayoutSchedule.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

PaymentsEditPayoutSchedule.defaultProps = {
  className: '',
};

export default PaymentsEditPayoutSchedule;
