import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Table from '@palette/components/designSystem/Table/Table';
import Formula from '@palette/components/formula/Formula/Formula';

import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getCommissionAmount, getCommissionValue } from '@palette/helpers/CommissionHelper';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionPerObjectRuleDetails.less';

const classNames = bindClassNames.bind(styles);

const CommissionPerObjectRuleDetails = ({ className, commission }) => {
  const { t } = useTranslation();

  const rule = commission.scopeParams?.rule;

  const commissionAmount = getCommissionAmount(commission);
  const commissionValue = getCommissionValue(commission);

  const contentNodes = commission.brackets.map((bracket) => {
    let detailsNode = (
      <div className={styles.detailWrapper}>
        <div className={styles.detailTitle}>
          {t('commissionRuleDetails.value')}
        </div>
        <div className={styles.detailValue}>
          {comaSeparatorFormatter(floatToFixedNumber(bracket.value))}
        </div>
      </div>
    );

    if (rule.brackets.length > 1) {
      const ruleBracketIndex = rule.brackets.findIndex((ruleBracket) => ruleBracket.id === bracket.id);
      const ruleBracketDefinition = rule.brackets[ruleBracketIndex];

      let from = comaSeparatorFormatter(ruleBracketDefinition.from);
      if (rule.percentage) {
        from = `${Math.round(ruleBracketDefinition.from * 100)}%`;
      }

      let ruleDescription = t('commissionRuleDetails.bracket.from', { from });

      if (ruleBracketDefinition.to !== null && ruleBracketDefinition.to !== Infinity) {
        let to = comaSeparatorFormatter(ruleBracketDefinition.to);
        if (rule.percentage) {
          to = `${Math.round(ruleBracketDefinition.to * 100)}%`;
        }

        ruleDescription = t('commissionRuleDetails.bracket.fromTo', { from, to });
      }

      const columns = [
        {
          id: 'ruleIndex',
          Header: rule.name || t('commissionRuleDetails.ruleNumber', { index: rule.index + 1 }),
          accessor: 'ruleDescription',
          width: `${100 / 3}%`,
          minWidth: `${100 / 3}%`,
        },
        {
          id: 'value',
          Header: t('commissionRuleDetails.value'),
          accessor: (commissionBracket) => comaSeparatorFormatter(floatToFixedNumber(commissionBracket.value)),
          width: `${100 / 3}%`,
          minWidth: `${100 / 3}%`,
        },
        {
          id: 'bracketPercentage',
          Header: t('commissionRuleDetails.bracketPercentage', { type: commission.scopeParams?.plan?.trackingObject?.type }),
          accessor: (commissionBracket) => `${parseFloat(((commissionBracket.value / commissionValue) * 100).toFixed(1))}%`,
          width: `${100 / 3}%`,
          minWidth: `${100 / 3}%`,
        },
      ];

      detailsNode = (
        <>
          <div className={styles.tierLabel}>
            {t('commissionRuleDetails.tierNumber', { index: ruleBracketIndex + 1 })}
          </div>
          <Table columns={columns} data={[{ ...bracket, ruleDescription }]} stretch />
        </>
      );
    }

    const ruleBracketDefinition = rule.brackets.find((ruleBracket) => ruleBracket.id === bracket.id);

    let { formula } = ruleBracketDefinition;
    if (bracket.value !== commissionValue) {
      formula = `(${ruleBracketDefinition.formula}) * ${parseFloat(((bracket.value / commissionValue) * 100).toFixed(1))}%`;
    }

    const formulaNode = (
      <div
        className={classNames({
          detailWrapper: true,
          detailFormulaWrapper: true,
        })}
      >
        <div className={styles.detailTitle}>
          {t('commissionRuleDetails.formula')}
        </div>
        <div className={styles.detailValue}>
          <Formula
            formula={formula}
            scope={commission.scope}
          />
        </div>
      </div>
    );

    let resultAmount = bracket.amount;

    if (resultAmount === null) {
      resultAmount = (bracket.value / commissionValue) * commissionAmount;
    }

    const resultNode = (
      <div className={styles.detailWrapper}>
        <div className={styles.detailTitle}>
          {t('commissionRuleDetails.result')}
        </div>
        <div className={styles.detailValue}>
          {formatPrice(resultAmount, commission.currency)}
        </div>
      </div>
    );

    return (
      <div key={bracket.id} className={styles.bracket}>
        {detailsNode}
        {formulaNode}
        {resultNode}
      </div>
    );
  });

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNodes}
    </div>
  );
};

CommissionPerObjectRuleDetails.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionPerObjectRuleDetails.defaultProps = {
  className: '',
};

export default CommissionPerObjectRuleDetails;
