import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PlanEstimationDefinition';

export const propTypes = PropTypes.shape({
  referenceDealId: PropTypes.string,
  explanation: PropTypes.string,
  display: PropTypes.bool,
});

export const transform = (planEstimationDefinition) => {
  if (!planEstimationDefinition) {
    return null;
  }

  return ({
    referenceDealId: manageStringAttribute(planEstimationDefinition, 'reference', null),
    explanation: manageStringAttribute(planEstimationDefinition, 'explanation'),
    display: manageBooleanAttribute(planEstimationDefinition, 'display', false),
  });
};

export const transformList = (planEstimationDefinitions) => planEstimationDefinitions.map((planEstimationDefinition) => transform(planEstimationDefinition));

export const merge = (planEstimationDefinition1, planEstimationDefinition2) => {
  if (!planEstimationDefinition2) return planEstimationDefinition1;
  if (!planEstimationDefinition1) return planEstimationDefinition2;

  return planEstimationDefinition2;
};
