import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AddUsersWithFastOnboardingModal from '@palette/components/user/AddUsersWithFastOnboardingModal/AddUsersWithFastOnboardingModal';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

const AddUsersInQuotaModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { quotaId } = useParams();

  const addUsersToQuotaIsPending = useSelector(QuotasSelectors.addUsersToQuotaIsPending);
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));

  const preSelectedUsers = useMemo(() => (quota.users.map((quotaUser) => quotaUser.user)), [quota]);

  const handleAddUsersInQuota = (usersToAdd, cleanAndClose) => {
    dispatch(QuotasActions.addUsersToQuota({ quota, usersToAdd, onSuccessCB: cleanAndClose }));
  };

  return (
    <AddUsersWithFastOnboardingModal
      title={t('quota.addUsersInQuota')}
      visible={visible}
      onClose={onClose}
      onOk={handleAddUsersInQuota}
      okText={t('quota.addUsers')}
      loading={addUsersToQuotaIsPending}
      preSelectedUsers={preSelectedUsers}
      disableUserConnectorsSelection
    />
  );
};

AddUsersInQuotaModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

AddUsersInQuotaModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddUsersInQuotaModal;
