import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import UserLinkedResourceItem from '@palette/components/userDetails/UserLinkedResourceItem/UserLinkedResourceItem';
import LinkResourcesToUser from '@palette/components/userDetails/LinkResourcesToUser/LinkResourcesToUser';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserLinkedResourcesSettings.less';

const classNames = bindClassNames.bind(styles);

const UserLinkedResourcesSettings = ({ className, user }) => {
  const { t } = useTranslation();

  const linkedResourcesListNode = useMemo(() => {
    if (user.resourceObjects.length === 0) {
      return (
        <DefaultEmptyState className={styles.emptyState} description={t('userLinkedResourcesSettings.empty.description')} />
      );
    }

    const listItemsNodes = user.resourceObjects.map((resourceObject) => (
      <UserLinkedResourceItem
        key={resourceObject.id}
        className={styles.resourceObjectItem}
        user={user}
        resourceObject={resourceObject}
      />
    ));

    return (
      <div className={styles.list}>
        {listItemsNodes}
      </div>
    );
  }, [user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.titleActionWrapper}>
        <h2>{t('userLinkedResourcesSettings.title')}</h2>
        <LinkResourcesToUser user={user} />
      </div>
      {linkedResourcesListNode}
    </div>
  );
};

UserLinkedResourcesSettings.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserLinkedResourcesSettings.defaultProps = {
  className: '',
};

export default UserLinkedResourcesSettings;
