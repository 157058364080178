import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';

import ChartTooltip from '@palette/components/designSystem/ChartTooltip/ChartTooltip';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { floatToFixedNumber, formatNumberShortDisplay } from '@palette/helpers/CommonHelper';
import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';
import {
  getPeriodElapsedTimePercentage,
  getPeriodProgressChartGradient,
  getPlanValueDefinition,
} from '@palette/helpers/MasterPlanHelper';

import {
  CHART_GRADIENT_GREEN,
  CHART_GRADIENT_ORANGE,
  CHART_GRADIENT_RED,
  COLOR_NEUTRALS_GREY_2,
  gradientsDefs,
  LABEL_TEXT_COLOR_WHITE,
  MARKER_COLOR,
  TEXT_STYLE_CAPTION_1,
} from '@palette/constants/charts';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as PlanProgressBarChartModel from '@palette/models/widgets/dashboard/PlanProgressBarChartData';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './PlanProgressBarChart.less';

const classNames = bindClassNames.bind(styles);

const PlanProgressBarChart = ({ className, plan, period, data }) => {
  const { t } = useTranslation();

  if (data.progressPerPeriod.length === 0) return null;

  const hasNoQuotaTarget = data.target === -1;

  if (hasNoQuotaTarget) {
    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <DefaultEmptyState description={t('planProgressBarChart.noTarget')} />
      </div>
    );
  }

  const planValueDefinition = getPlanValueDefinition(plan);

  const elapsedTimePerMonth = 100 / plan.frequency;
  const periodElapsedTime = getPeriodElapsedTimePercentage(plan, period);
  const nbOfSpentMonthInPeriod = Math.floor(periodElapsedTime / elapsedTimePerMonth);

  const chartData = data.progressPerPeriod.map((progressPeriod, progressPeriodIndex) => {
    const progress = floatToFixedNumber(progressPeriod.progress * 100);
    let elapsedTime = 0;
    if (progressPeriodIndex < nbOfSpentMonthInPeriod) {
      elapsedTime = 100;
    }
    if (progressPeriodIndex === nbOfSpentMonthInPeriod) {
      elapsedTime = periodElapsedTime - (elapsedTimePerMonth * nbOfSpentMonthInPeriod);
    }
    const progressGradientId = getPeriodProgressChartGradient(elapsedTime, progress).id;

    return {
      shortPeriod: getFrequencyPeriodName(PERIOD_TYPES.MONTH, 1, progressPeriod.year, progressPeriod.period, null, true),
      period: getFrequencyPeriodName(PERIOD_TYPES.MONTH, 1, progressPeriod.year, progressPeriod.period, null),
      progress: progressPeriod.progress,
      progressLabel: Math.round(progress),
      target: progressPeriod.target,
      elapsedTime,
      progressGradientId,
    };
  });

  const getTooltip = (dataDesc) => (
    <ChartTooltip
      title={`${dataDesc.data.period}: ${dataDesc.data.progressLabel}%`}
      content={`${planValueDefinition} · ${formatNumberShortDisplay(dataDesc.data.target * dataDesc.data.progress)} / ${formatNumberShortDisplay(dataDesc.data.target)}`}
    />
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.chartWrapper}>
        <div className={styles.chart}>
          <ResponsiveBar
            data={chartData}
            keys={['progressLabel']}
            indexBy="shortPeriod"
            margin={{ top: 0, right: 0, bottom: 55, left: 5 }}
            padding={0.2}
            valueScale={{ type: 'linear' }}
            borderRadius={5}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 8,
              tickRotation: -65,
            }}
            axisLeft={null}
            label={(d) => (`${d.value}%`)}
            labelSkipWidth={35}
            labelSkipHeight={20}
            enableGridY={false}
            markers={[
              {
                axis: 'y',
                value: 100,
                lineStyle: { stroke: MARKER_COLOR, strokeWidth: 1 },
                textStyle: { ...TEXT_STYLE_CAPTION_1, stroke: COLOR_NEUTRALS_GREY_2 },
                legend: '100%',
                legendPosition: 'top-left',
                legendOffsetX: -25,
                legendOffsetY: 10,
              },
            ]}
            defs={gradientsDefs}
            fill={[
              { match: (dataDesc) => (dataDesc.data.data.progressGradientId === CHART_GRADIENT_GREEN.id), id: CHART_GRADIENT_GREEN.id },
              { match: (dataDesc) => (dataDesc.data.data.progressGradientId === CHART_GRADIENT_ORANGE.id), id: CHART_GRADIENT_ORANGE.id },
              { match: (dataDesc) => (dataDesc.data.data.progressGradientId === CHART_GRADIENT_RED.id), id: CHART_GRADIENT_RED.id },
            ]}
            labelTextColor={LABEL_TEXT_COLOR_WHITE}
            tooltip={getTooltip}
          />
        </div>
      </div>
    </div>
  );
};

PlanProgressBarChart.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  data: PlanProgressBarChartModel.propTypes.isRequired,
};

PlanProgressBarChart.defaultProps = {
  className: '',
};

export default PlanProgressBarChart;
