import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Link from '@palette/components/designSystem/Link/Link';
import RolesFilled from '@palette/components/utils/Icons/RolesFilled';

import routePaths from '@palette/config/routePaths';

import * as RoleModel from '@palette/models/Role';

import styles from './RoleBreadcrumb.less';

const classNames = bindClassNames.bind(styles);

const RoleBreadcrumb = ({ className, role }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.roles}
        >
          <RolesFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {t('adminMenu.users.roles')}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
      <div className={styles.currentLocationWrapper}>
        <RolesFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {role !== null ? role.name : t('roleBreadcrumb.newRole')}
        </div>
      </div>
    </div>
  );
};

RoleBreadcrumb.propTypes = {
  className: PropTypes.string,
  role: RoleModel.propTypes,
};

RoleBreadcrumb.defaultProps = {
  className: '',
  role: null,
};

export default RoleBreadcrumb;
