import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import StatementPeriodStatus from '@palette/components/statement/statementPeriod/StatementPeriodStatus/StatementPeriodStatus';
import OngoingBulkApproveStatus from '@palette/components/statement/statementPeriodUsers/OngoingBulkApproveStatus/OngoingBulkApproveStatus';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useStatementPeriodStatus } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';
import { getStatementUserApprovalStatus, statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_APPROVAL_STATUSES, STATEMENT_PERIOD_STATUSES } from '@palette/constants/statements';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import { selectors as StatementsSelectors, actions as StatementsActions } from '@palette/state/Statements';

import styles from './UsersStatementsApproveAllAction.less';

const classNames = bindClassNames.bind(styles);

const UsersStatementsApproveAllAction = ({ className, userStatements, statementPeriod, currency, disabled }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const approveStatementsIsPending = useSelector(StatementsSelectors.approveStatementsIsPending);
  const ongoingBulkApproveStatus = useSelector(StatementsSelectors.getOngoingBulkApproveStatus);

  const statementPeriodStatus = useStatementPeriodStatus(statementPeriod);

  const approvableUserStatements = useMemo(() => (
    userStatements.filter((userStatement) => {
      if (userStatement.isPaid || userStatement.isDirty) return false;

      const statementUserApprovalStatus = getStatementUserApprovalStatus(userStatement);

      return statementUserApprovalStatus !== STATEMENT_APPROVAL_STATUSES.APPROVED;
    })
  ), [userStatements]);

  const handleApproveAll = useCallback(() => {
    const statementIds = approvableUserStatements.map((statement) => statement.id);

    dispatch(StatementsActions.approveStatements({
      statementIds,
      statementPeriodId: statementPeriod.id,
      currency,
    }));
  }, [approvableUserStatements, statementPeriod, currency]);

  const contentNode = useMemo(() => {
    const isFarInFuture = statementPeriodIsInFuture(statementPeriod);

    if (
      !statementPeriodStatus
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAID
      || ( // far in the future
        statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE
        && isFarInFuture
      )
    ) return null;

    if (
      statementPeriodStatus === STATEMENT_PERIOD_STATUSES.APPROVAL_REQUIRED
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PENDING_ACTIONS
      || ( // not so far in future
        statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE
        && !isFarInFuture
      )
    ) {
      let btnLabel = t('statementPeriodUsers.approveAll.button.label');
      if (approveStatementsIsPending && ongoingBulkApproveStatus !== null) {
        btnLabel = t('statementPeriodUsers.approveAll.button.processingLabel', { currentIndex: ongoingBulkApproveStatus.index + 1, nbToProcess: ongoingBulkApproveStatus.statementIds.length });
      }
      return (
        <Popconfirm
          title={t('statementPeriodUsers.approveAll.button.popconfirm')}
          onConfirm={handleApproveAll}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
          disabled={approveStatementsIsPending || approvableUserStatements.length === 0 || disabled}
        >
          <Button
            type="primaryBlue"
            icon={approveStatementsIsPending ? <LoadingLine spin /> : <CheckFilled />}
            disabled={approveStatementsIsPending || approvableUserStatements.length === 0 || disabled}
          >
            {btnLabel}
          </Button>
        </Popconfirm>
      );
    }

    return (
      <StatementPeriodStatus statementPeriod={statementPeriod} />
    );
  }, [
    statementPeriod,
    statementPeriodStatus,
    approveStatementsIsPending,
    disabled,
    handleApproveAll,
    approvableUserStatements,
    ongoingBulkApproveStatus,
  ]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      <OngoingBulkApproveStatus />
    </div>
  );
};

UsersStatementsApproveAllAction.propTypes = {
  className: PropTypes.string,
  userStatements: PropTypes.arrayOf(UserStatementModel.propTypes),
  statementPeriod: StatementPeriodModel.propTypes,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
};

UsersStatementsApproveAllAction.defaultProps = {
  className: '',
  userStatements: [],
  statementPeriod: null,
  currency: null,
  disabled: false,
};

export default UsersStatementsApproveAllAction;
