import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Button from '@palette/components/designSystem/Button/Button';
import DatabaseFilled from '@palette/components/utils/Icons/DatabaseFilled';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';

import { PLAN_VARIABLE_SOURCE_TYPE } from '@palette/constants/planV3';

import { actions as PlanV3Actions } from '@palette/state/PlanV3';

import styles from './ContextVariablesList.less';

const classNames = bindClassNames.bind(styles);

const ContextVariablesList = ({ className, source, variablesList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const variablesListItemNode = useMemo(() => variablesList.map((variable, index) => {
    const handleCopyName = (event) => {
      event.stopPropagation();
      dispatch(PlanV3Actions.copyVariableNameToClipboard({ name: variable.name }));
    };

    return (
      <div key={`variable-${source}-${index}`} className={styles.variable}>
        <Tooltip title={variable.label}>
          <div className={styles.name}>
            {variable.name}
          </div>
        </Tooltip>
        <Tooltip title={t('actions.copy')}>
          <Button
            className={styles.copy}
            type="link"
            icon={<DuplicateFilled width={16} height={16} />}
            onClick={handleCopyName}
          />
        </Tooltip>
      </div>
    );
  }), [variablesList]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>
        <DatabaseFilled className={styles.titleIcon} />
        {t(`editPlanPage.context.variables.source.${source}`)}
      </div>
      {variablesListItemNode}
    </div>
  );
};

ContextVariablesList.propTypes = {
  className: PropTypes.string,
  source: PropTypes.oneOf(Object.keys(PLAN_VARIABLE_SOURCE_TYPE)).isRequired,
  variablesList: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  })),
};

ContextVariablesList.defaultProps = {
  className: '',
  variablesList: [],
};

export default ContextVariablesList;
