import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import QuotaUserModal from '@palette/components/quota/QuotaUserModal/QuotaUserModal';
import RemoveUserFromQuota from '@palette/components/quota/RemoveUserFromQuota/RemoveUserFromQuota';
import AddQuotaUserLeaveDate from '@palette/components/quota/AddQuotaUserLeaveDate/AddQuotaUserLeaveDate';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import { selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './QuotaUserCell.less';

const classNames = bindClassNames.bind(styles);

const QuotaUserCell = ({ quotaUserId, quotaId }) => {
  const [quotaUserModalIsVisible, showQuotaUserModal] = useState(false);

  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));
  const quotaUser = useSelector((state) => QuotasSelectors.getQuotaUserByIdAndQuotaId(state, { quotaId, quotaUserId }));

  const handleShowQuotaUserModal = useMemo(() => {
    if (quota.archived) return null;

    return () => showQuotaUserModal(true);
  }, [showQuotaUserModal, quota]);

  let removeUserNode = null;
  if (quota !== null && !quota.archived && quota.periodType !== PERIOD_TYPES.CUSTOM) {
    removeUserNode = (
      <AddQuotaUserLeaveDate
        className={classNames({
          addQuotaUserLeaveDate: true,
          lastPeriodDefined: quotaUser.lastPeriod !== null,
        })}
        quotaUserId={quotaUserId}
        quotaId={quotaId}
      />
    );

    if (quotaUser.deletable) {
      removeUserNode = (
        <RemoveUserFromQuota className={styles.removeUserFromQuota} quotaUserId={quotaUserId} quotaId={quotaId} />
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <UserProfile
        className={styles.profile}
        user={quotaUser.user}
        onClick={(quota !== null && quota.periodType !== PERIOD_TYPES.CUSTOM) ? handleShowQuotaUserModal : null}
      />
      {removeUserNode}
      {
        quotaUserModalIsVisible && (
          <QuotaUserModal visible onClose={() => showQuotaUserModal(false)} quotaUserId={quotaUserId} quotaId={quotaId} />
        )
      }
    </div>
  );
};

QuotaUserCell.propTypes = {
  quotaUserId: PropTypes.string.isRequired,
  quotaId: PropTypes.string.isRequired,
};

export default QuotaUserCell;
