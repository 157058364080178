import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { STATEMENT_SNAPSHOT_ORIGINS } from '@palette/constants/statements';

import * as StatementStrategyModel from '@palette/models/StatementStrategy';
import * as MetaUserModel from '@palette/models/MetaUser';

export const modelName = 'StatementSnapshot';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  statementStrategy: StatementStrategyModel.propTypes,
  periodId: PropTypes.string,
  year: PropTypes.number,
  period: PropTypes.number,
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  totalAmount: PropTypes.number,
  currency: PropTypes.string,
  when: PropTypes.string,
  who: MetaUserModel.propTypes,
  snapshotOrigin: PropTypes.oneOf(Object.values(STATEMENT_SNAPSHOT_ORIGINS)),
});

export const manageStatementSnapshotOrigin = (statementSnapshot) => (STATEMENT_SNAPSHOT_ORIGINS[manageStringAttribute(statementSnapshot, 'snapshotOrigin')] || STATEMENT_SNAPSHOT_ORIGINS.APPROVED);

export const transform = (statementSnapshot) => {
  if (!statementSnapshot) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(statementSnapshot, 'snapshot'),
      statementStrategy: StatementStrategyModel.transform(manageFreeShapeObjectAttribute(statementSnapshot, 'periodStrategy')),
      periodId: manageStringAttribute(statementSnapshot, 'periodId'),
      year: manageNumberAttribute(statementSnapshot, 'year'),
      period: manageNumberAttribute(statementSnapshot, 'period'),
      beginDate: manageDateAttribute(statementSnapshot, 'beginAt'),
      endDate: manageDateAttribute(statementSnapshot, 'endAt'),
      totalAmount: manageAmountAttribute(statementSnapshot, 'amount'),
      currency: manageStringAttribute(statementSnapshot, 'currency'),
      who: MetaUserModel.transform(manageFreeShapeObjectAttribute(statementSnapshot, 'who')),
      when: manageDateAttribute(statementSnapshot, 'when'),
      snapshotOrigin: manageStatementSnapshotOrigin(statementSnapshot),
    }
  );
};

export const transformList = (statementSnapshots) => statementSnapshots.map((statementSnapshot) => transform(statementSnapshot));

export const merge = (statementSnapshot1, statementSnapshot2) => {
  if (!statementSnapshot2) return statementSnapshot1;
  if (!statementSnapshot1) return statementSnapshot2;

  return statementSnapshot2;
};
