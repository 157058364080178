import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import AddUsersInMasterPlanModal from '@palette/components/masterPlanSettings/AddUsersInMasterPlanModal/AddUsersInMasterPlanModal';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './AddUsersInMasterPlan.less';

const AddUsersInMasterPlan = ({ plan, forManagers }) => {
  const { t } = useTranslation();

  const [addUsersInMasterPlanModalIsVisible, showAddUsersInMasterPlanModal] = useState(false);

  return (
    <>
      <Button
        className={styles.button}
        type="link"
        onClick={() => showAddUsersInMasterPlanModal(true)}
        icon={<AddFilled />}
      >
        {forManagers ? t('masterPlanSettingsUsers.table.headers.addManager') : t('masterPlanSettingsUsers.table.headers.addUser')}
      </Button>
      {
        addUsersInMasterPlanModalIsVisible && (
          <AddUsersInMasterPlanModal visible onClose={() => showAddUsersInMasterPlanModal(false)} plan={plan} forManagers={forManagers} />
        )
      }
    </>
  );
};

AddUsersInMasterPlan.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  forManagers: PropTypes.bool,
};

AddUsersInMasterPlan.defaultProps = {
  forManagers: false,
};

export default AddUsersInMasterPlan;
