import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Layout } from 'antd';

import Sidebar from '@palette/components/navigation/Sidebar/Sidebar';
import LoggedUserRouting from '@palette/components/navigation/LoggedUserRouting/LoggedUserRouting';
import BannerWrappers from '@palette/components/banners/BannersWrapper/BannerWrappers';
import CommissionDrawer from '@palette/components/commission/CommissionDrawer/CommissionDrawer';
import CommissionV3Drawer from '@palette/components/commissionV3/CommissionV3Drawer/CommissionV3Drawer';
import TermsAndConditions from '@palette/components/termsAndConditions/TermsAndConditions/TermsAndConditions';
import GlobalNotif from '@palette/components/globalNotif/GlobalNotif/GlobalNotif';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useIsCollapsed } from '@palette/hooks/NavigationHooks';
import { useCurrencies, useUsers } from '@palette/hooks/UserHooks';

import { canSeeNPS, hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getBannersHeight } from '@palette/helpers/LocalStorageHelper';

import { APP_BOTTOM_ID, APP_TOP_ID, SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_NOT_COLLAPSED_WIDTH } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import FeedbackRateModal from '../../../../components/feedbackRateModal';

import styles from './LoggedUserApp.less';

const LoggedUserApp = ({ appWrapperState }) => {
  const profile = useProfile();
  const [menuIsCollapsed] = useIsCollapsed();
  const [currenciesList, getCurrenciesList, getCurrenciesListIsPending] = useCurrencies();
  const [usersList, getUsersList, getUsersListIsPending] = useUsers();

  const initialConnectorsGetListCallDone = useSelector(ConnectorsSelectors.initialGetListCallDone);

  const pendingConnectorsLoading = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.CONNECTORS.VIEW])) return false;

    return !initialConnectorsGetListCallDone;
  }, [profile, initialConnectorsGetListCallDone]);

  useEffect(() => {
    if (profile && !currenciesList.length && !getCurrenciesListIsPending) getCurrenciesList();
  }, [profile, currenciesList, getCurrenciesListIsPending]);

  useEffect(() => {
    if (profile && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.VIEW]) && !usersList.length && !getUsersListIsPending) getUsersList();
  }, [profile, usersList, getUsersListIsPending]);

  if (profile === null || pendingConnectorsLoading) return null;

  const bannersHeight = getBannersHeight();

  return (
    <>
      <BannerWrappers />
      <GlobalNotif />
      <Layout className={styles.layout} style={{ paddingTop: `${bannersHeight}px` }}>
        <Sidebar />
        <Layout
          className={styles.content}
          style={{ maxHeight: `calc(100vh - ${bannersHeight}px)`, maxWidth: `calc(100vw - ${menuIsCollapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_NOT_COLLAPSED_WIDTH})` }}
        >
          <div id={APP_TOP_ID} />
          <LoggedUserRouting appWrapperState={appWrapperState} />
          <div id={APP_BOTTOM_ID} />
        </Layout>
      </Layout>
      {canSeeNPS(profile) && <FeedbackRateModal />}
      <CommissionDrawer />
      <CommissionV3Drawer />
      <TermsAndConditions />
    </>
  );
};

LoggedUserApp.propTypes = {
  appWrapperState: PropTypes.object.isRequired,
};

export default LoggedUserApp;
