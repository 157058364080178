import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './TableBodyCell.less';

const classNames = bindClassNames.bind(styles);

const TableBodyCell = ({ className, type, children }) => (
  <div
    className={classNames({
      wrapper: true,
      borderless: type === 'borderless',
      [className]: className !== '',
    })}
  >
    <div className={styles.childrenWrapper}>
      {children}
    </div>
  </div>
);

TableBodyCell.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  children: PropTypes.any.isRequired,
};

TableBodyCell.defaultProps = {
  className: '',
  type: 'default',
};

export default TableBodyCell;
