import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ThreadComment from '@palette/components/thread/ThreadComment/ThreadComment';
import ChatFilled from '@palette/components/utils/Icons/ChatFilled';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Select from '@palette/components/designSystem/Select/Select';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';

import { TYPES } from '@palette/constants/comments';

import { STATUSES } from '@palette/constants/threads';

import * as ThreadModel from '@palette/models/Thread';
import * as CommissionModel from '@palette/models/Commission';

import { actions as CommissionsActions } from '@palette/state/Commissions';

import styles from './CommissionActivities.less';

const classNames = bindClassNames.bind(styles);

const CommissionActivities = ({ className, commission, thread }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const commentsBottomRef = useRef(null);

  useEffect(() => {
    commentsBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [commentsBottomRef, thread.comments]);

  const [nbMessages, nbEvents, commentsNodes] = useMemo(() => {
    let messagesCount = 0;
    let eventsCount = 0;

    const threadCommentNodes = thread.comments.map((comment) => {
      if (comment.type === TYPES.MESSAGE) {
        messagesCount += 1;
      } else {
        eventsCount += 1;
      }

      return (
        <ThreadComment className={styles.comment} key={comment.id} thread={thread} comment={comment} />
      );
    });

    return [messagesCount, eventsCount, threadCommentNodes];
  }, [thread.comments]);

  const statusesOptions = useMemo(() => {
    if (thread.status === STATUSES.PENDING) {
      return [
        {
          label: (
            <div className={styles.resolvedStatus}>
              <CheckFilled className={styles.statusIcon} width={16} height={16} />
              {t('thread.statuses.setTo', { status: '$t(thread.statuses.resolved)' })}
            </div>
          ),
          value: STATUSES.RESOLVED,
        },
      ];
    }

    return [
      {
        label: (
          <div className={styles.resolvedStatus}>
            <InfoCircleFilled className={styles.statusIcon} width={16} height={16} />
            {t('thread.statuses.setTo', { status: '$t(thread.statuses.pending)' })}
          </div>
        ),
        value: STATUSES.PENDING,
      },
    ];
  }, [thread]);

  const handleSelectStatus = (status) => {
    dispatch(CommissionsActions.setStatus({ commissionId: commission.id, status }));
  };

  let statusIcon = (
    <InfoCircleFilled className={styles.statusIcon} width={16} height={16} />
  );
  if (thread.status === STATUSES.RESOLVED) {
    statusIcon = (
      <CheckFilled className={styles.statusIcon} width={16} height={16} />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infos}>
        <div className={styles.changeStatus}>
          {statusIcon}
          <Select
            className={styles.statusSelect}
            dropdownClassName={styles.statusSelectDropdown}
            bordered={false}
            options={statusesOptions}
            value={t(`thread.statuses.${thread.status.toLowerCase()}`)}
            onSelect={handleSelectStatus}
          />
        </div>
        <div className={styles.stats}>
          <div className={styles.stat}>
            {nbMessages}
            <ChatFilled className={styles.icon} width={12} height={12} />
          </div>
          <div className={styles.stat}>
            {nbEvents}
            <PenFilled className={styles.icon} width={12} height={12} />
          </div>
        </div>
      </div>
      <div className={styles.comments}>
        {commentsNodes}
        <div ref={commentsBottomRef} />
      </div>
    </div>
  );
};

CommissionActivities.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  thread: ThreadModel.propTypes.isRequired,
};

CommissionActivities.defaultProps = {
  className: '',
};

export default CommissionActivities;
