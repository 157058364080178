import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './DeactivateUserModal.less';

const DeactivateUserModal = ({ visible, onClose, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deactivateUserIsPending = useSelector(UsersSelectors.deactivateUserIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
  };

  const handleDeactivateUser = useCallback(() => {
    dispatch(UsersActions.deactivateUser({ userId: user.id, onSuccessCB }));
  }, [user]);

  return (
    <Modal
      className={styles.modal}
      title={t('deactivateUserModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDeactivateUser}
      okText={t('deactivateUserModal.confirm')}
      loading={deactivateUserIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('deactivateUserModal.content', { displayName: user.displayName }) }} />
    </Modal>
  );
};

DeactivateUserModal.propTypes = {
  user: MetaUserModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

DeactivateUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default DeactivateUserModal;
