/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { convertAnyValueToMoment, isValueValidMomentableDate } from '@palette/helpers/MomentHelper';

import styles from './FormulaValue.less';

const classNames = bindClassNames.bind(styles);

const FormulaValue = ({ className, value }) => {
  let type = typeof (value);

  if (type === 'object' || type === 'function') return null;

  let finalValue = value;

  if (type === 'number' && !Number.isInteger(value)) {
    type = 'float';
    finalValue = floatToFixedNumber(value, 4);
  }

  if (isValueValidMomentableDate(value)) {
    type = 'date';
    finalValue = convertAnyValueToMoment(value, true).format('lll');
  }

  return (
    <span
      className={classNames({
        wrapper: true,
        [type]: true,
        [className]: className !== '',
      })}
    >
      {`(${finalValue})`}
    </span>
  );
};

FormulaValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
};

FormulaValue.defaultProps = {
  className: '',
};

export default FormulaValue;
