import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SelectUserResourcesModal from '@palette/components/resources/SelectUserResourcesModal/SelectUserResourcesModal';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

const LinkResourcesToUserModal = ({ visible, onClose, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const linkResourcesToUserIsPending = useSelector(UsersSelectors.linkResourcesToUserIsPending);

  const handleLinkResourcesToUser = useCallback((resourcesToLink, cleanAndClose) => {
    dispatch(UsersActions.linkResourcesToUser({ userId: user.id, resourcesToLink, onSuccessCB: cleanAndClose }));
  }, [user]);

  return (
    <SelectUserResourcesModal
      title={t('linkResourcesToUserModal.title', { displayName: user.displayName })}
      visible={visible}
      onClose={onClose}
      onOk={handleLinkResourcesToUser}
      okText={t('linkResourcesToUserModal.linkResources')}
      loading={linkResourcesToUserIsPending}
    />
  );
};

LinkResourcesToUserModal.propTypes = {
  user: MetaUserModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

LinkResourcesToUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default LinkResourcesToUserModal;
