import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Popover from '@palette/components/designSystem/Popover/Popover';
import Tag from '@palette/components/designSystem/Tag/Tag';
import AvatarGroup from '@palette/components/user/AvatarGroup/AvatarGroup';
import Link from '@palette/components/designSystem/Link/Link';
import TeamsListItemMoreOptionsMenu from '@palette/components/team/TeamsListItemMoreOptionsMenu/TeamsListItemMoreOptionsMenu';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getTeamMembersMetaUsers } from '@palette/helpers/TeamHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import routePaths from '@palette/config/routePaths';

import * as TeamModel from '@palette/models/Team';

import { actions as TeamsActions } from '@palette/state/Teams';

import styles from './TeamsWithSubTeamsListItem.less';

const classNames = bindClassNames.bind(styles);

const TeamsWithSubTeamsListItem = ({ className, team }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const nameNode = useMemo(() => (
    <Link
      className={styles.name}
      maxWidth="20"
      displayIcon
      path={routePaths.v2.teamDetails}
      params={{ teamId: team.team.id }}
    >
      {team.team.name}
    </Link>
  ), [team]);

  const subTeamsNode = useMemo(() => {
    if (team.subTeams.length === 0) {
      return (
        <div className={styles.subTeamsWrapper}>
          {t('teamsListItem.noSubTeams')}
        </div>
      );
    }

    const popoverContentNode = (
      <div className={styles.popoverContentWrapper}>
        <div className={styles.popoverContentTitle}>
          {t('teamsListItem.subTeamsList')}
        </div>
        <div className={styles.subTeamListWrapper}>
          {
            team.subTeams.map((subTeam) => (
              <div key={subTeam.team.id} className={styles.subTeamItem}>
                {subTeam.team.name}
              </div>
            ))
          }
        </div>
      </div>
    );

    return (
      <div className={styles.subTeamsWrapper}>
        <Popover
          trigger="hover"
          content={popoverContentNode}
        >
          <div>
            <Tag
              className={styles.subTeamsTag}
              theme={TAG_THEMES.LAVENDER}
              label={t('teamsListItem.subTeamsCount', { count: team.subTeams.length })}
            />
          </div>
        </Popover>
      </div>
    );
  }, [team]);

  const membersNode = useMemo(() => {
    const membersMetaUsers = getTeamMembersMetaUsers(team.team);

    return (
      <div className={styles.members}>
        <AvatarGroup
          users={membersMetaUsers}
          nbToDisplay={5}
          totalUsers={team.team.members.length}
        />
      </div>
    );
  }, [team]);

  const handleTeamUpdate = useCallback(() => {
    if (team.team.parentId !== null) {
      dispatch(TeamsActions.getTeamById({ teamId: team.team.parentId }));
      redirectTo({ path: routePaths.v2.teamDetails, params: { teamId: team.team.parentId } });
    } else {
      dispatch(TeamsActions.getList());
      redirectTo({ path: routePaths.v2.teams });
    }
  }, [team]);

  const [moreMenuIsOpen, setMoreMenuIsOpen] = useState(false);

  const handleMoreMenuOpenChange = useCallback((isOpen) => {
    setMoreMenuIsOpen(isOpen);
  }, [setMoreMenuIsOpen]);

  const moreOptionsMenuNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.MANAGE])) return null;

    return (
      <div
        className={classNames({
          moreMenuWrapper: true,
          moreMenuIsOpen,
        })}
      >
        <TeamsListItemMoreOptionsMenu
          className={styles.moreMenu}
          team={team.team}
          onUpdateTeam={handleTeamUpdate}
          onOpenChange={handleMoreMenuOpenChange}
        />
      </div>
    );
  }, [team, moreMenuIsOpen, handleTeamUpdate, handleMoreMenuOpenChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {nameNode}
      {subTeamsNode}
      {membersNode}
      {moreOptionsMenuNode}
    </div>
  );
};

TeamsWithSubTeamsListItem.propTypes = {
  className: PropTypes.string,
  team: TeamModel.withSubTeamsPropTypes.isRequired,
};

TeamsWithSubTeamsListItem.defaultProps = {
  className: '',
};

export default TeamsWithSubTeamsListItem;
