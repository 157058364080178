import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import OnboardUsersFlowPageGeneralContent from '@palette/components/userOnboarding/OnboardUsersFlowPageGeneralContent/OnboardUsersFlowPageGeneralContent';

import { ONBOARDING_USERS_FLOWS, ONBOARDING_USERS_MANUAL_INPUT_FLOW_FIRST_STEP } from '@palette/constants/user';

import { actions as UsersActions } from '@palette/state/Users';

const OnboardUsersManualInputFlowPageContent = ({ className }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: ONBOARDING_USERS_FLOWS.MANUAL_INPUT, onboardUsersCurrentFlowStep: ONBOARDING_USERS_MANUAL_INPUT_FLOW_FIRST_STEP }));
  }, []);

  return (
    <OnboardUsersFlowPageGeneralContent className={className} />
  );
};

OnboardUsersManualInputFlowPageContent.propTypes = {
  className: PropTypes.string,
};

OnboardUsersManualInputFlowPageContent.defaultProps = {
  className: '',
};

export default OnboardUsersManualInputFlowPageContent;
