import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Spin as AntDSpin } from 'antd';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';

import styles from './Loader.less';

const classNames = bindClassNames.bind(styles);

const Loader = ({ children, className, ...otherprops }) => {
  const { t } = useTranslation();

  let contentNode = (
    <div className={styles.empty} />
  );

  if (children !== null) {
    contentNode = children;
  }

  return (
    <AntDSpin
      wrapperClassName={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      tip={t('loading')}
      indicator={<LoadingLine className={styles.icon} width={24} height={24} spin />}
      {...otherprops}
    >
      {contentNode}
    </AntDSpin>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

Loader.defaultProps = {
  className: '',
  children: null,
};

export default Loader;
