import { useMemo } from 'react';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getMoment } from '@palette/helpers/MomentHelper';

import { USER_VARIABLE_DATE_FILTER_QS_KEY } from '@palette/constants/user';

export const useUserVariableDateFilterInSearch = () => {
  const moment = getMoment();

  const [date] = useKeyInSearch(USER_VARIABLE_DATE_FILTER_QS_KEY, null);

  return useMemo(() => {
    if (date === null) return moment.utc().format();

    return moment.utc(date).format();
  }, [date]);
};
