import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageBooleanAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as PlanRuleBracketModel from '@palette/models/PlanRuleBracket';
import * as MasterPlanVariableModel from '@palette/models/MasterPlanVariable';

import { RULE_TYPES } from '@palette/constants/masterPlans';

export const modelName = 'PlanRule';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  _id: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RULE_TYPES)),
  index: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  explanation: PropTypes.string,
  initFormula: PropTypes.string,
  percentage: PropTypes.bool,
  brackets: PropTypes.arrayOf(PlanRuleBracketModel.propTypes),
  variables: PropTypes.arrayOf(MasterPlanVariableModel.propTypes),
});

export const manageType = (planRule) => (RULE_TYPES[manageStringAttribute(planRule, 'type')] || RULE_TYPES.PER_OBJECT);

export const transform = (planRule) => {
  if (!planRule) {
    return null;
  }

  let brackets = PlanRuleBracketModel.transformList(manageArrayAttribute(planRule, 'brackets'));
  if (brackets.length === 0) {
    brackets = [PlanRuleBracketModel.transform({})];
  }

  return (
    {
      id: manageStringAttribute(planRule, '_id'),
      _id: manageStringAttribute(planRule, '_id', null),
      type: manageType(planRule),
      index: manageNumberAttribute(planRule, 'index'),
      name: manageStringAttribute(planRule, 'name'),
      description: manageStringAttribute(planRule, 'description'),
      explanation: manageStringAttribute(planRule, 'explanation'),
      initFormula: manageStringAttribute(planRule, 'initFormula'),
      percentage: manageBooleanAttribute(planRule, 'percentage', true),
      brackets,
      variables: MasterPlanVariableModel.transformList(manageArrayAttribute(planRule, 'variables')),
    }
  );
};

export const transformList = (planRules) => planRules.map((planRule) => transform(planRule));

export const merge = (rule1, rule2) => {
  if (!rule2) return rule1;
  if (!rule1) return rule2;

  return rule2;
};
