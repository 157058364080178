import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './OnboardUsersFlowStepWrapper.less';

const classNames = bindClassNames.bind(styles);

const OnboardUsersFlowStepWrapper = ({ className, title, subTitle, children }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <div className={styles.titleWrapper}>
      <h2>
        {title}
      </h2>
      <div className={styles.subTitle}>
        {subTitle}
      </div>
    </div>
    {children}
  </div>
);

OnboardUsersFlowStepWrapper.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

OnboardUsersFlowStepWrapper.defaultProps = {
  className: '',
};

export default OnboardUsersFlowStepWrapper;
