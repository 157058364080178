import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';

import { useOnboardUsersStepValue } from '@palette/hooks/UserHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import {
  getNextOnboardUsersManualInputFlowStep,
  getNextOnboardUsersFromResourcesFlowStep,
} from '@palette/helpers/UserHelper';

import { ONBOARDING_USERS_FLOWS } from '@palette/constants/user';

import routePaths from '@palette/config/routePaths';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './OnboardUsersFlowStepActions.less';

const classNames = bindClassNames.bind(styles);

const OnboardUsersFlowStepActions = ({
  className,
  disabled,
  disabledNext,
  onNextStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getUserResourcesCommonDataIsPending = useSelector(UsersSelectors.getUserResourcesCommonDataIsPending);
  const onboardUsersIsPending = useSelector(UsersSelectors.onboardUsersIsPending);
  const onboardCurrentUsers = useSelector(UsersSelectors.getOnboardCurrentUsers);
  const onboardUsersFlow = useSelector(UsersSelectors.getOnboardUsersFlow);
  const onboardUsersStepValue = useOnboardUsersStepValue();

  const nextOnboardUsersFlowStep = useMemo(() => {
    let getNextFunc = getNextOnboardUsersFromResourcesFlowStep;
    if (onboardUsersFlow === ONBOARDING_USERS_FLOWS.MANUAL_INPUT) {
      getNextFunc = getNextOnboardUsersManualInputFlowStep;
    }

    let nextFlowStep = getNextFunc(onboardUsersStepValue);
    while (
      nextFlowStep !== null
      && nextFlowStep.isDiscard()
    ) {
      nextFlowStep = getNextFunc(nextFlowStep.value);
    }

    return nextFlowStep;
  }, [onboardUsersFlow, onboardUsersStepValue]);

  const handleNextStep = useCallback(() => {
    if (nextOnboardUsersFlowStep === null) return;
    const onSuccessCB = () => {
      dispatch(UsersActions.setOnboardUsersCurrentFlowStep({ onboardUsersCurrentFlowStep: nextOnboardUsersFlowStep }));
    };

    if (onNextStep !== null) {
      onNextStep(onSuccessCB);
    } else {
      onSuccessCB();
    }
  }, [nextOnboardUsersFlowStep, onNextStep]);

  const handleFinish = useCallback(() => {
    const onSuccessCB = () => {
      redirectTo({ path: routePaths.v2.users });
    };

    dispatch(UsersActions.onboardUsers({ users: onboardCurrentUsers, onSuccessCB }));
  }, [onboardCurrentUsers]);

  const nextBtnNode = useMemo(() => {
    const loadingNextBtn = getUserResourcesCommonDataIsPending || onboardUsersIsPending;
    const disabledNextBtn = disabled || disabledNext || loadingNextBtn;

    if (nextOnboardUsersFlowStep === null) {
      return (
        <Button
          className={styles.nextBtn}
          onClick={handleFinish}
          disabled={disabledNextBtn}
          loading={loadingNextBtn}
        >
          {t('onboardUsersFlowStepActions.finish')}
        </Button>
      );
    }

    return (
      <Button
        className={styles.nextBtn}
        onClick={handleNextStep}
        disabled={disabledNextBtn}
        loading={loadingNextBtn}
      >
        {t('onboardUsersFlowStepActions.next')}
      </Button>
    );
  }, [
    getUserResourcesCommonDataIsPending,
    onboardUsersIsPending,
    disabled,
    disabledNext,
    nextOnboardUsersFlowStep,
    handleFinish,
    handleNextStep,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.row}>
        {nextBtnNode}
      </div>
    </div>
  );
};

OnboardUsersFlowStepActions.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disabledNext: PropTypes.bool,
  onNextStep: PropTypes.func,
};

OnboardUsersFlowStepActions.defaultProps = {
  className: '',
  disabled: false,
  disabledNext: false,
  onNextStep: null,
};

export default OnboardUsersFlowStepActions;
