import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import BoxFilled from '@palette/components/utils/Icons/BoxFilled';
import DollarFilled from '@palette/components/utils/Icons/DollarFilled';
import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';
import ProfileFilled from '@palette/components/utils/Icons/ProfileFilled';
import SettingsFilled from '@palette/components/utils/Icons/SettingsFilled';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';

import { ENTITY_TYPE } from '@palette/constants/auditTrail';

import styles from './AuditTrailIcon.less';

const classNames = bindClassNames.bind(styles);

const AuditTrailIcon = ({ className, entity, width, height }) => {
  const iconNode = useMemo(() => {
    const type = ENTITY_TYPE[entity];

    const props = {
      className: styles.icon,
      width,
      height,
    };

    switch (type) {
      case ENTITY_TYPE.SETTING:
        return <SettingsFilled {...props} />;
      case ENTITY_TYPE.USER:
        return <ProfileFilled {...props} />;
      case ENTITY_TYPE.RESOURCE:
        return <BoxFilled {...props} />;
      case ENTITY_TYPE.COMMISSION:
        return <DollarFilled {...props} />;
      case ENTITY_TYPE.STATEMENT:
        return <StatementsFilled {...props} />;
      default:
        return <MedicalChartFilled {...props} />;
    }
  }, [entity, width, height]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {iconNode}
    </div>
  );
};

AuditTrailIcon.propTypes = {
  className: PropTypes.string,
  entity: PropTypes.oneOf(Object.keys(ENTITY_TYPE)).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

AuditTrailIcon.defaultProps = {
  className: '',
  width: 24,
  height: 24,
};

export default AuditTrailIcon;
