import { ALERT_TYPES } from '@palette/constants/alert';

export const TEMPORARY_FADEOUT_DELAY = 3000;

export const NOTIF_CLEANUP_DELAY = 500;

export const GLOBAL_NOTIF_THEMES = ALERT_TYPES;

export const GLOBAL_NOTIF_TYPES = {
  PERMANENT: 'PERMANENT',
  TEMPORARY: 'TEMPORARY',
};
