import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import NotebooksBreadcrumb from '@palette/components/notebooks/NotebooksBreadcrumb/NotebooksBreadcrumb';
import NotebookDetailsPageContent from '@palette/components/notebooks/NotebookDetailsPageContent/NotebookDetailsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useNotebookIdInParams } from '@palette/hooks/NotebookHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import styles from './NotebookDetailsPage.less';

const NotebookDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { notebook } = useNotebookIdInParams();

  if (!hasFeature(profile, FEATURES.NOTEBOOKS) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.notebookDetailsTitle', { notebookName: notebook?.name })} />
      <NotebooksBreadcrumb className={styles.breadcrumb} notebook={notebook} />
      <NotebookDetailsPageContent className={styles.content} />
    </div>
  );
};

export default NotebookDetailsPage;
