import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import Select from '@palette/components/designSystem/Select/Select';
import UserStatus from '@palette/components/user/UserStatus/UserStatus';
import DeactivateUserButton from '@palette/components/userDetails/DeactivateUserButton/DeactivateUserButton';

import { useCompanyRoles } from '@palette/hooks/CompanyHooks';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './UserAccountSettings.less';

const classNames = bindClassNames.bind(styles);

const UserAccountSettings = ({ className, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateUserAccountIsPending = useSelector(UsersSelectors.updateUserAccountIsPending);

  const [form] = AntDForm.useForm();

  const companyRoles = useCompanyRoles();
  const rolesOptions = useMemo(() => (
    companyRoles.map((role) => ({
      label: role.name,
      value: role.id,
    }))
  ), [companyRoles]);

  const initialValues = useMemo(() => {
    const defaultRole = companyRoles.find((role) => (role.isDefault));
    const defaultRoleOption = rolesOptions.find((roleOption) => (roleOption.value === defaultRole.id));

    return {
      email: user?.account?.email,
      role: user?.account?.roleId ?? defaultRoleOption?.value,
    };
  }, [user, companyRoles, rolesOptions]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
    form.resetFields();
  }, [initialValues]);

  const handleSubmitForm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleFinish = useCallback(({ email, role }) => {
    dispatch(UsersActions.updateUserAccount({
      userId: user.id,
      email,
      role,
    }));
  }, [form]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setFormValues(allValues);
  }, [form]);

  const formIsPristine = useMemo(() => (
    _isEqual(initialValues, formValues)
  ), [initialValues, formValues]);

  const contentNode = useMemo(() => {
    if (user.account === null) {
      return (
        <div className={styles.noAccountWrapper}>
          <div className={styles.noAccount}>
            {t('userAccountSettings.noAccount')}
          </div>
          <UserStatus user={user} />
        </div>
      );
    }

    return (
      <Form
        className={styles.form}
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        onValuesChange={handleFormValuesChange}
      >
        <FormItem
          className={styles.formItem}
          name="email"
          label={t('userAccountSettings.form.email.label')}
          required
          rules={[
            { required: true, message: t('userAccountSettings.form.email.rules.required') },
            { type: 'email', warningOnly: false, message: t('userAccountSettings.form.email.rules.email') },
          ]}
        >
          <Input size="big" disabled={updateUserAccountIsPending} />
        </FormItem>
        <FormItem
          className={styles.formItem}
          name="role"
          label={t('userAccountSettings.form.role.label')}
          required
        >
          <Select size="big" options={rolesOptions} disabled={updateUserAccountIsPending} />
        </FormItem>
        <Button className={styles.submitBtn} onClick={handleSubmitForm} disabled={formIsPristine || updateUserAccountIsPending}>
          {t('userAccountSettings.form.submitBtnLabel')}
        </Button>
      </Form>
    );
  }, [
    user,
    form,
    initialValues,
    handleFinish,
    handleFormValuesChange,
    updateUserAccountIsPending,
    handleSubmitForm,
    formIsPristine,
  ]);

  const deactivateUserBtnNode = useMemo(() => {
    if (user.account === null) return null;

    return (
      <DeactivateUserButton user={user} />
    );
  }, [user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.titleActionWrapper}>
        <h2>{t('userAccountSettings.title')}</h2>
        {deactivateUserBtnNode}
      </div>
      {contentNode}
    </div>
  );
};

UserAccountSettings.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserAccountSettings.defaultProps = {
  className: '',
};

export default UserAccountSettings;
