import {
  BANNERS_HEIGHT,
  MENU_IS_COLLAPSED,
  OPENED_FOLDERS,
  CUSTOMIZE_DASHBOARD_PANEL_IS_CLOSE,
} from '@palette/constants/localStorage';

export const getLocalStorageItem = (item) => localStorage.getItem(item);
export const setLocalStorageItem = (item, value) => localStorage.setItem(item, value);

export const getMenuIsCollapsed = () => getLocalStorageItem(MENU_IS_COLLAPSED);
export const setMenuIsCollapsed = (isCollapsed) => setLocalStorageItem(MENU_IS_COLLAPSED, isCollapsed);

export const getBannersHeight = () => getLocalStorageItem(BANNERS_HEIGHT);
export const setBannersHeight = (bannersHeight) => setLocalStorageItem(BANNERS_HEIGHT, bannersHeight);

export const getOpenedFolders = () => getLocalStorageItem(OPENED_FOLDERS)?.split(',');
export const setOpenedFolders = (folderId) => {
  const folderIds = getOpenedFolders() || [];

  if (!folderIds.includes(folderId)) {
    folderIds.push(folderId);
    setLocalStorageItem(OPENED_FOLDERS, folderIds.join());
  }
};
export const removeOpenedFolders = (folderId) => {
  let folderIds = getOpenedFolders() || [];

  if (folderIds.includes(folderId)) {
    folderIds = folderIds.filter((id) => id !== folderId);
    setLocalStorageItem(OPENED_FOLDERS, folderIds.join());
  }
};

export const customizeDashboardPanelIsClose = () => getLocalStorageItem(CUSTOMIZE_DASHBOARD_PANEL_IS_CLOSE) === 'true';
export const closeCustomizeDashboardPanel = () => setLocalStorageItem(CUSTOMIZE_DASHBOARD_PANEL_IS_CLOSE, 'true');
