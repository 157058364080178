import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import EditableDashboardField from '@palette/components/quotaPerformance/EditableDashboardField/EditableDashboardField';
import AddPlansToDashboard from '@palette/components/quotaPerformance/AddPlansToDashboard/AddPlansToDashboard';
import RemoveDashboard from '@palette/components/quotaPerformance/RemoveDashboard/RemoveDashboard';
import QuotaPerformanceDetailContent from '@palette/components/quotaPerformance/QuotaPerformanceDetailContent/QuotaPerformanceDetailContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { scrollToTop } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { QUOTA_PERFORMANCE_UPDATABLE_FIELDS } from '@palette/constants/quotasPerformance';

import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';

import styles from './QuotaPerformanceDetails.less';

const classNames = bindClassNames.bind(styles);

const QuotaPerformanceDetails = ({ className }) => {
  const profile = useProfile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboardId } = useParams();

  const byIdIsPending = useSelector(QuotasPerformanceSelectors.getByIdIsPending);
  const dashboard = useSelector((state) => QuotasPerformanceSelectors.getDashboardById(state, { quotaPerformanceId: dashboardId }));

  useEffect(() => {
    if (dashboardId) {
      dispatch(QuotasPerformanceActions.getById({ quotaPerformanceId: dashboardId }));
    }
  }, [dashboardId]);

  useEffect(() => {
    if (!byIdIsPending) {
      scrollToTop();
    }
  }, [byIdIsPending]);

  const addPlansNode = useMemo(() => {
    if (!dashboard || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) return null;

    return (
      <AddPlansToDashboard dashboard={dashboard} />
    );
  }, [profile, dashboard]);

  const removeDashboardNode = useMemo(() => {
    if (!dashboard || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) return null;

    return (
      <RemoveDashboard className={styles.removeDashboardButton} dashboardId={dashboard.id} />
    );
  }, [profile, dashboard]);

  const contentNode = useMemo(() => {
    if (dashboard === null) {
      if (byIdIsPending) {
        return (
          <Loader className={styles.loaderContainer} />
        );
      }

      return null;
    }

    return (
      <>
        <MetaTag title={dashboard.name} />
        <div className={styles.header}>
          <div className={styles.infos}>
            <EditableDashboardField
              className={styles.title}
              dashboard={dashboard}
              field={QUOTA_PERFORMANCE_UPDATABLE_FIELDS.NAME}
              isRequired
            />
            <EditableDashboardField
              dashboard={dashboard}
              field={QUOTA_PERFORMANCE_UPDATABLE_FIELDS.DESCRIPTION}
              type="textarea"
              emptyText={t('quotasPerformance.details.header.emptyDescription')}
            />
          </div>
          <div className={styles.actions}>
            {addPlansNode}
            {removeDashboardNode}
          </div>
        </div>
        <QuotaPerformanceDetailContent className={styles.content} dashboard={dashboard} />
      </>
    );
  }, [dashboard, byIdIsPending, addPlansNode, removeDashboardNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

QuotaPerformanceDetails.propTypes = {
  className: PropTypes.string,
};

QuotaPerformanceDetails.defaultProps = {
  className: '',
};

export default QuotaPerformanceDetails;
