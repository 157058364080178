/* eslint-disable class-methods-use-this */
import React from 'react';
import flatten from 'flat';
import pluralize from 'pluralize';
import {
  Button, Card, Form, Input, Select, Space, Typography,
} from 'antd';
import { CloseOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Moment from 'moment';

import { connect } from 'react-redux';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ConnectorModel from '@palette/models/Connector';

import QueryBuilder from './queryBuilder';
import FormulaHelper from './formulaHelper';

import { dateFromMongoId, getDisplayType } from '../../services/utils';

import '../../styles/admin/reconciliation-details.less';

class ReconciliationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFormValues: this.props.initialValues,
      queryBuilderFields: {},
      runOnSubmit: true,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { refreshConnectorsListIsNeeded, refreshConnectorsList } = this.props;
    if (refreshConnectorsListIsNeeded) {
      refreshConnectorsList();
    }
  }

  handleFormFinish = (values) => {
    this.props.onFinish(values, this.state.runOnSubmit);
  };

  handleSave = (runOnSubmit = false) => {
    this.setState({
      runOnSubmit,
    }, () => {
      this.formRef.current.submit();
    });
  };

  renderFields({
    destinationObject,
    prevName,
    prevFieldKey,
    ...prevRestField
  }) {
    return (
      <Card
        size="small"
        title={`Create Custom Fields in ${destinationObject}`}
      >
        <Form.List
          {...prevRestField}
          name={[prevName, 'fields']}
          fieldKey={[prevFieldKey, 'matching']}
        >
          {(fields, {
            add,
            remove,
          }) => (
            <>
              {fields.map(({
                key,
                name,
                fieldKey,
                ...restField
              }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                    marginBottom: 8,
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    fieldKey={[fieldKey, 'name']}
                    label="Name the reconciliation"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'formula']}
                    fieldKey={[fieldKey, 'formula']}
                    label="Formula"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea style={{ minWidth: '340px' }} rows={1} />
                  </Form.Item>
                  <Button
                    size="small"
                    className="grey"
                    style={{
                      border: 'none',
                      top: 32,
                    }}
                    ghost
                    icon={<CloseOutlined />}
                    onClick={() => remove(name)}
                  />
                </Space>
              ))}
              <Form.Item noStyle>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Add a Field in
                  {' '}
                  {destinationObject}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Card>
    );
  }

  renderSteps({ connectorObjects }) {
    const {
      currentFormValues,
      queryBuilderFields,
    } = this.state;
    const destinationObject = getDisplayType({
      type: (currentFormValues.destinationConnectorObject || '')
        .split('_')
        .slice(1)
        .join('_'),
    });
    return (
      <Form.List name="steps">
        {(fields, {
          add,
          remove,
        }) => (
          <>
            {fields.map(({
              key,
              name,
              fieldKey,
              ...restField
            }, index) => {
              const stepObject = getDisplayType({
                type: (currentFormValues.steps[index]?.connectorObject || '')
                  .split('_')
                  .slice(1)
                  .join('_'),
              });
              const stepRelationship = currentFormValues.steps[index]?.relationship;
              return (
                <Card
                  key={key}
                  size="small"
                  title={(
                    <div className="flex">
                      <h3 className="width-100 bold" style={{ marginBottom: 0 }}>
                        {`Step #${index + 1}`}
                      </h3>
                      <Button
                        type="link"
                        size="small"
                        danger
                        onClick={() => remove(name)}
                        icon={<DeleteOutlined />}
                      >
                        Delete Step
                      </Button>
                    </div>
                  )}
                  style={{ marginBottom: 12 }}
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'connectorObject']}
                    fieldKey={[fieldKey, 'connectorObject']}
                    rules={[{ required: true }]}
                    label="Source"
                  >
                    <Select>
                      {connectorObjects.map(({
                        id,
                        type,
                        object,
                      }) => (
                        <Select.Option value={`${id}_${object}`} key={`${id}_${object}`}>
                          {`${object} (${type})`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {currentFormValues.steps[index]
                    && currentFormValues.steps[index].connectorObject
                    && (
                      <FormulaHelper
                        connectorId={currentFormValues.steps[index].connectorObject.split('_')[0]}
                        type={currentFormValues.steps[index].connectorObject.split('_').slice(1).join('_')}
                        onData={(data) => this.setState({
                          queryBuilderFields: {
                            ...queryBuilderFields,
                            [`steps${index}`]: Object.keys(flatten(data)),
                          },
                        })}
                        style={{ marginBottom: 24 }}
                      />
                    )}
                  <Form.Item
                    {...restField}
                    name={[name, 'query']}
                    fieldKey={[fieldKey, 'query']}
                    rules={[{ required: true }]}
                    label="Query"
                    valuePropName="query"
                    extra={destinationObject && stepObject ? (
                      <>
                        {'Best practise: use '}
                        <Typography.Text code>
                          {`XXX IN ${pluralize(destinationObject)}.map/reduce/...`}
                        </Typography.Text>
                        {` to filter ${pluralize(stepObject)} linked to the previous matched ${pluralize(destinationObject)}.`}
                      </>
                    ) : ''}
                  >
                    <QueryBuilder
                      prefix="data."
                      simplify
                      fields={queryBuilderFields[`steps${index}`] || []}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'relationship']}
                    fieldKey={[fieldKey, 'relationship']}
                    rules={[{ required: true }]}
                    label="Relationship"
                  >
                    <Select>
                      <Select.Option value="ONE_TO_ONE">1-to-1</Select.Option>
                      <Select.Option value="ONE_TO_MANY">1-to-many</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'matchingStrategy']}
                    fieldKey={[fieldKey, 'matchingStrategy']}
                    rules={[{ required: true }]}
                    label="Matching Strategy"
                  >
                    <Select>
                      <Select.Option value="ID_MATCHING">ID Matching</Select.Option>
                      <Select.Option value="FUNCTION">Function</Select.Option>
                    </Select>
                  </Form.Item>
                  {currentFormValues.steps[index]
                    && currentFormValues.steps[index].matchingStrategy === 'ID_MATCHING'
                    && (
                      <Form.Item>
                        <div className="flex flex-center">
                          <Form.Item
                            {...restField}
                            noStyle
                            name={[name, 'matchingDestinationFormula']}
                            fieldKey={[fieldKey, 'matchingDestinationFormula']}
                            rules={[{ required: true }]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder={destinationObject ? `${destinationObject}.XXX` : ''} />
                          </Form.Item>
                          <div
                            style={{
                              margin: '0 8px',
                              fontWeight: 'bold',
                              fontFamily: "'Montserrat'",
                              fontSize: 16,
                            }}
                          >
                            EQUALS
                          </div>
                          <Form.Item
                            {...restField}
                            noStyle
                            name={[name, 'matchingStepFormula']}
                            fieldKey={[fieldKey, 'matchingStepFormula']}
                            rules={[{ required: true }]}
                            style={{ flex: 1 }}
                          >
                            <Input placeholder={stepObject ? `${stepObject}.YYY` : ''} />
                          </Form.Item>
                        </div>
                      </Form.Item>
                    )}
                  {currentFormValues.steps[index]
                    && currentFormValues.steps[index].matchingStrategy === 'FUNCTION'
                    && (
                      <Form.Item
                        {...restField}
                        name={[name, 'matchingFunction']}
                        fieldKey={[fieldKey, 'matchingFunction']}
                        rules={[{ required: true }]}
                      >
                        <Input
                          addonBefore={`(${currentFormValues.destinationConnectorObject.split('_').slice(1).join('_') || 'A'}, ${(currentFormValues.steps[index].connectorObject || '').split('_').slice(1).join('_') || 'B'}) =>`}
                        />
                      </Form.Item>
                    )}
                  <Form.Item
                    {...restField}
                    name={[name, 'filter']}
                    fieldKey={[fieldKey, 'filter']}
                    label="Filter (once the reconciliation is done)"
                  >
                    <Input placeholder={destinationObject && stepObject && stepRelationship ? `Ex: ${stepRelationship === 'ONE_TO_ONE' ? '!!' : ''}${destinationObject}.${stepRelationship === 'ONE_TO_ONE' ? stepObject : `${pluralize(stepObject)}.length > 0`}` : ''} />
                  </Form.Item>
                  {this.renderFields({
                    destinationObject,
                    prevName: name,
                    prevFieldKey: fieldKey,
                    ...restField,
                  })}
                </Card>
              );
            })}
            <Form.Item>
              <Button
                icon={<PlusOutlined />}
                onClick={() => add()}
              >
                Add Step
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  }

  render() {
    const {
      currentFormValues,
      queryBuilderFields,
    } = this.state;
    const connectorObjects = this.props.connectors
      .map((connector) => connector.resources.map((object) => ({
        id: connector.id,
        type: connector.type,
        object: object.originalType,
      })))
      .flat();
    return (
      <Form
        ref={this.formRef}
        initialValues={currentFormValues}
        layout="vertical"
        onFinish={this.handleFormFinish}
        onValuesChange={(x, values) => this.setState({ currentFormValues: values })}
        style={{ marginTop: '12px' }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="destinationConnectorObject"
          label="Destination (where you want to add custom fields)"
          rules={[{ required: true }]}
        >
          <Select>
            {connectorObjects.map(({
              id,
              type,
              object,
            }) => (
              <Select.Option value={`${id}_${object}`} key={`${id}_${object}`}>
                {`${object} (${type})`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {currentFormValues.destinationConnectorObject && (
          <FormulaHelper
            connectorId={currentFormValues.destinationConnectorObject.split('_')[0]}
            type={currentFormValues.destinationConnectorObject.split('_').slice(1).join('_')}
            onData={(data) => this.setState({
              queryBuilderFields: {
                ...queryBuilderFields,
                destination: Object.keys(flatten(data)),
              },
            })}
            style={{ marginBottom: 24 }}
          />
        )}
        <Form.Item
          name="destinationFilter"
          label="Query"
          rules={[{ required: true }]}
          valuePropName="query"
        >
          <QueryBuilder
            prefix="data."
            simplify
            fields={queryBuilderFields.destination || []}
          />
        </Form.Item>
        {this.renderSteps({ connectorObjects })}
        <div className="flex" style={{ alignItems: 'center' }}>
          <div className="flex" style={{ flexGrow: 1, alignItems: 'center' }}>
            {
              this.props.lastJob && (
                <>
                  {`Last execution: ${Moment(dateFromMongoId(this.props.lastJob._id)).format('YYYY-MM-DD @ HH:mm')} (${this.props.lastJob.status})`}
                  <div className={`ReconciliationStatus ${this.props.lastJob.status}`} />
                </>
              )
            }
          </div>
          <div className="flex" style={{ alignItems: 'center' }}>
            {
              this.props.initialValues.name !== '' && (
                <Form.Item style={{ margin: 0 }}>
                  <Button htmlType="button" disabled={this.props.isRunning} onClick={() => this.handleSave(false)} style={{ marginRight: '10px' }}>
                    Save
                  </Button>
                </Form.Item>
              )
            }
            <Form.Item style={{ margin: 0 }}>
              <Button type="primary" htmlType="button" loading={this.props.isRunning} onClick={() => this.handleSave(true)}>
                {this.props.initialValues.name === '' ? 'Create' : 'Save and Run'}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  }
}

ReconciliationForm.propTypes = {
  initialValues: PropTypes.object,
  lastJob: PropTypes.object,
  onFinish: PropTypes.func.isRequired,
  isRunning: PropTypes.bool.isRequired,
  connectors: PropTypes.arrayOf(ConnectorModel.propTypes).isRequired,
  refreshConnectorsListIsNeeded: PropTypes.bool.isRequired,
  refreshConnectorsList: PropTypes.func.isRequired,
};

ReconciliationForm.defaultProps = {
  initialValues: {
    filter: { $and: [] },
    steps: [],
  },
  lastJob: null,
};

const mapStateToProps = (state) => ({
  connectors: ConnectorsSelectors.getConnectorsList(state),
  refreshConnectorsListIsNeeded: ConnectorsSelectors.refreshListIsNeeded(state),
});

const mapDispatchToProps = (dispatch) => ({
  refreshConnectorsList: () => dispatch(ConnectorsActions.refreshList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReconciliationForm);
