import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import CurvedLine from '@palette/components/utils/Icons/CurvedLine';
import MyUserStatementPlanPeriodDealsTable from '@palette/components/ic/myStatement/MyUserStatementPlanPeriodDealsTable/MyUserStatementPlanPeriodDealsTable';

import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as UserStatementPlanPeriodModel from '@palette/models/UserStatementPlanPeriod';

import styles from './MyUserStatementPlanPeriod.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementPlanPeriod = ({ className, userStatementPlanPeriod, plan, searchedDeal, inSalesforce }) => {
  const { t } = useTranslation();

  const periodLinkNode = useMemo(() => {
    if (inSalesforce) return null;

    return (
      <Link
        path={routePaths.v2.myPlanPeriodDetails}
        params={{ masterPlanId: plan.id, year: userStatementPlanPeriod.year, periodId: userStatementPlanPeriod.period }}
        displayIcon
      >
        {t('userStatementPlanPeriod.seePeriodDetails')}
      </Link>
    );
  }, [plan, userStatementPlanPeriod, inSalesforce]);

  const periodInfosNode = useMemo(() => {
    const periodName = getPlanPeriodName(plan, { year: userStatementPlanPeriod.year, period: userStatementPlanPeriod.period });

    return (
      <div className={styles.infosWrapper}>
        <div className={styles.nameLinkWrapper}>
          <CurvedLine className={styles.curvedLineIcon} />
          <div className={styles.name}>
            {periodName}
          </div>
          {periodLinkNode}
        </div>
        <div className={styles.totalWrapper}>
          <div className={styles.totalLabel}>
            {t('userStatementPlanPeriod.total')}
          </div>
          <div className={styles.totalAmount}>
            {formatPrice(userStatementPlanPeriod.totalAmount, userStatementPlanPeriod.currency)}
          </div>
        </div>
      </div>
    );
  }, [plan, userStatementPlanPeriod, periodLinkNode]);

  const periodDealsTableNode = useMemo(() => (
    <MyUserStatementPlanPeriodDealsTable
      className={styles.dealsTable}
      plan={plan}
      userStatementPlanPeriod={userStatementPlanPeriod}
      searchedDeal={searchedDeal}
      inSalesforce={inSalesforce}
    />
  ), [plan, userStatementPlanPeriod, searchedDeal, inSalesforce]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {periodInfosNode}
      {periodDealsTableNode}
    </div>
  );
};

MyUserStatementPlanPeriod.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  userStatementPlanPeriod: UserStatementPlanPeriodModel.propTypes.isRequired,
  searchedDeal: PropTypes.string,
  inSalesforce: PropTypes.bool,
};

MyUserStatementPlanPeriod.defaultProps = {
  className: '',
  searchedDeal: '',
  inSalesforce: false,
};

export default MyUserStatementPlanPeriod;
