import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CardItem from '@palette/components/settings/CardItem/CardItem';
import DollarLine from '@palette/components/utils/Icons/DollarLine';
import Tag from '@palette/components/designSystem/Tag/Tag';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import AddCompanyCurrencyButton from '@palette/components/company/AddCompanyCurrencyButton/AddCompanyCurrencyButton';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TAG_THEMES } from '@palette/constants/tag';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './CompanyCurrencies.less';

const classNames = bindClassNames.bind(styles);

const CompanyCurrencies = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company } = profile.userData;

  const updateCompanyIsPending = useSelector(ProfileSelectors.updateCompanyIsPending);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const updateCurrencies = (currencies) => dispatch(ProfileActions.updateCompany({ currencies }));

  const makeDefaultCurrency = useCallback((event, currencyCode) => {
    stopPropagation(event);

    const newCurrencies = company.currencies.map((currency) => ({
      code: currency.code,
      default: currency.code === currencyCode,
    }));

    updateCurrencies(newCurrencies);
  }, [company]);

  const deleteCurrency = useCallback((event, currencyCode) => {
    stopPropagation(event);

    const newCurrencies = company.currencies
      .filter((currency) => currency.code !== currencyCode)
      .map((currency) => ({
        code: currency.code,
        default: currency.isDefault,
      }));

    updateCurrencies(newCurrencies);
  }, [company]);

  const currenciesNode = useMemo(() => company.currencies.map((currency) => (
    <div className={styles.currencyLine} key={currency.code}>
      <div className={styles.currencyLineValue}>
        <Tag
          theme={TAG_THEMES.INFO}
          label={currency.code}
        />
        {currency.isDefault && (
          <span className={styles.default}>
            {t('companyCurrencies.currency.default')}
          </span>
        )}
      </div>
      <div>
        {!currency.isDefault && (
          <div className={styles.actions}>
            <Button
              className={styles.actionButton}
              type="link"
              onClick={(event) => makeDefaultCurrency(event, currency.code)}
              disabled={updateCompanyIsPending}
            >
              {t('companyCurrencies.currency.makeDefault')}
            </Button>
            <Popconfirm
              title={t('companyCurrencies.currency.delete.popconfirm')}
              onConfirm={(event) => deleteCurrency(event, currency.code)}
              okText={t('common.global.yes')}
              cancelText={t('common.global.no')}
              size="small"
            >
              <Button
                className={styles.actionButton}
                type="linkDestroy"
                icon={<TrashFilled width={16} height={16} />}
                disabled={updateCompanyIsPending}
              />
            </Popconfirm>
          </div>
        )}
      </div>
    </div>
  )), [company, updateCompanyIsPending, makeDefaultCurrency, deleteCurrency]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyCurrencies.block.title')}
      icon={<DollarLine width={20} height={20} />}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.textContent}>{t('companyCurrencies.content.text')}</div>
        {currenciesNode}
        <AddCompanyCurrencyButton />
      </div>
    </CardItem>
  );
};

CompanyCurrencies.propTypes = {
  className: PropTypes.string,
};

CompanyCurrencies.defaultProps = {
  className: '',
};

export default CompanyCurrencies;
