import PropTypes from 'prop-types';

import {
  manageDateAttribute, manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as UserVariableModel from '@palette/models/UserVariable';

export const modelName = 'UserVariableOverrideItem';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  date: PropTypes.string,
  value: UserVariableModel.propTypesShape.defaultValue,
});

export const transform = (userVariableOverrideItem, type) => {
  if (!userVariableOverrideItem) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(userVariableOverrideItem, '_id'),
      date: manageDateAttribute(userVariableOverrideItem, 'date'),
      value: UserVariableModel.manageUserVariableValue(userVariableOverrideItem.value ?? '', type),
    }
  );
};

export const transformList = (userVariableOverrideItems, type) => userVariableOverrideItems.map((userVariableOverrideItem) => transform(userVariableOverrideItem, type));
