import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';

import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import TeamSelector from '@palette/components/team/TeamSelector/TeamSelector';
import Input from '@palette/components/designSystem/Input/Input';
import Table from '@palette/components/designSystem/Table/Table';
import Loader from '@palette/components/utils/Loader/Loader';
import BarsFilled from '@palette/components/utils/Icons/BarsFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Checkbox from '@palette/components/designSystem/Checkbox/Checkbox';
import UserStatus from '@palette/components/user/UserStatus/UserStatus';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import UserLinkedResources from '@palette/components/user/UserLinkedResources/UserLinkedResources';

import { useCompanyMetaUsers } from '@palette/hooks/UserHooks';

import { filterOnlyActiveUsers } from '@palette/helpers/UserHelper';

import { selectors as UsersSelectors } from '@palette/state/Users';
import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './UsersPageContent.less';

const classNames = bindClassNames.bind(styles);

const UsersPageContent = ({ className }) => {
  const { t } = useTranslation();

  const usersListIsPending = useSelector(UsersSelectors.getListIsPending);
  const companyMetaUsers = useCompanyMetaUsers();

  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [searchedUser, setSearchedUser] = useState('');
  const [onlyActiveUsers, setOnlyActiveUsers] = useState(true);

  const selectedTeam = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId: selectedTeamId }));

  const data = useMemo(() => {
    let listedUsers = companyMetaUsers;

    if (selectedTeam !== null) {
      const membersIds = selectedTeam.members.map((teamUser) => teamUser.user.id);
      listedUsers = listedUsers.filter((metaUser) => membersIds.includes(metaUser.id));
    }

    if (onlyActiveUsers) {
      listedUsers = filterOnlyActiveUsers(listedUsers);
    }

    if (searchedUser !== '') {
      listedUsers = listedUsers.filter((metaUser) => (
        metaUser.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
        || metaUser.email.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
      ));
    }

    return _orderBy(listedUsers, (user) => (user.displayName.toLowerCase()));
  },
  [
    companyMetaUsers,
    searchedUser,
    selectedTeamId,
    selectedTeam,
    onlyActiveUsers,
  ]);

  const columns = useMemo(() => {
    const cols = [];

    const userProfileColumn = {
      id: 'users',
      Header: t('users_other'),
      accessor: (user) => user,
      minWidth: 300,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: metaUser }) => (<UserProfile user={metaUser} clickable />),
    };
    cols.push(userProfileColumn);

    const emailColumn = {
      id: 'email',
      Header: t('user.email'),
      accessor: 'email',
      minWidth: 290,
    };
    cols.push(emailColumn);

    const statusColumn = {
      id: 'status',
      Header: t('user.status'),
      accessor: (user) => user,
      minWidth: 250,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: metaUser }) => (<UserStatus user={metaUser} />),
    };
    cols.push(statusColumn);

    const linkedResourcesColumn = {
      id: 'linkedResources',
      Header: t('user.linkedResources'),
      accessor: (user) => user,
      minWidth: 450,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: metaUser }) => (<UserLinkedResources user={metaUser} />),
    };
    cols.push(linkedResourcesColumn);

    return cols;
  }, []);

  const resetFilters = useCallback(() => {
    setSelectedTeamId(null);
    setSearchedUser('');
  }, []);

  const handleOnlyActiveUsersCheckboxChange = useCallback((e) => {
    setOnlyActiveUsers(e.target.checked);
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.filters}>
        <TeamSelector
          selectedTeam={selectedTeamId}
          onTeamSelected={setSelectedTeamId}
        />
        <Input
          className={styles.search}
          type="search"
          placeholder={t('usersPageContent.userSearch.placeholder')}
          onChange={setSearchedUser}
          value={searchedUser}
        />
        <div className={styles.onlyActiveFilter}>
          <Checkbox
            checked={onlyActiveUsers}
            onChange={(e) => handleOnlyActiveUsersCheckboxChange(e)}
          />
          <div className={styles.onlyActiveFilterLabel}>
            {t('usersPageContent.onlyActiveFilter.label')}
          </div>
        </div>
        {
          (selectedTeamId !== null || searchedUser !== '') && (
            <Button
              className={styles.resetFiltersBtn}
              type="link"
              icon={<BarsFilled width={24} height={24} />}
              onClick={resetFilters}
            >
              {t('usersPageContent.resetFilters.label')}
            </Button>
          )
        }
      </div>
      <Loader spinning={usersListIsPending}>
        <div className={styles.table}>
          <Table columns={columns} data={data} nbOfFixedColumns={1} stickyHeader fitInContainer />
          {
            data.length === 0 && (
              <DefaultEmptyState className={styles.emptyState} />
            )
          }
        </div>
      </Loader>
    </div>
  );
};

UsersPageContent.propTypes = {
  className: PropTypes.string,
};

UsersPageContent.defaultProps = {
  className: '',
};

export default UsersPageContent;
