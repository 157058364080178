import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import StatementV3PeriodsListItem from '@palette/components/statementV3/StatementV3PeriodsListItem/StatementV3PeriodsListItem';

import { usePeriodsDatesFilter } from '@palette/hooks/StatementHooks';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import styles from './StatementV3PeriodsList.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PeriodsList = ({ className, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [, from, to] = usePeriodsDatesFilter();

  const listPeriods = useSelector(StatementV3Selectors.getListPeriods);
  const listPeriodsIsPending = useSelector(StatementV3Selectors.getListPeriodsIsPending);

  useEffect(() => {
    dispatch(StatementV3Actions.getListPeriods({ currency, from, to }));
  }, [currency, from, to]);

  const contentNode = useMemo(() => {
    if (listPeriodsIsPending) return <Loader />;

    if (listPeriods.length === 0) {
      return (
        <DefaultEmptyState description={t('statementPeriodsList.noPeriods')} />
      );
    }

    return listPeriods.map((statementPeriod, index) => (
      <StatementV3PeriodsListItem key={`${statementPeriod.periodId}-${index}`} className={styles.period} statementPeriod={statementPeriod} />
    ));
  }, [listPeriodsIsPending, listPeriods, currency]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementV3PeriodsList.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
};

StatementV3PeriodsList.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3PeriodsList;
