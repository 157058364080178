import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import UsersVariablesPageContent from '@palette/components/usersVariables/UsersVariablesPageContent/UsersVariablesPageContent';
import CreateVariable from '@palette/components/usersVariables/CreateVariable/CreateVariable';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './UsersVariablesPage.less';

const UsersVariablesPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAllRights(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.USERS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.usersVariablesTitle')} />
      <PageTitle className={styles.titleWrapper}>
        <div className={styles.titlesContainer}>
          <h1>{t('usersVariablesPage.page.title')}</h1>
        </div>
        <div className={styles.actions}>
          <CreateVariable className={styles.newVariableBtn} />
        </div>
      </PageTitle>
      <UsersVariablesPageContent />
    </div>
  );
};

export default UsersVariablesPage;
