import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import routePaths from '@palette/config/routePaths';

import * as ResourceUpdateStrategyModel from '@palette/models/statementHistoryStrategies/ResourceUpdateStrategy';

const classNames = bindClassNames.bind();

const ResourceUpdateContentStrategy = ({ className, details, i18nAction }) => {
  const {
    resourceId,
    connectorId,
    resourceName,
    resourceType,
    fieldPath,
  } = details;

  const i18nActionComplement = fieldPath.length === 0 ? '.reset' : '';

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey={`statement.history.list.content.${i18nAction}${i18nActionComplement}`}
        values={{
          fieldPath: fieldPath.split('.').join(' > '),
          name: resourceName,
        }}
        components={[
          <Link
            key={i18nAction}
            path={routePaths.v2.resourceDetails}
            params={{
              connectorId,
              type: resourceType,
              resourceId,
            }}
          />,
        ]}
      />
    </span>
  );
};

ResourceUpdateContentStrategy.propTypes = {
  className: PropTypes.string,
  details: ResourceUpdateStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

ResourceUpdateContentStrategy.defaultProps = {
  className: '',
};

export default ResourceUpdateContentStrategy;
