const routePaths = {
  addConnector: '/add-connector',
  commissionPayments: '/commission-payments',
  connectorDetails: '/connectors/:connectorId',
  connectors: '/connectors',
  connectSalesforce: '/connect-salesforce',
  reconciliationDetails: '/reconciliations/:reconciliationId',
  reconciliations: '/reconciliations',
  reconciliationsAdd: '/reconciliations/add',
  register: '/register',
  v2: {
    // admin
    auditTrail: '/audit-trail',
    commissionList: '/commission-list',
    comparator: '/plans/comparator',
    createPlanFlow: '/plans/create-plan',
    dashboardPresets: '/dashboard-presets',
    dashboardPresetDetails: '/dashboard-presets/:presetId',
    notebooks: '/notebooks',
    notebookDetails: '/notebooks/:notebookId',
    onboardUsersFromResourcesFlow: '/users/onboard-users/from-resources',
    onboardUsersManualInputFlow: '/users/onboard-users/manual-input',
    planDetails: '/plans/:masterPlanId',
    planSettings: '/plans/:masterPlanId/settings',
    planPeriodDetails: '/plans/:masterPlanId/year/:year/period/:periodId',
    plans: '/plans',
    quotaDetails: '/quotas/:quotaId',
    quotas: '/quotas',
    quotasPerformance: '/quotas-performance',
    quotasPerformanceDetails: '/quotas-performance/:dashboardId',
    resources: '/resources',
    resourcesByConnectorId: '/resources/:connectorId/:type',
    resourceDetails: '/resources/:connectorId/:type/:resourceId',
    roleDetails: '/roles/:roleId',
    roles: '/roles',
    statements: '/statements',
    statementsPeriod: '/statements/:statementPeriodId',
    statementsUserStatement: '/statements/:statementPeriodId/user/:userId',
    statementsValidationsExport: '/statements-validations-export',
    StatementsInvestigateCorrection: '/statements/:statementPeriodId/user/:userId/investigate-correction/:correctionStatementId',
    teamDetails: '/teams/:teamId',
    teams: '/teams',
    userDetails: '/users/:userId',
    users: '/users',
    usersMerge: '/users/merge',
    variables: '/variables',

    // Plan V3
    planV3: '/plans-v3',
    planV3Create: '/plans-v3/create',
    planV3RequirementsById: '/plans-v3/:planId/requirements',
    planV3EditAchievementById: '/plans-v3/:planId/achievements',
    planV3EditCommissionById: '/plans-v3/:planId/commissions',

    // Statement V3
    statementV3: '/statements-v3',
    statementV3Period: '/statements-v3/:statementPeriodId',
    statementV3UserStatement: '/statements-v3/:statementPeriodId/statement',

    // global
    connectAs: '/connect-as/:sessionId',
    forgotPassword: '/forgot-password',
    login: '/login',
    maintenance: '/maintenance',
    resetPassword: '/reset-password/:resetPasswordToken',

    // ic
    myCommissionList: '/my-commission-list',
    myDashboard: '/my-dashboard',
    myPlans: '/my-plans',
    myPlanDetails: '/my-plans/:masterPlanId',
    myPlanPeriodDetails: '/my-plans/:masterPlanId/year/:year/period/:periodId',
    myStatementDetails: '/my-statements/:statementPeriodId',
    myStatements: '/my-statements',

    // ic V3
    myPlansV3: '/my-plans-v3',
    myPlansV3RequirementsById: '/my-plans-v3/:planId/requirements',
    myStatementsV3: '/my-statements-v3',
    myStatementsV3UserStatement: '/my-statements-v3/:statementPeriodId/statement',

    // Shared Admin + IC
    profile: '/profile',
    settings: '/settings',

    // sf
    sfStatementDetails: '/sf/:sessionId/:statementPeriodId',
  },
};

export default routePaths;
