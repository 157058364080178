import routePaths from '@palette/config/routePaths';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import { getSessionId } from '@palette/helpers/SessionHelper';

export const canSeeNPS = (profile) => profile && profile.shouldDisplayNPS === true;

const profileHasRight = (profile, right) => (
  profile.userData.account.role.rights[right]
  || (
    right.indexOf('admin.') === 0
    && profile.userData.account.role.rights[RIGHTS.ADMIN.WILDCARD]
  )
);

export const hasAtLeastOneRight = (profile, rights) => {
  if (!profile?.userData?.account?.role?.rights) return false;

  return rights.some((right) => profileHasRight(profile, right));
};

export const hasAllRights = (profile, rights) => {
  if (!profile?.userData?.account?.role?.rights) return false;

  return rights.every((right) => profileHasRight(profile, right));
};

export const hasAtLeastOneAdminRight = (profile) => {
  if (!profile?.userData?.account?.role?.rights) return false;

  return Object.keys(profile.userData.account.role.rights).some((right) => right.includes('admin'));
};

export const hasFeature = (profile, feature) => profile.userData.company?.features[feature] || false;

export const isSuperAdmin = () => {
  const ss = getSessionId();
  if (!ss || ss.length === 0) {
    return false;
  }
  return ss.substring(0, 5) === 'admin';
};

export const getDefaultRoute = (profile) => {
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]) && hasFeature(profile, FEATURES.COMPENSATION)) return routePaths.v2.statements;
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.OBJECTS.DISPLAY])) return routePaths.v2.resources;
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.DISPLAY])) return routePaths.v2.users;
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.CONNECTORS.DISPLAY])) return routePaths.connectors;
  if (hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION]) && hasFeature(profile, FEATURES.COMPENSATION)) return routePaths.v2.myDashboard;
  return routePaths.v2.settings;
};
