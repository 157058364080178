import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import UsersStatementsApprovalsListItem from '@palette/components/statement/statementPeriodUsers/UsersStatementsApprovalsListItem/UsersStatementsApprovalsListItem';
import UsersStatementsApprovalsListMoreAvatar from '@palette/components/statement/statementPeriodUsers/UsersStatementsApprovalsListMoreAvatar/UsersStatementsApprovalsListMoreAvatar';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES } from '@palette/constants/profile';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './UsersStatementsApprovalsList.less';

const classNames = bindClassNames.bind(styles);

const UsersStatementsApprovalsList = ({ className, userStatement, limitShown }) => {
  const profile = useProfile();

  const contentNode = useMemo(() => {
    const approvals = userStatement?.approvals;

    if (!approvals?.length) return null;

    const finalContent = [];
    const approvalsList = [...approvals];

    for (let approval = 0; approval < limitShown; approval += 1) {
      const currentApproval = approvalsList.shift();

      if (currentApproval) {
        finalContent.push(
          <UsersStatementsApprovalsListItem
            key={`approval-${approval}`}
            className={styles.avatarItemWrapper}
            approval={currentApproval}
          />,
        );
      }
    }

    if (approvalsList.length >= 1) {
      finalContent.push(
        <UsersStatementsApprovalsListMoreAvatar key="more-approvals" approvals={approvalsList} />,
      );
    }

    return finalContent;
  }, [userStatement]);

  const contentContainer = useMemo(() => {
    if (!contentNode) return null;

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
    );
  }, [contentNode]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION)) return null;

  return contentContainer;
};

UsersStatementsApprovalsList.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
  limitShown: PropTypes.number,
};

UsersStatementsApprovalsList.defaultProps = {
  className: '',
  userStatement: null,
  limitShown: 3,
};

export default UsersStatementsApprovalsList;
