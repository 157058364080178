import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import PlanV3DealAchievementsResults from '@palette/components/planV3/PlanV3DealAchievementsResults/PlanV3DealAchievementsResults';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3AchievementsResults.less';

const classNames = bindClassNames.bind(styles);

const PlanV3AchievementsResults = ({ className, details }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { planId } = details;

  const achievementsListIsPending = useSelector(PlanV3Selectors.getAchievementsListIsPending);
  const achievementsList = useSelector(PlanV3Selectors.getAchievementsList);

  useEffect(() => {
    if (planId && !achievementsListIsPending) {
      dispatch(PlanV3Actions.getAchievementsList({ planId }));
    }
  }, [planId]);

  const titleNode = useMemo(() => (
    <div className={styles.header}>
      <div className={styles.title}>{t('editPlanPage.results.achievements.title')}</div>
      <div className={styles.subtitle}>{t('editPlanPage.results.achievements.subtitle')}</div>
    </div>
  ), []);

  const contentNode = useMemo(() => (
    <div className={styles.content}>
      <PlanV3DealAchievementsResults className={styles.resultsBlock} achievementsList={achievementsList} />
    </div>
  ), [achievementsList]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapse}
        panels={[{
          title: titleNode,
          content: contentNode,
        }]}
        bordered={false}
        ghost
      />
    </div>
  );
};

PlanV3AchievementsResults.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
};

PlanV3AchievementsResults.defaultProps = {
  className: '',
};

export default PlanV3AchievementsResults;
