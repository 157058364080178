import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans, useTranslation } from 'react-i18next';

import * as GlobalErrorConstraintViolationStrategyModel from '@palette/models/globalNotifStrategies/global/GlobalErrorConstraintViolationStrategy';

const classNames = bindClassNames.bind();

const GlobalErrorConstraintViolationContentStrategy = ({ className, content }) => {
  const { t } = useTranslation();
  const { violations } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <ul>
        <li>{t('global.error.constraint.violation.content')}</li>
        {violations.map(({ field, errorMessage }, index) => (
          <li key={`${field}-${index}`}>
            <Trans
              i18nKey="global.error.constraint.violation.list"
              values={{ field, errorMessage }}
            />
          </li>
        ))}
      </ul>
    </span>
  );
};

GlobalErrorConstraintViolationContentStrategy.propTypes = {
  className: PropTypes.string,
  content: GlobalErrorConstraintViolationStrategyModel.propTypes.isRequired,
};

GlobalErrorConstraintViolationContentStrategy.defaultProps = {
  className: '',
};

export default GlobalErrorConstraintViolationContentStrategy;
