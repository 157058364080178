import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import CommonWidgetBase from '@palette/components/widgets/dashboard/CommonWidgetBase/CommonWidgetBase';

import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { formatWidgetPrice } from '@palette/helpers/DashboardHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as StatementOneMonthDataModel from '@palette/models/widgets/dashboard/StatementOneMonthData';

import routePaths from '@palette/config/routePaths';

import styles from './StatementOneMonthWidget.less';

const classNames = bindClassNames.bind(styles);

const StatementOneMonthWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const statementOneMonthData = StatementOneMonthDataModel.transform(widgetData.data);
  const periodName = getFrequencyPeriodName(PERIOD_TYPES.MONTH, 1, statementOneMonthData.period.year, statementOneMonthData.period.period, null);

  const handleClick = () => {
    redirectTo({ path: routePaths.v2.myStatements });
  };

  return (
    <CommonWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      title={t('common.statementOneMonthWidget.title')}
      subtitle={periodName}
      useBgColor
      readOnly={readOnly}
    >
      <div className={styles.content}>
        <div className={styles.amount}>
          {formatWidgetPrice(statementOneMonthData?.totalCommissionAmount, statementOneMonthData.currency)}
        </div>
        <Button
          type="secondary"
          className={styles.button}
          onClick={handleClick}
          disabled={readOnly && !enableLinks}
        >
          {t('common.statementOneMonthWidget.button')}
        </Button>
      </div>
    </CommonWidgetBase>
  );
};

StatementOneMonthWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

StatementOneMonthWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default StatementOneMonthWidget;
