import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Checkbox as AntDCheckbox } from 'antd';

import styles from './Checkbox.less';

const classNames = bindClassNames.bind(styles);

const Checkbox = ({ className, value, checked, ...otherProps }) => {
  let finalChecked = checked;
  if (value !== null) {
    finalChecked = value;
  }

  return (
    <AntDCheckbox
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      checked={finalChecked}
      {...otherProps}
    />
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.bool,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: '',
  value: null,
  checked: false,
};

export default Checkbox;
