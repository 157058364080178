import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import _groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';

import DataConnectionConnectorsList from '@palette/components/planV3/DataSourcesList/DataConnectionConnectorsList/DataConnectionConnectorsList';

import { selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './DataConnectionList.less';

const classNames = bindClassNames.bind(styles);

const DataConnectionList = ({ className, isPending }) => {
  const { t } = useTranslation();

  const dataConnections = useSelector(PlanV3Selectors.getDataConnections);

  const dataConnectionConnectorsListNode = useMemo(() => {
    const groupByConnectorId = _groupBy(dataConnections, (dataConnectionConnector) => (dataConnectionConnector.connectorId));

    return Object.entries(groupByConnectorId).map(([connectorId, dataConnectionConnectorsList], index) => {
      const { connectorName } = dataConnectionConnectorsList.find((list) => list.connectorId === connectorId);

      return (
        <DataConnectionConnectorsList
          key={connectorId}
          connectorName={connectorName}
          dataConnectionConnectorsList={NotebookDataConnectionConnectorModel.transformList(dataConnectionConnectorsList)}
          defaultOpen={index === 0}
          isPending={isPending}
        />
      );
    });
  }, [dataConnections]);

  const dataConnectionsListNode = useMemo(() => {
    if (dataConnections.length === 0) return null;

    return (
      <div className={styles.dataConnectionsList}>
        <div className={styles.title}>
          {t('createPlanPage.form.datasources.list.title')}
        </div>
        {dataConnectionConnectorsListNode}
      </div>
    );
  }, [dataConnections, dataConnectionConnectorsListNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {dataConnectionsListNode}
    </div>
  );
};

DataConnectionList.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
};

DataConnectionList.defaultProps = {
  className: '',
  isPending: false,
};

export default DataConnectionList;
