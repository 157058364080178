import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import SettingsFilled from '@palette/components/utils/Icons/SettingsFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import routePaths from '@palette/config/routePaths';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

import * as PlanV3ListModel from '@palette/models/PlanV3List';

import styles from './PlanV3ListItem.less';

const classNames = bindClassNames.bind(styles);

const PlanV3ListItem = ({ className, plan, disableActions }) => {
  const { t } = useTranslation();

  const nameNode = useMemo(() => (
    <div className={styles.nameWrapper}>
      <Link
        path={routePaths.v2.myPlansV3RequirementsById}
        params={{ planId: plan.id }}
      >
        {plan.name}
      </Link>
    </div>
  ), [plan]);

  const actionsNode = useMemo(() => {
    if (disableActions) return null;

    return (
      <Tooltip title={t('plansV3.linkTo.requirements.tooltip')}>
        <Link
          className={styles.actionButton}
          path={routePaths.v2.myPlansV3RequirementsById}
          params={{ planId: plan.id }}
        >
          <SettingsFilled className={styles.settings} />
        </Link>
      </Tooltip>
    );
  }, [disableActions, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.namePeriodWrapper}>
          <div className={styles.nameScopeWrapper}>
            {nameNode}
          </div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsWrapper}>
            <div className={styles.frequency}>
              {t(`plansV3.periodicity.${PLAN_PERIODICITY_TYPE[plan.periodicity]}`)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actionsWrapper}>
        {actionsNode}
      </div>
    </div>
  );
};

PlanV3ListItem.propTypes = {
  className: PropTypes.string,
  plan: PlanV3ListModel.propTypes.isRequired,
  disableActions: PropTypes.bool,
};

PlanV3ListItem.defaultProps = {
  className: '',
  disableActions: false,
};

export default PlanV3ListItem;
