import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CreateQuota from '@palette/components/quota/CreateQuota/CreateQuota';
import QuotaList from '@palette/components/quota/QuotaList/QuotaList';
import QuotaDetail from '@palette/components/quota/QuotaDetail/QuotaDetail';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { RIGHTS } from '@palette/constants/profile';

import styles from './QuotasPage.less';

const QuotasPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const createQuotaNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) return null;

    return (
      <CreateQuota className={styles.createQuotaButton} />
    );
  }, [profile]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.quotasTitle')} />
      <div className={styles.leftSide}>
        <div className={styles.header}>
          <h2 className={styles.title}>
            {t('quotasPage.title')}
          </h2>
          {createQuotaNode}
        </div>
        <div className={styles.list}>
          <QuotaList />
        </div>
      </div>
      <div className={styles.main}>
        <QuotaDetail />
      </div>
    </div>
  );
};

export default QuotasPage;
