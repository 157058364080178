import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodsDatesSelector from '@palette/components/statement/statementPeriod/StatementPeriodsDatesSelector/StatementPeriodsDatesSelector';
import StatementPeriodsCardsList from '@palette/components/statement/statementPeriod/StatementPeriodsCardsList/StatementPeriodsCardsList';

import styles from './StatementPeriodsNavigation.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodsNavigation = ({ className, currency, inUserStatement }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <StatementPeriodsDatesSelector className={styles.datesSelector} />
    <StatementPeriodsCardsList currency={currency} inUserStatement={inUserStatement} />
  </div>
);

StatementPeriodsNavigation.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  inUserStatement: PropTypes.bool,
};

StatementPeriodsNavigation.defaultProps = {
  className: '',
  currency: null,
  inUserStatement: false,
};

export default StatementPeriodsNavigation;
