import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Form as AntDForm } from 'antd';
import { useTranslation } from 'react-i18next';

import Form from '@palette/components/designSystem/Form/Form';
import PlanV3FormRequirementsGeneral from '@palette/components/ic/planV3/PlanV3FormRequirementsGeneral/PlanV3FormRequirementsGeneral';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as PlanV3ConfigurationModel from '@palette/models/PlanV3Configuration';

import styles from './PlanV3FormRequirements.less';

const classNames = bindClassNames.bind(styles);

const PlanV3FormRequirements = ({ className, configuration }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const [form] = AntDForm.useForm();

  const internalInitialValues = useMemo(() => ({
    name: configuration?.name ?? '',
    description: configuration?.description ?? '',
    frequency: configuration?.frequency ?? PLAN_PERIODICITY_TYPE.MONTH,
    startPeriod: configuration?.startPeriod ? moment(configuration.startPeriod) : null,
    endPeriod: configuration?.endPeriod ? moment(configuration.endPeriod) : null,
    dayInterval: configuration?.dayInterval ?? 7,
  }), [configuration]);

  const initialValues = useMemo(() => ({
    ...internalInitialValues,
  }), [internalInitialValues]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form initialValues={initialValues} form={form}>
        <div>
          <div className={styles.container}>
            <h2 className={styles.title}>{t('createPlanPage.section1.title')}</h2>
            <h3 className={styles.subtitle}>{t('createPlanPage.section1.subtitle')}</h3>
            <PlanV3FormRequirementsGeneral frequencyType={formValues.frequency} />
          </div>
        </div>
      </Form>
    </div>
  );
};

PlanV3FormRequirements.propTypes = {
  className: PropTypes.string,
  configuration: PlanV3ConfigurationModel.propTypes,
};

PlanV3FormRequirements.defaultProps = {
  className: '',
  configuration: undefined,
};

export default PlanV3FormRequirements;
