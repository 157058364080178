import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Button from '@palette/components/designSystem/Button/Button';
import CommissionV3PaymentsEditPayoutScheduleModal from '@palette/components/commissionV3/CommissionV3PaymentsEditPayoutScheduleModal/CommissionV3PaymentsEditPayoutScheduleModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getCommissionV3Amount } from '@palette/helpers/CommissionV3Helper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import styles from './CommissionV3PaymentsEditPayoutSchedule.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3PaymentsEditPayoutSchedule = ({ className, commission }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [editPayoutScheduleModalIsVisible, showEditPayoutScheduleModal] = useState(false);

  const commissionAmount = getCommissionV3Amount(commission);

  if (commissionAmount === 0) return null;

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMMISSIONS.MANAGE]) || commission.cantUpdateMine) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        icon={<PenFilled />}
        onClick={() => showEditPayoutScheduleModal(true)}
      >
        {t('paymentsEditPayoutSchedule.editPayoutSchedule')}
      </Button>
      {
        editPayoutScheduleModalIsVisible && (
          <CommissionV3PaymentsEditPayoutScheduleModal visible onClose={() => showEditPayoutScheduleModal(false)} commission={commission} />
        )
      }
    </div>
  );
};

CommissionV3PaymentsEditPayoutSchedule.propTypes = {
  className: PropTypes.string,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
};

CommissionV3PaymentsEditPayoutSchedule.defaultProps = {
  className: '',
};

export default CommissionV3PaymentsEditPayoutSchedule;
