import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Avatar as AntDAvatar } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import CloudinaryUploader from '@palette/components/designSystem/CloudinaryUploader/CloudinaryUploader';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';
import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './AvatarUploaderModal.less';

const AvatarUploaderModal = ({
  visible,
  onClose,
  onUpdated,
  modelType,
  logoSrc,
  user,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [imageUrl, setImageUrl] = useState(null);

  const updateCompanyIsPending = useSelector(ProfileSelectors.updateCompanyIsPending);
  const updateProfileIsPending = useSelector(ProfileSelectors.updateProfileIsPending);
  const updateUserProfilePictureIsPending = useSelector(UsersSelectors.updateUserProfilePictureIsPending);

  const isPending = useMemo(() => updateCompanyIsPending || updateProfileIsPending || updateUserProfilePictureIsPending, [
    updateCompanyIsPending,
    updateProfileIsPending,
    updateUserProfilePictureIsPending,
  ]);

  const handleClose = () => onClose();

  const handleUpdated = () => {
    if (onUpdated) onUpdated();
    handleClose();
  };

  const handleUpdatePicture = useCallback((removeCurrentImg = false) => {
    const logo = !removeCurrentImg && imageUrl ? imageUrl : null;
    let updateFunc = ProfileActions.updateProfile;
    if (modelType === 'company') {
      updateFunc = ProfileActions.updateCompany;
    }
    if (modelType === 'user') {
      updateFunc = UsersActions.updateUserProfilePicture;
    }

    dispatch(updateFunc({
      logo,
      user,
      enableSuccessNotification: true,
      onSuccessCB: handleUpdated,
    }));
  }, [imageUrl, modelType, user]);

  const currentLogoNode = useMemo(() => {
    if (!logoSrc) return null;

    return (
      <>
        <div className={styles.currentLogoWrapper}>
          <AntDAvatar
            draggable={false}
            shape="circle"
            src={logoSrc}
            size={60}
          />
          <Tooltip title={t('common.global.remove')}>
            <Button
              className={styles.removeWrapper}
              type="linkDestroy"
              icon={<TrashFilled width={16} height={16} />}
              onClick={() => handleUpdatePicture(true)}
            />
          </Tooltip>
        </div>
        <div className={styles.dividerWrapper}>
          <div className={styles.divider} />
          <div className={styles.dividerLabel}>{t('avatarUploader.modal.label.or')}</div>
        </div>
      </>
    );
  }, [logoSrc, handleUpdatePicture]);

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t(`avatarUploader.modal.title_${modelType}`)}
        </div>
      )}
      visible={visible}
      onCancel={handleClose}
      onOk={() => handleUpdatePicture()}
      okText={t(`avatarUploader.modal.okText_${modelType}`)}
      okIcon={<CheckFilled />}
      loading={isPending}
      disableOk={!imageUrl}
    >
      <div className={styles.modalContent}>
        {currentLogoNode}
        <CloudinaryUploader onFileUrlChange={setImageUrl} />
      </div>
    </Modal>
  );
};

AvatarUploaderModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdated: PropTypes.func,
  modelType: PropTypes.oneOf(['profile', 'company', 'user']),
  logoSrc: PropTypes.string,
  user: MetaUserModel.propTypes,
};

AvatarUploaderModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onUpdated: () => {},
  modelType: 'profile',
  logoSrc: '',
  user: null,
};

export default AvatarUploaderModal;
