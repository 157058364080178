import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Link from '@palette/components/designSystem/Link/Link';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';

import { useTeams } from '@palette/hooks/TeamHooks';

import { getParents } from '@palette/helpers/TeamHelper';

import routePaths from '@palette/config/routePaths';

import * as TeamModel from '@palette/models/Team';

import styles from './TeamsBreadcrumb.less';

const classNames = bindClassNames.bind(styles);

const TeamsBreadcrumb = ({ className, team }) => {
  const { t } = useTranslation();

  const [, teamsListById] = useTeams();

  const currentLocationNode = useMemo(() => {
    if (team === null) return null;

    return (
      <div className={styles.currentLocationWrapper}>
        <TeamFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {team.name}
        </div>
      </div>
    );
  }, [team]);

  const treeLinkNodes = useMemo(() => {
    if (team === null || team.parentId === null) return null;

    const parentTeams = getParents(team, teamsListById);

    return parentTeams.map((parentTeam) => (
      <div key={parentTeam.id} className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.teamDetails}
          params={{ teamId: parentTeam.id }}
        >
          <TeamFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {parentTeam.name}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
    ));
  }, [team, teamsListById]);

  if (team === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.teams}
        >
          <TeamFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {t('teamsBreadcrumb.teams')}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
      {treeLinkNodes}
      {currentLocationNode}
    </div>
  );
};

TeamsBreadcrumb.propTypes = {
  className: PropTypes.string,
  team: TeamModel.propTypes,
};

TeamsBreadcrumb.defaultProps = {
  className: '',
  team: null,
};

export default TeamsBreadcrumb;
