import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '@palette/components/designSystem/Table/Table';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import { SCOPES } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './ComparatorPlanResults.less';

const classNames = bindClassNames.bind(styles);

const ComparatorPlanResults = ({
  className,
  firstPlan,
  firstPeriod,
  secondPlan,
  secondPeriod,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const firstPlanPeriod = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodBy(state, { masterPlanId: firstPlan?.id, year: firstPeriod?.year, periodId: firstPeriod?.period }));

  useEffect(() => {
    if (firstPlan !== null && firstPeriod !== null) {
      dispatch(MasterPlansActions.getPeriodBy({ planId: firstPlan?.id, year: firstPeriod?.year, period: firstPeriod?.period }));
    }
  }, [firstPlan, firstPeriod]);

  const secondPlanPeriod = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodBy(state, { masterPlanId: secondPlan?.id, year: secondPeriod?.year, periodId: secondPeriod?.period }));

  useEffect(() => {
    if (secondPlan !== null && secondPeriod !== null) {
      dispatch(MasterPlansActions.getPeriodBy({ planId: secondPlan?.id, year: secondPeriod?.year, period: secondPeriod?.period }));
    }
  }, [secondPlan, secondPeriod]);

  const globalResultsNode = useMemo(() => {
    const firstPlanPeriodGlobalResult = firstPlan !== null && firstPlanPeriod !== null ? formatPrice(firstPlanPeriod.totalCommissionAmount, firstPlanPeriod.currency) : '-';
    const secondPlanPeriodGlobalResult = secondPlan !== null && secondPlanPeriod !== null ? formatPrice(secondPlanPeriod.totalCommissionAmount, secondPlanPeriod.currency) : '-';
    let diffResult = '-';
    let diff = 0;
    if (firstPlanPeriod !== null && secondPlanPeriod !== null) {
      if (firstPlanPeriod.totalCommissionAmount !== 0) {
        diff = ((secondPlanPeriod.totalCommissionAmount - firstPlanPeriod.totalCommissionAmount) / firstPlanPeriod.totalCommissionAmount) * 100;
        diffResult = `${parseFloat(diff).toFixed(2)}%`;
        if (diff > 0) {
          diffResult = `+${diffResult}`;
        }
      } else if (secondPlanPeriod.totalCommissionAmount === 0) {
        diffResult = '0%';
      }
    }

    return (
      <div className={styles.globalResults}>
        <div className={styles.resultWrapper}>
          <div className={styles.resultLabel}>
            {t('comparatorPlanResults.commissions', { comparedPlanName: 'A' })}
          </div>
          <div className={styles.result}>
            {firstPlanPeriodGlobalResult}
          </div>
        </div>
        <div className={styles.resultWrapper}>
          <div className={styles.resultLabel}>
            {t('comparatorPlanResults.commissions', { comparedPlanName: 'B' })}
          </div>
          <div className={styles.result}>
            {secondPlanPeriodGlobalResult}
          </div>
        </div>
        <div className={styles.resultWrapper}>
          <div className={styles.resultLabel}>
            {t('comparatorPlanResults.diff')}
          </div>
          <div
            className={classNames({
              result: true,
              warning: diff < 0,
              success: diff > 0,
            })}
          >
            {diffResult}
          </div>
        </div>
      </div>
    );
  }, [
    firstPlan,
    firstPlanPeriod,
    secondPlan,
    secondPlanPeriod,
  ]);

  const firstPlanPeriodUsers = useSelector(
    (state) => MasterPlansSelectors.getMasterPlanPeriodUsers(state, { masterPlanId: firstPlan?.id, year: firstPlanPeriod?.year, periodId: firstPlanPeriod?.period }),
  );
  const firstPlanPeriodManagers = useSelector(
    (state) => MasterPlansSelectors.getMasterPlanPeriodManagers(state, { masterPlanId: firstPlan?.id, year: firstPlanPeriod?.year, periodId: firstPlanPeriod?.period }),
  );

  useEffect(() => {
    if (firstPlan !== null && firstPlanPeriod !== null) {
      dispatch(MasterPlansActions.getPeriodUsers({ planId: firstPlan.id, year: firstPlanPeriod.year, period: firstPlanPeriod.period, forManagers: firstPlan.scope === SCOPES.MANAGER }));
    }
  }, [firstPlan, firstPlanPeriod]);

  const firstPlanPeriodUsersData = useMemo(() => {
    if (firstPlan === null || firstPlanPeriod === null) return null;

    if (firstPlan.scope === SCOPES.MANAGER) {
      return firstPlanPeriodManagers;
    }

    return firstPlanPeriodUsers;
  }, [
    firstPlan,
    firstPlanPeriod,
    firstPlanPeriodUsers,
    firstPlanPeriodManagers,
  ]);

  const secondPlanPeriodUsers = useSelector(
    (state) => MasterPlansSelectors.getMasterPlanPeriodUsers(state, { masterPlanId: secondPlan?.id, year: secondPlanPeriod?.year, periodId: secondPlanPeriod?.period }),
  );
  const secondPlanPeriodManagers = useSelector(
    (state) => MasterPlansSelectors.getMasterPlanPeriodManagers(state, { masterPlanId: secondPlan?.id, year: secondPlanPeriod?.year, periodId: secondPlanPeriod?.period }),
  );

  useEffect(() => {
    if (secondPlan !== null && secondPlanPeriod !== null) {
      dispatch(MasterPlansActions.getPeriodUsers({ planId: secondPlan.id, year: secondPlanPeriod.year, period: secondPlanPeriod.period, forManagers: secondPlan.scope === SCOPES.MANAGER }));
    }
  }, [secondPlan, secondPlanPeriod]);

  const secondPlanPeriodUsersData = useMemo(() => {
    if (secondPlan === null || secondPlanPeriod === null) return null;

    if (secondPlan.scope === SCOPES.MANAGER) {
      return secondPlanPeriodManagers;
    }

    return secondPlanPeriodUsers;
  }, [
    secondPlan,
    secondPlanPeriod,
    secondPlanPeriodUsers,
    secondPlanPeriodManagers,
  ]);

  const usersTableColumns = useMemo(() => ([
    {
      id: 'periodUser',
      Header: t('comparatorPlanResults.table.headers.users'),
      accessor: 'user',
      minWidth: 200,
      width: '25%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<UserProfile user={value} clickable />),
    },
    {
      id: 'firstPlanPeriod',
      Header: t('comparatorPlanResults.commissions', { comparedPlanName: 'A' }),
      accessor: (userData) => (userData.firstPlanPeriod !== null ? formatPrice(userData.firstPlanPeriod.totalCommissionAmount, userData.firstPlanPeriod.currency) : '-'),
      minWidth: 200,
      width: '25%',
    },
    {
      id: 'secondPlanPeriod',
      Header: t('comparatorPlanResults.commissions', { comparedPlanName: 'B' }),
      accessor: (userData) => (userData.secondPlanPeriod !== null ? formatPrice(userData.secondPlanPeriod.totalCommissionAmount, userData.secondPlanPeriod.currency) : '-'),
      minWidth: 200,
      width: '25%',
    },
    {
      id: 'diff',
      Header: t('comparatorPlanResults.diff'),
      accessor: (userData) => (userData),
      minWidth: 200,
      width: '25%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => {
        let diffResult = '-';
        let diff = 0;
        if (value.firstPlanPeriod !== null && value.secondPlanPeriod !== null) {
          if (value.firstPlanPeriod.totalCommissionAmount !== 0) {
            diff = ((value.secondPlanPeriod.totalCommissionAmount - value.firstPlanPeriod.totalCommissionAmount) / value.firstPlanPeriod.totalCommissionAmount) * 100;
            diffResult = `${parseFloat(diff).toFixed(2)}%`;
            if (diff > 0) {
              diffResult = `+${diffResult}`;
            }
          } else if (value.secondPlanPeriod.totalCommissionAmount === 0) {
            diffResult = '0%';
          }
        }

        return (
          <div
            className={classNames({
              result: true,
              warning: diff < 0,
              success: diff > 0,
            })}
          >
            {diffResult}
          </div>
        );
      },
      /* eslint-enable react/prop-types */
    },
  ]), [firstPlan, secondPlan]);

  const usersTableData = useMemo(() => {
    if (firstPlanPeriodUsersData === null && secondPlanPeriodUsersData === null) return null;

    const byUserId = {};
    if (firstPlanPeriodUsersData !== null) {
      firstPlanPeriodUsersData.forEach((periodUser) => {
        const userId = periodUser.user.id;
        const userData = byUserId[userId] || { user: periodUser.user, firstPlanPeriod: null, secondPlanPeriod: null };
        userData.firstPlanPeriod = {
          totalCommissionAmount: periodUser.totalCommissionAmount,
          currency: periodUser.currency,
        };

        byUserId[userId] = userData;
      });
    }
    if (secondPlanPeriodUsersData !== null) {
      secondPlanPeriodUsersData.forEach((periodUser) => {
        const userId = periodUser.user.id;
        const userData = byUserId[userId] || { user: periodUser.user, firstPlanPeriod: null, secondPlanPeriod: null };
        userData.secondPlanPeriod = {
          totalCommissionAmount: periodUser.totalCommissionAmount,
          currency: periodUser.currency,
        };

        byUserId[userId] = userData;
      });
    }

    return Object.values(byUserId);
  }, [
    firstPlanPeriodUsersData,
    secondPlanPeriodUsersData,
  ]);

  const usersTableResultsNode = useMemo(() => {
    if (usersTableData === null) return null;

    return (
      <Table
        className={styles.table}
        columns={usersTableColumns}
        data={usersTableData}
        stretch
        highlightRowOnHover
      />
    );
  }, [
    usersTableColumns,
    usersTableData,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {globalResultsNode}
      {usersTableResultsNode}
    </div>
  );
};

ComparatorPlanResults.propTypes = {
  className: PropTypes.string,
  firstPlan: MasterPlanModel.propTypes,
  firstPeriod: YearPeriodModel.propTypes,
  secondPlan: MasterPlanModel.propTypes,
  secondPeriod: YearPeriodModel.propTypes,
};

ComparatorPlanResults.defaultProps = {
  className: '',
  firstPlan: null,
  firstPeriod: null,
  secondPlan: null,
  secondPeriod: null,
};

export default ComparatorPlanResults;
