import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import DeleteMasterPlanUserModal from '@palette/components/masterPlan/DeleteMasterPlanUserModal/DeleteMasterPlanUserModal';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './DeleteMasterPlanUserButton.less';

const DeleteMasterPlanUserButton = ({ planId, forManagers, user }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Tooltip title={t('masterPlanSettings.Users.removeTooltip')}>
        <Button
          className={styles.actionButton}
          type="linkDestroy"
          icon={<TrashFilled className={styles.actionTrashIcon} />}
          onClick={openModal}
        />
      </Tooltip>
      {
        showModal && (
          <DeleteMasterPlanUserModal
            visible={showModal}
            onClose={closeModal}
            planId={planId}
            user={user}
            forManagers={forManagers}
          />
        )
      }
    </div>
  );
};

DeleteMasterPlanUserButton.propTypes = {
  user: MetaUserModel.propTypes.isRequired,
  planId: PropTypes.string.isRequired,
  forManagers: PropTypes.bool,
};

DeleteMasterPlanUserButton.defaultProps = {
  forManagers: false,
};

export default DeleteMasterPlanUserButton;
