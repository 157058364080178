import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MasterPlanPeriodDealModel from '@palette/models/MasterPlanPeriodDeal';
import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'UserStatementPlanPeriod';

export const propTypes = PropTypes.shape({
  year: PropTypes.number,
  period: PropTypes.number,
  totalAmount: PropTypes.number,
  currency: PropTypes.string,
  deals: PropTypes.arrayOf(MasterPlanPeriodDealModel.propTypes),
});

export const transform = (userStatementPlanPeriod) => {
  if (!userStatementPlanPeriod) {
    return null;
  }

  const periodData = YearPeriodModel.transform(manageFreeShapeObjectAttribute(userStatementPlanPeriod, 'period'));

  return ({
    year: periodData.year,
    period: periodData.period,
    totalAmount: manageAmountAttribute(userStatementPlanPeriod, 'totalCommissions'),
    currency: manageStringAttribute(userStatementPlanPeriod, 'currency'),
    deals: MasterPlanPeriodDealModel.transformList(manageArrayAttribute(userStatementPlanPeriod, 'deals')),
  });
};

export const transformList = (userStatementPlanPeriods) => userStatementPlanPeriods.map((userStatementPlanPeriod) => transform(userStatementPlanPeriod));
