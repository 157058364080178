import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import NewDashboardPresetButton from '@palette/components/dashboard/NewDashboardPresetButton/NewDashboardPresetButton';
import DashboardPresetsPageContent from '@palette/components/dashboard/DashboardPresetsPageContent/DashboardPresetsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './DashboardPresetsPage.less';

const DashboardPresetsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAllRights(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.USERS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.dashboardPresetsTitle')} />
      <PageTitle className={styles.titleWrapper}>
        <div className={styles.titlesContainer}>
          <h1>{t('dashboardPresetsPage.page.title')}</h1>
        </div>
        <div className={styles.actions}>
          <NewDashboardPresetButton className={styles.newDashboardBtn} />
        </div>
      </PageTitle>
      <DashboardPresetsPageContent />
    </div>
  );
};

export default DashboardPresetsPage;
