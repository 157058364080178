import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'CommissionBracket';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const transform = (commissionBracket) => {
  if (!commissionBracket) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(commissionBracket, '_id'),
      value: manageAmountAttribute(commissionBracket, 'value'),
      amount: manageAmountAttribute(commissionBracket, 'amount', null),
    }
  );
};

export const transformList = (commissionBrackets) => commissionBrackets.map((commissionBracket) => transform(commissionBracket));
