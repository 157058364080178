import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import WidgetBase from '@palette/components/widgets/dashboard/WidgetBase/WidgetBase';

import styles from './ListWidgetBase.less';

const classNames = bindClassNames.bind(styles);

const ListWidgetBase = ({
  className,
  widgetIndex,
  children,
  title,
  secondTitle,
  secondTitleClick,
  readOnly,
  enableLinks,
}) => {
  const titleNode = useMemo(() => {
    if (title === '') return null;

    return (
      <div className={styles.title}>
        {title}
      </div>
    );
  }, [title]);

  const secondTitleNode = useMemo(() => {
    if (secondTitle === '' || secondTitleClick === null) return null;

    return (
      <Button
        type="link"
        className={styles.secondTitle}
        onClick={secondTitleClick}
        disabled={readOnly && !enableLinks}
      >
        {secondTitle}
      </Button>
    );
  }, [secondTitle, secondTitleClick, readOnly, enableLinks]);

  const headerNode = useMemo(() => {
    if (titleNode === null && secondTitleNode === null) return null;

    return (
      <div className={styles.header}>
        {titleNode}
        {secondTitleNode}
      </div>
    );
  }, [titleNode, secondTitleNode]);

  return (
    <WidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      readOnly={readOnly}
    >
      {headerNode}
      {children}
    </WidgetBase>
  );
};

ListWidgetBase.propTypes = {
  className: PropTypes.string,
  widgetIndex: PropTypes.number.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  secondTitle: PropTypes.string,
  secondTitleClick: PropTypes.func,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

ListWidgetBase.defaultProps = {
  className: '',
  children: null,
  title: '',
  secondTitle: '',
  secondTitleClick: null,
  readOnly: false,
  enableLinks: true,
};

export default ListWidgetBase;
