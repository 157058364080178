/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';
import _keyBy from 'lodash/keyBy';
import _assign from 'lodash/assign';
import _merge from 'lodash/merge';
import _flatMapDeep from 'lodash/flatMapDeep';
import _cloneDeep from 'lodash/cloneDeep';

import { CONNECTOR_TYPES } from '@palette/constants/connector';

import { getResourceColumns } from '@palette/helpers/ConnectorHelper';

/*
 * Initial State
 */
const initialState = {
  getAllUsersResourcesObjectsIsPending: false,
  getResourceObjectsIsPending: false,
  resetLastSyncDatesIsPending: false,
  getResourceFieldsIsPending: false,
  getConfigurationsFieldsIsPending: false,
  getAvailableConfigurationsFieldsIsPending: false,
  upsertResourceConfigurationsIsPending: false,

  byId: {},
  resourceObjects: {},
  resourceObjectsPagination: {},
  initialGetListCallDone: false,
  refreshListIsNeeded: false,
  resourceFields: {},
  configurationsFieldsByIdAndType: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'connectors',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get Connectors list */
    getList: (state) => {
      state.getListIsPending = true;
    },
    getListForIC: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { connectors = [], merge = false } = payload;

      if (merge) {
        state.byId = _merge(state.byId, _keyBy(connectors, 'id'));
      } else {
        state.byId = _assign(state.byId, _keyBy(connectors, 'id'));
      }
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
      state.initialGetListCallDone = true;
    },
    /* Get Resource's Objects */
    getResourceObjects: (state) => {
      state.getResourceObjectsIsPending = true;
    },
    setResourceObjects: (state, { payload }) => {
      const { resourceObjects, connectorId, type, pagination, override = false } = payload;

      const updatedResourceObjects = {
        [connectorId]: {
          [type]: resourceObjects,
        },
      };

      if (override) {
        state.resourceObjects = _assign(state.resourceObjects, { [connectorId]: { [type]: resourceObjects } });
      } else {
        state.resourceObjects = _merge(_cloneDeep(original(state.resourceObjects)), updatedResourceObjects);
      }

      state.resourceObjectsPagination = pagination;
    },
    getResourceObjectsCompleted: (state) => {
      state.getResourceObjectsIsPending = false;
    },
    /* Get All Resources' Objects */
    getAllUsersResourcesObjects: (state) => {
      state.getAllUsersResourcesObjectsIsPending = true;
    },
    getAllUsersResourcesObjectsCompleted: (state) => {
      state.getAllUsersResourcesObjectsIsPending = false;
    },
    /* Reset last sync dates */
    resetLastSyncDates: (state) => {
      state.resetLastSyncDatesIsPending = true;
    },
    resetLastSyncDatesCompleted: (state) => {
      state.resetLastSyncDatesIsPending = false;
    },
    /* Get Resource Fields */
    getResourceFields: (state) => {
      state.getResourceFieldsIsPending = true;
    },
    setConnectorResourceFields: (state, { payload }) => {
      const { connectorId, resourceType, fields } = payload;

      const updatedResourceFields = {
        [connectorId]: {
          [resourceType]: fields,
        },
      };

      state.resourceFields = _merge(
        _cloneDeep(original(state.resourceFields)),
        updatedResourceFields,
      );
    },
    getResourceFieldsCompleted: (state) => {
      state.getResourceFieldsIsPending = false;
    },
    /* Set refresh list */
    setRefreshListIsNeeded: (state, { payload }) => {
      const { isNeeded } = payload;

      state.refreshListIsNeeded = isNeeded;
    },
    /* Refresh list */
    refreshList: () => {},
    /* Get Resource Configurations */
    getResourceConfigurationsFields: (state) => {
      state.getConfigurationsFieldsIsPending = true;
    },
    setResourceConfigurationsFields: (state, { payload }) => {
      const { connectorId, resourceConfigurationsList } = payload;

      const configuration = {};

      resourceConfigurationsList.forEach((resourceConfiguration) => {
        configuration[resourceConfiguration.type] = {
          allFields: state.configurationsFieldsByIdAndType[connectorId]?.[resourceConfiguration.type]?.allFields || [],
          syncedFields: resourceConfiguration.fields,
          isConfigExists: true,
        };
      });

      state.configurationsFieldsByIdAndType = _assign(state.configurationsFieldsByIdAndType, { [connectorId]: configuration });
    },
    getResourceConfigurationsFieldsCompleted: (state) => {
      state.getConfigurationsFieldsIsPending = false;
    },
    /* Get Available Configurations */
    getAvailableConfigurationsFields: (state) => {
      state.getAvailableConfigurationsFieldsIsPending = true;
    },
    setAvailableConfigurationsFields: (state, { payload }) => {
      const { connectorId, resourceType, availableFieldsList } = payload;

      const configuration = {
        ...state.configurationsFieldsByIdAndType[connectorId],
        [resourceType]: {
          ...state.configurationsFieldsByIdAndType[connectorId]?.[resourceType],
          syncedFields: state.configurationsFieldsByIdAndType[connectorId]?.[resourceType]?.syncedFields || [],
          allFields: availableFieldsList,
        },
      };

      state.configurationsFieldsByIdAndType = _assign(state.configurationsFieldsByIdAndType, { [connectorId]: configuration });
    },
    getAvailableConfigurationsFieldsCompleted: (state) => {
      state.getAvailableConfigurationsFieldsIsPending = false;
    },
    /* Upsert Resource Configurations */
    upsertResourceConfigurations: (state) => {
      state.upsertResourceConfigurationsIsPending = true;
    },
    upsertResourceConfigurationsCompleted: (state) => {
      state.upsertResourceConfigurationsIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getById = (state) => root(state).byId;
const getResourceObjects = (state) => root(state).resourceObjects;
const getResourceObjectsPagination = (state) => root(state).resourceObjectsPagination;
const getResourceFields = (state) => root(state).resourceFields;
const getConfigurationsFields = (state) => root(state).configurationsFieldsByIdAndType;

const getAllUsersResourcesObjectsIsPending = (state) => root(state).getAllUsersResourcesObjectsIsPending;
const getResourceObjectsIsPending = (state) => root(state).getResourceObjectsIsPending;
const resetLastSyncDatesIsPending = (state) => root(state).resetLastSyncDatesIsPending;
const getResourceFieldsIsPending = (state) => root(state).getResourceFieldsIsPending;
const getConfigurationsFieldsIsPending = (state) => root(state).getConfigurationsFieldsIsPending;
const getAvailableConfigurationsFieldsIsPending = (state) => root(state).getAvailableConfigurationsFieldsIsPending;
const upsertResourceConfigurationsIsPending = (state) => root(state).upsertResourceConfigurationsIsPending;
const initialGetListCallDone = (state) => root(state).initialGetListCallDone;
const refreshListIsNeeded = (state) => root(state).refreshListIsNeeded;

const getConnectorsList = createSelector(
  [getById],
  (byId) => Object.values(byId),
);

const getConnectorById = createSelector(
  [getById, getProps],
  (byId, { connectorId }) => byId[connectorId] || null,
);

const getNbOfConnectors = createSelector(
  [getById],
  (byId) => Object.keys(byId).filter((id) => byId[id].type !== CONNECTOR_TYPES.NOTEBOOK).length,
);

const getNbOfNotebooks = createSelector(
  [getById],
  (byId) => Object.keys(byId).filter((id) => byId[id].type === CONNECTOR_TYPES.NOTEBOOK).length,
);

const getConnectorsWithUsersResources = createSelector(
  [getConnectorsList],
  (connectorsList) => connectorsList.map((connector) => {
    const usersResources = connector.resources.filter((resource) => resource.isUser);
    if (usersResources.length > 0) {
      return ({
        ...connector,
        resources: usersResources,
      });
    }
    return null;
  }).filter(Boolean),
);

const getResourceObjectsByConnectorAndType = createSelector(
  [getResourceObjects, getProps],
  (resourceObjects, { connectorId, type }) => (resourceObjects[connectorId]?.[type] || []),
);

const getAllUsersResourcesObjects = createSelector(
  [getConnectorsWithUsersResources, getResourceObjects],
  (connectorsWithUsersResources, resourceObjects) => _flatMapDeep(
    connectorsWithUsersResources.map((connector) => (
      connector.resources.map((resource) => (resourceObjects[connector.id]?.[resource.type] || []))
    )),
  ),
);

const getResourceFieldsByConnectorAndType = createSelector(
  [getResourceFields, getProps],
  (resourceFields, { connectorId, resourceType }) => (resourceFields[connectorId]?.[resourceType] || []),
);

const getConfigurationsFieldsByIdAndType = createSelector(
  [getConfigurationsFields, getProps],
  (configurationsFields, { connectorId, resourceType }) => (configurationsFields[connectorId]?.[resourceType] || null),
);

const getColumnsFieldsUsedByConnectorAndType = createSelector(
  [getById, getProps],
  (byId, { connectorId, resourceType }) => getResourceColumns(byId[connectorId], resourceType).reduce((ac, column) => ac.concat(column.fields), []),
);

export const selectors = {
  getAllUsersResourcesObjectsIsPending,
  getResourceObjectsIsPending,
  resetLastSyncDatesIsPending,
  getResourceFieldsIsPending,
  getConfigurationsFieldsIsPending,
  getAvailableConfigurationsFieldsIsPending,
  upsertResourceConfigurationsIsPending,

  getConnectorsList,
  getConnectorById,
  getNbOfConnectors,
  getNbOfNotebooks,
  getConnectorsWithUsersResources,
  getResourceObjectsByConnectorAndType,
  getResourceObjectsPagination,
  getAllUsersResourcesObjects,
  initialGetListCallDone,
  getResourceFieldsByConnectorAndType,
  refreshListIsNeeded,
  getConfigurationsFieldsByIdAndType,
  getColumnsFieldsUsedByConnectorAndType,
};
