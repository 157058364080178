import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';

import * as PlanTotalValueDataModel from '@palette/models/widgets/dashboard/PlanTotalValueData';

import styles from './PlanTotalValueWidgetContent.less';

const classNames = bindClassNames.bind(styles);

const PlanTotalValueWidgetContent = ({ className, planTotalValueData }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    {comaSeparatorFormatter(floatToFixedNumber(planTotalValueData?.totalValue))}
  </div>
);

PlanTotalValueWidgetContent.propTypes = {
  className: PropTypes.string,
  planTotalValueData: PlanTotalValueDataModel.propTypes.isRequired,
};

PlanTotalValueWidgetContent.defaultProps = {
  className: '',
};

export default PlanTotalValueWidgetContent;
