import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'Pagination';

export const propTypes = PropTypes.shape({
  total: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
});

export const transform = (pagination) => {
  if (!pagination) {
    return null;
  }

  return (
    {
      total: manageNumberAttribute(pagination, 'total'),
      limit: manageNumberAttribute(pagination, 'limit'),
      page: manageNumberAttribute(pagination, 'page'),
    }
  );
};

export const transformList = (paginations) => paginations.map((pagination) => transform(pagination));
