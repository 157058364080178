import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import AlgoliaAutocomplete from '@palette/components/vendors/Algolia/AlgoliaAutocomplete/AlgoliaAutocomplete';

const AlgoliaSearchModal = ({ visible, onClose }) => {
  const { t } = useTranslation();

  const cleanAndClose = () => {
    onClose();
  };

  return (
    <Modal
      title={t('algoliaSearchModal.documentation')}
      visible={visible}
      onCancel={cleanAndClose}
      displayFooter={false}
    >
      <AlgoliaAutocomplete />
    </Modal>
  );
};

AlgoliaSearchModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

AlgoliaSearchModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AlgoliaSearchModal;
