import PropTypes from 'prop-types';

import {
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { TEAM_USER_TYPES } from '@palette/constants/team';

import * as MetaUserModel from '@palette/models/MetaUser';

export const modelName = 'TeamUser';

export const propTypes = PropTypes.shape({
  user: MetaUserModel.propTypes,
  type: PropTypes.oneOf(Object.values(TEAM_USER_TYPES)),
});

export const manageTeamUserType = (teamUser) => (TEAM_USER_TYPES[manageStringAttribute(teamUser, 'type')] || TEAM_USER_TYPES.CONTRIBUTOR);

export const transform = (teamUser) => {
  if (!teamUser) {
    return null;
  }

  return ({
    user: MetaUserModel.transform(manageFreeShapeObjectAttribute(teamUser, 'user')),
    type: manageTeamUserType(teamUser),
  });
};

export const transformList = (teamUsers) => teamUsers.map((teamUser) => transform(teamUser));
