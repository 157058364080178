import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import Input from '@palette/components/designSystem/Input/Input';

import styles from './InputAmountCurrency.less';

const classNames = bindClassNames.bind(styles);

const InputAmountCurrency = ({ className, size, onCurrencyChange, ...otherProps }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <Input
      className={styles.inputNumber}
      type="comaSeparatorFormatted"
      size={size}
      {...otherProps}
    />
    <CompanyCurrencySelect
      className={classNames({
        selectCurrencies: true,
        big: size === 'big',
      })}
      value={otherProps.currency}
      onChange={onCurrencyChange}
    />
  </div>
);

InputAmountCurrency.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'big']),
  onCurrencyChange: PropTypes.func.isRequired,
};

InputAmountCurrency.defaultProps = {
  className: '',
  size: 'default',
};

export default InputAmountCurrency;
