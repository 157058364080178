import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import LeftMainContentLayout from '@palette/components/designSystem/LeftMainContentLayout/LeftMainContentLayout';
import AddDataConnectionStepsList from '@palette/components/notebooks/AddDataConnectionStepsList/AddDataConnectionStepsList';
import AddDataConnectionFlowPageStepsManagement from '@palette/components/notebooks/AddDataConnectionFlowPageStepsManagement/AddDataConnectionFlowPageStepsManagement';

import { useNewDataConnectionInfo } from '@palette/hooks/NotebookHooks';

import { ADD_DATA_CONNECTION_FLOW_FIRST_STEP } from '@palette/constants/notebooks';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './AddDataConnectionModal.less';

const AddDataConnectionModal = ({ visible, onClose, notebook }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  const { disableAddEditDataConnectionAction, addEditDataConnectionAction } = useNewDataConnectionInfo();

  useEffect(() => {
    dispatch(NotebooksActions.setAddDataConnectionCurrentFlowStep({ addDataConnectionCurrentFlowStep: ADD_DATA_CONNECTION_FLOW_FIRST_STEP }));
  }, []);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const handleAddDataConnection = useCallback(() => {
    if (addEditDataConnectionAction !== null) addEditDataConnectionAction(notebook, cleanAndClose);
  }, [notebook, addEditDataConnectionAction, cleanAndClose]);

  const leftSideContentNode = useMemo(() => (
    <AddDataConnectionStepsList />
  ), []);

  const mainContentNode = useMemo(() => (
    <AddDataConnectionFlowPageStepsManagement notebook={notebook} />
  ), [notebook]);

  return (
    <Modal
      className={styles.modal}
      title={t('addDataConnectionModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleAddDataConnection}
      okText={t('addDataConnectionModal.import')}
      disableOk={disableAddEditDataConnectionAction}
      loading={editNotebookIsPending}
      blockHeight
    >
      <LeftMainContentLayout
        leftSideContent={leftSideContentNode}
        leftSideContentClassName={styles.leftSideContentWrapper}
        mainClassName={styles.mainWrapper}
        mainContent={mainContentNode}
        mainContentClassName={styles.mainContentWrapper}
        leftSideWidth={21.2}
        autoWidth={false}
        borderless
      />
    </Modal>
  );
};

AddDataConnectionModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  notebook: NotebookModel.propTypes.isRequired,
};

AddDataConnectionModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddDataConnectionModal;
