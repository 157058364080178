import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';

import LoginPage from '@palette/pages/global/LoginPage/LoginPage';
import ForgotPasswordPage from '@palette/pages/global/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from '@palette/pages/global/ResetPasswordPage/ResetPasswordPage';
import ConnectAsPage from '@palette/pages/global/ConnectAsPage/ConnectAsPage';

import routePaths from '@palette/config/routePaths';

import Register from './pages/register';
import AppWrapper from './pages/app/index';
import ConnectCRM from './pages/app/admin/add-connector';
import QuickBooksDisconnected from './pages/quickbooks-disconnected';
import SalesforceDashboard from './pages/sf-dashboard';

import './styles/admin/app.less';

const Routing = ({ appProps }) => (
  <HashRouter>
    <MetaTag isRouter />
    <Switch>
      <Route
        path={routePaths.v2.login}
        exact
        render={(props) => <LoginPage {...appProps} {...props} />}
      />
      <Route path="/register" exact render={(props) => <Register {...appProps} {...props} />} />
      <Route path="/sf/:sessionId" exact render={(props) => <SalesforceDashboard {...appProps} {...props} />} />
      <Route path="/sf/:sessionId/:statementPeriodId" exact render={(props) => <SalesforceDashboard {...appProps} {...props} />} />
      <Route
        path={routePaths.v2.forgotPassword}
        exact
        render={(props) => <ForgotPasswordPage {...appProps} {...props} />}
      />
      <Route
        path={routePaths.v2.resetPassword}
        render={(props) => <ResetPasswordPage {...appProps} {...props} />}
      />
      <Route
        path={routePaths.v2.connectAs}
        exact
        render={(props) => <ConnectAsPage {...appProps} {...props} />}
      />
      <Route path="/add-connector" render={(props) => <ConnectCRM {...appProps} {...props} />} />
      <Route path="/quickbooks-disconnected" render={(props) => <QuickBooksDisconnected {...appProps} {...props} />} />
      <Route path="/" render={(props) => <AppWrapper {...appProps} {...props} />} />
      <Redirect from="*" to={routePaths.v2.login} />
    </Switch>
  </HashRouter>
);

Routing.propTypes = {
  appProps: PropTypes.object,
};

Routing.defaultProps = {
  appProps: {},
};

export default Routing;
