import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useCorrespondingPlanUser } from '@palette/hooks/QuotaHooks';

import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { getUserTotalTarget } from '@palette/helpers/QuotaHelper';

import { selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './QuotaTableUserTotalCell.less';

const classNames = bindClassNames.bind(styles);

const QuotaTableUserTotalCell = ({
  className,
  quotaUserId,
  quotaId,
  year,
}) => {
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));
  const quotaUser = useSelector((state) => QuotasSelectors.getQuotaUserByIdAndQuotaId(state, { quotaId, quotaUserId }));

  const correspondingPlanUser = useCorrespondingPlanUser(quotaUserId);

  const userTotalTarget = useMemo(() => getUserTotalTarget(quota, quotaUser, year, correspondingPlanUser), [quota, quotaUser, year, correspondingPlanUser]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {comaSeparatorFormatter(floatToFixedNumber(userTotalTarget))}
    </div>
  );
};

QuotaTableUserTotalCell.propTypes = {
  className: PropTypes.string,
  quotaUserId: PropTypes.string.isRequired,
  quotaId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
};

QuotaTableUserTotalCell.defaultProps = {
  className: '',
};

export default QuotaTableUserTotalCell;
