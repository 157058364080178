import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import _cloneDeep from 'lodash/cloneDeep';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import Alert from '@palette/components/designSystem/Alert/Alert';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import Link from '@palette/components/designSystem/Link/Link';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import StarFilled from '@palette/components/utils/Icons/StarFilled';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import InfoCircleLine from '@palette/components/utils/Icons/InfoCircleLine';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import PlanColumnEdition from '@palette/components/masterPlan/PlanColumnEdition/PlanColumnEdition';
import DraggablePlanColumnEdition from '@palette/components/masterPlan/DraggablePlanColumnEdition/DraggablePlanColumnEdition';

import { checkColumnsVariablesFields } from '@palette/helpers/MasterPlanHelper';

import { useMasterPlanColumns, usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { ALERT_TYPES } from '@palette/constants/alert';
import { FORMATTERS_VALUES } from '@palette/constants/masterPlans';
import { PLAN_SETTINGS_TABS_IDS, PLAN_SETTINGS_TABS_QS_KEY } from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './PlanEditColumnsModal.less';

const classNames = bindClassNames.bind(styles);

const INTERNAL_COLUMNS_TYPES = {
  NAME_COLUMN: 'NAME_COLUMN',
  TABLE_COLUMNS: 'TABLE_COLUMNS',
};

const newColumn = {
  name: '',
  fields: [],
  formatter: FORMATTERS_VALUES.String,
  evalFn: '',
  displayInResources: true,
  displayInPlans: true,
};

const PlanEditColumnsModal = ({ visible, onClose, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planColumns = useMasterPlanColumns(plan);

  /* ## Internal state management ## */
  const [nameColumn, setNameColumn] = useState(newColumn);
  const [tableColumns, setTableColumns] = useState([]);
  const [errors, setErrors] = useState(false);

  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);

  useEffect(() => {
    if (visible) {
      const columns = _cloneDeep(planColumns);

      // Name column is the first one
      let [firstColumn] = columns.splice(0, 1);
      if (!firstColumn) {
        firstColumn = newColumn;
      }
      setNameColumn(firstColumn);

      setTableColumns(columns);
    }
  }, [visible]);

  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample);

  /* ## Columns updates management ## */
  const columnChange = (field, newValue, columnsType, index = 0) => {
    if (columnsType === INTERNAL_COLUMNS_TYPES.NAME_COLUMN) {
      const column = _cloneDeep(nameColumn);
      column[field] = newValue;
      setNameColumn(column);
      return;
    }

    const columns = _cloneDeep(tableColumns);
    columns[index][field] = newValue;
    setTableColumns(columns);
  };

  const handleColumnNameChange = useCallback((newName, columnsType, index = 0) => {
    columnChange('name', newName, columnsType, index);
  });

  const handleColumnFieldsChange = useCallback((newFields, columnsType, index = 0) => {
    columnChange('fields', newFields, columnsType, index);
  });

  const handleColumnFormatterChange = useCallback((newFormatter, columnsType, index = 0) => {
    columnChange('formatter', newFormatter, columnsType, index);
  });

  const handleColumnEvalFnChange = useCallback((newEvalFn, columnsType, index = 0) => {
    columnChange('evalFn', newEvalFn, columnsType, index);
  });

  const handleRemoveColumn = useCallback((index = 0) => {
    const inTable = _cloneDeep(tableColumns);
    inTable.splice(index, 1);

    setTableColumns(inTable);
  });

  const handleAddColumn = useCallback(() => {
    const columns = _cloneDeep(tableColumns);
    columns.push(newColumn);
    setTableColumns(columns);
  });

  /* ## Drag & Drop management ## */
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const allDraggableColumns = _cloneDeep(tableColumns);
    const [removed] = allDraggableColumns.splice(result.source.index, 1);
    allDraggableColumns.splice(result.destination.index, 0, removed);

    setTableColumns(allDraggableColumns);
  };

  const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',

    ...draggableStyle,
  });

  /* ## Actions definition ## */
  const cleanAndClose = () => {
    onClose();
  };

  const onUpdateSuccess = () => {
    cleanAndClose();
  };

  const handleSaveColumns = () => {
    setErrors(false);

    const allColumns = [nameColumn].concat(tableColumns);

    if (checkColumnsVariablesFields(allColumns)) {
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, columns: allColumns, onSuccessCB: onUpdateSuccess }));
    } else {
      setErrors(true);
    }
  };

  const handleResetToOriginal = () => {
    dispatch(MasterPlansActions.updatePlan({ planId: plan.id, columns: null, onSuccessCB: onUpdateSuccess }));
  };

  /* ## Nodes definition ## */
  const resetNode = (
    <Popconfirm
      title={t('planEditColumnsModal.resetColumns.popconfirm.title')}
      onConfirm={handleResetToOriginal}
      okText={t('planEditColumnsModal.resetColumns.popconfirm.confirm')}
      cancelText={t('planEditColumnsModal.resetColumns.popconfirm.cancel')}
      size="small"
      disabled={updatePlanIsPending}
    >
      <Button
        className={styles.resetToOriginalButton}
        type="link"
        disabled={updatePlanIsPending}
      >
        {t('planEditColumnsModal.resetColumns.popconfirm.cta')}
      </Button>
    </Popconfirm>
  );

  const dealNameBlockNode = (
    <div
      className={classNames({
        blockWrapper: true,
      })}
    >
      <div className={styles.blockTitleWrapper}>
        <PenFilled className={styles.blockIcon} />
        <div className={styles.blockTitle}>
          {t('planEditColumnsModal.dealNameBlock.title', { type: plan.trackingObject?.type })}
        </div>
      </div>
      <div className={styles.blockColumns}>
        <div className={styles.labels}>
          <div className={styles.label}>
            {t('planEditColumnsModal.dealNameBlock.title', { type: plan.trackingObject?.type })}
          </div>
          <div className={styles.label}>
            {t('planEditColumnsModal.nameColumn.fields', { type: plan.trackingObject?.type })}
          </div>
          <div className={styles.label}>
            {t('planEditColumnsModal.column.formatter')}
          </div>
        </div>
        <div className={styles.columns}>
          <PlanColumnEdition
            className={styles.nameColumn}
            column={nameColumn}
            helperData={formulaHelperData}
            onNameChange={(newName) => handleColumnNameChange(newName, INTERNAL_COLUMNS_TYPES.NAME_COLUMN)}
            onFieldsChange={(newFields) => handleColumnFieldsChange(newFields, INTERNAL_COLUMNS_TYPES.NAME_COLUMN)}
            onFormatterChange={(newFormatter) => handleColumnFormatterChange(newFormatter, INTERNAL_COLUMNS_TYPES.NAME_COLUMN)}
            onEvalFnChange={(newEvalFn) => handleColumnEvalFnChange(newEvalFn, INTERNAL_COLUMNS_TYPES.NAME_COLUMN)}
          />
        </div>
      </div>
    </div>
  );

  const planColumnsBlockNode = (
    <Droppable droppableId="planColumnsDroppable">
      {
        (providedDroppable) => (
          <div
            {...providedDroppable.droppableProps}
            ref={providedDroppable.innerRef}
          >
            <div
              className={classNames({
                blockWrapper: true,
                planColumnsBlock: true,
              })}
            >
              <div className={styles.blockTitleWrapper}>
                <StarFilled className={styles.blockIcon} />
                <div className={styles.blockTitle}>
                  {t('planEditColumnsModal.planColumnsBlock.title')}
                </div>
              </div>
              <div className={styles.blockColumns}>
                <div className={styles.labels}>
                  <div className={styles.label}>
                    {t('planEditColumnsModal.column.name')}
                  </div>
                  <div className={styles.label}>
                    {t('planEditColumnsModal.column.fields', { type: plan.trackingObject?.type })}
                    <Tooltip
                      title={t('planEditColumnsModal.column.fields.tooltip')}
                    >
                      <InfoCircleLine className={styles.infoIcon} />
                    </Tooltip>
                  </div>
                  <div className={styles.label}>
                    {t('planEditColumnsModal.column.formatter')}
                  </div>
                </div>
                <div className={styles.columns}>
                  {
                    tableColumns.map((tableColumn, index) => (
                      <Draggable key={index} draggableId={`column_${index}`} index={index}>
                        {(providedDraggable) => (
                          <div
                            className={styles.column}
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            style={getItemStyle(
                              providedDraggable.draggableProps.style,
                            )}
                          >
                            <DraggablePlanColumnEdition
                              column={tableColumn}
                              helperData={formulaHelperData}
                              onNameChange={(newName) => handleColumnNameChange(newName, INTERNAL_COLUMNS_TYPES.TABLE_COLUMNS, index)}
                              onFieldsChange={(newFields) => handleColumnFieldsChange(newFields, INTERNAL_COLUMNS_TYPES.TABLE_COLUMNS, index)}
                              onFormatterChange={(newFormatter) => handleColumnFormatterChange(newFormatter, INTERNAL_COLUMNS_TYPES.TABLE_COLUMNS, index)}
                              onEvalFnChange={(newEvalFn) => handleColumnEvalFnChange(newEvalFn, INTERNAL_COLUMNS_TYPES.TABLE_COLUMNS, index)}
                              onRemoveColumn={() => handleRemoveColumn(index)}
                              disabled={updatePlanIsPending}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {providedDroppable.placeholder}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </Droppable>
  );

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            {t('planEditColumnsModal.title')}
          </div>
          <div className={styles.subTitle}>
            {t('planEditColumnsModal.subTitle', { type: pluralize(plan.trackingObject?.type), planName: plan.name })}
          </div>
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleSaveColumns}
      okText={t('planEditColumnsModal.save')}
      okIcon={<CheckFilled />}
      loading={updatePlanIsPending}
      extraFooterNode={resetNode}
    >
      <div
        className={classNames({
          contentWrapper: true,
        })}
      >
        {dealNameBlockNode}
        <DragDropContext onDragEnd={handleDragEnd}>
          {planColumnsBlockNode}
        </DragDropContext>
        <Button
          className={styles.addButton}
          type="link"
          icon={<AddFilled />}
          onClick={handleAddColumn}
          disabled={updatePlanIsPending}
        >
          {t('planEditColumnsModal.addColumn')}
        </Button>
        <Alert
          className={styles.tipAlert}
          type={ALERT_TYPES.INFO}
          message={(
            <div className={styles.tipMessage}>
              <span>
                {t('planEditColumnsModal.tip.part1', { type: pluralize(plan.trackingObject?.type) })}
              </span>
              <Link
                className={styles.tipLink}
                path={routePaths.v2.planDetails}
                params={{
                  masterPlanId: plan.id,
                }}
                qsObject={{
                  [PLAN_SETTINGS_TABS_QS_KEY]: PLAN_SETTINGS_TABS_IDS.QUOTA,
                }}
              >
                {t('planEditColumnsModal.tip.link')}
              </Link>
              <span>
                {t('planEditColumnsModal.tip.part2', { type: pluralize(plan.trackingObject?.type) })}
              </span>
            </div>
          )}
        />
        {errors && (
          <Alert
            className={styles.tipAlert}
            type={ALERT_TYPES.ERROR}
            message={t('planEditColumnsModal.error.text')}
          />
        )}
      </div>
    </Modal>
  );
};

PlanEditColumnsModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  plan: MasterPlanModel.propTypes.isRequired,
};

PlanEditColumnsModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default PlanEditColumnsModal;
