import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import LockFilled from '@palette/components/utils/Icons/LockFilled';

import styles from './QuotaLockedIndicator.less';

const classNames = bindClassNames.bind(styles);

const QuotaLockedIndicator = ({ className }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <LockFilled className={styles.icon} width={14} height={14} />
  </div>
);

QuotaLockedIndicator.propTypes = {
  className: PropTypes.string,
};

QuotaLockedIndicator.defaultProps = {
  className: '',
};

export default QuotaLockedIndicator;
