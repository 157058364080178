import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Link from '@palette/components/designSystem/Link/Link';
import WidgetsFilled from '@palette/components/utils/Icons/WidgetsFilled';

import routePaths from '@palette/config/routePaths';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import styles from './DashboardBreadcrumb.less';

const classNames = bindClassNames.bind(styles);

const DashboardBreadcrumb = ({ className, dashboardPreset }) => {
  const { t } = useTranslation();

  if (dashboardPreset === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.dashboardPresets}
        >
          <WidgetsFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {t('adminMenu.users.presets')}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
      <div className={styles.currentLocationWrapper}>
        <WidgetsFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {dashboardPreset.name}
        </div>
      </div>
    </div>
  );
};

DashboardBreadcrumb.propTypes = {
  className: PropTypes.string,
  dashboardPreset: DashboardPresetModel.propTypes,
};

DashboardBreadcrumb.defaultProps = {
  className: '',
  dashboardPreset: null,
};

export default DashboardBreadcrumb;
