import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';
import NoConnectorsAlert from '@palette/components/connector/NoConnectorsAlert/NoConnectorsAlert';

import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { CONNECTOR_RESOURCE_SEPARATOR, CONNECTOR_TYPES } from '@palette/constants/connector';

import styles from './ResourceConnectorsSelect.less';

const classNames = bindClassNames.bind(styles);

const ResourceConnectorsSelect = ({ className, onChange, value, ...otherProps }) => {
  const { t } = useTranslation();

  const connectorsList = useConnectorsList();

  const formatValue = ({ connectorId, type }) => `${connectorId}${CONNECTOR_RESOURCE_SEPARATOR}${type}`;

  const [selectedConnector, setSelectedConnector] = useState(value !== null ? formatValue(value) : value);

  useEffect(() => {
    if (value !== null) {
      setSelectedConnector(formatValue(value));
    }
  }, [value]);

  const connectorsOptions = useMemo(() => {
    const finalOptions = {};

    const optionFormat = (connector, resource) => ({
      label: t('resourceConnectorsSelect.resourcesOption.label', { type: pluralize(resource.type), connector: connector.name }),
      value: formatValue({ connectorId: connector.id, type: resource.originalType }),
    });

    const notebooksOptions = connectorsList
      .filter((connector) => connector.type === CONNECTOR_TYPES.NOTEBOOK)
      .reduce((options, connector) => {
        connector.resources.forEach((resource) => options.push(optionFormat(connector, resource)));
        return options;
      }, []);

    const otherConnectorsOptions = connectorsList
      .filter((connector) => connector.type !== CONNECTOR_TYPES.NOTEBOOK)
      .reduce((options, connector) => {
        connector.resources.forEach((resource) => options.push(optionFormat(connector, resource)));
        return options;
      }, []);

    if (otherConnectorsOptions.length > 0) {
      finalOptions[t('resourceConnectorsSelect.category.connectors')] = otherConnectorsOptions;
    }

    if (notebooksOptions.length > 0) {
      finalOptions[t('resourceConnectorsSelect.category.notebooks')] = notebooksOptions;
    }

    if (Object.keys(finalOptions).length === 1) {
      return finalOptions[Object.keys(finalOptions)[0]];
    }

    return finalOptions;
  }, [connectorsList]);

  const handleChangeValue = (newValue) => {
    setSelectedConnector(newValue);

    if (onChange === null) return;

    let finalValue = null;

    if (newValue) {
      const [connectorId, type] = newValue.split(CONNECTOR_RESOURCE_SEPARATOR);

      finalValue = { connectorId, type };
    }

    onChange(finalValue);
  };

  const contentNode = useMemo(() => {
    if (connectorsList.length === 0) return <NoConnectorsAlert />;

    return (
      <Select
        className={styles.selectContainer}
        options={connectorsOptions}
        value={selectedConnector}
        onChange={handleChangeValue}
        {...otherProps}
      />
    );
  }, [
    connectorsList,
    connectorsOptions,
    selectedConnector,
    otherProps,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

ResourceConnectorsSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
};

ResourceConnectorsSelect.defaultProps = {
  className: '',
  onChange: null,
  value: null,
};

export default ResourceConnectorsSelect;
