import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import { ACTION_TYPES } from '@palette/constants/statementsHistory';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as StatementHistoryModel from '@palette/models/StatementHistory';

import styles from './UserStatementHistoryListItem.less';

const classNames = bindClassNames.bind(styles);

const UserStatementHistoryListItem = ({ className, historyChange }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const { action, author, date, content } = historyChange;

  const { entity, contentStrategyComp, i18nAction } = ACTION_TYPES[action];
  const { i18nEntity, icon } = entity;

  const iconNode = useMemo(() => {
    if (!icon) return null;

    const Icon = (props) => icon(props);

    return (
      <div className={styles.entityIcon}>
        <Icon className={styles.icon} width={24} height={24} />
      </div>
    );
  }, [icon]);

  const contentNode = useMemo(() => {
    if (!contentStrategyComp) return null;

    const Content = (props) => contentStrategyComp(props);

    return <Content className={styles.contentText} details={content} i18nAction={i18nAction} />;
  }, [content, contentStrategyComp, i18nAction]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        {iconNode}
        {i18nEntity && (
          <>
            <div className={styles.title}>
              {t(`statement.history.list.entity.${i18nEntity}`)}
            </div>
            <ChevronRightLine className={styles.arrowIcon} width={24} height={24} />
          </>
        )}
        <div className={classNames({ title: true, last: true })}>
          {t(`statement.history.list.title.${i18nAction}`)}
        </div>
        <div className={styles.date}>
          {moment(date).format('lll')}
        </div>
      </div>
      <div className={styles.footer}>
        <UserProfile
          className={styles.userProfile}
          nameContainerClassName={styles.userProfileNameContainer}
          nameClassName={styles.userProfileName}
          user={author}
          isPaletteUser={author.isImpersonated}
          additionalNode={contentNode}
          avatarSize={24}
          avatarStyle={{
            fontSize: '1.2rem',
            width: '2.4rem',
            minWidth: '2.4rem',
            height: '2.4rem',
          }}
        />
      </div>
    </div>
  );
};

UserStatementHistoryListItem.propTypes = {
  className: PropTypes.string,
  historyChange: StatementHistoryModel.propTypes.isRequired,
};

UserStatementHistoryListItem.defaultProps = {
  className: '',
};

export default UserStatementHistoryListItem;
