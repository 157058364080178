import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'MasterPlanStatsStats';

export const propTypes = PropTypes.shape({
  active: PropTypes.number,
  past: PropTypes.number,
  draft: PropTypes.number,
  archived: PropTypes.number,
  activeAndPast: PropTypes.number,
  total: PropTypes.number,
});

export const transform = (masterPlanStats) => {
  if (!masterPlanStats) {
    return null;
  }

  return (
    {
      active: manageNumberAttribute(masterPlanStats, 'active'),
      past: manageNumberAttribute(masterPlanStats, 'past'),
      draft: manageNumberAttribute(masterPlanStats, 'draft'),
      archived: manageNumberAttribute(masterPlanStats, 'archived'),
      activeAndPast: manageNumberAttribute(masterPlanStats, 'activeAndPast'),
      total: manageNumberAttribute(masterPlanStats, 'total'),
    }
  );
};

export const transformList = (masterPlanStatsList) => masterPlanStatsList.map((masterPlanStats) => transform(masterPlanStats));
