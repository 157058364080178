import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { getTypeDisplayed } from '@palette/helpers/CommonHelper';

import * as ConnectorModel from '@palette/models/Connector';
import * as MongoDetailsModel from '@palette/models/MongoDetails';
import * as ResourceObjectOverwriteModel from '@palette/models/ResourceObjectOverwrite';

export const modelName = 'ResourceObject';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  connectorObjectId: PropTypes.string,
  data: PropTypes.object,
  overwrites: PropTypes.array,
  connector: ConnectorModel.propTypes,
  connectorId: PropTypes.string,
  type: PropTypes.string,
  originalType: PropTypes.string,
  crmLink: PropTypes.string,
  clonedFrom: PropTypes.string,
  mongoDetails: MongoDetailsModel.propTypes,
});

export const getDisplayName = (firstName, lastName) => {
  if (firstName === null && lastName === null) {
    return null;
  }

  return `${firstName || ''} ${lastName || ''}`.trim();
};

export const manageOverwrites = (overwrites) => {
  if (overwrites === null) return null;
  return ResourceObjectOverwriteModel.transformList(overwrites);
};

export const transform = (resourceObject) => {
  if (!resourceObject) {
    return null;
  }

  const id = manageStringAttribute(resourceObject, '_id', null)
    || manageStringAttribute(resourceObject, 'objectId', null)
    || manageStringAttribute(resourceObject, 'mongoId');

  const originalType = manageStringAttribute(resourceObject, 'type', null) || manageStringAttribute(resourceObject, 'CObjectType');

  let connector = null;
  let connectorId = manageStringAttribute(resourceObject, 'connector', null, true);

  if (connectorId === null) {
    if (resourceObject.connector == null) {
      connectorId = manageStringAttribute(resourceObject, 'CObjectConnector', null, true);
      if (connectorId === null) {
        connector = ConnectorModel.transform(resourceObject.CObjectConnector);
        connectorId = connector?.id;
      }
    } else {
      connector = ConnectorModel.transform(resourceObject.connector);
      connectorId = connector?.id;
    }
  }

  return (
    {
      id,
      connectorObjectId: manageStringAttribute(resourceObject, 'id'),
      data: manageFreeShapeObjectAttribute(resourceObject, 'data'),
      overwrites: manageOverwrites(manageArrayAttribute(resourceObject, 'overwrites', null)),
      connector,
      connectorId,
      type: getTypeDisplayed(originalType),
      originalType,
      crmLink: manageStringAttribute(resourceObject, 'crmUrl', null),
      clonedFrom: manageStringAttribute(resourceObject, 'clonedFrom', null),
      mongoDetails: MongoDetailsModel.transform({
        ...resourceObject,
        CObjectType: originalType,
        CObjectConnector: connectorId,
        mongoId: id,
        mongoCollection: 'CObject',
      }),
    }
  );
};

export const transformList = (resourceObjects) => resourceObjects.map((resourceObject) => transform(resourceObject));
