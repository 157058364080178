import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './ResourceDetailsSummaryActionDelete.less';

const classNames = bindClassNames.bind(styles);

const ResourceDetailsSummaryActionDelete = ({ className, resourceObject }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { connectorId } = useParams();

  const [deleteTooltipVisible, setDeleteTooltipVisible] = useState(false);

  const deleteResourceIsPending = useSelector(ResourcesSelectors.deleteIsPending);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleDelete = (event) => {
    stopPropagation(event);
    setDeleteTooltipVisible(false);
  };

  const deleteResource = useCallback((event) => {
    stopPropagation(event);

    const { clonedFrom, id, originalType, type } = resourceObject;

    dispatch(ResourcesActions.deleteResource({
      connectorId,
      objectId: id,
      originalType,
      type,
      clonedFrom,
    }));
  }, [resourceObject, connectorId]);

  const contentNode = useMemo(() => (
    <Popconfirm
      title={t('resources.details.delete.popconfirm.label', { type: resourceObject.type })}
      onConfirm={(event) => deleteResource(event)}
      okText={t('common.global.yes')}
      cancelText={t('common.global.no')}
      size="small"
    >
      <Tooltip
        title={t('resources.details.delete.tooltip.label', { type: resourceObject.type })}
        visible={deleteTooltipVisible}
        onVisibleChange={(visible) => setDeleteTooltipVisible(visible)}
      >
        <Button
          className={styles.button}
          type="linkDestroy"
          icon={deleteResourceIsPending ? <LoadingLine spin /> : <TrashFilled className={styles.icon} />}
          onClick={handleDelete}
          disabled={deleteResourceIsPending}
        >
          {t('actions.delete')}
        </Button>
      </Tooltip>
    </Popconfirm>
  ), [deleteTooltipVisible, deleteResource, deleteResourceIsPending, resourceObject.type]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

ResourceDetailsSummaryActionDelete.propTypes = {
  className: PropTypes.string,
  resourceObject: ResourceObjectModel.propTypes.isRequired,
};

ResourceDetailsSummaryActionDelete.defaultProps = {
  className: '',
};

export default ResourceDetailsSummaryActionDelete;
