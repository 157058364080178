import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  DASHBOARD_404_PLAN_NOT_FOUND: {
    code: 'dashboard.errors.plan.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  DASHBOARD_ADD_WIDGET_SUCCESS: {
    code: 'notifications.dashboard.success.addWidget',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  DASHBOARD_UPDATE_WIDGET_SUCCESS: {
    code: 'notifications.dashboard.success.updateWidget',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
