import PropTypes from 'prop-types';

import { capitalize } from '@palette/helpers/CommonHelper';
import { manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'UserCurrency';

export const propTypes = PropTypes.shape({
  country: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  number: PropTypes.string,
});

export const transform = (userCurrency) => {
  if (!userCurrency) {
    return null;
  }

  return (
    {
      country: capitalize(manageStringAttribute(userCurrency, 'country')),
      name: manageStringAttribute(userCurrency, 'name'),
      code: manageStringAttribute(userCurrency, 'code'),
      number: manageStringAttribute(userCurrency, 'number'),
    }
  );
};

export const transformList = (userCurrencies) => userCurrencies.map((userCurrency) => transform(userCurrency));
