import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { formatPeriodNameDates, getPeriodName } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import routePaths from '@palette/config/routePaths';

import * as StatementV3ListPeriodModel from '@palette/models/StatementV3ListPeriod';

import styles from './StatementV3PeriodsListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PeriodsListItem = ({ className, statementPeriod }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const headerNode = useMemo(() => (
    <div className={styles.spacedBlocks}>
      <div className={styles.nameDatesWrapper}>
        <Link
          className={styles.name}
          displayIcon
          path={routePaths.v2.myStatementsV3UserStatement}
          params={{ statementPeriodId: `${profile.userData.id}-${statementPeriod.periodId}` }}
          qsObject={periodsDatesFilterQSObject}
        >
          {getPeriodName({ type: statementPeriod.periodicity }, statementPeriod.period)}
        </Link>
        <div className={styles.dates}>
          {formatPeriodNameDates(statementPeriod.fromDate, statementPeriod.toDate)}
        </div>
      </div>
      <div className={styles.statusesWrapper}>
        <div className={styles.totalWrapper}>
          <div className={styles.totalLabel}>
            {t('statementPeriodsListItem.total')}
          </div>
          <div className={styles.totalAmount}>
            {formatPrice(statementPeriod.amount, statementPeriod.currency)}
          </div>
        </div>
      </div>
    </div>
  ), [profile, statementPeriod, periodsDatesFilterQSObject]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {headerNode}
    </div>
  );
};

StatementV3PeriodsListItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3ListPeriodModel.propTypes.isRequired,
};

StatementV3PeriodsListItem.defaultProps = {
  className: '',
};

export default StatementV3PeriodsListItem;
