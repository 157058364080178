import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import * as PlanDeleteStrategyModel from '@palette/models/statementHistoryStrategies/PlanDeleteStrategy';

const classNames = bindClassNames.bind();

const PlanDeleteContentStrategy = ({ className, details, i18nAction }) => {
  const { t } = useTranslation();
  const { planName } = details;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: t(`statement.history.list.content.${i18nAction}`, { name: planName }) }}
    />
  );
};

PlanDeleteContentStrategy.propTypes = {
  className: PropTypes.string,
  details: PlanDeleteStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

PlanDeleteContentStrategy.defaultProps = {
  className: '',
};

export default PlanDeleteContentStrategy;
