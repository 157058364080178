import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import MasterPlanSettingsPayoutRulesFormRule from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRulesFormRule/MasterPlanSettingsPayoutRulesFormRule';
import Select from '@palette/components/designSystem/Select/Select';
import FormulaHelper from '@palette/components/formula/FormulaHelper/FormulaHelper';
import WeCanHelp from '@palette/components/masterPlanSettings/WeCanHelp/WeCanHelp';
import TextArea from '@palette/components/designSystem/TextArea/TextArea';
import Switch from '@palette/components/designSystem/Switch/Switch';
import Disclaimer from '@palette/components/designSystem/Disclaimer/Disclaimer';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as PayoutRuleModel from '@palette/models/PayoutRule';

import { getNbOfPeriodsSinceLastFrozenPeriod, payoutRulesTemplates, payoutScheduleValidationForm } from '@palette/helpers/MasterPlanHelper';

import { MONTH_FREQUENCIES_STR, PERIOD_TYPES } from '@palette/constants/frequencies';
import { PAYOUT_CONDITION_STRATEGIES, PAYOUT_RULES_TEMPLATE } from '@palette/constants/masterPlans';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsPayoutRulesForm.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsPayoutRulesForm = ({
  className,
  plan,
  helperData,
  inPlanCreationFlow,
  inPlanCreationFlowSetSaveCB,
  inPlanCreationFlowOnRulesUpdate,
  inPlanCreationFlowOnTemplateUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [onSuccessSavePayoutRulesCB, setOnSuccessSavePayoutRulesCB] = useState(null);
  const [reconciliationIndex, setReconciliationIndex] = useState(0);

  const initialRules = useMemo(() => ({
    payoutRulesDescription: plan.payoutRulesDescription,
    payoutRulesConditionStrategy: plan.payoutRulesConditionStrategy,
    payoutRulesTemplate: plan.payoutRulesTemplate || PAYOUT_RULES_TEMPLATE.payMonthAfterEndOfPlan_001,
    payoutRules: plan.payoutRules,
  }), [plan]);

  const initialValues = useMemo(() => ({
    ...initialRules,
  }), [
    initialRules,
  ]);

  const [rules, setRules] = useState(initialValues.payoutRules || []);
  const [template, setTemplate] = useState(initialValues.payoutRulesTemplate || PAYOUT_RULES_TEMPLATE.payMonthAfterEndOfPlan_001);
  const [description, setDescription] = useState(initialValues.payoutRulesDescription || '');
  const [conditionStrategy, setConditionStrategy] = useState(initialValues.payoutRulesConditionStrategy || PAYOUT_CONDITION_STRATEGIES.FIRST_MATCH);
  const [openedCollapses, setOpenedCollapses] = useState(rules.map(() => false));

  useEffect(() => {
    setRules(initialValues.payoutRules || []);
    setTemplate(initialValues.payoutRulesTemplate || PAYOUT_RULES_TEMPLATE.payMonthAfterEndOfPlan_001);
    setDescription(initialValues.payoutRulesDescription || '');
    setConditionStrategy(initialValues.payoutRulesConditionStrategy || PAYOUT_CONDITION_STRATEGIES.FIRST_MATCH);

    if (inPlanCreationFlow) {
      if (inPlanCreationFlowOnRulesUpdate !== null) {
        inPlanCreationFlowOnRulesUpdate(initialValues.payoutRules);
      }
      if (inPlanCreationFlowOnTemplateUpdate !== null) {
        inPlanCreationFlowOnTemplateUpdate(initialValues.template);
      }
    }
  }, [initialValues, inPlanCreationFlow, inPlanCreationFlowOnRulesUpdate, inPlanCreationFlowOnTemplateUpdate]);

  const handleSetRules = useCallback((newRules) => {
    setRules(newRules);

    if (inPlanCreationFlow && inPlanCreationFlowOnRulesUpdate !== null) {
      inPlanCreationFlowOnRulesUpdate(newRules);
    }
  }, [setRules, inPlanCreationFlow, inPlanCreationFlowOnRulesUpdate]);

  const handleSetTemplate = useCallback((newTemplate) => {
    setTemplate(newTemplate);

    if (inPlanCreationFlow && inPlanCreationFlowOnTemplateUpdate !== null) {
      inPlanCreationFlowOnTemplateUpdate(newTemplate);
    }
  }, [setTemplate, inPlanCreationFlow, inPlanCreationFlowOnTemplateUpdate]);

  const ruleTemplates = Object.keys(PAYOUT_RULES_TEMPLATE).map((type) => {
    let label = t(`masterPlanSettingsPayoutRulesForm.form.template.${type}`, {
      type: plan.trackingObject?.type,
    });

    if (plan.periodType === PERIOD_TYPES.MONTH
      && (
        type === PAYOUT_RULES_TEMPLATE.payEndOfPlan_001
        || type === PAYOUT_RULES_TEMPLATE.payMonthAfterEndOfPlan_001
      )
    ) {
      label = t(`masterPlanSettingsPayoutRulesForm.form.template.${type}.frequency`, {
        type: plan.trackingObject?.type,
        frequency: t(MONTH_FREQUENCIES_STR[plan.frequency]).toLowerCase(),
      });
    }

    return {
      label,
      value: type,
    };
  });

  const [form] = AntDForm.useForm();

  const updatePayoutRulesIsPending = useSelector(MasterPlansSelectors.updatePayoutRulesIsPending);

  const transformRules = (rulesToManage, templateToManage, descriptionToManage, conditionStrategyToManage) => ({
    payoutRules: rulesToManage.map((rule) => {
      const updatedRule = _cloneDeep(rule);
      delete updatedRule.id;
      if (updatedRule._id === null) {
        delete updatedRule._id;
      }
      if (updatedRule.code === '' || updatedRule.code === null) {
        delete updatedRule.code;
      }

      return {
        ...updatedRule,
        payments: updatedRule.payments.map(({ id, ...payment }) => ({
          ...payment,
          ...(payment.repeatFormula !== '1' && payment.intervalFormula && payment.intervalFormula !== '' && payment.intervalFormula !== null ? {
            intervalFormula: payment.intervalFormula,
            intervalCount: undefined,
            intervalType: undefined,
          } : {
            intervalFormula: undefined,
            intervalCount: payment.intervalCount,
            intervalType: payment.intervalType,
          }),
        })),
      };
    }),
    payoutRulesTemplate: templateToManage,
    payoutRulesDescription: descriptionToManage,
    payoutRulesConditionStrategy: conditionStrategyToManage,
    ...payoutRulesTemplates(plan.trackingObject?.type)[templateToManage],
  });

  const isPristine = useMemo(() => {
    const finalInitialRules = transformRules(initialValues.payoutRules, initialValues.payoutRulesTemplate, initialValues.payoutRulesDescription, initialValues.payoutRulesConditionStrategy);
    const finalRules = transformRules(rules, template, description, conditionStrategy);

    return _isEqual(finalInitialRules, finalRules);
  }, [
    initialValues,
    rules,
    template,
    description,
    conditionStrategy,
  ]);

  const handleFinish = useCallback(() => {
    const finalRules = transformRules(rules, template, description, conditionStrategy);

    let otherUpdates = {};
    if (inPlanCreationFlow) {
      otherUpdates = {
        disableSuccessNotification: inPlanCreationFlow,
        onSuccessCB: onSuccessSavePayoutRulesCB,
      };
    }

    dispatch(MasterPlansActions.updatePayoutRules({ planId: plan.id, ...finalRules, ...otherUpdates }));
  }, [rules, template, description, conditionStrategy, inPlanCreationFlow, onSuccessSavePayoutRulesCB]);

  const handleSaveRules = useCallback((successSavePayoutRulesCB = null) => {
    if (successSavePayoutRulesCB !== null) {
      setOnSuccessSavePayoutRulesCB(() => successSavePayoutRulesCB);
    }

    form.submit();
  }, [form]);

  useEffect(() => {
    if (inPlanCreationFlow && inPlanCreationFlowSetSaveCB !== null) {
      inPlanCreationFlowSetSaveCB(handleSaveRules);
    }
  }, [inPlanCreationFlow, inPlanCreationFlowSetSaveCB, handleSaveRules]);

  const handleAddingARule = useCallback(() => {
    const newRuleValue = PayoutRuleModel.transform({});
    const newOpenedCollapses = [...openedCollapses];

    handleSetRules([...rules, newRuleValue]);
    setOpenedCollapses([...newOpenedCollapses, true]);

    form.setFieldsValue({
      rules: [...rules, newRuleValue],
    });

    // Scroll to the new rule added.
    setTimeout(() => {
      const newRule = document.getElementById(`rule-payout-offset-${rules.length}`);
      newRule?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }, [
    handleSetRules,
    openedCollapses,
    rules,
  ]);

  const handleRemoveRule = useCallback((index) => {
    const newRules = [...rules];
    const newOpenedCollapses = [...openedCollapses];

    newRules.splice(index, 1);
    newOpenedCollapses.splice(index, 1);

    setReconciliationIndex(reconciliationIndex + 1);
    handleSetRules(newRules);
    setOpenedCollapses(newOpenedCollapses);

    form.setFieldsValue({
      rules: newRules,
    });
  }, [
    handleSetRules,
    openedCollapses,
    rules,
  ]);

  const handleRuleChange = useCallback((updatedRule, index) => {
    const newRules = [...rules];

    newRules.splice(index, 1, updatedRule);

    handleSetRules(newRules);

    form.setFieldsValue({
      rules: newRules,
    });
  }, [handleSetRules, rules]);

  const handleCollapseChange = useCallback((arr, index) => {
    const collapseName = arr[0];
    const newOpenedCollapses = [...openedCollapses];

    newOpenedCollapses.splice(index, 1, !!collapseName);

    setOpenedCollapses(newOpenedCollapses);
  }, [openedCollapses]);

  const handleConditionStrategyChange = (value) => {
    if (!value) {
      setConditionStrategy(PAYOUT_CONDITION_STRATEGIES.FIRST_MATCH);
    } else {
      setConditionStrategy(PAYOUT_CONDITION_STRATEGIES.ALL_MATCHES);
    }
  };

  const rulesNode = useMemo(() => rules.map((rule, index) => (
    <FormItem
      className={styles.formItemRule}
      name={[rule, index]}
      key={`rule-${index}-${reconciliationIndex}`}
      rules={[
        () => ({
          validator(_, value) {
            if (payoutScheduleValidationForm(value)) {
              return Promise.resolve();
            }

            return Promise.reject(new Error(t('masterPlanSettingsCommissionRulesForm.form.validator.reject')));
          },
        }),
      ]}
    >
      <MasterPlanSettingsPayoutRulesFormRule
        rule={rule}
        ruleNumberPosition={index + 1}
        helperData={helperData}
        onChange={(updatedRule) => handleRuleChange(updatedRule, index)}
        onRemove={() => handleRemoveRule(index)}
        onCollapseChange={(arr) => handleCollapseChange(arr, index)}
        openedCollapses={openedCollapses}
      />
    </FormItem>
  )), [rules, openedCollapses]);

  const disclaimerSaveBtnNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    return (
      <>
        <Disclaimer
          className={styles.disclaimer}
          icon="info"
          title={t('masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount', { count: getNbOfPeriodsSinceLastFrozenPeriod(plan) })}
        />
        <Button className={styles.saveRulesBtn} onClick={handleSaveRules} disabled={isPristine || updatePayoutRulesIsPending}>
          {t('masterPlanSettingsPayoutRulesForm.form.submitBtnLabel')}
        </Button>
      </>
    );
  }, [
    inPlanCreationFlow,
    plan,
    handleSaveRules,
    isPristine,
    updatePayoutRulesIsPending,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form className={styles.form} onFinish={handleFinish} initialValues={initialValues} form={form}>
        <div className={styles.content}>
          <FormItem
            className={styles.formItem}
            label={t('masterPlanSettingsPayoutRulesForm.form.choosePayoutStrategy')}
            name="payoutRulesTemplate"
            value={template}
          >
            <Select options={ruleTemplates} onChange={handleSetTemplate} />
          </FormItem>

          {template === PAYOUT_RULES_TEMPLATE.custom && (
            <div>
              <div className={styles.title}>{t('masterPlanSettingsPayoutRules.form.defineStrategy')}</div>

              <WeCanHelp className={styles.canHelpContainer} />

              <div className={styles.title}>{t('masterPlanSettingsPayoutRules.form.describeYourPayment')}</div>

              <FormItem
                className={styles.formItem}
                label={t('masterPlanSettingsPayoutRulesForm.form.descriptionLabel')}
                name="payoutRulesDescription"
                value={description}
              >
                <TextArea onChange={(desc) => setDescription(desc)} />
              </FormItem>

              <div className={styles.title}>{t('masterPlanSettingsPayoutRules.form.configurePaymentLogic')}</div>

              <FormulaHelper
                className={styles.formulaHelper}
                helperData={helperData}
              />

              <div className={styles.switchWrapper}>
                <div className={styles.switchLabel}>{t('masterPlanSettingsPayoutRules.form.firstMatch')}</div>
                <Switch
                  value={conditionStrategy === PAYOUT_CONDITION_STRATEGIES.ALL_MATCHES}
                  onChange={handleConditionStrategyChange}
                />
                <div className={styles.switchLabel}>{t('masterPlanSettingsPayoutRules.form.allMatches')}</div>
              </div>

              {rules.length === 0 && (
                // eslint-disable-next-line react/no-danger
                <p className={styles.noRulesText} dangerouslySetInnerHTML={{ __html: t('masterPlanSettingsPayoutRules.form.noRulesText') }} />
              )}
              {rules.length > 0 && rulesNode}
            </div>
          )}
        </div>
        {template === PAYOUT_RULES_TEMPLATE.custom && (
          <Button
            className={styles.addRuleBtn}
            icon={(<AddFilled />)}
            onClick={handleAddingARule}
            type="secondary"
          >
            {t('masterPlanSettingsPayoutRulesForm.form.addNewRule')}
          </Button>
        )}
        {disclaimerSaveBtnNode}
      </Form>
    </div>
  );
};

MasterPlanSettingsPayoutRulesForm.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  inPlanCreationFlow: PropTypes.bool,
  inPlanCreationFlowSetSaveCB: PropTypes.func,
  inPlanCreationFlowOnRulesUpdate: PropTypes.func,
  inPlanCreationFlowOnTemplateUpdate: PropTypes.func,
};

MasterPlanSettingsPayoutRulesForm.defaultProps = {
  className: '',
  inPlanCreationFlow: false,
  inPlanCreationFlowSetSaveCB: null,
  inPlanCreationFlowOnRulesUpdate: null,
  inPlanCreationFlowOnTemplateUpdate: null,
};

export default MasterPlanSettingsPayoutRulesForm;
