import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import EditablePeriodYear from '@palette/components/frequencies/EditablePeriodYear/EditablePeriodYear';
import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';
import Button from '@palette/components/designSystem/Button/Button';
import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';

import { getFrequencyPeriodName, getPreviousPeriod, getNextPeriod } from '@palette/helpers/FrequencyHelper';
import { getSearch, redirectTo } from '@palette/helpers/NavigationHelper';
import { getPlanFirstPeriod, getPlanLastPeriod } from '@palette/helpers/MasterPlanHelper';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

import { selectors as NavigationSelectors } from '@palette/state/Navigation';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanPeriodSelector.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodSelector = ({ className, plan, period, isIC }) => {
  const dispatch = useDispatch();
  const location = useSelector(NavigationSelectors.getCurrentLocation);

  const planFirstPeriod = useMemo(() => getPlanFirstPeriod(plan), [plan]);
  const planLastPeriod = useMemo(() => getPlanLastPeriod(plan), [plan]);

  const previousPeriod = useMemo(() => (
    getPreviousPeriod(plan.periodType, plan.frequency, period.year, period.period, plan.customBeginAt)
  ), [plan, period]);

  const nextPeriod = useMemo(() => (
    getNextPeriod(plan.periodType, plan.frequency, period.year, period.period, plan.customBeginAt)
  ), [plan, period]);

  const disablePreviousBtn = useMemo(() => (
    previousPeriod.year < planFirstPeriod.year
    || (
      previousPeriod.year === planFirstPeriod.year
      && previousPeriod.period < planFirstPeriod.period
    )
  ), [planFirstPeriod, previousPeriod]);

  const disableNextBtn = useMemo(() => (
    nextPeriod.year > planLastPeriod.year
    || (
      nextPeriod.year === planLastPeriod.year
      && nextPeriod.period > planLastPeriod.period
    )
  ), [planLastPeriod, nextPeriod]);

  const handlePeriodYearUpdate = useCallback((newPeriod) => {
    const qsObject = getSearch(location);

    redirectTo({
      path: isIC ? routePaths.v2.myPlanPeriodDetails : routePaths.v2.planPeriodDetails,
      params: {
        masterPlanId: plan.id,
        year: newPeriod.year,
        periodId: newPeriod.period,
      },
      qsObject,
    });
  }, [location]);

  const handleSelectPeriod = useCallback((newPeriod) => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.SELECT_PERIOD }));
    handlePeriodYearUpdate(newPeriod);
  }, [handlePeriodYearUpdate]);

  const handlePrevPeriodClick = useCallback(() => {
    if (!disablePreviousBtn) {
      dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_PREVIOUS_PERIOD }));
      handlePeriodYearUpdate(previousPeriod);
    }
  }, [disablePreviousBtn, previousPeriod, handlePeriodYearUpdate]);

  const handleNextPeriodClick = useCallback(() => {
    if (!disableNextBtn) {
      dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_NEXT_PERIOD }));
      handlePeriodYearUpdate(nextPeriod);
    }
  }, [disableNextBtn, nextPeriod, handlePeriodYearUpdate]);

  const getPeriodContentDisplay = (periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay = null, fiscalYearShiftToDisplay = 0) => (
    <div className={styles.contentWrapper}>
      <div className={styles.content}>
        {getFrequencyPeriodName(periodTypeToDisplay, frequencyToDisplay, yearToDisplay, periodToDisplay, beginDateToDisplay, false, fiscalYearShiftToDisplay)}
      </div>
      <ChevronDownLine className={styles.contentIcon} width={24} height={24} />
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <EditablePeriodYear
        className={styles.editablePeriodYear}
        periodType={plan.periodType}
        frequency={plan.frequency}
        fiscalYearShift={plan.fiscalYearShift}
        beginDate={plan.customBeginAt}
        initialPeriod={period.period}
        initialYear={period.year}
        onUpdate={handleSelectPeriod}
        getContentDisplay={getPeriodContentDisplay}
        disableBeforePeriod={planFirstPeriod}
        disableAfterPeriod={planLastPeriod}
      />
      <div className={styles.prevNextWrapper}>
        <Button
          className={styles.prevNextBtn}
          type="link"
          icon={<ChevronLeftLine width={24} height={24} />}
          onClick={handlePrevPeriodClick}
          disabled={disablePreviousBtn}
        />
        <Button
          className={styles.prevNextBtn}
          type="link"
          icon={<ChevronRightLine width={24} height={24} />}
          onClick={handleNextPeriodClick}
          disabled={disableNextBtn}
        />
      </div>
    </div>
  );
};

MasterPlanPeriodSelector.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: YearPeriodModel.propTypes.isRequired,
  isIC: PropTypes.bool,
};

MasterPlanPeriodSelector.defaultProps = {
  className: '',
  isIC: false,
};

export default MasterPlanPeriodSelector;
