import CallMeHandLottie from '@palette/lotties/call-me-hand.json';
import ClappingHandsLottie from '@palette/lotties/clapping-hands.json';
import FireworksLottie from '@palette/lotties/fireworks.json';

export const LOTTIES = {
  CALL_ME_HAND: 'CALL_ME_HAND',
  CLAPPING_HANDS: 'CLAPPING_HANDS',
  FIREWORKS: 'FIREWORKS',
};

export const LOTTIES_ANIMATIONS = {
  CALL_ME_HAND: CallMeHandLottie,
  CLAPPING_HANDS: ClappingHandsLottie,
  FIREWORKS: FireworksLottie,
};
