import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _groupBy from 'lodash/groupBy';

import ContextVariablesList from '@palette/components/planV3/ContextVariablesList/ContextVariablesList';

import { getMoment } from '@palette/helpers/MomentHelper';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import styles from './PlanV3EditPageContentLeftSide.less';

const classNames = bindClassNames.bind(styles);

const PlanV3EditPageContentLeftSide = ({ className, details }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const { startPeriod, endPeriod, frequency, variables } = details;

  const variablesListNode = useMemo(() => {
    const groupBySource = _groupBy(variables, (variable) => (variable.source));

    return Object.entries(groupBySource).map(([source, variablesList], index) => (
      <ContextVariablesList
        key={`variables-${source}-${index}`}
        source={source}
        variablesList={variablesList}
      />
    ));
  }, [variables]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {variablesListNode}
      <div className={styles.summary}>
        <div className={styles.title}>
          {t('editPlanPage.context.summary.title')}
        </div>
        {startPeriod && (
          <>
            <div className={styles.subtitle}>{t('editPlanPage.context.requirements.start')}</div>
            <div className={styles.value}>{moment(startPeriod).format('YYYY-MM-DD')}</div>
          </>
        )}
        {endPeriod && (
          <>
            <div className={styles.subtitle}>{t('editPlanPage.context.requirements.end')}</div>
            <div className={styles.value}>{moment(endPeriod).format('YYYY-MM-DD')}</div>
          </>
        )}
        {frequency && (
          <>
            <div className={styles.subtitle}>{t('editPlanPage.context.requirements.frequency')}</div>
            <div className={styles.value}>{t(`createPlanPage.form.frequency.option.${PLAN_PERIODICITY_TYPE[frequency]}`)}</div>
          </>
        )}
      </div>
    </div>
  );
};

PlanV3EditPageContentLeftSide.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
};

PlanV3EditPageContentLeftSide.defaultProps = {
  className: '',
};

export default PlanV3EditPageContentLeftSide;
