import PropTypes from 'prop-types';

import { manageFreeShapeObjectAttribute } from '@palette/helpers/ModelHelper';

import * as PlanContentModel from '@palette/models/globalNotifStrategies/plan/PlanContent';

export const modelName = 'PlanPercentageRuleTargetErrorStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  plan: PlanContentModel.propTypes,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'PlanPercentageRuleTargetErrorContentStrategy',
      plan: PlanContentModel.transform(manageFreeShapeObjectAttribute(strategy, 'plan')),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
