import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import Modal from '@palette/components/designSystem/Modal/Modal';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import Link from '@palette/components/designSystem/Link/Link';
import PeriodDealCommissionValue from '@palette/components/masterPlanPeriod/PeriodDealCommissionValue/PeriodDealCommissionValue';
import PeriodDealCommissionInfos from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfos/PeriodDealCommissionInfos';
import PeriodDealOwners from '@palette/components/masterPlanPeriod/PeriodDealOwners/PeriodDealOwners';

import { useMasterPlanColumns } from '@palette/hooks/MasterPlanHooks';

import { getPlanFirstPeriod, getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';
import { getColumnValue } from '@palette/helpers/ConnectorHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { applyGenericTimezone } from '@palette/helpers/MomentHelper';
import { getCommissionValue } from '@palette/helpers/CommissionHelper';

import { SCOPES } from '@palette/constants/masterPlans';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowCommissionsResultsModal.less';

const CreatePlanFlowCommissionsResultsModal = ({ visible, onClose, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planFirstPeriod = useMemo(() => (
    getPlanFirstPeriod(plan)
  ), [plan]);

  const planColumns = useMasterPlanColumns(plan);

  const getPeriodDealsIsPending = useSelector(MasterPlansSelectors.getPeriodDealsIsPending);
  const periodDeals = useSelector((state) => (MasterPlansSelectors.getMasterPlanPeriodDeals(state, { masterPlanId: plan.id, year: planFirstPeriod.year, periodId: planFirstPeriod.period })));
  const periodDealsPagination = useSelector((state) => (
    MasterPlansSelectors.getMasterPlanPeriodDealsPagination(state, { masterPlanId: plan.id, year: planFirstPeriod.year, periodId: planFirstPeriod.period })),
  );

  const [currentPage, setCurrentPage] = useState(periodDealsPagination?.page || 0);
  const [currentLimit, setCurrentLimit] = useState(periodDealsPagination?.limit || 20);

  useEffect(() => {
    setCurrentPage(periodDealsPagination?.page || 0);
    setCurrentLimit(periodDealsPagination?.limit || 20);
  }, [periodDealsPagination]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  });

  const handleLimitChange = useCallback((newLimit) => {
    setCurrentLimit(newLimit);
  });

  useEffect(() => {
    dispatch(MasterPlansActions.getPeriodDeals({
      planId: plan.id,
      year: planFirstPeriod.year,
      period: planFirstPeriod.period,
      page: currentPage,
      limit: currentLimit,
    }));
  }, [currentPage, currentLimit]);

  const cleanAndClose = () => {
    onClose();
  };

  const fixedColumns = useMemo(() => {
    const cols = [];

    const usersColumn = {
      id: 'periodDealUser',
      Header: plan.usersDefinition?.type || t('masterPlanPeriodDeals.table.headers.user'),
      accessor: 'users',
      minWidth: 200,
      width: '20%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<PeriodDealOwners owners={value} />),
    };

    cols.push(usersColumn);

    if (
      plan.quotaId !== null
      && (
        plan.scope === SCOPES.MANAGER
        || plan.scope === SCOPES.TEAM
      )
    ) {
      const progressColumn = {
        id: 'progress',
        Header: t('masterPlanPeriodDeals.table.headers.progress'),
        accessor: (periodDeal) => `${floatToFixedNumber(periodDeal.progress * 100)}%`,
        minWidth: 100,
        width: '10%',
      };

      cols.push(progressColumn);
    }

    if (plan.trackingObject?.valueFormula !== '1') {
      const revenueColumn = {
        id: 'revenue',
        Header: getPlanValueDefinition(plan),
        accessor: (periodDeal) => {
          let dealValue = periodDeal.value;
          if (periodDeal.commissions.length === 1) {
            dealValue = getCommissionValue(periodDeal.commissions[0]);
          }

          return comaSeparatorFormatter(floatToFixedNumber(dealValue));
        },
        minWidth: 100,
        maxWidth: 150,
        width: '15%',
      };

      cols.push(revenueColumn);
    }

    const dateColumn = {
      id: 'date',
      Header: t('masterPlanPeriodDeals.table.headers.date'),
      accessor: (periodDeal) => applyGenericTimezone(periodDeal.date, plan.timezone).format('ll'),
      minWidth: 150,
      maxWidth: 150,
      width: '15%',
    };

    cols.push(dateColumn);

    if (plan.rules.length > 0) {
      const commissionsColumn = {
        id: 'commissions',
        Header: (
          <div className={styles.headerCommissions}>
            {t('masterPlanPeriodDeals.table.headers.commissions')}
          </div>
        ),
        accessor: (periodDeal) => periodDeal,
        minWidth: 100,
        maxWidth: 150,
        width: '15%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          const totalAmount = formatPrice(value.totalCommissionAmount, value.currency);
          const hasDot = value.commissions?.some((commission) => commission.overwrittenAmount !== null);

          return (
            <PeriodDealCommissionValue
              value={value.totalCommissionAmount}
              formattedValue={totalAmount}
              hasDot={hasDot}
              hasOnlyTargetRule={value.hasOnlyTargetRule}
            />
          );
        },
        /* eslint-enable react/prop-types */
        highlight: true,
      };

      cols.push(commissionsColumn);

      if (plan.rules.length === 1) {
        const commissionInfosColumn = {
          id: 'commissionInfos',
          accessor: (periodDeal) => periodDeal,
          minWidth: 230,
          maxWidth: 250,
          width: '25%',
          /* eslint-disable react/prop-types */
          Cell: ({ value }) => {
            if (value.commissions.length !== 1) return null;

            return <PeriodDealCommissionInfos commission={value.commissions[0]} />;
          },
          /* eslint-enable react/prop-types */
        };

        cols.push(commissionInfosColumn);
      }
    }

    return cols;
  }, [
    plan,
    planFirstPeriod,
  ]);

  const tableColumns = useMemo(() => {
    const planTableColumns = planColumns.map((column, index) => {
      const planColumnId = `planColumn_${index}_${column.name}`;

      const columnDefinition = {
        id: planColumnId,
        Header: column.name,
        accessor: (periodDeal) => getColumnValue(periodDeal.resourceObject?.data || {}, column),
        minWidth: 150,
        maxWidth: 250,
        width: 'auto',
      };

      if (index === 0) {
        columnDefinition.width = '25%';
      }

      return columnDefinition;
    });

    const indexColumn = {
      id: 'indexColumn',
      Header: '#',
      accessor: (periodDeal) => (periodDeal),
      minWidth: 130,
      maxWidth: 150,
      width: '15%',
      /* eslint-disable react/prop-types */
      Cell: ({ value, row }) => {
        const dealPath = routePaths.v2.resourceDetails;
        const dealPathParams = {
          connectorId: plan.trackingObject?.connectorId,
          type: plan.trackingObject?.type,
          resourceId: value.resourceObjectId,
        };
        const rowIndex = currentPage * currentLimit + row.index + 1;
        return (
          <Link path={dealPath} params={dealPathParams} displayIcon>
            {`${plan.trackingObject?.type} #${rowIndex}`}
          </Link>
        );
      },
      /* eslint-enable react/prop-types */
    };

    return [indexColumn].concat(planTableColumns, fixedColumns);
  }, [
    plan,
    planColumns,
    fixedColumns,
    currentPage,
    currentLimit,
  ]);

  const contentNode = useMemo(() => {
    if (periodDeals.length === 0) {
      return (
        <DefaultEmptyState description={t('masterPlanPeriodDeals.empty.description', { dealType: pluralize.singular(plan.trackingObject?.type || '') })} />
      );
    }

    return (
      <>
        <Table
          type="borderless"
          columns={tableColumns}
          data={periodDeals}
          nbOfFixedColumns={plan.rules.length === 1 ? 2 : 1}
          fixedColumnsPosition="fromRight"
        />
        <Pagination
          className={styles.pagination}
          pagination={periodDealsPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </>
    );
  }, [
    plan,
    periodDeals,
    periodDealsPagination,
    tableColumns,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <Modal
      className={styles.modal}
      title={t('createPlanFlowCommissionsResultsModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      okText={null}
      cancelText={t('createPlanFlowCommissionsResultsModal.close')}
      loading={getPeriodDealsIsPending}
    >
      {contentNode}
    </Modal>
  );
};

CreatePlanFlowCommissionsResultsModal.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

CreatePlanFlowCommissionsResultsModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CreatePlanFlowCommissionsResultsModal;
