import PropTypes from 'prop-types';

import { manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'NotebookDataframeNameCopiedStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  name: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'NotebookDataframeNameCopiedContentStrategy',
      name: manageStringAttribute(strategy, 'dataframeName'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
