import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import AlertOctagon from '@palette/components/utils/Icons/AlertOctagon';

import styles from './StatementCounterCorrections.less';

const classNames = bindClassNames.bind(styles);

const StatementCounterCorrections = ({ className, counter }) => {
  const { t } = useTranslation();

  if (counter === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.content}>
        <AlertOctagon className={styles.icon} />
        <div className={styles.label}>
          {t('statement.counters.label.correctionsCount', { count: counter })}
        </div>
      </div>
    </div>
  );
};

StatementCounterCorrections.propTypes = {
  className: PropTypes.string,
  counter: PropTypes.number.isRequired,
};

StatementCounterCorrections.defaultProps = {
  className: '',
};

export default StatementCounterCorrections;
