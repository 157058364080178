import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PlanV3EditAchievementPageContent from '@palette/components/planV3/PlanV3EditAchievementPageContent/PlanV3EditAchievementPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { EDIT_PAGE_TYPE } from '@palette/constants/planV3';

import styles from './PlanV3EditAchievementPage.less';

const PlanV3EditAchievementPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { planId } = useParams();

  if (
    !planId
    || !hasAllRights(profile, [
      RIGHTS.ADMIN.PLANS.VIEW,
      RIGHTS.ADMIN.PLANS.CREATE,
      RIGHTS.ADMIN.PLANS.UPDATE,
      RIGHTS.ADMIN.PLANS.GBO.MANAGE,
      RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
      RIGHTS.ADMIN.QUOTAS.VIEW,
      RIGHTS.ADMIN.QUOTAS.MANAGE,
      RIGHTS.ADMIN.QUOTAS.USERS.MANAGE,
    ])
  ) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.editPlan')} />
      <PlanV3EditAchievementPageContent planId={planId} type={EDIT_PAGE_TYPE.ACHIEVEMENT} />
    </div>
  );
};

export default PlanV3EditAchievementPage;
