import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';
import { line } from 'd3-shape';

import ChartTooltip from '@palette/components/designSystem/ChartTooltip/ChartTooltip';
import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { floatToFixedNumber, formatNumberShortDisplay } from '@palette/helpers/CommonHelper';
import { addCurrency, formatPrice } from '@palette/helpers/CurrencyHelper';
import {
  getPeriodElapsedTimePercentage,
  getPeriodProgressChartGradient,
  getPlanValueDefinition,
} from '@palette/helpers/MasterPlanHelper';
import { mapRange } from '@palette/helpers/InterpolationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import {
  CHART_GRADIENT_BLUE,
  CHART_GRADIENT_GREEN,
  CHART_GRADIENT_ORANGE,
  CHART_GRADIENT_TRANSPARENT,
  COLOR_NEUTRALS_GREY_2,
  gradientsDefs,
  MARKER_COLOR,
  TEXT_STYLE_CAPTION_1,
} from '@palette/constants/charts';
import { RIGHTS } from '@palette/constants/profile';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as PlanWhatIfProjectionDataModel from '@palette/models/widgets/dashboard/PlanWhatIfProjectionData';
import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './PlanWhatIfProjectionChart.less';

const classNames = bindClassNames.bind(styles);

const estimationGroupId = 'estimation';
const userGroupId = 'user';

const PlanWhatIfProjectionChart = ({
  className,
  plan,
  period,
  data,
  forAdmin,
  user,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const planValueDefinition = getPlanValueDefinition(plan);

  const icGetPeriodEstimationIsPending = useSelector(MasterPlansSelectors.getICPeriodEstimationIsPending);
  const icEstimation = useSelector((state) => (MasterPlansSelectors.getICPlanPeriodEstimation(state, { masterPlanId: plan.id, year: period.year, periodId: period.period })));

  const adminGetPeriodEstimationIsPending = useSelector(MasterPlansSelectors.getPeriodEstimationForUserIsPending);
  const userId = user !== null ? user.id : null;
  const adminEstimation = useSelector((state) => (
    MasterPlansSelectors.getPlanPeriodEstimationForUser(state, { masterPlanId: plan.id, year: period.year, periodId: period.period, userId })),
  );

  const { getPeriodEstimationIsPending, estimation } = useMemo(() => {
    if (forAdmin) {
      return {
        getPeriodEstimationIsPending: adminGetPeriodEstimationIsPending,
        estimation: adminEstimation,
      };
    }

    return {
      getPeriodEstimationIsPending: icGetPeriodEstimationIsPending,
      estimation: icEstimation,
    };
  }, [
    forAdmin,
    adminGetPeriodEstimationIsPending,
    adminEstimation,
    icGetPeriodEstimationIsPending,
    icEstimation,
  ]);

  useEffect(() => {
    if (forAdmin && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) {
      dispatch(MasterPlansActions.getPeriodEstimationForUser({
        planId: plan.id,
        year: period.year,
        period: period.period,
        user,
      }));
    } else if (hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) {
      dispatch(MasterPlansActions.getICPeriodEstimation({
        planId: plan.id,
        year: period.year,
        period: period.period,
      }));
    }
  }, [
    plan,
    period,
    forAdmin,
    user,
  ]);

  const getTooltip = (dataDesc) => (
    <ChartTooltip
      title={`${formatPrice(dataDesc.point.data.y, dataDesc.point.data.currency)} (${dataDesc.point.data.x}%)`}
      content={`${planValueDefinition} · ${formatNumberShortDisplay(dataDesc.point.data.dealValue)}`}
    />
  );

  const chartNode = useMemo(() => {
    if (estimation === null) return null;

    const estimationGroupData = [];
    const userGroupData = [];
    const tickValues = [0];
    const remarkablePoints = [];
    let userProgressIsIncluded = false;

    const userProgress = data?.periodData?.progress || null;
    /* eslint-disable-next-line prefer-destructuring */
    let currency = profile.userData.currency;

    estimation.forEach((estimationDataPoint, estimationDataPointIndex) => {
      const progress = floatToFixedNumber(estimationDataPoint.progress * 100, 0);
      const { amount } = estimationDataPoint;
      const dealValue = formatNumberShortDisplay(estimationDataPoint.value);
      currency = estimationDataPoint.currency;

      const dataPoint = {
        x: progress,
        y: amount,
        dealValue,
        currency,
      };

      if (userProgress !== null) {
        if (estimationDataPoint.progress < userProgress) {
          userGroupData.push(dataPoint);
        } else if (!userProgressIsIncluded) {
          const userProgressPercent = floatToFixedNumber(userProgress * 100, 0);

          if (estimationDataPoint.progress !== userProgress) {
            const prevEstimationDataPoint = estimation[estimationDataPointIndex - 1];

            const userProgressDataPoint = {
              x: userProgressPercent,
              y: mapRange(userProgress, prevEstimationDataPoint.progress, estimationDataPoint.progress, prevEstimationDataPoint.amount, estimationDataPoint.amount),
              dealValue: formatNumberShortDisplay(
                mapRange(userProgress, prevEstimationDataPoint.progress, estimationDataPoint.progress, prevEstimationDataPoint.value, estimationDataPoint.value),
              ),
              currency,
            };

            userGroupData.push(userProgressDataPoint);

            estimationGroupData.push(userProgressDataPoint);
          } else {
            const userProgressDataPoint = {
              x: userProgressPercent,
              y: data.periodData.totalCommissionAmount,
              dealValue: data.periodData.totalDealValue,
              currency,
            };

            userGroupData.push(userProgressDataPoint);
          }

          userProgressIsIncluded = true;
        }
      }

      estimationGroupData.push(dataPoint);

      if (estimationDataPoint.isFixed || estimationDataPoint.isBracket) {
        tickValues.push(progress);
        remarkablePoints.push(dataPoint);
      }
    });

    const chartData = [
      {
        id: estimationGroupId,
        data: estimationGroupData,
      },
    ];

    if (userProgress !== null) {
      chartData.push({
        id: userGroupId,
        data: userGroupData,
      });
    }

    const markers = [
      {
        axis: 'y',
        value: 0,
        lineStyle: { stroke: MARKER_COLOR, strokeWidth: 2 },
        textStyle: { ...TEXT_STYLE_CAPTION_1, stroke: COLOR_NEUTRALS_GREY_2 },
        legend: '',
        legendPosition: 'top-left',
        legendOffsetX: -25,
        legendOffsetY: 10,
      },
    ];

    if (userProgress !== null) {
      const periodProgressPercentage = floatToFixedNumber(data.periodData.progress * 100);
      const periodElapsedTimePercentage = getPeriodElapsedTimePercentage(plan, data.periodData);

      const progressChartGradient = getPeriodProgressChartGradient(periodElapsedTimePercentage, periodProgressPercentage);

      const userProgressPercentage = floatToFixedNumber(userProgress * 100, 0);

      markers.push({
        axis: 'x',
        value: userProgressPercentage,
        lineStyle: { stroke: progressChartGradient.colors[1].color, strokeWidth: 2 },
        textStyle: { ...TEXT_STYLE_CAPTION_1, stroke: progressChartGradient.colors[1].color },
        legend: `${formatPrice(data.periodData.totalCommissionAmount, currency)} (${userProgressPercentage}%)`,
        legendPosition: userProgressPercentage < 50 ? 'top-right' : 'top-left',
        legendOffsetX: 10,
        legendOffsetY: 10,
      });
    }

    const remarkablePointsLayer = ({ series, xScale, yScale }) => {
      if (series[0].data.length === 0) return null;

      const lineGenerator = line()
        .x((d) => xScale(d.x))
        .y((d) => yScale(d.y));

      const lastProgressValue = series[0].data[series[0].data.length - 1].data.x;

      return remarkablePoints.map((remarkablePoint) => (
        <g key={remarkablePoint.x}>
          <path
            d={lineGenerator([{ x: remarkablePoint.x, y: 0 }, { x: remarkablePoint.x, y: remarkablePoint.y }, { x: lastProgressValue, y: remarkablePoint.y }])}
            stroke={remarkablePoint.x < 100 ? CHART_GRADIENT_ORANGE.colors[1].color : CHART_GRADIENT_GREEN.colors[1].color}
            strokeWidth={1}
            strokeDasharray={4}
            fill="none"
          />
          <circle
            cx={xScale(remarkablePoint.x)}
            cy={yScale(remarkablePoint.y)}
            r="3"
            fill={remarkablePoint.x < 100 ? CHART_GRADIENT_ORANGE.colors[1].color : CHART_GRADIENT_GREEN.colors[1].color}
          />
        </g>
      ));
    };

    return (
      <div className={styles.chartWrapper}>
        <div className={styles.chart}>
          <ResponsiveLine
            data={chartData}
            margin={{ top: 0, right: 55, bottom: 55, left: 5 }}
            xScale={{ type: 'linear', min: 0 }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: false,
              reverse: false,
            }}
            curve="linear"
            colors={[CHART_GRADIENT_BLUE.colors[1].color, 'transparent']}
            axisRight={{
              tickSize: 0,
              tickPadding: 8,
              tickRotation: 0,
              legend: t('planWhatIfProjectionChart.axisRightLabel'),
              legendOffset: 65,
              legendPosition: 'middle',
              format: (value) => addCurrency(formatNumberShortDisplay(value), currency),
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 8,
              tickRotation: 0,
              legend: t('planWhatIfProjectionChart.axisBottomLabel'),
              legendOffset: 40,
              legendPosition: 'middle',
              tickValues,
              format: (value) => (`${value}%`),
            }}
            axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            lineWidth={2}
            enablePoints={false}
            enableArea
            useMesh
            crosshairType="bottom-right"
            motionConfig="slow"
            markers={markers}
            defs={gradientsDefs}
            fill={[
              { match: (d) => (d.id === userGroupId), id: CHART_GRADIENT_BLUE.id },
              { match: '*', id: CHART_GRADIENT_TRANSPARENT.id },
            ]}
            tooltip={getTooltip}
            layers={['grid', 'axes', 'areas', 'lines', remarkablePointsLayer, 'crosshair', 'points', 'slices', 'mesh', 'legends', 'markers']}
          />
        </div>
      </div>
    );
  }, [estimation, plan, period, data]);

  const contentNode = useMemo(() => {
    if (getPeriodEstimationIsPending) {
      return (
        <Loader />
      );
    }

    if (estimation === null) {
      return (
        <DefaultEmptyState />
      );
    }

    return chartNode;
  }, [
    getPeriodEstimationIsPending,
    estimation,
    chartNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

PlanWhatIfProjectionChart.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  data: PlanWhatIfProjectionDataModel.propTypes,
  forAdmin: PropTypes.bool,
  user: MetaUserModel.propTypes,
};

PlanWhatIfProjectionChart.defaultProps = {
  className: '',
  data: null,
  forAdmin: false,
  user: null,
};

export default PlanWhatIfProjectionChart;
