import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import Button from '@palette/components/designSystem/Button/Button';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import UserInPlansSelector from '@palette/components/user/UserInPlansSelector/UserInPlansSelector';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import Loader from '@palette/components/utils/Loader/Loader';
import StatementsSnapshotsExportList from '@palette/components/statement/statementValidation/StatementsSnapshotsExportList/StatementsSnapshotsExportList';

import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements';

import styles from './StatementsSnapshotsExportPageContent.less';

const classNames = bindClassNames.bind(styles);

const StatementsSnapshotsExportPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const listStatementsSnapshotsIsPending = useSelector(StatementsSelectors.listStatementsSnapshotsIsPending);
  const getListStatementsSnapshotsCallData = useSelector(StatementsSelectors.getListStatementsSnapshotsCallData);

  const [selectedUser, setSelectedUser] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [disabledForm, setDisabledForm] = useState(true);

  const getFiltersData = useMemo(() => ({
    userId: selectedUser,
    from,
    to,
  }), [
    selectedUser,
    from,
    to,
  ]);

  useEffect(() => {
    if (selectedUser !== null && from !== null && to !== null && !_isEqual(getListStatementsSnapshotsCallData, getFiltersData)) {
      setDisabledForm(false);
    } else {
      setDisabledForm(true);
    }
  }, [
    selectedUser,
    from,
    to,
    getFiltersData,
    getListStatementsSnapshotsCallData,
  ]);

  useEffect(() => dispatch(StatementsActions.resetStatementsSnapshots()), []);

  const handleUserSelect = useCallback((userId) => setSelectedUser(userId || null), []);

  const handleDatesChange = useCallback((newDates) => {
    setFrom(newDates[0]);
    setTo(newDates[1]);
  }, [setFrom, setTo]);

  const handleSubmit = useCallback(() => {
    if (getFiltersData.userId !== null && getFiltersData.from !== null && getFiltersData.to !== null && !_isEqual(getListStatementsSnapshotsCallData, getFiltersData)) {
      dispatch(StatementsActions.listStatementsSnapshots(getFiltersData));
    }
  }, [
    getFiltersData,
    getListStatementsSnapshotsCallData,
  ]);

  const userSelectorNode = useMemo(() => (
    <UserInPlansSelector
      className={classNames({
        field: true,
        userField: true,
      })}
      onUserSelected={handleUserSelect}
      searchedUser={selectedUser}
    />
  ), [
    selectedUser,
    handleUserSelect,
  ]);

  const datesSelectorNode = useMemo(() => (
    <DatePicker
      className={classNames({
        field: true,
        datesField: true,
      })}
      allowClear={false}
      disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
      showTime={false}
      showNow={false}
      isRange
      value={[from, to]}
      onChange={handleDatesChange}
    />
  ), [from, to, handleDatesChange]);

  const submitButtonNode = useMemo(() => (
    <Button
      className={styles.field}
      type="secondary"
      icon={<StatementsFilled width={20} height={20} />}
      disabled={disabledForm || listStatementsSnapshotsIsPending}
      onClick={handleSubmit}
    >
      {t('statementsSnapshotsExport.submit.button.label')}
    </Button>
  ), [
    disabledForm,
    handleSubmit,
    listStatementsSnapshotsIsPending,
  ]);

  const resultsNode = useMemo(() => (
    <Loader spinning={listStatementsSnapshotsIsPending}>
      <StatementsSnapshotsExportList />
    </Loader>
  ), [
    listStatementsSnapshotsIsPending,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        {userSelectorNode}
        {datesSelectorNode}
        {submitButtonNode}
      </div>
      {resultsNode}
    </div>
  );
};

StatementsSnapshotsExportPageContent.propTypes = {
  className: PropTypes.string,
};

StatementsSnapshotsExportPageContent.defaultProps = {
  className: '',
};

export default StatementsSnapshotsExportPageContent;
