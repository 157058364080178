import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as NotebookCodeCellConflictingOutputNameStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookCodeCellConflictingOutputNameStrategy';

const classNames = bindClassNames.bind();

const NotebookCodeCellConflictingOutputNameContentStrategy = ({ className, content }) => {
  const { cellTitle } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notebookCell.errors.type.notebook.codeCell.conflicting.outputName.content"
        values={{ cellTitle }}
      />
    </span>
  );
};

NotebookCodeCellConflictingOutputNameContentStrategy.propTypes = {
  className: PropTypes.string,
  content: NotebookCodeCellConflictingOutputNameStrategyModel.propTypes.isRequired,
};

NotebookCodeCellConflictingOutputNameContentStrategy.defaultProps = {
  className: '',
};

export default NotebookCodeCellConflictingOutputNameContentStrategy;
