import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Avatar from '@palette/components/user/Avatar/Avatar';
import CommentMessageTypeFilled from '@palette/components/utils/Icons/CommentMessageTypeFilled';
import CommentEventTypeFilled from '@palette/components/utils/Icons/CommentEventTypeFilled';
import CheckFilledLinearGreen from '@palette/components/utils/Icons/CheckFilledLinearGreen';

import { TYPES } from '@palette/constants/comments';
import { STATUSES } from '@palette/constants/threads';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { fromNowOrShort } from '@palette/helpers/MomentHelper';
import { getCommentEventContent, getActionTargetName } from '@palette/helpers/ThreadHelper';
import { computePathname, stringifyQS } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';

import { RIGHTS } from '@palette/constants/profile';
import { PERIOD_TABS_IDS, PERIOD_TABS_QS_KEY } from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import * as ThreadModel from '@palette/models/Thread';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './InlineThread.less';

const classNames = bindClassNames.bind(styles);

const InlineThread = ({ className, thread }) => {
  const profile = useProfile();
  const history = useHistory();
  const { t } = useTranslation();

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: thread[thread.type]?.resourceObject?.connectorId }));

  const handleClick = () => (
    hasAtLeastOneRight(profile, [RIGHTS.ADMIN.OBJECTS.VIEW])
      ? history.push({
        pathname: computePathname(routePaths.v2.planPeriodDetails, {
          masterPlanId: thread[thread.type]?.scopeParams?.plan?.id,
          year: thread[thread.type]?.scopeParams?.period?.year,
          periodId: thread[thread.type]?.scopeParams?.period?.period,
        }),
        search: stringifyQS({
          [PERIOD_TABS_QS_KEY]: PERIOD_TABS_IDS.DEALS,
          commissionId: thread[thread.type]?.id,
        }),
      })
      : history.push({
        pathname: computePathname(routePaths.v2.myPlanPeriodDetails, {
          masterPlanId: thread[thread.type]?.scopeParams?.plan?.id,
          year: thread[thread.type]?.scopeParams?.period?.year,
          periodId: thread[thread.type]?.scopeParams?.period?.period,
        }),
        search: stringifyQS({
          commissionId: thread[thread.type]?.id,
        }),
      })
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      onClick={handleClick}
    >
      <div className={styles.header}>
        <div className={styles.avatar_and_title}>
          <div className={styles.avatarWrapper}>
            <Avatar size={30} user={thread.lastComment.user} />
            <div className={styles.commentTypeIconWrapper}>
              {thread.lastComment.type === TYPES.MESSAGE ? (<CommentMessageTypeFilled />) : (<CommentEventTypeFilled />)}
            </div>
          </div>
          <div className={styles.title}>
            <span className={styles.name}>
              {`${thread.lastComment.user.firstName} ${thread.lastComment.user.lastName.length > 0 ? `${thread.lastComment.user.lastName.substring(0, 1)}.` : ''}`}
            </span>
            &nbsp;
            <span className={styles.action}>
              {thread.lastComment.type === TYPES.MESSAGE ? t('thread.action.commented') : `${t('thread.action.changed')} ${getActionTargetName(thread, connector)}`}
            </span>
          </div>
        </div>
        <div className={styles.date}>
          {fromNowOrShort(thread.lastCommentAt)}
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {
            thread.lastComment.type === TYPES.MESSAGE
              ? (<blockquote>{thread.lastComment.content}</blockquote>)
              : getCommentEventContent(thread.lastComment.content, thread[thread.type]?.scopeParams?.plan?.currency, getPlanValueDefinition(thread[thread.type]?.scopeParams?.plan))
          }
        </div>
        {thread.status === STATUSES.RESOLVED && (<CheckFilledLinearGreen width={20} height={20} className={styles.resolvedIcon} />)}
      </div>
    </div>
  );
};

InlineThread.propTypes = {
  className: PropTypes.string,
  thread: ThreadModel.propTypes.isRequired,
};

InlineThread.defaultProps = {
  className: '',
};

export default InlineThread;
