import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntDForm } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import Form from '@palette/components/designSystem/Form/Form';
import ArrowRightCircle from '@palette/components/utils/Icons/ArrowRightCircle';
import PlanV3FormRequirementsGeneral from '@palette/components/planV3/planV3FormItems/PlanV3FormRequirementsGeneral/PlanV3FormRequirementsGeneral';
import PlanV3FormRequirementsUsers from '@palette/components/planV3/planV3FormItems/PlanV3FormRequirementsUsers/PlanV3FormRequirementsUsers';
import PlanV3FormRequirementsDataSources from '@palette/components/planV3/planV3FormItems/PlanV3FormRequirementsDataSources/PlanV3FormRequirementsDataSources';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

import { getMoment } from '@palette/helpers/MomentHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as PlanV3ConfigurationModel from '@palette/models/PlanV3Configuration';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3FormRequirements.less';

const classNames = bindClassNames.bind(styles);

const PlanV3FormRequirements = ({ className, configuration }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const dataConnections = useSelector(PlanV3Selectors.getDataConnections);
  const createPlanIsPending = useSelector(PlanV3Selectors.createPlanIsPending);
  const updateConfigurationIsPending = useSelector(PlanV3Selectors.updateConfigurationIsPending);

  const createOrUpdateIsPending = createPlanIsPending || updateConfigurationIsPending;

  const [users, setUsers] = useState([]);
  const [selectedUsersIds] = useState(configuration?.usersIds ?? []);
  const [afterCreationCB, setAfterCreationCB] = useState(null);

  const [form] = AntDForm.useForm();

  const internalInitialValues = useMemo(() => ({
    name: configuration?.name ?? '',
    description: configuration?.description ?? '',
    frequency: configuration?.frequency ?? PLAN_PERIODICITY_TYPE.MONTH,
    startPeriod: configuration?.startPeriod ? moment(configuration.startPeriod) : null,
    endPeriod: configuration?.endPeriod ? moment(configuration.endPeriod) : null,
    dayInterval: configuration?.dayInterval ?? 7,
  }), [configuration]);

  useEffect(() => {
    if (configuration) {
      configuration.dataSources.forEach((dataSource) => {
        dispatch(PlanV3Actions.addDataConnectionConnector(dataSource));
      });
    }
  }, [configuration]);

  const initialValues = useMemo(() => ({
    ...internalInitialValues,
  }), [internalInitialValues]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    if (changedValues.frequency !== undefined && formValues.frequency !== changedValues.frequency) {
      form.validateFields();
    }

    setFormValues({
      ...formValues,
      ...allValues,
    });
  }, [formValues]);

  const handleFinish = useCallback((values) => {
    const {
      name,
      description,
      startPeriod,
      endPeriod,
      frequency,
      dayInterval,
    } = values;

    const requirements = {
      name,
      description: description ?? undefined,
      startDate: startPeriod,
      endDate: endPeriod ?? undefined,
      periodicity: frequency,
      periodLength: dayInterval ?? undefined,
    };

    const dataSources = dataConnections.map(({ connectorName, columns, ...dataConnection }) => ({
      ...dataConnection,
      fields: columns.map(({ dataSelectorBlockListItemType, ...column }) => column),
    }));

    const usersIds = users.reduce((acc, u) => [...acc, u.id], []);

    const onSuccessCB = (planId) => {
      if (afterCreationCB !== null) afterCreationCB(planId);
      redirectTo({ path: routePaths.v2.planV3EditAchievementById, params: { planId } });
    };

    const finalData = {
      requirements,
      dataSources,
      users: usersIds,
      onSuccessCB,
    };

    if (configuration) {
      dispatch(PlanV3Actions.updateConfiguration({ planId: configuration.planId, ...finalData }));
    } else {
      dispatch(PlanV3Actions.createPlan(finalData));
    }
  }, [users, dataConnections, configuration, afterCreationCB]);

  const handleSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <div>
          <div className={styles.container}>
            <h2 className={styles.title}>{t('createPlanPage.section1.title')}</h2>
            <h3 className={styles.subtitle}>{t('createPlanPage.section1.subtitle')}</h3>
            <PlanV3FormRequirementsGeneral isPending={createOrUpdateIsPending} frequencyType={formValues.frequency} />
          </div>
          <div className={styles.container}>
            <h2 className={styles.title}>{t('createPlanPage.section2.title')}</h2>
            <h3 className={styles.subtitle}>{t('createPlanPage.section2.subtitle')}</h3>
            <PlanV3FormRequirementsUsers
              planId={configuration?.planId ?? null}
              isPending={createOrUpdateIsPending}
              usersIds={selectedUsersIds}
              onChange={(selectedUsers) => setUsers(selectedUsers)}
              setAfterPlanCreationCB={configuration?.planId != null ? null : (creationCB) => setAfterCreationCB(() => creationCB)}
            />
          </div>
          <div className={styles.container}>
            <h2 className={styles.title}>{t('createPlanPage.section3.title')}</h2>
            <h3 className={styles.subtitle}>{t('createPlanPage.section3.subtitle')}</h3>
            <PlanV3FormRequirementsDataSources isPending={createOrUpdateIsPending} />
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            type="primaryBlue"
            icon={<ArrowRightCircle height={16} width={16} />}
            onClick={handleSubmit}
            disabled={createOrUpdateIsPending}
          >
            {!configuration && t('createPlanPage.form.submit.label')}
            {configuration && t('updatePlanPage.form.submit.label')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

PlanV3FormRequirements.propTypes = {
  className: PropTypes.string,
  configuration: PlanV3ConfigurationModel.propTypes,
};

PlanV3FormRequirements.defaultProps = {
  className: '',
  configuration: undefined,
};

export default PlanV3FormRequirements;
