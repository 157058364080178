import {
  call,
  put,
  all,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { actions as UsersVariablesActions } from '@palette/state/UsersVariables/slice';
import { manageError as manageUsersVariablesError } from '@palette/state/UsersVariables/errors';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { USERS_VARIABLES_EVENTS } from '@palette/constants/analytics';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as UsersVariablesService from '@palette/services/UsersVariablesService';

import * as UserVariablesListModel from '@palette/models/UserVariablesList';

export function* getUsersVariables() {
  const callResult = yield call(UsersVariablesService.getVariables);

  if (callResult.ok) {
    const usersVariables = UserVariablesListModel.transform(callResult.data);
    yield put(UsersVariablesActions.setUsersVariables({ usersVariables }));
  } else {
    const error = manageUsersVariablesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersVariablesActions.getUsersVariablesCompleted());
}

export function* createVariable({ payload }) {
  const {
    name,
    type,
    defaultValue,
    onSuccessCB = null,
  } = payload;

  const callData = {
    name,
    type,
    defaultValue,
  };

  const callResult = yield call(UsersVariablesService.createVariable, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(UsersVariablesActions.getUsersVariables());
    yield take(UsersVariablesActions.getUsersVariablesCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_VARIABLE_CREATE_SUCCESS));

    yield call(analyticsSendEvent, { payload: { event: USERS_VARIABLES_EVENTS.VARIABLE_CREATED } });
  } else {
    const error = manageUsersVariablesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersVariablesActions.createVariableCompleted());
}

export function* editVariable({ payload }) {
  const {
    usersIds,
    userVariableId,
    value,
    date,
    onSuccessCB = null,
  } = payload;

  const callData = {
    usersIds,
    userVariableId,
    value,
    date,
  };

  const callResult = yield call(UsersVariablesService.editVariable, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(UsersVariablesActions.getUsersVariables());
    yield take(UsersVariablesActions.getUsersVariablesCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_VARIABLE_EDIT_SUCCESS));

    yield call(analyticsSendEvent, { payload: { event: USERS_VARIABLES_EVENTS.VARIABLE_EDITED, params: { userVariableId } } });
  } else {
    const error = manageUsersVariablesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersVariablesActions.editVariableCompleted());
}

export function* deleteVariableValueOverride({ payload }) {
  const {
    userId,
    userVariableId,
    overrideId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    userId,
    userVariableId,
    overrideId,
  };

  const callResult = yield call(UsersVariablesService.deleteVariableValueOverride, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(UsersVariablesActions.getUsersVariables());
    yield take(UsersVariablesActions.getUsersVariablesCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_VARIABLE_DELETION_SUCCESS));

    yield call(analyticsSendEvent, { payload: { event: USERS_VARIABLES_EVENTS.VARIABLE_OVERRIDE_DELETED, params: { userVariableId, overrideId } } });
  } else {
    const error = manageUsersVariablesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersVariablesActions.deleteVariableValueOverrideCompleted());
}

export function* loop() {
  yield all([
    takeLatest(UsersVariablesActions.getUsersVariables.type, getUsersVariables),
    takeLatest(UsersVariablesActions.createVariable.type, createVariable),
    takeLatest(UsersVariablesActions.editVariable.type, editVariable),
    takeLatest(UsersVariablesActions.deleteVariableValueOverride.type, deleteVariableValueOverride),
  ]);
}

export function* clean() {
  yield put(UsersVariablesActions.resetToInitialState());
}
