import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { getCommissionV3Amount } from '@palette/helpers/CommissionV3Helper';
import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { addCurrency, getCurrencySymbol } from '@palette/helpers/CurrencyHelper';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import { actions as CommissionV3Actions, selectors as CommissionV3Selectors } from '@palette/state/CommissionV3';

import styles from './CommissionV3EditAmountModal.less';

const CommissionV3EditAmountModal = ({ visible, onClose, commission }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const overwriteAmountIsPending = useSelector(CommissionV3Selectors.overwriteAmountIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    amount: getCommissionV3Amount(commission),
  }), [commission]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const cleanAndClose = () => {
    onClose();
  };

  const handleFinish = (values) => {
    let newAmount = parseFloat(values.amount);

    if (newAmount === commission.amount) {
      newAmount = undefined;
    }

    if (
      (!newAmount && newAmount !== 0 && !commission.amountOverwritten)
      || (newAmount === parseFloat(initialValues.amount))
    ) {
      cleanAndClose();
      return;
    }

    dispatch(CommissionV3Actions.overwriteAmount({ commissionId: commission.id, amount: newAmount, onSuccessCB: cleanAndClose }));
  };

  const handleOverwriteValue = () => form.submit();

  const handleResetToOriginalAmount = () => {
    dispatch(CommissionV3Actions.overwriteAmount({ commissionId: commission.id, amount: commission.amount, onSuccessCB: cleanAndClose }));
  };

  let resetAmountNode = null;

  if (commission.amountOverwritten !== null) {
    const initialAmount = comaSeparatorFormatter(commission.amount);

    resetAmountNode = (
      <Popconfirm
        title={t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.title')}
        onConfirm={handleResetToOriginalAmount}
        okText={t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.confirm')}
        cancelText={t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.cancel')}
        size="small"
        disabled={overwriteAmountIsPending}
      >
        <Button
          className={styles.resetToOriginalAmountButton}
          type="link"
          disabled={overwriteAmountIsPending}
        >
          {t('editCommissionAmountModal.resetToOriginalAmount.popconfirm.cta', { amount: addCurrency(initialAmount, commission.currency) })}
        </Button>
      </Popconfirm>
    );
  }

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('editCommissionAmountModal.commission')}
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleOverwriteValue}
      okText={t('editCommissionAmountModal.overwriteAmount')}
      okIcon={<CheckFilled />}
      loading={overwriteAmountIsPending}
      extraFooterNode={resetAmountNode}
    >
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <FormItem
          name="amount"
          label={t('editCommissionAmountModal.form.amount.label')}
        >
          <Input size="big" type="comaSeparatorFormatted" addonBefore={getCurrencySymbol(commission.currency)} disabled={overwriteAmountIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

CommissionV3EditAmountModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
};

CommissionV3EditAmountModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CommissionV3EditAmountModal;
