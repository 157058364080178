import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
} from '@palette/helpers/ModelHelper';

import * as UserVariableModel from '@palette/models/UserVariable';
import * as UserVariableOverrideModel from '@palette/models/UserVariableOverride';

export const modelName = 'UserVariablesList';

export const propTypes = PropTypes.shape({
  variables: PropTypes.arrayOf(UserVariableModel.propTypes),
  overrides: PropTypes.arrayOf(UserVariableOverrideModel.propTypes),
});

export const transform = (userVariablesList) => {
  if (!userVariablesList) {
    return null;
  }

  const variables = UserVariableModel.transformList(manageArrayAttribute(userVariablesList, 'variables'));

  return (
    {
      variables,
      overrides: UserVariableOverrideModel.transformList(manageArrayAttribute(userVariablesList, 'overrides'), variables),
    }
  );
};

export const transformList = (userVariablesLists) => userVariablesLists.map((userVariablesList) => transform(userVariablesList));
