import PropTypes from 'prop-types';

import { manageStringAttribute } from '@palette/helpers/ModelHelper';

import { HALF_MONTH_PIVOTS, STATEMENT_STRATEGY_TYPES, WEEK_ROLL_TYPES } from '@palette/constants/statements';

export const modelName = 'StatementStrategy';

export const propTypes = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(STATEMENT_STRATEGY_TYPES)),
  weekRollType: PropTypes.oneOf(Object.values(WEEK_ROLL_TYPES)),
  halfMonthPivot: PropTypes.oneOf(Object.values(HALF_MONTH_PIVOTS)),
});

export const manageStrategyType = (strategy) => (STATEMENT_STRATEGY_TYPES[manageStringAttribute(strategy, 'type')] || STATEMENT_STRATEGY_TYPES.MONTH);

export const manageWeekRollType = (strategy) => (WEEK_ROLL_TYPES[manageStringAttribute(strategy, 'weekLocale')] || WEEK_ROLL_TYPES.US);

export const manageHalfMonthPivot = (strategy) => (HALF_MONTH_PIVOTS[manageStringAttribute(strategy, 'halfMonthPivot')] || HALF_MONTH_PIVOTS.FIFTEENTH);

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      type: manageStrategyType(strategy),
      weekRollType: manageWeekRollType(strategy),
      halfMonthPivot: manageHalfMonthPivot(strategy),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
