import PropTypes from 'prop-types';

import { manageFreeShapeObjectAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'ResourceUpdateStrategy';

export const propTypes = PropTypes.exact({
  resourceId: PropTypes.string,
  connectorId: PropTypes.string,
  resourceName: PropTypes.string,
  resourceType: PropTypes.string,
  fieldPath: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      resourceId: manageStringAttribute(strategy, 'resourceId'),
      connectorId: manageStringAttribute(strategy, 'connectorId'),
      resourceName: manageStringAttribute(strategy, 'resourceName'),
      resourceType: manageStringAttribute(strategy, 'resourceType'),
      fieldPath: manageStringAttribute(manageFreeShapeObjectAttribute(strategy, 'lastOverwrite'), 'path'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
