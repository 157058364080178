import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import NotebookDataConnectionConnectorsListItem from '@palette/components/notebooks/NotebookDataConnectionConnectorsListItem/NotebookDataConnectionConnectorsListItem';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './NotebookDataConnectionConnectorsList.less';

const classNames = bindClassNames.bind(styles);

const NotebookDataConnectionConnectorsList = ({ className, notebook, connectorName, dataConnectionConnectorsList, defaultOpen }) => {
  const listNode = useMemo(() => (
    dataConnectionConnectorsList.map((dataConnectionConnector) => (
      <NotebookDataConnectionConnectorsListItem
        key={dataConnectionConnector.dataframeName}
        className={styles.listItem}
        notebook={notebook}
        dataConnectionConnector={dataConnectionConnector}
      />
    ))
  ), [notebook, dataConnectionConnectorsList]);

  const collapseExtraProps = useMemo(() => {
    if (!defaultOpen) return {};

    return {
      keyValue: connectorName,
      defaultActiveKey: connectorName,
    };
  }, [connectorName, defaultOpen]);

  return (
    <Collapse
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      panels={[
        {
          title: (
            <div className={styles.connectorNameWrapper}>
              {connectorName}
            </div>
          ),
          content: (
            <div className={styles.list}>
              {listNode}
            </div>
          ),
        },
      ]}
      ghost
      {...collapseExtraProps}
    />
  );
};

NotebookDataConnectionConnectorsList.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  connectorName: PropTypes.string.isRequired,
  dataConnectionConnectorsList: PropTypes.arrayOf(NotebookDataConnectionConnectorModel.propTypes).isRequired,
  defaultOpen: PropTypes.bool,
};

NotebookDataConnectionConnectorsList.defaultProps = {
  className: '',
  defaultOpen: false,
};

export default NotebookDataConnectionConnectorsList;
