import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

import styles from './EditableDashboardPresetName.less';

const classNames = bindClassNames.bind(styles);

const EditableDashboardPresetName = ({ className, dashboardPreset }) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [dashboardPresetName, setDashboardPresetName] = useState(dashboardPreset.name);

  useEffect(() => {
    setDashboardPresetName(dashboardPreset.name);
  }, [dashboardPreset.name]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const updateDashboardPresetIsPending = useSelector(DashboardPresetsSelectors.updateDashboardPresetIsPending);

  useEffect(() => {
    if (!updateDashboardPresetIsPending) {
      setEditing(false);
    }
  }, [updateDashboardPresetIsPending]);

  const saveUpdate = () => {
    if (dashboardPreset.name !== dashboardPresetName) {
      dispatch(DashboardPresetsActions.updateDashboardPreset({ presetId: dashboardPreset.id, name: dashboardPresetName }));
    } else {
      setEditing(false);
    }
  };

  const handleInputChange = (newValue) => {
    setDashboardPresetName(newValue);
  };

  const switchToEditMode = () => {
    setEditing(true);
  };

  if (editing) {
    return (
      <Input
        ref={inputRef}
        className={styles.input}
        type="text"
        value={dashboardPresetName}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={saveUpdate}
      />
    );
  }

  return (
    <h1
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      onClick={switchToEditMode}
    >
      {dashboardPreset.name}
    </h1>
  );
};

EditableDashboardPresetName.propTypes = {
  className: PropTypes.string,
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
};

EditableDashboardPresetName.defaultProps = {
  className: '',
};

export default EditableDashboardPresetName;
