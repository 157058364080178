import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import EditOnboardUserModal from '@palette/components/userOnboarding/EditOnboardUserModal/EditOnboardUserModal';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import * as OnboardUserModel from '@palette/models/OnboardUser';

import styles from './EditOnboardUserButton.less';

const classNames = bindClassNames.bind(styles);

const EditOnboardUserButton = ({ className, user, onboardUserIndex }) => {
  const { t } = useTranslation();
  const [editOnboardUserModalIsVisible, showEditOnboardUserModal] = useState(false);

  const editOnboardUserButtonNode = useMemo(() => (
    <Tooltip title={t('editOnboardUserButton.editUserData')}>
      <Button
        className={styles.button}
        type="link"
        icon={<PenFilled />}
        onClick={() => showEditOnboardUserModal(true)}
      />
    </Tooltip>
  ), [showEditOnboardUserModal]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {editOnboardUserButtonNode}
      {editOnboardUserModalIsVisible && (
        <EditOnboardUserModal
          visible
          onClose={() => showEditOnboardUserModal(false)}
          user={user}
          onboardUserIndex={onboardUserIndex}
        />
      )}
    </div>
  );
};

EditOnboardUserButton.propTypes = {
  className: PropTypes.string,
  user: OnboardUserModel.propTypes.isRequired,
  onboardUserIndex: PropTypes.number.isRequired,
};

EditOnboardUserButton.defaultProps = {
  className: '',
};

export default EditOnboardUserButton;
