import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import MasterPlanSettingsQuotaAttainment from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaAttainment/MasterPlanSettingsQuotaAttainment';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';
import ResourceObjectSample from '@palette/components/resources/ResourceObjectSample/ResourceObjectSample';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowQuotaAttainmentStep.less';

const classNames = bindClassNames.bind(styles);

const defaultValueFormula = '1';

const CreatePlanFlowQuotaAttainmentStep = ({ className, plan }) => {
  const dispatch = useDispatch();
  const [saveQuotaAttainmentCB, setSaveQuotaAttainmentCB] = useState(null);
  const [valueFormula, setValueFormula] = useState('');

  const handleNextStep = useCallback((goToNextStep) => {
    saveQuotaAttainmentCB(goToNextStep);
  }, [saveQuotaAttainmentCB]);

  const handleSetSaveQuotaAttainmentCB = useCallback((saveAttainmentCB) => {
    setSaveQuotaAttainmentCB(() => saveAttainmentCB);
  }, []);

  const handleSkipStep = useCallback((goToNextStep) => {
    const trackingObjectValueFormula = plan.trackingObject?.valueFormula || '';

    setValueFormula(defaultValueFormula);
    setSaveQuotaAttainmentCB(() => goToNextStep);

    if (trackingObjectValueFormula === defaultValueFormula) return;

    dispatch(
      MasterPlansActions.updatePlanTrackingObjectDefinition({
        planId: plan.id,
        valueFormula: defaultValueFormula,
        disableSuccessNotification: true,
      }),
    );
  }, [plan]);

  useEffect(() => {
    if (valueFormula === defaultValueFormula) {
      saveQuotaAttainmentCB();
    }
  }, [valueFormula]);

  const actionsNode = useMemo(() => (
    <CreatePlanFlowStepActions
      plan={plan}
      currentStepValue={CREATE_PLAN_FLOW_STEPS.QUOTA_ATTAINMENT.value}
      onNextStep={handleNextStep}
      enableSkipButton
      onSkipStep={handleSkipStep}
    />
  ), [plan, handleNextStep]);

  const quotaAttainmentFormNode = useMemo(() => (
    <MasterPlanSettingsQuotaAttainment
      className={styles.quotaAttainment}
      plan={plan}
      inPlanCreationFlow
      inPlanCreationFlowSetSaveCB={handleSetSaveQuotaAttainmentCB}
      initialValueFormula={valueFormula}
    />
  ), [plan, handleSetSaveQuotaAttainmentCB, valueFormula]);

  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.formWrapper}>
        {quotaAttainmentFormNode}
        {actionsNode}
      </div>
      <ResourceObjectSample className={styles.resourceObjectSample} helperData={formulaHelperData} />
    </div>
  );
};

CreatePlanFlowQuotaAttainmentStep.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowQuotaAttainmentStep.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowQuotaAttainmentStep;
