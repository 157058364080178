import _invert from 'lodash/invert';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { generateNewCellName } from '@palette/helpers/NotebookHelper';

import { RIGHTS } from '@palette/constants/profile';
import { NOTEBOOKS_TABS_IDS } from '@palette/constants/tabs';
import { NOTEBOOK_CELL_TYPES, NOTEBOOK_CELL_TYPES_IDS, NOTEBOOK_STATUSES, NOTEBOOK_TYPES } from '@palette/constants/notebooks';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

import * as NotebookCellModel from '@palette/models/NotebookCell';

export function* listNotebooks(payload) {
  const {
    filter,
    page,
    limit,
  } = payload;

  const data = {
    page,
    limit,
    onlyMine: false,
    status: NOTEBOOK_STATUSES.ACTIVE,
  };
  if (filter === NOTEBOOKS_TABS_IDS.YOURS) {
    data.onlyMine = true;
    data.type = NOTEBOOK_TYPES.CLASSIC;
  }
  if (filter === NOTEBOOKS_TABS_IDS.TEMPLATES) {
    data.type = NOTEBOOK_TYPES.TEMPLATE;
  }
  if (filter === NOTEBOOKS_TABS_IDS.ARCHIVED) {
    data.status = NOTEBOOK_STATUSES.ARCHIVED;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.list, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.VIEW],
  );
}

export function* copyNotebook(payload) {
  const { notebookId, name, description = undefined } = payload;

  const data = { notebookId, name, description };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.duplicate, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* archiveNotebook(payload) {
  const { notebookId } = payload;

  const data = { notebookId, status: 'archived' };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.updateStatus, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* unarchiveNotebook(payload) {
  const { notebookId } = payload;

  const data = { notebookId, status: 'active' };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.updateStatus, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* deleteNotebook(payload) {
  const { notebookId } = payload;

  const data = { notebookId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.delete, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* createNotebook(payload) {
  const { name, description = '' } = payload;

  const data = { name, description };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.create, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* getNotebookById(payload) {
  const { notebookId } = payload;

  const data = { notebookId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.getById, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.VIEW],
  );
}

export function* editNotebook(payload) {
  const { notebookId, name, description } = payload;

  const data = {
    notebookId,
    name,
    description,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.edit, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* addCell(payload) {
  const {
    notebook,
    cellTypeId,
    position,
  } = payload;

  const cellTypeKey = _invert(NOTEBOOK_CELL_TYPES_IDS)[cellTypeId];
  const cellType = NOTEBOOK_CELL_TYPES[cellTypeKey];

  const cellName = generateNewCellName(notebook, cellType);
  const cellInitialContent = cellType.initialContent ?? '';

  const newCellForService = NotebookCellModel.transformForService({
    type: cellType.id,
    content: cellInitialContent,
    name: cellName,
    status: cellType.exportableStatus,
    position,
  });

  const data = {
    notebookId: notebook.id,
    ...newCellForService,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.upsertCell, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* updateCell(payload) {
  const {
    notebookId,
    updatedCell,
  } = payload;

  const updatedCellForService = NotebookCellModel.transformForService(updatedCell);

  const data = {
    notebookId,
    ...updatedCellForService,
    codeCellId: updatedCellForService._id,
  };
  delete data._id;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.upsertCell, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* deleteCell(payload) {
  const {
    notebookId,
    notebookCellId,
  } = payload;

  const data = {
    notebookId,
    codeCellId: notebookCellId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.deleteCell, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* addDataConnectionConnector(payload) {
  const {
    notebookId,
    connectorId,
    connectorName,
    resourceType,
    columns,
  } = payload;

  const data = {
    notebookId,
    connectorId,
    connectorName,
    resourceType,
    columns: columns.map(({ name, type }) => ({
      name,
      type,
    })),
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.upsertDataConnectionConnector, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* editDataConnectionConnector(payload) {
  const {
    notebookId,
    dataConnectionConnector,
    columns,
  } = payload;

  const data = {
    notebookId,
    connectorId: dataConnectionConnector.connectorId,
    resourceType: dataConnectionConnector.originalType,
    columns: columns.map(({ name, type }) => ({
      name,
      type,
    })),
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.upsertDataConnectionConnector, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* previewDataConnectionConnectorResults(payload) {
  const {
    connectorId,
    resourceType,
    columns,
  } = payload;

  const data = {
    connectorId,
    resourceType,
    columns: columns.map(({ name, type }) => ({
      name,
      type,
    })),
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.previewDataConnectionConnector, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}

export function* runCell(payload) {
  const {
    notebookId,
    cellId,
  } = payload;

  const data = {
    notebookId,
    codeCellId: cellId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.notebooks.runCell, data, getConfig()),
    [RIGHTS.ADMIN.NOTEBOOKS.MANAGE],
  );
}
