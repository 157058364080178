import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Table from '@palette/components/designSystem/Table/Table';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { getMoment } from '@palette/helpers/MomentHelper';
import { getStrategyDetailsStr } from '@palette/helpers/StatementHelper';

import * as StatementStrategyHistoryModel from '@palette/models/StatementStrategyHistory';

import styles from './HistoryStatementStrategyModal.less';

const HistoryStatementStrategyModal = ({ visible, onClose, statementStrategies }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const columns = useMemo(() => ([
    {
      Header: t('statementStrategy.history.modal.table.header.date'),
      accessor: ({ date }) => ({ date }),
      width: '40%',
      minWidth: '40%',
      Cell: ({ value }) => moment(value.date).format('ll'),
    },
    {
      Header: t('statementStrategy.history.modal.table.header.strategy'),
      accessor: ({ strategy }) => ({ strategy }),
      width: '58%',
      minWidth: '58%',
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div className={styles.detailsCell}>
          <span>{t(`statementStrategy.propertyTypes.${value.strategy.type}`)}</span>
          {getStrategyDetailsStr(value.strategy)}
        </div>
      ),
      /* eslint-enable react/prop-types */
    },
  ]), []);

  const contentNode = useMemo(() => {
    if (!statementStrategies.length) return <DefaultEmptyState />;

    return (
      <div className={styles.table}>
        <Table data={statementStrategies} columns={columns} stickyHeader stretch />
      </div>
    );
  }, [statementStrategies, columns]);

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('statementStrategy.history.modal.title')}
        </div>
      )}
      visible={visible}
      onCancel={onClose}
      displayFooter={false}
    >
      {contentNode}
    </Modal>
  );
};

HistoryStatementStrategyModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  statementStrategies: PropTypes.arrayOf(StatementStrategyHistoryModel.propTypes),
};

HistoryStatementStrategyModal.defaultProps = {
  visible: false,
  onClose: () => {},
  statementStrategies: [],
};

export default HistoryStatementStrategyModal;
