import PropTypes from 'prop-types';

import { manageAmountAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'PlanTotalValueData';

export const propTypes = PropTypes.shape({
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const transform = (planTotalValue) => {
  if (!planTotalValue) {
    return null;
  }

  return (
    {
      totalValue: manageAmountAttribute(planTotalValue, 'totalDealValue'),
    }
  );
};
