import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Popover from '@palette/components/designSystem/Popover/Popover';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import PeriodYearPicker from '@palette/components/frequencies/PeriodYearPicker/PeriodYearPicker';

import { getMoment } from '@palette/helpers/MomentHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as YearPeriodModel from '@palette/models/YearPeriod';

import styles from './EditablePeriodYear.less';

const classNames = bindClassNames.bind(styles);

const EditablePeriodYear = ({
  className,
  contentClassName,
  popoverVisibleClassName,
  periodType,
  frequency,
  fiscalYearShift,
  initialYear,
  initialPeriod,
  onUpdate,
  getContentDisplay,
  disabled,
  beginDate,
  disableBeforePeriod,
  disableAfterPeriod,
}) => {
  const moment = getMoment();

  const [year, setYear] = useState(initialYear);
  const [period, setPeriod] = useState(initialPeriod);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [datepickerOpened, setDatepickerOpened] = useState(false);
  const [datepickerOpenListening, setDatepickerOpenListening] = useState(false);

  const saveUpdate = useCallback(() => {
    if (initialPeriod !== period || initialYear !== year) {
      onUpdate({ period, year });
    }
  }, [
    initialPeriod,
    period,
    initialYear,
    year,
  ]);

  useEffect(() => {
    setYear(initialYear);
  }, [initialYear]);

  useEffect(() => {
    setPeriod(initialPeriod);
  }, [initialPeriod]);

  useEffect(() => {
    if (period === 1 && frequency === 12) {
      saveUpdate();
    }
  }, [period, year, frequency]);

  useEffect(() => {
    if (!popoverVisible) {
      saveUpdate();
    }
  }, [popoverVisible]);

  const handlePeriodChange = (newPeriod) => {
    setPeriod(newPeriod);
    setPopoverVisible(false);
  };

  const handleYearChange = (newYear) => {
    setYear(newYear);
  };

  useEffect(() => {
    if (periodType === PERIOD_TYPES.CUSTOM) return;

    if (datepickerOpenListening && !datepickerOpened && period === null) {
      handlePeriodChange(1);
      handleYearChange(year);
      setDatepickerOpenListening(false);
    }
  }, [datepickerOpened, period]);

  const handleDatePickerChange = (date, dateString) => {
    handleYearChange(parseInt(dateString, 10));
  };

  const handlePickerOpenChange = (datePickerIsOpen) => {
    setDatepickerOpened(datePickerIsOpen);
    setDatepickerOpenListening(true);
  };

  const handlePopoverVisibleChange = useCallback((isVisible) => {
    if (disabled) return;

    setPopoverVisible(isVisible);

    if (!isVisible) {
      saveUpdate();
    }
  }, [periodType, frequency, period, year, saveUpdate]);

  const bodyNode = useMemo(() => {
    if (periodType === PERIOD_TYPES.CUSTOM) return null;

    const contentDisplay = getContentDisplay(periodType, frequency, year, period, beginDate, fiscalYearShift);

    if (periodType === PERIOD_TYPES.MONTH && frequency === 12) {
      const handleDisabledDate = (d) => (
        !d
        || d.isSameOrBefore('1970-01-01')
        || (
          disableBeforePeriod !== null
          && d.isBefore(moment().year(disableBeforePeriod.year).startOf('year'))
        )
        || (
          disableAfterPeriod !== null
          && d.isAfter(moment().year(disableAfterPeriod.year).endOf('year'))
        )
      );

      return (
        <div className={styles.yearPickerWrapper}>
          <DatePicker
            className={styles.yearPicker}
            picker="year"
            value={moment().year(year)}
            allowClear={false}
            disabledDate={handleDisabledDate}
            disabled={disabled}
            onChange={handleDatePickerChange}
            onOpenChange={handlePickerOpenChange}
          />
          <div
            className={classNames({
              [contentClassName]: contentClassName !== '',
            })}
          >
            {contentDisplay}
          </div>
        </div>
      );
    }

    return (
      <Popover
        content={(
          <PeriodYearPicker
            periodType={periodType}
            frequency={frequency}
            fiscalYearShift={fiscalYearShift}
            period={period}
            year={year}
            beginDate={beginDate}
            onPeriodChange={handlePeriodChange}
            onYearChange={handleYearChange}
            disableBeforePeriod={disableBeforePeriod}
            disableAfterPeriod={disableAfterPeriod}
            forceReset={popoverVisible}
          />
        )}
        onVisibleChange={handlePopoverVisibleChange}
        visible={popoverVisible}
        disabled={disabled}
      >
        <div
          className={classNames({
            [contentClassName]: contentClassName !== '',
          })}
        >
          {contentDisplay}
        </div>
      </Popover>
    );
  },
  [
    popoverVisible,
    periodType,
    frequency,
    fiscalYearShift,
    period,
    year,
    getContentDisplay,
    disabled,
    beginDate,
    disableBeforePeriod,
    disableAfterPeriod,
  ]);

  if (periodType === PERIOD_TYPES.CUSTOM) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
        [popoverVisibleClassName]: popoverVisibleClassName !== '' && popoverVisible,
      })}
    >
      {bodyNode}
    </div>
  );
};

EditablePeriodYear.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  popoverVisibleClassName: PropTypes.string,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)).isRequired,
  frequency: PropTypes.number.isRequired,
  fiscalYearShift: PropTypes.number,
  initialYear: PropTypes.number.isRequired,
  initialPeriod: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  getContentDisplay: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  beginDate: PropTypes.string,
  disableBeforePeriod: YearPeriodModel.propTypes,
  disableAfterPeriod: YearPeriodModel.propTypes,
};

EditablePeriodYear.defaultProps = {
  className: '',
  fiscalYearShift: 0,
  contentClassName: '',
  popoverVisibleClassName: '',
  disabled: false,
  beginDate: null,
  initialPeriod: null,
  disableBeforePeriod: null,
  disableAfterPeriod: null,
};

export default EditablePeriodYear;
