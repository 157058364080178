import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodCardItem from '@palette/components/statement/statementPeriod/StatementPeriodCardItem/StatementPeriodCardItem';

import { useUserStatementsPanelContext } from '@palette/hooks/StatementHooks';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './StatementPanelPeriodsCardsListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementPanelPeriodsCardsListItem = ({ className, statementPeriod, isSelected }) => {
  const { setStatementPeriodId, resetHistoryParams } = useUserStatementsPanelContext();

  const handleClick = useCallback(() => {
    if (!isSelected) {
      resetHistoryParams();
      setStatementPeriodId(statementPeriod.id);
    }
  }, [isSelected, setStatementPeriodId, resetHistoryParams]);

  return (
    <div
      className={classNames({
        wrapper: true,
        isSelected,
        [className]: className !== '',
      })}
      onClick={handleClick}
    >
      <StatementPeriodCardItem statementPeriod={statementPeriod} isSelected={isSelected} inUserStatement />
    </div>
  );
};

StatementPanelPeriodsCardsListItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  isSelected: PropTypes.bool,
};

StatementPanelPeriodsCardsListItem.defaultProps = {
  className: '',
  isSelected: false,
};

export default StatementPanelPeriodsCardsListItem;
