import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AddUsersWithFastOnboardingModal from '@palette/components/user/AddUsersWithFastOnboardingModal/AddUsersWithFastOnboardingModal';

import { EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

const AddUsersInMasterPlanModal = ({ visible, onClose, plan, forManagers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const restrictToConnectorIdAndType = useMemo(() => {
    if (plan.usersDefinition?.type === EMPTY_DEFAULT_VALUE) return null;

    return {
      connectorId: plan.usersDefinition?.connectorId,
      type: plan.usersDefinition?.type,
    };
  }, [plan]);

  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: plan.id }));

  const addUsersToMasterPlanIsPending = useSelector(MasterPlansSelectors.addUsersToPlanIsPending);

  const preSelectedUsers = useMemo(() => {
    if (forManagers) {
      return planUsersAndManagers.managers.map((planUser) => planUser.user);
    }

    return planUsersAndManagers.users.map((planUser) => planUser.user);
  }, [planUsersAndManagers, forManagers]);

  const handleAddUsersMasterPlan = (usersToAdd, cleanAndClose) => {
    dispatch(MasterPlansActions.addUsersToPlan({ planId: plan.id, usersToAdd, forManagers, onSuccessCB: cleanAndClose }));
  };

  return (
    <AddUsersWithFastOnboardingModal
      title={forManagers ? t('addUsersInMasterPlanModal.addManagers.title') : t('addUsersInMasterPlanModal.addUsers.title')}
      visible={visible}
      onClose={onClose}
      onOk={handleAddUsersMasterPlan}
      okText={forManagers ? t('addUsersInMasterPlanModal.addManagers.button') : t('addUsersInMasterPlanModal.addUsers.button')}
      loading={addUsersToMasterPlanIsPending}
      preSelectedUsers={preSelectedUsers}
      restrictToConnectorIdAndType={restrictToConnectorIdAndType}
      onlyActiveUsers
    />
  );
};

AddUsersInMasterPlanModal.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  forManagers: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

AddUsersInMasterPlanModal.defaultProps = {
  forManagers: false,
  visible: false,
  onClose: () => {},
};

export default AddUsersInMasterPlanModal;
