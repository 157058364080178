import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './RemoveUserFromQuotaModal.less';

const RemoveUserFromQuotaModal = ({ visible, onClose, quotaUserId, quotaId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quotaUser = useSelector((state) => QuotasSelectors.getQuotaUserByIdAndQuotaId(state, { quotaId, quotaUserId }));
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));

  const removeUserFromQuotaIsPending = useSelector(QuotasSelectors.removeUserFromQuotaIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const handleRemoveUserFromQuota = () => {
    dispatch(QuotasActions.removeUserFromQuota({ quotaId: quota.id, quotaUser, onSuccessCB: cleanAndClose }));
  };

  return (
    <Modal
      className={styles.modal}
      title={t('quota.removeUserFromQuota.title', { firstName: quotaUser.user.firstName })}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleRemoveUserFromQuota}
      okText={t('quota.removeUserFromQuota.confirm')}
      loading={removeUserFromQuotaIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('quota.removeUserFromQuota.content', { userDisplayName: quotaUser.user.displayName, quotaName: quota.name }) }} />
    </Modal>
  );
};

RemoveUserFromQuotaModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  quotaUserId: PropTypes.string.isRequired,
  quotaId: PropTypes.string.isRequired,
};

RemoveUserFromQuotaModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default RemoveUserFromQuotaModal;
