import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Button from '@palette/components/designSystem/Button/Button';
import CommissionDrawerEditVariablesListModal from '@palette/components/commission/CommissionDrawerEditVariablesListModal/CommissionDrawerEditVariablesListModal';

import * as CommissionModel from '@palette/models/Commission';

import { actions as CommissionsActions } from '@palette/state/Commissions';

import styles from './CommissionDrawerEditVariablesList.less';

const classNames = bindClassNames.bind(styles);

const CommissionDrawerEditVariablesList = ({ className, commission }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [commissionDrawerEditVariablesListModalIsVisible, showCommissionDrawerEditVariablesListModal] = useState(false);

  const handleVariablesUpdate = useCallback(() => {
    dispatch(CommissionsActions.getById({ commissionId: commission.id }));
  });

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        icon={<PenFilled />}
        onClick={() => showCommissionDrawerEditVariablesListModal(true)}
      >
        {t('commissionDrawerEditVariablesList.editVariables')}
      </Button>
      {
        commissionDrawerEditVariablesListModalIsVisible && (
          <CommissionDrawerEditVariablesListModal
            visible
            onClose={() => showCommissionDrawerEditVariablesListModal(false)}
            plan={commission.scopeParams?.plan}
            onUpdate={handleVariablesUpdate}
          />
        )
      }
    </div>
  );
};

CommissionDrawerEditVariablesList.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionDrawerEditVariablesList.defaultProps = {
  className: '',
};

export default CommissionDrawerEditVariablesList;
