import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import OnboardUsersFlowStepActions from '@palette/components/userOnboarding/OnboardUsersFlowStepActions/OnboardUsersFlowStepActions';
import Table from '@palette/components/designSystem/Table/Table';
import Link from '@palette/components/designSystem/Link/Link';
import Alert from '@palette/components/designSystem/Alert/Alert';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import WarningFilled from '@palette/components/utils/Icons/WarningFilled';
import EditOnboardUserButton from '@palette/components/userOnboarding/EditOnboardUserButton/EditOnboardUserButton';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { onboardUserHasMissingRequiredFields } from '@palette/helpers/UserHelper';

import routePaths from '@palette/config/routePaths';

import { ALERT_TYPES } from '@palette/constants/alert';

import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './OnboardUsersCheckDataFlowStep.less';

const classNames = bindClassNames.bind(styles);

const OnboardUsersCheckDataFlowStep = ({ className, disableActions, onCheckMissingRequiredFields }) => {
  const { t } = useTranslation();

  const onboardCurrentUsers = useSelector(UsersSelectors.getOnboardCurrentUsers);

  useEffect(() => {
    if (onboardCurrentUsers.length === 0) {
      redirectTo({ path: routePaths.v2.onboardUsersFlow });
    }
  }, [onboardCurrentUsers]);

  const isMissingRequiredFields = useMemo(() => (
    onboardCurrentUsers.some(onboardUserHasMissingRequiredFields)
  ), [onboardCurrentUsers]);

  useEffect(() => {
    if (onCheckMissingRequiredFields !== null) {
      onCheckMissingRequiredFields(isMissingRequiredFields);
    }
  }, [isMissingRequiredFields]);

  const errorNode = useMemo(() => {
    if (!isMissingRequiredFields) return null;

    return (
      <Alert
        type={ALERT_TYPES.WARNING}
        message={t('onboardUsersCheckDataFlowStep.warning.isMissingRequiredFields')}
      />
    );
  }, [isMissingRequiredFields]);

  const columns = useMemo(() => {
    const cols = [];

    const hasMissingRequiredFieldsColumn = {
      id: 'hasMissingRequiredFields',
      accessor: (onboardUser) => (onboardUser),
      minWidth: 30,
      width: 30,
      maxWidth: 30,
      /* eslint-disable react/prop-types */
      Cell: ({ value: onboardUser }) => {
        if (!onboardUserHasMissingRequiredFields(onboardUser)) return null;

        return (
          <Tooltip title={t('onboardUsersCheckDataFlowStep.hasMissingRequiredFields')}>
            <WarningFilled className={styles.warningIcon} />
          </Tooltip>
        );
      },
      /* eslint-enable react/prop-types */
    };
    cols.push(hasMissingRequiredFieldsColumn);

    const editActionColumn = {
      id: 'editAction',
      accessor: (onboardUser) => (onboardUser),
      minWidth: 30,
      width: 30,
      maxWidth: 30,
      /* eslint-disable react/prop-types */
      Cell: ({ value: onboardUser, row }) => (
        <EditOnboardUserButton user={onboardUser} onboardUserIndex={row.index} />
      ),
      /* eslint-enable react/prop-types */
    };
    cols.push(editActionColumn);

    const firstNameColumn = {
      id: 'firstName',
      Header: t('onboardUsersCheckDataFlowStep.table.headers.firstName'),
      accessor: 'firstName',
      minWidth: 100,
    };
    cols.push(firstNameColumn);

    const lastNameColumn = {
      id: 'lastName',
      Header: t('onboardUsersCheckDataFlowStep.table.headers.lastName'),
      accessor: 'lastName',
      minWidth: 100,
    };
    cols.push(lastNameColumn);

    const emailColumn = {
      id: 'email',
      Header: t('onboardUsersCheckDataFlowStep.table.headers.email'),
      accessor: 'email',
      minWidth: 200,
    };
    cols.push(emailColumn);

    const currencyColumn = {
      id: 'currency',
      Header: t('onboardUsersCheckDataFlowStep.table.headers.currency'),
      accessor: 'currency',
      minWidth: 50,
    };
    cols.push(currencyColumn);

    const linkedResourceColumn = {
      id: 'linkedResource',
      accessor: (onboardUser) => onboardUser.cObject,
      minWidth: 200,
      /* eslint-disable react/prop-types */
      Cell: ({ value: cObject }) => {
        if (cObject !== null) {
          return (
            <Link
              path={routePaths.v2.resourceDetails}
              params={{
                resourceId: cObject.objectId,
                connectorId: cObject.connector,
                type: cObject.type,
              }}
              displayIcon
              target="_blank"
            >
              {t('onboardUsersCheckDataFlowStep.viewLinkedResource')}
            </Link>
          );
        }

        return null;
      },
      /* eslint-enable react/prop-types */
    };
    cols.push(linkedResourceColumn);

    return cols;
  }, []);

  const usersTableNode = useMemo(() => (
    <div className={styles.usersTableWrapper}>
      <Table
        type="borderless"
        stickyHeader
        fitInContainer
        columns={columns}
        data={onboardCurrentUsers}
      />
    </div>
  ), [columns, onboardCurrentUsers]);

  const actionsNode = useMemo(() => {
    if (disableActions) return null;

    return (
      <OnboardUsersFlowStepActions disabledNext={isMissingRequiredFields} />
    );
  }, [disableActions, isMissingRequiredFields]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {errorNode}
      {usersTableNode}
      {actionsNode}
    </div>
  );
};

OnboardUsersCheckDataFlowStep.propTypes = {
  className: PropTypes.string,
  disableActions: PropTypes.bool,
  onCheckMissingRequiredFields: PropTypes.func,
};

OnboardUsersCheckDataFlowStep.defaultProps = {
  className: '',
  disableActions: false,
  onCheckMissingRequiredFields: null,
};

export default OnboardUsersCheckDataFlowStep;
