import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Modal from '@palette/components/designSystem/Modal/Modal';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import * as FolderWithResourcesModel from '@palette/models/FolderWithResources';

import { actions as FoldersActions, selectors as FoldersSelectors } from '@palette/state/Folders';

import styles from './CreateEditFolderModal.less';

const defaultValues = {
  name: undefined,
};

const CreateEditFolderModal = ({ visible, onClose, folder }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState(defaultValues);

  const createFolderIsPending = useSelector(FoldersSelectors.createFolderIsPending);

  let actionLabel = 'add';

  if (folder.id !== '' && folder.name !== '') {
    actionLabel = 'edit';
  }

  const [form] = AntDForm.useForm();

  const checkInitialValues = () => {
    let { name } = folder;

    if (actionLabel === 'add') {
      name = t('folders.modals.defaultName');
    }

    return {
      ...initialValues,
      name,
    };
  };

  useEffect(() => {
    if (visible) {
      setInitialValues(checkInitialValues());
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [initialValues]);

  const cleanAndClose = () => {
    form.resetFields();
    onClose();
  };

  const handleFinish = (values) => {
    if (actionLabel === 'add') {
      dispatch(FoldersActions.createFolder({
        name: values.name,
      }));
    } else {
      dispatch(FoldersActions.renameFolder({
        folderId: folder.id,
        folderType: folder.type,
        folderParent: folder.parent,
        name: values.name,
      }));
    }
    cleanAndClose();
  };

  const handleAddEdit = (event) => {
    event.stopPropagation();
    form.submit();
  };

  const handleNameValueChange = useCallback((e, nameField) => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      [nameField]: e.target.value,
    });

    form.validateFields([nameField]);
  });

  const titleNode = useMemo(() => t(`folders.modals.${actionLabel}.title`, { name: folder.name }), [actionLabel, folder]);

  return (
    <Modal
      className={styles.modal}
      title={titleNode}
      visible={visible}
      onCancel={() => cleanAndClose()}
      onOk={(event) => handleAddEdit(event)}
      okIcon={<CheckFilled />}
      okText={t(`folders.modals.${actionLabel}.confirm`)}
      loading={createFolderIsPending}
      width="50%"
    >
      <Form className={styles.content} onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          className={styles.formItem}
          name="name"
          label={t('folders.modals.folderName')}
          required
          rules={[
            {
              required: true,
              message: t('folders.modals.rules.folderName'),
            },
          ]}
          onChange={(event) => handleNameValueChange(event, 'name')}
        >
          <Input
            placeholder={t('folders.modals.folderName')}
            autoFocus
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

CreateEditFolderModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  folder: FolderWithResourcesModel.propTypes,
};

CreateEditFolderModal.defaultProps = {
  visible: false,
  onClose: () => {},
  folder: {
    id: '',
    name: '',
  },
};

export default CreateEditFolderModal;
