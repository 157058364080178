import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import PlanV3PeriodsAdvancedTables from '@palette/components/planV3/PlanV3PeriodsAdvancedTables/PlanV3PeriodsAdvancedTables';
import PlanV3PeriodFreezeButton from '@palette/components/planV3/PlanV3PeriodFreezeButton/PlanV3PeriodFreezeButton';

import { getMoment } from '@palette/helpers/MomentHelper';
import { computeColumnWidthFromContentLength } from '@palette/helpers/components/TableHelper';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import styles from './PlanV3PeriodsResults.less';

const classNames = bindClassNames.bind(styles);

const PlanV3PeriodsResults = ({ className, details }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const { periods, planId } = details;

  const titleNode = useMemo(() => (
    <div className={styles.header}>
      <div className={styles.title}>{t('editPlanPage.results.periods.title')}</div>
      <div className={styles.subtitle}>{t('editPlanPage.results.periods.subtitle')}</div>
    </div>
  ), []);

  const tableColumns = useMemo(() => {
    const columns = [];

    const periodFreezeColumn = {
      id: 'periodFreeze',
      minWidth: 120,
      accessor: (period) => period,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div
          className={classNames({
            cell: true,
            frozen: !!value.isFrozen,
          })}
        >
          <PlanV3PeriodFreezeButton planId={planId} periodId={value.periodId} isFrozen={value.isFrozen} />
        </div>
      ),
      /* eslint-enable react/prop-types */
      disableBodyCellComponent: true,
    };

    columns.push(periodFreezeColumn);

    const periodIdColumn = {
      id: 'periodId',
      Header: t('editPlanPage.results.periods.table.periodId'),
      minWidth: 150,
      accessor: (period) => period,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div
          className={classNames({
            cell: true,
            frozen: !!value.isFrozen,
          })}
        >
          {value.periodId}
        </div>
      ),
      /* eslint-enable react/prop-types */
      disableBodyCellComponent: true,
    };

    columns.push(periodIdColumn);

    const startColumn = {
      id: 'start',
      Header: t('editPlanPage.results.periods.table.start'),
      minWidth: 150,
      accessor: (period) => period,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div
          className={classNames({
            cell: true,
            frozen: !!value.isFrozen,
          })}
        >
          {moment(value.start).format('L')}
        </div>
      ),
      /* eslint-enable react/prop-types */
      disableBodyCellComponent: true,
    };

    columns.push(startColumn);

    const endColumn = {
      id: 'end',
      Header: t('editPlanPage.results.periods.table.end'),
      minWidth: 150,
      accessor: (period) => period,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div
          className={classNames({
            cell: true,
            frozen: !!value.isFrozen,
          })}
        >
          {moment(value.end).format('L')}
        </div>
      ),
      /* eslint-enable react/prop-types */
      disableBodyCellComponent: true,
    };

    columns.push(endColumn);

    const stringifiedPeriodLength = JSON.stringify(periods[0] ?? {}).length;
    const codeValueColumnLength = Math.max(stringifiedPeriodLength, 50);

    const codeValueColumn = {
      id: 'codeValue',
      Header: t('editPlanPage.results.periods.table.codeValue'),
      minWidth: computeColumnWidthFromContentLength(codeValueColumnLength),
      maxWidth: computeColumnWidthFromContentLength(codeValueColumnLength),
      width: 'auto',
      accessor: (period) => period,
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <div
          className={classNames({
            cell: true,
            frozen: !!value.isFrozen,
          })}
        >
          {JSON.stringify(value)}
        </div>
      ),
      /* eslint-enable react/prop-types */
      disableBodyCellComponent: true,
    };

    columns.push(codeValueColumn);

    return columns;
  }, [planId]);

  const tableNode = useMemo(() => {
    if (periods.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <Table
        columns={tableColumns}
        data={periods}
        stickyHeader
        fitInContainer
      />
    );
  }, [
    periods,
    tableColumns,
  ]);

  const contentNode = useMemo(() => (
    <div className={styles.content}>
      {tableNode}
      <PlanV3PeriodsAdvancedTables className={styles.advancedTables} details={details} />
    </div>
  ), [tableNode, details]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapse}
        panels={[{
          title: titleNode,
          content: contentNode,
        }]}
        bordered={false}
        ghost
      />
    </div>
  );
};

PlanV3PeriodsResults.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
};

PlanV3PeriodsResults.defaultProps = {
  className: '',
};

export default PlanV3PeriodsResults;
