import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import QuotaPerformanceListItem from '@palette/components/quotaPerformance/QuotaPerformanceListItem/QuotaPerformanceListItem';

import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';

import styles from './QuotaPerformanceList.less';

const QuotaPerformanceList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dashboardId } = useParams();

  useEffect(() => {
    dispatch(QuotasPerformanceActions.getListAndCurrent({ quotaPerformanceId: dashboardId }));
  }, []);

  const listIsPending = useSelector(QuotasPerformanceSelectors.getListIsPending);
  const dashboardsList = useSelector(QuotasPerformanceSelectors.getDashboardsList);

  let listContentNode = (
    <DefaultEmptyState description={t('quotasPerformancePage.empty.description')} />
  );

  if (listIsPending) {
    listContentNode = (
      <Loader />
    );
  } else if (dashboardsList.length > 0) {
    listContentNode = dashboardsList.map((dashboard) => (
      <QuotaPerformanceListItem key={dashboard.id} className={styles.item} dashboard={dashboard} />
    ));
  }

  return (
    <div className={styles.wrapper}>
      {listContentNode}
    </div>
  );
};

export default QuotaPerformanceList;
