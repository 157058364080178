import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import { STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';
import { TAG_THEMES } from '@palette/constants/tag';

import * as UserStatementCorrectionModel from '@palette/models/UserStatementCorrection';

import styles from './CorrectionSuggestedAmount.less';

const classNames = bindClassNames.bind(styles);

const CorrectionSuggestedAmount = ({ className, correction }) => {
  const { t } = useTranslation();

  const diffAmount = useMemo(() => (
    correction.newAmount - correction.correctedAmount
  ), [correction]);

  const paidAmountNode = useMemo(() => {
    const { theme } = STATEMENT_PERIOD_USER_STATUSES.PAID_AMOUNT;

    return (
      <div className={styles.descriptionWrapper}>
        <div>
          {t('correctionSuggestedAmount.paidAmount.label')}
        </div>
        <div className={styles.descriptionContent}>
          <Tag
            theme={theme}
            label={formatPrice(correction.amount, correction.currency)}
          />
        </div>
      </div>
    );
  }, [correction]);

  const lastAmountNode = useMemo(() => (
    <div className={styles.descriptionWrapper}>
      <div>
        {t('correctionSuggestedAmount.lastAmount.label')}
      </div>
      <div className={styles.descriptionContent}>
        <Tag
          className={styles.amountTag}
          theme={TAG_THEMES.LAVENDER}
          label={formatPrice(correction.correctedAmount, correction.currency)}
        />
      </div>
    </div>
  ), [correction]);

  const newAmountNode = useMemo(() => (
    <div className={styles.descriptionWrapper}>
      <div>
        {t('correctionSuggestedAmount.newAmount.label')}
      </div>
      <div className={styles.descriptionContent}>
        <Tag
          className={styles.amountTag}
          theme={TAG_THEMES.WARNING}
          label={formatPrice(correction.newAmount, correction.currency)}
        />
      </div>
    </div>
  ), [correction]);

  const diffAmountNode = useMemo(() => (
    <div className={styles.descriptionWrapper}>
      <div>
        {t('correctionSuggestedAmount.diffAmount.label')}
      </div>
      <div className={styles.descriptionContent}>
        <Tag
          className={styles.amountTag}
          theme={diffAmount < 0 ? TAG_THEMES.ERROR : TAG_THEMES.SUCCESS}
          label={formatPrice(diffAmount, correction.currency)}
        />
      </div>
    </div>
  ), [diffAmount, correction]);

  const tooltipContentNode = useMemo(() => (
    <>
      {paidAmountNode}
      {lastAmountNode}
      {newAmountNode}
      {diffAmountNode}
    </>
  ), [
    paidAmountNode,
    lastAmountNode,
    newAmountNode,
    diffAmountNode,
  ]);

  return (
    <Tooltip content={tooltipContentNode}>
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {formatPrice(diffAmount, correction.currency)}
      </div>
    </Tooltip>
  );
};

CorrectionSuggestedAmount.propTypes = {
  className: PropTypes.string,
  correction: UserStatementCorrectionModel.propTypes.isRequired,
};

CorrectionSuggestedAmount.defaultProps = {
  className: '',
};

export default CorrectionSuggestedAmount;
