import React, { useEffect, useState } from 'react';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { Layout } from 'antd';

import SidebarCollapser from '@palette/components/navigation/SidebarCollapser/SidebarCollapser';
import Menu from '@palette/components/navigation/Menu/Menu';
import PaletteLogo from '@palette/components/utils/PaletteLogo/PaletteLogo';
import ActionsMenuDocumentationListener from '@palette/components/navigation/ActionsMenuDocumentationListener/ActionsMenuDocumentationListener';
import ActionsMenuFeedbackListener from '@palette/components/navigation/ActionsMenuFeedbackListener/ActionsMenuFeedbackListener';

import { useIsCollapsed } from '@palette/hooks/NavigationHooks';

import { selectors as NavigationSelectors } from '@palette/state/Navigation';

import styles from './Sidebar.less';

const classNames = bindClassNames.bind(styles);

const { Sider } = Layout;

const Sidebar = () => {
  const [isCollapsed, setCollapsed] = useIsCollapsed();
  const [isBroken, setBroken] = useState(false);

  const location = useSelector(NavigationSelectors.getCurrentLocation);
  useEffect(() => {
    if (isBroken) setCollapsed(true);
  }, [location]);

  const handleBreakpoint = (broken) => {
    setBroken(broken);
    if (broken) {
      setCollapsed(true);
    }
  };

  return (
    <>
      <Sider
        className={classNames({
          wrapper: true,
          mobile: isBroken,
        })}
        breakpoint="lg"
        onBreakpoint={handleBreakpoint}
        trigger={null}
        collapsible
        collapsed={isCollapsed}
        width="248px"
        collapsedWidth="98px"
      >
        <SidebarCollapser
          className={classNames({
            collapser: !isCollapsed,
          })}
          collapsed={isCollapsed}
          onToggle={() => setCollapsed(!isCollapsed)}
        />
        {
          !isCollapsed && (
            <div>
              <PaletteLogo key="logo" white />
            </div>
          )
        }
        <Menu className={styles.menu} />
      </Sider>
      <ActionsMenuDocumentationListener />
      <ActionsMenuFeedbackListener />
    </>
  );
};

export default Sidebar;
