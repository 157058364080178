import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Formula from '@palette/components/formula/Formula/Formula';

import styles from './RuleVariableCondition.less';

const classNames = bindClassNames.bind(styles);

const RuleVariableCondition = ({
  className,
  prefix,
  condition,
  formula,
  scope,
  variableName,
}) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <div className={styles.prefix}>
      {prefix}
    </div>
    {
      condition && (
        <div className={styles.condition}>
          (
          <Formula
            formula={condition}
            scope={scope}
          />
          )
        </div>
      )
    }
    <div className={styles.variableName}>
      {`${variableName}=`}
    </div>
    <div className={styles.formula}>
      <Formula
        formula={formula}
        scope={scope}
      />
    </div>
  </div>
);

RuleVariableCondition.propTypes = {
  className: PropTypes.string,
  prefix: PropTypes.string.isRequired,
  condition: PropTypes.string,
  formula: PropTypes.string.isRequired,
  scope: PropTypes.object,
  variableName: PropTypes.string.isRequired,
};

RuleVariableCondition.defaultProps = {
  className: '',
  condition: null,
  scope: {},
};

export default RuleVariableCondition;
