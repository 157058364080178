import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import StatementV3PageContent from '@palette/components/statementV3/StatementV3PageContent/StatementV3PageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useCurrencyInSearch } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import styles from './StatementV3Page.less';

const StatementV3Page = () => {
  const profile = useProfile();

  const { t } = useTranslation();

  const [currency, handleCurrencyChange] = useCurrencyInSearch(profile.userData.company.currency);

  if (!(hasFeature(profile, FEATURES.COMPENSATION)
    && hasFeature(profile, FEATURES.PLANV3)
    && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]))) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.statementsTitle')} />
      <div className={styles.titleWithCurrency}>
        <PageTitle
          className={styles.title}
          title={t('statements.page.title')}
        />
        <CompanyCurrencySelect
          className={styles.currencySelect}
          value={currency}
          onChange={handleCurrencyChange}
          disabled={profile.userData.company.currencies.length < 2}
        />
      </div>
      <StatementV3PageContent currency={currency} />
    </div>
  );
};

export default StatementV3Page;
