import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import OnboardUsersFromResourcesFlowPageContent from '@palette/components/userOnboarding/OnboardUsersFromResourcesFlowPageContent/OnboardUsersFromResourcesFlowPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './OnboardUsersFromResourcesFlowPage.less';

const OnboardUsersFromResourcesFlowPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (
    !hasAllRights(profile, [
      RIGHTS.ADMIN.USERS.UPDATE,
    ])
  ) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.onboardUsersFromResourcesFlowTitle')} />
      <PageTitle title={t('onboardUsersFromResourcesFlowPage.title')} />
      <OnboardUsersFromResourcesFlowPageContent />
    </div>
  );
};

export default OnboardUsersFromResourcesFlowPage;
