/* eslint-disable-next-line import/no-unresolved */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import resources from '@palette/i18n/resources';

let i18nextInstance = null;

export const getI18n = () => {
  if (i18nextInstance !== null) {
    return i18nextInstance;
  }

  i18nextInstance = i18next.createInstance();
  i18nextInstance
    .use(initReactI18next)
    .init({
      resources,
      lng: 'en',
      fallbackLng: 'en',

      keySeparator: false,

      debug: false,

      interpolation: {
        escapeValue: false,
        skipOnVariables: false,
      },
    });

  return i18nextInstance;
};

export default getI18n;
