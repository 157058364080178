import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import StatementV3Breadcrumb from '@palette/components/statementV3/StatementV3Breadcrumb/StatementV3Breadcrumb';
import StatementV3PeriodsNavigation from '@palette/components/statementV3/StatementV3PeriodsNavigation/StatementV3PeriodsNavigation';
import StatementV3UsersNavigation from '@palette/components/statementV3/StatementV3UsersNavigation/StatementV3UsersNavigation';
import StatementV3UserStatementInfosAndActions from '@palette/components/statementV3/StatementV3UserStatementInfosAndActions/StatementV3UserStatementInfosAndActions';
import StatementV3UserStatementCorrectionsSuggested from '@palette/components/statementV3/StatementV3UserStatementCorrectionsSuggested/StatementV3UserStatementCorrectionsSuggested';
import StatementV3UserStatementCorrectionsApplied from '@palette/components/statementV3/StatementV3UserStatementCorrectionsApplied/StatementV3UserStatementCorrectionsApplied';
import StatementV3UserStatementComissions from '@palette/components/statementV3/StatementV3UserStatementComissions/StatementV3UserStatementComissions';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useCurrencyInSearch } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';
import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './StatementV3UserStatementPage.less';

const StatementV3UserStatementPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();
  const { statementPeriodId } = useParams();

  const oneStatement = useSelector((state) => StatementV3Selectors.getOneStatement(state));
  const usersList = useSelector((state) => UsersSelectors.getUsersList(state));

  const [currency, handleCurrencyChange] = useCurrencyInSearch(profile.userData.company.currency);

  useEffect(() => {
    dispatch(StatementV3Actions.getOneStatement({ statementPeriodId, currency }));
  }, [currency, statementPeriodId]);

  const userDisplayName = useMemo(() => {
    if (!usersList.length) return null;

    const [userId] = statementPeriodId.split('-');
    const user = usersList.find((u) => u.id === userId);

    if (user === null) return '-';

    return user.displayName;
  }, [usersList, statementPeriodId]);

  const selectUserNode = useMemo(() => {
    if (!oneStatement) return null;
    return <StatementV3UsersNavigation />;
  }, [oneStatement]);

  const infosAndActionsNode = useMemo(() => {
    if (oneStatement === null) return null;

    return (
      <StatementV3UserStatementInfosAndActions statement={oneStatement} currency={currency} />
    );
  }, [oneStatement, currency]);

  const contentNode = useMemo(() => {
    if (oneStatement === null) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <>
        <StatementV3UserStatementCorrectionsSuggested statement={oneStatement} currency={currency} />
        <StatementV3UserStatementCorrectionsApplied statement={oneStatement} currency={currency} />
        <StatementV3UserStatementComissions statement={oneStatement} currency={currency} />
      </>
    );
  }, [oneStatement, currency]);

  if (!(hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]))) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.statementsUserStatementTitle')} />
      <StatementV3Breadcrumb className={styles.breadcrumb} period={oneStatement} userName={userDisplayName} />
      <div className={styles.titleWithCurrency}>
        <PageTitle className={styles.title}>
          <h2>{t('statementsUserStatement.page.title')}</h2>
        </PageTitle>
        <CompanyCurrencySelect
          className={styles.currencySelect}
          value={currency}
          onChange={handleCurrencyChange}
          disabled={profile.userData.company.currencies.length < 2}
        />
      </div>
      <div className={styles.container}>
        <StatementV3PeriodsNavigation className={styles.navigation} currency={profile.userData.company.currency} inUserStatement />
        {selectUserNode}
        {infosAndActionsNode}
        {contentNode}
      </div>
    </div>
  );
};

export default StatementV3UserStatementPage;
