import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'StatementOneMonthData';

export const propTypes = PropTypes.shape({
  currency: PropTypes.string,
  period: YearPeriodModel.propTypes,
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const transform = (statementOneMonth) => {
  if (!statementOneMonth) {
    return null;
  }

  return (
    {
      currency: manageStringAttribute(statementOneMonth, 'currency', null),
      period: YearPeriodModel.transform(manageFreeShapeObjectAttribute(statementOneMonth, 'period')),
      totalCommissionAmount: manageAmountAttribute(statementOneMonth.period || {}, 'totalCommissionAmount'),
    }
  );
};
