import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodsDatesSelector from '@palette/components/statement/statementPeriod/StatementPeriodsDatesSelector/StatementPeriodsDatesSelector';
import StatementPeriodsList from '@palette/components/statement/statementPeriod/StatementPeriodsList/StatementPeriodsList';

import styles from './StatementsPageContent.less';

const classNames = bindClassNames.bind(styles);

const StatementsPageContent = ({ className, currency }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <StatementPeriodsDatesSelector className={styles.datesSelector} />
    <StatementPeriodsList currency={currency} />
  </div>
);

StatementsPageContent.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
};

StatementsPageContent.defaultProps = {
  className: '',
  currency: null,
};

export default StatementsPageContent;
