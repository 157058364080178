import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTable, useExpanded, useSortBy } from 'react-table';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import TableHeaderCell from '@palette/components/designSystem/TableHeaderCell/TableHeaderCell';
import TableBodyCell from '@palette/components/designSystem/TableBodyCell/TableBodyCell';
import TableFooterCell from '@palette/components/designSystem/TableFooterCell/TableFooterCell';
import { TableHeaderRefForwarded as TableHeader } from '@palette/components/designSystem/TableHeader/TableHeader';
import TableBody from '@palette/components/designSystem/TableBody/TableBody';
import TableFooter from '@palette/components/designSystem/TableFooter/TableFooter';

import { useWindowSize } from '@palette/hooks/CommonHooks';

import { getColumnWidthAsFiniteNumber } from '@palette/helpers/components/TableHelper';

import styles from './Table.less';

const classNames = bindClassNames.bind(styles);

const Table = ({
  className,
  type,
  columns,
  data,
  tableProps,
  tableHooks,
  HeaderCellComponent,
  BodyCellComponent,
  FooterCellComponent,
  stickyHeader,
  nbOfFixedColumns,
  fixedColumnsPosition,
  scrollToBottom,
  stretch,
  highlightRowOnHover,
  displayFooters,
  enableExpandRow,
  renderExpandedRow,
  fitInContainer,
  centerInContainer,
  enableSortableColumns,
}) => {
  const windowSize = useWindowSize();

  const [horizontalScrollingOnLeft, setHorizontalScrollingOnLeft] = useState(true);
  const [horizontalScrollingOnRight, setHorizontalScrollingOnRight] = useState(false);

  const tableRef = useRef(null);
  const tableHeaderRef = useRef(null);

  const finalColumns = useMemo(() => {
    const allColumns = [].concat(columns);

    if (nbOfFixedColumns > 0) {
      allColumns.unshift({
        id: '__TABLE__SHADOW_COLUMN_LEFT',
        Header: '',
        Footer: '',
        accessor: () => {},
        minWidth: 0,
        maxWidth: 0,
        width: 0,
        Cell: () => null,
        disableHeaderCellComponent: true,
        disableBodyCellComponent: true,
        disableFooterCellComponent: true,
      });
      allColumns.push({
        id: '__TABLE__SHADOW_COLUMN_RIGHT',
        Header: '',
        Footer: '',
        accessor: () => {},
        minWidth: 0,
        maxWidth: 0,
        width: 0,
        Cell: () => null,
        disableHeaderCellComponent: true,
        disableBodyCellComponent: true,
        disableFooterCellComponent: true,
      });
    }

    return allColumns;
  }, [columns, nbOfFixedColumns]);

  const tableInstance = useTable(
    {
      columns: finalColumns,
      data,
      defaultColumn: {
        width: 100,
        minWidth: 100,
      },
      ...tableProps,
    },
    useSortBy,
    useExpanded,
    ...tableHooks,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const scrollHorizontalMax = useMemo(() => {
    if (tableRef.current === null || tableHeaderRef.current === null) return -1;

    const rowMaxWidth = Math.max(headerGroups.map((headerGroup) => {
      let headerRowTotalWidth = 0;
      headerGroup.headers.forEach((column) => {
        headerRowTotalWidth += getColumnWidthAsFiniteNumber(column);
      });

      return headerRowTotalWidth;
    }));

    return rowMaxWidth - tableRef.current.getBoundingClientRect().width;
  }, [windowSize.width, tableRef.current?.getBoundingClientRect(), tableHeaderRef.current?.getBoundingClientRect(), headerGroups]);

  useEffect(() => {
    setHorizontalScrollingOnLeft(true);

    if (scrollHorizontalMax <= 0) {
      setHorizontalScrollingOnRight(true);
    } else {
      setHorizontalScrollingOnRight(false);
    }
  }, [windowSize.width, scrollHorizontalMax]);

  useScrollPosition(
    ({ currPos }) => {
      setHorizontalScrollingOnLeft(currPos.x === 0);
      setHorizontalScrollingOnRight(currPos.x === scrollHorizontalMax);
    },
    [scrollHorizontalMax],
    tableHeaderRef,
    false,
    100,
    tableRef,
  );

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        tableRef.current.scrollTop = tableRef.current.scrollHeight;
      }, 200);
    }
  }, [rows]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.container}>
        <div
          ref={tableRef}
          className={classNames({
            table: true,
            fitInContainer,
            borderless: type === 'borderless',
            [fixedColumnsPosition]: nbOfFixedColumns > 0,
            horizontalScrollingOnLeft,
            horizontalScrollingOnRight,
          })}
          {...getTableProps()}
        >
          <TableHeader
            className={classNames({
              centerInContainer,
            })}
            ref={tableHeaderRef}
            type={type}
            headerGroups={headerGroups}
            HeaderCellComponent={HeaderCellComponent}
            nbOfFixedColumns={nbOfFixedColumns > 0 ? nbOfFixedColumns + 1 : 0}
            fixedColumnsPosition={fixedColumnsPosition}
            isSticky={stickyHeader}
            stretch={stretch}
            horizontalScrollingOnLeft={horizontalScrollingOnLeft}
            horizontalScrollingOnRight={horizontalScrollingOnRight}
            enableSortableColumns={enableSortableColumns}
          />
          <TableBody
            className={classNames({
              body: true,
              centerInContainer,
              hasFooters: displayFooters,
            })}
            type={type}
            rows={rows}
            prepareRow={prepareRow}
            BodyCellComponent={BodyCellComponent}
            nbOfFixedColumns={nbOfFixedColumns > 0 ? nbOfFixedColumns + 1 : 0}
            fixedColumnsPosition={fixedColumnsPosition}
            tableBodyProps={getTableBodyProps()}
            stretch={stretch}
            highlightRowOnHover={highlightRowOnHover}
            hasFooters={displayFooters}
            enableExpandRow={enableExpandRow}
            renderExpandedRow={renderExpandedRow}
            horizontalScrollingOnLeft={horizontalScrollingOnLeft}
            horizontalScrollingOnRight={horizontalScrollingOnRight}
          />
          {
            displayFooters && (
              <TableFooter
                className={classNames({
                  centerInContainer,
                })}
                type={type}
                footerGroups={footerGroups}
                FooterCellComponent={FooterCellComponent}
                nbOfFixedColumns={nbOfFixedColumns > 0 ? nbOfFixedColumns + 1 : 0}
                fixedColumnsPosition={fixedColumnsPosition}
                stretch={stretch}
                horizontalScrollingOnLeft={horizontalScrollingOnLeft}
                horizontalScrollingOnRight={horizontalScrollingOnRight}
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  tableProps: PropTypes.shape({
    initialState: PropTypes.object,
    autoResetHiddenColumns: PropTypes.bool,
    stateReducer: PropTypes.func,
    useControlledState: PropTypes.func,
    defaultColumn: PropTypes.object,
    getSubRows: PropTypes.func,
    getRowId: PropTypes.func,
  }),
  tableHooks: PropTypes.arrayOf(PropTypes.any),
  HeaderCellComponent: PropTypes.func,
  BodyCellComponent: PropTypes.func,
  FooterCellComponent: PropTypes.func,
  stickyHeader: PropTypes.bool,
  nbOfFixedColumns: PropTypes.number,
  fixedColumnsPosition: PropTypes.oneOf(['fromLeft', 'fromRight']),
  scrollToBottom: PropTypes.bool,
  stretch: PropTypes.bool,
  highlightRowOnHover: PropTypes.bool,
  displayFooters: PropTypes.bool,
  enableExpandRow: PropTypes.bool,
  renderExpandedRow: PropTypes.func,
  fitInContainer: PropTypes.bool,
  centerInContainer: PropTypes.bool,
  enableSortableColumns: PropTypes.bool,
};

Table.defaultProps = {
  className: '',
  type: 'default',
  tableProps: {},
  tableHooks: [],
  HeaderCellComponent: TableHeaderCell,
  BodyCellComponent: TableBodyCell,
  FooterCellComponent: TableFooterCell,
  stickyHeader: false,
  nbOfFixedColumns: 0,
  fixedColumnsPosition: 'fromLeft',
  scrollToBottom: false,
  stretch: false,
  highlightRowOnHover: false,
  displayFooters: false,
  enableExpandRow: false,
  renderExpandedRow: null,
  fitInContainer: false,
  centerInContainer: false,
  enableSortableColumns: false,
};

export default Table;
