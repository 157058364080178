import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AddUsersWithFastOnboardingModal from '@palette/components/user/AddUsersWithFastOnboardingModal/AddUsersWithFastOnboardingModal';

import * as TeamModel from '@palette/models/Team';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

const AddMembersToTeamModal = ({ visible, onClose, team }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const addMembersToTeamIsPending = useSelector(TeamsSelectors.addMembersToTeamIsPending);

  const preSelectedUsers = useMemo(() => (team.members.map((member) => member.user)), [team]);

  const handleAddMembersToTeam = useCallback((usersToAdd, cleanAndClose) => {
    dispatch(TeamsActions.addMembersToTeam({ teamId: team.id, usersToAdd, onSuccessCB: cleanAndClose }));
  }, [team]);

  return (
    <AddUsersWithFastOnboardingModal
      title={t('addMembersToTeamModal.title', { teamName: team.name })}
      visible={visible}
      onClose={onClose}
      onOk={handleAddMembersToTeam}
      okText={t('addMembersToTeamModal.addMembers')}
      loading={addMembersToTeamIsPending}
      preSelectedUsers={preSelectedUsers}
    />
  );
};

AddMembersToTeamModal.propTypes = {
  team: TeamModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

AddMembersToTeamModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddMembersToTeamModal;
