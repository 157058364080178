import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import Switch from '@palette/components/designSystem/Switch/Switch';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import * as ConnectorModel from '@palette/models/Connector';

import styles from './ResourcesLastSyncDatesModal.less';

const ResourcesLastSyncDatesModal = ({ visible, onClose, connector, onSyncConnector }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resetLastSyncDatesIsPending = useSelector(ConnectorsSelectors.resetLastSyncDatesIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    resourceTypes: [],
    lastSyncDate: null,
  }), []);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const handleClose = () => onClose();

  const syncAndClose = () => {
    onSyncConnector();
    handleClose();
  };

  const handleFinish = (values) => {
    const finalResourceTypes = [];

    connector.resources.forEach((resource, index) => {
      if (values[`resourceTypes[${index}]`]) {
        finalResourceTypes.push(resource.type);
      }
    });

    dispatch(ConnectorsActions.resetLastSyncDates({
      connectorId: connector.id,
      resourceTypes: finalResourceTypes,
      lastSyncDate: values.lastSyncDate,
      onSuccessCB: syncAndClose,
    }));
  };

  const handleUpdateSyncDates = () => form.submit();

  const listObjectsNode = useMemo(() => connector.resources.map((resource, index) => (
    <div className={styles.switchWrapper} key={index}>
      <FormItem
        className={styles.switch}
        name={`resourceTypes[${index}]`}
      >
        <Switch disabled={resetLastSyncDatesIsPending} />
      </FormItem>
      <div className={styles.switchLabel}>
        {resource.type}
      </div>
    </div>
  )), [
    connector,
  ]);

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('resourceLastSyncDates.modal.title')}
        </div>
      )}
      visible={visible}
      onCancel={handleClose}
      onOk={handleUpdateSyncDates}
      okText={t('resourceLastSyncDates.modal.save.label')}
      loading={resetLastSyncDatesIsPending}
    >
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <div className={styles.switchContainer}>
          {listObjectsNode}
        </div>
        <FormItem
          name="lastSyncDate"
          label={t('resourceLastSyncDates.modal.dateField.label')}
          required
          rules={[
            { required: true, message: t('resourceLastSyncDates.modal.dateField.error.message'), type: 'date' },
          ]}
        >
          <DatePicker
            className={styles.dateField}
            picker="date"
            allowClear={false}
            disabledDate={(d) => !d || d.isSameOrBefore('2010-01-01')}
            showNow={false}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

ResourcesLastSyncDatesModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  connector: ConnectorModel.propTypes.isRequired,
  onSyncConnector: PropTypes.func,
};

ResourcesLastSyncDatesModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onSyncConnector: () => {},
};

export default ResourcesLastSyncDatesModal;
