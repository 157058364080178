import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import bindClassNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { NOTEBOOK_CELL_EXPORTABLE_STATUSES, NOTEBOOK_CELL_TYPES } from '@palette/constants/notebooks';

import * as NotebookCellModel from '@palette/models/NotebookCell';

import { selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import { InfoCircleFilled } from '@ant-design/icons';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import styles from './EditableNotebookCellUniqueIdentifier.less';

const classNames = bindClassNames.bind(styles);

const EditableNotebookCellUniqueIdentifier = ({ className, cell, updateUniqueIdentifier, disabled, hasError }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const inputRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [cellUniqueIdentifier, setCellUniqueIdentifier] = useState(cell.outputUniqueIdentifier);
  const isExportable = NOTEBOOK_CELL_TYPES[cell.typeKey].exportableStatus === NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTABLE;

  useEffect(() => {
    setCellUniqueIdentifier(cell.outputUniqueIdentifier);
  }, [cell.outputUniqueIdentifier]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
      return;
    }

    setCellUniqueIdentifier(cell.outputUniqueIdentifier);
  }, [editing, cell.outputUniqueIdentifier]);

  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  useEffect(() => {
    if (!editNotebookIsPending) {
      setEditing(false);
    }
  }, [editNotebookIsPending]);

  const saveUpdate = useCallback(() => {
    setEditing(false);

    if (cell.outputUniqueIdentifier !== cellUniqueIdentifier) {
      updateUniqueIdentifier(cellUniqueIdentifier.trim());
    }
  }, [cell, cellUniqueIdentifier, updateUniqueIdentifier]);

  const handleInputChange = useCallback((newValue) => {
    setCellUniqueIdentifier(newValue);
  }, []);

  const switchToEditMode = useCallback(() => {
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE]) && !disabled) {
      setEditing(true);
    }
  }, [profile, disabled]);

  const inputNode = useMemo(() => (
    editing
      ? (
        <Input
          ref={inputRef}
          className={classNames({
            input: true,
            [className]: className !== '',
          })}
          type="text"
          value={cellUniqueIdentifier}
          onChange={handleInputChange}
          onBlur={saveUpdate}
          onPressEnter={saveUpdate}
        />
      )
      : null
  ), [editing, inputRef, className, cellUniqueIdentifier, handleInputChange, saveUpdate]);

  const readonlyUniqueIdentifierNode = useMemo(() => (
    !editing ? (
      <div
        aria-errormessage={t('notebookCell.uniqueIdentifier.required')}
        className={classNames({
          wrapper: true,
          disabled,
          required: hasError,
        })}
        onClick={switchToEditMode}
      >
        {cell.outputUniqueIdentifier}
        &nbsp;
      </div>
    ) : null
  ), [editing, disabled, className, switchToEditMode, cell]);

  if (!isExportable) {
    return null;
  }

  return (
    <div className={classNames({ cellUniqueIdentifierWrapper: true })}>
      <div className={styles.label}>
        {t('notebookCell.uniqueIdentifier.label')}
        :
      </div>
      {inputNode}
      {readonlyUniqueIdentifierNode}
      <Tooltip title={t('notebookCell.uniqueIdentifier.info')}>
        <InfoCircleFilled className={styles.info} />
      </Tooltip>
    </div>
  );
};

EditableNotebookCellUniqueIdentifier.propTypes = {
  className: PropTypes.string,
  cell: NotebookCellModel.propTypes.isRequired,
  updateUniqueIdentifier: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
};

EditableNotebookCellUniqueIdentifier.defaultProps = {
  className: '',
  disabled: false,
  hasError: false,
};

export default EditableNotebookCellUniqueIdentifier;
