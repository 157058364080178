import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Input from '@palette/components/designSystem/Input/Input';
import Select from '@palette/components/designSystem/Select/Select';
import EditVariableCustomDisplayModal from '@palette/components/commission/EditVariableCustomDisplayModal/EditVariableCustomDisplayModal';
import Button from '@palette/components/designSystem/Button/Button';
import FunctionLine from '@palette/components/utils/Icons/FunctionLine';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';

import { COLUMN_FORMATTERS, FORMATTERS_VALUES } from '@palette/constants/masterPlans';
import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';

import * as ResourceColumnModel from '@palette/models/ResourceColumn';

import styles from './DrawerVariableEdition.less';

const classNames = bindClassNames.bind(styles);

const DrawerVariableEdition = ({
  className,
  variable,
  helperData,
  onNameChange,
  onFieldsChange,
  onFormatterChange,
  onEvalFnChange,
}) => {
  const { t } = useTranslation();

  const [editVariableCustomDisplayModalIsVisible, showEditVariableCustomDisplayModal] = useState(false);

  const handleNameChange = useCallback((newName) => {
    onNameChange(newName);
  });

  const handleFieldsChange = useCallback((newFields) => {
    onFieldsChange(newFields);
  });

  const handleFormatterChange = useCallback((newFormatter) => {
    onFormatterChange(newFormatter);

    if (newFormatter === FORMATTERS_VALUES.Custom) {
      showEditVariableCustomDisplayModal(true);
    }
  });

  const handleEvalFnChange = useCallback((newEvalFn) => {
    onEvalFnChange(newEvalFn);
  });

  const formattersOptions = Object.keys(COLUMN_FORMATTERS).map((formatterKey) => {
    const formatter = COLUMN_FORMATTERS[formatterKey];

    return {
      label: t(formatter.label),
      value: formatter.value,
    };
  });

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Input
        className={classNames({
          input: true,
          name: true,
        })}
        size="big"
        placeholder={t('drawerVariableEdition.placeholders.variableName')}
        value={variable.name}
        onChange={handleNameChange}
      />
      <FormulaInput
        className={classNames({
          fields: true,
        })}
        type={FORMULA_INPUT_TYPES.MULTIPLE_FIELDS_SELECTION}
        helperData={helperData}
        includePrefix={false}
        size="big"
        placeholder={t('drawerVariableEdition.placeholders.fields')}
        value={variable.fields}
        onChange={handleFieldsChange}
      />
      <Select
        className={classNames({
          formatter: true,
        })}
        size="big"
        options={formattersOptions}
        dropdownMatchSelectWidth={false}
        value={variable.formatter}
        onChange={handleFormatterChange}
      />
      {
        variable.formatter === FORMATTERS_VALUES.Custom && (
          <Button
            className={styles.editEvalFn}
            type="link"
            icon={<FunctionLine />}
            onClick={() => showEditVariableCustomDisplayModal(true)}
          />
        )
      }
      {
        editVariableCustomDisplayModalIsVisible && (
          <EditVariableCustomDisplayModal visible onClose={() => showEditVariableCustomDisplayModal(false)} variable={variable} updateEvalFn={handleEvalFnChange} />
        )
      }
    </div>
  );
};

DrawerVariableEdition.propTypes = {
  className: PropTypes.string,
  variable: ResourceColumnModel.propTypes.isRequired,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  onNameChange: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  onFormatterChange: PropTypes.func.isRequired,
  onEvalFnChange: PropTypes.func.isRequired,
};

DrawerVariableEdition.defaultProps = {
  className: '',
};

export default DrawerVariableEdition;
