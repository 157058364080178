import { put, all, takeLatest, select } from 'redux-saga/effects';

import { getAddQSToLocationNewLocation, getCleanLocationSearchNewLocation, getHistory } from '@palette/helpers/NavigationHelper';

import { actions as NavigationActions, selectors as NavigationSelectors } from '@palette/state/Navigation/slice';

export function* addQSToLocation({ payload }) {
  const { qsObject, forcedLocation = null } = payload;

  let location = forcedLocation;

  if (forcedLocation === null) {
    location = yield select(NavigationSelectors.getCurrentLocation);
  }

  const newLocation = getAddQSToLocationNewLocation(location, qsObject);

  const history = getHistory();
  history.push(newLocation);
}

export function* cleanLocationSearch({ payload }) {
  const { keysToDelete } = payload;

  const location = yield select(NavigationSelectors.getCurrentLocation);

  const newLocation = getCleanLocationSearchNewLocation(location, keysToDelete);

  const history = getHistory();
  history.push(newLocation);
}

export function* updateAndCleanQSInLocation({ payload }) {
  const { qsObject = {}, keysToDelete = [] } = payload;

  const location = yield select(NavigationSelectors.getCurrentLocation);

  let newLocation = getAddQSToLocationNewLocation(location, qsObject);
  newLocation = getCleanLocationSearchNewLocation(newLocation, keysToDelete);

  const history = getHistory();
  history.push(newLocation);
}

export function* loop() {
  yield all([
    takeLatest(NavigationActions.addQSToLocation.type, addQSToLocation),
    takeLatest(NavigationActions.cleanLocationSearch.type, cleanLocationSearch),
    takeLatest(NavigationActions.updateAndCleanQSInLocation.type, updateAndCleanQSInLocation),
  ]);
}

export function* clean() {
  yield put(NavigationActions.resetToInitialState());
}
