import React, { Component } from 'react';
import { message, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { getSellsyConnectURL } from '../../../../services/connector';
import { CONNECTOR_TYPES } from '../../../../services/constants';
import SourceVignette from '../sourceVignette';
import '../../../../styles/connectCRMButton.less';

class ConnectSellsyButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  connectSellsy = () => {
    this.setState({ loading: true });
    getSellsyConnectURL()
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((e) => {
        message.error('Error while contacting Sellsy.');
        console.error(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    const { disabled } = this.props;
    return (
      <Tooltip
        placement="right"
        title={disabled ? 'Connector already linked' : null}
      >
        <SourceVignette
          loading={loading}
          icon={<img src="/img/sellsy_icon.png" alt="sellsy" style={{ width: '44px' }} />}
          type={CONNECTOR_TYPES.CRM}
          name="Sellsy"
          id="SELLSY"
          disabled={disabled}
          onClick={disabled ? null : this.connectSellsy}
        />
      </Tooltip>

    );
  }
}
ConnectSellsyButton.propTypes = {
  disabled: PropTypes.bool,
};

ConnectSellsyButton.defaultProps = {
  disabled: false,
};
export default ConnectSellsyButton;
