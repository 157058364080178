import React from 'react';
import PropTypes from 'prop-types';
import {
  AutoComplete, Button, Input, Radio, Select, Menu, Dropdown,
} from 'antd';
import {
  DeleteOutlined, PlusCircleFilled, CloseOutlined, MoreOutlined,
} from '@ant-design/icons';
import '../../styles/admin/queryBuilder.less';

/* eslint-disable react/prop-types, react/jsx-no-useless-fragment */
const ItemWrapper = ({ children }) => (
  <>
    { children }
  </>
);
/* eslint-enable react/prop-types, react/jsx-no-useless-fragment */

const OPERATORS = [
  {
    name: 'Equals',
    value: '$eq',
  },
  {
    name: 'Not Equals',
    value: '$ne',
  },
  {
    name: 'Contains',
    value: '$regex',
  },
  {
    name: 'In',
    value: '$in',
  },
  {
    name: 'Not In',
    value: '$nin',
  },
  {
    name: 'Greater Than',
    value: '$gt',
  },
  {
    name: 'Greater Than Equals',
    value: '$gte',
  },
  {
    name: 'Lower Than',
    value: '$lt',
  },
  {
    name: 'Lower Than Equals',
    value: '$lte',
  },
];
const OPERATOR_TO_STRING = {
  $eq: '=',
  $ne: 'NOT =',
  $regex: 'CONTAINS',
  $in: 'IN',
  $nin: 'NOT IN',
  $gt: '>',
  $gte: '>=',
  $lt: '<',
  $lte: '<=',
  $and: 'AND',
  $or: 'OR',
};

const isGroup = (object) => {
  if (typeof object !== 'object' || object === null) return false;
  const keys = Object.keys(object);
  return keys.length === 1 && ['$and', '$or'].includes(keys[0]);
};

const isRule = (object, prefix) => {
  if (typeof object !== 'object' || object === null) return false;
  const keys = Object.keys(object);
  if (keys.length !== 1) return false;
  const [field] = keys;
  if (field.indexOf(prefix) !== 0) return false;
  const objectValue = object[field];
  if (typeof objectValue !== 'object' || objectValue === null) return false;
  const objectValueKeys = Object.keys(objectValue);
  if (objectValueKeys.length !== 1) return false;
  const [operator] = objectValueKeys;
  const knownOperator = OPERATORS.find((o) => o.value === operator);
  if (!knownOperator) return false;
  return typeof objectValue[operator] === 'string';
};

const toHtml = (object, prefix, key = 0) => {
  if (isGroup(object)) {
    const [operator] = Object.keys(object);
    if (object[operator].length === 0) return null;
    const children = object[operator]
      .map((item, index) => toHtml(item, prefix, index))
      .filter((result) => result !== null);
    if (children.length === 0) return null;
    return (
      <div className="HtmlGroupContainer" key={`GG${key}`}>
        {children.map((item, index, array) => [
          item,
          index < array.length - 1 ? (
            // eslint-disable-next-line react/no-array-index-key
            <div className="HtmlGroupOperator" key={`Group${index}`}>
              {OPERATOR_TO_STRING[operator]}
            </div>
          ) : null,
        ].filter((e) => e !== null)).flat()}
      </div>
    );
  }
  if (isRule(object, prefix)) {
    const [field] = Object.keys(object);
    const objectValue = object[field];
    const [operator] = Object.keys(objectValue);
    const value = objectValue[operator];
    return (
      <div className="HtmlRule" key={`Item${key}`}>
        <div className="HtmlField">{field.replace(prefix || '', '') || '<empty>'}</div>
        <div className="HtmlOperator">{OPERATOR_TO_STRING[operator]}</div>
        <div className="HtmlValue">{value || '<empty>'}</div>
      </div>
    );
  }
  return (<div className="HtmlError">Error</div>);
};

const CHANGE_INTERVAL = 500;

class QueryBuilder extends React.Component {
  static toHtml = toHtml;

  constructor(props) {
    super(props);
    this.state = {
      query: JSON.parse(JSON.stringify(props.query)),
      toggled: false,
    };
  }

  /* eslint-disable-next-line react/no-unused-class-component-methods */
  getQuery = () => this.state.query;

  propsOnChange = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.props.onChange(this.state.query), CHANGE_INTERVAL);
  };

  updateGroupType = (operator, object) => {
    const [currentKey] = Object.keys(object);
    /* eslint-disable no-param-reassign */
    object[operator] = object[currentKey];
    delete object[currentKey];
    /* eslint-enable no-param-reassign */
    this.setState(this.state, this.propsOnChange);
  };

  addRule = (array) => {
    array.push({ [this.props.prefix || '']: { $eq: '' } });
    this.setState(this.state, this.propsOnChange);
  };

  addGroup = (array) => {
    array.push({ $and: [{ [this.props.prefix || '']: { $eq: '' } }] });
    this.setState(this.state, this.propsOnChange);
  };

  deleteItem = (keys) => {
    let field = this.state.query;
    for (let i = 0; i < keys.length - 1; i += 1) {
      field = field[keys[i]];
    }
    field.splice(keys[keys.length - 1], 1);
    this.setState(this.state, this.propsOnChange);
  };

  updateFieldName = (oldField, newField, object) => {
    /* eslint-disable no-param-reassign */
    object[`${this.props.prefix || ''}${newField}`] = object[`${this.props.prefix || ''}${oldField}`];
    delete object[`${this.props.prefix || ''}${oldField}`];
    /* eslint-enable no-param-reassign */
    this.setState(this.state, this.propsOnChange);
  };

  updateFieldOperator = (oldOperator, newOperator, object) => {
    /* eslint-disable no-param-reassign */
    object[newOperator] = object[oldOperator];
    delete object[oldOperator];
    /* eslint-enable no-param-reassign */
    this.setState(this.state, this.propsOnChange);
  };

  updateFieldValue = (operator, value, object) => {
    // eslint-disable-next-line no-param-reassign
    object[operator] = value;
    this.setState(this.state, this.propsOnChange);
  };

  renderBlock(object, keys = []) {
    const level = keys.length;
    const {
      disabled,
      prefix,
      simplify,
    } = this.props;
    if (isGroup(object)) {
      const [operator] = Object.keys(object);
      return (
        <div className={`Group Level${level}`}>
          <div className="TopLine flex">
            <Radio.Group
              size="small"
              buttonStyle="solid"
              value={operator}
              disabled={disabled}
              onChange={(e) => this.updateGroupType(e.target.value, object)}
            >
              <Radio.Button value="$and">AND</Radio.Button>
              <Radio.Button value="$or">OR</Radio.Button>
            </Radio.Group>
            &nbsp;
            <Dropdown
              trigger="click"
              className="More_options"
              overlay={(
                <Menu>
                  <Menu.Item
                    disabled={disabled}
                    onClick={() => this.addGroup(object[operator])}
                  >
                    Add [AND/OR] Group
                  </Menu.Item>
                </Menu>
              )}
            >
              <MoreOutlined style={{ marginLeft: 4 }} />
            </Dropdown>
            <div style={{ flex: 1 }} />
            {level > 0 && (
              <>
                &nbsp;
                <Button
                  size="small"
                  className="grey"
                  style={{ border: 'none', color: 'grey' }}
                  ghost
                  disabled={disabled}
                  icon={<CloseOutlined />}
                  onClick={() => this.deleteItem(keys)}
                />
              </>
            )}
            {simplify && level === 0 && (
              <Button
                type="link"
                size="small"
                onClick={() => this.setState({ toggled: false })}
              >
                Hide Editor
              </Button>
            )}
          </div>
          <div className="Items">
            {object[operator].map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ItemWrapper key={index}>
                {this.renderBlock(item, [...keys, operator, index])}
              </ItemWrapper>
            ))}
          </div>
          <div className="AddButtons">
            <Button
              size="small"
              type="link"
              disabled={disabled}
              icon={<PlusCircleFilled />}
              onClick={() => this.addRule(object[operator])}
            >
              Add Condition
            </Button>
          </div>
        </div>
      );
    }
    if (isRule(object, prefix)) {
      const { fields } = this.props;
      const field = prefix
        ? Object.keys(object)[0].split(prefix)
          .slice(1)
          .join(prefix)
        : Object.keys(object)[0];
      const objectValue = object[`${prefix || ''}${field}`];
      const [operator] = Object.keys(objectValue);
      return (
        <div className="Rule flex">
          <AutoComplete
            placeholder="Field Name"
            value={field || ''}
            style={{ width: 150 }}
            disabled={disabled}
            onChange={(e) => this.updateFieldName(field || '', e, object)}
            dropdownMatchSelectWidth={false}
            filterOption={(inputValue, option) => option.value.toLowerCase()
              .includes((inputValue || '').toLowerCase())}
          >
            {fields
              .sort()
              .map((f) => (
                <AutoComplete.Option key={f} value={f}>
                  <div style={{ textTransform: 'none' }}>
                    {f}
                  </div>
                </AutoComplete.Option>
              ))}
          </AutoComplete>
          <Select
            value={operator || ''}
            dropdownMatchSelectWidth={false}
            style={{ width: 150 }}
            disabled={disabled}
            onChange={(e) => this.updateFieldOperator(operator || '', e, objectValue)}
          >
            {OPERATORS.map(({
              name,
              value,
            }) => (
              <Select.Option value={value} key={value}>
                {name}
              </Select.Option>
            ))}
          </Select>
          <Input
            disabled={disabled}
            placeholder="Value or Code"
            value={objectValue[operator || ''] || ''}
            onChange={(e) => this.updateFieldValue(operator, e.target.value, objectValue)}
            style={{
              width: 'initial',
              flex: 1,
            }}
          />
          <Button
            size="small"
            disabled={disabled}
            className="grey"
            ghost
            style={{ border: 'none', color: 'grey' }}
            icon={<CloseOutlined />}
            onClick={() => this.deleteItem(keys)}
          />
        </div>
      );
    }
    return (
      <div className="Rule ErrorItem flex">
        Not a rule/group.
        <div style={{ flex: 1 }} />
        &nbsp;
        <Button
          size="small"
          disabled={disabled}
          type="danger"
          icon={<DeleteOutlined />}
          onClick={() => this.deleteItem(keys)}
        />
      </div>
    );
  }

  render() {
    const { query, toggled } = this.state;
    const { simplify, prefix } = this.props;
    return (
      <div className="QueryBuilder">
        {simplify && !toggled ? (
          <div className="DisplayContainer">
            <div style={{ flex: 1 }} className="DisplayContainer_inner">
              {toHtml(query, prefix || '')}
            </div>
            <Button
              size="small"
              type="primary"
              onClick={() => this.setState({ toggled: true })}
              style={{ marginLeft: '12px' }}
            >
              Edit
            </Button>
          </div>
        ) : this.renderBlock(query)}
      </div>
    );
  }
}

QueryBuilder.propTypes = {
  query: PropTypes.object,
  fields: PropTypes.array,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  simplify: PropTypes.bool,
  onChange: PropTypes.func,
};

QueryBuilder.defaultProps = {
  query: { $and: [] },
  fields: [],
  prefix: undefined,
  disabled: false,
  simplify: false,
  onChange: () => null,
};

export default QueryBuilder;
