import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getCellPreviewComponentFromType } from '@palette/helpers/NotebookComponentsHelper';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookCellModel from '@palette/models/NotebookCell';

import { selectors as NotebooksSelectors } from '@palette/state/Notebooks';

const NotebookCellPreview = ({ className, notebook, cell }) => {
  const error = useSelector((state) => NotebooksSelectors.getErrorsByCellId(state, {
    notebookId: notebook.id,
    cellId: cell.id,
  }));

  const CellPreviewComponent = useMemo(() => getCellPreviewComponentFromType(cell.type), [cell]);
  if (CellPreviewComponent === null || error) return null;

  return (
    <CellPreviewComponent
      className={className}
      notebook={notebook}
      cell={cell}
    />
  );
};

NotebookCellPreview.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  cell: NotebookCellModel.propTypes.isRequired,
};

NotebookCellPreview.defaultProps = {
  className: '',
};

export default NotebookCellPreview;
