import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import RawDataAddEditModal from '@palette/components/resources/RawDataAddEditModal/RawDataAddEditModal';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import styles from './RawDataAddButton.less';

const RawDataAddButton = ({ paddingLeft, property, resourceId, path }) => {
  const { t } = useTranslation();
  const [addEditModalIsVisible, showAddEditModal] = useState(false);
  const localized = property === 'object'
    ? 'rawData.button.addProperty'
    : 'rawData.button.addItem';

  const handleAddEdit = (event) => {
    event.stopPropagation();
    showAddEditModal(true);
  };

  const closeAddEditModal = () => {
    showAddEditModal(false);
  };

  return (
    <span className={styles.wrapper} style={{ paddingLeft }}>
      <Button
        className={styles.addButton}
        type="link"
        icon={<AddFilled />}
        onClick={(event) => handleAddEdit(event)}
      >
        {t(localized)}
      </Button>
      {
        addEditModalIsVisible && (
          <RawDataAddEditModal
            visible
            onClose={() => closeAddEditModal()}
            resourceId={resourceId}
            path={path}
          />
        )
      }
    </span>
  );
};

RawDataAddButton.propTypes = {
  paddingLeft: PropTypes.string,
  property: PropTypes.oneOf(['array', 'object']),
  resourceId: PropTypes.string,
  path: PropTypes.array,
};

RawDataAddButton.defaultProps = {
  paddingLeft: '',
  property: 'object',
  resourceId: '',
  path: [],
};

export default RawDataAddButton;
