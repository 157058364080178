import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import StatementV3PeriodsCardsListItem from '@palette/components/ic/statementV3/StatementV3PeriodsCardsListItem/StatementV3PeriodsCardsListItem';

import { usePeriodsDatesFilter } from '@palette/hooks/StatementHooks';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import styles from './StatementV3PeriodsCardsList.less';

const classNames = bindClassNames.bind(styles);

const MAX_ITEMS_BY_ROW = 6;

const StatementV3PeriodsCardsList = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { statementPeriodId } = useParams();

  const [, from, to] = usePeriodsDatesFilter();

  const listPeriods = useSelector(StatementV3Selectors.getListPeriods);
  const listPeriodsIsPending = useSelector(StatementV3Selectors.getListPeriodsIsPending);

  useEffect(() => {
    dispatch(StatementV3Actions.getListPeriods({ from, to, isIC: true }));
  }, [from, to]);

  const contentNode = useMemo(() => {
    if (listPeriodsIsPending) return <Loader className={styles.loaderWrapper} />;

    if (listPeriods.length === 0) {
      return (
        <DefaultEmptyState description={t('statementPeriodsList.noPeriods')} />
      );
    }

    return (
      <div
        className={classNames({
          container: true,
          hasMultipleLines: listPeriods.length > MAX_ITEMS_BY_ROW,
        })}
      >
        {listPeriods.map((statementPeriod, index) => (
          <StatementV3PeriodsCardsListItem
            key={`${statementPeriod.periodId}-${index}`}
            className={styles.period}
            statementPeriod={statementPeriod}
            isSelected={statementPeriodId.includes(statementPeriod.periodId)}
            statementPeriodId={statementPeriodId}
          />
        ))}
      </div>
    );
  }, [
    listPeriods,
    listPeriodsIsPending,
    statementPeriodId,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementV3PeriodsCardsList.propTypes = {
  className: PropTypes.string,
};

StatementV3PeriodsCardsList.defaultProps = {
  className: '',
};

export default StatementV3PeriodsCardsList;
