import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import Modal from '@palette/components/designSystem/Modal/Modal';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MyMasterPlanSomethingMissingModal.less';

const MyMasterPlanSomethingMissingModal = ({ visible, onClose, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: plan.trackingObject?.connectorId || '' }));
  const computePlanIsPending = useSelector(MasterPlansSelectors.computePlanIsPending);

  const handleCompute = () => {
    if (!computePlanIsPending) {
      dispatch(MasterPlansActions.computeICPlan({ planId: plan.id }));
    }
  };

  return (
    <Modal
      className={styles.modal}
      title={t('myMasterPlanSomethingMissingModal.title')}
      visible={visible}
      onCancel={onClose}
      displayFooter={false}
      width="50%"
    >
      <div className={styles.content}>
        {!connector && (
          <p>{t('myMasterPlanSomethingMissingModal.intro1', { type: plan.trackingObject?.type })}</p>
        )}
        {connector && (
          <>
            <p>{t('myMasterPlanSomethingMissingModal.intro2', { type: plan.trackingObject?.type })}</p>
            <p>
              <span className={styles.title}>
                <CheckFilled className={styles.icon} width={16} height={16} />
                {t('myMasterPlanSomethingMissingModal.verifyTitle', { connectorType: connector.name })}
              </span>
              {t('myMasterPlanSomethingMissingModal.verifyText')}
            </p>
          </>
        )}
        <p>
          <span className={styles.title}>
            <RefreshLine className={styles.icon} width={16} height={16} />
            {t('myMasterPlanSomethingMissingModal.triggerTitle')}
          </span>
          {t('myMasterPlanSomethingMissingModal.triggerText')}
          <Button
            className={styles.computeIcon}
            type="link"
            icon={<RefreshLine width={16} height={16} spin={computePlanIsPending} />}
            onClick={handleCompute}
            disabled={computePlanIsPending}
          />
        </p>
      </div>
    </Modal>
  );
};

MyMasterPlanSomethingMissingModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  plan: MasterPlanModel.propTypes.isRequired,
};

MyMasterPlanSomethingMissingModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default MyMasterPlanSomethingMissingModal;
