import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';

import { entities } from '@palette/components/globalNotif/GlobalNotifContentStrategies/entities';

import { GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

import * as GlobalNotifModel from '@palette/models/GlobalNotif';

import styles from './GlobalNotifItem.less';

const classNames = bindClassNames.bind(styles);

const GlobalNotifItem = ({ className, notif, onClose }) => {
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    if (notif.id && onClose && notif.type === GLOBAL_NOTIF_TYPES.PERMANENT) {
      onClose(notif.id);
    }
  }, [notif]);

  const contentNode = useMemo(() => {
    const { content } = notif;

    const contentStrategyComp = entities[content?.contentStrategy];

    if (!contentStrategyComp) return null;

    const Content = (props) => contentStrategyComp(props);

    return <Content className={styles.contentDesc} content={content} />;
  }, [notif]);

  const notifNode = useMemo(() => {
    const { fadeOut, hidden, theme, title, type } = notif;

    const isClosable = type === GLOBAL_NOTIF_TYPES.PERMANENT;

    return (
      <Alert
        className={classNames({
          wrapper: true,
          fadeOut,
          hidden,
          [className]: className !== '',
        })}
        type={theme}
        message={t(title)}
        description={contentNode}
        isClosable={isClosable}
        onClose={handleClose}
      />
    );
  }, [notif, contentNode, handleClose]);

  return notifNode;
};

GlobalNotifItem.propTypes = {
  className: PropTypes.string,
  notif: GlobalNotifModel.propTypes.isRequired,
  onClose: PropTypes.func,
};

GlobalNotifItem.defaultProps = {
  className: '',
  onClose: undefined,
};

export default GlobalNotifItem;
