import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddUsersInMasterPlan from '@palette/components/masterPlanSettings/AddUsersInMasterPlan/AddUsersInMasterPlan';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './MasterPlanSettingsUsersUserHeader.less';

const MasterPlanSettingsUsersUserHeader = ({ plan, forManagers }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {forManagers ? t('masterPlanSettingsUsers.table.headers.manager') : t('masterPlanSettingsUsers.table.headers.user')}
      </div>
      <AddUsersInMasterPlan plan={plan} forManagers={forManagers} />
    </div>
  );
};

MasterPlanSettingsUsersUserHeader.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  forManagers: PropTypes.bool,
};

MasterPlanSettingsUsersUserHeader.defaultProps = {
  forManagers: false,
};

export default MasterPlanSettingsUsersUserHeader;
