import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import CreatePlanFlowStepWrapper from '@palette/components/masterPlanCreation/CreatePlanFlowStepWrapper/CreatePlanFlowStepWrapper';
import CreatePlanFlowGeneralStep from '@palette/components/masterPlanCreation/CreatePlanFlowGeneralStep/CreatePlanFlowGeneralStep';
import CreatePlanFlowSelectUsersStep from '@palette/components/masterPlanCreation/CreatePlanFlowSelectUsersStep/CreatePlanFlowSelectUsersStep';
import CreatePlanFlowDataSourceStep from '@palette/components/masterPlanCreation/CreatePlanFlowDataSourceStep/CreatePlanFlowDataSourceStep';
import CreatePlanFlowDataFilteringStep from '@palette/components/masterPlanCreation/CreatePlanFlowDataFilteringStep/CreatePlanFlowDataFilteringStep';
import CreatePlanFlowQuotaSetupStep from '@palette/components/masterPlanCreation/CreatePlanFlowQuotaSetupStep/CreatePlanFlowQuotaSetupStep';
import CreatePlanFlowQuotaAttainmentStep from '@palette/components/masterPlanCreation/CreatePlanFlowQuotaAttainmentStep/CreatePlanFlowQuotaAttainmentStep';
import CreatePlanFlowCommissionRulesStep from '@palette/components/masterPlanCreation/CreatePlanFlowCommissionRulesStep/CreatePlanFlowCommissionRulesStep';
import CreatePlanFlowPayoutScheduleStep from '@palette/components/masterPlanCreation/CreatePlanFlowPayoutScheduleStep/CreatePlanFlowPayoutScheduleStep';
import CreatePlanFlowSummaryStep from '@palette/components/masterPlanCreation/CreatePlanFlowSummaryStep/CreatePlanFlowSummaryStep';

import { useDraftMasterPlanStepInSearch } from '@palette/hooks/MasterPlanHooks';

import { CREATE_PLAN_FLOW_FIRST_STEP, CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

const CreatePlanFlowPageStepsManagement = ({ plan }) => {
  const { t } = useTranslation();

  const createPlanFlowStepsNodes = useMemo(() => ({
    STEP_GENERAL: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.STEP_GENERAL.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.STEP_GENERAL.subTitle)}
      >
        <CreatePlanFlowGeneralStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    SELECT_USERS: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.SELECT_USERS.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.SELECT_USERS.subTitle)}
      >
        <CreatePlanFlowSelectUsersStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    DATA_SOURCE: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.DATA_SOURCE.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.DATA_SOURCE.subTitle)}
      >
        <CreatePlanFlowDataSourceStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    DATA_FILTERING: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.DATA_FILTERING.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.DATA_FILTERING.subTitle, { dealType: pluralize(planInDraft.trackingObject?.type || '') })}
      >
        <CreatePlanFlowDataFilteringStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    QUOTA_SETUP: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.subTitle)}
      >
        <CreatePlanFlowQuotaSetupStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    QUOTA_ATTAINMENT: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.QUOTA_ATTAINMENT.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.QUOTA_ATTAINMENT.subTitle, { dealType: pluralize.singular(planInDraft.trackingObject?.type || '') })}
      >
        <CreatePlanFlowQuotaAttainmentStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    COMMISSION_RULES: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.COMMISSION_RULES.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.COMMISSION_RULES.subTitle)}
      >
        <CreatePlanFlowCommissionRulesStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    PAYOUT_SCHEDULE: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.PAYOUT_SCHEDULE.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.PAYOUT_SCHEDULE.subTitle)}
      >
        <CreatePlanFlowPayoutScheduleStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
    SUMMARY: (planId = null, planInDraft = null) => (
      <CreatePlanFlowStepWrapper
        title={t(CREATE_PLAN_FLOW_STEPS.SUMMARY.label)}
        subTitle={t(CREATE_PLAN_FLOW_STEPS.SUMMARY.subTitle)}
      >
        <CreatePlanFlowSummaryStep planId={planId} plan={planInDraft} />
      </CreatePlanFlowStepWrapper>
    ),
  }), []);

  const [creationPlanStep] = useDraftMasterPlanStepInSearch();

  const planId = useMemo(() => (plan?.id || null), [plan]);

  const stepNode = useMemo(() => {
    const firstStepNode = createPlanFlowStepsNodes[CREATE_PLAN_FLOW_FIRST_STEP.value]();

    if (planId === null || plan === null) return firstStepNode;

    switch (creationPlanStep) {
      case CREATE_PLAN_FLOW_STEPS.STEP_GENERAL.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.STEP_GENERAL.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.SELECT_USERS.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.SELECT_USERS.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.DATA_SOURCE.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.DATA_SOURCE.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.DATA_FILTERING.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.DATA_FILTERING.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.QUOTA_ATTAINMENT.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.QUOTA_ATTAINMENT.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.COMMISSION_RULES.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.COMMISSION_RULES.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.PAYOUT_SCHEDULE.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.PAYOUT_SCHEDULE.value](planId, plan);
      }
      case CREATE_PLAN_FLOW_STEPS.SUMMARY.value: {
        return createPlanFlowStepsNodes[CREATE_PLAN_FLOW_STEPS.SUMMARY.value](planId, plan);
      }
      default:
        return null;
    }
  }, [planId, plan, creationPlanStep, createPlanFlowStepsNodes]);

  return stepNode;
};

CreatePlanFlowPageStepsManagement.propTypes = {
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowPageStepsManagement.defaultProps = {
  plan: null,
};

export default CreatePlanFlowPageStepsManagement;
