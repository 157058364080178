import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as StatementCorrectionApplyManuallyStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectionApplyManuallyStrategy';

const classNames = bindClassNames.bind();

const StatementCorrectionApplyManuallyContentStrategy = ({ className, details, i18nAction }) => {
  const { t } = useTranslation();
  const { approver, amount, currency } = details;

  const translation = t(`statement.history.list.content.${i18nAction}`, {
    user: approver.displayName,
    amount: formatPrice(amount, currency),
  });

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: translation }}
    />
  );
};

StatementCorrectionApplyManuallyContentStrategy.propTypes = {
  className: PropTypes.string,
  details: StatementCorrectionApplyManuallyStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

StatementCorrectionApplyManuallyContentStrategy.defaultProps = {
  className: '',
};

export default StatementCorrectionApplyManuallyContentStrategy;
