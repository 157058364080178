import {
  all,
  call,
  put,
  take,
  takeLatest,
  select,
} from 'redux-saga/effects';

import { DEFAULT_PAGE_QS_VALUE, DEFAULT_LIMIT_QS_VALUE } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';
import { INITIAL_STRINGIFIED_QUERY_BUILDER_VALUE } from '@palette/constants/queryBuilder';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import * as ConnectorsService from '@palette/services/ConnectorsService';

import * as ConnectorModel from '@palette/models/Connector';
import * as PaginationModel from '@palette/models/Pagination';
import * as ResourceObjectModel from '@palette/models/ResourceObject';
import * as FieldDefinitionModel from '@palette/models/FieldDefinition';
import * as ResourceConfigurationModel from '@palette/models/ResourceConfiguration';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors/slice';
import { manageError as manageConnectorsError } from '@palette/state/Connectors/errors';
import { selectors as ProfileSelectors } from '@palette/state/Profile';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

export function* getList() {
  const callResult = yield call(ConnectorsService.getList);

  if (callResult.ok) {
    const connectors = ConnectorModel.transformList(callResult.data);
    yield put(ConnectorsActions.setList({ connectors }));
  } else {
    const error = manageConnectorsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ConnectorsActions.getListCompleted());
}

export function* getListForIC() {
  const callResult = yield call(ConnectorsService.getListForIC);

  if (callResult.ok) {
    const connectors = ConnectorModel.transformList(callResult.data);
    yield put(ConnectorsActions.setList({ connectors }));
  } else {
    const error = manageConnectorsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ConnectorsActions.getListCompleted());
}

export function* getResourceObjects({ payload = {} }) {
  const {
    connectorId,
    type,
    quickSearch = '',
    filter = INITIAL_STRINGIFIED_QUERY_BUILDER_VALUE,
    maxCount = null,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    isPaginated = false,
  } = payload;

  let results = [];
  let callResult = null;
  let count = maxCount || Number.MAX_SAFE_INTEGER;
  let currentPage = isPaginated ? page : 0;
  const currentLimit = isPaginated ? limit : (maxCount || 100);
  const pagination = {};

  while (callResult === null || (results.length < count && !isPaginated)) {
    callResult = yield call(ConnectorsService.listResourceObjects, {
      connectorId,
      type,
      quickSearch,
      filter,
      page: currentPage,
      limit: currentLimit,
    });

    if (callResult.ok) {
      results = results.concat(ResourceObjectModel.transformList(callResult.data.objects));
      count = maxCount ? Math.min(maxCount, callResult.data.count) : callResult.data.count;
      if (isPaginated) {
        pagination.total = callResult.data.count;
        pagination.limit = currentLimit;
        pagination.page = currentPage;
      }
      currentPage += 1;
    } else {
      const error = manageConnectorsError(callResult);
      yield put(GlobalNotifActions.addGlobalNotif(error));
      return;
    }
  }

  const paginationTransformed = PaginationModel.transform(pagination);

  yield put(ConnectorsActions.setResourceObjects({
    resourceObjects: results,
    connectorId,
    type,
    pagination: paginationTransformed,
    override: isPaginated,
  }));

  yield put(ConnectorsActions.getResourceObjectsCompleted());
}

export function* getAllUsersResourcesObjects() {
  const connectorsList = yield select(ConnectorsSelectors.getConnectorsWithUsersResources);
  const allCalls = [];

  connectorsList.forEach((connector) => {
    connector.resources.forEach((resource) => {
      allCalls.push(call(getResourceObjects, { payload: { connectorId: connector.id, type: resource.type } }));
    });
  });

  yield all(allCalls);

  yield put(ConnectorsActions.getAllUsersResourcesObjectsCompleted());
}

export function* resetLastSyncDates({ payload = {} }) {
  const {
    connectorId,
    resourceTypes,
    lastSyncDate,
    onSuccessCB = null,
  } = payload;

  const callData = {
    connectorId,
    resourceTypes,
    lastSyncDate,
  };

  const callResult = yield call(ConnectorsService.resetLastSyncDates, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageConnectorsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ConnectorsActions.resetLastSyncDatesCompleted());
}

export function* getResourceFields({ payload = {} }) {
  const {
    connectorId,
    resourceType,
  } = payload;

  const callData = {
    connectorId,
    resourceType,
  };

  const callResult = yield call(ConnectorsService.getResourceFields, callData);

  if (callResult.ok) {
    const fields = FieldDefinitionModel.transformList(callResult.data);
    yield put(ConnectorsActions.setConnectorResourceFields({ connectorId, resourceType, fields }));
  } else {
    const error = manageConnectorsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ConnectorsActions.getResourceFieldsCompleted());
}

export function* refreshList() {
  yield put(ConnectorsActions.setRefreshListIsNeeded({ isNeeded: false }));

  const profile = yield select(ProfileSelectors.profile);

  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.CONNECTORS.VIEW])) {
    yield put(ConnectorsActions.getList());
  } else if (hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) {
    yield put(ConnectorsActions.getListForIC());
  }
}

export function* getResourceConfigurationsFields({ payload = {} }) {
  const { connectorId } = payload;

  const callData = { connectorId };

  const callResult = yield call(ConnectorsService.getResourceConfigurationsFields, callData);

  if (callResult.ok) {
    const resourceConfigurationsList = ResourceConfigurationModel.transformList(callResult.data);
    yield put(ConnectorsActions.setResourceConfigurationsFields({ connectorId, resourceConfigurationsList }));
  } else {
    const error = manageConnectorsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ConnectorsActions.getResourceConfigurationsFieldsCompleted());
}

export function* getAvailableConfigurationsFields({ payload = {} }) {
  const { connectorId, resourceType } = payload;

  const callData = { connectorId, resourceType };

  const callResult = yield call(ConnectorsService.getAvailableConfigurationsFields, callData);

  if (callResult.ok) {
    /**
     * Because of v1 code, we need to get the columns fields up to date.
     * Can be removed after full v2 completed.
     */
    yield put(ConnectorsActions.getList());
    yield take(ConnectorsActions.getListCompleted.type);

    const availableFieldsList = FieldDefinitionModel.transformList(callResult.data);
    yield put(ConnectorsActions.setAvailableConfigurationsFields({ connectorId, resourceType, availableFieldsList }));
  } else {
    const error = manageConnectorsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ConnectorsActions.getAvailableConfigurationsFieldsCompleted());
}

export function* upsertResourceConfigurations({ payload = {} }) {
  const { connectorId, resourceType, fields, onSuccessCB = null } = payload;

  const callData = { connectorId, resourceType, fields };

  const callResult = yield call(ConnectorsService.upsertResourceConfigurations, callData);

  if (callResult.ok) {
    yield put(ConnectorsActions.getResourceConfigurationsFields({ connectorId }));

    yield take(ConnectorsActions.getResourceConfigurationsFieldsCompleted.type);

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageConnectorsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ConnectorsActions.upsertResourceConfigurationsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(ConnectorsActions.getList.type, getList),
    takeLatest(ConnectorsActions.getListForIC.type, getListForIC),
    takeLatest(ConnectorsActions.getResourceObjects.type, getResourceObjects),
    takeLatest(ConnectorsActions.getAllUsersResourcesObjects.type, getAllUsersResourcesObjects),
    takeLatest(ConnectorsActions.resetLastSyncDates.type, resetLastSyncDates),
    takeLatest(ConnectorsActions.getResourceFields.type, getResourceFields),
    takeLatest(ConnectorsActions.refreshList.type, refreshList),
    takeLatest(ConnectorsActions.getResourceConfigurationsFields.type, getResourceConfigurationsFields),
    takeLatest(ConnectorsActions.getAvailableConfigurationsFields.type, getAvailableConfigurationsFields),
    takeLatest(ConnectorsActions.upsertResourceConfigurations.type, upsertResourceConfigurations),
  ]);
}

export function* clean() {
  yield put(ConnectorsActions.resetToInitialState());
}
