import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import PlanV3FormRequirements from '@palette/components/planV3/planV3FormItems/PlanV3FormRequirements/PlanV3FormRequirements';

import styles from './PlanV3CreatePageContent.less';

const classNames = bindClassNames.bind(styles);

const PlanV3CreatePageContent = ({ className }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <PlanV3FormRequirements />
  </div>
);

PlanV3CreatePageContent.propTypes = {
  className: PropTypes.string,
};

PlanV3CreatePageContent.defaultProps = {
  className: '',
};

export default PlanV3CreatePageContent;
