import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';
import _sortBy from 'lodash/sortBy';

import AddDataConnectionDataSelectorBlock from '@palette/components/notebooks/AddDataConnectionDataSelectorBlock/AddDataConnectionDataSelectorBlock';
import DataConnectionConnectorPreview from '@palette/components/notebooks/DataConnectionConnectorPreview/DataConnectionConnectorPreview';

import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES } from '@palette/constants/notebooks';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import * as NotebookDataSelectorBlockListItemModel from '@palette/models/NotebookDataSelectorBlockListItem';
import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './AddDataConnectionSelectDataConnectorStep.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionSelectDataConnectorStep = ({ className, editedDataConnectionConnector }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const connectorsList = useConnectorsList();

  const newDataConnectionSource = useSelector(NotebooksSelectors.getNewDataConnectionSource);

  const selectedConnector = useMemo(() => {
    const connectorId = newDataConnectionSource?.params?.connectorId ?? editedDataConnectionConnector?.connectorId;

    if (!connectorId) return null;

    return connectorsList.find((connector) => connector.id === connectorId) ?? null;
  }, [newDataConnectionSource, editedDataConnectionConnector]);

  const [selectedResourceType, setSelectedResourceType] = useState(editedDataConnectionConnector?.originalType ?? null);

  const selectedResource = useMemo(() => {
    if (selectedConnector === null || selectedResourceType === null) return null;

    const connectorResource = selectedConnector?.resources.find((resource) => (resource.originalType === selectedResourceType));

    if (!connectorResource) return null;

    let resourceLabel = pluralize(connectorResource.type);
    if (connectorResource.name !== '') {
      resourceLabel = `${resourceLabel} (${connectorResource.name})`;
    }

    return {
      ...connectorResource,
      resourceLabel,
    };
  }, [selectedConnector, selectedResourceType]);

  const selectedResourceTypeFields = useSelector(
    (state) => ConnectorsSelectors.getResourceFieldsByConnectorAndType(state, { connectorId: selectedConnector?.id, resourceType: selectedResourceType }),
  );

  const getResourceFieldsIsPending = useSelector(ConnectorsSelectors.getResourceFieldsIsPending);

  useEffect(() => {
    if (selectedConnector !== null && selectedResourceType !== null) {
      dispatch(ConnectorsActions.getResourceFields({ connectorId: selectedConnector?.id, resourceType: selectedResourceType }));
    }
  }, [selectedResourceType]);

  const [selectedFields, setSelectedFields] = useState(editedDataConnectionConnector?.columns.map((column) => (column.name)) ?? []);

  const handleSelectResourceType = useCallback((newResourcetype) => {
    setSelectedResourceType(newResourcetype);
    setSelectedFields([]);
  }, []);

  useEffect(() => {
    dispatch(NotebooksActions.setNewDataConnectionData({
      connectorId: selectedConnector?.id,
      connectorName: selectedConnector?.name,
      resourceType: selectedResourceType,
      selectedFields,
    }));
  }, [
    selectedConnector,
    selectedResourceType,
    selectedFields,
  ]);

  const resourceTypeSelectorBlockNode = useMemo(() => {
    if (selectedConnector === null) return null;

    const resourcesItems = selectedConnector?.resources.map((resource) => {
      let resourceLabel = pluralize(resource.type);
      if (resource.name !== '') {
        resourceLabel = `${resourceLabel} (${resource.name})`;
      }

      return NotebookDataSelectorBlockListItemModel.transform({
        key: resource.originalType,
        label: resourceLabel,
        type: NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.RESOURCE,
      });
    });

    return (
      <AddDataConnectionDataSelectorBlock
        className={styles.selectorBlock}
        title={selectedConnector?.name}
        subtitle={t('addDataConnectionSelectDataConnectorStep.availableResources', { count: selectedConnector?.resources.length })}
        items={resourcesItems}
        openedItemKey={selectedResourceType}
        onOpenItem={handleSelectResourceType}
        disabled={editedDataConnectionConnector !== null}
      />
    );
  }, [
    selectedConnector,
    selectedResourceType,
    handleSelectResourceType,
    editedDataConnectionConnector,
  ]);

  const dataSelectorBlocksNode = useMemo(() => {
    if (selectedConnector === null || selectedResource === null) return null;

    const blocks = [];

    let resourcesItems = selectedResourceTypeFields.map((field) => (
      NotebookDataSelectorBlockListItemModel.transform({
        key: field.name,
        label: field.name,
        type: field.dataSelectorBlockListItemType,
      })
    ));
    resourcesItems = _sortBy(resourcesItems, (resourcesItem) => (!selectedFields.includes(resourcesItem.label)));

    const block = (
      <AddDataConnectionDataSelectorBlock
        key="fields_deep_0"
        className={styles.selectorBlock}
        title={selectedResource.resourceLabel}
        items={resourcesItems}
        selectedItemsKeys={selectedFields}
        onUpdateSelectedItems={setSelectedFields}
        loading={getResourceFieldsIsPending}
      />
    );

    blocks.push(block);

    return blocks;
  }, [
    selectedConnector,
    selectedResource,
    selectedResourceTypeFields,
    selectedFields,
    getResourceFieldsIsPending,
  ]);

  const previewNode = useMemo(() => {
    if (
      selectedConnector === null
      || selectedResource === null
      || selectedFields.length === 0
    ) return null;

    return (
      <DataConnectionConnectorPreview
        className={styles.preview}
        connector={selectedConnector}
        resource={selectedResource}
        fields={selectedFields}
      />
    );
  }, [
    selectedConnector,
    selectedResource,
    selectedFields,
  ]);

  if (selectedConnector === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.selectorBlocks}>
        {resourceTypeSelectorBlockNode}
        {dataSelectorBlocksNode}
      </div>
      {previewNode}
    </div>
  );
};

AddDataConnectionSelectDataConnectorStep.propTypes = {
  className: PropTypes.string,
  editedDataConnectionConnector: NotebookDataConnectionConnectorModel.propTypes,
};

AddDataConnectionSelectDataConnectorStep.defaultProps = {
  className: '',
  editedDataConnectionConnector: null,
};

export default AddDataConnectionSelectDataConnectorStep;
