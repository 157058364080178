import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _difference from 'lodash/difference';
import _cloneDeep from 'lodash/cloneDeep';

import InputTable from '@palette/components/designSystem/InputTable/InputTable';
import PlanV3InputTable from '@palette/components/planV3/PlanV3InputTable/PlanV3InputTable';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { INPUT_TABLE_CELL_TYPES } from '@palette/constants/global';
import { INPUT_TABLE_TYPES } from '@palette/constants/planV3';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import * as PlanV3ListUserModel from '@palette/models/PlanV3ListUser';

import styles from './PlanV3FormRequirementsUsersInputTable.less';

const classNames = bindClassNames.bind(styles);

const USER_INPUT_TABLE_VARIABLE_NAME = 'user_table';

const PlanV3FormRequirementsUsersInputTable = ({ className, planId, selectedUsers, setAfterPlanCreationCB }) => {
  const dispatch = useDispatch();

  const inputTablesListIsPending = useSelector(PlanV3Selectors.getInputTablesListIsPending);
  const createInputTableIsPending = useSelector(PlanV3Selectors.getCreateInputTableIsPending);
  const inputTablesList = useSelector(PlanV3Selectors.getInputTablesList);

  const getInitialColumns = useCallback(() => {
    const columns = Array.from({ length: 3 }, (_, i) => {
      const columnName = `column${i + 1}`;
      return {
        label: columnName,
        position: i + 1,
        type: INPUT_TABLE_CELL_TYPES.STRING,
      };
    });

    columns.unshift({
      label: 'user',
      position: 0,
      type: INPUT_TABLE_CELL_TYPES.STRING,
    });

    return columns;
  }, []);

  const [inCreationTableColumns, setInCreationTableColumns] = useState(getInitialColumns());

  const getEmptyData = useCallback(() => inCreationTableColumns.reduce((res, column) => {
    res[column.label] = '';
    return res;
  }, {}), [inCreationTableColumns]);

  const getInitialData = useCallback(() => selectedUsers.map((user) => ({
    ...getEmptyData(),
    user: user.displayName,
  })), [selectedUsers]);

  const [inCreationTableData, setInCreationTableData] = useState(getInitialData());

  const handleInputTableRowsCreation = useCallback((newPlanId, newInputTableId) => {
    const inputTableRows = inCreationTableData.map((dataItem, index) => ({
      position: index,
      values: Object.entries(dataItem).map(([columnId, value]) => ({
        columnId,
        value,
      })),
    }));

    dispatch(PlanV3Actions.addMultipleRowsToInputTable({ planId: newPlanId, inputTableId: newInputTableId, rows: inputTableRows }));
  }, [inCreationTableData]);

  const handleInputTableCreationAfterPlanCreation = useCallback((newPlanId) => {
    dispatch(PlanV3Actions.createInputTable({
      planId: newPlanId,
      type: INPUT_TABLE_TYPES.USER,
      name: USER_INPUT_TABLE_VARIABLE_NAME,
      columns: inCreationTableColumns,
      onSuccessCB: handleInputTableRowsCreation,
      refreshInputTable: false,
    }));
  }, [inCreationTableColumns, handleInputTableRowsCreation]);

  useEffect(() => {
    if (planId === null && setAfterPlanCreationCB !== null) {
      setAfterPlanCreationCB(handleInputTableCreationAfterPlanCreation);
    }
  }, [setAfterPlanCreationCB]);

  useEffect(() => {
    if (!inputTablesListIsPending && planId !== null) {
      dispatch(PlanV3Actions.getInputTablesList({ planId }));
    }
  }, [planId]);

  const userInputTable = useMemo(() => {
    if (inputTablesList === null) return null;

    return inputTablesList.find((inputTable) => inputTable.type === INPUT_TABLE_TYPES.USER);
  }, [inputTablesList]);

  useEffect(() => {
    if (planId !== null && !userInputTable && !inputTablesListIsPending && inputTablesList !== null) {
      const columns = getInitialColumns();

      dispatch(PlanV3Actions.createInputTable({ planId, type: INPUT_TABLE_TYPES.USER, name: USER_INPUT_TABLE_VARIABLE_NAME, columns }));
    }
  }, [userInputTable, inputTablesListIsPending]);

  useEffect(() => {
    if (planId !== null && userInputTable != null && userInputTable.rows.length === 0) {
      const userRows = selectedUsers.map((user, index) => ({
        position: index,
        values: userInputTable.columns.map((column) => ({
          columnId: column.id,
          value: column.label === 'user' ? user.displayName : '',
        })),
      }));

      dispatch(PlanV3Actions.addMultipleRowsToInputTable({ planId, inputTableId: userInputTable.id, rows: userRows }));
    }
  }, [userInputTable]);

  const alignSavedAndSelectedUsers = useCallback(() => {
    const userColumnId = userInputTable.columns.find((column) => column.label === 'user').id;
    const savedUsersNames = userInputTable.rows.map((row) => row.values.find((rowValue) => rowValue.columnId === userColumnId).value);
    const selectedUsersNames = selectedUsers.map((user) => user.displayName);

    const toRemoveUsers = _difference(savedUsersNames, selectedUsersNames);
    const toAddUsers = _difference(selectedUsersNames, savedUsersNames);

    if (toRemoveUsers.length > 0) {
      const toDeleteRowsPositions = userInputTable.rows.filter((row) => {
        const userDisplayName = row.values.filter((rowValue) => rowValue.columnId === 'user').value;
        return toRemoveUsers.includes(userDisplayName);
      }).map((row) => row.position);

      dispatch(PlanV3Actions.deleteMultipleRowsFromInputTable({ planId, inputTableId: userInputTable.id, rowsPositions: toDeleteRowsPositions, refreshInputTable: toAddUsers.length === 0 }));
    }

    if (toAddUsers.length > 0) {
      const userRows = toAddUsers.map((userDisplayName, index) => ({
        position: userInputTable.rows.length + index,
        values: userInputTable.columns.map((column) => ({
          columnId: column.id,
          value: column.label === 'user' ? userDisplayName : '',
        })),
      }));

      dispatch(PlanV3Actions.addMultipleRowsToInputTable({ planId, inputTableId: userInputTable.id, rows: userRows }));
    }
  }, [
    userInputTable,
    selectedUsers,
    planId,
  ]);

  const alignInternalRowsAndSelectedUsers = useCallback(() => {
    const savedUsersNames = inCreationTableData.map((dataItem) => dataItem.user);
    const selectedUsersNames = selectedUsers.map((user) => user.displayName);

    const toRemoveUsers = _difference(savedUsersNames, selectedUsersNames);
    const toAddUsers = _difference(selectedUsersNames, savedUsersNames);

    let newInCreationTableData = _cloneDeep(inCreationTableData);

    if (toRemoveUsers.length > 0) {
      newInCreationTableData = newInCreationTableData.filter((dataItem) => (!toRemoveUsers.includes(dataItem.user)), []);
    }

    if (toAddUsers.length > 0) {
      toAddUsers.forEach((userDisplayName) => {
        newInCreationTableData.push({
          ...getEmptyData(),
          user: userDisplayName,
        });
      });
    }

    setInCreationTableData(newInCreationTableData);
  }, [
    inCreationTableData,
    getEmptyData,
    selectedUsers,
  ]);

  const [usersAlignmentFromInputTableDone, setUsersAlignmentFromInputTableDone] = useState(false);

  useEffect(() => {
    if (planId !== null && userInputTable != null && userInputTable.rows.length !== 0) {
      alignSavedAndSelectedUsers();
    }

    if (userInputTable === null && setAfterPlanCreationCB !== null && inCreationTableData.length !== selectedUsers.length) {
      alignInternalRowsAndSelectedUsers();
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (planId !== null && userInputTable != null && userInputTable.rows.length !== 0 && !usersAlignmentFromInputTableDone) {
      alignSavedAndSelectedUsers();
      setUsersAlignmentFromInputTableDone(true);
    }
  }, [userInputTable]);

  const handleInCreationTableDataUpdate = useCallback((newData) => {
    setInCreationTableData(newData);
  }, []);

  const handleInCreationTableColumnsUpdate = useCallback((newColumns) => {
    const updatedColumns = newColumns.map((tableColumn, i) => ({
      label: tableColumn.Header !== '' ? tableColumn.Header : tableColumn.id,
      position: i + 1,
      type: tableColumn.cellType,
    }));

    setInCreationTableColumns(updatedColumns);
  }, []);

  const tableColumns = useMemo(() => {
    if (setAfterPlanCreationCB !== null && !userInputTable) {
      return inCreationTableColumns.map((column) => ({
        id: column.label,
        Header: column.label !== 'user' ? column.label : '',
        accessor: column.label,
        minWidth: 150,
        cellType: column.type,
      }));
    }

    if (!userInputTable) return [];

    return userInputTable.columns.map((column) => ({
      id: column.id,
      Header: column.label !== 'user' ? column.label : '',
      accessor: column.id,
      minWidth: 150,
      cellType: column.type,
    }));
  }, [userInputTable, setAfterPlanCreationCB, inCreationTableColumns]);

  const tableData = useMemo(() => {
    if (setAfterPlanCreationCB !== null && !userInputTable) {
      return inCreationTableData;
    }

    if (!userInputTable) return [];

    return userInputTable.rows.map((row) => row.values.reduce((res, rowValue) => {
      res[rowValue.columnId] = rowValue.value;

      return res;
    }, {}));
  }, [userInputTable, setAfterPlanCreationCB, inCreationTableData]);

  const tableNode = useMemo(() => {
    if (userInputTable === null && setAfterPlanCreationCB !== null) {
      return (
        <InputTable
          columns={tableColumns}
          data={tableData}
          stickyHeader
          fitInContainer
          nbOfFixedColumns={1}
          firstColumnIsRowIndexColumn
          disableAutoAddRows
          onDataUpdate={handleInCreationTableDataUpdate}
          onColumnsUpdate={handleInCreationTableColumnsUpdate}
        />
      );
    }

    if (userInputTable == null || createInputTableIsPending) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <PlanV3InputTable
        columns={tableColumns}
        data={tableData}
        stickyHeader
        fitInContainer
        nbOfFixedColumns={1}
        firstColumnIsRowIndexColumn
        planId={planId}
        inputTable={userInputTable}
        disableAutoAddRows
      />
    );
  }, [
    inputTablesListIsPending,
    createInputTableIsPending,
    tableColumns,
    tableData,
    planId,
    setAfterPlanCreationCB,
    handleInCreationTableDataUpdate,
    handleInCreationTableColumnsUpdate,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {tableNode}
    </div>
  );
};

PlanV3FormRequirementsUsersInputTable.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string,
  selectedUsers: PropTypes.arrayOf(PlanV3ListUserModel.propTypes).isRequired,
  setAfterPlanCreationCB: PropTypes.func,
};

PlanV3FormRequirementsUsersInputTable.defaultProps = {
  className: '',
  planId: null,
  setAfterPlanCreationCB: null,
};

export default PlanV3FormRequirementsUsersInputTable;
