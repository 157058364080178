import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import DashboardPresetPreview from '@palette/components/dashboard/DashboardPresetPreview/DashboardPresetPreview';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserDetailsDashboardPreviewTab.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsDashboardPreviewTab = ({ className, user }) => {
  const dispatch = useDispatch();

  const getPresetDataForCandidateUserIsPending = useSelector((state) => DashboardPresetsSelectors.getPresetDataForCandidateUserIsPending(state));
  const presetData = useSelector(DashboardPresetsSelectors.getPresetDataForCandidateUser);

  useEffect(() => {
    dispatch(DashboardPresetsActions.getPresetDataForCandidateUser({ candidateUserId: user.id }));
  }, [user]);

  const previewContentNode = useMemo(() => {
    if (getPresetDataForCandidateUserIsPending) {
      return (
        <Loader />
      );
    }

    return (
      <DashboardPresetPreview user={user} data={presetData} />
    );
  }, [getPresetDataForCandidateUserIsPending, user, presetData]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {previewContentNode}
    </div>
  );
};

UserDetailsDashboardPreviewTab.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsDashboardPreviewTab.defaultProps = {
  className: '',
};

export default UserDetailsDashboardPreviewTab;
