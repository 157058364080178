import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Link from '@palette/components/designSystem/Link/Link';
import EyesLine from '@palette/components/utils/Icons/EyesLine';

import { getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';

import {
  SEARCHED_FROM_QS_KEY,
  SEARCHED_PLAN_QS_KEY,
  SEARCHED_STATUS_QS_KEY,
  SEARCHED_TO_QS_KEY,
  STATUSES_OPTIONS_KEYS,
} from '@palette/constants/commissions';
import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';

import routePaths from '@palette/config/routePaths';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './CommissionsWithoutPaymentLink.less';

const classNames = bindClassNames.bind(styles);

const CommissionsWithoutPaymentLink = ({ className, plan, period, forIC }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const iconNode = (
    <EyesLine className={styles.icon} width={12} height={12} />
  );

  const from = getMomentDateFromPeriod(plan.periodType, plan.frequency, period, plan.customBeginAt, false, plan.fiscalYearShift).format('YYYY-MM-DD');
  const to = getMomentDateFromPeriod(plan.periodType, plan.frequency, period, plan.customBeginAt, true, plan.fiscalYearShift).format('YYYY-MM-DD');

  const qsObject = {
    [SEARCHED_PLAN_QS_KEY]: plan.id,
    [SEARCHED_FROM_QS_KEY]: from,
    [SEARCHED_TO_QS_KEY]: to,
    [SEARCHED_STATUS_QS_KEY]: STATUSES_OPTIONS_KEYS.PARTIALLY_RELEASED_AND_ON_HOLD.toLowerCase(),
  };

  let linkPath = routePaths.v2.commissionList;
  if (forIC) {
    linkPath = routePaths.v2.myCommissionList;
  }

  const handleClick = () => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_COMMISSIONS_WITHOUT_PAYMENT_LINK }));
  };

  return (
    <Link
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      path={linkPath}
      qsObject={qsObject}
      displayIcon
      iconNode={iconNode}
      iconIsAfter={false}
      onClick={handleClick}
    >
      {t('commissionsWithoutPaymentLink.label')}
    </Link>
  );
};

CommissionsWithoutPaymentLink.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  forIC: PropTypes.bool,
};

CommissionsWithoutPaymentLink.defaultProps = {
  className: '',
  forIC: false,
};

export default CommissionsWithoutPaymentLink;
