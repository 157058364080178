import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import QuotaInfos from '@palette/components/quota/QuotaInfos/QuotaInfos';

import { getSearch, redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as QuotaModel from '@palette/models/Quota';

import { selectors as NavigationSelectors } from '@palette/state/Navigation';

import styles from './QuotaListItem.less';

const classNames = bindClassNames.bind(styles);

const QuotaListItem = ({ className, quota }) => {
  const { quotaId } = useParams();

  const location = useSelector(NavigationSelectors.getCurrentLocation);

  const handleClick = useCallback(() => {
    const qsObject = getSearch(location);

    redirectTo({
      path: routePaths.v2.quotaDetails,
      params: {
        quotaId: quota.id,
      },
      qsObject,
    });
  }, [location]);

  return (
    <div
      className={classNames({
        wrapper: true,
        selected: quotaId === quota.id,
        [className]: className !== '',
      })}
      onClick={handleClick}
    >
      <div className={styles.name}>
        {quota.name}
      </div>
      <QuotaInfos className={styles.infos} quota={quota} />
    </div>
  );
};

QuotaListItem.propTypes = {
  className: PropTypes.string,
  quota: QuotaModel.propTypes.isRequired,
};

QuotaListItem.defaultProps = {
  className: '',
};

export default QuotaListItem;
