import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import StatementV3UserStatementAdjustmentsItem from '@palette/components/statementV3/StatementV3UserStatementAdjustmentsItem/StatementV3UserStatementAdjustmentsItem';
import StatementV3AddEditAdjustmentModal from '@palette/components/statementV3/StatementV3AddEditAdjustmentModal/StatementV3AddEditAdjustmentModal';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { STATEMENT_CORRECTION_TYPES } from '@palette/constants/StatementV3';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { isUserStatementPaid } from '@palette/helpers/StatementV3Helper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';

import styles from './StatementV3UserStatementCorrectionsApplied.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementCorrectionsApplied = ({ className, statement, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profile = useProfile();

  const [addAdjustmentModalIsVisible, showAddAdjustmentModal] = useState(false);

  const listCorrections = useSelector(StatementV3Selectors.getListCorrections);
  const listCorrectionsIsPending = useSelector(StatementV3Selectors.getListCorrectionsIsPending);

  useEffect(() => {
    dispatch(StatementV3Actions.getListCorrections({ statementId: statement.id, currency }));
  }, [currency, statement]);

  const isStatementPaid = useMemo(() => isUserStatementPaid(statement), [statement]);

  const handleAddAnAdjustmentClick = useCallback((event) => {
    event.stopPropagation();
    if (!isStatementPaid) showAddAdjustmentModal(true);
  }, [isStatementPaid]);

  const contentNode = useMemo(() => {
    if (!listCorrections && !listCorrectionsIsPending) return null;

    if (listCorrectionsIsPending) return <Loader />;

    const appliedCorrections = listCorrections.filter((correction) => correction.status === STATEMENT_CORRECTION_TYPES.ACCEPTED);

    const count = appliedCorrections.length;

    const title = (
      <div className={styles.title}>
        {t('userStatementAdjustments.collapse.title', { count })}
      </div>
    );

    let content;

    if (count === 0) {
      content = <DefaultEmptyState />;
    } else {
      content = [(
        <div className={styles.adjustmentsListHeader} key="adjustements-list-header">
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.reasonLabel')}</span>
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.amountLabel')}</span>
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.whenLabel')}</span>
        </div>
      )];

      content.push(appliedCorrections.map((correction) => (
        <StatementV3UserStatementAdjustmentsItem
          key={correction.id}
          statement={statement}
          correction={correction}
          currency={currency}
        />
      )));
    }

    const addAdjustmentButton = (
      <Button
        className={styles.addAdjustmentButton}
        type={isStatementPaid ? 'linkSecondary' : 'link'}
        icon={<AddFilled width={18} height={18} />}
        onClick={handleAddAnAdjustmentClick}
      >
        {t('userStatementAdjustments.collapse.addAdjustmentButtonLabel')}
      </Button>
    );

    let actionsNode = null;
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.ADJUSTMENTS.MANAGE])) {
      actionsNode = (
        <div className={styles.actions}>
          {isStatementPaid && (
            <Tooltip title={t('userStatementAdjustments.collapse.alreadyPaid')}>
              {addAdjustmentButton}
            </Tooltip>
          )}
          {!isStatementPaid && addAdjustmentButton}
        </div>
      );
    }

    const extra = (
      <div className={styles.extraDetails}>
        <div className={styles.colRow}>
          {actionsNode}
        </div>
      </div>
    );

    return (
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title,
          content,
          extra,
        }]}
        keyValue={statement.id}
        defaultActiveKey={count > 0 ? statement.id : undefined}
      />
    );
  }, [profile, statement, isStatementPaid, listCorrections, listCorrectionsIsPending, currency, handleAddAnAdjustmentClick]);

  if (!statement) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      {addAdjustmentModalIsVisible && !isStatementPaid && (
        <StatementV3AddEditAdjustmentModal
          visible
          onClose={() => showAddAdjustmentModal(false)}
          statement={statement}
          currency={currency}
        />
      )}
    </div>
  );
};

StatementV3UserStatementCorrectionsApplied.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementV3UserStatementCorrectionsApplied.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3UserStatementCorrectionsApplied;
