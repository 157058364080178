import PropTypes from 'prop-types';

import {
  manageAmountAttribute, manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as PaymentModel from '@palette/models/Payment';

import { EVENT_CONTENT_ACTIONS, EVENT_CONTENT_STATUSES } from '@palette/constants/comments';

export const modelName = 'CommentEventContent';

export const propTypes = PropTypes.shape({
  action: PropTypes.oneOf(Object.values(EVENT_CONTENT_ACTIONS)),
  status: PropTypes.oneOf(Object.values(EVENT_CONTENT_STATUSES)),
  amount: PropTypes.number,
  value: PropTypes.number,
  payments: PropTypes.arrayOf(PaymentModel.propTypes),
});

export const manageCommentEventContentAction = (commentEventContent) => (EVENT_CONTENT_ACTIONS[manageStringAttribute(commentEventContent, 'event')] || EVENT_CONTENT_ACTIONS.STATUS_UPDATED);
export const manageCommentEventContentStatus = (commentEventContent) => (EVENT_CONTENT_STATUSES[manageStringAttribute(commentEventContent, 'status')] || EVENT_CONTENT_STATUSES.PENDING);

export const managePayments = (payments) => {
  if (payments === null) return null;
  return PaymentModel.transformList(payments);
};

export const transform = (commentEventContent) => {
  if (!commentEventContent) {
    return null;
  }

  return (
    {
      action: manageCommentEventContentAction(commentEventContent),
      status: manageCommentEventContentStatus(commentEventContent),
      amount: manageAmountAttribute(commentEventContent, 'amount', null),
      value: manageAmountAttribute(commentEventContent, 'value', null),
      payments: managePayments(manageArrayAttribute(commentEventContent, 'payments', null)),
    }
  );
};

export const transformList = (commentEventContents) => commentEventContents.map((commentEventContent) => transform(commentEventContent));
