/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';

import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _mergeWith from 'lodash/mergeWith';
import _keyBy from 'lodash/keyBy';

import * as CommissionModel from '@palette/models/Commission';

import { selectors as ThreadsSelectors } from '@palette/state/Threads';

/*
 * Initial State
 */
const initialState = {
  getByIdIsPending: false,
  overwriteAmountIsPending: false,
  overwriteValueIsPending: false,
  getActivitiesIsPending: false,
  addCommentIsPending: false,
  setStatusIsPending: false,
  updatePayoutScheduleIsPending: false,
  listDealCommissionsIsPending: false,
  listByFiltersIsPending: false,
  listByFiltersDashboardIsPending: false,
  byId: {},
  byObjectComposeId: {}, // Means "connectorId_type_objectId" string.
  listByFilters: {},
  listByFiltersPagination: {},
  listByFiltersDashboard: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'commissions',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get By Id */
    getById: (state) => {
      state.getByIdIsPending = true;
    },
    setById: (state, { payload }) => {
      const { commission } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      const inStateCommission = clonedById[commission.id] || null;

      clonedById[commission.id] = CommissionModel.merge(inStateCommission, commission);

      state.byId = clonedById;
    },
    getByIdCompleted: (state) => {
      state.getByIdIsPending = false;
    },
    /* Overwrite Commission Amount */
    overwriteAmount: (state) => {
      state.overwriteAmountIsPending = true;
    },
    overwriteAmountCompleted: (state) => {
      state.overwriteAmountIsPending = false;
    },
    /* Overwrite Commission Value */
    overwriteValue: (state) => {
      state.overwriteValueIsPending = true;
    },
    overwriteValueCompleted: (state) => {
      state.overwriteValueIsPending = false;
    },
    /* Get Activities */
    getActivities: (state) => {
      state.getActivitiesIsPending = true;
    },
    getActivitiesCompleted: (state) => {
      state.getActivitiesIsPending = false;
    },
    /* Add Comment */
    addComment: (state) => {
      state.addCommentIsPending = true;
    },
    addCommentCompleted: (state) => {
      state.addCommentIsPending = false;
    },
    /* Set Status */
    setStatus: (state) => {
      state.setStatusIsPending = true;
    },
    setStatusCompleted: (state) => {
      state.setStatusIsPending = false;
    },
    /* Update Payout Schedule */
    updatePayoutSchedule: (state) => {
      state.updatePayoutScheduleIsPending = true;
    },
    updatePayoutScheduleCompleted: (state) => {
      state.updatePayoutScheduleIsPending = false;
    },
    /* List Deal Commissions */
    listDealCommissions: (state) => {
      state.listDealCommissionsIsPending = true;
    },
    setByObjectComposeId: (state, { payload }) => {
      const { composeId, commissions } = payload;

      state.byObjectComposeId[composeId] = _map(commissions, 'id');

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(commissions, 'id'),
      );
    },
    listDealCommissionsCompleted: (state) => {
      state.listDealCommissionsIsPending = false;
    },
    /* List By Filters */
    listByFilters: (state) => {
      state.listByFiltersIsPending = true;
    },
    listICByFilters: (state) => {
      state.listByFiltersIsPending = true;
    },
    setListByFilters: (state, { payload }) => {
      const { commissions, pagination, callData } = payload;

      state.listByFilters = {
        list: _map(commissions, 'id'),
        callData,
      };
      state.listByFiltersPagination = pagination;

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(commissions, 'id'),
      );
    },
    listByFiltersCompleted: (state) => {
      state.listByFiltersIsPending = false;
    },
    /* List By Filters Dashboard */
    listByFiltersDashboard: (state) => {
      state.listByFiltersDashboardIsPending = true;
    },
    listICByFiltersDashboard: (state) => {
      state.listByFiltersDashboardIsPending = true;
    },
    setListByFiltersDashboard: (state, { payload }) => {
      const { dashboard, callData } = payload;

      state.listByFiltersDashboard = { dashboard, callData };
    },
    listByFiltersDashboardCompleted: (state) => {
      state.listByFiltersDashboardIsPending = false;
    },
    /* Download Commission List */
    downloadCommissionList: () => {
      // Nothing to do here
    },
    downloadCommissionICList: () => {
      // Nothing to do here
    },
    downloadCommissionListCompleted: () => {
      // Nothing to do here
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getById = (state) => root(state).byId;
const getByObjectComposeId = (state) => root(state).byObjectComposeId;
const getListByFilters = (state) => root(state).listByFilters;
const listByFiltersPagination = (state) => root(state).listByFiltersPagination;
const getListByFiltersDashboard = (state) => root(state).listByFiltersDashboard;

const getByIdIsPending = (state) => root(state).getByIdIsPending;
const overwriteAmountIsPending = (state) => root(state).overwriteAmountIsPending;
const overwriteValueIsPending = (state) => root(state).overwriteValueIsPending;
const getActivitiesIsPending = (state) => root(state).getActivitiesIsPending;
const addCommentIsPending = (state) => root(state).addCommentIsPending;
const setStatusIsPending = (state) => root(state).setStatusIsPending;
const updatePayoutScheduleIsPending = (state) => root(state).updatePayoutScheduleIsPending;
const listDealCommissionsIsPending = (state) => root(state).listDealCommissionsIsPending;
const listByFiltersIsPending = (state) => root(state).listByFiltersIsPending;
const listByFiltersDashboardIsPending = (state) => root(state).listByFiltersDashboardIsPending;

const getCommissionById = createSelector(
  [getById, getProps],
  (byId, { commissionId }) => byId[commissionId] || null,
);

const getThreadByCommissionId = (state, { commissionId }) => (ThreadsSelectors.getThreadByMongoCollectionAndId(state, { mongoCollection: 'Commission', mongoId: commissionId }));

const getCommissionActivitiesById = createSelector(
  [getThreadByCommissionId, getCommissionById],
  (thread, commission) => {
    if (!thread) return null;

    return {
      ...thread,
      commission,
    };
  },
);

const getCommissionsByObjectComposeId = createSelector(
  [getByObjectComposeId, getById, getProps],
  (byObjectComposeId, byId, { composeId }) => (byObjectComposeId[composeId] || []).map((commissionId) => byId[commissionId] || null).filter(Boolean),
);

const getCommissionListByFilters = createSelector(
  [getListByFilters, getById],
  (byFilters, byId) => (byFilters.list || []).map((commissionId) => byId[commissionId] || null).filter(Boolean),
);

const getCommissionListByFiltersDashboard = createSelector(
  [getListByFiltersDashboard],
  (listByFiltersDashboard) => (listByFiltersDashboard.dashboard || []),
);

export const selectors = {
  getByIdIsPending,
  overwriteAmountIsPending,
  overwriteValueIsPending,
  getActivitiesIsPending,
  addCommentIsPending,
  setStatusIsPending,
  updatePayoutScheduleIsPending,
  listDealCommissionsIsPending,
  listByFiltersIsPending,
  listByFiltersDashboardIsPending,

  listByFiltersPagination,

  getCommissionById,
  getCommissionActivitiesById,
  getCommissionsByObjectComposeId,
  getCommissionListByFilters,
  getCommissionListByFiltersDashboard,

  // Export following selectors only for saga "updateCommissionsListDataInState"
  getListByFilters,
  getListByFiltersDashboard,
};
