import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  QUOTA_PERFORMANCE_404_DASHBOARD_NOT_FOUND: {
    code: 'quotasPerformance.errors.dashboard.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  QUOTA_PERFORMANCE_FIELD_REQUIRED: {
    code: 'quotasPerformance.editableField.required.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
