import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import Alert from '@palette/components/designSystem/Alert/Alert';
import InputAmountCurrency from '@palette/components/designSystem/InputAmountCurrency/InputAmountCurrency';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DollarFilled from '@palette/components/utils/Icons/DollarFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getMoment } from '@palette/helpers/MomentHelper';

import { ALERT_TYPES } from '@palette/constants/alert';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './OffboardUserModal.less';

const classNames = bindClassNames.bind(styles);

const OffboardUserModal = ({ visible, onClose, user }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();

  const profile = useProfile();

  const [finalAdjustmentVisible, setFinalAdjustmentVisible] = useState(false);

  const offboardUserIsPending = useSelector(UsersSelectors.offboardUserIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    leaveDate: null,
    adjustmentReason: t('offboardUserModal.finalAdjustmentReason'),
    adjustmentAmount: null,
    adjustmentCurrency: profile.userData.company.currency,
  }), [profile]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    form.resetFields();
    setFormValues(initialValues);
  }, [initialValues]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setFormValues({
      ...formValues,
      ...allValues,
    });
  }, [formValues]);

  const handleCurrencyChange = useCallback((currencySelected) => {
    const newFormValues = {
      ...formValues,
      adjustmentCurrency: currencySelected,
    };

    form.setFieldsValue(newFormValues);
    setFormValues(newFormValues);
  }, [form, formValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const onSuccessCB = useCallback(() => {
    cleanAndClose();
  }, [cleanAndClose]);

  const handleFinish = useCallback(() => {
    const { leaveDate, adjustmentReason, adjustmentAmount, adjustmentCurrency } = formValues;

    const leaveDateValue = moment(leaveDate).utc().startOf('day').format();

    dispatch(UsersActions.offboardUser({
      userId: user.id,
      leaveDate: leaveDateValue,
      adjustmentReason,
      adjustmentAmount,
      adjustmentCurrency,
      onSuccessCB,
    }));
  }, [user, formValues]);

  const handleOffboardUser = () => form.submit();

  const handleAddFinalAdjustment = useCallback(() => {
    setFinalAdjustmentVisible(true);
  }, []);

  const handleRemoveFinalAdjustment = useCallback(() => {
    setFinalAdjustmentVisible(false);

    const newFormValues = {
      ...formValues,
      adjustmentReason: t('offboardUserModal.finalAdjustmentReason'),
      adjustmentAmount: null,
      adjustmentCurrency: profile.userData.company.currency,
    };
    form.setFieldsValue(newFormValues);
    setFormValues(newFormValues);
  }, [form, formValues, profile]);

  const finalAdjustmentNode = useMemo(() => {
    if (!finalAdjustmentVisible) {
      return (
        <Button
          className={styles.finalAdjustment}
          type="primaryBlue"
          icon={<DollarFilled />}
          onClick={handleAddFinalAdjustment}
          disabled={offboardUserIsPending}
        >
          {t('offboardUserModal.addFinalAdjustment')}
        </Button>
      );
    }

    return (
      <>
        <div
          className={classNames({
            finalAdjustment: true,
            sectionTitle: true,
          })}
        >
          {t('offboardUserModal.sections.finalAdjustment')}
        </div>
        <FormItem
          name="adjustmentAmount"
          label={t('userStatementAdjustments.addEditAdjustment.amount.label')}
          required
          rules={[
            { required: true, message: t('userStatementAdjustments.addEditAdjustment.amount.error.message') },
            () => ({
              validator(_, value) {
                if (value !== 0) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('userStatementAdjustments.addEditAdjustment.amount.error.message')));
              },
            }),
          ]}
        >
          <InputAmountCurrency
            placeholder="0"
            size="big"
            currency={formValues.adjustmentCurrency}
            onCurrencyChange={handleCurrencyChange}
            disabled={offboardUserIsPending}
          />
        </FormItem>
        <FormItem
          name="adjustmentReason"
          label={t('userStatementAdjustments.addEditAdjustment.reason.label')}
          required
          rules={[
            { required: true, message: t('userStatementAdjustments.addEditAdjustment.reason.error.message') },
          ]}
        >
          <Input
            placeholder={t('userStatementAdjustments.addEditAdjustment.reason.placeholder')}
            size="big"
            disabled={offboardUserIsPending}
          />
        </FormItem>
        <Button
          type="linkDestroy"
          icon={<TrashFilled />}
          onClick={handleRemoveFinalAdjustment}
          disabled={offboardUserIsPending}
        >
          {t('offboardUserModal.noFinalAdjustment')}
        </Button>
      </>
    );
  }, [
    finalAdjustmentVisible,
    handleAddFinalAdjustment,
    handleRemoveFinalAdjustment,
    formValues,
    handleCurrencyChange,
    offboardUserIsPending,
  ]);

  return (
    <Modal
      className={styles.modal}
      title={t('offboardUserModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleOffboardUser}
      okText={t('offboardUserModal.confirm')}
      loading={offboardUserIsPending}
    >
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
        onValuesChange={handleFormValuesChange}
      >
        <div className={styles.sectionTitle}>
          {t('offboardUserModal.sections.leaveDate')}
        </div>
        <FormItem
          name="leaveDate"
          label={t('offboardUserModal.form.leaveDate.label', { userDisplayName: user.displayName, companyName: profile.userData.company.name })}
          required
          rules={[
            { required: true, message: t('offboardUserModal.form.leaveDate.rules.required') },
          ]}
        >
          <DatePicker
            className={styles.leaveDatePicker}
            size="big"
            picker="date"
            disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
            disabled={offboardUserIsPending}
          />
        </FormItem>
        <Alert
          type={ALERT_TYPES.INFO}
          message={t('offboardUserModal.leaveDateDefinition.description.title')}
          description={t('offboardUserModal.leaveDateDefinition.description.content', { userDisplayName: user.displayName })}
        />
        {finalAdjustmentNode}
      </Form>
    </Modal>
  );
};

OffboardUserModal.propTypes = {
  user: MetaUserModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

OffboardUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default OffboardUserModal;
