import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import Tag from '@palette/components/designSystem/Tag/Tag';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { PALETTE_DOCUMENTATION_URL } from '@palette/constants/global';
import { ANALYTICS_DOC_SOURCES } from '@palette/constants/analytics';
import { MENU_TAGS, MENU_MODALS } from '@palette/constants/menu';
import { TAG_THEMES } from '@palette/constants/tag';

import { getQSStringified } from '@palette/helpers/MenuHelper';

import appConfig from '@palette/config/app';

import { actions as AnalyticsActions } from '@palette/state/Analytics';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './MenuItemWrapper.less';

const MenuItemWrapper = ({ menuItem, isSelected, ...otherProps }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  if (!menuItem.show(profile)) return null;

  let handleOnClick = null;

  if (!menuItem.disableOnClick && menuItem.onClick) {
    handleOnClick = () => menuItem.onClick(profile);
  }

  if (menuItem.disableOnClick && menuItem.openModal) {
    handleOnClick = () => {
      dispatch(NavigationActions.setModalVisible(menuItem.openModal));
    };

    if (menuItem.openModal === MENU_MODALS.ALGOLIA_SEARCH_MODAL
      && (appConfig.ALGOLIA_APP_ID === null
      || appConfig.ALGOLIA_API_KEY === null
      || appConfig.ALGOLIA_DOC_INDEX_NAME === null)
    ) {
      handleOnClick = () => {
        dispatch(AnalyticsActions.sendClickOnDocLink({ analyticsDocSource: ANALYTICS_DOC_SOURCES.SIDEBAR }));
        window.open(PALETTE_DOCUMENTATION_URL);
      };
    }
  }

  let contentNode = menuItem.contentComponent
    ? menuItem.contentComponent({
      name: menuItem.name,
      path: menuItem.path,
      pathQS: menuItem.pathQS,
      profile,
    })
    : t(menuItem.name);

  if (menuItem.hasTag) {
    contentNode = (
      <div className={styles.inner}>
        <div className={styles.contentNode}>
          {contentNode}
        </div>
        <Tag
          className={styles.tagContainer}
          theme={TAG_THEMES.WARNING}
          label={t(`common.global.${menuItem.hasTag}`)}
        />
      </div>
    );
  }

  if (!menuItem.disableOnClick) {
    contentNode = (
      <Link
        to={{
          pathname: menuItem.path,
          search: getQSStringified(menuItem.pathQS),
        }}
      >
        {contentNode}
      </Link>
    );
  }

  return (
    <Menu.Item
      key={menuItem.key}
      icon={menuItem.icon
        ? (
          typeof (menuItem.icon) === 'function'
            ? menuItem.icon({ profile })
            : menuItem.icon
        )
        : null}
      onClick={handleOnClick}
      {...otherProps}
    >
      {contentNode}
    </Menu.Item>
  );
};

export const MenuItemPropTypesShape = {
  key: PropTypes.string,
  path: PropTypes.string,
  pathQS: PropTypes.object,
  icon: PropTypes.any,
  name: PropTypes.string,
  show: PropTypes.func,
  onClick: PropTypes.func,
  contentComponent: PropTypes.any,
  disableOnClick: PropTypes.bool,
  hasTag: PropTypes.oneOf(Object.values(MENU_TAGS)),
};

MenuItemWrapper.propTypes = {
  menuItem: PropTypes.shape(MenuItemPropTypesShape).isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default MenuItemWrapper;
