import { useProfile } from '@palette/hooks/ProfileHooks';

import { manageAdditionalProperties } from '@palette/helpers/FormulaHelper';

export const useAdditionalProperties = (
  type,
  objectSample,
  config = {},
) => {
  const profile = useProfile();

  return manageAdditionalProperties(
    type,
    objectSample,
    { userCurrency: profile.userData.company.currency, ...config },
  );
};
