import {
  call,
  put,
  all,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import _orderBy from 'lodash/orderBy';

import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements/slice';
import { actions as MasterPlansActions } from '@palette/state/MasterPlans/slice';
import { manageError as manageStatementsError } from '@palette/state/Statements/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import apiConfig from '@palette/config/api';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as StatementModel from '@palette/models/Statement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementSnapshotModel from '@palette/models/StatementSnapshot';
import * as UserStatementCorrectionSummaryModel from '@palette/models/UserStatementCorrectionSummary';
import * as UserStatementCorrectionDetailsModel from '@palette/models/UserStatementCorrectionDetails';

import * as StatementsService from '@palette/services/StatementsService';

export function* listStatementsValidations({ payload = {} }) {
  const {
    userId = null,
    periodType = null,
    periodYear = null,
  } = payload;

  const callData = {
    userId,
    periodType,
    periodYear,
  };

  const callResult = yield call(StatementsService.statementsValidationsList, callData);

  if (callResult.ok) {
    const statements = StatementModel.transformList(callResult.data);

    yield put(StatementsActions.setListStatementsValidations({ statements, callData }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.listStatementsValidationsCompleted());
}

export function* listStatementsSnapshots({ payload = {} }) {
  const {
    userId,
    from,
    to,
  } = payload;

  const callData = {
    userId,
    from,
    to,
  };

  const callResult = yield call(StatementsService.listStatementsSnapshots, callData);

  if (callResult.ok) {
    const snapshots = StatementSnapshotModel.transformList(callResult.data);

    yield put(StatementsActions.setListStatementsSnapshots({ snapshots, callData }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.listStatementsSnapshotsCompleted());
}

export function* downloadStatementValidation({ payload = {} }) {
  const {
    validationId,
  } = payload;

  const callData = {
    validationId,
  };

  const callResult = yield call(StatementsService.downloadStatementValidation, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.downloadStatementValidationCompleted());
}

export function* downloadSnapshot({ payload = {} }) {
  const {
    snapshotId,
  } = payload;

  const callData = {
    snapshotId,
  };

  const callResult = yield call(StatementsService.downloadSnapshot, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.downloadSnapshotCompleted());
}

export function* listStatementPeriods({ payload = {} }) {
  const { currency, from, to, userId = null } = payload;

  const callData = {
    currency,
    from,
    to,
    userId,
  };

  const callResult = yield call(StatementsService.listStatementPeriods, callData);

  if (callResult.ok) {
    const statementPeriods = _orderBy(StatementPeriodModel.transformList(callResult.data), ['beginDate']);

    yield put(StatementsActions.setStatementPeriodsList({ statementPeriods }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.listStatementPeriodsCompleted());
}

export function* getStatementPeriodById({ payload = {} }) {
  const { statementPeriodId, currency, payeeId = undefined } = payload;

  const callData = {
    statementPeriodId,
    currency,
  };

  const callResult = yield call(StatementsService.getStatementPeriodById, callData);

  if (callResult.ok) {
    const statementPeriod = StatementPeriodModel.transform(callResult.data);

    yield put(StatementsActions.setStatementPeriod({ statementPeriod, payeeId }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.getStatementPeriodByIdCompleted());
}

export function* getUserStatementById({ payload = {} }) {
  const { statementPeriodId, userId, currency } = payload;

  const callData = {
    statementPeriodId,
    userId,
    currency,
  };

  const callResult = yield call(StatementsService.getUserStatementById, callData);

  if (callResult.ok) {
    const userStatement = UserStatementModel.transform(callResult.data);

    yield put(StatementsActions.setUserStatement({ statementPeriodId, userId, userStatement }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.getUserStatementByIdCompleted());
}

export function* deleteAdjustment({ payload = {} }) {
  const { statementId, adjustmentId, statementPeriodId, userId, currency } = payload;

  const data = {
    statementId,
    adjustmentId,
  };

  const callResult = yield call(StatementsService.deleteAdjustment, data);

  if (callResult.ok) {
    yield put(MasterPlansActions.computeAll({ enableSuccessNotification: false, enableReload: false }));

    yield take(MasterPlansActions.computeAllCompleted.type);

    yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));

    yield take(StatementsActions.getUserStatementByIdCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_ADJUSTMENT_DELETION_SUCCESS));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.deleteAdjustmentCompleted());
}

export function* addEditAdjustment({ payload = {} }) {
  const {
    statementId,
    adjustmentId = undefined,
    correctionStatementId = undefined,
    amount,
    adjustmentCurrency,
    reason,
    onSuccessCB = null,
    statementPeriodId,
    userId,
    currency,
  } = payload;

  const data = {
    statementId,
    amount,
    currency: adjustmentCurrency,
    reason,
  };

  let serviceCall = StatementsService.addAdjustment;

  if (adjustmentId) {
    data.adjustmentId = adjustmentId;
    serviceCall = StatementsService.updateAdjustment;
  } else if (correctionStatementId) {
    data.correctionStatementId = correctionStatementId;
    serviceCall = StatementsService.createCorrection;
  }

  const callResult = yield call(serviceCall, data);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));

    yield take(StatementsActions.getUserStatementByIdCompleted.type);

    if (adjustmentId) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_ADJUSTMENT_UPDATE_SUCCESS));
    } else if (correctionStatementId) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_APPLY_CORRECTION_SUCCESS));
    } else {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_ADJUSTMENT_ADD_SUCCESS));
    }
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.addEditAdjustmentCompleted());
}

export function* listMyStatementPeriods({ payload = {} }) {
  const { from, to, inSalesforce } = payload;

  const callData = {
    from,
    to,
    inSalesforce,
  };

  const callResult = yield call(StatementsService.listMyStatementPeriods, callData);

  if (callResult.ok) {
    const statementPeriods = StatementPeriodModel.transformList(callResult.data);

    yield put(StatementsActions.setMyStatementPeriodsList({ statementPeriods }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.listMyStatementPeriodsCompleted());
}

export function* getICUserStatement({ payload = {} }) {
  const { statementPeriodId, inSalesforce = false } = payload;

  const callData = {
    statementPeriodId,
    inSalesforce,
  };

  const callResult = yield call(StatementsService.getICUserStatement, callData);

  if (callResult.ok) {
    const userStatement = UserStatementModel.transform(callResult.data);

    yield put(StatementsActions.setICUserStatement({ statementPeriodId, userStatement }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.getICUserStatementCompleted());
}

export function* processApproveStatement(payload) {
  const {
    statementId,
    statementPeriodId,
    currency,
    userId = null,
    isForIC = false,
  } = payload;

  const callData = { statementPeriodId, statementIds: [statementId] };

  const callResult = yield call(StatementsService.approveStatements, callData);

  if (callResult.ok) {
    if (isForIC) {
      yield put(StatementsActions.getICUserStatement({ statementPeriodId }));

      yield take(StatementsActions.getICUserStatementCompleted.type);
    } else if (userId !== null) {
      yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));

      yield take(StatementsActions.getUserStatementByIdCompleted.type);
    } else {
      yield put(StatementsActions.getStatementPeriodById({ statementPeriodId, currency }));

      yield take(StatementsActions.getStatementPeriodByIdCompleted.type);
    }
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.updateOngoingBulkApproveStatusIndex());
}

export function* runApproveStatementsProcess() {
  let ongoingBulkApproveStatus = yield select(StatementsSelectors.getOngoingBulkApproveStatus);

  while (ongoingBulkApproveStatus.index < ongoingBulkApproveStatus.statementIds.length) {
    const {
      index,
      statementIds,
      statementPeriodId,
      currency,
      userId,
      isForIC,
    } = ongoingBulkApproveStatus;
    const statementId = statementIds[index];

    yield call(processApproveStatement, { statementId, statementPeriodId, currency, userId, isForIC });

    ongoingBulkApproveStatus = yield select(StatementsSelectors.getOngoingBulkApproveStatus);
  }

  yield put(GlobalNotifActions.addGlobalNotif({
    message: {
      code: GLOBAL_NOTIF_REASONS.STATEMENT_APPROVE_STATEMENTS_SUCCESS.code,
      context: {
        count: ongoingBulkApproveStatus.statementIds.length,
      },
    },
  }));

  yield put(StatementsActions.approveStatementsCompleted());
}

export function* approveStatements() {
  yield spawn(runApproveStatementsProcess);
}

export function* cancelStatementApproval({ payload = {} }) {
  const { statementId, statementPeriodId, currency, userId, isForIC = false } = payload;

  const callData = { statementId };

  const callResult = yield call(StatementsService.cancelStatementApproval, callData);

  if (callResult.ok) {
    if (isForIC) {
      yield put(StatementsActions.getICUserStatement({ statementPeriodId }));

      yield take(StatementsActions.getICUserStatementCompleted.type);
    } else if (userId) {
      yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));

      yield take(StatementsActions.getUserStatementByIdCompleted.type);
    } else {
      yield put(StatementsActions.getStatementPeriodById({ statementPeriodId, currency }));

      yield take(StatementsActions.getStatementPeriodByIdCompleted.type);
    }

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_CANCEL_APPROVAL_SUCCESS));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.cancelStatementApprovalCompleted());
}

export function* processMarkAsPaidStatement(payload) {
  const { statementId, statementPeriodId, currency, userId = null } = payload;

  const callData = { statementPeriodId, statementIds: [statementId] };

  const callResult = yield call(StatementsService.markAsPaidStatements, callData);

  if (callResult.ok) {
    if (userId) {
      yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));

      yield take(StatementsActions.getUserStatementByIdCompleted.type);
    } else {
      yield put(StatementsActions.getStatementPeriodById({ statementPeriodId, currency }));

      yield take(StatementsActions.getStatementPeriodByIdCompleted.type);
    }
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.updateOngoingBulkMarkAsPaidStatusIndex());
}

export function* runMarkAsPaidStatementsProcess() {
  let ongoingBulkMarkAsPaidStatus = yield select(StatementsSelectors.getOngoingBulkMarkAsPaidStatus);

  while (ongoingBulkMarkAsPaidStatus.index < ongoingBulkMarkAsPaidStatus.statementIds.length) {
    const { index, statementIds, statementPeriodId, currency, userId } = ongoingBulkMarkAsPaidStatus;
    const statementId = statementIds[index];

    yield call(processMarkAsPaidStatement, { statementId, statementPeriodId, currency, userId });

    ongoingBulkMarkAsPaidStatus = yield select(StatementsSelectors.getOngoingBulkMarkAsPaidStatus);
  }

  yield put(GlobalNotifActions.addGlobalNotif({
    message: {
      code: GLOBAL_NOTIF_REASONS.STATEMENT_MARK_AS_PAID_STATEMENTS_SUCCESS.code,
      context: {
        count: ongoingBulkMarkAsPaidStatus.statementIds.length,
      },
    },
  }));

  yield put(StatementsActions.markAsPaidStatementsCompleted());
}

export function* markAsPaidStatements() {
  yield spawn(runMarkAsPaidStatementsProcess);
}

export function* ignoreCorrection({ payload = {} }) {
  const {
    statementId,
    correctionStatementId,
    statementPeriodId,
    userId,
    currency,
    onSuccessCB = null,
  } = payload;

  const data = {
    statementId,
    correctionStatementId,
  };

  const callResult = yield call(StatementsService.ignoreCorrection, data);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));

    yield take(StatementsActions.getUserStatementByIdCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_IGNORE_CORRECTION_SUCCESS));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.ignoreCorrectionCompleted());
}

export function* downloadPeriodUsersStatements({ payload = {} }) {
  const {
    statementIds = null,
    statementPeriodId,
    currency = null,
  } = payload;

  const callData = {
    statementIds,
    statementPeriodId,
    currency,
  };

  const callResult = yield call(StatementsService.downloadPeriodUsersStatements, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.downloadPeriodUsersStatementsCompleted());
}

export function* downloadUserStatement({ payload = {} }) {
  const {
    user,
    statementPeriodId,
    currency,
  } = payload;

  const callData = {
    user,
    statementPeriodId,
    currency,
  };

  const callResult = yield call(StatementsService.downloadUserStatement, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.downloadUserStatementCompleted());
}

export function* downloadMyUserStatements({ payload = {} }) {
  const {
    userStatementId,
    statementPeriodId,
  } = payload;

  const callData = {
    userStatementId,
    statementPeriodId,
  };

  const callResult = yield call(StatementsService.downloadMyUserStatements, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.downloadMyUserStatementsCompleted());
}

export function* exportToPayroll({ payload = {} }) {
  const {
    payrollProviderId,
    statementPeriodId,
    statementIds,
  } = payload;

  const callData = {
    payrollProviderId,
    statementPeriodId,
    statementIds,
  };

  const callResult = yield call(StatementsService.exportToPayroll, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.exportToPayrollCompleted());
}

export function* getUserStatementCorrectionSummaries({ payload = {} }) {
  const { correctionStatementId } = payload;

  const callData = { correctionStatementId };

  const callResult = yield call(StatementsService.investigateCorrectionSummaries, callData);

  if (callResult.ok) {
    const correctionStatementSummaries = UserStatementCorrectionSummaryModel.transformList(callResult.data);

    yield put(StatementsActions.setUserStatementCorrectionSummaries({ correctionStatementSummaries, correctionStatementId }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.getUserStatementCorrectionSummariesCompleted());
}

export function* getUserStatementCorrectionDetails({ payload = {} }) {
  const { correctionStatementId, correctionSummaryId, planId, dealId, periodId } = payload;

  const callData = { correctionStatementId, planId, dealId, periodId };

  const callResult = yield call(StatementsService.investigateCorrectionDetails, callData);

  if (callResult.ok) {
    const correctionStatementDetails = UserStatementCorrectionDetailsModel.transform(callResult.data);

    yield put(StatementsActions.setUserStatementCorrectionDetails({ correctionStatementDetails, correctionStatementId, correctionSummaryId }));
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.getUserStatementCorrectionDetailsCompleted());
}

export function* markAsDirty({ payload = {} }) {
  const {
    statementId,
    userId,
    statementPeriodId,
    currency,
  } = payload;

  const callData = {
    statementId,
  };

  const callResult = yield call(StatementsService.markAsDirty, callData);

  if (callResult.ok) {
    yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));

    yield take(StatementsActions.getUserStatementByIdCompleted.type);
  } else {
    const error = manageStatementsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsActions.markAsDirtyCompleted());
}

export function* updateStatementDataInState({ statementPeriodId, userId, currency }) {
  yield put(StatementsActions.getUserStatementById({ statementPeriodId, userId, currency }));
  yield take(StatementsActions.getUserStatementByIdCompleted.type);
}

export function* loop() {
  yield all([
    takeLatest(StatementsActions.listStatementsValidations.type, listStatementsValidations),
    takeLatest(StatementsActions.listStatementsSnapshots.type, listStatementsSnapshots),
    takeLatest(StatementsActions.downloadStatementValidation.type, downloadStatementValidation),
    takeLatest(StatementsActions.downloadSnapshot.type, downloadSnapshot),
    takeLatest(StatementsActions.listStatementPeriods.type, listStatementPeriods),
    takeLatest(StatementsActions.getStatementPeriodById.type, getStatementPeriodById),
    takeLatest(StatementsActions.getUserStatementById.type, getUserStatementById),
    takeLatest(StatementsActions.deleteAdjustment.type, deleteAdjustment),
    takeLatest(StatementsActions.addEditAdjustment.type, addEditAdjustment),
    takeLatest(StatementsActions.listMyStatementPeriods.type, listMyStatementPeriods),
    takeLatest(StatementsActions.getICUserStatement.type, getICUserStatement),
    takeLatest(StatementsActions.approveStatements.type, approveStatements),
    takeLatest(StatementsActions.cancelStatementApproval.type, cancelStatementApproval),
    takeLatest(StatementsActions.markAsPaidStatements.type, markAsPaidStatements),
    takeLatest(StatementsActions.ignoreCorrection.type, ignoreCorrection),
    takeLatest(StatementsActions.downloadPeriodUsersStatements.type, downloadPeriodUsersStatements),
    takeLatest(StatementsActions.downloadUserStatement.type, downloadUserStatement),
    takeLatest(StatementsActions.downloadMyUserStatements.type, downloadMyUserStatements),
    takeLatest(StatementsActions.exportToPayroll.type, exportToPayroll),
    takeLatest(StatementsActions.getUserStatementCorrectionSummaries.type, getUserStatementCorrectionSummaries),
    takeLatest(StatementsActions.getUserStatementCorrectionDetails.type, getUserStatementCorrectionDetails),
    takeLatest(StatementsActions.markAsDirty.type, markAsDirty),
  ]);
}

export function* clean() {
  yield put(StatementsActions.resetToInitialState());
}
