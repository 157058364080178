import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionCustomCodeRuleDetails.less';

const classNames = bindClassNames.bind(styles);

const CommissionCustomCodeRuleDetails = ({ className, commission }) => {
  const { t } = useTranslation();

  const rule = useMemo(() => commission.scopeParams?.rule, [commission]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.ruleNumber}>
        {rule.name || t('commissionRuleDetails.ruleNumber', { index: rule.index + 1 })}
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: t('commissionRuleDetails.customLogic') }} />
      <div className={styles.description}>
        {rule.explanation}
      </div>
    </div>
  );
};

CommissionCustomCodeRuleDetails.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionCustomCodeRuleDetails.defaultProps = {
  className: '',
};

export default CommissionCustomCodeRuleDetails;
