import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import TeamsPageContent from '@palette/components/team/TeamsPageContent/TeamsPageContent';
import NewTeamButton from '@palette/components/team/NewTeamButton/NewTeamButton';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './TeamsPage.less';

const TeamsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.VIEW_ALL, RIGHTS.ADMIN.TEAMS.VIEW_SCOPED])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.teamsTitle')} />
      <PageTitle className={styles.titleWrapper}>
        <div className={styles.titlesContainer}>
          <h1>{t('teamsPage.page.title')}</h1>
        </div>
        <div className={styles.actions}>
          <NewTeamButton className={styles.newTeamBtn} />
        </div>
      </PageTitle>
      <TeamsPageContent />
    </div>
  );
};

export default TeamsPage;
