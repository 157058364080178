import { call, put, all, take, takeLatest } from 'redux-saga/effects';

import { actions as CommissionV3Actions } from '@palette/state/CommissionV3/slice';
import { manageError as manageCommissionV3Error } from '@palette/state/CommissionV3/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import * as CommissionV3Service from '@palette/services/CommissionV3Service';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

export function* getDetailsCommission({ payload = {} }) {
  const { commissionId } = payload;

  const callResult = yield call(CommissionV3Service.commissionV3getCommissionDetails, { commissionId });

  if (callResult.ok) {
    const detailsCommission = CommissionV3DetailsCommissionModel.transform(callResult.data);
    yield put(CommissionV3Actions.setDetailsCommission({ detailsCommission }));
  } else {
    const error = manageCommissionV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionV3Actions.getDetailsCommissionCompleted());
}

export function* getICDetailsCommission({ payload = {} }) {
  const { commissionId } = payload;

  const callResult = yield call(CommissionV3Service.commissionV3getICCommissionDetails, { commissionId });

  if (callResult.ok) {
    const detailsCommission = CommissionV3DetailsCommissionModel.transform(callResult.data);
    yield put(CommissionV3Actions.setICDetailsCommission({ detailsCommission }));
  } else {
    const error = manageCommissionV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionV3Actions.getICDetailsCommissionCompleted());
}

export function* overwriteAmount({ payload = {} }) {
  const { commissionId, amount, onSuccessCB = null } = payload;

  const callResult = yield call(CommissionV3Service.commissionV3overwriteAmount, { commissionId, amount });

  if (callResult.ok) {
    yield put(CommissionV3Actions.getDetailsCommission({ commissionId }));
    yield take(CommissionV3Actions.getDetailsCommissionCompleted.type);

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageCommissionV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionV3Actions.overwriteAmountCompleted());
}

export function* overwritePayments({ payload = {} }) {
  const { commissionId, payments, onSuccessCB } = payload;

  const callResult = yield call(CommissionV3Service.commissionV3overwritePayments, { commissionId, payments });

  if (callResult.ok) {
    yield put(CommissionV3Actions.getDetailsCommission({ commissionId }));
    yield take(CommissionV3Actions.getDetailsCommissionCompleted.type);

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageCommissionV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionV3Actions.overwritePaymentsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(CommissionV3Actions.getDetailsCommission.type, getDetailsCommission),
    takeLatest(CommissionV3Actions.getICDetailsCommission.type, getICDetailsCommission),
    takeLatest(CommissionV3Actions.overwriteAmount.type, overwriteAmount),
    takeLatest(CommissionV3Actions.overwritePayments.type, overwritePayments),
  ]);
}

export function* clean() {
  yield put(CommissionV3Actions.resetToInitialState());
}
