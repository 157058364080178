const en = {
  translation: {
    'actions.copyAsJSON': 'Copy as JSON',
    'actions.edit': 'Edit',
    'actions.delete': 'Delete',
    'actions.duplicate': 'Duplicate',
    'actions.filter': 'Filter',
    'actions.remove': 'Remove',
    'actions.copy': 'Copy',
    'actionsMenu.settings': 'Settings',
    'actionsMenu.documentation': 'Documentation',
    'actionsMenu.sendFeedback': 'Send feedback',
    'actionsMenu.chatWithSupport': 'Chat with support',
    'addDashboardWidgetDrawer.title': 'Add a Widget',
    'addDashboardWidgetDrawer.category.common.label': 'Common widgets',
    'addDashboardWidgetDrawer.category.common.showMore': 'Show more',
    'addDashboardWidgetDrawer.category.plan.label': 'Plan widgets',
    'addDashboardWidgetDrawer.category.plan.chooseAPlan': 'Choose a plan to list the widgets available',
    'addDashboardWidgetDrawer.addWidget': 'Add selected widget',
    'addDashboardWidgetDrawer.noWidgetsAvailable': 'No widgets available now, please contact your administrator if you think this is a mistake.',
    'addDataConnectionButton.label': 'Add new data',
    'addDataConnectionDataSelectorBlock.search.placeholder': 'Search',
    'addDataConnectionDataSourceConnectors.connectorLastSync': 'Last sync {{date}}',
    'addDataConnectionDataSourceConnectors.usedResourcesNode': '$t(addDataConnectionSelectDataConnectorStep.availableResources, {"count": {{total}} }) - $t(addDataConnectionSelectDataConnectorStep.usedResources, {"count": {{used}} })',
    'addDataConnectionFlowSteps.select_data_source.label': 'Select Data Source',
    'addDataConnectionFlowSteps.select_data.label': 'Select Data',
    'addDataConnectionModal.title': 'Import data',
    'addDataConnectionModal.import': 'Import',
    'addDataConnectionSelectDataConnectorStep.availableResources_zero': '{{count}} available resources',
    'addDataConnectionSelectDataConnectorStep.availableResources_one': '{{count}} available resource',
    'addDataConnectionSelectDataConnectorStep.availableResources_other': '{{count}} available resources',
    'addDataConnectionSelectDataConnectorStep.usedResources_zero': '{{count}} used',
    'addDataConnectionSelectDataConnectorStep.usedResources_one': '{{count}} used',
    'addDataConnectionSelectDataConnectorStep.usedResources_other': '{{count}} used',
    'addDataConnectionSelectDataSourceStep.tabs.connectors': 'Connectors',
    'addDataConnectionSelectDataSourceStep.tabs.models': 'Models',
    'addUsersInMasterPlanModal.addManagers.title': 'Add managers',
    'addUsersInMasterPlanModal.addUsers.title': 'Add users',
    'addUsersInMasterPlanModal.addManagers.button': 'Add managers',
    'addUsersInMasterPlanModal.addUsers.button': 'Add users',
    'addUsersModal.title': 'Add users',
    'addUsersModal.okText': 'Add users',
    'addUsersModal.usersSelection': 'Selection',
    'addUsersModal.usersSelected': 'Selected users',
    'addUsersWithFastOnboardingModal.cancelFastOnboarding': 'Cancel fast onboarding',
    'addMembersToTeam.label': 'Add members',
    'addMembersToTeamModal.title': 'Add members to {{teamName}}',
    'addMembersToTeamModal.addMembers': 'Add members',
    'addNotebookCell.cellTypesCategories.basic': 'Basic',
    'addNotebookCell.cellTypesCategories.noSupport': 'No Customer Support',
    'adminMenu.title': 'Manage',
    'adminMenu.commissions': 'Commissions',
    'adminMenu.commissions.statements': 'Statements',
    'adminMenu.commissions.list': 'Commission list',
    'adminMenu.plans': 'Plans',
    'adminMenu.auditTrail': 'Audit trail',
    'adminMenu.users': 'Users',
    'adminMenu.users.list': 'All Users',
    'adminMenu.users.teams': 'Teams',
    'adminMenu.users.roles': 'Roles',
    'adminMenu.users.presets': 'Dashboards',
    'adminMenu.users.variables': 'Variables',
    'adminMenu.quotas': 'Quotas',
    'adminMenu.quotas.configuration': 'Configuration',
    'adminMenu.quotas.performance': 'Performance',
    'adminMenu.plansV3.configuration': 'Configuration',
    'adminMenu.plansV3.plans': 'Plans',
    'adminMenu.plansV3.statements': 'Statements',
    'adminMenu.resources': 'Resources',
    'adminMenu.connectors': 'Connectors',
    'adminMenu.notifications': 'Notifications',
    'adminMenu.notebooks': 'Models',
    'algoliaAutocomplete.seeAllResults': 'See all {{nbResults}} results',
    'algoliaAutocomplete.noResultsFor': 'No results for "{{search}}"',
    'algoliaAutocomplete.seeFullDoc': 'Open Documentation',
    'algoliaAutocomplete.typeSearch': 'Type a search to display results',
    'algoliaSearchModal.documentation': 'Documentation',
    'app.metas.commissionListTitle': 'Commission list',
    'app.metas.auditTrailTitle': 'Activities audit trail',
    'app.metas.resourcesTitle': 'Resources',
    'app.metas.resourceDetailsTitle': 'Resource details',
    'app.metas.myCommissionListTitle': 'My Commission list',
    'app.metas.statementsValidationsExportTitle': 'Statements validations export',
    'app.metas.createPlanFlowTitle': 'Create Plan Wizard',
    'app.metas.editPlan': 'Edit plan',
    'app.metas.requirementsPlan': 'Requirements plan',
    'app.metas.dashboardPresetsTitle': 'Dashboard Presets',
    'app.metas.dashboardPresetDetailsTitle': 'Dashboard Preset',
    'app.metas.defaultTitle': 'Palette helps you design and automate the best sales compensation plan for your team | Palette',
    'app.metas.quotasTitle': 'Quotas configuration',
    'app.metas.quotasPerformanceTitle': 'Quotas performance',
    'app.metas.statementsInvestigateTitle': 'Investigate correction',
    'app.metas.masterPlansComparatorTitle': 'Plans Comparator',
    'app.metas.masterPlansTitle': 'Plans',
    'app.metas.masterPlansDetailsTitle': '{{planName}} - Plans',
    'app.metas.myMasterPlansDetailsTitle': '{{planName}} - My Plans',
    'app.metas.masterPlansSettingsTitle': '{{planName}} - Settings - Plans',
    'app.metas.masterPlanPeriodDetailsTitle': '{{periodName}} - {{planName}} - Plans',
    'app.metas.myMasterPlanPeriodDetailsTitle': '{{periodName}} - {{planName}} - My Plans',
    'app.metas.myDashboardTitle': 'My Dashboard',
    'app.metas.myMasterPlansTitle': 'My Plans',
    'app.metas.onboardUsersFromResourcesFlowTitle': 'Onboard Users Wizard: From Resources',
    'app.metas.onboardUsersManualInputFlowTitle': 'Onboard Users Wizard: Manual Input',
    'app.metas.rolesTitle': 'Roles',
    'app.metas.statementsTitle': 'Statements',
    'app.metas.statementsPeriodTitle': 'Statements',
    'app.metas.statementsUserStatementTitle': 'Statements',
    'app.metas.usersTitle': 'All Users',
    'app.metas.usersMergeTitle': 'Users Merge',
    'app.metas.usersVariablesTitle': 'Users\' Variables',
    'app.metas.teamsTitle': 'All Teams',
    'app.metas.teamDetailsTitle': '{{teamName}} - Teams',
    'app.metas.myStatementsTitle': 'My Statements',
    'app.metas.userDetailsTitle': '{{displayName}} - Users',
    'app.metas.notebooksTitle': 'Models',
    'app.metas.notebookDetailsTitle': '{{notebookName}} - Models',
    'app.metas.loginTitle': 'Login',
    'app.metas.forgotPasswordTitle': 'Forgot Password',
    'app.metas.resetPasswordTitle': 'Reset Password',
    'app.metas.profileTitle': 'My Profile',
    'app.metas.settingsTitle': 'Settings',
    'applyDashboardPresetModal.title': 'Apply {{presetName}} to users',
    'applyDashboardPresetModal.apply_zero': 'Select users',
    'applyDashboardPresetModal.apply_one': 'Apply Dashboard to 1 user',
    'applyDashboardPresetModal.apply_other': 'Apply Dashboard to {{count}} users',
    'applyDashboardPresetModal.alert.message': 'You are about to override user\'s dashboards',
    'applyDashboardPresetModal.alert.description': 'By applying a predefined Dashboard on users, you will override their own Dashboard.',
    'archiveMasterPlanModal.title': 'Archive Plan',
    'archiveMasterPlanModal.confirm': 'I confirm',
    'archiveMasterPlanModal.content': 'Do you confirm you want to archive <strong>{{planName}}</strong>?',
    'archiveNotebookModal.title': 'Archive Model',
    'archiveNotebookModal.confirm': 'I confirm',
    'archiveNotebookModal.content': 'Do you confirm you want to archive <strong>{{notebookName}}</strong>?',
    'avatarUploader.modal.title_profile': 'Edit Profile Picture',
    'avatarUploader.modal.title_user': 'Edit User Profile Picture',
    'avatarUploader.modal.title_company': 'Edit Company Picture',
    'avatarUploader.modal.okText_profile': 'Update profile picture',
    'avatarUploader.modal.okText_user': 'Update profile picture',
    'avatarUploader.modal.okText_company': 'Update company picture',
    'avatarUploader.modal.label.or': 'Or',
    'auditTrail.page.title': 'Activities audit trail',
    'auditTrail.page.subtitle': 'Logs are stored for 90 days',
    'auditTrail.filterByType.option.plan': 'Plans',
    'auditTrail.filterByType.option.plan_v3': 'Plans V3',
    'auditTrail.filterByType.option.statement': 'Statements',
    'auditTrail.filterByType.option.commission': 'Commissions',
    'auditTrail.filterByType.option.resource': 'Resources',
    'auditTrail.filterByType.option.user': 'Users',
    'auditTrail.filterByType.option.setting': 'Settings',
    'auditTrail.filterByTime.option.today': 'Today',
    'auditTrail.filterByTime.option.week': 'This week',
    'auditTrail.filterByTime.option.month': 'This month',
    'auditTrail.filterByType.placeholder': 'Filter by category',
    'auditTrail.filterByLimit.placeholder': 'Filter by date',
    'auditTrail.list.empty.description': 'No activities yet.',
    'auditTrail.list.item.link.label': 'See more',
    'auditTrail.action.title.create_plan_v3': 'Plan v3 creation',
    'auditTrail.action.title.delete_plan_v3': 'Plan v3 deletion',
    'auditTrail.action.title.update_plan_v3_configuration': 'Plan v3 update',
    'auditTrail.action.title.freeze_plan': 'Frozen period',
    'auditTrail.action.title.unfreeze_plan': 'Unfrozen period',
    'auditTrail.action.title.hide_plan_for_reps': 'Hide for reps',
    'auditTrail.action.title.show_plan_for_reps': 'Show for reps',
    'auditTrail.action.title.adjustment_added': 'Correction added',
    'auditTrail.action.title.adjustment_updated': 'Correction updated',
    'auditTrail.action.title.adjustment_deleted': 'Correction deleted',
    'auditTrail.action.title.correction_applied': 'Applied correction',
    'auditTrail.action.title.correction_ignored': 'Ignored correction',
    'auditTrail.action.title.overwrite_commission_amount': 'Amount overwritten',
    'auditTrail.action.title.overwrite_commission_payout': 'Payout overwritten',
    'auditTrail.action.title.reseted_commission_amount': 'Amount reseted',
    'auditTrail.action.title.reseted_commission_payout': 'Payout reseted',
    'auditTrail.action.title.connector_added': 'Connector added',
    'auditTrail.action.title.raw_data_updated': 'Raw data updated',
    'auditTrail.action.title.raw_data_deleted': 'Raw data deleted',
    'auditTrail.action.title.offboard_leave_date': 'Set leaving date',
    'auditTrail.action.title.offboard_reset_date': 'Reset offboarding',
    'auditTrail.action.title.user_role_updated': 'New role',
    'auditTrail.action.title.number_seats': 'New total number of seats',
    'auditTrail.action.title.statement_fx_rate_mode': 'New statement FX rates mode',
    'auditTrail.action.title.toggle_statement_validation': 'New statement validations parameter',
    'auditTrail.action.title.auto_freeze_plan_enabled': 'Auto freeze enabled',
    'auditTrail.action.title.auto_freeze_plan_disabled': 'Auto freeze disabled',
    'auditTrail.action.title.updated_plan_name': 'Name updated',
    'auditTrail.action.title.updated_plan_currency': 'Currency updated',
    'auditTrail.action.title.added_users_plan': 'Users added',
    'auditTrail.action.title.deleted_users_plan': 'Users deleted',
    'auditTrail.action.title.linked_quota_plan': 'Quota linked',
    'auditTrail.action.title.unlinked_quota_plan': 'Quota unlinked',
    'auditTrail.action.title.statement_approved': 'Statement approved',
    'auditTrail.action.title.statement_marked_as_paid': 'Statement marked as paid',
    'auditTrail.action.description.create_plan_v3': 'Plan <strong>{{planName}}</strong> has been created.',
    'auditTrail.action.description.delete_plan_v3': 'Plan <strong>{{planName}}</strong> has been deleted.',
    'auditTrail.action.description.update_plan_v3_configuration': 'Plan <strong>{{planName}}</strong> has been updated.',
    'auditTrail.action.description.freeze_plan': 'froze a period on <strong>{{plan}}</strong>.',
    'auditTrail.action.description.unfreeze_plan': 'unfroze a period on <strong>{{plan}}</strong>.',
    'auditTrail.action.description.hide_plan_for_reps': 'disabled <strong>{{plan}}</strong> visibility to reps.',
    'auditTrail.action.description.show_plan_for_reps': 'set <strong>{{plan}}</strong> visible to reps.',
    'auditTrail.action.description.adjustment_added': 'added a correction of <strong>{{newAmount}}</strong> on the <strong>{{periodName}}</strong> commission statement of <strong>{{payee}}</strong>.',
    'auditTrail.action.description.adjustment_updated': 'updated a correction on the <strong>{{periodName}}</strong> commission statement of <strong>{{payee}}</strong> from <strong>{{oldAmount}}</strong> to <strong>{{newAmount}}</strong>.',
    'auditTrail.action.description.adjustment_deleted': 'deleted a correction on the <strong>{{periodName}}</strong> commission statement of <strong>{{payee}}</strong>.',
    'auditTrail.action.description.correction_applied': 'applied the correction of <strong>{{amount}}</strong> related to the <strong>{{sourcePeriodName}}</strong> commission statement to the <strong>{{targetPeriodName}}</strong> commission statement of <strong>{{payee}}</strong>',
    'auditTrail.action.description.correction_ignored': 'ignored the correction of <strong>{{amount}}</strong> related to the <strong>{{sourcePeriodName}}</strong> commission statement of <strong>{{payee}}</strong>',
    'auditTrail.action.description.overwrite_commission_amount': 'updated the commission amount tied to the commission paid to <strong>{{payee}}</strong> from <strong>{{oldAmount}}</strong> to <strong>{{newAmount}}</strong>.',
    'auditTrail.action.description.overwrite_commission_payout': 'updated the payout schedule of the commission paid to <strong>{{payee}}</strong>{{valueDefinitionLabel}}.',
    'auditTrail.action.description.reseted_commission_amount': 'reseted the commission amount tied to the commission paid to <strong>{{payee}}</strong>.',
    'auditTrail.action.description.reseted_commission_payout': 'reseted the payout schedule of the commission paid to <strong>{{payee}}</strong>{{valueDefinitionLabel}}.',
    'auditTrail.action.description.connector_added': 'added <strong>{{connectorName}}</strong> connector.',
    'auditTrail.action.description.raw_data_updated': 'updated <strong>{{entryPath}}</strong> on <strong>{{type}}</strong> raw data.',
    'auditTrail.action.description.raw_data_deleted': 'deleted <strong>{{entryPath}}</strong> on <strong>{{type}}</strong> raw data.',
    'auditTrail.action.description.offboard_leave_date': 'set <strong>{{leaveDate}}</strong> as a leave date for <strong>{{userName}}</strong>.',
    'auditTrail.action.description.offboard_reset_date': 'reset <strong>{{userName}}</strong> leaving date.',
    'auditTrail.action.description.user_role_updated': 'set <strong>{{roleName}}</strong> as a new role for <strong>{{userName}}</strong>.',
    'auditTrail.action.description.number_seats': 'updated the number of seats to <strong>{{newSeatsQuantity}}</strong>.',
    'auditTrail.action.description.statement_fx_rate_mode': 'updated the statement FX rates mode to: <strong>{{newStatementFxRateMode}}</strong>.',
    'auditTrail.action.description.toggle_statement_validation': 'turned <strong>{{newStatementValidation}}</strong> statement validations.',
    'auditTrail.action.description.auto_freeze_plan_enabled': 'set <strong>{{plan}}</strong> automatically freeze periods{{freezeAfter}}.',
    'auditTrail.action.description.auto_freeze_plan_disabled': 'disabled <strong>{{plan}}</strong> automatically freeze periods.',
    'auditTrail.action.description.auto_freeze_after': '<strong>{{totalDays}} days</strong> after they end',
    'auditTrail.action.description.commission_payout.valueDefinitionLabel': ', for <strong>{{quotaTrackingType}}</strong>',
    'auditTrail.action.description.updated_plan_name': 'updated the plan name from <strong>{{oldPlan}}</strong> to <strong>{{plan}}</strong>.',
    'auditTrail.action.description.updated_plan_currency': 'set <strong>{{plan}}</strong> currency to <strong>{{currency}}</strong>.',
    'auditTrail.action.description.added_users_plan': 'added <strong>$t(usersWithCount, {"count": {{usersCount}} })</strong> to <strong>{{plan}}</strong>.',
    'auditTrail.action.description.deleted_users_plan': 'deleted <strong>$t(usersWithCount, {"count": {{usersCount}} })</strong> from <strong>{{plan}}</strong>.',
    'auditTrail.action.description.linked_quota_plan': 'linked <strong>{{quotaName}}</strong> quota ({{quotaTrackingType}} · {{quotaFrequencyName}}) to <strong>{{plan}}</strong>.',
    'auditTrail.action.description.unlinked_quota_plan': 'unlinked <strong>{{quotaName}}</strong> quota ({{quotaTrackingType}} · {{quotaFrequencyName}}) from <strong>{{plan}}</strong>.',
    'auditTrail.action.description.statement_approved': 'approved <strong>{{amount}}</strong> on the <strong>{{periodName}}</strong> commission statement of <strong>{{payee}}</strong>.',
    'auditTrail.action.description.statement_marked_as_paid': 'marked as paid <strong>{{amount}}</strong> on the <strong>{{periodName}}</strong> commission statement of <strong>{{payee}}</strong>.',
    'cloudinaryUploader.modal.error.uploading': 'Error while uploading, please try again.',
    'cloudinaryUploader.modal.error.format': 'You can only upload JPG/PNG file.',
    'cloudinaryUploader.modal.error.size': 'Image must be smaller than 0.5MB.',
    'commissions.empty.description': 'No commission to show.',
    'commissions.errors.commission.not.found': 'The commission was not found.',
    'commissions.errors.per.target': 'You cannot overwrite a commission value produced by a "Per Target" rule.',
    'commissions.seePlanDetails': 'See plan details',
    commissionsComments_zero: 'comment',
    commissionsComments_one: 'comment',
    commissionsComments_other: 'comments',
    commissionsCommentsWithCount_zero: '{{count}} $t(commissionsComments_zero)',
    commissionsCommentsWithCount_one: '{{count}} $t(commissionsComments_one)',
    commissionsCommentsWithCount_other: '{{count}} $t(commissionsComments_other)',
    'commissions.seePeriodDetails': 'See period details',
    'commissions.rules': 'Rules',
    'commissionCommentForm.form.comment.label': 'Add a comment',
    'commissionCommentForm.form.comment.placeholder': 'Type your comment...',
    'commissionCommentForm.form.submit.label': 'Publish',
    'commissionDetails.date': 'Date',
    'commissionDetails.name': 'Name',
    'commissionDetails.deal': 'Deal',
    'commissionDetails.payee': 'Payee',
    'commissionDetails.plan': 'Plan',
    'commissionDetails.viewRawObject': 'View raw object',
    'commissionDrawer.title': 'Commission details',
    'commissionDrawerActivitiesTab.emptyState.description': 'No comments yet',
    'commissionDrawerTabs.variables': 'Variables',
    'commissionDrawerTabs.payments': 'Payments',
    'commissionDrawerTabs.calculations': 'Calculations',
    'commissionDrawerTabs.activities': 'Activities',
    'commissionDrawerPaymentsTab.noScheduledPayments': 'There is no payments scheduled yet.',
    'commissionDrawerPaymentsTab.noCommissionToPay': 'There is no commission to pay yet.',
    'commissionDrawerEditVariablesList.editVariables': 'Edit variables list',
    'commissionDrawerEditVariablesListModal.title': 'Edit displayed variables',
    'commissionDrawerEditVariablesListModal.subTitle': 'For {{type}} in plan {{planName}}',
    'commissionDrawerEditVariablesListModal.save': 'Save variables',
    'commissionDrawerEditVariablesListModal.tip.title': 'Tip',
    'commissionDrawerEditVariablesListModal.tip.part1': 'You can add custom fields in the {{type}}',
    'commissionDrawerEditVariablesListModal.tip.link': 'here',
    'commissionDrawerEditVariablesListModal.tip.part2': '. (in Enrich {{type}} with new fields)',
    'commissionDrawerEditVariablesListModal.error.title': 'Please verify your information',
    'commissionDrawerEditVariablesListModal.error.text': 'The name of the variable is required as well as a formula if the type of display chosen is "Custom".',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.title': 'Are you sure to reset variables to default?',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.confirm': 'Yes',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.cancel': 'No',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.cta': 'Reset to default',
    'commissionDrawerEditVariablesListModal.addVariable': 'Add a variable',
    'commissionDrawerEditVariablesListModal.variable.name': 'Variable Name',
    'commissionDrawerEditVariablesListModal.variable.fields': '{{type}} field or variable from plan',
    'commissionDrawerEditVariablesListModal.variable.fields.tooltip': 'If you add multiple fields, they\'ll be concatenated with a space in between.',
    'commissionDrawerEditVariablesListModal.variable.formatter': 'Display',
    'commissionDrawerVariablesTab.emptyState1': 'Click on the link above to add variables',
    'commissionDrawerVariablesTab.emptyState2': 'No variables yet',
    'commissionRuleDetails.ruleNumber': 'Rule n°{{index}}',
    'commissionRuleDetails.tierNumber': 'Tier {{index}}',
    'commissionRuleDetails.customLogic': 'The rule is based on a <strong>custom logic</strong> as explained here:',
    'commissionRuleDetails.targetAchievement': '<strong>This {{type}} allowed you to get to <mark>{{percentage}}% of the target</mark></strong>',
    'commissionRuleDetails.unlockTier': 'and to unlock the <strong>tier #{{index}}</strong>',
    'commissionRuleDetails.value': 'Value',
    'commissionRuleDetails.bracketPercentage': '% of the {{type}} in tier',
    'commissionRuleDetails.formula': 'Formula',
    'commissionRuleDetails.result': 'Result',
    'commissionRuleDetails.bracket.from': 'From {{from}}',
    'commissionRuleDetails.bracket.fromTo': 'Tier {{from}} to {{to}}',
    'commissionRuleVariables.title': 'Variables used for calculations',
    'commissionRuleFormulaScope.hide': 'Hide advanced details',
    'commissionRuleFormulaScope.see': 'Advanced details',
    'commissionRuleFormulaScope.formulaScope': 'Formula Scope',
    'commissionValue.commission': 'Commission',
    'common.global.true': 'True',
    'common.global.false': 'False',
    'common.global.name': 'Name',
    'common.global.teams': 'Teams',
    'common.global.value': 'Value',
    'common.global.text': 'Text',
    'common.global.mostUsed': 'Most used',
    'common.global.yes': 'Yes',
    'common.global.no': 'No',
    'common.global.or': 'Or',
    'common.global.new': 'NEW',
    'common.global.beta': 'BETA',
    'common.global.close': 'Close',
    'common.global.edit': 'Edit',
    'common.global.remove': 'Remove',
    'common.global.upload': 'Upload',
    'common.global.total': 'Total',
    'common.global.current': 'Current',
    'common.palette.user': 'Palette User',
    'common.palette.on': 'On',
    'common.palette.off': 'Off',
    'companyBilling.block.title': 'Subscription and Billing',
    'companyBilling.block.error.subscription': 'Error while loading subscription.',
    'companyBilling.block.portal.label': 'Edit billing info / View invoices',
    'companyBilling.block.form.submit.label': 'Update',
    'companyBilling.block.form.addon.label': '# Seats',
    'companyBilling.block.form.error.required.message': 'Please enter a number of seats.',
    'companyBilling.block.form.error.min.message': 'You cannot have less than {{count}} seats.',
    'companySettings.block.title': 'Company',
    'companySettings.seats.count': '{{count}} seats used',
    'companySettings.seats.tooltip': 'A seat is a user being part of an active plan in the current period.',
    'companyCurrencies.block.title': 'Currencies',
    'companyCurrencies.content.text': 'Define the currencies authorized in the plans.',
    'companyCurrencies.currency.delete.popconfirm': 'Are you sure to remove this currency?',
    'companyCurrencies.currency.default': 'Default',
    'companyCurrencies.currency.makeDefault': 'Make default',
    'companyCurrencies.addCompanyCurrency.button.label': 'Add currency',
    'companyCurrencies.addCompanyCurrency.modal.title': 'Add a currency',
    'companyCurrencies.addCompanyCurrency.modal.save.label': 'Add currency',
    'companyCurrencies.addCompanyCurrency.modal.select.label': 'Currency code',
    'companyCurrencies.addCompanyCurrency.modal.select.placeholder': 'Enter a currency code (ex: USD)',
    'companyCurrencies.addCompanyCurrency.modal.select.error.message': 'Please select a valid currency.',
    'companyExchangeRates.block.title': 'Exchange Rates',
    'companyExchangeRates.content.text': 'Define the FX rates you want to use in the plan rules.',
    'companyExchangeRates.edit.tooltip.label': 'Click to edit the FX rate value',
    'companyExchangeRates.history.tooltip.label': '{{count}} changes, click to view history',
    'companyExchangeRates.button.add.label.new': 'Add exchange rate',
    'companyExchangeRates.button.add.label': 'Add new rate',
    'companyExchangeRates.modal.title.add': 'Add an exchange rate',
    'companyExchangeRates.modal.title.edit': 'Edit an exchange rate',
    'companyExchangeRates.modal.save.label.add': 'Add exchange rate',
    'companyExchangeRates.modal.save.label.edit': 'Update exchange rate',
    'companyExchangeRates.modal.select.error.message': 'Please select a valid currency.',
    'companyExchangeRates.modal.select.placeholder': 'Enter a currency code (ex: USD)',
    'companyExchangeRates.modal.select.label.from': 'From',
    'companyExchangeRates.modal.select.label.to': 'To',
    'companyExchangeRates.modal.input.label.rate': 'Rate',
    'companyExchangeRates.modal.input.label.startDate': 'Starting date',
    'companyExchangeRates.modal.rate.error.message': 'Please enter a rate.',
    'companyExchangeRates.modal.input.placeholder.rate': 'Enter a rate',
    'companyExchangeRates.modal.startDate.error.message': 'Please select a starting date.',
    'companyExchangeRates.modal.alert.label': '1 {{from}} would be {{rate}} {{to}}',
    'companyExchangeRates.modal.alert.error.label': 'Please enter an exchange rate.',
    'companyExchangeRates.history.modal.title': 'FX Rate History',
    'companyExchangeRates.history.modal.table.date': 'Start Date',
    'companyExchangeRates.history.modal.table.rate': 'Rate',
    'companyExchangeRates.history.modal.delete.popconfirm': 'Are you sure to remove this Fx Rate?',
    'comparatorPlanPeriodSelector.noPeriodsInPlan': 'No periods available in this plan',
    'comparatorPlanPeriodSelector.planSelect.placeholder': 'Select a plan for comparison',
    'comparatorPlanPeriodSelector.periodSelect.placeholder': 'Select a period for comparison',
    'comparatorPlanPeriodSelector.previewProjection.title': 'Projection',
    'comparatorPlanResults.commissions': 'Commissions {{comparedPlanName}}',
    'comparatorPlanResults.diff': 'Diff',
    'comparatorPlanResults.table.headers.users': 'Users',
    conditions_zero: 'condition',
    conditions_one: 'condition',
    conditions_other: 'conditions',
    conditionsWithCount_zero: '{{count}} $t(conditions_zero)',
    conditionsWithCount_one: '{{count}} $t(conditions_one)',
    conditionsWithCount_other: '{{count}} $t(conditions_other)',
    'commissionList.page.title': 'Commission list',
    'commissionList.page.subtitle': 'Define the commission you\'re looking for',
    'commissionList.table.header.name': 'Name',
    'commissionList.table.header.user': 'User',
    'commissionList.table.header.date': 'Date',
    'commissionList.table.header.type': 'Type',
    'commissionList.table.header.commissions': 'Commissions',
    'commissionList.table.header.payout': 'Payout',
    'commissionList.table.header.status': 'Status',
    'commissionList.status.tag.concluded': 'Concluded',
    'commissionList.status.tag.partially_released': 'Partially released',
    'commissionList.status.tag.on_hold': 'On hold',
    'commissionList.actions.button.view_details': 'View details',
    'commissionList.resourceSearch.placeholder': 'Search by resource name',
    'commissionList.resourceType.placeholder': 'Select a resource type',
    'commissionList.plan.placeholder': 'Select a plan',
    'commissionList.statuses.placeholder': 'Select a status',
    'commissionList.statuses.option.concluded': 'Concluded',
    'commissionList.statuses.option.partially_released': 'Partially released',
    'commissionList.statuses.option.on_hold': 'On hold',
    'commissionList.statuses.option.partially_released_and_on_hold': 'Partially released <br />and on hold',
    'commissionList.sortBy.option.date_asc': 'Ascending date',
    'commissionList.sortBy.option.date_desc': 'Descending date',
    'commissionList.sortBy.option.unpaid_percentage': 'Payout amount remaining',
    'commissionList.sortBy.placeholder': 'Sort by',
    'commissionList.currency.placeholder': 'Currency',
    'commissionList.clearFilters.label': 'Reset filters',
    'commissionList.clearFilters.modal.title': 'Are you sure you want to reset your filters?',
    'commissionList.clearFilters.modal.btn.confirm': 'Reset filters',
    'commissionList.emptyStates.no_results_found': 'No results found for this search.',
    'commissionList.emptyStates.all_deals_concluded': 'All your deals are concluded!',
    'commissionList.emptyStates.no_commission_to_list': 'No commission to list.',
    'commissionList.actions.exportSelection': 'Export this selection',
    'commissionsDueEstimateWidgetContent.description': 'All the commissions which are scheduled to be paid',
    'commissionsUnreleasedWidgetContent.description': 'On-hold payments or waiting to be released',
    'commissionsUnreleasedWidgetContent.viewDetails': 'View details',
    'commissionsTotalWidgetContent.totalDeals': '{{total}} {{dealsType}}',
    'commissionsWithoutPaymentLink.label': 'View commissions without any payment scheduled',
    'common.statementOneMonthWidget.title': 'My Current Statement',
    'common.statementOneMonthWidget.button': 'View statement details',
    'common.statementPerMonthWidget.title': 'Statements',
    'common.dueEstimateWidget.title': 'Commissions due',
    'common.dueEstimateWidget.subtitle': 'Estimate of commissions due from {{periodName}}',
    'common.listPlans.title': 'My Current Plans',
    'common.listPlans.secondTitle': 'View inactive plans',
    'common.latestObjectsWidget.items': 'items',
    'common.latestObjectsWidget.title': 'My latest {{types}}',
    'common.latestObjectsWidget.table.headers.date': 'Date',
    'common.latestObjectsWidget.table.headers.plans': 'Plans',
    'common.latestObjectsWidget.table.headers.commissions': 'Commissions',
    'common.commissions_unreleased_list.title': 'Latest elements on-hold or partially paid',
    'common.commissionsUnreleasedWidget.title': 'Commission not released yet',
    'companyCurrencySelect.mixed': 'Mixed currency',
    'companyTimezone.no_default_value': 'No default timezone set',
    'companyTimezone.default_value': 'Timezone {{timezone}}',
    'companyTimezone.modal.title': 'Update Company Timezone',
    'companyTimezone.modal.select.label': 'Select a Timezone',
    'companyTimezone.modal.select.placeholder': 'Select a Timezone',
    'companyTimezone.modal.save.label': 'Save timezone',
    'companyTimezone.modal.disclaimer.content': 'Updating the company timezone can have a lot of impact on commission calculations. If you\'re not sure on how to update this, please contact support or CSM team.',
    'componentData.defaultDealType': 'element',
    'copyNotebookModal.copiedName': 'Copy of {{notebookName}}',
    'copyNotebookModal.title': 'Duplicate model',
    'copyNotebookModal.copy': 'Duplicate',
    'copyNotebookModal.form.name.label': 'Model Name',
    'copyNotebookModal.form.name.rules.required': 'Please name your Model.',
    'correctionSuggestedAmount.paidAmount.label': 'Marked as paid',
    'correctionSuggestedAmount.lastAmount.label': 'Last Statement Amount',
    'correctionSuggestedAmount.newAmount.label': 'New Statement Amount',
    'correctionSuggestedAmount.diffAmount.label': 'Difference',
    'createPlanFlowCommissionsResults.viewResults.noDealsFound': 'No {{dealTypes}} found.',
    'createPlanFlowCommissionsResults.viewResultsBtn.label': 'View list',
    'createPlanFlowCommissionsResultsModal.title': 'Commissions Preview',
    'createPlanFlowCommissionsResultsModal.close': 'Close',
    'createPlanFlowDataFilteringStep.form.dateField.label': 'Date filter: How do you tell that a <mark>{{connectorName}} {{resourceType}}</mark> is included in <mark>{{periodName}}</mark>?',
    'createPlanFlowDataFilteringStep.form.filterObject.label': 'Additional filters: Do you want to filter <mark>{{connectorName}} {{resourceType}}</mark> having specific attributes?',
    'createPlanFlowDataSourceStep.form.connectorId.label': 'Select the data source to use',
    'createPlanFlowDataSourceStep.form.tabs.connectors': 'Connectors',
    'createPlanFlowDataSourceStep.form.tabs.notebooks': 'Models',
    'createPlanFlowDataSourceStep.form.type.label': 'Resource from <mark>{{connectorName}}</mark> that you want to track',
    'createPlanFlowDataSourceStep.form.matching.label': 'How do you tell if a <mark>{{dealType}}</mark> belongs to a user?',
    'createPlanFlowDataSourceStep.form.matchingUGByU.label': 'Define the id of a <mark>{{dealType}}</mark>',
    'createPlanFlowDataSourceStep.form.shouldMatch': 'Should Match',
    'createPlanFlowDealsResults.viewResults.dealsFound': '{{total}} {{dealType}} found',
    'createPlanFlowDealsResults.viewResults.noDealsFound': 'No {{dealTypes}} found.',
    'createPlanFlowDealsResults.viewResultsBtn.label': 'View list',
    'createPlanFlowDealsResultsModal.title': '{{dealType}} list ({{total}})',
    'createPlanFlowDealsResultsModal.close': 'Close',
    'createPlanFlowPage.title': 'Create a new Plan',
    'createPlanFlowPreviewCommissions.testBtn.label': 'Preview commissions for {{period}}',
    'createPlanFlowPreviewPayoutSchedule.testBtn.label': 'Preview payout schedule',
    'createPlanFlowPreviewPayoutSchedule.testBtn.refresh.label': 'Refresh preview payout schedule',
    'createPlanFlowPreviewPayoutSchedule.preview.title': 'Payments',
    'createPlanFlowQuotaSetupStep.disclaimer.noQuota.description': 'Do you want to define a Quota for this plan?',
    'createPlanFlowQuotaSetupStep.disclaimer.noQuota.defineQuotaCTA': 'Define a Quota',
    'createPlanFlowQuotaSetupStep.disclaimer.noQuota.discard': 'No, there is no Quota for this plan',
    'createPlanFlowQuotaSetupStep.quotaSetup.cancelSetup': 'No, in final there is no Quota for this plan',
    'createPlanFlowSelectUsersStep.addManagers.title': 'Select users earning commissions in this plan (usually managers)',
    'createPlanFlowSelectUsersStep.addManagers.subTitle': 'We\'ll just add them into the commission plan, you\'ll be able to invite them into Palette later on.',
    'createPlanFlowSelectUsersStep.addManagers.empty': 'No managers in this plan yet',
    'createPlanFlowSelectUsersStep.addManagers.addUsersBtnLabel_zero': 'No new selected manager',
    'createPlanFlowSelectUsersStep.addManagers.addUsersBtnLabel_one': 'Add the selected manager',
    'createPlanFlowSelectUsersStep.addManagers.addUsersBtnLabel_other': 'Add the {{count}} selected managers',
    'createPlanFlowSelectUsersStep.addManagers.managersInPlan_zero': 'Managers in the plan',
    'createPlanFlowSelectUsersStep.addManagers.managersInPlan_one': 'Manager in the plan',
    'createPlanFlowSelectUsersStep.addManagers.managersInPlan_other': 'Managers in the plan ({{count}})',
    'createPlanFlowSelectUsersStep.addUsers.title': 'Select users to calculate commissions on',
    'createPlanFlowSelectUsersStep.addUsers.subTitle': 'We\'ll just add them into the commission plan, you\'ll be able to invite them into Palette later on.',
    'createPlanFlowSelectUsersStep.addUsers.empty': 'No users in this plan yet',
    'createPlanFlowSelectUsersStep.addUsers.addUsersBtnLabel_zero': 'No new selected user',
    'createPlanFlowSelectUsersStep.addUsers.addUsersBtnLabel_one': 'Add the selected user',
    'createPlanFlowSelectUsersStep.addUsers.addUsersBtnLabel_other': 'Add the {{count}} selected users',
    'createPlanFlowSelectUsersStep.addUsers.usersInPlan_zero': 'Users in the plan',
    'createPlanFlowSelectUsersStep.addUsers.usersInPlan_one': 'User in the plan',
    'createPlanFlowSelectUsersStep.addUsers.usersInPlan_other': 'Users in the plan ({{count}})',
    'createPlanFlowStep.back': 'Back to {{previousStep}}',
    'createPlanFlowStep.next': 'Next',
    'createPlanFlowStep.skip': 'Skip',
    'createPlanFlowStep.finish': 'Finish',
    'createPlanFlowSteps.title': 'Creation Steps',
    'createPlanFlowSteps.step_general.label': 'General Settings',
    'createPlanFlowSteps.step_general.subTitle': 'Setup general settings of your compensation plan.',
    'createPlanFlowSteps.select_users.label': 'Select Users',
    'createPlanFlowSteps.select_users.subTitle': 'Define who is part of the team for this compensation plan.',
    'createPlanFlowSteps.data_source.label': 'Data Source',
    'createPlanFlowSteps.data_source.subTitle': 'In this section you can select a data source to use for computing compensations on.',
    'createPlanFlowSteps.data_filtering.label': 'Data Filtering',
    'createPlanFlowSteps.data_filtering.subTitle': 'You may want to select only {{dealType}} with specific attributes.',
    'createPlanFlowSteps.quota_setup.label': 'Quota Setup',
    'createPlanFlowSteps.quota_setup.subTitle': 'Setup the Quota for the reps.',
    'createPlanFlowSteps.quota_attainment.label': 'Quota Attainment',
    'createPlanFlowSteps.quota_attainment.subTitle': 'Define the value of each {{dealType}}.',
    'createPlanFlowSteps.commission_rules.label': 'Commission Rules',
    'createPlanFlowSteps.commission_rules.subTitle': 'Define how commissions are calculated.',
    'createPlanFlowSteps.payout_schedule.label': 'Payout Schedule',
    'createPlanFlowSteps.payout_schedule.subTitle': 'Define when to pay the commissions. You can schedule payments based on certain fields.',
    'createPlanFlowSteps.summary.label': 'Summary',
    'createPlanFlowSteps.summary.subTitle': 'Here you can find a summary of your plan configuration.',
    'createPlanFlowSummaryStep.firstPeriod': 'First period',
    'createPlanFlowSummaryStep.lastPeriod': 'End period',
    'createPlanFlowSummaryStep.general': 'General',
    'createPlanFlowSummaryStep.users': 'Users',
    'createPlanFlowSummaryStep.dataSource': 'Data Source',
    'createPlanFlowSummaryStep.quota': 'Quota',
    'createPlanFlowSummaryStep.quota.linked': 'A Quota is linked to this plan',
    'createPlanFlowSummaryStep.quota.noQuota': 'No quota defined for this plan',
    'createPlanFlowSummaryStep.quota.view': 'View Quota',
    'createPlanFlowSummaryStep.commissionRules': 'Commission Rules',
    'createPlanFlowSummaryStep.payoutRules': 'Payout Schedule',
    'createPlanFlowSummaryStep.noRules': 'No rules are defined for this plan',
    'createPlanFlowSummaryStep.noRules.because.noQuota': 'No rules are defined because no quota is defined for this plan',
    'createPlanFlowSummaryStep.noRules.because.noCommission': 'No rules are defined because no commission is defined for this plan',
    'createPlanFlowSummaryStep.planName': 'Plan\'s Name',
    'createPlanFlowSummaryStep.planType': 'Plan Type',
    'createPlanFlowSummaryStep.tracking': 'Tracking',
    'createPlanFlowSummaryStep.numberUsers': 'Number of users',
    'createPlanFlowSummaryStep.numberManagers': 'Number of managers',
    'createPlanFlowTestFilters.testBtn.label': 'Test Filters',
    'createPlanFlowTestFilters.neverRun.description': 'Number of {{dealType}} found will be displayed here.',
    'createVariableModal.createNewVariable': 'New User\'s Variable',
    'createVariableModal.createVariable': 'Create variable',
    'createVariableModal.form.name.label': 'Variable name',
    'createVariableModal.form.name.rules.required': 'Please name your variable with a valid variable name (no special character, e.g.: my_field_name).',
    'createVariableModal.form.name.rules.alreadyExists': 'A variable with the same name already exists.',
    'createVariableModal.form.variableType.label': 'Variable type',
    'createVariableModal.form.variableDefaultValue.label': 'What\'s the default value of the variable?',
    'createVariableModal.form.variableDefaultValue.rules.required': 'Please define a default value for this variable.',
    'dashboard.errors.plan.not.found': 'The plan was not found.',
    'dashboardPreset.errors.preset.not.found': 'The dashboard was not found.',
    'dashboardPreset.errors.plan.not.found': 'The plan was not found.',
    'dashboardPreset.errors.object.not.found': 'The user\'s datas were not found.',
    'dashboardPreset.errors.user.not.found': 'The user was not found.',
    'dashboardPreset.errors.preset.default.cannot.delete': 'You cannot delete the default Dashboard.',
    'dashboardPreset.applyDashboardPresetToUsers': 'Select users and apply Dashboard',
    'dashboardPreset.deleteDashboardPreset': 'Delete Dashboard',
    'dashboardPresetsListItem.components_zero': 'No components',
    'dashboardPresetsListItem.components_one': '{{count}} component',
    'dashboardPresetsListItem.components_other': '{{count}} components',
    'dashboardPresetsListItemMoreOptionsMenu.deleteDashboardPreset': 'Delete dashboard',
    'dashboardPresetsPage.page.title': 'Dashboard Presets',
    'dashboardPresetDetailsPageContent.widgets.title': 'Widgets ({{count}})',
    'dashboardPresetDetailsPageContent.preview.title': 'Dashboard preview',
    'dashboardPresetDetailsPageContent.preview.alert.message': 'Select a relevant user for the widgets',
    'dashboardPresetDetailsPageContent.preview.alert.description': 'Some users may not be relevant for a given Dashboard. Think about changing the selected user to match with the team you\'re designing the dashboard for.',
    'dashboardPresetDetailsPageContent.widgets.addWidget': 'Add a widget',
    'dataConnectionConnectorPreview.previewData': 'Preview data',
    day_zero: 'days',
    day_one: 'day',
    day_other: 'days',
    'deactivateUserButton.label': 'Deactivate user',
    'deactivateUserModal.title': 'Deactivate user',
    'deactivateUserModal.confirm': 'Deactivate',
    'deactivateUserModal.content': 'Deactivation will remove user\'s account. Do you confirm you want to deactivate {{displayName}}?',
    deleteConditionsWithCount_zero: 'Delete {{count}} $t(conditions_zero)',
    deleteConditionsWithCount_one: 'Delete {{count}} $t(conditions_one)',
    deleteConditionsWithCount_other: 'Delete {{count}} $t(conditions_other)',
    'deleteDashboardPresetModal.title': 'You\'re about to delete {{presetName}} Dashboard.',
    'deleteDashboardPresetModal.confirm': 'I confirm',
    'deleteDashboardPresetModal.content': 'Please confirm that you want to delete this Dashboard. Users will not lose their Dashboard, you\'ll just lose the template for newcomers.',
    'deleteMasterPlanModal.title': 'Delete Plan',
    'deleteMasterPlanModal.confirm': 'I confirm',
    'deleteMasterPlanModal.content': 'Do you confirm you want to delete <strong>{{planName}}</strong>?',
    'deleteNotebookModal.title': 'Delete Model',
    'deleteNotebookModal.confirm': 'I confirm',
    'deleteNotebookModal.content': 'Do you confirm you want to delete <strong>{{notebookName}}</strong>?',
    'deleteNotebookCellModal.title': 'Delete Cell',
    'deleteNotebookCellModal.confirm': 'I confirm',
    'deleteNotebookCellModal.content': 'Do you confirm you want to delete <strong>{{cellName}}</strong> from <strong>{{notebookName}}</strong>?',
    'deleteRoleModal.title': 'You\'re about to delete {{roleName}}.',
    'deleteRoleModal.confirm': 'I confirm',
    'deleteRoleModal.content': 'Do you confirm you want to delete role <strong>{{roleName}}</strong>?',
    'deleteTeamModal.title': 'Delete Team',
    'deleteTeamModal.confirm': 'I confirm',
    'deleteTeamModal.content': 'Do you confirm you want to delete <strong>{{teamName}}</strong>?',
    'deleteVariableValueHistoryItem.deleteItem.popconfirm': 'Do you confirm you want to delete this update from history?',
    'drawerVariableEdition.placeholders.variableName': 'Variable name',
    'drawerVariableEdition.placeholders.fields': 'Enter fields',
    'duplicateMasterPlanModal.title': 'Duplicate an existing Plan',
    'duplicateMasterPlanModal.duplicate': 'Duplicate',
    'duplicateMasterPlanModal.copyOf': 'Copy of',
    'duplicateMasterPlanModal.form.planId.label': 'Choose a Plan to duplicate',
    'duplicateMasterPlanModal.form.keepUsers.label': 'Keep existing users in the new plan',
    'duplicateMasterPlanModal.form.terminateParent.label': 'Terminate {{name}} at the end of {{endPeriod}}',
    'duplicateMasterPlanModal.goalLabel': 'Goal of the duplicated plan',
    'duplicateMasterPlanModal.goal.newPlan.label': 'Create a<br />new plan',
    'duplicateMasterPlanModal.goal.newPlan.description': 'Based on existing settings',
    'duplicateMasterPlanModal.goal.newVersion.label': 'Create a<br />new version',
    'duplicateMasterPlanModal.goal.newVersion.description': 'I want to do a new version of the original plan',
    'duplicateMasterPlanSelect.placeholder': 'Choose a Plan to duplicate',
    'editableNotebookDescription.emptyDescription': 'No description',
    'editColumnCustomDisplayModal.title': 'Custom display function',
    'editColumnCustomDisplayModal.validate': 'Validate',
    'editColumnCustomDisplayModal.form.formula.label': 'Display function',
    'editColumnCustomDisplayModal.form.formula.description': 'Following fields you chose can be used in the function: <strong>{{fields}}</strong>',
    'editCommissionAmountModal.commissionFor': 'Commission for {{dealName}}',
    'editCommissionAmountModal.commission': 'Commission',
    'editCommissionAmountModal.overwriteAmount': 'Overwrite amount',
    'editCommissionAmountModal.form.amount.label': 'Amount',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.title': 'Are you sure to reset commission to its original amount?',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.confirm': 'Yes',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.cancel': 'No',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.cta': 'Reset to original amount ({{amount}})',
    'editCommissionAmountModal.paymentsDone.alert.message': '{{percentage}}% ({{amount}}) of this commission was already paid.',
    'editCommissionValueModal.labelDefinitionFor': '{{labelDefinition}} for {{dealName}}',
    'editCommissionValueModal.overwriteValue': 'Overwrite value',
    'editCommissionValueModal.form.amount.label': 'Value',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.title': 'Are you sure to reset commission to its original value?',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.confirm': 'Yes',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.cancel': 'No',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.cta': 'Reset to original value ({{value}})',
    'editDataConnectionConnectorModal.title': 'Edit imported data',
    'editDataConnectionConnectorModal.update': 'Update data source',
    'editDefaultValuesPanel.saveDefaultValue': 'Save',
    'editDefaultValuesPanel.startingDate': 'Starting date',
    'editDefaultValuesPanel.defaultValue': 'Default value',
    'editDefaultValuesPanel.addNewDefaultValue': 'Add a new default value',
    'editDefaultValuesPanel.alert.noDefaultValues.message': 'No default values',
    'editableRoleDescription.noRoleDescription': 'No role description',
    'editOnboardUserButton.editUserData': 'Edit user data',
    'editOnboardUserModal.title': 'Edit user data',
    'editOnboardUserModal.confirm': 'Update',
    'editVariableCustomDisplayModal.title': 'Custom display function',
    'editVariableCustomDisplayModal.validate': 'Validate',
    'editVariableCustomDisplayModal.form.formula.label': 'Display function',
    'editVariableCustomDisplayModal.form.formula.description': 'Following fields you chose can be used in the function: <strong>{{fields}}</strong>',
    'editVariableValueModal.editVariableModalTitle': 'Edit Variable: {{variableName}}',
    'editVariableValueModal.editVariable': 'Edit Variable',
    'editVariableValueModal.form.value.label': 'What\'s the new value for this variable?',
    'editVariableValueModal.form.dateValue.label': 'What\'s the new date for this variable (in UTC+0)?',
    'editVariableValueModal.form.value.rules.required': 'Please define the new value for this variable.',
    'editVariableValueModal.form.date.label': 'Apply this new value from',
    'editVariableValueModal.form.date.rules.required': 'Please select a valid date.',
    'editVariableValueModal.editVariableModalSubTitle.multiUsers': 'For {{count}} users',
    'editVariableValueModal.editVariableModalSubTitle.singleUser': 'For {{userDisplayName}}',
    'emailNotifications.block.title': 'Email Notifications',
    'emailNotifications.content.text.intro': 'Manage if you want to receive email notifications.',
    'emailPasswordForm.form.email.label': 'Email',
    'emailPasswordForm.form.email.rules.required': 'Please enter your email.',
    'emailPasswordForm.form.email.placeholder': 'Your email',
    'emailPasswordForm.form.password.label': 'Password',
    'emailPasswordForm.form.password.rules.required': 'Please enter your password.',
    'emailPasswordForm.form.password.placeholder': 'Your password',
    'emailPasswordForm.form.submit': 'Login',
    'forgotPasswordForm.form.email.label': 'Email',
    'forgotPasswordForm.form.email.rules.required': 'Please enter your email.',
    'forgotPasswordForm.form.email.placeholder': 'Your email',
    'forgotPasswordForm.form.submit': 'I forgot my password',
    'forgotPasswordPageContent.title': 'Forgot Password?',
    'forgotPasswordPageContent.login': 'Login',
    'statementValidations.content.switch.weekly': 'Weekly commission digest',
    'statementValidations.content.switch.monthly': 'Monthly commission digest',
    'statementValidations.content.switch.threads': 'New comments on threads',
    'statementValidations.content.title.authorization': 'Authorize email digests for reps',
    'statementValidations.content.text.authorization': 'Reps can receive weekly and monthly digests about their commissions. They can manage their preferences in their settings if you authorize the emails. They won\'t have emails nor see the options if you disable.',
    'statementValidations.content.switch.authorization': 'Authorize commission digest',
    'statementValidations.content.switch.newStatement': 'New statement validated',
    'fastOnboardLink.label': 'I don\'t find my users.',
    'fastOnboardModal.title': 'Fast onboarding',
    'fastOnboardModal.description': 'Select the users you want to fast onboard before to be able to add it in your plan or quota. Since it\'s an accelerated onboarding process, you will probably need to update users information to complete their onboarding.',
    'fastOnboardModal.continue': 'Continue',
    'fastOnboardModal.onboardUsers': 'Onboard users',
    'fiscalYear.FY': 'FY',
    'formulaHelper.formulaHelperTitle': 'Formula helper',
    'folder.errors.delete.not.empty': 'Couldn\'t delete this folder as it contains plans',
    'folder.errors.cannot.be.self.parent': 'The folder cannot be self parent.',
    'folder.errors.folder.not.found': 'The folder was not found.',
    'folder.errors.parent.not.found': 'The folder\'s parent was not found.',
    'folders.modals.folderName': 'Folder name',
    'folders.modals.rules.folderName': 'Please enter a folder name.',
    'folders.modals.add.confirm': 'Create folder',
    'folders.modals.edit.confirm': 'Rename folder',
    'folders.modals.add.title': 'Create a new folder',
    'folders.modals.edit.title': 'Rename "{{name}}" folder',
    'folders.modals.defaultName': 'Untitled Folder',
    'folder.actions.rename.tooltip': 'Rename folder',
    'folder.actions.delete.tooltip': 'Delete folder',
    'folder.actions.delete.disabled.tooltip': 'Cannot delete this folder as it\'s not empty',
    'folder.actions.delete.popconfirm': 'Are you sure to delete this folder?',
    'frequency.monthly': 'Monthly',
    'frequency.month': 'Month',
    'frequency.monthly.short.periods.0': 'Jan',
    'frequency.monthly.short.periods.1': 'Feb',
    'frequency.monthly.short.periods.2': 'Mar',
    'frequency.monthly.short.periods.3': 'Apr',
    'frequency.monthly.short.periods.4': 'May',
    'frequency.monthly.short.periods.5': 'Jun',
    'frequency.monthly.short.periods.6': 'Jul',
    'frequency.monthly.short.periods.7': 'Aug',
    'frequency.monthly.short.periods.8': 'Sep',
    'frequency.monthly.short.periods.9': 'Oct',
    'frequency.monthly.short.periods.10': 'Nov',
    'frequency.monthly.short.periods.11': 'Dec',
    'frequency.monthly.periods.0': 'January',
    'frequency.monthly.periods.1': 'February',
    'frequency.monthly.periods.2': 'March',
    'frequency.monthly.periods.3': 'April',
    'frequency.monthly.periods.4': 'May',
    'frequency.monthly.periods.5': 'June',
    'frequency.monthly.periods.6': 'July',
    'frequency.monthly.periods.7': 'August',
    'frequency.monthly.periods.8': 'September',
    'frequency.monthly.periods.9': 'October',
    'frequency.monthly.periods.10': 'November',
    'frequency.monthly.periods.11': 'December',
    'frequency.bimonthly': 'Bi-Monthly',
    'frequency.bimonth': 'Bi-Month',
    'frequency.bimonthly.periods.0': 'Jan-Feb',
    'frequency.bimonthly.periods.1': 'Mar-Apr',
    'frequency.bimonthly.periods.2': 'May-Jun',
    'frequency.bimonthly.periods.3': 'Jul-Aug',
    'frequency.bimonthly.periods.4': 'Sep-Oct',
    'frequency.bimonthly.periods.5': 'Nov-Dec',
    'frequency.quarterly': 'Quarterly',
    'frequency.quarter': 'Quarter',
    'frequency.quarterly.periods.0': 'Q1',
    'frequency.quarterly.periods.1': 'Q2',
    'frequency.quarterly.periods.2': 'Q3',
    'frequency.quarterly.periods.3': 'Q4',
    'frequency.fourMonthsPeriod': 'Four Months Period',
    'frequency.fourMonths': 'Four Months',
    'frequency.fourMonthsPeriod.periods.0': 'Jan-Apr',
    'frequency.fourMonthsPeriod.periods.1': 'May-Aug',
    'frequency.fourMonthsPeriod.periods.2': 'Sep-Dec',
    'frequency.halfYearly': 'Semesterly',
    'frequency.halfYear': 'Semester',
    'frequency.halfYearly.periods.0': 'H1',
    'frequency.halfYearly.periods.1': 'H2',
    'frequency.yearly': 'Yearly',
    'frequency.year': 'Year',
    'frequency.yearly.periods.0': 'Year',
    'frequency.day': 'Every X days',
    'frequency.day.weekly': 'Weekly',
    'frequency.day.every': 'Every {{frequency}} $t(day, {"count": {{frequency}} })',
    'frequency.custom': 'Custom dates',
    'generateWithAIButton.label': 'Generate with AI',
    'generateWithAIModal.title': 'Generate with AI',
    'generateWithAIModal.generate': 'Generate',
    'generateWithAIModal.form.prompt.label': 'Prompt',
    'generateWithAIModal.form.prompt.rules.required': 'Please fill a prompt',
    'global.error.unknown': 'An error occurred.',
    'global.error.bad.request': 'The server cannot process the request.',
    'global.error.constraint.violation': 'Constraint violation error',
    'global.error.constraint.violation.content': 'One or multiple constraint violations occurred.',
    'global.error.constraint.violation.list': '- Violation on <strong>{{field}}</strong>: <strong>{{errorMessage}}</strong>;',
    'global.error.unauthorized': 'You are not authorized to perform this action.',
    'global.error.invalid.session': 'Your session is invalid.',
    'global.error.not.found': 'The resource was not found.',
    'global.error.method.not.allowed': 'This request method is not allowed.',
    'global.error.server.error': 'A server error occurred.',
    'global.error.maintenance.enabled': 'A maintenance is ongoing. We will be back soon!',
    'googleAuthButton.error': 'An error occurred during authentication with Google',
    'googleAuthButton.signIn': 'Sign in with Google',
    'highlightCommissionsDueEstimateWidget.title': 'Future payout',
    'highlightCommissionsDueEstimateWidget.subtitle': 'From {{periodName}}',
    'highlightCommissionsTotalWidget.title': 'Total commissions',
    'highlightCommissionsUnreleasedWidget.title': 'Commissions without any payment scheduled',
    'highlightzone.widgets.filters': 'Filter widgets',
    'highlightzone.widgets.filtersClose': 'Close to update',
    'icMenu.title': 'Private',
    'icMenu.myDashboard': 'My Dashboard',
    'icMenu.myPlans': 'My Plans',
    'icMenu.myStatements': 'Statements',
    'icMenu.myCommissions': 'My Commissions',
    'icMenu.myCommissionList': 'Commission list',
    'icMenu.notifications': 'Notifications',
    'inBulkEditVariableValue.inBulkEdit': 'Edit for all selected users',
    'inputTable.columnType': 'Type',
    'inputTable.columnType.string': 'String',
    'inputTable.columnType.number': 'Number',
    'inputTable.columnType.boolean': 'Boolean',
    'inputTable.copyColumnName': 'Copy column name',
    'inputTable.deleteColumn': 'Delete column',
    'inputTable.editColumnName': 'Rename column',
    'inputTable.deleteRow': 'Delete row',
    'inputTableNewColumnModal.title.newColumn': 'New column',
    'inputTableNewColumnModal.addNewColumn': 'Add column',
    'inputTableNewColumnModal.form.name.label': 'Column\'s name',
    'inputTableNewColumnModal.form.name.rules.required': 'Please fill a name for the new column (a valid variable name with no special character, e.g.: my_field_name)',
    'inputTableNewColumnModal.form.name.rules.alreadyExists': 'A column with the same name already exists.',
    'inputTableEditColumnModal.title.editColumn': 'Edit column',
    'inputTableEditColumnModal.updateColumn': 'Update column',
    'inputTableEditColumnModal.form.name.label': 'Column\'s name',
    'inputTableEditColumnModal.form.name.rules.required': 'Please fill a name for the new column (a valid variable name with no special character, e.g.: my_field_name)',
    'inputTableEditColumnModal.form.name.rules.alreadyExists': 'A column with the same name already exists.',
    'inviteUserLink.label': 'Invite user',
    'inviteUserModal.title': 'Invite {{userDisplayName}}',
    'inviteUserModal.confirm': 'Invite',
    'inviteUserModal.form.email.label': 'Email',
    'inviteUserModal.form.email.rules.required': 'Please fill an email.',
    'inviteUserModal.form.email.rules.email': 'Please provide a valid email address.',
    'inviteUserModal.form.role.label': 'Role',
    'inviteUserModal.form.hireDate.label': 'Set a hire date?',
    'inviteUserModal.form.teams.label': 'Select teams',
    'inviteUserModal.form.dashboardDesign.label': 'Dashboard design',
    loading: 'Loading',
    'linkResourcesToUser.label': 'Link Resources',
    'linkResourcesToUserModal.title': 'Link Resources to {{displayName}}',
    'linkResourcesToUserModal.linkResources': 'Link Resources',
    'listPlanItem.title': 'Commissions',
    'listPlanItem.viewType': 'View {{type}}',
    'listPlanItem.viewPlanDetails': 'View plan details',
    'loginPageContent.forgotPassword': 'Forgot password?',
    'loginPageContent.intermediaryTitle': 'Or sign in with email',
    'loginPageContent.title': 'Login',
    'loginPageContent.noAccountYet': 'I don\'t have an account yet',
    'maintenance.description.title': 'Palette is under maintenance',
    'maintenance.description.subtitle': 'We\'ll be back in a few hours',
    'masterPlan.frequency.monthly': 'Monthly',
    'masterPlan.frequency.bimonthly': 'Bi-Monthly',
    'masterPlan.frequency.quarterly': 'Quarterly',
    'masterPlan.frequency.fourMonthsPeriod': 'Four Months Period',
    'masterPlan.frequency.halfYearly': 'Semesterly',
    'masterPlan.frequency.yearly': 'Yearly',
    'masterPlanDependencies.title': 'Dependencies',
    'masterPlanDependencyItem.options_one': 'Option: ',
    'masterPlanDependencyItem.options_other': 'Options: ',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.auto': 'Auto (Best matching period)',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.relative': 'Relative period',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.absolute': 'Fixed period',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.formula': 'Formula',
    'masterPlanDependencyItem.relativePeriod': 'P{{relativePeriod}} (Considering current plan\'s last period is {{lastPeriodName}}, period {{relativePeriodName}} will be injected)',
    'masterPlanDependencyForm.alerts.planIsInFuture': 'The selected plan has not started yet.',
    'masterPlanDependencyForm.alerts.planIsInPast': 'The selected plan is already ended.',
    'masterPlanDependencyForm.title': 'Add a dependency',
    'masterPlanDependencyForm.form.name.label': 'Dependency name',
    'masterPlanDependencyForm.form.name.rules.required': 'Please name your dependency.',
    'masterPlanDependencyForm.form.name.rules.pattern': 'Please name your dependency with a valid variable name (no special character, e.g.: my_field_name).',
    'masterPlanDependencyForm.form.name.placeholder': 'Ex: myInjectedPlan1',
    'masterPlanDependencyForm.form.plan.label': 'Plan',
    'masterPlanDependencyForm.form.plan.rules.required': 'Please a choose a plan',
    'masterPlanDependencyForm.form.periodMatchType.label': 'Period Match type',
    'masterPlanDependencyForm.form.absolutePeriod.label': 'Choose a fixed period',
    'masterPlanDependencyForm.form.absolutePeriod.rules.required': 'Please choose a period',
    'masterPlanDependencyForm.form.relativePeriod.label': 'Choose a relative period',
    'masterPlanDependencyForm.form.relativePeriod.description': 'Examples are given relatively to the last period of current plan ({{lastPeriodName}})',
    'masterPlanDependencyForm.form.relativePeriod.rules.required': 'Please choose a period',
    'masterPlanDependencyForm.form.relativePeriod.rules.isNegative': 'Please fill with a negative number',
    'masterPlanDependencyForm.form.relativePeriod.inThePast': 'In the past',
    'masterPlanDependencyForm.form.relativePeriod.inTheFuture': 'In the future',
    'masterPlanDependencyForm.form.submitBtnLabel': 'Save',
    'masterPlanDependencyForm.deleteAction.description': 'Do you want to remove this dependency?',
    'masterPlanDependencyForm.deleteAction.btnLabel': 'Delete',
    'masterPlanDependencyForm.cancelAction.btnLabel': 'Cancel',
    'masterPlanDependencyForm.addDependencyOptions': 'Dependency options',
    'masterPlanDependencyForm.form.withDataPoints.label': 'Progress history',
    'masterPlanDependencyForm.form.withDataPoints.description': 'Choose whether to include the progress history in the dependency or not. Note that this is not checked by default because it consumes a lot of resources and has to be included only if needed. You could need that when trying to check when a user (in the dependency plan) passed a specific progress / quota achievement.',
    'masterPlanDependencyForm.form.withPayments.label': 'Payment List',
    'masterPlanDependencyForm.form.withPayments.description': 'Choose whether to include the full payment list in the dependency or not. You could need that when trying to have the actual payouts amounts instead of the theoretical commission amounts (total commissions released/paid).',
    'masterPlanDependencyForm.form.type.label': 'Users to inject',
    'masterPlanDependencyForm.form.type.description': 'Choose whether to inject All users information or only the one that match with the given User',
    'masterPlanDependencyForm.injectionTypes.match_user': 'Match User',
    'masterPlanDependencyForm.injectionTypes.all_users': 'All users',
    'masterPlanDependencyForm.injectionPeriodMatchStrategies.auto': 'Auto (Best matching period)',
    'masterPlanDependencyForm.injectionPeriodMatchStrategies.relative': 'Choose a relative period',
    'masterPlanDependencyForm.injectionPeriodMatchStrategies.absolute': 'Choose a fixed period',
    'masterPlanFreezeType.all': 'Freeze all calculations and payout rules',
    'masterPlanFreezeType.rules_only': 'Freeze only commission calculations',
    'masterPlanHiddenInfo.description': 'This plan is hidden. It\'s not visible to the reps or in the statements. You can turn this plan visible in the',
    'masterPlanHiddenInfo.description.settings.label': 'General Settings',
    'masterPlan.lastPeriod.viewPeriod': 'View {{periodName}}',
    'masterPlan.lastPeriod.totalCommission': 'Commission ·',
    'masterPlan.moreMenu.deletePlan': 'Delete plan',
    'masterPlan.moreMenu.archivePlan': 'Archive plan',
    'masterPlan.moreMenu.duplicatePlan': 'Duplicate plan',
    'masterPlan.moreMenu.comparePlan': 'Compare plan',
    'masterPlan.seeLastPeriods': 'See last periods',
    'masterPlan.topActions.settings': 'Plan settings',
    'masterPlan.topActions.recompute': 'Refresh plan data',
    'masterPlan.scope.team': 'Team',
    'masterPlan.scope.manager': 'Manager',
    'masterPlan.scope.hidden': 'Hidden for Reps',
    'masterPlan.discardPlan': 'Discard plan',
    'masterPlanBreadcrumb.plans': 'Plans',
    'masterPlanList.empty.description': 'No Plan available yet',
    'masterPlanPeriodFreezeButton.freezeUntilPeriod': 'Freeze',
    'masterPlanPeriodFreezeButton.freezeUntilPeriod.tooltip': 'Click to freeze this period and all periods before that.',
    'masterPlanPeriodFreezeButton.freezeUntilPeriod.popconfirm': 'Are you sure to freeze this period and all periods before that?',
    'masterPlanPeriodFreezeButton.unfreezeFromPeriod': 'Un-Freeze',
    'masterPlanPeriodFreezeButton.unfreezeFromPeriod.tooltip': 'Click to un-freeze this period and all periods after that.',
    'masterPlanPeriodFreezeButton.unfreezeFromPeriod.popconfirm': 'Are you sure to un-freeze this period and all periods after that?',
    'masterPlanPeriodFreezeButton.autoFreezeEnabled': 'Auto freezed',
    'masterPlanPeriodProgress.elapsedTime': '{{percentage}}% Time elapsed',
    'masterPlanPeriodsListItem.commissions': 'Commissions',
    'masterPlanListItemFreezeButton.allPeriodsFrozen.tooltip': 'All past periods are frozen',
    'masterPlanListItemFreezeButton.freezeAllPeriods.tooltip': 'Click to freeze all past periods',
    'masterPlanListItemFreezeButton.freezeAllPeriods.popconfirm': 'Are you sure to freeze all past periods?',
    'masterPlanPeriodDealCommissionsTable.table.headers.user': 'User',
    'masterPlanPeriodDealCommissionsTable.table.headers.rule': 'Rule',
    'masterPlanPeriodDealCommissionsTable.ruleNumber': 'Rule n°{{index}}',
    'masterPlanPeriodDealCommissionsTable.table.headers.commission': 'Commission',
    'masterPlanPeriodUserCell.viewDeals': 'View {{dealType}}',
    'masterPlansComparatorPage.title': 'Plan Comparator',
    'masterPlansComparatorPage.subtitle': 'Easily compare plans and commissions',
    'masterPlansListSelect.past': 'Inactive Plans',
    'masterPlansListSelect.active': 'Active Plans',
    'masterPlansListSelect.archived': 'Archived Plans',
    'masterPlansListSelect.placeholder': 'Choose a Plan',
    'masterPlansList.drafts.alert.message': 'Draft plans are plans that were created but the configuration is not finished yet.',
    'masterPlanListDraftItem.continueStep': 'Continue setup',
    'masterPlanListDraftItem.step': 'Step',
    'masterPlans.errors.plan.not.found': 'The plan was not found.',
    'masterPlans.errors.connector.not.found': 'The connector was not found.',
    'masterPlans.errors.parentPlan.not.found': 'The plan\'s parent was not found.',
    'masterPlans.errors.seats.exhausted': 'You must add seats to your account in order to add users in this plan.',
    'masterPlans.errors.computation.running': 'Please wait before doing this action, the computation is running.',
    'masterPlans.errors.plan.is.injected': 'You cannot delete a plan which is injected elsewhere.',
    'masterPlans.errors.incompatible.plan.scope': 'The plan scope is different, please choose the same plan scope.',
    'masterPlansPage.title': 'Plans',
    'masterPlansPageContent.tabs.past': 'Inactive',
    'masterPlansPageContent.tabs.active': 'Active',
    'masterPlansPageContent.tabs.draft': 'Draft',
    'masterPlansPageContent.tabs.archived': 'Archived',
    'masterPlansPageContent.planSearch.placeholder': 'Search a plan',
    'masterPlansPageContent.tabs.compare': 'Compare plans',
    'masterPlansPageContent.tabs.createPlanFlow': 'Create a plan',
    'masterPlansPageContent.tabs.createNewFolder': 'Create a folder',
    'masterPlanPeriodDeals.actions.exportDeals': 'Export {{dealType}}',
    'masterPlanPeriodDeals.actions.editColumns': 'Edit columns',
    'masterPlanPeriodDeals.dealSearch.placeholder': 'Search a {{dealType}} name',
    'masterPlanPeriodDeals.filterByUser.placeholder': 'Select a user',
    'masterPlanPeriodDeals.filterByPayee.placeholder': 'Select a payee',
    'masterPlanPeriodDeals.table.headers.user': 'User',
    'masterPlanPeriodDeals.table.headers.date': 'Date',
    'masterPlanPeriodDeals.table.headers.commissions': 'Commissions',
    'masterPlanPeriodDeals.table.headers.payout': 'Payout',
    'masterPlanPeriodDeals.table.headers.progress': 'Progress',
    'masterPlanPeriodDeals.empty.description': 'No {{dealType}} available',
    'masterPlanPeriodDetailsPageContent.timeElapsed': 'Time elapsed',
    'masterPlanPeriodTabs.tabs.users': 'Users',
    'masterPlanPeriodTabs.tabs.managers': 'Managers',
    'masterPlanPeriodUsers.table.headers.users': 'Users',
    'masterPlanPeriodUsers.table.headers.target': 'Target',
    'masterPlanPeriodUsers.table.headers.totalValue': 'Total Value',
    'masterPlanPeriodUsers.table.headers.progress': 'Progress',
    'masterPlanPeriodUsers.table.headers.commissions': 'Commissions',
    'masterPlanPeriodUsers.userSearch.placeholder': 'Search a user',
    'masterPlanPeriodUsers.addUsers.label': 'Add Users',
    'masterPlanPeriodUsers.users.empty.description': 'No user found',
    'masterPlanPeriodUsers.managers.empty.description': 'No manager found',
    'masterPlanPeriodUsers.users.disclaimer_zero': 'users',
    'masterPlanPeriodUsers.users.disclaimer_one': 'user',
    'masterPlanPeriodUsers.users.disclaimer_other': 'users',
    'masterPlanPeriodUsers.users.disclaimerCount_zero': '{{count}} $t(masterPlanPeriodUsers.users.disclaimer_zero) are hidden because they are not in your scope.',
    'masterPlanPeriodUsers.users.disclaimerCount_one': '{{count}} $t(masterPlanPeriodUsers.users.disclaimer_one) is hidden because he is not in your scope.',
    'masterPlanPeriodUsers.users.disclaimerCount_other': '{{count}} $t(masterPlanPeriodUsers.users.disclaimer_other) are hidden because they are not in your scope.',
    'masterPlanPeriodUsers.managers.disclaimer_zero': 'managers',
    'masterPlanPeriodUsers.managers.disclaimer_one': 'manager',
    'masterPlanPeriodUsers.managers.disclaimer_other': 'managers',
    'masterPlanPeriodUsers.managers.disclaimerCount_zero': '{{count}} $t(masterPlanPeriodUsers.managers.disclaimer_zero) are hidden because they are not in your scope.',
    'masterPlanPeriodUsers.managers.disclaimerCount_one': '{{count}} $t(masterPlanPeriodUsers.managers.disclaimer_one) is hidden because he is not in your scope.',
    'masterPlanPeriodUsers.managers.disclaimerCount_other': '{{count}} $t(masterPlanPeriodUsers.managers.disclaimer_other) are hidden because they are not in your scope.',
    'masterPlanPeriodUsers.sortBy.placeholder': 'Order by',
    'masterPlanPeriodUsers.sortBy.alphabetical': 'Alphabetical',
    'masterPlanPeriodUsers.sortBy.progress': 'Progress',
    'masterPlanPeriodUsers.sortBy.commission': 'Commission',
    'masterPlanPeriodUsers.sortBy.target': 'Target',
    'masterPlanPeriodUsers.sortBy.value': '{{type}} value',
    'masterPlanPeriodsFilter.viewBy': 'View by',
    'masterPlanPeriodsFilter.quarter_to_date': 'Quarter to date',
    'masterPlanPeriodsFilter.year_to_date': 'Year to date',
    'masterPlanPeriodsFilter.past_6_months': 'Past 6 months',
    'masterPlanPeriodsFilter.all_times': 'All time',
    'masterPlanPeriodsFilter.custom': 'Custom',
    'masterPlanPeriodsList.currentPeriod': 'Current period',
    'masterPlanPeriodsList.lastPeriod': 'Last period',
    'masterPlanPeriodsList.pastPeriods': 'Past periods ({{count}})',
    'masterPlanPeriodsList.empty.description': 'No Period available yet',
    'masterPlanRecomputeWarning.title': 'Refresh data',
    'masterPlanRecomputeWarning.description': 'Important changes were made, please update the commissions by refreshing data.',
    'masterPlanRecomputeWarning.recompute': 'Refresh data',
    'masterPlanSettingsCommissionRules.configurationTitle': 'Configuration',
    'masterPlanSettingsCommissionRules.detailsTitle': 'Details',
    'masterPlanSettingsCommissionRules.noRulesText': 'No rules are defined for this plan.',
    'masterPlanSettingsCommissionRules.ruleTitle': 'Rule',
    'masterPlanSettingsCommissionRules.variablesTitle': 'Variables',
    'masterPlanSettingsCommissionRules.ofEach': 'of each',
    'masterPlanSettingsCommissionRules.moreThan': 'More than',
    'masterPlanSettingsCommissionRules.youEarn': 'you earn:',
    'masterPlanSettingsCommissionRules.from': 'From',
    'masterPlanSettingsCommissionRules.to': 'to',
    'masterPlanSettingsCommissionRules.ofYourGoal': 'of your goal',
    'masterPlanSettingsCommissionRules.ifYouReach': 'If you reach',
    'masterPlanSettingsCommissionRules.your': 'your',
    'masterPlanSettingsCommissionRules.the': 'the',
    'masterPlanSettingsCommissionRules.target': 'target',
    'masterPlanSettingsCommissionRules.initFormulaTitle': 'Initialisation section',
    'masterPlanSettingsCommissionRules.dependenciesTitle': 'Dependencies',
    'masterPlanSettingsCommissionRules.previewProjection.title': 'Projection',
    'masterPlanSettingsWeCanHelp.configurationText1': 'If you\'re not sure on how to implement or change your compensation plan, please ',
    'masterPlanSettingsWeCanHelp.configurationText2': 'click here',
    'masterPlanSettingsWeCanHelp.configurationText3': ' so that we can help.',
    'masterPlanSettingsCommissionRulesForm.form.submitBtnLabel': 'Save rules',
    'masterPlanSettingsCommissionRulesForm.noCommissionRules': 'No commission rules are defined',
    'masterPlanSettingsCommissionRulesForm.form.addNewRule': 'Add a new rule',
    'masterPlanSettingsCommissionRulesForm.form.disclaimer_zero': 'periods',
    'masterPlanSettingsCommissionRulesForm.form.disclaimer_one': 'period',
    'masterPlanSettingsCommissionRulesForm.form.disclaimer_other': 'periods',
    'masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount_zero': 'Saving the rules will impact {{count}} $t(masterPlanSettingsCommissionRulesForm.form.disclaimer_zero)',
    'masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount_one': 'Saving the rules will impact {{count}} $t(masterPlanSettingsCommissionRulesForm.form.disclaimer_one)',
    'masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount_other': 'Saving the rules will impact {{count}} $t(masterPlanSettingsCommissionRulesForm.form.disclaimer_other)',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.PER_OBJECT': 'Per {{type}}',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.PER_TARGET': 'Per Target',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.CODE_ONLY': 'Custom Code',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.title': 'Rule type',
    'masterPlanSettingsCommissionRulesForm.form.ruleRemoveTooltip': 'Delete this rule',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.subtitle': 'Select the type of rule to use',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.disclaimer.PER_OBJECT': '<strong>Per {{type}}</strong> means that you want to calculate commissions for each {{type}}. Your tiers must start at 0 and end at Infinity.',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.disclaimer.PER_TARGET': '<strong>Per Target</strong> means that you want to calculate commissions based on hitting targets. Your tiers must be sorted in ascending order.',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.disclaimer.CODE_ONLY': '<strong>Custom Code</strong> means that you want to calculate commissions based on custom logic. You must return a number or a list of deals with a "commission" attribute.',
    'masterPlanSettingsCommissionRulesForm.form.ruleDesc.title': 'Describe the commission rule in plain words for your reps',
    'masterPlanSettingsCommissionRulesForm.form.ruleDesc.subtitle': 'It helps employees understand the Commissions rules very quickly.',
    'masterPlanSettingsCommissionRulesForm.form.ruleDesc.placeholder': 'Rule description for the employees',
    'masterPlanSettingsCommissionRulesForm.form.ruleName.placeholder': 'Enter a rule name',
    'masterPlanSettingsCommissionRulesForm.form.ruleVariables.title': 'Variables',
    'masterPlanSettingsCommissionRulesForm.form.ruleCode.title': 'Code section',
    'masterPlanSettingsCommissionRulesForm.form.ruleCode.placeholder': 'Write code here',
    'masterPlanSettingsCommissionRulesForm.form.ruleCode.error.required': 'Please enter the code',
    'masterPlanSettingsCommissionRulesForm.form.ruleExplanation.error.required': 'Please enter the explanation',
    'masterPlanSettingsCommissionRulesForm.form.ruleExplanation.title': 'Explanation',
    'masterPlanSettingsCommissionRulesForm.form.ruleExplanation.placeholder': 'Explain your code with plain text (this will be shown on individual statements)',
    'masterPlanSettingsCommissionRulesForm.form.ruleVariables.subtitle': 'In this section you can define variables that you want to use in the plan rules in order to simplify each rule.',
    'masterPlanSettingsCommissionRulesForm.form.ruleCalculation.title': 'Commission calculation',
    'masterPlanSettingsCommissionRulesForm.form.ruleCalculation.subtitle': 'Write the formulas to calculate the commission amount',
    'masterPlanSettingsCommissionRulesForm.form.validator.reject': 'Something is wrong in the form. Please check the error message below one of the input field.',
    'masterPlanSettingsPayoutRules.descriptionTitle': 'Description',
    'masterPlanSettingsPayoutRules.detailsTitle': 'Details',
    'masterPlanSettingsPayoutRules.configurationTitle': 'Configuration',
    'masterPlanSettingsPayoutRules.noRulesText': 'No payout rules are defined for this plan.',
    'masterPlanSettingsPayoutRules.payoutSchedule.title': 'Payments',
    'masterPlanSettingsPayoutRules.ruleTitle': 'Rule',
    'masterPlanSettingsPayoutRules.ruleStep.conditionMet': 'If this condition is met',
    'masterPlanSettingsPayoutRules.ruleStep.noOtheronditionMet': 'If no other condition is met.',
    'masterPlanSettingsPayoutRules.ruleStep.definedByCustom': 'Rule defined by a custom code logic.',
    'masterPlanSettingsPayoutRules.ruleStep.payXOfCommission': 'Pay {{commission}} of the commission.',
    masterPlanSettingsPayoutRulesStepInPayments_zero: 'payments',
    masterPlanSettingsPayoutRulesStepInPayments_one: 'payment',
    masterPlanSettingsPayoutRulesStepInPayments_other: 'payments',
    masterPlanSettingsPayoutRulesStepInPaymentWithCount_zero: 'In {{count}} $t(masterPlanSettingsPayoutRulesStepInPayments_zero)',
    masterPlanSettingsPayoutRulesStepInPaymentWithCount_one: 'In {{count}} $t(masterPlanSettingsPayoutRulesStepInPayments_one)',
    masterPlanSettingsPayoutRulesStepInPaymentWithCount_other: 'In {{count}} $t(masterPlanSettingsPayoutRulesStepInPayments_other)',
    masterPlanSettingsPayoutRulesStepIntervalTypeMonth_zero: 'months',
    masterPlanSettingsPayoutRulesStepIntervalTypeMonth_one: 'month',
    masterPlanSettingsPayoutRulesStepIntervalTypeMonth_other: 'months',
    masterPlanSettingsPayoutRulesStepIntervalTypeDay_zero: 'days',
    masterPlanSettingsPayoutRulesStepIntervalTypeDay_one: 'day',
    masterPlanSettingsPayoutRulesStepIntervalTypeDay_other: 'days',
    masterPlanSettingsPayoutRulesStepIntervalTypeYear_zero: 'years',
    masterPlanSettingsPayoutRulesStepIntervalTypeYear_one: 'year',
    masterPlanSettingsPayoutRulesStepIntervalTypeYear_other: 'years',
    'masterPlanSettingsPayoutRules.ruleStep.intervalType.every': ', every {{count}} {{type}}',
    'masterPlanSettingsPayoutRules.ruleStep.statement': 'In the statement of:',
    'masterPlanSettingsPayoutRules.ruleStep.starting': 'Starting on:',
    'masterPlanSettingsPayoutRulesForm.form.template.payEndOfPlan_001': 'Pay at the end of each period',
    'masterPlanSettingsPayoutRulesForm.form.template.payEndOfPlan_001.frequency': 'Pay at the end of each {{frequency}}',
    'masterPlanSettingsPayoutRulesForm.form.template.payMonthAfterEndOfPlan_001': 'Pay one month after the end of each period',
    'masterPlanSettingsPayoutRulesForm.form.template.payMonthAfterEndOfPlan_001.frequency': 'Pay one month after the end of each {{frequency}}',
    'masterPlanSettingsPayoutRulesForm.form.template.payClosedDate_001': 'Pay one month after the {{type}} is closed',
    'masterPlanSettingsPayoutRulesForm.form.template.custom': 'Custom payout logic',
    'masterPlanSettingsPayoutRulesForm.form.choosePayoutStrategy': 'Choose a payout strategy',
    'masterPlanSettingsPayoutRulesForm.form.addNewRule': 'Add a new rule',
    'masterPlanSettingsPayoutRulesForm.form.submitBtnLabel': 'Save rules',
    'masterPlanSettingsPayoutRules.form.defineStrategy': 'Define a custom Payout strategy',
    'masterPlanSettingsPayoutRules.form.describeYourPayment': 'First, describe your payment rules',
    'masterPlanSettingsPayoutRules.form.configurePaymentLogic': 'Then, configure the payment logic',
    'masterPlanSettingsPayoutRulesForm.form.descriptionLabel': 'Explain in plain words the payout rules for this plan. Payout rules can be tricky to understand but it\'s often simple to explain how it works in your own words.',
    'masterPlanSettingsPayoutRules.form.firstMatch': 'Stop at 1st match',
    'masterPlanSettingsPayoutRules.form.allMatches': 'Evaluate all',
    'masterPlanSettingsPayoutRules.form.noRulesText': 'No payout rules are defined.<br />You can add a payout rule by clicking on the button below.',
    'masterPlanSettingsPayoutRules.form.ruleTitle': 'Payout rule',
    'masterPlanSettingsPayoutRules.form.conditionTitle': 'Payout condition (formula)',
    'masterPlanSettingsPayoutRules.form.conditionSubtitle': 'E.g.: true or Deal.productType === \'My_product_type\'',
    'masterPlanSettingsPayoutRules.form.conditionSubtitle.error.required': 'Please enter a payout condition',
    'masterPlanSettingsPayoutRules.form.conditionPlaceholder': 'Payout condition',
    'masterPlanSettingsPayoutRules.form.ruleRemoveTooltip': 'Delete this rule',
    'masterPlanSettingsPayoutRules.form.addAPaymentRule': 'Add payment rule',
    'masterPlanSettingsPayoutRulesForm.form.switchToCode': 'Switch to code',
    'masterPlanSettingsPayoutRulesForm.form.ruleCode.title': 'Code section',
    'masterPlanSettingsPayoutRulesForm.form.backToNormal': 'Get back to normal',
    'masterPlanSettingsPayoutRulesForm.form.codePlaceholder': 'Type your code here',
    'masterPlanSettingsPayoutRules.form.noPaymentsText': 'No payment rules are defined.<br />You can add a payment rule by clicking on the button below.',
    'masterPlanSettingsPayoutRules.template1.desc': 'Payment of the commission will be processed at the end of the period.',
    'masterPlanSettingsPayoutRules.template2.desc': 'Payment of the commission will be processed one month after the end of the period.',
    'masterPlanSettingsPayoutRules.template3.desc': 'Payment of the commission will be processed one month after the {{trackingObjectType}} is closed.',
    'masterPlanSettingsPayoutRules.form.ruleCode.disclaimer': 'You must return an Array of { date, percentage }.',
    'masterPlanSettingsPayoutRules.form.ruleCode.error.required': 'Please enter the code',
    'masterPlanSettingsPayoutRules.form.validator.reject': 'Something is wrong in the form. Please check the error message below one of the input field.',
    'masterPlanSettingsDependencies.description': 'You can add other plans as dependencies to access their data in the rules of this plan. For instance you can use previous achievements to define accelerators in the current plan.',
    'masterPlanSettingsDependencies.addDependencyBtnLabel': 'Add a dependency',
    'masterPlanSettings.DocumentationGeneralLinkLabel': 'Documentation for General Settings',
    'masterPlanSettings.DocumentationPlanRulesLinkLabel': 'Documentation for Commission Rules',
    'masterPlanSettings.DocumentationPayoutRulesLinkLabel': 'Documentation for Payout Rules',
    'masterPlanSettings.Users.removeTooltip': 'Remove user from plan',
    'masterPlanSettings.Users.deleteMasterPlanUserModal.title': 'Removing {{userName}} from this plan?',
    'masterPlanSettings.Users.deleteMasterPlanUserModal.confirm': 'Remove the user',
    'masterPlanSettings.Users.deleteMasterPlanUserModal.content': 'All commissions calculated for this user will be deleted. This cannot be undone.',
    'masterPlanSettingsGeneral.form.name.label': 'Name',
    'masterPlanSettingsGeneral.form.name.placeholder': 'Ex: Plan for AEs',
    'masterPlanSettingsGeneral.form.name.rules.required': 'Please name your Plan.',
    'masterPlanSettingsGeneral.form.scope.label': 'Select a Plan type',
    'masterPlanSettingsGeneral.form.currency.label': 'Currency',
    'masterPlanSettingsGeneral.form.timezone.label': 'Select a Timezone',
    'masterPlanSettingsGeneral.form.currency.addNew.label': 'Add a currency',
    'masterPlanSettingsGeneral.form.live.label': 'Visible for users',
    'masterPlanSettingsGeneral.form.live.description': 'When this feature is enabled, commissions calculated by this plan will be shown on the statement of your users.',
    'masterPlanSettingsGeneral.form.frequency.label': 'What is the frequency for this Plan?',
    'masterPlanSettingsGeneral.form.beginPeriod.label': 'Start Period',
    'masterPlanSettingsGeneral.form.setEndPeriod': 'Set an end period',
    'masterPlanSettingsGeneral.form.endPeriod.label': 'End Period',
    'masterPlanSettingsGeneral.form.endPeriod.rules.required': 'Please select an End Period.',
    'masterPlanSettingsGeneral.form.endPeriod.rules.isAfter': 'End Period should be after Start Period',
    'masterPlanSettingsGeneral.form.customBeginAt.label': 'Start date',
    'masterPlanSettingsGeneral.form.customBeginAt.rules.isBefore': 'Start date should be before End date',
    'masterPlanSettingsGeneral.form.customEndAt.label': 'End date',
    'masterPlanSettingsGeneral.form.customEndAt.rules.isAfter': 'End date should be after Start date',
    'masterPlanSettingsGeneral.form.frequencyForDay.label': 'Duration of a period in days',
    'masterPlanSettingsGeneral.form.frequencyForDay.description': 'Enter 7 for a weekly plan',
    'masterPlanSettingsGeneral.form.frequencyForDay.rules.required': 'Please enter a duration in days',
    'masterPlanSettingsGeneral.form.freezeType.label': 'Select a freeze type',
    'masterPlanSettingsGeneral.form.freezeType.tooltip': 'You can either completely freeze the plan or freeze only the commission calculation but not the payout rules calculations. It is useful if your payout rules are set up to unlock payments over time (If you wait for invoices to be paid for instance). All calculations would be frozen',
    'masterPlanSettingsGeneral.form.submitBtnLabel': 'Update Plan',
    'masterPlanSettingsGeneral.form.fiscalYearShift.label': 'Shift periods (Fiscal Year)',
    'masterPlanSettingsGeneral.form.fiscalYearShift.description1': 'Shift the start of the periods by',
    'masterPlanSettingsGeneral.form.fiscalYearShift.description2': 'months',
    'masterPlanSettingsGeneral.form.fiscalYearShift.hint': 'It means {{beginPeriodName}} will begin on {{beginPeriodMonth}}.',
    'masterPlanSettingsGeneral.freezeSettings.title': 'Freeze settings',
    'masterPlanSettingsGeneral.freezeSettings.subtitle.autofreeze': 'Auto freeze',
    'masterPlanSettingsGeneral.freezeSettings.subtitle.type': 'Freeze type',
    'masterPlanSettingsGeneral.freezeSettings.autoFreeze.label1': 'Automatically freeze periods',
    'masterPlanSettingsGeneral.freezeSettings.autoFreeze.label2': 'days after they end',
    'masterPlanSettingsGeneral.freezeSettings.description': 'Freezing a plan stops the calculations. The list of Users will not change. That way, you\'ll be able to audit past commissions without the risk of data being updated in the background.',
    'masterPlanSettingsGeneral.deleteAction.title': 'Do you want to delete this plan?',
    'masterPlanSettingsGeneral.deleteAction.description': 'Deleting a plan will permanently remove it from the platform, along with any associated commissions and payouts. Please note that this action cannot be undone.',
    'masterPlanSettingsGeneral.deleteAction.btnLabel': 'Delete',
    'masterPlanSettingsGeneral.archiveAction.title': 'Do you want to archive this plan?',
    'masterPlanSettingsGeneral.archiveAction.description': 'Archiving a plan moves it to Plans > Archived. All calculations and payouts remain accessible. This action is irreversible and only available for inactive plans.',
    'masterPlanSettingsGeneral.archiveAction.btnLabel': 'Archive',
    'masterPlanSettingsGeneral.quotaWarning.message': 'Linked Quota not compatible',
    'masterPlanSettingsGeneral.quotaWarning.description': 'The current Quota linked to this plan doesn\'t fit the new settings. In consequence, when you will update this plan, we will unlink the current Quota from this plan.',
    'masterPlanSettingsGeneral.dependenciesWarning.message': 'Dependencies not compatible',
    'masterPlanSettingsGeneral.dependenciesWarning.description': 'The current dependencies injected to this plan don\'t fit the new settings. In consequence, when you will update this plan, we will remove all dependencies from this plan.',
    'masterPlanSettingsGeneral.historySettings.title': 'History',
    'masterPlanSettingsGeneral.historySettings.description.empty': 'This plan was started from scratch and has no logical link with any other plan.',
    'masterPlanSettingsGeneral.historySettings.description': 'This plan is a duplicate from the plan: "<strong>{{name}}</strong>"',
    'masterPlanSettingsGeneral.historySettings.defineLink': 'Define a link with another plan',
    'masterPlanSettingsGeneral.historySettings.removeLink': 'Remove link',
    'masterPlanSettingsGeneral.historySettings.viewPlan': 'View plan',
    'masterPlanSettingsGeneral.historySettings.form.parentPlan.label': 'Select a plan to link',
    'masterPlanSettingsGeneral.historySettings.form.parentPlan.placeholder': 'Choose a Plan to link',
    'masterPlanSettingsGeneral.payoutsPercentageSettings.title': 'Payouts percentage settings',
    'masterPlanSettingsGeneral.form.allowWrongPayoutsPercentage.label': 'Allow wrong payouts percentage (total percentage > 100%)',
    'masterPlanSettingsQuota.disclaimer.noQuota.title': 'No quota is linked to this plan',
    'masterPlanSettingsQuota.disclaimer.noQuota.description': 'Do you want to define one for this plan?',
    'masterPlanSettingsQuota.disclaimer.noQuota.defineQuotaCTA': 'Define a Quota',
    'masterPlanSettingsQuotaAttainment.title': 'Quota Attainment',
    'masterPlanSettingsQuotaAttainment.subtitle': 'Define the value each {{dealType}}',
    'masterPlanSettingsQuotaAttainment.dealVariables.title': 'Enrich {{dealType}} with new fields',
    'masterPlanSettingsQuotaAttainment.dealVariables.subtitle': 'In this section, you can define new fields that will be added to each {{dealType}} during refresh data.',
    'masterPlanSettingsQuotaAttainment.form.valueFormula.label': 'How do you calculate the value of a {{dealType}}?',
    'masterPlanSettingsQuotaAttainment.form.submitBtnLabel': 'Save Quota Attainment',
    'masterPlanSettingsQuotaAttainment.form.valueFormula.rules.required': 'Please enter a calculation formula',
    'masterPlanSettingsQuotaAttainment.needData.disclaimer.label': 'Need data from another plan?',
    'masterPlanSettingsQuotaAttainment.needData.modal.title': 'Add plan dependencies',
    'masterPlanSettingsQuotaAttainment.needData.modal.content': 'You\'ll be able to add dependencies in the plan settings once the plan is created. That way, you\'ll have access to other plan\'s data.',
    'masterPlanSettingsQuotaSetupPanel.alert.noQuotas.message': 'No quotas available for this Plan',
    'masterPlanSettingsQuotaSetupPanel.alert.noQuotas.description': 'Click on "$t(quota.createNewONe)" button or go to Quotas page to create a new Quota.',
    'masterPlanSettingsQuotaSetupPanel.quotaSelector.placeholder': 'Choose a Quota',
    'masterPlanSettingsQuotaSetupPanel.missingUsersInQuota.alert.message': '$t(usersWithCountToBe, {"count": {{count}} }) missing in the Quota.',
    'masterPlanSettingsQuotaSetupPanel.doYouConfirmQuestion': 'Do you confirm to choose this Quota and add missing users into the selected Quota?',
    'masterPlanSettingsQuotaSetupPanel.confirmBtnLabel': 'I confirm',
    'masterPlanSettingsQuotaSetupPanel.linkBtnLabel': 'Link selected Quota',
    'masterPlanSettingsQuotaSetupPanel.createForTeamBtnLabel': 'Define this Quota for the team',
    'masterPlanSettingsQuotaSetupPanel.viewQuotaLinkLabel': 'View Quota',
    'masterPlanSettingsQuotaSetupPanel.customTeamQuota.target.label': 'Team\'s target for this custom dates period',
    'masterPlanSettingsUsers.table.headers.manager': 'Manager',
    'masterPlanSettingsUsers.table.headers.addManager': 'Add a Manager',
    'masterPlanSettingsUsers.table.headers.user': 'User',
    'masterPlanSettingsUsers.table.headers.addUser': 'Add a User',
    'masterPlanSettingsUsers.table.headers.startDate': 'Joined the plan on',
    'masterPlanSettingsUsers.table.headers.endDate': 'Left the plan on',
    'masterPlanSettingsUsers.filter.active': 'Active users',
    'masterPlanSettingsUsers.filter.all': 'All users',
    'masterPlanSettingsUsers.filterDescription': 'Active users are the ones being active in the current period.',
    'masterPlanSettingsUsers.connectorsDescription': 'Select the data source containing users',
    'masterPlanSettingsPage.title': 'Settings',
    'masterPlanSettingsProjection.alert.message': 'No defined projection',
    'masterPlanSettingsProjection.alert.description': 'Do you want to define a projection for this Plan?',
    'masterPlanSettingsProjection.alert.defineProjectionCTA': 'Define a projection',
    'masterPlanProjectionDefinitionForm.form.explanation.label': 'Projection description',
    'masterPlanProjectionDefinitionForm.form.explanation.placeholder': 'Enter a description for the projection (ex: Projection with Deal type XXX)',
    'masterPlanProjectionDefinitionForm.form.referenceDealId.label': '{{dealType}} to use as reference for projection',
    'masterPlanProjectionDefinitionForm.form.display.label': 'Display projection graph for Reps',
    'masterPlanProjectionDefinitionForm.form.defineSubmitBtnLabel': 'Define projection',
    'masterPlanProjectionDefinitionForm.form.updateSubmitBtnLabel': 'Update projection',
    'masterPlanProjectionDefinitionForm.deleteAction.description': 'Do you want to delete this projection?',
    'masterPlanProjectionDefinitionForm.deleteAction.btnLabel': 'Delete the projection',
    'masterPlanProjectionDefinitionForm.previewProjection.title': 'Projection',
    'masterPlanProjectionDefinitionForm.previewProjection.selectorLabel': 'Display graph for this specific user (uses his own target to build the graph):',
    'masterPlanSettingsTabs.tabs.general': 'General',
    'masterPlanSettingsTabs.tabs.users': 'Users',
    'masterPlanSettingsTabs.tabs.quota': 'Quota',
    'masterPlanSettingsTabs.tabs.commissionRules': 'Commission rules',
    'masterPlanSettingsTabs.tabs.payoutRules': 'Payout rules',
    'masterPlanSettingsTabs.tabs.dataSource': 'Data source & Filtering',
    'masterPlanSettingsTabs.tabs.projection': 'What-If Projection',
    'masterPlanSettingsTabs.tabs.dependencies': 'Dependencies',
    'masterPlanTrackingObjectDefinitionForm.title': 'Resource to Track',
    'masterPlanTrackingObjectDefinitionForm.description': 'Define the resource that you want to track in this Plan.',
    'masterPlanTrackingObjectDefinitionForm.form.connectorIdType.label': 'Resource Tracked',
    'masterPlanTrackingObjectDefinitionForm.form.connectorIdType.rename': 'Rename',
    'masterPlanTrackingObjectDefinitionForm.form.uiType.label': 'Change {{dealType}} to the following name (used for display only)',
    'masterPlanTrackingObjectDefinitionForm.form.uiType.placeholder': 'ex: Deal, Meeting, Opportunity',
    'masterPlanTrackingObjectDefinitionForm.form.uiType.resetName': 'Reset to original name',
    'masterPlanTrackingObjectDefinitionForm.form.matchingField.label': 'Field to match with the Resource owner',
    'masterPlanTrackingObjectDefinitionForm.form.dateField.label': 'Date (Reference date field to use)',
    'masterPlanTrackingObjectDefinitionForm.form.filterObject.label': 'Resource Filter',
    'masterPlanTrackingObjectDefinitionForm.form.filterObject.empty': 'No resource filter',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.useCustomCode': 'Use custom code',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.description': 'Only use this when you can\'t express yourself with the Filter above.',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.hide': 'Hide',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.label': 'Filter Code (Optional)',
    'masterPlanTrackingObjectDefinitionForm.form.submitBtnLabel': 'Update Resource to Track configuration',
    'masterPlanTrackingObjectDefinitionForm.form.matchingField.rules.required': 'Please enter a Field to match',
    'masterPlanTrackingObjectDefinitionForm.form.dateField.rules.required': 'Please enter a date Field',
    'masterPlanTrackingObjectDefinitionForm.form.connectorIdType.rules.required': 'Please select the Resource Tracked',
    'masterPlanUsersDefinitionForm.title': 'Resource Owner',
    'masterPlanUsersDefinitionForm.description': 'Defining the resource owner helps Palette undersand who should be rewarded for a given {{dealType}}',
    'masterPlanUsersDefinitionForm.form.connectorIdType.label': 'Resource Type',
    'masterPlanUsersDefinitionForm.form.connectorIdType.rename': 'Rename',
    'masterPlanUsersDefinitionForm.form.uiType.label': 'Change {{userType}} to the following name (used for display only)',
    'masterPlanUsersDefinitionForm.form.uiType.placeholder': 'ex: Deal, Meeting, Opportunity',
    'masterPlanUsersDefinitionForm.form.uiType.resetName': 'Reset to original name',
    'masterPlanUsersDefinitionForm.form.matchingField.label': 'Field to match with the Resource to track',
    'masterPlanUsersDefinitionForm.form.submitBtnLabel': 'Update Resource Owner configuration',
    'masterPlanUsersDefinitionForm.form.matchingField.rules.required': 'Please enter a Field to match',
    'masterPlanUsersDefinitionForm.form.connectorIdType.rules.required': 'Please select the Resource Type',
    'menuNotificationsPopoverContent.title': 'Notifications',
    'menuNotificationsPopoverContent.pending': 'Pending · {{count}}',
    'menuNotificationsPopoverContent.resolved': 'Resolved · {{count}}',
    'mergeUserResults.sections.userDetailsDiff': 'User Details differences',
    'mergeUserResults.sections.plansInCommon': 'Plans in common',
    'mergeUserResults.sections.quotasInCommon': 'Quotas in common',
    'mergeUserResults.userDetailsDiff.table.headers.user': 'User {{userName}}',
    'modal.cancel': 'Cancel',
    'modal.ok': 'OK',
    'modal.confirm': 'Confirm',
    'moveTeamModal.title': 'Move team',
    'moveTeamModal.move': 'Move',
    'moveTeamModal.description': 'Select the team where you want to move <strong>{{teamName}}</strong> into:',
    'moveTeamModal.moveToRootLabel': 'Move team to root',
    'myCommissionList.page.title': 'My Commission list',
    'myCommissionList.page.subtitle': 'Define the commission you\'re looking for',
    'myDashboardPage.title': 'Hello {{firstName}}',
    'myDashboardPage.customize.title': 'Customize your Dashboard',
    'myDashboardPage.customize.description1': 'You can personnalize your experience by adding widgets on your dashboard.',
    'myDashboardPage.customize.description2': 'You can also organize the widgets thanks to the buttons < and > .',
    'myDashboardPage.customize.cta': 'Add widgets now',
    'myDashboardPage.customize.addWidget': 'Add a widget',
    'myMasterPlansPage.title': 'My Plans',
    'myMasterPlan.lastPeriod.totalCommission': 'Commission ·',
    'myMasterPlanBreadcrumb.plans': 'My Plans',
    'myMasterPlanPeriodDeals.actions.calculationDetails': 'Calculation details',
    'myMasterPlanPeriodDeals.actions.somethingMissing': 'Think something is missing?',
    'myMasterPlanSomethingMissingModal.title': 'Think something is missing?',
    'myMasterPlanSomethingMissingModal.intro1': 'If you think there is a missing {{type}}, here is an action you can take:',
    'myMasterPlanSomethingMissingModal.intro2': 'If you think there is a missing {{type}}, here are 2 actions you can take:',
    'myMasterPlanSomethingMissingModal.verifyTitle': 'Verify the data in {{connectorType}}',
    'myMasterPlanSomethingMissingModal.verifyText': 'Check that the data is correct and has no missing fields.',
    'myMasterPlanSomethingMissingModal.triggerTitle': 'Trigger a manual sync',
    'myMasterPlanSomethingMissingModal.triggerText': 'If the data seems correct to you, maybe it\'s just a matter of synchronisation. To trigger a sync, you can click on this button:',
    'myMasterPlanCalculationModal.title': 'Calculation details',
    'myMasterPlanCalculationModal.commissions.title': 'Commission rules',
    'myMasterPlanCalculationModal.payout.title': 'Payout rules',
    'myStatementsPage.title': 'My Statements',
    'myStatementsPageContent.viewMoreInPalette': 'View more in Palette',
    'myStatementsPageContent.unauthorized.title': 'Nothing here yet',
    'myStatementsPageContent.unauthorized.description': 'Contact your company Admin if you think this is a mistake.<br />You\'ll want to make sure your company have an account on <a target="_blank" rel="noopener noreferrer" href="https://palettehq.com/?ref=salesforce">Palette</a>.',
    'newDashboardPresetButton.label': 'Design new dashboard',
    'newNotebookButton.label': 'Add New Model',
    'newNotebookModal.title': 'New model',
    'newNotebookModal.create': 'Create Model',
    'newNotebookModal.form.name.label': 'Model Name',
    'newNotebookModal.form.name.rules.required': 'Please name your Model.',
    'newNotebookModal.form.description.label': 'Description',
    'newTeamButton.label.newSubteam': 'New Subteam',
    'newTeamButton.label.newTeam': 'New Team',
    'newTeamModal.title.newSubteam': 'Create new subteam',
    'newTeamModal.title.newTeam': 'Create new team',
    'newTeamModal.create': 'Create',
    'newTeamModal.form.name.label': 'Team Name',
    'newTeamModal.form.name.rules.required': 'Please name your Team.',
    'noConnectorsAlert.warning.message': 'No Connectors found',
    'noConnectorsAlert.warning.description': 'You may want to double check your connectors settings. It seems you have no connectors defined on your account.',
    'notebookCell.actions.makeExportable.popconfirm': 'Do you confirm to make results of this cell available as resources?',
    'notebookCell.actions.makeExportable.label': 'Create Resource',
    'notebookCell.actions.run': 'Run Query',
    'notebookCell.errors.type.notebook.empty.output.error': 'Code must print data at the end.',
    'notebookCell.errors.type.notebook.python.error': 'Python error occured',
    'notebookCell.errors.type.python.invalid.code': 'Provided Python code is not valid',
    'notebookCell.errors.type.notebook.run.error': 'Some code cells did not run well.',
    'notebookCell.errors.type.notebook.required.information': 'An information is required.',
    'notebookCell.errors.type.notebook.codeCell.required.information': 'Required field',
    'notebookCell.errors.type.notebook.codeCell.required.information.content': 'Code cell n°{{position}} requires <strong>{{field}}</strong> information.',
    'notebookCell.errors.type.notebook.codeCell.conflicting.outputName': 'Output name already exists',
    'notebookCell.errors.type.notebook.codeCell.conflicting.outputName.content': '<strong>{{cellTitle}}</strong>\'s output name must be unique.',
    'notebookCell.errors.type.notebook.already.existing.name': 'Company already has a notebook with this name.',
    'notebookCell.errors.type.notebook.not.existing': 'Notebook does not exist in company.',
    'notebookCell.errors.type.notebook.forbidden.codeCell.export': 'A cell is not exported yet.',
    'notebookCell.errors.type.notebook.invalid.codeCell.execution.status': 'Code cell execution status is not valid.',
    'notebookCell.errors.type.notebook.unexportable.language': 'This language is not exportable as Resource.',
    'notebookCell.errors.type.notebook.forbidden.update': 'Notebook status does not allow any updates on it.',
    'notebookCell.errors.type.notebook.connector.not.found': 'A connector does not exist in your company.',
    'notebookCell.errors.type.notebook.codeCell.not.exists': 'There is not any code cell with this query id.',
    'notebookCell.errors.type.notebook.status.language': 'There is an invalid code cell status.',
    'notebookCell.errors.type.notebook.codeCell.outputName.locked': 'The output name is locked.',
    'notebookCell.errors.type.notebook.invalid.type': 'This notebook type is invalid.',
    'notebookCell.errors.type.notebook.invalid.status': 'This notebook status is invalid.',
    'notebookCell.errors.type.notebook.invalid.group': 'There is a filter with an invalid group.',
    'notebookCell.errors.type.notebook.invalid.output.language': 'This language is not a valid language for generating output.',
    'notebookCell.errors.type.notebook.invalid.language': 'This language is not a valid code cell language.',
    'notebookCell.errors.type.notebook.invalid.column.type': 'This type is an invalid connector column type.',
    'notebookCell.errors.type.notebook.invalid.output.unique.identifier.error': 'Invalid UID',
    'notebookCell.errors.type.notebook.invalid.output.unique.identifier.error.content': '<strong>{{cellName}}</strong> must use an output value as UID.',
    'notebookCell.errors.type.notebook.output.unique.identifier.locked': 'UID cannot be modified',
    'notebookCell.errors.type.notebook.output.unique.identifier.locked.content': 'Code cell <strong>{{cellName}}</strong> output UID can not be modified when it\'s used as a resource.',
    'notebookCell.exportedOverlay.description': 'This cell is being used as a resource. Are you sure you want to edit it?',
    'notebookCell.exportedOverlay.edit.data': 'Edit data',
    'notebookCell.isExported': 'Resource created',
    'notebookCell.isNotExported': 'Resource not created',
    'notebookCell.title': 'Title',
    'notebookCell.uniqueIdentifier.label': 'UID',
    'notebookCell.uniqueIdentifier.required': 'Required field',
    'notebookCell.uniqueIdentifier.info': 'Field used as UID (unique identifier) to recognize each data contained in code cell output. It cannot be modified after resource is created!',
    'notebookCellMoreOptionsMenu.unMakeExportableCell': 'Do not export',
    'notebookCellMoreOptionsMenu.deleteNotebookCell': 'Delete',
    'notebookDetails.documentation': 'Documentation',
    'notebookDetails.save': 'Save',
    'notebookDetails.isSaving': 'Saving...',
    'notebookDetails.saved': 'Saved',
    'notebookDetailsLeftSideContent.dataConnections': 'Data Connections',
    'notebookDetailsLeftSideContent.tabs.dataConnections': 'Data',
    'notebookDetailsLeftSideContent.tabs.exportedCells': 'Resources',
    'notebookDetailsLeftSideContent.tabs.runnableCells': 'Cells',
    'notebookDataConnectionConnectorsListItem.copy.dataframe': 'Copy dataframe name "{{dataframeName}}"',
    'notebooks.addDataConnectionBlockListItem.disabled': 'You cannot change it',
    'notebooks.addDataConnectionBlockListItem.types.array': 'List',
    'notebooks.addDataConnectionBlockListItem.types.object': 'Object',
    'notebooks.addDataConnectionBlockListItem.types.column': 'Column',
    'notebooks.addDataConnectionConnector.disabled': 'All resources used',
    'notebooks.cellTypes.sql': 'SQL',
    'notebooks.cellTypes.text': 'Text',
    'notebooks.cellTypes.markdown': 'Markdown',
    'notebooks.cellTypes.table': 'Table',
    'notebooks.cellTypes.python': 'Python',
    'notebooks.cellTypes.js': 'JavaScript',
    'notebooksBreadcrumb.notebooks': 'Models',
    'notebooksListTable.emptyDescription': 'No model corresponding to your search',
    'notebooksListTable.header.name': 'Name',
    'notebooksListTable.header.owner': 'Owner',
    'notebooksListTable.header.created': 'Created',
    'notebooksListTable.header.edited': 'Edited',
    'notebooksListTable.header.usedInPlans': 'Used in plans',
    'notebooksListTable.header.actions': 'Actions',
    'notebooksPage.title': 'Models',
    'notebooksPage.subtitle': 'Transform your own data synced in Palette in order to simplify complex operations (like reconciliations)',
    'notebooksPageContent.tabs.all': 'All',
    'notebooksPageContent.tabs.yours': 'Yours',
    'notebooksPageContent.tabs.templates': 'Templates',
    'notebooksPageContent.tabs.archived': 'Archived',
    'notebooksProjectsListItemMoreOptionsMenu.copyNotebook': 'Duplicate',
    'notebooksProjectsListItemMoreOptionsMenu.archiveNotebook': 'Archive',
    'notebooksProjectsListItemMoreOptionsMenu.unarchiveNotebook': 'Unarchive',
    'notebooksProjectsListItemMoreOptionsMenu.deleteNotebook': 'Delete',
    'notifications.masterPlans.success.update': 'Plan configuration is successfully updated',
    'notifications.masterPlans.success.userDates': 'User date is successfully updated',
    'notifications.masterPlans.success.archive': 'Plan is successfully archived',
    'notifications.masterPlans.success.delete': 'Plan is successfully deleted',
    'notifications.plan.V3.success.create': 'Plan is successfully created',
    'notifications.plan.V3.success.requirements.update': 'Plan requirements are successfully updated',
    'notifications.plan.V3.success.plan.saved': 'Plan is successfully saved',
    'notifications.plan.V3.success.run.code.cell': 'Code cell ran successfully',
    'notifications.plan.V3.error.no.plan.user': 'Please select at least one user for this plan',
    'notifications.plan.V3.error.no.datasource': 'Please add the data sources to use in this plan',
    'notifications.plan.V3.error.invalid.endDate.message': 'Invalid end date',
    'notifications.plan.V3.error.invalid.endDate.content': 'The provided end date <strong>{{endDate}}</strong> should be after the start date <strong>{{startDate}}</strong>.',
    'notifications.plan.V3.error.missing.information.message': 'Required information error',
    'notifications.plan.V3.error.missing.information.content': 'Missing <strong>{{fieldPath}}</strong> required information.',
    'notifications.dashboard.success.addWidget': 'Widget added',
    'notifications.dashboard.success.updateWidget': 'Widget updated',
    'notifications.dashboard.success.applyDashboardPresetToUsers': 'Dashboard applied.',
    'notifications.statements.success.deleteAdjustment': 'Correction is successfully deleted',
    'notifications.statements.success.updateAdjustment': 'Correction is successfully updated',
    'notifications.statements.success.applyCorrection': 'Correction is successfully applied',
    'notifications.statements.success.addAdjustment': 'Correction is successfully added',
    'notifications.statements.success.approve.statements.title': 'Statement approvement',
    'notifications.statements.success.approveStatements_one': 'Statement is successfully approved.',
    'notifications.statements.success.approveStatements_other': 'Statements are successfully approved.',
    'notifications.statements.success.markAsPaid.statements.title': 'Statement mark as paid',
    'notifications.statements.success.markAsPaidStatements_one': 'Statement is successfully marked as paid.',
    'notifications.statements.success.markAsPaidStatements_other': 'Statements are successfully marked as paid.',
    'notifications.statements.success.ignoreCorrection': 'Correction is successfully canceled',
    'notifications.statements.success.cancelStatementApproval': 'Statement\'s approval is successfully removed',
    'notifications.statementValidations.success.activated': 'Statement validations activated',
    'notifications.statementValidations.success.deactivated': 'Statement validations deactivated',
    'notifications.statementFxRateMode.success': 'Statement configuration is successfully updated',
    'notifications.profile.success.company.update': 'Company is successfuly saved',
    'notifications.profile.success.profile.update': 'Profile is successfuly saved',
    'notifications.profile.success.updateStatementStrategy': 'New statement cycle is successfully saved',
    'notifications.profile.success.sendFeedback': 'Feedback sent! Thank you. We will get back to you ASAP.',
    'notifications.teams.success.create': 'Team is successfully created',
    'notifications.teams.success.delete': 'Team is successfully deleted',
    'notifications.teams.success.updateName': 'Team name is successfully updated',
    'notifications.teams.success.moveTeam': 'Team name is successfully moved',
    'notifications.users.success.invite': 'User is successfully invited',
    'notifications.users.success.onboardUsers': 'New users are successfully onboarded',
    'notifications.users.success.updateUser': 'User is successfully updated',
    'notifications.users.success.deactivateUser': 'User is successfully deactivated',
    'notifications.users.success.unlinkResourceFromUser': 'Resource is successfully unlinked from user',
    'notifications.users.success.linkResourcesToUser': 'Resources are successfully linked to user',
    'notifications.users.success.offboardUser': 'User is successfully offboarded',
    'notifications.usersVariables.success.create': 'Variable is successfully created',
    'notifications.usersVariables.success.edit': 'New variable value is successfully added',
    'notifications.usersVariables.success.deleteOverride': 'Variable value is successfully deleted',
    'notifications.notebooks.success.archive': 'Model is successfully archived',
    'notifications.notebooks.success.unarchive': 'Model is successfully unarchived',
    'notifications.notebooks.success.delete': 'Model is successfully deleted',
    'notifications.notebooks.success.addNewDataConnection': 'Data Connection is successfully added',
    'notifications.notebooks.success.editDataConnection': 'Data Connection is successfully edited',
    'notifications.notebooks.success.copyConnectionConnectorDataframeNameToClipboard': 'Dataframe name copied',
    'notifications.notebooks.success.copyConnectionConnectorDataframeNameToClipboard.content': 'Dataframe name <strong>{{name}}</strong> is successfully copied.',
    'notifications.notebooks.error.copyConnectionConnectorDataframeNameToClipboard': 'An error occurred during copy of dataframe name.',
    'notifications.planV3.error.copyName': 'An error occurred during copy of variable name.',
    'notifications.planV3.success.copyName': 'Variable name copied',
    'notifications.planV3.success.copyName.content': 'Variable name <strong>{{name}}</strong> is successfully copied.',
    'notifications.success.forgotPassword': 'You will receive an email soon with instructions to reset your password.',
    'notifications.resources.resource_deleted.success.message': 'Resource deleted',
    'notifications.resources.resource_deleted.success.content': '<strong>{{type}}</strong> is successfully deleted.',
    'notifications.resources.resource_data_copied.success.message': 'Resource data is successfully copied as JSON.',
    'notifications.resources.resource_data_copied.error.message': 'An error occurred during copy of resource data.',
    'noUserTypeConnectorsAlert.warning.noUsersFound.message': 'No Users were found',
    'noUserTypeConnectorsAlert.warning.noUsersFound.description': 'You may want to double check your connectors settings.<br />If you have a connector like GSheet or CSV, you\'ll want to define a Resource as a User type.',
    'offboardUserButton.label': 'Offboard {{userDisplayName}} now',
    'offboardUserModal.title': 'Offboarding',
    'offboardUserModal.confirm': 'Offboard User',
    'offboardUserModal.leaveDateDefinition.description.title': 'What will happen after this date?',
    'offboardUserModal.leaveDateDefinition.description.content': '{{userDisplayName}} will be removed from the current and future plans. No commissions will be calculated for the employee after this date. {{userDisplayName}} will lose access to Palette. {{userDisplayName}} profile will be removed from dashboard and reports.',
    'offboardUserModal.form.leaveDate.label': 'When does {{userDisplayName}} leave {{companyName}}?',
    'offboardUserModal.form.leaveDate.rules.required': 'Please select a leave date',
    'offboardUserModal.addFinalAdjustment': 'Add a final adjustment?',
    'offboardUserModal.noFinalAdjustment': 'No, in final I don\'t want to add final adjustment',
    'offboardUserModal.finalAdjustmentReason': 'Final adjustment',
    'offboardUserModal.sections.leaveDate': 'Leave Date',
    'offboardUserModal.sections.finalAdjustment': 'Final Adjustment',
    'onboardUsersFromResourcesFlowPage.title': 'Onboard new users',
    'onboardUsersManualInputFlowPage.title': 'Onboard new users',
    'onboardUsersCheckDataFlowStep.warning.isMissingRequiredFields': 'For some users, some required fields are missing. Please complete the missing data before to continue.',
    'onboardUsersCheckDataFlowStep.hasMissingRequiredFields': 'This user has missing required fields.',
    'onboardUsersCheckDataFlowStep.table.headers.firstName': 'First Name',
    'onboardUsersCheckDataFlowStep.table.headers.lastName': 'Last Name',
    'onboardUsersCheckDataFlowStep.table.headers.email': 'Email',
    'onboardUsersCheckDataFlowStep.table.headers.currency': 'Currency',
    'onboardUsersCheckDataFlowStep.viewLinkedResource': 'View linked resource',
    'onboardUserForm.form.firstName.label': 'First Name',
    'onboardUserForm.form.firstName.rules.required': 'Please fill a first name.',
    'onboardUserForm.form.lastName.label': 'Last Name',
    'onboardUserForm.form.lastName.rules.required': 'Please fill a last name.',
    'onboardUserForm.form.email.label': 'Email',
    'onboardUserForm.form.email.rules.required': 'Please fill an email.',
    'onboardUserForm.form.email.rules.email': 'Please provide a valid email address.',
    'onboardUserForm.form.currency.label': 'Currency',
    'onboardUsersFlowStepActions.next': 'Next',
    'onboardUsersFlowStepActions.finish': 'Finish',
    'onboardUsersFlowSteps.title': 'Onboarding Steps',
    'onboardUsersFlowSteps.availableSoon': 'Soon',
    'onboardUsersFlowSteps.manual_input.label': 'Fill user form',
    'onboardUsersFlowSteps.manual_input.subTitle': 'Fill user form to onboard a new user',
    'onboardUsersFlowSteps.select_resources.label': 'Resources selection',
    'onboardUsersFlowSteps.select_resources.subTitle': 'Choose resources to onboard as new users',
    'onboardUsersFlowSteps.add_to_teams.label': 'Add to Teams',
    'onboardUsersFlowSteps.add_to_teams.subTitle': 'Choose teams you want to add new users',
    'onboardUsersFlowSteps.add_to_plans.label': 'Add to Plans',
    'onboardUsersFlowSteps.add_to_plans.subTitle': 'Choose plans you want to add new users',
    'onboardUsersFlowSteps.define_quotas.label': 'Define Quotas',
    'onboardUsersFlowSteps.define_quotas.subTitle': 'Define quotas for new users',
    'onboardUsersFlowSteps.define_variables.label': 'Define Variables',
    'onboardUsersFlowSteps.define_variables.subTitle': 'Define variables for new users',
    'onboardUsersFlowSteps.check_data.label': 'Onboarding validation',
    'onboardUsersFlowSteps.check_data.subTitle': 'Validate new users data from this summary',
    'onboardingUsersButton.label': 'Onboard new users',
    'onboardingUsersButton.moreMenu.fromResources': 'From Resources',
    'onboardingUsersButton.moreMenu.manualInput': 'Fill The Form',
    'ongoingBulkApproveStatus.status': '{{currentIndex}} out of {{nbToProcess}} approved.',
    'ongoingBulkApproveStatus.description': 'Please remain on this page until all elements finish processing, as leaving the page will halt the ongoing process.',
    'ongoingBulkMarkAsPaidStatus.status': '{{currentIndex}} out of {{nbToProcess}} marked as paid.',
    'ongoingBulkMarkAsPaidStatus.description': 'Please remain on this page until all elements finish processing, as leaving the page will halt the ongoing process.',
    'pagination.results_zero': '{{count}} result',
    'pagination.results_one': '{{count}} result',
    'pagination.results_other': '{{count}} results',
    'pagination.goToPage': 'Go to page',
    'pagination.perPage': '{{limit}} per page',
    palette_users_one: 'Palette User',
    palette_users_other: 'Palette Users',
    'passwordRules.rule.hasLowercaseLetters_zero': 'At least {{count}} lowercases',
    'passwordRules.rule.hasLowercaseLetters_one': 'At least {{count}} lowercase',
    'passwordRules.rule.hasLowercaseLetters_other': 'At least {{count}} lowercases',
    'passwordRules.rule.hasMinLength_zero': 'Minimum {{count}} characters',
    'passwordRules.rule.hasMinLength_one': 'Minimum {{count}} character',
    'passwordRules.rule.hasMinLength_other': 'Minimum {{count}} characters',
    'passwordRules.rule.hasNumbers_zero': 'At least {{count}} numbers',
    'passwordRules.rule.hasNumbers_one': 'At least {{count}} number',
    'passwordRules.rule.hasNumbers_other': 'At least {{count}} numbers',
    'passwordRules.rule.hasUppercaseLetters_zero': 'At least {{count}} uppercases',
    'passwordRules.rule.hasUppercaseLetters_one': 'At least {{count}} uppercase',
    'passwordRules.rule.hasUppercaseLetters_other': 'At least {{count}} uppercases',
    'passwordRules.rule.hasSymbols_zero': 'At least {{count}} symbols',
    'passwordRules.rule.hasSymbols_one': 'At least {{count}} symbol',
    'passwordRules.rule.hasSymbols_other': 'At least {{count}} symbols',
    'passwordRules.title': 'Password rules:',
    payments_zero: 'Payment',
    payments_one: 'Payment',
    payments_other: 'Payments',
    paymentsWithCount_zero: '{{count}} payment',
    paymentsWithCount_one: '{{count}} payment',
    paymentsWithCount_other: '{{count}} payments',
    plans_one: 'Plan',
    plans_other: 'Plans',
    plansWithCount_zero: '{{count}} $t(plans_one)',
    plansWithCount_one: '{{count}} $t(plans_one)',
    plansWithCount_other: '{{count}} $t(plans_other)',
    'plan.invalid.commission.amount.error': 'Plan commission rules error',
    'plan.invalid.commission.amount.content': 'An error occured on <0>{{name}}\'s commission rules</0> and prevents to generate new commissions.<br />One of the formulas called <strong>"{{rules}}"</strong> evaluated to calculate the commission amount <strong>is not a number</strong>.',
    'plan.code.rule.evaluation.error': 'Plan rule evaluation error',
    'plan.code.rule.evaluation.content': 'An error occured on <0>{{name}}</0>\'s rule called <strong>"{{rule}}"</strong> and prevents to generate new commissions.',
    'plan.injection.error': 'Plan injection error',
    'plan.injection.content': '<0>{{name}}</0> plan <strong>cannot</strong> inject a future period of itself.',
    'percentage.rule.target.error': 'Invalid targetted percentage rule',
    'percentage.rule.target.content': '<0>{{name}}</0> plan <strong>cannot</strong> have 0 as a percentage target.<br />This plan rule is using percentage and an evaluated target is 0 for a given plan user.',
    'plan.invalid.codeOnly.formula.error': 'Invalid formula in plan',
    'plan.invalid.codeOnly.formula.content': '<0>{{name}}</0>\'s rule contains invalid custom formulas.',
    'plan.payout.rule.evaluation.error': 'Payout rule error',
    'plan.payout.rule.evaluation.content': 'An error occured on <0>{{name}}\'s payout rules</0> while evaluating <strong>{{field}}</strong> > <strong>{{formula}}</strong>.<br />Reason: <strong>{{reason}}</strong>.',
    'plan.tracked.resource.value.evaluation.error': 'Tracked resource evaluated value error',
    'plan.tracked.resource.value.evaluation.content': 'An error occured on <0>{{name}}\'s tracked resource value</0> while evaluating <strong>{{formula}}</strong>.<br />Reason: <strong>{{reason}}</strong>.',
    'plan.invalid.paymentPercentage.error': 'Invalid payment percentage',
    'plan.invalid.paymentPercentage.content': 'A payment in <0>{{name}}\'s payout rules</0> contains an invalid percentage value ({{paymentPercentage}}).<br />Reason: <strong>{{reason}}</strong>.',
    'paymentsEditPayoutSchedule.editPayoutSchedule': 'Update payout schedule',
    'paymentsEditPayoutScheduleModal.title': 'Edit commission payout schedule',
    'paymentsEditPayoutScheduleModal.save': 'Save',
    'paymentsEditPayoutScheduleModal.warning': 'Editing the payout schedule manually will override its value and therefore prevent any payout rule to update it.',
    'paymentsEditPayoutScheduleModal.commission': 'Commission',
    'paymentsEditPayoutScheduleModal.payments': 'Payments',
    'paymentsEditPayoutScheduleModal.use': 'Use',
    'paymentsEditPayoutScheduleModal.total': 'Total:',
    'paymentsEditPayoutScheduleModal.addPayment': 'Add a payment',
    'paymentsEditPayoutScheduleModal.alert.periodIsFrozen': 'Period is frozen so past payments cannot be modified.',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.title': 'Are you sure to reset payout schedule to original one?',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.confirm': 'Yes',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.cancel': 'No',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.cta': 'Reset to original payout schedule',
    'editPayoutPaymentRow.payment': 'Payment {{index}}',
    'paymentsList.pastPayments': 'Past payments',
    'paymentsList.upcomingPayments': 'Upcoming payments',
    'paymentsList.hasBeenPaid': '<strong>{{amount}}</strong> ({{percentage}}%) has been paid in the statement of <strong>{{date}}</strong> ({{exactDate}})',
    'paymentsList.willBePaid': '<strong>{{amount}}</strong> ({{percentage}}%) will be paid in the statement of <strong>{{date}}</strong> ({{exactDate}})',
    'paymentsList.withoutPayments': 'Amount of commission without payout scheduled yet: <strong>{{amount}} ({{percentage}}%)</strong>',
    'paymentsSummary.paymentsDone': 'Paid commissions',
    'paymentsSummary.paymentsToBeDone': 'Commissions to be paid',
    'paymentsSummary.paymentsNbPercentage': '($t(paymentsWithCount, {"count": {{paymentsCount}} }), {{percentage}}%)',
    'periodDealCommissionInfoPayments.payments_zero': '{{count}} payments',
    'periodDealCommissionInfoPayments.payments_one': '{{count}} payment',
    'periodDealCommissionInfoPayments.payments_other': '{{count}} payments',
    'periodDealCommissionInfos.viewDetails': 'View details',
    'periodDealCommissionValue.tooltip': 'No new target reached',
    'periodDealOwners.users_zero': '{{count}} users',
    'periodDealOwners.users_one': '{{count}} user',
    'periodDealOwners.users_other': '{{count}} users',
    'periodsPicker.from': 'From',
    'periodsPicker.to': 'To',
    'planColumnEdition.placeholders.variableName': 'Column name',
    'planColumnEdition.placeholders.fields': 'Enter fields',
    'plan.columnFormatter.default': 'Default',
    'plan.columnFormatter.date': 'Date (YYYY-MM-DD)',
    'plan.columnFormatter.datetime': 'Date & Time (YYYY-MM-DD HH:mm)',
    'plan.columnFormatter.custom': 'Custom',
    'planScope.individual.label': 'Individual',
    'planScope.individual.description': 'Individual contributors are commissionned on their own performance',
    'planScope.team.label': 'Team Based Achievement',
    'planScope.team.description': 'The whole team gets commissionned on their performance',
    'planScope.manager.label': 'Manager',
    'planScope.manager.description': 'Calculate commissions based on other people\'s performance',
    'planEditColumnsModal.title': 'Edit displayed columns',
    'planEditColumnsModal.subTitle': 'For {{type}} in plan {{planName}}',
    'planEditColumnsModal.save': 'Save columns',
    'planEditColumnsModal.tip.part1': 'Tip: You can add custom fields in the {{type}}',
    'planEditColumnsModal.tip.link': 'here',
    'planEditColumnsModal.tip.part2': '. (in Enrich {{type}} with new fields)',
    'planEditColumnsModal.error.text': 'Please verify your information. The name of the column is required as well as a formula if the type of display chosen is "Custom".',
    'planEditColumnsModal.resetColumns.popconfirm.title': 'Are you sure to reset columns to default?',
    'planEditColumnsModal.resetColumns.popconfirm.confirm': 'Yes',
    'planEditColumnsModal.resetColumns.popconfirm.cancel': 'No',
    'planEditColumnsModal.resetColumns.popconfirm.cta': 'Reset to default',
    'planEditColumnsModal.dealNameBlock.title': '{{type}} name',
    'planEditColumnsModal.planColumnsBlock.title': 'Columns displayed in the plan table',
    'planEditColumnsModal.addColumn': 'Add a column',
    'planEditColumnsModal.nameColumn.fields': 'Field representing the name of the {{type}}',
    'planEditColumnsModal.column.name': 'Columns Name',
    'planEditColumnsModal.column.fields': '{{type}} field or variable from plan',
    'planEditColumnsModal.column.fields.tooltip': 'If you add multiple fields, they\'ll be concatenated with a space in between.',
    'planEditColumnsModal.column.formatter': 'Display',
    'planProgressGaugeChart.noTarget': 'No target defined',
    'planProgressGaugeWidget.title': 'Progress',
    'planPayoutScheduleWidget.title': 'Payout Schedule',
    'planProgressBarChart.noTarget': 'No target defined',
    'planProgressBarChartWidget.title': 'Progress Over Months',
    'planTotalCommissionsWidget.title': 'Total Commissions',
    'planTotalValueWidget.title': 'Total {{valueDefinition}}',
    'planTotalValueWidget.title.empty': 'Total Value',
    'planDetailsWidget.commissions': 'Commissions',
    'plansPage.title': 'Plans',
    'plans.errors.computation.already.running': 'A computation is already running, please try again later.',
    'plans.success.computeAll.done': 'Refresh data done successfully, the page will be reloaded...',
    'plans.success.computePlan.done': 'Refresh data done successfully',
    'plansUsingQuota.notUsed': 'Not used by any plans yet',
    'plansUsingQuota.link_one': 'Used by {{count}} plan',
    'plansUsingQuota.link_other': 'Used by {{count}} plans',
    'plansUsingQuotaModal.title': 'Plans using',
    'planUsingQuotaItem.view': 'View',
    'planV3CodeCell.errors.type.notebook.python.error': 'Python error occured',
    'planV3CodeCell.errors.type.planv3.codeCell.unexpected.error': 'Unexpected error occured',
    'planV3CodeCell.errors.type.planv3.required.information': 'Required field',
    'planV3CodeCell.errors.type.planv3.required.information.content': 'Code cell requires <strong>{{path}}</strong> information.',
    'planV3CodeCell.errors.type.planv3.codeCell.no.code.provided': 'Empty code cell',
    'planV3CodeCell.errors.type.planv3.commission.no.payment': 'Empty payments',
    'planV3CodeCell.errors.type.planv3.commission.no.payment.content': 'Deal id <strong>{{dealId}}</strong> is missing payments.',
    'planWhatIfProjectionChart.axisBottomLabel': 'Quota reached',
    'planWhatIfProjectionChart.axisRightLabel': 'Commissions',
    'planWhatIfProjectionWidget.title': 'Projection',
    'planWithPeriods.periods': '{{count}} periods',
    'plansWithPeriods.plans': '{{count}} plans',
    'profile.errors.remaining.attempts_zero': 'No attempts remaining.',
    'profile.errors.remaining.attempts_one': '<strong>{{count}}</strong> attempt remaining.',
    'profile.errors.remaining.attempts_other': '<strong>{{count}}</strong> attempts remaining.',
    'profile.errors.invalid.credentials': 'Login error: Invalid credentials',
    'profile.errors.account.locked': 'Login error: account locked for 30 minutes.',
    'profile.errors.reset.password.link.expired': 'The link for updating your password has expired.',
    'profilePage.page.title': 'My Profile',
    'profilePageContent.role': 'Role: {{roleName}}',
    'profilePageContent.logout': 'Logout',
    'queryBuilder.empty': 'Query is empty.',
    'queryBuilder.hideEditor': 'Hide editor',
    'queryBuilder.showEditor': 'Show editor',
    'queryBuilderAndOrBlock.addCondition': 'Add Condition',
    'queryBuilderAndOrBlock.addAndOrBlock': 'Add [AND/OR] Group',
    'queryBuilderAndOrBlock.deleteAndOrBlock': 'Delete [AND/OR] Group',
    'queryBuilderAndOrCondition.fieldName': 'Field Name',
    'queryBuilderAndOrCondition.valueOrCode': 'Value or Code',
    'quota.exportQuota': 'Export Quota',
    'quota.archiveQuota': 'Archive Quota',
    'quota.archiveQuota.title': 'Archive Quota',
    'quota.archiveQuota.content': 'Do you confirm you want to archive <strong>{{quotaName}}</strong> ?',
    'quota.addQuotaUserLeaveDate.title': 'Set User Leave Date',
    'quota.addQuotaUserLeaveDate.save': 'Save',
    'quota.addQuotaUserLeaveDate.description': 'Define when <strong>{{userDisplayName}}</strong> should leave <strong>{{quotaName}}</strong>:',
    'quota.addUsersInQuota': 'Add users in Quota',
    'quota.addUsers': 'Add users',
    'quota.archived': 'This Quota is archived.',
    'quota.changeDefaultValues': 'Default values',
    'quota.createNewONe': 'Create a new Quota',
    'quota.createQuota': 'Create Quota',
    'quota.defaultValue.initial': 'Initial default value',
    'periodYear.from': 'From {{periodName}}',
    'quota.deleteQuota': 'Delete Quota',
    'quota.deleteQuota.confirm': 'I confirm',
    'quota.deleteQuota.title': 'Delete Quota',
    'quota.deleteQuota.content': 'Do you confirm you want to delete <strong>{{quotaName}}</strong> ?',
    'quota.editQuota': 'Edit Quota',
    'quota.errors.user.not.in.scope': 'The user you try to add is not in your scope.',
    'quota.errors.quota.not.found': 'Quota not found.',
    'quota.errors.user.not.in.quota': 'You try to do some update for a user that is not in Quota.',
    'quota.errors.user.not.found': 'User not found.',
    'quota.errors.user.already.in.quota': 'The user is already in Quota.',
    'quota.errors.user.attached.to.plans': 'The user is attached to Plans.',
    'quota.form.name.label': 'Name your Quota',
    'quota.form.name.placeholder': 'Ex: Quota for AEs',
    'quota.form.name.rules.required': 'Please name your Quota.',
    'quota.form.tracked.addTypeToOptions': 'Add type',
    'quota.form.tracked.label': 'What is tracked in the Quota?',
    'quota.form.tracked.placeholder': 'Ex: ARR, New ARR, Meeting, Revenue...',
    'quota.form.tracked.rules.required': 'Please select or create a tracked type.',
    'quota.form.tracked.searchNotFound': 'No existing type matches',
    'quota.form.frequency.label': 'What is the frequency for this Quota?',
    'quota.form.beginAt.label': 'Start date',
    'quota.form.beginAt.rules.required': 'Please select a start date',
    'quota.form.frequencyForDay.label': 'Duration of a period in days',
    'quota.form.frequencyForDay.description': 'Enter 7 for a weekly quota',
    'quota.form.frequencyForDay.rules.required': 'Please choose a duration',
    'quota.form.defaultValue.label': 'What is the default value for the Quota?',
    'quota.form.defaultValueForTeam.label': 'What is the default target of the Team?',
    'quota.form.defaultValueForCustomAndTeam.label': 'What is the target of the Team during this custom dates based period?',
    'quota.form.defaultValue.description': 'Reps added to this Quota will be initialised with this value',
    'quota.lockQuota': 'Lock Quota',
    'quota.removeUserFromQuota.confirm': 'I confirm',
    'quota.removeUserFromQuota.title': 'Remove {{firstName}} From Quota',
    'quota.removeUserFromQuota.content': 'Do you confirm you want to remove <strong>{{userDisplayName}}</strong> from <strong>{{quotaName}}</strong> ?',
    'quota.showDefaultValues': 'Show default values',
    'quotasPage.title': 'Quotas configuration',
    'quotasPerformancePage.title': 'Quotas performance',
    'quotasPerformancePage.empty.description': 'No Dashboard available yet',
    'quotasPerformance.createDashboard.label': 'Create a new Dashboard',
    'quotasPerformance.createDashboard.modal.title': 'Create a new Dashboard',
    'quotasPerformance.createDashboard.modal.button.create': 'Create Dashboard',
    'quotasPerformance.createDashboard.modal.form.name': 'Name your Dashboard',
    'quotasPerformance.createDashboard.modal.form.name.placeholder': 'Name your Dashboard',
    'quotasPerformance.createDashboard.modal.form.name.required': 'Please name your Dashboard.',
    'quotasPerformance.createDashboard.modal.form.description': 'Description',
    'quotasPerformance.addPlanSelectors.field.label': 'Add Plans',
    'quotasPerformance.addPlanSelectors.select.placeholder': 'Choose a Plan',
    'quotasPerformance.addPlanSelectors.addButton.label': 'Add a Plan',
    'quotasPerformance.removeDashboard.popconfirm.label': 'Are you sure to delete this Dashboard?',
    'quotasPerformance.removeDashboard.tooltip.label': 'Delete this Dashboard',
    'quotasPerformance.addPlansToDashboard.label': 'Add Plans',
    'quotasPerformance.details.header.emptyDescription': 'No description',
    'quotasPerformance.editableField.required.error': 'This field is required.',
    'quotasPerformance.addPlansToDashboard.modal.title': 'Add Plans to this Dashboard',
    'quotasPerformance.addPlansToDashboard.modal.button.addPlans': 'Add Plans',
    'quotasPerformance.detailsContent.noData.message': 'There are no Plans in this Dashboard',
    'quotasPerformance.detailsContent.noData.description': 'Click on the Add Plans button to start adding Plans',
    'quotasPerformance.errors.dashboard.not.found': 'Dashboard not found.',
    'quotasPerformance.dashboardPlan.metricTracked': 'Metric tracked:',
    'quotasPerformance.dashboardPlan.noMetricTracked': 'No metric tracked',
    'quotasPerformance.dashboardPlan.noMetricTracked.alert.message': 'There isn\'t any metric tracked for this plan',
    'quotasPerformance.dashboardPlan.planDetails': 'See plan details',
    'quotasPerformance.removeDashboardPlan.popconfirm.label': 'Are you sure to delete this quota achievement?',
    'quotasPerformance.removeDashboardPlan.tooltip.label': 'Delete this quota achievement',
    'quotasPerformance.dashboardPlan.entity.label.user': 'Users',
    'quotasPerformance.dashboardPlan.summary.label.average': 'Total average',
    'quotasPerformance.dashboardPlan.summary.label.median': 'Total median',
    'quota.isSaving': 'Saving...',
    'quota.saved': 'Saved',
    'quota.total': 'Total',
    'quota.userSearch.placeholder': 'Search a user',
    'quota.outOfScopeWarning.message_one': '{{count}} user is hidden because she/he is out of your scope.',
    'quota.outOfScopeWarning.message_other': '{{count}} users are hidden because they are out of your scope.',
    'quotaDetailContent.alert.noData.message': 'There are no users in this Quota',
    'quotaDetailContent.alert.noData.description': 'Click on the add button to start adding users',
    'quotaList.empty.description': 'No Quota available yet',
    'quotaList.filters.active': 'Active',
    'quotaList.filters.archived': 'Archived',
    'quotaSetupPanel.alert.noQuotas.message': 'No quotas available',
    'quotaSetupPanel.alert.noQuotas.description': 'Click on "$t(quota.createNewONe)" button or go to Quotas page to create a new Quota.',
    'quotaSetupPanel.quotaSelector.placeholder': 'Choose a Quota',
    'quotaSetupPanel.missingUsersInQuota.alert.message': '$t(usersWithCountToBe, {"count": {{count}} }) missing in the Quota.',
    'quotaSetupPanel.doYouConfirmQuestion': 'Do you confirm to choose this Quota and add missing users into the selected Quota?',
    'quotaSetupPanel.confirmBtnLabel': 'I confirm',
    'quotaTab.cancel': 'Cancel',
    'quotaTab.unlinkConfirmModal.title': 'Do you want to unlink the Quota from the Plan?',
    'quotaTab.unlinkConfirmModal.content': 'Please confirm you\'d like to unlink the Quota. The plan will no longer have a Quota.',
    'quotaTab.unlinkConfirmModal.confirm': 'Confirm',
    'quotaTab.linkQuota': 'Link selected Quota',
    'quotaTab.targetWillBeMissed.description': 'Please note that the current targets defined in the plan will be lost when switching to a Quota.',
    'quotaTab.targetWillBeMissed.message': 'Current targets will be lost',
    'quotaTab.title': 'Quota Setup',
    'quotaTab.unlinkQuota': 'Unlink',
    'quotaTab.updatePlanSuccess': 'Successfully updated Plan',
    'quotaTab.updatePlanError': 'Error while updating Plan',
    'quotaTableBodyCell.resetOverwriteBtn.tooltip': 'Reset to default value',
    'quotaTableBodyCell.notOnboardedYet.tooltip': 'Not onboarded yet',
    'quotaTableBodyCell.offboarded.tooltip': 'Offboarded',
    'quotaTableBodyCell.notInPlanYet.tooltip': 'Not in plan yet',
    'quotaTableBodyCell.leftThePlan.tooltip': 'Left the plan',
    'quotaTargetStrategy.sum': 'Sum quota of users',
    'quotaTargetStrategy.match': 'Use quota of the manager(s)',
    'quotaUserModal.quotasFor': 'Quotas for',
    'rawData.button.addItem': 'Add an item',
    'rawData.button.addProperty': 'Add a property',
    'rawData.lastEditedAt': 'Edited {{timeAgo}}',
    'rawData.search.placeholder': 'Search a property or a value',
    rawDataCollapseItemsConditions_zero: 'items',
    rawDataCollapseItemsConditions_one: 'item',
    rawDataCollapseItemsConditions_other: 'items',
    rawDataCollapseItemsConditionsWithCount_zero: '({{count}} $t(rawDataCollapseItemsConditions_zero))',
    rawDataCollapseItemsConditionsWithCount_one: '({{count}} $t(rawDataCollapseItemsConditions_one))',
    rawDataCollapseItemsConditionsWithCount_other: '({{count}} $t(rawDataCollapseItemsConditions_other))',
    rawDataCollapsePropertiesConditions_zero: 'properties',
    rawDataCollapsePropertiesConditions_one: 'property',
    rawDataCollapsePropertiesConditions_other: 'properties',
    rawDataCollapsePropertiesConditionsWithCount_zero: '({{count}} $t(rawDataCollapsePropertiesConditions_zero))',
    rawDataCollapsePropertiesConditionsWithCount_one: '({{count}} $t(rawDataCollapsePropertiesConditions_one))',
    rawDataCollapsePropertiesConditionsWithCount_other: '({{count}} $t(rawDataCollapsePropertiesConditions_other))',
    'rawData.modals.add.title': 'Add a property on {{type}}',
    'rawData.modals.add.confirm': 'Add the property',
    'rawData.modals.edit.disclaimer': 'Value is limited to string here but can be changed by editing the property itself.',
    'rawData.modals.edit.title': 'Edit a property on {{type}}',
    'rawData.modals.edit.subtitle.item': 'In position {{position}} of "{{parent}}"',
    'rawData.modals.edit.subtitle.property': 'In "{{parent}}" property',
    'rawData.modals.edit.confirm': 'Edit the property',
    'rawData.modals.edit.notEditable': 'Not editable here',
    'rawData.modals.edit.propertyName': 'Name of the property',
    'rawData.modals.edit.propertyType': 'Type of the property',
    'rawData.modals.edit.propertyValue': 'What\'s the value of the property?',
    'rawData.modals.edit.propertyValuePlaceholder': 'Value of the property',
    'rawData.modals.edit.rules.propertyName': 'Please name your property with a valid variable name (no special character, e.g.: my_field_name).',
    'rawData.modals.edit.rules.propertyValue': 'Please add a value to your property.',
    'rawData.modals.edit.rules.propertyDate': 'Please add a valid date to your property.',
    'rawData.modals.edit.rules.propertyBoolean': 'Please select a value to your property.',
    'rawData.modals.edit.rules.propertyNumber': 'Please add a number to your property.',
    'rawData.modals.deleteProperty.title': 'Delete a property on {{type}}',
    'rawData.modals.deleteProperty.content': 'You are going to delete the line. Are you sure you want to perform this action?',
    'rawData.modals.deleteProperty.confirm': 'Delete the property',
    'rawData.modals.storedAs': 'Stored as: {{value}}',
    'rawData.modals.editOriginalFormatLabel': '(click here to edit original format)',
    'rawData.versionHistory.button': 'Version history',
    'rawData.historyDrawer.title': 'Version history',
    'rawData.historyDrawer.rollbackOriginalValue': 'Rollback to original value',
    'rawData.historyDrawer.rollback': 'Rollback',
    'rawData.historyDrawer.rollbackTooltip': 'Rollback to this version',
    'rawData.historyDrawer.setTo': 'has been set to {{value}}',
    'rawData.historyDrawer.deletedTo': 'has been deleted',
    'rawData.rollbackModal.title': 'Confirm you want to rollback?',
    'rawData.rollbackModal.content': 'Please confirm that you want to rollback to a previous version.',
    rawDataHistoryConditions_zero: 'overwrites',
    rawDataHistoryConditions_one: 'overwrite',
    rawDataHistoryConditions_other: 'overwrites',
    rawDataHistoryConditionsWithCount_zero: '{{count}} $t(rawDataHistoryConditions_zero)',
    rawDataHistoryConditionsWithCount_one: '{{count}} $t(rawDataHistoryConditions_one)',
    rawDataHistoryConditionsWithCount_other: '{{count}} $t(rawDataHistoryConditions_other)',
    'rawDataEntry.storedAs': 'Stored as: {{value}}',
    'rawDataPanelTitleNode.badge.new': '+ Added',
    'rawDataPanelTitleNode.badge.old': '- Deleted',
    'rawDataTreeRecursive.recursivityDepthLimitReached': 'You can\'t explore deeper, please contact your csm',
    'removeMemberFromTeamModal.title': 'Remove member',
    'removeMemberFromTeamModal.confirm': 'I confirm',
    'removeMemberFromTeamModal.content': 'Do you confirm you want to remove <strong>{{userName}}</strong> from <strong>{{teamName}}</strong>?',
    'resendInviteUserLink.popconfirm.title': 'Are you sure to resend invitation to this user?',
    'resendInviteUserLink.label': 'Resend invitation',
    'resetPasswordForm.form.password.label': 'Password',
    'resetPasswordForm.form.password.placeholder': 'Your password',
    'resetPasswordForm.form.password.rules.isNotValid': 'Please enter a valid password.',
    'resetPasswordForm.form.password.rules.required': 'Please enter your password.',
    'resetPasswordForm.form.passwordConfirm.label': 'Password confirmation',
    'resetPasswordForm.form.passwordConfirm.placeholder': 'Write again your password',
    'resetPasswordForm.form.passwordConfirm.rules.isDifferent': 'You need enter the same password as above.',
    'resetPasswordForm.form.passwordConfirm.rules.required': 'Please confirm your password.',
    'resetPasswordForm.form.default.submit': 'Update my password',
    'resetPasswordForm.form.isNewUser.submit': 'Create my account',
    'resetPasswordPageContent.default.title': 'Reset your password',
    'resetPasswordPageContent.isNewUser.subtitle': 'Set your password to get started',
    'resetPasswordPageContent.isNewUser.title': 'Welcome at Palette!',
    'resetPasswordPageContent.login': 'Login',
    'resource.openIn': 'Open in {{connectorName}}',
    'resourceConnectorsSelect.resourcesOption.label': '{{type}} ({{connector}})',
    'resourceConnectorsSelect.category.connectors': 'Connectors',
    'resourceConnectorsSelect.category.notebooks': 'Models',
    'resourceObjectSample.title': '{{resourceType}} example',
    'resourceObjectSample.columns.name.label': 'Name',
    'resourceObjectSample.columns.value.label': 'Value',
    'resourceObjectSample.propSearch.placeholder': 'Search for a field',
    'resources.documentation.rawData': 'Documentation about Raw data and overrides',
    'resources.errors.object.not.found': 'The resource was not found.',
    'resources.propertyTypes.STRING': 'String',
    'resources.propertyTypes.DATE': 'Date',
    'resources.propertyTypes.BOOLEAN': 'Boolean',
    'resources.propertyTypes.ARRAY': 'List',
    'resources.propertyTypes.OBJECT': 'Nested properties',
    'resources.propertyTypes.NUMBER': 'Number',
    'resources.index.header.title': 'Resources available',
    'resources.index.header.tabs.connectors': 'Connectors',
    'resources.index.header.tabs.notebooks': 'Models',
    'resources.index.header.tabs.from.label': '(from {{name}})',
    'resources.index.main.empty.text': 'No Data available',
    'resources.table.actions.button.view_details': 'View details',
    'resources.table.actions.button.edit_columns': 'Edit columns',
    'resources.actions.search.placeholder': 'Quick search',
    'resources.filter.modal.title': 'Query Builder',
    'resources.filter.modal.ok.label': 'Filter',
    'resources.filter.modal.queryBuilder.empty.label': 'Query builder is empty.',
    'resources.details.backLink.label': 'Back to resources',
    'resources.details.tabs.rawData': 'Raw Data',
    'resources.details.tabs.commissions': 'Commissions',
    'resources.details.summary.report': 'from "{{reportName}}" report',
    'resources.details.summary.clonedFrom': '{{type}} cloned from this one',
    'resources.details.summary.externalLink': 'Open in {{connector}}',
    'resources.details.delete.popconfirm.label': 'Are you sure to delete this {{type}}?',
    'resources.details.delete.tooltip.label': 'Delete this {{type}}',
    'resources.details.clone.popconfirm.label': 'Are you sure to duplicate this {{type}}?',
    'resources.details.clone.tooltip.label': 'Duplicate this {{type}}',
    'resourceLastSyncDates.button.label': 'Reset last sync dates',
    'resourceLastSyncDates.modal.title': 'Reset last sync dates',
    'resourceLastSyncDates.modal.save.label': 'Save',
    'resourceLastSyncDates.modal.dateField.label': 'On which date would you reset last sync?',
    'resourceLastSyncDates.modal.dateField.error.message': 'Please select a valid date.',
    'resourceConfiguration.configure.resource': 'Configure resource',
    'resourceConfiguration.configure.tooltip.disabled': 'Please save the resource first',
    'resourceConfiguration.configure.tooltip.noconfig': 'Please configure the resource at least once',
    'resourceConfiguration.modal.title': '{{resourceType}} - Configuration',
    'resourceConfiguration.modal.introduction': 'Select the resource fields you want to sync with the connector.',
    'resourceConfiguration.modal.save.label': 'Save',
    'resourceConfiguration.modal.field.required': 'Required',
    'resourceConfiguration.modal.field.used': 'Used in some column fields',
    'resourceConfiguration.modal.search.placeholder': 'Search',
    'resourceConfiguration.modal.reset.label': 'Reset configuration',
    'resourceConfiguration.modal.reset.title': 'Do you confirm you want unselect all fields below?',
    'roleBreadcrumb.newRole': 'New Role',
    'roleDetailsPageContent.isProtectedAlert': 'This role is protected and cannot be edited.',
    'rolesListItem.users_zero': 'No users',
    'rolesListItem.users_one': '{{count}} user',
    'rolesListItem.users_other': '{{count}} users',
    'rolesListItem.isDefault': ' (Default)',
    'rolesListItemMoreOptionsMenu.duplicateRole': 'Duplicate Role',
    'rolesListItemMoreOptionsMenu.deleteRole': 'Delete Role',
    'rolesListItemMoreOptionsMenu.makeDefaultRole': 'Make Default',
    'roleManagement.adminRightsLabel': 'Manager/Admin permissions',
    'roleManagement.icRightsLabel': 'Individual Contributor permissions',
    'roleManagement.saveButtonLabel': 'Save Role',
    'roleManagement.rights.descriptions.admin.adjustments.manage': 'Can <strong>Create</strong>, <strong>Update</strong> & <strong>Delete</strong> corrections at statement level',
    'roleManagement.rights.descriptions.admin.commissions.manage': 'Can <strong>Update</strong> amounts & <strong>Cancel</strong> future payments',
    'roleManagement.rights.descriptions.admin.company.manage': 'Can <strong>Update</strong> global Palette settings (Billing, Currencies, Exchange rates, Statement validations, ...)',
    'roleManagement.rights.descriptions.admin.connectors.create': 'Can <strong>Create</strong> connector(s)',
    'roleManagement.rights.descriptions.admin.connectors.display': 'Can <strong>Access</strong> Connectors pages & features',
    'roleManagement.rights.descriptions.admin.connectors.manage': 'Can <strong>Update</strong> connectors settings',
    'roleManagement.rights.descriptions.admin.connectors.view': 'Can <strong>Access</strong> connectors settings',
    'roleManagement.rights.descriptions.admin.objects.csv_upload': 'CSV Upload',
    'roleManagement.rights.descriptions.admin.objects.display': 'Can <strong>Access</strong> Resources pages & features',
    'roleManagement.rights.descriptions.admin.objects.manage': 'Overwrite properties',
    'roleManagement.rights.descriptions.admin.objects.view': 'Can <strong>Access</strong> resources settings',
    'roleManagement.rights.descriptions.admin.plans.create': 'Can <strong>Create</strong> plan(s)',
    'roleManagement.rights.descriptions.admin.plans.delete': 'Can <strong>Delete</strong> plan(s)',
    'roleManagement.rights.descriptions.admin.plans.display': 'Can <strong>Access</strong> Plans pages & features',
    'roleManagement.rights.descriptions.admin.plans.update': 'Can <strong>Update</strong> general plans settings',
    'roleManagement.rights.descriptions.admin.plans.update_connector': 'Can <strong>Update</strong> connector settings (advanced configuration)',
    'roleManagement.rights.descriptions.admin.plans.view': 'Can <strong>Access</strong> plans, rewards & rankings settings',
    'roleManagement.rights.descriptions.admin.plans.gbo.manage': 'Can <strong>Add</strong> & <strong>Delete</strong> users from a plan',
    'roleManagement.rights.descriptions.admin.quotas.display': 'Can <strong>Access</strong> Quotas pages & features',
    'roleManagement.rights.descriptions.admin.quotas.manage': 'Can <strong>Create</strong>, <strong>Update</strong> & <strong>Delete</strong> default values',
    'roleManagement.rights.descriptions.admin.quotas.view': 'Can <strong>Access</strong> quotas settings',
    'roleManagement.rights.descriptions.admin.quotas.users.manage': 'Can <strong>Add</strong> & <strong>Delete</strong> users from a quota',
    'roleManagement.rights.descriptions.admin.notebooks.display': 'Can <strong>Access</strong> Models pages & features',
    'roleManagement.rights.descriptions.admin.notebooks.manage': 'Can <strong>Create</strong>, <strong>Update</strong> & <strong>Delete</strong> model(s)',
    'roleManagement.rights.descriptions.admin.notebooks.view': 'Can <strong>Access</strong> models settings',
    'roleManagement.rights.descriptions.admin.roles.display': 'Can <strong>Access</strong> Roles pages & features',
    'roleManagement.rights.descriptions.admin.roles.view': 'Can <strong>Access</strong> roles settings',
    'roleManagement.rights.descriptions.admin.roles.manage': 'Can <strong>Create</strong>, <strong>Update</strong> & <strong>Delete</strong> role(s)',
    'roleManagement.rights.descriptions.admin.statements.validations.manage': 'Can <strong>Approve</strong> & <strong>Delete</strong> Statements',
    'roleManagement.rights.descriptions.admin.teams.manage': 'Can <strong>Create</strong>, <strong>Update</strong> & <strong>Delete</strong> team(s)',
    'roleManagement.rights.descriptions.admin.teams.members.manage': 'Can <strong>Add</strong>, <strong>Update</strong> & <strong>Delete</strong> users from a team',
    'roleManagement.rights.descriptions.admin.users.display': 'Can <strong>Access</strong> Users pages & features',
    'roleManagement.rights.descriptions.admin.users.invite': 'Can <strong>Invite</strong> user(s) to Palette',
    'roleManagement.rights.descriptions.admin.users.toggle-activate': 'Can <strong>Delete</strong> users',
    'roleManagement.rights.descriptions.admin.users.update': 'Can <strong>Update</strong> users settings',
    'roleManagement.rights.descriptions.admin.users.view': 'Can <strong>Access</strong> users settings',
    'roleManagement.rights.descriptions.admin.wildcard': 'Has full access to all pages, features and has all permissions',
    'roleManagement.rights.descriptions.ic.compensation': 'Can <strong>Access</strong> IC\'s statements & plans for which IC is commissioned for',
    'roleManagement.rights.descriptions.ic.dashboard.update': 'Can <strong>Update</strong> dashboard display & Can <strong>Add</strong>, <strong>Update</strong>, <strong>Delete</strong> widget(s)',
    'roleManagement.rights.scope.options.labels.none': 'None',
    'roleManagement.rights.scope.options.labels.view_scoped': 'Scoped (can only access user data of the teams he/she leads)',
    'roleManagement.rights.scope.options.labels.view_all': 'All (can see all company\'s users data)',
    'roleManagement.sections.admin.commissions': 'Commissions',
    'roleManagement.sections.admin.company': 'Company',
    'roleManagement.sections.admin.connectors': 'Connectors',
    'roleManagement.sections.admin.objects': 'Resources',
    'roleManagement.sections.admin.plans': 'Plans',
    'roleManagement.sections.admin.quotas': 'Quotas',
    'roleManagement.sections.admin.roles': 'Roles',
    'roleManagement.sections.admin.scope': 'Scope',
    'roleManagement.sections.admin.teams': 'Teams',
    'roleManagement.sections.admin.users': 'Users',
    'roleManagement.sections.admin.notebooks': 'Models',
    'roleManagement.sections.admin.wildcard': 'Full Admin',
    'roleManagement.sections.ic.dashboard': 'Dashboard',
    'roleManagement.sections.ic.plans': 'Plans',
    'role.createNewRole': 'New Role',
    'role.createNewRole.initialName': 'New role',
    'role.createRole': 'Create Role',
    'role.form.name.label': 'Name',
    'role.form.name.rules.required': 'Please name your role.',
    'role.form.name.placeholder': 'Role name',
    'role.form.description.label': 'Description',
    'role.form.description.rules.required': 'Please add a description to your role',
    'role.form.description.placeholder': 'Role description',
    'rolesPage.page.title': 'Roles',
    'rulesCalculationForm.value.placeholder': 'Formula or Amount',
    'rulesCalculationForm.value.error.required': 'Please enter formula or amount',
    'rulesCalculationForm.useTiers.label': 'Use Tiers',
    'rulesCalculationForm.delete.PER_OBJECT': 'Delete Tier',
    'rulesCalculationForm.delete.PER_TARGET': 'Delete Target',
    'rulesCalculationForm.addA.PER_OBJECT': 'Add a Tier',
    'rulesCalculationForm.addA.PER_TARGET': 'Add a Target',
    'rulesCalculationForm.useValueFor.PER_OBJECT': 'Use value for Tiers',
    'rulesCalculationForm.useValueFor.PER_TARGET': 'Use value for Targets',
    'rulesCalculationForm.usePercentageFor.PER_OBJECT': 'Use percentage for Tiers',
    'rulesCalculationForm.usePercentageFor.PER_TARGET': 'Use percentage for Targets',
    'rulesCalculationForm.from': 'From',
    'rulesCalculationForm.to': 'To',
    'rulesCalculationForm.fromTo.error.required': 'Required',
    'rulesCalculationForm.formula': 'Formula',
    'rulesPayoutItemForm.delete': 'Delete this payment rule',
    'rulesPayoutItemForm.title': 'Payment {{position}}',
    'rulesPayoutItemForm.percentage.label': 'Percentage of the commission (formula)',
    'rulesPayoutItemForm.percentage.placeholder': 'Percentage (e.g.: 0.5 or 1)',
    'rulesPayoutItemForm.percentage.disclaimer': 'E.g.: 1 for 100% of the commission',
    'rulesPayoutItemForm.percentage.error.required': 'Please enter a percentage',
    'rulesPayoutItemForm.repeat.label': 'Repeat (formula)',
    'rulesPayoutItemForm.repeat.placeholder': 'Repeat (number of payments)',
    'rulesPayoutItemForm.repeat.disclaimer': 'E.g.: 1 for 1 payment, 2 for 2 different payments',
    'rulesPayoutItemForm.repeat.error.required': 'Please enter the repeat',
    'rulesPayoutItemForm.startingAt.label': 'Starting at (formula)',
    'rulesPayoutItemForm.startingAt.placeholder': 'Starting date',
    'rulesPayoutItemForm.startingAt.disclaimer': 'E.g.: Period.endAt for a payment at the end of the period',
    'rulesPayoutItemForm.startingAt.error.required': 'Please enter a starting date formula',
    'rulesPayoutItemForm.switchIntervalFormula.label': 'Switch interval formula type',
    'rulesPayoutItemForm.intervalCount.label': 'Interval count',
    'rulesPayoutItemForm.intervalCount.error.required': 'Please enter an interval above 0',
    'rulesPayoutItemForm.intervalType.label': 'Interval type',
    'rulesPayoutItemForm.intervalFormula.label': 'Interval (formula)',
    'rulesPayoutItemForm.intervalFormula.error.required': 'Please enter the interval',
    'rulesPayoutItemForm.intervalType.month': 'Month',
    'rulesPayoutItemForm.intervalType.day': 'Day',
    'rulesPayoutItemForm.intervalType.year': 'Year',
    'select.addToOptions': 'Add to options',
    'select.searchNotFound': 'No option matches',
    'selectUserResourcesModal.title': 'Select Resources',
    'selectUserResourcesModal.okText': 'Select',
    'sendFeedbackModal.alertMessage.hint': 'What do you think of Palette?',
    'sendFeedbackModal.alertMessage.explanation': 'Please share with us any issues you had or what can be improved.',
    'sendFeedbackModal.title': 'Send Feedback',
    'sendFeedbackModal.send': 'Send feedback',
    'sendFeedbackModal.form.content.label': 'Feedback',
    'sendFeedbackModal.form.content.rules.required': 'Please enter a feedback',
    'sendFeedbackModal.form.content.placeholder': 'Write your feedback here',
    'sendFeedbackModal.uploadAttachments': 'You can also upload attachments',
    'settingsPage.page.title': 'Settings',
    'showVariableValueHistoryModal.showHistoryModalTitle': 'Value History: {{variableName}}',
    'showVariableValueHistoryModal.showHistoryModalSubTitle': 'For {{userDisplayName}}',
    'showVariableValueHistoryModal.columns.from.header': 'From',
    'showVariableValueHistoryModal.columns.from.defaultValue': 'Initial default value',
    'showVariableValueHistoryModal.columns.value.header': 'Value',
    'showVariableValueHistoryModal.columns.dateValue.header': 'Date (UTC+0)',
    'sidebarCollapser.hide': 'Hide',
    'superAdminBanner.content': 'Secured connection - You are connected on a client\'s account.',
    statements_zero: 'statements',
    statements_one: 'statement',
    statements_other: 'statements',
    'statements.page.title': 'Statements',
    'statement.statuses.pendingActions': 'Pending actions',
    'statement.statuses.approvalRequired': 'Approval required',
    'statement.statuses.paymentRequired': 'Payment required',
    'statement.statuses.paid': 'Paid',
    'statement.statuses.inFuture': 'In Future',
    'statement.statuses.emptyStatements': 'Empty statements',
    'statement.statuses.periodUser.paid': 'Statement paid',
    'statement.statuses.periodUser.approved': 'Approved',
    'statement.counters.label.correctionsCount_one': '{{count}} correction',
    'statement.counters.label.correctionsCount_other': '{{count}} corrections',
    'statement.statuses.paid.amount': 'Paid: {{amount}}',
    'statement.history.empty.description': 'No changes have been on this statement yet.',
    'statement.history.list.entity.plan': 'Plan',
    'statement.history.list.entity.commission': 'Commission',
    'statement.history.list.entity.deal': 'Deal',
    'statement.history.list.entity.user': 'User',
    'statement.history.list.entity.statement': 'Statement',
    'statement.history.list.title.plan_general_settings_update': 'General settings update',
    'statement.history.list.content.plan_general_settings_update': 'updated general settings for <0>{{name}}</0>.',
    'statement.history.list.title.plan_commission_rules_update': 'Commission rules update',
    'statement.history.list.content.plan_commission_rules_update': 'updated commission rules for <0>{{name}}</0>.',
    'statement.history.list.title.plan_payout_rules_update': 'Payout rules update',
    'statement.history.list.content.plan_payout_rules_update': 'updated payout rules for <0>{{name}}</0>.',
    'statement.history.list.title.plan_delete': 'Delete',
    'statement.history.list.content.plan_delete': 'deleted <strong>{{name}}</strong> plan.',
    'statement.history.list.title.plan_dependencies_update': 'Dependencies update',
    'statement.history.list.content.plan_dependencies_update': 'updated dependencies for <0>{{name}}</0>.',
    'statement.history.list.title.plan_source_track_update': 'Tracked resources update',
    'statement.history.list.content.plan_source_track_update': 'updated tracked resources for <0>{{name}}</0>.',
    'statement.history.list.title.plan_user_track_update': 'User resources update',
    'statement.history.list.content.plan_user_track_update': 'updated user resources for <0>{{name}}</0>.',
    'statement.history.list.title.commission_value_overwrite': 'Amount overwrite',
    'statement.history.list.content.commission_value_overwrite': 'modified commission <0>{{name}}</0> ({{previousAmount}}) amount, related to the plan <1>{{planName}}</1>, from <strong>{{previousAmount}}</strong> to <strong>{{currentAmount}}</strong>.',
    'statement.history.list.title.commission_deal_value_overwrite': 'Deal value overwrite',
    'statement.history.list.content.commission_deal_value_overwrite': 'modified commission <0>{{name}}</0> ({{amount}}) deal value, related to the plan <1>{{planName}}</1>, from <strong>{{previousValue}}</strong> to <strong>{{currentValue}}</strong>.',
    'statement.history.list.title.commission_payout_overwrite': 'Payment overwrite',
    'statement.history.list.content.commission_payout_overwrite': 'modified commission <0>{{name}}</0> ({{amount}}) payments, related to the plan <1>{{planName}}</1>.',
    'statement.history.list.title.resource_update': 'Raw data update',
    'statement.history.list.content.resource_update': 'updated <strong>{{fieldPath}}</strong> on <0>{{name}}</0> raw data.',
    'statement.history.list.content.resource_update.reset': 'reset <0>{{name}}</0> raw data.',
    'statement.history.list.title.user_add_in_plan': 'User has been added to plan',
    'statement.history.list.content.user_add_in_plan': 'added <0>{{payee}}</0> to plan <1>{{name}}</1>.',
    'statement.history.list.title.user_delete_from_plan': 'User has been deleted from plan',
    'statement.history.list.content.user_delete_from_plan': 'removed <0>{{payee}}</0> from plan <1>{{name}}</1>.',
    'statement.history.list.title.statement_correction_apply': 'Correction applied',
    'statement.history.list.content.statement_correction_apply': 'applied the correction of <strong>{{amount}}</strong> related to the <0>{{date}}</0> commission statement.',
    'statement.history.list.title.statement_correction_reject': 'Correction ignored',
    'statement.history.list.content.statement_correction_reject': 'ignored the correction of <strong>{{amount}}</strong> related to the <0>{{date}}</0> commission statement.',
    'statement.history.list.title.statement_correction_applied_update': 'Correction applied update',
    'statement.history.list.content.statement_correction_applied_update': 'updated an applied correction approved by <strong>{{user}}</strong> from <strong>{{previousAmount}}</strong> to <strong>{{newAmount}}</strong>.',
    'statement.history.list.title.statement_correction_applied_delete': 'Correction applied delete',
    'statement.history.list.content.statement_correction_applied_delete': 'deleted an applied correction approved by <strong>{{user}}</strong> of <strong>{{amount}}</strong>.',
    'statement.history.list.title.statement_correction_apply_manually': 'Correction added manually',
    'statement.history.list.content.statement_correction_apply_manually': 'manually added a correction approved by <strong>{{user}}</strong> of <strong>{{amount}}</strong>.',
    'statement.history.list.title.statement_corrected': 'Corrected',
    'statement.history.list.content.statement_corrected': '<strong>{{action}}</strong> a corrected amount of <strong>{{amount}}</strong> in the <0>{{date}}</0> commission statement.',
    'statement.history.list.content.statement_corrected_approved': 'accepted',
    'statement.history.list.content.statement_corrected_refused': 'ignored',
    'statementPeriodsDates.type.custom': 'Custom',
    'statementPeriodsDates.type.year': 'Year {{year}}',
    'statementPeriodsDates.type.halfYear': 'H{{halfYear}} {{year}}',
    'statementPeriodsDates.type.quarter': 'Q{{quarter}} {{year}}',
    'statementPeriodsList.noPeriods': 'No statement periods found for the selected date range.',
    'statementPeriodsListItem.total': 'Total ·',
    'statementPeriodsListItem.exportAll': 'Export all user statements',
    'statementStrategyTypes.long.week': 'Week {{period}}',
    'statementStrategyTypes.short.week': 'W{{period}}',
    'statementStrategyTypes.long.biWeek': 'BiWeek {{period}}',
    'statementStrategyTypes.short.biWeek': 'BW{{period}}',
    'statementStrategyTypes.long.halfMonth': 'Period {{period}}',
    'statementStrategyTypes.short.halfMonth': 'P{{period}}',
    'statementsSnapshotsExport.page.title': 'Statements snapshots export',
    'statementsSnapshotsExport.page.subtitle': 'List snapshots for approved or paid statements',
    'statementsSnapshotsExport.submit.button.label': 'List snapshots',
    'statementsSnapshotsExport.empty.description': 'No snapshots to show.',
    'statementsSnapshotsExportListItem.export.label': 'Export snapshot',
    'statementsSnapshotsExportListItem.actions.approved': 'approved',
    'statementsSnapshotsExportListItem.actions.paid': 'marked as paid',
    'statementsSnapshotsExportListItem.didActionOn': '{{action}} on {{date}}',
    'statementStrategy.intro': 'Your current statement cycle is',
    'statementStrategy.modal.title': 'Update Statement Cycle',
    'statementStrategy.modal.disclaimer.content': 'You are about to update your statement cycle. After this update, all statements that are not marked as paid will be deleted and replaced by new statements based on the new cycle.',
    'statementStrategy.modal.save.label': 'Update Cycle',
    'statementStrategy.modal.selectStrategy': 'Select your cycle',
    'statementStrategy.modal.selectStrategy.placeholder': 'Select your cycle',
    'statementStrategy.modal.startStrategyAt': 'Start cycle at',
    'statementStrategy.block.title': 'Statement Cycle',
    'statementStrategy.modal.startStrategyAt.error.message': 'Please select a valid date.',
    'statementStrategy.modal.strategyType.error.message': 'Please select a cycle type.',
    'statementStrategy.propertyTypes.MONTH': 'Monthly',
    'statementStrategy.propertyTypes.HALF_MONTH': 'Semi-Monthly',
    'statementStrategy.propertyTypes.BI_WEEK': 'Biweekly',
    'statementStrategy.propertyTypes.WEEK': 'Weekly',
    'statementStrategy.weekBegins': 'Week begins on {{day}}',
    'statementStrategy.weekBegins.EU': 'Monday',
    'statementStrategy.weekBegins.US': 'Sunday',
    'statementStrategy.monthBreaks': 'Breaks on the {{break}} of the month',
    'statementStrategy.monthBreaks.FIFTEENTH': '15th',
    'statementStrategy.monthBreaks.SIXTEENTH': '16th',
    'statementStrategy.modal.selectRoll': 'Start week on',
    'statementStrategy.modal.selectRoll.placeholder': 'Start week on',
    'statementStrategy.modal.strategyRoll.error.message': 'Please select a roll type.',
    'statementStrategy.monthBreaks.types': 'The {{break}} of the month',
    'statementStrategy.modal.selectBreak': 'Break semi-monthly period on',
    'statementStrategy.modal.selectBreak.placeholder': 'Break semi-monthly period on',
    'statementStrategy.modal.selectBreak.error.message': 'Please select a semi-monthly pivot.',
    'statementStrategy.history.tooltip.label': 'See history',
    'statementStrategy.history.modal.title': 'Statement Strategies History',
    'statementStrategy.history.modal.table.header.date': 'From',
    'statementStrategy.history.modal.table.header.strategy': 'Cycle',
    'statementsPeriod.page.title': 'Statements',
    'statementsBreadcrumb.statements': 'Statements',
    'statementsUserStatement.page.title': 'Statements',
    'statementUsersNavigation.usersSelect.placeholder': 'Select a user',
    'statementPeriodApprovalProgress.approved': 'Approvals',
    'statementPeriodApprovalProgress.totalApprovedStatements': '{{count}} $t(statements, {"count": {{count}} }) with at least one approval',
    'statementPeriodApprovalProgress.totalInConflictApprovals': '({{count}} $t(statements, {"count": {{count}} }) with at least one approval in conflict)',
    'statementPeriodApprovalProgress.paid': 'Paid',
    'statementPeriodUsers.approveAll.button.label': 'Approve all',
    'statementPeriodUsers.approveAll.button.popconfirm': 'Are you sure to approve all these statements?',
    'statementPeriodUsers.approveAll.button.processingLabel': 'Processing {{currentIndex}} / {{nbToProcess}}',
    'statementPeriodUsers.markAllAsPaid.button.label': 'Mark all as paid',
    'statementPeriodUsers.markAllAsPaid.button.popconfirm': 'Are you sure to mark all these statements as paid?',
    'statementPeriodUsers.approve.button.label': 'Approve',
    'statementPeriodUsers.approve.button.waitingLabel': 'Waiting',
    'statementPeriodUsers.approve.button.processingLabel': 'Processing',
    'statementPeriodUsers.approve.button.popconfirm': 'Are you sure to approve this statement?',
    'statementPeriodUsers.cancelApproval.button.popconfirm': 'Are you sure to want cancelling this approval?',
    'statementPeriodUsers.markAsPaid.button.label': 'Mark as paid',
    'statementPeriodUsers.markAsPaid.button.waitingLabel': 'Waiting',
    'statementPeriodUsers.markAsPaid.button.processingLabel': 'Processing',
    'statementPeriodUsers.markAllAsPaid.button.processingLabel': 'Processing {{currentIndex}} / {{nbToProcess}}',
    'statementPeriodUsers.markAsPaid.button.popconfirm': 'Are you sure to mark this statement as paid?',
    'statementPeriodUsers.reapprove.button.label': 'Reapprove',
    'statementPeriodUsers.reapprove.button.popconfirm': 'Are you sure to reapprove this statement?',
    'statementRecomputeWarning.recompute': 'Refresh data',
    'statementRecomputeWarning.title': 'Refresh data',
    'statementRecomputeWarning.description': 'Important changes were made, please update the commissions by refreshing data.',
    'statement.approvals.tooltip.label': '{{userName}} approved<br />{{amount}}',
    'statement.paidBy.tooltip.label': '{{userName}} mark as paid<br />{{amount}}',
    'statementFxRates.block.title': 'Statement Rates',
    'statementFxRates.content.intro': 'Define the FX rates you want to use in the statements.',
    'statementFxRates.mode.from_payment_date': 'From payment date',
    'statementFxRates.mode.from_commission_date': 'From commission date',
    'statementValidations.block.title': 'Statement Validations',
    'statementValidations.content.text.approvals': 'Do you want to enable "approve" and "mark as paid" actions on statements? If enabled, editing managers and admins can approve statements and mark them as paid. Contributors will be able to see if a statement is approved or marked as paid.',
    'statementValidations.content.switch.toggleValidations': 'Statements approvals',
    'statementValidations.content.title.notifications': 'Email the rep on validation?',
    'statementValidations.content.text.notifications': 'Do you want to send an email to the user when a statement gets validated? Please note that each user can manage their own email notifications and deactivate this notification.',
    'statementValidations.content.switch.toggleNotifications': 'Email notification on validation',
    'teamDetailsPageContent.subTeamsLabel': 'Subteams ({{count}})',
    'teamDetailsPageContent.membersLabel': 'Members ({{count}})',
    'teamMembersListItem.teamLead': 'Team Lead',
    'teamMembersListItemMoreOptionsMenu.unsetTeamLeadRole': 'Remove Team Lead role',
    'teamMembersListItemMoreOptionsMenu.setAsTeamLead': 'Set as a Team Lead',
    'teamMembersListItemMoreOptionsMenu.removeMemberFromTeam': 'Remove from team',
    teams_one: 'Team',
    teams_other: 'Teams',
    'teamsListForUser.empty.description': 'No teams',
    'teamsListItem.noSubTeams': 'No subteams',
    'teamsListItem.subTeamsList': 'Subteams list:',
    'teamsListItem.subTeamsCount_one': '{{count}} subteam',
    'teamsListItem.subTeamsCount_other': '{{count}} subteams',
    'teamsListItemMoreOptionsMenu.updateTeamName': 'Update team name',
    'teamsListItemMoreOptionsMenu.moveTeam': 'Move team',
    'teamsListItemMoreOptionsMenu.deleteTeam': 'Delete team',
    'teamSelector.searchNotFound': 'No existing team matches',
    'teamSelector.placeholder': 'All Teams',
    'teamsPage.page.title': 'All Teams',
    'teamsBreadcrumb.teams': 'All Teams',
    'termsAndConditionsModal.title': 'Terms and Conditions',
    'termsAndConditionsModal.linkLabel': 'Terms and Conditions',
    'termsAndConditionsModal.acknowledge': 'I accept the terms and conditions',
    'termsAndConditionsModal.content': 'We updated our terms and conditions. Please review the following link below:',
    'thread.action.changed': 'changed',
    'thread.action.commented': 'commented',
    'thread.commission.changed': 'Commission changed to {{price}}',
    'thread.commission.reset': 'Commission reset to original value',
    'thread.commission.dealValue.changed': '{{dealType}} changed to {{value}}',
    'thread.commission.dealValue.reset': '{{dealType}} reset to original value',
    'thread.status.changed': 'Status changed to {{status}}',
    'thread.statuses.pending': 'Pending',
    'thread.statuses.resolved': 'Resolved',
    'thread.statuses.setTo': 'Set to {{status}}',
    'threadComment.commission.changed': 'changed commission to',
    'threadComment.commission.reset': 'reset commission to original value',
    'threadComment.commission.dealValue.changed': 'changed {{dealType}} value to',
    'threadComment.commission.dealValue.reset': 'reset {{dealType}} to original value',
    'threadComment.payments.changed': 'updated payments to',
    'threadComment.payments.reset': 'reset payments to original schedule',
    'threadComment.payments.paymentOn': '{{percentage}}% on {{date}}',
    'threadComment.status.changed': 'changed status to',
    'timezoneSelect.options.timeOffsetLabel': 'UTC{{timeOffsetLabel}}',
    'timezoneSelect.timezones': 'Timezones',
    'timezoneSelect.timeOffsets': 'Time offsets',
    'treeSelect.searchNotFound': 'No option matches',
    'trialBanner.ends.in': 'Your trial ends {{duration}}.',
    'trialBanner.finished': 'Your trial period is finished.',
    'trialBanner.startSubscriptionNow': 'Start my subscription now',
    'unarchiveNotebookModal.title': 'Unarchive Model',
    'unarchiveNotebookModal.confirm': 'I confirm',
    'unarchiveNotebookModal.content': 'Do you confirm you want to unarchive <strong>{{notebookName}}</strong>?',
    'unlinkQuotaFromMasterPlan.unlinkQuotaLabel': 'Unlink Quota',
    'unlinkQuotaFromMasterPlanModal.title': 'Do you want to unlink the Quota from the Plan?',
    'unlinkQuotaFromMasterPlanModal.confirm': 'I confirm',
    'unlinkQuotaFromMasterPlanModal.content': 'Please confirm you\'d like to unlink the Quota. The plan will no longer have a Quota.',
    'unMakeExportableCellModal.title': 'Do not export Cell',
    'unMakeExportableCellModal.confirm': 'I confirm',
    'unMakeExportableCellModal.content': 'Do you confirm to remove results of <strong>{{cellName}}</strong> from <strong>{{notebookName}}</strong> available as resources?',
    'updateTeamNameModal.title': 'Update team name',
    'updateTeamNameModal.update': 'Update',
    'updateTeamNameModal.form.name.label': 'Team Name',
    'updateTeamNameModal.form.name.rules.required': 'Please name your Team.',
    'updateUserTypeConfirmationModal.title': 'Updating type of Users',
    'updateUserTypeConfirmationModal.confirm': 'I confirm',
    'updateUserTypeConfirmationModal.content': 'By updating type of Users, you will lose all users and all calculated data from this plan. <strong>Do you confirm the update?</strong>',
    'user.email': 'Email',
    'user.status': 'Status',
    'user.linkedResources': 'Linked Resources',
    'userAccountSettings.title': 'User Account',
    'userAccountSettings.form.email.label': 'Email',
    'userAccountSettings.form.email.rules.required': 'Please fill an email.',
    'userAccountSettings.form.email.rules.email': 'Please provide a valid email address.',
    'userAccountSettings.form.role.label': 'Role',
    'userAccountSettings.form.submitBtnLabel': 'Update account',
    'userAccountSettings.noAccount': 'No account is attached to this user.',
    'userDataSettings.title': 'User Details',
    'userDataSettings.form.firstName.label': 'First Name',
    'userDataSettings.form.firstName.rules.required': 'Please fill a first name.',
    'userDataSettings.form.lastName.label': 'Last Name',
    'userDataSettings.form.lastName.rules.required': 'Please fill a last name.',
    'userDataSettings.form.email.label': 'Email',
    'userDataSettings.form.email.rules.required': 'Please fill an email.',
    'userDataSettings.form.email.rules.email': 'Please provide a valid email address.',
    'userDataSettings.form.currency.label': 'Currency',
    'userDataSettings.form.payrollId.label': 'Payroll Id',
    'userDataSettings.form.hireDate.label': 'Hire date',
    'userDataSettings.form.submitBtnLabel': 'Update',
    'userDetailsTabs.tabs.statements': 'Statements',
    'userDetailsTabs.tabs.plans': 'Plans',
    'userDetailsTabs.tabs.teams': 'Teams',
    'userDetailsTabs.tabs.quotas': 'Quotas',
    'userDetailsTabs.tabs.dashboardPreview': 'Dashboard Preview',
    'userDetailsTabs.tabs.settings': 'Settings',
    'usersBreadcrumb.users': 'All Users',
    'userResourcesSelectionPanel.userSearch.placeholder': 'Search a user',
    'usersSelectedPanel.warning.noNewSelectedUsers': 'No new selected users.',
    'usersSelectedPanel.removeUser': 'Remove',
    'userConnectorsSelect.allUsers': 'All Users',
    'userConnectorsSelect.resourcesOption.label': '<strong>{{type}}</strong> from <strong>{{connector}}</strong>',
    'userLinkedResourceItem.unlinkResource.popconfirm.title': 'Do you confirm you want unlink this resource from user?',
    'userLinkedResourceItem.viewLinkedResource': 'View linked resource',
    'userLinkedResourceItem.viewDetails': 'Details',
    'userLinkedResourcesSettings.title': 'Linked Resources',
    'userLinkedResourcesSettings.empty.description': 'No Linked Resources',
    'userOffboardingSettings.title': 'Offboarding',
    'userOffboardingSettings.description': 'If you want to offboard this user, by clicking Offboard now you\'ll be able to define a leave date, make a final adjustment with due payments and remove the user from current and future plans.',
    'userOffboardingSettings.userHasLeaveDate': 'Leave date set for {{userDisplayName}} is {{leaveDate}}.',
    'userOffboardingSettings.resetLeaveDateDescription': 'If you want to reset this leave date, by clicking on Reset Leave Date now you\'ll be able to remove leave date from this user but you will need to manage plans on your own and eventually invite this user again to the platform.',
    'userOffboardingSettings.resetLeaveDate.popconfirm.title': 'Do you confirm to reset leave date for this user?',
    'userOffboardingSettings.resetLeaveDate.label': 'Reset Leave Date for {{userDisplayName}} now',
    'usersMergePage.page.title': 'Merge Users',
    'usersMergePage.page.subtitle': 'List of conflicts that prevent two users to be merged',
    'usersSelectionPanel.userSearch.placeholder': 'Search a user',
    'userSelector.select.placeholder': 'Select a user',
    'userStatementContent.userWillBeHired': 'User will be hired on {{date}}',
    'userStatementContent.userWasHired': 'User was hired on {{date}}',
    'userStatementContent.userWasDeactivated': 'User was deactivated on {{date}}',
    'userStatementContent.userWillBeDeactivated': 'User will be deactivated on {{date}}',
    'userStatementContent.tabs.statement': 'Statement',
    'userStatementContent.tabs.history': 'History of changes',
    'userStatementPlanCollapse.seePlanDetails': 'See plan details',
    'userStatementPlanCollapse.total': 'Total ·',
    'userStatementPlanPeriod.seePeriodDetails': 'See period details',
    'userStatementPlanPeriod.total': 'Total period ·',
    'userStatementPlans.dealSearch.placeholder': 'Search by resource name',
    'userStatementPlanPeriodDealsTable.empty.description': 'No {{dealType}} corresponding to your search',
    'userStatementPeriodDeals.table.headers.commissions': 'Paid Commissions',
    users_one: 'User',
    users_other: 'Users',
    usersWithCount_zero: '{{count}} $t(users_one)',
    usersWithCount_one: '{{count}} $t(users_one)',
    usersWithCount_other: '{{count}} $t(users_other)',
    usersWithCountToBe_one: '$t(usersWithCount, {"count": {{count}} }) is',
    usersWithCountToBe_other: '$t(usersWithCount, {"count": {{count}} }) are',
    'usersStatementsList.noUserStatement': 'No users statements.',
    'userStatements.userSearch.placeholder': 'Search a user',
    'userStatements.title.usersCount': 'Users ({{count}})',
    'userStatements.exportAll': 'Export all',
    'userStatements.exportToPayroll': 'Export to {{payrollProviderName}}',
    'userStatements.payrollProviderRestrictedTo': 'Restricted to team {{team}}',
    'userStatement.table.header.name': 'Name',
    'userStatement.table.header.commissions': 'Commissions',
    'userStatement.table.header.actions': 'Actions',
    'userStatement.infosAndActions.export': 'Export',
    'userStatement.infosAndActions.markAsDirty.button.label': 'Mark as dirty',
    'userStatement.infosAndActions.markAsDirty.button.popconfirm': 'Are you sure to mark this statement as dirty?',
    'userStatementAdjustments.collapse.title': 'Corrections applied ({{count}})',
    'userStatementAdjustments.collapse.total': 'Total ·',
    'userStatementAdjustments.collapse.addAdjustmentButtonLabel': 'Add a correction',
    'userStatementAdjustments.collapse.reasonLabel': 'Reason',
    'userStatementAdjustments.collapse.amountLabel': 'Amount',
    'userStatementAdjustments.collapse.whenLabel': 'Correction added on',
    'userStatementAdjustments.collapse.alreadyPaid': 'You cannot make corrections because the statement was already marked as paid',
    'userStatementAdjustments.actions.delete.popconfirm': 'Are you sure to remove this correction?',
    'userStatementAdjustments.addEditAdjustment.modal.edit.title': 'Edit a correction',
    'userStatementAdjustments.addEditAdjustment.modal.add.title': 'Add a correction',
    'userStatementAdjustments.addEditAdjustment.modal.approve.title': 'Approve a correction',
    'userStatementAdjustments.addEditAdjustment.modal.commissions.label': 'Total commissions',
    'userStatementAdjustments.addEditAdjustment.save.edit.label': 'Update the correction',
    'userStatementAdjustments.addEditAdjustment.save.add.label': 'Add the correction',
    'userStatementAdjustments.addEditAdjustment.save.approve.label': 'Approve the correction',
    'userStatementAdjustments.addEditAdjustment.reason.label': 'Reason for this correction',
    'userStatementAdjustments.addEditAdjustment.reason.placeholder': 'Reason for this correction',
    'userStatementAdjustments.addEditAdjustment.amount.label': 'Correction amount (can be negative)',
    'userStatementAdjustments.addEditAdjustment.amount.error.message': 'Please enter an amount.',
    'userStatementAdjustments.addEditAdjustment.reason.error.message': 'Please enter a reason.',
    'userStatementCorrections.collapse.title': 'Corrections suggested ({{count}})',
    'userStatementCorrections.content.default.reason': 'Statement correction {{dates}}',
    'userStatementCorrections.actions.investigate': 'Investigate',
    'userStatementCorrections.actions.createAdjustment': 'Approve',
    'userStatementCorrections.actions.ignore': 'Reject',
    'userStatementCorrections.actions.ignore.confirm': 'Are you sure to reject this correction?',
    'userStatementCorrections.actions.apply.confirm': 'Are you sure to apply this correction?',
    'userStatementInvestigateList.title': 'Differences in statements ({{count}})',
    'userStatementInvestigateList.column.name': 'Name',
    'userStatementInvestigateList.column.plan': 'Plan name',
    'userStatementInvestigateList.column.period': 'Period',
    'userStatementInvestigateList.column.was': 'Was',
    'userStatementInvestigateList.column.is': 'Is',
    'userStatementInvestigateMain.title': 'Statement Differences',
    'userStatementInvestigateMain.empty.text': 'Select deal name to view details',
    'userStatus.wasDeactivated': 'User was deactivated on {{date}}',
    'userStatus.willBeDeactivated': 'User will be deactivated on {{date}}',
    'userStatus.invitationIsPending': 'Invitation is pending',
    'userStatus.noRole': 'No role',
    'userStatus.withRole': 'Role: <strong>{{roleName}}</strong>',
    'usersPage.page.title': 'All Users',
    'usersPage.mergeUsers': 'Merge Users',
    'usersPageContent.userSearch.placeholder': 'Search a user',
    'usersPageContent.onlyActiveFilter.label': 'Only active users',
    'usersPageContent.resetFilters.label': 'Reset filters',
    'usersPageContent.viewLinkedResource': 'View linked resource',
    'userVariableArrayValueInput.addNewItem': 'Add new item',
    'userVariableArrayValueInput.deleteItem.popconfirm': 'Are you sure to delete this item?',
    'userVariableArrayValueInput.emptyList': 'Empty list',
    'usersVariables.createNewVariable': 'New Variable',
    'usersVariablesPage.page.title': 'Users\' Variables',
    'usersVariablesPageContent.userSearch.placeholder': 'Search a user',
    'usersVariablesPageContent.onlyActiveFilter.label': 'Only active users',
    'usersVariablesPageContent.resetFilters.label': 'Reset filters',
    'usersVariablesPageContent.dateFilter.label': 'Show values for',
    'usersVariablesTableBodyCell.editValue.tooltip': 'Edit Value',
    'usersVariablesTableBodyCell.showHistory.tooltip': 'Show History',
    'usersVariables.types.string': 'String',
    'usersVariables.types.number': 'Number',
    'usersVariables.types.date': 'Date (UTC+0)',
    'usersVariables.types.boolean': 'Boolean',
    'usersVariables.types.array': 'List',
    'variablesForm.form.addAVariable': 'Add a variable',
    'variablesForm.value.title': 'Value',
    'variablesForm.value.placeholder': 'Variable value (formula)',
    'variablesForm.name.title': 'Name',
    'variablesForm.name.placeholder': 'Variable name',
    'variablesForm.fx.tooltip': 'Add IF statement',
    'variablesForm.delete.tooltip': 'Delete variable',
    'variablesForm.condition.placeholder': 'Condition (ex Deal.xxx === yyy)',
    'variablesForm.delete.condition.tooltip': 'Delete condition',
    'variablesForm.addACondition': 'Add a condition',
    'widgetBase.tooltips.moveLeft': 'Move widget to the left',
    'widgetBase.tooltips.moveRight': 'Move widget to the right',
    'widgetBase.tooltips.remove': 'Remove widget from dashboard',
    'widgetPeriodYearSelector.useMostRecent': 'Use most recent period',
    'widgets.highlight.commissions_total.name': 'Total commissions',
    'widgets.highlight.commissions_unreleased.name': 'Commissions without any payment scheduled',
    'widgets.highlight.commissions_due_estimate.name': 'Future payout',
    'widgets.highlight.commissions_total.shortname': 'Total commissions',
    'widgets.highlight.commissions_unreleased.shortname': 'Commissions without payment',
    'widgets.highlight.commissions_due_estimate.shortname': 'Future payout',
    'widgets.plan_progress_gauge.name': 'Plan Progress',
    'widgets.plan_progress_gauge.description': 'Progress on your plan so far, see if you are late, on track or ahead on timing.',
    'widgets.plan_payout_schedule.name': 'Plan Payout Schedule',
    'widgets.plan_payout_schedule.description': 'Payout schedule overtime for selected plan.',
    'widgets.plan_progress_bar_chart.name': 'Plan Progress Over Months',
    'widgets.plan_progress_bar_chart.description': 'Get your progress over month for multi-month plans.',
    'widgets.plan_total_commissions.name': 'Plan Total Commissions',
    'widgets.plan_total_commissions.description': 'Total commissions you\'re getting for this plan.',
    'widgets.plan_total_value.name': 'Plan Total Value',
    'widgets.plan_total_value.description': 'Total value reached in the plan so far.',
    'widgets.plan_count_objects.name': 'Plan Total Objects',
    'widgets.plan_count_objects.description': 'Number of elements taken into account in the selected plan.',
    'widgets.plan_details.name': 'Plan Details',
    'widgets.plan_details.description': 'Total commissions and number of elements in this plan.',
    'widgets.statement_one_month.name': 'Current Statement',
    'widgets.statement_one_month.description': 'Statement amount for the current month.',
    'widgets.statement_per_month.name': 'Three Month Statement',
    'widgets.statement_per_month.description': 'Statements from previous, current and next month.',
    'widgets.list_plans.name': 'Current Plans',
    'widgets.list_plans.description': 'List of active plans.',
    'widgets.latest_objects.name': 'Latest commissions',
    'widgets.latest_objects.description': 'Latest elements synced with Palette and their commission',
    'widgets.plan_what_if.name': 'Projection',
    'widgets.plan_what_if.description': 'Simulation of potential earnings for this plan.',
    'widgets.commissions_unreleased_list.name': 'Elements on-hold or partially paid',
    'widgets.commissions_unreleased_list.description': 'Latest elements on-hold or partially paid',
    'widgets.commissions_unreleased.name': 'Commissions not released yet',
    'widgets.commissions_unreleased.description': 'Total commissions without any payment scheduled',
    'widgets.highlight.progress.name': 'Progress',
    'widgets.highlight.progress.shortname': 'Progress',
    'widgets.highlight.total_value.name': 'Total value',
    'widgets.highlight.total_value.shortname': 'Total {{valueDefinition}}',
    'plansV3.createNewPlan.btnLabel': 'Create new plan',
    'plansV3.createNewFolder.btnLabel': 'Create new folder',
    'plansV3.periodicity.MONTH': 'Monthly',
    'plansV3.periodicity.QUARTER': 'Quarterly',
    'plansV3.periodicity.SEMESTER': 'Semesterly',
    'plansV3.periodicity.YEAR': 'Yearly',
    'plansV3.periodicity.DAY': 'Daily',
    'plansV3.periodicity.CUSTOM': 'Custom',
    'plansV3.status.DRAFT': 'Draft',
    'createPlanPage.title': 'Create new plan',
    'createPlanPage.subtitle.top': 'Step 1 out of 3',
    'createPlanPage.section1.title': 'General',
    'createPlanPage.section1.subtitle': 'Description',
    'createPlanPage.section2.title': 'Users',
    'createPlanPage.section2.subtitle': 'Select all the users that will benefit from this plan commissions',
    'createPlanPage.section3.title': 'Data Sources',
    'createPlanPage.section3.subtitle': 'Add all the resources that you want to use for this plan',
    'createPlanPage.form.submit.label': 'Next step',
    'updatePlanPage.form.submit.label': 'Save & next step',
    'createPlanPage.form.name.label': 'Plan name',
    'createPlanPage.form.name.placeholder': 'Ex: Plan for AEs',
    'createPlanPage.form.name.rules.required': 'Please name your Plan.',
    'createPlanPage.form.description.label': 'Plan description',
    'createPlanPage.form.description.placeholder': 'This plan is for...',
    'createPlanPage.form.frequency.label': 'Plan frequency',
    'createPlanPage.form.frequency.rules.required': 'Please select plan frequency.',
    'createPlanPage.form.frequency.option.MONTH': 'Monthly',
    'createPlanPage.form.frequency.option.QUARTER': 'Quarterly',
    'createPlanPage.form.frequency.option.SEMESTER': 'Semesterly',
    'createPlanPage.form.frequency.option.YEAR': 'Yearly',
    'createPlanPage.form.frequency.option.DAY': 'Every X days',
    'createPlanPage.form.frequency.option.CUSTOM': 'Custom dates',
    'createPlanPage.form.startPeriod.label': 'Start date',
    'createPlanPage.form.startPeriod.rules.required': 'Please select a start date.',
    'createPlanPage.form.endPeriod.label': 'End date',
    'createPlanPage.form.endPeriod.rules.required': 'Please select an end date.',
    'createPlanPage.form.dayInterval.label': 'Duration of a period in days (7 for a weekly plan)',
    'createPlanPage.form.dayInterval.rules.required': 'Please select an interval.',
    'createPlanPage.form.remove.endPeriod.label': 'Do not use end date',
    'createPlanPage.form.listUsers.countSelected': '$t(usersWithCount, {"count": {{usersCount}} }) selected',
    'createPlanPage.form.datasources.list.title': 'Models Resources',
    'createPlanPage.inputTables.title': 'Advanced tables',
    'editPlanPage.subtitle.top.step2': 'Step 2 out of 3',
    'editPlanPage.subtitle.top.step3': 'Step 3 out of 3',
    'plansV3.linkTo.requirements.tooltip': 'Requirements plan',
    'plansV3.editAchievementPage.leftSide.header': 'Plan Context',
    'editPlanPage.header.saved.time': 'Saved {{diffForHumans}}',
    'editPlanPage.footer.back.previous.step': 'Back to previous step',
    'editPlanPage.footer.next.step': 'Next step',
    'editPlanPage.footer.save.step': 'Save',
    'editPlanPage.context.variables.source.CRM': 'CRM Resources',
    'editPlanPage.context.variables.source.PALETTE': 'Palette Resources',
    'editPlanPage.context.variables.source.MODELS': 'Models Resources',
    'editPlanPage.context.summary.title': 'Requirements',
    'editPlanPage.context.requirements.start': 'Start',
    'editPlanPage.context.requirements.end': 'End',
    'editPlanPage.context.requirements.frequency': 'Periodicity',
    'editPlanPage.achievements.title': 'Quota attainment generation',
    'editPlanPage.achievements.docLabel': 'Access documentation & examples',
    'editPlanPage.achievements.description': 'Define quota rules to set how sales individual, team, or organization perform in a given period of time.',
    'editPlanPage.achievements.alert.title': 'Expected output',
    'editPlanPage.achievements.runQuery': 'Run query',
    'editPlanPage.commissions.title': 'Commission generation',
    'editPlanPage.commissions.docLabel': 'Access documentation & examples',
    'editPlanPage.commissions.description': 'Write Python/SQL logic that can use Plan Context, Achievements Results & Periods information.',
    'editPlanPage.commissions.alert.title': 'Expected output',
    'editPlanPage.commissions.runQuery': 'Run query',
    'editPlanPage.results.periods.title': 'Periods evaluated',
    'editPlanPage.results.periods.subtitle': 'Automatically generated from requirements',
    'editPlanPage.results.periods.table.periodId': 'Period Id',
    'editPlanPage.results.periods.table.start': 'Start',
    'editPlanPage.results.periods.table.end': 'End',
    'editPlanPage.results.periods.table.codeValue': 'Code Value',
    'editPlanPage.results.achievements.title': 'Achievements',
    'editPlanPage.results.achievements.subtitle': 'Automatically generated from requirements',
    'editPlanPage.results.achievements.user': 'User achievements results',
    'editPlanPage.results.achievements.deal': 'Deal achievements results',
    'editPlanPage.results.achievements.deal.table.periodId': 'Period',
    'editPlanPage.results.periods.table.dealId': 'Deal',
    'editPlanPage.results.periods.table.userId': 'User',
    'editPlanPage.results.periods.table.targetType': 'Target',
    'editPlanPage.results.periods.table.quota': 'Quota',
    'editPlanPage.results.periods.table.value': 'Value',
    'editPlanPage.results.periods.01': 'January',
    'editPlanPage.results.periods.02': 'February',
    'editPlanPage.results.periods.03': 'March',
    'editPlanPage.results.periods.04': 'April',
    'editPlanPage.results.periods.05': 'May',
    'editPlanPage.results.periods.06': 'June',
    'editPlanPage.results.periods.07': 'July',
    'editPlanPage.results.periods.08': 'August',
    'editPlanPage.results.periods.09': 'September',
    'editPlanPage.results.periods.10': 'October',
    'editPlanPage.results.periods.11': 'November',
    'editPlanPage.results.periods.12': 'December',
    'editPlanPage.results.periods.inputTables.title': 'Advanced tables',
    'editPlanPage.results.advancedOptions.title': 'Advanced options',
    'editPlanPage.results.advancedOptions.subtitle': 'Create additional tables if necessary',
    'userStatementV3.commissions.collapse.title': 'Commissions ({{count}})',
    'userStatementV3.commissions.header.plan': 'Plan',
    'userStatementV3.commissions.header.dealId': 'Deal Id',
    'userStatementV3.commissions.header.amount': 'Amount',
    'userStatementV3.commissions.header.date': 'Date',
  },
};

export default en;
