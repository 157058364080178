import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Avatar from '@palette/components/user/Avatar/Avatar';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Select from '@palette/components/designSystem/Select/Select';
import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';

import {
  usePeriodsDatesFilterAndCurrencyQSObject,
  useStatementPeriodIdInParams,
  useStatementUserIdInParams,
} from '@palette/hooks/StatementHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import styles from './StatementUsersNavigation.less';

const classNames = bindClassNames.bind(styles);

const StatementUsersNavigation = ({ className }) => {
  const { t } = useTranslation();

  const [editable, setEditable] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [userStatementUsers, setUserStatementUsers] = useState([]);

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const { statementPeriodId, statementPeriod } = useStatementPeriodIdInParams();
  const { userStatement } = useStatementUserIdInParams();

  useEffect(() => {
    if (userStatement) {
      setCurrentUserId(userStatement.user.id);
    }
  }, [userStatement]);

  useEffect(() => {
    if (statementPeriod.users.length) {
      setUserStatementUsers(statementPeriod.users);
    }
  }, [statementPeriod]);

  const handleUsersSelectChange = useCallback((newUserId) => {
    setCurrentUserId(userStatementUsers.find((user) => user.id === newUserId)?.id);
    redirectTo({
      path: routePaths.v2.statementsUserStatement,
      params: { statementPeriodId, userId: newUserId },
      qsObject: periodsDatesFilterQSObject,
    });
  }, [userStatementUsers, statementPeriodId, periodsDatesFilterQSObject]);

  const handleDropdownVisibleChange = (val) => setEditable(val);

  const nameNode = useMemo(() => {
    let contentNode = null;

    if (currentUserId !== null) {
      const currentUser = userStatementUsers.find((user) => user.id === currentUserId);

      if (currentUser) {
        contentNode = (
          <>
            <Avatar
              className={styles.avatar}
              user={currentUser}
              size={24}
              style={{
                fontSize: '1.2rem',
                width: '2.4rem',
                height: '2.4rem',
              }}
            />
            <div className={styles.name}>
              {currentUser.displayName}
            </div>
            <ChevronDownLine className={styles.icon} width={24} height={24} />
          </>
        );
      }
    }

    return (
      <div
        className={classNames({
          nameContainer: true,
          editable,
        })}
      >
        {contentNode}
      </div>
    );
  }, [currentUserId, userStatementUsers, editable]);

  const usersSelectNode = useMemo(() => {
    if (statementPeriod === null || currentUserId === null) return null;

    const userStatementOptions = userStatementUsers.map((user) => ({
      label: (
        <UserProfile
          className={styles.userOption}
          user={user}
          avatarSize={18}
          avatarStyle={{
            fontSize: '1rem',
            width: '1.8rem',
            minWidth: '1.8rem',
            height: '1.8rem',
          }}
        />
      ),
      rawlabel: user.displayName,
      value: user.id,
    }));

    return (
      <Select
        className={classNames({
          usersSelect: true,
          editable,
        })}
        dropdownClassName={styles.usersSelectDropdown}
        placeholder={t('statementUsersNavigation.usersSelect.placeholder')}
        options={userStatementOptions}
        onChange={handleUsersSelectChange}
        value={currentUserId}
        showSearch
        enableFilterOptions
        filterOptionProp="rawlabel"
        onDropdownVisibleChange={handleDropdownVisibleChange}
      />
    );
  }, [statementPeriod, userStatementUsers, currentUserId, handleUsersSelectChange, editable]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {nameNode}
      {usersSelectNode}
    </div>
  );
};

StatementUsersNavigation.propTypes = {
  className: PropTypes.string,
};

StatementUsersNavigation.defaultProps = {
  className: '',
};

export default StatementUsersNavigation;
