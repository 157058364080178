import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import AmountDescriptionWidgetContent from '@palette/components/widgetContents/AmountDescriptionWidgetContent/AmountDescriptionWidgetContent';

import * as CommissionsDueEstimateDataModel from '@palette/models/widgets/highlight/CommissionsDueEstimateData';

import styles from './CommissionsDueEstimateWidgetContent.less';

const classNames = bindClassNames.bind(styles);

const CommissionsDueEstimateWidgetContent = ({ className, data }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <AmountDescriptionWidgetContent
        amount={data.commissionsDueEstimateAmount}
        currency={data.currency}
        description={t('commissionsDueEstimateWidgetContent.description')}
      />
    </div>
  );
};

CommissionsDueEstimateWidgetContent.propTypes = {
  className: PropTypes.string,
  data: CommissionsDueEstimateDataModel.propTypes.isRequired,
};

CommissionsDueEstimateWidgetContent.defaultProps = {
  className: '',
};

export default CommissionsDueEstimateWidgetContent;
