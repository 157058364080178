import {
  call,
  put,
  all,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { actions as TeamsActions } from '@palette/state/Teams/slice';
import { manageError as manageTeamsError } from '@palette/state/Teams/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as TeamsService from '@palette/services/TeamsService';

import * as TeamModel from '@palette/models/Team';

export function* getList() {
  const callResult = yield call(TeamsService.list);

  if (callResult.ok) {
    const teams = TeamModel.transformList(callResult.data);
    yield put(TeamsActions.setList({ teams }));
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.getListCompleted());
}

export function* getTeamById({ payload = {} }) {
  const { teamId } = payload;

  const callResult = yield call(TeamsService.getById, { teamId });

  if (callResult.ok) {
    const team = TeamModel.transform(callResult.data);
    yield put(TeamsActions.setTeam({ team }));
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.getTeamByIdCompleted());
}

export function* updateMemberType({ payload = {} }) {
  const {
    teamId,
    userId,
    type,
  } = payload;

  const callResult = yield call(TeamsService.updateMemberType, { teamId, userId, type });

  if (callResult.ok) {
    yield put(TeamsActions.getTeamById({ teamId }));

    yield take(TeamsActions.getTeamByIdCompleted.type);
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.updateMemberTypeCompleted());
}

export function* removeMemberFromTeam({ payload = {} }) {
  const {
    teamId,
    userId,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(TeamsService.removeMemberFromTeam, { teamId, userId });

  if (callResult.ok) {
    yield put(TeamsActions.getTeamById({ teamId }));

    yield take(TeamsActions.getTeamByIdCompleted.type);

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.removeMemberFromTeamCompleted());
}

export function* addMembersToTeam({ payload = {} }) {
  const {
    teamId,
    usersToAdd = [],
    onSuccessCB = null,
  } = payload;

  if (usersToAdd.length > 0) {
    const usersIds = usersToAdd.map((user) => (user.id));

    const callResult = yield call(TeamsService.addMembersToTeam, { teamId, usersIds });

    if (callResult.ok) {
      yield put(TeamsActions.getTeamById({ teamId }));

      yield take(TeamsActions.getTeamByIdCompleted.type);

      if (onSuccessCB !== null) onSuccessCB();
    } else {
      const error = manageTeamsError(callResult);
      yield put(GlobalNotifActions.addGlobalNotif(error));
    }
  } else if (onSuccessCB !== null) {
    onSuccessCB();
  }

  yield put(TeamsActions.addMembersToTeamCompleted());
}

export function* createTeam({ payload = {} }) {
  const {
    name,
    parentTeamId = null,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(TeamsService.createTeam, { name, parentTeamId });

  if (callResult.ok) {
    yield put(TeamsActions.getList());

    yield take(TeamsActions.getListCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.TEAM_CREATION_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB(callResult.data._id);
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.createTeamCompleted());
}

export function* deleteTeam({ payload = {} }) {
  const {
    teamId,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(TeamsService.deleteTeam, { teamId });

  if (callResult.ok) {
    yield put(TeamsActions.getList());

    yield take(TeamsActions.getListCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.TEAM_DELETION_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.deleteTeamCompleted());
}

export function* updateTeamName({ payload = {} }) {
  const {
    teamId,
    name,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(TeamsService.updateTeamName, { teamId, name });

  if (callResult.ok) {
    yield put(TeamsActions.getList());

    yield take(TeamsActions.getListCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.TEAM_UPDATE_NAME_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.updateTeamNameCompleted());
}

export function* moveTeam({ payload = {} }) {
  const {
    teamId,
    parentTeamId,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(TeamsService.moveTeam, { teamId, parentTeamId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(TeamsActions.getList());

    yield take(TeamsActions.getListCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.TEAM_MOVE_SUCCESS));
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.moveTeamCompleted());
}

export function* getTeamsForUser({ payload = {} }) {
  const { userId } = payload;

  const callResult = yield call(TeamsService.getTeamsForUser, { userId });

  if (callResult.ok) {
    const teams = TeamModel.transformList(callResult.data);
    yield put(TeamsActions.setTeamsForUser({ userId, teams }));
  } else {
    const error = manageTeamsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(TeamsActions.getTeamsForUserCompleted());
}

export function* loop() {
  yield all([
    takeLatest(TeamsActions.getList.type, getList),
    takeLatest(TeamsActions.getTeamById.type, getTeamById),
    takeLatest(TeamsActions.updateMemberType.type, updateMemberType),
    takeLatest(TeamsActions.removeMemberFromTeam.type, removeMemberFromTeam),
    takeLatest(TeamsActions.addMembersToTeam.type, addMembersToTeam),
    takeLatest(TeamsActions.createTeam.type, createTeam),
    takeLatest(TeamsActions.deleteTeam.type, deleteTeam),
    takeLatest(TeamsActions.updateTeamName.type, updateTeamName),
    takeLatest(TeamsActions.moveTeam.type, moveTeam),
    takeLatest(TeamsActions.getTeamsForUser.type, getTeamsForUser),
  ]);
}

export function* clean() {
  yield put(TeamsActions.resetToInitialState());
}
