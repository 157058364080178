import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _groupBy from 'lodash/groupBy';

import PlanV3DealAchievementsPeriod from '@palette/components/planV3/PlanV3DealAchievementsPeriod/PlanV3DealAchievementsPeriod';

import * as PlanV3AchievementModel from '@palette/models/PlanV3Achievement';

import styles from './PlanV3DealAchievementsResults.less';

const classNames = bindClassNames.bind(styles);

const PlanV3DealAchievementsResults = ({ className, achievementsList }) => {
  const { t } = useTranslation();

  const resultsNode = useMemo(() => {
    const groupedByPeriodId = _groupBy(achievementsList, (achievement) => (achievement.periodId));

    return Object.entries(groupedByPeriodId).map(([periodId, periodAchievementsList]) => (
      <PlanV3DealAchievementsPeriod className={styles.periodResults} key={periodId} periodId={periodId} achievementsList={periodAchievementsList} />
    ));
  }, [
    achievementsList,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>
        {t('editPlanPage.results.achievements.deal')}
      </div>
      {resultsNode}
    </div>
  );
};

PlanV3DealAchievementsResults.propTypes = {
  className: PropTypes.string,
  achievementsList: PropTypes.arrayOf(PlanV3AchievementModel.propTypes).isRequired,
};

PlanV3DealAchievementsResults.defaultProps = {
  className: '',
};

export default PlanV3DealAchievementsResults;
