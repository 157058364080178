import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './ArchiveMasterPlanModal.less';

const ArchiveMasterPlanModal = ({ visible, onClose, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const archiveMasterPlanIsPending = useSelector(MasterPlansSelectors.archiveByIdIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
  };

  const handleArchiveMasterPlan = () => {
    dispatch(MasterPlansActions.archiveById({ planId: plan.id, onSuccessCB }));
  };

  return (
    <Modal
      className={styles.modal}
      title={t('archiveMasterPlanModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleArchiveMasterPlan}
      okText={t('archiveMasterPlanModal.confirm')}
      loading={archiveMasterPlanIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('archiveMasterPlanModal.content', { planName: plan.name }) }} />
    </Modal>
  );
};

ArchiveMasterPlanModal.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

ArchiveMasterPlanModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default ArchiveMasterPlanModal;
