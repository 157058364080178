import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageBooleanAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'DashboardWidgetParams';

export const propTypes = PropTypes.shape({
  useMostRecent: PropTypes.bool,
  planId: PropTypes.string,
  period: YearPeriodModel.propTypes,
});

export const transform = (widgetParams) => {
  if (!widgetParams) {
    return null;
  }

  const periodsArray = manageArrayAttribute(widgetParams, 'periods');
  let period = null;
  if (periodsArray.length > 0) {
    const periods = YearPeriodModel.transformList(periodsArray);
    // eslint-disable-next-line prefer-destructuring
    period = periods[0];
  }

  return (
    {
      useMostRecent: manageBooleanAttribute(widgetParams, 'useMostRecent', true),
      planId: manageStringAttribute(widgetParams, 'planId', null),
      period,
    }
  );
};

export const transformList = (widgetParamsList) => widgetParamsList.map((widgetParams) => transform(widgetParams));
