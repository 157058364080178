import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import UsersPageContent from '@palette/components/user/UsersPageContent/UsersPageContent';
import OnboardingUsersButton from '@palette/components/user/OnboardingUsersButton/OnboardingUsersButton';
import CompareFilled from '@palette/components/utils/Icons/CompareFilled';
import Link from '@palette/components/designSystem/Link/Link';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights, hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import styles from './UsersPage.less';

const UsersPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const compareUsersLinkNode = useMemo(() => {
    if (!hasAllRights(profile, [RIGHTS.ADMIN.USERS.UPDATE])) return null;

    return (
      <Link
        className={styles.mergeUsersLink}
        path={routePaths.v2.usersMerge}
      >
        <CompareFilled className={styles.mergeUsersLinkIcon} />
        <div className={styles.mergeUsersLinkLabel}>
          {t('usersPage.mergeUsers')}
        </div>
      </Link>
    );
  }, [profile]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.USERS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.usersTitle')} />
      <PageTitle className={styles.titleWrapper}>
        <div className={styles.titlesContainer}>
          <h1>{t('usersPage.page.title')}</h1>
        </div>
        <div className={styles.actions}>
          {compareUsersLinkNode}
          <OnboardingUsersButton className={styles.onboardingUsersBtn} />
        </div>
      </PageTitle>
      <UsersPageContent />
    </div>
  );
};

export default UsersPage;
