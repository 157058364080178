import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import Select from '@palette/components/designSystem/Select/Select';
import Input from '@palette/components/designSystem/Input/Input';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Button from '@palette/components/designSystem/Button/Button';

import {
  DEFAULT_CONDITION_VALUE_KEY,
  DEFAULT_CONDITION_VALUE_OPERATOR,
  OPERATORS_OPTIONS,
} from '@palette/constants/queryBuilder';
import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';

import styles from './QueryBuilderAndOrCondition.less';

const classNames = bindClassNames.bind(styles);

const QueryBuilderAndOrCondition = ({
  className,
  helperData,
  value,
  onChange,
  onDelete,
  prefix,
  disabled,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    if (!_isEqual(internalValue, value)) {
      setInternalValue(value);
    }
  }, [value]);

  const field = useMemo(() => {
    const firstKey = Object.keys(internalValue)[0];

    return firstKey || DEFAULT_CONDITION_VALUE_KEY;
  }, [internalValue]);

  const fieldValue = useMemo(() => {
    if (field === DEFAULT_CONDITION_VALUE_KEY) return '';

    return field.replace(prefix, '');
  }, [field, prefix]);

  const operator = useMemo(() => {
    const valObj = internalValue[field];
    const firstKey = Object.keys(valObj)[0];

    return firstKey || DEFAULT_CONDITION_VALUE_OPERATOR;
  }, [internalValue, field]);

  const code = useMemo(() => {
    const valObj = internalValue[field];
    const operatorObject = valObj[operator];

    return operatorObject || '';
  }, [internalValue, field, operator]);

  const handleChange = useCallback((newValue) => {
    setInternalValue(newValue);
    onChange(newValue);
  }, [onChange]);

  const handleFieldChange = useCallback((newField) => {
    const newValue = { [`${prefix}${newField}`]: { [operator]: code } };
    handleChange(newValue);
  }, [prefix, operator, code, handleChange]);

  const handleOperatorChange = useCallback((newOperator) => {
    const newValue = { [field]: { [newOperator]: code } };
    handleChange(newValue);
  }, [field, code, handleChange]);

  const handleCodeChange = useCallback((newCode) => {
    const newValue = { [field]: { [operator]: newCode } };
    handleChange(newValue);
  }, [field, operator, handleChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      <FormulaInput
        className={styles.fieldFormulaInput}
        value={fieldValue}
        onChange={handleFieldChange}
        type={FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION}
        helperData={helperData}
        includePrefix={false}
        placeholder={t('queryBuilderAndOrCondition.fieldName')}
        disabled={disabled}
      />
      <Select
        className={styles.operatorSelect}
        value={operator}
        onChange={handleOperatorChange}
        options={OPERATORS_OPTIONS}
        disabled={disabled}
        dropdownMatchSelectWidth={false}
      />
      <Input
        className={styles.codeInput}
        value={code}
        onChange={handleCodeChange}
        placeholder={t('queryBuilderAndOrCondition.valueOrCode')}
        disabled={disabled}
      />
      {
        onDelete !== null && (
          <Button
            className={styles.deleteBtn}
            type="linkDestroy"
            icon={(<TrashFilled width={18} height={18} />)}
            onClick={onDelete}
            disabled={disabled}
          />
        )
      }
    </div>
  );
};

QueryBuilderAndOrCondition.propTypes = {
  className: PropTypes.string,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
};

QueryBuilderAndOrCondition.defaultProps = {
  className: '',
  prefix: '',
  disabled: false,
  onDelete: null,
};

export default QueryBuilderAndOrCondition;
