import { EVENT_CONTENT_ACTIONS } from '@palette/constants/comments';
import { CURRENCIES } from '@palette/constants/currency';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getColumnValue, getResourceColumns } from '@palette/helpers/ConnectorHelper';
import getI18n from '@palette/i18n';
import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';

export const getCommentEventContent = (commentEventContent, currency = CURRENCIES.USD, dealType = null) => {
  const i18n = getI18n();
  switch (commentEventContent.action) {
    case EVENT_CONTENT_ACTIONS.COMMISSION_CHANGED:
    case EVENT_CONTENT_ACTIONS.COMMISSION_UPDATED:
      return commentEventContent.amount !== null
        ? i18n.t('thread.commission.changed', { price: formatPrice(commentEventContent.amount, currency) })
        : i18n.t('thread.commission.reset');
    case EVENT_CONTENT_ACTIONS.COMMISSION_VALUE_UPDATED: {
      return commentEventContent.value !== null
        ? i18n.t('thread.commission.dealValue.changed', { dealType: dealType || i18n.t('common.global.value'), value: comaSeparatorFormatter(commentEventContent.value) })
        : i18n.t('thread.commission.dealValue.reset', { dealType: dealType || i18n.t('common.global.value') });
    }
    case EVENT_CONTENT_ACTIONS.STATUS_CHANGED:
    case EVENT_CONTENT_ACTIONS.STATUS_UPDATED:
    default:
      return i18n.t('thread.status.changed', { status: `$t(thread.statuses.${commentEventContent.status.toLowerCase()})` });
  }
};

export const getActionTargetName = (thread, connector) => {
  const columns = thread[thread.type]?.plan?.columns.length > 0
    ? thread[thread.type]?.plan?.columns
    : getResourceColumns(
      connector,
      thread[thread.type]?.resourceObject?.type,
      (column) => column.displayInPlans,
    );
  return columns.map((column) => getColumnValue(thread[thread.type]?.resourceObject?.data || {}, column)).join(' - ');
};
