import {
  call, put, all, takeEvery, takeLatest, take, select, delay, spawn,
} from 'redux-saga/effects';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans/slice';
import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { manageError as managePlansError } from '@palette/state/MasterPlans/errors';
import { waitJob } from '@palette/state/AsyncJobs/sagas';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import {
  getBeginAndEndDatesByFilterType,
  getPlanValueDefinition,
  isArchived,
  isDraft,
  isInPast,
} from '@palette/helpers/MasterPlanHelper';
import { isSamePagePathname, redirectTo } from '@palette/helpers/NavigationHelper';
import { renameValueDefinition } from '@palette/helpers/FormulaHelper';
import { enhanceComponentsDataWithDealsType } from '@palette/helpers/DashboardHelper';

import routePaths from '@palette/config/routePaths';
import apiConfig from '@palette/config/api';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { SCOPES } from '@palette/constants/masterPlans';
import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';
import { DEFAULT_VALUE_DEFINITION_VALUE } from '@palette/constants/formula';
import { EMPTY_DEFAULT_VALUE as TRACKING_OBJECTS_EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';
import { EMPTY_DEFAULT_VALUE as USERS_DEFINITION_EMPTY_DEFAULT_VALUE } from '@palette/constants/usersDefinition';
import { PLANS_PAGE_CONTENT_TABS_IDS, IC_PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as MasterPlansService from '@palette/services/MasterPlansService';
import * as AsyncJobsService from '@palette/services/AsyncJobsService';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanUserModel from '@palette/models/MasterPlanUser';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as MasterPlanPeriodUserModel from '@palette/models/MasterPlanPeriodUser';
import * as MasterPlanPeriodDealModel from '@palette/models/MasterPlanPeriodDeal';
import * as MasterPlanStatsModel from '@palette/models/MasterPlanStats';
import * as PaginationModel from '@palette/models/Pagination';
import * as AsyncJobModel from '@palette/models/AsyncJob';
import * as PlanEstimationDataPointModel from '@palette/models/PlanEstimationDataPoint';
import * as PlanPayoutScheduleDataModel from '@palette/models/widgets/dashboard/PlanPayoutScheduleData';
import * as HighlightComponentDataModel from '@palette/models/HighlightComponentData';

export function* list({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(MasterPlansService.list);

  if (callResult.ok) {
    const plans = MasterPlanModel.transformList(callResult.data);
    const pastPlans = [];
    const activePlans = [];
    const draftPlans = [];
    const archivedPlans = [];

    plans.forEach((plan) => {
      if (isDraft(plan)) {
        draftPlans.push(plan);
      } else if (isArchived(plan)) {
        archivedPlans.push(plan);
      } else if (isInPast(plan)) {
        pastPlans.push(plan);
      } else {
        activePlans.push(plan);
      }
    });

    const stats = MasterPlanStatsModel.transform({
      past: pastPlans.length,
      active: activePlans.length,
      draft: draftPlans.length,
      archived: archivedPlans.length,
      activeAndPast: pastPlans.length + activePlans.length,
      total: plans.length,
    });

    yield put(MasterPlansActions.setList({ activePlans, pastPlans, draftPlans, archivedPlans, stats }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getListCompleted());
}

export function* listLastPeriods({ payload = {} }) {
  const { type = PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE, onSuccessCB = null } = payload;

  const callResult = yield call(MasterPlansService.listLastPeriods, {
    past: type === PLANS_PAGE_CONTENT_TABS_IDS.PAST,
  });

  if (callResult.ok) {
    const lastPeriods = MasterPlanPeriodModel.transformList(callResult.data);

    yield put(MasterPlansActions.setListLastPeriods({ lastPeriods }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getListLastPeriodsCompleted());
}

export function* listIC({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(MasterPlansService.listIC);

  if (callResult.ok) {
    const plans = MasterPlanModel.transformList(callResult.data);
    const pastPlans = [];
    const activePlans = [];
    const archivedPlans = [];

    plans.forEach((plan) => {
      if (isArchived(plan)) {
        archivedPlans.push(plan);
      } else if (isInPast(plan)) {
        pastPlans.push(plan);
      } else if (!isDraft(plan)) {
        activePlans.push(plan);
      }
    });

    const stats = MasterPlanStatsModel.transform({
      past: pastPlans.length,
      active: activePlans.length,
      archived: archivedPlans.length,
      draft: 0,
      activeAndPast: pastPlans.length + activePlans.length,
      total: plans.length,
    });

    yield put(MasterPlansActions.setList({ activePlans, pastPlans, archivedPlans, draftPlans: [], stats }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getListCompleted());
}

export function* listICLastPeriods({ payload = {} }) {
  const { type = IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE, onSuccessCB = null } = payload;

  const callResult = yield call(MasterPlansService.listICLastPeriods, {
    past: type === IC_PLANS_PAGE_CONTENT_TABS_IDS.PAST,
  });

  if (callResult.ok) {
    const lastPeriods = MasterPlanPeriodModel.transformList(callResult.data);

    yield put(MasterPlansActions.setListLastPeriods({ lastPeriods }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getListLastPeriodsCompleted());
}

export function* getById({ payload = {} }) {
  const { planId, updateLastPeriod = true } = payload;

  const callResult = yield call(MasterPlansService.getById, { planId });

  if (callResult.ok) {
    const plan = MasterPlanModel.transform(callResult.data);
    yield put(MasterPlansActions.setById({ plan, updateLastPeriod }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getByIdCompleted());
}

export function* getICById({ payload = {} }) {
  const { planId } = payload;

  const callResult = yield call(MasterPlansService.getICById, { planId });

  if (callResult.ok) {
    const plan = MasterPlanModel.transform(callResult.data);
    yield put(MasterPlansActions.setById({ plan }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getByIdCompleted());
}

export function* getPlanPeriods({ payload = {} }) {
  const {
    planId,
    periodsFilterType,
    customPeriods = null,
  } = payload;

  const plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });

  const { beginDate, endDate } = getBeginAndEndDatesByFilterType(periodsFilterType, customPeriods, plan);

  const callResult = yield call(MasterPlansService.getPeriods, { planId, beginDate, endDate });

  if (callResult.ok) {
    const periods = MasterPlanPeriodModel.transformList(callResult.data);

    const callData = {
      periodsFilterType,
      customPeriods,
    };

    yield put(MasterPlansActions.setPlanPeriods({ planId, periods, callData }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPlanPeriodsCompleted());
}

export function* getICPlanPeriods({ payload = {} }) {
  const {
    planId,
    periodsFilterType,
    customPeriods = null,
  } = payload;

  const plan = yield select(MasterPlansSelectors.getICMasterPlanById, { masterPlanId: planId });

  const { beginDate, endDate } = getBeginAndEndDatesByFilterType(periodsFilterType, customPeriods, plan);

  const callResult = yield call(MasterPlansService.getICPeriods, { planId, beginDate, endDate });

  if (callResult.ok) {
    const periods = MasterPlanPeriodModel.transformList(callResult.data);

    const callData = {
      periodsFilterType,
      customPeriods,
    };

    yield put(MasterPlansActions.setPlanPeriods({ planId, periods, callData }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPlanPeriodsCompleted());
}

export function* getPlanDashboard({ payload = {} }) {
  const {
    planId,
    periodsFilterType,
    customPeriods = null,
  } = payload;

  const callData = {
    planId,
    periodsFilterType,
    customPeriods,
  };

  const plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });

  const { beginDate, endDate } = getBeginAndEndDatesByFilterType(periodsFilterType, customPeriods, plan);

  const callResult = yield call(MasterPlansService.getPlanDashboard, { planId, from: beginDate, to: endDate, timezone: plan.timezone });

  if (callResult.ok) {
    const enhancedData = enhanceComponentsDataWithDealsType(callResult.data, plan.trackingObject?.type);
    const dashboard = HighlightComponentDataModel.transformList(enhancedData);

    yield put(MasterPlansActions.setPlanDashboard({ planId, dashboard, callData }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPlanDashboardCompleted());
}

export function* getICPlanDashboard({ payload = {} }) {
  const {
    planId,
    periodsFilterType,
    customPeriods = null,
  } = payload;

  const callData = {
    planId,
    periodsFilterType,
    customPeriods,
  };

  const plan = yield select(MasterPlansSelectors.getICMasterPlanById, { masterPlanId: planId });

  const { beginDate, endDate } = getBeginAndEndDatesByFilterType(periodsFilterType, customPeriods, plan);

  const callResult = yield call(MasterPlansService.getICPlanDashboard, { planId, from: beginDate, to: endDate, timezone: plan.timezone });

  if (callResult.ok) {
    const enhancedData = enhanceComponentsDataWithDealsType(callResult.data, plan.trackingObject?.type);
    const dashboard = HighlightComponentDataModel.transformList(enhancedData);

    yield put(MasterPlansActions.setPlanDashboard({ planId, dashboard, callData }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPlanDashboardCompleted());
}

export function* archiveById({ payload = {} }) {
  const { planId, onSuccessCB = null, disableSuccessNotification = false } = payload;

  const callResult = yield call(MasterPlansService.archiveById, { planId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(planId);

    yield call(analyticsSendEvent, { payload: { event: MASTER_PLANS_EVENTS.ARCHIVED, params: { planId } } });

    if (isSamePagePathname({ path: routePaths.v2.plans })) {
      yield put(MasterPlansActions.getList());

      yield take(MasterPlansActions.getListCompleted.type);
    } else {
      yield put(MasterPlansActions.getById({ planId }));

      yield take(MasterPlansActions.getByIdCompleted.type);
    }

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_ARCHIVE_SUCCESS));
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.archiveByIdCompleted());
}

export function* deleteById({ payload = {} }) {
  const { planId, onSuccessCB = null, disableSuccessNotification = false } = payload;

  const callResult = yield call(MasterPlansService.deleteById, { planId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(planId);

    if (isSamePagePathname({ path: routePaths.v2.plans })) {
      yield put(MasterPlansActions.getList());

      yield take(MasterPlansActions.getListCompleted.type);
    } else {
      redirectTo({ path: routePaths.v2.plans });
    }

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_DELETION_SUCCESS));
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.deleteByIdCompleted());
}

export function* getPeriodBy({ payload = {} }) {
  const {
    planId,
    year,
    period,
  } = payload;

  const callResult = yield call(MasterPlansService.getPeriodBy, { planId, year, period });

  if (callResult.ok) {
    const planPeriod = MasterPlanPeriodModel.transform(callResult.data);
    yield put(MasterPlansActions.setPeriodBy({ planId, period: planPeriod }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPeriodByCompleted());
}

export function* getICPeriodBy({ payload = {} }) {
  const {
    planId,
    year,
    period,
  } = payload;

  const callResult = yield call(MasterPlansService.getICPeriodBy, { planId, year, period });

  if (callResult.ok) {
    const planPeriod = MasterPlanPeriodModel.transform(callResult.data);
    yield put(MasterPlansActions.setPeriodBy({ planId, period: planPeriod }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPeriodByCompleted());
}

export function* getPeriodUsers({ payload = {} }) {
  const {
    planId,
    year,
    period,
    forManagers = false,
  } = payload;

  const callResult = yield call(MasterPlansService.getPlanUsers, { planId, year, period, forManagers });

  if (callResult.ok) {
    const periodUsers = MasterPlanPeriodUserModel.transformList(callResult.data);
    if (forManagers) {
      yield put(MasterPlansActions.setPeriodManagers({ planId, year, period, managers: periodUsers }));
    } else {
      yield put(MasterPlansActions.setPeriodUsers({ planId, year, period, users: periodUsers }));
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPeriodUsersCompleted());
}

export function* getPeriodDashboard({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    planId,
  } = payload;

  const callData = {
    from,
    to,
    planId,
  };

  const plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });

  const callResult = yield call(MasterPlansService.getPeriodDashboard, {
    from,
    to,
    planId,
    timezone: plan.timezone,
  });

  if (callResult.ok) {
    const enhancedData = enhanceComponentsDataWithDealsType(callResult.data, plan.trackingObject?.type);
    const dashboard = HighlightComponentDataModel.transformList(enhancedData);

    yield put(MasterPlansActions.setPeriodDashboard({ planId, dashboard, callData }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPeriodDashboardCompleted());
}

export function* getICPeriodDashboard({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    planId,
  } = payload;

  const callData = {
    from,
    to,
    planId,
  };

  const plan = yield select(MasterPlansSelectors.getICMasterPlanById, { masterPlanId: planId });

  const callResult = yield call(MasterPlansService.getICPeriodDashboard, {
    from,
    to,
    planId,
    timezone: plan.timezone,
  });

  if (callResult.ok) {
    const enhancedData = enhanceComponentsDataWithDealsType(callResult.data, plan.trackingObject?.type);
    const dashboard = HighlightComponentDataModel.transformList(enhancedData);

    yield put(MasterPlansActions.setPeriodDashboard({ planId, dashboard, callData }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPeriodDashboardCompleted());
}

export function* getPeriodDeals({ payload = {} }) {
  const {
    planId,
    year,
    period,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    searchedDeal = '',
    searchedUser = null,
    searchedPayee = null,
  } = payload;

  const callResult = yield call(MasterPlansService.getPeriodDeals, {
    planId,
    year,
    period,
    page,
    limit,
    searchedDeal,
    searchedUser,
    searchedPayee,
  });

  if (callResult.ok) {
    const periodDeals = MasterPlanPeriodDealModel.transformList(callResult.data.data);
    const periodDealsPagination = PaginationModel.transform(callResult.data.pagination);

    const callData = {
      limit,
      searchedDeal,
      searchedUser,
      searchedPayee,
    };

    yield put(MasterPlansActions.setPeriodDeals({
      planId,
      year,
      period,
      deals: periodDeals,
      pagination: periodDealsPagination,
      callData,
    }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPeriodDealsCompleted());
}

export function* getICPeriodDeals({ payload = {} }) {
  const {
    planId,
    year,
    period,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    searchedDeal = '',
  } = payload;

  const callResult = yield call(MasterPlansService.getICPeriodDeals, {
    planId,
    year,
    period,
    page,
    limit,
    searchedDeal,
  });

  if (callResult.ok) {
    const periodDeals = MasterPlanPeriodDealModel.transformList(callResult.data.data);
    const periodDealsPagination = PaginationModel.transform(callResult.data.pagination);

    const callData = {
      limit,
      searchedDeal,
    };

    yield put(MasterPlansActions.setPeriodDeals({
      planId,
      year,
      period,
      deals: periodDeals,
      pagination: periodDealsPagination,
      callData,
    }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPeriodDealsCompleted());
}

export function* downloadPeriodDeals({ payload = {} }) {
  const {
    planId,
    year,
    period,
    page,
    limit,
    searchedDeal,
    searchedUser,
    searchedPayee,
  } = payload;

  const callResult = yield call(MasterPlansService.downloadPeriodDeals, {
    planId,
    year,
    period,
    page,
    limit,
    searchedDeal,
    searchedUser,
    searchedPayee,
  });

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.downloadPeriodDealsCompleted());
}

export function* createPlan({ payload = {} }) {
  const {
    name,
    scope,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    endPeriod,
    customBeginAt,
    timezone,
    currency,
    creationFlowStep,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(MasterPlansService.createPlan, {
    name,
    scope,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    endPeriod,
    customBeginAt,
    timezone,
    currency,
    creationFlowStep,
  });

  if (callResult.ok) {
    yield put(MasterPlansActions.getById({ planId: callResult.data._id }));

    yield take(MasterPlansActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: MASTER_PLANS_EVENTS.CREATE_PLAN_FLOW.CREATE_NEW_PLAN, params: { step: creationFlowStep } } });

    if (onSuccessCB !== null) onSuccessCB(callResult.data._id);
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.createPlanCompleted());
}

function* cleanStateForPlan({ planId }) {
  // Clean periods list if some were already retrieved for this plan
  const statePlanPeriods = yield select(MasterPlansSelectors.getPlanPeriods);
  if (statePlanPeriods[planId]) {
    yield put(MasterPlansActions.cleanPlanPeriods({ planId }));
  }

  // Clean periods if some were already retrieved for this plan
  const statePeriodsBy = yield select(MasterPlansSelectors.getPeriodsBy);
  if (statePeriodsBy[planId]) {
    yield put(MasterPlansActions.cleanPeriodByForPlan({ planId }));
  }

  // Clean users list if some were already retrieved for this plan
  const statePeriodUsers = yield select(MasterPlansSelectors.getPeriodUsers);
  if (statePeriodUsers[planId]) {
    yield put(MasterPlansActions.cleanPeriodUsers({ planId }));
  }

  // Clean managers list if some were already retrieved for this plan
  const statePeriodManagers = yield select(MasterPlansSelectors.getPeriodManagers);
  if (statePeriodManagers[planId]) {
    yield put(MasterPlansActions.cleanPeriodManagers({ planId }));
  }

  // Clean deals list if some were already retrieved for this plan
  const statePeriodDeals = yield select(MasterPlansSelectors.getPeriodDeals);
  if (statePeriodDeals[planId]) {
    yield put(MasterPlansActions.cleanPeriodDeals({ planId }));
  }
}

const hasExceptionsForClean = (payload) => {
  const {
    columns,
    variables,
  } = payload;

  const exceptions = [columns, variables];

  return exceptions.some((exception) => (exception !== undefined));
};

export function* updatePlan({ payload = {} }) {
  const {
    planId,
    name,
    scope,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    endPeriod,
    customBeginAt,
    live,
    currency,
    freezeType,
    timezone,
    columns,
    variables,
    injections,
    lastFrozenPeriod,
    quotaId,
    quotaTargetStrategy,
    creationFlowStep = undefined,
    parentPlan,
    estimationDefinition,
    onSuccessCB = null,
    onSuccessAfterUpdatesCB = null,
    disableSuccessNotification = false,
    updateLastPeriod = true,
    planComponents,
    periodComponents,
    autofreeze = undefined,
    allowWrongPayoutsPercentageValue = undefined,
  } = payload;

  const callResult = yield call(MasterPlansService.updatePlan, {
    planId,
    name,
    scope,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    endPeriod,
    customBeginAt,
    live,
    currency,
    freezeType,
    timezone,
    columns,
    variables,
    injections,
    lastFrozenPeriod,
    quotaId,
    quotaTargetStrategy,
    creationFlowStep,
    parentPlan,
    estimationDefinition,
    planComponents,
    periodComponents,
    autofreeze,
    allowWrongPayoutsPercentageValue,
  });

  let completedActionSent = false;
  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    if (!hasExceptionsForClean(payload)) {
      yield call(cleanStateForPlan, { planId });
    }

    yield put(MasterPlansActions.getById({ planId, updateLastPeriod }));

    yield take(MasterPlansActions.getByIdCompleted.type);

    // In Creation Flow
    if (creationFlowStep !== undefined) {
      // Finalized if creationFlowStep === null
      if (creationFlowStep !== null) {
        yield call(analyticsSendEvent, { payload: { event: MASTER_PLANS_EVENTS.CREATE_PLAN_FLOW.CREATE_NEW_PLAN, params: { step: creationFlowStep } } });
      } else {
        yield call(analyticsSendEvent, { payload: { event: MASTER_PLANS_EVENTS.CREATE_PLAN_FLOW.CREATE_NEW_PLAN_FINALIZED } });
      }
    }

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_UPDATE_SUCCESS));
    }

    if (onSuccessAfterUpdatesCB !== null) {
      yield put(MasterPlansActions.updatePlanCompleted());
      completedActionSent = true;
      onSuccessAfterUpdatesCB();
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  if (!completedActionSent) {
    yield put(MasterPlansActions.updatePlanCompleted());
  }
}

export function* getPlanUsersAndManagers({ payload = {} }) {
  const {
    planId,
  } = payload;

  const plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });

  const calls = [];

  calls.push(call(MasterPlansService.getPlanUsers, { planId }));

  if (plan !== null && plan.scope === SCOPES.MANAGER) {
    calls.push(call(MasterPlansService.getPlanUsers, { planId, forManagers: true }));
  }

  const [callUsersResults, callManagersResults = null] = yield all(calls);

  let planUsers = [];
  let planManagers = [];

  if (callUsersResults.ok) {
    planUsers = MasterPlanUserModel.transformList(callUsersResults.data);
  } else {
    const error = managePlansError(callUsersResults);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  if (callManagersResults !== null) {
    if (callManagersResults.ok) {
      planManagers = MasterPlanUserModel.transformList(callManagersResults.data);
    } else {
      const error = managePlansError(callManagersResults);
      yield put(GlobalNotifActions.addGlobalNotif(error));
    }
  }

  yield put(MasterPlansActions.setPlanUsersAndManagers({ planId, users: planUsers, managers: planManagers }));

  yield put(MasterPlansActions.getPlanUsersAndManagersCompleted());
}

export function* updatePlanUserDates({ payload = {} }) {
  const {
    planId,
    user,
    startDate,
    endDate,
    forManagers = false,
    disableSuccessNotification = false,
  } = payload;

  const callResult = yield call(MasterPlansService.updatePlanUserDates, {
    planId,
    user,
    startDate,
    endDate,
    forManagers,
  });

  if (callResult.ok) {
    yield put(MasterPlansActions.getById({ planId }));

    yield take(MasterPlansActions.getByIdCompleted.type);

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_USER_DATES_SUCCESS));
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.updatePlanUserDatesCompleted());
}

export function* addUsersToPlan({ payload = {} }) {
  const { planId, usersToAdd, forManagers = false, onSuccessCB } = payload;

  const callResult = yield call(MasterPlansService.addUsersToPlan, {
    planId,
    usersToAdd,
    forManagers,
  });

  if (callResult.ok) {
    onSuccessCB();

    yield put(MasterPlansActions.getById({ planId }));

    yield take(MasterPlansActions.getByIdCompleted.type);
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.addUsersToPlanCompleted());
}

export function* removeUsersFromPlan({ payload = {} }) {
  const { planId, usersToRemove, forManagers = false, onSuccessCB = null } = payload;

  const callResult = yield call(MasterPlansService.removeUsersFromPlan, {
    planId,
    usersToRemove,
    forManagers,
  });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(MasterPlansActions.getById({ planId }));

    yield take(MasterPlansActions.getByIdCompleted.type);
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.removeUsersFromPlanCompleted());
}

export function* updatePlanUsersDefinition({ payload = {} }) {
  const {
    planId,
    connectorId = undefined,
    type = undefined,
    uiType = undefined,
    matchingField = undefined,
    disableSuccessNotification = false,
    onSuccessCB = null,
  } = payload;

  const plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });

  const finalConnectorId = connectorId || plan.usersDefinition?.connectorId;
  const finalType = type || plan.usersDefinition?.originalType;

  const callResult = yield call(MasterPlansService.updatePlanUsersDefinition, {
    planId,
    connectorId: finalConnectorId,
    type: finalType,
    uiType,
    matchingField,
  });

  if (callResult.ok) {
    yield put(MasterPlansActions.getById({ planId }));

    yield take(MasterPlansActions.getByIdCompleted.type);

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_UPDATE_SUCCESS));
    }

    if (onSuccessCB !== null) {
      onSuccessCB();
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.updatePlanUsersDefinitionCompleted());
}

export function* updatePlanTrackingObjectDefinition({ payload = {} }) {
  const {
    planId,
    connectorId = undefined,
    type = undefined,
    uiType = undefined,
    matchingField = undefined,
    filterObject = undefined,
    filterFormula = undefined,
    dateField = undefined,
    valueFormula = undefined,
    valueVariables = undefined,
    disableSuccessNotification = false,
    disableRefreshPlan = false,
    onSuccessCB = null,
  } = payload;

  const plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });

  const finalConnectorId = connectorId || plan.trackingObject?.connectorId;
  const finalType = type || plan.trackingObject?.originalType;

  const callResult = yield call(MasterPlansService.updatePlanTrackingObjectDefinition, {
    planId,
    connectorId: finalConnectorId,
    type: finalType,
    uiType,
    matchingField,
    filterObject,
    filterFormula,
    dateField,
    valueFormula,
    valueVariables,
  });

  if (callResult.ok) {
    if (!disableRefreshPlan) {
      yield put(MasterPlansActions.getById({ planId }));

      yield take(MasterPlansActions.getByIdCompleted.type);
    }

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_UPDATE_SUCCESS));
    }

    if (onSuccessCB !== null) {
      onSuccessCB();
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.updatePlanTrackingObjectDefinitionCompleted());
}

export function* getPlanDealObjectSample({ payload = {} }) {
  const {
    planId,
    enforcedConnectorIdType = null,
  } = payload;

  let plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });
  if (plan === null || plan.trackingObject?.connectorId === '' || plan.trackingObject?.originalType === '') {
    yield put(MasterPlansActions.getById({ planId }));

    yield take(MasterPlansActions.getByIdCompleted.type);

    plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });
  }

  let dealObjectSample = {};

  if (enforcedConnectorIdType !== null) {
    yield put(ConnectorsActions.getResourceObjects({ connectorId: enforcedConnectorIdType.connectorId, type: enforcedConnectorIdType.type, maxCount: 100 }));

    yield take(ConnectorsActions.getResourceObjectsCompleted.type);

    const resourceObjects = yield select(ConnectorsSelectors.getResourceObjectsByConnectorAndType, { connectorId: enforcedConnectorIdType.connectorId, type: enforcedConnectorIdType.type });

    dealObjectSample = resourceObjects.reduce((sample, resourceObject) => ({
      ...sample,
      ...resourceObject.data,
    }), {});
  } else {
    const callResult = yield call(MasterPlansService.getPlanDealObjectSample, {
      planId,
    });

    if (callResult.ok) {
      if (callResult.data.length > 0) {
        const periodDeals = MasterPlanPeriodDealModel.transformList(callResult.data);

        dealObjectSample = periodDeals.reduce((sample, periodDeal) => ({
          ...sample,
          ...periodDeal.resourceObject?.data,
        }), {});
      } else if (
        plan !== null
        && plan.trackingObject?.connectorId != null
        && plan.trackingObject?.originalType != null
        && plan.trackingObject?.originalType !== TRACKING_OBJECTS_EMPTY_DEFAULT_VALUE
      ) {
        yield put(ConnectorsActions.getResourceObjects({ connectorId: plan.trackingObject?.connectorId, type: plan.trackingObject?.originalType, maxCount: 100 }));

        yield take(ConnectorsActions.getResourceObjectsCompleted.type);

        const resourceObjects = yield select(ConnectorsSelectors.getResourceObjectsByConnectorAndType, { connectorId: plan.trackingObject?.connectorId, type: plan.trackingObject?.originalType });

        dealObjectSample = resourceObjects.reduce((sample, resourceObject) => ({
          ...sample,
          ...resourceObject.data,
        }), {});
      }
    } else {
      const error = managePlansError(callResult);
      yield put(GlobalNotifActions.addGlobalNotif(error));
    }
  }

  const planValueDefinition = getPlanValueDefinition(plan);
  if (planValueDefinition !== '') {
    dealObjectSample[`_${renameValueDefinition(planValueDefinition)}`] = DEFAULT_VALUE_DEFINITION_VALUE;
  }

  yield put(MasterPlansActions.setPlanDealObjectSample({ planId, type: enforcedConnectorIdType?.type || plan.trackingObject?.originalType, dealObjectSample }));

  yield put(MasterPlansActions.getPlanDealObjectSampleCompleted());
}

export function* getPlanUserObjectSample({ payload = {} }) {
  const {
    planId,
    enforcedConnectorIdType = null,
  } = payload;

  const plan = yield select(MasterPlansSelectors.getMasterPlanById, { masterPlanId: planId });

  let userObjectSample = {};

  if (enforcedConnectorIdType !== null) {
    yield put(ConnectorsActions.getResourceObjects({ connectorId: enforcedConnectorIdType.connectorId, type: enforcedConnectorIdType.type, maxCount: 100 }));

    yield take(ConnectorsActions.getResourceObjectsCompleted.type);

    const resourceObjects = yield select(ConnectorsSelectors.getResourceObjectsByConnectorAndType, { connectorId: enforcedConnectorIdType.connectorId, type: enforcedConnectorIdType.type });

    userObjectSample = resourceObjects.reduce((sample, resourceObject) => ({
      ...sample,
      ...resourceObject.data,
    }), {});
  } else if (
    plan !== null
    && plan.usersDefinition?.connectorId != null
    && plan.usersDefinition?.originalType != null
    && plan.usersDefinition?.originalType !== USERS_DEFINITION_EMPTY_DEFAULT_VALUE
  ) {
    yield put(ConnectorsActions.getResourceObjects({ connectorId: plan.usersDefinition?.connectorId, type: plan.usersDefinition?.originalType, maxCount: 100 }));

    yield take(ConnectorsActions.getResourceObjectsCompleted.type);

    const resourceObjects = yield select(ConnectorsSelectors.getResourceObjectsByConnectorAndType, { connectorId: plan.usersDefinition?.connectorId, type: plan.usersDefinition?.originalType });

    userObjectSample = resourceObjects.reduce((sample, resourceObject) => ({
      ...sample,
      ...resourceObject.data,
    }), {});
  }

  yield put(MasterPlansActions.setPlanUserObjectSample({ planId, type: enforcedConnectorIdType?.type || plan.usersDefinition?.originalType, userObjectSample }));

  yield put(MasterPlansActions.getPlanUserObjectSampleCompleted());
}

export function* duplicatePlan({ payload = {} }) {
  const {
    planId,
    name,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    customBeginAt,
    keepUsers,
    duplicateType,
    terminateParent,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(MasterPlansService.duplicatePlan, {
    planId,
    name,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    customBeginAt,
    keepUsers,
    duplicateType,
    terminateParent,
  });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(callResult.data._id);
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.duplicatePlanCompleted());
}

export function* updatePlanRules({ payload = {} }) {
  const {
    planId,
    rules,
    disableSuccessNotification = false,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(MasterPlansService.updatePlanRules, { planId, rules });

  if (callResult.ok) {
    yield put(MasterPlansActions.getById({ planId }));

    yield take(MasterPlansActions.getByIdCompleted.type);

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_UPDATE_SUCCESS));
    }

    if (onSuccessCB !== null) {
      onSuccessCB();
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.updatePlanRulesCompleted());
}

export function* updatePayoutRules({ payload = {} }) {
  const {
    planId,
    payoutRules,
    payoutRulesDescription,
    payoutRulesTemplate,
    payoutRulesConditionStrategy,
    disableSuccessNotification = false,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(MasterPlansService.updatePayoutRules, {
    planId,
    payoutRules,
    payoutRulesDescription,
    payoutRulesTemplate,
    payoutRulesConditionStrategy,
  });

  if (callResult.ok) {
    yield put(MasterPlansActions.getById({ planId }));

    yield take(MasterPlansActions.getByIdCompleted.type);

    if (!disableSuccessNotification) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_UPDATE_SUCCESS));
    }

    if (onSuccessCB !== null) {
      onSuccessCB();
    }
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.updatePayoutRulesCompleted());
}

export function* computeAllSuccess({ enableSuccessNotification = true, enableReload = true }) {
  if (enableSuccessNotification) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_COMPUTE_ALL_SUCCESS));
  }
  if (enableReload) {
    yield delay(2000);
    window.location.reload();
  }
}

export function* computeAllError({ error }) {
  yield put(GlobalNotifActions.addGlobalNotif(error));
}

export function* computeAllCompleted() {
  yield put(MasterPlansActions.computeAllCompleted());
}

export function* computeAll({ payload = {} }) {
  const { enableSuccessNotification = true, enableReload = true } = payload;

  const successData = { enableSuccessNotification, enableReload };

  const callResult = yield call(AsyncJobsService.computeAll);

  yield put(GlobalNotifActions.resetAllPermanentGlobalNotif());

  if (callResult.ok) {
    const job = AsyncJobModel.transform(callResult.data);
    yield spawn(waitJob, { job, successSaga: () => computeAllSuccess(successData), errorSaga: computeAllError, completedSaga: computeAllCompleted });
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
    yield put(MasterPlansActions.computeAllCompleted());
  }
}

export function* updatePlanDataInState({ planId, disableSuccessNotification = false, onSuccessCB = null }) {
  yield put(MasterPlansActions.getById({ planId }));
  yield take(MasterPlansActions.getByIdCompleted.type);

  // Update periods list if some were already retrieved for this plan
  const statePlanPeriods = yield select(MasterPlansSelectors.getPlanPeriods);
  if (statePlanPeriods[planId]) {
    const { callData } = statePlanPeriods[planId];
    yield put(MasterPlansActions.getPlanPeriods({ planId, ...callData }));
    yield take(MasterPlansActions.getPlanPeriodsCompleted.type);
  }

  // Update plan dashboard if it was already retrieved for this plan
  const statePlanDashboard = yield select(MasterPlansSelectors.getPlanDashboard);
  if (statePlanDashboard[planId]) {
    const { callData } = statePlanDashboard[planId];
    yield put(MasterPlansActions.getPlanDashboard({ planId, ...callData }));
    yield take(MasterPlansActions.getPlanDashboardCompleted.type);
  }

  // Update users and managers list for settings if some were already retrieved for this plan
  const statePlanUsersAndManagers = yield select(MasterPlansSelectors.getPlanUsersAndManagers);
  if (statePlanUsersAndManagers[planId]) {
    yield put(MasterPlansActions.getPlanUsersAndManagers({ planId }));
    yield take(MasterPlansActions.getPlanUsersAndManagersCompleted.type);
  }

  // Update periods if some were already retrieved for this plan
  const statePeriodsBy = yield select(MasterPlansSelectors.getPeriodsBy);
  if (statePeriodsBy[planId]) {
    const years = Object.keys(statePeriodsBy[planId]);
    for (let i = 0; i < years.length; i += 1) {
      const yearInt = parseInt(years[i], 10);
      const periods = Object.keys(statePeriodsBy[planId][yearInt]);

      for (let j = 0; j < periods.length; j += 1) {
        const periodInt = parseInt(periods[j], 10);
        yield put(MasterPlansActions.getPeriodBy({ planId, year: yearInt, period: periodInt }));
        yield take(MasterPlansActions.getPeriodByCompleted.type);
      }
    }
  }

  // Update users list if some were already retrieved for this plan
  const statePeriodUsers = yield select(MasterPlansSelectors.getPeriodUsers);
  if (statePeriodUsers[planId]) {
    const years = Object.keys(statePeriodUsers[planId]);
    for (let i = 0; i < years.length; i += 1) {
      const yearInt = parseInt(years[i], 10);
      const periods = Object.keys(statePeriodUsers[planId][yearInt]);

      for (let j = 0; j < periods.length; j += 1) {
        const periodInt = parseInt(periods[j], 10);
        yield put(MasterPlansActions.getPeriodUsers({ planId, year: yearInt, period: periodInt }));
        yield take(MasterPlansActions.getPeriodUsersCompleted.type);
      }
    }
  }

  // Update managers list if some were already retrieved for this plan
  const statePeriodManagers = yield select(MasterPlansSelectors.getPeriodManagers);
  if (statePeriodManagers[planId]) {
    const years = Object.keys(statePeriodManagers[planId]);
    for (let i = 0; i < years.length; i += 1) {
      const yearInt = parseInt(years[i], 10);
      const periods = Object.keys(statePeriodManagers[planId][yearInt]);

      for (let j = 0; j < periods.length; j += 1) {
        const periodInt = parseInt(periods[j], 10);
        yield put(MasterPlansActions.getPeriodUsers({ planId, year: yearInt, period: periodInt, forManagers: true }));
        yield take(MasterPlansActions.getPeriodUsersCompleted.type);
      }
    }
  }

  // Update deals list if some were already retrieved for this plan
  const statePeriodDeals = yield select(MasterPlansSelectors.getPeriodDeals);
  if (statePeriodDeals[planId]) {
    const years = Object.keys(statePeriodDeals[planId]);
    for (let i = 0; i < years.length; i += 1) {
      const yearInt = parseInt(years[i], 10);
      const periods = Object.keys(statePeriodDeals[planId][yearInt]);

      for (let j = 0; j < periods.length; j += 1) {
        const periodInt = parseInt(periods[j], 10);
        const { callData } = statePeriodDeals[planId][yearInt][periodInt];
        yield put(MasterPlansActions.getPeriodDeals({ planId, year: yearInt, period: periodInt, page: 0, ...callData }));
        yield take(MasterPlansActions.getPeriodDealsCompleted.type);
      }
    }
  }

  // Update plan period dashboard if it was already retrieved for this plan
  const statePeriodDashboard = yield select(MasterPlansSelectors.getPeriodDashboard);
  if (statePeriodDashboard[planId]) {
    const { callData } = statePeriodDashboard[planId];
    yield put(MasterPlansActions.getPeriodDashboard({ planId, ...callData }));
    yield take(MasterPlansActions.getPeriodDashboardCompleted.type);
  }

  if (!disableSuccessNotification) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_COMPUTE_DONE_SUCCESS));
  }

  if (onSuccessCB !== null) {
    onSuccessCB();
  }
}

export function* computeICPlanSuccess({ planId, disableSuccessNotification = false, onSuccessCB = null }) {
  yield put(MasterPlansActions.getICById({ planId }));
  yield take(MasterPlansActions.getByIdCompleted.type);

  // Update periods list if some were already retrieved for this plan
  const statePlanPeriods = yield select(MasterPlansSelectors.getPlanPeriods);
  if (statePlanPeriods[planId]) {
    const { callData } = statePlanPeriods[planId];
    yield put(MasterPlansActions.getICPlanPeriods({ planId, ...callData }));
    yield take(MasterPlansActions.getPlanPeriodsCompleted.type);
  }

  // Update deals list if some were already retrieved for this plan
  const statePeriodDeals = yield select(MasterPlansSelectors.getPeriodDeals);
  if (statePeriodDeals[planId]) {
    const years = Object.keys(statePeriodDeals[planId]);
    for (let i = 0; i < years.length; i += 1) {
      const yearInt = parseInt(years[i], 10);
      const periods = Object.keys(statePeriodDeals[planId][yearInt]);

      for (let j = 0; j < periods.length; j += 1) {
        const periodInt = parseInt(periods[j], 10);
        const { callData } = statePeriodDeals[planId][yearInt][periodInt];
        yield put(MasterPlansActions.getICPeriodDeals({ planId, year: yearInt, period: periodInt, page: 0, ...callData }));
        yield take(MasterPlansActions.getPeriodDealsCompleted.type);
      }
    }
  }

  // Update periods if some were already retrieved for this plan
  const statePeriodsBy = yield select(MasterPlansSelectors.getPeriodsBy);
  if (statePeriodsBy[planId]) {
    const years = Object.keys(statePeriodsBy[planId]);
    for (let i = 0; i < years.length; i += 1) {
      const yearInt = parseInt(years[i], 10);
      const periods = Object.keys(statePeriodsBy[planId][yearInt]);

      for (let j = 0; j < periods.length; j += 1) {
        const periodInt = parseInt(periods[j], 10);
        yield put(MasterPlansActions.getICPeriodBy({ planId, year: yearInt, period: periodInt }));
        yield take(MasterPlansActions.getPeriodByCompleted.type);
      }
    }
  }

  if (!disableSuccessNotification) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_COMPUTE_DONE_SUCCESS));
  }

  if (onSuccessCB !== null) {
    onSuccessCB();
  }
}

export function* computePlanError({ error }) {
  yield put(GlobalNotifActions.addGlobalNotif(error));
}

export function* computePlanCompleted() {
  yield put(MasterPlansActions.computePlanCompleted());
}

export function* computePlan({ payload = {} }) {
  const { planId, disableSuccessNotification = false, onSuccessCB = null } = payload;

  const callResult = yield call(AsyncJobsService.computeOne, { planId });

  yield put(GlobalNotifActions.resetAllPermanentGlobalNotif());

  if (callResult.ok) {
    const job = AsyncJobModel.transform(callResult.data);
    yield spawn(waitJob, {
      job,
      successSaga: () => updatePlanDataInState({ planId, disableSuccessNotification, onSuccessCB }),
      errorSaga: computePlanError,
      completedSaga: computePlanCompleted,
    });
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
    yield put(MasterPlansActions.computePlanCompleted());
  }
}

export function* computeICPlan({ payload = {} }) {
  const { planId, disableSuccessNotification = false, onSuccessCB = null } = payload;

  const callResult = yield call(AsyncJobsService.computeOne, { planId });

  if (callResult.ok) {
    const job = AsyncJobModel.transform(callResult.data);
    yield spawn(waitJob, {
      job,
      successSaga: () => computeICPlanSuccess({ planId, disableSuccessNotification, onSuccessCB }),
      errorSaga: computePlanError,
      completedSaga: computePlanCompleted,
    });
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
    yield put(MasterPlansActions.computePlanCompleted());
  }
}

export function* getPlanListForUser({ payload = {} }) {
  const { user, onSuccessCB = null } = payload;

  const callResult = yield call(MasterPlansService.getPlanListForUser, { user });

  if (callResult.ok) {
    const plans = MasterPlanModel.transformList(callResult.data);
    const pastPlans = [];
    const activePlans = [];
    const archivedPlans = [];

    plans.forEach((plan) => {
      if (isArchived(plan)) {
        archivedPlans.push(plan);
      } else if (isInPast(plan)) {
        pastPlans.push(plan);
      } else if (!isDraft(plan)) {
        activePlans.push(plan);
      }
    });

    const stats = MasterPlanStatsModel.transform({
      past: pastPlans.length,
      active: activePlans.length,
      archived: archivedPlans.length,
      draft: 0,
      activeAndPast: pastPlans.length + activePlans.length,
      total: plans.length,
    });

    yield put(MasterPlansActions.setPlanListForUser({ activePlans, pastPlans, archivedPlans, stats }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPlanListForUserCompleted());
}

export function* getPlanListLastPeriodsForUser({ payload = {} }) {
  const { user, type = PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE, onSuccessCB = null } = payload;

  const callResult = yield call(MasterPlansService.getPlanListLastPeriodsForUser, {
    user,
    past: type === PLANS_PAGE_CONTENT_TABS_IDS.PAST,
  });

  if (callResult.ok) {
    const lastPeriods = MasterPlanPeriodModel.transformList(callResult.data);

    yield put(MasterPlansActions.setPlanListLastPeriodsForUser({ lastPeriods }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPlanListLastPeriodsForUserCompleted());
}

export function* getICPeriodEstimationSuccess({ planId, year, period, job }) {
  const estimation = PlanEstimationDataPointModel.transformList(JSON.parse(job.result));

  yield put(MasterPlansActions.setICPeriodEstimation({
    planId,
    year,
    period,
    estimation,
  }));
}

export function getICPeriodEstimationError(/* { error } */) {
  /* Silent errors
   * showErrorNotification(error);
   */
}

export function* getICPeriodEstimationCompleted() {
  yield put(MasterPlansActions.getICPeriodEstimationCompleted());
}

export function* getICPeriodEstimation({ payload = {} }) {
  const {
    planId,
    year,
    period,
  } = payload;

  const callResult = yield call(AsyncJobsService.getICPeriodEstimation, { planId, year, period });

  if (callResult.ok) {
    const job = AsyncJobModel.transform(callResult.data);
    yield spawn(waitJob, {
      job,
      successSaga: ({ job: lastJob }) => getICPeriodEstimationSuccess({ planId, year, period, job: lastJob }),
      errorSaga: getICPeriodEstimationError,
      completedSaga: getICPeriodEstimationCompleted,
    });
  } else {
    /* Silent errors
     * const error = managePlansError(callResult);
     * showErrorNotification(error);
     */
    yield put(MasterPlansActions.getICPeriodEstimationCompleted());
  }
}

export function* getPeriodEstimationForUserSuccess({ planId, year, period, user, job }) {
  const estimation = PlanEstimationDataPointModel.transformList(JSON.parse(job.result));

  yield put(MasterPlansActions.setPeriodEstimationForUser({
    planId,
    userId: user !== null ? user.id : planId,
    year,
    period,
    estimation,
  }));
}

export function getPeriodEstimationForUserError(/* { error } */) {
  /* Silent errors
   * showErrorNotification(error);
   */
}

export function* getPeriodEstimationForUserCompleted() {
  yield put(MasterPlansActions.getPeriodEstimationForUserCompleted());
}

export function* getPeriodEstimationForUser({ payload = {} }) {
  const {
    planId,
    year,
    period,
    user,
  } = payload;

  const callResult = yield call(AsyncJobsService.getPeriodEstimationForUser, { planId, year, period, user });

  if (callResult.ok) {
    const job = AsyncJobModel.transform(callResult.data);
    yield spawn(waitJob, {
      job,
      successSaga: ({ job: lastJob }) => getPeriodEstimationForUserSuccess({ planId, year, period, user, job: lastJob }),
      errorSaga: getPeriodEstimationForUserError,
      completedSaga: getPeriodEstimationForUserCompleted,
    });
  } else {
    /* Silent errors
     * const error = managePlansError(callResult);
     * showErrorNotification(error);
     */
    yield put(MasterPlansActions.getPeriodEstimationForUserCompleted());
  }
}

export function* getPayoutSchedule({ payload = {} }) {
  const { planId, period } = payload;

  const callResult = yield call(MasterPlansService.getPayoutSchedule, { planId, period });

  if (callResult.ok) {
    const planPayoutScheduleData = PlanPayoutScheduleDataModel.transform(callResult.data);
    yield put(MasterPlansActions.setPayoutSchedule({ planId, amountPerPeriod: planPayoutScheduleData }));
  } else {
    const error = managePlansError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(MasterPlansActions.getPayoutScheduleCompleted());
}

export function* loop() {
  yield all([
    takeLatest(MasterPlansActions.getList.type, list),
    takeLatest(MasterPlansActions.getListLastPeriods.type, listLastPeriods),
    takeLatest(MasterPlansActions.getICList.type, listIC),
    takeLatest(MasterPlansActions.getListICLastPeriods.type, listICLastPeriods),
    takeEvery(MasterPlansActions.getById.type, getById),
    takeEvery(MasterPlansActions.getICById.type, getICById),
    takeEvery(MasterPlansActions.getPlanPeriods.type, getPlanPeriods),
    takeEvery(MasterPlansActions.getICPlanPeriods.type, getICPlanPeriods),
    takeEvery(MasterPlansActions.getPlanDashboard.type, getPlanDashboard),
    takeEvery(MasterPlansActions.getICPlanDashboard.type, getICPlanDashboard),
    takeLatest(MasterPlansActions.archiveById.type, archiveById),
    takeLatest(MasterPlansActions.deleteById.type, deleteById),
    takeEvery(MasterPlansActions.getPeriodBy.type, getPeriodBy),
    takeEvery(MasterPlansActions.getICPeriodBy.type, getICPeriodBy),
    takeEvery(MasterPlansActions.getPeriodUsers.type, getPeriodUsers),
    takeEvery(MasterPlansActions.getPeriodDashboard.type, getPeriodDashboard),
    takeEvery(MasterPlansActions.getICPeriodDashboard.type, getICPeriodDashboard),
    takeLatest(MasterPlansActions.getPeriodDeals.type, getPeriodDeals),
    takeLatest(MasterPlansActions.getICPeriodDeals.type, getICPeriodDeals),
    takeLatest(MasterPlansActions.downloadPeriodDeals.type, downloadPeriodDeals),
    takeLatest(MasterPlansActions.createPlan.type, createPlan),
    takeLatest(MasterPlansActions.updatePlan.type, updatePlan),
    takeLatest(MasterPlansActions.getPlanUsersAndManagers.type, getPlanUsersAndManagers),
    takeLatest(MasterPlansActions.updatePlanUserDates.type, updatePlanUserDates),
    takeLatest(MasterPlansActions.addUsersToPlan.type, addUsersToPlan),
    takeLatest(MasterPlansActions.removeUsersFromPlan.type, removeUsersFromPlan),
    takeLatest(MasterPlansActions.updatePlanUsersDefinition.type, updatePlanUsersDefinition),
    takeLatest(MasterPlansActions.updatePlanTrackingObjectDefinition.type, updatePlanTrackingObjectDefinition),
    takeLatest(MasterPlansActions.getPlanDealObjectSample.type, getPlanDealObjectSample),
    takeLatest(MasterPlansActions.getPlanUserObjectSample.type, getPlanUserObjectSample),
    takeLatest(MasterPlansActions.duplicatePlan.type, duplicatePlan),
    takeLatest(MasterPlansActions.updatePlanRules.type, updatePlanRules),
    takeLatest(MasterPlansActions.updatePayoutRules.type, updatePayoutRules),
    takeLatest(MasterPlansActions.computeAll.type, computeAll),
    takeLatest(MasterPlansActions.computePlan.type, computePlan),
    takeLatest(MasterPlansActions.computeICPlan.type, computeICPlan),
    takeLatest(MasterPlansActions.getPlanListForUser.type, getPlanListForUser),
    takeLatest(MasterPlansActions.getPlanListLastPeriodsForUser.type, getPlanListLastPeriodsForUser),
    takeLatest(MasterPlansActions.getICPeriodEstimation.type, getICPeriodEstimation),
    takeEvery(MasterPlansActions.getPeriodEstimationForUser.type, getPeriodEstimationForUser),
    takeLatest(MasterPlansActions.getPayoutSchedule.type, getPayoutSchedule),
  ]);
}

export function* clean() {
  yield put(MasterPlansActions.resetToInitialState());
}
