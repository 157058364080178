import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookCellModel from '@palette/models/NotebookCell';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './DeleteNotebookCellModal.less';

const DeleteNotebookCellModal = ({ visible, onClose, notebook, cell }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const handleDeleteNotebookCell = useCallback(() => {
    dispatch(NotebooksActions.deleteNotebookCell({ notebookId: notebook.id, notebookCellId: cell.id }));
  }, [notebook, cell]);

  return (
    <Modal
      className={styles.modal}
      title={t('deleteNotebookCellModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDeleteNotebookCell}
      okText={t('deleteNotebookCellModal.confirm')}
      loading={editNotebookIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('deleteNotebookCellModal.content', { notebookName: notebook.name, cellName: cell.name }) }} />
    </Modal>
  );
};

DeleteNotebookCellModal.propTypes = {
  notebook: NotebookModel.propTypes.isRequired,
  cell: NotebookCellModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteNotebookCellModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default DeleteNotebookCellModal;
