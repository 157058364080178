import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Input, { InputRefForwarded } from '@palette/components/designSystem/Input/Input';

import styles from './InputInCell.less';

const classNames = bindClassNames.bind(styles);

const InputInCell = ({ className, ...otherProps }, ref = {}) => {
  let InputComponent = Input;
  let refProp = {};
  if (Object.keys(ref).length !== 0) {
    InputComponent = InputRefForwarded;
    refProp = {
      ref,
    };
  }
  return (
    <InputComponent
      {...refProp}
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      clearIconWrapperClassName={styles.clearIconWrapper}
      {...otherProps}
    />
  );
};

/* eslint-disable react/no-unused-prop-types */
const propTypesShape = {
  className: PropTypes.string,
};
/* eslint-enable react/no-unused-prop-types */

const defaultPropsShape = {
  className: '',
};

InputInCell.propTypes = propTypesShape;
InputInCell.defaultProps = defaultPropsShape;

export const InputInCellRefForwarded = forwardRef((props, ref) => InputInCell(props, ref));
InputInCellRefForwarded.propTypes = propTypesShape;
InputInCellRefForwarded.defaultProps = defaultPropsShape;

export default InputInCell;
