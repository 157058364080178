import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import { Menu as AntDMenu } from 'antd';

import MenuWrapper from '@palette/components/navigation/MenuWrapper/MenuWrapper';

import menus from '@palette/config/menus';

import { usePrevious } from '@palette/hooks/CommonHooks';
import { useSelectedMenuKeys } from '@palette/hooks/MenuHooks';
import { useIsCollapsed } from '@palette/hooks/NavigationHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import styles from './Menu.less';

const classNames = bindClassNames.bind(styles);

const Menu = ({ className }) => {
  const profile = useProfile();
  const location = useLocation();

  const displayMenuTitles = menus.map((menu) => menu.show(profile)).filter(Boolean).length > 2;

  const [menuIsCollapsed] = useIsCollapsed();
  const [openSubMenuKeys, selectedMenuItemKeys] = useSelectedMenuKeys(menus, profile);
  const [openedSubMenuKeys, setOpenedSubMenuKeys] = useState();
  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (location !== previousLocation) {
      setOpenedSubMenuKeys();
    }
  }, [location]);

  useEffect(() => {
    if (!menuIsCollapsed) {
      setOpenedSubMenuKeys(openSubMenuKeys);
    }
  }, [menuIsCollapsed]);

  const handleOpenChange = (openedKeys) => setOpenedSubMenuKeys(openedKeys);

  return (
    <AntDMenu
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      theme="dark"
      mode="inline"
      defaultOpenKeys={openSubMenuKeys}
      openKeys={openedSubMenuKeys ?? openSubMenuKeys}
      selectedKeys={selectedMenuItemKeys}
      onOpenChange={handleOpenChange}
    >
      {menus.map((menu) => (
        <MenuWrapper
          displayTitle={displayMenuTitles}
          key={menu.key}
          menu={menu}
          selectedKeys={selectedMenuItemKeys}
        />
      ))}
    </AntDMenu>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
};

Menu.defaultProps = {
  className: '',
};

export default Menu;
