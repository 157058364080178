import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PlanPayoutScheduleData';

export const propTypes = PropTypes.shape({
  amountPerPeriod: PropTypes.arrayOf(PropTypes.shape({
    year: PropTypes.number,
    period: PropTypes.number,
    totalPaymentAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
  })),
});

const transformAmountPerPeriod = (amountPerPeriod) => {
  if (!amountPerPeriod) {
    return null;
  }

  return (
    {
      year: manageNumberAttribute(amountPerPeriod, 'year'),
      period: manageNumberAttribute(amountPerPeriod, 'period'),
      totalPaymentAmount: manageAmountAttribute(amountPerPeriod, 'totalPaymentAmount'),
      currency: manageStringAttribute(amountPerPeriod, 'currency'),
    }
  );
};

export const transformList = (planPayoutSchedules) => planPayoutSchedules.map((planPayoutSchedule) => transformAmountPerPeriod(planPayoutSchedule));

export const transform = (planPayoutSchedule) => {
  if (!planPayoutSchedule) {
    return null;
  }

  let amountPerPeriod = transformList(manageArrayAttribute(planPayoutSchedule, 'payoutMonths'));
  amountPerPeriod = _sortBy(amountPerPeriod, ['year', 'period']);

  return (
    {
      amountPerPeriod,
    }
  );
};
