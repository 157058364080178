import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './ResendInviteUserLink.less';

const classNames = bindClassNames.bind(styles);

const ResendInviteUserLink = ({
  className,
  user,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const profile = useProfile();

  const resendUserInvitationIsPending = useSelector(UsersSelectors.resendUserInvitationIsPending);

  const handleResendInvite = useCallback(() => {
    dispatch(UsersActions.resendUserInvitation({ userId: user.id }));
  }, [user]);

  const resendInviteUserLinkNode = useMemo(() => (
    <Popconfirm
      title={t('resendInviteUserLink.popconfirm.title')}
      onConfirm={handleResendInvite}
      okText={t('common.global.yes')}
      cancelText={t('common.global.no')}
      size="small"
    >
      <Button
        type="link"
        icon={resendUserInvitationIsPending ? (<LoadingLine spin />) : (<RefreshLine />)}
        disabled={resendUserInvitationIsPending}
      >
        {t('resendInviteUserLink.label')}
      </Button>
    </Popconfirm>
  ), [handleResendInvite, resendUserInvitationIsPending]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.INVITE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {resendInviteUserLinkNode}
    </div>
  );
};

ResendInviteUserLink.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

ResendInviteUserLink.defaultProps = {
  className: '',
};

export default ResendInviteUserLink;
