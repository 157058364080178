import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

import Button from '@palette/components/designSystem/Button/Button';
import RuleBracketForm from '@palette/components/rule/RuleBracketForm/RuleBracketForm';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import Switch from '@palette/components/designSystem/Switch/Switch';

import * as PlanRuleBracketModel from '@palette/models/PlanRuleBracket';

import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';
import { RULE_TYPES } from '@palette/constants/masterPlans';

import { usePrevious } from '@palette/hooks/CommonHooks';

import styles from './RuleCalculationForm.less';

const classNames = bindClassNames.bind(styles);

const RuleCalculationForm = ({
  className,
  brackets,
  percentage,
  helperData,
  onChangeBrackets,
  onChangePercentage,
  ruleType,
  ruleId,
}) => {
  const { t } = useTranslation();
  const previousRuleType = usePrevious(ruleType);

  const initialValues = useMemo(() => {
    let skipFirstEntry = false;

    if (brackets.length > 1) {
      if ((brackets[0].from === 0 && brackets[0].to === 0)
        || (previousRuleType !== undefined && ruleType !== previousRuleType)
      ) {
        skipFirstEntry = true;
      }
    }

    return {
      brackets: brackets.slice(ruleType === RULE_TYPES.PER_TARGET && skipFirstEntry && 1) || [],
      percentage,
    };
  }, [brackets, percentage]);

  const [stateBrackets, setStateBrackets] = useState(initialValues.brackets);
  const [statePercentage, setStatePercentage] = useState(initialValues.percentage);

  useEffect(() => {
    setStateBrackets(initialValues.brackets);
    setStatePercentage(initialValues.percentage);
  }, [initialValues]);

  const handleChange = useCallback(() => {
    if (!_isEqual(initialValues.brackets, stateBrackets)) {
      const newStateBrackets = [...stateBrackets];

      if (ruleType === RULE_TYPES.PER_TARGET && ruleType !== previousRuleType) {
        newStateBrackets.unshift({
          from: 0,
          to: newStateBrackets[0]?.from,
          formula: '0',
        });
      }

      onChangeBrackets(newStateBrackets);
    }
  }, [initialValues, stateBrackets]);

  const handleChangeStateBrackets = useCallback((newBrackets) => {
    setStateBrackets(newBrackets);
  }, []);

  const handleChangeStatePercentage = useCallback((newPercentage) => {
    setStatePercentage(newPercentage);

    onChangePercentage(stateBrackets.length > 1 || ruleType !== RULE_TYPES.PER_OBJECT ? newPercentage : false);
  }, [onChangePercentage, stateBrackets, ruleType]);

  useEffect(() => {
    handleChange();
  }, [stateBrackets]);

  const handleAddingABracket = useCallback(() => {
    const newBrackets = _cloneDeep(stateBrackets);
    let from = 0;
    if (stateBrackets.length > 0) {
      const lastBracket = stateBrackets[stateBrackets.length - 1];
      from = lastBracket.to;
      if (lastBracket.to === Infinity) {
        from = lastBracket.from + 100;
      }
      newBrackets[newBrackets.length - 1].to = from;
    }
    handleChangeStateBrackets([...newBrackets, PlanRuleBracketModel.transform({ from })]);
  }, [handleChangeStateBrackets, stateBrackets, statePercentage]);

  const handleAddATier = useCallback(() => {
    handleAddingABracket();
  }, [handleAddingABracket]);

  const handleRemoveBracket = useCallback((index) => {
    let newBrackets = _cloneDeep(stateBrackets);

    const beforeBracket = stateBrackets[index - 1];
    const afterBracket = stateBrackets[index + 1];

    if (afterBracket && beforeBracket) {
      newBrackets[index + 1].from = beforeBracket.to;
    }

    if (!afterBracket && beforeBracket) {
      newBrackets[index - 1].to = Infinity;
    }

    newBrackets.splice(index, 1);

    if (newBrackets.length === 0) {
      newBrackets = [PlanRuleBracketModel.transform({})];
    }

    handleChangeStateBrackets(newBrackets);
  }, [handleChangeStateBrackets, stateBrackets]);

  const handleBracketChange = useCallback((updatedBracket, index) => {
    const newBrackets = _cloneDeep(stateBrackets);

    // Update previous bracket "To" field
    if (newBrackets[index - 1]) {
      newBrackets[index - 1].to = updatedBracket.from;
    }

    // Update current bracket
    newBrackets.splice(index, 1, updatedBracket);

    // Update next bracket "From" field
    if (newBrackets[index + 1]) {
      if (updatedBracket.to !== null) {
        newBrackets[index + 1].from = updatedBracket.to;
      }
    }

    handleChangeStateBrackets(newBrackets);
  }, [handleChangeStateBrackets, stateBrackets]);

  const handleFormulaInputChange = useCallback((value) => {
    handleChangeStateBrackets([{
      ...(stateBrackets[0] || {}),
      formula: value,
      from: 0,
      to: Infinity,
    }]);
  }, [handleChangeStateBrackets, stateBrackets]);

  const percentageNode = useMemo(() => (
    <div className={styles.switchWrapper}>
      <div className={styles.switchLabel}>{t(`rulesCalculationForm.useValueFor.${ruleType}`)}</div>
      <Switch value={statePercentage} onChange={handleChangeStatePercentage} />
      <div className={styles.switchLabel}>{t(`rulesCalculationForm.usePercentageFor.${ruleType}`)}</div>
    </div>
  ), [handleChangeStatePercentage, statePercentage, ruleType]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {stateBrackets.length <= 1 && ruleType === RULE_TYPES.PER_OBJECT && (
        <>
          <FormulaInput
            className={styles.inputField}
            value={stateBrackets[0]?.formula || ''}
            placeholder={t('rulesCalculationForm.value.placeholder')}
            type={FORMULA_INPUT_TYPES.FORMULA}
            helperData={helperData}
            onChange={(formula) => handleFormulaInputChange(formula)}
          />
          {
            stateBrackets[0]?.formula === '' && (
              <div className={styles.inputFieldError}>{t('rulesCalculationForm.value.error.required')}</div>
            )
          }
          <Button icon={(<AddFilled />)} className={styles.useTiersBtn} onClick={handleAddATier}>
            {t('rulesCalculationForm.useTiers.label')}
          </Button>
        </>
      )}
      {(stateBrackets.length > 1 || ruleType === RULE_TYPES.PER_TARGET) && (
        <>
          {percentageNode}
          <div className={styles.bracketsWrapper}>
            {stateBrackets.map((bracket, index) => (
              <RuleBracketForm
                className={styles.container}
                ruleBracket={bracket}
                percentage={statePercentage}
                helperData={helperData}
                key={`rule-${ruleId}-bracket-${index}`}
                onChange={(updatedBracket) => handleBracketChange(updatedBracket, index)}
                onRemove={() => handleRemoveBracket(index)}
                position={index + 1}
                isFirst={index === 0}
                isLast={index === stateBrackets.length - 1}
                ruleType={ruleType}
              />
            ))}
          </div>
          <Button icon={(<AddFilled />)} onClick={handleAddATier}>
            {t(`rulesCalculationForm.addA.${ruleType}`)}
          </Button>
        </>
      )}
    </div>
  );
};

RuleCalculationForm.propTypes = {
  className: PropTypes.string,
  brackets: PropTypes.arrayOf(PlanRuleBracketModel.propTypes),
  percentage: PropTypes.bool,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  onChangeBrackets: PropTypes.func,
  onChangePercentage: PropTypes.func,
  ruleType: PropTypes.oneOf(Object.values(RULE_TYPES)),
  ruleId: PropTypes.string,
};

RuleCalculationForm.defaultProps = {
  className: '',
  brackets: [],
  percentage: false,
  helperData: {},
  onChangeBrackets: () => {},
  onChangePercentage: () => {},
  ruleType: RULE_TYPES.PER_OBJECT,
  ruleId: '',
};

export default RuleCalculationForm;
