import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import Input from '@palette/components/designSystem/Input/Input';
import FunctionLine from '@palette/components/utils/Icons/FunctionLine';
import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import VariableConditionForm from '@palette/components/variable/VariableConditionForm/VariableConditionForm';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import DragLine from '@palette/components/utils/Icons/DragLine';

import * as MasterPlanVariableModel from '@palette/models/MasterPlanVariable';
import * as MasterPlanConditionalVariableStepModel from '@palette/models/MasterPlanConditionalVariableStep';

import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';
import { VARIABLE_TYPES } from '@palette/constants/masterPlans';

import styles from './VariableForm.less';

const classNames = bindClassNames.bind(styles);

const VariableForm = ({
  className,
  variable,
  helperData,
  onRemove,
  onChange,
  prefixType,
  disabled,
  hasDragLineIcon,
  verticalMode,
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => ({
    name: variable?.name || '',
    formula: variable?.formula || '',
    steps: variable?.steps || [],
    type: variable?.type || VARIABLE_TYPES.SIMPLE,
  }), [variable]);

  const [variableValue, setVariableValue] = useState(initialValues);
  const [conditionUsed, setConditionUsed] = useState(initialValues.type === VARIABLE_TYPES.CONDITIONAL);

  useEffect(() => {
    setVariableValue(initialValues);
    setConditionUsed(initialValues.type === VARIABLE_TYPES.CONDITIONAL);
  }, [initialValues]);

  const handleChange = useCallback(() => {
    const finalVariableValue = variableValue;

    if (!conditionUsed) {
      finalVariableValue.steps = [];
      finalVariableValue.type = VARIABLE_TYPES.SIMPLE;
    } else {
      finalVariableValue.type = VARIABLE_TYPES.CONDITIONAL;
    }

    if (!_isEqual(initialValues, finalVariableValue)) {
      onChange(finalVariableValue);
    }
  }, [initialValues, variableValue, conditionUsed, onChange]);

  useEffect(() => {
    handleChange();
  }, [variableValue]);

  const handleAddCondition = useCallback(() => {
    setVariableValue({
      ...variableValue,
      steps: [...variableValue.steps, MasterPlanConditionalVariableStepModel.transform({})],
    });
  }, [variableValue]);

  useEffect(() => {
    if (conditionUsed && variableValue.steps.length === 0) {
      handleAddCondition();
    }
  }, [conditionUsed]);

  const handleRemoveCondition = useCallback((index) => {
    let newSteps = [...variableValue.steps];

    newSteps.splice(index, 1);

    if (newSteps.length === 0) {
      newSteps = [MasterPlanConditionalVariableStepModel.transform({})];
    }

    setVariableValue({
      ...variableValue,
      steps: newSteps,
    });
  }, [variableValue]);

  const handleDeleteAllConditions = useCallback((event) => {
    event.stopPropagation();
    setConditionUsed(false);
    setVariableValue({
      ...variableValue,
      steps: [],
    });
  }, [variableValue]);

  const handleNameChange = useCallback((name) => {
    setVariableValue({
      ...variableValue,
      name: name.replace(/\s/g, '_'),
    });
  }, [variableValue]);

  const handleFormulaInputChange = useCallback(({ field, value }, index = false) => {
    if (field === 'formula' && !index) {
      setVariableValue({
        ...variableValue,
        formula: value,
      });
    } else {
      const newSteps = [...variableValue.steps];
      const currentStep = newSteps[index];
      const newField = field === 'conditionFormula' ? 'formula' : field;

      newSteps.splice(index, 1, {
        ...currentStep,
        [newField]: value,
      });

      setVariableValue({
        ...variableValue,
        steps: newSteps,
      });
    }
  }, [variableValue]);

  const deleteNode = useMemo(() => (
    <Tooltip title={t('variablesForm.delete.tooltip')}>
      <Button
        className={styles.actionButton}
        type="link"
        icon={<TrashFilled className={classNames({ actionIcon: true, actionTrashIcon: true })} />}
        onClick={onRemove}
        disabled={disabled}
      />
    </Tooltip>
  ), [onRemove, disabled]);

  const collapseTitleNode = useMemo(() => (
    <div className={styles.collapseTitle}>
      {t('conditionsWithCount', { count: variableValue.steps.length || 1 })}
    </div>
  ), [variableValue]);

  const collapseExtraNode = useMemo(() => (
    <Button
      className={styles.actionDeleteLink}
      type="link"
      icon={<TrashFilled className={styles.actionIcon} />}
      onClick={handleDeleteAllConditions}
      disabled={disabled}
    >
      {t('deleteConditionsWithCount', { count: variableValue.steps.length || 1 })}
    </Button>
  ), [handleDeleteAllConditions, variableValue]);

  const nameNode = useMemo(() => (
    <FormItem
      className={classNames({
        formItem: true,
        singleLine: conditionUsed,
      })}
      label={t('variablesForm.name.title')}
    >
      <div className={styles.inputArea}>
        <Input
          className={styles.inputField}
          value={variableValue.name}
          placeholder={t('variablesForm.name.placeholder')}
          onChange={(name) => handleNameChange(name)}
          addonBefore={prefixType !== null ? `${prefixType}.` : null}
          disabled={disabled}
        />
        {(conditionUsed || verticalMode) && deleteNode}
      </div>
    </FormItem>
  ), [conditionUsed, variableValue, handleNameChange, deleteNode, verticalMode]);

  const valueNode = useMemo(() => {
    if (conditionUsed) {
      const contentNode = variableValue.steps.map((step, index) => (
        <VariableConditionForm
          key={`step-${index}`}
          prefix={index === 0 ? 'IF' : 'ELSE IF'}
          isFirst={index === 0}
          condition={step.condition}
          formula={step.formula}
          variableName={variableValue.name}
          helperData={helperData}
          onRemove={() => handleRemoveCondition(index)}
          onChange={(formula) => handleFormulaInputChange(formula, index)}
          prefixType={prefixType}
          disabled={disabled}
        />
      ));

      contentNode.push((
        <Button
          className={styles.addConditionButton}
          icon={(<AddFilled />)}
          key="addConditionButton"
          type="link"
          onClick={handleAddCondition}
          disabled={disabled}
        >
          {t('variablesForm.addACondition')}
        </Button>
      ));

      contentNode.push((
        <VariableConditionForm
          key="last"
          prefix="ELSE"
          formula={variableValue.formula}
          variableName={variableValue.name}
          helperData={helperData}
          isLast
          onChange={(formula) => handleFormulaInputChange(formula)}
          prefixType={prefixType}
          disabled={disabled}
        />
      ));

      return (
        <Collapse
          className={styles.collapseContainer}
          panels={[
            {
              title: collapseTitleNode,
              content: contentNode,
              extra: collapseExtraNode,
            },
          ]}
          ghost
          keyValue="variable-item"
          defaultActiveKey="variable-item"
        />
      );
    }

    return (
      <div className={styles.valueSingleContainer}>
        <FormItem
          className={styles.formItem}
          label={t('variablesForm.value.title')}
        >
          <div className={styles.inputArea}>
            <FormulaInput
              className={styles.formulaInput}
              value={variableValue.formula}
              placeholder={t('variablesForm.value.placeholder')}
              type={FORMULA_INPUT_TYPES.FORMULA}
              helperData={helperData}
              onChange={(formula) => handleFormulaInputChange({ field: 'formula', value: formula })}
              disabled={disabled}
            />
            <Tooltip title={t('variablesForm.fx.tooltip')}>
              <Button
                className={styles.actionButton}
                type="link"
                icon={<FunctionLine className={styles.actionIcon} />}
                onClick={() => setConditionUsed(true)}
                disabled={disabled}
              />
            </Tooltip>
            {!verticalMode && deleteNode}
          </div>
        </FormItem>
      </div>
    );
  }, [
    conditionUsed,
    variableValue,
    handleRemoveCondition,
    handleFormulaInputChange,
    handleAddCondition,
    collapseTitleNode,
    collapseExtraNode,
    setConditionUsed,
    deleteNode,
    verticalMode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div
        className={classNames({
          simpleLayout: !conditionUsed,
          conditionalLayout: conditionUsed || verticalMode,
        })}
      >
        <div className={styles.row}>
          {hasDragLineIcon && (
            <DragLine className={styles.dragIcon} width={24} height={24} />
          )}
          {nameNode}
        </div>
        {valueNode}
      </div>
    </div>
  );
};

VariableForm.propTypes = {
  className: PropTypes.string,
  variable: MasterPlanVariableModel.propTypes,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  prefixType: PropTypes.string,
  disabled: PropTypes.bool,
  hasDragLineIcon: PropTypes.bool,
  verticalMode: PropTypes.bool,
};

VariableForm.defaultProps = {
  className: '',
  variable: {},
  helperData: {},
  onRemove: () => {},
  onChange: () => {},
  prefixType: null,
  disabled: false,
  hasDragLineIcon: false,
  verticalMode: false,
};

export default VariableForm;
