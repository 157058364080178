import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { formatWidgetPrice } from '@palette/helpers/DashboardHelper';

import styles from './AmountDescriptionWidgetContent.less';

const classNames = bindClassNames.bind(styles);

const AmountDescriptionWidgetContent = ({ className, amount, currency, description, extraNode }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <div className={styles.amount}>
      {formatWidgetPrice(amount, currency)}
    </div>
    <div className={styles.description}>
      {description}
    </div>
    {extraNode}
  </div>
);

AmountDescriptionWidgetContent.propTypes = {
  className: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currency: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  extraNode: PropTypes.node,
};

AmountDescriptionWidgetContent.defaultProps = {
  className: '',
  extraNode: null,
};

export default AmountDescriptionWidgetContent;
