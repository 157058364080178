/* eslint-disable import/no-cycle */
import Axios from 'axios';

import { API_URL } from '../constants';
import { getSessionId } from '../utils';

export const updatePlan = (planId, params) => Axios.post(`${API_URL}/users/admin/plans/update`, { planId, ...params }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const deletePlan = (planId) => Axios.post(`${API_URL}/users/admin/plans/delete`, { planId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const listObjectIdsForPlan = (params) => Axios.post(`${API_URL}/users/admin/plans/group-by-objects/list`, params, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateTrackingObject = (planId, params) => Axios.post(`${API_URL}/users/admin/plans/update-tracking-object`, { planId, ...params }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateGroupByObject = (planId, params) => Axios.post(`${API_URL}/users/admin/plans/update-group-by-object`, { planId, ...params }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const freezePlan = (planId, freezeDate) => Axios.post(`${API_URL}/users/admin/plans/freeze`, { planId, freezeDate }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const renew = (planId) => Axios.post(`${API_URL}/users/admin/plans/renew`, { planId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const listTrackingObjects = (params) => Axios.post(`${API_URL}/users/admin/plans/tracking-objects/list`, params, {
  headers: {
    sessionId: getSessionId(),
  },
});
