import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageDateAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'Payment';

export const propTypes = PropTypes.shape({
  payoutRuleId: PropTypes.string,
  date: PropTypes.string,
  percentage: PropTypes.number,
  amount: PropTypes.number,
});

export const transform = (payment) => {
  if (!payment) {
    return null;
  }

  return (
    {
      payoutRuleId: manageStringAttribute(payment, 'payoutRule'),
      date: manageDateAttribute(payment, 'date'),
      percentage: managePureFloatAttribute(payment, 'percentage'),
      amount: manageAmountAttribute(payment, 'amount'),
    }
  );
};

export const transformList = (payments) => payments.map((payment) => transform(payment));
