import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import PlanV3AdvancedOptionsInputTable from '@palette/components/planV3/PlanV3AdvancedOptionsInputTable/PlanV3AdvancedOptionsInputTable';
import AddLine from '@palette/components/utils/Icons/AddLine';

import { INPUT_TABLE_CELL_TYPES } from '@palette/constants/global';
import { INPUT_TABLE_TYPES } from '@palette/constants/planV3';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3AdvancedOptionsInputTableList.less';

const classNames = bindClassNames.bind(styles);

const CUSTOM_INPUT_TABLE_VARIABLE_NAME = 'custom_table';

const PlanV3AdvancedOptionsInputTableList = ({ className, planId }) => {
  const dispatch = useDispatch();

  const inputTablesListIsPending = useSelector(PlanV3Selectors.getInputTablesListIsPending);
  const inputTablesList = useSelector(PlanV3Selectors.getInputTablesList);

  useEffect(() => {
    if (!inputTablesListIsPending) {
      dispatch(PlanV3Actions.getInputTablesList({ planId }));
    }
  }, [planId]);

  const customInputTableList = useMemo(() => {
    if (inputTablesList === null) return null;

    return inputTablesList.filter((inputTable) => inputTable.type === INPUT_TABLE_TYPES.CUSTOM);
  }, [inputTablesList]);

  const createNewCustomInputTable = useCallback(() => {
    const columns = Array.from({ length: 3 }, (_, i) => {
      const columnName = `column${i + 1}`;
      return {
        label: columnName,
        position: i + 1,
        type: INPUT_TABLE_CELL_TYPES.STRING,
      };
    });

    dispatch(PlanV3Actions.createInputTable({ planId, type: INPUT_TABLE_TYPES.CUSTOM, name: `${CUSTOM_INPUT_TABLE_VARIABLE_NAME}_${customInputTableList.length + 1}`, columns }));
  }, [customInputTableList]);

  useEffect(() => {
    if (customInputTableList?.length === 0 && !inputTablesListIsPending && inputTablesList !== null) {
      createNewCustomInputTable();
    }
  }, [customInputTableList, inputTablesListIsPending]);

  const tableNodes = useMemo(() => {
    if (!customInputTableList) return null;

    return customInputTableList.map((inputTable) => (
      <PlanV3AdvancedOptionsInputTable
        key={inputTable.id}
        className={styles.inputTable}
        planId={planId}
        inputTable={inputTable}
      />
    ));
  }, [customInputTableList]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {tableNodes}
      <div className={styles.newInputTable}>
        <div className={styles.iconWrapper} onClick={createNewCustomInputTable}>
          <AddLine className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

PlanV3AdvancedOptionsInputTableList.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

PlanV3AdvancedOptionsInputTableList.defaultProps = {
  className: '',
};

export default PlanV3AdvancedOptionsInputTableList;
