import {
  call,
  put,
  all,
  take,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { manageError as manageQuotasPerformanceError } from '@palette/state/QuotasPerformance/errors';
import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance/slice';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import { QUOTA_PERFORMANCE_ACTIONS } from '@palette/constants/quotasPerformance';
import { QUOTA_PERFORMANCE_EVENTS } from '@palette/constants/analytics';

import * as QuotasPerformanceService from '@palette/services/QuotasPerformanceService';

import * as QuotaPerformanceModel from '@palette/models/QuotaPerformance';

import routePaths from '@palette/config/routePaths';

export function* getListAndCurrent({ payload = {} }) {
  const { quotaPerformanceId } = payload;

  yield put(QuotasPerformanceActions.getList());

  yield take(QuotasPerformanceActions.getListCompleted.type);

  const listIds = yield select(QuotasPerformanceSelectors.getList);

  if (listIds.length > 0) {
    if (!quotaPerformanceId) redirectTo({ path: routePaths.v2.quotasPerformanceDetails, params: { dashboardId: listIds[0] } });
  } else {
    yield put(QuotasPerformanceActions.getListAndCurrentCompleted());
  }
}

export function* getList() {
  const callResult = yield call(QuotasPerformanceService.list);

  if (callResult.ok) {
    const quotasPerformance = QuotaPerformanceModel.transformList(callResult.data);
    yield put(QuotasPerformanceActions.setList({ quotasPerformance }));
  } else {
    const error = manageQuotasPerformanceError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasPerformanceActions.getListCompleted());
}

export function* getById({ payload = {} }) {
  const { quotaPerformanceId } = payload;

  const callResult = yield call(QuotasPerformanceService.getById, { quotaPerformanceId });

  if (callResult.ok) {
    const quotaPerformance = QuotaPerformanceModel.transform(callResult.data);
    yield put(QuotasPerformanceActions.setById({ quotaPerformance }));
  } else {
    const error = manageQuotasPerformanceError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasPerformanceActions.getByIdCompleted());
}

export function* createDashboard({ payload = {} }) {
  const {
    name,
    description,
    planIds = [],
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(QuotasPerformanceService.createDashboard, {
    name,
    description,
    planIds,
  });

  if (callResult.ok) {
    const quotaPerformanceId = callResult.data.dashboardId;

    if (onSuccessCB !== null) {
      onSuccessCB(quotaPerformanceId);
    }

    yield put(QuotasPerformanceActions.getList());

    yield take(QuotasPerformanceActions.getListCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_PERFORMANCE_EVENTS.CREATED, params: { quotaPerformanceId } } });
  } else {
    const error = manageQuotasPerformanceError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasPerformanceActions.createDashboardCompleted());
}

export function* updateDashboard({ payload = {} }) {
  const {
    quotaPerformanceId,
    name,
    description,
    action = QUOTA_PERFORMANCE_ACTIONS.UPDATE_QUOTA_PERFORMANCE_NAME,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(QuotasPerformanceService.updateDashboard, {
    quotaPerformanceId,
    name,
    description,
  });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(QuotasPerformanceActions.getById({ quotaPerformanceId }));

    yield take(QuotasPerformanceActions.getByIdCompleted.type);

    const event = action === QUOTA_PERFORMANCE_ACTIONS.UPDATE_QUOTA_PERFORMANCE_NAME ? QUOTA_PERFORMANCE_EVENTS.EDIT_NAME : QUOTA_PERFORMANCE_EVENTS.EDIT_DESCRIPTION;

    yield call(analyticsSendEvent, { payload: { event, params: { quotaPerformanceId } } });
  } else {
    const error = manageQuotasPerformanceError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasPerformanceActions.updateDashboardCompleted());
}

export function* deleteDashboard({ payload = {} }) {
  const { quotaPerformanceId, onSuccessCB = null } = payload;

  const callResult = yield call(QuotasPerformanceService.deleteDashboard, { quotaPerformanceId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(QuotasPerformanceActions.getList());

    yield take(QuotasPerformanceActions.getListCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_PERFORMANCE_EVENTS.DELETED, params: { quotaPerformanceId } } });
  } else {
    const error = manageQuotasPerformanceError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasPerformanceActions.deleteDashboardCompleted());
}

export function* addPlansToDashboard({ payload = {} }) {
  const { quotaPerformanceId, planIds, onSuccessCB = null } = payload;

  const callResult = yield call(QuotasPerformanceService.addPlansToDashboard, {
    quotaPerformanceId,
    planIds,
  });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(QuotasPerformanceActions.getList());

    yield take(QuotasPerformanceActions.getListCompleted.type);

    yield put(QuotasPerformanceActions.getById({ quotaPerformanceId }));

    yield take(QuotasPerformanceActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_PERFORMANCE_EVENTS.ADD_PLANS, params: { quotaPerformanceId } } });
  } else {
    const error = manageQuotasPerformanceError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasPerformanceActions.addPlansToDashboardCompleted());
}

export function* removePlanFromDashboard({ payload = {} }) {
  const { quotaPerformanceId, planId } = payload;

  const callResult = yield call(QuotasPerformanceService.removePlanFromDashboard, {
    quotaPerformanceId,
    planId,
  });

  if (callResult.ok) {
    yield put(QuotasPerformanceActions.getList());

    yield take(QuotasPerformanceActions.getListCompleted.type);

    yield put(QuotasPerformanceActions.getById({ quotaPerformanceId }));

    yield take(QuotasPerformanceActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_PERFORMANCE_EVENTS.REMOVE_PLAN, params: { quotaPerformanceId } } });
  } else {
    const error = manageQuotasPerformanceError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasPerformanceActions.removePlanFromDashboardCompleted());
}

export function* loop() {
  yield all([
    takeLatest(QuotasPerformanceActions.getListAndCurrent.type, getListAndCurrent),
    takeLatest(QuotasPerformanceActions.getList.type, getList),
    takeLatest(QuotasPerformanceActions.getById.type, getById),
    takeLatest(QuotasPerformanceActions.createDashboard.type, createDashboard),
    takeLatest(QuotasPerformanceActions.updateDashboard.type, updateDashboard),
    takeLatest(QuotasPerformanceActions.deleteDashboard.type, deleteDashboard),
    takeLatest(QuotasPerformanceActions.addPlansToDashboard.type, addPlansToDashboard),
    takeLatest(QuotasPerformanceActions.removePlanFromDashboard.type, removePlanFromDashboard),
  ]);
}

export function* clean() {
  yield put(QuotasPerformanceActions.resetToInitialState());
}
