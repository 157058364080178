import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PlanWidgetBase from '@palette/components/widgets/dashboard/PlanWidgetBase/PlanWidgetBase';
import PlanPayoutScheduleChart from '@palette/components/charts/PlanPayoutScheduleChart/PlanPayoutScheduleChart';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as PlanPayoutScheduleDataModel from '@palette/models/widgets/dashboard/PlanPayoutScheduleData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';

import styles from './PlanPayoutScheduleWidget.less';

const classNames = bindClassNames.bind(styles);

const PlanPayoutScheduleWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planPayoutScheduleData = PlanPayoutScheduleDataModel.transform(widgetData.data);

  const contentNode = useMemo(() => {
    if (planPayoutScheduleData.amountPerPeriod.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <PlanPayoutScheduleChart data={planPayoutScheduleData} currency={planPeriodData.currency} />
    );
  }, [planPayoutScheduleData, planPeriodData]);

  return (
    <PlanWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      useMostRecent={widgetData.dashboardComponent.params.useMostRecent}
      plan={planPeriodData.plan}
      period={planPeriodData.period}
      title={t('planPayoutScheduleWidget.title')}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      {contentNode}
    </PlanWidgetBase>
  );
};

PlanPayoutScheduleWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanPayoutScheduleWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlanPayoutScheduleWidget;
