import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
} from '@palette/helpers/ModelHelper';

import * as CommissionModel from '@palette/models/Commission';

export const modelName = 'CommissionsUnreleasedListData';

export const propTypes = PropTypes.shape({
  commissions: PropTypes.arrayOf(CommissionModel.propTypes),
});

export const transform = (commissionsUnreleasedListData) => {
  if (!commissionsUnreleasedListData) {
    return null;
  }

  return (
    {
      commissions: CommissionModel.transformList(manageArrayAttribute(commissionsUnreleasedListData, 'commissions')),
    }
  );
};
