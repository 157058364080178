import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Table from '@palette/components/designSystem/Table/Table';
import QuotaUserHeader from '@palette/components/quota/QuotaUserHeader/QuotaUserHeader';
import QuotaUserCell from '@palette/components/quota/QuotaUserCell/QuotaUserCell';
import QuotaTableBodyCell from '@palette/components/quota/QuotaTableBodyCell/QuotaTableBodyCell';
import QuotaTableTotalTitleCell from '@palette/components/quota/QuotaTableTotalTitleCell/QuotaTableTotalTitleCell';
import QuotaTableUserTotalCell from '@palette/components/quota/QuotaTableUserTotalCell/QuotaTableUserTotalCell';
import QuotaTablePeriodTotalCell from '@palette/components/quota/QuotaTablePeriodTotalCell/QuotaTablePeriodTotalCell';
import Alert from '@palette/components/designSystem/Alert/Alert';
import AddUsersInQuota from '@palette/components/quota/AddUsersInQuota/AddUsersInQuota';

import * as QuotaModel from '@palette/models/Quota';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getFilteredUsersList, getQuotaData, getQuotaTableColumns } from '@palette/helpers/QuotaHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { ALERT_TYPES } from '@palette/constants/alert';

import styles from './QuotaDetailContent.less';

const classNames = bindClassNames.bind(styles);

const QuotaDetailContent = ({
  className,
  quota,
  searchedUser,
  year,
  locked,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const tableColumns = useMemo(
    () => ([
      {
        id: 'quotaUser',
        Header: (<QuotaUserHeader disableAddBtn={quota.archived} />),
        Footer: (<QuotaTableTotalTitleCell />),
        accessor: 'quotaUserId',
        minWidth: 200,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, ...otherCellProps }) => (<QuotaUserCell quotaUserId={value} quotaId={quota.id} {...otherCellProps} />),
        disableBodyCellComponent: true,
        disableHeaderCellComponent: true,
        disableFooterCellComponent: true,
      },
      ...getQuotaTableColumns(quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell),
    ]),
    [quota.id, quota.frequency, year, locked],
  );

  const filteredUsers = useMemo(() => getFilteredUsersList(quota.users, year, searchedUser), [quota.id, quota.users, year, searchedUser]);
  const data = useMemo(() => getQuotaData(quota, filteredUsers.map((quotaUser) => (quotaUser.user)), year), [filteredUsers, quota, year]);
  const columns = useMemo(() => {
    const quotaUserIds = data.map(({ quotaUserId }) => quotaUserId);

    return tableColumns.map((column) => {
      if (column.id === 'quotaUser') return column;
      if (column.id === 'total') {
        const periods = tableColumns.reduce((allPeriods, tableColumn) => {
          if (tableColumn.id !== 'quotaUser' && tableColumn.id !== 'total') {
            allPeriods.push(tableColumn.period);
          }
          return allPeriods;
        }, []);
        return ({
          ...column,
          Footer: (<QuotaTablePeriodTotalCell quotaId={quota.id} year={year} periods={periods} quotaUserIds={quotaUserIds} />),
          disableFooterCellComponent: true,
        });
      }

      return ({
        ...column,
        Footer: (<QuotaTablePeriodTotalCell quotaId={quota.id} year={year} periods={[column.period]} quotaUserIds={quotaUserIds} />),
        disableFooterCellComponent: true,
      });
    });
  }, [data, tableColumns]);

  let noDataNode = null;
  if (data.length === 0) {
    let noDataAlertDescription = null;
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE])) {
      noDataAlertDescription = (
        <div className={styles.noDataDescription}>
          <div className={styles.noDataDescriptionContent}>
            {t('quotaDetailContent.alert.noData.description')}
          </div>
          <AddUsersInQuota />
        </div>
      );
    }
    noDataNode = (
      <Alert
        className={styles.noDataAlert}
        type={ALERT_TYPES.INFO}
        message={t('quotaDetailContent.alert.noData.message')}
        description={noDataAlertDescription}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Table columns={columns} data={data} nbOfFixedColumns={1} stickyHeader highlightRowOnHover displayFooters={data.length !== 0} fitInContainer />
      {noDataNode}
    </div>
  );
};

QuotaDetailContent.propTypes = {
  quota: QuotaModel.propTypes.isRequired,
  locked: PropTypes.bool.isRequired,
  year: PropTypes.number.isRequired,
  className: PropTypes.string,
  searchedUser: PropTypes.string,
};

QuotaDetailContent.defaultProps = {
  className: '',
  searchedUser: '',
};

export default QuotaDetailContent;
