import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementFxRates from '@palette/components/statement/statementSettings/StatementFxRates/StatementFxRates';
import StatementStrategy from '@palette/components/statement/statementSettings/StatementStrategy/StatementStrategy';
import StatementValidations from '@palette/components/statement/statementSettings/StatementValidations/StatementValidations';
import EmailNotifications from '@palette/components/settings/EmailNotifications/EmailNotifications';
import CompanySettings from '@palette/components/company/CompanySettings/CompanySettings';
import CompanyCurrencies from '@palette/components/company/CompanyCurrencies/CompanyCurrencies';
import CompanyExchangeRates from '@palette/components/company/CompanyExchangeRates/CompanyExchangeRates';
import CompanyBilling from '@palette/components/company/CompanyBilling/CompanyBilling';

import styles from './SettingsPageContent.less';

const classNames = bindClassNames.bind(styles);

const SettingsPageContent = ({ className }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <div className={styles.cols}>
      <CompanySettings className={styles.cardContainer} />
      <CompanyBilling className={styles.cardContainer} />
      <EmailNotifications className={styles.cardContainer} />
      <StatementStrategy className={styles.cardContainer} />
    </div>
    <div className={styles.cols}>
      <CompanyCurrencies className={styles.cardContainer} />
      <CompanyExchangeRates className={styles.cardContainer} />
      <StatementFxRates className={styles.cardContainer} />
    </div>
    <div className={styles.cols}>
      <StatementValidations className={styles.cardContainer} />
    </div>
  </div>
);

SettingsPageContent.propTypes = {
  className: PropTypes.string,
};

SettingsPageContent.defaultProps = {
  className: '',
};

export default SettingsPageContent;
