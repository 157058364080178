import MomentTimezone from 'moment-timezone';

export const TIMEZONE_NAMES = MomentTimezone.tz.names();

export const TIME_OFFSETS = {
  '-12:00': { minutes: -720, label: '-12' },
  '-11:00': { minutes: -660, label: '-11' },
  '-10:00': { minutes: -600, label: '-10' },
  '-09:30': { minutes: -570, label: '-9:30' },
  '-09:00': { minutes: -540, label: '-9' },
  '-08:00': { minutes: -480, label: '-8' },
  '-07:00': { minutes: -420, label: '-7' },
  '-06:00': { minutes: -360, label: '-6' },
  '-05:00': { minutes: -300, label: '-5' },
  '-04:00': { minutes: -240, label: '-4' },
  '-03:30': { minutes: -210, label: '-3:30' },
  '-03:00': { minutes: -180, label: '-3' },
  '-02:00': { minutes: -120, label: '-2' },
  '-01:00': { minutes: -60, label: '-1' },
  Z: { minutes: 0, label: '+0' },
  '+01:00': { minutes: 60, label: '+1' },
  '+02:00': { minutes: 120, label: '+2' },
  '+03:00': { minutes: 180, label: '+3' },
  '+03:30': { minutes: 210, label: '+3:30' },
  '+04:00': { minutes: 240, label: '+4' },
  '+04:30': { minutes: 270, label: '+4:30' },
  '+05:00': { minutes: 300, label: '+5' },
  '+05:30': { minutes: 330, label: '+5:30' },
  '+05:45': { minutes: 345, label: '+5:45' },
  '+06:00': { minutes: 360, label: '+6' },
  '+06:30': { minutes: 390, label: '+6:30' },
  '+07:00': { minutes: 420, label: '+7' },
  '+08:00': { minutes: 480, label: '+8' },
  '+08:45': { minutes: 525, label: '+8:45' },
  '+09:00': { minutes: 540, label: '+9' },
  '+09:30': { minutes: 570, label: '+9:30' },
  '+10:00': { minutes: 600, label: '+10' },
  '+10:30': { minutes: 630, label: '+10:30' },
  '+11:00': { minutes: 660, label: '+11' },
  '+12:00': { minutes: 720, label: '+12' },
  '+12:45': { minutes: 765, label: '+12:45' },
  '+13:00': { minutes: 780, label: '+13' },
  '+14:00': { minutes: 840, label: '+14' },
};

export const TIMEZONES_AND_TIME_OFFSETS_LIST = TIMEZONE_NAMES.concat(Object.keys(TIME_OFFSETS));
