import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import UserStatementAdjustmentsItem from '@palette/components/statement/userStatement/UserStatementAdjustmentsItem/UserStatementAdjustmentsItem';
import AddEditAdjustmentModal from '@palette/components/statement/userStatement/AddEditAdjustmentModal/AddEditAdjustmentModal';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './UserStatementAdjustments.less';

const classNames = bindClassNames.bind(styles);

const UserStatementAdjustments = ({ className, statementPeriod, userStatement, currency }) => {
  const { t } = useTranslation();

  const profile = useProfile();

  const [addAdjustmentModalIsVisible, showAddAdjustmentModal] = useState(false);

  const handleAddAnAdjustmentClick = useCallback((event) => {
    event.stopPropagation();
    if (!userStatement.isPaid) showAddAdjustmentModal(true);
  }, [userStatement]);

  const contentNode = useMemo(() => {
    if (!userStatement) return null;

    const count = userStatement.adjustments.length;

    const title = (
      <div className={styles.title}>
        {t('userStatementAdjustments.collapse.title', { count })}
      </div>
    );

    let content;

    if (count === 0) {
      content = <DefaultEmptyState />;
    } else {
      content = [(
        <div className={styles.adjustmentsListHeader} key="adjustements-list-header">
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.reasonLabel')}</span>
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.amountLabel')}</span>
          <span className={styles.colRow}>{t('userStatementAdjustments.collapse.whenLabel')}</span>
        </div>
      )];

      content.push(userStatement.adjustments.map((adjustment) => (
        <UserStatementAdjustmentsItem
          key={adjustment.id}
          userStatement={userStatement}
          statementPeriod={statementPeriod}
          adjustment={adjustment}
          currency={currency}
        />
      )));
    }

    const addAdjustmentButton = (
      <Button
        className={styles.addAdjustmentButton}
        type={userStatement.isPaid ? 'linkSecondary' : 'link'}
        icon={<AddFilled width={18} height={18} />}
        onClick={handleAddAnAdjustmentClick}
      >
        {t('userStatementAdjustments.collapse.addAdjustmentButtonLabel')}
      </Button>
    );

    let actionsNode = null;
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.ADJUSTMENTS.MANAGE])) {
      actionsNode = (
        <div className={styles.actions}>
          {userStatement.isPaid && (
            <Tooltip title={t('userStatementAdjustments.collapse.alreadyPaid')}>
              {addAdjustmentButton}
            </Tooltip>
          )}
          {!userStatement.isPaid && addAdjustmentButton}
        </div>
      );
    }

    const extra = (
      <div className={styles.extraDetails}>
        <div className={styles.colRow}>
          {actionsNode}
          <div className={styles.totalWrapper}>
            <div className={styles.totalLabel}>
              {t('userStatementAdjustments.collapse.total')}
            </div>
            <div className={styles.totalAmount}>
              {formatPrice(userStatement.adjustmentsTotalAmount, userStatement.currency)}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title,
          content,
          extra,
        }]}
        keyValue={userStatement.id}
        defaultActiveKey={count > 0 ? userStatement.id : undefined}
      />
    );
  }, [profile, userStatement, statementPeriod, currency, handleAddAnAdjustmentClick]);

  if (!userStatement) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      {addAdjustmentModalIsVisible && !userStatement.isPaid && (
        <AddEditAdjustmentModal
          visible
          onClose={() => showAddAdjustmentModal(false)}
          statementId={userStatement.id}
          statementAmount={userStatement.statementAmount}
          statementCurrency={userStatement.currency}
          statementPeriod={statementPeriod}
          currency={currency}
        />
      )}
    </div>
  );
};

UserStatementAdjustments.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
  statementPeriod: StatementPeriodModel.propTypes,
  currency: PropTypes.string,
};

UserStatementAdjustments.defaultProps = {
  className: '',
  userStatement: null,
  statementPeriod: null,
  currency: null,
};

export default UserStatementAdjustments;
