import PropTypes from 'prop-types';
import _keyBy from 'lodash/keyBy';
import _orderBy from 'lodash/orderBy';

import {
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as UserVariableOverrideItemModel from '@palette/models/UserVariableOverrideItem';

export const modelName = 'UserVariableOverride';

export const propTypes = PropTypes.shape({
  userId: PropTypes.string,
  overrides: PropTypes.objectOf(PropTypes.arrayOf(UserVariableOverrideItemModel.propTypes)),
});

const manageOverrides = (userOverrides, variables) => {
  const variablesById = _keyBy(variables, 'id');

  return Object.entries(userOverrides).reduce((res, [userVariableId, overrides]) => {
    const overridesArray = manageArrayAttribute({ overrides }, 'overrides');

    res[userVariableId] = _orderBy(
      UserVariableOverrideItemModel.transformList(overridesArray, variablesById[userVariableId].type),
      ['date'],
    );

    return res;
  }, {});
};

export const transform = (userVariableOverride, variables) => {
  if (!userVariableOverride) {
    return null;
  }

  return (
    {
      userId: manageStringAttribute(userVariableOverride, 'userId'),
      overrides: manageOverrides(manageFreeShapeObjectAttribute(userVariableOverride, 'overrides'), variables),
    }
  );
};

export const transformList = (userVariableOverrides, variables) => userVariableOverrides.map((userVariableOverride) => transform(userVariableOverride, variables));
