import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as ApproveStatementStrategyModel from '@palette/models/globalNotifStrategies/statement/ApproveStatementStrategy';

const classNames = bindClassNames.bind();

const ApproveStatementContentStrategy = ({ className, content }) => {
  const { total } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notifications.statements.success.approveStatements"
        values={{ count: total }}
      />
    </span>
  );
};

ApproveStatementContentStrategy.propTypes = {
  className: PropTypes.string,
  content: ApproveStatementStrategyModel.propTypes.isRequired,
};

ApproveStatementContentStrategy.defaultProps = {
  className: '',
};

export default ApproveStatementContentStrategy;
