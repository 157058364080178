import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute, manageDateAttribute, manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as YearPeriodModel from '@palette/models/YearPeriod';
import * as ResourceObjectModel from '@palette/models/ResourceObject';
import * as MasterPlanModel from '@palette/models/MasterPlan';

export const modelName = 'LatestObjectsData';

export const planWithPeriodsPropTypes = PropTypes.shape({
  plan: MasterPlanModel.propTypes,
  periods: PropTypes.arrayOf(YearPeriodModel.propTypes),
});

export const dealPropTypes = PropTypes.shape({
  resourceObject: ResourceObjectModel.propTypes,
  date: PropTypes.string,
  currency: PropTypes.string,
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  plansWithPeriods: PropTypes.arrayOf(planWithPeriodsPropTypes),
});

export const propTypes = PropTypes.shape({
  deals: PropTypes.arrayOf(dealPropTypes),
});

export const managePeriods = (periods) => periods.map((period) => (YearPeriodModel.transform(period)));

export const managePlansWithPeriods = (plansWithPeriods, types) => plansWithPeriods.map((planWithPeriods) => {
  const plan = MasterPlanModel.transform(planWithPeriods);
  const type = plan.trackingObject?.type || null;
  if (type !== null && type !== 'User' && !types.includes(type)) {
    types.push(type);
  }

  return {
    plan,
    periods: managePeriods(manageArrayAttribute(planWithPeriods, 'periods')),
  };
});

export const manageDeals = (deals, types) => deals.map((deal) => ({
  resourceObject: ResourceObjectModel.transform(deal),
  date: manageDateAttribute(deal, 'date'),
  currency: manageStringAttribute(deal, 'currency'),
  totalCommissionAmount: manageAmountAttribute(deal, 'totalCommissionAmount'),
  plansWithPeriods: managePlansWithPeriods(manageArrayAttribute(deal, 'plans'), types),
}));

export const transform = (latestObjectsData) => {
  if (!latestObjectsData) {
    return null;
  }

  const types = [];
  const deals = manageDeals(manageArrayAttribute(latestObjectsData, 'objects'), types);

  return (
    {
      deals,
      types,
    }
  );
};
