import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _cloneDeep from 'lodash/cloneDeep';

import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import styles from './UserVariableArrayValueInput.less';

const classNames = bindClassNames.bind(styles);

const UserVariableArrayValueInput = ({
  className,
  value,
  onChange,
  disabled,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [finalValue, setFinalValue] = useState(value === null ? [''] : value);

  useEffect(() => {
    setFinalValue(value === null ? [''] : value);
  }, [value]);

  const updateFinalValue = useCallback((newFinalValue) => {
    setFinalValue(newFinalValue);
    if (onChange !== null) onChange(newFinalValue);
  }, [onChange]);

  const handleChange = useCallback((newValue, index) => {
    const newFinalValue = _cloneDeep(finalValue);
    newFinalValue.splice(index, 1, newValue);

    updateFinalValue(newFinalValue);
  }, [finalValue, updateFinalValue]);

  const handleAddNewItem = useCallback(() => {
    const newFinalValue = _cloneDeep(finalValue);
    newFinalValue.push('');

    updateFinalValue(newFinalValue);
  }, [finalValue, updateFinalValue]);

  const handleDeleteItem = useCallback((index) => {
    const newFinalValue = _cloneDeep(finalValue);
    newFinalValue.splice(index, 1);

    updateFinalValue(newFinalValue);
  }, [finalValue, updateFinalValue]);

  const inputsNodes = useMemo(() => (
    finalValue.map((item, index) => (
      <div key={index} className={styles.item}>
        <div className={styles.itemInput}>
          <Input
            addonBefore={`${index + 1} - `}
            value={item}
            onChange={(newValue) => handleChange(newValue, index)}
            disabled={disabled}
            {...otherProps}
          />
        </div>
        <Popconfirm
          title={t('userVariableArrayValueInput.deleteItem.popconfirm')}
          onConfirm={() => handleDeleteItem(index)}
          okText={t('common.global.yes')}
          cancelText={t('common.global.no')}
          size="small"
        >
          <Button
            className={styles.deleteButton}
            type="link"
            icon={<TrashFilled className={styles.trashIcon} />}
          />
        </Popconfirm>
      </div>
    ))
  ), [finalValue, handleChange, handleDeleteItem]);

  const emptyListNode = useMemo(() => {
    if (inputsNodes.length > 0) return null;

    return (
      <div className={styles.emptyList}>
        {t('userVariableArrayValueInput.emptyList')}
      </div>
    );
  }, [inputsNodes]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {inputsNodes}
      {emptyListNode}
      <Button
        type="link"
        icon={<AddFilled />}
        onClick={handleAddNewItem}
      >
        {t('userVariableArrayValueInput.addNewItem')}
      </Button>
    </div>
  );
};

UserVariableArrayValueInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

UserVariableArrayValueInput.defaultProps = {
  className: '',
  value: null,
  onChange: null,
  disabled: false,
};

export default UserVariableArrayValueInput;
