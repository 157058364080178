import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import EditCompanyTimezoneModal from '@palette/components/company/EditCompanyTimezoneModal/EditCompanyTimezoneModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TIME_OFFSETS } from '@palette/constants/timezone';

import styles from './CompanyTimezone.less';

const classNames = bindClassNames.bind(styles);

const CompanyTimezone = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { company } = profile.userData;

  const [editCompanyTimezoneModalIsVisible, showEditCompanyTimezoneModal] = useState(false);

  const valueNode = useMemo(() => {
    let { timezone } = company;

    if (!timezone) return t('companyTimezone.no_default_value');

    if (TIME_OFFSETS[timezone]) {
      timezone = t('timezoneSelect.options.timeOffsetLabel', {
        timeOffsetLabel: TIME_OFFSETS[company.timezone].label,
      });
    }

    return t('companyTimezone.default_value', { timezone });
  }, [company]);

  let editButtonNode = (
    <Button
      className={styles.button}
      type="link"
      icon={<PenFilled width={16} height={16} />}
      onClick={() => showEditCompanyTimezoneModal(true)}
    />
  );

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) {
    editButtonNode = null;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.value}>
        {valueNode}
      </div>
      {editButtonNode}
      {
        editCompanyTimezoneModalIsVisible && (
          <EditCompanyTimezoneModal
            visible
            onClose={() => showEditCompanyTimezoneModal(false)}
            timezone={company.timezone}
          />
        )
      }
    </div>
  );
};

CompanyTimezone.propTypes = {
  className: PropTypes.string,
};

CompanyTimezone.defaultProps = {
  className: '',
};

export default CompanyTimezone;
