import PropTypes from 'prop-types';

import { manageAmountAttribute, manageDateAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'UserStatementAdjustment';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  reason: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  when: PropTypes.string,
});

export const transform = (userStatementAdjustment) => {
  if (!userStatementAdjustment) {
    return null;
  }

  return ({
    id: manageStringAttribute(userStatementAdjustment, '_id'),
    reason: manageStringAttribute(userStatementAdjustment, 'reason'),
    amount: manageAmountAttribute(userStatementAdjustment, 'amount'),
    currency: manageStringAttribute(userStatementAdjustment, 'currency'),
    when: manageDateAttribute(userStatementAdjustment, 'when'),
  });
};

export const transformList = (userStatementAdjustments) => userStatementAdjustments.map((userStatementAdjustment) => transform(userStatementAdjustment));
