import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import EditVariableValue from '@palette/components/usersVariables/EditVariableValue/EditVariableValue';
import ShowVariableValueHistory from '@palette/components/usersVariables/ShowVariableValueHistory/ShowVariableValueHistory';
import UserVariableValueCellContent from '@palette/components/usersVariables/UserVariableValueCellContent/UserVariableValueCellContent';

import * as UserVariableModel from '@palette/models/UserVariable';
import * as MetaUserModel from '@palette/models/MetaUser';
import * as UserVariableOverrideModel from '@palette/models/UserVariableOverride';

import styles from './UsersVariablesTableBodyCell.less';

const classNames = bindClassNames.bind(styles);

const UsersVariablesTableBodyCell = ({
  className,
  user,
  userVariable,
  value,
  overrides,
}) => {
  const { t } = useTranslation();

  const valueNode = useMemo(() => (
    <UserVariableValueCellContent className={styles.cellContent} userVariable={userVariable} value={value} />
  ), [userVariable, value]);

  const actionsNode = useMemo(() => (
    <div className={styles.actions}>
      <Tooltip title={t('usersVariablesTableBodyCell.editValue.tooltip')}>
        <EditVariableValue user={user} userVariable={userVariable} currentValue={value} />
      </Tooltip>
      <Tooltip title={t('usersVariablesTableBodyCell.showHistory.tooltip')}>
        <ShowVariableValueHistory className={styles.showHistoryBtn} user={user} userVariable={userVariable} overrides={overrides} />
      </Tooltip>
    </div>
  ), [user, userVariable, value, overrides]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {valueNode}
      {actionsNode}
    </div>
  );
};

UsersVariablesTableBodyCell.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
  userVariable: UserVariableModel.propTypes.isRequired,
  value: UserVariableModel.propTypesShape.defaultValue.isRequired,
  overrides: PropTypes.arrayOf(UserVariableOverrideModel.propTypes).isRequired,
};

UsersVariablesTableBodyCell.defaultProps = {
  className: '',
};

export default UsersVariablesTableBodyCell;
