import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import * as ResourceColumnModel from '@palette/models/ResourceColumn';

import styles from './EditColumnCustomDisplayModal.less';

const EditColumnCustomDisplayModal = ({ visible, onClose, column, updateEvalFn }) => {
  const { t } = useTranslation();

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    evalFn: column.evalFn,
  }), [column]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const cleanAndClose = () => {
    onClose();
  };

  const handleFinish = (values) => {
    updateEvalFn(values.evalFn);
    cleanAndClose();
  };

  const handleValidate = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('editColumnCustomDisplayModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleValidate}
      okText={t('editColumnCustomDisplayModal.validate')}
      okIcon={<CheckFilled />}
    >
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <FormItem
          name="evalFn"
          label={t('editColumnCustomDisplayModal.form.formula.label')}
          extra={(
            // eslint-disable-next-line react/no-danger
            <span dangerouslySetInnerHTML={{ __html: t('editColumnCustomDisplayModal.form.formula.description', { fields: column.fields.join(', ') }) }} />
          )}
        >
          <Input size="big" />
        </FormItem>
      </Form>
    </Modal>
  );
};

EditColumnCustomDisplayModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  column: ResourceColumnModel.propTypes.isRequired,
  updateEvalFn: PropTypes.func.isRequired,
};

EditColumnCustomDisplayModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default EditColumnCustomDisplayModal;
