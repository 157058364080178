import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'MasterPlanConditionalVariableStep';

export const propTypes = PropTypes.shape({
  condition: PropTypes.string,
  formula: PropTypes.string,
});

export const transform = (masterPlanConditionalVariableStep) => {
  if (!masterPlanConditionalVariableStep) {
    return null;
  }

  return (
    {
      condition: manageStringAttribute(masterPlanConditionalVariableStep, 'condition'),
      formula: manageStringAttribute(masterPlanConditionalVariableStep, 'formula'),
    }
  );
};

export const transformList = (masterPlanConditionalVariableSteps) => masterPlanConditionalVariableSteps.map((masterPlanConditionalVariableStep) => transform(masterPlanConditionalVariableStep));
