import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Popover from '@palette/components/designSystem/Popover/Popover';
import StatementV3ApprovalsListItem from '@palette/components/statementV3/StatementV3ApprovalsListItem/StatementV3ApprovalsListItem';

import { isApprovalInConflict } from '@palette/helpers/StatementV3Helper';

import * as StatementV3ActionModel from '@palette/models/StatementV3Action';

import styles from './StatementV3ApprovalsListMoreAvatar.less';

const classNames = bindClassNames.bind(styles);

const StatementV3ApprovalsListMoreAvatar = ({ className, approvals, statementAmount, statementCurrency }) => {
  const listNode = useMemo(() => approvals.map((approval, index) => (
    <StatementV3ApprovalsListItem
      key={`approval-${index}`}
      approval={approval}
      type="inline"
      isInConflict={isApprovalInConflict(statementAmount, statementCurrency, approval.amount, approval.currency)}
    />
  )), [approvals, statementAmount, statementCurrency]);

  const contentNode = useMemo(() => (
    <Popover
      trigger="hover"
      placement="right"
      content={listNode}
    >
      <div className={styles.counter}>
        {`+${approvals.length}`}
      </div>
    </Popover>
  ), [approvals, listNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementV3ApprovalsListMoreAvatar.propTypes = {
  className: PropTypes.string,
  approvals: PropTypes.arrayOf(StatementV3ActionModel.propTypes).isRequired,
  statementAmount: PropTypes.number.isRequired,
  statementCurrency: PropTypes.string.isRequired,
};

StatementV3ApprovalsListMoreAvatar.defaultProps = {
  className: '',
};

export default StatementV3ApprovalsListMoreAvatar;
