import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import AddDataConnectionSelectDataConnectorStep from '@palette/components/planV3/DataSources/AddDataConnectionSelectDataConnectorStep/AddDataConnectionSelectDataConnectorStep';

import { NOTEBOOK_DATA_CONNECTION_TYPES } from '@palette/constants/notebooks';

import { selectors as PlanV3Selectors } from '@palette/state/PlanV3';

const AddDataConnectionSelectDataStepManagement = () => {
  const newDataConnectionSource = useSelector(PlanV3Selectors.getNewDataConnectionSource);

  const addDataConnectionSelectDataNode = useMemo(() => {
    if (newDataConnectionSource === null) return null;

    switch (newDataConnectionSource.type) {
      case NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR: {
        return (
          <AddDataConnectionSelectDataConnectorStep />
        );
      }
      default:
        return null;
    }
  }, [newDataConnectionSource]);

  return addDataConnectionSelectDataNode;
};

export default AddDataConnectionSelectDataStepManagement;
