import PropTypes from 'prop-types';

import { manageFreeShapeObjectAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import * as PlanContentModel from '@palette/models/globalNotifStrategies/plan/PlanContent';

export const modelName = 'PlanPayoutEvaluationErrorStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  plan: PlanContentModel.propTypes,
  field: PropTypes.string,
  formula: PropTypes.string,
  reason: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  const evaluation = manageFreeShapeObjectAttribute(strategy, 'evaluation');

  return (
    {
      contentStrategy: 'PlanPayoutEvaluationErrorContentStrategy',
      plan: PlanContentModel.transform(manageFreeShapeObjectAttribute(strategy, 'plan')),
      field: manageStringAttribute(evaluation, 'field'),
      formula: manageStringAttribute(evaluation, 'formula'),
      reason: manageStringAttribute(evaluation, 'technicalError'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
