import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import WizardFilled from '@palette/components/utils/Icons/WizardFilled';
import GenerateWithAIModal from '@palette/components/planV3/GenerateWithAIModal/GenerateWithAIModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './GenerateWithAIButton.less';

const classNames = bindClassNames.bind(styles);

const GenerateWithAIButton = ({ className, planId, codeCellId }) => {
  const { t } = useTranslation();

  const profile = useProfile();

  const [generateWithAIModalIsVisible, showGenerateWithAIModal] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="secondary"
        icon={<WizardFilled />}
        onClick={() => showGenerateWithAIModal(true)}
      >
        {t('generateWithAIButton.label')}
      </Button>
      {
        generateWithAIModalIsVisible && (
          <GenerateWithAIModal
            visible
            onClose={() => showGenerateWithAIModal(false)}
            planId={planId}
            codeCellId={codeCellId}
          />
        )
      }
    </div>
  );
};

GenerateWithAIButton.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
  codeCellId: PropTypes.string.isRequired,
};

GenerateWithAIButton.defaultProps = {
  className: '',
};

export default GenerateWithAIButton;
