import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Tag from '@palette/components/designSystem/Tag/Tag';

import { STATEMENT_PERIOD_STATUSES } from '@palette/constants/statements';

import { useStatementPeriodStatus } from '@palette/hooks/StatementHooks';

import * as StatementV3ListPeriodModel from '@palette/models/StatementV3ListPeriod';

import styles from './StatementV3PeriodStatus.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PeriodStatus = ({ className, statementPeriod, statementV3PeriodStatus, inUserStatement }) => {
  const { t } = useTranslation();

  const statementPeriodStatus = statementPeriod ? useStatementPeriodStatus(statementPeriod, inUserStatement, true) : null;

  const contentNode = useMemo(() => {
    if (!statementPeriodStatus && !statementV3PeriodStatus) return null;

    const statusId = statementV3PeriodStatus?.id ?? statementPeriodStatus?.id;

    if (!statusId) return null;

    const { icon, i18n, theme } = STATEMENT_PERIOD_STATUSES[statusId];

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <Tag
          theme={theme}
          label={t(i18n)}
          icon={icon}
        />
      </div>
    );
  }, [statementPeriodStatus, statementV3PeriodStatus]);

  return contentNode;
};

StatementV3PeriodStatus.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3ListPeriodModel.propTypes,
  statementV3PeriodStatus: PropTypes.oneOf(Object.values(STATEMENT_PERIOD_STATUSES)),
  inUserStatement: PropTypes.bool,
};

StatementV3PeriodStatus.defaultProps = {
  className: '',
  inUserStatement: false,
};

export default StatementV3PeriodStatus;
