import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import PlanV3PeriodsInputTable from '@palette/components/planV3/PlanV3PeriodsInputTable/PlanV3PeriodsInputTable';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import AddLine from '@palette/components/utils/Icons/AddLine';

import { INPUT_TABLE_CELL_TYPES } from '@palette/constants/global';
import { INPUT_TABLE_TYPES } from '@palette/constants/planV3';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3PeriodsInputTableList.less';

const classNames = bindClassNames.bind(styles);

const PERIODS_INPUT_TABLE_VARIABLE_NAME = 'periods_table';

const PlanV3PeriodsInputTableList = ({ className, details }) => {
  const dispatch = useDispatch();

  const { planId, periods } = details;
  const inputTablesListIsPending = useSelector(PlanV3Selectors.getInputTablesListIsPending);
  const createInputTableIsPending = useSelector(PlanV3Selectors.getCreateInputTableIsPending);
  const inputTablesList = useSelector(PlanV3Selectors.getInputTablesList);

  useEffect(() => {
    if (!inputTablesListIsPending) {
      dispatch(PlanV3Actions.getInputTablesList({ planId }));
    }
  }, [planId]);

  const periodsInputTableList = useMemo(() => {
    if (inputTablesList === null) return null;

    return inputTablesList.filter((inputTable) => inputTable.type === INPUT_TABLE_TYPES.PERIOD);
  }, [inputTablesList]);

  const createNewPeriodsInputTable = useCallback(() => {
    const columns = periods.map((period, i) => ({
      label: period.periodId,
      position: i + 1,
      type: INPUT_TABLE_CELL_TYPES.STRING,
    }));

    dispatch(PlanV3Actions.createInputTable({ planId, type: INPUT_TABLE_TYPES.PERIOD, name: `${PERIODS_INPUT_TABLE_VARIABLE_NAME}_${periodsInputTableList.length + 1}`, columns }));
  }, [periods, periodsInputTableList]);

  useEffect(() => {
    if (periodsInputTableList?.length === 0 && !inputTablesListIsPending && !createInputTableIsPending && inputTablesList !== null) {
      createNewPeriodsInputTable();
    }
  }, [periodsInputTableList]);

  const tableNodes = useMemo(() => {
    if (!periodsInputTableList) {
      return (
        <DefaultEmptyState />
      );
    }

    return periodsInputTableList.map((inputTable) => (
      <PlanV3PeriodsInputTable
        key={inputTable.id}
        className={styles.inputTable}
        details={details}
        inputTable={inputTable}
      />
    ));
  }, [
    periodsInputTableList,
    details,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {tableNodes}
      <div className={styles.newInputTable}>
        <div className={styles.iconWrapper} onClick={createNewPeriodsInputTable}>
          <AddLine className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

PlanV3PeriodsInputTableList.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
};

PlanV3PeriodsInputTableList.defaultProps = {
  className: '',
};

export default PlanV3PeriodsInputTableList;
