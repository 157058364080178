import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import ArrowNewPageLine from '@palette/components/utils/Icons/ArrowNewPageLine';

import styles from './ExternalLink.less';

const classNames = bindClassNames.bind(styles);

const ExternalLink = ({
  className,
  href,
  children,
  displayIcon,
  ...otherProps
}) => {
  let contentNode = children;
  if (displayIcon) {
    contentNode = (
      <div className={styles.contentWithIcon}>
        <div>
          {contentNode}
        </div>
        <ArrowNewPageLine className={styles.icon} width={12} height={12} />
      </div>
    );
  }

  return (
    <a
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      {...otherProps}
    >
      {contentNode}
    </a>
  );
};

ExternalLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  displayIcon: PropTypes.bool,
};

ExternalLink.defaultProps = {
  className: '',
  displayIcon: false,
};

export default ExternalLink;
