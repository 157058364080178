import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import StatementsBreadcrumb from '@palette/components/statement/statementPagesContents/StatementsBreadcrumb/StatementsBreadcrumb';
import StatementsPeriodPageContent from '@palette/components/statement/statementPagesContents/StatementsPeriodPageContent/StatementsPeriodPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useCurrencyInSearch, useStatementPeriodIdInParams } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';

import styles from './StatementsPeriodPage.less';

const StatementsPeriodPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { statementPeriod } = useStatementPeriodIdInParams();

  const [currency, handleCurrencyChange] = useCurrencyInSearch(profile.userData.company.currencies.length < 2 ? profile.userData.company.currency : null);

  if (!(hasFeature(profile, FEATURES.COMPENSATION) && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.DISPLAY]))) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.statementsPeriodTitle')} />
      <StatementsBreadcrumb className={styles.breadcrumb} statementPeriod={statementPeriod} />
      <div className={styles.titleWithCurrency}>
        <PageTitle className={styles.title}>
          <h2>{t('statementsPeriod.page.title')}</h2>
        </PageTitle>
        <CompanyCurrencySelect
          enableMixedCurrencyOption
          defaultMixedCurrencyOptionValue={null}
          value={currency}
          onChange={handleCurrencyChange}
          disabled={profile.userData.company.currencies.length < 2}
        />
      </div>
      <StatementsPeriodPageContent currency={currency} />
    </div>
  );
};

export default StatementsPeriodPage;
