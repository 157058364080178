import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as StatementHistoryUserModel from '@palette/models/StatementHistoryUser';

export const modelName = 'StatementCorrectionAppliedUpdateStrategy';

export const propTypes = PropTypes.exact({
  approver: StatementHistoryUserModel.propTypes,
  previousAmount: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
  currentAmount: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
});

export const manageAmountShape = (amountObj) => ({
  value: manageAmountAttribute(amountObj, 'value'),
  currency: manageStringAttribute(amountObj, 'currency'),
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      approver: StatementHistoryUserModel.transform(manageFreeShapeObjectAttribute(strategy, 'doneBy')),
      previousAmount: manageAmountShape(manageFreeShapeObjectAttribute(strategy, 'previousAmount')),
      currentAmount: manageAmountShape(manageFreeShapeObjectAttribute(strategy, 'currentAmount')),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
