import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import TimezoneSelect from '@palette/components/utils/TimezoneSelect/TimezoneSelect';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { ALERT_TYPES } from '@palette/constants/alert';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './EditCompanyTimezoneModal.less';

const EditCompanyTimezoneModal = ({ visible, onClose, timezone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateCompanyIsPending = useSelector(ProfileSelectors.updateCompanyIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    timezone: timezone || null,
  }), [timezone]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const cleanAndClose = () => {
    onClose();
  };

  const handleFinish = (values) => {
    let newTimezone = values.timezone;

    if (newTimezone === timezone) {
      newTimezone = undefined;
    }

    if (!newTimezone) {
      cleanAndClose();
      return;
    }

    dispatch(ProfileActions.updateCompany({ timezone: newTimezone, onSuccessCB: cleanAndClose }));
  };

  const handleUpdateTimezone = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          {t('companyTimezone.modal.title')}
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleUpdateTimezone}
      okText={t('companyTimezone.modal.save.label')}
      okIcon={<CheckFilled />}
      loading={updateCompanyIsPending}
    >
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.WARNING}
        message={t('companyTimezone.modal.disclaimer.content')}
      />
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
      >
        <FormItem
          name="timezone"
          label={t('companyTimezone.modal.select.label')}
        >
          <TimezoneSelect
            size="big"
            disabled={updateCompanyIsPending}
            placeholder={t('companyTimezone.modal.select.placeholder')}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

EditCompanyTimezoneModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  timezone: PropTypes.string,
};

EditCompanyTimezoneModal.defaultProps = {
  visible: false,
  onClose: () => {},
  timezone: '',
};

export default EditCompanyTimezoneModal;
