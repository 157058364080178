import _omit from 'lodash/omit';
import _omitBy from 'lodash/omitBy';

import { formatToISOStringWithFractionalSeconds, getLocalTimezone } from '@palette/helpers/MomentHelper';
import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { cleanInjectionFromPeriodMatchType } from '@palette/helpers/MasterPlanHelper';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* list() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.list, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* listLastPeriods(payload) {
  const { past } = payload;

  const data = { past };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.listLastPeriods, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* listIC() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.list, undefined, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* listICLastPeriods(payload) {
  const { past } = payload;

  const data = { past };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.listLastPeriods, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* getById(payload) {
  const { planId } = payload;

  const data = { planId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getById, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getICById(payload) {
  const { planId } = payload;

  const data = { planId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.getById, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* getPeriods(payload) {
  const {
    planId,
    beginDate = undefined,
    endDate = undefined,
  } = payload;

  const timezone = getLocalTimezone();
  const data = { planId, beginDate, endDate, timezone };

  if (beginDate != null) {
    data.beginDate = formatToISOStringWithFractionalSeconds(beginDate);
  }

  if (endDate != null) {
    data.endDate = formatToISOStringWithFractionalSeconds(endDate, true);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPeriods, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getICPeriods(payload) {
  const {
    planId,
    beginDate = undefined,
    endDate = undefined,
  } = payload;

  const timezone = getLocalTimezone();
  const data = { planId, beginDate, endDate, timezone };

  if (beginDate != null) {
    data.beginDate = formatToISOStringWithFractionalSeconds(beginDate);
  }

  if (endDate != null) {
    data.endDate = formatToISOStringWithFractionalSeconds(endDate, true);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.getPeriods, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* getPlanDashboard(payload) {
  const {
    planId,
    from = undefined,
    to = undefined,
    timezone = undefined,
  } = payload;

  const finalTimezone = timezone || getLocalTimezone();

  const data = { planId, from, to, timezone: finalTimezone };

  if (from != null) {
    data.from = formatToISOStringWithFractionalSeconds(from);
  }

  if (to != null) {
    data.to = formatToISOStringWithFractionalSeconds(to, true);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPlanDashboard, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getICPlanDashboard(payload) {
  const {
    planId,
    from = undefined,
    to = undefined,
    timezone = undefined,
  } = payload;

  const finalTimezone = timezone || getLocalTimezone();

  const data = { planId, from, to, timezone: finalTimezone };

  if (from != null) {
    data.from = formatToISOStringWithFractionalSeconds(from);
  }

  if (to != null) {
    data.to = formatToISOStringWithFractionalSeconds(to, true);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.getPlanDashboard, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* getPeriodBy(payload) {
  const {
    planId,
    year,
    period,
  } = payload;

  const data = { planId, period: { year, period } };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPeriodBy, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getICPeriodBy(payload) {
  const {
    planId,
    year,
    period,
  } = payload;

  const data = { planId, period: { year, period } };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.getPeriodBy, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* getPlanUsers(payload) {
  const { planId, year = undefined, period = undefined, forManagers = false } = payload;

  const data = {
    planId,
    isManager: forManagers,
  };
  if (year !== undefined && period !== undefined) {
    data.period = {
      year,
      period,
    };
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPlanUsers, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

const getPeriodDealsData = (payload) => {
  const {
    planId,
    year,
    period,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    searchedDeal = '',
    searchedUser = null,
    searchedPayee = null,
  } = payload;

  const data = {
    planId,
    period: {
      year,
      period,
    },
    page,
    limit,
    dealFilter: searchedDeal,
    download: false,
  };

  if (searchedUser !== null) {
    data.userFilter = searchedUser;
  }

  if (searchedPayee !== null) {
    data.payeeFilter = searchedPayee;
  }

  return data;
};

export function* getPeriodDeals(payload) {
  const data = getPeriodDealsData(payload);

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPeriodDeals, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getICPeriodDeals(payload) {
  const data = getPeriodDealsData(payload);

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.getPeriodDeals, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* downloadPeriodDeals(payload) {
  const data = getPeriodDealsData(payload);
  data.download = true;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPeriodDeals, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getPeriodDashboard(payload) {
  const {
    planId,
    from = undefined,
    to = undefined,
    timezone = undefined,
  } = payload;

  const finalTimezone = timezone || getLocalTimezone();

  const data = { planId, from, to, timezone: finalTimezone };

  if (from) {
    data.from = formatToISOStringWithFractionalSeconds(from);
  }

  if (to) {
    data.to = formatToISOStringWithFractionalSeconds(to, true);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPeriodDashboard, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getICPeriodDashboard(payload) {
  const {
    planId,
    from = undefined,
    to = undefined,
    timezone = undefined,
  } = payload;

  const finalTimezone = timezone || getLocalTimezone();

  const data = { planId, from, to, timezone: finalTimezone };

  if (from) {
    data.from = formatToISOStringWithFractionalSeconds(from);
  }

  if (to) {
    data.to = formatToISOStringWithFractionalSeconds(to, true);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.masterPlans.getPeriodDashboard, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* archiveById(payload) {
  const { planId } = payload;

  const data = { planId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.archiveById, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* deleteById(payload) {
  const { planId } = payload;

  const data = { planId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.deleteById, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.DELETE],
  );
}

export function* createPlan(payload) {
  const {
    name,
    scope,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod = undefined,
    endPeriod = undefined,
    customBeginAt = undefined,
    timezone = undefined,
    currency = undefined,
    creationFlowStep = undefined,
  } = payload;

  let data = {
    name,
    scope,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    endPeriod,
    customBeginAt,
    timezone,
    currency,
    flowStr: creationFlowStep,
  };

  if (frequency !== undefined) {
    data.frequency = parseInt(frequency, 10);
  }

  if (fiscalYearShift !== undefined) {
    data.fiscalYearShift = parseInt(fiscalYearShift, 10);
  }

  if (customBeginAt != null) {
    data.customBeginAt = formatToISOStringWithFractionalSeconds(customBeginAt);
  }

  data = _omitBy(data, (elem) => elem === null);

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.createPlan, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE],
  );
}

const manageInjectionsForCall = (injections) => (
  injections.map((injection) => {
    const cleanInjection = cleanInjectionFromPeriodMatchType(injection);
    const inject = _omit(cleanInjection, ['planId']);
    inject.plan = cleanInjection.planId;

    return inject;
  })
);

export function* updatePlan(payload) {
  const {
    planId,
    name = undefined,
    scope = undefined,
    periodType = undefined,
    frequency = undefined,
    fiscalYearShift = undefined,
    beginPeriod = undefined,
    endPeriod = undefined,
    customBeginAt = undefined,
    live = undefined,
    currency = undefined,
    freezeType = undefined,
    timezone = undefined,
    columns = undefined,
    variables = undefined,
    injections = undefined,
    lastFrozenPeriod = undefined,
    quotaId = undefined,
    quotaTargetStrategy = undefined,
    creationFlowStep = undefined,
    parentPlan = undefined,
    estimationDefinition = undefined,
    planComponents = undefined,
    periodComponents = undefined,
    autofreeze = undefined,
    allowWrongPayoutsPercentageValue = undefined,
  } = payload;

  const data = {
    planId,
    name,
    scope,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    endPeriod,
    customBeginAt,
    live,
    currency,
    freezeType,
    timezone,
    columns,
    variables,
    injections,
    lastFrozenPeriod,
    quota: quotaId,
    quotaTargetStrategy,
    flowStr: creationFlowStep,
    parentPlan,
    planComponents,
    periodComponents,
    autofreeze,
    allowWrongPayoutsPercentageValue,
  };

  if (frequency !== undefined) {
    data.frequency = parseInt(frequency, 10);
  }

  if (fiscalYearShift !== undefined) {
    data.fiscalYearShift = parseInt(fiscalYearShift, 10);
  }

  if (customBeginAt != null) {
    data.customBeginAt = formatToISOStringWithFractionalSeconds(customBeginAt);
  }

  // Manage update of columns - 'null' to reset columns to default value
  if (columns != null) {
    data.columns = columns.map((column) => {
      const col = _omit(column, ['displayInResources', 'displayInPlans']);
      col.resources = column.displayInResources;
      col.plans = column.displayInPlans;

      return col;
    });
  }

  if (injections != null) {
    data.injections = manageInjectionsForCall(injections);
  }

  if (estimationDefinition !== undefined) {
    data.estimation = estimationDefinition;

    if (estimationDefinition !== null) {
      data.estimation = {
        explanation: estimationDefinition.explanation,
        reference: estimationDefinition.referenceDealId,
        display: estimationDefinition.display,
      };
    }
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.updatePlan, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* addUsersToPlan(payload) {
  const {
    planId,
    usersToAdd,
    forManagers,
  } = payload;

  const data = {
    planId,
    userIds: usersToAdd.map((user) => user.id),
    isManager: forManagers,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.addUsersToPlan, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.GBO.MANAGE],
  );
}

export function* removeUsersFromPlan(payload) {
  const {
    planId,
    usersToRemove,
    forManagers,
  } = payload;

  const data = {
    planId,
    userIds: usersToRemove.map((user) => user.id),
    isManager: forManagers,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.removeUsersFromPlan, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.GBO.MANAGE],
  );
}

export function* updatePlanUserDates(payload) {
  const {
    planId,
    user,
    startDate,
    endDate,
    forManagers,
  } = payload;

  const userId = user.id;

  const data = {
    planId,
    userId,
    isManager: forManagers,
  };

  if (startDate !== undefined) {
    data.startDate = formatToISOStringWithFractionalSeconds(startDate);
  }

  if (endDate !== undefined) {
    data.endDate = formatToISOStringWithFractionalSeconds(endDate, true);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.updatePlanUserDates, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.GBO.MANAGE],
  );
}

export function* updatePlanUsersDefinition(payload) {
  const {
    planId,
    connectorId,
    type,
    uiType = undefined,
    matchingField = undefined,
  } = payload;

  const data = {
    planId,
    connector: connectorId,
    type,
    uiType,
    matchingField,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.updatePlanUsersDefinition, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR],
  );
}

export function* updatePlanTrackingObjectDefinition(payload) {
  const {
    planId,
    connectorId,
    type,
    uiType = undefined,
    matchingField = undefined,
    filterObject = undefined,
    filterFormula = undefined,
    dateField = undefined,
    valueFormula = undefined,
    valueVariables = undefined,
  } = payload;

  const data = {
    planId,
    connector: connectorId,
    type,
    uiType,
    matchingField,
    filterObject,
    filterFormula,
    dateField,
    valueFormula,
    valueVariables,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.updatePlanTrackingObjectDefinition, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR],
  );
}

export function* getPlanDealObjectSample(payload) {
  const {
    planId,
  } = payload;

  const data = {
    planId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPlanDealObjectSample, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* duplicatePlan(payload) {
  const {
    planId,
    name,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    customBeginAt,
    keepUsers,
    duplicateType,
    terminateParent,
  } = payload;

  const data = {
    planId,
    name,
    periodType,
    frequency,
    fiscalYearShift,
    beginPeriod,
    customBeginAt,
    keepUsers,
    duplicateType,
    terminateParent,
  };

  if (beginPeriod === null) {
    delete data.beginPeriod;
  }

  if (fiscalYearShift !== undefined) {
    data.fiscalYearShift = parseInt(fiscalYearShift, 10);
  }

  if (customBeginAt === null) {
    delete data.customBeginAt;
  } else {
    data.customBeginAt = formatToISOStringWithFractionalSeconds(data.customBeginAt);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.duplicatePlan, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE],
  );
}

export function* updatePlanRules(payload) {
  const { planId, rules } = payload;

  const data = { planId, rules };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.updatePlanRules, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* updatePayoutRules(payload) {
  const {
    planId,
    payoutRules,
    payoutRulesDescription,
    payoutRulesTemplate,
    payoutRulesConditionStrategy,
  } = payload;

  const data = {
    planId,
    payoutRules,
    payoutRulesDescription,
    payoutRulesTemplate,
    payoutRulesConditionStrategy,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.updatePayoutRules, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* getPlanListForUser(payload) {
  const { user } = payload;

  const data = {
    userId: user.id,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.listPlansForUser, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getPlanListLastPeriodsForUser(payload) {
  const { user, past } = payload;

  const data = {
    userId: user.id,
    past,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.listLastPeriods, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getPayoutSchedule(payload) {
  const { planId, period = undefined } = payload;

  const data = { planId, period };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.masterPlans.getPayoutSchedule, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}
