import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Form as AntDForm } from 'antd';

import styles from './FormItem.less';

const classNames = bindClassNames.bind(styles);

const FormItem = ({
  className,
  label,
  extra,
  required,
  children,
  ...otherProps
}) => (
  <AntDForm.Item
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
    label={label}
    extra={extra}
    required={required}
    {...otherProps}
  >
    {children}
  </AntDForm.Item>
);

FormItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  extra: PropTypes.node,
  required: PropTypes.bool,
  children: PropTypes.any,
};

FormItem.defaultProps = {
  className: '',
  label: null,
  extra: null,
  required: false,
  children: null,
};

export default FormItem;
