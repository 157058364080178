import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodsDatesSelector from '@palette/components/statement/statementPeriod/StatementPeriodsDatesSelector/StatementPeriodsDatesSelector';
import StatementV3PeriodsCardsList from '@palette/components/ic/statementV3/StatementV3PeriodsCardsList/StatementV3PeriodsCardsList';

import styles from './StatementV3PeriodsNavigation.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PeriodsNavigation = ({ className }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <StatementPeriodsDatesSelector className={styles.datesSelector} />
    <StatementV3PeriodsCardsList />
  </div>
);

StatementV3PeriodsNavigation.propTypes = {
  className: PropTypes.string,
};

StatementV3PeriodsNavigation.defaultProps = {
  className: '',
};

export default StatementV3PeriodsNavigation;
