import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import DragLine from '@palette/components/utils/Icons/DragLine';
import PlanColumnEdition from '@palette/components/masterPlan/PlanColumnEdition/PlanColumnEdition';
import Button from '@palette/components/designSystem/Button/Button';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import * as ResourceColumnModel from '@palette/models/ResourceColumn';

import styles from './DraggablePlanColumnEdition.less';

const classNames = bindClassNames.bind(styles);

const DraggablePlanColumnEdition = ({
  className,
  column,
  helperData,
  onNameChange,
  onFieldsChange,
  onFormatterChange,
  onEvalFnChange,
  onRemoveColumn,
  disabled,
}) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <DragLine className={styles.dragIcon} width={24} height={24} />
    <PlanColumnEdition
      column={column}
      helperData={helperData}
      onNameChange={onNameChange}
      onFieldsChange={onFieldsChange}
      onFormatterChange={onFormatterChange}
      onEvalFnChange={onEvalFnChange}
    />
    <Button
      className={styles.removeButton}
      type="primary"
      shape="circle"
      onClick={onRemoveColumn}
      icon={<ClosePopupFilled width={24} height={24} />}
      disabled={disabled}
    />
  </div>
);

DraggablePlanColumnEdition.propTypes = {
  className: PropTypes.string,
  column: ResourceColumnModel.propTypes.isRequired,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  onNameChange: PropTypes.func.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
  onFormatterChange: PropTypes.func.isRequired,
  onEvalFnChange: PropTypes.func.isRequired,
  onRemoveColumn: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

DraggablePlanColumnEdition.defaultProps = {
  className: '',
};

export default DraggablePlanColumnEdition;
