import React from 'react';
import moment from 'moment';
import MomentTimezone from 'moment-timezone';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  DollarOutlined, FileTextOutlined, LoadingOutlined,
  DownloadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  List, message,
  Tag, Tooltip, Button,
  Divider,
  Pagination,
} from 'antd';
import _ from 'lodash';
import pluralize from 'pluralize';

import CommissionPaymentsPeriodYearSelector from '@palette/components/payments/CommissionPaymentsPeriodYearSelector/CommissionPaymentsPeriodYearSelector';

import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

import { listPayments } from '../../../services/admin/commissions';
import {
  getTagColorFromScope, parseSearch,
  stringifySearch,
  getCurrencyLabel,
} from '../../../services/utils';
import {
  API_URL,
  DEFAULT_PAGINATION_LIMIT,
  SCOPE,
} from '../../../services/constants';
import PageTopTitle from '../../../components/pageTopTitle';

class CommissionPayments extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      loading: false,
      exportLoading: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    const oldSearch = parseSearch(prevProps.location.search);
    const newSearch = parseSearch(this.props.location.search);
    if (
      oldSearch.page !== newSearch.page
      || oldSearch.limit !== newSearch.limit
      || oldSearch.period !== newSearch.period
      || oldSearch.year !== newSearch.year
    ) {
      this.fetch();
    }
  }

  fetch = () => {
    this.setState({ loading: true }, async () => {
      const {
        period,
        year: y,
        page,
        limit,
      } = parseSearch(this.props.location.search);
      const currentYear = Number.parseInt(y || new Date().getFullYear(), 10);
      const currentMonth = Number.parseInt(period || new Date().getMonth(), 10);
      const timezone = MomentTimezone.tz.guess(true);
      const params = {
        periodType: `M${+currentMonth + 1}`,
        periodYear: currentYear,
        timezone,
      };
      Promise
        .all([
          listPayments({
            ...params,
            page: (+page || 1) - 1,
            limit: +limit || 20,
          }),
          listPayments({
            ...params,
            count: true,
          }),
        ])
        .then(([{ data: payments }, { data: count }]) => {
          this.setState({
            payments,
            loading: false,
            count,
          });
        })
        .catch(() => {
          message.error('Error while fetching data.');
          this.setState({ loading: false });
        });
    });
  };

  onPaginationChange = (page, pageSize) => {
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = {
      ...currentSearch,
      page: `${page}`,
      limit: `${pageSize}`,
    };
    if (JSON.stringify(currentSearch) !== JSON.stringify(newSearch)) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: stringifySearch(newSearch),
      });
    }
  };

  export = () => {
    this.setState({ exportLoading: true }, () => {
      const {
        period,
        year: y,
      } = parseSearch(this.props.location.search);
      const currentYear = Number.parseInt(y || new Date().getFullYear(), 10);
      const currentMonth = Number.parseInt(period || new Date().getMonth(), 10);
      const timezone = MomentTimezone.tz.guess(true);
      const params = {
        periodType: `M${+currentMonth + 1}`,
        periodYear: currentYear,
        timezone,
      };
      listPayments({
        ...params,
        download: true,
      }).then(({ data: { _id } }) => {
        window.open(`${API_URL}/downloads/${_id}`, '_blank');
        this.setState({ exportLoading: false });
      }).catch((e) => {
        console.error(e);
        message.error('Error while preparing the export. Please try again.');
        this.setState({ exportLoading: false });
      });
    });
  };

  render() {
    const {
      payments,
      loading,
      count,
      exportLoading,
    } = this.state;
    const {
      page,
      limit,
    } = parseSearch(this.props.location.search);

    return (
      <div className="div-wrapper">
        <div className="container">
          <div className="container-inner">
            <PageTopTitle
              title={(
                <div>
                  <DollarOutlined />
                  &nbsp;
                  &nbsp;
                  Commission payments
                </div>
              )}
              actions={null}
            />
            <div
              style={{ marginTop: '12px', marginLeft: '60px' }}
              className="grey"
            >
              List all payments for a given period and export them into a spreadsheet.
            </div>
            <div style={{
              paddingTop: '22px',
              paddingBottom: '22px',
              background: 'white',
              width: '900px',
              maxWidth: '100%',
              margin: 'auto',
              marginBottom: '12px',
              marginTop: '12px',
            }}
            >
              <div className="flex flex-center" style={{ marginBottom: '30px' }}>
                <CommissionPaymentsPeriodYearSelector />
              </div>
              <div className="center">
                <div className="center bold title" style={{ fontSize: '16px' }}>
                  {loading ? (
                    <span>
                      <LoadingOutlined />
                    </span>
                  ) : (
                    <span>
                      {' '}
                      {count}
                      {' '}
                      {pluralize('payment', count)}
                    </span>
                  )}

                </div>
                <div style={{ marginTop: '12px' }}>
                  <Button
                    onClick={this.export}
                    type="primary"
                    icon={<DownloadOutlined />}
                    loading={exportLoading}
                  >
                    Export payments (XLS)
                  </Button>

                </div>
              </div>
            </div>
            <List
              style={{
                marginTop: 32, width: '900px', maxWidth: '100%', margin: 'auto',
              }}
              size="small"
              itemLayout="vertical"
              dataSource={payments}
              loading={loading}
              renderItem={(payment, i) => {
                const paymentPlan = MasterPlanModel.transform(payment.plan);
                const paymentPeriod = YearPeriodModel.transform(payment.period);

                return (
                  <List.Item
                    style={{ background: (i % 2) ? 'white' : 'transparent' }}
                    key={`${payment.commission._id}${payment.rule._id}${payment.payee?._id}`}
                  >
                    <div style={{ padding: '6px 0' }} className="flex width-100">
                      <div
                        className="flex"
                        style={{ flexGrow: '1' }}
                      >
                        <div>
                          <Tag
                            color={payment.rule.type === 'PER_TARGET' ? 'orange' : 'blue'}
                            style={{ border: 'none' }}
                          >
                            {
                              payment.rule.type === 'PER_TARGET'
                                ? `${payment.rule.name ? `Rule "${payment.rule.name}" (Per Target)` : '"Per Target" Rule'}`
                                : payment.resource.type
                            }
                          </Tag>
                        </div>
                        <div>
                          <div style={{ marginBottom: 6 }}>

                            {(
                              payment.rule.type === 'PER_TARGET'
                                ? ''
                                : payment.resource.columns
                                  .map(({
                                    name,
                                    value,
                                  }, index) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Tooltip key={index} title={name}>
                                      <Tag>
                                        {value}
                                      </Tag>
                                    </Tooltip>
                                  ))
                            )}
                            <Tooltip key="dateitem" title="Date">
                              <Tag>
                                {moment(payment.resource.date)
                                  .format('MMM Do YYYY')}
                              </Tag>
                            </Tooltip>

                          </div>
                          <div className="flex" style={{ alignItems: 'center' }}>
                            <div>
                              <a href={`/#${routePaths.v2.planDetails.replace(':masterPlanId', payment.plan._id)}`}>
                                <FileTextOutlined />
                                {' '}
                                <b>{paymentPlan.name}</b>
                              </a>
                              <span className="grey" style={{ fontSize: '12px', marginLeft: '13px' }}>
                                {getPlanPeriodName(paymentPlan, paymentPeriod)}
                              </span>
                              {payment.plan.scope !== SCOPE.INDIVIDUAL && (
                                <Tag
                                  className="Hide_if_selected"
                                  style={{ marginLeft: '8px' }}
                                  color={getTagColorFromScope(payment.plan.scope)}
                                >
                                  {payment.plan.scope}
                                </Tag>
                              )}
                            </div>

                            {payment.payee && (
                              <div className="flex" style={{ alignItems: 'center' }}>
                                <Divider
                                  style={{ marginLeft: '16px', marginRight: '16px' }}
                                  type="vertical"
                                />
                                <div>
                                  <a
                                    href={(payment.payee.type === 'User') ? `#/users/${payment.payee._id}`
                                      : `#/objects/${payment.payee.CObjectConnector}/${payment.payee.CObjectType}/${payment.payee._id}`}
                                    className="bold"
                                  >
                                    <UserOutlined />
                                    &nbsp;
                                    {(payment.payee.firstName || payment.payee.lastsName)
                                      ? `${payment.payee.firstName} ${payment.payee.lastName}`
                                      : payment.payee.type}
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            textAlign: 'right',
                            color: '#ef9600',
                            fontSize: '12px',
                          }}
                          className="bold"
                        >
                          {`${getCurrencyLabel(payment.payment.currency)}${payment.payment.amount}`}
                          <br />
                          {`on ${payment.payment.statement}`}
                        </div>

                      </div>

                    </div>
                  </List.Item>
                );
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 16,
              }}
            >
              <Pagination
                size="small"
                disabled={loading}
                total={count}
                showSizeChanger
                current={+page || 1}
                onChange={this.onPaginationChange}
                pageSize={+limit || DEFAULT_PAGINATION_LIMIT}
                showTotal={() => ((loading && count && (typeof count === 'undefined')) ? '' : `${count} result${count > 1 ? 's' : ''}`)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CommissionPayments.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default React.memo(withRouter(CommissionPayments), _.isEqual);
