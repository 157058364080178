import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';

import ArrowNewPageLine from '@palette/components/utils/Icons/ArrowNewPageLine';

import { getHistoryObject } from '@palette/helpers/NavigationHelper';

import styles from './Link.less';

const classNames = bindClassNames.bind(styles);

const Link = ({
  className,
  contentClassName,
  type,
  path,
  params,
  qsObject,
  children,
  displayIcon,
  maxWidth,
  disabled,
  iconNode,
  iconIsAfter,
  ...otherProps
}) => {
  let contentNode = children;

  if (displayIcon) {
    let finalIconNode = (
      <ArrowNewPageLine className={styles.icon} width={12} height={12} />
    );

    if (iconNode !== null) {
      finalIconNode = iconNode;
    }

    contentNode = (
      <div
        className={classNames({
          contentWithIcon: true,
          iconIsAfter,
        })}
      >
        {finalIconNode}
        <div
          className={classNames({
            content: true,
            [contentClassName]: contentClassName !== '',
          })}
          style={maxWidth !== '' ? { maxWidth: `${maxWidth}rem` } : undefined}
        >
          {contentNode}
        </div>
      </div>
    );
  }

  if (disabled) {
    return (
      <div
        className={classNames({
          wrapper: true,
          secondary: type === 'secondary',
          disabled: true,
          [className]: className !== '',
        })}
        style={!displayIcon && maxWidth !== '' ? { maxWidth: `${maxWidth}rem` } : undefined}
      >
        {contentNode}
      </div>
    );
  }

  return (
    <RouterLink
      className={classNames({
        wrapper: true,
        secondary: type === 'secondary',
        [className]: className !== '',
      })}
      style={!displayIcon && maxWidth !== '' ? { maxWidth: `${maxWidth}rem` } : undefined}
      to={getHistoryObject({ path, params, qsObject })}
      {...otherProps}
    >
      {contentNode}
    </RouterLink>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
  path: PropTypes.string.isRequired,
  params: PropTypes.object,
  qsObject: PropTypes.object,
  children: PropTypes.any,
  displayIcon: PropTypes.bool,
  maxWidth: PropTypes.string,
  disabled: PropTypes.bool,
  iconNode: PropTypes.node,
  iconIsAfter: PropTypes.bool,
};

Link.defaultProps = {
  className: '',
  contentClassName: '',
  type: 'primary',
  params: {},
  qsObject: {},
  children: null,
  displayIcon: false,
  maxWidth: '',
  disabled: false,
  iconNode: null,
  iconIsAfter: true,
};

export default Link;
