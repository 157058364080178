import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getResourceColumns } from '@palette/helpers/ConnectorHelper';
import { getPeriodsFilters, getPlanFirstPeriod, yearPeriodStringToJSON } from '@palette/helpers/MasterPlanHelper';
import { getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';

import {
  CREATE_PLAN_FLOW_FIRST_STEP,
  DRAFT_PLAN_ID_QS_KEY,
  DRAFT_PLAN_STEP_QS_KEY,
  PERIODS_FILTER_CUSTOM_TYPE_PERIODS_QS_KEY,
  PERIODS_FILTER_TYPE_IDS,
  PERIODS_FILTER_TYPE_IDS_INVERTED,
  PERIODS_FILTER_TYPE_QS_KEY,
  SCOPES,
  SEARCHED_DEAL_QS_KEY,
  SEARCHED_PAYEE_QS_KEY,
  SEARCHED_USER_QS_KEY,
} from '@palette/constants/masterPlans';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as NavigationActions } from '@palette/state/Navigation';

export const useDraftMasterPlanIdInSearch = () => useKeyInSearch(DRAFT_PLAN_ID_QS_KEY, null);

export const useDraftMasterPlanStepInSearch = () => useKeyInSearch(DRAFT_PLAN_STEP_QS_KEY, CREATE_PLAN_FLOW_FIRST_STEP.value);

export const useDraftMasterPlan = () => {
  const dispatch = useDispatch();

  const [draftPlanId] = useDraftMasterPlanIdInSearch();

  const draftPlan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: draftPlanId }));

  useEffect(() => {
    if (draftPlanId !== null) {
      dispatch(MasterPlansActions.getById({ planId: draftPlanId }));
    }
  }, [draftPlanId]);

  return { draftPlanId, draftPlan };
};

export const useMasterPlanInParams = () => {
  const { masterPlanId } = useParams();

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId }));
  const isPending = useSelector((state) => MasterPlansSelectors.getByIdIsPending(state));

  return { masterPlanId, plan, isPending };
};

export const useICMasterPlanInParams = () => {
  const { masterPlanId } = useParams();

  const plan = useSelector((state) => MasterPlansSelectors.getICMasterPlanById(state, { masterPlanId }));
  const isPending = useSelector((state) => MasterPlansSelectors.getByIdIsPending(state));

  return { masterPlanId, plan, isPending };
};

export const usePeriodsFilterTypeInSearch = () => useKeyInSearch(PERIODS_FILTER_TYPE_QS_KEY, null);
export const usePeriodsFilterCustomTypeDatesInSearch = () => useKeyInSearch(PERIODS_FILTER_CUSTOM_TYPE_PERIODS_QS_KEY, null);

export const useMasterPlanPeriodsFilters = (plan, isPending) => {
  const dispatch = useDispatch();

  const [searchedPeriodsFilterType] = usePeriodsFilterTypeInSearch();
  const [customFilterDates] = usePeriodsFilterCustomTypeDatesInSearch();

  const periodsFilterType = useMemo(() => {
    if (plan === null) return null;

    if (searchedPeriodsFilterType === null) {
      const planPeriodFilters = getPeriodsFilters(plan);
      return planPeriodFilters[Object.keys(planPeriodFilters)[0]];
    }

    return PERIODS_FILTER_TYPE_IDS_INVERTED[searchedPeriodsFilterType];
  }, [plan, searchedPeriodsFilterType]);

  const customFilterPeriods = useMemo(() => {
    if (plan === null || customFilterDates === null) return null;

    return {
      fromPeriod: { period: Number(customFilterDates.fromPeriod.period), year: Number(customFilterDates.fromPeriod.year) },
      toPeriod: { period: Number(customFilterDates.toPeriod.period), year: Number(customFilterDates.toPeriod.year) },
    };
  }, [plan, customFilterDates]);

  useEffect(() => {
    if (plan !== null && !isPending) {
      const planPeriodFilters = getPeriodsFilters(plan);

      const inPlanPeriodFilters = Object.values(planPeriodFilters).find((planPeriodFilterType) => planPeriodFilterType === periodsFilterType) !== undefined;
      if (periodsFilterType !== null && !inPlanPeriodFilters) {
        const qsObject = {
          [PERIODS_FILTER_TYPE_QS_KEY]: PERIODS_FILTER_TYPE_IDS[Object.keys(planPeriodFilters)[0]],
        };
        const keysToDelete = [PERIODS_FILTER_CUSTOM_TYPE_PERIODS_QS_KEY];
        dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject, keysToDelete }));
      }
    }
  }, [plan, isPending, periodsFilterType]);

  return [periodsFilterType, customFilterPeriods];
};

export const useMasterPlanPeriodInParams = () => {
  const { masterPlanId, plan } = useMasterPlanInParams();
  const { year, periodId } = useParams();

  const parsedYear = parseInt(year, 10);
  const parsedPeriodId = parseInt(periodId, 10);

  const period = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodBy(state, { masterPlanId, year: parsedYear, periodId: parsedPeriodId }));

  return { masterPlanId, year: parsedYear, periodId: parsedPeriodId, plan, period };
};

export const useICMasterPlanPeriodInParams = () => {
  const { masterPlanId, plan } = useICMasterPlanInParams();
  const { year, periodId } = useParams();

  const parsedYear = parseInt(year, 10);
  const parsedPeriodId = parseInt(periodId, 10);

  const period = useSelector((state) => MasterPlansSelectors.getICMasterPlanPeriodBy(state, { masterPlanId, year: parsedYear, periodId: parsedPeriodId }));

  return { masterPlanId, year: parsedYear, periodId: parsedPeriodId, plan, period };
};

export const useSearchedDealInSearch = () => useKeyInSearch(SEARCHED_DEAL_QS_KEY, '');

export const useSearchedUserInSearch = () => useKeyInSearch(SEARCHED_USER_QS_KEY, null);

export const useSearchedPayeeInSearch = () => useKeyInSearch(SEARCHED_PAYEE_QS_KEY, null);

export const usePlanDealObjectSample = (plan, enforcedConnectorIdType = null) => {
  const dispatch = useDispatch();

  const dealObjectSample = useSelector(
    (state) => (MasterPlansSelectors.getMasterPlanDealObjectSample(state, { masterPlanId: plan?.id, type: enforcedConnectorIdType?.type || plan?.trackingObject?.originalType })),
  );

  useEffect(() => {
    if (plan !== null && dealObjectSample === null) {
      let enforced = null;
      if (enforcedConnectorIdType !== null && (enforcedConnectorIdType.connectorId !== plan.trackingObject?.connectorId || enforcedConnectorIdType.type !== plan.trackingObject?.originalType)) {
        enforced = enforcedConnectorIdType;
      }
      dispatch(MasterPlansActions.getPlanDealObjectSample({ planId: plan.id, enforcedConnectorIdType: enforced }));
    }
  }, [plan, dealObjectSample, enforcedConnectorIdType]);

  if (plan === null) return {};

  return dealObjectSample || {};
};

export const usePlanUserObjectSample = (plan, enforcedConnectorIdType = null) => {
  const dispatch = useDispatch();

  const userObjectSample = useSelector(
    (state) => (MasterPlansSelectors.getMasterPlanUserObjectSample(state, { masterPlanId: plan?.id, type: enforcedConnectorIdType?.type || plan?.trackingObject?.originalType })),
  );

  useEffect(() => {
    if (plan !== null && userObjectSample === null) {
      let enforced = null;
      if (enforcedConnectorIdType !== null && (enforcedConnectorIdType.connectorId !== plan.usersDefinition?.connectorId || enforcedConnectorIdType.type !== plan.usersDefinition?.originalType)) {
        enforced = enforcedConnectorIdType;
      }
      dispatch(MasterPlansActions.getPlanUserObjectSample({ planId: plan.id, enforcedConnectorIdType: enforced }));
    }
  }, [plan, userObjectSample, enforcedConnectorIdType]);

  if (plan === null) return {};

  return userObjectSample || {};
};

export const useMasterPlanPeriodUsersAndManagers = (plan, period) => {
  const dispatch = useDispatch();

  const periodUsers = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodUsers(state, { masterPlanId: plan.id, year: period.year, periodId: period.period }));
  const periodManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodManagers(state, { masterPlanId: plan.id, year: period.year, periodId: period.period }));

  useEffect(() => {
    dispatch(MasterPlansActions.getPeriodUsers({ planId: plan.id, year: period.year, period: period.period, forManagers: false }));
    if (plan.scope === SCOPES.MANAGER) {
      dispatch(MasterPlansActions.getPeriodUsers({ planId: plan.id, year: period.year, period: period.period, forManagers: true }));
    }
  }, [plan, period]);

  return useMemo(() => ([
    Object.values(periodUsers).map((periodUser) => periodUser.user),
    Object.values(periodManagers).map((periodUser) => periodUser.user),
  ]), [periodUsers, periodManagers]);
};

export const useMasterPlanColumns = (masterPlan) => {
  const trackingObjectConnector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: masterPlan.trackingObject?.connectorId }));

  if (masterPlan.columns?.length > 0) return masterPlan.columns;

  return getResourceColumns(
    trackingObjectConnector,
    masterPlan.trackingObject?.originalType,
    (column) => column.displayInPlans,
  );
};

export const useComparatorPlanInSearch = (planQSKey, planPeriodQSKey) => {
  const dispatch = useDispatch();

  const [planId] = useKeyInSearch(planQSKey, null);
  const [periodId] = useKeyInSearch(planPeriodQSKey, null);

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));
  useEffect(() => {
    if (planId !== null) {
      dispatch(MasterPlansActions.getById({ planId }));
    }
  }, [planId]);

  const period = useMemo(() => {
    if (periodId === null) return null;

    return yearPeriodStringToJSON(periodId);
  }, [periodId]);

  return [plan, period];
};

export const usePlanLimitDates = (plan) => useMemo(() => {
  const beginDate = getMomentDateFromPeriod(plan.periodType, plan.frequency, getPlanFirstPeriod(plan), plan.customBeginAt, false, plan.fiscalYearShift);
  let endDate = null;

  if (plan.periodType === PERIOD_TYPES.CUSTOM) {
    endDate = getMomentDateFromPeriod(plan.periodType, plan.frequency, getPlanFirstPeriod(plan), plan.customBeginAt, true, plan.fiscalYearShift);
  } else if (plan.endPeriod) {
    endDate = getMomentDateFromPeriod(plan.periodType, plan.frequency, plan.endPeriod, plan.customBeginAt, true, plan.fiscalYearShift);
  }

  return { beginDate, endDate };
}, [plan]);
