import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodsDatesSelector from '@palette/components/statement/statementPeriod/StatementPeriodsDatesSelector/StatementPeriodsDatesSelector';
import StatementV3PeriodsList from '@palette/components/ic/statementV3/StatementV3PeriodsList/StatementV3PeriodsList';

import styles from './StatementV3PageContent.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PageContent = ({ className }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <StatementPeriodsDatesSelector className={styles.datesSelector} />
    <StatementV3PeriodsList />
  </div>
);

StatementV3PageContent.propTypes = {
  className: PropTypes.string,
};

StatementV3PageContent.defaultProps = {
  className: '',
};

export default StatementV3PageContent;
