import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PaletteLogo from '@palette/components/utils/PaletteLogo/PaletteLogo';
import ResetPasswordForm from '@palette/components/resetPassword/ResetPasswordForm/ResetPasswordForm';
import Link from '@palette/components/designSystem/Link/Link';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { FIRST_RESET_QS_KEY } from '@palette/constants/authentication';

import routePaths from '@palette/config/routePaths';

import styles from './ResetPasswordPageContent.less';

const classNames = bindClassNames.bind(styles);

const ResetPasswordPageContent = ({ className }) => {
  const { t } = useTranslation();

  const [isFirstReset] = useKeyInSearch(FIRST_RESET_QS_KEY, '', true);

  const isNewUser = useMemo(() => (isFirstReset === 'true'), [isFirstReset]);

  const subtitleNode = useMemo(() => {
    if (!isNewUser) return null;

    return (
      <h2>
        {t('resetPasswordPageContent.isNewUser.subtitle')}
      </h2>
    );
  }, [isNewUser]);

  const loginLinkNode = useMemo(() => {
    if (isNewUser) return null;

    return (
      <Link path={routePaths.v2.login}>
        {t('resetPasswordPageContent.login')}
      </Link>
    );
  }, [isNewUser]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <PaletteLogo width={177} height={60} />
      <h1>
        {isNewUser ? t('resetPasswordPageContent.isNewUser.title') : t('resetPasswordPageContent.default.title')}
      </h1>
      {subtitleNode}
      <ResetPasswordForm className={styles.resetPasswordForm} isNewUser={isNewUser} />
      {loginLinkNode}
    </div>
  );
};

ResetPasswordPageContent.propTypes = {
  className: PropTypes.string,
};

ResetPasswordPageContent.defaultProps = {
  className: '',
};

export default ResetPasswordPageContent;
