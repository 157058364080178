import _get from 'lodash/get';

import { getI18n } from '@palette/i18n';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { getFrequencyName } from '@palette/helpers/QuotaHelper';
import { getPeriodName } from '@palette/helpers/StatementHelper';

import { ACTION_TYPE, ACTION_TYPE_CONTENT, CONTENT_PARAMS, LINK_PARAMS } from '@palette/constants/auditTrail';

const isPlanV3 = (actionType) => (ACTION_TYPE[actionType] ?? '').match(/v3/i) != null;

export const getDescriptionParams = (auditTrail) => {
  const i18n = getI18n();
  const moment = getMoment();

  const paramsArr = ACTION_TYPE_CONTENT[auditTrail.actionType];
  const result = {};

  paramsArr.forEach((param) => {
    const { content } = auditTrail;

    switch (param) {
      case CONTENT_PARAMS.PLAN_NAME:
        result.plan = content.planName;
        break;
      case CONTENT_PARAMS.PREVIOUS_PLAN_NAME:
        result.oldPlan = content.previousPlanName;
        break;
      case CONTENT_PARAMS.AMOUNT:
        result.amount = formatPrice(content.amount.value, content.amount.currency);
        break;
      case CONTENT_PARAMS.NEW_AMOUNT:
        result.newAmount = formatPrice(content.newAmount.value, content.newAmount.currency);
        break;
      case CONTENT_PARAMS.OLD_AMOUNT:
        result.oldAmount = formatPrice(content.oldAmount.value, content.oldAmount.currency);
        break;
      case CONTENT_PARAMS.PAYEE:
        result.payee = content.payee.user.displayName;
        break;
      case CONTENT_PARAMS.USER:
        result.userName = content.user.user.displayName;
        break;
      case CONTENT_PARAMS.LEAVE_DATE:
        result.leaveDate = moment.utc(content.leaveDate).format('Do MMM YYYY');
        break;
      case CONTENT_PARAMS.ENTRY:
        result.entryPath = content.entry.split('.').join(' > ');
        break;
      case CONTENT_PARAMS.NAME:
        if (isPlanV3(auditTrail.actionType)) result.planName = content.name;
        else result.connectorName = content.name;
        break;
      case CONTENT_PARAMS.PERIOD_DETAILS: {
        const { strategy, period } = content.periodDetails;
        result.periodName = getPeriodName(strategy, period.period, period.year);
        break;
      }
      case CONTENT_PARAMS.SOURCE_PERIOD_DETAILS: {
        const { strategy, period, year } = content.sourcePeriodDetails;
        result.sourcePeriodName = getPeriodName(strategy, period, year);
        break;
      }
      case CONTENT_PARAMS.TARGET_PERIOD_DETAILS: {
        const { strategy, period, year } = content.targetPeriodDetails;
        result.targetPeriodName = getPeriodName(strategy, period, year);
        break;
      }
      case CONTENT_PARAMS.QUOTA: {
        const { name, trackingType, periodType, frequency, beginAt } = content.quota;
        result.quotaName = name;
        result.quotaTrackingType = trackingType;
        result.quotaFrequencyName = getFrequencyName({ periodType, frequency, beginAt });
        break;
      }
      case CONTENT_PARAMS.QUOTA_TRACKING_TYPE:
        result.valueDefinitionLabel = content[param] !== ''
          ? i18n.t('auditTrail.action.description.commission_payout.valueDefinitionLabel', { quotaTrackingType: content[param] })
          : '';
        break;
      case CONTENT_PARAMS.NEW_STATEMENT_FX_RATE_MODE:
        result.newStatementFxRateMode = i18n.t(`statementFxRates.mode.${content.newStatementFxRateMode.toLowerCase()}`);
        break;
      case CONTENT_PARAMS.NEW_STATEMENT_VALIDATION:
        result.newStatementValidation = i18n.t(`common.palette.${content.newStatementValidation === true ? 'on' : 'off'}`).toUpperCase();
        break;
      case CONTENT_PARAMS.FREEZE_AFTER:
        result.freezeAfter = content.freezeAfter > 0
          ? ` ${i18n.t('auditTrail.action.description.auto_freeze_after', { totalDays: content.freezeAfter })}`
          : '';
        break;
      default:
        result[param] = content[param];
    }
  });

  return result;
};

export const getLinkParams = (auditTrail) => {
  const moment = getMoment();

  const actionType = Object.keys(ACTION_TYPE).find((action) => action === auditTrail.actionType);
  const linkParams = LINK_PARAMS.find((linkParam) => linkParam.actions.includes(ACTION_TYPE[actionType]));

  if (!linkParams) return null;

  const { content } = auditTrail;

  const resultParams = {};
  const resultQSObject = {};

  linkParams.urlParams.params?.forEach((param) => {
    resultParams[param.urlField] = _get(content, param.storeFieldPath.split('.'));
  });

  linkParams.urlParams.qs?.forEach((qs) => {
    let value = _get(content, qs.storeFieldPath.split('.')) || qs.storeFieldPath;
    if (qs.isDate) value = moment.utc(value).format('YYYY-MM-DD');
    resultQSObject[qs.urlField] = value;
  });

  return {
    path: linkParams.urlParams.path,
    params: resultParams,
    qsObject: resultQSObject,
  };
};
