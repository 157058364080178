import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parseSearch } from '../../../services/utils';

const ConnectSalesforce = ({ history, location }) => {
  const { success, error } = parseSearch(location.search);
  const isSuccess = success === 'true';
  history.push(isSuccess
    ? '/settings?linkSalesforceSuccess=true'
    : `/settings?linkSalesforceError=true&salesforceError=${error}`);
  return null;
};

ConnectSalesforce.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(ConnectSalesforce);
