import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import MyMasterPlanListItem from '@palette/components/ic/myMasterPlan/MyMasterPlanListItem/MyMasterPlanListItem';

import { getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';
import { getPlanLastPeriod } from '@palette/helpers/MasterPlanHelper';

import { IC_PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MyMasterPlanList.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanList = ({ className, listType }) => {
  const { t } = useTranslation();

  const listIsPending = useSelector(MasterPlansSelectors.getListIsPending);

  const plansList = useSelector((state) => MasterPlansSelectors.getPlansListByType(state, { listType }));

  let listContentNode = (
    <DefaultEmptyState description={t('masterPlanList.empty.description')} />
  );

  if (listIsPending) {
    listContentNode = (
      <Loader />
    );
  } else if (plansList.length > 0) {
    let orderedList = plansList;

    if (listType === IC_PLANS_PAGE_CONTENT_TABS_IDS.PAST) {
      orderedList = _orderBy(
        plansList,
        [
          (plan) => getMomentDateFromPeriod(plan.periodType, plan.frequency, getPlanLastPeriod(plan), plan.customBeginAt, true, plan.fiscalYearShift),
          'name',
        ],
        ['desc', 'asc'],
      );
    }

    listContentNode = orderedList.map((plan) => (
      <MyMasterPlanListItem key={plan.id} plan={plan} />
    ));
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {listContentNode}
    </div>
  );
};

MyMasterPlanList.propTypes = {
  className: PropTypes.string,
  listType: PropTypes.string,
};

MyMasterPlanList.defaultProps = {
  className: '',
  listType: IC_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE,
};

export default MyMasterPlanList;
