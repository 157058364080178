import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import Loader from '@palette/components/utils/Loader/Loader';
import PlanListForUser from '@palette/components/userDetails/PlanListForUser/PlanListForUser';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { USER_PLANS_PAGE_CONTENT_QS_KEY, USER_PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './UserDetailsPlansTab.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsPlansTab = ({ className, user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [locationTab] = useKeyInSearch(USER_PLANS_PAGE_CONTENT_QS_KEY);

  const [currentTab, setCurrentTab] = useState('');
  const [listLoaded, setListLoaded] = useState(false);
  const [lastPeriodsLoaded, setLastPeriodsLoaded] = useState({});

  const getPlanListForUserIsPending = useSelector(MasterPlansSelectors.getPlanListForUserIsPending);
  const masterPlanListForUser = useSelector(MasterPlansSelectors.getMasterPlanListForUser);

  const handleGetLastPeriodsSuccessCB = useCallback(() => {
    if (currentTab) {
      setLastPeriodsLoaded({
        ...lastPeriodsLoaded,
        [currentTab]: true,
      });
    }
  }, [currentTab, lastPeriodsLoaded]);

  const getListStatsByTabType = useCallback((type) => {
    if (
      (type === USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE || type === USER_PLANS_PAGE_CONTENT_TABS_IDS.PAST)
      && !lastPeriodsLoaded[type]
    ) {
      dispatch(MasterPlansActions.getPlanListLastPeriodsForUser({
        user,
        type,
        onSuccessCB: handleGetLastPeriodsSuccessCB,
      }));
    }
  }, [lastPeriodsLoaded, user, handleGetLastPeriodsSuccessCB]);

  const handleGetListSuccessCB = useCallback(() => {
    setListLoaded(true);
    getListStatsByTabType(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!currentTab && !locationTab) {
      setCurrentTab(USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE);
    } else if (locationTab && (locationTab !== currentTab)) {
      setCurrentTab(locationTab);
    }

    if ((currentTab === locationTab) || (!locationTab && currentTab === USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE)) {
      if (listLoaded) {
        getListStatsByTabType(currentTab);
      } else {
        dispatch(MasterPlansActions.getPlanListForUser({ user, onSuccessCB: handleGetListSuccessCB }));
      }
    }
  }, [currentTab, locationTab]);

  const handleTabChange = (key) => setCurrentTab(Object.values(USER_PLANS_PAGE_CONTENT_TABS_IDS)[key]);

  const getTabPropsByType = useCallback((type) => ({
    title: t(`masterPlansPageContent.tabs.${type}`),
    titleCount: masterPlanListForUser.stats[type] || 0,
    content: (
      <PlanListForUser planList={masterPlanListForUser[type]} listType={type} user={user} />
    ),
    defaultActive: type === USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE,
    tabKey: type,
  }), [masterPlanListForUser, user]);

  const tabs = useMemo(() => {
    const finalTabsIds = [USER_PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE, USER_PLANS_PAGE_CONTENT_TABS_IDS.PAST];

    /**
     * Do not display Archived tab if no plan inside
     */
    if (!getPlanListForUserIsPending && masterPlanListForUser.stats[USER_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED] !== 0) {
      finalTabsIds.push(USER_PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED);
    }

    return finalTabsIds.map((type) => getTabPropsByType(type));
  }, [masterPlanListForUser, getPlanListForUserIsPending, getTabPropsByType]);

  const tabsNode = useMemo(() => {
    if (getPlanListForUserIsPending) {
      return (
        <Loader />
      );
    }

    return (
      <Tabs
        className={styles.tabs}
        tabs={tabs}
        onChange={handleTabChange}
        qsTabKey={USER_PLANS_PAGE_CONTENT_QS_KEY}
      />
    );
  }, [getPlanListForUserIsPending, tabs]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {tabsNode}
    </div>
  );
};

UserDetailsPlansTab.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsPlansTab.defaultProps = {
  className: '',
};

export default UserDetailsPlansTab;
