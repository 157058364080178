import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { QUOTA_PERFORMANCE_METRIC_THEMES } from '@palette/constants/quotasPerformance';

import styles from './QuotaPerformanceTableCell.less';

const classNames = bindClassNames.bind(styles);

const QuotaPerformanceTableCell = ({
  className,
  children,
  isHighlight,
  isBold,
  isDark,
  hasBorderBottom,
  theme,
}) => (
  <div
    className={classNames({
      wrapper: true,
      highlight: isHighlight,
      bold: isBold,
      dark: isDark,
      borderBottom: hasBorderBottom,
      [`theme-${theme}`]: theme !== null,
      [className]: className !== '',
    })}
  >
    {children}
  </div>
);

QuotaPerformanceTableCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  isHighlight: PropTypes.bool,
  isBold: PropTypes.bool,
  isDark: PropTypes.bool,
  hasBorderBottom: PropTypes.bool,
  theme: PropTypes.oneOf([null, ...Object.values(QUOTA_PERFORMANCE_METRIC_THEMES)]),
};

QuotaPerformanceTableCell.defaultProps = {
  className: '',
  children: null,
  isHighlight: false,
  isBold: false,
  isDark: false,
  hasBorderBottom: false,
  theme: null,
};

export default QuotaPerformanceTableCell;
