import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import RolesListItem from '@palette/components/role/RolesListItem/RolesListItem';
import Loader from '@palette/components/utils/Loader/Loader';

import { useCompanyRoles } from '@palette/hooks/CompanyHooks';

import { selectors as CompanySelectors } from '@palette/state/Company';

import styles from './RolesPageContent.less';

const classNames = bindClassNames.bind(styles);

const RolesPageContent = ({ className }) => {
  const getRolesIsPending = useSelector(CompanySelectors.getRolesIsPending);
  const companyRoles = useCompanyRoles(true);

  const companyRolesListItemsNodes = useMemo(() => {
    if (companyRoles.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      companyRoles.map((role) => (
        <RolesListItem
          key={role.id}
          className={styles.listItem}
          role={role}
        />
      ))
    );
  }, [companyRoles]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Loader spinning={getRolesIsPending}>
        <div className={styles.list}>
          {companyRolesListItemsNodes}
        </div>
      </Loader>
    </div>
  );
};

RolesPageContent.propTypes = {
  className: PropTypes.string,
};

RolesPageContent.defaultProps = {
  className: '',
};

export default RolesPageContent;
