import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'Currency';

export const propTypes = PropTypes.shape({
  code: PropTypes.string,
  isDefault: PropTypes.bool,
});

export const transform = (currency) => {
  if (!currency) {
    return null;
  }

  return (
    {
      code: manageStringAttribute(currency, 'code'),
      isDefault: manageBooleanAttribute(currency, 'default'),
    }
  );
};

export const transformList = (currencies) => currencies.map((currency) => transform(currency));
