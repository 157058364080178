import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import PlanEditColumnsModal from '@palette/components/masterPlan/PlanEditColumnsModal/PlanEditColumnsModal';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { RIGHTS } from '@palette/constants/profile';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanEditColumns.less';

const MasterPlanEditColumns = ({ className, plan }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const [planEditColumnsModalIsVisible, showPlanEditColumnsModal] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE])) return null;

  const handleEditColumnsClick = () => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_EDIT_COLUMNS }));
    showPlanEditColumnsModal(true);
  };

  return (
    <div className={className}>
      <Button
        className={styles.linkBtn}
        type="linkSecondary"
        icon={<PenFilled />}
        onClick={handleEditColumnsClick}
      >
        {t('masterPlanPeriodDeals.actions.editColumns')}
      </Button>
      {
        planEditColumnsModalIsVisible && (
          <PlanEditColumnsModal visible onClose={() => showPlanEditColumnsModal(false)} plan={plan} />
        )
      }
    </div>
  );
};

MasterPlanEditColumns.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanEditColumns.defaultProps = {
  className: '',
};

export default MasterPlanEditColumns;
