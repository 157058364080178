import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';

import styles from './FolderPlanPreview.less';

const classNames = bindClassNames.bind(styles);

const FolderPlanPreview = ({ className, planName }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <MedicalChartFilled className={styles.icon} />
    <div className={styles.name}>
      {planName}
    </div>
  </div>
);

FolderPlanPreview.propTypes = {
  className: PropTypes.string,
  planName: PropTypes.string.isRequired,
};

FolderPlanPreview.defaultProps = {
  className: '',
};

export default FolderPlanPreview;
