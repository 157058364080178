import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';

import { ALERT_TYPES } from '@palette/constants/alert';

import styles from './NoConnectorsAlert.less';

const classNames = bindClassNames.bind(styles);

const NoConnectorsAlert = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Alert
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      type={ALERT_TYPES.WARNING}
      message={t('noConnectorsAlert.warning.message')}
      description={t('noConnectorsAlert.warning.description')}
    />
  );
};

NoConnectorsAlert.propTypes = {
  className: PropTypes.string,
};

NoConnectorsAlert.defaultProps = {
  className: '',
};

export default NoConnectorsAlert;
