import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import {
  Input as AntDInput,
  InputNumber as AntDInputNumber,
  Radio as AntDRadio,
} from 'antd';

import SearchLine from '@palette/components/utils/Icons/SearchLine';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import {
  comaSeparatorFormatter,
  comaSeparatorParser,
} from '@palette/helpers/CommonHelper';

import styles from './Input.less';

const classNames = bindClassNames.bind(styles);

const Input = ({
  className,
  clearIconWrapperClassName,
  type,
  placeholder,
  disabled,
  allowClear,
  onClear,
  onChange,
  value,
  size,
  addonBefore,
  addonAfter,
  noPadding,
  radioAlign,
  radioValues,
  theme,
  ...otherProps
}, ref = {}) => {
  const [beforeInputWidth, setBeforeInputWidth] = useState(0);
  const [afterInputWidth, setAfterInputWidth] = useState(0);

  const beforeInputNodeRef = useRef(null);
  const afterInputNodeRef = useRef(null);

  useEffect(() => {
    if (beforeInputNodeRef.current) {
      const beforeInputNodeWidth = beforeInputNodeRef.current.offsetWidth;
      setBeforeInputWidth(beforeInputNodeWidth);
    }

    if (afterInputNodeRef.current) {
      const afterInputNodeWidth = afterInputNodeRef.current.offsetWidth;
      setAfterInputWidth(afterInputNodeWidth);
    }
  }, [beforeInputNodeRef, afterInputNodeRef]);

  const [internalValue, setInternalValue] = useState(value !== null ? value : '');

  const handleOnChange = (event) => {
    let newValue = event;
    if (event && event.target) {
      newValue = event.target.value;
      if (event.type === 'click') {
        newValue = '';
      }
    }

    setInternalValue(newValue);
    onChange(newValue);
  };

  const inputValue = (value !== null ? value : internalValue);

  const handleClearInput = (e) => {
    if (onClear !== null) {
      onClear(e);
    } else {
      handleOnChange(e);
    }
  };

  let suffix = null;
  if (allowClear) {
    suffix = inputValue !== '' ? (<ClosePopupFilled className={styles.clearIcon} onClick={handleClearInput} />) : (<span />);
  }

  const commonProps = {
    className: classNames({
      wrapper: (type !== 'radio' || radioValues.length === 0),
      big: size === 'big',
      [className]: className !== '',
      clearIconWrapperClassName: allowClear && clearIconWrapperClassName !== '',
    }),
    placeholder,
    disabled,
    value: inputValue,
    onChange: handleOnChange,
    suffix,
  };

  let AntDInputTag = AntDInput;
  let beforeInputNode = null;
  let afterInputNode = null;
  let managedProps = {
    ...commonProps,
    type,
  };

  if (type === 'number' || type === 'comaSeparatorFormatted') {
    AntDInputTag = AntDInputNumber;
    managedProps = {
      ...commonProps,
      className: classNames({
        wrapper: true,
        big: size === 'big',
        [className]: className !== '',
      }),
    };
    afterInputNode = allowClear && inputValue !== '' ? (<ClosePopupFilled className={styles.clearIcon} onClick={handleClearInput} />) : null;
    if (afterInputNode === null && addonAfter !== null) {
      afterInputNode = (
        <div ref={afterInputNodeRef} className={styles.addonAfter}>
          {addonAfter}
        </div>
      );
    }
  }

  if (type === 'password') {
    AntDInputTag = AntDInput.Password;
    managedProps = commonProps;
  }

  if (type === 'search') {
    AntDInputTag = AntDInput.Search;

    managedProps = {
      ...commonProps,
      className: classNames({
        searchWrapper: true,
        themeWhite: theme === 'white',
        [className]: className !== '',
      }),
      addonAfter: null,
      suffix: inputValue !== '' ? (<ClosePopupFilled className={styles.clearIcon} onClick={handleClearInput} />) : (<span />),
      prefix: null,
      addonBefore: (<SearchLine />),
      enterButton: null,
    };
  }

  if (type === 'comaSeparatorFormatted') {
    managedProps = {
      ...managedProps,
      formatter: comaSeparatorFormatter,
      parser: comaSeparatorParser,
      max: Number.MAX_SAFE_INTEGER,
      onChange,
    };
  }

  if (addonBefore !== null) {
    beforeInputNode = (
      <div ref={beforeInputNodeRef} className={styles.addonBefore}>
        {addonBefore}
      </div>
    );
  }

  let refProp = {};
  if (Object.keys(ref).length !== 0) {
    refProp = {
      ref,
    };
  }

  const horizontalPadding = noPadding ? 0 : 16;

  let inputNode = (
    <AntDInputTag
      {...refProp}
      {...managedProps}
      style={{
        paddingLeft: beforeInputWidth + horizontalPadding,
        paddingRight: afterInputWidth + horizontalPadding,
      }}
      {...otherProps}
    />
  );

  if (type === 'radio' && radioValues.length !== 0) {
    managedProps = {
      ...commonProps,
      className: classNames({
        radioWrapper: true,
        alignColumns: radioAlign === 'col',
        [className]: className !== '',
      }),
    };

    inputNode = (
      <AntDRadio.Group
        {...refProp}
        {...managedProps}
        {...otherProps}
      >
        {radioValues.map((v, i) => (
          <AntDRadio value={v.value} key={i}>{v.label}</AntDRadio>
        ))}
      </AntDRadio.Group>
    );
  }

  if (afterInputNode === null && beforeInputNode === null) return inputNode;

  return (
    <div
      className={classNames({
        withAddon: true,
        withAddonBefore: beforeInputNode !== null,
        withAddonAfter: afterInputNode !== null,
        [clearIconWrapperClassName]: clearIconWrapperClassName !== '',
      })}
    >
      {beforeInputNode}
      {inputNode}
      {afterInputNode}
    </div>
  );
};

/* eslint-disable react/no-unused-prop-types */
const propTypesShape = {
  className: PropTypes.string,
  clearIconWrapperClassName: PropTypes.string,
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week',
    'comaSeparatorFormatted',
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  value: PropTypes.any,
  size: PropTypes.oneOf(['default', 'big']),
  addonBefore: PropTypes.any,
  addonAfter: PropTypes.any,
  noPadding: PropTypes.bool,
  radioAlign: PropTypes.oneOf(['col', 'row']),
  radioValues: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.any,
    value: PropTypes.any,
  })),
  theme: PropTypes.oneOf(['default', 'white']),
};

const defaultPropsShape = {
  className: '',
  clearIconWrapperClassName: '',
  type: 'text',
  placeholder: '',
  disabled: false,
  allowClear: false,
  onChange: () => {},
  onClear: null,
  value: null,
  size: 'default',
  addonBefore: null,
  addonAfter: null,
  noPadding: false,
  radioAlign: 'row',
  radioValues: [],
  theme: 'default',
};

Input.propTypes = propTypesShape;
Input.defaultProps = defaultPropsShape;

export const InputRefForwarded = forwardRef((props, ref) => Input(props, ref));
InputRefForwarded.propTypes = propTypesShape;
InputRefForwarded.defaultProps = defaultPropsShape;

export default Input;
