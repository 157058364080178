import PropTypes from 'prop-types';

import { manageArrayAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'GlobalErrorConstraintViolationStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  violations: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    errorMessage: PropTypes.string,
  })),
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'GlobalErrorConstraintViolationContentStrategy',
      violations: manageArrayAttribute(strategy, 'violations'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
