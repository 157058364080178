import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs as AntDTabs } from 'antd';

import { COMMISSION_DRAWER_QS_KEY } from '@palette/constants/tabs';

import { useTabKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getSearch, stringifyQS } from '@palette/helpers/NavigationHelper';

import { actions as NavigationActions, selectors as NavigationSelectors } from '@palette/state/Navigation';

import styles from './Tabs.less';

const classNames = bindClassNames.bind(styles);

const TAB_KEY_PREFIX = 'tab_';

const Tabs = ({
  className,
  tabs,
  stretched,
  disableSeeMoreTabsBtn,
  onChange,
  qsTabKey,
  inSalesforce,
  tabBarExtraContent,
  alwaysDisplayTitleCount,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const reactLocation = useLocation();
  const reactHistory = useHistory();

  const [qsActiveKey] = useTabKeyInSearch(qsTabKey, null, inSalesforce);

  const [defaultActiveKey, setDefaultActiveKey] = useState(`${TAB_KEY_PREFIX}0`);
  const [activeKey, setActiveKey] = useState(undefined);

  const location = useSelector(NavigationSelectors.getCurrentLocation);

  const handleTabChange = useCallback((newActiveTabKey) => {
    if (activeKey === newActiveTabKey) return;

    const tabIndex = parseInt(newActiveTabKey.split(TAB_KEY_PREFIX)[1], 10);

    if (onChange !== null) onChange(tabIndex);
    if (qsTabKey !== null) {
      let beforeToUpdateLocation = location;

      if (activeKey) {
        const activeTabIndex = parseInt(activeKey.split(TAB_KEY_PREFIX)[1], 10);

        if (tabs[activeTabIndex]?.onLeaveTab) {
          tabs[activeTabIndex].onLeaveTab();
        }

        if (tabs[activeTabIndex]?.beforeUpdateLocation) {
          beforeToUpdateLocation = tabs[activeTabIndex].beforeUpdateLocation(beforeToUpdateLocation);
        }
      }

      const finalTabKey = tabs[tabIndex].tabKey || `${TAB_KEY_PREFIX}${tabIndex}`;

      if (inSalesforce) {
        const qsObj = getSearch(reactLocation);
        qsObj[COMMISSION_DRAWER_QS_KEY] = finalTabKey;

        reactHistory.push({
          pathname: reactLocation.pathname,
          search: stringifyQS(qsObj),
        });
      } else {
        dispatch(NavigationActions.addQSToLocation({ forcedLocation: beforeToUpdateLocation, qsObject: { [qsTabKey]: finalTabKey } }));
      }
    }
  }, [tabs, qsTabKey, activeKey, location, inSalesforce, reactHistory, reactLocation]);

  const tabPanesNodes = useMemo(() => (
    tabs.map((
      {
        title,
        titleCount = null,
        content,
        defaultActive = false,
        isActive = null,
        tabKey = null,
        className: tabClassName,
        ...otherTabProps
      },
      index,
    ) => {
      const tabPaneKey = `${TAB_KEY_PREFIX}${index}`;

      if (defaultActive) {
        setDefaultActiveKey(tabPaneKey);
      }

      if (isActive === true) {
        setActiveKey(tabPaneKey);
      } else if (
        isActive === null
        && qsTabKey !== null
      ) {
        const finalTabKey = tabKey || tabPaneKey;
        if (qsActiveKey === finalTabKey) {
          setActiveKey(tabPaneKey);
        }
      }

      let titleNode = title;

      if (alwaysDisplayTitleCount || titleCount !== 0) {
        titleNode = (
          <div className={styles.titleWithCount}>
            {titleNode}
            {titleCount !== null && (
              <div className={styles.count}>
                {`· ${titleCount}`}
              </div>
            )}
          </div>
        );
      }

      return (
        <AntDTabs.TabPane
          className={classNames({
            tab: true,
            [tabClassName]: tabClassName !== '',
          })}
          tab={titleNode}
          key={tabPaneKey}
          {...otherTabProps}
        >
          {content}
        </AntDTabs.TabPane>
      );
    })
  ), [
    tabs,
    qsActiveKey,
    setActiveKey,
    setDefaultActiveKey,
    alwaysDisplayTitleCount,
  ]);

  return (
    <AntDTabs
      className={classNames({
        wrapper: true,
        stretched,
        disableSeeMoreTabsBtn,
        [className]: className !== '',
      })}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      onChange={handleTabChange}
      tabBarExtraContent={tabBarExtraContent}
      {...otherProps}
    >
      {tabPanesNodes}
    </AntDTabs>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    titleCount: PropTypes.number,
    content: PropTypes.any,
    defaultActive: PropTypes.bool,
    isActive: PropTypes.bool,
    tabKey: PropTypes.string,
    onLeaveTab: PropTypes.func,
    beforeUpdateLocation: PropTypes.func,
  })).isRequired,
  stretched: PropTypes.bool,
  disableSeeMoreTabsBtn: PropTypes.bool,
  onChange: PropTypes.func,
  qsTabKey: PropTypes.string,
  inSalesforce: PropTypes.bool,
  tabBarExtraContent: PropTypes.node,
  alwaysDisplayTitleCount: PropTypes.bool,
};

Tabs.defaultProps = {
  className: '',
  stretched: false,
  disableSeeMoreTabsBtn: true,
  onChange: null,
  qsTabKey: null,
  inSalesforce: false,
  tabBarExtraContent: null,
  alwaysDisplayTitleCount: false,
};

export default Tabs;
