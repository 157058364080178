import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';

import {
  manageArrayAttribute,
  manageBooleanAttribute,
} from '@palette/helpers/ModelHelper';

import { DASHBOARD_WIDGETS } from '@palette/constants/dashboard';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as DashboardComponentModel from '@palette/models/DashboardComponent';

export const modelName = 'Profile';

export const propTypes = PropTypes.shape({
  userData: MetaUserModel.propTypes,
  shouldDisplayNPS: PropTypes.bool,
  dashboardComponents: PropTypes.arrayOf(DashboardComponentModel.propTypes),
});

export const getDashboardComponents = (profile) => {
  const dashboardComponents = _cloneDeep(profile.dashboardComponents);
  return dashboardComponents.map((dashboardComponent) => ({
    ...dashboardComponent,
    params: DASHBOARD_WIDGETS[dashboardComponent.type].getParams(dashboardComponent.params),
  }));
};

export const transform = (profile) => {
  if (!profile) {
    return null;
  }

  const dashboardComponentsArray = profile.account ? manageArrayAttribute(profile.account, 'dashboardComponents') : [];

  return (
    {
      userData: MetaUserModel.transform(profile),
      shouldDisplayNPS: manageBooleanAttribute(profile, 'shouldDisplayNPS'),
      dashboardComponents: DashboardComponentModel.transformList(dashboardComponentsArray),
    }
  );
};

export const transformList = (profiles) => profiles.map((profile) => transform(profile));
