import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Progress, { PROGRESS_TYPES } from '@palette/components/designSystem/Progress/Progress';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Tag from '@palette/components/designSystem/Tag/Tag';

import { useOnboardUsersStepValue } from '@palette/hooks/UserHooks';

import {
  getOnboardUsersFromResourcesFlowStepIndex,
  getOnboardUsersManualInputFlowStepIndex,
} from '@palette/helpers/UserHelper';

import {
  ONBOARDING_USERS_FLOWS,
  ONBOARDING_USERS_FROM_RESOURCES_FLOW_ORDERED_STEPS,
  ONBOARDING_USERS_MANUAL_INPUT_FLOW_ORDERED_STEPS,
} from '@palette/constants/user';
import { TAG_THEMES } from '@palette/constants/tag';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './OnboardUsersFlowPageStepsList.less';

const classNames = bindClassNames.bind(styles);

const OnboardUsersFlowPageStepsList = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onboardUsersFlow = useSelector(UsersSelectors.getOnboardUsersFlow);

  const onboardUsersStepValue = useOnboardUsersStepValue();

  const onboardUsersStepIndex = useMemo(() => {
    if (onboardUsersFlow === ONBOARDING_USERS_FLOWS.MANUAL_INPUT) {
      return getOnboardUsersManualInputFlowStepIndex(onboardUsersStepValue);
    }

    return getOnboardUsersFromResourcesFlowStepIndex(onboardUsersStepValue);
  }, [onboardUsersFlow, onboardUsersStepValue]);

  const onboardUsersFlowOrderedSteps = useMemo(() => {
    if (onboardUsersFlow === ONBOARDING_USERS_FLOWS.MANUAL_INPUT) {
      return ONBOARDING_USERS_MANUAL_INPUT_FLOW_ORDERED_STEPS;
    }

    return ONBOARDING_USERS_FROM_RESOURCES_FLOW_ORDERED_STEPS;
  }, [onboardUsersFlow]);

  const onboardingUsersFlowProgress = useMemo(() => (
    Math.floor(((onboardUsersStepIndex + 1) * 100) / onboardUsersFlowOrderedSteps.length)
  ), [onboardUsersStepIndex, onboardUsersFlowOrderedSteps]);

  const stepsNodes = useMemo(() => (
    onboardUsersFlowOrderedSteps.map((step, index) => {
      const isDisabled = index > onboardUsersStepIndex;
      const isDiscard = step.isDiscard();
      const availableSoon = step.availableSoon ?? false;

      const handleStepClicked = () => {
        if (!isDisabled && !isDiscard) {
          dispatch(UsersActions.setOnboardUsersCurrentFlowStep({ onboardUsersCurrentFlowStep: step }));
        }
      };

      let stepIconNode = null;

      if (!isDisabled && !isDiscard) {
        if (index === onboardUsersStepIndex) {
          stepIconNode = (
            <PenFilled className={styles.stepIcon} width={16} height={16} />
          );
        } else {
          stepIconNode = (
            <CheckFilled className={styles.stepIcon} width={16} height={16} />
          );
        }
      }

      let availableSoonNode = null;
      if (availableSoon) {
        availableSoonNode = (
          <Tag
            className={styles.availableSoonTag}
            theme={TAG_THEMES.SUCCESS}
            label={t('onboardUsersFlowSteps.availableSoon')}
          />
        );
      }

      return (
        <div
          key={step.value}
          className={classNames({
            step: true,
            selected: onboardUsersStepValue === step.value,
            disabled: isDisabled,
            discard: isDiscard && !isDisabled && !availableSoon,
            availableSoon,
          })}
          onClick={handleStepClicked}
        >
          <div className={styles.stepIconWrapper}>
            {stepIconNode}
          </div>
          <div className={styles.stepName}>
            {availableSoonNode}
            <span>
              {t(step.label)}
            </span>
          </div>
        </div>
      );
    })
  ), [onboardUsersStepValue, onboardUsersStepIndex, onboardUsersFlowOrderedSteps]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Progress className={styles.progressBar} type={PROGRESS_TYPES.SUCCESS} percent={onboardingUsersFlowProgress} />
      <div className={styles.stepsContainer}>
        {stepsNodes}
      </div>
    </div>
  );
};

OnboardUsersFlowPageStepsList.propTypes = {
  className: PropTypes.string,
};

OnboardUsersFlowPageStepsList.defaultProps = {
  className: '',
};

export default OnboardUsersFlowPageStepsList;
