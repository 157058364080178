import PropTypes from 'prop-types';

import {
  manageDateAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { STATUSES } from '@palette/constants/asyncJobs';

export const modelName = 'AsyncJob';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  companyId: PropTypes.string,
  status: PropTypes.oneOf(Object.values(STATUSES)),
  type: PropTypes.string,
  params: PropTypes.string,
  lastActivityAt: PropTypes.string,
  result: PropTypes.string,
  error: PropTypes.string,
});

export const manageAsyncJobStatus = (asyncJob) => (STATUSES[manageStringAttribute(asyncJob, 'status')] || STATUSES.QUEUED);

export const transform = (asyncJob) => {
  if (!asyncJob) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(asyncJob, '_id'),
      companyId: manageStringAttribute(asyncJob, 'company'),
      status: manageAsyncJobStatus(asyncJob),
      type: manageStringAttribute(asyncJob, 'type'),
      params: manageStringAttribute(asyncJob, 'params'),
      lastActivityAt: manageDateAttribute(asyncJob, 'lastActivityAt', null, false),
      result: manageStringAttribute(asyncJob, 'result'),
      error: manageStringAttribute(asyncJob, 'error'),
    }
  );
};

export const transformList = (asyncJobs) => asyncJobs.map((asyncJob) => transform(asyncJob));
