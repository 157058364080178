import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import NewTeamModal from '@palette/components/team/NewTeamModal/NewTeamModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as TeamModel from '@palette/models/Team';

import styles from './NewTeamButton.less';

const classNames = bindClassNames.bind(styles);

const NewTeamButton = ({ className, parentTeam }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [newTeamModalIsVisible, showNewTeamModal] = useState(false);

  const newTeamBtnNode = useMemo(() => (
    <Button
      type="primaryBlue"
      icon={<AddFilled />}
      onClick={() => showNewTeamModal(true)}
    >
      {parentTeam !== null ? t('newTeamButton.label.newSubteam') : t('newTeamButton.label.newTeam')}
    </Button>
  ), [parentTeam, showNewTeamModal]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {newTeamBtnNode}
      {newTeamModalIsVisible && (
        <NewTeamModal
          visible
          onClose={() => showNewTeamModal(false)}
          parentTeam={parentTeam}
        />
      )}
    </div>
  );
};

NewTeamButton.propTypes = {
  className: PropTypes.string,
  parentTeam: TeamModel.propTypes,
};

NewTeamButton.defaultProps = {
  className: '',
  parentTeam: null,
};

export default NewTeamButton;
