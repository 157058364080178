import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DeleteDashboardPresetModal from '@palette/components/dashboard/DeleteDashboardPresetModal/DeleteDashboardPresetModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import styles from './DashboardPresetsListItemMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetsListItemMoreOptionsMenu = ({ className, onOpenChange, dashboardPreset }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [deleteDashboardPresetModalIsVisible, showDeleteDashboardPresetModal] = useState(false);

  const closeMoreMenu = useCallback(() => {
    if (onOpenChange !== null) {
      onOpenChange(false);
    }
  }, [onOpenChange]);

  const moreMenuItems = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return [];

    const menuItems = [];

    const deleteDashboardPresetMenuItem = {
      key: 'deleteDashboardPreset',
      title: t('dashboardPresetsListItemMoreOptionsMenu.deleteDashboardPreset'),
      icon: (
        <TrashFilled width={16} height={16} />
      ),
      onClick: () => {
        showDeleteDashboardPresetModal(true);
        closeMoreMenu();
      },
    };

    menuItems.push(deleteDashboardPresetMenuItem);

    return menuItems;
  }, [
    profile,
    showDeleteDashboardPresetModal,
    closeMoreMenu,
  ]);

  const moreMenuProps = useMemo(() => {
    if (onOpenChange === null) return {};

    return {
      onVisibleChange: onOpenChange,
    };
  }, [onOpenChange]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={moreMenuItems}
        {...moreMenuProps}
      />
      {
        deleteDashboardPresetModalIsVisible && (
          <DeleteDashboardPresetModal visible onClose={() => showDeleteDashboardPresetModal(false)} dashboardPreset={dashboardPreset} />
        )
      }
    </div>
  );
};

DashboardPresetsListItemMoreOptionsMenu.propTypes = {
  className: PropTypes.string,
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
  onOpenChange: PropTypes.func,
};

DashboardPresetsListItemMoreOptionsMenu.defaultProps = {
  className: '',
  onOpenChange: null,
};

export default DashboardPresetsListItemMoreOptionsMenu;
