import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import UserResourcesSelectionPanel from '@palette/components/resources/UserResourcesSelectionPanel/UserResourcesSelectionPanel';
import OnboardUsersFlowStepActions from '@palette/components/userOnboarding/OnboardUsersFlowStepActions/OnboardUsersFlowStepActions';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './OnboardUsersSelectResourcesFlowStep.less';

const classNames = bindClassNames.bind(styles);

const OnboardUsersSelectResourcesFlowStep = ({
  className,
  disableActions,
  restrictToConnectorIdAndType,
  onResourcesSelected,
  disableReloadUsers,
  registerNextStepHandler,
}) => {
  const dispatch = useDispatch();

  const onboardCurrentUsers = useSelector(UsersSelectors.getOnboardCurrentUsers);

  const [selectedResources, setSelectedResources] = useState([]);

  const preSelectedResources = useMemo(() => (
    onboardCurrentUsers.map((onboardCurrentUser) => ResourceObjectModel.transform(onboardCurrentUser.cObject))
  ), [onboardCurrentUsers]);

  useEffect(() => {
    setSelectedResources(preSelectedResources);
  }, [preSelectedResources]);

  const handleSelectedResourcesChange = useCallback((newSelectedResources) => {
    setSelectedResources(newSelectedResources);

    if (onResourcesSelected !== null) onResourcesSelected(newSelectedResources);
  }, [onResourcesSelected]);

  const handleNextStepClick = useCallback((nextStepCB = null) => {
    dispatch(UsersActions.getUserResourcesCommonData({ resourceObjects: selectedResources, onSuccessCB: nextStepCB }));
  }, [selectedResources]);

  useEffect(() => {
    if (registerNextStepHandler !== null) {
      registerNextStepHandler(handleNextStepClick);
    }
  }, [handleNextStepClick]);

  const resourcesSelectionPanelNode = useMemo(() => (
    <UserResourcesSelectionPanel
      className={styles.userResourcesSelectionPanel}
      onChange={handleSelectedResourcesChange}
      restrictToConnectorIdAndType={restrictToConnectorIdAndType}
      disableReloadUsers={disableReloadUsers}
      preSelectedResources={preSelectedResources}
      lockPreselectedResources={false}
    />
  ), [
    restrictToConnectorIdAndType,
    disableReloadUsers,
    handleSelectedResourcesChange,
    preSelectedResources,
  ]);

  const actionsNode = useMemo(() => {
    if (disableActions) return null;

    return (
      <OnboardUsersFlowStepActions
        onNextStep={handleNextStepClick}
        disabledNext={selectedResources.length === 0}
      />
    );
  }, [disableActions, handleNextStepClick, selectedResources]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {resourcesSelectionPanelNode}
      {actionsNode}
    </div>
  );
};

OnboardUsersSelectResourcesFlowStep.propTypes = {
  className: PropTypes.string,
  disableActions: PropTypes.bool,
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  onResourcesSelected: PropTypes.func,
  disableReloadUsers: PropTypes.bool,
  registerNextStepHandler: PropTypes.func,
};

OnboardUsersSelectResourcesFlowStep.defaultProps = {
  className: '',
  disableActions: false,
  restrictToConnectorIdAndType: null,
  onResourcesSelected: null,
  disableReloadUsers: false,
  registerNextStepHandler: null,
};

export default OnboardUsersSelectResourcesFlowStep;
