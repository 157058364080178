import PropTypes from 'prop-types';

import {
  manageFreeShapeObjectAttribute,
} from '@palette/helpers/ModelHelper';

import * as DashboardComponentModel from '@palette/models/DashboardComponent';

export const modelName = 'DashboardComponentData';

export const propTypes = PropTypes.shape({
  dashboardComponent: DashboardComponentModel.propTypes,
  data: PropTypes.object,
});

export const transform = (dashboardComponentData) => {
  if (!dashboardComponentData) {
    return null;
  }

  return (
    {
      dashboardComponent: DashboardComponentModel.transform(dashboardComponentData),
      data: manageFreeShapeObjectAttribute(dashboardComponentData, 'data'),
    }
  );
};

export const transformList = (dashboardComponentDatas) => dashboardComponentDatas.map((dashboardComponentData) => transform(dashboardComponentData));
