import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';

import Table from '@palette/components/designSystem/Table/Table';
import MasterPlanPeriodProgress from '@palette/components/masterPlan/MasterPlanPeriodProgress/MasterPlanPeriodProgress';
import Input from '@palette/components/designSystem/Input/Input';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Loader from '@palette/components/utils/Loader/Loader';
import MasterPlanPeriodDealCommissionsTable from '@palette/components/masterPlanPeriod/MasterPlanPeriodDealCommissionsTable/MasterPlanPeriodDealCommissionsTable';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import MasterPlanPeriodDealExpandCell from '@palette/components/masterPlanPeriod/MasterPlanPeriodDealExpandCell/MasterPlanPeriodDealExpandCell';
import PeriodDealCommissionInfos from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfos/PeriodDealCommissionInfos';
import Button from '@palette/components/designSystem/Button/Button';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import Select from '@palette/components/designSystem/Select/Select';
import Disclaimer from '@palette/components/designSystem/Disclaimer/Disclaimer';
import PeriodDealPayoutInfos from '@palette/components/masterPlanPeriod/PeriodDealPayoutInfos/PeriodDealPayoutInfos';
import CommissionsWithoutPaymentLink from '@palette/components/masterPlanPeriod/CommissionsWithoutPaymentLink/CommissionsWithoutPaymentLink';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { floatToFixedNumber, formatNumberShortDisplay } from '@palette/helpers/CommonHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { RIGHTS } from '@palette/constants/profile';
import { PLAN_SETTINGS_TABS_IDS, PLAN_SETTINGS_TABS_QS_KEY } from '@palette/constants/tabs';
import { SCOPES, USERS_TAB_SORT_OPTIONS } from '@palette/constants/masterPlans';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanUGByUPeriodUsers.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanUGByUPeriodUsers = ({ className, plan, period, forManagers }) => {
  const profile = useProfile();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderByOptions = USERS_TAB_SORT_OPTIONS;

  const isNotManagerTab = useMemo(() => plan.scope === SCOPES.MANAGER && !forManagers, [plan, forManagers]);

  const orderByDefaultValue = useMemo(() => {
    if (!plan.quotaId && !isNotManagerTab) {
      return USERS_TAB_SORT_OPTIONS.COMMISSION;
    }

    return USERS_TAB_SORT_OPTIONS.PROGRESS;
  }, [plan, isNotManagerTab]);

  const sortByOptions = useMemo(() => {
    const finalOrderByOptions = { ...orderByOptions };

    if (plan.scope === SCOPES.MANAGER && !forManagers) {
      delete finalOrderByOptions.COMMISSION;
      delete finalOrderByOptions.TARGET;
    }

    delete finalOrderByOptions.VALUE;

    return Object.keys(finalOrderByOptions).map((type) => ({
      label: t(`masterPlanPeriodUsers.sortBy.${type.toLowerCase()}`, { type: plan?.trackingObject?.type }),
      value: type,
    }));
  }, [plan, forManagers]);

  const planValueDefinition = getPlanValueDefinition(plan);

  const [sortedBy, setSortedBy] = useState(orderByDefaultValue);

  const [searchedUser, setSearchedUser] = useState('');

  const getPeriodUsersIsPending = useSelector(MasterPlansSelectors.getPeriodUsersIsPending);

  const planPeriodUsers = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodUsers(state, { masterPlanId: plan.id, year: period.year, periodId: period.period }));
  const planPeriodManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodManagers(state, { masterPlanId: plan.id, year: period.year, periodId: period.period }));

  const periodUsers = useMemo(() => {
    if (forManagers) {
      return planPeriodManagers;
    }

    return planPeriodUsers;
  }, [forManagers, planPeriodUsers, planPeriodManagers]);

  const filteredUsers = useMemo(() => {
    let finalUsers = periodUsers;

    if (searchedUser !== '') {
      finalUsers = periodUsers.filter((periodUser) => periodUser.user.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1);
    }

    let orderByKey = '';
    let orderBySortOrder = 'desc';

    switch (sortedBy) {
      case USERS_TAB_SORT_OPTIONS.PROGRESS:
        orderByKey = 'progress';
        break;
      case USERS_TAB_SORT_OPTIONS.COMMISSION:
        orderByKey = 'totalCommissionAmount';
        break;
      case USERS_TAB_SORT_OPTIONS.TARGET:
        orderByKey = 'target';
        break;
      case USERS_TAB_SORT_OPTIONS.VALUE:
        orderByKey = 'totalValue';
        break;
      case USERS_TAB_SORT_OPTIONS.ALPHABETICAL:
      default:
        orderByKey = (periodUser) => periodUser.user[periodUser.user.type].displayName || '';
        orderBySortOrder = 'asc';
    }

    return _orderBy(finalUsers, [orderByKey], [orderBySortOrder]);
  }, [periodUsers, searchedUser, sortedBy]);

  useEffect(() => {
    dispatch(MasterPlansActions.getPeriodUsers({ planId: plan.id, year: period.year, period: period.period, forManagers }));
  }, [plan, period, forManagers]);

  const handleClickAddUsersButton = () => {
    redirectTo({
      path: routePaths.v2.planSettings,
      params: { masterPlanId: plan.id },
      qsObject: { [PLAN_SETTINGS_TABS_QS_KEY]: PLAN_SETTINGS_TABS_IDS.USERS },
    });
  };

  const handleSortByChange = useCallback((sortBy) => setSortedBy(sortBy));

  const handleExportDeals = useCallback(() => {
    dispatch(MasterPlansActions.downloadPeriodDeals({
      planId: plan.id,
      year: period.year,
      period: period.period,
    }));
  }, [
    plan,
    period,
  ]);

  const columns = useMemo(() => {
    const finalColumns = [
      {
        id: 'periodUser',
        Header: t('masterPlanPeriodUsers.table.headers.users'),
        accessor: (periodUser) => (periodUser),
        minWidth: 200,
        width: '20%',
        /* eslint-disable react/prop-types */
        Cell: ({ value, row }) => {
          const isExpanded = row.isExpanded === true;

          return (
            <MasterPlanPeriodDealExpandCell periodDealOrUserWithCommissions={value} isExpanded={isExpanded} toggleRowExpanded={row.toggleRowExpanded}>
              <UserProfile user={value.user} clickable />
            </MasterPlanPeriodDealExpandCell>
          );
        },
        /* eslint-enable react/prop-types */
        disableBodyCellComponent: true,
      },
    ];

    if (plan.quotaId !== null) {
      finalColumns.push({
        id: 'target',
        Header: `${t('masterPlanPeriodUsers.table.headers.target')} (${planValueDefinition})`,
        accessor: (periodUser) => periodUser,
        minWidth: 190,
        width: '19%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => (
          <Tooltip title={`${value.dealsCount > 0 ? floatToFixedNumber(value.totalValue, 4) : '-'} / ${floatToFixedNumber(value.target, 4)}`}>
            <span>
              {`${value.dealsCount > 0 ? formatNumberShortDisplay(value.totalValue) : '-'} / `}
              <strong>
                {formatNumberShortDisplay(value.target)}
              </strong>
            </span>
          </Tooltip>
        ),
        /* eslint-enable react/prop-types */
      });

      finalColumns.push({
        id: 'progress',
        Header: t('masterPlanPeriodUsers.table.headers.progress'),
        accessor: 'progress',
        minWidth: 270,
        width: '27%',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<MasterPlanPeriodProgress period={{ ...period, progress: value }} plan={plan} />),
      });
    } else {
      finalColumns.push({
        id: 'totalValue',
        Header: `${t('masterPlanPeriodUsers.table.headers.totalValue')}`,
        accessor: (periodUser) => (
          periodUser.dealsCount > 0
            ? (
              <Tooltip title={floatToFixedNumber(periodUser.totalValue, 4)}>
                {formatNumberShortDisplay(periodUser.totalValue)}
              </Tooltip>
            )
            : '-'
        ),
        minWidth: 190,
        width: '19%',
      });
    }

    finalColumns.push({
      id: 'commissions',
      Header: (
        <div className={styles.headerCommissions}>
          {t('masterPlanPeriodUsers.table.headers.commissions')}
        </div>
      ),
      accessor: (periodUser) => (periodUser.dealsCount > 0 ? formatPrice(periodUser.totalCommissionAmount, periodUser.currency) : '-'),
      minWidth: 130,
      maxWidth: 150,
      width: '15%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<div className={styles.cellCommissions}>{value}</div>),
      highlight: true,
    });

    if (plan.rules.length < 2) {
      const payoutInfosColumn = {
        id: 'payoutInfos',
        Header: (
          <div className={styles.headerCentered}>
            {t('masterPlanPeriodDeals.table.headers.payout')}
          </div>
        ),
        accessor: (periodUser) => periodUser,
        minWidth: 180,
        width: '18%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          if (value.commissions.length !== 1) return null;

          return <PeriodDealPayoutInfos commission={value.commissions[0]} />;
        },
        /* eslint-enable react/prop-types */
      };

      finalColumns.push(payoutInfosColumn);

      const commissionInfosColumn = {
        id: 'commissionInfos',
        accessor: (periodUser) => periodUser,
        minWidth: 170,
        width: '17%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          if (value.commissions.length !== 1) return null;

          return <PeriodDealCommissionInfos commission={value.commissions[0]} displayPayments={false} />;
        },
        /* eslint-enable react/prop-types */
      };

      finalColumns.push(commissionInfosColumn);
    }

    return finalColumns;
  }, [forManagers, plan]);

  const tableNode = useMemo(() => {
    if (getPeriodUsersIsPending) {
      return (
        <Loader />
      );
    }

    if (filteredUsers.length === 0) {
      if (forManagers) {
        return (
          <DefaultEmptyState description={t('masterPlanPeriodUsers.managers.empty.description')} />
        );
      }

      return (
        <DefaultEmptyState description={t('masterPlanPeriodUsers.users.empty.description')} />
      );
    }

    /* eslint-disable react/prop-types */
    return (
      <Table
        className={styles.usersTable}
        type="borderless"
        columns={columns}
        data={filteredUsers}
        nbOfFixedColumns={plan.rules.length < 2 ? 3 : 1}
        fixedColumnsPosition="fromRight"
        enableExpandRow
        renderExpandedRow={(row) => (<MasterPlanPeriodDealCommissionsTable periodDealOrUserWithCommissions={filteredUsers[row.index]} plan={plan} />)}
        stretch
      />
    );
    /* eslint-enable react/prop-types */
  }, [getPeriodUsersIsPending, columns, filteredUsers, plan]);

  const addUsersBtnNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.GBO.MANAGE])) return null;

    return (
      <Button
        className={styles.actionLink}
        type="linkSecondary"
        icon={<AddFilled />}
        onClick={handleClickAddUsersButton}
      >
        {t('masterPlanPeriodUsers.addUsers.label')}
      </Button>
    );
  }, [profile, handleClickAddUsersButton]);

  const hiddenUsersBannerNode = useMemo(() => {
    let diff = period.usersCount - periodUsers.length;
    let translateKey = 'users';

    if (forManagers === true) {
      diff = period.managersCount - periodUsers.length;
      translateKey = 'managers';
    }

    if (diff === 0 || getPeriodUsersIsPending) return null;

    return (
      <Disclaimer
        className={styles.disclaimer}
        type="warning"
        icon="info"
        description={t(`masterPlanPeriodUsers.${translateKey}.disclaimerCount`, { count: diff })}
      />
    );
  }, [getPeriodUsersIsPending, period, periodUsers, forManagers]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <Input
          className={styles.userSearch}
          type="search"
          placeholder={t('masterPlanPeriodUsers.userSearch.placeholder')}
          onChange={setSearchedUser}
          value={searchedUser}
        />
        <div className={styles.actions}>
          <CommissionsWithoutPaymentLink className={styles.actionLink} plan={plan} period={period} />
          {addUsersBtnNode}
          <Button
            className={styles.actionLink}
            type="linkSecondary"
            icon={<DownloadFilled />}
            onClick={handleExportDeals}
          >
            {t('masterPlanPeriodDeals.actions.exportDeals', { dealType: '' })}
          </Button>
          <Select
            className={styles.sortSelect}
            value={sortedBy}
            placeholder={t('masterPlanPeriodUsers.sortBy.placeholder')}
            onChange={handleSortByChange}
            options={sortByOptions}
          />
        </div>
      </div>
      {hiddenUsersBannerNode}
      {tableNode}
    </div>
  );
};

MasterPlanUGByUPeriodUsers.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  forManagers: PropTypes.bool,
};

MasterPlanUGByUPeriodUsers.defaultProps = {
  className: '',
  forManagers: false,
};

export default MasterPlanUGByUPeriodUsers;
