import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _size from 'lodash/size';

import * as PlanModel from '@palette/models/MasterPlan';

import MasterPlanSettingsCommissionRulesDependencyItem from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesDependencyItem/MasterPlanSettingsCommissionRulesDependencyItem';

import styles from './MasterPlanSettingsCommissionRulesDependencies.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCommissionRulesDependencies = ({ className, plan }) => {
  const { t } = useTranslation();

  if (!_size(plan.injections) > 0) {
    return null;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <span className={styles.title}>
        {t('masterPlanSettingsCommissionRules.dependenciesTitle')}
      </span>
      {plan.injections.map((injection, i) => (
        <MasterPlanSettingsCommissionRulesDependencyItem
          key={`injection_${i}`}
          className={styles.content}
          dependency={injection}
          plan={plan}
        />
      ))}
    </div>
  );
};

MasterPlanSettingsCommissionRulesDependencies.propTypes = {
  className: PropTypes.string,
  plan: PlanModel.propTypes,
};

MasterPlanSettingsCommissionRulesDependencies.defaultProps = {
  className: '',
  plan: {},
};

export default MasterPlanSettingsCommissionRulesDependencies;
