import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import Disclaimer from '@palette/components/designSystem/Disclaimer/Disclaimer';

import routePaths from '@palette/config/routePaths';

import styles from './MasterPlanHiddenInfo.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanHiddenInfo = ({ className, isLive, planId }) => {
  const { t } = useTranslation();

  const descriptionNode = useMemo(() => (
    <div className={styles.description}>
      {`${t('masterPlanHiddenInfo.description')} `}
      <Link
        className={styles.settingsLink}
        path={routePaths.v2.planSettings}
        params={{ masterPlanId: planId }}
      >
        {t('masterPlanHiddenInfo.description.settings.label')}
      </Link>
    </div>
  ), [isLive, planId]);

  if (isLive) return null;

  return (
    <Disclaimer
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      type="skyblue"
      icon="info"
      description={descriptionNode}
    />
  );
};

MasterPlanHiddenInfo.propTypes = {
  className: PropTypes.string,
  isLive: PropTypes.bool,
  planId: PropTypes.string,
};

MasterPlanHiddenInfo.defaultProps = {
  className: '',
  isLive: false,
  planId: '',
};

export default MasterPlanHiddenInfo;
