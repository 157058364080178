import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';

import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { isResourceReport } from '@palette/helpers/CommonHelper';

import { CONNECTOR_TYPES } from '@palette/constants/connector';
import { RESOURCES_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './ResourcesTabsContent.less';

const classNames = bindClassNames.bind(styles);

const ResourcesTabsContent = ({ className, currentConnectorId, currentOriginalType, currentTab, handleClick }) => {
  const { t } = useTranslation();

  const connectorsList = useConnectorsList();
  const nbOfConnectors = useSelector(ConnectorsSelectors.getNbOfConnectors);
  const nbOfNotebooks = useSelector(ConnectorsSelectors.getNbOfNotebooks);

  const contentNode = useMemo(() => {
    const isConnector = currentTab === RESOURCES_PAGE_CONTENT_TABS_IDS.CONNECTORS;
    const totalCount = isConnector ? nbOfConnectors : nbOfNotebooks;

    if (totalCount === 0) {
      return <DefaultEmptyState />;
    }

    const connectorResources = ({ id, resources }) => {
      if (!resources.length) {
        return <DefaultEmptyState className={styles.emptyResource} />;
      }

      return resources.map((resource, index) => {
        const selected = (currentConnectorId === id) && (currentOriginalType === resource.originalType);

        let resourceName = resource.type;

        if (isResourceReport(resource.originalType) && resource.name) {
          resourceName += ` ${t('resources.index.header.tabs.from.label', { name: resource.name })}`;
        }

        return (
          <div
            key={`resource-${id}-${index}`}
            className={classNames({
              resourceItem: true,
              selected,
            })}
            onClick={() => !selected && handleClick(id, resource.originalType)}
          >
            {resourceName}
          </div>
        );
      });
    };

    return connectorsList
      .filter((connector) => {
        if (isConnector) {
          return connector.type !== CONNECTOR_TYPES.NOTEBOOK;
        }
        return connector.type === CONNECTOR_TYPES.NOTEBOOK;
      })
      .map((connector) => (
        <Collapse
          key={`connector-${connector.id}`}
          className={classNames({
            collapseContainer: true,
            selected: currentConnectorId === connector.id,
          })}
          panels={[
            {
              title: (
                <div className={styles.title}>
                  {connector.name}
                </div>
              ),
              content: connectorResources(connector),
            },
          ]}
          keyValue={connector.id}
          defaultActiveKey={currentConnectorId ?? undefined}
          ghost
        />
      ));
  }, [
    connectorsList,
    nbOfConnectors,
    nbOfNotebooks,
    currentConnectorId,
    currentOriginalType,
    currentTab,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

ResourcesTabsContent.propTypes = {
  className: PropTypes.string,
  currentConnectorId: PropTypes.string,
  currentOriginalType: PropTypes.string,
  currentTab: PropTypes.oneOf(Object.values(RESOURCES_PAGE_CONTENT_TABS_IDS)),
  handleClick: PropTypes.func,
};

ResourcesTabsContent.defaultProps = {
  className: '',
  currentConnectorId: null,
  currentOriginalType: null,
  currentTab: RESOURCES_PAGE_CONTENT_TABS_IDS.CONNECTORS,
  handleClick: () => {},
};

export default ResourcesTabsContent;
