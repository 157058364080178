import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Input from '@palette/components/designSystem/Input/Input';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';

const DayIntervalFormItem = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      name="dayInterval"
      label={t('createPlanPage.form.dayInterval.label')}
      required
      rules={[
        { required: true, message: t('createPlanPage.form.dayInterval.rules.required') },
      ]}
    >
      <Input size="big" type="number" min={1} max={365} disabled={disabled} />
    </FormItem>
  );
};

DayIntervalFormItem.propTypes = {
  disabled: PropTypes.bool,
};

DayIntervalFormItem.defaultProps = {
  disabled: false,
};

export default DayIntervalFormItem;
