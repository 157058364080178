import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import NotebookDataframePreview from '@palette/components/notebooks/NotebookDataframePreview/NotebookDataframePreview';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookCellModel from '@palette/models/NotebookCell';

import { selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './NotebookRunnableCellPreview.less';

const classNames = bindClassNames.bind(styles);

const NotebookRunnableCellPreview = ({
  className,
  notebook,
  cell,
}) => {
  const runCellIsPending = useSelector((state) => NotebooksSelectors.getRunCellIsPending(state, {
    notebookId: notebook.id,
    cellId: cell.id,
  }));

  const cellPreviewDataframe = useMemo(() => (cell.previewDataframe), [cell]);

  if (runCellIsPending === null && (cellPreviewDataframe?.data ?? []).length === 0) return null;

  return (
    <NotebookDataframePreview
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      isLoading={runCellIsPending ?? false}
      dataframe={cellPreviewDataframe}
    />
  );
};

NotebookRunnableCellPreview.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  cell: NotebookCellModel.propTypes.isRequired,
};

NotebookRunnableCellPreview.defaultProps = {
  className: '',
};

export default NotebookRunnableCellPreview;
