import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import {
  getQuickBooksConnectURL, getQuickBooksSandboxConnectURL,
} from '../services/connector';
import FullScreenLoader from './loader';

class QuickBooksOriginalButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
      loading: false,
    };
  }

  setIsHover = (hovering) => this.setState({ hovering });

  connectQuickBooks = () => {
    this.setState({ loading: true });
    const fn = this.props.sandbox ? getQuickBooksSandboxConnectURL : getQuickBooksConnectURL;
    fn()
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((e) => {
        message.error('Error while contacting QuickBooks.');
        this.setState({ loading: false });
        console.error(e);
      });
  };

  render() {
    const { hovering, loading } = this.state;
    return (
      <>
        <img
          onMouseEnter={() => this.setIsHover(true)}
          onMouseLeave={() => this.setIsHover(false)}
          onClick={this.connectQuickBooks}
          style={{
            width: '274px',
            height: '48px',
            cursor: 'pointer',
          }}
          src={hovering
            ? '/img/qbo/C2QB_green_btn_tall_hover_2x.png'
            : '/img/qbo/C2QB_green_btn_tall_default_2x.png'}
          alt="QuickBooks button"
        />
        {loading && <FullScreenLoader />}
      </>
    );
  }
}
QuickBooksOriginalButton.propTypes = {
  sandbox: PropTypes.bool,
};
QuickBooksOriginalButton.defaultProps = {
  sandbox: false,
};
export default QuickBooksOriginalButton;
