import { useCallback, useEffect, useMemo, useState } from 'react';

import { getMoment } from '@palette/helpers/MomentHelper';

import {
  buildFrequencyOptionValue,
  extractPeriodTypeFrequencyFromOptionValue,
} from '@palette/helpers/FrequencyHelper';
import { getDefaultPeriodValue } from '@palette/helpers/MasterPlanHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

export const useSettingsGeneralFrequencyBeginEndFormItems = (
  form,
  initialPeriodType,
  initialFrequency,
  initialBeginPeriod,
  initialEndPeriod,
  initialFiscalYearShift,
  initialCustomBeginAt,
  initialCustomBeginAtString,
  initialCustomEndAt,
  disableEndPeriod = false,
) => {
  const moment = getMoment();

  const initialFrequencyType = useMemo(() => {
    if (initialPeriodType === PERIOD_TYPES.DAY) {
      return PERIOD_TYPES.DAY;
    }

    if (initialPeriodType === PERIOD_TYPES.CUSTOM) {
      return PERIOD_TYPES.CUSTOM;
    }

    return buildFrequencyOptionValue(initialPeriodType, initialFrequency);
  }, [initialPeriodType, initialFrequency]);

  const [periodTypeValue, setPeriodTypeValue] = useState(initialPeriodType);
  const [frequencyValue, setFrequencyValue] = useState(initialFrequency);
  const [beginPeriodValue, setBeginPeriodValue] = useState(initialBeginPeriod);
  const [endPeriodValue, setEndPeriodValue] = useState(initialEndPeriod);
  const [customBeginAtValue, setCustomBeginAtValue] = useState(initialCustomBeginAtString);
  const [fiscalYearShiftValue, setFiscalYearShiftValue] = useState(initialFiscalYearShift);

  const [displayEndPeriod, showEndPeriod] = useState(initialEndPeriod !== null);

  const [initialValues, initialValuesForState] = useMemo(() => {
    const initValues = {
      periodType: initialPeriodType,
      frequency: initialFrequency,
      frequencyType: initialFrequencyType,
      beginPeriod: initialBeginPeriod,
      endPeriod: initialEndPeriod,
      customBeginAt: initialCustomBeginAt,
      customEndAt: initialCustomEndAt,
      fiscalYearShift: initialFiscalYearShift,
    };

    const initValuesForState = {
      ...initValues,
      customBeginAt: initialCustomBeginAtString,
    };

    return [initValues, initValuesForState];
  }, [
    initialPeriodType,
    initialFrequency,
    initialFrequencyType,
    initialBeginPeriod,
    initialEndPeriod,
    initialCustomBeginAt,
    initialCustomEndAt,
    initialCustomBeginAtString,
    initialFiscalYearShift,
  ]);

  useEffect(() => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      ...initialValues,
    });

    setPeriodTypeValue(initialValuesForState.periodType);
    setFrequencyValue(initialValuesForState.frequency);
    setBeginPeriodValue(initialValuesForState.beginPeriod);
    setEndPeriodValue(initialValuesForState.endPeriod);
    setCustomBeginAtValue(initialValuesForState.customBeginAt);
    showEndPeriod(initialValuesForState.endPeriod !== null);
    setFiscalYearShiftValue(initialValuesForState.fiscalYearShift);
  }, [initialValues, initialValuesForState]);

  const handleSetEndPeriod = useCallback(() => {
    showEndPeriod(true);
  }, [showEndPeriod]);

  const handleRemoveEndPeriod = useCallback(() => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      endPeriod: null,
    });

    setEndPeriodValue(null);

    showEndPeriod(false);
  }, [form]);

  useEffect(() => {
    let newBeginPeriod = null;
    let newEndPeriod = null;
    let newCustomBeginAt = moment();
    let newCustomBeginAtValue = null;
    let newCustomEndAt = moment();
    let newFiscalYearShift = fiscalYearShiftValue;

    if (periodTypeValue === PERIOD_TYPES.MONTH) {
      newBeginPeriod = getDefaultPeriodValue(PERIOD_TYPES.MONTH, frequencyValue);

      if (endPeriodValue !== null && !disableEndPeriod) {
        const isYearly = frequencyValue === 12;

        if (isYearly) {
          newEndPeriod = {
            year: moment().year() + 1,
            period: 1,
          };
        } else {
          newEndPeriod = {
            year: moment().year(),
            period: 2,
          };
        }
      }

      if (fiscalYearShiftValue > (frequencyValue - 1)) {
        newFiscalYearShift = frequencyValue - 1;
      }
    }

    if (periodTypeValue === PERIOD_TYPES.DAY) {
      if (customBeginAtValue !== null) {
        newCustomBeginAtValue = customBeginAtValue;
        newCustomBeginAt = moment(customBeginAtValue).utc().startOf('day');
      } else {
        newCustomBeginAt = initialCustomBeginAt;
        newCustomBeginAtValue = moment(newCustomBeginAt).utc().startOf('day').format();
      }

      newFiscalYearShift = 0;
    }

    if (periodTypeValue === PERIOD_TYPES.CUSTOM) {
      if (initialPeriodType === PERIOD_TYPES.CUSTOM || customBeginAtValue !== null) {
        newCustomBeginAtValue = customBeginAtValue;
        newCustomBeginAt = moment(customBeginAtValue).utc().startOf('day');
      } else {
        newCustomBeginAt = moment().utc().startOf('day');
        newCustomBeginAtValue = moment(newCustomBeginAt).format();
      }
      newCustomEndAt = moment(newCustomBeginAt).utc().add(frequencyValue - 1, 'days').endOf('day');

      newFiscalYearShift = 0;
    }

    if (initialPeriodType === periodTypeValue && initialFrequency === frequencyValue) {
      newBeginPeriod = initialBeginPeriod;
      newEndPeriod = initialEndPeriod;
      newCustomBeginAt = initialCustomBeginAt;
      newCustomBeginAtValue = initialCustomBeginAtString;
      newCustomEndAt = initialCustomEndAt;
      newFiscalYearShift = initialFiscalYearShift;
    }

    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      beginPeriod: newBeginPeriod,
      endPeriod: newEndPeriod,
      customBeginAt: newCustomBeginAt,
      customEndAt: newCustomEndAt,
      fiscalYearShift: newFiscalYearShift,
    });

    setBeginPeriodValue(newBeginPeriod);
    setEndPeriodValue(newEndPeriod);
    setCustomBeginAtValue(newCustomBeginAtValue);
    setFiscalYearShiftValue(newFiscalYearShift);
  }, [periodTypeValue, frequencyValue, form, disableEndPeriod]);

  const handleFrequencyTypeChange = (optionValue) => {
    let periodType;
    let frequency;
    let fiscalYearShift = fiscalYearShiftValue;

    if (optionValue === PERIOD_TYPES.DAY) {
      periodType = PERIOD_TYPES.DAY;
      frequency = 7;

      if (initialPeriodType === PERIOD_TYPES.DAY) {
        frequency = initialFrequency;
      }

      fiscalYearShift = 0;
    } else if (optionValue === PERIOD_TYPES.CUSTOM) {
      periodType = PERIOD_TYPES.CUSTOM;
      frequency = 1;

      if (initialPeriodType === PERIOD_TYPES.CUSTOM) {
        frequency = initialFrequency;
      }

      fiscalYearShift = 0;
    } else {
      [periodType, frequency] = extractPeriodTypeFrequencyFromOptionValue(optionValue);

      if (fiscalYearShift > (frequency - 1)) {
        fiscalYearShift = frequency - 1;
      }
    }

    const newFrequency = parseInt(frequency, 10);

    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      periodType,
      frequency: newFrequency,
      fiscalYearShift,
    });

    setPeriodTypeValue(periodType);
    setFrequencyValue(newFrequency);
    setFiscalYearShiftValue(fiscalYearShift);
  };

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    if (changedValues.frequencyType !== undefined) {
      handleFrequencyTypeChange(changedValues.frequencyType);
    }

    if (changedValues.beginPeriod !== undefined) {
      setBeginPeriodValue(changedValues.beginPeriod);
    }

    if (changedValues.endPeriod !== undefined) {
      setEndPeriodValue(changedValues.endPeriod);
    }

    if (changedValues.customBeginAt !== undefined) {
      setCustomBeginAtValue(moment(changedValues.customBeginAt).utc().startOf('day').format());
    }

    if (changedValues.frequency !== undefined) {
      setFrequencyValue(changedValues.frequency);
    }

    if (changedValues.fiscalYearShift !== undefined) {
      setFiscalYearShiftValue(changedValues.fiscalYearShift);
    }

    let nbOfDays = null;
    if (
      (changedValues.customBeginAt !== undefined && allValues.customEndAt != null)
      || (changedValues.customEndAt !== undefined && allValues.customBeginAt != null)
    ) {
      nbOfDays = Math.round(moment(allValues.customEndAt).diff(moment(allValues.customBeginAt), 'days', true));
    } else if (changedValues.customBeginAt !== undefined || changedValues.customEndAt !== undefined) {
      nbOfDays = 1;
      if (allValues.frequencyType === PERIOD_TYPES.DAY) {
        nbOfDays = frequencyValue;
      }
    }

    if (nbOfDays !== null) {
      form.setFieldsValue({
        ...allValues,
        frequency: nbOfDays,
      });

      setFrequencyValue(nbOfDays);
    }
  }, [
    form,
    frequencyValue,
    handleFrequencyTypeChange,
    setBeginPeriodValue,
    setEndPeriodValue,
    setCustomBeginAtValue,
    setFrequencyValue,
  ]);

  const buildFinalValues = useCallback((values, otherInitialValues = {}) => {
    const stateValues = {
      periodType: periodTypeValue,
      frequency: frequencyValue,
      beginPeriod: beginPeriodValue,
      endPeriod: endPeriodValue,
      fiscalYearShift: fiscalYearShiftValue,
    };

    const finalValues = { ...initialValues, ...values, ...stateValues };
    delete finalValues.frequencyType;
    delete finalValues.customEndAt;
    finalValues.customBeginAt = customBeginAtValue;

    const finalInitialValues = { ...initialValues, ...initialValuesForState, ...otherInitialValues };
    delete finalInitialValues.frequencyType;
    delete finalInitialValues.customEndAt;

    if (disableEndPeriod) {
      delete finalValues.endPeriod;
      delete finalInitialValues.endPeriod;
    }

    return [finalValues, finalInitialValues];
  }, [
    periodTypeValue,
    frequencyValue,
    beginPeriodValue,
    endPeriodValue,
    fiscalYearShiftValue,
    initialValues,
    customBeginAtValue,
    initialValuesForState,
    disableEndPeriod,
  ]);

  return [
    periodTypeValue,
    frequencyValue,
    beginPeriodValue,
    endPeriodValue,
    fiscalYearShiftValue,
    customBeginAtValue,
    initialValues,
    displayEndPeriod,
    handleSetEndPeriod,
    handleRemoveEndPeriod,
    handleFormValuesChange,
    buildFinalValues,
  ];
};
