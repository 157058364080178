import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Link from '@palette/components/designSystem/Link/Link';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import ResourceObjectsListTable from '@palette/components/resources/ResourceObjectsListTable/ResourceObjectsListTable';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import ExternalLink from '@palette/components/designSystem/ExternalLink/ExternalLink';
import ResourceDetailsSummaryActionCopy from '@palette/components/resources/ResourceDetailsSummaryActionCopy/ResourceDetailsSummaryActionCopy';
import ResourceDetailsSummaryActionDuplicate from '@palette/components/resources/ResourceDetailsSummaryActionDuplicate/ResourceDetailsSummaryActionDuplicate';
import ResourceDetailsSummaryActionDelete from '@palette/components/resources/ResourceDetailsSummaryActionDelete/ResourceDetailsSummaryActionDelete';

import { getResourceColumns } from '@palette/helpers/ConnectorHelper';
import { isResourceReport } from '@palette/helpers/CommonHelper';

import routePaths from '@palette/config/routePaths';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './ResourceDetailsSummary.less';

const classNames = bindClassNames.bind(styles);

const ResourceDetailsSummary = ({ className, resourceObject, isUser, canManage }) => {
  const { t } = useTranslation();
  const { connectorId } = useParams();

  const contentNode = useMemo(() => {
    const {
      clonedFrom,
      connector,
      crmUrl,
      data,
      originalType,
      type,
    } = resourceObject;

    const columns = getResourceColumns(
      connector,
      originalType,
      (column) => column.displayInResources,
    );

    return (
      <>
        <div className={styles.header}>
          <div className={styles.infos}>
            {connector.name}
            <ChevronRightLine className={styles.icon} width={32} height={32} />
            {type}
            {isResourceReport(originalType) && data.reportName && (
              <Tooltip title={t('resources.details.summary.report', { reportName: data.reportName })}>
                <InfoCircleFilled className={styles.reportIcon} width={24} height={24} />
              </Tooltip>
            )}
            {clonedFrom && (
              <div className={styles.clonedText}>
                <Link
                  className={styles.link}
                  path={routePaths.v2.resourceDetails}
                  params={{ connectorId, type: originalType, resourceId: clonedFrom }}
                  displayIcon
                  iconIsAfter={false}
                  iconNode={<DuplicateFilled className={styles.icon} width={18} height={18} />}
                >
                  {t('resources.details.summary.clonedFrom', { type })}
                </Link>
              </div>
            )}
          </div>
          <div className={styles.actions}>
            {crmUrl && (
              <ExternalLink
                className={styles.actionItem}
                href={crmUrl}
                displayIcon
              >
                {t('resources.details.summary.externalLink', { connector: connector.name })}
              </ExternalLink>
            )}
            <ResourceDetailsSummaryActionCopy className={styles.actionItem} resourceObject={resourceObject} />
            {!isUser && canManage && (
              <>
                <ResourceDetailsSummaryActionDuplicate className={styles.actionItem} resourceObject={resourceObject} />
                <ResourceDetailsSummaryActionDelete className={styles.actionItem} resourceObject={resourceObject} />
              </>
            )}
          </div>
        </div>
        <div className={styles.content}>
          <ResourceObjectsListTable
            connectorId={connector.id}
            resourceObjects={[resourceObject]}
            columns={columns}
            showLastColumn={false}
            isBorderless={false}
          />
        </div>
      </>
    );
  }, [resourceObject, connectorId, isUser, canManage]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

ResourceDetailsSummary.propTypes = {
  className: PropTypes.string,
  resourceObject: ResourceObjectModel.propTypes.isRequired,
  isUser: PropTypes.bool.isRequired,
  canManage: PropTypes.bool.isRequired,
};

ResourceDetailsSummary.defaultProps = {
  className: '',
};

export default ResourceDetailsSummary;
