import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import AddDataConnectionButton from '@palette/components/notebooks/AddDataConnectionButton/AddDataConnectionButton';
import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import NotebookDataConnectionList from '@palette/components/notebooks/NotebookDataConnectionList/NotebookDataConnectionList';
import NotebookCellsList from '@palette/components/notebooks/NotebookCellsList/NotebookCellsList';

import {
  NOTEBOOK_DETAILS_LEFT_SIDE_TABS_IDS,
  NOTEBOOK_DETAILS_LEFT_SIDE_TABS_QS_KEY,
} from '@palette/constants/tabs';
import { NOTEBOOK_CELL_EXPORTABLE_STATUSES, NOTEBOOK_CELL_TYPES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './NotebookDetailsLeftSideContent.less';

const classNames = bindClassNames.bind(styles);

const NotebookDetailsLeftSideContent = ({ className, notebook }) => {
  const { t } = useTranslation();

  const headerNode = useMemo(() => (
    <div className={styles.leftSideHeader}>
      <h2 className={styles.title}>
        {t('notebookDetailsLeftSideContent.dataConnections')}
      </h2>
      <AddDataConnectionButton className={styles.addDataConnectionButton} notebook={notebook} />
    </div>
  ), [notebook]);

  const tabs = useMemo(() => {
    const finalTabs = [];

    finalTabs.push({
      tabKey: NOTEBOOK_DETAILS_LEFT_SIDE_TABS_IDS.DATA_CONNECTIONS,
      title: t('notebookDetailsLeftSideContent.tabs.dataConnections'),
      titleCount: notebook.dataConnections.length,
      content: (<NotebookDataConnectionList notebook={notebook} />),
      defaultActive: true,
    });

    const runnableCells = notebook.cells.filter((cell) => {
      const cellType = NOTEBOOK_CELL_TYPES[cell.typeKey];
      return cellType.isRunnable;
    });
    finalTabs.push({
      tabKey: NOTEBOOK_DETAILS_LEFT_SIDE_TABS_IDS.RUNNABLE_CELLS,
      title: t('notebookDetailsLeftSideContent.tabs.runnableCells'),
      titleCount: runnableCells.length,
      content: (<NotebookCellsList cells={runnableCells} />),
    });

    const exportedCells = notebook.cells.filter((cell) => (cell.status === NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTED));
    finalTabs.push({
      tabKey: NOTEBOOK_DETAILS_LEFT_SIDE_TABS_IDS.EXPORTED_CELLS,
      title: t('notebookDetailsLeftSideContent.tabs.exportedCells'),
      titleCount: exportedCells.length,
      content: (<NotebookCellsList cells={exportedCells} />),
    });

    return finalTabs;
  }, [notebook]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {headerNode}
      <Tabs
        className={styles.tabs}
        tabs={tabs}
        qsTabKey={NOTEBOOK_DETAILS_LEFT_SIDE_TABS_QS_KEY}
      />
    </div>
  );
};

NotebookDetailsLeftSideContent.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
};

NotebookDetailsLeftSideContent.defaultProps = {
  className: '',
};

export default NotebookDetailsLeftSideContent;
