import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';
import AutoComplete from '@palette/components/designSystem/AutoComplete/AutoComplete';
import StarFilled from '@palette/components/utils/Icons/StarFilled';
import ReactAutoCompleteInput from '@palette/components/vendors/ReactAutoCompleteInput/ReactAutoCompleteInput';
import { InputRefForwarded } from '@palette/components/designSystem/Input/Input';
import { TextAreaRefForwarded } from '@palette/components/designSystem/TextArea/TextArea';
import Button from '@palette/components/designSystem/Button/Button';

import { getSuggestions } from '@palette/helpers/FormulaHelper';

import { FORMULA_INPUT_TYPES, SUGGESTIONS_TYPES } from '@palette/constants/formula';

import styles from './FormulaInput.less';

const classNames = bindClassNames.bind(styles);

const FormulaInput = ({
  className,
  helperData,
  includePrefix,
  type,
  topFields,
  value,
  onChange,
  componentType,
  suggestionsType,
  position,
  rows,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => setInternalValue(value), [value]);

  const handleChange = (newValue) => {
    setInternalValue(newValue);
    if (onChange !== null) {
      onChange(newValue);
    }
  };

  if (type === FORMULA_INPUT_TYPES.MULTIPLE_FIELDS_SELECTION || type === FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION) {
    const topOptions = [];
    const otherOptions = [];

    const computeOptions = (obj, prefix = null) => {
      const fields = obj ? Object.keys(obj).sort() : [];
      fields.forEach((field) => {
        const optionValueLabel = prefix !== null ? `${prefix}.${field}` : field;
        const isTopField = topFields.includes(field);
        if (isTopField) {
          topOptions.push({
            label: (
              <div className={styles.mostUsedOption}>
                {optionValueLabel}
                <div className={styles.mostUsedOptionDetails}>
                  <span className={styles.mostUsedOptionLabel}>{t('common.global.mostUsed')}</span>
                  <StarFilled className={styles.mostUsedOptionIcon} width={12} height={12} />
                </div>
              </div>
            ),
            value: optionValueLabel,
          });
        } else {
          otherOptions.push({
            label: optionValueLabel,
            value: optionValueLabel,
          });
        }

        const fieldValue = obj[field];
        if (typeof (fieldValue) === 'object' && !Array.isArray(fieldValue)) {
          computeOptions(fieldValue, optionValueLabel);
        }
      });
    };

    if (includePrefix) {
      Object.keys(helperData.data).sort().forEach((dataKey) => {
        const dataValue = helperData.data[dataKey];
        if (typeof (dataValue) === 'object' && !Array.isArray(dataValue)) {
          computeOptions(helperData.data[dataKey], dataKey);
        } else {
          otherOptions.push({
            label: dataKey,
            value: dataKey,
          });
        }
      });
    } else {
      computeOptions(helperData.data[helperData.type]);
    }

    const fieldsOptions = topOptions.concat(otherOptions);

    if (type === FORMULA_INPUT_TYPES.MULTIPLE_FIELDS_SELECTION) {
      return (
        <Select
          className={classNames({
            wrapper: true,
            [className]: className !== '',
          })}
          options={fieldsOptions}
          mode="tags"
          value={internalValue}
          onChange={handleChange}
          {...otherProps}
        />
      );
    }

    if (type === FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION) {
      let suggestionsNode = null;
      if (suggestionsType) {
        const suggestions = getSuggestions(fieldsOptions, suggestionsType);

        if (suggestions.length > 0) {
          suggestionsNode = (
            <div className={styles.suggestionsWrapper}>
              {
                suggestions.map((suggestion) => (
                  <Button
                    key={suggestion}
                    className={styles.suggestionBtn}
                    type="flatFillBlueOutlined"
                    onClick={() => handleChange(suggestion)}
                  >
                    {suggestion}
                  </Button>
                ))
              }
            </div>
          );
        }
      }
      return (
        <>
          <AutoComplete
            className={classNames({
              wrapper: true,
              [className]: className !== '',
            })}
            dropdownClassName={styles.autoCompleteDropdown}
            options={fieldsOptions}
            filterOption={(inputValue, option) => option.value.toLowerCase().includes((inputValue || '').toLowerCase())}
            value={internalValue}
            onChange={handleChange}
            {...otherProps}
          />
          {suggestionsNode}
        </>
      );
    }
  }

  // type === FORMULA_INPUT_TYPES.FORMULA
  const triggers = ['', ' '];
  const options = {};

  const computeTriggersAndOptions = (obj, prefix) => {
    const allFields = obj ? Object.keys(obj).sort() : [];

    options[prefix] = [];

    allFields.forEach((field) => {
      const fieldValue = obj[field];
      if (typeof (fieldValue) === 'object' && !Array.isArray(fieldValue)) {
        const dottedField = `${field}.`;
        options[prefix].push(dottedField);

        const fieldValueOptions = computeTriggersAndOptions(fieldValue, dottedField);
        options[prefix] = options[prefix].concat(fieldValueOptions.map((fieldValueOption) => (`${dottedField}${fieldValueOption}`)));
      } else {
        options[prefix].push(field);
      }
    });

    triggers.push(prefix);

    return options[prefix];
  };

  let allIn = [];
  const rootKeys = [];
  Object.keys(helperData.data).sort().forEach((dataKey) => {
    const dataValue = helperData.data[dataKey];
    if (typeof (dataValue) === 'object' && !Array.isArray(dataValue)) {
      const prefix = `${dataKey}.`;
      const dataValueOptions = computeTriggersAndOptions(dataValue, prefix);

      const allOptionsWithPrefix = dataValueOptions.map((option) => (`${prefix}${option}`));
      allIn = allIn.concat(allOptionsWithPrefix);

      rootKeys.push(prefix);
    } else {
      rootKeys.push(dataKey);
    }
  });

  allIn = rootKeys.concat(allIn);

  options[''] = allIn;
  options[' '] = allIn;

  return (
    <ReactAutoCompleteInput
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      dropdownClassName={styles.reactAutoCompleteInputDropdown}
      activeOptionClassName={styles.active}
      Component={componentType === 'textarea' ? TextAreaRefForwarded : InputRefForwarded}
      spacer=""
      matchAny
      maxOptions={Infinity}
      offsetY={componentType === 'textarea' ? 22 : 12}
      trigger={triggers}
      options={options}
      value={internalValue}
      onChange={handleChange}
      position={position}
      rows={rows}
      {...otherProps}
    />
  );
};

FormulaInput.propTypes = {
  className: PropTypes.string,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  includePrefix: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(FORMULA_INPUT_TYPES)),
  topFields: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.any,
  onChange: PropTypes.func,
  componentType: PropTypes.oneOf(['input', 'textarea']),
  suggestionsType: PropTypes.oneOf(Object.values(SUGGESTIONS_TYPES)),
  position: PropTypes.oneOf(['bottom', 'top']),
  rows: PropTypes.number,
};

FormulaInput.defaultProps = {
  className: '',
  includePrefix: true,
  type: FORMULA_INPUT_TYPES.FORMULA,
  topFields: [],
  value: '',
  onChange: null,
  componentType: 'textarea',
  suggestionsType: null,
  position: 'bottom',
  rows: 1,
};

export default FormulaInput;
