import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as MarkAsPaidStatementStrategyModel from '@palette/models/globalNotifStrategies/statement/MarkAsPaidStatementStrategy';

const classNames = bindClassNames.bind();

const MarkAsPaidStatementContentStrategy = ({ className, content }) => {
  const { total } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notifications.statements.success.markAsPaidStatements"
        values={{ count: total }}
      />
    </span>
  );
};

MarkAsPaidStatementContentStrategy.propTypes = {
  className: PropTypes.string,
  content: MarkAsPaidStatementStrategyModel.propTypes.isRequired,
};

MarkAsPaidStatementContentStrategy.defaultProps = {
  className: '',
};

export default MarkAsPaidStatementContentStrategy;
