import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import DashboardBreadcrumb from '@palette/components/dashboard/DashboardBreadcrumb/DashboardBreadcrumb';
import DashboardPresetDetailsPageContent from '@palette/components/dashboard/DashboardPresetDetailsPageContent/DashboardPresetDetailsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useDashboardPresetInParams } from '@palette/hooks/DashboardPresetHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './DashboardPresetDetailsPage.less';

const DashboardPresetDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { dashboardPreset } = useDashboardPresetInParams();

  if (!hasAllRights(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.USERS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.dashboardPresetDetailsTitle')} />
      <DashboardBreadcrumb className={styles.breadcrumb} dashboardPreset={dashboardPreset} />
      <DashboardPresetDetailsPageContent />
    </div>
  );
};

export default DashboardPresetDetailsPage;
