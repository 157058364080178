import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getSearch } from '@palette/helpers/NavigationHelper';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE, LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';

import { selectors as ProfileSelectors, actions as ProfileActions } from '@palette/state/Profile';
import { selectors as NavigationSelectors } from '@palette/state/Navigation';

export const useIsCollapsed = () => {
  const dispatch = useDispatch();
  const isCollapsed = useSelector(ProfileSelectors.menuIsCollapsed);

  const savedSetIsCollapsed = (collapsed) => {
    dispatch(ProfileActions.collapseMenu(collapsed));
  };

  return [isCollapsed, savedSetIsCollapsed];
};

export const useKeyInSearch = (qsKey, defaultValue = null, useLocationHook = false) => {
  let location = useSelector(NavigationSelectors.getCurrentLocation);

  if (useLocationHook) location = useLocation();

  return useMemo(() => {
    const qsObj = getSearch(location);

    return [qsObj[qsKey] || defaultValue, qsKey in qsObj];
  }, [location]);
};

export const useTabKeyInSearch = (tabKey, defaultValue = null, inSalesforce = false) => {
  if (tabKey === null) return [defaultValue, false];

  return useKeyInSearch(tabKey, defaultValue, inSalesforce);
};

export const usePageInSearch = (defaultValue = DEFAULT_PAGE_QS_VALUE) => {
  const [page, pageInSearch] = useKeyInSearch(PAGE_QS_KEY, defaultValue);

  return [parseInt(page, 10), pageInSearch];
};

export const useLimitInSearch = (defaultValue = DEFAULT_LIMIT_QS_VALUE) => {
  const [limit, limitInSearch] = useKeyInSearch(LIMIT_QS_KEY, defaultValue);

  return [parseInt(limit, 10), limitInSearch];
};
