import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './UnlinkQuotaFromMasterPlanModal.less';

const UnlinkQuotaFromMasterPlanModal = ({ visible, onClose, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId: plan.quotaId }));
  useEffect(() => {
    dispatch(QuotasActions.getById({ quotaId: plan.quotaId }));
  }, [plan]);

  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
  };

  const handleUnlinkQuota = () => {
    const unlinkQuota = () => {
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, quotaId: null, onSuccessCB }));
    };

    if (quota.periodType === PERIOD_TYPES.CUSTOM || quota.global) {
      dispatch(QuotasActions.deleteById({ quotaId: quota.id, onSuccessCB: unlinkQuota }));
    } else {
      unlinkQuota();
    }
  };

  return (
    <Modal
      className={styles.modal}
      title={t('unlinkQuotaFromMasterPlanModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleUnlinkQuota}
      okText={t('unlinkQuotaFromMasterPlanModal.confirm')}
      loading={updatePlanIsPending}
    >
      <div className={styles.content}>
        {t('unlinkQuotaFromMasterPlanModal.content')}
      </div>
    </Modal>
  );
};

UnlinkQuotaFromMasterPlanModal.propTypes = {
  plan: MasterPlanModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

UnlinkQuotaFromMasterPlanModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default UnlinkQuotaFromMasterPlanModal;
