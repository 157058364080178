import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';
import DollarFilled from '@palette/components/utils/Icons/DollarFilled';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';

import { usePayments } from '@palette/hooks/CommissionHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { computePaymentValueToDisplay, getCommissionAmount } from '@palette/helpers/CommissionHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { ALERT_TYPES } from '@palette/constants/alert';

import * as CommissionModel from '@palette/models/Commission';

import styles from './PaymentsList.less';

const classNames = bindClassNames.bind(styles);

const PaymentsList = ({ className, commission }) => {
  const { t } = useTranslation();

  const moment = getMoment();

  const [paymentsDone, paymentsToBeDone, remaining, cumulativeRoundedValues] = usePayments(commission);

  const commissionAmount = getCommissionAmount(commission);

  const remainingNode = useMemo(() => {
    if (remaining.value === 0) return null;

    const amount = formatPrice(remaining.value, commission.currency);
    const percentage = parseFloat(remaining.percentage * 100).toFixed(2);

    const messageNode = (
      // eslint-disable-next-line react/no-danger
      <span dangerouslySetInnerHTML={{ __html: t('paymentsList.withoutPayments', { amount, percentage }) }} />
    );

    return (
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.WARNING}
        message={messageNode}
      />
    );
  }, [remaining, commission]);

  const toBeDoneNode = useMemo(() => {
    if (paymentsToBeDone.payments.length === 0) return null;

    const doneDescriptionNode = (
      <div className={styles.listWrapper}>
        <InfoCircleFilled className={styles.icon} />
        <ul className={styles.list}>
          {
            paymentsToBeDone.payments.map((payment, paymentIndex) => {
              const shouldCheckDiffWithCommissionAmount = commission.paymentsPercentage === 1
                && (paymentsToBeDone.payments.length - 1) === paymentIndex
                && remaining.value === 0;
              const paymentValue = computePaymentValueToDisplay(
                payment,
                cumulativeRoundedValues,
                commissionAmount,
                shouldCheckDiffWithCommissionAmount,
              );
              const amount = formatPrice(paymentValue, commission.currency);
              const percentage = parseFloat(payment.percentage * 100).toFixed(2);
              const date = moment.utc(payment.date).format('MMMM YYYY');
              const exactDate = moment.utc(payment.date).format('L');

              return (
                // eslint-disable-next-line react/no-danger
                <li key={payment.date} dangerouslySetInnerHTML={{ __html: t('paymentsList.willBePaid', { amount, percentage, date, exactDate }) }} />
              );
            })
          }
        </ul>
      </div>
    );

    return (
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.SUCCESS}
        showIcon={false}
        message={t('paymentsList.upcomingPayments')}
        description={doneDescriptionNode}
      />
    );
  }, [paymentsToBeDone, commission, remaining]);

  const doneNode = useMemo(() => {
    if (paymentsDone.payments.length === 0) return null;

    const doneDescriptionNode = (
      <div
        className={classNames({
          listWrapper: true,
          doneListWrapper: true,
        })}
      >
        <DollarFilled className={styles.icon} />
        <ul className={styles.list}>
          {
            paymentsDone.payments.map((payment, paymentIndex) => {
              const shouldCheckDiffWithCommissionAmount = commission.paymentsPercentage === 1
                && (paymentsDone.payments.length - 1) === paymentIndex
                && paymentsToBeDone.payments.length === 0;
              const paymentValue = computePaymentValueToDisplay(
                payment,
                cumulativeRoundedValues,
                commissionAmount,
                shouldCheckDiffWithCommissionAmount,
              );
              const amount = formatPrice(paymentValue, commission.currency);
              const percentage = parseFloat(payment.percentage * 100).toFixed(2);
              const date = moment.utc(payment.date).format('MMMM YYYY');
              const exactDate = moment.utc(payment.date).format('L');

              return (
                // eslint-disable-next-line react/no-danger
                <li key={payment.date} dangerouslySetInnerHTML={{ __html: t('paymentsList.hasBeenPaid', { amount, percentage, date, exactDate }) }} />
              );
            })
          }
        </ul>
      </div>
    );

    return (
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.INFO}
        showIcon={false}
        message={t('paymentsList.pastPayments')}
        description={doneDescriptionNode}
      />
    );
  }, [paymentsDone, commission, paymentsToBeDone]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {remainingNode}
      {toBeDoneNode}
      {doneNode}
    </div>
  );
};

PaymentsList.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

PaymentsList.defaultProps = {
  className: '',
};

export default PaymentsList;
