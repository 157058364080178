import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import TextArea from '@palette/components/designSystem/TextArea/TextArea';
import Alert from '@palette/components/designSystem/Alert/Alert';
import PaletteLogo from '@palette/components/utils/PaletteLogo/PaletteLogo';
import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import CloudinaryUploader from '@palette/components/designSystem/CloudinaryUploader/CloudinaryUploader';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { ALERT_TYPES } from '@palette/constants/alert';
import { SEND_FEEDBACK_MAX_ATTACHMENTS_NB } from '@palette/constants/global';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './SendFeedbackModal.less';

const SendFeedbackModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [attachmentsUploadIsVisible, setAttachmentsUploadIsVisible] = useState(false);
  const [uploadsList, setUploadsList] = useState([]);

  const handleUpdateUploadUrl = useCallback((fileUrl, index) => {
    setUploadsList((list) => {
      list.splice(index, 1, fileUrl);
      return [...list];
    });
  }, []);

  const handleRemoveUpdateUploadUrl = useCallback((event, index) => {
    event.stopPropagation();
    setUploadsList((list) => {
      list.splice(index, 1);
      return [...list];
    });
  }, []);

  const handleNewUploadUrl = useCallback((fileUrl) => {
    if (fileUrl !== null) {
      setUploadsList((list) => ([...list, fileUrl]));
    }
  }, []);

  const sendFeedbackIsPending = useSelector(ProfileSelectors.sendFeedbackIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    content: undefined,
  }), []);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const handleFinish = useCallback(({ content }) => {
    dispatch(ProfileActions.sendFeedback({ content, attachments: uploadsList, onSuccessCB: cleanAndClose }));
  }, [uploadsList, cleanAndClose]);

  const alertNode = useMemo(() => {
    const alertMessage = (
      <div className={styles.alertMessageWrapper}>
        <PaletteLogo logoOnly height={56} width={56} />
        <div className={styles.message}>
          <div className={styles.hint}>
            {t('sendFeedbackModal.alertMessage.hint')}
          </div>
          <div className={styles.explanation}>
            {t('sendFeedbackModal.alertMessage.explanation')}
          </div>
        </div>
      </div>
    );

    return (
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.INFO}
        showIcon={false}
        message={alertMessage}
      />
    );
  }, []);

  const uploadsListNodes = useMemo(() => {
    const uploadsListItemsNodes = uploadsList.map((uploadUrl, index) => {
      const removeNode = (
        <Tooltip title={t('common.global.remove')}>
          <Button
            className={styles.removeUploadListItem}
            type="linkDestroy"
            icon={<TrashFilled width={16} height={16} />}
            onClick={(event) => handleRemoveUpdateUploadUrl(event, index)}
          />
        </Tooltip>
      );

      return (
        <CloudinaryUploader
          key={`upload_${index}`}
          className={styles.uploadListItem}
          onFileUrlChange={(fileUrl) => handleUpdateUploadUrl(fileUrl, index)}
          fileUrl={uploadUrl}
          uploadedFileAddon={removeNode}
        />
      );
    });

    if (uploadsListItemsNodes.length < SEND_FEEDBACK_MAX_ATTACHMENTS_NB) {
      uploadsListItemsNodes.push((
        <CloudinaryUploader
          key={`upload_${uploadsListItemsNodes.length}`}
          className={styles.uploadListItem}
          onFileUrlChange={handleNewUploadUrl}
        />
      ));
    }

    return (
      <div className={styles.uploadsListWrapper}>
        {uploadsListItemsNodes}
      </div>
    );
  }, [
    uploadsList,
    handleUpdateUploadUrl,
    handleRemoveUpdateUploadUrl,
    handleNewUploadUrl,
  ]);

  const attachmentsUploadNode = useMemo(() => {
    if (attachmentsUploadIsVisible) {
      return uploadsListNodes;
    }

    return (
      <Button
        className={styles.uploadAttachmentsBtn}
        icon={<AddFilled />}
        type="primaryBlue"
        onClick={() => setAttachmentsUploadIsVisible(true)}
      >
        {t('sendFeedbackModal.uploadAttachments')}
      </Button>
    );
  }, [
    attachmentsUploadIsVisible,
    uploadsListNodes,
  ]);

  const handleSendFeedback = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('sendFeedbackModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleSendFeedback}
      okText={t('sendFeedbackModal.send')}
      loading={sendFeedbackIsPending}
    >
      {alertNode}
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="content"
          label={t('sendFeedbackModal.form.content.label')}
          required
          rules={[
            { required: true, message: t('sendFeedbackModal.form.content.rules.required') },
          ]}
        >
          <TextArea
            rows={4}
            placeholder={t('sendFeedbackModal.form.content.placeholder')}
            disabled={sendFeedbackIsPending}
          />
        </FormItem>
      </Form>
      {attachmentsUploadNode}
    </Modal>
  );
};

SendFeedbackModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

SendFeedbackModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default SendFeedbackModal;
