import qs from 'qs';

import { APP_BOTTOM_ID, APP_TOP_ID } from '@palette/constants/navigation';

let globalHistory = () => {};

export const setHistory = (history) => {
  globalHistory = history;
};

export const getHistory = () => (globalHistory);

export const stringifyQS = (qsObject, addQueryPrefix = true) => qs.stringify(qsObject, { addQueryPrefix });
export const getSearch = (location, ignoreQueryPrefix = true) => qs.parse(location.search || '', { ignoreQueryPrefix });

export const computePathname = (path, params) => Object.keys(params).reduce((finalPath, paramKey) => (
  finalPath.replaceAll(`:${paramKey}`, params[paramKey])
), path);

export const getHistoryObject = ({ path, params = {}, qsObject = {} }) => ({
  pathname: computePathname(path, params),
  search: stringifyQS(qsObject),
});

export const redirectTo = ({ path, params = {}, qsObject = {} }) => {
  const history = getHistory();

  const historyObject = getHistoryObject({ path, params, qsObject });

  if (typeof (history.push) !== 'function') {
    window.location.href = `./#${historyObject.pathname}${historyObject.search}`;
    window.location.reload();
    return;
  }

  history.push(historyObject);
};

export const isSamePagePathname = ({ path, params = {}, qsObject = {} }) => {
  const pageHistoryObject = getHistoryObject({ path, params, qsObject });

  const history = getHistory();

  let locationPathname = history.location?.pathname || null;
  if (locationPathname === null) {
    locationPathname = window.location.hash.substring(1);
  }

  return locationPathname === pageHistoryObject.pathname;
};

export const isSamePage = ({ path, params = {}, qsObject = {} }) => {
  const pageHistoryObject = getHistoryObject({ path, params, qsObject });

  const history = getHistory();

  let locationPathname = history.location?.pathname || null;
  if (locationPathname === null) {
    locationPathname = window.location.hash.substring(1);
  }

  let locationSearch = history.location ? history.location.search : null;
  if (locationSearch === null) {
    locationSearch = window.location.search;
  }

  return locationPathname === pageHistoryObject.pathname && locationSearch === pageHistoryObject.search;
};

export const getCleanLocationSearchNewLocation = (location, keysToDelete) => {
  const qsObj = getSearch(location);

  keysToDelete.forEach((keyToDelete) => {
    delete qsObj[keyToDelete];
  });

  return {
    pathname: location.pathname,
    search: stringifyQS(qsObj),
  };
};

export const getAddQSToLocationNewLocation = (location, qsObject) => ({
  pathname: location.pathname,
  search: stringifyQS({
    ...getSearch(location),
    ...qsObject,
  }),
});

export const scrollToPosition = (position, smooth = true, element = undefined) => {
  const scrollIntoViewArg = {};
  const scrollToArg = { top: 0, left: 0 };

  if (smooth) {
    scrollIntoViewArg.behavior = 'smooth';
    scrollToArg.behavior = 'smooth';
  }

  if (element && position === APP_TOP_ID) {
    element.scrollTo(scrollToArg);
  } else {
    document.getElementById(position).scrollIntoView(scrollIntoViewArg);
  }
};

export const scrollToTop = (smooth = true, element = undefined) => {
  scrollToPosition(APP_TOP_ID, smooth, element);
};

export const scrollToBottom = (smooth = true) => {
  scrollToPosition(APP_BOTTOM_ID, smooth);
};
