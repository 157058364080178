import React, { Component } from 'react';
import { message, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { getJobadderConnectURL } from '../../../../services/connector';
import { CONNECTOR_TYPES } from '../../../../services/constants';
import SourceVignette from '../sourceVignette';
import '../../../../styles/connectCRMButton.less';

class ConnectJobadderButtonOauth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  connectJobadder = () => {
    this.setState({ loading: true });
    getJobadderConnectURL()
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((e) => {
        message.error('Error while contacting JobAdder.');
        console.error(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    const { disabled } = this.props;
    return (
      <Tooltip
        placement="right"
        title={disabled ? 'Connector already linked' : null}
        style={{ display: 'block' }}
      >

        <SourceVignette
          loading={loading}
          icon={(
            <img
              style={{ width: '44px' }}
              alt="jobadder crm"
              src="/img/jobadder-logo.png"
            />
          )}
          type={CONNECTOR_TYPES.CRM}
          name="JobAdder"
          id="JOBADDER"
          disabled={disabled}
          onClick={disabled ? null : this.connectJobadder}
        />

      </Tooltip>

    );
  }
}

ConnectJobadderButtonOauth.propTypes = {
  disabled: PropTypes.bool,
};

ConnectJobadderButtonOauth.defaultProps = {
  disabled: false,
};

export default ConnectJobadderButtonOauth;
