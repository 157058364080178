import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import '../../styles/admin/formulaHelper.less';
import { getDisplayType } from '../../services/utils';
import { listObjectIdsForPlan, listTrackingObjects } from '../../services/admin/plans';
import { listObjects } from '../../services/admin/objects';

class FormulaHelper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type || prevProps.connectorId !== this.props.connectorId) {
      this.fetch();
    }
  }

  fetch = () => {
    const {
      connectorId, type, onData, plan, isTrackingObject,
    } = this.props;
    if (connectorId && type) {
      this.setState({ loading: true }, () => {
        let fetchData;
        if (plan != null) {
          if (isTrackingObject) {
            fetchData = listTrackingObjects({
              planId: plan._id, limit: 20, page: 0,
            }).then(({ data: { documents, trackingObjects } }) => {
              if (trackingObjects.length === 0) {
                return listObjects({ connectorId, type }).then(({ data: { objects } }) => objects);
              }
              return trackingObjects.map((t) => documents[t.object]);
            });
          } else {
            fetchData = listObjectIdsForPlan(
              { planId: plan._id },
            ).then(({ data }) => {
              if (data.length === 0) {
                return listObjects({ connectorId, type }).then(({ data: { objects } }) => objects);
              }
              return data;
            });
          }
        } else {
          fetchData = listObjects({ connectorId, type }).then(({ data: { objects } }) => objects);
        }
        fetchData
          .then((objects) => this.setState({
            loading: false,
            data: objects.reduce((o, object) => ({
              ...o,
              ...object.data,
              ...((plan && plan.targetDefinition)
                ? {
                  [`_${plan.targetDefinition.toLowerCase().replace(/[^a-z ]/g, '').trim().replace(/ /g, '_')}`]: 100,
                } : {}),
            }), {}),
          }, () => onData(this.state.data)))
          .catch((error) => {
            console.error('Unexpected error', error);
            this.setState({ loading: false, error: true });
          });
      });
    } else {
      this.setState({
        loading: false,
        data: null,
      });
    }
  };

  render() {
    const { loading, data, error } = this.state;
    if (error) return null;
    const { type, additionalProperties, uiType } = this.props;
    if (!loading && !data && !type) return null;
    const displayType = getDisplayType({ type, uiType });
    return (
      <div className="FormulaHelper" style={this.props.style}>
        {loading ? 'Loading...' : (
          data ? (
            <ReactJson
              src={additionalProperties ? {
                ...additionalProperties,
                [displayType]: {
                  ...data,
                  ...(additionalProperties[displayType] || {}),
                },
              } : data}
              name={additionalProperties ? 'Formula Helper' : displayType}
              indentWidth={8}
              collapsed
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
              sortKeys
            />
          ) : (
            type && `No ${displayType} synchronized in DB`
          )
        )}
      </div>
    );
  }
}

FormulaHelper.propTypes = {
  connectorId: PropTypes.string,
  type: PropTypes.string,
  uiType: PropTypes.string,
  additionalProperties: PropTypes.object,
  style: PropTypes.object,
  onData: PropTypes.func,
  plan: PropTypes.object,
  isTrackingObject: PropTypes.bool,
};

FormulaHelper.defaultProps = {
  connectorId: undefined,
  type: undefined,
  uiType: undefined,
  additionalProperties: null,
  style: {},
  onData: () => null,
  plan: null,
  isTrackingObject: true,
};

export default FormulaHelper;
