import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { getMoment } from '@palette/helpers/MomentHelper';
import { computeUserJoinLeaveDate } from '@palette/helpers/MasterPlanHelper';
import { usePlanLimitDates } from '@palette/hooks/MasterPlanHooks';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanUserModel from '@palette/models/MasterPlanUser';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './MasterPlanEditUserDateCell.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanEditUserDateCell = ({ className, plan, user, manageEndDate, forManagers }) => {
  const dispatch = useDispatch();
  const moment = getMoment();
  const { beginDate, endDate } = usePlanLimitDates(plan);

  const handleDatePickerChange = useCallback((date) => {
    const dateField = manageEndDate ? 'endDate' : 'startDate';
    const finalDate = computeUserJoinLeaveDate({ date, manageEndDate });

    const payload = {
      planId: plan.id,
      user: user.user,
      [dateField]: finalDate,
      forManagers,
    };

    dispatch(MasterPlansActions.updatePlanUserDates(payload));
  }, [manageEndDate, plan, user, forManagers]);

  const disabledDate = useCallback((date) => {
    let isInvalidDate = !date || date.isSameOrBefore('1970-01-01') || date.isBefore(beginDate);

    if (endDate !== null) {
      isInvalidDate = isInvalidDate || date.isAfter(endDate);
    }

    return isInvalidDate;
  }, [beginDate, endDate]);

  const date = useMemo(() => (manageEndDate ? user.endDate : user.startDate), [user, manageEndDate]);

  const contentNode = useMemo(() => {
    if (date === null) return '-';

    return moment(date).utc().format('LL');
  }, [date]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <DatePicker
        className={styles.datePicker}
        picker="date"
        value={date === null ? moment().utc() : moment(date).utc()}
        allowClear={date !== null && manageEndDate}
        disabledDate={disabledDate}
        onChange={handleDatePickerChange}
      />
      {contentNode}
    </div>
  );
};

MasterPlanEditUserDateCell.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  user: MasterPlanUserModel.propTypes.isRequired,
  manageEndDate: PropTypes.bool,
  forManagers: PropTypes.bool,
};

MasterPlanEditUserDateCell.defaultProps = {
  className: '',
  manageEndDate: false,
  forManagers: false,
};

export default MasterPlanEditUserDateCell;
