import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import { PLAN_SETTINGS_TABS_QS_KEY, PLAN_SETTINGS_TABS_IDS } from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import * as PlanTrackedResourceEvaluationErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanTrackedResourceEvaluationErrorStrategy';

const classNames = bindClassNames.bind();

const PlanTrackedResourceEvaluationErrorContentStrategy = ({ className, content }) => {
  const { formula, plan, reason } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="plan.tracked.resource.value.evaluation.content"
        values={{
          name: plan.name,
          formula,
          reason,
        }}
        components={[
          <Link
            key={plan.id}
            path={routePaths.v2.planSettings}
            params={{ masterPlanId: plan.id }}
            qsObject={{
              [PLAN_SETTINGS_TABS_QS_KEY]: PLAN_SETTINGS_TABS_IDS.QUOTA,
            }}
          />,
        ]}
      />
    </span>
  );
};

PlanTrackedResourceEvaluationErrorContentStrategy.propTypes = {
  className: PropTypes.string,
  content: PlanTrackedResourceEvaluationErrorStrategyModel.propTypes.isRequired,
};

PlanTrackedResourceEvaluationErrorContentStrategy.defaultProps = {
  className: '',
};

export default PlanTrackedResourceEvaluationErrorContentStrategy;
