import { put, all, takeLatest, call } from 'redux-saga/effects';

import { shouldLogAnalytics } from '@palette/helpers/AnalyticsHelper';

import { CLICK_ON_DOC_LINK_EVENT } from '@palette/constants/analytics';

import * as AnalyticsService from '@palette/services/AnalyticsService';

import { actions as AnalyticsActions } from '@palette/state/Analytics/slice';

export function* sendEvent({ payload }) {
  const { event, params = {} } = payload;

  if (shouldLogAnalytics()) {
    yield call(AnalyticsService.sendEvent, event, params);
  }
}

export function* sendClickOnDocLink({ payload }) {
  const { analyticsDocSource, params = {} } = payload;

  if (shouldLogAnalytics()) {
    yield call(AnalyticsService.sendEvent, CLICK_ON_DOC_LINK_EVENT, { ...params, source: analyticsDocSource });
  }
}

export function* loop() {
  yield all([
    takeLatest(AnalyticsActions.sendEvent.type, sendEvent),
    takeLatest(AnalyticsActions.sendClickOnDocLink.type, sendClickOnDocLink),
  ]);
}

export function* clean() {
  yield put(AnalyticsActions.resetToInitialState());
}
