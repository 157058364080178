import ArrayFilled from '@palette/components/utils/Icons/ArrayFilled';
import ObjectFilled from '@palette/components/utils/Icons/ObjectFilled';
import ColumnFilled from '@palette/components/utils/Icons/ColumnFilled';
import FolderIcon from '@palette/components/designSystem/FolderIcon/FolderIcon';
import { PALETTE_DOCUMENTATION_URL } from '@palette/constants/global';

export const NOTEBOOK_DOCUMENTATION_URL = `${PALETTE_DOCUMENTATION_URL}advanced-concepts/models`;

export const NOTEBOOK_TYPES = {
  CLASSIC: 'classic',
  TEMPLATE: 'template',
};

export const NOTEBOOK_STATUSES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const NOTEBOOK_DATA_CONNECTION_TYPES = {
  CONNECTOR: 'CONNECTOR',
  NOTEBOOK: 'NOTEBOOK',
};

export const NOTEBOOK_CELL_TYPES_IDS = {
  SQL: 'sql',
  TEXT: 'text',
  MARKDOWN: 'markdown',
  TABLE: 'table',
  PYTHON: 'python',
  JS: 'javascript',
};

export const NOTEBOOK_CELL_EXPORTABLE_STATUSES = {
  EXPORTED: 'exported',
  EXPORTABLE: 'exportable',
  UNEXPORTABLE: 'unexportable',
};

export const NOTEBOOK_CELL_TYPES = {
  SQL: {
    id: NOTEBOOK_CELL_TYPES_IDS.SQL,
    i18n: 'notebooks.cellTypes.sql',
    exportableStatus: NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTABLE,
    isRunnable: true,
    codeEditorLanguage: 'sql',
  },
  TEXT: {
    id: NOTEBOOK_CELL_TYPES_IDS.TEXT,
    i18n: 'notebooks.cellTypes.text',
    exportableStatus: NOTEBOOK_CELL_EXPORTABLE_STATUSES.UNEXPORTABLE,
    isRunnable: false,
  },
  MARKDOWN: {
    id: NOTEBOOK_CELL_TYPES_IDS.MARKDOWN,
    i18n: 'notebooks.cellTypes.markdown',
    exportableStatus: NOTEBOOK_CELL_EXPORTABLE_STATUSES.UNEXPORTABLE,
    isRunnable: false,
    codeEditorLanguage: 'markdown',
  },
  TABLE: {
    id: NOTEBOOK_CELL_TYPES_IDS.TABLE,
    i18n: 'notebooks.cellTypes.table',
    exportableStatus: NOTEBOOK_CELL_EXPORTABLE_STATUSES.UNEXPORTABLE,
    isRunnable: false,
  },
  PYTHON: {
    id: NOTEBOOK_CELL_TYPES_IDS.PYTHON,
    i18n: 'notebooks.cellTypes.python',
    exportableStatus: NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTABLE,
    isRunnable: true,
    codeEditorLanguage: 'python',
    initialContent: '# print(your_dataframe.to_json(orient=\'records\'))',
  },
  JS: {
    id: NOTEBOOK_CELL_TYPES_IDS.JS,
    i18n: 'notebooks.cellTypes.js',
    exportableStatus: NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTABLE,
    isRunnable: true,
    codeEditorLanguage: 'javascript',
  },
};

export const NOTEBOOK_BASIC_CELL_TYPES = [
  NOTEBOOK_CELL_TYPES.SQL,
  NOTEBOOK_CELL_TYPES.MARKDOWN,
];

export const NOTEBOOK_NO_SUPPORT_CELL_TYPES = [
  NOTEBOOK_CELL_TYPES.PYTHON,
];

export const ADD_DATA_CONNECTION_FLOW_STEPS = {
  SELECT_DATA_SOURCE: {
    label: 'addDataConnectionFlowSteps.select_data_source.label',
    isDiscard: () => false,
    value: 'SELECT_DATA_SOURCE',
  },
  SELECT_DATA: {
    label: 'addDataConnectionFlowSteps.select_data.label',
    isDiscard: () => false,
    value: 'SELECT_DATA',
  },
};

export const ADD_DATA_CONNECTION_FLOW_FIRST_STEP = ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA_SOURCE;

export const ADD_DATA_CONNECTION_FLOW_ORDERED_STEPS = [
  ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA_SOURCE,
  ADD_DATA_CONNECTION_FLOW_STEPS.SELECT_DATA,
];

export const NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES = {
  RESOURCE: 'RESOURCE',
  ARRAY: 'ARRAY',
  OBJECT: 'OBJECT',
  SELECTABLE: 'SELECTABLE',
};

export const NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS = {
  [NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.RESOURCE]: FolderIcon,
  [NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.ARRAY]: ArrayFilled,
  [NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.OBJECT]: ObjectFilled,
  [NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.SELECTABLE]: ColumnFilled,
};

export const NOTEBOOK_CODE_CELL_EXECUTION_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NEVER_EXECUTED: 'NEVER_EXECUTED',
};
