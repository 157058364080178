import PropTypes from 'prop-types';

import {
  manageAmountAttribute, manageArrayAttribute, manageBooleanAttribute, manageNumberAttribute,
  managePureFloatAttribute, manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as CommissionModel from '@palette/models/Commission';

export const modelName = 'MasterPlanPeriodUser';

export const propTypes = PropTypes.shape({
  user: MetaUserModel.propTypes,
  progress: PropTypes.number,
  target: PropTypes.number,
  totalValue: PropTypes.number,
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  dealsCount: PropTypes.number,
  // In case of a "User Group By User" plan
  commissions: PropTypes.arrayOf(CommissionModel.propTypes),
  hasOnlyTargetRule: PropTypes.bool,
});

export const managePeriodUserCommissions = (masterPlanPeriodUser) => {
  let commissions = manageArrayAttribute(masterPlanPeriodUser, 'commissions');
  commissions = commissions.map((commission) => ({
    ...commission,
    payee: masterPlanPeriodUser,
  }));
  return CommissionModel.transformList(commissions);
};

export const transform = (masterPlanPeriodUser) => {
  if (!masterPlanPeriodUser) {
    return null;
  }

  return ({
    user: MetaUserModel.transform(masterPlanPeriodUser),
    progress: managePureFloatAttribute(masterPlanPeriodUser, 'progress'),
    target: managePureFloatAttribute(masterPlanPeriodUser, 'target'),
    totalValue: managePureFloatAttribute(masterPlanPeriodUser, 'totalDealValue'),
    totalCommissionAmount: manageAmountAttribute(masterPlanPeriodUser, 'totalCommissionAmount'),
    currency: manageStringAttribute(masterPlanPeriodUser, 'currency'),
    dealsCount: manageNumberAttribute(masterPlanPeriodUser, 'dealsCount'),
    // In case of a "User Group By User" plan
    commissions: managePeriodUserCommissions(masterPlanPeriodUser),
    hasOnlyTargetRule: manageBooleanAttribute(masterPlanPeriodUser, 'hasOnlyTargetRule'),
  });
};

export const transformList = (masterPlanPeriodUsers) => masterPlanPeriodUsers.map((masterPlanPeriodUser) => transform(masterPlanPeriodUser));
