import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import PlanPayoutScheduleChart from '@palette/components/charts/PlanPayoutScheduleChart/PlanPayoutScheduleChart';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { usePrevious } from '@palette/hooks/CommonHooks';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowPreviewPayoutSchedule.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowPreviewPayoutSchedule = ({ className, plan, template, onPreview }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const previousTemplate = usePrevious(template);

  const computePlanIsPending = useSelector(MasterPlansSelectors.computePlanIsPending);
  const payoutScheduleIsPending = useSelector(MasterPlansSelectors.payoutScheduleIsPending);
  const masterPlanPayoutSchedule = useSelector((state) => MasterPlansSelectors.getMasterPlanPayoutSchedule(state, { masterPlanId: plan?.id || null }));

  const handleSuccessCompute = useCallback(() => {
    dispatch(MasterPlansActions.getPayoutSchedule({ planId: plan.id }));
  }, [plan]);

  const performCompute = useCallback(() => {
    dispatch(MasterPlansActions.computePlan({
      planId: plan.id,
      disableSuccessNotification: true,
      onSuccessCB: handleSuccessCompute,
    }));
  }, [plan]);

  const handlePreview = useCallback(() => {
    if (onPreview !== null) {
      onPreview(performCompute);
    } else {
      performCompute();
    }
  }, [performCompute, onPreview]);

  useEffect(() => {
    if (
      template
      && template !== previousTemplate
      && !computePlanIsPending
      && !payoutScheduleIsPending
      && masterPlanPayoutSchedule !== null
    ) {
      handlePreview();
    }
  }, [template, handlePreview]);

  const loadingNode = useMemo(() => {
    if (computePlanIsPending || payoutScheduleIsPending) {
      return (
        <div className={styles.loadingWrapper}>
          <RefreshLine className={styles.loadingIcon} width={18} height={18} spin />
          <div className={styles.loadingLabel}>
            {t('loading')}
          </div>
        </div>
      );
    }

    return null;
  }, [plan, computePlanIsPending, payoutScheduleIsPending]);

  const emptyNode = useMemo(() => {
    if (masterPlanPayoutSchedule?.amountPerPeriod.length === 0) {
      return (
        <DefaultEmptyState className={styles.emptyWrapper} />
      );
    }

    return null;
  }, [masterPlanPayoutSchedule?.amountPerPeriod]);

  const resultsNode = useMemo(() => {
    if (masterPlanPayoutSchedule !== null) {
      return (
        <div className={styles.resultsWrapper}>
          <span className={styles.previewTitle}>
            {t('createPlanFlowPreviewPayoutSchedule.preview.title')}
          </span>
          {emptyNode}
          <PlanPayoutScheduleChart data={masterPlanPayoutSchedule} />
        </div>
      );
    }

    return null;
  }, [plan, masterPlanPayoutSchedule]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.btnTestWrapper}>
        <Button
          type="primaryBlue"
          onClick={handlePreview}
          disabled={computePlanIsPending || payoutScheduleIsPending}
        >
          {masterPlanPayoutSchedule === null && t('createPlanFlowPreviewPayoutSchedule.testBtn.label')}
          {masterPlanPayoutSchedule !== null && t('createPlanFlowPreviewPayoutSchedule.testBtn.refresh.label')}
        </Button>
        {loadingNode}
      </div>
      {resultsNode}
    </div>
  );
};

CreatePlanFlowPreviewPayoutSchedule.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  template: PropTypes.string,
  onPreview: PropTypes.func,
};

CreatePlanFlowPreviewPayoutSchedule.defaultProps = {
  className: '',
  template: null,
  onPreview: null,
};

export default CreatePlanFlowPreviewPayoutSchedule;
