import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import AddLine from '@palette/components/utils/Icons/AddLine';
import BlingBlingIllustration from '@palette/components/utils/Icons/BlingBlingIllustration';
import Input from '@palette/components/designSystem/Input/Input';
import InputAmountCurrency from '@palette/components/designSystem/InputAmountCurrency/InputAmountCurrency';

import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { addCurrencyToNode } from '@palette/helpers/CurrencyHelper';
import { getPeriodDates, getPeriodName } from '@palette/helpers/StatementHelper';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useStatementUserIdInParams, usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementAdjustmentModel from '@palette/models/UserStatementAdjustment';

import styles from './AddEditAdjustmentModal.less';

const AddEditAdjustmentModal = ({
  visible,
  onClose,
  adjustment,
  statementId,
  statementAmount,
  statementCurrency,
  statementPeriod,
  correctionStatementId,
  currency,
  needRedirectOnCB,
  isASuggestedCorrection,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const { userId } = useStatementUserIdInParams();

  const addEditAdjustmentIsPending = useSelector(StatementsSelectors.addEditAdjustmentIsPending);

  const isEdition = !!adjustment && !isASuggestedCorrection;

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    adjustmentReason: adjustment?.reason ?? '',
    adjustmentAmount: adjustment?.amount ?? null,
    adjustmentCurrency: adjustment?.currency ?? statementCurrency ?? profile.userData.company.currency,
  }), [adjustment, statementCurrency, profile]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const handleClose = () => onClose();

  const handleSuccessCB = useCallback(() => {
    handleClose();

    if (needRedirectOnCB) {
      redirectTo({
        path: routePaths.v2.statementsUserStatement,
        params: { statementPeriodId: statementPeriod.id, userId },
        qsObject: periodsDatesFilterQSObject,
      });
    }
  }, [
    handleClose,
    needRedirectOnCB,
    statementPeriod,
    userId,
    periodsDatesFilterQSObject,
  ]);

  const handleFinish = useCallback((values) => {
    const { adjustmentReason, adjustmentAmount } = values;

    dispatch(StatementsActions.addEditAdjustment({
      statementId,
      adjustmentId: adjustment?.id || undefined,
      correctionStatementId: correctionStatementId || undefined,
      amount: adjustmentAmount,
      adjustmentCurrency: formValues.adjustmentCurrency,
      reason: adjustmentReason,
      onSuccessCB: handleSuccessCB,
      statementPeriodId: statementPeriod.id,
      userId,
      currency,
    }));
  }, [
    statementId,
    adjustment,
    correctionStatementId,
    formValues,
    handleSuccessCB,
    statementPeriod,
    userId,
    currency,
    periodsDatesFilterQSObject,
    needRedirectOnCB,
  ]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setFormValues({
      ...formValues,
      ...allValues,
    });
  }, [formValues]);

  const handleCurrencyChange = useCallback((currencySelected) => {
    setFormValues({
      ...formValues,
      adjustmentCurrency: currencySelected,
    });
  }, [formValues]);

  const formIsPristine = useMemo(() => {
    if (!correctionStatementId && _isEqual(initialValues, formValues)) return true;

    if (
      (formValues.adjustmentCurrency !== initialValues.adjustmentCurrency)
      && !formValues.adjustmentAmount
      && formValues.adjustmentAmount !== 0
    ) return true;

    return false;
  }, [initialValues, formValues, correctionStatementId]);

  const totalCommissionsNode = useMemo(() => {
    const periodName = getPeriodName(statementPeriod.statementStrategy, statementPeriod.period);
    const periodDates = getPeriodDates(statementPeriod.beginDate, statementPeriod.endDate);
    const splittedFormattedValue = comaSeparatorFormatter(statementAmount).split('.');
    const valuesNodes = [];

    valuesNodes.push((
      <div key="integerPart" className={styles.integerPart}>
        {splittedFormattedValue[0]}
      </div>
    ));
    if (splittedFormattedValue.length > 1) {
      valuesNodes.push((
        <div key="decimalPart" className={styles.decimalPart}>
          {`.${splittedFormattedValue[1]}`}
        </div>
      ));
    }

    return (
      <div className={styles.totalCommissionsWrapper}>
        <BlingBlingIllustration className={styles.illustration} />
        <div className={styles.label}>
          {t('userStatementAdjustments.addEditAdjustment.modal.commissions.label')}
        </div>
        <div className={styles.amount}>
          {addCurrencyToNode(valuesNodes, statementCurrency)}
        </div>
        <div className={styles.period}>
          <span className={styles.periodName}>{periodName}</span>
          {periodDates}
        </div>
      </div>
    );
  }, [statementAmount, statementCurrency, statementPeriod]);

  const titleNode = useMemo(() => {
    let title = t('userStatementAdjustments.addEditAdjustment.modal.add.title');

    if (isASuggestedCorrection) {
      title = t('userStatementAdjustments.addEditAdjustment.modal.approve.title');
    } else if (isEdition) {
      title = t('userStatementAdjustments.addEditAdjustment.modal.edit.title');
    }

    return (
      <div className={styles.titleWrapper}>
        {title}
      </div>
    );
  }, [isEdition, isASuggestedCorrection]);

  const okTextNode = useMemo(() => {
    let okText = t('userStatementAdjustments.addEditAdjustment.save.add.label');

    if (isASuggestedCorrection) {
      okText = t('userStatementAdjustments.addEditAdjustment.save.approve.label');
    } else if (isEdition) {
      okText = t('userStatementAdjustments.addEditAdjustment.save.edit.label');
    }

    return okText;
  }, [isEdition, isASuggestedCorrection]);

  const handleUpdateAdjustment = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={titleNode}
      visible={visible}
      onCancel={handleClose}
      onOk={handleUpdateAdjustment}
      okText={okTextNode}
      okIcon={isEdition ? <CheckFilled /> : <AddLine />}
      loading={addEditAdjustmentIsPending}
      disableOk={formIsPristine}
    >
      {totalCommissionsNode}
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
        onValuesChange={handleFormValuesChange}
      >
        <FormItem
          name="adjustmentAmount"
          label={t('userStatementAdjustments.addEditAdjustment.amount.label')}
          required
          rules={[
            { required: true, message: t('userStatementAdjustments.addEditAdjustment.amount.error.message') },
          ]}
        >
          <InputAmountCurrency
            placeholder="0"
            size="big"
            currency={formValues.adjustmentCurrency}
            onCurrencyChange={handleCurrencyChange}
          />
        </FormItem>
        <FormItem
          name="adjustmentReason"
          label={t('userStatementAdjustments.addEditAdjustment.reason.label')}
          required
          rules={[
            { required: true, message: t('userStatementAdjustments.addEditAdjustment.reason.error.message') },
          ]}
        >
          <Input
            placeholder={t('userStatementAdjustments.addEditAdjustment.reason.placeholder')}
            size="big"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

AddEditAdjustmentModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  statementId: PropTypes.string.isRequired,
  statementAmount: PropTypes.number.isRequired,
  statementCurrency: PropTypes.string.isRequired,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  adjustment: UserStatementAdjustmentModel.propTypes,
  correctionStatementId: PropTypes.string,
  currency: PropTypes.string,
  needRedirectOnCB: PropTypes.bool,
  isASuggestedCorrection: PropTypes.bool,
};

AddEditAdjustmentModal.defaultProps = {
  visible: false,
  onClose: () => {},
  adjustment: null,
  correctionStatementId: null,
  currency: null,
  needRedirectOnCB: false,
  isASuggestedCorrection: false,
};

export default AddEditAdjustmentModal;
