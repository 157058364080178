import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageNumberAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'MasterPlanPeriod';

export const propTypes = PropTypes.shape({
  planId: PropTypes.string,
  year: PropTypes.number,
  period: PropTypes.number,
  progress: PropTypes.number,
  target: PropTypes.number,
  usersCount: PropTypes.number,
  managersCount: PropTypes.number,
  dealsCount: PropTypes.number,
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalDealValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
});

export const transform = (masterPlanPeriod) => {
  if (!masterPlanPeriod) {
    return null;
  }

  return (
    {
      planId: manageStringAttribute(masterPlanPeriod, 'planId'),
      year: manageNumberAttribute(masterPlanPeriod, 'year'),
      period: manageNumberAttribute(masterPlanPeriod, 'period'),
      progress: managePureFloatAttribute(masterPlanPeriod, 'progress'),
      target: managePureFloatAttribute(masterPlanPeriod, 'target'),
      usersCount: manageNumberAttribute(masterPlanPeriod, 'usersCount'),
      managersCount: manageNumberAttribute(masterPlanPeriod, 'managersCount'),
      dealsCount: manageNumberAttribute(masterPlanPeriod, 'dealsCount'),
      totalCommissionAmount: manageAmountAttribute(masterPlanPeriod, 'totalCommissionAmount'),
      totalDealValue: manageAmountAttribute(masterPlanPeriod, 'totalDealValue'),
      currency: manageStringAttribute(masterPlanPeriod, 'currency'),
    }
  );
};

export const transformList = (masterPlanPeriods) => masterPlanPeriods.map((masterPlanPeriod) => transform(masterPlanPeriod));

export const merge = (masterPlanPeriod1, masterPlanPeriod2) => {
  if (!masterPlanPeriod2) return masterPlanPeriod1;
  if (!masterPlanPeriod1) return masterPlanPeriod2;

  return masterPlanPeriod2;
};
