import {
  call, put, all, takeLatest, take, select,
} from 'redux-saga/effects';

import { actions as DashboardActions } from '@palette/state/Dashboard/slice';
import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';
import { manageError as manageDashboardError } from '@palette/state/Dashboard/errors';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { DASHBOARD_EVENTS } from '@palette/constants/analytics';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as DashboardService from '@palette/services/DashboardService';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as ProfileModel from '@palette/models/Profile';

export function* addWidget({ payload = {} }) {
  const {
    widgetType,
    params,
    onSuccessCB,
  } = payload;

  const profile = yield select(ProfileSelectors.profile);

  const newComponents = ProfileModel.getDashboardComponents(profile);
  newComponents.unshift({ type: widgetType, params });

  const callResult = yield call(DashboardService.updateDashboardComponents, {
    components: newComponents,
  });

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());

    yield take(ProfileActions.getProfileCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: DASHBOARD_EVENTS.ADD_WIDGET_DRAWER, params: { type: widgetType } } });

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.DASHBOARD_ADD_WIDGET_SUCCESS));

    onSuccessCB();
  } else {
    const error = manageDashboardError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardActions.addWidgetCompleted());
}

export function* updateWidget({ payload = {} }) {
  const {
    widgetIndex,
    widgetType,
    params,
    onSuccessCB = null,
  } = payload;

  const profile = yield select(ProfileSelectors.profile);

  const newComponents = ProfileModel.getDashboardComponents(profile);
  newComponents.splice(widgetIndex, 1, { type: widgetType, params });

  const callResult = yield call(DashboardService.updateDashboardComponents, {
    components: newComponents,
  });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();
    yield put(ProfileActions.getProfile());

    yield take(ProfileActions.getProfileCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.DASHBOARD_UPDATE_WIDGET_SUCCESS));
  } else {
    const error = manageDashboardError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardActions.updateWidgetCompleted());
}

export function* getWidgetsData() {
  const callResult = yield call(DashboardService.listDashboardComponents);

  if (callResult.ok) {
    const widgetsData = DashboardComponentDataModel.transformList(callResult.data);
    yield put(DashboardActions.setWidgetsData({ widgetsData }));
  } else {
    const error = manageDashboardError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardActions.getWidgetsDataCompleted());
}

export function* moveWidget({ payload = {} }) {
  const {
    widgetIndex,
    nbOfPositions,
  } = payload;

  const profile = yield select(ProfileSelectors.profile);

  const newWidgetIndex = widgetIndex + nbOfPositions;

  const newComponents = ProfileModel.getDashboardComponents(profile);
  // Swap between former widget position and new widget position
  [newComponents[widgetIndex]] = newComponents.splice(newWidgetIndex, 1, newComponents[widgetIndex]);

  const callResult = yield call(DashboardService.updateDashboardComponents, {
    components: newComponents,
  });

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());

    yield take(ProfileActions.getProfileCompleted.type);
  } else {
    const error = manageDashboardError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardActions.moveWidgetCompleted());
}

export function* removeWidget({ payload = {} }) {
  const {
    widgetIndex,
  } = payload;

  const profile = yield select(ProfileSelectors.profile);

  const newComponents = ProfileModel.getDashboardComponents(profile);
  newComponents.splice(widgetIndex, 1);

  const callResult = yield call(DashboardService.updateDashboardComponents, {
    components: newComponents,
  });

  if (callResult.ok) {
    yield put(ProfileActions.getProfile());

    yield take(ProfileActions.getProfileCompleted.type);
  } else {
    const error = manageDashboardError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(DashboardActions.removeWidgetCompleted());
}

export function* loop() {
  yield all([
    takeLatest(DashboardActions.addWidget.type, addWidget),
    takeLatest(DashboardActions.updateWidget.type, updateWidget),
    takeLatest(DashboardActions.getWidgetsData.type, getWidgetsData),
    takeLatest(DashboardActions.moveWidget.type, moveWidget),
    takeLatest(DashboardActions.removeWidget.type, removeWidget),
  ]);
}

export function* clean() {
  yield put(DashboardActions.resetToInitialState());
}
