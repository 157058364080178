import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Disclaimer from '@palette/components/designSystem/Disclaimer/Disclaimer';
import Button from '@palette/components/designSystem/Button/Button';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './StatementRecomputeWarning.less';

const classNames = bindClassNames.bind(styles);

const StatementRecomputeWarning = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const computeAllIsPending = useSelector(MasterPlansSelectors.computeAllIsPending);

  const handleComputeAll = () => {
    dispatch(MasterPlansActions.computeAll());
  };

  const recomputeButton = useMemo(() => (
    <Button
      icon={(<RefreshLine width={18} height={18} spin={computeAllIsPending} />)}
      onClick={handleComputeAll}
      disabled={computeAllIsPending}
    >
      {t('statementRecomputeWarning.recompute')}
    </Button>
  ), [computeAllIsPending]);

  return (
    <Disclaimer
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      type="warning"
      title={t('statementRecomputeWarning.title')}
      description={t('statementRecomputeWarning.description')}
      extra={recomputeButton}
    />
  );
};

StatementRecomputeWarning.propTypes = {
  className: PropTypes.string,
};

StatementRecomputeWarning.defaultProps = {
  className: '',
};

export default StatementRecomputeWarning;
