import React from 'react';
import { useTranslation } from 'react-i18next';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { getMoment } from '@palette/helpers/MomentHelper';

import styles from './TrialBanner.less';

const TrialBanner = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!profile.userData.company || profile.userData.company.trialEndDate === null) return null;

  const moment = getMoment();
  const trialEndDateMoment = moment(profile.userData.company.trialEndDate);
  const endedAfterNow = trialEndDateMoment.isAfter(moment());

  return (
    <div className={styles.wrapper}>
      <span>{endedAfterNow ? t('trialBanner.ends.in', { duration: trialEndDateMoment.fromNow() }) : t('trialBanner.finished') }</span>
      {
        profile.userData.company.trialPaymentUrl !== null && (
          <span className={styles.linkWrapper}>
            <a
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
              href={profile.userData.company.trialPaymentUrl}
            >
              {t('trialBanner.startSubscriptionNow')}
            </a>
          </span>
        )
      }
    </div>
  );
};

export default TrialBanner;
