import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SendFeedbackModal from '@palette/components/sendFeedback/SendFeedbackModal/SendFeedbackModal';

import { MENU_MODALS } from '@palette/constants/menu';

import { actions as NavigationActions, selectors as NavigationSelectors } from '@palette/state/Navigation';

const ActionsMenuFeedbackListener = () => {
  const dispatch = useDispatch();

  const getModalVisible = useSelector(NavigationSelectors.getModalVisible);

  const isSendFeedbackModalVisible = getModalVisible === MENU_MODALS.SEND_FEEDBACK_MODAL;

  if (!isSendFeedbackModalVisible) return null;

  return <SendFeedbackModal visible onClose={() => dispatch(NavigationActions.setModalVisible(null))} />;
};

export default ActionsMenuFeedbackListener;
