import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AvatarUploader from '@palette/components/settings/AvatarUploader/AvatarUploader';
import Avatar from '@palette/components/user/Avatar/Avatar';
import Tag from '@palette/components/designSystem/Tag/Tag';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';
import Button from '@palette/components/designSystem/Button/Button';
import ArrowNewPageLine from '@palette/components/utils/Icons/ArrowNewPageLine';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { TAG_THEMES } from '@palette/constants/tag';

import { actions as ProfileActions } from '@palette/state/Profile';

import styles from './ProfilePageContent.less';

const classNames = bindClassNames.bind(styles);

const ProfilePageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profile = useProfile();

  const handleAvatarSuccessUpdated = useCallback(() => {
    dispatch(ProfileActions.getProfile());
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(ProfileActions.logout());
  }, []);

  const avatarDisplayNameNode = useMemo(() => {
    if (profile === null) return null;

    return (
      <div className={styles.avatarDisplayNameWrapper}>
        <AvatarUploader
          className={styles.avatarUpload}
          logoSrc={profile.userData.profilePictureUrl}
          defaultContent={(
            <Avatar
              className={styles.avatar}
              user={profile.userData}
              size={48}
            />
          )}
          size={48}
          editable
          onUpdated={handleAvatarSuccessUpdated}
        />
        <div className={styles.displayNameEmailWrapper}>
          <div className={styles.displayName}>
            {profile.userData.displayName}
          </div>
          <div className={styles.email}>
            {profile.userData.email}
          </div>
        </div>
      </div>
    );
  }, [profile, handleAvatarSuccessUpdated]);

  const actionsNode = useMemo(() => {
    if (profile === null) return null;

    return (
      <div className={styles.roleActionWrapper}>
        <Tag
          theme={TAG_THEMES.INFO}
          label={t('profilePageContent.role', { roleName: profile.userData.account.role?.name })}
          icon={InfoCircleFilled}
        />
        <Button
          className={styles.logout}
          type="secondary"
          icon={<ArrowNewPageLine width={14} height={14} />}
          onClick={handleLogout}
        >
          {t('profilePageContent.logout')}
        </Button>
      </div>
    );
  }, [profile, handleLogout]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.profileWrapper}>
        {avatarDisplayNameNode}
        {actionsNode}
      </div>
    </div>
  );
};

ProfilePageContent.propTypes = {
  className: PropTypes.string,
};

ProfilePageContent.defaultProps = {
  className: '',
};

export default ProfilePageContent;
