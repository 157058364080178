import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { getMoment, isDate12AM } from '@palette/helpers/MomentHelper';

import { USER_VARIABLE_TYPES } from '@palette/constants/user';

import * as UserVariableModel from '@palette/models/UserVariable';

import styles from './UserVariableValueCellContent.less';

const classNames = bindClassNames.bind(styles);

const UserVariableValueCellContent = ({
  className,
  userVariable,
  value,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const contentNode = useMemo(() => {
    switch (userVariable.type) {
      case USER_VARIABLE_TYPES.NUMBER: {
        return comaSeparatorFormatter(value);
      }
      case USER_VARIABLE_TYPES.DATE: {
        const valueMoment = moment.utc(value);
        if (isDate12AM(valueMoment, true)) {
          return valueMoment.format('ll');
        }
        return valueMoment.format('lll');
      }
      case USER_VARIABLE_TYPES.BOOLEAN: {
        if (value) {
          return (
            <CheckFilled className={styles.trueValueIcon} />
          );
        }

        return (
          <ClosePopupFilled className={styles.falseValueIcon} />
        );
      }
      case USER_VARIABLE_TYPES.ARRAY: {
        let itemsNodes = value.map((valueItem, index) => (
          <div key={index} className={styles.valueArrayItem}>
            {`- ${valueItem}`}
          </div>
        ));
        if (itemsNodes.length === 0) {
          itemsNodes = (
            <div className={styles.emptyList}>
              {t('userVariableArrayValueInput.emptyList')}
            </div>
          );
        }

        return itemsNodes;
      }
      default: {
        return value;
      }
    }
  }, [userVariable, value]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UserVariableValueCellContent.propTypes = {
  className: PropTypes.string,
  userVariable: UserVariableModel.propTypes.isRequired,
  value: UserVariableModel.propTypesShape.defaultValue.isRequired,
};

UserVariableValueCellContent.defaultProps = {
  className: '',
};

export default UserVariableValueCellContent;
