import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { formatToISOStringWithFractionalSeconds, getLocalTimezone } from '@palette/helpers/MomentHelper';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* getById(payload) {
  const { commissionId } = payload;

  const data = { commissionId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.getById, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* getByIdFromSF(payload) {
  const { commissionId } = payload;

  const data = { commissionId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.getByIdFromSF, data, getConfig()),
    [],
  );
}

export function* getByIdFromIC(payload) {
  const { commissionId } = payload;

  const data = { commissionId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.getByIdFromIC, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* overwriteAmount(payload) {
  const { commissionId, amount } = payload;

  const data = { commissionId, amount };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.overwriteAmount, data, getConfig()),
    [RIGHTS.ADMIN.COMMISSIONS.MANAGE],
  );
}

export function* overwriteValue(payload) {
  const { commissionId, value } = payload;

  const data = { commissionId, value };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.overwriteValue, data, getConfig()),
    [RIGHTS.ADMIN.COMMISSIONS.MANAGE],
  );
}

export function* updatePayoutSchedule(payload) {
  const { commissionId, payments } = payload;

  const data = { commissionId, payments };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.updatePayoutSchedule, data, getConfig()),
    [RIGHTS.ADMIN.COMMISSIONS.MANAGE],
  );
}

export function* listDealCommissions(payload) {
  const { connectorId, objectId, type } = payload;

  const data = { connectorId, objectId, type };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.listDealCommissions, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

const buildCommissionListByFiltersCallData = (payload) => {
  const {
    from = undefined,
    to = undefined,
    dealFilter = '',
    statusFilter = undefined,
    searchedUser = null,
    searchedTeam = undefined,
    searchedResourceType = null,
    searchedPlan = null,
    sortBy = undefined,
    currency = undefined,
  } = payload;

  const timezone = getLocalTimezone();

  const data = {
    timezone,
    dealFilter,
    statusFilter,
    teamFilter: searchedTeam,
    sort: sortBy,
  };

  if (from && to) {
    data.from = formatToISOStringWithFractionalSeconds(from);
    data.to = formatToISOStringWithFractionalSeconds(to, true);
  }

  if (searchedUser !== null) {
    data.userFilter = searchedUser;
  }

  if (searchedResourceType !== null) {
    data.dealTypeFilter = searchedResourceType;
  }

  if (searchedPlan !== null) {
    data.planFilter = searchedPlan;
  }

  if (currency !== null) {
    data.currency = currency;
  }

  return data;
};

export function* commissionListByFilters(payload) {
  const data = buildCommissionListByFiltersCallData(payload);

  const {
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
  } = payload;

  data.page = page;
  data.limit = limit;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.commissionListByFilters, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* commissionListICByFilters(payload) {
  const data = buildCommissionListByFiltersCallData(payload);

  const {
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
  } = payload;

  data.page = page;
  data.limit = limit;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.commissions.commissionListByFilters, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* commissionListByFiltersDashboard(payload) {
  const data = buildCommissionListByFiltersCallData(payload);

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.commissionListByFiltersDashboard, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* commissionListICByFiltersDashboard(payload) {
  const data = buildCommissionListByFiltersCallData(payload);

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.commissions.commissionListByFiltersDashboard, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* downloadCommissionList(payload) {
  const data = buildCommissionListByFiltersCallData(payload);

  data.download = true;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.commissions.commissionListByFilters, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* downloadCommissionICList(payload) {
  const data = buildCommissionListByFiltersCallData(payload);

  data.download = true;

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.ic.commissions.commissionListByFilters, data, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}
