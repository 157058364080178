import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

import * as FieldDefinitionModel from '@palette/models/FieldDefinition';
import { managePeriodicityType } from '@palette/models/PlanV3List';

export const modelName = 'PlanV3Configuration';

export const propTypes = PropTypes.shape({
  planId: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  frequency: PropTypes.oneOf(Object.keys(PLAN_PERIODICITY_TYPE)),
  startPeriod: PropTypes.string,
  endPeriod: PropTypes.string,
  dayInterval: PropTypes.number,
  usersIds: PropTypes.arrayOf(PropTypes.string),
  dataSources: PropTypes.arrayOf(PropTypes.shape({
    connectorId: PropTypes.string,
    connectorName: PropTypes.string,
    resourceType: PropTypes.string,
    columns: PropTypes.arrayOf(FieldDefinitionModel.propTypes),
  })),
});

export const transform = (configuration) => {
  if (!configuration) {
    return null;
  }

  const {
    name,
    description,
    startDate,
    endDate,
    periodLength,
    periodicity,
  } = manageFreeShapeObjectAttribute(configuration, 'requirements');

  const manageDataSources = (c) => manageArrayAttribute(c, 'dataSources').map((dataSource) => ({
    connectorId: manageStringAttribute(dataSource, 'connectorId'),
    connectorName: manageStringAttribute(dataSource.connectorName, 'name'),
    resourceType: manageStringAttribute(dataSource, 'resourceType'),
    columns: FieldDefinitionModel.transformList(manageArrayAttribute(dataSource, 'fields')),
  }));

  return (
    {
      planId: manageStringAttribute(configuration, 'planId'),
      name: manageStringAttribute({ name }, 'name'),
      description: manageStringAttribute({ description }, 'description'),
      frequency: managePeriodicityType({ periodicity }),
      startPeriod: manageDateAttribute({ startDate }, 'startDate'),
      endPeriod: manageDateAttribute({ endDate }, 'endDate'),
      dayInterval: manageNumberAttribute({ periodLength }, 'periodLength'),
      usersIds: manageArrayAttribute(configuration, 'users'),
      dataSources: manageDataSources(configuration),
    }
  );
};
