import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as StatementStrategyModel from '@palette/models/StatementStrategy';

export const modelName = 'UserStatementCorrection';

export const propTypes = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string,
  newAmount: PropTypes.number,
  correctedAmount: PropTypes.number,
  from: PropTypes.shape({
    statementId: PropTypes.string,
    periodStrategy: StatementStrategyModel.propTypes,
    year: PropTypes.number,
    period: PropTypes.number,
    periodId: PropTypes.string,
    beginDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
});

export const manageFromAttribute = (userStatementCorrection) => ({
  statementId: manageStringAttribute(userStatementCorrection, 'statementId'),
  periodStrategy: StatementStrategyModel.transform(manageFreeShapeObjectAttribute(userStatementCorrection, 'periodStrategy')),
  year: manageNumberAttribute(userStatementCorrection, 'year'),
  period: manageNumberAttribute(userStatementCorrection, 'period'),
  periodId: manageStringAttribute(userStatementCorrection, 'periodId'),
  beginDate: manageDateAttribute(userStatementCorrection, 'beginAt'),
  endDate: manageDateAttribute(userStatementCorrection, 'endAt'),
});

export const transform = (userStatementCorrection) => {
  if (!userStatementCorrection) {
    return null;
  }

  const amount = manageAmountAttribute(userStatementCorrection, 'formerAmount');
  let correctedAmount = manageAmountAttribute(userStatementCorrection, 'correctedAmount', null);
  if (correctedAmount === null) {
    correctedAmount = amount;
  }

  return ({
    amount,
    correctedAmount,
    currency: manageStringAttribute(userStatementCorrection, 'currency'),
    newAmount: manageAmountAttribute(userStatementCorrection, 'newAmount'),
    from: manageFromAttribute(userStatementCorrection),
  });
};

export const transformList = (userStatementCorrections) => userStatementCorrections.map((userStatementCorrection) => transform(userStatementCorrection));
