import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PlanV3Achievement';

export const propTypes = PropTypes.shape({
  planId: PropTypes.string,
  dealId: PropTypes.string,
  userId: PropTypes.string,
  periodId: PropTypes.string,
  targetType: PropTypes.string,
  quota: PropTypes.number,
  value: PropTypes.number,
});

export const transform = (achievement) => {
  if (!achievement) {
    return null;
  }

  return (
    {
      planId: manageStringAttribute(achievement, 'planId'),
      dealId: manageStringAttribute(achievement, 'dealId'),
      userId: manageStringAttribute(achievement, 'userId'),
      periodId: manageStringAttribute(achievement, 'periodId'),
      targetType: manageStringAttribute(achievement, 'targetType'),
      quota: manageNumberAttribute(achievement, 'quota'),
      value: manageNumberAttribute(achievement, 'value'),
    }
  );
};

export const transformList = (achievements) => achievements.map((achievement) => transform(achievement));
