import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Popover as AntDPopover } from 'antd';

import styles from './Popover.less';

const classNames = bindClassNames.bind(styles);

const Popover = ({ className, trigger, placement, ...otherProps }) => (
  <AntDPopover
    overlayClassName={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
    trigger={trigger}
    placement={placement}
    {...otherProps}
  />
);

Popover.propTypes = {
  className: PropTypes.string,
  trigger: PropTypes.oneOf(['hover', 'focus', 'click', 'contextMenu']),
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom']),
};

Popover.defaultProps = {
  className: '',
  trigger: 'click',
  placement: 'right',
};

export default Popover;
