import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import InputTableEditColumnModal from '@palette/components/designSystem/InputTableEditColumnModal/InputTableEditColumnModal';

import { INPUT_TABLE_CELL_TYPES } from '@palette/constants/global';

import styles from './InputTableHeaderCell.less';

const classNames = bindClassNames.bind(styles);

const InputTableHeaderCell = ({
  className,
  value,
  columnId,
  disableActions,
  onDeleteColumn,
  onColumnTypeChange,
  columns,
  onEditColumn,
}) => {
  const { t } = useTranslation();

  const [editColumnModalIsVisible, showEditColumnModal] = useState(false);

  const handleEditColumnName = useCallback((newColumnName = null) => {
    if (newColumnName !== null) {
      onEditColumn?.(columnId, newColumnName);
    } else {
      showEditColumnModal(false);
    }
  }, [onEditColumn]);

  const contentNode = (
    <div
      className={classNames({
        wrapper: true,
        actionsAvailable: !disableActions,
        [className]: className !== '',
      })}
    >
      <div className={styles.label}>
        {value}
      </div>
    </div>
  );

  const handleCopyColumnName = useCallback(() => {
    navigator.clipboard.writeText(value);
  }, [value]);

  const handleDeleteColumn = useCallback(() => {
    onDeleteColumn?.(columnId);
  }, [onDeleteColumn, columnId]);

  const handleUpdateColumnType = useCallback((newColumnType) => {
    onColumnTypeChange?.(columnId, newColumnType);
  }, [columnId]);

  if (disableActions) return contentNode;

  const columnMoreMenuItems = [
    {
      key: 'editColumnName',
      title: t('inputTable.editColumnName'),
      onClick: () => showEditColumnModal(true),
    },
    {
      key: 'copyColumnName',
      title: t('inputTable.copyColumnName'),
      onClick: handleCopyColumnName,
    },
    {
      key: 'columnType',
      title: t('inputTable.columnType'),
      subItems: [
        {
          key: 'columnTypeString',
          title: t('inputTable.columnType.string'),
          onClick: () => handleUpdateColumnType(INPUT_TABLE_CELL_TYPES.STRING),
        },
        {
          key: 'columnTypeNumber',
          title: t('inputTable.columnType.number'),
          onClick: () => handleUpdateColumnType(INPUT_TABLE_CELL_TYPES.NUMBER),
        },
        {
          key: 'columnTypeBoolean',
          title: t('inputTable.columnType.boolean'),
          onClick: () => handleUpdateColumnType(INPUT_TABLE_CELL_TYPES.BOOLEAN),
        },
      ],
    },
    {
      key: 'deleteColumn',
      className: styles.deleteColumnMenuItem,
      title: t('inputTable.deleteColumn'),
      onClick: handleDeleteColumn,
    },
  ];

  return (
    <>
      <MoreMenu className={styles.moreMenu} items={columnMoreMenuItems}>
        {contentNode}
      </MoreMenu>
      {editColumnModalIsVisible && (
        <InputTableEditColumnModal
          visible
          columns={columns}
          onClose={handleEditColumnName}
          columnId={columnId}
          columnName={value}
        />
      )}
    </>
  );
};

InputTableHeaderCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  columnId: PropTypes.string.isRequired,
  disableActions: PropTypes.bool,
  onDeleteColumn: PropTypes.func,
  onEditColumn: PropTypes.func,
  onColumnTypeChange: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object),
};

InputTableHeaderCell.defaultProps = {
  className: '',
  value: null,
  disableActions: false,
  onDeleteColumn: null,
  onEditColumn: null,
  onColumnTypeChange: null,
  columns: [],
};

export default InputTableHeaderCell;
