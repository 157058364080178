import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage400 = (problem) => {
  const managedErrors = {
    'folder.cannot.be.self.parent': {
      code: GLOBAL_NOTIF_REASONS.FOLDER_400_CANNOT_BE_SELF_PARENT.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage404 = (problem) => {
  const managedErrors = {
    'folder.not.found': {
      code: GLOBAL_NOTIF_REASONS.FOLDER_404_FOLDER_NOT_FOUND.code,
    },
    'parent.folder.not.found': {
      code: GLOBAL_NOTIF_REASONS.FOLDER_404_PARENT_NOT_FOUND.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage409 = (problem) => {
  const managedErrors = {
    'folder.not.empty': {
      code: GLOBAL_NOTIF_REASONS.FOLDER_409_DELETION_NOT_EMPTY.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    400: manage400,
    404: manage404,
    409: manage409,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code } = error;

    return {
      ...problem,
      message: { code },
    };
  }

  return manageGlobalError({ status, problem });
};
