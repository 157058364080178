import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';

import * as PlanV3AchievementModel from '@palette/models/PlanV3Achievement';

import styles from './PlanV3DealAchievementsPeriod.less';

const classNames = bindClassNames.bind(styles);

const PlanV3DealAchievementsPeriod = ({ className, periodId, achievementsList }) => {
  const { t } = useTranslation();

  const titleNode = useMemo(() => (
    <div className={styles.title}>
      {periodId}
    </div>
  ), [periodId]);

  const tableColumns = useMemo(() => {
    const columns = [];

    const dealIdColumn = {
      id: 'dealId',
      Header: t('editPlanPage.results.periods.table.dealId'),
      accessor: 'dealId',
      minWidth: 250,
    };

    columns.push(dealIdColumn);

    const userIdColumn = {
      id: 'userId',
      Header: t('editPlanPage.results.periods.table.userId'),
      accessor: 'userId',
      minWidth: 250,
    };

    columns.push(userIdColumn);

    const targetTypeColumn = {
      id: 'targetType',
      Header: t('editPlanPage.results.periods.table.targetType'),
      accessor: 'targetType',
      minWidth: 150,
    };

    columns.push(targetTypeColumn);

    const quotaColumn = {
      id: 'quota',
      Header: t('editPlanPage.results.periods.table.quota'),
      accessor: 'quota',
      minWidth: 150,
    };

    columns.push(quotaColumn);

    const valueColumn = {
      id: 'value',
      Header: t('editPlanPage.results.periods.table.value'),
      accessor: 'value',
      minWidth: 150,
    };

    columns.push(valueColumn);

    return columns;
  }, []);

  const tableNode = useMemo(() => {
    if (achievementsList.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <Table
        className={styles.table}
        columns={tableColumns}
        data={achievementsList}
        stickyHeader
        fitInContainer
      />
    );
  }, [
    achievementsList,
    tableColumns,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapse}
        panels={[{
          title: titleNode,
          content: tableNode,
        }]}
      />
    </div>
  );
};

PlanV3DealAchievementsPeriod.propTypes = {
  className: PropTypes.string,
  periodId: PropTypes.string.isRequired,
  achievementsList: PropTypes.arrayOf(PlanV3AchievementModel.propTypes).isRequired,
};

PlanV3DealAchievementsPeriod.defaultProps = {
  className: '',
};

export default PlanV3DealAchievementsPeriod;
