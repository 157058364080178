import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Resizable } from 'react-resizable';

import Editor from '@monaco-editor/react';

import { useWindowSize } from '@palette/hooks/CommonHooks';
import { useIsCollapsed } from '@palette/hooks/NavigationHooks';

import styles from './CodeEditor.less';

const classNames = bindClassNames.bind(styles);

const EDITOR_MIN_WIDTH = 664;
const EDITOR_MIN_HEIGHT = 200;

const CodeEditor = ({
  className,
  options,
  onMount,
  onFocus,
  onBlur,
  disabled,
  ...otherProps
}) => {
  const windowSize = useWindowSize();
  const [isCollapsed] = useIsCollapsed();

  const monacoEl = useRef(null);
  const editorRef = useRef(null);

  const [isFocus, setIsFocus] = useState(false);
  const [focusListener, setFocusListener] = useState(null);
  const [blurListener, setBlurListener] = useState(null);
  const [wrapperSize, setWrapperSize] = useState({ width: -1, height: EDITOR_MIN_HEIGHT });

  const handleWrapperResize = useCallback((event, { size }) => {
    setWrapperSize({ width: size.width, height: size.height });
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocus(true);

    if (onFocus !== null && !disabled) {
      onFocus();
    }
  }, [onFocus, disabled]);

  const handleBlur = useCallback(() => {
    setIsFocus(false);

    if (onBlur !== null && !disabled) {
      onBlur();
    }
  }, [onBlur, disabled]);

  const handleEditorDidMount = useCallback((editor, monaco) => {
    editorRef.current = editor;

    setFocusListener(editorRef.current.onDidFocusEditorWidget(handleFocus));
    setBlurListener(editorRef.current.onDidBlurEditorWidget(handleBlur));

    if (onMount !== null) onMount(editor, monaco);
  }, [onMount, handleFocus, handleBlur]);

  useEffect(() => {
    if (editorRef !== null && editorRef.current !== null) {
      if (focusListener !== null) focusListener.dispose();
      if (blurListener !== null) blurListener.dispose();

      setFocusListener(editorRef.current.onDidFocusEditorWidget(handleFocus));
      setBlurListener(editorRef.current.onDidBlurEditorWidget(handleBlur));
    }
  }, [editorRef, handleFocus, handleBlur]);

  useEffect(() => {
    if (editorRef === null || editorRef.current === null || monacoEl === null || monacoEl.current === null) return;

    editorRef.current.layout({ width: 0, height: 0 });

    window.requestAnimationFrame(() => {
      const rect = monacoEl.current.getBoundingClientRect();

      editorRef.current.layout({
        width: rect.width >= EDITOR_MIN_WIDTH ? rect.width : EDITOR_MIN_WIDTH,
        height: rect.height,
      });
    });
  }, [monacoEl, editorRef, windowSize.width, isCollapsed]);

  return (
    <Resizable
      width={wrapperSize.width}
      height={wrapperSize.height}
      onResize={handleWrapperResize}
      resizeHandles={['se']}
      minConstraints={[EDITOR_MIN_WIDTH, EDITOR_MIN_HEIGHT]}
      axis="y"
    >
      <div
        ref={monacoEl}
        className={classNames({
          wrapper: true,
          isFocus,
          isDisabled: disabled,
          [className]: className !== '',
        })}
        style={{
          height: `${wrapperSize.height}px`,
        }}
      >
        <Editor
          className={styles.editor}
          options={{
            minimap: {
              enabled: false,
            },
            scrollbar: {
              verticalScrollbarSize: '2px',
            },
            contextmenu: false,
            renderLineHighlight: 'none',
            renderLineHighlightOnlyWhenFocus: false,
            readOnly: disabled,
            ...options,
          }}
          onMount={handleEditorDidMount}
          {...otherProps}
        />
      </div>
    </Resizable>
  );
};

CodeEditor.propTypes = {
  className: PropTypes.string,
  options: PropTypes.object,
  onMount: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

CodeEditor.defaultProps = {
  className: '',
  options: {},
  onMount: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
};

export default CodeEditor;
