import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import AddPlanSelectors from '@palette/components/quotaPerformance/AddPlanSelectors/AddPlanSelectors';

import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';

import * as QuotaPerformanceModel from '@palette/models/QuotaPerformance';

import styles from './AddPlansToDashboardModal.less';

const AddPlansToDashboardModal = ({ visible, onClose, dashboard }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [planIds, setPlanIds] = useState([]);

  const addPlansToDashboardIsPending = useSelector(QuotasPerformanceSelectors.addPlansToDashboardIsPending);

  const cleanAndClose = () => {
    setPlanIds([]);
    onClose();
  };

  const handlePlanIdsChange = (ids) => setPlanIds(ids.filter((id) => id));

  const handleAddPlansToDashboard = useCallback(() => {
    if (!planIds.length) return;

    dispatch(QuotasPerformanceActions.addPlansToDashboard({
      quotaPerformanceId: dashboard.id,
      planIds,
      onSuccessCB: cleanAndClose,
    }));
  }, [planIds, dashboard, cleanAndClose]);

  return (
    <Modal
      className={styles.modal}
      title={t('quotasPerformance.addPlansToDashboard.modal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleAddPlansToDashboard}
      okText={t('quotasPerformance.addPlansToDashboard.modal.button.addPlans')}
      loading={addPlansToDashboardIsPending}
      disableOk={addPlansToDashboardIsPending || planIds.length === 0}
    >
      <AddPlanSelectors
        onPlanIdsChange={handlePlanIdsChange}
        disabled={addPlansToDashboardIsPending}
        disablePlanIds={dashboard.plans.map((plan) => plan.id)}
      />
    </Modal>
  );
};

AddPlansToDashboardModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  dashboard: QuotaPerformanceModel.propTypes.isRequired,
};

AddPlansToDashboardModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddPlansToDashboardModal;
