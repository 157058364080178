import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Select from '@palette/components/designSystem/Select/Select';
import UserVariableValueInput from '@palette/components/usersVariables/UserVariableValueInput/UserVariableValueInput';

import { convertVariableValue } from '@palette/helpers/UserVariableHelper';

import { USER_VARIABLE_TYPES } from '@palette/constants/user';
import { VARIABLE_NAME_REGEX } from '@palette/constants/resources';

import { actions as UsersVariablesActions, selectors as UsersVariablesSelectors } from '@palette/state/UsersVariables';

import styles from './CreateVariableModal.less';

const CreateVariableModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createVariableIsPending = useSelector(UsersVariablesSelectors.createVariableIsPending);

  const usersVariables = useSelector(UsersVariablesSelectors.getUsersVariables);

  const existingUserVariablesNames = useMemo(() => (
    usersVariables.variables.map((userVariable) => (userVariable.name))
  ), [usersVariables]);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    name: undefined,
    type: USER_VARIABLE_TYPES.STRING,
    defaultValue: undefined,
  }), []);

  const userVariableTypesOptions = Object.entries(USER_VARIABLE_TYPES).map(([key, value]) => ({
    label: t(`usersVariables.types.${key.toLowerCase()}`),
    value,
  }));

  const [selectedType, setSelectedType] = useState(initialValues.type);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    if (changedValues.type !== undefined) {
      if (allValues.defaultValue !== undefined) {
        const updatedDefaultValue = convertVariableValue(allValues.defaultValue, selectedType, changedValues.type);
        form.setFieldsValue({
          ...allValues,
          defaultValue: updatedDefaultValue,
        });
      }

      setSelectedType(changedValues.type);
    }

    if (changedValues.name !== undefined) {
      form.setFieldsValue({
        ...allValues,
        name: changedValues.name.replace(/\s/g, '_'),
      });
    }
  }, [form, selectedType]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const handleFinish = useCallback((values) => {
    const { name, type, defaultValue } = values;
    let finalDefaultValue = defaultValue;
    if (defaultValue == null && type === USER_VARIABLE_TYPES.ARRAY) {
      finalDefaultValue = [''];
    }
    dispatch(UsersVariablesActions.createVariable({ name, type, defaultValue: finalDefaultValue, onSuccessCB: cleanAndClose }));
  }, [cleanAndClose]);

  const handleCreateVariable = useCallback(() => form.submit(), [form]);

  return (
    <Modal
      className={styles.modal}
      blockHeight
      title={t('createVariableModal.createNewVariable')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleCreateVariable}
      okText={t('createVariableModal.createVariable')}
      loading={createVariableIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <div className={styles.formItemsGroup}>
          <FormItem
            className={styles.formItemInGroup}
            name="name"
            label={t('createVariableModal.form.name.label')}
            required
            rules={[
              { required: true, message: t('createVariableModal.form.name.rules.required'), pattern: VARIABLE_NAME_REGEX },
              () => ({
                validator(_, value) {
                  if (!value || !existingUserVariablesNames.includes(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('createVariableModal.form.name.rules.alreadyExists')));
                },
              }),
            ]}
          >
            <Input size="big" disabled={createVariableIsPending} />
          </FormItem>
          <FormItem
            className={styles.formItemInGroup}
            name="type"
            label={t('createVariableModal.form.variableType.label')}
            required
          >
            <Select size="big" options={userVariableTypesOptions} disabled={createVariableIsPending} />
          </FormItem>
        </div>
        <FormItem
          name="defaultValue"
          label={t('createVariableModal.form.variableDefaultValue.label')}
          required
          rules={[
            { required: selectedType !== USER_VARIABLE_TYPES.ARRAY, message: t('createVariableModal.form.variableDefaultValue.rules.required') },
          ]}
        >
          <UserVariableValueInput variableType={selectedType} size="big" disabled={createVariableIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

CreateVariableModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateVariableModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CreateVariableModal;
