import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _keyBy from 'lodash/keyBy';

import { getNextAddDataConnectionFlowStep } from '@palette/helpers/NotebookHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';
import {
  ADD_DATA_CONNECTION_FLOW_FIRST_STEP,
  NOTEBOOK_DATA_CONNECTION_TYPES,
  NOTEBOOK_CELL_EXPORTABLE_STATUSES,
  NOTEBOOK_CELL_TYPES,
} from '@palette/constants/notebooks';

import * as NotebookCellModel from '@palette/models/NotebookCell';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';
import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

export const useNotebookIdInParams = () => {
  const dispatch = useDispatch();

  const { notebookId } = useParams();

  const notebook = useSelector((state) => NotebooksSelectors.getNotebookById(state, { notebookId }));
  const isPending = useSelector(NotebooksSelectors.getNotebookByIdIsPending);

  useEffect(() => {
    dispatch(NotebooksActions.getNotebookById({ notebookId }));
  }, [notebookId]);

  return useMemo(() => ({
    notebookId,
    notebook,
    isPending,
  }), [
    notebookId,
    notebook,
    isPending,
  ]);
};

export const useAddDataConnectionStepValue = () => {
  const addDataConnectionCurrentFlowStep = useSelector(NotebooksSelectors.getAddDataConnectionCurrentFlowStep);

  if (addDataConnectionCurrentFlowStep === null) {
    return ADD_DATA_CONNECTION_FLOW_FIRST_STEP.value;
  }

  return addDataConnectionCurrentFlowStep.value;
};

export const useNextAddDataConnectionStep = () => {
  const addDataConnectionStepValue = useAddDataConnectionStepValue();

  const nextStep = getNextAddDataConnectionFlowStep(addDataConnectionStepValue);

  if (nextStep === null) {
    return ADD_DATA_CONNECTION_FLOW_FIRST_STEP.value;
  }

  return nextStep;
};

export const buildNewDataConnectionConnectorInfo = (newDataConnectionData, selectedResourceTypeFields, dispatch) => {
  const disableAddEditDataConnectionAction = !newDataConnectionData.connectorId
    || !newDataConnectionData.connectorName
    || !newDataConnectionData.resourceType
    || newDataConnectionData.selectedFields?.length === 0;

  const selectedResourceTypeFieldsByName = _keyBy(selectedResourceTypeFields, 'name');

  const addEditDataConnectionAction = (notebook, onSuccessCB, editedDataConnectionConnector = null) => {
    const columns = (newDataConnectionData.selectedFields || []).map((field) => (selectedResourceTypeFieldsByName[field]));

    if (editedDataConnectionConnector !== null) {
      dispatch(NotebooksActions.editDataConnectionConnector({
        notebookId: notebook.id,
        dataConnectionConnector: editedDataConnectionConnector,
        columns,
        onSuccessCB,
      }));

      return;
    }

    dispatch(NotebooksActions.addDataConnectionConnector({
      notebookId: notebook.id,
      connectorId: newDataConnectionData.connectorId,
      resourceType: newDataConnectionData.resourceType,
      columns,
      onSuccessCB,
    }));
  };

  return {
    disableAddEditDataConnectionAction,
    addEditDataConnectionAction,
  };
};

export const useNewDataConnectionInfo = () => {
  const dispatch = useDispatch();

  const newDataConnectionSource = useSelector(NotebooksSelectors.getNewDataConnectionSource);
  const newDataConnectionData = useSelector(NotebooksSelectors.getNewDataConnectionData);

  const selectedResourceTypeFields = useSelector(
    (state) => ConnectorsSelectors.getResourceFieldsByConnectorAndType(state, { connectorId: newDataConnectionData?.connectorId, resourceType: newDataConnectionData?.resourceType }),
  );

  return useMemo(() => {
    const defaultNewDataConnectionInfo = {
      disableAddEditDataConnectionAction: true,
      addEditDataConnectionAction: null,
    };

    if (newDataConnectionSource === null || newDataConnectionData === null) {
      return defaultNewDataConnectionInfo;
    }

    if (newDataConnectionSource.type !== NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR) {
      return defaultNewDataConnectionInfo;
    }

    return buildNewDataConnectionConnectorInfo(newDataConnectionData, selectedResourceTypeFields, dispatch);
  }, [
    newDataConnectionSource,
    newDataConnectionData,
    selectedResourceTypeFields,
  ]);
};

export const useCellEdition = (notebookId, cell) => {
  const dispatch = useDispatch();
  const [editableCell, setEditableCell] = useState({ ...cell });

  useEffect(() => {
    setEditableCell((previous) => ({ ...previous, ...cell }));
  }, [cell]);

  const globalError = useSelector((state) => NotebooksSelectors.getErrorsByCellId(state, {
    notebookId,
    cellId: cell.id,
  }));

  const errors = useMemo(() => {
    const isRequiredInformationError = globalError?.code === GLOBAL_NOTIF_REASONS.NOTEBOOK_CELL_REQUIRED_INFORMATION_ERROR.code;
    return {
      name: isRequiredInformationError && editableCell.name.trim().length === 0,
      outputUniqueIdentifier: isRequiredInformationError && editableCell.outputUniqueIdentifier.trim().length === 0,
    };
  }, [editableCell.name, editableCell.outputUniqueIdentifier, globalError]);

  const someRequiredFieldIsMissing = useMemo(() => {
    const assertions = [
      editableCell.name.trim().length === 0,
      editableCell.outputUniqueIdentifier.trim().length === 0,
    ];

    return assertions.some(Boolean);
  }, [editableCell]);

  const updateField = useCallback((field, value) => {
    setEditableCell((previous) => ({ ...previous, [field]: value }));
  }, [setEditableCell, editableCell]);

  const handleUpdateCellName = useCallback((name) => {
    updateField('name', name);

    const isExportable = NOTEBOOK_CELL_TYPES[editableCell.typeKey].exportableStatus === NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTABLE;
    if (isExportable) return;

    const updatedCell = NotebookCellModel.updateCellName(editableCell, name);
    dispatch(NotebooksActions.updateCell({ notebookId, updatedCell }));
  }, [updateField, editableCell]);

  const handleUpdateCellOutputName = useCallback((outputName) => {
    updateField('outputName', outputName);
  }, [updateField]);

  const handleUpdateCellOutputUniqueIdentifier = useCallback((uniqueIdentifier) => {
    updateField('outputUniqueIdentifier', uniqueIdentifier);
  }, [updateField]);

  const handleCellContentUpdate = useCallback((content) => {
    updateField('content', content);

    const isExportable = NOTEBOOK_CELL_TYPES[editableCell.typeKey].exportableStatus === NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTABLE;
    if (isExportable) return;

    const updatedCell = NotebookCellModel.updateCellContent(editableCell, content);
    dispatch(NotebooksActions.updateCell({ notebookId, updatedCell }));
  }, [updateField, editableCell]);

  const handleMakeExportable = useCallback(() => {
    updateField('status', NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTED);
  }, [updateField]);

  return {
    editableCell,
    someRequiredFieldIsMissing,
    handleCellContentUpdate,
    handleMakeExportable,
    handleUpdateCellName,
    handleUpdateCellOutputName,
    handleUpdateCellOutputUniqueIdentifier,
    errors,
  };
};
