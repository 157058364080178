import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { useStatementPeriodStatus } from '@palette/helpers/StatementV3Helper';

import { STATEMENT_PERIOD_STATUSES, STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import * as StatementV3PeriodModel from '@palette/models/StatementV3Period';
import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';

import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './StatementV3MarkAsPaidLabel.less';

const classNames = bindClassNames.bind(styles);

const StatementV3MarkAsPaidLabel = ({
  className,
  userStatement,
  currency,
  isSecondaryType,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const statementPeriodStatus = useStatementPeriodStatus(userStatement);

  const markAsPaidAction = userStatement.actions.find((action) => action.type === STATEMENT_ACTION_TYPES.PAYMENT);

  const user = useSelector((state) => UsersSelectors.getUserById(state, { userId: markAsPaidAction?.userId }));

  const statusTooltipWrapper = useCallback((children) => {
    if (!user || !markAsPaidAction) return children;

    const paidByDate = moment(markAsPaidAction.date).format('M/DD/YYYY');
    const paidByAmount = formatPrice(markAsPaidAction.amount, markAsPaidAction.currency || currency);
    const paidByUserName = user.displayName;

    return (
      <Tooltip
        title={paidByDate}
        content={(
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('statement.paidBy.tooltip.label', {
                userName: paidByUserName,
                amount: paidByAmount,
              }),
            }}
          />
        )}
      >
        <div>
          {children}
        </div>
      </Tooltip>
    );
  }, [markAsPaidAction, user, currency]);

  const contentNode = useMemo(() => {
    if (!statementPeriodStatus) return null;

    if (statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_STATUSES.IN_FUTURE;
      return statusTooltipWrapper(<Tag theme={theme} label={t(i18n)} icon={icon} />);
    }

    if (statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAID) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_USER_STATUSES.PAID;
      return statusTooltipWrapper(<Tag theme={theme} label={t(i18n)} icon={icon} />);
    }

    return null;
  }, [
    userStatement,
    statementPeriodStatus,
    statusTooltipWrapper,
    isSecondaryType,
  ]);

  const contentContainer = useMemo(() => {
    if (!contentNode) return null;

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
    );
  }, [contentNode]);

  return contentContainer;
};

StatementV3MarkAsPaidLabel.propTypes = {
  className: PropTypes.string,
  userStatement: PropTypes.oneOfType([StatementV3PeriodModel.propTypes, StatementV3OneStatementModel.propTypes]),
  currency: PropTypes.string,
  isSecondaryType: PropTypes.bool,
};

StatementV3MarkAsPaidLabel.defaultProps = {
  className: '',
  userStatement: null,
  currency: null,
  isSecondaryType: false,
};

export default StatementV3MarkAsPaidLabel;
