import PropTypes from 'prop-types';

import { manageNumberAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'BillingSubscription';

export const propTypes = PropTypes.shape({
  seats: PropTypes.number,
  portalUrl: PropTypes.string,
});

export const transform = (billingSubscription) => {
  if (!billingSubscription) {
    return null;
  }

  return (
    {
      seats: manageNumberAttribute(billingSubscription, '_seats'),
      portalUrl: manageStringAttribute(billingSubscription, 'portalUrl'),
    }
  );
};

export const transformList = (billingSubscriptions) => billingSubscriptions.map((billingSubscription) => transform(billingSubscription));
