import PropTypes from 'prop-types';

import { manageArrayAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import * as PayoutRulePaymentModel from '@palette/models/PayoutRulePayment';

export const modelName = 'PayoutRule';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  _id: PropTypes.string,
  conditionFormula: PropTypes.string,
  code: PropTypes.string,
  payments: PropTypes.arrayOf(PayoutRulePaymentModel.propTypes),
});

export const transform = (payoutRule) => {
  if (!payoutRule) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(payoutRule, '_id'),
      _id: manageStringAttribute(payoutRule, '_id', null),
      conditionFormula: manageStringAttribute(payoutRule, 'conditionFormula', 'true'),
      code: manageStringAttribute(payoutRule, 'code'),
      payments: PayoutRulePaymentModel.transformList(manageArrayAttribute(payoutRule, 'payments')),
    }
  );
};

export const transformList = (payoutRules) => payoutRules.map((payoutRule) => transform(payoutRule));

export const merge = (rule1, rule2) => {
  if (!rule2) return rule1;
  if (!rule1) return rule2;

  return rule2;
};
