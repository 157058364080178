import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage401 = (problem) => {
  const { detail = {} } = problem.additionalData;
  const { remaining = null } = detail;

  let { code } = GLOBAL_NOTIF_REASONS.PROFILE_ACCOUNT_LOCKED;
  let context;

  if (remaining !== null) {
    code = GLOBAL_NOTIF_REASONS.PROFILE_ACCOUNT_INVALID_CREDENTIALS.code;
    context = { remaining };
  }

  const managedErrors = {
    'failed.login.attempt': {
      code,
      context,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage404 = (problem) => {
  const managedErrors = {
    'user.not.found': {
      code: GLOBAL_NOTIF_REASONS.PROFILE_404_USER_NOT_FOUND.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage429 = (problem) => {
  const managedErrors = {
    'too.many.failed.login.attempts': {
      code: GLOBAL_NOTIF_REASONS.PROFILE_ACCOUNT_LOCKED.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    401: manage401,
    404: manage404,
    429: manage429,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code, context } = error;

    return {
      ...problem,
      message: { code, context },
    };
  }

  return manageGlobalError({ status, problem });
};
