import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddLine from '@palette/components/utils/Icons/AddLine';
import LinkResourcesToUserModal from '@palette/components/userDetails/LinkResourcesToUserModal/LinkResourcesToUserModal';

import * as MetaUserModel from '@palette/models/MetaUser';

const LinkResourcesToUser = ({ className, user }) => {
  const { t } = useTranslation();
  const [linkResourcesToUserModalIsVisible, showLinkResourcesToUserModal] = useState(false);

  return (
    <div className={className}>
      <Button
        type="secondary"
        icon={<AddLine />}
        onClick={() => showLinkResourcesToUserModal(true)}
      >
        {t('linkResourcesToUser.label')}
      </Button>
      {
        linkResourcesToUserModalIsVisible && (
          <LinkResourcesToUserModal visible onClose={() => showLinkResourcesToUserModal(false)} user={user} />
        )
      }
    </div>
  );
};

LinkResourcesToUser.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

LinkResourcesToUser.defaultProps = {
  className: '',
};

export default LinkResourcesToUser;
