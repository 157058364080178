import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import routePaths from '@palette/config/routePaths';

import * as PlanGeneralSettingsUpdateStrategyModel from '@palette/models/statementHistoryStrategies/PlanGeneralSettingsUpdateStrategy';

const classNames = bindClassNames.bind();

const PlanGeneralSettingsUpdateContentStrategy = ({ className, details, i18nAction }) => {
  const { planId, planName } = details;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey={`statement.history.list.content.${i18nAction}`}
        values={{ name: planName }}
        components={[
          <Link
            key={i18nAction}
            path={routePaths.v2.planSettings}
            params={{ masterPlanId: planId }}
          />,
        ]}
      />
    </span>
  );
};

PlanGeneralSettingsUpdateContentStrategy.propTypes = {
  className: PropTypes.string,
  details: PlanGeneralSettingsUpdateStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

PlanGeneralSettingsUpdateContentStrategy.defaultProps = {
  className: '',
};

export default PlanGeneralSettingsUpdateContentStrategy;
