import PropTypes from 'prop-types';
import _invert from 'lodash/invert';
import _sortBy from 'lodash/sortBy';

import {
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { NOTEBOOK_DATA_CONNECTION_TYPES, NOTEBOOK_STATUSES, NOTEBOOK_TYPES } from '@palette/constants/notebooks';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as NotebookDataConnectionModel from '@palette/models/NotebookDataConnection';
import * as NotebookCellModel from '@palette/models/NotebookCell';

export const modelName = 'Notebook';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.oneOf(Object.values(NOTEBOOK_TYPES)),
  status: PropTypes.oneOf(Object.values(NOTEBOOK_STATUSES)),
  name: PropTypes.string,
  description: PropTypes.string,
  owner: MetaUserModel.propTypes,
  cells: PropTypes.arrayOf(NotebookCellModel.propTypes),
  dataConnections: PropTypes.arrayOf(NotebookDataConnectionModel.propTypes),
  creationDate: PropTypes.string,
  lastModificationDate: PropTypes.string,
});

export const transform = (notebook) => {
  if (!notebook) {
    return null;
  }

  let type = NOTEBOOK_TYPES.CLASSIC;
  const foundType = _invert(NOTEBOOK_TYPES)[manageStringAttribute(notebook, 'type')];
  if (foundType) {
    type = NOTEBOOK_TYPES[foundType];
  }

  let status = NOTEBOOK_STATUSES.ACTIVE;
  const foundStatus = _invert(NOTEBOOK_STATUSES)[manageStringAttribute(notebook, 'status')];
  if (foundStatus) {
    status = NOTEBOOK_STATUSES[foundStatus];
  }

  const id = manageStringAttribute(notebook, '_id', null) || manageStringAttribute(notebook, 'id', null);

  const cells = NotebookCellModel.transformList(manageArrayAttribute(notebook, 'codeCells'));
  const sortedCells = _sortBy(cells, ['position']);

  const connectorDefinitions = manageArrayAttribute(notebook, 'connectorDefinitions');
  const dataConnections = NotebookDataConnectionModel.transformList(
    connectorDefinitions.map((connectorDefinition) => ({ dataConnectionType: NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR, ...connectorDefinition })),
  );

  return (
    {
      id,
      type,
      status,
      name: manageStringAttribute(notebook, 'name'),
      description: manageStringAttribute(notebook, 'description'),
      owner: MetaUserModel.transform(manageFreeShapeObjectAttribute(notebook, 'owner', null)),
      cells: sortedCells,
      dataConnections,
      creationDate: manageDateAttribute(notebook, 'creationDate'),
      lastModificationDate: manageDateAttribute(notebook, 'lastModificationDate'),
    }
  );
};

export const transformList = (notebooks) => notebooks.map((notebook) => transform(notebook));
