import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PeriodsPicker from '@palette/components/frequencies/PeriodsPicker/PeriodsPicker';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import {
  PERIODS_FILTER_CUSTOM_TYPE_PERIODS_QS_KEY,
  PERIODS_FILTER_TYPE,
  PERIODS_FILTER_TYPE_IDS,
  PERIODS_FILTER_TYPE_QS_KEY,
} from '@palette/constants/masterPlans';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import { useMasterPlanPeriodsFilters } from '@palette/hooks/MasterPlanHooks';

import { getPeriodsFilters, getPlanFirstPeriod, getPlanLastPeriod } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as AnalyticsActions } from '@palette/state/Analytics';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './MasterPlanPeriodsFilter.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodsFilter = ({
  className,
  plan,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [periodsFilterType, customFilterPeriods] = useMasterPlanPeriodsFilters(plan, false);
  const [customFilterPeriodsValue, setCustomFilterPeriodsValue] = useState(customFilterPeriods);

  const handleFromPeriodUpdate = useCallback((newFrom) => {
    setCustomFilterPeriodsValue({
      ...customFilterPeriodsValue,
      fromPeriod: newFrom,
    });
  }, [customFilterPeriodsValue]);

  const handleToPeriodUpdate = useCallback((newTo) => {
    setCustomFilterPeriodsValue({
      ...customFilterPeriodsValue,
      toPeriod: newTo,
    });
  }, [customFilterPeriodsValue]);

  useEffect(() => {
    const fromPeriod = customFilterPeriodsValue?.fromPeriod || null;
    const toPeriod = customFilterPeriodsValue?.toPeriod || null;
    if (fromPeriod !== null && toPeriod !== null) {
      const qsObject = {
        [PERIODS_FILTER_CUSTOM_TYPE_PERIODS_QS_KEY]: customFilterPeriodsValue,
      };
      dispatch(NavigationActions.addQSToLocation({ qsObject }));
    }
  }, [customFilterPeriodsValue]);

  const handlePeriodFilterTypeChange = useCallback((filterKey) => {
    const filterType = PERIODS_FILTER_TYPE[filterKey];
    const filterTypeId = PERIODS_FILTER_TYPE_IDS[filterType];
    if (periodsFilterType !== filterTypeId) {
      const qsObject = {
        [PERIODS_FILTER_TYPE_QS_KEY]: filterTypeId,
      };
      const keysToDelete = [PERIODS_FILTER_CUSTOM_TYPE_PERIODS_QS_KEY];
      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject, keysToDelete }));

      dispatch(AnalyticsActions.sendEvent({
        event: MASTER_PLANS_EVENTS.PERIOD_LIST.CLICK_VIEW_BY,
        params: {
          viewByType: filterType,
        },
      }));
    }
  }, [periodsFilterType]);

  const filtersBtnNodes = useMemo(() => {
    const planPeriodFilters = getPeriodsFilters(plan);

    return Object.keys(planPeriodFilters).map((filterKey) => {
      const isPressed = periodsFilterType === PERIODS_FILTER_TYPE[filterKey];

      let buttonLabel = t(`masterPlanPeriodsFilter.${filterKey.toLowerCase()}`);
      if (plan.fiscalYearShift > 0 && PERIODS_FILTER_TYPE[filterKey] === PERIODS_FILTER_TYPE.YEAR_TO_DATE) {
        buttonLabel = `${buttonLabel} (${t('fiscalYear.FY')})`;
      }

      return (
        <Button
          key={filterKey}
          className={styles.filterBtn}
          type="flat"
          pressed={isPressed}
          onClick={() => handlePeriodFilterTypeChange(filterKey)}
          disabled={disabled}
        >
          {buttonLabel}
        </Button>
      );
    });
  }, [plan, disabled, periodsFilterType, handlePeriodFilterTypeChange]);

  if (plan.periodType === PERIOD_TYPES.CUSTOM) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.label}>
        {t('masterPlanPeriodsFilter.viewBy')}
      </div>
      <div className={styles.filters}>
        {filtersBtnNodes}
        {
          (periodsFilterType === PERIODS_FILTER_TYPE.CUSTOM) && (
            <PeriodsPicker
              className={styles.periodsPicker}
              periodType={plan.periodType}
              frequency={plan.frequency}
              fiscalYearShift={plan.fiscalYearShift}
              beginDate={plan.customBeginAt}
              fromPeriod={customFilterPeriodsValue?.fromPeriod || null}
              toPeriod={customFilterPeriodsValue?.toPeriod || null}
              onFromPeriodUpdate={handleFromPeriodUpdate}
              onToPeriodUpdate={handleToPeriodUpdate}
              disabled={disabled}
              defaultInitialPeriod={null}
              disableBeforePeriod={getPlanFirstPeriod(plan)}
              disableAfterPeriod={getPlanLastPeriod(plan)}
            />
          )
        }
      </div>
    </div>
  );
};

MasterPlanPeriodsFilter.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  disabled: PropTypes.bool,
};

MasterPlanPeriodsFilter.defaultProps = {
  className: '',
  disabled: false,
};

export default MasterPlanPeriodsFilter;
