import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Tag from '@palette/components/designSystem/Tag/Tag';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useStatementPeriodStatus, useStatementUserIdInParams } from '@palette/hooks/StatementHooks';

import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';
import { getStatementUserApprovalStatus, statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_PERIOD_STATUSES, STATEMENT_PERIOD_USER_STATUSES, STATEMENT_APPROVAL_STATUSES } from '@palette/constants/statements';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import { selectors as StatementsSelectors, actions as StatementsActions } from '@palette/state/Statements';

import styles from './StatementApproveAction.less';

const classNames = bindClassNames.bind(styles);

const StatementApproveAction = ({
  className,
  userStatement,
  statementPeriod,
  currency,
  isForIC,
  isSecondaryType,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const approveStatementsIsPending = useSelector(StatementsSelectors.approveStatementsIsPending);
  const cancelStatementApprovalIsPending = useSelector(StatementsSelectors.cancelStatementApprovalIsPending);
  const approvalIsPending = useSelector((state) => StatementsSelectors.getApprovalIsPending(state, { userStatementId: userStatement.id }));
  const approvalIsWaiting = useSelector((state) => StatementsSelectors.getApprovalIsWaiting(state, { userStatementId: userStatement.id }));

  const statementPeriodStatus = useStatementPeriodStatus(statementPeriod);
  const { userId } = useStatementUserIdInParams();

  const handleApprove = useCallback(() => {
    dispatch(StatementsActions.approveStatements({
      statementIds: [userStatement.id],
      statementPeriodId: statementPeriod.id,
      currency,
      userId,
      isForIC,
    }));
  }, [userStatement, statementPeriod, currency, userId, isForIC]);

  const handleCancelApproval = useCallback(() => {
    dispatch(StatementsActions.cancelStatementApproval({
      statementId: userStatement.id,
      statementPeriodId: statementPeriod.id,
      currency,
      userId,
      isForIC,
    }));
  }, [userStatement, statementPeriod, currency, userId, isForIC]);

  const contentNode = useMemo(() => {
    const isFarInFuture = statementPeriodIsInFuture(statementPeriod);

    if (
      !statementPeriodStatus
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAID
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.EMPTY_STATEMENTS
      || ( // far in the future
        statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE
        && isFarInFuture
      )
      || userStatement.isPaid
    ) return null;

    const statementUserApprovalStatus = getStatementUserApprovalStatus(userStatement);

    if (statementUserApprovalStatus === STATEMENT_APPROVAL_STATUSES.APPROVED) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_USER_STATUSES.APPROVED;

      return (
        <div className={styles.approvedWrapper}>
          <Tag
            theme={theme}
            label={t(i18n)}
            icon={icon}
          />
          <Popconfirm
            title={t('statementPeriodUsers.cancelApproval.button.popconfirm')}
            onConfirm={handleCancelApproval}
            okText={t('common.global.yes')}
            cancelText={t('common.global.no')}
            size="small"
            disabled={cancelStatementApprovalIsPending || userStatement.isDirty}
          >
            <Button
              className={styles.cancelApprovalBtn}
              icon={(<ClosePopupFilled className={styles.cancelApprovalBtnIcon} width={16} height={16} />)}
              disabled={cancelStatementApprovalIsPending || userStatement.isDirty}
            />
          </Popconfirm>
        </div>
      );
    }

    const needReapproval = statementUserApprovalStatus === STATEMENT_APPROVAL_STATUSES.IN_CONFLICT;
    const typeBtnLabel = needReapproval ? 'reapprove' : 'approve';

    let btnLabel = t(`statementPeriodUsers.${typeBtnLabel}.button.label`);
    if (approvalIsWaiting) {
      btnLabel = t('statementPeriodUsers.approve.button.waitingLabel');
    }
    if (approvalIsPending) {
      btnLabel = t('statementPeriodUsers.approve.button.processingLabel');
    }

    let buttonType = isSecondaryType ? 'infoSecondary' : 'primaryBlue';
    if (needReapproval) {
      buttonType = isSecondaryType ? 'warningSecondary' : 'warning';
    }

    return (
      <Popconfirm
        title={t(`statementPeriodUsers.${typeBtnLabel}.button.popconfirm`)}
        onConfirm={handleApprove}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
        disabled={approveStatementsIsPending || userStatement.isDirty}
      >
        <Button
          className={classNames({ smallButton: isSecondaryType })}
          type={buttonType}
          icon={(approvalIsPending || approvalIsWaiting) ? <LoadingLine spin /> : <CheckFilled />}
          disabled={approveStatementsIsPending || userStatement.isDirty}
        >
          {btnLabel}
        </Button>
      </Popconfirm>
    );
  }, [
    userStatement,
    statementPeriodStatus,
    statementPeriod,
    approveStatementsIsPending,
    cancelStatementApprovalIsPending,
    approvalIsPending,
    approvalIsWaiting,
    handleCancelApproval,
    handleApprove,
    isSecondaryType,
  ]);

  const contentContainer = useMemo(() => {
    if (!contentNode) return null;

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
    );
  }, [contentNode]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return contentContainer;
};

StatementApproveAction.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
  statementPeriod: StatementPeriodModel.propTypes,
  currency: PropTypes.string,
  isForIC: PropTypes.bool,
  isSecondaryType: PropTypes.bool,
};

StatementApproveAction.defaultProps = {
  className: '',
  userStatement: null,
  statementPeriod: null,
  currency: null,
  isForIC: false,
  isSecondaryType: false,
};

export default StatementApproveAction;
