import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _keyBy from 'lodash/keyBy';

import Button from '@palette/components/designSystem/Button/Button';
import NotebookDataframePreview from '@palette/components/notebooks/NotebookDataframePreview/NotebookDataframePreview';

import * as ConnectorModel from '@palette/models/Connector';
import * as ResourceModel from '@palette/models/Resource';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './DataConnectionConnectorPreview.less';

const classNames = bindClassNames.bind(styles);

const DataConnectionConnectorPreview = ({ className, connector, resource, fields }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedResourceTypeFields = useSelector(
    (state) => ConnectorsSelectors.getResourceFieldsByConnectorAndType(state, { connectorId: connector.id, resourceType: resource.originalType }),
  );

  const previewDataConnectionConnectorResultsIsPending = useSelector(NotebooksSelectors.previewDataConnectionConnectorResultsIsPending);
  const previewDataConnectionConnectorResults = useSelector(NotebooksSelectors.getPreviewDataConnectionConnectorResults);

  const columns = useMemo(() => {
    if (selectedResourceTypeFields.length === 0) return [];

    const selectedResourceTypeFieldsByName = _keyBy(selectedResourceTypeFields, 'name');
    return fields.map((field) => (selectedResourceTypeFieldsByName[field]));
  }, [fields, selectedResourceTypeFields]);

  const getPreview = useCallback(() => {
    dispatch(NotebooksActions.previewDataConnectionConnectorResults({
      connectorId: connector.id,
      resourceType: resource.originalType,
      columns,
    }));
  }, [
    connector,
    resource,
    columns,
  ]);

  useEffect(() => {
    if (previewDataConnectionConnectorResults !== null) {
      getPreview();
    }
  }, [
    connector,
    resource,
    columns,
  ]);

  const previewButtonNode = useMemo(() => {
    if (previewDataConnectionConnectorResults !== null) return null;

    return (
      <Button
        type="secondary"
        onClick={getPreview}
      >
        {t('dataConnectionConnectorPreview.previewData')}
      </Button>
    );
  }, [previewDataConnectionConnectorResults, getPreview]);

  const previewNode = useMemo(() => (
    <NotebookDataframePreview
      isLoading={previewDataConnectionConnectorResultsIsPending}
      dataframe={previewDataConnectionConnectorResults}
    />
  ), [
    previewDataConnectionConnectorResultsIsPending,
    previewDataConnectionConnectorResults,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {previewButtonNode}
      {previewNode}
    </div>
  );
};

DataConnectionConnectorPreview.propTypes = {
  className: PropTypes.string,
  connector: ConnectorModel.propTypes.isRequired,
  resource: ResourceModel.propTypes.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

DataConnectionConnectorPreview.defaultProps = {
  className: '',
};

export default DataConnectionConnectorPreview;
