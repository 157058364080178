import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Input from '@palette/components/designSystem/Input/Input';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import UserVariableArrayValueInput from '@palette/components/usersVariables/UserVariableArrayValueInput/UserVariableArrayValueInput';

import { getMoment } from '@palette/helpers/MomentHelper';

import { USER_VARIABLE_TYPES } from '@palette/constants/user';

import * as UserVariableModel from '@palette/models/UserVariable';

import styles from './UserVariableValueInput.less';

const classNames = bindClassNames.bind(styles);

const UserVariableValueInput = ({
  className,
  variableType,
  value,
  onChange,
  disabled,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const moment = getMoment();

  switch (variableType) {
    case USER_VARIABLE_TYPES.STRING: {
      return (
        <Input
          className={classNames({
            wrapper: true,
            [className]: className !== '',
          })}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...otherProps}
        />
      );
    }
    case USER_VARIABLE_TYPES.NUMBER: {
      return (
        <Input
          className={classNames({
            wrapper: true,
            [className]: className !== '',
          })}
          value={value}
          onChange={onChange}
          disabled={disabled}
          type="number"
          {...otherProps}
        />
      );
    }
    case USER_VARIABLE_TYPES.DATE: {
      const valueMoment = value !== null ? moment.utc(value) : null;
      const handleDateChange = (newDate, newDateString) => {
        if (onChange !== null) onChange(moment.utc(newDateString).toISOString());
      };
      return (
        <DatePicker
          className={classNames({
            wrapper: true,
            [className]: className !== '',
          })}
          picker="date"
          allowClear={false}
          disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
          showTime
          showNow={false}
          value={valueMoment}
          onChange={handleDateChange}
          disabled={disabled}
          {...otherProps}
        />
      );
    }
    case USER_VARIABLE_TYPES.BOOLEAN: {
      return (
        <Input
          className={classNames({
            wrapper: true,
            [className]: className !== '',
          })}
          type="radio"
          radioValues={[
            {
              label: t('common.global.true').toLowerCase(),
              value: true,
            },
            {
              label: t('common.global.false').toLowerCase(),
              value: false,
            },
          ]}
          radioAlign="col"
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...otherProps}
        />
      );
    }
    case USER_VARIABLE_TYPES.ARRAY: {
      return (
        <UserVariableArrayValueInput
          className={classNames({
            wrapper: true,
            [className]: className !== '',
          })}
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...otherProps}
        />
      );
    }
    default:
      return null;
  }
};

UserVariableValueInput.propTypes = {
  className: PropTypes.string,
  variableType: PropTypes.oneOf(Object.values(USER_VARIABLE_TYPES)).isRequired,
  value: UserVariableModel.propTypesShape.defaultValue,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

UserVariableValueInput.defaultProps = {
  className: '',
  value: null,
  onChange: null,
  disabled: false,
};

export default UserVariableValueInput;
