import Axios from 'axios';
import { BehaviorSubject } from 'rxjs';

import _cloneDeep from 'lodash/cloneDeep';

import { getSagaMiddleware } from '@palette/helpers/SagasHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as ProfileModel from '@palette/models/Profile';

import { refreshProfile as refreshProfileSaga, refreshProfileShouldDisplayNPS as refreshProfileShouldDisplayNPSSaga } from '@palette/state/Profile/sagas';
import { cleanAll } from '@palette/state/Exceptions/sagas';

import { API_URL } from './constants';
import {
  setSessionId,
  deleteCookie,
  setCookie,
} from './utils';

const profile$$ = new BehaviorSubject();

export const isConnected = () => localStorage.getItem('sessionId')
  && localStorage.getItem('sessionId') != null
  && localStorage.getItem('sessionId') !== 'undefined';

export const refreshProfile = (newProfile) => {
  const transformedProfile = ProfileModel.transform(newProfile);
  profile$$.next(transformedProfile);
  getSagaMiddleware().run(refreshProfileSaga, { profile: transformedProfile });

  return transformedProfile;
};

export const logout = (cb) => Axios.post(`${API_URL}/users/logout`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
})
  .finally(() => {
    localStorage.clear();
    sessionStorage.clear();
    deleteCookie('sessionId');
    refreshProfile(null);

    getSagaMiddleware().run(cleanAll);

    if (cb) cb();
  });

export const resetPassword = (password, resetPasswordToken) => Axios.post(`${API_URL}/users/reset-password`, { password, resetPasswordToken }).then((data) => {
  setSessionId(data.data.sessionId);
});

export const getProfile = () => Axios.post(`${API_URL}/users/get-profile`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
})
  .then((getProfileRep) => {
    setCookie('sessionId', localStorage.getItem('sessionId'));

    // Keep 'shouldDisplayNPS' value if already available in the current profile value
    const profile = profile$$.getValue();
    if (profile) {
      // eslint-disable-next-line no-param-reassign
      getProfileRep.data.shouldDisplayNPS = profile.shouldDisplayNPS;
    }

    return refreshProfile(getProfileRep.data);
  })
  .catch((err) => {
    if (err.response.status === 503) {
      if (window.location.href.indexOf(routePaths.v2.maintenance) === -1) {
        redirectTo({ path: routePaths.v2.maintenance });
      }
    }

    throw err;
  });

export const shouldDisplayNPS = () => Axios.post(`${API_URL}/users/should-display-nps`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
})
  .then((shouldDisplayNPSRep) => {
    const profile = _cloneDeep(profile$$.getValue());

    // eslint-disable-next-line no-param-reassign
    profile.shouldDisplayNPS = shouldDisplayNPSRep.data;

    getSagaMiddleware().run(refreshProfileShouldDisplayNPSSaga, { shouldDisplayNPS: shouldDisplayNPSRep.data });
    profile$$.next(profile);
  });

export const updateProfile = (params) => Axios.post(`${API_URL}/users/update-profile`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const sendFeedback = (params) => Axios.post(`${API_URL}/users/send-product-feedback`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const sendNPSFeedback = (params) => Axios.post(`${API_URL}/users/send-nps`, params, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
})
  .then(() => {
    const profile = _cloneDeep(profile$$.getValue());
    // eslint-disable-next-line no-param-reassign
    profile.shouldDisplayNPS = false;

    getSagaMiddleware().run(refreshProfileShouldDisplayNPSSaga, { shouldDisplayNPS: false });
    profile$$.next(profile);
  });

export const profile$ = profile$$;
