import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import pluralize from 'pluralize';

import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import MasterPlanPeriodUsers from '@palette/components/masterPlanPeriod/MasterPlanPeriodUsers/MasterPlanPeriodUsers';
import MasterPlanUGByUPeriodUsers from '@palette/components/masterPlanPeriod/MasterPlanUGByUPeriodUsers/MasterPlanUGByUPeriodUsers';
import MasterPlanPeriodDeals from '@palette/components/masterPlanPeriod/MasterPlanPeriodDeals/MasterPlanPeriodDeals';

import { getCleanLocationSearchNewLocation } from '@palette/helpers/NavigationHelper';
import { isUserGroupByUserPlan } from '@palette/helpers/MasterPlanHelper';

import { PERIOD_TABS_QS_KEY, PERIOD_TABS_IDS } from '@palette/constants/tabs';
import {
  SCOPES,
  SEARCHED_DEAL_QS_KEY,
  SEARCHED_PAYEE_QS_KEY,
  SEARCHED_USER_QS_KEY,
} from '@palette/constants/masterPlans';
import { LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './MasterPlanPeriodTabs.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodTabs = ({ className, plan, period }) => {
  const { t } = useTranslation();

  const dealsTabBeforeUpdateLocation = (location) => getCleanLocationSearchNewLocation(location, [
    PAGE_QS_KEY,
    LIMIT_QS_KEY,
    SEARCHED_DEAL_QS_KEY,
    SEARCHED_USER_QS_KEY,
    SEARCHED_PAYEE_QS_KEY,
  ]);

  const tabs = useMemo(() => {
    if (isUserGroupByUserPlan(plan)) {
      const tabsDef = [
        {
          tabKey: PERIOD_TABS_IDS.USERS,
          title: t('masterPlanPeriodTabs.tabs.users'),
          titleCount: period.usersCount,
          content: (<MasterPlanUGByUPeriodUsers plan={plan} period={period} />),
          defaultActive: true,
        },
      ];

      if (plan.scope === SCOPES.MANAGER) {
        tabsDef[0].defaultActive = false;
        const managersTab = {
          tabKey: PERIOD_TABS_IDS.MANAGERS,
          title: t('masterPlanPeriodTabs.tabs.managers'),
          titleCount: period.managersCount,
          content: (<MasterPlanUGByUPeriodUsers plan={plan} period={period} forManagers />),
          defaultActive: true,
        };
        tabsDef.unshift(managersTab);
      }

      return tabsDef;
    }

    const tabsDef = [
      {
        tabKey: PERIOD_TABS_IDS.USERS,
        title: t('masterPlanPeriodTabs.tabs.users'),
        titleCount: period.usersCount,
        content: (<MasterPlanPeriodUsers plan={plan} period={period} />),
        defaultActive: true,
      },
      {
        tabKey: PERIOD_TABS_IDS.DEALS,
        title: pluralize(plan.trackingObject?.type || ''),
        titleCount: period.dealsCount,
        content: (<MasterPlanPeriodDeals plan={plan} period={period} />),
        beforeUpdateLocation: dealsTabBeforeUpdateLocation,
      },
    ];

    if (plan.scope === SCOPES.MANAGER) {
      tabsDef[0].defaultActive = false;
      const managersTab = {
        tabKey: PERIOD_TABS_IDS.MANAGERS,
        title: t('masterPlanPeriodTabs.tabs.managers'),
        titleCount: period.managersCount,
        content: (<MasterPlanPeriodUsers plan={plan} period={period} forManagers />),
        defaultActive: true,
      };
      tabsDef.unshift(managersTab);
    }

    return tabsDef;
  }, [plan, period]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        tabs={tabs}
        qsTabKey={PERIOD_TABS_QS_KEY}
      />
    </div>
  );
};

MasterPlanPeriodTabs.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
};

MasterPlanPeriodTabs.defaultProps = {
  className: '',
};

export default MasterPlanPeriodTabs;
