import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { STATUSES } from '@palette/constants/commissions';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as ResourceObjectModel from '@palette/models/ResourceObject';
import * as CommissionScopeParamsModel from '@palette/models/CommissionScopeParams';
import * as MetaUserModel from '@palette/models/MetaUser';
import * as PaymentModel from '@palette/models/Payment';
import * as CommissionBracketModel from '@palette/models/CommissionBracket';
import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'Commission';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  key: PropTypes.string,
  planId: PropTypes.string,
  plan: MasterPlanModel.propTypes,
  resourceObjectId: PropTypes.string,
  resourceObject: ResourceObjectModel.propTypes,
  scope: PropTypes.object,
  scopeParams: CommissionScopeParamsModel.propTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overwrittenValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  overwrittenAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  payee: MetaUserModel.propTypes,
  payments: PropTypes.arrayOf(PaymentModel.propTypes),
  progress: PropTypes.number,
  brackets: PropTypes.arrayOf(CommissionBracketModel.propTypes),
  ruleId: PropTypes.string,
  commentsCount: PropTypes.number,
  period: YearPeriodModel.propTypes,
  paymentsStatus: PropTypes.oneOf(Object.values(STATUSES)),
  paymentsPercentage: PropTypes.number,
  currency: PropTypes.string,
});

export const managePayments = (payments) => {
  if (payments === null) return null;
  return PaymentModel.transformList(payments);
};

export const manageStatus = (commission) => (STATUSES[manageStringAttribute(commission, 'paymentsStatus')] || STATUSES.ON_HOLD);

export const transform = (commission) => {
  if (!commission) {
    return null;
  }

  let plan = null;
  let planId = manageStringAttribute(commission, 'plan', null, true);
  if (planId === null) {
    plan = MasterPlanModel.transform(commission.plan);
    planId = plan?.id || '';
  }

  let resourceObject = null;
  let resourceObjectId = manageStringAttribute(commission, 'object', null, true);
  if (resourceObjectId === null) {
    resourceObject = ResourceObjectModel.transform(commission.object);
    resourceObjectId = resourceObject?.id || '';
  }

  let payeeInfos = {
    mongoCollection: 'CObject',
    ...(commission.scopeParams?.payee || {}),
  };
  if (commission.user) {
    payeeInfos = {
      mongoCollection: 'User',
      ...commission.user,
    };
  }
  if (typeof (commission.payee) !== 'string') {
    payeeInfos = commission.payee;
  }

  const rawCurrency = manageStringAttribute(commission, 'currency', null);
  const scopeParams = CommissionScopeParamsModel.transform(commission.scopeParams);
  const currency = rawCurrency || scopeParams?.plan?.currency;

  return (
    {
      id: manageStringAttribute(commission, '_id'),
      key: manageStringAttribute(commission, 'key'),
      planId,
      plan,
      resourceObjectId,
      resourceObject,
      scope: manageFreeShapeObjectAttribute(commission, 'scope'),
      scopeParams,
      value: manageAmountAttribute(commission, 'value'),
      overwrittenValue: manageAmountAttribute(commission, 'overwrittenValue', null),
      amount: manageAmountAttribute(commission, 'amount'),
      overwrittenAmount: manageAmountAttribute(commission, 'overwrittenAmount', null),
      date: manageDateAttribute(commission, 'date'),
      payee: MetaUserModel.transform(payeeInfos),
      cantUpdateMine: manageBooleanAttribute(commission, 'cantUpdateMine'),
      payments: managePayments(manageArrayAttribute(commission, 'payments', null)),
      progress: managePureFloatAttribute(commission, 'progress'),
      brackets: CommissionBracketModel.transformList(manageArrayAttribute(commission, 'brackets')),
      ruleId: manageStringAttribute(commission, 'rule', null),
      commentsCount: manageNumberAttribute(commission, 'commentsCount', null),
      period: YearPeriodModel.transform(commission.period),
      paymentsStatus: manageStatus(commission),
      paymentsPercentage: manageNumberAttribute(commission, 'paymentsPercentage', null),
      currency,
    }
  );
};

export const transformList = (commissions) => commissions.map((commission) => transform(commission));

export const merge = (commission1, commission2) => {
  if (!commission2) return commission1;
  if (!commission1) return commission2;

  let mergedCommentsCount = commission1.commentsCount;
  if (mergedCommentsCount === null) {
    mergedCommentsCount = commission2.commentsCount;
  }

  return {
    ...commission2,
    commentsCount: mergedCommentsCount,
  };
};
