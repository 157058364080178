import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import CommonWidgetBase from '@palette/components/widgets/dashboard/CommonWidgetBase/CommonWidgetBase';

import { getFrequencyPeriodName, getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { formatWidgetPrice } from '@palette/helpers/DashboardHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { PERIODS_DATES_FROM_QS_KEY } from '@palette/constants/statements';

import routePaths from '@palette/config/routePaths';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as StatementPerMonthDataModel from '@palette/models/widgets/dashboard/StatementPerMonthData';

import styles from './StatementPerMonthWidget.less';

const classNames = bindClassNames.bind(styles);

const StatementPerMonthWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const statementPerMonthData = StatementPerMonthDataModel.transform(widgetData.data);

  const handleClick = (statement) => {
    const periodBeginDateMoment = getMomentDateFromPeriod(PERIOD_TYPES.MONTH, 1, { year: statement.year, period: statement.period });

    redirectTo({
      path: routePaths.v2.myStatementDetails,
      params: { statementPeriodId: statement.statementPeriodId },
      qsObject: {
        [PERIODS_DATES_FROM_QS_KEY]: periodBeginDateMoment.format('YYYY-MM-DD'),
      },
    });
  };

  const statementsNode = useMemo(() => statementPerMonthData.statementPerPeriod.map((statement, index) => {
    const periodName = getFrequencyPeriodName(PERIOD_TYPES.MONTH, 1, statement.year, statement.period, null);
    const amount = formatWidgetPrice(statement?.totalCommissionAmount, statementPerMonthData.currency);

    return (
      <div className={styles.statementItem} key={`statement-per-month-${index}`}>
        <div className={styles.periodName}>{periodName}</div>
        <Button
          type="link"
          className={styles.amount}
          onClick={() => handleClick(statement)}
          disabled={readOnly && !enableLinks}
        >
          {amount}
        </Button>
      </div>
    );
  }), [statementPerMonthData, readOnly, enableLinks]);

  return (
    <CommonWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      title={t('common.statementPerMonthWidget.title')}
      readOnly={readOnly}
    >
      <div className={styles.content}>
        {statementsNode}
      </div>
    </CommonWidgetBase>
  );
};

StatementPerMonthWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

StatementPerMonthWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default StatementPerMonthWidget;
