import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as CommissionModel from '@palette/models/Commission';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import NothingToReport from '@palette/components/utils/Icons/NothingToReport';
import CommissionCommentForm from '@palette/components/commission/CommissionCommentForm/CommissionCommentForm';
import CommissionActivities from '@palette/components/commission/CommissionActivities/CommissionActivities';

import { selectors as CommissionsSelectors } from '@palette/state/Commissions';

import styles from './CommissionDrawerActivitiesTab.less';

const classNames = bindClassNames.bind(styles);

const CommissionDrawerActivitiesTab = ({ className, commission }) => {
  const { t } = useTranslation();

  const [commentFormIsOpen, setCommentFormIsOpen] = useState(false);

  const activities = useSelector((state) => CommissionsSelectors.getCommissionActivitiesById(state, { commissionId: commission.id }));

  let contentNode = (
    <DefaultEmptyState
      className={classNames({
        emptyState: true,
        activities: true,
        commentFormIsOpen,
      })}
      image={(<NothingToReport />)}
      description={t('commissionDrawerActivitiesTab.emptyState.description')}
    />
  );
  if (activities !== null) {
    contentNode = (
      <CommissionActivities
        className={classNames({
          activities: true,
          commentFormIsOpen,
        })}
        commission={commission}
        thread={activities}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      <CommissionCommentForm className={styles.commentForm} commission={commission} onChangeOpenChange={setCommentFormIsOpen} />
    </div>
  );
};

CommissionDrawerActivitiesTab.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionDrawerActivitiesTab.defaultProps = {
  className: '',
};

export default CommissionDrawerActivitiesTab;
