import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import { PERIODS_DATES_FROM_QS_KEY } from '@palette/constants/statements';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { getPeriodName } from '@palette/helpers/StatementHelper';

import routePaths from '@palette/config/routePaths';

import * as StatementCorrectionApplyStrategyModel from '@palette/models/statementHistoryStrategies/StatementCorrectionApplyStrategy';

const classNames = bindClassNames.bind();

const StatementCorrectionApplyContentStrategy = ({ className, details, i18nAction }) => {
  const moment = getMoment();

  const {
    amount,
    currency,
    date,
    period,
    year,
    payee,
    strategy,
    periodId,
  } = details;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey={`statement.history.list.content.${i18nAction}`}
        values={{
          amount: formatPrice(amount, currency),
          date: getPeriodName(strategy, period, year),
        }}
        components={[
          <Link
            key={i18nAction}
            path={routePaths.v2.statementsUserStatement}
            params={{
              statementPeriodId: periodId,
              userId: payee.id,
            }}
            qsObject={{
              [PERIODS_DATES_FROM_QS_KEY]: moment.utc(date).format('YYYY-MM-DD'),
            }}
          />,
        ]}
      />
    </span>
  );
};

StatementCorrectionApplyContentStrategy.propTypes = {
  className: PropTypes.string,
  details: StatementCorrectionApplyStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

StatementCorrectionApplyContentStrategy.defaultProps = {
  className: '',
};

export default StatementCorrectionApplyContentStrategy;
