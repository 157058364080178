import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

const FrequencyFormItem = ({ disabled }) => {
  const { t } = useTranslation();

  const frequencyTypesOptions = Object.keys(PLAN_PERIODICITY_TYPE).map((option) => ({
    label: t(`createPlanPage.form.frequency.option.${PLAN_PERIODICITY_TYPE[option]}`),
    value: PLAN_PERIODICITY_TYPE[option],
  }));

  return (
    <FormItem
      name="frequency"
      label={t('createPlanPage.form.frequency.label')}
      required
      rules={[
        { required: true, message: t('createPlanPage.form.frequency.rules.required') },
      ]}
    >
      <Select size="big" options={frequencyTypesOptions} disabled={disabled} />
    </FormItem>
  );
};

FrequencyFormItem.propTypes = {
  disabled: PropTypes.bool,
};

FrequencyFormItem.defaultProps = {
  disabled: false,
};

export default FrequencyFormItem;
