import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import FolderResource from '@palette/components/folders/FoldersRecursive/FolderResource/FolderResource';

import * as FolderWithResourcesModel from '@palette/models/FolderWithResources';

import styles from './FolderResources.less';

const classNames = bindClassNames.bind(styles);

const FolderResources = ({
  className,
  resourceClassName,
  folder,
  ItemComponent,
}) => {
  if (folder.resources.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        folder.resources.map((folderResource, index) => {
          const resourceProp = {
            [folder.type.toLowerCase()]: folderResource,
          };

          return (
            <FolderResource
              key={index}
              className={classNames({
                resource: true,
                [resourceClassName]: resourceClassName !== '',
              })}
              resourceId={folderResource.id}
              resourceParentId={folder.id}
              resourceType={folder.type}
              resourceProp={resourceProp}
              ItemComponent={ItemComponent}
            />
          );
        })
      }
    </div>
  );
};

FolderResources.propTypes = {
  className: PropTypes.string,
  resourceClassName: PropTypes.string,
  folder: FolderWithResourcesModel.propTypes.isRequired,
  ItemComponent: PropTypes.func.isRequired,
};

FolderResources.defaultProps = {
  className: '',
  resourceClassName: '',
};

export default FolderResources;
