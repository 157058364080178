import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { STATEMENT_CORRECTION_TYPES } from '@palette/constants/StatementV3';

import { actions as StatementV3Actions } from '@palette/state/StatementV3';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';
import * as StatementV3ListCorrectionModel from '@palette/models/StatementV3ListCorrection';

import styles from './StatementV3UserStatementCorrectionsItemActions.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementCorrectionsItemActions = ({
  className,
  statement,
  correction,
  currency,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const approveCorrection = useCallback((event) => {
    stopPropagation(event);

    dispatch(StatementV3Actions.correctionSuggestedValidation({
      statementId: statement.id,
      correctionId: correction.id,
      status: STATEMENT_CORRECTION_TYPES.ACCEPTED,
      currency,
      statementCurrency: statement.currency,
    }));
  }, [statement, correction, currency]);

  const ignoreCorrection = useCallback((event) => {
    stopPropagation(event);

    dispatch(StatementV3Actions.correctionSuggestedValidation({
      statementId: statement.id,
      correctionId: correction.id,
      status: STATEMENT_CORRECTION_TYPES.REFUSED,
      currency,
      statementCurrency: statement.currency,
    }));
  }, [statement, correction, currency]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Popconfirm
        title={t('userStatementCorrections.actions.apply.confirm')}
        onConfirm={approveCorrection}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Button
          className={styles.actionButton}
          type="link"
          icon={<CheckFilled width={18} height={18} />}
        >
          {t('userStatementCorrections.actions.createAdjustment')}
        </Button>
      </Popconfirm>
      <Popconfirm
        title={t('userStatementCorrections.actions.ignore.confirm')}
        onConfirm={ignoreCorrection}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Button
          className={styles.actionButton}
          type="linkDestroy"
          icon={<TrashFilled width={18} height={18} />}
        >
          {t('userStatementCorrections.actions.ignore')}
        </Button>
      </Popconfirm>
    </div>
  );
};

StatementV3UserStatementCorrectionsItemActions.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  correction: StatementV3ListCorrectionModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementV3UserStatementCorrectionsItemActions.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3UserStatementCorrectionsItemActions;
