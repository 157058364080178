import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import MasterPlanSettingsGeneral from '@palette/components/masterPlanSettings/MasterPlanSettingsGeneral/MasterPlanSettingsGeneral';
import MasterPlanSettingsUsers from '@palette/components/masterPlanSettings/MasterPlanSettingsUsers/MasterPlanSettingsUsers';
import MasterPlanSettingsDataSource from '@palette/components/masterPlanSettings/MasterPlanSettingsDataSource/MasterPlanSettingsDataSource';
import MasterPlanSettingsQuota from '@palette/components/masterPlanSettings/MasterPlanSettingsQuota/MasterPlanSettingsQuota';
import MasterPlanSettingsCommissionRules from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRules/MasterPlanSettingsCommissionRules';
import MasterPlanSettingsPayoutRules from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRules/MasterPlanSettingsPayoutRules';
import MasterPlanSettingsProjection from '@palette/components/masterPlanSettings/MasterPlanSettingsProjection/MasterPlanSettingsProjection';
import MasterPlanSettingsDependencies from '@palette/components/masterPlanSettings/MasterPlanSettingsDependencies/MasterPlanSettingsDependencies';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { PLAN_SETTINGS_TABS_QS_KEY, PLAN_SETTINGS_TABS_IDS } from '@palette/constants/tabs';
import { SCOPES } from '@palette/constants/masterPlans';
import { RIGHTS } from '@palette/constants/profile';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './MasterPlanSettingsTabs.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsTabs = ({ className, plan }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const finalTabs = [];

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE])) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.GENERAL,
        title: t('masterPlanSettingsTabs.tabs.general'),
        content: (<MasterPlanSettingsGeneral planId={plan.id} />),
        defaultActive: true,
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.GBO.MANAGE])) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.USERS,
        title: t('masterPlanSettingsTabs.tabs.users'),
        content: (<MasterPlanSettingsUsers planId={plan.id} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR])) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.DATA_SOURCE,
        title: t('masterPlanSettingsTabs.tabs.dataSource'),
        content: (<MasterPlanSettingsDataSource planId={plan.id} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE, RIGHTS.ADMIN.QUOTAS.USERS.MANAGE])) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.QUOTA,
        title: t('masterPlanSettingsTabs.tabs.quota'),
        content: (<MasterPlanSettingsQuota planId={plan.id} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE])) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.COMMISSION_RULES,
        title: t('masterPlanSettingsTabs.tabs.commissionRules'),
        content: (<MasterPlanSettingsCommissionRules planId={plan.id} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE])) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.PAYOUT_RULES,
        title: t('masterPlanSettingsTabs.tabs.payoutRules'),
        content: (<MasterPlanSettingsPayoutRules planId={plan.id} />),
      });
    }

    if (
      hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE])
      && plan.scope === SCOPES.INDIVIDUAL
      && plan.quotaId !== null
      && plan.injections.length === 0
    ) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.PROJECTION,
        title: t('masterPlanSettingsTabs.tabs.projection'),
        content: (<MasterPlanSettingsProjection planId={plan.id} />),
      });
    }

    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE])) {
      finalTabs.push({
        tabKey: PLAN_SETTINGS_TABS_IDS.DEPENDENCIES,
        title: t('masterPlanSettingsTabs.tabs.dependencies'),
        content: (<MasterPlanSettingsDependencies planId={plan.id} />),
      });
    }

    return finalTabs;
  }, [profile, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        tabs={tabs}
        qsTabKey={PLAN_SETTINGS_TABS_QS_KEY}
      />
    </div>
  );
};

MasterPlanSettingsTabs.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanSettingsTabs.defaultProps = {
  className: '',
};

export default MasterPlanSettingsTabs;
