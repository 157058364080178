import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _sortBy from 'lodash/sortBy';

import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';

import { formatBeginEndDate, getMoment } from '@palette/helpers/MomentHelper';
import { getDayPeriods } from '@palette/helpers/FrequencyHelper';

import { PERIOD_TYPES, PERIODS } from '@palette/constants/frequencies';

import * as YearPeriodModel from '@palette/models/YearPeriod';

import styles from './PeriodYearPicker.less';

const classNames = bindClassNames.bind(styles);

const PeriodYearPicker = ({
  className,
  periodType,
  frequency,
  fiscalYearShift,
  period,
  year,
  beginDate,
  onPeriodChange,
  onYearChange,
  onYearPeriodChange,
  disableBeforePeriod,
  disableAfterPeriod,
  disabled,
  forceReset,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const dayPeriods = useMemo(() => {
    if (periodType !== PERIOD_TYPES.DAY) return null;

    return getDayPeriods(frequency, beginDate);
  }, [periodType, frequency, beginDate]);

  const [currentMonth, setCurrentMonth] = useState(0);

  const [currentYear, setCurrentYear] = useState(year);
  const updateCurrentYear = useCallback((newYear) => {
    if (newYear < 1970) return;

    setCurrentYear(newYear);

    if (periodType === PERIOD_TYPES.MONTH && frequency === 12) {
      if (onYearChange !== null) {
        onYearChange(currentYear);
      }
      if (onYearPeriodChange !== null) {
        onYearPeriodChange({ year: currentYear, period: 1 });
      }
    }
  }, [periodType, frequency, onYearChange, onYearPeriodChange]);

  useEffect(() => {
    setCurrentYear(year);

    if (periodType === PERIOD_TYPES.DAY && dayPeriods !== null) {
      const yearMonths = dayPeriods[year] || null;
      if (yearMonths !== null) {
        setCurrentMonth(parseInt(Object.keys(yearMonths)[0], 10));
      } else {
        updateCurrentYear(parseInt(Object.keys(dayPeriods)[0], 10));
      }
    }
  }, [year]);

  useEffect(() => {
    if (forceReset) {
      setCurrentYear(year);
      if (dayPeriods !== null) {
        const yearMonths = dayPeriods[year] || null;
        if (yearMonths !== null) {
          Object.entries(yearMonths).forEach(([month, inMonthPeriods]) => {
            if (inMonthPeriods.some((dayPeriod) => dayPeriod.period === period)) {
              setCurrentMonth(parseInt(month, 10));
            }
          });
        }
      }
    }
  }, [forceReset]);

  const changeMonth = useCallback((newMonth) => {
    if (newMonth < 0) {
      const newYear = currentYear - 1;
      if (dayPeriods[newYear]) {
        updateCurrentYear(newYear);
        setCurrentMonth(11);
      }
    } else if (newMonth > 11) {
      const newYear = currentYear + 1;
      if (dayPeriods[newYear]) {
        updateCurrentYear(newYear);
        setCurrentMonth(0);
      }
    } else if (dayPeriods[currentYear][newMonth]) {
      setCurrentMonth(newMonth);
    }
  }, [dayPeriods, currentYear, updateCurrentYear]);

  const changePeriod = useCallback((newPeriod) => {
    if (onYearChange !== null) {
      onYearChange(currentYear);
    }
    if (onPeriodChange !== null) {
      onPeriodChange(newPeriod);
    }
    if (onYearPeriodChange !== null) {
      onYearPeriodChange({ year: currentYear, period: newPeriod });
    }
  }, [currentYear, onYearChange, onPeriodChange, onYearPeriodChange]);

  const yearSelectorNode = useMemo(() => {
    if (periodType === PERIOD_TYPES.DAY && dayPeriods !== null) {
      const years = _sortBy(Object.keys(dayPeriods));

      let prevNode = (
        <div
          className={classNames({
            prevNextBtn: true,
            prevBtn: true,
            disable: currentYear === 1970,
          })}
          onClick={() => updateCurrentYear(currentYear - 1)}
        >
          <ChevronLeftLine width={24} height={24} />
        </div>
      );
      if (currentYear <= parseInt(years[0], 10)) {
        prevNode = (
          <div
            className={classNames({
              fillBtnSpace: true,
              prevBtn: true,
            })}
          />
        );
      }

      let nextNode = (
        <div
          className={classNames({
            prevNextBtn: true,
            nextBtn: true,
          })}
          onClick={() => updateCurrentYear(currentYear + 1)}
        >
          <ChevronRightLine width={24} height={24} />
        </div>
      );
      if (currentYear >= parseInt(years[years.length - 1], 10)) {
        nextNode = (
          <div
            className={classNames({
              fillBtnSpace: true,
              nextBtn: true,
            })}
          />
        );
      }

      return (
        <div className={styles.prevCurrentNextWrapper}>
          {prevNode}
          <div className={styles.current}>
            {currentYear}
          </div>
          {nextNode}
        </div>
      );
    }

    return (
      <div className={styles.prevCurrentNextWrapper}>
        <div
          className={classNames({
            prevNextBtn: true,
            prevBtn: true,
            disable: currentYear === 1970,
          })}
          onClick={() => updateCurrentYear(currentYear - 1)}
        >
          <ChevronLeftLine width={24} height={24} />
        </div>
        <div className={styles.current}>
          {currentYear}
        </div>
        <div
          className={classNames({
            prevNextBtn: true,
            nextBtn: true,
          })}
          onClick={() => updateCurrentYear(currentYear + 1)}
        >
          <ChevronRightLine width={24} height={24} />
        </div>
      </div>
    );
  }, [periodType, currentYear, dayPeriods, updateCurrentYear]);

  const periodsNodesForMonth = useMemo(() => {
    if (frequency === 12) return [];

    const nodes = [];

    const nbPeriods = 12 / frequency;
    for (let freqPeriod = 0; freqPeriod < nbPeriods; freqPeriod += 1) {
      const periodId = `${frequency}-${freqPeriod}`;

      const disablePeriod = disabled
      || (
        disableBeforePeriod !== null
        && (
          currentYear < disableBeforePeriod.year
          || (currentYear === disableBeforePeriod.year && (freqPeriod + 1) < disableBeforePeriod.period)
        )
      )
      || (
        disableAfterPeriod !== null
        && (
          currentYear > disableAfterPeriod.year
          || (currentYear === disableAfterPeriod.year && (freqPeriod + 1) > disableAfterPeriod.period)
        )
      );

      let fiscalYearShiftSuffix = '';
      if (frequency > 1 && fiscalYearShift > 0) {
        fiscalYearShiftSuffix = ` (${t('fiscalYear.FY')})`;
      }

      nodes.push((
        <div
          key={periodId}
          className={classNames({
            period: true,
            selected: period === freqPeriod + 1 && currentYear === year,
            disabled: disablePeriod,
            forceDisabled: disabled,
          })}
          onClick={() => { if (!disablePeriod) { changePeriod(freqPeriod + 1); } }}
        >
          {`${t(PERIODS[periodId])}${fiscalYearShiftSuffix}`}
        </div>
      ));
    }

    return (
      <div
        className={classNames({
          periodsWrapper: true,
          yearly: periodType === PERIOD_TYPES.MONTH && frequency === 12,
        })}
      >
        {nodes}
      </div>
    );
  }, [
    periodType,
    frequency,
    fiscalYearShift,
    disableBeforePeriod,
    disabled,
    disableAfterPeriod,
    currentYear,
    changePeriod,
    period,
    year,
  ]);

  const periodsNodesForDay = useMemo(() => {
    if (dayPeriods === null) return null;

    const inYearPeriods = dayPeriods[currentYear] || {};
    let inMonthPeriods = Object.values(inYearPeriods).flat();
    if (frequency < 15) {
      inMonthPeriods = inYearPeriods[currentMonth] || [];
    }

    const nodes = inMonthPeriods.map((dayPeriod) => {
      const periodName = formatBeginEndDate(dayPeriod.beginDate, dayPeriod.endDate, true);

      const disablePeriod = disabled
        || (disableBeforePeriod !== null && dayPeriod.period < disableBeforePeriod.period)
        || (disableAfterPeriod !== null && dayPeriod.period > disableAfterPeriod.period);

      return (
        <div
          key={dayPeriod.period}
          className={classNames({
            period: true,
            selected: period === dayPeriod.period && currentYear === year,
            disabled: disablePeriod,
            forceDisabled: disabled,
          })}
          onClick={() => { if (!disablePeriod) { changePeriod(dayPeriod.period); } }}
        >
          {periodName}
        </div>
      );
    });

    let prevCurrentNextNode = null;
    if (frequency < 15) {
      const prevMonth = currentMonth - 1;
      let prevNode = (
        <div
          className={classNames({
            fillBtnSpace: true,
            prevBtn: true,
          })}
        />
      );
      if (
        (prevMonth < 0 && dayPeriods[currentYear - 1] !== undefined)
        || (dayPeriods[currentYear]?.[prevMonth] !== undefined)
      ) {
        prevNode = (
          <div
            className={classNames({
              prevNextBtn: true,
              prevBtn: true,
            })}
            onClick={() => changeMonth(prevMonth)}
          >
            <ChevronLeftLine width={24} height={24} />
          </div>
        );
      }

      const nextMonth = currentMonth + 1;
      let nextNode = (
        <div
          className={classNames({
            fillBtnSpace: true,
            nextBtn: true,
          })}
        />
      );
      if (
        (nextMonth > 11 && dayPeriods[currentYear + 1] !== undefined)
        || (dayPeriods[currentYear]?.[nextMonth] !== undefined)
      ) {
        nextNode = (
          <div
            className={classNames({
              prevNextBtn: true,
              nextBtn: true,
            })}
            onClick={() => changeMonth(nextMonth)}
          >
            <ChevronRightLine width={24} height={24} />
          </div>
        );
      }

      prevCurrentNextNode = (
        <div className={styles.prevCurrentNextWrapper}>
          {prevNode}
          <div className={styles.current}>
            {moment().day(10).month(currentMonth).format('MMMM')}
          </div>
          {nextNode}
        </div>
      );
    }

    return (
      <div className={styles.dayPeriodsWrapper}>
        {prevCurrentNextNode}
        <div className={styles.periodsWrapper}>
          {nodes}
        </div>
      </div>
    );
  }, [
    currentYear,
    currentMonth,
    dayPeriods,
    disableBeforePeriod,
    disableAfterPeriod,
    disabled,
    year,
    period,
    changeMonth,
    frequency,
  ]);

  const periodsNodes = useMemo(() => {
    if (periodType === PERIOD_TYPES.DAY) {
      return periodsNodesForDay;
    }

    return periodsNodesForMonth;
  }, [
    periodType,
    periodsNodesForDay,
    periodsNodesForMonth,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        quarterly: periodType === PERIOD_TYPES.MONTH && frequency === 3,
        [className]: className !== '',
      })}
    >
      {yearSelectorNode}
      {periodsNodes}
    </div>
  );
};

PeriodYearPicker.propTypes = {
  className: PropTypes.string,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)).isRequired,
  frequency: PropTypes.number.isRequired,
  fiscalYearShift: PropTypes.number,
  period: PropTypes.number,
  year: PropTypes.number.isRequired,
  onPeriodChange: PropTypes.func,
  onYearChange: PropTypes.func,
  onYearPeriodChange: PropTypes.func,
  beginDate: PropTypes.string,
  disableBeforePeriod: YearPeriodModel.propTypes,
  disableAfterPeriod: YearPeriodModel.propTypes,
  disabled: PropTypes.bool,
  forceReset: PropTypes.bool,
};

PeriodYearPicker.defaultProps = {
  className: '',
  fiscalYearShift: 0,
  onPeriodChange: null,
  onYearChange: null,
  onYearPeriodChange: null,
  beginDate: null,
  period: null,
  disableBeforePeriod: null,
  disableAfterPeriod: null,
  disabled: false,
  forceReset: false,
};

export default PeriodYearPicker;
