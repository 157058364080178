import _flatMapDeep from 'lodash/flatMapDeep';
import _findIndex from 'lodash/findIndex';

import { getI18n } from '@palette/i18n';

import { formatBeginEndDate, getMoment } from '@palette/helpers/MomentHelper';

import {
  DAY_FREQUENCIES,
  FREQUENCY_OPTION_VALUE_SEPARATOR,
  MONTH_FREQUENCIES,
  OTHER_FREQUENCIES,
  PERIOD_TYPES,
  PERIODS,
  PERIODS_SHORTNAME,
} from '@palette/constants/frequencies';

export const getPeriodTypeFrequencyName = (periodType, frequency) => {
  const i18n = getI18n();

  if (periodType === PERIOD_TYPES.DAY) {
    if (frequency === 7) return i18n.t(DAY_FREQUENCIES.WEEKLY);

    return i18n.t(DAY_FREQUENCIES.EVERY_X_DAYS, { frequency });
  }

  return i18n.t(MONTH_FREQUENCIES[frequency]);
};

export const getCustomFrequencyPeriodName = (beginDate, nbOfDays) => {
  const moment = getMoment();

  const beginPeriodMomentDate = moment(beginDate).utc()
    .startOf('day')
    .add(12, 'hours');
  const endPeriodMomentDate = moment(beginPeriodMomentDate).add(nbOfDays - 1, 'days');

  return formatBeginEndDate(beginPeriodMomentDate.format(), endPeriodMomentDate.format());
};

export const getCustomPeriodTypeFrequencyName = (beginDate, nbOfDays) => {
  const i18n = getI18n();

  const customPeriodName = getCustomFrequencyPeriodName(beginDate, nbOfDays);

  return `${i18n.t(OTHER_FREQUENCIES.CUSTOM)}\n(${customPeriodName})`;
};

export const getFrequencyPeriodName = (periodType, frequency, year, period, beginDate = null, useShortMonth = false, fiscalYearShift = 0, showYear = true) => {
  if (year === 0 || period === 0) return '-';

  const i18n = getI18n();
  const frequencyPeriodString = `${frequency}-${period - 1}`;

  let periodName = i18n.t(PERIODS[frequencyPeriodString]);

  if (useShortMonth) {
    periodName = i18n.t(PERIODS_SHORTNAME[frequencyPeriodString]);
  }

  let displayedYear = showYear ? ` ${year}` : '';

  if (periodType === PERIOD_TYPES.DAY) {
    if (beginDate === null) {
      periodName = `${i18n.t(DAY_FREQUENCIES.EVERY_X_DAYS, { frequency })} - P${period}`;
    } else {
      const moment = getMoment();

      const beginPeriodMomentDate = moment(beginDate).utc()
        .startOf('day')
        .add(12, 'hours')
        .add((period - 1) * frequency, 'days');
      const endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');

      periodName = formatBeginEndDate(beginPeriodMomentDate.format(), endPeriodMomentDate.format());
      displayedYear = '';
    }
  }

  let displayedFiscalYearShift = '';
  if (fiscalYearShift > 0) {
    displayedFiscalYearShift = ` (${i18n.t('fiscalYear.FY')})`;
  }

  return `${periodName}${displayedYear}${displayedFiscalYearShift}`;
};

export const buildFrequencyOptionValue = (periodType, freqKey) => `${periodType}${FREQUENCY_OPTION_VALUE_SEPARATOR}${freqKey}`;

export const extractPeriodTypeFrequencyFromOptionValue = (optionValue) => optionValue.split(FREQUENCY_OPTION_VALUE_SEPARATOR);

export const getMomentDateFromPeriod = (periodType, frequency, period, beginDate = null, endOfPeriod = false, fiscalYearShift = 0) => {
  const moment = getMoment();

  if (periodType === PERIOD_TYPES.CUSTOM && beginDate !== null) {
    const beginPeriodMomentDate = moment(beginDate).utc()
      .startOf('day')
      .add(12, 'hours');

    if (endOfPeriod) {
      const endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');

      return endPeriodMomentDate.endOf('day');
    }

    return beginPeriodMomentDate.startOf('day');
  }

  if (periodType === PERIOD_TYPES.DAY && beginDate !== null) {
    const beginPeriodMomentDate = moment(beginDate).utc()
      .startOf('day')
      .add(12, 'hours')
      .add((period.period - 1) * frequency, 'days');

    if (endOfPeriod) {
      const endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');

      return endPeriodMomentDate.endOf('day');
    }

    return beginPeriodMomentDate.startOf('day');
  }

  const momentDate = moment()
    .utc()
    .year(period.year)
    .month(frequency * (period.period - 1));

  if (endOfPeriod) {
    return momentDate
      .add(frequency - 1, 'month')
      .add(fiscalYearShift, 'month')
      .endOf('month');
  }

  return momentDate
    .add(fiscalYearShift, 'month')
    .startOf('month');
};

export const getDayPeriods = (frequency, beginDate = null, nbOfYearsInFutur = 10) => {
  if (beginDate === null) return null;

  const moment = getMoment();
  const now = moment().utc();

  const lastDay = nbOfYearsInFutur > 0 ? moment(now).add(nbOfYearsInFutur, 'years').endOf('year') : moment(now).endOf('day');

  let beginPeriodMomentDate = moment(beginDate).utc().startOf('day').add(12, 'hours');
  let endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');
  let periodNb = 1;
  const periodsByYearAndMonth = {
    [beginPeriodMomentDate.year()]: {
      [beginPeriodMomentDate.month()]: [
        {
          year: beginPeriodMomentDate.year(),
          period: periodNb,
          beginDate: beginPeriodMomentDate.format(),
          endDate: endPeriodMomentDate.format(),
        },
      ],
    },
  };

  while (endPeriodMomentDate.isBefore(lastDay)) {
    beginPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency, 'days');
    endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');
    periodNb += 1;

    const newYear = periodsByYearAndMonth[beginPeriodMomentDate.year()] || {};

    const newPeriods = newYear[beginPeriodMomentDate.month()] || [];
    newPeriods.push({
      year: beginPeriodMomentDate.year(),
      period: periodNb,
      beginDate: beginPeriodMomentDate.format(),
      endDate: endPeriodMomentDate.format(),
    });

    periodsByYearAndMonth[beginPeriodMomentDate.year()] = {
      ...newYear,
      [beginPeriodMomentDate.month()]: newPeriods,
    };
  }

  return periodsByYearAndMonth;
};

export const getPreviousPeriod = (periodType, frequency, year, period, beginDate = null) => {
  if (periodType === PERIOD_TYPES.CUSTOM) return null;

  if (periodType === PERIOD_TYPES.DAY) {
    if (beginDate === null) {
      return {
        year,
        period,
      };
    }

    const dayPeriods = getDayPeriods(frequency, beginDate, 0);
    const allPeriods = _flatMapDeep(dayPeriods, (yearMonth) => Object.values(yearMonth));
    const newPeriodIndex = _findIndex(allPeriods, { period: period - 1 });

    let newYear = year;
    let newPeriod = period;

    if (newPeriodIndex !== -1) {
      const previousPeriod = allPeriods[newPeriodIndex];
      newYear = previousPeriod.year;
      newPeriod = previousPeriod.period;
    }

    return {
      year: newYear,
      period: newPeriod,
    };
  }

  const nbPeriods = 12 / frequency;

  let newYear = year;
  let newPeriod = period - 1;

  if (newPeriod === 0) {
    newYear = year - 1;
    newPeriod = nbPeriods;
  }

  return {
    year: newYear,
    period: newPeriod,
  };
};

export const getNextPeriod = (periodType, frequency, year, period, beginDate = null) => {
  if (periodType === PERIOD_TYPES.CUSTOM) return null;

  if (periodType === PERIOD_TYPES.DAY) {
    if (beginDate === null) {
      return {
        year,
        period,
      };
    }

    const dayPeriods = getDayPeriods(frequency, beginDate, 0);
    const allPeriods = _flatMapDeep(dayPeriods, (yearMonth) => Object.values(yearMonth));
    const newPeriodIndex = _findIndex(allPeriods, { period: period + 1 });

    let newYear = year;
    let newPeriod = period;

    if (newPeriodIndex !== -1) {
      const previousPeriod = allPeriods[newPeriodIndex];
      newYear = previousPeriod.year;
      newPeriod = previousPeriod.period;
    }

    return {
      year: newYear,
      period: newPeriod,
    };
  }

  const nbPeriods = 12 / frequency;

  let newYear = year;
  let newPeriod = period + 1;

  if (newPeriod > nbPeriods) {
    newYear = year + 1;
    newPeriod = 1;
  }

  return {
    year: newYear,
    period: newPeriod,
  };
};

export const getPeriodForMomentDate = (momentDate, periodType, frequency, beginDate = null, fiscalYearShift = 0) => {
  const moment = getMoment();

  let searchedMomentDate = moment(momentDate).utc()
    .startOf('day')
    .add(12, 'hours');

  if (periodType === PERIOD_TYPES.CUSTOM) {
    const beginPeriodMomentDate = moment(beginDate).utc()
      .startOf('day');

    const endPeriodMomentDate = moment(beginPeriodMomentDate).utc()
      .add(12, 'hours')
      .add(frequency - 1, 'days')
      .endOf('day');

    if (searchedMomentDate.isBetween(beginPeriodMomentDate, endPeriodMomentDate, 'day', '[]')) {
      return {
        year: beginPeriodMomentDate.year(),
        period: 1,
      };
    }

    return null;
  }

  if (periodType === PERIOD_TYPES.DAY) {
    if (beginDate === null) return null;

    const beginPlanMomentDate = moment(beginDate).utc().startOf('day').add(12, 'hours');
    const movingForward = searchedMomentDate.isSameOrAfter(beginPlanMomentDate);

    let beginPeriodMomentDate = moment(beginDate).utc().startOf('day').add(12, 'hours');
    let endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');
    let periodNb = 1;

    if (!movingForward) {
      beginPeriodMomentDate = moment(beginDate).utc()
        .startOf('day')
        .add(12, 'hours')
        .subtract(frequency, 'days');
      endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');
      periodNb = 0;
    }

    if (movingForward) {
      while (
        !searchedMomentDate.isBetween(
          moment(beginPeriodMomentDate).utc().startOf('day'),
          moment(endPeriodMomentDate).utc().endOf('day'),
          'day',
          '[]',
        )
      ) {
        beginPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency, 'days');
        endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');
        periodNb += 1;
      }
    } else {
      while (
        !searchedMomentDate.isBetween(
          moment(beginPeriodMomentDate).utc().startOf('day'),
          moment(endPeriodMomentDate).utc().endOf('day'),
          'day',
          '[]',
        )
      ) {
        beginPeriodMomentDate = moment(beginPeriodMomentDate).subtract(frequency, 'days');
        endPeriodMomentDate = moment(beginPeriodMomentDate).add(frequency - 1, 'days');
        periodNb -= 1;
      }
    }

    return {
      year: beginPeriodMomentDate.utc().year(),
      period: periodNb,
    };
  }

  searchedMomentDate = searchedMomentDate.subtract(fiscalYearShift, 'month');
  const currentMonth = searchedMomentDate.month();

  const periodYear = searchedMomentDate.year();
  const periodPeriod = Math.ceil((currentMonth + 1) / frequency);

  return {
    period: periodPeriod,
    year: periodYear,
  };
};
