import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Input from '@palette/components/designSystem/Input/Input';
import TeamSelector from '@palette/components/team/TeamSelector/TeamSelector';
import StatementV3UsersStatementsList from '@palette/components/statementV3/StatementV3UsersStatementsList/StatementV3UsersStatementsList';
import StatementV3ApproveAllAction from '@palette/components/statementV3/StatementV3ApproveAllAction/StatementV3ApproveAllAction';
import StatementV3MarkAllAsPaidAction from '@palette/components/statementV3/StatementV3MarkAllAsPaidAction/StatementV3MarkAllAsPaidAction';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getStatementUserApprovalStatus } from '@palette/helpers/StatementV3Helper';

import { STATEMENT_APPROVAL_STATUSES } from '@palette/constants/statements';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import * as StatementV3PeriodModel from '@palette/models/StatementV3Period';

import { selectors as TeamsSelectors } from '@palette/state/Teams';
import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './StatementV3UsersStatements.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UsersStatements = ({ className, statementPeriod, currency }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [searchedUser, setSearchedUser] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const selectedTeam = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId: selectedTeamId }));
  const usersList = useSelector((state) => UsersSelectors.getUsersList(state));

  const filteredUsersStatements = useMemo(() => {
    let finalUsers = statementPeriod.statements;

    if (selectedTeam !== null) {
      const membersIds = selectedTeam.members.map((teamUser) => teamUser.user.id);
      finalUsers = finalUsers.filter((userStatement) => membersIds.includes(userStatement.userId));
    }

    if (searchedUser !== '') {
      const findUsers = usersList.filter((user) => user.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1).map((user) => user.id);
      finalUsers = finalUsers.filter((finalUser) => findUsers.includes(finalUser.userId));
    }

    return finalUsers;
  }, [
    statementPeriod,
    searchedUser,
    selectedTeam,
    selectedTeamId,
    usersList,
  ]);

  const actionsNode = useMemo(() => {
    if (filteredUsersStatements.length === 0) return null;

    const areAllStatementsApproved = filteredUsersStatements.every((userStatement) => getStatementUserApprovalStatus(userStatement, profile.userData.id) === STATEMENT_APPROVAL_STATUSES.APPROVED);
    const areAllStatementsPaid = filteredUsersStatements.every((userStatement) => userStatement.actions.some((action) => action.type === STATEMENT_ACTION_TYPES.PAYMENT));

    return (
      <div className={styles.actions}>
        <StatementV3ApproveAllAction
          className={styles.actionButton}
          statements={filteredUsersStatements}
          statementPeriod={statementPeriod}
          currency={currency}
          disabled={areAllStatementsApproved || areAllStatementsPaid}
          areAllStatementsPaid={areAllStatementsPaid}
        />
        <StatementV3MarkAllAsPaidAction
          className={styles.actionButton}
          statements={filteredUsersStatements}
          statementPeriod={statementPeriod}
          currency={currency}
          disabled={areAllStatementsPaid}
        />
      </div>
    );
  }, [
    filteredUsersStatements,
    statementPeriod,
    currency,
    selectedTeamId,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          {t('users_other')}
        </div>
        <div className={styles.filters}>
          <Input
            className={styles.userSearch}
            type="search"
            placeholder={t('userStatements.userSearch.placeholder')}
            onChange={setSearchedUser}
            value={searchedUser}
          />
          <TeamSelector
            className={styles.selector}
            selectedTeam={selectedTeamId}
            onTeamSelected={setSelectedTeamId}
          />
        </div>
        {actionsNode}
      </div>
      <StatementV3UsersStatementsList userStatements={filteredUsersStatements} currency={currency} />
    </div>
  );
};

StatementV3UsersStatements.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3PeriodModel.propTypes.isRequired,
  currency: PropTypes.string,
};

StatementV3UsersStatements.defaultProps = {
  className: '',
  currency: null,
};

export default StatementV3UsersStatements;
