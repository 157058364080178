import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage403 = (problem) => {
  const managedErrors = {
    'user.not.in.scope': {
      code: GLOBAL_NOTIF_REASONS.QUOTA_403_USER_NOT_IN_SCOPE.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage404 = (problem) => {
  const managedErrors = {
    'quota.not.found': {
      code: GLOBAL_NOTIF_REASONS.QUOTA_404_QUOTA_NOT_FOUND.code,
    },
    'user.not.in.quota': {
      code: GLOBAL_NOTIF_REASONS.QUOTA_404_USER_NOT_IN_QUOTA.code,
    },
    'user.not.found': {
      code: GLOBAL_NOTIF_REASONS.QUOTA_404_USER_NOT_FOUND.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage409 = (problem) => {
  const managedErrors = {
    'user.already.in.quota': {
      code: GLOBAL_NOTIF_REASONS.QUOTA_409_USER_ALREADY_IN_QUOTA.code,
    },
    'user.attached.to.plans': {
      code: GLOBAL_NOTIF_REASONS.QUOTA_409_USER_ATTACHED_TO_PLANS_ERROR.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    403: manage403,
    404: manage404,
    409: manage409,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code } = error;

    return {
      ...problem,
      message: { code },
    };
  }

  return manageGlobalError({ status, problem });
};
