import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import FastOnboardModalContent from '@palette/components/user/FastOnboardModalContent/FastOnboardModalContent';

import { useFastOnboarding } from '@palette/hooks/UserHooks';

import { actions as UsersActions } from '@palette/state/Users';

import styles from './FastOnboardModal.less';

const FastOnboardModal = ({ visible, onClose, restrictToConnectorIdAndType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cleanAndClose = useCallback(() => {
    dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: null }));
    onClose();
  }, [onClose]);

  useEffect(() => {
    dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: null }));
  }, []);

  const onOnboardingSuccessCB = useCallback(() => {
    cleanAndClose();
    dispatch(UsersActions.getList());
  }, [cleanAndClose]);

  const {
    selectedResources,
    setSelectedResources,
    isMissingRequiredFields,
    setIsMissingRequiredFields,
    onboardUsersIsPending,
    onboardCurrentUsers,
    handleContinueFastOnboard,
    registerNextStepHandler,
  } = useFastOnboarding(onOnboardingSuccessCB);

  return (
    <Modal
      className={styles.modal}
      width="80%"
      stretchHeight
      title={t('fastOnboardModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleContinueFastOnboard}
      okText={onboardCurrentUsers.length === 0 ? t('fastOnboardModal.continue') : t('fastOnboardModal.onboardUsers')}
      disableOk={selectedResources.length === 0 || (onboardCurrentUsers.length > 0 && isMissingRequiredFields)}
      loading={onboardUsersIsPending}
    >
      <FastOnboardModalContent
        restrictToConnectorIdAndType={restrictToConnectorIdAndType}
        setIsMissingRequiredFields={setIsMissingRequiredFields}
        onResourcesSelected={setSelectedResources}
        registerNextStepHandler={registerNextStepHandler}
      />
    </Modal>
  );
};

FastOnboardModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
};

FastOnboardModal.defaultProps = {
  visible: false,
  onClose: () => {},
  restrictToConnectorIdAndType: null,
};

export default FastOnboardModal;
