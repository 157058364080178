import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import _intersection from 'lodash/intersection';

import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import TeamSelector from '@palette/components/team/TeamSelector/TeamSelector';
import Input from '@palette/components/designSystem/Input/Input';
import Table from '@palette/components/designSystem/Table/Table';
import Loader from '@palette/components/utils/Loader/Loader';
import BarsFilled from '@palette/components/utils/Icons/BarsFilled';
import Button from '@palette/components/designSystem/Button/Button';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Checkbox from '@palette/components/designSystem/Checkbox/Checkbox';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import UsersVariablesTableBodyCell from '@palette/components/usersVariables/UsersVariablesTableBodyCell/UsersVariablesTableBodyCell';
import UserVariableHeader from '@palette/components/usersVariables/UserVariableHeader/UserVariableHeader';
import InBulkEditVariableValue from '@palette/components/usersVariables/InBulkEditVariableValue/InBulkEditVariableValue';

import { useCompanyMetaUsers } from '@palette/hooks/UserHooks';
import { useUserVariableDateFilterInSearch } from '@palette/hooks/UserVariableHooks';

import { getMoment, shortFormatWithShortYear } from '@palette/helpers/MomentHelper';
import { combineUsersAndUsersVariables, getColumnWidthFromType } from '@palette/helpers/UserVariableHelper';

import { USER_VARIABLE_DATE_FILTER_QS_KEY } from '@palette/constants/user';

import { selectors as UsersSelectors } from '@palette/state/Users';
import { selectors as TeamsSelectors } from '@palette/state/Teams';
import { actions as UsersVariablesActions, selectors as UsersVariablesSelectors } from '@palette/state/UsersVariables';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './UsersVariablesPageContent.less';

const classNames = bindClassNames.bind(styles);

const UsersVariablesPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const usersListIsPending = useSelector(UsersSelectors.getListIsPending);
  const companyMetaUsers = useCompanyMetaUsers();

  const getUsersVariablesIsPending = useSelector(UsersVariablesSelectors.getUsersVariablesIsPending);
  const usersVariables = useSelector(UsersVariablesSelectors.getUsersVariables);

  useEffect(() => {
    dispatch(UsersVariablesActions.getUsersVariables());
  }, []);

  const loading = useMemo(() => (usersListIsPending || getUsersVariablesIsPending), [usersListIsPending, getUsersVariablesIsPending]);

  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [searchedUser, setSearchedUser] = useState('');
  const [onlyActiveUsers, setOnlyActiveUsers] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const dateFilterValue = useUserVariableDateFilterInSearch();

  const selectedTeam = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId: selectedTeamId }));

  const data = useMemo(() => {
    let listedUsers = companyMetaUsers;

    if (selectedTeam !== null) {
      const membersIds = selectedTeam.members.map((teamUser) => teamUser.user.id);
      listedUsers = listedUsers.filter((metaUser) => membersIds.includes(metaUser.id));
    }

    if (onlyActiveUsers) {
      const now = moment.utc();
      listedUsers = listedUsers.filter((metaUser) => (
        metaUser.leaveDate === null
        || moment.utc(metaUser.leaveDate).isAfter(now)
      ));
    }

    if (searchedUser !== '') {
      listedUsers = listedUsers.filter((metaUser) => (
        metaUser.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
        || metaUser.email.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
      ));
    }

    listedUsers = _orderBy(listedUsers, (user) => (user.displayName.toLowerCase()));

    return combineUsersAndUsersVariables(listedUsers, usersVariables, dateFilterValue);
  },
  [
    companyMetaUsers,
    selectedTeam,
    onlyActiveUsers,
    searchedUser,
    usersVariables,
    dateFilterValue,
  ]);

  const changeSelectedUsers = useCallback((newSelectedUsers) => {
    setSelectedUsers(newSelectedUsers);
  }, []);

  const handleAllUsersCheckboxChange = useCallback((e) => {
    let newSelectedUsers = [];

    if (e.target.checked) {
      newSelectedUsers = data.map((userWithVariables) => (userWithVariables.user));
    }

    changeSelectedUsers(newSelectedUsers);
  }, [data, changeSelectedUsers]);

  const handleUserCheckboxChange = useCallback((e, metaUser) => {
    let newSelectedUsers = [...selectedUsers];

    if (e.target.checked) {
      newSelectedUsers.push(metaUser);
    } else {
      newSelectedUsers = newSelectedUsers.filter((selectedUser) => (selectedUser.id !== metaUser.id));
    }

    changeSelectedUsers(newSelectedUsers);
  }, [selectedUsers, changeSelectedUsers]);

  const columns = useMemo(() => {
    const cols = [];

    const dataUsersIds = data.map((userWithVariables) => (userWithVariables.user.id));
    const selectedUsersIds = selectedUsers.map((metaUser) => metaUser.id);
    const intersection = _intersection(dataUsersIds, selectedUsersIds);
    let headerCheckboxProps = { checked: false, indeterminate: false };
    if (dataUsersIds.length > 0 && intersection.length > 0) {
      if (intersection.length === dataUsersIds.length) {
        headerCheckboxProps = { checked: true, indeterminate: false };
      } else {
        headerCheckboxProps = { checked: false, indeterminate: true };
      }
    }

    const selectorColumn = {
      id: 'selector',
      Header: (<Checkbox onChange={handleAllUsersCheckboxChange} {...headerCheckboxProps} />),
      accessor: 'user',
      minWidth: 48,
      width: 48,
      /* eslint-disable react/prop-types */
      Cell: ({ value: metaUser }) => (
        <Checkbox
          checked={selectedUsersIds.includes(metaUser.id)}
          onChange={(e) => handleUserCheckboxChange(e, metaUser)}
        />
      ),
      /* eslint-enable react/prop-types */
    };
    cols.push(selectorColumn);

    const userProfileColumn = {
      id: 'users',
      Header: ({ data: rowsData }) => (`${t('users_other')} (${rowsData.length})`),
      accessor: 'user',
      minWidth: 300,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value: metaUser }) => (<UserProfile user={metaUser} clickable />),
    };
    cols.push(userProfileColumn);

    usersVariables.variables.forEach((userVariable) => {
      const variableColumn = {
        id: userVariable.id,
        Header: (<UserVariableHeader userVariable={userVariable} />),
        accessor: (userWithVariables) => (userWithVariables),
        minWidth: getColumnWidthFromType(userVariable.type),
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<UsersVariablesTableBodyCell user={value.user} userVariable={userVariable} value={value[userVariable.id].value} overrides={value[userVariable.id].overrides} />),
        disableHeaderCellComponent: true,
      };
      cols.push(variableColumn);
    });

    return cols;
  }, [data, selectedUsers, usersVariables, handleAllUsersCheckboxChange, handleUserCheckboxChange]);

  const resetFilters = useCallback(() => {
    setSelectedTeamId(null);
    setSearchedUser('');
  }, []);

  const handleSelectTeam = useCallback((newTeamId) => {
    if (newTeamId === '-1') {
      setSelectedTeamId(null);
      return;
    }

    setSelectedTeamId(newTeamId);
  }, []);

  const handleOnlyActiveUsersCheckboxChange = useCallback((e) => {
    setOnlyActiveUsers(e.target.checked);
  }, []);

  const handleDateFilterChange = useCallback((_, newDateString) => {
    const newDate = moment.utc(newDateString).format('YYYY-MM-DD');
    const QSToAdd = {
      [USER_VARIABLE_DATE_FILTER_QS_KEY]: newDate,
    };
    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleEditSuccess = useCallback(() => {
    changeSelectedUsers([]);
  }, [changeSelectedUsers]);

  const inBulkEditNode = useMemo(() => {
    if (selectedUsers.length === 0) return null;

    return (
      <InBulkEditVariableValue className={styles.inBulkEditVariableValue} users={selectedUsers} onEditSuccess={handleEditSuccess} />
    );
  }, [selectedUsers, handleEditSuccess]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.filters}>
        <TeamSelector
          selectedTeam={selectedTeamId}
          onTeamSelected={handleSelectTeam}
          disabled={loading}
        />
        <Input
          className={styles.search}
          type="search"
          placeholder={t('usersVariablesPageContent.userSearch.placeholder')}
          onChange={setSearchedUser}
          value={searchedUser}
          disabled={loading}
        />
        <div className={styles.onlyActiveFilter}>
          <Checkbox
            checked={onlyActiveUsers}
            onChange={(e) => handleOnlyActiveUsersCheckboxChange(e)}
            disabled={loading}
          />
          <div className={styles.onlyActiveFilterLabel}>
            {t('usersVariablesPageContent.onlyActiveFilter.label')}
          </div>
        </div>
        {
          (selectedTeamId !== null || searchedUser !== '') && (
            <Button
              className={styles.resetFiltersBtn}
              type="link"
              icon={<BarsFilled width={24} height={24} />}
              onClick={resetFilters}
              disabled={loading}
            >
              {t('usersVariablesPageContent.resetFilters.label')}
            </Button>
          )
        }
      </div>
      <div className={styles.dateFilterWrapper}>
        <div className={styles.dateFilterLabel}>
          {t('usersVariablesPageContent.dateFilter.label')}
        </div>
        <DatePicker
          className={styles.datePicker}
          picker="date"
          format={shortFormatWithShortYear}
          allowClear={false}
          disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
          disabled={loading}
          value={moment.utc(dateFilterValue)}
          onChange={handleDateFilterChange}
        />
      </div>
      <Loader spinning={loading}>
        <div className={styles.table}>
          <Table columns={columns} data={data} nbOfFixedColumns={2} stickyHeader fitInContainer />
          {
            data.length === 0 && (
              <DefaultEmptyState className={styles.emptyState} />
            )
          }
        </div>
        {inBulkEditNode}
      </Loader>
    </div>
  );
};

UsersVariablesPageContent.propTypes = {
  className: PropTypes.string,
};

UsersVariablesPageContent.defaultProps = {
  className: '',
};

export default UsersVariablesPageContent;
