import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { DASHBOARD_PRESET_USER_QS_KEY } from '@palette/constants/dashboard';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

export const useDashboardPresetInParams = () => {
  const { presetId } = useParams();

  const dashboardPreset = useSelector((state) => DashboardPresetsSelectors.getDashboardPresetById(state, { presetId }));
  const isPending = useSelector((state) => DashboardPresetsSelectors.getByIdIsPending(state));

  return { presetId, dashboardPreset, isPending };
};

export const useDashboardPresetUserInSearch = () => {
  const [presetUserId, presetUserIdInSearch] = useKeyInSearch(DASHBOARD_PRESET_USER_QS_KEY, null);

  const presetUser = useSelector((state) => DashboardPresetsSelectors.getPresetCandidateUser(state, { presetUserId }));

  return { presetUserId, presetUserIdInSearch, presetUser };
};

export const useDashboardPresetsList = () => {
  const dispatch = useDispatch();

  const dashboardPresetsList = useSelector(DashboardPresetsSelectors.getDashboardPresetsList);

  useEffect(() => {
    dispatch(DashboardPresetsActions.getDashboardPresetsList());
  }, []);

  return dashboardPresetsList;
};
