import React, { Component } from 'react';
import { Popconfirm } from 'antd';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';

import { connect } from 'react-redux';
import { canSeeNPS } from '@palette/helpers/ProfileHelper';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import { isSuperAdmin } from '../services/utils';
import NPSRating from './npsRating';
import { sendNPSFeedback } from '../services/user';
import { sendEvent } from '../services/analytics';
import '../styles/feedbackRateModal.less';

class FeedbackRateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      loading: false,
    };
  }

  componentDidMount() {
    if (!isSuperAdmin() && canSeeNPS(this.props.profile)) {
      this.openFeedbackRate();
    }
  }

  openFeedbackRate = () => this.setState({ isOpen: true });

  dismissRate = () => {
    this.setState({ loading: true }, () => {
      sendNPSFeedback({})
        .then(() => {
          sendEvent('dismiss NPS popup');
        })
        .finally(() => {
          this.setState({ loading: false }, () => {
            this.closeModal();
          });
        });
    });
  };

  closeModal = () => this.setState({
    isOpen: false,
  });

  render() {
    const { isOpen, loading } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <div
        className="Feedback_rate_modal"
      >
        <div className="flex">
          <div
            className="bold"
            style={{ fontSize: '16px', flexGrow: 1 }}
          >
            How likely are you to recommend Palette to a friend or colleague?
          </div>
          <div
            style={{
              cursor: 'pointer',
              color: 'grey',
              paddingTop: '4px',
            }}
          >
            <Popconfirm
              placement="topRight"
              title="Are you sure you want to dismiss?"
              onConfirm={this.dismissRate}
              okText="Yes"
              cancelText="No"
              overlayStyle={{ zIndex: 1111 }}
            >
              {loading ? <LoadingOutlined /> : <CloseOutlined />}
            </Popconfirm>
          </div>
        </div>
        <NPSRating
          close={this.closeModal}
        />
      </div>
    );
  }
}

FeedbackRateModal.propTypes = {
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(FeedbackRateModal);
