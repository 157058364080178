import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* planV3getList() {
  // TODO: to be removed from api
  const params = { page: 1, limit: 10 };

  return yield checkEndpointRights(
    () => ApiService.get(apiConfig.ENDPOINTS.planV3.crud, params, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* planV3getICList() {
  return yield checkEndpointRights(
    () => ApiService.get(apiConfig.ENDPOINTS.ic.planV3.crud, undefined, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* planV3delete(payload) {
  const { planId } = payload;

  const endpoint = `${apiConfig.ENDPOINTS.planV3.crud}/${planId}`;

  return yield checkEndpointRights(
    () => ApiService.delete(endpoint, {}, getConfig()),
    [RIGHTS.ADMIN.PLANS.DELETE],
  );
}

export function* planV3getListUsers() {
  return yield checkEndpointRights(
    () => ApiService.get(apiConfig.ENDPOINTS.planV3.listUsers, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* planV3create(payload) {
  const { requirements, dataSources, users } = payload;

  const params = { requirements, dataSources, users };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.planV3.crud, params, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE],
  );
}

export function* planV3GetConfiguration(payload) {
  const { planId } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.configurationCrud.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* planV3GetICConfiguration(payload) {
  const { planId } = payload;

  const endpoint = apiConfig.ENDPOINTS.ic.planV3.configurationCrud.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.IC.COMPENSATION],
  );
}

export function* planV3UpdateConfiguration(payload) {
  const { planId, requirements, dataSources, users } = payload;

  const params = { requirements, dataSources, users };

  const endpoint = apiConfig.ENDPOINTS.planV3.configurationCrud.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.put(endpoint, params, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3GetDetails(payload) {
  const { planId } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.details.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* planV3RunCodeCell(payload) {
  const { planId, codeCellId, cellCode } = payload;

  const data = { code: cellCode };

  const endpoint = apiConfig.ENDPOINTS.planV3.runCodeCell.replace('{planId}', planId).replace('{codeCellId}', codeCellId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3SavePlan(payload) {
  const { planId, codeCells } = payload;

  const data = { codeCells };

  const endpoint = apiConfig.ENDPOINTS.planV3.savePlan.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3GetAchievementsList(payload) {
  const { planId } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.achievementsList.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* planV3GetInputTablesList(payload) {
  const { planId } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.inputTablesList.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.get(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.VIEW],
  );
}

export function* planV3CreateInputTable(payload) {
  const { planId, type, name, columns } = payload;

  const data = { type, name, columns };

  const endpoint = apiConfig.ENDPOINTS.planV3.createInputTable.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3AddRowToInputTable(payload) {
  const { planId, inputTableId, row } = payload;

  const data = row;

  const endpoint = apiConfig.ENDPOINTS.planV3.addRowToInputTable.replace('{planId}', planId).replace('{inputTableId}', inputTableId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3DeleteRowFromInputTable(payload) {
  const { planId, inputTableId, rowPosition } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.deleteRowFromInputTable.replace('{planId}', planId).replace('{inputTableId}', inputTableId).replace('{rowPosition}', rowPosition);

  return yield checkEndpointRights(
    () => ApiService.delete(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3AddColumnToInputTable(payload) {
  const { planId, inputTableId, column } = payload;

  const data = column;

  const endpoint = apiConfig.ENDPOINTS.planV3.addColumnToInputTable.replace('{planId}', planId).replace('{inputTableId}', inputTableId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3DeleteColumnFromInputTable(payload) {
  const { planId, inputTableId, columnId } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.deleteColumnFromInputTable.replace('{planId}', planId).replace('{inputTableId}', inputTableId).replace('{columnId}', columnId);

  return yield checkEndpointRights(
    () => ApiService.delete(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3UpdateCellContentFromInputTable(payload) {
  const { planId, inputTableId, rowPosition, values } = payload;

  const data = {
    values,
  };

  const endpoint = apiConfig.ENDPOINTS.planV3.updateCellContentFromInputTable.replace('{planId}', planId).replace('{inputTableId}', inputTableId).replace('{rowPosition}', rowPosition);

  return yield checkEndpointRights(
    () => ApiService.put(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3UpdateColumnFromInputTable(payload) {
  const { planId, inputTableId, columnId, label, type } = payload;

  const data = {
    label,
    type,
  };

  const endpoint = apiConfig.ENDPOINTS.planV3.updateColumnFromInputTable.replace('{planId}', planId).replace('{inputTableId}', inputTableId).replace('{columnId}', columnId);

  return yield checkEndpointRights(
    () => ApiService.put(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3FreezePeriod(payload) {
  const { planId, periodId } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.freezePeriod.replace('{planId}', planId).replace('{periodId}', periodId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* planV3UnfreezePeriod(payload) {
  const { planId, periodId } = payload;

  const endpoint = apiConfig.ENDPOINTS.planV3.unfreezePeriod.replace('{planId}', planId).replace('{periodId}', periodId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, undefined, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}

export function* generateWithAI(payload) {
  const { planId, codeCellId, prompt } = payload;

  const data = {
    input: prompt,
  };

  const endpoint = apiConfig.ENDPOINTS.planV3.generateWithAI.replace('{planId}', planId).replace('{codeCellId}', codeCellId);

  return yield checkEndpointRights(
    () => ApiService.post(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.UPDATE],
  );
}
