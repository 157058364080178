import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { SCOPES, RULE_TYPES } from '@palette/constants/masterPlans';

import { capitalize, comaSeparatorFormatter } from '@palette/helpers/CommonHelper';

import * as PlanModel from '@palette/models/MasterPlan';
import * as RuleModel from '@palette/models/PlanRule';

import styles from './MasterPlanSettingsCommissionRulesStepItem.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCommissionRulesStepItem = ({ className, plan, rule }) => {
  const { t } = useTranslation();

  const usersDefinitionType = plan.usersDefinition?.type;
  const trackingObjectType = plan.trackingObject?.type;
  const trackingObjectOriginalType = plan.trackingObject?.originalType;

  const renderRule = () => {
    let percentVal = 1;
    let toFixed = 2;
    let percent = '';

    if (rule.percentage) {
      percentVal = 100;
      toFixed = 0;
      percent = '%';
    }

    if (rule.type === RULE_TYPES.PER_OBJECT) {
      if (rule.brackets.length === 1) {
        return (
          <div className={styles.rule}>
            <span className={styles.text}>{capitalize(t('masterPlanSettingsCommissionRules.youEarn'))}</span>
            <span className={styles.formula}>{rule.brackets[0].formula}</span>
            {usersDefinitionType !== trackingObjectOriginalType && (
              <span className={styles.text}>{`${t('masterPlanSettingsCommissionRules.ofEach')} ${trackingObjectType}.`}</span>
            )}
          </div>
        );
      }

      if (rule.brackets.length > 1) {
        return rule.brackets.map((bracket) => {
          let title;
          let text;

          const from = parseFloat(bracket.from * percentVal);
          const to = parseFloat(bracket.to * percentVal);

          if (bracket.to === Infinity) {
            title = `${t('masterPlanSettingsCommissionRules.moreThan')} ${from.toFixed(toFixed)}${percent}`;
            text = `${t('masterPlanSettingsCommissionRules.moreThan')} ${from.toFixed(toFixed)}${percent} ${t('masterPlanSettingsCommissionRules.youEarn')}`;
          } else {
            title = `${from.toFixed(toFixed)}${percent}`;
            text = `${t('masterPlanSettingsCommissionRules.from')} ${from.toFixed(toFixed)}${percent}`;
            if (bracket.to) {
              title += ` - ${to.toFixed(toFixed)}${percent}`;
              text += ` ${t('masterPlanSettingsCommissionRules.to')} ${to.toFixed(toFixed)}${percent}`;
              if (rule.percentage) {
                text += ` ${t('masterPlanSettingsCommissionRules.ofYourGoal')}`;
              }
              text += `, ${t('masterPlanSettingsCommissionRules.youEarn')}`;
            }
          }

          return (
            <div className={styles.rule} key={bracket.id}>
              <span className={styles.title}>{title}</span>
              <span className={styles.text}>{text}</span>
              <span className={styles.formula}>{bracket.formula}</span>
              {usersDefinitionType !== trackingObjectOriginalType && (
                <span className={styles.text}>{`${t('masterPlanSettingsCommissionRules.ofEach')} ${trackingObjectType}.`}</span>
              )}
            </div>
          );
        });
      }
    } else if (rule.type === RULE_TYPES.PER_TARGET) {
      return rule.brackets.slice(1).map((bracket) => {
        let title = `${t('masterPlanSettingsCommissionRules.ifYouReach')} `;

        if (rule.percentage) {
          title += `${parseFloat(bracket.from * 100).toFixed(0)}% of `;
          if (plan.scope === SCOPES.INDIVIDUAL) {
            title += `${t('masterPlanSettingsCommissionRules.your')} `;
          } else {
            title += `${t('masterPlanSettingsCommissionRules.the')} `;
          }
          title += t('masterPlanSettingsCommissionRules.target');
        } else {
          title += comaSeparatorFormatter(bracket.from);
        }

        return (
          <div className={styles.rule} key={bracket.id}>
            <span className={styles.title}>{title}</span>
            <span className={styles.text}>{capitalize(t('masterPlanSettingsCommissionRules.youEarn'))}</span>
            <span className={styles.formula}>{bracket.formula}</span>
          </div>
        );
      });
    }

    return null;
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {renderRule()}
    </div>
  );
};

MasterPlanSettingsCommissionRulesStepItem.propTypes = {
  className: PropTypes.string,
  plan: PlanModel.propTypes.isRequired,
  rule: RuleModel.propTypes.isRequired,
};

MasterPlanSettingsCommissionRulesStepItem.defaultProps = {
  className: '',
};

export default MasterPlanSettingsCommissionRulesStepItem;
