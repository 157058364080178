import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import EditVariableValueModal from '@palette/components/usersVariables/EditVariableValueModal/EditVariableValueModal';
import Select from '@palette/components/designSystem/Select/Select';

import * as MetaUserModel from '@palette/models/MetaUser';

import { selectors as UsersVariablesSelectors } from '@palette/state/UsersVariables';

import styles from './InBulkEditVariableValue.less';

const classNames = bindClassNames.bind(styles);

const InBulkEditVariableValue = ({ className, users, onEditSuccess }) => {
  const { t } = useTranslation();

  const usersVariables = useSelector(UsersVariablesSelectors.getUsersVariables);

  const usersVariablesOptions = useMemo(() => (
    usersVariables.variables.map((userVariable) => ({
      label: userVariable.name,
      value: userVariable.id,
    }))
  ), [usersVariables]);

  const [editVariableValueModalIsVisible, showEditVariableValueModal] = useState(false);
  const [selectedUserVariableId, setSelectedUserVariableId] = useState(null);

  useEffect(() => {
    if (usersVariables.variables.length === 0) return;

    setSelectedUserVariableId(usersVariables.variables[0].id);
  }, [usersVariables]);

  const editVariableValueModalNode = useMemo(() => {
    if (!editVariableValueModalIsVisible || selectedUserVariableId === null) return null;

    const selectedUserVariable = usersVariables.variables.find((userVariable) => (userVariable.id === selectedUserVariableId));

    return (
      <EditVariableValueModal
        visible
        onClose={() => showEditVariableValueModal(false)}
        users={users}
        userVariable={selectedUserVariable}
        currentValue={selectedUserVariable.defaultValue}
        onEditSuccess={onEditSuccess}
      />
    );
  }, [editVariableValueModalIsVisible, selectedUserVariableId, usersVariables, onEditSuccess]);

  if (usersVariables.variables.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Select
        className={styles.usersVariablesSelect}
        dropdownMatchSelectWidth={false}
        options={usersVariablesOptions}
        value={selectedUserVariableId}
        onChange={setSelectedUserVariableId}
      />
      <Button
        className={styles.inBulkEditBtn}
        type="primaryBlue"
        icon={(<PenFilled width={16} height={16} />)}
        onClick={() => showEditVariableValueModal(true)}
      >
        {t('inBulkEditVariableValue.inBulkEdit')}
      </Button>
      {editVariableValueModalNode}
    </div>
  );
};

InBulkEditVariableValue.propTypes = {
  className: PropTypes.string,
  users: PropTypes.arrayOf(MetaUserModel.propTypes).isRequired,
  onEditSuccess: PropTypes.func,
};

InBulkEditVariableValue.defaultProps = {
  className: '',
  onEditSuccess: null,
};

export default InBulkEditVariableValue;
