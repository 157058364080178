import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Dropdown as AntDDropdown, Menu as AntDMenu } from 'antd';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Checkbox from '@palette/components/designSystem/Checkbox/Checkbox';
import DragLine from '@palette/components/utils/Icons/DragLine';
import ThreeDotsFilled from '@palette/components/utils/Icons/ThreeDotsFilled';

import styles from './MoreMenuCheckbox.less';

const classNames = bindClassNames.bind(styles);

const MoreMenuCheckbox = ({
  className,
  items,
  children,
  onClose,
  onMove,
  ...otherProps
}) => {
  const [open, setOpen] = useState(false);

  let childrenNode = (
    <div className={styles.contentWrapper}>
      <ThreeDotsFilled className={styles.icon} width={24} height={24} />
    </div>
  );

  if (children !== null) {
    childrenNode = children;
  }

  const handleClick = () => setOpen(true);

  const handleVisibleChange = (flag) => {
    setOpen(flag);

    if (!flag && onClose) onClose();
  };

  /* ## Drag & Drop management ## */
  const handleDragStart = (result) => {
    const filterIndex = result.source.index;

    if (!items[filterIndex].isChecked) {
      document.body.style.cursor = 'no-drop';
    } else {
      document.body.style.cursor = 'grabbing';
    }
  };

  const handleDragEnd = (result) => {
    document.body.style.cursor = 'default';

    if (!result.destination) return;

    const filterIndex = result.source.index;
    const toPositionIndex = result.destination.index;

    if (onMove) onMove(filterIndex, toPositionIndex);
  };

  const getItemStyle = (draggableStyle, isDraggable) => ({
    userSelect: 'none',
    cursor: isDraggable ? 'grab' : 'no-drop',
    ...draggableStyle,
  });

  const filtersItemsNode = useMemo(() => items.map((item, index) => {
    const checkboxItemNode = (
      <Checkbox
        className={styles.menuItemTitle}
        checked={item.isChecked}
        onChange={item.onChange}
      >
        {item.title}
      </Checkbox>
    );

    if (onMove) {
      return (
        <Draggable key={index} draggableId={`filter_${index}`} index={index}>
          {(providedDraggable) => (
            <div
              className={classNames({
                menuItem: true,
                [item.className]: item.className && item.className !== '',
              })}
              ref={providedDraggable.innerRef}
              {...providedDraggable.draggableProps}
              {...providedDraggable.dragHandleProps}
              style={getItemStyle(
                providedDraggable.draggableProps.style,
                item.isChecked,
              )}
              onClick={handleClick}
            >
              <DragLine
                className={classNames({
                  dragIcon: true,
                  disabled: !item.isChecked,
                })}
                width={20}
                height={20}
              />
              {checkboxItemNode}
            </div>
          )}
        </Draggable>
      );
    }

    return (
      <AntDMenu.Item key={item.key} onClick={handleClick}>
        <div
          className={classNames({
            menuItem: true,
            [item.className]: item.className && item.className !== '',
          })}
        >
          {checkboxItemNode}
        </div>
      </AntDMenu.Item>
    );
  }), [items, onMove]);

  const filtersItemsContainerNode = useMemo(() => {
    if (!onMove) return filtersItemsNode;

    return (
      <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <Droppable droppableId="filtersDroppable">
          {
            (providedDroppable) => (
              <div
                {...providedDroppable.droppableProps}
                ref={providedDroppable.innerRef}
              >
                {filtersItemsNode}
                {providedDroppable.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
    );
  }, [onMove, filtersItemsNode]);

  return (
    <AntDDropdown
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      overlayClassName={styles.overlay}
      trigger="click"
      onVisibleChange={handleVisibleChange}
      visible={open}
      overlay={(
        <AntDMenu>
          {filtersItemsContainerNode}
        </AntDMenu>
      )}
      {...otherProps}
    >
      <div>
        {childrenNode}
      </div>
    </AntDDropdown>
  );
};

MoreMenuCheckbox.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
  })).isRequired,
  children: PropTypes.any,
  onClose: PropTypes.func,
  onMove: PropTypes.func, // Required only if items list needs to be orderable.
};

MoreMenuCheckbox.defaultProps = {
  className: '',
  children: null,
  onClose: undefined,
  onMove: undefined,
};

export default MoreMenuCheckbox;
