import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getSearch, redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as QuotaPerformanceModel from '@palette/models/QuotaPerformance';

import { selectors as NavigationSelectors } from '@palette/state/Navigation';

import styles from './QuotaPerformanceListItem.less';

const classNames = bindClassNames.bind(styles);

const QuotaPerformanceListItem = ({ className, dashboard }) => {
  const { t } = useTranslation();
  const { dashboardId } = useParams();

  const location = useSelector(NavigationSelectors.getCurrentLocation);

  const handleClick = useCallback(() => {
    const qsObject = getSearch(location);

    redirectTo({
      path: routePaths.v2.quotasPerformanceDetails,
      params: {
        dashboardId: dashboard.id,
      },
      qsObject,
    });
  }, [location]);

  return (
    <div
      className={classNames({
        wrapper: true,
        selected: dashboardId === dashboard.id,
        [className]: className !== '',
      })}
      onClick={handleClick}
    >
      <div className={styles.name}>
        {dashboard.name}
      </div>
      <div className={styles.infos}>
        {t('plansWithCount', { count: dashboard.totalPlans })}
      </div>
    </div>
  );
};

QuotaPerformanceListItem.propTypes = {
  className: PropTypes.string,
  dashboard: QuotaPerformanceModel.propTypes.isRequired,
};

QuotaPerformanceListItem.defaultProps = {
  className: '',
};

export default QuotaPerformanceListItem;
