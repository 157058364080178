import getI18n from '@palette/i18n';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import { DASHBOARD_WIDGETS } from '@palette/constants/dashboard';

import * as DashboardWidgetParamsModel from '@palette/models/DashboardWidgetParams';

export const buildWidgetParams = (widgetType, allParamsPayload) => {
  const transformedParams = DashboardWidgetParamsModel.transform(allParamsPayload);
  const allParams = {
    ...transformedParams,
    ...allParamsPayload,
  };

  return DASHBOARD_WIDGETS[widgetType].getParams(allParams);
};

export const formatWidgetPrice = (amount, currency) => {
  if (amount === undefined || amount === 0) return '-';

  return formatPrice(amount, currency);
};

export const enhanceComponentsDataWithDealsType = (componentDataList, dealsType = null) => {
  let finalDealsType = dealsType;
  if (dealsType === null) {
    const i18n = getI18n();
    finalDealsType = i18n.t('componentData.defaultDealType');
  }

  return componentDataList.map((componentData) => ({
    ...componentData,
    data: {
      ...componentData.data,
      dealsType: finalDealsType,
    },
  }));
};
