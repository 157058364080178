import PropTypes from 'prop-types';

import { manageArrayAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import * as FieldDefinitionModel from '@palette/models/FieldDefinition';

export const modelName = 'ResourceConfiguration';

export const propTypes = PropTypes.shape({
  type: PropTypes.string,
  fields: PropTypes.arrayOf(FieldDefinitionModel.propTypes),
});

export const transform = (resourceConfiguration) => {
  if (!resourceConfiguration) {
    return null;
  }

  return (
    {
      type: manageStringAttribute(resourceConfiguration, 'type'),
      fields: FieldDefinitionModel.transformList(manageArrayAttribute(resourceConfiguration, 'fields')),
    }
  );
};

export const transformList = (resourceConfigurations) => resourceConfigurations.map((resourceConfiguration) => transform(resourceConfiguration));
