import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import TableBodyRow from '@palette/components/designSystem/TableBodyRow/TableBodyRow';
import TableBodyCell from '@palette/components/designSystem/TableBodyCell/TableBodyCell';

import styles from './TableBody.less';

const classNames = bindClassNames.bind(styles);

const TableBody = ({
  className,
  type,
  tableBodyProps,
  rows,
  prepareRow,
  BodyCellComponent,
  nbOfFixedColumns,
  fixedColumnsPosition,
  stretch,
  highlightRowOnHover,
  hasFooters,
  enableExpandRow,
  renderExpandedRow,
  horizontalScrollingOnLeft,
  horizontalScrollingOnRight,
}) => (
  <div
    className={classNames({
      body: true,
      stretch,
      borderless: type === 'borderless',
      [className]: className !== '',
    })}
    {...tableBodyProps}
  >
    {
      rows.map((row) => (
        <TableBodyRow
          key={row.id}
          type={type}
          row={row}
          prepareRow={prepareRow}
          BodyCellComponent={BodyCellComponent}
          nbOfFixedColumns={nbOfFixedColumns}
          fixedColumnsPosition={fixedColumnsPosition}
          stretch={stretch}
          highlightOnHover={highlightRowOnHover}
          hasFooters={hasFooters}
          enableExpandRow={enableExpandRow}
          renderExpandedRow={renderExpandedRow}
          horizontalScrollingOnLeft={horizontalScrollingOnLeft}
          horizontalScrollingOnRight={horizontalScrollingOnRight}
        />
      ))
    }
  </div>
);

TableBody.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  tableBodyProps: PropTypes.object,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  prepareRow: PropTypes.func.isRequired,
  BodyCellComponent: PropTypes.func,
  nbOfFixedColumns: PropTypes.number,
  fixedColumnsPosition: PropTypes.oneOf(['fromLeft', 'fromRight']),
  stretch: PropTypes.bool,
  highlightRowOnHover: PropTypes.bool,
  hasFooters: PropTypes.bool,
  enableExpandRow: PropTypes.bool,
  renderExpandedRow: PropTypes.func,
  horizontalScrollingOnLeft: PropTypes.bool,
  horizontalScrollingOnRight: PropTypes.bool,
};

TableBody.defaultProps = {
  className: '',
  type: 'default',
  tableBodyProps: {},
  BodyCellComponent: TableBodyCell,
  nbOfFixedColumns: 0,
  fixedColumnsPosition: 'fromLeft',
  stretch: false,
  highlightRowOnHover: false,
  hasFooters: false,
  enableExpandRow: false,
  renderExpandedRow: null,
  horizontalScrollingOnLeft: false,
  horizontalScrollingOnRight: false,
};

export default TableBody;
