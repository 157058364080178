import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';
import { useStatementPeriodStatus } from '@palette/helpers/StatementV3Helper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_PERIOD_STATUSES, STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';
import { STATEMENT_ACTION_TYPES } from '@palette/constants/StatementV3';

import { usePeriodsDatesFilter } from '@palette/hooks/StatementHooks';

import * as StatementV3PeriodModel from '@palette/models/StatementV3Period';
import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';
import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './StatementV3MarkAsPaidAction.less';

const classNames = bindClassNames.bind(styles);

const StatementV3MarkAsPaidAction = ({
  className,
  userStatement,
  currency,
  isSecondaryType,
  userId,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const profile = useProfile();
  const dispatch = useDispatch();

  const addActionIsPending = useSelector(StatementV3Selectors.addActionIsPending);

  const markAsPaidIsPending = useSelector((state) => StatementV3Selectors.getMarkAsPaidIsPending(state, { statementPeriodId: userStatement.id }));
  const markAsPaidIsWaiting = useSelector((state) => StatementV3Selectors.getMarkAsPaidIsWaiting(state, { statementPeriodId: userStatement.id }));

  const statementPeriodStatus = useStatementPeriodStatus(userStatement);

  const markAsPaidAction = userStatement.actions.find((action) => action.type === STATEMENT_ACTION_TYPES.PAYMENT);

  const user = useSelector((state) => UsersSelectors.getUserById(state, { userId: markAsPaidAction?.userId }));

  const [, from, to] = usePeriodsDatesFilter();

  const handleMarkAsPaid = useCallback(() => {
    dispatch(StatementV3Actions.addAction({
      statementPeriodId: userStatement.id,
      type: STATEMENT_ACTION_TYPES.PAYMENT,
      currency,
      statementCurrency: userStatement.currency,
      from,
      to,
      userId,
    }));
  }, [userStatement, currency, from, to, userId]);

  const statusTooltipWrapper = useCallback((children) => {
    if (!user || !markAsPaidAction) return children;

    const paidByDate = moment(markAsPaidAction.date).format('M/DD/YYYY');
    const paidByAmount = formatPrice(markAsPaidAction.amount, markAsPaidAction.currency || currency);
    const paidByUserName = user.displayName;

    return (
      <Tooltip
        title={paidByDate}
        content={(
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('statement.paidBy.tooltip.label', {
                userName: paidByUserName,
                amount: paidByAmount,
              }),
            }}
          />
        )}
      >
        <div>
          {children}
        </div>
      </Tooltip>
    );
  }, [markAsPaidAction, user, currency]);

  const contentNode = useMemo(() => {
    if (!statementPeriodStatus) return null;

    if (statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_STATUSES.IN_FUTURE;
      return statusTooltipWrapper(<Tag theme={theme} label={t(i18n)} icon={icon} />);
    }

    if (statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAID) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_USER_STATUSES.PAID;
      return statusTooltipWrapper(<Tag theme={theme} label={t(i18n)} icon={icon} />);
    }

    let btnLabel = t('statementPeriodUsers.markAsPaid.button.label');
    if (markAsPaidIsWaiting) {
      btnLabel = t('statementPeriodUsers.markAsPaid.button.waitingLabel');
    }
    if (markAsPaidIsPending) {
      btnLabel = t('statementPeriodUsers.markAsPaid.button.processingLabel');
    }

    return (
      <Popconfirm
        title={t('statementPeriodUsers.markAsPaid.button.popconfirm')}
        onConfirm={handleMarkAsPaid}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
        disabled={addActionIsPending}
      >
        <Button
          className={classNames({ smallButton: isSecondaryType })}
          type={isSecondaryType ? 'successSecondary' : 'primary'}
          icon={(markAsPaidIsPending || markAsPaidIsWaiting) ? <LoadingLine spin /> : <StatementsFilled />}
          disabled={addActionIsPending}
        >
          {btnLabel}
        </Button>
      </Popconfirm>
    );
  }, [
    userStatement,
    statementPeriodStatus,
    addActionIsPending,
    markAsPaidIsPending,
    markAsPaidIsWaiting,
    statusTooltipWrapper,
    handleMarkAsPaid,
    isSecondaryType,
  ]);

  const contentContainer = useMemo(() => {
    if (!contentNode) return null;

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
    );
  }, [contentNode]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return contentContainer;
};

StatementV3MarkAsPaidAction.propTypes = {
  className: PropTypes.string,
  userStatement: PropTypes.oneOfType([StatementV3PeriodModel.propTypes, StatementV3OneStatementModel.propTypes]),
  currency: PropTypes.string,
  isSecondaryType: PropTypes.bool,
  userId: PropTypes.string,
};

StatementV3MarkAsPaidAction.defaultProps = {
  className: '',
  userStatement: null,
  currency: null,
  isSecondaryType: false,
  userId: null,
};

export default StatementV3MarkAsPaidAction;
