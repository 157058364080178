import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { PLAN_INJECTION_PERIOD_MATCH_STRATEGIES, PLAN_INJECTION_TYPES } from '@palette/constants/masterPlans';

import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'MasterPlanInjection';

export const propTypes = PropTypes.shape({
  planId: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(PLAN_INJECTION_TYPES)),
  withDataPoints: PropTypes.bool,
  withPayments: PropTypes.bool,
  periodMatchType: PropTypes.oneOf(Object.values(PLAN_INJECTION_PERIOD_MATCH_STRATEGIES)),
  absolutePeriod: YearPeriodModel.propTypes,
  relativePeriod: PropTypes.number,
  periodFormula: PropTypes.string,
});

export const manageMasterPlanInjectionType = (masterPlanInjection) => (PLAN_INJECTION_TYPES[manageStringAttribute(masterPlanInjection, 'type')] || PLAN_INJECTION_TYPES.MATCH_USER);

export const manageMasterPlanInjectionPeriodMathStrategy = (masterPlanInjection) => (PLAN_INJECTION_PERIOD_MATCH_STRATEGIES[manageStringAttribute(masterPlanInjection, 'periodMatchType')] || PLAN_INJECTION_PERIOD_MATCH_STRATEGIES.AUTO);

export const transform = (masterPlanInjection) => {
  if (!masterPlanInjection) {
    return null;
  }

  return (
    {
      planId: manageStringAttribute(masterPlanInjection, 'plan'),
      name: manageStringAttribute(masterPlanInjection, 'name'),
      type: manageMasterPlanInjectionType(masterPlanInjection),
      withDataPoints: manageBooleanAttribute(masterPlanInjection, 'withDataPoints'),
      withPayments: manageBooleanAttribute(masterPlanInjection, 'withPayments'),
      periodMatchType: manageMasterPlanInjectionPeriodMathStrategy(masterPlanInjection),
      absolutePeriod: YearPeriodModel.transform(masterPlanInjection.absolutePeriod),
      relativePeriod: manageNumberAttribute(masterPlanInjection, 'relativePeriod'),
      periodFormula: manageStringAttribute(masterPlanInjection, 'periodFormula'),
    }
  );
};

export const transformList = (masterPlanInjections) => masterPlanInjections.map((masterPlanInjection) => transform(masterPlanInjection));
