import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _isEqual from 'lodash/isEqual';

import UserConnectorsSelect from '@palette/components/user/UserConnectorsSelect/UserConnectorsSelect';
import UpdateUserTypeConfirmationModal from '@palette/components/masterPlan/UpdateUserTypeConfirmationModal/UpdateUserTypeConfirmationModal';

import styles from './UsersDefinitionConnectorsSelect.less';

const classNames = bindClassNames.bind(styles);

const UsersDefinitionConnectorsSelect = ({ className, onChange, value, initialValue, ...otherProps }) => {
  const [updateUserTypeConfirmationModalIsVisible, showUpdateUserTypeConfirmationModal] = useState(false);
  const [tempPreviousValue, setTempPreviousValue] = useState(null);

  const [selectedConnector, setSelectedConnector] = useState(value);

  useEffect(() => {
    setSelectedConnector(value);
  }, [value]);

  const handleChange = (newValue) => {
    setSelectedConnector(newValue);

    if (onChange !== null) {
      onChange(newValue);
    }
  };

  const handleSelectConnector = useCallback((newValue, connectorId, type) => {
    const newValueObject = { connectorId, type };

    if (selectedConnector !== null && !_isEqual(initialValue, newValueObject)) {
      setTempPreviousValue(selectedConnector);
      showUpdateUserTypeConfirmationModal(true);
    }

    handleChange(newValueObject);
  }, [selectedConnector]);

  const handleCancelUpdateUserType = useCallback(() => {
    if (tempPreviousValue !== null) {
      handleChange(tempPreviousValue);
    }
    setTempPreviousValue(null);
    showUpdateUserTypeConfirmationModal(false);
  }, [selectedConnector, tempPreviousValue]);

  const handleUpdateUserType = useCallback(() => {
    showUpdateUserTypeConfirmationModal(false);
  }, [selectedConnector, tempPreviousValue]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <UserConnectorsSelect
        onSelect={handleSelectConnector}
        value={value}
        {...otherProps}
      />
      {
        updateUserTypeConfirmationModalIsVisible && (
          <UpdateUserTypeConfirmationModal visible onCancel={handleCancelUpdateUserType} onConfirm={handleUpdateUserType} />
        )
      }
    </div>
  );
};

UsersDefinitionConnectorsSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  initialValue: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
};

UsersDefinitionConnectorsSelect.defaultProps = {
  className: '',
  onChange: null,
  value: null,
  initialValue: null,
};

export default UsersDefinitionConnectorsSelect;
