import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Menu } from 'antd';

import MenuItemWrapper, { MenuItemPropTypesShape } from '@palette/components/navigation/MenuItemWrapper/MenuItemWrapper';

import { useProfile } from '@palette/hooks/ProfileHooks';

const MenuWrapper = ({ menu, displayTitle, selectedKeys, ...otherProps }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const isSelected = useCallback((menuKey) => selectedKeys.includes(menuKey), [selectedKeys]);

  if (!menu.show(profile)) return null;

  return (
    <Menu.ItemGroup
      title={displayTitle && menu.title !== '' ? t(menu.title) : null}
      {...otherProps}
    >
      {
        menu.items.map((menuItem) => (
          menuItem.show(profile) && menuItem.submenuItems ? (
            <Menu.SubMenu
              key={menuItem.key}
              icon={menuItem.icon ? menuItem.icon : null}
              title={t(menuItem.name)}
            >
              {
                menuItem.submenuItems.map((submenuItem) => (
                  <MenuItemWrapper key={submenuItem.key} isSelected={isSelected(submenuItem.key)} menuItem={submenuItem} />
                ))
              }
            </Menu.SubMenu>
          ) : (
            <MenuItemWrapper key={menuItem.key} isSelected={isSelected(menuItem.key)} menuItem={menuItem} />
          )
        ))
      }
    </Menu.ItemGroup>
  );
};

MenuWrapper.propTypes = {
  menu: PropTypes.shape({
    title: PropTypes.string,
    show: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
      ...MenuItemPropTypesShape,
      submenuItems: PropTypes.arrayOf(PropTypes.shape(MenuItemPropTypesShape)),
    })),
  }).isRequired,
  displayTitle: PropTypes.bool.isRequired,
  selectedKeys: PropTypes.array,
};

MenuWrapper.defaultProps = {
  selectedKeys: [],
};

export default MenuWrapper;
