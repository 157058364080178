import React from 'react';

import appConfig from '@palette/config/app';

import styles from './MarketingBanner.less';

const LINK = 'https://techcrunch.com/2022/05/18/palette-sells-a-sales-commission-tool-for-modern-sales-teams/';

const MarketingBanner = () => {
  if (!appConfig.SHOW_MARKETING_BANNER) return null;

  return (
    <div className={styles.wrapper}>
      <span className={styles.textLong}>🎉 Palette raised $6M to help Finance teams manage commission plans</span>
      <span className={styles.textShort}>🎉 Palette raised $6M</span>

      <span className={styles.separator} />
      <a
        className="MarketingBanner_link"
        href={LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        Read article
      </a>
    </div>
  );
};

export default MarketingBanner;
