import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import { getDependencyStrategyDescription } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanInjectionModel from '@palette/models/MasterPlanInjection';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanDependencyItem.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanDependencyItem = ({ className, plan, dependency, onEditBtnClick, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dependencyPlan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: dependency.planId }));

  useEffect(() => {
    dispatch(MasterPlansActions.getById({ planId: dependency.planId }));
  }, [dependency.planId]);

  const dependencyOptionsNode = useMemo(() => {
    const dependencyOptions = [];

    if (dependency.withDataPoints) {
      dependencyOptions.push(t('masterPlanDependencyForm.form.withDataPoints.label'));
    }
    if (dependency.withPayments) {
      dependencyOptions.push(t('masterPlanDependencyForm.form.withPayments.label'));
    }

    if (dependencyOptions.length === 0) return null;

    return (
      <div className={styles.options}>
        <span>
          {t('masterPlanDependencyItem.options', { count: dependencyOptions.length })}
        </span>
        <span>
          {dependencyOptions.join(', ')}
        </span>
      </div>
    );
  }, [dependency]);

  const dependencyStrategyNode = useMemo(() => {
    const dependencyStrategyDescription = getDependencyStrategyDescription(dependency, dependencyPlan, plan);

    if (!dependencyStrategyDescription) return null;

    return (
      <div className={styles.strategyDescription}>
        {dependencyStrategyDescription}
      </div>
    );
  }, [dependency, dependencyPlan, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.dependencyInfosWrapper}>
        <div className={styles.dependencyName}>
          {dependency.name}
        </div>
        <div className={styles.planName}>
          {dependencyPlan?.name}
        </div>
        {dependencyStrategyNode}
        {dependencyOptionsNode}
      </div>
      <Button
        className={styles.editBtn}
        type="linkSecondaryBlack"
        icon={(<PenFilled width={16} height={16} />)}
        disabled={disabled}
        onClick={onEditBtnClick}
      />
    </div>
  );
};

MasterPlanDependencyItem.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  dependency: MasterPlanInjectionModel.propTypes.isRequired,
  onEditBtnClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

MasterPlanDependencyItem.defaultProps = {
  className: '',
};

export default MasterPlanDependencyItem;
