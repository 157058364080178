/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { original } from 'immer';

import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

/*
 * Initial State
 */
const initialState = {
  list: [],
  listIsPending: false,
  deletePlanIsPending: false,
  listUsersIsPending: false,
  listUsers: [],
  addDataConnectionCurrentFlowStep: null,
  newDataConnectionSource: null,
  newDataConnectionData: null,
  dataConnections: [],
  createPlanIsPending: false,
  configuration: null,
  configurationIsPending: false,
  updateConfigurationIsPending: false,
  details: null,
  detailsIsPending: false,
  runCodeCellIsPending: {},
  savePlanIsPending: false,
  achievementsList: [],
  achievementsListIsPending: false,
  inputTablesList: null,
  inputTablesListIsPending: false,
  createInputTableIsPending: false,
  addRowToInputTableIsPending: false,
  addMultipleRowsToInputTableIsPending: false,
  deleteRowFromInputTableIsPending: false,
  deleteMultipleRowsFromInputTableIsPending: false,
  addColumnToInputTableIsPending: false,
  addMultipleColumnsToInputTableIsPending: false,
  deleteColumnFromInputTableIsPending: false,
  deleteMultipleColumnsFromInputTableIsPending: false,
  updateCellContentFromInputTableIsPending: false,
  updateColumnFromInputTableIsPending: false,
  generateWithAIIsPending: false,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'planV3',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* getList */
    getList: (state) => {
      state.listIsPending = true;
    },
    setList: (state, { payload }) => {
      const { list } = payload;
      state.list = list;
    },
    getListCompleted: (state) => {
      state.listIsPending = false;
    },
    /* getICList */
    getICList: (state) => {
      state.listIsPending = true;
    },
    setICList: (state, { payload }) => {
      const { list } = payload;
      state.list = list;
    },
    getICListCompleted: (state) => {
      state.listIsPending = false;
    },
    /* deletePlan */
    deletePlan: (state) => {
      state.deletePlanIsPending = true;
    },
    deletePlanCompleted: (state) => {
      state.deletePlanIsPending = false;
    },
    /* getListUsers */
    getListUsers: (state) => {
      state.listUsersIsPending = true;
    },
    setListUsers: (state, { payload }) => {
      const { list } = payload;
      state.listUsers = list;
    },
    getListUsersCompleted: (state) => {
      state.listUsersIsPending = false;
    },
    /* Add cata connection flow Management */
    setAddDataConnectionCurrentFlowStep: (state, { payload }) => {
      const { addDataConnectionCurrentFlowStep } = payload;

      state.addDataConnectionCurrentFlowStep = addDataConnectionCurrentFlowStep;
    },
    /* Data connections management */
    setNewDataConnectionSource: (state, { payload }) => {
      const { type, params = {} } = payload;

      state.newDataConnectionSource = {
        type,
        params,
      };
    },
    setNewDataConnectionData: (state, { payload }) => {
      state.newDataConnectionData = payload;
    },
    resetNewDataConnectionInfo: (state) => {
      state.newDataConnectionSource = null;
      state.newDataConnectionData = null;
    },
    /* Add data connection */
    addDataConnectionConnector: (state, { payload }) => {
      const { connectorId, connectorName, resourceType, columns } = payload;

      const currentDataConnections = _cloneDeep(original(state.dataConnections));
      const filteredDataConnections = currentDataConnections
        .filter((dC) => !(dC.connectorId === connectorId && dC.resourceType === resourceType));

      state.dataConnections = [...filteredDataConnections, { connectorId, connectorName, resourceType, columns }];
    },
    /* Reset data connections */
    resetDataConnections: (state) => {
      state.dataConnections = [];
    },
    /* Create Plan */
    createPlan: (state) => {
      state.createPlanIsPending = true;
    },
    createPlanCompleted: (state) => {
      state.createPlanIsPending = false;
    },
    /* Get plan configuration */
    getConfiguration: (state) => {
      state.configurationIsPending = true;
    },
    setConfiguration: (state, { payload }) => {
      const { configuration } = payload;
      state.configuration = configuration;
    },
    getConfigurationCompleted: (state) => {
      state.configurationIsPending = false;
    },
    /* Get IC plan configuration */
    getICConfiguration: (state) => {
      state.configurationIsPending = true;
    },
    setICConfiguration: (state, { payload }) => {
      const { configuration } = payload;
      state.configuration = configuration;
    },
    getICConfigurationCompleted: (state) => {
      state.configurationIsPending = false;
    },
    /* Update configuration */
    updateConfiguration: (state) => {
      state.updateConfigurationIsPending = true;
    },
    updateConfigurationCompleted: (state) => {
      state.updateConfigurationIsPending = false;
    },
    /* Get plan details */
    getDetails: (state) => {
      state.detailsIsPending = true;
    },
    setDetails: (state, { payload }) => {
      const { details } = payload;
      state.details = details;
    },
    getDetailsCompleted: (state) => {
      state.detailsIsPending = false;
    },
    /* Copy variable name to clipboard */
    copyVariableNameToClipboard: () => {
      // Nothing to do here
    },
    /* Run code cell */
    runCodeCell: (state, { payload }) => {
      const { planId, codeCellId } = payload;

      const updatedRunCodeCellIsPending = {
        [planId]: {
          [codeCellId]: true,
        },
      };

      state.runCodeCellIsPending = _merge(
        _cloneDeep(original(state.runCodeCellIsPending)),
        updatedRunCodeCellIsPending,
      );
    },
    runCodeCellCompleted: (state, { payload }) => {
      const { planId, codeCellId } = payload;

      const updatedRunCodeCellIsPending = {
        [planId]: {
          [codeCellId]: false,
        },
      };

      state.runCodeCellIsPending = _merge(
        _cloneDeep(original(state.runCodeCellIsPending)),
        updatedRunCodeCellIsPending,
      );
    },
    /* Save plan */
    savePlan: (state) => {
      state.savePlanIsPending = true;
    },
    savePlanCompleted: (state) => {
      state.savePlanIsPending = false;
    },
    /* Get achievements list */
    getAchievementsList: (state) => {
      state.achievementsListIsPending = true;
    },
    setAchievementsList: (state, { payload }) => {
      const { achievementsList } = payload;
      state.achievementsList = achievementsList;
    },
    getAchievementsListCompleted: (state) => {
      state.achievementsListIsPending = false;
    },
    /* Get input tables list */
    getInputTablesList: (state) => {
      state.inputTablesListIsPending = true;
    },
    setInputTablesList: (state, { payload }) => {
      const { inputTablesList } = payload;
      state.inputTablesList = inputTablesList;
    },
    getInputTablesListCompleted: (state) => {
      state.inputTablesListIsPending = false;
    },
    /* Create input table */
    createInputTable: (state) => {
      state.createInputTableIsPending = true;
    },
    createInputTableCompleted: (state) => {
      state.createInputTableIsPending = false;
    },
    /* Add Row to input table */
    addRowToInputTable: (state) => {
      state.addRowToInputTableIsPending = true;
    },
    addRowToInputTableCompleted: (state) => {
      state.addRowToInputTableIsPending = false;
    },
    /* Add Multiple Rows to input table */
    addMultipleRowsToInputTable: (state) => {
      state.addMultipleRowsToInputTableIsPending = true;
    },
    addMultipleRowsToInputTableCompleted: (state) => {
      state.addMultipleRowsToInputTableIsPending = false;
    },
    /* Delete Row from input table */
    deleteRowFromInputTable: (state) => {
      state.deleteRowFromInputTableIsPending = true;
    },
    deleteRowFromInputTableCompleted: (state) => {
      state.deleteRowFromInputTableIsPending = false;
    },
    /* Delete Multiple Rows from input table */
    deleteMultipleRowsFromInputTable: (state) => {
      state.deleteMultipleRowsFromInputTableIsPending = true;
    },
    deleteMultipleRowsFromInputTableCompleted: (state) => {
      state.deleteMultipleRowsFromInputTableIsPending = false;
    },
    /* Add Column to input table */
    addColumnToInputTable: (state) => {
      state.addColumnToInputTableIsPending = true;
    },
    addColumnToInputTableCompleted: (state) => {
      state.addColumnToInputTableIsPending = false;
    },
    /* Add Multiple Columns to input table */
    addMultipleColumnsToInputTable: (state) => {
      state.addMultipleColumnsToInputTableIsPending = true;
    },
    addMultipleColumnsToInputTableCompleted: (state) => {
      state.addMultipleColumnsToInputTableIsPending = false;
    },
    /* Delete Column from input table */
    deleteColumnFromInputTable: (state) => {
      state.deleteColumnFromInputTableIsPending = true;
    },
    deleteColumnFromInputTableCompleted: (state) => {
      state.deleteColumnFromInputTableIsPending = false;
    },
    /* Delete Multiple Columns from input table */
    deleteMultipleColumnsFromInputTable: (state) => {
      state.deleteMultipleColumnsFromInputTableIsPending = true;
    },
    deleteMultipleColumnsFromInputTableCompleted: (state) => {
      state.deleteMultipleColumnsFromInputTableIsPending = false;
    },
    /* Update Cell Content from input table */
    updateCellContentFromInputTable: (state) => {
      state.updateCellContentFromInputTableIsPending = true;
    },
    updateCellContentFromInputTableCompleted: (state) => {
      state.updateCellContentFromInputTableIsPending = false;
    },
    /* Update Column from input table */
    updateColumnFromInputTable: (state) => {
      state.updateColumnFromInputTableIsPending = true;
    },
    updateColumnFromInputTableCompleted: (state) => {
      state.updateColumnFromInputTableIsPending = false;
    },
    /* Freeze Period */
    freezePeriod: () => {
      // Nothing to do here
    },
    freezePeriodCompleted: () => {
      // Nothing to do here
    },
    /* Unfreeze Period */
    unfreezePeriod: () => {
      // Nothing to do here
    },
    unfreezePeriodCompleted: () => {
      // Nothing to do here
    },
    /* Generate with AI */
    generateWithAI: (state) => {
      state.generateWithAIIsPending = true;
    },
    generateWithAICompleted: (state) => {
      state.generateWithAIIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getList = (state) => root(state).list;
const getListIsPending = (state) => root(state).listIsPending;
const deletePlanIsPending = (state) => root(state).deletePlanIsPending;
const getListUsersIsPending = (state) => root(state).listUsersIsPending;
const getListUsers = (state) => root(state).listUsers;
const getAddDataConnectionCurrentFlowStep = (state) => root(state).addDataConnectionCurrentFlowStep;
const getNewDataConnectionSource = (state) => root(state).newDataConnectionSource;
const getNewDataConnectionData = (state) => root(state).newDataConnectionData;
const getDataConnections = (state) => root(state).dataConnections;
const createPlanIsPending = (state) => root(state).createPlanIsPending;
const getConfiguration = (state) => root(state).configuration;
const getConfigurationIsPending = (state) => root(state).configurationIsPending;
const updateConfigurationIsPending = (state) => root(state).updateConfigurationIsPending;
const getDetails = (state) => root(state).details;
const getDetailsIsPending = (state) => root(state).detailsIsPending;
const rawRunCodeCellIsPending = (state) => root(state).runCodeCellIsPending;
const getRunCodeCellIsPending = createSelector(
  [rawRunCodeCellIsPending, getProps],
  (runCodeCellIsPending, { planId, codeCellId }) => (runCodeCellIsPending[planId]?.[codeCellId] ?? false),
);
const savePlanIsPending = (state) => root(state).savePlanIsPending;
const getAchievementsListIsPending = (state) => root(state).achievementsListIsPending;
const getAchievementsList = (state) => root(state).achievementsList;
const getInputTablesListIsPending = (state) => root(state).inputTablesListIsPending;
const getInputTablesList = (state) => root(state).inputTablesList;
const getCreateInputTableIsPending = (state) => root(state).createInputTableIsPending;
const getAddRowToInputTableIsPending = (state) => root(state).addRowToInputTableIsPending;
const getAddMultipleRowsToInputTableIsPending = (state) => root(state).addMultipleRowsToInputTableIsPending;
const getDeleteRowFromInputTableIsPending = (state) => root(state).deleteRowFromInputTableIsPending;
const getDeleteMultipleRowsFromInputTableIsPending = (state) => root(state).deleteMultipleRowsFromInputTableIsPending;
const getAddColumnToInputTableIsPending = (state) => root(state).addColumnToInputTableIsPending;
const getAddMultipleColumnsToInputTableIsPending = (state) => root(state).addMultipleColumnsToInputTableIsPending;
const getDeleteColumnFromInputTableIsPending = (state) => root(state).deleteColumnFromInputTableIsPending;
const getDeleteMultipleColumnsFromInputTableIsPending = (state) => root(state).deleteMultipleColumnsFromInputTableIsPending;
const getUpdateCellContentFromInputTableIsPending = (state) => root(state).updateCellContentFromInputTableIsPending;
const getUpdateColumnFromInputTableIsPending = (state) => root(state).updateColumnFromInputTableIsPending;
const getGenerateWithAIIsPending = (state) => root(state).generateWithAIIsPending;

export const selectors = {
  getList,
  getListIsPending,
  deletePlanIsPending,
  getListUsersIsPending,
  getListUsers,
  getAddDataConnectionCurrentFlowStep,
  getNewDataConnectionSource,
  getNewDataConnectionData,
  getDataConnections,
  createPlanIsPending,
  getConfiguration,
  getConfigurationIsPending,
  updateConfigurationIsPending,
  getDetails,
  getDetailsIsPending,
  getRunCodeCellIsPending,
  savePlanIsPending,
  getAchievementsListIsPending,
  getAchievementsList,
  getInputTablesListIsPending,
  getInputTablesList,
  getCreateInputTableIsPending,
  getAddRowToInputTableIsPending,
  getAddMultipleRowsToInputTableIsPending,
  getDeleteRowFromInputTableIsPending,
  getDeleteMultipleRowsFromInputTableIsPending,
  getAddColumnToInputTableIsPending,
  getAddMultipleColumnsToInputTableIsPending,
  getDeleteColumnFromInputTableIsPending,
  getDeleteMultipleColumnsFromInputTableIsPending,
  getUpdateCellContentFromInputTableIsPending,
  getUpdateColumnFromInputTableIsPending,
  getGenerateWithAIIsPending,
};
