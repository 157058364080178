// Dashboard widgets
import PlanProgressGaugeWidget from '@palette/components/widgets/dashboard/PlanProgressGaugeWidget/PlanProgressGaugeWidget';
import PlanPayoutScheduleWidget from '@palette/components/widgets/dashboard/PlanPayoutScheduleWidget/PlanPayoutScheduleWidget';
import PlanProgressBarChartWidget from '@palette/components/widgets/dashboard/PlanProgressBarChartWidget/PlanProgressBarChartWidget';
import PlanWhatIfProjectionWidget from '@palette/components/widgets/dashboard/PlanWhatIfProjectionWidget/PlanWhatIfProjectionWidget';
import PlanTotalCommissionsWidget from '@palette/components/widgets/dashboard/PlanTotalCommissionsWidget/PlanTotalCommissionsWidget';
import PlanTotalValueWidget from '@palette/components/widgets/dashboard/PlanTotalValueWidget/PlanTotalValueWidget';
import PlanTotalObjectsWidget from '@palette/components/widgets/dashboard/PlanTotalObjectsWidget/PlanTotalObjectsWidget';
import PlanDetailsWidget from '@palette/components/widgets/dashboard/PlanDetailsWidget/PlanDetailsWidget';
import StatementOneMonthWidget from '@palette/components/widgets/dashboard/StatementOneMonthWidget/StatementOneMonthWidget';
import StatementPerMonthWidget from '@palette/components/widgets/dashboard/StatementPerMonthWidget/StatementPerMonthWidget';
import ListPlansWidget from '@palette/components/widgets/dashboard/ListPlansWidget/ListPlansWidget';
import LatestObjectsWidget from '@palette/components/widgets/dashboard/LatestObjectsWidget/LatestObjectsWidget';
import CommissionsUnreleasedListWidget from '@palette/components/widgets/dashboard/CommissionsUnreleasedListWidget/CommissionsUnreleasedListWidget';
import CommissionsUnreleasedWidget from '@palette/components/widgets/dashboard/CommissionsUnreleasedWidget/CommissionsUnreleasedWidget';

// Highlight widgets
import HighlightCommissionsTotalWidget from '@palette/components/widgets/highlight/HighlightCommissionsTotalWidget/HighlightCommissionsTotalWidget';
import HighlightCommissionsUnreleasedWidget from '@palette/components/widgets/highlight/HighlightCommissionsUnreleasedWidget/HighlightCommissionsUnreleasedWidget';
import HighlightCommissionsDueEstimateWidget from '@palette/components/widgets/highlight/HighlightCommissionsDueEstimateWidget/HighlightCommissionsDueEstimateWidget';
import HighlightTotalValueWidget from '@palette/components/widgets/highlight/HighlightTotalValueWidget/HighlightTotalValueWidget';
import HighlightProgressGaugeWidget from '@palette/components/widgets/highlight/HighlightProgressGaugeWidget/HighlightProgressGaugeWidget';

import { DASHBOARD_WIDGETS } from '@palette/constants/dashboard';
import { HIGHLIGHT_WIDGETS } from '@palette/constants/highlight';

export const DASHBOARD_WIDGETS_COMPONENTS = {
  [DASHBOARD_WIDGETS.PLAN_PROGRESS_GAUGE.type]: PlanProgressGaugeWidget,
  [DASHBOARD_WIDGETS.PLAN_PAYOUT_SCHEDULE.type]: PlanPayoutScheduleWidget,
  [DASHBOARD_WIDGETS.PLAN_PROGRESS_BAR_CHART.type]: PlanProgressBarChartWidget,
  [DASHBOARD_WIDGETS.PLAN_WHAT_IF.type]: PlanWhatIfProjectionWidget,
  [DASHBOARD_WIDGETS.PLAN_TOTAL_COMMISSIONS.type]: PlanTotalCommissionsWidget,
  [DASHBOARD_WIDGETS.PLAN_TOTAL_VALUE.type]: PlanTotalValueWidget,
  [DASHBOARD_WIDGETS.PLAN_COUNT_OBJECTS.type]: PlanTotalObjectsWidget,
  [DASHBOARD_WIDGETS.PLAN_DETAILS.type]: PlanDetailsWidget,
  [DASHBOARD_WIDGETS.STATEMENT_ONE_MONTH.type]: StatementOneMonthWidget,
  [DASHBOARD_WIDGETS.STATEMENT_PER_MONTH.type]: StatementPerMonthWidget,
  [DASHBOARD_WIDGETS.LIST_PLANS.type]: ListPlansWidget,
  [DASHBOARD_WIDGETS.LATEST_OBJECTS.type]: LatestObjectsWidget,
  [DASHBOARD_WIDGETS.COMMISSIONS_UNRELEASED_LIST.type]: CommissionsUnreleasedListWidget,
  [DASHBOARD_WIDGETS.COMMISSIONS_UNRELEASED.type]: CommissionsUnreleasedWidget,
};

export const HIGHLIGHT_WIDGETS_COMPONENTS = {
  [HIGHLIGHT_WIDGETS.COMMISSIONS_TOTAL.type]: HighlightCommissionsTotalWidget,
  [HIGHLIGHT_WIDGETS.COMMISSIONS_UNRELEASED.type]: HighlightCommissionsUnreleasedWidget,
  [HIGHLIGHT_WIDGETS.COMMISSIONS_DUE_ESTIMATE.type]: HighlightCommissionsDueEstimateWidget,
  [HIGHLIGHT_WIDGETS.PLAN_PROGRESS_GAUGE.type]: HighlightProgressGaugeWidget,
  [HIGHLIGHT_WIDGETS.PLAN_TOTAL_VALUE.type]: HighlightTotalValueWidget,
};
