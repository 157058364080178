import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import Button from '@palette/components/designSystem/Button/Button';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import DiscardPlanButton from '@palette/components/masterPlan/DiscardPlanButton/DiscardPlanButton';
import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';

import { getFrequencyName, getPlanUsersCount } from '@palette/helpers/MasterPlanHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import {
  CREATE_PLAN_FLOW_STEPS,
  DRAFT_PLAN_ID_QS_KEY,
  DRAFT_PLAN_STEP_QS_KEY,
} from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './MasterPlanListDraftItem.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanListDraftItem = ({ className, plan }) => {
  const { t } = useTranslation();

  const getQsParams = useMemo(() => ({
    [DRAFT_PLAN_ID_QS_KEY]: plan.id,
    [DRAFT_PLAN_STEP_QS_KEY]: CREATE_PLAN_FLOW_STEPS[plan.creationFlowStep].value,
  }), [plan]);

  const redirectToFlowStep = () => {
    redirectTo({
      path: routePaths.v2.createPlanFlow,
      qsObject: getQsParams,
    });
  };

  const nameNode = useMemo(() => {
    const linkNode = (
      <Link
        className={styles.nameLink}
        path={routePaths.v2.createPlanFlow}
        qsObject={getQsParams}
        displayIcon
      >
        {plan.name}
      </Link>
    );

    return (
      <div className={styles.nameWrapper}>
        <div className={styles.nameLabel}>
          {plan.name}
        </div>
        {linkNode}
      </div>
    );
  }, [plan]);

  const usersCountNode = (
    <>
      <div className={styles.usersCount}>{getPlanUsersCount(plan)}</div>
      <TeamFilled className={styles.usersCountIcon} />
    </>
  );

  const actionsNode = useMemo(() => (
    <>
      <div className={styles.stepContainer}>
        <span className={styles.step}>
          {`${t('masterPlanListDraftItem.step')} ${t(CREATE_PLAN_FLOW_STEPS[plan.creationFlowStep].label)}`}
        </span>
        <Button
          className={classNames({
            action: true,
            actionStep: true,
          })}
          type="link"
          icon={<PenFilled className={styles.actionIcon} />}
          onClick={() => redirectToFlowStep()}
        >
          <div className={styles.actionLabel}>
            {t('masterPlanListDraftItem.continueStep')}
          </div>
        </Button>
      </div>
      <DiscardPlanButton className={styles.action} plan={plan} labelEnabled />
    </>
  ), [plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.namePeriodWrapper}>
          <div className={styles.nameScopeWrapper}>{nameNode}</div>
        </div>
        <div className={styles.peopleWrapper}>
          <div className={styles.usersCountWrapper}>{usersCountNode}</div>
          <div className={styles.frequency}>{getFrequencyName(plan)}</div>
          <MasterPlanScopeTags plan={plan} className={styles.scope} hiddenScopeExcluded />
        </div>
      </div>
      <div className={styles.actionsWrapper}>{actionsNode}</div>
    </div>
  );
};

MasterPlanListDraftItem.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanListDraftItem.defaultProps = {
  className: '',
};

export default MasterPlanListDraftItem;
