import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import MyMasterPlanPeriodsListItem from '@palette/components/ic/myMasterPlan/MyMasterPlanPeriodsListItem/MyMasterPlanPeriodsListItem';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { getLastPeriodAndPastPeriods, isPeriodCurrentOne } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './MyMasterPlanPeriodsList.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanPeriodsList = ({ className, plan, periods }) => {
  const { t } = useTranslation();

  const [lastPeriod, pastPeriods] = useMemo(() => getLastPeriodAndPastPeriods(periods), [plan, periods]);

  let lastPeriodNode = null;

  if (lastPeriod !== null) {
    const isCurrent = isPeriodCurrentOne(plan, lastPeriod);

    lastPeriodNode = (
      <div className={styles.periodsListWrapper}>
        <div className={styles.periodsListTitle}>
          {isCurrent ? t('masterPlanPeriodsList.currentPeriod') : t('masterPlanPeriodsList.lastPeriod')}
        </div>
        <div className={styles.periodsList}>
          <MyMasterPlanPeriodsListItem className={styles.item} period={lastPeriod} plan={plan} />
        </div>
      </div>
    );
  }

  let pastPeriodsListNode = null;

  if (pastPeriods.length > 0) {
    pastPeriodsListNode = (
      <div className={styles.periodsListWrapper}>
        <div className={styles.periodsListTitle}>
          {t('masterPlanPeriodsList.pastPeriods', { count: pastPeriods.length })}
        </div>
        <div className={styles.periodsList}>
          {
            pastPeriods.map((pastPeriod) => (
              <MyMasterPlanPeriodsListItem key={`${pastPeriod.year}_${pastPeriod.period}`} className={styles.item} period={pastPeriod} plan={plan} />
            ))
          }
        </div>
      </div>
    );
  } else if (lastPeriod === null) {
    pastPeriodsListNode = (
      <DefaultEmptyState description={t('masterPlanPeriodsList.empty.description')} />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {lastPeriodNode}
      {pastPeriodsListNode}
    </div>
  );
};

MyMasterPlanPeriodsList.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  periods: PropTypes.arrayOf(MasterPlanPeriodModel.propTypes).isRequired,
};

MyMasterPlanPeriodsList.defaultProps = {
  className: '',
};

export default MyMasterPlanPeriodsList;
