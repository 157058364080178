import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Tag from '@palette/components/designSystem/Tag/Tag';

import { STATEMENT_PERIOD_STATUSES } from '@palette/constants/statements';

import { useStatementPeriodStatus } from '@palette/hooks/StatementHooks';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './StatementPeriodStatus.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodStatus = ({ className, statementPeriod, inUserStatement }) => {
  const { t } = useTranslation();

  const statementPeriodStatus = useStatementPeriodStatus(statementPeriod, inUserStatement);

  const contentNode = useMemo(() => {
    if (!statementPeriodStatus) return null;

    const { icon, i18n, theme } = STATEMENT_PERIOD_STATUSES[statementPeriodStatus.id];

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <Tag
          theme={theme}
          label={t(i18n)}
          icon={icon}
        />
      </div>
    );
  }, [statementPeriodStatus]);

  return contentNode;
};

StatementPeriodStatus.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  inUserStatement: PropTypes.bool,
};

StatementPeriodStatus.defaultProps = {
  className: '',
  inUserStatement: false,
};

export default StatementPeriodStatus;
