import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import MasterPlanSettingsGeneral from '@palette/components/masterPlanSettings/MasterPlanSettingsGeneral/MasterPlanSettingsGeneral';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';

import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './CreatePlanFlowGeneralStep.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowGeneralStep = ({ className, planId, plan }) => {
  const [saveGeneralSettingsCB, setSaveGeneralSettingsCB] = useState(null);

  const handleNextStep = useCallback((goToNextStep) => {
    saveGeneralSettingsCB(goToNextStep);
  }, [saveGeneralSettingsCB]);

  const handleSetSaveGeneralSettingsCB = useCallback((saveSettingsCB) => {
    setSaveGeneralSettingsCB(() => saveSettingsCB);
  }, []);

  const actionsNode = useMemo(() => (
    <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.STEP_GENERAL.value} onNextStep={handleNextStep} />
  ), [plan, handleNextStep]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MasterPlanSettingsGeneral
        className={styles.settingsGeneral}
        planId={planId}
        inPlanCreationFlow
        inPlanCreationFlowSetSaveCB={handleSetSaveGeneralSettingsCB}
      />
      {actionsNode}
    </div>
  );
};

CreatePlanFlowGeneralStep.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowGeneralStep.defaultProps = {
  className: '',
  planId: null,
  plan: null,
};

export default CreatePlanFlowGeneralStep;
