import React, { useMemo } from 'react';
import bindClassNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import WarningFilled from '@palette/components/utils/Icons/WarningFilled';

import styles from './PlanV3CodeCellError.less';

const classNames = bindClassNames.bind(styles);

const PlanV3CodeCellError = ({ className, error }) => {
  const { t } = useTranslation();

  const contentMessage = useMemo(() => {
    if (!error) return null;

    const { code, message } = error;

    const title = code && (
      <div className={styles.title}>
        {t(`planV3CodeCell.errors.type.${code}`)}
      </div>
    );

    if (typeof message === 'object') {
      return (
        <>
          {title}
          <div className={styles.text}>
            <Trans
              i18nKey={`planV3CodeCell.errors.type.${code}.content`}
              values={message}
            />
          </div>
        </>
      );
    }

    return (
      <>
        {title}
        <ul className={styles.list}>
          {message.split('\n').map((line, index) => (
            <li className={styles.text} key={`codeCellError-${index}`}>{line.trim()}</li>
          ))}
        </ul>
      </>
    );
  }, [error]);

  if (!error) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <WarningFilled className={styles.icon} />
      <div className={styles.content}>
        {contentMessage}
      </div>
    </div>
  );
};

PlanV3CodeCellError.propTypes = {
  className: PropTypes.string,
  error: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.any,
  }),
};

PlanV3CodeCellError.defaultProps = {
  className: '',
  error: null,
};

export default PlanV3CodeCellError;
