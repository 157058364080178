import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getSearch } from '@palette/helpers/NavigationHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { QUOTA_TARGET_STRATEGIES, SCOPES } from '@palette/constants/masterPlans';

import { selectors as QuotasSelectors } from '@palette/state/Quotas';
import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

export const useYear = () => {
  const location = useLocation();
  const moment = getMoment();

  return useMemo(() => {
    let { year } = getSearch(location);
    year = year ? parseInt(year, 10) : moment().year();

    if (year < 1970) {
      return [moment().year(), year];
    }

    return [year, year];
  }, [location]);
};

export const useCorrespondingPlanUser = (quotaUserId) => {
  const beingEditedPlanId = useSelector(QuotasSelectors.beingEditedPlanId);
  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: beingEditedPlanId }));
  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: beingEditedPlanId }));

  return useMemo(() => {
    if (beingEditedPlanId === null || plan === null) return null;

    let planUsers = planUsersAndManagers.users;
    if (plan.scope === SCOPES.MANAGER && plan.quotaTargetStrategy === QUOTA_TARGET_STRATEGIES.MATCH) {
      planUsers = planUsersAndManagers.managers;
    }

    const correspondingUser = planUsers.find((planUser) => (
      planUser.user.id === quotaUserId
    ));

    return correspondingUser || null;
  }, [beingEditedPlanId, plan, planUsersAndManagers, quotaUserId]);
};
