import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import MasterPlanDependencyForm from '@palette/components/masterPlanSettings/MasterPlanDependencyForm/MasterPlanDependencyForm';
import MasterPlanDependencyItem from '@palette/components/masterPlanSettings/MasterPlanDependencyItem/MasterPlanDependencyItem';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsDependencies.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsDependencies = ({ className, planId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));
  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);

  const [addDependencyFormIsVisible, showAddDependencyForm] = useState(false);
  const [editedDependencyIndex, setEditedDependencyIndex] = useState(null);

  const handleEditDependency = useCallback((dependencyIndex) => {
    setEditedDependencyIndex(dependencyIndex);
    showAddDependencyForm(true);
  }, []);

  const handleAddNewDependency = useCallback(() => {
    setEditedDependencyIndex(null);
    showAddDependencyForm(true);
  }, []);

  const handleSaveDependency = useCallback((dependency, dependencyIndex) => {
    if (dependency === null && dependencyIndex === null) {
      showAddDependencyForm(false);
      return;
    }

    const dependencies = _cloneDeep(plan.injections);
    if (dependencyIndex === null) {
      dependencies.push(dependency);
    } else if (dependency === null) {
      dependencies.splice(dependencyIndex, 1);
    } else {
      dependencies[dependencyIndex] = dependency;
    }

    if (!_isEqual(plan.injections, dependencies)) {
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, injections: dependencies }));
    }

    showAddDependencyForm(false);
  }, [plan]);

  const dependenciesNode = useMemo(() => {
    if (addDependencyFormIsVisible) {
      return (
        <div className={styles.dependenciesWrapper}>
          <MasterPlanDependencyForm plan={plan} dependencyIndex={editedDependencyIndex} onSave={handleSaveDependency} />
        </div>
      );
    }

    return (
      <div className={styles.dependenciesWrapper}>
        <div className={styles.dependencies}>
          {
            plan.injections.map((injection, injectionIndex) => (
              <MasterPlanDependencyItem
                key={`${injection.planId}_${injection.name}`}
                className={styles.dependency}
                plan={plan}
                dependency={injection}
                onEditBtnClick={() => handleEditDependency(injectionIndex)}
                disabled={updatePlanIsPending}
              />
            ))
          }
        </div>
        <Button
          className={styles.addDependencyBtn}
          icon={(<AddFilled />)}
          disabled={updatePlanIsPending}
          onClick={handleAddNewDependency}
        >
          {t('masterPlanSettingsDependencies.addDependencyBtnLabel')}
        </Button>
      </div>
    );
  }, [
    addDependencyFormIsVisible,
    plan,
    editedDependencyIndex,
    handleSaveDependency,
    handleEditDependency,
    updatePlanIsPending,
    handleAddNewDependency,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.description}>
        {t('masterPlanSettingsDependencies.description')}
      </div>
      {dependenciesNode}
    </div>
  );
};

MasterPlanSettingsDependencies.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

MasterPlanSettingsDependencies.defaultProps = {
  className: '',
};

export default MasterPlanSettingsDependencies;
