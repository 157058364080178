import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as UserStatementPlanPeriodModel from '@palette/models/UserStatementPlanPeriod';

export const modelName = 'UserStatementPlan';

export const propTypes = PropTypes.shape({
  plan: MasterPlanModel.propTypes,
  totalAmount: PropTypes.number,
  currency: PropTypes.string,
  periods: PropTypes.arrayOf(UserStatementPlanPeriodModel.propTypes),
});

export const transform = (userStatementPlan) => {
  if (!userStatementPlan) {
    return null;
  }

  const periods = _orderBy(
    UserStatementPlanPeriodModel.transformList(manageArrayAttribute(userStatementPlan, 'periods')),
    ['year', 'period'],
  );

  return ({
    plan: MasterPlanModel.transform(manageFreeShapeObjectAttribute(userStatementPlan, 'plan')),
    totalAmount: manageAmountAttribute(userStatementPlan, 'totalCommissions'),
    currency: manageStringAttribute(userStatementPlan, 'currency'),
    periods,
  });
};

export const transformList = (userStatementPlans) => userStatementPlans.map((userStatementPlan) => transform(userStatementPlan));
