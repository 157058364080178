import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import Button from '@palette/components/designSystem/Button/Button';
import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';

import { getPlanPeriodName, isUserGroupByUserPlan } from '@palette/helpers/MasterPlanHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { formatWidgetPrice } from '@palette/helpers/DashboardHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

import routePaths from '@palette/config/routePaths';

import styles from './ListPlanItem.less';

const classNames = bindClassNames.bind(styles);

const ListPlanItem = ({
  className,
  plan,
  period,
  totalCommissionAmount,
  currency,
  readOnly,
  enableLinks,
}) => {
  const { t } = useTranslation();
  const periodName = getPlanPeriodName(plan, period);
  const isUserGroupByUser = isUserGroupByUserPlan(plan);

  const handleClick = () => {
    redirectTo({
      path: routePaths.v2.myPlanPeriodDetails,
      params: { masterPlanId: plan.id, year: period.year, periodId: period.period },
    });
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>{t('listPlanItem.title')}</div>
      <div className={styles.amount}>{formatWidgetPrice(totalCommissionAmount, currency)}</div>
      <Link
        className={styles.name}
        path={routePaths.v2.myPlanDetails}
        params={{ masterPlanId: plan.id }}
        disabled={readOnly && !enableLinks}
      >
        {plan.name}
      </Link>
      <div className={styles.details}>
        <div className={styles.period}>{periodName}</div>
        <MasterPlanScopeTags plan={plan} className={styles.scope} />
      </div>
      <Button className={styles.link} type="link" onClick={handleClick} disabled={readOnly && !enableLinks}>
        {isUserGroupByUser && t('listPlanItem.viewPlanDetails')}
        {!isUserGroupByUser && t('listPlanItem.viewType', {
          type: pluralize(plan.trackingObject?.type || ''),
        })}
      </Button>
    </div>
  );
};

ListPlanItem.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: YearPeriodModel.propTypes.isRequired,
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

ListPlanItem.defaultProps = {
  className: '',
  totalCommissionAmount: 0,
  currency: '',
  readOnly: false,
  enableLinks: true,
};

export default ListPlanItem;
