import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { ACTION_TYPE, ENTITY_TYPE } from '@palette/constants/auditTrail';

import * as AuditTrailContentModel from '@palette/models/AuditTrailContent';
import * as MetaUserModel from '@palette/models/MetaUser';

export const modelName = 'AuditTrail';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  actionType: PropTypes.oneOf(Object.keys(ACTION_TYPE)),
  entityType: PropTypes.oneOf(Object.keys(ENTITY_TYPE)),
  date: PropTypes.string,
  content: AuditTrailContentModel.propTypes,
  author: MetaUserModel.propTypes,
  isImpersonated: PropTypes.bool,
});

export const manageActionType = (auditTrail) => Object.keys(ACTION_TYPE).find((action) => ACTION_TYPE[action] === manageStringAttribute(auditTrail, 'action'));

export const manageEntityType = (auditTrail) => Object.keys(ENTITY_TYPE).find((entity) => ENTITY_TYPE[entity] === manageStringAttribute(auditTrail, 'entity'));

export const transform = (auditTrail) => {
  if (!auditTrail) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(auditTrail, '_id'),
      actionType: manageActionType(auditTrail),
      entityType: manageEntityType(auditTrail),
      date: manageDateAttribute(auditTrail, 'when'),
      content: AuditTrailContentModel.transform(manageFreeShapeObjectAttribute(auditTrail, 'content')),
      author: MetaUserModel.transform(manageFreeShapeObjectAttribute(auditTrail, 'who')),
      isImpersonated: manageBooleanAttribute(auditTrail, 'isImpersonated'),
    }
  );
};

export const transformList = (auditTrails) => auditTrails.map((auditTrail) => transform(auditTrail));
