import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { EMAIL_PREFERENCES } from '@palette/constants/profile';

import * as RoleModel from '@palette/models/Role';

export const modelName = 'MetaUserAccount';

export const propTypes = PropTypes.shape({
  email: PropTypes.string,
  roleId: PropTypes.string,
  role: RoleModel.propTypes,
  emailPreferences: PropTypes.shape(Object.values(EMAIL_PREFERENCES).reduce((res, feature) => ({ ...res, [feature]: PropTypes.bool }), {})),
  pendingInvitation: PropTypes.bool,
  hasAcknowledgeLastTerms: PropTypes.bool,
  intercomIdentifier: PropTypes.string,
});

export const manageEmailPreferences = (userEmailPreferences = {}) => Object.values(EMAIL_PREFERENCES).reduce((res, feature) => ({ ...res, [feature]: userEmailPreferences[feature] || false }), {});

export const transform = (metaUserAccount) => {
  if (!metaUserAccount) {
    return null;
  }

  let role = null;
  let roleId = manageStringAttribute(metaUserAccount, 'role', null, true);
  if (roleId === null) {
    role = RoleModel.transform(manageFreeShapeObjectAttribute(metaUserAccount, 'role', null));
    roleId = role?.id || null;
  }

  return (
    {
      email: manageStringAttribute(metaUserAccount, 'email'),
      roleId,
      role,
      emailPreferences: manageEmailPreferences(manageFreeShapeObjectAttribute(metaUserAccount, 'emailPreferences')),
      pendingInvitation: manageBooleanAttribute(metaUserAccount, 'pendingInvitation'),
      hasAcknowledgeLastTerms: manageBooleanAttribute(metaUserAccount, 'hasAcknowledgeLastTerms', true),
      intercomIdentifier: manageStringAttribute(metaUserAccount, 'intercomIdentifier', null),
    }
  );
};

export const transformList = (metaUserAccounts) => metaUserAccounts.map((metaUserAccount) => transform(metaUserAccount));
