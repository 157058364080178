import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Table from '@palette/components/designSystem/Table/Table';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Tag from '@palette/components/designSystem/Tag/Tag';
import StatementCounterCorrections from '@palette/components/statement/statementCommon/StatementCounterCorrections/StatementCounterCorrections';
import UsersStatementsApprovalsList from '@palette/components/statement/statementPeriodUsers/UsersStatementsApprovalsList/UsersStatementsApprovalsList';
import StatementApproveAction from '@palette/components/statement/statementCommon/StatementApproveAction/StatementApproveAction';
import StatementMarkAsPaidAction from '@palette/components/statement/statementCommon/StatementMarkAsPaidAction/StatementMarkAsPaidAction';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { usePeriodsDatesFilterAndCurrencyQSObject, useStatementPeriodIdInParams } from '@palette/hooks/StatementHooks';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './UsersStatementsList.less';

const classNames = bindClassNames.bind(styles);

const UsersStatementsList = ({ className, userStatements, currency }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { isPending, statementPeriodId, statementPeriod } = useStatementPeriodIdInParams();
  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const statementValidationEnabledOnProfile = hasFeature(profile, FEATURES.STATEMENT_VALIDATION);
  const statementValidationCanManageOnProfile = !!statementValidationEnabledOnProfile && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE]);

  const columns = useMemo(() => {
    const cols = [];

    const userColumn = {
      id: 'statementUser',
      Header: t('userStatement.table.header.name'),
      accessor: 'user',
      minWidth: 240,
      width: '24%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <UserProfile
          className={styles.userProfile}
          user={value}
          clickable
          linkProps={{
            path: routePaths.v2.statementsUserStatement,
            params: { statementPeriodId, userId: value.id },
            qsObject: periodsDatesFilterQSObject,
            displayIcon: true,
            contentClassName: styles.userProfileLink,
          }}
        />
      ),
      /* eslint-enable react/prop-types */
      sortType: (a, b) => {
        const aName = a.values.statementUser.displayName;
        const bName = b.values.statementUser.displayName;
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      },
    };

    cols.push(userColumn);

    const commissionsColumn = {
      id: 'statementCommissions',
      Header: t('userStatement.table.header.commissions'),
      accessor: (statement) => statement,
      minWidth: 410,
      width: '41%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => {
        const statementAmount = formatPrice(value.statementAmount, value.currency);
        const statementPaidByAmount = formatPrice(value.paidBy?.amount, value.currency);
        const { theme, icon } = STATEMENT_PERIOD_USER_STATUSES.PAID_AMOUNT;

        return (
          <div className={styles.commissions}>
            <div className={styles.amounts}>
              <div className={styles.statementAmount}>
                {statementAmount}
              </div>
              {value.isPaid && value.paidBy && statementAmount !== statementPaidByAmount && (
                <Tag
                  className={styles.statementPaidAmount}
                  theme={theme}
                  label={t('statement.statuses.paid.amount', { amount: statementPaidByAmount })}
                  icon={icon}
                />
              )}
            </div>
            {statementValidationEnabledOnProfile && (
              <StatementCounterCorrections
                className={styles.correctionsWrapper}
                counter={value.correctionsCount}
              />
            )}
          </div>
        );
      },
      /* eslint-enable react/prop-types */
      sortType: (a, b) => {
        const aAmount = a.values.statementCommissions.statementAmount;
        const bAmount = b.values.statementCommissions.statementAmount;
        if (aAmount < bAmount) return -1;
        if (aAmount > bAmount) return 1;
        return 0;
      },
    };

    cols.push(commissionsColumn);

    const approvalsColumn = {
      id: 'statementApprovals',
      accessor: (statement) => statement,
      minWidth: statementValidationCanManageOnProfile ? 380 : 670,
      width: statementValidationCanManageOnProfile ? '38%' : '67%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <UsersStatementsApprovalsList className={styles.approvalsWrapper} userStatement={value} />,
      disableSortBy: true,
    };

    cols.push(approvalsColumn);

    if (statementValidationCanManageOnProfile) {
      const actionsColumn = {
        id: 'statementActions',
        Header: (
          <div className={styles.headerRight}>
            {t('userStatement.table.header.actions')}
          </div>
        ),
        accessor: (statement) => statement,
        minWidth: 290,
        width: '29%',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <div className={styles.actionsWrapper}>
            <StatementApproveAction
              className={styles.actionButton}
              userStatement={value}
              statementPeriod={statementPeriod}
              currency={currency}
              isSecondaryType
            />
            <StatementMarkAsPaidAction
              className={styles.actionButton}
              userStatement={value}
              statementPeriod={statementPeriod}
              currency={currency}
              isSecondaryType
            />
          </div>
        ),
        disableSortBy: true,
      };

      cols.push(actionsColumn);
    }

    return cols;
  }, [
    statementPeriodId,
    statementPeriod,
    periodsDatesFilterQSObject,
    currency,
    statementValidationEnabledOnProfile,
    statementValidationCanManageOnProfile,
  ]);

  const contentNode = useMemo(() => {
    if (userStatements.length === 0 && isPending) return <Loader />;

    if (userStatements.length === 0) return <DefaultEmptyState description={t('usersStatementsList.noUserStatement')} />;

    return (
      <Table
        type="borderless"
        columns={columns}
        data={userStatements}
        nbOfFixedColumns={statementValidationCanManageOnProfile ? 1 : 0}
        fixedColumnsPosition="fromRight"
        enableSortableColumns
      />
    );
  }, [
    isPending,
    userStatements,
    columns,
    statementValidationCanManageOnProfile,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UsersStatementsList.propTypes = {
  className: PropTypes.string,
  userStatements: PropTypes.arrayOf(UserStatementModel.propTypes),
  currency: PropTypes.string,
};

UsersStatementsList.defaultProps = {
  className: '',
  userStatements: [],
  currency: null,
};

export default UsersStatementsList;
