import _pick from 'lodash/pick';

import { MONTH_FREQUENCIES } from '@palette/constants/frequencies';

export const AVAILABLE_MONTH_FREQUENCIES_OPTIONS = _pick(MONTH_FREQUENCIES, [1, 3, 6, 12]);

export const TYPES = {
  ARR: 'ARR',
  MRR: 'MRR',
  NEW_ARR: 'New ARR',
  REVENUE: 'Revenue',
};

export const QUOTA_TARGET_ORIGIN = {
  QUOTA_DEFAULT_VALUE: 'QUOTA_DEFAULT_VALUE',
  USER_DEFAULT_VALUE: 'USER_DEFAULT_VALUE',
  USER_OVERWRITE: 'USER_OVERWRITE',
  ONBOARDING_OFFBOARDING: 'ONBOARDING_OFFBOARDING',
};

export const QUOTA_ACTIONS = {
  UPDATE_QUOTA_DEFAULT_VALUES: 'UPDATE_QUOTA_DEFAULT_VALUES',
  UPDATE_QUOTA_NAME: 'UPDATE_QUOTA_NAME',
  UPDATE_USER_DEFAULT_VALUES: 'UPDATE_USER_DEFAULT_VALUES',
  UPDATE_USER_OVERWRITE: 'UPDATE_USER_OVERWRITE',
  UPDATE_USER_LAST_PERIOD: 'UPDATE_USER_LAST_PERIOD',
  RESET_USER_OVERWRITE: 'RESET_USER_OVERWRITE',
};
