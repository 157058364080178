import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import TextArea from '@palette/components/designSystem/TextArea/TextArea';
import AddPlanSelectors from '@palette/components/quotaPerformance/AddPlanSelectors/AddPlanSelectors';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';

import styles from './CreateDashboardModal.less';

const CreateDashboardModal = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [planIds, setPlanIds] = useState([]);

  const createDashboardIsPending = useSelector(QuotasPerformanceSelectors.createDashboardIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = {
    name: '',
    description: '',
  };

  const cleanAndClose = () => {
    form.resetFields();
    setPlanIds([]);
    onClose();
  };

  const handlePlanIdsChange = (ids) => setPlanIds(ids.filter((id) => id));

  const handleFinish = useCallback(() => {
    const onSuccessCB = (dashboardId) => {
      cleanAndClose();
      redirectTo({ path: routePaths.v2.quotasPerformanceDetails, params: { dashboardId } });
    };

    const fieldsValues = form.getFieldsValue(true);

    const finalValues = {
      name: fieldsValues.name,
      description: fieldsValues.description,
      planIds,
    };

    dispatch(QuotasPerformanceActions.createDashboard({ ...finalValues, onSuccessCB }));
  }, [form, planIds]);

  const handleCreateDashboard = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('quotasPerformance.createDashboard.modal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleCreateDashboard}
      okText={t('quotasPerformance.createDashboard.modal.button.create')}
      loading={createDashboardIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="name"
          label={t('quotasPerformance.createDashboard.modal.form.name')}
          required
          rules={[
            { required: true, message: t('quotasPerformance.createDashboard.modal.form.name.required') },
          ]}
        >
          <Input
            size="big"
            placeholder={t('quotasPerformance.createDashboard.modal.form.name.placeholder')}
            disabled={createDashboardIsPending}
          />
        </FormItem>
        <FormItem name="description" label={t('quotasPerformance.createDashboard.modal.form.description')}>
          <TextArea disabled={createDashboardIsPending} />
        </FormItem>
        <AddPlanSelectors
          onPlanIdsChange={handlePlanIdsChange}
          disabled={createDashboardIsPending}
        />
      </Form>
    </Modal>
  );
};

CreateDashboardModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

CreateDashboardModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CreateDashboardModal;
