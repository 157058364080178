import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import EyesLine from '@palette/components/utils/Icons/EyesLine';
import Tag from '@palette/components/designSystem/Tag/Tag';
import Table from '@palette/components/designSystem/Table/Table';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import CommissionName from '@palette/components/commissionList/CommissionName/CommissionName';
import CommissionPayout from '@palette/components/commissionList/CommissionPayout/CommissionPayout';

import { useSearchedResourceInSearch, useSearchedStatusInSearch } from '@palette/hooks/CommissionHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getCommissionAmount } from '@palette/helpers/CommissionHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { COMMISSION_DRAWER_COMMISION_ID_QS_KEY, STATUSES_OPTIONS, STATUSES_TAG } from '@palette/constants/commissions';
import { COMMISSION_DRAWER_QS_KEY, COMMISSION_DRAWER_TABS_IDS } from '@palette/constants/tabs';

import { selectors as CommissionsSelectors } from '@palette/state/Commissions';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './MyCommissionListTable.less';

const classNames = bindClassNames.bind(styles);

const NO_RESULTS_FOUND_IMG = '/img/empty-states/no-results-found.png';
const ALL_DEALS_CONCLUDED_IMG = '/img/empty-states/all-deals-concluded.png';

const MyCommissionListTable = ({ className, onPageChange, onLimitChange, onRefresh }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const commissionList = useSelector(CommissionsSelectors.getCommissionListByFilters);
  const commissionListPagination = useSelector(CommissionsSelectors.listByFiltersPagination);
  const listByFiltersIsPending = useSelector(CommissionsSelectors.listByFiltersIsPending);

  const [searchedStatus, searchStatusUrl, searchedStatusInSearch] = useSearchedStatusInSearch();
  const [searchedResourceFromSearch] = useSearchedResourceInSearch();

  const handlePageChange = useCallback((newPage) => {
    onPageChange(newPage);
  });

  const handleLimitChange = useCallback((newLimit) => {
    onLimitChange(newLimit);
  });

  const openCommissionDrawer = useCallback((tabKey, commissionId) => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [COMMISSION_DRAWER_COMMISION_ID_QS_KEY]: commissionId, [COMMISSION_DRAWER_QS_KEY]: tabKey } }));
  }, []);

  useEffect(() => {
    const shouldCheckUpdateInList = searchedStatusInSearch && searchedStatus !== null && searchedStatus !== STATUSES_OPTIONS.PARTIALLY_RELEASED_AND_ON_HOLD && commissionList.length > 0;

    if (!shouldCheckUpdateInList) return;

    if (commissionList.some((commission) => commission.paymentsStatus !== searchStatusUrl.toUpperCase())) {
      onRefresh();
    }
  }, [commissionList]);

  const columns = useMemo(() => {
    const cols = [];

    const nameColumn = {
      id: 'commissionName',
      Header: t('commissionList.table.header.name'),
      accessor: (commission) => commission,
      minWidth: 280,
      width: '28%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <CommissionName commission={value} />,
    };

    cols.push(nameColumn);

    const dateColumn = {
      id: 'commissionDate',
      Header: t('commissionList.table.header.date'),
      accessor: 'date',
      minWidth: 180,
      width: '18%',
      Cell: ({ value }) => moment.utc(value).format('ll'),
    };

    cols.push(dateColumn);

    const typeColumn = {
      id: 'commissionType',
      Header: t('commissionList.table.header.type'),
      accessor: 'scopeParams',
      minWidth: 150,
      width: '15%',
      Cell: ({ value }) => getPlanValueDefinition(value?.plan),
    };

    cols.push(typeColumn);

    const valueColumn = {
      id: 'commissionValue',
      Header: t('commissionList.table.header.commissions'),
      accessor: (commission) => commission,
      minWidth: 180,
      width: '18%',
      Cell: ({ value }) => formatPrice(getCommissionAmount(value), value?.currency),
    };

    cols.push(valueColumn);

    const payoutColumn = {
      id: 'commissionPayout',
      Header: t('commissionList.table.header.payout'),
      accessor: (commission) => commission,
      minWidth: 220,
      width: '22%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <CommissionPayout commission={value} />
      ),
      /* eslint-enable react/prop-types */
    };

    cols.push(payoutColumn);

    const statusColumn = {
      id: 'commissionStatus',
      Header: t('commissionList.table.header.status'),
      accessor: 'paymentsStatus',
      minWidth: 180,
      width: '18%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <Tag
          theme={STATUSES_TAG[value]}
          label={t(`commissionList.status.tag.${value.toLowerCase()}`)}
        />
      ),
      /* eslint-enable react/prop-types */
    };

    cols.push(statusColumn);

    const actionsColumn = {
      id: 'commissionActions',
      accessor: (commission) => commission,
      minWidth: 140,
      width: '14%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <Button
          type="link"
          icon={<EyesLine />}
          onClick={() => openCommissionDrawer(COMMISSION_DRAWER_TABS_IDS.VARIABLES, value.id)}
        >
          {t('commissionList.actions.button.view_details')}
        </Button>
      ),
      /* eslint-enable react/prop-types */
    };

    cols.push(actionsColumn);

    return cols;
  }, [openCommissionDrawer]);

  const emptyStates = useMemo(() => {
    if (listByFiltersIsPending) return null;

    let description = t('commissionList.emptyStates.no_results_found');
    let image = NO_RESULTS_FOUND_IMG;

    if (searchedStatusInSearch && searchedStatus !== null && searchedStatus === STATUSES_OPTIONS.PARTIALLY_RELEASED_AND_ON_HOLD) {
      description = t('commissionList.emptyStates.all_deals_concluded');
      image = ALL_DEALS_CONCLUDED_IMG;
    } else if (!searchedResourceFromSearch && !searchedStatusInSearch) {
      description = t('commissionList.emptyStates.no_commission_to_list');
      image = NO_RESULTS_FOUND_IMG;
    }

    return (
      <DefaultEmptyState
        className={styles.emptyStateContainer}
        image={(
          <img src={image} alt={description} />
        )}
        description={description}
        size="compact"
        isBold
      />
    );
  }, [
    listByFiltersIsPending,
    searchedStatus,
    searchedStatusInSearch,
    searchedResourceFromSearch,
  ]);

  const contentNode = useMemo(() => {
    if (commissionList.length === 0) {
      return emptyStates;
    }

    return (
      <>
        <Table
          type="borderless"
          columns={columns}
          data={commissionList}
        />
        <Pagination
          className={styles.pagination}
          pagination={commissionListPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </>
    );
  }, [
    commissionList,
    columns,
    commissionListPagination,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MyCommissionListTable.propTypes = {
  className: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

MyCommissionListTable.defaultProps = {
  className: '',
};

export default MyCommissionListTable;
