import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ListWidgetBase from '@palette/components/widgets/dashboard/ListWidgetBase/ListWidgetBase';
import ListPlanItem from '@palette/components/widgets/dashboard/ListPlanItem/ListPlanItem';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import { IC_PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as ListPlansDataModel from '@palette/models/widgets/dashboard/ListPlansData';

import styles from './ListPlansWidget.less';

const classNames = bindClassNames.bind(styles);

const ListPlansWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const listPlansData = ListPlansDataModel.transform(widgetData.data);

  const listPlans = useMemo(() => (
    listPlansData.plans.filter((planData) => (planData.plan.creationFlowStep === null))
  ), [listPlansData]);

  const plansNode = useMemo(() => {
    if (listPlans.length === 0) {
      return (
        <DefaultEmptyState className={styles.emptyState} />
      );
    }

    return listPlans.map((plan, index) => (
      <ListPlanItem
        key={`list_plan_item_${index}`}
        plan={plan.plan}
        period={plan.period}
        totalCommissionAmount={plan.totalCommissionAmount}
        currency={plan.currency}
        readOnly={readOnly}
        enableLinks={enableLinks}
      />
    ));
  }, [listPlans, readOnly, enableLinks]);

  const title = useMemo(() => {
    if (listPlans.length === 0) {
      return t('common.listPlans.title');
    }

    return `${t('common.listPlans.title')} (${listPlans.length})`;
  }, [listPlans]);

  const secondTitleHandleClick = () => {
    redirectTo({
      path: routePaths.v2.myPlans,
      qsObject: { plansTab: IC_PLANS_PAGE_CONTENT_TABS_IDS.PAST },
    });
  };

  return (
    <ListWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      title={title}
      secondTitle={t('common.listPlans.secondTitle')}
      secondTitleClick={secondTitleHandleClick}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      <div className={styles.content}>
        {plansNode}
      </div>
    </ListWidgetBase>
  );
};

ListPlansWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

ListPlansWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default ListPlansWidget;
