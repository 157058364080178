import React from 'react';
import ReactDOM from 'react-dom';
import { message } from 'antd';

import { setMomentLocale } from '@palette/helpers/MomentHelper';

import '@palette/styles/global.less';

import App from './App';

setMomentLocale('en');
message.config({
  top: 64,
});

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
