import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'CommissionV3DetailsCommission';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  amount: PropTypes.number,
  amountOverwritten: PropTypes.number,
  currency: PropTypes.string,
  planId: PropTypes.string,
  planName: PropTypes.string,
  userId: PropTypes.string,
  dealId: PropTypes.string,
  dealName: PropTypes.string,
  dealAmount: PropTypes.number,
  payments: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    percentage: PropTypes.number,
  })),
});

export const transform = (commission) => {
  if (!commission) {
    return null;
  }

  const managePayments = (payments) => payments && payments.map((payment) => ({
    date: manageDateAttribute(payment, 'date'),
    percentage: managePureFloatAttribute(payment, 'percentage'),
  }));

  const payments = managePayments(manageArrayAttribute(commission, 'payments'));
  const overwrittenPayments = managePayments(manageArrayAttribute(commission.overwrites, 'payments', null));

  return (
    {
      id: manageStringAttribute(commission, 'id'),
      amount: manageAmountAttribute(commission, 'amount'),
      amountOverwritten: manageAmountAttribute(manageFreeShapeObjectAttribute(commission, 'overwrites'), 'amount', null),
      currency: manageStringAttribute(commission, 'currency'),
      planId: manageStringAttribute(manageFreeShapeObjectAttribute(commission, 'plan'), 'id'),
      planName: manageStringAttribute(manageFreeShapeObjectAttribute(commission, 'plan'), 'name'),
      userId: manageStringAttribute(commission, 'payee'),
      dealId: manageStringAttribute(manageFreeShapeObjectAttribute(commission, 'deal'), 'id'),
      dealName: manageStringAttribute(manageFreeShapeObjectAttribute(commission, 'deal'), 'name'),
      dealAmount: manageAmountAttribute(manageFreeShapeObjectAttribute(commission, 'deal'), 'amount'),
      payments: overwrittenPayments ?? payments,
      originalPayments: payments,
    }
  );
};

export const transformList = (commissions) => commissions.map((commission) => transform(commission));
