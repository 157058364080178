import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import InviteUserModal from '@palette/components/user/InviteUserModal/InviteUserModal';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './InviteUserLink.less';

const classNames = bindClassNames.bind(styles);

const InviteUserLink = ({ className, user }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [inviteUserModalIsVisible, showInviteUserModal] = useState(false);

  const inviteUserLinkNode = useMemo(() => (
    <Button
      type="link"
      icon={<AddFilled />}
      onClick={() => showInviteUserModal(true)}
    >
      {t('inviteUserLink.label')}
    </Button>
  ), [showInviteUserModal]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.INVITE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {inviteUserLinkNode}
      {inviteUserModalIsVisible && (
        <InviteUserModal
          visible
          onClose={() => showInviteUserModal(false)}
          user={user}
        />
      )}
    </div>
  );
};

InviteUserLink.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

InviteUserLink.defaultProps = {
  className: '',
};

export default InviteUserLink;
