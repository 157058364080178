import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { SEARCHED_TYPE_QS_KEY, SEARCHED_SINCE_QS_KEY } from '@palette/constants/auditTrail';

export const useSearchedTypeInSearch = () => useKeyInSearch(SEARCHED_TYPE_QS_KEY);

export const useSearchedSinceInSearch = () => useKeyInSearch(SEARCHED_SINCE_QS_KEY);

export const useAuditTrailPageFilters = () => {
  const [searchedType, searchedTypeInSearch] = useSearchedTypeInSearch();
  const [searchedSince, searchedSinceInSearch] = useSearchedSinceInSearch();

  return {
    searchedType,
    searchedTypeInSearch,
    searchedSince,
    searchedSinceInSearch,
  };
};
