import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Select from '@palette/components/designSystem/Select/Select';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { getAvailableQuotaFrequenciesOptions } from '@palette/helpers/QuotaHelper';
import { buildFrequencyOptionValue, extractPeriodTypeFrequencyFromOptionValue } from '@palette/helpers/FrequencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import routePaths from '@palette/config/routePaths';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { AVAILABLE_MONTH_FREQUENCIES_OPTIONS } from '@palette/constants/quotas';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './CreateQuotaModal.less';

const CreateQuotaModal = ({ visible, onClose, periodType, frequency, afterCreate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const createQuotaIsPending = useSelector(QuotasSelectors.createQuotaIsPending);
  const quotaTypes = useSelector(QuotasSelectors.getQuotaTypes);

  const [form] = AntDForm.useForm();

  const quotaTypesOptions = quotaTypes.map((type) => ({
    label: type,
    value: type,
  }));

  const frequenciesOptions = getAvailableQuotaFrequenciesOptions();

  const initialPeriodType = periodType !== null ? periodType : PERIOD_TYPES.MONTH;
  const initialFrequency = frequency !== null ? frequency.toString() : Object.keys(AVAILABLE_MONTH_FREQUENCIES_OPTIONS)[0];
  const initialFrequencyType = initialPeriodType === PERIOD_TYPES.DAY ? PERIOD_TYPES.DAY : buildFrequencyOptionValue(initialPeriodType, initialFrequency);

  const [periodTypeValue, setPeriodTypeValue] = useState(initialPeriodType);
  const [frequencyValue, setFrequencyValue] = useState(initialFrequency);

  const initialValues = {
    name: undefined,
    type: undefined,
    frequency: initialFrequency,
    frequencyType: initialFrequencyType,
    defaultValue: 0,
  };

  const cleanAndClose = () => {
    form.resetFields();
    onClose();
  };

  const handleFrequencyTypeChange = (optionValue) => {
    let newPeriodType;
    let newFrequency;

    if (optionValue === PERIOD_TYPES.DAY) {
      newPeriodType = PERIOD_TYPES.DAY;
      newFrequency = 7;

      if (initialPeriodType === PERIOD_TYPES.DAY) {
        newFrequency = initialFrequency;
      }
    } else {
      [newPeriodType, newFrequency] = extractPeriodTypeFrequencyFromOptionValue(optionValue);
    }

    newFrequency = parseInt(newFrequency, 10);

    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      frequency: newFrequency,
    });

    setPeriodTypeValue(newPeriodType);
    setFrequencyValue(newFrequency);
  };

  const handleFormValuesChange = useCallback((changedValues) => {
    if (changedValues.frequencyType !== undefined) {
      handleFrequencyTypeChange(changedValues.frequencyType);
    }

    if (changedValues.frequency !== undefined) {
      setFrequencyValue(changedValues.frequency);
    }
  }, [form, frequencyValue]);

  const handleFinish = (values) => {
    let onSuccessCB = (quotaId) => {
      cleanAndClose();
      redirectTo({ path: routePaths.v2.quotaDetails, params: { quotaId } });
    };

    if (afterCreate !== null) {
      onSuccessCB = (quotaId) => {
        cleanAndClose();
        afterCreate(quotaId);
      };
    }

    const finalValues = {
      ...values,
      periodType: periodTypeValue,
      frequency: parseInt(frequencyValue, 10),
    };
    if (finalValues.beginAt !== undefined) {
      finalValues.beginAt = moment(finalValues.beginAt).utc().startOf('day').format();
    }
    delete finalValues.frequencyType;

    dispatch(QuotasActions.createQuota({ ...finalValues, onSuccessCB }));
  };

  const handleCreateQuota = () => form.submit();

  const dayPeriodTypeNodes = useMemo(() => {
    if (periodTypeValue !== PERIOD_TYPES.DAY) return null;

    return (
      <div className={styles.dayPeriodTypeWrapper}>
        <FormItem
          className={styles.beginAtInput}
          name="beginAt"
          label={t('quota.form.beginAt.label')}
          required
          rules={[
            { required: true, message: t('quota.form.beginAt.rules.required') },
          ]}
        >
          <DatePicker
            className={styles.datePicker}
            size="big"
            picker="date"
            allowClear={false}
            disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
            disabled={createQuotaIsPending}
          />
        </FormItem>
        <FormItem
          className={styles.frequencyInput}
          name="frequency"
          label={t('quota.form.frequencyForDay.label')}
          extra={t('quota.form.frequencyForDay.description')}
          required
          rules={[
            { required: true, message: t('quota.form.frequencyForDay.rules.required') },
          ]}
        >
          <Input size="big" type="number" min={1} max={365} disabled={createQuotaIsPending} />
        </FormItem>
      </div>
    );
  }, [periodTypeValue, createQuotaIsPending]);

  return (
    <Modal
      className={styles.modal}
      title={t('quota.createNewONe')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleCreateQuota}
      okText={t('quota.createQuota')}
      loading={createQuotaIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <FormItem
          name="name"
          label={t('quota.form.name.label')}
          required
          rules={[
            { required: true, message: t('quota.form.name.rules.required') },
          ]}
        >
          <Input size="big" placeholder={t('quota.form.name.placeholder')} disabled={createQuotaIsPending} />
        </FormItem>
        <FormItem
          name="type"
          label={t('quota.form.tracked.label')}
          required
          rules={[
            { required: true, message: t('quota.form.tracked.rules.required') },
          ]}
        >
          <Select
            size="big"
            showSearch
            addSearchToOptions
            addSearchToOptionsLabel={t('quota.form.tracked.addTypeToOptions')}
            searchNotFoundLabel={t('quota.form.tracked.searchNotFound')}
            placeholder={t('quota.form.tracked.placeholder')}
            options={quotaTypesOptions}
            disabled={createQuotaIsPending}
          />
        </FormItem>
        <FormItem
          name="frequencyType"
          label={t('quota.form.frequency.label')}
          required
        >
          <Select size="big" options={frequenciesOptions} disabled={createQuotaIsPending || frequency !== null} />
        </FormItem>
        {dayPeriodTypeNodes}
        <FormItem
          name="defaultValue"
          label={t('quota.form.defaultValue.label')}
          extra={t('quota.form.defaultValue.description')}
          required
        >
          <Input size="big" type="comaSeparatorFormatted" disabled={createQuotaIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

CreateQuotaModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  frequency: PropTypes.number,
  afterCreate: PropTypes.func,
};

CreateQuotaModal.defaultProps = {
  visible: false,
  onClose: () => {},
  periodType: null,
  frequency: null,
  afterCreate: null,
};

export default CreateQuotaModal;
