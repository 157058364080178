export const QUOTA_PERFORMANCE_ACTIONS = {
  UPDATE_QUOTA_PERFORMANCE_NAME: 'UPDATE_QUOTA_PERFORMANCE_NAME',
  UPDATE_QUOTA_PERFORMANCE_DESCRIPTION: 'UPDATE_QUOTA_PERFORMANCE_DESCRIPTION',
};

export const QUOTA_PERFORMANCE_UPDATABLE_FIELDS = {
  NAME: 'name',
  DESCRIPTION: 'description',
};

export const QUOTA_PERFORMANCE_METRIC_TYPES = {
  PERCENTAGE: 'percentage',
};

export const QUOTA_PERFORMANCE_METRIC_THEMES = {
  GREEN: 'green',
  KHAKI: 'khaki',
  WHITE: 'white',
  YELLOW: 'yellow',
  ORANGE: 'orange',
};

export const QUOTA_PERFORMANCE_ROWS_PARAMS = {
  ROWS: 'rows',
  USER: 'user',
  OPERATOR: 'operator',
};
