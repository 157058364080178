import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import CodeEditor from '@palette/components/designSystem/CodeEditor/CodeEditor';

import styles from './NotebookCodeEditorCellContent.less';

const classNames = bindClassNames.bind(styles);

const NotebookCodeEditorCellContent = ({
  className,
  codeEditorLanguage,
  content,
  onUpdate,
  onFocus,
  onBlur,
  disabled,
}) => (
  <CodeEditor
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
    defaultLanguage={codeEditorLanguage}
    value={content}
    onChange={onUpdate}
    onFocus={onFocus}
    onBlur={onBlur}
    disabled={disabled}
  />
);

NotebookCodeEditorCellContent.propTypes = {
  className: PropTypes.string,
  codeEditorLanguage: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NotebookCodeEditorCellContent.defaultProps = {
  className: '',
  disabled: false,
};

export default NotebookCodeEditorCellContent;
