import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@palette/components/designSystem/Button/Button';
import RemoveUserFromQuotaModal from '@palette/components/quota/RemoveUserFromQuotaModal/RemoveUserFromQuotaModal';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { RIGHTS } from '@palette/constants/profile';

import styles from './RemoveUserFromQuota.less';

const RemoveUserFromQuota = ({ className, quotaUserId, quotaId }) => {
  const profile = useProfile();

  const [removeUserFromQuotaIsVisible, showRemoveUserFromQuota] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE])) return null;

  return (
    <div className={className}>
      <Button
        className={styles.button}
        type="primary"
        shape="circle"
        onClick={() => showRemoveUserFromQuota(true)}
        icon={<TrashFilled width={14} height={14} />}
      />
      {
        removeUserFromQuotaIsVisible && (
          <RemoveUserFromQuotaModal visible onClose={() => showRemoveUserFromQuota(false)} quotaUserId={quotaUserId} quotaId={quotaId} />
        )
      }
    </div>
  );
};

RemoveUserFromQuota.propTypes = {
  className: PropTypes.string,
  quotaUserId: PropTypes.string.isRequired,
  quotaId: PropTypes.string.isRequired,
};

RemoveUserFromQuota.defaultProps = {
  className: '',
};

export default RemoveUserFromQuota;
