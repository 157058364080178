import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage404 = (problem) => {
  const managedErrors = {
    'preset.not.found': {
      code: GLOBAL_NOTIF_REASONS.PRESET_404_PRESET_NOT_FOUND.code,
    },
    'plan.not.found': {
      code: GLOBAL_NOTIF_REASONS.PRESET_404_PLAN_NOT_FOUND.code,
    },
    'object.not.found': {
      code: GLOBAL_NOTIF_REASONS.PRESET_404_OBJECT_NOT_FOUND.code,
    },
    'user.not.found': {
      code: GLOBAL_NOTIF_REASONS.PRESET_404_USER_NOT_FOUND.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage409 = (problem) => {
  const managedErrors = {
    'cannot.delete.default.preset': {
      code: GLOBAL_NOTIF_REASONS.PRESET_409_CANNOT_DELETE_DEFAULT.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    404: manage404,
    409: manage409,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code } = error;

    return {
      ...problem,
      message: { code },
    };
  }

  return manageGlobalError({ status, problem });
};
