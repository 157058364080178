import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import EditDataConnectionConnectorModal from '@palette/components/planV3/DataSourcesList/EditDataConnectionConnectorModal/EditDataConnectionConnectorModal';

import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './EditDataConnectionConnectorButton.less';

const classNames = bindClassNames.bind(styles);

const EditDataConnectionConnectorButton = ({ className, dataConnectionConnector, isPending }) => {
  const { t } = useTranslation();

  const [editDataConnectionConnectorModalIsVisible, showEditDataConnectionConnectorModal] = useState(false);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        onClick={() => showEditDataConnectionConnectorModal(true)}
        disabled={isPending}
      >
        {t('actions.edit')}
      </Button>
      {
        editDataConnectionConnectorModalIsVisible && !isPending && (
          <EditDataConnectionConnectorModal
            visible
            onClose={() => showEditDataConnectionConnectorModal(false)}
            dataConnectionConnector={dataConnectionConnector}
          />
        )
      }
    </div>
  );
};

EditDataConnectionConnectorButton.propTypes = {
  className: PropTypes.string,
  dataConnectionConnector: NotebookDataConnectionConnectorModel.propTypes.isRequired,
  isPending: PropTypes.bool,
};

EditDataConnectionConnectorButton.defaultProps = {
  className: '',
  isPending: false,
};

export default EditDataConnectionConnectorButton;
