import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import UsersStatementsApprovalsList from '@palette/components/statement/statementPeriodUsers/UsersStatementsApprovalsList/UsersStatementsApprovalsList';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import Tag from '@palette/components/designSystem/Tag/Tag';
import MyStatementMarkAsPaidLabel from '@palette/components/ic/myStatement/MyStatementMarkAsPaidLabel/MyStatementMarkAsPaidLabel';
import StatementApproveAction from '@palette/components/statement/statementCommon/StatementApproveAction/StatementApproveAction';

import { getPeriodName } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import { STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementModel from '@palette/models/UserStatement';

import { actions as StatementsActions } from '@palette/state/Statements';

import styles from './MyUserStatementInfosAndActions.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementInfosAndActions = ({ className, statementPeriod, userStatement, inSalesforce }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const periodName = getPeriodName(statementPeriod.statementStrategy, statementPeriod.period, statementPeriod.year);

  const handleExport = useCallback(() => {
    if (inSalesforce) return;

    dispatch(StatementsActions.downloadMyUserStatements({
      statementPeriodId: statementPeriod.id,
    }));
  }, [statementPeriod, inSalesforce]);

  const statementAmount = formatPrice(userStatement.statementAmount, userStatement.currency);
  const statementPaidByAmount = formatPrice(userStatement.paidBy?.amount, userStatement.currency);

  const { icon, theme } = STATEMENT_PERIOD_USER_STATUSES.PAID_AMOUNT;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infos}>
        <div className={styles.periodName}>{periodName}</div>
        <div className={styles.statementAmount}>{statementAmount}</div>
        {userStatement.isPaid && userStatement.paidBy && statementAmount !== statementPaidByAmount && (
          <div className={styles.statementPaidAmount}>
            <Tag
              theme={theme}
              label={t('statement.statuses.paid.amount', { amount: statementPaidByAmount })}
              icon={icon}
            />
          </div>
        )}
      </div>
      <div className={styles.actions}>
        {!inSalesforce && (
          <Button
            className={styles.actionLink}
            type="link"
            icon={<DownloadFilled width={20} height={20} />}
            onClick={handleExport}
          >
            {t('userStatement.infosAndActions.export')}
          </Button>
        )}
        <UsersStatementsApprovalsList
          className={styles.approvalsListWrapper}
          userStatement={userStatement}
          limitShown={6}
        />
        <div className={styles.buttonsWrapper}>
          {!inSalesforce && (
            <StatementApproveAction
              className={styles.actionButton}
              userStatement={userStatement}
              statementPeriod={statementPeriod}
              isForIC
            />
          )}
          <MyStatementMarkAsPaidLabel
            className={styles.actionButton}
            userStatement={userStatement}
          />
        </div>
      </div>
    </div>
  );
};

MyUserStatementInfosAndActions.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  userStatement: UserStatementModel.propTypes.isRequired,
  inSalesforce: PropTypes.bool,
};

MyUserStatementInfosAndActions.defaultProps = {
  className: '',
  inSalesforce: false,
};

export default MyUserStatementInfosAndActions;
