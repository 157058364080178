import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as CompanyModel from '@palette/models/Company';
import * as MetaUserAccountModel from '@palette/models/MetaUserAccount';
import * as ResourceObjectModel from '@palette/models/ResourceObject';

export const modelName = 'MetaUser';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  displayName: PropTypes.string,
  joinDate: PropTypes.string,
  leaveDate: PropTypes.string,
  currency: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  payrollId: PropTypes.string,
  companyId: PropTypes.string,
  company: CompanyModel.propTypes,
  account: MetaUserAccountModel.propTypes,
  resourceObjects: PropTypes.arrayOf(ResourceObjectModel.propTypes),
});
export const getDisplayName = (firstName, lastName, email) => {
  const displayName = `${firstName} ${lastName}`.trim();

  return displayName.length === 0 ? email : displayName;
};

export const transform = (metaUser) => {
  if (!metaUser) {
    return null;
  }

  const firstName = manageStringAttribute(metaUser, 'firstName');
  const lastName = manageStringAttribute(metaUser, 'lastName');
  const email = manageStringAttribute(metaUser, 'email');

  let company = null;
  let companyId = manageStringAttribute(metaUser, 'company', null, true);
  if (companyId === null) {
    company = CompanyModel.transform(manageFreeShapeObjectAttribute(metaUser, 'company', null));
    companyId = company?.id || '';
  }

  return (
    {
      id: manageStringAttribute(metaUser, '_id'),
      firstName,
      lastName,
      email,
      displayName: getDisplayName(firstName, lastName, email),
      joinDate: manageDateAttribute(metaUser, 'joinDate'),
      leaveDate: manageDateAttribute(metaUser, 'leaveDate'),
      currency: manageStringAttribute(metaUser, 'currency', null),
      profilePictureUrl: manageStringAttribute(metaUser, 'profilePicUrl', null),
      payrollId: manageStringAttribute(metaUser, 'payrollId', null),
      companyId,
      company,
      account: MetaUserAccountModel.transform(manageFreeShapeObjectAttribute(metaUser, 'account', null)),
      resourceObjects: ResourceObjectModel.transformList(manageArrayAttribute(metaUser, 'objectIds')),
    }
  );
};

export const transformList = (metaUsers) => metaUsers.map((metaUser) => transform(metaUser));
