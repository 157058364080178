import { useDrag, useDrop } from 'react-dnd';

import { FOLDER_ITEM_TYPES, DEFAULT_FOLDER_ID } from '@palette/constants/folders';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { RIGHTS } from '@palette/constants/profile';

export const getUseDrag = (profile, item, folder = null) => useDrag(() => ({
  item,
  collect: (monitor) => ({
    isDragging: !!monitor.isDragging(),
    ...(!folder && { draggedItem: monitor.getItem() }),
  }),
  canDrag: () => (
    hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])
  ),
}), [item, folder]);

export const getUseDrop = (onMoveFolder, onMoveEntity, folder = null) => useDrop(() => ({
  accept: [FOLDER_ITEM_TYPES.FOLDER, FOLDER_ITEM_TYPES.RESOURCE],
  drop: (_item, monitor) => {
    const didDrop = monitor.didDrop();

    if (didDrop) {
      return;
    }

    const { type } = _item;

    if (type === FOLDER_ITEM_TYPES.FOLDER && onMoveFolder !== null) {
      const { folderId } = _item;
      const parentId = folder?.id || DEFAULT_FOLDER_ID;
      onMoveFolder(folderId, parentId);
    }

    if (type === FOLDER_ITEM_TYPES.RESOURCE && onMoveEntity !== null) {
      const { resourceId, resourceParentId } = _item;
      const parentId = folder?.id || DEFAULT_FOLDER_ID;

      if (parentId !== resourceParentId) {
        onMoveEntity(resourceId, parentId);
      }
    }
  },
  ...(folder && {
    canDrop: (_item) => (
      _item.type === FOLDER_ITEM_TYPES.RESOURCE
      || (_item.type === FOLDER_ITEM_TYPES.FOLDER && _item.folderId !== folder.id)
    ),
  }),
  collect: (monitor) => ({
    isOverCurrent: !!monitor.isOver({ shallow: true }),
    ...(folder && { isOver: !!monitor.isOver() }),
    ...(folder && { canDrop: !!monitor.canDrop() }),
  }),
}), [folder, onMoveFolder, onMoveEntity]);
