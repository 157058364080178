import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _cloneDeep from 'lodash/cloneDeep';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import Input from '@palette/components/designSystem/Input/Input';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import AddDataConnectionDataSelectorBlockListItem from '@palette/components/notebooks/AddDataConnectionDataSelectorBlockListItem/AddDataConnectionDataSelectorBlockListItem';
import Loader from '@palette/components/utils/Loader/Loader';

import * as NotebookDataSelectorBlockListItemModel from '@palette/models/NotebookDataSelectorBlockListItem';

import styles from './AddDataConnectionDataSelectorBlock.less';

const classNames = bindClassNames.bind(styles);

const BLOCK_LIST_ITEM_TOTAL_HEIGHT = 55;

const AddDataConnectionDataSelectorBlock = ({
  className,
  title,
  subtitle,
  items,
  openedItemKey,
  onOpenItem,
  selectedItemsKeys,
  onUpdateSelectedItems,
  disabled,
  loading,
}) => {
  const { t } = useTranslation();

  const itemsListRef = useRef(null);
  const scrollDetectorRef = useRef(null);

  const [verticalScrollPosition, setVerticalScrollPosition] = useState(0);
  const [frozenVerticalScrollPosition, setFrozenVerticalScrollPosition] = useState(-1);

  const freezeVerticalScrollPosition = useCallback(() => {
    setFrozenVerticalScrollPosition(verticalScrollPosition);
  }, [verticalScrollPosition]);

  const resetVerticalScrollPosition = useCallback(() => {
    if (frozenVerticalScrollPosition > 0) {
      itemsListRef.current.scrollTop = frozenVerticalScrollPosition + BLOCK_LIST_ITEM_TOTAL_HEIGHT;
    }
    setFrozenVerticalScrollPosition(-1);
  }, [frozenVerticalScrollPosition, itemsListRef]);

  useScrollPosition(
    ({ currPos }) => {
      setVerticalScrollPosition(currPos.y);
    },
    [],
    scrollDetectorRef,
    false,
    100,
    itemsListRef,
  );

  const [searchValue, setSearchValue] = useState(null);
  const [selectedItems, setSelectedItems] = useState(selectedItemsKeys);

  useEffect(() => {
    setSelectedItems(selectedItemsKeys);
    resetVerticalScrollPosition();
  }, [selectedItemsKeys]);

  const handleSearchChange = useCallback((newSearch) => {
    setSearchValue(newSearch.length > 0 ? newSearch : null);
  }, []);

  const handleOpenItem = useCallback((itemKey) => {
    if (onOpenItem !== null) onOpenItem(itemKey);
  }, [onOpenItem]);

  const handleUpdateSelectedItems = useCallback((newSelectedItems) => {
    if (onUpdateSelectedItems !== null) onUpdateSelectedItems(newSelectedItems);
  }, [onUpdateSelectedItems]);

  const handleItemSelectChange = useCallback((itemKey, selected) => {
    const selectedItemIndex = selectedItems.findIndex((selectedItem) => (selectedItem === itemKey));
    const isSelected = selectedItemIndex !== -1;
    if (
      (selected && isSelected)
      || (!selected && !isSelected)
    ) return;

    if (selected) {
      freezeVerticalScrollPosition();
      const newSelectedItems = _cloneDeep(selectedItems);
      newSelectedItems.push(itemKey);
      setSelectedItems(newSelectedItems);
      handleUpdateSelectedItems(newSelectedItems);
      return;
    }

    const newSelectedItems = _cloneDeep(selectedItems);
    newSelectedItems.splice(selectedItemIndex, 1);
    setSelectedItems(newSelectedItems);
    handleUpdateSelectedItems(newSelectedItems);
  }, [selectedItems, handleUpdateSelectedItems, freezeVerticalScrollPosition]);

  const filteredItems = useMemo(() => {
    if (searchValue === null || searchValue === '') return items;

    return items.filter((item) => item.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
  }, [items, searchValue]);

  const headerNode = useMemo(() => (
    <div className={styles.header}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.subtitle}>
        {subtitle}
      </div>
    </div>
  ), []);

  const searchNode = useMemo(() => (
    <Input
      className={styles.search}
      type="search"
      placeholder={t('addDataConnectionDataSelectorBlock.search.placeholder')}
      onChange={handleSearchChange}
      value={searchValue}
    />
  ), [searchValue, handleSearchChange]);

  const itemsListNodes = useMemo(() => {
    if (loading) {
      return (
        <Loader spinning />
      );
    }

    if (filteredItems.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return filteredItems.map((item) => (
      <AddDataConnectionDataSelectorBlockListItem
        key={item.key}
        className={styles.item}
        item={item}
        isOpened={item.key === openedItemKey}
        isSelected={selectedItems.includes(item.key)}
        onOpen={handleOpenItem}
        onSelectChange={handleItemSelectChange}
        disabled={disabled}
      />
    ));
  }, [
    filteredItems,
    openedItemKey,
    handleOpenItem,
    selectedItems,
    handleItemSelectChange,
    disabled,
    loading,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {headerNode}
      {searchNode}
      <div
        ref={itemsListRef}
        className={styles.list}
      >
        <div ref={scrollDetectorRef} />
        {itemsListNodes}
      </div>
    </div>
  );
};

AddDataConnectionDataSelectorBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  items: PropTypes.arrayOf(NotebookDataSelectorBlockListItemModel.propTypes).isRequired,
  openedItemKey: PropTypes.string,
  onOpenItem: PropTypes.func,
  selectedItemsKeys: PropTypes.arrayOf(PropTypes.string),
  onUpdateSelectedItems: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

AddDataConnectionDataSelectorBlock.defaultProps = {
  className: '',
  subtitle: '',
  openedItemKey: null,
  onOpenItem: null,
  selectedItemsKeys: [],
  onUpdateSelectedItems: null,
  disabled: false,
  loading: false,
};

export default AddDataConnectionDataSelectorBlock;
