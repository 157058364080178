import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Popover from '@palette/components/designSystem/Popover/Popover';
import AddLine from '@palette/components/utils/Icons/AddLine';

import { NOTEBOOK_BASIC_CELL_TYPES, NOTEBOOK_NO_SUPPORT_CELL_TYPES } from '@palette/constants/notebooks';

import styles from './AddNotebookCell.less';

const classNames = bindClassNames.bind(styles);

const AddNotebookCell = ({ className, onAddCell, disabled }) => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisibleChange = useCallback((isVisible) => {
    setPopoverVisible(isVisible);
  }, []);

  const handleSelectCellType = useCallback((cellTypeId) => {
    setPopoverVisible(false);
    onAddCell(cellTypeId);
  }, [onAddCell]);

  const buildCellTypesItems = useCallback((cellTypesList) => (
    cellTypesList.map((cellType) => (
      <div
        key={cellType.id}
        className={styles.cellType}
        onClick={() => handleSelectCellType(cellType.id)}
      >
        {t(cellType.i18n)}
      </div>
    ))
  ), [handleSelectCellType]);

  const basicCellOptions = useMemo(() => {
    const cellTypesNodes = buildCellTypesItems(NOTEBOOK_BASIC_CELL_TYPES);

    return (
      <div className={styles.cellTypesWrapper}>
        <div className={styles.cellTypesCategory}>
          {t('addNotebookCell.cellTypesCategories.basic')}
        </div>
        <div className={styles.cellTypesList}>
          {cellTypesNodes}
        </div>
      </div>
    );
  }, [buildCellTypesItems]);

  const noCSSupportCellOptions = useMemo(() => {
    const cellTypesNodes = buildCellTypesItems(NOTEBOOK_NO_SUPPORT_CELL_TYPES);

    return (
      <div className={styles.cellTypesWrapper}>
        <div className={styles.cellTypesCategory}>
          {t('addNotebookCell.cellTypesCategories.noSupport')}
        </div>
        <div className={styles.cellTypesList}>
          {cellTypesNodes}
        </div>
      </div>
    );
  }, [buildCellTypesItems]);

  const contentNode = useMemo(() => (
    <div className={styles.popoverContent}>
      {basicCellOptions}
      {noCSSupportCellOptions}
    </div>
  ), [
    basicCellOptions,
    noCSSupportCellOptions,
  ]);

  if (disabled) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Popover
        className={styles.popover}
        trigger="click"
        placement="bottom"
        content={contentNode}
        onVisibleChange={handlePopoverVisibleChange}
        visible={popoverVisible}
      >
        <div className={styles.iconWrapper}>
          <AddLine className={styles.icon} />
        </div>
      </Popover>
    </div>
  );
};

AddNotebookCell.propTypes = {
  className: PropTypes.string,
  onAddCell: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AddNotebookCell.defaultProps = {
  className: '',
  disabled: false,
};

export default AddNotebookCell;
