import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import HighlightWidgetBase from '@palette/components/widgets/highlight/HighlightWidgetBase/HighlightWidgetBase';
import CommissionsDueEstimateWidgetContent from '@palette/components/widgetContents/CommissionsDueEstimateWidgetContent/CommissionsDueEstimateWidgetContent';

import { getDefaultPeriodValue } from '@palette/helpers/MasterPlanHelper';
import { getFrequencyPeriodName, getNextPeriod } from '@palette/helpers/FrequencyHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as HighlightComponentDataModel from '@palette/models/HighlightComponentData';
import * as CommissionsDueEstimateDataModel from '@palette/models/widgets/highlight/CommissionsDueEstimateData';

import styles from './HighlightCommissionsDueEstimateWidget.less';

const classNames = bindClassNames.bind(styles);

const HighlightCommissionsDueEstimateWidget = ({ className, widgetData }) => {
  const { t } = useTranslation();

  const commissionsDueEstimateData = CommissionsDueEstimateDataModel.transform(widgetData.data);

  const currentPeriod = getDefaultPeriodValue(PERIOD_TYPES.MONTH, 1);
  const nextPeriod = getNextPeriod(PERIOD_TYPES.MONTH, 1, currentPeriod.year, currentPeriod.period);
  const nextPeriodName = getFrequencyPeriodName(PERIOD_TYPES.MONTH, 1, nextPeriod.year, nextPeriod.period);

  return (
    <HighlightWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('highlightCommissionsDueEstimateWidget.title')}
      subtitle={t('highlightCommissionsDueEstimateWidget.subtitle', { periodName: nextPeriodName })}
    >
      <CommissionsDueEstimateWidgetContent data={commissionsDueEstimateData} />
    </HighlightWidgetBase>
  );
};

HighlightCommissionsDueEstimateWidget.propTypes = {
  className: PropTypes.string,
  widgetData: HighlightComponentDataModel.propTypes.isRequired,
};

HighlightCommissionsDueEstimateWidget.defaultProps = {
  className: '',
};

export default HighlightCommissionsDueEstimateWidget;
