import PropTypes from 'prop-types';

import { manageAmountAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'PlanTotalCommissionsData';

export const propTypes = PropTypes.shape({
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
});

export const transform = (planTotalCommissions) => {
  if (!planTotalCommissions) {
    return null;
  }

  return (
    {
      totalCommissionAmount: manageAmountAttribute(planTotalCommissions, 'totalCommissionAmount'),
      currency: manageStringAttribute(planTotalCommissions, 'currency'),
    }
  );
};
