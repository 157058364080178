import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PlanWidgetBase from '@palette/components/widgets/dashboard/PlanWidgetBase/PlanWidgetBase';
import PlanWhatIfProjectionChart from '@palette/components/charts/PlanWhatIfProjectionChart/PlanWhatIfProjectionChart';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';
import * as PlanWhatIfProjectionDataModel from '@palette/models/widgets/dashboard/PlanWhatIfProjectionData';
import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './PlanWhatIfProjectionWidget.less';

const classNames = bindClassNames.bind(styles);

const PlanWhatIfProjectionWidget = ({
  className,
  widgetData,
  widgetIndex,
  readOnly,
  enableLinks,
  user,
}) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planWhatIfProjectionData = PlanWhatIfProjectionDataModel.transform(widgetData.data);

  return (
    <PlanWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      useMostRecent={widgetData.dashboardComponent.params.useMostRecent}
      plan={planPeriodData.plan}
      period={planPeriodData.period}
      title={t('planWhatIfProjectionWidget.title')}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      <PlanWhatIfProjectionChart
        plan={planPeriodData.plan}
        period={planPeriodData.period}
        data={planWhatIfProjectionData}
        forAdmin={readOnly}
        user={user}
      />
    </PlanWidgetBase>
  );
};

PlanWhatIfProjectionWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
  user: MetaUserModel.propTypes,
};

PlanWhatIfProjectionWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
  user: null,
};

export default PlanWhatIfProjectionWidget;
