import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';
import Button from '@palette/components/designSystem/Button/Button';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { useCompanyDefaultCurrency } from '@palette/hooks/CompanyHooks';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './UserDataSettings.less';

const classNames = bindClassNames.bind(styles);

const UserDataSettings = ({ className, user }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();

  const updateUserIsPending = useSelector(UsersSelectors.updateUserIsPending);

  const [form] = AntDForm.useForm();

  const defaultCurrency = useCompanyDefaultCurrency();

  const initialValues = useMemo(() => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    currency: user?.currency ?? defaultCurrency,
    payrollId: user?.payrollId ?? '',
    joinDate: user?.joinDate ? moment.utc(user?.joinDate) : null,
  }), [user, defaultCurrency]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
    form.resetFields();
  }, [initialValues]);

  const handleSubmitForm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleFinish = useCallback((values) => {
    const {
      firstName,
      lastName,
      email,
      currency,
      payrollId,
      joinDate,
    } = values;

    const joinDateValue = joinDate !== null ? moment.utc(joinDate).startOf('day').format() : null;

    dispatch(UsersActions.updateUser({
      userId: user.id,
      firstName,
      lastName,
      email,
      currency,
      payrollId,
      joinDate: joinDateValue,
    }));
  }, [form, user]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setFormValues(allValues);
  }, [form]);

  const formIsPristine = useMemo(() => (
    _isEqual(initialValues, formValues)
  ), [initialValues, formValues]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <h2>{t('userDataSettings.title')}</h2>
      <Form
        className={styles.form}
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        onValuesChange={handleFormValuesChange}
      >
        <FormItem
          className={styles.formItem}
          name="firstName"
          label={t('userDataSettings.form.firstName.label')}
          required
          rules={[
            { required: true, message: t('userDataSettings.form.firstName.rules.required') },
          ]}
        >
          <Input size="big" disabled={updateUserIsPending} />
        </FormItem>
        <FormItem
          className={styles.formItem}
          name="lastName"
          label={t('userDataSettings.form.lastName.label')}
          required
          rules={[
            { required: true, message: t('userDataSettings.form.lastName.rules.required') },
          ]}
        >
          <Input size="big" disabled={updateUserIsPending} />
        </FormItem>
        <FormItem
          className={styles.formItem}
          name="email"
          label={t('userDataSettings.form.email.label')}
          required
          rules={[
            { required: true, message: t('userDataSettings.form.email.rules.required') },
            { type: 'email', warningOnly: false, message: t('userDataSettings.form.email.rules.email') },
          ]}
        >
          <Input size="big" disabled={updateUserIsPending} />
        </FormItem>
        <FormItem
          className={styles.formItem}
          name="currency"
          label={t('userDataSettings.form.currency.label')}
          required
        >
          <CompanyCurrencySelect size="big" disabled={updateUserIsPending} />
        </FormItem>
        <FormItem
          className={styles.formItem}
          name="payrollId"
          label={t('userDataSettings.form.payrollId.label')}
        >
          <Input size="big" disabled={updateUserIsPending} />
        </FormItem>
        <FormItem
          className={styles.formItem}
          name="joinDate"
          label={t('userDataSettings.form.hireDate.label')}
        >
          <DatePicker
            className={styles.hireDatePicker}
            size="big"
            picker="date"
            allowClear
            disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
            disabled={updateUserIsPending}
          />
        </FormItem>
        <Button className={styles.submitBtn} onClick={handleSubmitForm} disabled={formIsPristine || updateUserIsPending}>
          {t('userDataSettings.form.submitBtnLabel')}
        </Button>
      </Form>
    </div>
  );
};

UserDataSettings.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDataSettings.defaultProps = {
  className: '',
};

export default UserDataSettings;
