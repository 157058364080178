import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';

import { myEval } from '@palette/helpers/EvalHelper';
import { convertAnyValueToMoment } from '@palette/helpers/MomentHelper';

import { COLUMN_DATE_FORMAT, CONNECTOR_TYPES } from '@palette/constants/connector';

export const getResourceColumns = (connector, objectType, filterFn = null) => {
  if (connector === null) return [];
  const resource = connector.resources.filter((re) => re.type === objectType)[0] || null;
  if (resource === null) return [];

  return filterFn !== null ? resource.columns.filter(filterFn) : resource.columns;
};

export const getColumnValue = (objectData, column, defaultValue = '') => {
  if (!column) return '';

  const fieldsValues = column.fields.reduce((res, field) => ({ ...res, [field]: _get(objectData, field) }), {});
  let value = Object.values(fieldsValues).join(' ').trim();
  if (value.length === 0) {
    value = defaultValue;
  }

  if (COLUMN_DATE_FORMAT[column.formatter] && value !== '') {
    return convertAnyValueToMoment(value).format(COLUMN_DATE_FORMAT[column.formatter]);
  }

  if (column.formatter === 'Custom') {
    const evalResult = myEval(column.evalFn, {
      value,
      fieldsValues,
    }, defaultValue);

    if (evalResult == null) return '';
    return evalResult.toString();
  }

  return value;
};

export const manageConnectorName = ({ type, name }) => {
  const connectorTypeSwitch = {
    [CONNECTOR_TYPES.QUICKBOOKS]: 'QuickBooks',
    [CONNECTOR_TYPES.QUICKBOOKS_SANDBOX]: 'QuickBooks Sandbox',
  };

  const connectorTypePart = connectorTypeSwitch[type] || _capitalize(type.split('_').join(' '));
  const connectorNamePart = name && name.length > 0 ? ` - ${name}` : '';
  return `${connectorTypePart}${connectorNamePart}`;
};
