export const HIGHLIGHT_WIDGETS = {
  COMMISSIONS_TOTAL: {
    type: 'COMMISSIONS_TOTAL',
    nameI18NId: 'widgets.highlight.commissions_total.name',
    filterNameI18NId: 'widgets.highlight.commissions_total.shortname',
  },
  COMMISSIONS_UNRELEASED: {
    type: 'COMMISSIONS_UNRELEASED',
    nameI18NId: 'widgets.highlight.commissions_unreleased.name',
    filterNameI18NId: 'widgets.highlight.commissions_unreleased.shortname',
  },
  COMMISSIONS_DUE_ESTIMATE: {
    type: 'COMMISSIONS_DUE_ESTIMATE',
    nameI18NId: 'widgets.highlight.commissions_due_estimate.name',
    filterNameI18NId: 'widgets.highlight.commissions_due_estimate.shortname',
  },
  PLAN_PROGRESS_GAUGE: {
    type: 'PLAN_PROGRESS_GAUGE',
    nameI18NId: 'widgets.highlight.progress.name',
    filterNameI18NId: 'widgets.highlight.progress.shortname',
  },
  PLAN_TOTAL_VALUE: {
    type: 'PLAN_TOTAL_VALUE',
    nameI18NId: 'widgets.highlight.total_value.name',
    filterNameI18NId: 'widgets.highlight.total_value.shortname',
  },
};
