import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import MasterPlanSettingsPageContent from '@palette/components/masterPlanSettings/MasterPlanSettingsPageContent/MasterPlanSettingsPageContent';
import MasterPlanBreadcrumb from '@palette/components/masterPlan/MasterPlanBreadcrumb/MasterPlanBreadcrumb';
import MasterPlanRecomputeWarning from '@palette/components/masterPlan/MasterPlanRecomputeWarning/MasterPlanRecomputeWarning';
import MasterPlanSettingsDocumentationLink from '@palette/components/masterPlanSettings/MasterPlanSettingsDocumentationLink/MasterPlanSettingsDocumentationLink';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useMasterPlanInParams } from '@palette/hooks/MasterPlanHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './MasterPlanSettingsPage.less';

const MasterPlanSettingsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { plan } = useMasterPlanInParams();

  if (!hasAtLeastOneRight(profile, [
    RIGHTS.ADMIN.PLANS.VIEW,
    RIGHTS.ADMIN.PLANS.UPDATE,
    RIGHTS.ADMIN.PLANS.GBO.MANAGE,
    RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
  ])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.masterPlansSettingsTitle', { planName: plan?.name || '' })} />
      <MasterPlanBreadcrumb className={styles.breadcrumb} plan={plan} settings />
      <PageTitle className={styles.pageTitle}>
        <h3>
          {t('masterPlanSettingsPage.title')}
        </h3>
        <MasterPlanSettingsDocumentationLink className={styles.documentationLink} />
      </PageTitle>
      <MasterPlanRecomputeWarning plan={plan} />
      <MasterPlanSettingsPageContent />
    </div>
  );
};

export default MasterPlanSettingsPage;
