import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import AddLine from '@palette/components/utils/Icons/AddLine';
import BlingBlingIllustration from '@palette/components/utils/Icons/BlingBlingIllustration';
import Input from '@palette/components/designSystem/Input/Input';
import InputAmountCurrency from '@palette/components/designSystem/InputAmountCurrency/InputAmountCurrency';

import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';
import { addCurrencyToNode } from '@palette/helpers/CurrencyHelper';
import { getPeriodDates, getPeriodName } from '@palette/helpers/StatementHelper';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useStatementUserIdInParams, usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';
import * as StatementV3ListCorrectionModel from '@palette/models/StatementV3ListCorrection';

import styles from './StatementV3AddEditAdjustmentModal.less';

const StatementV3AddEditAdjustmentModal = ({
  visible,
  onClose,
  statement,
  correction,
  currency,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const { userId } = useStatementUserIdInParams();

  const addEditAdjustmentIsPending = useSelector(StatementV3Selectors.addEditAdjustmentIsPending);

  const isEdition = !!correction;

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    adjustmentReason: correction?.reason ?? '',
    adjustmentAmount: correction?.amount ?? null,
    adjustmentCurrency: correction?.currency ?? statement.currency ?? profile.userData.company.currency,
  }), [correction, statement, profile]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  const handleClose = () => onClose();

  const handleSuccessCB = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const handleFinish = useCallback((values) => {
    const { adjustmentReason, adjustmentAmount } = values;

    dispatch(StatementV3Actions.addEditAdjustment({
      statementId: statement.id,
      correctionId: correction?.id || undefined,
      amount: adjustmentAmount,
      currency: formValues.adjustmentCurrency,
      reason: adjustmentReason,
      statementCurrency: statement.currency,
      onSuccessCB: handleSuccessCB,
    }));
  }, [
    statement,
    correction,
    formValues,
    handleSuccessCB,
    userId,
    currency,
    periodsDatesFilterQSObject,
  ]);

  const handleFormValuesChange = useCallback((_, allValues) => {
    setFormValues({
      ...formValues,
      ...allValues,
    });
  }, [formValues]);

  const handleCurrencyChange = useCallback((currencySelected) => {
    setFormValues({
      ...formValues,
      adjustmentCurrency: currencySelected,
    });
  }, [formValues]);

  const formIsPristine = useMemo(() => {
    if (_isEqual(initialValues, formValues)) return true;

    if (
      (formValues.adjustmentCurrency !== initialValues.adjustmentCurrency)
      && !formValues.adjustmentAmount
      && formValues.adjustmentAmount !== 0
    ) return true;

    return false;
  }, [initialValues, formValues]);

  const totalCommissionsNode = useMemo(() => {
    const periodName = getPeriodName({ type: statement.periodicity }, statement.period);
    const periodDates = getPeriodDates(statement.fromDate, statement.toDate);
    const splittedFormattedValue = comaSeparatorFormatter(statement.amount).split('.');
    const valuesNodes = [];

    valuesNodes.push((
      <div key="integerPart" className={styles.integerPart}>
        {splittedFormattedValue[0]}
      </div>
    ));
    if (splittedFormattedValue.length > 1) {
      valuesNodes.push((
        <div key="decimalPart" className={styles.decimalPart}>
          {`.${splittedFormattedValue[1]}`}
        </div>
      ));
    }

    return (
      <div className={styles.totalCommissionsWrapper}>
        <BlingBlingIllustration className={styles.illustration} />
        <div className={styles.label}>
          {t('userStatementAdjustments.addEditAdjustment.modal.commissions.label')}
        </div>
        <div className={styles.amount}>
          {addCurrencyToNode(valuesNodes, statement.currency)}
        </div>
        <div className={styles.period}>
          <span className={styles.periodName}>{periodName}</span>
          {periodDates}
        </div>
      </div>
    );
  }, [statement]);

  const titleNode = useMemo(() => {
    let title = t('userStatementAdjustments.addEditAdjustment.modal.add.title');

    if (isEdition) {
      title = t('userStatementAdjustments.addEditAdjustment.modal.edit.title');
    }

    return (
      <div className={styles.titleWrapper}>
        {title}
      </div>
    );
  }, [isEdition]);

  const okTextNode = useMemo(() => {
    let okText = t('userStatementAdjustments.addEditAdjustment.save.add.label');

    if (isEdition) {
      okText = t('userStatementAdjustments.addEditAdjustment.save.edit.label');
    }

    return okText;
  }, [isEdition]);

  const handleUpdateAdjustment = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={titleNode}
      visible={visible}
      onCancel={handleClose}
      onOk={handleUpdateAdjustment}
      okText={okTextNode}
      okIcon={isEdition ? <CheckFilled /> : <AddLine />}
      loading={addEditAdjustmentIsPending}
      disableOk={formIsPristine}
    >
      {totalCommissionsNode}
      <Form
        onFinish={handleFinish}
        initialValues={initialValues}
        form={form}
        onValuesChange={handleFormValuesChange}
      >
        <FormItem
          name="adjustmentAmount"
          label={t('userStatementAdjustments.addEditAdjustment.amount.label')}
          required
          rules={[
            { required: true, message: t('userStatementAdjustments.addEditAdjustment.amount.error.message') },
          ]}
        >
          <InputAmountCurrency
            placeholder="0"
            size="big"
            currency={formValues.adjustmentCurrency}
            onCurrencyChange={handleCurrencyChange}
          />
        </FormItem>
        <FormItem
          name="adjustmentReason"
          label={t('userStatementAdjustments.addEditAdjustment.reason.label')}
          required
          rules={[
            { required: true, message: t('userStatementAdjustments.addEditAdjustment.reason.error.message') },
          ]}
        >
          <Input
            placeholder={t('userStatementAdjustments.addEditAdjustment.reason.placeholder')}
            size="big"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

StatementV3AddEditAdjustmentModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
  correction: StatementV3ListCorrectionModel.propTypes,
  currency: PropTypes.string,
};

StatementV3AddEditAdjustmentModal.defaultProps = {
  visible: false,
  correction: null,
  onClose: () => {},
  currency: null,
};

export default StatementV3AddEditAdjustmentModal;
