import { call } from 'redux-saga/effects';
import * as AnalyticsService from '@palette/services/AnalyticsService';
import { CLICK_ON_DOC_LINK_EVENT } from '@palette/constants/analytics';

export const shouldLogAnalytics = () => window
  && window.location
  && window.location.href.indexOf('localhost') === -1;

export function* sendClickOnDocLink(analyticsDocSource, params = {}) {
  if (shouldLogAnalytics()) {
    yield call(AnalyticsService.sendEvent, CLICK_ON_DOC_LINK_EVENT, { ...params, source: analyticsDocSource });
  }
}
