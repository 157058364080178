import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import ResetLine from '@palette/components/utils/Icons/ResetLine';
import Select from '@palette/components/designSystem/Select/Select';

import * as PayoutRulePaymentModel from '@palette/models/PayoutRulePayment';

import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';
import { PAYOUT_INTERVAL_TYPES } from '@palette/constants/masterPlans';

import styles from './RulePayoutItemForm.less';

const classNames = bindClassNames.bind(styles);

const RulePayoutItemForm = ({
  className,
  rulePayment,
  position,
  helperData,
  onRemove,
  onChange,
}) => {
  const { t } = useTranslation();

  const ruleIntervalTypes = Object.keys(PAYOUT_INTERVAL_TYPES).map((type) => ({
    label: t(`rulesPayoutItemForm.intervalType.${type}`),
    value: type,
  }));

  const initialValues = useMemo(() => ({
    ...rulePayment,
    percentageFormula: rulePayment?.percentageFormula || '1',
    repeatFormula: rulePayment?.repeatFormula || '1',
    startingAtFormula: rulePayment?.startingAtFormula || '',
    usePercentageAsAmount: rulePayment?.usePercentageAsAmount || false,
    intervalCount: rulePayment?.intervalCount || 1,
    intervalType: rulePayment?.intervalType || PAYOUT_INTERVAL_TYPES.month,
    intervalFormula: rulePayment?.intervalFormula || null,
  }), [rulePayment]);

  const [percentageFormula, setPercentageFormula] = useState(initialValues.percentageFormula);
  const [repeatFormula, setRepeatFormula] = useState(initialValues.repeatFormula);
  const [startingAtFormula, setStartingAtFormula] = useState(initialValues.startingAtFormula);
  const [intervalCount, setIntervalCount] = useState(initialValues.intervalCount);
  const [intervalType, setIntervalType] = useState(initialValues.intervalType);
  const [intervalFormula, setIntervalFormula] = useState(initialValues.intervalFormula);
  const [isIntervalFormula, setIsIntervalFormula] = useState(initialValues.intervalFormula !== '' && initialValues.intervalFormula !== null);

  const handleChange = useCallback(() => {
    let intervalValues = {
      intervalCount,
      intervalType,
    };

    if (repeatFormula !== '1' && isIntervalFormula) {
      intervalValues = {
        intervalFormula,
      };
    }

    const updatedPayment = {
      percentageFormula,
      repeatFormula,
      startingAtFormula,
      usePercentageAsAmount: initialValues.usePercentageAsAmount, // Not used
      ...intervalValues,
    };

    if (!_isEqual(initialValues, updatedPayment) || intervalFormula === null) {
      onChange(updatedPayment);
    }
  }, [
    initialValues,
    percentageFormula,
    repeatFormula,
    startingAtFormula,
    intervalCount,
    intervalType,
    intervalFormula,
    isIntervalFormula,
  ]);

  const handleIsIntervalFormula = useCallback((isIntervalFormulaBool) => {
    if (!isIntervalFormulaBool) {
      setIntervalFormula(null);
    } else {
      setIntervalFormula('');
    }

    setIsIntervalFormula(isIntervalFormulaBool);
  }, []);

  useEffect(() => {
    handleChange();
  }, [
    percentageFormula,
    repeatFormula,
    startingAtFormula,
    intervalCount,
    intervalType,
    intervalFormula,
    isIntervalFormula,
  ]);

  const deleteNode = useMemo(() => (
    <Button
      className={styles.actionDeleteLink}
      type="link"
      icon={<TrashFilled className={styles.actionIcon} />}
      onClick={onRemove}
    >
      {t('rulesPayoutItemForm.delete')}
    </Button>
  ), [onRemove]);

  const intervalNode = useMemo(() => (
    <>
      <div className={classNames({ row: true, noMarginBottom: true })}>
        <div className={styles.label}>&nbsp;</div>
        <div className={styles.field}>
          <Button
            className={styles.actionLink}
            type="link"
            icon={<ResetLine className={styles.actionIcon} />}
            onClick={() => handleIsIntervalFormula(!isIntervalFormula)}
          >
            {t('rulesPayoutItemForm.switchIntervalFormula.label')}
          </Button>
        </div>
      </div>
      {!isIntervalFormula && (
        <>
          <div className={styles.row}>
            <div className={styles.label}>{t('rulesPayoutItemForm.intervalCount.label')}</div>
            <div className={styles.field}>
              <Input
                className={styles.inputField}
                value={intervalCount}
                type="number"
                onChange={(value) => setIntervalCount(value)}
              />
              {
                intervalCount < 1 && (
                  <div className={styles.inputFieldError}>{t('rulesPayoutItemForm.intervalCount.error.required')}</div>
                )
              }
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.label}>{t('rulesPayoutItemForm.intervalType.label')}</div>
            <div className={styles.field}>
              <Select
                value={intervalType}
                onChange={(value) => setIntervalType(value)}
                options={ruleIntervalTypes}
              />
            </div>
          </div>
        </>
      )}
      {isIntervalFormula && (
        <div className={styles.row}>
          <div className={styles.label}>{t('rulesPayoutItemForm.intervalFormula.label')}</div>
          <div className={styles.field}>
            <FormulaInput
              className={styles.inputField}
              value={intervalFormula}
              type={FORMULA_INPUT_TYPES.FORMULA}
              helperData={helperData}
              onChange={(value) => setIntervalFormula(value)}
            />
            {
              intervalFormula === '' && (
                <div className={styles.inputFieldError}>{t('rulesPayoutItemForm.intervalFormula.error.required')}</div>
              )
            }
          </div>
        </div>
      )}
    </>
  ), [intervalCount, intervalType, intervalFormula, isIntervalFormula]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.header}>
        <div className={styles.paymentLabelWrapper}>
          <div className={styles.paymentLabel}>{t('rulesPayoutItemForm.title', { position })}</div>
        </div>
        {deleteNode}
      </div>
      <div className={styles.inputArea}>
        <div className={styles.row}>
          <div className={styles.label}>{t('rulesPayoutItemForm.percentage.label')}</div>
          <div className={styles.field}>
            <FormulaInput
              className={styles.inputField}
              value={percentageFormula}
              placeholder={t('rulesPayoutItemForm.percentage.placeholder')}
              type={FORMULA_INPUT_TYPES.FORMULA}
              helperData={helperData}
              onChange={(value) => setPercentageFormula(value)}
            />
            <div className={styles.disclaimer}>{t('rulesPayoutItemForm.percentage.disclaimer')}</div>
            {
              percentageFormula === '' && (
                <div className={styles.error}>{t('rulesPayoutItemForm.percentage.error.required')}</div>
              )
            }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{t('rulesPayoutItemForm.repeat.label')}</div>
          <div className={styles.field}>
            <FormulaInput
              className={styles.inputField}
              value={repeatFormula}
              placeholder={t('rulesPayoutItemForm.repeat.placeholder')}
              type={FORMULA_INPUT_TYPES.FORMULA}
              helperData={helperData}
              onChange={(value) => setRepeatFormula(value)}
            />
            <div className={styles.disclaimer}>{t('rulesPayoutItemForm.repeat.disclaimer')}</div>
            {
              repeatFormula === '' && (
                <div className={styles.error}>{t('rulesPayoutItemForm.repeat.error.required')}</div>
              )
            }
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{t('rulesPayoutItemForm.startingAt.label')}</div>
          <div className={styles.field}>
            <FormulaInput
              className={styles.inputField}
              value={startingAtFormula}
              placeholder={t('rulesPayoutItemForm.startingAt.placeholder')}
              type={FORMULA_INPUT_TYPES.FORMULA}
              helperData={helperData}
              onChange={(value) => setStartingAtFormula(value)}
            />
            <div className={styles.disclaimer}>{t('rulesPayoutItemForm.startingAt.disclaimer')}</div>
            {
              startingAtFormula === '' && (
                <div className={styles.error}>{t('rulesPayoutItemForm.startingAt.error.required')}</div>
              )
            }
          </div>
        </div>
        {repeatFormula !== '1' && intervalNode}
      </div>
    </div>
  );
};

RulePayoutItemForm.propTypes = {
  className: PropTypes.string,
  rulePayment: PayoutRulePaymentModel.propTypes,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  position: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

RulePayoutItemForm.defaultProps = {
  className: '',
  rulePayment: {},
  helperData: {},
  onRemove: () => {},
  onChange: () => {},
};

export default RulePayoutItemForm;
