import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import * as TeamModel from '@palette/models/Team';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './DeleteTeamModal.less';

const DeleteTeamModal = ({ visible, onClose, team, onDelete }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteTeamIsPending = useSelector(TeamsSelectors.deleteTeamIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = useCallback(() => {
    if (onDelete !== null) onDelete();

    cleanAndClose();
  }, [onDelete]);

  const handleDeleteTeam = () => {
    dispatch(TeamsActions.deleteTeam({ teamId: team.id, onSuccessCB }));
  };

  return (
    <Modal
      className={styles.modal}
      title={t('deleteTeamModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDeleteTeam}
      okText={t('deleteTeamModal.confirm')}
      loading={deleteTeamIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('deleteTeamModal.content', { teamName: team.name }) }} />
    </Modal>
  );
};

DeleteTeamModal.propTypes = {
  team: TeamModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

DeleteTeamModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onDelete: null,
};

export default DeleteTeamModal;
