import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

import Table from '@palette/components/designSystem/Table/Table';
import InputTableBodyCell from '@palette/components/designSystem/InputTableBodyCell/InputTableBodyCell';
import InputTableHeaderCell from '@palette/components/designSystem/InputTableHeaderCell/InputTableHeaderCell';
import InputTableRowIndexHeaderCell from '@palette/components/designSystem/InputTableRowIndexHeaderCell/InputTableRowIndexHeaderCell';
import InputTableAddNewColumnHeaderCell from '@palette/components/designSystem/InputTableAddNewColumnHeaderCell/InputTableAddNewColumnHeaderCell';

import {
  buildCellIndex,
  convertCellValue,
  extractCellColumnAndRowIndex,
} from '@palette/helpers/components/TableHelper';

import {
  INPUT_TABLE_ADD_NEW_ROW_LABEL,
  INPUT_TABLE_CELL_TYPES,
  INPUT_TABLE_DEFAULT_NB_OF_ROWS,
} from '@palette/constants/global';

import styles from './InputTable.less';

const classNames = bindClassNames.bind(styles);

const INPUT_TABLE_ROW_INDEX = 'inputTable_rowIndex';
const INPUT_TABLE_ADD_NEW_COLUMN = 'inputTable_addNewColumn';

const InputTable = ({
  className,
  columns,
  data,
  onDataUpdate,
  onColumnsUpdate,
  onAddNewRow,
  onAddMultipleRows,
  onDeleteRow,
  onAddNewColumn,
  onDeleteColumn,
  onUpdateColumn,
  onCellContentChange,
  firstColumnIsRowIndexColumn,
  disableAddColumnAction,
  disableColumnActions,
  disableAutoAddRows,
  ...otherProps
}) => {
  const [internalColumns, setInternalColumns] = useState(_cloneDeep(columns));

  const getEmptyData = useCallback(() => (
    columns.reduce((res, column) => {
      if (typeof column.accessor === 'function') return res;

      res[column.accessor] = '';

      return res;
    }, {})
  ), [internalColumns]);

  const [internalData, setInternalData] = useState(_cloneDeep(data));
  const [dataIsPristine, setDataIsPristine] = useState(true);

  useEffect(() => {
    setInternalColumns(_cloneDeep(columns));
  }, [columns]);

  useEffect(() => {
    let newInternalData = _cloneDeep(data);

    if (dataIsPristine && newInternalData.length === 0 && !disableAutoAddRows) {
      newInternalData = Array.from({ length: INPUT_TABLE_DEFAULT_NB_OF_ROWS }, () => getEmptyData());
      onAddMultipleRows(newInternalData);
    }

    setInternalData(newInternalData);
  }, [data]);

  useEffect(() => {
    if (!_isEqual(internalData, data)) {
      onDataUpdate?.(internalData);
    }
  }, [internalData]);

  useEffect(() => {
    if (!_isEqual(internalColumns, columns)) {
      onColumnsUpdate?.(internalColumns);
    }
  }, [internalColumns]);

  const handleAddNewRow = useCallback(() => {
    setInternalData((currentInternalData) => ([...currentInternalData, getEmptyData()]));
    setDataIsPristine(false);

    onAddNewRow?.(getEmptyData());
  }, [internalData, getEmptyData, onAddNewRow]);

  const handleDeleteRow = useCallback((rowIndex) => {
    setInternalData((currentInternalData) => {
      const newInternalData = [...currentInternalData];
      newInternalData.splice(rowIndex, 1);
      return newInternalData;
    });
    setDataIsPristine(false);

    onDeleteRow?.(rowIndex);
  }, [onDeleteRow]);

  const handleCellContentChange = useCallback((cellIndex, newContent) => {
    const [cellColumnId, cellRowIndex] = extractCellColumnAndRowIndex(cellIndex);

    setInternalData((currentInternalData) => {
      const newInternalData = [...currentInternalData];

      const column = internalColumns.find((col) => (col.id === cellColumnId));

      const newCellContent = newInternalData[cellRowIndex];
      newCellContent[column.accessor] = newContent;

      return newInternalData;
    });
    setDataIsPristine(false);

    onCellContentChange?.(cellColumnId, cellRowIndex, newContent);
  }, [internalColumns, onCellContentChange]);

  const handleAddNewColumn = useCallback((newColumnName) => {
    const newColumn = {
      id: newColumnName,
      Header: newColumnName,
      accessor: newColumnName,
      minWidth: 150,
      cellType: INPUT_TABLE_CELL_TYPES.STRING,
    };
    setInternalColumns((currentInternalColumns) => ([...currentInternalColumns, newColumn]));
    setInternalData((currentInternalData) => currentInternalData.map((dataItem) => ({
      ...dataItem,
      [newColumnName]: '',
    })));
    setDataIsPristine(false);

    onAddNewColumn?.(newColumnName, INPUT_TABLE_CELL_TYPES.STRING);
  }, [onAddNewColumn]);

  const handleDeleteColumn = useCallback((columnId) => {
    const deletedColumnIndex = internalColumns.findIndex((col) => (col.id === columnId));
    const deletedColumn = internalColumns[deletedColumnIndex];

    setInternalColumns((currentInternalColumns) => {
      const newInternalColumns = [...currentInternalColumns];
      newInternalColumns.splice(deletedColumnIndex, 1);
      return newInternalColumns;
    });
    setInternalData((currentInternalData) => (
      currentInternalData.map((dataItem) => {
        const updatedDataItem = { ...dataItem };
        delete updatedDataItem[deletedColumn.accessor];
        return updatedDataItem;
      })
    ));
    setDataIsPristine(false);

    onDeleteColumn?.(columnId);
  }, [internalColumns, onDeleteColumn]);

  const handleEditColumn = useCallback((columnId, newColumnName) => {
    const updatedColumnIndex = internalColumns.findIndex((col) => (col.id === columnId));
    const updatedColumn = internalColumns[updatedColumnIndex];

    setInternalColumns((currentInternalColumns) => {
      const newInternalColumns = [...currentInternalColumns];
      newInternalColumns.splice(updatedColumnIndex, 1, {
        ...updatedColumn,
        id: newColumnName,
        Header: newColumnName,
        accessor: newColumnName,
      });
      return newInternalColumns;
    });
    setInternalData((currentInternalData) => (
      currentInternalData.map((dataItem) => {
        const updatedDataItem = { ...dataItem };
        const columnDataItemValue = updatedDataItem[updatedColumn.accessor];
        delete updatedDataItem[updatedColumn.accessor];
        updatedDataItem[newColumnName] = columnDataItemValue;
        return updatedDataItem;
      })
    ));
    setDataIsPristine(false);

    onUpdateColumn?.(columnId, newColumnName, updatedColumn.cellType);
  }, [internalColumns, onUpdateColumn]);

  const handleColumnTypeChange = useCallback((columnId, newColumnType) => {
    const updatedColumnIndex = internalColumns.findIndex((col) => (col.id === columnId));
    const updatedColumn = internalColumns[updatedColumnIndex];

    setInternalColumns((currentInternalColumns) => {
      const newInternalColumns = [...currentInternalColumns];
      newInternalColumns.splice(updatedColumnIndex, 1, {
        ...updatedColumn,
        cellType: newColumnType,
      });
      return newInternalColumns;
    });
    setInternalData((currentInternalData) => (
      currentInternalData.map((dataItem) => {
        const updatedDataItem = { ...dataItem };
        const columnDataItemValue = updatedDataItem[updatedColumn.accessor];
        delete updatedDataItem[updatedColumn.accessor];
        updatedDataItem[updatedColumn.accessor] = convertCellValue(columnDataItemValue, updatedColumn.cellType, newColumnType);
        return updatedDataItem;
      })
    ));
    setDataIsPristine(false);

    onUpdateColumn?.(columnId, updatedColumn.Header, newColumnType);
  }, [internalColumns, onUpdateColumn]);

  const finalColumns = useMemo(() => {
    const allColumns = internalColumns.map((column, columnIndex) => {
      if (firstColumnIsRowIndexColumn && columnIndex === 0) {
        return {
          ...column,
          Header: (<InputTableHeaderCell columnId={INPUT_TABLE_ROW_INDEX} disableActions />),
          // eslint-disable-next-line react/prop-types
          Cell: ({ value }) => (
            <InputTableRowIndexHeaderCell
              value={value}
              rowIndex={value}
              disableActions
            />
          ),
          disableBodyCellComponent: true,
          disableHeaderCellComponent: true,
        };
      }

      return {
        ...column,
        Header: (
          <InputTableHeaderCell
            value={column.Header ?? null}
            columnId={column.id}
            disableActions={disableColumnActions}
            columns={internalColumns}
            onDeleteColumn={handleDeleteColumn}
            onEditColumn={handleEditColumn}
            onColumnTypeChange={handleColumnTypeChange}
          />),
        /* eslint-disable react/prop-types */
        Cell: ({ value, cell, row }) => (
          <InputTableBodyCell
            value={value}
            cellIndex={buildCellIndex(cell)}
            cellType={column.cellType}
            onChange={handleCellContentChange}
            isAddNewRow={row.original[INPUT_TABLE_ROW_INDEX] === INPUT_TABLE_ADD_NEW_ROW_LABEL}
          />
        ),
        /* eslint-enable react/prop-types */
        disableBodyCellComponent: true,
        disableHeaderCellComponent: true,
      };
    });

    if (!firstColumnIsRowIndexColumn) {
      const rowIndexColumn = {
        id: INPUT_TABLE_ROW_INDEX,
        accessor: INPUT_TABLE_ROW_INDEX,
        minWidth: 58,
        width: 58,
        maxWidth: 58,
        Header: (<InputTableHeaderCell columnId={INPUT_TABLE_ROW_INDEX} disableActions />),
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <InputTableRowIndexHeaderCell
            value={value}
            rowIndex={value}
            onAddNewRow={handleAddNewRow}
            onDeleteRow={handleDeleteRow}
          />
        ),
        disableBodyCellComponent: true,
        disableHeaderCellComponent: true,
      };

      allColumns.unshift(rowIndexColumn);
    }

    if (!disableAddColumnAction) {
      const addNewColumnActionColumn = {
        id: INPUT_TABLE_ADD_NEW_COLUMN,
        accessor: INPUT_TABLE_ADD_NEW_COLUMN,
        minWidth: 58,
        width: 58,
        maxWidth: 58,
        Header: (<InputTableAddNewColumnHeaderCell onAddNewColumn={handleAddNewColumn} columns={internalColumns} />),
        Cell: () => (<div className={styles.emptyCell} />),
        disableBodyCellComponent: true,
        disableHeaderCellComponent: true,
      };

      allColumns.push(addNewColumnActionColumn);
    }

    return allColumns;
  }, [
    internalColumns,
    firstColumnIsRowIndexColumn,
    disableAddColumnAction,
    disableColumnActions,
    handleCellContentChange,
    handleAddNewRow,
    handleDeleteRow,
    handleAddNewColumn,
    handleDeleteColumn,
    handleEditColumn,
    handleColumnTypeChange,
  ]);

  const finalData = useMemo(() => {
    let allData = [].concat(internalData);

    allData = allData.map((dataItem, dataItemIndex) => ({
      [INPUT_TABLE_ROW_INDEX]: dataItemIndex.toString(),
      ...dataItem,
    }));

    if (!firstColumnIsRowIndexColumn) {
      allData.push(({
        [INPUT_TABLE_ROW_INDEX]: INPUT_TABLE_ADD_NEW_ROW_LABEL,
        ...getEmptyData(),
      }));
    }

    return allData;
  }, [internalData, firstColumnIsRowIndexColumn, getEmptyData]);

  return (
    <Table
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      columns={finalColumns}
      data={finalData}
      {...otherProps}
    />
  );
};

InputTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDataUpdate: PropTypes.func,
  onColumnsUpdate: PropTypes.func,
  onAddNewRow: PropTypes.func,
  onAddMultipleRows: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onAddNewColumn: PropTypes.func,
  onDeleteColumn: PropTypes.func,
  onUpdateColumn: PropTypes.func,
  onCellContentChange: PropTypes.func,
  firstColumnIsRowIndexColumn: PropTypes.bool,
  disableAddColumnAction: PropTypes.bool,
  disableColumnActions: PropTypes.bool,
  disableAutoAddRows: PropTypes.bool,
};

InputTable.defaultProps = {
  className: '',
  onDataUpdate: null,
  onColumnsUpdate: null,
  onAddNewRow: null,
  onAddMultipleRows: null,
  onDeleteRow: null,
  onAddNewColumn: null,
  onDeleteColumn: null,
  onUpdateColumn: null,
  onCellContentChange: null,
  firstColumnIsRowIndexColumn: false,
  disableAddColumnAction: false,
  disableColumnActions: false,
  disableAutoAddRows: false,
};

export default InputTable;
