import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import {
  DEFAULT_CONDITION_DISPLAYED_CODE,
  DEFAULT_CONDITION_DISPLAYED_FIELD,
  DEFAULT_CONDITION_VALUE_KEY,
  DEFAULT_CONDITION_VALUE_OPERATOR, OPERATOR_TO_STRING,
} from '@palette/constants/queryBuilder';

import styles from './QueryBuilderDisplayedAndOrCondition.less';

const classNames = bindClassNames.bind(styles);

const QueryBuilderDisplayedAndOrCondition = ({
  className,
  value,
  prefix,
}) => {
  const fieldNode = useMemo(() => {
    const firstKey = Object.keys(value)[0];

    let fieldValue = firstKey || DEFAULT_CONDITION_VALUE_KEY;
    fieldValue = fieldValue.replace(prefix, '');

    let fieldValueNode = fieldValue;
    if (fieldValue === '' || fieldValue === DEFAULT_CONDITION_VALUE_KEY) {
      fieldValueNode = DEFAULT_CONDITION_DISPLAYED_FIELD;
    }

    return (
      <div className={styles.field}>
        {fieldValueNode}
      </div>
    );
  }, [value]);

  const operatorNode = useMemo(() => {
    const field = Object.keys(value)[0];
    const valObj = value[field];
    const firstKey = Object.keys(valObj)[0];

    const operatorValue = firstKey || DEFAULT_CONDITION_VALUE_OPERATOR;
    const operatorValueNode = OPERATOR_TO_STRING[operatorValue];

    return (
      <div className={styles.operator}>
        {operatorValueNode}
      </div>
    );
  }, [value]);

  const codeNode = useMemo(() => {
    const field = Object.keys(value)[0];
    const valObj = value[field];
    const firstKey = Object.keys(valObj)[0];
    const codeValue = valObj[firstKey];

    let codeValueNode = codeValue;
    if (codeValue === '') {
      codeValueNode = DEFAULT_CONDITION_DISPLAYED_CODE;
    }

    return (
      <div className={styles.code}>
        {codeValueNode}
      </div>
    );
  }, [value]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {fieldNode}
      {operatorNode}
      {codeNode}
    </div>
  );
};

QueryBuilderDisplayedAndOrCondition.propTypes = {
  className: PropTypes.string,
  value: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

QueryBuilderDisplayedAndOrCondition.defaultProps = {
  className: '',
  prefix: '',
};

export default QueryBuilderDisplayedAndOrCondition;
