import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import StatementPeriodsListItem from '@palette/components/statement/statementPeriod/StatementPeriodsListItem/StatementPeriodsListItem';

import { useStatementPeriods } from '@palette/hooks/StatementHooks';

import styles from './StatementPeriodsList.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodsList = ({ className, currency }) => {
  const { t } = useTranslation();

  const { listStatementPeriodsIsPending, statementPeriods } = useStatementPeriods(currency);

  const contentNode = useMemo(() => {
    if (listStatementPeriodsIsPending) return <Loader />;

    if (statementPeriods.length === 0) {
      return (
        <DefaultEmptyState description={t('statementPeriodsList.noPeriods')} />
      );
    }

    return statementPeriods.map((statementPeriod) => (
      <StatementPeriodsListItem key={statementPeriod.id} className={styles.period} statementPeriod={statementPeriod} currency={currency} />
    ));
  }, [listStatementPeriodsIsPending, statementPeriods, currency]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementPeriodsList.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
};

StatementPeriodsList.defaultProps = {
  className: '',
  currency: null,
};

export default StatementPeriodsList;
