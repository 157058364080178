import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';

import { actions as ProfileActions } from '@palette/state/Profile';

import styles from './ConnectAsPageContent.less';

const classNames = bindClassNames.bind(styles);

const ConnectAsPageContent = ({ className }) => {
  const dispatch = useDispatch();

  const { sessionId } = useParams();

  useEffect(() => {
    dispatch(ProfileActions.connectAs({ sessionId }));
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Loader />
    </div>
  );
};

ConnectAsPageContent.propTypes = {
  className: PropTypes.string,
};

ConnectAsPageContent.defaultProps = {
  className: '',
};

export default ConnectAsPageContent;
