import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import TextArea from '@palette/components/designSystem/TextArea/TextArea';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './GenerateWithAIModal.less';

const GenerateWithAIModal = ({ visible, onClose, planId, codeCellId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const generateWithAIIsPending = useSelector(PlanV3Selectors.getGenerateWithAIIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    prompt: undefined,
  }), []);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const handleFinish = useCallback(({ prompt }) => {
    dispatch(PlanV3Actions.generateWithAI({ planId, codeCellId, prompt, onSuccessCB: cleanAndClose }));
  }, [planId, codeCellId, cleanAndClose]);

  const handleGenerateWithAI = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('generateWithAIModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleGenerateWithAI}
      okText={t('generateWithAIModal.generate')}
      loading={generateWithAIIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="prompt"
          label={t('generateWithAIModal.form.prompt.label')}
          required
          rules={[
            { required: true, message: t('generateWithAIModal.form.prompt.rules.required') },
          ]}
        >
          <TextArea disabled={generateWithAIIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

GenerateWithAIModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  planId: PropTypes.string.isRequired,
  codeCellId: PropTypes.string.isRequired,
};

GenerateWithAIModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default GenerateWithAIModal;
