import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import StatementPeriodCardItem from '@palette/components/statement/statementPeriod/StatementPeriodCardItem/StatementPeriodCardItem';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import routePaths from '@palette/config/routePaths';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './StatementPeriodsCardsListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodsCardsListItem = ({ className, statementPeriod, isSelected, inUserStatement, userId }) => {
  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  return (
    <Link
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      path={inUserStatement ? routePaths.v2.statementsUserStatement : routePaths.v2.statementsPeriod}
      params={{ statementPeriodId: statementPeriod.id, userId }}
      qsObject={periodsDatesFilterQSObject}
      disabled={isSelected}
    >
      <StatementPeriodCardItem statementPeriod={statementPeriod} isSelected={isSelected} inUserStatement={inUserStatement} />
    </Link>
  );
};

StatementPeriodsCardsListItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  isSelected: PropTypes.bool,
  inUserStatement: PropTypes.bool,
  userId: PropTypes.string,
};

StatementPeriodsCardsListItem.defaultProps = {
  className: '',
  isSelected: false,
  inUserStatement: false,
  userId: null,
};

export default StatementPeriodsCardsListItem;
