import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';

import { TIME_OFFSETS, TIMEZONE_NAMES } from '@palette/constants/timezone';

import styles from './TimezoneSelect.less';

const classNames = bindClassNames.bind(styles);

const TimezoneSelect = ({ className, ...otherProps }) => {
  const { t } = useTranslation();

  const timezoneOptions = useMemo(() => {
    const timezonesOptions = TIMEZONE_NAMES.map((timezoneName) => ({
      label: timezoneName,
      value: timezoneName,
    }));

    const timeOffsetsOptions = Object.keys(TIME_OFFSETS).map((timeOffset) => ({
      label: t('timezoneSelect.options.timeOffsetLabel', {
        timeOffsetLabel: TIME_OFFSETS[timeOffset].label,
      }),
      value: timeOffset,
    }));

    return {
      [t('timezoneSelect.timezones')]: timezonesOptions,
      [t('timezoneSelect.timeOffsets')]: timeOffsetsOptions,
    };
  }, []);

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={timezoneOptions}
      showSearch
      {...otherProps}
    />
  );
};

TimezoneSelect.propTypes = {
  className: PropTypes.string,
};

TimezoneSelect.defaultProps = {
  className: '',
};

export default TimezoneSelect;
