import Axios from 'axios';
import { API_URL } from '../constants';
import { getSessionId } from '../utils';

export const listObjects = (params) => Axios.post(`${API_URL}/users/admin/objects/list`, params, {
  headers: {
    sessionId: getSessionId(),
  },
});

export default {};
