import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LeftMainContentLayout from '@palette/components/designSystem/LeftMainContentLayout/LeftMainContentLayout';
import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import ResourcesTabsContent from '@palette/components/resources/ResourcesTabsContent/ResourcesTabsContent';
import ResourcesContentDetails from '@palette/components/resources/ResourcesContentDetails/ResourcesContentDetails';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';
import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import { RESOURCES_PAGE_CONTENT_QS_KEY, RESOURCES_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './ResourcesPageContent.less';

const classNames = bindClassNames.bind(styles);

const ResourcesPageContent = ({ className }) => {
  const { t } = useTranslation();
  const { connectorId, type } = useParams();

  const [locationTab] = useKeyInSearch(RESOURCES_PAGE_CONTENT_QS_KEY);

  const [currentTab, setCurrentTab] = useState(locationTab || RESOURCES_PAGE_CONTENT_TABS_IDS.CONNECTORS);

  const connectorsList = useConnectorsList();
  const nbOfConnectors = useSelector(ConnectorsSelectors.getNbOfConnectors);
  const nbOfNotebooks = useSelector(ConnectorsSelectors.getNbOfNotebooks);

  const handleTabChange = (key) => setCurrentTab(Object.values(RESOURCES_PAGE_CONTENT_TABS_IDS)[key]);

  const getTabPropsByType = useCallback((tabType) => {
    const isConnector = tabType === RESOURCES_PAGE_CONTENT_TABS_IDS.CONNECTORS;

    return ({
      title: t(`resources.index.header.tabs.${tabType}`),
      titleCount: isConnector ? nbOfConnectors ?? 0 : nbOfNotebooks ?? 0,
      content: null,
      defaultActive: isConnector,
      tabKey: tabType,
    });
  }, [nbOfConnectors, nbOfNotebooks]);

  const tabs = useMemo(() => [
    RESOURCES_PAGE_CONTENT_TABS_IDS.CONNECTORS,
    RESOURCES_PAGE_CONTENT_TABS_IDS.NOTEBOOKS,
  ].map((tabType) => getTabPropsByType(tabType)), [
    getTabPropsByType,
  ]);

  useEffect(() => {
    if (!currentTab && !locationTab) {
      setCurrentTab(RESOURCES_PAGE_CONTENT_TABS_IDS.CONNECTORS);
    } else if (locationTab && (locationTab !== currentTab)) {
      setCurrentTab(locationTab);
    }
  }, [currentTab, locationTab]);

  useEffect(() => {
    if (connectorsList.length > 0 && !connectorId && !type) {
      const connectorWithResources = connectorsList.find((connector) => connector.resources.length >= 1);

      if (connectorWithResources) {
        redirectTo({
          path: routePaths.v2.resourcesByConnectorId,
          params: {
            connectorId: connectorWithResources.id,
            type: connectorWithResources.resources[0].type,
          },
        });
      }
    }
  }, []);

  const handleResourceClick = useCallback((connectorResourceId, connectorResourceType) => {
    redirectTo({
      path: routePaths.v2.resourcesByConnectorId,
      params: {
        connectorId: connectorResourceId,
        type: connectorResourceType,
      },
      ...(locationTab || currentTab !== RESOURCES_PAGE_CONTENT_TABS_IDS.CONNECTORS) && {
        qsObject: { [RESOURCES_PAGE_CONTENT_QS_KEY]: currentTab },
      },
    });
  }, [locationTab, currentTab]);

  const headerNode = useMemo(() => (
    <div className={styles.header}>
      <div className={styles.title}>
        {t('resources.index.header.title')}
      </div>
      <Tabs
        className={styles.tabs}
        tabs={tabs}
        onChange={handleTabChange}
        qsTabKey={RESOURCES_PAGE_CONTENT_QS_KEY}
      />
    </div>
  ), [tabs, handleTabChange]);

  const leftContentNode = useMemo(() => (
    <ResourcesTabsContent
      currentConnectorId={connectorId}
      currentOriginalType={type}
      handleClick={handleResourceClick}
      currentTab={currentTab}
    />
  ), [connectorId, type, handleResourceClick, currentTab]);

  const mainContentNode = useMemo(() => {
    if (!connectorId && !type) return null;

    return (
      <ResourcesContentDetails
        currentConnectorId={connectorId}
        currentOriginalType={type}
      />
    );
  }, [connectorId, type]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <LeftMainContentLayout
        leftSideHeader={headerNode}
        leftSideContent={leftContentNode}
        mainContent={mainContentNode}
        borderless
      />
    </div>
  );
};

ResourcesPageContent.propTypes = {
  className: PropTypes.string,
};

ResourcesPageContent.defaultProps = {
  className: '',
};

export default ResourcesPageContent;
