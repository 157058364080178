import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Table from '@palette/components/designSystem/Table/Table';
import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { computeColumnWidthFromContentLength } from '@palette/helpers/components/TableHelper';

import * as NotebookDataframeModel from '@palette/models/NotebookDataframe';

import styles from './NotebookDataframePreview.less';

const classNames = bindClassNames.bind(styles);

const FORCE_HEIGHT_ROWS_NB = 5;

const NotebookDataframePreview = ({ className, isLoading, dataframe }) => {
  const tableColumns = useMemo(() => (
    (dataframe?.headers ?? []).map((header, index) => ({
      id: `column_${index}_${header.label}`,
      Header: (
        <div className={styles.previewHeader}>
          {header.label}
        </div>
      ),
      accessor: header.label,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (
        <div className={styles.previewCell}>
          {value}
        </div>
      ),
      minWidth: computeColumnWidthFromContentLength(header.maxLength),
      maxWidth: computeColumnWidthFromContentLength(header.maxLength),
      width: 'auto',
      disableHeaderCellComponent: true,
      disableBodyCellComponent: true,
    }))
  ), [dataframe]);

  const tableNode = useMemo(() => {
    if (dataframe === null) return null;

    if (dataframe.data.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <Table
        type="borderless"
        columns={tableColumns}
        data={dataframe.data ?? []}
        stickyHeader
        fitInContainer
      />
    );
  }, [
    dataframe,
    tableColumns,
  ]);

  if (isLoading) {
    return (
      <Loader
        className={classNames({
          loader: true,
          [className]: className !== '',
        })}
        spinning={isLoading}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        forceHeight: (dataframe?.data ?? []).length > FORCE_HEIGHT_ROWS_NB,
        [className]: className !== '',
      })}
    >
      {tableNode}
    </div>
  );
};

NotebookDataframePreview.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  dataframe: NotebookDataframeModel.propTypes,
};

NotebookDataframePreview.defaultProps = {
  className: '',
  dataframe: null,
};

export default NotebookDataframePreview;
