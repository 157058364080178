import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import Button from '@palette/components/designSystem/Button/Button';
import RulePayoutItemForm from '@palette/components/rule/RulePayoutItemForm/RulePayoutItemForm';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import * as PayoutRulePaymentModel from '@palette/models/PayoutRulePayment';

import styles from './RulePayoutForm.less';

const classNames = bindClassNames.bind(styles);

const RulePayoutForm = ({
  className,
  payments,
  helperData,
  onChange,
  ruleId,
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => ({
    payments: payments || [],
  }), [payments]);

  const [statePayments, setStatePayments] = useState(initialValues.payments);
  const [reconciliationIndex, setReconciliationIndex] = useState(0);

  const handleChange = useCallback(() => {
    if (!_isEqual(initialValues.payments, statePayments)) {
      onChange(statePayments);
    }
  }, [initialValues, statePayments]);

  useEffect(() => {
    handleChange();
  }, [statePayments]);

  const handleAddingAPayment = useCallback(() => {
    setStatePayments([...statePayments, PayoutRulePaymentModel.transform({})]);
  }, [statePayments]);

  const handleRemovePayment = useCallback((index) => {
    const newPayments = [...statePayments];

    newPayments.splice(index, 1);

    setReconciliationIndex(reconciliationIndex + 1);
    setStatePayments(newPayments);
  }, [statePayments]);

  const handlePaymentChange = useCallback((updatedPayment, index) => {
    const newPayments = [...statePayments];

    newPayments.splice(index, 1, updatedPayment);

    setStatePayments(newPayments);
  }, [statePayments]);

  const statePaymentsNode = useMemo(() => {
    if (statePayments.length === 0) {
      // eslint-disable-next-line react/no-danger
      return <p className={styles.noPaymentsText} dangerouslySetInnerHTML={{ __html: t('masterPlanSettingsPayoutRules.form.noPaymentsText') }} />;
    }

    return statePayments.map((payment, index) => (
      <RulePayoutItemForm
        className={styles.container}
        rulePayment={payment}
        helperData={helperData}
        key={`rule-${ruleId}-payout-${index}-${reconciliationIndex}`}
        onChange={(updatedPayment) => handlePaymentChange(updatedPayment, index)}
        onRemove={() => handleRemovePayment(index)}
        position={index + 1}
      />
    ));
  }, [
    statePayments,
    helperData,
    ruleId,
    handlePaymentChange,
    handleRemovePayment,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {statePaymentsNode}
      <Button icon={(<AddFilled />)} onClick={handleAddingAPayment}>
        {t('masterPlanSettingsPayoutRules.form.addAPaymentRule')}
      </Button>
    </div>
  );
};

RulePayoutForm.propTypes = {
  className: PropTypes.string,
  payments: PropTypes.arrayOf(PayoutRulePaymentModel.propTypes),
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  onChange: PropTypes.func,
  ruleId: PropTypes.string,
};

RulePayoutForm.defaultProps = {
  className: '',
  payments: [],
  helperData: {},
  onChange: () => {},
  ruleId: '',
};

export default RulePayoutForm;
