import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { NOTEBOOK_STATUSES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import { selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './EditableNotebookName.less';

const classNames = bindClassNames.bind(styles);

const EditableNotebookName = ({ className, notebook, updateName }) => {
  const profile = useProfile();

  const inputRef = useRef(null);

  const [editing, setEditing] = useState(false);
  const [notebookName, setNotebookName] = useState(notebook.name);

  useEffect(() => {
    setNotebookName(notebook.name);
  }, [notebook.name]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  useEffect(() => {
    if (!editNotebookIsPending) {
      setEditing(false);
    }
  }, [editNotebookIsPending]);

  const saveUpdate = useCallback(() => {
    if (notebook.name !== notebookName && notebookName.trim() !== '') {
      updateName(notebookName.trim());
    }

    setEditing(false);
  }, [notebook, notebookName, updateName]);

  const handleInputChange = useCallback((newValue) => {
    setNotebookName(newValue);
  }, []);

  const switchToEditMode = useCallback(() => {
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE]) && notebook.status !== NOTEBOOK_STATUSES.ARCHIVED) {
      setEditing(true);
    }
  }, [profile, notebook]);

  return useMemo(() => {
    if (editing) {
      return (
        <Input
          ref={inputRef}
          className={styles.input}
          type="text"
          value={notebookName}
          onChange={handleInputChange}
          onBlur={saveUpdate}
          onPressEnter={saveUpdate}
        />
      );
    }

    return (
      <h1
        className={classNames({
          wrapper: true,
          disabled: notebook.status === NOTEBOOK_STATUSES.ARCHIVED,
          [className]: className !== '',
        })}
        onClick={switchToEditMode}
      >
        {notebook.name}
      </h1>
    );
  }, [
    editing,
    inputRef,
    notebookName,
    handleInputChange,
    saveUpdate,
    notebook,
    switchToEditMode,
  ]);
};

EditableNotebookName.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  updateName: PropTypes.func.isRequired,
};

EditableNotebookName.defaultProps = {
  className: '',
};

export default EditableNotebookName;
