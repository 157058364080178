import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { LottieAnimationRefForwarded as LottieAnimation } from '@palette/components/designSystem/LottieAnimation/LottieAnimation';

import { LOTTIES } from '@palette/constants/lotties';

import styles from './ClappingHandsLottieAnimation.less';

const classNames = bindClassNames.bind(styles);

const LOTTIE_DURATION = 483; // ms

const ClappingHandsLottieAnimation = ({ className, onClick }) => {
  const lottieRef = useRef();

  const [isAnimating, setIsAnimating] = useState(false);

  const stopAnimation = useCallback(() => {
    lottieRef?.current.goToAndStop(10, true);
    setIsAnimating(false);
  }, [lottieRef]);

  const handleAnimationClick = useCallback(() => {
    if (onClick !== null) {
      onClick(!isAnimating);
    }
    setIsAnimating(!isAnimating);
  }, [isAnimating, onClick]);

  useEffect(() => {
    let timeout;

    if (isAnimating) {
      lottieRef?.current.play();
      timeout = setTimeout(() => stopAnimation(), LOTTIE_DURATION * 3); // 3 claps
    } else {
      stopAnimation();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isAnimating, lottieRef]);

  return (
    <LottieAnimation
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      ref={lottieRef}
      lottieId={LOTTIES.CLAPPING_HANDS}
      onClick={handleAnimationClick}
      autoplay={false}
    />
  );
};

ClappingHandsLottieAnimation.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ClappingHandsLottieAnimation.defaultProps = {
  className: '',
  onClick: null,
};

export default ClappingHandsLottieAnimation;
