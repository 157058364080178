import { call, put, all, takeLatest } from 'redux-saga/effects';

import { actions as StatementsHistoryActions } from '@palette/state/StatementsHistory/slice';
import { manageError as manageStatementsHistoryError } from '@palette/state/StatementsHistory/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';

import * as StatementsHistoryService from '@palette/services/StatementsHistoryService';

import * as StatementHistoryModel from '@palette/models/StatementHistory';
import * as PaginationModel from '@palette/models/Pagination';

export function* listByFilters({ payload = {} }) {
  const {
    statementId,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
  } = payload;

  const callData = {
    statementId,
    page,
    limit,
  };

  const callResult = yield call(StatementsHistoryService.statementsHistoryListByFilters, callData);

  if (callResult.ok) {
    const historyChanges = StatementHistoryModel.transformList(callResult.data.data);
    const pagination = PaginationModel.transform(callResult.data.pagination);

    yield put(StatementsHistoryActions.setListByFilters({ historyChanges, pagination, callData }));
  } else {
    const error = manageStatementsHistoryError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementsHistoryActions.listByFiltersCompleted());
}

export function* loop() {
  yield all([
    takeLatest(StatementsHistoryActions.listByFilters.type, listByFilters),
  ]);
}

export function* clean() {
  yield put(StatementsHistoryActions.resetToInitialState());
}
