import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import AddDataConnectionSelectDataConnectorStep from '@palette/components/notebooks/AddDataConnectionSelectDataConnectorStep/AddDataConnectionSelectDataConnectorStep';

import { useNewDataConnectionInfo } from '@palette/hooks/NotebookHooks';

import { NOTEBOOK_DATA_CONNECTION_TYPES } from '@palette/constants/notebooks';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './EditDataConnectionConnectorModal.less';

const EditDataConnectionConnectorModal = ({ visible, onClose, notebook, dataConnectionConnector }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  const newDataConnectionSource = useSelector(NotebooksSelectors.getNewDataConnectionSource);

  const { disableAddEditDataConnectionAction, addEditDataConnectionAction } = useNewDataConnectionInfo();

  useEffect(() => {
    dispatch(NotebooksActions.setNewDataConnectionSource({ type: NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR, params: { connectorId: dataConnectionConnector.connectorId } }));
  }, [dataConnectionConnector]);

  const cleanAndClose = useCallback(() => {
    dispatch(NotebooksActions.resetNewDataConnectionInfo());
    onClose();
  }, []);

  const handleEditDataConnectionConnector = useCallback(() => {
    if (addEditDataConnectionAction !== null) addEditDataConnectionAction(notebook, cleanAndClose);
  }, [notebook, addEditDataConnectionAction, cleanAndClose]);

  if (newDataConnectionSource === null) return null;

  return (
    <Modal
      className={styles.modal}
      title={t('editDataConnectionConnectorModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleEditDataConnectionConnector}
      okText={t('editDataConnectionConnectorModal.update')}
      disableOk={disableAddEditDataConnectionAction}
      loading={editNotebookIsPending}
      blockHeight
    >
      <AddDataConnectionSelectDataConnectorStep editedDataConnectionConnector={dataConnectionConnector} />
    </Modal>
  );
};

EditDataConnectionConnectorModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  notebook: NotebookModel.propTypes.isRequired,
  dataConnectionConnector: NotebookDataConnectionConnectorModel.propTypes.isRequired,
};

EditDataConnectionConnectorModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default EditDataConnectionConnectorModal;
