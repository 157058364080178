export const COMMISSION_DRAWER_COMMISION_ID_QS_KEY = 'commissionId';

export const SEARCHED_RESOURCE_QS_KEY = 'searchedResource';

export const SEARCHED_STATUS_QS_KEY = 'searchedStatus';

export const SEARCHED_FROM_QS_KEY = 'searchedFrom';

export const SEARCHED_TO_QS_KEY = 'searchedTo';

export const SEARCHED_USER_QS_KEY = 'searchedUser';

export const SEARCHED_TEAM_QS_KEY = 'searchedTeam';

export const SEARCHED_RESOURCE_TYPE_QS_KEY = 'searchedResourceType';

export const SEARCHED_PLAN_QS_KEY = 'searchedPlan';

export const SORT_BY_QS_KEY = 'sortBy';

export const CURRENCY_QS_KEY = 'currency';

export const COMMISSION_PAYMENT_PERIOD_QS_KEY = 'period';

export const COMMISSION_PAYMENT_YEAR_QS_KEY = 'year';

export const STATUSES = {
  CONCLUDED: 'CONCLUDED',
  PARTIALLY_RELEASED: 'PARTIALLY_RELEASED',
  ON_HOLD: 'ON_HOLD',
};

export const STATUSES_TAG = {
  CONCLUDED: 'success',
  PARTIALLY_RELEASED: 'warning',
  ON_HOLD: 'danger',
};

export const STATUSES_OPTIONS_KEYS = {
  CONCLUDED: 'CONCLUDED',
  PARTIALLY_RELEASED: 'PARTIALLY_RELEASED',
  ON_HOLD: 'ON_HOLD',
  PARTIALLY_RELEASED_AND_ON_HOLD: 'PARTIALLY_RELEASED_AND_ON_HOLD',
};

export const STATUSES_OPTIONS = {
  [STATUSES_OPTIONS_KEYS.CONCLUDED]: [STATUSES.CONCLUDED],
  [STATUSES_OPTIONS_KEYS.PARTIALLY_RELEASED]: [STATUSES.PARTIALLY_RELEASED],
  [STATUSES_OPTIONS_KEYS.ON_HOLD]: [STATUSES.ON_HOLD],
  [STATUSES_OPTIONS_KEYS.PARTIALLY_RELEASED_AND_ON_HOLD]: [STATUSES.PARTIALLY_RELEASED, STATUSES.ON_HOLD],
};

export const SORTBY_OPTIONS_KEYS = {
  DATE_ASC: 'DATE_ASC',
  DATE_DESC: 'DATE_DESC',
  UNPAID_PERCENTAGE: 'UNPAID_PERCENTAGE',
};

export const SORTBY_OPTIONS = {
  [SORTBY_OPTIONS_KEYS.DATE_ASC]: { direction: 'ASC', fields: ['date'] },
  [SORTBY_OPTIONS_KEYS.DATE_DESC]: { direction: 'DESC', fields: ['date'] },
  [SORTBY_OPTIONS_KEYS.UNPAID_PERCENTAGE]: { direction: 'DESC', fields: ['unpaidPercentage'] },
};

export const COMMISSION_DRAWER_COMMISSION_ID_QS_KEY = 'commissionV3Id';
