import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Formula from '@palette/components/formula/Formula/Formula';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import RuleVariableCondition from '@palette/components/rule/RuleVariableCondition/RuleVariableCondition';

import * as MasterPlanVariableModel from '@palette/models/MasterPlanVariable';

import { VARIABLE_TYPES } from '@palette/constants/masterPlans';

import styles from './RuleVariable.less';

const classNames = bindClassNames.bind(styles);

const RuleVariable = ({ className, ruleVariable, scope }) => {
  const { t } = useTranslation();

  let valueNode = (
    <Formula
      formula={ruleVariable.formula}
      scope={scope}
    />
  );

  if (ruleVariable.type === VARIABLE_TYPES.CONDITIONAL) {
    const contentNode = ruleVariable.steps.map((step, index) => (
      <RuleVariableCondition
        className={styles.condition}
        key={index}
        prefix={index === 0 ? 'IF' : 'ELSE IF'}
        condition={step.condition}
        formula={step.formula}
        scope={scope}
        variableName={ruleVariable.name}
      />
    ));

    contentNode.push((
      <RuleVariableCondition
        key="last"
        prefix="ELSE"
        formula={ruleVariable.formula}
        scope={scope}
        variableName={ruleVariable.name}
      />
    ));

    valueNode = (
      <Collapse
        panels={[
          {
            title: t('conditionsWithCount', { count: ruleVariable.steps.length }),
            content: contentNode,
          },
        ]}
        ghost
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.name}>
        {ruleVariable.name}
      </div>
      <div className={styles.valueWrapper}>
        {valueNode}
      </div>
    </div>
  );
};

RuleVariable.propTypes = {
  className: PropTypes.string,
  ruleVariable: MasterPlanVariableModel.propTypes.isRequired,
  scope: PropTypes.object,
};

RuleVariable.defaultProps = {
  className: '',
  scope: {},
};

export default RuleVariable;
