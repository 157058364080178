import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import LeftMainContentLayout from '@palette/components/designSystem/LeftMainContentLayout/LeftMainContentLayout';
import PlanV3EditPageContentMain from '@palette/components/planV3/PlanV3EditPageContentMain/PlanV3EditPageContentMain';
import PlanV3EditPageContentLeftSide from '@palette/components/planV3/PlanV3EditPageContentLeftSide/PlanV3EditPageContentLeftSide';

import { EDIT_PAGE_TYPE } from '@palette/constants/planV3';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3EditAchievementPageContent.less';

const classNames = bindClassNames.bind(styles);

const PlanV3EditAchievementPageContent = ({ className, planId, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planDetails = useSelector((state) => PlanV3Selectors.getDetails(state));
  const isPending = useSelector((state) => PlanV3Selectors.getDetailsIsPending(state));

  useEffect(() => {
    if (planId && !isPending) {
      dispatch(PlanV3Actions.getDetails({ planId }));
    }
  }, [planId]);

  const headerNode = () => (
    <div className={styles.header}>
      <div className={styles.title}>
        {t('plansV3.editAchievementPage.leftSide.header')}
      </div>
    </div>
  );

  const leftContentNode = useMemo(() => planDetails && (
    <PlanV3EditPageContentLeftSide details={planDetails} />
  ), [planDetails]);

  const mainContentNode = useMemo(() => planDetails && (
    <PlanV3EditPageContentMain className={styles.main} details={planDetails} type={type} />
  ), [planDetails, type]);

  if (!isPending && !planDetails) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {isPending && <Loader />}
      {!isPending && (
        <LeftMainContentLayout
          leftSideHeaderClassName={styles.leftSideHeader}
          leftSideHeader={headerNode()}
          leftSideContentClassName={styles.leftSideContent}
          leftSideContent={leftContentNode}
          mainContentClassName={styles.mainContent}
          leftSideWidth={33}
          mainContent={mainContentNode}
        />
      )}
    </div>
  );
};

PlanV3EditAchievementPageContent.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(EDIT_PAGE_TYPE)),
};

PlanV3EditAchievementPageContent.defaultProps = {
  className: '',
  type: EDIT_PAGE_TYPE.ACHIEVEMENT,
};

export default PlanV3EditAchievementPageContent;
