import React from 'react';
import PropTypes from 'prop-types';

import { Form as AntDForm } from 'antd';

const Form = ({ layout, name, children, ...otherProps }) => (
  <AntDForm layout={layout} name={name} {...otherProps}>
    {children}
  </AntDForm>
);

Form.propTypes = {
  layout: PropTypes.oneOf(['horizontal', 'vertical', 'inline']),
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  children: PropTypes.any,
};

Form.defaultProps = {
  layout: 'vertical',
  name: null,
  children: null,
};

export default Form;
