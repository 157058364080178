import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';

import * as TeamModel from '@palette/models/Team';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './UpdateTeamNameModal.less';

const UpdateTeamNameModal = ({ visible, onClose, team, onUpdate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateTeamNameIsPending = useSelector(TeamsSelectors.updateTeamNameIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    name: team.name,
  }), [team]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const onSuccessCB = useCallback(() => {
    cleanAndClose();

    if (onUpdate !== null) onUpdate();
  }, [onUpdate, cleanAndClose]);

  const handleFinish = useCallback(({ name }) => {
    dispatch(TeamsActions.updateTeamName({ name, teamId: team.id, onSuccessCB }));
  }, [team, onSuccessCB]);

  const handleUpdateTeamName = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('updateTeamNameModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleUpdateTeamName}
      okText={t('updateTeamNameModal.update')}
      loading={updateTeamNameIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="name"
          label={t('updateTeamNameModal.form.name.label')}
          required
          rules={[
            { required: true, message: t('updateTeamNameModal.form.name.rules.required') },
          ]}
        >
          <Input size="big" disabled={updateTeamNameIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

UpdateTeamNameModal.propTypes = {
  team: TeamModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

UpdateTeamNameModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onUpdate: null,
};

export default UpdateTeamNameModal;
