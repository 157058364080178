import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Link from '@palette/components/designSystem/Link/Link';
import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';
import Calendar2Filled from '@palette/components/utils/Icons/Calendar2Filled';
import FlagFilled from '@palette/components/utils/Icons/FlagFilled';
import SettingsFilled from '@palette/components/utils/Icons/SettingsFilled';

import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './MasterPlanBreadcrumb.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanBreadcrumb = ({ className, plan, period, settings }) => {
  const { t } = useTranslation();

  if (plan === null) return null;

  let currentLocationNode = (
    <div className={styles.currentLocationWrapper}>
      <Calendar2Filled className={styles.currentLocationIcon} width={16} height={16} />
      <div className={styles.currentLocationLabel}>
        {plan.name}
      </div>
    </div>
  );

  let planLinkNode = null;
  if (period !== null) {
    planLinkNode = (
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.planDetails}
          params={{ masterPlanId: plan.id }}
        >
          <Calendar2Filled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {plan.name}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
    );

    let periodName = getPlanPeriodName(plan, period);

    if (plan.periodType === PERIOD_TYPES.CUSTOM) {
      planLinkNode = null;
      periodName = plan.name;
    }

    currentLocationNode = (
      <div className={styles.currentLocationWrapper}>
        <FlagFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {periodName}
        </div>
      </div>
    );
  }

  if (settings) {
    planLinkNode = (
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.planDetails}
          params={{ masterPlanId: plan.id }}
        >
          <Calendar2Filled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {plan.name}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
    );

    currentLocationNode = (
      <div className={styles.currentLocationWrapper}>
        <SettingsFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {t('masterPlanSettingsPage.title')}
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.plans}
        >
          <MedicalChartFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {t('masterPlanBreadcrumb.plans')}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
      {planLinkNode}
      {currentLocationNode}
    </div>
  );
};

MasterPlanBreadcrumb.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
  period: MasterPlanPeriodModel.propTypes,
  settings: PropTypes.bool,
};

MasterPlanBreadcrumb.defaultProps = {
  className: '',
  plan: null,
  period: null,
  settings: false,
};

export default MasterPlanBreadcrumb;
