import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { getFrequencyName } from '@palette/helpers/QuotaHelper';

import * as QuotaModel from '@palette/models/Quota';

import styles from './QuotaInfos.less';

const classNames = bindClassNames.bind(styles);

const QuotaInfos = ({ className, quota, displayUsersCount }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {`${quota.type} · ${getFrequencyName(quota)}${displayUsersCount ? ` · ${t('usersWithCount', { count: quota.usersCount })}` : ''}`}
    </div>
  );
};

QuotaInfos.propTypes = {
  className: PropTypes.string,
  quota: QuotaModel.propTypes.isRequired,
  displayUsersCount: PropTypes.bool,
};

QuotaInfos.defaultProps = {
  className: '',
  displayUsersCount: true,
};

export default QuotaInfos;
