import PropTypes from 'prop-types';

import {
  manageDateAttribute,
} from '@palette/helpers/ModelHelper';

import * as MetaUserModel from '@palette/models/MetaUser';

export const modelName = 'MasterPlanUser';

export const propTypes = PropTypes.shape({
  user: MetaUserModel.propTypes,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
});

export const transform = (masterPlanUser) => {
  if (!masterPlanUser) {
    return null;
  }

  return ({
    user: MetaUserModel.transform(masterPlanUser),
    startDate: manageDateAttribute(masterPlanUser, 'startDate'),
    endDate: manageDateAttribute(masterPlanUser, 'endDate'),
  });
};

export const transformList = (masterPlanUsers) => masterPlanUsers.map((masterPlanUser) => transform(masterPlanUser));
