import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _size from 'lodash/size';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select from '@palette/components/designSystem/Select/Select';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';
import { ALERT_TYPES } from '@palette/constants/alert';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlansListSelect.less';

const classNames = bindClassNames.bind(styles);

const MasterPlansListSelect = ({
  className,
  alertClassName,
  value,
  onChange,
  disabled,
  filterOnPeriodType,
  exceptPlanIds,
  disablePlanIds,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => setInternalValue(value), [value]);

  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    setInternalValue(newValue);

    if (onChange !== null) {
      onChange(newValue);
    }
  }, [onChange]);

  const getListIsPending = useSelector(MasterPlansSelectors.getListIsPending);
  const listStats = useSelector(MasterPlansSelectors.getListStats);
  const activePlansList = useSelector((state) => MasterPlansSelectors.getPlansListByType(state, { listType: PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE }));
  const pastPlansList = useSelector((state) => MasterPlansSelectors.getPlansListByType(state, { listType: PLANS_PAGE_CONTENT_TABS_IDS.PAST }));
  const archivedPlansList = useSelector((state) => MasterPlansSelectors.getPlansListByType(state, { listType: PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED }));

  useEffect(() => {
    if (listStats.activeAndPast === undefined) {
      dispatch(MasterPlansActions.getList());
    }
  }, [listStats]);

  const filteredPastPlansList = useMemo(() => {
    if (filterOnPeriodType === null) return pastPlansList;

    return pastPlansList.filter((planItem) => planItem.periodType === filterOnPeriodType);
  }, [filterOnPeriodType, pastPlansList]);

  const filteredActivePlansList = useMemo(() => {
    if (filterOnPeriodType === null) return activePlansList;

    return activePlansList.filter((planItem) => planItem.periodType === filterOnPeriodType);
  }, [filterOnPeriodType, activePlansList]);

  const filteredArchivedPlansList = useMemo(() => {
    if (filterOnPeriodType === null) return archivedPlansList;

    return archivedPlansList.filter((planItem) => planItem.periodType === filterOnPeriodType);
  }, [filterOnPeriodType, archivedPlansList]);

  const exceptPlanIdsFilter = useCallback((planItem) => {
    if (exceptPlanIds.length > 0) {
      return !exceptPlanIds.includes(planItem.id);
    }

    return planItem;
  }, [exceptPlanIds]);

  const disablePlanIdsCheck = useCallback((planItem) => {
    if (disablePlanIds.length > 0) {
      return disablePlanIds.includes(planItem.id);
    }

    return false;
  }, [disablePlanIds]);

  const plansListOptions = useMemo(() => {
    const finalPlansList = {};

    if (filteredActivePlansList.length > 0) {
      finalPlansList[t('masterPlansListSelect.active')] = filteredActivePlansList.filter(exceptPlanIdsFilter).map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
        disabled: disablePlanIdsCheck(planItem),
      }));
    }

    if (filteredPastPlansList.length > 0) {
      finalPlansList[t('masterPlansListSelect.past')] = filteredPastPlansList.filter(exceptPlanIdsFilter).map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
        disabled: disablePlanIdsCheck(planItem),
      }));
    }

    if (filteredArchivedPlansList.length > 0) {
      finalPlansList[t('masterPlansListSelect.archived')] = filteredArchivedPlansList.filter(exceptPlanIdsFilter).map((planItem) => ({
        label: planItem.name,
        value: planItem.id,
        disabled: disablePlanIdsCheck(planItem),
      }));
    }

    if (Object.keys(finalPlansList).length === 1) {
      return finalPlansList[Object.keys(finalPlansList)[0]];
    }

    return finalPlansList;
  }, [
    filterOnPeriodType,
    filteredActivePlansList,
    filteredPastPlansList,
    filteredArchivedPlansList,
    exceptPlanIdsFilter,
    disablePlanIdsCheck,
  ]);

  const contentNode = useMemo(() => {
    if (filteredActivePlansList.length === 0 && filteredPastPlansList.length === 0 && archivedPlansList.length === 0) {
      return (
        <Alert
          className={alertClassName}
          type={ALERT_TYPES.WARNING}
          message={t('masterPlanList.empty.description')}
        />
      );
    }

    return (
      <Select
        className={styles.selectContainer}
        options={plansListOptions}
        value={internalValue}
        onSelect={handleChange}
        disabled={disabled}
        placeholder={t('masterPlansListSelect.placeholder')}
        pendingOptions={_size(plansListOptions) === 0 && getListIsPending}
        showSearch
        {...otherProps}
      />
    );
  }, [
    filteredActivePlansList,
    filteredPastPlansList,
    archivedPlansList,
    alertClassName,
    plansListOptions,
    internalValue,
    handleChange,
    disabled,
    getListIsPending,
    otherProps,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlansListSelect.propTypes = {
  className: PropTypes.string,
  alertClassName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  filterOnPeriodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  exceptPlanIds: PropTypes.array,
  disablePlanIds: PropTypes.array,
};

MasterPlansListSelect.defaultProps = {
  className: '',
  alertClassName: '',
  value: null,
  onChange: null,
  disabled: false,
  filterOnPeriodType: null,
  exceptPlanIds: [],
  disablePlanIds: [],
};

export default MasterPlansListSelect;
