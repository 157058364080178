import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import NotebooksTab from '@palette/components/notebooks/NotebooksTab/NotebooksTab';

import { NOTEBOOKS_TABS_IDS, NOTEBOOKS_TABS_QS_KEY } from '@palette/constants/tabs';

import { selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './NotebooksPageContent.less';

const classNames = bindClassNames.bind(styles);

const NotebooksPageContent = ({ className }) => {
  const { t } = useTranslation();

  const notebooksListStats = useSelector(NotebooksSelectors.getNotebooksListStats);

  const tabs = useMemo(() => {
    const finalTabs = [];

    finalTabs.push({
      tabKey: NOTEBOOKS_TABS_IDS.ALL,
      title: t('notebooksPageContent.tabs.all'),
      titleCount: notebooksListStats[NOTEBOOKS_TABS_IDS.ALL] ?? 0,
      content: (<NotebooksTab filter={NOTEBOOKS_TABS_IDS.ALL} />),
      defaultActive: true,
    });

    finalTabs.push({
      tabKey: NOTEBOOKS_TABS_IDS.YOURS,
      title: t('notebooksPageContent.tabs.yours'),
      titleCount: notebooksListStats[NOTEBOOKS_TABS_IDS.YOURS] ?? 0,
      content: (<NotebooksTab filter={NOTEBOOKS_TABS_IDS.YOURS} />),
    });

    finalTabs.push({
      tabKey: NOTEBOOKS_TABS_IDS.TEMPLATES,
      title: t('notebooksPageContent.tabs.templates'),
      titleCount: notebooksListStats[NOTEBOOKS_TABS_IDS.TEMPLATES] ?? 0,
      content: (<NotebooksTab filter={NOTEBOOKS_TABS_IDS.TEMPLATES} />),
    });

    finalTabs.push({
      tabKey: NOTEBOOKS_TABS_IDS.ARCHIVED,
      title: t('notebooksPageContent.tabs.archived'),
      titleCount: notebooksListStats[NOTEBOOKS_TABS_IDS.ARCHIVED] ?? 0,
      content: (<NotebooksTab filter={NOTEBOOKS_TABS_IDS.ARCHIVED} />),
    });

    return finalTabs;
  }, [notebooksListStats]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        tabs={tabs}
        qsTabKey={NOTEBOOKS_TABS_QS_KEY}
        alwaysDisplayTitleCount
        destroyInactiveTabPane
      />
    </div>
  );
};

NotebooksPageContent.propTypes = {
  className: PropTypes.string,
};

NotebooksPageContent.defaultProps = {
  className: '',
};

export default NotebooksPageContent;
