import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { flatten } from 'flat';

import Input from '@palette/components/designSystem/Input/Input';

import { ContainerOutlined } from '@ant-design/icons';

import Table from '@palette/components/designSystem/Table/Table';

import styles from './ResourceObjectSample.less';

const classNames = bindClassNames.bind(styles);

const ResourceObjectSample = ({ className, helperData }) => {
  const { t } = useTranslation();

  const title = useMemo(() => {
    if (Object.keys(helperData.data).length > 1) {
      return t('formulaHelper.formulaHelperTitle');
    }

    return t('resourceObjectSample.title', { resourceType: helperData.type });
  }, [helperData]);

  const tableColumns = useMemo(() => ([
    {
      id: 'name',
      Header: t('resourceObjectSample.columns.name.label'),
      accessor: 'name',
      width: '50%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<div className={styles.cell}>{value}</div>),
    },
    {
      id: 'value',
      Header: t('resourceObjectSample.columns.value.label'),
      accessor: 'value',
      width: '50%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<div className={styles.cell}>{value}</div>),
    },
  ]), []);

  const [searchedProp, setSearchedProp] = useState('');

  const handleSearchPropChange = useCallback((newSearchedProp) => {
    setSearchedProp(newSearchedProp);
  });

  const tableNode = useMemo(() => {
    let data = helperData.data[helperData.type];
    if (Object.keys(helperData.data).length > 1) {
      data = helperData.data;
    }

    const flattenData = flatten(data);

    let nameValueData = Object.entries(flattenData).map(([name, value]) => ({
      name,
      value: typeof (value) === 'object' ? JSON.stringify(value) : value,
    }));
    if (searchedProp.length > 0) {
      const searchedPropValue = searchedProp.toLowerCase();
      nameValueData = nameValueData.filter((nameValue) => (
        (nameValue.name || '').toString().toLowerCase().indexOf(searchedPropValue) !== -1
        || (nameValue.value || '').toString().toLowerCase().indexOf(searchedPropValue) !== -1
      ));
    }

    return (
      <Table
        className={styles.table}
        columns={tableColumns}
        data={nameValueData}
        stretch
        stickyHeader
        fitInContainer
      />
    );
  }, [
    helperData,
    tableColumns,
    searchedProp,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.titleWrapper}>
        <ContainerOutlined className={styles.titleIcon} />
        <div className={styles.title}>
          {title}
        </div>
      </div>
      <Input
        className={styles.propSearch}
        type="search"
        theme="white"
        placeholder={t('resourceObjectSample.propSearch.placeholder')}
        onChange={handleSearchPropChange}
        value={searchedProp}
      />
      {tableNode}
    </div>
  );
};

ResourceObjectSample.propTypes = {
  className: PropTypes.string,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
};

ResourceObjectSample.defaultProps = {
  className: '',
};

export default ResourceObjectSample;
