import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { getCellContentNodeFromType } from '@palette/helpers/NotebookComponentsHelper';

import * as NotebookCellModel from '@palette/models/NotebookCell';

import styles from './NotebookCellContentWrapper.less';

const classNames = bindClassNames.bind(styles);

const NotebookCellContentWrapper = ({ className, cell, onCellContentUpdate, onCellContentSave, disabled }) => {
  const [isFocus, setIsFocus] = useState(false);

  const [currentContent, setCurrentContent] = useState(cell.content);

  useEffect(() => {
    setCurrentContent(cell.content);
  }, [cell]);

  const handleContentChange = useCallback((newContent) => {
    setCurrentContent(newContent);
    onCellContentUpdate(newContent);
  }, [currentContent, onCellContentUpdate]);

  const handleSaveUpdate = useCallback(() => {
    onCellContentSave(currentContent);
  }, [currentContent, onCellContentSave]);

  const handleCellContentFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleCellContentBlur = useCallback(() => {
    setIsFocus(false);
    handleSaveUpdate();
  }, [handleSaveUpdate]);

  const cellContentNode = useMemo(() => (
    getCellContentNodeFromType(
      cell.type,
      currentContent,
      handleContentChange,
      handleCellContentFocus,
      handleCellContentBlur,
      disabled,
    )
  ), [
    cell,
    currentContent,
    handleContentChange,
    handleCellContentFocus,
    handleCellContentBlur,
    disabled,
  ]);

  if (cellContentNode === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        isFocus,
        isDisabled: disabled,
        [className]: className !== '',
      })}
    >
      {cellContentNode}
    </div>
  );
};

NotebookCellContentWrapper.propTypes = {
  className: PropTypes.string,
  cell: NotebookCellModel.propTypes.isRequired,
  onCellContentUpdate: PropTypes.func.isRequired,
  onCellContentSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NotebookCellContentWrapper.defaultProps = {
  className: '',
  disabled: false,
};

export default NotebookCellContentWrapper;
