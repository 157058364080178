import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import MasterPlanListItem from '@palette/components/masterPlan/MasterPlanListItem/MasterPlanListItem';
import MasterPlanListDraftItem from '@palette/components/masterPlan/MasterPlanListDraftItem/MasterPlanListDraftItem';
import RootFolder from '@palette/components/folders/FoldersRecursive/RootFolder/RootFolder';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { buildPlansInFolders } from '@palette/helpers/MasterPlanHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { PLANS_PAGE_CONTENT_TABS_IDS } from '@palette/constants/tabs';
import { FOLDER_TYPES, DEFAULT_FOLDER_ID } from '@palette/constants/folders';
import { RIGHTS } from '@palette/constants/profile';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as FoldersActions, selectors as FoldersSelectors } from '@palette/state/Folders';

import styles from './MasterPlanList.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanList = ({ className, listType, searchedPlan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const listIsPending = useSelector(MasterPlansSelectors.getListIsPending);
  const foldersListIsPending = useSelector(FoldersSelectors.getListIsPending);

  const plansList = useSelector((state) => MasterPlansSelectors.getPlansListByType(state, { listType }));
  const foldersList = useSelector(FoldersSelectors.getFoldersList);

  const filteredPlansList = useMemo(() => {
    if (plansList.length === 0 || searchedPlan === '') return plansList;

    return plansList.filter((masterPlan) => masterPlan.name.toLowerCase().indexOf(searchedPlan.toLowerCase()) > -1);
  }, [plansList, searchedPlan]);

  const hasRightToCreatePlan = hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE]);

  const rootFolder = useMemo(() => (
    buildPlansInFolders(filteredPlansList, foldersList, hasRightToCreatePlan, listType, searchedPlan)
  ), [filteredPlansList, foldersList, hasRightToCreatePlan, searchedPlan]);

  const handleMoveFolder = (folderId, parentId) => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])) return;

    let finalParentId = parentId;
    let type;

    if (parentId === DEFAULT_FOLDER_ID) {
      finalParentId = undefined;
      type = FOLDER_TYPES.PLAN;
    }

    dispatch(FoldersActions.moveFolder({
      folderId,
      parentId: finalParentId,
      type,
    }));
  };

  const handleMoveEntity = (resourceId, parentId) => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])) return;

    let finalParentId = parentId;

    if (parentId === DEFAULT_FOLDER_ID) {
      finalParentId = undefined;
    }

    dispatch(FoldersActions.moveEntities({
      type: FOLDER_TYPES.PLAN,
      entityId: resourceId,
      folderId: finalParentId,
    }));
  };

  let listContentNode = (
    <DefaultEmptyState description={t('masterPlanList.empty.description')} />
  );

  if (listIsPending || foldersListIsPending) {
    listContentNode = (
      <Loader />
    );
  } else if (listType === PLANS_PAGE_CONTENT_TABS_IDS.DRAFT && filteredPlansList.length > 0) {
    listContentNode = (
      <>
        <Alert
          className={styles.alertContainer}
          message={t('masterPlansList.drafts.alert.message')}
        />
        <div className={styles.draftPlansContainer}>
          {filteredPlansList.map((plan) => (
            <MasterPlanListDraftItem key={plan.id} className={styles.item} plan={plan} />
          ))}
        </div>
      </>
    );
  } else if (rootFolder.children.length > 0 || rootFolder.resources.length > 0) {
    listContentNode = (
      <RootFolder
        folder={rootFolder}
        ItemComponent={MasterPlanListItem}
        onMoveFolder={handleMoveFolder}
        onMoveEntity={handleMoveEntity}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        isDraft: listType === PLANS_PAGE_CONTENT_TABS_IDS.DRAFT,
        [className]: className !== '',
      })}
    >
      {listContentNode}
    </div>
  );
};

MasterPlanList.propTypes = {
  className: PropTypes.string,
  listType: PropTypes.string,
  searchedPlan: PropTypes.string,
};

MasterPlanList.defaultProps = {
  className: '',
  listType: PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE,
  searchedPlan: '',
};

export default MasterPlanList;
