/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _debounce from 'lodash/debounce';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import Input from '@palette/components/designSystem/Input/Input';
import Select from '@palette/components/designSystem/Select/Select';
import MyCommissionListTable from '@palette/components/ic/myCommission/MyCommissionListTable/MyCommissionListTable';
import CommissionClearFilters from '@palette/components/commissionList/CommissionClearFilters/CommissionClearFilters';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import Loader from '@palette/components/utils/Loader/Loader';
import ResourceConnectorsSelect from '@palette/components/connector/ResourceConnectorsSelect/ResourceConnectorsSelect';
import MyMasterPlansListSelect from '@palette/components/ic/myMasterPlan/MyMasterPlansListSelect/MyMasterPlansListSelect';
import MyCommissionListHighlightZone from '@palette/components/ic/myCommission/MyCommissionListHighlightZone/MyCommissionListHighlightZone';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';

import { useCommissionListPageFilters } from '@palette/hooks/CommissionHooks';
import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { scrollToTop } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { buildCommissionListByFiltersPayload } from '@palette/helpers/CommissionHelper';

import {
  SEARCHED_RESOURCE_QS_KEY,
  SEARCHED_STATUS_QS_KEY,
  SEARCHED_FROM_QS_KEY,
  SEARCHED_TO_QS_KEY,
  SEARCHED_RESOURCE_TYPE_QS_KEY,
  SEARCHED_PLAN_QS_KEY,
  STATUSES_OPTIONS,
  SORTBY_OPTIONS,
  SORTBY_OPTIONS_KEYS,
  SORT_BY_QS_KEY,
} from '@palette/constants/commissions';
import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE, LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';
import { COMMISSION_LIST_EVENTS } from '@palette/constants/analytics';

import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions';
import { actions as NavigationActions } from '@palette/state/Navigation';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MyCommissionListPageContent.less';

const classNames = bindClassNames.bind(styles);

const MyCommissionListPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();
  const moment = getMoment();

  const statusesOptions = Object.keys(STATUSES_OPTIONS).map((type) => {
    const label = t(`commissionList.statuses.option.${type.toLowerCase()}`);

    return ({
      // eslint-disable-next-line react/no-danger
      label: <span dangerouslySetInnerHTML={{ __html: label }} />,
      value: type.toLowerCase(),
      rawlabel: label,
    });
  });

  const sortByOptions = Object.keys(SORTBY_OPTIONS).map((type) => {
    const label = t(`commissionList.sortBy.option.${type.toLowerCase()}`);

    return ({
      // eslint-disable-next-line react/no-danger
      label: <span dangerouslySetInnerHTML={{ __html: label }} />,
      value: type.toLowerCase(),
      rawlabel: label,
    });
  });

  const [getPaginationLimit, paginationLimitInSearch] = useLimitInSearch();
  const [getPaginationPage, paginationPageInSearch] = usePageInSearch();
  const commissionListPageFilters = useCommissionListPageFilters();

  const [searchedResource, setSearchedResource] = useState(null);

  const listByFiltersIsPending = useSelector(CommissionsSelectors.listByFiltersIsPending);

  const getCommissionList = () => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return;

    const listByFiltersPayload = buildCommissionListByFiltersPayload(commissionListPageFilters);

    listByFiltersPayload.page = getPaginationPage;
    listByFiltersPayload.limit = getPaginationLimit;

    dispatch(CommissionsActions.listICByFilters(listByFiltersPayload));
  };

  const {
    searchedResourceFromSearch,
    searchedStatus,
    searchStatusUrl,
    searchedStatusInSearch,
    searchedFrom,
    searchedFromInSearch,
    searchedTo,
    searchedToInSearch,
    searchedResourceType,
    searchedResourceTypeInSearch,
    searchedPlan,
    searchedPlanInSearch,
    sortBy,
    sortByUrl,
    sortByInSearch,
  } = useMemo(() => commissionListPageFilters, [commissionListPageFilters]);

  useEffect(() => {
    getCommissionList();
  }, [
    searchedFrom,
    searchedTo,
    searchedResourceFromSearch,
    searchedStatus,
    searchedResourceType,
    searchedPlan,
    getPaginationPage,
    getPaginationLimit,
    sortBy,
  ]);

  const handleExportSelection = useCallback(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return;

    const listByFiltersPayload = buildCommissionListByFiltersPayload(commissionListPageFilters);

    listByFiltersPayload.page = getPaginationPage;
    listByFiltersPayload.limit = getPaginationLimit;

    dispatch(CommissionsActions.downloadCommissionICList(listByFiltersPayload));

    dispatch(AnalyticsActions.sendEvent({ event: COMMISSION_LIST_EVENTS.EXPORT }));
  }, [
    searchedFrom,
    searchedTo,
    searchedResourceFromSearch,
    searchedStatus,
    searchedResourceType,
    searchedPlan,
    getPaginationPage,
    getPaginationLimit,
  ]);

  useEffect(() => {
    const keysToDelete = [];

    if (searchedResourceType === null && searchedResourceTypeInSearch) {
      keysToDelete.push(SEARCHED_RESOURCE_TYPE_QS_KEY);
    }

    if (searchedPlan === null && searchedPlanInSearch) {
      keysToDelete.push(SEARCHED_PLAN_QS_KEY);
    }

    if (!searchedStatus && searchedStatusInSearch) {
      keysToDelete.push(SEARCHED_STATUS_QS_KEY);
    }

    if ((searchedFromInSearch || searchedToInSearch) && (!moment(searchedFrom).isValid() || !moment(searchedTo).isValid())) {
      keysToDelete.push([SEARCHED_FROM_QS_KEY, SEARCHED_TO_QS_KEY]);
    }

    if (getPaginationPage === DEFAULT_PAGE_QS_VALUE && paginationPageInSearch) {
      keysToDelete.push(PAGE_QS_KEY);
    }

    if (getPaginationLimit === DEFAULT_LIMIT_QS_VALUE && paginationLimitInSearch) {
      keysToDelete.push(LIMIT_QS_KEY);
    }

    if (searchedResource === '' && searchedResourceFromSearch) {
      keysToDelete.push(SEARCHED_RESOURCE_QS_KEY);
    }

    if ((!sortBy || sortBy === SORTBY_OPTIONS[SORTBY_OPTIONS_KEYS.DATE_DESC]) && sortByInSearch) {
      keysToDelete.push(SORT_BY_QS_KEY);
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    searchedStatus,
    searchedStatusInSearch,
    getPaginationPage,
    paginationPageInSearch,
    getPaginationLimit,
    paginationLimitInSearch,
    searchedResource,
    searchedResourceFromSearch,
    searchedFrom,
    searchedFromInSearch,
    searchedTo,
    searchedToInSearch,
    searchedResourceType,
    searchedResourceTypeInSearch,
    searchedPlan,
    searchedPlanInSearch,
    sortBy,
    sortByInSearch,
  ]);

  const performResourceSearch = useCallback(
    _debounce((newSearchedResource) => {
      const QSToAdd = {};
      const keysToDelete = [PAGE_QS_KEY];

      if (newSearchedResource !== '') {
        QSToAdd[SEARCHED_RESOURCE_QS_KEY] = newSearchedResource;
      } else {
        keysToDelete.push(SEARCHED_RESOURCE_QS_KEY);
      }

      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
      dispatch(AnalyticsActions.sendEvent({ event: COMMISSION_LIST_EVENTS.FILTER_SEARCH }));
    }, 700),
    [],
  );

  const handlePerformResourceChange = useCallback((newSearchedResource) => {
    performResourceSearch(newSearchedResource);
  }, []);

  const handleSearchResourceChange = useCallback((newSearchedResource) => {
    setSearchedResource(newSearchedResource);

    if (newSearchedResource.length >= 1) {
      handlePerformResourceChange(newSearchedResource);
    }
  }, [handlePerformResourceChange]);

  const handleFilterByStatusChange = useCallback((status) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (status === null) {
      keysToDelete.push(SEARCHED_STATUS_QS_KEY);
    } else {
      QSToAdd[SEARCHED_STATUS_QS_KEY] = status;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    dispatch(AnalyticsActions.sendEvent({ event: COMMISSION_LIST_EVENTS.FILTER_STATUS, params: { STATUS_OPTION: status?.toUpperCase() || '' } }));
  }, []);

  const handleFilterByDateChange = useCallback((dates) => {
    let from = null;
    let to = null;

    if (dates !== null) {
      from = moment(dates[0]).format('YYYY-MM-DD');
      to = moment(dates[1]).format('YYYY-MM-DD');
    }

    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if ((!from || !to) && dates !== null) {
      keysToDelete.push([SEARCHED_FROM_QS_KEY, SEARCHED_TO_QS_KEY]);
    } else {
      QSToAdd[SEARCHED_FROM_QS_KEY] = from;
      QSToAdd[SEARCHED_TO_QS_KEY] = to;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    dispatch(AnalyticsActions.sendEvent({ event: COMMISSION_LIST_EVENTS.FILTER_DATE }));
  }, []);

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const handleFilterByResourceTypeChange = useCallback((resourceId) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (resourceId === null) {
      keysToDelete.push(SEARCHED_RESOURCE_TYPE_QS_KEY);
    } else {
      QSToAdd[SEARCHED_RESOURCE_TYPE_QS_KEY] = resourceId;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    dispatch(AnalyticsActions.sendEvent({ event: COMMISSION_LIST_EVENTS.FILTER_RESOURCE }));
  }, []);

  const handleFilterByPlanChange = useCallback((planId) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (planId === null) {
      keysToDelete.push(SEARCHED_PLAN_QS_KEY);
    } else {
      QSToAdd[SEARCHED_PLAN_QS_KEY] = planId;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    dispatch(AnalyticsActions.sendEvent({ event: COMMISSION_LIST_EVENTS.FILTER_PLAN }));
  }, []);

  const handleSortByChange = useCallback((sortBy) => {
    const QSToAdd = {};
    const keysToDelete = [];

    if (sortBy === null) {
      keysToDelete.push(SORT_BY_QS_KEY);
    } else {
      QSToAdd[SORT_BY_QS_KEY] = sortBy;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    dispatch(AnalyticsActions.sendEvent({ event: COMMISSION_LIST_EVENTS.SORT, params: { SORT_OPTION: sortBy?.toUpperCase() || '' } }));
  }, []);

  const handleClearFilters = useCallback(() => {
    const keysToDelete = [
      PAGE_QS_KEY,
      SEARCHED_RESOURCE_QS_KEY,
      SEARCHED_STATUS_QS_KEY,
      SEARCHED_FROM_QS_KEY,
      SEARCHED_TO_QS_KEY,
      SEARCHED_RESOURCE_TYPE_QS_KEY,
      SEARCHED_PLAN_QS_KEY,
    ];

    setSearchedResource(null);

    dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
  }, []);

  const handleRefresh = useCallback(() => getCommissionList(), []);

  useEffect(() => {
    if (!listByFiltersIsPending) {
      scrollToTop();
    }
  }, [listByFiltersIsPending]);

  const rangePickerNode = useMemo(() => (
    <DatePicker
      className={classNames({
        selectField: true,
        rangePicker: true,
      })}
      allowClear
      disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
      showTime={false}
      showNow={false}
      isRange
      value={[searchedFrom, searchedTo]}
      onChange={handleFilterByDateChange}
    />
  ), [
    searchedFrom,
    searchedTo,
    handleFilterByDateChange,
  ]);

  const statusSelectNode = useMemo(() => (
    <Select
      className={styles.selectField}
      value={searchStatusUrl}
      placeholder={t('commissionList.statuses.placeholder')}
      onChange={handleFilterByStatusChange}
      options={statusesOptions}
      filterOptionProp="rawlabel"
      allowClear
    />
  ), [
    statusesOptions,
    searchStatusUrl,
    handleFilterByStatusChange,
  ]);

  const resourceTypeSelectorNode = useMemo(() => (
    <ResourceConnectorsSelect
      className={styles.selectField}
      onChange={handleFilterByResourceTypeChange}
      value={searchedResourceType}
      placeholder={t('commissionList.resourceType.placeholder')}
      allowClear
    />
  ), [
    searchedResourceType,
    handleFilterByResourceTypeChange,
  ]);

  const planSelectorNode = useMemo(() => (
    <MyMasterPlansListSelect
      className={styles.selectField}
      alertClassName={styles.selectPlanAlertWrapper}
      onChange={handleFilterByPlanChange}
      value={searchedPlan}
      placeholder={t('commissionList.plan.placeholder')}
      allowClear
    />
  ), [
    searchedPlan,
    handleFilterByPlanChange,
  ]);

  const headerNode = useMemo(() => (
    <div className={styles.header}>
      <div className={styles.filters}>
        <div className={styles.resourceField}>
          <Input
            className={styles.resourceSearch}
            type="search"
            defaultValue={searchedResourceFromSearch}
            placeholder={t('commissionList.resourceSearch.placeholder')}
            onChange={handleSearchResourceChange}
            value={searchedResource || searchedResourceFromSearch}
          />
        </div>
        <div className={styles.otherFields}>
          {resourceTypeSelectorNode}
          {planSelectorNode}
          {rangePickerNode}
          {statusSelectNode}
        </div>
      </div>
      <CommissionClearFilters onClear={handleClearFilters} />
    </div>
  ), [
    searchedResourceFromSearch,
    handleSearchResourceChange,
    searchedResource,
    searchedResourceFromSearch,
    resourceTypeSelectorNode,
    planSelectorNode,
    rangePickerNode,
    statusSelectNode,
    handleClearFilters,
  ]);

  const highlightZoneNode = useMemo(() => (
    <MyCommissionListHighlightZone className={styles.highlightZone} />
  ), []);

  const sortBySelectNode = useMemo(() => (
    <Select
      className={styles.sortSelect}
      value={sortByUrl}
      placeholder={t('commissionList.sortBy.placeholder')}
      onChange={handleSortByChange}
      options={sortByOptions}
      filterOptionProp="rawlabel"
    />
  ), [
    sortByOptions,
    sortByUrl,
    handleSortByChange,
  ]);

  const actionsNode = useMemo(() => (
    <div className={styles.actions}>
      {sortBySelectNode}
      <div className={styles.rightSide}>
        <Button
          className={styles.actionLink}
          type="linkSecondary"
          icon={<DownloadFilled />}
          onClick={handleExportSelection}
        >
          {t('commissionList.actions.exportSelection')}
        </Button>
      </div>
    </div>
  ), [
    handleExportSelection,
    sortBySelectNode,
  ]);

  const tableNode = useMemo(() => (
    <Loader spinning={listByFiltersIsPending}>
      <MyCommissionListTable
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
        onRefresh={handleRefresh}
      />
    </Loader>
  ), [
    listByFiltersIsPending,
    handlePageChange,
    handleLimitChange,
    handleRefresh,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {headerNode}
      {highlightZoneNode}
      {actionsNode}
      {tableNode}
    </div>
  );
};

MyCommissionListPageContent.propTypes = {
  className: PropTypes.string,
};

MyCommissionListPageContent.defaultProps = {
  className: '',
};

export default MyCommissionListPageContent;
