import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import MasterPlanScopeSelector from '@palette/components/masterPlanSettings/MasterPlanScopeSelector/MasterPlanScopeSelector';

const MasterPlanSettingsGeneralScopeFormItem = ({ disabled, onChange }) => {
  const { t } = useTranslation();

  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    if (onChange !== null) {
      onChange(newValue);
    }
  });

  return (
    <FormItem
      name="scope"
      label={t('masterPlanSettingsGeneral.form.scope.label')}
      required
      rules={[
        { required: true },
      ]}
    >
      <MasterPlanScopeSelector disabled={disabled} onChange={handleChange} />
    </FormItem>
  );
};

MasterPlanSettingsGeneralScopeFormItem.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

MasterPlanSettingsGeneralScopeFormItem.defaultProps = {
  disabled: false,
  onChange: null,
};

export default MasterPlanSettingsGeneralScopeFormItem;
