import PropTypes from 'prop-types';

import { manageNumberAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'ApproveStatementStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  total: PropTypes.number,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'ApproveStatementContentStrategy',
      total: manageNumberAttribute(strategy, 'count'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
