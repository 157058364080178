import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import EyesLine from '@palette/components/utils/Icons/EyesLine';
import PeriodDealCommissionInfoComments from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfoComments/PeriodDealCommissionInfoComments';
import PeriodDealCommissionInfoPayments from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfoPayments/PeriodDealCommissionInfoPayments';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { COMMISSION_DRAWER_COMMISION_ID_QS_KEY } from '@palette/constants/commissions';
import { COMMISSION_DRAWER_QS_KEY, COMMISSION_DRAWER_TABS_IDS } from '@palette/constants/tabs';

import { getSearch, stringifyQS } from '@palette/helpers/NavigationHelper';

import * as CommissionModel from '@palette/models/Commission';

import { actions as NavigationActions } from '@palette/state/Navigation';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './PeriodDealCommissionInfos.less';

const classNames = bindClassNames.bind(styles);

const PeriodDealCommissionInfos = ({ className, commission, displayPayments, inSalesforce }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reactLocation = useLocation();
  const reactHistory = useHistory();

  const openCommissionDrawer = useCallback((tabKey) => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_VIEW_DETAILS }));

    if (inSalesforce) {
      const qsObj = getSearch(reactLocation);
      qsObj[COMMISSION_DRAWER_COMMISION_ID_QS_KEY] = commission.id;
      qsObj[COMMISSION_DRAWER_QS_KEY] = tabKey;

      reactHistory.push({
        pathname: reactLocation.pathname,
        search: stringifyQS(qsObj),
      });
    } else {
      dispatch(NavigationActions.addQSToLocation({ qsObject: { [COMMISSION_DRAWER_COMMISION_ID_QS_KEY]: commission.id, [COMMISSION_DRAWER_QS_KEY]: tabKey } }));
    }
  }, [commission, inSalesforce, reactLocation, reactHistory]);

  const handlePaymentsClick = useCallback(() => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_PAYMENT_TAG }));
  }, []);

  const handleCommentsClick = useCallback(() => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_ACTIVITY_TAG }));
  }, []);

  const paymentsNode = useMemo(() => {
    if (!displayPayments) return null;

    return (
      <div className={styles.tagWrapper}>
        <PeriodDealCommissionInfoPayments
          payments={commission.payments}
          commissionId={commission.id}
          onClick={handlePaymentsClick}
        />
      </div>
    );
  }, [displayPayments]);

  const commentsNode = useMemo(() => {
    if (inSalesforce) return null;

    return (
      <div className={styles.tagWrapper}>
        <PeriodDealCommissionInfoComments
          commentsCount={commission.commentsCount}
          commissionId={commission.id}
          inSalesforce={inSalesforce}
          onClick={handleCommentsClick}
        />
      </div>
    );
  }, [inSalesforce]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.detailsWrapper}>
        {paymentsNode}
        {commentsNode}
      </div>
      <Button
        className={styles.actionButton}
        type="link"
        icon={<EyesLine />}
        onClick={() => openCommissionDrawer(COMMISSION_DRAWER_TABS_IDS.VARIABLES)}
      >
        {t('periodDealCommissionInfos.viewDetails')}
      </Button>
    </div>
  );
};

PeriodDealCommissionInfos.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
  displayPayments: PropTypes.bool,
  inSalesforce: PropTypes.bool,
};

PeriodDealCommissionInfos.defaultProps = {
  className: '',
  displayPayments: true,
  inSalesforce: false,
};

export default PeriodDealCommissionInfos;
