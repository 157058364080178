import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import UsersBreadcrumb from '@palette/components/user/UsersBreadcrumb/UsersBreadcrumb';
import UserDetailsPageContent from '@palette/components/user/UserDetailsPageContent/UserDetailsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useUserIdInParams } from '@palette/hooks/UserHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './UserDetailsPage.less';

const UserDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { user } = useUserIdInParams();

  if (!hasAllRights(profile, [RIGHTS.ADMIN.USERS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.userDetailsTitle', { displayName: user?.displayName })} />
      <UsersBreadcrumb className={styles.breadcrumb} user={user} />
      <UserDetailsPageContent />
    </div>
  );
};

export default UserDetailsPage;
