import PropTypes from 'prop-types';

import { manageBooleanAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'StatementHistoryUser';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  displayName: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  isImpersonated: PropTypes.bool,
});

export const getDisplayName = (firstName, lastName, email) => {
  const displayName = `${firstName} ${lastName}`.trim();

  return displayName.length === 0 ? email : displayName;
};

export const transform = (author) => {
  if (!author) {
    return null;
  }

  const firstName = manageStringAttribute(author, 'firstName');
  const lastName = manageStringAttribute(author, 'lastName');
  const email = manageStringAttribute(author, 'email');

  return (
    {
      id: manageStringAttribute(author, 'id'),
      firstName,
      lastName,
      email,
      displayName: getDisplayName(firstName, lastName, email),
      profilePictureUrl: manageStringAttribute(author, 'profilePictureUrl', null),
      isImpersonated: manageBooleanAttribute(author, 'isImpersonated'),
    }
  );
};

export const transformList = (authors) => authors.map((author) => transform(author));
