import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import EditablePeriodYear from '@palette/components/frequencies/EditablePeriodYear/EditablePeriodYear';

import { useYear } from '@palette/hooks/QuotaHooks';

import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';

import { QUOTA_ACTIONS } from '@palette/constants/quotas';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './AddQuotaUserLeaveDateModal.less';

const AddQuotaUserLeaveDateModal = ({ visible, onClose, quotaUserId, quotaId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [yearInLocation] = useYear();

  const quotaUser = useSelector((state) => QuotasSelectors.getQuotaUserByIdAndQuotaId(state, { quotaId, quotaUserId }));
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));

  const removeUserFromQuotaIsPending = useSelector(QuotasSelectors.removeUserFromQuotaIsPending);

  const initialPeriod = quotaUser.lastPeriod ? quotaUser.lastPeriod.period : 1;
  const initialYear = quotaUser.lastPeriod ? quotaUser.lastPeriod.year : yearInLocation;
  const [lastPeriod, setLastPeriod] = useState({ period: initialPeriod, year: initialYear });

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
  };

  const handleAddQuotaUserLeaveDate = () => {
    dispatch(QuotasActions.updateUserFromQuota({
      quotaId: quota.id,
      quotaUser,
      lastPeriod,
      action: QUOTA_ACTIONS.UPDATE_USER_LAST_PERIOD,
      onSuccessCB,
    }));
  };

  const handlePeriodYearUpdate = ({ period, year }) => {
    setLastPeriod({ period, year });
  };

  const getEditablePeriodYearContentDisplay = (periodType, frequency, year, period, beginDate) => {
    const periodName = getFrequencyPeriodName(periodType, frequency, year, period, beginDate);

    return t('periodYear.from', { periodName });
  };

  return (
    <Modal
      className={styles.modal}
      title={t('quota.addQuotaUserLeaveDate.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleAddQuotaUserLeaveDate}
      okText={t('quota.addQuotaUserLeaveDate.save')}
      loading={removeUserFromQuotaIsPending}
    >
      <div className={styles.wrapper}>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: t('quota.addQuotaUserLeaveDate.description', { userDisplayName: quotaUser.user.displayName, quotaName: quota.name }) }} />
        <EditablePeriodYear
          className={styles.periodWrapper}
          contentClassName={styles.editablePeriodYearContent}
          popoverVisibleClassName={styles.popoverVisible}
          periodType={quota.periodType}
          frequency={quota.frequency}
          initialPeriod={initialPeriod}
          initialYear={initialYear}
          beginDate={quota.beginAt}
          onUpdate={handlePeriodYearUpdate}
          getContentDisplay={getEditablePeriodYearContentDisplay}
        />
      </div>
    </Modal>
  );
};

AddQuotaUserLeaveDateModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  quotaUserId: PropTypes.string.isRequired,
  quotaId: PropTypes.string.isRequired,
};

AddQuotaUserLeaveDateModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default AddQuotaUserLeaveDateModal;
