import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import MasterPlanList from '@palette/components/masterPlan/MasterPlanList/MasterPlanList';
import Input from '@palette/components/designSystem/Input/Input';
import Link from '@palette/components/designSystem/Link/Link';
import CompareFilled from '@palette/components/utils/Icons/CompareFilled';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import FolderOpenFilled from '@palette/components/utils/Icons/FolderOpenFilled';
import Button from '@palette/components/designSystem/Button/Button';
import CreateEditFolderModal from '@palette/components/folders/CreateEditFolderModal/CreateEditFolderModal';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight, hasAllRights } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { FOLDER_TYPES } from '@palette/constants/folders';
import { RIGHTS } from '@palette/constants/profile';
import { PLANS_PAGE_CONTENT_TABS_IDS, PLANS_PAGE_CONTENT_QS_KEY } from '@palette/constants/tabs';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as FoldersActions } from '@palette/state/Folders';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlansPageContent.less';

const classNames = bindClassNames.bind(styles);

const MasterPlansPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();
  const [locationTab] = useKeyInSearch(PLANS_PAGE_CONTENT_QS_KEY);

  const [searchedPlan, setSearchedPlan] = useState('');
  const [createEditFolderModalIsVisible, showCreateEditFolderModal] = useState(false);
  const [currentTab, setCurrentTab] = useState('');
  const [listLoaded, setListLoaded] = useState(false);
  const [lastPeriodsLoaded, setLastPeriodsLoaded] = useState({});

  const stats = useSelector(MasterPlansSelectors.getListStats);
  const getListIsPending = useSelector(MasterPlansSelectors.getListIsPending);
  const shouldRequestLastPeriods = useSelector(MasterPlansSelectors.shouldRequestLastPeriods);

  const handleGetLastPeriodsSuccessCB = useCallback(() => {
    if (currentTab) {
      setLastPeriodsLoaded({
        ...lastPeriodsLoaded,
        [currentTab]: true,
      });
    }
  }, [currentTab, lastPeriodsLoaded]);

  const getListStatsByTabType = useCallback((type) => {
    if (
      (type === PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE || type === PLANS_PAGE_CONTENT_TABS_IDS.PAST)
      && !lastPeriodsLoaded[type]
    ) {
      dispatch(MasterPlansActions.getListLastPeriods({ type, onSuccessCB: handleGetLastPeriodsSuccessCB }));
    }
  }, [lastPeriodsLoaded, handleGetLastPeriodsSuccessCB]);

  const handleGetListSuccessCB = useCallback(() => {
    setListLoaded(true);
    getListStatsByTabType(currentTab);
  }, [currentTab]);

  useEffect(() => {
    if (!currentTab && !locationTab) {
      setCurrentTab(PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE);
    } else if (locationTab && (locationTab !== currentTab)) {
      setCurrentTab(locationTab);
    }

    if ((currentTab === locationTab) || (!locationTab && currentTab === PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE)) {
      if (listLoaded) {
        getListStatsByTabType(currentTab);
      } else {
        dispatch(MasterPlansActions.getList({ onSuccessCB: handleGetListSuccessCB }));

        if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.VIEW_ALL, RIGHTS.ADMIN.TEAMS.VIEW_SCOPED])) {
          dispatch(FoldersActions.getList({ type: FOLDER_TYPES.PLAN }));
        }
      }
    }
  }, [currentTab, locationTab]);

  useEffect(() => {
    if (shouldRequestLastPeriods) {
      setLastPeriodsLoaded({});
    }
  }, [shouldRequestLastPeriods]);

  useEffect(() => {
    const activePeriodsLoaded = PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE in lastPeriodsLoaded;
    const pastPeriodsLoaded = PLANS_PAGE_CONTENT_TABS_IDS.PAST in lastPeriodsLoaded;

    if (activePeriodsLoaded && pastPeriodsLoaded) {
      dispatch(MasterPlansActions.resetShouldRequestLastPeriods());
    } else if (!activePeriodsLoaded && !pastPeriodsLoaded) {
      getListStatsByTabType(currentTab);
    }
  }, [lastPeriodsLoaded]);

  const getTabPropsByType = useCallback((type) => ({
    title: t(`masterPlansPageContent.tabs.${type}`),
    titleCount: stats[type] || 0,
    content: (
      <MasterPlanList listType={type} searchedPlan={searchedPlan} />
    ),
    defaultActive: type === PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE,
    tabKey: type,
  }), [stats, searchedPlan]);

  const tabs = useMemo(() => {
    const finalTabsIds = [PLANS_PAGE_CONTENT_TABS_IDS.ACTIVE, PLANS_PAGE_CONTENT_TABS_IDS.PAST];

    /**
     * Do not display Draft tab if profile doesn't have the good rights or if no plan inside
     */
    if (
      hasAllRights(profile, [
        RIGHTS.ADMIN.PLANS.UPDATE,
        RIGHTS.ADMIN.PLANS.GBO.MANAGE,
        RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
        RIGHTS.ADMIN.QUOTAS.VIEW,
        RIGHTS.ADMIN.QUOTAS.MANAGE,
        RIGHTS.ADMIN.QUOTAS.USERS.MANAGE,
      ])
      && !getListIsPending
      && stats[PLANS_PAGE_CONTENT_TABS_IDS.DRAFT] !== 0
    ) {
      finalTabsIds.push(PLANS_PAGE_CONTENT_TABS_IDS.DRAFT);
    }

    /**
     * Do not display Archived tab if no plan inside
     */
    if (!getListIsPending && stats[PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED] !== 0) {
      finalTabsIds.push(PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED);
    }

    return finalTabsIds.map((type) => getTabPropsByType(type));
  }, [stats, getListIsPending, getTabPropsByType]);

  const handleTabChange = (key) => setCurrentTab(Object.values(PLANS_PAGE_CONTENT_TABS_IDS)[key]);

  const handleCreatePlanClick = () => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.CLICK_CREATE_PLAN }));
    redirectTo({ path: routePaths.v2.createPlanFlow });
  };

  const handleComparePlanClick = () => {
    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PLAN_LIST.CLICK_COMPARE_PLAN }));
  };

  const actionsNodes = [
    (
      <Link
        key="compare"
        className={styles.action}
        path={routePaths.v2.comparator}
        onClick={handleComparePlanClick}
      >
        <CompareFilled className={styles.actionIcon} />
        <div className={styles.actionLabel}>
          {t('masterPlansPageContent.tabs.compare')}
        </div>
      </Link>
    ),
  ];

  const toolboxNode = useMemo(() => (
    <div
      className={classNames({
        toolbox: true,
        hasDraftTab: stats[PLANS_PAGE_CONTENT_TABS_IDS.DRAFT] > 0,
        hasArchivedTab: stats[PLANS_PAGE_CONTENT_TABS_IDS.ARCHIVED] > 0,
      })}
    >
      <Input
        className={styles.search}
        type="search"
        placeholder={t('masterPlansPageContent.planSearch.placeholder')}
        onChange={setSearchedPlan}
        value={searchedPlan}
      />
      <div className={styles.actionsWrapper}>
        {actionsNodes}
      </div>
    </div>
  ), [stats, setSearchedPlan, searchedPlan, actionsNodes]);

  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE])) {
    actionsNodes.unshift((
      <Button
        className={styles.actionBtn}
        key="createPlanFlow"
        type="primaryBlue"
        icon={<AddFilled />}
        onClick={handleCreatePlanClick}
      >
        {t('masterPlansPageContent.tabs.createPlanFlow')}
      </Button>
    ));

    if (currentTab !== PLANS_PAGE_CONTENT_TABS_IDS.DRAFT && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])) {
      actionsNodes.push((
        <Button
          key="createNewFolder"
          className={styles.action}
          type="link"
          icon={<FolderOpenFilled className={styles.actionIcon} />}
          onClick={() => showCreateEditFolderModal(true)}
        >
          <div className={styles.actionLabel}>
            {t('masterPlansPageContent.tabs.createNewFolder')}
          </div>
        </Button>
      ));
    }
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <DndProvider backend={HTML5Backend}>
        <Tabs
          className={styles.tabs}
          tabs={tabs}
          onChange={handleTabChange}
          qsTabKey={PLANS_PAGE_CONTENT_QS_KEY}
          tabBarExtraContent={toolboxNode}
        />
      </DndProvider>
      {
        createEditFolderModalIsVisible
        && hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE])
        && currentTab !== PLANS_PAGE_CONTENT_TABS_IDS.DRAFT
        && (
          <CreateEditFolderModal
            visible
            onClose={() => showCreateEditFolderModal(false)}
          />
        )
      }
    </div>
  );
};

MasterPlansPageContent.propTypes = {
  className: PropTypes.string,
};

MasterPlansPageContent.defaultProps = {
  className: '',
};

export default MasterPlansPageContent;
