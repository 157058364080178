import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Switch from '@palette/components/designSystem/Switch/Switch';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import ArrowRightSquareLine from '@palette/components/utils/Icons/ArrowRightSquareLine';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import {
  NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES,
  NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS,
} from '@palette/constants/notebooks';

import * as NotebookDataSelectorBlockListItemModel from '@palette/models/NotebookDataSelectorBlockListItem';

import styles from './AddDataConnectionDataSelectorBlockListItem.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionDataSelectorBlockListItem = ({
  className,
  item,
  isOpened,
  isSelected,
  onOpen,
  onSelectChange,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    if (disabled) return;

    if (item.type !== NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.SELECTABLE && onOpen !== null) {
      onOpen(item.key);
    }
  }, [item, onOpen, disabled]);

  const handleSelect = useCallback((checked) => {
    if (disabled) return;

    if (onSelectChange !== null) onSelectChange(item.key, checked);
  }, [item, onSelectChange, disabled]);

  const iconNode = useMemo(() => {
    const TypeIcon = NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES_ICONS[item.type];

    if (item.type === NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.RESOURCE) {
      return (
        <TypeIcon className={styles.icon} isOpened={isOpened} />
      );
    }

    let tooltipLabel = t('notebooks.addDataConnectionBlockListItem.types.column');
    if (item.type === NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.ARRAY) {
      tooltipLabel = t('notebooks.addDataConnectionBlockListItem.types.array');
    }
    if (item.type === NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.OBJECT) {
      tooltipLabel = t('notebooks.addDataConnectionBlockListItem.types.object');
    }

    return (
      <Tooltip title={tooltipLabel}>
        <TypeIcon className={styles.icon} />
      </Tooltip>
    );
  }, [item, isOpened]);

  const labelNode = useMemo(() => (
    <div className={styles.label}>
      {item.label}
    </div>
  ), [item]);

  const actionNode = useMemo(() => {
    if (item.type !== NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.RESOURCE) {
      return (
        <Switch
          className={styles.action}
          type="primaryTeal"
          checked={isSelected}
          onChange={handleSelect}
          disabled={disabled}
        />
      );
    }

    if (isOpened) {
      return (
        <ArrowRightSquareLine
          className={classNames({
            action: true,
            actionIcon: true,
          })}
        />
      );
    }

    return (
      <ChevronRightLine
        className={classNames({
          action: true,
          actionIcon: true,
        })}
      />
    );
  }, [
    item,
    handleSelect,
    isSelected,
    isOpened,
    disabled,
  ]);

  const contentNode = useMemo(() => (
    <div
      className={classNames({
        wrapper: true,
        isOpened,
        isSelected,
        isResource: item.type === NOTEBOOK_DATA_SELECTOR_BLOCK_LIST_ITEM_TYPES.RESOURCE,
        disabled: disabled && !isOpened,
        [className]: className !== '',
      })}
      onClick={handleClick}
    >
      {iconNode}
      {labelNode}
      {actionNode}
    </div>
  ), [
    isOpened,
    isSelected,
    item,
    disabled,
    handleClick,
    iconNode,
    labelNode,
    actionNode,
  ]);

  const containerNode = useMemo(() => {
    if (disabled && !isOpened) {
      return (
        <Tooltip title={t('notebooks.addDataConnectionBlockListItem.disabled')}>
          {contentNode}
        </Tooltip>
      );
    }

    return contentNode;
  }, [disabled, isOpened, contentNode]);

  return containerNode;
};

AddDataConnectionDataSelectorBlockListItem.propTypes = {
  className: PropTypes.string,
  item: NotebookDataSelectorBlockListItemModel.propTypes.isRequired,
  isOpened: PropTypes.bool,
  isSelected: PropTypes.bool,
  onOpen: PropTypes.func,
  onSelectChange: PropTypes.func,
  disabled: PropTypes.bool,
};

AddDataConnectionDataSelectorBlockListItem.defaultProps = {
  className: '',
  isOpened: false,
  isSelected: false,
  onOpen: null,
  onSelectChange: null,
  disabled: false,
};

export default AddDataConnectionDataSelectorBlockListItem;
