import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import MyMasterPlanDetailsPageContent from '@palette/components/ic/myMasterPlan/MyMasterPlanDetailsPageContent/MyMasterPlanDetailsPageContent';
import MyMasterPlanBreadcrumb from '@palette/components/ic/myMasterPlan/MyMasterPlanBreadcrumb/MyMasterPlanBreadcrumb';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useICMasterPlanInParams } from '@palette/hooks/MasterPlanHooks';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { RIGHTS } from '@palette/constants/profile';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import styles from './MyMasterPlanDetailsPage.less';

const MyMasterPlanDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { plan } = useICMasterPlanInParams();

  const moment = getMoment();

  useEffect(() => {
    if (plan !== null && plan.periodType === PERIOD_TYPES.CUSTOM) {
      const customBeginAtMomentDate = moment(plan.customBeginAt).utc();
      redirectTo({ path: routePaths.v2.myPlanPeriodDetails, params: { masterPlanId: plan.id, year: customBeginAtMomentDate.year(), periodId: 1 } });
    }
  }, [plan]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.myMasterPlansDetailsTitle', { planName: plan?.name || '' })} />
      <MyMasterPlanBreadcrumb className={styles.breadcrumb} plan={plan} />
      <PageTitle>
        <h3>
          {plan?.name || ''}
        </h3>
      </PageTitle>
      <MyMasterPlanDetailsPageContent />
    </div>
  );
};

export default MyMasterPlanDetailsPage;
