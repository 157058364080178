import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import StatementV3UserStatementCommissionsItem from '@palette/components/ic/statementV3/StatementV3UserStatementCommissionsItem/StatementV3UserStatementCommissionsItem';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';

import styles from './StatementV3UserStatementComissions.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UserStatementComissions = ({ className, statement }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profile = useProfile();

  const listCommissions = useSelector(StatementV3Selectors.getListCommissions);
  const listCommissionsIsPending = useSelector(StatementV3Selectors.getListCommissionsIsPending);

  useEffect(() => {
    dispatch(StatementV3Actions.getListCommissions({ statementId: statement.id, isIC: true }));
  }, [statement]);

  const contentNode = useMemo(() => {
    if (!listCommissions && !listCommissionsIsPending) return null;

    if (listCommissionsIsPending) return <Loader />;

    const count = listCommissions.length;

    const title = (
      <div className={styles.title}>
        {t('userStatementV3.commissions.collapse.title', { count })}
      </div>
    );

    let content;

    if (count === 0) {
      content = <DefaultEmptyState />;
    } else {
      content = [(
        <div className={styles.commissionsListHeader} key="commissions-list-header">
          <span className={styles.colRow}>{t('userStatementV3.commissions.header.plan')}</span>
          <span className={styles.colRow}>{t('userStatementV3.commissions.header.dealId')}</span>
          <span className={styles.colRow}>{t('userStatementV3.commissions.header.amount')}</span>
          <span className={styles.colRow}>{t('userStatementV3.commissions.header.date')}</span>
        </div>
      )];

      content.push(listCommissions.map((commission) => (
        <StatementV3UserStatementCommissionsItem
          key={commission.id}
          statement={statement}
          commission={commission}
        />
      )));
    }

    return (
      <Collapse
        className={styles.collapseContainer}
        panels={[{
          title,
          content,
        }]}
        keyValue={statement.id}
        defaultActiveKey={count > 0 ? statement.id : undefined}
      />
    );
  }, [profile, statement, listCommissions, listCommissionsIsPending]);

  if (!statement) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementV3UserStatementComissions.propTypes = {
  className: PropTypes.string,
  statement: StatementV3OneStatementModel.propTypes.isRequired,
};

StatementV3UserStatementComissions.defaultProps = {
  className: '',
};

export default StatementV3UserStatementComissions;
