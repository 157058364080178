import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  PRESET_404_PRESET_NOT_FOUND: {
    code: 'dashboardPreset.errors.preset.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PRESET_404_PLAN_NOT_FOUND: {
    code: 'dashboardPreset.errors.plan.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PRESET_404_OBJECT_NOT_FOUND: {
    code: 'dashboardPreset.errors.object.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PRESET_404_USER_NOT_FOUND: {
    code: 'dashboardPreset.errors.user.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PRESET_409_CANNOT_DELETE_DEFAULT: {
    code: 'dashboardPreset.errors.preset.default.cannot.delete',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PRESET_ADD_WIDGET_SUCCESS: {
    code: 'notifications.dashboard.success.addWidget',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  PRESET_APPLY_TO_USERS_SUCCESS: {
    code: 'notifications.dashboard.success.applyDashboardPresetToUsers',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
