/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Card, DatePicker, Divider, Form, Input, message, Tooltip, Alert,
  Collapse, Popconfirm, Popover,
  Switch, Modal, Row, Col, Tag, Checkbox, Typography,
} from 'antd';
import flatten from 'flat';
import {
  InfoCircleOutlined, PlusOutlined, LoadingOutlined, SyncOutlined,
  SaveOutlined, UpOutlined, DownOutlined, FileSearchOutlined,
  FileTextOutlined, DeleteOutlined, UserOutlined, EditOutlined,
} from '@ant-design/icons';
import Moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import pluralize from 'pluralize';

import { connect } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import ResourcesLastSyncDates from '@palette/components/connector/ResourcesLastSyncDates/ResourcesLastSyncDates';
import ResourceConfiguration from '@palette/components/connector/ResourceConfiguration/ResourceConfiguration';
import ProfileFilled from '@palette/components/utils/Icons/ProfileFilled';
import CloseSimpleLine from '@palette/components/utils/Icons/CloseSimpleLine';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import {
  checkSFObject,
  getConnectorById, getObjectsAvailableForConnector, retrieveSFReportByName, retrieveSheet,
  updateConnector, syncConnector, revoke, deleteResource,
} from '../../../services/connector';
import FullScreenLoader from '../../../components/loader';
import '../../../styles/admin/connector-details.less';
import UploadCSVModal from '../../../components/admin/uploadCSVModal';
import {
  getDisplayType, isReport, parseSearch, waitJob,
  getConnectorName, toVariableName,
} from '../../../services/utils';
import { listObjects } from '../../../services/admin/objects';
import { getProfile } from '../../../services/user';
import QuickBooksOriginalButton from '../../../components/QuickBooksOriginalButton';
import ColumnEditor from '../../../components/admin/column-editor';
import { sendClickOnDocLink } from '../../../services/analytics';
import { ANALYTICS_DOC_SOURCES } from '../../../services/constants';

class ConnectorDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      connector: null,
      objectTypes: null,
      showUploadModal: false,
      showAdvancedSettings: false,
      fields: null,
      customizedList: [],
      requiredFieldsByType: [],
      openCollasped: [],
      showEvalFnModal: null,
      addNewResourceGsheetModale: false,
      addNewResourceMySQLModale: false,
      editSQLQuery: null,
      defineUserTypeModale: false,
      newResourceFormValues: {},
      addGSheetResourceUser: false,
    };
    this.SFFormRef = React.createRef();
    this.SFFormCORef = React.createRef();
    this.SFFormSheetRef = React.createRef();
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      Promise.all([
        getConnectorById(this.props.match.params.connectorId),
        getObjectsAvailableForConnector(),
      ])
        .then(([{ data: connector }, { data: objectTypes }]) => {
          const allTypesForConnector = Object.values([
            ...(objectTypes[connector.type] || []), ...connector.objects,
          ]).map((e) => e.type).reduce((acc, element) => {
            if (!acc.includes(element)) {
              return [...acc, element];
            }
            return acc;
          }, []);
          const promisesObjects = connector.type === 'CSV' ? connector.objects.map((object) => listObjects({
            connectorId: connector._id,
            type: object.type,
          })) : allTypesForConnector.map((obj) => listObjects({
            connectorId: connector._id,
            type: obj,
          }));
          Promise.all(promisesObjects).then((allObjects) => {
            const fieldsData = (allObjects || []).map((a) => a.data.objects);
            const fieldData = fieldsData
              .reduce((acc, element, index) => ({
                ...acc,
                [allTypesForConnector[index]]: element
                  .reduce((ac, el) => ({ ...ac, ...el.data }), {}),
              }), {});
            const overwrites = fieldsData
              .reduce((acc, element, index) => ({
                ...acc,
                [allTypesForConnector[index]]: element
                  .reduce((ac, el) => [
                    ...ac,
                    ...(el.overwrites ? el.overwrites.reduce((accu, ov) => [
                      ...accu,
                      ...(!accu.includes(ov.fragments[0]) ? ov.fragments : ''),
                    ], []) : ''),
                  ], []),
              }), {});
            const requiredFieldsByType = objectTypes?.[connector.type]
              ?.reduce((acc, element) => ({
                ...acc,
                [element.type]: element.columns.reduce((ac, el) => [...ac, ...el.fields], []),
              }), {});
            const buildTypes = connector.type === 'CSV' ? {
              CSV: connector.objects.map((co) => ({
                ...co,
                isChecked: true,
                columns: (co.columns || [])
                  .map((c) => ({ ...c, uuid: uuidv4() })),
                default: false,
              })),
            } : {
              ...objectTypes,
              [connector.type]: (objectTypes[connector.type] || []).reduce((acc, element) => {
                const typeValues = connector.objects.find((o) => o.type === element.type);
                return [
                  ...acc,
                  {
                    ...element,
                    ...typeValues,
                    ...(typeValues ? {
                      columns: (typeValues.columns || [])
                        .map((c) => ({ ...c, uuid: uuidv4() })),
                    } : {}),
                    isChecked: !!typeValues,
                    default: true,
                    disableConfiguration: !objectTypes?.[connector.type]?.find((item) => item.type === element.type)?.isConfigurable,
                  }];
              }, []),
            };
            const alltypes = (buildTypes[connector.type]).map((o) => o.type);
            const missing = connector.objects
              .filter((o) => !alltypes.includes(o.type))
              .map((o) => ({
                ...o,
                isChecked: true,
                isRequired: true,
                default: false,
              }));
            buildTypes[connector.type].push(...missing);
            const { type } = parseSearch(this.props.location.search);
            this.setState({
              connector: {
                ...connector,
                beginSyncDate: connector.beginSyncDate || null,
              },
              objectTypes: buildTypes,
              shouldHideData: connector.type === 'CSV' && (!allObjects || !allObjects[0]),
              fields: fieldData,
              customizedList: overwrites,
              requiredFieldsByType,
              loading: false,
              openCollasped: (type && type.length > 0) ? [type] : [],
            });
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
          message.error('Error while loading connector details');
        });
    });
  };

  save = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      updateConnector({
        connectorId: this.props.match.params.connectorId,
        beginSyncDate: this.state.connector.beginSyncDate,
        objects: this.state.objectTypes[this.state.connector.type]
          .filter((type) => type.isChecked || type.isRequired),
      })
        .then(() => {
          getProfile();
          this.setState(
            { loading: false, showAdvancedSettings: false },
            this.fetch,
          );
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
          message.error('Error while updating connector');
        });
    });
  };

  onObjectCheck = (e, object) => {
    const { connector } = this.state;
    const newCollapsed = [...this.state.openCollasped];
    if (e && !this.state.openCollasped.includes(object.type)) {
      newCollapsed.push(object.type);
    }
    this.setState((prevState) => ({
      openCollasped: e
        ? newCollapsed
        : newCollapsed.filter((p) => p !== object.type),
      objectTypes: {
        ...prevState.objectTypes,
        [connector.type]: prevState.objectTypes[connector.type].reduce((acc, element) => [
          ...acc,
          object.type === element.type ? { ...element, isChecked: !element.isChecked } : element,
        ], []),
      },
    }));
  };

  onColumnChange = (val, objectIndex, columnIndex, columns, key) => {
    const { connector, objectTypes } = this.state;
    const newColumns = [...objectTypes[connector.type][objectIndex][columns]];
    const col = newColumns.map((c, i) => (i === columnIndex ? { ...c, [key]: val } : c));
    this.setState((prevState) => ({
      objectTypes:
      {
        ...prevState.objectTypes,
        [connector.type]: prevState.objectTypes[connector.type]
          .reduce((acc, element, index) => [...acc, index === objectIndex ? {
            ...prevState.objectTypes[connector.type][objectIndex],
            [columns]: col,
          } : element], []),
      },
    }));
  };

  updateDate = (date) => {
    const { connector } = this.state;
    connector.beginSyncDate = date ? date.toISOString() : null;
    this.setState({ connector });
  };

  addReport = ({ reportName }) => {
    this.setState({ loading: true }, () => {
      retrieveSFReportByName(this.props.match.params.connectorId, reportName)
        .then(({ data: report }) => {
          if (report) {
            if (report.itemCount >= 2000) {
              message.error('Your report cannot rely on more than 2000 CRM Objects.');
              this.setState({ loading: false });
              return;
            }
            const { objectTypes, connector } = this.state;
            objectTypes[connector.type].push({
              type: `ReportItem_${report.Id}_${report.report.reportMetadata.reportType.type}`,
              name: report.Name,
              columns: [],
              isRequired: true,
              isChecked: true,
            });
            this.setState({ objectTypes, loading: false });
            this.SFFormRef.current.resetFields();
          } else {
            message.error(`No report found for name: ${reportName}`);
            this.setState({ loading: false });
          }
        })
        .catch(() => {
          message.error('Error while requesting SalesForce.');
          this.setState({ loading: false });
        });
    });
  };

  addSheet = ({ spreadsheetId, range, type, ...otherValues }) => {
    if (!spreadsheetId || !range || !type) return;
    const { objectTypes, connector } = this.state;
    const prevType = objectTypes[connector.type].find((o) => o.type === type);
    if (prevType) {
      message.error(`Type "${type}" already defined.`);
      return;
    }
    const prevSheet = objectTypes[connector.type]
      .find((o) => o.spreadsheetId === spreadsheetId && o.range === range);
    if (prevSheet) {
      message.error('You already sync this sheet.');
      return;
    }
    this.setState({ loading: true }, () => {
      retrieveSheet(spreadsheetId, range)
        .then(() => {
          objectTypes[connector.type].push({
            type,
            spreadsheetId,
            range,
            columns: [],
            isRequired: true,
            isChecked: true,
            isUser: this.state.addGSheetResourceUser,
            ...(this.state.addGSheetResourceUser ? {
              firstNameFormula: otherValues.firstNameFormula,
              lastNameFormula: otherValues.lastNameFormula,
              emailFormula: otherValues.emailFormula,
            } : {}),
          });
          this.setState({
            objectTypes,
            loading: false,
            newResourceFormValues: {},
            addGSheetResourceUser: false,
          }, () => {
            this.SFFormSheetRef.current.resetFields();
            this.setState({
              addNewResourceGsheetModale: false,
            });
            this.save();
          });
        })
        .catch(() => {
          message.error('GSheet request error, please ensure that you shared your sheet with gsheet@palettehq.com');
          this.setState({ loading: false });
        });
    });
  };

  addCO = ({ type }) => {
    if (!type) return;
    this.setState({ loading: true }, () => {
      checkSFObject(this.props.match.params.connectorId, type)
        .then(() => {
          const { objectTypes, connector } = this.state;
          const prev = objectTypes[connector.type].find((o) => o.type === type);
          if (prev) {
            message.error(`${type} already in the object list`);
            return;
          }
          objectTypes[connector.type].push({
            type,
            columns: [{
              formatter: 'String',
              name: 'Id',
              fields: ['Id'],
              uuid: uuidv4(),
              resources: true,
            }],
            isRequired: true,
            isChecked: true,
          });
          this.setState({ objectTypes, loading: false });
          this.SFFormCORef.current.resetFields();
        })
        .catch(() => {
          message.error('Object type not found in SalesForce.');
          this.setState({ loading: false });
        });
    });
  };

  onEvalFnUpdate = ({ evalFn }) => {
    this.onColumnChange(evalFn, this.state.showEvalFnModal.objectIndex, this.state.showEvalFnModal.columnIndex, 'columns', 'evalFn');
    this.setState({ showEvalFnModal: null });
  };

  toggleAdvancedSettings = () => this.setState((prevState) => ({
    showAdvancedSettings: !prevState.showAdvancedSettings,
  }));

  onChangeCollapse = (params) => this.setState({ openCollasped: params });

  openAddNewResourceGsheet = () => this.setState({ addNewResourceGsheetModale: true });

  openAddNewResourceMySQL = () => this.setState({ addNewResourceMySQLModale: true });

  syncNewTableSQL = (object) => {
    const { connector } = this.state;
    this.setState((prevState) => ({
      objectTypes: {
        ...prevState.objectTypes,
        [connector.type]: [
          ...prevState.objectTypes[connector.type].filter((o) => o.type !== object.type),
          {
            ...object,
            isRequired: true,
            columns: prevState.editSQLQuery ? prevState.editSQLQuery.columns : [],
            isUser: prevState.addGSheetResourceUser,
            ...(prevState.addGSheetResourceUser ? {
              firstNameFormula: object.firstNameFormula,
              lastNameFormula: object.lastNameFormula,
              emailFormula: object.emailFormula,
            } : {}),
          },
        ],
      },
      addNewResourceMySQLModale: false,
      addGSheetResourceUser: false,
      editSQLQuery: null,

    }), () => {
      this.onObjectCheck({}, object);
      this.save();
    });
  };

  editSQLType = (object) => this.setState({
    addNewResourceMySQLModale: true,
    editSQLQuery: object,
    addGSheetResourceUser: object.isUser,
  });

  isSQLConnector = (type) => (type === 'MYSQL' || type === 'MSSQL' || type === 'BIG_QUERY' || type === 'SNOWFLAKE' || type === 'REDSHIFT');

  defineObjectAsUserType = (object) => this.setState({ defineUserTypeModale: object });

  onCancelDefineUserType = () => this.setState({ defineUserTypeModale: false });

  defineUserType = (values) => {
    const { connector, defineUserTypeModale: object } = this.state;
    this.setState((prevState) => ({
      objectTypes: {
        ...prevState.objectTypes,
        [connector.type]: prevState.objectTypes[connector.type].reduce((acc, element) => [
          ...acc,
          object.type === element.type ? { ...element, ...values, isUser: true } : element,
        ], []),
      },
      defineUserTypeModale: false,
    }), () => {
      this.save();
    });
  };

  notIsUser = () => {
    const { connector, defineUserTypeModale: object } = this.state;
    this.setState((prevState) => ({
      objectTypes: {
        ...prevState.objectTypes,
        [connector.type]: prevState.objectTypes[connector.type].reduce((acc, {
          firstNameFormula,
          lastNameFormula,
          emailFormula,
          ...element
        }) => [
          ...acc,
          object.type === element.type ? { ...element, isUser: false } : element,
        ], []),
      },
      defineUserTypeModale: false,
    }), this.save);
  };

  isCustomObject = (object) => {
    const { connector, objectTypes } = this.state;
    const customs = (connector && connector.type === 'SALESFORCE' && objectTypes)
      ? objectTypes[connector.type].filter(({ default: d, type }) => !isReport(type) && !d) : [];
    return customs.find((c) => c.type === object.type);
  };

  syncConnector = async (id) => {
    this.setState({ refreshLoading: id });
    try {
      const { data: job } = await syncConnector(id);
      await waitJob(job._id);
      message.success('Data synchronized.');
      this.fetch();
    } catch (error) {
      message.error('Error while syncing data');
    }
    this.setState({ refreshLoading: false });
  };

  disconnect = () => {
    this.setState({ loading: true }, () => {
      revoke(this.props.match.params.connectorId)
        .then(() => this.setState((prevState) => ({
          loading: false,
          connector: {
            ...prevState.connector,
            hasAccessToken: false,
          },
        })))
        .catch(() => {
          message.error('Error while disconnecting.');
          this.setState({ loading: false });
        });
    });
  };

  onNewResourceFormValuesChange = (changedValues, allValues) => {
    this.setState({
      newResourceFormValues: {
        ...allValues,
        type: toVariableName(allValues.type),
      },
    });
    if (changedValues.type) {
      this.SFFormSheetRef.current.setFieldsValue({
        type: toVariableName(changedValues.type),
      });
    }
  };

  async deleteResource(type) {
    const { connector } = this.state;
    await deleteResource({ connectorId: connector._id, type });
    await getProfile();
    this.fetch();
  }

  render() {
    const {
      loading,
      connector,
      objectTypes,
      showUploadModal,
      showAdvancedSettings,
      fields,
      customizedList,
      requiredFieldsByType,
      refreshLoading,
      openCollasped,
      showEvalFnModal,
      shouldHideData,
      addNewResourceGsheetModale,
      addNewResourceMySQLModale,
      editSQLQuery,
      defineUserTypeModale,
      addGSheetResourceUser,
    } = this.state;
    const canManage = hasAtLeastOneRight(this.props.profile, ['admin.connectors.manage']);
    const sfReports = (connector && objectTypes)
      ? objectTypes[connector.type].filter(({ type }) => isReport(type)) : [];
    const customObjects = (connector && connector.type === 'SALESFORCE' && objectTypes)
      ? objectTypes[connector.type].filter(({ default: d, type }) => !isReport(type) && !d) : [];

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const newSyncElementType = this.state.newResourceFormValues?.type || 'User';
    const isResourceAUser = (
      <>
        <Col span={16} offset={8} style={{ marginTop: '12px', marginBottom: '12px' }}>
          <Checkbox
            className="Connector_details_is_user_checkbox"
            checked={addGSheetResourceUser}
            onChange={(e) => {
              this.setState({
                addGSheetResourceUser: e.target.checked,
              });
            }}
          >
            <ProfileFilled width={16} height={16} />
            &nbsp;
            Is this resource a user?
          </Checkbox>
        </Col>
        {addGSheetResourceUser && (
          <Col span={24} style={{ marginTop: '12px' }}>
            <div>
              <div>To display properly the user basic information in Palette, you need to fill-in the attributes to use as Firtname, Lastname and Email.</div>
              <Typography.Text mark>
                Please prefix every attribute by
                {' '}
                <b>
                  {`${newSyncElementType}.`}
                </b>
                {' '}
                in the formulas.
              </Typography.Text>
              <br />
              <br />
            </div>
            <Form.Item
              label="Define Firstname"
              name="firstNameFormula"
              rules={[
                {
                  required: true,
                  message: 'Please enter a firstname attribute to use.',
                },
              ]}
              extra={(
                <div>{`Ex: ${newSyncElementType}.firstname, ${newSyncElementType}.forename, ${newSyncElementType}.first_name.`}</div>
              )}
            >
              <Input placeholder="Enter the attribute to use as Firstname " />
            </Form.Item>
            <Form.Item
              label="Define Lastname"
              name="lastNameFormula"
              extra={(
                <div>{`Ex: ${newSyncElementType}.lastname, ${newSyncElementType}.last_name.`}</div>
              )}
              rules={[
                {
                  required: true,
                  message: 'Please enter a Lastname attribute to use.',
                },
              ]}
            >
              <Input placeholder="Enter the attribute to use as Lastname." />
            </Form.Item>
            <Form.Item
              label="Define Email"
              name="emailFormula"
              extra={(
                <div>{`Ex: ${newSyncElementType}.email, ${newSyncElementType}.EmailAddress.`}</div>
              )}
              rules={[
                {
                  required: true,
                  message: 'Please enter an Email attribute to use.',
                },
              ]}
            >
              <Input
                placeholder="Enter the attribute to use as Email"
              />
            </Form.Item>
          </Col>
        )}
      </>
    );

    return (
      <div className="div-wrapper">
        <div className="container">
          <div className="container-inner">
            {connector && objectTypes && (
              <>
                <MetaTag title={getConnectorName(connector.type)} />
                <Card
                  className={`Connector_details_card Connector_${connector.type}`}
                  title={(
                    <div>
                      {connector.type !== 'CSV' && (
                        <div className="Connector_details_vignette_refresh">
                          <Popover
                            placement="bottom"
                            content={(
                              <div className="center">
                                <div>
                                  Click to refresh the data from
                                  {' '}
                                  {getConnectorName(connector.type)}
                                </div>
                              </div>

                            )}
                          >
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => this.syncConnector(connector._id)}
                            >
                              <Tag
                                color="transparent"
                                style={{
                                  border: '1px solid #ffffff54',
                                  marginRight: '0',
                                }}
                              >
                                {refreshLoading === connector._id
                                  ? <LoadingOutlined /> : <SyncOutlined />}
                                &nbsp;
                                Sync
                              </Tag>

                            </div>
                          </Popover>
                        </div>
                      )}
                      <div className="Connector_details_label">CONNECTOR</div>
                      <div className="title bold center">{getConnectorName(connector.type)}</div>
                      {connector.name ? (
                        <div
                          style={{ marginBottom: '4px' }}
                          className="center"
                        >
                          <Tag color="blue">{connector.name}</Tag>
                        </div>
                      ) : null}
                      {connector.lastSync ? (
                        <div
                          className="center italic"
                          style={{ fontSize: '12px', color: 'white', letterSpacing: 'initial' }}
                        >
                          Last sync
                          {' '}
                          {Moment(connector.lastSync).format('MMMM Do YYYY - HH:mm')}
                        </div>
                      ) : null}
                      <br />
                    </div>
                  )}
                >
                  {!canManage && (
                    <Alert
                      message="You are not allowed to update connector data"
                      type="warning"
                      showIcon
                    />
                  )}
                  {connector.type === 'CSV' && canManage && (
                    <Button
                      style={{
                        width: '100%',
                        height: '60px',
                        marginBottom: '12px',
                      }}
                      icon={<PlusOutlined />}
                      type="primary"
                      ghost
                      onClick={() => this.setState({ showUploadModal: true })}
                    >
                      Upload CSV
                    </Button>
                  )}
                  {!shouldHideData && (
                    <div>
                      <div className="flex">
                        <div><h3 className="bold" style={{ margin: 0 }}>Resources</h3></div>
                        <div className="flex" style={{ flexGrow: '1', justifyContent: 'flex-end' }}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://doc.palettehq.com/connector-settings"
                            onClick={() => { sendClickOnDocLink(ANALYTICS_DOC_SOURCES.CONNECTOR_DETAILS_PAGE, this.props.profile, { connectorType: connector.type }); }}
                          >
                            <FileSearchOutlined />
                            {' '}
                            Documentation for Connectors
                          </a>
                        </div>
                      </div>

                      <div className="grey" style={{ marginBottom: '20px' }}>Select the Resources you want to synchronize with Palette.</div>
                      <Collapse
                        bordered={false}
                        expandIconPosition="right"
                        className="Connector_collapse"
                        activeKey={openCollasped}
                        onChange={this.onChangeCollapse}
                      >

                        {objectTypes[connector.type].map((object, objectIndex) => (
                          <Collapse.Panel
                            collapsible={object.isChecked ? null : 'disabled'}
                            key={`${object.type}${objectIndex}`}
                            header={(
                              <div className="flex">
                                <div style={{ flexGrow: '1', display: 'flex' }}>
                                  <span className="bold">
                                    <Tooltip
                                      title={object.isRequired && canManage && connector.type === 'SALESFORCE' && this.isCustomObject(object) ? 'This is a Salesforce custom object, if you want to remove it, go to advanced settings.' : null}
                                    >
                                      <Switch
                                        size="small"
                                        onClick={(c, e) => {
                                          if (e) { e.stopPropagation(); }
                                        }}
                                        disabled={object.isRequired || !canManage || connector.type === 'CSV'}
                                        onChange={(e) => this.onObjectCheck(e, object)}
                                        checked={object.isChecked}
                                        className={object.isChecked ? '' : 'grey'}
                                      />
                                    </Tooltip>

                                    &nbsp;
                                    {isReport(object.type) ? (
                                      <>
                                        {`${getDisplayType(object)} `}
                                        <Tooltip
                                          title={`from "${object.name}" report`}
                                        >
                                          <InfoCircleOutlined />
                                        </Tooltip>
                                      </>
                                    ) : object.type}
                                  </span>
                                  {['GSHEET', 'HUBSPOT', 'SALESFORCE', 'REDSHIFT'].includes(connector.type) && (
                                    <ResourceConfiguration
                                      className="Connector_configure_resource"
                                      connectorId={connector._id}
                                      resourceType={object.type}
                                      requiredFields={requiredFieldsByType?.[object.type]}
                                      isSynchronizing={refreshLoading === connector._id}
                                      onRefreshList={this.fetch}
                                      enabled={object.isChecked && !object.disableConfiguration}
                                    />
                                  )}
                                  {connector.type === 'GSHEET' && (
                                    <span style={{ marginLeft: '30px' }}>
                                      <a
                                        style={{ fontSize: '13px' }}
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        href={`https://docs.google.com/spreadsheets/d/${object.spreadsheetId}`}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <img
                                          style={{
                                            width: '12px',
                                            height: '12px',
                                          }}
                                          alt="external link"
                                          src="/img/external_icon.svg"
                                        />
                                        {` Open ${object.range} spreadsheet`}
                                      </a>
                                    </span>
                                  )}
                                </div>
                                <div className="grey">
                                  {`${object.columns.length > 0 ? `${object.columns.length} ${pluralize('column', object.columns.length)}` : ''}`}
                                </div>
                              </div>
                            )}
                          >
                            <div className="grey" style={{ marginBottom: '14px' }}>
                              Define the columns you want to display within Palette UI.
                              {' '}
                              <br />
                              Check the
                              {' '}
                              <b>Resources</b>
                              {' '}
                              checkbox if you want the column to be displayed
                              in the Resources page and check the
                              {' '}
                              <b>Plans</b>
                              {' '}
                              checkbox if you want to display the column in the plan details.
                            </div>
                            <ColumnEditor
                              initialValue={object.columns}
                              autoCompleteFields={(
                                fields
                                && fields[object.type]
                                && Object.keys(flatten(fields[object.type]))
                              )}
                              customizedFields={customizedList?.[object.type]}
                              elementType={object.type}
                              displayCheckboxes
                              onChange={(columns) => this.setState((prevState) => {
                                // eslint-disable-next-line no-param-reassign
                                prevState.objectTypes[connector.type][objectIndex].columns = columns;
                                return prevState;
                              })}
                              disabled={!canManage}
                            />
                            <div
                              className="flex"
                              style={{ paddingLeft: 142, marginTop: -28 }}
                            >
                              <div style={{ flexGrow: '1' }}>
                                {canManage && this.isSQLConnector(connector.type) && (
                                  <Button
                                    icon={<EditOutlined />}
                                    type="link"
                                    onClick={() => this.editSQLType(object)}
                                  >
                                    Edit SQL Query
                                  </Button>
                                )}
                              </div>
                              <div className="flex" style={{ alignItems: 'center' }}>
                                {canManage
                                  && !object.default
                                  && (!object.isUser || !!object.firstNameFormula)
                                  && (
                                    <div>
                                      <Button
                                        icon={(object.firstNameFormula
                                          && object.lastNameFormula
                                          && object.emailFormula) ? <EditOutlined /> : <UserOutlined />}
                                        type="link"
                                        onClick={() => this.defineObjectAsUserType(object)}
                                      >
                                        {(object.firstNameFormula
                                          && object.lastNameFormula
                                          && object.emailFormula)
                                          ? 'Edit User fields' : 'Is this resource a User ?'}
                                      </Button>
                                    </div>
                                  )}
                                {canManage && this.isSQLConnector(connector.type) && (
                                  <Popconfirm
                                    title={`Are you sure you want to remove ${object.type}?`}
                                    okText="YES"
                                    cancelText="NO"
                                    okType="danger"
                                    onConfirm={() => this.deleteResource(object.type)}
                                  >
                                    <Button
                                      icon={<DeleteOutlined />}
                                      type="link"
                                    >
                                      Delete Resource
                                    </Button>
                                  </Popconfirm>

                                )}
                              </div>

                            </div>

                            <br />

                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    </div>
                  )}
                  {connector.type === 'GSHEET' && canManage && (
                    <div style={{ marginTop: '6px' }}>
                      <Button
                        type="link"
                        style={{ marginLeft: 0, paddingLeft: 0 }}
                        onClick={this.openAddNewResourceGsheet}
                      >
                        <PlusOutlined />
                        {' '}
                        Add a new Resource
                      </Button>
                    </div>
                  )}
                  {this.isSQLConnector(connector.type) && canManage && (
                    <div style={{ marginTop: '6px' }}>
                      <Button
                        type="link"
                        style={{ marginLeft: 0, paddingLeft: 0 }}
                        onClick={this.openAddNewResourceMySQL}
                      >
                        <PlusOutlined />
                        {' '}
                        Add a new Resource
                      </Button>
                    </div>
                  )}

                  {showAdvancedSettings && (
                    <div
                      style={{
                        marginTop: '24px', marginBottom: '24px',
                      }}
                    >
                      {connector.type === 'SALESFORCE' && (canManage || (!canManage && sfReports.length > 0)) && (
                        <>
                          <h3 className="bold">Salesforce reports to sync</h3>
                          {sfReports.map((report) => (
                            <div key={report.type} style={{ marginBottom: 6 }}>
                              {`${report.name} `}
                              {canManage && (
                                <Popconfirm
                                  title={`Are you sure you want to remove ${report.type}?`}
                                  okText="YES"
                                  cancelText="NO"
                                  okType="danger"
                                  onConfirm={() => this.deleteResource(report.type)}
                                >
                                  <Button size="small" type="danger">
                                    delete
                                  </Button>
                                </Popconfirm>

                              )}
                            </div>
                          ))}
                          {canManage && (
                            <Form
                              layout="horizontal"
                              style={{ display: 'flex', marginTop: 12 }}
                              onFinish={this.addReport}
                              ref={this.SFFormRef}
                            >
                              <Form.Item noStyle name="reportName">
                                <Input
                                  style={{ width: 300 }}
                                  placeholder="Enter report name"
                                />
                              </Form.Item>
                              &nbsp;
                              <Form.Item noStyle>
                                <Button
                                  htmlType="submit"
                                  type="primary"
                                  shape="circle"
                                  icon={<PlusOutlined />}
                                />
                              </Form.Item>
                            </Form>
                          )}
                          <Divider />
                        </>
                      )}
                      {connector.type === 'SALESFORCE' && (canManage || (!canManage && customObjects.length > 0)) && (
                        <>
                          <h3 className="bold">Salesforce Objects</h3>
                          {customObjects.map((customObject) => (
                            <div key={customObject.type} style={{ marginBottom: 6 }}>
                              <FileTextOutlined />
                              &nbsp;
                              <b>{`${customObject.type} `}</b>
                              {canManage && (
                                <>
                                  <Divider type="vertical" style={{ marginLeft: '12px' }} />
                                  <Popconfirm
                                    title={`Are you sure you want to remove ${customObject.type}?`}
                                    okText="YES"
                                    cancelText="NO"
                                    okType="danger"
                                    onConfirm={() => this.deleteReport(customObject.type)}
                                  >
                                    <Button
                                      style={{ marginLeft: '12px' }}
                                      icon={<DeleteOutlined />}
                                      size="small"
                                      type="link"
                                    >
                                      delete
                                    </Button>
                                  </Popconfirm>

                                </>

                              )}
                            </div>
                          ))}
                          {canManage && (
                            <Form
                              layout="horizontal"
                              style={{ display: 'flex', marginTop: 12 }}
                              onFinish={this.addCO}
                              ref={this.SFFormCORef}
                            >
                              <Form.Item
                                noStyle
                                name="type"
                              >
                                <Input
                                  style={{ width: 300 }}
                                  placeholder="Enter Object API Name"
                                />
                              </Form.Item>
                              &nbsp;
                              <Form.Item noStyle>
                                <Button
                                  htmlType="submit"
                                  type="primary"
                                  shape="circle"
                                  icon={<PlusOutlined />}
                                />
                              </Form.Item>
                            </Form>
                          )}
                          <Divider />
                        </>
                      )}
                      {connector.type !== 'CSV' && connector.type !== 'GSHEET' && (
                        <div className="flex" style={{ alignItems: 'center' }}>
                          <div>
                            <b>Begin Sync Date</b>
                            {' (optional)'}
                            &nbsp;
                          </div>
                          <DatePicker
                            disabled={!canManage}
                            value={
                              connector.beginSyncDate
                                ? Moment(connector.beginSyncDate)
                                : undefined
                            }
                            onChange={(e) => this.updateDate(e)}
                          />
                          <br />
                        </div>
                      )}
                      {!['CSV', 'GSHEET', 'MYSQL', 'MSSQL', 'BIG_QUERY', 'SNOWFLAKE', 'REDSHIFT'].includes(connector.type) && (
                        <>
                          <Divider />
                          <ResourcesLastSyncDates
                            connectorId={connector._id}
                            onSyncConnector={() => this.syncConnector(connector._id)}
                            isSynchronizing={refreshLoading === connector._id}
                          />
                        </>
                      )}
                      {connector.type === 'GSHEET' && (
                        <>
                          <h3 className="bold">Google Sheets synchronized with Palette</h3>
                          {objectTypes[connector.type].map((object) => (
                            <div key={object.type} style={{ marginBottom: 6 }}>
                              <b>{`${object.type} `}</b>
                              {object.isUser && <UserOutlined />}
                              &nbsp;
                              <i>
                                {'from spreadsheet '}
                                <a
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  href={`https://docs.google.com/spreadsheets/d/${object.spreadsheetId}`}
                                >
                                  {object.range}
                                </a>
                              </i>
                              &nbsp;
                              {canManage && (
                                <>
                                  <Divider type="vertical" />
                                  <Popconfirm
                                    title={`Are you sure you want to remove ${object.type}?`}
                                    okText="YES"
                                    cancelText="NO"
                                    okType="danger"
                                    onConfirm={() => this.deleteResource(object.type)}
                                  >
                                    <Button
                                      ghost
                                      size="small"
                                      type="danger"
                                      style={{ border: 'none', boxShadow: 'none' }}
                                    >
                                      <DeleteOutlined />
                                    </Button>
                                  </Popconfirm>
                                </>
                              )}
                            </div>
                          ))}

                          <Divider />
                        </>
                      )}
                      {['QUICKBOOKS', 'QUICKBOOKS_SANDBOX'].includes(connector.type) && canManage && (
                        connector.hasAccessToken ? (
                          <Button
                            type="danger"
                            style={{ marginTop: '14px' }}
                            onClick={this.disconnect}
                          >
                            Disconnect
                          </Button>
                        ) : (
                          <div style={{ marginTop: 30 }}>
                            This connector is currently disconnected.
                            Click on the button below to reconnect.
                            <div style={{ marginTop: '12px' }}>
                              <QuickBooksOriginalButton sandbox={connector.type === 'QUICKBOOKS_SANDBOX'} />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {!['CSV', 'MSSQL'].includes(connector.type) && canManage && (
                    <div className="center">
                      <Button
                        type="link"
                        style={{ marginTop: '14px' }}
                        onClick={this.toggleAdvancedSettings}
                      >
                        Advanced settings
                        {showAdvancedSettings ? <UpOutlined /> : <DownOutlined />}
                      </Button>
                    </div>
                  )}
                  <br />
                  {canManage && (
                    <Button
                      type="primary"
                      block
                      icon={<SaveOutlined />}
                      onClick={this.save}
                      size="large"
                    >
                      Save
                    </Button>
                  )}
                  {connector.type === 'CSV' && showUploadModal && (
                    <UploadCSVModal
                      connector={connector}
                      ok={() => this.setState({ showUploadModal: false }, this.fetch)}
                      close={() => this.setState({ showUploadModal: false })}
                      visible={showUploadModal}
                    />
                  )}
                </Card>
              </>
            )}
            {loading && <FullScreenLoader />}
            {showEvalFnModal && (
              <Modal
                title={`${showEvalFnModal.object.type} ${showEvalFnModal.column.name} custom function`}
                onCancel={() => this.setState({ showEvalFnModal: null })}
                visible
                footer={null}
                closeIcon={<CloseSimpleLine width={22} height={22} className="Connector_modal_close_icon" />}
              >
                <Form
                  onFinish={this.onEvalFnUpdate}
                  initialValues={{ evalFn: showEvalFnModal.column.evalFn }}
                >
                  <Form.Item name="evalFn">
                    <Input placeholder="Type code here" />
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
                    <Button
                      htmlType="submit"
                      type="primary"
                    >
                      Validate
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            )}
            <Modal
              visible={addNewResourceGsheetModale}
              title="Add a new Google Sheet Resource to synchronize"
              footer={null}
              onCancel={() => {
                this.setState({
                  newResourceFormValues: {},
                  addGSheetResourceUser: false,
                }, () => {
                  this.SFFormSheetRef.current.resetFields();
                  this.setState({ addNewResourceGsheetModale: false });
                });
              }}
              closeIcon={<CloseSimpleLine width={22} height={22} className="Connector_modal_close_icon" />}
            >
              <Alert
                showIcon
                type="info"
                message="Share the Google Sheet with Palette"
                style={{ marginBottom: '12px' }}
                description={(
                  <div>
                    Please share your Google Sheet with
                    {' '}
                    <b>gsheet@palettehq.com</b>
                    {' '}
                    before defining the following fields.
                  </div>
                )}
              />
              <Form
                {...layout}
                layout="horizontal"
                onFinish={this.addSheet}
                ref={this.SFFormSheetRef}
                initialValues={this.state.newResourceFormValues}
                onValuesChange={this.onNewResourceFormValuesChange}
              >
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      style={{ margin: 0 }}
                      name="type"
                      label="Resource Name"
                      extra="ex: User, Contact, Deal, Opportunity"
                      autoFocus
                      rules={[{
                        required: true,
                        message: 'Please name the resource.',
                      }]}
                    >
                      <Input
                        style={{ width: 220 }}
                        placeholder="Resource Type"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      style={{ margin: 0 }}
                      name="spreadsheetId"
                      label={(
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src="/img/google-sheets-logo.png"
                            alt="google sheet"
                            style={{
                              width: '18px',
                            }}
                          />
                          &nbsp;
                          Spreadsheet Id
                        </div>
                      )}
                      extra="Can be found in the URL, after /spreadsheets/d/ and before /edit"
                      rules={[{
                        required: true,
                        message: 'Please provide a spreadsheet Id.',
                      }]}
                    >
                      <Input
                        style={{ width: 220 }}
                        placeholder="Spreadsheet Id"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      style={{ margin: 0 }}
                      name="range"
                      label={(
                        <div
                          className="flex"
                          style={{
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src="/img/google-sheets-logo.png"
                            alt="google sheet"
                            style={{
                              width: '18px',
                            }}
                          />
                          &nbsp;
                          Sheet Name
                        </div>
                      )}
                      extra="Name of the sheet in Google Sheet"
                      rules={[{
                        required: true,
                        message: 'Please enter the name of the Sheet.',
                      }]}
                    >
                      <Input
                        style={{ width: 220 }}
                        placeholder="Sheet Name"
                      />
                    </Form.Item>
                  </Col>
                  {isResourceAUser}
                  <Col span={16} offset={8}>
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{ marginLeft: '-6px', marginTop: '12px' }}
                      >
                        Add Resource
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>

              </Form>
            </Modal>
            {addNewResourceMySQLModale && (
              <Modal
                visible={addNewResourceMySQLModale}
                title="Add a new SQL Resource to synchronize"
                footer={null}
                onCancel={() => this.setState({ addNewResourceMySQLModale: false, editSQLQuery: null, addGSheetResourceUser: false })}
                closeIcon={<CloseSimpleLine width={22} height={22} className="Connector_modal_close_icon" />}
              >
                <h3 className="bold">Add a table</h3>
                <Form
                  onFinish={this.syncNewTableSQL}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 16 }}
                  ref={this.SFFormSheetRef}
                  layout="horizontal"
                  initialValues={editSQLQuery}
                  onValuesChange={this.onNewResourceFormValuesChange}
                >
                  {/* name, sqlIdFormula, SQL request */}
                  <Form.Item
                    required
                    label="Name of the resource"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a resource name to synchronize',
                      },
                    ]}
                  >
                    <Input
                      disabled={!!editSQLQuery}
                      placeholder="Resource Name (ex: Users, Deals...)"
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    label="SQL Request to execute"
                    name="sqlQuery"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter an SQL request to get the data.',
                      },
                    ]}
                  >
                    <Input.TextArea rows={6} placeholder="SQL Request (ex: SELECT * FROM USERS)" />
                  </Form.Item>
                  <Form.Item
                    label="Id for each SQL item"
                    name="sqlIdFormula"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a formula or a field to use as an Id for each line.',
                      },
                    ]}
                  >
                    <Input placeholder="Formula or field name (Ex: User.id or Deal.id or Deal.firstname+Deal.lastname)" />
                  </Form.Item>
                  {isResourceAUser}
                  <div className="flex" style={{ justifyContent: 'flex-end' }}>
                    <Button htmlType="submit" type="primary">
                      Sync SQL request
                    </Button>
                  </div>

                </Form>
              </Modal>
            )}
            {defineUserTypeModale && (
              <Modal
                visible
                title="Define a User resource"
                footer={null}
                onCancel={this.onCancelDefineUserType}
                closeIcon={<CloseSimpleLine width={22} height={22} className="Connector_modal_close_icon" />}
              >
                <div>
                  <div>To display properly the user basic information in Palette, you need to fill-in the attributes to use as Firtname, Lastname and Email.</div>
                  <Typography.Text mark>
                    Please prefix every attribute by
                    {' '}
                    <b>
                      {`${defineUserTypeModale.type}.`}
                    </b>
                    {' '}
                    in the formulas.
                  </Typography.Text>
                  <br />
                  <br />
                </div>
                <br />
                <Form
                  onFinish={this.defineUserType}
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 16 }}
                  layout="horizontal"
                  initialValues={defineUserTypeModale}
                >
                  <Form.Item
                    label="Define Firstname"
                    name="firstNameFormula"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a firstname attribute to use.',
                      },
                    ]}
                    extra={(
                      <div>{`Ex: ${defineUserTypeModale.type}.firstname, ${defineUserTypeModale.type}.forename, ${defineUserTypeModale.type}.first_name.`}</div>
                    )}
                  >
                    <Input placeholder="Enter the attribute to use as Firstname " />
                  </Form.Item>
                  <Form.Item
                    label="Define Lastname"
                    name="lastNameFormula"
                    extra={(
                      <div>{`Ex: ${defineUserTypeModale.type}.lastname, ${defineUserTypeModale.type}.last_name.`}</div>
                    )}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a Lastname attribute to use.',
                      },
                    ]}
                  >
                    <Input placeholder="Enter the attribute to use as Lastname." />
                  </Form.Item>
                  <Form.Item
                    label="Define Email"
                    name="emailFormula"
                    extra={(
                      <div>{`Ex: ${defineUserTypeModale.type}.email, ${defineUserTypeModale.type}.EmailAddress.`}</div>
                    )}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter an Email attribute to use.',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter the attribute to use as Email"
                    />
                  </Form.Item>

                  <div className="flex" style={{ justifyContent: 'flex-end' }}>
                    {defineUserTypeModale.isUser && (
                      <Button type="link" onClick={this.notIsUser}>
                        Not a user?
                      </Button>
                    )}
                    <div style={{ flex: 1 }} />
                    <Button htmlType="submit" type="primary">
                      Save User Resource
                    </Button>
                  </div>
                </Form>
              </Modal>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ConnectorDetails.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(withRouter(ConnectorDetails));
