import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementPeriodsDatesSelector from '@palette/components/statement/statementPeriod/StatementPeriodsDatesSelector/StatementPeriodsDatesSelector';
import MyStatementPeriodsCardsList from '@palette/components/ic/myStatement/MyStatementPeriodsCardsList/MyStatementPeriodsCardsList';

import styles from './MyStatementsPeriodsNavigation.less';

const classNames = bindClassNames.bind(styles);

const MyStatementsPeriodsNavigation = ({ className, sessionId, inSalesforce }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <StatementPeriodsDatesSelector className={styles.datesSelector} inSalesforce={inSalesforce} />
    <MyStatementPeriodsCardsList sessionId={sessionId} inSalesforce={inSalesforce} />
  </div>
);

MyStatementsPeriodsNavigation.propTypes = {
  className: PropTypes.string,
  sessionId: PropTypes.string,
  inSalesforce: PropTypes.bool,
};

MyStatementsPeriodsNavigation.defaultProps = {
  className: '',
  sessionId: '',
  inSalesforce: false,
};

export default MyStatementsPeriodsNavigation;
