import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import RawDataEntryDetails from '@palette/components/resources/RawDataEntryDetails/RawDataEntryDetails';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';

import { getRawValueType } from '@palette/helpers/ResourceHelper';

import { PROPERTY_TYPES } from '@palette/constants/resources';

import styles from './RawDataEntry.less';

const classNames = bindClassNames.bind(styles);

const RawDataEntry = ({
  resourceId,
  property,
  oldValue,
  value,
  paddingLeft,
  showActionButtons,
  path,
  overwrites,
  hasDiffHighlight,
}) => {
  const { t } = useTranslation();

  const isDate = useMemo(() => getRawValueType(value)[2] === PROPERTY_TYPES.DATE, [value]);

  const typeNode = useMemo(() => {
    if (!getRawValueType(value)[1]) return null;

    return <div className={styles.type}>{getRawValueType(value)[1]}</div>;
  }, [value]);

  const storedAsNode = useMemo(() => {
    if (!isDate) return null;

    return (
      <Tooltip title={value}>
        <div className={styles.storedAs}>
          {t('rawDataEntry.storedAs', { value })}
        </div>
      </Tooltip>
    );
  }, [isDate, value]);

  const isUpdatingHighlight = hasDiffHighlight && oldValue !== '' && value !== '';

  return (
    <div
      className={classNames({
        wrapper: true,
        isDate,
      })}
      style={{ paddingLeft }}
      id={`root-${path}`}
    >
      <div
        className={classNames({
          container: true,
          fullWidth: !showActionButtons,
        })}
      >
        <div className={styles.property}>{property}</div>
        {oldValue !== '' && (
          <div
            className={classNames({
              value: true,
              oldValue: true,
              highlighted: hasDiffHighlight,
              highlightedChanges: isUpdatingHighlight,
            })}
          >
            {getRawValueType(oldValue)[0]}
          </div>
        )}
        {isUpdatingHighlight && (
          <ChevronRightLine className={styles.arrowIcon} />
        )}
        {value !== '' && (
          <>
            <div
              className={classNames({
                value: true,
                highlighted: hasDiffHighlight,
                highlightedChanges: isUpdatingHighlight,
              })}
            >
              {getRawValueType(value)[0]}
            </div>
            {typeNode}
            {storedAsNode}
          </>
        )}
      </div>
      <RawDataEntryDetails
        resourceId={resourceId}
        showActionButtons={showActionButtons}
        path={path}
        overwrites={overwrites}
      />
    </div>
  );
};

RawDataEntry.propTypes = {
  resourceId: PropTypes.string,
  property: PropTypes.string,
  oldValue: PropTypes.any,
  value: PropTypes.any,
  paddingLeft: PropTypes.string,
  showActionButtons: PropTypes.bool,
  path: PropTypes.array,
  overwrites: PropTypes.array,
  hasDiffHighlight: PropTypes.bool,
};

RawDataEntry.defaultProps = {
  resourceId: '',
  property: '',
  oldValue: '',
  value: '',
  paddingLeft: '',
  showActionButtons: true,
  path: [],
  overwrites: [],
  hasDiffHighlight: false,
};

export default RawDataEntry;
