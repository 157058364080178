import PropTypes from 'prop-types';

import {
  manageFreeShapeObjectAttribute, manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { HIGHLIGHT_WIDGETS } from '@palette/constants/highlight';

import * as HighlightComponentParamsModel from '@palette/models/HighlightComponentParams';

export const modelName = 'HighlightComponentData';

export const propTypes = PropTypes.shape({
  type: PropTypes.string,
  data: PropTypes.object,
  params: HighlightComponentParamsModel.propTypes,
});

export const manageHighlightComponentType = (highlightComponentData) => (HIGHLIGHT_WIDGETS[manageStringAttribute(highlightComponentData, 'type')]?.type || null);

export const transform = (highlightComponentData) => {
  if (!highlightComponentData) {
    return null;
  }

  return (
    {
      type: manageHighlightComponentType(highlightComponentData),
      data: manageFreeShapeObjectAttribute(highlightComponentData, 'data'),
      params: HighlightComponentParamsModel.transform(manageFreeShapeObjectAttribute(highlightComponentData, 'params')),
    }
  );
};

export const transformList = (highlightComponentDatas) => highlightComponentDatas.map((highlightComponentData) => transform(highlightComponentData));
