import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Modal as AntDModal } from 'antd';

import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';
import Button from '@palette/components/designSystem/Button/Button';

import styles from './Modal.less';

const classNames = bindClassNames.bind(styles);

const Modal = ({
  className,
  visible,
  title,
  displayFooter,
  okText,
  cancelText,
  onOk,
  onCancel,
  okIcon,
  okTheme,
  cancelIcon,
  extraFooterNode,
  children,
  width,
  loading,
  disableOk,
  stretchHeight,
  blockHeight,
  ...otherProps
}) => {
  const { t } = useTranslation();

  if (!visible) return null;

  let footerNode = null;
  if (displayFooter) {
    let cancelBtn = null;
    if (cancelText !== null) {
      const cancelBtnText = cancelText !== '' ? cancelText : t('modal.cancel');
      cancelBtn = (
        <Button type="secondary" onClick={(event) => onCancel(event)} disabled={loading} icon={cancelIcon}>
          {cancelBtnText}
        </Button>
      );
    }

    let okBtn = null;
    if (okText !== null) {
      const okBtnText = okText !== '' ? okText : t('modal.ok');
      okBtn = (
        <Button onClick={(event) => onOk(event)} disabled={loading || disableOk} loading={loading} icon={okIcon} type={okTheme}>
          {okBtnText}
        </Button>
      );
    }

    if (cancelBtn !== null || okBtn !== null) {
      footerNode = (
        <div
          className={classNames({
            footer: true,
            singleBtn: cancelBtn === null || okBtn === null,
          })}
        >
          {cancelBtn}
          {okBtn}
        </div>
      );
    }

    if (extraFooterNode !== null) {
      footerNode = (
        <div
          className={classNames({
            footer: true,
          })}
        >
          {extraFooterNode}
          {footerNode}
        </div>
      );
    }
  }

  return (
    <AntDModal
      wrapClassName={classNames({
        wrapper: true,
        stretchHeight,
        blockHeight,
        [className]: className !== '',
      })}
      visible
      closeIcon={<ClosePopupLine width={28} height={28} />}
      title={title}
      onCancel={(event) => onCancel(event)}
      width={width}
      footer={footerNode}
      {...otherProps}
    >
      {children}
    </AntDModal>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  visible: PropTypes.bool,
  title: PropTypes.any,
  displayFooter: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okIcon: PropTypes.any,
  okTheme: PropTypes.oneOf(['primary', 'danger']),
  cancelIcon: PropTypes.any,
  extraFooterNode: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  disableOk: PropTypes.bool,
  stretchHeight: PropTypes.bool,
  blockHeight: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  children: null,
  visible: false,
  onCancel: () => {},
  title: null,
  displayFooter: true,
  okText: '',
  cancelText: '',
  onOk: () => {},
  okIcon: null,
  okTheme: 'primary',
  cancelIcon: null,
  extraFooterNode: null,
  width: '40%',
  loading: false,
  disableOk: false,
  stretchHeight: false,
  blockHeight: false,
};

export default Modal;
