import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PaymentsNb from '@palette/components/utils/Icons/PaymentsNb';
import PaymentsDone from '@palette/components/utils/Icons/PaymentsDone';
import PaymentsToBeDone from '@palette/components/utils/Icons/PaymentsToBeDone';

import { usePayments } from '@palette/hooks/CommissionHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as CommissionModel from '@palette/models/Commission';

import styles from './PaymentsSummary.less';

const classNames = bindClassNames.bind(styles);

const PaymentsSummary = ({ className, commission }) => {
  const { t } = useTranslation();

  const [paymentsDone, paymentsToBeDone] = usePayments(commission);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.paymentsNb}>
        <PaymentsNb />
        <div className={styles.nb}>
          {commission.payments?.length || 0}
        </div>
        <div className={styles.label}>
          {t('payments', { count: commission.payments?.length || 0 })}
        </div>
      </div>
      <div className={styles.splits}>
        <div className={styles.paymentsDone}>
          <PaymentsDone />
          <div className={styles.splitInfo}>
            <div className={styles.amount}>
              {formatPrice(paymentsDone.value, commission.currency)}
            </div>
            <div className={styles.label}>
              {t('paymentsSummary.paymentsDone')}
            </div>
            <div className={styles.paymentsPercentage}>
              {t('paymentsSummary.paymentsNbPercentage', { paymentsCount: paymentsDone.payments.length, percentage: parseFloat(paymentsDone.percentage * 100).toFixed(2) })}
            </div>
          </div>
        </div>
        <div className={styles.paymentsToBeDone}>
          <PaymentsToBeDone />
          <div className={styles.splitInfo}>
            <div className={styles.amount}>
              {formatPrice(paymentsToBeDone.value, commission.currency)}
            </div>
            <div className={styles.label}>
              {t('paymentsSummary.paymentsToBeDone')}
            </div>
            <div className={styles.paymentsPercentage}>
              {t('paymentsSummary.paymentsNbPercentage', { paymentsCount: paymentsToBeDone.payments.length, percentage: parseFloat(paymentsToBeDone.percentage * 100).toFixed(2) })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PaymentsSummary.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

PaymentsSummary.defaultProps = {
  className: '',
};

export default PaymentsSummary;
