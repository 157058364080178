import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import { message as AntDMessage } from 'antd';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';

import { ONGOING_BULK_APPROVE_STATUS_MESSAGE_KEY } from '@palette/constants/statements';

import { selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import styles from './StatementV3OngoingBulkApproveStatus.less';

const classNames = bindClassNames.bind(styles);

const StatementV3OngoingBulkApproveStatus = ({ className }) => {
  const { t } = useTranslation();

  const ongoingBulkApproveStatus = useSelector(StatementV3Selectors.getOngoingBulkApproveStatus);

  useEffect(() => {
    if (ongoingBulkApproveStatus === null) return;

    const currentIndex = ongoingBulkApproveStatus.index + 1;
    const nbToProcess = ongoingBulkApproveStatus.statementPeriodIds.length;

    if (currentIndex > nbToProcess) {
      AntDMessage.destroy(ONGOING_BULK_APPROVE_STATUS_MESSAGE_KEY);
      return;
    }

    const messageContentNode = (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <div className={styles.status}>
          {t('ongoingBulkApproveStatus.status', { currentIndex, nbToProcess })}
        </div>
        <div className={styles.description}>
          {t('ongoingBulkApproveStatus.description')}
        </div>
      </div>
    );

    AntDMessage.info(
      {
        key: ONGOING_BULK_APPROVE_STATUS_MESSAGE_KEY,
        icon: (<LoadingLine className={styles.loadingIcon} spin />),
        content: messageContentNode,
        className: 'paletteNotification',
        duration: 0,
      },
      0,
    );
  }, [ongoingBulkApproveStatus]);

  return null;
};

StatementV3OngoingBulkApproveStatus.propTypes = {
  className: PropTypes.string,
};

StatementV3OngoingBulkApproveStatus.defaultProps = {
  className: '',
};

export default StatementV3OngoingBulkApproveStatus;
