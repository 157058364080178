import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';

import { INPUT_TABLE_ADD_NEW_ROW_LABEL } from '@palette/constants/global';

import styles from './InputTableRowIndexHeaderCell.less';

const classNames = bindClassNames.bind(styles);

const InputTableRowIndexHeaderCell = ({
  className,
  rowIndex,
  value,
  disableActions,
  onAddNewRow,
  onDeleteRow,
}) => {
  const { t } = useTranslation();

  const handleAddNewRow = useCallback(() => {
    onAddNewRow?.();
  }, [onAddNewRow]);

  const handleDeleteRow = useCallback(() => {
    onDeleteRow?.(rowIndex);
  }, [onDeleteRow, rowIndex]);

  const isAddNewRole = useMemo(() => (value === INPUT_TABLE_ADD_NEW_ROW_LABEL), [value]);

  const onClickProps = useMemo(() => {
    if (isAddNewRole && !disableActions) {
      return {
        onClick: handleAddNewRow,
      };
    }

    return {};
  }, [isAddNewRole, disableActions]);

  const contentNode = (
    <div
      className={classNames({
        wrapper: true,
        isAddNewRole,
        [className]: className !== '',
      })}
      {...onClickProps}
    >
      <div className={styles.label}>
        {value}
      </div>
    </div>
  );

  if (isAddNewRole || disableActions) {
    return contentNode;
  }

  const rowMoreMenuItems = [
    {
      key: 'deleteRow',
      className: styles.deleteRowMenuItem,
      title: t('inputTable.deleteRow'),
      onClick: handleDeleteRow,
    },
  ];

  return (
    <MoreMenu className={styles.moreMenu} items={rowMoreMenuItems}>
      {contentNode}
    </MoreMenu>
  );
};

InputTableRowIndexHeaderCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  rowIndex: PropTypes.string,
  disableActions: PropTypes.bool,
  onAddNewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

InputTableRowIndexHeaderCell.defaultProps = {
  className: '',
  rowIndex: null,
  disableActions: false,
  onAddNewRow: null,
  onDeleteRow: null,
};

export default InputTableRowIndexHeaderCell;
