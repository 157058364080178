import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import * as UserStatementAdjustmentModel from '@palette/models/UserStatementAdjustment';

import styles from './MyUserStatementAdjustmentsItem.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementAdjustmentsItem = ({ className, adjustment }) => {
  const moment = getMoment();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.colRow}>
        <span className={styles.details}>
          {adjustment.reason}
        </span>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {formatPrice(adjustment.amount, adjustment.currency)}
        </span>
      </div>
      <div className={styles.colRow}>
        <span className={styles.details}>
          {moment.utc(adjustment.when).format('LL')}
        </span>
      </div>
    </div>
  );
};

MyUserStatementAdjustmentsItem.propTypes = {
  className: PropTypes.string,
  adjustment: UserStatementAdjustmentModel.propTypes.isRequired,
};

MyUserStatementAdjustmentsItem.defaultProps = {
  className: '',
};

export default MyUserStatementAdjustmentsItem;
