import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import CompanyCurrencySelect from '@palette/components/company/CompanyCurrencySelect/CompanyCurrencySelect';

import { useCompanyDefaultCurrency } from '@palette/hooks/CompanyHooks';

import * as OnboardUserModel from '@palette/models/OnboardUser';

import styles from './OnboardUserForm.less';

const classNames = bindClassNames.bind(styles);

const OnboardUserForm = ({ className, user, setSubmitFunc, onUpdateFinish, onValuesChange }) => {
  const { t } = useTranslation();

  const [form] = AntDForm.useForm();

  const defaultCurrency = useCompanyDefaultCurrency();

  const initialValues = useMemo(() => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    currency: user?.currency ?? defaultCurrency,
  }), [user, defaultCurrency]);

  const handleValuesChange = useCallback((newFormValues) => {
    if (onValuesChange !== null) {
      onValuesChange({
        ...newFormValues,
        cObject: user?.cObject ?? null,
      });
    }
  }, [onValuesChange, user]);

  useEffect(() => {
    handleValuesChange(initialValues);
    form.resetFields();
  }, [initialValues]);

  const handleSubmitForm = useCallback(() => {
    form.submit();
  }, [form]);

  const handleFinish = useCallback(({ firstName, lastName, email, currency }) => {
    const onboardUser = OnboardUserModel.transform({ firstName, lastName, email, currency, cObject: user?.cObject ?? null });
    onUpdateFinish(onboardUser);
  }, [form, onUpdateFinish]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    handleValuesChange(allValues);
  }, [form, handleValuesChange]);

  useEffect(() => {
    setSubmitFunc(handleSubmitForm);
  }, [setSubmitFunc, handleSubmitForm]);

  return (
    <Form
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
      onValuesChange={handleFormValuesChange}
    >
      <FormItem
        name="firstName"
        label={t('onboardUserForm.form.firstName.label')}
        required
        rules={[
          { required: true, message: t('onboardUserForm.form.firstName.rules.required') },
        ]}
      >
        <Input size="big" />
      </FormItem>
      <FormItem
        name="lastName"
        label={t('onboardUserForm.form.lastName.label')}
        required
        rules={[
          { required: true, message: t('onboardUserForm.form.lastName.rules.required') },
        ]}
      >
        <Input size="big" />
      </FormItem>
      <FormItem
        name="email"
        label={t('onboardUserForm.form.email.label')}
        required
        rules={[
          { required: true, message: t('onboardUserForm.form.email.rules.required') },
          { type: 'email', warningOnly: false, message: t('onboardUserForm.form.email.rules.email') },
        ]}
      >
        <Input size="big" />
      </FormItem>
      <FormItem
        name="currency"
        label={t('onboardUserForm.form.currency.label')}
        required
      >
        <CompanyCurrencySelect size="big" />
      </FormItem>
    </Form>
  );
};

OnboardUserForm.propTypes = {
  className: PropTypes.string,
  user: OnboardUserModel.propTypes,
  setSubmitFunc: PropTypes.func.isRequired,
  onUpdateFinish: PropTypes.func.isRequired,
  onValuesChange: PropTypes.func,
};

OnboardUserForm.defaultProps = {
  className: '',
  user: null,
  onValuesChange: null,
};

export default OnboardUserForm;
