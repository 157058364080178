import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import CommissionClearFiltersModal from '@palette/components/commissionList/CommissionClearFiltersModal/CommissionClearFiltersModal';
import BarsFilled from '@palette/components/utils/Icons/BarsFilled';

import styles from './CommissionClearFilters.less';

const classNames = bindClassNames.bind(styles);

const CommissionClearFilters = ({ className, onClear }) => {
  const { t } = useTranslation();

  const [modalIsVisible, showModal] = useState(false);

  const handleClearClick = () => showModal(true);

  const handleClearFilters = () => onClear();

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        icon={<BarsFilled width={24} height={24} />}
        onClick={handleClearClick}
      >
        {t('commissionList.clearFilters.label')}
      </Button>
      {
        modalIsVisible && (
          <CommissionClearFiltersModal
            visible
            onClose={() => showModal(false)}
            onClear={handleClearFilters}
          />
        )
      }
    </div>
  );
};

CommissionClearFilters.propTypes = {
  className: PropTypes.string,
  onClear: PropTypes.func.isRequired,
};

CommissionClearFilters.defaultProps = {
  className: '',
};

export default CommissionClearFilters;
