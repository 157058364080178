import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './HighlightWidgetBase.less';

const classNames = bindClassNames.bind(styles);

const HighlightWidgetBase = ({
  className,
  title,
  subtitle,
  children,
}) => {
  const titleNode = useMemo(() => {
    if (title === null) return null;

    return (
      <div className={styles.title}>
        {title}
      </div>
    );
  }, [title]);

  const subtitleNode = useMemo(() => {
    if (subtitle === null) return null;

    return (
      <div className={styles.subtitle}>
        {subtitle}
      </div>
    );
  }, [subtitle]);

  const titleSubtitleNode = useMemo(() => {
    if (titleNode === null && subtitleNode === null) return null;

    return (
      <div className={styles.titleSubtitleWrapper}>
        {titleNode}
        {subtitleNode}
      </div>
    );
  }, [titleNode, subtitleNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {titleSubtitleNode}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};

HighlightWidgetBase.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HighlightWidgetBase.defaultProps = {
  className: '',
  children: null,
  title: null,
  subtitle: null,
};

export default HighlightWidgetBase;
