import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import MasterPlanDetailsPageContent from '@palette/components/masterPlan/MasterPlanDetailsPageContent/MasterPlanDetailsPageContent';
import MasterPlanBreadcrumb from '@palette/components/masterPlan/MasterPlanBreadcrumb/MasterPlanBreadcrumb';
import MasterPlanTopActions from '@palette/components/masterPlan/MasterPlanTopActions/MasterPlanTopActions';
import MasterPlanRecomputeWarning from '@palette/components/masterPlan/MasterPlanRecomputeWarning/MasterPlanRecomputeWarning';
import MasterPlanDependencies from '@palette/components/masterPlan/MasterPlanDependencies/MasterPlanDependencies';
import MasterPlanHiddenInfo from '@palette/components/masterPlan/MasterPlanHiddenInfo/MasterPlanHiddenInfo';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useMasterPlanInParams } from '@palette/hooks/MasterPlanHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { isDraft } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';

import { RIGHTS } from '@palette/constants/profile';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import styles from './MasterPlanDetailsPage.less';

const MasterPlanDetailsPage = () => {
  const profile = useProfile();

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) return null;

  const { plan } = useMasterPlanInParams();

  const moment = getMoment();

  useEffect(() => {
    if (plan !== null) {
      if (isDraft(plan)) {
        redirectTo({ path: routePaths.v2.plans });
        return;
      }

      if (plan.periodType === PERIOD_TYPES.CUSTOM) {
        const customBeginAtMomentDate = moment(plan.customBeginAt).utc();
        redirectTo({ path: routePaths.v2.planPeriodDetails, params: { masterPlanId: plan.id, year: customBeginAtMomentDate.year(), periodId: 1 } });
      }
    }
  }, [plan]);

  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.masterPlansDetailsTitle', { planName: plan?.name || '' })} />
      <MasterPlanBreadcrumb className={styles.breadcrumb} plan={plan} />
      <PageTitle>
        <h3>{plan?.name || ''}</h3>
        {plan && (
          <MasterPlanTopActions className={styles.actions} plan={plan} />
        )}
      </PageTitle>
      <MasterPlanDependencies injections={plan?.injections} />
      <MasterPlanHiddenInfo isLive={plan?.live} planId={plan?.id} />
      <MasterPlanRecomputeWarning plan={plan} />
      <MasterPlanDetailsPageContent />
    </div>
  );
};

export default MasterPlanDetailsPage;
