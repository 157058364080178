import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as PlanV3VariableNameCopiedStrategyModel from '@palette/models/globalNotifStrategies/planV3/PlanV3VariableNameCopiedStrategy';

const classNames = bindClassNames.bind();

const PlanV3VariableNameCopiedContentStrategy = ({ className, content }) => {
  const { name } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notifications.planV3.success.copyName.content"
        values={{ name }}
      />
    </span>
  );
};

PlanV3VariableNameCopiedContentStrategy.propTypes = {
  className: PropTypes.string,
  content: PlanV3VariableNameCopiedStrategyModel.propTypes.isRequired,
};

PlanV3VariableNameCopiedContentStrategy.defaultProps = {
  className: '',
};

export default PlanV3VariableNameCopiedContentStrategy;
