import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import StatementApproveAction from '@palette/components/statement/statementCommon/StatementApproveAction/StatementApproveAction';
import StatementMarkAsPaidAction from '@palette/components/statement/statementCommon/StatementMarkAsPaidAction/StatementMarkAsPaidAction';
import UsersStatementsApprovalsList from '@palette/components/statement/statementPeriodUsers/UsersStatementsApprovalsList/UsersStatementsApprovalsList';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import Tag from '@palette/components/designSystem/Tag/Tag';
import FlagFilled from '@palette/components/utils/Icons/FlagFilled';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { getPeriodName } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { isSuperAdmin } from '@palette/helpers/ProfileHelper';

import { STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementModel from '@palette/models/UserStatement';

import { actions as StatementsActions, selectors as StatementsSelectors } from '@palette/state/Statements';

import styles from './UserStatementInfosAndActions.less';

const classNames = bindClassNames.bind(styles);

const UserStatementInfosAndActions = ({ className, statementPeriod, userStatement, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const periodName = getPeriodName(statementPeriod.statementStrategy, statementPeriod.period, statementPeriod.year);

  const markAsDirtyIsPending = useSelector(StatementsSelectors.markAsDirtyIsPending);

  const handleExport = useCallback(() => {
    dispatch(StatementsActions.downloadUserStatement({
      user: userStatement.user,
      statementPeriodId: statementPeriod.id,
      currency,
    }));
  }, [userStatement, statementPeriod, currency]);

  const handleMarkAsDirty = useCallback(() => {
    dispatch(StatementsActions.markAsDirty({
      statementId: userStatement.id,
      userId: userStatement.user.id,
      statementPeriodId: statementPeriod.id,
      currency,
    }));
  }, [userStatement, statementPeriod, currency]);

  const markAsDirtyBtnNode = useMemo(() => {
    if (!isSuperAdmin() || userStatement.isDirty) return null;

    return (
      <Popconfirm
        title={t('userStatement.infosAndActions.markAsDirty.button.popconfirm')}
        onConfirm={handleMarkAsDirty}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Button
          className={styles.actionLink}
          type="link"
          icon={<FlagFilled width={20} height={20} />}
          disabled={markAsDirtyIsPending}
          loading={markAsDirtyIsPending}
        >
          {t('userStatement.infosAndActions.markAsDirty.button.label')}
        </Button>
      </Popconfirm>
    );
  }, [userStatement, handleMarkAsDirty, markAsDirtyIsPending]);

  const statementAmount = formatPrice(userStatement.statementAmount, userStatement.currency);
  const statementPaidByAmount = formatPrice(userStatement.paidBy?.amount, userStatement.currency);

  const { theme, icon } = STATEMENT_PERIOD_USER_STATUSES.PAID_AMOUNT;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.infos}>
        <div className={styles.periodName}>{periodName}</div>
        <div className={styles.statementAmount}>{statementAmount}</div>
        {userStatement.isPaid && userStatement.paidBy && statementAmount !== statementPaidByAmount && (
          <div className={styles.statementPaidAmount}>
            <Tag
              theme={theme}
              label={t('statement.statuses.paid.amount', { amount: statementPaidByAmount })}
              icon={icon}
            />
          </div>
        )}
      </div>
      <div className={styles.actions}>
        {markAsDirtyBtnNode}
        <Button
          className={styles.actionLink}
          type="link"
          icon={<DownloadFilled width={20} height={20} />}
          onClick={handleExport}
        >
          {t('userStatement.infosAndActions.export')}
        </Button>
        <UsersStatementsApprovalsList
          className={styles.approvalsListWrapper}
          userStatement={userStatement}
          limitShown={6}
        />
        <div className={styles.buttonsWrapper}>
          <StatementApproveAction
            className={styles.actionButton}
            userStatement={userStatement}
            statementPeriod={statementPeriod}
            currency={currency}
          />
          <StatementMarkAsPaidAction
            className={styles.actionButton}
            userStatement={userStatement}
            statementPeriod={statementPeriod}
            currency={currency}
          />
        </div>
      </div>
    </div>
  );
};

UserStatementInfosAndActions.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  userStatement: UserStatementModel.propTypes.isRequired,
  currency: PropTypes.string,
};

UserStatementInfosAndActions.defaultProps = {
  className: '',
  currency: null,
};

export default UserStatementInfosAndActions;
