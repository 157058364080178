import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import Table from '@palette/components/designSystem/Table/Table';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import EyesLine from '@palette/components/utils/Icons/EyesLine';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import { getColumnValue } from '@palette/helpers/ConnectorHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import { useWindowSize } from '@palette/hooks/CommonHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import * as PaginationModel from '@palette/models/Pagination';
import * as ResourceColumnModel from '@palette/models/ResourceColumn';
import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './ResourceObjectsListTable.less';

const classNames = bindClassNames.bind(styles);

const ResourceObjectsListTable = ({
  className,
  connectorId,
  resourceObjects,
  columns,
  showLastColumn,
  isBorderless,
  pagination,
  onPageChange,
  onLimitChange,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const windowSize = useWindowSize();

  const containerRef = useRef(null);

  const [containerWidth, setContainerWidth] = useState(0);

  const handlePageChange = useCallback((newPage) => {
    onPageChange(newPage);
  });

  const handleLimitChange = useCallback((newLimit) => {
    onLimitChange(newLimit);
  });

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, [containerRef, windowSize.width]);

  const resourceColumns = useMemo(() => {
    const cols = [];
    const actionsColumnWidth = 15;

    columns.forEach((column) => {
      const otherColumnsMinWidth = 22;
      const widthToSubstract = showLastColumn ? actionsColumnWidth : 0;
      const columnWidth = parseFloat(((containerWidth / 10) - widthToSubstract) / columns.length);
      const finalColumnWidth = columnWidth > otherColumnsMinWidth ? columnWidth : otherColumnsMinWidth;

      cols.push({
        id: column.name,
        Header: column.name,
        accessor: (resourceObject) => getColumnValue(resourceObject.data, column),
        minWidth: finalColumnWidth * 10,
        width: `${finalColumnWidth}%`,
      });
    });

    if (showLastColumn) {
      const actionsColumn = {
        id: 'resourceObjectActions',
        Header: hasAtLeastOneRight(profile, [RIGHTS.ADMIN.CONNECTORS.MANAGE]) && (
          <Link
            className={styles.editButton}
            path={routePaths.connectorDetails}
            params={{ connectorId }}
          >
            <PenFilled className={styles.editIcon} />
            {t('resources.table.actions.button.edit_columns')}
          </Link>
        ),
        accessor: (resourceObject) => resourceObject,
        minWidth: actionsColumnWidth * 10,
        width: `${actionsColumnWidth}%`,
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => (
          <Link
            className={styles.viewButton}
            path={routePaths.v2.resourceDetails}
            params={{ connectorId, type: value.originalType, resourceId: value.id }}
          >
            <EyesLine className={styles.viewIcon} />
            {t('resources.table.actions.button.view_details')}
          </Link>
        ),
        /* eslint-enable react/prop-types */
      };

      cols.push(actionsColumn);
    }

    return cols;
  }, [
    connectorId,
    columns,
    profile,
    showLastColumn,
    containerWidth,
  ]);

  const contentNode = useMemo(() => (
    <>
      <Table
        type={isBorderless ? 'borderless' : 'default'}
        columns={resourceColumns}
        data={resourceObjects}
        nbOfFixedColumns={showLastColumn ? 1 : 0}
        fixedColumnsPosition="fromRight"
      />
      <Pagination
        className={styles.pagination}
        pagination={pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </>
  ), [
    resourceObjects,
    resourceColumns,
    pagination,
    showLastColumn,
    isBorderless,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      ref={containerRef}
    >
      {contentNode}
    </div>
  );
};

ResourceObjectsListTable.propTypes = {
  className: PropTypes.string,
  connectorId: PropTypes.string.isRequired,
  resourceObjects: PropTypes.arrayOf(ResourceObjectModel.propTypes).isRequired,
  columns: PropTypes.arrayOf(ResourceColumnModel.propTypes).isRequired,
  showLastColumn: PropTypes.bool,
  isBorderless: PropTypes.bool,
  pagination: PaginationModel.propTypes,
  onPageChange: PropTypes.func,
  onLimitChange: PropTypes.func,
};

ResourceObjectsListTable.defaultProps = {
  className: '',
  showLastColumn: true,
  isBorderless: true,
  pagination: null,
  onPageChange: () => {},
  onLimitChange: () => {},
};

export default ResourceObjectsListTable;
