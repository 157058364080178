import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import OnboardUsersSelectResourcesFlowStep from '@palette/components/userOnboarding/OnboardUsersSelectResourcesFlowStep/OnboardUsersSelectResourcesFlowStep';
import OnboardUsersCheckDataFlowStep from '@palette/components/userOnboarding/OnboardUsersCheckDataFlowStep/OnboardUsersCheckDataFlowStep';
import Alert from '@palette/components/designSystem/Alert/Alert';

import { ALERT_TYPES } from '@palette/constants/alert';

import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './FastOnboardModalContent.less';

const classNames = bindClassNames.bind(styles);

const FastOnboardModalContent = ({
  className,
  restrictToConnectorIdAndType,
  setIsMissingRequiredFields,
  onResourcesSelected,
  registerNextStepHandler,
}) => {
  const { t } = useTranslation();

  const onboardCurrentUsers = useSelector(UsersSelectors.getOnboardCurrentUsers);

  const selectResourcesFlowStepNode = useMemo(() => (
    <OnboardUsersSelectResourcesFlowStep
      className={styles.selectResourcesFlowStep}
      restrictToConnectorIdAndType={restrictToConnectorIdAndType}
      onResourcesSelected={onResourcesSelected}
      registerNextStepHandler={registerNextStepHandler}
      disableActions
      disableReloadUsers
    />
  ), [restrictToConnectorIdAndType, registerNextStepHandler, onResourcesSelected]);

  const checkDataFlowStepNode = useMemo(() => (
    <OnboardUsersCheckDataFlowStep
      disableActions
      onCheckMissingRequiredFields={setIsMissingRequiredFields}
    />
  ), [setIsMissingRequiredFields]);

  const modalContentNode = useMemo(() => {
    if (onboardCurrentUsers.length > 0) {
      return checkDataFlowStepNode;
    }

    return selectResourcesFlowStepNode;
  }, [onboardCurrentUsers, selectResourcesFlowStepNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Alert
        className={styles.alert}
        type={ALERT_TYPES.INFO}
        message={t('fastOnboardModal.description')}
      />
      {modalContentNode}
    </div>
  );
};

FastOnboardModalContent.propTypes = {
  className: PropTypes.string,
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  setIsMissingRequiredFields: PropTypes.func,
  onResourcesSelected: PropTypes.func,
  registerNextStepHandler: PropTypes.func,
};

FastOnboardModalContent.defaultProps = {
  className: '',
  restrictToConnectorIdAndType: null,
  setIsMissingRequiredFields: null,
  onResourcesSelected: null,
  registerNextStepHandler: null,
};

export default FastOnboardModalContent;
