import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import StatementV3PeriodStatus from '@palette/components/statementV3/StatementV3PeriodStatus/StatementV3PeriodStatus';

import { formatPeriodNameDates, getPeriodName } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import { selectors as StatementV3Selectors } from '@palette/state/StatementV3';

import * as StatementV3ListPeriodModel from '@palette/models/StatementV3ListPeriod';

import styles from './StatementV3PeriodCardItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PeriodCardItem = ({ className, statementPeriod, enableStatus, isSelected, inUserStatement }) => {
  const [statementPeriodInState, setStatementPeriodInState] = useState(statementPeriod);

  const getOneStatementIsPending = useSelector((state) => StatementV3Selectors.getOneStatementIsPending(state));

  useEffect(() => {
    if (!getOneStatementIsPending) {
      setStatementPeriodInState(statementPeriod);
    }
  }, [statementPeriod, getOneStatementIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        isSelected,
        [className]: className !== '',
      })}
    >
      <Tooltip title={formatPeriodNameDates(statementPeriodInState.fromDate, statementPeriodInState.toDate)}>
        <div className={styles.date}>
          {getPeriodName({ type: statementPeriodInState.periodicity }, statementPeriodInState.period, statementPeriodInState.year)}
        </div>
      </Tooltip>
      <div className={styles.amount}>
        {formatPrice(statementPeriodInState.amount, statementPeriodInState.currency)}
      </div>
      {enableStatus && (
        <StatementV3PeriodStatus
          className={styles.status}
          statementPeriod={statementPeriodInState}
          inUserStatement={inUserStatement}
        />
      )}
    </div>
  );
};

StatementV3PeriodCardItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3ListPeriodModel.propTypes.isRequired,
  enableStatus: PropTypes.bool,
  isSelected: PropTypes.bool,
  inUserStatement: PropTypes.bool,
};

StatementV3PeriodCardItem.defaultProps = {
  className: '',
  enableStatus: true,
  isSelected: false,
  inUserStatement: false,
};

export default StatementV3PeriodCardItem;
