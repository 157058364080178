import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { getScheduleFromPercentage, getTotalPayments } from '@palette/helpers/CommissionHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionPayout.less';

const classNames = bindClassNames.bind(styles);

const CommissionPayout = ({ className, commission }) => {
  const [totalAmount, totalPercentage] = getTotalPayments(getScheduleFromPercentage(commission));

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.percent}>
        {`${totalPercentage}%`}
      </div>
      <div className={styles.amount}>
        {`- ${formatPrice(totalAmount, commission.currency)}`}
      </div>
    </div>
  );
};

CommissionPayout.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionPayout.defaultProps = {
  className: '',
};

export default CommissionPayout;
