import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import TeamSelector from '@palette/components/team/TeamSelector/TeamSelector';
import Switch from '@palette/components/designSystem/Switch/Switch';

import * as TeamModel from '@palette/models/Team';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './MoveTeamModal.less';

const MoveTeamModal = ({ visible, onClose, team, onMove }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const moveTeamIsPending = useSelector(TeamsSelectors.moveTeamIsPending);

  const initialParentTeamId = useMemo(() => (team.parentId), [team]);

  const [parentTeamId, setParentTeamId] = useState(initialParentTeamId);

  useEffect(() => {
    setParentTeamId(initialParentTeamId);
  }, [initialParentTeamId]);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const onSuccessCB = useCallback(() => {
    cleanAndClose();
    if (onMove !== null) onMove();
  }, [onMove, cleanAndClose]);

  const handleMoveTeam = useCallback(() => {
    if (initialParentTeamId !== parentTeamId) {
      dispatch(TeamsActions.moveTeam({ parentTeamId, teamId: team.id, onSuccessCB }));
    }
  }, [team, initialParentTeamId, parentTeamId, onSuccessCB]);

  const handleMoveToRootChange = useCallback((isChecked) => {
    if (isChecked) {
      setParentTeamId(null);
    } else {
      setParentTeamId(initialParentTeamId);
    }
  }, [initialParentTeamId, setParentTeamId]);

  const moveToRootNode = useMemo(() => {
    if (initialParentTeamId === null) return null;

    return (
      <div className={styles.moveToRootSwitchWrapper}>
        <Switch className={styles.moveToRootSwitch} disabled={moveTeamIsPending} value={parentTeamId === null} onChange={handleMoveToRootChange} />
        <div className={styles.moveToRootLabel}>
          {t('moveTeamModal.moveToRootLabel')}
        </div>
      </div>
    );
  }, [initialParentTeamId, moveTeamIsPending, parentTeamId, handleMoveToRootChange]);

  /* eslint-disable react/no-danger */
  return (
    <Modal
      className={styles.modal}
      title={t('moveTeamModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleMoveTeam}
      okText={t('moveTeamModal.move')}
      disableOk={initialParentTeamId === parentTeamId}
      loading={moveTeamIsPending}
    >
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: t('moveTeamModal.description', { teamName: team.name }) }} />
      <TeamSelector
        className={styles.teamSelector}
        selectedTeam={parentTeamId}
        onTeamSelected={setParentTeamId}
        placeholder={(initialParentTeamId !== null && parentTeamId === null) ? t('moveTeamModal.moveToRootLabel') : ''}
        disableTeamFamily={team.id}
        disabled={moveTeamIsPending || (initialParentTeamId !== null && parentTeamId === null)}
      />
      {moveToRootNode}
    </Modal>
  );
  /* eslint-enable react/no-danger */
};

MoveTeamModal.propTypes = {
  team: TeamModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onMove: PropTypes.func,
};

MoveTeamModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onMove: null,
};

export default MoveTeamModal;
