import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import AuditTrailListItem from '@palette/components/auditTrail/AuditTrailListItem/AuditTrailListItem';

import { selectors as AuditTrailSelectors } from '@palette/state/AuditTrail';

import styles from './AuditTrailList.less';

const classNames = bindClassNames.bind(styles);

const AuditTrailList = ({ className, onPageChange, onLimitChange }) => {
  const { t } = useTranslation();

  const auditTrailsList = useSelector(AuditTrailSelectors.getAuditTrailsListByFilters);
  const auditTrailsListPagination = useSelector(AuditTrailSelectors.listByFiltersPagination);

  const handlePageChange = useCallback((newPage) => {
    onPageChange(newPage);
  });

  const handleLimitChange = useCallback((newLimit) => {
    onLimitChange(newLimit);
  });

  const contentNode = useMemo(() => {
    if (auditTrailsList.length === 0) {
      return (
        <DefaultEmptyState
          className={styles.emptyStateContainer}
          description={t('auditTrail.list.empty.description')}
          size="compact"
        />
      );
    }

    return (
      <>
        {auditTrailsList.map((auditTrail) => (
          <AuditTrailListItem
            auditTrail={auditTrail}
            key={`audit_${auditTrail.id}`}
          />
        ))}
        <Pagination
          className={styles.pagination}
          pagination={auditTrailsListPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </>
    );
  }, [
    auditTrailsList,
    auditTrailsListPagination,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

AuditTrailList.propTypes = {
  className: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

AuditTrailList.defaultProps = {
  className: '',
};

export default AuditTrailList;
