import { useSelector } from 'react-redux';

import { USER_STATEMENT_CONTENT_TABS_QS_KEY } from '@palette/constants/tabs';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { selectors as StatementsHistorySelectors } from '@palette/state/StatementsHistory';

export const useHistoryTabInSearch = () => useKeyInSearch(USER_STATEMENT_CONTENT_TABS_QS_KEY);

export const useStatementUserHistoryInParams = () => {
  const historyChangesList = useSelector((state) => StatementsHistorySelectors.getStatementHistoryChangesListByFilters(state));
  const listHistoryIsPending = useSelector((state) => StatementsHistorySelectors.listByFiltersIsPending(state));
  const listByFiltersCallData = useSelector((state) => StatementsHistorySelectors.getListByFiltersCallData(state));
  const listByFiltersPagination = useSelector((state) => StatementsHistorySelectors.listByFiltersPagination(state));

  return {
    historyChangesList,
    listHistoryIsPending,
    listByFiltersCallData,
    listByFiltersPagination,
  };
};
