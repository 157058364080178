import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'YearPeriod';

export const propTypes = PropTypes.shape({
  year: PropTypes.number,
  period: PropTypes.number,
});

export const transform = (yearPeriod) => {
  if (!yearPeriod) {
    return null;
  }

  return ({
    year: manageNumberAttribute(yearPeriod, 'year'),
    period: manageNumberAttribute(yearPeriod, 'period'),
  });
};

export const transformList = (yearPeriods) => yearPeriods.map((yearPeriod) => transform(yearPeriod));
