import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  NOTEBOOK_COPY_TO_CLIPBOARD_ERROR: {
    code: 'notifications.notebooks.error.copyConnectionConnectorDataframeNameToClipboard',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_ARCHIVE_SUCCESS: {
    code: 'notifications.notebooks.success.archive',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_UNARCHIVE_SUCCESS: {
    code: 'notifications.notebooks.success.unarchive',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_DELETION_SUCCESS: {
    code: 'notifications.notebooks.success.delete',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_ADD_NEW_DATA_SUCCESS: {
    code: 'notifications.notebooks.success.addNewDataConnection',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_EDIT_DATA_SUCCESS: {
    code: 'notifications.notebooks.success.editDataConnection',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_COPY_TO_CLIPBOARD_SUCCESS: {
    code: 'notifications.notebooks.success.copyConnectionConnectorDataframeNameToClipboard',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_REQUIRED_INFORMATION_ERROR: {
    code: 'notebookCell.errors.type.notebook.required.information',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_CELL_REQUIRED_INFORMATION_ERROR: {
    code: 'notebookCell.errors.type.notebook.codeCell.required.information',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_CELL_OUTPUT_NAME_CONFLICT: {
    code: 'notebookCell.errors.type.notebook.codeCell.conflicting.outputName',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_ALREADY_EXISTING_NAME: {
    code: 'notebookCell.errors.type.notebook.already.existing.name',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_FORBIDDEN_CELL_EXPORT: {
    code: 'notebookCell.errors.type.notebook.forbidden.codeCell.export',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_CELL_EXEC_STATUS: {
    code: 'notebookCell.errors.type.notebook.invalid.codeCell.execution.status',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_COLUMN_TYPE: {
    code: 'notebookCell.errors.type.notebook.invalid.column.type',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_LANGUAGE: {
    code: 'notebookCell.errors.type.notebook.invalid.language',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_OUTPUT_LANGUAGE: {
    code: 'notebookCell.errors.type.notebook.invalid.output.language',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_GROUP: {
    code: 'notebookCell.errors.type.notebook.invalid.group',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_STATUS: {
    code: 'notebookCell.errors.type.notebook.invalid.status',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_TYPE: {
    code: 'notebookCell.errors.type.notebook.invalid.type',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_INVALID_OUPUT_UID_ERROR: {
    code: 'notebookCell.errors.type.notebook.invalid.output.unique.identifier.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_STATUS_LANGUAGE_ERROR: {
    code: 'notebookCell.errors.type.notebook.status.language',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_OUPUT_NAME_LOCKED: {
    code: 'notebookCell.errors.type.notebook.codeCell.outputName.locked',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_EMPTY_OUPUT_ERROR: {
    code: 'notebookCell.errors.type.notebook.empty.output.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_CELL_NOT_EXISTS: {
    code: 'notebookCell.errors.type.notebook.codeCell.not.exists',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_CONNECTOR_NOT_FOUND: {
    code: 'notebookCell.errors.type.notebook.connector.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_NOT_EXISTING_ERROR: {
    code: 'notebookCell.errors.type.notebook.not.existing',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_FORBIDDEN_UPDATE: {
    code: 'notebookCell.errors.type.notebook.forbidden.update',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_RUN_ERROR: {
    code: 'notebookCell.errors.type.notebook.run.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_OUTPUT_UID_LOCKED: {
    code: 'notebookCell.errors.type.notebook.output.unique.identifier.locked',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_PYTHON_ERROR: {
    code: 'notebookCell.errors.type.notebook.python.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  NOTEBOOK_UNEXPORTABLE_LANGUAGE: {
    code: 'notebookCell.errors.type.notebook.unexportable.language',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
