/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import { original } from 'immer';

/*
 * Initial State
 */
const initialState = {
  getListIsPending: false,
  getTeamByIdIsPending: false,
  removeMemberFromTeamIsPending: false,
  addMembersToTeamIsPending: false,
  createTeamIsPending: false,
  deleteTeamIsPending: false,
  updateTeamNameIsPending: false,
  moveTeamIsPending: false,
  getTeamsForUserIsPending: false,

  list: [],
  byId: {},
  teamsByUserId: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get List */
    getList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { teams = [] } = payload;

      state.list = _map(teams, 'id');
      state.byId = _merge(state.byId, _keyBy(teams, 'id'));
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Get Team By Id */
    getTeamById: (state) => {
      state.getTeamByIdIsPending = true;
    },
    setTeam: (state, { payload }) => {
      const { team = null } = payload;

      if (team !== null) {
        const clonedById = _cloneDeep(original(state.byId));
        clonedById[team.id] = team;
        state.byId = clonedById;
      }
    },
    getTeamByIdCompleted: (state) => {
      state.getTeamByIdIsPending = false;
    },
    /* Update Team Member type */
    updateMemberType: (state) => {
      state.updateMemberTypeIsPending = true;
    },
    updateMemberTypeCompleted: (state) => {
      state.updateMemberTypeIsPending = false;
    },
    /* Remove Member from Team */
    removeMemberFromTeam: (state) => {
      state.removeMemberFromTeamIsPending = true;
    },
    removeMemberFromTeamCompleted: (state) => {
      state.removeMemberFromTeamIsPending = false;
    },
    /* Add Members to Team */
    addMembersToTeam: (state) => {
      state.addMembersToTeamIsPending = true;
    },
    addMembersToTeamCompleted: (state) => {
      state.addMembersToTeamIsPending = false;
    },
    /* Create a Team */
    createTeam: (state) => {
      state.createTeamIsPending = true;
    },
    createTeamCompleted: (state) => {
      state.createTeamIsPending = false;
    },
    /* Delete a Team */
    deleteTeam: (state) => {
      state.deleteTeamIsPending = true;
    },
    deleteTeamCompleted: (state) => {
      state.deleteTeamIsPending = false;
    },
    /* Update Team Name */
    updateTeamName: (state) => {
      state.updateTeamNameIsPending = true;
    },
    updateTeamNameCompleted: (state) => {
      state.updateTeamNameIsPending = false;
    },
    /* Move Team */
    moveTeam: (state) => {
      state.moveTeamIsPending = true;
    },
    moveTeamCompleted: (state) => {
      state.moveTeamIsPending = false;
    },
    /* Get Teams for User */
    getTeamsForUser: (state) => {
      state.getTeamsForUserIsPending = true;
    },
    setTeamsForUser: (state, { payload }) => {
      const { teams = [], userId } = payload;

      const updatedTeamsList = {
        [userId]: _map(teams, 'id'),
      };

      state.teamsByUserId = _merge(_cloneDeep(original(state.teamsByUserId)), updatedTeamsList);

      state.byId = _merge(state.byId, _keyBy(teams, 'id'));
    },
    getTeamsForUserCompleted: (state) => {
      state.getTeamsForUserIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getList = (state) => root(state).list;
const getById = (state) => root(state).byId;
const getTeamsByUserId = (state) => root(state).teamsByUserId;

const getListIsPending = (state) => root(state).getListIsPending;
const getTeamByIdIsPending = (state) => root(state).getTeamByIdIsPending;
const removeMemberFromTeamIsPending = (state) => root(state).removeMemberFromTeamIsPending;
const addMembersToTeamIsPending = (state) => root(state).addMembersToTeamIsPending;
const createTeamIsPending = (state) => root(state).createTeamIsPending;
const deleteTeamIsPending = (state) => root(state).deleteTeamIsPending;
const updateTeamNameIsPending = (state) => root(state).updateTeamNameIsPending;
const moveTeamIsPending = (state) => root(state).moveTeamIsPending;
const getTeamsForUserIsPending = (state) => root(state).getTeamsForUserIsPending;

const getTeamsList = createSelector(
  [getList, getById],
  (listIds, byId) => listIds.map((teamId) => (byId[teamId] || null)).filter(Boolean),
);
const getTeamById = createSelector(
  [getById, getProps],
  (byId, { teamId }) => (byId[teamId] || null),
);
const getTeamsListById = getById;

const getTeamsForUser = createSelector(
  [getTeamsByUserId, getById, getProps],
  (teamsByUserId, byId, { userId }) => (teamsByUserId[userId] || []).map((teamId) => (byId[teamId] || null)).filter(Boolean),
);

const getNbOfTeams = createSelector(
  [getById],
  (byId) => Object.keys(byId).length,
);

export const selectors = {
  getListIsPending,
  getTeamByIdIsPending,
  removeMemberFromTeamIsPending,
  addMembersToTeamIsPending,
  createTeamIsPending,
  deleteTeamIsPending,
  updateTeamNameIsPending,
  moveTeamIsPending,
  getTeamsForUserIsPending,

  getTeamsList,
  getTeamsListById,
  getTeamById,
  getTeamsForUser,
  getNbOfTeams,
};
