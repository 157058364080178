import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import LinkFilled from '@palette/components/utils/Icons/LinkFilled';
import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanSettingsCommissionRulesSteps from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesSteps/MasterPlanSettingsCommissionRulesSteps';
import MasterPlanSettingsPayoutRulesSteps from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRulesSteps/MasterPlanSettingsPayoutRulesSteps';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';

import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { getFrequencyName, getPlanFirstPeriod, getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import {
  CREATE_PLAN_FLOW_STEPS,
  DRAFT_PLAN_ID_QS_KEY,
  DRAFT_PLAN_STEP_QS_KEY,
  SCOPES,
} from '@palette/constants/masterPlans';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './CreatePlanFlowSummaryStep.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowSummaryStep = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planFirstPeriod = useMemo(() => getPlanFirstPeriod(plan), [plan]);

  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: plan.id }));
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId: plan.quotaId }));

  useEffect(() => {
    dispatch(MasterPlansActions.getPlanUsersAndManagers({ planId: plan.id }));
  }, [plan]);

  useEffect(() => {
    if (!quota && plan.quotaId !== null) {
      dispatch(QuotasActions.getById({ quotaId: plan.quotaId }));
    }
  }, [plan, quota]);

  const connectorsList = useConnectorsList();

  const trackingObjectConnector = useMemo(() => (
    connectorsList.find((connectorItem) => (connectorItem.id === plan.trackingObject?.connectorId))
  ), [plan, connectorsList]);

  const usersDefinitionConnector = useMemo(() => (
    connectorsList.find((connectorItem) => (connectorItem.id === plan.usersDefinition?.connectorId))
  ), [plan, connectorsList]);

  const planTypeNode = useMemo(() => (
    <div className={styles.cardItem}>
      <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.planType')}</div>
      <div className={styles.cardItemRight}>{`${t(`planScope.${plan.scope.toLowerCase()}.label`)} - ${getFrequencyName(plan)}`}</div>
    </div>
  ), [plan]);

  const planFirstPeriodNode = useMemo(() => (
    <div className={styles.cardItem}>
      <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.firstPeriod')}</div>
      <div className={styles.cardItemRight}>{getPlanPeriodName(plan, planFirstPeriod)}</div>
    </div>
  ), [plan, planFirstPeriod]);

  const planLastPeriodNode = useMemo(() => plan.endPeriod && (
    <div className={styles.cardItem}>
      <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.lastPeriod')}</div>
      <div className={styles.cardItemRight}>{getPlanPeriodName(plan, plan.endPeriod)}</div>
    </div>
  ), [plan]);

  const planTrackingNode = useMemo(() => (
    <div className={styles.cardItem}>
      <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.tracking')}</div>
      <div className={styles.cardItemRight}>{`${trackingObjectConnector?.type} - ${plan.trackingObject?.type}`}</div>
    </div>
  ), [plan, trackingObjectConnector]);

  const usersManagersNode = useMemo(() => {
    let managersCountNode = null;

    if (plan.scope === SCOPES.MANAGER) {
      managersCountNode = (
        <div className={styles.cardItem}>
          <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.numberManagers')}</div>
          <div className={styles.cardItemRight}>{planUsersAndManagers.managers.length}</div>
        </div>
      );
    }

    return (
      <>
        <div className={styles.cardItem}>
          <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.numberUsers')}</div>
          <div className={styles.cardItemRight}>{planUsersAndManagers.users.length}</div>
        </div>
        {managersCountNode}
      </>
    );
  }, [plan, planUsersAndManagers]);

  const dataSourceNode = useMemo(() => (
    <div className={styles.cardItem}>
      <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.dataSource')}</div>
      <div className={styles.cardItemRight}>{`${usersDefinitionConnector?.type} - ${plan.usersDefinition?.type}`}</div>
    </div>
  ), [plan, usersDefinitionConnector]);

  const quotaNode = useMemo(() => {
    if (quota) {
      let quotaLink = (
        <Link
          className={styles.quotaLink}
          path={routePaths.v2.quotaDetails}
          params={{ quotaId: quota.id }}
        >
          {quota.name}
        </Link>
      );

      if (plan.scope === SCOPES.TEAM || plan.periodType === PERIOD_TYPES.CUSTOM) {
        quotaLink = (
          <Link
            className={styles.quotaLink}
            path={routePaths.v2.createPlanFlow}
            qsObject={{
              [DRAFT_PLAN_ID_QS_KEY]: plan.id,
              [DRAFT_PLAN_STEP_QS_KEY]: CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value,
            }}
          >
            {t('createPlanFlowSummaryStep.quota.view')}
          </Link>
        );
      }

      return (
        <div className={styles.content}>
          {t('createPlanFlowSummaryStep.quota.linked')}
          <LinkFilled className={styles.iconLinked} width={24} height={24} />
          {quotaLink}
        </div>
      );
    }

    return (
      <div className={styles.content}>
        {t('createPlanFlowSummaryStep.quota.noQuota')}
      </div>
    );
  }, [plan, quota]);

  const commissionRulesNode = useMemo(() => {
    let contentNode = (
      <MasterPlanSettingsCommissionRulesSteps plan={plan} />
    );

    if (plan.rules.length === 0) {
      let emptyStr = t('createPlanFlowSummaryStep.noRules');

      if (!quota) {
        emptyStr = t('createPlanFlowSummaryStep.noRules.because.noQuota');
      }

      contentNode = (
        <div
          className={classNames({
            content: true,
            empty: true,
          })}
        >
          {emptyStr}
        </div>
      );
    }

    return contentNode;
  }, [plan, quota]);

  const payoutRulesNode = useMemo(() => {
    let contentNode = (
      <>
        {plan.payoutRulesDescription && (
          <div className={styles.subtitle}>{plan.payoutRulesDescription}</div>
        )}
        <MasterPlanSettingsPayoutRulesSteps plan={plan} forSummary />
      </>
    );

    if (plan.payoutRules.length === 0 || plan.rules.length === 0) {
      let emptyStr = t('createPlanFlowSummaryStep.noRules');

      if (!quota) {
        emptyStr = t('createPlanFlowSummaryStep.noRules.because.noQuota');
      }

      if (plan.rules.length === 0) {
        emptyStr = t('createPlanFlowSummaryStep.noRules.because.noCommission');
      }

      contentNode = (
        <div
          className={classNames({
            content: true,
            empty: true,
          })}
        >
          {emptyStr}
        </div>
      );
    }

    return contentNode;
  }, [plan, quota]);

  const actionsNode = useMemo(() => (
    <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.SUMMARY.value} />
  ), [plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.summaryStep}>
        <div className={styles.title}>{t('createPlanFlowSummaryStep.general')}</div>
        <div className={styles.card}>
          <div className={styles.cardItem}>
            <div className={styles.cardItemLeft}>{t('createPlanFlowSummaryStep.planName')}</div>
            <div className={styles.cardItemRight}>{plan.name}</div>
          </div>
          {planTypeNode}
          {planFirstPeriodNode}
          {planLastPeriodNode}
          {planTrackingNode}
        </div>
      </div>
      <div className={styles.summaryStep}>
        <div className={styles.title}>{t('createPlanFlowSummaryStep.users')}</div>
        <div className={styles.card}>
          {usersManagersNode}
          {dataSourceNode}
        </div>
      </div>
      <div className={styles.summaryStep}>
        <div className={styles.title}>{t('createPlanFlowSummaryStep.quota')}</div>
        {quotaNode}
      </div>
      <div
        className={classNames({
          summaryStep: true,
          rulesContainer: true,
        })}
      >
        <div className={styles.title}>{t('createPlanFlowSummaryStep.commissionRules')}</div>
        {commissionRulesNode}
      </div>
      <div
        className={classNames({
          summaryStep: true,
          rulesContainer: true,
        })}
      >
        <div className={styles.title}>{t('createPlanFlowSummaryStep.payoutRules')}</div>
        {payoutRulesNode}
      </div>
      {actionsNode}
    </div>
  );
};

CreatePlanFlowSummaryStep.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowSummaryStep.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowSummaryStep;
