import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageNumberAttribute,
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
} from '@palette/helpers/ModelHelper';

import { DEFAULT_PERIOD_VALUE, SCOPES } from '@palette/constants/masterPlans';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'QuotaPlan';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  scope: PropTypes.oneOf(Object.values(SCOPES)),
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  frequency: PropTypes.number,
  beginPeriod: YearPeriodModel.propTypes,
  endPeriod: YearPeriodModel.propTypes,
  customBeginAt: PropTypes.string,
  users: PropTypes.arrayOf(MetaUserModel.propTypes),
  usersCount: PropTypes.number,
});

export const manageQuotaPlanScope = (quotaPlan) => (SCOPES[manageStringAttribute(quotaPlan, 'scope')] || SCOPES.INDIVIDUAL);

export const manageQuotaPlanPeriodType = (quotaPlan) => (PERIOD_TYPES[manageStringAttribute(quotaPlan, 'periodType')] || PERIOD_TYPES.MONTH);

export const transform = (quotaPlan) => {
  if (!quotaPlan) {
    return null;
  }

  const frequency = manageNumberAttribute(quotaPlan, 'frequency', 1);

  const beginPeriodRaw = manageFreeShapeObjectAttribute(quotaPlan, 'beginPeriod', null) || DEFAULT_PERIOD_VALUE;
  const beginPeriod = YearPeriodModel.transform(beginPeriodRaw);

  const endPeriodRaw = manageFreeShapeObjectAttribute(quotaPlan, 'endPeriod', null);
  const endPeriod = YearPeriodModel.transform(endPeriodRaw);

  return (
    {
      id: manageStringAttribute(quotaPlan, '_id'),
      name: manageStringAttribute(quotaPlan, 'name'),
      scope: manageQuotaPlanScope(quotaPlan),
      periodType: manageQuotaPlanPeriodType(quotaPlan),
      frequency,
      beginPeriod,
      endPeriod,
      customBeginAt: manageDateAttribute(quotaPlan, 'customBeginAt'),
      users: MetaUserModel.transformList(manageArrayAttribute(quotaPlan, 'users')),
      usersCount: manageNumberAttribute(quotaPlan, 'usersCount'),
    }
  );
};

export const transformList = (quotaPlans) => quotaPlans.map((quotaPlan) => transform(quotaPlan));
