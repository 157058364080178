import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import DeactivateUserModal from '@palette/components/userDetails/DeactivateUserModal/DeactivateUserModal';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './DeactivateUserButton.less';

const classNames = bindClassNames.bind(styles);

const DeactivateUserButton = ({ className, user }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [deactivateUserModalIsVisible, showDeactivateUserModal] = useState(false);

  const deactivateUserButtonNode = useMemo(() => (
    <Button
      type="secondary"
      icon={<ClosePopupFilled />}
      onClick={() => showDeactivateUserModal(true)}
    >
      {t('deactivateUserButton.label')}
    </Button>
  ), [showDeactivateUserModal]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.USERS.TOGGLE_ACTIVATE]) || user.account === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {deactivateUserButtonNode}
      {deactivateUserModalIsVisible && (
        <DeactivateUserModal
          visible
          onClose={() => showDeactivateUserModal(false)}
          user={user}
        />
      )}
    </div>
  );
};

DeactivateUserButton.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

DeactivateUserButton.defaultProps = {
  className: '',
};

export default DeactivateUserButton;
