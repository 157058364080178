import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import RemoveMemberFromTeamModal from '@palette/components/team/RemoveMemberFromTeamModal/RemoveMemberFromTeamModal';
import RolesFilled from '@palette/components/utils/Icons/RolesFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useTeamIdInParams } from '@palette/hooks/TeamHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { TEAM_USER_TYPES } from '@palette/constants/team';

import * as TeamUserModel from '@palette/models/TeamUser';

import { actions as TeamsActions } from '@palette/state/Teams';

import styles from './TeamMembersListItemMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const TeamMembersListItemMoreOptionsMenu = ({ className, member, onOpenChange }) => {
  const profile = useProfile();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { team } = useTeamIdInParams();

  const [removeMemberFromTeamModalIsVisible, showRemoveMemberFromTeamModal] = useState(false);

  const closeMoreMenu = useCallback(() => {
    if (onOpenChange !== null) {
      onOpenChange(false);
    }
  }, [onOpenChange]);

  const moreMenuItems = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.MEMBERS.MANAGE]) || team === null) return [];

    const menuItems = [];

    const teamLeadManagementMenuItem = {
      key: 'teamLeadManagement',
      title: member.type === TEAM_USER_TYPES.MANAGER ? t('teamMembersListItemMoreOptionsMenu.unsetTeamLeadRole') : t('teamMembersListItemMoreOptionsMenu.setAsTeamLead'),
      icon: (
        <RolesFilled width={16} height={16} />
      ),
      onClick: () => {
        const newType = member.type === TEAM_USER_TYPES.MANAGER ? TEAM_USER_TYPES.CONTRIBUTOR : TEAM_USER_TYPES.MANAGER;
        dispatch(TeamsActions.updateMemberType({ teamId: team.id, userId: member.user.id, type: newType }));
      },
    };

    menuItems.push(teamLeadManagementMenuItem);

    const removeMemberFromTeamMenuItem = {
      key: 'removeMemberFromTeam',
      title: t('teamMembersListItemMoreOptionsMenu.removeMemberFromTeam'),
      icon: (
        <TrashFilled width={16} height={16} />
      ),
      onClick: () => {
        showRemoveMemberFromTeamModal(true);
        closeMoreMenu();
      },
    };

    menuItems.push(removeMemberFromTeamMenuItem);

    return menuItems;
  }, [
    profile,
    team,
    member,
    showRemoveMemberFromTeamModal,
    closeMoreMenu,
  ]);

  const moreMenuProps = useMemo(() => {
    if (onOpenChange === null) return {};

    return {
      onVisibleChange: onOpenChange,
    };
  }, [onOpenChange]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.MEMBERS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={moreMenuItems}
        {...moreMenuProps}
      />
      {
        removeMemberFromTeamModalIsVisible && (
          <RemoveMemberFromTeamModal visible onClose={() => showRemoveMemberFromTeamModal(false)} team={team} member={member} />
        )
      }
    </div>
  );
};

TeamMembersListItemMoreOptionsMenu.propTypes = {
  member: TeamUserModel.propTypes.isRequired,
  className: PropTypes.string,
  onOpenChange: PropTypes.func,
};

TeamMembersListItemMoreOptionsMenu.defaultProps = {
  className: '',
  onOpenChange: null,
};

export default TeamMembersListItemMoreOptionsMenu;
