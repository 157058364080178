import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddLine from '@palette/components/utils/Icons/AddLine';
import AddDataConnectionModal from '@palette/components/planV3/DataSources/AddDataConnectionModal/AddDataConnectionModal';

import { actions as PlanV3Actions } from '@palette/state/PlanV3';

import styles from './AddDataConnectionButton.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionButton = ({ className, isPending }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [addDataConnectionModalIsVisible, showAddDataConnectionModal] = useState(false);

  useEffect(() => dispatch(PlanV3Actions.resetDataConnections()), []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="secondary"
        icon={<AddLine />}
        onClick={() => showAddDataConnectionModal(true)}
        disabled={isPending}
      >
        {t('addDataConnectionButton.label')}
      </Button>
      {
        addDataConnectionModalIsVisible && !isPending && (
          <AddDataConnectionModal
            visible
            onClose={() => showAddDataConnectionModal(false)}
          />
        )
      }
    </div>
  );
};

AddDataConnectionButton.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
};

AddDataConnectionButton.defaultProps = {
  className: '',
  isPending: false,
};

export default AddDataConnectionButton;
