import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import _difference from 'lodash/difference';

import PlanV3InputTable from '@palette/components/planV3/PlanV3InputTable/PlanV3InputTable';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { INPUT_TABLE_CELL_TYPES } from '@palette/constants/global';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';
import * as PlanV3InputTableModel from '@palette/models/PlanV3InputTable';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3PeriodsInputTable.less';

const classNames = bindClassNames.bind(styles);

const PlanV3PeriodsInputTable = ({ className, details, inputTable }) => {
  const dispatch = useDispatch();

  const { planId, periods } = details;
  const inputTablesListIsPending = useSelector(PlanV3Selectors.getInputTablesListIsPending);
  const createInputTableIsPending = useSelector(PlanV3Selectors.getCreateInputTableIsPending);

  useEffect(() => {
    if (inputTable != null) {
      const savedPeriods = inputTable.columns.map((column) => column.label);
      const currentPeriods = periods.map((period) => period.periodId);

      const toRemovePeriods = _difference(savedPeriods, currentPeriods);
      const toAddPeriods = _difference(currentPeriods, savedPeriods);

      if (toRemovePeriods.length > 0) {
        const toDeleteColumnsIds = inputTable.columns.filter((column) => toRemovePeriods.includes(column.label)).map((column) => column.id);

        dispatch(PlanV3Actions.deleteMultipleColumnsFromInputTable({ planId, inputTableId: inputTable.id, columnsIds: toDeleteColumnsIds, refreshInputTable: toAddPeriods.length === 0 }));
      }

      if (toAddPeriods.length > 0) {
        const toAddColumns = toAddPeriods.map((periodId, i) => ({
          label: periodId,
          position: inputTable.columns.length + 1 + i,
          type: INPUT_TABLE_CELL_TYPES.STRING,
          values: inputTable.rows.map((row) => ({
            rowPosition: row.position,
            value: '',
          })),
        }));

        dispatch(PlanV3Actions.addMultipleColumnsToInputTable({ planId, inputTableId: inputTable.id, columns: toAddColumns }));
      }
    }
  }, [inputTable, periods]);

  const tableColumns = useMemo(() => {
    if (!inputTable) return [];

    return inputTable.columns.map((column) => ({
      id: column.id,
      Header: column.label,
      accessor: column.id,
      minWidth: 150,
      cellType: column.type,
    }));
  }, [inputTable]);

  const tableData = useMemo(() => {
    if (!inputTable) return [];

    return inputTable.rows.map((row) => row.values.reduce((res, rowValue) => {
      res[rowValue.columnId] = rowValue.value;

      return res;
    }, {}));
  }, [inputTable]);

  const tableNode = useMemo(() => {
    if (inputTable == null || createInputTableIsPending) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <PlanV3InputTable
        columns={tableColumns}
        data={tableData}
        stickyHeader
        fitInContainer
        disableAddColumnAction
        disableColumnActions
        planId={planId}
        inputTable={inputTable}
      />
    );
  }, [
    inputTablesListIsPending,
    createInputTableIsPending,
    tableColumns,
    tableData,
    planId,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {tableNode}
    </div>
  );
};

PlanV3PeriodsInputTable.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
  inputTable: PlanV3InputTableModel.propTypes.isRequired,
};

PlanV3PeriodsInputTable.defaultProps = {
  className: '',
};

export default PlanV3PeriodsInputTable;
