import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import UserStatementCorrectionsItemActions from '@palette/components/statement/userStatement/UserStatementCorrectionsItemActions/UserStatementCorrectionsItemActions';
import ChevronLeftLine from '@palette/components/utils/Icons/ChevronLeftLine';
import CorrectionSuggestedAmount from '@palette/components/statement/userStatement/CorrectionSuggestedAmount/CorrectionSuggestedAmount';

import { getPeriodName, getPeriodDates } from '@palette/helpers/StatementHelper';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import routePaths from '@palette/config/routePaths';

import { STATEMENT_EVENTS } from '@palette/constants/analytics';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as UserStatementCorrectionModel from '@palette/models/UserStatementCorrection';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './UserStatementInvestigateHeader.less';

const classNames = bindClassNames.bind(styles);

const UserStatementInvestigateHeader = ({ className, userStatement, statementPeriod, correction, currency }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const periodName = getPeriodName(statementPeriod.statementStrategy, statementPeriod.period);
  const periodDates = getPeriodDates(statementPeriod.beginDate, statementPeriod.endDate);

  const { from } = correction;

  const fromPeriodName = getPeriodName(from.periodStrategy, from.period, from.year);

  const handleBackClick = useCallback(() => {
    dispatch(AnalyticsActions.sendEvent({
      event: STATEMENT_EVENTS.CLICK_BACK_TO_STATEMENT_LINK,
      params: {
        STATEMENT_PERIOD_ID: statementPeriod.id,
        USER_ID: userStatement.user.id,
      },
    }));
  }, [statementPeriod, userStatement]);

  const handleFromWhichClick = useCallback(() => {
    dispatch(AnalyticsActions.sendEvent({
      event: STATEMENT_EVENTS.CLICK_FROM_WHICH_STATEMENT_LINK,
      params: {
        STATEMENT_PERIOD_ID: from.periodId,
        USER_ID: userStatement.user.id,
      },
    }));
  }, [from, userStatement]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Link
        className={styles.backLink}
        path={routePaths.v2.statementsUserStatement}
        params={{ statementPeriodId: statementPeriod.id, userId: userStatement.user.id }}
        qsObject={periodsDatesFilterQSObject}
        displayIcon
        iconIsAfter={false}
        iconNode={<ChevronLeftLine className={styles.icon} width={18} height={18} />}
        maxWidth="26"
        onClick={handleBackClick}
      >
        {`${periodName} - ${periodDates}`}
      </Link>
      <UserProfile
        className={styles.userContainer}
        user={userStatement.user}
        vertical
        clickable
      />
      <Link
        className={styles.fromLink}
        path={routePaths.v2.statementsUserStatement}
        params={{ statementPeriodId: from.periodId, userId: userStatement.user.id }}
        qsObject={periodsDatesFilterQSObject}
        maxWidth="26"
        onClick={handleFromWhichClick}
      >
        {t('userStatementCorrections.content.default.reason', { dates: fromPeriodName })}
      </Link>
      <CorrectionSuggestedAmount className={styles.suggestedAmount} correction={correction} />
      <UserStatementCorrectionsItemActions
        className={styles.actionsContainer}
        userStatement={userStatement}
        statementPeriod={statementPeriod}
        correction={correction}
        reason={t('userStatementCorrections.content.default.reason', { dates: fromPeriodName })}
        currency={currency}
        needRedirectOnCB
      />
    </div>
  );
};

UserStatementInvestigateHeader.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes.isRequired,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  correction: UserStatementCorrectionModel.propTypes.isRequired,
  currency: PropTypes.string,
};

UserStatementInvestigateHeader.defaultProps = {
  className: '',
  currency: null,
};

export default UserStatementInvestigateHeader;
