import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import AmountDescriptionWidgetContent from '@palette/components/widgetContents/AmountDescriptionWidgetContent/AmountDescriptionWidgetContent';
import Link from '@palette/components/designSystem/Link/Link';
import EyesLine from '@palette/components/utils/Icons/EyesLine';

import {
  SEARCHED_FROM_QS_KEY,
  SEARCHED_STATUS_QS_KEY, SEARCHED_TO_QS_KEY,
  STATUSES_OPTIONS_KEYS,
} from '@palette/constants/commissions';

import routePaths from '@palette/config/routePaths';

import * as CommissionsUnreleasedDataModel from '@palette/models/widgets/common/CommissionsUnreleasedData';

import styles from './CommissionsUnreleasedWidgetDashboardContent.less';

const classNames = bindClassNames.bind(styles);

const CommissionsUnreleasedWidgetDashboardContent = ({ className, data, disableLink }) => {
  const { t } = useTranslation();

  const commissionListPageLinkNode = useMemo(() => {
    const qsObject = {
      [SEARCHED_STATUS_QS_KEY]: STATUSES_OPTIONS_KEYS.PARTIALLY_RELEASED_AND_ON_HOLD.toLowerCase(),
      [SEARCHED_FROM_QS_KEY]: '',
      [SEARCHED_TO_QS_KEY]: '',
    };

    return (
      <Link
        className={styles.viewLink}
        path={routePaths.v2.myCommissionList}
        qsObject={qsObject}
        displayIcon
        iconNode={<EyesLine className={styles.icon} width={12} height={12} />}
        iconIsAfter={false}
        disabled={disableLink}
      >
        {t('commissionsUnreleasedWidgetContent.viewDetails')}
      </Link>
    );
  }, [disableLink]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <AmountDescriptionWidgetContent
        className={styles.content}
        amount={data.commissionsUnreleasedAmount}
        currency={data.currency}
        description={t('commissionsUnreleasedWidgetContent.description')}
        extraNode={commissionListPageLinkNode}
      />
    </div>
  );
};

CommissionsUnreleasedWidgetDashboardContent.propTypes = {
  className: PropTypes.string,
  data: CommissionsUnreleasedDataModel.propTypes.isRequired,
  disableLink: PropTypes.bool,
};

CommissionsUnreleasedWidgetDashboardContent.defaultProps = {
  className: '',
  disableLink: false,
};

export default CommissionsUnreleasedWidgetDashboardContent;
