import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import {
  PLAN_SETTINGS_TABS_QS_KEY,
  PLAN_SETTINGS_TABS_IDS,
  USER_DETAILS_TABS_QS_KEY,
  USER_DETAILS_TABS_IDS,
} from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import * as UserDeleteFromPlanStrategyModel from '@palette/models/statementHistoryStrategies/UserDeleteFromPlanStrategy';

const classNames = bindClassNames.bind();

const UserDeleteFromPlanContentStrategy = ({ className, details, i18nAction }) => {
  const {
    planId,
    planName,
    payee,
  } = details;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey={`statement.history.list.content.${i18nAction}`}
        values={{
          payee: payee.displayName,
          name: planName,
        }}
        components={[
          <Link
            key={`${i18nAction}-user-link`}
            path={routePaths.v2.userDetails}
            params={{ userId: payee.id }}
            qsObject={{
              [USER_DETAILS_TABS_QS_KEY]: USER_DETAILS_TABS_IDS.PLANS,
            }}
          />,
          <Link
            key={`${i18nAction}-plan-settings-link`}
            path={routePaths.v2.planSettings}
            params={{ masterPlanId: planId }}
            qsObject={{
              [PLAN_SETTINGS_TABS_QS_KEY]: PLAN_SETTINGS_TABS_IDS.USERS,
            }}
          />,
        ]}
      />
    </span>
  );
};

UserDeleteFromPlanContentStrategy.propTypes = {
  className: PropTypes.string,
  details: UserDeleteFromPlanStrategyModel.propTypes.isRequired,
  i18nAction: PropTypes.string.isRequired,
};

UserDeleteFromPlanContentStrategy.defaultProps = {
  className: '',
};

export default UserDeleteFromPlanContentStrategy;
