import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import FolderOpenFilled from '@palette/components/utils/Icons/FolderOpenFilled';

import { PALETTE_DOCUMENTATION_URL } from '@palette/constants/global';

import styles from './RawDataDocumentationLink.less';

const classNames = bindClassNames.bind(styles);

const DOCUMENTATION_URL = `${PALETTE_DOCUMENTATION_URL}managing-commissions/commission-audit/#how-to-update-a-commission`;

const RawDataDocumentationLink = ({ className }) => {
  const { t } = useTranslation();

  const goToDocumentation = () => {
    window.open(DOCUMENTATION_URL, '_blank');
  };

  return (
    <Button
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      type="link"
      onClick={goToDocumentation}
      icon={<FolderOpenFilled />}
    >
      {t('resources.documentation.rawData')}
    </Button>
  );
};

RawDataDocumentationLink.propTypes = {
  className: PropTypes.string,
};

RawDataDocumentationLink.defaultProps = {
  className: '',
};

export default RawDataDocumentationLink;
