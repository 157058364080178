import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import SettingsFilled from '@palette/components/utils/Icons/SettingsFilled';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import MasterPlanPeriodFreezeButton from '@palette/components/masterPlanPeriod/MasterPlanPeriodFreezeButton/MasterPlanPeriodFreezeButton';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { isPeriodCurrentOne } from '@palette/helpers/MasterPlanHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { RIGHTS } from '@palette/constants/profile';
import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanTopActions.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanTopActions = ({ className, plan, period }) => {
  const profile = useProfile();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let analyticsEventsPath = MASTER_PLANS_EVENTS.PERIOD_LIST;
  if (period) analyticsEventsPath = MASTER_PLANS_EVENTS.PERIOD_DETAILS;

  const computePlanIsPending = useSelector(MasterPlansSelectors.computePlanIsPending);

  const handleClickSettingsButton = useCallback(() => {
    dispatch(AnalyticsActions.sendEvent({ event: analyticsEventsPath.CLICK_SETTINGS }));
    redirectTo({ path: routePaths.v2.planSettings, params: { masterPlanId: plan.id } });
  }, [analyticsEventsPath, plan]);

  const handleClickComputeButton = () => {
    if (computePlanIsPending) return;

    dispatch(AnalyticsActions.sendEvent({ event: analyticsEventsPath.CLICK_RECOMPUTE }));
    dispatch(MasterPlansActions.computePlan({ planId: plan.id }));
  };

  const freezeBtnNode = useMemo(() => {
    const isCurrent = period && isPeriodCurrentOne(plan, period);

    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.UPDATE]) || !period || isCurrent) return null;

    const handleConfirmFreezeAction = (action) => {
      dispatch(AnalyticsActions.sendEvent({
        event: analyticsEventsPath.CLICK_FREEZE,
        params: { action },
      }));
    };

    return (
      <MasterPlanPeriodFreezeButton
        className={styles.item}
        plan={plan}
        period={period}
        onConfirm={handleConfirmFreezeAction}
      />
    );
  }, [profile, plan, period]);

  const settingsBtnNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [
      RIGHTS.ADMIN.PLANS.UPDATE,
      RIGHTS.ADMIN.PLANS.GBO.MANAGE,
      RIGHTS.ADMIN.PLANS.UPDATE_CONNECTOR,
    ])) return null;

    return (
      <Button
        className={styles.item}
        type="linkSecondary"
        icon={<SettingsFilled width={16} height={16} />}
        onClick={handleClickSettingsButton}
      >
        {t('masterPlan.topActions.settings')}
      </Button>
    );
  }, [profile, handleClickSettingsButton]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {freezeBtnNode}
      {settingsBtnNode}
      <Button
        className={styles.item}
        type="linkSecondary"
        icon={<RefreshLine width={16} height={16} spin={computePlanIsPending} />}
        onClick={!computePlanIsPending ? handleClickComputeButton : undefined}
        disabled={computePlanIsPending}
      >
        {t('masterPlan.topActions.recompute')}
      </Button>
    </div>
  );
};

MasterPlanTopActions.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes,
};

MasterPlanTopActions.defaultProps = {
  className: '',
  period: null,
};

export default MasterPlanTopActions;
