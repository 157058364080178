import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';

import { MIXED_CURRENCY_VALUE } from '@palette/constants/currency';

import { useProfile } from '@palette/hooks/ProfileHooks';

import styles from './CompanyCurrencySelect.less';

const classNames = bindClassNames.bind(styles);

const CompanyCurrencySelect = ({ className, enableMixedCurrencyOption, defaultMixedCurrencyOptionValue, ...otherProps }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const currenciesOptions = useMemo(() => {
    const currencies = profile.userData.company.currencies.map((currency) => ({
      label: currency.code,
      value: currency.code,
    }));

    if (enableMixedCurrencyOption) {
      currencies.unshift({
        label: t('companyCurrencySelect.mixed'),
        value: defaultMixedCurrencyOptionValue,
      });
    }

    return currencies;
  }, [profile, enableMixedCurrencyOption]);

  return (
    <Select
      className={classNames({
        wrapper: true,
        withMixedCurrency: enableMixedCurrencyOption,
        [className]: className !== '',
      })}
      options={currenciesOptions}
      {...otherProps}
    />
  );
};

CompanyCurrencySelect.propTypes = {
  className: PropTypes.string,
  enableMixedCurrencyOption: PropTypes.bool,
  defaultMixedCurrencyOptionValue: PropTypes.string,
};

CompanyCurrencySelect.defaultProps = {
  className: '',
  enableMixedCurrencyOption: false,
  defaultMixedCurrencyOptionValue: MIXED_CURRENCY_VALUE,
};

export default CompanyCurrencySelect;
