import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  QUOTA_403_USER_NOT_IN_SCOPE: {
    code: 'quota.errors.user.not.in.scope',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  QUOTA_404_QUOTA_NOT_FOUND: {
    code: 'quota.errors.quota.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  QUOTA_404_USER_NOT_IN_QUOTA: {
    code: 'quota.errors.user.not.in.quota',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  QUOTA_404_USER_NOT_FOUND: {
    code: 'quota.errors.user.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  QUOTA_409_USER_ALREADY_IN_QUOTA: {
    code: 'quota.errors.user.already.in.quota',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  QUOTA_409_USER_ATTACHED_TO_PLANS_ERROR: {
    code: 'quota.errors.user.attached.to.plans',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
