import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import { getMoment } from '@palette/helpers/MomentHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getPeriodDates, getPeriodName } from '@palette/helpers/StatementHelper';

import { actions as StatementsActions } from '@palette/state/Statements';

import * as StatementSnapshotModel from '@palette/models/StatementSnapshot';

import styles from './StatementsSnapshotsExportListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementsSnapshotsExportListItem = ({ className, snapshot }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const handleExport = useCallback(() => {
    dispatch(StatementsActions.downloadSnapshot({ snapshotId: snapshot.id }));
  }, [
    snapshot,
  ]);

  const periodName = getPeriodName(snapshot.statementStrategy, snapshot.period);
  const periodDates = getPeriodDates(snapshot.beginDate, snapshot.endDate);
  const actionId = t(`statementsSnapshotsExportListItem.actions.${snapshot.snapshotOrigin.toLowerCase()}`);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.row}>
        <div className={styles.row}>
          <div className={styles.periodName}>
            {periodName}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.periodDates}>
            {periodDates}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <UserProfile className={styles.who} user={snapshot.who} />
      </div>
      <div className={styles.row}>
        <div className={styles.row}>
          <div className={styles.actionDone}>
            {t('statementsSnapshotsExportListItem.didActionOn', { action: actionId, date: moment.utc(snapshot.when).format('ll') })}
          </div>
          <div className={styles.amount}>{formatPrice(snapshot.totalAmount, snapshot.currency)}</div>
        </div>
        <div className={styles.actionsContainer}>
          <Button
            className={styles.exportButton}
            type="link"
            icon={<DownloadFilled width={20} height={20} />}
            onClick={handleExport}
          >
            {t('statementsSnapshotsExportListItem.export.label')}
          </Button>
        </div>
      </div>
    </div>
  );
};

StatementsSnapshotsExportListItem.propTypes = {
  className: PropTypes.string,
  snapshot: StatementSnapshotModel.propTypes.isRequired,
};

StatementsSnapshotsExportListItem.defaultProps = {
  className: '',
};

export default StatementsSnapshotsExportListItem;
