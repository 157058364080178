export const GOOGLE_AUTH_ERROR_QS_KEY = 'error';

export const GOOGLE_AUTH_ERROR_DESCRIPTION_QS_KEY = 'error_description';

export const LOGIN_NEXT_QS_KEY = 'next';

export const LOGIN_NEXT_DOCUMENTATION_URI = 'doc.palettehq.com';

export const REGISTER_REF_QS_KEY = 'ref';

export const FIRST_RESET_QS_KEY = 'new';
