import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Select from '@palette/components/designSystem/Select/Select';
import AuditTrailList from '@palette/components/auditTrail/AuditTrailList/AuditTrailList';
import Loader from '@palette/components/utils/Loader/Loader';

import { useAuditTrailPageFilters } from '@palette/hooks/AuditTrailHooks';
import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';
import { useProfile } from '@palette/hooks/ProfileHooks';

import { scrollToTop } from '@palette/helpers/NavigationHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { FILTER_BY_TYPE_OPTIONS, FILTER_BY_TIME_OPTIONS, SEARCHED_SINCE_QS_KEY, SEARCHED_TYPE_QS_KEY } from '@palette/constants/auditTrail';
import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE, LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';
import { AUDIT_TRAIL_EVENTS } from '@palette/constants/analytics';

import { actions as AuditTrailActions, selectors as AuditTrailSelectors } from '@palette/state/AuditTrail';
import { actions as NavigationActions } from '@palette/state/Navigation';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './AuditTrailPageContent.less';

const classNames = bindClassNames.bind(styles);

const AuditTrailPageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useProfile();

  const filterByTypeOptions = Object.keys(FILTER_BY_TYPE_OPTIONS).map((type) => ({
    label: t(`auditTrail.filterByType.option.${type.toLowerCase()}`),
    value: FILTER_BY_TYPE_OPTIONS[type],
  }));

  const filterByTimeOptions = Object.keys(FILTER_BY_TIME_OPTIONS).map((type) => ({
    label: t(`auditTrail.filterByTime.option.${type.toLowerCase()}`),
    value: FILTER_BY_TIME_OPTIONS[type],
  }));

  const [getPaginationLimit, paginationLimitInSearch] = useLimitInSearch();
  const [getPaginationPage, paginationPageInSearch] = usePageInSearch();
  const auditTrailPageFilters = useAuditTrailPageFilters();

  const listByFiltersIsPending = useSelector(AuditTrailSelectors.listByFiltersIsPending);

  const {
    searchedType,
    searchedTypeInSearch,
    searchedSince,
    searchedSinceInSearch,
  } = useMemo(() => auditTrailPageFilters, [auditTrailPageFilters]);

  const getAuditTrailsList = () => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return;

    const listByFiltersPayload = {
      typeFilter: searchedType,
      sinceFilter: searchedSince,
      page: getPaginationPage,
      limit: getPaginationLimit,
    };

    dispatch(AuditTrailActions.listByFilters(listByFiltersPayload));
  };

  useEffect(() => {
    getAuditTrailsList();
  }, [
    searchedType,
    searchedSince,
    getPaginationPage,
    getPaginationLimit,
  ]);

  useEffect(() => {
    const keysToDelete = [];

    if (searchedType === null && searchedTypeInSearch) {
      keysToDelete.push(SEARCHED_TYPE_QS_KEY);
    }

    if (searchedSince === null && searchedSinceInSearch) {
      keysToDelete.push(SEARCHED_SINCE_QS_KEY);
    }

    if (getPaginationPage === DEFAULT_PAGE_QS_VALUE && paginationPageInSearch) {
      keysToDelete.push(PAGE_QS_KEY);
    }

    if (getPaginationLimit === DEFAULT_LIMIT_QS_VALUE && paginationLimitInSearch) {
      keysToDelete.push(LIMIT_QS_KEY);
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    getPaginationPage,
    paginationPageInSearch,
    getPaginationLimit,
    paginationLimitInSearch,
    searchedType,
    searchedTypeInSearch,
    searchedSince,
    searchedSinceInSearch,
  ]);

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const handleFilterByTypeChange = useCallback((selectedFilterByType) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (selectedFilterByType === null) {
      keysToDelete.push(SEARCHED_TYPE_QS_KEY);
    } else {
      QSToAdd[SEARCHED_TYPE_QS_KEY] = selectedFilterByType;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    dispatch(AnalyticsActions.sendEvent({ event: AUDIT_TRAIL_EVENTS.FILTER_TYPE, params: { FILTER: selectedFilterByType?.toUpperCase() || '' } }));
  }, []);

  const handleFilterByLimitChange = useCallback((selectedFilterByLimit) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (selectedFilterByLimit === null) {
      keysToDelete.push(SEARCHED_SINCE_QS_KEY);
    } else {
      QSToAdd[SEARCHED_SINCE_QS_KEY] = selectedFilterByLimit;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    dispatch(AnalyticsActions.sendEvent({ event: AUDIT_TRAIL_EVENTS.FILTER_TIME, params: { FILTER: selectedFilterByLimit?.toUpperCase() || '' } }));
  }, []);

  useEffect(() => {
    if (!listByFiltersIsPending) {
      scrollToTop();
    }
  }, [listByFiltersIsPending]);

  const filterByTypeSelectNode = useMemo(() => (
    <Select
      className={styles.filterSelect}
      value={searchedType}
      placeholder={t('auditTrail.filterByType.placeholder')}
      onChange={handleFilterByTypeChange}
      options={filterByTypeOptions}
      allowClear
    />
  ), [
    filterByTypeOptions,
    searchedType,
    handleFilterByTypeChange,
  ]);

  const filterByLimitSelectNode = useMemo(() => (
    <Select
      className={styles.filterSelect}
      value={searchedSince}
      placeholder={t('auditTrail.filterByLimit.placeholder')}
      onChange={handleFilterByLimitChange}
      options={filterByTimeOptions}
      allowClear
    />
  ), [
    filterByTimeOptions,
    searchedSince,
    handleFilterByLimitChange,
  ]);

  const actionsNode = useMemo(() => (
    <div className={styles.actionsContainer}>
      {filterByTypeSelectNode}
      {filterByLimitSelectNode}
    </div>
  ), [filterByTypeSelectNode]);

  const resultsNode = useMemo(() => (
    <Loader spinning={listByFiltersIsPending}>
      <AuditTrailList onPageChange={handlePageChange} onLimitChange={handleLimitChange} />
    </Loader>
  ), [
    listByFiltersIsPending,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {actionsNode}
      {resultsNode}
    </div>
  );
};

AuditTrailPageContent.propTypes = {
  className: PropTypes.string,
};

AuditTrailPageContent.defaultProps = {
  className: '',
};

export default AuditTrailPageContent;
