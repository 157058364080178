import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import PlanUsingQuotaItem from '@palette/components/quota/PlanUsingQuotaItem/PlanUsingQuotaItem';

import * as QuotaModel from '@palette/models/Quota';

import styles from './PlansUsingQuotaModal.less';

const PlansUsingQuotaModal = ({ visible, onClose, quota }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={(
        <div className={styles.modalTitle}>
          <div>
            {t('plansUsingQuotaModal.title')}
          </div>
          <div className={styles.quotaName}>
            {quota.name}
          </div>
        </div>
      )}
      visible={visible}
      onCancel={onClose}
      displayFooter={false}
    >
      {
        quota.plans.map((plan) => (
          <PlanUsingQuotaItem key={plan.id} className={styles.plan} plan={plan} />
        ))
      }
    </Modal>
  );
};

PlansUsingQuotaModal.propTypes = {
  quota: QuotaModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

PlansUsingQuotaModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default PlansUsingQuotaModal;
