import React from 'react';
import { useTranslation } from 'react-i18next';

import * as UserVariableModel from '@palette/models/UserVariable';

import styles from './UserVariableHeader.less';

const UserVariableHeader = ({ userVariable }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {userVariable.name}
      </div>
      <div className={styles.type}>
        {t(`usersVariables.types.${userVariable.type.toLowerCase()}`)}
      </div>
    </div>
  );
};

UserVariableHeader.propTypes = {
  userVariable: UserVariableModel.propTypes.isRequired,
};

export default UserVariableHeader;
