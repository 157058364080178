import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { TreeSelect as AntDTreeSelect } from 'antd';

import ArrowRightSquareLine from '@palette/components/utils/Icons/ArrowRightSquareLine';

import styles from './TreeSelectMultiple.less';

const classNames = bindClassNames.bind(styles);

export const getOptionsNodes = (options) => options.map(({ label, value, options: subOptions = [], ...otherOptionProps }) => {
  let subOptionsNodes = null;
  if (subOptions.length > 0) {
    subOptionsNodes = getOptionsNodes(subOptions);
  }
  return (
    <AntDTreeSelect.TreeNode key={value} value={value} title={label} {...otherOptionProps}>
      {subOptionsNodes}
    </AntDTreeSelect.TreeNode>
  );
});

const TreeSelectMultiple = ({
  className,
  options,
  searchNotFoundLabel,
  defaultOpen,
  onChange,
  onSearch,
  onDropdownVisibleChange,
  open,
  size,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(defaultOpen);

  const optionsNodes = getOptionsNodes(options);

  const notFoundContentNode = (
    <div className={styles.not_found_content_description}>
      {searchNotFoundLabel || t('treeSelect.searchNotFound')}
    </div>
  );

  const handleDropdownVisibleChange = (value) => {
    setDropdownOpen(value);
    onDropdownVisibleChange(value);
  };

  const handleChange = (newValue) => {
    setSelectedValues(newValue);
    onChange(newValue);
  };

  return (
    <AntDTreeSelect
      className={classNames({
        wrapper: true,
        big: size === 'big',
        [className]: className !== '',
      })}
      dropdownClassName={styles.dropdown}
      multiple
      onChange={handleChange}
      value={selectedValues}
      showCheckedStrategy="SHOW_ALL"
      showSearch
      onSearch={onSearch}
      treeNodeFilterProp="title"
      notFoundContent={notFoundContentNode}
      switcherIcon={<ArrowRightSquareLine />}
      treeLine={{ showLeafIcon: false }}
      treeDefaultExpandAll
      onDropdownVisibleChange={handleDropdownVisibleChange}
      open={open !== null ? open : dropdownOpen}
      {...otherProps}
    >
      {optionsNodes}
    </AntDTreeSelect>
  );
};

const treeSelectOptionShape = {
  label: PropTypes.any,
  value: PropTypes.any,
};
treeSelectOptionShape.options = PropTypes.arrayOf(PropTypes.shape(treeSelectOptionShape));

TreeSelectMultiple.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape(treeSelectOptionShape)).isRequired,
  searchNotFoundLabel: PropTypes.string,
  defaultOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onDropdownVisibleChange: PropTypes.func,
  open: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'big']),
};

TreeSelectMultiple.defaultProps = {
  className: '',
  searchNotFoundLabel: null,
  defaultOpen: false,
  onChange: () => {},
  onSearch: undefined,
  onDropdownVisibleChange: () => {},
  open: null,
  size: 'default',
};

export default TreeSelectMultiple;
