/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Button, Divider, message, Popconfirm,
} from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';

import FullScreenLoader from '../../../components/loader';
import BackLink from '../../../components/backLink';
import {
  createReconciliation, deleteReconciliation, execReconciliation, getReconciliationById, updateReconciliation,
} from '../../../services/admin/reconciliations';
import '../../../styles/admin/reconciliation-details.less';

import ReconciliationForm from '../../../components/admin/reconciliation-form';
import { JobError, waitJob } from '../../../services/utils';

class ReconciliationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reconciliation: null,
      loading: false,
      isRunning: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.reconciliationId !== prevProps.match.params.reconciliationId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        reconciliation: null,
        loading: false,
      }, this.fetch);
    }
  }

  fetch = () => {
    if (this.props.match.params.reconciliationId === 'add') {
      this.setState({
        reconciliation: {
          name: '',
          destination: {
            connector: { _id: '' },
            object: '',
            filter: JSON.stringify({ $and: [] }),
          },
          steps: [],
        },
      });
    } else {
      if (this.state.loading) return;
      this.setState({ loading: true }, () => {
        getReconciliationById(this.props.match.params.reconciliationId)
          .then(({ data: reconciliation }) => this.setState({
            reconciliation,
            loading: false,
          }))
          .catch((error) => {
            console.error(error);
            this.setState({ loading: false });
            message.error('Error while loading reconciliation');
          });
      });
    }
  };

  exec = () => {
    if (this.state.isRunning) return;
    this.setState({ isRunning: true }, async () => {
      try {
        let { data: job } = await execReconciliation(this.state.reconciliation._id);
        job = await waitJob(job._id);
        message.success(`Successfully played ${this.state.reconciliation.name}`);
        this.setState({
          reconciliation: {
            ...this.state.reconciliation,
            lastJob: job,
          },
        });
      } catch (error) {
        if (error instanceof JobError) {
          const e = JSON.parse(error.message);
          message.error(`Error while playing: ${e.message}`);
        } else {
          message.error('Unexpected error while playing.');
        }
      }
      this.setState({
        loading: false,
        isRunning: false,
      });
    });
  };

  update = (values, execAfterUpdate = true) => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      const isAdd = this.props.match.params.reconciliationId === 'add';
      const body = {
        name: values.name,
        destination: {
          connector: values.destinationConnectorObject.split('_')[0],
          object: values.destinationConnectorObject.split('_').slice(1).join('_'),
          filter: JSON.stringify(values.destinationFilter),
        },
        steps: values.steps.map(({ connectorObject, ...step }) => ({
          ...step,
          connector: connectorObject.split('_')[0],
          object: connectorObject.split('_').slice(1).join('_'),
          query: JSON.stringify(step.query),
        })),
      };
      const promise = isAdd
        ? createReconciliation(body)
        : updateReconciliation(this.props.match.params.reconciliationId, body);
      promise
        .then(({ data: reconciliation }) => {
          if (isAdd) {
            this.props.history.push(`/reconciliations/${reconciliation._id}`);
          } else {
            this.setState({
              reconciliation,
              loading: false,
            }, () => {
              if (execAfterUpdate) {
                this.exec();
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
          message.error('Error while updating reconciliation');
        });
    });
  };

  delete = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      deleteReconciliation(this.props.match.params.reconciliationId)
        .then(() => this.props.history.push('/reconciliations'))
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
          message.error('Error while deleting reconciliation');
        });
    });
  };

  render() {
    const {
      reconciliation,
      loading,
    } = this.state;
    const isAdd = this.props.match.params.reconciliationId === 'add';
    const title = isAdd ? 'Create Reconciliation' : reconciliation?.name;

    return (
      <div className="div-wrapper ReconciliationDetails">
        <MetaTag title={title} />
        <div className="container">
          <div className="container-inner">
            <br />
            <div>
              <BackLink defaultUrl="/reconciliations" />
            </div>
            {reconciliation && (
              <>
                <h1 style={{
                  marginTop: '12px',
                  marginBottom: '12px',
                }}
                >
                  {title}
                </h1>
                <ReconciliationForm
                  initialValues={{
                    name: reconciliation.name,
                    destinationConnectorObject: isAdd ? '' : `${reconciliation.destination.connector._id}_${reconciliation.destination.object}`,
                    destinationFilter: JSON.parse(reconciliation.destination.filter),
                    steps: reconciliation.steps.map((step) => ({
                      ...step,
                      query: JSON.parse(step.query),
                      connectorObject: `${step.connector._id}_${step.object}`,
                    })),
                  }}
                  onFinish={this.update}
                  lastJob={reconciliation.lastJob}
                  isRunning={this.state.isRunning}
                />
                {!isAdd && (
                  <>
                    <Divider />
                    <Popconfirm
                      title="Are you sure?"
                      okText="YES"
                      cancelText="NO"
                      okType="danger"
                      onConfirm={() => this.delete()}
                    >
                      <Button
                        icon={<DeleteOutlined />}
                        danger
                      >
                        Delete Reconciliation
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </>
            )}
            {loading && <FullScreenLoader />}
          </div>
        </div>
      </div>
    );
  }
}

ReconciliationDetails.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default ReconciliationDetails;
