import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { buildTeamsTree } from '@palette/helpers/TeamHelper';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

export const useTeams = () => {
  const dispatch = useDispatch();

  const teamsList = useSelector(TeamsSelectors.getTeamsList);
  const teamsListById = useSelector(TeamsSelectors.getTeamsListById);

  useEffect(() => {
    dispatch(TeamsActions.getList());
  }, []);

  return [teamsList, teamsListById];
};

export const useTeamsTree = (forTeamId = null) => {
  const [teamsList] = useTeams();

  return useMemo(() => (
    buildTeamsTree(teamsList, forTeamId)
  ), [teamsList, forTeamId]);
};

export const useTeamIdInParams = () => {
  const dispatch = useDispatch();

  const { teamId } = useParams();

  const team = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId }));
  const isPending = useSelector((state) => TeamsSelectors.getTeamByIdIsPending(state));

  useEffect(() => {
    dispatch(TeamsActions.getTeamById({ teamId }));
  }, [teamId]);

  return useMemo(() => ({
    teamId,
    team,
    isPending,
  }), [
    teamId,
    team,
    isPending,
  ]);
};
