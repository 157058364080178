const fr = {
  translation: {
    'actions.copyAsJSON': 'Copier en JSON',
    'actions.edit': 'Editer',
    'actions.delete': 'Supprimer',
    'actions.duplicate': 'Dupliquer',
    'actions.filter': 'Filtrer',
    'actions.remove': 'Retirer',
    'actions.copy': 'Copier',
    'actionsMenu.settings': 'Paramètres',
    'actionsMenu.documentation': 'Documentation',
    'actionsMenu.sendFeedback': 'Envoyer un avis',
    'actionsMenu.chatWithSupport': 'Discuter avec le support',
    'addDashboardWidgetDrawer.title': 'Ajouter un Widget',
    'addDashboardWidgetDrawer.category.common.label': 'Widgets globaux',
    'addDashboardWidgetDrawer.category.common.showMore': 'En voir davantage',
    'addDashboardWidgetDrawer.category.plan.label': 'Widgets de plan',
    'addDashboardWidgetDrawer.category.plan.chooseAPlan': 'Choisissez un plan pour lister les widgets disponibles',
    'addDashboardWidgetDrawer.addWidget': 'Ajouter le widget sélectionné',
    'addDashboardWidgetDrawer.noWidgetsAvailable': 'Aucun widget disponible actuellement, veuillez contacter votre administrateur si vous pensez qu\'il s\'agit d\'une erreur.',
    'addDataConnectionButton.label': 'Ajouter une nouvelle connexion',
    'addDataConnectionDataSelectorBlock.search.placeholder': 'Recherche',
    'addDataConnectionSelectDataConnectorStep.availableResources_zero': '{{count}} ressource disponible',
    'addDataConnectionSelectDataConnectorStep.availableResources_one': '{{count}} ressource disponible',
    'addDataConnectionSelectDataConnectorStep.availableResources_other': '{{count}} ressources disponibles',
    'addDataConnectionSelectDataConnectorStep.usedResources_zero': '{{count}} utilisée',
    'addDataConnectionSelectDataConnectorStep.usedResources_one': '{{count}} utilisée',
    'addDataConnectionSelectDataConnectorStep.usedResources_other': '{{count}} utilisées',
    'addDataConnectionDataSourceConnectors.connectorLastSync': 'Dernier sync {{date}}',
    'addDataConnectionDataSourceConnectors.usedResourcesNode': '$t(addDataConnectionSelectDataConnectorStep.availableResources, {"count": {{total}} }) - $t(addDataConnectionSelectDataConnectorStep.usedResources, {"count": {{used}} })',
    'addDataConnectionFlowSteps.select_data_source.label': 'Source de données',
    'addDataConnectionFlowSteps.select_data.label': 'Type de données',
    'addDataConnectionModal.title': 'Importation des données',
    'addDataConnectionModal.import': 'Importer',
    'addDataConnectionSelectDataSourceStep.tabs.connectors': 'Connecteurs',
    'addDataConnectionSelectDataSourceStep.tabs.models': 'Modèles',
    'addUsersInMasterPlanModal.addManagers.title': 'Ajouter des managers',
    'addUsersInMasterPlanModal.addUsers.title': 'Ajouter des utilisateurs',
    'addUsersInMasterPlanModal.addManagers.button': 'Ajouter les managers',
    'addUsersInMasterPlanModal.addUsers.button': 'Ajouter les utilisateurs',
    'addUsersModal.title': 'Ajouter des utilisateurs',
    'addUsersModal.okText': 'Ajouter les utilisateurs',
    'addUsersModal.usersSelection': 'Sélectionner',
    'addUsersModal.usersSelected': 'Utilisateurs sélectionnés',
    'addUsersWithFastOnboardingModal.cancelFastOnboarding': 'Annuler l\'enregistrement rapide',
    'addMembersToTeam.label': 'Ajouter des membres',
    'addMembersToTeamModal.title': 'Ajouter des membres à {{teamName}}',
    'addMembersToTeamModal.addMembers': 'Ajouter des membres',
    'addNotebookCell.cellTypesCategories.basic': 'Basique',
    'addNotebookCell.cellTypesCategories.noSupport': 'Sans Support Client',
    'adminMenu.title': 'Administrer',
    'adminMenu.commissions': 'Commissions',
    'adminMenu.commissions.statements': 'Statements',
    'adminMenu.commissions.list': 'Commissions',
    'adminMenu.plans': 'Plans',
    'adminMenu.auditTrail': 'Piste d\'audit',
    'adminMenu.users': 'Utilisateurs',
    'adminMenu.users.list': 'Tous les utilisateurs',
    'adminMenu.users.teams': 'Equipes',
    'adminMenu.users.roles': 'Roles',
    'adminMenu.users.presets': 'Tableaux de bord',
    'adminMenu.users.variables': 'Variables',
    'adminMenu.quotas': 'Quotas',
    'adminMenu.quotas.configuration': 'Configuration',
    'adminMenu.quotas.performance': 'Performance',
    'adminMenu.plansV3.configuration': 'Configuration',
    'adminMenu.plansV3.plans': 'Plans',
    'adminMenu.plansV3.statements': 'Statements',
    'adminMenu.resources': 'Ressources',
    'adminMenu.connectors': 'Connecteurs',
    'adminMenu.notifications': 'Notifications',
    'adminMenu.notebooks': 'Modèles',
    'algoliaAutocomplete.seeAllResults': 'Voir les {{nbResults}} resultats',
    'algoliaAutocomplete.noResultsFor': 'Aucun résultat pour "{{search}}"',
    'algoliaAutocomplete.seeFullDoc': 'Ouvrir la Documentation',
    'algoliaAutocomplete.typeSearch': 'Faites une recherche pour afficher les résultats',
    'algoliaSearchModal.documentation': 'Documentation',
    'app.metas.commissionListTitle': 'Liste des commissions',
    'app.metas.auditTrailTitle': 'Piste d\'audit des activités',
    'app.metas.resourcesTitle': 'Resources',
    'app.metas.resourceDetailsTitle': 'Détails d\'une resource',
    'app.metas.myCommissionListTitle': 'Ma liste de commissions',
    'app.metas.statementsValidationsExportTitle': 'Export des validations des relevés',
    'app.metas.createPlanFlowTitle': 'Assistant de création d\'un Plan',
    'app.metas.editPlan': 'Editer un plan',
    'app.metas.requirementsPlan': 'Besoins du plan',
    'app.metas.dashboardPresetsTitle': 'Tableaux de bord',
    'app.metas.dashboardPresetDetailsTitle': 'Préréglage du tableau de bord',
    'app.metas.defaultTitle': 'Palette vous aide à concevoir et automatiser le meilleur plan de commissions pour votre équipe | Palette',
    'app.metas.quotasTitle': 'Quotas configuration',
    'app.metas.quotasPerformanceTitle': 'Quotas performance',
    'app.metas.statementsInvestigateTitle': 'Investiguer une correction',
    'app.metas.masterPlansComparatorTitle': 'Comparateur de Plans',
    'app.metas.masterPlansTitle': 'Plans',
    'app.metas.masterPlansDetailsTitle': '{{planName}} - Plans',
    'app.metas.myMasterPlansDetailsTitle': '{{planName}} - Mes Plans',
    'app.metas.masterPlanPeriodDetailsTitle': '{{periodName}} - {{planName}} - Plans',
    'app.metas.myMasterPlanPeriodDetailsTitle': '{{periodName}} - {{planName}} - Mes Plans',
    'app.metas.myDashboardTitle': 'Mon tableau de bord',
    'app.metas.myMasterPlansTitle': 'Mes Plans',
    'app.metas.onboardUsersFromResourcesFlowTitle': 'Assistant d\'enregistrement de nouveaux utilisateurs: à partir de ressources existantes',
    'app.metas.onboardUsersManualInputFlowTitle': 'Assistant d\'enregistrement de nouveaux utilisateurs: en remplissant le formulaire',
    'app.metas.rolesTitle': 'Roles',
    'app.metas.statementsTitle': 'Fiches de paie',
    'app.metas.statementsPeriodTitle': 'Fiches de paie',
    'app.metas.statementsUserStatementTitle': 'Fiches de paie',
    'app.metas.usersTitle': 'Tous les Utilisateurs',
    'app.metas.usersMergeTitle': 'Fusion d\'utilisateurs',
    'app.metas.usersVariablesTitle': 'Variables des Utilisateurs',
    'app.metas.teamsTitle': 'Toutes les Équipes',
    'app.metas.teamDetailsTitle': '{{teamName}} - Équipes',
    'app.metas.userDetailsTitle': '{{displayName}} - Utilisateurs',
    'app.metas.myStatementsTitle': 'Mes fiches de paie',
    'app.metas.notebooksTitle': 'Modèles',
    'app.metas.notebookDetailsTitle': '{{notebookName}} - Modèles',
    'app.metas.loginTitle': 'Connexion',
    'app.metas.forgotPasswordTitle': 'Mot de passe oublié',
    'app.metas.resetPasswordTitle': 'Réinitialiser son mot de passe',
    'app.metas.profileTitle': 'Mon Profil',
    'app.metas.settingsTitle': 'Paramètres',
    'applyDashboardPresetModal.title': 'Assigner {{presetName}} aux utilisateurs',
    'applyDashboardPresetModal.apply_zero': 'Sélectionner les utilisateurs',
    'applyDashboardPresetModal.apply_one': 'Assigner le Tableau de bord à 1 utilisateur',
    'applyDashboardPresetModal.apply_other': 'Assigner le Tableau de bord à {{count}} utilisateurs',
    'applyDashboardPresetModal.alert.message': 'Vous êtes sur le point de remplacer les tableaux de bord de l\'utilisateur',
    'applyDashboardPresetModal.alert.description': 'En appliquant un Tableau de bord prédéfini aux utilisateurs, vous remplacerez leur propre Tableau de bord.',
    'archiveMasterPlanModal.title': 'Archiver le Plan',
    'archiveMasterPlanModal.confirm': 'Je confirme',
    'archiveMasterPlanModal.content': 'Confirmez-vous vouloir archiver <strong>{{planName}}</strong> ?',
    'archiveNotebookModal.title': 'Archiver le Modèle',
    'archiveNotebookModal.confirm': 'Je confirme',
    'archiveNotebookModal.content': 'Confirmez-vous vouloir archiver <strong>{{notebookName}}</strong> ?',
    'avatarUploader.modal.title_profile': 'Éditer la photo de profil',
    'avatarUploader.modal.title_user': 'Éditer la photo de profil de l\'utilisateur',
    'avatarUploader.modal.title_company': 'Éditer la photo de société',
    'avatarUploader.modal.okText_profile': 'Mettre à jour la photo de profil',
    'avatarUploader.modal.okText_user': 'Mettre à jour la photo de profil',
    'avatarUploader.modal.okText_company': 'Mettre à jour la photo d\'entreprise',
    'avatarUploader.modal.label.or': 'Ou',
    'auditTrail.page.title': 'Piste d\'audit des activités',
    'auditTrail.page.subtitle': 'Les données sont stockés pendant 90 jours',
    'auditTrail.filterByType.option.plan': 'Plans',
    'auditTrail.filterByType.option.plan_v3': 'Plans V3',
    'auditTrail.filterByType.option.statement': 'Déclarations',
    'auditTrail.filterByType.option.commission': 'Commissions',
    'auditTrail.filterByType.option.resource': 'Ressources',
    'auditTrail.filterByType.option.user': 'Utilisateurs',
    'auditTrail.filterByType.option.setting': 'Paramètres',
    'auditTrail.filterByTime.option.today': 'Aujourd\'hui',
    'auditTrail.filterByTime.option.week': 'Cette semaine',
    'auditTrail.filterByTime.option.month': 'Ce mois-ci',
    'auditTrail.filterByType.placeholder': 'Filtrer par catégorie',
    'auditTrail.filterByLimit.placeholder': 'Filtrer par date',
    'auditTrail.list.empty.description': 'Aucune activité pour le moment.',
    'auditTrail.list.item.link.label': 'Voir plus',
    'auditTrail.action.title.create_plan_V3': 'Création d\'un plan v3',
    'auditTrail.action.title.delete_plan_v3': 'Suppression d\'un plan v3',
    'auditTrail.action.title.update_plan_v3_configuration': 'Mise à jour d\'un plan v3',
    'auditTrail.action.title.freeze_plan': 'Période gelée',
    'auditTrail.action.title.unfreeze_plan': 'Période dégelée',
    'auditTrail.action.title.hide_plan_for_reps': 'Masqué pour les reps',
    'auditTrail.action.title.show_plan_for_reps': 'Affiché pour les reps',
    'auditTrail.action.title.adjustment_added': 'Correction ajoutée',
    'auditTrail.action.title.adjustment_updated': 'Correction mise à jour',
    'auditTrail.action.title.adjustment_deleted': 'Correction supprimée',
    'auditTrail.action.title.correction_applied': 'Correction appliquée',
    'auditTrail.action.title.correction_ignored': 'Correction ignorée',
    'auditTrail.action.title.overwrite_commission_amount': 'Montant écrasé',
    'auditTrail.action.title.overwrite_commission_payout': 'Paiement écrasé',
    'auditTrail.action.title.reseted_commission_amount': 'Montant réinitialisé',
    'auditTrail.action.title.reseted_commission_payout': 'Paiement réinitialisé',
    'auditTrail.action.title.connector_added': 'Connecteur ajouté',
    'auditTrail.action.title.raw_data_updated': 'Données brutes mises à jour',
    'auditTrail.action.title.raw_data_deleted': 'Données brutes supprimées',
    'auditTrail.action.title.offboard_leave_date': 'Date de départ défini',
    'auditTrail.action.title.offboard_reset_date': 'Date de départ réinitialisée',
    'auditTrail.action.title.user_role_updated': 'Nouveau rôle',
    'auditTrail.action.title.number_seats': 'Nouveau nombre total de licences',
    'auditTrail.action.title.statement_fx_rate_mode': 'Nouveau mode des taux de déclaration',
    'auditTrail.action.title.toggle_statement_validation': 'Nouveau paramètre de validation des déclarations',
    'auditTrail.action.title.auto_freeze_plan_enabled': 'Gèle automatique activé',
    'auditTrail.action.title.auto_freeze_plan_disabled': 'Gèle automatique désactivé',
    'auditTrail.action.title.updated_plan_name': 'Nom mis à jour',
    'auditTrail.action.title.updated_plan_currency': 'Devise mise à jour',
    'auditTrail.action.title.added_users_plan': 'Utilisateurs ajoutés',
    'auditTrail.action.title.deleted_users_plan': 'Utilisateurs supprimés',
    'auditTrail.action.title.linked_quota_plan': 'Quota lié',
    'auditTrail.action.title.unlinked_quota_plan': 'Quota dissocié',
    'auditTrail.action.title.statement_approved': 'Déclaration approuvée',
    'auditTrail.action.title.statement_marked_as_paid': 'Déclaration marquée comme payée',
    'auditTrail.action.description.create_plan_v3': 'Le plan <strong>{{planName}}</strong> a été créé',
    'auditTrail.action.description.delete_plan_v3': 'Le plan <strong>{{planName}}</strong> a été supprimé.',
    'auditTrail.action.description.update_plan_v3_configuration': 'Le plan <strong>{{planName}}</strong> a été mis à jour.',
    'auditTrail.action.description.freeze_plan': 'a gelé une période sur <strong>{{plan}}</strong>.',
    'auditTrail.action.description.unfreeze_plan': 'a dégelé une période sur <strong>{{plan}}</strong>.',
    'auditTrail.action.description.hide_plan_for_reps': 'a désactivé la visibilité de <strong>{{plan}}</strong> pour les commerciaux.',
    'auditTrail.action.description.show_plan_for_reps': 'a défini <strong>{{plan}}</strong> visible pour les commerciaux.',
    'auditTrail.action.description.adjustment_added': 'a ajouté une correction de <strong>{{newAmount}}</strong> sur le relevé de commission de <strong>{{periodName}}</strong> de <strong>{{payee}}</strong>.',
    'auditTrail.action.description.adjustment_updated': 'a mis à jour une correction sur le relevé de commission de <strong>{{periodName}}</strong> de <strong>{{payee}}</strong> de <strong>{{oldAmount}}</strong> à <strong>{ {nouveauMontant}}</strong>.',
    'auditTrail.action.description.adjustment_deleted': 'a supprimé une correction sur le relevé de commission de <strong>{{periodName}}</strong> de <strong>{{payee}}</strong>.',
    'auditTrail.action.description.correction_applied': 'a appliqué la correction de <strong>{{amount}}</strong> relative au relevé de commission de <strong>{{sourcePeriodName}}</strong> de <strong>{{payee}}</strong> sur le relevé de commission de <strong>{{targetPeriodName}}</strong>',
    'auditTrail.action.description.correction_ignored': 'a ignoré la correction de <strong>{{amount}}</strong> relative au relevé de commission de <strong>{{periodName}}</strong> de <strong>{{payee}}</strong>',
    'auditTrail.action.description.overwrite_commission_amount': 'a mis à jour le montant de la commission liée à la commission versée à <strong>{{payee}}</strong> de <strong>{{oldAmount}}</strong> à <strong >{{nouveauMontant}}</strong>.',
    'auditTrail.action.description.overwrite_commission_payout': 'a mis à jour le paiement de la commission versée à <strong>{{payee}}</strong>{{valueDefinitionLabel}}.',
    'auditTrail.action.description.reseted_commission_amount': 'a réinitialisé le montant de la commission liée à la commission versée à <strong>{{payee}}</strong>.',
    'auditTrail.action.description.reseted_commission_payout': 'a réinitialisé le paiement de la commission versée à <strong>{{payee}}</strong>{{valueDefinitionLabel}}.',
    'auditTrail.action.description.connector_added': 'a ajouté le connecteur <strong>{{connectorName}}</strong>.',
    'auditTrail.action.description.raw_data_updated': 'a mis à jour <strong>{{entryPath}}</strong> sur les données brutes <strong>{{type}}</strong>.',
    'auditTrail.action.description.raw_data_deleted': 'a supprimé <strong>{{entryPath}}</strong> sur les données brutes <strong>{{type}}</strong>.',
    'auditTrail.action.description.offboard_leave_date': 'a défini <strong>{{leaveDate}}</strong> comme date de départ concernant <strong>{{userName}}</strong>.',
    'auditTrail.action.description.offboard_reset_date': 'a réinitialisé la date de départ de <strong>{{userName}}</strong>.',
    'auditTrail.action.description.user_role_updated': 'a défini <strong>{{roleName}}</strong> comme nouveau rôle pour <strong>{{userName}}</strong>.',
    'auditTrail.action.description.number_seats': 'a mis à jour le nombre de licences à <strong>{{newSeatsQuantity}}</strong>.',
    'auditTrail.action.description.statement_fx_rate_mode': 'a mis à jour le mode des taux de déclaration à : <strong>{{newStatementFxRateMode}}</strong>.',
    'auditTrail.action.description.toggle_statement_validation': 'a <strong>{{newStatementValidation}}</strong> la validation des déclarations.',
    'auditTrail.action.description.auto_freeze_plan_enabled': 'a défini <strong>{{plan}}</strong> gelant automatiquement les périodes{{freezeAfter}}.',
    'auditTrail.action.description.auto_freeze_plan_disabled': 'a désactivé le gèle automatique des périodes de <strong>{{plan}}</strong>.',
    'auditTrail.action.description.auto_freeze_after': '<strong>{{totalDays}} jours</strong> après leur fin',
    'auditTrail.action.description.commission_payout.valueDefinitionLabel': ', pour <strong>{{quotaTrackingType}}</strong>',
    'auditTrail.action.description.updated_plan_name': 'a mis à jour le nom du plan de <strong>{{oldPlan}}</strong> à <strong>{{plan}}</strong>.',
    'auditTrail.action.description.updated_plan_currency': 'a défini <strong>{{currency}}</strong> comme devise pour <strong>{{plan}}</strong>.',
    'auditTrail.action.description.added_users_plan': 'a ajouté <strong>$t(usersWithCount, {"count": {{usersCount}} })</strong> à <strong>{{plan}}</strong>.',
    'auditTrail.action.description.deleted_users_plan': 'a supprimé <strong>$t(usersWithCount, {"count": {{usersCount}} })</strong> de <strong>{{plan}}</strong>.',
    'auditTrail.action.description.linked_quota_plan': 'a lié le quota <strong>{{quotaName}}</strong> ({{quotaTrackingType}} · {{quotaFrequencyName}}) à <strong>{{plan}}</strong>.',
    'auditTrail.action.description.unlinked_quota_plan': 'a dissocié le quota <strong>{{quotaName}}</strong> ({{quotaTrackingType}} · {{quotaFrequencyName}}) de <strong>{{plan}}</strong>.',
    'auditTrail.action.description.statement_approved': 'a approuvé <strong>{{amount}}</strong> sur le relevé de commission de <strong>{{periodName}}</strong> de <strong>{{payee}}</strong>.',
    'auditTrail.action.description.statement_marked_as_paid': 'a marqué comme payé <strong>{{amount}}</strong> sur le relevé de commission de <strong>{{periodName}}</strong> de <strong>{{payee}}</strong>.',
    'cloudinaryUploader.modal.error.uploading': 'Erreur lors du téléchargement, veuillez réessayer.',
    'cloudinaryUploader.modal.error.format': 'Vous ne pouvez télécharger que des fichiers JPG/PNG.',
    'cloudinaryUploader.modal.error.size': 'L\'image doit être inférieure à 0,5 Mo.',
    'commissions.empty.description': 'Aucune commission à afficher.',
    'commissions.errors.commission.not.found': 'La commission n\'a pas été trouvée.',
    'commissions.errors.per.target': 'Vous ne pouvez pas modifier le montant d\'une commission produite par une règle de type "Par Objectif".',
    'commissions.seePlanDetails': 'Voir les détails du plan',
    commissionsComments_zero: 'commentaire',
    commissionsComments_one: 'commentaire',
    commissionsComments_other: 'commentaires',
    commissionsCommentsWithCount_zero: '{{count}} $t(commissionsComments_zero)',
    commissionsCommentsWithCount_one: '{{count}} $t(commissionsComments_one)',
    commissionsCommentsWithCount_other: '{{count}} $t(commissionsComments_other)',
    'commissions.seePeriodDetails': 'Voir les détails de la période',
    'commissions.rules': 'Règles',
    'commissionCommentForm.form.comment.label': 'Ajouter un commentaire',
    'commissionCommentForm.form.comment.placeholder': 'Ecrivez votre commentaire...',
    'commissionDetails.date': 'Date',
    'commissionDetails.name': 'Nom',
    'commissionDetails.payee': 'Bénéficiaire',
    'commissionDetails.plan': 'Plan',
    'commissionDetails.viewRawObject': 'Voir les données brutes',
    'commissionDrawer.title': 'Détails de la commission',
    'commissionDrawerActivitiesTab.emptyState.description': 'Aucun commentaire',
    'commissionDrawerTabs.variables': 'Variables',
    'commissionDrawerTabs.payments': 'Paiements',
    'commissionDrawerTabs.calculations': 'Calculs',
    'commissionDrawerTabs.activities': 'Activités',
    'commissionDrawerPaymentsTab.noScheduledPayments': 'Il n\'y a aucun paiement planifié pour l\'instant.',
    'commissionDrawerPaymentsTab.noCommissionToPay': 'Il n\'y a aucune commission à payer pour l\'instant.',
    'commissionDrawerEditVariablesList.editVariables': 'Modifier la liste des variables',
    'commissionDrawerEditVariablesListModal.title': 'Modification des variables affichées',
    'commissionDrawerEditVariablesListModal.subTitle': 'Pour les {{type}} dans le plan {{planName}}',
    'commissionDrawerEditVariablesListModal.save': 'Enregistrer les variables',
    'commissionDrawerEditVariablesListModal.tip.title': 'Astuce',
    'commissionDrawerEditVariablesListModal.tip.part1': 'Vous pouvez ajouter des champs custom dans les {{type}}',
    'commissionDrawerEditVariablesListModal.tip.link': 'ici',
    'commissionDrawerEditVariablesListModal.tip.part2': '. (dans la section Enrichir les {{type}} avec de nouveaux champs)',
    'commissionDrawerEditVariablesListModal.error.title': 'Veuillez vérifier vos informations',
    'commissionDrawerEditVariablesListModal.error.text': 'Le nom de la variable est obligatoire ainsi qu\'une formule si le type d\'affichage choisi est "Custom".',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.title': 'Êtes-vous sûr de vouloir réinitialiser aux variables d\'origines ?',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.confirm': 'Oui',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.cancel': 'Non',
    'commissionDrawerEditVariablesListModal.resetVariables.popconfirm.cta': 'Réinitialiser aux variables d\'origines',
    'commissionDrawerEditVariablesListModal.addVariable': 'Ajouter une variable',
    'commissionDrawerEditVariablesListModal.variable.name': 'Nom de la variable',
    'commissionDrawerEditVariablesListModal.variable.fields': 'Champs du {{type}} ou variable du plan',
    'commissionDrawerEditVariablesListModal.variable.fields.tooltip': 'Si vous ajoutez plusieurs champs, ils seront concaténés avec un espace.',
    'commissionDrawerEditVariablesListModal.variable.formatter': 'Affichage',
    'commissionDrawerVariablesTab.emptyState1': 'Cliquer sur le lien ci-dessus pour ajouter des variables',
    'commissionDrawerVariablesTab.emptyState2': 'Aucune variable pour le moment',
    'commissionRuleDetails.ruleNumber': 'Règle n°{{index}}',
    'commissionRuleDetails.tierNumber': 'Tier {{index}}',
    'commissionRuleDetails.customLogic': 'Cette règle est basée sur une <strong>logique personnalisée</strong> comme décrit ci-dessous:',
    'commissionRuleDetails.targetAchievement': '<strong>Ce {{type}} vous a permis d\'atteindre <mark>{{percentage}}% de votre objectif</mark></strong>',
    'commissionRuleDetails.unlockTier': 'et de débloquer le <strong>tier #{{index}}</strong>',
    'commissionRuleDetails.value': 'Valeur',
    'commissionRuleDetails.bracketPercentage': '% du {{type}} dans le tier',
    'commissionRuleDetails.formula': 'Formule',
    'commissionRuleDetails.result': 'Résultat',
    'commissionRuleDetails.bracket.from': 'A partir de {{from}}',
    'commissionRuleDetails.bracket.fromTo': 'Tier {{from}} à {{to}}',
    'commissionRuleVariables.title': 'Variables utilisées pour les calculs',
    'commissionRuleFormulaScope.hide': 'Cacher les détails avancés',
    'commissionRuleFormulaScope.see': 'Détails avancés',
    'commissionRuleFormulaScope.formulaScope': 'Scope des Formules',
    'commissionValue.commission': 'Commission',
    'common.global.true': 'Vrai',
    'common.global.false': 'Faux',
    'common.global.name': 'Nom',
    'common.global.teams': 'Equipes',
    'common.global.value': 'Valeur',
    'common.global.text': 'Texte',
    'common.global.mostUsed': 'Fréquemment utilisé',
    'common.global.yes': 'Oui',
    'common.global.no': 'Non',
    'common.global.or': 'Ou',
    'common.global.new': 'NOUVEAU',
    'common.global.beta': 'BETA',
    'common.global.close': 'Fermer',
    'common.global.edit': 'Éditer',
    'common.global.remove': 'Supprimer',
    'common.global.upload': 'Télécharger',
    'common.global.total': 'Total',
    'common.global.current': 'Actuel',
    'common.palette.user': 'Utilisateur Palette',
    'common.palette.on': 'Activé',
    'common.palette.off': 'Désactivé',
    'companyBilling.block.title': 'Abonnement et facturation',
    'companyBilling.block.error.subscription': 'Erreur lors du chargement de l\'abonnement.',
    'companyBilling.block.portal.label': 'Informations de facturation / Factures',
    'companyBilling.block.form.submit.label': 'Mettre à jour',
    'companyBilling.block.form.addon.label': '# Licences',
    'companyBilling.block.form.error.required.message': 'Veuillez saisir un nombre de licences.',
    'companyBilling.block.form.error.min.message': 'Vous ne pouvez pas avoir moins de {{count}} licences.',
    'companySettings.block.title': 'Entreprise',
    'companySettings.seats.count': '{{count}} licences utilisées',
    'companySettings.seats.tooltip': 'Une licence est un utilisateur faisant partie d\'un plan actif dans la période en cours.',
    'companyCurrencies.block.title': 'Devises',
    'companyCurrencies.content.text': 'Définir les devises autorisées dans les plans.',
    'companyCurrencies.currency.delete.popconfirm': 'Êtes-vous sûr de vouloir supprimer cette devise ?',
    'companyCurrencies.currency.default': 'Par défaut',
    'companyCurrencies.currency.makeDefault': 'Définir par défaut',
    'companyCurrencies.addCompanyCurrency.button.label': 'Ajouter une devise',
    'companyCurrencies.addCompanyCurrency.modal.title': 'Ajouter une devise',
    'companyCurrencies.addCompanyCurrency.modal.save.label': 'Ajouter la devise',
    'companyCurrencies.addCompanyCurrency.modal.select.label': 'Code de devise',
    'companyCurrencies.addCompanyCurrency.modal.select.placeholder': 'Entrer un code de devise (ex : USD)',
    'companyCurrencies.addCompanyCurrency.modal.select.error.message': 'Veuillez sélectionner une devise valide.',
    'companyExchangeRates.block.title': 'Taux de change',
    'companyExchangeRates.content.text': 'Définissez les taux de change que vous souhaitez utiliser dans les règles du plan.',
    'companyExchangeRates.edit.tooltip.label': 'Cliquez pour modifier la valeur du taux de change',
    'companyExchangeRates.history.tooltip.label': '{{count}} modifications, cliquez pour voir l\'historique',
    'companyExchangeRates.button.add.label.new': 'Ajouter un taux de change',
    'companyExchangeRates.button.add.label': 'Ajouter un taux',
    'companyExchangeRates.modal.title.add': 'Ajouter un taux de change',
    'companyExchangeRates.modal.title.edit': 'Modifier un taux de change',
    'companyExchangeRates.modal.save.label.add': 'Ajouter le taux de change',
    'companyExchangeRates.modal.save.label.edit': 'Mettre à jour le taux de change',
    'companyExchangeRates.modal.select.error.message': 'Veuillez sélectionner une devise valide.',
    'companyExchangeRates.modal.select.placeholder': 'Entrer un code de devise (ex : USD)',
    'companyExchangeRates.modal.select.label.from': 'Depuis',
    'companyExchangeRates.modal.select.label.to': 'Vers',
    'companyExchangeRates.modal.input.label.rate': 'Taux',
    'companyExchangeRates.modal.input.label.startDate': 'Date de début',
    'companyExchangeRates.modal.rate.error.message': 'Veuillez entrer un taux.',
    'companyExchangeRates.modal.input.placeholder.rate': 'Entrer un taux',
    'companyExchangeRates.modal.startDate.error.message': 'Veuillez sélectionner une date de début.',
    'companyExchangeRates.modal.alert.label': '1 {{from}} serait {{rate}} {{to}}',
    'companyExchangeRates.modal.alert.error.label': 'Veuillez entrer un taux.',
    'companyExchangeRates.history.modal.title': 'Historique des taux de change',
    'companyExchangeRates.history.modal.table.date': 'Date de début',
    'companyExchangeRates.history.modal.table.rate': 'Taux',
    'companyExchangeRates.history.modal.delete.popconfirm': 'Êtes-vous sûr de vouloir supprimer ce taux de change ?',
    'comparatorPlanPeriodSelector.noPeriodsInPlan': 'Aucune période disponible dasn ce plan',
    'comparatorPlanPeriodSelector.placeholder': 'Sélectionner un plan pour la comparaison',
    'comparatorPlanPeriodSelector.planSelect.placeholder': 'Sélectionner un plan pour la comparaison',
    'comparatorPlanPeriodSelector.periodSelect.placeholder': 'Sélectionner une période pour la comparaison',
    'comparatorPlanPeriodSelector.previewProjection.title': 'Projection',
    'comparatorPlanResults.commissions': 'Commissions {{comparedPlanName}}',
    'comparatorPlanResults.diff': 'Diff',
    'comparatorPlanResults.table.headers.users': 'Utilisateurs',
    conditions_zero: 'condition',
    conditions_one: 'condition',
    conditions_other: 'conditions',
    conditionsWithCount_zero: '{{count}} $t(conditions_zero)',
    conditionsWithCount_one: '{{count}} $t(conditions_one)',
    conditionsWithCount_other: '{{count}} $t(conditions_other)',
    'commissionList.page.title': 'Liste des commissions',
    'commissionList.page.subtitle': 'Définissez la commission que vous recherchez',
    'commissionList.table.header.name': 'Nom',
    'commissionList.table.header.user': 'Utilisateur',
    'commissionList.table.header.date': 'Date',
    'commissionList.table.header.type': 'Type',
    'commissionList.table.header.commissions': 'Commissions',
    'commissionList.table.header.payout': 'Paiement',
    'commissionList.table.header.status': 'Statut',
    'commissionList.status.tag.concluded': 'Conclu',
    'commissionList.status.tag.partially_released': 'Partiellement payé',
    'commissionList.status.tag.on_hold': 'En attente',
    'commissionList.actions.button.view_details': 'Voir les détails',
    'commissionList.resourceSearch.placeholder': 'Rechercher par nom de ressource',
    'commissionList.resourceType.placeholder': 'Sélectionner un type de ressource',
    'commissionList.plan.placeholder': 'Sélectionner un plan',
    'commissionList.statuses.placeholder': 'Sélectionner un statut',
    'commissionList.statuses.option.concluded': 'Conclu',
    'commissionList.statuses.option.partially_released': 'Partiellement payé',
    'commissionList.statuses.option.on_hold': 'En attente',
    'commissionList.statuses.option.partially_released_and_on_hold': 'Partiellement payé <br />et en attente',
    'commissionList.sortBy.option.date_asc': 'Date croissante',
    'commissionList.sortBy.option.date_desc': 'Date décroissante',
    'commissionList.sortBy.option.unpaid_percentage': 'Montant du paiement restant',
    'commissionList.sortBy.placeholder': 'Trier par',
    'commissionList.currency.placeholder': 'Devise',
    'commissionList.clearFilters.label': 'Réinitialiser les filtres',
    'commissionList.clearFilters.modal.title': 'Voulez-vous vraiment réinitialiser vos filtres ?',
    'commissionList.clearFilters.modal.btn.confirm': 'Réinitialiser les filtres',
    'commissionList.emptyStates.no_results_found': 'Aucun résultat trouvé pour cette recherche.',
    'commissionList.emptyStates.all_deals_concluded': 'Tous vos deals sont conclus !',
    'commissionList.emptyStates.no_commission_to_list': 'Aucune commission à lister.',
    'commissionList.actions.exportSelection': 'Exporter cette sélection',
    'commissionsDueEstimateWidgetContent.description': 'Toutes les commissions qu\'il est prévu d\'être payé',
    'commissionsUnreleasedWidgetContent.description': 'Paiements en attente ou en attente de libération',
    'commissionsUnreleasedWidgetContent.viewDetails': 'Voir les détails',
    'commissionsTotalWidgetContent.totalDeals': '{{total}} {{dealsType}}',
    'commissionsWithoutPaymentLink.label': 'Voir les commissions sans aucun paiement prévu',
    'common.statementOneMonthWidget.title': 'Mon relevé du mois courant',
    'common.statementOneMonthWidget.button': 'Afficher les détails',
    'common.statementPerMonthWidget.title': 'Relevés',
    'common.dueEstimateWidget.title': 'Commissions dues',
    'common.dueEstimateWidget.subtitle': 'Estimation des commissions dues à partir de {{periodName}}',
    'common.listPlans.title': 'Mes plans actifs',
    'common.listPlans.secondTitle': 'Voir les plans inactifs',
    'common.latestObjectsWidget.items': 'éléments',
    'common.latestObjectsWidget.title': 'Mes dernier·e·s {{types}}',
    'common.latestObjectsWidget.table.headers.date': 'Date',
    'common.latestObjectsWidget.table.headers.plans': 'Plans',
    'common.latestObjectsWidget.table.headers.commissions': 'Commissions',
    'common.commissions_unreleased_list.title': 'Derniers éléments en attente ou partiellement payés',
    'common.commissionsUnreleasedWidget.title': 'Commissions pas encore libérées',
    'companyCurrencySelect.mixed': 'Devises multiples',
    'companyTimezone.no_default_value': 'Aucun fuseau horaire par défaut défini',
    'companyTimezone.default_value': 'Fuseau horaire {{timezone}}',
    'companyTimezone.modal.title': 'Mettre à jour le fuseau horaire de l\'entreprise',
    'companyTimezone.modal.select.label': 'Sélectionnez un fuseau horaire',
    'companyTimezone.modal.select.placeholder': 'Sélectionnez un fuseau horaire',
    'companyTimezone.modal.save.label': 'Sauvegarder le fuseau horaire',
    'companyTimezone.modal.disclaimer.content': 'La mise à jour du fuseau horaire de l\'entreprise peut avoir un impact important sur le calcul des commissions. Si vous ne savez pas comment mettre à jour ceci, veuillez contacter le support ou l\'équipe CSM.',
    'componentData.defaultDealType': 'élément',
    'copyNotebookModal.copiedName': 'Copie de {{notebookName}}',
    'copyNotebookModal.title': 'Dupliquer le modèle',
    'copyNotebookModal.copy': 'Dupliquer',
    'copyNotebookModal.form.name.label': 'Nom du Modèle',
    'copyNotebookModal.form.name.rules.required': 'Merci de nommer votre modèle.',
    'correctionSuggestedAmount.paidAmount.label': 'Marqué comme payée',
    'correctionSuggestedAmount.lastAmount.label': 'Dernier Montant',
    'correctionSuggestedAmount.newAmount.label': 'Nouveau Montant',
    'correctionSuggestedAmount.diffAmount.label': 'Différence',
    'createPlanFlowCommissionsResults.viewResults.noDealsFound': 'Aucun·e {{dealType}} trouvé·e.',
    'createPlanFlowCommissionsResults.viewResultsBtn.label': 'Voir la liste',
    'createPlanFlowCommissionsResultsModal.title': 'Aperçu des commissions',
    'createPlanFlowCommissionsResultsModal.close': 'Fermer',
    'createPlanFlowDataFilteringStep.form.dateField.label': 'Filtre par date: Comment décrivez-vous qu\'un·e <mark>{{connectorName}} {{resourceType}}</mark> est inclus·e dans <mark>{{periodName}}</mark> ?',
    'createPlanFlowDataFilteringStep.form.filterObject.label': 'Filtres supplémentaires: Voulez-vous filtrer les <mark>{{connectorName}} {{resourceType}}</mark> ayant des attributs spécifiques ?',
    'createPlanFlowDataSourceStep.form.connectorId.label': 'Sélectionnez la source de données à utiliser',
    'createPlanFlowDataSourceStep.form.tabs.connectors': 'Connecteurs',
    'createPlanFlowDataSourceStep.form.tabs.notebooks': 'Modèles',
    'createPlanFlowDataSourceStep.form.type.label': 'Ressource provenant de <mark>{{connectorName}}</mark> que vous voulez suivre',
    'createPlanFlowDataSourceStep.form.matching.label': 'Comment décrivez-vous qu\'un·e <mark>{{dealType}}</mark> appartient à un utilisateur ?',
    'createPlanFlowDataSourceStep.form.matchingUGByU.label': 'Définissez l\'id d\'un·e <mark>{{dealType}}</mark>',
    'createPlanFlowDataSourceStep.form.shouldMatch': 'Correspond à',
    'createPlanFlowDealsResults.viewResults.dealsFound': '{{total}} {{dealType}} trouvé·e·s',
    'createPlanFlowDealsResults.viewResults.noDealsFound': 'Aucun·e {{dealType}} trouvé·e.',
    'createPlanFlowDealsResults.viewResultsBtn.label': 'Voir la liste',
    'createPlanFlowDealsResultsModal.title': 'List de {{dealType}} ({{total}})',
    'createPlanFlowDealsResultsModal.close': 'Fermer',
    'createPlanFlowPage.title': 'Créer un nouveau Plan',
    'createPlanFlowPreviewCommissions.testBtn.label': 'Aperçu des commissions pour {{period}}',
    'createPlanFlowPreviewPayoutSchedule.testBtn.label': 'Aperçu des paiements',
    'createPlanFlowPreviewPayoutSchedule.testBtn.refresh.label': 'Rafraîchir l\'aperçu des paiements',
    'createPlanFlowPreviewPayoutSchedule.preview.title': 'Paiements',
    'createPlanFlowQuotaSetupStep.disclaimer.noQuota.description': 'Voulez-vous définir un Quota pour ce plan ?',
    'createPlanFlowQuotaSetupStep.disclaimer.noQuota.defineQuotaCTA': 'Définir un Quota',
    'createPlanFlowQuotaSetupStep.disclaimer.noQuota.discard': 'Non, il n\'y a pas de Quota pour ce plan',
    'createPlanFlowQuotaSetupStep.quotaSetup.cancelSetup': 'Non, au final il n\'y a pas de Quota pour ce plan',
    'createPlanFlowSelectUsersStep.addManagers.title': 'Sélectionnez les utilisateurs qui cumulent des commissions dans ce plan (habituellement les managers)',
    'createPlanFlowSelectUsersStep.addManagers.subTitle': 'Nous allons seulement les ajouter au plan de commissions, vous pourrez les inviter dans Palette plus tard.',
    'createPlanFlowSelectUsersStep.addManagers.empty': 'Aucun manager dans ce plan pour l\'instant',
    'createPlanFlowSelectUsersStep.addManagers.addUsersBtnLabel_zero': 'Aucun nouveau manager sélectionné',
    'createPlanFlowSelectUsersStep.addManagers.addUsersBtnLabel_one': 'Ajouter le manager sélectionné',
    'createPlanFlowSelectUsersStep.addManagers.addUsersBtnLabel_other': 'Ajouter les {{count}} managers sélectionnés',
    'createPlanFlowSelectUsersStep.addManagers.managersInPlan_zero': 'Managers dans le plan',
    'createPlanFlowSelectUsersStep.addManagers.managersInPlan_one': 'Manager dans le plan',
    'createPlanFlowSelectUsersStep.addManagers.managersInPlan_other': 'Managers dans le plan ({{count}})',
    'createPlanFlowSelectUsersStep.addUsers.title': 'Sélectionnez les utilisateurs avec lesquels calculer les commissions',
    'createPlanFlowSelectUsersStep.addUsers.subTitle': 'Nous allons seulement les ajouter au plan de commissions, vous pourrez les inviter dans Palette plus tard.',
    'createPlanFlowSelectUsersStep.addUsers.empty': 'Aucun utilisateur dans ce plan pour l\'instant',
    'createPlanFlowSelectUsersStep.addUsers.addUsersBtnLabel_zero': 'Aucun nouvel utilisateur sélectionné',
    'createPlanFlowSelectUsersStep.addUsers.addUsersBtnLabel_one': 'Ajouter l\'utilisateur sélectionné',
    'createPlanFlowSelectUsersStep.addUsers.addUsersBtnLabel_other': 'Ajouter les {{count}} utilisateurs sélectionnés',
    'createPlanFlowSelectUsersStep.addUsers.usersInPlan_zero': 'Utilisateurs dans le plan',
    'createPlanFlowSelectUsersStep.addUsers.usersInPlan_one': 'Utilisateur dans le plan',
    'createPlanFlowSelectUsersStep.addUsers.usersInPlan_other': 'Utilisateurs dans le plan ({{count}})',
    'createPlanFlowStep.back': 'Retour à {{previousStep}}',
    'createPlanFlowStep.next': 'Étape suivante',
    'createPlanFlowStep.skip': 'Passer',
    'createPlanFlowStep.finish': 'Terminer la création',
    'createPlanFlowSteps.title': 'Étapes de création',
    'createPlanFlowSteps.step_general.label': 'Configuration Générale',
    'createPlanFlowSteps.step_general.subTitle': 'Configurez les paramètres généraux de votre plan de compensation.',
    'createPlanFlowSteps.select_users.label': 'Selection des Utilisateurs',
    'createPlanFlowSteps.select_users.subTitle': 'Définissez qui fait partie de l\'équipe pour ce plan de compensation.',
    'createPlanFlowSteps.data_source.label': 'Source de données',
    'createPlanFlowSteps.data_source.subTitle': 'Dans cette section, vous pouvez selection une source de données à partir de laquelle effectuer les calculs de compensations.',
    'createPlanFlowSteps.data_filtering.label': 'Filtrage des données',
    'createPlanFlowSteps.data_filtering.subTitle': 'Vous pouvez sélectionner seulement les {{dealType}} avec des attributs spécifiques.',
    'createPlanFlowSteps.quota_setup.label': 'Configuration du Quota',
    'createPlanFlowSteps.quota_setup.subTitle': 'Configurez le Quota pour les utilisateurs dans ce plan.',
    'createPlanFlowSteps.quota_attainment.label': 'Définition de l\'atteinte du Quota',
    'createPlanFlowSteps.quota_attainment.subTitle': 'Définissez la valeur de chaque {{dealType}}.',
    'createPlanFlowSteps.commission_rules.label': 'Règles de commission',
    'createPlanFlowSteps.commission_rules.subTitle': 'Définissez comment sont calculées les commissions.',
    'createPlanFlowSteps.payout_schedule.label': 'Règles de paiements',
    'createPlanFlowSteps.payout_schedule.subTitle': 'Définissez quand payer les commissions. Vous pouvez définir un calendrier des paiements basé sur certains champs.',
    'createPlanFlowSteps.summary.label': 'Résumé',
    'createPlanFlowSteps.summary.subTitle': 'Voici un résumé de la configuration de votre plan.',
    'createPlanFlowSummaryStep.firstPeriod': 'Première période',
    'createPlanFlowSummaryStep.lastPeriod': 'Dernière période',
    'createPlanFlowSummaryStep.general': 'Général',
    'createPlanFlowSummaryStep.users': 'Utilisateurs',
    'createPlanFlowSummaryStep.dataSource': 'Source do données',
    'createPlanFlowSummaryStep.quota': 'Quota',
    'createPlanFlowSummaryStep.quota.linked': 'Un Quota est lié à ce plan',
    'createPlanFlowSummaryStep.quota.noQuota': 'Aucun Quota n\'a été défini pour ce plan',
    'createPlanFlowSummaryStep.quota.view': 'Voir le Quota',
    'createPlanFlowSummaryStep.commissionRules': 'Règles de commission',
    'createPlanFlowSummaryStep.payoutRules': 'Règles de paiements',
    'createPlanFlowSummaryStep.noRules': 'Aucune règle n\'est définie pour ce plan',
    'createPlanFlowSummaryStep.noRules.because.noQuota': 'Aucune règle n\'est définie car aucun quota n\'est défini pour ce plan',
    'createPlanFlowSummaryStep.noRules.because.noCommission': 'Aucune règle n\'est définie car aucune commission n\'est définie pour ce plan',
    'createPlanFlowSummaryStep.planName': 'Nom du Plan',
    'createPlanFlowSummaryStep.planType': 'Type de Plan',
    'createPlanFlowSummaryStep.tracking': 'Ressource',
    'createPlanFlowSummaryStep.numberUsers': 'Nombre d\'utilisateurs',
    'createPlanFlowSummaryStep.numberManagers': 'Nombre de managers',
    'createPlanFlowTestFilters.testBtn.label': 'Tester les Filtres',
    'createPlanFlowTestFilters.neverRun.description': 'Le nombre de {{dealType}} trouvé·e·s sera affiché ici.',
    'createVariableModal.createNewVariable': 'Nouvelle Variable Utilisateur',
    'createVariableModal.createVariable': 'Créer la variable',
    'createVariableModal.form.name.label': 'Nom de la variable',
    'createVariableModal.form.name.rules.required': 'Merci de nommer votre variable avec un nom de variable valable (pas de caractères spéciaux, ex: nom_de_propriete).',
    'createVariableModal.form.name.rules.alreadyExists': 'Une variable avec le même nom existe déjà.',
    'createVariableModal.form.variableType.label': 'Type de la variable',
    'createVariableModal.form.variableDefaultValue.label': 'Quelle est la valeur par défaut de la variable ?',
    'createVariableModal.form.variableDefaultValue.rules.required': 'Merci de préciser la valeur par défaut de cette variable.',
    'dashboard.errors.plan.not.found': 'Le plan n\'a pas été trouvé.',
    'dashboardPreset.errors.preset.not.found': 'Le Tableau de bord n\'a pas été trouvé.',
    'dashboardPreset.errors.plan.not.found': 'Le plan n\'a pas été trouvé.',
    'dashboardPreset.errors.object.not.found': 'Les données de l\'utilisateur n\'ont pas été trouvées.',
    'dashboardPreset.errors.user.not.found': 'L\'utilisateur n\'a pas été trouvé.',
    'dashboardPreset.errors.preset.default.cannot.delete': 'Vous ne pouvez pas supprimer un Tableau de bord par défaut.',
    'dashboardPreset.applyDashboardPresetToUsers': 'Sélectionner les utilisateurs et assigner le Tableau de bord',
    'dashboardPreset.deleteDashboardPreset': 'Supprimer le Tableau de bord',
    'dashboardPresetsPage.page.title': 'Tableaux de bord',
    'dashboardPresetsListItem.components_zero': 'Aucun widget',
    'dashboardPresetsListItem.components_one': '{{count}} component',
    'dashboardPresetsListItem.components_other': '{{count}} components',
    'dashboardPresetsListItemMoreOptionsMenu.deleteDashboardPreset': 'Supprimer le tableau de bord',
    'dashboardPresetDetailsPageContent.widgets.title': 'Widgets ({{count}})',
    'dashboardPresetDetailsPageContent.preview.title': 'Aperçu du Tableau de bord',
    'dashboardPresetDetailsPageContent.preview.alert.message': 'Sélectionnez un utilisateur pertinent pour les widgets',
    'dashboardPresetDetailsPageContent.preview.alert.description': 'Certains utilisateurs peuvent ne pas être pertinents pour un tableau de bord donné. Pensez à modifier l\'utilisateur sélectionné pour qu\'il corresponde à l\'équipe pour laquelle vous concevez le tableau de bord.',
    'dashboardPresetDetailsPageContent.widgets.addWidget': 'Ajouter un widget',
    'dataConnectionConnectorPreview.previewData': 'Prévisualiser les données',
    day_zero: 'jour',
    day_one: 'jour',
    day_other: 'jours',
    'deactivateUserButton.label': 'Désactiver l\'utilisateur',
    'deactivateUserModal.title': 'Désactiver l\'utilisateur',
    'deactivateUserModal.confirm': 'Désactiver',
    'deactivateUserModal.content': 'La désactivation de l\'utilisateur va supprimer son compte. Confirmez-vous vouloir désactiver {{displayName}} ?',
    deleteConditionsWithCount_zero: 'Supprimer {{count}} $t(conditions_zero)',
    deleteConditionsWithCount_one: 'Supprimer {{count}} $t(conditions_one)',
    deleteConditionsWithCount_other: 'Supprimer {{count}} $t(conditions_other)',
    'deleteDashboardPresetModal.title': 'Vous êtes sur le point de supprimer le Tableau de bord "{{presetName}}".',
    'deleteDashboardPresetModal.confirm': 'Je confirme',
    'deleteDashboardPresetModal.content': 'Veuillez confirmer que vous souhaitez supprimer ce Tableau de bord. Les utilisateurs ne perdront pas leur Tableau de bord, vous perdrez simplement le modèle pour les nouveaux arrivants.',
    'deleteMasterPlanModal.title': 'Supprimer le Plan',
    'deleteMasterPlanModal.confirm': 'Je confirme',
    'deleteMasterPlanModal.content': 'Confirmez-vous vouloir supprimer <strong>{{planName}}</strong> ?',
    'deleteNotebookModal.title': 'Supprimer le Modèle',
    'deleteNotebookModal.confirm': 'Je confirme',
    'deleteNotebookModal.content': 'Confirmez-vous vouloir supprimer <strong>{{notebookName}}</strong> ?',
    'deleteNotebookCellModal.title': 'Supprimer la cellule',
    'deleteNotebookCellModal.confirm': 'Je confirme',
    'deleteNotebookCellModal.content': 'Confirmez-vous vouloir supprimer <strong>{{cellName}}</strong> de <strong>{{notebookName}}</strong> ?',
    'deleteTeamModal.title': 'Supprimer l\'Équipe',
    'deleteTeamModal.confirm': 'Je confirme',
    'deleteTeamModal.content': 'Confirmez-vous vouloir supprimer <strong>{{teamName}}</strong> ?',
    'deleteVariableValueHistoryItem.deleteItem.popconfirm': 'Confirmez-vous vouloir supprimer cette entrée de l\'historique ?',
    'drawerVariableEdition.placeholders.variableName': 'Nom de la variable',
    'drawerVariableEdition.placeholders.fields': 'Choisir les champs',
    'duplicateMasterPlanModal.title': 'Dupliquer un Plan existant',
    'duplicateMasterPlanModal.duplicate': 'Dupliquer',
    'duplicateMasterPlanModal.copyOf': 'Copie de',
    'duplicateMasterPlanModal.form.planId.label': 'Choisissez un Plan à dupliquer',
    'duplicateMasterPlanModal.form.keepUsers.label': 'Conserver les utilisateurs existants dans le nouveau plan',
    'duplicateMasterPlanModal.form.terminateParent.label': 'Terminer {{name}} à la fin de {{endPeriod}}',
    'duplicateMasterPlanModal.goalLabel': 'Objectif du plan dupliqué',
    'duplicateMasterPlanModal.goal.newPlan.label': 'Créer un<br />nouveau plan',
    'duplicateMasterPlanModal.goal.newPlan.description': 'Basé sur les réglages existants',
    'duplicateMasterPlanModal.goal.newVersion.label': 'Créer une<br />nouvelle version',
    'duplicateMasterPlanModal.goal.newVersion.description': 'Je veux faire une nouvelle version du plan original',
    'duplicateMasterPlanSelect.placeholder': 'Choisissez un Plan à dupliquer',
    'editableNotebookDescription.emptyDescription': 'Aucune description',
    'editColumnCustomDisplayModal.title': 'Fonction d\'affichage custom',
    'editColumnCustomDisplayModal.validate': 'Valider',
    'editColumnCustomDisplayModal.form.formula.label': 'Fonction d\'affichage',
    'editColumnCustomDisplayModal.form.formula.description': 'Les champs suivants que vous avez choisis peuvent être utilisés dans la fonction: <strong>{{fields}}</strong>',
    'editCommissionAmountModal.commissionFor': 'Commission pour {{dealName}}',
    'editCommissionAmountModal.commission': 'Commission',
    'editCommissionAmountModal.overwriteAmount': 'Redéfinir le montant',
    'editCommissionAmountModal.form.amount.label': 'Montant',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.title': 'Êtes-vous sûr de vouloir réinitialiser la commission à son montant d\'origine ?',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.confirm': 'Oui',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.cancel': 'Non',
    'editCommissionAmountModal.resetToOriginalAmount.popconfirm.cta': 'Réinitialiser au montant d\'origine ({{amount}})',
    'editCommissionAmountModal.paymentsDone.alert.message': '{{percentage}}% ({{amount}}) de cette commission ont déjà été payés.',
    'editCommissionValueModal.labelDefinitionFor': '{{labelDefinition}} pour {{dealName}}',
    'editCommissionValueModal.overwriteValue': 'Redéfinir la valeur',
    'editCommissionValueModal.form.amount.label': 'Valeur',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.title': 'Êtes-vous sûr de vouloir réinitialiser la commission à sa valeur d\'origine ?',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.confirm': 'Oui',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.cancel': 'Non',
    'editCommissionValueModal.resetToOriginalAmount.popconfirm.cta': 'Réinitialiser à la valeur d\'origine ({{value}})',
    'editDataConnectionConnectorModal.title': 'Modification des données importées',
    'editDataConnectionConnectorModal.update': 'Mettre à jour la source de données',
    'editDefaultValuesPanel.saveDefaultValue': 'Enregistrer',
    'editDefaultValuesPanel.startingDate': 'Date de départ',
    'editDefaultValuesPanel.defaultValue': 'Valeur par défaut',
    'editDefaultValuesPanel.addNewDefaultValue': 'Ajouter une nouvelle valeur par défaut',
    'editDefaultValuesPanel.alert.noDefaultValues.message': 'Aucune valeur par défaut',
    'editableRoleDescription.noRoleDescription': 'Aucune description de role',
    'editOnboardUserButton.editUserData': 'Modifier les informations de l\'utilisateur',
    'editOnboardUserModal.title': 'Modification des informations de l\'utilisateur',
    'editOnboardUserModal.confirm': 'Mettre à jour',
    'editVariableCustomDisplayModal.title': 'Fonction d\'affichage custom',
    'editVariableCustomDisplayModal.validate': 'Valider',
    'editVariableCustomDisplayModal.form.formula.label': 'Fonction d\'affichage',
    'editVariableCustomDisplayModal.form.formula.description': 'Les champs suivants que vous avez choisis peuvent être utilisés dans la fonction: <strong>{{fields}}</strong>',
    'editVariableValueModal.editVariableModalTitle': 'Mise à jour de la Variable: {{variableName}}',
    'editVariableValueModal.editVariable': 'Mettre à jour la variable',
    'editVariableValueModal.form.value.label': 'Quelle est la nouvelle valeur pour cette variable ?',
    'editVariableValueModal.form.dateValue.label': 'Quelle est la nouvelle date pour cette variable (en UTC+0) ?',
    'editVariableValueModal.form.value.rules.required': 'Merci de définir la nouvelle valeur pour cette variable.',
    'editVariableValueModal.form.date.label': 'Utiliser cette nouvelle valeur à partir du',
    'editVariableValueModal.form.date.rules.required': 'Merci de sélectionner une date valide.',
    'editVariableValueModal.editVariableModalSubTitle.multiUsers': 'Pour {{count}} utilisateurs',
    'editVariableValueModal.editVariableModalSubTitle.singleUser': 'Pour {{userDisplayName}}',
    'emailNotifications.block.title': 'Notifications par email',
    'emailNotifications.content.text.intro': 'Configuration des notifications par e-mail.',
    'emailPasswordForm.form.email.label': 'Adresse mail',
    'emailPasswordForm.form.email.rules.required': 'Merci de renseigner votre adresse mail.',
    'emailPasswordForm.form.email.placeholder': 'Votre adresse mail',
    'emailPasswordForm.form.password.label': 'Mot de passe',
    'emailPasswordForm.form.password.rules.required': 'Merci de renseigner votre mot de passe.',
    'emailPasswordForm.form.password.placeholder': 'Votre mot de passe',
    'emailPasswordForm.form.submit': 'Se connecter',
    'statementValidations.content.switch.weekly': 'Récapitulatif hebdomadaire des commissions',
    'statementValidations.content.switch.monthly': 'Récapitulatif mensuel des commissions',
    'statementValidations.content.switch.threads': 'Nouveaux commentaires sur les discussions',
    'statementValidations.content.title.authorization': 'Autoriser les résumés d\'e-mails pour les commerciaux',
    'statementValidations.content.text.authorization': 'Les représentants peuvent recevoir des résumés hebdomadaires et mensuels de leurs commissions. Ils peuvent gérer leurs préférences dans leurs paramètres si vous autorisez les e-mails. Ils n\'auront pas d\'e-mails ni ne verront les options si vous les désactivez.',
    'statementValidations.content.switch.authorization': 'Autoriser le résumé des commissions',
    'statementValidations.content.switch.newStatement': 'Nouvelle déclaration validée',
    'fastOnboardLink.label': 'Je ne trouve pas mes utilisateurs.',
    'fastOnboardModal.title': 'Enregistrement rapide',
    'fastOnboardModal.description': 'Sélectionnez les utilisateurs que vous souhaitez enregistrer rapidement avant de pouvoir les ajouter à votre plan ou à votre quota. Comme il s\'agit d\'un processus d\'enregistrement accéléré, vous devrez probablement mettre à jour les informations des utilisateurs pour terminer leur enregistrement.',
    'fastOnboardModal.continue': 'Continuer',
    'fastOnboardModal.onboardUsers': 'Enregistrer les utilisateurs',
    'fiscalYear.FY': 'AF',
    'formulaHelper.formulaHelperTitle': 'Aide aux formules',
    'folder.errors.delete.not.empty': 'Erreur lors de la suppression de ce dossier puisqu\'il contient des plans',
    'folder.errors.cannot.be.self.parent': 'Le dossier ne peut pas être parent de lui même.',
    'folder.errors.folder.not.found': 'Le dossier n\'a pas été trouvé.',
    'folder.errors.parent.not.found': 'Le parent du dossier n\'a pas été trouvé.',
    'folders.modals.folderName': 'Nom du dossier',
    'folders.modals.rules.folderName': 'Veuillez entrer un nom de dossier.',
    'folders.modals.add.confirm': 'Créer le dossier',
    'folders.modals.edit.confirm': 'Renommer le dossier',
    'folders.modals.add.title': 'Créer un nouveau dossier',
    'folders.modals.edit.title': 'Renommer le dossier "{{name}}"',
    'folders.modals.defaultName': 'Dossier sans titre',
    'folder.actions.rename.tooltip': 'Renommer le dossier',
    'folder.actions.delete.tooltip': 'Supprimer le dossier',
    'folder.actions.delete.disabled.tooltip': 'Impossible de supprimer ce dossier tant qu\'il n\'est pas vide',
    'folder.actions.delete.popconfirm': 'Êtes-vous sûr de vouloir supprimer ce dossier ?',
    'forgotPasswordForm.form.email.label': 'Adresse mail',
    'forgotPasswordForm.form.email.rules.required': 'Merci de renseigner votre adresse mail.',
    'forgotPasswordForm.form.email.placeholder': 'Votre adresse mail',
    'forgotPasswordForm.form.submit': 'J\'ai oublié mon mot de passe',
    'forgotPasswordPageContent.title': 'Mot de passe oublié ?',
    'forgotPasswordPageContent.login': 'Se connecter',
    'frequency.monthly': 'Mensuel',
    'frequency.month': 'Mois',
    'frequency.monthly.short.periods.0': 'Jan',
    'frequency.monthly.short.periods.1': 'Fév',
    'frequency.monthly.short.periods.2': 'Mar',
    'frequency.monthly.short.periods.3': 'Avr',
    'frequency.monthly.short.periods.4': 'Mai',
    'frequency.monthly.short.periods.5': 'Jui',
    'frequency.monthly.short.periods.6': 'Jui',
    'frequency.monthly.short.periods.7': 'Aoû',
    'frequency.monthly.short.periods.8': 'Sep',
    'frequency.monthly.short.periods.9': 'Oct',
    'frequency.monthly.short.periods.10': 'Nov',
    'frequency.monthly.short.periods.11': 'Déc',
    'frequency.monthly.periods.0': 'Janvier',
    'frequency.monthly.periods.1': 'Février',
    'frequency.monthly.periods.2': 'Mars',
    'frequency.monthly.periods.3': 'Avril',
    'frequency.monthly.periods.4': 'Mai',
    'frequency.monthly.periods.5': 'Juin',
    'frequency.monthly.periods.6': 'Juillet',
    'frequency.monthly.periods.7': 'Août',
    'frequency.monthly.periods.8': 'Septembre',
    'frequency.monthly.periods.9': 'Octobre',
    'frequency.monthly.periods.10': 'Novembre',
    'frequency.monthly.periods.11': 'Décembre',
    'frequency.bimonthly': 'Bimestriel',
    'frequency.bimonth': 'Bimestre',
    'frequency.bimonthly.periods.0': 'Jan-Fev',
    'frequency.bimonthly.periods.1': 'Mar-Avr',
    'frequency.bimonthly.periods.2': 'Mai-Jun',
    'frequency.bimonthly.periods.3': 'Jul-Aoû',
    'frequency.bimonthly.periods.4': 'Sep-Oct',
    'frequency.bimonthly.periods.5': 'Nov-Déc',
    'frequency.quarterly': 'Trimestriel',
    'frequency.quarter': 'Trimestre',
    'frequency.quarterly.periods.0': 'T1',
    'frequency.quarterly.periods.1': 'T2',
    'frequency.quarterly.periods.2': 'T3',
    'frequency.quarterly.periods.3': 'T4',
    'frequency.fourMonthsPeriod': 'Quadrimestriel',
    'frequency.fourMonths': 'Quadrimestre',
    'frequency.fourMonthsPeriod.periods.0': 'Jan-Avr',
    'frequency.fourMonthsPeriod.periods.1': 'Mai-Aoû',
    'frequency.fourMonthsPeriod.periods.2': 'Sep-Déc',
    'frequency.halfYearly': 'Semestriel',
    'frequency.halfYear': 'Semestre',
    'frequency.halfYearly.periods.0': 'S1',
    'frequency.halfYearly.periods.1': 'S2',
    'frequency.yearly': 'Annuel',
    'frequency.year': 'Année',
    'frequency.yearly.periods.0': 'Année',
    'frequency.day': 'Tous les X jours',
    'frequency.day.weekly': 'Hebdomadaire',
    'frequency.day.every': 'Tous les {{frequency}} $t(day, {"count": {{frequency}} })',
    'frequency.custom': 'Dates personnalisées',
    'generateWithAIButton.label': 'Générer avec l\'IA',
    'generateWithAIModal.title': 'Générer avec l\'IA',
    'generateWithAIModal.generate': 'Générer',
    'generateWithAIModal.form.prompt.label': 'Prompt',
    'generateWithAIModal.form.prompt.rules.required': 'Merci de renseigner un prompt',
    'global.error.unknown': 'Une erreur s\'est produite.',
    'global.error.bad.request': 'Le serveur ne peut pas traiter cette requête.',
    'global.error.constraint.violation': 'Erreur de violation de contrainte',
    'global.error.constraint.violation.content': 'Une ou plusieurs contraintes de données n\'ont pas été respectées.',
    'global.error.constraint.violation.list': '- Violation sur <strong>{{field}}</strong> : <strong>{{errorMessage}}</strong>;',
    'global.error.unauthorized': 'Vous n\'êtes pas autorisé à effectuer cette action.',
    'global.error.invalid.session': 'Votre session est invalide.',
    'global.error.not.found': 'La ressource n\'a pas été trouvée.',
    'global.error.method.not.allowed': 'Cette requête n\'est pas autorisée.',
    'global.error.server.error': 'Une erreur serveur s\'est produite.',
    'global.error.maintenance.enabled': 'Une maintenance est en cours. Nous serons de retour très vite!',
    'googleAuthButton.error': 'Une erreur s\'est produite lors de l\'authentification avec Google',
    'googleAuthButton.signIn': 'Se connecter avec Google',
    'highlightCommissionsDueEstimateWidget.title': 'Futur paiements',
    'highlightCommissionsDueEstimateWidget.subtitle': 'A partir de {{periodName}}',
    'highlightCommissionsTotalWidget.title': 'Total commissions',
    'highlightCommissionsUnreleasedWidget.title': 'Commissions sans aucun paiement prévu',
    'highlightzone.widgets.filters': 'Filtrer les widgets',
    'highlightzone.widgets.filtersClose': 'Fermer pour sauvegarder',
    'icMenu.title': 'Privée',
    'icMenu.myDashboard': 'Mon Dashboard',
    'icMenu.myPlans': 'Mes Plans',
    'icMenu.myStatements': 'Statements',
    'icMenu.myCommissions': 'Mes Commissions',
    'icMenu.myCommissionList': 'Liste de commissions',
    'icMenu.notifications': 'Notifications',
    'inBulkEditVariableValue.inBulkEdit': 'Mettre à jour pour tous les utilisateurs sélectionnés',
    'inputTable.copyColumnName': 'Copier le nom de la colonne',
    'inputTable.columnType': 'Type',
    'inputTable.columnType.string': 'Chaine de caractères',
    'inputTable.columnType.number': 'Nombre',
    'inputTable.columnType.boolean': 'Booléen',
    'inputTable.deleteColumn': 'Supprimer la colonne',
    'inputTable.editColumnName': 'Renommer la colonne',
    'inputTable.deleteRow': 'Supprimer la ligne',
    'inputTableNewColumnModal.title.newColumn': 'Nouvelle colonne',
    'inputTableNewColumnModal.addNewColumn': 'Ajouter la colonne',
    'inputTableNewColumnModal.form.name.label': 'Nom de la colonne',
    'inputTableNewColumnModal.form.name.rules.required': 'Merci de renseigner un nom pour la nouvelle colonne (nom de variable valable, sans caractères spéciaux, ex: nom_de_propriete)',
    'inputTableNewColumnModal.form.name.rules.alreadyExists': 'Une colonne avec le même nom existe déjà.',
    'inputTableEditColumnModal.title.editColumn': 'Modifier la colonne',
    'inputTableEditColumnModal.updateColumn': 'Mettre à jour la colonne',
    'inputTableEditColumnModal.form.name.label': 'Nom de la colonne',
    'inputTableEditColumnModal.form.name.rules.required': 'Merci de renseigner un nom pour la nouvelle colonne (nom de variable valable, sans caractères spéciaux, ex: nom_de_propriete)',
    'inputTableEditColumnModal.form.name.rules.alreadyExists': 'Une colonne avec le même nom existe déjà.',
    'inviteUserLink.label': 'Inviter l\'utilisateur',
    'inviteUserModal.title': 'Inviter {{userDisplayName}}',
    'inviteUserModal.confirm': 'Inviter',
    'inviteUserModal.form.email.label': 'Adresse mail',
    'inviteUserModal.form.email.rules.required': 'Merci de renseigner une adresse mail.',
    'inviteUserModal.form.email.rules.email': 'Merci de fournir une adresse mail valide.',
    'inviteUserModal.form.role.label': 'Rôle',
    'inviteUserModal.form.hireDate.label': 'Définir une date d\'embauche?',
    'inviteUserModal.form.teams.label': 'Sélectionner son/ses équipe(s)',
    'inviteUserModal.form.dashboardDesign.label': 'Tableau de bord',
    loading: 'Chargement',
    'linkResourcesToUser.label': 'Lier des Ressources',
    'linkResourcesToUserModal.title': 'Lier des ressources à {{displayName}}',
    'linkResourcesToUserModal.linkResources': 'Lier les ressources',
    'listPlanItem.title': 'Commissions',
    'listPlanItem.viewType': 'Voir {{type}}',
    'listPlanItem.viewPlanDetails': 'Voir les détails du plan',
    'loginPageContent.forgotPassword': 'Mot de passe oublié ?',
    'loginPageContent.intermediaryTitle': 'Ou se connecter par adresse mail',
    'loginPageContent.title': 'Connexion',
    'loginPageContent.noAccountYet': 'Je n\'ai pas encore de compte',
    'maintenance.description.title': 'Palette est actuellement en maintenance',
    'maintenance.description.subtitle': 'Nous serons de retour dans quelques heures',
    'masterPlan.frequency.monthly': 'Mensuel',
    'masterPlan.frequency.bimonthly': 'Bimestriel',
    'masterPlan.frequency.quarterly': 'Trimestriel',
    'masterPlan.frequency.fourMonthsPeriod': 'Quadrimestriel',
    'masterPlan.frequency.halfYearly': 'Semestriel',
    'masterPlan.frequency.yearly': 'Annuel',
    'masterPlanDependencies.title': 'Dépendances',
    'masterPlanDependencyItem.options_one': 'Option: ',
    'masterPlanDependencyItem.options_other': 'Options: ',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.auto': 'Auto (Meilleure correspondance de périodes)',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.relative': 'Période relative',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.absolute': 'Période fixe',
    'masterPlanDependencyItem.injectionPeriodMatchStrategies.formula': 'Formule',
    'masterPlanDependencyItem.relativePeriod': 'P{{relativePeriod}} (En considérant que la dernière période du plan courant est {{lastPeriodName}}, la période {{relativePeriodName}} sera injectée)',
    'masterPlanDependencyForm.alerts.planIsInFuture': 'Le plan sélectionné n\'a pas encore commencé.',
    'masterPlanDependencyForm.alerts.planIsInPast': 'Le plan sélectionné est déjà terminé.',
    'masterPlanDependencyForm.title': 'Ajout d\'une dépendance',
    'masterPlanDependencyForm.form.name.label': 'Nom de la dépendance',
    'masterPlanDependencyForm.form.name.rules.required': 'Merci de nommer votre dépendance.',
    'masterPlanDependencyForm.form.name.rules.pattern': 'Merci de nommer votre dépendance avec un nom de variable valable (pas de caractères spéciaux, ex: nom_de_propriete).',
    'masterPlanDependencyForm.form.name.placeholder': 'Ex: myInjectedPlan1',
    'masterPlanDependencyForm.form.plan.label': 'Plan',
    'masterPlanDependencyForm.form.plan.rules.required': 'Merci de choisir un plan',
    'masterPlanDependencyForm.form.periodMatchType.label': 'Type de correspondance des périodes',
    'masterPlanDependencyForm.form.absolutePeriod.label': 'Choisissez une période fixe',
    'masterPlanDependencyForm.form.absolutePeriod.rules.required': 'Merci de sélectionner une période',
    'masterPlanDependencyForm.form.relativePeriod.label': 'Choisissez une période relative',
    'masterPlanDependencyForm.form.relativePeriod.description': 'Les exemples sont donnés relativement à la dernière période du plan courant ({{lastPeriodName}})',
    'masterPlanDependencyForm.form.relativePeriod.rules.required': 'Merci de sélectionner une période',
    'masterPlanDependencyForm.form.relativePeriod.rules.isNegative': 'Merci de renseigner un nombre négatif',
    'masterPlanDependencyForm.form.relativePeriod.inThePast': 'Dans le passé',
    'masterPlanDependencyForm.form.relativePeriod.inTheFuture': 'Dans le futur',
    'masterPlanDependencyForm.form.submitBtnLabel': 'Enregistrer',
    'masterPlanDependencyForm.deleteAction.description': 'Voulez-vous supprimer cette dépendance ?',
    'masterPlanDependencyForm.deleteAction.btnLabel': 'Supprimer',
    'masterPlanDependencyForm.cancelAction.btnLabel': 'Annuler',
    'masterPlanDependencyForm.addDependencyOptions': 'Options de la dépendance',
    'masterPlanDependencyForm.form.withDataPoints.label': 'Historique de la progression',
    'masterPlanDependencyForm.form.withDataPoints.description': 'Choisissez d\'inclure ou non l\'historique de progression dans la dépendance. Notez que cette option n\'est pas cochée par défaut car elle consomme beaucoup de ressources et ne doit être incluse qu\'en cas de besoin. Vous pourriez en avoir besoin lorsque vous essayez de vérifier quand un utilisateur (dans le plan de dépendance) a réussi une progression / un quota spécifique.',
    'masterPlanDependencyForm.form.withPayments.label': 'Liste des paiements',
    'masterPlanDependencyForm.form.withPayments.description': 'Choisissez d\'inclure ou non la liste complète des paiements dans la dépendance. Vous pourriez en avoir besoin lorsque vous essayez d\'obtenir les montants de paiements réels au lieu des montants de commission théoriques (total des commissions libérées/payées).',
    'masterPlanDependencyForm.form.type.label': 'Utilisateurs à injecter',
    'masterPlanDependencyForm.form.type.description': 'Choisissez d\'injecter les informations de tous les utilisateurs ou uniquement celles qui correspondent à l\'utilisateur donné',
    'masterPlanDependencyForm.injectionTypes.match_user': 'Utilisateur correspondant',
    'masterPlanDependencyForm.injectionTypes.all_users': 'Tous les utilisateurs',
    'masterPlanDependencyForm.injectionPeriodMatchStrategies.auto': 'Auto (Meilleure correspondance de périodes)',
    'masterPlanDependencyForm.injectionPeriodMatchStrategies.relative': 'Choisissez une période relative',
    'masterPlanDependencyForm.injectionPeriodMatchStrategies.absolute': 'Choisissez une période fixe',
    'masterPlanFreezeType.all': 'Geler tous les calculs et règles de paiement',
    'masterPlanFreezeType.rules_only': 'Geler uniquement les calculs de commission',
    'masterPlanHiddenInfo.description': 'Ce plan est caché. Il n\'est pas visible pour les représentants ou dans les déclarations. Vous pouvez rendre ce plan visible dans les',
    'masterPlanHiddenInfo.description.settings.label': 'Paramètres de Configuration',
    'masterPlan.lastPeriod.viewPeriod': 'Voir {{periodName}}',
    'masterPlan.lastPeriod.totalCommission': 'Commission ·',
    'masterPlan.moreMenu.deletePlan': 'Supprimer le plan',
    'masterPlan.moreMenu.archivePlan': 'Archiver le plan',
    'masterPlan.moreMenu.duplicatePlan': 'Dupliquer le plan',
    'masterPlan.moreMenu.comparePlan': 'Comparer le plan',
    'masterPlan.seeLastPeriods': 'Voir les dernières périodes',
    'masterPlan.topActions.settings': 'Configuration du plan',
    'masterPlan.topActions.recompute': 'Rafraîchir les données du plan',
    'masterPlan.scope.team': 'Equipe',
    'masterPlan.scope.manager': 'Manager',
    'masterPlan.scope.hidden': 'Caché pour les Représentants',
    'masterPlan.discardPlan': 'Jeter le plan',
    'masterPlanBreadcrumb.plans': 'Plans',
    'masterPlanList.empty.description': 'Aucun Plan disponible',
    'masterPlanPeriodFreezeButton.freezeUntilPeriod': 'Geler',
    'masterPlanPeriodFreezeButton.freezeUntilPeriod.tooltip': 'Cliquez pour figer cette période et toutes les périodes précédentes.',
    'masterPlanPeriodFreezeButton.freezeUntilPeriod.popconfirm': 'Êtes-vous sûr de figer cette période et toutes les périodes précédentes ?',
    'masterPlanPeriodFreezeButton.unfreezeFromPeriod': 'Dégeler',
    'masterPlanPeriodFreezeButton.unfreezeFromPeriod.tooltip': 'Cliquez pour débloquer cette période et toutes les périodes suivantes.',
    'masterPlanPeriodFreezeButton.unfreezeFromPeriod.popconfirm': 'Êtes-vous sûr de débloquer cette période et toutes les périodes suivantes ?',
    'masterPlanPeriodFreezeButton.autoFreezeEnabled': 'Gelé automatiquement',
    'masterPlanPeriodProgress.elapsedTime': '{{percentage}}% du temps écoulé',
    'masterPlanPeriodsListItem.commissions': 'Commissions',
    'masterPlanListItemFreezeButton.allPeriodsFrozen.tooltip': 'Toutes les périodes précédentes sont gelées',
    'masterPlanListItemFreezeButton.freezeAllPeriods.tooltip': 'Cliquez pour geler toutes les périodes précédentes',
    'masterPlanListItemFreezeButton.freezeAllPeriods.popconfirm': 'Êtes-vous sûr de figer toutes les périodes précédentes ?',
    'masterPlanPeriodDealCommissionsTable.table.headers.user': 'Utilisateur',
    'masterPlanPeriodDealCommissionsTable.table.headers.rule': 'Règle',
    'masterPlanPeriodDealCommissionsTable.ruleNumber': 'Règle n°{{index}}',
    'masterPlanPeriodDealCommissionsTable.table.headers.commission': 'Commission',
    'masterPlanPeriodUserCell.viewDeals': 'Voir les {{dealType}}',
    'masterPlanPeriodDeals.actions.exportDeals': 'Exporter les {{dealType}}',
    'masterPlanPeriodDeals.actions.editColumns': 'Modifier les colonnes',
    'masterPlanPeriodDeals.dealSearch.placeholder': 'Rechercher le nom d\'un·e {{dealType}}',
    'masterPlanPeriodDeals.filterByUser.placeholder': 'Sélectionner un utilisateur',
    'masterPlanPeriodDeals.filterByPayee.placeholder': 'Sélectionner un bénéficiaire',
    'masterPlanPeriodDeals.table.headers.user': 'Utilisateur',
    'masterPlanPeriodDeals.table.headers.date': 'Date',
    'masterPlanPeriodDeals.table.headers.commissions': 'Commissions',
    'masterPlanPeriodDeals.table.headers.payout': 'Paiements',
    'masterPlanPeriodDeals.table.headers.progress': 'Progression',
    'masterPlanPeriodDeals.empty.description': 'Aucun {{dealType}} disponible',
    'masterPlansComparatorPage.title': 'Comparateur de Plans',
    'masterPlansComparatorPage.subtitle': 'Comparer facilement les plans et les commissions',
    'masterPlansListSelect.past': 'Plans Inactifs',
    'masterPlansListSelect.active': 'Plans Actifs',
    'masterPlansListSelect.archived': 'Plans Archivés',
    'masterPlansListSelect.placeholder': 'Choisissez un Plan',
    'masterPlansList.drafts.alert.message': 'Les brouillons sont des plans qui ont été créés mais dont la configuration n\'est pas encore terminée.',
    'masterPlanListDraftItem.continueStep': 'Continuer la configuration',
    'masterPlanListDraftItem.step': 'Etape',
    'masterPlans.errors.plan.not.found': 'Le plan n\'a pas été trouvé.',
    'masterPlans.errors.connector.not.found': 'Le connecteur n\'a pas été trouvée.',
    'masterPlans.errors.parentPlan.not.found': 'Le parent du plan n\'a pas été trouvé.',
    'masterPlans.errors.seats.exhausted': 'Vous devez ajouter des places d\'utilisateurs à votre compte afin d\'en ajouter dans ce plan.',
    'masterPlans.errors.computation.running': 'Veuillez patienter avant de faire cette action, un calcul est en cours.',
    'masterPlans.errors.plan.is.injected': 'Vous ne pouvez pas supprimer un plan qui est injecté ailleurs.',
    'masterPlans.errors.incompatible.plan.scope': 'Le type de Plan est différent, veuillez choisir le même type de Plan.',
    'masterPlansPage.title': 'Plans',
    'masterPlansPageContent.tabs.past': 'Inactifs',
    'masterPlansPageContent.tabs.active': 'Actifs',
    'masterPlansPageContent.tabs.draft': 'Brouillons',
    'masterPlansPageContent.tabs.archived': 'Archivés',
    'masterPlansPageContent.planSearch.placeholder': 'Rechercher un plan',
    'masterPlansPageContent.tabs.compare': 'Comparer les plans',
    'masterPlansPageContent.tabs.createPlanFlow': 'Créer un plan',
    'masterPlansPageContent.tabs.createNewFolder': 'Créer un dossier',
    'masterPlanPeriodDetailsPageContent.timeElapsed': 'Temps écoulé',
    'masterPlanPeriodTabs.tabs.users': 'Utilisateurs',
    'masterPlanPeriodTabs.tabs.managers': 'Managers',
    'masterPlanPeriodUsers.table.headers.users': 'Utilisateurs',
    'masterPlanPeriodUsers.table.headers.target': 'Objectif',
    'masterPlanPeriodUsers.table.headers.totalValue': 'Valeur totale',
    'masterPlanPeriodUsers.table.headers.progress': 'Progression',
    'masterPlanPeriodUsers.table.headers.commissions': 'Commissions',
    'masterPlanPeriodUsers.userSearch.placeholder': 'Rechercher un utilisateur',
    'masterPlanPeriodUsers.addUsers.label': 'Ajouter des utilisateurs',
    'masterPlanPeriodUsers.users.empty.description': 'Aucun utilisateur n\'a été trouvé',
    'masterPlanPeriodUsers.managers.empty.description': 'Aucun manager n\'a été trouvé',
    'masterPlanPeriodUsers.users.disclaimer_zero': 'utilisateurs',
    'masterPlanPeriodUsers.users.disclaimer_one': 'utilisateur',
    'masterPlanPeriodUsers.users.disclaimer_other': 'utilisateurs',
    'masterPlanPeriodUsers.users.disclaimerCount_zero': '{{count}} $t(masterPlanPeriodUsers.users.disclaimer_zero) sont masqués parce qu\'ils ne sont pas dans votre scope.',
    'masterPlanPeriodUsers.users.disclaimerCount_one': '{{count}} $t(masterPlanPeriodUsers.users.disclaimer_one) est masqué parce qu\'il n\'est pas dans votre scope.',
    'masterPlanPeriodUsers.users.disclaimerCount_other': '{{count}} $t(masterPlanPeriodUsers.users.disclaimer_other) sont masqués parce qu\'ils ne sont pas dans votre scope.',
    'masterPlanPeriodUsers.managers.disclaimer_zero': 'managers',
    'masterPlanPeriodUsers.managers.disclaimer_one': 'manager',
    'masterPlanPeriodUsers.managers.disclaimer_other': 'managers',
    'masterPlanPeriodUsers.managers.disclaimerCount_zero': '{{count}} $t(masterPlanPeriodUsers.managers.disclaimer_zero) sont masqués parce qu\'ils ne sont pas dans votre scope.',
    'masterPlanPeriodUsers.managers.disclaimerCount_one': '{{count}} $t(masterPlanPeriodUsers.managers.disclaimer_one) est masqué parce qu\'il n\'est pas dans votre scope.',
    'masterPlanPeriodUsers.managers.disclaimerCount_other': '{{count}} $t(masterPlanPeriodUsers.managers.disclaimer_other) sont masqués parce qu\'ils ne sont pas dans votre scope.',
    'masterPlanPeriodUsers.sortBy.placeholder': 'Trier par',
    'masterPlanPeriodUsers.sortBy.alphabetical': 'Alphabétique',
    'masterPlanPeriodUsers.sortBy.progress': 'Progression',
    'masterPlanPeriodUsers.sortBy.commission': 'Commission',
    'masterPlanPeriodUsers.sortBy.target': 'Objectif',
    'masterPlanPeriodUsers.sortBy.value': 'Valeur de/du {{type}}',
    'masterPlanPeriodsFilter.viewBy': 'Voir par',
    'masterPlanPeriodsFilter.quarter_to_date': 'Depuis ce trimestre',
    'masterPlanPeriodsFilter.year_to_date': 'Depuis cette année',
    'masterPlanPeriodsFilter.past_6_months': 'Depuis les 6 derniers mois',
    'masterPlanPeriodsFilter.all_times': 'Depuis toujours',
    'masterPlanPeriodsFilter.custom': 'Personnalisé',
    'masterPlanPeriodsList.currentPeriod': 'Période courante',
    'masterPlanPeriodsList.lastPeriod': 'Dernière période',
    'masterPlanPeriodsList.pastPeriods': 'Périodes passées ({{count}})',
    'masterPlanPeriodsList.empty.description': 'Aucune Période disponible',
    'masterPlanRecomputeWarning.title': 'Rafraîchir les données',
    'masterPlanRecomputeWarning.description': 'Des modifications importantes ont été apportées, veuillez mettre à jour les commissions en rafraîchissant les données.',
    'masterPlanRecomputeWarning.recompute': 'Rafraîchir les données',
    'masterPlanSettingsCommissionRules.configurationTitle': 'Configuration',
    'masterPlanSettingsCommissionRules.detailsTitle': 'Détails',
    'masterPlanSettingsCommissionRules.noRulesText': 'Aucun règle n\'est définie pour ce plan.',
    'masterPlanSettingsCommissionRules.ruleTitle': 'Rule',
    'masterPlanSettingsCommissionRules.variablesTitle': 'Variables',
    'masterPlanSettingsCommissionRules.ofEach': 'de chaque',
    'masterPlanSettingsCommissionRules.moreThan': 'Pour plus de',
    'masterPlanSettingsCommissionRules.youEarn': 'vous gagnez :',
    'masterPlanSettingsCommissionRules.from': 'De',
    'masterPlanSettingsCommissionRules.to': 'à',
    'masterPlanSettingsCommissionRules.ofYourGoal': 'de votre objectif',
    'masterPlanSettingsCommissionRules.ifYouReach': 'Si vous atteignez',
    'masterPlanSettingsCommissionRules.your': 'votre',
    'masterPlanSettingsCommissionRules.the': 'la',
    'masterPlanSettingsCommissionRules.target': 'cible',
    'masterPlanSettingsCommissionRules.initFormulaTitle': 'Section d\'initialisation',
    'masterPlanSettingsCommissionRules.dependenciesTitle': 'Dependencies',
    'masterPlanSettingsCommissionRules.previewProjection.title': 'Projection',
    'masterPlanSettingsWeCanHelp.configurationText1': 'Si vous n\'êtes pas sûr de savoir comment implémenter ou modifier votre plan de compensation, veuillez ',
    'masterPlanSettingsWeCanHelp.configurationText2': 'cliquer ici',
    'masterPlanSettingsWeCanHelp.configurationText3': ' afin que nous puissions vous aider.',
    'masterPlanSettingsCommissionRulesForm.form.submitBtnLabel': 'Sauvegarder les règles',
    'masterPlanSettingsCommissionRulesForm.noCommissionRules': 'Aucune règle définie',
    'masterPlanSettingsCommissionRulesForm.form.addNewRule': 'Ajouter une nouvelle règle',
    'masterPlanSettingsCommissionRulesForm.form.disclaimer_zero': 'période',
    'masterPlanSettingsCommissionRulesForm.form.disclaimer_one': 'période',
    'masterPlanSettingsCommissionRulesForm.form.disclaimer_other': 'périodes',
    'masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount_zero': 'Sauvegarder les règles impactera {{count}} $t(masterPlanSettingsCommissionRulesForm.form.disclaimer_zero)',
    'masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount_one': 'Sauvegarder les règles impactera {{count}} $t(masterPlanSettingsCommissionRulesForm.form.disclaimer_one)',
    'masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount_other': 'Sauvegarder les règles impactera {{count}} $t(masterPlanSettingsCommissionRulesForm.form.disclaimer_other)',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.PER_OBJECT': 'Par {{type}}',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.PER_TARGET': 'Par Objectif',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.CODE_ONLY': 'Code personnalisé',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.title': 'Type de règle',
    'masterPlanSettingsCommissionRulesForm.form.ruleRemoveTooltip': 'Supprimer cette règle',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.subtitle': 'Sélectionnez le type de règle à utiliser',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.disclaimer.PER_OBJECT': '<strong>Par {{type}}</strong> signifie que vous souhaitez calculer les commissions de chaque {{type}}. Vos niveaux doivent commencer par 0 et terminer par "Infinity".',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.disclaimer.PER_TARGET': '<strong>Par Objectif</strong> signifie que vous souhaitez calculer les commissions en fonction des objectifs atteints. Vos niveaux doivent être triés par ordre croissant.',
    'masterPlanSettingsCommissionRulesForm.form.ruleType.disclaimer.CODE_ONLY': '<strong>Code personnalisé</strong> signifie que vous souhaitez calculer les commissions en fonction d\'une logique personnalisée. Vous devez renvoyer un nombre ou une liste d\'offres avec un attribut "commission".',
    'masterPlanSettingsCommissionRulesForm.form.ruleDesc.title': 'Décrivez la règle de commission en termes clairs pour vos commerciaux',
    'masterPlanSettingsCommissionRulesForm.form.ruleDesc.subtitle': 'Cela aide les employés à comprendre très rapidement les règles de la Commission.',
    'masterPlanSettingsCommissionRulesForm.form.ruleDesc.placeholder': 'Description de la règle pour les employés',
    'masterPlanSettingsCommissionRulesForm.form.ruleName.placeholder': 'Entrez un nom de règle',
    'masterPlanSettingsCommissionRulesForm.form.ruleVariables.title': 'Variables',
    'masterPlanSettingsCommissionRulesForm.form.ruleCode.title': 'Rubrique de codes',
    'masterPlanSettingsCommissionRulesForm.form.ruleCode.placeholder': 'Écrivez le code ici',
    'masterPlanSettingsCommissionRulesForm.form.ruleCode.error.required': 'Veuillez saisir le code',
    'masterPlanSettingsCommissionRulesForm.form.ruleExplanation.error.required': 'Veuillez saisir l\'explication',
    'masterPlanSettingsCommissionRulesForm.form.ruleExplanation.title': 'Explication',
    'masterPlanSettingsCommissionRulesForm.form.ruleExplanation.placeholder': 'Expliquez votre code avec du texte brut (cela sera affiché sur les relevés individuels)',
    'masterPlanSettingsCommissionRulesForm.form.ruleVariables.subtitle': 'Dans cette section, vous pouvez définir les variables que vous souhaitez utiliser dans les règles du plan afin de simplifier chaque règle.',
    'masterPlanSettingsCommissionRulesForm.form.ruleCalculation.title': 'Calcul des commissions',
    'masterPlanSettingsCommissionRulesForm.form.ruleCalculation.subtitle': 'Écrivez les formules pour calculer le montant de la commission',
    'masterPlanSettingsCommissionRulesForm.form.validator.reject': 'Il y a une erreur dans le formulaire. Veuillez vérifier le message d\'erreur sous l\'un des champs de saisie.',
    'masterPlanSettingsPayoutRules.descriptionTitle': 'Description',
    'masterPlanSettingsPayoutRules.detailsTitle': 'Details',
    'masterPlanSettingsPayoutRules.configurationTitle': 'Configuration',
    'masterPlanSettingsPayoutRules.noRulesText': 'Aucune règle de paiement n\'est définie pour ce plan.',
    'masterPlanSettingsPayoutRules.payoutSchedule.title': 'Paiements',
    'masterPlanSettingsPayoutRules.ruleTitle': 'Rule',
    'masterPlanSettingsPayoutRules.ruleStep.conditionMet': 'Si cette condition est remplie',
    'masterPlanSettingsPayoutRules.ruleStep.noOtheronditionMet': 'Si aucune autre condition n\'est remplie.',
    'masterPlanSettingsPayoutRules.ruleStep.definedByCustom': 'Règle définie par une logique de code personnalisé.',
    'masterPlanSettingsPayoutRules.ruleStep.payXOfCommission': 'Payer {{commission}} de la commission.',
    masterPlanSettingsPayoutRulesStepInPayments_zero: 'paiement',
    masterPlanSettingsPayoutRulesStepInPayments_one: 'paiement',
    masterPlanSettingsPayoutRulesStepInPayments_other: 'paiements',
    masterPlanSettingsPayoutRulesStepInPaymentWithCount_zero: 'En {{count}} $t(masterPlanSettingsPayoutRulesStepInPayments_zero)',
    masterPlanSettingsPayoutRulesStepInPaymentWithCount_one: 'En {{count}} $t(masterPlanSettingsPayoutRulesStepInPayments_one)',
    masterPlanSettingsPayoutRulesStepInPaymentWithCount_other: 'En {{count}} $t(masterPlanSettingsPayoutRulesStepInPayments_other)',
    masterPlanSettingsPayoutRulesStepIntervalTypeMonth_zero: 'mois',
    masterPlanSettingsPayoutRulesStepIntervalTypeMonth_one: 'mois',
    masterPlanSettingsPayoutRulesStepIntervalTypeMonth_other: 'mois',
    masterPlanSettingsPayoutRulesStepIntervalTypeDay_zero: 'jour',
    masterPlanSettingsPayoutRulesStepIntervalTypeDay_one: 'jour',
    masterPlanSettingsPayoutRulesStepIntervalTypeDay_other: 'jours',
    masterPlanSettingsPayoutRulesStepIntervalTypeYear_zero: 'an',
    masterPlanSettingsPayoutRulesStepIntervalTypeYear_one: 'an',
    masterPlanSettingsPayoutRulesStepIntervalTypeYear_other: 'ans',
    'masterPlanSettingsPayoutRules.ruleStep.intervalType.every': ', tous les {{count}} {{type}}',
    'masterPlanSettingsPayoutRules.ruleStep.statement': 'Dans la déclaration de :',
    'masterPlanSettingsPayoutRules.ruleStep.starting': 'À partir de :',
    'masterPlanSettingsPayoutRulesForm.form.template.payEndOfPlan_001': 'Payer à la fin de chaque période',
    'masterPlanSettingsPayoutRulesForm.form.template.payEndOfPlan_001.frequency': 'Payer à la fin de chaque {{frequency}}',
    'masterPlanSettingsPayoutRulesForm.form.template.payMonthAfterEndOfPlan_001': 'Payer un mois après la fin de chaque période',
    'masterPlanSettingsPayoutRulesForm.form.template.payMonthAfterEndOfPlan_001.frequency': 'Payer un mois après la fin de chaque {{frequency}}',
    'masterPlanSettingsPayoutRulesForm.form.template.payClosedDate_001': 'Payer un mois après que le {{type}} soit terminé',
    'masterPlanSettingsPayoutRulesForm.form.template.custom': 'Logique de paiement personnalisée',
    'masterPlanSettingsPayoutRulesForm.form.choosePayoutStrategy': 'Choisissez une stratégie de paiement',
    'masterPlanSettingsPayoutRulesForm.form.addNewRule': 'Ajouter une nouvelle règle',
    'masterPlanSettingsPayoutRulesForm.form.submitBtnLabel': 'Enregistrer les règles',
    'masterPlanSettingsPayoutRules.form.defineStrategy': 'Définir une stratégie de paiement personnalisée',
    'masterPlanSettingsPayoutRules.form.describeYourPayment': 'Tout d\'abord, décrivez vos règles de paiement',
    'masterPlanSettingsPayoutRules.form.configurePaymentLogic': 'Ensuite, configurez la logique de paiement',
    'masterPlanSettingsPayoutRulesForm.form.descriptionLabel': 'Expliquez en termes simples les règles de paiement de ce plan. Les règles de paiement peuvent être difficiles à comprendre, mais il est souvent simple d\'expliquer comment cela fonctionne avec vos propres mots.',
    'masterPlanSettingsPayoutRules.form.firstMatch': 'Arrêt à la 1ère condition remplie',
    'masterPlanSettingsPayoutRules.form.allMatches': 'Tout évaluer',
    'masterPlanSettingsPayoutRules.form.noRulesText': 'Aucune règle de paiement n\'est définie.<br />Vous pouvez ajouter une règle de paiement en cliquant sur le bouton ci-dessous.',
    'masterPlanSettingsPayoutRules.form.ruleTitle': 'Règle de paiement',
    'masterPlanSettingsPayoutRules.form.conditionTitle': 'Condition de paiement (formule)',
    'masterPlanSettingsPayoutRules.form.conditionSubtitle': 'Ex: true ou Deal.productType === \'My_product_type\'',
    'masterPlanSettingsPayoutRules.form.conditionPlaceholder': 'Condition de paiement',
    'masterPlanSettingsPayoutRules.form.conditionSubtitle.error.required': 'Merci de renseigner une condition de paiement',
    'masterPlanSettingsPayoutRules.form.ruleRemoveTooltip': 'Supprimer cette règle',
    'masterPlanSettingsPayoutRules.form.addAPaymentRule': 'Ajouter une règle de paiement',
    'masterPlanSettingsPayoutRulesForm.form.switchToCode': 'Passer au code',
    'masterPlanSettingsPayoutRulesForm.form.ruleCode.title': 'Rubrique de codes',
    'masterPlanSettingsPayoutRulesForm.form.backToNormal': 'Revenez à la normale',
    'masterPlanSettingsPayoutRulesForm.form.codePlaceholder': 'Tapez votre code ici',
    'masterPlanSettingsPayoutRules.form.noPaymentsText': 'Aucune règle de paiement n\'est définie.<br />Vous pouvez ajouter une règle de paiement en cliquant sur le bouton ci-dessous.',
    'masterPlanSettingsPayoutRules.template1.desc': 'Le paiement de la commission sera traité à la fin de la période.',
    'masterPlanSettingsPayoutRules.template2.desc': 'Le paiement de la commission sera traité un mois après la fin de la période.',
    'masterPlanSettingsPayoutRules.template3.desc': 'Le paiement de la commission sera traité un mois après la clôture de {{trackingObjectType}}.',
    'masterPlanSettingsPayoutRules.form.ruleCode.disclaimer': 'Vous devez retourner un tableau de { date, percentage }.',
    'masterPlanSettingsPayoutRules.form.ruleCode.error.required': 'Veuillez saisir le code',
    'masterPlanSettingsPayoutRules.form.validator.reject': 'Il y a une erreur dans le formulaire. Veuillez vérifier le message d\'erreur sous l\'un des champs de saisie.',
    'masterPlanSettingsDependencies.description': 'Vous pouvez ajouter d\'autres plans en tant que dépendances pour accéder à leurs données dans les règles de ce plan. Par exemple, vous pouvez utiliser les réalisations précédentes pour définir des accélérateurs dans le plan actuel.',
    'masterPlanSettingsDependencies.addDependencyBtnLabel': 'Ajouter une dépendance',
    'masterPlanSettings.DocumentationGeneralLinkLabel': 'Documentation pour la configuration générale',
    'masterPlanSettings.DocumentationPlanRulesLinkLabel': 'Documentation pour les règles de commission',
    'masterPlanSettings.DocumentationPayoutRulesLinkLabel': 'Documentation pour les règles de paiement',
    'masterPlanSettings.Users.deleteMasterPlanUserModal.title': 'Supprimer {{userName}} de ce plan?',
    'masterPlanSettings.Users.deleteMasterPlanUserModal.confirm': 'Supprimer l\'utilisateur',
    'masterPlanSettings.Users.deleteMasterPlanUserModal.content': 'Les commissions calculées pour cet utilisateur seront éffacées. Cela est irréversible.',
    'masterPlanSettings.Users.removeTooltip': 'Supprimer l\'utilisateur du plan',
    'masterPlanSettingsGeneral.form.name.label': 'Nom',
    'masterPlanSettingsGeneral.form.name.placeholder': 'Ex: Plan pour AEs',
    'masterPlanSettingsGeneral.form.name.rules.required': 'Merci de nommer votre Plan.',
    'masterPlanSettingsGeneral.form.currency.label': 'Devise',
    'masterPlanSettingsGeneral.form.timezone.label': 'Sélectionner une Timezone',
    'masterPlanSettingsGeneral.form.currency.addNew.label': 'Ajouter une devise',
    'masterPlanSettingsGeneral.form.live.label': 'Visible pour les utilisateurs',
    'masterPlanSettingsGeneral.form.live.description': 'Lorsque cette fonctionnalité est activée, les commissions calculées par ce plan seront affichées sur le relevé de vos utilisateurs.',
    'masterPlanSettingsGeneral.form.scope.label': 'Sélectionner un type de Plan',
    'masterPlanSettingsGeneral.form.frequency.label': 'Quelle est la fréquence de ce Plan ?',
    'masterPlanSettingsGeneral.form.beginPeriod.label': 'Période de démarrage',
    'masterPlanSettingsGeneral.form.setEndPeriod': 'Définir une période de fin',
    'masterPlanSettingsGeneral.form.endPeriod.label': 'Période de fin',
    'masterPlanSettingsGeneral.form.endPeriod.rules.required': 'Merci de sélectionner une Période de fin.',
    'masterPlanSettingsGeneral.form.endPeriod.rules.isAfter': 'La Période de fin doit être après la Période de démarrage',
    'masterPlanSettingsGeneral.form.customBeginAt.label': 'Date de Début',
    'masterPlanSettingsGeneral.form.customBeginAt.rules.isBefore': 'La date de Début doit être avant la date de Fin',
    'masterPlanSettingsGeneral.form.customEndAt.label': 'Date de Fin',
    'masterPlanSettingsGeneral.form.customEndAt.rules.isAfter': 'La date de Fin doit être après la date de Début',
    'masterPlanSettingsGeneral.form.frequencyForDay.label': 'Durée d\'une période en jours',
    'masterPlanSettingsGeneral.form.frequencyForDay.description': 'Renseignez 7 pour un plan hebdomadaire',
    'masterPlanSettingsGeneral.form.frequencyForDay.rules.required': 'Merci de renseigner la durée en jours',
    'masterPlanSettingsGeneral.form.freezeType.label': 'Sélectionner un type de gel',
    'masterPlanSettingsGeneral.form.freezeType.tooltip': 'Vous pouvez soit geler complètement le plan, soit geler uniquement le calcul de la commission mais pas les calculs des règles de paiement. C\'est utile si vos règles de paiement sont configurées pour débloquer les paiements au fil du temps (si vous attendez que les factures soient payées par exemple). Tous les calculs seraient gelés',
    'masterPlanSettingsGeneral.form.submitBtnLabel': 'Mettre à jour le Plan',
    'masterPlanSettingsGeneral.form.fiscalYearShift.label': 'Décalage des périodes (Année Fiscale)',
    'masterPlanSettingsGeneral.form.fiscalYearShift.description1': 'Décaler le début des périodes de',
    'masterPlanSettingsGeneral.form.fiscalYearShift.description2': 'mois',
    'masterPlanSettingsGeneral.form.fiscalYearShift.hint': 'Cela signifie que {{beginPeriodName}} commencera en {{beginPeriodMonth}}.',
    'masterPlanSettingsGeneral.freezeSettings.title': 'Configuration du gèle des périodes',
    'masterPlanSettingsGeneral.freezeSettings.subtitle.autofreeze': 'Gèle automatique',
    'masterPlanSettingsGeneral.freezeSettings.subtitle.type': 'Type de gèle',
    'masterPlanSettingsGeneral.freezeSettings.autoFreeze.label1': 'Geler automatiquement les périodes',
    'masterPlanSettingsGeneral.freezeSettings.autoFreeze.label2': 'jours après leur fin',
    'masterPlanSettingsGeneral.freezeSettings.description': 'Le gel d\'un plan arrête les calculs. La liste des utilisateurs ne changera pas. De cette façon, vous pourrez auditer les commissions passées sans risquer que les données soient mises à jour en arrière-plan.',
    'masterPlanSettingsGeneral.deleteAction.title': 'Voulez-vous supprimer ce plan ?',
    'masterPlanSettingsGeneral.deleteAction.description': 'La suppression d\'un plan le retire définitivement de la plateforme, ainsi que toutes les commissions et tous les paiements qui y sont associés. Veuillez noter que cette action ne peut être annulée.',
    'masterPlanSettingsGeneral.deleteAction.btnLabel': 'Supprimer',
    'masterPlanSettingsGeneral.archiveAction.title': 'Voulez-vous archiver ce plan ?',
    'masterPlanSettingsGeneral.archiveAction.description': 'Archiver un plan le déplace vers Plans > Archivés. Tous les calculs et paiements restent accessibles. Cette action est irréversible et n\'est disponible que pour les plans inactifs.',
    'masterPlanSettingsGeneral.archiveAction.btnLabel': 'Archiver',
    'masterPlanSettingsGeneral.quotaWarning.message': 'Le Quota lié n\'est plus compatible',
    'masterPlanSettingsGeneral.quotaWarning.description': 'Le Quota lié actuellement à ce plan ne correspond plus aux nouveaux paramètres de configuration. Par conséquent, nous supprimerons automatiquement le lien du Quota actuel avec le plan lorsque vous mettrez à jour le plan.',
    'masterPlanSettingsGeneral.dependenciesWarning.message': 'Les dépendances injectées dans ce plan ne sont pas compatibles',
    'masterPlanSettingsGeneral.dependenciesWarning.description': 'Les dépendances injectées dans ce plan ne correspondent plus aux nouveaux paramètres de configuration. Par conséquent, nous supprimerons automatiquement toutes les dépendances de ce plan lorsque vous le mettrez à jour.',
    'masterPlanSettingsGeneral.historySettings.title': 'Historique',
    'masterPlanSettingsGeneral.historySettings.description.empty': 'Ce plan a été créé à partir de zéro et n\'a aucun lien logique avec un autre plan.',
    'masterPlanSettingsGeneral.historySettings.description': 'Ce plan est une copie du plan : "<strong>{{name}}</strong>"',
    'masterPlanSettingsGeneral.historySettings.defineLink': 'Définir un lien avec un autre plan',
    'masterPlanSettingsGeneral.historySettings.removeLink': 'Supprimer le lien',
    'masterPlanSettingsGeneral.historySettings.viewPlan': 'Voir le plan',
    'masterPlanSettingsGeneral.historySettings.form.parentPlan.label': 'Sélectionner un Plan à lier',
    'masterPlanSettingsGeneral.historySettings.form.parentPlan.placeholder': 'Choisir un Plan à lier',
    'masterPlanSettingsGeneral.payoutsPercentageSettings.title': 'Gestion du pourcentage des paiements',
    'masterPlanSettingsGeneral.form.allowWrongPayoutsPercentage.label': 'Autoriser un pourcentage de paiement incohérent (pourcentage total > 100%)',
    'masterPlanSettingsQuota.disclaimer.noQuota.title': 'Aucun quota n\'est lié à ce plan',
    'masterPlanSettingsQuota.disclaimer.noQuota.description': 'Voulez-vous en définir un pour ce plan ?',
    'masterPlanSettingsQuota.disclaimer.noQuota.defineQuotaCTA': 'Définir un Quota',
    'masterPlanSettingsQuotaAttainment.title': 'Atteinte du Quota',
    'masterPlanSettingsQuotaAttainment.subtitle': 'Définition de la valeur de chaque {{dealType}}',
    'masterPlanSettingsQuotaAttainment.dealVariables.title': 'Enrichissez les {{dealType}} avec de nouvelles variables',
    'masterPlanSettingsQuotaAttainment.dealVariables.subtitle': 'Dans cette section, vous pouvez définir de nouvelles variables qui seront ajoutées à chaque {{dealType}} durant le rafraîchissement des données.',
    'masterPlanSettingsQuotaAttainment.form.valueFormula.label': 'Comment calculez-vous la valeur d\'un·e {{dealType}} ?',
    'masterPlanSettingsQuotaAttainment.form.submitBtnLabel': 'Mettre à jour la définition de \'Atteinte du Quota',
    'masterPlanSettingsQuotaAttainment.form.valueFormula.rules.required': 'Merci de renseigner une formule de calcul',
    'masterPlanSettingsQuotaAttainment.needData.disclaimer.label': 'Besoin de données d\'un autre plan ?',
    'masterPlanSettingsQuotaAttainment.needData.modal.title': 'Ajouter des dépendances de plan',
    'masterPlanSettingsQuotaAttainment.needData.modal.content': 'Vous pourrez ajouter des dépendances dans les paramètres du plan une fois le plan créé. De cette façon, vous aurez accès aux données des autres plans.',
    'masterPlanSettingsQuotaSetupPanel.alert.noQuotas.message': 'Aucun Quota disponible pour ce Plan',
    'masterPlanSettingsQuotaSetupPanel.alert.noQuotas.description': 'Cliquez sur le bouton "$t(quota.createNewONe)" ou allez sur la page Quotas pour créer un nouveau Quota.',
    'masterPlanSettingsQuotaSetupPanel.quotaSelector.placeholder': 'Sélectionnez un Quota',
    'masterPlanSettingsQuotaSetupPanel.missingUsersInQuota.alert.message': '$t(usersWithCountToBeMissing, {"count": {{count}} }) dans la Quota.',
    'masterPlanSettingsQuotaSetupPanel.doYouConfirmQuestion': 'Est-ce que vous confirmez choisir ce Quota et ajouter les utilisants manquants dans le Quota ?',
    'masterPlanSettingsQuotaSetupPanel.confirmBtnLabel': 'Je confirme',
    'masterPlanSettingsQuotaSetupPanel.linkBtnLabel': 'Lier le Quota sélectionné',
    'masterPlanSettingsQuotaSetupPanel.createForTeamBtnLabel': 'Définir ce Quota pour l\'équipe',
    'masterPlanSettingsQuotaSetupPanel.viewQuotaLinkLabel': 'Voir le Quota',
    'masterPlanSettingsQuotaSetupPanel.customTeamQuota.target.label': 'Objectif de l\'équipe durant cette période de temps',
    'masterPlanSettingsUsers.table.headers.manager': 'Manager',
    'masterPlanSettingsUsers.table.headers.addManager': 'Ajouter un Manager',
    'masterPlanSettingsUsers.table.headers.user': 'Utilisateur',
    'masterPlanSettingsUsers.table.headers.addUser': 'Ajouter un Utilisateur',
    'masterPlanSettingsUsers.table.headers.startDate': 'A rejoint le plan le',
    'masterPlanSettingsUsers.table.headers.endDate': 'A quitté le plan le',
    'masterPlanSettingsUsers.filter.active': 'Utilisateurs actifs',
    'masterPlanSettingsUsers.filter.all': 'Tous les utilisateurs',
    'masterPlanSettingsUsers.filterDescription': 'Les utilisateurs actifs sont ceux qui sont actifs dans la période en cours.',
    'masterPlanSettingsUsers.connectorsDescription': 'Sélectionnez la source de données contenant les utilisateurs',
    'masterPlanSettingsPage.title': 'Configuration',
    'masterPlanSettingsProjection.alert.message': 'Aucune projection définie',
    'masterPlanSettingsProjection.alert.description': 'Voulez-vous définir une projection pour ce Plan ?',
    'masterPlanSettingsProjection.alert.defineProjectionCTA': 'Définir une projection',
    'masterPlanProjectionDefinitionForm.form.explanation.label': 'Description de la projection',
    'masterPlanProjectionDefinitionForm.form.explanation.placeholder': 'Description pour la projection (ex: Projection avec le type de Deal XXX)',
    'masterPlanProjectionDefinitionForm.form.referenceDealId.label': '{{dealType}} à utiliser comme référence pour la projection',
    'masterPlanProjectionDefinitionForm.form.display.label': 'Afficher le graphique de projection pour les Reps',
    'masterPlanProjectionDefinitionForm.form.defineSubmitBtnLabel': 'Définir la projection',
    'masterPlanProjectionDefinitionForm.form.updateSubmitBtnLabel': 'Mettre à jour la projection',
    'masterPlanProjectionDefinitionForm.deleteAction.description': 'Voulez-vous supprimer cette projection ?',
    'masterPlanProjectionDefinitionForm.deleteAction.btnLabel': 'Supprimer la projection',
    'masterPlanProjectionDefinitionForm.previewProjection.title': 'Projection',
    'masterPlanProjectionDefinitionForm.previewProjection.selectorLabel': 'Afficher le graphique pour cet utilisateur spécifique (utilise sa propre cible pour construire le graphique):',
    'masterPlanSettingsTabs.tabs.general': 'Général',
    'masterPlanSettingsTabs.tabs.users': 'Utilisateurs',
    'masterPlanSettingsTabs.tabs.quota': 'Quota',
    'masterPlanSettingsTabs.tabs.commissionRules': 'Règles de commission',
    'masterPlanSettingsTabs.tabs.payoutRules': 'Règles de paiements',
    'masterPlanSettingsTabs.tabs.dataSource': 'Source de données & Filtrage',
    'masterPlanSettingsTabs.tabs.projection': 'Projection de simulation',
    'masterPlanSettingsTabs.tabs.dependencies': 'Dépendances',
    'masterPlanTrackingObjectDefinitionForm.title': 'Ressource à suivre',
    'masterPlanTrackingObjectDefinitionForm.description': 'Définissez la ressource que vous souhaitez suivre dans ce plan.',
    'masterPlanTrackingObjectDefinitionForm.form.connectorIdType.label': 'Ressource Suivie',
    'masterPlanTrackingObjectDefinitionForm.form.connectorIdType.rename': 'Renommer',
    'masterPlanTrackingObjectDefinitionForm.form.uiType.label': 'Changer {{dealType}} par le nom suivant (utilisé seulement pour l\'affichage)',
    'masterPlanTrackingObjectDefinitionForm.form.uiType.placeholder': 'ex: Deal, Meeting, Opportunity',
    'masterPlanTrackingObjectDefinitionForm.form.uiType.resetName': 'Réinitialiser au nom original',
    'masterPlanTrackingObjectDefinitionForm.form.matchingField.label': 'Champ à faire correspondre avec le propriétaire de la Ressource',
    'masterPlanTrackingObjectDefinitionForm.form.dateField.label': 'Date (champ de date de référence à utiliser)',
    'masterPlanTrackingObjectDefinitionForm.form.filterObject.label': 'Filtre de Ressources',
    'masterPlanTrackingObjectDefinitionForm.form.filterObject.empty': 'Aucun filtre de ressources',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.useCustomCode': 'Utiliser du code custom',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.description': 'Utilisez-le uniquement lorsque vous ne pouvez pas vous exprimer avec le filtre ci-dessus.',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.hide': 'Cacher',
    'masterPlanTrackingObjectDefinitionForm.form.filterFormula.label': 'Code du Filtre (Optionnel)',
    'masterPlanTrackingObjectDefinitionForm.form.submitBtnLabel': 'Mettre à jour la configuration de la Ressource à suivre',
    'masterPlanTrackingObjectDefinitionForm.form.matchingField.rules.required': 'Merci de renseigner un champs à faire correspondre',
    'masterPlanTrackingObjectDefinitionForm.form.dateField.rules.required': 'Merci de renseigner le champs de date',
    'masterPlanTrackingObjectDefinitionForm.form.connectorIdType.rules.required': 'Merci de sélectionner une ressource à suivre',
    'masterPlanUsersDefinitionForm.title': 'Propriétaire de la Ressource',
    'masterPlanUsersDefinitionForm.description': 'Définir le propriétaire de la ressource aide Palette à comprendre qui devrait être récompensé pour un·e {{dealType}} donné·e',
    'masterPlanUsersDefinitionForm.form.connectorIdType.label': 'Type de la Ressource',
    'masterPlanUsersDefinitionForm.form.connectorIdType.rename': 'Renommer',
    'masterPlanUsersDefinitionForm.form.uiType.label': 'Changer {{userType}} par le nom suivant (utilisé seulement pour l\'affichage)',
    'masterPlanUsersDefinitionForm.form.uiType.placeholder': 'ex: Deal, Meeting, Opportunity',
    'masterPlanUsersDefinitionForm.form.uiType.resetName': 'Réinitialiser au nom original',
    'masterPlanUsersDefinitionForm.form.matchingField.label': 'Champ à faire correspondre avec la Ressource à suivre',
    'masterPlanUsersDefinitionForm.form.submitBtnLabel': 'Mettre à jour la configuration du Propriétaire de la Ressource',
    'masterPlanUsersDefinitionForm.form.matchingField.rules.required': 'Merci de renseigner un champs à faire correspondre',
    'masterPlanUsersDefinitionForm.form.connectorIdType.rules.required': 'Merci de sélectionner un type de ressource',
    'menuNotificationsPopoverContent.title': 'Notifications',
    'menuNotificationsPopoverContent.pending': 'En attente · {{count}}',
    'menuNotificationsPopoverContent.resolved': 'Résolus · {{count}}',
    'mergeUserResults.sections.userDetailsDiff': 'Différences dans les informations utilisateurs',
    'mergeUserResults.sections.plansInCommon': 'Plans en commun',
    'mergeUserResults.sections.quotasInCommon': 'Quotas en commun',
    'mergeUserResults.userDetailsDiff.table.headers.user': 'Utilisateur {{userName}}',
    'modal.cancel': 'Annuler',
    'modal.ok': 'OK',
    'modal.confirm': 'Confirmer',
    'moveTeamModal.title': 'Déplacer l\'équipe',
    'moveTeamModal.move': 'Déplacer',
    'moveTeamModal.description': 'Sélectionnez l\'équipe sous laquelle vous voulez déplacer <strong>{{teamName}}</strong> :',
    'moveTeamModal.moveToRootLabel': 'Déplacer l\'équipe à la racine',
    'myCommissionList.page.title': 'Ma liste de commissions',
    'myCommissionList.page.subtitle': 'Définissez la commission que vous recherchez',
    'myDashboardPage.title': 'Bonjour {{firstName}}',
    'myDashboardPage.customize.title': 'Personnaliser votre Tableau de bord',
    'myDashboardPage.customize.description1': 'Vous pouvez personnaliser votre expérience en ajoutant des Widgets à votre tableau de bord.',
    'myDashboardPage.customize.description2': 'Vous pouvez aussi organiser les widgets grâce aux boutons < et > .',
    'myDashboardPage.customize.cta': 'Ajouter des widgets maintenant',
    'myDashboardPage.customize.addWidget': 'Ajouter un widget',
    'myMasterPlansPage.title': 'Mes Plans',
    'myMasterPlan.lastPeriod.totalCommission': 'Commission ·',
    'myMasterPlanBreadcrumb.plans': 'Mes Plans',
    'myMasterPlanPeriodDeals.actions.calculationDetails': 'Détails des calculs',
    'myMasterPlanPeriodDeals.actions.somethingMissing': 'Vous pensez qu\'il manque quelque chose ?',
    'myMasterPlanSomethingMissingModal.title': 'Vous pensez qu\'il manque quelque chose ?',
    'myMasterPlanSomethingMissingModal.intro1': 'Si vous pensez qu\'il manque un(e) {{type}}, voici une action que vous pouvez entreprendre :',
    'myMasterPlanSomethingMissingModal.intro2': 'Si vous pensez qu\'il manque un(e) {{type}}, voici 2 actions que vous pouvez entreprendre :',
    'myMasterPlanSomethingMissingModal.verifyTitle': 'Vérifiez les données dans {{connectorType}}',
    'myMasterPlanSomethingMissingModal.verifyText': 'Vérifiez que les données sont correctes et qu\'il n\'y a pas de champs manquants.',
    'myMasterPlanSomethingMissingModal.triggerTitle': 'Déclencher une synchronisation manuelle',
    'myMasterPlanSomethingMissingModal.triggerText': 'Si les données vous semblent correctes, c\'est peut-être juste une question de synchronisation. Pour déclencher une synchronisation, vous pouvez cliquer sur ce bouton :',
    'myMasterPlanCalculationModal.title': 'Détails des calculs',
    'myMasterPlanCalculationModal.commissions.title': 'Règles de commission',
    'myMasterPlanCalculationModal.payout.title': 'Règles de paiements',
    'myStatementsPage.title': 'Mes fiches de paie',
    'myStatementsPageContent.viewMoreInPalette': 'Voir plus dans Palette',
    'myStatementsPageContent.unauthorized.title': 'Rien ici encore',
    'myStatementsPageContent.unauthorized.description': 'Contactez l\'administrateur de votre entreprise si vous pensez qu\'il s\'agisse d\'une erreur.<br />Veuillez vous assurer que votre entreprise ait un compte sur <a target="_blank" rel="noopener noreferrer" href="https://palettehq.com/?ref=salesforce">Palette</a>.',
    'newNotebookButton.label': 'Ajouter un nouveau Modèle',
    'newNotebookModal.title': 'Nouveau Modèle',
    'newNotebookModal.create': 'Créer le Modèle',
    'newNotebookModal.form.name.label': 'Nom du Modèle',
    'newNotebookModal.form.name.rules.required': 'Merci de donner un nom à votre Modèle.',
    'newNotebookModal.form.description.label': 'Description',
    'newTeamButton.label.newSubteam': 'Nouvelle Sous équipe',
    'newTeamButton.label.newTeam': 'Nouvelle Equipe',
    'newTeamModal.title.newSubteam': 'Créer une nouvelle sous équipe',
    'newTeamModal.title.newTeam': 'Créer une nouvelle équipe',
    'newTeamModal.create': 'Créer',
    'newTeamModal.form.name.label': 'Nom de l\'équipe',
    'newTeamModal.form.name.rules.required': 'Merci de nommer votre Equipe.',
    'newDashboardPresetButton.label': 'Créer un nouveau tableau de bord',
    'noConnectorsAlert.warning.message': 'Aucun connecteur n\'a été trouvé',
    'noConnectorsAlert.warning.description': 'Vous devriez vérifier la configuration de vos connecteurs. Il semblerait qu\'aucun connecteur ne soit défini sur votre compte.',
    'notebookCell.actions.makeExportable.popconfirm': 'Confirmez-vous rendre disponible les résultats de cette cellule comme ressources ?',
    'notebookCell.actions.makeExportable.label': 'Créer la ressource',
    'notebookCell.actions.run': 'Exécuter la requête',
    'notebookCell.errors.type.notebook.empty.output.error': 'Le code doit générer des données à la fin.',
    'notebookCell.errors.type.notebook.python.error': 'Une erreur Python s\'est produite',
    'notebookCell.errors.type.python.invalid.code': 'Le code python n\'est pas valide',
    'notebookCell.errors.type.notebook.run.error': 'Certaines cellules de code n\'ont pas bien fonctionné.',
    'notebookCell.errors.type.notebook.required.information': 'Une information est nécessaire.',
    'notebookCell.errors.type.notebook.codeCell.required.information': 'Champ requis',
    'notebookCell.errors.type.notebook.codeCell.required.information.content': 'La cellule de code n°{{position}} requiert l\'information <strong>{{field}}</strong>.',
    'notebookCell.errors.type.notebook.codeCell.conflicting.outputName': 'Le nom de la donnée en sortie existe déjà',
    'notebookCell.errors.type.notebook.codeCell.conflicting.outputName.content': 'Le nom de sortie <strong>{{cellTitle}}</strong> doit être unique.',
    'notebookCell.errors.type.notebook.already.existing.name': 'L\'entreprise dispose déjà d\'un notebook portant ce nom.',
    'notebookCell.errors.type.notebook.not.existing': 'Le notebook n\'existe pas au sein de l\'entreprise.',
    'notebookCell.errors.type.notebook.forbidden.codeCell.export': 'Une cellule n\'est pas encore exportée.',
    'notebookCell.errors.type.notebook.invalid.codeCell.execution.status': 'Le statut d\'exécution de la cellule de code n\'est pas valide.',
    'notebookCell.errors.type.notebook.unexportable.language': 'Cette langue n\'est pas exportable en tant que ressource.',
    'notebookCell.errors.type.notebook.forbidden.update': 'Le statut du notebook ne permet pas de le mettre à jour.',
    'notebookCell.errors.type.notebook.connector.not.found': 'Un connecteur n\'existe pas dans votre compagnie.',
    'notebookCell.errors.type.notebook.codeCell.not.exists': 'Il n\'y a pas de cellule de code avec cet identifiant de requête.',
    'notebookCell.errors.type.notebook.status.language': 'Le statut de la cellule de code est invalide.',
    'notebookCell.errors.type.notebook.codeCell.outputName.locked': 'Le nom de la donnée en sortie est verrouillé.',
    'notebookCell.errors.type.notebook.invalid.type': 'Ce type de notebook n\'est pas valide.',
    'notebookCell.errors.type.notebook.invalid.status': 'Le statut de ce notebook n\'est pas valide.',
    'notebookCell.errors.type.notebook.invalid.group': 'Il y a un filtre avec un groupe invalide.',
    'notebookCell.errors.type.notebook.invalid.output.language': 'Ce langage n\'est pas un langage valide pour générer des résultats.',
    'notebookCell.errors.type.notebook.invalid.language': 'Ce langage n\'est pas un langage de cellule de code valide.',
    'notebookCell.errors.type.notebook.invalid.column.type': 'Ce type est un type de colonne de connecteur non valide.',
    'notebookCell.errors.type.notebook.invalid.output.unique.identifier.error': 'UID invalide',
    'notebookCell.errors.type.notebook.invalid.output.unique.identifier.error.content': '<strong>{{cellName}}</strong> doit utiliser une valeur de sortie comme UID.',
    'notebookCell.errors.type.notebook.output.unique.identifier.locked': 'L\'UID ne peut pas être modifié',
    'notebookCell.errors.type.notebook.output.unique.identifier.locked.content': 'L\'UID de sortie de la cellule de code <strong>{{cellName}}</strong> ne peut pas être modifié lorsque celle-ci est utilisée comme ressource.',
    'notebookCell.exportedOverlay.description': 'Cette cellule est utilisée comme une ressource. Êtes-vous sûr de vouloir l\'éditer ?',
    'notebookCell.exportedOverlay.edit.data': 'Éditer données',
    'notebookCell.isExported': 'Resource créée',
    'notebookCell.isNotExported': 'Ressource non créée',
    'notebookCell.title': 'Titre',
    'notebookCell.uniqueIdentifier.label': 'UID',
    'notebookCell.uniqueIdentifier.required': 'Champ requis',
    'notebookCell.uniqueIdentifier.info': 'Champ utilisé comme UID (identifiant unique) pour reconnaître chaque donnée contenue dans la sortie de la cellule de code. Il ne peut pas être modifié une fois la ressource créée!',
    'notebookCellMoreOptionsMenu.unMakeExportableCell': 'Ne plus exporter',
    'notebookCellMoreOptionsMenu.deleteNotebookCell': 'Supprimer',
    'notebookDetails.documentation': 'Documentation',
    'notebookDetails.save': 'Sauvegarder',
    'notebookDetails.isSaving': 'Enregistrement...',
    'notebookDetails.saved': 'Sauvegardé',
    'notebookDetailsLeftSideContent.dataConnections': 'Connexions aux données',
    'notebookDetailsLeftSideContent.tabs.dataConnections': 'Données',
    'notebookDetailsLeftSideContent.tabs.exportedCells': 'Ressources',
    'notebookDetailsLeftSideContent.tabs.runnableCells': 'Cellules',
    'notebookDataConnectionConnectorsListItem.copy.dataframe': 'Copier le nom du dataframe "{{dataframeName}}"',
    'notebooks.addDataConnectionBlockListItem.disabled': 'Vous ne pouvez pas le modifier',
    'notebooks.addDataConnectionBlockListItem.types.array': 'Liste',
    'notebooks.addDataConnectionBlockListItem.types.object': 'Objet',
    'notebooks.addDataConnectionBlockListItem.types.column': 'Colonne',
    'notebooks.addDataConnectionConnector.disabled': 'Toutes les ressources sont utilisées',
    'notebooks.cellTypes.sql': 'SQL',
    'notebooks.cellTypes.text': 'Texte',
    'notebooks.cellTypes.markdown': 'Markdown',
    'notebooks.cellTypes.table': 'Tableau',
    'notebooks.cellTypes.python': 'Python',
    'notebooks.cellTypes.js': 'JavaScript',
    'notebooksBreadcrumb.notebooks': 'Modèles',
    'notebooksListTable.emptyDescription': 'Aucun modèle ne correspond à votre recherche',
    'notebooksListTable.header.name': 'Nom',
    'notebooksListTable.header.owner': 'Créateur',
    'notebooksListTable.header.created': 'Créé le',
    'notebooksListTable.header.edited': 'Edité le',
    'notebooksListTable.header.usedInPlans': 'Utilisé dans les plans',
    'notebooksListTable.header.actions': 'Actions',
    'notebooksPage.title': 'Modèles',
    'notebooksPage.subtitle': 'Transformer vos propres données synchronisées dans Palette afin de simplifier les opérations complexes (comme les réconciliations)',
    'notebooksPageContent.tabs.all': 'Tous',
    'notebooksPageContent.tabs.yours': 'Les vôtres',
    'notebooksPageContent.tabs.templates': 'Templates',
    'notebooksPageContent.tabs.archived': 'Archivés',
    'notebooksProjectsListItemMoreOptionsMenu.copyNotebook': 'Dupliquer',
    'notebooksProjectsListItemMoreOptionsMenu.archiveNotebook': 'Archiver',
    'notebooksProjectsListItemMoreOptionsMenu.unarchiveNotebook': 'Désarchiver',
    'notebooksProjectsListItemMoreOptionsMenu.deleteNotebook': 'Supprimer',
    'notifications.masterPlans.success.update': 'La configuration du Plan a bien été mise à jour',
    'notifications.masterPlans.success.userDates': 'La date de cet Utilisateur a bien été mise à jour',
    'notifications.masterPlans.success.archive': 'Le plan a bien été archivé',
    'notifications.masterPlans.success.delete': 'Le plan a bien été supprimé',
    'notifications.plan.V3.success.create': 'Le plan a bien été créé',
    'notifications.plan.V3.success.requirements.update': 'Les besoins du plan ont bien été mis à jour',
    'notifications.plan.V3.success.plan.saved': 'Le plan ont bien été mis à jour',
    'notifications.plan.V3.success.run.code.cell': 'La cellule de code a fonctionné correctement',
    'notifications.plan.V3.error.no.plan.user': 'Veuillez sélectionner au moins un utilisateur pour ce plan',
    'notifications.plan.V3.error.no.datasource': 'Veuillez ajouter les sources de données à utiliser dans ce plan',
    'notifications.plan.V3.error.invalid.endDate.message': 'Date de fin non valide',
    'notifications.plan.V3.error.invalid.endDate.content': 'La date de fin <strong>{{endDate}}</strong> doit être postérieure à la date de début <strong>{{startDate}}</strong>.',
    'notifications.plan.V3.error.missing.information.message': 'Erreur d\'information requise',
    'notifications.plan.V3.error.missing.information.content': 'Information manquante : <strong>{{fieldPath}}</strong>.',
    'notifications.dashboard.success.addWidget': 'Widget ajouté',
    'notifications.dashboard.success.updateWidget': 'Widget mis à jour',
    'notifications.dashboard.success.applyDashboardPresetToUsers': 'Tableau de bord assigné.',
    'notifications.statements.success.deleteAdjustment': 'La correction a bien été supprimée',
    'notifications.statements.success.updateAdjustment': 'La correction a bien été mise à jour',
    'notifications.statements.success.applyCorrection': 'La correction a bien été appliquée',
    'notifications.statements.success.addAdjustment': 'La correction a bien été ajoutée',
    'notifications.statements.success.approve.statements.title': 'Validation de fiche de paie',
    'notifications.statements.success.approveStatements_one': 'La fiche de paie a bien été validée.',
    'notifications.statements.success.approveStatements_other': 'Les fiches de paie ont bien été validées.',
    'notifications.statements.success.markAsPaid.statements.title': 'Fiche de paie marquée comme payée',
    'notifications.statements.success.markAsPaidStatements_one': 'La fiche de paie a bien été marquée comme payée.',
    'notifications.statements.success.markAsPaidStatements_other': 'Les fiches de paie ont bien été marquées comme payées.',
    'notifications.statements.success.ignoreCorrection': 'La correction a bien été annulée',
    'notifications.statements.success.cancelStatementApproval': 'La validation de la fiche de paie a bien été supprimée',
    'notifications.statementValidations.success.activated': 'Validations des déclarations activées',
    'notifications.statementValidations.success.deactivated': 'Validations des déclarations desactivées',
    'notifications.statementFxRateMode.success': 'La configuration de la déclaration a été mise à jour avec succès',
    'notifications.profile.success.company.update': 'La société a bien été mise à jour',
    'notifications.profile.success.profile.update': 'Le profil a bien été mis à jour',
    'notifications.profile.success.updateStatementStrategy': 'La nouvelle stratégie de déclaration a bien été enregistrée',
    'notifications.profile.success.sendFeedback': 'Avis bien reçu! Merci. Nous vous recontacterons dès que possible.',
    'notifications.teams.success.create': 'L\'équipe a bien été créée',
    'notifications.teams.success.delete': 'L\'équipe a bien été supprimée',
    'notifications.teams.success.updateName': 'Le nom de l\'équipe a bien été mis à jour',
    'notifications.teams.success.moveTeam': 'L\'équipe a bien été déplacée',
    'notifications.users.success.invite': 'L\'utilisateur a bien été invité',
    'notifications.users.success.onboardUsers': 'Les nouveaux utilisateurs ont bien été enregistrés',
    'notifications.users.success.updateUser': 'L\'utilisateur a bien été modifié',
    'notifications.users.success.deactivateUser': 'L\'utilisateur a bien été désactivé',
    'notifications.users.success.unlinkResourceFromUser': 'Le lien entre la ressource et l\'utilisateur a bien été supprimé',
    'notifications.users.success.linkResourcesToUser': 'Le lien entre les ressources et l\'utilisateur a bien été effectué',
    'notifications.users.success.offboardUser': 'L\'utilisateur a bien été retiré',
    'notifications.usersVariables.success.create': 'La variable a bien été créée',
    'notifications.usersVariables.success.edit': 'La nouvelle valeur de la variable a bien été ajoutée',
    'notifications.usersVariables.success.deleteOverride': 'La valeur de la variable a bien été supprimée',
    'notifications.notebooks.success.archive': 'Le modèle a bien été archivé',
    'notifications.notebooks.success.unarchive': 'Le modèle a bien été désarchivé',
    'notifications.notebooks.success.delete': 'Le modèle a bien été supprimé',
    'notifications.notebooks.success.addNewDataConnection': 'La nouvelle connexion aux données a bien été ajoutée',
    'notifications.notebooks.success.editDataConnection': 'La connexion aux données a bien été modifiée',
    'notifications.notebooks.success.copyConnectionConnectorDataframeNameToClipboard': 'Nom du dataframe copié',
    'notifications.notebooks.success.copyConnectionConnectorDataframeNameToClipboard.content': 'Le nom du dataframe <strong>{{name}}</strong> a bien été copié.',
    'notifications.notebooks.error.copyConnectionConnectorDataframeNameToClipboard': 'Une erreur est survenue durant la copie du nom du dataframe.',
    'notifications.planV3.error.copyName': 'Une erreur est survenue durant la copie du nom de la variable.',
    'notifications.planV3.success.copyName': 'Nom de la variable copié',
    'notifications.planV3.success.copyName.content': 'Le nom de la variable <strong>{{name}}</strong> a bien été copié.',
    'notifications.success.forgotPassword': 'Vous recevrez bientôt un email avec des instructions pour réinitialiser votre mot de passe.',
    'notifications.resources.resource_deleted.success.message': 'Suppression de ressource',
    'notifications.resources.resource_deleted.success.content': '<strong>{{type}}</strong> a bien été supprimé.',
    'notifications.resources.resource_data_copied.success.message': 'Les données de la ressource sont copiées avec succès sous forme de JSON.',
    'notifications.resources.resource_data_copied.error.message': 'Une erreur est survenue durant la copie des données de la ressource.',
    'noUserTypeConnectorsAlert.warning.noUsersFound.message': 'Aucune ressource de type Utilisateur n\'a été trouvée',
    'noUserTypeConnectorsAlert.warning.noUsersFound.description': 'Vous devriez vérifier la configuration de vos connecteurs.<br />Si vous utilisez un connecteur comme GSheet ou CSV, vous devez définir une Resource de type Utilisateur.',
    'offboardUserButton.label': 'Offboard {{userDisplayName}} maintenant',
    'offboardUserModal.title': 'Retirer l\'utilisateur',
    'offboardUserModal.confirm': 'Retirer',
    'offboardUserModal.form.leaveDate.label': 'Quand {{userDisplayName}} quittera {{companyName}}?',
    'offboardUserModal.form.leaveDate.rules.required': 'Merci de sélectionner une date de départ',
    'offboardUserModal.leaveDateDefinition.description.title': 'Que va-t-il se produire après cette date ?',
    'offboardUserModal.leaveDateDefinition.description.content': '{{userDisplayName}} sera supprimé des plans actuels et futurs. Aucune commission ne sera calculée pour l\'employé après cette date. {{userDisplayName}} perdra l\'accès à Palette. Le profil {{userDisplayName}} sera supprimé du tableau de bord et des rapports.',
    'offboardUserModal.addFinalAdjustment': 'Ajouter un ajustement final ?',
    'offboardUserModal.noFinalAdjustment': 'Non, finalement je n\'ai pas besoin d\'ajouter d\'ajustement final',
    'offboardUserModal.finalAdjustmentReason': 'Ajustement final',
    'offboardUserModal.sections.leaveDate': 'Date de Départ',
    'offboardUserModal.sections.finalAdjustment': 'Ajustement Final',
    'onboardUsersCheckDataFlowStep.hasMissingRequiredFields': 'Cet utilisateur a des champs requis manquants.',
    'onboardUsersCheckDataFlowStep.warning.isMissingRequiredFields': 'Des champs requis sont manquants pour certains utilisateurs. Merci de compléter les informations manquantes avant de continuer.',
    'onboardUsersCheckDataFlowStep.table.headers.firstName': 'Prénom',
    'onboardUsersCheckDataFlowStep.table.headers.lastName': 'Nom',
    'onboardUsersCheckDataFlowStep.table.headers.email': 'Adresse mail',
    'onboardUsersCheckDataFlowStep.table.headers.currency': 'Devise',
    'onboardUsersCheckDataFlowStep.viewLinkedResource': 'Voir la ressource liée',
    'onboardUserForm.form.firstName.label': 'Prénom',
    'onboardUserForm.form.firstName.rules.required': 'Merci de renseigner un prénom.',
    'onboardUserForm.form.lastName.label': 'Nom',
    'onboardUserForm.form.lastName.rules.required': 'Merci de renseigner un nom.',
    'onboardUserForm.form.email.label': 'Adresse mail',
    'onboardUserForm.form.email.rules.required': 'Merci de renseigner une adresse mail.',
    'onboardUserForm.form.email.rules.email': 'Merci de fournir une adresse mail valide.',
    'onboardUserForm.form.currency.label': 'Devise',
    'onboardUsersFromResourcesFlowPage.title': 'Enregistrer de nouveaux utilisateurs à partir de ressources existantes',
    'onboardUsersManualInputFlowPage.title': 'Enregistrer de nouveaux utilisateurs en renseignant les informations manuellement',
    'onboardUsersFlowStepActions.next': 'Étape suivante',
    'onboardUsersFlowStepActions.finish': 'Terminer l\'enregistrement',
    'onboardUsersFlowSteps.title': 'Etapes d\'enregistrement',
    'onboardUsersFlowSteps.availableSoon': 'Bientôt',
    'onboardUsersFlowSteps.manual_input.label': 'Compléter le formulaire',
    'onboardUsersFlowSteps.manual_input.subTitle': 'Renseigner manuellement les informations pour enregistrer un nouvel utilisateur',
    'onboardUsersFlowSteps.select_resources.label': 'Importer à partir de ressources existantes',
    'onboardUsersFlowSteps.select_resources.subTitle': 'Choisissez les ressources à transformer en nouveaux utilisateurs',
    'onboardUsersFlowSteps.add_to_teams.label': 'Ajouter aux équipes',
    'onboardUsersFlowSteps.add_to_teams.subTitle': 'Choisissez les équipes dans lesquelles ajouter les nouveaux utilisateurs',
    'onboardUsersFlowSteps.add_to_plans.label': 'Ajouter aux plans',
    'onboardUsersFlowSteps.add_to_plans.subTitle': 'Choisissez les plans dans lesquels ajouter les nouveaux utilisateurs',
    'onboardUsersFlowSteps.define_quotas.label': 'Définissez les Quotas',
    'onboardUsersFlowSteps.define_quotas.subTitle': 'Définissez les quotas pour les nouveaux utilisateurs',
    'onboardUsersFlowSteps.define_variables.label': 'Définissez les Variables',
    'onboardUsersFlowSteps.define_variables.subTitle': 'Définissez les variables pour les nouveaux utilisateurs',
    'onboardUsersFlowSteps.check_data.label': 'Valider la création',
    'onboardUsersFlowSteps.check_data.subTitle': 'Valider la création des nouveaux utilisateurs à partir de ce résumé',
    'onboardingUsersButton.label': 'Enregistrer de nouveaux utilisateurs',
    'onboardingUsersButton.moreMenu.fromResources': 'A partir de ressources',
    'onboardingUsersButton.moreMenu.manualInput': 'Remplir manuellement le formulaire',
    'ongoingBulkApproveStatus.status': '{{currentIndex}} sur {{nbToProcess}} validées.',
    'ongoingBulkApproveStatus.description': 'Veuillez rester sur cette page jusqu\'à ce que tous les éléments soient traités, car quitter la page arrêtera le processus en cours.',
    'ongoingBulkMarkAsPaidStatus.status': '{{currentIndex}} sur {{nbToProcess}} marquées comme payées.',
    'ongoingBulkMarkAsPaidStatus.description': 'Veuillez rester sur cette page jusqu\'à ce que tous les éléments soient traités, car quitter la page arrêtera le processus en cours.',
    'pagination.results_zero': '{{count}} résultat',
    'pagination.results_one': '{{count}} résultat',
    'pagination.results_other': '{{count}} résultats',
    'pagination.goToPage': 'Se rendre à la page',
    'pagination.perPage': '{{limit}} par page',
    palette_users_one: 'Utilisateur Palette',
    palette_users_other: 'Utilisateurs Palette',
    'passwordRules.rule.hasLowercaseLetters_zero': 'Au moins {{count}} minuscule',
    'passwordRules.rule.hasLowercaseLetters_one': 'Au moins {{count}} minuscule',
    'passwordRules.rule.hasLowercaseLetters_other': 'Au moins {{count}} minuscules',
    'passwordRules.rule.hasMinLength_zero': 'Minimum {{count}} caractère',
    'passwordRules.rule.hasMinLength_one': 'Minimum {{count}} caractère',
    'passwordRules.rule.hasMinLength_other': 'Minimum {{count}} caractères',
    'passwordRules.rule.hasNumbers_zero': 'Au moins {{count}} chiffre',
    'passwordRules.rule.hasNumbers_one': 'Au moins {{count}} chiffre',
    'passwordRules.rule.hasNumbers_other': 'Au moins {{count}} chiffres',
    'passwordRules.rule.hasUppercaseLetters_zero': 'Au moins {{count}} majuscule',
    'passwordRules.rule.hasUppercaseLetters_one': 'Au moins {{count}} majuscule',
    'passwordRules.rule.hasUppercaseLetters_other': 'Au moins {{count}} majuscules',
    'passwordRules.rule.hasSymbols_zero': 'Au moins {{count}} symbole',
    'passwordRules.rule.hasSymbols_one': 'Au moins {{count}} symbole',
    'passwordRules.rule.hasSymbols_other': 'Au moins {{count}} symboles',
    'passwordRules.title': 'Règles mot de passe:',
    payments_zero: 'Paiement',
    payments_one: 'Paiement',
    payments_other: 'Paiements',
    paymentsWithCount_zero: '{{count}} paiement',
    paymentsWithCount_one: '{{count}} paiement',
    paymentsWithCount_other: '{{count}} paiements',
    plans_one: 'Plan',
    plans_other: 'Plans',
    plansWithCount_zero: '{{count}} $t(plans_one)',
    plansWithCount_one: '{{count}} $t(plans_one)',
    plansWithCount_other: '{{count}} $t(plans_other)',
    'plan.invalid.commission.amount.error': 'Erreur dans les règles de commission d\'un plan',
    'plan.invalid.commission.amount.content': 'Une erreur s\'est produite dans les <0>règles de commission de {{name}}</0> et empêche de générer de nouvelles commissions.<br />Une des formules <strong>"{{rules}}"</strong> évaluée pour calculer le montant de la commission <strong>n\'est pas un nombre</strong>.',
    'plan.code.rule.evaluation.error': 'Erreur d\'évaluation de la règle du plan',
    'plan.code.rule.evaluation.content': 'Une erreur s\'est produite dans la règle de <0>{{name}}</0> appelée <strong>"{{rule}}"</strong> et empêche la création de nouvelles commissions.',
    'plan.injection.error': 'Erreur d\'injection de plan',
    'plan.injection.content': 'Le plan <0>{{name}}</0> <strong>ne peut pas</strong> injecter une future période de lui-même.',
    'percentage.rule.target.error': 'Pourcentage de cible invalide dans le plan',
    'percentage.rule.target.content': 'Le plan <0>{{name}}</0> <strong>ne peut pas</strong> avoir 0 comme objectif de pourcentage.<br />Ce plan utilise une règle de pourcentage et l\'objectif évalué est de 0 pour un utilisateur de ce plan.',
    'plan.invalid.codeOnly.formula.error': 'Formule non valide dans le plan',
    'plan.invalid.codeOnly.formula.content': 'La règle de <0>{{name}}</0> contient des formules personnalisées non valides.',
    'plan.payout.rule.evaluation.error': 'Erreur dans la règle de paiement',
    'plan.payout.rule.evaluation.content': 'Une erreur s\'est produite lors de l\'évaluation de <strong>{{field}}</strong> > <strong>{{formula}}</strong> dans les <0>règles de paiement de {{name}}</0>.<br />Raison : <strong>{{reason}}</strong>.',
    'plan.tracked.resource.value.evaluation.error': 'Erreur de valeur évaluée d\'une ressource suivie',
    'plan.tracked.resource.value.evaluation.content': 'Une erreur s\'est produite sur la <0>valeur de la ressource suivie de {{name}}</0> lors de l\'évaluation de <strong>{{formula}}</strong>.<br />Raison : <strong>{{reason}}</strong>.',
    'plan.invalid.paymentPercentage.error': 'Pourcentage de paiement non valide',
    'plan.invalid.paymentPercentage.content': 'Un paiement dans les <0>règles de paiement de {{name}}</0> contient une valeur de pourcentage non valide.<br />Raison : <strong>{{reason}}</strong>.',
    'paymentsEditPayoutSchedule.editPayoutSchedule': 'Modifier le calendrier des paiements',
    'paymentsEditPayoutScheduleModal.title': 'Modifier le calendrier des paiements de la commission',
    'paymentsEditPayoutScheduleModal.save': 'Enregistrer',
    'paymentsEditPayoutScheduleModal.warning': 'Modifier manuellement le calendrier des paiements empêche tous changements appliqués par les règles de paiements définis par le plan.',
    'paymentsEditPayoutScheduleModal.commission': 'Commission',
    'paymentsEditPayoutScheduleModal.payments': 'Paiements',
    'paymentsEditPayoutScheduleModal.use': 'Use',
    'paymentsEditPayoutScheduleModal.total': 'Total:',
    'paymentsEditPayoutScheduleModal.addPayment': 'Ajouter un paiement',
    'paymentsEditPayoutScheduleModal.alert.periodIsFrozen': 'La période est figée donc les paiements dans le passé ne peuvent plus être modifiés.',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.title': 'Êtes-vous sûr de vouloir réinitialiser au calendrier des paiements d\'origine ?',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.confirm': 'Oui',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.cancel': 'Non',
    'paymentsEditPayoutScheduleModal.resetPayout.popconfirm.cta': 'Réinitialiser au calendrier des paiements d\'origine',
    'editPayoutPaymentRow.payment': 'Paiement {{index}}',
    'paymentsList.pastPayments': 'Paiements effectués',
    'paymentsList.upcomingPayments': 'Paiements à venir',
    'paymentsList.hasBeenPaid': '<strong>{{amount}}</strong> ({{percentage}}%) ont été payés dans le relevé de <strong>{{date}}</strong> ({{exactDate}})',
    'paymentsList.willBePaid': '<strong>{{amount}}</strong> ({{percentage}}%) seront payés dans le relevé de <strong>{{date}}</strong> ({{exactDate}})',
    'paymentsList.withoutPayments': 'Montant de la commission sans échéancier: <strong>{{amount}} ({{percentage}}%)</strong>',
    'paymentsSummary.paymentsDone': 'Commissions payées',
    'paymentsSummary.paymentsToBeDone': 'Commissions à payer',
    'paymentsSummary.paymentsNbPercentage': '($t(paymentsWithCount, {"count": {{paymentsCount}} }), {{percentage}}%)',
    'periodDealCommissionInfoPayments.payments_zero': '{{count}} paiement',
    'periodDealCommissionInfoPayments.payments_one': '{{count}} paiement',
    'periodDealCommissionInfoPayments.payments_other': '{{count}} paiements',
    'periodDealCommissionInfos.viewDetails': 'Voir les détails',
    'periodDealCommissionValue.tooltip': 'Aucun nouvel objectif atteint',
    'periodDealOwners.users_zero': '{{count}} utilisateur',
    'periodDealOwners.users_one': '{{count}} utilisateur',
    'periodDealOwners.users_other': '{{count}} utilisateurs',
    'periodsPicker.from': 'De',
    'periodsPicker.to': 'A',
    'planColumnEdition.placeholders.variableName': 'Nom de la colonne',
    'planColumnEdition.placeholders.fields': 'Choisir les champs',
    'plan.columnFormatter.default': 'Défault',
    'plan.columnFormatter.date': 'Date (YYYY-MM-DD)',
    'plan.columnFormatter.datetime': 'Date & Heure (YYYY-MM-DD HH:mm)',
    'plan.columnFormatter.custom': 'Custom',
    'planScope.individual.label': 'Individuel',
    'planScope.individual.description': 'Les contributeurs individuels sont commissionnés sur leur propre performance',
    'planScope.team.label': 'Basé sur la performance d\'équipe',
    'planScope.team.description': 'Toute l\'équipe est commissionnée sur sa performance',
    'planScope.manager.label': 'Manager',
    'planScope.manager.description': 'Calculer les commissions en fonction des performances des autres',
    'planEditColumnsModal.title': 'Modification des colonnes affichées',
    'planEditColumnsModal.subTitle': 'Pour les {{type}} dans le plan {{planName}}',
    'planEditColumnsModal.save': 'Enregistrer les colonnes',
    'planEditColumnsModal.tip.part1': 'Astuce: Vous pouvez ajouter des champs custom dans les {{type}}',
    'planEditColumnsModal.tip.link': 'ici',
    'planEditColumnsModal.tip.part2': '. (dans la section Enrichir les {{type}} avec de nouveaux champs)',
    'planEditColumnsModal.error.text': 'Veuillez vérifier vos informations. Le nom de la colonne est obligatoire ainsi qu\'une formule si le type d\'affichage choisi est "Custom".',
    'planEditColumnsModal.resetColumns.popconfirm.title': 'Êtes-vous sûr de vouloir réinitialiser aux colonnes d\'origines ?',
    'planEditColumnsModal.resetColumns.popconfirm.confirm': 'Oui',
    'planEditColumnsModal.resetColumns.popconfirm.cancel': 'Non',
    'planEditColumnsModal.resetColumns.popconfirm.cta': 'Réinitialiser aux colonnes d\'origines',
    'planEditColumnsModal.dealNameBlock.title': 'Nom du {{type}}',
    'planEditColumnsModal.planColumnsBlock.title': 'Colonnes affichées dans le tableau du plan',
    'planEditColumnsModal.addColumn': 'Ajouter une colonne',
    'planEditColumnsModal.nameColumn.fields': 'Champs représentant le nom du {{type}}',
    'planEditColumnsModal.column.name': 'Nom de la colonne',
    'planEditColumnsModal.column.fields': 'Champs du {{type}} ou variable du plan',
    'planEditColumnsModal.column.fields.tooltip': 'Si vous ajoutez plusieurs champs, ils seront concaténés avec un espace.',
    'planEditColumnsModal.column.formatter': 'Affichage',
    'planProgressGaugeChart.noTarget': 'Aucun objectif défini',
    'planProgressGaugeWidget.title': 'Progression',
    'planPayoutScheduleWidget.title': 'Calendrier de paiement',
    'planProgressBarChart.noTarget': 'Aucun objectif défini',
    'planProgressBarChartWidget.title': 'Progression au fil des mois',
    'planTotalCommissionsWidget.title': 'Total des commissions',
    'planTotalValueWidget.title': 'Total {{valueDefinition}}',
    'planTotalValueWidget.title.empty': 'Valeur totale',
    'planDetailsWidget.commissions': 'Commissions',
    'plansPage.title': 'Plans',
    'plans.errors.computation.already.running': 'Un calcul est déjà en cours, merci de retenter plus tard.',
    'plans.success.computeAll.done': 'Le rafraîchissement des données est maintenant effectué, nous allons recharger la page...',
    'plans.success.computePlan.done': 'Le rafraîchissement des données est maintenant effectué',
    'plansUsingQuota.notUsed': 'Utilisé par aucun plan',
    'plansUsingQuota.link_one': 'Utilisé par {{count}} plan',
    'plansUsingQuota.link_other': 'Utilisé par {{count}} plans',
    'plansUsingQuotaModal.title': 'Plans liés à',
    'planUsingQuotaItem.view': 'Voir',
    'planV3CodeCell.errors.type.notebook.python.error': 'Une erreur Python s\'est produite',
    'planV3CodeCell.errors.type.planv3.codeCell.unexpected.error': 'Une erreur inatendue s\'est produite',
    'planV3CodeCell.errors.type.planv3.required.information': 'Champ requis',
    'planV3CodeCell.errors.type.planv3.required.information.content': 'La cellule de code requiert l\'information <strong>{{path}}</strong>.',
    'planV3CodeCell.errors.type.planv3.codeCell.no.code.provided': 'Cellule de code vide',
    'planV3CodeCell.errors.type.planv3.commission.no.payment': 'Paiements manquants',
    'planV3CodeCell.errors.type.planv3.commission.no.payment.content': 'Le deal ayant l\'id <strong>{{dealId}}</strong> est en manque de paiements (<strong>payments</strong>).',
    'planWhatIfProjectionChart.axisBottomLabel': 'Quota atteint',
    'planWhatIfProjectionChart.axisRightLabel': 'Commissions',
    'planWhatIfProjectionWidget.title': 'Projection',
    'planWithPeriods.periods': '{{count}} périodes',
    'plansWithPeriods.plans': '{{count}} plans',
    'profile.errors.remaining.attempts_zero': 'Aucune tentative restante.',
    'profile.errors.remaining.attempts_one': '<strong>{{count}}</strong> tentative restante.',
    'profile.errors.remaining.attempts_other': '<strong>{{count}}</strong> tentatives restantes.',
    'profile.errors.invalid.credentials': 'Erreur de connexion: Adresse mail/mot de passe invalides',
    'profile.errors.account.locked': 'Erreur de connexion: le compte est vérouillé pendant 30 minutes.',
    'profile.errors.reset.password.link.expired': 'Le lien pour mettre à jour votre mot de passe a expiré.',
    'profilePage.page.title': 'Mon Profil',
    'profilePageContent.role': 'Rôle: {{roleName}}',
    'profilePageContent.logout': 'Se connecter',
    'queryBuilder.empty': 'La requête est vide.',
    'queryBuilder.hideEditor': 'Cacher l\'éditeur',
    'queryBuilder.showEditor': 'Montrer l\'éditeur',
    'queryBuilderAndOrBlock.addCondition': 'Ajouter une Condition',
    'queryBuilderAndOrBlock.addAndOrBlock': 'Ajouter un Groupe [AND/OR]',
    'queryBuilderAndOrBlock.deleteAndOrBlock': 'Supprimer le Groupe [AND/OR]',
    'queryBuilderAndOrCondition.fieldName': 'Nom du champs',
    'queryBuilderAndOrCondition.valueOrCode': 'Valeur ou Code',
    'quota.exportQuota': 'Exporter le Quota',
    'quota.archiveQuota': 'Archiver le Quota',
    'quota.archiveQuota.title': 'Archiver le Quota',
    'quota.archiveQuota.content': 'Confirmez vous vouloir archiver <strong>{{quotaName}}</strong> ?',
    'quota.addQuotaUserLeaveDate.title': 'Date de départ de l\'utilisateur',
    'quota.addQuotaUserLeaveDate.save': 'Enregistrer',
    'quota.addQuotaUserLeaveDate.description': 'Définissez la date à laquelle <strong>{{userDisplayName}}</strong> doit être retirer de <strong>{{quotaName}}</strong>:',
    'quota.addUsersInQuota': 'Ajouter des utilisateurs dans le Quota',
    'quota.addUsers': 'Ajouter les utilisateurs',
    'quota.archived': 'Ce Quota est archivé.',
    'quota.changeDefaultValues': 'Modifier les valeurs par défaut',
    'quota.createNewONe': 'Créer un nouveau Quota',
    'quota.createQuota': 'Créer le Quota',
    'quota.defaultValue.initial': 'Valeur par défaut initiale',
    'periodYear.from': 'A partir de {{periodName}}',
    'quota.deleteQuota': 'Supprimer le Quota',
    'quota.deleteQuota.confirm': 'Je confirme',
    'quota.deleteQuota.title': 'Supprimer le Quota',
    'quota.deleteQuota.content': 'Confirmez-vous vouloir supprimer <strong>{{quotaName}}</strong> ?',
    'quota.editQuota': 'Modifier le Quota',
    'quota.errors.user.not.in.scope': 'Vous ne pouvez pas ajouter un utilisateur que vous ne gérez pas.',
    'quota.errors.quota.not.found': 'Le Quota n\'a pas été trouvé.',
    'quota.errors.user.not.in.quota': 'Vous essayez de faire une mise à jour pour un utilisateur qui n\'est pas dans le Quota.',
    'quota.errors.user.not.found': 'L\'utilisateur n\'a pas été trouvé.',
    'quota.errors.user.already.in.quota': 'L\'utilisateur est déjà dans le Quota.',
    'quota.errors.user.attached.to.plans': 'L\'utilisateur est attaché aux Plans.',
    'quota.form.name.label': 'Nommer votre Quota',
    'quota.form.name.placeholder': 'Ex: Quota pour les AEs',
    'quota.form.name.rules.required': 'Merci de nommer votre Quota.',
    'quota.form.tracked.addTypeToOptions': 'Ajouter ce type',
    'quota.form.tracked.label': 'Qu\'est-ce qui est traqué dans ce Quota ?',
    'quota.form.tracked.placeholder': 'Ex: ARR, Nouveau ARR, Réunions, Revenue...',
    'quota.form.tracked.rules.required': 'Merci de sélectionner ou créer le type traqué.',
    'quota.form.tracked.searchNotFound': 'Aucun type existant ne correspond',
    'quota.form.frequency.label': 'Quelle est la fréquence de ce Quota ?',
    'quota.form.beginAt.label': 'Date de début',
    'quota.form.beginAt.rules.required': 'Merci de sélectionner une date de début',
    'quota.form.frequencyForDay.label': 'Durée d\'une période en jours',
    'quota.form.frequencyForDay.description': 'Renseignez 7 pour un quota hebdomadaire',
    'quota.form.frequencyForDay.rules.required': 'Merci de renseigner une durée',
    'quota.form.defaultValue.label': 'Quelle est la valeur par défaut pour ce Quota ?',
    'quota.form.defaultValueForTeam.label': 'Quel est l\'objectif par défaut pour l\'équipe ?',
    'quota.form.defaultValueForCustomAndTeam.label': 'Quel est l\'objectif par défaut pour l\'équipe pour cette période basée sur des dates personnaliées ?',
    'quota.form.defaultValue.description': 'Les utilisateurs ajoutés auront leur Quota initialisé avec cette valeur',
    'quota.lockQuota': 'Vérouiller le Quota',
    'quota.removeUserFromQuota.confirm': 'Je confirme',
    'quota.removeUserFromQuota.title': 'Retrait de {{firstName}} du Quota',
    'quota.removeUserFromQuota.content': 'Confirmez-vous vouloir retirer <strong>{{userDisplayName}}</strong> de <strong>{{quotaName}}</strong> ?',
    'quota.showDefaultValues': 'Voir les valeurs par défaut',
    'quota.isSaving': 'Enregistrement...',
    'quota.saved': 'Sauvegardé',
    'quota.total': 'Total',
    'quota.userSearch.placeholder': 'Rechercher un utilisateur',
    'quota.outOfScopeWarning.message_one': '{{count}} utilisateur est caché car il/elle est hors de votre scope.',
    'quota.outOfScopeWarning.message_other': '{{count}} utilisateurs sont cachés car ils sont hors de votre scope.',
    'quotaDetailContent.alert.noData.message': 'Aucun utilisateur n\'est présent dans ce Quota.',
    'quotaDetailContent.alert.noData.description': 'Cliquez sur le bouton suivant pour ajouter des utilisateurs dans ce Quota',
    'quotaList.empty.description': 'Aucun Quota disponible',
    'quotaList.filters.active': 'Actifs',
    'quotaList.filters.archived': 'Archivés',
    'quotaSetupPanel.alert.noQuotas.message': 'Aucun Quota disponible',
    'quotaSetupPanel.alert.noQuotas.description': 'Cliquez sur le bouton "$t(quota.createNewONe)" ou allez sur la page Quotas pour créer un nouveau Quota.',
    'quotaSetupPanel.quotaSelector.placeholder': 'Sélectionnez un Quota',
    'quotaSetupPanel.missingUsersInQuota.alert.message': '$t(usersWithCountToBeMissing, {"count": {{count}} }) dans la Quota.',
    'quotaSetupPanel.doYouConfirmQuestion': 'Est-ce que vous confirmez choisir ce Quota et ajouter les utilisants manquants dans le Quota ?',
    'quotaSetupPanel.confirmBtnLabel': 'Je confirme',
    'quotaTab.cancel': 'Annuler',
    'quotaTab.unlinkConfirmModal.title': 'Voulez-vous supprimer le lien entre ce Quota et le Plan ?',
    'quotaTab.unlinkConfirmModal.content': 'Merci de confirmer que vous voulez supprimer le lien. Le plan ne sera alors plus lié à aucun Quota.',
    'quotaTab.unlinkConfirmModal.confirm': 'Confirmer',
    'quotaTab.linkQuota': 'Lier le Quota sélectionné',
    'quotaTab.targetWillBeMissed.description': 'Veuillez noter que les targets définies actuellement dans le plan vont être perdues lorsqu\'un Quota sera lié à ce plan.',
    'quotaTab.targetWillBeMissed.message': 'Les targets définies actuellement vont être perdues',
    'quotaTab.title': 'Configuration du Quota',
    'quotaTab.unlinkQuota': 'Supprimer le lien avec ce Quota',
    'quotaTab.updatePlanSuccess': 'La plan a bien été mis à jour',
    'quotaTab.updatePlanError': 'Une erreur est survenue pendant la mise à jour du plan',
    'quotaTableBodyCell.resetOverwriteBtn.tooltip': 'Réinitialiser à la valeur par défaut',
    'quotaTableBodyCell.notOnboardedYet.tooltip': 'Pas encore intégré',
    'quotaTableBodyCell.offboarded.tooltip': 'Ne fait plus partie de l\'effectif',
    'quotaTableBodyCell.notInPlanYet.tooltip': 'Pas encore dans le plan',
    'quotaTableBodyCell.leftThePlan.tooltip': 'A quitté le plan',
    'quotaTargetStrategy.sum': 'Sommer les quota des utilisateurs',
    'quotaTargetStrategy.match': 'Utiliser le quota du(des) manager(s)',
    'quotasPage.title': 'Quotas configuration',
    'quotasPerformancePage.title': 'Quotas performance',
    'quotasPerformancePage.empty.description': 'Aucun tableau de bord disponible pour le moment',
    'quotasPerformance.createDashboard.label': 'Créer un nouveau tableau de bord',
    'quotasPerformance.createDashboard.modal.title': 'Créer un nouveau tableau de bord',
    'quotasPerformance.createDashboard.modal.button.create': 'Créer le tableau de bord',
    'quotasPerformance.createDashboard.modal.form.name': 'Nommer votre tableau de bord',
    'quotasPerformance.createDashboard.modal.form.name.placeholder': 'Nommer votre tableau de bord',
    'quotasPerformance.createDashboard.modal.form.name.required': 'Veuillez nommer votre tableau de bord.',
    'quotasPerformance.createDashboard.modal.form.description': 'Description',
    'quotasPerformance.addPlanSelectors.field.label': 'Ajouter des Plans',
    'quotasPerformance.addPlanSelectors.select.placeholder': 'Choisir un Plan',
    'quotasPerformance.addPlanSelectors.addButton.label': 'Ajouter un Plan',
    'quotasPerformance.removeDashboard.popconfirm.label': 'Êtes-vous sûr de vouloir supprimer ce tableau de bord ?',
    'quotasPerformance.removeDashboard.tooltip.label': 'Supprimer ce tableau de bord',
    'quotasPerformance.addPlansToDashboard.label': 'Ajouter des Plans',
    'quotasPerformance.details.header.emptyDescription': 'Aucune description',
    'quotasPerformance.editableField.required.error': 'Ce champ est obligatoire.',
    'quotasPerformance.addPlansToDashboard.modal.title': 'Ajouter des plans à ce tableau de bord',
    'quotasPerformance.addPlansToDashboard.modal.button.addPlans': 'Ajouter les plans',
    'quotasPerformance.detailsContent.noData.message': 'Il n\'y a pas de plans dans ce tableau de bord',
    'quotasPerformance.detailsContent.noData.description': 'Cliquez sur le bouton Ajouter des Plans pour commencer à ajouter des plans',
    'quotasPerformance.errors.dashboard.not.found': 'Le tableau de bord n\'a pas été trouvé.',
    'quotasPerformance.dashboardPlan.metricTracked': 'Métrique suivie :',
    'quotasPerformance.dashboardPlan.noMetricTracked': 'Aucune métrique suivie',
    'quotasPerformance.dashboardPlan.noMetricTracked.alert.message': 'Aucune statistique n\'est suivie pour ce plan',
    'quotasPerformance.dashboardPlan.planDetails': 'Voir les détails du plan',
    'quotasPerformance.removeDashboardPlan.popconfirm.label': 'Voulez-vous vraiment supprimer cette réalisation de quota ?',
    'quotasPerformance.removeDashboardPlan.tooltip.label': 'Supprimer cette réalisation de quota',
    'quotasPerformance.dashboardPlan.entity.label.user': 'Utilisateurs',
    'quotasPerformance.dashboardPlan.summary.label.average': 'Moyenne totale',
    'quotasPerformance.dashboardPlan.summary.label.median': 'Médiane totale',
    'quotaUserModal.quotasFor': 'Quotas pour',
    'rawData.button.addItem': 'Ajouter un élément',
    'rawData.button.addProperty': 'Ajouter une propriété',
    'rawData.lastEditedAt': 'Modifié {{timeAgo}}',
    'rawData.search.placeholder': 'Rechercher une propriété ou une valeur',
    rawDataCollapseItemsConditions_zero: 'élément',
    rawDataCollapseItemsConditions_one: 'élément',
    rawDataCollapseItemsConditions_other: 'éléments',
    rawDataCollapseItemsConditionsWithCount_zero: '({{count}} $t(rawDataCollapseItemsConditions_zero))',
    rawDataCollapseItemsConditionsWithCount_one: '({{count}} $t(rawDataCollapseItemsConditions_one))',
    rawDataCollapseItemsConditionsWithCount_other: '({{count}} $t(rawDataCollapseItemsConditions_other))',
    rawDataCollapsePropertiesConditions_zero: 'propriété',
    rawDataCollapsePropertiesConditions_one: 'propriété',
    rawDataCollapsePropertiesConditions_other: 'propriétés',
    rawDataCollapsePropertiesConditionsWithCount_zero: '({{count}} $t(rawDataCollapsePropertiesConditions_zero))',
    rawDataCollapsePropertiesConditionsWithCount_one: '({{count}} $t(rawDataCollapsePropertiesConditions_one))',
    rawDataCollapsePropertiesConditionsWithCount_other: '({{count}} $t(rawDataCollapsePropertiesConditions_other))',
    'rawData.modals.add.title': 'Ajouter une entrée {{type}}',
    'rawData.modals.add.confirm': 'Ajouter l\'entrée',
    'rawData.modals.edit.disclaimer': 'La valeur est limitée à du texte ici mais pourra être modifiée en éditant la propriété en elle-même.',
    'rawData.modals.edit.title': 'Modifier une entrée {{type}}',
    'rawData.modals.edit.subtitle.item': 'Au rang {{position}} de "{{parent}}"',
    'rawData.modals.edit.subtitle.property': 'Dans la propriété "{{parent}}"',
    'rawData.modals.edit.confirm': 'Modifier l\'entrée',
    'rawData.modals.edit.notEditable': 'Non modifiable ici',
    'rawData.modals.edit.propertyName': 'Nom de la propriété',
    'rawData.modals.edit.propertyType': 'Type de la propriété',
    'rawData.modals.edit.propertyValue': 'Quelle est la valeur de la propriété ?',
    'rawData.modals.edit.propertyValuePlaceholder': 'Valeur de la propriété',
    'rawData.modals.edit.rules.propertyName': 'Merci de nommer votre propriété avec un nom de variable valable (pas de caractères spéciaux, ex: nom_de_propriete).',
    'rawData.modals.edit.rules.propertyValue': 'Merci de définir une valeur à votre propriété.',
    'rawData.modals.edit.rules.propertyDate': 'Merci de définir une date valide à votre propriété.',
    'rawData.modals.edit.rules.propertyBoolean': 'Merci de sélectionner une valeur pour votre propriété.',
    'rawData.modals.edit.rules.propertyNumber': 'Merci de définir un nombre à votre propriété.',
    'rawData.modals.deleteProperty.title': 'Supprimer une entrée {{type}}',
    'rawData.modals.deleteProperty.content': 'Vous êtes sur le point de supprimer cette ligne. Êtes-vous sûr de vouloir confirmer cette action ?',
    'rawData.modals.deleteProperty.confirm': 'Supprimer l\'entrée',
    'rawData.modals.storedAs': 'Stocké par : {{value}}',
    'rawData.modals.editOriginalFormatLabel': '(cliquez ici pour modifier le format d\'origine)',
    'rawData.versionHistory.button': 'Historique des versions',
    'rawData.historyDrawer.title': 'Historique des versions',
    'rawData.historyDrawer.rollbackOriginalValue': 'Revenir à la version d\'origine',
    'rawData.historyDrawer.rollback': 'Revenir',
    'rawData.historyDrawer.rollbackTooltip': 'Revenir à cette version',
    'rawData.historyDrawer.setTo': 'a été modifié par {{value}}',
    'rawData.historyDrawer.deletedTo': 'a été supprimé',
    'rawData.rollbackModal.title': 'Revenir à une version précédente ?',
    'rawData.rollbackModal.content': 'Veuillez confirmer que vous souhaitez revenir à une version précédente.',
    rawDataHistoryConditions_zero: 'modification',
    rawDataHistoryConditions_one: 'modification',
    rawDataHistoryConditions_other: 'modifications',
    rawDataHistoryConditionsWithCount_zero: '{{count}} $t(rawDataHistoryConditions_zero)',
    rawDataHistoryConditionsWithCount_one: '{{count}} $t(rawDataHistoryConditions_one)',
    rawDataHistoryConditionsWithCount_other: '{{count}} $t(rawDataHistoryConditions_other)',
    'rawDataEntry.storedAs': 'Stocké par : {{value}}',
    'rawDataPanelTitleNode.badge.new': '+ Ajouté',
    'rawDataPanelTitleNode.badge.old': '- Supprimé',
    'rawDataTreeRecursive.recursivityDepthLimitReached': 'Vous ne pouvez pas explorer plus loin, merci de contacter le support',
    'removeMemberFromTeamModal.title': 'Retirer le membre',
    'removeMemberFromTeamModal.confirm': 'Je confirme',
    'removeMemberFromTeamModal.content': 'Confirmez-vous vouloir retirer <strong>{{userName}}</strong> de <strong>{{teamName}}</strong> ?',
    'resendInviteUserLink.popconfirm.title': 'Êtes-vous sûr de vouloir renvoyer l\'invitation à cet utilisateur ?',
    'resendInviteUserLink.label': 'Renvoyer l\'invitation',
    'resetPasswordForm.form.password.label': 'Mot de passe',
    'resetPasswordForm.form.password.placeholder': 'Votre mot de passe',
    'resetPasswordForm.form.password.rules.isNotValid': 'Merci de fournir un mot de passe valide.',
    'resetPasswordForm.form.password.rules.required': 'Merci de renseigner votre mot de passe.',
    'resetPasswordForm.form.passwordConfirm.label': 'Confirmation du mot de passe',
    'resetPasswordForm.form.passwordConfirm.placeholder': 'Ecrivez de nouveau votre mot de passe',
    'resetPasswordForm.form.passwordConfirm.rules.isDifferent': 'Vous devez entrer le même mot de passe que ci-dessus.',
    'resetPasswordForm.form.passwordConfirm.rules.required': 'Merci de confirmer votre mot de passe.',
    'resetPasswordForm.form.default.submit': 'Mettre à jour mon mot de passe',
    'resetPasswordForm.form.isNewUser.submit': 'Créer mon compte',
    'resetPasswordPageContent.default.title': 'Réinitialisez votre mot de passe',
    'resetPasswordPageContent.isNewUser.subtitle': 'Définissez votre mot de passe pour commencer',
    'resetPasswordPageContent.isNewUser.title': 'Bienvenue à Palette !',
    'resetPasswordPageContent.login': 'Se connecter',
    'resource.openIn': 'Ouvrir dans {{connectorName}}',
    'resourceConnectorsSelect.resourcesOption.label': '{{type}} ({{connector}})',
    'resourceConnectorsSelect.category.connectors': 'Connecteurs',
    'resourceConnectorsSelect.category.notebooks': 'Modèles',
    'resourceObjectSample.title': 'Exemple de {{resourceType}}',
    'resourceObjectSample.columns.name.label': 'Nom',
    'resourceObjectSample.columns.value.label': 'Valeur',
    'resourceObjectSample.propSearch.placeholder': 'Rechercher un champs',
    'resources.documentation.rawData': 'Documentation à propos des données brutes et leur surcharge',
    'resources.errors.object.not.found': 'La ressource n\'a pas été trouvée.',
    'resources.propertyTypes.STRING': 'Texte',
    'resources.propertyTypes.DATE': 'Date',
    'resources.propertyTypes.BOOLEAN': 'Booléen',
    'resources.propertyTypes.ARRAY': 'Liste',
    'resources.propertyTypes.OBJECT': 'Propriétés imbriquées',
    'resources.propertyTypes.NUMBER': 'Nombre',
    'resources.index.header.title': 'Ressources disponibles',
    'resources.index.header.tabs.connectors': 'Connecteurs',
    'resources.index.header.tabs.notebooks': 'Modèles',
    'resources.index.header.tabs.from.label': '(de {{name}})',
    'resources.index.main.empty.text': 'Pas de données disponibles',
    'resources.table.actions.button.view_details': 'Voir les détails',
    'resources.table.actions.button.edit_columns': 'Modifier les colonnes',
    'resources.actions.search.placeholder': 'Recherche rapide',
    'resources.filter.modal.title': 'Constructeur de requête',
    'resources.filter.modal.ok.label': 'Filtrer',
    'resources.filter.modal.queryBuilder.empty.label': 'Constructeur de requête vide.',
    'resources.details.backLink.label': 'Retourner sur les resources',
    'resources.details.tabs.rawData': 'Données brutes',
    'resources.details.tabs.commissions': 'Commissions',
    'resources.details.summary.report': 'du rapport "{{reportName}}"',
    'resources.details.summary.clonedFrom': '{{type}} cloné à partir de celui-ci',
    'resources.details.summary.externalLink': 'Ouvrir dans {{connector}}',
    'resources.details.delete.popconfirm.label': 'Êtes-vous sûr de vouloir supprimer ce {{type}}?',
    'resources.details.delete.tooltip.label': 'Supprimer ce {{type}}',
    'resources.details.clone.popconfirm.label': 'Êtes-vous sûr de vouloir dupliquer ce {{type}}?',
    'resources.details.clone.tooltip.label': 'Dupliquer ce {{type}}',
    'resourceLastSyncDates.button.label': 'Redéfinir les dates de dernière synchronisation',
    'resourceLastSyncDates.modal.title': 'Redéfinir les dates de dernière synchronisation',
    'resourceLastSyncDates.modal.save.label': 'Sauvegarder',
    'resourceLastSyncDates.modal.dateField.label': 'À quelle date réinitialiseriez-vous la dernière synchronisation ?',
    'resourceLastSyncDates.modal.dateField.error.message': 'Veuillez sélectionner une date valide.',
    'resourceConfiguration.configure.resource': 'Configurer la ressource',
    'resourceConfiguration.configure.tooltip.disabled': 'Veuillez d\'abord enregistrer la ressource',
    'resourceConfiguration.configure.tooltip.noconfig': 'Veuillez configurer la ressource au moins une fois',
    'resourceConfiguration.modal.title': 'Configuration - {{resourceType}}',
    'resourceConfiguration.modal.introduction': 'Sélectionnez les champs de ressource que vous souhaitez synchroniser avec le connecteur.',
    'resourceConfiguration.modal.save.label': 'Sauvegarder',
    'resourceConfiguration.modal.field.required': 'Requis',
    'resourceConfiguration.modal.field.used': 'Utilisé dans certains champs de colonnes',
    'resourceConfiguration.modal.search.placeholder': 'Rechercher',
    'resourceConfiguration.modal.reset.label': 'Réinitialiser la configuration',
    'resourceConfiguration.modal.reset.title': 'Confirmez-vous que vous souhaitez désélectionner tous les champs ci-dessous ?',
    'roleBreadcrumb.newRole': 'Nouveau Rôle',
    'roleDetailsPageContent.isProtectedAlert': 'Ce rôle est protégé et ne peut pas être modifié.',
    'rolesListItem.users_zero': 'Aucun utilisateur',
    'rolesListItem.users_one': '{{count}} utilisateur',
    'rolesListItem.users_other': '{{count}} utilisateurs',
    'rolesListItem.isDefault': ' (Par défaut)',
    'rolesListItemMoreOptionsMenu.duplicateRole': 'Dupliquer le rôle',
    'rolesListItemMoreOptionsMenu.deleteRole': 'Supprimer le rôle',
    'rolesListItemMoreOptionsMenu.makeDefaultRole': 'Configurer par défaut',
    'roleManagement.adminRightsLabel': 'Permissions Manager/Admin',
    'roleManagement.icRightsLabel': 'Permissions Contributeur Individuel',
    'roleManagement.saveButtonLabel': 'Sauvegarder le Rôle',
    'roleManagement.rights.descriptions.admin.adjustments.manage': 'Possibilité de <strong>créer</strong>, de <strong>mettre à jour</strong> et de <strong>supprimer</strong> des corrections au niveau de l\'état.',
    'roleManagement.rights.descriptions.admin.commissions.manage': 'Possibilité de <strong>mettre à jour</strong> les montants et d\'<strong>annuler</strong> les paiements futurs',
    'roleManagement.rights.descriptions.admin.company.manage': 'Possibilité de <strong>mettre à jour</strong> les paramètres globaux de Palette (facturation, devises, taux de change, validations de relevés, ...)',
    'roleManagement.rights.descriptions.admin.connectors.create': 'Possibilité de <strong>Créer</strong> des connecteurs',
    'roleManagement.rights.descriptions.admin.connectors.display': 'Possibilité d\'<strong>accéder</strong> aux pages et caractéristiques des connecteurs',
    'roleManagement.rights.descriptions.admin.connectors.manage': 'Possibilité de <strong>Mettre à jour</strong> les paramètres des connecteurs',
    'roleManagement.rights.descriptions.admin.connectors.view': 'Possibilité d\'<strong>accéder</strong> aux paramètres des connecteurs',
    'roleManagement.rights.descriptions.admin.objects.csv_upload': 'Téléchargement CSV',
    'roleManagement.rights.descriptions.admin.objects.display': 'Possibilité d\'<strong>accéder</strong> aux pages et caractéristiques des ressources',
    'roleManagement.rights.descriptions.admin.objects.manage': 'Ecraser les propriétés',
    'roleManagement.rights.descriptions.admin.objects.view': 'Possibilité d\'<strong>accéder</strong> aux paramètres des ressources',
    'roleManagement.rights.descriptions.admin.plans.create': 'Possibilité de <strong>Créer</strong> des plans',
    'roleManagement.rights.descriptions.admin.plans.delete': 'Possibilité de <strong>Supprimer</strong> des plans',
    'roleManagement.rights.descriptions.admin.plans.display': 'Possibilité d\'<strong>accéder</strong> aux pages et caractéristiques des plans',
    'roleManagement.rights.descriptions.admin.plans.update': 'Possibilité de <strong>Mettre à jour</strong> les paramètres généraux des plans',
    'roleManagement.rights.descriptions.admin.plans.update_connector': 'Possibilité de <strong>mettre à jour</strong> les paramètres du connecteur (configuration avancée)',
    'roleManagement.rights.descriptions.admin.plans.view': 'Possibilité d\'<strong>accéder</strong> aux plans, défis, récompenses et paramètres de classement',
    'roleManagement.rights.descriptions.admin.plans.gbo.manage': 'Possibilité d\'<strong>ajouter</strong> et de <strong>supprimer</strong> des utilisateurs d\'un plan',
    'roleManagement.rights.descriptions.admin.quotas.display': 'Possibilité d\'<strong>accéder</strong> aux pages et fonctionnalités des quotas',
    'roleManagement.rights.descriptions.admin.quotas.manage': 'Possibilité de <strong>Créer</strong>, <strong>Mettre à jour</strong> et <strong>Supprimer</strong> les valeurs par défaut',
    'roleManagement.rights.descriptions.admin.quotas.view': 'Possibilité d\'<strong>accéder</strong> aux paramètres des quotas',
    'roleManagement.rights.descriptions.admin.quotas.users.manage': 'Possibilité d\'<strong>ajouter</strong> et de <strong>supprimer</strong> des utilisateurs d\'un quota',
    'roleManagement.rights.descriptions.admin.notebooks.display': 'Possibilité d\'<strong>accéder</strong> aux pages et fonctionnalités des modèles',
    'roleManagement.rights.descriptions.admin.notebooks.manage': 'Possibilité de <strong>Créer</strong>, <strong>Mettre à jour</strong> et <strong>Supprimer</strong> les modèles',
    'roleManagement.rights.descriptions.admin.notebooks.view': 'Possibilité d\'<strong>accéder</strong> aux paramètres des modèles',
    'roleManagement.rights.descriptions.admin.roles.display': 'Possibilité d\'<strong>accéder</strong> aux pages et fonctionnalités des rôles',
    'roleManagement.rights.descriptions.admin.roles.view': 'Possibilité d\'<strong>accéder</strong> aux paramètres des rôles',
    'roleManagement.rights.descriptions.admin.roles.manage': 'Possibilité de <strong>Créer</strong>, <strong>Mettre à jour</strong> et <strong>Supprimer</strong> les rôles',
    'roleManagement.rights.descriptions.admin.statements.validations.manage': 'Peut <strong>approuver</strong> et <strong>supprimer</strong> des déclarations',
    'roleManagement.rights.descriptions.admin.teams.manage': 'Possibilité de <strong>Créer</strong>, <strong>Mettre à jour</strong> et <strong>Supprimer</strong> les équipes',
    'roleManagement.rights.descriptions.admin.teams.members.manage': 'Possibilité d\'<strong>Ajouter</strong>, <strong>Mettre à jour</strong> et <strong>Supprimer</strong> les utilisateurs d\'une équipe',
    'roleManagement.rights.descriptions.admin.users.display': 'Possibilité d\'<strong>accéder</strong> aux pages et fonctionnalités des utilisateurs',
    'roleManagement.rights.descriptions.admin.users.invite': 'Possibilité d\'<strong>inviter</strong> un utilisateur à Palette',
    'roleManagement.rights.descriptions.admin.users.toggle-activate': 'Possibilité de <strong>supprimer</strong> un utilisateur',
    'roleManagement.rights.descriptions.admin.users.update': 'Possibilité de <strong>Mettre à jour</strong> les paramètres des utilisateurs',
    'roleManagement.rights.descriptions.admin.users.view': 'Possibilité d\'<strong>accéder</strong> aux paramètres des utilisateurs',
    'roleManagement.rights.descriptions.admin.wildcard': 'A un accès complet à toutes les pages et fonctionnalités et dispose de toutes les autorisations.',
    'roleManagement.rights.descriptions.ic.compensation': 'Possibilité d\'<strong>accéder</strong> aux déclarations et plans de l\'IC pour lesquels l\'IC est mandaté',
    'roleManagement.rights.descriptions.ic.dashboard.update': 'Possibilité de <strong>mettre à jour</strong> l\'affichage du tableau de bord et possibilité d\'<strong>ajouter</strong>, <strong>mettre à jour</strong>, <strong>supprimer</strong> le(s) widget(s)',
    'roleManagement.rights.scope.options.labels.none': 'Aucun',
    'roleManagement.rights.scope.options.labels.view_scoped': 'Scopé (ne peut accéder qu\'aux données utilisateur des équipes qu\'il dirige)',
    'roleManagement.rights.scope.options.labels.view_all': 'Tous (peut voir toutes les données des utilisateurs de l\'entreprise)',
    'roleManagement.sections.admin.commissions': 'Commissions',
    'roleManagement.sections.admin.company': 'Companie',
    'roleManagement.sections.admin.connectors': 'Connecteurs',
    'roleManagement.sections.admin.objects': 'Ressources',
    'roleManagement.sections.admin.plans': 'Plans',
    'roleManagement.sections.admin.quotas': 'Quotas',
    'roleManagement.sections.admin.roles': 'Rôles',
    'roleManagement.sections.admin.scope': 'Scope',
    'roleManagement.sections.admin.teams': 'Equipes',
    'roleManagement.sections.admin.users': 'Utilisateurs',
    'roleManagement.sections.admin.notebooks': 'Modèles',
    'roleManagement.sections.admin.wildcard': 'Admin accès complet',
    'roleManagement.sections.ic.dashboard': 'Tableau de bord',
    'roleManagement.sections.ic.plans': 'Plans',
    'role.createNewRole': 'Nouveau Rôle',
    'role.createNewRole.initialName': 'Nouveau rôle',
    'role.createRole': 'Créer le rôle',
    'role.form.name.label': 'Nom',
    'role.form.name.rules.required': 'Merci de nommer votre rôle.',
    'role.form.name.placeholder': 'Nom du rôle',
    'role.form.description.label': 'Description',
    'role.form.description.rules.required': 'Merci de rajouter une description à votre rôle',
    'role.form.description.placeholder': 'Description du rôle',
    'rolesPage.page.title': 'Rôles',
    'rulesCalculationForm.value.placeholder': 'Formule ou Montant',
    'rulesCalculationForm.value.error.required': 'Veuillez saisir la formule ou le montant',
    'rulesCalculationForm.useTiers.label': 'Utiliser les Niveaux',
    'rulesCalculationForm.delete.PER_OBJECT': 'Supprimer le Niveau',
    'rulesCalculationForm.delete.PER_TARGET': 'Supprimer l\'Objectif',
    'rulesCalculationForm.addA.PER_OBJECT': 'Ajouter un Niveau',
    'rulesCalculationForm.addA.PER_TARGET': 'Ajouter un Objectif',
    'rulesCalculationForm.useValueFor.PER_OBJECT': 'Utiliser des valeurs pour les Niveaux',
    'rulesCalculationForm.useValueFor.PER_TARGET': 'Utiliser des valeurs pour les Objectifs',
    'rulesCalculationForm.usePercentageFor.PER_OBJECT': 'Utiliser des pourcentages pour les Niveaux',
    'rulesCalculationForm.usePercentageFor.PER_TARGET': 'Utiliser des pourcentages pour les Objectifs',
    'rulesCalculationForm.from': 'De',
    'rulesCalculationForm.to': 'À',
    'rulesCalculationForm.fromTo.error.required': 'Requis',
    'rulesCalculationForm.formula': 'Formule',
    'rulesPayoutItemForm.delete': 'Supprimer cette règle de paiement',
    'rulesPayoutItemForm.title': 'Paiement {{position}}',
    'rulesPayoutItemForm.percentage.label': 'Pourcentage de la commission (formule)',
    'rulesPayoutItemForm.percentage.placeholder': 'Pourcentage (ex: 0.5 or 1)',
    'rulesPayoutItemForm.percentage.disclaimer': 'Ex: 1 ou 100% de la commission',
    'rulesPayoutItemForm.percentage.error.required': 'Merci de renseigner un pourcentage',
    'rulesPayoutItemForm.repeat.label': 'Répétition (formule)',
    'rulesPayoutItemForm.repeat.placeholder': 'Répétition (nombre de paiements)',
    'rulesPayoutItemForm.repeat.disclaimer': 'Ex: 1 pour 1 paiement, 2 pour 2 paiements différents',
    'rulesPayoutItemForm.repeat.error.required': 'Merci de renseigner la répétition',
    'rulesPayoutItemForm.startingAt.label': 'À partir de (formule)',
    'rulesPayoutItemForm.startingAt.placeholder': 'Date de début',
    'rulesPayoutItemForm.startingAt.disclaimer': 'Ex: Period.endAt pour un paiement à la fin de la période',
    'rulesPayoutItemForm.startingAt.error.required': 'Merci de renseigner une formule pour la date de début',
    'rulesPayoutItemForm.switchIntervalFormula.label': 'Changer le type de formule d\'intervalle',
    'rulesPayoutItemForm.intervalCount.label': 'Nombre d\'intervalles',
    'rulesPayoutItemForm.intervalCount.error.required': 'Veuillez saisir un intervalle supérieur à 0',
    'rulesPayoutItemForm.intervalType.label': 'Type d\'intervalle',
    'rulesPayoutItemForm.intervalFormula.label': 'Intervalle (formule)',
    'rulesPayoutItemForm.intervalFormula.error.required': 'Veuillez saisir l\'intervalle',
    'rulesPayoutItemForm.intervalType.month': 'Mois',
    'rulesPayoutItemForm.intervalType.day': 'Jour',
    'rulesPayoutItemForm.intervalType.year': 'An',
    'select.addToOptions': 'Ajouter aux options',
    'select.searchNotFound': 'Aucune correspondance',
    'selectUserResourcesModal.title': 'Sélectionner les Ressources',
    'selectUserResourcesModal.okText': 'Sélectionner',
    'sendFeedbackModal.alertMessage.hint': 'Que pensez-vous de Palette ?',
    'sendFeedbackModal.alertMessage.explanation': 'S\'il vous plaît partagez avec nous tous les problèmes que vous avez rencontrés ou ce qui peut être amélioré.',
    'sendFeedbackModal.title': 'Envoyer un avis',
    'sendFeedbackModal.send': 'Envoyer',
    'sendFeedbackModal.form.content.label': 'Avis',
    'sendFeedbackModal.form.content.rules.required': 'Merci de renseigner votre avis',
    'sendFeedbackModal.form.content.placeholder': 'Ecrivez votre commentaire ici',
    'sendFeedbackModal.uploadAttachments': 'Vous pouvez aussi joindre des captures d\'écran',
    'settingsPage.page.title': 'Paramètres',
    'showVariableValueHistoryModal.showHistoryModalTitle': 'Historique des valeurs: {{variableName}}',
    'showVariableValueHistoryModal.showHistoryModalSubTitle': 'Pour {{userDisplayName}}',
    'showVariableValueHistoryModal.columns.from.header': 'A partir de',
    'showVariableValueHistoryModal.columns.from.defaultValue': 'Valeur initiale par défaut',
    'showVariableValueHistoryModal.columns.value.header': 'Valeur',
    'showVariableValueHistoryModal.columns.dateValue.header': 'Valeur (UTC+0)',
    'sidebarCollapser.hide': 'Réduire',
    'superAdminBanner.content': 'Connexion sécurisée - Vous êtes connecté à un compte client.',
    statements_zero: 'fiches de paie',
    statements_one: 'fiche de paie',
    statements_other: 'fiches de paie',
    'statements.page.title': 'Fiches de paie',
    'statement.statuses.pendingActions': 'Actions à réaliser',
    'statement.statuses.approvalRequired': 'Approbation requise',
    'statement.statuses.paymentRequired': 'Paiement requis',
    'statement.statuses.paid': 'Payé',
    'statement.statuses.inFuture': 'Dans le Futur',
    'statement.statuses.emptyStatements': 'Déclarations vides',
    'statement.statuses.periodUser.paid': 'Déclaration payée',
    'statement.statuses.periodUser.approved': 'Validé',
    'statement.counters.label.correctionsCount_one': '{{count}} correction',
    'statement.counters.label.correctionsCount_other': '{{count}} corrections',
    'statement.statuses.paid.amount': 'Payé: {{amount}}',
    'statement.history.empty.description': 'Aucune modification n\'a encore été apportée à cette déclaration.',
    'statement.history.list.entity.plan': 'Plan',
    'statement.history.list.entity.commission': 'Commission',
    'statement.history.list.entity.deal': 'Deal',
    'statement.history.list.entity.user': 'Utilisateur',
    'statement.history.list.entity.statement': 'Déclaration',
    'statement.history.list.title.plan_general_settings_update': 'Mise à jour des paramètres généraux',
    'statement.history.list.content.plan_general_settings_update': 'a mis à jour les paramètres généraux pour <0>{{name}}</0>.',
    'statement.history.list.title.plan_commission_rules_update': 'Mise à jour des règles de commission',
    'statement.history.list.content.plan_commission_rules_update': 'a mis à jour les règles de commission pour <0>{{name}}</0>.',
    'statement.history.list.title.plan_payout_rules_update': 'Mise à jour des règles de paiement',
    'statement.history.list.content.plan_payout_rules_update': 'a mis à jour les règles de paiement pour <0>{{name}}</0>.',
    'statement.history.list.title.plan_delete': 'Suppression',
    'statement.history.list.content.plan_delete': 'a supprimé le plan <strong>{{name}}</strong>.',
    'statement.history.list.title.plan_dependencies_update': 'Mise à jour des dépendances',
    'statement.history.list.content.plan_dependencies_update': 'a mis à jour les dépendances pour <0>{{name}}</0>.',
    'statement.history.list.title.plan_source_track_update': 'Mise à jour des ressources suivies',
    'statement.history.list.content.plan_source_track_update': 'a mis à jour les ressources suivies pour <0>{{name}}</0>.',
    'statement.history.list.title.plan_user_track_update': 'Mise à jour des ressources utilisateur',
    'statement.history.list.content.plan_user_track_update': 'a mis à jour les ressources utilisateur pour <0>{{name}}</0>.',
    'statement.history.list.title.commission_value_overwrite': 'Montant écrasé',
    'statement.history.list.content.commission_value_overwrite': 'a modifié le montant de la commission <0>{{name}}</0> ({{previousAmount}}), lié au plan <1>{{planName}}</1>, de <strong>{{previousAmount}}</strong> à <strong>{{currentAmount}}</strong>.',
    'statement.history.list.title.commission_deal_value_overwrite': 'Valeur du deal écrasé',
    'statement.history.list.content.commission_deal_value_overwrite': 'a modifié la valeur de la commission <0>{{name}}</0> ({{amount}}), lié au plan <1>{{planName}}</1>, de <strong>{{previousValue}}</strong> à <strong>{{currentValue}}</strong>.',
    'statement.history.list.title.commission_payout_overwrite': 'Paiement écrasé',
    'statement.history.list.content.commission_payout_overwrite': 'a modifié les paiements de la commission <0>{{name}}</0> ({{amount}}), lié au plan <1>{{planName}}</1>.',
    'statement.history.list.title.resource_update': 'Mise à jour des données brutes',
    'statement.history.list.content.resource_update': 'a mis à jour <strong>{{fieldPath}}</strong> sur les données brutes de <0>{{name}}</0>.',
    'statement.history.list.content.resource_update.reset': 'a réinitialisé les données brutes de <0>{{name}}</0>.',
    'statement.history.list.title.user_add_in_plan': 'Utilisateur ajouté au plan',
    'statement.history.list.content.user_add_in_plan': 'a ajouté <0>{{payee}}</0> au plan <1>{{name}}</1>.',
    'statement.history.list.title.user_delete_from_plan': 'Utilisateur supprimé du plan',
    'statement.history.list.content.user_delete_from_plan': 'a retiré <0>{{payee}}</0> du plan <1>{{name}}</1>.',
    'statement.history.list.title.statement_correction_apply': 'Correction appliquée',
    'statement.history.list.content.statement_correction_apply': 'a appliqué la correction de <strong>{{amount}}</strong> relative au relevé de commission de <0>{{date}}</0>.',
    'statement.history.list.title.statement_correction_reject': 'Correction ignorée',
    'statement.history.list.content.statement_correction_reject': 'a ignoré la correction de <strong>{{amount}}</strong> relative au relevé de commission de <0>{{date}}</0>.',
    'statement.history.list.title.statement_correction_applied_update': 'Correction mise à jour',
    'statement.history.list.content.statement_correction_applied_update': 'a mis à jour une correction approuvée par <strong>{{user}}</strong> de <strong>{{previousAmount}}</strong> à <strong>{{newAmount}}</strong>.',
    'statement.history.list.title.statement_correction_applied_delete': 'Correction supprimée',
    'statement.history.list.content.statement_correction_applied_delete': 'a supprimé une correction approuvée par <strong>{{user}}</strong> de <strong>{{amount}}</strong>.',
    'statement.history.list.title.statement_correction_apply_manually': 'Correction ajoutée manuellement',
    'statement.history.list.content.statement_correction_apply_manually': 'a ajouté manuellement une correction approuvée par <strong>{{user}}</strong> de <strong>{{amount}}</strong>.',
    'statement.history.list.title.statement_corrected': 'Corrigé',
    'statement.history.list.content.statement_corrected': '<strong>{{action}}</strong> une correction de <strong>{{amount}}</strong> dans le relevé de commission de <0>{{date}}</0>.',
    'statement.history.list.content.statement_corrected_approved': 'a accepté',
    'statement.history.list.content.statement_corrected_refused': 'a ignoré',
    'statementPeriodsDates.type.custom': 'Custom',
    'statementPeriodsDates.type.year': 'Année {{year}}',
    'statementPeriodsDates.type.halfYear': 'S{{halfYear}} {{year}}',
    'statementPeriodsDates.type.quarter': 'T{{quarter}} {{year}}',
    'statementPeriodsList.noPeriods': 'Aucune période n\'a été trouvée pour la plage de dates sélectionnée.',
    'statementPeriodsListItem.total': 'Total ·',
    'statementPeriodsListItem.exportAll': 'Exporter toutes les déclarations de l\'utilisateur',
    'statementStrategyTypes.long.week': 'Semaine {{period}}',
    'statementStrategyTypes.short.week': 'S{{period}}',
    'statementStrategyTypes.long.biWeek': 'BiSemaine {{period}}',
    'statementStrategyTypes.short.biWeek': 'BS{{period}}',
    'statementStrategyTypes.long.halfMonth': 'Période {{period}}',
    'statementStrategyTypes.short.halfMonth': 'P{{period}}',
    'statementsSnapshotsExport.page.title': 'Export des snapshots des relevés',
    'statementsSnapshotsExport.page.subtitle': 'Lister les snapshots des relevés validés ou marqués comme payé',
    'statementsSnapshotsExport.submit.button.label': 'Lister les snapshots',
    'statementsSnapshotsExport.empty.description': 'Aucune snapshot à afficher.',
    'statementsSnapshotsExportListItem.export.label': 'Exporter le snapshot',
    'statementsSnapshotsExportListItem.actions.approved': 'a validé',
    'statementsSnapshotsExportListItem.actions.paid': 'a marqué comme payée',
    'statementsSnapshotsExportListItem.didActionOn': '{{action}} le {{date}}',
    'statementStrategy.intro': 'Votre stratégie de déclaration actuelle est',
    'statementStrategy.modal.title': 'Mise à jour de la stratégie de déclaration',
    'statementStrategy.modal.disclaimer.content': 'Vous êtes sur le point de mettre à jour votre stratégie de déclaration. Après cette mise à jour, tous les relevés qui ne sont pas marqués comme payés seront supprimés et remplacés par de nouveaux relevés basés sur la nouvelle stratégie.',
    'statementStrategy.modal.save.label': 'Mettre à jour la stratégie',
    'statementStrategy.modal.selectStrategy': 'Sélectionnez votre stratégie',
    'statementStrategy.modal.selectStrategy.placeholder': 'Sélectionnez votre stratégie',
    'statementStrategy.modal.startStrategyAt': 'Commencez la stratégie le',
    'statementStrategy.block.title': 'Stratégie de déclaration',
    'statementStrategy.modal.startStrategyAt.error.message': 'Veuillez sélectionner une date valide.',
    'statementStrategy.modal.strategyType.error.message': 'Veuillez sélectionner une stratégie.',
    'statementStrategy.propertyTypes.MONTH': 'Mensuelle',
    'statementStrategy.propertyTypes.HALF_MONTH': 'Semi-mensuelle',
    'statementStrategy.propertyTypes.BI_WEEK': 'Bihebdomadaire',
    'statementStrategy.propertyTypes.WEEK': 'Hebdomadaire',
    'statementStrategy.weekBegins': 'La semaine commence le {{day}}',
    'statementStrategy.weekBegins.EU': 'Lundi',
    'statementStrategy.weekBegins.US': 'Dimanche',
    'statementStrategy.monthBreaks': 'Pause le {{break}} du mois',
    'statementStrategy.monthBreaks.FIFTEENTH': '15',
    'statementStrategy.monthBreaks.SIXTEENTH': '16',
    'statementStrategy.modal.selectRoll': 'Commencer la semaine le',
    'statementStrategy.modal.selectRoll.placeholder': 'Commencer la semaine le',
    'statementStrategy.modal.strategyRoll.error.message': 'Veuillez sélectionner un jour.',
    'statementStrategy.monthBreaks.types': 'Le {{break}} du mois',
    'statementStrategy.modal.selectBreak': 'Interruption de la période bimensuelle le',
    'statementStrategy.modal.selectBreak.placeholder': 'Interruption de la période bimensuelle le',
    'statementStrategy.modal.selectBreak.error.message': 'Veuillez sélectionner un pivot bimensuel.',
    'statementStrategy.history.tooltip.label': 'Voir l\'historique',
    'statementStrategy.history.modal.title': 'Historique des stratégies',
    'statementStrategy.history.modal.table.header.date': 'Depuis',
    'statementStrategy.history.modal.table.header.strategy': 'Stratégie',
    'statementsPeriod.page.title': 'Fiches de paie',
    'statementsBreadcrumb.statements': 'Fiches de paie',
    'statementsUserStatement.page.title': 'Fiches de paie',
    'statementUsersNavigation.usersSelect.placeholder': 'Sélectionner un utilisateur',
    'statementPeriodApprovalProgress.approved': 'Approbations',
    'statementPeriodApprovalProgress.totalApprovedStatements': '{{count}} $t(statements, {"count": {{count}} }) avec au moins une validation',
    'statementPeriodApprovalProgress.totalInConflictApprovals': '({{count}} $t(statements, {"count": {{count}} }) avec au moins une validation en conflit)',
    'statementPeriodApprovalProgress.paid': 'Payés',
    'statementPeriodUsers.approveAll.button.label': 'Tout approuver',
    'statementPeriodUsers.approveAll.button.processingLabel': 'Traitement {{currentIndex}} / {{nbToProcess}}',
    'statementPeriodUsers.approveAll.button.popconfirm': 'Êtes-vous sûr de vouloir approuver toutes ces déclarations ?',
    'statementPeriodUsers.markAllAsPaid.button.label': 'Tout marquer comme payé',
    'statementPeriodUsers.markAllAsPaid.button.processingLabel': 'Traitement {{currentIndex}} / {{nbToProcess}}',
    'statementPeriodUsers.markAllAsPaid.button.popconfirm': 'Êtes-vous sûr de vouloir marquer toutes ces déclarations comme payées ?',
    'statementPeriodUsers.approve.button.label': 'Approuver',
    'statementPeriodUsers.approve.button.waitingLabel': 'En attente',
    'statementPeriodUsers.approve.button.processingLabel': 'En traitement',
    'statementPeriodUsers.approve.button.popconfirm': 'Êtes-vous sûr de vouloir approuver cette déclaration ?',
    'statementPeriodUsers.cancelApproval.button.popconfirm': 'Êtes-vous sûr de vouloir annuler cette validation ?',
    'statementPeriodUsers.markAsPaid.button.label': 'Marquer comme payé',
    'statementPeriodUsers.markAsPaid.button.waitingLabel': 'En attente',
    'statementPeriodUsers.markAsPaid.button.processingLabel': 'En traitement',
    'statementPeriodUsers.markAsPaid.button.popconfirm': 'Êtes-vous sûr de vouloir marquer cette déclaration comme payée ?',
    'statementPeriodUsers.reapprove.button.label': 'Ré-approuver',
    'statementPeriodUsers.reapprove.button.popconfirm': 'Êtes-vous sûr de vouloir ré-approuver cette déclaration ?',
    'statementRecomputeWarning.recompute': 'Rafraîchir les données',
    'statementRecomputeWarning.title': 'Rafraîchir les données',
    'statementRecomputeWarning.description': 'Des modifications importantes ont été apportées, veuillez mettre à jour les commissions en rafraîchissant les données.',
    'statement.approvals.tooltip.label': '{{userName}} a validé<br />{{amount}}',
    'statement.paidBy.tooltip.label': '{{userName}} a marqué comme payé<br />{{amount}}',
    'statementFxRates.block.title': 'Taux des déclarations',
    'statementFxRates.content.intro': 'Définissez les taux de change que vous souhaitez utiliser dans les déclarations.',
    'statementFxRates.mode.from_payment_date': 'À partir de la date de paiement',
    'statementFxRates.mode.from_commission_date': 'À partir de la date de commission',
    'statementValidations.block.title': 'Validations des déclarations',
    'statementValidations.content.text.approvals': 'Voulez-vous activer les actions "Approuver" et "Marquer comme payée" sur les fiches de paie ? Si ces actions sont activées, les responsables de l\'édition et les administrateurs peuvent approuver et marquer comme payée les fiches de paie. Les contributeurs pourront voir si une déclaration est approuvée ou marquée comme payée.',
    'statementValidations.content.switch.toggleValidations': 'Approbations des déclarations',
    'statementValidations.content.title.notifications': 'Envoyer un e-mail au représentant lors de la validation ?',
    'statementValidations.content.text.notifications': 'Voulez-vous envoyer un e-mail à l\'utilisateur lorsqu\'une déclaration est validée ? Veuillez noter que chaque utilisateur peut gérer ses propres notifications par e-mail et désactiver cette notification.',
    'statementValidations.content.switch.toggleNotifications': 'Notification par e-mail lors d\'une validation',
    'teamDetailsPageContent.subTeamsLabel': 'Sous équipes ({{count}})',
    'teamDetailsPageContent.membersLabel': 'Membres ({{count}})',
    teams_one: 'Equipe',
    teams_other: 'Equipes',
    'teamsListForUser.empty.description': 'Aucune équipe',
    'teamsListItem.noSubTeams': 'Aucune sous équipe',
    'teamsListItem.subTeamsList': 'Liste des sous équipes:',
    'teamsListItem.subTeamsCount_one': '{{count}} sous équipe',
    'teamsListItem.subTeamsCount_other': '{{count}} sous équipes',
    'teamsListItemMoreOptionsMenu.updateTeamName': 'Modifier le nom',
    'teamsListItemMoreOptionsMenu.moveTeam': 'Déplacer l\'équipe',
    'teamsListItemMoreOptionsMenu.deleteTeam': 'Supprimer l\'équipe',
    'teamMembersListItemMoreOptionsMenu.unsetTeamLeadRole': 'Enlever le rôle Team Lead',
    'teamMembersListItemMoreOptionsMenu.setAsTeamLead': 'Définir comme Team Lead',
    'teamMembersListItemMoreOptionsMenu.removeMemberFromTeam': 'Retirer de l\'équipe',
    'teamSelector.searchNotFound': 'L\'équipe n\'a pas été trouvée',
    'teamSelector.placeholder': 'Toutes les équipes',
    'teamsPage.page.title': 'Toutes les équipes',
    'teamsBreadcrumb.teams': 'Toutes les équipes',
    'termsAndConditionsModal.title': 'Modalités et conditions d\'utilisation',
    'termsAndConditionsModal.linkLabel': 'Modalités et conditions d\'utilisation',
    'termsAndConditionsModal.acknowledge': 'J\'accepte les modalités et conditions d\'utilisation',
    'termsAndConditionsModal.content': 'Nous avons mis à jour nos modalités et conditions d\'utilisation. Merci de lire et revoir les informations présentes dans le lien ci-dessous:',
    'thread.action.changed': 'a modifié',
    'thread.action.commented': 'a commenté',
    'thread.commission.changed': 'La commission a été mis à jour à {{price}}',
    'thread.commission.reset': 'La commission a été réinitialisée à sa valeur originale',
    'thread.commission.dealValue.changed': '{{dealType}} a été mis à jour à {{value}}',
    'thread.commission.dealValue.reset': '{{dealType}} a été réinitialisé(e) à sa valeur originale',
    'thread.status.changed': 'Le statut est passé à {{status}}',
    'thread.statuses.pending': 'En attente',
    'thread.statuses.resolved': 'Résolu',
    'thread.statuses.setTo': 'Définir {{status}}',
    'threadComment.commission.changed': 'a changé le montant de la commission à',
    'threadComment.commission.reset': 'a réinitialisé le montant de la commission à sa valeur originale',
    'threadComment.commission.dealValue.changed': 'a changé le montant du/de la {{dealType}} à',
    'threadComment.commission.dealValue.reset': 'a réinitialisé le montant du/de la {{dealType}} à sa valeur originale',
    'threadComment.payments.changed': 'a modifié les paiements pour',
    'threadComment.payments.reset': 'a réinitialisé les paiements au calendrier original',
    'threadComment.payments.paymentOn': '{{percentage}}% en {{date}}',
    'threadComment.status.changed': 'a changé le statut à',
    'timezoneSelect.options.timeOffsetLabel': 'UTC{{timeOffsetLabel}}',
    'timezoneSelect.timezones': 'Fuseaux horaires',
    'timezoneSelect.timeOffsets': 'Décalages horaires',
    'treeSelect.searchNotFound': 'Aucune correspondance',
    'trialBanner.ends.in': 'Votre période d\'essai se finit {{duration}}.',
    'trialBanner.finished': 'Votre période d\'essai est finie.',
    'trialBanner.startSubscriptionNow': 'Démarrer mon abonnement maintenant',
    'unarchiveNotebookModal.title': 'Déarchiver le Modèle',
    'unarchiveNotebookModal.confirm': 'Je confirme',
    'unarchiveNotebookModal.content': 'Confirmez-vous vouloir désarchiver <strong>{{notebookName}}</strong> ?',
    'unlinkQuotaFromMasterPlan.unlinkQuotaLabel': 'Supprimer le lien avec ce Quota',
    'unlinkQuotaFromMasterPlanModal.title': 'Voulez-vous supprimer le lien entre le Quota et ce Plan ?',
    'unlinkQuotaFromMasterPlanModal.confirm': 'Je confirme',
    'unlinkQuotaFromMasterPlanModal.content': 'Merci de confirmer que vous souhaitez supprimer le lien avec ce Quota. Le plan ne sera plus lié à aucun Quota.',
    'unMakeExportableCellModal.title': 'Ne plus exporter la cellule',
    'unMakeExportableCellModal.confirm': 'Je confirme',
    'unMakeExportableCellModal.content': 'Confirmez-vous vouloir retirer les résultats de <strong>{{cellName}}</strong> de <strong>{{notebookName}}</strong> comme ressources disponible ?',
    'updateTeamNameModal.title': 'Mettre à jour le nom de l\'équipe',
    'updateTeamNameModal.update': 'Mettre à jour',
    'updateTeamNameModal.form.name.label': 'Nom de l\'équipe',
    'updateTeamNameModal.form.name.rules.required': 'Merci de nommer votre Equipe.',
    'updateUserTypeConfirmationModal.title': 'Changement du type d\'utilisateurs',
    'updateUserTypeConfirmationModal.confirm': 'Je confirme',
    'updateUserTypeConfirmationModal.content': 'En changeant le type d\'utilisateurs, vous allez perdre tous les utilisateurs et toutes les données calculées de ce Plan. <strong>Confirmez-vous ce changement ?</strong>',
    'user.email': 'Email',
    'user.status': 'Statut',
    'user.linkedResources': 'Ressources Liées',
    'userAccountSettings.title': 'Compte Utilisateur',
    'userAccountSettings.form.email.label': 'Adresse mail',
    'userAccountSettings.form.email.rules.required': 'Merci de renseigner une adresse mail.',
    'userAccountSettings.form.email.rules.email': 'Merci de fournir une adresse mail valide.',
    'userAccountSettings.form.role.label': 'Rôle',
    'userAccountSettings.form.submitBtnLabel': 'Mettre à jour le compte',
    'userAccountSettings.noAccount': 'Aucun compte n\'est attaché à cet utilisateur.',
    'userDataSettings.title': 'Détails Utilisateur',
    'userDataSettings.form.firstName.label': 'Prénom',
    'userDataSettings.form.firstName.rules.required': 'Merci de renseigner un prénom.',
    'userDataSettings.form.lastName.label': 'Nom',
    'userDataSettings.form.lastName.rules.required': 'Merci de renseigner un nom.',
    'userDataSettings.form.email.label': 'Adresse mail',
    'userDataSettings.form.email.rules.required': 'Merci de renseigner une adresse mail.',
    'userDataSettings.form.email.rules.email': 'Merci de fournir une adresse mail valide.',
    'userDataSettings.form.currency.label': 'Devise',
    'userDataSettings.form.payrollId.label': 'Payroll Id',
    'userDataSettings.form.hireDate.label': 'Date d\'embauche',
    'userDataSettings.form.submitBtnLabel': 'Mettre à jour',
    'userDetailsTabs.tabs.statements': 'Fiches de paie',
    'userDetailsTabs.tabs.plans': 'Plans',
    'userDetailsTabs.tabs.teams': 'Équipes',
    'userDetailsTabs.tabs.quotas': 'Quotas',
    'userDetailsTabs.tabs.dashboardPreview': 'Tableau de bord',
    'userDetailsTabs.tabs.settings': 'Configuration',
    'usersBreadcrumb.users': 'Tous les utilisateurs',
    'userResourcesSelectionPanel.userSearch.placeholder': 'Rechercher un utilisateur',
    'usersSelectedPanel.warning.noNewSelectedUsers': 'Aucun nouvel utilisateur sélectionné.',
    'usersSelectedPanel.removeUser': 'Supprimer',
    'userConnectorsSelect.allUsers': 'Tous les utilisateurs',
    'userConnectorsSelect.resourcesOption.label': '<strong>{{type}}</strong> du connecteur <strong>{{connector}}</strong>',
    'userLinkedResourceItem.unlinkResource.popconfirm.title': 'Confirmez-vous que vous voulez supprimer le lien entre cette ressource et cet utilisateur ?',
    'userLinkedResourceItem.viewLinkedResource': 'Voir la ressource liée',
    'userLinkedResourceItem.viewDetails': 'Détails',
    'userLinkedResourcesSettings.title': 'Ressources Liées',
    'userLinkedResourcesSettings.empty.description': 'Aucune ressource liée.',
    'userOffboardingSettings.title': 'Offboarding',
    'userOffboardingSettings.description': 'Si vous souhaitez retirer cet utilisateur, en cliquant sur Offboard maintenant, vous pourrez définir une date de congé, effectuer un dernier ajustement avec les paiements dus et supprimer l\'utilisateur des plans actuels et futurs.',
    'userOffboardingSettings.userHasLeaveDate': 'La date de départ définie pour {{userDisplayName}} est le {{leaveDate}}.',
    'userOffboardingSettings.resetLeaveDateDescription': 'Si vous souhaitez réinitialiser cette date de départ, en cliquant sur Réinitialiser la date de congé maintenant, vous pourrez supprimer la date de congé de cet utilisateur, mais vous devrez gérer vous-même les plans et éventuellement inviter à nouveau cet utilisateur sur la plateforme.',
    'userOffboardingSettings.resetLeaveDate.popconfirm.title': 'Confirmez-vous la réinitialisation de la date de départ pour cet utilisateur ?',
    'userOffboardingSettings.resetLeaveDate.label': 'Réinitialiser la date de départ pour {{userDisplayName}} maintenant',
    'usersMergePage.page.title': 'Fusion d\'utilisateurs',
    'usersMergePage.page.subtitle': 'Liste des conflits qui empêchent 2 utilisateurs d\'être fusionnés',
    'usersSelectionPanel.userSearch.placeholder': 'Rechercher un utilisateur',
    'userSelector.select.placeholder': 'Sélectionner un utilisateur',
    'userStatementContent.userWillBeHired': 'L\'utilisateur sera embauché le {{date}}',
    'userStatementContent.userWasHired': 'L\'utilisateur a été embauché le {{date}}',
    'userStatementContent.userWasDeactivated': 'L\'utilisateur a été désactivé le {{date}}',
    'userStatementContent.userWillBeDeactivated': 'L\'utilisateur sera désactivé le {{date}}',
    'userStatementContent.tabs.statement': 'Déclaration',
    'userStatementContent.tabs.history': 'Historique des changements',
    'userStatementPlanCollapse.seePlanDetails': 'Voir les détails du plan',
    'userStatementPlanCollapse.total': 'Total ·',
    'userStatementPlanPeriod.seePeriodDetails': 'Voir les détails de la période',
    'userStatementPlanPeriod.total': 'Total période ·',
    'userStatementPlans.dealSearch.placeholder': 'Rechercher par nom de ressource',
    'userStatementPlanPeriodDealsTable.empty.description': 'Aucun {{dealType}} ne correspond à votre recherche',
    'userStatementPeriodDeals.table.headers.commissions': 'Commissions Payées',
    users_one: 'Utilisateur',
    users_other: 'Utilisateurs',
    usersWithCount_zero: '{{count}} $t(users_one)',
    usersWithCount_one: '{{count}} $t(users_one)',
    usersWithCount_other: '{{count}} $t(users_other)',
    usersWithCountToBe_one: '$t(usersWithCount, {"count": {{count}} }) est',
    usersWithCountToBe_other: '$t(usersWithCount, {"count": {{count}} }) sont',
    usersWithCountToBeMissing_one: '$t(usersWithCountToBe, {"count": {{count}} }) manquant',
    usersWithCountToBeMissing_other: '$t(usersWithCountToBe, {"count": {{count}} }) manquants',
    'usersStatementsList.noUserStatement': 'Pas de déclarations d\'utilisateurs.',
    'userStatements.userSearch.placeholder': 'Rechercher un utilisateur',
    'userStatements.title.usersCount': 'Utilisateurs ({{count}})',
    'userStatements.exportAll': 'Tout exporter',
    'userStatements.exportToPayroll': 'Exporter pour {{payrollProviderName}}',
    'userStatements.payrollProviderRestrictedTo': 'Limité à l\'équipe {{team}}',
    'userStatement.table.header.name': 'Nom',
    'userStatement.table.header.commissions': 'Commissions',
    'userStatement.table.header.actions': 'Actions',
    'userStatement.infosAndActions.export': 'Exporter',
    'userStatement.infosAndActions.markAsDirty.button.label': 'Marqué comme à recalculer',
    'userStatement.infosAndActions.markAsDirty.button.popconfirm': 'Êtes-vous sûr de vouloir marquer cette déclaration comme à recalculer ?',
    'userStatementAdjustments.collapse.title': 'Corrections appliquées ({{count}})',
    'userStatementAdjustments.collapse.total': 'Total ·',
    'userStatementAdjustments.collapse.addAdjustmentButtonLabel': 'Ajouter une correction',
    'userStatementAdjustments.collapse.reasonLabel': 'Raison',
    'userStatementAdjustments.collapse.amountLabel': 'Montant',
    'userStatementAdjustments.collapse.whenLabel': 'Correction ajoutée le',
    'userStatementAdjustments.collapse.alreadyPaid': 'Vous ne pouvez pas effectuer de corrections car le relevé a déjà été marqué comme payé',
    'userStatementAdjustments.actions.delete.popconfirm': 'Êtes-vous sûr de vouloir retirer cette correction ?',
    'userStatementAdjustments.addEditAdjustment.modal.edit.title': 'Mettre à jour une correction',
    'userStatementAdjustments.addEditAdjustment.modal.add.title': 'Ajouter une correction',
    'userStatementAdjustments.addEditAdjustment.modal.approve.title': 'Approuver une correction',
    'userStatementAdjustments.addEditAdjustment.modal.commissions.label': 'Commissions totales',
    'userStatementAdjustments.addEditAdjustment.save.edit.label': 'Mettre à jour la correction',
    'userStatementAdjustments.addEditAdjustment.save.add.label': 'Ajouter la correction',
    'userStatementAdjustments.addEditAdjustment.save.approve.label': 'Approuver la correction',
    'userStatementAdjustments.addEditAdjustment.reason.label': 'Motif de la correction',
    'userStatementAdjustments.addEditAdjustment.reason.placeholder': 'Motif de la correction',
    'userStatementAdjustments.addEditAdjustment.amount.label': 'Montant de la correction (peut être négatif)',
    'userStatementAdjustments.addEditAdjustment.amount.error.message': 'Veuillez inscrire un montant.',
    'userStatementAdjustments.addEditAdjustment.reason.error.message': 'Veuillez inscrire un motif.',
    'userStatementCorrections.collapse.title': 'Corrections suggérées ({{count}})',
    'userStatementCorrections.content.default.reason': 'Correction de déclaration {{dates}}',
    'userStatementCorrections.actions.investigate': 'Investiguer',
    'userStatementCorrections.actions.createAdjustment': 'Approuver',
    'userStatementCorrections.actions.ignore': 'Rejeter',
    'userStatementCorrections.actions.ignore.confirm': 'Êtes-vous sûr de vouloir rejeter cette correction ?',
    'userStatementCorrections.actions.apply.confirm': 'Êtes-vous sûr de vouloir accepter cette correction ?',
    'userStatementInvestigateList.title': 'Différences dans les déclarations ({{count}})',
    'userStatementInvestigateList.column.name': 'Nom',
    'userStatementInvestigateList.column.plan': 'Nom du plan',
    'userStatementInvestigateList.column.period': 'Période',
    'userStatementInvestigateList.column.was': 'Etait',
    'userStatementInvestigateList.column.is': 'Est',
    'userStatementInvestigateMain.title': 'Différences entre les déclarations',
    'userStatementInvestigateMain.empty.text': 'Sélectionner le nom du deal pour en voir les détails',
    'userStatus.wasDeactivated': 'L\'utilisateur a été désactivé le {{date}}',
    'userStatus.willBeDeactivated': 'L\'utilisateur sera désactivé le {{date}}',
    'userStatus.invitationIsPending': 'L\'invitation est en attente',
    'userStatus.noRole': 'Aucun rôle',
    'userStatus.withRole': 'Rôle : <strong>{{roleName}}</strong>',
    'usersPage.page.title': 'Tous les Utilisateurs',
    'usersPage.mergeUsers': 'Fusionner les utilisateurs',
    'usersPageContent.userSearch.placeholder': 'Rechercher un utilisateur',
    'usersPageContent.onlyActiveFilter.label': 'Seulement les utilisateurs actifs',
    'usersPageContent.resetFilters.label': 'Réinitialiser les filtres',
    'usersPageContent.viewLinkedResource': 'Voir la ressource liée',
    'userVariableArrayValueInput.addNewItem': 'Ajouter un nouvel élément',
    'userVariableArrayValueInput.deleteItem.popconfirm': 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    'userVariableArrayValueInput.emptyList': 'Liste vide',
    'usersVariables.createNewVariable': 'Nouvelle Variable',
    'usersVariablesPage.page.title': 'Variables des Utilisateurs',
    'usersVariablesPageContent.userSearch.placeholder': 'Rechercher un utilisateur',
    'usersVariablesPageContent.onlyActiveFilter.label': 'Seulement les utilisateurs actifs',
    'usersVariablesPageContent.resetFilters.label': 'Réinitialiser les filtres',
    'usersVariablesPageContent.dateFilter.label': 'Afficher les valeurs pour le',
    'usersVariablesTableBodyCell.editValue.tooltip': 'Mettre à jour la valeur',
    'usersVariablesTableBodyCell.showHistory.tooltip': 'Voir l\'historique',
    'usersVariables.types.string': 'Texte',
    'usersVariables.types.number': 'Nombre',
    'usersVariables.types.date': 'Date (UTC+0)',
    'usersVariables.types.boolean': 'Booléen',
    'usersVariables.types.array': 'Liste',
    'variablesForm.form.addAVariable': 'Ajouter une variable',
    'variablesForm.value.title': 'Valeur',
    'variablesForm.value.placeholder': 'Valeur de la variable (formule)',
    'variablesForm.name.title': 'Nom',
    'variablesForm.name.placeholder': 'Nom de la variable',
    'variablesForm.fx.tooltip': 'Ajouter une condition SI',
    'variablesForm.delete.tooltip': 'Supprimer la variable',
    'variablesForm.condition.placeholder': 'Condition (ex Deal.xxx === yyy)',
    'variablesForm.delete.condition.tooltip': 'Supprimer la condition',
    'variablesForm.addACondition': 'Ajouter une condition',
    'widgetBase.tooltips.moveLeft': 'Déplacer le widget sur la gauche',
    'widgetBase.tooltips.moveRight': 'Déplacer le widget sur la droite',
    'widgetBase.tooltips.remove': 'Supprimer le widget du tableau de bord',
    'widgetPeriodYearSelector.useMostRecent': 'Utiliser la période la plus récente',
    'widgets.highlight.commissions_total.name': 'Total commissions',
    'widgets.highlight.commissions_unreleased.name': 'Commissions sans aucun paiement prévu',
    'widgets.highlight.commissions_due_estimate.name': 'Futur paiement',
    'widgets.highlight.commissions_total.shortname': 'Total commissions',
    'widgets.highlight.commissions_unreleased.shortname': 'Commissions sans paiement',
    'widgets.highlight.commissions_due_estimate.shortname': 'Futur paiement',
    'widgets.plan_progress_gauge.name': 'Progression dans le Plan',
    'widgets.plan_progress_gauge.description': 'Progression dans votre plan, voyez si vous êtes en retard, sur la bonne voie ou en avance sur le calendrier.',
    'widgets.plan_payout_schedule.name': 'Calendrier de Paiement',
    'widgets.plan_payout_schedule.description': 'Calendrier futur de paiement pour le plan sélectionné.',
    'widgets.plan_progress_bar_chart.name': 'Progression au fil des mois',
    'widgets.plan_progress_bar_chart.description': 'Obtenez votre progression au cours du mois pour les plans de plusieurs mois.',
    'widgets.plan_total_commissions.name': 'Total des commissions d\'un plan',
    'widgets.plan_total_commissions.description': 'Total des commissions que vous recevez pour ce plan.',
    'widgets.plan_total_value.name': 'Valeur totale du plan',
    'widgets.plan_total_value.description': 'Valeur totale atteinte dans le plan jusqu\'à présent.',
    'widgets.plan_count_objects.name': 'Nombre d\'éléments',
    'widgets.plan_count_objects.description': 'Nombre d\'éléments pris en compte dans le plan sélectionné.',
    'widgets.plan_details.name': 'Détails du plan',
    'widgets.plan_details.description': 'Total des commissions et nombre d\'éléments dans ce plan.',
    'widgets.statement_one_month.name': 'Relevé actuelle',
    'widgets.statement_one_month.description': 'Montant du relevé pour le mois en cours.',
    'widgets.statement_per_month.name': 'Relevé de trois mois',
    'widgets.statement_per_month.description': 'Relevés du mois précédent, en cours et suivant.',
    'widgets.list_plans.name': 'Plans en cours',
    'widgets.list_plans.description': 'Liste des plans actifs.',
    'widgets.latest_objects.name': 'Dernières commissions',
    'widgets.latest_objects.description': 'Derniers éléments synchronisés avec Palette et leur commission',
    'widgets.plan_what_if.name': 'Projection',
    'widgets.plan_what_if.description': 'Simulation du gain potentiel pour ce plan.',
    'widgets.commissions_unreleased_list.name': 'Éléments en attente ou partiellement payés',
    'widgets.commissions_unreleased_list.description': 'Derniers éléments en attente ou partiellement payés',
    'widgets.commissions_unreleased.name': 'Commissions pas encore libérées',
    'widgets.commissions_unreleased.description': 'Total des commissions sans aucun paiement prévu',
    'widgets.highlight.progress.name': 'Progression',
    'widgets.highlight.progress.shortname': 'Progression',
    'widgets.highlight.total_value.name': 'Valeur totale',
    'widgets.highlight.total_value.shortname': 'Total {{valueDefinition}}',
    'plansV3.createNewPlan.btnLabel': 'Créer un nouveau plan',
    'plansV3.createNewFolder.btnLabel': 'Créer un nouveau dossier',
    'plansV3.periodicity.MONTH': 'Mensuel',
    'plansV3.periodicity.QUARTER': 'Trimestriel',
    'plansV3.periodicity.SEMESTER': 'Semestriel',
    'plansV3.periodicity.YEAR': 'Annuel',
    'plansV3.periodicity.DAY': 'Journalier',
    'plansV3.periodicity.CUSTOM': 'Personnalisé',
    'plansV3.status.DRAFT': 'Brouillon',
    'createPlanPage.title': 'Créer un nouveau plan',
    'createPlanPage.subtitle.top': 'Étape 1 sur 3',
    'createPlanPage.section1.title': 'Général',
    'createPlanPage.section1.subtitle': 'Description',
    'createPlanPage.section2.title': 'Utilisateurs',
    'createPlanPage.section2.subtitle': 'Sélectionnez tous les utilisateurs qui bénéficieront de ces commissions de plan',
    'createPlanPage.section3.title': 'Sources de données',
    'createPlanPage.section3.subtitle': 'Ajoutez toutes les ressources que vous souhaitez utiliser pour ce plan',
    'createPlanPage.form.submit.label': 'Étape suivante',
    'updatePlanPage.form.submit.label': 'Sauvegarder & étape suivante',
    'createPlanPage.form.name.label': 'Nom du plan',
    'createPlanPage.form.name.placeholder': 'Ex: Plan pour AEs',
    'createPlanPage.form.name.rules.required': 'Merci de nommer votre Plan.',
    'createPlanPage.form.description.label': 'Description du plan',
    'createPlanPage.form.description.placeholder': 'Ce plan est pour...',
    'createPlanPage.form.frequency.label': 'Fréquence du plan',
    'createPlanPage.form.frequency.rules.required': 'Veuillez sélectionner une fréquence.',
    'createPlanPage.form.frequency.option.MONTH': 'Mensuel',
    'createPlanPage.form.frequency.option.QUARTER': 'Trimestriel',
    'createPlanPage.form.frequency.option.SEMESTER': 'Semestriel',
    'createPlanPage.form.frequency.option.YEAR': 'Annuel',
    'createPlanPage.form.frequency.option.DAY': 'Tous les X jours',
    'createPlanPage.form.frequency.option.CUSTOM': 'Dates personnalisées',
    'createPlanPage.form.startPeriod.label': 'Date de début',
    'createPlanPage.form.startPeriod.rules.required': 'Veuillez sélectionner une date de début.',
    'createPlanPage.form.endPeriod.label': 'Date de fin',
    'createPlanPage.form.endPeriod.rules.required': 'Veuillez sélectionner une date de fin.',
    'createPlanPage.form.dayInterval.label': 'Durée d\'une période en jours (7 pour un plan hebdomadaire)',
    'createPlanPage.form.dayInterval.rules.required': 'Veuillez sélectionner un intervalle.',
    'createPlanPage.form.remove.endPeriod.label': 'Ne pas utiliser de date de fin',
    'createPlanPage.form.listUsers.countSelected': '$t(usersWithCount, {"count": {{usersCount}} }) sélectionné(s)',
    'createPlanPage.form.datasources.list.title': 'Modèles de ressources',
    'createPlanPage.inputTables.title': 'Tableaux avancés',
    'editPlanPage.subtitle.top.step2': 'Étape 2 sur 3',
    'editPlanPage.subtitle.top.step3': 'Étape 3 sur 3',
    'plansV3.linkTo.requirements.tooltip': 'Besoins du plan',
    'plansV3.editAchievementPage.leftSide.header': 'Contexte du plan',
    'editPlanPage.header.saved.time': 'Sauvegardé {{diffForHumans}}',
    'editPlanPage.footer.back.previous.step': 'Retour à l\'étape précédente',
    'editPlanPage.footer.next.step': 'Prochaine étape',
    'editPlanPage.footer.save.step': 'Sauvegarder',
    'editPlanPage.context.variables.source.CRM': 'Ressources CRM',
    'editPlanPage.context.variables.source.PALETTE': 'Ressources Palette',
    'editPlanPage.context.variables.source.MODELS': 'Ressources Notebook',
    'editPlanPage.context.summary.title': 'Besoins',
    'editPlanPage.context.requirements.start': 'Début',
    'editPlanPage.context.requirements.end': 'Fin',
    'editPlanPage.context.requirements.frequency': 'Périodicité',
    'editPlanPage.achievements.title': 'Génération de quotas',
    'editPlanPage.achievements.docLabel': 'Documentation et exemples d\'accès',
    'editPlanPage.achievements.description': 'Définissez des règles de quota pour déterminer les performances des vendeurs, des équipes ou des organisations au cours d\'une période donnée.',
    'editPlanPage.achievements.alert.title': 'Résultats attendus',
    'editPlanPage.achievements.runQuery': 'Exécuter la requête',
    'editPlanPage.commissions.title': 'Génération de commissions',
    'editPlanPage.commissions.docLabel': 'Documentation et exemples d\'accès',
    'editPlanPage.commissions.description': 'Écrire une logique Python/SQL qui peut utiliser les informations relatives au contexte du plan, aux réalisations, aux résultats et aux périodes.',
    'editPlanPage.commissions.alert.title': 'Résultats attendus',
    'editPlanPage.commissions.runQuery': 'Exécuter la requête',
    'editPlanPage.results.periods.title': 'Périodes évaluées',
    'editPlanPage.results.periods.subtitle': 'Automatiquement générées à partir des besoins',
    'editPlanPage.results.periods.table.periodId': 'Period Id',
    'editPlanPage.results.periods.table.start': 'Début',
    'editPlanPage.results.periods.table.end': 'Fin',
    'editPlanPage.results.periods.table.codeValue': 'Code',
    'editPlanPage.results.achievements.title': 'Performances',
    'editPlanPage.results.achievements.subtitle': 'Automatiquement générées à partir des besoins',
    'editPlanPage.results.achievements.user': 'Résultats de performance des Utilisateurs',
    'editPlanPage.results.achievements.deal': 'Résultats de performance des Deals',
    'editPlanPage.results.achievements.deal.table.periodId': 'Période',
    'editPlanPage.results.periods.table.dealId': 'Deal',
    'editPlanPage.results.periods.table.userId': 'Utilisateur',
    'editPlanPage.results.periods.table.targetType': 'Type',
    'editPlanPage.results.periods.table.quota': 'Quota',
    'editPlanPage.results.periods.table.value': 'Valeur',
    'editPlanPage.results.periods.01': 'Janvier',
    'editPlanPage.results.periods.02': 'Février',
    'editPlanPage.results.periods.03': 'Mars',
    'editPlanPage.results.periods.04': 'Avril',
    'editPlanPage.results.periods.05': 'Mai',
    'editPlanPage.results.periods.06': 'Juin',
    'editPlanPage.results.periods.07': 'Juillet',
    'editPlanPage.results.periods.08': 'Août',
    'editPlanPage.results.periods.09': 'Septembre',
    'editPlanPage.results.periods.10': 'Octobre',
    'editPlanPage.results.periods.11': 'Novembre',
    'editPlanPage.results.periods.12': 'Décembre',
    'editPlanPage.results.periods.inputTables.title': 'Tableaux avancés',
    'editPlanPage.results.advancedOptions.title': 'Options avancées',
    'editPlanPage.results.advancedOptions.subtitle': 'Créer des tableaux addionnels si nécessaire',
    'userStatementV3.commissions.collapse.title': 'Commissions ({{count}})',
    'userStatementV3.commissions.header.plan': 'Plan',
    'userStatementV3.commissions.header.dealId': 'Deal Id',
    'userStatementV3.commissions.header.amount': 'Amount',
    'userStatementV3.commissions.header.date': 'Date',
  },
};

export default fr;
