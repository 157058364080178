import PropTypes from 'prop-types';

import { manageAmountAttribute, manageNumberAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'PlanDetailsData';

export const propTypes = PropTypes.shape({
  totalObjects: PropTypes.number,
  totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
});

export const transform = (planDetailsData) => {
  if (!planDetailsData) {
    return null;
  }

  return (
    {
      totalObjects: manageNumberAttribute(planDetailsData, 'dealsCount'),
      totalCommissionAmount: manageAmountAttribute(planDetailsData, 'totalCommissionAmount'),
      currency: manageStringAttribute(planDetailsData, 'currency'),
    }
  );
};
