import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import NameFormItem from '@palette/components/planV3/planV3FormItems/NameFormItem/NameFormItem';
import DescriptionFormItem from '@palette/components/planV3/planV3FormItems/DescriptionFormItem/DescriptionFormItem';
import FrequencyFormItem from '@palette/components/planV3/planV3FormItems/FrequencyFormItem/FrequencyFormItem';
import StartPeriodFormItem from '@palette/components/planV3/planV3FormItems/StartPeriodFormItem/StartPeriodFormItem';
import EndPeriodFormItem from '@palette/components/planV3/planV3FormItems/EndPeriodFormItem/EndPeriodFormItem';
import DayIntervalFormItem from '@palette/components/planV3/planV3FormItems/DayIntervalFormItem/DayIntervalFormItem';

import { PLAN_PERIODICITY_TYPE } from '@palette/constants/planV3';

import styles from './PlanV3FormRequirementsGeneral.less';

const classNames = bindClassNames.bind(styles);

const PlanV3FormRequirementsGeneral = ({ className, frequencyType }) => {
  const [showEndPeriodField, setShowEndPeriodField] = useState(frequencyType === PLAN_PERIODICITY_TYPE.CUSTOM);

  useEffect(() => {
    if (frequencyType === PLAN_PERIODICITY_TYPE.CUSTOM) {
      setShowEndPeriodField(true);
    }
  }, [frequencyType]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <NameFormItem disabled />
          <FrequencyFormItem disabled />
          {frequencyType === PLAN_PERIODICITY_TYPE.DAY && <DayIntervalFormItem disabled />}
        </div>
        <div className={classNames(styles.row, styles.orderFirst)}>
          <DescriptionFormItem disabled />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.row}>
          <StartPeriodFormItem disabled />
        </div>
        <div className={styles.row}>
          {showEndPeriodField && (
            <EndPeriodFormItem disabled isRequired={frequencyType === PLAN_PERIODICITY_TYPE.CUSTOM} />
          )}
        </div>
      </div>
    </div>
  );
};

PlanV3FormRequirementsGeneral.propTypes = {
  className: PropTypes.string,
  frequencyType: PropTypes.oneOf(Object.values(PLAN_PERIODICITY_TYPE)),
};

PlanV3FormRequirementsGeneral.defaultProps = {
  className: '',
  frequencyType: PLAN_PERIODICITY_TYPE.MONTH,
};

export default PlanV3FormRequirementsGeneral;
