import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { getLocalTimezone } from '@palette/helpers/MomentHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

import * as MasterPlansService from '@palette/services/MasterPlansService';

export function* getDashboardPresetsList() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.getList, undefined, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* getById(payload) {
  const { presetId } = payload;

  const data = { presetId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.getById, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* updateDashboardPreset(payload) {
  const {
    presetId,
    name = undefined,
    dashboardComponents = undefined,
  } = payload;

  const data = {
    presetId,
    name,
    components: dashboardComponents,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.updateDashboardPreset, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* createDashboardPreset() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.createDashboardPreset, {}, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* getPresetCandidateUsers() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.getPresetCandidateUsers, {}, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* getPresetDataForCandidateUser(payload) {
  const {
    presetId,
    userId,
  } = payload;

  const timezone = getLocalTimezone();
  const data = {
    presetId,
    userId,
    timezone,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.previewDashboardForUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.VIEW],
  );
}

export function* getPlanListForCandidateUser(payload) {
  const { candidateUser } = payload;

  return yield MasterPlansService.getPlanListForUser({ user: candidateUser });
}

export function* deleteById(payload) {
  const { presetId } = payload;

  const data = {
    presetId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.deleteById, data, getConfig()),
    [RIGHTS.ADMIN.COMPANY.MANAGE],
  );
}

export function* applyDashboardPresetToUsers(payload) {
  const { presetId, selectedUsers } = payload;

  const data = selectedUsers.map((selectedUser) => ({
    presetId,
    _id: selectedUser.id,
  }));

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.dashboardPresets.applyToUsers, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}
