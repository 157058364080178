import PropTypes from 'prop-types';

import { manageFreeShapeObjectAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'UserStatementCorrectionDetails';

export const propTypes = PropTypes.shape({
  data: PropTypes.object,
});

export const transform = (userStatementCorrectionDetail) => {
  if (!userStatementCorrectionDetail) {
    return null;
  }

  return ({
    data: manageFreeShapeObjectAttribute({ details: userStatementCorrectionDetail }, 'details'),
  });
};

export const transformList = (userStatementCorrectionDetails) => userStatementCorrectionDetails.map((userStatementCorrectionDetail) => transform(userStatementCorrectionDetail));
