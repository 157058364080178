import AustralianDollarLine from '@palette/components/utils/Icons/AustralianDollarLine';
import CanadianDollarLine from '@palette/components/utils/Icons/CanadianDollarLine';
import DollarLine from '@palette/components/utils/Icons/DollarLine';
import EuroLine from '@palette/components/utils/Icons/EuroLine';
import KoreanWonLine from '@palette/components/utils/Icons/KoreanWonLine';
import PoundLine from '@palette/components/utils/Icons/PoundLine';
import RubleLine from '@palette/components/utils/Icons/RubleLine';
import RupeeLine from '@palette/components/utils/Icons/RupeeLine';
import YenLine from '@palette/components/utils/Icons/YenLine';

export const CURRENCIES = {
  EUR: 'EUR',
  USD: 'USD',
  XAF: 'XAF',
};

export const CURRENCIES_ICONS = {
  AUD: AustralianDollarLine,
  CAD: CanadianDollarLine,
  USD: DollarLine,
  EUR: EuroLine,
  KPW: KoreanWonLine,
  KRW: KoreanWonLine,
  GBP: PoundLine,
  RUB: RubleLine,
  INR: RupeeLine,
  JPY: YenLine,
};

export const MIXED_CURRENCY_VALUE = 'Mixed';
