import getI18n from '@palette/i18n';

import {
  ADD_DATA_CONNECTION_FLOW_ORDERED_STEPS,
} from '@palette/constants/notebooks';

export const getAddDataConnectionFlowStepIndex = (currentStepValue) => {
  const stepsByValueWithIndex = {};
  ADD_DATA_CONNECTION_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByValueWithIndex[step.value] = index;
  });

  return stepsByValueWithIndex[currentStepValue] || 0;
};

export const getNextAddDataConnectionFlowStep = (currentStepValue) => {
  const stepsByValueWithNext = {};
  ADD_DATA_CONNECTION_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByValueWithNext[step.value] = {
      step,
      next: ADD_DATA_CONNECTION_FLOW_ORDERED_STEPS[index + 1] || null,
    };
  });

  return stepsByValueWithNext[currentStepValue].next || null;
};

export const generateNewCellName = (notebook, cellType) => {
  const i18n = getI18n();
  const cellTypeLabel = i18n.t(cellType.i18n);

  const sameTypeCells = notebook.cells.filter((cell) => (cell.type === cellType.id));
  let suffixNumber = sameTypeCells.length;
  let generatedName = `${cellTypeLabel} ${suffixNumber}`;

  const cellsOutputNames = notebook.cells.map((cell) => (cell.outputName));

  while (cellsOutputNames.includes(generatedName.trim().replace(/\s+/g, '_'))) {
    suffixNumber += 1;
    generatedName = `${cellTypeLabel} ${suffixNumber}`;
  }

  return generatedName;
};
