import { PasswordMeter } from 'password-meter';

import appConfig from '@palette/config/app';

export const hasMinimumLength = (value) => {
  const result = new PasswordMeter({
    minLength: appConfig.PASSWORD_MIN_LENGTH,
  }).getResult(value);

  return result.score !== -1;
};

export const hasUppercaseLetters = (value) => {
  const result = new PasswordMeter({
    uppercaseLettersMinLength: appConfig.PASSWORD_UPPERCASE_LETTERS_QUANTITY,
  }).getResult(value);

  return result.score !== -1;
};

export const hasLowercaseLetters = (value) => {
  const result = new PasswordMeter({
    lowercaseLettersMinLength: appConfig.PASSWORD_LOWERCASE_LETTERS_QUANTITY,
  }).getResult(value);

  return result.score !== -1;
};

export const hasNumbers = (value) => {
  const result = new PasswordMeter({
    numbersMinLength: appConfig.PASSWORD_NUMBERS_QUANTITY,
  }).getResult(value);

  return result.score !== -1;
};

export const hasSymbols = (value) => {
  const result = new PasswordMeter({
    symbolsMinLength: appConfig.PASSWORD_SYMBOLS_QUANTITY,
  }).getResult(value);

  return result.score !== -1;
};

export const isValidPassword = (value) => {
  const result = new PasswordMeter({
    minLength: appConfig.PASSWORD_MIN_LENGTH,
    uppercaseLettersMinLength: appConfig.PASSWORD_UPPERCASE_LETTERS_QUANTITY,
    lowercaseLettersMinLength: appConfig.PASSWORD_LOWERCASE_LETTERS_QUANTITY,
    numbersMinLength: appConfig.PASSWORD_NUMBERS_QUANTITY,
    symbolsMinLength: appConfig.PASSWORD_SYMBOLS_QUANTITY,
  }).getResult(value);

  return result.score !== -1;
};
