import {
  call,
  put,
  all,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks/slice';
import { actions as ConnectorsActions } from '@palette/state/Connectors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';
import { manageError as manageNotebooksError } from '@palette/state/Notebooks/errors';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as NotebookModel from '@palette/models/Notebook';
import * as PaginationModel from '@palette/models/Pagination';
import * as NotebooksStatsModel from '@palette/models/NotebooksStats';
import * as NotebookDataframeModel from '@palette/models/NotebookDataframe';

import * as NotebooksService from '@palette/services/NotebooksService';
import * as AsyncJobModel from '@palette/models/AsyncJob';
import { waitJob } from '@palette/state/AsyncJobs/sagas';

export function* listNotebooks({ payload = {} }) {
  const {
    filter,
    page,
    limit,
  } = payload;

  const callData = {
    filter,
    page,
    limit,
  };

  const callResult = yield call(NotebooksService.listNotebooks, callData);

  if (callResult.ok) {
    const notebooksList = NotebookModel.transformList(callResult.data.data);
    const notebooksListPagination = PaginationModel.transform(callResult.data.pagination);
    const notebooksListStats = NotebooksStatsModel.transform(callResult.data.summaries);

    yield put(NotebooksActions.setNotebooksList({
      callData,
      notebooksList,
      notebooksListPagination,
      notebooksListStats,
    }));
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.listNotebooksCompleted());
}

export function* refreshLastFetchedList() {
  const { filter, page, limit } = yield select(NotebooksSelectors.getNotebooksLastListCallData);

  yield put(NotebooksActions.listNotebooks({ filter, page, limit }));
  yield take(NotebooksActions.listNotebooksCompleted.type);
}

export function* copyNotebook({ payload = {} }) {
  const {
    notebookId,
    name,
    onSuccessCB = null,
  } = payload;

  const callData = {
    notebookId,
    name,
  };

  const callResult = yield call(NotebooksService.copyNotebook, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(callResult.data.notebookId);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.copyNotebookCompleted());
}

export function* archiveNotebook({ payload = {} }) {
  const {
    notebookId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    notebookId,
  };

  const callResult = yield call(NotebooksService.archiveNotebook, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield call(refreshLastFetchedList);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.NOTEBOOK_ARCHIVE_SUCCESS));
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.archiveNotebookCompleted());
}

export function* unarchiveNotebook({ payload = {} }) {
  const {
    notebookId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    notebookId,
  };

  const callResult = yield call(NotebooksService.unarchiveNotebook, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield call(refreshLastFetchedList);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.NOTEBOOK_UNARCHIVE_SUCCESS));
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.unarchiveNotebookCompleted());
}

export function* deleteNotebook({ payload = {} }) {
  const {
    notebookId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    notebookId,
  };

  const callResult = yield call(NotebooksService.deleteNotebook, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield call(refreshLastFetchedList);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.NOTEBOOK_DELETION_SUCCESS));
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.deleteNotebookCompleted());
}

export function* createNotebook({ payload = {} }) {
  const {
    name,
    description,
    onSuccessCB = null,
  } = payload;

  const callData = {
    name,
    description,
  };

  const callResult = yield call(NotebooksService.createNotebook, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(callResult.data.notebookId);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.createNotebookCompleted());
}

export function* getNotebookById({ payload = {} }) {
  const { notebookId } = payload;
  const callData = {
    notebookId,
  };

  const callResult = yield call(NotebooksService.getNotebookById, callData);

  if (callResult.ok) {
    const notebook = NotebookModel.transform(callResult.data);

    yield put(NotebooksActions.setNotebook({ notebook }));
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.getNotebookByIdCompleted());
}

export function* editNotebook({ payload = {} }) {
  const { notebookId, name, description } = payload;

  const callData = {
    notebookId,
    name,
    description,
  };

  const callResult = yield call(NotebooksService.editNotebook, callData);

  if (callResult.ok) {
    yield put(NotebooksActions.getNotebookById({ notebookId }));
    yield take(NotebooksActions.getNotebookByIdCompleted.type);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.editNotebookCompleted());
}

export function* addCell({ payload = {} }) {
  const { notebook, cellTypeId, position } = payload;

  const callData = {
    notebook,
    cellTypeId,
    position,
  };

  const callResult = yield call(NotebooksService.addCell, callData);

  if (callResult.ok) {
    yield put(NotebooksActions.getNotebookById({ notebookId: notebook.id }));
    yield take(NotebooksActions.getNotebookByIdCompleted.type);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.editNotebookCompleted());
}

export function* updateCell({ payload = {} }) {
  const { notebookId, updatedCell, onSuccessCB = null } = payload;

  const callData = {
    notebookId,
    updatedCell,
  };

  const callResult = yield call(NotebooksService.updateCell, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(NotebooksActions.getNotebookById({ notebookId }));
    yield take(NotebooksActions.getNotebookByIdCompleted.type);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
    yield put(NotebooksActions.updateCellError({ notebookId, cellId: updatedCell.id, error }));
  }

  yield put(NotebooksActions.updateCellCompleted({ notebookId, cellId: updatedCell.id }));
}

export function* deleteNotebookCell({ payload = {} }) {
  const { notebookId, notebookCellId } = payload;

  const callData = {
    notebookId,
    notebookCellId,
  };

  const callResult = yield call(NotebooksService.deleteCell, callData);

  if (callResult.ok) {
    yield put(NotebooksActions.getNotebookById({ notebookId }));
    yield take(NotebooksActions.getNotebookByIdCompleted.type);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.deleteNotebookCellCompleted());
}

export function* addDataConnectionConnector({ payload = {} }) {
  const {
    notebookId,
    connectorId,
    connectorName,
    resourceType,
    columns,
    onSuccessCB = null,
  } = payload;

  const callData = {
    notebookId,
    connectorId,
    connectorName,
    resourceType,
    columns,
  };

  const callResult = yield call(NotebooksService.addDataConnectionConnector, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.NOTEBOOK_ADD_NEW_DATA_SUCCESS));

    yield put(NotebooksActions.getNotebookById({ notebookId }));
    yield take(NotebooksActions.getNotebookByIdCompleted.type);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.addDataConnectionConnectorCompleted());
}

export function* editDataConnectionConnector({ payload = {} }) {
  const {
    notebookId,
    dataConnectionConnector,
    columns,
    onSuccessCB = null,
  } = payload;

  const callData = {
    notebookId,
    dataConnectionConnector,
    columns,
  };

  const callResult = yield call(NotebooksService.editDataConnectionConnector, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.NOTEBOOK_EDIT_DATA_SUCCESS));

    yield put(NotebooksActions.getNotebookById({ notebookId }));
    yield take(NotebooksActions.getNotebookByIdCompleted.type);
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.editDataConnectionConnectorCompleted());
}

export function* previewDataConnectionConnectorResults({ payload = {} }) {
  const {
    connectorId,
    resourceType,
    columns,
  } = payload;

  const callData = {
    connectorId,
    resourceType,
    columns,
  };

  const callResult = yield call(NotebooksService.previewDataConnectionConnectorResults, callData);

  if (callResult.ok) {
    const dataframe = NotebookDataframeModel.transform(callResult.data);
    yield put(NotebooksActions.setPreviewDataConnectionConnectorResults({ dataframe }));
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(NotebooksActions.previewDataConnectionConnectorResultsCompleted());
}

export function* runCellSuccess({ notebookId }) {
  yield put(NotebooksActions.getNotebookById({ notebookId }));
  yield take(NotebooksActions.getNotebookByIdCompleted.type);

  yield put(ConnectorsActions.setRefreshListIsNeeded({ isNeeded: true }));
}

export function* runCellError(notebookId, cellId, { error }) {
  yield put(NotebooksActions.updateCellError({ notebookId, cellId, error }));
}

export function* runCellCompleted({ notebookId, cellId }) {
  yield put(NotebooksActions.runCellCompleted({ notebookId, cellId }));
}

export function* runCell({ payload = {} }) {
  const { notebookId, cell } = payload;

  const callData = {
    notebookId,
    cellId: cell.id,
  };

  const callResult = yield call(NotebooksService.runCell, callData);

  if (callResult.ok) {
    const job = AsyncJobModel.transform(callResult.data);
    yield spawn(waitJob, {
      job,
      successSaga: () => getNotebookById({ payload: { notebookId } }),
      errorSaga: () => getNotebookById({ payload: { notebookId } }),
      completedSaga: () => runCellCompleted({ notebookId, cellId: cell.id }),
    });
  } else {
    const error = manageNotebooksError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
    yield put(NotebooksActions.runCellCompleted({ notebookId, cellId: cell.id }));
  }
}

export function* copyConnectionConnectorDataframeNameToClipboard({ payload = {} }) {
  const {
    dataConnectionConnector,
  } = payload;

  try {
    yield call(() => (navigator.clipboard.writeText(dataConnectionConnector.dataframeName)));
    yield put(GlobalNotifActions.addGlobalNotif({
      message: {
        code: GLOBAL_NOTIF_REASONS.NOTEBOOK_COPY_TO_CLIPBOARD_SUCCESS.code,
        context: {
          dataframeName: dataConnectionConnector.dataframeName,
        },
      },
    }));
  } catch (e) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.NOTEBOOK_COPY_TO_CLIPBOARD_ERROR));
  }
}

export function* updateAndRunCell({ payload = {} }) {
  const { notebookId, updatedCell } = payload;

  yield put(NotebooksActions.updateCellError({ notebookId, cellId: updatedCell.id, error: null }));

  const editNotebookIsPending = yield select(NotebooksSelectors.editNotebookIsPending);
  if (!editNotebookIsPending) {
    yield put(NotebooksActions.updateCell({ notebookId, updatedCell }));
  }
  yield take(NotebooksActions.updateCellCompleted.type);

  const error = yield select((state) => NotebooksSelectors.getErrorsByCellId(state, {
    notebookId,
    cellId: updatedCell.id,
  }));

  if (error) {
    return;
  }

  yield put(NotebooksActions.runCell({ notebookId, cell: updatedCell }));
}

export function* loop() {
  yield all([
    takeLatest(NotebooksActions.listNotebooks.type, listNotebooks),
    takeLatest(NotebooksActions.copyNotebook.type, copyNotebook),
    takeLatest(NotebooksActions.archiveNotebook.type, archiveNotebook),
    takeLatest(NotebooksActions.unarchiveNotebook.type, unarchiveNotebook),
    takeLatest(NotebooksActions.deleteNotebook.type, deleteNotebook),
    takeLatest(NotebooksActions.createNotebook.type, createNotebook),
    takeLatest(NotebooksActions.getNotebookById.type, getNotebookById),
    takeLatest(NotebooksActions.editNotebook.type, editNotebook),
    takeLatest(NotebooksActions.addCell.type, addCell),
    takeLatest(NotebooksActions.updateCell.type, updateCell),
    takeLatest(NotebooksActions.deleteNotebookCell.type, deleteNotebookCell),
    takeLatest(NotebooksActions.addDataConnectionConnector.type, addDataConnectionConnector),
    takeLatest(NotebooksActions.editDataConnectionConnector.type, editDataConnectionConnector),
    takeLatest(NotebooksActions.previewDataConnectionConnectorResults.type, previewDataConnectionConnectorResults),
    takeLatest(NotebooksActions.runCell.type, runCell),
    takeLatest(NotebooksActions.copyConnectionConnectorDataframeNameToClipboard.type, copyConnectionConnectorDataframeNameToClipboard),
    takeLatest(NotebooksActions.updateAndRunCell.type, updateAndRunCell),
  ]);
}

export function* clean() {
  yield put(NotebooksActions.resetToInitialState());
}
