import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import _debounce from 'lodash/debounce';
import _keyBy from 'lodash/keyBy';

import Input from '@palette/components/designSystem/Input/Input';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Select from '@palette/components/designSystem/Select/Select';
import Button from '@palette/components/designSystem/Button/Button';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import MasterPlanEditColumns from '@palette/components/masterPlan/MasterPlanEditColumns/MasterPlanEditColumns';
import MasterPlanPeriodDealsTable from '@palette/components/masterPlanPeriod/MasterPlanPeriodDealsTable/MasterPlanPeriodDealsTable';
import Loader from '@palette/components/utils/Loader/Loader';
import CommissionsWithoutPaymentLink from '@palette/components/masterPlanPeriod/CommissionsWithoutPaymentLink/CommissionsWithoutPaymentLink';

import {
  useMasterPlanPeriodUsersAndManagers,
  useSearchedDealInSearch,
  useSearchedPayeeInSearch,
  useSearchedUserInSearch,
} from '@palette/hooks/MasterPlanHooks';
import {
  useLimitInSearch,
  usePageInSearch,
} from '@palette/hooks/NavigationHooks';

import { scrollToTop } from '@palette/helpers/NavigationHelper';

import {
  SCOPES,
  SEARCHED_DEAL_QS_KEY,
  SEARCHED_PAYEE_QS_KEY,
  SEARCHED_USER_QS_KEY,
} from '@palette/constants/masterPlans';
import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE, LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './MasterPlanPeriodDeals.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodDeals = ({ className, plan, period }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [getDealsLimit, dealsLimitInSearch] = useLimitInSearch(DEFAULT_LIMIT_QS_VALUE);
  const [getDealsPage, dealsPageInSearch] = usePageInSearch(DEFAULT_PAGE_QS_VALUE);
  const [searchedDealFromSearch] = useSearchedDealInSearch();
  const [searchedUser, searchedUserInSearch] = useSearchedUserInSearch();
  const [searchedPayee, searchedPayeeInSearch] = useSearchedPayeeInSearch();
  const [periodUsers, periodManagers] = useMasterPlanPeriodUsersAndManagers(plan, period);

  const [searchedDeal, setSearchedDeal] = useState(null);

  const getPeriodDealsIsPending = useSelector(MasterPlansSelectors.getPeriodDealsIsPending);

  useEffect(() => {
    dispatch(MasterPlansActions.getPeriodDeals({
      planId: plan.id,
      year: period.year,
      period: period.period,
      page: getDealsPage,
      limit: getDealsLimit,
      searchedDeal: searchedDealFromSearch,
      searchedUser,
      searchedPayee,
    }));
  }, [
    plan,
    period,
    getDealsPage,
    getDealsLimit,
    searchedDealFromSearch,
    searchedUser,
    searchedPayee,
  ]);

  useEffect(() => {
    const keysToDelete = [];

    if (searchedUser === null && searchedUserInSearch) {
      keysToDelete.push(SEARCHED_USER_QS_KEY);
    }

    if (searchedPayee === null && searchedPayeeInSearch) {
      keysToDelete.push(SEARCHED_PAYEE_QS_KEY);
    }

    if (getDealsPage === DEFAULT_PAGE_QS_VALUE && dealsPageInSearch) {
      keysToDelete.push(PAGE_QS_KEY);
    }

    if (getDealsLimit === DEFAULT_LIMIT_QS_VALUE && dealsLimitInSearch) {
      keysToDelete.push(LIMIT_QS_KEY);
    }

    if (searchedDeal === '' && searchedDealFromSearch) {
      keysToDelete.push(SEARCHED_DEAL_QS_KEY);
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    searchedUser,
    searchedUserInSearch,
    searchedPayee,
    searchedPayeeInSearch,
    getDealsPage,
    dealsPageInSearch,
    getDealsLimit,
    dealsLimitInSearch,
    searchedDeal,
    searchedDealFromSearch,
  ]);

  const performDealSearch = useCallback(
    _debounce((newSearchedDeal) => {
      const QSToAdd = {};
      const keysToDelete = [PAGE_QS_KEY];

      if (newSearchedDeal !== '') {
        QSToAdd[SEARCHED_DEAL_QS_KEY] = newSearchedDeal;
      } else {
        keysToDelete.push(SEARCHED_DEAL_QS_KEY);
      }

      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    }, 700),
    [],
  );

  const handlePerformDealChange = useCallback((newSearchedDeal) => {
    performDealSearch(newSearchedDeal);
  }, []);

  const handleSearchDealChange = (newSearchedDeal) => {
    setSearchedDeal(newSearchedDeal);

    if (newSearchedDeal.length >= 1) {
      handlePerformDealChange(newSearchedDeal);
    }
  };

  const handleFilterByUserChange = useCallback((userId) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (userId === null) {
      keysToDelete.push(SEARCHED_USER_QS_KEY);
    } else {
      QSToAdd[SEARCHED_USER_QS_KEY] = userId;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const handleFilterByPayeeChange = useCallback((userId) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (userId === null) {
      keysToDelete.push(SEARCHED_PAYEE_QS_KEY);
    } else {
      QSToAdd[SEARCHED_PAYEE_QS_KEY] = userId;
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const usersOptions = useMemo(() => {
    const usersById = _keyBy(periodUsers, (periodUser) => periodUser.id);

    return Object.values(usersById).map((periodUser) => ({
      label: (
        <UserProfile
          className={styles.userOption}
          user={periodUser}
          avatarSize={18}
          avatarStyle={{
            fontSize: '1rem',
            width: '1.8rem',
            minWidth: '1.8rem',
            height: '1.8rem',
          }}
        />
      ),
      value: periodUser.id,
      rawlabel: periodUser.displayName,
    }));
  }, [periodUsers]);

  const usersSelectNode = useMemo(() => {
    if (usersOptions.length === 0) return null;

    return (
      <Select
        className={styles.filterByUserSelector}
        dropdownClassName={styles.filterByUserSelectorDropdown}
        options={usersOptions}
        showSearch
        enableFilterOptions
        filterOptionProp="rawlabel"
        allowClear
        placeholder={t('masterPlanPeriodDeals.filterByUser.placeholder')}
        value={searchedUser}
        onChange={handleFilterByUserChange}
      />
    );
  }, [usersOptions, searchedUser, handleFilterByUserChange]);

  const payeesOptions = useMemo(() => {
    if (plan.scope !== SCOPES.MANAGER) return [];

    const usersById = _keyBy(periodManagers, (periodUser) => periodUser.id);

    return Object.values(usersById).map((periodUser) => ({
      label: (
        <UserProfile
          className={styles.userOption}
          user={periodUser}
          avatarSize={18}
          avatarStyle={{
            fontSize: '1rem',
            width: '1.8rem',
            minWidth: '1.8rem',
            height: '1.8rem',
          }}
        />
      ),
      value: periodUser.id,
      rawlabel: periodUser.displayName,
    }));
  }, [periodManagers, plan]);

  const payeesSelectNode = useMemo(() => {
    if (payeesOptions.length === 0) return null;

    return (
      <Select
        className={styles.filterByUserSelector}
        dropdownClassName={styles.filterByUserSelectorDropdown}
        options={payeesOptions}
        showSearch
        enableFilterOptions
        filterOptionProp="rawlabel"
        allowClear
        placeholder={t('masterPlanPeriodDeals.filterByPayee.placeholder')}
        value={searchedPayee}
        onChange={handleFilterByPayeeChange}
      />
    );
  }, [payeesOptions, handleFilterByPayeeChange]);

  const handleExportDeals = useCallback(() => {
    dispatch(MasterPlansActions.downloadPeriodDeals({
      planId: plan.id,
      year: period.year,
      period: period.period,
      page: getDealsPage,
      limit: getDealsLimit,
      searchedDeal: searchedDealFromSearch,
      searchedUser,
      searchedPayee,
    }));
  }, [
    plan,
    period,
    getDealsPage,
    getDealsLimit,
    searchedDealFromSearch,
    searchedUser,
    searchedPayee,
  ]);

  useEffect(() => {
    if (!getPeriodDealsIsPending) {
      scrollToTop();
    }
  }, [getPeriodDealsIsPending]);

  const contentNode = useMemo(() => (
    <Loader spinning={getPeriodDealsIsPending}>
      <div className={styles.header}>
        <div className={styles.filters}>
          {usersSelectNode}
          {payeesSelectNode}
          <Input
            className={styles.dealSearch}
            type="search"
            defaultValue={searchedDealFromSearch}
            placeholder={t('masterPlanPeriodDeals.dealSearch.placeholder', { dealType: pluralize.singular(plan.trackingObject?.type || '') })}
            onChange={handleSearchDealChange}
            value={searchedDeal || searchedDealFromSearch}
          />
        </div>
        <div className={styles.actions}>
          <CommissionsWithoutPaymentLink plan={plan} period={period} />
          <Button
            className={styles.actionLink}
            type="linkSecondary"
            icon={<DownloadFilled />}
            onClick={handleExportDeals}
          >
            {t('masterPlanPeriodDeals.actions.exportDeals', { dealType: pluralize(plan.trackingObject?.type || '') })}
          </Button>
          <MasterPlanEditColumns className={styles.editColumns} plan={plan} />
        </div>
      </div>
      <MasterPlanPeriodDealsTable
        plan={plan}
        period={period}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </Loader>
  ), [
    getPeriodDealsIsPending,
    usersSelectNode,
    payeesSelectNode,
    searchedDealFromSearch,
    plan,
    handleSearchDealChange,
    searchedDeal,
    searchedDealFromSearch,
    handleExportDeals,
    period,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlanPeriodDeals.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
};

MasterPlanPeriodDeals.defaultProps = {
  className: '',
};

export default MasterPlanPeriodDeals;
