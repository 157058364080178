import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* list() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.list, {}, getConfig()),
    [RIGHTS.ADMIN.TEAMS.VIEW_ALL, RIGHTS.ADMIN.TEAMS.VIEW_SCOPED],
  );
}

export function* getById(payload) {
  const { teamId } = payload;

  const data = { teamId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.getById, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.VIEW_ALL, RIGHTS.ADMIN.TEAMS.VIEW_SCOPED],
  );
}

export function* updateMemberType(payload) {
  const { teamId, userId, type } = payload;

  const data = { teamId, userId, type };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.updateMemberType, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.MEMBERS.MANAGE],
  );
}

export function* removeMemberFromTeam(payload) {
  const { teamId, userId } = payload;

  const data = { teamId, userId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.removeMemberFromTeam, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.MEMBERS.MANAGE],
  );
}

export function* addMembersToTeam(payload) {
  const { teamId, usersIds } = payload;

  const data = {
    teamId,
    members: usersIds.map((userId) => ({ user: userId })),
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.addMembersToTeam, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.MEMBERS.MANAGE],
  );
}

export function* createTeam(payload) {
  const { name, parentTeamId } = payload;

  const data = { name };
  if (parentTeamId !== null) {
    data.parentTeam = parentTeamId;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.createTeam, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.MANAGE],
  );
}

export function* deleteTeam(payload) {
  const { teamId } = payload;

  const data = { teamId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.deleteTeam, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.MANAGE],
  );
}

export function* updateTeamName(payload) {
  const { teamId, name } = payload;

  const data = { teamId, name };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.updateTeamName, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.MANAGE],
  );
}

export function* moveTeam(payload) {
  const { teamId, parentTeamId } = payload;

  const data = { teamId, parentTeam: parentTeamId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.moveTeam, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.MANAGE],
  );
}

export function* getTeamsForUser(payload) {
  const { userId } = payload;

  const data = { userId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.teams.getTeamsForUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.VIEW],
  );
}
