import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as RoleModel from '@palette/models/Role';

import { actions as CompanyActions, selectors as CompanySelectors } from '@palette/state/Company';

import styles from './DeleteRoleModal.less';

const DeleteRoleModal = ({ visible, onClose, role }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteRoleByIdIsPending = useSelector(CompanySelectors.deleteRoleByIdIsPending);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const onSuccessCB = useCallback(() => {
    cleanAndClose();
    dispatch(CompanyActions.getRoles({ withUsersCount: true }));
    redirectTo({ path: routePaths.v2.roles });
  }, [cleanAndClose]);

  const handleDeleteRole = useCallback(() => {
    dispatch(CompanyActions.deleteRoleById({ roleId: role.id, onSuccessCB }));
  }, [role, onSuccessCB]);

  /* eslint-disable react/no-danger */
  return (
    <Modal
      className={styles.modal}
      title={t('deleteRoleModal.title', { roleName: role.name })}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDeleteRole}
      okText={t('deleteRoleModal.confirm')}
      loading={deleteRoleByIdIsPending}
    >
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('deleteRoleModal.content', { roleName: role.name }) }} />
    </Modal>
  );
  /* eslint-enable react/no-danger */
};

DeleteRoleModal.propTypes = {
  role: RoleModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteRoleModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default DeleteRoleModal;
