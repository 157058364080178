import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntDForm } from 'antd';
import pluralize from 'pluralize';
import _isEqual from 'lodash/isEqual';

import Button from '@palette/components/designSystem/Button/Button';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Form from '@palette/components/designSystem/Form/Form';
import VariablesForm from '@palette/components/variable/VariablesForm/VariablesForm';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import FormulaHelper from '@palette/components/formula/FormulaHelper/FormulaHelper';
import MasterPlanSettingsQuotaAttainmentNeedDataModal from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaAttainmentNeedDataModal/MasterPlanSettingsQuotaAttainmentNeedDataModal';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { enhanceFormulaHelperDataWithVariables } from '@palette/helpers/FormulaHelper';
import { isUserGroupByUserPlan } from '@palette/helpers/MasterPlanHelper';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as AnalyticsActions } from '@palette/state/Analytics';
import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsQuotaAttainment.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsQuotaAttainment = ({
  className,
  plan,
  inPlanCreationFlow,
  inPlanCreationFlowSetSaveCB,
  initialValueFormula,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [onSuccessSaveQuotaAttainmentCB, setOnSuccessSaveQuotaAttainmentCB] = useState(null);
  const [needDataDisclaimerModalIsVisible, showNeedDataDisclaimerModal] = useState(false);

  const updatePlanTrackingObjectDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanTrackingObjectDefinitionIsPending);

  const [form] = AntDForm.useForm();

  const initialTrackingObjectValueFormula = useMemo(() => {
    let trackingObjectValueFormula = plan.trackingObject?.valueFormula || '';

    if (!inPlanCreationFlow) return trackingObjectValueFormula;

    if (initialValueFormula !== '') trackingObjectValueFormula = initialValueFormula;
    else if (trackingObjectValueFormula === '1') trackingObjectValueFormula = '';

    return trackingObjectValueFormula;
  }, [plan, inPlanCreationFlow, initialValueFormula]);

  const initialTrackingObjectVariables = useMemo(() => (plan.trackingObject?.valueVariables || []), [plan]);
  const [trackingObjectVariables, setTrackingObjectVariables] = useState(initialTrackingObjectVariables);
  useEffect(() => {
    setTrackingObjectVariables(initialTrackingObjectVariables);
  }, [initialTrackingObjectVariables]);

  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(
    plan.trackingObject?.type,
    dealObjectSample,
    {
      plan,
      addPlanProperties: false,
      addPeriodProperties: false,
      addUsersProperties: false,
      addTeamProperties: false,
      addDependenciesProperties: isUserGroupByUserPlan(plan),
      addPlanTrackingObjectVariablesProperties: false,
    },
  );

  const initialValues = useMemo(() => ({
    valueFormula: initialTrackingObjectValueFormula,
  }), [initialTrackingObjectValueFormula]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      ...initialValues,
    });
  }, [initialValues]);

  const trackingObjectVariablesNames = useMemo(() => {
    const varNames = [];
    trackingObjectVariables.forEach((trackingObjectVariable) => {
      if (trackingObjectVariable.name !== '') {
        varNames.push(trackingObjectVariable.name);
      }
    });

    return varNames;
  }, [trackingObjectVariables]);

  const getFormUpdatedData = useCallback((values) => {
    const finalValues = {
      ...values,
      valueVariables: trackingObjectVariables,
    };

    const finalInitialValues = {
      ...initialValues,
      valueVariables: initialTrackingObjectVariables,
    };

    const updatedData = Object.keys(finalValues).reduce((data, valKey) => {
      if (!_isEqual(finalInitialValues[valKey], finalValues[valKey])) {
        return {
          ...data,
          [valKey]: finalValues[valKey],
        };
      }

      return data;
    }, {});

    return [updatedData, finalValues, finalInitialValues];
  }, [trackingObjectVariables, initialTrackingObjectVariables, initialValues]);

  const formIsPristine = useMemo(() => {
    const [updatedData] = getFormUpdatedData(formValues);

    return Object.keys(updatedData).length === 0;
  }, [formValues, getFormUpdatedData]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    setFormValues(allValues);
  }, []);

  const handleFinish = useCallback((values) => {
    const [updatedData] = getFormUpdatedData(values);

    if (Object.keys(updatedData).length > 0) {
      let otherProps = {};
      if (inPlanCreationFlow) {
        otherProps = {
          disableSuccessNotification: true,
          onSuccessCB: onSuccessSaveQuotaAttainmentCB,
        };
      }
      dispatch(MasterPlansActions.updatePlanTrackingObjectDefinition({ planId: plan.id, ...updatedData, ...otherProps }));
    } else if (onSuccessSaveQuotaAttainmentCB !== null) {
      onSuccessSaveQuotaAttainmentCB();
    }
  }, [getFormUpdatedData, inPlanCreationFlow, onSuccessSaveQuotaAttainmentCB]);

  const handleSaveQuotaAttainment = useCallback((successSaveQuotaAttainmentCB = null) => {
    if (successSaveQuotaAttainmentCB !== null) {
      setOnSuccessSaveQuotaAttainmentCB(() => successSaveQuotaAttainmentCB);
    }

    form.submit();
  }, [form]);

  useEffect(() => {
    if (inPlanCreationFlow && inPlanCreationFlowSetSaveCB !== null) {
      inPlanCreationFlowSetSaveCB(handleSaveQuotaAttainment);
    }
  }, [inPlanCreationFlow, inPlanCreationFlowSetSaveCB, handleSaveQuotaAttainment]);

  const handleNeedDataLinkClick = useCallback(() => {
    if (inPlanCreationFlow) {
      dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.CREATE_PLAN_FLOW.CREATE_PLAN_FLOW_ADD_DEPENDENCIES }));
      showNeedDataDisclaimerModal(true);
    }
  }, [inPlanCreationFlow]);

  const titleSubtitleFormulaHelperNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    return (
      <div className={styles.header}>
        <div className={styles.title}>
          {t('masterPlanSettingsQuotaAttainment.title')}
        </div>
        <div className={styles.subtitle}>
          {t('masterPlanSettingsQuotaAttainment.subtitle', { dealType: pluralize.singular(plan.trackingObject?.type || '') })}
        </div>
        <FormulaHelper
          className={styles.formulaHelper}
          helperData={formulaHelperData}
        />
      </div>
    );
  }, [inPlanCreationFlow, plan, formulaHelperData]);

  const actionsNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    return (
      <div className={styles.actions}>
        <Button className={styles.submitBtn} onClick={handleSaveQuotaAttainment} disabled={formIsPristine || updatePlanTrackingObjectDefinitionIsPending}>
          {t('masterPlanSettingsQuotaAttainment.form.submitBtnLabel')}
        </Button>
      </div>
    );
  }, [
    inPlanCreationFlow,
    handleSaveQuotaAttainment,
    formIsPristine,
    updatePlanTrackingObjectDefinitionIsPending,
  ]);

  const needDataDisclaimerModalNode = useMemo(() => {
    if (!inPlanCreationFlow) return null;

    return (
      <Button
        type="link"
        onClick={handleNeedDataLinkClick}
      >
        {t('masterPlanSettingsQuotaAttainment.needData.disclaimer.label')}
      </Button>
    );
  }, [inPlanCreationFlow]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form className={styles.form} onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        {titleSubtitleFormulaHelperNode}
        <div className={styles.dealVariablesWrapper}>
          <div className={styles.dealVariablesTitle}>
            {t('masterPlanSettingsQuotaAttainment.dealVariables.title', { dealType: pluralize(plan.trackingObject?.type || '') })}
          </div>
          <div className={styles.dealVariablesSubtitle}>
            {t('masterPlanSettingsQuotaAttainment.dealVariables.subtitle', { dealType: pluralize.singular(plan.trackingObject?.type || '') })}
          </div>
          <div className={styles.variablesFormWrapper}>
            <VariablesForm
              variables={trackingObjectVariables}
              helperData={formulaHelperData}
              onChange={(variables) => setTrackingObjectVariables(variables)}
              prefixType={plan.trackingObject?.type || null}
              disabled={updatePlanTrackingObjectDefinitionIsPending}
              verticalMode={inPlanCreationFlow}
            />
          </div>
        </div>
        <div className={styles.valueFormulaWrapper}>
          <FormItem
            className={classNames({
              valueFormulaFormItem: true,
              noMarginBottom: inPlanCreationFlow,
            })}
            name="valueFormula"
            label={t('masterPlanSettingsQuotaAttainment.form.valueFormula.label', { dealType: pluralize.singular(plan.trackingObject?.type || '') })}
            extra={`ex: ${plan.trackingObject?.type || '[Resource]'}.amount - ${plan.trackingObject?.type || '[Resource]'}.setup_fees`}
            required
            rules={[
              { required: true, message: t('masterPlanSettingsQuotaAttainment.form.valueFormula.rules.required') },
            ]}
          >
            <FormulaInput
              type={FORMULA_INPUT_TYPES.FORMULA}
              size="big"
              helperData={enhanceFormulaHelperDataWithVariables(formulaHelperData, trackingObjectVariablesNames, plan.trackingObject?.type || null)}
              disabled={updatePlanTrackingObjectDefinitionIsPending}
              position="top"
            />
          </FormItem>
          {needDataDisclaimerModalNode}
          {needDataDisclaimerModalIsVisible && (
            <MasterPlanSettingsQuotaAttainmentNeedDataModal
              visible
              onClose={() => showNeedDataDisclaimerModal(false)}
            />
          )}
        </div>
        {actionsNode}
      </Form>
    </div>
  );
};

MasterPlanSettingsQuotaAttainment.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  inPlanCreationFlow: PropTypes.bool,
  inPlanCreationFlowSetSaveCB: PropTypes.func,
  initialValueFormula: PropTypes.string,
};

MasterPlanSettingsQuotaAttainment.defaultProps = {
  className: '',
  inPlanCreationFlow: false,
  inPlanCreationFlowSetSaveCB: null,
  initialValueFormula: '',
};

export default MasterPlanSettingsQuotaAttainment;
