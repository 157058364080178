import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import Button from '@palette/components/designSystem/Button/Button';
import ResetLine from '@palette/components/utils/Icons/ResetLine';

import styles from './RawDataHistoryDrawerHeader.less';

const RawDataHistoryDrawerHeader = ({ resource, canManage, handleRollback }) => {
  const { t } = useTranslation();
  const overwritesCount = resource?.overwrites?.length || 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t('rawData.historyDrawer.title')}
      </div>
      <div className={styles.container}>
        <span className={styles.subtitle}>
          {t('rawDataHistoryConditionsWithCount', { count: overwritesCount })}
        </span>
        {overwritesCount > 0 && canManage && (
          <Button
            className={styles.rollbackButton}
            type="link"
            onClick={() => handleRollback(0)}
            icon={<ResetLine />}
          >
            {t('rawData.historyDrawer.rollbackOriginalValue')}
          </Button>
        )}
      </div>
    </div>
  );
};

RawDataHistoryDrawerHeader.propTypes = {
  resource: ResourceObjectModel.propTypes,
  canManage: PropTypes.bool,
  handleRollback: PropTypes.func,
};

RawDataHistoryDrawerHeader.defaultProps = {
  resource: {},
  canManage: false,
  handleRollback: () => {},
};

export default RawDataHistoryDrawerHeader;
