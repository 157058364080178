import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import WidgetBase from '@palette/components/widgets/dashboard/WidgetBase/WidgetBase';

import styles from './CommonWidgetBase.less';

const classNames = bindClassNames.bind(styles);

const CommonWidget = ({
  className,
  widgetIndex,
  children,
  title,
  subtitle,
  useBgColor,
  readOnly,
}) => (
  <WidgetBase
    className={classNames({
      wrapper: true,
      withBgColor: useBgColor,
      [className]: className !== '',
    })}
    widgetIndex={widgetIndex}
    readOnly={readOnly}
  >
    {title && <div className={styles.title}>{title}</div>}
    <div className={styles.subtitle}>{subtitle}</div>
    {children}
  </WidgetBase>
);

CommonWidget.propTypes = {
  className: PropTypes.string,
  widgetIndex: PropTypes.number.isRequired,
  children: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  useBgColor: PropTypes.bool,
  readOnly: PropTypes.bool,
};

CommonWidget.defaultProps = {
  className: '',
  children: null,
  title: '',
  subtitle: '',
  useBgColor: false,
  readOnly: false,
};

export default CommonWidget;
