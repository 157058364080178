import PropTypes from 'prop-types';

import { manageArrayAttribute, manageFreeShapeObjectAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'PlanPeriodData';

export const propTypes = PropTypes.shape({
  plan: MasterPlanModel.propTypes,
  period: YearPeriodModel.propTypes,
  currency: PropTypes.string,
});

export const transform = (planPeriodData) => {
  if (!planPeriodData) {
    return null;
  }

  const periodsArray = manageArrayAttribute(planPeriodData, 'periods');

  let period = YearPeriodModel.transform({});

  if (periodsArray.length > 0) {
    const periods = YearPeriodModel.transformList(periodsArray);
    // eslint-disable-next-line prefer-destructuring
    period = periods[0];
  }

  return (
    {
      plan: MasterPlanModel.transform(manageFreeShapeObjectAttribute(planPeriodData, 'plan')),
      period,
      currency: manageStringAttribute(planPeriodData, 'currency'),
    }
  );
};
