import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import CommissionRuleDetails from '@palette/components/commission/CommissionRuleDetails/CommissionRuleDetails';
import CommissionRuleFormulaScope from '@palette/components/commission/CommissionRuleFormulaScope/CommissionRuleFormulaScope';
import RuleVariables from '@palette/components/rule/RuleVariables/RuleVariables';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionDrawerCalculationsTab.less';

const classNames = bindClassNames.bind(styles);

const CommissionDrawerCalculationsTab = ({ className, commission }) => {
  const { t } = useTranslation();
  const rule = useMemo(() => commission.scopeParams?.rule, [commission]);

  const ruleVariablesNode = useMemo(() => {
    if (!rule || rule.variables.length === 0) return null;

    return (
      <RuleVariables
        className={styles.variables}
        variables={rule.variables}
        scope={commission.scope}
        title={t('commissionRuleVariables.title')}
      />
    );
  }, [rule, commission]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <CommissionRuleDetails commission={commission} />
      {ruleVariablesNode}
      <CommissionRuleFormulaScope className={styles.formulaScope} commission={commission} />
    </div>
  );
};

CommissionDrawerCalculationsTab.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionDrawerCalculationsTab.defaultProps = {
  className: '',
};

export default CommissionDrawerCalculationsTab;
