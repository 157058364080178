import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';

import styles from './RemoveDashboardPlan.less';

const classNames = bindClassNames.bind(styles);

const RemoveDashboardPlan = ({ className, dashboardId, planId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deleteTooltipVisible, setDeleteTooltipVisible] = useState(false);
  const [deleteIsPending, setDeleteIsPending] = useState(false);

  const removePlanFromDashboardIsPending = useSelector(QuotasPerformanceSelectors.removePlanFromDashboardIsPending);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleDelete = (event) => {
    stopPropagation(event);
    setDeleteTooltipVisible(false);
  };

  const deleteDashboardPlan = useCallback((event) => {
    stopPropagation(event);
    setDeleteIsPending(true);

    dispatch(QuotasPerformanceActions.removePlanFromDashboard({
      quotaPerformanceId: dashboardId,
      planId,
    }));
  }, [dashboardId, planId]);

  useEffect(() => {
    if (!removePlanFromDashboardIsPending && deleteIsPending) {
      setDeleteIsPending(false);
    }
  }, [removePlanFromDashboardIsPending, deleteIsPending]);

  const contentNode = useMemo(() => (
    <Popconfirm
      title={t('quotasPerformance.removeDashboardPlan.popconfirm.label')}
      onConfirm={(event) => deleteDashboardPlan(event)}
      okText={t('common.global.yes')}
      cancelText={t('common.global.no')}
      size="small"
    >
      <Tooltip
        title={t('quotasPerformance.removeDashboardPlan.tooltip.label')}
        visible={deleteTooltipVisible}
        onVisibleChange={(visible) => setDeleteTooltipVisible(visible)}
      >
        <Button
          className={styles.deleteButton}
          type="linkDestroy"
          icon={removePlanFromDashboardIsPending && deleteIsPending ? <LoadingLine spin /> : <TrashFilled className={styles.deleteIcon} />}
          onClick={handleDelete}
          disabled={removePlanFromDashboardIsPending}
        >
          {t('actions.delete')}
        </Button>
      </Tooltip>
    </Popconfirm>
  ), [deleteTooltipVisible, deleteDashboardPlan, removePlanFromDashboardIsPending, deleteIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

RemoveDashboardPlan.propTypes = {
  className: PropTypes.string,
  dashboardId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
};

RemoveDashboardPlan.defaultProps = {
  className: '',
};

export default RemoveDashboardPlan;
