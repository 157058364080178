import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import UserDataSettings from '@palette/components/userDetails/UserDataSettings/UserDataSettings';
import UserAccountSettings from '@palette/components/userDetails/UserAccountSettings/UserAccountSettings';
import UserLinkedResourcesSettings from '@palette/components/userDetails/UserLinkedResourcesSettings/UserLinkedResourcesSettings';
import UserOffboardingSettings from '@palette/components/userDetails/UserOffboardingSettings/UserOffboardingSettings';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserDetailsSettingsTab.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsSettingsTab = ({ className, user }) => {
  const userDetailsNode = useMemo(() => (
    <UserDataSettings user={user} />
  ), [user]);

  const accountNode = useMemo(() => (
    <UserAccountSettings className={styles.account} user={user} />
  ), [user]);

  const linkedResourcesNode = useMemo(() => (
    <UserLinkedResourcesSettings className={styles.linkedResources} user={user} />
  ), [user]);

  const offboardingNode = useMemo(() => (
    <UserOffboardingSettings className={styles.offboarding} user={user} />
  ), [user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {userDetailsNode}
      {accountNode}
      {linkedResourcesNode}
      {offboardingNode}
    </div>
  );
};

UserDetailsSettingsTab.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsSettingsTab.defaultProps = {
  className: '',
};

export default UserDetailsSettingsTab;
