import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';

import { actions as ResourcesActions } from '@palette/state/Resources';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './ResourceDetailsSummaryActionCopy.less';

const classNames = bindClassNames.bind(styles);

const ResourceDetailsSummaryActionCopy = ({ className, resourceObject }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCopy = useCallback((event) => {
    event.stopPropagation();
    dispatch(ResourcesActions.copyResourceDataAsJSON(resourceObject));
  }, [resourceObject]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.button}
        type="link"
        icon={<MedicalChartFilled className={styles.icon} />}
        onClick={handleCopy}
      >
        {t('actions.copyAsJSON')}
      </Button>
    </div>
  );
};

ResourceDetailsSummaryActionCopy.propTypes = {
  className: PropTypes.string,
  resourceObject: ResourceObjectModel.propTypes.isRequired,
};

ResourceDetailsSummaryActionCopy.defaultProps = {
  className: '',
};

export default ResourceDetailsSummaryActionCopy;
