import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Icon from '@ant-design/icons';

import styles from './SVGIcon.less';

const classNames = bindClassNames.bind(styles);

const SVGIcon = ({
  className,
  preFillInWhite,
  Component,
  width,
  height,
  spin,
  ...rest
}) => (
  <Icon
    className={classNames({
      wrapper: true,
      preFillInWhite,
      spin,
      [className]: className !== '',
    })}
    component={() => (<Component fill="none" width={width} height={height} />)}
    style={{ width, height, lineHeight: height }}
    {...rest}
  />
);

SVGIcon.propTypes = {
  className: PropTypes.string,
  preFillInWhite: PropTypes.bool,
  Component: PropTypes.any.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  spin: PropTypes.bool,
};

SVGIcon.defaultProps = {
  className: '',
  preFillInWhite: true,
  width: 18,
  height: 18,
  spin: false,
};

export default SVGIcon;
