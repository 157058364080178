import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';
import ChevronUpLine from '@palette/components/utils/Icons/ChevronUpLine';

import * as CommissionModel from '@palette/models/Commission';

import styles from './MasterPlanPeriodDealExpandCell.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodDealExpandCell = ({ className, periodDealOrUserWithCommissions, isExpanded, toggleRowExpanded, children }) => {
  const canBeExpanded = periodDealOrUserWithCommissions.commissions.length > 1;

  let expandIconNode = null;
  let onClickProps = {};
  if (canBeExpanded) {
    expandIconNode = (
      <ChevronDownLine className={styles.expandIcon} width={24} height={24} />
    );

    if (isExpanded) {
      expandIconNode = (
        <ChevronUpLine className={styles.expandIcon} width={24} height={24} />
      );
    }

    onClickProps = {
      onClick: () => toggleRowExpanded(!isExpanded),
    };
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        isClickable: canBeExpanded,
        [className]: className !== '',
      })}
      {...onClickProps}
    >
      <div className={styles.content}>
        {children}
      </div>
      {expandIconNode}
    </div>
  );
};

MasterPlanPeriodDealExpandCell.propTypes = {
  className: PropTypes.string,
  periodDealOrUserWithCommissions: PropTypes.shape({
    commissions: PropTypes.arrayOf(CommissionModel.propTypes),
  }).isRequired,
  isExpanded: PropTypes.bool,
  toggleRowExpanded: PropTypes.func,
  children: PropTypes.any.isRequired,
};

MasterPlanPeriodDealExpandCell.defaultProps = {
  className: '',
  isExpanded: false,
  toggleRowExpanded: null,
};

export default MasterPlanPeriodDealExpandCell;
