import PropTypes from 'prop-types';

import { manageAmountAttribute, manageDateAttribute, manageFreeShapeObjectAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'StatementV3ListCommission';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  dealId: PropTypes.string,
  planId: PropTypes.string,
  planName: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  date: PropTypes.string,
});

export const transform = (commission) => {
  if (!commission) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(commission, 'commissionId'),
      dealId: manageStringAttribute(commission, 'dealId'),
      planId: manageStringAttribute(manageFreeShapeObjectAttribute(commission, 'plan'), 'id'),
      planName: manageStringAttribute(manageFreeShapeObjectAttribute(commission, 'plan'), 'name'),
      amount: manageAmountAttribute(commission, 'amount'),
      currency: manageStringAttribute(commission, 'currency'),
      date: manageDateAttribute(commission, 'date'),
    }
  );
};

export const transformList = (commissions) => commissions.map((commission) => transform(commission));
