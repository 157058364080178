import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';
import ExternalLink from '@palette/components/designSystem/ExternalLink/ExternalLink';

import { PALETTE_TERMS_AND_CONDITIONS_URL } from '@palette/constants/global';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './TermsAndConditionsModal.less';

const TermsAndConditionsModal = ({ visible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const acknowledgeTermsAndConditionsIsPending = useSelector(ProfileSelectors.acknowledgeTermsAndConditionsIsPending);

  const handleAcknowledgeTermsAndConditions = useCallback(() => {
    dispatch(ProfileActions.acknowledgeTermsAndConditions());
  }, []);

  return (
    <Modal
      className={styles.modal}
      title={t('termsAndConditionsModal.title')}
      visible={visible}
      onOk={handleAcknowledgeTermsAndConditions}
      okText={t('termsAndConditionsModal.acknowledge')}
      loading={acknowledgeTermsAndConditionsIsPending}
      cancelText={null}
      closable={false}
      closeIcon={null}
    >
      <div className={styles.content}>
        {t('termsAndConditionsModal.content')}
      </div>
      <ExternalLink
        href={PALETTE_TERMS_AND_CONDITIONS_URL}
        displayIcon
      >
        {t('termsAndConditionsModal.linkLabel')}
      </ExternalLink>
    </Modal>
  );
};

TermsAndConditionsModal.propTypes = {
  visible: PropTypes.bool,
};

TermsAndConditionsModal.defaultProps = {
  visible: false,
};

export default TermsAndConditionsModal;
