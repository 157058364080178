import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ResponsiveRadialBar } from '@nivo/radial-bar';

import ChartTooltip from '@palette/components/designSystem/ChartTooltip/ChartTooltip';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { floatToFixedNumber, formatNumberShortDisplay } from '@palette/helpers/CommonHelper';
import {
  getPeriodElapsedTimePercentage,
  getPeriodProgressChartGradient,
  getPlanValueDefinition,
} from '@palette/helpers/MasterPlanHelper';

import {
  CHART_GRADIENT_BLUE,
  gradientsDefs,
  TRACKS_COLOR,
} from '@palette/constants/charts';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as PlanProgressGaugeDataModel from '@palette/models/widgets/dashboard/PlanProgressGaugeData';

import styles from './PlanProgressGaugeChart.less';

const classNames = bindClassNames.bind(styles);

const elapsedGroupId = 'elapsed';
const progressGroupId = 'progress';

const PlanProgressGaugeChart = ({ className, plan, data }) => {
  const { t } = useTranslation();

  const hasNoQuotaTarget = data.target === -1;

  if (hasNoQuotaTarget) {
    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <DefaultEmptyState description={t('planProgressGaugeChart.noTarget')} />
      </div>
    );
  }

  const planValueDefinition = getPlanValueDefinition(plan);

  const periodProgressPercentage = floatToFixedNumber(data.progress * 100);
  const periodElapsedTimePercentage = getPeriodElapsedTimePercentage(plan, data.beginPeriod, data.endPeriod);

  const progressGradientId = getPeriodProgressChartGradient(periodElapsedTimePercentage, periodProgressPercentage).id;

  const chartData = [
    {
      id: elapsedGroupId,
      data: [
        {
          x: 'percentage',
          y: periodElapsedTimePercentage,
        },
      ],
    },
    {
      id: progressGroupId,
      data: [
        {
          x: 'percentage',
          y: Math.min(periodProgressPercentage, 100),
          progress: periodProgressPercentage,
        },
      ],
    },
  ];

  const getTooltip = (barDesc) => {
    if (barDesc.bar.groupId === elapsedGroupId) {
      return (
        <ChartTooltip
          title={`${t('masterPlanPeriodProgress.elapsedTime', { percentage: barDesc.bar.value })}`}
        />
      );
    }

    return (
      <ChartTooltip
        title={`${t('masterPlanPeriodUsers.table.headers.progress')}: ${barDesc.bar.data.progress}%`}
      />
    );
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.chartWrapper}>
        <div className={styles.chart}>
          <ResponsiveRadialBar
            data={chartData}
            startAngle={-90}
            endAngle={90}
            maxValue={100}
            innerRadius={0.75}
            cornerRadius={10}
            padding={0.3}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            enableRadialGrid={false}
            enableCircularGrid={false}
            radialAxisStart={null}
            circularAxisOuter={null}
            motionConfig="slow"
            transitionMode="startAngle"
            isInteractive
            tooltip={getTooltip}
            tracksColor={TRACKS_COLOR}
            defs={gradientsDefs}
            fill={[
              { match: (d) => (d.groupId === progressGroupId), id: progressGradientId },
              { match: (d) => (d.groupId === elapsedGroupId), id: CHART_GRADIENT_BLUE.id },
            ]}
          />
        </div>
      </div>
      <div className={styles.textualProgressWrapper}>
        <div
          className={classNames({
            percentageProgress: true,
            [progressGradientId.toLowerCase()]: true,
          })}
        >
          {`${periodProgressPercentage}%`}
        </div>
        <div className={styles.valueDefinition}>
          {planValueDefinition}
        </div>
        <div className={styles.target}>
          <span>
            {formatNumberShortDisplay(data.target * data.progress)}
          </span>
          <span>
            /
          </span>
          <span>
            {formatNumberShortDisplay(data.target)}
          </span>
        </div>
      </div>
    </div>
  );
};

PlanProgressGaugeChart.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  data: PlanProgressGaugeDataModel.propTypes.isRequired,
};

PlanProgressGaugeChart.defaultProps = {
  className: '',
};

export default PlanProgressGaugeChart;
