import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { scrollToPosition } from '@palette/helpers/NavigationHelper';

import * as NotebookCellModel from '@palette/models/NotebookCell';

import styles from './NotebookCellsList.less';

const classNames = bindClassNames.bind(styles);

const NotebookCellsList = ({ className, cells }) => {
  const cellsListNode = useMemo(() => {
    if (cells.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    const cellsNodes = cells.map((cell) => (
      <div
        key={cell.id}
        className={styles.listItem}
        onClick={() => scrollToPosition(cell.id)}
      >
        {cell.name}
      </div>
    ));

    return (
      <div className={styles.list}>
        {cellsNodes}
      </div>
    );
  }, [cells]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {cellsListNode}
    </div>
  );
};

NotebookCellsList.propTypes = {
  className: PropTypes.string,
  cells: PropTypes.arrayOf(NotebookCellModel.propTypes).isRequired,
};

NotebookCellsList.defaultProps = {
  className: '',
};

export default NotebookCellsList;
