import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as TeamModel from '@palette/models/Team';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './NewTeamModal.less';

const NewTeamModal = ({ visible, onClose, parentTeam }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const createTeamIsPending = useSelector(TeamsSelectors.createTeamIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    name: undefined,
  }), []);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const onSuccessCB = useCallback((newTeamId) => {
    cleanAndClose();
    redirectTo({ path: routePaths.v2.teamDetails, params: { teamId: newTeamId } });
  }, [cleanAndClose]);

  const handleFinish = useCallback(({ name }) => {
    dispatch(TeamsActions.createTeam({ name, parentTeamId: parentTeam?.id || null, onSuccessCB }));
  }, [parentTeam, onSuccessCB]);

  const handleNewTeam = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={parentTeam !== null ? t('newTeamModal.title.newSubteam') : t('newTeamModal.title.newTeam')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleNewTeam}
      okText={t('newTeamModal.create')}
      loading={createTeamIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="name"
          label={t('newTeamModal.form.name.label')}
          required
          rules={[
            { required: true, message: t('newTeamModal.form.name.rules.required') },
          ]}
        >
          <Input size="big" disabled={createTeamIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

NewTeamModal.propTypes = {
  parentTeam: TeamModel.propTypes,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

NewTeamModal.defaultProps = {
  parentTeam: null,
  visible: false,
  onClose: () => {},
};

export default NewTeamModal;
