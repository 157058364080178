import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';

import Table from '@palette/components/designSystem/Table/Table';
import MasterPlanPeriodDealExpandCell from '@palette/components/masterPlanPeriod/MasterPlanPeriodDealExpandCell/MasterPlanPeriodDealExpandCell';
import MasterPlanPeriodDealCommissionsTable from '@palette/components/masterPlanPeriod/MasterPlanPeriodDealCommissionsTable/MasterPlanPeriodDealCommissionsTable';
import PeriodDealCommissionInfos from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfos/PeriodDealCommissionInfos';
import PeriodDealCommissionValue from '@palette/components/masterPlanPeriod/PeriodDealCommissionValue/PeriodDealCommissionValue';
import Pagination from '@palette/components/designSystem/Pagination/Pagination';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import PeriodDealPayoutInfos from '@palette/components/masterPlanPeriod/PeriodDealPayoutInfos/PeriodDealPayoutInfos';
import PeriodDealOwners from '@palette/components/masterPlanPeriod/PeriodDealOwners/PeriodDealOwners';

import { useMasterPlanColumns, useSearchedUserInSearch } from '@palette/hooks/MasterPlanHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { applyGenericTimezone } from '@palette/helpers/MomentHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';
import { getColumnValue } from '@palette/helpers/ConnectorHelper';
import { getCommissionValue } from '@palette/helpers/CommissionHelper';

import { SCOPES } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanPeriodDealsTable.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodDealsTable = ({ className, plan, period, onPageChange, onLimitChange }) => {
  const { t } = useTranslation();

  const planColumns = useMasterPlanColumns(plan);
  const [searchedUser] = useSearchedUserInSearch();

  const periodDeals = useSelector((state) => (MasterPlansSelectors.getMasterPlanPeriodDeals(state, { masterPlanId: plan.id, year: period.year, periodId: period.period })));
  const periodDealsPagination = useSelector((state) => (MasterPlansSelectors.getMasterPlanPeriodDealsPagination(state, { masterPlanId: plan.id, year: period.year, periodId: period.period })));

  const handlePageChange = useCallback((newPage) => {
    onPageChange(newPage);
  });

  const handleLimitChange = useCallback((newLimit) => {
    onLimitChange(newLimit);
  });

  const fixedColumns = useMemo(() => {
    const cols = [];

    if (planColumns.length === 0) {
      const seeCommissionsColumn = {
        id: 'seeCommissions',
        accessor: (periodDeal) => periodDeal,
        minWidth: 40,
        maxWidth: 40,
        width: '4%',
        /* eslint-disable react/prop-types */
        Cell: ({ value, row }) => {
          if (value.commissions.length < 2) return null;

          const isExpanded = row.isExpanded === true;

          return (
            <MasterPlanPeriodDealExpandCell periodDealOrUserWithCommissions={value} isExpanded={isExpanded} toggleRowExpanded={row.toggleRowExpanded}>
              &nbsp;
            </MasterPlanPeriodDealExpandCell>
          );
        },
        /* eslint-enable react/prop-types */
      };

      cols.push(seeCommissionsColumn);
    }

    const usersColumn = {
      id: 'periodDealUser',
      Header: plan.usersDefinition?.type || t('masterPlanPeriodDeals.table.headers.user'),
      accessor: 'users',
      minWidth: 200,
      width: '20%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<PeriodDealOwners owners={value} />),
    };

    cols.push(usersColumn);

    if (
      plan.quotaId !== null
      && (
        plan.scope === SCOPES.MANAGER
        || plan.scope === SCOPES.TEAM
        || (
          plan.scope === SCOPES.INDIVIDUAL
          && searchedUser !== null
        )
      )
    ) {
      const progressColumn = {
        id: 'progress',
        Header: t('masterPlanPeriodDeals.table.headers.progress'),
        accessor: (periodDeal) => `${floatToFixedNumber(periodDeal.progress * 100)}%`,
        minWidth: 100,
        width: '10%',
      };

      cols.push(progressColumn);
    }

    if (plan.trackingObject?.valueFormula !== '1') {
      const revenueColumn = {
        id: 'revenue',
        Header: getPlanValueDefinition(plan),
        accessor: (periodDeal) => {
          let dealValue = periodDeal.value;
          if (periodDeal.commissions.length === 1) {
            dealValue = getCommissionValue(periodDeal.commissions[0]);
          }

          return comaSeparatorFormatter(floatToFixedNumber(dealValue));
        },
        minWidth: 100,
        maxWidth: 150,
        width: '15%',
      };

      cols.push(revenueColumn);
    }

    const dateColumn = {
      id: 'date',
      Header: t('masterPlanPeriodDeals.table.headers.date'),
      accessor: (periodDeal) => applyGenericTimezone(periodDeal.date, plan.timezone).format('ll'),
      minWidth: 150,
      maxWidth: 150,
      width: '15%',
    };

    cols.push(dateColumn);

    if (plan.rules.length > 0) {
      const commissionsColumn = {
        id: 'commissions',
        Header: (
          <div className={styles.headerCommissions}>
            {t('masterPlanPeriodDeals.table.headers.commissions')}
          </div>
        ),
        accessor: (periodDeal) => periodDeal,
        minWidth: 130,
        maxWidth: 180,
        width: '18%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          const totalAmount = formatPrice(value.totalCommissionAmount, value.currency);
          const hasDot = value.commissions?.some((commission) => commission.overwrittenAmount !== null);

          return (
            <PeriodDealCommissionValue
              value={value.totalCommissionAmount}
              formattedValue={totalAmount}
              hasDot={hasDot}
              hasOnlyTargetRule={value.hasOnlyTargetRule}
            />
          );
        },
        /* eslint-enable react/prop-types */
        highlight: true,
      };

      cols.push(commissionsColumn);
    }

    if (plan.rules.length < 2) {
      const payoutInfosColumn = {
        id: 'payoutInfos',
        Header: (
          <div className={styles.headerCentered}>
            {t('masterPlanPeriodDeals.table.headers.payout')}
          </div>
        ),
        accessor: (periodDeal) => periodDeal,
        minWidth: 180,
        maxWidth: 200,
        width: '20%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          if (value.commissions.length !== 1) return null;

          return <PeriodDealPayoutInfos commission={value.commissions[0]} />;
        },
        /* eslint-enable react/prop-types */
      };

      cols.push(payoutInfosColumn);

      const commissionInfosColumn = {
        id: 'commissionInfos',
        accessor: (periodDeal) => periodDeal,
        minWidth: 170,
        maxWidth: 190,
        width: '19%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          if (value.commissions.length !== 1) return null;

          return <PeriodDealCommissionInfos commission={value.commissions[0]} displayPayments={false} />;
        },
        /* eslint-enable react/prop-types */
      };

      cols.push(commissionInfosColumn);
    }

    return cols;
  }, [
    plan,
    period,
    searchedUser,
    planColumns,
  ]);

  const tableColumns = useMemo(() => {
    const planTableColumns = planColumns.map((column, index) => {
      const planColumnId = `planColumn_${index}_${column.name}`;

      if (index === 0) {
        return {
          id: planColumnId,
          Header: column.name,
          accessor: (periodDeal) => periodDeal,
          minWidth: 150,
          maxWidth: 250,
          width: '25%',
          /* eslint-disable react/prop-types */
          Cell: ({ value, row }) => {
            const isExpanded = row.isExpanded === true;

            return (
              <MasterPlanPeriodDealExpandCell periodDealOrUserWithCommissions={value} isExpanded={isExpanded} toggleRowExpanded={row.toggleRowExpanded}>
                {getColumnValue(value.resourceObject?.data || {}, column)}
              </MasterPlanPeriodDealExpandCell>
            );
          },
          /* eslint-enable react/prop-types */
        };
      }

      return {
        id: planColumnId,
        Header: column.name,
        accessor: (periodDeal) => getColumnValue(periodDeal.resourceObject?.data || {}, column),
        minWidth: 150,
        maxWidth: 250,
        width: 'auto',
      };
    });

    const allColumns = planTableColumns.concat(fixedColumns);

    if (allColumns.length < 7 && planColumns.length !== 0) {
      allColumns[0].minWidth = 250;
      allColumns[0].maxWidth = 300;
      allColumns[0].width = '30%';
    }

    return allColumns;
  }, [
    plan,
    planColumns,
    fixedColumns,
  ]);

  const contentNode = useMemo(() => {
    if (periodDeals.length === 0) {
      return (
        <DefaultEmptyState description={t('masterPlanPeriodDeals.empty.description', { dealType: pluralize.singular(plan.trackingObject?.type || '') })} />
      );
    }

    let nbOfFixedColumns = 3;
    if (plan.rules.length === 0) {
      nbOfFixedColumns = 2;
    }
    if (plan.rules.length > 1) {
      nbOfFixedColumns = 1;
    }

    return (
      <>
        <Table
          type="borderless"
          columns={tableColumns}
          data={periodDeals}
          enableExpandRow
          renderExpandedRow={(row) => (<MasterPlanPeriodDealCommissionsTable periodDealOrUserWithCommissions={periodDeals[row.index]} plan={plan} />)}
          nbOfFixedColumns={nbOfFixedColumns}
          fixedColumnsPosition="fromRight"
        />
        <Pagination
          className={styles.pagination}
          pagination={periodDealsPagination}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </>
    );
  }, [
    periodDeals,
    plan,
    tableColumns,
    periodDealsPagination,
    handlePageChange,
    handleLimitChange,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlanPeriodDealsTable.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

MasterPlanPeriodDealsTable.defaultProps = {
  className: '',
};

export default MasterPlanPeriodDealsTable;
