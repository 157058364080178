import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _size from 'lodash/size';

import RuleVariables from '@palette/components/rule/RuleVariables/RuleVariables';
import MasterPlanSettingsCommissionRulesStepItem from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesStepItem/MasterPlanSettingsCommissionRulesStepItem';
import PlanWhatIfProjectionChart from '@palette/components/charts/PlanWhatIfProjectionChart/PlanWhatIfProjectionChart';

import { getPlanLastPeriod } from '@palette/helpers/MasterPlanHelper';

import { RULE_TYPES } from '@palette/constants/masterPlans';

import * as PlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './MasterPlanSettingsCommissionRulesSteps.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCommissionRulesSteps = ({ className, plan, period, forIC }) => {
  const { t } = useTranslation();

  const projectionNode = useMemo(() => {
    if (
      plan === null
      || plan.quotaId === null
      || plan.estimationDefinition === null
      || plan.estimationDefinition.referenceDealId === null
    ) {
      return null;
    }

    let projectionPeriod = getPlanLastPeriod(plan);
    let projectionData = null;
    if (period !== null) {
      projectionPeriod = {
        year: period.year,
        period: period.period,
      };
      projectionData = {
        periodData: period,
      };
    }

    return (
      <div className={styles.previewProjectionWrapper}>
        <h3>
          {t('masterPlanSettingsCommissionRules.previewProjection.title')}
        </h3>
        <div className={styles.projectionChartWrapper}>
          <PlanWhatIfProjectionChart
            plan={plan}
            period={projectionPeriod}
            data={projectionData}
            forAdmin={!forIC}
          />
        </div>
      </div>
    );
  }, [
    plan,
    period,
    forIC,
  ]);

  if (!_size(plan.rules) > 0) {
    return (
      <p>{t('masterPlanSettingsCommissionRules.noRulesText')}</p>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {projectionNode}
      {plan.rules.map((rule, i) => (
        <div className={styles.ruleWrapper} key={rule.id}>
          {/* Title */}
          {(rule.name || plan.rules.length > 1) && (
            <span className={styles.ruleName}>
              {rule.name}
              {!rule.name && plan.rules.length > 1 && `${t('masterPlanSettingsCommissionRules.ruleTitle')} ${i + 1}`}
            </span>
          )}

          {/* Description */}
          {(rule.description !== '') && (
            <span className={styles.ruleDescription}>
              {rule.description}
            </span>
          )}

          {/* Steps rule */}
          <MasterPlanSettingsCommissionRulesStepItem className={styles.ruleContainer} rule={rule} plan={plan} />

          {/* Variables */}
          {rule.type !== RULE_TYPES.CODE_ONLY && rule.variables?.length > 0 && (
            <RuleVariables
              className={styles.variablesContainer}
              variables={rule.variables}
              title={t('masterPlanSettingsCommissionRules.variablesTitle')}
            />
          )}

          {/* Init formula */}
          {rule.type !== RULE_TYPES.CODE_ONLY && rule.initFormula && (
            <div className={styles.initFormulaContainer}>
              <span className={styles.formulaTitle}>
                {t('masterPlanSettingsCommissionRules.initFormulaTitle')}
              </span>
              <div className={styles.formulaContent}>
                {rule.initFormula}
              </div>
            </div>
          )}

          {/* Code only */}
          {rule.type === RULE_TYPES.CODE_ONLY && (
            <div className={styles.codeOnlyContainer}>
              {rule.explanation}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

MasterPlanSettingsCommissionRulesSteps.propTypes = {
  className: PropTypes.string,
  plan: PlanModel.propTypes,
  period: MasterPlanPeriodModel.propTypes,
  forIC: PropTypes.bool,
};

MasterPlanSettingsCommissionRulesSteps.defaultProps = {
  className: '',
  plan: {},
  period: null,
  forIC: false,
};

export default MasterPlanSettingsCommissionRulesSteps;
