import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntDForm } from 'antd';

import Select from '@palette/components/designSystem/Select/Select';
import Button from '@palette/components/designSystem/Button/Button';
import UnlinkQuotaFromMasterPlan from '@palette/components/masterPlanSettings/UnlinkQuotaFromMasterPlan/UnlinkQuotaFromMasterPlan';
import MasterPlanSettingsQuotaTable from '@palette/components/masterPlanSettings/MasterPlanSettingsQuotaTable/MasterPlanSettingsQuotaTable';
import QuotaTargetStrategySelect from '@palette/components/masterPlanSettings/QuotaTargetStrategySelect/QuotaTargetStrategySelect';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';
import Form from '@palette/components/designSystem/Form/Form';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as QuotaModel from '@palette/models/Quota';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';
import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsCustomQuotaSetupPanel.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCustomQuotaSetupPanel = ({ className, plan, quota, inPlanCreationFlow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quotasList = useSelector(QuotasSelectors.getQuotasList);
  useEffect(() => {
    if (quotasList.length === 0) {
      dispatch(QuotasActions.getList());
    }
  }, [quotasList]);

  const createQuotaIsPending = useSelector(QuotasSelectors.createQuotaIsPending);
  const quotaTypes = useSelector(QuotasSelectors.getQuotaTypes);

  const [form] = AntDForm.useForm();

  const quotaTypesOptions = quotaTypes.map((type) => ({
    label: type,
    value: type,
  }));

  const initialValues = {
    type: undefined,
    defaultValue: 0,
  };

  const handleFinish = (values) => {
    const onSuccessCB = (quotaId) => {
      let otherUpdatedData = {};
      if (inPlanCreationFlow) {
        otherUpdatedData = {
          creationFlowStep: CREATE_PLAN_FLOW_STEPS.QUOTA_SETUP.value,
          disableSuccessNotification: true,
        };
      }
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, quotaId, ...otherUpdatedData }));
    };

    const finalValues = {
      ...values,
      name: `${plan.name} Quota`,
      periodType: PERIOD_TYPES.CUSTOM,
      frequency: plan.frequency,
      beginAt: plan.customBeginAt,
    };

    dispatch(QuotasActions.createQuota({ ...finalValues, onSuccessCB }));
  };

  const handleCreateAndLinkQuota = () => form.submit();

  const contentNode = useMemo(() => {
    if (quota === null) {
      return (
        <Form className={styles.form} onFinish={handleFinish} initialValues={initialValues} form={form}>
          <FormItem
            name="type"
            label={t('quota.form.tracked.label')}
            required
            rules={[
              { required: true, message: t('quota.form.tracked.rules.required') },
            ]}
          >
            <Select
              size="big"
              showSearch
              addSearchToOptions
              addSearchToOptionsLabel={t('quota.form.tracked.addTypeToOptions')}
              searchNotFoundLabel={t('quota.form.tracked.searchNotFound')}
              placeholder={t('quota.form.tracked.placeholder')}
              options={quotaTypesOptions}
              disabled={createQuotaIsPending}
            />
          </FormItem>
          <FormItem
            name="defaultValue"
            label={t('quota.form.defaultValue.label')}
            extra={t('quota.form.defaultValue.description')}
            required
          >
            <Input size="big" type="comaSeparatorFormatted" disabled={createQuotaIsPending} />
          </FormItem>
          <Button onClick={handleCreateAndLinkQuota}>
            {t('masterPlanSettingsQuotaSetupPanel.linkBtnLabel')}
          </Button>
        </Form>
      );
    }

    return (
      <div className={styles.quotaTableWrapper}>
        <div className={styles.quotaInfosWithActions}>
          <h3>{quota.name}</h3>
          <div className={styles.actions}>
            <QuotaTargetStrategySelect className={styles.action} plan={plan} />
            <UnlinkQuotaFromMasterPlan className={styles.action} plan={plan} />
          </div>
        </div>
        <div className={styles.quotaTable}>
          <MasterPlanSettingsQuotaTable plan={plan} quota={quota} />
        </div>
      </div>
    );
  }, [
    quota,
    form,
    createQuotaIsPending,
    quotaTypesOptions,
    initialValues,
    handleFinish,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MasterPlanSettingsCustomQuotaSetupPanel.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  quota: QuotaModel.propTypes,
  inPlanCreationFlow: PropTypes.bool,
};

MasterPlanSettingsCustomQuotaSetupPanel.defaultProps = {
  className: '',
  quota: null,
  inPlanCreationFlow: false,
};

export default MasterPlanSettingsCustomQuotaSetupPanel;
