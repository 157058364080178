import React from 'react';

import { CURRENCIES } from '@palette/constants/currency';
import { comaSeparatorFormatter } from '@palette/helpers/CommonHelper';

export const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case CURRENCIES.EUR:
      return '€';
    case CURRENCIES.USD:
      return '$';
    case CURRENCIES.XAF:
      return 'FCFA';
    default:
      return currencyCode;
  }
};

export const addCurrency = (value, currencyCode) => {
  switch (currencyCode) {
    case CURRENCIES.EUR:
      return `${value}€`;
    case CURRENCIES.USD:
      return `$${value}`;
    case CURRENCIES.XAF:
      return `${value} FCFA`;
    default:
      return `${currencyCode} ${value}`;
  }
};

export const removeCurrency = (value, currencyCode) => {
  switch (currencyCode) {
    case CURRENCIES.EUR:
      return `${value}`.replace('€', '');
    case CURRENCIES.USD:
      return `${value}`.replace('$', '');
    case CURRENCIES.XAF:
      return `${value}`.replace(' FCFA', '');
    default:
      return `${value}`.replace(`${currencyCode} `, '');
  }
};

export const addCurrencyToNode = (node, currencyCode) => {
  switch (currencyCode) {
    case CURRENCIES.EUR:
      return (
        <>
          {node}
          €
        </>
      );
    case CURRENCIES.USD:
      return (
        <>
          $
          {node}
        </>
      );
    case CURRENCIES.XAF:
      return (
        <>
          {node}
          &nbsp;
          FCFA
        </>
      );
    default:
      return (
        <>
          {currencyCode}
          &nbsp;
          {node}
        </>
      );
  }
};

export const formatPrice = (value, currencyCode) => {
  const parsedValue = parseFloat(value).toFixed(2);
  const formattedValue = comaSeparatorFormatter(parsedValue);
  return addCurrency(formattedValue, currencyCode);
};
