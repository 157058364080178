import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import HistoryFilled from '@palette/components/utils/Icons/HistoryFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import HistoryExchangeRateModal from '@palette/components/company/HistoryExchangeRateModal/HistoryExchangeRateModal';
import ExchangeRateModal from '@palette/components/company/ExchangeRateModal/ExchangeRateModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './HistoryExchangeRateButton.less';

const classNames = bindClassNames.bind(styles);

const HistoryExchangeRateButton = ({ className, from, to, rate, totalRates }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [historyTooltipVisible, setHistoryTooltipVisible] = useState(false);
  const [historyExchangeRateModalIsVisible, showHistoryExchangeRateModal] = useState(false);
  const [exchangeRateModalIsVisible, showExchangeRateModal] = useState(false);

  const handleSeeHistoryClick = () => {
    setHistoryTooltipVisible(false);
    showHistoryExchangeRateModal(true);
  };

  const handleNewRateClick = () => {
    showHistoryExchangeRateModal(false);
    showExchangeRateModal(true);
  };

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tooltip
        title={t('companyExchangeRates.history.tooltip.label', { count: totalRates })}
        visible={historyTooltipVisible}
        onVisibleChange={(visible) => setHistoryTooltipVisible(visible)}
      >
        <Button
          className={styles.historyButton}
          type="link"
          icon={<HistoryFilled className={styles.icon} width={18} height={18} />}
          onClick={handleSeeHistoryClick}
        />
      </Tooltip>
      {historyExchangeRateModalIsVisible && (
        <HistoryExchangeRateModal
          visible
          from={from}
          to={to}
          onAddNewRateClick={handleNewRateClick}
          onClose={() => showHistoryExchangeRateModal(false)}
        />
      )}
      {exchangeRateModalIsVisible && (
        <ExchangeRateModal
          visible
          type="edit"
          from={from}
          to={to}
          rate={rate}
          onClose={() => showExchangeRateModal(false)}
        />
      )}
    </div>
  );
};

HistoryExchangeRateButton.propTypes = {
  className: PropTypes.string,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  totalRates: PropTypes.number.isRequired,
};

HistoryExchangeRateButton.defaultProps = {
  className: '',
};

export default HistoryExchangeRateButton;
