import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import UserStatementPlanCollapse from '@palette/components/statement/userStatement/UserStatementPlanCollapse/UserStatementPlanCollapse';
import Input from '@palette/components/designSystem/Input/Input';

import * as UserStatementModel from '@palette/models/UserStatement';

import styles from './UserStatementPlans.less';

const classNames = bindClassNames.bind(styles);

const UserStatementPlans = ({ className, userStatement }) => {
  const { t } = useTranslation();

  const [searchedDeal, setSearchedDeal] = useState('');

  const searchDealInputNode = useMemo(() => (
    <Input
      className={styles.dealSearch}
      type="search"
      placeholder={t('userStatementPlans.dealSearch.placeholder')}
      onChange={setSearchedDeal}
      value={searchedDeal}
    />
  ), [searchedDeal, setSearchedDeal]);

  const contentNode = useMemo(() => {
    const plansCollapseNodes = userStatement.plans.map((userStatementPlan) => (
      <UserStatementPlanCollapse key={userStatementPlan.plan.id} className={styles.planCollapse} userStatementPlan={userStatementPlan} searchedDeal={searchedDeal} />
    ));

    return (
      <div className={styles.plans}>
        {plansCollapseNodes}
      </div>
    );
  }, [userStatement, searchedDeal]);

  if (userStatement === null || userStatement.plans.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {searchDealInputNode}
      {contentNode}
    </div>
  );
};

UserStatementPlans.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
};

UserStatementPlans.defaultProps = {
  className: '',
  userStatement: null,
};

export default UserStatementPlans;
