import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import * as NotebookCodeCellRequiredInformationStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookCodeCellRequiredInformationStrategy';

const classNames = bindClassNames.bind();

const NotebookCodeCellRequiredInformationContentStrategy = ({ className, content }) => {
  const { position, field } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notebookCell.errors.type.notebook.codeCell.required.information.content"
        values={{ position, field }}
      />
    </span>
  );
};

NotebookCodeCellRequiredInformationContentStrategy.propTypes = {
  className: PropTypes.string,
  content: NotebookCodeCellRequiredInformationStrategyModel.propTypes.isRequired,
};

NotebookCodeCellRequiredInformationContentStrategy.defaultProps = {
  className: '',
};

export default NotebookCodeCellRequiredInformationContentStrategy;
