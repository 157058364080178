/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _map from 'lodash/map';

import { STATUSES } from '@palette/constants/threads';
import _assign from 'lodash/assign';

/*
 * Initial State
 */
const initialState = {
  getListIsPending: false,
  getOneIsPending: false,
  createIsPending: false,
  addCommentIsPending: false,
  setStatusIsPending: false,
  list: Object.values(STATUSES)
    .reduce((res, status) => ({
      ...res,
      [status]: [],
    }), {}),
  byId: {},
  byMongoCollectionAndId: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get all notifications */
    getList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { threads = [] } = payload;
      state.list = Object.values(STATUSES)
        .reduce((res, status) => ({
          ...res,
          [status]: _map(threads.filter((thread) => thread.status === status), 'id'),
        }), {});

      state.byId = _merge(state.byId, _keyBy(threads, 'id'));
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Get one thread */
    getOne: (state) => {
      state.getOneIsPending = true;
    },
    setOne: (state, { payload }) => {
      const { mongoCollection, mongoId, thread, merge = false } = payload;

      state.byMongoCollectionAndId = _assign(state.byMongoCollectionAndId, {
        [`${mongoCollection}_${mongoId}`]: thread !== null ? thread.id : null,
      });

      if (thread !== null) {
        if (merge) {
          let storedThread = {};
          if (state.byId[thread.id]) {
            storedThread = original(state.byId[thread.id]);
          }
          const newThread = {
            ...thread,
            lastComment: thread.lastComment !== null ? thread.lastComment : storedThread.lastComment,
            [thread.type]: thread[thread.type] !== null ? thread[thread.type] : storedThread[storedThread.type],
          };
          state.byId = _merge(state.byId, { [thread.id]: newThread });
        } else {
          state.byId = _assign(state.byId, { [thread.id]: thread });
        }
      }
    },
    getOneCompleted: (state) => {
      state.getOneIsPending = false;
    },
    /* Create a Thread */
    create: (state) => {
      state.createIsPending = true;
    },
    createCompleted: (state) => {
      state.createIsPending = false;
    },
    /* Add a Comment */
    addComment: (state) => {
      state.addCommentIsPending = true;
    },
    addCommentCompleted: (state) => {
      state.addCommentIsPending = false;
    },
    /* Set Status */
    setStatus: (state) => {
      state.setStatusIsPending = true;
    },
    setStatusCompleted: (state) => {
      state.setStatusIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getList = (state) => root(state).list;
const getById = (state) => root(state).byId;
const getByMongoCollectionAndId = (state) => root(state).byMongoCollectionAndId;

const getListIsPending = (state) => root(state).getListIsPending;
const getOneIsPending = (state) => root(state).getOneIsPending;
const createIsPending = (state) => root(state).createIsPending;
const addCommentIsPending = (state) => root(state).addCommentIsPending;
const setStatusIsPending = (state) => root(state).setStatusIsPending;

const getThreadsListIdsByStatus = createSelector(
  [getList, getProps],
  (list, { threadsStatus = STATUSES.PENDING }) => list[threadsStatus],
);
const getThreadsListByStatus = createSelector(
  [getThreadsListIdsByStatus, getById],
  (listIds, byId) => listIds.map((threadId) => byId[threadId] || null),
);
const getNbOfThreadsByStatus = createSelector(
  [getThreadsListIdsByStatus],
  (listIds) => listIds.length,
);

const getThreadByMongoCollectionAndId = createSelector(
  [getByMongoCollectionAndId, getById, getProps],
  (byMongoCollectionAndId, byId, { mongoCollection, mongoId }) => {
    const threadId = byMongoCollectionAndId[`${mongoCollection}_${mongoId}`] || null;
    if (threadId === null) return null;
    return byId[threadId] || null;
  },
);

export const selectors = {
  getListIsPending,
  getOneIsPending,
  createIsPending,
  addCommentIsPending,
  setStatusIsPending,
  getThreadsListIdsByStatus,
  getThreadsListByStatus,
  getNbOfThreadsByStatus,
  getThreadByMongoCollectionAndId,
};
