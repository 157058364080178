import React from 'react';

import { TableOutlined } from '@ant-design/icons';

import ConnectSalesforceButton
  from '../../components/admin/ConnectCRM/buttons/connectSalesforceButton';
import ConnectSellsyButton
  from '../../components/admin/ConnectCRM/buttons/connectSellsyButton';
import ConnectHubspotButtonOauth
  from '../../components/admin/ConnectCRM/buttons/connectHubspotButtonOauth';
import ConnectQuickBooksButtonOauth
  from '../../components/admin/ConnectCRM/buttons/connectQuickBooksButtonOauth';
import ConnectOutreachButtonOauth
  from '../../components/admin/ConnectCRM/buttons/connectOutreachButtonOauth';
import ConnectXeroButtonOauth
  from '../../components/admin/ConnectCRM/buttons/connectXeroButtonOauth';
import ConnectJobadderButtonOauth
  from '../../components/admin/ConnectCRM/buttons/connectJobadderButtonOauth';
import SourceVignette
  from '../../components/admin/ConnectCRM/sourceVignette';

import { CONNECTOR_TYPES } from '../../services/constants';

export const availableConnectorsList = (loading, profile, onClick = {}) => ([
  {
    name: 'HUBSPOT',
    type: CONNECTOR_TYPES.CRM,
    template: ({ disabled }) => <ConnectHubspotButtonOauth disabled={disabled} />,
  },
  {
    name: 'PIPEDRIVE',
    type: CONNECTOR_TYPES.CRM,
    template: ({ disabled }) => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/pipedrive_icon.png" alt="Pipedrive" style={{ width: '38px' }} />}
        type={CONNECTOR_TYPES.CRM}
        name="Pipedrive"
        id="PIPEDRIVE"
        disabled={disabled}
        onClick={() => onClick('connectpipedrive')}
      />
    ),
  },
  {
    name: 'SALESFORCE',
    type: CONNECTOR_TYPES.CRM,
    template: () => <ConnectSalesforceButton />,
  },
  {
    name: 'JOBADDER',
    type: CONNECTOR_TYPES.CRM,
    template: ({ disabled }) => <ConnectJobadderButtonOauth disabled={disabled} />,
  },
  { name: 'SELLSY', type: CONNECTOR_TYPES.CRM, template: () => <ConnectSellsyButton /> },
  { name: 'QUICKBOOKS', type: CONNECTOR_TYPES.BILLING, template: () => <ConnectQuickBooksButtonOauth /> },
  {
    name: 'QUICKBOOKS_SANDBOX',
    type: CONNECTOR_TYPES.BILLING,
    template: () => <ConnectQuickBooksButtonOauth sandbox />,
    hide: (!profile || (
      profile.userData.company._id !== '5faadaa4ecebaf7acea5d5af'
      && profile.userData.company._id !== '61010f061e1f1b128da6ab02' // staging main account
      && profile.userData.company._id !== '61128cc7fbfc1f32be14ff3b' // (STAGING company: "QBO #1")
      && profile.userData.company._id !== '61128ccafbfc1f32be14ff40' // (STAGING company: "QBO #2")
    )),
  },
  { name: 'OUTREACH', type: CONNECTOR_TYPES.CRM, template: ({ disabled }) => <ConnectOutreachButtonOauth disabled={disabled} /> },
  {
    name: 'CLOSE',
    type: CONNECTOR_TYPES.CRM,
    template: ({ disabled }) => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/close-logo.png" alt="close" style={{ width: '38px' }} />}
        type={CONNECTOR_TYPES.CRM}
        name="Close CRM"
        id="CLOSE"
        disabled={disabled}
        onClick={() => onClick('connectclose')}
      />
    ),
  },
  {
    name: 'STRIPE',
    type: CONNECTOR_TYPES.BILLING,
    template: ({ disabled }) => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/stripe-logo.png" alt="stripe" style={{ width: '32px' }} />}
        type={CONNECTOR_TYPES.BILLING}
        name="Stripe"
        id="STRIPE"
        disabled={disabled}
        onClick={() => onClick('connectstripe')}
      />
    ),
  },
  {
    name: 'SAASOPTICS',
    type: CONNECTOR_TYPES.BILLING,
    template: () => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/saasoptics_logo.png" alt="SaaSOptics" style={{ width: '40px' }} />}
        type={CONNECTOR_TYPES.BILLING}
        name="SaaSOptics"
        id="SAASOPTICS"
        onClick={() => onClick('connectsaasoptics')}
      />
    ),
  },
  {
    name: 'SAPLING',
    type: CONNECTOR_TYPES.HR,
    template: () => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/saplinghr.png" alt="Sapling HR" style={{ width: '44px' }} />}
        type={CONNECTOR_TYPES.HR}
        name="Sapling HR"
        id="SAPLING"
        onClick={() => onClick('connectsapling')}
      />
    ),
  },
  {
    name: 'CHARGEBEE',
    type: CONNECTOR_TYPES.BILLING,
    template: () => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/chargebee-icon.svg" alt="chargebee" style={{ width: '38px' }} />}
        type={CONNECTOR_TYPES.BILLING}
        name="Chargebee"
        id="CHARGEBEE"
        onClick={() => onClick('connectchargebee')}
      />
    ),
  },
  {
    name: 'XERO',
    type: CONNECTOR_TYPES.BILLING,
    template: () => (
      <ConnectXeroButtonOauth />
    ),
  },
  {
    name: 'GSHEET',
    type: CONNECTOR_TYPES.RAW_DATA,
    template: ({ disabled }) => (
      <SourceVignette
        icon={<img src="/img/google-sheets-logo.png" alt="ghseet" style={{ width: '38px' }} />}
        type={CONNECTOR_TYPES.RAW_DATA}
        disabled={disabled}
        name="Google Sheets"
        onClick={disabled ? null : () => onClick('gsheet')}
      />
    ),
  },
  {
    name: 'CSV',
    type: CONNECTOR_TYPES.RAW_DATA,
    template: ({ disabled }) => (
      <SourceVignette
        icon={<TableOutlined />}
        type={CONNECTOR_TYPES.RAW_DATA}
        disabled={disabled}
        name="CSV"
        onClick={disabled ? null : () => onClick('csv')}
      />
    ),
  },
  {
    name: 'MS SQL',
    type: CONNECTOR_TYPES.RAW_DATA,
    template: ({ disabled }) => (
      <SourceVignette
        icon={<img src="/img/ms_sql.png" alt="microsoft sql" style={{ width: '44px' }} />}
        type={CONNECTOR_TYPES.RAW_DATA}
        disabled={disabled}
        name="MS SQL"
        onClick={() => onClick('connectmssql')}
      />
    ),
  },
  {
    name: 'My SQL',
    type: CONNECTOR_TYPES.RAW_DATA,
    template: ({ disabled }) => (
      <SourceVignette
        icon={<img src="/img/mysql_logo.png" alt="mysql" style={{ width: '44px' }} />}
        type={CONNECTOR_TYPES.RAW_DATA}
        disabled={disabled}
        name="My SQL"
        onClick={() => onClick('connectmysql')}
      />
    ),
  },
  {
    name: 'NETSUITE',
    type: CONNECTOR_TYPES.BILLING,
    template: () => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/netsuite.png" alt="NetSuite" style={{ width: '40px' }} />}
        type={CONNECTOR_TYPES.BILLING}
        name="NetSuite"
        id="NETSUITE"
        onClick={() => onClick('connectnetsuite')}
      />
    ),
  },
  {
    name: 'PENNYLANE',
    type: CONNECTOR_TYPES.BILLING,
    template: ({ disabled }) => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/pennylane-logo.png" alt="pennylane" style={{ width: '44px' }} />}
        type={CONNECTOR_TYPES.BILLING}
        name="Pennylane"
        id="PENNYLANE"
        disabled={disabled}
        onClick={() => onClick('connectpennylane')}
      />
    ),
  },
  {
    name: 'CRELATE',
    type: CONNECTOR_TYPES.CRM,
    template: ({ disabled }) => (
      <SourceVignette
        loading={loading}
        icon={<img src="/img/crelate-logo.png" alt="crelate" style={{ width: '44px' }} />}
        type={CONNECTOR_TYPES.CRM}
        name="Crelate"
        id="CRELATE"
        disabled={disabled}
        onClick={() => onClick('connectcrelate')}
      />
    ),
  },
  {
    name: 'SNOWFLAKE',
    type: CONNECTOR_TYPES.RAW_DATA,
    template: ({ disabled }) => (
      <SourceVignette
        icon={<img src="/img/snowflake-logo.png" alt="snowflake" style={{ width: '44px' }} />}
        type={CONNECTOR_TYPES.RAW_DATA}
        disabled={disabled}
        name="Snowflake"
        onClick={() => onClick('connectsnowflake')}
      />
    ),
  },
  {
    name: 'REDSHIFT',
    type: CONNECTOR_TYPES.RAW_DATA,
    template: ({ disabled }) => (
      <SourceVignette
        icon={<img src="/img/redshift-logo.png" alt="redshift" style={{ width: '44px' }} />}
        type={CONNECTOR_TYPES.RAW_DATA}
        disabled={disabled}
        name="Redshift"
        onClick={() => onClick('connectredshift')}
      />
    ),
  },
]);
