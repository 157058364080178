import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import styles from './UpdateUserTypeConfirmationModal.less';

const UpdateUserTypeConfirmationModal = ({ visible, onCancel, onConfirm }) => {
  const { t } = useTranslation();

  const cleanAndClose = () => {
    onCancel();
  };

  const confirmUpdateUserType = () => {
    onConfirm();
  };

  return (
    <Modal
      className={styles.modal}
      title={t('updateUserTypeConfirmationModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={confirmUpdateUserType}
      okText={t('updateUserTypeConfirmationModal.confirm')}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('updateUserTypeConfirmationModal.content') }} />
    </Modal>
  );
};

UpdateUserTypeConfirmationModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
};

UpdateUserTypeConfirmationModal.defaultProps = {
  visible: false,
  onCancel: () => {},
};

export default UpdateUserTypeConfirmationModal;
