import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { replaceVariables } from '@palette/helpers/FormulaHelper';

import styles from './Formula.less';

const classNames = bindClassNames.bind(styles);

const Formula = ({ className, formula, scope }) => {
  const transformedFormula = replaceVariables(formula, scope);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {transformedFormula}
    </div>
  );
};

Formula.propTypes = {
  className: PropTypes.string,
  formula: PropTypes.string.isRequired,
  scope: PropTypes.object,
};

Formula.defaultProps = {
  className: '',
  scope: {},
};

export default Formula;
