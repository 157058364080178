import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';

import RawDataAddEditModal from '@palette/components/resources/RawDataAddEditModal/RawDataAddEditModal';
import RawDataDeleteModal from '@palette/components/resources/RawDataDeleteModal/RawDataDeleteModal';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { getMoment } from '@palette/helpers/MomentHelper';

import styles from './RawDataEntryDetails.less';

const RawDataEntryDetails = ({
  resourceId,
  showActionButtons,
  id,
  path,
  overwrites,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const [deleteModalIsVisible, showDeleteModal] = useState(false);
  const [addEditModalIsVisible, showAddEditModal] = useState(false);
  const [editTooltipVisible, setEditTooltipVisible] = useState(false);
  const [deleteTooltipVisible, setDeleteTooltipVisible] = useState(false);

  const handleDelete = (event) => {
    event.stopPropagation();
    setDeleteTooltipVisible(false);
    showDeleteModal(true);
  };

  const closeDeleteModal = (event) => {
    event.stopPropagation();
    showDeleteModal(false);
  };

  const handleAddEdit = (event) => {
    event.stopPropagation();
    setEditTooltipVisible(false);
    showAddEditModal(true);
  };

  const closeAddEditModal = () => {
    showAddEditModal(false);
  };

  const fieldOverwrites = overwrites?.filter((o) => JSON.stringify(o.path) === JSON.stringify(path));
  const lastOverwrite = fieldOverwrites && _sortBy(fieldOverwrites, ['created']).slice(-1);
  const lastEditedAt = lastOverwrite && lastOverwrite[0]?.created;

  return (
    <div className={styles.wrapper} id={`root-${id}`}>
      {lastEditedAt && (
        <span className={styles.editedAt}>
          {t('rawData.lastEditedAt', { timeAgo: moment(lastEditedAt).fromNow() })}
        </span>
      )}
      {showActionButtons && (
        <div className={styles.buttons}>
          <Tooltip
            title={t('actions.edit')}
            visible={editTooltipVisible}
            onVisibleChange={(visible) => setEditTooltipVisible(visible)}
          >
            <Button
              className={styles.actionButton}
              type="link"
              icon={<PenFilled className={styles.actionIcon} />}
              onClick={(event) => handleAddEdit(event)}
            />
          </Tooltip>
          <Tooltip
            title={t('actions.delete')}
            visible={deleteTooltipVisible}
            onVisibleChange={(visible) => setDeleteTooltipVisible(visible)}
          >
            <Button
              className={styles.actionButton}
              type="link"
              icon={<TrashFilled className={styles.actionIcon} />}
              onClick={(event) => handleDelete(event)}
            />
          </Tooltip>
          {
            deleteModalIsVisible && (
              <div onClick={(event) => event.stopPropagation()}>
                <RawDataDeleteModal
                  visible
                  onClose={(event) => closeDeleteModal(event)}
                  resourceId={resourceId}
                  path={path}
                />
              </div>
            )
          }
          {
            addEditModalIsVisible && (
              <div onClick={(event) => event.stopPropagation()}>
                <RawDataAddEditModal
                  visible
                  onClose={() => closeAddEditModal()}
                  resourceId={resourceId}
                  path={path}
                  action="edit"
                />
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};

RawDataEntryDetails.propTypes = {
  resourceId: PropTypes.string,
  showActionButtons: PropTypes.bool,
  path: PropTypes.array,
  overwrites: PropTypes.array,
  id: PropTypes.any,
};

RawDataEntryDetails.defaultProps = {
  resourceId: '',
  showActionButtons: true,
  path: [],
  overwrites: [],
  id: undefined,
};

export default RawDataEntryDetails;
