import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import TeamSelector from '@palette/components/team/TeamSelector/TeamSelector';
import UsersStatementsList from '@palette/components/statement/statementPeriodUsers/UsersStatementsList/UsersStatementsList';
import UsersStatementsApproveAllAction from '@palette/components/statement/statementPeriodUsers/UsersStatementsApproveAllAction/UsersStatementsApproveAllAction';
import UsersStatementsMarkAllAsPaidAction from '@palette/components/statement/statementPeriodUsers/UsersStatementsMarkAllAsPaidAction/UsersStatementsMarkAllAsPaidAction';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import UsersStatementsExportToPayrollAction from '@palette/components/statement/statementPeriodUsers/UsersStatementsExportToPayrollAction/UsersStatementsExportToPayrollAction';
import StatementRecomputeWarning from '@palette/components/statement/statementCommon/StatementRecomputeWarning/StatementRecomputeWarning';

import { getStatementUserApprovalStatus, statementPeriodIsInFuture } from '@palette/helpers/StatementHelper';

import { useStatementPeriodStatus } from '@palette/hooks/StatementHooks';

import { STATEMENT_PERIOD_STATUSES, STATEMENT_APPROVAL_STATUSES } from '@palette/constants/statements';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import { actions as StatementsActions } from '@palette/state/Statements';
import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './UsersStatements.less';

const classNames = bindClassNames.bind(styles);

const UsersStatements = ({ className, statementPeriod, currency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchedUser, setSearchedUser] = useState('');
  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const statementPeriodStatus = useStatementPeriodStatus(statementPeriod);

  const selectedTeam = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId: selectedTeamId }));

  const filteredUsersStatements = useMemo(() => {
    let finalUsers = statementPeriod.userStatements;

    if (selectedTeam !== null) {
      const membersIds = selectedTeam.members.map((teamUser) => teamUser.user.id);
      finalUsers = finalUsers.filter((userStatement) => membersIds.includes(userStatement.user.id));
    }

    if (searchedUser !== '') {
      finalUsers = finalUsers.filter((userStatement) => userStatement.user.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1);
    }

    return finalUsers;
  }, [
    statementPeriod,
    searchedUser,
    selectedTeam,
    selectedTeamId,
  ]);

  const hasAtLeastOneDirtyStatement = useMemo(() => filteredUsersStatements.some((userStatement) => userStatement.isDirty), [
    filteredUsersStatements,
  ]);

  const handleExport = useCallback(() => {
    const statementIds = filteredUsersStatements.map((statement) => statement.id);

    dispatch(StatementsActions.downloadPeriodUsersStatements({
      statementIds,
      statementPeriodId: statementPeriod.id,
      currency,
    }));
  }, [filteredUsersStatements, statementPeriod, currency]);

  const actionsNode = useMemo(() => {
    if (filteredUsersStatements.length === 0) return null;

    const actionIsDisabled = !statementPeriodStatus
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAID
      || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE;

    const areAllStatementsApproved = filteredUsersStatements.every((userStatement) => getStatementUserApprovalStatus(userStatement) === STATEMENT_APPROVAL_STATUSES.APPROVED);

    const areAllStatementsPaid = filteredUsersStatements.every((userStatement) => userStatement.isPaid);

    return (
      <div className={styles.actions}>
        <Button
          className={classNames({
            actionButton: true,
            exportButton: true,
          })}
          type="link"
          icon={<DownloadFilled width={20} height={20} />}
          onClick={handleExport}
        >
          {t('userStatements.exportAll')}
        </Button>
        <UsersStatementsApproveAllAction
          className={styles.actionButton}
          userStatements={filteredUsersStatements}
          statementPeriod={statementPeriod}
          currency={currency}
          disabled={areAllStatementsApproved || areAllStatementsPaid}
        />
        <UsersStatementsMarkAllAsPaidAction
          className={styles.actionButton}
          userStatements={filteredUsersStatements}
          statementPeriod={statementPeriod}
          currency={currency}
          disabled={actionIsDisabled || areAllStatementsPaid}
        />
        {!statementPeriodIsInFuture(statementPeriod) && (
          <UsersStatementsExportToPayrollAction
            className={styles.actionButton}
            userStatements={filteredUsersStatements}
            statementPeriod={statementPeriod}
            selectedTeamId={selectedTeamId}
            currency={currency}
          />
        )}
      </div>
    );
  }, [
    filteredUsersStatements,
    statementPeriod,
    currency,
    handleExport,
    selectedTeamId,
    statementPeriodStatus,
  ]);

  const recomputeWarningNode = useMemo(() => {
    if (!hasAtLeastOneDirtyStatement) return null;
    return <StatementRecomputeWarning />;
  }, [hasAtLeastOneDirtyStatement]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {recomputeWarningNode}
      <div className={styles.header}>
        <div className={styles.title}>
          {t('userStatements.title.usersCount', { count: statementPeriod.usersCount })}
        </div>
        <div className={styles.filters}>
          <Input
            className={styles.userSearch}
            type="search"
            placeholder={t('userStatements.userSearch.placeholder')}
            onChange={setSearchedUser}
            value={searchedUser}
          />
          <TeamSelector
            className={styles.selector}
            selectedTeam={selectedTeamId}
            onTeamSelected={setSelectedTeamId}
          />
        </div>
        {actionsNode}
      </div>
      <UsersStatementsList userStatements={filteredUsersStatements} currency={currency} />
    </div>
  );
};

UsersStatements.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  currency: PropTypes.string,
};

UsersStatements.defaultProps = {
  className: '',
  currency: null,
};

export default UsersStatements;
