import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Avatar from '@palette/components/user/Avatar/Avatar';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import StatementApproversStatusIcon from '@palette/components/statement/statementCommon/StatementApproversStatusIcon/StatementApproversStatusIcon';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import * as StatementV3ActionModel from '@palette/models/StatementV3Action';

import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './StatementV3ApprovalsListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3ApprovalsListItem = ({ className, approval, type, isInConflict }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const user = useSelector((state) => UsersSelectors.getUserById(state, { userId: approval.userId }));

  const avatarNode = useMemo(() => {
    if (type === 'inline') {
      return (
        <UserProfile
          className={styles.avatar}
          user={user}
          avatarSize={24}
          avatarStyle={{
            fontSize: '1.2rem',
            width: '2.4rem',
            height: '2.4rem',
          }}
        />
      );
    }

    return (
      <Avatar
        className={styles.avatar}
        user={user}
        size={24}
        style={{
          fontSize: '1.2rem',
          width: '2.4rem',
          height: '2.4rem',
        }}
      />
    );
  }, [user, type]);

  const statusNode = useMemo(() => (
    <StatementApproversStatusIcon
      className={styles.status}
      isInConflict={isInConflict}
    />
  ), [isInConflict]);

  const getDate = useMemo(() => moment(approval.date).format('M/DD/YYYY'), [approval]);

  const getAmount = useMemo(() => formatPrice(approval.amount, approval.currency), [approval]);

  const getUserName = useMemo(() => user.displayName, [user]);

  const contentNode = useMemo(() => {
    if (type === 'inline') {
      return (
        <div className={styles.inline}>
          <span className={styles.date}>{getDate}</span>
          {avatarNode}
          <span className={styles.amount}>{getAmount}</span>
          {statusNode}
        </div>
      );
    }

    return (
      <Tooltip
        title={getDate}
        content={(
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('statement.approvals.tooltip.label', {
                userName: getUserName,
                amount: getAmount,
              }),
            }}
          />
        )}
      >
        {avatarNode}
        {statusNode}
      </Tooltip>
    );
  }, [type, avatarNode, statusNode, getDate, getAmount, getUserName]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementV3ApprovalsListItem.propTypes = {
  className: PropTypes.string,
  approval: StatementV3ActionModel.propTypes.isRequired,
  type: PropTypes.oneOf(['withTooltip', 'inline']),
  isInConflict: PropTypes.bool,
};

StatementV3ApprovalsListItem.defaultProps = {
  className: '',
  type: 'withTooltip',
  isInConflict: false,
};

export default StatementV3ApprovalsListItem;
