import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CalendarCheckFilled from '@palette/components/utils/Icons/CalendarCheckFilled';
import CardItem from '@palette/components/settings/CardItem/CardItem';
import Input from '@palette/components/designSystem/Input/Input';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS, STATEMENT_FX_RATES_MODE } from '@palette/constants/profile';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './StatementFxRates.less';

const classNames = bindClassNames.bind(styles);

const StatementFxRates = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company } = profile.userData;

  const [statementFxRateMode, setStatementFxRateMode] = useState(company.statementFxRateMode);

  const updateStatementFxRateModeIsPending = useSelector(ProfileSelectors.updateStatementFxRateModeIsPending);

  const updateStatementFxRates = useCallback((value) => {
    if (!updateStatementFxRateModeIsPending) {
      setStatementFxRateMode(value);
      dispatch(ProfileActions.updateStatementFxRateMode({ value }));
    }
  }, [updateStatementFxRateModeIsPending]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('statementFxRates.block.title')}
      icon={<CalendarCheckFilled width={20} height={20} />}
    >
      <div className={styles.textContent}>
        {t('statementFxRates.content.intro')}
      </div>
      <Input
        className={styles.inputContainer}
        type="radio"
        radioValues={Object.keys(STATEMENT_FX_RATES_MODE).map((mode) => ({
          label: t(`statementFxRates.mode.${mode.toLowerCase()}`),
          value: STATEMENT_FX_RATES_MODE[mode],
        }))}
        radioAlign="col"
        value={statementFxRateMode}
        onChange={updateStatementFxRates}
        disabled={updateStatementFxRateModeIsPending}
      />
    </CardItem>
  );
};

StatementFxRates.propTypes = {
  className: PropTypes.string,
};

StatementFxRates.defaultProps = {
  className: '',
};

export default StatementFxRates;
