import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Collapse as AntCollapse } from 'antd';

import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import ChevronUpLine from '@palette/components/utils/Icons/ChevronUpLine';

import bindClassNames from 'classnames/bind';

import styles from './Collapse.less';

const classNames = bindClassNames.bind(styles);

const ICON_DEFAULT_SIZE = 20;

const Collapse = ({
  className,
  panels,
  size,
  prefixKey,
  keyValue,
  iconWidth,
  iconHeight,
  iconStartDirection,
  ...otherProps
}, ref = {}) => {
  const expandIcon = ({ isActive }) => {
    const classname = classNames({
      icon: true,
      isActive,
      [iconStartDirection]: true,
    });
    if (iconStartDirection === 'down') {
      return (
        <ChevronDownLine
          className={classname}
          width={iconWidth}
          height={iconHeight}
        />
      );
    }
    if (iconStartDirection === 'up') {
      return (
        <ChevronUpLine
          className={classname}
          width={iconWidth}
          height={iconHeight}
        />
      );
    }
    return (
      <ChevronRightLine
        className={classname}
        width={iconWidth}
        height={iconHeight}
      />
    );
  };

  let refProp = {};
  if (Object.keys(ref).length !== 0) {
    refProp = {
      ref,
    };
  }

  return (
    <AntCollapse
      {...refProp}
      className={classNames({
        wrapper: true,
        big: size === 'big',
        [className]: className !== '',
      })}
      expandIcon={expandIcon}
      {...otherProps}
    >
      {
        panels.map((panel, i) => (
          <AntCollapse.Panel key={`${prefixKey}${keyValue || i}`} header={panel.title} extra={panel.extra}>
            {panel.content}
          </AntCollapse.Panel>
        ))
      }
    </AntCollapse>
  );
};

/* eslint-disable react/no-unused-prop-types */
const propTypesShape = {
  className: PropTypes.string,
  panels: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.any,
    content: PropTypes.any,
    extra: PropTypes.node,
  })).isRequired,
  size: PropTypes.oneOf(['default', 'big']),
  prefixKey: PropTypes.string,
  keyValue: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconStartDirection: PropTypes.oneOf(['down', 'right', 'up']),
};

const defaultPropsShape = {
  className: '',
  size: 'default',
  prefixKey: '',
  keyValue: '',
  iconWidth: ICON_DEFAULT_SIZE,
  iconHeight: ICON_DEFAULT_SIZE,
  iconStartDirection: 'right',
};

Collapse.propTypes = propTypesShape;
Collapse.defaultProps = defaultPropsShape;

export const CollapseRefForwarded = forwardRef((props, ref) => Collapse(props, ref));
CollapseRefForwarded.propTypes = propTypesShape;
CollapseRefForwarded.defaultProps = defaultPropsShape;

export default Collapse;
