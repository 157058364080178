import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Button from '@palette/components/designSystem/Button/Button';
import ArrowNewPageLine from '@palette/components/utils/Icons/ArrowNewPageLine';
import AuditTrailIcon from '@palette/components/auditTrail/AuditTrailIcon/AuditTrailIcon';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import { getDescriptionParams, getLinkParams } from '@palette/helpers/AuditTrailHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { AUDIT_TRAIL_EVENTS } from '@palette/constants/analytics';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import * as AuditTrailModel from '@palette/models/AuditTrail';

import styles from './AuditTrailListItem.less';

const classNames = bindClassNames.bind(styles);

const AuditTrailListItem = ({ className, auditTrail }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const linkParams = useMemo(() => getLinkParams(auditTrail), [auditTrail]);

  const handleClick = useCallback(() => {
    if (!linkParams) return;

    const { entityType, actionType } = auditTrail;

    dispatch(AnalyticsActions.sendEvent({ event: AUDIT_TRAIL_EVENTS.CLICK_LINK, params: { ENTITY: entityType, ACTION: actionType } }));

    redirectTo(linkParams);
  }, [linkParams, auditTrail]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={classNames({ contentContainer: true, noLink: !linkParams })}>
        <div className={styles.header}>
          <AuditTrailIcon className={styles.entityIcon} entity={auditTrail.entityType} />
          <div className={styles.title}>
            {t(`auditTrail.filterByType.option.${auditTrail.entityType.toLowerCase()}`)}
          </div>
          <ChevronRightLine className={styles.arrowIcon} width={24} height={24} />
          <div className={classNames({ title: true, last: true })}>
            {t(`auditTrail.action.title.${auditTrail.actionType.toLowerCase()}`)}
          </div>
          <div className={styles.date}>
            {moment(auditTrail.date).format('lll')}
          </div>
        </div>
        <div className={styles.footer}>
          <UserProfile
            className={styles.userProfile}
            nameContainerClassName={styles.userProfileNameContainer}
            nameClassName={classNames({ userProfileName: true, link: !auditTrail.isImpersonated })}
            user={auditTrail.author}
            isPaletteUser={auditTrail.isImpersonated}
            additionalNode={(
              <span
                className={styles.userProfileAdditionalText}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t(`auditTrail.action.description.${auditTrail.actionType.toLowerCase()}`, getDescriptionParams(auditTrail)) }}
              />
            )}
            clickable
            avatarSize={24}
            avatarStyle={{
              fontSize: '1.2rem',
              width: '2.4rem',
              minWidth: '2.4rem',
              height: '2.4rem',
            }}
          />
        </div>
      </div>
      {linkParams && (
        <div className={styles.linkContainer}>
          <Button
            className={styles.linkButton}
            icon={(<ArrowNewPageLine width={12} height={12} />)}
            type="secondary"
            onClick={handleClick}
          >
            {t('auditTrail.list.item.link.label')}
          </Button>
        </div>
      )}
    </div>
  );
};

AuditTrailListItem.propTypes = {
  className: PropTypes.string,
  auditTrail: AuditTrailModel.propTypes.isRequired,
};

AuditTrailListItem.defaultProps = {
  className: '',
};

export default AuditTrailListItem;
