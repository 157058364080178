import PropTypes from 'prop-types';

import { manageBooleanAttribute, manageNumberAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'AutoFreeze';

export const propTypes = PropTypes.shape({
  enabled: PropTypes.bool,
  freezeAfter: PropTypes.number,
});

export const transform = (autoFreeze) => {
  if (!autoFreeze) {
    return null;
  }

  return ({
    enabled: manageBooleanAttribute(autoFreeze, 'enabled'),
    freezeAfter: manageNumberAttribute(autoFreeze, 'freezeAfter', null),
  });
};

export const transformList = (autoFreezes) => autoFreezes.map((autoFreeze) => transform(autoFreeze));
