import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import FolderSubFolders from '@palette/components/folders/FoldersRecursive/FolderSubFolders/FolderSubFolders';
import FolderResources from '@palette/components/folders/FoldersRecursive/FolderResources/FolderResources';
import FolderContent from '@palette/components/folders/FoldersRecursive/FolderContent/FolderContent';
import FolderResourcePreview from '@palette/components/folders/FoldersRecursive/FolderResourcePreview/FolderResourcePreview';

import { folderColorPropTypes, folderColorDefaultProps } from '@palette/components/folders/FoldersRecursive/FolderTitle/FolderTitle';

import * as FolderWithResourcesModel from '@palette/models/FolderWithResources';

import styles from './RootFolder.less';

const classNames = bindClassNames.bind(styles);

const RootFolder = ({
  className,
  folder,
  ItemComponent,
  onMoveFolder,
  onMoveEntity,
  folderColor,
  isPlanV3,
}) => {
  /* Folder management */
  const subFoldersNodes = useMemo(() => (
    <FolderSubFolders
      className={styles.subFolders}
      subFolderClassName={styles.subFolder}
      folder={folder}
      ItemComponent={ItemComponent}
      fromRoot
      onMoveFolder={onMoveFolder}
      onMoveEntity={onMoveEntity}
      folderColor={folderColor}
    />
  ), [folder, ItemComponent, onMoveFolder, onMoveEntity, folderColor]);

  const resourcesNodes = useMemo(() => (
    <FolderResources
      className={classNames({
        resources: true,
        paddingRight: isPlanV3,
      })}
      resourceClassName={styles.resource}
      folder={folder}
      ItemComponent={ItemComponent}
    />
  ), [folder, ItemComponent, isPlanV3]);

  const contentNode = useMemo(() => (
    <FolderContent className={styles.content} fromRoot onMoveFolder={onMoveFolder} onMoveEntity={onMoveEntity}>
      {subFoldersNodes}
      {resourcesNodes}
    </FolderContent>
  ), [subFoldersNodes, resourcesNodes, onMoveFolder, onMoveEntity]);

  return (
    <>
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
      <FolderResourcePreview folderColor={folderColor} isPlanV3={isPlanV3} />
    </>
  );
};

RootFolder.propTypes = {
  className: PropTypes.string,
  folder: FolderWithResourcesModel.propTypes.isRequired,
  ItemComponent: PropTypes.func.isRequired,
  onMoveFolder: PropTypes.func,
  onMoveEntity: PropTypes.func,
  folderColor: folderColorPropTypes,
  isPlanV3: PropTypes.bool,
};

RootFolder.defaultProps = {
  className: '',
  onMoveFolder: null,
  onMoveEntity: null,
  folderColor: folderColorDefaultProps,
  isPlanV3: false,
};

export default RootFolder;
