import { call, select } from 'redux-saga/effects';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { selectors as ProfileSelectors } from '@palette/state/Profile/slice';

export function* checkEndpointRights(serviceFunc, rights = []) {
  if (rights.length > 0) {
    const profile = yield select(ProfileSelectors.profile);
    const hasRights = hasAtLeastOneRight(profile, rights);

    if (!hasRights) {
      return {
        ok: false,
        status: 403,
      };
    }
  }

  return yield call(serviceFunc);
}
