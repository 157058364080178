import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import QueryBuilderAndOrCondition from '@palette/components/designSystem/QueryBuilderAndOrCondition/QueryBuilderAndOrCondition';

import { buildNewValue, isAndOrBlock } from '@palette/helpers/QueryBuilderHelper';

import {
  AND_OPERATOR, DEFAULT_AND_OR_GROUP_VALUE,
  DEFAULT_CONDITION_VALUE,
  OPERATOR_TO_STRING,
  OR_OPERATOR,
} from '@palette/constants/queryBuilder';

import styles from './QueryBuilderAndOrBlock.less';

const classNames = bindClassNames.bind(styles);

const QueryBuilderAndOrBlock = ({
  className,
  helperData,
  value,
  onChange,
  onDelete,
  prefix,
  disabled,
  ...otherProps
}) => {
  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    if (!_isEqual(internalValue, value)) {
      setInternalValue(value);
    }
  }, [value]);

  const isAndOperator = useMemo(() => (
    Object.keys(internalValue)[0] === AND_OPERATOR
  ), [internalValue]);

  const conditions = useMemo(() => {
    const valueKey = Object.keys(internalValue)[0];
    return internalValue[valueKey];
  }, [internalValue]);

  const handleChange = useCallback((newValue) => {
    setInternalValue(newValue);
    onChange(newValue);
  }, [onChange]);

  const handleUseAndOperator = useCallback((useAndOp) => {
    if (isAndOperator === useAndOp) return;

    const newValue = buildNewValue(useAndOp, conditions);

    handleChange(newValue);
  }, [internalValue, isAndOperator, conditions]);

  const handleAddCondition = useCallback(() => {
    const newValue = buildNewValue(isAndOperator, [...conditions, DEFAULT_CONDITION_VALUE]);

    handleChange(newValue);
  }, [internalValue, isAndOperator, conditions, handleChange]);

  const handleAddAndOrBlock = useCallback(() => {
    const newValue = buildNewValue(isAndOperator, [...conditions, DEFAULT_AND_OR_GROUP_VALUE]);

    handleChange(newValue);
  }, [internalValue, isAndOperator, conditions, handleChange]);

  const handleConditionChange = useCallback((index, newBlockValue) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1, newBlockValue);

    const newValue = buildNewValue(isAndOperator, newConditions);

    handleChange(newValue);
  }, [internalValue, isAndOperator, conditions, handleChange]);

  const handleDeleteCondition = useCallback((index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);

    if (newConditions.length === 0 && onDelete !== null) {
      onDelete();
    } else {
      const newValue = buildNewValue(isAndOperator, newConditions);

      handleChange(newValue);
    }
  }, [internalValue, isAndOperator, conditions, handleChange, onDelete]);

  const conditionsNodes = useMemo(() => (
    conditions.map((condition, index) => {
      if (isAndOrBlock(condition)) {
        return (
          <QueryBuilderAndOrBlock
            className={styles.condition}
            key={index}
            value={condition}
            onChange={(newBlockValue) => handleConditionChange(index, newBlockValue)}
            onDelete={() => handleDeleteCondition(index)}
            helperData={helperData}
            prefix={prefix}
            disabled={disabled}
          />
        );
      }

      return (
        <QueryBuilderAndOrCondition
          className={styles.condition}
          key={index}
          value={condition}
          onChange={(newConditionValue) => handleConditionChange(index, newConditionValue)}
          onDelete={() => handleDeleteCondition(index)}
          helperData={helperData}
          prefix={prefix}
          disabled={disabled}
        />
      );
    })
  ), [internalValue, conditions, handleConditionChange, helperData, prefix, disabled]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      <div className={styles.andOrSelector}>
        <Button
          className={styles.andOrBtn}
          type="flatFillBlue"
          pressed={isAndOperator}
          onClick={() => handleUseAndOperator(true)}
        >
          {OPERATOR_TO_STRING[AND_OPERATOR]}
        </Button>
        <Button
          className={styles.andOrBtn}
          type="flatFillBlue"
          pressed={!isAndOperator}
          onClick={() => handleUseAndOperator(false)}
        >
          {OPERATOR_TO_STRING[OR_OPERATOR]}
        </Button>
        {
          onDelete !== null && (
            <Button
              className={styles.deleteBtn}
              type="linkDestroy"
              onClick={onDelete}
              disabled={disabled}
            >
              {t('queryBuilderAndOrBlock.deleteAndOrBlock')}
            </Button>
          )
        }
      </div>
      <div className={styles.content}>
        <div className={styles.addButtons}>
          <Button
            className={styles.addBtn}
            type="link"
            icon={<AddFilled width={18} height={18} />}
            onClick={handleAddCondition}
            disabled={disabled}
          >
            {t('queryBuilderAndOrBlock.addCondition')}
          </Button>
          <Button
            className={styles.addBtn}
            type="linkSecondaryBlack"
            icon={<AddFilled width={18} height={18} />}
            onClick={handleAddAndOrBlock}
            disabled={disabled}
          >
            {t('queryBuilderAndOrBlock.addAndOrBlock')}
          </Button>
        </div>
        {conditionsNodes}
      </div>
    </div>
  );
};

QueryBuilderAndOrBlock.propTypes = {
  className: PropTypes.string,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
};

QueryBuilderAndOrBlock.defaultProps = {
  className: '',
  prefix: '',
  disabled: false,
  onDelete: null,
};

export default QueryBuilderAndOrBlock;
