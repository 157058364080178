import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import StatementPeriodCardItem from '@palette/components/statement/statementPeriod/StatementPeriodCardItem/StatementPeriodCardItem';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import routePaths from '@palette/config/routePaths';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './MyStatementPeriodsCardsListItem.less';

const classNames = bindClassNames.bind(styles);

const MyStatementPeriodsCardsListItem = ({ className, statementPeriod, isSelected, sessionId, inSalesforce }) => {
  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject(inSalesforce);

  return (
    <Link
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      path={inSalesforce ? routePaths.v2.sfStatementDetails : routePaths.v2.myStatementDetails}
      params={{
        statementPeriodId: statementPeriod.id,
        ...(inSalesforce ? { sessionId } : undefined),
      }}
      qsObject={periodsDatesFilterQSObject}
      disabled={isSelected}
    >
      <StatementPeriodCardItem statementPeriod={statementPeriod} isSelected={isSelected} enableStatus={false} />
    </Link>
  );
};

MyStatementPeriodsCardsListItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  isSelected: PropTypes.bool,
  inSalesforce: PropTypes.bool,
  sessionId: PropTypes.string,
};

MyStatementPeriodsCardsListItem.defaultProps = {
  className: '',
  isSelected: false,
  inSalesforce: false,
  sessionId: '',
};

export default MyStatementPeriodsCardsListItem;
