import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AmountDescriptionWidgetContent from '@palette/components/widgetContents/AmountDescriptionWidgetContent/AmountDescriptionWidgetContent';
import Link from '@palette/components/designSystem/Link/Link';
import EyesLine from '@palette/components/utils/Icons/EyesLine';

import { isSamePagePathname } from '@palette/helpers/NavigationHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import {
  SEARCHED_FROM_QS_KEY,
  SEARCHED_PLAN_QS_KEY,
  SEARCHED_STATUS_QS_KEY,
  SEARCHED_TO_QS_KEY,
  STATUSES_OPTIONS_KEYS,
} from '@palette/constants/commissions';
import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';

import routePaths from '@palette/config/routePaths';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import * as CommissionsUnreleasedDataModel from '@palette/models/widgets/common/CommissionsUnreleasedData';
import * as HighlightComponentParamsModel from '@palette/models/HighlightComponentParams';

import styles from './CommissionsUnreleasedWidgetHighlightContent.less';

const classNames = bindClassNames.bind(styles);

const CommissionsUnreleasedWidgetHighlightContent = ({ className, data, params, forIC }) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const dispatch = useDispatch();

  const commissionListPageLinkNode = useMemo(() => {
    if (
      params === null
      || (!forIC && isSamePagePathname({ path: routePaths.v2.commissionList }))
      || (forIC && isSamePagePathname({ path: routePaths.v2.myCommissionList }))
    ) return null;

    const qsObject = {
      [SEARCHED_STATUS_QS_KEY]: STATUSES_OPTIONS_KEYS.PARTIALLY_RELEASED_AND_ON_HOLD.toLowerCase(),
    };

    if (params.from !== null || params.to !== null) {
      const from = params.from !== null ? moment.utc(params.from).startOf('day').format('YYYY-MM-DD') : moment().utc().startOf('month').format('YYYY-MM-DD');
      const to = params.to !== null ? moment.utc(params.to).endOf('day').format('YYYY-MM-DD') : moment().utc().endOf('month').format('YYYY-MM-DD');

      qsObject[SEARCHED_FROM_QS_KEY] = from;
      qsObject[SEARCHED_TO_QS_KEY] = to;
    }

    if (params.planId !== null) {
      qsObject[SEARCHED_PLAN_QS_KEY] = params.planId;
    }

    let linkPath = routePaths.v2.commissionList;
    if (forIC) {
      linkPath = routePaths.v2.myCommissionList;
    }

    const handleClick = () => {
      dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.HIGHLIGHT_ZONE.CLICK_COMMISSIONS_UNRELEASED_WIDGET }));
    };

    return (
      <Link
        className={styles.viewLink}
        path={linkPath}
        qsObject={qsObject}
        displayIcon
        iconNode={<EyesLine className={styles.icon} width={12} height={12} />}
        iconIsAfter={false}
        onClick={handleClick}
      >
        {t('commissionsUnreleasedWidgetContent.viewDetails')}
      </Link>
    );
  }, [params, forIC]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <AmountDescriptionWidgetContent
        amount={data.commissionsUnreleasedAmount}
        currency={data.currency}
        description={t('commissionsUnreleasedWidgetContent.description')}
        extraNode={commissionListPageLinkNode}
      />
    </div>
  );
};

CommissionsUnreleasedWidgetHighlightContent.propTypes = {
  className: PropTypes.string,
  data: CommissionsUnreleasedDataModel.propTypes.isRequired,
  params: HighlightComponentParamsModel.propTypes,
  forIC: PropTypes.bool,
};

CommissionsUnreleasedWidgetHighlightContent.defaultProps = {
  className: '',
  params: null,
  forIC: false,
};

export default CommissionsUnreleasedWidgetHighlightContent;
