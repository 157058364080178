import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import TextArea from '@palette/components/designSystem/TextArea/TextArea';

import styles from './NotebookTextCellContent.less';

const classNames = bindClassNames.bind(styles);

const NotebookTextCellContent = ({
  className,
  content,
  onUpdate,
  onFocus,
  onBlur,
  disabled,
}) => (
  <TextArea
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
    value={content}
    onChange={onUpdate}
    onFocus={onFocus}
    onBlur={onBlur}
    autoSize={{ minRows: 2 }}
    disabled={disabled}
  />
);

NotebookTextCellContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

NotebookTextCellContent.defaultProps = {
  className: '',
  disabled: false,
};

export default NotebookTextCellContent;
