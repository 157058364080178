import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import DeleteNotebookCellModal from '@palette/components/notebooks/DeleteNotebookCellModal/DeleteNotebookCellModal';
import ExportFilled from '@palette/components/utils/Icons/ExportFilled';
import UnMakeExportableCellModal from '@palette/components/notebooks/UnMakeExportableCellModal/UnMakeExportableCellModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import {
  NOTEBOOK_CELL_EXPORTABLE_STATUSES,
  NOTEBOOK_CELL_TYPES,
  NOTEBOOK_STATUSES,
} from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookCellModel from '@palette/models/NotebookCell';

import styles from './NotebookCellMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const NotebookCellMoreOptionsMenu = ({ className, notebook, cell }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [unMakeExportableCellModalIsVisible, showUnMakeExportableCellModal] = useState(false);
  const [deleteNotebookCellModalIsVisible, showDeleteNotebookCellModal] = useState(false);

  const moreMenuItems = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE]) || notebook.status === NOTEBOOK_STATUSES.ARCHIVED) return [];

    const menuItems = [];

    if (
      NOTEBOOK_CELL_TYPES[cell.typeKey].exportableStatus !== NOTEBOOK_CELL_EXPORTABLE_STATUSES.UNEXPORTABLE
      && cell.status === NOTEBOOK_CELL_EXPORTABLE_STATUSES.EXPORTED
    ) {
      const unMakeExportableCellMenuItem = {
        key: 'unMakeExportableCell',
        title: t('notebookCellMoreOptionsMenu.unMakeExportableCell'),
        icon: (
          <ExportFilled width={16} height={16} />
        ),
        onClick: () => {
          showUnMakeExportableCellModal(true);
        },
      };

      menuItems.push(unMakeExportableCellMenuItem);
    }

    const deleteNotebookCellMenuItem = {
      key: 'deleteNotebookCell',
      title: t('notebookCellMoreOptionsMenu.deleteNotebookCell'),
      icon: (
        <TrashFilled width={16} height={16} />
      ),
      onClick: () => {
        showDeleteNotebookCellModal(true);
      },
    };

    menuItems.push(deleteNotebookCellMenuItem);

    return menuItems;
  }, [
    profile,
    notebook,
    cell,
    showUnMakeExportableCellModal,
    showDeleteNotebookCellModal,
  ]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu items={moreMenuItems} />
      {
        unMakeExportableCellModalIsVisible && (
          <UnMakeExportableCellModal
            visible
            onClose={() => showUnMakeExportableCellModal(false)}
            notebook={notebook}
            cell={cell}
          />
        )
      }
      {
        deleteNotebookCellModalIsVisible && (
          <DeleteNotebookCellModal
            visible
            onClose={() => showDeleteNotebookCellModal(false)}
            notebook={notebook}
            cell={cell}
          />
        )
      }
    </div>
  );
};

NotebookCellMoreOptionsMenu.propTypes = {
  notebook: NotebookModel.propTypes.isRequired,
  cell: NotebookCellModel.propTypes.isRequired,
  className: PropTypes.string,
};

NotebookCellMoreOptionsMenu.defaultProps = {
  className: '',
};

export default NotebookCellMoreOptionsMenu;
