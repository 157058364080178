import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import _sortBy from 'lodash/sortBy';

import {
  selectors as UsersSelectors,
  actions as UsersActions,
} from '@palette/state/Users';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Select from '@palette/components/designSystem/Select/Select';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import styles from './UserInPlansSelector.less';

const classNames = bindClassNames.bind(styles);

const UserInPlansSelector = ({ className, onUserSelected, disabled, searchedUser }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const usersInPlansList = useSelector(UsersSelectors.getListInPlans);
  const usersInPlansListIsPending = useSelector(UsersSelectors.getListInPlansIsPending);

  useEffect(() => {
    dispatch(UsersActions.getListInPlans());
  }, []);

  const usersOptions = useMemo(() => {
    const options = usersInPlansList.map(
      (user) => ({
        label: (
          <UserProfile
            className={styles.userOption}
            user={user}
            avatarSize={18}
            avatarStyle={{
              fontSize: '1rem',
              width: '1.8rem',
              minWidth: '1.8rem',
              height: '1.8rem',
            }}
          />
        ),
        value: user.id,
        rawlabel: user.displayName,
      }),
    );

    return _sortBy(options, 'rawlabel');
  }, [usersInPlansList]);

  const usersSelectNode = useMemo(() => {
    if (usersOptions.length === 0) return null;

    return (
      <Select
        className={styles.select}
        dropdownClassName={styles.filterByUserSelectorDropdown}
        options={usersOptions}
        showSearch
        enableFilterOptions
        filterOptionProp="rawlabel"
        allowClear
        placeholder={t('userSelector.select.placeholder')}
        value={searchedUser}
        onChange={onUserSelected}
        disabled={disabled}
      />
    );
  }, [usersOptions, searchedUser, onUserSelected]);

  const isLoading = useMemo(() => usersInPlansListIsPending, [usersInPlansListIsPending]);

  const contentNode = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingLine className={styles.icon} width={24} height={24} spin />
      );
    }

    return usersSelectNode;
  }, [isLoading, usersSelectNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UserInPlansSelector.propTypes = {
  className: PropTypes.string,
  onUserSelected: PropTypes.func,
  disabled: PropTypes.bool,
  searchedUser: PropTypes.string,
};

UserInPlansSelector.defaultProps = {
  className: '',
  onUserSelected: null,
  disabled: false,
  searchedUser: '',
};

export default UserInPlansSelector;
