import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'antd';
import routePaths from '@palette/config/routePaths';
import '../styles/quickbooksDisconnected.less';

const QuickBooksDisconnected = () => (
  <div className="QuickBooksDisconnected_page" style={{ background: 'linear-gradient(0deg, #7DE2E4 7%, #FFFFFF 100%)' }}>
    <div className="div-wrapper">
      <div className="container">
        <div className="White_vignette_wrapper">
          <div style={{ padding: '20px', paddingBottom: '40px' }}>
            <img style={{ width: '100%' }} alt="unplug" src="/img/plug.png" />
          </div>
          <h1 className="bold center">QuickBooks is disconnected</h1>
          <div className="grey center">
            Your QuickBooks integration has been disconnected.
            You will no longer to be able to sync invoices with Palette to calculate commissions.
            <br />
            If you’d like to re-connect Palette and your QuickBooks account,
            {' '}
            <a href={`/#${routePaths.addConnector}`} className="bold">please click here</a>
            .
          </div>
          <div style={{ marginTop: '34px' }} className="center">
            <a href={`/#${routePaths.login}`}><Button size="large" type="primary">Go to my dashboard</Button></a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default (withRouter(QuickBooksDisconnected));
