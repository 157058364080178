import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';
import WarningFilled from '@palette/components/utils/Icons/WarningFilled';

import styles from './Disclaimer.less';

const classNames = bindClassNames.bind(styles);

const Disclaimer = ({
  className,
  type,
  icon,
  title,
  description,
  extra,
}) => {
  let titleNode = null;
  let descriptionNode = null;
  let extraNode = null;

  const iconNode = (size) => {
    if (!icon) return null;

    if (icon === 'info') {
      return <InfoCircleFilled className={styles.icon} width={size} height={size} />;
    }

    return <WarningFilled className={styles.icon} width={size} height={size} />;
  };

  if (title) {
    titleNode = (
      <div className={styles.title}>
        {iconNode(24)}
        {title}
      </div>
    );
  }

  if (description) {
    descriptionNode = (
      <div className={styles.description}>
        {!title && iconNode(18)}
        {description}
      </div>
    );
  }

  if (extra) {
    extraNode = (
      <div className={styles.extra}>
        {extra}
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        skyblue: type === 'skyblue',
        warning: type === 'warning',
        [className]: className !== '',
      })}
    >
      <div
        className={classNames({
          content: true,
          withExtra: extra,
        })}
      >
        {titleNode}
        {descriptionNode}
      </div>
      {extraNode}
    </div>
  );
};

Disclaimer.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'skyblue', 'warning']),
  icon: PropTypes.oneOf(['warning', 'info']),
  title: PropTypes.any,
  description: PropTypes.any,
  extra: PropTypes.any,
};

Disclaimer.defaultProps = {
  className: '',
  type: 'default',
  icon: 'warning',
  title: null,
  description: null,
  extra: null,
};

export default Disclaimer;
