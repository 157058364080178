import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '@palette/components/designSystem/Modal/Modal';
import OnboardUserForm from '@palette/components/userOnboarding/OnboardUserForm/OnboardUserForm';

import * as OnboardUserModel from '@palette/models/OnboardUser';

import { actions as UsersActions, selectors as UsersSelectors } from '@palette/state/Users';

import styles from './EditOnboardUserModal.less';

const EditOnboardUserModal = ({ visible, onClose, user, onboardUserIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onboardCurrentUsers = useSelector(UsersSelectors.getOnboardCurrentUsers);

  const [submitFormFunc, setSubmitFormFunc] = useState(null);

  const setSubmitFunc = useCallback((submitFunc) => {
    setSubmitFormFunc(() => submitFunc);
  }, []);

  const cleanAndClose = useCallback(() => {
    onClose();
  }, []);

  const handleFinish = useCallback((updatedOnboardUser) => {
    const newOnboardCurrentUsers = [...onboardCurrentUsers];
    newOnboardCurrentUsers[onboardUserIndex] = updatedOnboardUser;
    dispatch(UsersActions.setOnboardCurrentUsers({ users: newOnboardCurrentUsers }));
    cleanAndClose();
  }, [cleanAndClose, onboardCurrentUsers, onboardUserIndex]);

  const handleEditOnboardUser = useCallback(() => {
    if (submitFormFunc !== null) submitFormFunc();
  }, [submitFormFunc]);

  return (
    <Modal
      className={styles.modal}
      title={t('editOnboardUserModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleEditOnboardUser}
      okText={t('editOnboardUserModal.confirm')}
    >
      <OnboardUserForm user={user} setSubmitFunc={setSubmitFunc} onUpdateFinish={handleFinish} />
    </Modal>
  );
};

EditOnboardUserModal.propTypes = {
  user: OnboardUserModel.propTypes.isRequired,
  onboardUserIndex: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

EditOnboardUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default EditOnboardUserModal;
