import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import Button from '@palette/components/designSystem/Button/Button';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';
import CreatePlanFlowDealsResults from '@palette/components/masterPlanCreation/CreatePlanFlowDealsResults/CreatePlanFlowDealsResults';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowTestFilters.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowTestFilters = ({ className, plan, onTest, isPending }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [runAtLeastOnce, setRunAtLeastOnce] = useState(false);

  const performCompute = useCallback(() => {
    setRunAtLeastOnce(true);
    dispatch(MasterPlansActions.computePlan({ planId: plan.id, disableSuccessNotification: true }));
  }, [plan]);

  const handleTest = useCallback(() => {
    if (onTest !== null) {
      onTest(performCompute);
    } else {
      performCompute();
    }
  }, [performCompute, onTest]);

  const resultsNode = useMemo(() => {
    if (isPending) {
      return (
        <div className={styles.loadingWrapper}>
          <RefreshLine className={styles.loadingIcon} width={18} height={18} spin />
          <div className={styles.loadingLabel}>
            {t('loading')}
          </div>
        </div>
      );
    }

    if (runAtLeastOnce) {
      return (
        <CreatePlanFlowDealsResults plan={plan} />
      );
    }

    return (
      <div className={styles.neverRunLabel}>
        {t('createPlanFlowTestFilters.neverRun.description', { dealType: pluralize(plan.trackingObject?.type) })}
      </div>
    );
  }, [plan, runAtLeastOnce, isPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="primaryBlue"
        onClick={handleTest}
        disabled={isPending}
      >
        {t('createPlanFlowTestFilters.testBtn.label')}
      </Button>
      {resultsNode}
    </div>
  );
};

CreatePlanFlowTestFilters.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  onTest: PropTypes.func,
  isPending: PropTypes.bool,
};

CreatePlanFlowTestFilters.defaultProps = {
  className: '',
  onTest: null,
  isPending: false,
};

export default CreatePlanFlowTestFilters;
