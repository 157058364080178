import React from 'react';
import flatten from 'flat';
import _orderBy from 'lodash/orderBy';

import FormulaValue from '@palette/components/formula/FormulaValue/FormulaValue';

import {
  DEFAULT_INJECTION, DEFAULT_INJECTION_DATA_POINTS,
  DEFAULT_PERIOD_DEFINITION, DEFAULT_SCOPE_MANAGER, DEFAULT_SCOPE_TEAM,
  DEFAULT_USERS_DEFINITION,
  SUGGESTIONS_TYPES_TESTS,
} from '@palette/constants/formula';
import { SCOPES } from '@palette/constants/masterPlans';
import { EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';

export const replaceVariables = (formula, scope = {}) => {
  // If scope is empty, do nothing
  if (Object.keys(scope).length === 0) return formula;

  const flattenScope = flatten(scope);

  const variables = Object.keys(flattenScope).sort((a, b) => b.length - a.length);

  const usedVariables = [];

  let workingFormula = formula;
  // Replace all used variables by a placeholder
  variables.forEach((variable) => {
    const variableFound = workingFormula.indexOf(variable) !== -1;

    if (variableFound) {
      const varIndex = usedVariables.length;
      usedVariables.push(variable);

      const splits = workingFormula.split(variable);
      workingFormula = splits.join(`___PALETTE___${varIndex}___PALETTE___`);
    }
  });

  // Replace the placeholder by the corresponding node
  const replaceVariable = (toReplace, variableIndex = 0) => {
    const splits = toReplace.split(`___PALETTE___${variableIndex}___PALETTE___`);

    return splits.map((split, index) => {
      let replaced = split;
      if (split.trim() !== '') { // If there is something to search in
        const newVariableIndex = variableIndex + 1;
        if (newVariableIndex < usedVariables.length) { // If there is a remaining variable to replace
          replaced = replaceVariable(split, newVariableIndex);
        }
      }

      // If it's not the last split, add the replacement at the end
      if (index !== splits.length - 1) {
        const variable = usedVariables[variableIndex];

        const value = flattenScope[variable];
        const valueNode = value ? (<FormulaValue value={value} />) : null;

        const replacement = (
          <code>
            {variable}
            {valueNode}
          </code>
        );

        return (
          <span key={`${variableIndex}_${index}`}>
            {replaced}
            {replacement}
          </span>
        );
      }

      return replaced;
    });
  };

  return replaceVariable(workingFormula);
};

export const renameValueDefinition = (valueDefinition) => valueDefinition.toLowerCase().replace(/[^a-z ]/g, '').trim().replace(/ /g, '_');

export const enhanceFormulaHelperDataWithVariables = (formulaHelperData, variableNames, type = null) => {
  const additionalVariables = {};

  variableNames.forEach((variableName) => {
    additionalVariables[variableName] = '-';
  });

  let newHelperData = {
    ...formulaHelperData,
    data: {
      ...formulaHelperData.data,
      ...additionalVariables,
    },
  };

  if (type !== null) {
    newHelperData = {
      ...formulaHelperData,
      data: {
        ...formulaHelperData.data,
        [type]: {
          ...(formulaHelperData.data[type] || {}),
          ...additionalVariables,
        },
      },
    };
  }

  return newHelperData;
};

export const getFieldScore = (fieldValue, suggestionsType) => {
  let score = 0;
  const value = fieldValue.toLowerCase();
  const tests = SUGGESTIONS_TYPES_TESTS[suggestionsType];

  tests.forEach((testFunc) => {
    score += testFunc(value);
  });

  return score;
};

export const getSuggestions = (fieldsOptions, suggestionsType, nbOfSuggestions = 3) => {
  const fieldsWithScore = fieldsOptions.map((fieldOption) => {
    const fieldValue = fieldOption.value;
    const score = getFieldScore(fieldValue, suggestionsType);
    return {
      fieldValue,
      score,
    };
  });

  return _orderBy(fieldsWithScore, ['score'], ['desc'])
    .filter((fieldWithScore) => fieldWithScore.score !== 0)
    .slice(0, nbOfSuggestions)
    .map((fieldWithScore) => fieldWithScore.fieldValue);
};

export const manageAdditionalProperties = (
  type,
  objectSample,
  config = {},
) => {
  const {
    plan = null,
    addPlanProperties = true,
    addPeriodProperties = true,
    addUsersProperties = true,
    addTeamProperties = true,
    addDependenciesProperties = true,
    addPlanTrackingObjectVariablesProperties = true,
    userCurrency = 'EUR',
  } = config;

  if (plan === null) {
    return {
      type,
      data: {
        [type]: objectSample,
      },
    };
  }

  const additionalProperties = {};

  if (addPlanProperties) {
    const currency = plan.currency || userCurrency;
    additionalProperties.Plan = {
      currency,
    };
  }

  if (addPeriodProperties) {
    additionalProperties.Period = DEFAULT_PERIOD_DEFINITION;
  }

  if (addUsersProperties && plan.usersDefinition !== null && (plan.scope === SCOPES.INDIVIDUAL || plan.scope === SCOPES.MANAGER)) {
    additionalProperties[plan.usersDefinition.type] = DEFAULT_USERS_DEFINITION;
  }

  if (addTeamProperties && (plan.scope === SCOPES.TEAM || plan.scope === SCOPES.MANAGER)) {
    let teamProperties = {};

    if (plan.scope === SCOPES.TEAM) {
      teamProperties = DEFAULT_SCOPE_TEAM;
    }

    if (plan.scope === SCOPES.MANAGER) {
      teamProperties = DEFAULT_SCOPE_MANAGER;
    }

    additionalProperties.Team = teamProperties;
  }

  if (addDependenciesProperties) {
    plan.injections.forEach((injection) => {
      additionalProperties[injection.name] = DEFAULT_INJECTION;

      if (injection.withDataPoints) {
        additionalProperties[injection.name].dataPoints = DEFAULT_INJECTION_DATA_POINTS;
      }
    });
  }

  if (addPlanTrackingObjectVariablesProperties && plan.trackingObject?.originalType !== EMPTY_DEFAULT_VALUE) {
    const planTrackingObjectType = plan.trackingObject?.type || '';
    if (planTrackingObjectType !== '') {
      additionalProperties[planTrackingObjectType] = additionalProperties[planTrackingObjectType] || {};

      const planTrackingObjectVariables = plan.trackingObject?.valueVariables || [];
      planTrackingObjectVariables.forEach((planTrackingObjectVariable) => {
        additionalProperties[planTrackingObjectType][planTrackingObjectVariable.name] = '-';
      });
    }
  }

  return {
    type,
    data: {
      ...additionalProperties,
      [type]: {
        ...objectSample,
        ...(additionalProperties[type] || {}),
      },
    },
  };
};
