import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import QueryBuilder from '@palette/components/designSystem/QueryBuilder/QueryBuilder';
import Button from '@palette/components/designSystem/Button/Button';
import Input from '@palette/components/designSystem/Input/Input';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import CreatePlanFlowTestFilters from '@palette/components/masterPlanCreation/CreatePlanFlowTestFilters/CreatePlanFlowTestFilters';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';
import ResourceObjectSample from '@palette/components/resources/ResourceObjectSample/ResourceObjectSample';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';
import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { getPlanFirstPeriod, getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import { FORMULA_INPUT_TYPES, SUGGESTIONS_TYPES } from '@palette/constants/formula';
import { CREATE_PLAN_FLOW_STEPS } from '@palette/constants/masterPlans';
import { EMPTY_DEFAULT_VALUE, FILTER_OBJECT_EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';
import { DATE_FIELDS } from '@palette/constants/connector';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowDataFilteringStep.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowDataFilteringStep = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updatePlanTrackingObjectDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanTrackingObjectDefinitionIsPending);

  const initialFilterObject = useMemo(() => (plan.trackingObject?.filterObject), [plan]);
  const initialFilterFormula = useMemo(() => (plan.trackingObject?.filterFormula), [plan]);
  const initialDateField = useMemo(() => (plan.trackingObject?.dateField), [plan]);

  const [filterFormulaValue, setFilterFormulaValue] = useState(initialFilterFormula);
  const [dateFieldValue, setDateFieldValue] = useState(initialDateField);

  const [displayFilterFormulaFormItem, showFilterFormulaFormItem] = useState(initialFilterFormula !== '');

  const [form] = AntDForm.useForm();

  const [goToNextStepCB, setGoToNextStepCB] = useState(null);

  const initialValues = useMemo(() => ({
    filterObject: initialFilterObject,
    filterFormula: initialFilterFormula,
    dateField: initialDateField,
  }), [
    initialFilterObject,
    initialFilterFormula,
    initialDateField,
  ]);

  useEffect(() => {
    setFilterFormulaValue(initialValues.filterFormula);
    setDateFieldValue(initialValues.dateField);
  }, [initialValues]);

  const handleFormValuesChange = useCallback((changedValues) => {
    if (changedValues.filterFormula !== undefined) {
      setFilterFormulaValue(changedValues.filterFormula);
    }

    if (changedValues.dateField !== undefined) {
      setDateFieldValue(changedValues.dateField);
    }
  });

  const handleFinish = useCallback((values) => {
    const finalValues = { ...values };
    if (finalValues.filterObject === '') {
      finalValues.filterObject = FILTER_OBJECT_EMPTY_DEFAULT_VALUE;
    }

    dispatch(MasterPlansActions.updatePlanTrackingObjectDefinition({
      planId: plan.id,
      ...finalValues,
      disableSuccessNotification: true,
      onSuccessCB: goToNextStepCB,
    }));
  }, [plan, goToNextStepCB]);

  const connectorIdType = useMemo(() => {
    if (
      plan.trackingObject?.originalType === EMPTY_DEFAULT_VALUE
      || plan.trackingObject?.connectorId === ''
      || plan.trackingObject?.originalType === ''
    ) return null;

    return {
      connectorId: plan.trackingObject?.connectorId,
      type: plan.trackingObject?.originalType,
    };
  }, [plan]);

  const dealObjectSample = usePlanDealObjectSample(plan, connectorIdType);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample);

  const connectorsList = useConnectorsList();
  const connector = useMemo(() => (
    connectorsList.find((connectorItem) => (connectorItem.id === plan.trackingObject?.connectorId))
  ), [connectorsList, plan]);

  const dateFieldTopFields = useMemo(() => {
    if (!connector) return [];

    return DATE_FIELDS[`${connector.type}_${plan.trackingObject?.originalType}`] || [];
  }, [connector, plan]);

  const filterObjectFormItemNode = useMemo(() => (
    <FormItem
      className={classNames({
        filterObjectFormItem: true,
        filterObjectFormItemSticked: !displayFilterFormulaFormItem,
      })}
      name="filterObject"
      label={(
        /* eslint-disable react/no-danger */
        <div
          className={styles.formItemLabel}
          dangerouslySetInnerHTML={{
            __html: t('createPlanFlowDataFilteringStep.form.filterObject.label', { connectorName: connector?.name, resourceType: pluralize(plan.trackingObject?.type) }),
          }}
        />
        /* eslint-enable react/no-danger */
      )}
    >
      <QueryBuilder
        className={styles.queryBuilderContainer}
        helperData={formulaHelperData}
        emptyLabel={t('masterPlanTrackingObjectDefinitionForm.form.filterObject.empty')}
        prefix="data."
        disabled={updatePlanTrackingObjectDefinitionIsPending}
      />
    </FormItem>
  ), [
    displayFilterFormulaFormItem,
    formulaHelperData,
    updatePlanTrackingObjectDefinitionIsPending,
    connector,
  ]);

  const filterFormulaFormItemNode = useMemo(() => {
    if (!displayFilterFormulaFormItem) {
      return (
        <Button className={styles.useCustomCodeBtn} type="link" onClick={() => showFilterFormulaFormItem(true)} disabled={updatePlanTrackingObjectDefinitionIsPending}>
          {t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.useCustomCode')}
        </Button>
      );
    }

    const extraNode = (
      <div className={styles.filterFormulaExtra}>
        {t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.description')}
        {
          filterFormulaValue === '' && (
            <Button className={styles.filterFormulaExtraBtn} type="link" onClick={() => showFilterFormulaFormItem(false)} disabled={updatePlanTrackingObjectDefinitionIsPending}>
              {t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.hide')}
            </Button>
          )
        }
      </div>
    );

    return (
      <FormItem
        name="filterFormula"
        label={t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.label')}
        extra={extraNode}
      >
        <Input size="big" disabled={updatePlanTrackingObjectDefinitionIsPending} />
      </FormItem>
    );
  }, [filterFormulaValue, displayFilterFormulaFormItem]);

  const handleNextStep = useCallback((goToNextStep) => {
    setGoToNextStepCB(() => goToNextStep);

    form.submit();
  }, [form]);

  const testFiltersNode = useMemo(() => {
    if (dateFieldValue === '') return null;

    return (
      <CreatePlanFlowTestFilters plan={plan} onTest={handleNextStep} isPending={updatePlanTrackingObjectDefinitionIsPending} />
    );
  }, [
    dateFieldValue,
    plan,
    handleNextStep,
    /**
     * Do not add this dependency just below as it's already managed by the handle:
     * "updatePlanTrackingObjectDefinitionIsPending"
     * If you add it, the loader state happens twice.
     */
  ]);

  const actionsNode = useMemo(() => (
    <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.DATA_FILTERING.value} onNextStep={handleNextStep} />
  ), [plan, handleNextStep]);

  const periodName = useMemo(() => {
    const firstPlanPeriod = getPlanFirstPeriod(plan);

    return getPlanPeriodName(plan, firstPlanPeriod);
  }, [plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.formWrapper}>
        <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
          <FormItem
            name="dateField"
            label={(
              /* eslint-disable react/no-danger */
              <div
                className={styles.formItemLabel}
                dangerouslySetInnerHTML={{
                  __html: t('createPlanFlowDataFilteringStep.form.dateField.label', { connectorName: connector?.name, resourceType: plan.trackingObject?.type, periodName }),
                }}
              />
              /* eslint-enable react/no-danger */
            )}
            rules={[
              { required: true, message: t('masterPlanTrackingObjectDefinitionForm.form.dateField.rules.required') },
            ]}
          >
            <FormulaInput
              type={FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION}
              size="big"
              helperData={formulaHelperData}
              topFields={dateFieldTopFields}
              disabled={updatePlanTrackingObjectDefinitionIsPending}
              suggestionsType={SUGGESTIONS_TYPES.DATE}
              placeholder={`ex: ${plan.trackingObject?.type || '[Resource]'}.closedate`}
            />
          </FormItem>
          {filterObjectFormItemNode}
          {filterFormulaFormItemNode}
          {testFiltersNode}
        </Form>
        {actionsNode}
      </div>
      <ResourceObjectSample className={styles.resourceObjectSample} helperData={formulaHelperData} />
    </div>
  );
};

CreatePlanFlowDataFilteringStep.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowDataFilteringStep.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowDataFilteringStep;
