import PropTypes from 'prop-types';

import { manageDateAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'PlanV3InvalidEndDateErrorStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'PlanV3InvalidEndDateErrorContentStrategy',
      startDate: manageDateAttribute(strategy, 'startDate'),
      endDate: manageDateAttribute(strategy, 'endDate'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
