import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';

import styles from './StartPeriodFormItem.less';

const StartPeriodFormItem = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      className={styles.wrapper}
      name="startPeriod"
      label={t('createPlanPage.form.startPeriod.label')}
      required
      rules={[
        { required: true, message: t('createPlanPage.form.startPeriod.rules.required'), type: 'date' },
      ]}
    >
      <DatePicker
        className={styles.dateField}
        picker="date"
        allowClear
        disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
        disabled={disabled}
        size="big"
      />
    </FormItem>
  );
};

StartPeriodFormItem.propTypes = {
  disabled: PropTypes.bool,
};

StartPeriodFormItem.defaultProps = {
  disabled: false,
};

export default StartPeriodFormItem;
