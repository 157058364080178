import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tag from '@palette/components/designSystem/Tag/Tag';
import Link from '@palette/components/designSystem/Link/Link';
import RolesFilled from '@palette/components/utils/Icons/RolesFilled';
import RolesListItemMoreOptionsMenu from '@palette/components/role/RolesListItemMoreOptionsMenu/RolesListItemMoreOptionsMenu';

import { TAG_THEMES } from '@palette/constants/tag';

import routePaths from '@palette/config/routePaths';

import * as RoleModel from '@palette/models/Role';

import styles from './RolesListItem.less';

const classNames = bindClassNames.bind(styles);

const RolesListItem = ({ className, role }) => {
  const { t } = useTranslation();

  const nameNode = useMemo(() => (
    <Link
      className={styles.name}
      maxWidth="20"
      displayIcon
      path={routePaths.v2.roleDetails}
      params={{ roleId: role.id }}
    >
      {`${role.name}${role.isDefault ? t('rolesListItem.isDefault') : ''}`}
    </Link>
  ), [role]);

  const usersCountNode = useMemo(() => (
    <Tag
      className={styles.usersCount}
      theme={TAG_THEMES.LAVENDER}
      key="scope-hidden"
      label={t('rolesListItem.users', { count: role.usersCount })}
    />
  ), [role]);

  const [moreMenuIsOpen, setMoreMenuIsOpen] = useState(false);

  const handleMoreMenuOpenChange = useCallback((isOpen) => {
    setMoreMenuIsOpen(isOpen);
  }, [setMoreMenuIsOpen]);

  const moreOptionsMenuNode = useMemo(() => (
    <div
      className={classNames({
        moreMenuWrapper: true,
        moreMenuIsOpen,
      })}
    >
      <RolesListItemMoreOptionsMenu
        className={styles.moreMenu}
        role={role}
        onOpenChange={handleMoreMenuOpenChange}
      />
    </div>
  ), [role, moreMenuIsOpen, handleMoreMenuOpenChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <RolesFilled className={styles.icon} width={24} height={24} />
      {nameNode}
      {usersCountNode}
      {moreOptionsMenuNode}
    </div>
  );
};

RolesListItem.propTypes = {
  className: PropTypes.string,
  role: RoleModel.propTypes.isRequired,
};

RolesListItem.defaultProps = {
  className: '',
};

export default RolesListItem;
