import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import NewNotebookModal from '@palette/components/notebooks/NewNotebookModal/NewNotebookModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './NewNotebookButton.less';

const classNames = bindClassNames.bind(styles);

const NewNotebookButton = ({ className }) => {
  const { t } = useTranslation();

  const profile = useProfile();

  const [newNotebookModalIsVisible, showNewNotebookModal] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="primaryBlue"
        icon={<AddFilled />}
        onClick={() => showNewNotebookModal(true)}
      >
        {t('newNotebookButton.label')}
      </Button>
      {
        newNotebookModalIsVisible && (
          <NewNotebookModal
            visible
            onClose={() => showNewNotebookModal(false)}
          />
        )
      }
    </div>
  );
};

NewNotebookButton.propTypes = {
  className: PropTypes.string,
};

NewNotebookButton.defaultProps = {
  className: '',
};

export default NewNotebookButton;
