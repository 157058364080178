import {
  call,
  all,
  takeLatest,
} from 'redux-saga/effects';

import { deleteCookie } from '@palette/helpers/CookiesHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { SESSION_ID } from '@palette/constants/localStorage';

import routePaths from '@palette/config/routePaths';

import { actions as ExceptionsActions } from '@palette/state/Exceptions/slice';

import { entities } from '@palette/state/entities';

export function* cleanAll() {
  const entitiesCleans = [];
  entities.forEach((entity) => {
    entitiesCleans.push(entity.clean());
  });
  yield all(entitiesCleans);
}

export function* forceLogout() {
  localStorage.clear();
  sessionStorage.clear();
  deleteCookie(SESSION_ID);

  yield call(cleanAll);

  redirectTo({ path: routePaths.v2.login });
}

export function* loop() {
  yield all([
    takeLatest(ExceptionsActions.forceLogout.type, forceLogout),
  ]);
}
