import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './DeletePlanModal.less';

const DeletePlanModal = ({ visible, onClose, planId, planName, isPlanV3 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPending = useSelector(PlanV3Selectors.deletePlanIsPending);
  const deleteMasterPlanIsPending = useSelector(MasterPlansSelectors.deleteByIdIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
  };

  const handleDelete = useCallback(() => {
    if (isPlanV3) {
      dispatch(PlanV3Actions.deletePlan({ planId, onSuccessCB }));
    } else {
      dispatch(MasterPlansActions.deleteById({ planId, onSuccessCB }));
    }
  }, [isPlanV3, planId]);

  return (
    <Modal
      className={styles.modal}
      title={t('deleteMasterPlanModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDelete}
      okText={t('deleteMasterPlanModal.confirm')}
      loading={isPending || deleteMasterPlanIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('deleteMasterPlanModal.content', { planName }) }} />
    </Modal>
  );
};

DeletePlanModal.propTypes = {
  planId: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  isPlanV3: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

DeletePlanModal.defaultProps = {
  isPlanV3: false,
  visible: false,
  onClose: () => {},
};

export default DeletePlanModal;
