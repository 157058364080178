import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import routePaths from '@palette/config/routePaths';

import { useProfile } from '@palette/hooks/ProfileHooks';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import { selectors as UsersSelectors } from '@palette/state/Users';

import styles from './CommissionV3Details.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3Details = ({ className, commission, isIC }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { userId, dealId, dealName, planId, planName } = commission;

  const user = useSelector((state) => UsersSelectors.getUserById(state, { userId }));

  const nameNode = (
    <div className={styles.detail}>
      <div className={styles.label}>
        {t('commissionDetails.deal')}
      </div>
      <div className={styles.value}>
        {dealName !== '' && (
          <span>{dealName}</span>
        )}
        <span className={styles.complement}>
          {`${t('userStatementV3.commissions.header.dealId')}: ${dealId}`}
        </span>
      </div>
    </div>
  );

  const planNode = (
    <div className={styles.detail}>
      <div className={styles.label}>
        {t('commissionDetails.plan')}
      </div>
      <div className={styles.value}>
        <Link
          path={isIC ? routePaths.v2.myPlansV3RequirementsById : routePaths.v2.planV3EditAchievementById}
          params={{ planId }}
          displayIcon
        >
          {planName !== '' ? planName : '-'}
        </Link>
      </div>
    </div>
  );

  const payeeNode = (
    <div className={styles.detail}>
      <div className={styles.label}>
        {t('commissionDetails.payee')}
      </div>
      <div className={styles.value}>
        <UserProfile user={isIC ? profile.userData : user} />
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {nameNode}
      {planNode}
      {payeeNode}
    </div>
  );
};

CommissionV3Details.propTypes = {
  className: PropTypes.string,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
  isIC: PropTypes.bool,
};

CommissionV3Details.defaultProps = {
  className: '',
  isIC: false,
};

export default CommissionV3Details;
