import React, { Component } from 'react';
import { message, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { getXeroConnectURL } from '../../../../services/connector';
import { CONNECTOR_TYPES } from '../../../../services/constants';
import SourceVignette from '../sourceVignette';
import '../../../../styles/connectCRMButton.less';

class ConnectXeroButtonOauth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  connectXero = () => {
    this.setState({ loading: true });
    getXeroConnectURL()
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((e) => {
        message.error('Error while contacting Xero.');
        console.error(e);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    const { disabled } = this.props;
    return (
      <Tooltip
        placement="right"
        title={disabled ? 'Connector already linked' : null}
      >
        <SourceVignette
          loading={loading}
          disabled={disabled}
          icon={<img src="/img/xero-logo.png" alt="xero" style={{ width: '44px' }} />}
          type={CONNECTOR_TYPES.BILLING}
          name="Xero"
          id="XERO"
          onClick={disabled ? null : this.connectXero}
        />
      </Tooltip>

    );
  }
}

ConnectXeroButtonOauth.propTypes = {
  disabled: PropTypes.bool,
};

ConnectXeroButtonOauth.defaultProps = {
  disabled: false,
};
export default ConnectXeroButtonOauth;
