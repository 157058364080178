import PropTypes from 'prop-types';

import * as CommentModel from '@palette/models/Comment';

import {
  manageStringAttribute,
  manageDateAttribute, manageArrayAttribute,
} from '@palette/helpers/ModelHelper';

import * as CommissionModel from '@palette/models/Commission';

import { STATUSES } from '@palette/constants/threads';

export const modelName = 'Thread';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  status: PropTypes.oneOf(Object.values(STATUSES)),
  lastComment: CommentModel.propTypes,
  lastCommentAt: PropTypes.string,
  comments: PropTypes.arrayOf(CommentModel.propTypes),
  type: PropTypes.string,
  item: PropTypes.oneOfType([CommissionModel.propTypes, PropTypes.object]),
});

export const manageThreadStatus = (thread) => (STATUSES[manageStringAttribute(thread, 'status')] || null);

export const manageType = (thread) => manageStringAttribute(thread, 'mongoCollection').toLowerCase();

export const manageItem = (thread, type) => {
  if (type === 'commission') return CommissionModel.transform(thread.item);

  return thread.item;
};

export const transform = (thread) => {
  if (!thread) {
    return null;
  }

  const type = manageType(thread);
  const item = manageItem(thread, type);

  return (
    {
      id: manageStringAttribute(thread, '_id'),
      status: manageThreadStatus(thread),
      lastComment: CommentModel.transform(thread.lastComment),
      lastCommentAt: manageDateAttribute(thread, 'lastCommentAt'),
      comments: CommentModel.transformList(manageArrayAttribute(thread, 'comments')),
      type,
      [type]: item,
    }
  );
};

export const transformList = (threads) => threads.map((thread) => transform(thread));
