import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MasterPlanConditionalVariableStepModel from '@palette/models/MasterPlanConditionalVariableStep';

import { VARIABLE_TYPES } from '@palette/constants/masterPlans';

export const modelName = 'MasterPlanVariable';

export const propTypes = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(VARIABLE_TYPES)),
  name: PropTypes.string,
  formula: PropTypes.string,
  steps: PropTypes.arrayOf(MasterPlanConditionalVariableStepModel.propTypes),
});

export const manageType = (masterPlanVariable) => (VARIABLE_TYPES[manageStringAttribute(masterPlanVariable, 'type')] || VARIABLE_TYPES.SIMPLE);

export const transform = (masterPlanVariable) => {
  if (!masterPlanVariable) {
    return null;
  }

  return (
    {
      type: manageType(masterPlanVariable),
      name: manageStringAttribute(masterPlanVariable, 'name'),
      formula: manageStringAttribute(masterPlanVariable, 'formula'),
      steps: MasterPlanConditionalVariableStepModel.transformList(manageArrayAttribute(masterPlanVariable, 'steps')),
    }
  );
};

export const transformList = (masterPlanVariables) => masterPlanVariables.map((masterPlanVariable) => transform(masterPlanVariable));
