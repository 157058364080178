import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import TableFooterCell from '@palette/components/designSystem/TableFooterCell/TableFooterCell';

import { getColumnWidth, getColumnWidthAsFiniteNumber } from '@palette/helpers/components/TableHelper';

import styles from './TableFooter.less';

const classNames = bindClassNames.bind(styles);

const TableFooter = ({
  className,
  type,
  footerGroups,
  FooterCellComponent,
  nbOfFixedColumns,
  fixedColumnsPosition,
  stretch,
  horizontalScrollingOnLeft,
  horizontalScrollingOnRight,
}) => (
  <div
    className={classNames({
      footer: true,
      stretch,
      borderless: type === 'borderless',
      [className]: className !== '',
      horizontalScrollingOnLeft,
      horizontalScrollingOnRight,
    })}
  >
    {
      footerGroups.map((footerGroup) => {
        const { headers } = footerGroup;
        if (nbOfFixedColumns > 0) {
          let totalRight = 0;
          for (let i = headers.length - 1; i >= 0; i -= 1) {
            headers[i].totalRight = totalRight;
            totalRight += getColumnWidthAsFiniteNumber(headers[i]);
          }
        }
        return (
          <div className={styles.footerRow} {...footerGroup.getFooterGroupProps()}>
            {
              headers.map((column, columnIndex, columns) => {
                let columnWidth = getColumnWidthAsFiniteNumber(column);
                if (stretch) {
                  columnWidth = getColumnWidth(column);
                }

                let cellContentNode = (
                  <FooterCellComponent type={type}>
                    {
                      column.render('Footer')
                    }
                  </FooterCellComponent>
                );
                if (column.disableFooterCellComponent === true) {
                  cellContentNode = column.render('Footer');
                }

                const columnStyleLeft = !Number.isFinite(column.totalLeft) ? 0 : column.totalLeft;
                const columnStyle = {
                  width: columnWidth,
                  minWidth: column.totalMinWidth,
                  maxWidth: column.totalMaxWidth,
                  left: columnStyleLeft,
                };
                if (nbOfFixedColumns > 0
                  && (
                    (fixedColumnsPosition === 'fromRight' && columnIndex !== 0)
                    || (fixedColumnsPosition === 'fromLeft' && columnIndex === columns.length - 1)
                  )
                ) {
                  columnStyle.left = 'auto';
                  columnStyle.right = column.totalRight;
                }

                return (
                  <div
                    className={classNames({
                      footerCell: true,
                      stickyCell: (
                        (fixedColumnsPosition === 'fromLeft' && columnIndex < nbOfFixedColumns)
                        || (fixedColumnsPosition === 'fromRight' && columnIndex > (columns.length - 1 - nbOfFixedColumns))
                        || (
                          nbOfFixedColumns > 0
                          && (
                            columnIndex === 0
                            || columnIndex === columns.length - 1
                          )
                        )
                      ),
                      highlight: !!column.highlight,
                      withShadow: (
                        nbOfFixedColumns > 0
                        && (
                          (fixedColumnsPosition === 'fromLeft' && !horizontalScrollingOnLeft && columnIndex === nbOfFixedColumns - 1)
                          || (fixedColumnsPosition === 'fromRight' && !horizontalScrollingOnRight && columnIndex === columns.length - nbOfFixedColumns)
                        )
                      ),
                      withOppositeShadow: (
                        nbOfFixedColumns > 0
                        && (
                          (fixedColumnsPosition === 'fromLeft' && !horizontalScrollingOnRight && columnIndex === columns.length - 1)
                          || (fixedColumnsPosition === 'fromRight' && !horizontalScrollingOnLeft && columnIndex === 0)
                        )
                      ),
                      fixedColumnsFromRight: nbOfFixedColumns > 0 && fixedColumnsPosition === 'fromRight',
                      hidden: nbOfFixedColumns > 0 && (columnIndex === 0 || columnIndex === columns.length - 1),
                      lastNotHiddenColumn: (
                        (nbOfFixedColumns > 0 && columnIndex === columns.length - 2)
                        || (nbOfFixedColumns === 0 && columnIndex === columns.length - 1)
                      ),
                    })}
                    {...column.getFooterProps()}
                    style={columnStyle}
                  >
                    {cellContentNode}
                  </div>
                );
              })
            }
          </div>
        );
      })
    }
  </div>
);

TableFooter.propTypes = {
  footerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  FooterCellComponent: PropTypes.func,
  nbOfFixedColumns: PropTypes.number,
  fixedColumnsPosition: PropTypes.oneOf(['fromLeft', 'fromRight']),
  stretch: PropTypes.bool,
  horizontalScrollingOnLeft: PropTypes.bool,
  horizontalScrollingOnRight: PropTypes.bool,
};

TableFooter.defaultProps = {
  className: '',
  type: 'default',
  FooterCellComponent: TableFooterCell,
  nbOfFixedColumns: 0,
  fixedColumnsPosition: 'fromLeft',
  stretch: false,
  horizontalScrollingOnLeft: false,
  horizontalScrollingOnRight: false,
};

export default TableFooter;
