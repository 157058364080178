/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
 * Initial State
 */
const initialState = {
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'exceptions',
  initialState,
  reducers: {
    /* Force Logout */
    forceLogout: () => {
      //
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
// const root = (state) => state[slice.name];

export const selectors = {};
