import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import HistoryFilled from '@palette/components/utils/Icons/HistoryFilled';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import CardItem from '@palette/components/settings/CardItem/CardItem';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import EditStatementStrategyModal from '@palette/components/statement/statementSettings/EditStatementStrategyModal/EditStatementStrategyModal';
import HistoryStatementStrategyModal from '@palette/components/statement/statementSettings/HistoryStatementStrategyModal/HistoryStatementStrategyModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getStrategyDetailsStr } from '@palette/helpers/StatementHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './StatementStrategy.less';

const classNames = bindClassNames.bind(styles);

const StatementStrategy = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { company } = profile.userData;

  const [editStatementStrategyModalIsVisible, showStatementStrategyModal] = useState(false);
  const [historyStatementStrategyModalIsVisible, showHistoryStatementStrategyModal] = useState(false);
  const [historyTooltipVisible, setHistoryTooltipVisible] = useState(false);

  const valueNode = useMemo(() => t(`statementStrategy.propertyTypes.${company.statementStrategy.type}`), [company]);

  const handleSeeHistoryClick = () => {
    setHistoryTooltipVisible(false);
    showHistoryStatementStrategyModal(true);
  };

  let editButtonNode = (
    <Button
      className={styles.button}
      type="link"
      icon={<PenFilled width={16} height={16} />}
      onClick={() => showStatementStrategyModal(true)}
    />
  );

  let historyButtonNode = (
    <Tooltip
      title={t('statementStrategy.history.tooltip.label')}
      visible={historyTooltipVisible}
      onVisibleChange={(visible) => setHistoryTooltipVisible(visible)}
      placement="right"
    >
      <Button
        className={classNames({
          button: true,
          historyButton: true,
        })}
        type="link"
        icon={<HistoryFilled className={styles.icon} width={18} height={18} />}
        onClick={handleSeeHistoryClick}
      />
    </Tooltip>
  );

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) {
    editButtonNode = null;
    historyButtonNode = null;
  }

  const detailsNode = useMemo(() => getStrategyDetailsStr(company.statementStrategy), [company]);

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('statementStrategy.block.title')}
      icon={<StatementsFilled width={20} height={20} />}
    >
      <div className={styles.intro}>
        {t('statementStrategy.intro')}
      </div>
      <div className={styles.content}>
        <div className={styles.value}>
          {valueNode}
        </div>
        {editButtonNode}
        {historyButtonNode}
      </div>
      <div className={styles.subcontent}>
        {detailsNode}
      </div>
      {editStatementStrategyModalIsVisible && (
        <EditStatementStrategyModal
          visible
          onClose={() => showStatementStrategyModal(false)}
          statementStrategy={company.statementStrategy}
          statementStrategies={company.statementStrategies}
        />
      )}
      {historyStatementStrategyModalIsVisible && (
        <HistoryStatementStrategyModal
          visible
          onClose={() => showHistoryStatementStrategyModal(false)}
          statementStrategies={company.statementStrategies}
        />
      )}
    </CardItem>
  );
};

StatementStrategy.propTypes = {
  className: PropTypes.string,
};

StatementStrategy.defaultProps = {
  className: '',
};

export default StatementStrategy;
