import PropTypes from 'prop-types';

import { manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'PlanContent';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const transform = (content) => {
  if (!content) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(content, 'id'),
      name: manageStringAttribute(content, 'name'),
    }
  );
};
