import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';
import pluralize from 'pluralize';
import { useDispatch } from 'react-redux';

import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Button from '@palette/components/designSystem/Button/Button';
import EyesLine from '@palette/components/utils/Icons/EyesLine';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { MASTER_PLANS_EVENTS } from '@palette/constants/analytics';
import { PERIOD_TABS_IDS, PERIOD_TABS_QS_KEY } from '@palette/constants/tabs';
import { SEARCHED_PAYEE_QS_KEY, SEARCHED_USER_QS_KEY } from '@palette/constants/masterPlans';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanPeriodUserCell.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodUserCell = ({ className, user, plan, period, forManagers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleViewDeals = useCallback(() => {
    let searchedQSKey = SEARCHED_USER_QS_KEY;

    if (forManagers) {
      searchedQSKey = SEARCHED_PAYEE_QS_KEY;
    }

    dispatch(AnalyticsActions.sendEvent({ event: MASTER_PLANS_EVENTS.PERIOD_DETAILS.CLICK_VIEW_DEALS }));

    redirectTo({
      path: routePaths.v2.planPeriodDetails,
      params: { masterPlanId: plan.id, year: period.year, periodId: period.period },
      qsObject: { [PERIOD_TABS_QS_KEY]: PERIOD_TABS_IDS.DEALS, [searchedQSKey]: user.id },
    });
  }, [forManagers, plan, period, user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <UserProfile user={user} clickable />
      <Button
        className={styles.viewDealsBtn}
        type="link"
        icon={<EyesLine />}
        onClick={handleViewDeals}
      >
        {t('masterPlanPeriodUserCell.viewDeals', { dealType: pluralize(plan.trackingObject?.type || '') })}
      </Button>
    </div>
  );
};

MasterPlanPeriodUserCell.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  forManagers: PropTypes.bool,
};

MasterPlanPeriodUserCell.defaultProps = {
  className: '',
  forManagers: false,
};

export default MasterPlanPeriodUserCell;
