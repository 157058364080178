import PropTypes from 'prop-types';

import { manageAmountAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'CommissionPayoutOverwriteStrategy';

export const propTypes = PropTypes.exact({
  commissionId: PropTypes.string,
  amount: PropTypes.number,
  currency: PropTypes.string,
  dealName: PropTypes.string,
  planId: PropTypes.string,
  planName: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      commissionId: manageStringAttribute(strategy, 'commissionId'),
      amount: manageAmountAttribute(strategy, 'amount'),
      currency: manageStringAttribute(strategy, 'currency'),
      dealName: manageStringAttribute(strategy, 'dealName'),
      planId: manageStringAttribute(strategy, 'planId'),
      planName: manageStringAttribute(strategy, 'planName'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
