import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SpeedometerFilled from '@palette/components/utils/Icons/SpeedometerFilled';
import Button from '@palette/components/designSystem/Button/Button';
import AddDashboardWidgetDrawer from '@palette/components/ic/myDashboard/AddDashboardWidgetDrawer/AddDashboardWidgetDrawer';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';
import Loader from '@palette/components/utils/Loader/Loader';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { closeCustomizeDashboardPanel, customizeDashboardPanelIsClose } from '@palette/helpers/LocalStorageHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY } from '@palette/constants/dashboard';
import { DASHBOARD_WIDGETS_COMPONENTS } from '@palette/constants/widgets';
import { RIGHTS } from '@palette/constants/profile';
import { DASHBOARD_EVENTS } from '@palette/constants/analytics';

import { actions as DashboardActions, selectors as DashboardSelectors } from '@palette/state/Dashboard';
import { actions as NavigationActions } from '@palette/state/Navigation';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MyDashboardPageContent.less';

const classNames = bindClassNames.bind(styles);

const MyDashboardPageContent = ({ className }) => {
  const profile = useProfile();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getWidgetsDataIsPending = useSelector(DashboardSelectors.getWidgetsDataIsPending);
  const widgetsData = useSelector(DashboardSelectors.widgetsData);
  useEffect(() => {
    dispatch(DashboardActions.getWidgetsData());
  }, [profile.dashboardComponents]);

  const [customizePanelIsClose, setCustomizePanelIsClose] = useState(customizeDashboardPanelIsClose());

  const handleCloseCustomizeDashboardPanel = useCallback(() => {
    closeCustomizeDashboardPanel();
    setCustomizePanelIsClose(true);
  }, []);

  const handleOpenAddWidgetDrawer = useCallback(() => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [DASHBOARD_DRAWER_ADD_WIDGET_DRAWER_QS_KEY]: 'true' } }));
    dispatch(AnalyticsActions.sendEvent({ event: DASHBOARD_EVENTS.OPEN_WIDGET_DRAWER }));
  }, []);

  const customizeDashboardNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.DASHBOARD.UPDATE])) return null;

    if (customizePanelIsClose) {
      return (
        <div className={styles.customizeDashboardInlineWrapper}>
          <Button
            type="link"
            icon={<AddFilled />}
            onClick={handleOpenAddWidgetDrawer}
          >
            {t('myDashboardPage.customize.addWidget')}
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.customizeDashboardWrapper}>
        <SpeedometerFilled className={styles.customizeDashboardIcon} width={400} height={400} />
        <Button
          className={styles.customizeDashboardCloseBtn}
          type="linkSecondary"
          icon={<ClosePopupLine width={20} height={20} />}
          onClick={handleCloseCustomizeDashboardPanel}
        />
        <div className={styles.customizeDashboardTitle}>
          {t('myDashboardPage.customize.title')}
        </div>
        <div className={styles.customizeDashboardDescription}>
          <div className={styles.customizeDashboardDescriptionPart}>
            {t('myDashboardPage.customize.description1')}
          </div>
          <div className={styles.customizeDashboardDescriptionPart}>
            {t('myDashboardPage.customize.description2')}
          </div>
          <Button
            className={styles.customizeDashboardCTA}
            onClick={handleOpenAddWidgetDrawer}
          >
            {t('myDashboardPage.customize.cta')}
          </Button>
        </div>
      </div>
    );
  }, [profile, customizePanelIsClose, handleOpenAddWidgetDrawer, handleCloseCustomizeDashboardPanel]);

  const widgetsNode = useMemo(() => {
    if (getWidgetsDataIsPending && widgetsData.length === 0) {
      return (
        <Loader />
      );
    }

    if (widgetsData.length === 0) return null;

    const readOnly = !hasAtLeastOneRight(profile, [RIGHTS.IC.DASHBOARD.UPDATE]);

    const widgetsNodes = widgetsData.map((widgetData, index) => {
      const WidgetComponent = DASHBOARD_WIDGETS_COMPONENTS[widgetData.dashboardComponent.type] || null;

      if (WidgetComponent === null) return null;

      return (
        <WidgetComponent
          key={`widget_${index}`}
          widgetData={widgetData}
          widgetIndex={index}
          readOnly={readOnly}
        />
      );
    });

    return (
      <div className={styles.widgets}>
        {widgetsNodes}
      </div>
    );
  }, [
    profile,
    getWidgetsDataIsPending,
    widgetsData,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {customizeDashboardNode}
      {widgetsNode}
      <AddDashboardWidgetDrawer />
    </div>
  );
};

MyDashboardPageContent.propTypes = {
  className: PropTypes.string,
};

MyDashboardPageContent.defaultProps = {
  className: '',
};

export default MyDashboardPageContent;
