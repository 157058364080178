import _cloneDeep from 'lodash/cloneDeep';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

import { formatToISOStringWithFractionalSeconds } from '@palette/helpers/MomentHelper';

export function* list(payload) {
  const { archived } = payload;

  const data = { archived };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.list, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.VIEW],
  );
}

export function* getById(payload) {
  const { quotaId } = payload;

  const data = { quotaId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.getById, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.VIEW],
  );
}

export function* createQuota(payload) {
  const {
    name,
    type,
    periodType,
    frequency,
    defaultValue,
    beginAt = undefined,
    global = false,
  } = payload;

  const data = {
    name,
    type,
    periodType,
    frequency,
    defaultValue,
    beginAt,
    global,
  };

  if (beginAt != null) {
    data.beginAt = formatToISOStringWithFractionalSeconds(beginAt);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.create, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* addUsersToQuota(payload) {
  const {
    quotaId,
    usersToAdd,
  } = payload;

  const data = {
    quotaId,
    userIds: usersToAdd.map((user) => user.id),
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.addUsersToQuota, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE],
  );
}

export function* updateQuota(payload) {
  const {
    quotaId,
    name,
    type,
    defaultValues,
  } = payload;

  const data = {
    quotaId,
    name,
    type,
    defaultValues,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.updateQuota, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* updateUserFromQuota(payload) {
  const {
    quotaId,
    quotaUser,
    overwrite = null,
    defaultValues = null,
    lastPeriod = null,
  } = payload;

  const data = {
    quotaId,
    userId: quotaUser.user.id,
  };
  if (overwrite !== null) {
    const { year, period, target } = overwrite;
    let overwrites = _cloneDeep(quotaUser.overwrites);

    if (target === null) {
      overwrites = overwrites.filter((quotaUserOverwrite) => !(quotaUserOverwrite.year === year && quotaUserOverwrite.period === period));
    } else {
      let added = false;
      overwrites = overwrites.map((quotaUserOverwrite) => {
        if (quotaUserOverwrite.year === year && quotaUserOverwrite.period === period) {
          added = true;
          return ({
            year,
            period,
            value: target,
          });
        }

        return quotaUserOverwrite;
      });
      if (!added) {
        overwrites.push({
          year,
          period,
          value: target,
        });
      }
    }

    data.overwrites = overwrites;
  }

  if (defaultValues !== null) {
    data.defaultValues = defaultValues;
  }

  if (lastPeriod !== null) {
    data.lastPeriod = lastPeriod;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.updateUserFromQuota, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* listQuotasByUser(payload) {
  const { user } = payload;

  const data = {
    userId: user.id,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.listQuotasByUser, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.VIEW],
  );
}

export function* deleteById(payload) {
  const { quotaId } = payload;

  const data = { quotaId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.deleteById, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* removeUserFromQuota(payload) {
  const {
    quotaId,
    quotaUser,
  } = payload;

  const data = {
    quotaId,
    userId: quotaUser.user.id,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.removeUserFromQuota, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE],
  );
}

export function* downloadQuota(payload) {
  const {
    quotaId,
    year,
  } = payload;

  const data = {
    quotaId,
    year,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotas.exportAllByIdAndYear, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.VIEW],
  );
}
