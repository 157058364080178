/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { original } from 'immer';

import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _mergeWith from 'lodash/mergeWith';
import _keyBy from 'lodash/keyBy';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';
import * as UserStatementModel from '@palette/models/UserStatement';

/*
 * Initial State
 */
const initialState = {
  byId: {},
  listStatementsValidations: {},

  snapshotsById: {},
  listStatementsSnapshots: {},

  statementPeriods: [],
  statementPeriodsById: {},
  userStatementsByPeriodAndUserId: {},
  userStatementsById: {},
  icUserStatementsByPeriodId: {},
  userStatementsCorrectionsSummaries: {},
  userStatementsCorrectionsDetailsById: {},
  ongoingBulkApproveStatus: null,
  ongoingBulkMarkAsPaidStatus: null,

  listStatementsValidationsIsPending: false,
  listStatementsSnapshotsIsPending: false,
  listStatementPeriodsIsPending: false,
  statementPeriodByIdIsPending: false,
  getUserStatementByIdIsPending: false,
  deleteAdjustmentIsPending: false,
  addEditAdjustmentIsPending: false,
  listMyStatementPeriodsIsPending: false,
  getICUserStatementIsPending: false,
  approveStatementsIsPending: false,
  cancelStatementApprovalIsPending: false,
  markStatementsAsPaidIsPending: false,
  getUserStatementCorrectionSummariesIsPending: false,
  getUserStatementCorrectionDetailsIsPending: false,
  markAsDirtyIsPending: false,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Reset Statements Validations */
    resetStatementsValidations: (state) => {
      state.listStatementsValidations = {};
    },
    /* List Statements Validations */
    listStatementsValidations: (state) => {
      state.listStatementsValidationsIsPending = true;
    },
    setListStatementsValidations: (state, { payload }) => {
      const { statements, callData } = payload;

      state.listStatementsValidations = {
        list: _map(statements, 'id'),
        callData,
      };

      state.byId = _mergeWith(
        _cloneDeep(original(state.byId)),
        _keyBy(statements, 'id'),
      );
    },
    listStatementsValidationsCompleted: (state) => {
      state.listStatementsValidationsIsPending = false;
    },
    /* Download Statement Validation */
    downloadStatementValidation: () => {
      // Nothing to do here
    },
    downloadStatementValidationCompleted: () => {
      // Nothing to do here
    },
    /* Reset Statements Snapshots */
    resetStatementsSnapshots: (state) => {
      state.listStatementsSnapshots = {};
    },
    /* List Statements Snapshots */
    listStatementsSnapshots: (state) => {
      state.listStatementsSnapshotsIsPending = true;
    },
    setListStatementsSnapshots: (state, { payload }) => {
      const { snapshots, callData } = payload;

      state.listStatementsSnapshots = {
        list: _map(snapshots, 'id'),
        callData,
      };

      state.snapshotsById = _mergeWith(
        _cloneDeep(original(state.snapshotsById)),
        _keyBy(snapshots, 'id'),
      );
    },
    listStatementsSnapshotsCompleted: (state) => {
      state.listStatementsSnapshotsIsPending = false;
    },
    /* Download Snapshot */
    downloadSnapshot: () => {
      // Nothing to do here
    },
    downloadSnapshotCompleted: () => {
      // Nothing to do here
    },
    /* List Statements Periods */
    listStatementPeriods: (state) => {
      state.listStatementPeriodsIsPending = true;
    },
    setStatementPeriodsList: (state, { payload }) => {
      const { statementPeriods } = payload;

      state.statementPeriods = _map(statementPeriods, 'id');
      state.statementPeriodsById = _mergeWith(
        _cloneDeep(original(state.statementPeriodsById)),
        _keyBy(statementPeriods, 'id'),
        StatementPeriodModel.merge,
      );
    },
    listStatementPeriodsCompleted: (state) => {
      state.listStatementPeriodsIsPending = false;
    },
    /* Get Statement Period By Id */
    getStatementPeriodById: (state) => {
      state.statementPeriodByIdIsPending = true;
    },
    setStatementPeriod: (state, { payload }) => {
      const { statementPeriod, payeeId = undefined } = payload;

      const clonedStatementPeriodsById = _cloneDeep(original(state.statementPeriodsById));
      const inStateStatementPeriod = clonedStatementPeriodsById[statementPeriod.id] || null;

      clonedStatementPeriodsById[statementPeriod.id] = StatementPeriodModel.merge(inStateStatementPeriod, statementPeriod);

      const { userStatements, users } = clonedStatementPeriodsById[statementPeriod.id];

      if (payeeId) {
        const statementByUserId = userStatements.find((userStatement) => userStatement.user.id === payeeId);

        if (statementByUserId) {
          clonedStatementPeriodsById[statementPeriod.id] = StatementPeriodModel.mergeFromUserStatement(
            clonedStatementPeriodsById[statementPeriod.id],
            {
              ...statementByUserId,
              ...(inStateStatementPeriod && {
                statementAmount: inStateStatementPeriod.totalAmount,
                currency: inStateStatementPeriod.currency,
              }),
            },
          );
        }
      } else {
        state.userStatementsById = _mergeWith(
          _cloneDeep(original(state.userStatementsById)),
          _keyBy(userStatements, 'id'),
          UserStatementModel.merge,
        );
      }

      clonedStatementPeriodsById[statementPeriod.id].userStatements = _map(userStatements, 'id');
      clonedStatementPeriodsById[statementPeriod.id].users = users;
      state.statementPeriodsById = clonedStatementPeriodsById;
    },
    getStatementPeriodByIdCompleted: (state) => {
      state.statementPeriodByIdIsPending = false;
    },
    /* Get User Statement By Period Id */
    getUserStatementById: (state) => {
      state.getUserStatementByIdIsPending = true;
    },
    setUserStatement: (state, { payload }) => {
      const { statementPeriodId, userId, userStatement } = payload;

      const clonedUserStatementsById = _cloneDeep(original(state.userStatementsById));
      const inStateUserStatement = clonedUserStatementsById[userStatement.id] || null;

      clonedUserStatementsById[userStatement.id] = UserStatementModel.merge(inStateUserStatement, userStatement);
      state.userStatementsById = clonedUserStatementsById;

      const clonedUserStatementsByPeriodAndUserId = _cloneDeep(original(state.userStatementsByPeriodAndUserId));
      clonedUserStatementsByPeriodAndUserId[statementPeriodId] = clonedUserStatementsByPeriodAndUserId[statementPeriodId] || {};
      clonedUserStatementsByPeriodAndUserId[statementPeriodId][userId] = userStatement.id;
      state.userStatementsByPeriodAndUserId = clonedUserStatementsByPeriodAndUserId;

      if (!state.statementPeriodsById[statementPeriodId]) return;

      const clonedStatementPeriodsById = _cloneDeep(original(state.statementPeriodsById));
      const inStateStatementPeriod = clonedStatementPeriodsById[statementPeriodId];

      clonedStatementPeriodsById[statementPeriodId] = StatementPeriodModel.mergeFromUserStatement(
        inStateStatementPeriod,
        {
          ...userStatement,
          ...(inStateStatementPeriod && {
            statementAmount: inStateStatementPeriod.totalAmount,
            currency: inStateStatementPeriod.currency,
          }),
        },
      );
      state.statementPeriodsById = clonedStatementPeriodsById;
    },
    getUserStatementByIdCompleted: (state) => {
      state.getUserStatementByIdIsPending = false;
    },
    /* Get User Statement Correction Summary */
    getUserStatementCorrectionSummaries: (state) => {
      state.getUserStatementCorrectionSummariesIsPending = true;
    },
    setUserStatementCorrectionSummaries: (state, { payload }) => {
      const { correctionStatementSummaries, correctionStatementId } = payload;

      const clonedUserStatementsCorrectionsSummaries = _cloneDeep(original(state.userStatementsCorrectionsSummaries));

      clonedUserStatementsCorrectionsSummaries[correctionStatementId] = correctionStatementSummaries;

      state.userStatementsCorrectionsSummaries = clonedUserStatementsCorrectionsSummaries;
    },
    getUserStatementCorrectionSummariesCompleted: (state) => {
      state.getUserStatementCorrectionSummariesIsPending = false;
    },
    /* Get User Statement Correction Details */
    getUserStatementCorrectionDetails: (state) => {
      state.getUserStatementCorrectionDetailsIsPending = true;
    },
    setUserStatementCorrectionDetails: (state, { payload }) => {
      const { correctionStatementDetails, correctionStatementId, correctionSummaryId } = payload;

      const clonedUserStatementsCorrectionsDetailsById = _cloneDeep(original(state.userStatementsCorrectionsDetailsById));

      clonedUserStatementsCorrectionsDetailsById[correctionStatementId] = {
        ...clonedUserStatementsCorrectionsDetailsById[correctionStatementId],
        [correctionSummaryId]: correctionStatementDetails,
      };

      state.userStatementsCorrectionsDetailsById = clonedUserStatementsCorrectionsDetailsById;
    },
    getUserStatementCorrectionDetailsCompleted: (state) => {
      state.getUserStatementCorrectionDetailsIsPending = false;
    },
    /* Delete Adjustment */
    deleteAdjustment: (state) => {
      state.deleteAdjustmentIsPending = true;
    },
    deleteAdjustmentCompleted: (state) => {
      state.deleteAdjustmentIsPending = false;
    },
    /* Add or Update an Adjustment */
    addEditAdjustment: (state) => {
      state.addEditAdjustmentIsPending = true;
    },
    addEditAdjustmentCompleted: (state) => {
      state.addEditAdjustmentIsPending = false;
    },
    /* List My Statements Periods */
    listMyStatementPeriods: (state) => {
      state.listMyStatementPeriodsIsPending = true;
    },
    setMyStatementPeriodsList: (state, { payload }) => {
      const { statementPeriods } = payload;

      state.statementPeriods = _map(statementPeriods, 'id');
      state.statementPeriodsById = _mergeWith(
        _cloneDeep(original(state.statementPeriodsById)),
        _keyBy(statementPeriods, 'id'),
        StatementPeriodModel.merge,
      );
    },
    listMyStatementPeriodsCompleted: (state) => {
      state.listMyStatementPeriodsIsPending = false;
    },
    /* Get IC User Statement By Period Id */
    getICUserStatement: (state) => {
      state.getICUserStatementIsPending = true;
    },
    setICUserStatement: (state, { payload }) => {
      const { statementPeriodId, userStatement } = payload;

      const clonedUserStatementsById = _cloneDeep(original(state.userStatementsById));
      const inStateUserStatement = clonedUserStatementsById[userStatement.id] || null;

      clonedUserStatementsById[userStatement.id] = UserStatementModel.merge(inStateUserStatement, userStatement);
      state.userStatementsById = clonedUserStatementsById;

      state.icUserStatementsByPeriodId[statementPeriodId] = userStatement.id;
    },
    getICUserStatementCompleted: (state) => {
      state.getICUserStatementIsPending = false;
    },
    /* Approve Statements */
    approveStatements: (state, { payload }) => {
      state.approveStatementsIsPending = true;
      const {
        statementIds,
        statementPeriodId,
        currency,
        userId = null,
        isForIC = false,
      } = payload;
      state.ongoingBulkApproveStatus = {
        index: 0,
        statementIds,
        statementPeriodId,
        currency,
        userId,
        isForIC,
      };
    },
    updateOngoingBulkApproveStatusIndex: (state) => {
      const clonedOngoingBulkApproveStatus = _cloneDeep(original(state.ongoingBulkApproveStatus));
      clonedOngoingBulkApproveStatus.index += 1;
      state.ongoingBulkApproveStatus = clonedOngoingBulkApproveStatus;
    },
    approveStatementsCompleted: (state) => {
      state.approveStatementsIsPending = false;
      state.ongoingBulkApproveStatus = null;
    },
    /* Cancel Statement Approval */
    cancelStatementApproval: (state) => {
      state.cancelStatementApprovalIsPending = true;
    },
    cancelStatementApprovalCompleted: (state) => {
      state.cancelStatementApprovalIsPending = false;
    },
    /* Mark Statements As Paid */
    markAsPaidStatements: (state, { payload }) => {
      state.markStatementsAsPaidIsPending = true;
      const {
        statementIds,
        statementPeriodId,
        currency,
        userId = null,
      } = payload;
      state.ongoingBulkMarkAsPaidStatus = {
        index: 0,
        statementIds,
        statementPeriodId,
        currency,
        userId,
      };
    },
    updateOngoingBulkMarkAsPaidStatusIndex: (state) => {
      const clonedOngoingBulkMarkAsPaidStatus = _cloneDeep(original(state.ongoingBulkMarkAsPaidStatus));
      clonedOngoingBulkMarkAsPaidStatus.index += 1;
      state.ongoingBulkMarkAsPaidStatus = clonedOngoingBulkMarkAsPaidStatus;
    },
    markAsPaidStatementsCompleted: (state) => {
      state.markStatementsAsPaidIsPending = false;
    },
    /* Ignore Correction */
    ignoreCorrection: () => {
      // Nothing to do here
    },
    ignoreCorrectionCompleted: () => {
      // Nothing to do here
    },
    /* Download Period Users Statements */
    downloadPeriodUsersStatements: () => {
      // Nothing to do here
    },
    downloadPeriodUsersStatementsCompleted: () => {
      // Nothing to do here
    },
    /* Download User Statements */
    downloadUserStatement: () => {
      // Nothing to do here
    },
    downloadUserStatementCompleted: () => {
      // Nothing to do here
    },
    /* Download My User Statements */
    downloadMyUserStatements: () => {
      // Nothing to do here
    },
    downloadMyUserStatementsCompleted: () => {
      // Nothing to do here
    },
    /* Export to Payroll */
    exportToPayroll: () => {
      // Nothing to do here
    },
    exportToPayrollCompleted: () => {
      // Nothing to do here
    },
    /* Mark Statement as dirty */
    markAsDirty: (state) => {
      state.markAsDirtyIsPending = true;
    },
    markAsDirtyCompleted: (state) => {
      state.markAsDirtyIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getById = (state) => root(state).byId;
const listStatementsValidations = (state) => root(state).listStatementsValidations;
const getListStatementsValidationsCallData = (state) => root(state).listStatementsValidations.callData;
const getSnapshotsById = (state) => root(state).snapshotsById;
const listStatementsSnapshots = (state) => root(state).listStatementsSnapshots;
const getListStatementsSnapshotsCallData = (state) => root(state).listStatementsSnapshots.callData;
const rawStatementPeriods = (state) => root(state).statementPeriods;
const rawStatementPeriodsById = (state) => root(state).statementPeriodsById;
const rawUserStatementsById = (state) => root(state).userStatementsById;
const rawUserStatementsCorrectionsSummaries = (state) => root(state).userStatementsCorrectionsSummaries;
const rawUserStatementsCorrectionsDetailsById = (state) => root(state).userStatementsCorrectionsDetailsById;
const rawUserStatementsByPeriodAndUserId = (state) => root(state).userStatementsByPeriodAndUserId;
const rawICUserStatementsByPeriodId = (state) => root(state).icUserStatementsByPeriodId;
const getOngoingBulkApproveStatus = (state) => root(state).ongoingBulkApproveStatus;
const getOngoingBulkMarkAsPaidStatus = (state) => root(state).ongoingBulkMarkAsPaidStatus;

const listStatementsValidationsIsPending = (state) => root(state).listStatementsValidationsIsPending;
const listStatementsSnapshotsIsPending = (state) => root(state).listStatementsSnapshotsIsPending;
const listStatementPeriodsIsPending = (state) => root(state).listStatementPeriodsIsPending;
const getStatementPeriodByIdIsPending = (state) => root(state).statementPeriodByIdIsPending;
const getUserStatementByIdIsPending = (state) => root(state).getUserStatementByIdIsPending;
const deleteAdjustmentIsPending = (state) => root(state).deleteAdjustmentIsPending;
const addEditAdjustmentIsPending = (state) => root(state).addEditAdjustmentIsPending;
const listMyStatementPeriodsIsPending = (state) => root(state).listMyStatementPeriodsIsPending;
const getICUserStatementIsPending = (state) => root(state).getICUserStatementIsPending;
const approveStatementsIsPending = (state) => root(state).approveStatementsIsPending;
const cancelStatementApprovalIsPending = (state) => root(state).cancelStatementApprovalIsPending;
const markStatementsAsPaidIsPending = (state) => root(state).markStatementsAsPaidIsPending;
const getUserStatementCorrectionSummariesIsPending = (state) => root(state).getUserStatementCorrectionSummariesIsPending;
const getUserStatementCorrectionDetailsIsPending = (state) => root(state).getUserStatementCorrectionDetailsIsPending;
const markAsDirtyIsPending = (state) => root(state).markAsDirtyIsPending;

const getListStatementsValidations = createSelector(
  [listStatementsValidations, getById],
  (statementsValidations, byId) => (statementsValidations.list || []).map((statementId) => byId[statementId] || null).filter(Boolean),
);

const getListStatementsSnapshots = createSelector(
  [listStatementsSnapshots, getSnapshotsById],
  (statementsSnapshots, snapshotsById) => (statementsSnapshots.list || []).map((snapshotId) => snapshotsById[snapshotId] || null).filter(Boolean),
);

const buildStatementPeriod = (statementPeriodsById, userStatementsById, statementPeriodId) => {
  const statementPeriod = statementPeriodsById[statementPeriodId] || null;

  if (!statementPeriod) return null;

  const userStatements = statementPeriod.userStatements.map((userStatementId) => userStatementsById[userStatementId] || null).filter(Boolean);

  return {
    ...statementPeriod,
    userStatements,
  };
};

const getStatementPeriodById = createSelector(
  [rawStatementPeriodsById, rawUserStatementsById, getProps],
  (statementPeriodsById, userStatementsById, { statementPeriodId }) => buildStatementPeriod(statementPeriodsById, userStatementsById, statementPeriodId),
);

const getStatementPeriods = createSelector(
  [rawStatementPeriods, rawStatementPeriodsById, rawUserStatementsById],
  (statementPeriods, statementPeriodsById, userStatementsById) => statementPeriods.map((statementPeriodId) => (
    buildStatementPeriod(statementPeriodsById, userStatementsById, statementPeriodId)
  )).filter(Boolean),
);

const getMyStatementPeriods = createSelector(
  [rawStatementPeriods, rawStatementPeriodsById, rawUserStatementsById],
  (statementPeriods, statementPeriodsById, userStatementsById) => statementPeriods.map((statementPeriodId) => (
    buildStatementPeriod(statementPeriodsById, userStatementsById, statementPeriodId)
  )).filter(Boolean),
);

const getUserStatement = createSelector(
  [rawUserStatementsByPeriodAndUserId, rawUserStatementsById, getProps],
  (userStatementsByPeriodAndUserId, userStatementsById, { statementPeriodId, userId }) => (userStatementsById[userStatementsByPeriodAndUserId[statementPeriodId]?.[userId]] || null),
);

const getICUserStatement = createSelector(
  [rawICUserStatementsByPeriodId, rawUserStatementsById, getProps],
  (icUserStatementsByPeriodId, userStatementsById, { statementPeriodId }) => (userStatementsById[icUserStatementsByPeriodId[statementPeriodId]] || null),
);

const getUserStatementCorrectionSummaries = createSelector(
  [rawUserStatementsCorrectionsSummaries, getProps],
  (userStatementsCorrectionsSummaries, { correctionStatementId }) => userStatementsCorrectionsSummaries[correctionStatementId] || [],
);

const getUserStatementCorrectionDetailsById = createSelector(
  [rawUserStatementsCorrectionsDetailsById, getProps],
  (userStatementsCorrectionsDetailsById, { correctionStatementId, correctionSummaryId }) => userStatementsCorrectionsDetailsById[correctionStatementId]?.[correctionSummaryId] || null,
);

const getApprovalIsPending = createSelector(
  [approveStatementsIsPending, getOngoingBulkApproveStatus, getProps],
  (bulkApproveIsPending, ongoingBulkApproveStatus, { userStatementId }) => {
    if (!bulkApproveIsPending || ongoingBulkApproveStatus === null || !ongoingBulkApproveStatus.statementIds.includes(userStatementId)) return false;
    return ongoingBulkApproveStatus.statementIds.indexOf(userStatementId) === ongoingBulkApproveStatus.index;
  },
);

const getApprovalIsWaiting = createSelector(
  [approveStatementsIsPending, getOngoingBulkApproveStatus, getProps],
  (bulkApproveIsPending, ongoingBulkApproveStatus, { userStatementId }) => {
    if (!bulkApproveIsPending || ongoingBulkApproveStatus === null || !ongoingBulkApproveStatus.statementIds.includes(userStatementId)) return false;
    return ongoingBulkApproveStatus.statementIds.indexOf(userStatementId) > ongoingBulkApproveStatus.index;
  },
);

const getMarkAsPaidIsPending = createSelector(
  [markStatementsAsPaidIsPending, getOngoingBulkMarkAsPaidStatus, getProps],
  (bulkMarkAsPaidIsPending, ongoingBulkMarkAsPaidStatus, { userStatementId }) => {
    if (!bulkMarkAsPaidIsPending || ongoingBulkMarkAsPaidStatus === null || !ongoingBulkMarkAsPaidStatus.statementIds.includes(userStatementId)) return false;
    return ongoingBulkMarkAsPaidStatus.statementIds.indexOf(userStatementId) === ongoingBulkMarkAsPaidStatus.index;
  },
);

const getMarkAsPaidIsWaiting = createSelector(
  [markStatementsAsPaidIsPending, getOngoingBulkMarkAsPaidStatus, getProps],
  (bulkMarkAsPaidIsPending, ongoingBulkMarkAsPaidStatus, { userStatementId }) => {
    if (!bulkMarkAsPaidIsPending || ongoingBulkMarkAsPaidStatus === null || !ongoingBulkMarkAsPaidStatus.statementIds.includes(userStatementId)) return false;
    return ongoingBulkMarkAsPaidStatus.statementIds.indexOf(userStatementId) > ongoingBulkMarkAsPaidStatus.index;
  },
);

export const selectors = {
  getListStatementsValidations,
  getListStatementsValidationsCallData,
  getListStatementsSnapshots,
  getListStatementsSnapshotsCallData,
  getStatementPeriods,
  getStatementPeriodById,
  getUserStatement,
  getMyStatementPeriods,
  getICUserStatement,
  getUserStatementCorrectionSummaries,
  getUserStatementCorrectionDetailsById,
  getOngoingBulkApproveStatus,
  getApprovalIsPending,
  getApprovalIsWaiting,
  getOngoingBulkMarkAsPaidStatus,
  getMarkAsPaidIsPending,
  getMarkAsPaidIsWaiting,

  listStatementsValidationsIsPending,
  listStatementsSnapshotsIsPending,
  listStatementPeriodsIsPending,
  getStatementPeriodByIdIsPending,
  getUserStatementByIdIsPending,
  deleteAdjustmentIsPending,
  addEditAdjustmentIsPending,
  listMyStatementPeriodsIsPending,
  getICUserStatementIsPending,
  approveStatementsIsPending,
  cancelStatementApprovalIsPending,
  markStatementsAsPaidIsPending,
  getUserStatementCorrectionSummariesIsPending,
  getUserStatementCorrectionDetailsIsPending,
  markAsDirtyIsPending,
};
