import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';

import { VARIABLE_NAME_REGEX } from '@palette/constants/resources';

import styles from './InputTableEditColumnModal.less';

const InputTableEditColumnModal = ({ visible, columns, onClose, columnId, columnName }) => {
  const { t } = useTranslation();

  const columnsNames = useMemo(() => (
    columns.filter((col) => (col.id !== columnId)).map((col) => (col.Header ?? ''))
  ), [columns, columnId]);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    name: columnName,
  }), [columnName]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback((newColumnName = null) => {
    form.resetFields();
    onClose(newColumnName);
  }, [onClose]);

  const handleFinish = useCallback(({ name }) => {
    cleanAndClose(name);
  }, []);

  const handleNewColumn = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('inputTableEditColumnModal.title.editColumn')}
      visible={visible}
      onCancel={(event) => { event.stopPropagation(); cleanAndClose(); }}
      onOk={handleNewColumn}
      okText={t('inputTableEditColumnModal.updateColumn')}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="name"
          label={t('inputTableEditColumnModal.form.name.label')}
          required
          rules={[
            { required: true, message: t('inputTableEditColumnModal.form.name.rules.required'), pattern: VARIABLE_NAME_REGEX },
            () => ({
              validator(_, value) {
                if (!value || !columnsNames.includes(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('inputTableEditColumnModal.form.name.rules.alreadyExists')));
              },
            }),
          ]}
        >
          <Input size="big" />
        </FormItem>
      </Form>
    </Modal>
  );
};

InputTableEditColumnModal.propTypes = {
  visible: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func,
  columnId: PropTypes.string.isRequired,
  columnName: PropTypes.string.isRequired,
};

InputTableEditColumnModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default InputTableEditColumnModal;
