import _cloneDeep from 'lodash/cloneDeep';
import _size from 'lodash/size';

import { getI18n } from '@palette/i18n';

import { NUMBER_REGEX } from '@palette/constants/global';
import { REPORT_SLUG } from '@palette/constants/resources';

export const deduplicateFilterFn = (element, index, array) => (array.indexOf(element) === index);

export const comaSeparatorFormatter = (value) => {
  const splittedValue = `${value}`.split('.');

  let decimalPart = '';
  if (splittedValue.length === 2) {
    decimalPart = `.${splittedValue[1]}`;
  }
  const replacedIntegerPart = splittedValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${replacedIntegerPart}${decimalPart}`;
};

export const comaSeparatorParser = (value) => {
  const parsedValue = `${value}`.replace(/(,)/g, '');
  if (parsedValue === '') return '0';
  return parsedValue;
};

export const capitalize = (string) => string
  .toLowerCase()
  .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());

export const formatNumberShortDisplay = (value) => {
  const i18n = getI18n();

  const parsedValue = parseFloat(value);

  const formatter = new Intl.NumberFormat(i18n.language, {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
  });
  return formatter.format(parsedValue);
};

export const floatToFixedNumber = (value, fractionDigits = 2) => {
  let parsedValue = parseFloat(parseFloat(value).toFixed(fractionDigits));
  if (parsedValue && parsedValue % 1 === 0) {
    parsedValue = parseInt(parsedValue, 10);
  }

  return parsedValue;
};

export const isResourceReport = (type) => type && type.indexOf(REPORT_SLUG) === 0;

export const getTypeDisplayed = (originalType) => {
  let typeDisplayed = originalType;

  if (isResourceReport(typeDisplayed)) {
    typeDisplayed = originalType.split('_').splice(0, 2).join('_');

    if (typeDisplayed.length === 0) {
      typeDisplayed = originalType;
    }
  }

  return typeDisplayed;
};

export const isFakedNumber = (value) => !!(typeof (value) === 'number' || NUMBER_REGEX.test(value));

export const swapListItemsPositions = (listItems, itemIndex, toPositionIndex) => {
  const newListItems = _cloneDeep(listItems);
  const widgetComponent = newListItems[itemIndex];

  if (itemIndex !== toPositionIndex) {
    if (toPositionIndex < itemIndex) {
      newListItems.splice(toPositionIndex, 0, widgetComponent);
      newListItems.splice(itemIndex + 1, 1);
    } else {
      newListItems.splice(toPositionIndex + 1, 0, widgetComponent);
      newListItems.splice(itemIndex, 1);
    }
  }

  return newListItems;
};

export const countKeysOcc = (items, searchTerm) => {
  let totalOcc = 0;

  if (!_size(items) > 0) return totalOcc;

  if (Array.isArray(items)) {
    let totalIfArr = 0;

    items.forEach((item) => {
      if (Array.isArray(item) || typeof item === 'object') {
        totalIfArr += countKeysOcc(item, searchTerm);
      } else if (searchTerm === item) {
        totalIfArr += 1;
      }
    });

    return totalIfArr;
  }

  if (typeof items === 'object') {
    let totalIfObj = 0;

    Object.keys(items).map((item) => {
      if (items[item] && (Array.isArray(items[item]) || typeof items[item] === 'object')) {
        totalIfObj += countKeysOcc(items[item], searchTerm);
      } else if (searchTerm === item) {
        totalIfObj += 1;
      }

      return totalIfObj;
    });

    totalOcc += totalIfObj;
  }

  return totalOcc;
};
