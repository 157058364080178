import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';

import { getRoutesList } from '@palette/config/routes';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getDefaultRoute } from '@palette/helpers/ProfileHelper';
import { setHistory } from '@palette/helpers/NavigationHelper';

import { actions as NavigationActions } from '@palette/state/Navigation';

const ROUTES = getRoutesList();

const LoggedUserRouting = ({ appWrapperState }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  setHistory(history);

  useEffect(() => dispatch(NavigationActions.updateCurrentLocation(location)), [location]);

  const profile = useProfile();

  return (
    <Switch>
      {ROUTES.map((route) => (
        <Route
          key={route.path}
          path={`${route.path}`}
          render={(props) => (
            <route.component
              {...props}
              {...appWrapperState}
            />
          )}
          exact
        />
      ))}
      <Redirect from="*" to={getDefaultRoute(profile)} />
    </Switch>
  );
};

LoggedUserRouting.propTypes = {
  appWrapperState: PropTypes.object.isRequired,
};

export default LoggedUserRouting;
