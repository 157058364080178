import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import StatementV3ApprovalsListItem from '@palette/components/statementV3/StatementV3ApprovalsListItem/StatementV3ApprovalsListItem';
import StatementV3ApprovalsListMoreAvatar from '@palette/components/statementV3/StatementV3ApprovalsListMoreAvatar/StatementV3ApprovalsListMoreAvatar';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasFeature } from '@palette/helpers/ProfileHelper';
import { isApprovalInConflict } from '@palette/helpers/StatementV3Helper';

import { FEATURES } from '@palette/constants/profile';

import * as StatementV3ActionModel from '@palette/models/StatementV3Action';

import styles from './StatementV3ApprovalsList.less';

const classNames = bindClassNames.bind(styles);

const StatementV3ApprovalsList = ({ className, approvals, limitShown, statementAmount, statementCurrency }) => {
  const profile = useProfile();

  const contentNode = useMemo(() => {
    if (!approvals?.length) return null;

    const finalContent = [];
    const approvalsList = [...approvals];

    for (let approval = 0; approval < limitShown; approval += 1) {
      const currentApproval = approvalsList.shift();

      if (currentApproval) {
        const { amount, currency } = currentApproval;

        finalContent.push(
          <StatementV3ApprovalsListItem
            key={`approval-${approval}`}
            className={styles.avatarItemWrapper}
            approval={currentApproval}
            isInConflict={isApprovalInConflict(statementAmount, statementCurrency, amount, currency)}
          />,
        );
      }
    }

    if (approvalsList.length >= 1) {
      finalContent.push(
        <StatementV3ApprovalsListMoreAvatar
          key="more-approvals"
          approvals={approvalsList}
          statementAmount={statementAmount}
          statementCurrency={statementCurrency}
        />,
      );
    }

    return finalContent;
  }, [approvals, statementAmount, statementCurrency]);

  const contentContainer = useMemo(() => {
    if (!contentNode) return null;

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
    );
  }, [contentNode]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION)) return null;

  return contentContainer;
};

StatementV3ApprovalsList.propTypes = {
  className: PropTypes.string,
  approvals: PropTypes.arrayOf(StatementV3ActionModel.propTypes),
  limitShown: PropTypes.number,
  statementAmount: PropTypes.number.isRequired,
  statementCurrency: PropTypes.string.isRequired,
};

StatementV3ApprovalsList.defaultProps = {
  className: '',
  approvals: [],
  limitShown: 3,
};

export default StatementV3ApprovalsList;
