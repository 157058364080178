import PropTypes from 'prop-types';

import { manageArrayAttribute, managePureFloatAttribute } from '@palette/helpers/ModelHelper';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

export const modelName = 'PlanProgressBarChartData';

export const propTypes = PropTypes.shape({
  progressPerPeriod: PropTypes.arrayOf(MasterPlanPeriodModel.propTypes),
  target: PropTypes.number,
});

export const transform = (planProgressBarChart) => {
  if (!planProgressBarChart) {
    return null;
  }

  return (
    {
      progressPerPeriod: MasterPlanPeriodModel.transformList(manageArrayAttribute(planProgressBarChart, 'periodMonths')),
      target: managePureFloatAttribute(planProgressBarChart, 'target'),
    }
  );
};
