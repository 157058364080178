import PropTypes from 'prop-types';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as CommentEventContentModel from '@palette/models/CommentEventContent';

import {
  manageDateAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { TYPES } from '@palette/constants/comments';

export const modelName = 'Comment';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  createdAt: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Object is CommentEventContentModel.propTypes
  type: PropTypes.oneOf(Object.values(TYPES)),
  user: MetaUserModel.propTypes,
});

export const manageCommentType = (comment) => (TYPES[manageStringAttribute(comment, 'type')] || null);

export const manageCommentContent = (comment) => {
  const content = manageStringAttribute(comment, 'content', null);

  if (content === null) return null;

  const type = manageCommentType(comment);

  return type === TYPES.EVENT
    ? CommentEventContentModel.transform(JSON.parse(content))
    : content;
};

export const transform = (comment) => {
  if (!comment) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(comment, '_id'),
      createdAt: manageDateAttribute(comment, 'createdAt'),
      content: manageCommentContent(comment),
      type: manageStringAttribute(comment, 'type'),
      user: MetaUserModel.transform(comment.user),
    }
  );
};

export const transformList = (comments) => comments.map((comment) => transform(comment));
