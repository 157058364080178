import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage400 = (problem) => {
  const { detail = undefined } = problem.additionalData;
  const context = detail;

  const managedErrors = {
    'planv3.no.plan.user': GLOBAL_NOTIF_REASONS.PLAN_V3_NO_PLAN_USER_ERROR.code,
    'planv3.no.data.source': GLOBAL_NOTIF_REASONS.PLAN_V3_NO_PLAN_DATA_SOURCE_ERROR.code,
    'planV3.invalid.endDate': GLOBAL_NOTIF_REASONS.PLAN_V3_INVALID_END_DATE_ERROR.code,
    'planv3.required.information': GLOBAL_NOTIF_REASONS.PLAN_V3_MISSING_INFORMATION_ERROR.code,
  };

  let error = null;

  if (managedErrors[problem.errorId]) {
    error = {
      code: managedErrors[problem.errorId],
      context,
    };
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    400: manage400,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code, context } = error;

    return {
      ...problem,
      message: { code, context },
    };
  }

  return manageGlobalError({ status, problem });
};
