import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import _keyBy from 'lodash/keyBy';
import _orderBy from 'lodash/orderBy';

import TeamSelector from '@palette/components/team/TeamSelector/TeamSelector';
import Select from '@palette/components/designSystem/Select/Select';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';

import { useDashboardPresetUserInSearch } from '@palette/hooks/DashboardPresetHooks';

import { DASHBOARD_PRESET_USER_QS_KEY } from '@palette/constants/dashboard';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';
import { selectors as TeamsSelectors } from '@palette/state/Teams';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './DashboardPresetUserSelect.less';

const classNames = bindClassNames.bind(styles);

const DashboardPresetUserSelect = ({ className }) => {
  const dispatch = useDispatch();

  const { presetUserId, presetUserIdInSearch } = useDashboardPresetUserInSearch();

  const presetCandidateUsersList = useSelector(DashboardPresetsSelectors.getPresetCandidateUsersList);
  const getPresetCandidateUsersIsPending = useSelector(DashboardPresetsSelectors.getPresetCandidateUsersIsPending);

  useEffect(() => {
    if (presetCandidateUsersList === null) {
      dispatch(DashboardPresetsActions.getPresetCandidateUsers());
    }
  }, [presetCandidateUsersList]);

  const presetCandidateUsers = useMemo(() => {
    const usersList = presetCandidateUsersList || [];
    const hasAccount = (user) => (user.account !== null);
    const orderedByDisplayName = (user) => (user.displayName);

    return _orderBy(usersList, [hasAccount, orderedByDisplayName], ['desc', 'asc']);
  }, [presetCandidateUsersList]);

  useEffect(() => {
    if (
      !getPresetCandidateUsersIsPending
      && presetCandidateUsersList !== null
      && presetCandidateUsers.length > 0
      && !presetUserIdInSearch
    ) {
      dispatch(NavigationActions.addQSToLocation({ qsObject: { [DASHBOARD_PRESET_USER_QS_KEY]: presetCandidateUsers[0].id } }));
    }
  }, [presetCandidateUsersList, presetCandidateUsers, getPresetCandidateUsersIsPending, presetUserIdInSearch]);

  const [selectedTeamId, setSelectedTeamId] = useState(null);

  const nbOfTeams = useSelector(TeamsSelectors.getNbOfTeams);
  const selectedTeam = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId: selectedTeamId }));

  const teamsAvailable = useMemo(() => (nbOfTeams > 0), [nbOfTeams]);

  const filteredUsers = useMemo(() => {
    let finalUsers = presetCandidateUsers;

    if (selectedTeam !== null) {
      const membersIds = selectedTeam.members.map((teamUser) => teamUser.user.id);
      finalUsers = finalUsers.filter((user) => membersIds.includes(user.id));
    }

    return finalUsers;
  }, [presetCandidateUsers, selectedTeam]);

  const usersOptions = useMemo(() => {
    const usersById = _keyBy(filteredUsers, (user) => user.id);

    return Object.values(usersById).map((user) => ({
      label: (
        <UserProfile
          className={styles.userOption}
          user={user}
          avatarSize={18}
          avatarStyle={{
            fontSize: '1rem',
            width: '1.8rem',
            minWidth: '1.8rem',
            height: '1.8rem',
          }}
        />
      ),
      value: user.id,
      rawlabel: user.displayName,
    }));
  }, [filteredUsers]);

  const handleUserChange = useCallback((userId) => {
    if (userId !== null) {
      dispatch(NavigationActions.addQSToLocation({ qsObject: { [DASHBOARD_PRESET_USER_QS_KEY]: userId } }));
    }
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <TeamSelector
        selectedTeam={selectedTeamId}
        onTeamSelected={setSelectedTeamId}
        disabled={!teamsAvailable || getPresetCandidateUsersIsPending}
      />
      <Select
        className={styles.select}
        dropdownClassName={styles.selectDropdown}
        options={usersOptions}
        showSearch
        enableFilterOptions
        filterOptionProp="rawlabel"
        value={presetUserId}
        onChange={handleUserChange}
        pendingOptions={usersOptions.length === 0 && getPresetCandidateUsersIsPending}
      />
    </div>
  );
};

DashboardPresetUserSelect.propTypes = {
  className: PropTypes.string,
};

DashboardPresetUserSelect.defaultProps = {
  className: '',
};

export default DashboardPresetUserSelect;
