import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { getCustomPeriodTypeFrequencyName, getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { QUOTA_PERFORMANCE_METRIC_TYPES, QUOTA_PERFORMANCE_METRIC_THEMES } from '@palette/constants/quotasPerformance';

export const getQuotaPerformanceValuesByMetric = (periodValue) => {
  const { metricType } = periodValue;

  let value = periodValue.value ?? periodValue;
  let theme = QUOTA_PERFORMANCE_METRIC_THEMES.WHITE;

  if (metricType && metricType !== QUOTA_PERFORMANCE_METRIC_TYPES.PERCENTAGE) {
    value = comaSeparatorFormatter(floatToFixedNumber(value));
  } else {
    const percentValue = value * 100;

    if (percentValue >= 100) {
      theme = QUOTA_PERFORMANCE_METRIC_THEMES.GREEN;
    } else if (percentValue >= 90) {
      theme = QUOTA_PERFORMANCE_METRIC_THEMES.KHAKI;
    } else if (percentValue >= 70) {
      theme = QUOTA_PERFORMANCE_METRIC_THEMES.WHITE;
    } else if (percentValue >= 30) {
      theme = QUOTA_PERFORMANCE_METRIC_THEMES.YELLOW;
    } else {
      theme = QUOTA_PERFORMANCE_METRIC_THEMES.ORANGE;
    }

    value = `${floatToFixedNumber(percentValue)}%`;
  }

  return { theme, value };
};

export const getQuotaPerformancePeriodName = (periodType, frequency, year, period, customBeginAt) => {
  if (periodType === PERIOD_TYPES.CUSTOM) {
    return getCustomPeriodTypeFrequencyName(customBeginAt, frequency);
  }

  return getFrequencyPeriodName(periodType, frequency, year, period, customBeginAt, true);
};
