import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import UserConnectorsSelect from '@palette/components/user/UserConnectorsSelect/UserConnectorsSelect';
import UpdateUserTypeConfirmationModal from '@palette/components/masterPlan/UpdateUserTypeConfirmationModal/UpdateUserTypeConfirmationModal';
import UsersSelectionPanel from '@palette/components/user/UsersSelectionPanel/UsersSelectionPanel';
import UsersSelectedPanel from '@palette/components/user/UsersSelectedPanel/UsersSelectedPanel';
import Button from '@palette/components/designSystem/Button/Button';
import CreatePlanFlowStepActions from '@palette/components/masterPlanCreation/CreatePlanFlowStepActions/CreatePlanFlowStepActions';

import { scrollToNextStep } from '@palette/helpers/MasterPlanHelper';

import { CREATE_PLAN_FLOW_STEPS, SCOPES } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowSelectUsersStep.less';

const classNames = bindClassNames.bind(styles);

const MANAGERS_SELECTED_PANEL_ID = 'managersSelectedPanelTop';

const CreatePlanFlowSelectUsersStep = ({ className, planId, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updateUserTypeConfirmationModalIsVisible, showUpdateUserTypeConfirmationModal] = useState(false);
  const [connectorIdAndType, setConnectorIdAndType] = useState(null);

  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: planId }));

  const addUsersToMasterPlanIsPending = useSelector(MasterPlansSelectors.addUsersToPlanIsPending);
  const planUsersAndManagersIsPending = useSelector(MasterPlansSelectors.getPlanUsersAndManagersIsPending);

  useEffect(() => {
    if (plan !== null) {
      dispatch(MasterPlansActions.getPlanUsersAndManagers({ planId: plan.id }));
    }
  }, [plan]);

  const displayUsersManagementNodes = useMemo(() => {
    if (plan === null) return false;

    return plan.usersDefinition?.type !== 'default';
  }, [plan]);

  const userConnectorValue = useMemo(() => {
    if (!displayUsersManagementNodes) return null;

    if (connectorIdAndType !== null) return connectorIdAndType;

    return {
      connectorId: plan.usersDefinition?.connectorId,
      type: plan.usersDefinition?.type,
    };
  }, [displayUsersManagementNodes, plan, connectorIdAndType, planUsersAndManagersIsPending]);

  const updateUserType = useCallback((connectorId, type) => {
    dispatch(MasterPlansActions.updatePlanUsersDefinition({ planId: plan.id, connectorId, type, disableSuccessNotification: true }));
  }, [plan]);

  const handleCancelUpdateUserType = useCallback(() => {
    setConnectorIdAndType(null);
    showUpdateUserTypeConfirmationModal(false);
  });

  const handleUpdateUserType = useCallback(() => {
    if (connectorIdAndType !== null) {
      updateUserType(connectorIdAndType.connectorId, connectorIdAndType.type);
    }
    showUpdateUserTypeConfirmationModal(false);
  }, [connectorIdAndType, updateUserType]);

  const handleSelectConnector = useCallback((value, connectorId, type) => {
    if (planUsersAndManagers.managers.length > 0 || planUsersAndManagers.users.length > 0) {
      setConnectorIdAndType({ connectorId, type });
      showUpdateUserTypeConfirmationModal(true);
    } else {
      updateUserType(connectorId, type);
    }
  }, [planUsersAndManagers, updateUserType]);

  const [managersToAdd, setManagersToAdd] = useState([]);

  const addNewManagers = useCallback(() => {
    const afterAddingManagers = () => {
      setManagersToAdd([]);
      scrollToNextStep(MANAGERS_SELECTED_PANEL_ID);
    };

    dispatch(MasterPlansActions.addUsersToPlan({ planId: plan.id, usersToAdd: managersToAdd, forManagers: true, onSuccessCB: afterAddingManagers }));
  }, [plan, managersToAdd, setManagersToAdd]);

  const handleRemoveManager = useCallback((managerUser) => {
    const managersToRemove = [managerUser];
    dispatch(MasterPlansActions.removeUsersFromPlan({ planId: plan.id, usersToRemove: managersToRemove, forManagers: true }));
  }, [plan, planUsersAndManagers]);

  const handleRemoveUser = useCallback((userUser) => {
    const usersToRemove = [userUser];
    dispatch(MasterPlansActions.removeUsersFromPlan({ planId: plan.id, usersToRemove, forManagers: false }));
  }, [plan, planUsersAndManagers]);

  const managersNode = useMemo(() => {
    if (!displayUsersManagementNodes || plan.scope !== SCOPES.MANAGER) return null;

    const preSelectedManagers = planUsersAndManagers.managers.map((planUser) => planUser.user);

    const handleSelectedManagersChange = (selectedManagers) => {
      setManagersToAdd(selectedManagers);
    };

    return (
      <div className={styles.addUsersWrapper}>
        <div className={styles.addUsersTitleWrapper}>
          <div className={styles.addUsersTitle}>
            {t('createPlanFlowSelectUsersStep.addManagers.title')}
          </div>
          <div className={styles.addUsersSubTitle}>
            {t('createPlanFlowSelectUsersStep.addManagers.subTitle')}
          </div>
        </div>
        <UsersSelectionPanel
          className={styles.usersSelectionPanel}
          preSelectedUsers={preSelectedManagers}
          onChange={handleSelectedManagersChange}
          restrictToConnectorIdAndType={userConnectorValue}
          onlyActiveUsers
        />
        <div id={MANAGERS_SELECTED_PANEL_ID} />
        <Button className={styles.addUsersBtn} onClick={addNewManagers} disabled={addUsersToMasterPlanIsPending || managersToAdd.length === 0}>
          {t('createPlanFlowSelectUsersStep.addManagers.addUsersBtnLabel', { count: managersToAdd.length })}
        </Button>
        <div className={styles.selectedUsersWrapper}>
          <div className={styles.selectedUsersTitle}>
            {t('createPlanFlowSelectUsersStep.addManagers.managersInPlan', { count: preSelectedManagers.length })}
          </div>
          <UsersSelectedPanel
            className={styles.selectedUsersPanel}
            selectedUsers={preSelectedManagers}
            noSelectedUsersWarningMessage={t('createPlanFlowSelectUsersStep.addManagers.empty')}
            onRemove={handleRemoveManager}
          />
        </div>
      </div>
    );
  }, [
    displayUsersManagementNodes,
    plan,
    planUsersAndManagers,
    userConnectorValue,
    managersToAdd,
    setManagersToAdd,
    addNewManagers,
    addUsersToMasterPlanIsPending,
    planUsersAndManagersIsPending,
    handleRemoveManager,
  ]);

  const [usersToAdd, setUsersToAdd] = useState([]);

  const addNewUsers = useCallback(() => {
    const afterAddingUsers = () => {
      setUsersToAdd([]);
      scrollToNextStep();
    };

    dispatch(MasterPlansActions.addUsersToPlan({ planId: plan.id, usersToAdd, forManagers: false, onSuccessCB: afterAddingUsers }));
  }, [plan, usersToAdd, setUsersToAdd]);

  const usersNode = useMemo(() => {
    if (!displayUsersManagementNodes) return null;

    const preSelectedUsers = planUsersAndManagers.users.map((planUser) => planUser.user);

    const handleSelectedUsersChange = (selectedUsers) => {
      setUsersToAdd(selectedUsers);
    };

    return (
      <div className={styles.addUsersWrapper}>
        <div className={styles.addUsersTitleWrapper}>
          <div className={styles.addUsersTitle}>
            {t('createPlanFlowSelectUsersStep.addUsers.title')}
          </div>
          <div className={styles.addUsersSubTitle}>
            {t('createPlanFlowSelectUsersStep.addUsers.subTitle')}
          </div>
        </div>
        <UsersSelectionPanel
          className={styles.usersSelectionPanel}
          preSelectedUsers={preSelectedUsers}
          onChange={handleSelectedUsersChange}
          restrictToConnectorIdAndType={userConnectorValue}
          onlyActiveUsers
        />
        <Button className={styles.addUsersBtn} onClick={addNewUsers} disabled={addUsersToMasterPlanIsPending || usersToAdd.length === 0}>
          {t('createPlanFlowSelectUsersStep.addUsers.addUsersBtnLabel', { count: usersToAdd.length })}
        </Button>
        <div className={styles.selectedUsersWrapper}>
          <div className={styles.selectedUsersTitle}>
            {t('createPlanFlowSelectUsersStep.addUsers.usersInPlan', { count: preSelectedUsers.length })}
          </div>
          <UsersSelectedPanel
            className={styles.selectedUsersPanel}
            selectedUsers={preSelectedUsers}
            noSelectedUsersWarningMessage={t('createPlanFlowSelectUsersStep.addUsers.empty')}
            onRemove={handleRemoveUser}
          />
        </div>
      </div>
    );
  }, [
    displayUsersManagementNodes,
    planUsersAndManagers,
    userConnectorValue,
    usersToAdd,
    setUsersToAdd,
    addNewUsers,
    addUsersToMasterPlanIsPending,
    planUsersAndManagersIsPending,
    handleRemoveUser,
  ]);

  const actionsNode = useMemo(() => {
    if (!displayUsersManagementNodes) return null;

    let disabledNext = false;
    disabledNext = disabledNext || (planUsersAndManagers.users.length === 0 && usersToAdd.length === 0);
    if (plan.scope === SCOPES.MANAGER) {
      disabledNext = disabledNext || (planUsersAndManagers.managers.length === 0 && managersToAdd.length === 0);
    }

    return (
      <CreatePlanFlowStepActions plan={plan} currentStepValue={CREATE_PLAN_FLOW_STEPS.SELECT_USERS.value} disabled={addUsersToMasterPlanIsPending} disabledNext={disabledNext} />
    );
  }, [
    plan,
    displayUsersManagementNodes,
    addUsersToMasterPlanIsPending,
    managersToAdd,
    usersToAdd,
    planUsersAndManagers,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.connectorsDescription}>
        {t('masterPlanSettingsUsers.connectorsDescription')}
      </div>
      <UserConnectorsSelect
        className={styles.connectorsSelect}
        onSelect={handleSelectConnector}
        value={userConnectorValue}
        selectFirstOptionByDefault
      />
      {managersNode}
      {usersNode}
      {actionsNode}
      {updateUserTypeConfirmationModalIsVisible && (
        <UpdateUserTypeConfirmationModal visible onCancel={handleCancelUpdateUserType} onConfirm={handleUpdateUserType} />
      )}
    </div>
  );
};

CreatePlanFlowSelectUsersStep.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowSelectUsersStep.defaultProps = {
  className: '',
  planId: null,
  plan: null,
};

export default CreatePlanFlowSelectUsersStep;
