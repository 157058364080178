import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Card as AntDCard } from 'antd';

import styles from './CardItem.less';

const classNames = bindClassNames.bind(styles);

const CardItem = ({ className, title, icon, children }) => {
  const titleNode = useMemo(() => (
    <div className={styles.title}>
      {icon && <span className={styles.icon}>{icon}</span>}
      {title}
    </div>
  ), [title, icon]);

  return (
    <AntDCard
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={titleNode}
    >
      {children}
    </AntDCard>
  );
};

CardItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.any,
  children: PropTypes.any,
};

CardItem.defaultProps = {
  className: '',
  icon: null,
  children: null,
};

export default CardItem;
