import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';

import { ALERT_TYPES } from '@palette/constants/alert';

import styles from './NoUserTypeConnectorsAlert.less';

const classNames = bindClassNames.bind(styles);

const NoUserTypeConnectorsAlert = ({ className }) => {
  const { t } = useTranslation();

  /* eslint-disable react/no-danger */
  return (
    <Alert
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      type={ALERT_TYPES.WARNING}
      message={t('noUserTypeConnectorsAlert.warning.noUsersFound.message')}
      description={<span dangerouslySetInnerHTML={{ __html: t('noUserTypeConnectorsAlert.warning.noUsersFound.description') }} />}
    />
  );
  /* eslint-enable react/no-danger */
};

NoUserTypeConnectorsAlert.propTypes = {
  className: PropTypes.string,
};

NoUserTypeConnectorsAlert.defaultProps = {
  className: '',
};

export default NoUserTypeConnectorsAlert;
