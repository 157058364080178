import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import Disclaimer from '@palette/components/designSystem/Disclaimer/Disclaimer';
import MasterPlanSettingsCommissionRulesFormRule from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesFormRule/MasterPlanSettingsCommissionRulesFormRule';

import { getNbOfPeriodsSinceLastFrozenPeriod, commissionRulesValidationForm } from '@palette/helpers/MasterPlanHelper';

import { RULE_TYPES } from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as PlanRuleModel from '@palette/models/PlanRule';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsCommissionRulesForm.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCommissionRulesForm = ({
  className,
  plan,
  helperData,
  inPlanCreationFlow,
  inPlanCreationFlowSetSaveCB,
  inPlanCreationFlowOnRulesUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [onSuccessSaveCommissionRulesCB, setOnSuccessSaveCommissionRulesCB] = useState(null);
  const [reconciliationIndex, setReconciliationIndex] = useState(0);

  const initialRules = useMemo(() => (plan.rules.map((rule) => ({
    ...rule,
    brackets: rule.brackets.map((bracket) => ({
      ...bracket,
      ...(rule.percentage && {
        from: Math.floor(bracket.from * 100),
        to: Math.floor(bracket.to * 100),
      }),
    })),
  }))), [plan]);

  const initialValues = useMemo(() => ({
    rules: initialRules,
  }), [
    initialRules,
  ]);

  const [rules, setRules] = useState(initialValues.rules || []);
  const [openedCollapses, setOpenedCollapses] = useState(rules.map(() => false));

  useEffect(() => {
    setRules(initialValues.rules || []);
    if (inPlanCreationFlow && inPlanCreationFlowOnRulesUpdate !== null) {
      inPlanCreationFlowOnRulesUpdate(initialValues.rules);
    }
  }, [initialValues, inPlanCreationFlow, inPlanCreationFlowOnRulesUpdate]);

  const handleSetRules = useCallback((newRules) => {
    setRules(newRules);
    if (inPlanCreationFlow && inPlanCreationFlowOnRulesUpdate !== null) {
      inPlanCreationFlowOnRulesUpdate(newRules);
    }
  }, [setRules, inPlanCreationFlow, inPlanCreationFlowOnRulesUpdate]);

  const updatePlanRulesIsPending = useSelector(MasterPlansSelectors.updatePlanRulesIsPending);

  const [form] = AntDForm.useForm();

  const transformRules = (rulesToManage) => (
    rulesToManage.map((rule) => {
      const updatedRule = {
        ...rule,
        variables: rule.type === RULE_TYPES.CODE_ONLY ? [] : rule.variables,
        brackets: rule.type === RULE_TYPES.CODE_ONLY ? [] : rule.brackets.map((bracket) => {
          let to = rule.percentage ? bracket.to / 100 : bracket.to;

          if (to === Infinity) {
            to = 'Infinity';
          }

          const updatedBracket = {
            ...bracket,
            from: rule.percentage ? bracket.from / 100 : bracket.from,
            to,
          };

          if (updatedBracket._id === null) {
            delete updatedBracket._id;
          }

          return updatedBracket;
        }),
        explanation: rule.type === RULE_TYPES.CODE_ONLY ? rule.explanation : '',
      };

      if (updatedRule._id === null) {
        delete updatedRule._id;
      }

      return updatedRule;
    })
  );

  const isPristine = useMemo(() => {
    const finalInitialRules = transformRules(initialValues.rules);
    const finalRules = transformRules(rules);

    return _isEqual(finalInitialRules, finalRules);
  }, [initialValues, rules]);

  const handleFinish = useCallback(() => {
    const finalRules = transformRules(rules);

    let otherUpdates = {};
    if (inPlanCreationFlow) {
      otherUpdates = {
        disableSuccessNotification: inPlanCreationFlow,
        onSuccessCB: onSuccessSaveCommissionRulesCB,
      };
    }

    dispatch(MasterPlansActions.updatePlanRules({ planId: plan.id, rules: finalRules, ...otherUpdates }));
  }, [rules, inPlanCreationFlow, onSuccessSaveCommissionRulesCB]);

  const handleSaveRules = useCallback((successSaveCommissionRulesCB = null) => {
    if (successSaveCommissionRulesCB !== null) {
      setOnSuccessSaveCommissionRulesCB(() => successSaveCommissionRulesCB);
    }

    form.submit();
  }, [form]);

  useEffect(() => {
    if (inPlanCreationFlow && inPlanCreationFlowSetSaveCB !== null) {
      inPlanCreationFlowSetSaveCB(handleSaveRules);
    }
  }, [inPlanCreationFlow, inPlanCreationFlowSetSaveCB, handleSaveRules]);

  const handleRemoveRule = useCallback((index) => {
    const newRules = [...rules];
    const newOpenedCollapses = [...openedCollapses];

    newRules.splice(index, 1);
    newOpenedCollapses.splice(index, 1);

    setReconciliationIndex(reconciliationIndex + 1);
    handleSetRules(newRules);
    setOpenedCollapses(newOpenedCollapses);

    form.setFieldsValue({
      rules: newRules,
    });
  }, [
    handleSetRules,
    openedCollapses,
    rules,
  ]);

  const handleRuleChange = useCallback((updatedRule, index) => {
    const newRules = [...rules];

    newRules.splice(index, 1, updatedRule);

    handleSetRules(newRules);

    form.setFieldsValue({
      rules: newRules,
    });
  }, [handleSetRules, rules]);

  const handleAddingARule = useCallback(() => {
    const newRuleValue = PlanRuleModel.transform({});
    const newOpenedCollapses = [...openedCollapses];
    const newRuleAddedIndex = rules.length;

    handleSetRules([...rules, newRuleValue]);
    setOpenedCollapses([...newOpenedCollapses, true]);

    form.setFieldsValue({
      rules: [...rules, newRuleValue],
    });

    setTimeout(() => {
      // Trigger a change on the form to handle validator.
      newRuleValue.initFormula = '1';
      handleRuleChange({ ...newRuleValue }, newRuleAddedIndex);
      // Scroll to the new rule added.
      const newRule = document.getElementById(`rule-offset-${rules.length}`);
      newRule?.scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }, [
    handleSetRules,
    openedCollapses,
    rules,
  ]);

  const handleCollapseChange = (arr, index) => {
    const collapseName = arr[0];
    const newOpenedCollapses = [...openedCollapses];

    newOpenedCollapses.splice(index, 1, !!collapseName);

    setOpenedCollapses(newOpenedCollapses);
  };

  const rulesNode = useMemo(() => rules.map((rule, index) => (
    <FormItem
      className={styles.formItemRule}
      name={[rule, index]}
      key={`rule-${index}-${reconciliationIndex}`}
      rules={[
        () => ({
          validator(_, value) {
            if (commissionRulesValidationForm(value)) {
              return Promise.resolve();
            }

            return Promise.reject(new Error(t('masterPlanSettingsPayoutRules.form.validator.reject')));
          },
        }),
      ]}
    >
      <MasterPlanSettingsCommissionRulesFormRule
        rule={rule}
        ruleNumberPosition={index + 1}
        helperData={helperData}
        onChange={(updatedRule) => handleRuleChange(updatedRule, index)}
        onRemove={() => handleRemoveRule(index)}
        onCollapseChange={(arr) => handleCollapseChange(arr, index)}
        openedCollapses={openedCollapses}
        onSubmitEditionName={handleSaveRules}
        trackingObjectType={plan.trackingObject?.type}
      />
    </FormItem>
  )), [plan.trackingObject, rules, openedCollapses]);

  const disclaimerSaveBtnNode = useMemo(() => {
    if (inPlanCreationFlow) return null;

    return (
      <>
        <Disclaimer
          className={styles.disclaimer}
          icon="info"
          title={t('masterPlanSettingsCommissionAndPayoutRulesForm.form.disclaimerCount', { count: getNbOfPeriodsSinceLastFrozenPeriod(plan) })}
        />
        <Button
          className={styles.saveRulesBtn}
          onClick={handleSaveRules}
          disabled={isPristine || updatePlanRulesIsPending}
        >
          {t('masterPlanSettingsCommissionRulesForm.form.submitBtnLabel')}
        </Button>
      </>
    );
  }, [
    inPlanCreationFlow,
    plan,
    handleSaveRules,
    isPristine,
    updatePlanRulesIsPending,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Form className={styles.form} onFinish={handleFinish} initialValues={initialValues} form={form}>
        <div className={styles.content}>
          {rules.length === 0 && (
            <p className={styles.noRulesText}>
              {t('masterPlanSettingsCommissionRulesForm.noCommissionRules')}
            </p>
          )}
          {rules.length > 0 && rulesNode}
        </div>
        <Button
          className={styles.addRuleBtn}
          icon={(<AddFilled />)}
          onClick={handleAddingARule}
          type="secondary"
        >
          {t('masterPlanSettingsCommissionRulesForm.form.addNewRule')}
        </Button>
        {disclaimerSaveBtnNode}
      </Form>
    </div>
  );
};

MasterPlanSettingsCommissionRulesForm.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  inPlanCreationFlow: PropTypes.bool,
  inPlanCreationFlowSetSaveCB: PropTypes.func,
  inPlanCreationFlowOnRulesUpdate: PropTypes.func,
};

MasterPlanSettingsCommissionRulesForm.defaultProps = {
  className: '',
  inPlanCreationFlow: false,
  inPlanCreationFlowSetSaveCB: null,
  inPlanCreationFlowOnRulesUpdate: null,
};

export default MasterPlanSettingsCommissionRulesForm;
