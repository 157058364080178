import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import Button from '@palette/components/designSystem/Button/Button';
import CreatePlanFlowDealsResultsModal from '@palette/components/masterPlanCreation/CreatePlanFlowDealsResultsModal/CreatePlanFlowDealsResultsModal';
import RefreshLine from '@palette/components/utils/Icons/RefreshLine';

import { getPlanFirstPeriod } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CreatePlanFlowDealsResults.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowDealsResults = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planFirstPeriod = useMemo(() => (
    getPlanFirstPeriod(plan)
  ), [plan]);

  useEffect(() => {
    dispatch(MasterPlansActions.getPeriodDeals({
      planId: plan.id,
      year: planFirstPeriod.year,
      period: planFirstPeriod.period,
      page: 0,
      limit: 20,
    }));
  }, [planFirstPeriod]);

  const getPeriodDealsIsPending = useSelector(MasterPlansSelectors.getPeriodDealsIsPending);
  const periodDealsPagination = useSelector((state) => (
    MasterPlansSelectors.getMasterPlanPeriodDealsPagination(state, { masterPlanId: plan.id, year: planFirstPeriod.year, periodId: planFirstPeriod.period })),
  );

  const [dealsResultsModalIsVisible, showDealsResultsModal] = useState(false);

  const contentNode = useMemo(() => {
    if (getPeriodDealsIsPending) {
      return (
        <div className={styles.loadingWrapper}>
          <RefreshLine className={styles.loadingIcon} width={18} height={18} spin />
          <div className={styles.loadingLabel}>
            {t('loading')}
          </div>
        </div>
      );
    }

    if (periodDealsPagination !== null && periodDealsPagination.total > 0) {
      const dealType = periodDealsPagination.total > 1 ? pluralize(plan.trackingObject?.type) : plan.trackingObject?.type;

      return (
        <div className={styles.viewResultsWrapper}>
          <div className={styles.viewResultsLabelWrapper}>
            <div className={styles.viewResultsLabel}>
              {t('createPlanFlowDealsResults.viewResults.dealsFound', { total: periodDealsPagination.total, dealType })}
            </div>
            <div className={styles.viewResultsLabelIcon} aria-label="success" role="img">
              🎉
            </div>
          </div>
          <Button
            className={styles.viewResultsBtn}
            type="link"
            onClick={() => showDealsResultsModal(true)}
          >
            {t('createPlanFlowDealsResults.viewResultsBtn.label')}
          </Button>
        </div>
      );
    }

    return (
      <div className={styles.viewResultsWrapper}>
        <div className={styles.viewResultsLabelWrapper}>
          <div className={styles.viewResultsLabel}>
            {t('createPlanFlowDealsResults.viewResults.noDealsFound', { dealType: plan.trackingObject?.type, dealTypes: pluralize(plan.trackingObject?.type) })}
          </div>
        </div>
      </div>
    );
  }, [
    plan,
    getPeriodDealsIsPending,
    periodDealsPagination,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      {
        dealsResultsModalIsVisible && (
          <CreatePlanFlowDealsResultsModal visible onClose={() => showDealsResultsModal(false)} plan={plan} />
        )
      }
    </div>
  );
};

CreatePlanFlowDealsResults.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

CreatePlanFlowDealsResults.defaultProps = {
  className: '',
};

export default CreatePlanFlowDealsResults;
