import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import AddDataConnectionButton from '@palette/components/planV3/DataSources/AddDataConnectionButton/AddDataConnectionButton';
import DataConnectionList from '@palette/components/planV3/DataSourcesList/DataConnectionList/DataConnectionList';

import styles from './PlanV3FormRequirementsDataSources.less';

const classNames = bindClassNames.bind(styles);

const PlanV3FormRequirementsDataSources = ({ className, isPending }) => (
  <div
    className={classNames({
      wrapper: true,
      [className]: className !== '',
    })}
  >
    <DataConnectionList isPending={isPending} />
    <AddDataConnectionButton isPending={isPending} />
  </div>
);

PlanV3FormRequirementsDataSources.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
};

PlanV3FormRequirementsDataSources.defaultProps = {
  className: '',
  isPending: false,
};

export default PlanV3FormRequirementsDataSources;
