/* eslint-disable no-await-in-loop */
import React from 'react';
import Moment from 'moment';
import {
  Button, Divider, message, List,
} from 'antd';
import {
  PartitionOutlined, PlusOutlined, CaretRightOutlined,
} from '@ant-design/icons';

import { connect } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import routePaths from '@palette/config/routePaths';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import PageTopTitle from '../../../components/pageTopTitle';
import { dateFromMongoId, waitJob, JobError } from '../../../services/utils';
import FullScreenLoader from '../../../components/loader';

import '../../../styles/admin/connectors.less';
import { execReconciliation, listReconciliations } from '../../../services/admin/reconciliations';

class Reconciliations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reconciliations: [],
      exec: {},
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      listReconciliations()
        .then(({ data: reconciliations }) => this.setState({
          reconciliations,
          loading: false,
        }))
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
          message.error('Error while loading reconciliations');
        });
    });
  };

  exec = (reconciliation) => {
    if (this.state.exec[reconciliation._id]) return;
    this.setState({ exec: { ...this.state.exec, [reconciliation._id]: true } }, async () => {
      try {
        let { data: job } = await execReconciliation(reconciliation._id);
        job = await waitJob(job._id);
        message.success(`Successfully played ${reconciliation.name}`);
        this.setState({
          reconciliations: this.state.reconciliations.map((r) => (r._id === reconciliation._id ? {
            ...reconciliation,
            lastJob: job,
          } : r)),
        });
      } catch (error) {
        if (error instanceof JobError) {
          const e = JSON.parse(error.message);
          message.error(`Error while playing: ${e.message}`);
        } else {
          message.error('Unexpected error while playing.');
        }
      }
      this.setState({
        loading: false,
        exec: { ...this.state.exec, [reconciliation._id]: false },
      });
    });
  };

  render() {
    const { loading, reconciliations, exec } = this.state;
    return (
      <div className="div-wrapper MyPlans">
        <MetaTag title="Reconciliations" />
        <div className="container">
          <div className="container-inner">
            <PageTopTitle
              title={(
                <>
                  <PartitionOutlined />
                 &nbsp;
                    &nbsp;
                  Reconciliations
                </>
              )}
              actions={hasAtLeastOneRight(this.props.profile, ['admin.connectors.manage']) && (
                <a href={`/#${routePaths.reconciliationsAdd}`}>
                  <Button
                    icon={<PlusOutlined />}
                    type="link"
                  >
                    Create
                  </Button>
                </a>
              )}
            />
            <Divider />
            <List
              bordered
              dataSource={reconciliations}
              renderItem={(reconciliation) => (
                <List.Item
                  actions={[(
                    <Button
                      size="large"
                      type="primary"
                      loading={exec[reconciliation._id]}
                      icon={<CaretRightOutlined />}
                      onClick={() => this.exec(reconciliation)}
                    >
                      RUN
                    </Button>
                  )]}
                >
                  <List.Item.Meta
                    title={<a href={`#/reconciliations/${reconciliation._id}`} className="bold">{reconciliation.name}</a>}
                    description={reconciliation.lastJob ? (
                      <>
                        {`Last execution: ${Moment(dateFromMongoId(reconciliation.lastJob._id)).format('YYYY-MM-DD @ HH:mm')} (${reconciliation.lastJob.status})`}
                        <div className={`ReconciliationStatus ${reconciliation.lastJob.status}`} />
                      </>
                    ) : '-'}
                  />
                </List.Item>
              )}
            />
            {loading && <FullScreenLoader />}
          </div>
        </div>
      </div>
    );
  }
}

Reconciliations.propTypes = {
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(Reconciliations);
