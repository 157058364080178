import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const MetaTag = ({ isRouter, title }) => {
  const { t } = useTranslation();

  if (isRouter) {
    return (
      <Helmet
        titleTemplate={`%s | ${t('app.metas.defaultTitle')}`}
        defaultTitle={t('app.metas.defaultTitle')}
      />
    );
  }

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

MetaTag.propTypes = {
  isRouter: PropTypes.bool,
  title: PropTypes.string,
};

MetaTag.defaultProps = {
  isRouter: false,
  title: '',
};

export default MetaTag;
