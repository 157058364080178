import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PlanV3EditBlockCell from '@palette/components/planV3/PlanV3EditBlockCell/PlanV3EditBlockCell';

import { ACHIEVEMENT_DOCUMENTATION_URL, CELL_TYPE } from '@palette/constants/planV3';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3EditAchievements.less';

const classNames = bindClassNames.bind(styles);

const PlanV3EditAchievements = ({ className, details, onCodeCellChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { planId, cells } = details;

  const cell = useMemo(() => cells.find((planCell) => planCell.type === CELL_TYPE.ACHIEVEMENTS) ?? null, [cells]);

  const codeCellIsRunning = useSelector((state) => PlanV3Selectors.getRunCodeCellIsPending(state, { planId, codeCellId: cell?.id }));

  const goToDocumentation = () => window.open(ACHIEVEMENT_DOCUMENTATION_URL, '_blank');

  const [cellCode, setCellCode] = useState(cell?.code ?? '');

  useEffect(() => {
    setCellCode(cell?.code ?? '');
  }, [cell]);

  const handleCellCodeChange = useCallback((newContent) => {
    setCellCode(newContent);
    onCodeCellChange(newContent);
  }, [onCodeCellChange]);

  const handleRunCodeCell = useCallback(() => {
    if (cell === null) return;

    dispatch(PlanV3Actions.runCodeCell({ planId, codeCellId: cell.id, cellCode }));
  }, [planId, cell, cellCode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <PlanV3EditBlockCell
        title={t('editPlanPage.achievements.title')}
        docLabel={t('editPlanPage.achievements.docLabel')}
        description={t('editPlanPage.achievements.description')}
        alertTitle={t('editPlanPage.achievements.alert.title')}
        alertContent="{ dealId, userId, periodId, targetType, quota, value }"
        submitLabel={t('editPlanPage.achievements.runQuery')}
        handleDocClick={goToDocumentation}
        cellCode={cellCode}
        handleCellCodeChange={handleCellCodeChange}
        handleRunCodeCell={handleRunCodeCell}
        codeCellIsRunning={codeCellIsRunning}
        codeCellError={cell?.error}
        preview={cell?.preview ?? null}
        planId={planId}
        codeCellId={cell?.id ?? null}
      />
    </div>
  );
};

PlanV3EditAchievements.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
  onCodeCellChange: PropTypes.func.isRequired,
};

PlanV3EditAchievements.defaultProps = {
  className: '',
};

export default PlanV3EditAchievements;
