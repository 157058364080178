/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { original } from 'immer';
import _cloneDeep from 'lodash/cloneDeep';

/*
 * Initial State
 */
const initialState = {
  temporary: [],
  permanent: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'globalNotif',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Add global notif */
    addGlobalNotif: () => {
      // Nothing to do here
    },
    /* Add temporary global notif */
    addTemporaryGlobalNotif: (state, { payload }) => {
      const clonedTemporary = [...state.temporary];
      clonedTemporary.push(payload);
      state.temporary = clonedTemporary;
    },
    /* FadeOut temporary global notif from UI */
    fadeOutTemporaryGlobalNotifFromUI: (state) => {
      const clonedTemporary = [...state.temporary];
      const notifIndex = clonedTemporary.findIndex((notif) => !notif.fadeOut);
      clonedTemporary.splice(notifIndex, 1, { ...clonedTemporary[notifIndex], fadeOut: true });
      state.temporary = clonedTemporary;
    },
    /* Hide temporary global notif from UI */
    hideTemporaryGlobalNotifFromUI: (state) => {
      const clonedTemporary = [...state.temporary];
      const notifIndex = clonedTemporary.findIndex((notif) => !notif.hidden);
      clonedTemporary.splice(notifIndex, 1, { ...clonedTemporary[notifIndex], hidden: true });

      if (clonedTemporary.find((notif) => !notif.hidden)) {
        state.temporary = clonedTemporary;
      } else {
        // Clear state if no more temporary notif on going
        state.temporary = [];
      }
    },
    /* Add permanent global notif */
    addPermanentGlobalNotif: (state, { payload }) => {
      const { id } = payload;
      state.permanent[id] = payload;
    },
    /* FadeOut permanent global notif from UI */
    fadeOutPermanentGlobalNotifFromUI: (state, { payload }) => {
      const { id } = payload;
      const clonedPermanent = _cloneDeep(original(state.permanent));

      clonedPermanent[id] = { ...clonedPermanent[id], fadeOut: true };
      state.permanent = clonedPermanent;
    },
    /* Remove permanent global notif from state */
    removePermanentGlobalNotif: (state, { payload }) => {
      const clonedPermanent = _cloneDeep(original(state.permanent));
      delete clonedPermanent[payload];
      state.permanent = clonedPermanent;
    },
    /* Reset all permanent global notif */
    resetAllPermanentGlobalNotif: () => {
      // Nothing to do here
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getTemporary = (state) => root(state).temporary;
const getPermanent = (state) => root(state).permanent;

export const selectors = {
  getTemporary,
  getPermanent,
};
