import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';
import ChevronUpLine from '@palette/components/utils/Icons/ChevronUpLine';

import styles from './TableHeaderCell.less';

const classNames = bindClassNames.bind(styles);

const TableHeaderCell = ({ className, type, children, column, enableSortableColumns }) => {
  const handleSortByClick = useCallback(() => {
    if (column.disableSortBy || !enableSortableColumns) return;
    column.toggleSortBy(!column.isSortedDesc);
  }, [
    column.disableSortBy,
    column.isSortedDesc,
    enableSortableColumns,
  ]);

  const orderingIconNode = useMemo(() => {
    if (column.disableSortBy || !enableSortableColumns) return null;

    let orderingIcon = <ChevronDownLine className={styles.sortIcon} />;

    if (column.isSorted) {
      orderingIcon = <ChevronUpLine className={classNames({ sortIcon: true, sortIconActive: true })} />;

      if (column.isSortedDesc) {
        orderingIcon = <ChevronDownLine className={classNames({ sortIcon: true, sortIconActive: true })} />;
      }
    }

    return orderingIcon;
  }, [
    column.disableSortBy,
    column.isSorted,
    column.isSortedDesc,
    enableSortableColumns,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        borderless: type === 'borderless',
        [className]: className !== '',
      })}
      {...(enableSortableColumns && column.getHeaderProps(column.getSortByToggleProps()))}
      onClick={handleSortByClick}
    >
      {children}
      {orderingIconNode}
    </div>
  );
};

TableHeaderCell.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'borderless']),
  children: PropTypes.any.isRequired,
  column: PropTypes.object.isRequired,
  enableSortableColumns: PropTypes.bool.isRequired,
};

TableHeaderCell.defaultProps = {
  className: '',
  type: 'default',
};

export default TableHeaderCell;
