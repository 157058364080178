import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import * as UserStatementCorrectionSummaryModel from '@palette/models/UserStatementCorrectionSummary';

import styles from './UserStatementInvestigateListItem.less';

const classNames = bindClassNames.bind(styles);

const UserStatementInvestigateListItem = ({ className, summary, onClick, isSelected }) => {
  const {
    id,
    plan,
    deal,
    period,
    year,
    oldTotal,
    newTotal,
    currency,
  } = summary;

  const handleClick = useCallback(() => onClick(isSelected ? null : id), [id, isSelected]);

  return (
    <div
      className={classNames({
        wrapper: true,
        selected: isSelected,
        [className]: className !== '',
      })}
      onClick={handleClick}
    >
      <div className={styles.column}>{deal.name}</div>
      <div className={styles.column}>{plan.name}</div>
      <div className={styles.column}>{getPlanPeriodName(plan, { period, year }, true, false)}</div>
      <div className={styles.column}>{formatPrice(oldTotal, currency)}</div>
      <div className={styles.column}>{formatPrice(newTotal, currency)}</div>
    </div>
  );
};

UserStatementInvestigateListItem.propTypes = {
  className: PropTypes.string,
  summary: UserStatementCorrectionSummaryModel.propTypes.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

UserStatementInvestigateListItem.defaultProps = {
  className: '',
  isSelected: false,
};

export default UserStatementInvestigateListItem;
