import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import EditDataConnectionConnectorModal from '@palette/components/notebooks/EditDataConnectionConnectorModal/EditDataConnectionConnectorModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { NOTEBOOK_STATUSES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';
import * as NotebookDataConnectionConnectorModel from '@palette/models/NotebookDataConnectionConnector';

import styles from './EditDataConnectionConnectorButton.less';

const classNames = bindClassNames.bind(styles);

const EditDataConnectionConnectorButton = ({ className, notebook, dataConnectionConnector }) => {
  const { t } = useTranslation();

  const profile = useProfile();

  const [editDataConnectionConnectorModalIsVisible, showEditDataConnectionConnectorModal] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE]) || notebook.status === NOTEBOOK_STATUSES.ARCHIVED) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="link"
        onClick={() => showEditDataConnectionConnectorModal(true)}
      >
        {t('actions.edit')}
      </Button>
      {
        editDataConnectionConnectorModalIsVisible && (
          <EditDataConnectionConnectorModal
            visible
            onClose={() => showEditDataConnectionConnectorModal(false)}
            notebook={notebook}
            dataConnectionConnector={dataConnectionConnector}
          />
        )
      }
    </div>
  );
};

EditDataConnectionConnectorButton.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  dataConnectionConnector: NotebookDataConnectionConnectorModel.propTypes.isRequired,
};

EditDataConnectionConnectorButton.defaultProps = {
  className: '',
};

export default EditDataConnectionConnectorButton;
