import React from 'react';
import _filter from 'lodash/filter';

import { getI18n } from '@palette/i18n';

import {
  buildFrequencyOptionValue,
  getCustomPeriodTypeFrequencyName, getPeriodForMomentDate,
  getPeriodTypeFrequencyName,
} from '@palette/helpers/FrequencyHelper';
import { formatBeginEndDate, getMoment } from '@palette/helpers/MomentHelper';

import { QUOTA_TARGET_ORIGIN, AVAILABLE_MONTH_FREQUENCIES_OPTIONS } from '@palette/constants/quotas';
import { DAY_FREQUENCIES, PERIOD_TYPES, PERIODS } from '@palette/constants/frequencies';

export const getFrequencyName = (quota) => {
  if (quota.periodType === PERIOD_TYPES.CUSTOM) return getCustomPeriodTypeFrequencyName(quota.beginAt, quota.frequency);

  return getPeriodTypeFrequencyName(quota.periodType, quota.frequency);
};

export const getAvailableQuotaFrequenciesOptions = () => {
  const i18n = getI18n();

  const monthOptions = Object.keys(AVAILABLE_MONTH_FREQUENCIES_OPTIONS).map((monthFreqKey) => ({
    label: i18n.t(AVAILABLE_MONTH_FREQUENCIES_OPTIONS[monthFreqKey]),
    value: buildFrequencyOptionValue(PERIOD_TYPES.MONTH, monthFreqKey),
  }));

  const dayOptions = [
    {
      label: i18n.t(DAY_FREQUENCIES.DAY),
      value: PERIOD_TYPES.DAY,
    },
  ];

  return monthOptions.concat(dayOptions);
};

export const getQuotaTableColumnsForCustomPeriodType = (quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell, highlightPeriods = -1) => {
  const periodId = 'customPeriod';

  return [
    {
      id: periodId,
      period: 1,
      highlight: Array.isArray(highlightPeriods) ? highlightPeriods.includes(1) : highlightPeriods === 1,
      width: 250,
      minWidth: 250,
      Header: getCustomPeriodTypeFrequencyName(quota.beginAt, quota.frequency),
      accessor: periodId,
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value, ...otherCellprops }) => (
        <QuotaTableBodyCell
          quotaUserId={value.quotaUserId}
          period={value.period}
          quotaId={quota.id}
          year={year}
          locked={locked}
          {...otherCellprops}
        />
      ),
      /* eslint-enable react/prop-types */
    },
  ];
};

export const getQuotaTableColumnsForDayPeriodType = (quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell, highlightPeriods = -1) => {
  const moment = getMoment();
  const now = moment().utc();

  const columns = [];

  const firstDay = moment(now).utc()
    .startOf('month')
    .year(year)
    .startOf('year');
  const lastDay = moment(firstDay).utc().endOf('year');

  let beginPeriodMomentDate = moment(quota.beginAt).utc().startOf('day').add(12, 'hours');
  let periodNb = 0;

  while (beginPeriodMomentDate.isBefore(firstDay)) {
    beginPeriodMomentDate = moment(beginPeriodMomentDate).utc().add(quota.frequency, 'days');
    periodNb += 1;
  }

  while (beginPeriodMomentDate.isBefore(lastDay)) {
    const endPeriodMomentDate = moment(beginPeriodMomentDate).utc().add(quota.frequency - 1, 'days');
    const periodId = `${quota.periodType}-${quota.frequency}-${periodNb}`;
    columns.push({
      id: periodId,
      period: periodNb + 1,
      highlight: Array.isArray(highlightPeriods) ? highlightPeriods.includes(periodNb + 1) : highlightPeriods === (periodNb + 1),
      width: 115,
      minWidth: 115,
      Header: formatBeginEndDate(beginPeriodMomentDate.format(), endPeriodMomentDate.format(), true),
      accessor: periodId,
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value, ...otherCellprops }) => (
        <QuotaTableBodyCell
          quotaUserId={value.quotaUserId}
          period={value.period}
          quotaId={quota.id}
          year={year}
          locked={locked}
          {...otherCellprops}
        />
      ),
      /* eslint-enable react/prop-types */
    });

    beginPeriodMomentDate = moment(beginPeriodMomentDate).utc().add(quota.frequency, 'days');
    periodNb += 1;
  }

  columns.push({
    id: 'total',
    width: 140,
    minWidth: 140,
    Header: (<QuotaTableTotalTitleCell />),
    disableHeaderCellComponent: true,
    disableBodyCellComponent: true,
    accessor: 'quotaUserId',
    /* eslint-disable react/prop-types */
    Cell: ({ value, ...otherCellprops }) => (
      <QuotaTableUserTotalCell
        quotaUserId={value}
        quotaId={quota.id}
        year={year}
        {...otherCellprops}
      />
    ),
    /* eslint-enable react/prop-types */
  });

  return columns;
};

export const getQuotaTableColumnsForMonthPeriodType = (quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell, highlightPeriods = -1) => {
  const i18n = getI18n();
  const nbColumns = 12 / quota.frequency;
  const columns = [];

  for (let period = 0; period < nbColumns; period += 1) {
    const periodId = `${quota.periodType}-${quota.frequency}-${period}`;

    let periodHeader = i18n.t(PERIODS[`${quota.frequency}-${period}`]);

    if (nbColumns === 1) {
      periodHeader = `${periodHeader} ${year}`;
    }

    columns.push({
      id: periodId,
      period: period + 1,
      highlight: Array.isArray(highlightPeriods) ? highlightPeriods.includes(period + 1) : highlightPeriods === (period + 1),
      width: 115,
      minWidth: 115,
      Header: periodHeader,
      accessor: periodId,
      disableBodyCellComponent: true,
      /* eslint-disable react/prop-types */
      Cell: ({ value, ...otherCellprops }) => (
        <QuotaTableBodyCell
          quotaUserId={value.quotaUserId}
          period={value.period}
          quotaId={quota.id}
          year={year}
          locked={locked}
          {...otherCellprops}
        />
      ),
      /* eslint-enable react/prop-types */
    });
  }

  if (quota.frequency !== 12) {
    columns.push({
      id: 'total',
      width: 140,
      minWidth: 140,
      Header: (<QuotaTableTotalTitleCell />),
      disableHeaderCellComponent: true,
      disableBodyCellComponent: true,
      accessor: 'quotaUserId',
      /* eslint-disable react/prop-types */
      Cell: ({ value, ...otherCellprops }) => (
        <QuotaTableUserTotalCell
          quotaUserId={value}
          quotaId={quota.id}
          year={year}
          {...otherCellprops}
        />
      ),
      /* eslint-enable react/prop-types */
    });
  }

  return columns;
};

export const getQuotaTableColumns = (quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell, highlightPeriods = -1) => {
  if (quota.periodType === PERIOD_TYPES.CUSTOM) {
    return getQuotaTableColumnsForCustomPeriodType(quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell, highlightPeriods);
  }

  if (quota.periodType === PERIOD_TYPES.DAY) {
    return getQuotaTableColumnsForDayPeriodType(quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell, highlightPeriods);
  }

  // quota.periodType === PERIOD_TYPES.MONTH
  return getQuotaTableColumnsForMonthPeriodType(quota, QuotaTableBodyCell, year, locked, QuotaTableUserTotalCell, QuotaTableTotalTitleCell, highlightPeriods);
};

export const defaultValueFilter = (searchedYear, searchedPeriod) => (defaultValue) => (
  defaultValue.year < searchedYear
  || (
    defaultValue.year === searchedYear
    && defaultValue.period <= searchedPeriod
  )
);

export const quotaUserOutOfYearPeriod = (date, quota, year, period, checkEndOfPeriod = false) => {
  if (date !== null) {
    if (quota.periodType === PERIOD_TYPES.CUSTOM) {
      const moment = getMoment();
      const dateMoment = moment(date).utc();

      if (checkEndOfPeriod) {
        return dateMoment.isBefore(moment(quota.beginAt).utc(), 'day');
      }

      const endPeriodMomentDate = moment(quota.beginAt).utc()
        .add(12, 'hours')
        .add(quota.frequency - 1, 'days')
        .endOf('day');
      return dateMoment.isAfter(endPeriodMomentDate, 'day');
    }

    const datePeriod = getPeriodForMomentDate(date, quota.periodType, quota.frequency, quota.beginAt);

    if (checkEndOfPeriod) {
      return (
        year > datePeriod.year
        || (year === datePeriod.year && period > datePeriod.period)
      );
    }

    return (
      year < datePeriod.year
      || (year === datePeriod.year && period < datePeriod.period)
    );
  }

  return false;
};

export const getUserTarget = (quota, quotaUser, year, period, correspondingPlanUser = null) => {
  const { joinDate, leaveDate } = quotaUser.user;

  if (
    quotaUserOutOfYearPeriod(joinDate, quota, year, period)
    || quotaUserOutOfYearPeriod(leaveDate, quota, year, period, true)
    || (
      correspondingPlanUser !== null
      && (
        quotaUserOutOfYearPeriod(correspondingPlanUser.startDate, quota, year, period)
        || quotaUserOutOfYearPeriod(correspondingPlanUser.endDate, quota, year, period, true)
      )
    )
  ) {
    return {
      target: 0,
      origin: QUOTA_TARGET_ORIGIN.ONBOARDING_OFFBOARDING,
    };
  }

  const { defaultValues: userDefaultValues, overwrites: userOverwrites } = quotaUser;

  let origin = QUOTA_TARGET_ORIGIN.QUOTA_DEFAULT_VALUE;
  const matchedDefaultValues = _filter(quota.defaultValues, defaultValueFilter(year, period));
  let target = matchedDefaultValues[matchedDefaultValues.length - 1].value;

  const matchedUserDefaultValues = _filter(userDefaultValues, defaultValueFilter(year, period));
  if (matchedUserDefaultValues.length > 0) {
    target = matchedUserDefaultValues[matchedUserDefaultValues.length - 1].value;
    origin = QUOTA_TARGET_ORIGIN.USER_DEFAULT_VALUE;
  }

  const matchedUserOverwrites = _filter(userOverwrites, { year, period });
  if (matchedUserOverwrites.length > 0) {
    target = matchedUserOverwrites[0].value;
    origin = QUOTA_TARGET_ORIGIN.USER_OVERWRITE;
  }

  return {
    target,
    origin,
  };
};

export const getUserTotalTargetForCustomPeriodType = (quota, quotaUser, year) => (
  getUserTarget(quota, quotaUser, year, 1).target
);

export const getUserTotalTargetForDayPeriodType = (quota, quotaUser, year, correspondingPlanUser = null) => {
  const moment = getMoment();
  const now = moment().utc();

  const firstDay = moment(now).utc()
    .startOf('month')
    .year(year)
    .startOf('year');
  const lastDay = moment(firstDay).utc().endOf('year');

  let beginPeriodMomentDate = moment(quota.beginAt).utc().startOf('day').add(12, 'hours');
  let periodNb = 0;

  while (beginPeriodMomentDate.isBefore(firstDay)) {
    beginPeriodMomentDate = moment(beginPeriodMomentDate).utc().add(quota.frequency, 'days');
    periodNb += 1;
  }

  let total = 0;

  while (beginPeriodMomentDate.isBefore(lastDay)) {
    total += getUserTarget(quota, quotaUser, year, periodNb + 1, correspondingPlanUser).target;

    beginPeriodMomentDate = moment(beginPeriodMomentDate).utc().add(quota.frequency, 'days');
    periodNb += 1;
  }

  return total;
};

export const getUserTotalTargetForMonthPeriodType = (quota, quotaUser, year, correspondingPlanUser = null) => {
  const nbColumns = 12 / quota.frequency;
  let total = 0;
  for (let period = 0; period < nbColumns; period += 1) {
    total += getUserTarget(quota, quotaUser, year, period + 1, correspondingPlanUser).target;
  }

  return total;
};

export const getUserTotalTarget = (quota, quotaUser, year, correspondingPlanUser = null) => {
  if (quota.periodType === PERIOD_TYPES.CUSTOM) {
    return getUserTotalTargetForCustomPeriodType(quota, quotaUser, year);
  }

  if (quota.periodType === PERIOD_TYPES.DAY) {
    return getUserTotalTargetForDayPeriodType(quota, quotaUser, year, correspondingPlanUser);
  }

  // quota.periodType === PERIOD_TYPES.MONTH
  return getUserTotalTargetForMonthPeriodType(quota, quotaUser, year, correspondingPlanUser);
};

export const getQuotaTableUserRowForCustomPeriodType = (quota, quotaUserId) => ({
  customPeriod: {
    quotaUserId,
    period: 1,
  },
});

export const getQuotaTableUserRowForDayPeriodType = (quota, quotaUserId, year) => {
  const moment = getMoment();
  const now = moment().utc();

  const firstDay = moment(now).utc()
    .startOf('month')
    .year(year)
    .startOf('year');
  const lastDay = moment(firstDay).utc().endOf('year');

  let beginPeriodMomentDate = moment(quota.beginAt).utc().startOf('day').add(12, 'hours');
  let periodNb = 0;

  while (beginPeriodMomentDate.isBefore(firstDay)) {
    beginPeriodMomentDate = moment(beginPeriodMomentDate).utc().add(quota.frequency, 'days');
    periodNb += 1;
  }

  const columns = {};

  while (beginPeriodMomentDate.isBefore(lastDay)) {
    const periodId = `${quota.periodType}-${quota.frequency}-${periodNb}`;
    columns[periodId] = {
      quotaUserId,
      period: periodNb + 1,
    };

    beginPeriodMomentDate = moment(beginPeriodMomentDate).utc().add(quota.frequency, 'days');
    periodNb += 1;
  }

  return columns;
};

export const getQuotaTableUserRowForMonthPeriodType = (quota, quotaUserId) => {
  const nbColumns = 12 / quota.frequency;
  const columns = {};
  for (let period = 0; period < nbColumns; period += 1) {
    const periodId = `${quota.periodType}-${quota.frequency}-${period}`;
    columns[periodId] = {
      quotaUserId,
      period: period + 1,
    };
  }

  return columns;
};

export const getQuotaTableUserRow = (quota, quotaUserId, year) => {
  if (quota.periodType === PERIOD_TYPES.CUSTOM) {
    return getQuotaTableUserRowForCustomPeriodType(quota, quotaUserId);
  }

  if (quota.periodType === PERIOD_TYPES.DAY) {
    return getQuotaTableUserRowForDayPeriodType(quota, quotaUserId, year);
  }

  // quota.periodType === PERIOD_TYPES.MONTH
  return getQuotaTableUserRowForMonthPeriodType(quota, quotaUserId);
};

export const getFilteredUsersList = (quotaUsers, year, searchedUser = '', selectedTeam = null) => {
  let usersList = quotaUsers.filter((quotaUser) => {
    if (quotaUser.lastPeriod !== null) {
      return quotaUser.lastPeriod.year >= year;
    }

    return true;
  });

  if (searchedUser !== '') {
    usersList = usersList.filter((quotaUser) => quotaUser.user.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1);
  }

  if (selectedTeam !== null) {
    const membersIds = selectedTeam.members.map((teamUser) => teamUser.user.id);
    usersList = usersList.filter((quotaUser) => membersIds.includes(quotaUser.user.id));
  }

  return usersList;
};

export const getQuotaData = (quota, filteredUsers, year) => filteredUsers.map((user) => ({
  quotaUserId: user.id,
  ...getQuotaTableUserRow(quota, user.id, year),
}));

export const isInitialDefaultValues = (defaultValue) => (defaultValue.year === 1970 && defaultValue.period === 1);
