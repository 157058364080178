import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { NOTEBOOK_DATA_CONNECTION_TYPES } from '@palette/constants/notebooks';
import { useNextAddDataConnectionStep } from '@palette/hooks/NotebookHooks';
import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import { getMoment } from '@palette/helpers/MomentHelper';

import { CONNECTOR_DETAILS, CONNECTOR_TYPES } from '@palette/constants/connector';

import { actions as NotebooksActions } from '@palette/state/Notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './AddDataConnectionDataSourceConnectors.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionDataSourceConnectors = ({ className, notebook }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const connectorsList = useConnectorsList();

  const filteredConnectorsList = useMemo(() => (
    connectorsList.filter((connector) => (connector.type !== CONNECTOR_TYPES.NOTEBOOK && connector.resources.length > 0))
  ), [connectorsList]);

  const nextAddDataConnectionStep = useNextAddDataConnectionStep();

  const handleConnectorClicked = useCallback((connectorId) => {
    dispatch(NotebooksActions.setNewDataConnectionSource({ type: NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR, params: { connectorId } }));
    dispatch(NotebooksActions.setAddDataConnectionCurrentFlowStep({ addDataConnectionCurrentFlowStep: nextAddDataConnectionStep }));
  }, [nextAddDataConnectionStep]);

  const notebookDataConnectionsConnectors = useMemo(() => (
    notebook.dataConnections
      .filter((dataConnection) => (dataConnection.type === NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR))
      .map((dataConnection) => (dataConnection[dataConnection.type]))
  ), [notebook]);

  const connectorItemsNode = useMemo(() => (
    filteredConnectorsList.map((connector) => {
      const connectorDetails = CONNECTOR_DETAILS[connector.type] ?? CONNECTOR_DETAILS.UNKNOWN;

      let lastSyncNode = null;

      if (connector.lastSync !== null) {
        lastSyncNode = (
          <div className={styles.connectorLastSync}>
            {t('addDataConnectionDataSourceConnectors.connectorLastSync', { date: moment(connector.lastSync).format('ll - HH:mm') })}
          </div>
        );
      }

      const currentConnectorDataConnectionsResourceTypes = notebookDataConnectionsConnectors
        .filter((dataConnectionConnector) => (
          dataConnectionConnector.connectorId === connector.id
        )).map((dataConnectionConnector) => (
          dataConnectionConnector.originalType
        ));

      const disabledConnector = currentConnectorDataConnectionsResourceTypes.length === connector.resources.length;

      let handleConnectorClick;

      if (!disabledConnector) {
        handleConnectorClick = () => handleConnectorClicked(connector.id);
      }

      const usedResourcesNode = (
        <div className={styles.usedResources}>
          {t('addDataConnectionDataSourceConnectors.usedResourcesNode', { total: connector.resources.length, used: currentConnectorDataConnectionsResourceTypes.length })}
        </div>
      );

      const contentNode = (
        <div
          key={connector.id}
          className={classNames({
            connectorItem: true,
            disabledConnector,
          })}
          onClick={handleConnectorClick}
        >
          <div className={styles.connectorIcon}>
            {connectorDetails.iconComponent({ width: '40px', height: '40px' })}
          </div>
          <div className={styles.connectorDetails}>
            <div className={styles.connectorName}>
              {connector.name}
            </div>
            {lastSyncNode}
            {usedResourcesNode}
          </div>
        </div>
      );

      if (disabledConnector) {
        return (
          <Tooltip title={t('notebooks.addDataConnectionConnector.disabled')} key={`${connector.id}_tooltip`}>
            {contentNode}
          </Tooltip>
        );
      }

      return contentNode;
    })
  ), [
    filteredConnectorsList,
    handleConnectorClicked,
    notebookDataConnectionsConnectors,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {connectorItemsNode}
    </div>
  );
};

AddDataConnectionDataSourceConnectors.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
};

AddDataConnectionDataSourceConnectors.defaultProps = {
  className: '',
};

export default AddDataConnectionDataSourceConnectors;
