import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import ApplyDashboardPresetModal from '@palette/components/dashboard/ApplyDashboardPresetModal/ApplyDashboardPresetModal';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

const ApplyDashboardPresetButton = ({ className, dashboardPreset }) => {
  const { t } = useTranslation();
  const [applyDashboardPresetModalIsVisible, showApplyDashboardPresetModal] = useState(false);

  return (
    <>
      <Button
        className={className}
        type="secondary"
        onClick={() => showApplyDashboardPresetModal(true)}
      >
        {t('dashboardPreset.applyDashboardPresetToUsers')}
      </Button>
      {
        applyDashboardPresetModalIsVisible && (
          <ApplyDashboardPresetModal visible onClose={() => showApplyDashboardPresetModal(false)} dashboardPreset={dashboardPreset} />
        )
      }
    </>
  );
};

ApplyDashboardPresetButton.propTypes = {
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
  className: PropTypes.string,
};

ApplyDashboardPresetButton.defaultProps = {
  className: '',
};

export default ApplyDashboardPresetButton;
