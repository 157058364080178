import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import FreezeFilled from '@palette/components/utils/Icons/FreezeFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { isInPast, periodIsFrozen } from '@palette/helpers/MasterPlanHelper';
import { getPreviousPeriod } from '@palette/helpers/FrequencyHelper';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './MasterPlanListItemFreezeButton.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanListItemFreezeButton = ({ className, onConfirm, plan, lastPeriod }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const previousPeriod = useMemo(() => {
    if (isInPast(plan)) return lastPeriod;
    return getPreviousPeriod(plan.periodType, plan.frequency, lastPeriod.year, lastPeriod.period);
  }, [plan, lastPeriod]);

  const handleFreeze = useCallback(() => {
    if (onConfirm) onConfirm('freeze');

    dispatch(MasterPlansActions.updatePlan({
      planId: plan.id,
      lastFrozenPeriod: {
        year: previousPeriod.year,
        period: previousPeriod.period,
      },
      updateLastPeriod: false,
    }));
  }, [plan, onConfirm, previousPeriod]);

  const handleClickButton = () => setTooltipVisible(false);

  const freezeBtnNode = useMemo(() => {
    if (periodIsFrozen(plan, previousPeriod)) {
      return (
        <Tooltip
          title={t('masterPlanListItemFreezeButton.allPeriodsFrozen.tooltip')}
          visible={tooltipVisible}
          onVisibleChange={(visible) => setTooltipVisible(visible)}
        >
          <FreezeFilled className={styles.freezeBtnActiveIcon} />
        </Tooltip>
      );
    }

    return (
      <Popconfirm
        title={t('masterPlanListItemFreezeButton.freezeAllPeriods.popconfirm')}
        onConfirm={handleFreeze}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Tooltip
          title={t('masterPlanListItemFreezeButton.freezeAllPeriods.tooltip')}
          visible={tooltipVisible}
          onVisibleChange={(visible) => setTooltipVisible(visible)}
        >
          <Button
            className={styles.freezeBtn}
            type="linkSecondary"
            icon={<FreezeFilled />}
            onClick={handleClickButton}
          />
        </Tooltip>
      </Popconfirm>
    );
  }, [plan, previousPeriod, tooltipVisible]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {freezeBtnNode}
    </div>
  );
};

MasterPlanListItemFreezeButton.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  lastPeriod: MasterPlanPeriodModel.propTypes.isRequired,
  onConfirm: PropTypes.func,
};

MasterPlanListItemFreezeButton.defaultProps = {
  className: '',
  onConfirm: undefined,
};

export default MasterPlanListItemFreezeButton;
