import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import Alert from '@palette/components/designSystem/Alert/Alert';
import AddPlansToDashboard from '@palette/components/quotaPerformance/AddPlansToDashboard/AddPlansToDashboard';
import QuotaPerformanceDashboardPlan from '@palette/components/quotaPerformance/QuotaPerformanceDashboardPlan/QuotaPerformanceDashboardPlan';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { ALERT_TYPES } from '@palette/constants/alert';
import { RIGHTS } from '@palette/constants/profile';

import * as QuotaPerformanceModel from '@palette/models/QuotaPerformance';

import { selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';

import styles from './QuotaPerformanceDetailContent.less';

const classNames = bindClassNames.bind(styles);

const QuotaPerformanceDetailContent = ({ className, dashboard }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const byIdIsPending = useSelector(QuotasPerformanceSelectors.getByIdIsPending);

  const contentNode = useMemo(() => {
    if (byIdIsPending) {
      return <Loader />;
    }

    if (!dashboard.plans || dashboard.plans.length === 0) {
      let noDataAlertDescription = null;

      if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) {
        noDataAlertDescription = (
          <div className={styles.noDataDescription}>
            <div className={styles.noDataDescriptionContent}>
              {t('quotasPerformance.detailsContent.noData.description')}
            </div>
            <AddPlansToDashboard dashboard={dashboard} />
          </div>
        );
      }

      return (
        <Alert
          className={styles.noDataAlert}
          type={ALERT_TYPES.INFO}
          message={t('quotasPerformance.detailsContent.noData.message')}
          description={noDataAlertDescription}
        />
      );
    }

    return dashboard.plans.map((plan) => (
      <QuotaPerformanceDashboardPlan
        className={styles.dashboardPlanContainer}
        key={`plan-${plan.id}`}
        dashboardId={dashboard.id}
        plan={plan}
      />
    ));
  }, [dashboard, profile, byIdIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

QuotaPerformanceDetailContent.propTypes = {
  className: PropTypes.string,
  dashboard: QuotaPerformanceModel.propTypes.isRequired,
};

QuotaPerformanceDetailContent.defaultProps = {
  className: '',
};

export default QuotaPerformanceDetailContent;
