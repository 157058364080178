import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import PlanV3PeriodsInputTableList from '@palette/components/planV3/PlanV3PeriodsInputTableList/PlanV3PeriodsInputTableList';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import styles from './PlanV3PeriodsAdvancedTables.less';

const classNames = bindClassNames.bind(styles);

const PlanV3PeriodsAdvancedTables = ({ className, details }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => (
    <div className={styles.content}>
      <PlanV3PeriodsInputTableList details={details} />
    </div>
  ), [details]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapse}
        panels={[{
          title: t('editPlanPage.results.periods.inputTables.title'),
          content: contentNode,
        }]}
        bordered={false}
        ghost
      />
    </div>
  );
};

PlanV3PeriodsAdvancedTables.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
};

PlanV3PeriodsAdvancedTables.defaultProps = {
  className: '',
};

export default PlanV3PeriodsAdvancedTables;
