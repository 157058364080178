import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import StatementPeriodStatus from '@palette/components/statement/statementPeriod/StatementPeriodStatus/StatementPeriodStatus';

import { formatPeriodNameDates, getPeriodName } from '@palette/helpers/StatementHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import { selectors as StatementsSelectors } from '@palette/state/Statements';

import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './StatementPeriodCardItem.less';

const classNames = bindClassNames.bind(styles);

const StatementPeriodCardItem = ({ className, statementPeriod, enableStatus, isSelected, inUserStatement }) => {
  const [statementPeriodInState, setStatementPeriodInState] = useState(statementPeriod);

  const getUserStatementByIdIsPending = useSelector((state) => StatementsSelectors.getUserStatementByIdIsPending(state));

  useEffect(() => {
    if (!getUserStatementByIdIsPending) {
      setStatementPeriodInState(statementPeriod);
    }
  }, [statementPeriod, getUserStatementByIdIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        isSelected,
        [className]: className !== '',
      })}
    >
      <Tooltip title={formatPeriodNameDates(statementPeriodInState.beginDate, statementPeriodInState.endDate)}>
        <div className={styles.date}>
          {getPeriodName(statementPeriodInState.statementStrategy, statementPeriodInState.period, statementPeriodInState.year)}
        </div>
      </Tooltip>
      <div className={styles.amount}>
        {formatPrice(statementPeriodInState.totalAmount, statementPeriodInState.currency)}
      </div>
      {enableStatus && (
        <StatementPeriodStatus
          className={styles.status}
          statementPeriod={statementPeriodInState}
          inUserStatement={inUserStatement}
        />
      )}
    </div>
  );
};

StatementPeriodCardItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  enableStatus: PropTypes.bool,
  isSelected: PropTypes.bool,
  inUserStatement: PropTypes.bool,
};

StatementPeriodCardItem.defaultProps = {
  className: '',
  enableStatus: true,
  isSelected: false,
  inUserStatement: false,
};

export default StatementPeriodCardItem;
