import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  USER_VARIABLE_CREATE_SUCCESS: {
    code: 'notifications.usersVariables.success.create',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_VARIABLE_EDIT_SUCCESS: {
    code: 'notifications.usersVariables.success.edit',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  USER_VARIABLE_DELETION_SUCCESS: {
    code: 'notifications.usersVariables.success.deleteOverride',
    theme: GLOBAL_NOTIF_THEMES.SUCCESS,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
