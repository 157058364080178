import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import pluralize from 'pluralize';
import { useTranslation } from 'react-i18next';
import { getMoment } from '@palette/helpers/MomentHelper';

import CommonWidgetBase from '@palette/components/widgets/dashboard/CommonWidgetBase/CommonWidgetBase';
import Table from '@palette/components/designSystem/Table/Table';
import DealDescription from '@palette/components/resources/DealDescription/DealDescription';
import PlansWithPeriods from '@palette/components/masterPlan/PlansWithPeriods/PlansWithPeriods';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { formatPrice } from '@palette/helpers/CurrencyHelper';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as LatestObjectsDataModel from '@palette/models/widgets/dashboard/LatestObjectsData';

import styles from './LatestObjectsWidget.less';

const classNames = bindClassNames.bind(styles);

const LatestObjectsWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const latestDeals = useMemo(() => (LatestObjectsDataModel.transform(widgetData.data)), [widgetData]);

  const types = useMemo(() => {
    if (latestDeals.types.length > 0) {
      return latestDeals.types.map((type) => (pluralize(type).toLowerCase())).join(' ');
    }

    return t('common.latestObjectsWidget.items');
  }, [latestDeals]);

  const columns = useMemo(() => {
    const cols = [];

    const dealNameColumn = {
      id: 'dealName',
      Header: '',
      accessor: (deal) => (deal),
      width: '25%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<DealDescription deal={value} displayType={latestDeals.types.length > 1} readOnly={readOnly} enableLinks={enableLinks} />),
    };
    cols.push(dealNameColumn);

    const dateColumn = {
      id: 'date',
      Header: t('common.latestObjectsWidget.table.headers.date'),
      accessor: (deal) => moment(deal.date).format('ll'),
      width: '15%',
    };
    cols.push(dateColumn);

    const plansColumn = {
      id: 'plans',
      Header: t('common.latestObjectsWidget.table.headers.plans'),
      accessor: (deal) => (deal),
      width: '45%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => (<PlansWithPeriods deal={value} readOnly={readOnly} enableLinks={enableLinks} />),
    };
    cols.push(plansColumn);

    const commissionsColumn = {
      id: 'commissions',
      Header: t('common.latestObjectsWidget.table.headers.commissions'),
      accessor: (deal) => formatPrice(deal.totalCommissionAmount, deal.currency),
      width: '15%',
    };
    cols.push(commissionsColumn);

    return cols;
  }, [latestDeals, readOnly]);

  const deals = useMemo(() => (latestDeals.deals), [latestDeals]);

  const tableNode = useMemo(() => {
    if (deals.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <Table
        type="borderless"
        columns={columns}
        data={deals}
        stretch
      />
    );
  }, [deals, columns]);

  return (
    <CommonWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      title={t('common.latestObjectsWidget.title', { types })}
      readOnly={readOnly}
    >
      <div className={styles.content}>
        {tableNode}
      </div>
    </CommonWidgetBase>
  );
};

LatestObjectsWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

LatestObjectsWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default LatestObjectsWidget;
