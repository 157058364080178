import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { Trans } from 'react-i18next';

import { getMoment } from '@palette/helpers/MomentHelper';

import * as PlanV3InvalidEndDateErrorStrategyModel from '@palette/models/globalNotifStrategies/planV3/PlanV3InvalidEndDateErrorStrategy';

const classNames = bindClassNames.bind();

const PlanV3InvalidEndDateErrorContentStrategy = ({ className, content }) => {
  const moment = getMoment();
  const { startDate, endDate } = content;

  return (
    <span
      className={classNames({
        [className]: className !== '',
      })}
    >
      <Trans
        i18nKey="notifications.plan.V3.error.invalid.endDate.content"
        values={{
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        }}
      />
    </span>
  );
};

PlanV3InvalidEndDateErrorContentStrategy.propTypes = {
  className: PropTypes.string,
  content: PlanV3InvalidEndDateErrorStrategyModel.propTypes.isRequired,
};

PlanV3InvalidEndDateErrorContentStrategy.defaultProps = {
  className: '',
};

export default PlanV3InvalidEndDateErrorContentStrategy;
