import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import Link from '@palette/components/designSystem/Link/Link';
import UserStatementPlanPeriod from '@palette/components/statement/userStatement/UserStatementPlanPeriod/UserStatementPlanPeriod';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import routePaths from '@palette/config/routePaths';

import * as UserStatementPlanModel from '@palette/models/UserStatementPlan';

import styles from './UserStatementPlanCollapse.less';

const classNames = bindClassNames.bind(styles);

const UserStatementPlanCollapse = ({ className, userStatementPlan, searchedDeal }) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const titleNode = useMemo(() => (
    <div className={styles.title}>
      {userStatementPlan.plan.name}
    </div>
  ), [userStatementPlan]);

  const contentNode = useMemo(() => {
    const periodsNodes = userStatementPlan.periods.map((userStatementPlanPeriod) => (
      <UserStatementPlanPeriod
        key={`${userStatementPlanPeriod.year}_${userStatementPlanPeriod.period}`}
        className={styles.period}
        plan={userStatementPlan.plan}
        userStatementPlanPeriod={userStatementPlanPeriod}
        searchedDeal={searchedDeal}
      />
    ));

    return (
      <div className={styles.periodsListWrapper}>
        {periodsNodes}
      </div>
    );
  }, [userStatementPlan, searchedDeal]);

  const planLinkNode = useMemo(() => {
    if (userStatementPlan.plan.periodType === PERIOD_TYPES.CUSTOM) {
      return (
        <Link
          path={routePaths.v2.planPeriodDetails}
          params={{ masterPlanId: userStatementPlan.plan.id, year: moment(userStatementPlan.plan.customBeginAt).utc().year(), periodId: 1 }}
          displayIcon
        >
          {t('userStatementPlanCollapse.seePlanDetails')}
        </Link>
      );
    }

    return (
      <Link
        path={routePaths.v2.planDetails}
        params={{ masterPlanId: userStatementPlan.plan.id }}
        displayIcon
        onClick={(event) => event.stopPropagation()}
      >
        {t('userStatementPlanCollapse.seePlanDetails')}
      </Link>
    );
  }, [userStatementPlan]);

  const extraNode = useMemo(() => (
    <div className={styles.extraDetails}>
      {planLinkNode}
      <div className={styles.totalWrapper}>
        <div className={styles.totalLabel}>
          {t('userStatementPlanCollapse.total')}
        </div>
        <div className={styles.totalAmount}>
          {formatPrice(userStatementPlan.totalAmount, userStatementPlan.currency)}
        </div>
      </div>
    </div>
  ), [userStatementPlan, planLinkNode]);

  return (
    <Collapse
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      panels={[{
        title: titleNode,
        content: contentNode,
        extra: extraNode,
      }]}
    />
  );
};

UserStatementPlanCollapse.propTypes = {
  className: PropTypes.string,
  userStatementPlan: UserStatementPlanModel.propTypes.isRequired,
  searchedDeal: PropTypes.string,
};

UserStatementPlanCollapse.defaultProps = {
  className: '',
  searchedDeal: '',
};

export default UserStatementPlanCollapse;
