import { all, fork } from 'redux-saga/effects';

import { entities } from '@palette/state/entities';
import { loop as exceptionsLoop } from '@palette/state/Exceptions/sagas';

const entitiesLoops = entities.map((entity) => entity.loop());
entitiesLoops.push(exceptionsLoop());

export function* loop() {
  yield all(entitiesLoops);
}

export function* rootSaga() {
  yield fork(loop);
}

export default rootSaga;
