import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import OnboardUsersFlowStepWrapper from '@palette/components/userOnboarding/OnboardUsersFlowStepWrapper/OnboardUsersFlowStepWrapper';
import OnboardUsersManualInputFlowStep from '@palette/components/userOnboarding/OnboardUsersManualInputFlowStep/OnboardUsersManualInputFlowStep';
import OnboardUsersCheckDataFlowStep from '@palette/components/userOnboarding/OnboardUsersCheckDataFlowStep/OnboardUsersCheckDataFlowStep';
import OnboardUsersSelectResourcesFlowStep from '@palette/components/userOnboarding/OnboardUsersSelectResourcesFlowStep/OnboardUsersSelectResourcesFlowStep';

import { useOnboardUsersStepValue } from '@palette/hooks/UserHooks';

import { ONBOARDING_USERS_FLOW_STEPS } from '@palette/constants/user';

const OnboardUsersFlowPageStepsManagement = () => {
  const { t } = useTranslation();

  const onboardUsersFlowStepsNodes = useMemo(() => ({
    [ONBOARDING_USERS_FLOW_STEPS.MANUAL_INPUT.value]: () => (
      <OnboardUsersFlowStepWrapper
        title={t(ONBOARDING_USERS_FLOW_STEPS.MANUAL_INPUT.label)}
        subTitle={t(ONBOARDING_USERS_FLOW_STEPS.MANUAL_INPUT.subTitle)}
      >
        <OnboardUsersManualInputFlowStep />
      </OnboardUsersFlowStepWrapper>
    ),
    [ONBOARDING_USERS_FLOW_STEPS.SELECT_RESOURCES.value]: () => (
      <OnboardUsersFlowStepWrapper
        title={t(ONBOARDING_USERS_FLOW_STEPS.SELECT_RESOURCES.label)}
        subTitle={t(ONBOARDING_USERS_FLOW_STEPS.SELECT_RESOURCES.subTitle)}
      >
        <OnboardUsersSelectResourcesFlowStep />
      </OnboardUsersFlowStepWrapper>
    ),
    [ONBOARDING_USERS_FLOW_STEPS.CHECK_DATA.value]: () => (
      <OnboardUsersFlowStepWrapper
        title={t(ONBOARDING_USERS_FLOW_STEPS.CHECK_DATA.label)}
        subTitle={t(ONBOARDING_USERS_FLOW_STEPS.CHECK_DATA.subTitle)}
      >
        <OnboardUsersCheckDataFlowStep />
      </OnboardUsersFlowStepWrapper>
    ),
  }), []);

  const onboardUsersStepValue = useOnboardUsersStepValue();

  const stepNode = useMemo(() => {
    switch (onboardUsersStepValue) {
      case ONBOARDING_USERS_FLOW_STEPS.MANUAL_INPUT.value: {
        return onboardUsersFlowStepsNodes[ONBOARDING_USERS_FLOW_STEPS.MANUAL_INPUT.value]();
      }
      case ONBOARDING_USERS_FLOW_STEPS.SELECT_RESOURCES.value: {
        return onboardUsersFlowStepsNodes[ONBOARDING_USERS_FLOW_STEPS.SELECT_RESOURCES.value]();
      }
      case ONBOARDING_USERS_FLOW_STEPS.CHECK_DATA.value: {
        return onboardUsersFlowStepsNodes[ONBOARDING_USERS_FLOW_STEPS.CHECK_DATA.value]();
      }
      default:
        return null;
    }
  }, [onboardUsersStepValue, onboardUsersFlowStepsNodes]);

  return stepNode;
};

export default OnboardUsersFlowPageStepsManagement;
