import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Formula from '@palette/components/formula/Formula/Formula';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getCommissionAmount } from '@palette/helpers/CommissionHelper';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionPerTargetRuleDetails.less';

const classNames = bindClassNames.bind(styles);

const CommissionPerTargetRuleDetails = ({ className, commission }) => {
  const { t } = useTranslation();

  const rule = useMemo(() => commission.scopeParams?.rule, [commission]);

  const descriptionNode = useMemo(() => {
    let lastBracketIndex = null;
    if (commission.brackets.length > 1) {
      lastBracketIndex = commission.scopeParams?.rule?.brackets
        .findIndex((bracket) => bracket.id === commission.brackets[commission.brackets.length - 1].id);
    }

    /* eslint-disable react/no-danger */
    let detailsNode = (
      <span
        dangerouslySetInnerHTML={{
          __html: t('commissionRuleDetails.targetAchievement', {
            type: commission.scopeParams?.plan?.trackingObject?.type,
            percentage: Math.round(commission.progress * 100),
          }),
        }}
      />
    );

    if (lastBracketIndex !== null) {
      detailsNode = (
        <>
          {detailsNode}
          &nbsp;
          <span dangerouslySetInnerHTML={{ __html: t('commissionRuleDetails.unlockTier', { index: lastBracketIndex }) }} />
        </>
      );
    }
    detailsNode = (
      <>
        {detailsNode}
        .
      </>
    );
    /* eslint-enable react/no-danger */

    let formulaNode = null;
    let resultNode = null;
    if (lastBracketIndex !== null) {
      const firstBracketIndex = commission.scopeParams?.rule?.brackets
        .findIndex((bracket) => bracket.id === commission.brackets[0].id);
      const firstBracketDefinition = commission.scopeParams?.rule?.brackets[firstBracketIndex];
      const lastBracketDefinition = commission.scopeParams?.rule?.brackets[lastBracketIndex];
      const formula = `${lastBracketDefinition.formula} - (${firstBracketDefinition.formula})`;

      formulaNode = (
        <div
          className={classNames({
            detailWrapper: true,
            detailFormulaWrapper: true,
          })}
        >
          <div className={styles.detailTitle}>
            {t('commissionRuleDetails.formula')}
          </div>
          <div className={styles.detailValue}>
            <Formula
              formula={formula}
              scope={commission.scope}
            />
          </div>
        </div>
      );

      const resultAmount = getCommissionAmount(commission);

      resultNode = (
        <div className={styles.detailWrapper}>
          <div className={styles.detailTitle}>
            {t('commissionRuleDetails.result')}
          </div>
          <div className={styles.detailValue}>
            {formatPrice(resultAmount, commission.currency)}
          </div>
        </div>
      );
    }

    return (
      <>
        {detailsNode}
        {formulaNode}
        {resultNode}
      </>
    );
  }, [commission]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.ruleNumber}>
        {rule.name || t('commissionRuleDetails.ruleNumber', { index: rule.index + 1 })}
      </div>
      <div className={styles.description}>
        {descriptionNode}
      </div>
    </div>
  );
};

CommissionPerTargetRuleDetails.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionPerTargetRuleDetails.defaultProps = {
  className: '',
};

export default CommissionPerTargetRuleDetails;
