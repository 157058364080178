import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import * as TeamUserModel from '@palette/models/TeamUser';
import * as TeamModel from '@palette/models/Team';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './RemoveMemberFromTeamModal.less';

const RemoveMemberFromTeamModal = ({ visible, onClose, member, team }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const removeMemberFromTeamIsPending = useSelector(TeamsSelectors.removeMemberFromTeamIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
  };

  const handleRemoveMemberFromTeam = () => {
    dispatch(TeamsActions.removeMemberFromTeam({ teamId: team.id, userId: member.user.id, onSuccessCB }));
  };

  return (
    <Modal
      className={styles.modal}
      title={t('removeMemberFromTeamModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleRemoveMemberFromTeam}
      okText={t('removeMemberFromTeamModal.confirm')}
      loading={removeMemberFromTeamIsPending}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: t('removeMemberFromTeamModal.content', { userName: member.user.displayName, teamName: team.name }) }} />
    </Modal>
  );
};

RemoveMemberFromTeamModal.propTypes = {
  member: TeamUserModel.propTypes.isRequired,
  team: TeamModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

RemoveMemberFromTeamModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default RemoveMemberFromTeamModal;
