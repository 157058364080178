import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { redirectTo } from '@palette/helpers/NavigationHelper';
import { getDefaultRoute } from '@palette/helpers/ProfileHelper';

import { actions as ProfileActions } from '@palette/state/Profile';

import styles from './MaintenancePageContent.less';

const classNames = bindClassNames.bind(styles);

const MaintenancePageContent = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const redirectToHomePage = (profile) => {
    redirectTo({ path: getDefaultRoute(profile) });
  };

  useEffect(() => {
    dispatch(ProfileActions.getProfile({ onSuccessCB: redirectToHomePage }));
  }, []);

  const imageNode = (
    <img
      className={styles.image}
      src="/img/maintenance.png"
      alt="maintenance"
    />
  );

  const descriptionNode = (
    <div className={styles.description}>
      <h2>
        {t('maintenance.description.title')}
      </h2>
      <div>
        {t('maintenance.description.subtitle')}
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <DefaultEmptyState
        className={styles.emptyState}
        image={imageNode}
        description={descriptionNode}
      />
    </div>
  );
};

MaintenancePageContent.propTypes = {
  className: PropTypes.string,
};

MaintenancePageContent.defaultProps = {
  className: '',
};

export default MaintenancePageContent;
