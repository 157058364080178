import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import MasterPlansListSelect from '@palette/components/masterPlan/MasterPlansListSelect/MasterPlansListSelect';
import MasterPlanSettingsGeneralNameFormItem from '@palette/components/masterPlanSettings/MasterPlanSettingsGeneralFormItems/MasterPlanSettingsGeneralNameFormItem/MasterPlanSettingsGeneralNameFormItem';
import MasterPlanSettingsGeneralFrequencyBeginEndFormItems from '@palette/components/masterPlanSettings/MasterPlanSettingsGeneralFormItems/MasterPlanSettingsGeneralFrequencyBeginEndFormItems/MasterPlanSettingsGeneralFrequencyBeginEndFormItems';
import DuplicateMasterPlanTypeFormItem from '@palette/components/masterPlan/DuplicateMasterPlanTypeFormItem/DuplicateMasterPlanTypeFormItem';
import Switch from '@palette/components/designSystem/Switch/Switch';

import { useSettingsGeneralFrequencyBeginEndFormItems } from '@palette/hooks/MasterPlanSettingsHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';
import {
  getMomentDateFromPeriod,
  getNextPeriod,
  getPeriodForMomentDate,
  getPreviousPeriod,
} from '@palette/helpers/FrequencyHelper';
import { getPlanLastPeriod, getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import routePaths from '@palette/config/routePaths';

import { DEFAULT_PERIOD_VALUE, DUPLICATE_TYPES } from '@palette/constants/masterPlans';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './DuplicateMasterPlanModal.less';

const DuplicateMasterPlanModal = ({ visible, onClose, planId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const duplicatePlanIsPending = useSelector(MasterPlansSelectors.duplicatePlanIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const [planIdValue, setPlanIdValue] = useState(planId);
  const [duplicateTypeValue, setDuplicateTypeValue] = useState(DUPLICATE_TYPES.DUPLICATE);
  const [terminateParentValue, setTerminateParentValue] = useState(false);
  const [planName, setPlanName] = useState();
  const [disableBeforePeriod, setDisableBeforePeriod] = useState(null);
  const [disableBeforeDate, setDisableBeforeDate] = useState(null);

  useEffect(() => {
    setPlanIdValue(planId);
  }, [planId]);

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planIdValue }));

  const [form] = AntDForm.useForm();

  const [initialPeriodType, setInitialPeriodType] = useState(plan?.periodType || PERIOD_TYPES.MONTH);
  const [initialFrequency, setInitialFrequency] = useState(plan?.frequency || 1);
  const [initialBeginPeriod, setInitialBeginPeriod] = useState(plan?.beginPeriod || DEFAULT_PERIOD_VALUE);
  const [initialCustomBeginAt, setInitialCustomBeginAt] = useState(plan?.customBeginAt ? moment(plan?.customBeginAt) : moment());
  const [initialCustomBeginAtString, setInitialCustomBeginAtString] = useState(plan?.customBeginAt || null);
  const [initialCustomEndAt, setInitialCustomEndAt] = useState(moment());
  const [initialFiscalYearShift, setInitialFiscalYearShift] = useState(plan?.fiscalYearShift || 0);

  const resetToOriginalPlanValues = useCallback((duplicateTypeIsLink = false) => {
    const resetPeriodType = plan?.periodType || PERIOD_TYPES.MONTH;
    const resetFrequency = plan?.frequency || 1;
    let resetBeginPeriod = plan?.beginPeriod || DEFAULT_PERIOD_VALUE;
    let resetCustomBeginAt = plan?.customBeginAt ? moment(plan?.customBeginAt) : moment();
    let resetCustomBeginAtString = plan?.customBeginAt || null;
    const resetFiscalYearShift = plan?.fiscalYearShift || 0;

    let customEndAt = null;

    if (plan.periodType === PERIOD_TYPES.CUSTOM) {
      customEndAt = moment(plan.customBeginAt).utc().add(plan.frequency, 'days').endOf('day');
    }

    customEndAt = customEndAt ? moment(customEndAt) : moment();

    if (duplicateTypeIsLink) {
      const planLastPeriod = getPlanLastPeriod(plan);
      resetCustomBeginAt = getMomentDateFromPeriod(resetPeriodType, resetFrequency, planLastPeriod, plan?.customBeginAt || null);
      resetCustomBeginAtString = resetCustomBeginAt.utc().startOf('day').format();

      resetBeginPeriod = planLastPeriod;
      if (plan.periodType === PERIOD_TYPES.DAY) {
        resetBeginPeriod = {
          year: moment().year(),
          period: 1,
        };
      }
    }

    setPlanName(plan.name);
    setInitialPeriodType(resetPeriodType);
    setInitialFrequency(resetFrequency);
    setInitialBeginPeriod(resetBeginPeriod);
    setInitialCustomBeginAt(resetCustomBeginAt);
    setInitialCustomBeginAtString(resetCustomBeginAtString);
    setInitialCustomEndAt(customEndAt);
    setInitialFiscalYearShift(resetFiscalYearShift);
    setTerminateParentValue(false);
  }, [plan]);

  useEffect(() => {
    if (plan !== null) {
      resetToOriginalPlanValues(duplicateTypeValue === DUPLICATE_TYPES.LINK);
    }
  }, [plan]);

  useEffect(() => {
    if (duplicateTypeValue === DUPLICATE_TYPES.LINK) {
      resetToOriginalPlanValues(true);
    } else {
      const fieldsValues = form.getFieldsValue(true);
      form.setFieldsValue({
        ...fieldsValues,
        terminateParent: false,
      });

      setTerminateParentValue(false);
    }
  }, [duplicateTypeValue]);

  const [
    periodTypeValue,
    frequencyValue,
    beginPeriodValue,
    endPeriodValue,
    fiscalYearShiftValue,
    customBeginAtValue,
    frequencyBeginEndFormItemsInitialValues,
    displayEndPeriod,
    handleSetEndPeriod,
    handleRemoveEndPeriod,
    handleFrequencyBeginEndFormItemsChange,
    getFinalFrequencyBeginEndFormItemsValues,
  ] = useSettingsGeneralFrequencyBeginEndFormItems(
    form,
    initialPeriodType,
    initialFrequency,
    initialBeginPeriod,
    null,
    initialFiscalYearShift,
    initialCustomBeginAt,
    initialCustomBeginAtString,
    initialCustomEndAt,
    true,
  );

  useEffect(() => {
    if (terminateParentValue) {
      const initPlanBeginPeriod = plan?.beginPeriod || DEFAULT_PERIOD_VALUE;
      const initPlanCustomBeginAt = plan?.customBeginAt ? moment(plan?.customBeginAt) : moment();

      const nextPeriod = getNextPeriod(periodTypeValue, frequencyValue, initPlanBeginPeriod.year, initPlanBeginPeriod.period, initPlanCustomBeginAt);

      if (periodTypeValue === PERIOD_TYPES.MONTH) {
        if (
          nextPeriod.year > beginPeriodValue.year
          || (
            nextPeriod.year === beginPeriodValue.year
            && nextPeriod.period > beginPeriodValue.period
          )
        ) {
          setInitialBeginPeriod(nextPeriod);
        }

        setDisableBeforePeriod(nextPeriod);
      }

      if (periodTypeValue === PERIOD_TYPES.DAY) {
        const nextPeriodBeginMomentDate = getMomentDateFromPeriod(periodTypeValue, frequencyValue, nextPeriod, initPlanCustomBeginAt);

        if (nextPeriodBeginMomentDate.isAfter(moment(customBeginAtValue))) {
          setInitialCustomBeginAt(nextPeriodBeginMomentDate);
          setInitialCustomBeginAtString(nextPeriodBeginMomentDate.format());
        }

        setDisableBeforeDate(nextPeriodBeginMomentDate.format());
      }
    } else {
      setDisableBeforePeriod(null);
      setDisableBeforeDate(null);
    }
  }, [
    terminateParentValue,
  ]);

  const initialValues = useMemo(() => ({
    planId: planIdValue,
    name: t('duplicateMasterPlanModal.copyOf'),
    keepUsers: true,
    duplicateType: duplicateTypeValue,
    terminateParent: terminateParentValue,
    ...frequencyBeginEndFormItemsInitialValues,
  }), [
    planIdValue,
    frequencyBeginEndFormItemsInitialValues,
  ]);

  useEffect(() => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      planId: initialValues.planId,
      name: `${t('duplicateMasterPlanModal.copyOf')} ${plan.name}`,
      duplicateType: initialValues.duplicateType,
      terminateParent: initialValues.terminateParent,
    });
  }, [initialValues]);

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    if (changedValues.planId !== undefined) {
      setPlanIdValue(changedValues.planId);
    }

    if (changedValues.duplicateType !== undefined) {
      setDuplicateTypeValue(changedValues.duplicateType);
    }

    if (changedValues.terminateParent !== undefined) {
      setTerminateParentValue(changedValues.terminateParent);
    }

    handleFrequencyBeginEndFormItemsChange(changedValues, allValues);
  }, []);

  const handleFinish = (values) => {
    const [finalValues] = getFinalFrequencyBeginEndFormItemsValues(values);

    const onDuplicateSuccessCB = (masterPlanId) => {
      dispatch(MasterPlansActions.computePlan({ planId: masterPlanId }));

      redirectTo({
        path: routePaths.v2.planDetails,
        params: {
          masterPlanId,
        },
      });
    };

    dispatch(MasterPlansActions.duplicatePlan({ ...finalValues, onSuccessCB: onDuplicateSuccessCB }));
  };

  const handleDuplicatePlan = () => form.submit();

  const showTerminateParentFormItem = useMemo(() => (
    duplicateTypeValue === DUPLICATE_TYPES.LINK
    && periodTypeValue !== PERIOD_TYPES.CUSTOM
    && plan.endPeriod === null
  ), [
    duplicateTypeValue,
    periodTypeValue,
    plan.endPeriod,
  ]);

  const originalPlanEndPeriodName = useMemo(() => {
    if (!showTerminateParentFormItem) return '';

    let beginPeriodForName = beginPeriodValue;
    if (beginPeriodValue === null) {
      beginPeriodForName = {
        year: moment().year(),
        period: 1,
      };
    }

    const beginPeriodMomentDate = getMomentDateFromPeriod(periodTypeValue, frequencyValue, beginPeriodForName, customBeginAtValue, false, fiscalYearShiftValue);

    const initPlanPeriodType = plan?.periodType || PERIOD_TYPES.MONTH;
    const initPlanFrequency = plan?.frequency || 1;
    const initPlanCustomBeginAt = plan?.customBeginAt ? moment(plan?.customBeginAt) : moment();

    const originalPlanPeriod = getPeriodForMomentDate(beginPeriodMomentDate, initPlanPeriodType, initPlanFrequency, initPlanCustomBeginAt, fiscalYearShiftValue);

    const previousPeriod = getPreviousPeriod(initPlanPeriodType, initPlanFrequency, originalPlanPeriod.year, originalPlanPeriod.period, initPlanCustomBeginAt);

    return getPlanPeriodName(plan, previousPeriod);
  }, [
    periodTypeValue,
    frequencyValue,
    fiscalYearShiftValue,
    beginPeriodValue,
    customBeginAtValue,
    plan,
    showTerminateParentFormItem,
  ]);

  return (
    <Modal
      className={styles.modal}
      title={t('duplicateMasterPlanModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDuplicatePlan}
      okText={t('duplicateMasterPlanModal.duplicate')}
      loading={duplicatePlanIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <FormItem
          name="planId"
          label={t('duplicateMasterPlanModal.form.planId.label')}
          required
          rules={[
            { required: true },
          ]}
        >
          <MasterPlansListSelect
            placeholder={t('duplicateMasterPlanSelect.placeholder')}
            disabled={duplicatePlanIsPending}
          />
        </FormItem>
        <DuplicateMasterPlanTypeFormItem disabled={duplicatePlanIsPending} />
        {showTerminateParentFormItem && (
          <div className={styles.switchItemParent}>
            <FormItem
              className={styles.switchItem}
              name="terminateParent"
              required
            >
              <Switch disabled={duplicatePlanIsPending} />
            </FormItem>
            <div className={styles.switchItemLabel}>
              {t('duplicateMasterPlanModal.form.terminateParent.label', {
                name: planName,
                endPeriod: originalPlanEndPeriodName,
              })}
            </div>
          </div>
        )}
        <MasterPlanSettingsGeneralNameFormItem disabled={duplicatePlanIsPending} />
        <MasterPlanSettingsGeneralFrequencyBeginEndFormItems
          periodTypeValue={periodTypeValue}
          frequencyValue={frequencyValue}
          beginPeriodValue={beginPeriodValue}
          displayEndPeriod={displayEndPeriod}
          endPeriodValue={endPeriodValue}
          fiscalYearShiftValue={fiscalYearShiftValue}
          customBeginAtValue={customBeginAtValue}
          handleSetEndPeriod={handleSetEndPeriod}
          handleRemoveEndPeriod={handleRemoveEndPeriod}
          disabled={duplicatePlanIsPending}
          disableEndPeriod
          disableFrequency={duplicateTypeValue === DUPLICATE_TYPES.LINK}
          disableBeforePeriod={disableBeforePeriod}
          disableBeforeDate={disableBeforeDate}
        />
        <div className={styles.switchItemParent}>
          <FormItem
            className={styles.switchItem}
            name="keepUsers"
            required
          >
            <Switch disabled={duplicatePlanIsPending} />
          </FormItem>
          <div className={styles.switchItemLabel}>
            {t('duplicateMasterPlanModal.form.keepUsers.label')}
          </div>
        </div>
      </Form>
    </Modal>
  );
};

DuplicateMasterPlanModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  planId: PropTypes.string,
};

DuplicateMasterPlanModal.defaultProps = {
  visible: false,
  onClose: () => {},
  planId: null,
};

export default DuplicateMasterPlanModal;
