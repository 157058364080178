import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import Calendar2Filled from '@palette/components/utils/Icons/Calendar2Filled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import ResourcesLastSyncDatesModal from '@palette/components/connector/ResourcesLastSyncDatesModal/ResourcesLastSyncDatesModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './ResourcesLastSyncDates.less';

const classNames = bindClassNames.bind(styles);

const ResourcesLastSyncDates = ({ className, connectorId, onSyncConnector, isSynchronizing }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId }));

  const [resetResourceLastSyncDatesModalIsVisible, showResetResourceLastSyncDatesModal] = useState(false);

  const resetButtonNode = useMemo(() => (
    <Button
      type="secondary"
      icon={(
        isSynchronizing
          ? <LoadingLine width={16} height={16} spin />
          : <Calendar2Filled width={16} height={16} />
      )}
      onClick={() => showResetResourceLastSyncDatesModal(true)}
      disabled={isSynchronizing}
    >
      {t('resourceLastSyncDates.button.label')}
    </Button>
  ), [
    isSynchronizing,
  ]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.OBJECTS.VIEW])) {
    return null;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {resetButtonNode}
      {resetResourceLastSyncDatesModalIsVisible && (
        <ResourcesLastSyncDatesModal
          visible
          onClose={() => showResetResourceLastSyncDatesModal(false)}
          connector={connector}
          onSyncConnector={onSyncConnector}
        />
      )}
    </div>
  );
};

ResourcesLastSyncDates.propTypes = {
  className: PropTypes.string,
  connectorId: PropTypes.string.isRequired,
  onSyncConnector: PropTypes.func,
  isSynchronizing: PropTypes.bool,
};

ResourcesLastSyncDates.defaultProps = {
  className: '',
  onSyncConnector: () => {},
  isSynchronizing: false,
};

export default ResourcesLastSyncDates;
