import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';

export const useConnectorsList = () => {
  const dispatch = useDispatch();

  const connectorsList = useSelector(ConnectorsSelectors.getConnectorsList);
  const refreshListIsNeeded = useSelector(ConnectorsSelectors.refreshListIsNeeded);

  if (refreshListIsNeeded) {
    dispatch(ConnectorsActions.refreshList());
  }

  return useMemo(() => (
    connectorsList
  ), [connectorsList]);
};
