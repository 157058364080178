import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Empty as AntDEmpty } from 'antd';

import styles from './DefaultEmptyState.less';

const classNames = bindClassNames.bind(styles);

const DefaultEmptyState = ({ className, description, size, isBold, ...otherProps }) => {
  let descriptionNode = null;

  if (description !== null) {
    descriptionNode = (
      <div
        className={classNames({
          description: true,
          bold: isBold,
        })}
      >
        {description}
      </div>
    );
  }

  return (
    <AntDEmpty
      className={classNames({
        wrapper: true,
        compact: size === 'compact',
        [className]: className !== '',
      })}
      description={descriptionNode}
      {...otherProps}
    />
  );
};

DefaultEmptyState.propTypes = {
  className: PropTypes.string,
  image: PropTypes.any,
  description: PropTypes.any,
  size: PropTypes.oneOf(['default', 'compact']),
  isBold: PropTypes.bool,
};

DefaultEmptyState.defaultProps = {
  className: '',
  description: null,
  image: AntDEmpty.PRESENTED_IMAGE_SIMPLE,
  size: 'default',
  isBold: false,
};

export default DefaultEmptyState;
