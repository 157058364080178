import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '@palette/components/utils/Loader/Loader';
import TeamsListForUser from '@palette/components/userDetails/TeamsListForUser/TeamsListForUser';

import * as MetaUserModel from '@palette/models/MetaUser';

import { actions as TeamsActions, selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './UserDetailsTeamsTab.less';

const classNames = bindClassNames.bind(styles);

const UserDetailsTeamsTab = ({ className, user }) => {
  const dispatch = useDispatch();

  const getTeamsForUserIsPending = useSelector(TeamsSelectors.getTeamsForUserIsPending);
  const userTeams = useSelector((state) => TeamsSelectors.getTeamsForUser(state, { userId: user.id }));

  useEffect(() => {
    dispatch(TeamsActions.getTeamsForUser({ userId: user.id }));
  }, [user]);

  const teamsNodes = useMemo(() => (
    <Loader spinning={getTeamsForUserIsPending}>
      <TeamsListForUser teams={userTeams} />
    </Loader>
  ), [getTeamsForUserIsPending, userTeams]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {teamsNodes}
    </div>
  );
};

UserDetailsTeamsTab.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserDetailsTeamsTab.defaultProps = {
  className: '',
};

export default UserDetailsTeamsTab;
