import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as NotebookCellModel from '@palette/models/NotebookCell';

import { selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './EditableNotebookCellName.less';

const classNames = bindClassNames.bind(styles);

const EditableNotebookCellName = ({ className, cell, updateName, disabled, hasError }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const [editing, setEditing] = useState(false);
  const [cellName, setCellName] = useState(cell.name);

  useEffect(() => {
    setCellName(cell.name);
  }, [cell.name]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  useEffect(() => {
    if (!editNotebookIsPending) {
      setEditing(false);
    }
  }, [editNotebookIsPending]);

  const saveUpdate = useCallback(() => {
    if (cell.name !== cellName.trim()) {
      updateName(cellName.trim());
    }

    setEditing(false);
  }, [cell, cellName, updateName]);

  const handleInputChange = useCallback((newValue) => {
    setCellName(newValue);
  }, []);

  const switchToEditMode = useCallback(() => {
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE]) && !disabled) {
      setEditing(true);
    }
  }, [profile, disabled]);

  const inputNode = useMemo(() => (
    editing ? (
      <Input
        ref={inputRef}
        className={classNames({
          input: true,
          [className]: className !== '',
        })}
        type="text"
        value={cellName}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={saveUpdate}
      />
    ) : null
  ), [editing, inputRef, className, cellName, handleInputChange, saveUpdate]);

  const readonlyNameNode = useMemo(() => (
    !editing ? (
      <div
        aria-errormessage={t('notebookCell.uniqueIdentifier.required')}
        className={classNames({
          wrapper: true,
          disabled,
          required: hasError,
          [className]: className !== '',
        })}
        onClick={switchToEditMode}
      >
        {cell.name}
        &nbsp;
      </div>
    ) : null
  ), [editing, disabled, className, switchToEditMode, cell]);

  return (
    <div className={styles.cellNameWrapper}>
      <div className={styles.label}>
        {t('notebookCell.title')}
        :
      </div>
      {inputNode}
      {readonlyNameNode}
    </div>
  );
};

EditableNotebookCellName.propTypes = {
  className: PropTypes.string,
  cell: NotebookCellModel.propTypes.isRequired,
  updateName: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
};

EditableNotebookCellName.defaultProps = {
  className: '',
  disabled: false,
  hasError: false,
};

export default EditableNotebookCellName;
