import PropTypes from 'prop-types';

import {
  manageBooleanAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PayoutRulePayment';

export const propTypes = PropTypes.shape({
  percentageFormula: PropTypes.string,
  usePercentageAsAmount: PropTypes.bool,
  repeatFormula: PropTypes.string,
  startingAtFormula: PropTypes.string,
  intervalCount: PropTypes.number,
  intervalType: PropTypes.oneOf(['', 'day', 'month', 'year']),
  intervalFormula: PropTypes.string,
});

export const transform = (payment) => {
  if (!payment) {
    return null;
  }

  return (
    {
      percentageFormula: manageStringAttribute(payment, 'percentageFormula'),
      usePercentageAsAmount: manageBooleanAttribute(payment, 'usePercentageAsAmount'),
      repeatFormula: manageStringAttribute(payment, 'repeatFormula'),
      startingAtFormula: manageStringAttribute(payment, 'startingAtFormula'),
      intervalCount: manageNumberAttribute(payment, 'intervalCount', 1),
      intervalType: manageStringAttribute(payment, 'intervalType'),
      intervalFormula: manageStringAttribute(payment, 'intervalFormula', null),
    }
  );
};

export const transformList = (payments) => payments.map((payment) => transform(payment));
