import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import _map from 'lodash/map';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as StatementStrategyModel from '@palette/models/StatementStrategy';
import * as UserStatementModel from '@palette/models/UserStatement';

export const modelName = 'StatementPeriodPeriod';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  statementStrategy: StatementStrategyModel.propTypes,
  year: PropTypes.number,
  period: PropTypes.number,
  beginDate: PropTypes.string,
  endDate: PropTypes.string,
  totalAmount: PropTypes.number,
  currency: PropTypes.string,
  usersCount: PropTypes.number,
  approvedCount: PropTypes.number,
  pendingApprovalsCount: PropTypes.number,
  inConflictCount: PropTypes.number,
  paidCount: PropTypes.number,
  correctionsCount: PropTypes.number,
  userStatements: PropTypes.arrayOf(UserStatementModel.propTypes),
  users: PropTypes.arrayOf(MetaUserModel.propTypes),
});

export const transform = (statementPeriod) => {
  if (!statementPeriod) {
    return null;
  }

  const userStatements = _orderBy(
    UserStatementModel.transformList(manageArrayAttribute(statementPeriod, 'statements')),
    (userStatement) => (userStatement.user.displayName),
    ['asc'],
  );

  return (
    {
      id: manageStringAttribute(statementPeriod, 'periodId'),
      statementStrategy: StatementStrategyModel.transform(manageFreeShapeObjectAttribute(statementPeriod, 'periodStrategy')),
      year: manageNumberAttribute(statementPeriod, 'year'),
      period: manageNumberAttribute(statementPeriod, 'period'),
      beginDate: manageDateAttribute(statementPeriod, 'beginAt'),
      endDate: manageDateAttribute(statementPeriod, 'endAt'),
      totalAmount: manageAmountAttribute(statementPeriod, 'amount'),
      currency: manageStringAttribute(statementPeriod, 'currency'),
      usersCount: manageNumberAttribute(statementPeriod, 'usersCount'),
      approvedCount: manageNumberAttribute(statementPeriod, 'approvedStatementsCount'),
      pendingApprovalsCount: manageNumberAttribute(statementPeriod, 'pendingApprovalsCount'),
      inConflictCount: manageNumberAttribute(statementPeriod, 'conflictingStatementsCount'),
      paidCount: manageNumberAttribute(statementPeriod, 'paidStatementsCount'),
      correctionsCount: manageNumberAttribute(statementPeriod, 'correctionsCount'),
      userStatements,
      users: _map(userStatements, 'user'),
    }
  );
};

export const transformList = (statementPeriods) => statementPeriods.map((statementPeriod) => transform(statementPeriod));

export const merge = (statementPeriod1, statementPeriod2) => {
  if (!statementPeriod2) return statementPeriod1;
  if (!statementPeriod1) return statementPeriod2;

  let mergedUserStatements = statementPeriod1.userStatements;
  let mergedUsers = statementPeriod1.users;

  if (statementPeriod2.userStatements.length) {
    mergedUserStatements = statementPeriod2.userStatements;
  }

  if (statementPeriod2.users.length) {
    mergedUsers = statementPeriod2.users;
  }

  return {
    ...statementPeriod2,
    userStatements: mergedUserStatements,
    users: mergedUsers,
  };
};

export const mergeFromUserStatement = (statementPeriod, userStatement) => {
  const {
    approvals,
    correctionsCount,
    currency,
    isPaid,
    statementAmount,
  } = userStatement;

  const approvedCount = approvals.length;

  return {
    ...statementPeriod,
    totalAmount: statementAmount,
    currency,
    approvedCount,
    pendingApprovalsCount: isPaid || approvedCount > 0 ? 0 : 1,
    paidCount: isPaid ? 1 : 0,
    correctionsCount,
  };
};
