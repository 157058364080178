import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import styles from './RawDataPanelTitleNode.less';

const classNames = bindClassNames.bind(styles);

const RawDataPanelTitleNode = ({
  label,
  count,
  isRoot,
  paddingLeft,
  property,
  badge,
}) => {
  const { t } = useTranslation();

  const localized = property === 'object'
    ? 'rawDataCollapsePropertiesConditionsWithCount'
    : 'rawDataCollapseItemsConditionsWithCount';

  return (
    <span
      className={classNames({
        wrapper: true,
        big: isRoot,
      })}
      style={{ paddingLeft }}
    >
      {label}
      <small>{t(localized, { count })}</small>
      {badge !== null && (
        <span
          className={classNames({
            badge: true,
            deletion: badge === 'old',
          })}
        >
          {t(`rawDataPanelTitleNode.badge.${badge}`)}
        </span>
      )}
    </span>
  );
};

RawDataPanelTitleNode.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  isRoot: PropTypes.bool,
  paddingLeft: PropTypes.string,
  property: PropTypes.oneOf(['array', 'object']),
  badge: PropTypes.oneOf([null, 'old', 'new']),
};

RawDataPanelTitleNode.defaultProps = {
  label: '',
  count: 0,
  isRoot: false,
  paddingLeft: '',
  property: 'object',
  badge: null,
};

export default RawDataPanelTitleNode;
