import _omitBy from 'lodash/omitBy';

import { checkEndpointRights } from '@palette/helpers/ApiHelper';
import { formatToISOStringWithFractionalSeconds } from '@palette/helpers/MomentHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* list() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.list, {}, getConfig()),
    [RIGHTS.ADMIN.USERS.VIEW],
  );
}

export function* listInPlans() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.company.listUsersInPlans, {}, getConfig()),
    [RIGHTS.ADMIN.USERS.VIEW],
  );
}

export function* currenciesList() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.listCurrencies, {}, getConfig()),
    [],
  );
}

export function* inviteUser(payload) {
  const {
    userId,
    email,
    roleId,
    hireDate = null,
    teamIds,
    dashboardPresetId,
  } = payload;

  const data = {
    userId,
    email,
    roleId,
    teamIds,
    presetId: dashboardPresetId,
  };

  if (hireDate !== null) {
    data.joinDate = formatToISOStringWithFractionalSeconds(hireDate);
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.inviteUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.INVITE],
  );
}

export function* resendUserInvitation(payload) {
  const {
    userId,
  } = payload;

  const data = {
    userIds: [userId],
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.resendUserInvitation, data, getConfig()),
    [RIGHTS.ADMIN.USERS.INVITE],
  );
}

export function* getUserById(payload) {
  const {
    userId,
  } = payload;

  const data = {
    userId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.getUserById, data, getConfig()),
    [RIGHTS.ADMIN.USERS.VIEW],
  );
}

export function* onboardUsers(payload) {
  const {
    users,
  } = payload;

  const data = users.map((onboardUser) => _omitBy(onboardUser, (elem) => elem === null));

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.onboardUsers, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}

export function* getUserResourcesCommonData(payload) {
  const {
    resourceObjects,
  } = payload;

  const data = resourceObjects.map((resourceObject) => ({
    objectId: resourceObject.id,
    connector: resourceObject.connectorId,
    type: resourceObject.originalType,
  }));

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.getUserResourcesCommonData, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}

export function* updateUser(payload) {
  const {
    userId,
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    currency = undefined,
    payrollId = undefined,
    joinDate = undefined,
    leaveDate = undefined,
    profilePicUrl = undefined,
  } = payload;

  const data = {
    userId,
    firstName,
    lastName,
    email,
    currency,
    joinDate,
    leaveDate,
    profilePicUrl,
  };
  if (payrollId !== '') {
    data.payrollId = payrollId;
  }
  if (joinDate !== undefined) {
    data.joinDate = joinDate;

    if (joinDate !== null) {
      data.joinDate = formatToISOStringWithFractionalSeconds(joinDate);
    }
  }
  if (leaveDate !== undefined) {
    data.leaveDate = leaveDate;

    if (leaveDate !== null) {
      data.leaveDate = formatToISOStringWithFractionalSeconds(leaveDate);
    }
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.updateUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}

export function* updateUserAccount(payload) {
  const {
    userId,
    email = undefined,
    role = undefined,
  } = payload;

  const data = {
    userId,
    account: {
      email,
      role,
    },
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.updateUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}

export function* deactivateUser(payload) {
  const {
    userId,
  } = payload;

  const data = {
    userId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.deactivateUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.TOGGLE_ACTIVATE],
  );
}

export function* unlinkResourceFromUser(payload) {
  const {
    userId,
    resourceObjectId,
  } = payload;

  const data = {
    userId,
    objectId: resourceObjectId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.unlinkResourceFromUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}

export function* linkResourceToUser(payload) {
  const {
    userId,
    resourceToLink,
  } = payload;

  const data = {
    userId,
    objectToLink: {
      objectId: resourceToLink.id,
      connector: resourceToLink.connectorId,
      type: resourceToLink.originalType,
    },
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.linkResourceToUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}

export function* offboardUser(payload) {
  const {
    userId,
    leaveDate,
    adjustmentReason,
    adjustmentAmount,
    adjustmentCurrency,
  } = payload;

  const data = {
    userId,
    leaveDate: formatToISOStringWithFractionalSeconds(leaveDate),
  };
  if (adjustmentAmount !== null) {
    data.adjustment = {
      amount: adjustmentAmount,
      currency: adjustmentCurrency,
      reason: adjustmentReason,
    };
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.offboardUser, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}

export function* getMergeConflicts(payload) {
  const {
    firstUserId,
    secondUserId,
  } = payload;

  const data = {
    sourceUserId: firstUserId,
    destinationUserId: secondUserId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.users.getMergeConflicts, data, getConfig()),
    [RIGHTS.ADMIN.USERS.UPDATE],
  );
}
