import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import FilterFilled from '@palette/components/utils/Icons/FilterFilled';
import ResourcesQueryFilterModal from '@palette/components/resources/ResourcesQueryFilterModal/ResourcesQueryFilterModal';

import { PAGE_QS_KEY } from '@palette/constants/navigation';
import { INITIAL_STRINGIFIED_QUERY_BUILDER_VALUE } from '@palette/constants/queryBuilder';
import { QUERY_FILTER_QS_KEY, QUICK_SEARCH_QS_KEY } from '@palette/constants/resources';

import { usePageInSearch } from '@palette/hooks/NavigationHooks';

import { actions as NavigationActions } from '@palette/state/Navigation';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './ResourcesContentDetailsActions.less';

const classNames = bindClassNames.bind(styles);

const ResourcesContentDetailsActions = ({ className, quickSearchFromSearch, queryFilterFromSearch, helperData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [, paginationPageInSearch] = usePageInSearch();
  const [quickSearch, setQuickSearch] = useState(null);
  const [filterModalIsVisible, showFilterModal] = useState(false);
  const [filterTooltipVisible, setFilterTooltipVisible] = useState(false);

  useEffect(() => {
    const keysToDelete = [];

    if (quickSearch === '' && quickSearchFromSearch) {
      keysToDelete.push(QUICK_SEARCH_QS_KEY);

      if (paginationPageInSearch) {
        keysToDelete.push(PAGE_QS_KEY);
      }
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    paginationPageInSearch,
    quickSearchFromSearch,
    quickSearch,
  ]);

  useEffect(() => {
    if (quickSearch && !quickSearchFromSearch) {
      setQuickSearch(null);
    }
  }, [quickSearchFromSearch]);

  const performQuickSearch = useCallback(
    _debounce((newSearch) => {
      const QSToAdd = {};
      const keysToDelete = [PAGE_QS_KEY];

      if (newSearch !== '') {
        QSToAdd[QUICK_SEARCH_QS_KEY] = newSearch;
      } else {
        keysToDelete.push(QUICK_SEARCH_QS_KEY);
      }

      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    }, 700),
    [],
  );

  const handlePerformQuickSearchChange = useCallback((newSearch) => {
    performQuickSearch(newSearch);
  }, []);

  const handleQuickSearchChange = useCallback((newSearch) => {
    setQuickSearch(newSearch);

    if (newSearch.length >= 1) {
      handlePerformQuickSearchChange(newSearch);
    }
  }, [handlePerformQuickSearchChange]);

  const handleQueryFilterSubmit = useCallback((newFilter) => {
    const QSToAdd = {};
    const keysToDelete = [PAGE_QS_KEY];

    if (newFilter) {
      QSToAdd[QUERY_FILTER_QS_KEY] = newFilter;
    } else {
      keysToDelete.push(QUERY_FILTER_QS_KEY);
    }

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const handleFilterQueryBuilder = (event) => {
    event.stopPropagation();
    setFilterTooltipVisible(false);
    showFilterModal(true);
  };

  const closeFilterModal = (event) => {
    event.stopPropagation();
    showFilterModal(false);
  };

  const queryFilterButtonNode = useMemo(() => {
    const hasQuery = queryFilterFromSearch && (queryFilterFromSearch !== INITIAL_STRINGIFIED_QUERY_BUILDER_VALUE);

    return (
      <Button
        className={styles.filterButton}
        type={hasQuery ? 'primary' : 'secondary'}
        icon={<FilterFilled className={classNames('filterIcon', { white: hasQuery })} />}
        onClick={(event) => handleFilterQueryBuilder(event)}
      />
    );
  }, [queryFilterFromSearch]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.searchContainer}>
        <Input
          className={styles.inputSearch}
          type="search"
          defaultValue={quickSearchFromSearch}
          placeholder={t('resources.actions.search.placeholder')}
          onChange={handleQuickSearchChange}
          value={quickSearch || quickSearchFromSearch}
        />
      </div>
      <Tooltip
        title={t('actions.filter')}
        visible={filterTooltipVisible}
        onVisibleChange={(visible) => setFilterTooltipVisible(visible)}
      >
        {queryFilterButtonNode}
      </Tooltip>
      {filterModalIsVisible && (
        <div onClick={(event) => event.stopPropagation()}>
          <ResourcesQueryFilterModal
            visible
            value={queryFilterFromSearch}
            helperData={helperData}
            onSubmit={handleQueryFilterSubmit}
            onClose={(event) => closeFilterModal(event)}
          />
        </div>
      )}
    </div>
  );
};

ResourcesContentDetailsActions.propTypes = {
  className: PropTypes.string,
  quickSearchFromSearch: PropTypes.string,
  queryFilterFromSearch: PropTypes.string,
  helperData: ResourceObjectModel.propTypes,
};

ResourcesContentDetailsActions.defaultProps = {
  className: '',
  quickSearchFromSearch: '',
  queryFilterFromSearch: '',
  helperData: {},
};

export default ResourcesContentDetailsActions;
