import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import FolderOpenFilled from '@palette/components/utils/Icons/FolderOpenFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import UpdateTeamNameModal from '@palette/components/team/UpdateTeamNameModal/UpdateTeamNameModal';
import DeleteTeamModal from '@palette/components/team/DeleteTeamModal/DeleteTeamModal';
import MoveTeamModal from '@palette/components/team/MoveTeamModal/MoveTeamModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as TeamModel from '@palette/models/Team';

import styles from './TeamsListItemMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const TeamsListItemMoreOptionsMenu = ({ className, team, onUpdateTeam, onOpenChange }) => {
  const profile = useProfile();
  const { t } = useTranslation();

  const [updateTeamNameModalIsVisible, showUpdateTeamNameModal] = useState(false);
  const [moveTeamModalIsVisible, showMoveTeamModal] = useState(false);
  const [deleteTeamModalIsVisible, showDeleteTeamModal] = useState(false);

  const closeMoreMenu = useCallback(() => {
    if (onOpenChange !== null) {
      onOpenChange(false);
    }
  }, [onOpenChange]);

  const moreMenuItems = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.MANAGE])) return [];

    const menuItems = [];

    const updateTeamNameMenuItem = {
      key: 'updateTeamName',
      title: t('teamsListItemMoreOptionsMenu.updateTeamName'),
      icon: (
        <PenFilled width={16} height={16} />
      ),
      onClick: () => {
        showUpdateTeamNameModal(true);
        closeMoreMenu();
      },
    };

    menuItems.push(updateTeamNameMenuItem);

    const moveTeamMenuItem = {
      key: 'moveTeam',
      title: t('teamsListItemMoreOptionsMenu.moveTeam'),
      icon: (
        <FolderOpenFilled width={16} height={16} />
      ),
      onClick: () => {
        showMoveTeamModal(true);
        closeMoreMenu();
      },
    };

    menuItems.push(moveTeamMenuItem);

    const deleteTeamMenuItem = {
      key: 'deleteTeam',
      title: t('teamsListItemMoreOptionsMenu.deleteTeam'),
      icon: (
        <TrashFilled width={16} height={16} />
      ),
      onClick: () => {
        showDeleteTeamModal(true);
        closeMoreMenu();
      },
    };

    menuItems.push(deleteTeamMenuItem);

    return menuItems;
  }, [
    profile,
    team,
    showUpdateTeamNameModal,
    showMoveTeamModal,
    showDeleteTeamModal,
    closeMoreMenu,
  ]);

  const moreMenuProps = useMemo(() => {
    if (onOpenChange === null) return {};

    return {
      onVisibleChange: onOpenChange,
    };
  }, [onOpenChange]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.TEAMS.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={moreMenuItems}
        {...moreMenuProps}
      />
      {
        updateTeamNameModalIsVisible && (
          <UpdateTeamNameModal visible onClose={() => showUpdateTeamNameModal(false)} team={team} onUpdate={onUpdateTeam} />
        )
      }
      {
        moveTeamModalIsVisible && (
          <MoveTeamModal visible onClose={() => showMoveTeamModal(false)} team={team} onMove={onUpdateTeam} />
        )
      }
      {
        deleteTeamModalIsVisible && (
          <DeleteTeamModal visible onClose={() => showDeleteTeamModal(false)} team={team} onDelete={onUpdateTeam} />
        )
      }
    </div>
  );
};

TeamsListItemMoreOptionsMenu.propTypes = {
  team: TeamModel.propTypes.isRequired,
  className: PropTypes.string,
  onUpdateTeam: PropTypes.func,
  onOpenChange: PropTypes.func,
};

TeamsListItemMoreOptionsMenu.defaultProps = {
  className: '',
  onUpdateTeam: null,
  onOpenChange: null,
};

export default TeamsListItemMoreOptionsMenu;
