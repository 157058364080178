import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import RawData from '@palette/components/resources/RawData/RawData';

import * as CommissionModel from '@palette/models/Commission';

import styles from './CommissionRuleFormulaScope.less';

const classNames = bindClassNames.bind(styles);

const CommissionRuleFormulaScope = ({ className, commission }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const rawData = {
    type: t('commissionRuleFormulaScope.formulaScope'),
    data: commission.scope,
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        isOpen,
        [className]: className !== '',
      })}
    >
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          type="link"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? t('commissionRuleFormulaScope.hide') : t('commissionRuleFormulaScope.see')}
        </Button>
        <ChevronRightLine className={styles.icon} />
      </div>

      <div className={styles.rawDataWrapper}>
        <RawData
          className={styles.rawData}
          data={rawData}
          onlyReadable
          isExpandedByDefault
        />
      </div>
    </div>
  );
};

CommissionRuleFormulaScope.propTypes = {
  className: PropTypes.string,
  commission: CommissionModel.propTypes.isRequired,
};

CommissionRuleFormulaScope.defaultProps = {
  className: '',
};

export default CommissionRuleFormulaScope;
