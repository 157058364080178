import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import _debounce from 'lodash/debounce';

import Button from '@palette/components/designSystem/Button/Button';
import Input from '@palette/components/designSystem/Input/Input';
import Loader from '@palette/components/utils/Loader/Loader';
import DollarFilled from '@palette/components/utils/Icons/DollarFilled';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';
import MyMasterPlanPeriodDealsTable from '@palette/components/ic/myMasterPlan/MyMasterPlanPeriodDealsTable/MyMasterPlanPeriodDealsTable';
import MyMasterPlanSomethingMissingModal from '@palette/components/ic/myMasterPlan/MyMasterPlanSomethingMissingModal/MyMasterPlanSomethingMissingModal';
import MyMasterPlanCalculationModal from '@palette/components/ic/myMasterPlan/MyMasterPlanCalculationModal/MyMasterPlanCalculationModal';
import CommissionsWithoutPaymentLink from '@palette/components/masterPlanPeriod/CommissionsWithoutPaymentLink/CommissionsWithoutPaymentLink';

import { useSearchedDealInSearch } from '@palette/hooks/MasterPlanHooks';
import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';

import { periodIsFrozen } from '@palette/helpers/MasterPlanHelper';
import { scrollToTop } from '@palette/helpers/NavigationHelper';

import { SEARCHED_DEAL_QS_KEY } from '@palette/constants/masterPlans';
import { DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE, LIMIT_QS_KEY, PAGE_QS_KEY } from '@palette/constants/navigation';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './MyMasterPlanPeriodDeals.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanPeriodDeals = ({ className, plan, period }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [getDealsLimit, dealsLimitInSearch] = useLimitInSearch(DEFAULT_LIMIT_QS_VALUE);
  const [getDealsPage, dealsPageInSearch] = usePageInSearch(DEFAULT_PAGE_QS_VALUE);
  const [searchedDealFromSearch] = useSearchedDealInSearch();

  const [searchedDeal, setSearchedDeal] = useState(null);
  const [calculationModalIsVisible, showCalculationModal] = useState(false);
  const [somethingMissingModalIsVisible, showSomethingMissingModal] = useState(false);

  const getPeriodDealsIsPending = useSelector(MasterPlansSelectors.getPeriodDealsIsPending);

  useEffect(() => {
    dispatch(MasterPlansActions.getICPeriodDeals({
      planId: plan.id,
      year: period.year,
      period: period.period,
      page: getDealsPage,
      limit: getDealsLimit,
      searchedDeal: searchedDealFromSearch,
    }));
  }, [
    plan,
    period,
    getDealsPage,
    getDealsLimit,
    searchedDealFromSearch,
  ]);

  useEffect(() => {
    const keysToDelete = [];

    if (getDealsPage === DEFAULT_PAGE_QS_VALUE && dealsPageInSearch) {
      keysToDelete.push(PAGE_QS_KEY);
    }

    if (getDealsLimit === DEFAULT_LIMIT_QS_VALUE && dealsLimitInSearch) {
      keysToDelete.push(LIMIT_QS_KEY);
    }

    if (searchedDeal === '' && searchedDealFromSearch) {
      keysToDelete.push(SEARCHED_DEAL_QS_KEY);
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    getDealsPage,
    dealsPageInSearch,
    getDealsLimit,
    dealsLimitInSearch,
    searchedDeal,
    searchedDealFromSearch,
  ]);

  const performDealSearch = useCallback(
    _debounce((newSearchedDeal) => {
      const QSToAdd = {};
      const keysToDelete = [PAGE_QS_KEY];

      if (newSearchedDeal !== '') {
        QSToAdd[SEARCHED_DEAL_QS_KEY] = newSearchedDeal;
      } else {
        keysToDelete.push(SEARCHED_DEAL_QS_KEY);
      }

      dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
    }, 700),
    [],
  );

  const handlePerformDealChange = useCallback((newSearchedDeal) => {
    performDealSearch(newSearchedDeal);
  }, []);

  const handleSearchDealChange = (newSearchedDeal) => {
    setSearchedDeal(newSearchedDeal);

    if (newSearchedDeal.length >= 1) {
      handlePerformDealChange(newSearchedDeal);
    }
  };

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  useEffect(() => {
    if (!getPeriodDealsIsPending) {
      scrollToTop();
    }
  }, [getPeriodDealsIsPending]);

  const contentNode = useMemo(() => (
    <>
      <div className={styles.header}>
        <Input
          className={styles.dealSearch}
          type="search"
          defaultValue={searchedDealFromSearch}
          placeholder={t('masterPlanPeriodDeals.dealSearch.placeholder', { dealType: pluralize.singular(plan.trackingObject?.type || '') })}
          onChange={handleSearchDealChange}
          value={searchedDeal || searchedDealFromSearch}
        />
        <div className={styles.actions}>
          <CommissionsWithoutPaymentLink className={styles.actionLink} plan={plan} period={period} forIC />
          <Button
            className={styles.actionLink}
            type="linkSecondary"
            icon={<DollarFilled />}
            onClick={() => showCalculationModal(true)}
          >
            {t('myMasterPlanPeriodDeals.actions.calculationDetails')}
          </Button>
          {!periodIsFrozen(plan, period) && (
            <Button
              className={styles.actionLink}
              type="linkSecondary"
              icon={<InfoCircleFilled />}
              onClick={() => showSomethingMissingModal(true)}
            >
              {t('myMasterPlanPeriodDeals.actions.somethingMissing')}
            </Button>
          )}
        </div>
      </div>
      <Loader spinning={getPeriodDealsIsPending}>
        <MyMasterPlanPeriodDealsTable
          plan={plan}
          period={period}
          onPageChange={handlePageChange}
          onLimitChange={handleLimitChange}
        />
      </Loader>
    </>
  ), [
    getPeriodDealsIsPending,
    searchedDealFromSearch,
    plan,
    handleSearchDealChange,
    searchedDeal,
    searchedDealFromSearch,
    period,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      {calculationModalIsVisible && (
        <MyMasterPlanCalculationModal visible onClose={() => showCalculationModal(false)} plan={plan} period={period} />
      )}
      {somethingMissingModalIsVisible && !periodIsFrozen(plan, period) && (
        <MyMasterPlanSomethingMissingModal visible onClose={() => showSomethingMissingModal(false)} plan={plan} />
      )}
    </div>
  );
};

MyMasterPlanPeriodDeals.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
};

MyMasterPlanPeriodDeals.defaultProps = {
  className: '',
};

export default MyMasterPlanPeriodDeals;
