import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import ExchangeRateModal from '@palette/components/company/ExchangeRateModal/ExchangeRateModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './EditExchangeRateButton.less';

const classNames = bindClassNames.bind(styles);

const EditExchangeRateButton = ({ className, from, to, rate }) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [editTooltipVisible, setEditTooltipVisible] = useState(false);
  const [exchangeRateModalIsVisible, showExchangeRateModal] = useState(false);

  const handleEditClick = () => {
    setEditTooltipVisible(false);
    showExchangeRateModal(true);
  };

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tooltip
        title={t('companyExchangeRates.edit.tooltip.label')}
        visible={editTooltipVisible}
        onVisibleChange={(visible) => setEditTooltipVisible(visible)}
      >
        <Button
          className={styles.editButton}
          type="link"
          icon={<PenFilled width={16} height={16} />}
          onClick={handleEditClick}
        />
      </Tooltip>
      {exchangeRateModalIsVisible && (
        <ExchangeRateModal
          visible
          type="edit"
          from={from}
          to={to}
          rate={rate}
          onClose={() => showExchangeRateModal(false)}
        />
      )}
    </div>
  );
};

EditExchangeRateButton.propTypes = {
  className: PropTypes.string,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
};

EditExchangeRateButton.defaultProps = {
  className: '',
};

export default EditExchangeRateButton;
