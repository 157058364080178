import React from 'react';

import Icon from '@ant-design/icons';

import SettingsFilled from '@palette/components/utils/Icons/SettingsFilled';
import BookFilled from '@palette/components/utils/Icons/BookFilled';
import SendFilled from '@palette/components/utils/Icons/SendFilled';
import ChatFilled from '@palette/components/utils/Icons/ChatFilled';
import Avatar from '@palette/components/user/Avatar/Avatar';

import routePaths from '@palette/config/routePaths';

import { PALETTE_SALESFORCE_URL } from '@palette/constants/global';
import { MENU_MODALS } from '@palette/constants/menu';

import { hasAtLeastOneAdminRight } from '@palette/helpers/ProfileHelper';

const actionsMenu = {
  key: 'ActionsMenu',
  title: '',
  show: () => true,
  items: [
    {
      key: 'ActionsMenuDocumentation',
      icon: (<BookFilled />),
      name: 'actionsMenu.documentation',
      show: (profile) => hasAtLeastOneAdminRight(profile),
      disableOnClick: true,
      openModal: MENU_MODALS.ALGOLIA_SEARCH_MODAL,
    },
    {
      key: 'ActionsMenuFeedback',
      icon: (<SendFilled />),
      name: 'actionsMenu.sendFeedback',
      show: () => true,
      disableOnClick: true,
      openModal: MENU_MODALS.SEND_FEEDBACK_MODAL,
    },
    {
      key: 'ActionsMenuIntercom',
      onClick: () => window.Intercom('show'),
      icon: (<ChatFilled />),
      name: 'actionsMenu.chatWithSupport',
      show: () => window
        && window.location
        && window.location.host !== PALETTE_SALESFORCE_URL,
    },
    {
      key: 'ActionsMenuSettings',
      path: routePaths.v2.settings,
      icon: (<SettingsFilled />),
      name: 'actionsMenu.settings',
      show: () => true,
    },
    {
      key: 'ActionsMenuProfile',
      path: routePaths.v2.profile,
      icon: ({ profile }) => (<Icon className="ActionsMenuProfileIcon" component={() => (<Avatar user={profile.userData} />)} />),
      name: '',
      show: () => true,
      contentComponent: ({ profile }) => profile.userData.displayName,
    },
  ],
};

export default actionsMenu;
