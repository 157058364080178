import PropTypes from 'prop-types';

import {
  manageNumberAttribute,
} from '@palette/helpers/ModelHelper';
import { NOTEBOOKS_TABS_IDS } from '@palette/constants/tabs';

export const modelName = 'NotebooksStats';

export const propTypes = PropTypes.shape({
  [NOTEBOOKS_TABS_IDS.ALL]: PropTypes.number,
  [NOTEBOOKS_TABS_IDS.YOURS]: PropTypes.number,
  [NOTEBOOKS_TABS_IDS.TEMPLATES]: PropTypes.number,
  [NOTEBOOKS_TABS_IDS.ARCHIVED]: PropTypes.number,
});

export const transform = (notebooksStats) => {
  if (!notebooksStats) {
    return null;
  }

  const statsByFilter = notebooksStats.reduce((res, stat) => {
    res[stat.filter] = stat.total;

    return res;
  }, {});

  return (
    {
      [NOTEBOOKS_TABS_IDS.ALL]: manageNumberAttribute(statsByFilter, 'active'),
      [NOTEBOOKS_TABS_IDS.YOURS]: manageNumberAttribute(statsByFilter, 'owner'),
      [NOTEBOOKS_TABS_IDS.TEMPLATES]: manageNumberAttribute(statsByFilter, 'template'),
      [NOTEBOOKS_TABS_IDS.ARCHIVED]: manageNumberAttribute(statsByFilter, 'archived'),
    }
  );
};

export const transformList = (notebooksStatsList) => notebooksStatsList.map((notebooksStats) => transform(notebooksStats));
