import PropTypes from 'prop-types';

import {
  manageFloatAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as PlanContentModel from '@palette/models/globalNotifStrategies/plan/PlanContent';

export const modelName = 'PlanInvalidPaymentPercentageErrorStrategy';

export const propTypes = PropTypes.exact({
  contentStrategy: PropTypes.string,
  plan: PlanContentModel.propTypes,
  paymentPercentage: PropTypes.number,
  reason: PropTypes.string,
});

export const transform = (strategy) => {
  if (!strategy) {
    return null;
  }

  return (
    {
      contentStrategy: 'PlanInvalidPaymentPercentageErrorContentStrategy',
      plan: PlanContentModel.transform(manageFreeShapeObjectAttribute(strategy, 'plan')),
      paymentPercentage: manageFloatAttribute(strategy, 'paymentPercentage'),
      reason: manageStringAttribute(strategy, 'reason'),
    }
  );
};

export const transformList = (strategies) => strategies.map((strategy) => transform(strategy));
