export const PROPERTY_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  ARRAY: 'ARRAY',
  OBJECT: 'OBJECT',
};

export const VARIABLE_NAME_REGEX = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

export const RAW_DATA_SEARCH_RESULTS_PATH_SEPARATOR = '||--||';

export const COLLAPSE_ICON_CONFIG = {
  iconWidth: 32,
  iconHeight: 32,
  iconStartDirection: 'down',
};

export const ADD_CTA_PADDING_LEFT = 20;

export const CHILDRENS_PADDING_LEFT = 24;

export const QUERY_FILTER_QS_KEY = 'queryFilter';

export const QUICK_SEARCH_QS_KEY = 'quickSearch';

export const REPORT_SLUG = 'ReportItem_';
