import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import MoreMenuCheckbox from '@palette/components/designSystem/MoreMenuCheckbox/MoreMenuCheckbox';
import BarsFilled from '@palette/components/utils/Icons/BarsFilled';
import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';

import { HIGHLIGHT_WIDGETS } from '@palette/constants/highlight';

import { swapListItemsPositions } from '@palette/helpers/CommonHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions } from '@palette/state/MasterPlans';

import styles from './MasterPlanHighlightZoneFilters.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanHighlightZoneFilters = ({ className, plan, isPeriodDetailsPage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fieldToTrack = useMemo(() => (isPeriodDetailsPage ? 'periodComponents' : 'planComponents'), [isPeriodDetailsPage]);

  const [filters, setFilters] = useState([...plan[fieldToTrack]]);

  const handleFilterChange = (filterName, filter) => {
    const newFilters = _cloneDeep(filters);

    if (filter.target.checked && !newFilters.includes(filterName)) {
      newFilters.push(filterName);
    } else if (!filter.target.checked && newFilters.includes(filterName)) {
      const filterPosition = newFilters.indexOf(filterName);
      newFilters.splice(filterPosition, 1);
    }

    setFilters(newFilters);
  };

  const handleFiltersDropdownClose = useCallback(() => {
    if (!_isEqual(filters, plan[fieldToTrack])) {
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, [fieldToTrack]: filters }));
    }
  }, [filters, plan, fieldToTrack]);

  const handleFiltersMove = useCallback((filterIndex, toPositionIndex) => {
    if (!filters[filterIndex]) return;
    setFilters(swapListItemsPositions(filters, filterIndex, toPositionIndex));
  }, [filters]);

  const getFilterItemData = (widget) => {
    const { type } = HIGHLIGHT_WIDGETS[widget];

    const valueDefinition = getPlanValueDefinition(plan);

    return {
      key: type,
      title: t(HIGHLIGHT_WIDGETS[widget].filterNameI18NId, { valueDefinition }),
      onChange: (filter) => handleFilterChange(type, filter),
      isChecked: filters.includes(type),
    };
  };

  const filtersItems = useMemo(() => {
    const finalFilters = [];

    // Filters in state (known and checked) - at the top of the list
    filters.map((widget) => finalFilters.push(getFilterItemData(widget)));

    // Other filters (get from constants)
    Object.keys(HIGHLIGHT_WIDGETS)
      .filter((widget) => !filters.includes(widget))
      .map((widget) => finalFilters.push(getFilterItemData(widget)));

    return finalFilters;
  }, [filters]);

  const actionButtonNode = useMemo(() => {
    if (!_isEqual(filters, plan[fieldToTrack])) {
      return (
        <>
          <ClosePopupLine className={styles.iconCloseFilter} width={18} height={18} />
          {t('highlightzone.widgets.filtersClose')}
        </>
      );
    }

    return (
      <>
        <BarsFilled className={styles.iconFilter} width={24} height={24} />
        {t('highlightzone.widgets.filters')}
      </>
    );
  }, [filters, plan, fieldToTrack]);

  const filtersNode = useMemo(() => (
    <MoreMenuCheckbox
      className={styles.filtersAction}
      items={filtersItems}
      onClose={handleFiltersDropdownClose}
      onMove={handleFiltersMove}
      placement="bottomRight"
    >
      {actionButtonNode}
    </MoreMenuCheckbox>
  ), [filtersItems, actionButtonNode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {filtersNode}
    </div>
  );
};

MasterPlanHighlightZoneFilters.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  isPeriodDetailsPage: PropTypes.bool,
};

MasterPlanHighlightZoneFilters.defaultProps = {
  className: '',
  isPeriodDetailsPage: false,
};

export default MasterPlanHighlightZoneFilters;
