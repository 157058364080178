import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import Link from '@palette/components/designSystem/Link/Link';
import BoxFilled from '@palette/components/utils/Icons/BoxFilled';

import routePaths from '@palette/config/routePaths';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './NotebooksBreadcrumb.less';

const classNames = bindClassNames.bind(styles);

const NotebooksBreadcrumb = ({ className, notebook }) => {
  const { t } = useTranslation();

  const currentLocationNode = useMemo(() => {
    if (notebook === null) return null;

    return (
      <div className={styles.currentLocationWrapper}>
        <BoxFilled className={styles.currentLocationIcon} width={16} height={16} />
        <div className={styles.currentLocationLabel}>
          {notebook.name}
        </div>
      </div>
    );
  }, [notebook]);

  if (notebook === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          path={routePaths.v2.notebooks}
        >
          <BoxFilled className={styles.linkIcon} width={16} height={16} />
          <div className={styles.linkLabel}>
            {t('notebooksBreadcrumb.notebooks')}
          </div>
        </Link>
        <ChevronRightLine className={styles.separator} width={20} height={20} />
      </div>
      {currentLocationNode}
    </div>
  );
};

NotebooksBreadcrumb.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes,
};

NotebooksBreadcrumb.defaultProps = {
  className: '',
  notebook: null,
};

export default NotebooksBreadcrumb;
