import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';

import styles from './RawDataDeleteModal.less';

const RawDataDeleteModal = ({ visible, onClose, resourceId, path }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resource = useSelector((state) => ResourcesSelectors.getResourceById(state, { resourceId }));

  const removePropertyIsPending = useSelector(ResourcesSelectors.removePropertyIsPending);

  const cleanAndClose = (event) => {
    onClose(event);
  };

  const handleRemoveProperty = (event) => {
    let finalPath = path;
    let value;

    // Modify path and pass the new value if the property to remove is inside an array
    const { data } = resource;
    const pathWithoutLastItem = path.slice(0, path.length - 1);
    const parentData = _get(data, pathWithoutLastItem);
    const hasParentAndParentIsArray = Array.isArray(parentData);

    if (hasParentAndParentIsArray) {
      const lastItemInPath = path.pop();
      const index = parentData.indexOf(parentData[lastItemInPath]);
      const finalValue = [...parentData];
      if (index >= 0) {
        finalValue.splice(index, 1);
      }
      value = finalValue;
      finalPath = pathWithoutLastItem;
    }

    dispatch(ResourcesActions.removeProperty({
      connectorId: resource.connectorId,
      objectId: resource.id,
      type: resource.originalType,
      fragments: finalPath,
      value,
    }));

    cleanAndClose(event);
  };

  return (
    <Modal
      className={styles.modal}
      title={t('rawData.modals.deleteProperty.title', { type: resource.type })}
      visible={visible}
      onCancel={(event) => cleanAndClose(event)}
      onOk={(event) => handleRemoveProperty(event)}
      okIcon={<TrashFilled />}
      okTheme="danger"
      okText={t('rawData.modals.deleteProperty.confirm')}
      loading={removePropertyIsPending}
      width="50%"
    >
      <div
        className={styles.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: t('rawData.modals.deleteProperty.content') }}
      />
    </Modal>
  );
};

RawDataDeleteModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  resourceId: PropTypes.string,
  path: PropTypes.array,
};

RawDataDeleteModal.defaultProps = {
  visible: false,
  onClose: () => {},
  resourceId: '',
  path: [],
};

export default RawDataDeleteModal;
