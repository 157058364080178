import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PlanV3EditBlockCell from '@palette/components/planV3/PlanV3EditBlockCell/PlanV3EditBlockCell';
import PlanV3PeriodsResults from '@palette/components/planV3/PlanV3PeriodsResults/PlanV3PeriodsResults';
import PlanV3AchievementsResults from '@palette/components/planV3/PlanV3AchievementsResults/PlanV3AchievementsResults';
import PlanV3AdvancedOptions from '@palette/components/planV3/PlanV3AdvancedOptions/PlanV3AdvancedOptions';

import { CELL_TYPE, COMMISSION_DOCUMENTATION_URL } from '@palette/constants/planV3';

import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './PlanV3EditCommissions.less';

const classNames = bindClassNames.bind(styles);

const PlanV3EditCommissions = ({ className, details, onCodeCellChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { planId, cells } = details;

  const cell = useMemo(() => cells.find((planCell) => planCell.type === CELL_TYPE.COMMISSIONS) ?? null, [cells]);

  const codeCellIsRunning = useSelector((state) => PlanV3Selectors.getRunCodeCellIsPending(state, { planId, codeCellId: cell?.id }));

  const goToDocumentation = () => window.open(COMMISSION_DOCUMENTATION_URL, '_blank');

  const [cellCode, setCellCode] = useState(cell?.code ?? '');

  useEffect(() => {
    setCellCode(cell?.code ?? '');
  }, [cell]);

  const handleCellCodeChange = useCallback((newContent) => {
    setCellCode(newContent);
    onCodeCellChange(newContent);
  }, [onCodeCellChange]);

  const handleRunCodeCell = useCallback(() => {
    if (cell === null) return;

    dispatch(PlanV3Actions.runCodeCell({ planId, codeCellId: cell.id, cellCode }));
  }, [planId, cell, cellCode]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <PlanV3EditBlockCell
        title={t('editPlanPage.commissions.title')}
        docLabel={t('editPlanPage.commissions.docLabel')}
        description={t('editPlanPage.commissions.description')}
        alertTitle={t('editPlanPage.commissions.alert.title')}
        alertContent="{ dealId, payeeId, amount, currency, date, payments : [{ date, percentage }], rule, dealAmount }"
        submitLabel={t('editPlanPage.commissions.runQuery')}
        handleDocClick={goToDocumentation}
        cellCode={cellCode}
        handleCellCodeChange={handleCellCodeChange}
        handleRunCodeCell={handleRunCodeCell}
        codeCellIsRunning={codeCellIsRunning}
        codeCellError={cell?.error}
        preview={cell?.preview ?? null}
        planId={planId}
        codeCellId={cell?.id ?? null}
      />
      <PlanV3PeriodsResults
        className={styles.resultsBlock}
        details={details}
      />
      <PlanV3AchievementsResults
        className={styles.resultsBlock}
        details={details}
      />
      <PlanV3AdvancedOptions
        className={styles.resultsBlock}
        planId={planId}
      />
    </div>
  );
};

PlanV3EditCommissions.propTypes = {
  className: PropTypes.string,
  details: PlanV3DetailModel.propTypes.isRequired,
  onCodeCellChange: PropTypes.func.isRequired,
};

PlanV3EditCommissions.defaultProps = {
  className: '',
};

export default PlanV3EditCommissions;
