import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddLine from '@palette/components/utils/Icons/AddLine';
import AddDataConnectionModal from '@palette/components/notebooks/AddDataConnectionModal/AddDataConnectionModal';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { NOTEBOOK_STATUSES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import styles from './AddDataConnectionButton.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionButton = ({ className, notebook }) => {
  const { t } = useTranslation();

  const profile = useProfile();

  const [addDataConnectionModalIsVisible, showAddDataConnectionModal] = useState(false);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE]) || notebook.status === NOTEBOOK_STATUSES.ARCHIVED) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        className={styles.addBtn}
        type="secondary"
        icon={<AddLine />}
        onClick={() => showAddDataConnectionModal(true)}
      >
        {t('addDataConnectionButton.label')}
      </Button>
      {
        addDataConnectionModalIsVisible && (
          <AddDataConnectionModal
            visible
            onClose={() => showAddDataConnectionModal(false)}
            notebook={notebook}
          />
        )
      }
    </div>
  );
};

AddDataConnectionButton.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
};

AddDataConnectionButton.defaultProps = {
  className: '',
};

export default AddDataConnectionButton;
