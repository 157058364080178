import PropTypes from 'prop-types';

import {
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { DASHBOARD_WIDGETS } from '@palette/constants/dashboard';

import * as DashboardWidgetParamsModel from '@palette/models/DashboardWidgetParams';

export const modelName = 'DashboardComponent';

export const propTypes = PropTypes.shape({
  type: PropTypes.string,
  params: DashboardWidgetParamsModel.propTypes,
});

export const manageDashboardComponentType = (dashboardComponent) => (DASHBOARD_WIDGETS[manageStringAttribute(dashboardComponent, 'type')]?.type || null);

export const transform = (dashboardComponent) => {
  if (!dashboardComponent) {
    return null;
  }

  return (
    {
      type: manageDashboardComponentType(dashboardComponent),
      params: DashboardWidgetParamsModel.transform(manageFreeShapeObjectAttribute(dashboardComponent, 'params')),
    }
  );
};

export const transformList = (dashboardComponents) => dashboardComponents.map((dashboardComponent) => transform(dashboardComponent));
