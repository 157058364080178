import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import PlanWidgetBase from '@palette/components/widgets/dashboard/PlanWidgetBase/PlanWidgetBase';

import { formatWidgetPrice } from '@palette/helpers/DashboardHelper';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as PlanTotalCommissionsDataModel from '@palette/models/widgets/dashboard/PlanTotalCommissionsData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';

import styles from './PlanTotalCommissionsWidget.less';

const classNames = bindClassNames.bind(styles);

const PlanTotalCommissionsWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planTotalCommissionsData = PlanTotalCommissionsDataModel.transform(widgetData.data);

  return (
    <PlanWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      useMostRecent={widgetData.dashboardComponent.params.useMostRecent}
      plan={planPeriodData.plan}
      period={planPeriodData.period}
      title={t('planTotalCommissionsWidget.title')}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      <div className={styles.amount}>
        {formatWidgetPrice(planTotalCommissionsData?.totalCommissionAmount, planTotalCommissionsData?.currency)}
      </div>
    </PlanWidgetBase>
  );
};

PlanTotalCommissionsWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanTotalCommissionsWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlanTotalCommissionsWidget;
