import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import InviteUserLink from '@palette/components/user/InviteUserLink/InviteUserLink';
import ResendInviteUserLink from '@palette/components/user/ResendInviteUserLink/ResendInviteUserLink';
import ClosePopupFilled from '@palette/components/utils/Icons/ClosePopupFilled';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import InfoCircleFilled from '@palette/components/utils/Icons/InfoCircleFilled';

import { getUserStatus } from '@palette/helpers/UserHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import { STATUSES } from '@palette/constants/user';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserStatus.less';

const classNames = bindClassNames.bind(styles);

const UserStatus = ({
  className,
  user,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();

  const contentNode = useMemo(() => {
    const userStatus = getUserStatus(user);

    if (userStatus === STATUSES.OFFBOARDED) {
      const now = moment.utc();
      const userLeaveDateMoment = moment.utc(user.leaveDate);

      let deactivatedLabel = t('userStatus.willBeDeactivated', { date: userLeaveDateMoment.format('LL') });
      if (userLeaveDateMoment.isSameOrBefore(now)) {
        deactivatedLabel = t('userStatus.wasDeactivated', { date: userLeaveDateMoment.format('LL') });
      }

      return (
        <div className={styles.deactivatedUserWrapper}>
          <ClosePopupFilled className={styles.deactivatedUserIcon} />
          <div className={styles.deactivatedUserLabel}>
            {deactivatedLabel}
          </div>
        </div>
      );
    }

    if (userStatus === STATUSES.NOT_INVITED) {
      return (
        <InviteUserLink className={styles.inviteLink} user={user} />
      );
    }

    if (userStatus === STATUSES.PENDING) {
      return (
        <div className={styles.pendingInvitationWrapper}>
          <div className={styles.pendingInvitationContainer}>
            <InfoCircleFilled className={styles.pendingInvitationIcon} />
            <div className={styles.pendingInvitationLabel}>
              {t('userStatus.invitationIsPending')}
            </div>
          </div>
          <ResendInviteUserLink className={styles.resendInviteLink} user={user} />
        </div>
      );
    }

    /* eslint-disable react/no-danger */
    return (
      <div className={styles.connectedUserWrapper}>
        <CheckFilled className={styles.connectedUserIcon} />
        <div
          className={styles.connectedUserLabel}
          dangerouslySetInnerHTML={{
            __html: user.account.role !== null ? t('userStatus.withRole', { roleName: user.account.role.name }) : t('userStatus.noRole'),
          }}
        />
      </div>
    );
    /* eslint-enable react/no-danger */
  }, [user]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

UserStatus.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserStatus.defaultProps = {
  className: '',
};

export default UserStatus;
