import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import FolderOpenFilled from '@palette/components/utils/Icons/FolderOpenFilled';

import { getSearch } from '@palette/helpers/NavigationHelper';

import { PALETTE_DOCUMENTATION_URL } from '@palette/constants/global';
import { PLAN_SETTINGS_TABS_IDS } from '@palette/constants/tabs';
import { ANALYTICS_DOC_SOURCES } from '@palette/constants/analytics';

import { selectors as NavigationSelectors } from '@palette/state/Navigation';
import { actions as AnalyticsActions } from '@palette/state/Analytics';

import styles from './MasterPlanSettingsDocumentationLink.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsDocumentationLink = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useSelector(NavigationSelectors.getCurrentLocation);

  const settingsTabLocation = useMemo(() => {
    const qsObj = getSearch(location);

    return qsObj.settingsTab || null;
  }, [location]);

  const [currentTab, setCurrentTab] = useState(settingsTabLocation);

  useEffect(() => {
    if (settingsTabLocation) {
      setCurrentTab(settingsTabLocation);
    }
  }, [settingsTabLocation]);

  const getLinkAndLabel = () => {
    let link = `${PALETTE_DOCUMENTATION_URL}plan/settings`;
    let label = t('masterPlanSettings.DocumentationGeneralLinkLabel');

    if (currentTab === PLAN_SETTINGS_TABS_IDS.COMMISSION_RULES) {
      link = `${PALETTE_DOCUMENTATION_URL}plan/rules`;
      label = t('masterPlanSettings.DocumentationPlanRulesLinkLabel');
    } else if (currentTab === PLAN_SETTINGS_TABS_IDS.PAYOUT_RULES) {
      link = `${PALETTE_DOCUMENTATION_URL}plan/payout-rules`;
      label = t('masterPlanSettings.DocumentationPayoutRulesLinkLabel');
    }

    return { link, label };
  };

  const handleClick = () => {
    const linkAndLabel = getLinkAndLabel();
    dispatch(AnalyticsActions.sendClickOnDocLink({ analyticsDocSource: ANALYTICS_DOC_SOURCES.PLAN_DETAILS_PAGE_SETTINGS, params: { link: linkAndLabel.link } }));
    window.open(linkAndLabel.link, '_blank');
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button className={styles.button} type="link" icon={<FolderOpenFilled />} onClick={handleClick}>
        {getLinkAndLabel().label}
      </Button>
    </div>
  );
};

MasterPlanSettingsDocumentationLink.propTypes = {
  className: PropTypes.string,
};

MasterPlanSettingsDocumentationLink.defaultProps = {
  className: '',
};

export default MasterPlanSettingsDocumentationLink;
