import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Collapse from '@palette/components/designSystem/Collapse/Collapse';
import PlanV3FormRequirementsUsersInputTable from '@palette/components/planV3/planV3FormItems/PlanV3FormRequirementsUsersInputTable/PlanV3FormRequirementsUsersInputTable';

import * as PlanV3ListUserModel from '@palette/models/PlanV3ListUser';

import styles from './PlanV3FormRequirementsUsersAdvancedTables.less';

const classNames = bindClassNames.bind(styles);

const PlanV3FormRequirementsUsersAdvancedTables = ({ className, planId, selectedUsers, setAfterPlanCreationCB }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => (
    <div className={styles.content}>
      <PlanV3FormRequirementsUsersInputTable planId={planId} selectedUsers={selectedUsers} setAfterPlanCreationCB={setAfterPlanCreationCB} />
    </div>
  ), [planId, selectedUsers, setAfterPlanCreationCB]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Collapse
        className={styles.collapse}
        panels={[{
          title: t('createPlanPage.inputTables.title'),
          content: contentNode,
        }]}
        bordered={false}
        ghost
      />
    </div>
  );
};

PlanV3FormRequirementsUsersAdvancedTables.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string,
  selectedUsers: PropTypes.arrayOf(PlanV3ListUserModel.propTypes).isRequired,
  setAfterPlanCreationCB: PropTypes.func,
};

PlanV3FormRequirementsUsersAdvancedTables.defaultProps = {
  className: '',
  planId: null,
  setAfterPlanCreationCB: null,
};

export default PlanV3FormRequirementsUsersAdvancedTables;
