import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form as AntDForm } from 'antd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import Input from '@palette/components/designSystem/Input/Input';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as NotebookModel from '@palette/models/Notebook';

import { actions as NotebooksActions, selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './CopyNotebookModal.less';

const CopyNotebookModal = ({ visible, onClose, notebook }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const copyNotebookIsPending = useSelector(NotebooksSelectors.copyNotebookIsPending);

  const [form] = AntDForm.useForm();

  const initialValues = useMemo(() => ({
    name: t('copyNotebookModal.copiedName', { notebookName: notebook.name }),
  }), [notebook]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const cleanAndClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, []);

  const onSuccessCB = useCallback((newNotebookId) => {
    redirectTo({ path: routePaths.v2.notebookDetails, params: { notebookId: newNotebookId } });
  }, []);

  const handleFinish = useCallback(({ name }) => {
    dispatch(NotebooksActions.copyNotebook({ notebookId: notebook.id, name, onSuccessCB }));
  }, [notebook, onSuccessCB]);

  const handleNewNotebook = () => form.submit();

  return (
    <Modal
      className={styles.modal}
      title={t('copyNotebookModal.title')}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleNewNotebook}
      okText={t('copyNotebookModal.copy')}
      loading={copyNotebookIsPending}
    >
      <Form onFinish={handleFinish} initialValues={initialValues} form={form}>
        <FormItem
          name="name"
          label={t('copyNotebookModal.form.name.label')}
          required
          rules={[
            { required: true, message: t('copyNotebookModal.form.name.rules.required') },
          ]}
        >
          <Input size="big" disabled={copyNotebookIsPending} />
        </FormItem>
      </Form>
    </Modal>
  );
};

CopyNotebookModal.propTypes = {
  notebook: NotebookModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

CopyNotebookModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default CopyNotebookModal;
