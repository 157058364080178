import { GLOBAL_NOTIF_THEMES, GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

export default {
  GLOBAL_ERROR_UNKNOWN: {
    code: 'global.error.unknown',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  GLOBAL_ERROR_BAD_REQUEST: {
    code: 'global.error.bad.request',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  GLOBAL_ERROR_UNAUTHORIZED: {
    code: 'global.error.unauthorized',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  GLOBAL_ERROR_INVALID_SESSION: {
    code: 'global.error.invalid.session',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  GLOBAL_ERROR_NOT_FOUND: {
    code: 'global.error.not.found',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  GLOBAL_ERROR_METHOD_NOT_ALLOWED: {
    code: 'global.error.method.not.allowed',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  GLOBAL_ERROR_SERVER_ERROR: {
    code: 'global.error.server.error',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
  GLOBAL_ERROR_MAINTENANCE_ENABLED: {
    code: 'global.error.maintenance.enabled',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.PERMANENT,
  },
  GLOBAL_ERROR_CONSTRAINT_VIOLATION: {
    code: 'global.error.constraint.violation',
    theme: GLOBAL_NOTIF_THEMES.ERROR,
    type: GLOBAL_NOTIF_TYPES.TEMPORARY,
  },
};
