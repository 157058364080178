import PropTypes from 'prop-types';

import { manageAmountAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'CommissionsUnreleasedData';

export const propTypes = PropTypes.shape({
  commissionsUnreleasedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
});

export const transform = (commissionsUnreleasedData) => {
  if (!commissionsUnreleasedData) {
    return null;
  }

  return (
    {
      commissionsUnreleasedAmount: manageAmountAttribute(commissionsUnreleasedData, 'commissionsWithoutPayments'),
      currency: manageStringAttribute(commissionsUnreleasedData, 'currency'),
    }
  );
};
