import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Link from '@palette/components/designSystem/Link/Link';

import { useConnectorsList } from '@palette/hooks/ConnectorHooks';

import routePaths from '@palette/config/routePaths';

import * as MetaUserModel from '@palette/models/MetaUser';

import styles from './UserLinkedResources.less';

const classNames = bindClassNames.bind(styles);

const UserLinkedResources = ({
  className,
  user,
}) => {
  const { t } = useTranslation();

  const connectorsList = useConnectorsList();

  if (user.resourceObjects.length === 0) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        user.resourceObjects.map((resourceObject) => {
          const resourceObjectConnector = connectorsList.find((connector) => (connector.id === resourceObject.connectorId));

          let prefix = resourceObjectConnector ? `[${resourceObjectConnector.name}` : '';
          if (user.resourceObjects.length > 1) {
            prefix = `${prefix} - ${resourceObject.type}`;
          }

          prefix = `${prefix}] `;

          return (
            <Link
              key={resourceObject.id}
              path={routePaths.v2.resourceDetails}
              params={{
                resourceId: resourceObject.id,
                connectorId: resourceObject.connectorId,
                type: resourceObject.originalType,
              }}
              displayIcon
            >
              {`${prefix}${t('usersPageContent.viewLinkedResource')}`}
            </Link>
          );
        })
      }
    </div>
  );
};

UserLinkedResources.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
};

UserLinkedResources.defaultProps = {
  className: '',
};

export default UserLinkedResources;
