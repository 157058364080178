import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import CheckFilled from '@palette/components/utils/Icons/CheckFilled';

import { selectors as ResourcesSelectors } from '@palette/state/Resources';

import styles from './RawDataHistoryRollbackModal.less';

const RawDataHistoryRollbackModal = ({ visible, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const rollbackIsPending = useSelector(ResourcesSelectors.rollbackIsPending);

  return (
    <Modal
      className={styles.modal}
      title={t('rawData.rollbackModal.title')}
      visible={visible}
      onCancel={() => onClose()}
      onOk={() => onConfirm()}
      okIcon={<CheckFilled />}
      okText={t('modal.confirm')}
      loading={rollbackIsPending}
      width="50%"
    >
      <div
        className={styles.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: t('rawData.rollbackModal.content') }}
      />
    </Modal>
  );
};

RawDataHistoryRollbackModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

RawDataHistoryRollbackModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onConfirm: () => {},
};

export default RawDataHistoryRollbackModal;
