import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import MyMasterPlanBreadcrumb from '@palette/components/ic/myMasterPlan/MyMasterPlanBreadcrumb/MyMasterPlanBreadcrumb';
import MyMasterPlanPeriodDetailsPageContent from '@palette/components/ic/myMasterPlan/MyMasterPlanPeriodDetailsPageContent/MyMasterPlanPeriodDetailsPageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useICMasterPlanPeriodInParams } from '@palette/hooks/MasterPlanHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getPlanPeriodName } from '@palette/helpers/MasterPlanHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './MyMasterPlanPeriodDetailsPage.less';

const MyMasterPlanPeriodDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { plan, year, periodId } = useICMasterPlanPeriodInParams();

  if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return null;

  const planName = plan?.name || '';
  const period = { year, period: periodId };
  const periodName = (plan !== null) ? getPlanPeriodName(plan, period) : '';

  return (
    <div className={styles.wrapper}>
      <MetaTag
        title={t('app.metas.myMasterPlanPeriodDetailsTitle', { planName, periodName })}
      />
      <div>
        <MyMasterPlanBreadcrumb className={styles.breadcrumb} plan={plan} period={period} />
        <PageTitle>
          <h3>
            {plan?.name || ''}
          </h3>
        </PageTitle>
      </div>
      <MyMasterPlanPeriodDetailsPageContent />
    </div>
  );
};

export default MyMasterPlanPeriodDetailsPage;
