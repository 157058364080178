import PropTypes from 'prop-types';

import {
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'MongoDetails';

export const propTypes = PropTypes.shape({
  mongoId: PropTypes.string,
  mongoCollection: PropTypes.string,
  CObjectConnector: PropTypes.string,
  CObjectType: PropTypes.string,
});

export const transform = (mongoDetails) => {
  if (!mongoDetails) {
    return null;
  }

  return ({
    mongoId: manageStringAttribute(mongoDetails, 'mongoId'),
    mongoCollection: manageStringAttribute(mongoDetails, 'mongoCollection'),
    CObjectConnector: manageStringAttribute(mongoDetails, 'CObjectConnector', null),
    CObjectType: manageStringAttribute(mongoDetails, 'CObjectType', null),
  });
};

export const transformList = (mongoDetailsList) => mongoDetailsList.map((mongoDetails) => transform(mongoDetails));
