import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { getUserTarget } from '@palette/helpers/QuotaHelper';

import { selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './QuotaTablePeriodTotalCell.less';

const classNames = bindClassNames.bind(styles);

const QuotaTablePeriodTotalCell = ({
  className,
  quotaUserIds,
  quotaId,
  year,
  periods,
}) => {
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));
  const quotaUsers = useSelector((state) => QuotasSelectors.getQuotaUsersByIdsAndQuotaId(state, { quotaId, quotaUserIds }));

  const totalTarget = useMemo(() => (
    periods.reduce((totalPeriods, period) => {
      const periodTotalTarget = quotaUsers.reduce((total, quotaUser) => {
        const userTarget = getUserTarget(quota, quotaUser, year, period);
        return total + userTarget.target;
      }, 0);

      return totalPeriods + periodTotalTarget;
    }, 0)
  ), [quotaUsers, quota, year, periods]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {comaSeparatorFormatter(floatToFixedNumber(totalTarget))}
    </div>
  );
};

QuotaTablePeriodTotalCell.propTypes = {
  className: PropTypes.string,
  quotaUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  quotaId: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  periods: PropTypes.arrayOf(PropTypes.number).isRequired,
};

QuotaTablePeriodTotalCell.defaultProps = {
  className: '',
};

export default QuotaTablePeriodTotalCell;
