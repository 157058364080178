import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormulaHelper from '@palette/components/formula/FormulaHelper/FormulaHelper';
import MasterPlanSettingsCommissionRulesSteps from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesSteps/MasterPlanSettingsCommissionRulesSteps';
import MasterPlanSettingsCommissionRulesDependencies from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesDependencies/MasterPlanSettingsCommissionRulesDependencies';
import MasterPlanSettingsCommissionRulesForm from '@palette/components/masterPlanSettings/MasterPlanSettingsCommissionRulesForm/MasterPlanSettingsCommissionRulesForm';
import WeCanHelp from '@palette/components/masterPlanSettings/WeCanHelp/WeCanHelp';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsCommissionRules.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCommissionRules = ({ className, planId }) => {
  const { t } = useTranslation();

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));

  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample, { plan });

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>{t('masterPlanSettingsCommissionRules.detailsTitle')}</div>

      <div className={styles.section}>
        <MasterPlanSettingsCommissionRulesSteps plan={plan} />
        <MasterPlanSettingsCommissionRulesDependencies plan={plan} />
      </div>

      <div className={styles.title}>{t('masterPlanSettingsCommissionRules.configurationTitle')}</div>

      <div className={styles.section}>
        <WeCanHelp />

        <FormulaHelper
          className={styles.formulaHelper}
          helperData={formulaHelperData}
        />

        <MasterPlanSettingsCommissionRulesForm
          plan={plan}
          helperData={formulaHelperData}
        />
      </div>
    </div>
  );
};

MasterPlanSettingsCommissionRules.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

MasterPlanSettingsCommissionRules.defaultProps = {
  className: '',
};

export default MasterPlanSettingsCommissionRules;
