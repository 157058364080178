import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import ProfilePageContent from '@palette/components/profile/ProfilePageContent/ProfilePageContent';

import styles from './ProfilePage.less';

const ProfilePage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.profileTitle')} />
      <PageTitle title={t('profilePage.page.title')} />
      <ProfilePageContent />
    </div>
  );
};

export default ProfilePage;
