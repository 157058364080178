import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import ComparatorPlanPeriodSelector from '@palette/components/masterPlanComparator/ComparatorPlanPeriodSelector/ComparatorPlanPeriodSelector';
import CompareFilled from '@palette/components/utils/Icons/CompareFilled';
import ComparatorPlanResults from '@palette/components/masterPlanComparator/ComparatorPlanResults/ComparatorPlanResults';

import { useComparatorPlanInSearch } from '@palette/hooks/MasterPlanHooks';

import {
  COMPARE_PLAN_FIRST_QS_KEY,
  COMPARE_PLAN_PERIOD_FIRST_QS_KEY,
  COMPARE_PLAN_SECOND_QS_KEY,
  COMPARE_PLAN_PERIOD_SECOND_QS_KEY,
} from '@palette/constants/masterPlans';

import styles from './MasterPlansComparatorPageContent.less';

const classNames = bindClassNames.bind(styles);

const MasterPlansComparatorPageContent = ({ className }) => {
  const [firstPlan, firstPlanPeriod] = useComparatorPlanInSearch(COMPARE_PLAN_FIRST_QS_KEY, COMPARE_PLAN_PERIOD_FIRST_QS_KEY);
  const [secondPlan, secondPlanPeriod] = useComparatorPlanInSearch(COMPARE_PLAN_SECOND_QS_KEY, COMPARE_PLAN_PERIOD_SECOND_QS_KEY);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.plansPeriodsSelectorWrapper}>
        <ComparatorPlanPeriodSelector
          title="A"
          className={styles.planPeriodSelector}
          planQSKey={COMPARE_PLAN_FIRST_QS_KEY}
          planPeriodQSKey={COMPARE_PLAN_PERIOD_FIRST_QS_KEY}
          plan={firstPlan}
          period={firstPlanPeriod}
        />
        <div className={styles.divider}>
          <CompareFilled className={styles.dividerIcon} width={80} height={80} />
        </div>
        <ComparatorPlanPeriodSelector
          title="B"
          className={styles.planPeriodSelector}
          planQSKey={COMPARE_PLAN_SECOND_QS_KEY}
          planPeriodQSKey={COMPARE_PLAN_PERIOD_SECOND_QS_KEY}
          plan={secondPlan}
          period={secondPlanPeriod}
        />
      </div>
      <ComparatorPlanResults
        className={styles.results}
        firstPlan={firstPlan}
        firstPeriod={firstPlanPeriod}
        secondPlan={secondPlan}
        secondPeriod={secondPlanPeriod}
      />
    </div>
  );
};

MasterPlansComparatorPageContent.propTypes = {
  className: PropTypes.string,
};

MasterPlansComparatorPageContent.defaultProps = {
  className: '',
};

export default MasterPlansComparatorPageContent;
