import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Alert from '@palette/components/designSystem/Alert/Alert';
import CommissionV3PaymentsList from '@palette/components/commissionV3/CommissionV3PaymentsList/CommissionV3PaymentsList';
import CommissionV3PaymentsSummary from '@palette/components/commissionV3/CommissionV3PaymentsSummary/CommissionV3PaymentsSummary';
import CommissionV3PaymentsEditPayoutSchedule from '@palette/components/commissionV3/CommissionV3PaymentsEditPayoutSchedule/CommissionV3PaymentsEditPayoutSchedule';

import { getCommissionV3Amount } from '@palette/helpers/CommissionV3Helper';

import { ALERT_TYPES } from '@palette/constants/alert';

import * as CommissionV3DetailsCommissionModel from '@palette/models/CommissionV3DetailsCommission';

import styles from './CommissionV3DrawerPaymentsTab.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3DrawerPaymentsTab = ({ className, commission }) => {
  const { t } = useTranslation();

  const commissionAmount = getCommissionV3Amount(commission);

  let contentNode = (
    <Alert
      type={ALERT_TYPES.WARNING}
      message={t('commissionDrawerPaymentsTab.noScheduledPayments')}
    />
  );

  if (commissionAmount === 0) {
    contentNode = (
      <Alert
        type={ALERT_TYPES.INFO}
        message={t('commissionDrawerPaymentsTab.noCommissionToPay')}
      />
    );
  }

  if (commission.payments.length) {
    contentNode = (
      <>
        <CommissionV3PaymentsList commission={commission} />
        <CommissionV3PaymentsSummary className={styles.summary} commission={commission} />
      </>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      <CommissionV3PaymentsEditPayoutSchedule className={styles.editPayoutSchedule} commission={commission} />
    </div>
  );
};

CommissionV3DrawerPaymentsTab.propTypes = {
  className: PropTypes.string,
  commission: CommissionV3DetailsCommissionModel.propTypes.isRequired,
};

CommissionV3DrawerPaymentsTab.defaultProps = {
  className: '',
};

export default CommissionV3DrawerPaymentsTab;
