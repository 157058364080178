import bindClassNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import NoUserTypeConnectorsAlert from '@palette/components/connector/NoUserTypeConnectorsAlert/NoUserTypeConnectorsAlert';
import Select from '@palette/components/designSystem/Select/Select';
import Table from '@palette/components/designSystem/Table/Table';
import UpdateUserTypeConfirmationModal from '@palette/components/masterPlan/UpdateUserTypeConfirmationModal/UpdateUserTypeConfirmationModal';
import MasterPlanEditUserDateCell from '@palette/components/masterPlanSettings/MasterPlanEditUserDateCell/MasterPlanEditUserDateCell';
import MasterPlanSettingsUsersUserHeader from '@palette/components/masterPlanSettings/MasterPlanSettingsUsersUserHeader/MasterPlanSettingsUsersUserHeader';
import UserConnectorsSelect from '@palette/components/user/UserConnectorsSelect/UserConnectorsSelect';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import DeleteMasterPlanUserButton from '@palette/components/masterPlan/DeleteMasterPlanUserButton/DeleteMasterPlanUserButton';

import { isActiveUserFilter } from '@palette/helpers/MasterPlanHelper';

import { SCOPES, SETTINGS_USERS_FILTER } from '@palette/constants/masterPlans';
import { EMPTY_DEFAULT_VALUE } from '@palette/constants/usersDefinition';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsUsers.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsUsers = ({ className, planId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [updateUserTypeConfirmationModalIsVisible, showUpdateUserTypeConfirmationModal] = useState(false);

  const [connectorIdAndType, setConnectorIdAndType] = useState(null);

  const [filter, setFilter] = useState(SETTINGS_USERS_FILTER.ACTIVE);

  const connectorsList = useSelector(ConnectorsSelectors.getConnectorsWithUsersResources);

  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: planId }));

  const planUsersAndManagers = useSelector((state) => MasterPlansSelectors.getMasterPlanUsersAndManagers(state, { masterPlanId: planId }));

  useEffect(() => {
    if (plan !== null) {
      dispatch(MasterPlansActions.getPlanUsersAndManagers({ planId: plan.id }));
    }
  }, [plan]);

  const displayUsersManagementNodes = useMemo(() => {
    if (plan === null) return false;

    return plan.usersDefinition?.type !== 'default';
  }, [plan]);

  const userConnectorValue = useMemo(() => {
    if (!displayUsersManagementNodes) return null;

    if (connectorIdAndType !== null) return connectorIdAndType;

    return {
      connectorId: plan.usersDefinition?.connectorId,
      type: plan.usersDefinition?.type,
    };
  }, [displayUsersManagementNodes, plan, connectorIdAndType]);

  const updateUserType = (connectorId, type) => {
    dispatch(MasterPlansActions.updatePlanUsersDefinition({ planId: plan.id, connectorId, type }));
  };

  const handleCancelUpdateUserType = useCallback(() => {
    setConnectorIdAndType(null);
    showUpdateUserTypeConfirmationModal(false);
  });

  const handleUpdateUserType = useCallback(() => {
    if (connectorIdAndType !== null) {
      updateUserType(connectorIdAndType.connectorId, connectorIdAndType.type);
    }
    showUpdateUserTypeConfirmationModal(false);
  }, [connectorIdAndType]);

  const handleSelectConnector = useCallback((value, connectorId, type) => {
    if (planUsersAndManagers.managers.length > 0 || planUsersAndManagers.users.length > 0) {
      setConnectorIdAndType({ connectorId, type });
      showUpdateUserTypeConfirmationModal(true);
    } else {
      updateUserType(connectorId, type);
    }
  }, [planUsersAndManagers]);

  const filterOptions = Object.values(SETTINGS_USERS_FILTER).map((userFilter) => ({
    label: t(`masterPlanSettingsUsers.filter.${userFilter}`),
    value: userFilter,
  }));

  const userSelectorNode = useMemo(() => {
    if (plan.usersDefinition?.originalType !== EMPTY_DEFAULT_VALUE) return null;

    return (
      <>
        <div className={styles.connectorsDescription}>
          {t('masterPlanSettingsUsers.connectorsDescription')}
        </div>
        <UserConnectorsSelect
          className={styles.connectorsSelect}
          onSelect={handleSelectConnector}
          value={userConnectorValue}
        />
      </>
    );
  }, [plan]);

  const filterNode = useMemo(() => {
    if (!displayUsersManagementNodes) return null;

    return (
      <div className={styles.filterWrapper}>
        <Select
          className={styles.selectFilter}
          options={filterOptions}
          value={filter}
          onSelect={setFilter}
        />
        <div className={styles.filterDescription}>
          {t('masterPlanSettingsUsers.filterDescription')}
        </div>
      </div>
    );
  }, [plan, displayUsersManagementNodes, filter, setFilter]);

  const filteredManagers = useMemo(() => {
    if (filter === SETTINGS_USERS_FILTER.ALL) return planUsersAndManagers.managers;

    return planUsersAndManagers.managers.filter(isActiveUserFilter);
  }, [planUsersAndManagers, filter]);

  const filteredUsers = useMemo(() => {
    if (filter === SETTINGS_USERS_FILTER.ALL) return planUsersAndManagers.users;

    return planUsersAndManagers.users.filter(isActiveUserFilter);
  }, [planUsersAndManagers, filter]);

  const getTableColumns = useCallback((forManagers = false) => {
    if (plan === null) return [];

    return [
      {
        id: 'user',
        Header: (<MasterPlanSettingsUsersUserHeader plan={plan} forManagers={forManagers} />),
        accessor: 'user',
        minWidth: 200,
        width: '20%',
        disableHeaderCellComponent: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<UserProfile user={value} clickable />),
      },
      {
        id: 'startDate',
        Header: t('masterPlanSettingsUsers.table.headers.startDate'),
        accessor: (user) => user,
        minWidth: 250,
        width: '25%',
        disableBodyCellComponent: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<MasterPlanEditUserDateCell plan={plan} user={value} forManagers={forManagers} />),
      },
      {
        id: 'endDate',
        Header: t('masterPlanSettingsUsers.table.headers.endDate'),
        accessor: (user) => user,
        minWidth: 250,
        width: '25%',
        disableBodyCellComponent: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (<MasterPlanEditUserDateCell plan={plan} user={value} manageEndDate forManagers={forManagers} />),
      },
      {
        id: 'removeUser',
        accessor: 'user',
        minWidth: 50,
        width: '5%',
        disableBodyCellComponent: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <DeleteMasterPlanUserButton
            planId={planId}
            user={value}
            className={styles.actionButton}
            forManagers={forManagers}
          />
        ),
      },
    ];
  }, [plan]);

  const managersTableNode = useMemo(() => {
    if (plan === null || !displayUsersManagementNodes || plan.scope !== SCOPES.MANAGER) return null;

    const columns = getTableColumns(true);

    return (
      <Table className={classNames({ tables: true, managersTable: true })} type="borderless" columns={columns} data={filteredManagers} />
    );
  }, [plan, displayUsersManagementNodes, getTableColumns, filteredManagers]);

  const usersTableNode = useMemo(() => {
    if (plan === null || !displayUsersManagementNodes) return null;

    const columns = getTableColumns();

    return (
      <Table className={classNames({ tables: true, usersTable: true })} type="borderless" columns={columns} data={filteredUsers} />
    );
  }, [plan, displayUsersManagementNodes, getTableColumns, filteredUsers]);

  if (connectorsList.length === 0) {
    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        <NoUserTypeConnectorsAlert />
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {userSelectorNode}
      {filterNode}
      {managersTableNode}
      {usersTableNode}
      {
        updateUserTypeConfirmationModalIsVisible && (
          <UpdateUserTypeConfirmationModal visible onCancel={handleCancelUpdateUserType} onConfirm={handleUpdateUserType} />
        )
      }
    </div>
  );
};

MasterPlanSettingsUsers.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
};

MasterPlanSettingsUsers.defaultProps = {
  className: '',
};

export default MasterPlanSettingsUsers;
