import {
  call,
  put,
  all,
  take,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas/slice';
import { actions as MasterPlansActions } from '@palette/state/MasterPlans';
import { manageError as manageQuotasError } from '@palette/state/Quotas/errors';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';
import { selectors as NavigationSelectors } from '@palette/state/Navigation/slice';

import { getSearch, redirectTo } from '@palette/helpers/NavigationHelper';

import { QUOTA_EVENTS } from '@palette/constants/analytics';
import { QUOTA_ACTIONS } from '@palette/constants/quotas';
import { QUOTA_LIST_TABS_IDS, QUOTA_LIST_TABS_QS_KEY } from '@palette/constants/tabs';

import * as QuotasService from '@palette/services/QuotasService';

import * as QuotaModel from '@palette/models/Quota';

import apiConfig from '@palette/config/api';
import routePaths from '@palette/config/routePaths';

export function* getListAndCurrent({ payload = {} }) {
  const { quotaId, archived = false } = payload;

  yield put(QuotasActions.getList({ archived }));

  yield take(QuotasActions.getListCompleted.type);

  const listIds = yield select(QuotasSelectors.getList);

  if (listIds.length > 0) {
    const finalQuotaId = quotaId ?? listIds[0];
    const { search } = yield select(NavigationSelectors.getCurrentLocation);
    const filter = getSearch({ search })[QUOTA_LIST_TABS_QS_KEY] ?? QUOTA_LIST_TABS_IDS.ACTIVE;
    redirectTo({
      path: routePaths.v2.quotaDetails,
      params: { quotaId: finalQuotaId },
      qsObject: { [QUOTA_LIST_TABS_QS_KEY]: filter },
    });
  } else {
    yield put(QuotasActions.getListAndCurrentCompleted());
  }
}

export function* getList({ payload = {} }) {
  const { archived = false } = payload;

  const callResult = yield call(QuotasService.list, { archived });

  if (callResult.ok) {
    const quotas = QuotaModel.transformList(callResult.data);
    yield put(QuotasActions.setList({ quotas }));
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.getListCompleted());
}

export function* getById({ payload = {} }) {
  const { quotaId } = payload;

  const callResult = yield call(QuotasService.getById, { quotaId });

  if (callResult.ok) {
    const quota = QuotaModel.transform(callResult.data);
    yield put(QuotasActions.setById({ quota }));
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.getByIdCompleted());
}

export function* createQuota({ payload = {} }) {
  const {
    name,
    type,
    periodType,
    frequency,
    defaultValue,
    beginAt = undefined,
    global = false,
    onSuccessCB,
  } = payload;

  const callResult = yield call(QuotasService.createQuota, {
    name,
    type,
    periodType,
    frequency,
    defaultValue,
    beginAt,
    global,
  });

  if (callResult.ok) {
    const quota = QuotaModel.transform(callResult.data);
    yield put(QuotasActions.setById({ quota }));

    yield put(QuotasActions.getList());

    yield take(QuotasActions.getListCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_EVENTS.CREATED, params: { quotaId: quota.id } } });

    onSuccessCB(quota.id);
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.createQuotaCompleted());
}

export function* addUsersToQuota({ payload = {} }) {
  const { quota, usersToAdd, onSuccessCB } = payload;

  const quotaId = quota.id;

  const callResult = yield call(QuotasService.addUsersToQuota, {
    quotaId,
    usersToAdd,
  });

  if (callResult.ok) {
    onSuccessCB();

    yield put(QuotasActions.getById({ quotaId }));

    yield take(QuotasActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_EVENTS.ADD_USERS, params: { quotaId } } });
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.addUsersToQuotaCompleted());
}

export function* updateQuota({ payload = {} }) {
  const {
    quotaId,
    name,
    type,
    defaultValues,
    action = QUOTA_ACTIONS.UPDATE_QUOTA_DEFAULT_VALUES,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(QuotasService.updateQuota, {
    quotaId,
    name,
    type,
    defaultValues,
  });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield put(QuotasActions.getById({ quotaId }));

    yield take(QuotasActions.getByIdCompleted.type);

    const event = action === QUOTA_ACTIONS.UPDATE_QUOTA_DEFAULT_VALUES ? QUOTA_EVENTS.EDIT_DEFAULT_VALUES : QUOTA_EVENTS.EDIT_NAME;

    yield call(analyticsSendEvent, { payload: { event, params: { quotaId } } });
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.updateQuotaCompleted());
}

export function* updateUserFromQuota({ payload = {} }) {
  const {
    quotaId,
    quotaUser,
    overwrite = null,
    defaultValues = null,
    lastPeriod = null,
    action = QUOTA_ACTIONS.UPDATE_USER_DEFAULT_VALUES,
    onSuccessCB = null,
  } = payload;

  const callResult = yield call(QuotasService.updateUserFromQuota, {
    quotaId,
    quotaUser,
    overwrite,
    defaultValues,
    lastPeriod,
  });

  if (callResult.ok) {
    yield put(QuotasActions.getById({ quotaId }));

    yield take(QuotasActions.getByIdCompleted.type);

    let eventName;
    switch (action) {
      case QUOTA_ACTIONS.UPDATE_USER_OVERWRITE:
        eventName = QUOTA_EVENTS.EDIT_OVERWRITE_USER_TARGET;
        break;
      case QUOTA_ACTIONS.UPDATE_USER_LAST_PERIOD:
        eventName = QUOTA_EVENTS.EDIT_USER_LAST_PERIOD;
        break;
      case QUOTA_ACTIONS.RESET_USER_OVERWRITE:
        eventName = QUOTA_EVENTS.EDIT_RESET_USER_TARGET;
        break;
      case QUOTA_ACTIONS.UPDATE_USER_DEFAULT_VALUES:
      default:
        eventName = QUOTA_EVENTS.EDIT_USER_DEFAULT_VALUES;
        break;
    }

    yield call(analyticsSendEvent, { payload: { event: eventName, params: { quotaId, userId: quotaUser.user.id } } });

    const beingEditedPlanId = yield select(QuotasSelectors.beingEditedPlanId);
    if (beingEditedPlanId !== null) {
      yield put(MasterPlansActions.getById({ planId: beingEditedPlanId }));
      yield take(MasterPlansActions.getByIdCompleted.type);
    }

    if (onSuccessCB !== null) {
      onSuccessCB(quotaId);
    }
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.updateUserFromQuotaCompleted());
}

export function* listQuotasByUser({ payload = {} }) {
  const { user } = payload;

  const callResult = yield call(QuotasService.listQuotasByUser, { user });

  if (callResult.ok) {
    const quotas = QuotaModel.transformList(callResult.data);
    yield put(QuotasActions.setListByUser({ userId: user.id, quotas }));
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.listQuotasByUserCompleted());
}

export function* deleteById({ payload = {} }) {
  const { quotaId, onSuccessCB } = payload;

  const callResult = yield call(QuotasService.deleteById, { quotaId });

  if (callResult.ok) {
    yield put(QuotasActions.getList());

    yield take(QuotasActions.getListCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_EVENTS.DELETED, params: { quotaId } } });

    onSuccessCB(quotaId);
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.deleteByIdCompleted());
}

export function* removeUserFromQuota({ payload = {} }) {
  const { quotaId, quotaUser, onSuccessCB = null } = payload;

  const callResult = yield call(QuotasService.removeUserFromQuota, {
    quotaId,
    quotaUser,
  });

  if (callResult.ok) {
    if (onSuccessCB !== null) {
      onSuccessCB(quotaId);
    }

    yield put(QuotasActions.getById({ quotaId }));

    yield take(QuotasActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: QUOTA_EVENTS.REMOVE_USER, params: { quotaId } } });
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.removeUserFromQuotaCompleted());
}

export function* downloadQuota({ payload = {} }) {
  const { quotaId, year } = payload;

  const callData = {
    quotaId,
    year,
  };

  const callResult = yield call(QuotasService.downloadQuota, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageQuotasError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(QuotasActions.downloadQuotaCompleted());
}

export function* loop() {
  yield all([
    takeLatest(QuotasActions.getListAndCurrent.type, getListAndCurrent),
    takeLatest(QuotasActions.getList.type, getList),
    takeLatest(QuotasActions.getById.type, getById),
    takeLatest(QuotasActions.createQuota.type, createQuota),
    takeLatest(QuotasActions.addUsersToQuota.type, addUsersToQuota),
    takeLatest(QuotasActions.updateQuota.type, updateQuota),
    takeLatest(QuotasActions.updateUserFromQuota.type, updateUserFromQuota),
    takeLatest(QuotasActions.listQuotasByUser.type, listQuotasByUser),
    takeLatest(QuotasActions.deleteById.type, deleteById),
    takeLatest(QuotasActions.removeUserFromQuota.type, removeUserFromQuota),
    takeLatest(QuotasActions.downloadQuota.type, downloadQuota),
  ]);
}

export function* clean() {
  yield put(QuotasActions.resetToInitialState());
}
