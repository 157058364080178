import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ContainerOutlined as AntDContainerOutlined } from '@ant-design/icons';

import MoreMenu from '@palette/components/designSystem/MoreMenu/MoreMenu';
import DeleteQuotaModal from '@palette/components/quota/DeleteQuotaModal/DeleteQuotaModal';
import DownloadFilled from '@palette/components/utils/Icons/DownloadFilled';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import * as QuotaModel from '@palette/models/Quota';

import { actions as QuotasActions } from '@palette/state/Quotas';

import styles from './QuotaMoreOptionsMenu.less';

const classNames = bindClassNames.bind(styles);

const QuotaMoreOptionsMenu = ({ className, quota, year }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const [deleteQuotaModalIsVisible, showDeleteQuotaModal] = useState(false);

  const handleExport = useCallback(() => {
    /**
     * setTimeout is used here to wait until the dropdown is closed,
     * to avoid to have the menuItem still actived after the click event.
     * 300 is used related to the @animation-duration-slow set from antd.
     */
    setTimeout(() => {
      dispatch(QuotasActions.downloadQuota({
        quotaId: quota.id,
        year,
      }));
    }, 300);
  }, [quota, year]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) return null;

  let deleteMenuItem = {
    key: 'deleteQuota',
    title: t('quota.deleteQuota'),
    icon: (
      <TrashFilled />
    ),
    iconClassName: styles.iconMenuItem,
    onClick: () => showDeleteQuotaModal(true),
  };

  let modalTitle = t('quota.deleteQuota.title');
  let modalContent = t('quota.deleteQuota.content', { quotaName: quota.name });

  if (quota.plans.length > 0) {
    deleteMenuItem = {
      key: 'archiveQuota',
      title: t('quota.archiveQuota'),
      icon: (
        <AntDContainerOutlined />
      ),
      iconClassName: styles.iconMenuItem,
      onClick: () => showDeleteQuotaModal(true),
    };
    modalTitle = t('quota.archiveQuota.title');
    modalContent = t('quota.archiveQuota.content', { quotaName: quota.name });
  }

  const exportQuotaItem = {
    key: 'exportAll',
    title: t('quota.exportQuota'),
    icon: (
      <DownloadFilled />
    ),
    iconClassName: styles.iconMenuItem,
    onClick: handleExport,
  };

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <MoreMenu
        items={[
          deleteMenuItem,
          exportQuotaItem,
        ]}
      />
      {
        deleteQuotaModalIsVisible && (
          <DeleteQuotaModal visible onClose={() => showDeleteQuotaModal(false)} quota={quota} title={modalTitle} content={modalContent} />
        )
      }
    </div>
  );
};

QuotaMoreOptionsMenu.propTypes = {
  className: PropTypes.string,
  quota: QuotaModel.propTypes.isRequired,
  year: PropTypes.number.isRequired,
};

QuotaMoreOptionsMenu.defaultProps = {
  className: '',
};

export default QuotaMoreOptionsMenu;
