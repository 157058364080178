import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import StatementV3PeriodCardItem from '@palette/components/statementV3/StatementV3PeriodCardItem/StatementV3PeriodCardItem';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import routePaths from '@palette/config/routePaths';

import * as StatementV3ListPeriodModel from '@palette/models/StatementV3ListPeriod';

import styles from './StatementV3PeriodsCardsListItem.less';

const classNames = bindClassNames.bind(styles);

const StatementV3PeriodsCardsListItem = ({
  className,
  statementPeriod,
  isSelected,
  inUserStatement,
  statementPeriodId,
}) => {
  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  let paramStatementId = statementPeriod.periodId;

  if (inUserStatement) {
    const [statementId] = statementPeriodId.split('-');
    paramStatementId = `${statementId}-${paramStatementId}`;
  }

  return (
    <Link
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      path={inUserStatement ? routePaths.v2.statementV3UserStatement : routePaths.v2.statementV3Period}
      params={{ statementPeriodId: paramStatementId }}
      qsObject={periodsDatesFilterQSObject}
      disabled={isSelected}
    >
      <StatementV3PeriodCardItem statementPeriod={statementPeriod} isSelected={isSelected} inUserStatement={inUserStatement} />
    </Link>
  );
};

StatementV3PeriodsCardsListItem.propTypes = {
  className: PropTypes.string,
  statementPeriod: StatementV3ListPeriodModel.propTypes.isRequired,
  isSelected: PropTypes.bool,
  inUserStatement: PropTypes.bool,
  statementPeriodId: PropTypes.string,
};

StatementV3PeriodsCardsListItem.defaultProps = {
  className: '',
  isSelected: false,
  inUserStatement: false,
  statementPeriodId: null,
};

export default StatementV3PeriodsCardsListItem;
