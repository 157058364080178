import apisauce from 'apisauce';
import Qs from 'qs';

import { getSessionId } from '@palette/helpers/SessionHelper';

import apiConfig from '@palette/config/api';

export const ApiService = apisauce.create({
  baseURL: apiConfig.BASE_URL,
  timeout: apiConfig.TIMEOUT,
  paramsSerializer: (params) => (
    Qs.stringify(params, { arrayFormat: 'brackets' })
  ),
});

export const getConfig = () => ({
  headers: {
    sessionId: getSessionId(),
  },
});
