import { NOTIF_CLEANUP_DELAY, TEMPORARY_FADEOUT_DELAY } from '@palette/constants/globalNotif';

import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

export const delayedRemovalGlobalNotifMiddleware = (store) => (next) => (action) => {
  if (action.type === GlobalNotifActions.addTemporaryGlobalNotif.type) {
    const timeout = setTimeout(() => {
      store.dispatch({ type: GlobalNotifActions.fadeOutTemporaryGlobalNotifFromUI.type });
    }, TEMPORARY_FADEOUT_DELAY);

    next(action);

    return () => clearTimeout(timeout);
  }

  if (action.type === GlobalNotifActions.fadeOutTemporaryGlobalNotifFromUI.type) {
    const timeout = setTimeout(() => {
      store.dispatch({ type: GlobalNotifActions.hideTemporaryGlobalNotifFromUI.type });
    }, NOTIF_CLEANUP_DELAY);

    next(action);

    return () => clearTimeout(timeout);
  }

  if (action.type === GlobalNotifActions.fadeOutPermanentGlobalNotifFromUI.type) {
    const timeout = setTimeout(() => {
      store.dispatch({ type: GlobalNotifActions.removePermanentGlobalNotif.type, payload: action.payload.id });
    }, NOTIF_CLEANUP_DELAY);

    next(action);

    return () => clearTimeout(timeout);
  }

  return next(action);
};
