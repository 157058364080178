import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';
import { TextAreaRefForwarded as TextArea } from '@palette/components/designSystem/TextArea/TextArea';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';
import { RIGHTS } from '@palette/constants/profile';
import { QUOTA_PERFORMANCE_ACTIONS, QUOTA_PERFORMANCE_UPDATABLE_FIELDS } from '@palette/constants/quotasPerformance';

import * as QuotaPerformanceModel from '@palette/models/QuotaPerformance';

import { actions as QuotasPerformanceActions, selectors as QuotasPerformanceSelectors } from '@palette/state/QuotasPerformance';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif';

import styles from './EditableDashboardField.less';

const classNames = bindClassNames.bind(styles);

const EditableDashboardField = ({
  className,
  dashboard,
  type,
  field,
  emptyText,
  isRequired,
}) => {
  const profile = useProfile();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [editing, setEditing] = useState(false);
  const [dashboardField, setDashboardField] = useState(dashboard[field]);

  useEffect(() => {
    setDashboardField(dashboard[field]);
  }, [dashboard[field]]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const updateDashboardIsPending = useSelector(QuotasPerformanceSelectors.updateDashboardIsPending);

  useEffect(() => {
    if (!updateDashboardIsPending) {
      setEditing(false);
    }
  }, [updateDashboardIsPending]);

  const saveUpdate = () => {
    if (dashboard[field] !== dashboardField.trim()) {
      if (!isRequired || dashboardField.trim() !== '') {
        dispatch(QuotasPerformanceActions.updateDashboard({
          quotaPerformanceId: dashboard.id,
          name: dashboard.name,
          description: dashboard.description,
          [field]: dashboardField.trim(),
          action: field === QUOTA_PERFORMANCE_UPDATABLE_FIELDS.NAME
            ? QUOTA_PERFORMANCE_ACTIONS.UPDATE_QUOTA_PERFORMANCE_NAME
            : QUOTA_PERFORMANCE_ACTIONS.UPDATE_QUOTA_PERFORMANCE_DESCRIPTION,
        }));
      } else {
        dispatch(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.QUOTA_PERFORMANCE_FIELD_REQUIRED));
        setEditing(false);
      }
    } else {
      setEditing(false);
    }
  };

  const handleInputChange = (newValue) => {
    setDashboardField(newValue);
  };

  const switchToEditMode = () => {
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) {
      setEditing(true);
    }
  };

  const contentNode = useMemo(() => {
    if (editing) {
      switch (type) {
        case 'textarea':
          return (
            <TextArea
              ref={inputRef}
              className={classNames({
                fieldInput: true,
                textarea: true,
              })}
              value={dashboardField}
              onChange={handleInputChange}
              onBlur={saveUpdate}
              autoSize={{ minRows: 2 }}
              disableResize
            />
          );
        default:
          return (
            <Input
              ref={inputRef}
              className={classNames({
                fieldInput: true,
                input: true,
              })}
              type="text"
              value={dashboardField}
              onChange={handleInputChange}
              onBlur={saveUpdate}
              onPressEnter={saveUpdate}
            />
          );
      }
    }

    return (
      <div className={styles.fieldContainer} onClick={switchToEditMode}>
        {dashboard[field] && (
          <div
            className={classNames({
              field: true,
              title: field === QUOTA_PERFORMANCE_UPDATABLE_FIELDS.NAME,
            })}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: dashboard[field] }}
          />
        )}
        {!dashboard[field] && <span className={styles.emptyText}>{emptyText}</span>}
      </div>
    );
  }, [editing, type, inputRef, dashboardField, dashboard, field, emptyText]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

EditableDashboardField.propTypes = {
  className: PropTypes.string,
  dashboard: QuotaPerformanceModel.propTypes.isRequired,
  field: PropTypes.oneOf(Object.values(QUOTA_PERFORMANCE_UPDATABLE_FIELDS)).isRequired,
  type: PropTypes.oneOf(['input', 'textarea']),
  emptyText: PropTypes.string,
  isRequired: PropTypes.bool,
};

EditableDashboardField.defaultProps = {
  className: '',
  type: 'input',
  emptyText: '',
  isRequired: false,
};

export default EditableDashboardField;
