import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MasterPlansListSelect from '@palette/components/masterPlan/MasterPlansListSelect/MasterPlansListSelect';
import Select from '@palette/components/designSystem/Select/Select';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import Link from '@palette/components/designSystem/Link/Link';
import PlanWhatIfProjectionChart from '@palette/components/charts/PlanWhatIfProjectionChart/PlanWhatIfProjectionChart';
import MasterPlanPeriodProgressDetails from '@palette/components/masterPlan/MasterPlanPeriodProgressDetails/MasterPlanPeriodProgressDetails';

import {
  getPlanPeriodName,
  yearPeriodToString,
} from '@palette/helpers/MasterPlanHelper';

import { PERIODS_FILTER_TYPE, SCOPES } from '@palette/constants/masterPlans';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './ComparatorPlanPeriodSelector.less';

const classNames = bindClassNames.bind(styles);

const ComparatorPlanPeriodSelector = ({
  className,
  title,
  planQSKey,
  planPeriodQSKey,
  plan,
  period,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnChange = useCallback((newPlanId) => {
    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: { [planQSKey]: newPlanId }, keysToDelete: [planPeriodQSKey] }));
  }, [planQSKey, planPeriodQSKey]);

  const getPlanPeriodsIsPending = useSelector(MasterPlansSelectors.getPlanPeriodsIsPending);
  const periods = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriods(state, { masterPlanId: plan?.id || null }));

  useEffect(() => {
    if (plan !== null) {
      dispatch(MasterPlansActions.getPlanPeriods({
        planId: plan.id,
        periodsFilterType: PERIODS_FILTER_TYPE.ALL_TIMES,
      }));
    }
  }, [plan]);

  useEffect(() => {
    if (period === null && periods.length > 0) {
      const lastPeriod = periods[periods.length - 1];
      const lastPeriodId = yearPeriodToString(lastPeriod);
      dispatch(NavigationActions.addQSToLocation({ qsObject: { [planPeriodQSKey]: lastPeriodId } }));
    }
  }, [period, periods]);

  const handlePeriodChange = useCallback((newPeriodId) => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [planPeriodQSKey]: newPeriodId } }));
  }, [planPeriodQSKey]);

  const periodsSelectNode = useMemo(() => {
    if (plan === null) return null;

    if (periods.length === 0) {
      return (
        <div className={styles.noPeriodsInPlan}>
          {t('comparatorPlanPeriodSelector.noPeriodsInPlan')}
        </div>
      );
    }

    const periodsOptions = periods.map((planPeriod) => ({
      label: getPlanPeriodName(plan, planPeriod),
      value: yearPeriodToString(planPeriod),
    }));

    return (
      <Select
        className={styles.select}
        placeholder={t('comparatorPlanPeriodSelector.periodSelect.placeholder')}
        options={periodsOptions}
        value={period !== null ? yearPeriodToString(period) : null}
        onChange={handlePeriodChange}
        pendingOptions={periodsOptions.length === 0 && getPlanPeriodsIsPending}
      />
    );
  }, [plan, period, periods, handlePeriodChange, getPlanPeriodsIsPending]);

  const planNameNode = useMemo(() => {
    if (plan === null) return null;

    return (
      <Link
        className={styles.planName}
        path={routePaths.v2.planDetails}
        params={{
          masterPlanId: plan.id,
        }}
      >
        {plan.name}
      </Link>
    );
  }, [plan]);

  const periodNameNode = useMemo(() => {
    if (plan === null || period === null) return null;

    const periodName = getPlanPeriodName(plan, period);
    return (
      <Link
        className={styles.periodName}
        path={routePaths.v2.planPeriodDetails}
        params={{
          masterPlanId: plan.id,
          year: period.year,
          periodId: period.period,
        }}
      >
        {periodName}
      </Link>
    );
  }, [plan, period]);

  const periodData = useMemo(() => {
    if (plan === null || period === null) return null;

    return periods.find((planPeriod) => (
      planPeriod.year === period.year
      && planPeriod.period === period.period
    )) || null;
  }, [
    plan,
    period,
    periods,
  ]);

  const usersCountNode = useMemo(() => {
    if (plan === null || periodData === null) return null;

    return (
      <div className={styles.usersCountWrapper}>
        <div className={styles.usersCount}>
          {plan.scope === SCOPES.MANAGER ? periodData.managersCount : periodData.usersCount}
        </div>
        <TeamFilled className={styles.usersCountIcon} />
      </div>
    );
  }, [plan, periodData]);

  const progressNode = useMemo(() => {
    if (plan === null || periodData === null) return null;

    return (
      <MasterPlanPeriodProgressDetails
        className={styles.progressDetailsWrapper}
        plan={plan}
        period={periodData}
      />
    );
  }, [
    plan,
    periodData,
  ]);

  const projectionNode = useMemo(() => {
    if (
      plan === null
      || period === null
      || plan.quotaId === null
      || plan.estimationDefinition === null
      || plan.estimationDefinition.referenceDealId === null
    ) {
      return null;
    }

    return (
      <div className={styles.previewProjectionWrapper}>
        <h3>
          {t('comparatorPlanPeriodSelector.previewProjection.title')}
        </h3>
        <div className={styles.projectionChartWrapper}>
          <PlanWhatIfProjectionChart
            plan={plan}
            period={period}
            forAdmin
          />
        </div>
      </div>
    );
  }, [
    plan,
    period,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <h2>
        {title}
      </h2>
      <MasterPlansListSelect
        className={styles.select}
        placeholder={t('comparatorPlanPeriodSelector.planSelect.placeholder')}
        value={plan?.id || null}
        onChange={handleOnChange}
      />
      {periodsSelectNode}
      {planNameNode}
      <div className={styles.periodUsersCount}>
        {periodNameNode}
        {usersCountNode}
      </div>
      {progressNode}
      {projectionNode}
    </div>
  );
};

ComparatorPlanPeriodSelector.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  planQSKey: PropTypes.string.isRequired,
  planPeriodQSKey: PropTypes.string.isRequired,
  plan: MasterPlanModel.propTypes,
  period: YearPeriodModel.propTypes,
};

ComparatorPlanPeriodSelector.defaultProps = {
  className: '',
  plan: null,
  period: null,
};

export default ComparatorPlanPeriodSelector;
