import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageBooleanAttribute,
  manageArrayAttribute,
} from '@palette/helpers/ModelHelper';

import { FORMATTERS_VALUES } from '@palette/constants/masterPlans';

export const modelName = 'ResourceColumn';

export const propTypes = PropTypes.shape({
  name: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string),
  formatter: PropTypes.string,
  evalFn: PropTypes.string,
  displayInResources: PropTypes.bool,
  displayInPlans: PropTypes.bool,
});

export const manageFormatter = (resourceColumn) => (FORMATTERS_VALUES[manageStringAttribute(resourceColumn, 'formatter')] || FORMATTERS_VALUES.String);

export const transform = (resourceColumn) => {
  if (!resourceColumn) {
    return null;
  }

  return (
    {
      name: manageStringAttribute(resourceColumn, 'name'),
      fields: manageArrayAttribute(resourceColumn, 'fields'),
      formatter: manageFormatter(resourceColumn),
      evalFn: manageStringAttribute(resourceColumn, 'evalFn'),
      displayInResources: manageBooleanAttribute(resourceColumn, 'resources', true),
      displayInPlans: manageBooleanAttribute(resourceColumn, 'plans', true),
    }
  );
};

export const transformList = (resourceColumns) => resourceColumns.map((resourceColumn) => transform(resourceColumn));
