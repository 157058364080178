import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import Loader from '@palette/components/utils/Loader/Loader';
import ChevronRightLine from '@palette/components/utils/Icons/ChevronRightLine';
import ResourceObjectsListTable from '@palette/components/resources/ResourceObjectsListTable/ResourceObjectsListTable';
import ResourcesContentDetailsActions from '@palette/components/resources/ResourcesContentDetailsActions/ResourcesContentDetailsActions';

import { getResourceColumns } from '@palette/helpers/ConnectorHelper';
import { scrollToTop } from '@palette/helpers/NavigationHelper';
import { buildSearchFilter } from '@palette/helpers/QueryBuilderHelper';
import { manageAdditionalProperties } from '@palette/helpers/FormulaHelper';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useLimitInSearch, usePageInSearch } from '@palette/hooks/NavigationHooks';
import { useQueryFilterInSearch, useQuickSearchInSearch } from '@palette/hooks/ResourceHooks';

import {
  DEFAULT_LIMIT_QS_VALUE,
  DEFAULT_PAGE_QS_VALUE,
  LIMIT_QS_KEY,
  MAIN_CONTENT_ID,
  PAGE_QS_KEY,
} from '@palette/constants/navigation';
import { AND_OPERATOR, INITIAL_STRINGIFIED_QUERY_BUILDER_VALUE } from '@palette/constants/queryBuilder';

import { actions as ConnectorsActions, selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './ResourcesContentDetails.less';

const classNames = bindClassNames.bind(styles);

const ResourcesContentDetails = ({ className, currentConnectorId, currentOriginalType }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const isPending = useSelector(ConnectorsSelectors.getResourceObjectsIsPending);
  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById((state), { connectorId: currentConnectorId }));
  const pagination = useSelector(ConnectorsSelectors.getResourceObjectsPagination);
  const resourceObjects = useSelector((state) => ConnectorsSelectors.getResourceObjectsByConnectorAndType(state, {
    connectorId: currentConnectorId,
    type: currentOriginalType,
  }));

  const [getPaginationLimit, paginationLimitInSearch] = useLimitInSearch();
  const [getPaginationPage, paginationPageInSearch] = usePageInSearch();
  const [quickSearchFromSearch] = useQuickSearchInSearch();
  const [queryFilterFromSearch] = useQueryFilterInSearch();

  const getResourceObjects = useCallback(() => {
    if (!connector || !currentConnectorId || !currentOriginalType) return;

    const columns = getResourceColumns(
      connector,
      currentOriginalType,
      (column) => column.displayInResources,
    );

    dispatch(ConnectorsActions.getResourceObjects({
      connectorId: currentConnectorId,
      type: currentOriginalType,
      isPaginated: true,
      page: getPaginationPage,
      limit: getPaginationLimit,
      quickSearch: quickSearchFromSearch,
      filter: JSON.stringify({
        [AND_OPERATOR]: [
          ...(queryFilterFromSearch && queryFilterFromSearch !== INITIAL_STRINGIFIED_QUERY_BUILDER_VALUE ? [JSON.parse(queryFilterFromSearch)] : []),
          ...(quickSearchFromSearch ? [buildSearchFilter(quickSearchFromSearch, columns)] : []),
        ],
      }),
    }));
  }, [
    connector,
    currentConnectorId,
    currentOriginalType,
    getPaginationPage,
    getPaginationLimit,
    quickSearchFromSearch,
    queryFilterFromSearch,
  ]);

  useEffect(() => {
    getResourceObjects();
  }, [
    currentConnectorId,
    currentOriginalType,
    getPaginationPage,
    getPaginationLimit,
    quickSearchFromSearch,
    queryFilterFromSearch,
  ]);

  useEffect(() => {
    const keysToDelete = [];

    if (getPaginationPage === DEFAULT_PAGE_QS_VALUE && paginationPageInSearch) {
      keysToDelete.push(PAGE_QS_KEY);
    }

    if (getPaginationLimit === DEFAULT_LIMIT_QS_VALUE && paginationLimitInSearch) {
      keysToDelete.push(LIMIT_QS_KEY);
    }

    if (keysToDelete.length) {
      dispatch(NavigationActions.updateAndCleanQSInLocation({ keysToDelete }));
    }
  }, [
    getPaginationPage,
    paginationPageInSearch,
    getPaginationLimit,
    paginationLimitInSearch,
  ]);

  useEffect(() => {
    if (!isPending) {
      scrollToTop(true, document.getElementById(MAIN_CONTENT_ID));
    }
  }, [isPending]);

  const handlePageChange = useCallback((page) => {
    const QSToAdd = { [PAGE_QS_KEY]: page };

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd }));
  }, []);

  const handleLimitChange = useCallback((limit) => {
    const QSToAdd = { [LIMIT_QS_KEY]: limit };
    const keysToDelete = [PAGE_QS_KEY];

    dispatch(NavigationActions.updateAndCleanQSInLocation({ qsObject: QSToAdd, keysToDelete }));
  }, []);

  const contentNode = useMemo(() => {
    if (!connector) return null;

    let mainContent;

    const resource = connector.resources.filter((connectorResource) => connectorResource.originalType === currentOriginalType);
    const { type = '' } = resource[0] ?? {};
    const { data = {} } = resourceObjects[0] ?? {};
    const helperData = manageAdditionalProperties(currentOriginalType, data, { userCurrency: profile.userData.company.currency });
    const columns = getResourceColumns(
      connector,
      currentOriginalType,
      (column) => column.displayInResources,
    );

    if (!resourceObjects || resourceObjects.length === 0) {
      if (isPending) {
        mainContent = <Loader />;
      } else {
        mainContent = <DefaultEmptyState description={t('resources.index.main.empty.text')} />;
      }
    } else {
      mainContent = (
        <Loader spinning={isPending}>
          <ResourceObjectsListTable
            connectorId={connector.id}
            resourceObjects={resourceObjects}
            columns={columns}
            pagination={pagination}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
          />
        </Loader>
      );
    }

    return (
      <>
        <div className={styles.header}>
          <div className={styles.infos}>
            {connector.name}
            {type && (
              <>
                <ChevronRightLine className={styles.icon} width={32} height={32} />
                {type}
              </>
            )}
          </div>
          <div className={styles.actions}>
            {type && (
              <ResourcesContentDetailsActions
                quickSearchFromSearch={quickSearchFromSearch}
                queryFilterFromSearch={queryFilterFromSearch}
                helperData={helperData}
              />
            )}
          </div>
        </div>
        <div className={styles.content}>
          {mainContent}
        </div>
      </>
    );
  }, [
    profile,
    connector,
    resourceObjects,
    isPending,
    pagination,
    quickSearchFromSearch,
    queryFilterFromSearch,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

ResourcesContentDetails.propTypes = {
  className: PropTypes.string,
  currentConnectorId: PropTypes.string,
  currentOriginalType: PropTypes.string,
};

ResourcesContentDetails.defaultProps = {
  className: '',
  currentConnectorId: null,
  currentOriginalType: null,
};

export default ResourcesContentDetails;
