import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import PlanV3InputTable from '@palette/components/planV3/PlanV3InputTable/PlanV3InputTable';

import { selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import * as PlanV3InputTableModel from '@palette/models/PlanV3InputTable';

import styles from './PlanV3AdvancedOptionsInputTable.less';

const classNames = bindClassNames.bind(styles);

const PlanV3AdvancedOptionsInputTable = ({ className, planId, inputTable }) => {
  const createInputTableIsPending = useSelector(PlanV3Selectors.getCreateInputTableIsPending);

  const tableColumns = useMemo(() => {
    if (!inputTable) return [];

    return inputTable.columns.map((column) => ({
      id: column.id,
      Header: column.label,
      accessor: column.id,
      minWidth: 150,
      cellType: column.type,
    }));
  }, [inputTable]);

  const tableData = useMemo(() => {
    if (!inputTable) return [];

    return inputTable.rows.map((row) => row.values.reduce((res, rowValue) => {
      res[rowValue.columnId] = rowValue.value;

      return res;
    }, {}));
  }, [inputTable]);

  const tableNode = useMemo(() => {
    if (inputTable == null || createInputTableIsPending) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <PlanV3InputTable
        columns={tableColumns}
        data={tableData}
        stickyHeader
        fitInContainer
        planId={planId}
        inputTable={inputTable}
      />
    );
  }, [
    inputTable,
    createInputTableIsPending,
    tableColumns,
    tableData,
    planId,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {tableNode}
    </div>
  );
};

PlanV3AdvancedOptionsInputTable.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
  inputTable: PlanV3InputTableModel.propTypes.isRequired,
};

PlanV3AdvancedOptionsInputTable.defaultProps = {
  className: '',
};

export default PlanV3AdvancedOptionsInputTable;
