import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import * as UserVariableModel from '@palette/models/UserVariable';
import * as MetaUserModel from '@palette/models/MetaUser';
import * as UserVariableOverrideItemModel from '@palette/models/UserVariableOverrideItem';

import { actions as UsersVariablesActions } from '@palette/state/UsersVariables';

import styles from './DeleteVariableValueHistoryItem.less';

const classNames = bindClassNames.bind(styles);

const DeleteVariableValueHistoryItem = ({ className, user, userVariable, override }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteVariableValueHistoryItem = useCallback(() => {
    dispatch(UsersVariablesActions.deleteVariableValueOverride({ userId: user.id, userVariableId: userVariable.id, overrideId: override.id }));
  }, [user, userVariable, override]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Popconfirm
        title={t('deleteVariableValueHistoryItem.deleteItem.popconfirm')}
        onConfirm={handleDeleteVariableValueHistoryItem}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
      >
        <Button
          className={styles.deleteBtn}
          icon={(<TrashFilled className={styles.deleteBtnIcon} width={16} height={16} />)}
        />
      </Popconfirm>
    </div>
  );
};

DeleteVariableValueHistoryItem.propTypes = {
  className: PropTypes.string,
  user: MetaUserModel.propTypes.isRequired,
  userVariable: UserVariableModel.propTypes.isRequired,
  override: UserVariableOverrideItemModel.propTypes.isRequired,
};

DeleteVariableValueHistoryItem.defaultProps = {
  className: '',
};

export default DeleteVariableValueHistoryItem;
