import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import HighlightWidgetBase from '@palette/components/widgets/highlight/HighlightWidgetBase/HighlightWidgetBase';
import CommissionsTotalWidgetContent from '@palette/components/widgetContents/CommissionsTotalWidgetContent/CommissionsTotalWidgetContent';

import * as HighlightComponentDataModel from '@palette/models/HighlightComponentData';
import * as CommissionsTotalDataModel from '@palette/models/widgets/highlight/CommissionsTotalData';

import styles from './HighlightCommissionsTotalWidget.less';

const classNames = bindClassNames.bind(styles);

const HighlightCommissionsTotalWidget = ({ className, widgetData }) => {
  const { t } = useTranslation();

  const commissionsTotalData = CommissionsTotalDataModel.transform(widgetData.data);

  return (
    <HighlightWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('highlightCommissionsTotalWidget.title')}
    >
      <CommissionsTotalWidgetContent data={commissionsTotalData} />
    </HighlightWidgetBase>
  );
};

HighlightCommissionsTotalWidget.propTypes = {
  className: PropTypes.string,
  widgetData: HighlightComponentDataModel.propTypes.isRequired,
};

HighlightCommissionsTotalWidget.defaultProps = {
  className: '',
};

export default HighlightCommissionsTotalWidget;
