import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import Lottie from 'lottie-react';

import { LOTTIES, LOTTIES_ANIMATIONS } from '@palette/constants/lotties';

import styles from './LottieAnimation.less';

const classNames = bindClassNames.bind(styles);

const LottieAnimation = ({
  className,
  lottieId,
  loop,
  autoplay,
  onClick,
  ...otherProps
}, ref = {}) => {
  let refProp = {};
  if (Object.keys(ref).length !== 0) {
    refProp = {
      lottieRef: ref,
    };
  }

  let onClickProp = {};
  if (onClick !== null) {
    onClickProp = {
      onClick,
    };
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...onClickProp}
    >
      <Lottie
        {...refProp}
        animationData={LOTTIES_ANIMATIONS[lottieId]}
        loop={loop}
        autoplay={autoplay}
        height="100%"
        width="100%"
        {...otherProps}
      />
    </div>
  );
};

/* eslint-disable react/no-unused-prop-types */
const propTypesShape = {
  className: PropTypes.string,
  lottieId: PropTypes.oneOf(Object.values(LOTTIES)).isRequired,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultPropsShape = {
  className: '',
  loop: true,
  autoplay: true,
  onClick: null,
};

LottieAnimation.propTypes = propTypesShape;
LottieAnimation.defaultProps = defaultPropsShape;

export const LottieAnimationRefForwarded = forwardRef((props, ref) => LottieAnimation(props, ref));
LottieAnimationRefForwarded.propTypes = propTypesShape;
LottieAnimationRefForwarded.defaultProps = defaultPropsShape;

export default LottieAnimation;
