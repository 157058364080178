import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'ListPlansData';

export const propTypes = PropTypes.shape({
  plans: PropTypes.arrayOf(PropTypes.shape({
    plan: MasterPlanModel.propTypes,
    period: YearPeriodModel.propTypes,
    totalCommissionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
  })),
});

export const manageListPlans = (plansData) => plansData.map((planData) => ({
  plan: MasterPlanModel.transform(planData),
  period: YearPeriodModel.transform(manageFreeShapeObjectAttribute(planData, 'lastPeriod')),
  totalCommissionAmount: manageAmountAttribute(planData.lastPeriod || {}, 'totalCommissionAmount'),
  currency: manageStringAttribute(planData.lastPeriod || {}, 'currency'),
}));

export const transform = (listPlansData) => {
  if (!listPlansData) {
    return null;
  }

  return (
    {
      plans: manageListPlans(manageArrayAttribute(listPlansData, 'plans')),
    }
  );
};
