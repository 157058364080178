import {
  call,
  put,
  all,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { actions as UsersActions } from '@palette/state/Users/slice';
import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';
import { manageError as manageUsersError } from '@palette/state/Users/errors';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';
import { addGlobalNotif } from '@palette/state/GlobalNotif/sagas';

import { USER_EVENTS } from '@palette/constants/analytics';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as UsersService from '@palette/services/UsersService';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as UserCurrencyModel from '@palette/models/UserCurrency';
import * as OnboardUserModel from '@palette/models/OnboardUser';
import * as MergeUserConflictModel from '@palette/models/MergeUserConflict';

export function* getList() {
  const callResult = yield call(UsersService.list);

  if (callResult.ok) {
    const users = MetaUserModel.transformList(callResult.data);
    yield put(UsersActions.setList({ users }));
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.getListCompleted());
}

export function* getListInPlans() {
  const callResult = yield call(UsersService.listInPlans);

  if (callResult.ok) {
    const usersInPlans = MetaUserModel.transformList(callResult.data);
    yield put(UsersActions.setListInPlans({ usersInPlans }));
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.getListInPlansCompleted());
}

export function* getCurrenciesList() {
  const callResult = yield call(UsersService.currenciesList);

  if (callResult.ok) {
    const currencies = UserCurrencyModel.transformList(callResult.data);
    yield put(UsersActions.setCurrenciesList({ currencies }));
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.getCurrenciesListCompleted());
}

export function* inviteUser({ payload = {} }) {
  const {
    userId,
    email,
    roleId,
    hireDate = null,
    teamIds,
    dashboardPresetId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    userId,
    email,
    roleId,
    hireDate,
    teamIds,
    dashboardPresetId,
  };

  const callResult = yield call(UsersService.inviteUser, callData);

  if (callResult.ok) {
    yield put(UsersActions.getList());
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_INVITATION_SUCCESS));
    if (onSuccessCB !== null) onSuccessCB(userId);
    yield call(analyticsSendEvent, { payload: { event: USER_EVENTS.INVITE_USER, params: { userId } } });
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.inviteUserCompleted());
}

export function* resendUserInvitation({ payload = {} }) {
  const {
    userId,
  } = payload;

  const callData = {
    userId,
  };

  const callResult = yield call(UsersService.resendUserInvitation, callData);

  if (callResult.ok) {
    yield put(UsersActions.getList());
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_INVITATION_SUCCESS));
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.resendUserInvitationCompleted());
}

export function* getUserById({ payload = {} }) {
  const {
    userId,
  } = payload;

  const callData = {
    userId,
  };

  const callResult = yield call(UsersService.getUserById, callData);

  if (callResult.ok) {
    const user = MetaUserModel.transform(callResult.data);
    yield put(UsersActions.setUser({ user }));
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.getUserByIdCompleted());
}

export function* onboardUsers({ payload = {} }) {
  const {
    users,
    onSuccessCB = null,
  } = payload;

  const callData = {
    users,
  };

  const callResult = yield call(UsersService.onboardUsers, callData);

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB();

    yield call(analyticsSendEvent, { payload: { event: USER_EVENTS.ONBOARD_USERS_COMPLETED } });

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_ONBOARDING_SUCCESS));

    yield put(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: null }));
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.onboardUsersCompleted());
}

export function* getUserResourcesCommonData({ payload = {} }) {
  const {
    resourceObjects,
    onSuccessCB = null,
  } = payload;

  const callData = {
    resourceObjects,
  };

  const callResult = yield call(UsersService.getUserResourcesCommonData, callData);

  if (callResult.ok) {
    const users = OnboardUserModel.transformList(callResult.data);
    yield put(UsersActions.setOnboardCurrentUsers({ users }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.getUserResourcesCommonDataCompleted());
}

export function* updateUser({ payload = {} }) {
  const {
    userId,
    firstName = undefined,
    lastName = undefined,
    email = undefined,
    currency = undefined,
    payrollId = undefined,
    joinDate = null,
  } = payload;

  const callData = {
    userId,
    firstName,
    lastName,
    email,
    currency,
    payrollId,
    joinDate,
  };

  const callResult = yield call(UsersService.updateUser, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_UPDATE_SUCCESS));

    yield put(UsersActions.getUserById({ userId }));
    yield take(UsersActions.getUserByIdCompleted.type);

    const profile = yield select(ProfileSelectors.profile);
    if (profile.userData.id === userId) {
      yield put(ProfileActions.getProfile());
      yield take(ProfileActions.getProfileCompleted.type);
    }
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.updateUserCompleted());
}

export function* updateUserAccount({ payload = {} }) {
  const {
    userId,
    email = undefined,
    role = undefined,
  } = payload;

  const callData = {
    userId,
    email,
    role,
  };

  const callResult = yield call(UsersService.updateUserAccount, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_UPDATE_SUCCESS));

    yield put(UsersActions.getUserById({ userId }));
    yield take(UsersActions.getUserByIdCompleted.type);
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.updateUserAccountCompleted());
}

export function* deactivateUser({ payload = {} }) {
  const {
    userId,
    onSuccessCB = null,
  } = payload;

  const callData = {
    userId,
  };

  const callResult = yield call(UsersService.deactivateUser, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_DEACTIVATE_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB();

    yield call(analyticsSendEvent, { payload: { event: USER_EVENTS.DEACTIVATE_USER, params: { userId } } });

    yield put(UsersActions.getUserById({ userId }));
    yield take(UsersActions.getUserByIdCompleted.type);
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.deactivateUserCompleted());
}

export function* unlinkResourceFromUser({ payload = {} }) {
  const {
    userId,
    resourceObjectId,
  } = payload;

  const callData = {
    userId,
    resourceObjectId,
  };

  const callResult = yield call(UsersService.unlinkResourceFromUser, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_UNLINK_RESOURCE_SUCCESS));

    yield call(analyticsSendEvent, { payload: { event: USER_EVENTS.UNLINK_RESOURCE_FROM_USER, params: { userId, resourceObjectId } } });

    yield put(UsersActions.getUserById({ userId }));
    yield take(UsersActions.getUserByIdCompleted.type);
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.unlinkResourceFromUserCompleted());
}

export function* linkResourcesToUser({ payload = {} }) {
  const {
    userId,
    resourcesToLink,
    onSuccessCB = null,
  } = payload;

  const calls = [];
  resourcesToLink.forEach((resourceToLink) => {
    const callData = {
      userId,
      resourceToLink,
    };

    calls.push(call(UsersService.linkResourceToUser, callData));
  });

  const callResults = yield all(calls);

  if (callResults.every((callResult) => (callResult.ok))) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_LINK_RESOURCE_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB();

    yield call(analyticsSendEvent, { payload: { event: USER_EVENTS.LINK_RESOURCES_TO_USER, params: { userId, resourceObjectIds: resourcesToLink.map((resourceToLink) => (resourceToLink.id)) } } });

    yield put(UsersActions.getUserById({ userId }));
    yield take(UsersActions.getUserByIdCompleted.type);
  } else {
    const callResultsInError = callResults.filter((callResult) => (!callResult.ok));
    const errorsCalls = [];
    callResultsInError.forEach((callResult) => {
      const error = manageUsersError(callResult);
      errorsCalls.push(call(addGlobalNotif, { payload: error }));
    });
    yield all(errorsCalls);
  }

  yield put(UsersActions.linkResourcesToUserCompleted());
}

export function* offboardUser({ payload = {} }) {
  const {
    userId,
    leaveDate,
    adjustmentReason,
    adjustmentAmount,
    adjustmentCurrency,
    onSuccessCB = null,
  } = payload;

  const callData = {
    userId,
    leaveDate,
    adjustmentReason,
    adjustmentAmount,
    adjustmentCurrency,
  };

  const callResult = yield call(UsersService.offboardUser, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_OFFBOARDING_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB();

    yield call(analyticsSendEvent, { payload: { event: USER_EVENTS.OFFBOARDING_APPROVED, params: { userId } } });

    yield put(UsersActions.getUserById({ userId }));
    yield take(UsersActions.getUserByIdCompleted.type);
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.offboardUserCompleted());
}

export function* resetLeaveDate({ payload = {} }) {
  const {
    userId,
  } = payload;

  const callData = {
    userId,
    leaveDate: null,
  };

  const callResult = yield call(UsersService.updateUser, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_UPDATE_SUCCESS));

    yield put(UsersActions.getUserById({ userId }));
    yield take(UsersActions.getUserByIdCompleted.type);

    const profile = yield select(ProfileSelectors.profile);
    if (profile.userData.id === userId) {
      yield put(ProfileActions.getProfile());
      yield take(ProfileActions.getProfileCompleted.type);
    }
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.resetLeaveDateCompleted());
}

export function* updateUserProfilePicture({ payload = {} }) {
  const {
    logo,
    user,
    onSuccessCB = null,
  } = payload;

  const callData = {
    userId: user.id,
    profilePicUrl: logo,
  };

  const callResult = yield call(UsersService.updateUser, callData);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.USER_UPDATE_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB();

    yield put(UsersActions.getUserById({ userId: user.id }));
    yield take(UsersActions.getUserByIdCompleted.type);

    const profile = yield select(ProfileSelectors.profile);
    if (profile.userData.id === user.id) {
      yield put(ProfileActions.getProfile());
      yield take(ProfileActions.getProfileCompleted.type);
    }
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.updateUserProfilePictureCompleted());
}

export function* getMergeConflicts({ payload = {} }) {
  const {
    firstUserId,
    secondUserId,
  } = payload;

  const callData = {
    firstUserId,
    secondUserId,
  };

  const callResult = yield call(UsersService.getMergeConflicts, callData);

  if (callResult.ok) {
    const mergeUserConflits = MergeUserConflictModel.transform(callResult.data);
    yield put(UsersActions.setMergeUserConflits({ mergeUserConflits }));
  } else {
    const error = manageUsersError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(UsersActions.getMergeConflictsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(UsersActions.getList.type, getList),
    takeLatest(UsersActions.getListInPlans.type, getListInPlans),
    takeLatest(UsersActions.getCurrenciesList.type, getCurrenciesList),
    takeLatest(UsersActions.inviteUser.type, inviteUser),
    takeLatest(UsersActions.resendUserInvitation.type, resendUserInvitation),
    takeEvery(UsersActions.getUserById.type, getUserById),
    takeLatest(UsersActions.getUserResourcesCommonData.type, getUserResourcesCommonData),
    takeLatest(UsersActions.onboardUsers.type, onboardUsers),
    takeLatest(UsersActions.updateUser.type, updateUser),
    takeLatest(UsersActions.updateUserAccount.type, updateUserAccount),
    takeLatest(UsersActions.deactivateUser.type, deactivateUser),
    takeLatest(UsersActions.unlinkResourceFromUser.type, unlinkResourceFromUser),
    takeLatest(UsersActions.linkResourcesToUser.type, linkResourcesToUser),
    takeLatest(UsersActions.offboardUser.type, offboardUser),
    takeLatest(UsersActions.resetLeaveDate.type, resetLeaveDate),
    takeLatest(UsersActions.updateUserProfilePicture.type, updateUserProfilePicture),
    takeLatest(UsersActions.getMergeConflicts.type, getMergeConflicts),
  ]);
}

export function* clean() {
  yield put(UsersActions.resetToInitialState());
}
