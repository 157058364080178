import React from 'react';
import { useTranslation } from 'react-i18next';

import PlanV3PageContent from '@palette/components/ic/planV3/PlanV3PageContent/PlanV3PageContent';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './MyPlanV3Page.less';

const MyPlanV3Page = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.myMasterPlansTitle')} />
      <PageTitle title={t('myMasterPlansPage.title')} />
      <PlanV3PageContent />
    </div>
  );
};

export default MyPlanV3Page;
