import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import HighlightZone from '@palette/components/highlight/HighlightZone/HighlightZone';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useICMasterPlanPeriodInParams } from '@palette/hooks/MasterPlanHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getMomentDateFromPeriod } from '@palette/helpers/FrequencyHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MyMasterPlanPeriodHighlightZone.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanPeriodHighlightZone = ({ className }) => {
  const dispatch = useDispatch();
  const profile = useProfile();

  const { masterPlanId, plan, period } = useICMasterPlanPeriodInParams();

  const getPeriodByIsPending = useSelector(MasterPlansSelectors.getPeriodByIsPending);

  const periodDashboardIsPending = useSelector(MasterPlansSelectors.getPeriodDashboardIsPending);
  const masterPlanPeriodDashboard = useSelector((state) => MasterPlansSelectors.getMasterPlanPeriodDashboard(state, { masterPlanId }));

  const getMasterPlanPeriodWidgetsData = useCallback(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.IC.COMPENSATION])) return;

    const from = getMomentDateFromPeriod(plan.periodType, plan.frequency, period, plan.customBeginAt, false, plan.fiscalYearShift).format();
    const to = getMomentDateFromPeriod(plan.periodType, plan.frequency, period, plan.customBeginAt, true, plan.fiscalYearShift).format();

    dispatch(MasterPlansActions.getICPeriodDashboard({ planId: plan.id, from, to }));
  }, [plan, profile, period]);

  useEffect(() => {
    if (plan === null || period === null || getPeriodByIsPending) return;

    getMasterPlanPeriodWidgetsData();
  }, [plan, period, getPeriodByIsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <HighlightZone loading={periodDashboardIsPending} data={masterPlanPeriodDashboard} forIC />
    </div>
  );
};

MyMasterPlanPeriodHighlightZone.propTypes = {
  className: PropTypes.string,
};

MyMasterPlanPeriodHighlightZone.defaultProps = {
  className: '',
};

export default MyMasterPlanPeriodHighlightZone;
