import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import _size from 'lodash/size';

import { Drawer as AntDDrawer } from 'antd';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import RawData from '@palette/components/resources/RawData/RawData';
import ClosePopupLine from '@palette/components/utils/Icons/ClosePopupLine';

import { countKeysOcc } from '@palette/helpers/CommonHelper';
import { removeEmptyObjectsAndArrays } from '@palette/helpers/ResourceHelper';

import { STATEMENT_DIFF_TYPE_FIELD } from '@palette/constants/statements';

import { selectors as StatementsSelectors } from '@palette/state/Statements';

import styles from './UserStatementInvestigateMainDrawer.less';

const classNames = bindClassNames.bind(styles);

const UserStatementInvestigateMainDrawer = ({
  className,
  statementId,
  summaryId,
  dealName,
  visible,
  handleClose,
}) => {
  const { t } = useTranslation();

  const userStatementCorrectionDetailsIsPending = useSelector((state) => StatementsSelectors.getUserStatementCorrectionDetailsIsPending(state));
  const userStatementCorrectionDetails = useSelector((state) => (
    StatementsSelectors.getUserStatementCorrectionDetailsById(state, {
      correctionStatementId: statementId,
      correctionSummaryId: summaryId,
    })
  ));

  const mainContentNode = useMemo(() => {
    let contentNode = (
      <DefaultEmptyState />
    );

    if (userStatementCorrectionDetailsIsPending && !userStatementCorrectionDetails && summaryId) {
      contentNode = (
        <Loader className={styles.loaderContainer} />
      );
    } else if (userStatementCorrectionDetails === null) {
      contentNode = (
        <DefaultEmptyState description={t('userStatementInvestigateMain.empty.text')} />
      );
    } else {
      const details = removeEmptyObjectsAndArrays(userStatementCorrectionDetails);
      const totalChanges = countKeysOcc(details, STATEMENT_DIFF_TYPE_FIELD);

      if (details && _size(details) > 0 && totalChanges > 0) {
        contentNode = (
          <RawData
            className={styles.rawDataContainer}
            key={summaryId}
            data={{
              type: t('userStatementInvestigateMain.title'),
              data: details.data,
            }}
            onlyReadable
            manageDiff
            expandAllByDefault
            recursivityDepth={8}
          />
        );
      }
    }

    return (
      <div className={styles.content}>
        {contentNode}
      </div>
    );
  }, [
    userStatementCorrectionDetailsIsPending,
    userStatementCorrectionDetails,
    summaryId,
  ]);

  return (
    <AntDDrawer
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={dealName}
      placement="right"
      width={800}
      visible={visible}
      onClose={handleClose}
      closeIcon={<ClosePopupLine width={28} height={28} />}
    >
      {mainContentNode}
    </AntDDrawer>
  );
};

UserStatementInvestigateMainDrawer.propTypes = {
  className: PropTypes.string,
  statementId: PropTypes.string.isRequired,
  summaryId: PropTypes.string,
  dealName: PropTypes.string,
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
};

UserStatementInvestigateMainDrawer.defaultProps = {
  className: '',
  summaryId: null,
  dealName: '',
  visible: false,
  handleClose: () => {},
};

export default UserStatementInvestigateMainDrawer;
