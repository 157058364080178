import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageArrayAttribute,
  manageDateAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { getPeriodConstantsFromPeriodId } from '@palette/helpers/StatementHelper';

import { STATEMENT_PERIODICITY_TYPES } from '@palette/constants/StatementV3';

import * as StatementV3ActionModel from '@palette/models/StatementV3Action';

export const modelName = 'StatementV3Period';

export const propTypes = PropTypes.shape({
  periodId: PropTypes.string,
  periodicity: PropTypes.oneOf(Object.values(STATEMENT_PERIODICITY_TYPES)),
  period: PropTypes.number,
  year: PropTypes.number,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  statements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    fromDate: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    userId: PropTypes.string,
    correctionsCount: PropTypes.number,
    actions: PropTypes.arrayOf(StatementV3ActionModel.propTypes),
  })),
});

export const transform = (periodDetails) => {
  if (!periodDetails) {
    return null;
  }

  const periodId = manageStringAttribute(periodDetails, 'periodId');
  const [periodicity, period, year] = getPeriodConstantsFromPeriodId(periodId);

  const fromDate = manageDateAttribute(periodDetails, 'start');

  const manageStatements = (statements) => statements.map((statement) => ({
    id: manageStringAttribute(statement, 'key'),
    fromDate,
    amount: manageAmountAttribute(statement, 'amount'),
    currency: manageStringAttribute(statement, 'currency'),
    userId: manageStringAttribute(statement, 'payee'),
    correctionsCount: manageNumberAttribute(statement, 'correctionsCount'),
    actions: StatementV3ActionModel.transformList(manageArrayAttribute(statement, 'actions')),
  }));

  return (
    {
      periodId,
      periodicity,
      period,
      year,
      fromDate,
      toDate: manageDateAttribute(periodDetails, 'end'),
      statements: manageStatements(manageArrayAttribute(periodDetails, 'statements')),
    }
  );
};

export const transformList = (periods) => periods.map((periodDetails) => transform(periodDetails));
