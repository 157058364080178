import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _keyBy from 'lodash/keyBy';

import {
  ADD_DATA_CONNECTION_FLOW_FIRST_STEP,
  NOTEBOOK_DATA_CONNECTION_TYPES,
} from '@palette/constants/notebooks';

import { getNextAddDataConnectionFlowStep } from '@palette/helpers/NotebookHelper';

import { actions as PlanV3Actions, selectors as PlanV3Selectors } from '@palette/state/PlanV3';
import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

export const useAddDataConnectionStepValue = () => {
  const addDataConnectionCurrentFlowStep = useSelector(PlanV3Selectors.getAddDataConnectionCurrentFlowStep);

  if (addDataConnectionCurrentFlowStep === null) {
    return ADD_DATA_CONNECTION_FLOW_FIRST_STEP.value;
  }

  return addDataConnectionCurrentFlowStep.value;
};

export const useNextAddDataConnectionStep = () => {
  const addDataConnectionStepValue = useAddDataConnectionStepValue();

  const nextStep = getNextAddDataConnectionFlowStep(addDataConnectionStepValue);

  if (nextStep === null) {
    return ADD_DATA_CONNECTION_FLOW_FIRST_STEP.value;
  }

  return nextStep;
};

export const buildNewDataConnectionConnectorInfo = (newDataConnectionData, selectedResourceTypeFields, dispatch) => {
  const disableAddEditDataConnectionAction = !newDataConnectionData.connectorId
    || !newDataConnectionData.connectorName
    || !newDataConnectionData.resourceType
    || newDataConnectionData.selectedFields?.length === 0;

  const selectedResourceTypeFieldsByName = _keyBy(selectedResourceTypeFields, 'name');

  const addEditDataConnectionAction = () => {
    const columns = (newDataConnectionData.selectedFields || []).map((field) => (selectedResourceTypeFieldsByName[field]));

    dispatch(PlanV3Actions.addDataConnectionConnector({
      connectorId: newDataConnectionData.connectorId,
      connectorName: newDataConnectionData.connectorName,
      resourceType: newDataConnectionData.resourceType,
      columns,
    }));
  };

  return {
    disableAddEditDataConnectionAction,
    addEditDataConnectionAction,
  };
};

export const useNewDataConnectionInfo = () => {
  const dispatch = useDispatch();

  const newDataConnectionSource = useSelector(PlanV3Selectors.getNewDataConnectionSource);
  const newDataConnectionData = useSelector(PlanV3Selectors.getNewDataConnectionData);

  const selectedResourceTypeFields = useSelector(
    (state) => ConnectorsSelectors.getResourceFieldsByConnectorAndType(state, { connectorId: newDataConnectionData?.connectorId, resourceType: newDataConnectionData?.resourceType }),
  );

  return useMemo(() => {
    const defaultNewDataConnectionInfo = {
      disableAddEditDataConnectionAction: true,
      addEditDataConnectionAction: null,
    };

    if (newDataConnectionSource === null || newDataConnectionData === null) {
      return defaultNewDataConnectionInfo;
    }

    if (newDataConnectionSource.type !== NOTEBOOK_DATA_CONNECTION_TYPES.CONNECTOR) {
      return defaultNewDataConnectionInfo;
    }

    return buildNewDataConnectionConnectorInfo(newDataConnectionData, selectedResourceTypeFields, dispatch);
  }, [
    newDataConnectionSource,
    newDataConnectionData,
    selectedResourceTypeFields,
  ]);
};
