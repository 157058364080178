import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import AddLine from '@palette/components/utils/Icons/AddLine';
import AddMembersToTeamModal from '@palette/components/team/AddMembersToTeamModal/AddMembersToTeamModal';

import * as TeamModel from '@palette/models/Team';

const AddMembersToTeam = ({ className, team }) => {
  const { t } = useTranslation();
  const [addMembersToTeamModalIsVisible, showAddMembersToTeamModal] = useState(false);

  return (
    <div className={className}>
      <Button
        type="secondary"
        icon={<AddLine />}
        onClick={() => showAddMembersToTeamModal(true)}
      >
        {t('addMembersToTeam.label')}
      </Button>
      {
        addMembersToTeamModalIsVisible && (
          <AddMembersToTeamModal visible onClose={() => showAddMembersToTeamModal(false)} team={team} />
        )
      }
    </div>
  );
};

AddMembersToTeam.propTypes = {
  className: PropTypes.string,
  team: TeamModel.propTypes.isRequired,
};

AddMembersToTeam.defaultProps = {
  className: '',
};

export default AddMembersToTeam;
