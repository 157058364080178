import {
  call,
  put,
  all,
  takeLatest, take, select,
} from 'redux-saga/effects';

import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { manageError as manageCommissionsError } from '@palette/state/Commissions/errors';
import { actions as CommissionsActions, selectors as CommissionsSelectors } from '@palette/state/Commissions/slice';
import { updatePlanDataInState as masterPlansUpdatePlanDataInState } from '@palette/state/MasterPlans/sagas';
import { selectors as NavigationSelectors } from '@palette/state/Navigation/slice';
import { selectors as ProfileSelectors } from '@palette/state/Profile';
import { actions as ThreadsActions } from '@palette/state/Threads/slice';
import { updateStatementDataInState } from '@palette/state/Statements/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { enhanceComponentsDataWithDealsType } from '@palette/helpers/DashboardHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getSearch } from '@palette/helpers/NavigationHelper';

import apiConfig from '@palette/config/api';

import { COMMISSION_EVENTS } from '@palette/constants/analytics';
import { COMMISSIONS_UPDATE_PATH_ENTITIES, DEFAULT_LIMIT_QS_VALUE, DEFAULT_PAGE_QS_VALUE } from '@palette/constants/navigation';
import { RIGHTS } from '@palette/constants/profile';

import * as CommissionsService from '@palette/services/CommissionsService';
import * as CommissionModel from '@palette/models/Commission';
import * as PaginationModel from '@palette/models/Pagination';
import * as HighlightComponentDataModel from '@palette/models/HighlightComponentData';

export function* updateCommissionsListDataInState() {
  // Refresh commission list if it was already retrieved
  const stateCommissionsListByFilters = yield select(CommissionsSelectors.getListByFilters);
  const { callData: commissionsListByFiltersCallData = null } = stateCommissionsListByFilters;
  if (commissionsListByFiltersCallData !== null) {
    yield put(CommissionsActions.listByFilters(commissionsListByFiltersCallData));
    yield take(CommissionsActions.listByFiltersCompleted.type);
  }

  // Refresh commission list dashboard if it was already retrieved
  const stateCommissionsListByFiltersDashboard = yield select(CommissionsSelectors.getListByFiltersDashboard);
  const { callData: commissionsListByFiltersDashboardCallData = null } = stateCommissionsListByFiltersDashboard;
  if (commissionsListByFiltersDashboardCallData !== null) {
    yield put(CommissionsActions.listByFiltersDashboard(commissionsListByFiltersDashboardCallData));
    yield take(CommissionsActions.listByFiltersDashboardCompleted.type);
  }
}

export function* updateDataInStoreAfterCommissionUpdate({ commission }) {
  const { pathname, search } = yield select(NavigationSelectors.getCurrentLocation);
  const pathItems = pathname.slice(1).split('/');
  const pathEntity = pathItems[0];

  switch (pathEntity) {
    case COMMISSIONS_UPDATE_PATH_ENTITIES.STATEMENTS: {
      const currency = getSearch({ search }).currency ?? undefined;
      yield call(updateStatementDataInState, { statementPeriodId: pathItems[1], userId: pathItems[3], currency });
      break;
    }
    case COMMISSIONS_UPDATE_PATH_ENTITIES.PLANS:
      yield call(masterPlansUpdatePlanDataInState, { planId: commission.planId, disableSuccessNotification: true });
      break;
    case COMMISSIONS_UPDATE_PATH_ENTITIES.COMMISSION_LIST:
      yield call(updateCommissionsListDataInState);
      break;
    default:
  }
}

export function* getById({ payload = {} }) {
  const { commissionId, firstFetch = false, inSalesforce = false } = payload;

  const profile = yield select(ProfileSelectors.profile);

  let callResult;

  if (inSalesforce) {
    callResult = yield call(CommissionsService.getByIdFromSF, { commissionId });
  } else if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) { // if admin, call admin endpoint
    callResult = yield call(CommissionsService.getById, { commissionId });
  } else { // else call IC endpoint
    callResult = yield call(CommissionsService.getByIdFromIC, { commissionId });
  }

  if (callResult.ok) {
    const commission = CommissionModel.transform(callResult.data);
    yield put(CommissionsActions.setById({ commission }));
    if (firstFetch) {
      yield call(analyticsSendEvent, { payload: { event: COMMISSION_EVENTS.LOADING_DETAILS, params: { commissionId } } });
    } else {
      yield call(updateDataInStoreAfterCommissionUpdate, { commission });
    }
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.getByIdCompleted());
}

export function* overwriteAmount({ payload = {} }) {
  const { commissionId, amount, onSuccessCB } = payload;

  const callResult = yield call(CommissionsService.overwriteAmount, { commissionId, amount });

  if (callResult.ok) {
    onSuccessCB();

    yield put(ThreadsActions.getList());

    yield put(CommissionsActions.getById({ commissionId }));

    yield take(CommissionsActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: COMMISSION_EVENTS.UPDATE_AMOUNT, params: { commissionId } } });
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.overwriteAmountCompleted());
}

export function* overwriteValue({ payload = {} }) {
  const { commissionId, value, onSuccessCB } = payload;

  const callResult = yield call(CommissionsService.overwriteValue, { commissionId, value });

  if (callResult.ok) {
    onSuccessCB();

    yield put(ThreadsActions.getList());

    yield put(CommissionsActions.getById({ commissionId }));

    yield take(CommissionsActions.getByIdCompleted.type);

    yield call(analyticsSendEvent, { payload: { event: COMMISSION_EVENTS.UPDATE_VALUE, params: { commissionId } } });
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.overwriteValueCompleted());
}

export function* getActivities({ payload = {} }) {
  const { commissionId } = payload;

  yield put(ThreadsActions.getOne({ mongoCollection: 'Commission', mongoId: commissionId }));
  yield take(ThreadsActions.getOneCompleted.type);

  yield put(CommissionsActions.getActivitiesCompleted());
}

export function* addComment({ payload = {} }) {
  const { commissionId, comment, onSuccessCB } = payload;

  let activities = yield select(CommissionsSelectors.getCommissionActivitiesById, { commissionId });

  if (activities === null) {
    yield put(ThreadsActions.create({ mongoCollection: 'Commission', mongoId: commissionId }));

    yield take(ThreadsActions.createCompleted.type);

    activities = yield select(CommissionsSelectors.getCommissionActivitiesById, { commissionId });
  }

  yield put(ThreadsActions.addComment({ threadId: activities.id, comment, onSuccessCB }));

  yield take(ThreadsActions.addCommentCompleted.type);

  yield put(ThreadsActions.getList());
  yield put(CommissionsActions.getById({ commissionId }));

  yield take(CommissionsActions.getByIdCompleted.type);

  yield put(CommissionsActions.addCommentCompleted());
}

export function* setStatus({ payload = {} }) {
  const { commissionId, status } = payload;

  let activities = yield select(CommissionsSelectors.getCommissionActivitiesById, { commissionId });

  if (activities === null) {
    yield put(ThreadsActions.create({ mongoCollection: 'Commission', mongoId: commissionId }));

    yield take(ThreadsActions.createCompleted.type);

    activities = yield select(CommissionsSelectors.getCommissionActivitiesById, { commissionId });
  }

  yield put(ThreadsActions.setStatus({ threadId: activities.id, status }));

  yield take(ThreadsActions.setStatusCompleted.type);

  yield put(ThreadsActions.getList());
  yield put(CommissionsActions.getById({ commissionId }));

  yield take(CommissionsActions.getByIdCompleted.type);

  yield put(CommissionsActions.setStatusCompleted());
}

export function* updatePayoutSchedule({ payload = {} }) {
  const { commissionId, payments, onSuccessCB } = payload;

  const callResult = yield call(CommissionsService.updatePayoutSchedule, { commissionId, payments });

  if (callResult.ok) {
    onSuccessCB();

    yield put(ThreadsActions.getList());

    yield put(CommissionsActions.getById({ commissionId }));

    yield take(CommissionsActions.getByIdCompleted.type);
    yield call(analyticsSendEvent, { payload: { event: COMMISSION_EVENTS.UPDATE_PAYOUT, params: { commissionId } } });
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.updatePayoutScheduleCompleted());
}

export function* listDealCommissions({ payload = {} }) {
  const { connectorId, objectId, type } = payload;

  const callResult = yield call(CommissionsService.listDealCommissions, { connectorId, objectId, type });

  if (callResult.ok) {
    const commissions = CommissionModel.transformList(callResult.data);
    const composeId = `${connectorId}_${type}_${objectId}`;
    yield put(CommissionsActions.setByObjectComposeId({ composeId, commissions }));
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.listDealCommissionsCompleted());
}

export function* listByFilters({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    dealFilter = '',
    statusFilter = undefined,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    searchedUser = null,
    searchedTeam = undefined,
    searchedResourceType = null,
    searchedPlan = null,
    sortBy = undefined,
    currency = undefined,
  } = payload;

  const callData = {
    from,
    to,
    statusFilter,
    dealFilter,
    page,
    limit,
    searchedUser,
    searchedTeam,
    searchedResourceType,
    searchedPlan,
    sortBy,
    currency,
  };

  const callResult = yield call(CommissionsService.commissionListByFilters, callData);

  if (callResult.ok) {
    const commissions = CommissionModel.transformList(callResult.data.data);
    const pagination = PaginationModel.transform(callResult.data.pagination);

    yield put(CommissionsActions.setListByFilters({ commissions, pagination, callData }));
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.listByFiltersCompleted());
}

export function* listICByFilters({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    dealFilter = '',
    statusFilter = undefined,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    searchedResourceType = null,
    searchedPlan = null,
    sortBy = undefined,
  } = payload;

  const callData = {
    from,
    to,
    statusFilter,
    dealFilter,
    page,
    limit,
    searchedResourceType,
    searchedPlan,
    sortBy,
  };

  const callResult = yield call(CommissionsService.commissionListICByFilters, callData);

  if (callResult.ok) {
    const commissions = CommissionModel.transformList(callResult.data.data);
    const pagination = PaginationModel.transform(callResult.data.pagination);

    yield put(CommissionsActions.setListByFilters({ commissions, pagination, callData }));
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.listByFiltersCompleted());
}

export function* listByFiltersDashboard({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    dealFilter = '',
    statusFilter = undefined,
    searchedUser = null,
    searchedTeam = undefined,
    searchedResourceType = null,
    searchedPlan = null,
    currency = undefined,
  } = payload;

  const callData = {
    from,
    to,
    statusFilter,
    dealFilter,
    searchedUser,
    searchedTeam,
    searchedResourceType,
    searchedPlan,
    currency,
  };

  const callResult = yield call(CommissionsService.commissionListByFiltersDashboard, callData);

  if (callResult.ok) {
    const enhancedData = enhanceComponentsDataWithDealsType(callResult.data, searchedResourceType?.type);
    const dashboard = HighlightComponentDataModel.transformList(enhancedData);

    yield put(CommissionsActions.setListByFiltersDashboard({ dashboard, callData }));
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.listByFiltersDashboardCompleted());
}

export function* listICByFiltersDashboard({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    dealFilter = '',
    statusFilter = undefined,
    searchedResourceType = null,
    searchedPlan = null,
  } = payload;

  const callData = {
    from,
    to,
    statusFilter,
    dealFilter,
    searchedResourceType,
    searchedPlan,
  };

  const callResult = yield call(CommissionsService.commissionListICByFiltersDashboard, callData);

  if (callResult.ok) {
    const enhancedData = enhanceComponentsDataWithDealsType(callResult.data, searchedResourceType?.type);
    const dashboard = HighlightComponentDataModel.transformList(enhancedData);

    yield put(CommissionsActions.setListByFiltersDashboard({ dashboard, callData }));
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.listByFiltersDashboardCompleted());
}

export function* downloadCommissionList({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    dealFilter = '',
    statusFilter = undefined,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    searchedUser = null,
    searchedTeam = undefined,
    searchedResourceType = null,
    searchedPlan = null,
    currency = undefined,
  } = payload;

  const callData = {
    from,
    to,
    statusFilter,
    dealFilter,
    page,
    limit,
    searchedUser,
    searchedTeam,
    searchedResourceType,
    searchedPlan,
    currency,
  };

  const callResult = yield call(CommissionsService.downloadCommissionList, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.downloadCommissionListCompleted());
}

export function* downloadCommissionICList({ payload = {} }) {
  const {
    from = undefined,
    to = undefined,
    dealFilter = '',
    statusFilter = undefined,
    page = DEFAULT_PAGE_QS_VALUE,
    limit = DEFAULT_LIMIT_QS_VALUE,
    searchedResourceType = null,
    searchedPlan = null,
  } = payload;

  const callData = {
    from,
    to,
    statusFilter,
    dealFilter,
    page,
    limit,
    searchedResourceType,
    searchedPlan,
  };

  const callResult = yield call(CommissionsService.downloadCommissionICList, callData);

  if (callResult.ok) {
    window.open(`${apiConfig.BASE_URL}/downloads/${callResult.data._id}`, '_blank');
  } else {
    const error = manageCommissionsError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(CommissionsActions.downloadCommissionListCompleted());
}

export function* loop() {
  yield all([
    takeLatest(CommissionsActions.getById.type, getById),
    takeLatest(CommissionsActions.overwriteAmount.type, overwriteAmount),
    takeLatest(CommissionsActions.overwriteValue.type, overwriteValue),
    takeLatest(CommissionsActions.getActivities.type, getActivities),
    takeLatest(CommissionsActions.addComment.type, addComment),
    takeLatest(CommissionsActions.setStatus.type, setStatus),
    takeLatest(CommissionsActions.updatePayoutSchedule.type, updatePayoutSchedule),
    takeLatest(CommissionsActions.listDealCommissions.type, listDealCommissions),
    takeLatest(CommissionsActions.listByFilters.type, listByFilters),
    takeLatest(CommissionsActions.listICByFilters.type, listICByFilters),
    takeLatest(CommissionsActions.listByFiltersDashboard.type, listByFiltersDashboard),
    takeLatest(CommissionsActions.listICByFiltersDashboard.type, listICByFiltersDashboard),
    takeLatest(CommissionsActions.downloadCommissionList.type, downloadCommissionList),
    takeLatest(CommissionsActions.downloadCommissionICList.type, downloadCommissionICList),
  ]);
}

export function* clean() {
  yield put(CommissionsActions.resetToInitialState());
}
