import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Loader from '@palette/components/utils/Loader/Loader';
import TeamsWithSubTeamsList from '@palette/components/team/TeamsWithSubTeamsList/TeamsWithSubTeamsList';
import TeamMembersList from '@palette/components/team/TeamMembersList/TeamMembersList';
import AddMembersToTeam from '@palette/components/team/AddMembersToTeam/AddMembersToTeam';
import NewTeamButton from '@palette/components/team/NewTeamButton/NewTeamButton';

import { useTeamIdInParams, useTeamsTree } from '@palette/hooks/TeamHooks';

import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './TeamDetailsPageContent.less';

const classNames = bindClassNames.bind(styles);

const TeamDetailsPageContent = ({ className }) => {
  const { t } = useTranslation();

  const { team } = useTeamIdInParams();
  const teamsListIsPending = useSelector(TeamsSelectors.getListIsPending);
  const teamsTree = useTeamsTree(team?.id || null);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.subTeamsWrapper}>
        <div className={styles.subTeamsLabelActionsWrapper}>
          <div className={styles.subTeamsLabel}>
            {t('teamDetailsPageContent.subTeamsLabel', { count: teamsTree.length })}
          </div>
          <NewTeamButton className={styles.newTeamBtn} parentTeam={team} />
        </div>
        <Loader className={styles.subTeamsList} spinning={teamsListIsPending}>
          <TeamsWithSubTeamsList className={styles.subTeamsList} teams={teamsTree} />
        </Loader>
      </div>
      <div className={styles.membersWrapper}>
        <div className={styles.membersLabelActionsWrapper}>
          <div className={styles.membersLabel}>
            {t('teamDetailsPageContent.membersLabel', { count: team?.members?.length || 0 })}
          </div>
          {
            team !== null && (
              <AddMembersToTeam className={styles.addMembersToTeamBtn} team={team} />
            )
          }
        </div>
        <TeamMembersList className={styles.membersList} members={team?.members || []} />
      </div>
    </div>
  );
};

TeamDetailsPageContent.propTypes = {
  className: PropTypes.string,
};

TeamDetailsPageContent.defaultProps = {
  className: '',
};

export default TeamDetailsPageContent;
