import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import InputTable from '@palette/components/designSystem/InputTable/InputTable';
import CurvedLine from '@palette/components/utils/Icons/CurvedLine';

import { actions as PlanV3Actions } from '@palette/state/PlanV3';

import * as PlanV3InputTableModel from '@palette/models/PlanV3InputTable';

import styles from './PlanV3InputTable.less';

const classNames = bindClassNames.bind(styles);

const PlanV3InputTable = ({ className, planId, inputTable, ...otherProps }) => {
  const dispatch = useDispatch();

  const handleAddNewRow = useCallback((rowData) => {
    if (inputTable === null) return;

    const higherPosition = Math.max(0, ...inputTable.rows.map((row) => row.position)) ?? 0;

    const newRow = {
      position: higherPosition + 1,
      values: inputTable.columns.map((column) => ({
        columnId: column.id,
        value: rowData[column.id],
      })),
    };

    dispatch(PlanV3Actions.addRowToInputTable({ planId, inputTableId: inputTable.id, row: newRow }));
  }, [planId, inputTable]);

  const handleAddMultipleRows = useCallback((rowsData) => {
    if (inputTable === null) return;

    const higherPosition = Math.max(0, ...inputTable.rows.map((row) => row.position)) ?? 0;

    const newRows = rowsData.map((rowData, i) => ({
      position: higherPosition + 1 + i,
      values: inputTable.columns.map((column) => ({
        columnId: column.id,
        value: rowData[column.id],
      })),
    }));

    dispatch(PlanV3Actions.addMultipleRowsToInputTable({ planId, inputTableId: inputTable.id, rows: newRows }));
  }, [planId, inputTable]);

  const handleDeleteRow = useCallback((rowIndex) => {
    if (inputTable === null) return;

    const rowPosition = inputTable.rows[rowIndex].position;

    dispatch(PlanV3Actions.deleteRowFromInputTable({ planId, inputTableId: inputTable.id, rowPosition }));
  }, [planId, inputTable]);

  const handleAddNewColumn = useCallback((newColumnName, newColumnType) => {
    if (inputTable === null) return;

    const higherPosition = Math.max(0, ...inputTable.columns.map((column) => column.position)) ?? 0;

    const newColumn = {
      label: newColumnName,
      position: higherPosition + 1,
      type: newColumnType,
      values: inputTable.rows.map((row) => ({
        rowPosition: row.position,
        value: '',
      })),
    };

    dispatch(PlanV3Actions.addColumnToInputTable({ planId, inputTableId: inputTable.id, column: newColumn }));
  }, [planId, inputTable]);

  const handleDeleteColumn = useCallback((columnId) => {
    if (inputTable === null) return;

    dispatch(PlanV3Actions.deleteColumnFromInputTable({ planId, inputTableId: inputTable.id, columnId }));
  }, [planId, inputTable]);

  const handleUpdateColumn = useCallback((columnId, columnName, columnType) => {
    if (inputTable === null) return;

    dispatch(PlanV3Actions.updateColumnFromInputTable({ planId, inputTableId: inputTable.id, columnId, label: columnName, type: columnType }));
  }, [planId, inputTable]);

  const handleCellContentChange = useCallback((cellColumnId, cellRowIndex, newContent) => {
    if (inputTable === null) return;

    const rowPosition = inputTable.rows[cellRowIndex].position;

    dispatch(PlanV3Actions.updateCellContentFromInputTable({ planId, inputTableId: inputTable.id, rowPosition, columnId: cellColumnId, content: newContent }));
  }, [planId, inputTable]);

  return (
    <>
      <InputTable
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
        onAddNewRow={handleAddNewRow}
        onAddMultipleRows={handleAddMultipleRows}
        onDeleteRow={handleDeleteRow}
        onAddNewColumn={handleAddNewColumn}
        onDeleteColumn={handleDeleteColumn}
        onUpdateColumn={handleUpdateColumn}
        onCellContentChange={handleCellContentChange}
        {...otherProps}
      />
      <div className={styles.variableNameWrapper}>
        <CurvedLine className={styles.variableNameIcon} />
        <div className={styles.variableName}>
          {inputTable.variableName}
        </div>
      </div>
    </>
  );
};

PlanV3InputTable.propTypes = {
  className: PropTypes.string,
  planId: PropTypes.string.isRequired,
  inputTable: PlanV3InputTableModel.propTypes,
};

PlanV3InputTable.defaultProps = {
  className: '',
  inputTable: null,
};

export default PlanV3InputTable;
