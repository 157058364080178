import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';

import { getDependencyStrategyDescription } from '@palette/helpers/MasterPlanHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanInjectionModel from '@palette/models/MasterPlanInjection';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import { SCOPES } from '@palette/constants/masterPlans';

import styles from './MasterPlanSettingsCommissionRulesDependencyItem.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsCommissionRulesDependencyItem = ({ className, dependency, plan }) => {
  const dispatch = useDispatch();

  const dependencyPlan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: dependency.planId }));

  useEffect(() => {
    dispatch(MasterPlansActions.getById({ planId: dependency.planId }));
  }, [dependency.planId]);

  const dependencyStrategyNode = useMemo(() => {
    const dependencyStrategyDescription = getDependencyStrategyDescription(dependency, dependencyPlan, plan);

    if (!dependencyStrategyDescription) return null;

    return (
      <div className={styles.strategy}>
        {dependencyStrategyDescription}
      </div>
    );
  }, [dependency, dependencyPlan, plan]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.name}>{dependency.name}</div>
      <div className={styles.description}>
        {dependencyPlan && <MasterPlanScopeTags className={styles.scopes} plan={dependencyPlan} />}
        <span className={styles.planName}>
          {dependencyPlan?.name}
          {dependencyPlan?.scope !== SCOPES.INDIVIDUAL && (
            ` - ${dependencyPlan?.scope}`
          )}
        </span>
        {dependencyStrategyNode}
      </div>
    </div>
  );
};

MasterPlanSettingsCommissionRulesDependencyItem.propTypes = {
  className: PropTypes.string,
  dependency: MasterPlanInjectionModel.propTypes.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanSettingsCommissionRulesDependencyItem.defaultProps = {
  className: '',
};

export default MasterPlanSettingsCommissionRulesDependencyItem;
