import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@palette/components/designSystem/Modal/Modal';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import * as DashboardPresetModel from '@palette/models/DashboardPreset';

import { actions as DashboardPresetsActions, selectors as DashboardPresetsSelectors } from '@palette/state/DashboardPresets';

import styles from './DeleteDashboardPresetModal.less';

const DeleteDashboardPresetModal = ({ visible, onClose, dashboardPreset }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteDashboardPresetIsPending = useSelector(DashboardPresetsSelectors.deleteByIdIsPending);

  const cleanAndClose = () => {
    onClose();
  };

  const onSuccessCB = () => {
    cleanAndClose();
    dispatch(DashboardPresetsActions.getDashboardPresetsList());
    redirectTo({ path: routePaths.v2.dashboardPresets });
  };

  const handleDeleteDashboardPreset = () => {
    dispatch(DashboardPresetsActions.deleteById({ presetId: dashboardPreset.id, onSuccessCB }));
  };

  return (
    <Modal
      className={styles.modal}
      title={t('deleteDashboardPresetModal.title', { presetName: dashboardPreset.name })}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleDeleteDashboardPreset}
      okText={t('deleteDashboardPresetModal.confirm')}
      loading={deleteDashboardPresetIsPending}
    >
      <div className={styles.content}>
        {t('deleteDashboardPresetModal.content')}
      </div>
    </Modal>
  );
};

DeleteDashboardPresetModal.propTypes = {
  dashboardPreset: DashboardPresetModel.propTypes.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

DeleteDashboardPresetModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default DeleteDashboardPresetModal;
