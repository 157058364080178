import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import RolesPageContent from '@palette/components/role/RolesPageContent/RolesPageContent';
import CreateRole from '@palette/components/role/CreateRole/CreateRole';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './RolesPage.less';

const RolesPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAllRights(profile, [RIGHTS.ADMIN.COMPANY.MANAGE, RIGHTS.ADMIN.ROLES.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.rolesTitle')} />
      <PageTitle className={styles.titleWrapper}>
        <div className={styles.titlesContainer}>
          <h1>{t('rolesPage.page.title')}</h1>
        </div>
        <div className={styles.actions}>
          <CreateRole className={styles.newRoleBtn} />
        </div>
      </PageTitle>
      <RolesPageContent />
    </div>
  );
};

export default RolesPage;
