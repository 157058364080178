import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getMoment } from '@palette/helpers/MomentHelper';

import CommonWidgetBase from '@palette/components/widgets/dashboard/CommonWidgetBase/CommonWidgetBase';
import Table from '@palette/components/designSystem/Table/Table';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import CommissionName from '@palette/components/commissionList/CommissionName/CommissionName';
import Tag from '@palette/components/designSystem/Tag/Tag';
import Button from '@palette/components/designSystem/Button/Button';
import EyesLine from '@palette/components/utils/Icons/EyesLine';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getCommissionAmount } from '@palette/helpers/CommissionHelper';

import { COMMISSION_DRAWER_COMMISION_ID_QS_KEY, STATUSES_TAG } from '@palette/constants/commissions';
import { COMMISSION_DRAWER_QS_KEY, COMMISSION_DRAWER_TABS_IDS } from '@palette/constants/tabs';

import { actions as NavigationActions } from '@palette/state/Navigation';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as CommissionsUnreleasedListDataModel from '@palette/models/widgets/dashboard/CommissionsUnreleasedListData';

import styles from './CommissionsUnreleasedListWidget.less';

const classNames = bindClassNames.bind(styles);

const CommissionsUnreleasedListWidget = ({ className, widgetData, widgetIndex, readOnly }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const commissionsUnreleasedListData = useMemo(() => (CommissionsUnreleasedListDataModel.transform(widgetData.data)), [widgetData]);

  const openCommissionDrawer = useCallback((tabKey, commissionId) => {
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [COMMISSION_DRAWER_COMMISION_ID_QS_KEY]: commissionId, [COMMISSION_DRAWER_QS_KEY]: tabKey } }));
  }, []);

  const columns = useMemo(() => {
    const cols = [];

    const nameColumn = {
      id: 'commissionName',
      Header: t('commissionList.table.header.name'),
      accessor: (commission) => commission,
      minWidth: 250,
      width: '30%',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => <CommissionName commission={value} />,
    };

    cols.push(nameColumn);

    const dateColumn = {
      id: 'commissionDate',
      Header: t('commissionList.table.header.date'),
      accessor: 'date',
      minWidth: 140,
      width: '14%',
      Cell: ({ value }) => moment.utc(value).format('ll'),
    };

    cols.push(dateColumn);

    const valueColumn = {
      id: 'commissionValue',
      Header: t('commissionList.table.header.commissions'),
      accessor: (commission) => commission,
      minWidth: 160,
      width: '20%',
      Cell: ({ value }) => formatPrice(getCommissionAmount(value), value?.currency),
    };

    cols.push(valueColumn);

    const statusColumn = {
      id: 'commissionStatus',
      Header: t('commissionList.table.header.status'),
      accessor: 'paymentsStatus',
      minWidth: 160,
      width: '20%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <Tag
          theme={STATUSES_TAG[value]}
          label={t(`commissionList.status.tag.${value.toLowerCase()}`)}
        />
      ),
      /* eslint-enable react/prop-types */
    };

    cols.push(statusColumn);

    const actionsColumn = {
      id: 'commissionActions',
      accessor: (commission) => commission,
      minWidth: 140,
      width: '16%',
      /* eslint-disable react/prop-types */
      Cell: ({ value }) => (
        <Button
          type="link"
          icon={<EyesLine />}
          onClick={() => openCommissionDrawer(COMMISSION_DRAWER_TABS_IDS.VARIABLES, value.id)}
        >
          {t('commissionList.actions.button.view_details')}
        </Button>
      ),
      /* eslint-enable react/prop-types */
    };

    cols.push(actionsColumn);

    return cols;
  }, [readOnly, openCommissionDrawer]);

  const commissions = useMemo(() => (commissionsUnreleasedListData.commissions), [commissionsUnreleasedListData]);

  const tableNode = useMemo(() => {
    if (commissions.length === 0) {
      return (
        <DefaultEmptyState />
      );
    }

    return (
      <Table
        type="borderless"
        columns={columns}
        data={commissions}
        stretch
      />
    );
  }, [commissions, columns]);

  return (
    <CommonWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      title={t('common.commissions_unreleased_list.title')}
      readOnly={readOnly}
    >
      <div className={styles.content}>
        {tableNode}
      </div>
    </CommonWidgetBase>
  );
};

CommissionsUnreleasedListWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
};

CommissionsUnreleasedListWidget.defaultProps = {
  className: '',
  readOnly: false,
};

export default CommissionsUnreleasedListWidget;
