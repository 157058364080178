import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';
import { getTypeDisplayed } from '@palette/helpers/CommonHelper';

import * as FieldDefinitionModel from '@palette/models/FieldDefinition';

export const modelName = 'NotebookDataConnectionConnector';

export const propTypes = PropTypes.shape({
  connectorId: PropTypes.string,
  connectorName: PropTypes.string,
  type: PropTypes.string,
  originalType: PropTypes.string,
  dataframeName: PropTypes.string,
  columns: PropTypes.arrayOf(FieldDefinitionModel.propTypes),
});

export const transform = (notebookDataConnectionConnector) => {
  if (!notebookDataConnectionConnector) {
    return null;
  }

  const originalType = manageStringAttribute(notebookDataConnectionConnector, 'resourceType');

  const rawColumns = manageArrayAttribute(notebookDataConnectionConnector, 'columns');
  const columns = FieldDefinitionModel.transformList(rawColumns);

  return (
    {
      connectorId: manageStringAttribute(notebookDataConnectionConnector, 'connectorId', null),
      connectorName: manageStringAttribute(notebookDataConnectionConnector, 'connectorName'),
      type: getTypeDisplayed(originalType),
      originalType,
      dataframeName: manageStringAttribute(notebookDataConnectionConnector, 'dataframeName'),
      columns,
    }
  );
};

export const transformList = (notebookDataConnectionConnectors) => notebookDataConnectionConnectors.map((notebookDataConnectionConnector) => transform(notebookDataConnectionConnector));
