import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { actions as ResourcesActions } from '@palette/state/Resources/slice';
import { manageError as manageResourcesError } from '@palette/state/Resources/errors';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import * as ResourcesService from '@palette/services/ResourcesService';

import * as ResourceObject from '@palette/models/ResourceObject';

import { RAW_DATA_EVENTS } from '@palette/constants/analytics';

import routePaths from '@palette/config/routePaths';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

export function* getById({ payload = {} }) {
  const { connectorId, objectId, type } = payload;

  const data = { connectorId, objectId, type };

  const callResult = yield call(ResourcesService.getById, data);

  if (callResult.ok) {
    const resource = ResourceObject.transform(callResult.data);
    yield put(ResourcesActions.setById({ resource }));
  } else {
    const error = manageResourcesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ResourcesActions.getByIdCompleted());
}

export function* removeProperty({ payload = {} }) {
  const { connectorId, objectId, type, fragments, value } = payload;

  const data = { connectorId, objectId, type, fragments, value };

  const callResult = yield call(ResourcesService.deleteProperty, data);

  if (callResult.ok) {
    yield put(ResourcesActions.getById({ connectorId, objectId, type }));
    yield call(analyticsSendEvent, { payload: { event: RAW_DATA_EVENTS.REMOVE_PROPERTY } });
  } else {
    const error = manageResourcesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ResourcesActions.removePropertyCompleted());
}

export function* addEditProperty({ payload = {} }) {
  const { connectorId, objectId, type, fragments, value } = payload;

  const data = { connectorId, objectId, type, fragments, value };

  const callResult = yield call(ResourcesService.addEditProperty, data);

  if (callResult.ok) {
    yield put(ResourcesActions.getById({ connectorId, objectId, type }));
    yield call(analyticsSendEvent, { payload: { event: RAW_DATA_EVENTS.CREATE_OR_EDIT } });
  } else {
    const error = manageResourcesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ResourcesActions.addEditPropertyCompleted());
}

export function* deleteOverwrite({ payload = {} }) {
  const { connectorId, objectId, type, rollbackId, overwritesAllIds } = payload;

  let overwriteIds = [];

  if (rollbackId === 0) {
    // Reset to original value: all overwrites ids
    overwriteIds = overwritesAllIds;
  } else {
    overwriteIds = overwritesAllIds.slice(overwritesAllIds.indexOf(rollbackId) + 1);
  }

  const data = { connectorId, objectId, type, overwriteIds };

  const callResult = yield call(ResourcesService.deleteOverwrite, data);

  if (callResult.ok) {
    yield put(ResourcesActions.getById({ connectorId, objectId, type }));
    yield call(analyticsSendEvent, { payload: { event: RAW_DATA_EVENTS.DELETE_OVERWRITE } });
  } else {
    const error = manageResourcesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ResourcesActions.deleteOverwriteCompleted());
}

export function* deleteResource({ payload = {} }) {
  const { connectorId, objectId, originalType, type, clonedFrom = null } = payload;

  const data = { connectorId, objectId, originalType };

  const callResult = yield call(ResourcesService.deleteResource, data);

  if (callResult.ok) {
    yield put(GlobalNotifActions.addGlobalNotif({
      message: {
        code: GLOBAL_NOTIF_REASONS.RESOURCE_DELETION_SUCCESS.code,
        context: { type },
      },
    }));

    if (clonedFrom) {
      redirectTo({ path: routePaths.v2.resourceDetails, params: { connectorId, type: originalType, resourceId: clonedFrom } });
    } else {
      redirectTo({ path: routePaths.v2.resourcesByConnectorId, params: { connectorId, type: originalType } });
    }
  } else {
    const error = manageResourcesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ResourcesActions.deleteResourceCompleted());
}

export function* cloneResource({ payload = {} }) {
  const { connectorId, objectId, originalType } = payload;

  const data = { connectorId, objectId, originalType };

  const callResult = yield call(ResourcesService.cloneResource, data);

  if (callResult.ok) {
    const { id } = ResourceObject.transform(callResult.data);

    redirectTo({ path: routePaths.v2.resourceDetails, params: { connectorId, type: originalType, resourceId: id } });
  } else {
    const error = manageResourcesError(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(ResourcesActions.cloneResourceCompleted());
}

export function* copyResourceDataAsJSON({ payload = {} }) {
  const { data } = payload;

  try {
    yield call(() => (navigator.clipboard.writeText(JSON.stringify(data))));
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.RESOURCE_DATA_COPY_TO_CLIPBOARD_SUCCESS));
  } catch (e) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.RESOURCE_DATA_COPY_TO_CLIPBOARD_ERROR));
  }
}

export function* loop() {
  yield all([
    takeEvery(ResourcesActions.getById.type, getById),
    takeLatest(ResourcesActions.removeProperty.type, removeProperty),
    takeLatest(ResourcesActions.addEditProperty.type, addEditProperty),
    takeLatest(ResourcesActions.deleteOverwrite.type, deleteOverwrite),
    takeLatest(ResourcesActions.deleteResource.type, deleteResource),
    takeLatest(ResourcesActions.cloneResource.type, cloneResource),
    takeLatest(ResourcesActions.copyResourceDataAsJSON.type, copyResourceDataAsJSON),
  ]);
}

export function* clean() {
  yield put(ResourcesActions.resetToInitialState());
}
