import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import UsersMergePageContent from '@palette/components/user/UsersMergePageContent/UsersMergePageContent';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAllRights } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import styles from './UsersMergePage.less';

const UsersMergePage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  if (!hasAllRights(profile, [RIGHTS.ADMIN.USERS.UPDATE])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.usersMergeTitle')} />
      <PageTitle title={t('usersMergePage.page.title')} subtitle={t('usersMergePage.page.subtitle')} />
      <UsersMergePageContent />
    </div>
  );
};

export default UsersMergePage;
