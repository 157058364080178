import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import PlanWidgetBase from '@palette/components/widgets/dashboard/PlanWidgetBase/PlanWidgetBase';

import { formatWidgetPrice } from '@palette/helpers/DashboardHelper';

import * as DashboardComponentDataModel from '@palette/models/DashboardComponentData';
import * as PlanDetailsDataModel from '@palette/models/widgets/dashboard/PlanDetailsData';
import * as PlanPeriodDataModel from '@palette/models/widgets/dashboard/PlanPeriodData';

import styles from './PlanDetailsWidget.less';

const classNames = bindClassNames.bind(styles);

const PlanDetailsWidget = ({ className, widgetData, widgetIndex, readOnly, enableLinks }) => {
  const { t } = useTranslation();

  const planPeriodData = PlanPeriodDataModel.transform(widgetData.data);
  const planDetailsData = PlanDetailsDataModel.transform(widgetData.data);

  return (
    <PlanWidgetBase
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      widgetIndex={widgetIndex}
      useMostRecent={widgetData.dashboardComponent.params.useMostRecent}
      plan={planPeriodData.plan}
      period={planPeriodData.period}
      readOnly={readOnly}
      enableLinks={enableLinks}
    >
      <div className={styles.content}>
        <div className={styles.valueArea}>
          <div className={styles.title}>{t('planDetailsWidget.commissions')}</div>
          <div className={styles.amount}>
            {formatWidgetPrice(planDetailsData?.totalCommissionAmount, planDetailsData?.currency)}
          </div>
        </div>
        <div className={styles.valueArea}>
          <div className={styles.title}>{pluralize(planPeriodData.plan.trackingObject?.type || '')}</div>
          <div className={styles.amount}>
            {planDetailsData?.totalObjects > 0 ? planDetailsData.totalObjects : '-'}
          </div>
        </div>
      </div>
    </PlanWidgetBase>
  );
};

PlanDetailsWidget.propTypes = {
  className: PropTypes.string,
  widgetData: DashboardComponentDataModel.propTypes.isRequired,
  widgetIndex: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
  enableLinks: PropTypes.bool,
};

PlanDetailsWidget.defaultProps = {
  className: '',
  readOnly: false,
  enableLinks: true,
};

export default PlanDetailsWidget;
