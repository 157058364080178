import { slice } from '@palette/state/Statements/slice';
import { loop, clean } from '@palette/state/Statements/sagas';

export { actions, selectors } from '@palette/state/Statements/slice';

export default {
  slice,
  loop,
  clean,
};
