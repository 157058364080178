import PropTypes from 'prop-types';

import { manageAmountAttribute } from '@palette/helpers/ModelHelper';

export const modelName = 'PlanCountObjectsData';

export const propTypes = PropTypes.shape({
  totalObjects: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const transform = (planCountObjectsData) => {
  if (!planCountObjectsData) {
    return null;
  }

  return (
    {
      totalObjects: manageAmountAttribute(planCountObjectsData, 'dealsCount'),
    }
  );
};
