import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import OnboardUsersFlowPageGeneralContent from '@palette/components/userOnboarding/OnboardUsersFlowPageGeneralContent/OnboardUsersFlowPageGeneralContent';

import {
  ONBOARDING_USERS_FLOWS,
  ONBOARDING_USERS_FROM_RESOURCES_FLOW_FIRST_STEP,
} from '@palette/constants/user';

import { actions as UsersActions } from '@palette/state/Users';

const OnboardUsersFromResourcesFlowPageContent = ({ className }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UsersActions.setOnboardUsersFlow({ onboardUsersFlow: ONBOARDING_USERS_FLOWS.FROM_RESOURCES, onboardUsersCurrentFlowStep: ONBOARDING_USERS_FROM_RESOURCES_FLOW_FIRST_STEP }));
  }, []);

  return (
    <OnboardUsersFlowPageGeneralContent className={className} />
  );
};

OnboardUsersFromResourcesFlowPageContent.propTypes = {
  className: PropTypes.string,
};

OnboardUsersFromResourcesFlowPageContent.defaultProps = {
  className: '',
};

export default OnboardUsersFromResourcesFlowPageContent;
