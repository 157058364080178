import React, { PureComponent } from 'react';
import moment from 'moment';
import {
  Rate, Button, message, Input, Divider,
} from 'antd';
import PropTypes from 'prop-types';
import { sendNPSFeedback } from '../services/user';
import { sendEvent, sendIdentify } from '../services/analytics';

class NPSRating extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rate: null,
      error: false,
      alreadyClicked: false,
      loading: false,
      explanation: null,
      errorExplanation: false,
    };
  }

  onChange = (value) => this.setState({
    rate: value,
    errorExplanation: false,
    error: false,
  });

  onChangeExplanation = (e) => this.setState({
    explanation: e.target.value,
    errorExplanation: false,
    error: false,
  });

  getTextForExplanation = (rate) => {
    if (rate <= 9) {
      return 'What can we do to make things right ? Please be direct :)';
    }
    return "We're thrilled you feel that way, what's the main reason for your score?";
  };

  saveRate = () => {
    const { rate, explanation } = this.state;
    if (!rate) {
      this.setState({ alreadyClicked: true, error: true, errorExplanation: false });
    } else if ((!explanation || explanation.length === 0)) {
      this.setState({ alreadyClicked: true, errorExplanation: true, error: false });
    } else {
      this.setState({ loading: true, errorExplanation: false, error: false }, () => {
        const score = rate - 1; // Rate from antd will go from 1 to 11, we want from 0 to 10
        sendNPSFeedback({
          score,
          explanation,
        }).then(() => {
          message.success('Thanks a lot for your feedback.');
          const npsData = {
            npsScore: score,
            npsExplanation: explanation,
            npsDate: moment().toISOString(),
          };
          sendIdentify(npsData);
          sendEvent('new NPS', npsData);
        }).finally(() => {
          this.setState({ loading: false }, () => {
            this.props.close();
          });
        });
      });
    }
  };

  render() {
    const {
      rate, alreadyClicked, error,
      loading, explanation, errorExplanation,
    } = this.state;
    return (
      <div
        className="center"
        style={{
          marginTop: '20px',
        }}
      >
        <Rate
          className="width-100"
          autoFocus
          value={rate}
          onChange={this.onChange}
          count={11}
          character={(props) => (
            <div
              key={props.index}
              style={{
                border: '1px solid #e2e2e2',
                borderRadius: '50%',
                width: '34px',
                height: '34px',
                borderColor: (props.value && props.value > props.index) ? '#1f79cc' : '#e2e2e2',
                background: (props.value && props.value > props.index) ? '#1f79cc' : 'white',
                color: (props.value && props.value > props.index) ? 'white' : '#e2e2e2',
                fontWeight: 'bold',
                fontSize: '16px',
                lineHeight: '33px',
              }}
            >
              {props.index}
            </div>
          )}
        />
        <div
          className="flex left grey"
          style={{
            marginLeft: '8px',
            marginRight: '8px',
            marginBottom: '4px',
            marginTop: '8px',
          }}
        >
          <div style={{ flexGrow: '1' }}>Not at all likely</div>
          <div>Extremely likely</div>
        </div>
        {error && <div style={{ color: 'red' }}>Please rate your experience with Palette.</div>}
        {(rate) ? (
          <div style={{
            textAlign: 'left',
          }}
          >
            <Divider />
            <div className="bold left" style={{ marginBottom: '6px', fontSize: '16px' }}>
              {this.getTextForExplanation(rate)}
            </div>
            <Input.TextArea
              row={3}
              value={explanation}
              placeholder="Anything to share with us..."
              onChange={this.onChangeExplanation}
            />
          </div>
        ) : null}
        {errorExplanation && (
          <div style={{ color: 'red', textAlign: 'left' }}>
            Please help us improve by sharing your experience.
          </div>
        )}

        {rate && (
          <div className="flex width-100" style={{ marginTop: '26px', justifyContent: 'center' }}>
            <Button
              disabled={alreadyClicked && (error || errorExplanation)}
              type="primary"
              block
              onClick={this.saveRate}
              loading={loading}
            >
              Send Feedback
            </Button>

          </div>
        )}
      </div>
    );
  }
}

NPSRating.propTypes = {
  close: PropTypes.func.isRequired,
};

export default NPSRating;
