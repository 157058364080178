import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import InfoCircleLine from '@palette/components/utils/Icons/InfoCircleLine';

import styles from './PeriodDealCommissionValue.less';

const classNames = bindClassNames.bind(styles);

const PeriodDealCommissionValue = ({ className, value, formattedValue, hasDot, hasOnlyTargetRule }) => {
  const { t } = useTranslation();

  const contentNode = useMemo(() => {
    if (value === 0) {
      if (hasOnlyTargetRule) {
        return (
          <div className={styles.tooltipWrapper}>
            -
            <Tooltip title={t('periodDealCommissionValue.tooltip')}>
              <InfoCircleLine className={styles.tooltipIcon} />
            </Tooltip>
          </div>
        );
      }

      return (
        <div className={styles.zeroValue}>
          {formattedValue}
        </div>
      );
    }

    return formattedValue;
  }, [value, formattedValue, hasOnlyTargetRule]);

  const dotNode = useMemo(() => {
    if (!hasDot) return null;

    return (
      <div className={styles.dot} />
    );
  }, [hasDot]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
      {dotNode}
    </div>
  );
};

PeriodDealCommissionValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number.isRequired,
  formattedValue: PropTypes.string.isRequired,
  hasDot: PropTypes.bool,
  hasOnlyTargetRule: PropTypes.bool,
};

PeriodDealCommissionValue.defaultProps = {
  className: '',
  hasDot: false,
  hasOnlyTargetRule: false,
};

export default PeriodDealCommissionValue;
