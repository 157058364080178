import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Link from '@palette/components/designSystem/Link/Link';
import EyesLine from '@palette/components/utils/Icons/EyesLine';
import UserStatementCorrectionsItemActions from '@palette/components/statement/userStatement/UserStatementCorrectionsItemActions/UserStatementCorrectionsItemActions';
import CorrectionSuggestedAmount from '@palette/components/statement/userStatement/CorrectionSuggestedAmount/CorrectionSuggestedAmount';

import { usePeriodsDatesFilterAndCurrencyQSObject, useStatementUserIdInParams } from '@palette/hooks/StatementHooks';

import { getPeriodName } from '@palette/helpers/StatementHelper';

import routePaths from '@palette/config/routePaths';

import { STATEMENT_EVENTS } from '@palette/constants/analytics';

import { actions as AnalyticsActions } from '@palette/state/Analytics';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as UserStatementCorrectionModel from '@palette/models/UserStatementCorrection';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import styles from './UserStatementCorrectionsItem.less';

const classNames = bindClassNames.bind(styles);

const UserStatementCorrectionsItem = ({ className, userStatement, statementPeriod, correction, currency }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const { userId } = useStatementUserIdInParams();

  const { from } = correction;

  const periodShortName = getPeriodName(from.periodStrategy, from.period, from.year);

  const reason = t('userStatementCorrections.content.default.reason', { dates: periodShortName });

  const handleClick = useCallback(() => {
    dispatch(AnalyticsActions.sendEvent({
      event: STATEMENT_EVENTS.CLICK_INVESTIGATE_LINK,
      params: {
        STATEMENT_PERIOD_ID: statementPeriod.id,
        USER_ID: userId,
        CORRECTION_STATEMENT_ID: from.statementId,
      },
    }));
  }, [statementPeriod, userId, from]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.content}>
        <Link
          className={styles.link}
          path={routePaths.v2.statementsUserStatement}
          params={{ statementPeriodId: from.periodId, userId }}
          qsObject={periodsDatesFilterQSObject}
        >
          {reason}
        </Link>
        <CorrectionSuggestedAmount correction={correction} />
      </div>
      <div className={styles.actions}>
        <Link
          className={styles.link}
          path={routePaths.v2.StatementsInvestigateCorrection}
          params={{
            statementPeriodId: statementPeriod.id,
            userId,
            correctionStatementId: from.statementId,
          }}
          qsObject={periodsDatesFilterQSObject}
          iconNode={<EyesLine className={styles.icon} width={18} height={18} />}
          displayIcon
          iconIsAfter={false}
          onClick={handleClick}
        >
          {t('userStatementCorrections.actions.investigate')}
        </Link>
        <UserStatementCorrectionsItemActions
          userStatement={userStatement}
          statementPeriod={statementPeriod}
          correction={correction}
          reason={reason}
          currency={currency}
        />
      </div>
    </div>
  );
};

UserStatementCorrectionsItem.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes.isRequired,
  statementPeriod: StatementPeriodModel.propTypes.isRequired,
  correction: UserStatementCorrectionModel.propTypes.isRequired,
  currency: PropTypes.string,
};

UserStatementCorrectionsItem.defaultProps = {
  className: '',
  currency: null,
};

export default UserStatementCorrectionsItem;
