import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Tabs from '@palette/components/designSystem/Tabs/Tabs';
import AddDataConnectionDataSourceConnectors from '@palette/components/planV3/DataSources/AddDataConnectionDataSourceConnectors/AddDataConnectionDataSourceConnectors';

import {
  PLAN_V3_ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_QS_KEY,
  PLAN_V3_ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_IDS,
} from '@palette/constants/tabs';

import { actions as PlanV3Actions } from '@palette/state/PlanV3';

import styles from './AddDataConnectionSelectDataSourceStep.less';

const classNames = bindClassNames.bind(styles);

const AddDataConnectionSelectDataSourceStep = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PlanV3Actions.resetNewDataConnectionInfo());
  }, []);

  const tabs = useMemo(() => {
    const finalTabs = [];

    finalTabs.push({
      tabKey: PLAN_V3_ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_IDS.CONNECTORS,
      title: t('addDataConnectionSelectDataSourceStep.tabs.connectors'),
      content: (<AddDataConnectionDataSourceConnectors />),
      defaultActive: true,
    }, {
      tabKey: PLAN_V3_ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_IDS.MODELS,
      title: t('addDataConnectionSelectDataSourceStep.tabs.models'),
      content: (<AddDataConnectionDataSourceConnectors isModelsFor />),
      defaultActive: false,
    });

    return finalTabs;
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tabs
        tabs={tabs}
        qsTabKey={PLAN_V3_ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_QS_KEY}
      />
    </div>
  );
};

AddDataConnectionSelectDataSourceStep.propTypes = {
  className: PropTypes.string,
};

AddDataConnectionSelectDataSourceStep.defaultProps = {
  className: '',
};

export default AddDataConnectionSelectDataSourceStep;
