import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@palette/components/designSystem/Button/Button';
import AddFilled from '@palette/components/utils/Icons/AddFilled';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import { actions as DashboardPresetsActions } from '@palette/state/DashboardPresets';

import styles from './NewDashboardPresetButton.less';

const classNames = bindClassNames.bind(styles);

const NewDashboardPresetButton = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const onSuccessCB = useCallback((presetId) => {
    redirectTo({ path: routePaths.v2.dashboardPresetDetails, params: { presetId } });
  }, []);

  const handleCreateNewDashboard = useCallback(() => {
    dispatch(DashboardPresetsActions.createDashboardPreset({ onSuccessCB }));
  }, []);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE])) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Button
        type="primaryBlue"
        icon={<AddFilled />}
        onClick={handleCreateNewDashboard}
      >
        {t('newDashboardPresetButton.label')}
      </Button>
    </div>
  );
};

NewDashboardPresetButton.propTypes = {
  className: PropTypes.string,
};

NewDashboardPresetButton.defaultProps = {
  className: '',
};

export default NewDashboardPresetButton;
