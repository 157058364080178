import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import pluralize from 'pluralize';
import _sortBy from 'lodash/sortBy';

import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import TeamFilled from '@palette/components/utils/Icons/TeamFilled';
import MedicalChartFilled from '@palette/components/utils/Icons/MedicalChartFilled';

import * as ConnectorModel from '@palette/models/Connector';

import styles from './CreatePlanFlowResourceSelector.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowResourceSelector = ({
  className,
  connector,
  value,
  onChange,
  disabled,
  ...otherProps
}) => {
  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    if (onChange !== null) {
      onChange(newValue);
    }
  });

  const resourcesOptions = useMemo(() => (
    _sortBy(connector.resources, ['isUser'], ['desc']).map((resource) => ({
      label: pluralize(resource.type),
      value: resource.type,
      isUser: resource.isUser,
    }), [])
  ), [connector]);

  const resourcesNodes = useMemo(() => (
    resourcesOptions.map((resourcesOption) => {
      const selected = value === resourcesOption.value;

      let iconNode = (
        <MedicalChartFilled className={styles.icon} width={24} height={24} />
      );

      if (resourcesOption.isUser) {
        iconNode = (
          <TeamFilled className={styles.icon} width={24} height={24} />
        );
      }

      return (
        <div
          key={resourcesOption.value}
          className={classNames({
            resourceCardWrapper: true,
            disabled,
            selected,
          })}
          onClick={() => handleChange(resourcesOption.value)}
        >
          <div className={styles.iconWrapper}>
            {iconNode}
          </div>
          <div className={styles.label}>
            {resourcesOption.label}
          </div>
          {selected && (
            <CheckFilled
              className={styles.checkedIcon}
              width={20}
              height={20}
            />
          )}
        </div>
      );
    })
  ), [resourcesOptions, value, handleChange]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      {resourcesNodes}
    </div>
  );
};

CreatePlanFlowResourceSelector.propTypes = {
  className: PropTypes.string,
  connector: ConnectorModel.propTypes.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CreatePlanFlowResourceSelector.defaultProps = {
  className: '',
  value: null,
  onChange: null,
  disabled: false,
};

export default CreatePlanFlowResourceSelector;
