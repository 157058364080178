// V2 ADMIN
import AuditTrailPage from '@palette/pages/admin/AuditTrailPage/AuditTrailPage';
import CommissionListPage from '@palette/pages/admin/CommissionListPage/CommissionListPage';
import CreatePlanFlowPage from '@palette/pages/admin/CreatePlanFlowPage/CreatePlanFlowPage';
import DashboardPresetsPage from '@palette/pages/admin/DashboardPresetsPage/DashboardPresetsPage';
import DashboardPresetDetailsPage from '@palette/pages/admin/DashboardPresetDetailsPage/DashboardPresetDetailsPage';
import MasterPlansComparatorPage from '@palette/pages/admin/MasterPlansComparatorPage/MasterPlansComparatorPage';
import MasterPlansPage from '@palette/pages/admin/MasterPlansPage/MasterPlansPage';
import MasterPlanDetailsPage from '@palette/pages/admin/MasterPlanDetailsPage/MasterPlanDetailsPage';
import MasterPlanSettingsPage from '@palette/pages/admin/MasterPlanSettingsPage/MasterPlanSettingsPage';
import MasterPlanPeriodDetailsPage from '@palette/pages/admin/MasterPlanPeriodDetailsPage/MasterPlanPeriodDetailsPage';
import NotebookDetailsPage from '@palette/pages/admin/NotebookDetailsPage/NotebookDetailsPage';
import NotebooksPage from '@palette/pages/admin/NotebooksPage/NotebooksPage';
import OnboardUsersManualInputFlowPage from '@palette/pages/admin/OnboardUsersManualInputFlowPage/OnboardUsersManualInputFlowPage';
import OnboardUsersFromResourcesFlowPage from '@palette/pages/admin/OnboardUsersFromResourcesFlowPage/OnboardUsersFromResourcesFlowPage';
import QuotasPage from '@palette/pages/admin/QuotasPage/QuotasPage';
import QuotasPerformancePage from '@palette/pages/admin/QuotasPerformancePage/QuotasPerformancePage';
import ResourcePage from '@palette/pages/admin/ResourcePage/ResourcePage';
import ResourceDetailsPage from '@palette/pages/admin/ResourceDetailsPage/ResourceDetailsPage';
import RoleDetailsPage from '@palette/pages/admin/RoleDetailsPage/RoleDetailsPage';
import RolesPage from '@palette/pages/admin/RolesPage/RolesPage';
import StatementsPage from '@palette/pages/admin/StatementsPage/StatementsPage';
import StatementsPeriodPage from '@palette/pages/admin/StatementsPeriodPage/StatementsPeriodPage';
import StatementsUserStatementPage from '@palette/pages/admin/StatementsUserStatementPage/StatementsUserStatementPage';
import StatementsValidationsExportPage from '@palette/pages/admin/StatementsValidationsExportPage/StatementsValidationsExportPage';
import StatementsInvestigateCorrectionPage from '@palette/pages/admin/StatementsInvestigateCorrectionPage/StatementsInvestigateCorrectionPage';
import TeamDetailsPage from '@palette/pages/admin/TeamDetailsPage/TeamDetailsPage';
import TeamsPage from '@palette/pages/admin/TeamsPage/TeamsPage';
import UserDetailsPage from '@palette/pages/admin/UserDetailsPage/UserDetailsPage';
import UsersPage from '@palette/pages/admin/UsersPage/UsersPage';
import UsersMergePage from '@palette/pages/admin/UsersMergePage/UsersMergePage';
import UsersVariablesPage from '@palette/pages/admin/UsersVariablesPage/UsersVariablesPage';

// Plans V3
import PlanV3Page from '@palette/pages/admin/PlanV3Page/PlanV3Page';
import PlanV3CreatePage from '@palette/pages/admin/PlanV3CreatePage/PlanV3CreatePage';
import PlanV3RequirementsPage from '@palette/pages/admin/PlanV3RequirementsPage/PlanV3RequirementsPage';
import PlanV3EditAchievementPage from '@palette/pages/admin/PlanV3EditAchievementPage/PlanV3EditAchievementPage';
import PlanV3EditCommissionPage from '@palette/pages/admin/PlanV3EditCommissionPage/PlanV3EditCommissionPage';

// Statement V3
import StatementV3Page from '@palette/pages/admin/StatementV3Page/StatementV3Page';
import StatementV3PeriodPage from '@palette/pages/admin/StatementV3PeriodPage/StatementV3PeriodPage';
import StatementV3UserStatementPage from '@palette/pages/admin/StatementV3UserStatementPage/StatementV3UserStatementPage';

// V2 Global
import MaintenancePage from '@palette/pages/global/MaintenancePage/MaintenancePage';

// V2 IC
import MyCommissionListPage from '@palette/pages/ic/MyCommissionListPage/MyCommissionListPage';
import MyDashboardPage from '@palette/pages/ic/MyDashboardPage/MyDashboardPage';
import MyMasterPlansPage from '@palette/pages/ic/MyMasterPlansPage/MyMasterPlansPage';
import MyMasterPlanDetailsPage from '@palette/pages/ic/MyMasterPlanDetailsPage/MyMasterPlanDetailsPage';
import MyMasterPlanPeriodDetailsPage from '@palette/pages/ic/MyMasterPlanPeriodDetailsPage/MyMasterPlanPeriodDetailsPage';
import MyStatementsPage from '@palette/pages/ic/MyStatementsPage/MyStatementsPage';

// V2 IC V3
import MyPlanV3Page from '@palette/pages/ic/MyPlanV3Page/MyPlanV3Page';
import MyPlanV3RequirementsPage from '@palette/pages/ic/MyPlanV3RequirementsPage/MyPlanV3RequirementsPage';
import MyStatementsV3Page from '@palette/pages/ic/MyStatementsV3Page/MyStatementsV3Page';
import MyStatementsV3UserStatementPage from '@palette/pages/ic/MyStatementsV3UserStatementPage/MyStatementsV3UserStatementPage';

// V2 Shared Admin + IC
import ProfilePage from '@palette/pages/shared/ProfilePage/ProfilePage';
import SettingsPage from '@palette/pages/shared/SettingsPage/SettingsPage';

import routePaths from '@palette/config/routePaths';

// V1
import CommissionPayments from '../../pages/app/admin/commission-payments';
import ConnectorDetails from '../../pages/app/admin/connector-details';
import Connectors from '../../pages/app/admin/connectors';
import ConnectSalesforce from '../../pages/app/admin/connect-salesforce';
import ReconciliationDetails from '../../pages/app/admin/reconciliation-details';
import Reconciliations from '../../pages/app/admin/reconciliations';

export const routes = {
  commissionPayments: {
    path: routePaths.commissionPayments,
    component: CommissionPayments,
  },
  connectors: {
    path: routePaths.connectors,
    component: Connectors,
  },
  connectorDetails: {
    path: routePaths.connectorDetails,
    component: ConnectorDetails,
  },
  connectSalesforce: {
    path: routePaths.connectSalesforce,
    component: ConnectSalesforce,
  },
  reconciliationDetails: {
    path: routePaths.reconciliationDetails,
    component: ReconciliationDetails,
  },
  reconciliations: {
    path: routePaths.reconciliations,
    component: Reconciliations,
  },
  // Admin
  v2AuditTrail: {
    path: routePaths.v2.auditTrail,
    component: AuditTrailPage,
  },
  v2CommissionList: {
    path: routePaths.v2.commissionList,
    component: CommissionListPage,
  },
  v2Comparator: {
    path: routePaths.v2.comparator,
    component: MasterPlansComparatorPage,
  },
  v2CreatePlanFlow: {
    path: routePaths.v2.createPlanFlow,
    component: CreatePlanFlowPage,
  },
  v2DashboardPresetDetails: {
    path: routePaths.v2.dashboardPresetDetails,
    component: DashboardPresetDetailsPage,
  },
  v2DashboardPresets: {
    path: routePaths.v2.dashboardPresets,
    component: DashboardPresetsPage,
  },
  v2OnboardUsersFromResourcesFlow: {
    path: routePaths.v2.onboardUsersFromResourcesFlow,
    component: OnboardUsersFromResourcesFlowPage,
  },
  v2OnboardUsersManualInputFlow: {
    path: routePaths.v2.onboardUsersManualInputFlow,
    component: OnboardUsersManualInputFlowPage,
  },
  v2NotebookDetails: {
    path: routePaths.v2.notebookDetails,
    component: NotebookDetailsPage,
  },
  v2Notebooks: {
    path: routePaths.v2.notebooks,
    component: NotebooksPage,
  },
  v2PlanDetails: {
    path: routePaths.v2.planDetails,
    component: MasterPlanDetailsPage,
  },
  v2PlanSettings: {
    path: routePaths.v2.planSettings,
    component: MasterPlanSettingsPage,
  },
  v2PlanPeriodDetails: {
    path: routePaths.v2.planPeriodDetails,
    component: MasterPlanPeriodDetailsPage,
  },
  v2Plans: {
    path: routePaths.v2.plans,
    component: MasterPlansPage,
  },
  v2PlanV3: {
    path: routePaths.v2.planV3,
    component: PlanV3Page,
  },
  v2PlanV3Create: {
    path: routePaths.v2.planV3Create,
    component: PlanV3CreatePage,
  },
  v2PlanV3EditAchievement: {
    path: routePaths.v2.planV3EditAchievementById,
    component: PlanV3EditAchievementPage,
  },
  v2PlanV3EditCommission: {
    path: routePaths.v2.planV3EditCommissionById,
    component: PlanV3EditCommissionPage,
  },
  v2PlanV3Requirements: {
    path: routePaths.v2.planV3RequirementsById,
    component: PlanV3RequirementsPage,
  },
  v2StatementV3: {
    path: routePaths.v2.statementV3,
    component: StatementV3Page,
  },
  v2StatementV3Period: {
    path: routePaths.v2.statementV3Period,
    component: StatementV3PeriodPage,
  },
  v2StatementV3UserStatement: {
    path: routePaths.v2.statementV3UserStatement,
    component: StatementV3UserStatementPage,
  },
  v2Quotas: {
    path: routePaths.v2.quotas,
    component: QuotasPage,
  },
  v2QuotaDetails: {
    path: routePaths.v2.quotaDetails,
    component: QuotasPage,
  },
  v2QuotasPerformance: {
    path: routePaths.v2.quotasPerformance,
    component: QuotasPerformancePage,
  },
  v2QuotasPerformanceDetails: {
    path: routePaths.v2.quotasPerformanceDetails,
    component: QuotasPerformancePage,
  },
  v2Resources: {
    path: routePaths.v2.resources,
    component: ResourcePage,
  },
  v2ResourcesByConnectorId: {
    path: routePaths.v2.resourcesByConnectorId,
    component: ResourcePage,
  },
  v2ResourceDetails: {
    path: routePaths.v2.resourceDetails,
    component: ResourceDetailsPage,
  },
  v2RoleDetails: {
    path: routePaths.v2.roleDetails,
    component: RoleDetailsPage,
  },
  v2Roles: {
    path: routePaths.v2.roles,
    component: RolesPage,
  },
  v2Statements: {
    path: routePaths.v2.statements,
    component: StatementsPage,
  },
  v2StatementsPeriod: {
    path: routePaths.v2.statementsPeriod,
    component: StatementsPeriodPage,
  },
  v2StatementsUserStatement: {
    path: routePaths.v2.statementsUserStatement,
    component: StatementsUserStatementPage,
  },
  v2StatementsValidationsExport: {
    path: routePaths.v2.statementsValidationsExport,
    component: StatementsValidationsExportPage,
  },
  v2StatementsInvestigateCorrection: {
    path: routePaths.v2.StatementsInvestigateCorrection,
    component: StatementsInvestigateCorrectionPage,
  },
  v2TeamDetails: {
    path: routePaths.v2.teamDetails,
    component: TeamDetailsPage,
  },
  v2Teams: {
    path: routePaths.v2.teams,
    component: TeamsPage,
  },
  v2UsersMerge: {
    path: routePaths.v2.usersMerge,
    component: UsersMergePage,
  },
  v2UserDetails: {
    path: routePaths.v2.userDetails,
    component: UserDetailsPage,
  },
  v2Users: {
    path: routePaths.v2.users,
    component: UsersPage,
  },
  v2Variables: {
    path: routePaths.v2.variables,
    component: UsersVariablesPage,
  },
  // Global
  V2Maintenance: {
    path: routePaths.v2.maintenance,
    component: MaintenancePage,
  },
  // IC
  v2MyCommissionList: {
    path: routePaths.v2.myCommissionList,
    component: MyCommissionListPage,
  },
  v2MyDashboard: {
    path: routePaths.v2.myDashboard,
    component: MyDashboardPage,
  },
  v2MyPlans: {
    path: routePaths.v2.myPlans,
    component: MyMasterPlansPage,
  },
  v2MyPlanDetails: {
    path: routePaths.v2.myPlanDetails,
    component: MyMasterPlanDetailsPage,
  },
  v2MyPlanPeriodDetails: {
    path: routePaths.v2.myPlanPeriodDetails,
    component: MyMasterPlanPeriodDetailsPage,
  },
  v2MyStatementDetails: {
    path: routePaths.v2.myStatementDetails,
    component: MyStatementsPage,
  },
  v2MyStatements: {
    path: routePaths.v2.myStatements,
    component: MyStatementsPage,
  },
  // IC V3
  v2MyPlansV3: {
    path: routePaths.v2.myPlansV3,
    component: MyPlanV3Page,
  },
  v2MyPlanV3Requirements: {
    path: routePaths.v2.myPlansV3RequirementsById,
    component: MyPlanV3RequirementsPage,
  },
  v2MyStatementsV3: {
    path: routePaths.v2.myStatementsV3,
    component: MyStatementsV3Page,
  },
  v2MyStatementsV3UserStatement: {
    path: routePaths.v2.myStatementsV3UserStatement,
    component: MyStatementsV3UserStatementPage,
  },
  // Shared Admin + IC
  v2Profile: {
    path: routePaths.v2.profile,
    component: ProfilePage,
  },
  v2Settings: {
    path: routePaths.v2.settings,
    component: SettingsPage,
  },
};

export const getRoutesList = () => Object.values(routes);

export default routes;
