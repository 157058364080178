import React from 'react';
import PropTypes from 'prop-types';

import { Progress as AntProgress } from 'antd';

import bindClassNames from 'classnames/bind';

import styles from './Progress.less';

const classNames = bindClassNames.bind(styles);

export const PROGRESS_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
};

const Progress = ({ className, type, ...otherProps }) => (
  <AntProgress
    className={classNames({
      wrapper: true,
      warning: type === PROGRESS_TYPES.WARNING,
      danger: type === PROGRESS_TYPES.DANGER,
      info: type === PROGRESS_TYPES.INFO,
      [className]: className !== '',
    })}
    showInfo={false}
    {...otherProps}
  />
);

Progress.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(PROGRESS_TYPES)),
};

Progress.defaultProps = {
  className: '',
  type: PROGRESS_TYPES.SUCCESS,
};

export default Progress;
