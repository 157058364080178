import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import MasterPlanBreadcrumb from '@palette/components/masterPlan/MasterPlanBreadcrumb/MasterPlanBreadcrumb';
import MasterPlanPeriodDetailsPageContent from '@palette/components/masterPlanPeriod/MasterPlanPeriodDetailsPageContent/MasterPlanPeriodDetailsPageContent';
import MasterPlanTopActions from '@palette/components/masterPlan/MasterPlanTopActions/MasterPlanTopActions';
import MasterPlanRecomputeWarning from '@palette/components/masterPlan/MasterPlanRecomputeWarning/MasterPlanRecomputeWarning';
import MasterPlanDependencies from '@palette/components/masterPlan/MasterPlanDependencies/MasterPlanDependencies';
import MasterPlanHiddenInfo from '@palette/components/masterPlan/MasterPlanHiddenInfo/MasterPlanHiddenInfo';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useMasterPlanPeriodInParams } from '@palette/hooks/MasterPlanHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';
import { getPlanPeriodName, isDraft } from '@palette/helpers/MasterPlanHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import { RIGHTS } from '@palette/constants/profile';

import routePaths from '@palette/config/routePaths';

import styles from './MasterPlanPeriodDetailsPage.less';

const MasterPlanPeriodDetailsPage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const { plan, period } = useMasterPlanPeriodInParams();

  useEffect(() => {
    if (plan !== null && isDraft(plan)) {
      redirectTo({ path: routePaths.v2.plans });
    }
  }, [plan]);

  const planName = plan?.name || '';
  const periodName = (plan !== null && period !== null) ? getPlanPeriodName(plan, period) : '';

  const actionsNode = useMemo(() => {
    if (plan === null || period === null) return null;

    return (
      <MasterPlanTopActions className={styles.actions} plan={plan} period={period} />
    );
  }, [plan, period]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.PLANS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag
        title={t('app.metas.masterPlanPeriodDetailsTitle', { planName, periodName })}
      />
      <MasterPlanBreadcrumb className={styles.breadcrumb} plan={plan} period={period} />
      <PageTitle>
        <h3>{plan?.name || ''}</h3>
        {actionsNode}
      </PageTitle>
      <MasterPlanDependencies injections={plan?.injections} />
      <MasterPlanHiddenInfo isLive={plan?.live} planId={plan?.id} />
      <MasterPlanRecomputeWarning plan={plan} />
      <MasterPlanPeriodDetailsPageContent />
    </div>
  );
};

export default MasterPlanPeriodDetailsPage;
