import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';
import _debounce from 'lodash/debounce';

import Button from '@palette/components/designSystem/Button/Button';
import Input from '@palette/components/designSystem/Input/Input';
import RawDataHistoryDrawer from '@palette/components/resources/RawDataHistoryDrawer/RawDataHistoryDrawer';
import RawDataTree from '@palette/components/resources/RawDataTree/RawDataTree';
import RawDataDocumentationLink from '@palette/components/resources/RawDataDocumentationLink/RawDataDocumentationLink';

import { filterDataBySearch, removeEmptyObjectsAndArrays } from '@palette/helpers/ResourceHelper';

import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';
import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './RawData.less';

const classNames = bindClassNames.bind(styles);

/**
 * This component can be used for resourceObject data OR simple json as well.
 * If connectorId, objectId and type are given in entries it will be a resourceObject.
 * Else, data prop can be passed in entry to display raw data of a simple json, example:
 * data={
 *  type: 'My object title',
 *  data: {
 *    property: 'value',
 *    ...
 *  },
 * }
 */
const RawData = ({
  className,
  data,
  hasSearch,
  hasHistoryCTA,
  hasDocumentationLink,
  onlyReadable,
  connectorId,
  objectId,
  type,
  isExpandedByDefault,
  expandAllByDefault,
  manageDiff,
  recursivityDepth,
  maxNbOfAutoExpandedArrayItems,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [trimmedValue, setTrimmedValue] = useState('');

  const resource = useSelector((state) => ResourcesSelectors.getResourceById(state, { resourceId: objectId }));

  useEffect(() => {
    if (connectorId && objectId && type) {
      dispatch(ResourcesActions.getById({ connectorId, objectId, type }));
    }
  }, [connectorId, objectId, type]);

  const performSearch = useCallback(
    _debounce((newSearchedValue) => {
      setTrimmedValue(newSearchedValue.trim());
    }, 700),
    [],
  );

  const handleSearchChange = useCallback((newSearchedValue) => {
    setSearchValue(newSearchedValue);
    performSearch(newSearchedValue);
  }, []);

  const rawData = useMemo(() => (resource || data), [resource, data]);
  const showActionButtons = useMemo(() => (!!(!onlyReadable && resource)), [onlyReadable, resource]);
  const showHistoryButton = useMemo(() => (!!(hasHistoryCTA && resource)), [hasHistoryCTA, resource]);

  const handleHistoryClick = useCallback(() => {
    const qsObject = {
      history: {
        connectorId,
        objectId,
        type,
      },
    };
    dispatch(NavigationActions.addQSToLocation({ qsObject }));
  }, [
    connectorId,
    objectId,
    type,
  ]);

  const filteredRawData = useMemo(() => ({
    ...rawData,
    data: trimmedValue && trimmedValue.length > 0 && !manageDiff
      ? removeEmptyObjectsAndArrays(filterDataBySearch(trimmedValue, rawData.data))
      : rawData.data,
  }), [
    trimmedValue,
    rawData,
    manageDiff,
  ]);

  if (!filteredRawData.data) {
    return null;
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {((hasSearch && !manageDiff) || showHistoryButton || hasDocumentationLink) && (
        <div
          className={classNames({
            header: true,
            justifyRight: !hasSearch,
          })}
        >
          {hasSearch && !manageDiff && (
            <Input
              className={styles.search}
              type="search"
              placeholder={t('rawData.search.placeholder')}
              onChange={handleSearchChange}
              value={searchValue}
              noPadding
            />
          )}
          <div className={styles.flexEndRow}>
            {hasDocumentationLink && <RawDataDocumentationLink className={styles.documentationLink} />}
            {showHistoryButton && (
              <Button type="secondary" className={styles.historyButton} onClick={handleHistoryClick}>
                {t('rawData.versionHistory.button')}
              </Button>
            )}
          </div>
        </div>
      )}
      <RawDataTree
        data={filteredRawData}
        resourceId={resource?.id}
        expandRootByDefault={isExpandedByDefault}
        expandAllByDefault={expandAllByDefault}
        showActionButtons={showActionButtons}
        hasSearchValue={trimmedValue !== ''}
        manageDiff={manageDiff}
        recursivityDepth={recursivityDepth}
        maxNbOfAutoExpandedArrayItems={maxNbOfAutoExpandedArrayItems}
      />
      <RawDataHistoryDrawer showActionButtons={showActionButtons} />
    </div>
  );
};

RawData.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  connectorId: PropTypes.string,
  objectId: PropTypes.string,
  type: PropTypes.string,
  hasSearch: PropTypes.bool,
  hasHistoryCTA: PropTypes.bool,
  hasDocumentationLink: PropTypes.bool,
  onlyReadable: PropTypes.bool,
  isExpandedByDefault: PropTypes.bool,
  expandAllByDefault: PropTypes.bool,
  manageDiff: PropTypes.bool,
  recursivityDepth: PropTypes.number,
  maxNbOfAutoExpandedArrayItems: PropTypes.number,
};

RawData.defaultProps = {
  className: '',
  data: {},
  connectorId: '',
  objectId: '',
  type: '',
  hasSearch: false,
  hasHistoryCTA: false,
  hasDocumentationLink: false,
  onlyReadable: false,
  isExpandedByDefault: true,
  expandAllByDefault: false,
  manageDiff: false,
  recursivityDepth: null,
  maxNbOfAutoExpandedArrayItems: 10,
};

export default RawData;
