import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import apiConfig from '@palette/config/api';

import { RIGHTS } from '@palette/constants/profile';
import { FOLDER_TYPES } from '@palette/constants/folders';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* getList(payload) {
  const { type } = payload;

  const data = { type };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.folders.list, data, getConfig()),
    [RIGHTS.ADMIN.TEAMS.VIEW_ALL, RIGHTS.ADMIN.TEAMS.VIEW_SCOPED],
  );
}

export function* createFolder(payload) {
  const { type, name } = payload;

  const data = { type, name };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.folders.create, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE],
  );
}

export function* renameFolder(payload) {
  const {
    folderId,
    folderType = FOLDER_TYPES.PLAN,
    folderParent = null,
    name,
  } = payload;

  const data = { folderId, name };
  if (folderParent === null) {
    data.type = folderType;
  } else {
    data.parentId = folderParent;
  }

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.folders.update, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE],
  );
}

export function* deleteFolder(payload) {
  const { folderId } = payload;

  const data = { folderId };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.folders.delete, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE],
  );
}

export function* moveFolder(payload) {
  const { folderId, parentId, type } = payload;

  const data = { folderId, parentId, type };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.folders.update, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE],
  );
}

export function* moveEntities(payload) {
  const { folderId, entityIds } = payload;

  const data = { folderId, entityIds };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.folders.moveEntities, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE],
  );
}

export function* updatePlanFolder(payload) {
  const { folderId, planId } = payload;

  const data = { folderId };

  const endpoint = apiConfig.ENDPOINTS.planV3.folderUpdate.replace('{planId}', planId);

  return yield checkEndpointRights(
    () => ApiService.put(endpoint, data, getConfig()),
    [RIGHTS.ADMIN.PLANS.CREATE, RIGHTS.ADMIN.PLANS.DELETE],
  );
}
