import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _unionBy from 'lodash/unionBy';
import _differenceBy from 'lodash/differenceBy';
import _intersection from 'lodash/intersection';
import _find from 'lodash/find';

import Loader from '@palette/components/utils/Loader/Loader';
import Table from '@palette/components/designSystem/Table/Table';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import TeamSelector from '@palette/components/team/TeamSelector/TeamSelector';
import Input from '@palette/components/designSystem/Input/Input';
import Checkbox from '@palette/components/designSystem/Checkbox/Checkbox';
import NoUserTypeConnectorsAlert from '@palette/components/connector/NoUserTypeConnectorsAlert/NoUserTypeConnectorsAlert';
import UserConnectorsSelect from '@palette/components/user/UserConnectorsSelect/UserConnectorsSelect';
import FastOnboardLink from '@palette/components/user/FastOnboardLink/FastOnboardLink';

import { useCompanyMetaUsers } from '@palette/hooks/UserHooks';

import { filterOnlyActiveUsers } from '@palette/helpers/UserHelper';

import { ALL_USERS_CONNECTOR_VALUE, CONNECTOR_RESOURCE_SEPARATOR } from '@palette/constants/connector';

import * as MetaUserModel from '@palette/models/MetaUser';

import { selectors as UsersSelectors } from '@palette/state/Users';
import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';
import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './UsersSelectionPanel.less';

const classNames = bindClassNames.bind(styles);

const UsersSelectionPanel = ({
  className,
  preSelectedUsers,
  onChange,
  restrictToConnectorIdAndType,
  restrictToUsersWithAccount,
  disableUserConnectorsSelection,
  disableFastOnboarding,
  onFastOnboardLinkClick,
  onlyActiveUsers,
}) => {
  const { t } = useTranslation();

  const [selectedConnector, setSelectedConnector] = useState(
    restrictToConnectorIdAndType?.connectorId && restrictToConnectorIdAndType?.type
      ? `${restrictToConnectorIdAndType.connectorId}${CONNECTOR_RESOURCE_SEPARATOR}${restrictToConnectorIdAndType.type}`
      : ALL_USERS_CONNECTOR_VALUE,
  );
  const [selectedConnectorId, setSelectedConnectorId] = useState(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.connectorId : '');
  const [selectedConnectorType, setSelectedConnectorType] = useState(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.type : '');
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [searchedUser, setSearchedUser] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([...preSelectedUsers]);

  useEffect(() => {
    setSelectedConnector(
      restrictToConnectorIdAndType?.connectorId && restrictToConnectorIdAndType?.type
        ? `${restrictToConnectorIdAndType.connectorId}${CONNECTOR_RESOURCE_SEPARATOR}${restrictToConnectorIdAndType.type}`
        : ALL_USERS_CONNECTOR_VALUE,
    );
    setSelectedConnectorId(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.connectorId : '');
    setSelectedConnectorType(restrictToConnectorIdAndType !== null ? restrictToConnectorIdAndType.type : '');
  }, [restrictToConnectorIdAndType]);

  useEffect(() => {
    setSelectedUsers([...preSelectedUsers]);
  }, [preSelectedUsers.length]);

  const usersListIsPending = useSelector(UsersSelectors.getListIsPending);
  const companyMetaUsers = useCompanyMetaUsers();

  const connectorsList = useSelector(ConnectorsSelectors.getConnectorsWithUsersResources);

  const selectedTeam = useSelector((state) => TeamsSelectors.getTeamById(state, { teamId: selectedTeamId }));

  const data = useMemo(() => {
    let listedUsers = [];

    if (restrictToUsersWithAccount) {
      listedUsers = companyMetaUsers.filter((user) => (user.account !== null));
    } else {
      switch (selectedConnector) {
        case ALL_USERS_CONNECTOR_VALUE: {
          listedUsers = companyMetaUsers;
          break;
        }
        default: {
          listedUsers = companyMetaUsers.filter((user) => {
            if (user.resourceObjects.length === 0) return false;

            return user.resourceObjects.some((resourceObject) => (resourceObject.connectorId === selectedConnectorId && resourceObject.originalType === selectedConnectorType));
          });
        }
      }
    }

    if (onlyActiveUsers) {
      listedUsers = filterOnlyActiveUsers(listedUsers);
    }

    if (selectedTeam !== null) {
      const membersIds = selectedTeam.members.map((teamUser) => teamUser.user.id);
      listedUsers = listedUsers.filter((metaUser) => membersIds.includes(metaUser.id));
    }

    if (searchedUser !== '') {
      listedUsers = listedUsers.filter((metaUser) => (
        metaUser.displayName.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
        || metaUser.email.toLowerCase().indexOf(searchedUser.toLowerCase()) > -1
      ));
    }

    return listedUsers;
  },
  [
    selectedConnector,
    selectedConnectorId,
    selectedConnectorType,
    companyMetaUsers,
    searchedUser,
    selectedTeamId,
    selectedTeam,
    restrictToUsersWithAccount,
    onlyActiveUsers,
  ]);

  const changeSelectedUsers = (newSelectedUsers) => {
    setSelectedUsers(newSelectedUsers);
    onChange(_differenceBy(newSelectedUsers, preSelectedUsers, (metaUser) => metaUser.id));
  };

  const handleAllUsersCheckboxChange = (e) => {
    let newSelectedUsers;

    if (e.target.checked) {
      newSelectedUsers = _unionBy(selectedUsers, data, (metaUser) => metaUser.id);
    } else {
      newSelectedUsers = _differenceBy(selectedUsers, data, (metaUser) => metaUser.id);
      newSelectedUsers = _unionBy(newSelectedUsers, preSelectedUsers, (metaUser) => metaUser.id);
    }

    changeSelectedUsers(newSelectedUsers);
  };

  const handleUserCheckboxChange = (e, metaUser) => {
    let newSelectedUsers = [...selectedUsers];

    if (e.target.checked) {
      newSelectedUsers.push(metaUser);
    } else {
      newSelectedUsers = newSelectedUsers.filter((selectedUser) => selectedUser.id !== metaUser.id);
    }

    changeSelectedUsers(newSelectedUsers);
  };

  const columns = useMemo(
    () => {
      const dataUsersIds = data.map((metaUser) => metaUser.id);
      const selectedUsersIds = selectedUsers.map((metaUser) => metaUser.id);
      const preSelectedUsersIds = preSelectedUsers.map((metaUser) => metaUser.id);
      const intersection = _intersection(dataUsersIds, selectedUsersIds);
      let headerCheckboxProps = { checked: false, indeterminate: false };
      if (dataUsersIds.length > 0 && intersection.length > 0) {
        if (intersection.length === dataUsersIds.length) {
          headerCheckboxProps = { checked: true, indeterminate: false };
        } else {
          headerCheckboxProps = { checked: false, indeterminate: true };
        }
      }

      return ([
        {
          id: 'selector',
          Header: (<Checkbox onChange={handleAllUsersCheckboxChange} {...headerCheckboxProps} />),
          accessor: (user) => user,
          minWidth: 48,
          width: 48,
          /* eslint-disable react/prop-types */
          Cell: ({ value: metaUser }) => (
            <Checkbox
              checked={selectedUsersIds.includes(metaUser.id)}
              onChange={(e) => handleUserCheckboxChange(e, metaUser)}
              disabled={preSelectedUsersIds.includes(metaUser.id)}
            />
          ),
          /* eslint-enable react/prop-types */
        },
        {
          id: 'users',
          Header: t('users_other'),
          accessor: (user) => user,
          minWidth: 200,
          // eslint-disable-next-line react/prop-types
          Cell: ({ value: metaUser }) => (<UserProfile user={metaUser} />),
        },
        {
          id: 'email',
          Header: t('user.email'),
          accessor: 'email',
          minWidth: 290,
        },
      ]);
    },
    [data, selectedUsers, preSelectedUsers],
  );

  const resetFilters = () => {
    setSelectedTeamId(null);
    setSearchedUser('');
  };

  const handleSelectConnector = (value, connectorId, type) => {
    resetFilters();
    setSelectedConnector(value);
    setSelectedConnectorId(connectorId);
    setSelectedConnectorType(type);
  };

  const handleSearchEnterPressed = () => {
    if (searchedUser !== '' && data.length === 1) {
      const metaUser = data[0];

      const preSelectedUserFound = _find(preSelectedUsers, (preSelectedUser) => preSelectedUser.id === metaUser.id);
      if (preSelectedUserFound) return;

      const alreadySelected = _find(selectedUsers, (selectedUser) => selectedUser.id === metaUser.id);

      let newSelectedUsers = [...selectedUsers];
      if (!alreadySelected) {
        newSelectedUsers.push(metaUser);
      } else {
        newSelectedUsers = newSelectedUsers.filter((selectedUser) => selectedUser.id !== metaUser.id);
      }

      changeSelectedUsers(newSelectedUsers);
    }
  };

  const fastOnboardLinkNode = useMemo(() => {
    if (restrictToUsersWithAccount || disableFastOnboarding) return null;

    return (
      <div className={styles.fastOnboardLinkWrapper}>
        <FastOnboardLink onFastOnboardLinkClick={onFastOnboardLinkClick} />
      </div>
    );
  }, [restrictToUsersWithAccount, disableFastOnboarding, onFastOnboardLinkClick]);

  if (usersListIsPending) {
    return (
      <Loader />
    );
  }

  if (connectorsList.length === 0) {
    return (
      <div className={className}>
        <NoUserTypeConnectorsAlert className={styles.warning} />
      </div>
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {
        restrictToConnectorIdAndType === null && !restrictToUsersWithAccount && !disableUserConnectorsSelection && (
          <UserConnectorsSelect
            className={styles.connectorsSelect}
            enableAllUsersOption
            onSelect={handleSelectConnector}
          />
        )
      }
      <div className={styles.filters}>
        <TeamSelector
          selectedTeam={selectedTeamId}
          onTeamSelected={setSelectedTeamId}
        />
        <Input
          className={styles.search}
          type="search"
          placeholder={t('usersSelectionPanel.userSearch.placeholder')}
          onChange={setSearchedUser}
          value={searchedUser}
          onPressEnter={handleSearchEnterPressed}
        />
      </div>
      {fastOnboardLinkNode}
      <div className={styles.table}>
        <Table columns={columns} data={data} stickyHeader fitInContainer centerInContainer />
      </div>
    </div>
  );
};

UsersSelectionPanel.propTypes = {
  className: PropTypes.string,
  preSelectedUsers: PropTypes.arrayOf(MetaUserModel.propTypes),
  onChange: PropTypes.func,
  restrictToConnectorIdAndType: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  restrictToUsersWithAccount: PropTypes.bool,
  disableUserConnectorsSelection: PropTypes.bool,
  disableFastOnboarding: PropTypes.bool,
  onFastOnboardLinkClick: PropTypes.func,
  onlyActiveUsers: PropTypes.bool,
};

UsersSelectionPanel.defaultProps = {
  className: '',
  preSelectedUsers: [],
  onChange: () => {},
  restrictToConnectorIdAndType: null,
  restrictToUsersWithAccount: false,
  disableUserConnectorsSelection: false,
  disableFastOnboarding: false,
  onFastOnboardLinkClick: null,
  onlyActiveUsers: false,
};

export default UsersSelectionPanel;
