import { useEffect } from 'react';

import { useKeyInSearch } from '@palette/hooks/NavigationHooks';

import { getSessionId } from '@palette/helpers/SessionHelper';
import { redirectTo } from '@palette/helpers/NavigationHelper';

import {
  GOOGLE_AUTH_ERROR_DESCRIPTION_QS_KEY,
  GOOGLE_AUTH_ERROR_QS_KEY,
  LOGIN_NEXT_QS_KEY,
  REGISTER_REF_QS_KEY,
} from '@palette/constants/authentication';

export const useGoogleAuthErrorInSearch = () => useKeyInSearch(GOOGLE_AUTH_ERROR_QS_KEY, '', true);
export const useGoogleAuthErrorDescriptionInSearch = () => useKeyInSearch(GOOGLE_AUTH_ERROR_DESCRIPTION_QS_KEY, '', true);

export const useLoginNextInSearch = () => useKeyInSearch(LOGIN_NEXT_QS_KEY, null, true);

export const useRegisterRefInSearch = () => useKeyInSearch(REGISTER_REF_QS_KEY, null, true);

export const useCheckSessionExists = () => useEffect(() => {
  const sessionId = getSessionId();
  if (sessionId != null && sessionId.length > 0) {
    redirectTo({ path: '/' });
  }
}, []);
