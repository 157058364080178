import { all, put, select, takeLatest } from 'redux-saga/effects';

import { GLOBAL_NOTIF_TYPES } from '@palette/constants/globalNotif';

import { actions as GlobalNotifActions, selectors as GlobalNotifSelectors } from '@palette/state/GlobalNotif/slice';

import * as GlobalNotifModel from '@palette/models/GlobalNotif';

export function* addGlobalNotif({ payload = {} }) {
  const notif = GlobalNotifModel.transform(payload);

  if (notif.type === GLOBAL_NOTIF_TYPES.TEMPORARY) {
    yield put(GlobalNotifActions.addTemporaryGlobalNotif(notif));
  } else {
    yield put(GlobalNotifActions.addPermanentGlobalNotif(notif));
  }
}

export function* resetAllPermanentGlobalNotif() {
  const getPermanentNotifs = yield select(GlobalNotifSelectors.getPermanent);

  yield all(
    Object.keys(getPermanentNotifs).map((id) => put(GlobalNotifActions.fadeOutPermanentGlobalNotifFromUI({ id }))),
  );
}

export function* loop() {
  yield all([
    takeLatest(GlobalNotifActions.addGlobalNotif.type, addGlobalNotif),
    takeLatest(GlobalNotifActions.resetAllPermanentGlobalNotif.type, resetAllPermanentGlobalNotif),
  ]);
}

export function* clean() {
  yield put(GlobalNotifActions.resetToInitialState());
}
