import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

import Tag from '@palette/components/designSystem/Tag/Tag';
import ChatFilled from '@palette/components/utils/Icons/ChatFilled';

import { COMMISSION_DRAWER_COMMISION_ID_QS_KEY } from '@palette/constants/commissions';
import { COMMISSION_DRAWER_QS_KEY, COMMISSION_DRAWER_TABS_IDS } from '@palette/constants/tabs';
import { TAG_THEMES } from '@palette/constants/tag';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './PeriodDealCommissionInfoComments.less';

const classNames = bindClassNames.bind(styles);

const PeriodDealCommissionInfoComments = ({ className, onClick, commentsCount, commissionId }) => {
  const dispatch = useDispatch();

  const openCommissionDrawer = useCallback((tabKey) => {
    if (onClick) onClick();
    dispatch(NavigationActions.addQSToLocation({ qsObject: { [COMMISSION_DRAWER_COMMISION_ID_QS_KEY]: commissionId, [COMMISSION_DRAWER_QS_KEY]: tabKey } }));
  }, []);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      onClick={() => openCommissionDrawer(COMMISSION_DRAWER_TABS_IDS.ACTIVITIES)}
    >
      <Tag
        theme={TAG_THEMES.WARNING}
        label={commentsCount > 0 ? commentsCount.toString() : ''}
        icon={ChatFilled}
      />
    </div>
  );
};

PeriodDealCommissionInfoComments.propTypes = {
  className: PropTypes.string,
  commentsCount: PropTypes.number.isRequired,
  commissionId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

PeriodDealCommissionInfoComments.defaultProps = {
  className: '',
  onClick: undefined,
};

export default PeriodDealCommissionInfoComments;
