import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { Popconfirm as AntDPopconfirm } from 'antd';

import styles from './Popconfirm.less';

const classNames = bindClassNames.bind(styles);

const Popconfirm = ({ className, size, placement, ...otherProps }) => (
  <AntDPopconfirm
    overlayClassName={classNames({
      wrapper: true,
      small: size === 'small',
      [className]: className !== '',
    })}
    placement={placement}
    icon={null}
    {...otherProps}
  />
);

Popconfirm.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['default', 'small']),
  placement: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom']),
};

Popconfirm.defaultProps = {
  className: '',
  size: 'default',
  placement: 'top',
};

export default Popconfirm;
