import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import bindClassNames from 'classnames/bind';

import Loader from '@palette/components/utils/Loader/Loader';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import StatementPeriodsCardsListItem from '@palette/components/statement/statementPeriod/StatementPeriodsCardsListItem/StatementPeriodsCardsListItem';

import {
  useStatementPeriodIdInParams,
  useStatementPeriods,
  useStatementUserIdInParams,
} from '@palette/hooks/StatementHooks';

import styles from './StatementPeriodsCardsList.less';

const classNames = bindClassNames.bind(styles);

const MAX_ITEMS_BY_ROW = 6;

const StatementPeriodsCardsList = ({ className, currency, inUserStatement }) => {
  const { t } = useTranslation();

  const { userId } = useStatementUserIdInParams();
  const { listStatementPeriodsIsPending, statementPeriods } = useStatementPeriods(currency, inUserStatement ? userId : null);
  const { statementPeriodId } = useStatementPeriodIdInParams();

  const contentNode = useMemo(() => {
    if (listStatementPeriodsIsPending) return <Loader className={styles.loaderWrapper} />;

    if (statementPeriods.length === 0) {
      return (
        <DefaultEmptyState description={t('statementPeriodsList.noPeriods')} />
      );
    }

    return (
      <div
        className={classNames({
          container: true,
          hasMultipleLines: statementPeriods.length > MAX_ITEMS_BY_ROW,
        })}
      >
        {statementPeriods.map((statementPeriod) => (
          <StatementPeriodsCardsListItem
            key={statementPeriod.id}
            className={styles.period}
            statementPeriod={statementPeriod}
            isSelected={statementPeriod.id === statementPeriodId}
            inUserStatement={inUserStatement}
            userId={userId}
          />
        ))}
      </div>
    );
  }, [
    listStatementPeriodsIsPending,
    statementPeriods,
    statementPeriodId,
    inUserStatement,
    userId,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

StatementPeriodsCardsList.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  inUserStatement: PropTypes.bool,
};

StatementPeriodsCardsList.defaultProps = {
  className: '',
  currency: null,
  inUserStatement: false,
};

export default StatementPeriodsCardsList;
