import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PlusLine from '@palette/components/utils/Icons/PlusLine';
import AddPlansToDashboardModal from '@palette/components/quotaPerformance/AddPlansToDashboardModal/AddPlansToDashboardModal';

import * as QuotaPerformanceModel from '@palette/models/QuotaPerformance';

const AddPlansToDashboard = ({ dashboard }) => {
  const { t } = useTranslation();

  const [addPlansToDashboardModalIsVisible, showAddPlansToDashboardModal] = useState(false);

  return (
    <>
      <Button
        type="secondary"
        icon={<PlusLine width={12} height={12} />}
        onClick={() => showAddPlansToDashboardModal(true)}
      >
        {t('quotasPerformance.addPlansToDashboard.label')}
      </Button>
      {
        addPlansToDashboardModalIsVisible && (
          <AddPlansToDashboardModal
            visible
            onClose={() => showAddPlansToDashboardModal(false)}
            dashboard={dashboard}
          />
        )
      }
    </>
  );
};

AddPlansToDashboard.propTypes = {
  dashboard: QuotaPerformanceModel.propTypes.isRequired,
};

export default AddPlansToDashboard;
