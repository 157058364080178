import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import bindClassNames from 'classnames/bind';

import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import StatementsFilled from '@palette/components/utils/Icons/StatementsFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Tag from '@palette/components/designSystem/Tag/Tag';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useStatementPeriodStatus, useStatementUserIdInParams } from '@palette/hooks/StatementHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight, hasFeature } from '@palette/helpers/ProfileHelper';

import { FEATURES, RIGHTS } from '@palette/constants/profile';
import { STATEMENT_PERIOD_STATUSES, STATEMENT_PERIOD_USER_STATUSES } from '@palette/constants/statements';

import * as UserStatementModel from '@palette/models/UserStatement';
import * as StatementPeriodModel from '@palette/models/StatementPeriod';

import { selectors as StatementsSelectors, actions as StatementsActions } from '@palette/state/Statements';

import styles from './StatementMarkAsPaidAction.less';

const classNames = bindClassNames.bind(styles);

const StatementMarkAsPaidAction = ({
  className,
  userStatement,
  statementPeriod,
  currency,
  isSecondaryType,
}) => {
  const { t } = useTranslation();
  const moment = getMoment();
  const profile = useProfile();
  const dispatch = useDispatch();

  const markStatementsAsPaidIsPending = useSelector(StatementsSelectors.markStatementsAsPaidIsPending);
  const markAsPaidIsPending = useSelector((state) => StatementsSelectors.getMarkAsPaidIsPending(state, { userStatementId: userStatement.id }));
  const markAsPaidIsWaiting = useSelector((state) => StatementsSelectors.getMarkAsPaidIsWaiting(state, { userStatementId: userStatement.id }));

  const approveStatementsIsPending = useSelector(StatementsSelectors.approveStatementsIsPending);

  const statementPeriodStatus = useStatementPeriodStatus(statementPeriod);
  const { userId } = useStatementUserIdInParams();

  const handleMarkAsPaid = useCallback(() => {
    dispatch(StatementsActions.markAsPaidStatements({
      statementIds: [userStatement.id],
      statementPeriodId: statementPeriod.id,
      currency,
      userId,
    }));
  }, [
    userStatement,
    statementPeriod,
    currency,
    userId,
  ]);

  const statusTooltipWrapper = useCallback((children) => {
    const { paidBy } = userStatement;

    if (!userStatement.isPaid || !paidBy) return children;

    const paidByDate = moment(paidBy.date).format('M/DD/YYYY');
    const paidByAmount = formatPrice(paidBy.amount, userStatement.currency || currency);
    const paidByUserName = paidBy.user.displayName;

    return (
      <Tooltip
        title={paidByDate}
        content={(
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('statement.paidBy.tooltip.label', {
                userName: paidByUserName,
                amount: paidByAmount,
              }),
            }}
          />
        )}
      >
        <div>
          {children}
        </div>
      </Tooltip>
    );
  }, [userStatement, currency]);

  const contentNode = useMemo(() => {
    if (!statementPeriodStatus || statementPeriodStatus === STATEMENT_PERIOD_STATUSES.EMPTY_STATEMENTS) return null;

    if (statementPeriodStatus === STATEMENT_PERIOD_STATUSES.IN_FUTURE) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_STATUSES.IN_FUTURE;
      return statusTooltipWrapper(<Tag theme={theme} label={t(i18n)} icon={icon} />);
    }

    if (statementPeriodStatus === STATEMENT_PERIOD_STATUSES.PAID || userStatement.isPaid) {
      const { theme, i18n, icon } = STATEMENT_PERIOD_USER_STATUSES.PAID;
      return statusTooltipWrapper(<Tag theme={theme} label={t(i18n)} icon={icon} />);
    }

    let btnLabel = t('statementPeriodUsers.markAsPaid.button.label');
    if (markAsPaidIsWaiting) {
      btnLabel = t('statementPeriodUsers.markAsPaid.button.waitingLabel');
    }
    if (markAsPaidIsPending) {
      btnLabel = t('statementPeriodUsers.markAsPaid.button.processingLabel');
    }

    return (
      <Popconfirm
        title={t('statementPeriodUsers.markAsPaid.button.popconfirm')}
        onConfirm={handleMarkAsPaid}
        okText={t('common.global.yes')}
        cancelText={t('common.global.no')}
        size="small"
        disabled={markStatementsAsPaidIsPending || approveStatementsIsPending || userStatement.isDirty}
      >
        <Button
          className={classNames({ smallButton: isSecondaryType })}
          type={isSecondaryType ? 'successSecondary' : 'primary'}
          icon={(markAsPaidIsPending || markAsPaidIsWaiting) ? <LoadingLine spin /> : <StatementsFilled />}
          disabled={markStatementsAsPaidIsPending || approveStatementsIsPending || userStatement.isDirty}
        >
          {btnLabel}
        </Button>
      </Popconfirm>
    );
  }, [
    userStatement,
    statementPeriodStatus,
    markStatementsAsPaidIsPending,
    markAsPaidIsPending,
    markAsPaidIsWaiting,
    statusTooltipWrapper,
    handleMarkAsPaid,
    approveStatementsIsPending,
    isSecondaryType,
  ]);

  const contentContainer = useMemo(() => {
    if (!contentNode) return null;

    return (
      <div
        className={classNames({
          wrapper: true,
          [className]: className !== '',
        })}
      >
        {contentNode}
      </div>
    );
  }, [contentNode]);

  if (!hasFeature(profile, FEATURES.STATEMENT_VALIDATION) || !hasAtLeastOneRight(profile, [RIGHTS.ADMIN.STATEMENTS.VALIDATIONS.MANAGE])) return null;

  return contentContainer;
};

StatementMarkAsPaidAction.propTypes = {
  className: PropTypes.string,
  userStatement: UserStatementModel.propTypes,
  statementPeriod: StatementPeriodModel.propTypes,
  currency: PropTypes.string,
  isSecondaryType: PropTypes.bool,
};

StatementMarkAsPaidAction.defaultProps = {
  className: '',
  userStatement: null,
  statementPeriod: null,
  currency: null,
  isSecondaryType: false,
};

export default StatementMarkAsPaidAction;
