import Axios from 'axios';
import { API_URL } from './constants';

const shouldLogAnalytics = () => window
  && window.location
  && window.location.href.indexOf('localhost') === -1;

export const sendEvent = (event, params = {}) => {
  if (shouldLogAnalytics()) {
    const { hash, host, href } = window.location;
    Axios.post(`${API_URL}/users/log-event`, {
      event, type: 'TRACK', params: { ...params, hash, host, href },
    }, {
      headers: { sessionId: localStorage.getItem('sessionId') },
    });
  }
};

export const sendClickOnDocLink = (analyticsDocSource, params = {}) => sendEvent('click on Documentation link', { ...params, source: analyticsDocSource });

export const sendCompanyGroup = (params = {}) => {
  if (shouldLogAnalytics()) {
    Axios.post(`${API_URL}/users/log-event`, {
      type: 'GROUP', params,
    }, {
      headers: { sessionId: localStorage.getItem('sessionId') },
    });
  }
};

export const sendIdentify = (params = {}, onBehalfOf) => {
  if (shouldLogAnalytics()) {
    Axios.post(`${API_URL}/users/log-event`, {
      type: 'IDENTIFY', params, ...(onBehalfOf ? { onBehalfOf } : {}),
    }, {
      headers: { sessionId: localStorage.getItem('sessionId') },
    });
  }
};

export const sendPageVisit = (pageName, params = {}) => {
  if (shouldLogAnalytics()) {
    Axios.post(`${API_URL}/users/log-event`, {
      type: 'PAGE', page: pageName, params,
    }, {
      headers: { sessionId: localStorage.getItem('sessionId') },
    });
  }
};
