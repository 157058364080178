import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import AddLine from '@palette/components/utils/Icons/AddLine';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import Input from '@palette/components/designSystem/Input/Input';

import { DUPLICATE_TYPES } from '@palette/constants/masterPlans';

import styles from './DuplicateMasterPlanTypeSelector.less';

const classNames = bindClassNames.bind(styles);

const DuplicateMasterPlanTypeSelector = ({ className, value, onChange, disabled, ...otherProps }) => {
  const { t } = useTranslation();

  const handleChange = useCallback((newValue) => {
    if (disabled) return;

    if (onChange !== null) {
      onChange(newValue);
    }
  });

  const newPlanNode = (
    <div
      className={classNames({
        cardWrapper: true,
        disabled,
        selected: value === DUPLICATE_TYPES.DUPLICATE,
      })}
      onClick={() => handleChange(DUPLICATE_TYPES.DUPLICATE)}
    >
      <DuplicateFilled className={styles.icon} width={20} height={20} />
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: t('duplicateMasterPlanModal.goal.newPlan.label') }} />
      <div className={styles.description}>
        {t('duplicateMasterPlanModal.goal.newPlan.description')}
      </div>
      <Input className={styles.radio} type="radio" checked={value === DUPLICATE_TYPES.DUPLICATE} />
    </div>
  );

  const newVersionNode = (
    <div
      className={classNames({
        cardWrapper: true,
        disabled,
        selected: value === DUPLICATE_TYPES.LINK,
      })}
      onClick={() => handleChange(DUPLICATE_TYPES.LINK)}
    >
      <AddLine className={styles.icon} width={20} height={20} />
      {/* eslint-disable-next-line react/no-danger */}
      <div className={styles.label} dangerouslySetInnerHTML={{ __html: t('duplicateMasterPlanModal.goal.newVersion.label') }} />
      <div className={styles.description}>
        {t('duplicateMasterPlanModal.goal.newVersion.description')}
      </div>
      <Input className={styles.radio} type="radio" checked={value === DUPLICATE_TYPES.LINK} />
    </div>
  );

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      {...otherProps}
    >
      {newPlanNode}
      {newVersionNode}
    </div>
  );
};

DuplicateMasterPlanTypeSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DuplicateMasterPlanTypeSelector.defaultProps = {
  className: '',
  value: DUPLICATE_TYPES.DUPLICATE,
  onChange: null,
  disabled: false,
};

export default DuplicateMasterPlanTypeSelector;
