import PropTypes from 'prop-types';
import _sortBy from 'lodash/sortBy';

import {
  manageStringAttribute,
  manageNumberAttribute,
  manageArrayAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
} from '@palette/helpers/ModelHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as QuotaUserModel from '@palette/models/QuotaUser';
import * as YearPeriodValueModel from '@palette/models/YearPeriodValue';
import * as QuotaPlanModel from '@palette/models/QuotaPlan';

export const modelName = 'Quota';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
  frequency: PropTypes.number,
  usersCount: PropTypes.number,
  users: PropTypes.arrayOf(QuotaUserModel.propTypes),
  defaultValues: PropTypes.arrayOf(YearPeriodValueModel.propTypes),
  plans: PropTypes.arrayOf(QuotaPlanModel.propTypes),
  archived: PropTypes.bool,
  global: PropTypes.bool,
  beginAt: PropTypes.string,
});

export const manageDefaultValues = (quota) => {
  const defaultValues = manageArrayAttribute(quota, 'defaultValues').map((defaultValue) => YearPeriodValueModel.transform(defaultValue));
  return _sortBy(defaultValues, ['year', 'period']);
};

export const manageQuotaPeriodType = (quota) => (PERIOD_TYPES[manageStringAttribute(quota, 'periodType')] || PERIOD_TYPES.MONTH);

export const transform = (quota) => {
  if (!quota) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(quota, '_id'),
      name: manageStringAttribute(quota, 'name'),
      type: manageStringAttribute(quota, 'type'),
      periodType: manageQuotaPeriodType(quota),
      frequency: manageNumberAttribute(quota, 'frequency'),
      users: QuotaUserModel.transformList(manageArrayAttribute(quota, 'users')),
      usersCount: manageNumberAttribute(quota, 'usersCount'),
      defaultValues: manageDefaultValues(quota),
      plans: QuotaPlanModel.transformList(manageArrayAttribute(quota, 'plans')),
      archived: manageBooleanAttribute(quota, 'archived'),
      global: manageBooleanAttribute(quota, 'global'),
      beginAt: manageDateAttribute(quota, 'beginAt'),
    }
  );
};

export const transformList = (quotas) => quotas.map((quota) => transform(quota));
