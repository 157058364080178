import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import _sortBy from 'lodash/sortBy';

import Select from '@palette/components/designSystem/Select/Select';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useCurrencies } from '@palette/hooks/UserHooks';

import styles from './AllCurrenciesSelect.less';

const classNames = bindClassNames.bind(styles);

const AllCurrenciesSelect = ({ className, disableCompanyCurrencies, ...otherProps }) => {
  const profile = useProfile();
  const [currenciesList] = useCurrencies();

  const { company } = profile.userData;

  const currencyOptions = useMemo(() => {
    if (!currenciesList.length) return null;

    const companyCurrenciesCodes = company.currencies.map((currency) => currency.code);

    const currenciesByCode = currenciesList
      .reduce((res, currency) => {
        if (currency.code === '') return res;

        if (res[currency.code] === undefined) {
          res[currency.code] = {
            code: currency.code,
            name: currency.name,
            countries: [],
          };
        }

        res[currency.code].countries.push(currency.country);

        return res;
      }, {});

    return _sortBy(Object.values(currenciesByCode), ['code'])
      .map((currency) => ({
        label: `${currency.code} - ${currency.name} (${currency.countries.join(', ')})`,
        value: currency.code,
        disabled: disableCompanyCurrencies && companyCurrenciesCodes.includes(currency.code),
      }));
  }, [currenciesList, company, disableCompanyCurrencies]);

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={currencyOptions}
      showSearch
      {...otherProps}
    />
  );
};

AllCurrenciesSelect.propTypes = {
  className: PropTypes.string,
  disableCompanyCurrencies: PropTypes.bool,
};

AllCurrenciesSelect.defaultProps = {
  className: '',
  disableCompanyCurrencies: false,
};

export default AllCurrenciesSelect;
