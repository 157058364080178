import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import FolderPlanPreview from '@palette/components/folders/FolderPlanPreview/MasterPlanPreview';

import { FOLDER_TYPES } from '@palette/constants/folders';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { selectors as PlanV3Selectors } from '@palette/state/PlanV3';

import styles from './ResourcePreview.less';

const classNames = bindClassNames.bind(styles);

const ResourcePreview = ({ className, resourceId, resourceType, isPlanV3 }) => {
  const plan = useSelector((state) => MasterPlansSelectors.getMasterPlanById(state, { masterPlanId: resourceId }));
  const plansList = useSelector((state) => PlanV3Selectors.getList(state));

  const previewNode = useMemo(() => {
    if (resourceType === FOLDER_TYPES.PLAN && (plan !== null || plansList.length)) {
      let planName = plan?.name;

      if (isPlanV3 && !planName) {
        planName = plansList.find((p) => p.id === resourceId)?.name;
      }

      return (
        <FolderPlanPreview planName={planName} />
      );
    }

    return null;
  }, [resourceId, resourceType, plan, plansList, isPlanV3]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {previewNode}
    </div>
  );
};

ResourcePreview.propTypes = {
  className: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf(Object.values(FOLDER_TYPES)).isRequired,
  isPlanV3: PropTypes.bool,
};

ResourcePreview.defaultProps = {
  className: '',
  isPlanV3: false,
};

export default ResourcePreview;
