import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import LoadingOutlined from '@palette/components/utils/Icons/LoadingOutlined';
import Link from '@palette/components/designSystem/Link/Link';
import MasterPlanScopeTags from '@palette/components/masterPlan/MasterPlanScopeTags/MasterPlanScopeTags';
import MasterPlanPeriodProgressDetails from '@palette/components/masterPlan/MasterPlanPeriodProgressDetails/MasterPlanPeriodProgressDetails';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { getFrequencyName, getPlanPeriodName, isArchived } from '@palette/helpers/MasterPlanHelper';
import { formatPrice } from '@palette/helpers/CurrencyHelper';

import routePaths from '@palette/config/routePaths';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MyMasterPlanListItem.less';

const classNames = bindClassNames.bind(styles);

const MyMasterPlanListItem = ({ className, plan }) => {
  const { t } = useTranslation();

  const isLastPeriodStatsPending = useSelector(MasterPlansSelectors.getListLastPeriodsIsPending);

  const nameNode = useMemo(() => {
    let linkNode = (
      <Tooltip title={t('masterPlan.seeLastPeriods')}>
        <Link
          path={routePaths.v2.myPlanDetails}
          params={{ masterPlanId: plan.id }}
          displayIcon
        >
          {plan.name}
        </Link>
      </Tooltip>
    );

    if (plan.periodType === PERIOD_TYPES.CUSTOM) {
      linkNode = (
        <div className={styles.nameLabel}>
          {plan.name}
        </div>
      );
    }

    return (
      <div className={styles.nameWrapper}>
        {linkNode}
      </div>
    );
  }, [plan]);

  const lastPeriodLinkNode = useMemo(() => {
    let lastPeriodLink = null;

    if (plan.lastPeriod !== null) {
      const lastPeriodName = getPlanPeriodName(plan, plan.lastPeriod);

      lastPeriodLink = (
        <Link
          path={routePaths.v2.myPlanPeriodDetails}
          params={{ masterPlanId: plan.id, year: plan.lastPeriod.year, periodId: plan.lastPeriod.period }}
          displayIcon
        >
          {t('masterPlan.lastPeriod.viewPeriod', { periodName: lastPeriodName })}
        </Link>
      );
    }

    return lastPeriodLink;
  }, [plan, isLastPeriodStatsPending]);

  const lastPeriodTotalCommissionNode = useMemo(() => {
    let lastPeriodTotalCommission = null;

    if (isLastPeriodStatsPending) {
      lastPeriodTotalCommission = <LoadingOutlined spin />;
    } else if (plan.lastPeriod !== null) {
      lastPeriodTotalCommission = (
        <>
          <div className={styles.lastPeriodCommission}>
            {t('myMasterPlan.lastPeriod.totalCommission')}
          </div>
          <div className={styles.lastPeriodCommissionValue}>
            {formatPrice(plan.lastPeriod.totalCommissionAmount, plan.lastPeriod.currency)}
          </div>
        </>
      );
    }

    return lastPeriodTotalCommission;
  }, [plan, isLastPeriodStatsPending]);

  const lastPeriodDetailsNode = useMemo(() => {
    let lastPeriodDetails = null;

    if (isLastPeriodStatsPending) {
      lastPeriodDetails = <LoadingOutlined spin />;
    } else if (plan.lastPeriod !== null && plan.quotaId !== null) {
      lastPeriodDetails = (
        <MasterPlanPeriodProgressDetails plan={plan} period={plan.lastPeriod} />
      );
    }

    return lastPeriodDetails;
  }, [plan, isLastPeriodStatsPending]);

  return (
    <div
      className={classNames({
        wrapper: true,
        alignCenter: isArchived(plan),
        [className]: className !== '',
      })}
    >
      <div className={styles.infosWrapper}>
        <div className={styles.namePeriodWrapper}>
          <div className={styles.nameScopeWrapper}>{nameNode}</div>
        </div>
        <div className={styles.detailsContainer}>
          <div className={styles.detailsWrapper}>
            <div className={styles.frequency}>{getFrequencyName(plan)}</div>
            <MasterPlanScopeTags plan={plan} className={styles.scope} />
          </div>
        </div>
      </div>
      <div className={styles.progressWrapper}>
        <div
          className={classNames({
            periodLinkCommissionWrapper: true,
            alignRight: isArchived(plan),
          })}
        >
          <div className={styles.lastPeriodLink}>{lastPeriodLinkNode}</div>
          {!isArchived(plan) && (
            <div className={styles.totalCommissionValue}>{lastPeriodTotalCommissionNode}</div>
          )}
        </div>
        {!isArchived(plan) && (
          <div className={styles.lastPeriodDetails}>{lastPeriodDetailsNode}</div>
        )}
      </div>
    </div>
  );
};

MyMasterPlanListItem.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MyMasterPlanListItem.defaultProps = {
  className: '',
};

export default MyMasterPlanListItem;
