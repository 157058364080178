import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import ForgotPasswordPageContent from '@palette/components/forgotPassword/ForgotPasswordPageContent/ForgotPasswordPageContent';
import GlobalNotif from '@palette/components/globalNotif/GlobalNotif/GlobalNotif';

import { useCheckSessionExists } from '@palette/hooks/AuthenticationHooks';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  useCheckSessionExists();

  return (
    <>
      <MetaTag title={t('app.metas.forgotPasswordTitle')} />
      <GlobalNotif />
      <ForgotPasswordPageContent />
    </>
  );
};

export default ForgotPasswordPage;
