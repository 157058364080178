import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { InputRefForwarded as Input } from '@palette/components/designSystem/Input/Input';

import * as RoleModel from '@palette/models/Role';

import { actions as CompanyActions, selectors as CompanySelectors } from '@palette/state/Company';

import styles from './EditableRoleDescription.less';

const classNames = bindClassNames.bind(styles);

const EditableRoleDescription = ({ className, role }) => {
  const { t } = useTranslation();

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [roleDescription, setRoleDescription] = useState(role.description);

  useEffect(() => {
    setRoleDescription(role.description);
  }, [role.description]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const updateRoleIsPending = useSelector(CompanySelectors.updateRoleIsPending);

  useEffect(() => {
    if (!updateRoleIsPending) {
      setEditing(false);
    }
  }, [updateRoleIsPending]);

  const saveUpdate = () => {
    if (role.description !== roleDescription) {
      dispatch(CompanyActions.updateRole({ roleId: role.id, description: roleDescription }));
    } else {
      setEditing(false);
    }
  };

  const handleInputChange = (newValue) => {
    setRoleDescription(newValue);
  };

  const switchToEditMode = () => {
    setEditing(true);
  };

  if (editing) {
    return (
      <Input
        ref={inputRef}
        className={classNames({
          input: true,
          [className]: className !== '',
        })}
        type="text"
        value={roleDescription}
        onChange={handleInputChange}
        onBlur={saveUpdate}
        onPressEnter={saveUpdate}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        emptyDescription: role.description === '',
        [className]: className !== '',
      })}
      onClick={switchToEditMode}
    >
      {role.description || t('editableRoleDescription.noRoleDescription')}
    </div>
  );
};

EditableRoleDescription.propTypes = {
  className: PropTypes.string,
  role: RoleModel.propTypes.isRequired,
};

EditableRoleDescription.defaultProps = {
  className: '',
};

export default EditableRoleDescription;
