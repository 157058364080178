import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import DuplicateFilled from '@palette/components/utils/Icons/DuplicateFilled';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';

import { actions as ResourcesActions, selectors as ResourcesSelectors } from '@palette/state/Resources';

import * as ResourceObjectModel from '@palette/models/ResourceObject';

import styles from './ResourceDetailsSummaryActionDuplicate.less';

const classNames = bindClassNames.bind(styles);

const ResourceDetailsSummaryActionDuplicate = ({ className, resourceObject }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { connectorId } = useParams();

  const [cloneTooltipVisible, setCloneTooltipVisible] = useState(false);

  const cloneResourceIsPending = useSelector(ResourcesSelectors.cloneIsPending);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleClone = (event) => {
    stopPropagation(event);
    setCloneTooltipVisible(false);
  };

  const cloneResource = useCallback((event) => {
    stopPropagation(event);

    const { id, originalType } = resourceObject;

    dispatch(ResourcesActions.cloneResource({
      connectorId,
      objectId: id,
      originalType,
    }));
  }, [resourceObject, connectorId]);

  const contentNode = useMemo(() => (
    <Popconfirm
      title={t('resources.details.clone.popconfirm.label', { type: resourceObject.type })}
      onConfirm={(event) => cloneResource(event)}
      okText={t('common.global.yes')}
      cancelText={t('common.global.no')}
      size="small"
    >
      <Tooltip
        title={t('resources.details.clone.tooltip.label', { type: resourceObject.type })}
        visible={cloneTooltipVisible}
        onVisibleChange={(visible) => setCloneTooltipVisible(visible)}
      >
        <Button
          className={styles.button}
          type="link"
          icon={cloneResourceIsPending ? <LoadingLine spin /> : <DuplicateFilled className={styles.icon} />}
          onClick={handleClone}
          disabled={cloneResourceIsPending}
        >
          {t('actions.duplicate')}
        </Button>
      </Tooltip>
    </Popconfirm>
  ), [cloneTooltipVisible, cloneResource, cloneResourceIsPending, resourceObject.type]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

ResourceDetailsSummaryActionDuplicate.propTypes = {
  className: PropTypes.string,
  resourceObject: ResourceObjectModel.propTypes.isRequired,
};

ResourceDetailsSummaryActionDuplicate.defaultProps = {
  className: '',
};

export default ResourceDetailsSummaryActionDuplicate;
