import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import FolderTitle, { folderColorPropTypes, folderColorDefaultProps } from '@palette/components/folders/FoldersRecursive/FolderTitle/FolderTitle';

import { selectors as FoldersSelectors } from '@palette/state/Folders';

import styles from './FolderPreview.less';

const classNames = bindClassNames.bind(styles);

const FolderPreview = ({ className, folderId, folderColor }) => {
  const folder = useSelector((state) => FoldersSelectors.getFolderById(state, { folderId }));

  if (folder === null) return null;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <FolderTitle
        className={styles.titleContainer}
        folder={folder}
        isOpen
        fromRoot
        folderColor={folderColor}
      />
    </div>
  );
};

FolderPreview.propTypes = {
  className: PropTypes.string,
  folderId: PropTypes.string.isRequired,
  folderColor: folderColorPropTypes,
};

FolderPreview.defaultProps = {
  className: '',
  folderColor: folderColorDefaultProps,
};

export default FolderPreview;
