import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import StripeFilled from '@palette/components/utils/Icons/StripeFilled';
import CardItem from '@palette/components/settings/CardItem/CardItem';
import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';
import ExternalLink from '@palette/components/designSystem/ExternalLink/ExternalLink';
import CompanyBillingForm from '@palette/components/company/CompanyBillingForm/CompanyBillingForm';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';

import { actions as ProfileActions, selectors as ProfileSelectors } from '@palette/state/Profile';

import styles from './CompanyBilling.less';

const classNames = bindClassNames.bind(styles);

const CompanyBilling = ({ className }) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();

  const { company } = profile.userData;

  const getSubscriptionIsPending = useSelector(ProfileSelectors.getSubscriptionIsPending);

  useEffect(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE]) || !company.stripeSubscriptionId) return;

    dispatch(ProfileActions.getSubscription());
  }, []);

  const contentNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE]) || !company.stripeSubscriptionId) return null;

    if (getSubscriptionIsPending) {
      return (
        <div className={styles.loadingContainer}>
          <LoadingLine className={styles.laoderIcon} width={20} height={20} spin />
        </div>
      );
    }

    if (!company.subscription) {
      return (
        <DefaultEmptyState
          size="compact"
          description={t('companyBilling.block.error.subscription')}
        />
      );
    }

    return (
      <div className={styles.contentWrapper}>
        <CompanyBillingForm
          className={styles.form}
          seats={company.subscription.seats}
          minSeats={company.minSeats}
        />
        {company.subscription.portalUrl && (
          <ExternalLink
            href={company.subscription.portalUrl}
            displayIcon
          >
            {t('companyBilling.block.portal.label')}
          </ExternalLink>
        )}
      </div>
    );
  }, [profile, company, getSubscriptionIsPending]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.COMPANY.MANAGE]) || !company.stripeSubscriptionId) return null;

  return (
    <CardItem
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      title={t('companyBilling.block.title')}
      icon={<StripeFilled width={20} height={20} />}
    >
      {contentNode}
    </CardItem>
  );
};

CompanyBilling.propTypes = {
  className: PropTypes.string,
};

CompanyBilling.defaultProps = {
  className: '',
};

export default CompanyBilling;
