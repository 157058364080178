import PropTypes from 'prop-types';

import {
  manageStringAttribute,
  manageArrayAttribute,
  manageBooleanAttribute,
} from '@palette/helpers/ModelHelper';

import * as DashboardComponentModel from '@palette/models/DashboardComponent';

export const modelName = 'DashboardPreset';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  isDefault: PropTypes.bool,
  dashboardComponents: PropTypes.arrayOf(DashboardComponentModel.propTypes),
});

export const transform = (dashboardPreset) => {
  if (!dashboardPreset) {
    return null;
  }

  const dashboardComponentsInRaw = manageArrayAttribute(dashboardPreset, 'components', null) !== null;

  return (
    {
      id: manageStringAttribute(dashboardPreset, '_id'),
      name: manageStringAttribute(dashboardPreset, 'name'),
      isDefault: manageBooleanAttribute(dashboardPreset, 'default'),
      dashboardComponents: DashboardComponentModel.transformList(manageArrayAttribute(dashboardPreset, 'components')),
      dashboardComponentsInRaw,
    }
  );
};

export const transformList = (dashboardPresets) => dashboardPresets.map((dashboardPreset) => transform(dashboardPreset));

export const merge = (dashboardPreset1, dashboardPreset2) => {
  if (!dashboardPreset2) return dashboardPreset1;
  if (!dashboardPreset1) return dashboardPreset2;

  let mergedDashboardComponents = dashboardPreset1.dashboardComponents;
  if (dashboardPreset2.dashboardComponentsInRaw) {
    mergedDashboardComponents = dashboardPreset2.dashboardComponents;
  }

  return {
    ...dashboardPreset2,
    dashboardComponents: mergedDashboardComponents,
  };
};
