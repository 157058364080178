import { manageGlobalError, parseProblemData } from '@palette/helpers/ErrorHelper';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

const manage402 = (problem) => {
  const managedErrors = {
    'seats.exhausted': {
      code: GLOBAL_NOTIF_REASONS.PLAN_402_SEATS_EXHAUSTED.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage404 = (problem) => {
  const managedErrors = {
    'plan.not.found': {
      code: GLOBAL_NOTIF_REASONS.PLAN_404_PLAN_NOT_FOUND.code,
    },
    'connector.not.found': {
      code: GLOBAL_NOTIF_REASONS.PLAN_404_CONNECTOR_NOT_FOUND.code,
    },
    'parent.plan.not.found': {
      code: GLOBAL_NOTIF_REASONS.PLAN_404_PARENT_PLAN_NOT_FOUND.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

const manage409 = (problem) => {
  const managedErrors = {
    'plan.computation.running': {
      code: GLOBAL_NOTIF_REASONS.PLAN_409_COMPUTE_PENDING.code,
    },
    'plan.is.injected': {
      code: GLOBAL_NOTIF_REASONS.PLAN_409_PLAN_IS_INJECTED.code,
    },
    'incompatible.plan.scope': {
      code: GLOBAL_NOTIF_REASONS.PLAN_409_INCOMPATIBLE_PLAN_SCOPE.code,
    },
  };

  let error = null;
  if (managedErrors[problem.errorId]) {
    error = managedErrors[problem.errorId];
  }

  return error;
};

export const manageError = ({ status, data }) => {
  const problem = parseProblemData(data);

  const managedErrors = {
    402: manage402,
    404: manage404,
    409: manage409,
  };

  let error = null;

  if (managedErrors[status]) {
    error = managedErrors[status](problem);
  }

  if (error !== null) {
    const { code } = error;

    return {
      ...problem,
      message: { code },
    };
  }

  return manageGlobalError({ status, problem });
};
