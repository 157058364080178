import React from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import Progress from '@palette/components/designSystem/Progress/Progress';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import DecreaseFilled from '@palette/components/utils/Icons/DecreaseFilled';
import WarningFilled from '@palette/components/utils/Icons/WarningFilled';

import { floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { getPeriodElapsedTimePercentage, getPeriodProgressBarType } from '@palette/helpers/MasterPlanHelper';

import { PERIOD_PROGRESSBAR_TYPES } from '@palette/constants/masterPlans';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';
import * as MasterPlanModel from '@palette/models/MasterPlan';

import styles from './MasterPlanPeriodProgress.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanPeriodProgress = ({ className, period, plan }) => {
  const { t } = useTranslation();

  const periodProgressPercentage = floatToFixedNumber(period.progress * 100);
  const periodElapsedTimePercentage = getPeriodElapsedTimePercentage(plan, period);
  const periodProgressBarType = getPeriodProgressBarType(periodElapsedTimePercentage, periodProgressPercentage);

  let iconNode = (
    <CheckFilled
      className={classNames({
        progressIcon: true,
        success: true,
      })}
      width={14}
      height={14}
    />
  );
  if (periodProgressBarType === PERIOD_PROGRESSBAR_TYPES.WARNING) {
    iconNode = (
      <DecreaseFilled
        className={classNames({
          progressIcon: true,
          warning: true,
        })}
        width={14}
        height={14}
      />
    );
  }
  if (periodProgressBarType === PERIOD_PROGRESSBAR_TYPES.DANGER) {
    iconNode = (
      <WarningFilled
        className={classNames({
          progressIcon: true,
          danger: true,
        })}
        width={14}
        height={14}
      />
    );
  }

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Tooltip className={styles.tooltip} title={t('masterPlanPeriodProgress.elapsedTime', { percentage: periodElapsedTimePercentage })}>
        {iconNode}
        <Progress className={styles.progressBar} type={periodProgressBarType} percent={period.progress * 100} />
        <div className={styles.progressPercent}>
          {`${periodProgressPercentage}%`}
        </div>
      </Tooltip>
    </div>
  );
};

MasterPlanPeriodProgress.propTypes = {
  className: PropTypes.string,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanPeriodProgress.defaultProps = {
  className: '',
};

export default MasterPlanPeriodProgress;
