import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';

import { MAIN_CONTENT_ID } from '@palette/constants/navigation';

import styles from './LeftMainContentLayout.less';

const classNames = bindClassNames.bind(styles);

const LeftMainContentLayout = ({
  className,
  leftSideClassName,
  leftSideHeader,
  leftSideHeaderClassName,
  leftSideContent,
  leftSideContentClassName,
  mainClassName,
  mainContent,
  mainContentClassName,
  leftSideWidth,
  borderless,
  autoWidth,
}) => {
  const leftSideHeaderNode = useMemo(() => {
    if (leftSideHeader === null) return null;

    return (
      <div
        className={classNames({
          header: true,
          borderless,
          [leftSideHeaderClassName]: leftSideHeaderClassName !== '',
        })}
      >
        {leftSideHeader}
      </div>
    );
  }, [leftSideHeader, borderless]);

  const leftSideContentNode = useMemo(() => {
    if (leftSideContent === null) return null;

    return (
      <div
        className={classNames({
          leftSideContent: true,
          [leftSideContentClassName]: leftSideContentClassName !== '',
        })}
      >
        {leftSideContent}
      </div>
    );
  }, [leftSideContent]);

  const leftSideNode = useMemo(() => {
    if (!leftSideHeaderNode && !leftSideContentNode) return null;

    return (
      <div
        className={classNames({
          leftSide: true,
          [leftSideClassName]: leftSideClassName !== '',
        })}
        style={{
          width: `${leftSideWidth}rem`,
          minWidth: `${leftSideWidth}rem`,
        }}
      >
        {leftSideHeaderNode}
        {leftSideContentNode}
      </div>
    );
  }, [leftSideHeaderNode, leftSideContentNode, leftSideWidth]);

  return (
    <div
      className={classNames({
        wrapper: true,
        autoWidth,
        [className]: className !== '',
      })}
    >
      {leftSideNode}
      <div
        id={MAIN_CONTENT_ID}
        className={classNames({
          main: true,
          [mainClassName]: mainClassName !== '',
        })}
        style={autoWidth ? {
          maxWidth: `calc(100% - ${leftSideWidth}rem)`,
        } : {}}
      >
        <div
          className={classNames({
            mainWrapper: true,
            borderless,
            [mainContentClassName]: mainContentClassName !== '',
          })}
          style={autoWidth ? {
            maxWidth: `calc(140rem - ${leftSideWidth}rem)`,
          } : {}}
        >
          {mainContent}
        </div>
      </div>
    </div>
  );
};

LeftMainContentLayout.propTypes = {
  className: PropTypes.string,
  leftSideClassName: PropTypes.string,
  leftSideHeader: PropTypes.node,
  leftSideHeaderClassName: PropTypes.string,
  leftSideContent: PropTypes.node,
  leftSideContentClassName: PropTypes.string,
  mainClassName: PropTypes.string,
  mainContent: PropTypes.node,
  mainContentClassName: PropTypes.string,
  leftSideWidth: PropTypes.number,
  borderless: PropTypes.bool,
  autoWidth: PropTypes.bool,
};

LeftMainContentLayout.defaultProps = {
  className: '',
  leftSideClassName: '',
  leftSideHeader: null,
  leftSideHeaderClassName: '',
  leftSideContent: null,
  leftSideContentClassName: '',
  mainClassName: '',
  mainContent: null,
  mainContentClassName: '',
  leftSideWidth: 32,
  borderless: false,
  autoWidth: true,
};

export default LeftMainContentLayout;
