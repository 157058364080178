/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

import _cloneDeep from 'lodash/cloneDeep';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _map from 'lodash/map';
import { original } from 'immer';

import * as QuotaPerformanceModel from '@palette/models/QuotaPerformance';

/*
 * Initial State
 */
const initialState = {
  getListIsPending: false,
  getByIdIsPending: false,
  createDashboardIsPending: false,
  updateDashboardIsPending: false,
  deleteDashboardIsPending: false,
  addPlansToDashboardIsPending: false,
  removePlanFromDashboardIsPending: false,
  list: [],
  byId: {},
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'quotasPerformance',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* Get List And Current */
    getListAndCurrent: (state) => {
      state.getListIsPending = true;
      state.getByIdIsPending = true;
    },
    getListAndCurrentCompleted: (state) => {
      state.getListIsPending = false;
      state.getByIdIsPending = false;
    },
    /* Get List */
    getList: (state) => {
      state.getListIsPending = true;
    },
    setList: (state, { payload }) => {
      const { quotasPerformance = [] } = payload;

      state.list = _map(quotasPerformance, 'id');
      state.byId = _merge(state.byId, _keyBy(quotasPerformance, 'id'));
    },
    getListCompleted: (state) => {
      state.getListIsPending = false;
    },
    /* Get By Id */
    getById: (state) => {
      state.getByIdIsPending = true;
    },
    setById: (state, { payload }) => {
      const { quotaPerformance } = payload;

      const clonedById = _cloneDeep(original(state.byId));
      const inStateQuotaPerformance = clonedById[quotaPerformance.id] || null;

      clonedById[quotaPerformance.id] = QuotaPerformanceModel.merge(inStateQuotaPerformance, quotaPerformance);

      state.byId = clonedById;
    },
    getByIdCompleted: (state) => {
      state.getByIdIsPending = false;
    },
    /* Create Dashboard */
    createDashboard: (state) => {
      state.createDashboardIsPending = true;
    },
    createDashboardCompleted: (state) => {
      state.createDashboardIsPending = false;
    },
    /* Update Dashboard */
    updateDashboard: (state) => {
      state.updateDashboardIsPending = true;
    },
    updateDashboardCompleted: (state) => {
      state.updateDashboardIsPending = false;
    },
    /* Delete Dashboard */
    deleteDashboard: (state) => {
      state.deleteDashboardIsPending = true;
    },
    deleteDashboardCompleted: (state) => {
      state.deleteDashboardIsPending = false;
    },
    /* Add Plans to Dashboard */
    addPlansToDashboard: (state) => {
      state.addPlansToDashboardIsPending = true;
    },
    addPlansToDashboardCompleted: (state) => {
      state.addPlansToDashboardIsPending = false;
    },
    /* Remove Plan From Dashboard */
    removePlanFromDashboard: (state) => {
      state.removePlanFromDashboardIsPending = true;
    },
    removePlanFromDashboardCompleted: (state) => {
      state.removePlanFromDashboardIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getProps = (_, props) => props;
const getList = (state) => root(state).list;
const getById = (state) => root(state).byId;

const getListIsPending = (state) => root(state).getListIsPending;
const getByIdIsPending = (state) => root(state).getByIdIsPending;
const createDashboardIsPending = (state) => root(state).createDashboardIsPending;
const updateDashboardIsPending = (state) => root(state).updateDashboardIsPending;
const deleteDashboardIsPending = (state) => root(state).deleteDashboardIsPending;
const addPlansToDashboardIsPending = (state) => root(state).addPlansToDashboardIsPending;
const removePlanFromDashboardIsPending = (state) => root(state).removePlanFromDashboardIsPending;

const getDashboardsList = createSelector(
  [getList, getById],
  (listIds, byId) => listIds.map((quotaPerformanceId) => byId[quotaPerformanceId] || null),
);

const getDashboardById = createSelector(
  [getById, getProps],
  (byId, { quotaPerformanceId }) => byId[quotaPerformanceId] || null,
);

export const selectors = {
  getList,
  getDashboardsList,
  getDashboardById,

  getListIsPending,
  getByIdIsPending,
  updateDashboardIsPending,
  createDashboardIsPending,
  deleteDashboardIsPending,
  addPlansToDashboardIsPending,
  removePlanFromDashboardIsPending,
};
