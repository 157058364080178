import PropTypes from 'prop-types';

import {
  manageArrayAttribute,
  managePureFloatAttribute,
} from '@palette/helpers/ModelHelper';

import * as YearPeriodModel from '@palette/models/YearPeriod';

export const modelName = 'PlanProgressGaugeData';

export const propTypes = PropTypes.shape({
  progress: PropTypes.number,
  target: PropTypes.number,
  beginPeriod: YearPeriodModel.propTypes,
  endPeriod: YearPeriodModel.propTypes,
});

export const transform = (planProgressGaugeData) => {
  if (!planProgressGaugeData) {
    return null;
  }

  const periodsArray = manageArrayAttribute(planProgressGaugeData, 'periods');
  const beginPeriod = YearPeriodModel.transform(periodsArray[0]);
  const endPeriod = YearPeriodModel.transform(periodsArray[periodsArray.length - 1]);

  return (
    {
      progress: managePureFloatAttribute(planProgressGaugeData, 'progress'),
      target: managePureFloatAttribute(planProgressGaugeData, 'target'),
      beginPeriod,
      endPeriod,
    }
  );
};
