import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageBooleanAttribute,
  manageDateAttribute,
  manageFreeShapeObjectAttribute,
  manageNumberAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

import { CONTENT_PARAMS } from '@palette/constants/auditTrail';
import { PERIOD_TYPES } from '@palette/constants/frequencies';
import { STATEMENT_FX_RATES_MODE } from '@palette/constants/profile';

import * as MetaUserModel from '@palette/models/MetaUser';
import * as YearPeriodModel from '@palette/models/YearPeriod';
import * as StatementStrategyModel from '@palette/models/StatementStrategy';

export const modelName = 'AuditTrailContent';

export const propTypes = PropTypes.shape({
  planId: PropTypes.string,
  planName: PropTypes.string,
  previousPlanName: PropTypes.string,
  periodId: PropTypes.string,
  periodDetails: PropTypes.shape({
    period: YearPeriodModel.propTypes,
    strategy: StatementStrategyModel.propTypes,
  }),
  sourcePeriodDetails: PropTypes.shape({
    period: PropTypes.number,
    year: PropTypes.number,
    strategy: StatementStrategyModel.propTypes,
    periodId: PropTypes.string,
  }),
  targetPeriodDetails: PropTypes.shape({
    period: PropTypes.number,
    year: PropTypes.number,
    strategy: StatementStrategyModel.propTypes,
    periodId: PropTypes.string,
  }),
  fromDate: PropTypes.string,
  commissionId: PropTypes.string,
  amount: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
  newAmount: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
  oldAmount: PropTypes.shape({
    value: PropTypes.number,
    currency: PropTypes.string,
  }),
  payee: PropTypes.shape({
    user: MetaUserModel.propTypes,
    genericId: PropTypes.string,
  }),
  user: PropTypes.shape({
    user: MetaUserModel.propTypes,
    id: PropTypes.string,
  }),
  leaveDate: PropTypes.string,
  quotaTrackingType: PropTypes.string,
  roleName: PropTypes.string,
  type: PropTypes.string,
  objectId: PropTypes.string,
  connectorId: PropTypes.string,
  entry: PropTypes.string,
  name: PropTypes.string,
  newSeatsQuantity: PropTypes.number,
  newStatementFxRateMode: PropTypes.oneOf(Object.values(STATEMENT_FX_RATES_MODE)),
  newStatementValidation: PropTypes.bool,
  autoFreezeEnabled: PropTypes.bool,
  freezeAfter: PropTypes.number,
  currency: PropTypes.string,
  usersCount: PropTypes.number,
  quota: PropTypes.shape({
    name: PropTypes.string,
    trackingType: PropTypes.string,
    periodType: PropTypes.oneOf(Object.values(PERIOD_TYPES)),
    frequency: PropTypes.number,
    beginAt: PropTypes.string,
  }),
});

export const getDisplayName = (firstName, lastName) => `${firstName || ''} ${lastName || ''}`.trim() || null;

export const transform = (auditTrailContent) => {
  if (!auditTrailContent) {
    return null;
  }

  const content = auditTrailContent;
  const result = {};

  Object.keys(content).forEach((param) => {
    switch (param) {
      case CONTENT_PARAMS.NEW_AMOUNT:
        result.newAmount = {
          value: manageAmountAttribute(content[param], 'value'),
          currency: manageStringAttribute(content[param], 'currency'),
        };
        break;
      case CONTENT_PARAMS.OLD_AMOUNT:
        result.oldAmount = {
          value: manageAmountAttribute(content[param], 'value'),
          currency: manageStringAttribute(content[param], 'currency'),
        };
        break;
      case CONTENT_PARAMS.AMOUNT:
        result.amount = {
          value: manageAmountAttribute(content[param], 'value'),
          currency: manageStringAttribute(content[param], 'currency'),
        };
        break;
      case CONTENT_PARAMS.PAYEE: {
        const user = MetaUserModel.transform(manageFreeShapeObjectAttribute(content, param));

        result.payee = {
          user,
          genericId: user.id,
        };
        break;
      }
      case CONTENT_PARAMS.USER: {
        const user = MetaUserModel.transform(manageFreeShapeObjectAttribute(content, param));

        result.user = {
          user,
          id: user.id,
        };
        break;
      }
      case CONTENT_PARAMS.NEW_SEATS_QUANTITY:
        result[param] = manageAmountAttribute(content, param);
        break;
      case CONTENT_PARAMS.PERIOD_DETAILS:
        result.periodDetails = {
          period: YearPeriodModel.transform(content[param]),
          strategy: StatementStrategyModel.transform(content[param].strategy),
        };
        break;
      case CONTENT_PARAMS.SOURCE_PERIOD_DETAILS:
        result.sourcePeriodDetails = {
          period: manageNumberAttribute(content[param], 'period'),
          year: manageNumberAttribute(content[param], 'year'),
          strategy: StatementStrategyModel.transform(content[param].strategy),
          periodId: manageStringAttribute(content[param], 'periodId'),
        };
        break;
      case CONTENT_PARAMS.TARGET_PERIOD_DETAILS:
        result.targetPeriodDetails = {
          period: manageNumberAttribute(content[param], 'period'),
          year: manageNumberAttribute(content[param], 'year'),
          strategy: StatementStrategyModel.transform(content[param].strategy),
          periodId: manageStringAttribute(content[param], 'periodId'),
        };
        break;
      case CONTENT_PARAMS.QUOTA:
        result.quota = {
          name: manageStringAttribute(content[param], 'name'),
          trackingType: manageStringAttribute(content[param], 'trackingType'),
          periodType: PERIOD_TYPES[manageStringAttribute(content[param], 'periodType')] || PERIOD_TYPES.MONTH,
          frequency: manageNumberAttribute(content[param], 'frequency'),
          beginAt: manageStringAttribute(content[param], 'beginAt'),
        };
        break;
      case CONTENT_PARAMS.PERIOD_DATE:
        result.fromDate = manageDateAttribute(content, param);
        break;
      case CONTENT_PARAMS.NEW_STATEMENT_VALIDATION:
      case CONTENT_PARAMS.AUTO_FREEZE_ENABLED:
        result[param] = manageBooleanAttribute(content, param);
        break;
      case CONTENT_PARAMS.FREEZE_AFTER:
      case CONTENT_PARAMS.USERS_COUNT:
        result[param] = manageNumberAttribute(content, param);
        break;
      default:
        result[param] = manageStringAttribute(content, param);
    }
  });

  return result;
};

export const transformList = (auditTrailContents) => auditTrailContents.map((auditTrailContent) => transform(auditTrailContent));
