import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import Select from '@palette/components/designSystem/Select/Select';
import NoUserTypeConnectorsAlert from '@palette/components/connector/NoUserTypeConnectorsAlert/NoUserTypeConnectorsAlert';

import { ALL_USERS_CONNECTOR_VALUE, CONNECTOR_RESOURCE_SEPARATOR } from '@palette/constants/connector';

import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './UserConnectorsSelect.less';

const classNames = bindClassNames.bind(styles);

const UserConnectorsSelect = ({
  className,
  enableAllUsersOption,
  onSelect,
  value,
  selectFirstOptionByDefault,
  hideIfSingleChoice,
}) => {
  const { t } = useTranslation();

  let initialSelectedConnectorValue = enableAllUsersOption ? ALL_USERS_CONNECTOR_VALUE : null;

  if (value !== null) {
    initialSelectedConnectorValue = `${value.connectorId}${CONNECTOR_RESOURCE_SEPARATOR}${value.type}`;
  }

  const [selectedConnector, setSelectedConnector] = useState(initialSelectedConnectorValue);

  useEffect(() => {
    let newValue = enableAllUsersOption ? ALL_USERS_CONNECTOR_VALUE : null;

    if (value !== null) {
      newValue = `${value.connectorId}${CONNECTOR_RESOURCE_SEPARATOR}${value.type}`;
    }

    setSelectedConnector(newValue);
  }, [enableAllUsersOption, value]);

  const connectorsList = useSelector(ConnectorsSelectors.getConnectorsWithUsersResources);

  const connectorsOptions = connectorsList.reduce((options, connector) => {
    connector.resources.forEach((resource) => options.push({
      // eslint-disable-next-line react/no-danger
      label: (<span dangerouslySetInnerHTML={{ __html: t('userConnectorsSelect.resourcesOption.label', { type: pluralize(resource.type), connector: connector.name }) }} />),
      value: `${connector.id}${CONNECTOR_RESOURCE_SEPARATOR}${resource.type}`,
    }));

    return options;
  }, []);

  const handleSelectConnector = (newValue) => {
    if (newValue === selectedConnector) return;

    setSelectedConnector(newValue);

    if (onSelect !== null) {
      const [connectorId, type] = newValue.split(CONNECTOR_RESOURCE_SEPARATOR);

      onSelect(newValue, connectorId, type);
    }
  };

  useEffect(() => {
    if (selectFirstOptionByDefault && connectorsOptions.length > 0 && !selectedConnector) {
      const defaultValue = connectorsOptions[0].value;
      const [connectorId, type] = defaultValue.split(CONNECTOR_RESOURCE_SEPARATOR);
      const newValue = `${connectorId}${CONNECTOR_RESOURCE_SEPARATOR}${type}`;

      handleSelectConnector(newValue);
    }
  }, [selectFirstOptionByDefault, connectorsOptions, selectedConnector]);

  if (connectorsList.length === 0) {
    return (
      <NoUserTypeConnectorsAlert className={className} />
    );
  }

  if (enableAllUsersOption) {
    connectorsOptions.unshift({
      label: t('userConnectorsSelect.allUsers'),
      value: ALL_USERS_CONNECTOR_VALUE,
    });
  }

  if (!enableAllUsersOption && selectFirstOptionByDefault && connectorsOptions.length === 1 && hideIfSingleChoice) return null;

  return (
    <Select
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
      options={connectorsOptions}
      value={selectedConnector}
      onSelect={handleSelectConnector}
    />
  );
};

UserConnectorsSelect.propTypes = {
  className: PropTypes.string,
  enableAllUsersOption: PropTypes.bool,
  onSelect: PropTypes.func,
  value: PropTypes.shape({
    connectorId: PropTypes.string,
    type: PropTypes.string,
  }),
  selectFirstOptionByDefault: PropTypes.bool,
  hideIfSingleChoice: PropTypes.bool,
};

UserConnectorsSelect.defaultProps = {
  className: '',
  enableAllUsersOption: false,
  onSelect: null,
  value: null,
  selectFirstOptionByDefault: false,
  hideIfSingleChoice: false,
};

export default UserConnectorsSelect;
