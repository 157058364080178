import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Modal from '@palette/components/designSystem/Modal/Modal';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import QuotaUserDefaultValuesPanel from '@palette/components/quota/QuotaUserDefaultValuesPanel/QuotaUserDefaultValuesPanel';

import { selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './QuotaUserModal.less';

const QuotaUserModal = ({ visible, onClose, quotaUserId, quotaId }) => {
  const quotaUser = useSelector((state) => QuotasSelectors.getQuotaUserByIdAndQuotaId(state, { quotaId, quotaUserId }));

  const cleanAndClose = () => {
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      visible={visible}
      onCancel={cleanAndClose}
      displayFooter={false}
      width="55%"
    >
      <div className={styles.wrapper}>
        <div className={styles.profileWrapper}>
          <UserProfile user={quotaUser.user} vertical clickable linkProps={{ qsObject: { tab: 'quotas' } }} />
        </div>
        <div className={styles.panelsWrapper}>
          <QuotaUserDefaultValuesPanel quotaId={quotaId} quotaUserId={quotaUser.user.id} />
        </div>
      </div>
    </Modal>
  );
};

QuotaUserModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  quotaUserId: PropTypes.string.isRequired,
  quotaId: PropTypes.string.isRequired,
};

QuotaUserModal.defaultProps = {
  visible: false,
  onClose: () => {},
};

export default QuotaUserModal;
