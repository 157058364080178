import PropTypes from 'prop-types';

import {
  managePureFloatAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'PlanRuleBracket';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  _id: PropTypes.string,
  formula: PropTypes.string,
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const transform = (planRuleBracket) => {
  if (!planRuleBracket) {
    return null;
  }

  return (
    {
      id: manageStringAttribute(planRuleBracket, '_id'),
      _id: manageStringAttribute(planRuleBracket, '_id', null),
      formula: manageStringAttribute(planRuleBracket, 'formula'),
      from: managePureFloatAttribute(planRuleBracket, 'from', 0),
      to: managePureFloatAttribute(planRuleBracket, 'to', Infinity, true),
    }
  );
};

export const transformList = (planRuleBrackets) => planRuleBrackets.map((planRuleBracket) => transform(planRuleBracket));
