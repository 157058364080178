import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TextAreaRefForwarded as TextArea } from '@palette/components/designSystem/TextArea/TextArea';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { NOTEBOOK_STATUSES } from '@palette/constants/notebooks';

import * as NotebookModel from '@palette/models/Notebook';

import { selectors as NotebooksSelectors } from '@palette/state/Notebooks';

import styles from './EditableNotebookDescription.less';

const classNames = bindClassNames.bind(styles);

const EditableNotebookDescription = ({ className, notebook, updateDescription }) => {
  const { t } = useTranslation();

  const profile = useProfile();

  const inputRef = useRef(null);

  const [editing, setEditing] = useState(false);
  const [notebookDescription, setNotebookDescription] = useState(notebook.description);

  useEffect(() => {
    setNotebookDescription(notebook.description);
  }, [notebook.description]);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  const editNotebookIsPending = useSelector(NotebooksSelectors.editNotebookIsPending);

  useEffect(() => {
    if (!editNotebookIsPending) {
      setEditing(false);
    }
  }, [editNotebookIsPending]);

  const saveUpdate = useCallback(() => {
    if (notebook.description !== notebookDescription) {
      updateDescription(notebookDescription);
    }

    setEditing(false);
  }, [notebook, notebookDescription, updateDescription]);

  const handleInputChange = useCallback((newValue) => {
    setNotebookDescription(newValue);
  }, []);

  const switchToEditMode = useCallback(() => {
    if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.NOTEBOOKS.MANAGE]) && notebook.status !== NOTEBOOK_STATUSES.ARCHIVED) {
      setEditing(true);
    }
  }, [profile, notebook]);

  const contentNode = useMemo(() => {
    if (editing) {
      return (
        <TextArea
          ref={inputRef}
          className={styles.input}
          value={notebookDescription}
          onChange={handleInputChange}
          onBlur={saveUpdate}
          autoSize={{ minRows: 2 }}
        />
      );
    }

    return (
      <div
        className={classNames({
          displayWrapper: true,
          disabled: notebook.status === NOTEBOOK_STATUSES.ARCHIVED,
          empty: notebook.description === '',
        })}
        onClick={switchToEditMode}
      >
        {notebook.description !== '' ? notebook.description : t('editableNotebookDescription.emptyDescription')}
      </div>
    );
  }, [
    editing,
    inputRef,
    notebookDescription,
    handleInputChange,
    saveUpdate,
    notebook,
    switchToEditMode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

EditableNotebookDescription.propTypes = {
  className: PropTypes.string,
  notebook: NotebookModel.propTypes.isRequired,
  updateDescription: PropTypes.func.isRequired,
};

EditableNotebookDescription.defaultProps = {
  className: '',
};

export default EditableNotebookDescription;
