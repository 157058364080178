import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import pluralize from 'pluralize';

import Table from '@palette/components/designSystem/Table/Table';
import MasterPlanPeriodDealExpandCell from '@palette/components/masterPlanPeriod/MasterPlanPeriodDealExpandCell/MasterPlanPeriodDealExpandCell';
import MyMasterPlanPeriodDealCommissionsTable from '@palette/components/ic/myMasterPlan/MyMasterPlanPeriodDealCommissionsTable/MyMasterPlanPeriodDealCommissionsTable';
import PeriodDealCommissionInfos from '@palette/components/masterPlanPeriod/PeriodDealCommissionInfos/PeriodDealCommissionInfos';
import PeriodDealCommissionValue from '@palette/components/masterPlanPeriod/PeriodDealCommissionValue/PeriodDealCommissionValue';
import DefaultEmptyState from '@palette/components/designSystem/DefaultEmptyState/DefaultEmptyState';

import { useMasterPlanColumns } from '@palette/hooks/MasterPlanHooks';

import { formatPrice } from '@palette/helpers/CurrencyHelper';
import { comaSeparatorFormatter, floatToFixedNumber } from '@palette/helpers/CommonHelper';
import { applyGenericTimezone } from '@palette/helpers/MomentHelper';
import { getPlanValueDefinition } from '@palette/helpers/MasterPlanHelper';
import { getColumnValue } from '@palette/helpers/ConnectorHelper';
import { getCommissionValue, getNameColumn } from '@palette/helpers/CommissionHelper';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as UserStatementPlanPeriodModel from '@palette/models/UserStatementPlanPeriod';

import styles from './MyUserStatementPlanPeriodDealsTable.less';

const classNames = bindClassNames.bind(styles);

const MyUserStatementPlanPeriodDealsTable = ({ className, plan, userStatementPlanPeriod, searchedDeal, inSalesforce }) => {
  const { t } = useTranslation();

  const planColumns = useMasterPlanColumns(plan);

  const dealNameColumn = useMemo(() => {
    if (planColumns.length === 0) return null;

    const nameColumn = getNameColumn(planColumns);

    if (nameColumn === null) return planColumns[0];

    return nameColumn;
  }, [planColumns]);

  const periodDeals = useMemo(() => {
    const planPeriodDeals = userStatementPlanPeriod.deals;

    if (dealNameColumn === null || searchedDeal === '') return planPeriodDeals;

    return planPeriodDeals.filter((periodDeal) => {
      const dealName = getColumnValue(periodDeal.resourceObject?.data || {}, dealNameColumn);

      return dealName.toLowerCase().indexOf(searchedDeal.toLowerCase()) !== -1;
    });
  }, [userStatementPlanPeriod, searchedDeal, dealNameColumn]);

  const fixedColumns = useMemo(() => {
    const cols = [];

    if (planColumns.length === 0) {
      const seeCommissionsColumn = {
        id: 'seeCommissions',
        accessor: (periodDeal) => periodDeal,
        minWidth: 40,
        maxWidth: 40,
        width: '4%',
        /* eslint-disable react/prop-types */
        Cell: ({ value, row }) => {
          if (value.commissions.length < 2) return null;

          const isExpanded = row.isExpanded === true;

          return (
            <MasterPlanPeriodDealExpandCell periodDealOrUserWithCommissions={value} isExpanded={isExpanded} toggleRowExpanded={row.toggleRowExpanded}>
              &nbsp;
            </MasterPlanPeriodDealExpandCell>
          );
        },
        /* eslint-enable react/prop-types */
      };

      cols.push(seeCommissionsColumn);
    }

    if (plan.trackingObject?.valueFormula !== '1') {
      const revenueColumn = {
        id: 'revenue',
        Header: getPlanValueDefinition(plan),
        accessor: (periodDeal) => {
          let dealValue = periodDeal.value;
          if (periodDeal.commissions.length === 1) {
            dealValue = getCommissionValue(periodDeal.commissions[0]);
          }

          return comaSeparatorFormatter(floatToFixedNumber(dealValue));
        },
        minWidth: 100,
        maxWidth: 150,
        width: '15%',
      };

      cols.push(revenueColumn);
    }

    const dateColumn = {
      id: 'date',
      Header: t('masterPlanPeriodDeals.table.headers.date'),
      accessor: (periodDeal) => applyGenericTimezone(periodDeal.date, plan.timezone).format('ll'),
      minWidth: 150,
      maxWidth: 150,
      width: '15%',
    };

    cols.push(dateColumn);

    if (plan.rules.length > 0) {
      const commissionsColumn = {
        id: 'commissions',
        Header: (
          <div className={styles.headerCommissions}>
            {t('userStatementPeriodDeals.table.headers.commissions')}
          </div>
        ),
        accessor: (periodDeal) => periodDeal,
        minWidth: 180,
        maxWidth: 230,
        width: '23%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          const totalAmount = formatPrice(value.totalCommissionAmount, value.currency);
          const hasDot = value.commissions?.some((commission) => commission.overwrittenAmount !== null);

          return (
            <PeriodDealCommissionValue
              value={value.totalCommissionAmount}
              formattedValue={totalAmount}
              hasDot={hasDot}
              hasOnlyTargetRule={value.hasOnlyTargetRule}
            />
          );
        },
        /* eslint-enable react/prop-types */
        highlight: true,
      };

      cols.push(commissionsColumn);
    }

    if (plan.rules.length < 2) {
      const commissionInfosColumn = {
        id: 'commissionInfos',
        accessor: (periodDeal) => periodDeal,
        minWidth: 170,
        maxWidth: 190,
        width: '19%',
        /* eslint-disable react/prop-types */
        Cell: ({ value }) => {
          if (value.commissions.length !== 1) return null;

          return <PeriodDealCommissionInfos commission={value.commissions[0]} displayPayments={false} inSalesforce={inSalesforce} />;
        },
        /* eslint-enable react/prop-types */
      };

      cols.push(commissionInfosColumn);
    }

    return cols;
  }, [
    plan,
    inSalesforce,
    planColumns,
  ]);

  const tableColumns = useMemo(() => {
    const planTableColumns = planColumns.map((column, index) => {
      const planColumnId = `planColumn_${index}_${column.name}`;

      if (index === 0) {
        return {
          id: planColumnId,
          Header: column.name,
          accessor: (periodDeal) => periodDeal,
          minWidth: 150,
          maxWidth: 250,
          width: '25%',
          /* eslint-disable react/prop-types */
          Cell: ({ value, row }) => {
            const isExpanded = row.isExpanded === true;

            return (
              <MasterPlanPeriodDealExpandCell periodDealOrUserWithCommissions={value} isExpanded={isExpanded} toggleRowExpanded={row.toggleRowExpanded}>
                {getColumnValue(value.resourceObject?.data || {}, column)}
              </MasterPlanPeriodDealExpandCell>
            );
          },
          /* eslint-enable react/prop-types */
        };
      }

      return {
        id: planColumnId,
        Header: column.name,
        accessor: (periodDeal) => getColumnValue(periodDeal.resourceObject?.data || {}, column),
        minWidth: 150,
        maxWidth: 250,
        width: 'auto',
      };
    });

    const allColumns = planTableColumns.concat(fixedColumns);

    if (allColumns.length < 7 && planColumns.length !== 0) {
      allColumns[0].minWidth = 250;
      allColumns[0].maxWidth = 300;
      allColumns[0].width = '30%';
    }

    return allColumns;
  }, [
    plan,
    planColumns,
    fixedColumns,
  ]);

  const contentNode = useMemo(() => {
    if (periodDeals.length === 0) {
      return (
        <DefaultEmptyState description={t('userStatementPlanPeriodDealsTable.empty.description', { dealType: pluralize.singular(plan.trackingObject?.type || '') })} />
      );
    }

    let nbOfFixedColumns = 2;
    if (plan.rules.length === 0) {
      nbOfFixedColumns = 1;
    }
    if (plan.rules.length > 1) {
      nbOfFixedColumns = 0;
    }

    return (
      <Table
        type="borderless"
        columns={tableColumns}
        data={periodDeals}
        enableExpandRow
        renderExpandedRow={(row) => (<MyMasterPlanPeriodDealCommissionsTable periodDealOrUserWithCommissions={periodDeals[row.index]} plan={plan} />)}
        nbOfFixedColumns={nbOfFixedColumns}
        fixedColumnsPosition="fromRight"
      />
    );
  }, [
    periodDeals,
    plan,
    tableColumns,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

MyUserStatementPlanPeriodDealsTable.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
  userStatementPlanPeriod: UserStatementPlanPeriodModel.propTypes.isRequired,
  searchedDeal: PropTypes.string,
  inSalesforce: PropTypes.bool,
};

MyUserStatementPlanPeriodDealsTable.defaultProps = {
  className: '',
  searchedDeal: '',
  inSalesforce: false,
};

export default MyUserStatementPlanPeriodDealsTable;
