import _flatMap from 'lodash/flatMap';
import _map from 'lodash/map';
import _differenceWith from 'lodash/differenceWith';

import { getMoment } from '@palette/helpers/MomentHelper';

import {
  ONBOARDING_USERS_FROM_RESOURCES_FLOW_ORDERED_STEPS,
  ONBOARDING_USERS_MANUAL_INPUT_FLOW_ORDERED_STEPS,
  STATUSES,
} from '@palette/constants/user';

export const excludeMetaUsersResourceObjects = (resourceObjectsList, metaUsersList) => {
  const resourceObjectIdsToOmit = _flatMap(metaUsersList, (metaUser) => _map(metaUser.resourceObjects, 'id'));
  return _differenceWith(resourceObjectsList, resourceObjectIdsToOmit, (resourceObject, idToOmit) => resourceObject.id === idToOmit);
};

export const getUserStatus = (metaUser) => {
  if (metaUser.leaveDate !== null) return STATUSES.OFFBOARDED;

  if (metaUser.account === null) return STATUSES.NOT_INVITED;

  if (metaUser.account.pendingInvitation) return STATUSES.PENDING;

  return STATUSES.CONNECTED;
};

export const getOnboardUsersFlowStepIndex = (steps, currentStepValue) => {
  const stepsByValueWithIndex = {};
  steps.forEach((step, index) => {
    stepsByValueWithIndex[step.value] = index;
  });

  return stepsByValueWithIndex[currentStepValue] || 0;
};

export const getOnboardUsersManualInputFlowStepIndex = (currentStepValue) => (
  getOnboardUsersFlowStepIndex(ONBOARDING_USERS_MANUAL_INPUT_FLOW_ORDERED_STEPS, currentStepValue)
);

export const getOnboardUsersFromResourcesFlowStepIndex = (currentStepValue) => (
  getOnboardUsersFlowStepIndex(ONBOARDING_USERS_FROM_RESOURCES_FLOW_ORDERED_STEPS, currentStepValue)
);

export const getNextOnboardUsersManualInputFlowStep = (currentStep) => {
  const stepsByValueWithNext = {};
  ONBOARDING_USERS_MANUAL_INPUT_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByValueWithNext[step.value] = ONBOARDING_USERS_MANUAL_INPUT_FLOW_ORDERED_STEPS[index + 1] || null;
  });

  return stepsByValueWithNext[currentStep] || null;
};

export const getNextOnboardUsersFromResourcesFlowStep = (currentStep) => {
  const stepsByValueWithNext = {};
  ONBOARDING_USERS_FROM_RESOURCES_FLOW_ORDERED_STEPS.forEach((step, index) => {
    stepsByValueWithNext[step.value] = ONBOARDING_USERS_FROM_RESOURCES_FLOW_ORDERED_STEPS[index + 1] || null;
  });

  return stepsByValueWithNext[currentStep] || null;
};

export const onboardUserHasMissingRequiredFields = (onboardUser) => (
  onboardUser.firstName === ''
  || onboardUser.lastName === ''
  || onboardUser.email === ''
  || onboardUser.currency === ''
);

export const filterOnlyActiveUsers = (metaUsers) => {
  const moment = getMoment();
  const now = moment.utc();

  return metaUsers.filter((metaUser) => (
    metaUser.leaveDate === null
    || moment.utc(metaUser.leaveDate).isAfter(now)
  ));
};
