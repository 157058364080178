import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LoadingLine from '@palette/components/utils/Icons/LoadingLine';
import TreeSelectMultiple from '@palette/components/designSystem/TreeSelectMultiple/TreeSelectMultiple';

import { useTeamsTree } from '@palette/hooks/TeamHooks';

import { buildTeamsTreeOptions } from '@palette/helpers/TeamHelper';

import { selectors as TeamsSelectors } from '@palette/state/Teams';

import styles from './TeamSelectorMultiple.less';

const classNames = bindClassNames.bind(styles);

const TeamSelectorMultiple = ({
  className,
  onChange,
  size,
}) => {
  const { t } = useTranslation();
  const teamsListIsPending = useSelector(TeamsSelectors.getListIsPending);
  const teamsTree = useTeamsTree();
  const teamsOptions = useMemo(() => buildTeamsTreeOptions(teamsTree), [teamsTree]);

  const contentNode = useMemo(() => {
    if (teamsListIsPending) {
      return (
        <LoadingLine className={styles.icon} width={24} height={24} spin />
      );
    }

    return (
      <TreeSelectMultiple
        className={styles.treeSelect}
        searchNotFoundLabel={t('teamSelector.searchNotFound')}
        options={teamsOptions}
        onChange={onChange}
        size={size}
      />
    );
  }, [
    teamsListIsPending,
    teamsOptions,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {contentNode}
    </div>
  );
};

TeamSelectorMultiple.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['default', 'big']),
};

TeamSelectorMultiple.defaultProps = {
  className: '',
  onChange: () => {},
  size: 'default',
};

export default TeamSelectorMultiple;
