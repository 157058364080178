/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/*
 * Initial State
 */
const initialState = {
  detailsCommission: null,
  detailsCommissionIsPending: false,
  overwriteAmountIsPending: false,
  overwritePaymentsIsPending: false,
};

/*
 * Slice
 */
export const slice = createSlice({
  name: 'commissionV3',
  initialState,
  reducers: {
    /* Reset to initial state */
    resetToInitialState: (state) => {
      Object.entries(initialState).forEach(([key, val]) => {
        state[key] = val;
      });
    },
    /* getDetailsCommission */
    getDetailsCommission: (state) => {
      state.detailsCommissionIsPending = true;
    },
    setDetailsCommission: (state, { payload }) => {
      const { detailsCommission } = payload;
      state.detailsCommission = detailsCommission;
    },
    getDetailsCommissionCompleted: (state) => {
      state.detailsCommissionIsPending = false;
    },
    /* Overwrite Commission Amount */
    overwriteAmount: (state) => {
      state.overwriteAmountIsPending = true;
    },
    overwriteAmountCompleted: (state) => {
      state.overwriteAmountIsPending = false;
    },
    /* Overwrite Commission Payments */
    overwritePayments: (state) => {
      state.overwritePaymentsIsPending = true;
    },
    overwritePaymentsCompleted: (state) => {
      state.overwritePaymentsIsPending = false;
    },
    /* getICDetailsCommission */
    getICDetailsCommission: (state) => {
      state.detailsCommissionIsPending = true;
    },
    setICDetailsCommission: (state, { payload }) => {
      const { detailsCommission } = payload;
      state.detailsCommission = detailsCommission;
    },
    getICDetailsCommissionCompleted: (state) => {
      state.detailsCommissionIsPending = false;
    },
  },
});

export const { actions } = slice;

/*
 * Selectors
 */
const root = (state) => state[slice.name];
const getDetailsCommission = (state) => root(state).detailsCommission;
const getDetailsCommissionIsPending = (state) => root(state).detailsCommissionIsPending;
const overwriteAmountIsPending = (state) => root(state).overwriteAmountIsPending;
const overwritePaymentsIsPending = (state) => root(state).overwritePaymentsIsPending;

export const selectors = {
  getDetailsCommission,
  getDetailsCommissionIsPending,
  overwriteAmountIsPending,
  overwritePaymentsIsPending,
};
