import {
  call,
  put,
  all,
  select,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3/slice';
import { manageError as manageStatementV3Error } from '@palette/state/StatementV3/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';
import { selectors as ProfileSelectors } from '@palette/state/Profile';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';
import { STATEMENT_CORRECTION_TYPES } from '@palette/constants/StatementV3';

import * as StatementV3Service from '@palette/services/StatementV3Service';

import * as StatementV3ListPeriodModel from '@palette/models/StatementV3ListPeriod';
import * as StatementV3PeriodModel from '@palette/models/StatementV3Period';
import * as StatementV3OneStatementModel from '@palette/models/StatementV3OneStatement';
import * as StatementV3ListCorrectionModel from '@palette/models/StatementV3ListCorrection';
import * as StatementV3ListCommissionModel from '@palette/models/StatementV3ListCommission';

export function* getListPeriods({ payload = {} }) {
  const {
    from,
    to,
    isIC = false,
    currency = '',
    userId = null,
    onSuccessCB = null,
  } = payload;

  const params = { from, to, currency, userId, isIC };

  const callResult = yield call(StatementV3Service.statementV3getListPeriods, params);

  if (callResult.ok) {
    const listPeriods = StatementV3ListPeriodModel.transformList(callResult.data);

    yield put(StatementV3Actions.setListPeriods({ listPeriods }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.getListPeriodsCompleted());
}

export function* getPeriodDetails({ payload = {} }) {
  const { periodId, currency, onSuccessCB = null } = payload;

  const params = { period: periodId, currency };

  const callResult = yield call(StatementV3Service.statementV3getPeriod, params);

  if (callResult.ok) {
    const periodDetails = StatementV3PeriodModel.transform(callResult.data);

    yield put(StatementV3Actions.setPeriodDetails({ periodDetails }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.getPeriodDetailsCompleted());
}

export function* getOneStatement({ payload = {} }) {
  const { statementPeriodId, currency = '', isIC = false, onSuccessCB = null } = payload;

  const params = { statementPeriodId, currency, isIC };

  const callResult = yield call(StatementV3Service.statementV3getOneStatement, params);

  if (callResult.ok) {
    const oneStatement = StatementV3OneStatementModel.transform(callResult.data);

    yield put(StatementV3Actions.setOneStatement({ oneStatement }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.getOneStatementCompleted());
}

export function* addAction({ payload = {} }) {
  const {
    statementPeriodId,
    type,
    currency,
    statementCurrency,
    from,
    to,
    userId = null,
    isForIC = false,
  } = payload;

  const params = { statementPeriodId, type };

  const callResult = yield call(StatementV3Service.statementV3addAction, params);

  if (callResult.ok) {
    const [, periodId] = statementPeriodId.split('-');

    if (isForIC) {
      // TODO: waiting for IC part
    } else {
      yield put(StatementV3Actions.getListPeriods({ from, to, currency: currency ?? statementCurrency, userId }));
      yield take(StatementV3Actions.getListPeriodsCompleted.type);

      yield put(StatementV3Actions.getPeriodDetails({ periodId, currency }));
      yield take(StatementV3Actions.getPeriodDetailsCompleted.type);

      yield put(StatementV3Actions.getOneStatement({ statementPeriodId, currency: currency ?? statementCurrency }));
      yield take(StatementV3Actions.getOneStatementCompleted.type);
    }

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_APPROVE_STATEMENTS_SUCCESS));
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.addActionCompleted());
}

export function* processMultipleActions(payload) {
  const {
    statementPeriodId,
    type,
    currency,
    from,
    to,
    isForIC = false,
  } = payload;

  const profile = yield select(ProfileSelectors.profile);

  const params = { statementPeriodIds: [statementPeriodId], type };

  const callResult = yield call(StatementV3Service.statementV3addMultipleActions, params);

  if (callResult.ok) {
    const [, periodId] = statementPeriodId.split('-');

    if (isForIC) {
      // TODO: waiting for IC part
    } else {
      yield put(StatementV3Actions.getListPeriods({ from, to, currency: currency ?? profile.userData.company.currency }));
      yield take(StatementV3Actions.getListPeriodsCompleted.type);

      yield put(StatementV3Actions.getPeriodDetails({ periodId, currency }));
      yield take(StatementV3Actions.getPeriodDetailsCompleted.type);

      yield put(StatementV3Actions.getOneStatement({ statementPeriodId, currency: currency ?? profile.userData.company.currency }));
      yield take(StatementV3Actions.getOneStatementCompleted.type);
    }
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.updateOngoingBulkStatusIndex({ type }));
}

export function* runMultipleActionsProcess() {
  let ongoingBulkApproveStatus = yield select(StatementV3Selectors.getOngoingBulkApproveStatus);
  let ongoingBulkMarkAsPaidStatus = yield select(StatementV3Selectors.getOngoingBulkMarkAsPaidStatus);

  if (ongoingBulkApproveStatus) {
    while (ongoingBulkApproveStatus.index < ongoingBulkApproveStatus.statementPeriodIds.length) {
      const {
        index,
        statementPeriodIds,
        type,
        currency,
        from,
        to,
        isForIC,
      } = ongoingBulkApproveStatus;
      const statementPeriodId = statementPeriodIds[index];

      yield call(processMultipleActions, {
        statementPeriodId,
        type,
        currency,
        from,
        to,
        isForIC,
      });

      ongoingBulkApproveStatus = yield select(StatementV3Selectors.getOngoingBulkApproveStatus);
    }

    yield put(GlobalNotifActions.addGlobalNotif({
      message: {
        code: GLOBAL_NOTIF_REASONS.STATEMENT_APPROVE_STATEMENTS_SUCCESS.code,
        context: {
          count: ongoingBulkApproveStatus.statementPeriodIds.length,
        },
      },
    }));
  } else if (ongoingBulkMarkAsPaidStatus) {
    while (ongoingBulkMarkAsPaidStatus.index < ongoingBulkMarkAsPaidStatus.statementPeriodIds.length) {
      const {
        index,
        statementPeriodIds,
        type,
        currency,
        from,
        to,
      } = ongoingBulkMarkAsPaidStatus;
      const statementPeriodId = statementPeriodIds[index];

      yield call(processMultipleActions, {
        statementPeriodId,
        type,
        currency,
        from,
        to,
      });

      ongoingBulkMarkAsPaidStatus = yield select(StatementV3Selectors.getOngoingBulkMarkAsPaidStatus);
    }

    yield put(GlobalNotifActions.addGlobalNotif({
      message: {
        code: GLOBAL_NOTIF_REASONS.STATEMENT_MARK_AS_PAID_STATEMENTS_SUCCESS.code,
        context: {
          count: ongoingBulkMarkAsPaidStatus.statementPeriodIds.length,
        },
      },
    }));
  }

  yield put(StatementV3Actions.addMultipleActionsCompleted());
}

export function* addMultipleActions() {
  yield spawn(runMultipleActionsProcess);
}

export function* deleteAction({ payload = {} }) {
  const {
    statementPeriodId,
    type,
    currency,
    statementCurrency,
    from,
    to,
    userId = null,
    isForIC = false,
  } = payload;

  const profile = yield select(ProfileSelectors.profile);

  const params = { statementPeriodId, userId: profile.userData.id, type };

  const callResult = yield call(StatementV3Service.statementV3deleteAction, params);

  if (callResult.ok) {
    const [, periodId] = statementPeriodId.split('-');

    if (isForIC) {
      // TODO: waiting for IC part
    } else {
      yield put(StatementV3Actions.getListPeriods({ from, to, currency: currency ?? statementCurrency, userId }));
      yield take(StatementV3Actions.getListPeriodsCompleted.type);

      yield put(StatementV3Actions.getPeriodDetails({ periodId, currency }));
      yield take(StatementV3Actions.getPeriodDetailsCompleted.type);

      yield put(StatementV3Actions.getOneStatement({ statementPeriodId, currency: currency ?? statementCurrency }));
      yield take(StatementV3Actions.getOneStatementCompleted.type);
    }

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_CANCEL_APPROVAL_SUCCESS));
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.deleteActionCompleted());
}

export function* getListCorrections({ payload = {} }) {
  const { statementId, currency = '', isIC = false, onSuccessCB = null } = payload;

  const params = { statementId, currency, isIC };

  const callResult = yield call(StatementV3Service.statementV3getListCorrections, params);

  if (callResult.ok) {
    const listCorrections = StatementV3ListCorrectionModel.transformList(callResult.data);

    yield put(StatementV3Actions.setListCorrections({ listCorrections }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.getListCorrectionsCompleted());
}

export function* correctionSuggestedValidation({ payload = {} }) {
  const { statementId, correctionId, status, currency, statementCurrency } = payload;

  const params = { statementId, correctionId, status };

  const callResult = yield call(StatementV3Service.statementV3correctionSuggestedValidation, params);

  if (callResult.ok) {
    yield put(StatementV3Actions.getOneStatement({ statementPeriodId: statementId, currency: currency ?? statementCurrency }));
    yield take(StatementV3Actions.getOneStatementCompleted.type);

    if (status === STATEMENT_CORRECTION_TYPES.ACCEPTED) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_APPLY_CORRECTION_SUCCESS));
    } else if (status === STATEMENT_CORRECTION_TYPES.REFUSED) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_IGNORE_CORRECTION_SUCCESS));
    }
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.correctionSuggestedValidationCompleted());
}

export function* correctionDelete({ payload = {} }) {
  const { statementId, correctionId, currency, statementCurrency } = payload;

  const params = { statementId, correctionId };

  const callResult = yield call(StatementV3Service.statementV3correctionDelete, params);

  if (callResult.ok) {
    yield put(StatementV3Actions.getOneStatement({ statementPeriodId: statementId, currency: currency ?? statementCurrency }));
    yield take(StatementV3Actions.getOneStatementCompleted.type);

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_ADJUSTMENT_DELETION_SUCCESS));
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.correctionDeleteCompleted());
}

export function* addEditAdjustment({ payload = {} }) {
  const {
    statementId,
    correctionId = undefined,
    amount,
    currency,
    reason,
    statementCurrency,
    onSuccessCB = null,
  } = payload;

  const params = { statementId, amount, currency, reason };
  let service = StatementV3Service.statementV3correctionCreate;

  if (correctionId) {
    params.correctionId = correctionId;
    service = StatementV3Service.statementV3correctionUpdate;
  }

  const callResult = yield call(service, params);

  if (callResult.ok) {
    yield put(StatementV3Actions.getOneStatement({ statementPeriodId: statementId, currency: currency ?? statementCurrency }));
    yield take(StatementV3Actions.getOneStatementCompleted.type);

    if (correctionId) {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_ADJUSTMENT_UPDATE_SUCCESS));
    } else {
      yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.STATEMENT_ADJUSTMENT_ADD_SUCCESS));
    }

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.addEditAdjustmentCompleted());
}

export function* getListCommissions({ payload = {} }) {
  const { statementId, currency = '', isIC = false, onSuccessCB = null } = payload;

  const params = { statementId, currency, isIC };

  const callResult = yield call(StatementV3Service.statementV3getListCommissions, params);

  if (callResult.ok) {
    const listCommissions = StatementV3ListCommissionModel.transformList(callResult.data);

    yield put(StatementV3Actions.setListCommissions({ listCommissions }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = manageStatementV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(StatementV3Actions.getListCommissionsCompleted());
}

export function* loop() {
  yield all([
    takeLatest(StatementV3Actions.getListPeriods.type, getListPeriods),
    takeLatest(StatementV3Actions.getPeriodDetails.type, getPeriodDetails),
    takeLatest(StatementV3Actions.getOneStatement.type, getOneStatement),
    takeLatest(StatementV3Actions.addAction.type, addAction),
    takeLatest(StatementV3Actions.addMultipleActions.type, addMultipleActions),
    takeLatest(StatementV3Actions.deleteAction.type, deleteAction),
    takeLatest(StatementV3Actions.getListCorrections.type, getListCorrections),
    takeLatest(StatementV3Actions.correctionSuggestedValidation.type, correctionSuggestedValidation),
    takeLatest(StatementV3Actions.correctionDelete.type, correctionDelete),
    takeLatest(StatementV3Actions.addEditAdjustment.type, addEditAdjustment),
    takeLatest(StatementV3Actions.getListCommissions.type, getListCommissions),
  ]);
}

export function* clean() {
  yield put(StatementV3Actions.resetToInitialState());
}
