import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import pluralize from 'pluralize';
import { Form as AntDForm } from 'antd';

import Form from '@palette/components/designSystem/Form/Form';
import FormItem from '@palette/components/designSystem/FormItem/FormItem';
import ResourceConnectorsSelect from '@palette/components/connector/ResourceConnectorsSelect/ResourceConnectorsSelect';
import Button from '@palette/components/designSystem/Button/Button';
import FormulaHelper from '@palette/components/formula/FormulaHelper/FormulaHelper';
import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import QueryBuilder from '@palette/components/designSystem/QueryBuilder/QueryBuilder';
import Input from '@palette/components/designSystem/Input/Input';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { FORMULA_INPUT_TYPES, SUGGESTIONS_TYPES } from '@palette/constants/formula';
import { DATE_FIELDS, MATCHING_FIELDS } from '@palette/constants/connector';
import { EMPTY_DEFAULT_VALUE } from '@palette/constants/trackingObjects';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';
import { selectors as ConnectorsSelectors } from '@palette/state/Connectors';

import styles from './MasterPlanTrackingObjectDefinitionForm.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanTrackingObjectDefinitionForm = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updatePlanTrackingObjectDefinitionIsPending = useSelector(MasterPlansSelectors.updatePlanTrackingObjectDefinitionIsPending);

  const initialConnectorIdType = useMemo(() => {
    if (
      plan.trackingObject?.originalType === EMPTY_DEFAULT_VALUE
      || plan.trackingObject?.connectorId === ''
      || plan.trackingObject?.originalType === ''
    ) return null;

    return {
      connectorId: plan.trackingObject?.connectorId,
      type: plan.trackingObject?.originalType,
    };
  }, [plan]);
  const initialUiType = useMemo(() => (plan.trackingObject?.uiType), [plan]);
  const initialMatchingField = useMemo(() => (plan.trackingObject?.matchingField), [plan]);
  const initialFilterObject = useMemo(() => (plan.trackingObject?.filterObject), [plan]);
  const initialFilterFormula = useMemo(() => (plan.trackingObject?.filterFormula), [plan]);
  const initialDateField = useMemo(() => (plan.trackingObject?.dateField), [plan]);

  const [connectorIdTypeValue, setConnectorIdTypeValue] = useState(initialConnectorIdType);
  const [uiTypeValue, setUiTypeValue] = useState(initialUiType);
  const [filterFormulaValue, setFilterFormula] = useState(initialFilterFormula);

  const [displayFilterFormulaFormItem, showFilterFormulaFormItem] = useState(initialFilterFormula !== '');
  const [displayRenameTypeFormItem, showRenameTypeFormItem] = useState(initialUiType !== null);

  const dealObjectSample = usePlanDealObjectSample(plan, connectorIdTypeValue);
  const formulaHelperData = useAdditionalProperties(uiTypeValue || connectorIdTypeValue?.type, dealObjectSample);
  const closeDateFormulaHelperData = useAdditionalProperties(
    uiTypeValue || connectorIdTypeValue?.type,
    dealObjectSample,
    {
      plan,
      addPlanProperties: false,
      addPeriodProperties: true,
      addUsersProperties: false,
      addTeamProperties: false,
      addDependenciesProperties: false,
      addPlanTrackingObjectVariablesProperties: false,
    },
  );

  const connector = useSelector((state) => ConnectorsSelectors.getConnectorById(state, { connectorId: connectorIdTypeValue?.connectorId || plan.trackingObject?.connectorId }));

  const [form] = AntDForm.useForm();
  const initialValues = useMemo(() => ({
    connectorIdType: initialConnectorIdType,
    uiType: initialUiType,
    matchingField: initialMatchingField,
    filterObject: initialFilterObject,
    filterFormula: initialFilterFormula,
    dateField: initialDateField,
  }), [
    initialConnectorIdType,
    initialUiType,
    initialMatchingField,
    initialFilterObject,
    initialFilterFormula,
    initialDateField,
  ]);

  const [formValues, setFormValues] = useState(initialValues);

  useEffect(() => {
    setConnectorIdTypeValue(initialValues.connectorIdType);
    setUiTypeValue(initialValues.uiType);
    setFilterFormula(initialValues.filterFormula);
    showRenameTypeFormItem(initialValues.uiType !== null);
    setFormValues(initialValues);
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const getFormUpdatedData = useCallback((values) => {
    if (values.connectorIdType === null || initialValues.connectorIdType === null) return [{}, {}, {}];

    const { connectorId, type } = values.connectorIdType;
    const finalValues = { ...values, connectorId, type, uiType: uiTypeValue };
    delete finalValues.connectorIdType;

    const { connectorId: initialConnectorId, type: initialType } = initialValues.connectorIdType;
    const finalInitialValues = { ...initialValues, connectorId: initialConnectorId, type: initialType };
    delete finalInitialValues.connectorIdType;

    const updatedData = Object.keys(finalValues).reduce((data, valKey) => {
      if (!_isEqual(finalInitialValues[valKey], finalValues[valKey])) {
        return {
          ...data,
          [valKey]: finalValues[valKey],
        };
      }

      return data;
    }, {});

    return [updatedData, finalValues, finalInitialValues];
  }, [uiTypeValue, initialValues]);

  const formIsPristine = useMemo(() => {
    const [updatedData] = getFormUpdatedData(formValues);

    return Object.keys(updatedData).length === 0;
  }, [formValues, getFormUpdatedData]);

  const handleConnectorIdTypeValueChange = (newConnectorIdType) => {
    setConnectorIdTypeValue(newConnectorIdType);

    if (initialConnectorIdType === null) {
      dispatch(MasterPlansActions.updatePlanTrackingObjectDefinition({
        planId: plan.id,
        connectorId: newConnectorIdType.connectorId,
        type: newConnectorIdType.type,
        disableSuccessNotification: true,
      }));
    }
  };

  const handleFormValuesChange = useCallback((changedValues, allValues) => {
    if (changedValues.connectorIdType !== undefined) {
      handleConnectorIdTypeValueChange(changedValues.connectorIdType);
    }

    if (changedValues.uiType !== undefined) {
      setUiTypeValue(changedValues.uiType);
    }

    if (changedValues.filterFormula !== undefined) {
      setFilterFormula(changedValues.filterFormula);
    }

    setFormValues(allValues);
  });

  const handleFinish = useCallback((values) => {
    const [updatedData] = getFormUpdatedData(values);

    if (Object.keys(updatedData).length > 0) {
      dispatch(MasterPlansActions.updatePlanTrackingObjectDefinition({ planId: plan.id, ...updatedData }));
    }
  }, [plan, getFormUpdatedData]);

  const handleUpdatePlanTrackingObjectDefinition = () => form.submit();

  const matchingFieldTopFields = useMemo(() => {
    if (!connector || !connectorIdTypeValue) return [];

    return MATCHING_FIELDS[`${connector.type}_${connectorIdTypeValue.type}`] || [];
  }, [connector, connectorIdTypeValue]);

  const dateFieldTopFields = useMemo(() => {
    if (!connector || !connectorIdTypeValue) return [];

    return DATE_FIELDS[`${connector.type}_${connectorIdTypeValue.type}`] || [];
  }, [connector, connectorIdTypeValue]);

  const resetUIType = useCallback(() => {
    const fieldsValues = form.getFieldsValue(true);

    form.setFieldsValue({
      ...fieldsValues,
      uiType: null,
    });

    setUiTypeValue(null);
    showRenameTypeFormItem(false);
  }, [
    form,
    setUiTypeValue,
    showRenameTypeFormItem,
  ]);

  const connectorIdTypeRenameNode = useMemo(() => {
    if (!connector || !connectorIdTypeValue) return null;

    if (displayRenameTypeFormItem) {
      return (
        <div className={styles.connectorIdTypeWrapper}>
          <FormItem
            className={styles.connectorIdTypeInput}
            name="uiType"
            label={t('masterPlanTrackingObjectDefinitionForm.form.uiType.label', { dealType: pluralize.singular(connectorIdTypeValue.type || '') })}
          >
            <Input size="big" placeholder={t('masterPlanTrackingObjectDefinitionForm.form.uiType.placeholder')} disabled={updatePlanTrackingObjectDefinitionIsPending} />
          </FormItem>
          <Button className={styles.renameTypeBtn} type="link" onClick={resetUIType} disabled={updatePlanTrackingObjectDefinitionIsPending}>
            {t('masterPlanTrackingObjectDefinitionForm.form.uiType.resetName')}
          </Button>
        </div>
      );
    }

    return (
      <Button className={styles.renameTypeBtn} type="link" onClick={() => showRenameTypeFormItem(true)} disabled={updatePlanTrackingObjectDefinitionIsPending}>
        {t('masterPlanTrackingObjectDefinitionForm.form.connectorIdType.rename')}
      </Button>
    );
  }, [
    connector,
    connectorIdTypeValue,
    updatePlanTrackingObjectDefinitionIsPending,
    displayRenameTypeFormItem,
    resetUIType,
  ]);

  const filterObjectFormItemNode = useMemo(() => (
    <FormItem
      className={classNames({
        filterObjectFormItem: true,
        filterObjectFormItemSticked: !displayFilterFormulaFormItem,
      })}
      name="filterObject"
      label={t('masterPlanTrackingObjectDefinitionForm.form.filterObject.label')}
    >
      <QueryBuilder
        helperData={formulaHelperData}
        emptyLabel={t('masterPlanTrackingObjectDefinitionForm.form.filterObject.empty')}
        prefix="data."
        disabled={updatePlanTrackingObjectDefinitionIsPending}
      />
    </FormItem>
  ), [displayFilterFormulaFormItem, formulaHelperData, updatePlanTrackingObjectDefinitionIsPending]);

  const filterFormulaFormItemNode = useMemo(() => {
    if (!displayFilterFormulaFormItem) {
      return (
        <Button className={styles.useCustomCodeBtn} type="link" onClick={() => showFilterFormulaFormItem(true)} disabled={updatePlanTrackingObjectDefinitionIsPending}>
          {t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.useCustomCode')}
        </Button>
      );
    }

    const extraNode = (
      <div className={styles.filterFormulaExtra}>
        {t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.description')}
        {
          filterFormulaValue === '' && (
            <Button className={styles.filterFormulaExtraBtn} type="link" onClick={() => showFilterFormulaFormItem(false)} disabled={updatePlanTrackingObjectDefinitionIsPending}>
              {t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.hide')}
            </Button>
          )
        }
      </div>
    );

    return (
      <FormItem
        name="filterFormula"
        label={t('masterPlanTrackingObjectDefinitionForm.form.filterFormula.label')}
        extra={extraNode}
      >
        <Input size="big" disabled={updatePlanTrackingObjectDefinitionIsPending} />
      </FormItem>
    );
  }, [filterFormulaValue, displayFilterFormulaFormItem]);

  const remainingFormNode = useMemo(() => {
    if (connectorIdTypeValue === null) return null;

    return (
      <>
        <FormulaHelper
          className={styles.formulaHelper}
          helperData={formulaHelperData}
        />
        <FormItem
          name="matchingField"
          label={t('masterPlanTrackingObjectDefinitionForm.form.matchingField.label')}
          extra={`ex: ${uiTypeValue || connectorIdTypeValue.type || '[Resource]'}.ownerId`}
          required
          rules={[
            { required: true, message: t('masterPlanTrackingObjectDefinitionForm.form.matchingField.rules.required') },
          ]}
        >
          <FormulaInput
            type={FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION}
            size="big"
            helperData={formulaHelperData}
            topFields={matchingFieldTopFields}
            disabled={updatePlanTrackingObjectDefinitionIsPending}
            suggestionsType={SUGGESTIONS_TYPES.DEAL}
          />
        </FormItem>
        {filterObjectFormItemNode}
        {filterFormulaFormItemNode}
        <FormItem
          name="dateField"
          label={t('masterPlanTrackingObjectDefinitionForm.form.dateField.label')}
          extra={`ex: ${uiTypeValue || connectorIdTypeValue.type || '[Resource]'}.closedate`}
          required
          rules={[
            { required: true, message: t('masterPlanTrackingObjectDefinitionForm.form.dateField.rules.required') },
          ]}
        >
          <FormulaInput
            type={FORMULA_INPUT_TYPES.ONE_FIELD_SELECTION}
            size="big"
            helperData={closeDateFormulaHelperData}
            topFields={dateFieldTopFields}
            disabled={updatePlanTrackingObjectDefinitionIsPending}
            suggestionsType={SUGGESTIONS_TYPES.DATE}
          />
        </FormItem>
      </>
    );
  }, [
    connectorIdTypeValue,
    uiTypeValue,
    formulaHelperData,
    closeDateFormulaHelperData,
    matchingFieldTopFields,
    filterObjectFormItemNode,
    filterFormulaFormItemNode,
    dateFieldTopFields,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.title}>
        {t('masterPlanTrackingObjectDefinitionForm.title')}
      </div>
      <div className={styles.description}>
        {t('masterPlanTrackingObjectDefinitionForm.description')}
      </div>
      <Form onFinish={handleFinish} initialValues={initialValues} form={form} onValuesChange={handleFormValuesChange}>
        <div
          className={classNames({
            connectorIdTypeWrapper: true,
            withRenameInput: displayRenameTypeFormItem,
          })}
        >
          <FormItem
            className={styles.connectorIdTypeInput}
            name="connectorIdType"
            label={t('masterPlanTrackingObjectDefinitionForm.form.connectorIdType.label')}
            required
            rules={[
              { required: true, message: t('masterPlanTrackingObjectDefinitionForm.form.connectorIdType.rules.required') },
            ]}
          >
            <ResourceConnectorsSelect size="big" disabled={updatePlanTrackingObjectDefinitionIsPending} />
          </FormItem>
          {connectorIdTypeRenameNode}
        </div>
        {remainingFormNode}
        <div className={styles.actions}>
          <Button className={styles.submitBtn} onClick={handleUpdatePlanTrackingObjectDefinition} disabled={formIsPristine || updatePlanTrackingObjectDefinitionIsPending}>
            {t('masterPlanTrackingObjectDefinitionForm.form.submitBtnLabel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

MasterPlanTrackingObjectDefinitionForm.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes.isRequired,
};

MasterPlanTrackingObjectDefinitionForm.defaultProps = {
  className: '',
};

export default MasterPlanTrackingObjectDefinitionForm;
