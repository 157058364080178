/* Tabs in commission drawer */
export const COMMISSION_DRAWER_QS_KEY = 'drawerTab';
export const COMMISSION_DRAWER_TABS_IDS = {
  VARIABLES: 'variables',
  PAYMENTS: 'payments',
  CALCULATIONS: 'calculations',
  ACTIVITIES: 'activities',
};

/* Tabs in plan period details page */
export const PERIOD_TABS_QS_KEY = 'periodTab';
export const PERIOD_TABS_IDS = {
  USERS: 'users',
  MANAGERS: 'managers',
  DEALS: 'deals',
};

/* Tabs in plan settings page */
export const PLAN_SETTINGS_TABS_QS_KEY = 'settingsTab';
export const PLAN_SETTINGS_TABS_IDS = {
  GENERAL: 'general',
  USERS: 'users',
  QUOTA: 'quota',
  COMMISSION_RULES: 'commissionRules',
  PAYOUT_RULES: 'payoutRules',
  DATA_SOURCE: 'dataSource',
  PROJECTION: 'projection',
  DEPENDENCIES: 'dependencies',
};

/* Tabs in plans page content (active / past / draft / archived) */
export const PLANS_PAGE_CONTENT_QS_KEY = 'plansTab';
export const PLANS_PAGE_CONTENT_TABS_IDS = {
  ACTIVE: 'active',
  PAST: 'past',
  DRAFT: 'draft',
  ARCHIVED: 'archived',
};

/* Tabs in plans V3 page content (active) */
export const PLANS_V3_PAGE_CONTENT_QS_KEY = 'plansV3Tab';
export const PLANS_V3_PAGE_CONTENT_TABS_IDS = {
  ACTIVE: 'active',
};

/* Tabs in IC plans page content (active / past / archived) */
export const IC_PLANS_PAGE_CONTENT_QS_KEY = 'plansTab';
export const IC_PLANS_PAGE_CONTENT_TABS_IDS = {
  ACTIVE: 'active',
  PAST: 'past',
  ARCHIVED: 'archived',
};

/* Tabs in user details page */
export const USER_DETAILS_TABS_QS_KEY = 'userDetailsTab';
export const USER_DETAILS_TABS_IDS = {
  STATEMENTS: 'statements',
  PLANS: 'plans',
  TEAMS: 'teams',
  QUOTAS: 'quotas',
  DASHBOARD_PREVIEW: 'dashboardPreview',
  SETTINGS: 'settings',
};

/* Tabs in user details' plan tab (active / past / archived) */
export const USER_PLANS_PAGE_CONTENT_QS_KEY = 'userPlansTab';
export const USER_PLANS_PAGE_CONTENT_TABS_IDS = {
  ACTIVE: 'active',
  PAST: 'past',
  ARCHIVED: 'archived',
};

/* Tabs in resources index page (connectors) */
export const RESOURCES_PAGE_CONTENT_QS_KEY = 'resourcesTab';
export const RESOURCES_PAGE_CONTENT_TABS_IDS = {
  CONNECTORS: 'connectors',
  NOTEBOOKS: 'notebooks',
};

/* Tabs in resource details page (raw-data / commissions) */
export const RESOURCE_DETAILS_PAGE_CONTENT_QS_KEY = 'resourceTab';
export const RESOURCE_DETAILS_PAGE_CONTENT_TABS_IDS = {
  RAWDATA: 'rawData',
  COMMISSIONS: 'commissions',
};

/* Tabs in create plan flow data source (connectors) */
export const DRAFT_DATA_SOURCE_QS_KEY = 'dataSourceTab';
export const DRAFT_DATA_SOURCE_TABS_IDS = {
  CONNECTORS: 'connectors',
  NOTEBOOKS: 'notebooks',
};

/* Tabs in notebooks page */
export const NOTEBOOKS_TABS_QS_KEY = 'notebooksTab';
export const NOTEBOOKS_TABS_IDS = {
  ALL: 'all',
  YOURS: 'yours',
  TEMPLATES: 'templates',
  ARCHIVED: 'archived',
};

/* Tabs in add data connection modal - select data source step */
export const ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_QS_KEY = 'addDataConnectionSelectDataSourceTab';
export const ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_IDS = {
  CONNECTORS: 'connectors',
};

/* Tabs in add data connection modal - select data source step */
export const PLAN_V3_ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_QS_KEY = 'planV3AddDataConnectionSelectDataSourceTab';
export const PLAN_V3_ADD_DATA_CONNECTION_SELECT_DATA_SOURCE_TABS_IDS = {
  CONNECTORS: 'connectors',
  MODELS: 'models',
};

/* Tabs in notebook details page, in left side */
export const NOTEBOOK_DETAILS_LEFT_SIDE_TABS_QS_KEY = 'notebookDetailsLeftSideTab';
export const NOTEBOOK_DETAILS_LEFT_SIDE_TABS_IDS = {
  DATA_CONNECTIONS: 'dataConnections',
  RUNNABLE_CELLS: 'runnableCells',
  EXPORTED_CELLS: 'exportedCells',
};

/* Tabs in user statement content (statement / history) */
export const USER_STATEMENT_CONTENT_TABS_QS_KEY = 'userStatementTab';
export const USER_STATEMENT_CONTENT_TABS_IDS = {
  STATEMENT: 'statement',
  HISTORY: 'history',
};

/* Tabs in quota configuration list (active / archived) */
export const QUOTA_LIST_TABS_QS_KEY = 'quotaFilterTab';
export const QUOTA_LIST_TABS_IDS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

/* Tabs in commission drawer */
export const COMMISSION_V3_DRAWER_QS_KEY = 'drawerV3Tab';
export const COMMISSION_V3_DRAWER_TABS_IDS = {
  PAYMENTS: 'payments',
};
