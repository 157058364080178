import PropTypes from 'prop-types';

import {
  manageAmountAttribute,
  manageNumberAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'YearPeriodValue';

export const propTypes = PropTypes.shape({
  year: PropTypes.number,
  period: PropTypes.number,
  value: PropTypes.number,
});

export const transform = (yearPeriodValue) => {
  if (!yearPeriodValue) {
    return null;
  }

  return ({
    year: manageNumberAttribute(yearPeriodValue, 'year'),
    period: manageNumberAttribute(yearPeriodValue, 'period'),
    value: manageAmountAttribute(yearPeriodValue, 'value'),
  });
};

export const transformList = (yearPeriodValues) => yearPeriodValues.map((yearPeriodValue) => transform(yearPeriodValue));
