import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import LeftMainContentLayout from '@palette/components/designSystem/LeftMainContentLayout/LeftMainContentLayout';
import CreateDashboard from '@palette/components/quotaPerformance/CreateDashboard/CreateDashboard';
import QuotaPerformanceList from '@palette/components/quotaPerformance/QuotaPerformanceList/QuotaPerformanceList';
import QuotaPerformanceDetails from '@palette/components/quotaPerformance/QuotaPerformanceDetails/QuotaPerformanceDetails';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';

import { RIGHTS } from '@palette/constants/profile';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { useProfile } from '@palette/hooks/ProfileHooks';

import styles from './QuotasPerformancePage.less';

const QuotasPerformancePage = () => {
  const { t } = useTranslation();
  const profile = useProfile();

  const createDashboardNode = useMemo(() => {
    if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE])) return null;

    return (
      <CreateDashboard className={styles.createDashboardButton} />
    );
  }, [profile]);

  const headerNode = useMemo(() => (
    <div className={styles.header}>
      <h2 className={styles.title}>
        {t('quotasPerformancePage.title')}
      </h2>
      {createDashboardNode}
    </div>
  ), [createDashboardNode]);

  if (!hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.VIEW])) return null;

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.quotasPerformanceTitle')} />
      <LeftMainContentLayout
        leftSideHeader={headerNode}
        leftSideContent={<QuotaPerformanceList />}
        leftSideContentClassName={styles.leftSideContent}
        mainContent={<QuotaPerformanceDetails className={styles.main} />}
        mainContentClassName={styles.mainContent}
        leftSideWidth={33}
        borderless
      />
    </div>
  );
};

export default QuotasPerformancePage;
