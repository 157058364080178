import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import PenFilled from '@palette/components/utils/Icons/PenFilled';

import EditQuotaDefaultValuesModal from '@palette/components/quota/EditQuotaDefaultValuesModal/EditQuotaDefaultValuesModal';

const EditQuotaDefaultValues = ({ className }) => {
  const { t } = useTranslation();
  const [editQuotaDefaultValuesModalIsVisible, showEditQuotaDefaultValuesModal] = useState(false);

  return (
    <>
      <Button
        className={className}
        type="link"
        icon={<PenFilled />}
        onClick={() => showEditQuotaDefaultValuesModal(true)}
      >
        {t('quota.changeDefaultValues')}
      </Button>
      {
        editQuotaDefaultValuesModalIsVisible && (
          <EditQuotaDefaultValuesModal visible onClose={() => showEditQuotaDefaultValuesModal(false)} />
        )
      }
    </>
  );
};

EditQuotaDefaultValues.propTypes = {
  className: PropTypes.string,
};

EditQuotaDefaultValues.defaultProps = {
  className: '',
};

export default EditQuotaDefaultValues;
