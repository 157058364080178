import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '@palette/components/designSystem/Button/Button';
import CloseSimpleLine from '@palette/components/utils/Icons/CloseSimpleLine';
import Input from '@palette/components/designSystem/Input/Input';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';

import { useProfile } from '@palette/hooks/ProfileHooks';

import { getMoment } from '@palette/helpers/MomentHelper';
import { getCurrencySymbol } from '@palette/helpers/CurrencyHelper';

import styles from './CommissionV3EditPayoutPaymentRow.less';

const classNames = bindClassNames.bind(styles);

const CommissionV3EditPayoutPaymentRow = ({
  className,
  payment,
  commissionAmount,
  index,
  usePercentage,
  currency,
  onDelete,
  setDate,
  setPercentage,
  setAmount,
  disabled,
}) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const moment = getMoment();
  const now = moment();

  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const handleDateChange = useCallback((date) => {
    setDate(index, moment(date).format());
  }, [index, setDate, profile]);

  const handlePercentageChange = useCallback((percentage) => {
    setPercentage(index, percentage / 100);
  }, [index, setPercentage]);

  const handleAmountChange = useCallback((amount) => {
    setAmount(index, amount);
  }, [index, setAmount]);

  const inputNode = useMemo(() => {
    if (usePercentage) {
      return (
        <Input
          className={styles.input}
          clearIconWrapperClassName={styles.inputWrapper}
          type="number"
          value={payment.percentage * 100}
          controls={false}
          min={0}
          max={100}
          addonAfter="%"
          onChange={handlePercentageChange}
          disabled={disabled}
        />
      );
    }

    return (
      <Input
        className={styles.input}
        clearIconWrapperClassName={styles.inputWrapper}
        type="comaSeparatorFormatted"
        value={commissionAmount * payment.percentage}
        controls={false}
        addonAfter={getCurrencySymbol(currency)}
        onChange={handleAmountChange}
        disabled={disabled}
      />
    );
  }, [
    usePercentage,
    payment,
    handlePercentageChange,
    disabled,
    currency,
    handleAmountChange,
  ]);

  const dateNode = useMemo(() => (
    <DatePicker
      className={styles.datePicker}
      picker="date"
      value={moment.utc(payment.date)}
      allowClear={false}
      showNow={false}
      disabledDate={(d) => !d || d.isSameOrBefore('1970-01-01')}
      onChange={handleDateChange}
      disabled={disabled}
    />
  ), [
    profile,
    payment,
    now,
    handleDateChange,
    disabled,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        isDisabled: disabled,
        [className]: className !== '',
      })}
    >
      <div className={styles.paymentLabel}>
        {t('editPayoutPaymentRow.payment', { index: index + 1 })}
      </div>
      <div className={styles.editionWrapper}>
        <div className={styles.inputs}>
          {inputNode}
          {dateNode}
        </div>
        {
          !disabled && (
            <Button
              className={styles.delete}
              type="primary"
              shape="circle"
              onClick={handleDelete}
              icon={<CloseSimpleLine width={14} height={14} />}
            />
          )
        }
      </div>
    </div>
  );
};

CommissionV3EditPayoutPaymentRow.propTypes = {
  className: PropTypes.string,
  payment: PropTypes.shape({
    date: PropTypes.string,
    percentage: PropTypes.number,
  }).isRequired,
  commissionAmount: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  usePercentage: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  setPercentage: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CommissionV3EditPayoutPaymentRow.defaultProps = {
  className: '',
};

export default CommissionV3EditPayoutPaymentRow;
