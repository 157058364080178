import Axios from 'axios';
import { API_URL } from '../constants';

export const listReconciliations = () => Axios.post(`${API_URL}/users/admin/connectors/reconciliations/list`, null, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const getReconciliationById = (reconciliationId) => Axios.post(`${API_URL}/users/admin/connectors/reconciliations/get-by-id`, { reconciliationId }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const updateReconciliation = (reconciliationId, body) => Axios.post(`${API_URL}/users/admin/connectors/reconciliations/update`, { reconciliationId, ...body }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const createReconciliation = (body) => Axios.post(`${API_URL}/users/admin/connectors/reconciliations/create`, body, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const execReconciliation = (reconciliationId) => Axios.post(`${API_URL}/users/admin/connectors/reconciliations/exec`, { reconciliationId }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export const deleteReconciliation = (reconciliationId) => Axios.post(`${API_URL}/users/admin/connectors/reconciliations/delete`, { reconciliationId }, {
  headers: {
    sessionId: localStorage.getItem('sessionId'),
  },
});

export default {};
