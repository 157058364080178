import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import _cloneDeep from 'lodash/cloneDeep';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Modal from '@palette/components/designSystem/Modal/Modal';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import Alert from '@palette/components/designSystem/Alert/Alert';
import Button from '@palette/components/designSystem/Button/Button';
import Popconfirm from '@palette/components/designSystem/Popconfirm/Popconfirm';
import Link from '@palette/components/designSystem/Link/Link';
import AddFilled from '@palette/components/utils/Icons/AddFilled';
import InfoCircleLine from '@palette/components/utils/Icons/InfoCircleLine';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';
import DraggableDrawerVariableEdition from '@palette/components/commission/DraggableDrawerVariableEdition/DraggableDrawerVariableEdition';

import { checkColumnsVariablesFields } from '@palette/helpers/MasterPlanHelper';

import { usePlanDealObjectSample } from '@palette/hooks/MasterPlanHooks';
import { useAdditionalProperties } from '@palette/hooks/FormulaHooks';

import { ALERT_TYPES } from '@palette/constants/alert';
import { FORMATTERS_VALUES } from '@palette/constants/masterPlans';
import { PLAN_SETTINGS_TABS_IDS, PLAN_SETTINGS_TABS_QS_KEY } from '@palette/constants/tabs';

import routePaths from '@palette/config/routePaths';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './CommissionDrawerEditVariablesListModal.less';

const classNames = bindClassNames.bind(styles);

const newVariable = {
  name: '',
  fields: [],
  formatter: FORMATTERS_VALUES.String,
  evalFn: '',
};

const CommissionDrawerEditVariablesListModal = ({ visible, onClose, plan, onUpdate }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planVariables = plan.variables;

  /* ## Internal state management ## */
  const [variables, setVariables] = useState([]);
  const [errors, setErrors] = useState(false);

  const updatePlanIsPending = useSelector(MasterPlansSelectors.updatePlanIsPending);

  useEffect(() => {
    if (visible) {
      const allVariables = _cloneDeep(planVariables);

      setVariables(allVariables);
    }
  }, [visible]);

  const dealObjectSample = usePlanDealObjectSample(plan);
  const formulaHelperData = useAdditionalProperties(plan.trackingObject?.type, dealObjectSample);

  /* ## Variables updates management ## */
  const variableChange = (field, newValue, index = 0) => {
    const allVariables = _cloneDeep(variables);
    allVariables[index][field] = newValue;
    setVariables(allVariables);
  };

  const handleVariableNameChange = useCallback((newName, index = 0) => {
    variableChange('name', newName, index);
  });

  const handleVariableFieldsChange = useCallback((newFields, index = 0) => {
    variableChange('fields', newFields, index);
  });

  const handleVariableFormatterChange = useCallback((newFormatter, index = 0) => {
    variableChange('formatter', newFormatter, index);
  });

  const handleVariableEvalFnChange = useCallback((newEvalFn, index = 0) => {
    variableChange('evalFn', newEvalFn, index);
  });

  const handleRemoveVariable = useCallback((index = 0) => {
    const allVariables = _cloneDeep(variables);
    allVariables.splice(index, 1);

    setVariables(allVariables);
  });

  const handleAddVariable = useCallback(() => {
    const allVariables = _cloneDeep(variables);
    allVariables.push(newVariable);
    setVariables(allVariables);
  });

  /* ## Drag & Drop management ## */
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const allVariables = _cloneDeep(variables);
    const [removed] = allVariables.splice(result.source.index, 1);
    allVariables.splice(result.destination.index, 0, removed);

    setVariables(allVariables);
  };

  const getItemStyle = (draggableStyle) => ({
    userSelect: 'none',

    ...draggableStyle,
  });

  /* ## Actions definition ## */
  const cleanAndClose = () => {
    onClose();
  };

  const onUpdateSuccess = () => {
    if (onUpdate !== null) {
      onUpdate();
    }
    cleanAndClose();
  };

  const handleSaveVariables = () => {
    setErrors(false);

    if (checkColumnsVariablesFields(variables)) {
      dispatch(MasterPlansActions.updatePlan({ planId: plan.id, variables, onSuccessCB: onUpdateSuccess }));
    } else {
      setErrors(true);
    }
  };

  const handleResetToOriginal = () => {
    dispatch(MasterPlansActions.updatePlan({ planId: plan.id, variables: null, onSuccessCB: onUpdateSuccess }));
  };

  /* ## Nodes definition ## */
  const resetNode = (
    <Popconfirm
      title={t('commissionDrawerEditVariablesListModal.resetVariables.popconfirm.title')}
      onConfirm={handleResetToOriginal}
      okText={t('commissionDrawerEditVariablesListModal.resetVariables.popconfirm.confirm')}
      cancelText={t('commissionDrawerEditVariablesListModal.resetVariables.popconfirm.cancel')}
      size="small"
      disabled={updatePlanIsPending}
    >
      <Button
        className={styles.resetToOriginalButton}
        type="link"
        disabled={updatePlanIsPending}
      >
        {t('commissionDrawerEditVariablesListModal.resetVariables.popconfirm.cta')}
      </Button>
    </Popconfirm>
  );

  const variablesBlockNode = (
    <Droppable droppableId="variablesDroppable">
      {
        (providedDroppable) => (
          <div
            {...providedDroppable.droppableProps}
            ref={providedDroppable.innerRef}
          >
            <div className={styles.variables}>
              {
                variables.map((variable, index) => (
                  <Draggable key={index} draggableId={`variable_${index}`} index={index}>
                    {(providedDraggable) => (
                      <div
                        className={styles.variable}
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        style={getItemStyle(
                          providedDraggable.draggableProps.style,
                        )}
                      >
                        <DraggableDrawerVariableEdition
                          variable={variable}
                          helperData={formulaHelperData}
                          onNameChange={(newName) => handleVariableNameChange(newName, index)}
                          onFieldsChange={(newFields) => handleVariableFieldsChange(newFields, index)}
                          onFormatterChange={(newFormatter) => handleVariableFormatterChange(newFormatter, index)}
                          onEvalFnChange={(newEvalFn) => handleVariableEvalFnChange(newEvalFn, index)}
                          onRemoveVariable={() => handleRemoveVariable(index)}
                          disabled={updatePlanIsPending}
                        />
                      </div>
                    )}
                  </Draggable>
                ))
              }
              {providedDroppable.placeholder}
            </div>
          </div>
        )
      }
    </Droppable>
  );

  return (
    <Modal
      className={styles.modal}
      title={(
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            {t('commissionDrawerEditVariablesListModal.title')}
          </div>
          <div className={styles.subTitle}>
            {t('commissionDrawerEditVariablesListModal.subTitle', { type: pluralize(plan.trackingObject?.type), planName: plan.name })}
          </div>
        </div>
      )}
      visible={visible}
      onCancel={cleanAndClose}
      onOk={handleSaveVariables}
      okText={t('commissionDrawerEditVariablesListModal.save')}
      okIcon={<CheckFilled />}
      loading={updatePlanIsPending}
      extraFooterNode={resetNode}
    >
      <div
        className={classNames({
          contentWrapper: true,
        })}
      >
        <div className={styles.labels}>
          <div className={styles.label}>
            {t('commissionDrawerEditVariablesListModal.variable.name')}
          </div>
          <div className={styles.label}>
            {t('commissionDrawerEditVariablesListModal.variable.fields', { type: plan.trackingObject?.type })}
            <Tooltip
              title={t('commissionDrawerEditVariablesListModal.variable.fields.tooltip')}
            >
              <InfoCircleLine className={styles.infoIcon} />
            </Tooltip>
          </div>
          <div className={styles.label}>
            {t('commissionDrawerEditVariablesListModal.variable.formatter')}
          </div>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          {variablesBlockNode}
        </DragDropContext>
        <Button
          className={styles.addButton}
          type="link"
          icon={<AddFilled />}
          onClick={handleAddVariable}
          disabled={updatePlanIsPending}
        >
          {t('commissionDrawerEditVariablesListModal.addVariable')}
        </Button>
        <Alert
          className={styles.tipAlert}
          type={ALERT_TYPES.INFO}
          message={t('commissionDrawerEditVariablesListModal.tip.title')}
          description={(
            <div className={styles.tipMessage}>
              <span>
                {t('commissionDrawerEditVariablesListModal.tip.part1', { type: pluralize(plan.trackingObject?.type) })}
              </span>
              <Link
                className={styles.tipLink}
                path={routePaths.v2.planDetails}
                params={{
                  masterPlanId: plan.id,
                }}
                qsObject={{
                  [PLAN_SETTINGS_TABS_QS_KEY]: PLAN_SETTINGS_TABS_IDS.QUOTA,
                }}
              >
                {t('commissionDrawerEditVariablesListModal.tip.link')}
              </Link>
              <span>
                {t('commissionDrawerEditVariablesListModal.tip.part2', { type: pluralize(plan.trackingObject?.type) })}
              </span>
            </div>
          )}
        />
        {errors && (
          <Alert
            className={styles.tipAlert}
            type={ALERT_TYPES.ERROR}
            message={t('commissionDrawerEditVariablesListModal.error.title')}
            description={t('commissionDrawerEditVariablesListModal.error.text')}
          />
        )}
      </div>
    </Modal>
  );
};

CommissionDrawerEditVariablesListModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  plan: MasterPlanModel.propTypes.isRequired,
  onUpdate: PropTypes.func,
};

CommissionDrawerEditVariablesListModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onUpdate: null,
};

export default CommissionDrawerEditVariablesListModal;
