import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import FormulaInput from '@palette/components/designSystem/FormulaInput/FormulaInput';
import TrashFilled from '@palette/components/utils/Icons/TrashFilled';
import Button from '@palette/components/designSystem/Button/Button';
import Tooltip from '@palette/components/designSystem/Tooltip/Tooltip';

import { FORMULA_INPUT_TYPES } from '@palette/constants/formula';

import styles from './VariableConditionForm.less';

const classNames = bindClassNames.bind(styles);

const VariableConditionForm = ({
  className,
  prefix,
  condition,
  formula,
  variableName,
  helperData,
  isFirst,
  isLast,
  onRemove,
  onChange,
  prefixType,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleChange = (field, value) => {
    onChange({ field, value });
  };

  const deleteNode = (
    <Tooltip title={t('variablesForm.delete.condition.tooltip')}>
      <Button
        className={styles.actionButton}
        type="link"
        icon={<TrashFilled className={styles.actionIcon} />}
        onClick={onRemove}
        disabled={disabled}
      />
    </Tooltip>
  );

  const variableNameNode = useMemo(() => {
    if (variableName === '') return null;

    let finalVariableName = variableName;
    if (prefixType !== null) {
      finalVariableName = `${prefixType}.${variableName}`;
    }

    return (
      <strong className={styles.prefixStrong}>{finalVariableName}</strong>
    );
  }, [variableName, prefixType]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <div className={styles.fields}>
        {
          (condition || isFirst || !isLast) && (
            <div className={styles.rowField}>
              <div className={styles.prefix}>
                {prefix}
              </div>
              <FormulaInput
                className={styles.formulaInput}
                value={condition}
                placeholder={t('variablesForm.condition.placeholder')}
                type={FORMULA_INPUT_TYPES.FORMULA}
                helperData={helperData}
                onChange={(value) => handleChange('condition', value)}
                disabled={disabled}
              />
            </div>
          )
        }
        <div className={styles.rowField}>
          <div className={styles.prefix}>
            {(condition || isFirst || !isLast) && 'THEN'}
            {isLast && prefix}
            <span className={styles.variableNameContainer}>
              {variableNameNode}
              =
            </span>
          </div>
          <FormulaInput
            className={styles.formulaInput}
            value={formula}
            placeholder={t('variablesForm.value.placeholder')}
            type={FORMULA_INPUT_TYPES.FORMULA}
            helperData={helperData}
            onChange={(value) => handleChange(isLast ? 'formula' : 'conditionFormula', value)}
            disabled={disabled}
          />
        </div>
      </div>
      {!isLast && !isFirst && (
        <div className={styles.actions}>
          {deleteNode}
        </div>
      )}
    </div>
  );
};

VariableConditionForm.propTypes = {
  className: PropTypes.string,
  prefix: PropTypes.string.isRequired,
  condition: PropTypes.string,
  formula: PropTypes.string,
  variableName: PropTypes.string,
  helperData: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  prefixType: PropTypes.string,
  disabled: PropTypes.bool,
};

VariableConditionForm.defaultProps = {
  className: '',
  condition: null,
  formula: '',
  variableName: '',
  helperData: {},
  isFirst: false,
  isLast: false,
  onRemove: () => {},
  onChange: () => {},
  prefixType: null,
  disabled: false,
};

export default VariableConditionForm;
