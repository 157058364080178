import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Popover from '@palette/components/designSystem/Popover/Popover';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import PeriodYearPicker from '@palette/components/frequencies/PeriodYearPicker/PeriodYearPicker';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import Switch from '@palette/components/designSystem/Switch/Switch';

import { getMoment } from '@palette/helpers/MomentHelper';
import { getFrequencyPeriodName } from '@palette/helpers/FrequencyHelper';
import { getPlanFirstPeriod, getPlanLastPeriod } from '@palette/helpers/MasterPlanHelper';

import { PERIOD_TYPES } from '@palette/constants/frequencies';

import * as MasterPlanModel from '@palette/models/MasterPlan';
import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

import styles from './WidgetPeriodYearSelector.less';

const classNames = bindClassNames.bind(styles);

const WidgetPeriodYearSelector = ({
  className,
  onPeriodUpdate,
  onUseMostRecentUpdate,
  plan,
  period,
  useMostRecent,
  disabled,
}) => {
  const { t } = useTranslation();

  const planFirstPeriod = useMemo(() => getPlanFirstPeriod(plan), [plan]);
  const planLastPeriod = useMemo(() => getPlanLastPeriod(plan), [plan]);

  const periodType = useMemo(() => (plan.periodType), [plan]);
  const frequency = useMemo(() => (plan.frequency), [plan]);
  const fiscalYearShift = useMemo(() => (plan.fiscalYearShift), [plan]);
  const beginDate = useMemo(() => (plan.customBeginAt), [plan]);
  const initialPeriodId = useMemo(() => (period.period), [period]);
  const initialYear = useMemo(() => (period.year), [period]);

  const moment = getMoment();

  const [year, setYear] = useState(initialYear);
  const [periodId, setPeriodId] = useState(initialPeriodId);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const saveUpdate = useCallback((newPeriodId = periodId, newYear = year) => {
    if (initialPeriodId !== newPeriodId || initialYear !== newYear) {
      onPeriodUpdate({ period: newPeriodId, year: newYear });
    }
  }, [initialPeriodId, periodId, initialYear, year, onPeriodUpdate]);

  useEffect(() => {
    setYear(initialYear);
  }, [initialYear]);

  useEffect(() => {
    setPeriodId(initialPeriodId);
  }, [initialPeriodId]);

  useEffect(() => {
    if (frequency === 12) {
      saveUpdate();
    }
  }, [year]);

  const handleYearPeriodChange = useCallback((newYearPeriod) => {
    setPeriodId(newYearPeriod.period);
    setYear(newYearPeriod.year);
    setPopoverVisible(false);

    saveUpdate(newYearPeriod.period, newYearPeriod.year);
  }, [setPeriodId, setYear, setPopoverVisible, saveUpdate]);

  const handleYearChange = (newYear) => {
    setYear(newYear);
  };

  const handleDatePickerChange = (date, dateString) => {
    handleYearChange(parseInt(dateString, 10));
  };

  const handleUseMostRecentChange = (newUseMostRecent) => {
    onUseMostRecentUpdate(newUseMostRecent);
    if (newUseMostRecent) {
      setPopoverVisible(false);
    }
  };

  const handlePopoverVisibleChange = useCallback((isVisible) => {
    setPopoverVisible(isVisible);

    if (!isVisible) {
      saveUpdate();
    }
  }, [setPopoverVisible, saveUpdate]);

  const contentDisplayNode = useMemo(() => (
    getFrequencyPeriodName(periodType, frequency, initialYear, initialPeriodId, beginDate, false, fiscalYearShift)
  ), [
    periodType,
    frequency,
    fiscalYearShift,
    initialYear,
    initialPeriodId,
    beginDate,
  ]);

  const editIconNode = useMemo(() => (
    <PenFilled
      className={classNames({
        editIcon: true,
        popoverVisible,
      })}
      width={14}
      height={14}
    />
  ), [popoverVisible]);

  const useMostRecentNode = useMemo(() => (
    <div tabIndex={-1} className={styles.useMostRecentSwitchWrapper}>
      <Switch value={useMostRecent} onChange={handleUseMostRecentChange} />
      <div className={styles.useMostRecentLabel}>
        {t('widgetPeriodYearSelector.useMostRecent')}
      </div>
    </div>
  ), [useMostRecent, handleUseMostRecentChange]);

  const datePickerNode = useMemo(() => {
    if (disabled) {
      return (
        <div className={styles.dateYearPickerWrapper}>
          <div className={styles.content}>
            {contentDisplayNode}
          </div>
        </div>
      );
    }

    const handleDisabledDate = (d) => (
      !d
      || useMostRecent
      || d.isSameOrBefore('1970-01-01')
      || (
        planFirstPeriod !== null
        && d.isBefore(moment().year(planFirstPeriod.year).startOf('year'))
      )
      || (
        planLastPeriod !== null
        && d.isAfter(moment().year(planLastPeriod.year).endOf('year'))
      )
    );

    if (periodType === PERIOD_TYPES.CUSTOM) return null;

    return (
      <div className={styles.dateYearPickerWrapper}>
        <div className={styles.content}>
          {contentDisplayNode}
        </div>
        <div className={styles.yearPickerWrapper}>
          <DatePicker
            className={styles.yearPicker}
            dropdownClassName={styles.yearPickerDropdown}
            picker="year"
            value={moment().year(year)}
            allowClear={false}
            disabledDate={handleDisabledDate}
            onChange={handleDatePickerChange}
            onOpenChange={handlePopoverVisibleChange}
            open={popoverVisible}
            renderExtraFooter={() => (
              <div className={styles.popoverContent}>
                {useMostRecentNode}
              </div>
            )}
          />
          {editIconNode}
        </div>
      </div>
    );
  }, [
    planFirstPeriod,
    planLastPeriod,
    year,
    handleDatePickerChange,
    useMostRecent,
    contentDisplayNode,
    handlePopoverVisibleChange,
    popoverVisible,
    useMostRecentNode,
    disabled,
  ]);

  const popoverContentNode = useMemo(() => (
    <div className={styles.popoverContent}>
      <PeriodYearPicker
        periodType={periodType}
        frequency={frequency}
        fiscalYearShift={fiscalYearShift}
        period={initialPeriodId}
        year={initialYear}
        beginDate={beginDate}
        onYearPeriodChange={handleYearPeriodChange}
        disableBeforePeriod={planFirstPeriod}
        disableAfterPeriod={planLastPeriod}
        disabled={useMostRecent}
      />
      {useMostRecentNode}
    </div>
  ), [
    periodType,
    frequency,
    fiscalYearShift,
    initialPeriodId,
    initialYear,
    beginDate,
    handleYearPeriodChange,
    planFirstPeriod,
    planLastPeriod,
    useMostRecent,
    useMostRecentNode,
  ]);

  const popoverNode = useMemo(() => {
    if (disabled) {
      return (
        <div className={styles.popoverWrapper}>
          <div className={styles.content}>
            {contentDisplayNode}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.popoverWrapper}>
        <div className={styles.content}>
          {contentDisplayNode}
        </div>
        <Popover
          content={popoverContentNode}
          onVisibleChange={handlePopoverVisibleChange}
          visible={popoverVisible}
        >
          {editIconNode}
        </Popover>
      </div>
    );
  }, [
    popoverContentNode,
    handlePopoverVisibleChange,
    popoverVisible,
    contentDisplayNode,
    editIconNode,
    disabled,
  ]);

  const bodyNode = useMemo(() => {
    if (periodType === PERIOD_TYPES.MONTH && frequency === 12) {
      return datePickerNode;
    }

    return popoverNode;
  },
  [
    periodType,
    frequency,
    datePickerNode,
    popoverNode,
  ]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {bodyNode}
    </div>
  );
};

WidgetPeriodYearSelector.propTypes = {
  className: PropTypes.string,
  onPeriodUpdate: PropTypes.func.isRequired,
  onUseMostRecentUpdate: PropTypes.func.isRequired,
  plan: MasterPlanModel.propTypes.isRequired,
  period: MasterPlanPeriodModel.propTypes.isRequired,
  useMostRecent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

WidgetPeriodYearSelector.defaultProps = {
  className: '',
  disabled: false,
};

export default WidgetPeriodYearSelector;
