import { put } from 'redux-saga/effects';

import apiConfig from '@palette/config/api';

import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import { getSagaMiddleware } from '@palette/helpers/SagasHelper';
import { isSamePagePathname, redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import { actions as ExceptionsActions } from '@palette/state/Exceptions/slice';

export const parseProblemData = (data) => {
  let errorId = 'about:blank';
  let message = '';
  let additionalData = {};

  if (data) {
    const {
      type,
      title,
      ...otherAttributes
    } = data;

    if (type && type !== '') {
      errorId = type.substring(apiConfig.PROBLEM_BASE_URI.length);
    }

    if (title && title !== '') {
      message = title;
    }

    additionalData = otherAttributes;
  }

  return {
    errorId,
    message,
    additionalData,
  };
};

function* forceLogout() {
  yield put(ExceptionsActions.forceLogout());
}

const forceRedirectToMaintenancePage = () => {
  if (!isSamePagePathname({ path: routePaths.v2.maintenance })) {
    redirectTo({ path: routePaths.v2.maintenance });
  }
};

export const manageGlobalError = ({ status, problem }) => {
  let { code } = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_UNKNOWN;
  let context = null;

  switch (status) {
    case 400: {
      code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_BAD_REQUEST.code;
      if (problem?.errorId === 'constraint.violation') {
        code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_CONSTRAINT_VIOLATION.code;
        context = {
          violations: (problem?.additionalData?.violations || []).map(({ param, msg }) => ({
            field: param,
            errorMessage: msg,
          })),
        };
      }
      break;
    }
    case 401: {
      code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_UNAUTHORIZED.code;
      if (problem?.errorId === 'invalid.session') {
        code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_INVALID_SESSION.code;
        getSagaMiddleware().run(forceLogout);
      }
      break;
    }
    case 403: {
      code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_UNAUTHORIZED.code;
      break;
    }
    case 404: {
      code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_NOT_FOUND.code;
      break;
    }
    case 405: {
      code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_METHOD_NOT_ALLOWED.code;
      break;
    }
    case 500: {
      code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_SERVER_ERROR.code;
      break;
    }
    case 503: {
      code = GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_MAINTENANCE_ENABLED.code;
      forceRedirectToMaintenancePage();
      break;
    }
    default:
  }

  return {
    ...problem,
    message: { code, context },
  };
};
