import PropTypes from 'prop-types';

import {
  manageFreeShapeObjectAttribute,
  manageStringAttribute,
} from '@palette/helpers/ModelHelper';

export const modelName = 'OnboardUser';

export const propTypes = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  currency: PropTypes.string,
  cObject: PropTypes.shape({
    objectId: PropTypes.string,
    connector: PropTypes.string,
    type: PropTypes.string,
  }),
});
const manageCObject = (cObject) => {
  if (cObject === null) return null;

  const objectId = manageStringAttribute(cObject, 'objectId', null);
  const connector = manageStringAttribute(cObject, 'connector', null);
  const type = manageStringAttribute(cObject, 'type', null);

  if (
    objectId === null
    || connector === null
    || type === null
  ) return null;

  return {
    objectId,
    connector,
    type,
  };
};

export const transform = (onboardUser) => {
  if (!onboardUser) {
    return null;
  }

  return (
    {
      firstName: manageStringAttribute(onboardUser, 'firstName'),
      lastName: manageStringAttribute(onboardUser, 'lastName'),
      email: manageStringAttribute(onboardUser, 'email'),
      currency: manageStringAttribute(onboardUser, 'currency'),
      cObject: manageCObject(
        manageFreeShapeObjectAttribute(onboardUser, 'cObject', null),
      ),
    }
  );
};

export const transformList = (onboardUsers) => onboardUsers.map((onboardUser) => transform(onboardUser));
