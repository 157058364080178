import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bindClassNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import Progress, { PROGRESS_TYPES } from '@palette/components/designSystem/Progress/Progress';
import CheckFilled from '@palette/components/utils/Icons/CheckFilled';
import PenFilled from '@palette/components/utils/Icons/PenFilled';
import DiscardPlanButton from '@palette/components/masterPlan/DiscardPlanButton/DiscardPlanButton';

import { useDraftMasterPlanStepInSearch } from '@palette/hooks/MasterPlanHooks';

import { getCreatePlanFlowStepIndex } from '@palette/helpers/MasterPlanHelper';

import {
  CREATE_PLAN_FLOW_FIRST_STEP,
  CREATE_PLAN_FLOW_ORDERED_STEPS,
  DRAFT_PLAN_STEP_QS_KEY,
} from '@palette/constants/masterPlans';

import * as MasterPlanModel from '@palette/models/MasterPlan';

import { actions as NavigationActions } from '@palette/state/Navigation';

import styles from './CreatePlanFlowPageStepsList.less';

const classNames = bindClassNames.bind(styles);

const CreatePlanFlowPageStepsList = ({ className, plan }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [creationPlanStep] = useDraftMasterPlanStepInSearch();

  const creationPlanStepIndex = useMemo(() => getCreatePlanFlowStepIndex(creationPlanStep), [creationPlanStep]);

  const planCreationFlowStep = useMemo(() => (plan?.creationFlowStep || CREATE_PLAN_FLOW_FIRST_STEP.value), [plan]);
  const planCreationFlowStepIndex = useMemo(() => getCreatePlanFlowStepIndex(planCreationFlowStep), [planCreationFlowStep]);

  const planCreationFlowProgress = useMemo(() => (
    Math.floor(((planCreationFlowStepIndex + 1) * 100) / CREATE_PLAN_FLOW_ORDERED_STEPS.length)
  ), [planCreationFlowStepIndex, planCreationFlowStep]);

  const stepsNodes = useMemo(() => (
    CREATE_PLAN_FLOW_ORDERED_STEPS.map((step, index) => {
      const isDisabled = index > planCreationFlowStepIndex;
      const isDiscard = step.isDiscard(plan);

      const handleStepClicked = () => {
        if (!isDisabled && !isDiscard) {
          dispatch(NavigationActions.addQSToLocation({ qsObject: { [DRAFT_PLAN_STEP_QS_KEY]: step.value } }));
        }
      };

      let stepIconNode = null;

      if (!isDisabled && !isDiscard) {
        if (index === planCreationFlowStepIndex) {
          stepIconNode = (
            <PenFilled className={styles.stepIcon} width={16} height={16} />
          );
        } else {
          stepIconNode = (
            <CheckFilled className={styles.stepIcon} width={16} height={16} />
          );
        }
      }

      return (
        <div
          key={step.value}
          className={classNames({
            step: true,
            selected: creationPlanStep === step.value,
            disabled: isDisabled,
            discard: isDiscard && !isDisabled,
          })}
          onClick={handleStepClicked}
        >
          <div className={styles.stepIconWrapper}>
            {stepIconNode}
          </div>
          <div className={styles.stepName}>
            {t(step.label)}
          </div>
        </div>
      );
    })
  ), [plan, creationPlanStep, planCreationFlowStepIndex]);

  const discardPlanBtnNode = useMemo(() => {
    if (creationPlanStepIndex === 0 || plan === null) return null;

    return (
      <DiscardPlanButton className={styles.discardBtn} plan={plan} labelEnabled />
    );
  }, [creationPlanStepIndex]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      <Progress className={styles.progressBar} type={PROGRESS_TYPES.SUCCESS} percent={planCreationFlowProgress} />
      <div className={styles.stepsContainer}>
        {stepsNodes}
      </div>
      {discardPlanBtnNode}
    </div>
  );
};

CreatePlanFlowPageStepsList.propTypes = {
  className: PropTypes.string,
  plan: MasterPlanModel.propTypes,
};

CreatePlanFlowPageStepsList.defaultProps = {
  className: '',
  plan: null,
};

export default CreatePlanFlowPageStepsList;
