import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Avatar from '@palette/components/user/Avatar/Avatar';
import UserProfile from '@palette/components/user/UserProfile/UserProfile';
import Select from '@palette/components/designSystem/Select/Select';
import ChevronDownLine from '@palette/components/utils/Icons/ChevronDownLine';

import { usePeriodsDatesFilterAndCurrencyQSObject } from '@palette/hooks/StatementHooks';

import { actions as StatementV3Actions, selectors as StatementV3Selectors } from '@palette/state/StatementV3';
import { selectors as UsersSelectors } from '@palette/state/Users';

import { redirectTo } from '@palette/helpers/NavigationHelper';

import routePaths from '@palette/config/routePaths';

import styles from './StatementV3UsersNavigation.less';

const classNames = bindClassNames.bind(styles);

const StatementV3UsersNavigation = ({ className }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { statementPeriodId } = useParams();

  const [editable, setEditable] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [userStatementUsers, setUserStatementUsers] = useState([]);

  const periodsDatesFilterQSObject = usePeriodsDatesFilterAndCurrencyQSObject();

  const periodDetails = useSelector((state) => StatementV3Selectors.getPeriodDetails(state));
  const usersList = useSelector((state) => UsersSelectors.getUsersList(state));

  useEffect(() => {
    const [, periodId] = statementPeriodId.split('-');
    dispatch(StatementV3Actions.getPeriodDetails({ periodId }));
  }, [statementPeriodId]);

  useEffect(() => {
    const [userId] = statementPeriodId.split('-');
    if (userId) {
      setCurrentUserId(userId);
    }
  }, [statementPeriodId]);

  useEffect(() => {
    if (periodDetails) {
      const userIds = periodDetails.statements.reduce((acc, statement) => {
        if (!acc.some((item) => item.userId === statement.userId)) {
          acc.push({ userId: statement.userId, periodId: statement.id });
        }
        return acc;
      }, []);
      setUserStatementUsers(userIds);
    }
  }, [periodDetails]);

  const handleUsersSelectChange = useCallback((newUserId) => {
    const currentUserStatement = userStatementUsers.find((userStatementUser) => userStatementUser.userId === newUserId);
    if (currentUserStatement) {
      setCurrentUserId(currentUserStatement.userId);
      redirectTo({
        path: routePaths.v2.statementV3UserStatement,
        params: { statementPeriodId: currentUserStatement.periodId },
        qsObject: periodsDatesFilterQSObject,
      });
    }
  }, [userStatementUsers, periodsDatesFilterQSObject]);

  const handleDropdownVisibleChange = (val) => setEditable(val);

  const nameNode = useMemo(() => {
    let contentNode = null;

    if (currentUserId !== null) {
      const user = usersList.find((u) => u.id === currentUserId);

      if (user) {
        contentNode = (
          <>
            <Avatar
              className={styles.avatar}
              user={user}
              size={24}
              style={{
                fontSize: '1.2rem',
                width: '2.4rem',
                height: '2.4rem',
              }}
            />
            <div className={styles.name}>
              {user.displayName}
            </div>
            <ChevronDownLine className={styles.icon} width={24} height={24} />
          </>
        );
      }
    }

    return (
      <div
        className={classNames({
          nameContainer: true,
          editable,
        })}
      >
        {contentNode}
      </div>
    );
  }, [currentUserId, editable, usersList]);

  const usersSelectNode = useMemo(() => {
    if (currentUserId === null) return null;

    const userStatementOptions = userStatementUsers.map((userStatementUserId) => {
      const user = usersList.find((u) => u.id === userStatementUserId.userId);

      return {
        label: (
          <UserProfile
            className={styles.userOption}
            user={user}
            avatarSize={18}
            avatarStyle={{
              fontSize: '1rem',
              width: '1.8rem',
              minWidth: '1.8rem',
              height: '1.8rem',
            }}
          />
        ),
        rawlabel: user.displayName,
        value: user.id,
      };
    });

    return (
      <Select
        className={classNames({
          usersSelect: true,
          editable,
        })}
        dropdownClassName={styles.usersSelectDropdown}
        placeholder={t('statementUsersNavigation.usersSelect.placeholder')}
        options={userStatementOptions}
        onChange={handleUsersSelectChange}
        value={currentUserId}
        showSearch
        enableFilterOptions
        filterOptionProp="rawlabel"
        onDropdownVisibleChange={handleDropdownVisibleChange}
      />
    );
  }, [userStatementUsers, currentUserId, handleUsersSelectChange, editable, usersList]);

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {nameNode}
      {usersSelectNode}
    </div>
  );
};

StatementV3UsersNavigation.propTypes = {
  className: PropTypes.string,
};

StatementV3UsersNavigation.defaultProps = {
  className: '',
};

export default StatementV3UsersNavigation;
