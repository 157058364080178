/* eslint-disable no-await-in-loop */
import React from 'react';
import {
  Button, Divider, Empty, message, Popover, Tag,
} from 'antd';
import {
  ApiOutlined, PlusOutlined, LoadingOutlined, SyncOutlined, FileSearchOutlined,
} from '@ant-design/icons';
import Moment from 'moment';

import { connect } from 'react-redux';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import routePaths from '@palette/config/routePaths';

import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { selectors as ProfileSelectors } from '@palette/state/Profile';

import * as ProfileModel from '@palette/models/Profile';

import PageTopTitle from '../../../components/pageTopTitle';
import { listConnectors, syncConnector } from '../../../services/connector';
import { waitJob, getConnectorName } from '../../../services/utils';
import FullScreenLoader from '../../../components/loader';

import '../../../styles/admin/connectors.less';
import { sendClickOnDocLink } from '../../../services/analytics';
import { ANALYTICS_DOC_SOURCES } from '../../../services/constants';

const stc = require('string-to-color');

class Connectors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      connectors: [],
      refreshLoading: null,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = () => {
    if (this.state.loading) return;
    this.setState({ loading: true }, () => {
      listConnectors()
        .then(({ data: connectors }) => this.setState({
          connectors,
          loading: false,
        }))
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
          message.error('Error while loading connectors');
        });
    });
  };

  syncConnector = async (id) => {
    this.setState({ refreshLoading: id });
    try {
      const { data: job } = await syncConnector(id);
      await waitJob(job._id);
      message.success('Data synchronized.');
      this.fetch();
    } catch (error) {
      message.error('Error while syncing data');
    }
    this.setState({ refreshLoading: false });
  };

  hasMultipleConnectorsOfThisKind = (connector) => this.state.connectors
    .find((c) => c._id !== connector._id && c.type === connector.type);

  render() {
    const { loading, connectors, refreshLoading } = this.state;

    return (
      <div className="div-wrapper MyPlans">
        <MetaTag title="Connectors" />
        <div className="container">
          <div className="container-inner">
            <PageTopTitle
              title={(
                <>
                  <ApiOutlined />
                  &nbsp;
                  &nbsp;
                  Connectors
                </>
              )}
              actions={hasAtLeastOneRight(this.props.profile, ['admin.connectors.create']) && (
                <a href={`/#${routePaths.addConnector}`}>
                  <Button
                    icon={<PlusOutlined />}
                    type="link"
                  >
                    Add Connector
                  </Button>
                </a>
              )}
            />
            <Divider />
            <div
              className="flex"
              style={{
                flexGrow: '1',
                justifyContent: 'flex-end',
                marginRight: '32px',
                marginBottom: '12px',
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://doc.palettehq.com/add-connectors"
                style={{ fontSize: '14px' }}
                onClick={() => { sendClickOnDocLink(ANALYTICS_DOC_SOURCES.CONNECTORS_PAGE, this.props.profile); }}
              >
                <FileSearchOutlined />
                {' '}
                Documentation for Connectors
              </a>
            </div>
            <div className="Connectors_list">
              {(connectors && (connectors.length > 0) ? (
                connectors.map((c) => (
                  <div key={c._id} className={`Connector_vignette_wrapper ${c.type}_back`}>
                    {c.type !== 'CSV' && (
                      <div className="Connector_vignette_refresh">
                        <Popover
                          placement="bottom"
                          content={(
                            <div className="center">
                              <div>
                                Click to refresh the data from
                                {' '}
                                {getConnectorName(c.type)}
                              </div>
                            </div>

                          )}
                        >
                          <div
                            onClick={() => this.syncConnector(c._id)}
                          >
                            <Tag
                              color="transparent"
                              style={{
                                border: '1px solid #ffffff54',
                                marginRight: '0',
                              }}
                            >
                              {refreshLoading === c._id ? <LoadingOutlined /> : <SyncOutlined />}
                              &nbsp;
                              Sync
                            </Tag>

                          </div>
                        </Popover>
                      </div>
                    )}

                    <div className="Connector_vignette_inner">
                      <br />
                      <a href={`#/connectors/${c._id}`}>
                        <div>
                          {this.hasMultipleConnectorsOfThisKind(c) && <div className="Connector_pastille" style={{ backgroundColor: stc(c._id) }} />}
                          <h2 className="Connector_vignette_type title bold center no-margin">
                            {getConnectorName(c.type)}
                          </h2>
                          {c.name ? (
                            <div
                              style={{ marginBottom: '4px' }}
                              className="center"
                            >
                              <Tag color="blue">{c.name}</Tag>
                            </div>
                          ) : null}
                          {c.lastSync ? (
                            <div className="center italic" style={{ fontSize: '12px', color: 'white' }}>
                              Last sync
                              {' '}
                              {Moment(c.lastSync).format('MMMM Do YYYY - HH:mm')}
                            </div>
                          ) : <br />}
                          <br />
                          <div className="center">
                            <Button type="link" style={{ color: 'white' }}>View details</Button>
                          </div>
                        </div>

                      </a>

                    </div>
                  </div>
                ))
              ) : (
                !loading && (
                  <div className="width-100">
                    <Empty description={(
                      <div>
                        <div className="grey">
                          No connectors
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          {hasAtLeastOneRight(this.props.profile, ['admin.connectors.create']) && (
                            <a href={`/#${routePaths.addConnector}`}>
                              <Button
                                icon={<PlusOutlined />}
                                type="primary"
                              >
                                Add Connector
                              </Button>
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                    />
                  </div>
                )
              ))}
            </div>

            {loading && <FullScreenLoader />}
          </div>
        </div>
      </div>
    );
  }
}

Connectors.propTypes = {
  profile: ProfileModel.propTypes.isRequired,
};

const mapStateToProps = (state) => ({
  profile: ProfileSelectors.profile(state),
});

export default connect(mapStateToProps, null)(Connectors);
