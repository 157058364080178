import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _size from 'lodash/size';

import PlanPayoutScheduleChart from '@palette/components/charts/PlanPayoutScheduleChart/PlanPayoutScheduleChart';
import MasterPlanSettingsPayoutRulesStepItem from '@palette/components/masterPlanSettings/MasterPlanSettingsPayoutRulesStepItem/MasterPlanSettingsPayoutRulesStepItem';

import { payoutRulesConditionToHideRuleStep } from '@palette/helpers/MasterPlanHelper';
import { getMoment } from '@palette/helpers/MomentHelper';

import * as PlanModel from '@palette/models/MasterPlan';
import * as YearPeriodModel from '@palette/models/YearPeriod';

import { actions as MasterPlansActions, selectors as MasterPlansSelectors } from '@palette/state/MasterPlans';

import styles from './MasterPlanSettingsPayoutRulesSteps.less';

const classNames = bindClassNames.bind(styles);

const MasterPlanSettingsPayoutRulesSteps = ({ className, plan, period, forIC, forSummary }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moment = getMoment();

  const masterPlanPayoutSchedule = useSelector((state) => MasterPlansSelectors.getMasterPlanPayoutSchedule(state, { masterPlanId: plan?.id || null }));

  useEffect(() => {
    if (!_size(plan.payoutRules) > 0) return;

    if (!forIC && !forSummary) {
      dispatch(MasterPlansActions.getPayoutSchedule({ planId: plan.id, period: period || undefined }));
    }
  }, [plan, period, forIC, forSummary]);

  const payoutScheduleNode = useMemo(() => {
    if (
      !forIC
      && !forSummary
      && plan !== null
      && plan.lastComputedAt !== null
      && plan.lastBreakingChangeUpdate !== null
      && moment(plan.lastComputedAt).utc().isAfter(moment(plan.lastBreakingChangeUpdate).utc())
      && masterPlanPayoutSchedule?.amountPerPeriod.length > 0
    ) {
      return (
        <div className={styles.payoutScheduleWrapper}>
          <span className={styles.previewTitle}>
            {t('masterPlanSettingsPayoutRules.payoutSchedule.title')}
          </span>
          <div className={styles.payoutScheduleChart}>
            <PlanPayoutScheduleChart data={masterPlanPayoutSchedule} />
          </div>
        </div>
      );
    }

    return null;
  }, [plan, masterPlanPayoutSchedule, forIC, forSummary]);

  if (!_size(plan.payoutRules) > 0) {
    return (
      <p>{t('masterPlanSettingsPayoutRules.noRulesText')}</p>
    );
  }

  const rulesToHideCounter = plan.payoutRules.filter((rule) => payoutRulesConditionToHideRuleStep(rule)).length;

  return (
    <div
      className={classNames({
        wrapper: true,
        [className]: className !== '',
      })}
    >
      {payoutScheduleNode}
      {plan.payoutRules.map((rule, i) => {
        if (payoutRulesConditionToHideRuleStep(rule)) return null;

        return (
          <div className={styles.ruleWrapper} key={rule.id}>
            {/* Title */}
            {plan.payoutRules.length - rulesToHideCounter > 1 && (
              <span className={styles.ruleName}>
                {`${t('masterPlanSettingsPayoutRules.ruleTitle')} ${i + 1}`}
              </span>
            )}

            {/* Steps */}
            <MasterPlanSettingsPayoutRulesStepItem
              className={styles.ruleContainer}
              rule={rule}
              plan={plan}
              period={period}
              isLast={i === plan.payoutRules.length - 1}
            />
          </div>
        );
      })}
    </div>
  );
};

MasterPlanSettingsPayoutRulesSteps.propTypes = {
  className: PropTypes.string,
  plan: PlanModel.propTypes,
  period: YearPeriodModel.propTypes,
  forIC: PropTypes.bool,
  forSummary: PropTypes.bool,
};

MasterPlanSettingsPayoutRulesSteps.defaultProps = {
  className: '',
  plan: {},
  period: null,
  forIC: false,
  forSummary: false,
};

export default MasterPlanSettingsPayoutRulesSteps;
