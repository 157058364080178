import PropTypes from 'prop-types';

import { manageArrayAttribute, manageFreeShapeObjectAttribute } from '@palette/helpers/ModelHelper';

import * as MasterPlanPeriodModel from '@palette/models/MasterPlanPeriod';

export const modelName = 'PlanWhatIfProjectionData';

export const propTypes = PropTypes.shape({
  periodData: MasterPlanPeriodModel.propTypes,
});

export const transform = (planWhatIfProjectionData) => {
  if (!planWhatIfProjectionData) {
    return null;
  }

  const periodsArray = manageArrayAttribute(planWhatIfProjectionData, 'periods');
  let period = {};
  if (periodsArray.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    period = periodsArray[0];
  }

  return (
    {
      periodData: MasterPlanPeriodModel.transform({
        ...planWhatIfProjectionData,
        ...manageFreeShapeObjectAttribute({ period }, 'period', {}),
      }),
    }
  );
};
