import { checkEndpointRights } from '@palette/helpers/ApiHelper';

import { RIGHTS } from '@palette/constants/profile';

import apiConfig from '@palette/config/api';

import { ApiService, getConfig } from '@palette/services/ApiService';

export function* list() {
  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotasPerformance.listDashboard, {}, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.VIEW],
  );
}

export function* getById(payload) {
  const { quotaPerformanceId } = payload;

  const data = {
    dashboardId: quotaPerformanceId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotasPerformance.getDashboardById, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.VIEW],
  );
}

export function* createDashboard(payload) {
  const {
    name,
    description,
    planIds = [],
  } = payload;

  const data = {
    name,
    description,
    planIds,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotasPerformance.createDashboard, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* updateDashboard(payload) {
  const {
    quotaPerformanceId,
    name,
    description,
  } = payload;

  const data = {
    dashboardId: quotaPerformanceId,
    name,
    description,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotasPerformance.updateDashboard, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* deleteDashboard(payload) {
  const { quotaPerformanceId } = payload;

  const data = {
    dashboardId: quotaPerformanceId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotasPerformance.deleteDashboard, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* addPlansToDashboard(payload) {
  const {
    quotaPerformanceId,
    planIds,
  } = payload;

  const data = {
    dashboardId: quotaPerformanceId,
    planIds,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotasPerformance.addPlansToDashboard, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}

export function* removePlanFromDashboard(payload) {
  const {
    quotaPerformanceId,
    planId,
  } = payload;

  const data = {
    dashboardId: quotaPerformanceId,
    planId,
  };

  return yield checkEndpointRights(
    () => ApiService.post(apiConfig.ENDPOINTS.quotasPerformance.removePlanFromDashboard, data, getConfig()),
    [RIGHTS.ADMIN.QUOTAS.MANAGE],
  );
}
