import {
  call,
  put,
  all,
  spawn,
  take,
  takeLatest,
} from 'redux-saga/effects';
import _every from 'lodash/every';
import _find from 'lodash/find';

import { actions as PlanV3Actions } from '@palette/state/PlanV3/slice';
import { manageError as managePlanV3Error } from '@palette/state/PlanV3/errors';
import { actions as GlobalNotifActions } from '@palette/state/GlobalNotif/slice';
import { sendEvent as analyticsSendEvent } from '@palette/state/Analytics/sagas';
import { waitJob } from '@palette/state/AsyncJobs/sagas';

import { PLANS_V3_EVENTS } from '@palette/constants/analytics';
import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

import * as PlanV3Service from '@palette/services/PlanV3Service';

import * as PlanV3ListModel from '@palette/models/PlanV3List';
import * as PlanV3ListUserModel from '@palette/models/PlanV3ListUser';
import * as PlanV3ConfigurationModel from '@palette/models/PlanV3Configuration';
import * as PlanV3DetailModel from '@palette/models/PlanV3Detail';
import * as PlanV3AchievementModel from '@palette/models/PlanV3Achievement';
import * as PlanV3InputTableModel from '@palette/models/PlanV3InputTable';
import * as AsyncJobModel from '@palette/models/AsyncJob';

export function* getList({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(PlanV3Service.planV3getList);

  if (callResult.ok) {
    const list = PlanV3ListModel.transformList(callResult.data);

    yield put(PlanV3Actions.setList({ list }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getListCompleted());
}

export function* getICList({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(PlanV3Service.planV3getICList);

  if (callResult.ok) {
    const list = PlanV3ListModel.transformList(callResult.data);

    yield put(PlanV3Actions.setICList({ list }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getICListCompleted());
}

export function* deletePlan({ payload = {} }) {
  const { planId, onSuccessCB = null } = payload;

  const callResult = yield call(PlanV3Service.planV3delete, { planId });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(planId);

    yield put(PlanV3Actions.getList());
    yield take(PlanV3Actions.getListCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.deletePlanCompleted());
}

export function* getListUsers({ payload = {} }) {
  const { onSuccessCB = null } = payload;

  const callResult = yield call(PlanV3Service.planV3getListUsers);

  if (callResult.ok) {
    const list = PlanV3ListUserModel.transformList(callResult.data);

    yield put(PlanV3Actions.setListUsers({ list }));

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getListUsersCompleted());
}

export function* createPlan({ payload = {} }) {
  const { requirements, dataSources, users, onSuccessCB = null } = payload;

  const params = { requirements, dataSources, users };

  const callResult = yield call(PlanV3Service.planV3create, params);

  if (callResult.ok) {
    const { planId } = callResult.data;

    yield call(analyticsSendEvent, { payload: { event: PLANS_V3_EVENTS.CREATE_NEW_PLAN, params: { planId } } });

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_V3_CREATED_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB(planId);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.createPlanCompleted());
}

export function* getConfiguration({ payload = {} }) {
  const { planId } = payload;

  const callResult = yield call(PlanV3Service.planV3GetConfiguration, { planId });

  if (callResult.ok) {
    const configuration = PlanV3ConfigurationModel.transform({ planId, ...callResult.data });

    yield put(PlanV3Actions.setConfiguration({ configuration }));
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getConfigurationCompleted());
}

export function* getICConfiguration({ payload = {} }) {
  const { planId } = payload;

  const callResult = yield call(PlanV3Service.planV3GetICConfiguration, { planId });

  if (callResult.ok) {
    const configuration = PlanV3ConfigurationModel.transform({ planId, ...callResult.data });

    yield put(PlanV3Actions.setICConfiguration({ configuration }));
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getICConfigurationCompleted());
}

export function* updateConfiguration({ payload = {} }) {
  const { planId, requirements, dataSources, users, onSuccessCB = null } = payload;

  const params = { planId, requirements, dataSources, users };

  const callResult = yield call(PlanV3Service.planV3UpdateConfiguration, params);

  if (callResult.ok) {
    yield call(analyticsSendEvent, { payload: { event: PLANS_V3_EVENTS.UPDATE_PLAN_REQUIREMENTS, params: { planId } } });

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_V3_REQUIREMENTS_UPDATED_SUCCESS));

    if (onSuccessCB !== null) onSuccessCB(planId);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.updateConfigurationCompleted());
}

export function* getDetails({ payload = {} }) {
  const { planId } = payload;

  const callResult = yield call(PlanV3Service.planV3GetDetails, { planId });

  if (callResult.ok) {
    const details = PlanV3DetailModel.transform(callResult.data);

    yield put(PlanV3Actions.setDetails({ details }));
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getDetailsCompleted());
}

export function* getAchievementsList({ payload = {} }) {
  const { planId } = payload;

  const callResult = yield call(PlanV3Service.planV3GetAchievementsList, { planId });

  if (callResult.ok) {
    const achievementsList = PlanV3AchievementModel.transformList(callResult.data);

    yield put(PlanV3Actions.setAchievementsList({ achievementsList }));
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getAchievementsListCompleted());
}

export function* runCodeCellSuccess({ planId }) {
  yield call(getDetails, { payload: { planId } });
  yield call(getAchievementsList, { payload: { planId } });
  yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_V3_RUN_CODE_CELL_SUCCESS));
}

export function* runCodeCellCompleted({ planId, codeCellId }) {
  yield put(PlanV3Actions.runCodeCellCompleted({ planId, codeCellId }));
}

export function* runCodeCell({ payload = {} }) {
  const { planId, codeCellId, cellCode } = payload;

  const callResult = yield call(PlanV3Service.planV3RunCodeCell, { planId, codeCellId, cellCode });

  if (callResult.ok) {
    const job = AsyncJobModel.transform(callResult.data);
    yield spawn(waitJob, {
      job,
      successSaga: () => runCodeCellSuccess({ planId }),
      errorSaga: () => getDetails({ payload: { planId } }),
      completedSaga: () => runCodeCellCompleted({ planId, codeCellId }),
    });
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
    yield put(PlanV3Actions.runCodeCellCompleted({ planId, codeCellId }));
  }
}

export function* copyVariableNameToClipboard({ payload = {} }) {
  const { name } = payload;

  try {
    yield call(() => (navigator.clipboard.writeText(name)));
    yield put(GlobalNotifActions.addGlobalNotif({
      message: {
        code: GLOBAL_NOTIF_REASONS.PLAN_V3_COPY_TO_CLIPBOARD_SUCCESS.code,
        context: {
          name,
        },
      },
    }));
  } catch (e) {
    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_V3_COPY_TO_CLIPBOARD_ERROR));
  }
}

export function* savePlan({ payload = {} }) {
  const { planId, codeCells, refreshPlanDetails = false, onSuccessCB = null } = payload;

  const params = { planId, codeCells };

  const callResult = yield call(PlanV3Service.planV3SavePlan, params);

  if (callResult.ok) {
    yield call(analyticsSendEvent, { payload: { event: PLANS_V3_EVENTS.SAVE_PLAN, params: { planId } } });

    yield put(GlobalNotifActions.addGlobalNotif(GLOBAL_NOTIF_REASONS.PLAN_V3_PLAN_SAVED_SUCCESS));

    if (refreshPlanDetails) {
      yield put(PlanV3Actions.getDetails({ planId }));
      yield take(PlanV3Actions.getDetailsCompleted.type);
    }

    if (onSuccessCB !== null) onSuccessCB(planId);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.savePlanCompleted());
}

export function* getInputTablesList({ payload = {} }) {
  const { planId } = payload;

  const callResult = yield call(PlanV3Service.planV3GetInputTablesList, { planId });

  if (callResult.ok) {
    const inputTablesList = PlanV3InputTableModel.transformList(callResult.data);

    yield put(PlanV3Actions.setInputTablesList({ inputTablesList }));
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.getInputTablesListCompleted());
}

export function* createInputTable({ payload = {} }) {
  const {
    planId,
    type,
    name,
    columns,
    onSuccessCB = null,
    refreshInputTable = true,
  } = payload;

  const callResult = yield call(PlanV3Service.planV3CreateInputTable, { planId, type, name, columns });

  if (callResult.ok) {
    if (onSuccessCB !== null) onSuccessCB(planId, callResult.data.inputTableId);

    if (refreshInputTable) {
      yield put(PlanV3Actions.getInputTablesList({ planId }));
      yield take(PlanV3Actions.getInputTablesListCompleted.type);
    }
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.createInputTableCompleted());
}

export function* addRowToInputTable({ payload = {} }) {
  const { planId, inputTableId, row } = payload;

  const callResult = yield call(PlanV3Service.planV3AddRowToInputTable, { planId, inputTableId, row });

  if (callResult.ok) {
    yield put(PlanV3Actions.getInputTablesList({ planId }));
    yield take(PlanV3Actions.getInputTablesListCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.addRowToInputTableCompleted());
}

export function* addMultipleRowsToInputTable({ payload = {} }) {
  const { planId, inputTableId, rows, refreshInputTable = true } = payload;

  const callResults = [];

  for (let i = 0; i < rows.length; i += 1) {
    const row = rows[i];
    const callResult = yield call(PlanV3Service.planV3AddRowToInputTable, { planId, inputTableId, row });
    callResults.push(callResult);
  }

  const allOk = _every(callResults, (callResult) => callResult.ok);
  if (allOk) {
    if (refreshInputTable) {
      yield put(PlanV3Actions.getInputTablesList({ planId }));
      yield take(PlanV3Actions.getInputTablesListCompleted.type);
    }
  } else {
    const callResult = _find(callResults, (cResult) => !cResult.ok);
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.addMultipleRowsToInputTableCompleted());
}

export function* deleteRowFromInputTable({ payload = {} }) {
  const { planId, inputTableId, rowPosition } = payload;

  const callResult = yield call(PlanV3Service.planV3DeleteRowFromInputTable, { planId, inputTableId, rowPosition });

  if (callResult.ok) {
    yield put(PlanV3Actions.getInputTablesList({ planId }));
    yield take(PlanV3Actions.getInputTablesListCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.deleteRowFromInputTableCompleted());
}

export function* deleteMultipleRowsFromInputTable({ payload = {} }) {
  const { planId, inputTableId, rowsPositions, refreshInputTable = true } = payload;

  const callResults = [];

  for (let i = 0; i < rowsPositions.length; i += 1) {
    const rowPosition = rowsPositions[i];
    const callResult = yield call(PlanV3Service.planV3DeleteRowFromInputTable, { planId, inputTableId, rowPosition });
    callResults.push(callResult);
  }

  const allOk = _every(callResults, (callResult) => callResult.ok);
  if (allOk) {
    if (refreshInputTable) {
      yield put(PlanV3Actions.getInputTablesList({ planId }));
      yield take(PlanV3Actions.getInputTablesListCompleted.type);
    }
  } else {
    const callResult = _find(callResults, (cResult) => !cResult.ok);
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.deleteMultipleRowsFromInputTableCompleted());
}

export function* addColumnToInputTable({ payload = {} }) {
  const { planId, inputTableId, column } = payload;

  const callResult = yield call(PlanV3Service.planV3AddColumnToInputTable, { planId, inputTableId, column });

  if (callResult.ok) {
    yield put(PlanV3Actions.getInputTablesList({ planId }));
    yield take(PlanV3Actions.getInputTablesListCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.addColumnToInputTableCompleted());
}

export function* addMultipleColumnsToInputTable({ payload = {} }) {
  const { planId, inputTableId, columns, refreshInputTable = true } = payload;

  const callResults = [];

  for (let i = 0; i < columns.length; i += 1) {
    const column = columns[i];
    const callResult = yield call(PlanV3Service.planV3AddColumnToInputTable, { planId, inputTableId, column });
    callResults.push(callResult);
  }

  const allOk = _every(callResults, (callResult) => callResult.ok);
  if (allOk) {
    if (refreshInputTable) {
      yield put(PlanV3Actions.getInputTablesList({ planId }));
      yield take(PlanV3Actions.getInputTablesListCompleted.type);
    }
  } else {
    const callResult = _find(callResults, (cResult) => !cResult.ok);
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.addMultipleColumnsToInputTableCompleted());
}

export function* deleteColumnFromInputTable({ payload = {} }) {
  const { planId, inputTableId, columnId } = payload;

  const callResult = yield call(PlanV3Service.planV3DeleteColumnFromInputTable, { planId, inputTableId, columnId });

  if (callResult.ok) {
    yield put(PlanV3Actions.getInputTablesList({ planId }));
    yield take(PlanV3Actions.getInputTablesListCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.deleteColumnFromInputTableCompleted());
}

export function* deleteMultipleColumnsFromInputTable({ payload = {} }) {
  const { planId, inputTableId, columnsIds, refreshInputTable = true } = payload;

  const callResults = [];

  for (let i = 0; i < columnsIds.length; i += 1) {
    const columnId = columnsIds[i];
    const callResult = yield call(PlanV3Service.planV3DeleteColumnFromInputTable, { planId, inputTableId, columnId });
    callResults.push(callResult);
  }

  const allOk = _every(callResults, (callResult) => callResult.ok);
  if (allOk) {
    if (refreshInputTable) {
      yield put(PlanV3Actions.getInputTablesList({ planId }));
      yield take(PlanV3Actions.getInputTablesListCompleted.type);
    }
  } else {
    const callResult = _find(callResults, (cResult) => !cResult.ok);
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.deleteMultipleColumnsFromInputTableCompleted());
}

export function* updateCellContentFromInputTable({ payload = {} }) {
  const { planId, inputTableId, rowPosition, columnId, content } = payload;

  const values = [{
    columnId,
    value: content,
  }];

  const callResult = yield call(PlanV3Service.planV3UpdateCellContentFromInputTable, { planId, inputTableId, rowPosition, values });

  if (callResult.ok) {
    yield put(PlanV3Actions.getInputTablesList({ planId }));
    yield take(PlanV3Actions.getInputTablesListCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.updateCellContentFromInputTableCompleted());
}

export function* updateColumnFromInputTable({ payload = {} }) {
  const { planId, inputTableId, columnId, label, type } = payload;

  const callResult = yield call(PlanV3Service.planV3UpdateColumnFromInputTable, { planId, inputTableId, columnId, label, type });

  if (callResult.ok) {
    yield put(PlanV3Actions.getInputTablesList({ planId }));
    yield take(PlanV3Actions.getInputTablesListCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.updateColumnFromInputTableCompleted());
}

export function* freezePeriod({ payload = {} }) {
  const { planId, periodId } = payload;

  const params = { planId, periodId };

  const callResult = yield call(PlanV3Service.planV3FreezePeriod, params);

  if (callResult.ok) {
    yield put(PlanV3Actions.getDetails({ planId }));
    yield take(PlanV3Actions.getDetailsCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.freezePeriodCompleted());
}

export function* unfreezePeriod({ payload = {} }) {
  const { planId, periodId } = payload;

  const params = { planId, periodId };

  const callResult = yield call(PlanV3Service.planV3UnfreezePeriod, params);

  if (callResult.ok) {
    yield put(PlanV3Actions.getDetails({ planId }));
    yield take(PlanV3Actions.getDetailsCompleted.type);
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.unfreezePeriodCompleted());
}

export function* generateWithAI({ payload = {} }) {
  const { planId, codeCellId, prompt, onSuccessCB = null } = payload;

  const params = { planId, codeCellId, prompt };

  const callResult = yield call(PlanV3Service.generateWithAI, params);

  if (callResult.ok) {
    yield put(PlanV3Actions.getDetails({ planId }));
    yield take(PlanV3Actions.getDetailsCompleted.type);

    if (onSuccessCB !== null) onSuccessCB();
  } else {
    const error = managePlanV3Error(callResult);
    yield put(GlobalNotifActions.addGlobalNotif(error));
  }

  yield put(PlanV3Actions.generateWithAICompleted());
}

export function* loop() {
  yield all([
    takeLatest(PlanV3Actions.getList.type, getList),
    takeLatest(PlanV3Actions.getICList.type, getICList),
    takeLatest(PlanV3Actions.deletePlan.type, deletePlan),
    takeLatest(PlanV3Actions.getListUsers.type, getListUsers),
    takeLatest(PlanV3Actions.createPlan.type, createPlan),
    takeLatest(PlanV3Actions.getConfiguration.type, getConfiguration),
    takeLatest(PlanV3Actions.getICConfiguration.type, getICConfiguration),
    takeLatest(PlanV3Actions.updateConfiguration.type, updateConfiguration),
    takeLatest(PlanV3Actions.getDetails.type, getDetails),
    takeLatest(PlanV3Actions.getAchievementsList.type, getAchievementsList),
    takeLatest(PlanV3Actions.runCodeCell.type, runCodeCell),
    takeLatest(PlanV3Actions.copyVariableNameToClipboard.type, copyVariableNameToClipboard),
    takeLatest(PlanV3Actions.savePlan.type, savePlan),
    takeLatest(PlanV3Actions.getInputTablesList.type, getInputTablesList),
    takeLatest(PlanV3Actions.createInputTable.type, createInputTable),
    takeLatest(PlanV3Actions.addRowToInputTable.type, addRowToInputTable),
    takeLatest(PlanV3Actions.addMultipleRowsToInputTable.type, addMultipleRowsToInputTable),
    takeLatest(PlanV3Actions.deleteRowFromInputTable.type, deleteRowFromInputTable),
    takeLatest(PlanV3Actions.deleteMultipleRowsFromInputTable.type, deleteMultipleRowsFromInputTable),
    takeLatest(PlanV3Actions.addColumnToInputTable.type, addColumnToInputTable),
    takeLatest(PlanV3Actions.addMultipleColumnsToInputTable.type, addMultipleColumnsToInputTable),
    takeLatest(PlanV3Actions.deleteColumnFromInputTable.type, deleteColumnFromInputTable),
    takeLatest(PlanV3Actions.deleteMultipleColumnsFromInputTable.type, deleteMultipleColumnsFromInputTable),
    takeLatest(PlanV3Actions.updateCellContentFromInputTable.type, updateCellContentFromInputTable),
    takeLatest(PlanV3Actions.updateColumnFromInputTable.type, updateColumnFromInputTable),
    takeLatest(PlanV3Actions.freezePeriod.type, freezePeriod),
    takeLatest(PlanV3Actions.unfreezePeriod.type, unfreezePeriod),
    takeLatest(PlanV3Actions.generateWithAI.type, generateWithAI),
  ]);
}

export function* clean() {
  yield put(PlanV3Actions.resetToInitialState());
}
