import { GLOBAL_NOTIF_REASONS } from '@palette/constants/globalNotifReason/entities';

// Global
import * as GlobalErrorConstraintViolationStrategyModel from '@palette/models/globalNotifStrategies/global/GlobalErrorConstraintViolationStrategy';

// Notebook
import * as NotebookDataframeNameCopiedStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookDataframeNameCopiedStrategy';
import * as NotebookCodeCellRequiredInformationStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookCodeCellRequiredInformationStrategy';
import * as NotebookCodeCellConflictingOutputNameStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookCodeCellConflictingOutputNameStrategy';
import * as NotebookCodeInvalidOutputUIDStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookCodeInvalidOutputUIDStrategy';
import * as NotebookCodeCellOutputUIDLockedStrategyModel from '@palette/models/globalNotifStrategies/notebook/NotebookCodeCellOutputUIDLockedStrategy';

// Plan
import * as PlanInvalidCommissionAmountStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanInvalidCommissionAmountStrategy';
import * as PlanCodeRuleEvaluationErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanCodeRuleEvaluationErrorStrategy';
import * as PlanInjectionErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanInjectionErrorStrategy';
import * as PlanPercentageRuleTargetErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanPercentageRuleTargetErrorStrategy';
import * as PlanInvalidCodeFormulaErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanInvalidCodeFormulaErrorStrategy';
import * as PlanPayoutEvaluationErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanPayoutEvaluationErrorStrategy';
import * as PlanTrackedResourceEvaluationErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanTrackedResourceEvaluationErrorStrategy';
import * as PlanInvalidPaymentPercentageErrorStrategyModel from '@palette/models/globalNotifStrategies/plan/PlanInvalidPaymentPercentageErrorStrategy';

// Plan V3
import * as PlanV3InvalidEndDateErrorStrategyModel from '@palette/models/globalNotifStrategies/planV3/PlanV3InvalidEndDateErrorStrategy';
import * as PlanV3MissingInformationErrorStrategyModel from '@palette/models/globalNotifStrategies/planV3/PlanV3MissingInformationErrorStrategy';
import * as PlanV3VariableNameCopiedStrategyModel from '@palette/models/globalNotifStrategies/planV3/PlanV3VariableNameCopiedStrategy';

// Profile
import * as ProfileAccountInvalidCredentialsStrategyModel from '@palette/models/globalNotifStrategies/profile/ProfileAccountInvalidCredentialsStrategy';

// Resource
import * as ResourceDeletionContentStrategyModel from '@palette/models/globalNotifStrategies/resource/ResourceDeletionStrategy';

// Statement
import * as ApproveStatementStrategyModel from '@palette/models/globalNotifStrategies/statement/ApproveStatementStrategy';
import * as MarkAsPaidStatementStrategyModel from '@palette/models/globalNotifStrategies/statement/MarkAsPaidStatementStrategy';

export const entities = {
  // Global
  [GLOBAL_NOTIF_REASONS.GLOBAL_ERROR_CONSTRAINT_VIOLATION.code]: GlobalErrorConstraintViolationStrategyModel,

  // Notebook
  [GLOBAL_NOTIF_REASONS.NOTEBOOK_COPY_TO_CLIPBOARD_SUCCESS.code]: NotebookDataframeNameCopiedStrategyModel,
  [GLOBAL_NOTIF_REASONS.NOTEBOOK_CELL_REQUIRED_INFORMATION_ERROR.code]: NotebookCodeCellRequiredInformationStrategyModel,
  [GLOBAL_NOTIF_REASONS.NOTEBOOK_CELL_OUTPUT_NAME_CONFLICT.code]: NotebookCodeCellConflictingOutputNameStrategyModel,
  [GLOBAL_NOTIF_REASONS.NOTEBOOK_INVALID_OUPUT_UID_ERROR.code]: NotebookCodeInvalidOutputUIDStrategyModel,
  [GLOBAL_NOTIF_REASONS.NOTEBOOK_OUTPUT_UID_LOCKED.code]: NotebookCodeCellOutputUIDLockedStrategyModel,

  // Plan
  [GLOBAL_NOTIF_REASONS.PLAN_INVALID_COMMISSION_AMOUNT_ERROR.code]: PlanInvalidCommissionAmountStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_CODE_RULE_EVALUATION_ERROR.code]: PlanCodeRuleEvaluationErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_INJECTION_ERROR.code]: PlanInjectionErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_PERCENTAGE_RULE_TARGET_ERROR.code]: PlanPercentageRuleTargetErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_INVALID_CODE_FORMULA_ERROR.code]: PlanInvalidCodeFormulaErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_PAYOUT_EVALUATION_ERROR.code]: PlanPayoutEvaluationErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_TRACKED_RESOURCE_EVALUATION_ERROR.code]: PlanTrackedResourceEvaluationErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_INVALID_PAYMENT_PERCENTAGE_ERROR.code]: PlanInvalidPaymentPercentageErrorStrategyModel,

  // Plan V3
  [GLOBAL_NOTIF_REASONS.PLAN_V3_INVALID_END_DATE_ERROR.code]: PlanV3InvalidEndDateErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_V3_MISSING_INFORMATION_ERROR.code]: PlanV3MissingInformationErrorStrategyModel,
  [GLOBAL_NOTIF_REASONS.PLAN_V3_COPY_TO_CLIPBOARD_SUCCESS.code]: PlanV3VariableNameCopiedStrategyModel,

  // Profile
  [GLOBAL_NOTIF_REASONS.PROFILE_ACCOUNT_INVALID_CREDENTIALS.code]: ProfileAccountInvalidCredentialsStrategyModel,

  // Resource
  [GLOBAL_NOTIF_REASONS.RESOURCE_DELETION_SUCCESS.code]: ResourceDeletionContentStrategyModel,

  // Statement
  [GLOBAL_NOTIF_REASONS.STATEMENT_APPROVE_STATEMENTS_SUCCESS.code]: ApproveStatementStrategyModel,
  [GLOBAL_NOTIF_REASONS.STATEMENT_MARK_AS_PAID_STATEMENTS_SUCCESS.code]: MarkAsPaidStatementStrategyModel,
};
