import PropTypes from 'prop-types';

import { manageArrayAttribute, manageStringAttribute } from '@palette/helpers/ModelHelper';

import { getDisplayName } from '@palette/models/MetaUser';

export const modelName = 'PlanV3ListUser';

export const propTypes = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  displayName: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.string),
});

export const transform = (user) => {
  if (!user) {
    return null;
  }

  const firstName = manageStringAttribute(user, 'firstName');
  const lastName = manageStringAttribute(user, 'lastName');
  const email = manageStringAttribute(user, 'email');

  return (
    {
      id: manageStringAttribute(user, 'id'),
      firstName,
      lastName,
      email,
      displayName: getDisplayName(firstName, lastName, email),
      profilePictureUrl: manageStringAttribute(user, 'profilePictureUrl', null),
      teams: manageArrayAttribute(user, 'teams'),
    }
  );
};

export const transformList = (users) => users.map((user) => transform(user));
