import React, { useCallback, useEffect, useMemo, useState } from 'react';
import bindClassNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Loader from '@palette/components/utils/Loader/Loader';
import DatePicker from '@palette/components/designSystem/DatePicker/DatePicker';
import QuotaDetailContent from '@palette/components/quota/QuotaDetailContent/QuotaDetailContent';
import Input from '@palette/components/designSystem/Input/Input';
import Button from '@palette/components/designSystem/Button/Button';
import LockFilled from '@palette/components/utils/Icons/LockFilled';
import UnlockFilled from '@palette/components/utils/Icons/UnlockFilled';
import EditQuotaDefaultValues from '@palette/components/quota/EditQuotaDefaultValues/EditQuotaDefaultValues';
import QuotaInfos from '@palette/components/quota/QuotaInfos/QuotaInfos';
import QuotaSaved from '@palette/components/utils/Icons/QuotaSaved';
import QuotaSaving from '@palette/components/utils/Icons/QuotaSaving';
import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import EditableQuotaName from '@palette/components/quota/EditableQuotaName/EditableQuotaName';
import Alert from '@palette/components/designSystem/Alert/Alert';
import PlansUsingQuota from '@palette/components/quota/PlansUsingQuota/PlansUsingQuota';
import QuotaMoreOptionsMenu from '@palette/components/quota/QuotaMoreOptionsMenu/QuotaMoreOptionsMenu';

import { useProfile } from '@palette/hooks/ProfileHooks';
import { useYear } from '@palette/hooks/QuotaHooks';

import { computePathname, stringifyQS } from '@palette/helpers/NavigationHelper';
import { getMoment } from '@palette/helpers/MomentHelper';
import { hasAtLeastOneRight } from '@palette/helpers/ProfileHelper';

import { RIGHTS } from '@palette/constants/profile';
import { ALERT_TYPES } from '@palette/constants/alert';
import { PERIOD_TYPES } from '@palette/constants/frequencies';

import routePaths from '@palette/config/routePaths';

import { actions as QuotasActions, selectors as QuotasSelectors } from '@palette/state/Quotas';

import styles from './QuotaDetail.less';

const classNames = bindClassNames.bind(styles);

const QuotaDetail = () => {
  const profile = useProfile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { quotaId } = useParams();
  const moment = getMoment();

  const [year, yearInSearch] = useYear();
  const [searchedUser, setSearchedUser] = useState('');
  const [locked, setLocked] = useState(true);
  const [open, setOpen] = useState(false);

  const updateUserFromQuotaIsPending = useSelector(QuotasSelectors.updateUserFromQuotaIsPending);

  const byIdIsPending = useSelector(QuotasSelectors.getByIdIsPending);
  const quota = useSelector((state) => QuotasSelectors.getQuotaById(state, { quotaId }));

  useEffect(() => {
    if (yearInSearch < 1970) {
      history.push({
        pathname: computePathname(routePaths.v2.quotaDetails, {
          quotaId,
        }),
      });
    }
    if (quota !== null && quota.periodType === PERIOD_TYPES.DAY && yearInSearch < moment(quota.beginAt).year()) {
      history.push({
        pathname: computePathname(routePaths.v2.quotaDetails, {
          quotaId,
        }),
        search: stringifyQS({
          year: moment(quota.beginAt).year(),
        }),
      });
    }
  }, [yearInSearch, quota]);

  useEffect(() => {
    if (quotaId) dispatch(QuotasActions.getById({ quotaId }));
  }, [quotaId]);

  const saveStatusIconNode = useMemo(() => {
    if (quota === null || quota.archived) return null;

    return (
      <div className={styles.saveStatusWrapper}>
        <div
          className={classNames({
            saveStatusLabel: true,
            isSaving: updateUserFromQuotaIsPending,
          })}
        >
          {updateUserFromQuotaIsPending ? t('quota.isSaving') : t('quota.saved')}
        </div>
        {
          updateUserFromQuotaIsPending
            ? (<QuotaSaving className={styles.saveStatusIcon} width={32} height={32} />)
            : (<QuotaSaved className={styles.saveStatusIcon} width={32} height={32} />)
        }
      </div>
    );
  }, [updateUserFromQuotaIsPending, quota]);

  const handleOpenChange = useCallback((isOpened) => {
    if (!isOpened && open) {
      setOpen(false);
    }
  }, [open]);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleDisabledDate = useCallback((d) => (
    !d
    || d.isSameOrBefore('1970-01-01')
    || (
      quota !== null
      && quota.periodType === PERIOD_TYPES.DAY
      && d.isSameOrBefore(moment(quota.beginAt))
    )
  ), [quota]);

  if (quota === null && byIdIsPending) {
    return (
      <Loader className={styles.loaderContainer} />
    );
  }

  if (quota === null) {
    return null;
  }

  const handleYearChange = (date, dateString) => {
    history.push({
      pathname: computePathname(routePaths.v2.quotaDetails, {
        quotaId: quota.id,
      }),
      search: stringifyQS({
        year: dateString,
      }),
    });
  };

  const handleLockButton = () => {
    const newLocked = !locked;
    setLocked(newLocked);
    if (newLocked) {
      dispatch(QuotasActions.getById({ quotaId }));
    }
  };

  let editQuotaDefaultValuesNode = null;
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE]) && !quota.archived) {
    editQuotaDefaultValuesNode = (
      <EditQuotaDefaultValues className={styles.editDefaultValues} />
    );
  }

  let archivedTagNode = null;
  if (quota.archived) {
    archivedTagNode = (
      <Alert
        className={styles.archivedTag}
        type={ALERT_TYPES.WARNING}
        message={t('quota.archived')}
      />
    );
  }

  let lockButtonNode = null;
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.USERS.MANAGE]) && !quota.archived) {
    lockButtonNode = (
      <Button
        className={styles.lockButton}
        type={locked ? 'primaryBlue' : 'primary'}
        icon={locked ? (<LockFilled />) : (<UnlockFilled />)}
        onClick={handleLockButton}
        disabled={quota?.users.length === 0}
      >
        {locked ? t('quota.editQuota') : t('quota.lockQuota')}
      </Button>
    );
  }

  let moreOptionsMenuNode = null;
  if (hasAtLeastOneRight(profile, [RIGHTS.ADMIN.QUOTAS.MANAGE]) && !quota.archived) {
    moreOptionsMenuNode = (
      <QuotaMoreOptionsMenu className={styles.moreOptionsMenu} quota={quota} year={year} />
    );
  }

  let outOfScopeWarningNode = null;
  if (!byIdIsPending && quota.usersCount > quota.users.length) {
    outOfScopeWarningNode = (
      <Alert
        className={styles.outOfScopeWarning}
        type={ALERT_TYPES.WARNING}
        message={t('quota.outOfScopeWarning.message', { count: (quota.usersCount - quota.users.length) })}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <MetaTag title={quota.name} />
      <div className={styles.header}>
        <EditableQuotaName quota={quota} />
        <div className={styles.details}>
          <PlansUsingQuota className={styles.plans} quota={quota} />
          <QuotaInfos className={styles.infos} quota={quota} />
        </div>
      </div>
      <div className={styles.actions}>
        <DatePicker
          className={styles.yearPicker}
          picker="year"
          value={moment().year(year)}
          allowClear={false}
          disabledDate={handleDisabledDate}
          onChange={handleYearChange}
          open={open}
          onOpenChange={handleOpenChange}
          onClick={handleClick}
        />
        <Input
          className={styles.search}
          type="search"
          placeholder={t('quota.userSearch.placeholder')}
          onChange={setSearchedUser}
          value={searchedUser}
        />
        {editQuotaDefaultValuesNode}
        {saveStatusIconNode}
        {lockButtonNode}
        {moreOptionsMenuNode}
      </div>
      {archivedTagNode}
      {outOfScopeWarningNode}
      <QuotaDetailContent
        className={styles.content}
        quota={quota}
        searchedUser={searchedUser}
        locked={locked}
        year={year}
      />
    </div>
  );
};

export default QuotaDetail;
