import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaTag from '@palette/components/utils/MetaTag/MetaTag';
import PageTitle from '@palette/components/utils/PageTitle/PageTitle';
import SettingsPageContent from '@palette/components/settings/SettingsPageContent/SettingsPageContent';

import styles from './SettingsPage.less';

const SettingsPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <MetaTag title={t('app.metas.settingsTitle')} />
      <PageTitle title={t('settingsPage.page.title')} />
      <SettingsPageContent />
    </div>
  );
};

export default SettingsPage;
